import type {ComponentType} from 'react';
import React, {useMemo} from 'react';
import {useAppSelector} from '_hooks';
import {
  Flex,
  FluroDataTable,
  FluroTableBody,
  FluroTableColumn,
  FluroTableHeader,
  FluroTableRow,
  Text,
} from 'components';
import {Circle} from 'components/shapes/circle';
import {
  selectReportDashboardData,
  selectReportDataUnits,
  selectReportAvailableSourcingRegions,
  selectReportAvailableInterventions,
  selectReportType,
  selectReportPageIndex,
  selectReportPageSize,
} from 'containers/si/module/selectors';
import {selectCropTypesCDLIdMap} from 'modules/global/selectors';
import {selectIsAdmin, selectMeasurement} from 'containers/login/login-selectors';
import {SIScenarioKPIs} from 'containers/si/types';
import {PRECISION_UNITS, SIScenarioKPIsLabel} from 'containers/si/constants';
import {REPORT_DASHBOARD_COLORS} from 'containers/si/colors';
import {calculateKPIValue} from 'containers/si/planning-report/dashboard/utils/calculate-scenario';
import {
  getRegionName,
  getRegionId,
  getAdoptionPercentage,
} from 'containers/si/planning-report/dashboard/utils/utils';
import {CardToolTip} from 'containers/map/features/sustainability-insights/filters-panel/cards/components/card-tooltip';
import './scenario-table.scss';

import {useGetChartRedToGreenColorRange} from '../hooks/useGetChartRedToGreenColorRange';
import {StyledFluroTableColumn} from './styled-cards';

export const ScenarioTable: ComponentType<{}> = () => {
  const dashboardData = useAppSelector(selectReportDashboardData);
  const units = useAppSelector(selectReportDataUnits);
  const measurement = useAppSelector(selectMeasurement);
  const isAdmin = useAppSelector(selectIsAdmin);

  const cropsById = useAppSelector(selectCropTypesCDLIdMap);
  const availableSourcingRegions = useAppSelector(selectReportAvailableSourcingRegions);
  const availableInterventions = useAppSelector(selectReportAvailableInterventions);
  const reportType = useAppSelector(selectReportType);
  const measurementUnit = useMemo(() => (measurement === 'ac' ? 'acre' : 'hectare'), [measurement]);

  const getChartColor = useGetChartRedToGreenColorRange();

  // Used for the numbering in the circle
  const pageIndex = useAppSelector(selectReportPageIndex);
  const pageSize = useAppSelector(selectReportPageSize);
  const startNumber = pageIndex * pageSize + 1;

  return (
    <div className="scenario-table">
      <Flex justifyContent="space-between" nowrap>
        <Text variant="h3" bold className="margin-bottom-4">
          Top ranked scenarios
        </Text>
        <CardToolTip
          id="reductions-removal-card"
          content={
            <Text variant={'small'}>
              The statistics shown below are all annual per acre average change per the practice
              intervention changes relative to the baseline. The practice intervention change is the
              counter factual scenario while the baseline scenario is the factual as determined by
              Regrow’s monitor and measure methods. The coloring of each cell is determined by the
              scenario bar chart range shown in the section below. This table is sorted according to
              the{' '}
              <Text variant={'small'} bold>
                Rank by
              </Text>{' '}
              filter at the top of the report.
            </Text>
          }
          place="bottom"
        />
      </Flex>
      <Text tertiary variant="medium" className="margin-bottom-4">
        Annual per acre average change relative to the baseline
      </Text>
      {dashboardData.length > 0 && (
        <>
          <FluroDataTable elevated={false}>
            <FluroTableHeader>
              <FluroTableRow>
                <FluroTableColumn type={'date'}>#</FluroTableColumn>
                <FluroTableColumn>Geography</FluroTableColumn>
                <FluroTableColumn>Crop</FluroTableColumn>
                <FluroTableColumn>Practice interventions</FluroTableColumn>
                {reportType === 'program_plan' && (
                  <FluroTableColumn type={'number'}>Baseline</FluroTableColumn>
                )}
                <FluroTableColumn type={'number'}>
                  <Flex direction="column" alignItems={'flex-start'}>
                    {SIScenarioKPIsLabel.ghg_reductions}
                    <Text variant="small-thin">{`metric tons / ${measurementUnit}`}</Text>
                  </Flex>
                </FluroTableColumn>
                <FluroTableColumn type={'number'}>
                  <Flex direction="column" alignItems={'flex-start'}>
                    {SIScenarioKPIsLabel.soc_removals}
                    <Text variant="small-thin">{`metric tons / ${measurementUnit}`}</Text>
                  </Flex>
                </FluroTableColumn>
                <FluroTableColumn type={'number'}>
                  <Flex direction="column" alignItems={'flex-start'}>
                    {SIScenarioKPIsLabel.net_ghg}
                    <Text variant="small-thin">{`metric tons / ${measurementUnit}`}</Text>
                  </Flex>
                </FluroTableColumn>
                {reportType === 'abatement_potential' && (
                  <FluroTableColumn type={'number'}>
                    <Flex direction="column" alignItems={'flex-start'}>
                      {SIScenarioKPIsLabel.ef_reductions}
                      <Text variant="small-thin">kg CO2 eq / kg yield</Text>
                    </Flex>
                  </FluroTableColumn>
                )}
              </FluroTableRow>
            </FluroTableHeader>
            <FluroTableBody>
              {dashboardData.map((s, index) => {
                const ghgReductions = calculateKPIValue({
                  scenario: s,
                  kpi: SIScenarioKPIs.ghg_reductions,
                  units,
                  measurement,
                  round: true,
                  precision: PRECISION_UNITS,
                });
                const ghgReductionsBackground = getChartColor({value: ghgReductions.value});

                const socRemovals = calculateKPIValue({
                  scenario: s,
                  kpi: SIScenarioKPIs.soc_removals,
                  units,
                  measurement,
                  round: true,
                  precision: PRECISION_UNITS,
                });
                const socRemovalsBackground = getChartColor({
                  value: socRemovals.value,
                  lowValueIsBad: true,
                });

                const netEmissions = calculateKPIValue({
                  scenario: s,
                  kpi: SIScenarioKPIs.net_ghg,
                  units,
                  measurement,
                  round: true,
                  precision: PRECISION_UNITS,
                });
                const netEmissionsBackground = getChartColor({
                  value: netEmissions.value,
                });

                const efReductions = calculateKPIValue({
                  scenario: s,
                  kpi: SIScenarioKPIs.ef_reductions,
                  units,
                  measurement,
                  round: true,
                  precision: PRECISION_UNITS,
                });
                const efReductionsBackground = getChartColor({value: efReductions.value});

                return (
                  <FluroTableRow key={s.id}>
                    <FluroTableColumn type={'date'}>
                      <Circle color={REPORT_DASHBOARD_COLORS[index] || '#60b854'}>
                        {startNumber + index}
                      </Circle>
                    </FluroTableColumn>
                    <FluroTableColumn>
                      {getRegionName(s, availableSourcingRegions)}
                      {isAdmin && (
                        <Text inline className="margin-left-8" tertiary variant="small-thin">
                          ({getRegionId(s, isAdmin)})
                        </Text>
                      )}
                    </FluroTableColumn>
                    <FluroTableColumn>
                      {cropsById[s.crop_type]?.label}
                      {isAdmin && (
                        <Text inline className="margin-left-8" tertiary variant="small-thin">
                          (#{s.crop_type})
                        </Text>
                      )}
                    </FluroTableColumn>
                    <FluroTableColumn>
                      {availableInterventions[s.scenario_id]}
                      {isAdmin && (
                        <Text inline className="margin-left-8" tertiary variant="small-thin">
                          (#{s.scenario_id})
                        </Text>
                      )}
                    </FluroTableColumn>
                    {reportType === 'program_plan' && (
                      <FluroTableColumn type={'number'}>
                        {getAdoptionPercentage(s)}
                      </FluroTableColumn>
                    )}
                    <StyledFluroTableColumn type={'number'} background={ghgReductionsBackground}>
                      {ghgReductions.value} {ghgReductions.unit}
                    </StyledFluroTableColumn>
                    <StyledFluroTableColumn type={'number'} background={socRemovalsBackground}>
                      {socRemovals.value} {socRemovals.unit}
                    </StyledFluroTableColumn>
                    <StyledFluroTableColumn type={'number'} background={netEmissionsBackground}>
                      {netEmissions.value} {netEmissions.unit}
                    </StyledFluroTableColumn>
                    {reportType === 'abatement_potential' && (
                      <StyledFluroTableColumn type={'number'} background={efReductionsBackground}>
                        {efReductions.value} {efReductions.unit}
                      </StyledFluroTableColumn>
                    )}
                  </FluroTableRow>
                );
              })}
            </FluroTableBody>
          </FluroDataTable>
        </>
      )}
    </div>
  );
};
