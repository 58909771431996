import {Flex, Sticky, Text} from 'components';
import type {ComponentType} from 'react';
import React from 'react';
import styled, {css} from 'styled-components';
import {
  main_gray_300,
  main_gray_400,
  main_gray_600,
  main_gray_700,
  main_green_700,
} from '_utils/colors';

export const Card = styled.div<{fullWidth?: boolean}>`
  border: 1px solid ${main_gray_300};
  border-radius: 4px;
  padding: 20px 16px 0;
  max-height: 400px;
  height: 400px;
  background-color: white;
  ${({fullWidth}) =>
    fullWidth
      ? css`
          width: 100%;
        `
      : css`
          max-width: 410px;
          width: 300px;
        `};

  .card-dynamic-value {
    &__red {
      color: #dc2828;
    }
    &__green {
      color: ${main_green_700};
    }
  }
  .dashed-line {
    border-bottom: 2px dashed #c2c2c2;
    width: 15px;
  }
`;

export const CardTitle: ComponentType = ({children}) => (
  <Text variant="h3" className="mb-0" bold>
    {children}
  </Text>
);
export const CardSubtitle: ComponentType = ({children}) => (
  <Text tertiary variant="medium" style={{height: 22}}>
    {children}
  </Text>
);

export const CardBigNumber = styled.div`
  font-size: 36px;
  font-weight: 600;
  text-align: center;
`;
export const CardBigNumberUnits = styled.div`
  font-size: 14px;
  height: 14px;
  color: ${main_gray_600};
  text-align: center;
`;

export const CardBigNumberComponent: ComponentType<{value: string; units: string}> = ({value}) => (
  <CardBigNumber>{value}</CardBigNumber>
);

export const BarChartNav = styled.div`
  display: flex;
  justify-content: space-between;

  .nav-btn {
    color: ${main_gray_700};
    font-size: 12px;
    text-transform: none;
    cursor: pointer;
    padding: 5px;
    font-weight: 600;

    &.disabled {
      cursor: default;
      color: ${main_gray_400};
    }

    .md-icon {
      color: inherit;
    }
  }
`;

export const cardStyles = css`
  border: 1px solid ${({theme}) => theme.color.stroke.strong};
  border-radius: 4px;
  padding: 8px 16px 20px;
`;

export const HeaderContainer = styled.div`
  padding: 20px 0;
  background-color: ${({theme}) => theme.color.surface.secondary};

  .filter-container {
    .area-select-menu {
      flex-grow: unset;
      flex-basis: auto;
    }
  }

  .title {
    font-size: 36px;
  }
`;

export const KPIContainer = styled.div`
  padding: 0px 24px 20px;
  background-color: ${({theme}) => theme.color.surface.secondary};
  max-width: 1300px;
  margin: 0 auto;

  .section-title {
    margin-top: 2rem;
  }

  .ef-stacked-chart {
    margin-top: 1rem;
  }

  .section-description {
    letter-spacing: 0;
  }

  b {
    font-weight: 500;
  }

  .map-container {
    ${cardStyles}
    height: 400px;
    max-width: 920px;
    min-width: 610px;
    flex: 1;
    background-color: white;

    .kpi-map {
      width: 100%;
      height: 323px;
      z-index: 0;

      .mapboxgl-ctrl-bottom-left,
      .mapboxgl-ctrl-bottom-right {
        display: none;
      }
    }
  }

  .si-crop-type-chart {
    ${cardStyles}
    min-width: 440px;
    display: flex;
    flex-direction: column;

    .crop-type-chart-container {
      margin: auto 0;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;

      .total-value {
        position: absolute;
        z-index: 1;
      }
    }
  }
`;

export const StyledTillageLabel = styled.div`
  display: flex;
  font-size: 15px;
  justify-content: center;
  .label {
    ::before {
      content: '';
      display: inline-block;
      width: 10px;
      height: 10px;
      background-color: #7db065;
      border-radius: 50%;
      margin-right: 5px;
    }
  }
  .label-value {
    margin-left: 4px;
    font-weight: 500;
  }
`;

export const TillagePieContainer = styled.div`
  width: 268px;
  height: 240px;
`;

export const CropRotationPieContainer = styled.div`
  width: 268px;
  height: 270px;
  margin-top: 20px;
`;

export const SparklineContainer = styled.div`
  height: 130px;
`;

export const NoData = styled.div`
  display: flex;
  justify-content: center;
  width: calc(300px - 32px);
`;

export const SliderContainer = styled.div<{sliding: boolean}>`
  .slick-prev,
  .slick-next {
    position: absolute;
    display: inline-block;
    cursor: pointer;
    top: calc(285px + 15px);
    :before {
      content: none;
    }
  }
  .slick-prev {
    left: 0;
  }
  .slick-next {
    right: 0;
  }
  .slick-dots {
    position: absolute;
    width: 80%;
    left: 10%;
    top: 285px;
    li {
      margin: 0;
      button {
        :hover {
          ::before {
            color: ${({theme}) => theme.colorPalette.fs_main.gray_400};
          }
        }
        ::before {
          font-size: 28px;
          opacity: 1;
          color: ${({theme}) => theme.colorPalette.fs_main.gray_300};
        }
      }
      &.slick-active button {
        :before {
          opacity: 1;
          color: ${({theme}) => theme.colorPalette.fs_main.gray_600};
        }
      }
    }
  }
`;

export const Squares = styled.div<{color: string}>`
  height: inherit;
  background-color: ${props => props.color};
  flex-grow: 1;
`;

export const BarContainer = styled.div<{barHeight?: number}>`
  position: relative;
  height: ${props => props.barHeight || 200}px;
  .line {
    position: absolute;
    height: inherit;
    width: 100%;
  }
  .legend {
    height: 72px;
    .confidence {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      height: 24px;
      .label {
        margin-right: 4px;
        ::before {
          content: '';
          display: inline-block;
          width: 15px;
          height: 10px;
          transform: scaleY(0.2);
          background-color: ${({theme}) => theme.colorPalette.fs_main.gray_400};
          margin-right: 4px;
        }
      }
      .tooltip {
        div {
          display: flex;
          align-items: center;
        }
      }
    }
    .health {
      height: 48px;
      .buckets {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        padding: 0px;
        gap: 2px;
        width: 100%;
        height: 6px;
      }
      .labels {
        display: flex;
        justify-content: space-between;
        color: ${({theme}) => theme.colorPalette.fs_main.gray_600};
      }
    }
  }
`;

export const PortalContainer = styled.div`
  position: relative;
`;

interface PortalProps {
  mousePosition: {x: number; y: number};
}

export const Portal = styled.div.attrs<PortalProps>(
  ({mousePosition}: {mousePosition: {x: number; y: number}}) => ({
    style: {top: mousePosition.y - 130, left: mousePosition.x - 102.5},
  })
)<PortalProps>`
  position: fixed;
  z-index: 20;
  background-color: black;
  color: white;
  border-radius: 4px;
  opacity: 0.9;
`;

export const ChartTooltip = styled.div`
  padding: 10px;
  background-color: black;
  color: white;
  border-radius: 4px;
  opacity: 0.9;
`;

export const StyledAGPracticesContainer = styled(Flex)`
  > div {
    width: 410px;
    @media (max-width: 1361px) {
      width: 300px;
    }
  }

  @media (min-width: 1362px) /* and (max-width: 1370px) */ {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  }
`;

export const StyledStickyHeader = styled(Sticky)`
  max-width: 1300px;
  margin: auto;
`;

export const StyledLinesContainer = styled.div`
  margin-top: 20px;
`;
