import React, {useContext, useEffect, useMemo} from 'react';
import {GeoJSON as ReactLeafletGeoJSON} from 'react-leaflet';
import {useAppSelector} from '_hooks';
import {maskGeometry} from '_utils/geometry';
import {featureCollection} from '@turf/turf';
import {OverlayContext} from './context';
import {selectHasEnrolledFields, selectProgramBoundaries} from './monitoring/module/selectors';
import type {MRVRegion} from './types';

// Overlay and border of allowed regions.
export const RegionsMaskOverlay = () => {
  const {fitBounds} = useContext(OverlayContext);
  const hasEnrolledFields = useAppSelector(selectHasEnrolledFields);
  const programBoundaries = useAppSelector(selectProgramBoundaries);
  const regionGeometries = useMemo(
    () =>
      programBoundaries
        ?.map((r: MRVRegion) => r.geom)
        .filter(Boolean)
        .map(geometry => ({type: 'Feature', geometry})) as unknown as GeoJSON.Feature[],
    [programBoundaries]
  );

  const regionsMask = useMemo(() => {
    if (!regionGeometries?.length) return;
    return maskGeometry(regionGeometries as GeoJSON.Feature[]);
  }, [regionGeometries]);

  useEffect(() => {
    if (!regionGeometries?.length || hasEnrolledFields) return;
    const collection = featureCollection(regionGeometries);
    // @ts-expect-error featureCollection returns type that L.geoJSON doesn't expect, just misunderstanding between two libraries
    const geoJSON = L.geoJSON(collection);
    const bounds = geoJSON.getBounds();
    fitBounds(bounds);
  }, [regionGeometries, hasEnrolledFields]);

  if (!regionsMask) return null;
  return (
    <ReactLeafletGeoJSON
      pathOptions={{
        weight: 4,
        opacity: 1,
        color: 'white',
        fillOpacity: 0.4,
        fillColor: 'black',
      }}
      data={regionsMask}
    />
  );
};
