// @ts-nocheck
import {urlsToReason} from '_constants';
import type {GlobalAction, GlobalState} from './types';
import {GlobalActionTypes} from './types';
import {DEFAULT_LOCALE} from 'i18n-utils';

const initialState: GlobalState = {
  isMapView: false,
  isReportView: false,
  cropTypes: {},
  isHeaderExpanded: false, // On narrow screens (mobile) header can be expanded to fullscreen
  isMapLoading: false,
  currentGroupId: 0,
  growerName: '',
  loaders: {},
  dialogsState: {
    editField: {
      fieldId: 0,
      somethingNew: false,
    },
  },
  sessionExpired: false,
  sessionExpiredRequests: [],
  tabNotifications: {},
  tooltip: {id: '', content: ''},
  locale: DEFAULT_LOCALE,
};

const globalReducer = (state = initialState, action: GlobalAction): GlobalState => {
  switch (action.type) {
    case GlobalActionTypes.ADD_LOADING:
      return {
        ...state,
        loaders: {
          ...state.loaders,
          [action.method + action.URLPattern + action.key]: urlsToReason[action.URLPattern]
            ? urlsToReason[action.URLPattern]?.messages[action.method]
            : 'Loading ' + action.URLPattern + '...',
        },
      };

    case GlobalActionTypes.REMOVE_LOADING:
      delete state.loaders[action.method + action.URLPattern + action.key];

      return {
        ...state,
        loaders: {
          ...state.loaders,
        },
      };

    case GlobalActionTypes.MAP_LOADING:
      return {
        ...state,
        isMapLoading: action.value,
      };

    case GlobalActionTypes.TOGGLE_HEADER:
      return {
        ...state,
        isHeaderExpanded: action.value,
      };

    case GlobalActionTypes.TOGGLE_MAP_VIEW:
      return {
        ...state,
        isMapView: action.value,
      };

    case GlobalActionTypes.SET_REPORT_VIEW:
      return {
        ...state,
        isReportView: action.value,
      };

    case GlobalActionTypes.SET_GLOBAL_PARAM:
      return {
        ...state,
        [action.propName]: action.value,
      };

    case GlobalActionTypes.SET_CROP_TYPES: {
      const {cropTypes} = action.payload;

      return {
        ...state,
        cropTypes,
      };
    }

    case GlobalActionTypes.TOGGLE_DIALOG: {
      const dialogProperties = action.resetDialogState ? {} : state.dialogsState[action.dialog];

      return {
        ...state,
        dialogsState: {
          ...state.dialogsState,
          [action.dialog]: {
            ...dialogProperties,
            ...action.properties,
          },
        },
      };
    }

    case GlobalActionTypes.ADD_CROP_TYPE: {
      return {
        ...state,
        cropTypes: {...state.cropTypes, [action.crop.value]: action.crop},
      };
    }

    case GlobalActionTypes.UPDATE_CROP_TYPE: {
      return {
        ...state,
        cropTypes: {
          ...state.cropTypes,
          [action.crop.value]: {...state.cropTypes[action.crop.value], ...action.crop},
        },
      };
    }

    case GlobalActionTypes.TOGGLE_SESSION_EXPIRED:
      return {
        ...state,
        sessionExpired: action.sessionExpired,
        sessionExpiredRequests: !action.sessionExpired
          ? [] // clean requests if session is no longer expired
          : state.sessionExpiredRequests,
      };

    case GlobalActionTypes.ADD_SESSION_EXPIRED_REQUEST:
      return {
        ...state,
        sessionExpiredRequests: [...state.sessionExpiredRequests, action.request],
      };

    case GlobalActionTypes.SET_TAB_NOTIFICATION: {
      return {
        ...state,
        tabNotifications: {
          ...state.tabNotifications,
          [action.tabKey]: {...action.tabData},
        },
      };
    }
    case GlobalActionTypes.TOGGLE_GLOBAL_TOOLTIP: {
      return {
        ...state,
        tooltip: {...action.data},
      };
    }

    default:
      return state;
  }
};

export default globalReducer;
