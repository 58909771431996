// @ts-nocheck
import {t} from 'i18n-utils';
import React, {useState} from 'react';
import cn from 'classnames';
import './geometries-popup.scss';
import {getGeometryLabelByValue, normalizeSensorIndex, convertUnit, formatUnit} from '_utils';
import {getShapeCoordinates} from '_utils/geometry';
import {
  userFacingArea,
  userFacingPriority,
} from 'containers/map/features/anomalies/anomalies-utils';
import moment from 'moment';
import LabelIcon from '../../label-icon';
import {DeleteIcon, EditIcon, UnmutedIcon, VigorIcon} from 'containers/map/icons';
import type {IAnomaly, AnomalyHistoryItem} from 'containers/map/features/anomalies/types';
import {FluroButton, FluroMapPopup} from 'components';
import PermanentlyExcluded from 'components/anomalies-ui/permanently-excluded';
import {useAppSelector} from '_hooks';
import {selectCurrentSensor} from 'containers/map/reducer/selectors';
import {selectIsAdmin, selectMeasurement} from 'containers/login/login-selectors';

type Props = {
  data: any;
  LabelDropdown?: React.ComponentType<{label: string}>;
  onOpen?: () => void; // onOpen is optional since we open popup separately from the popup component
  onClose: () => void;
  onDelete?: () => void;
  onEdit?: () => void;
  onSnooze?: () => void;
  isAnomaly?: boolean;
  sectionName: string;
  sensingDate: string;
  type?: string;
  onHistoryItemClick?: (anomaly: AnomalyHistoryItem) => void;
};

// todo!! - just a copy from an old component, need review!

export const GeometriesPopup = ({
  data,
  LabelDropdown,
  onOpen,
  onClose,
  onDelete,
  onEdit,
  onSnooze,
  isAnomaly,
  sectionName,
  sensingDate,
  // type,
  onHistoryItemClick,
}: Props) => {
  const currentSensor = useAppSelector(selectCurrentSensor);
  const measurement = useAppSelector(selectMeasurement);
  const range = useAppSelector(s => s.map.histogram.range);
  const isAdmin = useAppSelector(selectIsAdmin);

  const [historyOpened, toggleHistory] = useState(false);
  const [lng = 0, lat = 0] = getShapeCoordinates(
    data.geometry,
    // Irrigation issue rings have 2 arrays in the coordinates for inner and outer circles.
    data.properties?.label === 'irrigation_issue' && data.geometry.coordinates.length > 1
  );

  const center = {lat, lng};
  const {
    anomaly_id,
    title,
    label = 'No label',
    startDate,
    endDate,
    area,
    snoozed,
    mean,
    children,
    parents,
    priority,
    clogged_nozzle_distance,
    uploading,
    type,
    exclusion_flag,
  } = data.properties;
  const meanIsShowed = mean > 0 && !['TCI', 'TIRS'].includes(currentSensor);

  return (
    <FluroMapPopup
      position={center}
      onOpen={onOpen}
      onClose={onClose}
      className={`geometry-popup ${!isAnomaly ? 'map-popup__item--geometry' : ''}`}
    >
      <div className="header">
        <div className="header__label">{sectionName}</div>
        {!!anomaly_id && <div className="header__id">#{anomaly_id}</div>}
        <div className="header__title">
          {t({id: 'Identified'})}:
          <span>
            {endDate
              ? `${moment.utc(startDate).format('MMM D')} - ${moment.utc(endDate).format('MMM D')}`
              : `${moment.utc(startDate).format('MMM D')}`}
          </span>
        </div>
      </div>
      {historyOpened ? null : (
        <div className={`geometry__item ${!isAnomaly ? 'geometry__item--geometry' : ''}`}>
          <div className="geometry__item__overview-row">
            <div className="geometry__item__date">
              {moment.utc(sensingDate).format('DD MMM YY')}
            </div>
            <div className="geometry__item__area">{userFacingArea(area, measurement)}</div>
            {meanIsShowed && (
              <div className="geometry__item__ndvi">
                <VigorIcon type={type} style={{margin: 0}} />
                {`${currentSensor}: ${normalizeSensorIndex(mean, range)}`}
              </div>
            )}
          </div>
          <div className="geometry__item__primary-row">
            <div className="geometry__item__icon">
              <LabelIcon label={label} />
            </div>
            <div className="geometry__item__info">
              <div className="geometry__item__label">
                {LabelDropdown ? (
                  <LabelDropdown label={getGeometryLabelByValue(label)} />
                ) : (
                  getGeometryLabelByValue(label)
                )}
              </div>
              <div className="geometry__item__description">{title}</div>
              {clogged_nozzle_distance ? (
                <div className={'geometry__item__distance'}>
                  Distance to the field center{' '}
                  {parseFloat(convertUnit(measurement, 'm', clogged_nozzle_distance)).toFixed(1)}{' '}
                  {formatUnit(measurement, 'm')}
                </div>
              ) : null}
            </div>
          </div>
        </div>
      )}
      {((priority && isAnomaly) || parents || children) && (
        <div className={'d-flex justify-between'}>
          {isAnomaly && priority && !historyOpened && (
            <div className={`geometry-popup__info__val priority ${priority ? priority : ''}`}>
              {t({id: priority})}
            </div>
          )}
          {parents || children ? (
            <History
              anomaly={data}
              measurement={measurement}
              toggleHistory={toggleHistory}
              historyOpened={historyOpened}
              onHistoryItemClick={onHistoryItemClick}
            />
          ) : null}
        </div>
      )}
      {!isAnomaly && !uploading ? (
        <div className="geometry-popup__actions">
          <FluroButton
            readonly
            id={'geometry-popup__action-delete'}
            raised
            blank
            iconEl={<DeleteIcon />}
            onClick={onDelete}
            className="geometry-popup__actions__item"
          >
            {t({id: 'Delete'})}
          </FluroButton>

          <FluroButton
            id={'geometry-popup__action-edit'}
            readonly
            raised
            primary
            iconEl={<EditIcon />}
            onClick={onEdit}
            className="geometry-popup__actions__item"
          >
            {t({id: 'Edit'})}
          </FluroButton>
        </div>
      ) : null}

      {isAnomaly ? (
        <div className="geometry-popup__actions">
          {isAdmin && (
            <FluroButton
              id={'geometry-popup__action-delete'}
              readonly
              raised
              blank
              iconEl={<DeleteIcon />}
              onClick={onDelete}
              className="geometry-popup__actions__item"
            >
              {t({id: 'Delete'})}
            </FluroButton>
          )}

          {!!exclusion_flag && <PermanentlyExcluded />}
          <FluroButton
            raised
            readonly
            primary={!snoozed}
            iconEl={<UnmutedIcon className={'snoozed-icon'} />}
            onClick={onSnooze}
            className="geometry-popup__actions__item"
          >
            {snoozed ? t({id: 'Unsnooze'}) : t({id: 'Snooze'})}
          </FluroButton>
        </div>
      ) : null}
    </FluroMapPopup>
  );
};

const History = ({
  anomaly,
  measurement,
  toggleHistory,
  historyOpened,
  onHistoryItemClick,
}: {
  anomaly: IAnomaly;
  measurement: string; // 'ha' | 'ac'
  toggleHistory: (open: boolean) => void;
  historyOpened: boolean;
  onHistoryItemClick: (anomaly: AnomalyHistoryItem) => void;
}) => {
  const {parents, children} = anomaly.properties;
  return (
    <div className="geometry-popup__history">
      <div
        onClick={() => toggleHistory(!historyOpened)}
        className={`geometry-popup__history__btn ${historyOpened ? 'opened' : ''}`}
      >
        {t({id: 'Detection history'})}
      </div>
      {historyOpened && (
        <div className="geometry-popup__history__list">
          {parents?.map(anomaly => (
            <HistoryItem
              key={anomaly.id}
              anomaly={anomaly}
              measurement={measurement}
              handleClick={() => onHistoryItemClick(anomaly)}
            />
          ))}
          {<HistoryItem anomaly={anomaly.properties} measurement={measurement} isCurrent />}
          {children?.map(anomaly => (
            <HistoryItem
              key={anomaly.id}
              anomaly={anomaly}
              measurement={measurement}
              handleClick={() => onHistoryItemClick(anomaly)}
            />
          ))}
        </div>
      )}
    </div>
  );
};

const HistoryItem = ({
  anomaly,
  measurement,
  handleClick,
  isCurrent = false,
}: {
  anomaly: AnomalyHistoryItem;
  measurement: string; // 'ha' | 'ac'
  handleClick?: () => void;
  isCurrent?: boolean;
}) => {
  const {label, sensing_date, anomaly_size, priority} = anomaly;
  const anomalyPriority = userFacingPriority(priority, label);
  return (
    <div
      className={cn({
        'geometry-popup__history__list__item': true,
        'geometry-popup__history__list__item--history': !isCurrent,
      })}
      onClick={handleClick}
    >
      <div className="geometry__item__icon">
        <LabelIcon label={label} />
      </div>
      <div className="geometry__item__info">
        <div className="geometry__item__date">{`${moment.utc(sensing_date).format('DD MMM')}`}</div>
        <div className="geometry__item__label">{`${label || 'No label'}`.replace(/_/g, ' ')}</div>
      </div>
      <div className="geometry__item__additional-info">
        <div className="geometry-popup__area">{userFacingArea(anomaly_size, measurement)}</div>
        <div className={`geometry-popup__info__val priority ${anomalyPriority}`}>
          {anomalyPriority}
        </div>
      </div>
    </div>
  );
};
