// @ts-nocheck
import React, {useCallback, useMemo, useState} from 'react';
import {Controller, useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import {SketchPicker} from 'react-color';
import {AccessibleFakeButton, DropdownMenu, FontIcon, TextField} from 'react-md';
import {genKey} from '_utils/pure-utils';
import {AdminApi} from '_api';
import {updateCropType} from '../../../modules/global/actions';
import {showNotification} from 'components/notification/notification';
import {t} from 'i18n-utils';
import {useDispatch} from 'react-redux';
import {SimpleButton} from './edit-crop.styled';

const createSchema = (key: string) =>
  Yup.object().shape({
    [key]: Yup.string().required(),
  });

export const InlineEditForm = ({
  data,
  dataKey,
  onClose,
}: {
  data: any;
  dataKey: string;
  onClose?: () => void;
}) => {
  const dispatch = useDispatch();

  const id = useMemo(() => genKey(), []);
  const [isColorPicker, setIsColorPicker] = useState(false);
  const {
    handleSubmit,
    control,
    formState: {errors, isDirty},
    reset,
    setValue,
    watch,
  } = useForm<{
    [key: string]: string;
  }>({
    resolver: yupResolver(createSchema(dataKey)),
    mode: 'onChange',
    defaultValues: {[dataKey]: data?.[dataKey] || ''},
  });

  const watchValue = watch(dataKey);

  const onSave = useCallback(
    async (payload: {[key: string]: string}) => {
      if (isDirty || (dataKey === 'color' && payload.color !== data.color)) {
        try {
          const response = await AdminApi.updateCrop([{...data, ...payload}]);

          const resultData = response.data.result?.[0];

          if (resultData) {
            dispatch(updateCropType(resultData));
          }

          showNotification({
            title: t({id: 'note.success', defaultMessage: 'Success'}),
            message: 'Crop was saved.',
            type: 'success',
          });

          onClose?.();
        } catch (e) {
          showNotification({
            title: t({id: 'note.error', defaultMessage: 'Error'}),
            message: e.message,
            type: 'error',
          });
        }

        reset(data);
      }
    },
    [isDirty, data, dataKey]
  );

  return (
    <form onSubmit={handleSubmit(onSave)} style={{display: 'flex', alignItems: 'center'}}>
      <Controller
        name={dataKey}
        control={control}
        render={({field: {value, onChange}}) => (
          <>
            {dataKey === 'color' ? (
              <DropdownMenu
                id={id}
                simplifiedMenu={false}
                listHeightRestricted={false}
                menuItems={
                  <li>
                    <SketchPicker
                      color={watchValue}
                      onChange={color => {
                        setValue('color', color.hex);
                      }}
                    />
                  </li>
                }
                centered
                onVisibilityChange={visible => {
                  if (!visible) {
                    handleSubmit(onSave)();
                  }

                  setIsColorPicker(visible);
                }}
                visible={isColorPicker}
              >
                <AccessibleFakeButton>
                  <div
                    style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-start'}}
                  >
                    <div style={{width: 15, height: 15, background: data.color || '#efefef'}} />
                    <div style={{marginLeft: 10}}>{data.color || '#efefef'}</div>
                  </div>
                </AccessibleFakeButton>
              </DropdownMenu>
            ) : (
              <TextField
                style={{minWidth: 100}}
                id={`crops-${id}`}
                // onBlur={handleSubmit(onSave)}
                label=""
                ref={ref => {
                  setTimeout(() => {
                    //@ts-expect-error error leftover from convertion to strict mode, please fix
                    const input = ref?._container?.querySelector('input');

                    input?.focus();
                  }, 0);
                }}
                value={value}
                onChange={onChange}
                error={!!errors?.[dataKey]}
                // errorText="Field is required"
              />
            )}
          </>
        )}
      />

      {dataKey !== 'color' ? (
        <div style={{display: 'flex'}}>
          <SimpleButton primary style={{marginLeft: 5}} type="submit">
            <FontIcon>check</FontIcon>
          </SimpleButton>

          {onClose ? (
            <SimpleButton
              type="button"
              onClick={e => {
                e.stopPropagation();
                onClose();
              }}
              style={{marginLeft: 5}}
            >
              <FontIcon>close</FontIcon>
            </SimpleButton>
          ) : null}
        </div>
      ) : null}
    </form>
  );
};
