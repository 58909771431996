import {t} from 'i18n-utils';
import React, {useState} from 'react';
import moment from 'moment';
import type {ItemUserActivity} from '../types';
import type {MeasurementSystem} from '_utils';
import {formatBigNumber, convertUnit} from '_utils';
import SortButton from 'components/sort-button';
import {TABLE_COLUMNS} from '../constants';
import {Link} from 'react-router-dom';
import {history} from '_utils/history-utils';

import {
  FluroTableRow,
  FluroTableColumn,
  FluroTableBody,
  FluroTableHeader,
  FluroDataTable,
} from 'components';
import {AdminContentWrapper} from '../../admin.styled';

type Props = {
  list: ItemUserActivity[];
  measurement: MeasurementSystem;
};

type Sort = {
  value: string;
  direction: boolean;
};

export const sortBy = (list: ItemUserActivity[], {value, direction}: Sort): ItemUserActivity[] => {
  let sortedItems = list;

  if (value === 'user') sortedItems = list.sort((a, b) => `${a.name}`.localeCompare(`${b.name}`));
  if (value === 'email')
    sortedItems = list.sort((a, b) => `${a.email}`.localeCompare(`${b.email}`));
  if (value === 'created_at' || value === 'last_activity')
    sortedItems = list.sort((a, b) => {
      if (moment(a[value]).isBefore(moment(b[value]))) return -1;
      if (moment(a[value]).isAfter(moment(b[value]))) return 1;
      return 0;
    });
  if (value === 'sessions' || value === 'fields_area_ha')
    sortedItems = list.sort((a, b) => a[value] - b[value]);

  return direction ? sortedItems : sortedItems.reverse();
};

const UserActivityTable = React.memo(({list, measurement}: Props) => {
  const [sortParams, setSortParams] = useState({value: 'last_activity', direction: false});

  const setSorting = (value: string) => {
    const direction = value === sortParams.value ? !sortParams.direction : true;
    setSortParams(() => ({value, direction}));
  };
  const sortedList = sortBy(list, sortParams);

  const TableHeader = (
    <FluroTableRow>
      {TABLE_COLUMNS.map((el: any, i: number) => (
        <FluroTableColumn
          sortIconBefore={false}
          sorted={true}
          type={el.type}
          nowrap={!!el.nowrap}
          sortIcon={
            <SortButton
              selected={sortParams.value === el.property}
              descending={sortParams.value === el.property ? sortParams.direction : false}
            />
          }
          onClick={() => setSorting(el.property)}
          key={`${i}element${el.property}`}
          className={`user-activity__table-column user-activity__table-column--${el.property}`}
        >
          {el.name}
        </FluroTableColumn>
      ))}
    </FluroTableRow>
  );

  // for avoiding twice push history
  const stop = (e: any) => e.stopPropagation();

  const TableBody = sortedList.map((data, i: number) => {
    const {
      name,
      surname,
      email,
      created_at,
      last_activity,
      sessions,
      fields,
      farms,
      fields_area_ha,
      id,
    } = data;
    const formattedArea = convertUnit(measurement, 'ac', fields_area_ha);
    return (
      <FluroTableRow
        onClick={() => history.push(`/admin/users/${id}`)}
        className={`user-activity__table-row`}
        key={i}
      >
        <FluroTableColumn className={'user-activity__table-column--user'}>
          <Link onClick={stop} to={`/admin/users/${id}`}>{`${name} ${surname}`}</Link>
        </FluroTableColumn>
        <FluroTableColumn className={'user-activity__table-column--email'}>
          {email}
        </FluroTableColumn>
        <FluroTableColumn type={'date'} className={'user-activity__table-column--registration'}>
          {moment(created_at).format('DD MMM YYYY')}
        </FluroTableColumn>
        <FluroTableColumn type={'date'} className={'user-activity__table-column--last'}>
          {moment(last_activity).format('DD MMM YYYY')}
        </FluroTableColumn>
        <FluroTableColumn type={'number'} className={'user-activity__table-column--sessions'}>
          {sessions}
        </FluroTableColumn>
        <FluroTableColumn className={'user-activity__table-column--acreage'}>
          <div>
            {`${farms} farm${farms > 1 ? 's' : ''}`} {`${fields} field${fields > 1 ? 's' : ''}`}
          </div>
          <div>{`${formatBigNumber(formattedArea)} ${t({id: measurement})}`}</div>
        </FluroTableColumn>
      </FluroTableRow>
    );
  });

  return (
    <AdminContentWrapper>
      <FluroDataTable>
        <FluroTableHeader>{TableHeader}</FluroTableHeader>
        <FluroTableBody>{TableBody}</FluroTableBody>
      </FluroDataTable>
    </AdminContentWrapper>
  );
});

export default UserActivityTable;
