import styled from 'styled-components';

export const Wrapper = styled.div`
  .md-table-column:first-child {
    padding-left: 5px;
  }

  .md-avatar {
    width: 28px;
    height: 28px;
  }

  .md-table-column {
    padding-right: 16px;
  }

  .md-table-row--hover {
    background: #fff;
  }
`;

export const SearchInputWrapper = styled.label`
  background-color: #fff;
  display: flex;
  align-items: center;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  padding: 5px 10px;
  width: 68%;

  input {
    border: none;
    outline: none;
  }

  .md-icon {
    margin-right: 3px;
  }
`;

export const TopControlWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;

  .lang-select {
    width: 30%;
    max-width: 350px;
  }
`;

type SimpleButtonProps = {
  primary?: boolean;
};

export const SimpleButton = styled.button`
  border: 1px solid ${(p: SimpleButtonProps) => (p.primary ? '#43a047' : 'rgba(0,0,0,0.1)')};
  color: ${(p: SimpleButtonProps) => (p.primary ? '#fff' : 'rgba(0, 0, 0, 0.87)')};
  background-color: ${(p: SimpleButtonProps) => (p.primary ? '#43a047' : '#fff')};
  border-radius: 2px;
  cursor: pointer;
  align-items: center;
  display: flex;

  .md-icon {
    color: ${(p: SimpleButtonProps) => (p.primary ? '#fff' : '#757575')};
  }
`;
