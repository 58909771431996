// @ts-nocheck
import {t} from 'i18n-utils';
import React from 'react';
import {Button} from 'react-md';
import moment from 'moment';
import {toggleFieldGeometries} from '../../actions';
import {
  createAreaOfInterest,
  getAndSetAreasOfInterestMeanIndex,
} from '../../actions/areas-of-interest-actions';
import {
  changeLowPerfAnomalyProp,
  toggleLowPerformingAreas,
  saveLowPerfAnomalies,
  setUploadingAnomaliesStatus,
} from '../../actions/anomalies-actions';
import {showNotification} from 'components/notification/notification';
import {userFacingArea} from './anomalies-utils';
import AnomalyItem from 'components/anomalies-ui/anomaly-item';
import AnomalyLabelsDropdown from './labels-dropdown';
import type {IAnomaly, TAnomalyProps} from './types';
import ReadOnly from 'components/read-only-container';
import Mixpanel from '_utils/mixpanel-utils';
import {RequestStatus} from '../../../../types';
import {useAppDispatch, useAppSelector} from '../../../../_hooks';
import {selectAnomalies, selectLowPerformingAnomalies} from './anomalies-selectors';
import {
  selectCurrentDate,
  selectCurrentImageName,
  selectGeometriesOnMap,
  selectIsReadOnly,
} from '../../reducer/selectors';
import {selectMeasurement} from '../../../login/login-selectors';

const emptyFunc = (): null => null;

const LowPerfAnomalies = () => {
  const dispatch = useAppDispatch();
  const lowPerfAnomalies = useAppSelector(selectLowPerformingAnomalies);
  const currentDate = useAppSelector(selectCurrentDate);
  const measurement = useAppSelector(selectMeasurement);
  const isReadOnly = useAppSelector(selectIsReadOnly);
  const geometriesOnMap = useAppSelector(selectGeometriesOnMap);
  const {uploadingAOIStatus} = useAppSelector(selectAnomalies);
  const currentImageName = useAppSelector(selectCurrentImageName);

  const getGeometries = () => {
    const {list} = lowPerfAnomalies;
    return list.uploadedROI?.features
      ? list.uploadedROI
      : list[currentImageName] || {features: [] as any, savedAsRoi: false};
  };

  const onSaveLowPerfAnomalies = () => {
    const geometries = getGeometries();

    if (geometries.savedAsRoi) {
      saveAnomaliesAsROI(geometries);
    } else {
      if (!geometries.features.some((el: IAnomaly) => el.properties.label)) {
        showNotification({
          title: t({id: 'note.info', defaultMessage: 'Info'}),
          message: t({
            id: 'Please label the shapes before saving them.',
            defaultMessage: 'Please label the shapes before saving them.',
          }),
          type: 'warning',
        });
        return;
      }
      dispatch(saveLowPerfAnomalies(currentDate, geometries, true));
      closeAnomaliesTab();
    }
  };

  const saveAnomaliesAsROI = (visibleGeometries: {features: IAnomaly[]}) => {
    dispatch(setUploadingAnomaliesStatus(RequestStatus.Loading));
    Promise.all(
      visibleGeometries.features.map((f: IAnomaly) => {
        const geometry = {
          type: 'Feature',
          geometry: {...f.geometry},
          properties: {
            ...f.properties,
            isLowPerf: false,
            saved: true,
            uploading: false,
          },
        };
        Mixpanel.addGeometry('Upload');
        return dispatch(createAreaOfInterest(geometry, visibleGeometries.features.length > 1));
      })
    )
      .then(() => {
        dispatch(setUploadingAnomaliesStatus(RequestStatus.Success));
        dispatch(getAndSetAreasOfInterestMeanIndex());
        closeAnomaliesTab();
        !geometriesOnMap && dispatch(toggleFieldGeometries(true)); // toggle geometries on if they are not toggled
      })
      .catch(() => {
        dispatch(setUploadingAnomaliesStatus(RequestStatus.Success));
      });
  };

  const onChangeLowPerfAnomalyProp = (anomaly: IAnomaly, prop: keyof TAnomalyProps, value: any) => {
    dispatch(changeLowPerfAnomalyProp(anomaly, prop, value));
  };

  const closeAnomaliesTab = () => {
    dispatch(toggleLowPerformingAreas(false));
  };

  const visibleGeometries = getGeometries().features || [];
  const uploadProcess = visibleGeometries?.[0]?.savedAsRoi;
  const isUploading = uploadingAOIStatus === RequestStatus.Loading;

  if (!lowPerfAnomalies.isVisible) return null;

  return (
    <div id={'anomalies'} className={'geometry-section low-perf-anomalies'}>
      <h3 className={'anomaly-header__title'}>
        {uploadProcess
          ? t(
              {
                id: 'Shape uploaded',
                defaultMessage: '{count, plural, one {Shape} other {Shapes}} uploaded',
              },
              {count: visibleGeometries}
            )
          : t({id: 'Low performing areas', defaultMessage: 'Low performing areas'})}
      </h3>

      {visibleGeometries.length ? (
        <>
          {!uploadProcess && (
            <div className="low-perf-anomalies__message">
              {t({
                id: 'Only labeled areas will be saved',
                defaultMessage: 'Only labeled areas will be saved',
              })}
            </div>
          )}
          {visibleGeometries.map((anomaly: IAnomaly, i: number) => (
            <AnomalyItem
              label={
                <AnomalyLabelsDropdown
                  disabled={isReadOnly}
                  anomaly={anomaly}
                  label={anomaly.properties.label}
                  miniButton
                  onChange={(prop: keyof TAnomalyProps, value: any, geometry: IAnomaly) =>
                    onChangeLowPerfAnomalyProp(geometry, prop, value)
                  }
                  width={200}
                  className={'label-drop-down'}
                  position={'br'}
                />
              }
              startDate={anomaly.properties.startDate}
              endDate={anomaly.properties.endDate}
              isChecked={!!anomaly.properties.checked}
              area={userFacingArea(anomaly.properties.area, measurement)}
              key={`${anomaly.properties.area}-${i}`}
              labelName={anomaly.properties.label}
              onCheck={value => onChangeLowPerfAnomalyProp(anomaly, 'checked', value)}
              title={''}
              onView={emptyFunc}
              openPopUp={false}
              measurement={measurement}
            />
          ))}
          <div className={'buttons'}>
            <ReadOnly>
              <Button
                id={'save-geometries-btn'}
                primary
                className="element-full-width"
                raised
                disabled={!visibleGeometries || isUploading}
                onClick={onSaveLowPerfAnomalies}
              >
                Save
              </Button>
            </ReadOnly>

            <Button className="element-full-width" onClick={closeAnomaliesTab} raised>
              {t({id: 'Cancel', defaultMessage: 'Cancel'})}
            </Button>
          </div>
        </>
      ) : (
        <div className={'low-perf-anomalies__message'}>
          {t(
            {
              id: 'No low performing areas detected on',
              defaultMessage: 'No low performing areas detected on {date}',
            },
            {date: moment(currentDate, 'DD/MM/YYYY').format('DD MMMM YYYY')}
          )}
        </div>
      )}
    </div>
  );
};

export default LowPerfAnomalies;
