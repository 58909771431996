// @ts-nocheck
import {reportError} from 'containers/error-boundary';
import {useEffect, useRef} from 'react';

/**
 * Wait for the callback promise to resolve. Then wait ms before running again.
 * This continues indefinitely, the callback will unsubscribe when `shouldRun` is false.
 *
 * TODO - pass a dependency array
 *
 * For now `callback` needs to be a value from a useCallback hook.
 */
export function useRecursivePoll<T>(
  callback: () => Promise<T>,
  ms: number,
  shouldRun: boolean
): void {
  const callbackRef = useRef<() => Promise<T>>();
  const shouldRunRef = useRef(shouldRun);

  useEffect(() => {
    callbackRef.current = callback;
  }, [callback]);

  useEffect(() => {
    shouldRunRef.current = shouldRun;
  }, [shouldRun]);

  useEffect(() => {
    let timeout: NodeJS.Timeout;

    async function recursivePoll() {
      if (!shouldRunRef.current) {
        return;
      }

      try {
        await callbackRef.current();
        timeout = setTimeout(recursivePoll, ms);
      } catch (error) {
        reportError(error);
      }
    }

    recursivePoll();

    return () => {
      clearTimeout(timeout);
    };
  }, [ms, shouldRun]);
}
