import type {ComponentType} from 'react';
import React, {useContext, useEffect, useState} from 'react';
import {TextField} from 'react-md';
import type {InputComponentProps} from './input-component';
import {InputContext} from './input-context';

type Props = {
  onChange: InputComponentProps['onChange'];
  totalDecimalPlaces?: number;
};

export const InputNumber: ComponentType<Props> = ({onChange, totalDecimalPlaces = 1}) => {
  const {id, value, label, placeholder, disabled} = useContext(InputContext);
  const [localValue, setLocalValue] = useState(sanitizeValue(value, totalDecimalPlaces));

  // Update the local value after the bulk edit.
  useEffect(() => {
    if (value !== localValue) {
      setLocalValue(sanitizeValue(value, totalDecimalPlaces));
    }
  }, [value]);

  function change(v: string) {
    if (v.split('.')[1]?.length > totalDecimalPlaces) {
      return;
    }

    const formattedValue = v
      // Remove anything that isn't valid in a positive number
      .replace(/[^\d.]/g, '')
      // Remove the last period if there is already one
      .replace(/(\..*)\.$/, '$1');

    setLocalValue(formattedValue);

    // Just update the draft, don't update the server value yet.
    onChange(formattedValue, false);
  }

  const update = () => {
    // Update the server value.
    onChange(localValue);
  };

  return (
    <TextField
      type={'text'}
      id={id}
      value={localValue || ''}
      label={label}
      placeholder={placeholder}
      disabled={disabled}
      onChange={v => change(v as string)}
      onKeyUp={e => e.code === 'Enter' && update()}
      onBlur={update}
      className={'input-dry-yield'}
    />
  );
};

/**
 * If the server returns a value with more than totalDecimalPlaces,
 * convert the value to have the correct number of decimal places.
 */
const sanitizeValue = (value: string, totalDecimalPlaces?: number) => {
  if (typeof value === 'string' && value.indexOf('.') !== -1) {
    return Number(value).toFixed(totalDecimalPlaces);
  }
  return value;
};
