import axios from 'axios';
import config from '_environment';
import cancelTokenStore from './cancel-tokens-store';

export default class {
  // YYYYMMDD
  static getRangeByCropType(
    fieldMD5: string,
    startDate: string,
    endDate: string,
    cropType: string = 'cotton'
  ) {
    cancelTokenStore.cancel('loadFieldWeather');
    const source = cancelTokenStore.generateSource('loadFieldWeather');

    return axios.get(
      `${config.baseUrl}services/weather/field_weather/${fieldMD5}?from=${startDate}&till=${endDate}&crop=${cropType}`,
      {cancelToken: source.token, params: {__skipPreloader: true}}
    );
  }
}
