import type {ComponentType} from 'react';
import React, {useMemo} from 'react';
import {batch} from 'react-redux';
import {useAppDispatch, useAppSelector} from '_hooks';
import {naturalSortAlphaNum} from '_utils/sorters';
import {Flex} from 'components';
import {AreaSelectMenu} from 'containers/map/features/sustainability-insights/area-select/area-select-menu';
import type {Props as OptionsGroupsProps} from 'components/grouped-selection-control/options-group';
import {
  selectReportSourcingRegionFilter,
  selectReportAvailableSourcingRegions,
  selectReportAreAllSourcingRegionsSelected,
} from 'containers/si/module/selectors';
import {
  setDashboardPageIndex,
  setDashboardSourcingRegionFilter,
  toggleDashboardSourcingRegionFilter,
} from 'containers/si/module/reducer';

const dropdownStyles = {width: 'max-content', paddingBottom: 0, paddingTop: '5px'};

export const SourcingRegionsFilter: ComponentType = () => {
  const dispatch = useAppDispatch();
  const availableSourcingRegions = useAppSelector(selectReportAvailableSourcingRegions);

  const selected = useAppSelector(selectReportSourcingRegionFilter);
  const allSelected = useAppSelector(selectReportAreAllSourcingRegionsSelected);

  const controls = useMemo(() => {
    const areaSets = Object.entries(availableSourcingRegions).map(area => {
      const [areaId, label] = area;

      return {
        label: label,
        value: areaId,
      };
    });

    return naturalSortAlphaNum(areaSets, 'label');
  }, [availableSourcingRegions]);

  const handleClear = () => {
    dispatch(setDashboardSourcingRegionFilter([]));
  };

  const handleSelect: OptionsGroupsProps['onChange'] = selectedChange => {
    if (typeof selectedChange === 'boolean') {
      //Means we clicked select all button
      batch(() => {
        dispatch(
          setDashboardSourcingRegionFilter(
            selectedChange ? Object.keys(availableSourcingRegions) : []
          )
        );
        dispatch(setDashboardPageIndex(0));
      });
      return;
    } else {
      //Means we clicked any other value
      batch(() => {
        dispatch(toggleDashboardSourcingRegionFilter(selectedChange.value));
        dispatch(setDashboardPageIndex(0));
      });
    }
  };

  return (
    <Flex alignItems="center" nowrap className="margin-right-12">
      <AreaSelectMenu
        className="sourcing-region-filter-menu"
        allowSelectAll
        allowSearch
        allLabel={`Select all sourcing regions`}
        selectedLabel={'sourcing regions'}
        options={controls}
        selected={selected.map(String)}
        disabled={false}
        allSelected={allSelected}
        onSelect={handleSelect}
        onClear={handleClear}
        style={dropdownStyles}
      />
    </Flex>
  );
};
