import {createSelector} from '@reduxjs/toolkit';
import chroma from 'chroma-js';
import {sortByKeyReverseUnsafe} from '_utils/sorters';
import {calculatePercentage, createCachingSelector} from '_utils/pure-utils';
import {SUMMARY_BARS_PREVIEW_THRESHOLD} from '../constants';
import type {
  AreaId,
  GroupedSummaryChartData,
  MetricYieldStatDatum,
  SIStateChildMeta,
  SocGhgSummaryChartData,
} from '../types';
import {SIGeometryFilterType} from '../types';
import {calculateScale, isInRange, parseAreaId} from '../utils';
import {
  getAreaId,
  selectActiveGeometriesIds,
  selectAggLevel,
  selectAreaUnits,
  selectCurrentMeta,
  selectGeometriesColors,
  selectIsYearsDiff,
  selectMetrics,
  selectSIChartGroupingType,
  selectSIFilter,
  selectStatesMeta,
  selectYearsFilterRange,
} from './selectors';
import groupBy from 'lodash/groupBy';
import {
  classifyExtremaPerUnitsFromValues,
  classifySocGhgStateStats,
  getDefaultExtremaPerUnits,
} from 'containers/map/features/sustainability-insights/filters-panel/cards/utils';

export const selectFertilizerCardState = createSelector(
  [selectSIFilter],
  f => f[SIGeometryFilterType.Fertilizer]
);
export const selectFertilizerChartTab = createSelector(
  [selectFertilizerCardState],
  cardState => cardState.chartTab
);
export const selectFertilizerCardRange = createSelector(
  [selectFertilizerCardState],
  cardState => cardState.range
);

export const selectFertilizerStats = createSelector(
  [
    selectActiveGeometriesIds,
    selectMetrics,
    selectYearsFilterRange,
    selectGeometriesColors,
    selectCurrentMeta,
  ],
  (activeIds, data, yearsRange, colorsMap, currentMeta): Record<number, MetricYieldStatDatum> => {
    if (!data || !activeIds?.length || !yearsRange?.length) return {};

    const numValuesPerId: Record<AreaId, {totalValue: number; totalArea: number}> = {}; // store values per id to prevent double calculations
    let totalValues = 0; // classify total values sum to calculate percentage later

    activeIds.forEach(id => {
      const dataPerYear = data[id];
      if (!dataPerYear) {
        return;
      }
      let fertilizerValuePerEntityAndYears = 0;
      let fertilizerAreaPerEntityAndYears = 0;

      yearsRange.forEach((year: number) => {
        fertilizerValuePerEntityAndYears =
          fertilizerValuePerEntityAndYears + Number(dataPerYear[year]?.fertilizer); // get total value per years
        fertilizerAreaPerEntityAndYears =
          fertilizerAreaPerEntityAndYears + Number(dataPerYear[year]?.kpi_area_ha); //  get total area per years
      });

      if (!fertilizerValuePerEntityAndYears) return;
      numValuesPerId[id] = {
        totalValue: fertilizerValuePerEntityAndYears,
        totalArea: fertilizerAreaPerEntityAndYears,
      };
      totalValues = totalValues + fertilizerValuePerEntityAndYears;
    });

    const stats = activeIds.reduce<{[key: number]: MetricYieldStatDatum}>((acc, id: number) => {
      const dataPerYear = data[id];
      const entityTotalValue = numValuesPerId[id]?.totalValue;
      const entityTotalArea = numValuesPerId[id]?.totalArea;

      if (!dataPerYear || entityTotalValue === undefined) {
        return acc;
      }

      const {name, statefp} = (currentMeta[id] || {}) as SIStateChildMeta;

      acc[id] = {
        // classify a chart entity
        id,
        name,
        statefp,
        values: {
          num: entityTotalValue,
          pct: calculatePercentage(entityTotalValue, totalValues),
          rawNumValue: entityTotalValue, // use to get later the total values
          area: entityTotalArea,
        },
        color: colorsMap[id],
      };

      return acc;
    }, {});

    return stats;
  }
);

export const selectFertilizerStateStats = createSelector(
  [selectAggLevel, selectFertilizerStats],
  (aggLevel, stats) => classifySocGhgStateStats(aggLevel, stats)
);

export const selectActiveFertilizerStatsList = createSelector(
  [selectActiveGeometriesIds, selectFertilizerStats],
  (ids, stats) => {
    if (!stats) return [];
    return ids.reduce((acc: MetricYieldStatDatum[], id: number) => {
      const stat = stats[id];
      if (!stat) return acc;
      return [...acc, stat];
    }, []);
  }
);

export const selectFertilizerStatsById = createSelector(
  [selectFertilizerStats, getAreaId],
  (stats, id) => stats?.[id]
);

type Extrema = {num: {min: number; max: number}; pct: {min: number; max: number}};

export const selectFertilizerExtremaPerUnits = createSelector(
  [selectActiveFertilizerStatsList],
  (stats): Extrema => {
    if (!stats?.length) {
      return getDefaultExtremaPerUnits();
    }

    const values: {num: number[]; pct: number[]} = {num: [], pct: []};

    stats.forEach(stat => {
      const numStat = stat?.values?.num || 0;
      const pctStat = stat?.values?.pct || 0;
      values.num.push(numStat);
      values.pct.push(pctStat);
    });

    return classifyExtremaPerUnitsFromValues(values);
  }
);
export const selectFertilizerGroupedExtremaPerUnits = createSelector(
  [selectActiveFertilizerStatsList, selectFertilizerStateStats, selectSIChartGroupingType],
  (stats, stateStats, groupingType): Extrema => {
    // get min/max values including grouped state values that might be higher than not grouped
    const values: {num: number[]; pct: number[]} = {num: [], pct: []};

    if (!stats.length || groupingType !== 'grouped' || !stateStats)
      return getDefaultExtremaPerUnits();

    stats.forEach(stat => {
      const numStat = stat?.values?.num || 0;
      const pctStat = stat?.values?.pct || 0;
      values.num.push(numStat);
      values.pct.push(pctStat);
    });

    Object.values(stateStats).forEach(stat => {
      const numStat = stat?.num || 0;
      const pctStat = stat?.pct || 0;
      values.num.push(numStat);
      values.pct.push(pctStat);
    });

    return classifyExtremaPerUnitsFromValues(values);
  }
);

export const selectFertilizerStatsExtrema = createSelector(
  [selectAreaUnits, selectFertilizerExtremaPerUnits],
  (units, extrema) => extrema[units]
);
export const selectFertilizerGroupedStatsExtrema = createSelector(
  [selectAreaUnits, selectFertilizerGroupedExtremaPerUnits],
  (units, extrema) => extrema[units]
);

export const selectFertilizerColorScaleValues = createSelector(
  [selectFertilizerStatsExtrema],
  ({min, max}) => {
    if (max <= 0) {
      return {colors: ['#37681c', '#fff']};
    } else if (min >= 0) {
      return {colors: ['#fff', '#37681c']};
    }

    const zero = calculateScale(0, min, max);

    return {colors: ['red', 'white', '#37681c'], domain: [0, zero, 1]};
  }
);

export const selectFertilizerColorScale = createSelector(
  [selectIsYearsDiff, selectFertilizerColorScaleValues],
  (isDiff, {colors, domain}) => {
    // if (!isDiff) return {colors: ['#ffffff', '#37681c']};

    return {colors, domain};
  }
);

export const selectFertilizerChromaScale = createSelector(
  selectFertilizerColorScale,
  ({colors, domain}) => {
    const scale = chroma.scale(colors);
    if (domain) {
      scale.domain(domain);
    }

    return scale;
  }
);

export const selectFertilizerColorById = createSelector(
  [
    selectFertilizerStatsById,
    selectFertilizerStatsExtrema,
    selectFertilizerChromaScale,
    selectAreaUnits,
  ],
  (stats, {max, min}, colorScale, units) => {
    if (!stats) return;
    const value = stats.values?.[units];
    const scale = calculateScale(value, min, max);
    const color = colorScale(scale).css();

    return color;
  }
);

export const selectFertilizerSummaryChartData = createSelector(
  [
    selectActiveFertilizerStatsList,
    selectFertilizerStateStats,
    selectFertilizerCardRange,
    selectAreaUnits,
    selectStatesMeta,
    selectSIChartGroupingType,
  ],
  (stats, stateStats, range, units, statesMeta, groupingType): SocGhgSummaryChartData | null => {
    if (!stats?.length) return null;
    let totalValue = 0;
    let totalArea = 0;
    let values: number[] = [];
    const valuesById: Record<number, number> = {};
    let chartData = stats;
    const chartGeometries: Record<string, boolean> = {};
    const chartGeometriesIds: number[] = [];

    chartData.forEach(stat => {
      const value = stat.values[units] || 0;
      const rawNumValue = stat.values?.rawNumValue || 0;
      const area = stat.values?.area || 0;
      stat.value = value;

      valuesById[stat.id] = value;

      values = values.concat(value);
      totalValue = totalValue + rawNumValue;
      totalArea = totalArea + area;
    });

    // Apply filters for chart data
    const preview = chartData.length > SUMMARY_BARS_PREVIEW_THRESHOLD;
    if (preview && range) {
      chartData = chartData.filter(d => isInRange(Number(d.value), range));
    }

    chartData.forEach(stat => {
      // add geometries after range filter
      chartGeometries[stat.id] = true;
      chartGeometriesIds.push(stat.id);
    });

    if (groupingType === 'grouped') {
      const groupedByState = groupBy(sortByKeyReverseUnsafe(chartData, 'value'), 'statefp');
      chartData = sortByKeyReverseUnsafe(
        Object.keys(groupedByState).reduce((acc: GroupedSummaryChartData[], stateFP: string) => {
          const value = stateStats?.[Number(stateFP)]?.[units];
          if (!value) return acc;
          const name = statesMeta[parseAreaId(stateFP)]?.name?.toUpperCase?.();
          const data = groupedByState[stateFP];

          return [
            ...acc,
            {
              data,
              name,
              value,
            } as GroupedSummaryChartData,
          ];
        }, []),
        'value'
      );
    }

    chartData = sortByKeyReverseUnsafe(chartData, 'value');

    return {
      type: groupingType,
      chartData,
      values,
      valuesById,
      preview,
      totalValue: (totalValue * totalArea) / 1000000,
      chartGeometries,
      chartGeometriesIds,
    };
  }
);

export const selectFertilizerGeometryValueById = createCachingSelector(
  [selectFertilizerSummaryChartData, getAreaId],
  (summaryData, id) => summaryData?.valuesById?.[id]
);

export const isGeometryInFertilizerSummaryChart = createSelector(
  [selectFertilizerSummaryChartData, getAreaId],
  (data, id) => Boolean(data?.chartGeometries?.[id])
);
