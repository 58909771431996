import {isDefined} from '_utils/typeGuards';
import type {
  GroupSelectedEvent,
  OptionSelectedEvent,
} from 'components/grouped-selection-control/options-group';
import _ from 'lodash';

export const getNewIdsFromSelectedChange =
  ({selectedIds, allAvailableIds}: {selectedIds: Array<number>; allAvailableIds: Array<number>}) =>
  (selectedChange: boolean | GroupSelectedEvent | OptionSelectedEvent) => {
    // Select all clicked
    if (typeof selectedChange === 'boolean') {
      return selectedChange ? allAvailableIds : [];
    } else {
      // If values array exists, use that, otherwise use the value
      const values = isDefined(selectedChange.values)
        ? selectedChange.values
        : isDefined(selectedChange.value)
        ? [selectedChange.value]
        : [];

      // Sometimes the values returned are strings and sometimes they are numbers; ensure numbers
      const numericSelectedChange = values.map(Number);

      // sets selected values based on selected prop and the values array
      const newIds = selectedChange.selected
        ? _.union(selectedIds, numericSelectedChange)
        : _.difference(selectedIds, numericSelectedChange);

      return newIds;
    }
  };
