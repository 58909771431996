// a copy of src/containers/map/features/sustainability-insights/years-select.tsx

import type {ComponentType} from 'react';
import React, {useState} from 'react';
import {Flex, FluroButton} from 'components';
import {List, ListItem, SelectField, SelectionControl} from 'react-md';
import {calcPlural} from '_utils/pure-utils';
import uniq from 'lodash/uniq';
import {FluroChipDropdownControllable as FluroChipDropdown} from 'components/fluro-dropdown/fluro-chip-dropdown';
import {t} from 'i18n-utils';

type YearsInputProps = {
  defaultValue: number[];
  yearsAvailable: number[];
  onChange: (range: number[]) => void;
};

const YearsInput: ComponentType<YearsInputProps> = ({
  defaultValue = [],
  onChange,
  yearsAvailable,
}) => {
  // const rangeAvailable = yearsAvailable?.length > 1;
  const rangeAvailable = false; // For now, no range selection
  const [enableRange, setEnableRange] = useState(
    rangeAvailable && Number.isFinite(defaultValue[0]) && Number.isFinite(defaultValue[1])
  );
  const [value, setValue] = useState<number[]>(defaultValue);

  const handleChange = () => {
    const update = uniq(value.filter(Boolean));
    onChange(update);
  };

  const [yearFrom, yearTo] = value;
  const input = enableRange ? (
    <>
      <Flex justifyContent="space-between" className="mb-2" nowrap>
        <SelectField
          id="years-select-year-from"
          name="years-select-year-from"
          menuItems={yearsAvailable}
          value={yearFrom}
          onChange={year => {
            const newValue = [yearFrom, yearTo || yearFrom];
            if (newValue[1] < Number(year)) {
              setValue([Number(year), Number(year)]);
            } else {
              newValue[0] = Number(year);
              setValue(newValue);
            }
          }}
        />
        <SelectField
          key={yearFrom}
          id="years-select-min-year-to"
          name="years-select-min-year-to"
          menuItems={yearsAvailable.filter(year => year >= yearFrom)}
          value={yearTo || yearFrom}
          onChange={year => {
            const newValue = [...value];
            newValue[1] = Number(year);
            setValue(newValue);
          }}
        />
      </Flex>
      <Flex justifyContent="center">
        <FluroButton flat onClick={handleChange}>
          APPLY
        </FluroButton>
      </Flex>
    </>
  ) : (
    <List>
      {yearsAvailable.map(y => {
        const isActive = [yearFrom, yearTo].includes(y);

        return (
          <ListItem
            key={y}
            primaryText={y}
            primaryTextStyle={{color: isActive ? 'green' : 'black'}}
            role="button"
            onClick={() => {
              onChange([y]);
            }}
          />
        );
      })}
    </List>
  );

  return (
    <>
      {rangeAvailable && (
        <SelectionControl
          type="switch"
          id="range-switch"
          label="DATE RANGE"
          defaultChecked={enableRange}
          name="range-switch"
          onChange={checked => {
            if (!checked) setValue([yearFrom]);
            setEnableRange(Boolean(checked));
          }}
        />
      )}
      {input}
    </>
  );
};

type YearsSelectProps = {
  selectedYears: Array<number>;
  allAvailableYears: Array<number>;
  onChange: (years: Array<number>) => void;
};

export const YearsSelect = ({selectedYears, allAvailableYears, onChange}: YearsSelectProps) => {
  const [expand, setExpand] = useState(false);

  // Make a list of years from the program start and end dates
  const yearsAvailable = [...Array(allAvailableYears[1] - allAvailableYears[0] + 1).keys()].map(
    i => i + allAvailableYears[0]
  );

  const handleChange = (newYears: number[]) => {
    setExpand(false);
    onChange(newYears);
  };

  if (!selectedYears) return null;
  const btnLabel = `${t({id: `Crop ${calcPlural('year', selectedYears)}`})}  ${selectedYears
    .filter(Boolean)
    .join(' - ')}`;

  const [yearFrom, yearTo] = selectedYears;

  return (
    <FluroChipDropdown
      expand={expand}
      setExpand={setExpand}
      label={btnLabel}
      dropdownStyle={{overflow: 'visible'}}
    >
      <YearsInput
        yearsAvailable={yearsAvailable}
        defaultValue={[yearFrom, yearTo]}
        onChange={handleChange}
      />
    </FluroChipDropdown>
  );
};
