import React, {useState} from 'react';
import {useForm, Controller} from 'react-hook-form';
import Yup from 'yup';
import {TextField, Button, FontIcon} from 'react-md';
import {FarmerIcon} from 'components/svg-icons';
import {genKey} from '_utils/pure-utils';
import {yupResolver} from '@hookform/resolvers/yup';

type IProps = {
  addUserEmail: (email: string[], resetForm: () => void) => void;
};

type FormData = {
  email: string[];
};

type IStateField = {id: string; value: string};

const InviteUserSchema = Yup.object({
  email: Yup.array().of(
    Yup.string().email('Email must be valid').required('Email is a required field')
  ),
});

const InviteUserForm = ({addUserEmail}: IProps) => {
  const initialFields: IStateField[] = [
    {
      id: genKey(),
      value: '',
    },
  ];
  const [fields, setFields] = useState<IStateField[]>(initialFields);

  const {
    handleSubmit,
    control,
    formState: {errors},
    reset,
  } = useForm<FormData>({
    resolver: yupResolver(InviteUserSchema),
  });
  const {email: errorsEmail = []} = errors;

  const appendField = () => setFields([...fields, {id: genKey(), value: ''}]);
  const deleteField = (id: string) => setFields(fields.filter(el => el.id !== id));
  const resetForm = () => {
    setFields(initialFields);
    reset();
  };
  const onSubmit = ({email = []}: {email: string[]}) => addUserEmail(email, resetForm);

  const isMultiple = fields.length > 1;
  const isMaxCount = fields.length >= 4; // max additional email fields = 4

  return (
    <form className={'invite-user-form'} onSubmit={handleSubmit(onSubmit)}>
      <h3>Invite new users</h3>
      {fields.map((el: IStateField, i: number) => {
        const {message = ''} = errorsEmail[i] || {};

        return (
          <div key={el.id} className="invite-user-form__row">
            <Controller
              render={({field: {value, onChange}}) => (
                <TextField
                  id={`invite-user-email-${el.id}`}
                  label="Email"
                  lineDirection="right"
                  placeholder="Email"
                  error={!!message}
                  // Since name={`email[i]`} value is not an array, but an element of an array.
                  // react-hook-form types doesn't cover this type of `name`, but the library itself supports it.
                  //@ts-expect-error error leftover from convertion to strict mode, please fix
                  value={value}
                  onChange={onChange}
                  errorText={message}
                  inlineIndicator={
                    isMultiple ? (
                      <Button
                        onClick={() => deleteField(el.id)}
                        icon
                        className="text-fields__inline-btn"
                      >
                        clear
                      </Button>
                    ) : undefined
                  }
                />
              )}
              //@ts-expect-error error leftover from convertion to strict mode, please fix
              name={`email[${i}]`}
              control={control}
              defaultValue={el.value}
            />
          </div>
        );
      })}

      <Button
        disabled={isMaxCount}
        iconEl={<FontIcon>add_circle_outline</FontIcon>}
        flat
        onClick={() => appendField()}
        className="add-more-btn"
      >
        Add email
      </Button>

      <Button
        id={'submit-invite'}
        type="submit"
        iconEl={<FarmerIcon viewBox="0 0 24 24" />}
        raised
        primary
        className="submit-btn"
      >
        Invite User
      </Button>
    </form>
  );
};

export default InviteUserForm;
