import React, {useEffect, useState} from 'react';
import {useAppSelector} from '_hooks';
import {
  selectReportAvailableSourcingRegions,
  selectReportDashboardData,
  selectReportDataUnits,
  selectReportPageIndex,
  selectReportPageSize,
} from 'containers/si/module/selectors';
import {selectCropTypesCDLIdMap} from 'modules/global/selectors';
import {selectMeasurement} from 'containers/login/login-selectors';
import {SIScenarioKPIs} from 'containers/si/types';
import {
  calculateKPIValue,
  getTooltipValuesByKPI,
} from 'containers/si/planning-report/dashboard/utils/calculate-scenario';
import {getRegionName} from 'containers/si/planning-report/dashboard/utils/utils';
import type {BarChartProps} from 'containers/si/kpi/charts/components/bar-chart/bar-chart';
import {BarChart} from 'containers/si/kpi/charts/components/bar-chart/bar-chart';
import {ChartHeader} from 'containers/si/kpi/charts/components/chart-header';
import type {BarChartData} from './bar-charts';
import {AdjustableCard} from '../../styled-components';
import {useGetChartRedToGreenColorRange} from '../../hooks/useGetChartRedToGreenColorRange';
import {PRECISION_UNITS, SIScenarioKPIsLabel} from 'containers/si/constants';
import {REPORT_DASHBOARD_COLORS} from 'containers/si/colors';
import {ScenarioBarsGradients} from '../../components/scenario-bars-gradient';

export const SOCRemovalsCard = ({commonBarProps}: {commonBarProps: Partial<BarChartProps>}) => {
  const dashboardData = useAppSelector(selectReportDashboardData);
  const units = useAppSelector(selectReportDataUnits);
  const cropsById = useAppSelector(selectCropTypesCDLIdMap);
  const availableSourcingRegions = useAppSelector(selectReportAvailableSourcingRegions);
  const measurement = useAppSelector(selectMeasurement);

  const getChartColor = useGetChartRedToGreenColorRange();

  // Used for the numbering in the circle
  const pageIndex = useAppSelector(selectReportPageIndex);
  const pageSize = useAppSelector(selectReportPageSize);
  const startNumber = pageIndex * pageSize + 1;

  const [barChartData, setBarChartData] = useState<BarChartData[]>([]);

  useEffect(() => {
    // Generate Chart Data
    const cardData: BarChartData[] = [];

    dashboardData.map((scenario, index) => {
      const cropName = cropsById[Number(scenario.crop_type)]?.label || scenario.crop_type;
      const regionName = getRegionName(scenario, availableSourcingRegions);

      // Bars don't allow duplicate names, so need to figure out how to allow that since the name might be duplicated with multiple scenarios
      const cardLabel = `${index}+++${regionName}+++${cropName}+++${scenario.id}`;
      const scenarioData = calculateKPIValue({
        scenario,
        kpi: SIScenarioKPIs.soc_removals,
        units,
        measurement,
        round: true,
        precision: PRECISION_UNITS,
      }).value;

      return cardData.push({
        barDetails: {
          color: REPORT_DASHBOARD_COLORS[index],
          scenario: startNumber + index,
          ...getTooltipValuesByKPI(scenario, SIScenarioKPIs.soc_removals, true, 3, measurement),
        },
        label: cardLabel,
        value: scenarioData,
      });
    });

    setBarChartData(cardData);
  }, [availableSourcingRegions, cropsById, dashboardData, measurement, startNumber, units]);

  const colors = barChartData.map(bar => getChartColor({value: bar.value, lowValueIsBad: true}));

  return (
    <AdjustableCard className="emission-card" base={390} min={220} max={400}>
      <ChartHeader
        title={SIScenarioKPIsLabel.soc_removals}
        subtitle={`metric tons / ${measurement === 'ac' ? 'acre' : 'hectare'}`}
      />
      <div className="chart-container bar-charts">
        <BarChart {...commonBarProps} data={barChartData} colors={colors.reverse() as string[]} />
      </div>
      <ScenarioBarsGradients inverted leftLabel="Soil carbon loss" rightLabel="Soil carbon gain" />
    </AdjustableCard>
  );
};
