// @ts-nocheck
import {t} from 'i18n-utils';
import cn from 'classnames';
import IntegrationCard from './helpers/integration-card';
import {
  ExpandableArea,
  ExpandableRowContainer,
  NoItemsTitle,
  SubContainer,
  SubItem,
} from 'components/expandable-table-items/expandable-table-items';
import {ExternalService} from '../../map/types';
import {useAppDispatch, useAppSelector} from '_hooks';
import type {ComponentType} from 'react';
import React, {useEffect, useState} from 'react';
import {RequestStatus} from 'types';
import {PWApi} from '_api';
import {showNotification} from 'components/notification/notification';
import {sortByStringKey} from 'containers/profile/integration/helpers/utils';
import {reportError} from '../../error-boundary';
import Mixpanel from '_utils/mixpanel-utils';
import {importPWFarms} from './actions';
import {Button, CircularProgress, SelectionControl} from 'react-md';
import {Flex} from 'components/flex/flex';
import {selectIntegrationPlatform} from './integration-selectors';
import type {IntegrationComponentProps} from './types';
import {SelectedPlatformBackButton} from './helpers/selected-platform-back-btn';
import {Sticky} from '../../../components';
import {SelectFarmsAndFieldsToImportRow} from './helpers/select-farms-fields-to-import-row';

export type PWSyncRequestPayload = {
  farms: string[];
  paddocks: string[];
};

type PWEntity = {
  id: string;
  name: string;
};
const NoEntities: PWEntity[] = [{name: 'no-entities', id: null}];

const IntegrationPW: ComponentType<IntegrationComponentProps> = ({expanded}) => {
  const platform = useAppSelector(s =>
    selectIntegrationPlatform(s, ExternalService.ProductionWise)
  );
  const dispatch = useAppDispatch();

  const [loading, setLoading] = useState(false);

  const [farms, setFarms] = useState<PWEntity[]>([]);
  const [selectedFarms, setSelectedFarms] = useState<string[]>([]);
  const [expandedFarms, setExpandedFarms] = useState<{[farmId: string]: boolean}>({});

  const [fields, setFields] = useState<{[farmId: string]: PWEntity[]}>({});
  const [selectedFields, setSelectedFields] = useState<{[farmId: string]: string[]}>({});

  const [requests, setRequests] = useState<{[entityId: string]: RequestStatus}>({});

  const getFarms = () => {
    if (!expanded || farms.length || loading) return;

    setLoading(true);
    PWApi.getFarms()
      .then(({data}) => {
        const sorted = sortByStringKey(data.data || [], 'name');
        setFarms(sorted);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
        showNotification({
          title: t({id: 'note.error', defaultMessage: 'Error'}),
          message: "Couldn't fetch ProductionWise farms.",
          type: 'error',
        });
      });
  };

  useEffect(getFarms, [expanded]);

  const toggleFarm = (farmId: string, value: boolean) => {
    setSelectedFarms(selectedFarms => {
      return value ? [...selectedFarms, farmId] : selectedFarms.filter(x => x !== farmId);
    });
    // If we have fields loaded for the farm, update them.
    if (fields[farmId]) {
      setSelectedFields(selectedFields =>
        value
          ? {...selectedFields, [farmId]: fields[farmId].map(f => f.id)}
          : {...selectedFields, [farmId]: []}
      );
    }
  };

  const toggleAllFarms = (value: boolean) => {
    setSelectedFarms(value ? farms.map(f => f.id) : []);

    const newFields: {[farmId: string]: string[]} = {};
    Object.entries(fields).forEach(([farmId, fields]) => {
      newFields[farmId] = value ? fields.map(f => f.id) : [];
    });
    setSelectedFields(newFields);
  };

  const getPWFields = (farmId: string) => {
    setRequests(r => ({...r, [farmId]: RequestStatus.Loading}));
    PWApi.getFields(farmId)
      .then(({data}) => {
        let f = sortByStringKey(data.data || [], 'name');
        if (!f.length) {
          f = NoEntities;
        }
        setFields(fields => ({...fields, [farmId]: f}));
        setRequests(r => ({...r, [farmId]: RequestStatus.Success}));
        if (selectedFarms.includes(farmId)) {
          setSelectedFields(selectedFields => ({
            ...selectedFields,
            [farmId]: f.map(f => f.id),
          }));
        }
      })
      .catch(e => {
        setFields(fields => ({...fields, [farmId]: NoEntities}));
        setRequests(r => ({...r, [farmId]: RequestStatus.Error}));
        reportError(`PW sync error fetching fields for farm ${farmId}: ` + e);
      });
  };

  const expandFarm = (farmId: string) => {
    const newValue = !expandedFarms[farmId];
    setExpandedFarms(x => ({
      ...x,
      [farmId]: newValue,
    }));
    if (newValue && !fields[farmId]) {
      getPWFields(farmId);
    }
  };

  const toggleField = (farmId: string, fieldId: string, value: boolean) => {
    const oldFields = selectedFields[farmId] || [];
    const newFields = value ? [...oldFields, fieldId] : oldFields.filter(x => x !== fieldId);
    setSelectedFields(selectedFields => ({
      ...selectedFields,
      [farmId]: newFields,
    }));
    // Check if the parent (farm) needs to be updated.
    if (selectedFarms.includes(farmId) && !newFields.length) {
      // The farm is selected but none of the fields inside this farm is selected anymore.
      // Deselect the farm.
      const newFarms = selectedFarms.filter(id => id !== farmId);
      setSelectedFarms(newFarms);
    } else if (!selectedFarms.includes(farmId) && newFields.length) {
      // The farm is not selected but the field inside this farm is selected.
      // Select the farm.
      const newFarms = [...(selectedFarms || []), farmId];
      setSelectedFarms(newFarms);
    }
  };

  const onImportFarms = () => {
    const farmNames = farms.filter(f => selectedFarms.includes(f.id)).map(f => f.name);
    Mixpanel.importSync(ExternalService.ProductionWise, farmNames);
    // for selectedFarms, only keep those that have all fields (or no fields implying fields weren't opened) selected
    const filteredSelectedFarms = selectedFarms.filter(farmId => {
      // we only care about this logic if any fields are selected
      if (farmId in selectedFields) {
        return fields[farmId].length === selectedFields[farmId].length;
      }
      // otherwise we keep the farm selected
      return true;
    });
    // delete any field objects that belong to a farm that is already selected
    const selectedPaddocks = {...selectedFields};
    filteredSelectedFarms.forEach(farmId => {
      delete selectedPaddocks[farmId];
    });
    const paddocks = Object.values(selectedPaddocks).reduce((acc, v) => [...acc, ...v], []);

    dispatch(
      importPWFarms({
        // send all required farms and fields
        farms: filteredSelectedFarms,
        paddocks,
      })
    );
  };

  const isSyncInProgress = platform.syncStatus === RequestStatus.Loading;
  const farmsLoading = loading && !farms.length;
  const isReady = Boolean(!farmsLoading);

  return (
    <div className={cn('integration-platform productionwise', {selected: expanded})}>
      <IntegrationCard
        authorized={true} // assuming you are "authorised" already if you can see this card
        expanded={expanded}
        onAuthorize={null}
        platform={ExternalService.ProductionWise}
        loading={farmsLoading}
      />

      {expanded && (
        <>
          {isReady && (
            <>
              <SelectFarmsAndFieldsToImportRow />

              <div className={'entities-to-import-container'}>
                {farms.length > 1 && (
                  <SelectionControl
                    id={'select-all-productionwise-farms'}
                    name={'select-all-productionwise-farms'}
                    label={t(
                      {id: 'Select all farms ({count1}/{count2})'},
                      {count1: selectedFarms.length, count2: farms.length}
                    )}
                    type={'checkbox'}
                    className={'select-all-checkbox'}
                    checked={selectedFarms.length === farms.length}
                    onChange={(v: boolean) => toggleAllFarms(v)}
                  />
                )}
                {farms.map(farm => {
                  const isFarmSelected = selectedFarms.includes(farm.id);
                  return (
                    <React.Fragment key={farm.id}>
                      <ExpandableRowContainer
                        className={cn({
                          selected: isFarmSelected,
                        })}
                      >
                        <SelectionControl
                          id={farm.id}
                          name={'farm selection'}
                          className={'highest-platform-entity'}
                          label={
                            farm.name +
                            selectedEntitiesNumber(fields[farm.id], selectedFields[farm.id])
                          }
                          type={'checkbox'}
                          checked={isFarmSelected}
                          onChange={(v: boolean) => toggleFarm(farm.id, v)}
                        />
                        <ExpandableArea
                          onClick={() => expandFarm(farm.id)}
                          expanded={expandedFarms[farm.id]}
                        />
                      </ExpandableRowContainer>
                      {expandedFarms[farm.id] && (
                        <SubContainer>
                          {requests[farm.id] === RequestStatus.Loading && (
                            <span className="preloader">
                              <CircularProgress centered={false} id={farm.id} />
                            </span>
                          )}
                          {fields[farm.id]?.map(field => {
                            if (fields[farm.id]?.length === 1 && field.name === 'no-entities') {
                              return (
                                <NoItemsTitle key={field.id}>
                                  {t({id: 'No fields found'})}
                                </NoItemsTitle>
                              );
                            }

                            return (
                              <SubItem key={field.id}>
                                <SelectionControl
                                  id={field.id}
                                  name={'field selection'}
                                  className={'small-platform-entity'}
                                  label={field.name}
                                  type={'checkbox'}
                                  checked={(selectedFields[farm.id] || []).includes(field.id)}
                                  onChange={(v: boolean) => toggleField(farm.id, field.id, v)}
                                />
                              </SubItem>
                            );
                          })}
                        </SubContainer>
                      )}
                    </React.Fragment>
                  );
                })}
              </div>
            </>
          )}

          <Sticky className="action-buttons-holder">
            <SelectedPlatformBackButton />

            {isReady && (
              <Flex alignItems="center">
                {isSyncInProgress && (
                  <CircularProgress
                    className="margin-0 margin-right-5"
                    id="integration-productionwise-progress"
                  />
                )}

                <Button
                  raised
                  primary
                  className="md-btn--right"
                  disabled={!selectedFarms.length || isSyncInProgress}
                  onClick={onImportFarms}
                >
                  {isSyncInProgress ? t({id: 'Importing...'}) : t({id: 'Import'})}
                </Button>
              </Flex>
            )}
          </Sticky>
        </>
      )}

      {/*<ColoredBorder colorStart={'rgb(255, 151, 0)'} colorEnd={'rgba(255, 151, 0, 0.2)'} />*/}
    </div>
  );
};

const selectedEntitiesNumber = (list: PWEntity[], selected?: string[]) => {
  if (!list) {
    return '';
  }
  return ` (${selected?.length || 0}/${list.length})`;
};

export default IntegrationPW;
