import type {ComponentType} from 'react';
import React from 'react';
import {Ln} from 'components';
import {FontIcon} from 'react-md';
import {useAppSelector} from '_hooks';
import {selectSelectedPlatformToImport} from '../integration-selectors';
import {t} from 'i18n-utils';

export const SelectFarmsAndFieldsToImportRow: ComponentType<{
  message?: string | JSX.Element;
}> = ({message = t({id: 'Select farms and fields to import.'})}) => {
  const singlePlatformView = useAppSelector(selectSelectedPlatformToImport);

  if (!singlePlatformView) return null;
  return (
    <div className={'integration-card__info-link-container'}>
      {message}
      <Ln blank external href={'https://help.flurosense.com/en/collections/2564248-integrations'}>
        <FontIcon>help_outline</FontIcon>
      </Ln>
    </div>
  );
};
