import {useEffect} from 'react';
import {useAppSelector} from '_hooks';
import {mixpanelCarbon} from '_utils/mixpanel-utils';
import {useMonitoringUrlParams} from '../../hooks';
import {selectStageById} from '../../module/selectors';

export const useMixpanelTrackSwitchStage = (type: 'table' | 'bulk-edit') => {
  const {stageId} = useMonitoringUrlParams();
  const stage = useAppSelector(s => selectStageById(s, stageId));

  useEffect(() => {
    if (stage?.type_) {
      if (type === 'table') {
        mixpanelCarbon.measurementTableOpen(stage.type_);
      }

      if (type === 'bulk-edit') {
        mixpanelCarbon.measurementBulkEdit(stage.type_);
      }
    }
  }, [stage, type]);
};
