import type {ReactElement} from 'react';
import type {
  ProgramResponse,
  CommodityItem,
  KPISummaryType,
  KPI,
  ReportType,
  EmissionsFactorDetails,
  Model,
  InterventionType,
  Protocol,
  SourcingRegionConfig,
} from 'containers/si/api/apiTypes';

export type SIProgramEntities = {
  programs: Record<number, ProgramResponse>;
};

export type SIProgramResult = number[];

export type AddSIUserToAProgramFormData = {
  user_id: number;
  programs: number[];
  email: string;
  first_name?: string;
  last_name?: string;
  prevent_email?: boolean; // prevent the Core from sending an invitation email, should be true only for existing users
};

// Supply Shed
export type SISupplyShed = {
  id: number;
  program_id?: number;
  name: string;
  description?: string;
  subsections: SISubsection[];
  total_field_area_ha?: number;
  commodities?: CommodityItem[];
  color?: string; // TODO: color not on BE type
};

export type SISupplyShedInput = Omit<SISupplyShed, 'id'>;

export type SISubsection = {
  id: number;
  program_id?: number;
  name: string;
  supply_shed_id: number;
  commodities?: CommodityItem[];
  field_area_ha?: number;
  status?: string; // TODO: this is an actual status; update BE type
};

export type SISubsectionInput = Omit<SISubsection, 'id' | 'status' | 'geometries'> & {
  geometries?: Array<GeoJSON.Feature<GeoJSON.Polygon | GeoJSON.MultiPolygon>> | null;
  selected_features?: {
    feature_id: string;
    collection_id: string;
    feature_name: string;
  }[];
};

export type SIMapInteractionType = 'select' | 'circle' | 'polygon' | 'upload' | 'none';

export type SIMapStyleType = 'road' | 'satellite' | 'light';

export const mapboxMapStyle: Record<SIMapStyleType, string> = {
  road: 'mapbox://styles/mapbox/streets-v11',
  satellite: 'mapbox://styles/mapbox/satellite-streets-v11',
  light: 'mapbox://styles/mapbox/light-v10',
};

export type SIMapLayerType = 'admin0' | 'admin1' | 'admin2' | 'huc8' | 'huc10';

export type SIMapLayerParams = {
  label: string;
  url: string;
  layer: string;
  collection_id: string;
  name_getter: Function;
};

export const mapboxTilesetParams: Record<SIMapLayerType, SIMapLayerParams> = {
  admin2: {
    label: 'Admin 2 - Counties and Districts',
    url: 'mapbox://greatozmen.455rmhu6',
    layer: 'admin_2_geoboundaries_cgazgeojsonld',
    collection_id: 'admin_2_geoboundaries_cgaz',
    name_getter: (feature: any) => {
      return feature.properties.shapeName;
    },
  },
  admin1: {
    label: 'Admin 1 - States and Provinces',
    url: 'mapbox://greatozmen.2hcayjrq',
    layer: 'admin_1_geoboundaries_cgazgeojsonld',
    collection_id: 'admin_1_geoboundaries_cgaz',
    name_getter: (feature: any) => {
      return feature.properties.shapeName;
    },
  },
  admin0: {
    label: 'Admin 0 - Countries',
    url: 'mapbox://greatozmen.1aon9cvn',
    layer: 'admin_0_geoboundaries_cgazgeojsonld',
    collection_id: 'admin_0_geoboundaries_cgaz',
    name_getter: (feature: any) => {
      return feature.properties.ISO_CODE;
    },
  },
  huc8: {
    label: 'Watersheds - HUC8',
    url: 'mapbox://greatozmen.6uz9v1mg',
    layer: 'watersheds_usgs_wbd_huc_8geojsonld',
    collection_id: 'watersheds_usgs_wbd_huc_8',
    name_getter: (feature: any) => {
      return `HUC8 - ${feature.properties.name}`;
    },
  },
  huc10: {
    label: 'Watersheds - HUC10',
    url: 'mapbox://greatozmen.7htxkuf7',
    layer: 'watersheds_usgs_wbd_huc_10geojsonld',
    collection_id: 'watersheds_usgs_wbd_huc_10',
    name_getter: (feature: any) => {
      return `HUC 10 - ${feature.properties.name}`;
    },
  },
};

export type SICropTypeOptions = {
  label: string;
  value: string;
  color: string;
  icon: string;
  type: string;
  handleSelectAll?: (value: boolean) => void;
};

// Planning Reports / Abatement Potential / IMT Report
export type InterventionOptions = 'tillage' | 'cover_cropping' | 'fertilizer_usage';

export type Intervention = Record<InterventionOptions, string>;

export type CropUnits = 'kg' | 'lb' | 'mt' | 't';

export type ReportSelectCropsType = {
  key: string;
  label: string;
  type: string;
  volume_units: CropUnits;
  volume: number;
  value: string;
};

export type ReportSelectedCommodities = {
  id: number;
  label: string;
  type: string;
  volume: number;
  volume_units: CropUnits;
};
export type ReportSelectedCrops = {
  commodities?: ReportSelectedCommodities[];
  subsection?: {[subsectionId: number]: ReportSelectedCommodities[]};
};

export type ReportSelectedSupplyShedCrops = {
  [supplyShedId: number]: ReportSelectedCrops;
};

// TODO: missing QUEUED and ACTIVE
export type APReportStatus = 'ACCEPTED' | 'IN_PROGRESS' | 'COMPLETE';

export type StepsItem = {
  label: string;
  value?: string | number;
  active?: boolean;
  icon: ReactElement;
  screen?: ReactElement;
  disabled: boolean;
};

export type PlanningReport = {
  id: number;
  name: string; // TODO: required in FE, optional in BE
  owner?: string;
  report_type: ReportType; // TODO: required in FE, optional in BE
  model: Model; // TODO: required in FE, optional in BE
  protocol: Protocol; // TODO: required in FE, optional in BE
  sourcing_regions: Array<SourcingRegionConfig>; // TODO: commodities volume and volume_units are ignored by BE. Investigate if needed on FE for any reason
  intervention_scenarios: {
    scenario_id?: number;
    intervention_type: InterventionType;
    percentage: number;
  }[][]; // TODO: required in FE, optional in BE
  // @deprecated
  aggregation_units: string[]; // TODO: required in FE, optional in BE; Array<SpatialAggregationUnit>
  start_year: number;
  end_year: number;
  status: APReportStatus;
  results_url: string;
  created_at: string; // TODO: required in FE, optional in BE
  updated_at: string; // TODO: required in FE, optional in BE
};

// TODO name, report_type, model, protocol, aggregation units required in FE
// optional in BE ReportRequest
export type PlanningReportInput = Omit<
  PlanningReport,
  'id' | 'status' | 'results_url' | 'created_at' | 'updated_at'
>;

// KPI

export type SIProgramKPICropAreaLabels = {
  yield: 'yield_area_m2';
  crop_area: 'area_m2' | 'ghg_m2' | 'net_impact_m2' | `fert_${'s' | 'p' | 'n' | 'k'}_m2`;
  ghg: 'ghg_area_m2';
  soc: 'soc_area_m2';
  cover_crop: 'cover_cc_area_m2';
  tillage: 'till_redu_area_m2' | 'till_notill_area_m2';
  fertilizer: 'fert_k_area_m2' | 'fert_p_area_m2' | 'fert_s_area_m2' | 'fert_n_area_m2';
  fert_emissions_factor: 'fert_emissions_factor';
  ghg_emissions_factor: 'ghg_emissions_factor';
  net_ghg_emissions_factor: 'net_ghg_emissions_factor';
  net_ghg_emissions: 'net_ghg_emissions';
  lulc: 'lulc_total_tracked_area_m2';
  living_root_cover: 'living_root_cover';
  living_root_health: 'living_root_health';
  living_root_confidence: 'living_root_confidence';
  crop_rotation_number: 'croprtn_1_m2';
  crop_rotation_fallow: 'nofallow-m2';
  crop_rotation_common_rotations: 'common_crop_rtn_m2';
};

export type SIProgramKPICropAreaMetric = {
  value: number;
  label: SIProgramKPICropAreaLabels[KPI][];
};

export type SIProgramKPIMetricLabels = {
  yield: 'yield_kg';
  crop_area: 'area_m2' | 'net_impact_kg' | `fert_${'s' | 'p' | 'n' | 'k'}_kg`;
  ghg: 'ghg_kg';
  soc: 'soc_kg';
  cover_crop: 'cover_cc_area_m2';
  tillage: 'till_redu_area_m2' | 'till_notill_area_m2';
  fertilizer: 'fert_k_area_kg' | 'fert_p_area_kg' | 'fert_s_area_kg' | 'fert_n_area_kg';
  fert_emissions_factor: 'fert_emissions_factor';
  ghg_emissions_factor: 'ghg_emissions_factor';
  net_ghg_emissions_factor: 'net_ghg_emissions_factor';
  net_ghg_emissions: 'net_ghg_emissions';
  living_root_cover: 'living_root_cover' | `living_root_cover_${number}`;
  living_root_health: 'living_root_health';
  living_root_confidence: 'living_root_confidence';
  lulc: 'lulc_conversion_area_m2';
  crop_rotation_number: 'croprtn_1_m2';
  crop_rotation_fallow: 'nofallow-m2';
  crop_rotation_common_rotations: 'common_crop_rtn_m2';
};

export type SIProgramKPIMetric = {
  value: number;
  label: SIProgramKPIMetricLabels[KPI][];
};

export type SIProgramKPISubtype = {
  yield: 'yield_kg';
  crop_area: 'area_m2'; // should this also have 'ghg_m2'?
  ghg: 'ghg_kg' | 'n2o_indirect_kg' | 'n2o_direct_kg' | 'ch4_kg';
  soc: 'soc_kg';
  cover_crop: 'cover_cc_area_m2' | 'cover_nocc_area_m2';
  tillage: 'till_conv_area_m2' | 'till_redu_area_m2' | 'till_notill_area_m2';
  fertilizer: 'fert_k_kg' | 'fert_n_kg' | 'fert_p_kg' | 'fert_s_kg';
  fert_emissions_factor: 'fert_emissions_factor';
  ghg_emissions_factor: 'ghg_emissions_factor';
  net_ghg_emissions_factor: 'net_ghg_emissions_factor';
  net_ghg_emissions: 'net_ghg_emissions';
  living_root_cover: 'living_root_cover' | `living_root_cover_${number}`;
  living_root_health: 'living_root_health';
  living_root_confidence: 'living_root_confidence';
  lulc: 'lulc_conversion_area_m2;';
  crop_rotation_number: 'croprtn_1_m2' | 'croprtn_2_m2' | 'croprtn_3_m2';
  crop_rotation_fallow: 'nofallow_m2' | 'lowfallow_m2' | 'midfallow_m2' | 'highfallow_m2';
  crop_rotation_common_rotations: string;
};

export type SIProgramKPI = {
  kpi_name: KPI;
  year: number;
  crop_area?: SIProgramKPICropAreaMetric;
  metric?: SIProgramKPIMetric;
  annualized_summary?: {[year: string]: number};
  annualized_kpi_subtype_summary?: {
    [year: number]: Record<SIProgramKPISubtype[KPI], number>;
  };
  crop_type_summary?: {[cropId: SICropTypeOptions['value']]: number};
  crop_types_summary?: {[cropIds: string]: number};
  kpi_subtype_summary?: Record<SIProgramKPISubtype[KPI], number>;
  subsection_summary?: {[subsectionId: string]: number};
  supply_shed_summary?: {[supplyShedId: string]: number};
  included_supply_sheds: string[];
};

export type SIProgramKPIInput = {
  program_id: number;
  kpi_type: KPI;
  year_of_interest?: number;
  summarize_by?: KPISummaryType[];
  include_crop_types?: string[];
  include_supply_sheds?: number[];
};

// Program Dashboard
export const SIScenarioKPIs = {
  ef_reductions: 'ef_reductions',
  ghg_reductions: 'ghg_reductions',
  soc_removals: 'soc_removals',
  net_ghg: 'net_ghg',
} as const;

// eslint-disable-next-line @typescript-eslint/no-redeclare -- intentionally naming the variable the same as the type
export type SIScenarioKPIs = keyof typeof SIScenarioKPIs;

export type SIPracticeAdoption = {
  reduce_till: number;
  no_till: number;
  cover_crop: number;
  reduce_nitrogen: number;
};

// Abatement Potential Scenario
export interface SIScenarioAP {
  id: number; // generated from API response index
  tag: 'abatement_potential';
  crop_type: number;
  scenario_id: number;
  sourcing_region_id: {
    unit_id: number;
    unit_type: 'subsection' | 'supply_shed';
  };
  book: {
    emission_factors: EmissionsFactorDetails;
  };
  baseline: {
    soc_kg_per_m2: number;
    ghg_kg_per_m2: number;
    emission_factors: EmissionsFactorDetails;
  };
  simulation: {
    soc_kg_per_m2: number;
    ghg_kg_per_m2: number;
    emission_factors: EmissionsFactorDetails;
  };
}

export type SIScenarioResponseAP = Omit<SIScenarioAP, 'id' | 'tag'>;

// Program Plan Scenario
export interface SIScenarioPP {
  id: number; // generated from API response index
  tag: 'program_plan';
  crop_type: number;
  scenario_id: number;
  geography: {
    feature_id: string;
    collection_id: string;
    feature_name: string;
  };
  baseline: {
    soc_kg_per_m2: number;
    ghg_kg_per_m2: number;
    scenario_adoption: number;
  };
  simulation: {
    soc_kg_per_m2: number;
    ghg_kg_per_m2: number;
  };
}

export type SIScenarioResponsePP = Omit<SIScenarioPP, 'id' | 'tag'>;

// We want to be able to use the same type for both AP and PP scenarios since there are many shared components
// Check the SIScenario.tag to determine which type of scenario it is
export type SIScenario = SIScenarioAP | SIScenarioPP;

export type BarDataType = {
  label: string;
  value: number;
};
