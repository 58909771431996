import {selectUser} from 'containers/login/login-selectors';
import {selectFarmsIdsList} from 'modules/farms/selectors';
import React from 'react';
import {Redirect} from 'react-router-dom';
import {CI_ROOT_PATH} from '_environment';
import {useAppSelector} from '_hooks';
import {setGetParamToURL} from '../_utils/pure-utils';

const BasicAllocation = () => {
  const user = useAppSelector(selectUser);
  const farmsIds = useAppSelector(selectFarmsIdsList);
  const farmId = user?.groupIds?.[0] || farmsIds[0];
  const pageLink = new URL(location.href);
  pageLink.searchParams.get('tab');
  if (!pageLink.searchParams.get('tab')) {
    setGetParamToURL('tab', 'farm'); // set the farm tab as default
  }

  return <Redirect to={`/${CI_ROOT_PATH}${farmId ? `/${farmId}` : ''}` + location.search} />;
};

export default BasicAllocation;
