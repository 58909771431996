import {FluroProgressIcon} from 'components/fluro-progress-icon/fluro-progress-icon';
import cn from 'classnames';
import type {ComponentType, HTMLAttributes, ReactElement} from 'react';
import React from 'react';
import colors from '../../variables.scss';
import {CheckIcon} from 'components/icons';

type Props = {
  progress?: number;
  label: string | number;
  icon: string | ReactElement;
  line?: boolean;
  active?: boolean;
  disabled?: boolean;
};
/**
 * TODO refactor `active` to pass to the `FluroProgressIcon.active` prop instead of a class, will require some refactoring of the styling.
 */
export const FluroStep: ComponentType<Props & HTMLAttributes<HTMLDivElement>> = ({
  progress,
  disabled,
  label,
  icon,
  line = false,
  className,
  active = false,
  ...props
}) => {
  const done = progress !== undefined && progress >= 100;
  const iconColor = done && active ? colors['white'] : colors['main-green-600'];

  return (
    <div className={cn('fluro-step', className, {active, done, disabled})} {...props}>
      <FluroProgressIcon
        id={label}
        icon={
          done ? (
            <CheckIcon
              circleColor={'transparent'}
              checkMarkColor={iconColor}
              className="done-icon"
            />
          ) : (
            icon
          )
        }
        progressValue={progress}
        disabled={disabled}
      />
      {line && <div className="line" />}
      <span className="label">{label}</span>
    </div>
  );
};
