// @ts-nocheck
import {useLeafletContext} from '@react-leaflet/core';
import {t} from 'i18n-utils';
import L from 'leaflet';
import 'leaflet-draw/dist/leaflet.draw.css';
import 'leaflet-measure';
import 'leaflet-measure/dist/leaflet-measure.css';
import 'leaflet/dist/leaflet.css';
import type {ComponentType} from 'react';
import React, {useContext, useEffect, useState} from 'react';
import {FeatureGroup} from 'react-leaflet';
import {showNotification} from 'components/notification/notification';
import {FieldGeometryApi} from '_api';
import {useAppDispatch, useAppSelector} from '_hooks';
import {kmlToGeoJSON, updateEachFeatureProperties} from '_utils/geometry';
import {reportError} from '../error-boundary';
import {loadFieldData, setKmlCoordinates, updateFieldGeometries} from './actions';
import {MapContext} from './context';
import {CropPerformancePopup} from './crop-performance-popup';
import AnalyticsOverlay from './features/analytics/analytics-overlay';
import {AddAreaOfInterestDialog} from './features/anomalies/add-area-of-interest-dialog';
import PremiumAnomaliesOverlay from './features/anomalies/anomalies-overlay';
import GeometriesOverlay from './features/anomalies/geometries-overlay';
import CompareOverlay from './features/data-layers/compare-overlay';
import TissueSamplingOverlay from './features/sampling-points/tissue-sampling-overlay';
// map overlays
import NRecommendationOverlay from './features/zoning/nitrogen-recommendation/n-recommendation-overlay';
import ProductivityMapOverlay from './features/zoning/productivity-map/productivity-map-overlay';
import './index.scss';
import './lib/fluro-measurement';
import './lib/svg-icon';
// buttons
import MapButtons from './map-buttons';
import {EditControlButton} from './map-buttons/edit-control';
import {AddFieldsOverlay} from './overlays/add-fields-overlay';
import {CLUFieldBoundariesOverlay} from './overlays/clu-field-boundaries-overlay';
import MainFieldOverlay from './overlays/field-overlay';
import KmlFieldOverlay from './overlays/kml-field-overlay';
import {NoFarmsOverlay} from './overlays/no-farms-overlay';
import SeasonGeometriesAddingOverlay from './overlays/season-geometries-adding-overlay';
import SeasonGeometryOverlay from './overlays/season-geometry-overlay';
import WholeFarmOverlay from './overlays/whole-farm-overlay';
import 'react-virtualized/styles.css';

import {
  selectCurrentFarmId,
  selectCurrentField,
  selectCurrentField2,
  selectCurrentFieldGeometry,
  selectCurrentFieldId,
  selectCurrentTab,
  selectFeatureGroupReDrawId,
  selectIsWholeFarmView,
  selectMapFields,
} from './reducer/selectors';
import {selectHasFarmsOrGroupsIds} from '../login/login-selectors';

type Props = {
  setWholeFarmBounds: any;
};

export const FarmMap: ComponentType<Props> = () => {
  const [wholeFarmBounds, setWholeFarmBounds] = useState<L.LatLngBounds>();
  const [originalImageOverlayRef, setOriginalImageOverlayRef] = useState<L.ImageOverlay>();

  const {patchedFitBounds} = useContext(MapContext);
  const leafletElement = useLeafletContext().map;

  const dispatch = useAppDispatch();

  const selectedFieldId = useAppSelector(selectCurrentFieldId);
  const fields = useAppSelector(selectMapFields);
  const field = useAppSelector(selectCurrentField);
  const currentField = useAppSelector(selectCurrentField2); // this field won't have the patches and imagery that map.field would have.
  const isCompareOn = useAppSelector(s => s.map.isCompareOn);
  const groupId = useAppSelector(selectCurrentFarmId);
  const fieldGeometry = useAppSelector(selectCurrentFieldGeometry);
  const feature = useAppSelector(selectCurrentTab);
  const featureGroupReDrawId = useAppSelector(selectFeatureGroupReDrawId);
  const isWholeFarmView = useAppSelector(selectIsWholeFarmView);
  const hasFarms = useAppSelector(selectHasFarmsOrGroupsIds);

  const [shouldCheckFieldKmlVisibility, toggleShouldCheckFieldKmlVisibility] = useState(false);

  const fitBounds = (bounds?: L.LatLngBounds) => {
    const defaultBounds =
      (isWholeFarmView && wholeFarmBounds) || L.geoJSON(fieldGeometry)?.getBounds();

    patchedFitBounds(bounds || defaultBounds);
  };

  const loadFieldDataAndGeometry = (fieldId: number, MD5: string) => {
    const fieldGeometryRequest = new Promise(res => {
      if (fieldGeometry) {
        // TODO (stas): get rid of map.currentFieldKml and use map.fieldGeometries[MD5] instead.
        dispatch(setKmlCoordinates(fieldGeometry.features));
        res('ok');
        return;
      }
      FieldGeometryApi.kml(MD5)
        .then(({data}) => {
          const geoJson = kmlToGeoJSON(data);
          updateEachFeatureProperties(geoJson, {fluro_id: fieldId, id: fieldId});
          dispatch(updateFieldGeometries({[MD5]: geoJson}));
          dispatch(setKmlCoordinates(geoJson.features));
          res('ok');
        })
        .catch(err => {
          reportError(err?.message);

          showNotification({
            title: t({id: 'note.error', defaultMessage: 'Error'}),
            message: t({id: 'KML file not found', defaultMessage: 'KML file not found'}),
            type: 'error',
          });
        });
    });

    // Wait until both field's geometry and data are loaded.
    Promise.all([fieldGeometryRequest, dispatch(loadFieldData(fieldId))])
      .then(() => {
        // check should display the field kml after kml and seasons data is loaded
        toggleShouldCheckFieldKmlVisibility(true);
      })
      .catch();
  };

  useEffect(() => {
    if (currentField?.ID) {
      loadFieldDataAndGeometry(currentField.ID, currentField.MD5);
    }
  }, [currentField?.ID]);

  useEffect(() => {
    // In case case of WholeFarm, whole-farm-overlay.tsx will fitBounds instead.
    if (selectedFieldId !== 'WholeFarm') {
      fitBounds();
    }
  }, [feature]);

  useEffect(() => {
    if (fieldGeometry && leafletElement) {
      fitBounds();
    }
  }, [fieldGeometry, leafletElement]);

  useEffect(() => {
    if (!fields.length) {
      leafletElement?.setMinZoom(null);
    }
  }, [groupId, fields.length]);

  return (
    <>
      {leafletElement && (
        <MapButtons leafletElement={leafletElement} fitBounds={fitBounds} hasFarms={hasFarms} />
      )}

      {/* Selected field geometry (KML, geojson) overlay */}
      <KmlFieldOverlay
        shouldCheckFieldKmlVisibility={shouldCheckFieldKmlVisibility}
        toggleShouldCheckFieldKmlVisibility={toggleShouldCheckFieldKmlVisibility}
      />

      {/* Overlay to locate user to his position if user has no farms */}
      {leafletElement && !hasFarms && <NoFarmsOverlay leafletElement={leafletElement} />}

      {feature !== 'sustainability-insights' && feature !== 'crop-performance' && (
        <>
          {/* Areas of interest and anomalies */}
          <GeometriesOverlay />
          <PremiumAnomaliesOverlay />

          {/*Season geometry*/}
          <SeasonGeometryOverlay />

          {/*Season geometries overlay (using for create new season with geometry)*/}
          <SeasonGeometriesAddingOverlay />

          {/* Selected field sensor image overlay (NDVI, NMAP, etc) */}
          {leafletElement && (
            <MainFieldOverlay setOriginalImageOverlayRef={setOriginalImageOverlayRef} />
          )}

          {/*Processing new fields geometry */}
          <AddFieldsOverlay fitBounds={fitBounds} />
        </>
      )}

      {feature === 'crop' && <AddAreaOfInterestDialog />}

      {/* Compare one field overlay */}
      {!!field.ID &&
        isCompareOn &&
        leafletElement &&
        !isWholeFarmView &&
        feature !== 'sustainability-insights' && (
          <CompareOverlay
            leafletElement={leafletElement}
            compareOriginalRef={originalImageOverlayRef}
          />
        )}

      <ProductivityMapOverlay />

      {feature === 'crop-performance' && <CropPerformancePopup />}

      {feature !== 'sustainability-insights' && (
        <FeatureGroup key={featureGroupReDrawId}>
          <EditControlButton />

          {/* Tissue Sampling points on the map */}
          {!isWholeFarmView && !!selectedFieldId && <TissueSamplingOverlay />}

          {/*Checked field geometries (KML, geojson) and whole farm compare*/}
          {(isWholeFarmView || feature === 'crop-performance') && (
            <WholeFarmOverlay
              fitBounds={patchedFitBounds}
              wholeFarmBoundsCallback={setWholeFarmBounds}
            />
          )}

          {leafletElement && <CLUFieldBoundariesOverlay />}

          {/*Analytics overlay*/}
          {!!(field || fields.length) && feature === 'analytics' && leafletElement && (
            <AnalyticsOverlay leafletElement={leafletElement} />
          )}

          <NRecommendationOverlay />
        </FeatureGroup>
      )}
    </>
  );
};
