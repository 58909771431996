// @ts-nocheck
import {t} from 'i18n-utils';
import React, {useCallback, useMemo, useState} from 'react';
import Dialog from 'components/fluro-dialog';
import {dialogToggle, DialogType} from 'modules/helpers';
import {genKey} from '_utils/pure-utils';
import {Button, TextField} from 'react-md';
import {saveBulkFieldData} from 'containers/map/actions';
import styled from 'styled-components';
import ReadOnly from 'components/read-only-container';
import type {Field} from '../../types';
import {useAppDispatch, useAppSelector} from '_hooks';
import {selectFarmById} from 'modules/farms/selectors';
import {selectDialogData} from '../../../../modules/helpers/selectors';

const SaveButton = styled(Button)`
  width: 100%;
  margin-top: 10px;
`;

const EditFieldNameDialog = () => {
  const id = useMemo(() => genKey(), []);
  const dispatch = useAppDispatch();

  const {payload = {}} = useAppSelector(s => selectDialogData(s, DialogType.editFieldName));

  const {field, farmId}: {field: Field; farmId: number} = payload;
  const farm = useAppSelector(state => selectFarmById(state, farmId));
  const [fieldName, setFieldName] = useState(field.Name || '');

  const onSubmit = useCallback(
    (e: any) => {
      e.preventDefault();

      dispatch(saveBulkFieldData('Name', fieldName, farm.id, [field.ID]));
      dispatch(dialogToggle(DialogType.editFieldName, false));
    },
    [fieldName, field]
  );

  return (
    <Dialog
      id={`field-${id}`}
      visible={true}
      title={t({id: 'Edit name'})}
      focusOnMount={false}
      isDraggable
      onHide={() => dispatch(dialogToggle(DialogType.editFieldName, false))}
      width={400}
      portal
    >
      <form onSubmit={onSubmit}>
        <TextField
          id={`edit-field-name-${id}`}
          placeholder={t({id: 'Field name'})}
          lineDirection="center"
          value={fieldName}
          label={t({id: 'Field name'})}
          onChange={(value: string) => setFieldName(value)}
        />

        <ReadOnly>
          <SaveButton
            disabled={!fieldName.trim() || farm.readOnly || fieldName === field.Name}
            raised
            primary
            type="submit"
          >
            {t({id: 'Save name'})}
          </SaveButton>
        </ReadOnly>
      </form>
    </Dialog>
  );
};

export default EditFieldNameDialog;
