import {Flex, FluroButton} from 'components';
import type {ComponentType} from 'react';
import React, {useState} from 'react';
import {useDispatch} from 'react-redux';
import {useAppSelector} from '_hooks';
import {useMonitoringUrlParams} from '../../hooks';
import {selectStageById} from '../../module/selectors';
import type {MRVPhaseType} from 'containers/mrv/types';
import {MobEditDialog} from './mob-edit-dialog';
import {FontIcon} from 'react-md';
import {toggleTooltip} from 'modules/global/actions';
import {t} from 'i18n-utils';

type Props = {
  phaseType: MRVPhaseType;
};
export const MobEditButton: ComponentType<Props> = ({phaseType}) => {
  const dispatch = useDispatch();
  const [dialogVisible, setDialogVisible] = useState(false);
  const {stageId} = useMonitoringUrlParams();
  const stage = useAppSelector(s => selectStageById(s, stageId));
  if (!stage) return null;

  const onShowTooltip = () => {
    dispatch(
      toggleTooltip({
        id: 'monit-mob-edit-fields',
        place: 'top',
        content: t({
          id: 'EditMobMessage',
          defaultMessage: 'Click this button to add or remove mobs.',
        }),
      })
    );
  };

  return (
    <>
      <Flex alignItems="center" gap="1rem">
        <FluroButton onClick={() => setDialogVisible(true)} raised primary>
          {t({id: 'BtnLabel.EditMobs', defaultMessage: 'Add / Edit Mobs'})}
        </FluroButton>

        <FontIcon data-for="monit-mob-edit-fields" data-tip="Test" onMouseEnter={onShowTooltip}>
          help_outline
        </FontIcon>
      </Flex>
      {dialogVisible && (
        <MobEditDialog phaseType={phaseType} stage={stage} onHide={() => setDialogVisible(false)} />
      )}
    </>
  );
};
