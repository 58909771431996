import React from 'react';
import ConfirmDialog from './confirm.dialog';
import AlertDialog from './alert.dialog';
import FieldInfoDialog from 'containers/map/features/farm/field-info-dialog';
import EditFieldNameDialog from 'containers/map/features/farm/edit-field-name-dialog';
import EditCloudyDialog from 'containers/map/features/data-layers/edit-cloudy/edit-cloudy-dialog';
import {DialogType} from 'modules/helpers';
import {EditFarmDialog} from 'containers/map/features/farm/dialogs/edit-farm';
import EditFarmNameDialog from 'containers/map/features/farm/dialogs/edit-farm-name';
import DeleteDialog from '../delete-dialog';
import {useAppSelector} from '_hooks';
import {CarbonResourcesDialog} from 'containers/mrv/enrollment/form/carbon-resources-dialog/carbon-resources-dialog';
import {RequestAccessDialog} from 'containers/app-index-page/request-access-dialog';
import IntegrationsPlatformsPopUp from 'containers/profile/integration/integration-platforms-pop-up';
import AddFieldDialog from 'containers/dialogs/add-field';
import {selectDialogVisibility} from '../../modules/helpers/selectors';
import {Profile} from 'containers/profile';

const CommonDialogs = () => {
  const isVisibleConfirmDialog = useAppSelector(s => selectDialogVisibility(s, DialogType.confirm));
  const isVisibleAlertDialog = useAppSelector(s => selectDialogVisibility(s, DialogType.alert));
  const isVisibleFieldInfoDialog = useAppSelector(s =>
    selectDialogVisibility(s, DialogType.fieldInfo)
  );
  const isVisibleEditFieldNameDialog = useAppSelector(s =>
    selectDialogVisibility(s, DialogType.editFieldName)
  );
  const isVisibleEditCloudyDialog = useAppSelector(s =>
    selectDialogVisibility(s, DialogType.editCloudy)
  );
  const isVisibleEditFarmDialog = useAppSelector(s =>
    selectDialogVisibility(s, DialogType.editFarm)
  );
  const isVisibleEditFarmNameDialog = useAppSelector(s =>
    selectDialogVisibility(s, DialogType.editFarmName)
  );
  const isVisibleIntegrationPlatforms = useAppSelector(s =>
    selectDialogVisibility(s, DialogType.integrationPlatforms)
  );
  const isDeleteDialog = useAppSelector(s => selectDialogVisibility(s, DialogType.deleteDialog));

  const iCarbonResourcesDialog = useAppSelector(s =>
    selectDialogVisibility(s, DialogType.carbonResources)
  );
  const isRequestFSAccessDialog = useAppSelector(s =>
    selectDialogVisibility(s, DialogType.requestFSAccess)
  );

  const isAddNewFieldDialog = useAppSelector(s =>
    selectDialogVisibility(s, DialogType.addNewField)
  );

  const isProfileDialog = useAppSelector(s => selectDialogVisibility(s, DialogType.profile));

  return (
    <>
      {isVisibleConfirmDialog && <ConfirmDialog />}
      {isVisibleAlertDialog && <AlertDialog />}
      {isVisibleFieldInfoDialog && <FieldInfoDialog />}
      {isVisibleEditFieldNameDialog && <EditFieldNameDialog />}
      {isVisibleEditCloudyDialog && <EditCloudyDialog />}
      {isVisibleEditFarmDialog && <EditFarmDialog />}
      {isVisibleEditFarmNameDialog && <EditFarmNameDialog />}
      {isVisibleIntegrationPlatforms && <IntegrationsPlatformsPopUp />}

      {iCarbonResourcesDialog && <CarbonResourcesDialog />}
      {isDeleteDialog && <DeleteDialog />}
      {isRequestFSAccessDialog && <RequestAccessDialog />}
      {isAddNewFieldDialog && <AddFieldDialog />}
      {isProfileDialog && <Profile />}
    </>
  );
};

export default CommonDialogs;
