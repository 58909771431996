import React from 'react';
import {kiloFormatter} from '_utils/number-formatters';
import {CircularProgress} from 'react-md';
import {useTheme} from 'styled-components';
import convert from 'convert-units';
import type {BarItemProps} from '@nivo/bar';
import {ResponsiveBar} from '@nivo/bar';
import {useFetchKPI} from 'containers/si/hooks/useFetchKPI';

type NetBarLabels = 'GHG' | 'dSOC' | 'Net Emissions';

export const NetEmissionBarCard = () => {
  const theme = useTheme();

  const {response: ghgResponse, loading} = useFetchKPI({kpi: 'ghg', summarizeBy: ['annualized']});
  const {response: socResponse} = useFetchKPI({kpi: 'soc', summarizeBy: ['annualized']});

  const ghg = convert(ghgResponse?.metric?.value).from('kg').to('mt');
  const soc = convert(socResponse?.metric?.value).from('kg').to('mt');

  // Since the net bar might be [above, below, span] the x axis, we need to calculate how far above the x axis it should be
  // This is so the height of the chart will be correct

  // If +GHG & +SOC: Net bar above the x-axis will be Net (default)
  let net = ghg - soc;

  // If +GHG & -SOC: Net bar above the x-axis will only be made up of GHG
  if (ghg > 0 && soc < 0) {
    net = ghg;
  }

  // If -GHG & +SOC: Net bar above the x-axis will only be made up of SOC
  if (ghg < 0 && soc > 0) {
    net = soc;
  }

  // If -GHG & -SOC: Net bar will be below the x-axis
  // Make sure Net is always negative
  if (ghg < 0 && soc < 0) {
    net = -Math.abs(net);
  }

  const netBarData: {label: NetBarLabels; value: number}[] = [
    {
      label: 'GHG',
      value: ghg,
    },
    {
      label: 'dSOC',
      value: soc,
    },
    {
      label: 'Net Emissions',
      value: net,
    },
  ];

  if (!net || loading) return <CircularProgress className={'progress'} id="loading-net-bars" />;

  return (
    <ResponsiveBar
      data={netBarData}
      indexBy="label"
      layout="vertical"
      enableGridY={false}
      margin={{top: 30, right: 0, bottom: 20, left: 0}}
      padding={0.3}
      enableLabel={false}
      colors={theme.colorPalette.fs_main.gray_300}
      isInteractive={false}
      axisLeft={null}
      axisBottom={{
        tickSize: 0,
        tickPadding: 10,
      }}
      layers={['grid', 'axes', 'bars', 'markers', props => <NetBarAndLines {...props} />]}
      barComponent={BarWithoutNet}
    />
  );
};

export const BarWithoutNet = ({data, x, y, width, height, color}: BarItemProps) => {
  // Do not draw the Net Emissions bar, that will be done when the lines are drawn
  if (data.data.label === 'Net Emissions') return null;

  return (
    <g key={data.data.label}>
      <rect x={x} y={y} width={width} height={height} fill={color} />
    </g>
  );
};

const NetBarAndLines = ({bars}: {bars: BarItemProps[]}) => {
  const theme = useTheme();

  // Since this component will only be used for the Net Emissions bar, we can use '!' to tell TS that the bars will exist
  /* eslint-disable @typescript-eslint/no-non-null-assertion */
  const ghgBar = bars.find(bar => bar.data.data.label === 'GHG')!;
  const socBar = bars.find(bar => bar.data.data.label === 'dSOC')!;
  const netBar = bars.find(bar => bar.data.data.label === 'Net Emissions')!;
  /* eslint-enable @typescript-eslint/no-non-null-assertion */

  const ghgX = ghgBar.x;
  const ghgY = ghgBar.y;
  const ghgHeight = ghgBar.height;
  const ghgValue = ghgBar.data.value;

  const socX = socBar.x;
  const socY = socBar.y;
  const socHeight = socBar.height;
  const socValue = socBar.data.value;

  const netX = netBar.x;
  const netY = netBar.y;
  const netHeight = netBar.height;
  const netWidth = netBar.width;
  const netValue = ghgValue - socValue;

  let ghgLineX1 = 0;
  let ghgLineX2 = 0;
  let ghgLineY1 = 0;
  let ghgLineY2 = 0;

  let socLineX1 = 0;
  let socLineX2 = 0;
  let socLineY1 = 0;
  let socLineY2 = 0;

  let netRectX = netX;
  let netRectY = netY;
  let netRectHeight = netHeight;

  // Need to determine where to draw the lines and the net bar based on the values of GHG and SOC
  if (ghgValue > 0 && socValue > 0) {
    // +GHG && +SOC
    ghgLineX1 = ghgX;
    ghgLineX2 = netX + netWidth;
    ghgLineY1 = ghgY;
    ghgLineY2 = ghgY;

    socLineX1 = socX;
    socLineX2 = netX + netWidth;
    socLineY1 = socY;
    socLineY2 = socY;

    netRectX = netX;
    netRectY = Math.min(ghgY, socY);
    netRectHeight = netHeight;
  } else if (ghgValue < 0 && socValue > 0) {
    // -GHG && +SOC
    ghgLineX1 = ghgX;
    ghgLineX2 = netX + netWidth;
    ghgLineY1 = ghgY + ghgHeight;
    ghgLineY2 = ghgY + ghgHeight;

    socLineX1 = socX;
    socLineX2 = netX + netWidth;
    socLineY1 = socY;
    socLineY2 = socY;

    netRectX = netX;
    netRectY = socY;
    netRectHeight = ghgHeight + socHeight;
  } else if (ghgValue > 0 && socValue < 0) {
    // +GHG && -SOC
    ghgLineX1 = ghgX;
    ghgLineX2 = netX + netWidth;
    ghgLineY1 = ghgY;
    ghgLineY2 = ghgY;

    socLineX1 = socX;
    socLineX2 = netX + netWidth;
    socLineY1 = socY + socHeight;
    socLineY2 = socY + socHeight;

    netRectX = netX;
    netRectY = ghgY;
    netRectHeight = ghgHeight + socHeight;
  } else if (ghgValue < 0 && socValue < 0) {
    // -GHG && -SOC
    ghgLineX1 = ghgX;
    ghgLineX2 = netX + netWidth;
    ghgLineY1 = ghgY + ghgHeight;
    ghgLineY2 = ghgY + ghgHeight;

    socLineX1 = socX;
    socLineX2 = netX + netWidth;
    socLineY1 = socY + socHeight;
    socLineY2 = socY + socHeight;

    netRectX = netX;
    netRectY = Math.max(ghgY, socY) + Math.min(ghgHeight, socHeight);
    netRectHeight = netHeight;
  }

  return (
    <>
      {bars.map((bar: BarItemProps) => {
        const {
          width,
          x,
          data: {value},
        } = bar;
        const barLabel = bar.data.data.label;
        const barValue = barLabel === 'Net Emissions' ? netValue : value;

        // Make sure the text value is right above the bar
        let barTextY = 0;
        if (barLabel === 'Net Emissions') {
          barTextY = netRectY;
        } else if (barLabel === 'GHG') {
          barTextY = ghgY;
        } else if (barLabel === 'dSOC') {
          barTextY = socY;
        }

        return (
          <g key={`${barLabel}${barValue}`} transform={`translate(${x}, 0)`}>
            <text
              transform={`translate(${width / 2}, ${barTextY - 4})`}
              textAnchor="middle"
              fontSize="12px"
              fontWeight={600}
              fill={theme.colorPalette.fs_main.black}
            >
              {kiloFormatter(barValue)}
            </text>
          </g>
        );
      })}

      <line
        x1={ghgLineX1}
        y1={ghgLineY1}
        x2={ghgLineX2}
        y2={ghgLineY2}
        stroke={theme.colorPalette.fs_main.gray_600}
        strokeDasharray="2"
      />
      <line
        x1={socLineX1}
        y1={socLineY1}
        x2={socLineX2}
        y2={socLineY2}
        stroke={theme.colorPalette.fs_main.gray_600}
        strokeDasharray="2"
      />
      <rect
        x={netRectX}
        y={netRectY}
        width={netWidth}
        height={netRectHeight}
        fill={
          netValue > 0
            ? theme.colorPalette.fs_category.red_600
            : theme.colorPalette.fs_main.green_600
        }
      />
    </>
  );
};
