import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './index.scss';
import 'containers/google-tag-manager';
import 'core-js/features/array/flat';
import 'react-intl-tel-input/dist/main.css';
import './init-sentry';
import {initCarbonHistoryTracker} from '_utils/history-utils';

ReactDOM.render(<App />, document.getElementById('root'));

if (module.hot) {
  // Whenever a new version of App.js is available
  module.hot.accept('./App', function () {
    // Require the new version and render it instead
    const NextApp = require('./App');
    ReactDOM.render(<NextApp />, document.getElementById('root'));
  });
}

initCarbonHistoryTracker();
