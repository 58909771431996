import ReadOnly from 'components/read-only-container';
import FarmInfo from 'containers/farm/farm-info';
import 'containers/farm/index.scss';
import {
  selectHasFarmsOrGroupsIds,
  selectIsAdmin,
  selectUser,
} from 'containers/login/login-selectors';
import {toggleMapBar} from 'containers/map/actions';
import {FormattedMessage} from 'i18n-utils';
import React, {useEffect, useState} from 'react';
import {FontIcon} from 'react-md';
import {useDispatch} from 'react-redux';
import {useAppSelector} from '_hooks';
import {setAddFieldCurrentStep} from 'modules/add-fields';
import {AsyncStatusType, dialogToggle, DialogType, Status} from 'modules/helpers';
import InviteUsers from '../../../invite-users';
import {
  selectCurrentField,
  selectIsReadOnly,
  selectIsWholeFarmView,
  selectMapFields,
  selectCurrentFarmId,
  selectCurrentFarm,
} from 'containers/map/reducer/selectors';
import Fields from './field/';
import FieldsInfoLine from './fields-info-line';
import './index.scss';
import {AddFieldsStep} from './new-fields/steps';
import {isAgxField} from '_utils/farm-utils';
import {selectAsyncRequestStatus} from 'modules/global/selectors';
import {selectDialogVisibility} from 'modules/helpers/selectors';
import ImportSeasonsPopUp from 'containers/dialogs/import-seasons';
import {FluroButton} from 'components';

export const MapFarm = () => {
  const dispatch = useDispatch();
  const agxSync = useAppSelector(selectUser).agxSync;
  const fields = useAppSelector(selectMapFields);
  const field = useAppSelector(selectCurrentField);
  const isAdmin = useAppSelector(selectIsAdmin);
  const isReadOnly = useAppSelector(selectIsReadOnly);
  const currentFarmId = useAppSelector(selectCurrentFarmId);
  const currentFarm = useAppSelector(selectCurrentFarm);
  const isWholeFarmView = useAppSelector(selectIsWholeFarmView);
  const isFarmsLoading =
    useAppSelector(s => selectAsyncRequestStatus(s, AsyncStatusType.farmsList)) === Status.Pending;
  const hasFarms = useAppSelector(selectHasFarmsOrGroupsIds);

  const importSeasonsDialogVisible = useAppSelector(s =>
    selectDialogVisibility(s, DialogType.importSeasons)
  );

  const [isInvite, setIsInvite] = useState(false);
  const [filteredFieldIds, setFilteredFieldIds] = useState<number[]>([]);
  useEffect(() => {
    setFilteredFieldIds([]);
  }, [currentFarmId]);

  const filteredFields = fields.filter(f => !filteredFieldIds.includes(f.ID));

  const agxFieldsExist = isWholeFarmView
    ? fields.find(f => isAgxField(f)) && (isAdmin || agxSync)
    : isAgxField(field) && (isAdmin || agxSync);

  if (!hasFarms && !isFarmsLoading) {
    return (
      <div className="section-container">
        <div className="no-farm-container">
          <h3>
            <FormattedMessage id="no-farms.welcome" defaultMessage="Welcome" />
          </h3>

          <div className="welcome-description">
            <FormattedMessage
              id="no-farms-lets-start"
              defaultMessage="You currently don't have any fields. Let's start by importing your fields."
            />
          </div>
          <div className="prefer-options-label">
            <FormattedMessage
              id="no-farms-upload-format-label"
              defaultMessage="Select the import format you prefer:"
            />
          </div>

          <AddFieldsStep changeCurrentStep={value => dispatch(setAddFieldCurrentStep(value))} />
        </div>
      </div>
    );
  }

  const handleAddFields = () => {
    dispatch(toggleMapBar(false));
    dispatch(dialogToggle(DialogType.addNewField, true));
  };

  return (
    <div className="section-container">
      <h3 className="tab-title">
        <FormattedMessage id="farm.title" defaultMessage="Farm set-up" />
      </h3>

      <InviteUsers
        isVisible={isInvite}
        groupId={currentFarmId}
        toggleInviteUser={() => setIsInvite(v => !v)}
      />

      {currentFarmId ? (
        <div className={'element-full-width'}>
          <FarmInfo
            isReadOnly={isReadOnly}
            isAgxUser={agxSync}
            isAgxField={isAgxField(field)}
            currentFarm={currentFarm}
            onEditFarm={() => dispatch(dialogToggle(DialogType.editFarm, true, currentFarm))}
            onAddInvite={() => setIsInvite(v => !v)} // TODO: tmp prop
          />

          <div className="farm-info">
            <FieldsInfoLine filteredIds={filteredFieldIds} />
          </div>

          <div className={'farm-buttons-container'}>
            <ReadOnly isReadOnly={isReadOnly}>
              <FluroButton
                id={'add-field'}
                className={'add-button on-boarding-add-fields'} // add-button - global class for buttons with + icon
                onClick={handleAddFields}
                raised
                primary={!agxFieldsExist}
                iconEl={<FontIcon>add</FontIcon>}
              >
                <FormattedMessage id="farm.btn.addFields" defaultMessage="Add fields" />
              </FluroButton>
            </ReadOnly>

            <FluroButton
              id={'add-farm-btn'}
              className="add-button on-boarding-add-farm" // add-button - global class for buttons with + icon
              onClick={() => dispatch(dialogToggle(DialogType.editFarm, true, {id: 0, name: ''}))}
              raised
              iconEl={<FontIcon>add</FontIcon>}
            >
              <FormattedMessage id="farm.btn.addFarm" defaultMessage="Add Farm" />
            </FluroButton>
          </div>

          <Fields setFilteredFields={setFilteredFieldIds} filteredFieldIds={filteredFields} />
        </div>
      ) : null}

      {importSeasonsDialogVisible && <ImportSeasonsPopUp />}
    </div>
  );
};

export default MapFarm;
