import type {ReactNode} from 'react';
import React, {useCallback, useEffect} from 'react';
import {useLocation} from 'react-router-dom';

type Props = {
  children: ReactNode;
};
const ScrollToTop = ({children}: Props) => {
  const location = useLocation();
  const scrollToTop = useCallback(() => {
    window.setTimeout(() => {
      window.scrollTo(0, 0);
    }, 0);
  }, []);

  useEffect(() => {
    scrollToTop();
  }, [location]);

  return <>{children}</>;
};

export default ScrollToTop;
