// @ts-nocheck
//@ts-expect-error error leftover from convertion to strict mode, please fix
import tokml from 'tokml';

export type GeoJsonVariants = GeoJSON.Feature | GeoJSON.FeatureCollection;

export const removeUndefinedProps = (data: GeoJsonVariants) => {
  if (data.type === 'FeatureCollection') {
    data.features.forEach(f => removeUndefinedProps(f));

    return data;
  }

  Object.keys(data.properties).forEach(k => {
    if (data.properties[k] === undefined) {
      delete data.properties[k];
    }
  });

  return data;
};

const toKml = (data: GeoJsonVariants, options?: {[key: string]: any}) => {
  // need to remove all undefined properties from the each feature properties to avoid tokml function exception
  return tokml(removeUndefinedProps(data), options);
};

export default toKml;
