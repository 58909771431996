/**
 * This file imports from both carbon and monitoring. To avoid circular dependencies,
 * we put that kind of complex thunks here.
 */
import {createAsyncThunk} from '@reduxjs/toolkit';
import {showNotification} from 'components/notification/notification';
import {reportError} from 'containers/error-boundary';
import {selectFarmIdByFieldId} from 'containers/map/reducer/selectors';
import {
  selectPhase,
  selectProjectFieldsList,
  selectProjectId,
  selectProjectProgram,
} from 'containers/mrv/monitoring/module/selectors';
import {fetchWholeProject, removeProjectFields} from 'containers/mrv/monitoring/module/thunks';
import {MRVPhaseType} from 'containers/mrv/types';
import type {AppStore} from 'reducers';
import {MRVApi} from '_api';
import {getGetURLParam, chunkArray} from '_utils/pure-utils';
import {setIneligibleRegionFields} from '.';
import {selectEnrolledFieldObjects} from './selectors';

/**
 * Save project fields into MRV service.
 * Uses both carbon-reducer and monitoring-thunks. Make sure not to import it into any of them.
 */
export const saveEnrolledFields = createAsyncThunk<boolean, void, {state: AppStore}>(
  'carbon/saveEnrolledFields',
  async (_, thunkAPI) => {
    const s = thunkAPI.getState();
    let hasError = false;

    const projectId = selectProjectId(s);
    const program = selectProjectProgram(s, projectId);
    const enrollment = selectPhase(s, MRVPhaseType.Enrollment);
    if (!enrollment) {
      reportError(
        `Trying to save enrolled fields without enrolment phase in the program: ${program.id}`
      );
      hasError = true;
      showNotification({
        title: 'Error',
        message: 'Enrollment phase is not configured correctly. Please contact your administrator.',
        type: 'error',
      });
      return hasError;
    }

    if (!projectId) {
      hasError = true;
      return hasError;
    }

    const enrolledFields = selectEnrolledFieldObjects(s);
    const projectFields = selectProjectFieldsList(s, projectId);

    const enrolledFieldIds = enrolledFields.map(f => f.FieldID);
    const projectFSFieldIds = projectFields.map(f => f.fs_field_id);

    const mrvFieldIdsToRemove = projectFields
      .filter(f => !enrolledFieldIds.includes(f.fs_field_id))
      .map(f => f.id);

    // skip already enrolled fields
    const fieldsToAdd = enrolledFields.filter(f => !projectFSFieldIds.includes(f.FieldID || -1));

    const fieldsRequest = fieldsToAdd.map(field => ({
      fs_field_id: field.FieldID || -1,
      farm_id: selectFarmIdByFieldId(s, field.ID) || -1,
    }));

    if (mrvFieldIdsToRemove.length) {
      // remove unselect fields
      await thunkAPI.dispatch(removeProjectFields({projectId, mrvFieldIds: mrvFieldIdsToRemove}));
    }

    // add new fields
    if (fieldsRequest.length) {
      // Split all the requests into chunks and SEQUENTIALY send them, because backend can't handle too many requests.
      const requests = chunkArray(fieldsRequest, Number(getGetURLParam('fieldsPerRequest')) || 10);
      for (const request of requests) {
        await MRVApi.addProjectFields(projectId, request).catch(e => {
          hasError = true;
          const fieldIds = e?.data?.detail?.disallowed_fields as number[];
          if (fieldIds?.length) {
            const ineligibleFields: {[fieldId: number]: boolean} = {};
            fieldIds.forEach(fieldId => (ineligibleFields[fieldId] = true));
            thunkAPI.dispatch(setIneligibleRegionFields(ineligibleFields));
          }
        });
      }

      await thunkAPI.dispatch(fetchWholeProject({projectId}));
    }

    return hasError;
  }
);
