export const TABLE_COLUMNS = [
  {name: 'User', property: 'user'},
  {name: 'Email', property: 'email'},
  {name: 'Registration date', property: 'created_at', nowrap: true, type: 'date'},
  {name: 'Last activity', property: 'last_activity', nowrap: true, type: 'date'},
  {name: 'Sessions', property: 'sessions', type: 'number'},
  {name: 'Acreage', property: 'fields_area_ha'},
];

export const SEARCH_TYPES = [
  {label: 'User', value: 'user'},
  // {label: 'Subscription', value: 'subscription'}, Waiting time when backend's issue will be done
];
