/**
 * Returns the names of the _typed_ enumerable string properties and methods of an object.
 *
 * Created because of this issue https://github.com/Microsoft/TypeScript/issues/12870
 */
export const getTypedKeys = Object.keys as <T extends object>(obj: T) => Array<keyof T>;

/**
 * Returns an array of _typed_ values of the enumerable properties of an object.
 *
 * Created because of this issue https://github.com/Microsoft/TypeScript/issues/12870
 */
export const getTypedValues = Object.values as <T extends object>(obj: T) => Array<T[keyof T]>;

/**
 * Returns an array of _typed_ key/values of the enumerable properties of an object.
 *
 * Created because of this issue https://github.com/Microsoft/TypeScript/issues/12870
 */
export const getTypedEntries = Object.entries as <T extends object>(
  obj: T
) => Array<[keyof T, T[keyof T]]>;

/**
 * Returns an array of all the _typed_ properties, including non-enumerable properties, of an object.
 *
 * Created because of this issue https://github.com/Microsoft/TypeScript/issues/12870
 */
export const getOwnPropertyNames = Object.getOwnPropertyNames as <T extends object>(
  obj: T
) => Array<keyof T>;
