import {t} from 'i18n-utils';
import type {ReactElement} from 'react';
import React, {useCallback, useEffect} from 'react';
import {CircularProgress} from 'react-md';
import cn from 'classnames';
import {useAppDispatch, useAppSelector} from '_hooks';
import {AsyncStatusType, Status} from '../../../../modules/helpers';
import {Flex, FluroButton, FluroStatusCircle} from 'components';
import {setSelectedPlatformToImport} from '../actions';
import type {IntegrationPlatform} from '../types';
import {selectPlatformIsFavorite, selectSelectedPlatformToImport} from '../integration-selectors';
import {updateUserFavoritePlatforms} from '../../../login/actions';
import {
  getIntegrationCardLogo,
  getIntegrationCompanyLabel,
  getIntegrationLabel,
} from '../integration-utils';

type Props = {
  authorized: boolean; // if the prop is undefined it means we don't know yet
  expanded: boolean;
  platform: IntegrationPlatform;
  containerClassName?: string;
  onAuthorize: (() => void) | null;
  customAuthButton?: ReactElement;
  reauthComponent?: ReactElement;
  loading?: boolean;
};

const IntegrationCard = ({
  authorized,
  onAuthorize,
  expanded,
  platform,
  containerClassName = '',
  customAuthButton,
  loading,
  reauthComponent,
}: Props) => {
  const dispatch = useAppDispatch();
  const singlePlatformView = useAppSelector(selectSelectedPlatformToImport);
  const loadingPreselectedPlatform = useAppSelector(
    state =>
      state.helpers.asyncStatuses[AsyncStatusType.authorizedPlatforms].status === Status.Pending
  );
  const isFavoritePlatform = useAppSelector(s => selectPlatformIsFavorite(s, platform));

  const handleAuthorizeAction = (ev: React.MouseEvent<HTMLElement>) => {
    ev.stopPropagation();
    onAuthorize?.();
  };

  const onToggleFavoritePlatform = () => {
    dispatch(updateUserFavoritePlatforms(platform, !isFavoritePlatform));
  };

  const onSelectPlatform = useCallback(() => {
    dispatch(setSelectedPlatformToImport(platform));
  }, []);

  useEffect(() => {
    if (expanded && !isFavoritePlatform) {
      onToggleFavoritePlatform(); // make the platform favorite once a user expanded it
    }
  }, [expanded]);

  const logoSrc = getIntegrationCardLogo(platform);
  const platformName = getIntegrationLabel(platform);
  const platformCompanyName = getIntegrationCompanyLabel(platform);

  return (
    <div
      onClick={!singlePlatformView ? onSelectPlatform : undefined}
      className={cn(`integration-card ${containerClassName}`)}
    >
      <div className={'integration-card__logo-container'}>
        <div className="logo">
          <img alt={logoSrc} src={logoSrc} />
        </div>
        <div className={'names'}>
          <div className="platform-name">{platformName}</div>
          <div className="company-name">{platformCompanyName}</div>
        </div>

        {singlePlatformView && (loading || loadingPreselectedPlatform) && (
          <CircularProgress
            className={'integration-card__loader'}
            centered={false}
            id={platformCompanyName + '-loader'}
          />
        )}
      </div>

      {singlePlatformView ? (
        customAuthButton ? (
          customAuthButton
        ) : authorized === undefined || authorized ? (
          reauthComponent // hide the button when we don't know do we are authorized or not
        ) : (
          <FluroButton
            className={'integration-card__authorize-button'}
            raised
            primary
            onClick={handleAuthorizeAction}
          >
            {t({id: 'Login'})}
          </FluroButton>
        )
      ) : (
        <Flex gap="10px" alignItems={'center'}>
          <FluroStatusCircle status={authorized ? 'green' : 'gray'} />
          <span className={'connected-status-label'}>
            {authorized ? 'Connected' : 'Not connected'}
          </span>
          <FluroButton
            className={cn('integration-card__favorite-platform', {highlighted: isFavoritePlatform})}
            onClick={ev => {
              ev.stopPropagation();
              onToggleFavoritePlatform();
            }}
            icon
          >
            {isFavoritePlatform ? 'star' : 'star_border'}
          </FluroButton>
        </Flex>
      )}
    </div>
  );
};

export default IntegrationCard;
