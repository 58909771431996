import React from 'react';
import {CPBadge} from 'components/badge';
import {MAIN_SHAPE_COLOR} from '_constants';
import {useTranslation} from 'i18n-utils';

export const MonitoringColorsOverview: React.ComponentType = () => {
  const t = useTranslation();

  return (
    <div className="carbon-colors-overview">
      <CPBadge>{t({id: 'Budge.Enrolled fields'})}</CPBadge>
      <CPBadge backgroundColor={MAIN_SHAPE_COLOR} color="#fff">
        {t({id: 'Budge.Selected fields'})}
      </CPBadge>
    </div>
  );
};
