import type {FC, ReactElement} from 'react';
import React, {Fragment} from 'react';

import {CardBigNumber, CardBigNumberUnits} from 'containers/si/kpi/styled-components';
export interface ChartMiddleDataProps {
  cardCenterNumber: string | ReactElement;
  cardCenterUnits: string | ReactElement;
}

export const ChartMiddleData: FC<ChartMiddleDataProps> = ({cardCenterNumber, cardCenterUnits}) => {
  return (
    <Fragment>
      <CardBigNumber>{cardCenterNumber}</CardBigNumber>
      <CardBigNumberUnits className="margin-bottom-15">{cardCenterUnits}</CardBigNumberUnits>
    </Fragment>
  );
};
