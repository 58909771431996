// @ts-nocheck
import {t, FormattedMessage} from 'i18n-utils';
import React, {Component} from 'react';
import type {ConnectedProps} from 'react-redux';
import {connect} from 'react-redux';
import {SelectField, FileInput, FontIcon, Button, Checkbox} from 'react-md';
import type {Moment} from 'moment';
import moment from 'moment';
import axios from 'axios';
import Server from '_api/server';

import {FluroDatePicker, InfoBlock, Ln} from 'components';
import {UploadIcon} from 'containers/map/icons';
import {showNotification} from 'components/notification/notification';
import UploadFile from './file-upload';
import {SENSORS, SOURCE_TYPES} from '../constants';
import {sensorView} from '_utils';
import {contactSupportTeam} from '_utils/pure-utils';
import {GLOBAL_FORMAT_DATE} from '_constants';
import {TabContainer, CommonBlockWrapper, CommonFieldsWrapper} from './upload-layers.styled';
import type {AppStore} from 'reducers';
import {DialogContentWrapper} from 'containers/admin/logs/email/email-logs.styled';
import {selectFarmsList} from 'modules/farms/selectors';

type Props = ConnectedProps<typeof connector>;

type State = {
  selectedDate: string;
  sourceType: string;
  sensorType: string;
  mosaicBands: string[];
  mosaicBandsCurrent: string;
  filesToUpload: {file: File; mosaicIndex: string}[];
  isUploading: boolean;
  errorWhileUpload: boolean;
  selectedDateError: string;
  allowUpload: boolean;
  fieldMD5: string;
  isToAllFields: boolean;
};

class FormUploader extends Component<Props, State> {
  defaultDate: string;
  scrollContainer: any = null;

  constructor(props: Props) {
    super(props);
    const currentSeasonEndDate =
      this.props.currentSeason.endDate &&
      moment(this.props.currentSeason.endDate, GLOBAL_FORMAT_DATE);

    this.defaultDate =
      currentSeasonEndDate && currentSeasonEndDate.isSameOrBefore(moment()) // if season in future, set current date as selected
        ? currentSeasonEndDate.format()
        : moment().format();

    this.state = {
      selectedDate: this.defaultDate,
      sourceType: '',
      sensorType: SENSORS[0].id,
      mosaicBands: SENSORS[0].options.mosaicBands,
      mosaicBandsCurrent: SENSORS[0].options.mosaicBands[0],
      filesToUpload: [],
      isUploading: false,
      errorWhileUpload: false,
      selectedDateError: '',
      allowUpload: false,
      fieldMD5: '',
      isToAllFields: false,
    };
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (prevState.mosaicBands[0] !== this.state.mosaicBands[0]) {
      this.updateMosaicBands(this.state.mosaicBands[0]);
    }
  }

  validateDate = () => {
    const {startDate, endDate} = this.props.currentSeason;
    const {selectedDate} = this.state;
    const momentSelectedDate = moment(selectedDate, GLOBAL_FORMAT_DATE);
    const range =
      momentSelectedDate.isSameOrAfter(moment(startDate, GLOBAL_FORMAT_DATE)) &&
      momentSelectedDate.isSameOrBefore(moment(endDate, GLOBAL_FORMAT_DATE));

    if (!range) {
      this.setState({
        selectedDateError: t({
          id: 'The date does not intersect with the crop dates. Please adjust the crop dates or the capture date.',
        }),
      });
      return false;
    }

    this.setState({selectedDateError: ''});
    return true;
  };

  onChangeDate = (value: Moment) => {
    this.setState({selectedDate: value.format()}, this.validateDate);
  };

  onChangeSourceType = (value: string) => {
    const {sourceType, sensorType} = this.state;
    const fitSensor = SENSORS.find(
      s => s.id === sensorType && s.options.sourceTypes.includes(value)
    );
    const resultLayer = fitSensor
      ? fitSensor
      : SENSORS.find(s => s.options.sourceTypes.includes(value));
    if (sourceType !== value)
      this.setState({
        sourceType: value,
        mosaicBands: resultLayer.options.mosaicBands,
        sensorType: resultLayer.id,
      });
  };

  onChangeSensorType = (value: string) => {
    if (this.state.sensorType !== value) {
      let mosaicBandsNewValue = this.state.mosaicBands;
      SENSORS.forEach(element => {
        if (element.id === value) {
          mosaicBandsNewValue = element.options.mosaicBands;
        }
      });

      this.setState({
        sensorType: value,
        mosaicBands: mosaicBandsNewValue,
      });
    }
  };

  validateDataBeforeSend = () => {
    if (!this.validateDate()) {
      return showNotification({
        title: 'Missing date',
        message: t({
          id: 'The date does not intersect with the crop dates. Please adjust the crop dates or the capture date.',
        }),
        type: 'error',
      });
    }

    if (!this.state.fieldMD5 && !this.state.isToAllFields) {
      return showNotification({
        title: t({id: 'Missing field'}),
        message: t({
          id: 'pleaseSelectFieldOr',
          defaultMessage: 'Please select field or use "upload to all fields" option.',
        }),
        type: 'error',
      });
    }

    this.uploadFilesToServer();
  };

  checkSourceType = () => {
    if (!this.state.sourceType) {
      this.emptySourceTypeMsg();
    }
  };

  emptySourceTypeMsg = () =>
    showNotification({
      title: t({id: 'note.warning', defaultMessage: 'Warning'}),
      message: t({id: 'Select the platform first.'}),
      type: 'warning',
    });

  errorWhileUpload = () => {
    showNotification({
      title: t({id: 'Oops'}),
      message: t({
        id: 'Your file can not be uploaded directly, please send it to the support team using the instant messaging.',
      }),
      type: 'error',
    });

    this.setState({
      isUploading: false,
      errorWhileUpload: true,
      filesToUpload: this.state.filesToUpload.map(element => ({
        ...element,
        percentLoaded: null,
      })),
    });
  };

  uploadFilesToServer = () => {
    const {sensorType, filesToUpload, isToAllFields, fieldMD5} = this.state;
    const dataJson: any = {
      md5: this.props.field.MD5,
      date: this.state.selectedDate,
      sensor: sensorType,
      sourceType: this.state.sourceType,
      software: 'photoscan',
    };

    axios
      .all(
        filesToUpload.map(currentFile => {
          this.uploadStart();

          const data = new FormData();
          data.append('file', currentFile.file);

          const newDate = moment(dataJson.date);
          const newSecondValue = newDate.second() + 1 === 60 ? 0 : newDate.second() + 1;
          newDate.set({second: newSecondValue});
          dataJson.date = sensorType === 'index' ? newDate.format() : dataJson.date;

          if (!isToAllFields) {
            dataJson.fields = [fieldMD5];
          } else {
            dataJson.groupId = this.props.currentGroupId;
          }

          const updateDataJson = () => {
            dataJson.date = newDate.format();
            return dataJson;
          };

          return Server.request({
            url: `uploadFile?sensor=${sensorType}&type=${
              currentFile.mosaicIndex
            }&date=${encodeURIComponent(dataJson.date)}&md5=${this.props.field.MD5}`,
            data,
            method: 'post',
            // @ts-expect-error error leftover from convertion to strict mode, please fix
            onUploadProgress: (progressEvent: any, uploadSpeed: number) => {
              this.setState({
                filesToUpload: this.state.filesToUpload.map(fileObject => {
                  if (fileObject.file === currentFile.file) {
                    return {
                      ...fileObject,
                      percentLoaded: Math.round((progressEvent.loaded * 100) / progressEvent.total),
                      uploadSpeed,
                    };
                  }
                  return fileObject;
                }),
              });
            },
          })
            .then(() => {
              if (sensorType === 'index') {
                return Server.request({
                  url: `uploadForm`,
                  data: updateDataJson(),
                  method: 'post',
                }).catch(err => this.errorWhileUpload('error upload index json data', err));
              }
            })
            .catch((err: Error) => this.errorWhileUpload('error upload file', err));
        })
      )
      .then(() => {
        // axios all
        if (sensorType === 'bands' || sensorType === 'layer') {
          return Server.request({
            url: `uploadForm`,
            data: dataJson,
            method: 'post',
          }).catch(err => this.errorWhileUpload('error upload bands||layers json data', err));
        }
      })
      .then(() => !this.state.errorWhileUpload && this.uploadIsFinished())
      .catch(err => this.errorWhileUpload('global upload error', err));
  };

  uploadStart = () => {
    this.setState({isUploading: true});
  };

  uploadIsFinished = () => {
    this.setState({
      isUploading: false,
      selectedDate: this.defaultDate,
      filesToUpload: [],
    });

    showNotification({
      title: t({id: 'Upload completed'}),
      type: 'success',
      message: t({id: 'uploadLayersDoneOk'}),
    });
  };

  resetFiles = () => {
    this.setState({filesToUpload: []});
  };

  onRemoveFile = (file: File) => {
    this.setState(state => ({
      filesToUpload: state.filesToUpload.filter(f => f.file !== file),
    }));
  };

  allowUpload = () =>
    this.setState({allowUpload: true, mosaicBandsCurrent: this.state.mosaicBands[0]}, () => {
      setTimeout(() => {
        this.scrollContainer && this.scrollContainer.scrollTo(0, this.scrollContainer.scrollHeight);
      }, 0);
    });

  setFile = (file: File) => {
    if (!file) return;

    const isDuplicate = this.state.filesToUpload.find(
      f => f && f.file.name === file.name && f.file.size === file.size
    );

    if (isDuplicate) {
      return showNotification({
        title: t({id: 'note.error', defaultMessage: 'Error'}),
        message: t({id: 'This file is already selected. Please choose another file.'}),
        type: 'warning',
      });
    }
    this.setState({
      filesToUpload: [
        ...this.state.filesToUpload,
        {
          file,
          mosaicIndex: this.state.mosaicBandsCurrent,
        },
      ],
      allowUpload: false,
    });
  };

  onChangeIndex = (file: {file: File}, value: string) => {
    if (!file) return this.setState({mosaicBandsCurrent: value});

    this.setState({
      filesToUpload: this.state.filesToUpload.map(uploadedFile => ({
        ...uploadedFile,
        mosaicIndex: uploadedFile.file === file.file ? value : uploadedFile.mosaicIndex,
      })),
    });
  };

  updateMosaicBands = (value: string) => {
    this.setState({
      filesToUpload: this.state.filesToUpload.map(f => ({
        ...f,
        mosaicIndex: value,
      })),
      mosaicBandsCurrent: value,
    });
  };

  uploadedFilesIncludeIndex = (index: string) => {
    const {filesToUpload, mosaicBandsCurrent} = this.state;
    return !!filesToUpload.find(f => f.mosaicIndex === index) || mosaicBandsCurrent === index;
  };

  onChangeField = (fieldMD5: string) => this.setState({fieldMD5});

  onCahngeToAllFields = (isToAllFields: boolean) => this.setState({isToAllFields});

  onScrollContainerRef = (node: any) => {
    this.scrollContainer = node;
  };

  render() {
    const {selectedDateError, filesToUpload, sourceType, fieldMD5, isToAllFields} = this.state;
    const {currentFarm} = this.props;
    const disabled = this.state.isUploading;
    const mosaicBands = this.state.mosaicBands.map(band => ({
      label: sensorView(band),
      value: band,
    }));

    return (
      <>
        <div className="upload-layers-container">
          <CommonBlockWrapper>
            <TabContainer>
              {SOURCE_TYPES.map(element => (
                <div
                  id={element.value}
                  key={element.value}
                  onClick={() => this.onChangeSourceType(element.value)}
                  className={`source-type-tab ${
                    element.value === this.state.sourceType ? 'active' : ''
                  } ${disabled ? 'disabled' : ''}`}
                >
                  {element.icon()}
                </div>
              ))}
            </TabContainer>

            <CommonFieldsWrapper>
              <div className="top-block">
                <SelectField
                  id="upload-layer-fields"
                  label={t({id: 'Field'})}
                  className="select-field"
                  menuItems={this.props.fields.map(f => ({Name: f.Name, MD5: f.MD5}))}
                  itemLabel="Name"
                  itemValue="MD5"
                  simplifiedMenu
                  onChange={this.onChangeField}
                  value={fieldMD5}
                  disabled={isToAllFields}
                  anchor={{
                    x: SelectField.HorizontalAnchors.INNER_RIGHT,
                    y: SelectField.VerticalAnchors.BOTTOM,
                  }}
                />

                <FluroDatePicker
                  id="upload-layers-date-input"
                  label={t({id: 'Capture date'})}
                  error={!!selectedDateError}
                  errorText={selectedDateError}
                  selected={moment.utc(this.state.selectedDate)}
                  onChange={this.onChangeDate}
                  disabled={disabled}
                  portal
                />
              </div>

              <div>
                <Checkbox
                  id="upload-to-whole-farm"
                  className="upload-to-whole-farm-checkbox"
                  name="upload-to-whole-farm"
                  label={t(
                    {id: 'upload to all fields in the farm ({farmName})'},
                    {farmName: currentFarm?.name}
                  )}
                  checked={isToAllFields}
                  onChange={this.onCahngeToAllFields}
                />
              </div>

              <div>
                <SelectField
                  id="aerial-sensor"
                  label={t({id: 'Layer type'})}
                  itemLabel="title"
                  itemValue="id"
                  className="layer-type-select"
                  onClick={this.checkSourceType}
                  menuItems={SENSORS.filter(element =>
                    element.options.sourceTypes.includes(this.state.sourceType)
                  )}
                  simplifiedMenu
                  onChange={this.onChangeSensorType}
                  value={this.state.sensorType}
                  disabled={disabled || !sourceType}
                  anchor={{
                    x: SelectField.HorizontalAnchors.INNER_RIGHT,
                    y: SelectField.VerticalAnchors.BOTTOM,
                  }}
                />
              </div>
            </CommonFieldsWrapper>
          </CommonBlockWrapper>

          <DialogContentWrapper innerRef={this.onScrollContainerRef} excludedHeight={650}>
            {filesToUpload.map(file => (
              <UploadFile
                key={file.file.name}
                onRemoveFile={this.onRemoveFile}
                uploadedFile={file}
                disabled={disabled}
                mosaicBands={mosaicBands}
                onChangeIndex={this.onChangeIndex}
              />
            ))}

            {!filesToUpload.length || this.state.allowUpload ? (
              <div className="holder">
                <SelectField
                  id={`selected-layer-index-new-file`}
                  label={t({id: 'Index type'})}
                  placeholder={t({id: 'Index'})}
                  className="index-type new-bands-select"
                  menuItems={mosaicBands}
                  value={this.state.mosaicBandsCurrent}
                  onClick={this.checkSourceType}
                  onChange={(value: string) => this.onChangeIndex(null, value)}
                  simplifiedMenu={false}
                  repositionOnScroll={false}
                  anchor={{
                    x: SelectField.HorizontalAnchors.INNER_LEFT,
                    y: SelectField.VerticalAnchors.TOP,
                  }}
                  disabled={disabled || !sourceType}
                />
                <FileInput
                  id="upload-layer-input"
                  accept=".tif,.tiff"
                  name="images"
                  className="select-file-button"
                  primary
                  label={t({id: 'Select file'})}
                  // @ts-expect-error error leftover from convertion to strict mode, please fix
                  onClick={() => (document.getElementById('upload-layer-input').value = '')}
                  onChange={(file: File) => this.setFile(file)}
                  disabled={disabled}
                  icon={<FontIcon iconClassName="far fa-arrow-alt-circle-up" />}
                />
              </div>
            ) : null}
          </DialogContentWrapper>
        </div>

        {sourceType === 'satellite' && this.uploadedFilesIncludeIndex('RGB-NIR') ? (
          <InfoBlock>
            <FormattedMessage
              id="We will automatically process imagery coming from Planet (SkySat, PlanetScope and RapidEye). If your imagery comes from a different provider, <a>please contact us</a>."
              defaultMessage=""
              values={{
                a: (txt: string) => contactSupportTeam(txt),
              }}
            />
          </InfoBlock>
        ) : null}

        {/*{sourceType === 'UAV/drone' || sourceType === 'airplane' ? (*/}
        <InfoBlock>
          <FormattedMessage
            id="Files need to be in TIFF format (read more about uploading layers <a>here</a>). If you're having trouble uploading your imagery, <contact>please contact us</contact>."
            values={{
              a: (txt: string) => (
                <Ln
                  external
                  blank
                  href="https://help.flurosense.com/en/articles/4484904-upload-your-own-data-layers"
                >
                  {txt}
                </Ln>
              ),
              contact: (txt: string) => contactSupportTeam(txt),
            }}
          />
        </InfoBlock>
        {/*) : null}*/}

        {sourceType !== 'satellite' && this.uploadedFilesIncludeIndex('RGB-NIR') ? (
          <InfoBlock>
            {t({
              id: 'When uploading data using RGB-NIR template, Crop Insights expects a multilayer Geotiff made of 4 bands, in the following order: R | G | B | NIR.',
            })}
          </InfoBlock>
        ) : null}

        <InfoBlock visible={this.uploadedFilesIncludeIndex('MICASENSE')}>
          {t({
            id: 'When uploading data from MICASENSE, Crop Insights expects a multilayer Geotiff made of 5 or 6 bands, in the following order: B | G | R | REDGE | NIR (+ | TIR, if there’s a Thermal band).',
          })}
        </InfoBlock>

        <InfoBlock visible={this.uploadedFilesIncludeIndex('CERES')}>
          {t({
            id: 'When uploading data from CERES, Crop Insights expects a multilayer Geotiff made of 4 bands, in the following order: G | R | REDGE |NIR.',
          })}
        </InfoBlock>

        <div className="buttons-holder">
          <Button disabled={!filesToUpload.length || disabled} onClick={this.resetFiles} raised>
            {t({id: 'Clear'})}
          </Button>

          <div>
            <Button
              className="add-another add-button"
              raised
              primary
              onClick={this.allowUpload}
              disabled={!filesToUpload.length || disabled}
              iconEl={<FontIcon>add</FontIcon>}
            >
              {t({id: 'add another layer'})}
            </Button>

            <Button
              className="upload-button btn-with-icon"
              raised
              primary
              iconEl={<UploadIcon />}
              onClick={this.validateDataBeforeSend}
              disabled={!filesToUpload.length || disabled}
            >
              {t({id: 'Upload'})}
            </Button>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (s: AppStore) => ({
  field: s.map.field,
  fields: s.map.fields,
  currentFarm: selectFarmsList(s).find(f => f.id === s.global.currentGroupId),
  currentSeason: s.map.currentSeason,
  currentGroupId: s.global.currentGroupId,
});

const connector = connect(mapStateToProps);
export default connector(FormUploader);
