import type {CreateSliceOptions} from '@reduxjs/toolkit';
import {createSlice} from '@reduxjs/toolkit';
import {mergeEntities} from './merge-entities';

export const createEntitiesSlice = (
  key: string,
  actionTypeScope: string,
  {
    name,
    initialState = {},
    reducers = {},
  }: Partial<CreateSliceOptions> & Pick<CreateSliceOptions, 'name'>
) =>
  createSlice({
    name,
    initialState,
    reducers,
    extraReducers: b =>
      b.addMatcher(
        action => action.type.includes(actionTypeScope) && !!action?.payload?.entities?.[key],
        (state, action) => {
          return mergeEntities(state, action.payload.entities[key]);
        }
      ),
  });
