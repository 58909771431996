import React from 'react';
import type {BarItemProps} from '@nivo/bar';
import {Text} from 'components';
import type {SIAreaUnits} from 'modules/sustainability-insights/types';
import styled from 'styled-components';

export const StyledEFValueOutsideText = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
  flex-wrap: nowrap;
  height: 100%;
`;

// Display the value of the EF reduction on ONLY the 'scenario' bar
export const EFReductionValueOutside = ({
  bars,
  units,
}: {
  bars: BarItemProps[];
  units: SIAreaUnits;
}) => (
  <>
    {bars.map((bar: BarItemProps) => {
      const {width, height, x, y, data} = bar;
      const key = `${data.data.index}-${data.id}`;

      return (
        <g key={key} transform={`translate(${x ?? 0}, ${y})`}>
          <foreignObject
            style={{overflow: 'visible'}}
            width={outerWidth}
            height={height}
            transform={`translate(${width + 8 || 0}, ${0})`}
            key={key}
          >
            <StyledEFValueOutsideText key={key}>
              <Text variant="small" bold noMargin>
                {Number(data.value.toPrecision(3))}
              </Text>
              {data.id === 'scenario' && (
                <Text variant="small-thin" noMargin>
                  {/* When ef is 0 or -0, nivo strips the key out of the bar.data.data */}(
                  {data.data.ef ?? 0}
                  {units === 'pct' ? '%' : ''})
                </Text>
              )}
            </StyledEFValueOutsideText>
          </foreignObject>
        </g>
      );
    })}
  </>
);
