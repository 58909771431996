import React from 'react';
import {useAppSelector} from '_hooks';
import {toFixedFloat} from '_utils/number-formatters';
import {selectAllReportDashboardData, selectReportDataUnits} from 'containers/si/module/selectors';
import {selectMeasurement} from 'containers/login/login-selectors';
import {calculateScenarioRange} from 'containers/si/planning-report/dashboard/utils/calculate-scenario';
import type {BarChartProps} from 'containers/si/kpi/charts/components/bar-chart/bar-chart';
import {StyledEmissionsSection} from '../../styled-components';
import {BarChartLabelCard} from './bar-chart-label';
import {GHGReductionsCard} from './ghg-reductions';
import {SOCRemovalsCard} from './soc-removals';
import {NetEmissionsCard} from './net-emissions';
import {RoundedBar} from './rounded-bar';
import {ChartHeader} from 'containers/si/kpi/charts/components/chart-header';
import {Text} from 'components';

const barChartTheme = {
  axis: {
    domain: {
      line: {
        stroke: '#E0E0E0',
        strokeWidth: 1,
      },
    },
  },
  grid: {
    line: {
      stroke: '#E0E0E0',
      strokeWidth: 1,
    },
  },
};

export type BarChartData = {
  value: number;
  label: string;
  barDetails: BarDetails;
};

export type BarDetails = {
  color: string;
  scenario: number;
  baseline: number;
  withInterventions: number;
  difference: number;
  percentChange: number;
};

export const BarCharts = () => {
  const allDashboardData = useAppSelector(selectAllReportDashboardData); // Need ALL data for the color scale
  const units = useAppSelector(selectReportDataUnits);
  const measurement = useAppSelector(selectMeasurement);

  const {lowest, highest} = calculateScenarioRange({
    scenarios: allDashboardData,
    units,
    measurement,
  }); // calculates the range of ALL scenarios to apply to the bar chart
  const maxRange = Math.max(Math.abs(highest), Math.abs(lowest)) * 1.25;

  const commonBarProps: Partial<BarChartProps> = {
    enableGridX: true,
    gridXValues: [0],
    barComponent: RoundedBar,
    sorted: false,
    isInteractive: true,
    theme: barChartTheme,
    unit: units as string,
    margin: {left: 12, bottom: 40},
    minValue: toFixedFloat(-maxRange, units === 'pct' ? 0 : 2),
    maxValue: toFixedFloat(maxRange, units === 'pct' ? 0 : 2),
    axisLeft: null,
    axisBottom: {
      tickSize: 0,
      tickValues: [
        toFixedFloat(-maxRange, units === 'pct' ? 0 : 2),
        0,
        toFixedFloat(maxRange, units === 'pct' ? 0 : 2),
      ],
      format: value => `${value}${units === 'pct' ? '%' : ''}`,
    },
  };

  return (
    <StyledEmissionsSection>
      <ChartHeader
        title="Scope 3 emissions analysis"
        subtitle="Average annual change per acre relative to the baseline"
        tooltip={
          <Text variant="small">
            The statistics shown below are all annual per acre average change per the practice
            intervention changes relative to the baseline. The practice intervention change is the
            counter factual scenario while the baseline scenario is the factual as determined by
            Regrow’s monitor and measure methods. The x-axis values of each chart are the minimum
            and maximum values across all scenarios. This table is sorted according to the{' '}
            <Text variant="small" bold>
              Rank by
            </Text>{' '}
            filter at the top of the report.
          </Text>
        }
      />
      <div className="bar-charts">
        <BarChartLabelCard />
        <GHGReductionsCard commonBarProps={commonBarProps} />
        <SOCRemovalsCard commonBarProps={commonBarProps} />
        <NetEmissionsCard commonBarProps={commonBarProps} />
      </div>
    </StyledEmissionsSection>
  );
};
