import {Flex, FluroButton, FluroDialog, Text} from 'components';
import {Col} from 'components/flex';
import {FluroSelectLite} from 'components/fluro-select-lite/fluro-select-lite';
import React, {useCallback, useContext, useEffect, useMemo, useState} from 'react';
import {TextField} from 'react-md';
import {InputContext} from './input-context';
import {MRVSelectContainer} from './styled';
import type {onValueChange} from './types';
import {selectMeasurement} from '../../../../login/login-selectors';
import {useAppSelector} from '../../../../../_hooks';
import {convertUnit} from '_utils';
import {toFixedFloatUnsafe} from '_utils/number-formatters';
import {t} from 'i18n-utils';
import {attributeOptionsToListItems} from 'containers/mrv/base/base';

export const CustomDepth = 'CustomDepth';

type DepthSelectProps = {
  onChange: onValueChange;
  title: string;
  testId?: string;
};
/**
 * If a value `CustomDepth` is set within items a modal will open when set to that value, here the user can enter the custom value.
 */
export const DepthSelect = ({onChange, title, testId}: DepthSelectProps) => {
  const {id, value, label, defaultValue, placeholder, disabled, options} = useContext(InputContext);
  const [customValue, setCustomValue] = useState('');
  const [showDialog, setShowDialog] = useState(false);
  const measurement = useAppSelector(selectMeasurement);
  const isMetric = measurement === 'ha';
  const [isCustomValueInRange, setIsCustomValueInRange] = useState(true);

  const maxValue = isMetric ? 50.8 : 20;
  const minValue = isMetric ? 25 : 12;

  const unitType = isMetric
    ? t({id: 'Unit.cm', defaultMessage: 'cm'})
    : t({id: 'Unit.in', defaultMessage: 'in'});

  const items = useMemo(() => {
    if (!!options && options.length > 0) {
      return attributeOptionsToListItems(options);
    }

    if (isMetric) {
      return [
        {label: `0-3 ${unitType}`, value: '3'},
        {label: `3-6 ${unitType}`, value: '6'},
        {label: `6-11 ${unitType}`, value: '11'},
        {label: `11-18 ${unitType}`, value: '18'},
        {label: `18-25 ${unitType}`, value: '25'},
        {label: `25+ ${unitType}`, value: CustomDepth},
      ];
    }

    return [
      {label: `0-1 ${unitType}`, value: '3'},
      {label: `1-3 ${unitType}`, value: '6'},
      {label: `3-6 ${unitType}`, value: '11'},
      {label: `6-9 ${unitType}`, value: '18'},
      {label: `9-12 ${unitType}`, value: '25'},
      {label: `12+ ${unitType}`, value: CustomDepth},
    ];
  }, [isMetric, options, unitType]);

  const handleChange = useCallback(
    (v: string | null) => {
      if (v === CustomDepth) {
        setShowDialog(true);
        return;
      }
      onChange(v);
    },
    [onChange]
  );

  useEffect(() => {
    if (!value) {
      return;
    }

    // Reset value if it doesn't exist in the available options, and there is no valid custom value being used.
    if (
      !items.some(item => item.value === value || (value > minValue && item.value === CustomDepth))
    ) {
      handleChange(null);
    }
  }, [handleChange, items, minValue, value]);

  const customValueChange = (v: string) => {
    setCustomValue(v);
    setIsCustomValueInRange(parseInt(v) >= minValue && parseInt(v) <= maxValue);
  };

  const onSubmitCustomValue = () => {
    const preparedValue = isMetric
      ? customValue
      : toFixedFloatUnsafe(convertUnit(null, 'cm', parseFloat(customValue), true), 1);

    onChange(preparedValue);
    setShowDialog(false);
  };

  const convertedValue =
    // values are all stored in cm (metric), convert to inches when user is imperial
    !value || isMetric || items.some(i => i.value === value)
      ? value
      : toFixedFloatUnsafe(convertUnit(null, 'cm', value), 1);

  return (
    <>
      <FluroDialog
        id={`${id}-dialog`}
        visible={showDialog}
        title={title}
        onHide={() => setShowDialog(false)}
      >
        <Text>
          {t(
            {
              id: 'DepthSelect.Please enter your title below',
              defaultMessage: 'Please enter your {title} below:',
            },
            {title}
          )}
        </Text>
        <div className="mb-2">
          <TextField
            id={id}
            type={'number'}
            value={customValue}
            label={label}
            placeholder={`0 (${unitType})`}
            disabled={disabled}
            onChange={v => customValueChange(v.toString())}
            min={minValue}
            max={maxValue}
            error={!isCustomValueInRange}
            errorText={t(
              {
                id: 'DepthSelectErrorMessage',
                defaultMessage:
                  'Value should be between {classifiedMinValue} and {classifiedMaxValue} {unitType}',
              },
              {classifiedMinValue: minValue, classifiedMaxValue: maxValue, unitType}
            )}
          />
        </div>
        <Flex justifyContent="space-between">
          <Col>
            <FluroButton raised onClick={() => setShowDialog(false)}>
              {t({id: 'Cancel'})}
            </FluroButton>
          </Col>
          <Col>
            <FluroButton
              disabled={!isCustomValueInRange}
              raised
              primary
              onClick={onSubmitCustomValue}
            >
              {t({id: 'BtnLabel.Done', defaultMessage: 'Done'})}
            </FluroButton>
          </Col>
        </Flex>
      </FluroDialog>
      <MRVSelectContainer>
        <FluroSelectLite
          testId={testId}
          disabled={disabled}
          placeholder={
            convertedValue || defaultValue
              ? `${convertedValue || defaultValue} ${unitType}`
              : placeholder || t({id: 'Select depth'})
          }
          selectedValue={convertedValue || defaultValue}
          items={items}
          label={label}
          onSelect={handleChange}
        />
      </MRVSelectContainer>
    </>
  );
};
