import Yup from 'yup';
import {validatePasswordSchema} from '_utils/validate-password';

export const validationSchema = (userId: string | number) =>
  Yup.object().shape({
    email: Yup.string().email('Invalid email').required('Email is required'),
    name: Yup.string().required('Name is required'),
    surname: Yup.string().required('Surname is required'),
    password: validatePasswordSchema('password', userId !== 'new'),
    perm: Yup.string().required('Permission is required'),
    groupIds: Yup.array().of(Yup.number()),
    phone: Yup.string().test('phone', '', function () {
      return this.parent.isPhoneValid
        ? true
        : this.createError({path: this.path, message: 'Invalid phone number'});
    }),
    workspaces: Yup.object().test(
      'workspaces',
      'At least one workspace should be selected',
      function () {
        const atLeastOneWorkspaceIsOn = Object.values(this.parent.workspaces || {}).some(Boolean);
        return atLeastOneWorkspaceIsOn;
      }
    ),
  });
