import type {ComponentType} from 'react';
import React from 'react';
import Yup from 'yup';
import {yupResolver} from '@hookform/resolvers/yup';
import {Controller, useForm} from 'react-hook-form';
import {Flex, FluroButton, FluroDialog, FluroInput, Text} from 'components';

type FilterNameFormProps = {
  onHide: () => void;
  name?: string;
  submit: (name: string) => void;
};

export const FilterNameForm: ComponentType<FilterNameFormProps> = ({onHide, name = '', submit}) => {
  const {handleSubmit, control} = useForm<{
    name: string;
  }>({
    resolver: yupResolver(
      Yup.object().shape({
        name: Yup.string().required().max(25),
      })
    ),
    mode: 'onChange',
    defaultValues: {name},
  });

  return (
    <FluroDialog
      id="save-filter"
      initialFocus="#filter-name"
      focusOnMount
      title="Save Dashboard View"
      visible={true}
      onHide={onHide}
      width={400}
    >
      <Text secondary variant="medium" elementType={'div'}>
        Save your current Sustainability Reporting filters so you can revisit this view anytime
      </Text>
      <form onSubmit={handleSubmit(({name: newName}) => submit(newName))}>
        <Controller
          name="name"
          control={control}
          render={({field: {value, onChange}, fieldState: {error}}) => (
            <>
              <FluroInput
                id={'filter-name'}
                value={value}
                onChange={onChange}
                label="Name"
                error={!!error}
                errorText={error?.message}
              />
            </>
          )}
        />

        <Flex className="margin-top-10" fullWidth justifyContent="space-between">
          <FluroButton onClick={onHide} raised blank>
            Cancel
          </FluroButton>

          <FluroButton onClick={handleSubmit(({name: newName}) => submit(newName))} raised primary>
            Save
          </FluroButton>
        </Flex>
      </form>
    </FluroDialog>
  );
};
