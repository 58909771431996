import {combineReducers} from '@reduxjs/toolkit';
import {accessSlice} from './slices/access';
import {entitiesSlice} from './slices/entities';
import {programsSlice} from './slices/programs';
import {reportsSlice} from './slices/reports';
import {kpiFilterSlice} from './slices/kpiFilter';
import {mapSlice} from './slices/map';
import {supplyShedMapSlice} from './slices/supply-shed-map';
import type {SI2State} from './types';
import {kpisSlice} from './slices/kpis';

export const {
  setSIYearsFilter,
  setSIVisibleSubsectionIds,
  toggleSISubsectionVisibility,
  setSISelectedCropTypes,
  setCurrentKPIFilter,
} = kpiFilterSlice.actions;
export const {loadSIProgramsList, setCurrentProgram, setProcessing, setDataExportAgreement} =
  programsSlice.actions;
export const {
  setNewProgramId,
  setNewName,
  setNewStartYear,
  setNewEndYear,
  setNewReportType,
  setNewModel,
  setNewProtocol,
  setNewAreas,
  setNewCrops,
  setCropStepBlocked,
  setNewInterventions,
  setDashboardProgramId,
  setDashboardReportId,
  setDashboardReportType,
  setDashboardPageIndex,
  setDashboardOrderBy,
  setDashboardOrderDirection,
  setDashboardTopScenarios,
  setDashboardDataUnits,
  setDashboardCropFilter,
  toggleDashboardCropFilter,
  setDashboardSourcingRegionFilter,
  toggleDashboardSourcingRegionFilter,
  setDashboardInterventionFilter,
  toggleDashboardInterventionFilter,
  setDashboardShowIneligible,
  setDashboardCarbonPrice,
  setDashboardCarbonUnit,
  setDashboardSelectedScenario,
  setDashboardPracticeAdoption,
} = reportsSlice.actions;
export const {switchMapStyle} = mapSlice.actions;
export const {
  setSelectLayerID,
  setInteractionType,
  setShouldShowMapTools,
  setSubsectionGeometries,
  addSubsectionGeometries,
  removeSubsectionGeometries,
  setSubsectionSelectedFeatures,
  addSubsectionSelectedFeatures,
  removeSubsectionSelectedFeatures,
  setProgramGeometries,
  setShowProgramGeometries,
} = supplyShedMapSlice.actions;

const siReducer = combineReducers<SI2State>({
  access: accessSlice.reducer,
  programs: programsSlice.reducer,
  reports: reportsSlice.reducer,
  entities: entitiesSlice.reducer,
  kpiFilter: kpiFilterSlice.reducer,
  map: mapSlice.reducer,
  supplyShedMap: supplyShedMapSlice.reducer,
  kpis: kpisSlice.reducer,
});

export default siReducer;
