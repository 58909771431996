// @ts-nocheck
import type {Serie} from '@nivo/line';
import {createSelector} from '@reduxjs/toolkit';
import chroma from 'chroma-js';
import LoGet from 'lodash/get';
import LoGroupBy from 'lodash/groupBy';
import LoRange from 'lodash/range';
import LoReduce from 'lodash/reduce';
import {selectCropTypes} from 'modules/global/selectors';
import {sortByKeyReverseUnsafe, sortByKey} from '_utils/sorters';
import {calculatePercentage} from '_utils/pure-utils';
import {
  coverCropsColorScale,
  SUMMARY_BARS_PREVIEW_THRESHOLD,
  SUMMER_CROP_TYPE_TO_CROP_TYPE_MAP,
} from '../constants';
import type {
  AreaId,
  MetricsStats,
  MetricStatDatum,
  MetricStatSummaryDatum,
  MetricTypeKeys,
  SIAdopterType,
  SIMetricData,
  SIStateChildMeta,
  StateCCStats,
  StateFP,
  StatsDatum,
  StatsDatumValue,
  SummaryChartData,
} from '../types';
import {calculateScale, isInRange, isYearsDiff, parseAreaId} from '../utils';
import {
  getAreaId,
  selectActiveAdopterTypeFilter,
  selectActiveGeometriesIds,
  selectAdopterTypeFilter,
  selectAggLevel,
  selectAreaUnits,
  selectCurrentMeta,
  selectGeometriesColors,
  selectIsYearsDiff,
  selectMetrics,
  selectMetricsById,
  selectMetricsTypeFilter,
  selectSIChartGroupingType,
  selectSIData,
  selectSIFilter,
  selectStatesMeta,
  selectSummary,
  selectVisibleGeometriesIds,
  selectYearsFilter,
} from './selectors';
import {getDefaultExtremaPerUnits} from 'containers/map/features/sustainability-insights/filters-panel/cards/utils';

export const selectCoverCropsCardState = createSelector([selectSIFilter], f => f.coverCrops);
export const selectCoverCropsChartTab = createSelector(
  [selectCoverCropsCardState],
  cc => cc.chartTab
);
export const selectCropIncludeCategories = createSelector(
  [selectCoverCropsCardState],
  cc => cc.includeCategories
);
export const selectCropCardRange = createSelector([selectCoverCropsCardState], cc => cc.range);

export const selectMetricTypeKeysMap = createSelector(
  [
    selectActiveAdopterTypeFilter,
    s => selectAdopterTypeFilter(s, 'operationAdopterType'),
    s => selectAdopterTypeFilter(s, 'fieldAdopterType'),
  ],
  (adopterTypeFilter: SIAdopterType, operationsAdopterType, fieldsAdopterType) => {
    const metricTypeKeysMap: MetricTypeKeys = {
      ac: [`cc_area_ac.1`, `total_arable_area_ac`],
      op: [`cc_operations_nb`, `count_operations`],
    };

    if (operationsAdopterType) {
      // for set adopter type of Operations filter type the "total_arable_area_ac" is replaced with "cc_operations_ac"
      metricTypeKeysMap.ac[1] = 'cc_operations_ac';
    }

    switch (adopterTypeFilter) {
      case 'new':
        metricTypeKeysMap.op[0] = 'cc_operations_new_nb';
        if (operationsAdopterType) metricTypeKeysMap.ac[0] = 'cc_operations_new_ac'; // for only adopter type === "new" of Operations filter type
        if (fieldsAdopterType) metricTypeKeysMap.ac[0] = 'cc_new_area_ac'; // for only adopter type === "new" of Fields filter type
        break;
      case 'consistent':
        metricTypeKeysMap.op[0] = 'cc_operations_consistent_nb';
        if (operationsAdopterType) metricTypeKeysMap.ac[0] = 'cc_operations_consistent_ac'; // for only adopter type === "consistent" of Operations filter type
        if (fieldsAdopterType) metricTypeKeysMap.ac[0] = 'cc_consistent_area_ac'; // for only adopter type === "consistent" of Fields filter type
        break;
    }
    return metricTypeKeysMap;
  }
);

const strategies = {
  singlePct: (source: number, total: number): number => {
    return calculatePercentage(source, total);
  },
  diffNum: (fromSource: number, toSource: number): number => {
    if (!Number.isFinite(fromSource) || !Number.isFinite(toSource)) return null;
    return toSource - fromSource;
  },
  diffPct: (fromSource: number, fromTotal: number, toSource: number, toTotal: number): number => {
    if ([fromSource, fromTotal, toSource, toTotal].every(n => !n)) return null;
    const value =
      calculatePercentage(toSource, toTotal) - calculatePercentage(fromSource, fromTotal);

    return value;
  },
};

function getStatValues(data: SIMetricData, keys: [string, string]): [number | null, number | null] {
  const [sourceKey, totalKey] = keys;
  const source = LoGet(data, sourceKey, null);
  const total = LoGet(data, totalKey, null);

  return [source, total];
}

const singleStrategyPerUnits = (
  data: SIMetricData,
  units: 'ac' | 'op',
  metricTypeKeysMap: MetricTypeKeys
): StatsDatumValue => {
  const metricTypeKeys = metricTypeKeysMap[units];
  const [num, total] = getStatValues(data, metricTypeKeys);
  const pct = strategies.singlePct(num, total);

  return {
    num,
    pct,
    total,
  };
};

const singleStrategy =
  (yearFrom: number) =>
  (dataPerYear: Record<number, SIMetricData>, metricTypeKeysMap: MetricTypeKeys): StatsDatum => {
    const data = dataPerYear[yearFrom];
    const result = {
      ac: singleStrategyPerUnits(data, 'ac', metricTypeKeysMap),
      op: singleStrategyPerUnits(data, 'op', metricTypeKeysMap),
    };

    return result;
  };

const diffStrategyPerUnits = (
  dataFrom: SIMetricData,
  dataTo: SIMetricData,
  units: 'ac' | 'op',
  metricTypeKeysMap: MetricTypeKeys
): StatsDatumValue => {
  const metricTypeKeys = metricTypeKeysMap[units];
  const [from, totalFrom] = getStatValues(dataFrom, metricTypeKeys);
  const [to, totalTo] = getStatValues(dataTo, metricTypeKeys);

  if ([from, totalFrom, to, totalTo].every(v => !v)) return null;

  const num = strategies.diffNum(from, to);
  const pct = strategies.diffPct(from, totalFrom, to, totalTo);

  return {num, pct, numFrom: from, numTo: to, totalFrom, totalTo};
};

const diffStrategy =
  (yearFrom: number, yearTo: number) =>
  (dataPerYear: Record<number, SIMetricData>, metricTypeKeysMap: MetricTypeKeys): StatsDatum => {
    const dataFrom = dataPerYear[yearFrom];
    const dataTo = dataPerYear[yearTo];
    const ac = diffStrategyPerUnits(dataFrom, dataTo, 'ac', metricTypeKeysMap);
    const op = diffStrategyPerUnits(dataFrom, dataTo, 'op', metricTypeKeysMap);

    const result = {
      ac,
      op,
    };

    return result;
  };

const prepareTrendData =
  (yearFrom: number, yearTo: number) =>
  (
    dataPerYear: {[year: number]: SIMetricData},
    metricTypeKeysMap: MetricTypeKeys
  ): MetricStatDatum['values']['trends'] => {
    const yearsRange = LoRange(yearFrom, yearTo).concat(yearTo);

    const mapYears = (strategy: (data: SIMetricData) => number) =>
      yearsRange.map(year => {
        const data = dataPerYear[year];
        const value = strategy(data);

        return {x: year, y: value};
      });

    const trends = {
      'ac-num': mapYears((data: SIMetricData) => LoGet(data, metricTypeKeysMap.ac[0])),
      'ac-pct': mapYears((data: SIMetricData) => {
        const [ac, acTotal] = getStatValues(data, metricTypeKeysMap.ac);

        return strategies.singlePct(ac, acTotal);
      }),
      'op-num': mapYears((data: SIMetricData) => LoGet(data, metricTypeKeysMap.op[0])),
      'op-pct': mapYears((data: SIMetricData) => {
        const [op, opTotal] = getStatValues(data, metricTypeKeysMap.op);

        return strategies.singlePct(op, opTotal);
      }),
    };

    return trends;
  };

export const selectCoverCropStats = createSelector(
  [
    selectVisibleGeometriesIds,
    selectMetrics,
    selectYearsFilter,
    selectGeometriesColors,
    selectAggLevel,
    selectCurrentMeta,
    selectSummary,
    selectMetricTypeKeysMap,
  ],
  (
    availableIds,
    data,
    years,
    colorsMap,
    aggLevel,
    currentMeta,
    summary,
    metricKeysMap
  ): MetricsStats => {
    if (!data || !availableIds?.length || !years?.length || !years[0]) return {stats: null};
    const [yearFrom, yearTo] = years;
    const isDiff = isYearsDiff(years);
    const retrieveValue = isDiff ? diffStrategy(yearFrom, yearTo) : singleStrategy(yearFrom);
    const getTrendValues = isDiff && prepareTrendData(yearFrom, yearTo);

    const stats = availableIds.reduce<{[key: number]: MetricStatDatum}>((acc, id: number) => {
      const dataPerYear = data[id];
      if (!dataPerYear) {
        return acc;
      }
      const {name, statefp} = (currentMeta[id] || {}) as SIStateChildMeta;

      const values = retrieveValue(dataPerYear, metricKeysMap);
      if (!values) return acc;
      const statData: MetricStatDatum = {
        id,
        name,
        statefp,
        values,
        color: colorsMap[id],
      };
      if (isDiff) {
        statData.values.trends = getTrendValues(dataPerYear, metricKeysMap);
      }
      acc[id] = statData;
      return acc;
    }, {});

    const results: MetricsStats = {stats};

    // Bring in summary data to add to results
    const summaryValues: MetricStatSummaryDatum = {
      values: retrieveValue(summary, metricKeysMap),
    };
    if (isDiff) {
      summaryValues.values.trends = getTrendValues(summary, metricKeysMap);
    }

    results.summary = summaryValues;

    if (aggLevel !== 'state') {
      const groupedByState = LoGroupBy(Object.values(stats), 'statefp');
      const stateStats = LoReduce<any, Record<StateFP, StateCCStats>>(
        groupedByState,
        (statsAcc, stateItems, statefp) => {
          type ValuesPerUnits = {
            ac: Partial<StatsDatumValue>;
            op: Partial<StatsDatumValue>;
          };

          const accumulateStatsValues = (
            acc: Partial<StatsDatumValue>,
            values: Partial<StatsDatumValue> = {}
          ) => {
            if (!values) return;
            const accumulate = (innerAcc: any, innerValues: any, key: string) =>
              Number.isFinite(innerValues?.[key])
                ? innerAcc?.[key] + innerValues?.[key]
                : innerAcc?.[key];

            acc.num = accumulate(values, acc, 'num');
            acc.total = accumulate(values, acc, 'total');
            acc.totalFrom = accumulate(values, acc, 'totalFrom');
            acc.totalTo = accumulate(values, acc, 'totalTo');
            acc.numFrom = accumulate(values, acc, 'numFrom');
            acc.numTo = accumulate(values, acc, 'numTo');
          };

          const valuesPerUnits: ValuesPerUnits = stateItems.reduce(
            (acc: ValuesPerUnits, {values}: MetricStatDatum) => {
              const {ac, op} = values;
              const {ac: acAcc, op: opAcc} = acc;
              accumulateStatsValues(acAcc, ac);
              accumulateStatsValues(opAcc, op);
              return acc;
            },
            {
              ac: {num: 0, total: 0, numTo: 0, numFrom: 0, totalFrom: 0, totalTo: 0},
              op: {num: 0, total: 0, numTo: 0, numFrom: 0, totalFrom: 0, totalTo: 0},
            }
          );

          statsAcc[parseAreaId(statefp)] = {
            ac: {
              num: valuesPerUnits.ac.num,
              pct: calculatePercentage(valuesPerUnits.ac.num, valuesPerUnits.ac.total),
              diff: {
                num: valuesPerUnits.ac.numTo - valuesPerUnits.ac.numFrom,
                pct:
                  calculatePercentage(valuesPerUnits.ac.numTo, valuesPerUnits.ac.totalTo) -
                  calculatePercentage(valuesPerUnits.ac.numFrom, valuesPerUnits.ac.totalFrom),
              },
            },
            op: {
              num: valuesPerUnits.op.num,
              pct: calculatePercentage(valuesPerUnits.op.num, valuesPerUnits.op.total),
              diff: {
                num: valuesPerUnits.op.numTo - valuesPerUnits.op.numFrom,
                pct:
                  calculatePercentage(valuesPerUnits.op.numTo, valuesPerUnits.op.totalTo) -
                  calculatePercentage(valuesPerUnits.op.numFrom, valuesPerUnits.op.totalFrom),
              },
            },
          };

          return statsAcc;
        },
        {}
      );

      results.stateStats = stateStats;
    }

    return results;
  }
);

export const selectActiveStatsList = createSelector(
  [selectActiveGeometriesIds, selectCoverCropStats],
  (ids, stats) => {
    if (!stats.stats) return [];
    return ids.reduce((acc: Array<MetricStatDatum>, id) => {
      const stat = stats.stats[id];
      if (!stat) return acc;
      return [...acc, stat];
    }, []);
  }
);

export const selectCropStatsById = createSelector(
  [selectCoverCropStats, getAreaId],
  ({stats}, id) => stats?.[id]
);

type Extrema = {num: {min: number; max: number}; pct: {min: number; max: number}};

export const selectCropStatsExtremaPerUnits = createSelector(
  [selectActiveStatsList, selectMetricsTypeFilter],
  (stats, metricType): Extrema => {
    if (!stats?.length) {
      return getDefaultExtremaPerUnits();
    }

    const values = stats.reduce(
      (acc, stat) => {
        const numStat = stat?.values?.[metricType]?.num || 0;
        const pctStat = stat?.values?.[metricType]?.pct || 0;

        acc.num = acc.num.concat(numStat);
        acc.pct = acc.pct.concat(pctStat);
        return acc;
      },
      {num: [], pct: []}
    );

    return {
      num: {
        min: Math.min(...values.num),
        max: Math.max(...values.num),
      },
      pct: {
        min: Math.min(...values.pct),
        max: Math.max(...values.pct),
      },
    };
  }
);

export const selectCropStatsExtrema = createSelector(
  [selectAreaUnits, selectCropStatsExtremaPerUnits],
  (units, extrema) => extrema[units]
);

export const selectColorScaleValues = createSelector([selectCropStatsExtrema], ({min, max}) => {
  if (max <= 0) {
    return {colors: ['red', 'white']};
  } else if (min >= 0) {
    return {colors: ['white', 'green']};
  }

  const zero = calculateScale(0, min, max);

  return {colors: ['red', 'white', 'green'], domain: [0, zero, 1]};
});

export const selectCropColorScale = createSelector(
  [selectIsYearsDiff, selectColorScaleValues, selectCropIncludeCategories],
  (isDiff, {colors, domain}, includeCategories) => {
    if (!isDiff) return {colors: coverCropsColorScale[includeCategories[0]]};

    return {colors, domain};
  }
);

export const selectCropChromaScale = createSelector(selectCropColorScale, ({colors, domain}) => {
  const scale = chroma.scale(colors);
  if (domain) {
    scale.domain(domain);
  }

  return scale;
});

export const selectCropColorById = createSelector(
  [
    selectCropStatsById,
    selectCropStatsExtrema,
    selectCropChromaScale,
    selectCoverCropsChartTab,
    selectAreaUnits,
    selectMetricsTypeFilter,
  ],
  (stats, {max, min}, colorScale, tab, units, metricType) => {
    if (!stats) return;
    if (tab === 'trend') return stats.color;
    const value = stats.values[metricType]?.[units];
    const scale = calculateScale(value, min, max);
    const color = colorScale(scale).css();

    return color;
  }
);

export const selectSummerCropTypes = createSelector([selectSIData], d => d.summerCropTypes);

export const selectSummerCropTypeOptions = createSelector(
  [selectSummerCropTypes, selectCropTypes],
  (summerCropTypes, cropTypes) => {
    const options = Object.keys(summerCropTypes)
      .map((value: string) => {
        const type = SUMMER_CROP_TYPE_TO_CROP_TYPE_MAP[value];
        if (!type || !cropTypes[type]) {
          return null;
        }
        const {label, color, icon} = cropTypes[type];

        return {label, value, color, icon, type};
      })
      .filter(Boolean);

    return options;
  }
);

export const selectCoverCropsSummaryChartData = createSelector(
  [
    selectActiveStatsList,
    selectCoverCropStats,
    selectCropCardRange,
    selectAreaUnits,
    selectMetricsTypeFilter,
    selectStatesMeta,
    selectAggLevel,
    selectIsYearsDiff,
    selectActiveAdopterTypeFilter,
    selectSIChartGroupingType,
  ],
  (
    stats,
    {stateStats, summary},
    range,
    units,
    metricType,
    statesMeta,
    aggLevel,
    isDiff,
    adopterTypeFilter,
    groupingType
  ): SummaryChartData & {values: number[]; diff: {pct: number; num: number}} => {
    if (!stats?.length || !range) return null;
    const totalArea = metricType === 'op' ? summary.values.op.total : summary.values.ac.num;

    let values: number[] = [];

    let chartData: (MetricStatDatum & {value: number})[] = stats.reduce((acc, datum) => {
      const value = datum.values[metricType]?.[units];
      if (!Number.isFinite(value)) return acc;
      values = values.concat(value || 0);
      const chartDatum = {value, ...datum};

      return acc.concat(chartDatum);
    }, []);

    // Apply filters for chart data
    const preview = chartData.length > SUMMARY_BARS_PREVIEW_THRESHOLD;
    if (preview && range) {
      chartData = chartData.filter(d => isInRange(d.value, range));
    }

    // From summary data
    if (!summary?.values[metricType]) return null;
    const {numFrom, numTo, totalFrom, totalTo} = summary.values[metricType];

    // Compute required data from the filtered chart data
    const {chartGeometries, chartGeometriesIds} = sortByKeyReverseUnsafe(
      chartData,
      'value'
    ).reduce<{
      chartGeometries: Record<AreaId, boolean>;
      chartGeometriesIds: AreaId[];
    }>(
      (acc, datum) => {
        // eslint-disable-next-line @typescript-eslint/no-shadow
        const {chartGeometries, chartGeometriesIds} = acc;
        const {id} = datum;

        return {
          chartGeometries: {...chartGeometries, [id]: true},
          chartGeometriesIds: chartGeometriesIds.concat(id),
        };
      },
      {
        chartGeometries: {},
        chartGeometriesIds: [],
      }
    );

    if (groupingType === 'grouped') {
      const groupedByState = LoGroupBy(chartData, 'statefp');

      const groupedList = Object.keys(groupedByState).reduce((acc, stateFP) => {
        const value = isDiff
          ? stateStats?.[Number(stateFP)]?.[metricType]?.diff?.[units]
          : stateStats?.[Number(stateFP)]?.[metricType]?.[units];
        if (!value) return acc;
        const name = statesMeta[parseAreaId(stateFP)]?.name?.toUpperCase?.();
        const data = groupedByState[stateFP];

        return [
          ...acc,
          {
            data,
            name,
            value,
          },
        ];
      }, []);

      chartData = sortByKeyReverseUnsafe(groupedList, 'value');
    }
    return {
      type: groupingType,
      chartGeometries,
      chartGeometriesIds,
      chartData,
      values,
      preview,
      totalValue: totalArea,
      diff: {
        pct: calculatePercentage(numTo, totalTo) - calculatePercentage(numFrom, totalFrom),
        num: numTo - numFrom,
      },
    };
  }
);

export const selectCoverCropsSummaryChartIdsMap = createSelector(
  [selectCoverCropsSummaryChartData],
  data => data?.chartGeometries || {}
);

export const isGeometryInCoverCropsSummaryChart = createSelector(
  [selectCoverCropsSummaryChartIdsMap, getAreaId],
  (map, id) => Boolean(map?.[id])
);

export const selectCoverCropsTrendChartData = createSelector(
  [
    selectActiveStatsList,
    selectCurrentMeta,
    selectAreaUnits,
    selectMetricsTypeFilter,
    selectAggLevel,
  ],
  (stats, currentMeta, units, metricType): Serie[] => {
    if (!stats?.length) return [];
    const chartData = stats.map(datum => {
      const {
        id,
        color,
        values,
        values: {trends},
      } = datum;
      const {name} = currentMeta?.[id] || {};

      return {
        id,
        color,
        data: trends[`${metricType}-${units}`],
        name,
        value: values[metricType]?.[units],
      };
    });

    return sortByKey(chartData, 'value');
  }
);

export const selectCCGeometryData = createSelector(
  [
    getAreaId,
    selectMetricsById,
    selectCropStatsById,
    selectMetricsTypeFilter,
    selectAreaUnits,
    selectYearsFilter,
    selectMetricTypeKeysMap,
  ],
  (id, metrics, stats, metricsType, units, years, metricTypeKeysMap) => {
    const value = stats?.values[metricsType]?.[units];
    if (!Number.isFinite(value)) return null;

    const [yearFrom, yearTo] = years ?? [];
    if (yearFrom && yearTo) {
      return {
        metricsType,
        units,
        value,
        years,
      };
    }

    const [ccKey, totalKey] = metricTypeKeysMap[metricsType];
    const metric = metrics[yearFrom];
    const cc = LoGet(metric, ccKey, 0);
    const total = LoGet(metric, totalKey, 0);
    const pct = calculatePercentage(cc, total);

    return {
      metricsType,
      pct,
      total,
      units,
      value: cc,
    };
  }
);
