import {t} from 'i18n-utils';
import type {ComponentType} from 'react';
import React from 'react';
import {InputSelect} from './input-select';
import type {onValueChange} from './types';

type Props = {onChange: onValueChange};

export const ResidueHarvestedSelect: ComponentType<Props> = ({onChange}) => {
  const options = [
    {
      value: '0%',
      label: t({id: 'Label.No residue harvested', defaultMessage: 'No residue harvested'}),
    },
    {
      value: '25%',
      label: t({id: 'HarvestedPercent', defaultMessage: '{percent}% harvested'}, {percent: 25}),
    },
    {
      value: '50%',
      label: t({id: 'HarvestedPercent', defaultMessage: '{percent}% harvested'}, {percent: 50}),
    },
    {
      value: '75%',
      label: t({id: 'HarvestedPercent', defaultMessage: '{percent}% harvested'}, {percent: 75}),
    },
    {
      value: '100%',
      label: t({id: 'All residue harvested', defaultMessage: 'All residue harvested'}),
    },
  ];

  return (
    <InputSelect
      testId="MRV-table-cell-input-select--Residue harvested"
      options={options}
      onChange={onChange}
    />
  );
};
