// @ts-nocheck
import type {PayloadAction} from '@reduxjs/toolkit';
import {createSlice} from '@reduxjs/toolkit';
import {getGetURLParam} from '_utils/pure-utils';
import {currentYear} from '../base/base';
import {YearQuarter} from '../base/crop-practice';
import type {CarbonState, DNDCResults, OFSProgress} from './types';
import {EnrollmentStep} from './types';

export const isStep = (step: string): step is EnrollmentStep => {
  return [
    EnrollmentStep.Fields,
    EnrollmentStep.CropPractices,
    EnrollmentStep.AssignPractices,
    EnrollmentStep.ViewOutcomes,
  ].includes(step as EnrollmentStep);
};

const urlStep = getGetURLParam('enrollmentStep');

const initialState: CarbonState = {
  step: isStep(urlStep) ? urlStep : EnrollmentStep.Fields,
  year: currentYear,
  quarter: YearQuarter.SummerCrop,
  farmId: undefined,
  enrolledFields: {},
  importedFields: {},
  overlapFields: [],
  eligibleRegionFields: {},
  ineligibleRegionFields: {},
  assignPracticesSelectedFields: [],
  dndcResults: {payment: 0, tonsSequestered: 0, detailedItems: []},
  dndcPercentCompleted: 0,
  ofsProgress: {
    hasOptisValues: false,
    hasUserValues: false,
    percentCompleted: 0,
  },
};

export const carbonSlice = createSlice({
  name: 'carbon',
  initialState,
  reducers: {
    updateStep: (state, action: PayloadAction<EnrollmentStep>) => {
      state.step = action.payload;
    },
    setYear: (state, action: PayloadAction<number>) => {
      state.year = action.payload;
    },
    setQuarter: (state, action: PayloadAction<YearQuarter>) => {
      state.quarter = action.payload;
    },
    setFarmId: (state, action: PayloadAction<number>) => {
      state.farmId = action.payload;
    },
    setEnrolledFields: (state, action: PayloadAction<{[fieldId: number]: boolean}>) => {
      state.enrolledFields = {...state.enrolledFields, ...action.payload};
    },
    setImportedFields: (state, action: PayloadAction<{[fieldId: number]: boolean}>) => {
      state.importedFields = {...state.importedFields, ...action.payload};
    },
    setOverlapFields: (state, action: PayloadAction<number[][]>) => {
      state.overlapFields = action.payload;
    },
    setEligibleRegionFields: (state, action: PayloadAction<{[fieldId: number]: boolean}>) => {
      state.eligibleRegionFields = {...state.eligibleRegionFields, ...action.payload};
    },
    setIneligibleRegionFields: (state, action: PayloadAction<{[fieldId: number]: boolean}>) => {
      state.ineligibleRegionFields = {...state.ineligibleRegionFields, ...action.payload};
    },
    setAssignPracticeSelectedFields: (state, action: PayloadAction<number[]>) => {
      state.assignPracticesSelectedFields = action.payload;
    },
    removeFields: (state, action: PayloadAction<{fieldIds: number[]}>) => {
      const enrolledFields = {...state.enrolledFields};

      Object.keys(enrolledFields).forEach((fieldId: string) => {
        if (action.payload.fieldIds.includes(parseInt(fieldId))) {
          delete enrolledFields[parseInt(fieldId)];
        }
      });

      state.enrolledFields = enrolledFields;
    },
    setDNDCResults: (state, action: PayloadAction<DNDCResults>) => {
      state.dndcResults = action.payload;
    },
    setDNDCPercentCompleted: (state, action: PayloadAction<number>) => {
      state.dndcPercentCompleted = action.payload;
    },
    setOFSProgress: (state, action: PayloadAction<OFSProgress>) => {
      state.ofsProgress = action.payload;
    },
  },
});

export const {
  updateStep,
  setYear,
  setQuarter,
  setFarmId,
  setEligibleRegionFields,
  setIneligibleRegionFields,
  setOverlapFields,
  setEnrolledFields,
  setImportedFields,
  setAssignPracticeSelectedFields,
  removeFields,
  setDNDCResults,
  setDNDCPercentCompleted,
  setOFSProgress,
} = carbonSlice.actions;
