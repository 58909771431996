import {useTheme} from 'styled-components';

export const useGreenToRedColors = () => {
  const theme = useTheme();

  // For a scale that looks something like -50% -------- 0% -------- 50%
  // There is a highest color and a lowest color for both the positive and negative sides
  // (-50%)highestColorNegative -------- lowestColorNegative (0%) lowestColorPositive -------- highestColorPositive (50%)
  const [highestColorNegative, lowestColorNegative, lowestColorPositive, highestColorPositive] = [
    theme.colorPalette.fs_main.green_800,
    theme.colorPalette.fs_main.green_400,
    // [SF 2023/01/25] TODO: low color values for red in current theme are too light to see in our data
    // visualizations making them unusable; future design system will have updated color values, and this will
    // be updated at that time. Re: discussion with Patrick Gill
    '#F0C6D5',
    theme.colorPalette.fs_category.red_600,
  ];

  return {highestColorNegative, lowestColorNegative, lowestColorPositive, highestColorPositive};
};
