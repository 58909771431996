// @ts-nocheck
import {t} from 'i18n-utils';
import React, {useMemo, useState} from 'react';
import {FluroAutocomplete, FluroChip} from 'components';
import {sortByKey} from '_utils/sorters';
import {escapeRegExp, setGetParamToURL} from '_utils/pure-utils';
import {selectFarm} from 'modules/farms/actions';
import {cancelDrawingPivotCenter, resetSensitiveParams} from 'containers/map/actions';
import {toggleGlobalDialog} from 'modules/global/actions';
import cn from 'classnames';
import {AsyncStatusType, Status} from 'modules/helpers';
import {Button, CircularProgress, FontIcon} from 'react-md';
import {FarmsLoaderWrapper} from '../selectors-toolbar.styled';
import ExternalServiceIcon from 'components/external-service-icon/external-service-icon';
import {useAppDispatch, useAppSelector} from '_hooks';
import {selectHasFarmsOrGroupsIds, selectIsAdmin, selectUser} from '../../login/login-selectors';
import {
  selectDemoFarmsListIds,
  selectDemoFarmsObject,
  selectFarmsList,
} from 'modules/farms/selectors';
import {selectAsyncRequestStatus} from 'modules/global/selectors';
import {selectCurrentFarm} from 'containers/map/reducer/selectors';
import type {Farm} from 'containers/map/types';

const NO_GROWER = ' __grower_less__';

type DataType = Array<
  React.ReactElement<any> | string | number | {[dataLabel: string]: string | number}
>;

type Props = {
  mobileView?: boolean; // used to display data in FluroChips in certain areas (not in the toggle header) for mobiles
};

const SelectorFarms = ({mobileView}: Props) => {
  const dispatch = useAppDispatch();
  const farmId = useAppSelector(s => s.global.currentGroupId);
  const user = useAppSelector(selectUser);
  const farms = useAppSelector(selectFarmsList);
  const demoFarmsObject = useAppSelector(selectDemoFarmsObject);
  const demoFarmsIdsList = useAppSelector(selectDemoFarmsListIds);
  const growerName = useAppSelector(s => s.global.growerName);
  const farmsLoadingStatus = useAppSelector(s =>
    selectAsyncRequestStatus(s, AsyncStatusType.farmsList)
  );
  const isAdmin = useAppSelector(selectIsAdmin);
  const hasFarms = useAppSelector(selectHasFarmsOrGroupsIds);

  const currentFarm = useAppSelector(selectCurrentFarm);

  const [focused, toggleFocus] = useState(false);

  const buildList = (farms: Array<Farm>, currentFarmID: number) => {
    const demoFarmsCount = farms.filter(f => demoFarmsIdsList.includes(f.id)).length;
    const _growersObject: any = {};
    const listOfFarms: Array<any> = [];
    let {regularFarms, demoFarms} = farms.reduce(
      ({regularFarms, demoFarms}, farm) =>
        demoFarmsIdsList.includes(farm.id) // separate demo farms from other
          ? user.groupIds.includes(farm.id) // return only farms user have access even for admins
            ? {regularFarms, demoFarms: [...demoFarms, farm]}
            : {regularFarms, demoFarms}
          : {regularFarms: [...regularFarms, farm], demoFarms},
      {regularFarms: [], demoFarms: []}
    );

    regularFarms.forEach(g => {
      const growerName = g.growerName ? g.growerName.trim() : NO_GROWER;
      if (_growersObject[growerName]) {
        _growersObject[growerName].push({...g});
      } else {
        _growersObject[growerName] = [{...g}];
      }
    });

    // sort growers
    const sortedGrowers = Object.keys(_growersObject).sort((a, b) => {
      if (a.toLowerCase() > b.toLowerCase()) {
        return 1;
      }

      if (a.toLowerCase() < b.toLowerCase()) {
        return -1;
      }

      return 0;
    });

    sortedGrowers.forEach(growerName => {
      if (growerName !== NO_GROWER) {
        listOfFarms.push(
          <div key={growerName} className="grower-name-item">
            {growerName}
          </div>
        );
      }

      const sortedFarms: Farm[] = sortByKey(_growersObject[growerName], 'name', false);

      sortedFarms.forEach((g, i) => {
        listOfFarms.push({
          label: [
            <div
              key={`${i}-one-${growerName}-div`}
              className={`${g.id === currentFarmID ? 'active-farm' : ''}`}
            >
              <div className="farm-name-item">{g.name}</div>
              {g.external_service && isAdmin ? (
                <ExternalServiceIcon value={g.external_service} />
              ) : null}
            </div>,
          ],
          value: g.id,
          key: `${i}-one-${growerName}`,
        });
      });
    });

    if (user.settings.showDemoFarms) {
      listOfFarms.push(
        <div key={'manage-demo-farm'} className={'manage-demo-farms'}>
          <div className="manage-demo-farms__btn">
            <FontIcon>star</FontIcon>
            <span>DEMO FARMS</span>
            <Button raised onClick={manageDemoFarms}>
              + {demoFarmsCount ? 'Manage' : 'Add'}
            </Button>
          </div>
          {demoFarms.map(f => (
            <div
              key={`demo-farm-item-${f.id}`}
              onClick={() => onSelectFarm(f.id, true)}
              className={cn('md-list-tile md-text demo-farm-item', {
                'active-farm': currentFarmID === f.id,
              })}
            >
              <div className="farm-name-item">{demoFarmsObject[f.id]?.name}</div>
            </div>
          ))}
        </div>
      );
    }

    return listOfFarms;
  };

  const filteredFarms = useMemo(() => {
    return buildList(farms, farmId);
  }, [dispatch, farms, user.groupIds, farmId]);

  const manageDemoFarms = () => {
    dispatch(toggleGlobalDialog('demoFarms', {visible: true}));
    closeAutocompleteMenu();
  };

  const closeAutocompleteMenu = () => {
    setTimeout(() => document.querySelector('body').click(), 100);
  };

  const onSelectFarm = (farmId: number, closeMenu?: boolean) => {
    setGetParamToURL('filterSources', null);
    dispatch(resetSensitiveParams());
    dispatch(selectFarm(farmId));
    dispatch(cancelDrawingPivotCenter());
    if (closeMenu) {
      closeAutocompleteMenu();
    }
  };

  const onAutocompleteFarm = (suggestion: number, suggestionIndex: number, matches: DataType) => {
    if (matches[suggestionIndex]) {
      onSelectFarm(suggestion);
    }
  };

  const onFilter = (value: string) => {
    const r = new RegExp(escapeRegExp(value), 'i');
    return buildList(
      farms.reduce((matches, g) => {
        if (
          r.test(g.name) ||
          r.test(g.growerName || '') ||
          (r.test('Demo Farms') && demoFarmsIdsList.includes(g.id)) // show demo farms by "Demo farms" search request
        ) {
          matches.push(g);
        }

        return matches;
      }, []),
      farmId
    );
  };

  if (!farmsLoadingStatus || farmsLoadingStatus === Status.Pending) {
    return (
      <FarmsLoaderWrapper id={'map__select-farm'} className="suggest-wrapper">
        <CircularProgress centered={false} id="farms-circle-loader" />
        <div>{t({id: 'Loading farms...'})}</div>
      </FarmsLoaderWrapper>
    );
  }

  if (!hasFarms) {
    return null;
    //
    // Add farm functionality
    //
    // return (
    //   <div className="suggest-wrapper">
    //     <Button
    //       className="add-button on-boarding-add-farm" // add-button - global class for buttons with + icon
    //       onClick={() => this.props.dialogToggle(DialogType.editFarm, true, {id: 0, name: ''})}
    //       raised
    //       iconEl={<FontIcon>add</FontIcon>}
    //     >
    //       <FormattedMessage id="farm.btn.addFarm" defaultMessage="Add Farm" />
    //     </Button>
    //   </div>
    // );
  }

  const displayGrowerName = !focused && growerName;
  const userFacingValue = currentFarm?.name || 'Farm';

  if (mobileView) {
    return <FluroChip className={'farm-field-mobile-chip'} label={userFacingValue} />;
  }

  return (
    <div className="suggest-wrapper">
      <FluroAutocomplete
        id={'map__select-farm'}
        title={currentFarm?.name || ''}
        searchIcon={focused}
        label={displayGrowerName && growerName.toUpperCase()}
        placeholder={t({id: 'Farms'})}
        onFilter={onFilter}
        onBlur={() => toggleFocus(false)}
        onFocus={() => toggleFocus(true)}
        onAutocomplete={onAutocompleteFarm}
        className={cn('selectors-toolbar__select-farm white-autocomplete', {
          'with-label': displayGrowerName,
          focused,
        })}
        inputClassName={'select-farm-input'}
        value={userFacingValue}
        menuItems={filteredFarms}
      />
    </div>
  );
};

export default SelectorFarms;
