import React, {useMemo} from 'react';
import {Switch, Redirect, Route, useLocation, useHistory} from 'react-router-dom';
import {
  SI_COMPARE,
  SI_KPI,
  SI_MAP,
  SI_ABATEMENT_POTENTIAL,
  SI_DATA_EXPORT,
  SI_HOME,
} from 'containers/si/routes';
import {PrivateRoute} from 'containers';
import {FluroTabs} from 'components';
import {getFeaturesLabels} from '_utils/translations';
import {KPIDashboard} from 'containers/si/kpi/kpi';
import {SIv1Map} from '../si-map-v1';
import {useAppSelector} from '_hooks';
import {selectTabsUserHasAccessTo} from '../module/selectors';
import type {TabAccess} from 'containers/si/api/apiTypes';
import {SIPageContainer} from 'containers/si/si-styled-components';
import {applyPathParams} from '_utils/pure-utils';
import {DataExportList} from 'containers/si/data-export/data-export-list';
import {isDefined} from '_utils/typeGuards';
import {ComparisonDashboard} from 'containers/si/comparison-dashboard/ComparisonDashboard';

export const SIMonitor = ({programId}: {programId: number}) => {
  const featureLabels = getFeaturesLabels();
  const {push} = useHistory();
  const location = useLocation();
  const tabsAccess = useAppSelector(selectTabsUserHasAccessTo);

  // These are the available, second level Monitor tabs
  const availableTabs = useMemo(() => {
    const siMonitorTabs: {
      accessKey: TabAccess;
      label: string;
      value: string;
    }[] = [
      {
        accessKey: 'kpi',
        label: featureLabels['si-kpi'],
        value: applyPathParams(SI_KPI, {programId}),
      },
      {
        accessKey: 'comparison_dashboard',
        label: featureLabels['si-comparison-dashboard'],
        value: applyPathParams(SI_COMPARE, {programId}),
      },
      {
        accessKey: 'map',
        label: featureLabels['si-map'],
        value: applyPathParams(SI_MAP, {programId}),
      },
      {
        accessKey: 'data_export',
        label: featureLabels['si-data-export'],
        value: applyPathParams(SI_DATA_EXPORT, {programId}),
      },
    ];

    return siMonitorTabs.filter(tab => tabsAccess.includes(tab.accessKey));
  }, [featureLabels, programId, tabsAccess]);

  const defaultTab = useMemo((): string | undefined => {
    const kpiTab = availableTabs.find(tab => tab.accessKey === 'kpi');

    return isDefined(kpiTab) ? kpiTab.value : availableTabs[0]?.value;
  }, [availableTabs]);

  const selectedTab = useMemo(() => {
    return String(availableTabs.find(({value}) => location.pathname.includes(value))?.value);
  }, [availableTabs, location]);

  if (!availableTabs.length) {
    return <Route component={() => <Redirect to={SI_HOME} />} />; // redirect to main screen if a user doesn't have access to any tabs from the Monitor page
  }

  return (
    <>
      <FluroTabs
        containerClassName="second-level-menu-tabs"
        tabs={availableTabs}
        selectedTab={selectedTab}
        onTabClick={tab => push(tab)}
      />
      <SIPageContainer>
        <Switch>
          <PrivateRoute path={SI_KPI} component={KPIDashboard} />
          <PrivateRoute path={[SI_MAP, SI_ABATEMENT_POTENTIAL]} component={SIv1Map} />
          <PrivateRoute path={[SI_COMPARE]} component={ComparisonDashboard} />
          <PrivateRoute path={SI_DATA_EXPORT} component={DataExportList} />
          {defaultTab && <Route component={() => <Redirect to={defaultTab} />} />}
        </Switch>
      </SIPageContainer>
    </>
  );
};
