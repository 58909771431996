export enum FieldSystemProp {
  Id = '__id__',
  Checked = '__checked__',
  FarmId = '__farmId__',
  FileName = '__fileName__',
  NewFarmName = '__newFarmName__',
  Area = '__area__',
  FieldName = '__fieldName__',
  ProtectedArea = '__protectedArea__',
}

export enum FarmStateInUploadingFields {
  NoFarms = 'no-farm',
  OneFarm = 'one-farm',
  MultipleFarms = 'multiple-farms',
}

export enum NewFieldError {
  FieldSizeError = 'field-size-error',
  FieldNameError = 'field-name-error',
  FieldFarmNameError = 'field-farm-name-error',
  FieldProtectedAreaError = 'field-protected-area-error',
}

export type FieldBoundary = GeoJSON.Feature<
  GeoJSON.Geometry,
  {
    [FieldSystemProp.Area]: number;
    [FieldSystemProp.FieldName]: string;
    [FieldSystemProp.Id]: string;
    [FieldSystemProp.Checked]: boolean;
    [FieldSystemProp.FarmId]: number;
    [FieldSystemProp.NewFarmName]: string;
  }
>;
