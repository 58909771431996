// @ts-nocheck
import cn from 'classnames';
import {
  ExpandableArea,
  ExpandableRowContainer,
  SubContainer,
  SubItem,
} from 'components/expandable-table-items/expandable-table-items';
import {Flex} from 'components/flex/flex';
import {MRVFeature} from 'components/mrv/mrv-feature';
import {t} from 'i18n-utils';
import type {ComponentType} from 'react';
import React, {useEffect, useMemo, useState} from 'react';
import {Button, CircularProgress, SelectionControl} from 'react-md';
import {useDispatch} from 'react-redux';
import {RequestStatus} from 'types';
import {showNotification} from 'components/notification/notification';
import {ClimateApi} from '_api';
import config from '_environment';
import Mixpanel from '_utils/mixpanel-utils';
import {FluroButton, Sticky} from '../../../components';
import {useAppSelector} from '../../../_hooks';
import {ExternalService} from '../../map/types';
import {setPlatformAuthStatus, syncClimateFields} from './actions';
import IntegrationCard from './helpers/integration-card';
import {selectIntegrationPlatform} from './integration-selectors';
import type {ClimateFarm, IntegrationComponentProps} from './types';
import {
  selectEnrollmentProjectIdRedirectUrlForFMS,
  selectPhase,
} from '../../mrv/monitoring/module/selectors';
import {SelectedPlatformBackButton} from './helpers/selected-platform-back-btn';
import {SelectFarmsAndFieldsToImportRow} from './helpers/select-farms-fields-to-import-row';
import {MRVPhaseType} from 'containers/mrv/types';

/**
 * Climate integration flow:
 *
 * 1. Fetch farms list
 * -> if success – show the growers list
 *
 * 2. A user can select farms or fields to import from Climate to Regrow.
 * -> As request payload we are always using field ids, farm selection is just a visual effect
 */

const IntegrationClimate: ComponentType<IntegrationComponentProps> = ({expanded}) => {
  const dispatch = useDispatch();
  const platform = useAppSelector(s => selectIntegrationPlatform(s, ExternalService.Climate));
  const loginRedirectUrl = useAppSelector(selectEnrollmentProjectIdRedirectUrlForFMS);
  // The MRV stuff shouldn't be there in the Profile component.
  // But since mrv_phase_id is a necessary parameter for the Climate API, we need to bring MRV here.
  // We also just pick the first (random) phase - Enrollment.
  const phase = useAppSelector(s => selectPhase(s, MRVPhaseType.Enrollment));

  const authorized = platform.authorized;

  const [farms, setFarms] = useState<ClimateFarm[]>([]);
  const [loading, setLoading] = useState(false);
  const [selectedFields, setSelectedFields] = useState<string[]>([]);
  const [selectedFarms, setSelectedFarms] = useState<string[]>([]);
  const [expandedFarms, setExpandedFarms] = useState<{[farmId: string]: boolean}>({});

  const onAuthorise = () => {
    window.location.assign(`${config.baseUrl}login/climate${loginRedirectUrl}`);
  };

  const getFarms = () => {
    if (farms.length || loading || !authorized || !expanded) return;

    setLoading(true);
    ClimateApi.getFarms()
      .then(({data}) => {
        const resultFarms = Object.keys(data.result.farms).map(farmId => {
          return {
            id: farmId,
            name: data.result.farms[farmId][0].farm_name,
            fields: data.result.farms[farmId].map(({field_name, field_id}) => ({
              name: field_name,
              id: field_id,
            })),
          };
        });

        setFarms(resultFarms);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
        dispatch(setPlatformAuthStatus(ExternalService.Climate, false));
        showNotification({
          title: t({id: 'note.error', defaultMessage: 'Error'}),
          message: t({id: 'fetchClimateFarmsErr', defaultMessage: "Couldn't fetch Climate farms."}),
          type: 'error',
        });
      });
  };

  useEffect(() => {
    getFarms();
  }, [authorized, expanded]);

  useEffect(
    function calculateSelectedFarms() {
      const selectedFarms = farms
        .filter(f => f.fields.every(f => selectedFields.includes(f.id))) // get farms with all selected fields inside
        .map(({id}) => id); // return farm ids

      setSelectedFarms(selectedFarms);
    },
    [selectedFields]
  );

  const toggleField = (fieldId: string, value: boolean) => {
    setSelectedFields(selectedFields => {
      return value ? [...selectedFields, fieldId] : selectedFields.filter(id => id !== fieldId);
    });
  };

  const toggleFarm = (farmId: string, value: boolean) => {
    const fieldIds = farms.find(f => f.id === farmId).fields.map(f => f.id);
    setSelectedFields(selectedFields => {
      return value
        ? [...new Set([...selectedFields, ...fieldIds])]
        : selectedFields.filter(fieldId => !fieldIds.includes(fieldId));
    });
  };

  const toggleAllFarms = (value: boolean) => {
    setSelectedFields(value ? farms.map(farm => farm.fields.map(field => field.id)).flat() : []);
  };

  const onExpandFarm = (farmId: string) => {
    setExpandedFarms(expandedFarms => ({...expandedFarms, [farmId]: !expandedFarms[farmId]}));
  };

  const onImportFields = () => {
    Mixpanel.importSync(ExternalService.Climate, selectedFields);
    const phaseId = phase?.id;
    dispatch(syncClimateFields({fieldIds: selectedFields, phaseId}));
  };

  const onLogout = async () => {
    await ClimateApi.logOut();
    dispatch(setPlatformAuthStatus(ExternalService.Climate, false));
  };

  const isSyncInProgress = platform.syncStatus === RequestStatus.Loading;
  const areFarmsLoading = loading && !farms.length;
  const isReady = Boolean(authorized && !areFarmsLoading);

  const reauthComponent = useMemo(() => {
    return (
      <Flex alignItems="center">
        <FluroButton blank raised onClick={onAuthorise}>
          {t({id: 'Re-authorize'})}
        </FluroButton>
        <MRVFeature>
          <FluroButton blank className={'reauthorize-btn'} raised onClick={onLogout}>
            {t({id: 'Log Out'})}
          </FluroButton>
        </MRVFeature>
      </Flex>
    );
  }, []);

  return (
    <div className={cn('integration-platform climate', {selected: expanded})}>
      <IntegrationCard
        authorized={authorized}
        expanded={expanded}
        platform={ExternalService.Climate}
        onAuthorize={onAuthorise}
        reauthComponent={reauthComponent}
        loading={areFarmsLoading}
      />

      {expanded && (
        <>
          {isReady && (
            <>
              <SelectFarmsAndFieldsToImportRow />

              <div className={'entities-to-import-container climate'}>
                {farms.length > 1 && (
                  <SelectionControl
                    id={'select-all-climate-farms'}
                    name={'select-all-climate-farms'}
                    label={t(
                      {id: 'Select all farms ({count1}/{count2})'},
                      {count1: selectedFarms.length, count2: farms.length}
                    )}
                    type={'checkbox'}
                    className={'select-all-checkbox'}
                    checked={selectedFarms.length === farms.length}
                    onChange={(v: boolean) => toggleAllFarms(v)}
                  />
                )}
                {farms.map(farm => {
                  const farmIsSelected = selectedFarms.includes(farm.id);
                  return (
                    farm.fields.length !== 0 && (
                      <React.Fragment key={farm.id}>
                        <ExpandableRowContainer className={cn({selected: farmIsSelected})}>
                          <SelectionControl
                            id={farm.id}
                            name={'farm selection'}
                            className={'highest-platform-entity'}
                            label={farm.name}
                            type={'checkbox'}
                            checked={farmIsSelected}
                            onChange={(v: boolean) => toggleFarm(farm.id, v)}
                          />

                          <ExpandableArea
                            onClick={() => onExpandFarm(farm.id)}
                            expanded={expandedFarms[farm.id]}
                          />
                        </ExpandableRowContainer>

                        {expandedFarms[farm.id] && (
                          <SubContainer>
                            {farm.fields.map(field => (
                              <SubItem key={field.id}>
                                <SelectionControl
                                  id={field.id}
                                  className={'medium-platform-entity'}
                                  name={'field selection'}
                                  label={field.name}
                                  type={'checkbox'}
                                  checked={selectedFields.includes(field.id)}
                                  onChange={(v: boolean) => toggleField(field.id, v)}
                                />
                              </SubItem>
                            ))}
                          </SubContainer>
                        )}
                      </React.Fragment>
                    )
                  );
                })}
              </div>
            </>
          )}

          <Sticky className="action-buttons-holder">
            <SelectedPlatformBackButton />

            {isReady && (
              <Flex alignItems="center">
                {isSyncInProgress && (
                  <CircularProgress
                    id="integration-climate-progress"
                    className="margin-0 margin-right-5"
                  />
                )}

                <Button
                  raised
                  primary
                  className="md-btn--right"
                  disabled={!selectedFields.length || isSyncInProgress}
                  onClick={onImportFields}
                >
                  {isSyncInProgress ? t({id: 'Importing...'}) : t({id: 'Import'})}
                </Button>
              </Flex>
            )}
          </Sticky>
        </>
      )}
      {/*<ColoredBorder colorStart={'rgb(234, 162, 47)'} colorEnd={'rgba(234, 162, 47, 0.2)'} />*/}
    </div>
  );
};

export default IntegrationClimate;
