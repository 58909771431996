// @ts-nocheck
import type {CSSProperties} from 'react';
import React, {useCallback, useState} from 'react';
import {GeoJSON, MapContainer} from 'react-leaflet';
import type {Layer, Map} from 'leaflet';
import type * as geojson from 'geojson';
import {MAIN_SHAPE_COLOR} from '../../_constants';
import {resolveEventHandlers} from '../fluro-leaflet/fix-click-handler';

type Props = {
  data: GeoJSON.GeoJsonObject;
  style?: CSSProperties;
  size?: [number, number];
  onEachFeature?(feature: geojson.Feature, layer: Layer): void;
  isSeasonGeometry?: boolean;
  onMouseEnter?: (e: any) => void;
  onMouseLeave?: (e: any) => void;
};

export const GeometryPreview = ({
  isSeasonGeometry = false,
  size,
  data,
  style = {},
  onEachFeature,
  onMouseEnter,
  onMouseLeave,
}: Props) => {
  const [map, setMap] = useState<Map>();

  const _onEachFeature = useCallback(
    (feature: any, layer: any) => {
      if (isSeasonGeometry) {
        layer.setStyle({
          color: feature?.properties?.fill || MAIN_SHAPE_COLOR,
          fillOpacity: 0.8,
          strokeWidth: '2px',
        });

        layer.on(
          resolveEventHandlers({
            click: () => {
              const bounds = layer.getBounds();
              try {
                window.leafletElement.fitBounds(bounds);
              } catch (e) {}
            },
          })
        );
      }

      onEachFeature?.(feature, layer);
    },
    [isSeasonGeometry]
  );

  return (
    <div onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
      <MapContainer
        zoomControl={false}
        scrollWheelZoom={false}
        dragging={false}
        touchZoom={false}
        doubleClickZoom={false}
        boxZoom={false}
        keyboard={false}
        tap={false}
        attributionControl={false}
        whenCreated={map => setMap(map)}
        zoomSnap={0.5}
        style={{
          zIndex: 1,
          width: size?.[0] || 100,
          height: size?.[1] || 100,
          backgroundColor: 'transparent',
          ...style,
        }}
      >
        <GeoJSON
          data={data}
          onEachFeature={_onEachFeature}
          ref={(n: any) => {
            if (n && map) {
              map.fitBounds(n.getBounds());
            }
          }}
        />
      </MapContainer>
    </div>
  );
};

export default GeometryPreview;
