import {Flex} from 'components';
import type {FC} from 'react';
import React from 'react';
import {FontIcon} from 'react-md';
import {ArticleLink} from 'components/article-link/ArticleLink';

type LearnMoreProps = {
  articleId: number;
};

export const LearnMore: FC<LearnMoreProps> = ({articleId}) => {
  return (
    <Flex>
      <ArticleLink content="Learn More" articleId={articleId} />
      <FontIcon className="margin-left-5">launch</FontIcon>
    </Flex>
  );
};
