import React from 'react';
import {MatchStatusLabel} from './crop-types-matching.styled';
import {MatchStatus as MatchStatusType} from './crop-types-matching.module';

type Props = {
  value: MatchStatusType;
};

export const colorMapping: any = {
  [MatchStatusType.Matched]: {
    color: '#43A047',
    label: 'MATCHED',
  },
  [MatchStatusType.NoMatch]: {
    color: '#FFA726',
    label: 'NO MATCH',
  },
};

const MatchStatus = ({value}: Props) => {
  return (
    <MatchStatusLabel color={colorMapping[value].color}>
      {colorMapping[value].label}
    </MatchStatusLabel>
  );
};

export default MatchStatus;
