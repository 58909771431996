import {createBrowserHistory} from 'history';
import {mixpanelCarbon} from '_utils/mixpanel-utils';
import {APP_BASE_PATH_NAME} from '_constants';
import {checkWorkspace} from 'containers/admin/users/types';

export const history = createBrowserHistory({
  basename: APP_BASE_PATH_NAME,
});

const {isWorkspaceMrv} = checkWorkspace();

export const initCarbonHistoryTracker = () => {
  // track first carbon page load - direct
  if (isWorkspaceMrv) {
    mixpanelCarbon.urlChange();
  }

  let historyTrackTimer: ReturnType<typeof setTimeout>;
  history.listen(() => {
    if (isWorkspaceMrv) {
      clearTimeout(historyTrackTimer);

      historyTrackTimer = setTimeout(() => {
        mixpanelCarbon.urlChange();
      }, 800);
    }
  });
};
