// @ts-nocheck
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {useAppDispatch, useAppSelector} from '_hooks';
import './styles.scss';
import {FormattedMessage, t} from 'i18n-utils';
import {applyPathParams} from '_utils/pure-utils';
import {sortFieldsByProp, convertUnit} from '_utils';
import {toFixedFloatUnsafe} from '_utils/number-formatters';
import {isDefined} from '_utils/typeGuards';
import {Flex, FluroButton, GeometryPreview, Sticky, Text} from 'components';
import cn from 'classnames';
import {selectFieldGeometries, selectFieldsByFarmId} from 'containers/map/reducer/selectors';
import {
  selectHasFarmsOrGroupsIds,
  selectMeasurement,
  selectUserIsImpersonated,
} from 'containers/login/login-selectors';
import type {Farm, Field} from 'containers/map/types';
import {
  selectPhase,
  selectProjectFieldsList,
  selectProjectPractices,
} from 'containers/mrv/monitoring/module/selectors';
import {useParsedMatchParams} from '_hooks/use-parsed-match-params';
import {GEOMETRY_THUMB_SIZE, handleFieldFeature} from '../utils';
import {DNDCStatus, MRVPhaseType} from 'containers/mrv/types';
import {selectFarms} from 'modules/farms/selectors';
import {
  selectAssignPracticeAttribute,
  selectAssignPracticesStage,
} from 'containers/mrv/monitoring/module/enrollment.selectors';
import {
  selectAssignPracticesSelectedFieldIds,
  selectDNDC,
  selectContractExists,
  selectEnrollmentReadOnly,
} from '../../carbon-store/selectors';
import {ReadonlyMessage} from '../readonly-message';
import {useHistoryToRedirectToHome} from '../../../utils';
import {backToHomeLabel} from '../../../constants';
import {highlightField, toggleTableView} from 'containers/map/actions';
import {OutcomeCalculation} from 'containers/mrv/enrollment/form/view-outcomes/outcome-calculation';
import {PracticeSelectDropDown} from 'components/practice-select/practice-select-dropdown';
import {generateEnrollmentDocusignLink} from 'containers/mrv/monitoring/module/thunks';
import {MRV_HOME} from 'containers/mrv/routes';
import styled from 'styled-components';
import {DNDCMessage} from 'containers/mrv/enrollment/form/view-outcomes/dndc-message';
import {reportError} from 'containers/error-boundary';
import {useOpenDNDCModal} from './utils';
import Mixpanel from '_utils/mixpanel-utils';
import {useMRVValues} from 'containers/mrv/data/use-mrv-values';

export const ViewOutcomes = () => {
  const {projectId} = useParsedMatchParams();

  const projectFields = useAppSelector(s => selectProjectFieldsList(s, projectId));
  const fieldsByFarmId = useAppSelector(selectFieldsByFarmId);
  const farms = useAppSelector(selectFarms);
  const {status} = useAppSelector(selectDNDC);

  const enrolledFieldIds = useMemo(() => {
    const ids: {[fieldId: number]: true} = {};
    projectFields.forEach(f => (ids[f.fs_field_id] = true));
    return ids;
  }, [projectFields]);

  const isFieldEnrolled = useCallback(
    (field: Field) => enrolledFieldIds[field.FieldID],
    [enrolledFieldIds]
  );

  const farmsToProcess = useMemo(() => {
    return Object.keys(fieldsByFarmId).reduce((acc, farmKey) => {
      const farmId = Number(farmKey);
      const farm = farms[farmId];

      const farmHasEnrolledFields = Object.values(fieldsByFarmId[farmId]).some(isFieldEnrolled);
      if (!farmHasEnrolledFields || !farm) {
        return acc;
      }

      return [...acc, farm];
    }, [] as Farm[]);
  }, [fieldsByFarmId, farms, isFieldEnrolled]);

  return (
    <div className="enrollment-form__view-outcomes">
      <div className="description mt-1">
        {status === null ? (
          <>
            <FormattedMessage
              id="DNDCModalMessage"
              defaultMessage="<Text>Calculations may take a few moments.</Text>
          <Text>
            While outcomes are being calculated you will not be able to edit your fields.
          </Text>"
              values={{
                Text: (msg: string) => <Text>{msg}</Text>,
              }}
            />
            <FormattedMessage
              id="Enroll.StartDndcMessage"
              defaultMessage="<Text>Press <strong>Start</strong> to calculate the carbon sequestration and payment outcomes.</Text>"
              values={{
                Text: (msg: string) => <Text>{msg}</Text>,
                strong: (msg: string) => <strong>{msg}</strong>,
              }}
            />
          </>
        ) : (
          <FormattedMessage
            id="Enroll.SignContractMessage"
            defaultMessage="<p>Below are the sequestration and payment outcomes based on the practices assigned to your fields.</p><p>Press <strong>Sign Contract</strong> to move on to your producer agreement.</p>"
            values={{
              p: (msg: string) => <p>{msg}</p>,
              strong: (msg: string) => <strong>{msg}</strong>,
            }}
          />
        )}
      </div>

      <FieldsPerFarmList farms={farmsToProcess} isFieldEnrolled={isFieldEnrolled} />
    </div>
  );
};

const FieldsPerFarmList = ({
  farms,
  isFieldEnrolled,
}: {
  farms: Farm[];
  isFieldEnrolled: (field: Field) => boolean;
}) => {
  const dispatch = useAppDispatch();
  const {projectId} = useParsedMatchParams();

  const {isReadOnly} = useAppSelector(selectEnrollmentReadOnly);
  const projectFields = useAppSelector(s => selectProjectFieldsList(s, projectId));
  const fieldsByFarmId = useAppSelector(selectFieldsByFarmId);
  const geometries = useAppSelector(selectFieldGeometries);
  const hasFarms = useAppSelector(selectHasFarmsOrGroupsIds);
  const selectedFieldIds = useAppSelector(selectAssignPracticesSelectedFieldIds);
  const measurement = useAppSelector(selectMeasurement);
  const practicesList = useAppSelector(s => selectProjectPractices(s, projectId));
  const enrollmentCfg = useAppSelector(s => selectPhase(s, MRVPhaseType.Enrollment));
  const contractExists = useAppSelector(selectContractExists);
  const dndc = useAppSelector(selectDNDC);
  const [signingContract, setSigningContract] = useState(false);
  const userIsImpersonated = useAppSelector(selectUserIsImpersonated);
  const assignPracticesStage = useAppSelector(selectAssignPracticesStage);
  const assignPracticesAttribute = useAppSelector(selectAssignPracticeAttribute);

  const {entityValueRows} = useMRVValues(MRVPhaseType.Enrollment, projectId, assignPracticesStage);

  const hasValues = !!Object.values(entityValueRows).find(rows =>
    rows.find(r => Object.values(r.values).find(v => isDefined(v.value)))
  );

  const fsFieldIdToMrvFieldId = useMemo(() => {
    const fields: Record<number, number> = {};
    projectFields.forEach(f => {
      fields[f.fs_field_id] = f.id;
    });
    return fields;
  }, [projectFields]);

  const [collapsedFarms, setCollapsedFarms] = useState<{[farmId: number]: boolean}>({});
  const toggleExpandFarm = (farmId: number, forceValue?: boolean) => {
    setCollapsedFarms(e => ({...e, [farmId]: forceValue !== undefined ? forceValue : !e[farmId]}));
  };

  const {canOpenDNDCModal, openDNDCModal} = useOpenDNDCModal();

  useEffect(() => {
    dispatch(toggleTableView(undefined));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const backToHomePage = useHistoryToRedirectToHome();

  const onFieldClick = (fieldId: number) => {
    dispatch(highlightField(fieldId));
  };

  const generateProjectContract = async () => {
    try {
      setSigningContract(true);
      const generatedContractLinkAction = await dispatch(
        generateEnrollmentDocusignLink({
          projectId,
          phaseId: enrollmentCfg.id,
          redirect_url: `${location.origin}${applyPathParams(MRV_HOME, {
            projectId,
          })}`,
        })
      );

      if (generatedContractLinkAction.meta.requestStatus === 'fulfilled') {
        window.open(generatedContractLinkAction.payload as string, '_self');
        Mixpanel.track('Generate contract');
      }

      backToHomePage();
    } catch (error) {
      reportError(error);
    } finally {
      setSigningContract(false);
    }
  };

  if (!hasFarms) {
    return <div>No farms</div>;
  }

  const canGenerateContract =
    !contractExists && !isReadOnly && dndc.status === DNDCStatus.Success && !userIsImpersonated;

  return (
    <>
      {dndc.status === DNDCStatus.Success && <ReadonlyMessage />}
      <DNDCMessage />
      <div className={cn('farm-list mt-1', {dndcIncomplete: dndc.status !== DNDCStatus.Success})}>
        {farms.map(farm => {
          const farmId = farm.id;
          const fields = Object.values(fieldsByFarmId[farmId]).filter(isFieldEnrolled);

          if (!fields.length) {
            return null;
          }

          const farmName = farm.name;
          const farmArea = convertUnit(
            measurement,
            'ac',
            fields.reduce((acc, f) => acc + f.Area, 0)
          );

          const classifiedFarmArea =
            farmArea > 1000 ? toFixedFloatUnsafe(farmArea / 1000, 1) + 'k ' : farmArea;
          const isExpanded = !collapsedFarms[farmId];

          return (
            <div className={cn('farm', {'farm--expanded': isExpanded})} key={farmId}>
              <div className={cn('farm__row')}>
                <div title={farmName} className="checkbox-label">
                  {farmName}
                </div>

                <FluroButton className={'expand-btn'} icon onClick={() => toggleExpandFarm(farmId)}>
                  {isExpanded ? 'keyboard_arrow_down' : 'keyboard_arrow_right'}
                </FluroButton>

                <div className={'farm-area'}>
                  {classifiedFarmArea} {t({id: measurement})}
                </div>
              </div>
              {isExpanded && (
                <div>
                  {sortFieldsByProp(fields, 'Name', 'string').map(field => {
                    const mrvFieldId = fsFieldIdToMrvFieldId[field.FieldID];
                    const fieldPractices = [
                      ...new Set(
                        entityValueRows[mrvFieldId]?.map(
                          row => row.values[assignPracticesAttribute?.id]?.value
                        )
                      ),
                    ];

                    return (
                      <div
                        className={cn({
                          field: true,
                        })}
                        onClick={() => onFieldClick(field.ID)}
                        key={field.ID}
                      >
                        <Flex nowrap>
                          {geometries[field.MD5] && (
                            <div className="field-geometry-thumb">
                              <GeometryPreview
                                data={geometries[field.MD5]}
                                size={GEOMETRY_THUMB_SIZE}
                                onEachFeature={handleFieldFeature}
                              />
                            </div>
                          )}
                          <div>
                            <div className="field-name field-name-disabled">{field.Name}</div>
                            <div className="field-area">
                              <Text secondary>
                                {convertUnit(measurement, 'ac', field.Area)} {t({id: measurement})}
                              </Text>
                            </div>
                          </div>
                        </Flex>

                        <div className="field-item-right-block">
                          <PracticeSelectDropDown
                            id={field.ID + '-practice-dropdown'}
                            items={practicesList}
                            value={fieldPractices}
                            fieldsNumber={selectedFieldIds.length}
                            isCirclesHandle
                            disabled
                          />
                        </div>
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          );
        })}
      </div>

      <StyledFloatingContainer variant="container">
        <OutcomeCalculation phaseId={enrollmentCfg?.id} />

        <Buttons>
          <FluroButton blank raised onClick={backToHomePage}>
            {t({id: 'BtnLabel.Back'})}
          </FluroButton>
          {canOpenDNDCModal || dndc.status === DNDCStatus.InProgress ? (
            <FluroButton
              raised
              primary
              loading={dndc.status === DNDCStatus.InProgress}
              disabled={dndc.status === DNDCStatus.InProgress}
              onClick={openDNDCModal}
            >
              {t({id: 'Start', defaultMessage: 'Start'})}
            </FluroButton>
          ) : (
            <FluroButton
              raised
              primary
              loading={signingContract}
              // We display a disabled "Sign Contract" button if dndc.status is not Success
              disabled={!hasValues || dndc.status !== DNDCStatus.Success}
              onClick={canGenerateContract ? generateProjectContract : backToHomePage}
            >
              {/* We display a disabled "Sign Contract" button if dndc.status is not Success. */}
              {/* To show the user that this is the next step of the process. */}
              {canGenerateContract || dndc.status !== DNDCStatus.Success
                ? t({id: 'BtnLabel.SignContract', defaultMessage: 'Sign Contract'})
                : backToHomeLabel}
            </FluroButton>
          )}
        </Buttons>
      </StyledFloatingContainer>
    </>
  );
};

const StyledFloatingContainer = styled(Sticky)`
  flex-direction: column;
`;

const Buttons = styled.div`
  width: 100%;
  margin-top: 16px;
  display: flex;
  justify-content: space-between;
`;
