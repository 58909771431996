// @ts-nocheck
import {createAsyncThunk} from '@reduxjs/toolkit';
import type {AppStore} from 'reducers';
import {replaceFieldAtFieldsByFarmId, updateFieldGeometries} from 'containers/map/actions';
import {FarmApi, KmlApi} from '_api';
import {GeoJSON} from 'leaflet';
import {reportError} from 'containers/error-boundary';
import {featureCollection} from '@turf/turf';
import tokml from '_utils/tokml';
import {PANEL_PADDING} from '_constants';
import {allowToChangeToTheStep} from '../../utils';
import MRVApi from '_api/mrv';
import type {EnrollmentStep} from './types';
import {
  setDNDCResults,
  setImportedFields,
  setEnrolledFields,
  setOverlapFields,
  updateStep,
} from './carbon-reducer';
import {ActionType} from 'containers/mrv/monitoring/module/types';
import {DNDCStatus} from 'containers/mrv/types';
import {updateProjectDNDCStatus} from 'containers/mrv/monitoring/module/reducer';
import {selectIsAddingFields} from 'modules/add-fields/selectors';

export const setStep = createAsyncThunk<
  void,
  {step: EnrollmentStep; isReadOnly: boolean},
  {state: AppStore}
>('carbon/setStep', async ({step, isReadOnly}, thunkAPI) => {
  const state = thunkAPI.getState();
  const isEditingMode = state.map.drawControl.isEditingMode;
  const isAddingFields = selectIsAddingFields(state);

  if (isAddingFields || isEditingMode) {
    return;
  }

  const allowChangeStep = thunkAPI.dispatch(allowToChangeToTheStep(step));

  if (!isReadOnly && !allowChangeStep) return; // early return;

  const prev = state.carbon.step;

  if (prev === step) {
    return;
  }

  thunkAPI.dispatch(updateStep(step));
});

/**
 * Select/deselect fields on the Fields & Boundaries stage of enrollment,
 * And mark the field overlaps.
 */
export const enrollFields = createAsyncThunk<void, {[fieldId: number]: boolean}, {state: AppStore}>(
  'carbon/enrollFields',
  async (enrolledFields, thunkAPI) => {
    thunkAPI.dispatch(setEnrolledFields(enrolledFields));

    // Update field overlaps if the some of the overlapping fields got removed.
    const overlapFields = thunkAPI.getState().carbon.overlapFields;
    if (overlapFields.length) {
      const newOverlaps = overlapFields
        .map(overlap => {
          const newOverlap = overlap.filter(fieldId => !(fieldId in enrolledFields));
          return newOverlap.length > 1 ? newOverlap : undefined; // Skip overlap that has only 1 field left.
        })
        .filter(Boolean);
      thunkAPI.dispatch(setOverlapFields(newOverlaps));
    }
  }
);

/**
 * Select/deselect fields on the Fields & Boundaries stage of enrollment,
 * And mark the field overlaps.
 */
export const toggleEnrollField = createAsyncThunk<void, {fieldId: number}, {state: AppStore}>(
  'carbon/toggleEnrollFields',
  async ({fieldId}, thunkAPI) => {
    thunkAPI.dispatch(
      enrollFields({[fieldId]: !thunkAPI.getState().carbon.enrolledFields[fieldId]})
    );
  }
);

export const importFields = createAsyncThunk<void, {[fieldId: number]: boolean}, {state: AppStore}>(
  'carbon/importFields',
  async (importedFields, thunkAPI) => {
    thunkAPI.dispatch(setImportedFields(importedFields));

    // Update field overlaps if the some of the overlapping fields got removed.
    const state = thunkAPI.getState();
    if (state.carbon.overlapFields.length) {
      const newOverlaps = state.carbon.overlapFields
        .map(overlap => {
          const newOverlap = overlap.filter(fieldId => !(fieldId in importedFields));
          return newOverlap.length > 1 ? newOverlap : undefined; // Skip overlap that has only 1 field left.
        })
        .filter(Boolean);
      thunkAPI.dispatch(setOverlapFields(newOverlaps));
    }
  }
);

export const saveEditedField = createAsyncThunk<void, GeoJSON.Feature, {state: AppStore}>(
  'carbon/saveEditedField',
  async (data, thunkAPI) => {
    try {
      const state = thunkAPI.getState();
      const field =
        state.map.fieldsByFarmId[data.properties.fluro_farm_id][data.properties.fluro_id];

      const geometries = {...state.map.fieldGeometries};

      const result = await FarmApi.saveFields([
        {
          farm_id: data.properties.fluro_farm_id,
          name: field.Name,
          kml: tokml(data),
        },
      ]);

      await KmlApi.dropField(data.properties.fluro_farm_id, field.ID);

      const newField = result.data.result[0].field;

      // if edited field was selected we need to update enrolled fields
      if (state.carbon.enrolledFields[field.ID]) {
        const enrolledFields = {
          [field.ID]: false,
          [newField.ID]: true,
        };
        thunkAPI.dispatch(enrollFields(enrolledFields));
      }

      // remove prev geometry
      delete geometries[field.MD5];

      data.properties.fluro_id = newField.ID;

      //@ts-expect-error error leftover from convertion to strict mode, please fix
      geometries[newField.MD5] = featureCollection([data]);

      thunkAPI.dispatch(
        replaceFieldAtFieldsByFarmId(data.properties.fluro_farm_id, field.ID, newField)
      );

      thunkAPI.dispatch(updateFieldGeometries(geometries));

      // fit edited field
      setTimeout(() => {
        const layer = new GeoJSON(data);
        //@ts-expect-error error leftover from convertion to strict mode, please fix
        window.leafletElement.fitBounds(layer.getBounds(), PANEL_PADDING);
      }, 100);
    } catch (e) {
      reportError('Cannot edit field boundary: ' + e.message);
    }
  }
);
// TODO: Update getDNDCResults when API is ready
// export const getDNDCResults = createAsyncThunk(
//   ActionType.GET_DNDC_RESULTS,
//   async ({projectId, phaseId}: {projectId: number; phaseId: number}, thunkAPI) => {
//     try {
//       const response = await MRVApi.getDNDCResults(projectId, phaseId);
//       const {
//         line_items_summary: {total_carbon_sequestered, total_payment},
//       } = response.data;

//       thunkAPI.dispatch(
//         setDNDCResults({
//           payment: total_payment,
//           tonsSequestered: total_carbon_sequestered,
//         })
//       );
//     } catch (error) {
//       thunkAPI.dispatch(updateProjectDNDCStatus({projectId, status: DNDCStatus.Failed}));
//       reportError(error);
//     }
//   }
// );
export const getDNDCResults = createAsyncThunk(
  ActionType.GET_DNDC_RESULTS,
  async ({projectId, phaseId}: {projectId: number; phaseId: number}, thunkAPI) => {
    try {
      const response = await MRVApi.getDNDCResults(projectId, phaseId);
      const {
        line_items_summary: {total_carbon_sequestered, total_payment},
        line_items,
      } = response.data;

      thunkAPI.dispatch(
        setDNDCResults({
          payment: total_payment,
          tonsSequestered: total_carbon_sequestered,
          detailedItems: line_items,
        })
      );
    } catch (error) {
      thunkAPI.dispatch(updateProjectDNDCStatus({projectId, status: DNDCStatus.Failed}));
      reportError(error);
    }
  }
);
