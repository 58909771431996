import {FormattedMessage} from 'i18n-utils';
import React from 'react';
import {useDispatch} from 'react-redux';
import {setFeature} from 'containers/map/actions';

const IsOnlyCloudy = () => {
  const dispatch = useDispatch();

  const handleClick = () => {
    dispatch(setFeature('data-layers'));
  };

  return (
    <>
      <FormattedMessage
        id="Some imagery is available for your fields, but it might be cloudy"
        defaultMessage="Some imagery is available for your fields, but it might be cloudy. By default, your cloudy imagery is automatically hidden. To reveal this cloudy imagery, switch to the <span>data layers</span> tab, and adjust the cloud filter."
        values={{
          span: (txt: string) => (
            <span onClick={handleClick} className={'global-link'}>
              {' '}
              {txt}{' '}
            </span>
          ),
        }}
      />
    </>
  );
};
export default IsOnlyCloudy;
