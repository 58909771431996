import React, {useMemo} from 'react';
import {useSelector} from 'react-redux';
import type {AppStore} from 'reducers';
import type {AsyncStatusType} from '../modules/helpers';
import {Status} from '../modules/helpers';
import {MapLoader} from './map-loader';

export const MapPreloader = ({requestTypes}: {requestTypes: AsyncStatusType[]}) => {
  const asyncStatuses = useSelector((state: AppStore) => state.helpers.asyncStatuses);
  const loaders = useSelector((state: AppStore) => state.global.loaders);

  const isLoading = requestTypes.some(r => asyncStatuses[r].status === Status.Pending);

  const hasGlobalLoaders = useMemo(() => {
    return Object.keys(loaders).length > 0;
  }, [loaders]);

  // show layer preloader only if we do not have any other global blocking screen preloader
  return <MapLoader loading={isLoading && !hasGlobalLoaders} />;
};
