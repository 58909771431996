import '@geoman-io/leaflet-geoman-free';
import '@geoman-io/leaflet-geoman-free/dist/leaflet-geoman.css';
import {useMap} from 'react-leaflet';
import {useEffect} from 'react';
import {useAppSelector} from '../../_hooks';
import {selectDrawControl} from './reducer/selectors';
import {selectIsMapLoading} from 'modules/global/selectors';
import {useWorkspace} from '_hooks/use-workspace';

const editStyle = {
  color: '#fff',
  fillColor: '#43A047',
  fillOpacity: 0.3,
};

export const EditControl2 = (): null => {
  const map = useMap();
  const {isEditingMode, fieldId} = useAppSelector(selectDrawControl);
  const isMapLoading = useAppSelector(selectIsMapLoading);
  const {isWorkspaceMrv} = useWorkspace();

  useEffect(() => {
    if (isEditingMode && map) {
      setTimeout(() => {
        map.eachLayer(layer => {
          if (isWorkspaceMrv) {
            //@ts-expect-error error leftover from convertion to strict mode, please fix
            if (layer.feature?.properties?.fluro_id === fieldId) {
              //@ts-expect-error error leftover from convertion to strict mode, please fix
              layer.pm.enable();
              //@ts-expect-error error leftover from convertion to strict mode, please fix
              layer.setStyle(editStyle);
            }
          } else {
            //@ts-expect-error error leftover from convertion to strict mode, please fix
            if (layer.__fluroLayerToEdit && fieldId) {
              //@ts-expect-error error leftover from convertion to strict mode, please fix
              layer.pm.enable();
              //@ts-expect-error error leftover from convertion to strict mode, please fix
              layer.setStyle(editStyle);
            }
          }
        });
      }, 100); // let map update layers first and then run map.eachLayer
    }
  }, [isEditingMode, map, fieldId, isMapLoading]);

  // useEffect(() => {
  //   if (map) {
  //     map.pm.addControls({
  //       position: 'topleft',
  //       drawCircle: false,
  //     });
  //   }
  // }, [map]);

  return null;
};
