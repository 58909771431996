// @ts-nocheck
import React, {useMemo} from 'react';
import Select from 'react-select';
import './index.scss';
import {FontIcon} from 'react-md';
import cn from 'classnames';

type Props = {
  id?: string;
  placeholder?: string;
  value: string;
  options: {label: string; value: string}[];
  onChange: (value: string) => void;
  className?: string;
  selectClassName?: string;
  error?: boolean;
  errorText?: string;
};

const controlStyle = {
  boxShadow: 'none',
  color: 'rgba(0, 0, 0, 0.54)',
  '&:hover': {borderColor: '#f1f1f1'},
  border: '1px solid #f1f1f1',
  borderRadius: '4px',
  fontSize: '1rem',
  padding: '3px 10px',
  height: 44,
};

const DropdownIndicator = () => <FontIcon>arrow_drop_down</FontIcon>;

const FluroSelect = ({
  id,
  options,
  placeholder,
  onChange,
  value,
  className = '',
  selectClassName = '',
  error,
  errorText,
}: Props) => {
  const valueObj = useMemo(() => {
    return options.find(item => item.value === value) || {label: value, value};
  }, [value, options]);

  return (
    <div className={cn({'fluro-select': true, [className]: !!className})}>
      {placeholder && <div className="fluro-select-label">{placeholder}</div>}
      <Select
        id={id}
        instanceId={id}
        components={{DropdownIndicator}}
        isClearable={false}
        placeholder={placeholder || 'Select...'}
        options={options}
        onChange={({value}) => onChange(value)}
        value={valueObj}
        menuPortalTarget={document.body}
        className={selectClassName}
        theme={t => ({
          ...t,
          colors: {
            ...t.colors,
            primary: 'rgba(0,0,0,.12)',
            primary25: 'rgba(0,0,0,.12)',
            primary50: 'rgba(0,0,0,.12)',
          },
        })}
        styles={{
          option: (css, state) => ({
            ...css,
            ...(state.isSelected && {color: '#000'}),
          }),

          placeholder: css => ({
            ...css,
            color: 'unset',
            fontSize: '14px',
          }),

          valueContainer: (base: any) => ({...base, paddingLeft: 0, fontSize: '14px'}),
          indicatorSeparator: () => ({display: 'none'}),
          menuPortal: (base: any) => ({
            ...base,
            zIndex: 9999,
          }),
          control: (base: any) => ({
            ...base,
            ...controlStyle,
          }),
        }}
      />
      {Boolean(error && errorText) ? (
        <div className="md-text md-text-field-message md-text--error">{errorText}</div>
      ) : null}
    </div>
  );
};

export default FluroSelect;
