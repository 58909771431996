import * as React from 'react';
import {useAppSelector} from '_hooks';
import {EnrollmentStep} from '../carbon-store';
import './carbon-colors-overview.scss';
import {CPBadge} from 'components/badge';
import {MAIN_SHAPE_COLOR} from '_constants';
import {selectHasFarms} from 'containers/login/login-selectors';
import {selectEnrolledFieldsList, selectEnrollmentReadOnly} from '../carbon-store/selectors';
import {selectProgramBoundaries} from 'containers/mrv/monitoring/module/selectors';
import {selectIsAddingFields} from 'modules/add-fields/selectors';
import {t} from 'i18n-utils';

export const CarbonColorsOverview = () => {
  const carbon = useAppSelector(s => s.carbon);
  const hasFarms = useAppSelector(selectHasFarms);
  const enrolledFieldsCount = useAppSelector(selectEnrolledFieldsList).length;
  const {isReadOnly} = useAppSelector(selectEnrollmentReadOnly);
  const isAddingFields = useAppSelector(selectIsAddingFields);
  const programBoundaries = useAppSelector(selectProgramBoundaries);

  let content = null;

  switch (carbon.step) {
    case EnrollmentStep.Fields:
      content = (
        <>
          {programBoundaries?.length ? (
            <CPBadge className={'program-boundaries-badge'} backgroundColor={'#fff'} color="#000">
              Program boundaries
            </CPBadge>
          ) : null}
          {/* We don't need this badge since we don't do PADUS anymore. If we get it back, we can uncomment this legend item. */}
          {/* <ProtectedFieldAreaBadge /> */}
          {hasFarms && (
            <CPBadge backgroundColor={MAIN_SHAPE_COLOR} color="#fff">
              {isReadOnly
                ? t(
                    {
                      id: 'EnrolledFields',
                      defaultMessage:
                        'Enrolled {fieldsNumber, plural, one {field} other {fields}} ({fieldsNumber})',
                    },
                    {fieldsNumber: enrolledFieldsCount}
                  )
                : t(
                    {
                      id: 'SelectedFields',
                      defaultMessage:
                        'Selected {fieldsNumber, plural, one {field} other {fields}} ({fieldsNumber})',
                    },
                    {fieldsNumber: enrolledFieldsCount}
                  )}
            </CPBadge>
          )}
        </>
      );
      break;
    case EnrollmentStep.CropPractices:
      break;
  }

  if (!content) return null;
  if (isAddingFields) return null; // adding fields has its own fields counter

  return <div className="carbon-colors-overview">{content}</div>;
};
