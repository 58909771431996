// @ts-nocheck
import {ActivityApi, AreasOfInterestApi} from '_api';
import {t} from 'i18n-utils';
import {ActionTypes} from '../reducer/types';
import {getAnomalyLabelValue, GLOBAL_FORMAT_DATE} from '_constants';
import {getImagePath} from '_utils/pure-utils';
import moment from 'moment';
import type {AppStore} from 'reducers';
import type {IAnomaly, TAnomalyProps} from '../features/anomalies/types';
import {area as turfArea} from '@turf/turf';
import {showNotification} from 'components/notification/notification';
import {reportError} from '../../error-boundary';
import {AsyncStatusType, setRequestStatus, Status} from '../../../modules/helpers';
import {
  selectCurrentImageObj,
  selectCurrentImagePngPath,
  selectCurrentSeason,
  selectCurrentSensor,
  selectMapGeometry,
} from '../reducer/selectors';
import type {AppDispatch} from 'store';
import {getGeometryMeanIndex} from 'containers/map/utils';

const propertiesShouldCallRequest: (keyof TAnomalyProps)[] = ['label', 'endDate', 'startDate'];

export const createAreaOfInterest =
  (shape: Partial<IAnomaly>, isBulk?: boolean) => (dispatch: any, getState: () => AppStore) => {
    const {group, selectedFieldId} = getState().map;
    return AreasOfInterestApi.creatAreaOfInterest(group.id, selectedFieldId, shape)
      .then(({data}) => {
        dispatch(addAreaOfInterest(data.result));
        showNotification({
          title: t({id: 'note.success', defaultMessage: 'Success'}),
          message: t({id: 'AOIUploadOk'}, {count: isBulk ? 2 : 1}),
          type: 'success',
        });
      })
      .catch(() => reportError(`createAreaOfInterest err, = ${shape}`));
  };

export const addAreaOfInterest = (areaOfInterest: Partial<IAnomaly>) => ({
  type: ActionTypes.MAP_AOI_ADD,
  shape: areaOfInterest,
});

export const addDraftAreaOfInterest =
  (AOI: IAnomaly) => (dispatch: AppDispatch, getState: () => AppStore) => {
    const state = getState();
    const currentSensor = selectCurrentSensor(state);
    const {endDate, startDate} = selectCurrentSeason(state) || {};
    const currentImage = selectCurrentImageObj(state);

    const getSeasonFormattedDate = (date: string) =>
      moment(date, GLOBAL_FORMAT_DATE).format(GLOBAL_FORMAT_DATE); // small helper

    AOI.properties.area = turfArea(AOI); // meters
    AOI.properties.type = 'trial';
    AOI.properties.startDate =
      AOI.properties.startDate || getSeasonFormattedDate(String(startDate));
    AOI.properties.endDate = AOI.properties.endDate || getSeasonFormattedDate(String(endDate));

    if (currentSensor !== 'NONE' && currentSensor !== 'TCI' && currentSensor !== 'NC') {
      const png = currentImage?.url || '';
      const imagePath = getImagePath(png);

      return getGeometryMeanIndex(imagePath, AOI)
        .then(({data}) => {
          AOI.properties.mean = data.Means[AOI.properties.id];
          dispatch(toggleEditAreaOfInterest(true, AOI));
        })
        .catch(() => {
          dispatch(toggleEditAreaOfInterest(true, AOI));
        });
    }

    dispatch(toggleEditAreaOfInterest(true, AOI));

    return Promise.resolve();
  };

export const updateAreaOfInterest =
  (areaOfInterest: IAnomaly, isBulk?: boolean) => (dispatch: any) => {
    AreasOfInterestApi.updateAreaOfInterest(areaOfInterest.properties.id, areaOfInterest).then(
      ({data}) => {
        dispatch({
          type: ActionTypes.MAP_AOI_UPDATE,
          geometry: data.result,
          id: areaOfInterest.properties.id, // provide the previous AOI id because back-end can create a new AOI with new id after updating
        });

        showNotification({
          title: t({id: 'note.success', defaultMessage: 'Success'}),
          message: t({id: 'AOIUpdateOk'}, {count: isBulk ? 2 : 1}),
          type: 'success',
        });
      }
    );
  };

export const toggleEditAreaOfInterest =
  (state: boolean, geometry = {}) =>
  (dispatch: any) => {
    dispatch({
      type: ActionTypes.MAP_AOI_EDIT_TOGGLE,
      state,
      geometry,
    });
  };

export const bulkUpdateAreasOfInterest =
  (geometries: IAnomaly[], prop: keyof TAnomalyProps, value: any) => (dispatch: any) => {
    if (propertiesShouldCallRequest.includes(prop)) {
      return geometries.forEach(g => {
        // @ts-expect-error because of the propertiesShouldCallRequest, couldn't configure it to avoid the warning
        g.properties[prop] = value;
        dispatch(updateAreaOfInterest(g, geometries.length > 1));
      });
    }

    dispatch({
      type: ActionTypes.MAP_AOIs_CHANGE_PROP,
      geometries,
      value,
      prop,
    });
  };

export const changeAreaOfInterestProp =
  (geometry: any, prop: keyof TAnomalyProps, value: any) => (dispatch: AppDispatch) => {
    if (propertiesShouldCallRequest.includes(prop)) {
      geometry.properties[prop] = value;
      return dispatch(updateAreaOfInterest(geometry));
    }

    dispatch({
      type: ActionTypes.MAP_AOI_CHANGE_PROP,
      data: {
        geometry,
        prop,
        value,
      },
    });
  };

export const getAreasOfInterest =
  (fieldId: number | string) => (dispatch: any, getState: () => AppStore) => {
    const {group} = getState().map;

    dispatch(setRequestStatus(AsyncStatusType.fieldAreasOfInterest, Status.Pending));

    return AreasOfInterestApi.getAreasOfInterest(group.id, fieldId)
      .then(({data}) => {
        if (data.result && Array.isArray(data.result)) {
          data.result.forEach((geometry: IAnomaly) =>
            dispatch({
              type: ActionTypes.MAP_AOI_ADD,
              shape: {
                ...geometry,
                properties: {
                  ...geometry.properties,
                  id: geometry.properties.id,
                  label: getAnomalyLabelValue(geometry.properties.label), // transform geometry label to label value
                  saved: true, // reset risky props
                  checked: false, // reset risky props
                  color: '', // reset risky props
                },
              },
            })
          );
          dispatch(getAndSetAreasOfInterestMeanIndex());
        }
      })
      .catch(() => Promise.reject('action getGeometries'))
      .finally(() => {
        dispatch(setRequestStatus(AsyncStatusType.fieldAreasOfInterest, Status.Done));
      });
  };

export const removeAreaOfInterest = (id: number, isBulk?: boolean) => (dispatch: any) => {
  AreasOfInterestApi.removeAreaOfInterest(id).then(() => {
    showNotification({
      title: t({id: 'note.success', defaultMessage: 'Success'}),
      message: t({id: 'AOIDeleteOk'}, {count: isBulk ? 2 : 1}),
      type: 'success',
    });
    dispatch({
      type: ActionTypes.MAP_AOI_REMOVE,
      id,
    });
  });
};

export const getAndSetAreasOfInterestMeanIndex =
  () => (dispatch: any, getState: () => AppStore) => {
    const state = getState();
    const currentSensor = selectCurrentSensor(state);
    const geometry = selectMapGeometry(state);
    const currentImagePath = selectCurrentImagePngPath(state);
    if (['TCI', 'NONE', 'NC'].includes(currentSensor)) {
      // there is no mean index for these sensors
      return;
    }
    const coordinates: Record<number, number[][]> = {};
    geometry.forEach(({geometry, properties}) => {
      switch (geometry.type) {
        case 'MultiPolygon':
          coordinates[properties.id] = geometry?.coordinates?.[0]?.flat();
          break;
        default:
          coordinates[properties.id] = geometry?.coordinates?.[0];
      }
    });

    ActivityApi.getMeanIndex(currentImagePath, coordinates)
      .then(({data}) => {
        dispatch({
          type: ActionTypes.MAP_AOI_UPDATE_MEAN_INDEX,
          data,
        });
      })
      .catch();
  };
