import React, {useCallback} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {removeNitrogenMarker} from '../actions';

import {Tooltip} from 'react-leaflet';
import Leaflet from 'leaflet';
import type {AppStore} from 'reducers';
import {FluroMarker} from 'components/fluro-leaflet';

const markerIcon = new Leaflet.Icon({
  iconRetinaUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.0.0/images/marker-icon.png',
  iconUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.0.0/images/marker-icon.png',
  shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.0.0/images/marker-shadow.png',
  iconSize: [25, 41],
  shadowSize: [20, 35],
  iconAnchor: [13, 41],
  shadowAnchor: [4, 40],
});

/* Tooltip for the nitrogen layer */
type NitrogenPopupProps = {
  value: string | number;
  position: {x: number; y: number};
};
export const NitrogenPopup = ({position, value}: NitrogenPopupProps) => (
  <div className="map__nitrogen-popup" style={{top: position.y + 'px', left: position.x + 'px'}}>
    <span>
      <b>{value}</b>N%
    </span>
  </div>
);

const NitrogenMarkersOverlay = () => {
  const dispatch = useDispatch();
  const markers = useSelector((store: AppStore) => store.map.nitrogen.markers);
  const currentDate = useSelector((store: AppStore) => store.map.currentDate);

  const onRemoveMarker = useCallback((markerId: number) => {
    dispatch(removeNitrogenMarker(markerId));
  }, []);

  return markers[currentDate]
    ? markers[currentDate].map((m: any, i: number) => (
        <FluroMarker
          key={`nitrogen-tooltip-marker-${i}`}
          position={m.position}
          eventHandlers={{click: () => onRemoveMarker(i)}}
          icon={markerIcon}
        >
          <Tooltip permanent={true}>
            <div>
              <b>{m.value}</b>N%
            </div>
          </Tooltip>
        </FluroMarker>
      ))
    : null;
};

export default NitrogenMarkersOverlay;
