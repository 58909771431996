//@ts-expect-error error leftover from convertion to strict mode, please fix
import {reproject} from 'reproject';
//@ts-expect-error error leftover from convertion to strict mode, please fix
import proj4 from 'proj4';
import type {Feature, FeatureCollection} from '@turf/turf';
import {CoordinateSystem} from 'containers/mrv/types';
import type {FeatureCollectionWithFilename} from 'shpjs';

// EPSG:2154 is common French projection format
proj4.defs(
  CoordinateSystem.EPSG2154,
  '+proj=lcc +lat_0=46.5 +lon_0=3 +lat_1=49 +lat_2=44 +x_0=700000 +y_0=6600000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs +type=crs'
);

export const convertGeoJsonToEPSG4326 = (
  gJson: Feature | FeatureCollection | FeatureCollectionWithFilename,
  fromProj: CoordinateSystem = CoordinateSystem.EPSG2154
) => {
  return reproject(gJson, fromProj, CoordinateSystem.EPSG4326);
};
