// @ts-nocheck
import {Flex, Text} from 'components';
import {
  AbatementNoDataLabel,
  abatementPotentialColors,
  lowAbatementPotentialColor,
  LOW_ABATEMENT_THRESHOLD,
  SIAreaDefaultFillColor,
} from 'modules/sustainability-insights/constants';
import {
  selectAbatementPotentialChartData,
  selectAbatementPotentialFilterRange,
  selectActiveGeometriesIds,
} from 'modules/sustainability-insights/selectors';
import {classifyAbatementPotentialFilterScaleLabel} from 'modules/sustainability-insights/utils';
import type {ComponentType} from 'react';
import React from 'react';
import {useAppSelector} from '_hooks';

export const SIAbatementPotentialFilterStatus: ComponentType<{}> = () => {
  return (
    <>
      <Text className={'abatement-potential-filter-range-title'} variant={'h3'}>
        Abatement potential (t/ac)
      </Text>

      <SiAbatementPotentialFilterRangeColors />
    </>
  );
};

export const SiAbatementPotentialFilterRangeColors = () => {
  const rangeFilter = useAppSelector(selectAbatementPotentialFilterRange);
  const rangeFilterLabels = classifyAbatementPotentialFilterScaleLabel(rangeFilter);
  const {valuesById} = useAppSelector(selectAbatementPotentialChartData) || {};
  const activeGeometryIds = useAppSelector(selectActiveGeometriesIds);

  const haveAreasWithNoData =
    typeof valuesById === 'object' && Object.keys(valuesById).length !== activeGeometryIds?.length; // detect areas with no data
  const haveLowPotentialAreas =
    valuesById && Object.values(valuesById).some(v => v.value < LOW_ABATEMENT_THRESHOLD);

  return (
    <Flex className={'abatement-potential-filter-range-colors'} alignItems="center">
      {haveAreasWithNoData && (
        <ColorScaleItem label={AbatementNoDataLabel} backgroundColor={SIAreaDefaultFillColor} />
      )}
      {haveLowPotentialAreas && (
        <ColorScaleItem
          label={`< ${LOW_ABATEMENT_THRESHOLD}`}
          backgroundColor={lowAbatementPotentialColor}
        />
      )}

      {rangeFilterLabels.map((label, index) => {
        return (
          <ColorScaleItem
            key={label + index}
            label={label}
            backgroundColor={abatementPotentialColors[index]}
          />
        );
      })}
    </Flex>
  );
};

type ColorScaleItemProps = {
  label: string;
  backgroundColor: string;
};

const ColorScaleItem = ({label, backgroundColor}: ColorScaleItemProps) => {
  return (
    <Flex className={'status'} alignItems={'center'} gap={'4px'}>
      <div style={{backgroundColor}} className={'status__icon'} />
      <span>{label}</span>
    </Flex>
  );
};
