import {useLeafletContext} from '@react-leaflet/core';
import 'leaflet-draw/dist/leaflet.draw.css';
import 'leaflet-measure';
import 'leaflet-measure/dist/leaflet-measure.css';
import 'leaflet/dist/leaflet.css';
import type {ComponentType} from 'react';
import React from 'react';
import {useAppSelector} from '_hooks';
import {reportError} from '../error-boundary';
import Analytics from './features/analytics';
import MapCrop from './features/anomalies';
import CropPerformance from './features/crop-performance/crop-performance';
import RemoteSensingList from './features/data-layers/remote-sensing-list';
import MapFarm from './features/farm';
import FieldsListContainer from './features/farm/table-view';
import MapTissueSampling from './features/sampling-points';
import MapZoning from './features/zoning';
import './index.scss';
import './lib/fluro-measurement';
import './lib/svg-icon';
import {useMapBarView} from './map-bar/map-bar-view';
import {isMapFeature} from './utils';
import {selectCurrentTab} from './reducer/selectors';

type Props = {};

export const FlurosensePanel: ComponentType<Props> = () => {
  const feature = useAppSelector(selectCurrentTab);
  const leafletElement = useLeafletContext().map;
  const [wholeTableView] = useMapBarView();

  switch (feature) {
    case 'farm':
      return wholeTableView ? <FieldsListContainer /> : <MapFarm />;

    case 'crop':
      return <MapCrop />;

    case 'zoning':
      return <MapZoning />;

    case 'data-layers':
      return <RemoteSensingList />;

    case 'tsp':
      return <MapTissueSampling leafletElement={leafletElement} />;

    case 'crop-performance':
      return <CropPerformance />;

    case 'analytics':
      return <Analytics />;

    default:
      // special case for sustainability-insights because it is map feature is lazy importing here src/containers/map/flurosense-map.tsx:183
      if (isMapFeature(feature) && feature !== 'sustainability-insights') {
        reportError(`Map bar: No panel component for CI feature "${feature}"`);
      }
      return null;
  }
};
