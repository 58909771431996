import React from 'react';
import type {Practice} from 'containers/mrv/types';
import {practiceTypeToIcon} from 'containers/mrv/types';
import {Avatar, SimpleAvatar} from './practice.styled';
import MrvApi from '_api/mrv';

export const PracticeAvatar = ({value}: {value: Practice}) => {
  const iconName = practiceTypeToIcon[value];
  if (iconName) {
    return (
      <Avatar
        className={'practice-avatar'}
        title={value}
        src={MrvApi.getPracticeIconUrl(iconName)}
      />
    );
  }

  return (
    <SimpleAvatar className={'practice-avatar'} title={value}>
      {value?.charAt(0)}
    </SimpleAvatar>
  );
};
