import {useRef, useEffect} from 'react';
import type {TypedUseSelectorHook} from 'react-redux';
import {useDispatch, useSelector} from 'react-redux';
import type {AppStore, AppThunkDispatch} from 'reducers';
import type {Action} from 'redux';
import type {AppDispatch} from 'store';

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<AppStore> = useSelector;
/**
 * Use async dispatch within components.
 */
export const useAppThunkDispatch = <ActionType extends Action>() =>
  useDispatch<AppThunkDispatch<ActionType>>();

export function usePrevious<T>(value: T): T {
  const ref = useRef<T>(value);
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}
