export default class {
  static login = (action: any) => {
    const {user} = action.response;
    return {
      event: 'Login',
      'User email': user.email,
      'User name': user.name,
      'Is agX user': user.agxSync, // TODO: check value in googleTagManager
    };
  };
}
