import styled, {css} from 'styled-components';
import {calculateContrastColor} from 'containers/si/planning-report/dashboard/utils/utils';
import type {FluroTableColumnProps} from 'components';
import {FluroTableColumn} from 'components';

export const StyledFluroTableColumn = styled(FluroTableColumn)<
  FluroTableColumnProps & {background: string}
>(
  // These require important tags since the css classes applied to the FluroTableColumn overwrite these
  ({background}) => css`
    font-weight: 500 !important;
    background-color: ${background} !important;
    color: ${({theme}) =>
      calculateContrastColor(
        background,
        theme.color.text.mainInverted,
        theme.color.text.main
      )} !important;
  `
);
