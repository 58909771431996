// @ts-nocheck
import React from 'react';
import {GeoJSON} from 'react-leaflet';
import {genKey} from '_utils/pure-utils';
import type {Feature} from 'geojson';
import type L from 'leaflet';
import {updateNRxRecommendationProps} from 'containers/map/actions/nitrogen-recommendation-actions';

import {resolveEventHandlers} from 'components/fluro-leaflet/fix-click-handler';
import {useAppDispatch, useAppSelector} from '_hooks';
import {selectIsNRxEnabled, selectNRecommendation} from 'containers/map/features/nrx/nrx-selectors';

import {selectCurrentTab} from 'containers/map/reducer/selectors';

const NRecommendationOverlay = () => {
  const dispatch = useAppDispatch();
  const {nrxResult, nrxTabRate, selectedObjective} = useAppSelector(selectNRecommendation);
  const isNrx = useAppSelector(selectIsNRxEnabled);
  const feature = useAppSelector(selectCurrentTab);

  const onEachFeature = (feature: Feature, layer: L.Path) => {
    // select a zones
    layer.on(
      resolveEventHandlers({
        click: () => {
          dispatch(
            updateNRxRecommendationProps({
              selected: !feature.properties.selected,
              id: feature.properties.id,
            })
          );
        },
      })
    );
    // color zone
    layer.setStyle({
      fillOpacity: 1,
      color: feature.properties.color,
    });
  };
  const toShowOverlay =
    isNrx && feature === 'zoning' && nrxResult[nrxTabRate][selectedObjective].type;
  if (!toShowOverlay) return null;

  const dataToDisplay = {
    ...nrxResult[nrxTabRate][selectedObjective],
    features: nrxResult[nrxTabRate][selectedObjective].features.filter(
      (f: any) => f.properties.merged !== 'initial'
    ), // filter initial merged zones
  };

  return <GeoJSON key={genKey()} onEachFeature={onEachFeature} data={dataToDisplay} />;
};

export default NRecommendationOverlay;
