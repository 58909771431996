import React, {useEffect, useMemo, useState} from 'react';
import PremiumAppsTable from './premium-apps-table';
import {getUniqArrayOfObjectsByProp} from '_utils';
import {getCropLabelById} from '_utils/pure-utils';
import {buildLabel} from '../shared';
import {rowsPerPage} from 'containers/admin';
import '../index.scss';
import {AdminContentWrapper, AdminControls} from '../../admin.styled';
import type {SelectCheckboxItem} from 'components';
import {Flex, SelectCheckbox} from 'components';
import {CropAvatar} from 'components/crop-avatar';
import type {FField, PerSeasonFilter, SearchOptionalParams} from 'containers/admin/features/types';
import {FilterType, tagAliases, Tags} from 'containers/admin/features/types';
import {FluroChip} from 'components/fluro-chip/fluro-chip';
import moment from 'moment';
import type {Season} from 'containers/map/types';
import {FluroSelectLite} from 'components/fluro-select-lite/fluro-select-lite';
import {selectFilteredFields} from './premium-apps.selectors';
import {useAppDispatch, useAppSelector} from '_hooks';
import {getTagKeys, searchFields} from '../actions';
import {FontIcon} from 'react-md';
import {
  selectAsyncRequestStatus,
  selectCropTypes,
  selectCropTypesList,
} from 'modules/global/selectors';
import {AsyncStatusType, Status} from '../../../../modules/helpers';

type ItemObj = {
  [key in string | number]: SelectCheckboxItem;
};

const searchOption = [
  {label: 'Farm name', value: 'farm_name'},
  {label: 'Farm ID', value: 'farm_id'},
  {label: 'Field name', value: 'field_name'},
  {label: 'Field ID', value: 'field_id'},
  {label: 'Organization name', value: 'organization_name'},
  {label: 'Organization ID', value: 'organization_id'},
  {label: 'Subscription name', value: 'subscription_name'},
  {label: 'Subscription ID', value: 'subscription_id'},
  {label: 'All', value: 'all'},
];

export const TABS: {value: Tags; label: string}[] = [
  {
    value: Tags.SustainabilityInsights,
    label: tagAliases[Tags.SustainabilityInsights],
  },
  {
    value: Tags.NRx,
    label: tagAliases[Tags.NRx],
  },
  {
    value: Tags.CropStress,
    label: tagAliases[Tags.CropStress],
  },
  {
    value: Tags.TreeAnalysis,
    label: tagAliases[Tags.TreeAnalysis],
  },
];

export const ACTIVATED_ITEMS = [
  {label: 'All activated/non activated', value: 'all'},
  {label: 'Activated', value: 'activated'},
  {label: 'Non activated', value: 'non_activated'},
];

const ActivatedItemsDefaultValue = ACTIVATED_ITEMS[0].value;

type Props = {
  tabValue: Tags;
};

const RegularTagsRepresentative = ({tabValue}: Props) => {
  const dispatch = useAppDispatch();

  const searchRequestStatus = useAppSelector(s =>
    selectAsyncRequestStatus(s, AsyncStatusType.adminSearch)
  );

  const timeoutRef = React.useRef<ReturnType<typeof setTimeout> | null>(null);

  const {fields, result, tagKeys} = useAppSelector(s => s.admin.features);

  const isBySeasons = tabValue === Tags.NRx || tabValue === Tags.CropStress;

  /*
   *
   * Filters block
   *
   * */

  const [filterValues, setFilterValues] = useState<PerSeasonFilter>({
    [FilterType.Years]: [],
    [FilterType.Crops]: [],
    [FilterType.Entities]: [],
    [FilterType.ActivatedState]: ActivatedItemsDefaultValue,
  });

  const {
    [FilterType.Years]: yearsFilter,
    [FilterType.Crops]: cropsFilter,
    [FilterType.ActivatedState]: activatedFilter,
  } = filterValues;

  const [searchBy, changeSearchBy] = useState<string>('farm_name');
  const [search, onChangeSearch] = useState('');
  const [pagination, onChangePagination] = useState({start: 0, perPage: rowsPerPage, page: 1});
  const cropTypes = useAppSelector(selectCropTypes);
  const cropTypesList = useAppSelector(selectCropTypesList);

  const filteredFields = selectFilteredFields(result, filterValues[FilterType.Entities], searchBy);

  useEffect(() => {
    // @ts-expect-error the returned type is not a promise, need to work on the proper return type for actions
    dispatch(getTagKeys()).then(keys => {
      dispatch(searchFields('', searchBy, keys, {with_seasons: true}));
    });
  }, []);

  useEffect(() => {
    onChangePagination({start: 0, perPage: rowsPerPage, page: 1});
    setFilterValues({
      ...filterValues,
      [FilterType.Crops]: [],
      [FilterType.Years]: [],
      [FilterType.ActivatedState]: ActivatedItemsDefaultValue,
    });
  }, [tabValue]);

  const cropItems = useMemo(() => {
    return cropTypesList.map(c => ({
      label: (
        <Flex alignItems="center" gap="10px" style={{flexFlow: 'row'}}>
          <CropAvatar cropType={c.value} className="dropdown-crop-avatar" />{' '}
          <span>{getCropLabelById(cropTypes, c.label)}</span>
        </Flex>
      ),
      textLabel: getCropLabelById(cropTypes, c.value),
      value: c.value,
    }));
  }, [cropTypesList]);

  const filterItems = useMemo(() => {
    const years: ItemObj = {};
    const entities: ItemObj = {};

    result.forEach(field => {
      (field?.seasons || []).forEach((season: Season) => {
        const year1 = moment(season.startDate).format('YYYY');

        years[year1] = {label: year1, value: year1};
      });
    });

    return {
      [FilterType.Years]: Object.values(years).reverse(),
      [FilterType.Crops]: Object.values(cropTypesList),
      [FilterType.Entities]: Object.values(entities),
    };
  }, [result]);

  const getAllEntitiesLabel = () => {
    switch (true) {
      case searchBy.includes('farm'):
        return 'farm(s)';
      case searchBy.includes('field'):
        return 'field(s)';
      case searchBy.includes('organization'):
        return 'organization(s)';
      case searchBy.includes('subscription'):
        return 'subscription(s)';

      default:
        return 'farm(s)';
    }
  };

  const [totalCropsNumber, filteredCropsNumber] = useMemo(() => {
    return [
      result.reduce((acc, field) => acc + field.seasons?.length || 0, 0),
      filteredFields.reduce((acc, field) => acc + field.seasons?.length || 0, 0),
    ];
  }, [result, filteredFields]);

  const searchOptionalParams: SearchOptionalParams = useMemo(() => {
    const optionalParams: SearchOptionalParams = {with_seasons: true};

    if (yearsFilter.length) {
      optionalParams[FilterType.Years] = yearsFilter;
    }
    if (cropsFilter.length) {
      optionalParams[FilterType.Crops] = cropsFilter;
    }
    if (activatedFilter !== ActivatedItemsDefaultValue) {
      switch (activatedFilter) {
        case ACTIVATED_ITEMS[1].value: //activated
          optionalParams[FilterType.ActivatedState] = tabValue;
          break;
        case ACTIVATED_ITEMS[2].value: //deactivated
          optionalParams[FilterType.ActivatedState] = `!${tabValue}`; // use !tagName construction to explain to back-end the not-activated state
          break;
      }
    }

    return optionalParams;
  }, [yearsFilter, cropsFilter, activatedFilter, tabValue]);

  // -- Filters block end

  const handlePagination = (start: number, perPage: number, page: number) =>
    onChangePagination({start, perPage, page});

  const handlePaginationMemo = useMemo(() => handlePagination, [pagination]);

  useEffect(() => {
    if (timeoutRef.current) clearTimeout(timeoutRef.current);

    timeoutRef.current = setTimeout(async () => {
      const keys = await getTagKeys()(dispatch);

      onChangePagination({start: 0, perPage: pagination.perPage, page: 1});
      dispatch(searchFields(search, searchBy, keys, searchOptionalParams));
    }, 500);
  }, [search, searchBy, searchOptionalParams]);

  const fieldsMemo = useMemo(() => {
    let _fields = fields;

    if (searchBy.startsWith('farm')) {
      _fields = getUniqArrayOfObjectsByProp(fields, 'farmId');
    } else if (searchBy.startsWith('organization')) {
      _fields = getUniqArrayOfObjectsByProp(fields, 'organizationId');
    } else if (searchBy.startsWith('subscription')) {
      _fields = getUniqArrayOfObjectsByProp(fields, 'subscriptionId');
    }

    return _fields
      .map((f: FField) => {
        const label = buildLabel(searchBy, f);
        return {
          label,
          textLabel: label,
          value: f.farmId + ';' + f.fieldId + ';' + f.organizationId + ';' + f.subscriptionId,
        };
      })
      .slice(0, 100);
  }, [fields, searchBy, search]);

  const onSelectEntity = (values: SelectCheckboxItem[]) => {
    setFilterValues(f => ({...f, [FilterType.Entities]: values.map(item => item.value as string)}));
    onChangePagination({start: 0, perPage: pagination.perPage, page: 1});
  };

  const onChangeSearchBy = (value: string) => {
    changeSearchBy(value);
    setFilterValues({
      [FilterType.Crops]: [],
      [FilterType.Years]: [],
      [FilterType.Entities]: [],
      [FilterType.ActivatedState]: ActivatedItemsDefaultValue,
    }); // reset selected filters
  };

  const onClearFilter = (filterKey: FilterType, ev: React.MouseEvent<HTMLElement>) => {
    ev?.preventDefault();
    ev?.stopPropagation();
    setFilterValues(f => ({
      ...f,
      [filterKey]: filterKey === FilterType.ActivatedState ? ActivatedItemsDefaultValue : [],
    }));
  };

  const CustomActivatedButton = ({onClick}: {onClick: () => void}) => {
    const active = activatedFilter !== ActivatedItemsDefaultValue;
    const clearIcon = (
      <FontIcon onClick={ev => onClearFilter(FilterType.ActivatedState, ev)}>clear</FontIcon>
    );
    return (
      <FluroChip
        dropdown
        active={active}
        onClick={onClick}
        label={ACTIVATED_ITEMS.find(item => item.value === activatedFilter)?.label}
        rightIcon={active ? clearIcon : null}
      />
    );
  };

  const CustomSearchByButton = ({onClick}: {onClick: () => void}) => (
    <FluroChip
      dropdown
      active={true}
      onClick={onClick}
      label={searchOption.find(item => item.value === searchBy)?.label}
    />
  );

  const CustomSelectButton = (label: string, filterType: FilterType) => {
    const active = !!filterValues[filterType]?.length;
    const clearIcon = <FontIcon onClick={ev => onClearFilter(filterType, ev)}>clear</FontIcon>;
    return (
      <FluroChip
        label={label}
        size="medium"
        tone="light"
        active={active}
        dropdown
        rightIcon={active ? clearIcon : null}
      />
    );
  };

  return (
    <>
      <AdminControls
        justifyContent={'flex-start'}
        alignItems={'center'}
        style={{display: 'block', marginTop: '0px', paddingBottom: '20px'}}
      >
        <Flex alignItems={'center'} gap={'5px'}>
          <span className="filter-label">Search by</span>
          <FluroSelectLite
            items={searchOption}
            selectedValue={searchBy}
            onSelect={(value: string) => onChangeSearchBy(value)}
            Button={CustomSearchByButton}
          />
        </Flex>

        <Flex style={{marginTop: '20px'}} gap={'5px'} alignItems="center">
          <span className="filter-label">Filter by</span>
          <SelectCheckbox
            withSearch
            menuItems={fieldsMemo}
            triggerComponent={rawLabel =>
              CustomSelectButton(
                (rawLabel ? rawLabel : 'All') + ` ${getAllEntitiesLabel()}`,
                FilterType.Entities
              )
            }
            selectedValues={filterValues[FilterType.Entities]}
            onChange={onSelectEntity}
            searchChangeCallback={onChangeSearch}
          />

          {isBySeasons && (
            <>
              <SelectCheckbox
                withSearch
                menuItems={filterItems[FilterType.Years]}
                triggerComponent={rawLabel =>
                  CustomSelectButton(
                    (rawLabel ? rawLabel : 'All planting') + ' year(s)',
                    FilterType.Years
                  )
                }
                selectedValues={yearsFilter}
                onChange={(values: any[]) => {
                  setFilterValues(f => ({
                    ...f,
                    [FilterType.Years]: values.map(item => item.value),
                  }));
                }}
              />

              <SelectCheckbox
                withSearch
                menuItems={cropItems}
                triggerComponent={rawLabel =>
                  CustomSelectButton(
                    (rawLabel ? rawLabel : 'All') + ' crop type(s)',
                    FilterType.Crops
                  )
                }
                selectedValues={cropsFilter}
                onChange={(values: any[]) => {
                  setFilterValues(f => ({
                    ...f,
                    [FilterType.Crops]: values.map(item => item.value),
                  }));
                }}
              />

              <FluroSelectLite
                items={ACTIVATED_ITEMS}
                selectedValue={activatedFilter}
                onSelect={(value: string) =>
                  setFilterValues(f => ({...f, [FilterType.ActivatedState]: value}))
                }
                Button={CustomActivatedButton}
              />
            </>
          )}
        </Flex>
      </AdminControls>

      {filteredFields.length ? (
        <>
          <div className="total-crops-displaying">
            Showing {filteredCropsNumber} out of {totalCropsNumber} crops
          </div>
          <AdminContentWrapper className="features__table-container">
            <PremiumAppsTable
              fields={filteredFields}
              tagKeys={tagKeys}
              handlePagination={handlePaginationMemo}
              pagination={pagination}
              seasonTagValue={tabValue}
            />
          </AdminContentWrapper>
        </>
      ) : (
        <AdminContentWrapper className="features__not-found">
          {searchRequestStatus === Status.Pending ? 'Loading fields' : 'Fields not found'}
        </AdminContentWrapper>
      )}
    </>
  );
};

export default RegularTagsRepresentative;
