// @ts-nocheck
import React, {useEffect, useMemo, useState} from 'react';
import Autocomplete from 'components/autocomplete';
import {useDispatch, useSelector} from 'react-redux';
import type {Organization} from 'modules/subscriptions';
import {loadList, SubscriptionEntityType} from 'modules/subscriptions';
import {genKey} from '_utils/pure-utils';
import type {AppStore} from 'reducers';
import {SelectWrapper} from './subscriptions.styled';
import {SelectField} from 'react-md';

type Props = {
  value: number;
  onChange: (value: number) => void;
  error?: boolean;
  errorText?: any;
  className?: string;
  isMultiSelected?: boolean;
};

const searchOptions: {label: string; value: string}[] = [
  {label: 'Name', value: 'name'},
  {label: 'ID', value: 'id'},
];

const OrganizationSelect = ({
  isMultiSelected,
  className,
  errorText,
  error,
  value,
  onChange,
}: Props) => {
  const [searchOptionValue, setSearchOptionValue] = useState(searchOptions[0].value);
  const dispatch = useDispatch();
  const organizations = useSelector((state: AppStore) => state.admin.subscriptions.organizations);

  const id = useMemo(() => genKey(), []);

  const currentOrg = useMemo(
    () => organizations.find((o: Organization) => o.id === value),
    [organizations, value]
  );

  useEffect(() => {
    !organizations.length && loadList(SubscriptionEntityType.Organization, null)(dispatch);
  }, [organizations]);

  return (
    <SelectWrapper>
      <SelectField
        id={`orgs-select-search0by-${id}`}
        label="Search by"
        menuItems={searchOptions}
        value={searchOptionValue}
        onChange={(value: string) => {
          setSearchOptionValue(value);
        }}
        simplifiedMenu={true}
      />

      <Autocomplete
        id={`organizations-autocomplete-${id}`}
        label={isMultiSelected ? 'Multiple organizations selected' : 'Organization'}
        menuItems={organizations}
        dataLabel="name"
        dataValue="id"
        onAutocomplete={onChange}
        value={currentOrg?.name ? `${currentOrg.name} (#${currentOrg.id})` : ''}
        error={!!error}
        errorText={errorText}
        className={className && className}
        searchKey={searchOptionValue}
        formatLabel={(item: any) => (
          <>
            {item.name} <span className="id-string">(#{item.id})</span>
          </>
        )}
      />
    </SelectWrapper>
  );
};

export default OrganizationSelect;
