import {selectMeasurement} from 'containers/login/login-selectors';
import {selectFarms} from 'modules/farms/selectors';
import React from 'react';
import type {CellMeasurerCache, ListRowProps} from 'react-virtualized';
import {CellMeasurer} from 'react-virtualized';
import {useAppSelector} from '_hooks';
import {classifyAreaWithUnits} from '_utils';
import {getFieldFarmName} from '_utils/farm-utils';
import type {FieldBoundary} from '../types';
import {FieldSystemProp} from '../types';
import {NewFieldPreview} from './new-field-preview';
import type {FieldErrorsViews} from './use-field-errors';

export const NewFieldPreviewRow = ({
  index,
  style,
  parent,
  field,
  rowHeightCache,
  errors,
  farmsSelector,
  farm,
  onSelectField,
  onRemoveField,
  onChangeFieldName,
}: ListRowProps & {
  field: FieldBoundary;
  rowHeightCache: CellMeasurerCache;
  errors: FieldErrorsViews;
  farmsSelector?: React.ReactElement;
  farm?: string | number;
  onChangeFieldName: (id: string, value: string) => void;
  onSelectField?: (id: string, value: boolean) => void;
  onRemoveField?: (id: string) => void;
}) => {
  const measurement = useAppSelector(selectMeasurement);
  const farms = useAppSelector(selectFarms);
  const fieldArea = classifyAreaWithUnits(field.properties?.[FieldSystemProp.Area], measurement);
  const fieldName = field.properties?.[FieldSystemProp.FieldName];
  const fieldId = field.properties?.[FieldSystemProp.Id];
  const fieldSelected = field.properties?.[FieldSystemProp.Checked];
  const farmName = getFieldFarmName(field, farms, farm);

  return (
    <CellMeasurer
      key={fieldId}
      cache={rowHeightCache}
      parent={parent}
      columnIndex={0}
      rowIndex={index}
    >
      <div style={style}>
        <NewFieldPreview
          id={fieldId}
          selected={fieldSelected}
          preview={field}
          fieldArea={fieldArea}
          fieldName={fieldName}
          farmName={farmName}
          farmsSelector={farmsSelector}
          errors={errors}
          onChangeName={onChangeFieldName}
          onSelect={farmsSelector ? (value: boolean) => onSelectField?.(fieldId, value) : undefined}
          removeField={farmsSelector ? undefined : () => onRemoveField?.(fieldId)}
        />
      </div>
    </CellMeasurer>
  );
};
