import React from 'react';
import {Redirect} from 'react-router-dom';
import {useAppDispatch, useAppSelector} from '_hooks';
import {applyPathParams} from '_utils/pure-utils';
import {selectIsSucceed} from 'modules/helpers/selectors';
import {selectSIProgramsList} from 'containers/si/module/selectors';
import {setCurrentProgram} from 'containers/si/module/reducer';
import {SI_KPI} from 'containers/si/routes';
import {ActionType} from 'containers/si/module/types';

import {SIProgramsTable} from './components/si-programs-table';
import {SIPageContainer, SIPageTitle, SISectionContainer} from 'containers/si/si-styled-components';

export const SIHome = () => {
  const dispatch = useAppDispatch();
  const programs = useAppSelector(selectSIProgramsList);
  const programsFetched = useAppSelector(s =>
    selectIsSucceed(s, [ActionType.FETCH_ALL_SI_PROGRAMS])
  );

  if (programsFetched && programs.length === 1) {
    dispatch(setCurrentProgram(programs[0].id));
    return <Redirect to={applyPathParams(SI_KPI, {programId: programs[0].id})} />;
  }

  return (
    <SIPageContainer>
      <SISectionContainer>
        <SIPageTitle>Sustainability Insights programs</SIPageTitle>
        <SIProgramsTable />
      </SISectionContainer>
    </SIPageContainer>
  );
};
