// @ts-nocheck
import {FluroChipDropdown} from 'components/fluro-dropdown/fluro-chip-dropdown';
import {FluroSelectionControlGroup} from 'components/fluro-selection-control-group/fluro-selection-control-group';
import {useTranslation} from 'i18n-utils';
import type {ComponentType} from 'react';
import React from 'react';
import {useAppDispatch, useAppSelector} from '_hooks';
import {setFilterKey} from 'modules/sustainability-insights/actions';
import {
  selectAdopterTypeFilter,
  selectMetricsTypeFilter,
  selectSummerCropTypesFilter,
  selectIsDemographicsFilterSet,
} from 'modules/sustainability-insights/selectors';
import type {SIMetricColumn} from 'modules/sustainability-insights/types';
import {Text} from 'components/text/text';

type Props = {adopterLevel: 'fieldAdopterType' | 'operationAdopterType'};

export const AdopterTypeFilter: ComponentType<Props> = ({adopterLevel}) => {
  const dispatch = useAppDispatch();
  const t = useTranslation();
  const oppositeAdopterLevel =
    adopterLevel === 'fieldAdopterType' ? 'operationAdopterType' : 'fieldAdopterType';

  const summerCropTypesFilter = useAppSelector(selectSummerCropTypesFilter);
  const metricTypesFilter = useAppSelector(selectMetricsTypeFilter);
  const oppositeAdopterSet = useAppSelector(
    (
      s // allow only one type of demographics filters
    ) => selectIsDemographicsFilterSet(s, oppositeAdopterLevel)
  );

  const disabled =
    oppositeAdopterSet || (adopterLevel === 'fieldAdopterType' && metricTypesFilter === 'op');

  const adopterTypeOptions = [
    {
      label: t('New Adopter'),
      value: 'new',
    },
    {
      label: t('Consistent Adopter'),
      value: 'consistent',
    },
  ];
  const adopterType = useAppSelector(s => selectAdopterTypeFilter(s, adopterLevel));

  const handleFilterChange = (value: SIMetricColumn) => {
    // if (adopterLevel === 'operationAdopterType') {
    // FSB-7479 If the operations adopter filter is set, disable the pct (%) and enable the num (#) filter
    // dispatch(setFilterAreaUnits('num'));
    // }

    dispatch(setFilterKey(adopterLevel, value));
  };

  const clearFilter = () => {
    dispatch(setFilterKey(adopterLevel, null));
  };

  return (
    <FluroChipDropdown
      onClear={clearFilter}
      active={!!adopterType}
      className="grower-demographics-chip-dropdown"
      label={
        !!adopterType
          ? adopterTypeOptions.find(i => i.value === adopterType)?.label ?? 'Type of Adopter'
          : 'Type of Adopter'
      }
      disabled={Boolean(disabled || summerCropTypesFilter)}
    >
      <FluroSelectionControlGroup
        id="adopter-type-radio"
        name="adopterType"
        type="radio"
        value={adopterType}
        onChange={handleFilterChange}
        controls={adopterTypeOptions}
      />
      <Text secondary>
        * A grower is considered a "New Adopter" if they have not planted cover crops in the past 2
        years.
      </Text>
    </FluroChipDropdown>
  );
};
