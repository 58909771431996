import {Flex, FluroButton} from 'components';
import {Span} from 'components/text/span';
import type {ComponentType} from 'react';
import React, {useContext, useState} from 'react';
import {useDispatch} from 'react-redux';
import {useAppSelector} from '_hooks';
import {calcPlural} from '_utils/pure-utils';
import {isDefined} from '_utils/typeGuards';
import {useMonitoringUrlParams} from '../../hooks';
import {
  selectStageById,
  selectStageEnabledAttributes,
  selectSyncingOperationsStatus,
} from '../../module/selectors';
import {updateMRVValues} from '../../module/thunks';
import type {MRVPhaseType, MRVValuesInput} from 'containers/mrv/types';
import {phaseTypeToProgress} from 'containers/mrv/types';
import {TableRowsSelectionContext} from '../table-rows-selection';
import {MonitoringBulkEditFieldsDialog} from './bulk-edit-dialog';
import {FontIcon} from 'react-md';
import {toggleTooltip} from 'modules/global/actions';
import {t} from 'i18n-utils';
import {isPhaseReadonly} from '../../module/selectors-complex';
import {useMRVValues} from 'containers/mrv/data/use-mrv-values';
import {useIsAttributeDisabled} from '../../module/useIsAttributeDisabled';
import type {MRVBulkEditValues} from './types';

export const BulkEditButton: ComponentType<{phaseType: MRVPhaseType}> = ({phaseType}) => {
  const dispatch = useDispatch();
  const [selectedRows] = useContext(TableRowsSelectionContext);
  const {stageId, projectId} = useMonitoringUrlParams();
  const stage = useAppSelector(s => selectStageById(s, stageId));
  const attributes = useAppSelector(s => selectStageEnabledAttributes(s, stageId));
  const isSyncingOperations = useAppSelector(selectSyncingOperationsStatus).isSync;
  const {isReadOnly} = useAppSelector(s => isPhaseReadonly(s, phaseType));
  const isAttributeDisabled = useIsAttributeDisabled();

  const {entityValueRows, revalidate} = useMRVValues(phaseType, projectId, stage);

  const [dialogVisible, setDialogVisible] = useState(false);

  if (!stage) return null;

  const selectedYears = new Set<number>();
  selectedRows.forEach(selectedRow => {
    const rows = entityValueRows[selectedRow.entityId];
    rows?.forEach(row => {
      if (row.row_id === selectedRow.rowId && row.year) {
        selectedYears.add(row.year);
      }
    });
  });

  const defaultValues = attributes.reduce<MRVBulkEditValues>((acc, attr) => {
    const uniqueAttributeValues = new Set<string | undefined>();
    let totalRowsNumber = 0;
    let lockedRowsNumber = 0;
    let disabledRowsNumber = 0;

    selectedRows.forEach(r => {
      const row = entityValueRows[r.entityId]?.find(row => row.row_id === r.rowId);
      if (!row) {
        return;
      }

      const disabled = isAttributeDisabled(attr, row.values);

      if (!disabled) {
        uniqueAttributeValues.add(row.values[attr.id]?.value);
      }

      if (disabled) {
        disabledRowsNumber += 1;
      }

      if (row.values[attr.id]?.locked) {
        lockedRowsNumber += 1;
      }

      totalRowsNumber += 1;
    });

    const firstValue = [...uniqueAttributeValues].filter(isDefined)[0];

    return {
      ...acc,
      [attr.id]: {
        value: firstValue || '',
        isLocked: lockedRowsNumber > 0,
        isMultipleValuesSelected: uniqueAttributeValues.size > 1,
        disabledRowsNumber,
        lockedRowsNumber,
        totalRowsNumber,
      },
    };
  }, {});

  const handleBulkSubmit = async (values: MRVBulkEditValues) => {
    const entityType = stage.entity_type;
    const entityValues = Object.entries(values).map(([attribute_id, item]) => {
      return {
        confirmed: true,
        locked: item.isLocked,
        entity_type: entityType,
        attribute_id: parseInt(attribute_id, 10),
        progress: phaseTypeToProgress[phaseType],
        value: item.value,
      };
    });

    const update: MRVValuesInput = selectedRows.reduce<MRVValuesInput>((acc, row) => {
      if (!acc[row.entityId]) {
        acc[row.entityId] = [];
      }

      const oldValues =
        entityValueRows[row.entityId]?.find(r => r.row_id === row.rowId)?.values || {};
      const newValues = {...values, ...oldValues};
      const rowValues = entityValues
        .map(value => ({...value, row_id: row.rowId}))
        .filter(value => {
          const attribute = attributes.find(a => a.id === value.attribute_id);
          const disabled = attribute && isAttributeDisabled(attribute, newValues);
          return !disabled;
        });

      acc[row.entityId] = [...acc[row.entityId], ...rowValues];
      return acc;
    }, {});

    await dispatch(updateMRVValues({projectId, update, entityType}));

    // We could populate the cache straight away
    // but we'll need to revalidate anyways,
    // to get the ids of the newly created values.
    revalidate();

    setDialogVisible(false);
  };

  const onShowTooltip = () => {
    dispatch(
      toggleTooltip({
        id: 'monit-bulk-edit-fields',
        place: 'top',
        content: t({
          id: 'EditFieldMessage',
          defaultMessage:
            'Select fields below by checking the box to the left of the field name. Then click the bulk edit button to enter the same value for multiple fields. This is helpful if you have done the same practices on multiple fields or worked in multiple fields on the same date.',
        }),
      })
    );
  };

  return (
    <>
      <Flex alignItems="center" gap="1rem">
        {!!selectedRows.length && (
          <Span secondary>
            {selectedRows.length} {calcPlural('row', selectedRows)} selected
          </Span>
        )}

        <FluroButton
          onClick={() => setDialogVisible(true)}
          raised
          primary
          disabled={!selectedRows.length || isSyncingOperations || isReadOnly}
        >
          {t({id: 'BtnLabel.BulkEditRows', defaultMessage: 'Bulk edit rows'})}
        </FluroButton>

        <FontIcon data-for="monit-bulk-edit-fields" data-tip="Test" onMouseEnter={onShowTooltip}>
          help_outline
        </FontIcon>
      </Flex>
      {dialogVisible && (
        <MonitoringBulkEditFieldsDialog
          selectedFields={selectedRows}
          defaultValues={defaultValues}
          stage={stage}
          selectedYears={[...selectedYears]}
          attributes={attributes}
          onHide={() => setDialogVisible(false)}
          onSubmit={handleBulkSubmit}
        />
      )}
    </>
  );
};
