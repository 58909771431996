// @ts-nocheck
import type {PayloadAction} from '@reduxjs/toolkit';
import {createSlice} from '@reduxjs/toolkit';
import type {Farm, Field} from 'containers/map/types';
import {createEntitiesSlice} from 'modules/redux-utils/create-entities-slice';
import type {EntitiesState} from 'modules/redux-utils/types';
import {combineReducers} from 'redux';
import type {DemoFarm, FarmAction, FarmState} from './types';
import {FarmActionTypes} from './types';

type LoadFarmsListAction = PayloadAction<{
  farms: {
    list: number[];
    totalCount: number;
  };
  demoFarms: DemoFarm;
  entities: {
    farms: EntitiesState<Farm>;
    fields: EntitiesState<Field>;
  };
  result: number[];
}>;

const listSlice = createSlice({
  name: 'farms/list',
  initialState: [],
  reducers: {
    loadFarmsList(state, action: LoadFarmsListAction) {
      return [...(action.payload.result || [])];
    },
  },
  extraReducers: b =>
    b
      .addCase<string, FarmAction>(FarmActionTypes.CREATE_FARM, (state, action) => [
        ...state,
        action.farm.id,
      ])
      .addCase<string, FarmAction>(FarmActionTypes.REMOVE_FARM, (state, action) => {
        return state.filter(farmId => farmId !== action.farmId);
      }),
});

export const {loadFarmsList} = listSlice.actions;

const totalCountSlice = createSlice({
  name: 'farms/totalCount',
  initialState: 0,
  reducers: {},
  extraReducers: b =>
    b
      .addCase(
        loadFarmsList.type,
        (state, action: LoadFarmsListAction) => action.payload.farms.totalCount
      )
      .addCase<string, FarmAction>(FarmActionTypes.CREATE_FARM, state => state + 1)
      .addCase<string, FarmAction>(FarmActionTypes.REMOVE_FARM, state => state - 1),
});

const demoFarmsObjectSlice = createSlice({
  name: 'farms/demoFarmsObject',
  reducers: {},
  initialState: {},
  extraReducers: b =>
    b.addCase<string, LoadFarmsListAction>(
      loadFarmsList.type,
      (state, action) => action.payload.demoFarms
    ),
});

const demoFarmsIdsListSlice = createSlice({
  name: 'farms/demoFarmsIdsList',
  reducers: {},
  initialState: [],
  extraReducers: b =>
    b.addCase<string, LoadFarmsListAction>(loadFarmsList.type, (state, action) =>
      Object.keys(action.payload.demoFarms).map(id => Number(id))
    ),
});

const entitiesReducer = combineReducers<{
  farms: EntitiesState;
  fields: EntitiesState;
  seasons: EntitiesState;
  demoFarms: EntitiesState;
}>({
  demoFarms: createEntitiesSlice('demoFarms', 'farms', {name: 'monitoring/entities/farms'}).reducer,
  farms: createEntitiesSlice('farms', 'farms', {name: 'monitoring/entities/farms'}).reducer,
  fields: createEntitiesSlice('fields', 'farms', {name: 'monitoring/entities/farms'}).reducer,
  seasons: createEntitiesSlice('seasons', 'farms', {name: 'monitoring/entities/farms'}).reducer,
});

const reducer = combineReducers<FarmState>({
  list: listSlice.reducer,
  totalCount: totalCountSlice.reducer,
  demoFarmsObject: demoFarmsObjectSlice.reducer,
  demoFarmsIdsList: demoFarmsIdsListSlice.reducer,
  entities: entitiesReducer,
});

export default reducer;
