import React from 'react';
import cn from 'classnames';
import './blink.scss';

/**
 * Blink filler. The parent should be positioned relatively.
 */
export const Blink = ({blinking}: {blinking: boolean}) => {
  return <div className={cn({'animation--blink': blinking, blink: true})} />;
};
