// @ts-nocheck
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {Wrapper, CropItemWrapper, SelectedTitle} from './productivity-map.styled';
import type {AppStore} from 'reducers';
import {CropInfo, FluroButton, Sticky} from 'components';
import moment from 'moment';
import {GLOBAL_FORMAT_DATE} from '_constants';
import {convertFromSquareMetersToMeasure, formatDate, sensorView} from '_utils';
import {toFixedFloatUnsafe} from '_utils/number-formatters';
import {sortDates} from '_utils/pure-utils';
import {EditSelectedSeasonsListDialog} from './edit-selected-seasons-list-dialog';
import {AsyncStatusType, dialogToggle, DialogType, Status} from 'modules/helpers';
import {
  toggleProductivityMap,
  fetchData,
  PRODUCTIVITY_MAP_ASSETS_HOST,
  populateReachedPeakCanopyData,
} from './productivity-map.module';
import {DownloadIcon} from '../../../icons';
import {area as turfArea} from '@turf/turf';
import {FormattedMessage, t} from 'i18n-utils';
import cn from 'classnames';
import {ZoneElement} from '../zoning-zones';
import type {Zone} from '../../../types';
import {ZoneType} from '../../../types';
import {ContainersSeparator} from 'components/reusable-conponents.styled';
import {useAppDispatch, useAppSelector} from '_hooks';
import {selectCurrentField} from '../../../reducer/selectors';
import {selectMeasurement} from 'containers/login/login-selectors';
import {selectAsyncRequestStatus} from 'modules/global/selectors';
import {selectFarmsList} from 'modules/farms/selectors';

export const ProductivityMap = () => {
  const dispatch = useAppDispatch();
  const currentSeasonId = useAppSelector((state: AppStore) => state.map.currentSeasonId);
  const field = useAppSelector(selectCurrentField);
  const measurement = useAppSelector(selectMeasurement);
  const currentSensor = useAppSelector((state: AppStore) => state.map.currentSensor).toLowerCase();
  const _seasons = field.Seasons;
  const productivityMapData = useAppSelector(
    (state: AppStore) => state.productivityMap.data[state.productivityMap.currentCacheKey]
  );
  const isLoading = useAppSelector(
    state => selectAsyncRequestStatus(state, AsyncStatusType.productivityMap) === Status.Pending
  );

  const currentGroupId = useAppSelector((state: AppStore) => state.global.currentGroupId);
  const farms = useAppSelector(selectFarmsList);

  const farm = useMemo(() => farms.find(f => f.id === currentGroupId), [farms, currentGroupId]);

  const seasons = useMemo(() => {
    return populateReachedPeakCanopyData(_seasons);
  }, [_seasons]);

  const totalArea = useMemo(
    () => (productivityMapData?.geoJsonData ? turfArea(productivityMapData.geoJsonData) : 0),
    [productivityMapData]
  );

  const [selectedSeasonsIdsObj, setSelectedSeasonsIds] = useState<{[key: number]: boolean}>({
    [currentSeasonId]: true,
  });

  const selectedSeasonsIds = useMemo(() => {
    return Object.keys(selectedSeasonsIdsObj)
      .filter(k => selectedSeasonsIdsObj[parseInt(k)])
      .map(id => parseInt(id));
  }, [selectedSeasonsIdsObj]);

  const prodMapDates = useMemo(() => {
    const dates = sortDates(
      seasons
        .filter(s => selectedSeasonsIds.includes(s.id))
        .map(s => [s.startDate, s.endDate])
        .flat(),
      GLOBAL_FORMAT_DATE
    );

    if (dates.length >= 2) {
      return [
        moment(dates[0]).format(GLOBAL_FORMAT_DATE),
        moment(dates[dates.length - 1]).format(GLOBAL_FORMAT_DATE),
      ];
    } else {
      return [];
    }
  }, [selectedSeasonsIds, seasons]);

  useEffect(() => {
    dispatch(toggleProductivityMap(true));

    dispatch(
      fetchData(
        field?.MD5,
        currentSensor,
        prodMapDates[0],
        prodMapDates[1],
        `Productivity_zone_${farm.name.replaceAll(' ', '_')}_${field.Name.replaceAll(
          ' ',
          '_'
        )}_${currentSensor}`
      )
    );

    return function offProductivityMap() {
      dispatch(toggleProductivityMap(false));
    };
  }, [prodMapDates, currentSensor, field?.MD5]);

  const onEdit = useCallback(
    () =>
      dispatch(
        dialogToggle(DialogType.editSelectedSeasonsProductivityMap, true, {
          selectedIds: selectedSeasonsIds,
          setSelected: setSelectedSeasonsIds,
        })
      ),
    [selectedSeasonsIds, setSelectedSeasonsIds]
  );

  const exportShp = useCallback(() => {
    window.open(PRODUCTIVITY_MAP_ASSETS_HOST + productivityMapData.shapefile);
  }, [productivityMapData]);

  return (
    <>
      <Wrapper>
        <div>
          <SelectedTitle>
            <FormattedMessage
              id="Crops selected"
              defaultMessage="{count, plural, one {Crop} other {Crops}} selected"
              values={{count: seasons.length}}
            />
          </SelectedTitle>

          {seasons
            .filter(s => selectedSeasonsIds.includes(s.id))
            .map(({isReachedPeakCanopy, id, cropType, startDate, endDate, params}) => (
              <CropItemWrapper key={`season-${id}`}>
                <CropInfo
                  cropType={cropType}
                  cropSubType={params?.cropSubType}
                  startDate={getCropDate(startDate)}
                  endDate={getCropDate(endDate)}
                  message={
                    isReachedPeakCanopy
                      ? ''
                      : t({
                          id: "This season's crop hasn't yet reached peak green biomass, which may impact the accuracy of the productivity map reflecting fields' yield potential.",
                        })
                  }
                />
              </CropItemWrapper>
            ))}
        </div>

        {seasons.length >= 2 ? (
          <FluroButton
            readonly
            className="element-full-width margin-top-15 margin-bottom-5"
            raised
            onClick={onEdit}
          >
            {t({id: 'Edit Crop Selection'})}
          </FluroButton>
        ) : null}

        <ContainersSeparator />

        {productivityMapData && !isLoading ? (
          <div className="zones-container element-full-width">
            <h3 className="zones-title">
              <FormattedMessage id="Management zones" defaultMessage="Management zones" />
            </h3>

            <div className="zoning-statistics">
              <div className="zone-list-header">
                <div className={cn('zone-list-header__name')}>{t({id: 'Zone name'})}</div>
                <div className={'zone-list-header__area'}>
                  {t({id: 'Area'})} <br />
                </div>

                {/*// current sensor value*/}
                <div className={'zone-list-header__layer-value'}>
                  {sensorView(currentSensor.toUpperCase())}
                </div>
              </div>

              {productivityMapData.geoJsonData.features.map(({properties}) => {
                const zone = {
                  name: properties.name,
                  area: convertFromSquareMetersToMeasure(properties.area, measurement),
                  percent: toFixedFloatUnsafe((properties.area / totalArea) * 100, 0),
                  value: toFixedFloatUnsafe(properties?.[currentSensor + '_avg'] || 0),
                  type: ZoneType.Productivity,
                  color: properties.color,
                };
                return (
                  <ZoneElement
                    key={properties.name}
                    zone={zone as Zone}
                    index={properties.name}
                    editable={false}
                    setZoneParam={null}
                    measurement={measurement}
                  />
                );
              })}
            </div>
          </div>
        ) : null}

        {productivityMapData && !isLoading ? (
          <Sticky className="buttons-block">
            <FluroButton
              readonly
              className="btn-with-icon element-full-width"
              raised
              primary
              onClick={exportShp}
              iconEl={<DownloadIcon />}
            >
              {t({id: 'Export {index}'}, {index: 'SHP'})}
            </FluroButton>
          </Sticky>
        ) : null}
      </Wrapper>

      <EditSelectedSeasonsListDialog />
    </>
  );
};

export const getCropDate = (date: string) => moment(date, GLOBAL_FORMAT_DATE).format(formatDate());
