import React, {useState} from 'react';
import {Flex, FluroButton, Text} from 'components';
import {reportError} from 'containers/error-boundary';
import {generateEnrollmentDocusignLink} from 'containers/mrv/monitoring/module/thunks';
import {MRV_HOME} from 'containers/mrv/routes';
import {applyPathParams} from '_utils/pure-utils';
import {t} from 'i18n-utils';
import {useAppSelector, useAppThunkDispatch} from '_hooks';
import {MRVApi} from '_api/mrv';
import {selectContractExists} from 'containers/mrv/enrollment/carbon-store/selectors';
import {FluroProgressIcon} from 'components/fluro-progress-icon/fluro-progress-icon';
import {CheckIcon} from 'components/icons';
import {StageCustomView} from 'containers/mrv/monitoring/form/mrv-table/alternative-views/base';
import {viewTheContract} from 'containers/mrv/utils';
import {selectUserIsImpersonated} from 'containers/login/login-selectors';

type ProducerAgreementProps = {
  projectId: number;
  phaseId: number;
  isReadOnly: boolean;
};
export function ProducerAgreement({projectId, phaseId, isReadOnly}: ProducerAgreementProps) {
  const dispatch = useAppThunkDispatch();
  const [signingContract, setSigningContract] = useState(false);
  const contractExists = useAppSelector(selectContractExists);
  const userIsImpersonated = useAppSelector(selectUserIsImpersonated);

  async function generateProjectContract() {
    try {
      setSigningContract(true);
      await MRVApi.getDNDCResults(projectId, phaseId);
      const generatedContractLinkAction = await dispatch(
        generateEnrollmentDocusignLink({
          projectId,
          phaseId,
          redirect_url: location.origin + applyPathParams(MRV_HOME, {projectId}),
        })
      );

      if (generatedContractLinkAction.meta.requestStatus === 'fulfilled') {
        window.open(generatedContractLinkAction.payload as string, '_self');
      }
    } catch (error) {
      reportError(error);
    } finally {
      setSigningContract(false);
    }
  }

  function getTooltipPropsForSignProducerAgreementButton(): {
    tooltipPosition?: 'top';
    tooltipLabel?: string;
  } {
    if (!contractExists || userIsImpersonated) {
      return {
        tooltipPosition: 'top',
        tooltipLabel: t({
          id: 'ProducerAgreement.OnlySignedByProducersTooltip',
          defaultMessage: 'Producer Agreements can only be signed by producers.',
        }),
      };
    }

    return {};
  }

  const canGenerateContract = !isReadOnly && !contractExists && !userIsImpersonated;

  return (
    <StageCustomView
      illustration={
        <img src={'/assets/mrv/signing-document-illustration.svg'} alt="signing a document" />
      }
    >
      <Flex direction="column" alignItems="center" gap="10px">
        {contractExists && (
          <Flex alignItems="center" gap="10px">
            <FluroProgressIcon
              icon={<CheckIcon style={{width: 30, height: 30}} />}
              outlined
              active
              progressValue={100}
              hideProgressLabel
              id="producer-agreement-signed"
            />
            <Text variant="h3" inline>
              {t({id: 'Producer Agreement Signed!', defaultMessage: 'Producer Agreement Signed!'})}
            </Text>
          </Flex>
        )}
        {isReadOnly || contractExists ? (
          <FluroButton
            raised
            primary
            loading={signingContract}
            disabled={!contractExists}
            onClick={contractExists ? () => viewTheContract(projectId) : undefined}
          >
            {t({id: 'View Producer Agreement', defaultMessage: 'View Producer Agreement'})}
          </FluroButton>
        ) : (
          <FluroButton
            raised
            primary
            loading={signingContract}
            disabled={!canGenerateContract}
            onClick={canGenerateContract ? generateProjectContract : undefined}
            {...getTooltipPropsForSignProducerAgreementButton()}
          >
            {t({id: 'Sign Producer Agreement', defaultMessage: 'Sign Producer Agreement'})}
          </FluroButton>
        )}
      </Flex>
    </StageCustomView>
  );
}
