import React, {useEffect, useState} from 'react';
import {applyPathParams} from '_utils/pure-utils';
import {useAppDispatch, useAppSelector} from '_hooks';
import {useParsedMatchParams} from '_hooks/use-parsed-match-params';
import {CircularProgress} from 'react-md';
import {push} from 'connected-react-router';
import {Flex, Text, FluroButton, FluroDialog} from 'components';
import {AreaCropContainer} from 'containers/si/planning-report/create-report/summary/area-crop-summary';
import {InterventionScenarios} from 'containers/si/planning-report/create-report/interventions/interventions-scenarios';
import {
  selectSISupplyShedsObjectByProgramId,
  selectCurrentProgramSupplyShedIdBySubsectionId,
} from 'containers/si/module/selectors';
import {selectIsAdmin} from 'containers/login/login-selectors';
import {fetchSISupplySheds} from 'containers/si/module/thunks';
import type {InterventionOptions, PlanningReport} from 'containers/si/types';
import {SI_COLORS} from 'containers/si/colors';
import {PLANNING_REPORT_INTERVENTION_REVERSE_MAPPING} from 'containers/si/constants';
import {SI_REPORT_DASHBOARD} from 'containers/si/routes';

type ReportSummaryProps = {
  report: PlanningReport;
  onHide: () => void;
};

export const ReportSummary = ({report, onHide}: ReportSummaryProps) => {
  const dispatch = useAppDispatch();
  const {programId} = useParsedMatchParams();
  const supplySheds = useAppSelector(s => selectSISupplyShedsObjectByProgramId(s, programId));
  const subsectionIdObject = useAppSelector(selectCurrentProgramSupplyShedIdBySubsectionId);
  const isAdmin = useAppSelector(selectIsAdmin);

  const [reportLoading, setReportLoading] = useState(true);

  useEffect(() => {
    if (!Object.keys(supplySheds).length) {
      dispatch(fetchSISupplySheds(programId));
      setReportLoading(true);
    } else {
      setReportLoading(false);
    }
  }, [dispatch, report, programId, supplySheds]);

  return (
    <FluroDialog
      id={'report-summary-popup'}
      onHide={onHide}
      visible={true}
      width={'80%'}
      portal={true}
    >
      {reportLoading && (
        <>
          <Text variant="h2" bold>
            Loading Report Summary
          </Text>{' '}
          <CircularProgress className={'progress'} id={'si-reports-table'} />
        </>
      )}
      {!reportLoading && (
        <>
          <Flex direction="row" justifyContent={'space-between'}>
            <Flex direction="row" alignItems={'baseline'}>
              <Text variant="h2" bold>
                {`${report.name} (${report.start_year} - ${report.end_year})`}
              </Text>
              {isAdmin && (
                <Text className="margin-left-8" tertiary>
                  (#{report.id})
                </Text>
              )}
            </Flex>
            <FluroButton
              primary
              raised
              onClick={() => {
                dispatch(
                  push(
                    applyPathParams(SI_REPORT_DASHBOARD, {
                      programId: programId,
                      reportId: report.id,
                    })
                  )
                );
              }}
            >
              View Report
            </FluroButton>
          </Flex>
          <Flex direction="column" className={'summary-step'}>
            <Text variant="h2" className="mt-1">
              Areas and Crops
            </Text>

            <div>
              {report.sourcing_regions.map(region => {
                // If the unit_type is `supply_shed`, unit_id === supplyShedId
                // If the unit_type is `subsection`, unit_id === subsectionId
                const supplyShedId =
                  region.unit_type === 'supply_shed'
                    ? Number(region.unit_id)
                    : subsectionIdObject[Number(region.unit_id)];

                const shed = supplySheds[supplyShedId];

                if (region.unit_type === 'supply_shed') {
                  return (
                    <AreaCropContainer
                      key={region.unit_id}
                      areaId={shed?.id}
                      areaName={`${shed?.name}`}
                      color={shed?.color || SI_COLORS[0]}
                      commodities={region.commodities}
                      unitType={region.unit_type}
                      convertUnits // Convert units since it comes from the API in `kg`
                    />
                  );
                } else {
                  // Subsection
                  const subsection = shed?.subsections.filter(
                    s => s.id === Number(region.unit_id)
                  )[0];

                  return (
                    <AreaCropContainer
                      key={region.unit_id}
                      areaId={subsection?.id}
                      areaName={`${shed?.name}: ${subsection?.name}`}
                      color={shed?.color || SI_COLORS[0]}
                      commodities={region.commodities}
                      unitType={region.unit_type}
                      convertUnits
                    />
                  );
                }
              })}
            </div>
            <Text variant="h2" className="mt-3">
              Intervention Scenarios
            </Text>
            <div className="interventions-step">
              {report.intervention_scenarios.map((intervention, index) => {
                const intArr: [InterventionOptions, string][] = intervention.map((int, _index) => {
                  const option =
                    PLANNING_REPORT_INTERVENTION_REVERSE_MAPPING[int.intervention_type]
                      .interventionOption;
                  const label = PLANNING_REPORT_INTERVENTION_REVERSE_MAPPING[
                    int.intervention_type
                  ].label(int.percentage);

                  return [option, label];
                });

                return (
                  <React.Fragment key={index}>
                    <InterventionScenarios
                      scenarioNumber={index + 1}
                      interventionScenarioArray={intArr}
                    />
                    {isAdmin ? (
                      <Text className="margin-left-16">{`Scenario ${index + 1}: scenario_id ${
                        intervention[0]?.scenario_id
                      }`}</Text>
                    ) : (
                      ' '
                    )}
                  </React.Fragment>
                );
              })}
            </div>
          </Flex>
          <Flex className="mt-4" justifyContent="flex-end">
            <FluroButton raised primary className="mr-2" onClick={onHide}>
              Close
            </FluroButton>
          </Flex>
        </>
      )}
    </FluroDialog>
  );
};
