import React, {useState} from 'react';
import {Divider} from 'react-md';
import SIApi from 'containers/si/api/si';
import {useAppSelector} from '_hooks';
import {Flex, FluroButton, FluroChip, FluroDialog, Text} from 'components';
import {showNotification} from 'components/notification/notification';
import {FluroSelectionControlGroup} from 'components/fluro-selection-control-group/fluro-selection-control-group';
import {
  selectSIProgramById,
  selectSISupplyShedsListByProgramId,
} from 'containers/si/module/selectors';
import {LoadingIcon} from 'containers/mrv/icons';
import {ErrorIcon} from 'containers/map/icons';
import {main_error_500, main_gray_800, main_warning_500} from '_utils/colors';

import {SIHeaderColor} from 'containers/si/si-styled-components';
import styled from 'styled-components';

export const StyledRecomputeContainer = styled.div`
  position: relative;
  padding: 12px 0px 12px 16px;
  background-color: ${({theme}) => theme.color.surface.secondary};
  border-radius: 4px;
  margin-top: 8px;
`;

export const StyledRecomputeRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 8px;
`;

export const RecomputePopup = ({
  programId,
  onHide,
  portal = true,
}: {
  programId: number;
  onHide: () => void;
  portal?: boolean;
}) => {
  const programSupplySheds = useAppSelector(s => selectSISupplyShedsListByProgramId(s, programId));
  const program = useAppSelector(s => selectSIProgramById(s, programId));
  const [failedOnly, setFailedOnly] = useState('failed');
  const [recomputeArea, setRecomputeArea] = useState('true');

  const recomputeProgram = async () => {
    const response = await SIApi.recomputeProgram(
      programId,
      failedOnly === 'failed',
      recomputeArea === 'true'
    );
    // Console logging for admin-only debugging
    // eslint-disable-next-line no-console
    console.log('recomputeProgram response', response);
    showNotification({
      type: 'success',
      title: 'Success',
      message: `Program reprocessing.`,
    });
  };

  const recomputeSubsection = async (supplyShedId: number, subsectionId: number) => {
    const response = await SIApi.recomputeSubsection(
      programId,
      supplyShedId,
      subsectionId,
      recomputeArea === 'true'
    );
    // Console logging for admin-only debugging
    // eslint-disable-next-line no-console
    console.log('recomputeSubsection response', response);
    showNotification({
      type: 'success',
      title: 'Success',
      message: `Subsection reprocessing.`,
    });
  };

  return (
    <FluroDialog
      id={'si-program-name-popup'}
      onHide={onHide}
      visible={true}
      width={'80%'}
      portal={portal}
    >
      <Flex direction="column" className={'reprocess-popup'}>
        <Flex alignItems="baseline">
          <Text variant="h2">{program?.name} </Text>
          <Text className="ml-05" secondary variant="small-thin">
            #{program?.id}
          </Text>
        </Flex>
        <FluroSelectionControlGroup
          id="area-radio"
          name="area-radio"
          type="radio"
          value={recomputeArea}
          onChange={setRecomputeArea}
          controls={[
            {
              label: 'Recompute Area',
              value: 'true',
            },
            {
              label: 'Do Not Recompute Area',
              value: 'false',
            },
          ]}
          inline
        />
        <Divider />
        <Text variant="h2" className="mt-4">
          Recompute Entire Program
        </Text>
        <Flex direction="row" justifyContent="space-between">
          <FluroSelectionControlGroup
            id="failed-only-radio"
            name="failedOnly"
            type="radio"
            value={failedOnly}
            onChange={setFailedOnly}
            controls={[
              {
                label: 'Recompute Failed Only',
                value: 'failed',
              },
              {
                label: 'Recompute All',
                value: 'all',
              },
            ]}
            inline
          />

          <FluroButton raised primary onClick={() => recomputeProgram()} className="mb-4">
            Recompute Program
          </FluroButton>
        </Flex>
        <Divider />
        <Text variant="h2" className="mt-4">
          Recompute Subsection
        </Text>
        {programSupplySheds?.map(supplyShed => (
          <StyledRecomputeContainer key={supplyShed.id}>
            <SIHeaderColor headerColor={supplyShed.color} />
            <Flex alignItems="baseline">
              <Text variant="h2">Supply Shed: </Text>
              <Text variant="h2" primary>
                {supplyShed.name}{' '}
              </Text>
              <Text className="ml-05" secondary variant="small-thin">
                #{supplyShed.id}
              </Text>
            </Flex>
            {supplyShed.subsections?.map(subsection => (
              <div key={subsection?.id}>
                <Flex
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                  nowrap
                  className="m-1 ml-2"
                >
                  <StyledRecomputeRow>
                    <Text variant="h3" noMargin className="ml-4">
                      Subsection:{' '}
                    </Text>
                    <Text variant="h3" noMargin primary>
                      {subsection?.name}{' '}
                    </Text>
                    <Text className="ml-05" secondary variant="small-thin">
                      #{subsection?.id}
                    </Text>
                    <FluroChip
                      label={subsection?.status}
                      tone={'light'}
                      size={'small'}
                      className="ml-8"
                      style={{
                        backgroundColor:
                          subsection?.status === 'PROCESSING'
                            ? main_warning_500
                            : subsection?.status === 'FAILED'
                            ? main_error_500
                            : '#ffffff',
                        color:
                          subsection?.status === 'PROCESSING'
                            ? '#ffffff'
                            : subsection?.status === 'FAILED'
                            ? '#ffffff'
                            : main_gray_800,
                      }}
                      leftIcon={
                        subsection?.status === 'PROCESSING' ? (
                          <LoadingIcon style={{color: '#ffffff'}} />
                        ) : subsection?.status === 'FAILED' ? (
                          <ErrorIcon style={{color: '#ffffff'}} />
                        ) : null
                      }
                    />
                  </StyledRecomputeRow>
                  <FluroButton
                    raised
                    primary
                    onClick={() => recomputeSubsection(supplyShed.id, subsection.id)}
                  >
                    Recompute {subsection.name}
                  </FluroButton>
                </Flex>
              </div>
            ))}
          </StyledRecomputeContainer>
        ))}
      </Flex>
      <Flex justifyContent="space-between" className="mt-2">
        <FluroButton raised blank onClick={onHide}>
          Cancel
        </FluroButton>
      </Flex>
    </FluroDialog>
  );
};
