import React, {useContext, useMemo} from 'react';
import {FluroSelectLite} from 'components/fluro-select-lite/fluro-select-lite';
import {InputContext} from './input-context';
import {MRVSelectContainer} from './styled';
import {selectAttributeByType} from 'containers/mrv/monitoring/module/selectors';
import {MRVAttributeType, MRVPhaseType, MRVStageType} from 'containers/mrv/types';
import {useAppSelector} from '_hooks';
import type {InputComponentProps} from 'containers/mrv/monitoring/form/inputs/input-component';
import {t} from 'i18n-utils';
import {attributeOptionsToListItems} from 'containers/mrv/base/base';

type ProductSelectProps = {
  onChange: InputComponentProps['onChange'];
};

export function ProductSelect({onChange}: ProductSelectProps) {
  const {id, value, defaultValue, options, disabled, placeholder, label} = useContext(InputContext);

  const categoryAttribute = useAppSelector(s =>
    selectAttributeByType(
      s,
      MRVPhaseType.Monitoring,
      MRVStageType.NutrientManagement,
      MRVAttributeType.NutrientManagementAppProductType
    )
  );

  const items = useMemo(
    () =>
      attributeOptionsToListItems(options || []).map(item => ({
        ...item,
        label: t({id: `Option.${item.value}`, defaultMessage: item.label as string}),
      })) || [],
    [options]
  );
  const selectedValue = value || defaultValue;

  const fuzzySimplify = (s?: string) => s?.toLowerCase().replace(/[^a-z0-9]/gi, '');
  const fuzzySelectedValue = fuzzySimplify(selectedValue);

  const foundItem = items.find(item => fuzzySimplify(item.value) === fuzzySelectedValue);

  return (
    <MRVSelectContainer>
      <FluroSelectLite
        id={id}
        items={items}
        disabled={disabled}
        selectedValue={foundItem?.value}
        placeholder={
          placeholder || t({id: 'Placeholder.Select product', defaultMessage: 'Select product'})
        }
        // Display the value as a label if the value doesn't exist in our list of available options.
        label={foundItem ? label : selectedValue}
        onSelect={(v, category) =>
          onChange(
            v,
            true,
            categoryAttribute ? [{attribute: categoryAttribute, value: category}] : undefined
          )
        }
        className="product-select"
        isSearchable
      />
    </MRVSelectContainer>
  );
}
