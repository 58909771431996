import cn from 'classnames';
import type {ComponentType, HTMLAttributes} from 'react';
import React from 'react';
import {CardHeading, CardStyled} from './card-styled';
import styled from 'styled-components';

type Props = {
  active: boolean;
  heading?: JSX.Element;
};

const CardRestyled = styled(CardStyled)`
  margin: 20px 0;
`;

export const CardBase: ComponentType<Props & HTMLAttributes<HTMLElement>> = ({
  children,
  active,
  heading,

  ...cardProps
}) => {
  return (
    <CardRestyled {...cardProps} className={cn(`card ${cardProps.className || ''}`, {active})}>
      {heading && <CardHeading className={'card-heading'}>{heading}</CardHeading>}
      {children}
    </CardRestyled>
  );
};
