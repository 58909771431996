export const jobTitles = [
  {
    label: 'Agronomist',
    value: 'agronomist',
  },
  {
    label: 'Grower',
    value: 'grower',
  },
  {
    label: 'Dealer',
    value: 'dealer',
  },
  {
    label: 'Technician',
    value: 'technician',
  },
  {
    label: 'Operator',
    value: 'operator',
  },
  {
    label: 'PA Manager',
    value: 'pa_manager',
  },
  {
    label: 'Service provider',
    value: 'service_provider',
  },
  {
    label: 'Division manager',
    value: 'division_manager',
  },
  {
    label: 'Research',
    value: 'research',
  },
  {
    label: 'Other',
    value: 'other',
  },
];
