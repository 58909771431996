// @ts-nocheck
import {createSlice} from '@reduxjs/toolkit';
import {SUPPLY_SHEDS, SUPPLY_SHEDS_META} from 'modules/sustainability-insights/constants';
import type {NormalizedSchema} from 'normalizr';
import {combineReducers} from 'redux';
import {fetchGeometries, fetchMetrics, fetchSummerCropTypes, getUserPolicy} from '../thunks';
import type {SIAggLevel, SIDataState, SIDataAggState} from '../types';
import {parseAreaId} from '../utils';

const summerCropTypesSlice = createSlice({
  name: 'summerCropTypes',
  initialState: {},
  reducers: {},
  extraReducers: {
    [fetchSummerCropTypes.fulfilled.type]: (state, action) => action.payload,
  },
});

const policySlice = createSlice({
  name: 'policy',
  initialState: {
    aggLevelsAvailable: [],
    metricGroupsAvailable: [],
    yearsAvailable: [],
    state: [],
    crd: [],
    county: [],
    huc8: [],
    huc10: [],
    huc12: [],
    supply_shed: Object.keys(SUPPLY_SHEDS).map(Number), // Supply sheds are known strings and ids and stored on UI side as well
  },
  reducers: {
    setPolicy(state, action) {
      const {yearsAvailable, aggLevelsAvailable, metricGroupsAvailable} = action.payload;
      state.yearsAvailable = yearsAvailable;
      state.aggLevelsAvailable = aggLevelsAvailable;
      state.metricGroupsAvailable = metricGroupsAvailable;
    },
  },
  extraReducers: builder => {
    builder.addCase<string, {type: string; payload: NormalizedSchema<any, any>}>(
      getUserPolicy.fulfilled.type,
      (state, action) => {
        const {
          entities,
          result: {years, metric_groups, agg_levels},
        } = action.payload;

        const newState = {
          ...state,
          aggLevelsAvailable: agg_levels,
          metricGroupsAvailable: metric_groups,
          yearsAvailable: years,
        };

        const aggLevels = Object.keys(entities);
        const newPolicy = aggLevels.reduce<Partial<SIDataState['policy']>>(
          (acc, entityType: SIAggLevel) => {
            const newEntities = entities[entityType];
            acc[entityType] = Object.keys(newEntities).map(parseAreaId).filter(Boolean);
            return acc;
          },
          {}
        );

        return {...newState, ...newPolicy};
      }
    );
  },
});

export const {setPolicy} = policySlice.actions;

const createAreaAggregationSlice = (
  aggLevel: SIAggLevel,
  defaultValues: Partial<SIDataAggState> = {}
) =>
  createSlice({
    name: aggLevel,
    initialState: {
      geometries: {},
      meta: {},
      metrics: {},
      summary: {},
      ...defaultValues,
    },
    reducers: {},
    extraReducers: builder => {
      builder
        .addCase<any, any>(fetchGeometries.fulfilled.type, (state, action) => {
          const {
            payload: {geometries},
            meta: {
              arg: {agg_level},
            },
          } = action;
          if (agg_level !== aggLevel) return state;
          state.geometries = {...state.geometries, ...geometries};
        })
        .addCase<any, any>(fetchMetrics.fulfilled.type, (state, action) => {
          const {
            payload: {metrics, summary},
            meta: {
              arg: {agg_level},
            },
          } = action;
          if (agg_level !== aggLevel) return state;
          state.metrics = {...state.metrics, ...metrics};
          state.summary = {...state.summary, ...summary};
        })
        .addCase<string, any>(getUserPolicy.fulfilled.type, (state, action) => {
          const {entities} = action.payload;
          const newMeta = entities[aggLevel];
          state.meta = {...state.meta, ...newMeta};
        });
    },
  });

const stateSlice = createAreaAggregationSlice('state');
const crdSlice = createAreaAggregationSlice('crd');
const countySlice = createAreaAggregationSlice('county');
const huc8Slice = createAreaAggregationSlice('huc8');
const huc10Slice = createAreaAggregationSlice('huc10');
const huc12Slice = createAreaAggregationSlice('huc12');
const supplyShedsSlice = createAreaAggregationSlice('supply_shed', {meta: SUPPLY_SHEDS_META});

export const dataReducer = combineReducers<SIDataState>({
  summerCropTypes: summerCropTypesSlice.reducer,
  policy: policySlice.reducer,
  supply_shed: supplyShedsSlice.reducer,
  state: stateSlice.reducer,
  crd: crdSlice.reducer,
  county: countySlice.reducer,
  huc8: huc8Slice.reducer,
  huc10: huc10Slice.reducer,
  huc12: huc12Slice.reducer,
});
