import type {ComponentType} from 'react';
import React, {useState} from 'react';
import {Flex, FluroButton, FluroInput, Text} from 'components';
import {CheckIcon} from 'components/icons';
import {useTheme} from 'styled-components';

export type EditableMob = {
  name: string;
  id: number;
  isEditing: boolean;
};

type Props = {
  mob: EditableMob;
  onChange: (mob: EditableMob | null) => void;
};

export const MobEditRow: ComponentType<Props> = ({mob, onChange}) => {
  const [mobName, setMobName] = useState<string>(mob.name);
  const theme = useTheme();

  const handleMobChange = () => {
    onChange({...mob, name: mobName, isEditing: false});
  };

  const handleMobDelete = () => {
    onChange(null);
  };

  const handleMobEdit = () => {
    onChange({...mob, isEditing: true});
  };

  return (
    <>
      <Flex justifyContent={'space-between'} alignItems={'center'} gap={'10px'}>
        {mob.isEditing ? (
          <FluroInput className="mt-0 mob-edit-row-input" value={mobName} onChange={setMobName} />
        ) : (
          <Text variant={'medium'} className="mb-2 mt-2 mob-edit-row-input">
            {mobName}
          </Text>
        )}

        <Flex alignItems={'center'}>
          {mob.isEditing ? (
            <FluroButton
              icon
              iconEl={
                <CheckIcon
                  circleColor={'transparent'}
                  checkMarkColor={theme.colorPalette.fs_main.green_400}
                />
              }
              onClick={handleMobChange}
            />
          ) : (
            <FluroButton icon onClick={handleMobEdit}>
              edit
            </FluroButton>
          )}
          <FluroButton icon onClick={handleMobDelete}>
            delete
          </FluroButton>
        </Flex>
      </Flex>
    </>
  );
};
