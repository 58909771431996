// @ts-nocheck
import type {ReactElement} from 'react';
import {useState, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {loadCropTypes} from 'modules/global/actions';
import {checkAuth} from './login/actions';
import {getFarmsList} from 'modules/farms/actions';
import type {AppStore} from 'reducers';
import {useUrlParams} from '_hooks/use-url-params';
import {reportError} from './error-boundary';
import {safeLocalStorage} from '_utils/safe-local-storage';
type Props = {
  children: ReactElement;
};
const BootAuthContainer = ({children}: Props) => {
  const dispatch = useDispatch();
  const [{token, redirectUrl}] = useUrlParams();
  const [fetching, setFetching] = useState(true);
  const sessionExpired = useSelector((s: AppStore) => s.global.sessionExpired);

  const bootAuth = async () => {
    try {
      await dispatch(checkAuth());
      await dispatch(loadCropTypes());
      dispatch(getFarmsList());
    } catch (e) {
      if (e !== 'No token set') {
        reportError(e);
      }
    } finally {
      setFetching(false);
    }
  };

  const bootIntegrationTokenAuth = () => {
    safeLocalStorage.setItem('token', token);
    window.location.assign(redirectUrl || '/');
  };

  useEffect(() => {
    if (token) {
      bootIntegrationTokenAuth();
    } else {
      bootAuth();
    }
  }, []);

  // The weirdest magic here: for some reason in the pr https://github.com/flurosat/flurosense-ui/pull/1425
  // when auth returns 401 the code outside try above doesn't run and `fetching` does not get reset.
  useEffect(() => {
    if (sessionExpired) {
      setFetching(false);
    }
  }, [sessionExpired]);

  return fetching ? null : children;
};

export default BootAuthContainer;
