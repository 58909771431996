import styled from 'styled-components';
const permColors: any = {
  admin: '#0683F9',
  write: '#43A047',
  read: '#FFB118',
};

export const PermissionLabel = styled.span`
  color: ${({value}: {value: string}) => permColors[value] || '#000'};
  background: #fff;
  padding: 5px 10px;
  border: 1px solid ${({value}: {value: string}) => permColors[value] || '#000'};
  border-radius: 3px;
  margin-left: 5px;
`;

export const Title = styled.div`
  font-weight: 500;
`;

export const FarmId = styled.span`
  color: rgba(0, 0, 0, 0.6);
`;
