// @ts-nocheck
import type {Locales} from 'i18n-utils';
import {langInfo} from 'i18n-utils';
import type {ReactElement} from 'react';
import React from 'react';
import {SelectField} from 'react-md';

export enum LangValue {
  EN = 'en',
  PT = 'pt',
}

const langItems: {label: string; value: LangValue}[] = [
  {
    label: 'English',
    value: LangValue.EN,
  },
  {
    label: 'Portuguese',
    value: LangValue.PT,
  },
];

type LangSelectProps = {
  id: string | number;
  value: LangValue;
  onChange: (value: LangValue) => void;
  errorMessage?: string;
  simplifiedMenu?: boolean;
};

export const LangSelect = ({
  id,
  onChange,
  value,
  errorMessage,
  simplifiedMenu = true,
}: LangSelectProps) => {
  return (
    <SelectField
      id={`lang-${id}`}
      label="Language"
      menuItems={langItems}
      onChange={onChange}
      value={value}
      simplifiedMenu={simplifiedMenu}
      error={!!errorMessage}
      errorText={errorMessage}
      fullWidth
    />
  );
};

type LocaleSelectProps = {
  id: string | number;
  value: Locales;
  onChange: (value: Locales) => void;
  errorMessage?: string;
};

export const LocaleSelect = ({id, onChange, value, errorMessage}: LocaleSelectProps) => {
  return (
    <SelectField
      id={`lang-${id}`}
      label="Language"
      menuItems={langInfo}
      onChange={onChange}
      value={value}
      simplifiedMenu
      error={!!errorMessage}
      errorText={errorMessage}
      fullWidth
    />
  );
};

export enum LogoType {
  Climate = 'https://storage.googleapis.com/flurosat-email/common/climate_logo.png',
  CropQuest = 'https://storage.googleapis.com/flurosat-email/common/cropquest_logo.png',
  Lambweston = 'https://storage.googleapis.com/flurosat-email/common/lambweston_logo.png',
  McCain = 'https://storage.googleapis.com/flurosat-email/common/mccain_logo.png',
  Regrow = 'https://storage.googleapis.com/flurosat-email/common/regrow_logo.png',
  FVC = 'https://storage.googleapis.com/flurosat-email/common/fvc_logo.jpeg',
  NK = 'https://storage.googleapis.com/flurosat-email/common/nk_logo.jpg',
  AgView = 'https://storage.googleapis.com/flurosat-email/common/agview_logo.png',
  Simplot = 'https://storage.googleapis.com/flurosat-email/common/simplot_logo.png',
  AgLeader = 'https://storage.googleapis.com/flurosat-email/common/agleader_logo.png',
  Growmark = 'https://storage.googleapis.com/flurosat-email/common/growmark_logo.png',
  DairyOne = 'https://storage.googleapis.com/flurosat-email/common/diary_one_logo.png',
  Netafim = 'https://storage.googleapis.com/flurosat-email/common/netafim.png',
  TallCrop = 'https://storage.googleapis.com/flurosat-email/common/tall_crop_logo.jpg',
  Echelon = 'https://storage.googleapis.com/flurosat-email/common/echelon.png',
  Nutrien = 'https://storage.googleapis.com/flurosat-email/common/nutrien.png',
}

const logoItems: {label: ReactElement; value: LogoType}[] = [
  {
    label: (
      <div className="media-item">
        <i style={{backgroundImage: `url(${LogoType.Regrow})`}} /> <span>Regrow</span>
      </div>
    ),
    value: LogoType.Regrow,
  },
  {
    label: (
      <div className="media-item">
        <i style={{backgroundImage: `url(${LogoType.CropQuest})`}} /> <span>CropQuest</span>
      </div>
    ),
    value: LogoType.CropQuest,
  },
  {
    label: (
      <div className="media-item">
        <i style={{backgroundImage: `url(${LogoType.Lambweston})`}} /> <span>Lambweston</span>
      </div>
    ),
    value: LogoType.Lambweston,
  },
  {
    label: (
      <div className="media-item">
        <i style={{backgroundImage: `url(${LogoType.McCain})`}} /> <span>McCain</span>
      </div>
    ),
    value: LogoType.McCain,
  },
  {
    label: (
      <div className="media-item">
        <i style={{backgroundImage: `url(${LogoType.Climate})`}} /> <span>Climate</span>
      </div>
    ),
    value: LogoType.Climate,
  },
  {
    label: (
      <div className="media-item">
        <i style={{backgroundImage: `url(${LogoType.FVC})`}} /> <span>FVC</span>
      </div>
    ),
    value: LogoType.FVC,
  },
  {
    label: (
      <div className="media-item">
        <i style={{backgroundImage: `url(${LogoType.NK})`}} /> <span>NK</span>
      </div>
    ),
    value: LogoType.NK,
  },
  {
    label: (
      <div className="media-item">
        <i style={{backgroundImage: `url(${LogoType.AgView})`}} /> <span>Ag View</span>
      </div>
    ),
    value: LogoType.AgView,
  },
  {
    label: (
      <div className="media-item">
        <i style={{backgroundImage: `url(${LogoType.Simplot})`}} /> <span>Simplot</span>
      </div>
    ),
    value: LogoType.Simplot,
  },
  {
    label: (
      <div className="media-item">
        <i style={{backgroundImage: `url(${LogoType.AgLeader})`}} /> <span>Ag Leader</span>
      </div>
    ),
    value: LogoType.AgLeader,
  },
  {
    label: (
      <div className="media-item">
        <i style={{backgroundImage: `url(${LogoType.Growmark})`}} /> <span>Growmark</span>
      </div>
    ),
    value: LogoType.Growmark,
  },
  {
    label: (
      <div className="media-item">
        <i style={{backgroundImage: `url(${LogoType.DairyOne})`}} /> <span>Dairy One</span>
      </div>
    ),
    value: LogoType.DairyOne,
  },
  {
    label: (
      <div className="media-item">
        <i style={{backgroundImage: `url(${LogoType.Netafim})`}} /> <span>Netafim</span>
      </div>
    ),
    value: LogoType.Netafim,
  },
  {
    label: (
      <div className="media-item">
        <i style={{backgroundImage: `url(${LogoType.TallCrop})`}} /> <span>Tall Crop</span>
      </div>
    ),
    value: LogoType.TallCrop,
  },
  {
    label: (
      <div className="media-item">
        <i style={{backgroundImage: `url(${LogoType.Echelon})`}} /> <span>Echelon</span>
      </div>
    ),
    value: LogoType.Echelon,
  },
  {
    label: (
      <div className="media-item">
        <i style={{backgroundImage: `url(${LogoType.Nutrien})`}} /> <span>Nutrien</span>
      </div>
    ),
    value: LogoType.Nutrien,
  },
];

type LogoSelectProps = {
  id: string | number;
  value: LogoType;
  onChange: (value: LogoType) => void;
  errorMessage?: string;
  hasLabel?: boolean;
  fullWidth?: boolean;
  disabled?: boolean;
  simplifiedMenu?: boolean;
};

export const LogoSelect = ({
  hasLabel = true,
  fullWidth = true,
  disabled,
  id,
  onChange,
  value,
  errorMessage,
  simplifiedMenu = true,
}: LogoSelectProps) => {
  return (
    <SelectField
      id={`logo-${id}`}
      label={hasLabel ? 'Logo' : null}
      placeholder="Logo"
      menuItems={logoItems.sort((a, b) => a.value.localeCompare(b.value))}
      onChange={onChange}
      value={value}
      simplifiedMenu={simplifiedMenu}
      error={!!errorMessage}
      errorText={errorMessage}
      fullWidth={fullWidth}
      disabled={disabled}
    />
  );
};
