import type {ComponentType} from 'react';
import React from 'react';
import {FluroDialog, Flex, FluroButton, Text} from 'components';
import {CropAvatar} from 'components/crop-avatar';
import {useAppSelector} from '_hooks';
import {selectFieldById} from '../../../monitoring/module/selectors';
import {classifyAreaWithUnits} from '_utils';
import {selectMeasurement} from '../../../../login/login-selectors';
import type {MRVProjectEligibility} from '../../../types';
import {EligibilityMethod} from '../../../types';
import {FormattedMessage, t} from 'i18n-utils';

type Props = {
  eligibilityMethod: EligibilityMethod | null;
  fieldId: number;
  onHide: () => void;
  removeField: () => void;
  fieldsEligibility: MRVProjectEligibility | null;
};

const IneligibleFieldDialog: ComponentType<Props> = ({
  onHide,
  eligibilityMethod,
  fieldId,
  removeField,
  fieldsEligibility,
}) => {
  const field = useAppSelector(s => selectFieldById(s, fieldId));
  const measurement = useAppSelector(selectMeasurement);
  const fieldArea = classifyAreaWithUnits(field?.core_attributes?.field_area, measurement);
  const fieldName = field?.core_attributes?.field_name;

  const message = fieldsEligibility?.[fieldId]?.message;
  const cropTypes: string[] | undefined = fieldsEligibility?.[fieldId]?.crop_types;
  const missingCropHistory =
    eligibilityMethod !== EligibilityMethod.AlwaysEligible && !fieldsEligibility?.[fieldId]; // missing field in the eligibility response assume it has no historic practices set

  const onRemoveField = () => {
    removeField();
    onHide();
  };

  return (
    <FluroDialog
      title={
        missingCropHistory ? t({id: 'Missing crop history'}) : t({id: 'Ineligible crop history'})
      }
      onHide={onHide}
      visible={true}
      id={'ineligible-field-dialog'}
      className={'ineligible-field-dialog'}
      width={400}
    >
      <Text className={'margin-bottom-10'} secondary variant={'medium'}>
        {fieldName} | {fieldArea}
      </Text>
      <Text className={'margin-bottom-20'} variant={'medium'}>
        {missingCropHistory ? (
          t({
            id: 'The cropping history for this field is incomplete, you can not assign practices.',
          })
        ) : (
          <Flex>
            {cropTypes?.length ? (
              <FormattedMessage
                id="OnlyFieldsEligibleForProgramMessage"
                defaultMessage="Only fields that previously had {cropTypes} are eligible for this program."
                values={{
                  cropTypes: cropTypes.map(cropType => {
                    return (
                      <Flex alignItems={'center'} key={cropType}>
                        <CropAvatar cropType={cropType} />
                        {cropType},
                      </Flex>
                    );
                  }),
                }}
              />
            ) : (
              // TODO: get the MRV service to send a message `type` so that the UI has full control over the message itself.
              // Hack for the backend to return a translated message for ineligible fields, should be translated on the backend.
              t({id: message, defaultMessage: message})
            )}
          </Flex>
        )}
      </Text>
      <Flex className={'margin-top-15'} fullWidth justifyContent={'flex-end'}>
        <FluroButton primary raised onClick={onRemoveField}>
          {t({id: 'BtnLabel.RemoveFieldFromProgram', defaultMessage: 'Remove field from program'})}
        </FluroButton>
      </Flex>
    </FluroDialog>
  );
};

export default IneligibleFieldDialog;
