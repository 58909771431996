import React from 'react';
import './placeholder-loader.scss';

export const PlaceholderLoader = ({
  width,
  height,
  borderRadius,
  ...props
}: {
  width?: number;
  height?: number;
  borderRadius?: 's' | 'm' | 'l';
} & React.HTMLProps<HTMLDivElement>) => {
  const style = props?.style || {};
  if (width) style.width = width;
  if (height) style.height = height;
  if (borderRadius) style.borderRadius = borderRadiusValues[borderRadius];
  return <div className="placeholder-loader animation--pulsate" style={style} {...props}></div>;
};

const borderRadiusValues = {
  s: '2px',
  m: '4px',
  l: '10px',
};
