import {isString} from '_utils/typeGuards';

/**
 * Accepts a number or string and returns a number to a fixed max length after the decimal
 */
export function toFixedFloat(val: number | string, num = 2): number {
  const numericVal = isString(val) ? parseFloat(val) : val;
  return isFinite(numericVal) && !isNaN(numericVal) ? parseFloat(numericVal.toFixed(num)) : 0;
}

/**
 * @deprecated use toFixedFloat instead
 */
export function toFixedFloatUnsafe(val: any, num = 2) {
  if (isFinite(val) && val !== null) {
    val = parseFloat(val);

    if (val === 0) return val;
    return parseFloat(val.toFixed(num));
  }

  return 0;
}

export const kiloFormatter = (
  num = 0,
  /** number of decimals for values less than 1000 */
  numDecimalsLT1k = 1
): string => {
  if (Math.abs(num) < 1000) return toFixedFloatUnsafe(num, numDecimalsLT1k);
  if (Math.abs(num) > 1000000) {
    return `${toFixedFloatUnsafe(num / 1000000, 1)}m`;
  }

  return `${toFixedFloatUnsafe(num / 1000, 1)}k`;
};
