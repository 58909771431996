import type {
  Season,
  SamplingPoint,
  ITreeData,
  TInfoExt,
  CloudV2,
  Field,
  DatesObj,
  FieldSoilLayers,
} from '../types';
import {iterateLayers} from '../types';
import {toFixedFloatUnsafe} from '_utils/number-formatters';
import moment from 'moment';
import {GLOBAL_FORMAT_DATE} from '_constants';
import type {AppStore} from 'reducers';
import {selectAnalyticsItemColor} from 'containers/map/features/analytics/analytics-selectors';

export const updateSeasonData = (
  getState: () => AppStore,
  seasons: Array<Season>,
  treeData: Array<ITreeData>,
  cloudV2?: CloudV2
): Array<Season> => {
  return seasons.map(_s => {
    _s.tissueSampling = _s.tissueSampling?.length
      ? updateSeasonTSP(getState, _s.tissueSampling)
      : [];
    _s.infoExt =
      (treeData.length || cloudV2) && _s.infoExt
        ? updateSeasonInfoExt(_s.infoExt, treeData, cloudV2)
        : _s.infoExt || [];
    return _s;
  });
};

const updateSeasonTSP = (getState: () => AppStore, points: Array<SamplingPoint>) => {
  let currentColors: string[] = [];

  return points.map((point: SamplingPoint) => {
    const currentColor = selectAnalyticsItemColor(getState(), currentColors);
    currentColors = [...currentColors, currentColor]; // modify the array to pass a new one to the selectAnalyticsItemColor selector
    const migration = (sampleType: string) =>
      sampleType === 'Petiole'
        ? 'Petiole sample'
        : sampleType === 'Leaf'
        ? 'Leaf sample'
        : sampleType;

    return {
      ...point,
      properties: {
        ...point.properties,
        color: currentColor,
        growthStage: point?.properties?.growthStage || point?.properties?.growth_stage || '',
        checked: false, // default checked value
        samplingPointType:
          migration(String(point?.properties?.samplingPointType)) ||
          migration(String(point?.properties?.plantMaterialType)) ||
          'Petiole sample',
        timedate: moment(point?.properties?.timedate, GLOBAL_FORMAT_DATE).isValid()
          ? moment(point?.properties?.timedate, GLOBAL_FORMAT_DATE).format(GLOBAL_FORMAT_DATE)
          : moment.utc(point?.properties?.timedate).format(GLOBAL_FORMAT_DATE),
      },
    };
  });
};

// Pay attention that it's 'dron' and not 'drone'.
const layersWithTreeAnalysis = ['plane', 'dron', 'satellite_hd'];

const updateSeasonInfoExt = (
  infoExt: Array<TInfoExt>,
  treeData: Array<ITreeData>,
  cloudV2?: CloudV2
) => {
  const treesDataObj = classifyTreeData(treeData);

  const cloudV2Data =
    cloudV2?.data.reduce((obj: any, data: any) => {
      if (data.cloudyV2 !== undefined && ['copernicus', 'google'].includes(data.source)) {
        const sensingDate = moment(data.sensing, 'YYYY-MM-DD').format(GLOBAL_FORMAT_DATE);
        obj[sensingDate] = {
          date: data.cloudyV2,
          source: data.source,
        };
      }
      return obj;
    }, {}) || {};

  return infoExt.map((dateObject: TInfoExt) => {
    const loopDate = moment(dateObject.Date, 'YYYYMMDD').format(GLOBAL_FORMAT_DATE);
    // set trees data
    if (
      (treesDataObj[loopDate] && layersWithTreeAnalysis.includes(dateObject.Type)) ||
      (dateObject?.Type.includes('satellite') && treesDataObj[loopDate]?.imagery_type === 'vnir')
    ) {
      dateObject.treeData = treesDataObj[loopDate];
      dateObject.appName = Array.isArray(dateObject.appName)
        ? [...dateObject.appName, 'tree_analysis']
        : ['tree_analysis'];
    }
    // set trees data end
    /// set cloudy v2 data
    if (cloudV2Data[loopDate] !== undefined) {
      dateObject.CloudV2 = toFixedFloatUnsafe(cloudV2Data[loopDate].date, 0);
      dateObject.Source = cloudV2Data[loopDate].source;
    }
    // end set cloudy v2 data

    return dateObject;
  });
};

export const calculateFieldSeason = (seasons: Season[], currentDate: string): Season => {
  let resultSeason: Season | undefined = undefined;
  const dateNow = moment();
  const currentMomentDate = moment(currentDate, 'DD/MM/YYYY');
  if (seasons.length === 1) return (resultSeason = seasons[0]); // return the season[0] if it is single for the field

  seasons.forEach((s: Season) => {
    // if field has season that intersects current date, return it
    if (
      currentMomentDate.isSameOrAfter(s.startDate) &&
      currentMomentDate.isSameOrBefore(s.endDate) &&
      s.infoExt?.length
    ) {
      return (resultSeason = s);
    }
  });

  if (resultSeason) return resultSeason;

  [...seasons].reverse().forEach(s => {
    // try to find season that is not in future
    const resultSeasonDate = resultSeason && moment(resultSeason.startDate, GLOBAL_FORMAT_DATE);
    const currentLoopSeasonStartDate = moment(s.startDate, GLOBAL_FORMAT_DATE);
    if (
      !resultSeason ||
      (resultSeasonDate?.isAfter(dateNow) && // season in future
        currentLoopSeasonStartDate.isBefore(resultSeasonDate))
    ) {
      resultSeason = s;
    }
  });

  return resultSeason || ({} as Season);
};

export const getFieldsWithAllDates = (
  fields: Field[],
  treeDetectionData: {[fieldId: string]: ITreeData[]}
) => {
  try {
    const fieldsObject: {[md5: string]: DatesObj} = {};
    const tsps: GeoJSON.Feature[][] = [];
    fields
      .filter(f => f.Seasons)
      .forEach(field => {
        const arrayDates = field?.Seasons?.filter(season => season.infoExt).flatMap(season => [
          ...(season.infoExt || []),
        ]);
        const fieldTreeData = classifyTreeData(treeDetectionData[Number(field.FieldID)]);

        const newTsps =
          field?.Seasons?.filter(
            season => season.tissueSampling && season.tissueSampling.length
          ).map(season => [...(season.tissueSampling as GeoJSON.Feature[])]) || [];

        tsps.push(...newTsps);

        const dates: DatesObj = {};
        arrayDates?.forEach(d => {
          const loopDate = moment(d.Date, 'YYYYMMDD').format(GLOBAL_FORMAT_DATE);
          const treeData =
            d.Type !== 'satellite' && fieldTreeData[loopDate] ? fieldTreeData[loopDate] : {};

          iterateLayers(d, (layer, sensor) => {
            const keyDate = `${layer.display}-${d.Type}`;

            dates[keyDate] = {
              ...dates[keyDate],
              [sensor]: layer,
              Cloud: d.Cloud,
              Hidden: d.Hidden,
              Date: d.Date,
              Type: d.Type,
              treeData,
            };
          });
        });

        fieldsObject[field.MD5] = dates;
      });
    return {fieldsObject, tsps: tsps.flat()};
  } catch (err) {
    return {fieldsObject: {}, tsps: []};
  }
};

const classifyTreeData = (treeData: ITreeData[]) => {
  return (
    treeData?.reduce(
      (obj: any, treeData: ITreeData) => ({
        ...obj,
        [moment.utc(treeData.sensing_date).format(GLOBAL_FORMAT_DATE)]: treeData,
      }),
      {}
    ) || {}
  );
};

export const getFieldSoilLayer = (data: FieldSoilLayers, fieldMd5: string) => {
  const fieldLayers = data?.features?.filter(feature => feature.id === fieldMd5);
  let soilLayers = null;

  if (fieldLayers?.length) {
    soilLayers = {...data, features: fieldLayers};
  }

  return soilLayers;
};
