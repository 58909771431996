import {useCallback, useMemo} from 'react';
import useSWR from 'swr';
import {useAppDispatch} from '_hooks';
import {currentYear} from '../enrollment/base/base';
import {updateMRVValues} from '../monitoring/module/thunks';
import type {FieldValuesRow, MRVPhaseType, MRVStageNormalized, MRVValueInput} from '../types';
import {createRow} from '../value-utils';
import {useMRVValuesRequestKey} from './use-mrv-values-request-key';

export const useMRVValues = (
  phaseType: MRVPhaseType,
  projectId: number,
  stage?: MRVStageNormalized
) => {
  const dispatch = useAppDispatch();
  const stageId = stage?.id;
  const entityType = stage?.entity_type;
  const stageEndYear = stage?.year_end || currentYear;

  // 1. Get data.
  // It can be empty if:
  //   a. the stage + field combination was never opened before
  //      and the default values (i.e. record year) were not being created
  //   b. the stage can have optis but optis values are not loaded yet
  const requestKey = useMRVValuesRequestKey(projectId, stageId);
  const {data, error, mutate} = useSWR<RowsPerEntity>(!stageId ? null : requestKey, {
    revalidateIfStale: false,
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });
  const loading = !data && !error;

  // 2. Populate empty values if needed.

  // ---
  // You can uncomment and use the following code
  // to populate the rows with default values,
  // when backend didn't populate them.
  // ---

  // const populateRows = usePopulateRows({
  //   phaseType,
  //   projectId,
  //   rowsPerEntity: data,
  //   stage,
  // });

  // // For the given stages backend will take care of the default values.
  // // It creates the values once OPTIS fails or returns nothing.
  // const shouldPopulateRows =
  //   // Populate the rows for 'farm' entity type on our side until Asit implements it on the backend https://regrow.atlassian.net/browse/FSB-12291
  //   stage?.entity_type === 'farm' ||
  //   stage?.entity_type === 'mob' ||
  //   (stage?.type_ !== MRVStageType.HistoricalCropRotation &&
  //     stage?.type_ !== MRVStageType.HistoricalTillage);

  // const entityValueRows = data && stage && shouldPopulateRows ? populateRows() : data;

  const entityValueRows = data;

  const valueRows = useMemo(
    () => (entityValueRows ? Object.values(entityValueRows).flat() : []),
    [entityValueRows]
  );

  /**
   * updateValues does 2 things:
   * 1. Send the update request to the server
   * 2. Update the values in the local cache straight away
   *
   * It doesn't wait for the server created values with ids,
   * So we locally operate with id-less values.
   * We didn't implement waiting for the values with ids just to save time and complexity.
   *
   * Consider expanding this function to be able to update the values:
   * 1. In mrv table cell (it's already supported)
   * 2. In bulk edit modal (it's not supported yet)
   * 3. In add row (it's not supported yet) -- this will need to wait for value ids
   * 4. In Assign practices stage (it's not supported yet)
   */
  const updateValues = useCallback(
    (entityId: number, rowIndex: number, values: MRVValueInput[]) => {
      mutate(
        async rowsPerEntity => {
          if (!rowsPerEntity || !entityType) return;

          // Do not await but return the data straight away.
          dispatch(updateMRVValues({update: {[entityId]: values}, projectId, entityType}));

          // Update local cache straight away.
          const rows = rowsPerEntity[entityId];
          const newRow = {...rows[rowIndex]};
          values.forEach(value => {
            newRow.values[value.attribute_id] = value;
          });
          const newRows = [...rows];
          newRows[rowIndex] = newRow;
          return {...rowsPerEntity, [entityId]: newRows};
        },
        {revalidate: false}
      );
    },
    [mutate, dispatch, projectId, entityType]
  );

  /**
   * Updates Assign Practices stage values.
   * Doesn't revalidate the data after the update. This is done manually and asynchronously.
   */
  const updateAssignPractices = useCallback(
    (entityId: number, values: MRVValueInput[]) => {
      return mutate(
        rowsPerEntity => {
          if (!rowsPerEntity || !entityType) {
            return;
          }

          const rows = rowsPerEntity[entityId] || [];
          const newRows = [
            ...rows,
            ...values.map(v =>
              createRow(entityType, entityId, v.row_id, stageEndYear, {[v.attribute_id]: v})
            ),
          ];
          return {...rowsPerEntity, [entityId]: newRows};
        },
        {revalidate: false}
      );
    },
    [mutate, entityType, stageEndYear]
  );

  return {
    entityValueRows: entityValueRows || {},
    valueRows,
    loading,
    error,
    updateAssignPractices,
    updateValues,
    revalidate: useCallback(() => mutate(), [mutate]),
  };
};

type EntityId = number;
export type RowsPerEntity = Record<EntityId, FieldValuesRow[]>;
