import type {Dispatch, Action} from 'redux';
import {t} from 'i18n-utils';
import type {ThunkAction} from 'redux-thunk';
import type {StateUserActivity} from './types';
import {ActionType} from './types';
import {UserActivityApi} from '_api';
import {showNotification} from 'components/notification/notification';
import {reportError} from '../../error-boundary';
import {AsyncStatusType, setRequestStatus, Status} from 'modules/helpers';

export const loadUserActivityList =
  (): ThunkAction<void, StateUserActivity, null, Action> => (dispatch: Dispatch<any>) => {
    dispatch(setRequestStatus(AsyncStatusType.usersActivity, Status.Pending));
    UserActivityApi.getUserActivityList()
      .then(({data}) => {
        const {summary, users} = data.result;
        dispatch({
          type: ActionType.SET_USER_ACTIVITY,
          payload: {summary, list: users},
        });
        dispatch(setRequestStatus(AsyncStatusType.usersActivity, Status.Done));
      })
      .catch(err => {
        dispatch(setRequestStatus(AsyncStatusType.usersActivity, Status.Done));
        reportError(`loadUserActivityList() err= ${err}`);
        showNotification({
          title: t({id: 'note.error', defaultMessage: 'Error'}),
          message: t({
            id: 'userActivityListErr',
            defaultMessage: "Couldn't load user activity list!",
          }),
          type: 'error',
        });
      });
  };

export const updateSummary =
  (ids: number[]): ThunkAction<void, StateUserActivity, null, Action> =>
  (dispatch: Dispatch<any>) => {
    UserActivityApi.getSummary(ids)
      .then(({data}) => {
        const {summary} = data.result;
        dispatch({
          type: ActionType.UPDATE_SUMMARY_USER_ACTIVITY,
          summary: {
            ...summary,
            total_users: ids.length,
          },
        });
      })
      .catch(err => {
        reportError(`updateSummary() err= ${err}`);
        showNotification({
          title: t({id: 'note.error', defaultMessage: 'Error'}),
          message: t({
            id: 'userActivitySummaryErr',
            defaultMessage: "Couldn't load summary for user activities!",
          }),
          type: 'error',
        });
      });
  };
