import type {SIScenario} from 'containers/si/types';
import {SIScenarioKPIs} from 'containers/si/types';
import type {SIAreaUnits} from 'modules/sustainability-insights/types';
import type {Measurement} from 'containers/login/types';
import {toFixedFloat} from '_utils/number-formatters';
import {
  KG_PER_M2_TO_TONNES_PER_ACRE,
  KG_PER_M2_TO_TONNES_PER_HECTARE,
} from 'containers/si/constants';
import {isDefined} from '_utils/typeGuards';
import type {EmissionsFactorDetailsKey} from 'containers/si/api/apiTypes';

export const calculatePercentChange = ({
  scenario,
  baseline,
}: {
  scenario: number;
  baseline: number;
}) => {
  if (baseline === 0) {
    return 0;
  }
  return ((scenario - baseline) / baseline) * 100;
};

// This function is used to calculate the percentage change when the baseline starts at a negative number for SIScenarioKPIs.net_ghg
export const calculatePercentNegativeChange = ({
  scenario,
  baseline,
}: {
  scenario: number;
  baseline: number;
}) => {
  if (baseline === 0) {
    return 0;
  }
  return ((baseline - scenario) / baseline) * 100;
};

export const extractScenarioValues = ({
  scenarios,
  units,
  measurement,
}: {
  scenarios: SIScenario[];
  units: SIAreaUnits;
  measurement: Measurement;
}) =>
  scenarios.flatMap(scenario =>
    Object.values(SIScenarioKPIs).map(value => {
      const calculatedValue = calculateKPIValue({
        scenario,
        kpi: value,
        units,
        measurement,
        round: false,
      }).value;

      return isFinite(calculatedValue) ? calculatedValue : 0;
    })
  );

/**
 * Calculates the 'highest' and 'lowest' values for the KPIs in the scenario.
 *
 * @param {SIScenario[]} scenarios - The entire SIScenario
 * @param {SIAreaUnits} units - Perform the calculation in 'pct' or 'num'
 * @param {Measurement} measurement - If value should be calculated with 'ha' or 'ac'
 * @returns {{lowest: number, highest: number}} - Returns an object with {lowest: The lowest value of the KPIs, highest: The highest value of the KPIs}
 *
 * @example
 * // returns {lowest: 0, highest: 100}
 * calculateScenarioRange({scenarios, units: 'pct', measurement: 'ha'})
 *
 */
export const calculateScenarioRange = ({
  scenarios,
  units,
  measurement,
}: {
  scenarios: SIScenario[];
  units: SIAreaUnits;
  measurement: Measurement;
}) => {
  const extractedValues = extractScenarioValues({scenarios, units, measurement});
  const lowest = Math.min(0, ...extractedValues);
  const highest = Math.max(0, ...extractedValues);

  return {lowest, highest};
};

/**
 * A single function used to calculate all the KPIs. The result can be returned in 'pct' or 'num' units.
 * If 'num' is selected the result can be returned in 't/ha' or 't/ac' units.
 * If 'round = true' the result will be rounded to the number of decimals specified in 'decimals'
 *
 * @param {SIScenario} scenario - The entire SIScenario
 * @param {SIScenarioKPIs} kpi - The KPI to be calculated
 * @param {SIAreaUnits} units - Return the calculation in 'pct' or 'num'
 * @param {Measurement} measurement - If unit is 'num' return 't/ha' or 't/ac'
 * @param {boolean} round - Should the result be rounded
 * @param {number} decimals - If round = true, the number of decimals the result should be rounded to
 * @param {number} precision - Returns the value with "precision" digits.
 * @returns {{value: number, unit: string}} - Returns an object with {value: The calculated KPI value, unit: '%' | 't/ha' | 't/ac'}
 *
 * @example
 * // returns {value: 10, unit: '%'}
 * calculateKPIValue({scenario, kpi: SIScenarioKPIs.ef_reductions, units: 'pct'})
 *
 * @example
 * // returns {value: 167.435756467453, unit: 't/ha'}
 * calculateKPIValue({scenario, kpi: SIScenarioKPIs.ghg_reductions, units: 'num', measurement: 'ha', round: false})
 *
 * @example
 * // returns {value: 167.4, unit: 't/ha'}
 * calculateKPIValue({scenario, kpi: SIScenarioKPIs.ghg_reductions, units: 'num', measurement: 'ha', round: true, decimals: 1})
 */
export const calculateKPIValue = ({
  scenario,
  kpi,
  units,
  measurement,
  round = true,
  decimals = 2,
  precision,
}: {
  scenario: SIScenario;
  kpi: SIScenarioKPIs;
  units: SIAreaUnits;
  measurement: Measurement;
  round?: boolean;
  decimals?: number;
  precision?: number;
}) => {
  let calculatedScenario = 0;
  let baselineValue = 0;
  let scenarioValue = 0;

  switch (kpi) {
    case SIScenarioKPIs.ef_reductions:
      // only run this code if scenario is typeof SIScenarioAP
      if (scenario.tag === 'abatement_potential') {
        baselineValue = Object.values(scenario.baseline?.emission_factors).reduce(
          (a, b) => a + b,
          0
        );
        scenarioValue = Object.values(scenario.simulation?.emission_factors).reduce(
          (a, b) => a + b,
          0
        );
      }
      break;

    case SIScenarioKPIs.ghg_reductions:
      baselineValue = scenario.baseline.ghg_kg_per_m2;
      scenarioValue = scenario.simulation.ghg_kg_per_m2;
      break;

    case SIScenarioKPIs.soc_removals:
      baselineValue = scenario.baseline.soc_kg_per_m2;
      scenarioValue = scenario.simulation.soc_kg_per_m2;
      break;

    case SIScenarioKPIs.net_ghg:
      baselineValue = scenario.baseline.ghg_kg_per_m2 - scenario.baseline.soc_kg_per_m2;
      scenarioValue = scenario.simulation.ghg_kg_per_m2 - scenario.simulation.soc_kg_per_m2;
      break;
  }

  if (units === 'num') {
    calculatedScenario = scenarioValue - baselineValue;
  } else if (units === 'pct') {
    if (kpi === SIScenarioKPIs.net_ghg && baselineValue < 0) {
      // If baseline for Net GHG is < 0, swap the scenario and baseline in the calculatePercentChange function
      calculatedScenario = calculatePercentNegativeChange({
        scenario: scenarioValue,
        baseline: baselineValue,
      });
    } else {
      calculatedScenario = calculatePercentChange({
        scenario: scenarioValue,
        baseline: baselineValue,
      });
    }
  }

  // Convert
  // 1 kg_per_m2 === 10 Tonnes Per Hectare (t/ha)
  // 1 kg_per_m2 === 4.046856422400006 Tonnes Per Acre (t/ac)
  let returnedUnitString = '';

  if (units === 'num') {
    if (measurement === 'ha')
      calculatedScenario = calculatedScenario * KG_PER_M2_TO_TONNES_PER_HECTARE;
    if (measurement === 'ac')
      calculatedScenario = calculatedScenario * KG_PER_M2_TO_TONNES_PER_ACRE;
  } else {
    returnedUnitString = '%';
  }

  if (precision) {
    calculatedScenario = Number(calculatedScenario.toPrecision(precision));
  } else if (round) {
    calculatedScenario = toFixedFloat(calculatedScenario, decimals);
  }

  return {value: calculatedScenario, unit: returnedUnitString} as {
    value: number;
    unit: string;
  };
};

const convert = (value: number, measurement: Measurement) =>
  value * (measurement === 'ha' ? KG_PER_M2_TO_TONNES_PER_HECTARE : KG_PER_M2_TO_TONNES_PER_ACRE);

export const getTooltipValuesByKPI = (
  scenario: SIScenario,
  kpi: SIScenarioKPIs | EmissionsFactorDetailsKey,
  round: boolean = true,
  precision: number = 2,
  measurement: Measurement
) => {
  let book: number | undefined = undefined;
  let baseline = 0;
  let withInterventions = 0;

  let difference = 0;
  switch (kpi) {
    case SIScenarioKPIs.ghg_reductions:
      baseline = convert(scenario.baseline.ghg_kg_per_m2, measurement);
      withInterventions = convert(scenario.simulation.ghg_kg_per_m2, measurement);
      break;
    case SIScenarioKPIs.soc_removals:
      baseline = convert(scenario.baseline.soc_kg_per_m2, measurement);
      withInterventions = convert(scenario.simulation.soc_kg_per_m2, measurement);
      break;
    case SIScenarioKPIs.net_ghg:
      baseline = convert(
        scenario.baseline.ghg_kg_per_m2 - scenario.baseline.soc_kg_per_m2,
        measurement
      );
      withInterventions = convert(
        scenario.simulation.ghg_kg_per_m2 - scenario.simulation.soc_kg_per_m2,
        measurement
      );
      break;
    case 'fert_emissions_factor':
    case 'ghg_emissions_factor':
    case 'trace_metals':
    case 'crop_residues':
    case 'seeds_seedlings_orchards':
    case 'irrigation':
    case 'luluc':
    case 'plant_protection':
    case 'other':
      // only run this code if scenario is typeof SIScenarioAP
      if (scenario.tag === 'abatement_potential') {
        // EF values are not converted by area
        book = scenario.book.emission_factors[kpi];
        baseline = scenario.baseline.emission_factors[kpi] ?? 0;
        withInterventions = scenario.simulation.emission_factors[kpi] ?? 0;
      }
  }

  difference = withInterventions - baseline;
  const percentChange = toFixedFloat(
    calculatePercentChange({scenario: withInterventions, baseline}),
    0
  );

  if (round) {
    book = isDefined(book) ? Number(book.toPrecision(precision)) : undefined;
    baseline = Number(baseline.toPrecision(precision));
    withInterventions = Number(withInterventions.toPrecision(precision));
    difference = Number(difference.toPrecision(precision));
  }
  return {baseline, withInterventions, difference, percentChange, book};
};
