import {Flex, Text} from 'components';
import type {ComponentType} from 'react';
import React from 'react';
import {AdopterTypeFilter} from './adopter-type-filter';
import './grower-demographics-filters.scss';
import {OperationSizeFilter} from './operation-size-filter';
import {FieldOwnershipFilter} from './field-ownership-filter';

type Props = {};

export const GrowerDemographicsFilters: ComponentType<Props> = () => {
  return (
    <div>
      <Text>Grower demographics</Text>
      <Flex>
        <Text variant={'medium'} secondary fullWidth>
          Field level filters
        </Text>
        <AdopterTypeFilter adopterLevel={'fieldAdopterType'} />
        <FieldOwnershipFilter />
      </Flex>
      <Flex>
        <Text variant={'medium'} secondary fullWidth>
          Operation level filters
        </Text>
        <AdopterTypeFilter adopterLevel={'operationAdopterType'} />
        <OperationSizeFilter />
      </Flex>
    </div>
  );
};
