import {Flex, FluroButton} from 'components';
import {ENROLLMENT_PROJECT_FORM, MONITORING_PROJECT_FORM, MRV_HOME} from 'containers/mrv/routes';
import {applyPathParams} from '_utils/pure-utils';
import type {ComponentType} from 'react';
import React, {useContext, useMemo, useCallback} from 'react';
import {Link, useLocation, useHistory} from 'react-router-dom';
import {useAppSelector} from '_hooks';
import {applyUrlParams} from '_hooks/use-url-params';
import {useMonitoringUrlParams} from '../../hooks';
import {selectEnabledPhaseStages} from '../../module/selectors';
import {FinishPhaseDialogContainer} from '../finish-phase-dialog-container';
import {navigationHighlightContext} from '../navigation-highlight-context';
import {MRVPhaseType} from 'containers/mrv/types';
import {useIsStageDisabled} from '../../hooks/use-is-stage-disabled';
import {isCustomStageView, getStageName} from 'containers/mrv/base';
import {t} from 'i18n-utils';
import {MRVFMSSyncBtn} from '../../fms-sync/fms-sync-button';

export const MRVBottomBar: ComponentType<{phaseType: MRVPhaseType; isReadOnly: boolean}> = ({
  phaseType,
  isReadOnly,
}) => {
  const {highlightUntouchedCells} = useContext(navigationHighlightContext);
  const {projectId, stageId} = useMonitoringUrlParams();
  const stagesList = useAppSelector(s => selectEnabledPhaseStages(s, phaseType));
  const location = useLocation();
  const history = useHistory();

  const completion = useAppSelector(s => s.monitoring.completion);

  const {prevStage, nextStage, currentStage} = useMemo(() => {
    const stageIndex = stagesList.findIndex(s => s.id === stageId);

    return {
      prevStage: stageIndex !== 0 && stagesList[stageIndex - 1],
      nextStage: stageIndex !== stagesList.length - 1 && stagesList[stageIndex + 1],
      currentStage: stagesList[stageIndex],
    };
  }, [stageId, stagesList]);

  const isStageDisabled = useIsStageDisabled(phaseType);

  const path =
    phaseType === MRVPhaseType.Monitoring ? MONITORING_PROJECT_FORM : ENROLLMENT_PROJECT_FORM;

  const backButtonLocation =
    prevStage && !isReadOnly
      ? {
          pathname: applyPathParams(path, {projectId}),
          search: applyUrlParams(location.search, {stageId: prevStage.id}),
        }
      : {
          pathname: applyPathParams(MRV_HOME, {projectId}),
        };

  const allIncompletedStages = useMemo(() => {
    return stagesList
      .map(stage => {
        if (!completion[stage.id]?.is_completed) {
          return Number(stage.id);
        }
        return null;
      })
      .filter(res => res !== null);
  }, [completion, stagesList]);

  const handleHighlightIncompleteCells = useCallback(() => {
    if (allIncompletedStages.includes(stageId)) {
      return highlightUntouchedCells();
    } else {
      history.push({
        pathname: applyPathParams(path, {projectId}),
        search: applyUrlParams(location.search, {stageId: allIncompletedStages[0]}),
      });
    }
  }, [
    allIncompletedStages,
    highlightUntouchedCells,
    history,
    location.search,
    path,
    projectId,
    stageId,
  ]);

  const hasIncompleteFields = useMemo(
    () =>
      !isReadOnly &&
      (allIncompletedStages.includes(stageId) || (!nextStage && allIncompletedStages.length)),
    [allIncompletedStages, isReadOnly, nextStage, stageId]
  );

  return (
    <div className="mrv-table__bottom-bar">
      <Flex alignItems="center" justifyContent="space-between" gap="1em">
        <FluroButton flat component={Link} to={backButtonLocation}>
          {t({id: 'BtnLabel.Back', defaultMessage: 'Back'})}
        </FluroButton>

        <Flex gap="10px">
          <MRVFMSSyncBtn phaseType={phaseType} />
          {!!hasIncompleteFields && !isCustomStageView(currentStage.type_) && (
            <FluroButton primary raised skin="warning" onClick={handleHighlightIncompleteCells}>
              {t({id: 'BtnLabel.IncompleteCells', defaultMessage: 'Incomplete cells'})}
            </FluroButton>
          )}
          {!isReadOnly &&
            (nextStage ? (
              <FluroButton
                primary
                raised
                component={Link}
                disabled={isStageDisabled(nextStage)}
                to={{
                  pathname: applyPathParams(path, {projectId}),
                  search: applyUrlParams(location.search, {stageId: nextStage.id}),
                }}
                onClick={e => {
                  // Our react md button + link combination doesn't respect the disabled prop,
                  // So we need to prevent the default action if the button is disabled.
                  if (isStageDisabled(nextStage)) e.preventDefault();
                }}
              >
                {t({id: 'BtnLabel.Next', defaultMessage: 'Next'})}: {getStageName(nextStage)}
              </FluroButton>
            ) : (
              <FinishPhaseDialogContainer
                phaseType={phaseType}
                disabled={!!allIncompletedStages.length}
              />
            ))}
        </Flex>
      </Flex>
    </div>
  );
};
