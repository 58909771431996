import React, {useCallback, useMemo} from 'react';
import {CropAvatar} from 'components/crop-avatar';
import type {UpdatedDataSet} from './helpers';
import cn from 'classnames';
import {updateBenchmarkRecord} from '../reducer';
import type {AppStore} from 'reducers';
import type {CSGViewModel} from '../types';
import {sortByKey} from '_utils/sorters';
import {toFixedFloatUnsafe} from '_utils/number-formatters';
import {getCropLabelById} from '_utils/pure-utils';
import {CPFilterType} from '_reducers/crop-performance-filter/field-filter-types';
import {toggleTooltip} from 'modules/global/actions';
import {useAppDispatch, useAppSelector} from '_hooks';
import {selectCropTypes} from 'modules/global/selectors';

type Props = {
  data: {labels: string[]; datasets: UpdatedDataSet[]};
  filteredRecords: CSGViewModel[];
  popupVisible: boolean;
  togglePopUpVisibility: (value: boolean) => void;
};

const RankingView = ({filteredRecords}: Props) => {
  const dispatch = useAppDispatch();
  const biomassOverTimeFilter = useAppSelector(
    (store: AppStore) => store.cropPerformanceFilter.filters[CPFilterType.BIOMASS_OVER_TIME]
  );
  const cropPerformanceBenchmarkRecords = useAppSelector(
    (store: AppStore) => store.cropPerformance.benchmark.recordsToProcess
  );
  const cropTypes = useAppSelector(selectCropTypes);

  const {minValue, avgValue, maxValue} = useMemo(() => {
    // retrieves the max value of dataset to limit the yAxis to it
    let maxValue = filteredRecords[0].cumulativeSmoothSatelliteNdvi || 1;
    let minValue = filteredRecords[0].cumulativeSmoothSatelliteNdvi || 0;

    filteredRecords.forEach(({cumulativeSmoothSatelliteNdvi}) => {
      maxValue =
        cumulativeSmoothSatelliteNdvi > maxValue ? cumulativeSmoothSatelliteNdvi : maxValue;
      minValue =
        cumulativeSmoothSatelliteNdvi < minValue ? cumulativeSmoothSatelliteNdvi : minValue;
    });

    return {
      maxValue: toFixedFloatUnsafe(maxValue, 0),
      minValue: toFixedFloatUnsafe(minValue, 0),
      avgValue: toFixedFloatUnsafe((maxValue + minValue) / 2, 0),
    };
  }, [filteredRecords]);

  const colors = useMemo(() => {
    const colors: {[seasonId: string]: string} = {};
    biomassOverTimeFilter.forEach(({value, color}) => {
      colors[value] = color;
    });
    return colors;
  }, [biomassOverTimeFilter]);

  const preparedRecords = useMemo(() => {
    const sortedRecords: CSGViewModel[] = sortByKey(
      filteredRecords,
      'cumulativeSmoothSatelliteNdvi'
    );

    return sortedRecords.map(record => {
      return {
        ...record,
        backgroundColor: colors[record.seasonId] || '#c2c2c2',
        width:
          toFixedFloatUnsafe(
            (toFixedFloatUnsafe(record.cumulativeSmoothSatelliteNdvi, 0) / maxValue) * 100,
            0
          ) || 1,
        cumulativeSmoothSatelliteNdvi: toFixedFloatUnsafe(record.cumulativeSmoothSatelliteNdvi, 0),
      };
    });
  }, [filteredRecords]);

  const onUpdateRecordsToProcess = useCallback(
    (seasonId: number) => {
      dispatch(updateBenchmarkRecord(seasonId, !cropPerformanceBenchmarkRecords[seasonId]));
    },
    [cropPerformanceBenchmarkRecords]
  );

  const showTooltip = useCallback(
    (id: number, data: CSGViewModel) => {
      dispatch(
        toggleTooltip({
          id,
          place: 'top',
          tooltipClassName: 'line-chart-tooltip',
          content: `${getCropLabelById(cropTypes, data.cropType)} ${
            data.cropVariety ? `[${data.cropVariety}]` : ''
          }${data.seasonName ? ', ' + data.seasonName : ''}
          - ${data.cumulativeSmoothSatelliteNdvi}`,
          width: 'auto',
        })
      );
    },
    [dispatch, toggleTooltip]
  );

  return (
    <div className={'ranking-view'}>
      <ul className={cn('legend-list')}>
        {preparedRecords.map(data => {
          return (
            <React.Fragment key={data.seasonId}>
              <RankingItem
                data={data}
                onUpdateRecordsToProcess={onUpdateRecordsToProcess}
                toggleTooltip={() => showTooltip(data.seasonId, data)}
              />
            </React.Fragment>
          );
        })}
      </ul>
      {preparedRecords.length ? (
        <div className="range-scale">
          <span>{minValue}</span>
          <span>{avgValue}</span>
          <span>{maxValue}</span>
        </div>
      ) : null}
    </div>
  );
};

type RankingItemProps = {
  data: CSGViewModel & {backgroundColor: string; width: string};
  onUpdateRecordsToProcess: (seasonId: number) => void;
  toggleTooltip: () => void;
};

const RankingItem = ({data, onUpdateRecordsToProcess, toggleTooltip}: RankingItemProps) => {
  const {seasonId, cropType, fieldName, backgroundColor, width} = data;
  return (
    <li
      key={seasonId}
      onClick={() => onUpdateRecordsToProcess(seasonId)}
      className={cn({
        'legend-list-item': true,
        'ranking-item': true,
      })}
    >
      <span className={'field-season'}>
        <CropAvatar cropType={cropType} />
      </span>
      <span className={'field-name'}>
        {fieldName}
        <div
          data-tip=""
          data-for={seasonId}
          onMouseEnter={toggleTooltip}
          style={{width: `${width}%`}}
          className={cn('bar-container')}
        >
          <div style={{backgroundColor}} className={'bar-line'} />
          <span className={'bar-value'}>{data.cumulativeSmoothSatelliteNdvi}</span>
        </div>
      </span>
    </li>
  );
};

export default RankingView;
