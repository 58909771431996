import React, {useState, useEffect} from 'react';
import {FluroButton, FluroSelect} from 'components';
import {useAppDispatch, useAppSelector} from '_hooks';
import {CircleIcon, PartFieldIcon, ShapeIcon, UploadIcon} from 'containers/map/icons';
import {setInteractionType, setSelectLayerID} from './../module/reducer';
import {selectInteractionType, selectSelectLayerID} from './../module/selectors';
import type {SIMapInteractionType, SIMapLayerType, SIMapLayerParams} from 'containers/si/types';
import {mapboxTilesetParams} from 'containers/si/types';
import './si-map.scss';

interface MapInputToolsProps {
  mapRef: React.RefObject<any>;
  canAddNewFeature?: boolean;
  handleAddNewFeature: (featureType: SIMapInteractionType) => void;
}

const boundaryRowText: Record<SIMapInteractionType, string> = {
  select: 'Boundary type',
  circle: 'Click and drag to draw a circle',
  polygon: 'Click to add points, double click to finish',
  upload: 'Upload boundary files',
  none: '',
};

export const MapInputTools = ({canAddNewFeature, handleAddNewFeature}: MapInputToolsProps) => {
  const dispatch = useAppDispatch();
  const [selectOptions, setSelectOptions] = useState<{value: SIMapLayerType; label: string}[]>([]);

  const handleInteractionTypeChange = (interaction: SIMapInteractionType) => {
    dispatch(setInteractionType(interaction));
  };

  const interactionType = useAppSelector(selectInteractionType);
  const selectedLayerID = useAppSelector(selectSelectLayerID);

  useEffect(() => {
    // generate FlureSelect options from mapboxTilesetParams
    const options = Object.entries(mapboxTilesetParams).map(value => {
      const [layerName, layerParams] = value as [SIMapLayerType, SIMapLayerParams];

      return {
        value: layerName,
        label: layerParams.label,
      };
    });
    setSelectOptions(options);
  }, []);

  const handleSelectLayerChange = (newValue: SIMapLayerType) => {
    dispatch(setSelectLayerID(newValue));
  };

  return (
    <div className="map-input-tools-container">
      <div className="add-areas-row">
        <div className="map-input-text">Add areas</div>
        <div className="draw-buttons-container">
          <FluroButton
            raised
            primary={interactionType === 'select'}
            secondary={interactionType !== 'select'}
            onClick={() => handleInteractionTypeChange('select')}
            value="select"
            className="draw-button"
            iconEl={<PartFieldIcon fill="inherit" />}
          >
            Select on Map
          </FluroButton>
          <FluroButton
            raised
            primary={interactionType === 'circle'}
            secondary={interactionType !== 'circle'}
            onClick={() => handleInteractionTypeChange('circle')}
            value="circle"
            className="draw-button"
            iconEl={<CircleIcon />}
          >
            Draw Circle
          </FluroButton>
          <FluroButton
            raised
            primary={interactionType === 'polygon'}
            secondary={interactionType !== 'polygon'}
            onClick={() => handleInteractionTypeChange('polygon')}
            value="polygon"
            className="draw-button"
            iconEl={<ShapeIcon />}
          >
            Draw Polygon
          </FluroButton>
          <FluroButton
            raised
            primary={interactionType === 'upload'}
            secondary={interactionType !== 'upload'}
            onClick={() => handleInteractionTypeChange('upload')}
            value="polygon"
            className="draw-button"
            iconEl={<UploadIcon fill="currentColor" />}
          >
            Upload Files
          </FluroButton>
        </div>
      </div>
      <div className="boundary-row">
        <div className="map-input-text">{boundaryRowText[interactionType]}</div>
        <div className="boundary-type-container">
          {interactionType === 'select' && (
            <FluroSelect
              value={selectedLayerID}
              onChange={layer => handleSelectLayerChange(layer as SIMapLayerType)}
              options={selectOptions}
              className="boundary-drop-down"
            />
          )}
          {interactionType === 'circle' && canAddNewFeature && (
            <FluroButton raised primary onClick={() => handleAddNewFeature('circle')}>
              Add Another Circle
            </FluroButton>
          )}
          {interactionType === 'polygon' && canAddNewFeature && (
            <FluroButton raised primary onClick={() => handleAddNewFeature('polygon')}>
              Add Another Polygon
            </FluroButton>
          )}
        </div>
      </div>
    </div>
  );
};
