import {
  selectAssignPracticeAttribute,
  selectExistingProjectValuesByAttributeId,
} from 'containers/mrv/monitoring/module/enrollment.selectors';
import {selectProjectFieldsList} from 'containers/mrv/monitoring/module/selectors';
import type {Field} from 'containers/map/types';
import * as React from 'react';
import {useAppDispatch, useAppSelector} from '_hooks';
import {getShapeCoordinates} from '_utils/geometry';
import './assign-practices-popup.scss';
import {useMonitoringUrlParams} from 'containers/mrv/monitoring/hooks';
import {FluroMapPopup} from 'components';
import type {ComponentType} from 'react';
import {highlightField} from 'containers/map/actions';
import {selectFieldGeometries, selectFieldsByFarmId} from 'containers/map/reducer/selectors';
import {PracticePreviewCircles} from 'components/practice-select/practice-preview-circles';
import {Text} from 'components/text/text';
import {getEntityId} from 'containers/mrv/value-utils';

type Props = {
  fieldId: number;
};

export const AssignPracticesPopup: ComponentType<Props> = ({fieldId}) => {
  const dispatch = useAppDispatch();
  const fieldsByFarmId = useAppSelector(selectFieldsByFarmId);
  const fieldGeometries = useAppSelector(selectFieldGeometries);

  const {projectId, isEnrollment} = useMonitoringUrlParams();

  const projectFields = useAppSelector(s => selectProjectFieldsList(s, projectId));

  const attr = useAppSelector(selectAssignPracticeAttribute);

  let field: Field | undefined;
  for (const farmId in fieldsByFarmId) {
    const id = Number(farmId);
    field = Object.values(fieldsByFarmId[id]).find(f => f.ID === fieldId);
    if (field) break;
  }

  const mrvField = projectFields.find(f => f.fs_field_id === field?.FieldID);

  const fieldPracticeValues = useAppSelector(s =>
    attr?.id ? selectExistingProjectValuesByAttributeId(s, attr?.id) : []
  )
    .filter(v => getEntityId(v) === mrvField?.id)
    .map(v => v.value);

  if (!field || !mrvField) {
    return null;
  }

  const geometry = fieldGeometries[field.MD5];
  if (!geometry) {
    return null;
  }
  if (!isEnrollment) {
    return null;
  }

  const [lng = 0, lat = 0] = getShapeCoordinates(geometry);
  const position = {lat, lng};

  return (
    <FluroMapPopup
      position={position}
      autoPan={false}
      className={'assign-practices-popup'}
      onClose={() => dispatch(highlightField(undefined))}
    >
      <div className="practices-popup-content">
        <Text nowrap className="margin-right-10 margin-bottom-0" elementType="h2">
          {field.Name}
        </Text>
        <PracticePreviewCircles id={field.ID + 'practices'} value={fieldPracticeValues} />
      </div>
    </FluroMapPopup>
  );
};
