import type {ConflictTypeCombo} from 'containers/mrv/admin/dashboards/practice-verification-dashboard/filters/conflict-type/utils';
import type {MultiPolygon} from 'geojson';
import type {DateStringWithNoTZ} from 'types';

export enum PaymentFor {
  TONS_CO2E = 'Tons CO2e',
  HECTARES = 'hectares',
  FIELD_AREA = 'FIELD_AREA',
}

export type DNDCResultsResponse = {
  line_items_summary: {
    total_area: number;
    total_carbon_sequestered: number;
    total_fields_number: number;
    total_payment: number;
  };
  line_items: {
    contract_id: number;
    field_area: number;
    field_id: number;
    id: number;
    value: number;
    quantity: number;
    units: PaymentFor;
    note: {
      farm_name: string;
      field_name: string;
    };
  }[];
};

export type GetProjectParams = {
  /**
   * include geometry and field name
   */
  include_core_attributes?: boolean;
  include_user_sync_info?: boolean;
  include_dndc_status?: boolean;
  include_ofs_status?: boolean;
};

export type ProgramConflictsResponse = {
  conflicts: Conflict[];
  fields_with_geometry: FieldWithGeometry[];
  stats: Stats;
  filtered_stats: Stats;
  percent_processed: number;
  filter_options: {
    all_statuses: ConflictStatus[];
    all_producers: {
      /**
       * producerName
       */
      label: string;
      /**
       * projectId
       */
      value: string;
    }[];
    all_conflict_types: ConflictType[];
    all_states: string[];
  };
};

export type Conflict = {
  id: number;
  conflict_type: ConflictType;
  record_year: number;
  producer_name: string;
  /**
   * projectId
   */
  producer_id: number;
  field_id: number;
  field_name: string;
  field_area: number;
  field_est_payment: number;
  field_est_outcome: number;
  baseline_value: string;
  baseline_value_id: number;
  producer_value: string | null;
  producer_value_id: number | null;
  regrow_value: string;
  conflict_status: ConflictStatus;
  status_updated_at: DateStringWithNoTZ | null;
  state: string;
  user: string;
  user_id: number;
  email: string;
};

export type FieldWithGeometry = {
  field_id: number;
  geometry: MultiPolygon;
  statuses: ConflictStatus[];
};

export enum MeasurementFilterKey {
  HA = 'KmlFiles.totalAreaHa',
  AC = 'KmlFiles.totalAreaAc',
}
export type MinMax = {
  min?: number;
  max?: number;
};

export type ProgramConflictsFilter = {
  'MrvConflictResolutionHistory.status'?: string;
  'MrvConflicts.producerName'?: string;
  'MrvConflicts.conflictType'?: ConflictTypeCombo;
  'Regions.name'?: string;
  'MrvProjectContractLineItems.quantity'?: MinMax;
  'MrvProjectContractLineItems.value'?: MinMax;
  'KmlFiles.area'?: MinMax;
};

export type ConflictType = 'WINTER_CROP' | 'TILLAGE';

export enum ConflictStatus {
  agreeWithRegrow = 'agreed_with_regrow',
  agreeWithProducer = 'agreed_with_producer',
  unresolved = 'unresolved',
}

export type Stats = {
  total_fields: number;
  total_producers: number;
  total_area: number;
  total_credits: number;
};

export type OptisAttributeMappingLabel =
  | 'curr_crop_id'
  | 'cover_crop'
  | 'spring_till_class'
  | 'fall_till_class';

export type OptisAttributeMapping = {
  label: OptisAttributeMappingLabel;
  attribute_id: number;
  order: number; // Not used anywhere. Just always use 0.
};

export type OptisAttributeMappingPayload = {
  program_id?: number;
  label_attribute_list: OptisAttributeMapping[];
};

export type HistoryRow = Pick<Conflict, 'conflict_status' | 'status_updated_at' | 'producer_value'>;

export type ProjectConflictFile = {
  id: number;
  file_name: string;
  file_size: number;
  upload_url?: string;
  download_url: string;
  usage:
    | 'customer'
    // Normalized Difference Vegetation Index graph
    | 'ndvi'
    // Normalized Difference Tillage Index graph
    | 'ndti'
    // False Color Image
    | 'fci'
    // Red Green Blue Image
    | 'rgb';
};
