import {useGreenToRedColors} from './useGreenToRedColors';
import {useAppSelector} from '_hooks';
import {selectAllReportDashboardData, selectReportDataUnits} from 'containers/si/module/selectors';
import {calculateScenarioRange} from '../utils/calculate-scenario';
import {selectMeasurement} from 'containers/login/login-selectors';
import React from 'react';
import {lerpColor} from '_utils/colors';

export const useGetChartRedToGreenColorRange = () => {
  const allDashboardData = useAppSelector(selectAllReportDashboardData);
  const units = useAppSelector(selectReportDataUnits);
  const measurement = useAppSelector(selectMeasurement);
  const {highestColorNegative, lowestColorNegative, lowestColorPositive, highestColorPositive} =
    useGreenToRedColors();

  // scale of 3 colors with the mid color at 0 value and the hightest colors above zero and lowest below zero
  // We want the scale on both sides of 0 to be the same
  // Calculate the highest and lowest values for ALL scenarios, then use the absolute value of the highest and lowest to determine the range
  const {highest, lowest} = React.useMemo(
    () => calculateScenarioRange({scenarios: allDashboardData, units, measurement}),
    [allDashboardData, units, measurement]
  );
  const maxValue = Math.max(Math.abs(highest), Math.abs(lowest));

  return ({value, lowValueIsBad = false}: {value: number; lowValueIsBad?: boolean}) => {
    const colorPercentage = Math.abs(value) / maxValue;

    if (value < 0) {
      return lowValueIsBad
        ? lerpColor(lowestColorPositive, highestColorPositive, colorPercentage)
        : lerpColor(lowestColorNegative, highestColorNegative, colorPercentage);
    }
    return lowValueIsBad
      ? lerpColor(lowestColorNegative, highestColorNegative, colorPercentage)
      : lerpColor(lowestColorPositive, highestColorPositive, colorPercentage);
  };
};
