import type {FluroSelectLiteItem} from 'components/fluro-select-lite/fluro-select-lite';
import type {
  MRVProgram,
  MRVProgramEntities,
  MRVProgramInput,
  MRVProgramResult,
} from 'containers/mrv/types';
import {MRVProgramUnits} from 'containers/mrv/types';
import {Locales} from 'i18n-utils';
import {normalize} from 'normalizr';
import {ProgramSchema} from './normalize-schemas';

export enum InputType {
  Date = 'date',
  Number = 'number',
  String = 'string',
  Boolean = 'boolean',
  Coordinates = 'coordinates',
}

export type MonitoringColumn = {
  name: string;
  title: string;
  type: InputType;
  options?: FluroSelectLiteItem[];
  measurement?: string;
};

export const defaultProgram: MRVProgramInput = {
  name: 'New program',
  description: 'description',
  version: 1,
  editable: true,
  crediting_year: 2021,
  owner_org: 1,
  assets: [],
  practice_changes: [],
  carbon_price: 0,
  custom_reg_inputs: [],
  self_enrol: false,
  locale: Locales.EnUS,
  units: MRVProgramUnits.IMPERIAL,
  is_optis_enabled: true,
};

export const tillageDepthOptions = ['0.5', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10'];
export const residueRemovedOptions = ['0', '<50%', '<50-95%', '95%', '100%'];
export const coverCropOptions = [
  'Cereal Rye',
  'Annual Ryegrass',
  'Oats',
  'Clover',
  'Turnips',
  'Radishes',
  'Other (winter killed)',
  'Other (non-winterkill)',
  'Winter wheat',
  'Triticale',
  'Pea',
  'Vetch',
  'Lentil',
  'Wheatgrass',
  'Buckwheat',
  'Sunn hemp',
];
export const terminationMethodOptions = [
  'Herbicide',
  'Harvested for grain',
  'Winterkill',
  'Mechanical',
];

export const normalizePrograms = (programs: MRVProgram[]) => {
  return normalize<MRVProgram, MRVProgramEntities, MRVProgramResult>(programs, [ProgramSchema]);
};
