// @ts-nocheck
import {CropTypesMatchingApi} from '_api';
import {t} from 'i18n-utils';
import {sortByKey} from '_utils/sorters';
import {genKey} from '_utils/pure-utils';
import {rowsPerPage} from 'containers/admin';
import {showNotification} from 'components/notification/notification';
import {AsyncStatusType, setRequestStatus, Status} from '../../../modules/helpers';

enum ActionType {
  FetchCropMatching = '@crop-types-matching/fetch',
  Sort = '@crop-types-matching/sort',
  AddRuleTList = '@crop-types-matching/rule/add',
  SetPagination = '@crop-types-matching/pagination/set',
}

export type CropMatchItem = {
  id: number;
  created_at: string;
  created_by: number;
  updated_at: string;
  wrong_crop_value: string;
  correct_crop_value: string;
};

type UnmatchedCropItem = {
  crop: string;
  seasons: number;
};

export enum MatchStatus {
  Matched = 'matched',
  NoMatch = 'no match',
}

export type CropMatchListItem = {
  id: string | number;
  status: MatchStatus;
  wrongCrop: string;
  correctCrop: string;
  seasonNumber: number;
};

export type CropTypesMatchingStore = {
  list: CropMatchListItem[];

  sortBy: string;
  desc: boolean;

  page: number;
  perPage: number;
  startIndex: number;
};

const initialState: CropTypesMatchingStore = {
  list: [],

  sortBy: 'status',
  desc: false,
  page: 1,
  perPage: rowsPerPage,
  startIndex: 0,
};

export const fetchCropMatching = () => async (dispatch: any) => {
  dispatch(setRequestStatus(AsyncStatusType.cropTypesMatching, Status.Pending));

  const result = await Promise.all([
    CropTypesMatchingApi.getMatchingRules(),
    CropTypesMatchingApi.getUnmatchedCrops(),
  ]);

  const matchedCropRules: CropMatchItem[] = result[0].data?.result || [];
  const notMatchedCrops: UnmatchedCropItem[] = result[1].data?.result || [];

  const notMatched: CropMatchListItem[] = [];

  notMatchedCrops.map(cropItem => {
    if (!matchedCropRules.some(item => item.wrong_crop_value === cropItem.crop)) {
      notMatched.push({
        id: `local-${genKey()}`,
        wrongCrop: cropItem.crop,
        correctCrop: '',
        seasonNumber: cropItem.seasons,
        status: MatchStatus.NoMatch,
      });
    }
  });

  const list = matchedCropRules.map(matchedItem => {
    const noRuleItem = notMatchedCrops.find(
      notMatchedItem => notMatchedItem.crop === matchedItem.wrong_crop_value
    );

    const item: CropMatchListItem = {
      id: matchedItem.id,
      wrongCrop: matchedItem.wrong_crop_value,
      correctCrop: matchedItem.correct_crop_value,
      seasonNumber: noRuleItem ? noRuleItem.seasons : 0,
      status: MatchStatus.Matched,
    };

    return item;
  });

  dispatch({type: ActionType.FetchCropMatching, list: [...list, ...notMatched]});
  dispatch(setRequestStatus(AsyncStatusType.cropTypesMatching, Status.Done));
};

export const setSort = (sortBy: string, desc: boolean) => ({
  type: ActionType.Sort,
  sortBy,
  desc,
});

export const addRuleToList = (id: string | number, data: CropMatchListItem) => ({
  type: ActionType.AddRuleTList,
  id,
  data,
});

export const updateRule =
  (id: any, wrongCrop: string, correctCrop: string) => async (dispatch: any) => {
    let response: any;

    if (typeof id === 'string') {
      response = await CropTypesMatchingApi.createRule({
        wrong_crop_value: wrongCrop,
        correct_crop_value: correctCrop,
      });
    } else {
      response = await CropTypesMatchingApi.updateRule({
        id,
        wrong_crop_value: wrongCrop,
        correct_crop_value: correctCrop,
      });
    }

    const data = response.data?.result || {};

    dispatch(
      addRuleToList(id, {
        id: data.id,
        status: MatchStatus.Matched,
        wrongCrop: data.wrong_crop_value,
        correctCrop: data.correct_crop_value,
        seasonNumber: 0,
      } as CropMatchListItem)
    );

    dispatch(applyRule(data.id as number));
  };

export const applyRule = (ruleId: number) => async () => {
  try {
    await CropTypesMatchingApi.applyRule(ruleId);

    showNotification({
      title: t({id: 'note.success', defaultMessage: 'Success'}),
      message: 'The rule applied',
      type: 'success',
    });
  } catch (e) {
    showNotification({
      title: t({id: 'note.warning', defaultMessage: 'Warning'}),
      message: e.message,
      type: 'warning',
    });
  }
};

export const setPagination = (page: number, perPage: number, startIndex: number) => ({
  type: ActionType.SetPagination,
  page,
  perPage,
  startIndex,
});

export const reducer = (state = initialState, action: any) => {
  switch (action.type) {
    case ActionType.SetPagination: {
      return {
        ...state,
        page: action.page,
        startIndex: action.startIndex,
        perPage: action.perPage,
      };
    }
    case ActionType.AddRuleTList: {
      return {
        ...state,
        list: state.list.map(rule => {
          if (rule.id === action.id) {
            return {
              ...rule,
              ...action.data,
            };
          }

          return rule;
        }),
      };
    }

    case ActionType.Sort: {
      const list = sortByKey(state.list, action.sortBy);

      return {
        ...state,
        sortBy: action.sortBy,
        desc: action.desc,
        list: action.desc ? list : list.reverse(),
      };
    }

    case ActionType.FetchCropMatching: {
      const list = sortByKey(action.list, state.sortBy);

      return {
        ...state,
        list: state.desc ? list : list.reverse(),
      };
    }

    default: {
      return state;
    }
  }
};
