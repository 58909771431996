import type {ComponentType} from 'react';
import React, {useEffect, useState} from 'react';
import MRVApi from '_api/mrv';
import colors from 'variables.scss';
import {LeafIcon} from 'containers/map/icons';
import type {MRVStageType} from 'containers/mrv/types';
import {Circle} from 'components/shapes/circle';
import {monitoringStageIcons} from 'containers/mrv/admin/base/base';

type Props = {
  iconUrl?: string;
  imagePlaceholder?: React.ReactElement;
  iconColor?: string;
  stageType?: MRVStageType;
};

export const MRVStageIconComponent: ComponentType<Props> = ({
  iconUrl,
  imagePlaceholder,
  iconColor,
  stageType,
}) => {
  const [imageSrc, setImageSrc] = useState('');

  const shouldUseLocalIcon = !iconUrl?.includes('/static/');

  useEffect(() => {
    if (iconUrl && !shouldUseLocalIcon) {
      setImageSrc(MRVApi.getIconUrl(iconUrl));
    }
  }, [iconUrl, shouldUseLocalIcon]);

  if (shouldUseLocalIcon && stageType) {
    return (
      <Circle className="crop-icon" color={colors['main-green-600']}>
        {monitoringStageIcons[stageType]}
      </Circle>
    );
  }

  if (!imageSrc) {
    return imagePlaceholder || <LeafIcon fill={colors['main-green-600']} />;
  }

  const maskStyle = `url(${imageSrc}) no-repeat center`;

  const style: React.CSSProperties = {
    mask: maskStyle,
    WebkitMask: maskStyle,
    width: '100%',
    height: '100%',
  };

  if (iconColor) {
    style.background = iconColor;
  }

  return <div style={style} className="item" />;
};
