// @ts-nocheck
import {
  Flex,
  FluroButton,
  FluroDataTable,
  FluroTableBody,
  FluroTableColumn,
  FluroTableHeader,
  FluroTableRow,
  TableSubtext,
} from 'components';
import {FluroChip} from 'components/fluro-chip/fluro-chip';
import {selectUsersList} from 'containers/admin/users/users-selectors';
import {metricGroupsLabels} from 'modules/sustainability-insights/constants';
import type {SIPolicyRequestBody} from 'modules/sustainability-insights/types';
import React, {useEffect, useState} from 'react';
import {useAppDispatch, useAppSelector} from '_hooks';
import {calcPlural, capitalizeFirstLetter} from '_utils/pure-utils';
import {EditIcon} from '../../../map/icons';
import {AdminContentWrapper} from '../../admin.styled';
import {getUsersList} from '../../users/actions';
import type {User} from '../../users/types';
import {loadSIPolices} from '../actions';
import {selectSIPolices} from './premium-apps.selectors';
import ManageSITagsPopUp from './SI-tag-representative-pop-up';

/***
 * SI tag and SI policy are equal concepts
 */

const SITagRepresentative = () => {
  const dispatch = useAppDispatch();
  const usersList = useAppSelector(selectUsersList);
  const polices = useAppSelector(selectSIPolices);

  const [popUpVisible, togglePopUpVisibility] = useState(false);
  const [usersObject, setUserObject] = useState<{[key: string]: User}>({});
  const [selectedTag, setSelectedTag] = useState<SIPolicyRequestBody>(null);

  useEffect(function loadInitData() {
    dispatch(loadSIPolices());

    if (!usersList.length) {
      dispatch(getUsersList());
    }
  }, []);

  useEffect(() => {
    const preparedUsersObject: {[key: string]: User} = {};
    usersList.forEach(u => {
      preparedUsersObject[`${u.id}`] = u;
    });
    setUserObject(preparedUsersObject);
  }, [usersList]);

  const onEditPolicy = (policy: SIPolicyRequestBody) => {
    const {years, metric_groups, agg_levels, states, user_id} = policy;
    setSelectedTag({
      years: years.length === 1 ? [years[0] as number, years[0] as number] : years, // handle case when the end and start dates are the same and we have a single date in the array
      metric_groups: metric_groups || [],
      agg_levels,
      states,
      user_ids: [user_id],
    });
    togglePopUpVisibility(true);
  };

  return (
    <div className={'si-tags-page'}>
      <div className="add-tag-btn-container">
        <FluroButton onClick={() => togglePopUpVisibility(true)} primary raised>
          Add a tag
        </FluroButton>
      </div>

      <ManageSITagsPopUp
        usersObject={usersObject}
        visible={popUpVisible}
        onHide={() => {
          togglePopUpVisibility(false);
          setSelectedTag(null);
        }}
        defaultValues={selectedTag}
      />

      <AdminContentWrapper>
        <FluroDataTable>
          <FluroTableHeader>
            <FluroTableRow>
              <FluroTableColumn>User</FluroTableColumn>
              <FluroTableColumn type={'date'}>Years</FluroTableColumn>
              <FluroTableColumn>Product coverage</FluroTableColumn>
              <FluroTableColumn>Aggregation</FluroTableColumn>
              <FluroTableColumn>Metric groups</FluroTableColumn>
              <FluroTableColumn />
            </FluroTableRow>
          </FluroTableHeader>

          <FluroTableBody>
            {polices.map(policy => {
              const {years, user_id, agg_levels, metric_groups, states} = policy;
              const userObj: User = usersObject[user_id] || ({} as User);
              const yearsString =
                years.length > 1 ? `${years[0]} to ${years[years.length - 1]}` : years[0];
              const countiesLength = policy.states.reduce(
                (acc, state) => state.counties.length + acc,
                0
              );
              return (
                <FluroTableRow key={user_id}>
                  <FluroTableColumn>
                    {userObj.email}
                    <TableSubtext>#{user_id}</TableSubtext>
                  </FluroTableColumn>
                  <FluroTableColumn nowrap type={'date'}>
                    {yearsString}
                  </FluroTableColumn>
                  <FluroTableColumn>
                    {states.length} {calcPlural('State', policy.states.length)}
                    {agg_levels.includes('county') && (
                      <TableSubtext>
                        {countiesLength} {calcPlural('County', countiesLength, 'Counties')}
                      </TableSubtext>
                    )}
                  </FluroTableColumn>
                  <FluroTableColumn>
                    {agg_levels.map(capitalizeFirstLetter).join(', ')}
                  </FluroTableColumn>
                  <FluroTableColumn>
                    <Flex className={'metric-groups-container'} gap={'5px'}>
                      {metric_groups?.map(m => (
                        <FluroChip
                          tone={'dimmed'}
                          size={'small'}
                          key={m}
                          label={metricGroupsLabels[m] || m}
                        />
                      )) || '-'}
                    </Flex>
                  </FluroTableColumn>
                  <FluroTableColumn>
                    <FluroButton onClick={() => onEditPolicy(policy)} iconEl={<EditIcon />} icon>
                      edit
                    </FluroButton>
                  </FluroTableColumn>
                </FluroTableRow>
              );
            })}
          </FluroTableBody>
        </FluroDataTable>
      </AdminContentWrapper>
    </div>
  );
};

export default SITagRepresentative;
