import {FormattedMessage} from 'i18n-utils';
import React from 'react';
import {FontIcon} from 'react-md';
import Control from 'containers/map/lib/control';

const GoHomeBtn = ({fitBounds}: {fitBounds: () => void}) => {
  return (
    <Control position="topleft">
      <FontIcon
        // @ts-expect-error error leftover from convertion to strict mode, please fix
        title={<FormattedMessage id="Go to field" defaultMessage="Go to field" />}
        iconClassName={'fas fa-home'}
        onClick={() => fitBounds()}
        className={'locate-to-farm'}
      />
    </Control>
  );
};

export default GoHomeBtn;
