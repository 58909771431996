// @ts-nocheck
import React, {useCallback} from 'react';
import {GeoJSON} from 'react-leaflet';
import type {Feature} from 'geojson';
import ReactDOMServer from 'react-dom/server';
import {getSoilColorByType} from '../../../_utils/colors';
import type {FieldSoilLayers} from '../types';
import {moveLayerToTop} from '../utils';
import {MAIN_SHAPE_COLOR} from '../../../_constants';
import {resolveEventHandlers} from '../../../components/fluro-leaflet/fix-click-handler';
import {useAppSelector} from '../../../_hooks';
import {selectIsWholeFarmView} from '../reducer/selectors';

type Props = {
  id: number;
  data: FieldSoilLayers;
};

const FieldSoilMapOverlay = ({id, data}: Props) => {
  const isWholeFarmView = useAppSelector(selectIsWholeFarmView);
  const onEachSoilMapFeature = useCallback(
    (feature: Feature, layer: L.Path) => {
      const popupContent = (
        <>
          <div className={'map-popup__label'}>{feature.properties.texture}</div>
          <div className={'map-popup__subtitle'}>{feature.properties.map_unit_name}</div>
        </>
      );
      layer.bindPopup(ReactDOMServer.renderToString(popupContent), {className: 'map-popup'});
      layer.setStyle({
        color: '#999',
        fillColor: getSoilColorByType(feature.properties.texture),
        fillOpacity: isWholeFarmView ? 1 : 0.8, // remove transparency for the whole farm
      });
      layer.on(
        resolveEventHandlers({
          click: ev => {
            layer.openPopup(ev.latlng);
            moveLayerToTop(layer); // move the current element to the top, so it is not covered by others (and the border is fully visible)
          },
          popupclose: () => {
            layer.setStyle({color: '#999'});
          },
          popupopen: () => {
            layer.setStyle({color: MAIN_SHAPE_COLOR});
          },
        })
      );
    },
    [isWholeFarmView]
  );

  return <GeoJSON key={id} onEachFeature={onEachSoilMapFeature} data={data} />;
};
export default FieldSoilMapOverlay;
