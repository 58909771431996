import React, {Component} from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import ReadOnly from 'components/read-only-container';
import {GLOBAL_FORMAT_DATE} from '_constants';
import {Button, TextField, SelectField, SelectionControl} from 'react-md';
import get from 'lodash.get';
import {toggleEditingMode} from '../../actions';
import {removeSamplingPoint} from '../../actions/sampling-points';
import Yup from 'yup';
import {withFormik} from 'formik';
import SamplingPointsLabelsDropdown from './sampling-points-labels-menu';

import {EmptyStringNumber, formatDate, parseNumber, checkForGeometryCollection} from '_utils';
import {point as turfPoint, booleanContains} from '@turf/turf';
import SAMPLING_GROWTH_STAGES from '_constants/growth-stages';
import {FluroDatePicker} from 'components';
import {getPointsGroupKeys} from '../../utils/sampling-points';
import {t} from 'i18n-utils';

const emptyStringNumber = new EmptyStringNumber();

class EditMarkerComp extends Component {
  static propTypes = {
    id: PropTypes.string.isRequired,
    marker: PropTypes.object.isRequired,
    onSave: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      isDisabledLatLon: true,
      isAdvancedEnable: false,
    };

    this.growthStageAllowedTypes = ['Petiole sample', 'Leaf sample'];
    this.labResultsAllowedTypes = ['Petiole sample', 'Leaf sample', 'Soil sample'];
  }

  componentDidMount() {
    this.scrollToBottom();
  }

  scrollToBottom() {
    this.el && this.el.scrollIntoView({behavior: 'smooth'});
  }

  onAdvanced = value => {
    this.setState({isAdvancedEnable: value});
    if (value) setTimeout(() => this.scrollToBottom(), 50);
  };

  onChangeCoordinates(field, value) {
    this.props.setFieldValue(`marker.geometry._coordinates.${field}`, parseNumber(value));
  }

  onChangeProp(field, value) {
    if (field === 'samplingPointType' && !this.toShowGrowthStage(value))
      this.props.setFieldValue(`marker.properties.growthStage`, '');

    this.props.setFieldValue(`marker.properties.${field}`, value);
  }

  onChangeNewGroupDate = value => this.props.setFieldValue('newGroupDate', value);

  onUnlock = () => {
    this.setState(state => ({
      isDisabledLatLon: !state.isDisabledLatLon,
    }));
  };

  getGroupsOptions = () => {
    return [
      ...getPointsGroupKeys().map(date => ({label: date, value: date})),
      {label: t({id: 'Add new group'}), value: 'new'},
    ];
  };

  propError = key => get(this.props.errors, `marker.properties.${key}`, false);

  coordinateErr = coordinate =>
    this.props.errors?.marker?.geometry?._coordinates?.[coordinate] || false;

  toShowGrowthStage = samplingPointType =>
    this.growthStageAllowedTypes.includes(samplingPointType) &&
    this.props.currentSeason.cropType &&
    SAMPLING_GROWTH_STAGES[this.props.currentSeason.cropType];

  toShowLabResults = () =>
    this.labResultsAllowedTypes.includes(this.props.values.marker.properties.samplingPointType);

  coordinatesSection = () => {
    const {marker} = this.props.values;
    const {isDisabledLatLon} = this.state;
    return (
      <div className="coordinates-container">
        <TextField
          id="lat"
          label={t({id: 'Lat'})}
          type="number"
          lineDirection="center"
          className="coordinate-input"
          disabled={isDisabledLatLon}
          value={marker.geometry._coordinates.lat}
          onChange={this.onChangeCoordinates.bind(this, 'lat')}
          error={!!this.coordinateErr('lat')}
          errorText={this.coordinateErr('lat')}
        />

        <TextField
          id="lng"
          label={t({id: 'Lng'})}
          type="number"
          lineDirection="center"
          className="coordinate-input"
          disabled={isDisabledLatLon}
          value={marker.geometry._coordinates.lng}
          onChange={this.onChangeCoordinates.bind(this, 'lng')}
          error={!!this.coordinateErr('lng')}
          errorText={this.coordinateErr('lng')}
        />

        <div className="coordinate-buttons">
          <Button
            tooltipPosition="left"
            tooltipLabel={`${isDisabledLatLon ? t({id: 'Unlock'}) : t({id: 'Lock'})} ${t({
              id: 'Lat/Lon values',
            })}`}
            icon
            onClick={this.onUnlock}
          >
            {isDisabledLatLon ? 'lock' : 'lock_open'}
          </Button>
          <Button
            tooltipPosition="left"
            tooltipLabel={t({id: 'Edit coordinates'})}
            icon
            onClick={() => this.props.toggleEditingMode(true)}
          >
            edit
          </Button>
        </div>
      </div>
    );
  };

  render() {
    const {values, handleSubmit, cellMapping} = this.props;
    const cropType = this.props.currentSeason.cropType;
    const {properties} = values.marker;
    const cellMappingKeys = Object.keys(cellMapping).filter(
      (k, i) => i > 3 && !['samplingType', 'growthStage'].includes(k)
    );
    const groupsOptions = this.getGroupsOptions();
    const isCreateNewGroup = properties.timedate === 'new';

    return (
      <form onSubmit={handleSubmit} ref={el => (this.el = el)}>
        <div className="geometry-edit-form__label">
          <div className="geometry-edit-form__label--title">Label</div>
          <SamplingPointsLabelsDropdown
            id={`point-sampling-type`}
            className={'element-full-width'}
            label={properties.samplingPointType}
            onChange={this.onChangeProp.bind(this, 'samplingPointType')}
          />
        </div>

        {this.toShowGrowthStage(properties.samplingPointType) && (
          <SelectField
            id={`sampling-growth-stage`}
            className={'element-full-width'}
            label={t({id: 'Growth Stage'})}
            placeholder={t({id: 'Choose growth stage'})}
            menuItems={SAMPLING_GROWTH_STAGES[cropType]}
            simplifiedMenu={true}
            value={properties.growthStage}
            onChange={this.onChangeProp.bind(this, 'growthStage')}
          />
        )}

        <TextField
          id="marker-name"
          label={t({id: 'Point ID'})}
          lineDirection="center"
          maxLength={30}
          className="element-full-width"
          value={properties.title}
          onChange={this.onChangeProp.bind(this, 'title')}
          error={Boolean(this.propError('title'))}
          errorText={this.propError('title')}
        />

        <SelectField
          id="map-tsp-groups-set-for-marker"
          placeholder={t({id: 'Sampling group'})}
          className="element-full-width"
          label={t({id: 'Sampling date'})}
          menuItems={groupsOptions}
          value={
            isCreateNewGroup
              ? properties.timedate
              : moment(properties.timedate).format(formatDate())
          }
          onChange={val =>
            this.onChangeProp('timedate', val === 'new' ? val : moment(val, formatDate()).format())
          }
          simplifiedMenu={true}
        />

        {isCreateNewGroup && (
          <FluroDatePicker
            id={`new-group-date`}
            label={t({id: 'Group date'})}
            selected={moment(values.newGroupDate)}
            onChange={val => this.onChangeNewGroupDate(val.format(GLOBAL_FORMAT_DATE))}
            hideFormat
            portal
          />
        )}

        {this.toShowLabResults() ? (
          <React.Fragment>
            {this.coordinatesSection() /* coordinates */}

            <SelectionControl
              id="toggle-ts-advanced"
              type="switch"
              label={t({id: 'Lab results'})}
              labelBefore={true}
              name="toggle-ts-advanced"
              onChange={this.onAdvanced}
              checked={this.state.isAdvancedEnable}
              className="element-full-width"
            />

            {this.state.isAdvancedEnable ? (
              <div className={'point-params'}>
                {cellMappingKeys.map((k, i) => (
                  <TextField
                    key={`marker-elem-${k}-${i}`}
                    id={`marker-elem-${k}-${i}`}
                    label={cellMapping[k].title}
                    className={'point-params__item'}
                    type="number"
                    value={properties[k]}
                    error={Boolean(this.propError(k))}
                    errorText={this.propError(k)}
                    onChange={val => this.onChangeProp(k, val)}
                  />
                ))}
              </div>
            ) : null}
          </React.Fragment>
        ) : (
          <React.Fragment>
            <TextField
              id={'sampling-point-text'}
              label={t({id: 'Description'})}
              value={properties.description}
              rows={1}
              onChange={this.onChangeProp.bind(this, 'description')}
              error={Boolean(this.propError('description'))}
              errorText={this.propError('description')}
              maxLength={100}
            />
            {this.coordinatesSection() /* coordinates */}
          </React.Fragment>
        )}

        <ReadOnly>
          <div className={'edit-point-buttons'}>
            {this.props.marker.id !== 'new' && (
              <Button raised onClick={() => this.props.removeSamplingPoint(this.props.marker.id)}>
                {t({id: 'Delete'})}
              </Button>
            )}

            <Button id={'save-tsp-btn'} raised primary type="submit">
              {t({id: 'Save'})}
            </Button>
          </div>
        </ReadOnly>
      </form>
    );
  }
}

const FormicForm = withFormik({
  enableReinitialize: true,
  mapPropsToValues: props => {
    return {
      marker: {
        ...props.marker,
        geometry: {
          ...props.marker.geometry,
          _coordinates: {
            lat: props.marker.geometry.coordinates[0],
            lng: props.marker.geometry.coordinates[1],
          },
        },
      },
      newGroupDate: moment().format(GLOBAL_FORMAT_DATE),
    };
  },

  validationSchema: props => {
    return Yup.object().shape({
      marker: Yup.object().shape({
        geometry: Yup.object().shape({
          _coordinates: Yup.object().shape({
            lat: emptyStringNumber
              .min(-90, t({id: 'Min {value}'}, {value: -90}))
              .max(90, t({id: 'Max {value}'}, {value: 90}))
              .required()
              .test('lat', '', function (lat) {
                const isGeometryCollection = checkForGeometryCollection(props.currentFieldKml);

                const {lng} = this.parent;
                const point = turfPoint([lng, lat]);

                if (
                  isGeometryCollection
                    ? props.currentFieldKml.geometry.geometries.every(
                        g => !booleanContains(g, point)
                      )
                    : !booleanContains(props.currentFieldKml, point)
                ) {
                  return this.createError({
                    path: this.path,
                    message: t({id: 'You cannot add point outside of the current field'}),
                  });
                }

                return true;
              }),
            lng: emptyStringNumber
              .min(-180, t({id: 'Min {value}'}, {value: -180}))
              .max(180, t({id: 'Max {value}'}, {value: 180}))
              .required()
              .test('lng', '', function (lng) {
                const isGeometryCollection = checkForGeometryCollection(props.currentFieldKml);

                const {lat} = this.parent;
                const point = turfPoint([lng, lat]);

                if (
                  isGeometryCollection
                    ? props.currentFieldKml.geometry.geometries.every(
                        g => !booleanContains(g, point)
                      )
                    : !booleanContains(props.currentFieldKml, point)
                ) {
                  return this.createError({
                    path: this.path,
                    message: t({id: 'You cannot add point outside of the current field'}),
                  });
                }

                return true;
              }),
          }),
        }),

        properties: Yup.object().shape({
          title: Yup.string()
            .max(30, t({id: 'Max {value}'}, {value: 30}))
            .required(t({id: 'Required field'})),
          timedate: Yup.string().required(),
          n_result: emptyStringNumber
            .max(100, t({id: 'Max {value}'}, {value: 100}))
            .positive(t({id: 'Must be positive'})),
          n_result2: emptyStringNumber.positive(t({id: 'Must be positive'})),
          // new
          total_K: emptyStringNumber
            .max(100, t({id: 'Max {value}'}, {value: 100}))
            .positive(t({id: 'Must be positive'})),
          total_P: emptyStringNumber
            .max(100, t({id: 'Max {value}'}, {value: 100}))
            .positive(t({id: 'Must be positive'})),
          Ca: emptyStringNumber
            .max(100, t({id: 'Max {value}'}, {value: 100}))
            .positive(t({id: 'Must be positive'})),
          Mg: emptyStringNumber
            .max(100, t({id: 'Max {value}'}, {value: 100}))
            .positive(t({id: 'Must be positive'})),
          Na: emptyStringNumber
            .max(100, t({id: 'Max {value}'}, {value: 100}))
            .positive(t({id: 'Must be positive'})),
          S: emptyStringNumber
            .max(100, t({id: 'Max {value}'}, {value: 100}))
            .positive(t({id: 'Must be positive'})),
          Zn_ppm: emptyStringNumber.positive(t({id: 'Must be positive'})),
          Mn_ppm: emptyStringNumber.positive(t({id: 'Must be positive'})),
          Fe_ppm: emptyStringNumber.positive(t({id: 'Must be positive'})),
          Cu_ppm: emptyStringNumber.positive(t({id: 'Must be positive'})),
          B_ppm: emptyStringNumber.positive(t({id: 'Must be positive'})),
          Cl: emptyStringNumber
            .max(100, t({id: 'Max {value}'}, {value: 100}))
            .positive(t({id: 'Must be positive'})),
          Mo_ppm: emptyStringNumber.positive(t({id: 'Must be positive'})),
          growthStage: Yup.string(),
          description: Yup.string().max(
            100,
            t({id: 'Only {value} characters allowed'}, {value: 100})
          ),
        }),
      }),
    });
  },

  handleSubmit: (values, {props, setSubmitting}) => {
    values.marker.geometry.coordinates = [
      values.marker.geometry._coordinates.lat,
      values.marker.geometry._coordinates.lng,
    ];
    const pointDate = values.marker.properties.timedate;
    values.marker.properties.timedate = pointDate === 'new' ? values.newGroupDate : pointDate;

    props.onSave(values.marker);

    setSubmitting(true);
  },

  displayName: 'EditMarker',
})(EditMarkerComp);

const mapStateToProps = state => ({
  cellMapping: state.tsUpload.cellMapping,
  currentFieldKml: state.map.currentFieldKml || {},
  currentSeason: state.map.currentSeason,
  pointsGroups: state.map.pointsGroups,
});

export default connect(mapStateToProps, {
  toggleEditingMode,
  removeSamplingPoint,
})(FormicForm);
