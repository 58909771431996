// @ts-nocheck
import {FormattedMessage, t} from 'i18n-utils';
import React, {useEffect, useMemo, useState} from 'react';
import {setSchema} from '../../actions';
import {SelectField} from 'react-md';
import {ColorSchema} from '../../types';
import {getSingleDateSchema} from '../../utils';
import {getTreeDataByDate} from '../../utils/trees';
import {formatUnit} from '_utils';
import type {AppStore} from 'reducers';
import {useAppDispatch, useAppSelector, usePrevious} from '_hooks';
import {PRODUCTIVITY_MAP_ASSETS_HOST} from '../../features/zoning/productivity-map/productivity-map.module';
import {AsyncStatusType, Status} from 'modules/helpers';
import './color-schema-overlay.scss';
import {
  selectColorSchema,
  selectCurrentCompareDate,
  selectCurrentSeasonId,
  selectDateTimeByDate,
  selectIsCompareOn,
  selectIsWholeFarmView,
  selectIsZoning,
  selectTreeDetection,
  selectTreeDetectionScale,
  selectCurrentDate,
  selectCurrentImageObj,
  selectCurrentSensor,
  selectCurrentTab,
} from '../../reducer/selectors';
import {selectMeasurement} from '../../../login/login-selectors';
import {selectAsyncRequestStatus, selectViewport} from 'modules/global/selectors';
import {addColorSchemaToImageUrl} from '_utils/imagery-utils';
import {selectImageCache} from 'modules/images-cache.module';

const allowedIndexes: string[] = [
  'CCCI',
  'MSAVI',
  'NDWI',
  'NDRE',
  'NMAP',
  'NDVI',
  'RNDVI',
  'TIRS',
  'VVI',
  'PGI',
  'EC',
  'PH',
  'YIELD',
  'ELEVATION',
  'DIAMETER',
  'PTIRS',
];

const schemas: Array<{label: any; value: string}> = [
  {label: <FormattedMessage id="Single Date" defaultMessage="Single Date" />, value: 'singleDate'},
  {
    label: <FormattedMessage id="Whole Season" defaultMessage="Whole Season" />,
    value: 'wholeSeason',
  },
];

const MapColorSchema = () => {
  const [expanded, setExpanded] = useState(true);
  const [imageSrc, setImageSrc] = useState('');
  const [errorSrc, setErrorSrc] = useState('');
  const [prevSchemaValue, setPrevSchemaValue] = useState(ColorSchema.Relative); // store the before whole farm switching schema to set it back when a single field view is selected
  const dispatch = useAppDispatch();

  const viewport = useAppSelector(selectViewport);
  const currentSeasonId = useAppSelector(selectCurrentSeasonId);
  const currentDate = useAppSelector(selectCurrentDate);
  const currentSensor = useAppSelector(selectCurrentSensor);
  const isWholeFarmView = useAppSelector(selectIsWholeFarmView);
  const treeDetection = useAppSelector(selectTreeDetection);
  const colorSchema = useAppSelector(selectColorSchema);
  const scaleUrl = useAppSelector(store => store.map.nitrogen.scaleUrl);
  const feature = useAppSelector(selectCurrentTab);
  const isZoning = useAppSelector(selectIsZoning);
  const measurement = useAppSelector(selectMeasurement);
  const productivityMapData = useAppSelector(
    (state: AppStore) => state.productivityMap.data[state.productivityMap.currentCacheKey]
  );
  const isProductivityMap = useAppSelector((state: AppStore) => state.productivityMap.isOn);
  const isProductivityMapLoading = useAppSelector(
    state => selectAsyncRequestStatus(state, AsyncStatusType.productivityMap) === Status.Pending
  );

  const isBinaryImageLoading = useAppSelector(
    s => selectAsyncRequestStatus(s, AsyncStatusType.binaryImageLayerData) === Status.Pending
  );

  const cachedImages = useAppSelector(selectImageCache).data;
  const currentImageObject = useAppSelector(selectCurrentImageObj);
  const treeDetectionScale = useAppSelector(selectTreeDetectionScale);
  const isCompareOn = useAppSelector(selectIsCompareOn);
  const currentDateTime = useAppSelector(s => selectDateTimeByDate(s, currentDate));
  const compareDateTime = useAppSelector(s => selectDateTimeByDate(s, selectCurrentCompareDate(s)));

  const currentImageUrl = useMemo(() => {
    return addColorSchemaToImageUrl(currentImageObject?.url, colorSchema);
  }, [currentImageObject?.url, colorSchema]);

  const prev = usePrevious({viewport, scaleUrl});

  useEffect(() => {
    if (isWholeFarmView) {
      setPrevSchemaValue(colorSchema);
      dispatch(setSchema(ColorSchema.Absolute));
    } else {
      dispatch(setSchema(prevSchemaValue));
    }
  }, [isWholeFarmView]);

  useEffect(() => {
    if (viewport.width < 561) {
      setExpanded(false);
    }
    if (isWholeFarmView) {
      dispatch(setSchema(ColorSchema.Absolute));
    }

    setImageSrc(calcScaleUrl());
  }, []);

  useEffect(() => {
    if (isProductivityMap && productivityMapData) {
      setImageSrc(PRODUCTIVITY_MAP_ASSETS_HOST + productivityMapData.thumbnail_zones_black_scale);
    } else if (isCompareOn) {
      setImageSrc(calcScaleUrl());
    }

    setErrorSrc('');
  }, [
    colorSchema,
    currentDate,
    currentSensor,
    currentSeasonId,
    productivityMapData,
    isProductivityMap,
    isCompareOn,
  ]);

  useEffect(() => {
    if (prev?.viewport?.width > 560 && viewport.width < 561) {
      setExpanded(false);
    } else if (prev?.viewport?.width < 561 && viewport.width > 560) {
      setExpanded(true);
    }
  }, [viewport]);

  useEffect(() => {
    // NMAP scale is loaded async, need to update state to get the image

    if (currentSensor === 'NMAP' && imageSrc === '' && scaleUrl && !prev?.scaleUrl) {
      setImageSrc(scaleUrl);
      setErrorSrc('');
    }
  }, [currentSensor, imageSrc, scaleUrl]);

  const toggleExpanded = () => setExpanded(!expanded);

  const calcScaleUrl = (isOld = false, removeThermal = false) => {
    if (currentSensor === 'TIRS') {
      return getSingleDateUrl(isOld, removeThermal);
    }

    return colorSchema !== ColorSchema.Relative
      ? `/assets/schemas/${colorSchema}.png`
      : currentSensor === 'NMAP'
      ? scaleUrl
      : getSingleDateUrl(isOld);
  };

  const getSingleDateUrl = (isOld = false, removeThermal = false) => {
    const isFahrenheit =
      !removeThermal &&
      (currentSensor === 'TIRS' || currentSensor === 'PTIRS') &&
      measurement === 'ac'
        ? 'F'
        : ''; // a parameter to switch scale between Fahrenheit and Celsius
    return (currentImageUrl || '').replace(
      '.png',
      `_singleDate.${isOld ? 'scale' : 'scaleInv'}${isFahrenheit}.png`
    );
  };

  // const onErrorLoadScale = () => {
  //   const isFahrenheit = ['TIRS', 'PTIRS'].includes(currentSensor) && measurement === 'ac';

  //   setErrorSrc(imageSrc);

  //   if (isProductivityMap) {
  //     return;
  //   }

  //   let newImageSrc = calcScaleUrl(true);

  //   if (isFahrenheit && (imageSrc.includes('scaleF') || imageSrc.includes('scaleInv.png'))) {
  //     newImageSrc = calcScaleUrl(!imageSrc.includes('scaleF.png'), true);
  //   }

  //   if (
  //     (isFahrenheit ? !imageSrc.includes('scale.png') : true) &&
  //     newImageSrc !== errorSrc &&
  //     newImageSrc !== imageSrc
  //   ) {
  //     setImageSrc(newImageSrc);
  //   }
  // };

  const getTreeSchema = () => {
    return getSingleDateSchema(treeDetectionScale);
  };

  const isTreeDetection = treeDetection.layerType !== 'default' && getTreeDataByDate(currentDate);

  const scaleLabel = useMemo(() => {
    if (isProductivityMap) {
      return null;
    }

    if (isTreeDetection && !isCompareOn) {
      return getTreeSchema();
    }

    return (
      <>
        {['TIRS', 'PTIRS'].includes(currentSensor) || isWholeFarmView ? (
          <span className="color-schema-label">
            {isWholeFarmView ? (
              schemas.find(schema => schema.value === colorSchema)?.label
            ) : (
              <FormattedMessage
                id="thermal"
                defaultMessage="Thermal {unit}"
                values={{
                  unit: <span className={'thermal-scale'}>(°{formatUnit(measurement, 'C')})</span>,
                }}
              />
            )}
          </span>
        ) : (
          <SelectField
            id="color-schema-select"
            placeholder={t({id: 'Select color schema', defaultMessage: 'Select color schema'})}
            className="color-schema-select"
            label=""
            menuItems={schemas}
            value={colorSchema}
            onChange={(value: string) => dispatch(setSchema(value))}
            simplifiedMenu={false}
            anchor={{
              x: SelectField.HorizontalAnchors.RIGHT,
              y: SelectField.VerticalAnchors.TOP,
            }}
          />
        )}
      </>
    );
  }, [
    isTreeDetection,
    currentSensor,
    isWholeFarmView,
    schemas,
    colorSchema,
    setSchema,
    measurement,
    getTreeSchema,
    isProductivityMap,
    currentImageObject,
  ]);

  if (
    isZoning ||
    (feature === 'zoning' && !isProductivityMap) ||
    (feature === 'zoning' && isProductivityMap && isProductivityMapLoading) ||
    !allowedIndexes.includes(currentSensor) ||
    (!getSingleDateUrl() && !isTreeDetection)
    // || isWholeFarmView
  ) {
    return null;
  }

  if (!expanded) {
    return <div className={`map-color-schema__${colorSchema}`} onClick={toggleExpanded} />;
  }

  const canShowScale =
    errorSrc !== imageSrc &&
    !(isTreeDetection && !isCompareOn) &&
    currentDate &&
    currentSensor !== 'NONE';

  const schemaImage = () => {
    if (!canShowScale) return null;

    if (colorSchema === ColorSchema.Relative && isCompareOn) {
      return isBinaryImageLoading ? (
        <div style={{color: '#bfbfbf'}}>Loading...</div>
      ) : (
        <img
          className="color-schema-img"
          src={cachedImages[`${currentDateTime}_${compareDateTime}_${currentSensor}_scale`]?.data}
          alt={`color-schema-${colorSchema}`}
          onClick={toggleExpanded}
        />
      );
    }

    if (colorSchema === ColorSchema.Relative) {
      return isBinaryImageLoading ? (
        <div style={{color: '#bfbfbf'}}>Loading...</div>
      ) : (
        getSingleDateSchema(
          [
            `${cachedImages[currentImageUrl]?.meta?.min || 0}`,
            `${cachedImages[currentImageUrl]?.meta?.max || 0}`,
          ],
          true
        )
      );
    }

    return getSingleDateSchema([`0`, `1`], true, [0, 0.2, 0.4, 0.6, 0.8, 1]);
  };

  return (
    <div className={'map-color-schema'}>
      {scaleLabel}

      {/* single field wiew */}
      {canShowScale ? schemaImage() : null}

      {imageSrc === errorSrc && !isTreeDetection && (
        <div className={'error-color-schema-img'}>
          {t({
            id: 'Something went wrong, please try again later.',
            defaultMessage: 'Something went wrong, please try again later.',
          })}
        </div>
      )}
    </div>
  );
};

export default MapColorSchema;
