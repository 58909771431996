import type {ValueFormatter} from '@nivo/bar';
import {Flex, Text} from 'components';
import {CardHr} from 'components/card/card-styled';
import {FluroRheostat} from 'components/fluro-rheostat';
import {Span} from 'components/text/span';
import {debounce} from 'lodash';
import {setHighlightGeometry, setGhgFilter} from 'modules/sustainability-insights/actions';
import {
  AGG_LEVEL_TO_LABEL,
  SUMMARY_BARS_PREVIEW_THRESHOLD,
} from 'modules/sustainability-insights/constants';
import {
  selectActiveGeometriesIds,
  selectAggLevel,
  selectAreaUnits,
  selectCurrentMeta,
} from 'modules/sustainability-insights/selectors';
import {
  selectGhgChromaScale,
  selectGhgCardRange,
  selectGhgStatsExtrema,
  selectGhgGroupedStatsExtrema,
  selectGhgSummaryChartData,
} from 'modules/sustainability-insights/selectors/ghg';
import type {
  GroupedSummaryChartData,
  SetGhgFilterAction,
} from 'modules/sustainability-insights/types';
import {calculateScale, getSummaryChartLabel} from 'modules/sustainability-insights/utils';
import type {ComponentType} from 'react';
import React, {useCallback, useEffect, useRef} from 'react';
import {useAppDispatch, useAppSelector} from '_hooks';
import {kiloFormatter} from '_utils/number-formatters';
import {LearnMore} from '../components/learn-more';
import {NoSelectedAreaMessage, NoDataMessage} from '../messages';
import {SummaryChart, SummaryChartGrouped} from '../summary-chart';
import {formatValueTPerAcres} from '../utils';

const CHART_KEYS = ['value'];

export const GhgSummaryChart: ComponentType<{}> = () => {
  const dispatch = useAppDispatch();

  const units = useAppSelector(selectAreaUnits);
  const data = useAppSelector(selectGhgSummaryChartData);
  const colorScale = useAppSelector(selectGhgChromaScale);
  const {min, max} = useAppSelector(selectGhgStatsExtrema);
  const {max: groupedMax} = useAppSelector(selectGhgGroupedStatsExtrema);
  const meta = useAppSelector(selectCurrentMeta);
  const aggLevel = useAppSelector(selectAggLevel);
  const range = useAppSelector(selectGhgCardRange);
  const selectedIds = useAppSelector(selectActiveGeometriesIds);
  const rangeMax = Math.ceil(max);
  const rangeMin = Math.floor(min);

  const setCardFilter = useCallback(
    (p: SetGhgFilterAction['payload']) => {
      dispatch(setGhgFilter(p));
    },
    [dispatch]
  );

  const setCardFilterDebounceRef = useRef(debounce(setCardFilter, 100));

  useEffect(() => {
    // setup initial range
    const newRange: [number, number] = [rangeMin, rangeMax];
    if (range || !newRange.every(Number.isFinite)) return;
    setCardFilter({range: newRange});
  }, [setCardFilter, rangeMin, rangeMax, range]);

  const getColor = useCallback(
    (value: number) => {
      const scale = calculateScale(value, min, max);
      const color = colorScale(scale).css();
      return color;
    },
    [colorScale, max, min]
  );

  const nameLabel: string | ValueFormatter = (id: number) => {
    return getSummaryChartLabel(meta?.[id]?.name, aggLevel === 'state');
  };

  const setRange = (range: [number, number]) => {
    setCardFilterDebounceRef.current({range});
  };

  const handleBarMouseEnter = ({data: {id}}: any) => {
    dispatch(setHighlightGeometry(id));
  };

  const handleBarMouseLeave = () => {
    dispatch(setHighlightGeometry(null));
  };

  const chartValuesFormatter = (value: number) => {
    return formatValueTPerAcres(value, units);
  };

  if (!selectedIds) return <NoSelectedAreaMessage />;
  if (!data) return <NoDataMessage />;

  const {type, chartData, chartGeometriesIds, values, preview, totalValue} = data;
  const geometryTypeLabel = AGG_LEVEL_TO_LABEL[aggLevel] || aggLevel;

  // These are border values to make grouped chart's bars positions connected between each other
  // minValue must be zero or negative, otherwise horizontal bar's left position will break
  const maxValue = type === 'grouped' ? Math.ceil(groupedMax) : undefined;
  const minValue = type === 'grouped' ? Math.min(Math.floor(min), 0) : undefined;
  return (
    <>
      {!!(preview && range) && (
        <>
          <div className="chart-range chart-range__container mb-2">
            <FluroRheostat
              colorScale={colorScale}
              name={`soc-summary-${units}`}
              inputChar={units === 'pct' ? '%' : 't/ac'}
              min={rangeMin}
              max={rangeMax}
              data={values}
              onChange={setRange}
            />
          </div>
          <CardHr />
        </>
      )}

      {!!chartGeometriesIds?.length && (
        <>
          <Text secondary elementType={'p'} variant={'small'}>
            {chartGeometriesIds.length} {geometryTypeLabel}, {kiloFormatter(totalValue)} t match
            current filters
          </Text>
          <CardHr />
        </>
      )}

      <div className="mb-1">
        {type === 'normal' ? (
          <SummaryChart
            keys={CHART_KEYS}
            data={chartData}
            getColor={getColor}
            nameLabel={nameLabel}
            onMouseEnter={handleBarMouseEnter}
            onMouseLeave={handleBarMouseLeave}
            maxVisibleItems={SUMMARY_BARS_PREVIEW_THRESHOLD}
            format={chartValuesFormatter}
            allowShowMore={aggLevel !== 'huc10' && aggLevel !== 'huc12'}
          />
        ) : (
          <SummaryChartGrouped
            keys={CHART_KEYS}
            data={chartData as GroupedSummaryChartData[]}
            getColor={getColor}
            nameLabel={nameLabel}
            onMouseEnter={handleBarMouseEnter}
            onMouseLeave={handleBarMouseLeave}
            maxVisibleItems={SUMMARY_BARS_PREVIEW_THRESHOLD}
            format={chartValuesFormatter}
            maxValue={maxValue}
            minValue={minValue}
            allowShowMore={aggLevel !== 'huc10' && aggLevel !== 'huc12'}
          />
        )}
      </div>

      <Flex justifyContent="space-between">
        <LearnMore articleId={6479120} />
        <Span secondary>CO2 eq. t/ac</Span>
      </Flex>
    </>
  );
};
