import type {FField} from '../types';
import {createSelector} from '@reduxjs/toolkit';
import type {AppStore} from 'reducers';

export const selectFilteredFields = createSelector(
  [
    (result: FField[]) => result,
    (result: FField[], selectedEntities: string[]) => selectedEntities,
    (result: FField[], selectedEntities: string[], searchBy: string) => searchBy,
  ],
  (result, selectedEntities, searchBy) => {
    const filterResult: {
      organizationsIds: number[];
      subscriptionIds: number[];
      farmIds: number[];
      fieldIds: number[];
    } = {
      organizationsIds: [],
      subscriptionIds: [],
      farmIds: [],
      fieldIds: [],
    };
    selectedEntities.forEach(searchValue => {
      const [farmId, fieldId, organizationId, subscriptionId] = `${searchValue}`
        .split(';') // extract values from the string
        .map(e => parseInt(e)); // convert into numbers
      filterResult.organizationsIds.push(organizationId);
      filterResult.subscriptionIds.push(subscriptionId);
      filterResult.farmIds.push(farmId);
      filterResult.fieldIds.push(fieldId);
    });

    return result
      .map(field => {
        return {
          ...field,
          seasons: field?.seasons || [],
        };
      })
      .filter(field => {
        if (!field.seasons.length) return false;
        if (!selectedEntities.length) return true;

        if (searchBy.startsWith('farm') || searchBy === 'all') {
          return filterResult.farmIds.includes(field.farmId);
        } else if (searchBy.startsWith('organization')) {
          return filterResult.organizationsIds.includes(field.organizationId);
        } else if (searchBy.startsWith('subscription')) {
          return filterResult.subscriptionIds.includes(field.subscriptionId);
        } else {
          return (
            filterResult.farmIds.includes(field.farmId) &&
            filterResult.fieldIds.includes(field.fieldId)
          );
        }
        // let flag =
        //   !filterValues.entities.length || (filterValues.entities as number[]).includes(field.farmId);
        //
        // return flag && field.seasons.length;
      });
  }
);

export const selectSIPolices = (state: AppStore) => state.admin.features.SIPolices;
