import xor from 'lodash/xor';
import type {AnyAction} from 'redux';
import {TREND_THRESHOLD} from '../constants';
import {getUserPolicy} from '../thunks';
import type {SetChartTabAction, SIFilterState} from '../types';
import {ActionType, CoverCropsType, SIGeometryFilterType, TillagePractice} from '../types';
import {isYearsDiff, parseAreaId} from '../utils';

const initialState: SIFilterState = {
  /* Request related filters */
  stateIdsRequestParam: [],
  supplyShedIdsRequestParam: [],
  years: null,
  aggLevel: 'state',

  metricsType: 'ac',
  areaUnits: 'pct',
  fieldAdopterType: null,
  operationAdopterType: null,
  operationSize: '',
  summerCropTypes: '',

  /* UI display related filters */
  visibleGeometriesIds: [],
  activeGeometriesIds: [],
  highlightGeometryId: null,

  activeGeometryType: SIGeometryFilterType.CoverCrops,
  tillage: {
    chartTab: 'summary',
    selectedPractice: TillagePractice.NoTill,
    range: null,
  },
  coverCrops: {
    chartTab: 'summary',
    includeCategories: [CoverCropsType.CoverCrop],
    range: null,
  },
  abatementPotential: {
    selectedMetric: 'carbon_corn_rotation',
    chartTab: 'summary',
    filterRange: [],
  },
  ghg: {
    chartTab: 'summary',
    includeCategories: [CoverCropsType.CoverCrop],
    range: null,
  },
  soc: {
    chartTab: 'summary',
    includeCategories: [CoverCropsType.CoverCrop],
    range: null,
  },
  yield: {
    chartTab: 'summary',
    includeCategories: [CoverCropsType.CoverCrop],
    range: null,
  },
  fertilizer: {
    chartTab: 'summary',
    includeCategories: [CoverCropsType.CoverCrop],
    range: null,
  },
  ef: {
    chartTab: 'summary',
    includeCategories: [CoverCropsType.CoverCrop],
    range: null,
  },
};

export const filterReducer = (
  state: SIFilterState = initialState,
  action: AnyAction
): SIFilterState => {
  switch (action.type) {
    case getUserPolicy.fulfilled.type: {
      if (state.stateIdsRequestParam?.length) return state;
      const {entities} = action.payload;
      const ids = entities[state.aggLevel]
        ? Object.keys(entities[state.aggLevel])
            .map(parseAreaId)
            .filter(id => !isNaN(id))
        : [];

      return {
        ...state,
        stateIdsRequestParam: ids,
        visibleGeometriesIds: ids,
        activeGeometriesIds: ids,
      };
    }

    case ActionType.SET_AREA_UNITS: {
      const {areaUnits} = action;

      return {
        ...state,
        areaUnits,
        coverCrops: {
          ...state.coverCrops,
          range: null,
        },
        tillage: {
          ...state.tillage,
          range: null,
        },
      };
    }

    case ActionType.SET_FILTER: {
      return {
        ...state,
        ...action.payload,
        coverCrops: {
          ...state.coverCrops,
          range: null,
        },
        tillage: {
          ...state.tillage,
          range: null,
        },
      };
    }

    case ActionType.SET_AGG_LEVEL: {
      const {aggLevel, nextAreaIds} = action;

      const newState: SIFilterState = {
        ...state,
        aggLevel,
        visibleGeometriesIds: nextAreaIds,
        activeGeometriesIds: nextAreaIds,
      };

      if (
        newState.stateIdsRequestParam.length > 1 &&
        (aggLevel === 'huc10' || aggLevel === 'huc12')
      ) {
        newState.stateIdsRequestParam = [];
      }

      return newState;
    }

    case ActionType.SET_CHART_TAB: {
      const {tab, cardType, areEveryGeometrySelected} = action as SetChartTabAction;
      const activeGeometriesIds =
        tab === 'trend' &&
        areEveryGeometrySelected &&
        state.activeGeometriesIds?.length > TREND_THRESHOLD
          ? []
          : state.activeGeometriesIds;

      return {
        ...state,
        activeGeometriesIds,
        [cardType]: {
          ...state[cardType],
          chartTab: tab,
        },
      };
    }

    case ActionType.SET_ACTIVE_GEOMETRIES_FILTER: {
      const {id, multiselect, resetVisible} = action;
      const ids = id ? [].concat(id) : [];
      const activeGeometriesIds = multiselect ? xor(state.activeGeometriesIds, ids) : ids;

      return {
        ...state,
        visibleGeometriesIds: resetVisible ? activeGeometriesIds : state.visibleGeometriesIds,
        activeGeometriesIds,
      };
    }

    case ActionType.SELECT_ACTIVE_GEOMETRIES: {
      const {ids, selected, resetVisible} = action.payload;
      const activeGeometriesIds = ids
        ? selected
          ? state.activeGeometriesIds.concat(ids)
          : state.activeGeometriesIds.filter(id => !ids.includes(id))
        : state.activeGeometriesIds;

      return {
        ...state,
        visibleGeometriesIds: resetVisible ? activeGeometriesIds : state.visibleGeometriesIds,
        activeGeometriesIds,
      };
    }

    case ActionType.SET_STATE_IDS_REQUEST_PARAM: {
      const {statesIds, selected} = action.payload;
      if (typeof selected === 'boolean') {
        return {
          ...state,
          stateIdsRequestParam: selected
            ? state.stateIdsRequestParam.concat(statesIds)
            : state.stateIdsRequestParam.filter(id => !statesIds.includes(id)),
        };
      }

      return {
        ...state,
        stateIdsRequestParam: statesIds,
      };
    }

    case ActionType.SET_SUPPLY_SHEDS_REQUEST_PARAM: {
      const {supplySheds, selected} = action.payload;
      if (typeof selected === 'boolean') {
        return {
          ...state,
          supplyShedIdsRequestParam: selected
            ? state.supplyShedIdsRequestParam.concat(supplySheds)
            : state.supplyShedIdsRequestParam.filter(id => !supplySheds.includes(id)),
        };
      }

      return {
        ...state,
        supplyShedIdsRequestParam: supplySheds,
      };
    }

    case ActionType.SET_FILTER_YEARS: {
      const newState = {...state, years: action.years};

      newState.coverCrops.range = null;
      if (!isYearsDiff(newState.years)) {
        newState.coverCrops.chartTab = 'summary';
      }

      return newState;
    }

    case ActionType.SET_FILTER_KEY: {
      const {key, value} = action;
      const newState: Partial<SIFilterState> = {[key]: value};

      return {
        ...state,
        ...newState,
      };
    }

    case ActionType.SET_HIGHLIGHT_GEOMETRY: {
      const {geometryId} = action;

      return {
        ...state,
        highlightGeometryId: geometryId,
      };
    }

    case ActionType.SET_CROP_CHART_FILTER_ACTION: {
      const {payload} = action;

      return {
        ...state,
        coverCrops: {...state.coverCrops, ...payload},
      };
    }

    case ActionType.ABATEMENT_POTENTIAL_SET_SELECTED_METRIC: {
      return {
        ...state,
        abatementPotential: {...state.abatementPotential, selectedMetric: action.metric},
      };
    }

    case ActionType.ABATEMENT_POTENTIAL_SET_FILTER_RANGE: {
      return {
        ...state,
        abatementPotential: {...state.abatementPotential, filterRange: action.filterRange},
      };
    }

    // Tillage

    case ActionType.TILLAGE_SET_SELECTED_PRACTICE: {
      return {
        ...state,
        tillage: {...state.tillage, selectedPractice: action.practice},
      };
    }

    case ActionType.TILLAGE_SET_RANGE_VALUES: {
      return {
        ...state,
        tillage: {...state.tillage, range: action.range},
      };
    }

    // Soc/ GHG

    case ActionType.SET_SOC_FILTER: {
      return {
        ...state,
        [SIGeometryFilterType.SOC]: {...state[SIGeometryFilterType.SOC], ...action.payload},
      };
    }
    case ActionType.SET_GHG_FILTER: {
      return {
        ...state,
        [SIGeometryFilterType.GHG]: {...state[SIGeometryFilterType.GHG], ...action.payload},
      };
    }

    case ActionType.SET_YIELD_FILTER: {
      return {
        ...state,
        [SIGeometryFilterType.Yield]: {...state[SIGeometryFilterType.Yield], ...action.payload},
      };
    }
    case ActionType.SET_EMISSIONS_FACTOR_FILTER: {
      return {
        ...state,
        [SIGeometryFilterType.EmissionsFactor]: {
          ...state[SIGeometryFilterType.EmissionsFactor],
          ...action.payload,
        },
      };
    }

    case ActionType.SET_FERTILIZERS_FILTER: {
      return {
        ...state,
        [SIGeometryFilterType.Fertilizer]: {
          ...state[SIGeometryFilterType.Fertilizer],
          ...action.payload,
        },
      };
    }

    default:
      return state;
  }
};
