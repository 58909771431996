import {t} from 'i18n-utils';
import React from 'react';
import type {ImportSeason} from './index';
import {TextField} from 'react-md';
import {
  FluroAutocomplete,
  FluroDatePicker,
  FluroTableRow,
  FluroTableColumn,
  FluroButton,
} from 'components';
import moment from 'moment';
import {GLOBAL_FORMAT_DATE} from '_constants';
import type {CropType} from 'modules/global/types';
import {useAppSelector} from '_hooks';
import {selectCropTypeLabelByCropId} from 'modules/global/selectors';
type SeasonProps = {
  season: ImportSeason;
  cropTypes: CropType[];
  onChange(id: number, prop: keyof ImportSeason, value: string | number): void;
  onDelete(id: number): void;
};
const SeasonRow = ({season, cropTypes, onChange, onDelete}: SeasonProps) => {
  const {uniqId, variety, sowing_date, harvest_date, farm_name, field_name, harvest_date_error} =
    season;
  const cropTypeInList = cropTypes.find(
    crop => season.crop_type === crop.value || season.crop_type === crop.label
  );
  const cropTypeValue = cropTypeInList ? cropTypeInList.value : 'unknown';
  const cropTypeLabel = useAppSelector(s => selectCropTypeLabelByCropId(s, cropTypeValue));
  const cropErrorMessage = !season.crop_type
    ? ''
    : t({id: 'Couldt recognize crop type crop type'}, {cropType: season.crop_type});
  return (
    <FluroTableRow>
      <FluroTableColumn className={'table-row__field-name'} title={field_name}>
        {field_name}
      </FluroTableColumn>
      <FluroTableColumn className={'table-row__farm-name'} title={farm_name}>
        {farm_name}
      </FluroTableColumn>
      <FluroTableColumn>
        <FluroAutocomplete
          id={`crop-type-${uniqId}`}
          className={'crop-type-autocomplete'}
          label=""
          menuItems={cropTypes}
          value={cropTypeLabel}
          onAutocomplete={value => onChange(uniqId, 'crop_type', String(value))}
          error={Boolean(!cropTypeInList && cropErrorMessage)}
          errorText={cropErrorMessage}
          simplifiedMenu={false}
        />
      </FluroTableColumn>
      <FluroTableColumn className={'table-row__season-variety'}>
        <TextField
          id={`crop-variety-${uniqId}`}
          label=""
          style={{maxWidth: '350px'}}
          value={variety}
          onChange={value => onChange(uniqId, 'variety', value)}
        />
      </FluroTableColumn>
      <FluroTableColumn type={'date'} className={'table-row__date'}>
        <FluroDatePicker
          id={`sowing-date-${uniqId}`}
          hideFormat
          selected={moment(sowing_date)}
          onChange={(val: any) => onChange(uniqId, 'sowing_date', val.format(GLOBAL_FORMAT_DATE))}
          portal
        />
      </FluroTableColumn>
      <FluroTableColumn type={'date'} className={'table-row__date'}>
        <FluroDatePicker
          id={`harvest-date-${uniqId}`}
          hideFormat
          selected={moment(harvest_date)}
          onChange={(val: any) => onChange(uniqId, 'harvest_date', val.format(GLOBAL_FORMAT_DATE))}
          error={harvest_date_error}
          errorText={t({id: 'Must be > 30 days after sowing'})}
          portal
        />
      </FluroTableColumn>
      <FluroTableColumn>
        <FluroButton onClick={() => onDelete(season.uniqId)} icon>
          {t({id: 'delete'})}
        </FluroButton>
      </FluroTableColumn>
    </FluroTableRow>
  );
};

export default SeasonRow;
