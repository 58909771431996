import type {ComponentType} from 'react';
import React from 'react';
import {FluroDialog, InfoBlock, Flex, FluroButton} from 'components';
import colors from '../../../../../variables.scss';
import {ErrorIcon} from '../../../../map/icons';
import {t} from 'i18n-utils';

type Props = {
  onHide: () => void;
  removeField: () => void;
};

const RemoveFieldDialog: ComponentType<Props> = ({onHide, removeField}) => {
  return (
    <FluroDialog
      title={t({id: 'Remove field from program?'})}
      onHide={onHide}
      visible={true}
      id={'remove-field-dialog'}
      className={'remove-field-dialog'}
      width={400}
    >
      <InfoBlock
        icon={<ErrorIcon style={{width: '16px', color: colors['saffron']}} />}
        className={'margin-top-10px margin-bottom-15px'}
        appearance={'warning'}
        color={'warning'}
        title={t({id: 'Remove field from enrollment.'})}
      >
        {t({id: 'You can add this field again in the Fields and Boundaries step.'})}
      </InfoBlock>

      <Flex className={'margin-top-15'} justifyContent={'flex-end'} gap={'10px'}>
        <FluroButton raised blank onClick={onHide}>
          {t({id: 'Cancel'})}
        </FluroButton>
        <FluroButton primary raised onClick={removeField}>
          {t({id: 'BtnLabel.RemoveField', defaultMessage: 'Remove field'})}
        </FluroButton>
      </Flex>
    </FluroDialog>
  );
};

export default RemoveFieldDialog;
