// @ts-nocheck
import {push} from 'connected-react-router';
import {reportError} from 'containers/error-boundary';
import {checkAuth, logout} from 'containers/login/actions';
import {loadFields} from 'containers/map/actions';
import {ActionTypes} from 'containers/map/reducer/types';
import type {Farm} from 'containers/map/types';
import {ActionType as IntegrationsActionType} from 'containers/profile/integration/reducer';
import {t} from 'i18n-utils';
import {
  AsyncStatusType,
  dialogToggle,
  DialogType as HelpersDialogType,
  setRequestStatus,
  Status,
} from 'modules/helpers';
import {normalize} from 'normalizr';
import {compile} from 'path-to-regexp';
import {setGlobalParam} from 'modules/global/actions';
import {showNotification} from 'components/notification/notification';
import {FarmApi} from '_api';
import {isAgxFarm} from '_utils/farm-utils';
import Mixpanel from '_utils/mixpanel-utils';
import type {AppStore} from '../../reducers';
import {FarmSchema} from './normalize-schemas';
import {loadFarmsList} from './reducer';
import {selectFarmsList} from './selectors';
import {FarmActionTypes} from './types';
import {CI_ROOT_PATH} from '_environment';
import {CI_HOME} from 'containers/map/routes';

export const getFarmsList = () => async (dispatch: any) => {
  dispatch(setRequestStatus(AsyncStatusType.farmsList, Status.Pending));

  try {
    const farmsListResponse = await FarmApi.getFarmsList();

    const payload: {farms: any; demoFarms: any; entities: any; result: number[]} = {
      farms: farmsListResponse.data.result,
      demoFarms: {},
      entities: {},
      result: [],
    };
    payload.farms.list = payload.farms.list || [];
    const {entities, result} = normalize(payload.farms.list, [FarmSchema]);
    payload.entities = entities;
    payload.result = result;

    if (payload.farms.list.length) {
      await FarmApi.getDemoFarmsList().then(response => {
        payload.demoFarms = response.data.result;
      });
    }

    const hasAgx = payload.farms.list.some(isAgxFarm);

    dispatch({
      type: IntegrationsActionType.AGX_TOGGLE_FARMS_IMPORTED,
      farmsImported: hasAgx,
    });
    dispatch(loadFarmsList(payload));
    dispatch(setRequestStatus(AsyncStatusType.farmsList, Status.Done));

    return payload.farms.list;
  } catch (err) {
    reportError(`getFarmsList() err = ${err}`);
  }
};

export const getOne =
  (farmId: number, skipLoader = false) =>
  (dispatch: any) => {
    dispatch(setRequestStatus(AsyncStatusType.loadOneFarm, Status.Pending));
    return FarmApi.getFarmById(farmId, skipLoader)
      .then(({data: {result}}) => {
        dispatch(setRequestStatus(AsyncStatusType.loadOneFarm, Status.Done));
        return result;
      })
      .catch(err => {
        dispatch(setRequestStatus(AsyncStatusType.loadOneFarm, Status.Done));
        return err;
      });
  };

export const selectFarm = (farmId: number) => (dispatch: any) => {
  const path = `/:view/:groupId`;
  const view = CI_ROOT_PATH;
  const toPath = compile(path);
  dispatch(setGlobalParam('currentGroupId', farmId));

  try {
    dispatch(
      push(
        toPath({
          view,
          path,
          section: null,
          groupId: '' + farmId,
        }) + window.location.search || ''
      )
    );

    // if it is potato demo farm then go to the WholeFarm first
    return dispatch(loadFields(farmId, farmId === 13367 ? 'WholeFarm' : null));
  } catch (e) {
    return Promise.resolve(false);
  }
};

export const saveFarm =
  (params: Partial<Farm>) => async (dispatch: any, getState: () => AppStore) => {
    const {group} = getState().map;
    const mode = params.id === 0 ? 'create' : 'update';

    return FarmApi[mode === 'create' ? 'saveFarm' : 'updateFarm'](params)
      .then(({data: {result}}) => {
        if (mode === 'create') {
          Mixpanel.addNewFarm(result);
        }

        const payload = normalize(result, FarmSchema);
        dispatch({
          type: mode === 'create' ? FarmActionTypes.CREATE_FARM : FarmActionTypes.UPDATE_FARM,
          farm: result,
          payload,
        });

        // FIXME: not good approach, we just keep value in two place, need to remove it
        if (group.id === result.id) dispatch({type: ActionTypes.MAP_FARM_UPDATE, group: result});
        return result;
      })
      .catch(() => {
        return {};
      });
  };

export const removeFarm =
  (farmId: number, isWorkspaceMrv?: boolean) => (dispatch: any, getState: () => AppStore) => {
    const state = getState();
    const farmsList = selectFarmsList(state);

    return FarmApi.removeFarm(farmId)
      .then(({data: {result}}) => {
        dispatch({type: FarmActionTypes.REMOVE_FARM, farmId});
        dispatch({type: ActionTypes.MAP_FARM_DELETE, farmId});

        dispatch(dialogToggle(HelpersDialogType.deleteDialog, false));
        dispatch(dialogToggle(HelpersDialogType.editFarm, false));

        showNotification({
          title: t({id: 'note.success', defaultMessage: 'Success'}),
          message: t({id: 'Your farm was successfully deleted'}),
          type: 'success',
        });

        if (!isWorkspaceMrv) {
          const nextFarmId = farmsList[0]?.id;
          dispatch(push(nextFarmId ? `/${CI_ROOT_PATH}/${nextFarmId}` : CI_HOME));
          if (!nextFarmId) {
            dispatch(checkAuth());
          }
        }

        return result;
      })
      .catch(e => reportError(`Remove farm error: ${e}`));
  };

export const removeUserFromFarm = (farmId: number, userId: number) => (dispatch: any) => {
  return FarmApi.removeUserFromFarm(farmId, userId)
    .then(() => {
      dispatch({type: FarmActionTypes.RELOAD_FARM});
      showNotification({
        title: t({id: 'note.success', defaultMessage: 'Success'}),
        message: t({id: 'User was deleted'}),
        type: 'success',
      });
    })
    .catch();
};

export const sendInvite = (farmId: number, email: string[]) => (_dispatch: any) => {
  return FarmApi.sendInviteToFarm(farmId, email)
    .then(() => {
      showNotification({
        title: t({id: 'note.success', defaultMessage: 'Success'}),
        message: t({id: 'Invite was sent'}),
        type: 'success',
      });
    })
    .catch();
};

export const acceptFarmInvite = (uuid: string) => (dispatch: any, getState: () => AppStore) => {
  const {login} = getState();

  return FarmApi.acceptFarmInvite(uuid)
    .then(({data: {result}}) => {
      if (result && result.UserID) {
        if (login.isAuth && login.user.id === result.UserID) {
          dispatch(push(`/${CI_ROOT_PATH}/` + result.GroupID + '?noCache'));
        } else {
          dispatch(push('/login?noCache'));
        }

        showNotification({
          title: t({id: 'note.success', defaultMessage: 'Success'}),
          message: t({id: 'Invite was accepted', defaultMessage: 'Invite was accepted'}),
          type: 'success',
        });

        setTimeout(() => window.location.reload(), 1500);
      } else {
        logout()(dispatch).then(() => {
          dispatch(push(`/sign-up?noCache&invite=${uuid}`));

          showNotification({
            title: t({id: 'note.warning', defaultMessage: 'Warning'}),
            message: t({
              id: 'Please create your account first',
              defaultMessage: 'Please create your account first',
            }),
            type: 'warning',
          });
        });
      }
    })
    .catch(() => dispatch(push(`/`)));
};
