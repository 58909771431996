import React from 'react';
import {Route, Switch, Redirect} from 'react-router-dom';
import {useAppSelector} from '_hooks';
import {applyPathParams} from '_utils/pure-utils';
import {MapBar} from 'containers/map/map-bar/map-bar';
import {EmbedLogin} from 'containers/login/embed/embed-login-container';
import {SI_HOME, SI_SUPPLY_SHED_CONFIG, SI_SUPPLY_SHED_ADD} from '../routes';
import {AddSubregion} from './subregion/add-subregion';
import {SupplyShedConfig} from './supply-shed-config';
import {SupplyShedMap} from 'containers/si/map/supply-shed-map';
import {selectCurrentProgram} from 'containers/si/module/selectors';

export const SupplyShed = () => {
  const sessionExpired = useAppSelector(s => s.global.sessionExpired);
  const currentProgram = useAppSelector(selectCurrentProgram);

  return (
    <>
      <SupplyShedMap />
      {sessionExpired && <EmbedLogin id="session-expired-embed-login" width={400} visible />}
      <MapBar hideExpand>
        <div className="section-container si-right-panel-section-container">
          <Switch>
            <Route path={SI_SUPPLY_SHED_ADD} component={AddSubregion} />
            <Route path={SI_SUPPLY_SHED_CONFIG} component={SupplyShedConfig} />
            <Route
              path={SI_SUPPLY_SHED_CONFIG}
              component={() =>
                currentProgram ? (
                  <Redirect
                    to={applyPathParams(SI_SUPPLY_SHED_CONFIG, {programId: currentProgram})}
                  />
                ) : (
                  <Redirect to={SI_HOME} />
                )
              }
            />
          </Switch>
        </div>
      </MapBar>
    </>
  );
};
