import {Flex, Text} from 'components';
import {ChromaScale} from 'components/chroma-scale/chroma-scale';
import type {ComponentType} from 'react';
import React from 'react';
import {useAppSelector} from '_hooks';
import {valueFormatter} from '../filters-panel/cards/utils';
import './filter-status.scss';

import {
  selectAreaUnits,
  selectCropColorScale,
  selectCoverCropsChartTab,
  selectCropStatsExtrema,
  selectIsYearsDiff,
  selectYearsFilter,
  selectAreaUnitsLabel,
} from 'modules/sustainability-insights/selectors';

export const SICoverCropsFilterStatus: ComponentType<{}> = () => {
  const {colors, domain} = useAppSelector(selectCropColorScale);
  const units = useAppSelector(selectAreaUnits);
  const unitsLabel = useAppSelector(selectAreaUnitsLabel);
  const {min, max} = useAppSelector(selectCropStatsExtrema);
  const isDiff = useAppSelector(selectIsYearsDiff);
  const years = useAppSelector(selectYearsFilter);
  const chartTab = useAppSelector(selectCoverCropsChartTab);

  if (!years?.length || !Number.isFinite(min) || !Number.isFinite(max)) return null;
  if (chartTab !== 'summary') return null;

  const [yearFrom, yearTo] = years;

  return (
    <>
      <Flex alignItems="center">
        <Text className={`mb-0 mr-2 ${isDiff ? 'is-diff-long' : 'is-diff-short'}`}>
          {isDiff ? (
            <>
              Change in cover crops {yearFrom} - {yearTo} ({unitsLabel})
            </>
          ) : (
            <>
              Cover crops in {yearFrom} ({unitsLabel})
            </>
          )}
        </Text>
        <ChromaScale
          min={valueFormatter(min, units, true)}
          max={valueFormatter(max, units, true)}
          colors={colors}
          domain={domain}
        />
      </Flex>
    </>
  );
};
