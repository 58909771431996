import React, {useEffect, useState} from 'react';
import {CircularProgress} from 'react-md';
import {Link} from 'react-router-dom';
import {push} from 'connected-react-router';
import {useAppDispatch, useAppSelector} from '_hooks';
import {useParsedMatchParams} from '_hooks/use-parsed-match-params';
import {applyPathParams} from '_utils/pure-utils';
import {SI_SUPPLY_SHED_CONFIG} from 'containers/si/routes';
import {Flex, FluroButton, Text} from 'components';
import {showNotification} from 'components/notification/notification';
import {FluroInput} from 'components/fluro-form-components';
import {CropDropdown} from './crop-dropdown';
import {SubsectionCard} from '../components/subsection-card';
import {ActionType} from 'containers/si/module/types';
import type {CommodityItem} from 'containers/si/api/apiTypes';
import type {SICropTypeOptions, SISubsectionInput} from 'containers/si/types';
import {fetchSISupplySheds, addSISubsection} from 'containers/si/module/thunks';
import {
  setInteractionType,
  setProcessing,
  setProgramGeometries,
  setShouldShowMapTools,
  setShowProgramGeometries,
  setSubsectionGeometries,
  setSubsectionSelectedFeatures,
} from 'containers/si/module/reducer';
import {selectIsLoading} from 'modules/helpers/selectors';
import {
  selectInteractionType,
  selectSISupplySheds,
  selectSISupplyShedsBySupplyShedId,
  selectSubsectionGeometries,
  selectSubsectionSelectedFeatures,
} from 'containers/si/module/selectors';
import {defaultSISubsection} from 'containers/si/module/base';
import {UploadBoundariesPopup} from 'containers/si/map/upload-boundaries-popup';
import {useFetchGeometries} from 'containers/si/hooks/useFetchGeometries';

export const AddSubregion = () => {
  const dispatch = useAppDispatch();
  const {programId, supplyShedId} = useParsedMatchParams();
  const supplySheds = useAppSelector(selectSISupplySheds);
  const supplyShed = useAppSelector(s => selectSISupplyShedsBySupplyShedId(s, supplyShedId));
  const subsectionSelectedFeatures = useAppSelector(selectSubsectionSelectedFeatures);
  const subsectionGeometries = useAppSelector(selectSubsectionGeometries);
  const interactionType = useAppSelector(selectInteractionType);

  const [newSubsectionName, setNewSubsectionName] = useState('');
  const [commodities, setCommodities] = useState<CommodityItem[]>([]);
  const [openUploadPopup, setOpenUploadPopup] = useState(false);
  const isLoadingSupplySheds = useAppSelector(s =>
    selectIsLoading(s, [ActionType.FETCH_SI_SUPPLY_SHEDS_BY_ID])
  );

  const {isLoading: isLoadingGeometries, allGeometryFeatures} = useFetchGeometries('supply-shed');

  useEffect(() => {
    dispatch(setShouldShowMapTools(true));
    dispatch(setInteractionType('select'));
    if (!supplySheds[programId]) {
      dispatch(fetchSISupplySheds(programId));
    }
  }, [dispatch, programId, supplyShedId, supplySheds]);

  useEffect(() => {
    if (interactionType === 'upload') {
      setOpenUploadPopup(true);
    }
  }, [dispatch, interactionType]);

  useEffect(() => {
    // Generate the ProgramGeometries to display the Supply Shed on the map
    dispatch(setProgramGeometries(allGeometryFeatures));
    dispatch(setShowProgramGeometries(true));
  }, [dispatch, allGeometryFeatures]);

  const onChangeCrop = (selectedCropList: SICropTypeOptions[]) => {
    const selectedCommodities: CommodityItem[] = [];

    selectedCropList.map(crop => {
      return selectedCommodities.push({
        id: Number(crop.value),
        volume: 0,
      });
    });

    setCommodities(selectedCommodities);
  };

  const createSubsection = async () => {
    // Combine the selected Geometries and Features
    const combinedGeometries: NonNullable<SISubsectionInput['geometries']> = [];
    const combinedFeatures: NonNullable<SISubsectionInput['selected_features']> = [];

    Object.values(subsectionGeometries).forEach(
      (geometry: GeoJSON.Feature<GeoJSON.Polygon | GeoJSON.MultiPolygon>) => {
        combinedGeometries.push(geometry);
      }
    );
    Object.values(subsectionSelectedFeatures).forEach((feature: any) => {
      combinedFeatures.push({
        feature_id: feature.properties.id || feature.properties.FIPS,
        collection_id: feature.properties.collection_id || feature.properties.layer,
        feature_name:
          feature.properties.feature_name || feature.properties.title || feature.properties.name,
      });
    });

    const newSubsection = {
      ...defaultSISubsection,
      supply_shed_id: supplyShedId,
      name: newSubsectionName,
      commodities,
    };
    if (combinedGeometries.length > 0) newSubsection['geometries'] = combinedGeometries;
    if (combinedFeatures.length > 0) newSubsection['selected_features'] = combinedFeatures;

    dispatch(addSISubsection({programId, subsection: newSubsection}))
      // @ts-expect-error error leftover from convertion to strict mode, please fix
      .unwrap()
      .then(() => {
        // clear out selected features and geometries
        dispatch(setSubsectionGeometries({}));
        dispatch(setSubsectionSelectedFeatures({}));

        // Set processing for the processing popup
        dispatch(setProcessing(true));

        // After save push to the Supply Shed Config page
        dispatch(push(applyPathParams(SI_SUPPLY_SHED_CONFIG, {programId, supplyShedId})));
      })
      .catch(() => {
        showNotification({
          type: 'error',
          title: 'Error',
          message: 'There was an error creating the subsection.',
        });
      });
  };

  return (
    <>
      <div className={'subregion-header'}>
        <Flex direction="column">
          <Flex alignItems="center">
            <Text variant="h1" className="mt-2 margin-bottom-8">
              Add Subregion
            </Text>

            {(isLoadingSupplySheds || isLoadingGeometries) && (
              <CircularProgress
                className="mt-0 mb-0 ml-05 mr-0"
                scale={0.7}
                id="map-bar-header-progress"
              />
            )}
          </Flex>
          {!isLoadingSupplySheds && !isLoadingGeometries && (
            <Text variant="h3" className="mb-0">
              {supplyShed?.name}
            </Text>
          )}
        </Flex>
      </div>
      <div className="divider" />
      <Flex
        className={'margin-top-15 margin-bottom-10'}
        fullWidth
        justifyContent={'center'}
        direction="column"
      >
        <Text>Name</Text>
        <FluroInput
          type="text"
          id="subsection-name"
          name="subsection-name"
          value={newSubsectionName}
          className="mt-0"
          onChange={p => setNewSubsectionName(String(p))}
        />
        <Text className="mt-1">Crops</Text>
        <CropDropdown onChange={onChangeCrop} />
      </Flex>
      <div className="divider" />
      {supplyShed && <SubsectionCard color={supplyShed.color || '#88DA9F'} />}

      {isLoadingSupplySheds || isLoadingGeometries ? (
        <CircularProgress className={'progress'} id="loading-supply-sheds" />
      ) : (
        <Flex direction="column" justifyContent="flex-end" className="subregion-buttons-container">
          <div className="divider" />
          <Flex
            className={'margin-top-10'}
            fullWidth
            justifyContent={'space-between'}
            gap={'10px'}
            nowrap
          >
            <FluroButton
              id={'cancel-add-subregion'}
              component={Link}
              secondary
              raised
              className="element-full-width"
              to={{
                pathname: applyPathParams(SI_SUPPLY_SHED_CONFIG, {programId: programId}),
              }}
              onClick={() => {
                dispatch(setSubsectionGeometries({}));
                dispatch(setSubsectionSelectedFeatures({}));
              }}
            >
              Cancel
            </FluroButton>

            <FluroButton
              id={'save-subregion'}
              raised
              primary
              className="element-full-width"
              // disabled if name missing or no crops or no geometries
              disabled={
                !newSubsectionName ||
                !commodities.length ||
                (Object.keys(subsectionGeometries).length === 0 &&
                  Object.keys(subsectionSelectedFeatures).length === 0)
              }
              onClick={createSubsection}
            >
              Save
            </FluroButton>
          </Flex>
        </Flex>
      )}
      {openUploadPopup && (
        <UploadBoundariesPopup
          onHide={() => {
            dispatch(setInteractionType('select'));
            setOpenUploadPopup(false);
          }}
        />
      )}
    </>
  );
};
