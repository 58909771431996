import type {FluroChipProps} from 'components/fluro-chip/fluro-chip';
import {FluroChip} from 'components/fluro-chip/fluro-chip';
import type {ComponentType, ReactNode} from 'react';
import React, {useState} from 'react';
import {FontIcon} from 'react-md';
import {FluroDropdown} from './fluro-dropdown';

type ControllableProps = {
  active?: boolean;
  className?: string;
  disabled?: boolean;
  expand: boolean;
  label: ReactNode;
  dropdownStyle?: React.CSSProperties;
  clearText?: string;
  onClear?: () => void;
  setExpand: (expand: boolean) => void;
};

export const FluroChipDropdownControllable: ComponentType<
  ControllableProps & Partial<FluroChipProps>
> = ({
  active,
  children,
  className,
  clearText,
  disabled,
  dropdownStyle,
  expand,
  label,
  onClear,
  setExpand,
  ...props
}) => {
  const isActive = active || expand;
  const allowClear = typeof onClear === 'function';

  const ChipButton = ({onClick}: {onClick: () => void}) => {
    const clearIcon = allowClear ? (
      <span
        className="filter-clear-button"
        role="button"
        onClick={onClear}
        title={clearText || 'Clear selection'}
      >
        <FontIcon>clear</FontIcon>
      </span>
    ) : null;

    return (
      <FluroChip
        active={isActive}
        disabled={disabled}
        dropdown={allowClear ? !active : true}
        rightIcon={active ? clearIcon : undefined}
        label={label}
        onClick={onClick}
        {...props}
      />
    );
  };

  return (
    <FluroDropdown
      className={className}
      expand={expand}
      Button={ChipButton}
      dropdownStyle={dropdownStyle}
      onExpand={() => setExpand(true)}
      onClose={() => setExpand(false)}
    >
      {children}
    </FluroDropdown>
  );
};

type Props = Omit<ControllableProps, 'expand' | 'setExpand'> & {
  defaultExpand?: boolean;
};

export const FluroChipDropdown: ComponentType<Props> = ({defaultExpand = false, ...props}) => {
  const [expand, setExpand] = useState(defaultExpand);

  return <FluroChipDropdownControllable expand={expand} setExpand={setExpand} {...props} />;
};
