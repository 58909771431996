import type {PayloadAction} from '@reduxjs/toolkit';
import {createSlice} from '@reduxjs/toolkit';
import {mergeEntities} from 'modules/redux-utils/merge-entities';
import {toggle} from '_utils/pure-utils';
import {
  fetchPlanningReports,
  addPlanningReport,
  updatePlanningReport,
  updatePlanningReportURL,
  fetchAPReportScenarios,
  fetchPPReportScenarios,
} from '../thunks';
import {LoginActionTypes} from 'containers/login/types';
import type {SI2State} from '../types';
import type {
  ReportSelectedSupplyShedCrops,
  Intervention,
  SIScenarioAP,
  SIScenarioPP,
  SIPracticeAdoption,
} from 'containers/si/types';
import type {Model, ReportType, Protocol} from 'containers/si/api/apiTypes';
import {SIScenarioKPIs} from 'containers/si/types';
import type {SIAreaUnits} from 'modules/sustainability-insights/types';

export const reportsSlice = createSlice({
  name: 'si/reports',
  initialState: {
    planningReports: {},
    new: {
      programId: null,
      name: '',
      startYear: new Date().getFullYear() - 5,
      endYear: new Date().getFullYear(),
      reportType: 'abatement_potential',
      model: 'dndc',
      protocol: 'vm0042',
      areas: {},
      crops: {},
      interventions: [],
      cropStepBlocked: true,
    },
    data: {},
    dashboard: {
      programId: null,
      reportId: null,
      reportType: 'abatement_potential',
      pageIndex: 0,
      pageSize: 5,
      orderBy: SIScenarioKPIs.net_ghg,
      orderDirection: 'desc',
      onlyTopScenarios: false,
      dataUnits: 'num',
      cropFilter: [],
      sourcingRegionFilter: [],
      interventionFilter: [],
      showIneligible: false,
      carbonPrice: 2000,
      carbonUnit: 'usd',
      selectedScenario: {
        crop_id: null,
        scenario_id: null,
      },
      adoption: {
        reduce_till: 20,
        no_till: 20,
        cover_crop: 20,
        reduce_nitrogen: 20,
      },
    },
  } as SI2State['reports'],
  reducers: {
    setNewProgramId(state, action: PayloadAction<number>) {
      state.new.programId = action.payload;
    },
    setNewName(state, action: PayloadAction<string>) {
      state.new.name = action.payload;
    },
    setNewStartYear(state, action: PayloadAction<number>) {
      state.new.startYear = action.payload;
    },
    setNewEndYear(state, action: PayloadAction<number>) {
      state.new.endYear = action.payload;
    },
    setNewReportType(state, action: PayloadAction<ReportType>) {
      state.new.reportType = action.payload;
    },
    setNewModel(state, action: PayloadAction<Model>) {
      state.new.model = action.payload;
    },
    setNewProtocol(state, action: PayloadAction<Protocol>) {
      state.new.protocol = action.payload;
    },
    setNewAreas(state, action: PayloadAction<Record<number, number[]>>) {
      state.new.areas = action.payload;
    },
    setNewCrops(state, action: PayloadAction<ReportSelectedSupplyShedCrops>) {
      state.new.crops = action.payload;
    },
    setCropStepBlocked(state, action: PayloadAction<boolean>) {
      state.new.cropStepBlocked = action.payload;
    },
    setNewInterventions(state, action: PayloadAction<Intervention[]>) {
      state.new.interventions = action.payload;
    },
    // Dashboard
    setDashboardProgramId(state, action: PayloadAction<number>) {
      state.dashboard.programId = action.payload;
    },
    setDashboardReportId(state, action: PayloadAction<number>) {
      state.dashboard.reportId = action.payload;
    },
    setDashboardReportType(state, action: PayloadAction<ReportType>) {
      state.dashboard.reportType = action.payload;
    },
    setDashboardPageIndex(state, action: PayloadAction<number>) {
      state.dashboard.pageIndex = action.payload;
    },
    setDashboardOrderBy(state, action: PayloadAction<SIScenarioKPIs>) {
      state.dashboard.orderBy = action.payload;
    },
    setDashboardOrderDirection(state, action: PayloadAction<'asc' | 'desc'>) {
      state.dashboard.orderDirection = action.payload;
    },
    setDashboardTopScenarios(state, action: PayloadAction<boolean>) {
      state.dashboard.onlyTopScenarios = action.payload;
    },
    setDashboardDataUnits(state, action: PayloadAction<SIAreaUnits>) {
      state.dashboard.dataUnits = action.payload;
    },
    setDashboardCropFilter(state, action: PayloadAction<number[]>) {
      state.dashboard.cropFilter = action.payload;
    },
    toggleDashboardCropFilter(state, action: PayloadAction<number>) {
      state.dashboard.cropFilter = toggle(state.dashboard.cropFilter, action.payload);
    },
    setDashboardSourcingRegionFilter(state, action: PayloadAction<string[]>) {
      state.dashboard.sourcingRegionFilter = action.payload;
    },
    toggleDashboardSourcingRegionFilter(state, action: PayloadAction<string>) {
      state.dashboard.sourcingRegionFilter = toggle(
        state.dashboard.sourcingRegionFilter,
        action.payload
      );
    },
    setDashboardInterventionFilter(state, action: PayloadAction<number[]>) {
      state.dashboard.interventionFilter = action.payload;
    },
    toggleDashboardInterventionFilter(state, action: PayloadAction<number>) {
      state.dashboard.interventionFilter = toggle(
        state.dashboard.interventionFilter,
        action.payload
      );
    },
    setDashboardShowIneligible(state, action: PayloadAction<boolean>) {
      state.dashboard.showIneligible = action.payload;
    },
    setDashboardCarbonPrice(state, action: PayloadAction<number>) {
      state.dashboard.carbonPrice = action.payload;
    },
    setDashboardCarbonUnit(state, action: PayloadAction<string>) {
      state.dashboard.carbonUnit = action.payload;
    },
    setDashboardSelectedScenario(state, action: PayloadAction<{crop_id: null; scenario_id: null}>) {
      state.dashboard.selectedScenario.crop_id = action.payload.crop_id;
      state.dashboard.selectedScenario.scenario_id = action.payload.scenario_id;
    },
    setDashboardPracticeAdoption(state, action: PayloadAction<SIPracticeAdoption>) {
      state.dashboard.adoption = action.payload;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(LoginActionTypes.LOGOUT, state => {
        return {
          ...state,
          planningReports: {},
          new: {
            programId: null,
            name: '',
            startYear: new Date().getFullYear() - 5,
            endYear: new Date().getFullYear(),
            reportType: 'abatement_potential',
            model: 'dndc',
            protocol: 'vm0042',
            areas: {},
            crops: {},
            interventions: [],
            cropStepBlocked: true,
          },
          data: {},
          dashboard: {
            programId: null,
            reportId: null,
            reportType: 'abatement_potential',
            pageIndex: 0,
            pageSize: 5,
            orderBy: SIScenarioKPIs.net_ghg,
            orderDirection: 'desc',
            onlyTopScenarios: false,
            dataUnits: 'num',
            cropFilter: [],
            sourcingRegionFilter: [],
            interventionFilter: [],
            showIneligible: false,
            carbonPrice: 2000,
            carbonUnit: 'usd',
            selectedScenario: {
              crop_id: null,
              scenario_id: null,
            },
            adoption: {
              reduce_till: 20,
              no_till: 20,
              cover_crop: 20,
              reduce_nitrogen: 20,
            },
          },
        };
      })
      .addCase<
        string,
        PayloadAction<{scenarios: SIScenarioAP[]; programId: number; reportId: number}>
      >(fetchAPReportScenarios.fulfilled.type, (state, action) => {
        // Abatement Potential Report Scenarios
        const {scenarios, programId, reportId} = action.payload;

        // get all unique crop_type from the scenarios and set to dashboard.cropFilter
        const cropTypes = scenarios.reduce((acc, scenario) => {
          const cropType = scenario.crop_type;
          if (!acc.includes(cropType)) {
            acc.push(cropType);
          }
          return acc;
        }, [] as number[]);

        // get all unique sourcing_region from the scenarios and set to dashboard.sourcingRegionFilter
        const sourcingRegionIds = scenarios.reduce((acc, scenario) => {
          const region = `${scenario.sourcing_region_id.unit_id}+++${scenario.sourcing_region_id.unit_type}`;
          if (!acc.includes(region)) {
            acc.push(region);
          }
          return acc;
        }, [] as string[]);

        // get all unique scenario_id from the scenarios and set to dashboard.interventionFilter
        const interventionIds = scenarios.reduce((acc, scenario) => {
          const interventionId = scenario.scenario_id;
          if (!acc.includes(interventionId)) {
            acc.push(interventionId);
          }
          return acc;
        }, [] as number[]);

        return {
          ...state,
          data: {
            ...state.data,
            [reportId]: scenarios,
          },
          dashboard: {
            ...state.dashboard,
            programId,
            reportId,
            reportType: 'abatement_potential',
            cropFilter: cropTypes,
            sourcingRegionFilter: sourcingRegionIds,
            interventionFilter: interventionIds,
            recordCount: scenarios.length,
          },
        };
      })
      .addCase<
        string,
        PayloadAction<{scenarios: SIScenarioPP[]; programId: number; reportId: number}>
      >(fetchPPReportScenarios.fulfilled.type, (state, action) => {
        // Program Plan Report Scenarios
        const {scenarios, programId, reportId} = action.payload;

        // get all unique crop_type from the scenarios and set to dashboard.cropFilter
        const cropTypes = scenarios.reduce((acc, scenario) => {
          const cropType = scenario.crop_type;
          if (!acc.includes(cropType)) {
            acc.push(cropType);
          }
          return acc;
        }, [] as number[]);

        // get all unique sourcing_region from the scenarios and set to dashboard.sourcingRegionFilter
        const sourcingRegionIds = scenarios.reduce((acc, scenario) => {
          const region = `${scenario.geography.feature_id}+++${scenario.geography.collection_id}`;
          if (!acc.includes(region)) {
            acc.push(region);
          }
          return acc;
        }, [] as string[]);

        // get all unique scenario_id from the scenarios and set to dashboard.interventionFilter
        const interventionIds = scenarios.reduce((acc, scenario) => {
          const interventionId = scenario.scenario_id;
          if (!acc.includes(interventionId)) {
            acc.push(interventionId);
          }
          return acc;
        }, [] as number[]);

        return {
          ...state,
          data: {
            ...state.data,
            [reportId]: scenarios,
          },
          dashboard: {
            ...state.dashboard,
            programId,
            reportId,
            reportType: 'program_plan',
            cropFilter: cropTypes,
            sourcingRegionFilter: sourcingRegionIds,
            interventionFilter: interventionIds,
          },
        };
      })
      .addMatcher(
        action =>
          [
            fetchPlanningReports.fulfilled.type,
            addPlanningReport.fulfilled.type,
            updatePlanningReport.fulfilled.type,
            updatePlanningReportURL.fulfilled.type,
          ].includes(action.type),
        (state, action) => {
          return mergeEntities(state, action.payload.reports);
        }
      );
  },
});
