// @ts-nocheck
import type {Serie} from '@nivo/line';
import chroma from 'chroma-js';
import groupBy from 'lodash/groupBy';
import {createSelector} from 'reselect';
import {sortByKeyReverseUnsafe} from '_utils/sorters';
import {calculatePercentage} from '_utils/pure-utils';
import {selectStatesMeta} from '.';
import type {AppStore} from '../../../reducers';
import {SUMMARY_BARS_PREVIEW_THRESHOLD, tillageColors, tillagePracticeLabel} from '../constants';
import type {
  AreaId,
  SIAreaUnits,
  SIStateChildMeta,
  SummaryChartData,
  SummaryChartDatum,
  TillageStats,
} from '../types';
import {TillagePractice} from '../types';
import {
  calcSummaryChartExtrema,
  calculateScale,
  isInRange,
  isYearsDiff,
  parseAreaId,
} from '../utils';
import {
  getAreaId,
  selectActiveGeometriesIds,
  selectAggLevel,
  selectAreaUnits,
  selectCurrentMeta,
  selectGeometriesColors,
  selectIsYearsDiff,
  selectMetrics,
  selectSIChartGroupingType,
  selectSIFilter,
  selectVisibleGeometriesIds,
  selectYearsFilter,
  selectYearsFilterRange,
} from './selectors';

const TillagePracticesToProcess = [
  TillagePractice.Conventional,
  TillagePractice.Reduced,
  TillagePractice.NoTill,
];

/**
 * Main selectors
 */
export const selectTillageCardState = createSelector([selectSIFilter], f => f.tillage);

export const selectTillageCardRange = createSelector([selectTillageCardState], cc => cc.range);

export const selectTillageChartTab = createSelector([selectTillageCardState], cc => cc.chartTab);

export const selectSelectedTillagePractice = createSelector(
  [selectTillageCardState],
  card => card.selectedPractice
);

export const selectSelectedTillagePracticeLabel = createSelector(
  [selectSelectedTillagePractice],
  selectedPractice => tillagePracticeLabel[selectedPractice]
);

/**
 * Stats */
export const selectTillageStats = createSelector(
  [selectVisibleGeometriesIds, selectMetrics, selectYearsFilterRange],
  (visibleIds, metrics, years) => {
    if (!visibleIds || !years) return null;

    const stats: TillageStats = {};

    years.forEach(year => {
      stats[year] = {};
      visibleIds.forEach(id => {
        if (!stats[year][id]) {
          stats[year][id] = {
            [TillagePractice.Conventional]: {num: 0, pct: 0, total: 0},
            [TillagePractice.Reduced]: {num: 0, pct: 0, total: 0},
            [TillagePractice.NoTill]: {num: 0, pct: 0, total: 0},
          };
        }
        TillagePracticesToProcess.forEach(tillagePractice => {
          const tillagePracticeValue = metrics?.[id]?.[year]?.tillage?.[tillagePractice];
          const totalArableAreaValue = metrics?.[id]?.[year]?.total_arable_area_ac;
          if (!tillagePracticeValue || !totalArableAreaValue) return;

          stats[year][id][tillagePractice].num = tillagePracticeValue;
          stats[year][id][tillagePractice].pct = calculatePercentage(
            // calc percentage value
            tillagePracticeValue,
            totalArableAreaValue
          );
          stats[year][id][tillagePractice].total = totalArableAreaValue;
        });
      });
    });

    return stats;
  }
);

const summaryDiffStrategy = (
  stats: TillageStats,
  years: number[],
  tillagePractice: TillagePractice,
  areaUnit: SIAreaUnits,
  id: AreaId
) => {
  const [yearFrom, yearTo] = years;

  const yearFromValue = stats?.[yearFrom]?.[id]?.[tillagePractice]?.[areaUnit] || 0;
  const yearToValue = stats?.[yearTo]?.[id]?.[tillagePractice]?.[areaUnit] || 0;

  return yearToValue - yearFromValue;
};

const summarySingleStrategy = (
  stats: TillageStats,
  year: number,
  tillagePractice: TillagePractice,
  areaUnit: SIAreaUnits,
  id: AreaId
) => {
  return stats?.[year]?.[id]?.[tillagePractice]?.[areaUnit] || 0;
};

/**
 * Summary chart */

type TillageSummaryChartData = SummaryChartData & {
  minValue: number;
  maxValue: number;
  values: number[];
  valuesById?: Record<
    AreaId,
    {
      area: number;
      value: number;
      name: string;
      totalArableArea?: number;
      numFrom?: number;
      totalFrom?: number;
      numTo?: number;
      totalTo?: number;
    }
  >;
};
export const selectTillageChartSummaryData = createSelector(
  [
    selectVisibleGeometriesIds,
    selectActiveGeometriesIds,
    selectTillageStats,
    selectSelectedTillagePractice,
    selectAggLevel,
    selectYearsFilter,
    selectAreaUnits,
    selectGeometriesColors,
    selectTillageCardRange,
    selectMetrics,
    selectCurrentMeta,
    selectStatesMeta,
    selectSIChartGroupingType,
  ],
  (
    visibleIds,
    activeIds,
    tillageStats,
    selectedPractice,
    aggLevel,
    years,
    areaUnits,
    geometryColors,
    range,
    metrics,
    meta,
    statesMeta,
    groupingType
  ): TillageSummaryChartData => {
    if (!activeIds?.length || !years?.length || !tillageStats) return null;

    const valuesById: TillageSummaryChartData['valuesById'] = {};
    const allValues: number[] = [];
    const isDiff = isYearsDiff(years);
    let totalArea = 0;

    // Apply filters for chart data
    const preview = activeIds.length > SUMMARY_BARS_PREVIEW_THRESHOLD;

    let chartDatums: SummaryChartDatum[] = sortByKeyReverseUnsafe(
      visibleIds // loop through all geometries, but left only active
        .reduce((acc, id) => {
          const areaObj = meta[id] as SIStateChildMeta;
          const value = isDiff
            ? summaryDiffStrategy(tillageStats, years, selectedPractice, areaUnits, id)
            : summarySingleStrategy(tillageStats, years[0], selectedPractice, areaUnits, id);

          if (!areaObj || !value) return acc; // filter no values
          allValues.push(value);
          if (!activeIds.includes(id)) return acc;
          if (preview && range && !isInRange(value, range)) return acc;

          const [yearFrom, yearTo] = years;
          const area =
            summarySingleStrategy(tillageStats, yearFrom, selectedPractice, 'num', id) || 0; // we need to calc total area for message above the chart
          const totalArableArea = isDiff ? 0 : metrics?.[id]?.[yearFrom]?.total_arable_area_ac || 0; // we need total arable area only for map tooltips
          const numFrom = metrics?.[id]?.[yearFrom]?.tillage[selectedPractice];
          const totalFrom = metrics?.[id]?.[yearFrom]?.total_arable_area_ac;
          const numTo = metrics?.[id]?.[yearTo]?.tillage[selectedPractice];
          const totalTo = metrics?.[id]?.[yearTo]?.total_arable_area_ac;

          valuesById[id] = {
            ...areaObj,
            value,
            area,
            totalArableArea,
            numFrom,
            totalFrom,
            numTo,
            totalTo,
          };

          if (!isDiff) {
            totalArea = totalArea + totalArableArea;
          }

          const nextObj = {
            ...areaObj,
            value,
            color: geometryColors[id],
          };

          return [...acc, nextObj];
        }, []),
      'value'
    );

    if (groupingType === 'grouped') {
      const groupedByState = groupBy(chartDatums, 'statefp');
      chartDatums = sortByKeyReverseUnsafe(
        Object.keys(groupedByState).map(stateFP => {
          const data = groupedByState[stateFP];

          let value = 0;

          const diffNumReducer = (acc: any, {id}: any) => {
            const {numFrom, numTo} = valuesById[id];

            return {from: acc.from + numFrom, to: acc.to + numTo};
          };
          const diffPctReducer = (acc: any, {id}: any) => {
            const {numFrom, totalFrom, numTo, totalTo} = valuesById[id];

            return {
              from: acc.from + numFrom,
              totalFrom: acc.totalFrom + totalFrom,
              to: acc.to + numTo,
              totalTo: acc.totalTo + totalTo,
            };
          };
          const singleNumReducer = (acc: any, {id}: any) => {
            const {area} = valuesById[id];

            return acc + area;
          };
          const singlePctReducer = (acc: any, {id}: any) => {
            const {area, totalArableArea} = valuesById[id];

            return {num: acc.num + area, total: acc.total + totalArableArea};
          };

          if (isDiff) {
            if (areaUnits === 'num') {
              const {from, to} = data.reduce(diffNumReducer, {from: 0, to: 0});
              value = to - from;
            } else if (areaUnits === 'pct') {
              const {from, to, totalFrom, totalTo} = data.reduce(diffPctReducer, {
                from: 0,
                to: 0,
                totalFrom: 0,
                totalTo: 0,
              });

              value = calculatePercentage(to, totalTo) - calculatePercentage(from, totalFrom);
            }
          } else {
            if (areaUnits === 'num') {
              value = data.reduce(singleNumReducer, 0);
            } else if (areaUnits === 'pct') {
              const {num, total} = data.reduce(singlePctReducer, {num: 0, total: 0});
              value = calculatePercentage(num, total);
            }
          }

          const stateMeta = statesMeta[parseAreaId(stateFP)];
          const name = stateMeta?.name?.toUpperCase();

          return {
            name,
            data,
            value,
          };
        }),
        'value'
      );
    }

    const {minValue, maxValue} = calcSummaryChartExtrema(allValues);
    const tillageChartData: TillageSummaryChartData = {
      type: groupingType,
      chartData: chartDatums,
      preview,
      minValue,
      maxValue,
      valuesById,
      values: allValues,
      totalValue: totalArea,
    };

    return tillageChartData;
  }
);

/** Trends chart */

export const selectTillageTrendChartData = createSelector(
  [
    selectTillageStats,
    selectActiveGeometriesIds,
    selectAreaUnits,
    selectGeometriesColors,
    selectSelectedTillagePractice,
    selectCurrentMeta,
  ],
  (stats, activeIds, units, colors, selectedPractice, currentMeta) => {
    if (!stats || !activeIds) return [];
    const chartData: Serie[] = [];

    activeIds.forEach(areaId => {
      const singleAreaData: Serie = {
        id: areaId,
        color: colors[areaId],
        data: [],
        name: currentMeta?.[areaId]?.name || '',
      };
      Object.keys(stats).forEach(year => {
        const value = stats?.[year]?.[areaId]?.[selectedPractice]?.[units];
        if (!value) return null;
        singleAreaData.data.push({x: year, y: value});
      });
      if (singleAreaData.data.length) {
        chartData.push(singleAreaData);
      }
    });

    return chartData;
  }
);

export const selectTillageExtrema = createSelector([selectTillageChartSummaryData], chartData => {
  const values = chartData?.valuesById && Object.values(chartData.valuesById);
  if (!values?.length)
    return {
      min: Infinity,
      max: -Infinity,
    };

  const {minValue, maxValue} = calcSummaryChartExtrema(
    values.map(({value}) => value) // use only active values for calculating scale
  );

  return {
    min: minValue,
    max: maxValue,
  };
});

export const getTillageAreaValuesById = createSelector(
  [selectTillageChartSummaryData, getAreaId],
  (chartData, areaId: AreaId) => chartData?.valuesById?.[areaId]
);

export const isGeometryInTillageSummaryChart = createSelector(
  [selectTillageChartSummaryData, getAreaId],
  (chartData, id) => chartData?.valuesById?.[id] !== undefined
);

/**
 * Filter scale Start */

export const selectTillageColorScaleValues = createSelector(
  [selectTillageExtrema, selectSelectedTillagePractice],
  ({min, max}, selectedTillage) => {
    if (max <= 0) {
      return {colors: [tillageColors.min, tillageColors.mid]};
    } else if (min >= 0) {
      return {colors: [tillageColors.mid, tillageColors[selectedTillage]]};
    }

    const zero = calculateScale(0, min, max);

    return {
      colors: [tillageColors.min, tillageColors.mid, tillageColors[selectedTillage]],
      domain: [0, zero, 1],
    };
  }
);

export const selectTillageColorScale = createSelector(
  [selectIsYearsDiff, selectTillageColorScaleValues, selectSelectedTillagePractice],
  (isDiff, {colors, domain}, selectedTillage): {colors: string[]; domain?: any} => {
    if (!isDiff) return {colors: [tillageColors.mid, tillageColors[selectedTillage]]};
    return {colors, domain};
  }
);

export const selectTillageChromaScale = createSelector(
  selectTillageColorScale,
  ({colors, domain}) => {
    const scale = chroma.scale(colors);
    if (domain) {
      scale.domain(domain);
    }

    return scale;
  }
);

/**
 * Filter scale End */

/**
 * Tillage geometry Start */

export const selectTillageGeometryColorById = (store: AppStore, id: AreaId) => {
  const tab = selectTillageChartTab(store);
  if (tab === 'trend') return selectTillageTrendGeometryColorById(store, id);

  return selectTillageChartGeometryColorById(store, id);
};

export const selectTillageChartGeometryColorById = createSelector(
  [selectTillageExtrema, getTillageAreaValuesById, selectTillageChromaScale],
  ({max, min}, values, colorScale) => {
    const scale = calculateScale(values?.value, min, max);
    const color = colorScale(scale).css();

    return color;
  }
);
export const selectTillageTrendGeometryColorById = createSelector(
  [selectGeometriesColors, getAreaId],
  (geometryColors, geometryId) => {
    return geometryColors[geometryId];
  }
);
