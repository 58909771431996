export enum ActionType {
  SET_USER_ACTIVITY = 'user-activity/set',
  UPDATE_SUMMARY_USER_ACTIVITY = 'user-activity/update-summary',
}

export interface StateUserActivity {
  summary: SummaryUserActivity;
  list: ItemUserActivity[];
  loaded: boolean;
}

export interface SummaryUserActivity {
  total_users: number;
  total_farms: number;
  total_fields: number;
  total_area_ha: number;
}

export interface ItemUserActivity {
  id: number;
  name: string;
  surname: string;
  email: string;
  created_at: string;
  last_activity: string;
  sessions: number;
  farms: number;
  fields: number;
  fields_area_ha: number;
}
