import {useFetchKPI} from 'containers/si/hooks/useFetchKPI';
import {useSparklingChart} from 'containers/si/hooks/useSparklingChart';
import React, {useCallback} from 'react';
import {ChartMiddleData} from '../charts/components/chart-middle-data';
import {SparklineChart} from '../charts/components/sparkline-chart';
import {TOOLTIPS} from 'containers/si/constants';
import {MultiChartKPICard} from '../charts/multi-chart-kpi-card';
import {selectMeasurement} from 'containers/login/login-selectors';
import {useAppSelector} from '_hooks';
import {kiloFormatter} from '_utils/number-formatters';
import {calculatePercentage} from '_utils/pure-utils';
import {FontIcon} from 'react-md';
import {Flex, Text} from 'components';

export const LandUseLandChangeCard = () => {
  const {response: lulcResponse, loading} = useFetchKPI({kpi: 'lulc', summarizeBy: ['annualized']});
  const {metric, comparisonValues, sparklingChartData, dataset, average, perArea} =
    useSparklingChart(lulcResponse);
  const measurement = useAppSelector(selectMeasurement);

  const getLULCPercentage = useCallback(
    (baseValue: number) => {
      const totalArea = metric / perArea;

      return `${calculatePercentage(baseValue, totalArea).toPrecision(2)}`;
    },
    [metric, perArea]
  );

  return (
    <MultiChartKPICard
      title="Land use land change"
      subtitle="grassland converted to cropland"
      tooltip={TOOLTIPS.KPI.lulc}
      loading={loading}
    >
      <div>
        <ChartMiddleData
          cardCenterNumber={metric === 0 ? 'No Data' : `${getLULCPercentage(metric)}%`}
          cardCenterUnits={
            metric === 0
              ? ''
              : `${String(kiloFormatter(metric))} ${measurement === 'ac' ? 'acres' : 'hectares'}`
          }
        />
        <SparklineChart
          sparklingChartCaption={`${sparklingChartData.datasets.length} year avg`}
          sparklingChartCaptionBolded={`${kiloFormatter(average)} ${
            measurement === 'ac' ? 'ac' : 'ha'
          }`}
          comparisonValues={comparisonValues}
          chartData={{
            labels: sparklingChartData.labels,
            datasets: dataset,
          }}
          chartDataMidline={average}
        />
        <Flex alignItems="center">
          <FontIcon>info_outline</FontIcon>
          <Text variant="small" tertiary>
            This data is not crop specific
          </Text>
        </Flex>
      </div>
    </MultiChartKPICard>
  );
};
