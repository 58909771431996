import {InfoBlock, Text} from 'components';
import {selectIsSIDataLoading} from 'modules/sustainability-insights/selectors';
import type {ComponentType} from 'react';
import React from 'react';
import {CircularProgress} from 'react-md';
import {useAppSelector} from '_hooks';

type Props = {};

export const NoSelectedAreaMessage: ComponentType<Props> = () => {
  const isLoading = useAppSelector(selectIsSIDataLoading);

  if (isLoading) return <CircularProgress id="no-data-message-loader" />;

  return (
    <InfoBlock
      appearance="warning"
      iconColor="orange"
      noBackground
      containerStyle={{color: 'inherit', margin: '0 -1rem'}}
    >
      <Text fontWeight={500} secondary>
        Nothing is selected.
      </Text>
      <Text>
        Please select an area by clicking on the map or using the area selection dropdowns.
      </Text>
    </InfoBlock>
  );
};
export const NoDataMessage: ComponentType<Props> = () => {
  const isLoading = useAppSelector(selectIsSIDataLoading);

  if (isLoading) return <CircularProgress id="no-data-message-loader" />;

  return (
    <InfoBlock
      appearance="warning"
      iconColor="orange"
      noBackground
      containerStyle={{color: 'inherit', margin: '0 -1rem'}}
    >
      <Text elementType={'span'} fontWeight={500} secondary>
        No data found for selected filters
      </Text>
    </InfoBlock>
  );
};
