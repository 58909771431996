import type {ComponentType} from 'react';
import React, {useContext, useMemo} from 'react';
import type {FluroSelectLiteItem} from 'components/fluro-select-lite/fluro-select-lite';
import {FluroSelectLite} from 'components/fluro-select-lite/fluro-select-lite';
import {InputContext} from './input-context';
import {MRVSelectContainer} from './styled';
import {t} from 'i18n-utils';

type Props = {
  options?: FluroSelectLiteItem[];
  testId?: string;
  onChange: (value: string) => void;
};

export const InputSelect: ComponentType<Props> = ({options: optionsProp, testId, onChange}) => {
  const context = useContext(InputContext);
  const {
    id,
    label,
    value,
    options: attributeOptions,
    disabled,
    placeholder,
    defaultValue,
  } = context;

  const options = useMemo(() => {
    if (optionsProp?.length) return optionsProp;
    if (!attributeOptions?.length) return [];

    // https://regrowag.slack.com/archives/C02EZPYF399/p1644476715900859
    return (typeof attributeOptions === 'string' ? [] : attributeOptions)?.map(value => ({
      value,
      label: value,
    }));
  }, [optionsProp, attributeOptions]);

  return (
    <MRVSelectContainer>
      <FluroSelectLite
        testId={testId}
        id={id}
        items={options}
        disabled={disabled}
        selectedValue={value || defaultValue}
        placeholder={placeholder || t({id: 'Placeholder.Select option'})}
        label={label}
        onSelect={v => onChange(v)}
      />
    </MRVSelectContainer>
  );
};
