// @ts-nocheck
import type {
  IInitialMapState,
  IZoning,
  SamplingPoint,
  Season,
  TDateLayer,
  WholeFarmTreeZoning,
  ZoningParams,
  ZoningTab,
} from '../types';
import {t} from 'i18n-utils';
import tokml from '_utils/tokml';
import {deepCopy, denormalizeZoningColor, formatDate, formatUnit, isLocalhost} from '_utils';
import {downloadFile, getGetURLParam} from '_utils/pure-utils';
import {toFixedFloatUnsafe} from '_utils/number-formatters';
import {classifyTreesZone, getTreeZoningDate} from '../utils/trees';
import {ActivityApi, AgxApi, NrxApi, NutrilogicApi, SeasonApi, ZoningApi} from '_api';
import {AsyncStatusType, setRequestStatus, Status} from 'modules/helpers';
import type {Dispatch} from 'redux';
import moment from 'moment';
import {ActionTypes} from '../reducer/types';
import {showNotification} from 'components/notification/notification';
import {point as turfPoint, booleanContains} from '@turf/turf';
import {GLOBAL_FORMAT_DATE} from '_constants';
import {savePoint} from './sampling-points';
import {getGDDTillTSDate, showWarning} from '../utils';
import type {AppStore} from 'reducers';
import type {NRxZone} from 'containers/map/features/nrx';
import axios from 'axios';
import Mixpanel from '_utils/mixpanel-utils';
import type {AppDispatch} from 'store';
import type {ZoningInfoHeader} from '_api/zoning';
import {selectMeasurement} from '../../login/login-selectors';
import type {Measurement} from '../../login/types';
import {
  selectCurrentDate,
  selectCurrentFarm,
  selectCurrentField,
  selectCurrentImageObj,
  selectCurrentImagePngPath,
  selectPointsCurrentGroupDate,
  selectCurrentPointsGroup,
  selectCurrentSeasonId,
  selectCurrentSensor,
  selectIsWholeFarmView,
  selectMapFields,
  selectTreeDetectionLayerType,
  selectWholeFarmData,
  selectZoning,
} from '../reducer/selectors';
import {
  selectNRecommendation,
  selectNrxFertilizerListItemData,
} from 'containers/map/features/nrx/nrx-selectors';
import {reportError} from 'containers/error-boundary';
import {selectAsyncRequest, selectAsyncRequestStatus} from '../../../modules/global/selectors';
import {selectZoningUrl} from 'containers/map/features/zoning/zoning-selectors';

// zoning basic actions
/**
 *  Set zoning state, a powerful action
 */
export const setZoning = (zoning: Partial<IZoning> = {}) => ({
  type: ActionTypes.MAP_SET_ZONING,
  zoning,
});

/**
 *  Set zoning tab
 */
export const setZoningTab = (zoningTab: ZoningTab) => (dispatch: AppDispatch) => {
  dispatch({
    type: ActionTypes.MAP_SET_ZONING_TAB,
    zoningTab,
  });
};

/**
 * Toggle zoning layer on map, is used only on sampling points tab. allows to see points on zones
 */
export const toggleZoning = () => ({
  type: ActionTypes.MAP_TOGGLE_ZONING,
});
/**
 * Toggle zones input edit mode. Allows to edit zones name, value.
 */
export const toggleRx = (isEnableRx: boolean) => ({
  type: ActionTypes.MAP_TOGGLE_ZONING_RX,
  isEnableRx,
});
/**
 * Call a request to get suggested points depending on the current field status (secret algorithm)
 */

export const loadSuggestedPoints = () => (dispatch: Dispatch<any>, getState: () => AppStore) => {
  dispatch(setRequestStatus(AsyncStatusType.loadSuggestedPoints, Status.Pending));

  const state = getState();
  const field = selectCurrentField(state);
  const farm = selectCurrentFarm(state);
  const currentDate = selectCurrentDate(state);
  const farmName = farm.name;
  const fieldName = field.Name;
  const date = moment(currentDate, 'DD/MM/YYYY').format('YYYY-MM-DD');

  const url = selectZoningUrl(state, {
    return_sampling_points: 'true',
  });

  return ZoningApi.getZoning(url)
    .then(({data: {sampling_points, colors}}) => {
      dispatch(
        setSuggestedPoints({
          type: 'FeatureCollection',
          features: sampling_points.map((point, i) => ({
            type: 'Feature',
            geometry: {
              type: 'Point',
              coordinates: point,
            },
            properties: {
              farmName,
              fieldName,
              date,
              classes: i + 1,
              color: colors[i] ? `rgba(${denormalizeZoningColor(colors[i])})` : 'orange',
              growthStage: '',
              growerName: '-',
            },
          })),
        })
      );
      dispatch(setRequestStatus(AsyncStatusType.loadSuggestedPoints, Status.Done));
    })
    .catch(e => {
      dispatch(setRequestStatus(AsyncStatusType.loadSuggestedPoints, Status.Done, e));
    });
};

export const exportSuggestedPointsToKml = () => (dispatch: any, getState: () => AppStore) => {
  const {group: farm, field, currentDate, zoning}: IInitialMapState = getState().map;
  const fileName = `${farm.name}_${field.Name}`;
  const formattedCurrentDate = moment(currentDate, 'DD/MM/YYYY').format(GLOBAL_FORMAT_DATE);

  downloadFile(
    tokml({
      ...zoning.points,
      features: zoning.points.features,
    }),
    `Suggested_Sampling_Points_${fileName}_${formattedCurrentDate}.kml`
  );
};

/**
 * Save suggested points as sampling points, checks if the same points were saved before.
 */
export const saveSuggestedPoints = () => (dispatch: any, getState: () => AppStore) => {
  const {currentSeason, zoning, currentDate} = getState().map;

  if (!currentSeason) {
    showNotification({
      title: t({id: 'note.warning', defaultMessage: 'Warning'}),
      message: t({id: 'Please create season before saving points.'}),
      type: 'warning',
    });

    return;
  }

  const ts = [...(currentSeason.tissueSampling || [])];
  let points: Array<any> = [];

  if (zoning.points && zoning.points.features.length) {
    points = [...zoning.points.features];
  }

  const notExistPoints = points.filter(point => {
    // TODO: reverse is dirty hack should be fixed on the backend
    const turfP = turfPoint([point.geometry.coordinates[1], point.geometry.coordinates[0]]);
    let isNotEsist = true;

    ts.forEach(tsPoint => {
      const turfTSPoint = turfPoint(tsPoint.geometry.coordinates);

      if (booleanContains(turfP, turfTSPoint)) {
        isNotEsist = false;
      }
    });

    return isNotEsist;
  });

  if (notExistPoints.length) {
    const preparedMarkers = notExistPoints.map(p => ({
      id: 'new',
      ...p,
      geometry: {
        type: 'Point',
        // TODO: fix reverse lat/lng
        coordinates: [p.geometry.coordinates[1], p.geometry.coordinates[0]],
      },
      properties: {
        title: 'Untitled Point',
        timedate: moment.utc(currentDate, 'DD/MM/YYYY').format(GLOBAL_FORMAT_DATE),
        n_result: 0,
        n_result2: 0,
        p_result: 0,
        k_result: 0,
        classes: p.properties.classes || 0,
      },
    }));

    const promises = preparedMarkers.map(p => dispatch(savePoint(p, true)));

    Promise.all(promises)
      .then(() => {
        dispatch(setSuggestedPoints({}));
        showNotification({
          title: t({id: 'note.success', defaultMessage: 'Success'}),
          message: t({id: 'Points was saved as tissue sampling.'}),
          type: 'success',
        });
      })
      .catch();
  } else {
    dispatch(setSuggestedPoints({}));
    showNotification({
      title: t({id: 'note.warning', defaultMessage: 'Warning'}),
      message: t({id: 'Points already saved.'}),
      type: 'warning',
    });
  }
};

/**
 * Updates suggested points state value, can be used to clear the points data
 */
export const setSuggestedPoints = (points: any) => ({
  type: ActionTypes.MAP_SET_ZONING_POINTS,
  points,
});

/**
 * Loads regular and tree detection zoning
 */
export const loadZoningData = () => (dispatch: AppDispatch, getState: () => AppStore) => {
  const state = getState();
  const treeDetectionLayerType = selectTreeDetectionLayerType(state);
  const isWholeFarmView = selectIsWholeFarmView(state);
  const wholeFarmDataLoading =
    selectAsyncRequestStatus(state, AsyncStatusType.wholeFarmData) === Status.Pending;

  if (isWholeFarmView && wholeFarmDataLoading) {
    return;
  }

  const isTreeAnalysis = treeDetectionLayerType !== 'default';
  const currentImage: TDateLayer = selectCurrentImageObj(state);
  const currentImageDate = getTreeZoningDate();
  const measurement = selectMeasurement(state);

  if (!currentImage && !(isTreeAnalysis && currentImageDate)) {
    return;
  }
  if (isTreeAnalysis) {
    return dispatch(getTreeDetectionZoning());
  }

  dispatch(setRequestStatus(AsyncStatusType.mainZoning, Status.Pending));
  return ZoningApi.getZoning(selectZoningUrl(state, {}))
    .then(response => {
      // ds sends NaN instead of null sometimes
      const sanitized = response.headers['fs-zoning-info'].replace(/\bNaN\b/g, 'null');
      const zoningInfo = JSON.parse(sanitized) as ZoningInfoHeader;

      // converting ds api response to input for setZoning
      const zones = Object.values(zoningInfo).map(zone => {
        return [
          zone.color.map(c => c * 255), // rgba
          [zone.average_index], // avg index value
          [zone.percentage.toString()], // area_percentage
          [(zone.area * 0.00024710538146717).toString()], // area_ac
          [(zone.area / 10000).toString()], // area_ha
          ['0'], // soil_rx(?)
        ];
      });
      dispatch(setZoning({zones: formatZoneObject(zones, measurement)}));
      dispatch(setRequestStatus(AsyncStatusType.mainZoning, Status.Done));
    })
    .catch(e => {
      if (!axios.isCancel(e)) {
        dispatch(setRequestStatus(AsyncStatusType.mainZoning, Status.Done, e));
      }
    });
};

export const getTreeDetectionZoning =
  (retryRequest = false) =>
  (dispatch: AppDispatch, getState: () => AppStore) => {
    const state = getState();
    const zoning = selectZoning(state);
    const field = selectCurrentField(state);
    const fields = selectMapFields(state);
    const currentSensor = selectCurrentSensor(state);
    const {isWholeFarmView} = selectWholeFarmData(state);
    const {treeZoning} = selectWholeFarmData(state);

    const fieldsToRequest = isWholeFarmView
      ? fields.filter(f => treeZoning.fields[f.MD5]?.selected && getTreeZoningDate(f.MD5))
      : [field];

    if (!fieldsToRequest.length) {
      dispatch(setRequestStatus(AsyncStatusType.mainZoning, Status.Todo));
      showNotification({
        title: t({id: 'note.info', defaultMessage: 'Info'}),
        message: t({id: 'Please, use the fields dropdown to select fields to run zoning on.'}),
        type: 'info',
      });
      return; // prevent requesting data if there is no fields passed to the filter
    }

    const prevSocket = selectAsyncRequest(state, AsyncStatusType.treeDetectionZoning).errors; // use errors object to store the data
    if (typeof prevSocket?.close === 'function') {
      // works similar to axios.cancel()
      prevSocket.close();
    }

    const zoningDataAccumulator: WholeFarmTreeZoning = {
      fields: treeZoning.fields,
      zones: [],
      treeZoningShapefileSrc: '',
    };

    const treeDetectionSocket = new WebSocket(
      `wss://${
        isLocalhost() ? 'app.dev.regrow.ag' : window.location.hostname
      }/services/tree-detection/ws/zoning`
    );

    dispatch(
      setRequestStatus(AsyncStatusType.treeDetectionZoning, Status.Pending, treeDetectionSocket) // associate the websocket with the request
    );

    let arg = ['BestPerforming', 'WorstPerforming'].includes(zoning.method) // represents threshold or number of zones, depending on the zoning method
      ? zoning.treeZoningPercentage * 0.01 // this will be a threshold
      : zoning.method === 'custom'
      ? zoning.zonesRange // this also will be a threshold
      : zoning.classes; // this is zones number

    const requestPayload = JSON.stringify({
      fields: fieldsToRequest.map(f => ({md5: f.MD5, sensing_date: getTreeZoningDate(f.MD5)})),
      method: zoning.method,
      arg: arg,
      index: currentSensor.toLowerCase(),
    });

    treeDetectionSocket.onopen = () => {
      treeDetectionSocket.send(requestPayload);
    };

    treeDetectionSocket.onerror = () => {
      if (!retryRequest) {
        dispatch(getTreeDetectionZoning(true)); // just try one more time, WS connection fails sometime
      } else {
        dispatch(
          setRequestStatus(AsyncStatusType.treeDetectionZoning, Status.Done, treeDetectionSocket)
        );
      }
    };

    treeDetectionSocket.onmessage = event => {
      const data = JSON.parse(event.data);
      if (data.status === 'success') {
        treeDetectionSocket.close(); // close connection and return
        dispatch(setRequestStatus(AsyncStatusType.treeDetectionZoning, Status.Done, null));
        return;
      }

      if (data.status === 'zoning_completed') {
        //zoning part is complete, waiting for shapefile without loader
        dispatch(
          setRequestStatus(AsyncStatusType.treeDetectionZoning, Status.Done, treeDetectionSocket)
        );
      }

      if (data.status === 'failed') {
        showNotification({
          title: t({id: 'note.error', defaultMessage: 'error'}),
          message: t({
            id: 'errorTryReloadPage',
            defaultMessage: 'An error occurred, try to reload the page.',
          }),
          type: 'error',
        });
        reportError(`tree detection zoning failed, payload = ${requestPayload}`);
        dispatch(
          setRequestStatus(AsyncStatusType.treeDetectionZoning, Status.Todo, treeDetectionSocket)
        );
      }

      if (data.shapefile) {
        zoningDataAccumulator.treeZoningShapefileSrc = data.shapefile;
      }

      if (data.zoning) {
        // only zoning data
        zoningDataAccumulator.zones = classifyTreesZone(data.zoning);
      }

      const fieldWithData = fieldsToRequest.find(({MD5}) => data[MD5]);

      if (!isWholeFarmView) {
        if (fieldWithData || data.zoning) {
          dispatch(
            setZoning({
              treeZonesImage: data[field.MD5]?.png,
              treeZones:
                zoningDataAccumulator.zones ||
                classifyTreesZone(data.zoning || data[field.MD5]?.zoning),
            })
          );
        }
        if (zoningDataAccumulator.treeZoningShapefileSrc) {
          dispatch(
            setZoning({
              treeZoningShapefileSrc: zoningDataAccumulator.treeZoningShapefileSrc,
            })
          );
        }
        return; // early return;
      }

      if (fieldWithData) {
        zoningDataAccumulator.fields[fieldWithData.MD5] = {
          selected: zoningDataAccumulator.fields[fieldWithData.MD5]?.selected || true, // keep selected state
          zoningImageSrc: data[fieldWithData.MD5]?.png,
        };
      }

      dispatch({
        type: ActionTypes.MAP_SET_WHOLE_FARM_TREE_ZONING_FIELDS,
        data: zoningDataAccumulator,
      });
    };
  };

const formatZoneObject = (zones: any[], measurement: Measurement) => {
  return zones.map((z, index) => {
    return {
      id: index + 1,
      // leaving all values here the same, as I am not sure if min/max are used thoroughly enough
      min: toFixedFloatUnsafe(z[1][0], 2),
      max: toFixedFloatUnsafe(z[1][0], 2),
      mid: toFixedFloatUnsafe(z[1][0], 2),
      area: toFixedFloatUnsafe(measurement === 'ha' ? z[4][0] : z[3][0], 2),
      color: `rgba(${z[0][0]}, ${z[0][1]}, ${z[0][2]}, ${z[0][3]})`,
      percent: toFixedFloatUnsafe(z[2][0], 1),
    };
  });
};

/**
 * Custom zoning range
 */
export const updateZonesRange = (zonesRange: number[]) => ({
  type: ActionTypes.MAP_UPDATE_ZONES_RANGE,
  zonesRange,
});

/**
 * Update regular zoning zone prop
 */
export const updateZoningZone = (id: number, prop: string, value: any) => ({
  type: ActionTypes.MAP_UPDATE_ZONE_PROP,
  id,
  prop,
  value,
});

/**
 * Update regular zoning zone prop
 */
export const setZoningUnits = (value: {label: string; value: string}) => ({
  type: ActionTypes.MAP_SET_ZONING_UNITS,
  value,
});

// nutrilogic // todo this section should be refactored

/**
 * Nutrilogic is a specific algorithm to get the N recommendation. it is based on Nitrate pmm value in a specific zone + GDD (n_result2)
 * The model can accept much more data, but it is not implemented, yet. It depends on sampling points sample date, not the selected layer date.
 * Steps description:
 * 1. Takes total GDD till sampling points group date.
 * 2. Sends to back-end current points to get grey index (it helps to detect which point to which zone belongs)
 * 3. Gets fertilizer for each point (i think it is senseless, because there is no connection between point,
 *    just n_result2 and GDD, need to consider this one) and updates zoning zones with result value.
 * 4.
 */

export const runNutrilogicRecommendation =
  () => async (dispatch: any, getState: () => AppStore) => {
    const {
      pointsGroups,
      pointsCurrentGroupDate,
      feature,
      nRecommendation,
      temperatureData,
      currentSeason,
    } = getState().map;
    if (!pointsGroups[pointsCurrentGroupDate]) {
      return dispatch(showWarning(feature, 'no-tsp-group'));
    }

    if (temperatureData.length) {
      if (
        !moment(temperatureData[0].date, GLOBAL_FORMAT_DATE).isSame(
          moment(currentSeason.startDate, GLOBAL_FORMAT_DATE),
          'day'
        )
      ) {
        return dispatch(showWarning(feature, 'weather-missing'));
      }
    }

    const GDD = getGDDTillTSDate()(dispatch, getState); // 1

    if (!GDD) {
      // stop executing function if don't have GDD
      return dispatch(nutrilogicHandleNoGDDCase(pointsCurrentGroupDate, currentSeason));
    }
    await dispatch(getAndSavePointsPosition()); // 2
    const zonesWithFertilizer = await getFertilize(GDD)(dispatch, getState); //3

    if (
      !nRecommendation.zonesWithNData.length &&
      zonesWithFertilizer.length &&
      feature === 'zoning'
    ) {
      showNotification({
        title: t({id: 'note.success', defaultMessage: 'Success'}),
        message: t({id: 'Your recommendation was generated.'}),
        type: 'success',
      });
    }

    dispatch({
      type: ActionTypes.MAP_UPDATE_N_DATA,
      data: zonesWithFertilizer,
    });
  };

const getFertilize =
  (gdd = 0) =>
  async (dispatch: any, getState: () => AppStore) => {
    const state = getState().map;
    const {pointsGroups, pointsCurrentGroupDate, feature} = state;
    let noEmptyPoints = pointsGroups[pointsCurrentGroupDate].filter(
      (point: SamplingPoint) => point.properties.n_result2
    );

    if (!noEmptyPoints.length) {
      dispatch(showWarning(feature, 'empty-points')); // if no points whit nitrate ppm
      return [];
    }

    noEmptyPoints = await Promise.all(
      noEmptyPoints.map(async (point: SamplingPoint) => {
        // get rx value according to points nitrate ppm value
        const nitrateValue = point.properties.n_result2;
        const recommendationValue = await getNutrilogic([
          {Gdd: gdd, Nitrate: parseInt(nitrateValue, 10)},
        ]);
        point.rxValue = parseInt(recommendationValue, 10);
        return point;
      })
    );
    return state.zoning.zones.map((zone: any, i: number, arr: Array<any>) => {
      const zoneMinValue = zone.min;
      const zoneMaxValue = zone.max;
      noEmptyPoints.forEach((point: SamplingPoint) => {
        const {silverIndex, rxValue} = point;

        if (
          (silverIndex >= zoneMinValue && silverIndex < zoneMaxValue) ||
          (i === arr.length - 1 && silverIndex === zoneMaxValue)
        ) {
          // get point's zone
          zone.value = zone.value ? (zone.value + rxValue) / 2 : rxValue; // if already have value, calculate average
        }
      });
      return zone;
    });
  };

const getNutrilogic = (data: any) => NutrilogicApi.petiole(data).then(({data}) => data.rxvalue);

const getAndSavePointsPosition = () => (dispatch: AppDispatch, getState: () => AppStore) => {
  const state = getState();
  const currentPointsGroup = selectCurrentPointsGroup(state);
  const pointsCurrentGroupDate = selectPointsCurrentGroupDate(state);
  const path = selectCurrentImagePngPath(state);

  let arrayPoints: number[][] = [];

  if (currentPointsGroup?.length) {
    // get coordinates from points
    arrayPoints = currentPointsGroup.map((point: SamplingPoint) => point.geometry.coordinates);
  }
  return ActivityApi.getPointsSilverIndex(path, arrayPoints)
    .then(({data}) => {
      currentPointsGroup.forEach(
        (point: SamplingPoint, i: number) => (point.silverIndex = data[i])
      );

      dispatch({
        type: ActionTypes.MAP_POINTS_UPDATE_WITH_NUTRILOGIC_DATA, // update TSP group with points, that have silverIndex
        value: currentPointsGroup,
        date: pointsCurrentGroupDate,
      });
    })
    .catch();
};

const nutrilogicHandleNoGDDCase = (pointsCurrentGroupDate: string, currentSeason: Season) => () => {
  const pointsDate = moment(pointsCurrentGroupDate, formatDate());
  let string = '';

  if (pointsDate.isBefore(moment(currentSeason.startDate, GLOBAL_FORMAT_DATE)))
    string = t({
      id: 'Your recommendations can not be generated because your sampling date is before the sowing date.',
    });
  else if (pointsDate.isAfter(moment(currentSeason.endDate, GLOBAL_FORMAT_DATE)))
    string = t({
      id: 'Your recommendations can not be generated because your sampling date is after the harvest date.',
    });
  else if (pointsDate.isAfter(moment()))
    string = t({
      id: 'Your recommendations can not be generated because your sampling date is after the current date.',
    });

  if (string) {
    showNotification({
      title: t({id: 'note.warning', defaultMessage: 'Warning'}),
      message: string,
      type: 'warning',
    });
  }
};

/// end nutrilogic section

// trees zoning

export const setTreeZoneParam = (id: number, data: any) => ({
  type: ActionTypes.MAP_SET_TREE_ZONE_PARAM,
  id,
  data,
});

export const toggleTreeZoningFields = (fieldMD5s: string[], value: boolean) => ({
  type: ActionTypes.MAP_TOGGLE_WHOLE_FARM_TREE_ZONING_FIELD,
  fieldMD5s,
  value,
});

// end trees zoning section

export const saveZonesToAgX = (result: any) => (dispatch: any, getState: () => AppStore) => {
  const {
    map: {group, field, currentSeasonId, zoning},
    login: {user},
  } = getState();

  const requestData = {
    FsUserId: user.id,
    FieldID: field.ID,
    SeasonId: currentSeasonId,
    // FertilizerProduct: zoning.product.Name,
    // FertilizerProductId: zoning.product.Id,
    ApplicationMethodId: zoning.application.method,
    ApplicationTimingId: zoning.application.timing,
    ReiUnitId: zoning.application.reiType,
    ReiValue: zoning.application.reiValue,
    Zones: result,
  };

  AgxApi.saveZonesToAgX(`${group.id}/${field.ID}/${currentSeasonId}`, requestData).then(() => {
    showNotification({
      title: t({id: 'note.success', defaultMessage: 'Success'}),
      message: t({id: 'Your data was sent to agX.'}),
      type: 'success',
    });
  });
};

/// export zoning

export const exportZoningShapeFile = () => (dispatch: AppDispatch, getState: () => AppStore) => {
  Mixpanel.exportZoning('SHP');
  const state = getState();
  const field = selectCurrentField(state);
  const zoning = selectZoning(state);
  const currentDate = selectCurrentDate(state);
  const currentSensor = selectCurrentSensor(state);
  const farm = selectCurrentFarm(state);

  const date = moment(currentDate, 'DD/MM/YYYY').format(GLOBAL_FORMAT_DATE);
  const name = `Zones_${farm.name}_${field.Name}_${date}_${currentSensor}.zip`;
  const fileName = encodeURIComponent(name);

  const params: ZoningParams = {
    export_shapefile: 'true',
    shapefile_name: fileName,
  };
  if (zoning.zones.some(z => z.value)) {
    params.rx = zoning.zones
      .slice()
      .reverse()
      .map(z => z.value || 0)
      .toString();
  }
  params.names = zoning.zones
    .slice()
    .reverse()
    .map((z, i) => z.name || `Zone ${i + 1}`)
    .toString();

  const url = selectZoningUrl(state, params);
  window.open(url);
};

export const exportNrxToShp = () => (dispatch: any, getState: () => AppStore) => {
  const state = getState();
  const {nrxRecommendationSettings, nrxResult, nrxTabRate, selectedObjective} =
    selectNRecommendation(state);
  const farm = selectCurrentFarm(state);
  const field = selectCurrentField(state);
  const measurement = selectMeasurement(state);

  let gjson = deepCopy(nrxResult[nrxTabRate][selectedObjective]);
  const formattedNRxDate = moment(
    nrxRecommendationSettings.recommendation_date || getGetURLParam('nrx-date'),
    GLOBAL_FORMAT_DATE
  ).format(GLOBAL_FORMAT_DATE);
  const name = `Nitrogen_Recommendation_${farm.name}_${field.Name}_${formattedNRxDate}`;
  if (gjson?.features?.length) {
    gjson.features = gjson.features
      .filter((f: NRxZone) => f.properties.merged !== 'initial')
      .map((f: NRxZone) => {
        const properties = {
          date: formattedNRxDate,
          growerName: farm.growerName || '',
          farmName: farm.name,
          fieldName: field.Name,
          zoneID: f.properties.id,
          zoneName: f.properties.name || `Zone ${f.properties.id}`,
          [`area_${measurement}`]: f.properties.area?.toFixed(1),
          areaPercent: f.properties.percent?.toFixed(1),
          value: f.properties.value,
          product: nrxRecommendationSettings.product,
        };

        return {
          ...f,
          properties: {...properties, _order: Object.keys(properties)},
        };
      });
  }
  Mixpanel.exportNRx('SHP');
  SeasonApi.downloadShapefile([gjson], name).then(({data}) => {
    downloadFile(data, name + '.zip');
  });
};

export const exportNrxToKml = () => (dispatch: any, getState: () => AppStore) => {
  const state = getState();
  const {nrxRecommendationSettings, nrxResult, nrxTabRate, selectedObjective} =
    selectNRecommendation(state);
  const farm = selectCurrentFarm(state);
  const field = selectCurrentField(state);
  const measurement = selectMeasurement(state);

  const formattedNRxDate = moment(
    nrxRecommendationSettings.recommendation_date || getGetURLParam('nrx-date'),
    GLOBAL_FORMAT_DATE
  ).format(GLOBAL_FORMAT_DATE);
  const preparedData: GeoJSON.FeatureCollection = {
    ...nrxResult[nrxTabRate][selectedObjective],
    features: nrxResult[nrxTabRate][selectedObjective].features
      .filter((f: NRxZone) => f.properties.merged !== 'initial')
      .map((f: NRxZone) => {
        return {
          ...f,
          properties: {
            area: `${toFixedFloatUnsafe(f.properties.area, 1)} ${t({id: measurement})}`,
            value: f.properties.value,
            percentArea: toFixedFloatUnsafe(f.properties.percent, 1),
            fieldName: field.Name,
            farmName: farm.name,
            date: formattedNRxDate,
            zoneName: f.properties.name || `${t({id: 'Zone'})} ${f.properties.id}`,
          },
        };
      }),
  };
  Mixpanel.exportNRx('KML');
  downloadFile(
    tokml(preparedData),
    `Nitrogen_Recommendation_${field.Name}_${formattedNRxDate}.kml`
  );
};

export const exportNrxToAgx = () => (dispatch: AppDispatch, getState: () => AppStore) => {
  const state = getState();
  const {nrxResult, nrxTabRate, nrxRecommendationSettings, selectedObjective} =
    selectNRecommendation(state);
  const farm = selectCurrentFarm(state);
  const field = selectCurrentField(state);
  const currentSeasonId = selectCurrentSeasonId(state);
  const measurement = selectMeasurement(state);
  const fertilizerProductId = selectNrxFertilizerListItemData(
    state,
    nrxRecommendationSettings.product
  )?.typeID;

  const productMeasure = formatUnit(
    measurement,
    nrxRecommendationSettings.isLiquid ? 'l/ha' : 'kg/ha'
  );

  const preparedData = {
    fertilizer_product_id: fertilizerProductId,
    date: moment(nrxRecommendationSettings.recommendation_date, GLOBAL_FORMAT_DATE).format(
      'YYYYMMDDTHHMMSS'
    ),
    zones: nrxResult[nrxTabRate][selectedObjective].features
      .filter((f: NRxZone) => f.properties.merged !== 'initial')
      .map((f: NRxZone) => {
        return {
          ...f,
          properties: {
            fertilizer_rate: f.properties.value === '-' ? 0 : f.properties.value,
            fertilizer_rate_units: productMeasure,
          },
        };
      }),
  };
  Mixpanel.exportNRx('AgX');
  NrxApi.exportToAgX(farm.id, field.ID, currentSeasonId, preparedData)
    .then(() =>
      showNotification({
        title: t({id: 'note.success', defaultMessage: 'Success'}),
        message: t(
          {id: 'The recommendation was exported to {external_service}.'},
          {external_service: 'agX'}
        ),
        type: 'success',
      })
    )
    .catch(() =>
      showNotification({
        title: t({id: 'note.warning', defaultMessage: 'Warning'}),
        message: t({id: 'errorTryReloadPage'}),
        type: 'warning',
      })
    );
};

export const exportNrxToAgworld = () => (dispatch: any, getState: () => AppStore) => {
  const {
    map: {
      nRecommendation: {nrxResult, nrxTabRate, nrxRecommendationSettings, selectedObjective},
      group: farm,
      field,
    },
    login,
  } = getState();

  const productMeasure = formatUnit(
    login.user.settings.measurement,
    nrxRecommendationSettings.isLiquid ? 'l/ha' : 'kg/ha'
  );

  const preparedData = {
    recommendation_date: moment(
      nrxRecommendationSettings.recommendation_date,
      GLOBAL_FORMAT_DATE
    ).format(GLOBAL_FORMAT_DATE),
    units: productMeasure,
    fertiliser_name: nrxRecommendationSettings.product,
    map: nrxResult[nrxTabRate][selectedObjective].features
      .filter((f: NRxZone) => f.properties.merged !== 'initial')
      .map((f: NRxZone) => {
        return {
          ...f,
          properties: f.raw_properties,
        };
      }),
  };

  Mixpanel.exportNRx('Agworld');
  NrxApi.exportToAgworld(farm.id, field.FieldID, preparedData)
    .then(() =>
      showNotification({
        title: t({id: 'note.success'}),
        message: t(
          {id: 'The recommendation was exported to {external_service}.'},
          {external_service: 'Agworld'}
        ),
        type: 'success',
      })
    )
    .catch(() =>
      showNotification({
        title: t({id: 'note.warning'}),
        message: t({id: 'errorTryReloadPage'}),
        type: 'warning',
      })
    );
};

export const exportTreesToShapefile = () => (dispatch: AppDispatch, getState: () => AppStore) => {
  const state = getState();
  const {isWholeFarmView, treeZoning} = selectWholeFarmData(state);
  const {treeZoningShapefileSrc: singleFieldShapefile} = selectZoning(state);
  const linkToShapefile = isWholeFarmView
    ? treeZoning.treeZoningShapefileSrc
    : singleFieldShapefile;
  if (!linkToShapefile) return;

  window.open(linkToShapefile);
};
