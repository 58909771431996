import {Flex, InfoBlock, Text} from 'components';
import {CardHr} from 'components/card/card-styled';
import {CarbonSequesteredIcon} from 'components/fluro-icons';
import {Span} from 'components/text/span';
import {
  setAbatementPotentialFilterRange,
  setHighlightGeometry,
} from 'modules/sustainability-insights/actions';
import {
  getAbatementPotentialColor,
  SUMMARY_BARS_PREVIEW_THRESHOLD,
} from 'modules/sustainability-insights/constants';
import {
  selectAbatementPotentialChartData,
  selectAbatementPotentialFilterRange,
  selectAggLevel,
  selectCurrentMeta,
  selectIsSIDataLoading,
} from 'modules/sustainability-insights/selectors';
import type {GroupedSummaryChartData} from 'modules/sustainability-insights/types';
import {
  classifyAbatementPotentialFilterScale,
  getSummaryChartLabel,
} from 'modules/sustainability-insights/utils';
import React, {useCallback, useEffect} from 'react';
import {CircularProgress} from 'react-md';
import {useDispatch} from 'react-redux';
import {useAppSelector} from '_hooks';
import {kiloFormatter, toFixedFloatUnsafe} from '_utils/number-formatters';
import {PastureIcon} from '../../../../../icons';
import {SummaryChart, SummaryChartGrouped} from '../summary-chart';
import type {AxisProps} from '@nivo/axes/dist/types/types';
import {isDefined} from '_utils/typeGuards';

export const AbatementChart = () => {
  const dispatch = useDispatch();

  const isLoading = useAppSelector(selectIsSIDataLoading);
  const aggLevel = useAppSelector(selectAggLevel);
  const abatementPotentialChatData = useAppSelector(selectAbatementPotentialChartData);
  const filterRange = useAppSelector(selectAbatementPotentialFilterRange);

  const {type, chartData, minValue, maxValue, totalSequestered, totalValue} =
    abatementPotentialChatData ?? {};

  const keys = ['value'];
  const meta = useAppSelector(selectCurrentMeta);

  // calculate filterRange values
  useEffect(() => {
    if (isDefined(maxValue)) {
      const dynamicRange = classifyAbatementPotentialFilterScale(maxValue);
      dispatch(setAbatementPotentialFilterRange(dynamicRange));
    }
  }, [dispatch, maxValue]);

  const getColor = useCallback(
    (value: number) => {
      return getAbatementPotentialColor(filterRange, value);
    },
    [filterRange]
  );

  const nameLabel: AxisProps['format'] = (id: number) => {
    return getSummaryChartLabel(meta?.[id]?.name, aggLevel === 'state');
  };

  const handleBarMouseEnter = ({data: {id}}: any) => {
    dispatch(setHighlightGeometry(id));
  };

  const handleBarMouseLeave = () => {
    dispatch(setHighlightGeometry(null));
  };

  const chartValuesFormatter = (value: number) => {
    const formatterValue = value > 0.1 ? 1 : 2;
    return toFixedFloatUnsafe(value, formatterValue);
  };

  if (isLoading) return <CircularProgress id="abatement-potential-data-progress" />;
  if (!abatementPotentialChatData || !chartData?.length)
    return (
      <InfoBlock appearance="info" noBackground>
        No data to display
      </InfoBlock>
    );

  return (
    <>
      <Text variant={'small'} elementType={'p'} secondary>
        Average abatement potential for the years 2015-2020 is modelled for no till + cover crops
        adoption.
      </Text>
      <CardHr />

      {type === 'normal' ? (
        <SummaryChart
          data={chartData}
          keys={keys}
          getColor={getColor}
          nameLabel={nameLabel}
          onMouseEnter={handleBarMouseEnter}
          onMouseLeave={handleBarMouseLeave}
          maxVisibleItems={SUMMARY_BARS_PREVIEW_THRESHOLD}
          format={chartValuesFormatter}
          maxValue={chartData?.length > 1 ? maxValue : undefined}
          minValue={chartData?.length > 1 ? minValue : undefined}
          labelSkipWidth={12}
        />
      ) : (
        <SummaryChartGrouped
          data={chartData as GroupedSummaryChartData[]}
          keys={keys}
          getColor={getColor}
          nameLabel={nameLabel}
          onMouseEnter={handleBarMouseEnter}
          onMouseLeave={handleBarMouseLeave}
          maxVisibleItems={SUMMARY_BARS_PREVIEW_THRESHOLD}
          format={chartValuesFormatter}
          maxValue={chartData?.length > 1 ? maxValue : undefined}
          minValue={chartData?.length > 1 ? minValue : undefined}
          labelSkipWidth={12}
        />
      )}

      <Flex justifyContent="flex-end">
        <Span secondary>abatement potential (t/ac)</Span>
      </Flex>

      {isDefined(totalValue) && isDefined(totalSequestered) && (
        <TotalValues totalAcres={totalValue} totalSequestered={totalSequestered} />
      )}
    </>
  );
};

const TotalValues = ({
  totalAcres,
  totalSequestered,
}: {
  totalAcres: number;
  totalSequestered: number;
}) => {
  return (
    <>
      <CardHr />
      <div className="total-items">
        <div className="total-item">
          <div className={'total-item__icon-wrapper'}>
            <PastureIcon />
          </div>
          <div className={'total-item__label'}>Total Acres</div>
          <div className={'total-item__result'}>
            <span className="total-item__result__value">{kiloFormatter(totalAcres)}</span>
            <span className="total-item__result__units">ac</span>
          </div>
        </div>
        <div className="total-item">
          <div className={'total-item__icon-wrapper'}>
            <CarbonSequesteredIcon />
          </div>
          <div className={'total-item__label'}>Carbon sequestered and net GHG reductions </div>
          <div className={'total-item__result'}>
            <span className="total-item__result__value">{kiloFormatter(totalSequestered)} T</span>
            <span className="total-item__result__units">
              CO<sub>2</sub>eq
            </span>
          </div>
        </div>
      </div>
    </>
  );
};
