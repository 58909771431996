import {t} from 'i18n-utils';
import React from 'react';
import moment from 'moment';
import {CropStatus} from './helpers';
import {NdviView} from './helpers/ndvi';
import type {CSGViewModel, CSGAvgViewModel, Biomass} from './types';
import './panel-view-card.scss';
import {CropInfo} from 'components';
import {useAppSelector} from '_hooks';
import {selectCropVarietiesColors} from 'containers/map/reducer/selectors';
import {selectCropColorById} from 'modules/global/selectors';

type Props = {
  record: CSGViewModel;
  biomass: Biomass;
  showFarmName: boolean;
  onHighlightField?: (fieldId: number) => void;
};

/**
 * Card with field name, season and crop performance.
 */
export const PanelViewCard = ({record, biomass, showFarmName, onHighlightField}: Props) => {
  return (
    <div className="crop-performance-card" onClick={() => onHighlightField?.(record.seasonId)}>
      <div className="field-name">
        <h3>{record.fieldName}</h3>
      </div>
      {showFarmName && <div className="farm-name">{record.farmName}</div>}
      {record.isPlantingArea && <div className="season-name">{record.seasonName}</div>}

      <CropInfo
        cropType={record.cropType}
        cropSubType={record.cropVariety}
        startDate={moment.utc(record.startDate).format('DD MMM YYYY')}
        endDate={moment.utc(record.endDate).format('DD MMM YYYY')}
        hasGeometry={record.isPlantingArea}
      />
      <div className="data-freshness">{!record.reliable && '☁️'}</div>
      {/* {!record.reliable &&
        <InfoBlock appearance="info">
          The latest clear imagery was more than 10 days ago.
        </InfoBlock>
      } */}
      <div className="statuses">
        <div className="column status">
          <div>{t({id: 'Crop status'})}</div>
          <div>
            <CropStatus
              fieldId={record.fieldID}
              // TODO (stas): Commented out because of switch to CSGModel which doesn't
              // have `statusAlert`. seasonId is easy to add. What is statusAlert?
              // If it's the "sowing date could be wrong", than we have this info!
              // seasonId={record.seasonId}
              // statusAlert={record.statusAlert}
              value={record.cropStatus}
              predicted={record.predicted}
            />
          </div>
        </div>
        <div className="column biomass">
          <div>{t({id: 'Biomass ({index})'}, {index: 'NDVI'})}</div>
          <div>
            <NdviView
              ndvi={record.smoothSatelliteNdvi}
              status={biomass}
              predicted={record.predicted}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

type Crop = {
  fieldName: string;
  cropType: string;
  startDate: string;
  endDate: string;
  cropVariety: string;
};

/**
 * Card with field name and season.
 */
export const PanelViewSimpleCard = ({record}: {record: Crop}) => {
  return (
    <div className="crop-performance-card">
      <div>
        <h3>{record.fieldName}</h3>
      </div>
      <CropInfo
        cropType={record.cropType}
        cropSubType={record.cropVariety}
        startDate={moment.utc(record.startDate).format('DD MMM YYYY')}
        endDate={moment.utc(record.endDate).format('DD MMM YYYY')}
      />
    </div>
  );
};

/**
 * Card with season instead of field name. Is used as an average card for groupBy crop.
 */
export const PanelViewAverageCard = ({record}: {record: CSGAvgViewModel}) => {
  const cropVarietyColors = useAppSelector(selectCropVarietiesColors);
  const cropTypeColor = useAppSelector(s => selectCropColorById(s, record.cropType));
  const color = (record.cropVariety && cropVarietyColors[record.cropVariety]) || cropTypeColor;

  return (
    <div className="crop-performance-card average">
      <div className="color" style={{backgroundColor: color}}></div>
      <CropInfo cropType={record.cropType} cropSubType={record.cropVariety} />
      {/*<div className="season-placeholder">*/}
      {/*  /!**/}
      {/*    Since crop info takes tha place of field name,*/}
      {/*    have this placeholder on place of season.*/}
      {/*  *!/*/}
      {/*</div>*/}
      {/*<div className="data-freshness">{!record.reliable && '☁️'}</div>*/}
      {/* {!record.reliable &&
        <InfoBlock appearance="info">
          The latest clear imagery was more than 10 days ago.
        </InfoBlock>
      } */}
      {/*<div className="statuses">*/}
      {/*  <div className="column status">*/}
      {/*    <div>{t({id: 'Crop status'})}</div>*/}
      {/*    <div>*/}
      {/*      <CropStatus value={record.cropStatus} />*/}
      {/*    </div>*/}
      {/*  </div>*/}
      {/*  <div className="column biomass">*/}
      {/*    <div>{t({id: 'Biomass ({index})'}, {index: 'NDVI'})}</div>*/}
      {/*    <div>*/}
      {/*      <NdviView ndvi={record.smoothSatelliteNdvi} status={'medium'} />*/}
      {/*    </div>*/}
      {/*  </div>*/}
      {/*</div>*/}
    </div>
  );
};
