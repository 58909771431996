import {selectMeasurement} from 'containers/login/login-selectors';
import {useEffect, useState} from 'react';
import {useAppSelector} from '_hooks';
import {convertUnit} from '_utils';
import {toFixedFloat} from '_utils/number-formatters';
import {getSparklineDataSetDefinition} from '../kpi/charts/components/helpers';
import {selectSIYearsFilter} from '../module/selectors';
import type {SIProgramKPI} from '../types';
import {calcValueByKPI} from '../utils/calcValueByKPI';

type SparklingChartOptions = {
  negativeIsGain?: boolean;
  tooltip?: {
    up: string;
    down: string;
  };
};

export const useSparklingChart = (kpiResponse?: SIProgramKPI, options?: SparklingChartOptions) => {
  const measurement = useAppSelector(selectMeasurement);
  const yearsFilter = useAppSelector(selectSIYearsFilter);

  const previousYear = yearsFilter[0] - 1;

  const [sparklingChartData, setSparklingChartData] = useState<{
    labels: string[];
    datasets: number[];
  }>({
    labels: [],
    datasets: [],
  });
  const [metric, setMetric] = useState(0);
  const [perArea, setPerArea] = useState(0);
  const [average, setAverage] = useState(0);
  const [pctFromAverage, setPctFromAverage] = useState(0);
  const [pctFromPrev, setPctFromPrev] = useState<number | null>(0);

  //Rounds percentage considering edge 0 - 0.99% or -0.99% - 0.
  const formatRoundPercentage = (pct: number): number => {
    return pct < 1 ? (pct < 0 && pct > -1 ? Math.floor(pct) : Math.ceil(pct)) : Math.round(pct);
  };

  useEffect(() => {
    if (!kpiResponse) return;
    // Generate Chart Data
    const data: {labels: string[]; datasets: number[]} = {labels: [], datasets: []};

    // Getting data for sparkline chart
    Object.keys(kpiResponse?.annualized_summary ?? []).forEach(year => {
      data.labels.push(year);
      data.datasets.push(
        toFixedFloat(
          calcValueByKPI(
            kpiResponse.annualized_summary?.[Number(year)] ?? 0,
            kpiResponse?.kpi_name,
            measurement
          ),
          15
        ) // Important to leave this [15] to keep precision
      );
    });
    setSparklingChartData(data);

    // Calculating crop area
    const calcCropAreaM2 = kpiResponse.crop_area?.value ?? 0; // should be always m2
    const calcCropAreaAc = convertUnit('ac', 'm2', calcCropAreaM2, true); // in ac
    const calcCropArea = measurement === 'ac' ? calcCropAreaAc : calcCropAreaM2 / 10000; // in ac or ha

    // Calculate metric
    const kpiMetric = kpiResponse?.metric?.value || 0;
    const calcKPIMetric = calcValueByKPI(kpiMetric, kpiResponse?.kpi_name, measurement) || 0;
    setMetric(calcKPIMetric || 0);

    // Calculates kpi metric per area
    const calcCropPerArea = calcKPIMetric / calcCropArea;
    setPerArea(calcCropPerArea);

    // Calculate average metric over the years in 'annualized_summary'
    const cropSum = data.datasets.reduce((a, c) => a + c, 0);
    const cropAverage = cropSum / data.datasets.length;
    setAverage(cropAverage ?? 0);

    // Calculate percentage change from average to current year
    const calcAvgPercent = ((calcKPIMetric - cropAverage) / cropAverage) * 100;
    setPctFromAverage((calcAvgPercent && formatRoundPercentage(calcAvgPercent)) ?? 0);

    // Calculate percentage change from previous year to current year

    const previousYearCrop =
      (kpiResponse?.annualized_summary &&
        calcValueByKPI(
          kpiResponse?.annualized_summary[String(previousYear)] ?? 0,
          kpiResponse?.kpi_name,
          measurement
        )) ||
      null;
    const calcPrevPercent =
      (previousYearCrop &&
        ((calcKPIMetric - previousYearCrop) / Math.abs(previousYearCrop)) * 100) ||
      null;

    setPctFromPrev(calcPrevPercent && formatRoundPercentage(calcPrevPercent));
  }, [kpiResponse, measurement, previousYear]);

  const isPositive = (pct: number) => {
    return options?.negativeIsGain ? pct < 0 : pct > 0;
  };

  const getDirection = (pct: number | null): 'positive' | 'negative' => {
    if (!pct) return 'negative';
    return options?.negativeIsGain
      ? isPositive(pct)
        ? 'negative'
        : 'positive'
      : isPositive(pct)
      ? 'positive'
      : 'negative';
  };

  const getTooltip = (pct: number | null) => {
    if (options?.tooltip && pct) {
      return options?.negativeIsGain
        ? {
            tooltip: isPositive(pct) ? options.tooltip.down : options.tooltip.up,
          }
        : {
            tooltip: isPositive(pct) ? options.tooltip.up : options.tooltip.down,
          };
    }
    return {};
  };

  const getColor = (pct: number | null): {color: 'green' | 'red'} | {color: undefined} => {
    if (options?.negativeIsGain && pct) {
      return {color: isPositive(pct) ? 'green' : 'red'};
    }
    return {color: undefined};
  };

  const comparisonValues: {
    label: string;
    value: string | number;
    direction: 'positive' | 'negative';
    tooltip?: string;
    color?: 'red' | 'green';
  }[] = [
    {
      label: `Compared to ${yearsFilter[0] - 1}`,
      value: pctFromPrev ? `${Math.abs(pctFromPrev)}%` : 'Not Available',
      direction: getDirection(pctFromPrev),
      ...getTooltip(pctFromPrev),
      ...getColor(pctFromPrev),
    },
    {
      label: `Compared to ${sparklingChartData.datasets.length} year avg.`,
      value: Number.isFinite(pctFromAverage) ? `${Math.abs(pctFromAverage)}%` : 'Not Available',
      direction: getDirection(pctFromAverage),
      ...getTooltip(pctFromAverage),
      ...getColor(pctFromAverage),
    },
  ];

  return {
    sparklingChartData,
    dataset: getSparklineDataSetDefinition(sparklingChartData.datasets),
    metric,
    perArea,
    average,
    comparisonValues,
  };
};
