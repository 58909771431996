import type {
  SISupplyShed,
  SISupplyShedInput,
  SISubsection,
  SISubsectionInput,
  SIProgramKPI,
  PlanningReport,
  PlanningReportInput,
  SIScenarioResponseAP,
  SIScenarioResponsePP,
} from 'containers/si/types';
import Server from '_api/server';
import type {FeatureCollection} from 'geojson';
import type {
  CountryBookValueResponse,
  ProgramRequest,
  ProgramResponse,
  ProgramUserResponse,
  KPIFilter,
  SupplyShedGeometries,
} from 'containers/si/api/apiTypes';
// This is the SI v2 API.

const SI_BASE_URL = 'si/v2';

export class SIApi {
  static getPrograms() {
    return Server.get<ProgramResponse[]>(`${SI_BASE_URL}/programs`, {
      params: {
        __skipPreloader: true,
      },
    });
  }

  static getProgram(programId: number) {
    return Server.get<ProgramResponse>(`${SI_BASE_URL}/programs/${programId}`, {
      params: {__skipPreloader: true},
    });
  }

  static addProgram(program: ProgramRequest) {
    return Server.post<ProgramResponse[]>(`${SI_BASE_URL}/programs`, [program], {
      params: {__skipPreloader: true},
    });
  }

  static updateProgram(programId: number, program: Partial<ProgramResponse>) {
    return Server.put<ProgramResponse>(`${SI_BASE_URL}/programs/${programId}`, program, {
      params: {__skipPreloader: true},
    });
  }

  static removeProgram(programId: number) {
    return Server.delete(`${SI_BASE_URL}/programs/${programId}`, {
      params: {__skipPreloader: true},
    });
  }

  // USERS
  static getProgramUsers(programId: number) {
    return Server.get<ProgramUserResponse[]>(`${SI_BASE_URL}/programs/${programId}/users/users`, {
      params: {__skipPreloader: true},
    });
  }

  static getProgramAdmins(programId: number) {
    return Server.get<ProgramUserResponse[]>(`${SI_BASE_URL}/programs/${programId}/users/admins`, {
      params: {__skipPreloader: true},
    });
  }

  static addProgramUser(programId: number, user_id: number, role: 'admin' | 'user') {
    return Server.post<ProgramUserResponse>(
      `${SI_BASE_URL}/programs/${programId}/users/invite`,
      {user_id, role},
      {
        params: {__skipPreloader: true},
      }
    );
  }

  static deleteProgramUsers(programId: number, user_ids: number[]) {
    return Server.delete(`${SI_BASE_URL}/programs/${programId}/users`, {
      data: user_ids,
      params: {__skipPreloader: true},
    });
  }

  // Geometries
  static getProgramGeometries(programId: number) {
    return Server.get<Array<SupplyShedGeometries>>(
      `${SI_BASE_URL}/programs/${programId}/geometries`,
      {
        params: {__skipPreloader: true},
      }
    );
  }

  //Supply Sheds
  static getSupplySheds(programId: number) {
    return Server.get<SISupplyShed[]>(`${SI_BASE_URL}/programs/${programId}/supply_sheds`, {
      params: {__skipPreloader: true},
    });
  }

  static getSupplyShedById(programId: number, supplyShedId: number) {
    return Server.get<SISupplyShed>(
      `${SI_BASE_URL}/programs/${programId}/supply_sheds/${supplyShedId}`,
      {
        params: {__skipPreloader: true},
      }
    );
  }

  static addSupplyShed(programId: number, supplyShed: SISupplyShedInput) {
    return Server.post<SISupplyShed[]>(
      `${SI_BASE_URL}/programs/${programId}/supply_sheds`,
      [supplyShed],
      {
        params: {__skipPreloader: true},
      }
    );
  }

  static updateSupplyShed(
    programId: number,
    supplyShedId: number,
    supplyShed: Partial<SISupplyShed>
  ) {
    return Server.put<SISupplyShed>(
      `${SI_BASE_URL}/programs/${programId}/supply_sheds/${supplyShedId}`,
      supplyShed,
      {
        params: {__skipPreloader: true},
      }
    );
  }

  static removeSupplyShed(programId: number, supplyShedId: number) {
    return Server.delete(`${SI_BASE_URL}/programs/${programId}/supply_sheds/${supplyShedId}`, {
      params: {__skipPreloader: true},
    });
  }

  static getSupplyShedSubsections(programId: number, supplyShedId: number) {
    return Server.get<SISubsection[]>(
      `${SI_BASE_URL}/programs/${programId}/supply_sheds/${supplyShedId}/subsections`,
      {
        params: {__skipPreloader: true},
      }
    );
  }

  static addSupplyShedSubsection(programId: number, subsection: SISubsectionInput) {
    return Server.post<SISupplyShed[]>(
      `${SI_BASE_URL}/programs/${programId}/supply_sheds/${subsection.supply_shed_id}/subsections`,
      [subsection],
      {
        params: {__skipPreloader: true},
      }
    );
  }

  static updateSupplyShedSubsection(
    programId: number,
    supplyShedId: number,
    subsectionId: number,
    subsection: Partial<SISubsection>
  ) {
    return Server.put<SISubsection>(
      `${SI_BASE_URL}/programs/${programId}/supply_sheds/${supplyShedId}/subsections/${subsectionId}`,
      subsection,
      {
        params: {__skipPreloader: true},
      }
    );
  }

  static removeSupplyShedSubsection(programId: number, supplyShedId: number, subsectionId: number) {
    return Server.delete(
      `${SI_BASE_URL}/programs/${programId}/supply_sheds/${supplyShedId}/subsections/${subsectionId}`,
      {
        params: {__skipPreloader: true},
      }
    );
  }

  static getSubsectionById(programId: number, supplyShedId: number, subsectionId: number) {
    return Server.get<SISubsection>(
      `${SI_BASE_URL}/programs/${programId}/supply_sheds/${supplyShedId}/subsections/${subsectionId}`,
      {
        params: {__skipPreloader: true},
      }
    );
  }

  static convertFileToFeature(file: File, shouldUnion: boolean) {
    const formData = new FormData();
    formData.append('file', file);
    return Server.post<FeatureCollection>(
      `${SI_BASE_URL}/convert?should_union=${shouldUnion}`,
      formData,
      {
        headers: {'Content-Type': 'multipart/form-data', accept: 'application/json'},
        params: {__skipPreloader: true},
      }
    );
  }

  // KPI Data
  static getKPIData(programId: number, kpiType: string) {
    return Server.get<SIProgramKPI>(
      `${SI_BASE_URL}/programs/${programId}/supply_sheds/kpis/${kpiType}`,
      {
        params: {__skipPreloader: true},
      }
    );
  }

  static getBookValues(programId: number) {
    return Server.get<Array<CountryBookValueResponse>>(
      `${SI_BASE_URL}/programs/${programId}/book_values`,
      {
        params: {__skipPreloader: true},
      }
    );
  }

  // KPI Filter
  static getAllKPIFilters(programId: number) {
    return Server.get<KPIFilter[]>(
      `${SI_BASE_URL}/programs/${programId}/supply_sheds/kpi_filters`,
      {
        params: {__skipPreloader: true},
      }
    );
  }

  static setAllKPIFilters(programId: number, filters: KPIFilter[]) {
    return Server.post<KPIFilter[]>(
      `${SI_BASE_URL}/programs/${programId}/supply_sheds/kpi_filters`,
      filters,
      {
        params: {__skipPreloader: true},
      }
    );
  }

  // Recompute
  static recomputeProgram(programId: number, failed_only: boolean, recompute_area: boolean) {
    return Server.post(
      `${SI_BASE_URL}/programs/${programId}/compute${failed_only ? '?failed_only=true' : ''}${
        recompute_area ? '&recompute_area=true' : '&recompute_area=false'
      }`,
      {
        params: {__skipPreloader: true},
      }
    );
  }
  static recomputeSubsection(
    programId: number,
    supplyShedId: number,
    subsectionId: number,
    recompute_area: boolean
  ) {
    return Server.post(
      `${SI_BASE_URL}/programs/${programId}/supply_sheds/${supplyShedId}/subsections/${subsectionId}/compute${
        recompute_area ? '?recompute_area=true' : '?recompute_area=false'
      }`,
      {
        params: {__skipPreloader: true},
      }
    );
  }

  // Scenario Modeling / Planning Reports / IMT / Abatement Potential
  static getPlanningReports(programId: number) {
    return Server.get<PlanningReport[]>(`${SI_BASE_URL}/programs/${programId}/imt_reports`, {
      params: {__skipPreloader: true},
    });
  }
  static addPlanningReport(programId: number, abatementPotentialReport: PlanningReportInput) {
    return Server.post(
      `${SI_BASE_URL}/programs/${programId}/imt_reports`,
      [abatementPotentialReport],
      {
        params: {__skipPreloader: true},
      }
    );
  }
  // Currently only 'name' is editable
  static updatePlanningReport(
    programId: number,
    reportId: number,
    abatementPotentialReport: Partial<PlanningReportInput>
  ) {
    return Server.put<PlanningReport[]>(
      `${SI_BASE_URL}/programs/${programId}/imt_reports/${reportId}`,
      abatementPotentialReport,
      {
        params: {__skipPreloader: true},
      }
    );
  }
  // Acknowledge an ACCEPTED IMT Report and change its status to IN_PROGRESS.
  static acknowledgePlanningReport(programId: number, reportId: number) {
    return Server.put<PlanningReport[]>(
      `${SI_BASE_URL}/programs/${programId}/imt_reports/${reportId}/acknowledge`,
      {
        params: {__skipPreloader: true},
      }
    );
  }
  static updatePlanningReportURL(programId: number, reportId: number, resultsUrl: string) {
    return Server.put<{results_url: string}>(
      `${SI_BASE_URL}/programs/${programId}/imt_reports/${reportId}/results`,
      {results_url: resultsUrl},
      {
        params: {__skipPreloader: true},
      }
    );
  }
  static deletePlanningReport(programId: number, reportId: number) {
    return Server.delete<PlanningReport[]>(
      `${SI_BASE_URL}/programs/${programId}/imt_reports/${reportId}`,
      {
        params: {__skipPreloader: true},
      }
    );
  }

  // Abatement Potential Scenarios
  static getAPReportScenarios(programId: number, reportId: number) {
    return Server.get<SIScenarioResponseAP[]>(
      `${SI_BASE_URL}/programs/${programId}/imt_reports/${reportId}/scenario_executions`,
      {
        params: {__skipPreloader: true},
      }
    );
  }
  // Program Plan Scenarios
  static getPPReportScenarios(programId: number, reportId: number) {
    return Server.get<SIScenarioResponsePP[]>(
      `${SI_BASE_URL}/programs/${programId}/imt_reports/${reportId}/program_plan_scenario_executions`,
      {
        params: {__skipPreloader: true},
      }
    );
  }

  // Data Export
  static getSupplyShedCSV(
    programId: number,
    aggregation_unit: string,
    supply_sheds: number[],
    crop_ids: number[],
    year_of_interest: number | undefined = undefined
  ) {
    return Server.post<Blob>(
      `${SI_BASE_URL}/programs/${programId}/export`,
      {
        aggregation_unit,
        supply_sheds,
        crop_ids,
        year_of_interest,
      },
      {
        params: {__skipPreloader: true},
      }
    );
  }
}

export default SIApi;
