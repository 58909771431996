import {useLeafletContext} from '@react-leaflet/core';
import {MapContext} from 'containers/map/context';
import MapButtons from 'containers/map/map-buttons';
import {
  selectSIGeometriesBounds,
  selectVisibleGeometriesIds,
} from 'modules/sustainability-insights/selectors';
import type {ComponentType} from 'react';
import React, {useContext, useEffect} from 'react';
import {FeatureGroup} from 'react-leaflet';
import {useAppSelector} from '_hooks';
import {SIGeometry} from './geometry/geometry';
import './overlay.scss';

export const SIOverlay: ComponentType<{}> = () => {
  const leafletElement = useLeafletContext().map;
  const {patchedFitBounds} = useContext(MapContext);
  const bounds = useAppSelector(selectSIGeometriesBounds);
  const areaIds = useAppSelector(selectVisibleGeometriesIds);

  useEffect(() => {
    if (!bounds || typeof patchedFitBounds !== 'function') return;
    patchedFitBounds(bounds);
  }, [patchedFitBounds, bounds]);

  return (
    <>
      <MapButtons leafletElement={leafletElement} fitBounds={patchedFitBounds} />
      <FeatureGroup>
        {areaIds.map((id, index) => (
          <SIGeometry key={`${id}-${index}`} id={id} />
        ))}
      </FeatureGroup>
    </>
  );
};
