import Server from './server';
import service from './service';
import type {
  NaasPlantingSettingsResponse,
  NRxRecomendationSettings,
  NRxResultsResponse,
  NRxSeason,
} from 'containers/map/features/nrx';
import type {SoilType} from 'containers/map/features/nrx/nrx-types';
import type {AxiosPromise} from 'axios';
import cancelTokenStore from '_api/cancel-tokens-store';

class NRxApi {
  static updateSeasonNrxData(data: Partial<NRxSeason>) {
    return Server.put(`app/nrx/season/`, data);
  }

  static setSeasonNrxData(data: Partial<NRxSeason>) {
    return Server.post(`app/nrx/season/`, data);
  }

  static getCropTypes = (cropId?: number) => {
    return Server.get(`app/nrx/crop/${cropId ? cropId : ''}`);
  };

  static getSoilType = (soilId?: number) => {
    return Server.get<{result: {soilType: SoilType[]}}>(
      `app/nrx/soil_type/${soilId ? soilId : ''}`
    );
  };

  static getNearestSoilType = (fieldCoordinates: {id: number; coords: number[]}[]) => {
    return service.post<{result: {fields: Record<number, number[]>; soilType: SoilType[]}}>(
      `recommendation_date/closest_apsoils`,
      {
        n: 10,
        fields: fieldCoordinates,
      }
    );
  };

  static getFertilizerParams = () => {
    return Server.get(`app/nrx/fertiliser_application_type/`);
  };

  static getNRxSeasons = (ids: string) => {
    return Server.get(`app/nrx/season/?season_ids=${ids}`);
  };

  static getFertilizerApplication = (appId: any) => {
    return Server.get(`app/nrx/season/?kml_ids=${appId}`);
  };

  static addFertilizerApplication = (data: any) => {
    return Server.post(`app/nrx/fertiliser_application/`, data);
  };

  static updateFertilizerApplication = (data: any) => {
    return Server.put(`app/nrx/fertiliser_application/`, data);
  };

  static deleteFertilizerApplication = (nrxSeasonId: number, appId: number | string) => {
    return Server.delete(`app/nrx/${nrxSeasonId}/fertiliser_application/${appId}`);
  };

  static getNRxRecommendation = (
    data: any,
    requestIndex?: number
  ): AxiosPromise<NRxResultsResponse> => {
    cancelTokenStore.cancel(`getNRxRecommendation${requestIndex ? requestIndex : ''}`);
    const source = cancelTokenStore.generateSource(
      `getNRxRecommendation${requestIndex ? requestIndex : ''}`
    );

    return service.get('nrx-output/roi', {
      cancelToken: source.token,
      params: {__skipPreloader: true, ...data, summary: true},
    });
  };

  static saveRecommendationSettings = (
    data: Partial<NRxRecomendationSettings>,
    seasonId: number
  ) => {
    cancelTokenStore.cancel('saveRecommendationSettings');
    const source = cancelTokenStore.generateSource('saveRecommendationSettings');

    return Server.post(`app/nrx/settings/${seasonId}`, data, {
      params: {cancelToken: source.token, __skipPreloader: true},
    });
  };

  static updateRecommendationDate = (seasonId: number, date: string) => {
    return service.post(`recommendation_date/${seasonId}/${date}`);
  };

  static getDashboard = () => {
    return Server.get('app/nrx/dashboard', {params: {__skipPreloader: true}});
  };

  static exportToAgX = (farmId: number, fieldId: number, seasonId: number, payload: any) => {
    return Server.post(`nrx/${farmId}/${fieldId}/${seasonId}`, payload);
  };

  static exportToAgworld = (farmId: number, fieldId: number, payload: any) => {
    return Server.post(`export/agworld/nrx/${farmId}/${fieldId}`, payload);
  };

  static getDefaultCornSettings = (
    seasonStartDate: string,
    state: string
  ): AxiosPromise<NaasPlantingSettingsResponse> => {
    return service.get(
      `nass-service/corn/?irrigated=false&state=${state}&season_startdate=${seasonStartDate}&years_to_average=5`
    );
  };
}

export default NRxApi;
