// @ts-nocheck
import * as React from 'react';
import {t} from 'i18n-utils';
import {useState, useMemo, useEffect, useCallback} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {TextField, FontIcon, Switch} from 'react-md';
import CircularProgresslink from 'react-md/lib/Progress/CircularProgress';
import {Waypoint} from 'react-waypoint';
import type {AppStore} from 'reducers';
import {escapeRegExp, unreachableError} from '_utils/pure-utils';
import {loadCropPerformance, toggleCropPerformanceFarms} from './reducer';
import './farms-dropdown.scss';
import {RequestStatus} from 'types';
import type {CropPerformanceFarm} from './types';
import {Dropdown, Flex, FluroButton, FluroChip} from 'components';
import {selectFarm} from 'modules/farms/actions';
import {selectFarmsList} from 'modules/farms/selectors';
import type {Farm} from 'containers/map/types';

type Props = {
  mobileView?: boolean; // used to display data in FluroChips in certain areas (not in the toggle header) for mobiles
};

export const CropPerformanceFarmsDropdown = ({mobileView}: Props) => {
  const dispatch = useDispatch();
  const [multifarm, setMultifarm] = useState(false);
  const [query, setQuery] = useState<string>('');
  const [pagination, setPagination] = useState<number>(PAGINATION_STEP);
  const farms = useSelector(selectFarmsList);
  const selectedFarms = useSelector((s: AppStore) => s.cropPerformance.farms);
  const currentFarmId = useSelector((s: AppStore) => s.global.currentGroupId);

  useEffect(() => {
    // if several farm were selected automatically (from url params as an example)
    if (Object.keys(selectedFarms).length > 1 && !multifarm) {
      setMultifarm(true);
    }
  }, [selectedFarms]);

  // Group farms by grower and sort them.
  const {growers, sortedGrowers} = useMemo(() => {
    // Group farms by grower.
    const growers: {[key: string]: Farm[]} = {};
    farms.forEach(f => {
      const name = f.growerName ? f.growerName.trim() : NO_GROWER;
      if (!growers[name]) {
        growers[name] = [];
      }
      growers[name].push(f);
    });

    // Sort growers by name and sort the farms inside each grower by name.
    const sortedGrowers = Object.keys(growers).sort((a, b) => a.localeCompare(b));
    sortedGrowers.forEach(name => growers[name].sort((a, b) => a.name.localeCompare(b.name)));

    return {growers, sortedGrowers};
  }, [farms]);

  // Filter farms (1): update farms visibility.
  const farmsVisibility = useMemo(() => {
    const r = new RegExp(escapeRegExp(query), 'i');
    const visibility: {[key: number]: boolean} = {};
    farms.forEach(f => {
      // Don't filter if the query is short – too much occurrences anyway
      // and too big list to traverse.
      visibility[f.id] = query.length < 2 ? true : r.test(f.name) || r.test(f.growerName);
    });
    return visibility;
  }, [farms, query]);

  // Filter farms (2): apply farms visibility to farms grouped by grower.
  const {filteredFarms, selectedGrowersAndFarms, unselectedGrowersAndFarms} = useMemo(() => {
    // Since there are >5k farms try to avoid allocating new arrays.
    const filteredFarms: Farm[] = [];
    const unselectedGrowersAndFarms: (string | Farm)[] = [];
    const selectedGrowersAndFarms: (string | Farm)[] = [];
    sortedGrowers.forEach(name => {
      let anyGrowerAdded = false;
      let selectedGrowerAdded = false;
      growers[name].forEach(f => {
        // Don't filter out selected farms based on search query.
        if (selectedFarms[f.id]) {
          if (!selectedGrowerAdded && name !== NO_GROWER) {
            selectedGrowersAndFarms.push(name);
            selectedGrowerAdded = true;
          }
          selectedGrowersAndFarms.push(f);
          filteredFarms.push(f);
          return;
        }

        // Filter out all the other farms based on search query.
        if (!farmsVisibility[f.id]) {
          return;
        }

        if (!anyGrowerAdded && name !== NO_GROWER) {
          unselectedGrowersAndFarms.push(name);
          anyGrowerAdded = true;
        }
        unselectedGrowersAndFarms.push(f);
        filteredFarms.push(f);
      });
    });
    return {filteredFarms, selectedGrowersAndFarms, unselectedGrowersAndFarms};
  }, [growers, sortedGrowers, farmsVisibility, selectedFarms]);

  // Show more farms while user scrolling the list.
  const filteredAndPaginatedUnselectedGrowersAndFarms = useMemo(
    () => unselectedGrowersAndFarms.slice(0, pagination),
    [unselectedGrowersAndFarms, pagination]
  );

  const selectedFarmsList = useMemo(() => Object.values(selectedFarms), [selectedFarms]);

  const onResetFarms = useCallback(() => {
    const ids = Object.keys(selectedFarms)
      .map(Number)
      .filter(farmId => farmId !== currentFarmId);
    if (!selectedFarms[currentFarmId]) {
      ids.push(currentFarmId);
    }
    dispatch(toggleCropPerformanceFarms(ids));
  }, [selectedFarms]);

  const userFacingValue = selectedFarms[currentFarmId]?.farmName || t({id: 'Farm'});

  if (mobileView) {
    return <FluroChip className={'farm-field-mobile-chip'} label={userFacingValue} />;
  }

  return (
    <Dropdown
      className={'crop-performance-farms-dropdown'}
      button={
        <FluroButton raised className="more-farms-button">
          <span className={'content-wrapper'}>
            {selectedFarmsList.some(f => f.status === RequestStatus.Loading) ? (
              <div className="preloader">
                <CircularProgresslink id={`farm-loader-table`} />
              </div>
            ) : selectedFarmsList.some(f => f.status === RequestStatus.Error) ? (
              <FontIcon style={{color: 'red', marginRight: '4px'}}>error_outline</FontIcon>
            ) : (
              <FontIcon className="add-more-farms-icon">add_circle_outline</FontIcon>
            )}
            <span className={'farm-name'}>{userFacingValue}</span>
          </span>
          <Flex alignItems={'center'}>
            {selectedFarmsList.length > 1 && (
              <span className="additional-farm-count">{selectedFarmsList.length}</span>
            )}
            <FontIcon className="drop-down-icon">arrow_drop_down</FontIcon>
          </Flex>
        </FluroButton>
      }
    >
      <React.Fragment>
        {/* Filter */}
        <TextField
          id="farms-filter-input"
          className="farms-filter"
          placeholder={t({id: 'Type farm name...'})}
          value={query}
          onChange={(query: string) => setQuery(query)}
        />

        {/* Multifarm toggle */}
        <Switch
          id="multifarm-toggle"
          name="multifarm-toggle"
          className="multifarm-toggle"
          label={t({id: 'Select multiple farms'})}
          labelBefore={true}
          checked={multifarm}
          onChange={() => setMultifarm(multifarm => !multifarm)}
        />

        {multifarm &&
          (filteredFarms.length < 20 ||
            selectedFarmsList.length > 1 ||
            !selectedFarms[currentFarmId]) && (
            <div className="multiple-farms-controls">
              {filteredFarms.length < 20 ? (
                <div className="farm-item">
                  <FarmCheckbox
                    id={-1}
                    label={'All farms'}
                    checked={selectedFarmsList.length === filteredFarms.length}
                    onToggle={() => {
                      const checked = selectedFarmsList.length === filteredFarms.length;
                      dispatch(
                        toggleCropPerformanceFarms(
                          checked
                            ? filteredFarms.map(f => f.id).filter(id => id !== currentFarmId)
                            : filteredFarms.map(f => f.id),
                          !checked
                        )
                      );
                      dispatch(loadCropPerformance());
                    }}
                  />
                </div>
              ) : (
                /** A plug, so the next element will be positioned at right via flexbox */
                <div />
              )}
              {(selectedFarmsList.length > 1 || !selectedFarms[currentFarmId]) && (
                <div className="reset-farms">
                  <FluroButton blank raised className="reset-farms-btn" onClick={onResetFarms}>
                    {t({id: 'Reset farms'})}
                  </FluroButton>
                </div>
              )}
            </div>
          )}

        {/* Selected section */}
        {selectedGrowersAndFarms.map(growerOrFarm => (
          <React.Fragment
            key={`selected-${typeof growerOrFarm === 'string' ? growerOrFarm : growerOrFarm.id}`}
          >
            {typeof growerOrFarm === 'string' ? (
              <div className="grower-name-item">{growerOrFarm}</div>
            ) : (
              <div
                className="farm-item"
                onClick={() => dispatch(toggleCropPerformanceFarms([growerOrFarm.id]))}
              >
                {multifarm ? (
                  <FarmCheckbox
                    id={growerOrFarm.id}
                    label={growerOrFarm.name}
                    disabled={selectedFarmsList.length === 1}
                    checked={!!selectedFarms[growerOrFarm.id]}
                    selected
                    onToggle={() => undefined}
                  />
                ) : (
                  <span>{growerOrFarm.name}</span>
                )}
                <div
                  className="status-icon"
                  onClick={e => {
                    // Error icon click retries the request.
                    if (selectedFarms[growerOrFarm.id].status === RequestStatus.Error) {
                      e.stopPropagation();
                      dispatch(loadCropPerformance());
                    }
                  }}
                >
                  {statusIcon(selectedFarms[growerOrFarm.id])}
                </div>
              </div>
            )}
          </React.Fragment>
        ))}

        {/* Unselected section */}
        {filteredAndPaginatedUnselectedGrowersAndFarms.map(growerOrFarm => (
          <React.Fragment key={typeof growerOrFarm === 'string' ? growerOrFarm : growerOrFarm.id}>
            {typeof growerOrFarm === 'string' ? (
              <div key={growerOrFarm} className="grower-name-item">
                {growerOrFarm}
              </div>
            ) : (
              <div
                className="farm-item"
                onClick={() => {
                  if (multifarm) {
                    dispatch(toggleCropPerformanceFarms([growerOrFarm.id]));
                    dispatch(loadCropPerformance());
                  } else {
                    dispatch(selectFarm(growerOrFarm.id));
                  }
                }}
              >
                {multifarm ? (
                  <FarmCheckbox
                    id={growerOrFarm.id}
                    label={growerOrFarm.name}
                    onToggle={() => undefined}
                  />
                ) : (
                  <span>{growerOrFarm.name}</span>
                )}
              </div>
            )}
          </React.Fragment>
        ))}
        {/* Load more farms waypoint  */}
        <Waypoint onEnter={() => setPagination(p => p + PAGINATION_STEP)} />
      </React.Fragment>
    </Dropdown>
  );
};

type FarmCheckboxProps = {
  id: number;
  label: string;
  disabled?: boolean;
  checked?: boolean;
  selected?: boolean; // whether this checkbox is in the "selected" section
  onToggle: () => void;
};
const FarmCheckbox = ({id, label, disabled, checked, selected, onToggle}: FarmCheckboxProps) => {
  const id_ = `farm-checkbox-${selected ? 'selected' : ''}-${id}`;
  return (
    <div className="farm-checkbox">
      <input
        id={id_}
        type="checkbox"
        checked={checked}
        disabled={disabled}
        onChange={onToggle}
      ></input>
      {
        // TODO: fixme a11y
        // eslint-disable-next-line jsx-a11y/label-has-for
        <label htmlFor={id_}>{label}</label>
      }
    </div>
  );
};

const statusIcon = (farm: CropPerformanceFarm) => {
  switch (farm.status) {
    case RequestStatus.Idle:
    case RequestStatus.Skipped:
      return '';
    case RequestStatus.Loading:
      return (
        <div className="preloader">
          <CircularProgresslink id={`farm-loader-${farm.id}`} />
        </div>
      );
    case RequestStatus.Error:
      return <FontIcon style={{color: 'red', cursor: 'pointer'}}>error_outline</FontIcon>;
    case RequestStatus.Success:
      return <FontIcon style={{color: '#43a047'}}>check</FontIcon>;
    default:
      unreachableError(
        farm.status,
        `Farms dropdown: Request status "${farm.status}" is not implemented`
      );
  }
};

const NO_GROWER = ' __grower_less__';
const PAGINATION_STEP = 25;
