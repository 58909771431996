import {Flex, FluroChip} from 'components';
import {CardFullWidth} from 'components/card/card-styled';
import {FluroChipDropdownControllable as FluroChipDropdown} from 'components/fluro-dropdown/fluro-chip-dropdown';
import {Span} from 'components/text/span';
import type {ComponentType} from 'react';
import React, {useMemo, useState} from 'react';
import {List, ListItem} from 'react-md';
import {useAppSelector} from '_hooks';
import {useUrlParams} from '_hooks/use-url-params';
import {useMonitoringUrlParams} from '../hooks';
import {selectProjectFarmsList} from '../module/selectors';
import {mixpanelCarbon} from '_utils/mixpanel-utils';
import {useTranslation} from 'i18n-utils';
import {useMobs} from '../../data/use-mobs';

export const FarmSelect: ComponentType = () => {
  const t = useTranslation();
  const {projectId} = useMonitoringUrlParams();
  const [{entityId: activeFarmId}, setParams] = useUrlParams();
  const farms = useAppSelector(s => [
    {id: -1, name: t({id: 'SelectListItem.Mrv.All farms', defaultMessage: 'All farms'})},
    ...selectProjectFarmsList(s, projectId),
  ]);
  const onEntityItemClick = (farmId: number) => {
    setParams({entityId: farmId});
    mixpanelCarbon.track('measurement_farm_filter');
  };

  return (
    <EntitySelectInner
      label={t({id: 'FarmLabel'}, {farmsNumber: farms.length})}
      entities={farms}
      activeEntityId={activeFarmId as number}
      onEntityItemClick={onEntityItemClick}
      testId="MRV-form--farm-select"
    />
  );
};

export const MobSelect: ComponentType = () => {
  const t = useTranslation();
  const {projectId} = useMonitoringUrlParams();
  const [{entityId: activeMobId}, setParams] = useUrlParams();
  const {mobs} = useMobs(projectId, 'mob');
  const items = useMemo(
    () => [
      {id: -1, name: t({id: 'SelectListItem.Mrv.All mobs', defaultMessage: 'All mobs'})},
      ...mobs.map(mob => ({id: mob.id, name: mob.mob_name})),
    ],
    [mobs, t]
  );
  const onEntityItemClick = (mobId: number) => {
    setParams({entityId: mobId});
    mixpanelCarbon.track('measurement_mob_filter');
  };

  return (
    <EntitySelectInner
      label={t(
        {id: 'MobLabel', defaultMessage: '{mobsNumber, plural, one {Mob} other {Mobs}}'},
        {mobsNumber: mobs.length}
      )}
      entities={items}
      activeEntityId={(activeMobId as number) || -1}
      onEntityItemClick={onEntityItemClick}
    />
  );
};

type Entity = {id: number; name: string};

type Props = {
  label: string;
  entities: Entity[];
  activeEntityId: number;
  testId?: string;
  onEntityItemClick: (entityId: number) => void;
};

export const EntitySelectInner: ComponentType<Props> = ({
  label,
  entities,
  activeEntityId,
  testId,
  onEntityItemClick,
}) => {
  const [expand, setExpand] = useState(false);

  const activeEntity = useMemo(
    () => entities.find(e => e.id === activeEntityId),
    [activeEntityId, entities]
  );

  if (!entities?.length) return null;

  if (entities.length === 1) {
    const [{name: entityName}] = entities;
    return <FluroChip active className={'entities-chip'} label={entityName} />;
  }

  return (
    <Flex className={'entities-selector'} alignItems="center" gap="0.5rem" data-testid={testId}>
      <Span>{label}</Span>
      <FluroChipDropdown
        expand={expand}
        setExpand={setExpand}
        active={activeEntity?.id !== -1}
        label={activeEntity?.name}
        tone="dimmed"
      >
        <CardFullWidth>
          <List>
            {entities.map(entity => {
              const isActive = activeEntityId === entity.id;
              const handleClick = isActive
                ? undefined
                : () => {
                    onEntityItemClick(entity.id);
                    setExpand(false);
                  };

              return (
                <ListItem
                  key={entity.id}
                  primaryText={entity.name}
                  active={isActive}
                  role="button"
                  onClick={handleClick}
                />
              );
            })}
          </List>
        </CardFullWidth>
      </FluroChipDropdown>
    </Flex>
  );
};
