import type {CSSProperties} from 'react';
import React, {useState, useEffect, useCallback} from 'react';
import {createPortal} from 'react-dom';
import {usePopper} from 'react-popper';
import styled, {css} from 'styled-components';

type PopoverProps = {
  children: React.ReactNode;
  targetId: string;
  /**
   * When setting `isOpen` as a boolean, showing or hiding the Popover is controlled via props rather than mouse events.
   */
  isOpen?: boolean;
  className?: string;
  style?: CSSProperties;
  backgroundColor?: string;
  size?: 'small' | 'medium';
  /**
   * TODO: Add support for other placements.
   */
  placement?: 'top' | 'bottom' | 'left' | 'right';
  /**
   * When `true`, the Popover will be positioned relative to the nearest scrolling container.
   */
  withinScrollingContainer?: boolean;
};

export function Popover({
  children,
  targetId,
  isOpen,
  className,
  style,
  backgroundColor = '#fff',
  size = 'small',
  placement = 'top',
  withinScrollingContainer = false,
}: PopoverProps): JSX.Element | null {
  const [visible, setVisible] = useState(isOpen || false);
  const [referenceElement, setReferenceElement] = useState<HTMLElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null);
  const [arrowElement, setArrowElement] = useState<HTMLDivElement | null>(null);
  const {styles, attributes, update} = usePopper(referenceElement, popperElement, {
    modifiers: [
      {
        name: 'arrow',
        options: {element: arrowElement, padding: 8},
      },
    ],
    placement,
  });

  function showPopover(): void {
    setVisible(true);
  }

  function hidePopover(): void {
    setVisible(false);
  }

  const conditionallyHidePopover = useCallback(
    (event: MouseEvent) => {
      if (event.target instanceof HTMLElement) {
        const target = event.target.closest(`#${targetId}`);
        if (!target) {
          hidePopover();
        }
      }
    },
    [targetId]
  );

  useEffect(() => {
    // update the position of the Popover
    update?.();

    const element = document.getElementById(targetId);
    if (!element) {
      // Popover could not find target element.
      // Make sure your target component isn't conditionally rendered when attempting to render the Popover.
      return;
    }

    element.style.cursor = 'pointer';
    element.style.userSelect = 'none';

    setReferenceElement(element);

    const componentIsControlled = typeof isOpen === 'boolean';
    if (!componentIsControlled) {
      document.addEventListener('click', conditionallyHidePopover);
      element.addEventListener('click', showPopover);
    } else {
      setVisible(isOpen);
    }

    return () => {
      if (!componentIsControlled) {
        document.removeEventListener('click', conditionallyHidePopover);
        element.addEventListener('click', showPopover);
      }
    };
  }, [conditionallyHidePopover, isOpen, targetId, update]);

  const PopperComponent = (
    // TODO: fixme a11y
    // eslint-disable-next-line styled-components-a11y/click-events-have-key-events
    <PopperPlacement
      ref={setPopperElement}
      style={styles.popper}
      {...attributes.popper}
      onClick={isOpen === undefined ? () => setVisible(false) : undefined}
    >
      <Container style={style} className={className} backgroundColor={backgroundColor} size={size}>
        {children}
        <Arrow
          ref={setArrowElement}
          backgroundColor={backgroundColor}
          style={styles.arrow}
          {...attributes.arrow}
          data-popper-arrow
        />
      </Container>
    </PopperPlacement>
  );

  if (!visible) {
    return null;
  }

  if (withinScrollingContainer) {
    return PopperComponent;
  }

  const popperRoot = document.getElementById('popper-root');
  if (!popperRoot) {
    return null;
  }

  return createPortal(PopperComponent, popperRoot);
}

type ContainerProps = Pick<PopoverProps, 'backgroundColor' | 'size'>;
const Container = styled.div<ContainerProps>`
  max-width: 250px;
  padding: 8px;
  color: #191411;
  text-align: left;
  background-color: ${({backgroundColor}) => backgroundColor};
  border-radius: 4px;

  ${({size}) => {
    switch (size) {
      case 'small':
        return css`
          font-size: 12px;
          line-height: 18px;
        `;

      case 'medium':
        return css`
          font-size: 14px;
          line-height: 20px;
        `;
    }
  }};
`;

type ArrowProps = {
  backgroundColor: string;
};
const Arrow = styled.div<ArrowProps>`
  width: 18px;
  height: 9px;
  background: inherit;
  visibility: hidden;

  &::before {
    position: absolute;
    bottom: 0;
    border-width: 0 9px 9px;
    border-color: transparent;
    border-style: solid;
    border-bottom-color: ${({backgroundColor}) => backgroundColor};
    visibility: visible;
    transform-origin: bottom center;
    content: '';
  }
`;

const PopperPlacement = styled.div`
  z-index: 16;
  filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.25));

  display: block;
  &[data-popper-placement^='bottom'] > ${Container} > ${Arrow} {
    top: 1px;
  }
  &[data-popper-placement^='bottom'] {
    padding-top: 10px;
  }

  &[data-popper-placement^='top'] > ${Container} > ${Arrow} {
    bottom: 1px;
    &::before {
      transform: rotate(180deg);
      bottom: 9px;
    }
  }
  &[data-popper-placement^='top'] {
    padding-bottom: 10px;
  }

  &[data-popper-placement^='right'] > ${Container} > ${Arrow} {
    left: 1px;
    height: 18px;
    width: 9px;
    &::before {
      transform: rotate(270deg);
      right: -9px;
      bottom: 9px;
    }
  }
  &[data-popper-placement^='right'] {
    padding-left: 10px;
  }

  &[data-popper-placement^='left'] > ${Container} > ${Arrow} {
    right: 1px;
    height: 18px;
    width: 9px;
    &::before {
      transform: rotate(90deg);
      left: -9px;
      bottom: 9px;
    }
  }
  &[data-popper-placement^='left'] {
    padding-right: 10px;
  }
`;
