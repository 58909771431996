import {useEffect} from 'react';
import {getAreasOfInterest} from '../actions/areas-of-interest-actions';
import {AsyncStatusType, setRequestStatus, Status} from 'modules/helpers';
import {useAppDispatch, useAppSelector, usePrevious} from '_hooks';
import {
  selectCurrentDate,
  selectCurrentDates,
  selectCurrentFieldId,
  selectCurrentSeason,
  selectCurrentSensor,
  selectCurrentFieldKml,
  selectCurrentImageName,
  selectCurrentTab,
} from '../reducer/selectors';
import {RequestStatus} from '../../../types';
import {
  selectIsAnomaliesTagExist,
  selectLowPerformingAnomalies,
  selectPremiumAnomalies,
} from '../features/anomalies/anomalies-selectors';
import {
  generateLowPerfAnomalies,
  getLowPerfAnomalies,
  getPremiumAnomalies,
} from '../actions/anomalies-actions';
import {selectAsyncRequestStatus} from 'modules/global/selectors';

export const AnomaliesEffects = (): null => {
  const dispatch = useAppDispatch();
  const currentFieldId = useAppSelector(selectCurrentFieldId);
  const currentFieldKml = useAppSelector(selectCurrentFieldKml);
  const feature = useAppSelector(selectCurrentTab);
  const currentSeason = useAppSelector(selectCurrentSeason);
  const lowPerfAnomalies = useAppSelector(selectLowPerformingAnomalies);
  const premiumAnomalies = useAppSelector(selectPremiumAnomalies);
  const currentDates = useAppSelector(selectCurrentDates);
  const currentSensor = useAppSelector(selectCurrentSensor);
  const currentDate = useAppSelector(selectCurrentDate);
  const currentImageName = useAppSelector(selectCurrentImageName);
  const AOIRequestStatus = useAppSelector(s =>
    selectAsyncRequestStatus(s, AsyncStatusType.fieldAreasOfInterest)
  );

  const tabsWithAllowedLoading = ['farm', 'crop', 'data-layers', 'analytics', 'zoning', 'tsp'];

  const prev = usePrevious({
    date: currentDate,
    sensor: currentSensor,
  });

  useEffect(() => {
    const fieldIsLoaded = currentDates && currentFieldKml.type;
    const dateOrSensorWasChanged = currentSensor !== prev?.sensor || currentDate !== prev?.date;

    if (!fieldIsLoaded || !tabsWithAllowedLoading.includes(feature)) {
      return;
    }

    if (
      dateOrSensorWasChanged &&
      lowPerfAnomalies.isVisible &&
      !lowPerfAnomalies.list[currentImageName]
    ) {
      dispatch(generateLowPerfAnomalies());
    }

    if (!currentSeason?.kmlId) return;

    if (
      premiumAnomalies.status !== RequestStatus.Success ||
      dateOrSensorWasChanged // if the anomalies were loaded, load on any tab
    ) {
      if (
        selectIsAnomaliesTagExist(currentSeason) &&
        premiumAnomalies.status !== RequestStatus.Loading &&
        Object.keys(currentDates).length
      ) {
        dispatch(getPremiumAnomalies());
      } else if (!lowPerfAnomalies.isLoaded) {
        dispatch(getLowPerfAnomalies());
      }
    }
  }, [
    currentSeason?.id,
    currentDates,
    currentDate,
    currentSensor,
    currentFieldKml,
    feature,
    lowPerfAnomalies,
  ]);

  useEffect(() => {
    if (!currentFieldId) return;
    if (!tabsWithAllowedLoading.includes(feature)) return;
    if (AOIRequestStatus !== Status.Todo) return;

    currentFieldId && dispatch(getAreasOfInterest(currentFieldId));
  }, [currentFieldId, feature, AOIRequestStatus]);

  useEffect(() => {
    if (currentFieldId) {
      // reset loaded AOI status
      dispatch(setRequestStatus(AsyncStatusType.fieldAreasOfInterest, Status.Todo));
    }
  }, [currentFieldId]);
  return null;
};
