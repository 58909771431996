import * as Sentry from '@sentry/react';
import {Integrations} from '@sentry/tracing';
import {applyWorkspace} from 'containers/admin/users/types';
import config from '_environment';

const environment = process.env.FLURO_ENV || 'dev';
const configuration = {
  enabled: environment !== 'local',
  environment,
  integrations: [new Integrations.BrowserTracing()],
  ...applyWorkspace({
    mrv: {
      dsn: 'https://d8446a04b6ce4e57ab9f054df2fa4b61@o281368.ingest.sentry.io/5832052',
      release: 'mrv-ui@' + config.app_version,
    },
    other: {
      dsn: 'https://ca1b6cdfc4874f41b48b2d26caf7c800@sentry.io/1506605',
      release: 'flurosense-ui@' + config.app_version,
    },
  }),
};

Sentry.init(configuration);
