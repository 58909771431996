// @ts-nocheck
import {selectFarmListIsPending, selectFarmsList} from 'modules/farms/selectors';
import type {Selector} from 'react-redux';
import type {AppStore} from 'reducers';
import {createSelector} from '@reduxjs/toolkit';
import {isAdminPerm} from '../../_utils';
import {SIAccess} from './types';
import {selectCurrentTab} from '../map/reducer/selectors';
import {aNewFieldSizeLimitAc, aNewFieldSizeLimitHa} from '_constants';
import type {CropTypeTranslationMRV} from 'modules/global/types';
import {capitalizeFirstLetter} from '_utils/pure-utils';

export const selectIsAdmin = (state: AppStore): boolean => isAdminPerm(state.login.user.perm);
export const selectUser = (s: AppStore) => s.login.user;

export const selectUserLocale = (s: AppStore) => s.login.user.settings.langLocale;

export const selectCropLabelPropMRV = createSelector([selectUserLocale], locale => {
  const l = locale.split('-')?.[0];

  return `label_${l}` as CropTypeTranslationMRV;
});

export const selectUserSettings = createSelector([selectUser], user => user.settings);

export const selectUserVisibleName = createSelector(
  [selectUser, (s: AppStore, includeSurname?: boolean) => includeSurname],
  (user, includeSurname) =>
    user.name ? `${user.name}${includeSurname ? ` ${user.surname}` : ''}` : user.email
);

export const selectMenubarUserNameOrEmail = createSelector([selectUser], user => {
  const {name, surname, email} = user;
  const classifiedSurname = surname ? `${capitalizeFirstLetter(surname?.[0])}.` : ''; // display only first character

  if (name.length) {
    return `${name} ${classifiedSurname}`;
  }

  return email;
});

export const selectUserIsImpersonated = (s: AppStore) => s.login.isImpersonated;
export const selectIsImpersonatorNotSuperAdmin = (s: AppStore) =>
  s.login.isImpersonatorNotSuperAdmin;
export const selectIsImpersonatorSuperAdmin = (s: AppStore) =>
  s.login.isImpersonated && !s.login.isImpersonatorNotSuperAdmin;

export const selectUserHasScenarioPlaningAccess = createSelector(
  [selectUser],
  user =>
    user.settings.si_tool_access !== SIAccess.Off || user.settings.scenarioPlanningTool !== null
);

export const selectUserHasScenarioPlaningAccessOnly = createSelector(
  [selectUser],
  user => user.settings.si_tool_access === SIAccess.SIOnly
);

export const selectUserId = createSelector([selectUser], user => user?.id || null);
export const selectUserGroupIds = (s: AppStore) => selectUser(s).groupIds;

export const selectHasFarms: Selector<AppStore, boolean> = createSelector(
  [selectIsAdmin, selectFarmsList],
  (isAdmin, farmsList) => {
    return Boolean(isAdmin || farmsList?.length);
  }
);

export const selectHasFarmsOrGroupsIds: Selector<AppStore, boolean> = createSelector(
  [selectIsAdmin, selectUserGroupIds, selectFarmsList, selectFarmListIsPending],
  (isAdmin, groupIds, farmsList, isPending) => {
    return Boolean(isAdmin || (!isPending && farmsList?.length) || groupIds?.length);
  }
);

export const selectSIAccessSetting = (s: AppStore) => s.login.user.settings.si_tool_access;

export const selectUserSISavedFilters = createSelector(
  [selectUserSettings],
  settings => settings.si_saved_filters || {}
);

export const selectIsHasAccessToSI: Selector<AppStore, boolean> = createSelector(
  [selectSIAccessSetting],
  siAccessSetting => {
    return siAccessSetting !== SIAccess.Off;
  }
);

export const selectIsSITabOpenWithAccess: Selector<AppStore, boolean> = createSelector(
  [selectIsHasAccessToSI, selectCurrentTab],
  (hasAccessToSI, currentTab) => {
    return Boolean(hasAccessToSI && currentTab === 'sustainability-insights');
  }
);

export const selectMeasurement = (state: AppStore) => state.login.user.settings.measurement;

export const selectFieldSizeLimit = createSelector([selectMeasurement], measurement =>
  measurement === 'ha' ? `${aNewFieldSizeLimitHa} ha` : `${aNewFieldSizeLimitAc} ac`
);

export const selectIsAuth = (state: AppStore) => state.login.isAuth;
