import {createSelector} from '@reduxjs/toolkit';
import chroma from 'chroma-js';
import {sortByKeyReverseUnsafe} from '_utils/sorters';
import {calculatePercentage, createCachingSelector} from '_utils/pure-utils';
import {SUMMARY_BARS_PREVIEW_THRESHOLD} from '../constants';
import type {
  AreaId,
  GroupedSummaryChartData,
  MetricYieldStatDatum,
  SIStateChildMeta,
  SocGhgSummaryChartData,
} from '../types';
import {SIGeometryFilterType} from '../types';
import {calculateScale, isInRange, parseAreaId} from '../utils';
import {
  getAreaId,
  selectActiveGeometriesIds,
  selectAggLevel,
  selectAreaUnits,
  selectCurrentMeta,
  selectGeometriesColors,
  selectIsYearsDiff,
  selectMetrics,
  selectSIChartGroupingType,
  selectSIFilter,
  selectStatesMeta,
  selectYearsFilterRange,
} from './selectors';
import groupBy from 'lodash/groupBy';
import {
  classifyExtremaPerUnitsFromValues,
  classifySocGhgStateStats,
  getDefaultExtremaPerUnits,
} from 'containers/map/features/sustainability-insights/filters-panel/cards/utils';

export const selectYieldCardState = createSelector(
  [selectSIFilter],
  f => f[SIGeometryFilterType.Yield]
);
export const selectYieldChartTab = createSelector(
  [selectYieldCardState],
  cardState => cardState.chartTab
);
export const selectYieldCardRange = createSelector(
  [selectYieldCardState],
  cardState => cardState.range
);

export const selectYieldStats = createSelector(
  [
    selectActiveGeometriesIds,
    selectMetrics,
    selectYearsFilterRange,
    selectGeometriesColors,
    selectCurrentMeta,
  ],
  (activeIds, data, yearsRange, colorsMap, currentMeta): Record<number, MetricYieldStatDatum> => {
    if (!data || !activeIds?.length || !yearsRange?.length) return {};

    const numValuesPerId: Record<AreaId, {totalValue: number; totalArea: number}> = {}; // store values per id to prevent double calculations
    let totalValues = 0; // classify total values sum to calculate percentage later

    activeIds.forEach(id => {
      const dataPerYear = data[id];
      if (!dataPerYear) {
        return;
      }
      let yieldValuePerEntityAndYears = 0;
      let yieldAreaPerEntityAndYears = 0;

      yearsRange.forEach((year: number) => {
        yieldValuePerEntityAndYears =
          yieldValuePerEntityAndYears + Number(dataPerYear[year]?.crop_yield); // get total value per years
        yieldAreaPerEntityAndYears =
          yieldAreaPerEntityAndYears + Number(dataPerYear[year]?.kpi_area_ha); //  get total area per years
      });

      if (!yieldValuePerEntityAndYears) return;
      numValuesPerId[id] = {
        totalValue: yieldValuePerEntityAndYears,
        totalArea: yieldAreaPerEntityAndYears,
      };
      totalValues = totalValues + yieldValuePerEntityAndYears;
    });

    const stats = activeIds.reduce<{[key: number]: MetricYieldStatDatum}>((acc, id: number) => {
      const dataPerYear = data[id];
      const entityTotalValue = numValuesPerId[id]?.totalValue;
      const entityTotalArea = numValuesPerId[id]?.totalArea;

      if (!dataPerYear || entityTotalValue === undefined) {
        return acc;
      }

      const {name, statefp} = (currentMeta[id] || {}) as SIStateChildMeta;

      acc[id] = {
        // classify a chart entity
        id,
        name,
        statefp,
        values: {
          num: entityTotalValue,
          pct: calculatePercentage(entityTotalValue, totalValues),
          rawNumValue: entityTotalValue, // use to get later the total values
          area: entityTotalArea,
        },
        color: colorsMap[id],
      };

      return acc;
    }, {});

    return stats;
  }
);

export const selectYieldStateStats = createSelector(
  [selectAggLevel, selectYieldStats],
  (aggLevel, stats) => classifySocGhgStateStats(aggLevel, stats)
);

export const selectActiveYieldStatsList = createSelector(
  [selectActiveGeometriesIds, selectYieldStats],
  (ids, stats) => {
    if (!stats) return [];
    return ids.reduce((acc: MetricYieldStatDatum[], id: number) => {
      const stat = stats[id];
      if (!stat) return acc;
      return [...acc, stat];
    }, []);
  }
);

export const selectYieldStatsById = createSelector(
  [selectYieldStats, getAreaId],
  (stats, id) => stats?.[id]
);

type Extrema = {num: {min: number; max: number}; pct: {min: number; max: number}};

export const selectYieldExtremaPerUnits = createSelector(
  [selectActiveYieldStatsList],
  (stats): Extrema => {
    if (!stats?.length) {
      return getDefaultExtremaPerUnits();
    }

    const values: {num: number[]; pct: number[]} = {num: [], pct: []};

    stats.forEach(stat => {
      const numStat = stat?.values?.num || 0;
      const pctStat = stat?.values?.pct || 0;
      values.num.push(numStat);
      values.pct.push(pctStat);
    });

    return classifyExtremaPerUnitsFromValues(values);
  }
);
export const selectYieldGroupedExtremaPerUnits = createSelector(
  [selectActiveYieldStatsList, selectYieldStateStats, selectSIChartGroupingType],
  (stats, stateStats, groupingType): Extrema => {
    // get min/max values including grouped state values that might be higher than not grouped
    const values: {num: number[]; pct: number[]} = {num: [], pct: []};

    if (!stats.length || groupingType !== 'grouped' || !stateStats)
      return getDefaultExtremaPerUnits();

    stats.forEach(stat => {
      const numStat = stat?.values?.num || 0;
      const pctStat = stat?.values?.pct || 0;
      values.num.push(numStat);
      values.pct.push(pctStat);
    });

    Object.values(stateStats).forEach(stat => {
      const numStat = stat?.num || 0;
      const pctStat = stat?.pct || 0;
      values.num.push(numStat);
      values.pct.push(pctStat);
    });

    return classifyExtremaPerUnitsFromValues(values);
  }
);

export const selectYieldStatsExtrema = createSelector(
  [selectAreaUnits, selectYieldExtremaPerUnits],
  (units, extrema) => extrema[units]
);
export const selectYieldGroupedStatsExtrema = createSelector(
  [selectAreaUnits, selectYieldGroupedExtremaPerUnits],
  (units, extrema) => extrema[units]
);

export const selectYieldColorScaleValues = createSelector(
  [selectYieldStatsExtrema],
  ({min, max}) => {
    if (max <= 0) {
      return {colors: ['#37681c', '#fff']};
    } else if (min >= 0) {
      return {colors: ['#fff', '#37681c']};
    }

    const zero = calculateScale(0, min, max);

    return {colors: ['red', 'white', '#37681c'], domain: [0, zero, 1]};
  }
);

export const selectYieldColorScale = createSelector(
  [selectIsYearsDiff, selectYieldColorScaleValues],
  (isDiff, {colors, domain}) => {
    // if (!isDiff) return {colors: ['#ffffff', '#37681c']};

    return {colors, domain};
  }
);

export const selectYieldChromaScale = createSelector(selectYieldColorScale, ({colors, domain}) => {
  const scale = chroma.scale(colors);
  if (domain) {
    scale.domain(domain);
  }

  return scale;
});

export const selectYieldColorById = createSelector(
  [selectYieldStatsById, selectYieldStatsExtrema, selectYieldChromaScale, selectAreaUnits],
  (stats, {max, min}, colorScale, units) => {
    if (!stats) return;
    const value = stats.values?.[units];
    const scale = calculateScale(value, min, max);
    const color = colorScale(scale).css();

    return color;
  }
);

export const selectYieldSummaryChartData = createSelector(
  [
    selectActiveYieldStatsList,
    selectYieldStateStats,
    selectYieldCardRange,
    selectAreaUnits,
    selectStatesMeta,
    selectSIChartGroupingType,
  ],
  (stats, stateStats, range, units, statesMeta, groupingType): SocGhgSummaryChartData | null => {
    if (!stats?.length) return null;
    let totalValue = 0;
    let totalArea = 0;
    let values: number[] = [];
    const valuesById: Record<number, number> = {};
    let chartData = stats;
    const chartGeometries: Record<string, boolean> = {};
    const chartGeometriesIds: number[] = [];

    chartData.forEach(stat => {
      const value = stat.values[units] || 0;
      const rawNumValue = stat.values?.rawNumValue || 0;
      const area = stat.values?.area || 0;
      stat.value = value;

      valuesById[stat.id] = value;

      values = values.concat(value);
      totalValue = totalValue + rawNumValue;
      totalArea = totalArea + area;
    });

    // Apply filters for chart data
    const preview = chartData.length > SUMMARY_BARS_PREVIEW_THRESHOLD;
    if (preview && range) {
      chartData = chartData.filter(d => isInRange(Number(d.value), range));
    }

    chartData.forEach(stat => {
      // add geometries after range filter
      chartGeometries[stat.id] = true;
      chartGeometriesIds.push(stat.id);
    });

    if (groupingType === 'grouped') {
      const groupedByState = groupBy(sortByKeyReverseUnsafe(chartData, 'value'), 'statefp');
      chartData = sortByKeyReverseUnsafe(
        Object.keys(groupedByState).reduce((acc: GroupedSummaryChartData[], stateFP: string) => {
          const value = stateStats?.[Number(stateFP)]?.[units];
          if (!value) return acc;
          const name = statesMeta[parseAreaId(stateFP)]?.name?.toUpperCase?.();
          const data = groupedByState[stateFP];

          return [
            ...acc,
            {
              data,
              name,
              value,
            } as GroupedSummaryChartData,
          ];
        }, []),
        'value'
      );
    }

    chartData = sortByKeyReverseUnsafe(chartData, 'value');

    return {
      type: groupingType,
      chartData,
      values,
      valuesById,
      preview,
      totalValue: (totalValue * totalArea) / 1000000,
      chartGeometries,
      chartGeometriesIds,
    };
  }
);

export const selectYieldGeometryValueById = createCachingSelector(
  [selectYieldSummaryChartData, getAreaId],
  (summaryData, id) => summaryData?.valuesById?.[id]
);

export const isGeometryInYieldSummaryChart = createSelector(
  [selectYieldSummaryChartData, getAreaId],
  (data, id) => Boolean(data?.chartGeometries?.[id])
);
