import {Flex, Text} from 'components';
import {CardHr} from 'components/card/card-styled';
import {Span} from 'components/text/span';
import {MapBarContent} from 'containers/map/map-bar/map-bar-content';
import {
  selectAvailableMetricGroups,
  selectGeometryTypeFilter,
  selectIsSIDataLoading,
} from 'modules/sustainability-insights/selectors';
import {selectIsMapLoading, selectIsSI} from 'modules/global/selectors';
import {SIGeometryFilterType} from 'modules/sustainability-insights/types';
import type {ComponentType} from 'react';
import React from 'react';
import {useAppSelector} from '_hooks';
import {YearsSelect} from '../years-select';
import AbatementPotentialMetricFilter from './abatement-potential-metric-filter';
import {AreaUnitsSelect} from './area-units-select';
import {
  AbatementCard,
  CoverCropsCard,
  TillageCard,
  SocCard,
  GhgCard,
  YieldCard,
  FertilizerCard,
  FieldEmissionsFactorCard,
} from './cards';
import './filters-panel.scss';
import {GrowerDemographicsFilters} from './grower-demographics-filters/grower-demographics-filters';
import {MetricsTypeSwitch} from './metrics-type-switch';
import {SIAreaSelect} from './si-area-select';
import {SummerCropTypeFilter} from './summer-crop-type-filter';
import SICardTypeRouter from '../base/si-card-type-router';
import {MapBarHeader} from 'containers/map/map-bar/map-bar-header';
import {SaveFilter} from 'containers/map/features/sustainability-insights/filters-panel/save-filter';

type Props = {};

export const FiltersPanel: ComponentType<Props> = () => {
  const availableMetrics = useAppSelector(selectAvailableMetricGroups);
  const activeFilterType = useAppSelector(selectGeometryTypeFilter);
  const allowGrowerDemographics = availableMetrics.includes('grower_demographics');
  const allowCoverCrops = availableMetrics.includes('cover_crops');
  const allowTillage = availableMetrics.includes('tillage_practices');
  const allowAbatement = availableMetrics.includes('carbon');
  const allowGHG = availableMetrics.includes('ghg');
  const allowSOC = availableMetrics.includes('soc_sequestration');
  const allowYield = availableMetrics.includes('crop_yield');
  const allowFertilizer = availableMetrics.includes('fertilizer');
  const allowEmissionsFactor = availableMetrics.includes('ef');
  const isAbatementActive = activeFilterType === SIGeometryFilterType.AbatementPotential;
  const isDataLoading = useAppSelector(selectIsSIDataLoading);
  const isMapLoading = useAppSelector(selectIsMapLoading);
  const isSI2 = useAppSelector(selectIsSI); // Is in SIv2 selector

  return (
    <MapBarContent className="sustainability-insights__filter-panel">
      <MapBarHeader
        title="Sustainability insights"
        sticky
        isLoading={isDataLoading || isMapLoading}
      />

      {isSI2 && !isAbatementActive && <SaveFilter isLoading={isDataLoading || isMapLoading} />}
      <Flex justifyContent={'space-between'} nowrap gap="0.5rem">
        {!isAbatementActive && <YearsSelect />}

        {allowAbatement && isAbatementActive ? (
          <AbatementPotentialMetricFilter />
        ) : (
          <SummerCropTypeFilter />
        )}
      </Flex>
      <CardHr />

      <SIAreaSelect />
      <CardHr />

      {activeFilterType === SIGeometryFilterType.CoverCrops &&
        !isAbatementActive &&
        allowGrowerDemographics && (
          <>
            <GrowerDemographicsFilters />
            <CardHr />
          </>
        )}

      {!isAbatementActive && (
        <div>
          <Span>Data Display</Span>
          <Flex nowrap alignItems="center" justifyContent="space-between">
            {allowGrowerDemographics && (
              <SICardTypeRouter type={SIGeometryFilterType.CoverCrops}>
                <div className="selection-control-col">
                  <MetricsTypeSwitch />
                </div>
                <span className="selection-control-divider" />
              </SICardTypeRouter>
            )}
            <div className="selection-control-col">
              <AreaUnitsSelect />
            </div>
          </Flex>
        </div>
      )}

      <div className="cards-container">
        {!isAbatementActive && ( // abatement card appearing above other cards
          <>
            {(allowCoverCrops || allowTillage) && (
              <Text className={'margin-bottom-0 margin-top-15'} elementType="h2">
                Practice KPIs
              </Text>
            )}
            {allowCoverCrops && (
              <CoverCropsCard isActive={activeFilterType === SIGeometryFilterType.CoverCrops} />
            )}

            {allowTillage && (
              <TillageCard isActive={activeFilterType === SIGeometryFilterType.Tillage} />
            )}

            {allowFertilizer && (
              <FertilizerCard isActive={activeFilterType === SIGeometryFilterType.Fertilizer} />
            )}

            {(allowGHG || allowSOC || allowYield || allowEmissionsFactor) && (
              <Text className={'margin-bottom-0 margin-top-15'} elementType="h2">
                Outcome KPIs
              </Text>
            )}
            {allowGHG && <GhgCard isActive={activeFilterType === SIGeometryFilterType.GHG} />}
            {allowSOC && <SocCard isActive={activeFilterType === SIGeometryFilterType.SOC} />}
            {allowYield && <YieldCard isActive={activeFilterType === SIGeometryFilterType.Yield} />}
            {allowEmissionsFactor && (
              <FieldEmissionsFactorCard
                isActive={activeFilterType === SIGeometryFilterType.EmissionsFactor}
              />
            )}
          </>
        )}

        {allowAbatement && <AbatementCard isActive={isAbatementActive} />}
      </div>
    </MapBarContent>
  );
};
