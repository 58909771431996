import type {CancelTokenSource} from 'axios';
import type {RequestStatus} from 'types';
import type {CPFilterType} from '_reducers/crop-performance-filter/field-filter-types';
import type {NdviQuartiles} from './biomass/biomass';
import type {PeriodType} from './helpers/periodic';
import type {StressedFieldsPerDate} from 'containers/map/features/anomalies/types';

export enum ReasonCode {
  Unknown,
  NotSown,
  Cloudy,
  NoImages,
  Fallow,
}

export const ReasonLabel = {
  [ReasonCode.Unknown]: 'unknown',
  [ReasonCode.NotSown]: 'not sown',
  [ReasonCode.Cloudy]: 'cloudy',
  [ReasonCode.NoImages]: 'no images',
  [ReasonCode.Fallow]: 'fallow',
};

export enum RequestCancellationReason {
  NEW_REQUEST = 'Cancelled by new request',
  FARM_CHANGED = 'Cancelled by farm change',
}

export enum Aggregation {
  SUM = 'sum',
  COUNT = 'count',
}

export type WithFieldArea = {
  fieldArea: number;
};

export type CropPerformanceState = {
  info?: CSGInfo;
  farms: {[id: number]: CropPerformanceFarm};
  records: CSGViewModel[]; // `farms` reduced to `fields` for `currentDate`
  twoWeeksAgoRecords: CSGViewModel[]; // `farms` reduced to `fields` for `currentDate - 2 weeks` (used in Crop Stress card only)
  representation: CPFilterType; // Current active crop perf card. Used both in panel and on map.
  aggregation: Aggregation; // Aggregation used in charts (sum area or count fields).
  useUnreliableData: boolean; // Dates when latest imagery is >10 days old we put into "No images" by default. But users can see the value of the latest imagery anyway if they decide to.
  includeGrowthStage: boolean;
  ndviQuartiles: {[cropTypeVariety: string]: NdviQuartiles}; // Key is `${cropType}_${cropVariety}`.
  period: PeriodType;
  fieldsVariability: FieldsVariability;
  stressedFields: {[farmId: number]: StressedFieldsPerDate[]};
  cropStressChartData: CropStressChartData;
  benchmark: CropPerformanceBenchmark;
  loaded: boolean; // represents if the farms data is loaded
};

export enum CropStatusSequence {
  BROADCARE_CROPS = 'Broadacre crops',
  GRAPES = 'Grapes',
  HOPS = 'Hops',
  NUT_TREES = 'Nut trees',
  OLIVES = 'Olives',
  TREES = 'Trees',
}

export enum CropStatus {
  NOT_EMERGED = 'Not emerged',
  EMERGED = 'Emerged',
  CROP_DEVELOPMENT = 'Crop development',
  CLOSED_CANOPY = 'Closed canopy',
  SENESCING = 'Senescing',
  HARVESTED = 'Harvested',
}

export enum TreeStatus {
  POST_HARVEST = 'Post harvest',
  LEAF_FALL = 'Leaf fall',
  LEAF_FLUSH = 'Leaf flush',
  FLOWER_DEVELOPMENT = 'Flower development',
  DORMANT = 'Dormant',
  FRUIT_SET = 'Fruit set',
  SPROUTING = 'Sprouting',
  FRUIT_GROWTH = 'Fruit growth',
  VERAISON = 'Veraison',
  CONE_DEVELOPMENT = 'Cone development',
  FRUIT_FILL = 'Fruit fill',
  MATURING = 'Maturing',
  HARVEST = 'Harvest',
}

export enum FieldVariabilityStatus {
  noData = 'No data',
  low = 'Low (<30)',
  med = 'Moderate (>31, <50)',
  high = 'High (>51)',
}

export type FieldsVariability = {
  [fieldId: string]: {value: number; status: FieldVariabilityStatus};
};

export type CropPerformanceFarm = {
  id: number;
  farmName?: string;
  signal?: CancelTokenSource;
  status: RequestStatus;
  csgs?: {
    [fieldId: number]: {
      seasons: {
        [seasonId: string]: {
          dates: {[date: string]: CSGViewModel};
          sequence: CropStatusSequence;
        };
      };
    };
  };
};

export type CropPerformanceBenchmark = {
  recordsToProcess: {[seasonId: number]: boolean};
};

// https://github.com/flurosat/flurosat-py/blob/master/crop_performance/crop_status_and_growth/crop_status_and_growth.py#L16-L21
export const cropStatusWeight: {[key: string]: number} = {
  // Crop statuses:
  'Not emerged': 1,
  Emerged: 2,
  'Crop development': 3,
  'Closed canopy': 4,
  Senescing: 5,
  Harvested: 6,

  // Tree statuses:
  'Post harvest': 11,
  'Leaf fall': 12,
  'Leaf flush': 13,
  Dormant: 14,
  Sprouting: 15,
  'Flower Development': 16,
  'Flower development': 17,
  Flowering: 18,
  'Fruit set': 19,
  'Fruit growth': 20,
  'Fruit fill': 21,
  Vegetative: 22,
  Veraison: 23,
  'Cone development': 24,
  Maturing: 25,
  Harvest: 26,

  'No images': 100,
};

export const fieldsVariabilityColors = {
  [FieldVariabilityStatus.noData]: '#cfd8dc',
  [FieldVariabilityStatus.low]: '#ede5f8',
  [FieldVariabilityStatus.med]: '#a883dc',
  [FieldVariabilityStatus.high]: '#7e30ef',
};

export type CropGrowth = -10 | -3 | -2 | -1 | 0 | 1 | 2 | 3; // -10 is for No images

export const cropGrowthColors = {
  [-10]: 'rgba(207, 216, 220, 1)',
  [-3]: 'rgba(121, 46, 0, 1)',
  [-2]: 'rgba(190, 115, 32, 1)',
  [-1]: 'rgba(255, 191, 71, 1)',
  0: 'rgba(242, 218, 165, 1)',
  1: 'rgba(75, 234, 196, 1)',
  2: 'rgba(0, 164, 144, 1)',
  3: 'rgba(34, 132, 121, 1)',
};

export const cropGrowthLabels: {[key: string]: string} = {
  '3': 'Fast growth',
  '2': 'Moderate growth',
  '1': 'Slow growth',
  '0': 'Stable',
  '-1': 'Slow decline',
  '-2': 'Moderate decline',
  '-3': 'Fast decline',
  '-10': 'No images',
};

export const cropGrowthLabelsToValues: {[key: string]: CropGrowth} = {
  'Fast growth': 3,
  'Moderate growth': 2,
  'Slow growth': 1,
  Stable: 0,
  'Slow decline': -1,
  'Moderate decline': -2,
  'Fast decline': -3,
  'No images': -10,
};

// Crop growth stage

export const CROP_TYPES_FOR_GROWTH_STAGES = ['corn'];

export const CropGrowthStages = [
  '01',
  '09',
  '11',
  '13',
  '15',
  '17',
  '19',
  '51',
  '65',
  '87',
] as const;
export type CropGrowthStage = typeof CropGrowthStages[number];

export type CropGrowthStageLabel =
  | 'Not emerged'
  | 'Emerged'
  | 'One leaf'
  | 'Three leaves'
  | 'Five leaves'
  | 'Seven leaves'
  | 'Nine leaves'
  | 'Tassle emergence'
  | 'Silking'
  | 'Black layer maturity';

export const cropGrowthStageLabels: {[stage: string]: CropGrowthStageLabel} = {
  '01': 'Not emerged',
  '09': 'Emerged',
  '11': 'One leaf',
  '13': 'Three leaves',
  '15': 'Five leaves',
  '17': 'Seven leaves',
  '19': 'Nine leaves',
  '51': 'Tassle emergence',
  '65': 'Silking',
  '87': 'Black layer maturity',
};

export const cropGrowthStageColors: {[stage: string]: string} = {
  '01': '#4D4D4D',
  '09': '#F8F036',
  '11': '#f8e123',
  '13': '#FFD600',
  '15': '#01EF6F',
  '17': '#7BC342',
  '19': '#3F820A',
  '51': '#A1BCFF',
  '65': '#0047FF',
  '87': '#0030AD',
};

export const cropGrowthStageLabelsToValues: {[key: string]: CropGrowthStage} = {
  'Not emerged': '01',
  Emerged: '09',
  'One leaf': '11',
  'Three leaves': '13',
  'Five leaves': '15',
  'Seven leaves': '17',
  'Nine leaves': '19',
  'Tassle emergence': '51',
  Silking: '65',
  'Black layer maturity': '87',
};

// Biomass
export type Biomass = 'noimages' | 'low' | 'medium' | 'high';

export const biomassColors = {
  low: 'rgba(191, 236, 191, 1)',
  medium: 'rgba(95, 210, 99, 1)',
  high: 'rgba(57, 157, 43, 1)',
  noimages: 'rgb(207, 216, 220)',
};

export const biomassLabels = {
  low: 'Low vigor',
  medium: 'Medium vigor',
  high: 'High vigor',
  noimages: 'No images',
};

export const biomassLabelsToValues: {[key: string]: Biomass} = {
  'Low vigor': 'low',
  'Medium vigor': 'medium',
  'High vigor': 'high',
  'No images': 'noimages',
};

export enum CropStressChartLabels {
  NoImages = 'No data',
  NotActivated = 'Not activated',
  NoStressDetected = 'No stress',
  StressDetected = 'Stress detected',
}

export const cropStressLabelColors: {[label in CropStressChartLabels]: string} = {
  [CropStressChartLabels.StressDetected]: '#AA1E22',
  [CropStressChartLabels.NoStressDetected]: '#A2D29B',
  [CropStressChartLabels.NotActivated]: '#CFD8DC',
  [CropStressChartLabels.NoImages]: '#BBBBBB',
};

export type CropStressChartData = {[fieldId: number]: CropStressChartLabels};

export type CSGInfo = {
  colours: {[status: string]: string};
  status_seq: {[sequence in CropStatusSequence]: string[]};
};

export type AverageNDVIPayload = {
  md5?: string;
  geometry_id?: string; // use the geometry_id of the planting area to get avg data for it
  start_date: string;
  end_date: string;
  crop_type: string;
  key: number | string;
};

export type CSGResponse = {
  [fieldId: string]: {
    data: {
      // `date` example 20200814T003709.
      // If time is zero (20200814T000000),
      // there is no imagery and the CSGResponseDate is smoothed.
      [date: string]: CSGResponseDate;
    };
    status_seq_name: CropStatusSequence;
  };
};

export type FieldsVariabilityResponse = {
  [fieldId: number]: {
    img_count: number | null;
    peak_date: string | null;
    var_score: number | null;
  };
};

export type CSGResponseDate = {
  crop_growth: CropGrowth;
  crop_status: CropStatus | TreeStatus;
  ndvi_smooth: number;
  predicted: boolean;
  growth_stage: CropGrowthStageLabel;
  bbch_code: CropGrowthStage;
};

/**
 * CSG stands for Crop Status Growth.
 */
export type CSGViewModel = {
  farmId: number;
  farmName: string;
  fieldID: number;
  fieldName: string;
  fieldArea: number; // in acres

  // Season.
  cropType: string;
  cropVariety?: string;
  startDate: string; // YYYYMMDDTHHMMSS
  endDate: string; // YYYYMMDDTHHMMSS
  seasonId: number;
  seasonName: string; // planting area name
  isPlantingArea: boolean;

  // Crop status & growth (CS&G).
  date: string; // YYYYMMDD
  lastImageryDate: string; // YYYYMMDD
  imagery: boolean; // is there an imagery for this date or the values are smoothed
  reliable: boolean; // If the gap between the current date and the very latest imagery is more than 10 days, treat the data as unreliable.
  predicted: boolean; // The data is after the latest imagery, but the status was predicted.
  growth: CropGrowth;
  cropStatus: CropStatus | TreeStatus | 'No images' | 'Fallow';
  growthStage?: CropGrowthStageLabel;
  growthStageCode?: CropGrowthStage;
  smoothSatelliteNdvi: number;
  cumulativeSmoothSatelliteNdvi: number; // cumulative ndvi
};

export type CSGAvgViewModel = Pick<
  CSGViewModel,
  | 'cropType'
  | 'cropVariety'
  | 'fieldArea'
  | 'cropStatus'
  | 'growth'
  | 'smoothSatelliteNdvi'
  | 'predicted'
  | 'reliable'
  | 'cumulativeSmoothSatelliteNdvi'
>;
