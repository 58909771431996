import {t, FormattedMessage} from 'i18n-utils';
import React, {useEffect} from 'react';
import {FontIcon} from 'react-md';
import {FluroButton, Ln} from 'components';
import {toggleMapBar, toggleTableView} from 'containers/map/actions';
import {WarningSvg} from 'components/icons';
import {toggleGlobalDialog, toggleTooltip} from 'modules/global/actions';
import {showNotification} from 'components/notification/notification';
import {useAppDispatch, useAppSelector} from '_hooks';
import {dialogToggle, DialogType} from 'modules/helpers';
import {selectCurrentFieldId} from 'containers/map/reducer/selectors';
import {
  selectFarmsFieldsAddedTo,
  selectNotUploadedFields,
  selectUploadedFieldsNumber,
} from 'modules/add-fields/selectors';
import {useWorkspace} from '_hooks/use-workspace';

type Props = {
  onHide?(): void;
};
const CompleteStep = ({onHide}: Props) => {
  const errorFieldsUploadMessage = t({
    id: 'Make sure the files contain polygons geometries (not points, lines or images) and try to upload them again.',
  });

  const {isWorkspaceMrv} = useWorkspace();
  const dispatch = useAppDispatch();
  const selectedFieldId = useAppSelector(selectCurrentFieldId);
  const notUploadedFields = useAppSelector(selectNotUploadedFields);
  const farmsFieldsAddedTo = useAppSelector(selectFarmsFieldsAddedTo);
  const uploadedFieldsNumber = useAppSelector(selectUploadedFieldsNumber);

  useEffect(() => {
    if (isWorkspaceMrv) {
      // skip the complete step for the carbon
      dispatch(dialogToggle(DialogType.addNewField, false));
      dispatch(toggleMapBar(true));

      if (uploadedFieldsNumber) {
        showNotification({
          title: t({id: 'note.success'}),
          message: t({id: 'The {count} successfully added.'}, {count: uploadedFieldsNumber}),
          type: 'success',
        });
      }
    }
  }, []);

  const setUpCrops = (fromFile = false) => {
    onHide?.();

    if (uploadedFieldsNumber === 1) {
      dispatch(toggleGlobalDialog('editSeason', {visible: true, fieldId: selectedFieldId}, true));
      return;
    }

    dispatch(toggleTableView('farm'));
    if (fromFile) {
      dispatch(dialogToggle(DialogType.importSeasons, true));
    }
  };

  return (
    <>
      <div className={'upload-complete'}>
        {uploadedFieldsNumber !== 0 && (
          <div className={'uploaded-fields'}>
            <FontIcon className={'icon'}>done</FontIcon>{' '}
            <FormattedMessage
              id="field was uploaded to the following farm(s)"
              values={{count: uploadedFieldsNumber}}
            />
          </div>
        )}

        {farmsFieldsAddedTo.length !== 0 && (
          <div className={'farms-fields-were-uploaded-to'}>
            {farmsFieldsAddedTo.map(({id, name, isNew}, index) => (
              <React.Fragment key={id}>
                <Ln map blank href={`${id}`}>
                  <span className={'as-link'}>{name}</span>{' '}
                  {isNew ? <span className={'new'}>{t({id: 'New'})}</span> : ''}
                </Ln>
                {farmsFieldsAddedTo.length === index + 1 ? null : ', '}
              </React.Fragment>
            ))}
          </div>
        )}

        {notUploadedFields.length !== 0 && <div className={'delimiter'} />}

        {notUploadedFields.length !== 0 && (
          <div className={'fields-not-uploaded'}>
            <div className="warning-message">
              <WarningSvg />{' '}
              <FormattedMessage
                id="field could not be uploaded"
                values={{count: notUploadedFields.length}}
              />
              <FontIcon
                className={'info-icon'}
                data-for={'fail-to-upload-some-fields'}
                data-tip={''}
                onMouseEnter={() =>
                  dispatch(
                    toggleTooltip({
                      id: `fail-to-upload-some-fields`,
                      content: errorFieldsUploadMessage,
                    })
                  )
                }
              >
                help_outline
              </FontIcon>
            </div>
            <div className={'fields-not-uploaded__list'}>{notUploadedFields.join(', ')}</div>
          </div>
        )}
      </div>

      <div className="new-fields-nav-container complete">
        {t({id: 'Set up your crops'})}:
        <FluroButton className={'control-btn next'} raised primary onClick={() => setUpCrops(true)}>
          {t({id: 'Set up from file'})}
        </FluroButton>
        <FluroButton className={'control-btn next'} raised onClick={() => setUpCrops()}>
          {t({id: 'Set up manually'})}
        </FluroButton>
      </div>
    </>
  );
};

export default CompleteStep;
