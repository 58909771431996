import type {ReactElement, ComponentType} from 'react';
import React from 'react';
import cn from 'classnames';
import {CircularProgress} from 'react-md';
import {Circle} from 'components/shapes/circle';
import './fluro-progress-icon.scss';

type Props = {
  id: string | number;
  progressValue?: number;
  badge?: Badge;
  icon: string | ReactElement;
  /**
   * display progress arc outline
   */
  outlined?: boolean;
  active?: boolean;
  disabled?: boolean;
  hideProgressLabel?: boolean;
};
export const FluroProgressIcon: ComponentType<Props> = ({
  id,
  progressValue,
  badge,
  icon,
  outlined = false,
  active,
  disabled,
  hideProgressLabel,
}) => {
  return (
    <div
      className={cn('fluro-progress-icon', {
        percentage: progressValue !== undefined || outlined,
        active,
        disabled,
      })}
    >
      <div className={cn('icon')}>
        <ItemIcon icon={icon} />
      </div>
      <ProgressItem
        value={progressValue}
        badge={badge}
        id={'progress-id' + id}
        outlined={outlined}
        hideProgressLabel={hideProgressLabel}
      />
    </div>
  );
};

const ItemIcon = ({icon}: {icon: Props['icon']}) => {
  if (!icon) return null;

  if (typeof icon === 'string') {
    const maskStyle = `url(${icon}) no-repeat center`;
    return <div style={{mask: maskStyle, WebkitMask: maskStyle}} className={'item'} />;
  }
  return icon;
};

export type ProgressItemProps = {
  value?: number;
  id: string;
  outlined: boolean;
  badge?: Badge;
  hideProgressLabel?: boolean;
};

const ProgressItem: ComponentType<ProgressItemProps> = ({
  value,
  id,
  outlined,
  badge,
  hideProgressLabel,
}) => {
  const displayPercentage = value !== undefined;
  if (!displayPercentage && !outlined) return null;

  return (
    <div className={'progress-container'}>
      {displayPercentage && (
        <CircularProgress className={'progress-bar top-layout-progress'} id={id} value={value} />
      )}

      {value !== 100 && (
        <CircularProgress className={'progress-bar bottom-layout'} id={id + 'bottom'} value={100} />
      )}

      {badge ? (
        <Circle size={10} color={getBadgeColor(badge)} className="progress-badge" />
      ) : (
        displayPercentage && !hideProgressLabel && <div className={'progress-label'}>{value}%</div>
      )}
    </div>
  );
};

type Badge = 'info' | 'error';
function getBadgeColor(badge: Badge) {
  switch (badge) {
    case 'error':
      return 'red';
    case 'info':
      return 'blue';
  }
}
