import {useCallback} from 'react';
import useSWRImmutable from 'swr/immutable';
import useSWRMutation from 'swr/mutation';
import type {MRVEntityType, MRVMob} from '../types';
import {useMobsRequestKey} from './use-mobs-request-key';
import {MRVApi} from '_api';
import {reportError} from 'containers/error-boundary';

type UpdateMobsRequest = {
  creates: string[];
  updates: {id: number; name: string}[];
  deletes: number[];
};

export const useMobs = (projectId: number, entityType: MRVEntityType | undefined) => {
  const requestKey = useMobsRequestKey(projectId);
  const {
    data: mobs,
    error,
    mutate,
  } = useSWRImmutable<MRVMob[]>(entityType === 'mob' ? requestKey : null, {onError: reportError});
  const loading = !mobs && !error;

  const updateMobs = useCallback(
    async (key: string, {arg}: {arg: UpdateMobsRequest}): Promise<void> => {
      const {creates, updates, deletes} = arg;
      const promises = [];
      if (creates.length) {
        promises.push(MRVApi.createMobs({projectId, mobNames: creates}));
      }
      if (updates.length) {
        for (const {id, name} of updates) {
          promises.push(MRVApi.updateMob({projectId, mobId: id, mobName: name}));
        }
      }
      if (deletes.length) {
        promises.push(MRVApi.deleteMobs({projectId, mobIds: deletes}));
      }
      await Promise.all(promises);
      await MRVApi.createRecordYears(projectId);
    },
    [projectId]
  );
  const {trigger, isMutating} = useSWRMutation<void, Error, any, UpdateMobsRequest>(
    requestKey,
    updateMobs,
    {onError: reportError}
  );

  return {
    mobs: mobs || [],
    loading: loading || isMutating,
    error,
    updateMobs: trigger,
    revalidate: mutate,
  };
};
