import * as React from 'react';
import {useState, useMemo, useCallback, useEffect, useRef} from 'react';
import {FontIcon, SelectionControl} from 'react-md';
import {Tags as PremiumAppTag} from 'containers/admin/features/types';
import {loadZoningData, toggleTreeZoningFields} from '../../actions/zoning-actions';
import {CropSelectMd, FluroAutocomplete, FluroButton, FluroChip, Dropdown} from 'components';
import {FormattedMessage, t} from 'i18n-utils';
import {useAppDispatch, useAppSelector} from '_hooks';
import {selectMapFields, selectWholeFarmData} from '../../reducer/selectors';
import {selectCropTypeLabelByCropId} from 'modules/global/selectors';
import {reportError} from 'containers/error-boundary';
import {sortByKey} from '_utils/sorters';

type Props = {
  mobileView?: boolean; // used to display data in FluroChips in certain areas (not in the toggle header) for mobiles
};

export const TreeZoningFieldsDropdown = ({mobileView}: Props) => {
  const dispatch = useAppDispatch();
  const fields = useAppSelector(selectMapFields);
  const wholeFarm = useAppSelector(selectWholeFarmData);
  const [selectedCropType, setSelectedCropType] = useState('all');
  const selectedCropTypeLabel = useAppSelector(s =>
    selectCropTypeLabelByCropId(s, selectedCropType)
  );
  const [selectedCropVariety, setSelectedCropVariety] = useState('all');

  const dropDownButtonText = useRef<HTMLSpanElement | null>(null);

  const treeFields = useMemo(
    () =>
      fields.filter(f => {
        return f.tags?.includes(PremiumAppTag.TreeAnalysis);
      }),
    [fields, wholeFarm.treeZoning.fields]
  );

  const cropTypes = useMemo(
    function calculateCropTypes() {
      const cropTypeValues: {[cropName: string]: boolean} = {};

      treeFields.forEach(f => {
        f?.Seasons?.forEach(season => {
          if (season.id === f.SeasonID) {
            const cropTypeValue = season.cropType;
            if (season.cropType) {
              cropTypeValues[cropTypeValue] = true;
            } else {
              reportError(`Unknown tree crop type = ${cropTypeValue}`);
            }
          }
        });
      });

      const cropTypes = Object.keys(cropTypeValues).map(cropValue => ({
        value: cropValue,
        label: t({id: 'cropValue'}),
      }));

      if (cropTypes.length > 1) {
        // add all crops only if there is more than one crop type
        cropTypes.unshift({
          value: 'all',
          label: t({id: 'All crops'}),
        });
      }

      if (cropTypes.length && !cropTypes.find(c => c.value === selectedCropType)) {
        // reset the selected crop type if the current is not in the range for some reason
        setSelectedCropType(cropTypes[0].value);
      }

      return cropTypes;
    },
    [treeFields, selectedCropType]
  );

  const cropVarieties = useMemo(
    function calculateCropSubtype() {
      const cropVarietyValues: {[varietyName: string]: boolean} = {};

      treeFields
        .filter(f => f.CropType === selectedCropTypeLabel && f.CropSubtype)
        .forEach(f => (cropVarietyValues[String(f.CropSubtype)] = true));

      let cropVarieties = Object.keys(cropVarietyValues).map(subtype => ({
        value: subtype,
        label: subtype,
      }));

      if (!cropVarieties.length) {
        setSelectedCropVariety('all');
        return [];
      }

      if (
        cropVarieties.length > 1 ||
        !treeFields.every(f => f.CropSubtype === cropVarieties[0].value) // if some fields doesn't have the variety
      ) {
        // add all varieties only if there is more than one crop type
        cropVarieties.unshift({value: 'all', label: 'All varieties'});
      }

      if (!cropVarieties.find(c => c.value === selectedCropVariety)) {
        // reset the selected variety if the current is not in the range
        setSelectedCropVariety(cropVarieties[0].value);
      }

      return cropVarieties;
    },
    [treeFields, selectedCropType, selectedCropTypeLabel]
  );

  const filteredFields = useMemo(() => {
    if (selectedCropType === 'all' && selectedCropVariety === 'all') return treeFields;

    return sortByKey(
      treeFields.filter(
        f =>
          f.CropType === selectedCropTypeLabel &&
          (selectedCropVariety === 'all' || f.CropSubtype === selectedCropVariety)
      ),
      'Name'
    );
  }, [treeFields, selectedCropType, selectedCropVariety, selectedCropTypeLabel]);

  useEffect(
    function unselectFilteredFields() {
      const fieldsToUnselect: string[] = [];
      const filteredFieldsIds = filteredFields.map(f => f.ID);
      treeFields.forEach(f => {
        if (
          !filteredFieldsIds.includes(f.ID) &&
          wholeFarm.treeZoning.fields[f.MD5]?.selected !== false
        ) {
          fieldsToUnselect.push(f.MD5);
        }
      });

      if (fieldsToUnselect.length) {
        // untoggle fields that didn't pass the filters
        dispatch(toggleTreeZoningFields(fieldsToUnselect, false));
      }
    },
    [filteredFields]
  );

  const selectedFields = useMemo(
    () => filteredFields.filter(f => wholeFarm.treeZoning.fields[f.MD5]?.selected).map(f => f.MD5),
    [filteredFields]
  );

  const onToggleField = useCallback(
    (field: string, value: boolean) => {
      const fieldsMD5 = field === 'all' ? filteredFields.map(f => f.MD5) : [field];
      dispatch(toggleTreeZoningFields(fieldsMD5, value));
    },
    [filteredFields]
  );

  const applySelection = () => {
    dispatch(loadZoningData());
    if (dropDownButtonText) {
      dropDownButtonText.current?.click(); // hack close the dropdown
    }
  };

  const onResetFields = useCallback(() => {
    dispatch(toggleTreeZoningFields(selectedFields, false));
  }, [selectedFields]);

  const userFacingValue = t({id: '{count} fields selected'}, {count: selectedFields.length});

  if (mobileView) {
    return <FluroChip className={'farm-field-mobile-chip'} label={userFacingValue} />;
  }

  return (
    <Dropdown
      className={'multi-tree-fields-selector'}
      button={
        <FluroButton raised className="semi-white-skin-button more-fields-button">
          <span ref={dropDownButtonText}>{userFacingValue}</span>
          <span>
            <FontIcon className="add-more-fields-icon">arrow_drop_down</FontIcon>
          </span>
        </FluroButton>
      }
    >
      <React.Fragment>
        <CropSelectMd // Crop types selector
          cropTypes={cropTypes}
          cropType={selectedCropType}
          label={''}
          onChange={setSelectedCropType}
          simplifiedMenu={true}
        />
        {cropVarieties.length !== 0 && (
          <FluroAutocomplete // crop variety selector
            id={`select-variety`}
            label={''}
            menuItems={cropVarieties}
            onAutocomplete={variety => setSelectedCropVariety(String(variety))}
            className={'crop-variety-select'}
            value={String(cropVarieties?.find(v => v.value === selectedCropVariety)?.label)}
          />
        )}
        {filteredFields.length > 1 && ( // bulk fields selector
          <div className="select-all-checkbox-container">
            <SelectionControl
              id={-1}
              name={'Select all'}
              label={
                selectedCropType === 'all' ? t({id: 'Select whole farm'}) : t({id: 'Select all'})
              }
              type={'checkbox'}
              className={'select-all-checkbox'}
              checked={filteredFields.length === selectedFields.length}
              onChange={value => onToggleField('all', Boolean(value))}
            />
          </div>
        )}

        {selectedFields.length > 1 && (
          <div className="reset-farms">
            <FluroButton blank raised className="reset-fields-btn" onClick={onResetFields}>
              {t({id: 'Reset fields'})}
            </FluroButton>
          </div>
        )}

        {filteredFields.map(field => (
          <div key={field.ID} className="field-item">
            <SelectionControl
              id={field.ID}
              name={field.Name}
              label={field.Name}
              type={'checkbox'}
              checked={selectedFields.includes(field.MD5)}
              onChange={value => onToggleField(field.MD5, Boolean(value))}
            />
          </div>
        ))}

        <FluroButton
          className={'apply-selection'}
          onClick={applySelection}
          disabled={!filteredFields.length || !selectedFields.length}
          raised
          primary
        >
          <FormattedMessage
            id={'zoning.whole-farm-tree-apply-selection'}
            defaultMessage={'Apply selection'}
          />
        </FluroButton>
      </React.Fragment>
    </Dropdown>
  );
};
