// @ts-nocheck
import type {ReactNode} from 'react';
import React, {useMemo, useState} from 'react';
import moment from 'moment';
import cn from 'classnames';
import {GLOBAL_APP_DATE_FORMAT, SERVER_FORMAT_DATE} from '_constants';
import {BrowserView} from 'react-device-detect';
import './selector-date-calendar.scss';
import {setDate} from 'containers/map/actions';
import {
  FluroDatePicker,
  FluroCalendarContainer,
  FluroChip,
  MultiKeysPressed,
  FluroButton,
} from 'components';
import {drawDateIcon} from 'containers/map/utils';
import {useAppDispatch, useAppSelector} from '_hooks';
import {
  selectCurrentDate,
  selectCurrentTab,
  selectFieldsByFarmId,
} from 'containers/map/reducer/selectors';
import {selectCropPerformance} from 'containers/map/features/crop-performance/crop-performance-selectors';

type Props = {
  mobileView?: boolean; // used to display data in FluroChips in certain areas (not in the toggle header) for mobiles
};
export const SelectorDateCalendar = ({mobileView}: Props) => {
  const dispatch = useAppDispatch();
  const feature = useAppSelector(selectCurrentTab);
  const currentDate = useAppSelector(selectCurrentDate);
  const fieldsByFarmId = useAppSelector(selectFieldsByFarmId);
  const cropPerformance = useAppSelector(selectCropPerformance);
  const [legendVisible, setLegendVisible] = useState(false);

  const toggleLegend = () => setLegendVisible(v => !v);

  const fields = useMemo(
    () =>
      Object.keys(fieldsByFarmId)
        .filter(farmId => cropPerformance.farms[Number(farmId)])
        .flatMap(farmId => Object.values(fieldsByFarmId[Number(farmId)])),
    [fieldsByFarmId, cropPerformance.farms]
  );

  const {latestPredictedDate, highlightDates} = useMemo(() => {
    const dateStats: {[date: string]: {imagery: number; total: number; predicted: 0}} = {};
    let latestPredictedDate = '';
    Object.keys(cropPerformance.farms).forEach(farmId => {
      const csgs = cropPerformance.farms[Number(farmId)].csgs;
      if (!csgs) {
        return;
      }
      Object.keys(csgs).forEach(fieldId => {
        const field = csgs[Number(fieldId)];
        Object.keys(field.seasons).forEach(season => {
          Object.keys(field.seasons[season].dates).forEach(date => {
            const d = field.seasons[season].dates[date];
            if (!dateStats[date]) {
              dateStats[date] = {imagery: 0, total: 0, predicted: 0};
            }
            if (d.predicted) {
              dateStats[date].predicted++;
              if (latestPredictedDate < d.date) {
                latestPredictedDate = d.date;
              }
            }
            if (d.imagery) {
              dateStats[date].imagery++;
            }
            dateStats[date].total++;
          });
        });
      });
    });

    const lessThanHalf: Date[] = [];
    const moreThanHalf: Date[] = [];
    const full: Date[] = [];
    const predicted__nodata: Date[] = [];
    const predicted__full: Date[] = [];
    const predicted__lessThanHalf: Date[] = [];
    const predicted__moreThanHalf: Date[] = [];

    Object.keys(dateStats).forEach(date => {
      const d = moment(date, SERVER_FORMAT_DATE).toDate();
      const predicted = dateStats[date].predicted;
      const alpha = dateStats[date].imagery / dateStats[date].total;

      // We use 4 highlights for the dates:
      // 1. Border for predicted values.
      // 2. Fully opaque colors for 1 result.
      // 3. Days with > 0.5 fields have imagery should be colored with 75% alpha.
      // 4. Days with < 0.5 fields have imagery should be colored with 25% alpha.
      if (!alpha) {
        predicted && predicted__nodata.push(d);
      } else if (alpha === 1) {
        predicted ? predicted__full.push(d) : full.push(d);
      } else if (alpha > 0.5) {
        predicted ? predicted__moreThanHalf.push(d) : moreThanHalf.push(d);
      } else {
        predicted ? predicted__lessThanHalf.push(d) : lessThanHalf.push(d);
      }
    });

    const highlightDates: {[className: string]: Date[]}[] = [
      {lessThanHalf},
      {moreThanHalf},
      {full},
      {predicted: predicted__nodata},
      {'predicted full': predicted__full},
      {'predicted lessThanHalf': predicted__lessThanHalf},
      {'predicted moreThanHalf': predicted__moreThanHalf},
    ];

    return {
      latestPredictedDate: moment(latestPredictedDate),
      highlightDates,
    };
  }, [cropPerformance.farms]);

  // TODO (stas): Seems like we don't need periods anymore.
  // We only use the min date of the first period
  // And the last date of the last period.
  const periods = useMemo(() => {
    let periods: {minDate: string; maxDate: string}[] = [];
    const randomSeason = fields.find(f => f.Seasons?.[0])?.Seasons[0];
    if (!randomSeason) {
      return periods;
    }

    periods = [{minDate: randomSeason.startDate, maxDate: randomSeason.endDate}];
    fields.forEach(f => {
      const candidates: {minDate: string; maxDate: string}[] = [];
      f.Seasons.forEach(s => {
        periods.forEach(p => {
          if (p.maxDate < s.startDate || p.minDate > s.endDate) {
            candidates.push({minDate: s.startDate, maxDate: s.endDate});
            return;
          }
          if (p.minDate > s.startDate) {
            p.minDate = s.startDate;
          }
          if (p.maxDate < s.endDate) {
            p.maxDate = s.endDate;
          }
        });
      });
      const finalists: {minDate: string; maxDate: string}[] = [];
      candidates.forEach(c => {
        let intersects = false;
        periods.forEach(p => {
          if (p.maxDate < c.minDate || p.minDate > c.maxDate) {
            return;
          }
          intersects = true;
          if (p.minDate > c.minDate) {
            p.minDate = c.minDate;
          }
          if (p.maxDate < c.maxDate) {
            p.maxDate = c.maxDate;
          }
        });
        if (!intersects) {
          finalists.push(c);
        }
      });
      periods.push(...finalists);
    });
    periods.sort((a, b) => a.minDate.localeCompare(b.minDate));
    return periods;
  }, [fields]);

  const momentPeriods = periods.map(p => ({min: moment(p.minDate), max: moment(p.maxDate)}));
  const today = moment();
  const firstDay = momentPeriods.length ? momentPeriods[0].min : moment('1970-01-01');
  const lastDay = momentPeriods.length
    ? momentPeriods[periods.length - 1].max
    : moment('9999-12-31');
  const lastValidDay = latestPredictedDate.isAfter(today) ? latestPredictedDate : today;
  const date = currentDate
    ? moment(currentDate, GLOBAL_APP_DATE_FORMAT)
    : moment().isBefore(lastDay)
    ? moment()
    : lastDay;
  const momentMinDate = firstDay;
  const momentMaxDate = lastDay.isAfter(lastValidDay) ? lastValidDay : lastDay;
  const selectPrevDate = () => {
    dispatch(
      setDate(moment(date).subtract(1, 'day').format(GLOBAL_APP_DATE_FORMAT) + '-satellite')
    );
  };
  const selectNextDate = () => {
    dispatch(setDate(moment(date).add(1, 'day').format(GLOBAL_APP_DATE_FORMAT) + '-satellite'));
  };

  if (mobileView) {
    return <FluroChip label={drawDateIcon({date: currentDate})} />;
  }

  return (
    <div className="crop-performance-date-container">
      <FluroButton
        className={'select-date-btn prev'}
        disabled={moment(date).subtract(1, 'day').isBefore(momentMinDate)}
        icon
        onClick={selectPrevDate}
      >
        keyboard_arrow_left
      </FluroButton>
      <FluroDatePicker
        id={'crop-performance-date'}
        selected={date}
        onChange={date => dispatch(setDate(date.format(GLOBAL_APP_DATE_FORMAT) + '-satellite'))}
        calendarContainer={({children}) => (
          <CalendarLegend
            defaultCalendar={children}
            visible={legendVisible}
            toggleVisibility={toggleLegend}
          />
        )}
        hideFormat
        minDate={momentMinDate}
        maxDate={momentMaxDate}
        highlightDates={highlightDates}
      />
      <FluroButton
        className={'select-date-btn next'}
        disabled={moment(date).add(1, 'day').isAfter(momentMaxDate)}
        icon
        onClick={selectNextDate}
      >
        keyboard_arrow_right
      </FluroButton>

      <BrowserView>
        {feature === 'data-layers' ? null : (
          <>
            <MultiKeysPressed callback={selectPrevDate} keys={['Shift', 'ArrowLeft']} />
            <MultiKeysPressed callback={selectNextDate} keys={['Shift', 'ArrowRight']} />
          </>
        )}
      </BrowserView>
    </div>
  );
};

const CalendarLegend = ({
  visible,
  defaultCalendar,
}: {
  visible: boolean;
  toggleVisibility: () => void;
  defaultCalendar: ReactNode;
}) => {
  return (
    <FluroCalendarContainer defaultCalendar={defaultCalendar}>
      <div className={cn({help: true, expanded: visible})}>
        <div className="help-content">
          <div className="help-row">
            <div className="help-col">
              <span className="color" style={{background: `#43a047`}} />
              <span>0% cloudy</span>
            </div>
            <div className="help-col">
              <span className="color" style={{background: `#a5d0a4`}} />
              <span>{'< 50% cloudy'}</span>
            </div>
            <div className="help-col">
              <span className="color" style={{background: `#e4f1e4`}} />
              <span>{'> 50% cloudy'}</span>
            </div>
          </div>
          <div className="help-row">
            <div className="help-col">
              <span className="color empty" style={{background: `#fff`}} />
              <span>No imagery</span>
            </div>
            <div className="help-col">
              <span className="color" style={{border: `1px dashed cornflowerblue`}} />
              <span>Predicted observations</span>
            </div>
          </div>
        </div>
      </div>
    </FluroCalendarContainer>
  );
};
