import type {ComponentType} from 'react';
import React, {useEffect, useRef} from 'react';
import type {ImageOverlay} from 'leaflet';
import L from 'leaflet';
import '../../leaflet-side-by-side';
import {FluroImageOverlay} from 'components/fluro-leaflet';
import {drawCompareTooltips} from '../../utils';
import {useAppDispatch, useAppSelector} from '_hooks';
import {
  selectColorSchema,
  selectCurrentCompareDate,
  selectCurrentCompareSensor,
  selectCurrentDate,
  selectCurrentField,
  selectCurrentSeason,
  selectCurrentSensor,
  selectDateTimeByDate,
  selectTreeDetectionLayerType,
} from '../../reducer/selectors';
import {useSelectCurrentFieldLayerImageUrl} from '../../selectors';
import {bbox} from '@turf/turf';
import ActivityAPI from '_api/activity';
import {saveImage} from 'modules/images-cache.module';
import {AsyncStatusType, setRequestStatus, Status} from 'modules/helpers';
import {reportError} from 'containers/error-boundary';

type Props = {
  leafletElement: L.Map;
  compareOriginalRef: L.ImageOverlay;
};

const CompareOverlay: ComponentType<Props> = ({leafletElement, compareOriginalRef}) => {
  const dispatch = useAppDispatch();
  const currentSensorCompare = useAppSelector(selectCurrentCompareSensor);
  const currentCompareDate = useAppSelector(selectCurrentCompareDate);
  const currentSensor = useAppSelector(selectCurrentSensor);
  const currentDate = useAppSelector(selectCurrentDate);
  const field = useAppSelector(selectCurrentField);
  const currentSeason = useAppSelector(selectCurrentSeason);
  const {selectFieldLayerImageUrl, selectPlantingAreaLayerImageUrl, selectTreeLayerImageUrl} =
    useSelectCurrentFieldLayerImageUrl();
  const isTreeDetection = useAppSelector(selectTreeDetectionLayerType) !== 'default';
  const currentDateTime = useAppSelector(s => selectDateTimeByDate(s, currentDate));
  const compareDateTime = useAppSelector(s => selectDateTimeByDate(s, currentCompareDate));
  const colorSchema = useAppSelector(selectColorSchema);

  const layerRef = useRef<ImageOverlay | null>(null);
  const sbs = useRef<L.Control | null>(null);

  const plantingAreaBox = currentSeason?.geometry_id ? bbox(currentSeason?.geometry) : null;

  const requestCompareData = () =>
    ActivityAPI.getFieldCompareImagesAndLayers({
      index: currentSensorCompare,
      dates: [currentDateTime, compareDateTime],
      md5: field.MD5,
      geometryId: currentSeason?.geometry_id,
      isTreeDetection,
    });

  useEffect(() => {
    dispatch(setRequestStatus(AsyncStatusType.binaryImageLayerData, Status.Pending));
    let numberOfTries = 0;
    requestCompareData()
      .then(data => {
        unpackageZipFile(data.data);
      })
      .catch(() => {
        numberOfTries = numberOfTries + 1;
        if (numberOfTries === 1) {
          // try one more time, back-end fails sometimes
          requestCompareData()
            .then(data => {
              unpackageZipFile(data.data);
            })
            .catch(err => {
              reportError(err);
              dispatch(setRequestStatus(AsyncStatusType.binaryImageLayerData, Status.Done));
            });
        }
      });
  }, [
    currentSensorCompare,
    currentDateTime,
    compareDateTime,
    field.MD5,
    currentSeason?.geometry_id,
    isTreeDetection,
  ]);

  const unpackageZipFile = async (responseData: Blob) => {
    const JSZip_ = (await import('jszip')).default;
    try {
      const zip_ = await new JSZip_().loadAsync(responseData);
      const result = zip_;

      Object.keys(result.files).forEach(fileName => {
        const file = result.files[fileName] as any;
        let imageName = fileName.includes(currentDateTime)
          ? `${currentDateTime}_${currentSensor}?wholeSeason=False` // current date
          : `${compareDateTime}_${currentSensor}?wholeSeason=False`; // compare date

        if (fileName.includes('scale')) {
          imageName = `${currentDateTime}_${compareDateTime}_${currentSensor}_scale`;
        }

        var blob = new Blob([file._data.compressedContent], {type: 'image/jpeg'});

        const image = URL.createObjectURL(blob);

        dispatch(saveImage(imageName, image));
      });
      dispatch(setRequestStatus(AsyncStatusType.binaryImageLayerData, Status.Done));
    } catch (err) {
      dispatch(setRequestStatus(AsyncStatusType.binaryImageLayerData, Status.Todo));
      reportError(err);
    }
  };

  const imageUrl = currentSeason?.geometry_id
    ? selectPlantingAreaLayerImageUrl(true)
    : selectTreeLayerImageUrl(true) || selectFieldLayerImageUrl(true);

  const fieldRef = (node: ImageOverlay) => {
    sbs.current && sbs.current.remove();

    if (!node) {
      return;
    }

    layerRef.current = node;
    sbs.current = L.control
      //@ts-expect-error error leftover from convertion to strict mode, please fix
      .sideBySide(compareOriginalRef, layerRef.current)
      .addTo(leafletElement);

    drawCompareTooltips(currentSensor, currentSensorCompare, currentDate, currentCompareDate);
  };

  useEffect(() => {
    layerRef.current = null;
    sbs.current && sbs.current.remove();
  }, []);

  useEffect(() => {
    drawCompareTooltips(currentSensor, currentSensorCompare, currentDate, currentCompareDate);
  }, [currentSensor, currentSensorCompare, currentDate, currentCompareDate]);

  if (!imageUrl || (currentSensorCompare === 'NONE' && !currentCompareDate)) {
    return null;
  }

  const compareImageUrl =
    colorSchema === 'singleDate' ? `${compareDateTime}_${currentSensor}` : imageUrl;

  return (
    <FluroImageOverlay
      isCompare
      lRef={fieldRef}
      bounds={
        plantingAreaBox
          ? [
              [plantingAreaBox?.[1], plantingAreaBox?.[0]],
              [plantingAreaBox?.[3], plantingAreaBox?.[2]],
            ]
          : [
              [Number(field.SouthLat), Number(field.WestLon)],
              [Number(field.NorthLat), Number(field.EastLon)],
            ]
      }
      url={compareImageUrl}
    />
  );
};

export default CompareOverlay;
