import styled from 'styled-components';

export const Wrapper = styled.div`
  color: #fff;
  display: inline-block;
  vertical-align: middle;
  border-radius: 3px;
  text-align: center;
  padding: 2px 6px;
  text-transform: uppercase;
  white-space: nowrap;
  user-select: none;
  font-weight: 500;
`;

export const WrapperGroup = styled.div`
  display: inline-block;
  ${Wrapper} {
    margin-right: 4px;
  }

  ${Wrapper}:last-child {
    margin-left: 0;
  }
`;

export const CPBadgeWrapper = styled.span`
  padding: 7px;
  border-radius: 4px;
  display: inline;
  white-space: nowrap;
  font-size: 14px;
  line-height: 1.2;
  &:not(:first-child) {
    margin-left: 4px;
  }

  &.actionable {
    cursor: pointer;
  }
`;
