import initialState from '../initial-state';
import type {IAction, IInitialMapState} from '../../types';

import {ActionTypes} from '../types';

import {preselectSensor, preselectSensors, preselectRange} from 'containers/map/utils';

export default (state = initialState, action: IAction): Partial<IInitialMapState> => {
  switch (action.type) {
    case ActionTypes.MAP_SET_TREE_LAYER_TYPE: {
      const {currentDates, currentDate, currentSensor} = state;

      const preselectedSensor = preselectSensor(
        currentDate,
        currentDates,
        currentSensor,
        action.layer
      );
      const range: number = preselectRange(preselectedSensor);

      return {
        ...state,
        currentSensor: preselectedSensor,
        currentSensors: preselectSensors(currentDate, currentDates, action.layer),
        currentSensorsCompare: preselectSensors(
          state.currentCompareDate,
          currentDates,
          action.layer
        ),
        currentSensorCompare: preselectSensor(
          state.currentCompareDate,
          currentDates,
          currentSensor,
          action.layer
        ),
        treeDetection: {
          ...state.treeDetection,
          layerType: action.layer,
        },
        histogram: {
          ...state.histogram,
          range,
        },
      };
    }

    case ActionTypes.MAP_SET_TREE_ZONE_PARAM: {
      return {
        ...state,
        zoning: {
          ...state.zoning,
          treeZones: state.zoning.treeZones.map(z => {
            if (z.id === action.id) return {...z, ...action.data};
            return z;
          }),
        },
      };
    }

    default:
      return state;
  }
};
