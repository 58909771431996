import React from 'react';
import {FontIcon} from 'react-md';
import './index.scss';
import styled from 'styled-components';

const SortButton = ({
  onClick = null,
  selected,
  descending,
}: {
  onClick?: any;
  selected?: boolean;
  descending: boolean;
}) => {
  return (
    <FontIcon className={`field-sort-btn ${selected ? 'selected' : ''}`} onClick={onClick}>
      {descending ? 'arrow_drop_up' : 'arrow_drop_down'}
    </FontIcon>
  );
};

export const SortButtonContainer = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: inherit;
`;

export default SortButton;
