import {t} from 'i18n-utils';
import React, {useEffect} from 'react';
import {sensorModel, sensorView} from '_utils';
import {getLayersItems, createDatesMenuList} from 'containers/map/utils';
import {SelectField} from 'react-md';

import {
  setCompareDate,
  setSensorCompare,
  setSensor,
  setDate,
  toggleCompare,
} from 'containers/map/actions';
import type {TSensor} from 'types';

import {toggleLowPerformingAreas} from 'containers/map/actions/anomalies-actions';
import {useAppDispatch, useAppSelector} from '_hooks';
import {
  selectCurrentCompareDate,
  selectCurrentCompareSensor,
  selectCurrentCompareSensors,
  selectCurrentDate,
  selectCurrentDates,
  selectCurrentSensor,
  selectIsWholeFarmView,
} from 'containers/map/reducer/selectors';
import {selectLowPerformingAnomalies} from 'containers/map/features/anomalies/anomalies-selectors';

const MapCompare = () => {
  const dispatch = useAppDispatch();
  const visibleLowPerfAnomalies = useAppSelector(selectLowPerformingAnomalies).isVisible;
  const currentDate = useAppSelector(selectCurrentDate);
  const currentDates = useAppSelector(selectCurrentDates);
  const currentSensor = useAppSelector(selectCurrentSensor);
  const isWholeFarmView = useAppSelector(selectIsWholeFarmView);
  const currentCompareDate = useAppSelector(selectCurrentCompareDate);
  const currentSensorCompare = useAppSelector(selectCurrentCompareSensor);
  const currentSensorsCompare = useAppSelector(selectCurrentCompareSensors);

  useEffect(() => {
    if (visibleLowPerfAnomalies) {
      dispatch(toggleLowPerformingAreas(false));
    }

    return () => {
      dispatch(toggleCompare(false));
    };
  }, []);

  const selectLeftSideSensor = (val: TSensor) => {
    dispatch(setSensor(sensorModel(val)));
  };

  const selectLeftSideDate = (date: string) => {
    dispatch(setDate(date));
  };

  const selectRightSideSensor = (val: TSensor) => {
    const sensor = sensorModel(val);
    dispatch(setSensorCompare(sensor));
  };

  const selectRightSideDate = (date: string) => {
    dispatch(setCompareDate(date));
  };

  const clearedSensorsToCompare = isWholeFarmView
    ? currentSensorsCompare.filter(
        (sensor: TSensor) => currentDates[currentCompareDate]?.[sensor]?.type
      )
    : currentSensorsCompare;

  const modyfiedSensorsToCompare = clearedSensorsToCompare.map(sensorView);
  const layers = getLayersItems();
  const dates = createDatesMenuList(currentDates, true, isWholeFarmView);

  return (
    <div className={'compare-container'}>
      <div className={'select-date-layer'}>
        <SelectField
          id="select-field-date"
          placeholder={t({id: 'Select Date'})}
          label={t({id: 'Date'})}
          className={'date'}
          menuItems={dates}
          onChange={date => selectLeftSideDate(String(date))}
          value={currentDate}
          disabled={!dates.length}
          simplifiedMenu={true}
        />

        <SelectField
          id="select-field-index"
          placeholder={t({id: 'Select layer'})}
          className="layer"
          label={t({id: 'Layer'})}
          menuItems={layers}
          disabled={!layers.length || layers.length === 1}
          value={sensorView(currentSensor)}
          onChange={sensor => selectLeftSideSensor(sensor as TSensor)}
          position={'br'}
        />
      </div>

      <div className={'select-date-layer'}>
        <b className={'element-full-width'}>{t({id: 'Compare to'})}</b>
        <SelectField
          id="select-field-date-compare"
          placeholder={t({id: 'Select Compare Date'})}
          label={t({id: 'Date'})}
          className={'date'}
          menuItems={dates}
          onChange={date => selectRightSideDate(String(date))}
          value={currentCompareDate}
          disabled={!dates.length}
          simplifiedMenu={true}
        />

        <SelectField
          id="select-field-index-compare"
          placeholder={t({id: 'Select Compare Layer'})}
          className="layer"
          label={t({id: 'Layer'})}
          menuItems={[{label: t({id: 'NONE'}), value: 'NONE'}, ...modyfiedSensorsToCompare]}
          disabled={!modyfiedSensorsToCompare.length}
          value={sensorView(currentSensorCompare)}
          onChange={sensor => selectRightSideSensor(sensor as TSensor)}
          position={'br'}
        />
      </div>
    </div>
  );
};

export default MapCompare;
