// @ts-nocheck
import type {Dispatch} from 'redux';
import type {RequestStatus} from 'types';
import type {AppStore} from '../../reducers';
import {selectAreEveryGeometrySelected} from './selectors/selectors';
import type {
  AreaId,
  FilterPayload,
  SelectActiveGeometriesAction,
  SetAbatementPotentialFilterRange,
  SetAbatementPotentialMetricAction,
  SetActiveGeometriesFilterAction,
  SetAggLevelAction,
  SetAreaUnitsAction,
  SetChartTabAction,
  SetCoverCropsFilterAction,
  SetFilterAction,
  SetFilterKeyAction,
  SetFilterYearsAction,
  SetHighlightGeometryAction,
  SetSelectedTillagePractice,
  SetStateIdsRequestParam,
  SetSupplyShedsRequestParam,
  SetStatusAction,
  SetTillageRangeValues,
  SIAggLevel,
  SIAreaUnits,
  SIChartTab,
  SIFilterState,
  SIGeometryFilterType,
  SIMetricColumn,
  StateFP,
  TillagePractice,
  SetSocFilterAction,
  SetGhgFilterAction,
  SetYieldFilterAction,
  SetEmissionsFactorFilterAction,
  SetFertilizerFilterAction,
  SupplyShedId,
} from './types';
import {ActionType} from './types';

export const setStatus = (actionType: ActionType, status: RequestStatus): SetStatusAction => ({
  type: ActionType.SET_STATUS,
  actionType,
  status,
});

export const setAggLevel = (aggLevel: SIAggLevel, nextAreaIds: AreaId[]): SetAggLevelAction => ({
  type: ActionType.SET_AGG_LEVEL,
  aggLevel,
  nextAreaIds,
});

export const setFilter = (payload: FilterPayload): SetFilterAction => ({
  type: ActionType.SET_FILTER,
  payload,
});

export const setCoverCropsCardFilter = (payload: Partial<SIFilterState['coverCrops']>) => ({
  type: ActionType.SET_CC_CARD_FILTER,
  payload,
});

export const setFilterYears = (years: number[]): SetFilterYearsAction => ({
  type: ActionType.SET_FILTER_YEARS,
  years,
});

export const setActiveGeometriesFilter: (
  id: AreaId | AreaId[] | null,
  opts?: {multiselect?: boolean; resetVisible?: boolean}
) => SetActiveGeometriesFilterAction = (id, {multiselect = false, resetVisible = false} = {}) => ({
  type: ActionType.SET_ACTIVE_GEOMETRIES_FILTER,
  id,
  multiselect,
  resetVisible,
});

export const selectActiveGeometries = ({
  ids,
  selected,
  stateId,
  resetVisible = false,
}: SelectActiveGeometriesAction['payload']): SelectActiveGeometriesAction => ({
  type: ActionType.SELECT_ACTIVE_GEOMETRIES,
  payload: {
    ids,
    selected,
    stateId,
    resetVisible,
  },
});

export const setFilterAreaUnits = (areaUnits: SIAreaUnits): SetAreaUnitsAction => ({
  type: ActionType.SET_AREA_UNITS,
  areaUnits,
});

export const setChartTab =
  (cardType: SIGeometryFilterType, tab: SIChartTab) =>
  (dispatch: Dispatch<SetChartTabAction>, getState: () => AppStore) => {
    const areEveryGeometrySelected = selectAreEveryGeometrySelected(getState());

    dispatch({
      type: ActionType.SET_CHART_TAB,
      tab,
      cardType,
      areEveryGeometrySelected: areEveryGeometrySelected,
    });
  };

export const setFilterKey = (
  key: SetFilterKeyAction['key'],
  value?: SIMetricColumn | null
): SetFilterKeyAction => ({
  type: ActionType.SET_FILTER_KEY,
  key,
  value,
});

export const setHighlightGeometry = (geometryId: AreaId | null): SetHighlightGeometryAction => ({
  type: ActionType.SET_HIGHLIGHT_GEOMETRY,
  geometryId,
});

export const setCoverCropsFilter = (
  payload: SetCoverCropsFilterAction['payload']
): SetCoverCropsFilterAction => ({
  type: ActionType.SET_CROP_CHART_FILTER_ACTION,
  payload,
});

// Abatement potential actions

export const setAbatementPotentialMetric = (
  metric: SetAbatementPotentialMetricAction['metric']
): SetAbatementPotentialMetricAction => ({
  type: ActionType.ABATEMENT_POTENTIAL_SET_SELECTED_METRIC,
  metric,
});

export const setAbatementPotentialFilterRange = (
  filterRange: SetAbatementPotentialFilterRange['filterRange']
): SetAbatementPotentialFilterRange => ({
  type: ActionType.ABATEMENT_POTENTIAL_SET_FILTER_RANGE,
  filterRange,
});

// END Abatement potential actions

// Tillage actions

export const setSelectedTillagePractice = (
  practice: TillagePractice
): SetSelectedTillagePractice => ({
  type: ActionType.TILLAGE_SET_SELECTED_PRACTICE,
  practice,
});

export const setTillageRangeValues = (
  range: SetTillageRangeValues['range']
): SetTillageRangeValues => ({
  type: ActionType.TILLAGE_SET_RANGE_VALUES,
  range,
});

// END Tillage actions

export const setStateIdsRequestParam = (
  statesIds: Array<StateFP | SupplyShedId>,
  selected?: boolean
): SetStateIdsRequestParam => ({
  type: ActionType.SET_STATE_IDS_REQUEST_PARAM,
  payload: {statesIds, selected},
});

export const setSupplyShedsRequestParam = (
  supplySheds: SupplyShedId[],
  selected?: boolean
): SetSupplyShedsRequestParam => ({
  type: ActionType.SET_SUPPLY_SHEDS_REQUEST_PARAM,
  payload: {supplySheds, selected},
});

// Soc/ghg actions

export const setSocFilter = (payload: SetSocFilterAction['payload']): SetSocFilterAction => ({
  type: ActionType.SET_SOC_FILTER,
  payload,
}); // Soc actions

export const setGhgFilter = (payload: SetGhgFilterAction['payload']): SetGhgFilterAction => ({
  type: ActionType.SET_GHG_FILTER,
  payload,
});

export const setYieldFilter = (payload: SetYieldFilterAction['payload']): SetYieldFilterAction => ({
  type: ActionType.SET_YIELD_FILTER,
  payload,
});

export const setEmissionsFactorFilter = (
  payload: SetEmissionsFactorFilterAction['payload']
): SetEmissionsFactorFilterAction => ({
  type: ActionType.SET_EMISSIONS_FACTOR_FILTER,
  payload,
});

export const setFertilizerFilter = (
  payload: SetFertilizerFilterAction['payload']
): SetFertilizerFilterAction => ({
  type: ActionType.SET_FERTILIZERS_FILTER,
  payload,
});
