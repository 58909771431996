import {useEffect, useCallback} from 'react';
import {setTabNotification} from 'modules/global/actions';
import {AdminApi} from '_api';
import {useAppDispatch, useAppSelector} from '_hooks';
import {selectIsAdmin, selectIsAuth} from 'containers/login/login-selectors';

/**
 * Every 30 minutes fetches 'admin/emails/to_review'
 * to check if there are a new emails to review.
 */

let toReviewInterval: ReturnType<typeof setTimeout>;

const useEmailToReviewNotification = () => {
  const dispatch = useAppDispatch();
  const isAdmin = useAppSelector(selectIsAdmin);
  const isAuth = useAppSelector(selectIsAuth);

  const refreshData = useCallback(() => {
    AdminApi.emailsToReview().then(({data}) => {
      const hasUpdates = data?.result?.to_review;
      dispatch(setTabNotification('admin', {hasUpdates: !!hasUpdates, count: hasUpdates}));
      toReviewInterval = setTimeout(refreshData, 60 * 1000 * 30);
    });
  }, []);

  useEffect(() => {
    if (!isAdmin || !isAuth) {
      return;
    }

    refreshData();

    return () => {
      clearTimeout(toReviewInterval);
    };
  }, [isAdmin, isAuth]);
};

export default useEmailToReviewNotification;
