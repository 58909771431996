// @ts-nocheck
import * as React from 'react';
import styled from 'styled-components';
import cn from 'classnames';
import {FormattedMessage} from 'i18n-utils';
import {sum} from '_utils/pure-utils';
import {ColoredBullet} from '../legend-list/legend-list';

export type ChartData = {
  values: number[];
  labels: string[];
  colors: string[];
  subvalues?: number[];
  labelTail?: string[];
};

export const StackedSingleBarChart = ({
  data,
  selectedLabels,
  onFilterToggle,
  onShowTooltip,
}: {
  data: ChartData;
  selectedLabels: string[];
  onFilterToggle?: (value: string) => void;
  onShowTooltip?: (id: string, content: JSX.Element) => void;
}) => {
  // Calculate marginless plot area and use it as percentage,
  // so we can have white margins between sectors via flexbox space between.
  const PLOT_SIZE = 293; // 293px width of the plot.
  const PLOT_SECTOR_MARGIN = 2 * 2; // 2px on each side.
  const sectorsArea = PLOT_SIZE - data.labels.length * PLOT_SECTOR_MARGIN;
  const percentage = Math.round((sectorsArea / PLOT_SIZE) * 100);

  const total = sum(data.values);
  return (
    <LineChartPlot>
      {data.values.filter(Boolean).map((v, i) => {
        return (
          // TODO: fixme a11y
          // eslint-disable-next-line styled-components-a11y/click-events-have-key-events, styled-components-a11y/interactive-supports-focus, styled-components-a11y/mouse-events-have-key-events
          <LineChartPlotSector
            role="button"
            key={i}
            className={cn({
              selected: selectedLabels.includes(data.labels[i]),
              deselected: selectedLabels.length && !selectedLabels.includes(data.labels[i]),
            })}
            style={{
              width: `calc(${(v / total) * percentage}%)`,
              backgroundColor: data.colors[i],
            }}
            data-for={`line-chart-${i}`}
            data-tip={''}
            onClick={() => onFilterToggle?.(data.labels[i])}
            onMouseOver={() =>
              onShowTooltip?.(
                `line-chart-${i}`,
                <Tooltip
                  backgroundColor={data.colors[i]}
                  label={data.labels[i]}
                  value={data.values[i]}
                  labelTail={data.labelTail?.[i]}
                />
              )
            }
          />
        );
      })}
    </LineChartPlot>
  );
};

const LineChartPlot = styled.div`
  display: flex;
  justify-content: space-between;
`;

const LineChartPlotSector = styled.div`
  height: 14px;
  display: inline-block;
  transition: transform 0.2s, box-shadow 0.2s;
  cursor: pointer;

  &.selected,
  &:hover {
    transform: scaleY(1.3);
    box-shadow: 0 2px 5px rgba(119, 119, 119, 0.5);
  }

  &.deselected {
    opacity: 0.3;
  }

  &:first-child {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }

  &:last-child {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }
`;

const Tooltip = ({
  backgroundColor,
  label,
  value,
  labelTail,
}: {
  backgroundColor: string;
  label: string;
  value: number;
  labelTail: string;
}) => {
  return (
    <div className="line-chart-tooltip">
      <ColoredBullet style={{backgroundColor}} />
      <FormattedMessage id={label} defaultMessage={label} />{' '}
      <FormattedMessage id="{count} crops" defaultMessage="{count} crops" values={{count: value}} />
      {labelTail && <span> {labelTail}</span>}
    </div>
  );
};
