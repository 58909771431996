import type {PayloadAction} from '@reduxjs/toolkit';
import {createSlice} from '@reduxjs/toolkit';
import {mergeEntities} from 'modules/redux-utils/merge-entities';
import type {NormalizedSchemaPayloadAction} from 'types';
import {
  updateProgramAssets,
  fetchPrograms,
  fetchProgram,
  fetchProgramProjects,
  fetchProject,
  removeProgramAssets,
  updateProgramPracticeChanges,
  removeProgramPracticeChanges,
  updateProgramPhase,
  addProgramStage,
  addProgramAttributes,
  removeProgramAttribute,
  updateProgram,
  updateProjectConfig,
  updateProgramStage,
  updateProgramStages,
  addProgram,
  removeProgram,
  addCropType,
  removeCropType,
  updateContractStatus,
  requestContractRemoving,
  deleteProjectContract,
  updateProgramAttribute,
  removeProjectFields,
  fetchProgramStats,
  updateProgramAttributes,
} from '../thunks';
import type {MonitoringState} from '../types';
import type {
  OFSStatus,
  ProgramAdminUser,
  DNDCStatus,
  MRVProject,
  ProgramInvite,
} from '../../../types';
import {LoginActionTypes} from '../../../../login/types';
import {mergeUserInfoFromOldProjects} from '../../../utils';

export const entitiesSlice = createSlice({
  name: 'monitoring/entities',
  initialState: {
    attributes: {},
    fields: {},
    programs: {},
    projects: {},
    projectValues: {},
    stages: {},
    stats: {},
    invites: {},
  } as MonitoringState['entities'],
  reducers: {
    addMonitoringPrograms: (
      state,
      action: PayloadAction<{entities: Partial<MonitoringState['entities']>}>
    ) => {
      const {entities} = action.payload;
      return {
        ...state,
        ...entities,
        programs: mergeEntities(state.programs, action.payload.entities.programs || {}),
      };
    },
    bulkAddProgramAdmin: (state, action: PayloadAction<ProgramAdminUser[]>) => {
      action.payload.forEach(user => {
        state.programs[user.program_id] = {
          ...state.programs[user.program_id],
          permissions: [...state.programs[user.program_id].permissions, user],
        };
      });
    },
    removeProgramAdmin: (state, action: PayloadAction<{programId: number; userId: number}>) => {
      const {programId, userId} = action.payload;
      state.programs[programId].permissions = state.programs[programId].permissions.filter(
        user => user.user_id !== userId
      );
    },
    updateFieldOFSStatus: (
      state,
      action: PayloadAction<{mrvFieldId: number; status: OFSStatus}>
    ) => {
      const {mrvFieldId, status} = action.payload;
      if (state.fields[mrvFieldId]) {
        state.fields[mrvFieldId].ofs_status = status;
      }
    },
    updateProjectOFSStatus: (
      state,
      action: PayloadAction<{projectId: number; status: OFSStatus}>
    ) => {
      const {projectId, status} = action.payload;
      if (state.projects[projectId]) {
        state.projects[projectId].ofs_status = status;
      }
    },
    updateFieldDNDCStatus: (
      state,
      action: PayloadAction<{fieldId: number; status: DNDCStatus}>
    ) => {
      const {fieldId, status} = action.payload;
      if (state.fields[fieldId]) {
        state.fields[fieldId].dndc_status = status;
      }
    },
    updateProjectDNDCStatus: (
      state,
      action: PayloadAction<{projectId: number; status: DNDCStatus}>
    ) => {
      const {projectId, status} = action.payload;
      if (state.projects[projectId]) {
        state.projects[projectId].dndc_status = status;
      }
    },
    setProgramInvites: (
      state,
      action: PayloadAction<{programId: number; invites: ProgramInvite[]}>
    ) => {
      const {invites, programId} = action.payload;
      state.invites[programId] = invites;
    },
    addProgramInvites: (state, action: PayloadAction<{invites: ProgramInvite[]}>) => {
      const {invites} = action.payload;

      invites.forEach(invite => {
        state.invites[invite.program_id] = state.invites[invite.program_id]
          ? [...state.invites[invite.program_id], invite]
          : [invite];
      });
    },
    removeProgramInvite: (state, action: PayloadAction<{programId: number; inviteId: string}>) => {
      const {inviteId, programId} = action.payload;
      state.invites[programId] = state.invites[programId].filter(invite => invite.id !== inviteId);
    },
    removeStage: (state, action: PayloadAction<{stageId: number}>) => {
      const {stageId} = action.payload;
      delete state.stages[stageId];
    },
  },
  extraReducers: builder => {
    builder
      .addCase(LoginActionTypes.LOGOUT, state => {
        return {
          ...state,
          attributes: {},
          fields: {},
          programs: {},
          projects: {},
          projectValues: {},
          stages: {},
          stats: {},
        };
      })
      .addCase<string, PayloadAction<number>>(removeProgram.fulfilled.type, (state, action) => {
        const programId = action.payload;
        delete state.programs[programId];
      })
      .addCase<string, NormalizedSchemaPayloadAction>(
        fetchProject.fulfilled.type,
        (state, action) => {
          return {
            ...state,
            ...action.payload.entities,
            projects: mergeEntities(state.projects, action.payload.entities.projects),
          };
        }
      )
      .addCase<string, NormalizedSchemaPayloadAction>(
        updateContractStatus.fulfilled.type,
        (state, action) => {
          return {
            ...state,
            ...action.payload.entities,
            projects: mergeEntities(state.projects, action.payload.entities.projects),
          };
        }
      )
      .addCase<string, PayloadAction<{project: number; deletion_reason: string; id: number}>>(
        requestContractRemoving.fulfilled.type,
        (state, action) => {
          const {project, deletion_reason, id} = action.payload;

          state.projects[project].contracts?.forEach(contract => {
            if (contract.id === id) {
              contract.deletion_reason = deletion_reason;
            }
          });
          return state;
        }
      )
      .addCase<string, PayloadAction<{projectId: number}>>(
        deleteProjectContract.fulfilled.type,
        (state, action) => {
          const {projectId} = action.payload;

          state.projects[projectId].contracts = [];
          return state;
        }
      )

      // .addCase<string, NormalizedSchemaPayloadAction>(
      .addCase<string, PayloadAction<{entities: MonitoringState['entities']}>>(
        fetchProgramProjects.fulfilled.type,
        (state, action) => {
          mergeUserInfoFromOldProjects(state.projects, action.payload.entities.projects);

          return {
            ...state,
            ...action.payload.entities,
            projects: mergeEntities(state.projects, action.payload.entities.projects),
          };
        }
      )
      .addCase<
        string,
        PayloadAction<Pick<MRVProject, 'id' | 'config' | 'created_at' | 'updated_at'>>
      >(updateProjectConfig.fulfilled.type, (state, action) => {
        const {id, config, created_at, updated_at} = action.payload;
        state.projects[id] = {
          ...state.projects[id],
          config,
          created_at,
          updated_at,
        };
      })
      .addCase<string, NormalizedSchemaPayloadAction>(
        addProgramStage.fulfilled.type,
        (state, action) => {
          const {entities, result} = action.payload;
          // @ts-expect-error action.meta is not exposed
          const {phaseId} = action.meta.arg;
          state.phases[phaseId]?.stages.push(...result);
          state.stages = mergeEntities(state.stages, entities.stages);
        }
      )
      .addCase<string, NormalizedSchemaPayloadAction>(
        updateProgramStage.fulfilled.type,
        (state, action) => {
          const {entities} = action.payload;
          state.stages = mergeEntities(state.stages, entities.stages);
        }
      )
      .addCase<string, NormalizedSchemaPayloadAction>(
        updateProgramStages.fulfilled.type,
        (state, action) => {
          const {entities} = action.payload;
          state.stages = mergeEntities(state.stages, entities.stages);
        }
      )
      .addCase<string, NormalizedSchemaPayloadAction>(
        addProgramAttributes.fulfilled.type,
        (state, action) => {
          const {entities, result} = action.payload;
          // @ts-expect-error action.meta is not exposed
          const stageId = action.meta.arg.stageId;
          state.stages[stageId]?.attributes.push(...result);
          state.attributes = mergeEntities(state.attributes, entities.attributes);
        }
      )
      .addCase<string, PayloadAction<number>>(
        removeProgramAttribute.fulfilled.type,
        (state, action) => {
          const attributeId = action.payload;
          // @ts-expect-error action.meta is not exposed
          const stageId = action.meta.arg.stageId;
          const stage = state.stages[stageId];
          stage.attributes = stage.attributes.filter(attr => attr !== attributeId);
          delete state.attributes[attributeId];
        }
      )
      .addCase<string, PayloadAction<{projectId: number; mrvFieldIds: number[]}>>(
        removeProjectFields.fulfilled.type,
        (state, action) => {
          const {projectId, mrvFieldIds} = action.payload;

          mrvFieldIds.forEach(id => {
            delete state.fields[id];
          });

          state.projects[projectId].fields = state.projects[projectId].fields.filter(
            id => !mrvFieldIds.includes(id)
          );
        }
      )
      .addMatcher(
        action =>
          [
            addProgram.fulfilled.type,
            fetchProgram.fulfilled.type,
            addCropType.fulfilled.type,
            removeCropType.fulfilled.type,
            updateProgram.fulfilled.type,
            updateProgramPhase.fulfilled.type,
            updateProgramAssets.fulfilled.type,
            removeProgramAssets.fulfilled.type,
            updateProgramPracticeChanges.fulfilled.type,
            updateProgramAttribute.fulfilled.type,
            updateProgramAttributes.fulfilled.type,
            removeProgramPracticeChanges.fulfilled.type,
            fetchPrograms.fulfilled.type,
            fetchProgramStats.fulfilled.type,
          ].includes(action.type),
        (state, action) => {
          return mergeEntities(state, action.payload.entities);
        }
      );
  },
});
