// @ts-nocheck
import {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import type {AppStore} from 'reducers';

import {setSensor} from '../actions';
import {
  loadZoningData,
  runNutrilogicRecommendation,
  setZoning,
  setZoningTab,
} from '../actions/zoning-actions';
import {
  getNRxRecommendation,
  updateRecommendationDate,
} from 'containers/map/actions/nitrogen-recommendation-actions';
import {RegularZoningMethods, TreesZoningMethods} from '../features/zoning/zoning-constants';
import {useAppDispatch, useAppSelector, usePrevious} from '_hooks';
import type {IZoning} from '../types';
import {ZoningTab} from '../types';
import {
  selectCurrentFarm,
  selectCurrentFieldId,
  selectCurrentPointsGroup,
  selectCurrentSensor,
  selectCurrentTab,
  selectIsNitrogenZoningTab,
  selectPointsCurrentGroupDate,
  selectZoning,
} from '../reducer/selectors';
import {selectNRecommendation} from 'containers/map/features/nrx/nrx-selectors';

import {getLastAvailableRecommendationDate, loadNrxData} from 'containers/map/features/nrx';
import {selectAsyncRequest, selectAsyncRequestStatus} from 'modules/global/selectors';
import {AsyncStatusType, Status} from '../../../modules/helpers';
import Mixpanel from '_utils/mixpanel-utils';
import {getGetURLParam, setGetParamToURL} from '_utils/pure-utils';
import {useNitrogenRx} from '../features/zoning/nitrogen-recommendation/use-nitrogen-rx';
import {selectCurrentNRxSeason} from '../features/nrx/nrx-selectors';
import {useSelectCurrentFieldLayerImageUrl} from '../selectors';

/**
 * The component doesn't have any rendering, it exists only to handle when to call the analytic request.
 */
const ZoningEffects = (): null => {
  const [shouldLoadZoning, setShouldLoadZoning] = useState(false);
  const dispatch = useAppDispatch();

  // store props
  const nRecommendation = useAppSelector(selectNRecommendation);
  const feature = useAppSelector(selectCurrentTab);
  const zoning = useAppSelector(selectZoning);
  const currentSensor = useAppSelector(selectCurrentSensor);
  const farm = useAppSelector(selectCurrentFarm);
  const layerType = useSelector((state: AppStore) => state.map.treeDetection.layerType);
  const userSavedZoning = useSelector(
    (state: AppStore) => state.login.user.settings.zoning.favoriteZoningMethod?.[farm.id]
  );
  const currentPointsGroup = useAppSelector(selectCurrentPointsGroup);
  const pointsCurrentGroupDate = useAppSelector(selectPointsCurrentGroupDate);
  const isNitrogenTab = useAppSelector(selectIsNitrogenZoningTab);
  const loadingNRxRequest = useAppSelector(s =>
    selectAsyncRequest(s, AsyncStatusType.NRxRecommendation)
  );
  const loadingNRxListsStatus = useAppSelector(s =>
    selectAsyncRequestStatus(s, AsyncStatusType.NRxListsData)
  );
  const currentFieldId = useAppSelector(selectCurrentFieldId);
  const {isNrxAllowed} = useNitrogenRx();
  const currentNRxSeason = useAppSelector(selectCurrentNRxSeason);
  const {selectCurrentFieldDefaultImageUrl} = useSelectCurrentFieldLayerImageUrl();
  const currentImageUrl = selectCurrentFieldDefaultImageUrl();

  const [shouldLoadNRx, setShouldLoadNrx] = useState(false);

  const isTreeAnalysis = layerType !== 'default';
  const prev = usePrevious({
    nRecommendation,
    farm,
    pointsCurrentGroupDate,
    currentPointsGroup,
    zoning,
    currentNRxSeason,
  });

  useEffect(() => {
    if (shouldLoadZoning && currentImageUrl && feature === 'zoning' && !isNitrogenTab) {
      setShouldLoadZoning(false);
      setTimeout(() => dispatch(loadZoningData()), 1000); // for some reason the loadZoning is called before the state is updated
    }
  }, [shouldLoadZoning, currentImageUrl, feature, zoning.tab]);

  useEffect(() => {
    if (feature === 'zoning') {
      checkFavoriteZoningMethod();
      if (currentSensor === 'TCI' || currentSensor === 'NC') dispatch(setSensor('CCCI'));
    }
  }, [feature]);

  useEffect(
    function updateNRxToggleURLParam() {
      if (isNrxAllowed) {
        setGetParamToURL('nrx-toggle', `${zoning.tab === ZoningTab.NitrogenRx}`);
      } else if (getGetURLParam('nrx-toggle')) {
        setGetParamToURL('nrx-toggle', null);
      }
    },
    [zoning.tab]
  );

  useEffect(
    function triggerMainZoningLoading() {
      if (feature === 'zoning') {
        if (
          !zoning.zones.length &&
          prev?.zoning?.tab === ZoningTab.NitrogenRx &&
          zoning.tab !== ZoningTab.NitrogenRx
        ) {
          // load zoning if it wan't loaded because of n-recommendation enabled
          setShouldLoadZoning(true);
        }
      }
    },
    [zoning.tab]
  );

  useEffect(
    function NRxRelatedEffects() {
      if (feature === 'zoning') {
        const {method, nrxResult, nrxTabRate, selectedObjective} = nRecommendation;

        if (zoning.tab !== ZoningTab.NitrogenRx) return;
        if (method === 'apsim') {
          if (
            [loadingNRxRequest.status, loadingNRxListsStatus].includes(Status.Pending) ||
            loadingNRxRequest.errors?.fieldId === currentFieldId // means it tried to load but catched an error
          ) {
            return;
          }
          if (!loadingNRxListsStatus) {
            dispatch(loadNrxData());
          } else if (
            nRecommendation.displayResults &&
            currentNRxSeason.recommendationDates?.length &&
            (!nrxResult[nrxTabRate][selectedObjective].type || shouldLoadNRx)
          ) {
            dispatch(getNRxRecommendation());
          }
        } else if (method === 'nutrilogic') {
          Mixpanel.getNutrilogic();
          dispatch(runNutrilogicRecommendation());
        }
      }
    },
    [
      feature,
      zoning.tab,
      loadingNRxListsStatus,
      nRecommendation.method,
      nRecommendation.displayResults,
      currentFieldId,
      currentNRxSeason,
      shouldLoadNRx,
    ]
  );

  useEffect(() => {
    setShouldLoadNrx(true);
  }, [currentNRxSeason]);

  useEffect(
    function NRxRecommendationDateEffect() {
      const currentRecommendationDate =
        nRecommendation.nrxRecommendationSettings.recommendation_date;
      if (
        currentRecommendationDate &&
        currentNRxSeason.recommendationDates.length &&
        !currentNRxSeason.recommendationDates.includes(currentRecommendationDate)
      ) {
        dispatch(
          updateRecommendationDate(
            getLastAvailableRecommendationDate(currentNRxSeason.recommendationDates)
          )
        );
      }
    },
    [currentNRxSeason]
  );

  useEffect(
    function handleLayerTypeChanges() {
      if (isTreeAnalysis) {
        const method = 'Intervals';
        if (zoning.method !== method) dispatch(setZoning({method: method}));
      }
    },
    [isTreeAnalysis]
  );

  useEffect(
    function handleTreesZoning() {
      if (feature === 'zoning' && !currentMethodFits) {
        dispatch(setZoning({method: isTreeAnalysis ? 'Intervals' : RegularZoningMethods[0].value}));
        setShouldLoadZoning(true);
      }
      // else if (
      //   feature === 'zoning' &&
      //   wholeFarm.isWholeFarmView &&
      //   !Object.keys(wholeFarm.treeZoning.fields).length
      // ) {
      //   setShouldLoadZoning(true);
      // }
    },
    [feature]
  );

  const currentMethodFits = (isTreeAnalysis ? TreesZoningMethods : RegularZoningMethods).find(
    m => m.value === zoning.method // check is current selected method fits for the zoning methods according to the zoning type (regular, tree)
  );
  useEffect(
    function handleLayerChange() {
      if (currentImageUrl) {
        if (!currentMethodFits) {
          //WARNING: isTreeAnalysis is not at deps ???
          dispatch(setZoning({method: isTreeAnalysis ? 'Records' : RegularZoningMethods[0].value}));
        }
        setShouldLoadZoning(true);
      }
    },
    [currentImageUrl]
  );

  const checkFavoriteZoningMethod = () => {
    if (
      userSavedZoning?.method &&
      Object.keys(userSavedZoning).some(
        // check if the values are different do prevent setting the same
        (key: keyof IZoning) => zoning[key] !== userSavedZoning[key]
      ) &&
      !zoning.selectedByUser
    ) {
      dispatch(
        setZoning({
          ...userSavedZoning,
          isFavoriteMethod: true,
        })
      );
      setShouldLoadZoning(true);
    }
  };
  useEffect(
    function setUserSavedZoningMethod() {
      if (feature === 'zoning' && prev?.farm?.id !== farm.id) {
        checkFavoriteZoningMethod();
      }
    },
    [farm, feature]
  );

  useEffect(() => {
    if (
      // toggle nutrilogic off when the last points group or last point in last points group was deleted || the new points group === 'all'
      isNitrogenTab &&
      nRecommendation.method === 'nutrilogic' &&
      ((prev?.currentPointsGroup && !currentPointsGroup) ||
        (prev?.pointsCurrentGroupDate !== 'all' && pointsCurrentGroupDate === 'all'))
    ) {
      dispatch(setZoningTab(ZoningTab.MainZoning));
      // toggleNRecommendationMethod(false, 'nutrilogic');
    } else if (isNitrogenTab && nRecommendation.method === 'nutrilogic' && feature === 'zoning') {
      dispatch(runNutrilogicRecommendation());
    }
  }, [currentPointsGroup]);

  return null;
};

export default ZoningEffects;
