import React, {useMemo} from 'react';
import type {ComponentType} from 'react';
import {useAppSelector} from '_hooks';
import Select, {components} from 'react-select';
import type {ValueContainerProps, OptionProps, StylesConfig} from 'react-select';
import cn from 'classnames';
import {
  MenubarMiddleNavTabsContainer,
  MenubarMiddleNavOptionInnerContainer,
  MenubarMiddleNavIndicatorsInnerContainer,
  MenubarMiddleNavTabElement,
} from 'containers/menubar/menubar-middle-nav/menubar-middle-nav';
import {useHistory, useLocation, useRouteMatch} from 'react-router-dom';
import {
  MenubarMenuOptionStyles,
  MenubarMenuStyles,
  MenubarControlStyles,
  MenubarIndicatorsContainerStyles,
  MenubarValueContainerStyles,
  MenubarMenuListStyles,
} from 'containers/menubar/menubar.styled';
import {MenubarArrowDropdown} from 'containers/menubar/menubar-components';
import {selectViewport} from 'modules/global/selectors';
import {SI_CONFIGURE, SI_MONITOR, SI_PLAN} from 'containers/si/routes';
import {selectTabsUserHasAccessTo} from '../../si/module/selectors';
import type {TabAccess} from 'containers/si/api/apiTypes';
import {t} from 'i18n-utils';
import {isSingleOption} from 'containers/menubar/menubar-utils';
import {useProgramId} from 'containers/si/hooks/useProgramId';
import {isDefined, isNil} from '_utils/typeGuards';
import {applyPathParams} from '_utils/pure-utils';

type Option = {
  value: string;
  label: string;
  onClick?: (value?: string) => void;
  title?: string;
  topHorizontalSeparator?: boolean;
};

// Used for narrow screen view
const IndicatorsContainer: ComponentType<ValueContainerProps<Option>> = ({...props}) => {
  const configureMatch = useRouteMatch(SI_CONFIGURE);
  const monitorMatch = useRouteMatch(SI_MONITOR);
  const planMatch = useRouteMatch(SI_PLAN);
  let selectedFeatureGroup: string | null = null;

  switch (true) {
    case isDefined(configureMatch):
      selectedFeatureGroup = t({id: 'Configure', defaultMessage: 'Configure'});
      break;
    case isDefined(monitorMatch):
      selectedFeatureGroup = t({id: 'Monitor', defaultMessage: 'Monitor'});
      break;
    case isDefined(planMatch):
      selectedFeatureGroup = t({id: 'Plan', defaultMessage: 'Plan'});
      break;
  }

  return (
    <components.IndicatorsContainer {...props}>
      <MenubarMiddleNavIndicatorsInnerContainer>
        <div className="workspace-label">{selectedFeatureGroup}</div>
        <MenubarArrowDropdown />
      </MenubarMiddleNavIndicatorsInnerContainer>
    </components.IndicatorsContainer>
  );
};

const OptionComponent: ComponentType<OptionProps<Option>> = ({children, ...props}) => {
  const {pathname} = useLocation();
  const featureLink = props.data.value;

  const isSelected = !!featureLink && pathname.includes(featureLink);

  return (
    <MenubarMiddleNavOptionInnerContainer>
      <components.Option {...props} isSelected={isSelected}>
        {children}
      </components.Option>
    </MenubarMiddleNavOptionInnerContainer>
  );
};

export const SINavigationComponent: ComponentType = () => {
  const history = useHistory();
  const {pathname} = useLocation();
  const viewport = useAppSelector(selectViewport);
  const displayDropdownMenu = viewport.width <= 1000;

  const {paramsProgramId} = useProgramId();
  const tabsAccess = useAppSelector(selectTabsUserHasAccessTo);

  const isFeatureActive = (featureLink: string) => {
    return !!featureLink && pathname.includes(featureLink);
  };

  // These are the top level SI tabs
  const siMenuItems: Option[] = useMemo(() => {
    // If you are not on a program detail page, you do not have configure/monitor/plan tabs
    if (isNil(paramsProgramId)) {
      return [];
    }
    const SIFeatureGroupsWithTabs: {tabs: TabAccess[]; title: string; link: string}[] = [
      {
        tabs: ['admin', 'supply_shed'],
        title: t({id: 'Configure', defaultMessage: 'Configure'}),
        link: applyPathParams(SI_CONFIGURE, {programId: paramsProgramId}),
      },
      {
        tabs: ['kpi', 'map', 'comparison_dashboard', 'data_export'],
        title: t({id: 'Monitor', defaultMessage: 'Monitor'}),
        link: applyPathParams(SI_MONITOR, {programId: paramsProgramId}),
      },
      {
        tabs: ['plan_dashboard'],
        title: t({id: 'Plan', defaultMessage: 'Plan'}),
        link: applyPathParams(SI_PLAN, {programId: paramsProgramId}),
      },
    ];

    return SIFeatureGroupsWithTabs.filter(
      feature => !feature.tabs.length || feature.tabs.find(tab => tabsAccess.includes(tab))
    ).map(feature => ({
      value: feature.link,
      label: feature.title,
      onClick: () => history.push(feature.link),
    }));
  }, [history, paramsProgramId, tabsAccess]);

  // If you are not on a program detail page, you do not have configure/monitor/plan tabs
  if (isNil(paramsProgramId)) {
    return null;
  }

  return displayDropdownMenu ? (
    // Narrow screen view
    <Select
      // menuIsOpen
      isMulti={false}
      styles={TopLevelNavSelectStyles}
      onChange={value => {
        if (isSingleOption(value) && value?.onClick) {
          value.onClick();
        }
      }}
      options={siMenuItems}
      components={{IndicatorsContainer, Option: OptionComponent}}
    />
  ) : (
    // Normal screen view
    <MenubarMiddleNavTabsContainer className="middle-nav-container">
      {siMenuItems.map(feature => (
        <MenubarMiddleNavTabElement
          onClick={() => history.push(feature.value)}
          className={cn({selected: isFeatureActive(feature.value)})}
          key={feature.value}
        >
          {feature.label}
        </MenubarMiddleNavTabElement>
      ))}
    </MenubarMiddleNavTabsContainer>
  );
};

export const TopLevelNavSelectStyles: StylesConfig<Option> = {
  option: (base, state) => ({
    ...base,
    ...MenubarMenuOptionStyles,
    fontWeight: state.isSelected ? 700 : 400,
  }),
  valueContainer: base => ({...base, ...MenubarValueContainerStyles}),
  indicatorsContainer: base => ({
    ...base,
    ...MenubarIndicatorsContainerStyles,
  }),

  menu: base => ({
    ...base,
    ...MenubarMenuStyles,
    left: 0,
  }),
  control: base => ({
    ...base,
    ...MenubarControlStyles,
  }),
  menuList: base => {
    return {...base, ...MenubarMenuListStyles};
  },
};
