import type {ComponentType} from 'react';
import React, {useMemo} from 'react';
import {Bar} from 'react-chartjs-2';
import type {ChartTooltipOptions} from 'chart.js';

import 'rc-slider/assets/index.css';
import './index.scss';
import {t} from 'i18n-utils';
import {
  selectHistogramBarRangeLabels,
  selectHistogramBarColors,
  selectHistogramBarLegendData,
  selectHistogramBarData,
  selectHistogramBarValues,
  selectHistogramPercentages,
} from 'containers/map/selectors/histogram-selectors';
import {useAppSelector} from '_hooks';
import {selectMeasurement} from 'containers/login/login-selectors';
import {selectCurrentSensor} from 'containers/map/reducer/selectors';
import {toFixedFloatUnsafe} from '_utils/number-formatters';

const Legend: ComponentType = () => {
  const data = useAppSelector(selectHistogramBarLegendData);
  return (
    <>
      {data.labels.length ? (
        <table cellSpacing="0" className="histogram-legend">
          <thead>
            <tr>
              <td>
                <b>{t({id: 'Range'})}</b>
              </td>
              <td style={{textAlign: 'right'}}>
                <b>%</b>
              </td>
            </tr>
          </thead>

          <tbody>
            {data.labels.map((value, i) => {
              return (
                <tr key={`map-legend-item-${i}`}>
                  <td className="range-cell" style={{backgroundColor: data.colors[i]}}>
                    {value}
                  </td>
                  <td>{isFinite(data.percentages[i]) ? `${data.percentages[i]}%` : '-'}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      ) : (
        <b>{t({id: 'No data'})}</b>
      )}
    </>
  );
};

const Histogram = () => {
  const colors = useAppSelector(selectHistogramBarColors);
  const percentages = useAppSelector(selectHistogramPercentages);
  const values = useAppSelector(selectHistogramBarValues);
  const rangeLabels = useAppSelector(selectHistogramBarRangeLabels);
  const measurement = useAppSelector(selectMeasurement);
  const currentSensor = useAppSelector(selectCurrentSensor);
  const barData = useAppSelector(selectHistogramBarData);

  const tooltips: ChartTooltipOptions = useMemo(() => {
    return {
      callbacks: {
        title: tooltip => {
          const index = Number(tooltip[0].index);
          return rangeLabels[index];
        },
        label: tooltip => `${tooltip.value} ${measurement}`, // add the measurement label
      },
    };
  }, [measurement, rangeLabels]);

  return (
    <div className="histogram-popup">
      <div className="histogram-popup__bar">
        <Bar
          id={`histogram-bar`}
          data={{
            labels: percentages, // use percentages as labels cause the percentages.length is matches the number of bars, NOTE: labels are modified in options.scales.xAxes.ticks.callback
            datasets: [
              {backgroundColor: colors, data: barData, barPercentage: 1, categoryPercentage: 1},
            ],
          }}
          options={{
            legend: {display: false},
            responsive: false,
            tooltips: tooltips,
            scales: {
              yAxes: [
                {
                  scaleLabel: {
                    display: true,
                    labelString: measurement === 'ha' ? t({id: 'Hectare(s)'}) : t({id: 'Acre(s)'}),
                  },
                  gridLines: {
                    drawBorder: false, // do not draw the vertical line near ticks
                    drawTicks: false, // remove additional dash between tick and actual tick line
                  },
                  ticks: {
                    padding: 5,
                  },
                },
              ],
              xAxes: [
                {
                  scaleLabel: {
                    display: true,
                    labelString: currentSensor + ` ${t({id: 'Index'})}`,
                  },
                  gridLines: {
                    display: false,
                  },
                  ticks: {
                    maxRotation: 0,
                    minRotation: 0,
                    callback: (_, i, arr) => {
                      // in this section i move the labels to the right by adding spaces + add one more label to the end
                      // it's because each bar is representing a range of values ([0.6 - 0.7, 0.7 - 0.8, 0.8 - 0.9]), so there is always one more label than number of bars
                      const value = toFixedFloatUnsafe(values[i], 2);
                      if (i === arr.length - 1) {
                        // add one more label to the latest element
                        const nextValue = toFixedFloatUnsafe(values[i + 1], 2);
                        return `${value}${SPACES_BETWEEN_LAST_LABELS}${nextValue}`;
                      }
                      return `${value}${SPACES_BETWEEN_LABELS}`;
                    },
                  },
                },
              ],
            },
          }}
          width={275}
          height={300}
        />
      </div>

      <div className="legend-container">
        <Legend />
      </div>
    </div>
  );
};

const SPACES_BETWEEN_LABELS = `               `; // 15 spaces to create space between labels (to shift them to the right)
const SPACES_BETWEEN_LAST_LABELS = `        `; // 8 spaces to create space between labels (to shift them to the right)

export default Histogram;
