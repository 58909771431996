// @ts-nocheck
import type {AccessorFunc, BarSvgProps} from '@nivo/bar';
import {Bar} from '@nivo/bar';
import chroma from 'chroma-js';
import {Flex, FluroButton} from 'components';
import {Span} from 'components/text/span';
import type {ComponentType} from 'react';
import React, {useEffect, useState} from 'react';
import {main_gray_700} from '_utils/colors';
import type {AxisProps} from '@nivo/axes';

export type BarChartProps = {
  collapse?: boolean;
  data: ({id: string | number; value: number; name: string} & any)[];
  format?: (value: number) => string;
  getColor: (i: number, datum: any) => string;
  keys?: string[];
  maxVisibleItems: number;
  nameLabel: AxisProps['format'];
  allowShowMore?: boolean;
} & BarSvgProps;

const VALUE_SCALE: BarSvgProps['valueScale'] = {type: 'symlog'};

const defaultFormat = (value: any) => String(value);

const CHART_THEME = {
  fontSize: 12,
  color: main_gray_700,
  textColor: main_gray_700,
};

export const BarChart: ComponentType<BarChartProps> = ({
  data: initialData,
  format = defaultFormat,
  getColor,
  keys,
  maxVisibleItems,
  nameLabel,
  onMouseEnter,
  onMouseLeave,
  maxValue,
  minValue,
  labelSkipWidth = 0,
  allowShowMore = true,
}) => {
  const showMoreButton = initialData.length > maxVisibleItems;
  const [collapse, setCollapse] = useState(false);
  const chartData =
    showMoreButton && !collapse ? initialData.slice(0, maxVisibleItems) : initialData;

  useEffect(() => {
    setCollapse(false);
  }, [initialData]);

  const getBarColor = (item: any) => {
    const color = getColor(item.value, item);
    return color;
  };

  const barLabel: AccessorFunc = ({value}: {value: number}): string => {
    return format(value);
  };

  // Chart UI settings
  const barsCount = chartData.length;
  const sidebarWidth = 120;
  const topMargin = 0;
  const bottomMargin = 0;
  const barHeight = barsCount > 1 ? 26 : 14;
  const chartHeight = topMargin + bottomMargin + barsCount * barHeight;
  const barPadding = barsCount > 1 ? 0.4 : 0;

  const AXIS_BOTTOM = {
    tickSize: 0,
    tickPadding: 0,
    tickValues: 3,
    format,
  };
  const AXIS_LEFT = {tickSize: 0, tickPadding: 25, format: nameLabel};
  const CHART_MARGIN = {top: topMargin, right: 16, bottom: bottomMargin, left: sidebarWidth};

  const getLabelColor = (item: {color: string}) => {
    const textColor =
      chroma.contrast(item.color, '#fff') > chroma.contrast(item.color, '#000') ? '#fff' : '#000';
    return textColor;
  };

  return (
    <div>
      <div className="chart-container" style={{height: chartHeight}}>
        <Bar
          height={chartHeight}
          width={330}
          theme={CHART_THEME}
          layout="horizontal"
          groupMode="grouped"
          indexBy="id"
          keys={keys}
          data={[...chartData].reverse()}
          animate={false}
          //
          labelSkipHeight={10}
          labelSkipWidth={labelSkipWidth}
          padding={barPadding}
          axisBottom={AXIS_BOTTOM}
          axisLeft={AXIS_LEFT}
          axisRight={null}
          axisTop={null}
          borderColor="#C2C2C2"
          borderRadius={8}
          borderWidth={1}
          colors={getBarColor}
          enableGridX={false}
          enableGridY={false}
          enableLabel
          labelTextColor={getLabelColor}
          innerPadding={0}
          label={barLabel}
          margin={CHART_MARGIN}
          valueScale={VALUE_SCALE}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          isInteractive={false}
          maxValue={maxValue}
          minValue={minValue}
        />
      </div>

      {showMoreButton && (
        <>
          {allowShowMore ? (
            <Flex justifyContent="flex-end">
              {collapse ? (
                <FluroButton flat onClick={() => setCollapse(false)}>
                  Collapse
                </FluroButton>
              ) : (
                <FluroButton flat onClick={() => setCollapse(true)}>
                  SEE {initialData?.length - maxVisibleItems} MORE
                </FluroButton>
              )}
            </Flex>
          ) : (
            <Flex justifyContent="flex-end">
              <Span secondary>
                Showing {maxVisibleItems} results out of {initialData?.length}
              </Span>
            </Flex>
          )}
        </>
      )}
    </div>
  );
};
