import type {AxiosPromise} from 'axios';
import Server from './server';
import type {
  AverageNDVIPayload,
  CSGInfo,
  CSGResponse,
  FieldsVariabilityResponse,
} from 'containers/map/features/crop-performance/types';
import service from '_api/service';
import cancelTokenStore from '_api/cancel-tokens-store';

export default class {
  static deleteSeason(groupId: number, kmlId: number, seasonId: number) {
    return Server.delete(`season/${groupId}/${kmlId}/${seasonId}`);
  }

  static getSeasonList(groupId: number, kmlId: number) {
    return Server.get(`season/${groupId}/${kmlId}`);
  }

  static getSeason(groupId: number, kmlId: number, seasonId: number) {
    return Server.get(`season/${groupId}/${kmlId}/${seasonId}`);
  }

  static saveCrop(groupId: number, kmlId: number, id: string | number, crop: any) {
    return Server[id === 'new' ? 'post' : 'put'](
      `season/${groupId}/${kmlId}${id === 'new' ? '' : '/' + id}`,
      crop
    );
  }

  static saveBulkCrop(groupId: number, arrayFieldsWithCrops: any[], allowOverlapping = false) {
    // `result` is a list of new season ids. If the season already had an id, it won't be returned in result.
    return Server.post<{result: number[]; status: 'ok'}>(
      `season/${groupId}`,
      arrayFieldsWithCrops,
      {
        params: {allow_overlapping: allowOverlapping, __skipPreloader: true},
      }
    );
  }

  static cloneSeasons(seasonIds: number[], kmlIds: number[]) {
    return Server.post(`copy_seasons_to_fields`, {seasonIds, kmlIds});
  }

  /*
   * @param data: is array of GeoJeSON data
   * @param includeFileNames: is param for configure the name of files which contains in the downloaded archive
   * */
  static downloadShapefile(data: any[], name?: string) {
    return Server({
      url: 'geojson/shp' + (name ? '?includeFileNames=' + encodeURI(name) : ''),
      method: 'post',
      responseType: 'blob',
      timeout: 30000,
      data,
    });
  }

  static getSimpleCropPerformanceByFarmId(farmID: number, params: any) {
    // cancel prev request if exist;
    cancelTokenStore.cancel('getSimpleCropPerformanceByFarmId' + farmID);
    const source = cancelTokenStore.generateSource('getSimpleCropPerformanceByFarmId' + farmID);

    return Server({
      method: 'post',
      url: `crop_performance/${farmID}`,
      data: params,
      params: {__skipPreloader: true},
      cancelToken: source.token,
    });
  }

  static getCropPerformanceCSVByFarmId(farmID: number, params: any): AxiosPromise<string> {
    return Server({
      method: 'post',
      url: `crop_performance/${farmID}?dl=1`,
      data: params,
      // responseType: 'blob',
      params: {__skipPreloader: true},
    });
  }

  static getCropPerformanceInfo = (): AxiosPromise<CSGInfo> => {
    return service.get('crop-status-growth/crop_performance/info');
  };

  static getCropPerformanceNdviStatus = (
    seasons: AverageNDVIPayload[],
    params?: {[param: string]: boolean},
    __skipPreloader?: boolean
  ): AxiosPromise<CSGResponse> => {
    const payload = {
      seasons,
      planting_areas: seasons.some(s => s.geometry_id),
      include_prediction: true,
      ...(params ? params : {}),
    };

    return service.post('crop-status-growth/crop_performance/ndvi_and_status_growth', payload, {
      params: {__skipPreloader: __skipPreloader !== undefined ? __skipPreloader : true},
    });
  };

  static getFieldVariabilityScore = (
    seasons: {
      md5: string;
      start_date: string;
      end_date: string;
      crop_type: string;
      key?: number | string;
    }[]
  ): AxiosPromise<FieldsVariabilityResponse> => {
    return service.post(
      'crop-status-growth/crop_performance/variability_score',
      {seasons},
      {params: {__skipPreloader: true}}
    );
  };
}
