import React from 'react';
import type {ComponentType} from 'react';
import styled from 'styled-components';
import {getWorkspace} from 'containers/admin/users/types';
import cn from 'classnames';
import {CropInsightsNavigationComponent} from 'containers/menubar/menubar-middle-nav/crop-insights-component';
import {SINavigationComponent} from 'containers/menubar/menubar-middle-nav/si-navigation';
import {MRVNavigationComponent} from 'containers/menubar/menubar-middle-nav/mrv-navigation';
import {useLocation} from 'react-router-dom';
import {GLOBAL_ADMIN_PATH} from '_environment';

export const MenubarTopLevelNavigationComponent: ComponentType = () => {
  const currentWorkspace = getWorkspace(window.location.pathname);
  const {pathname} = useLocation();

  if (pathname.includes(GLOBAL_ADMIN_PATH)) return <div />; // put a div as a center component to give the parent grid a correct second elem

  return (
    <MenubarTopLevelMiddleNavMenuContainer
      className={cn({hidden: false})}
      id="menubar__top-level-nav-component"
    >
      {currentWorkspace === 'ci' ? <CropInsightsNavigationComponent /> : null}
      {currentWorkspace === 'si' ? <SINavigationComponent /> : null}
      {currentWorkspace === 'mrv' ? <MRVNavigationComponent /> : null}
    </MenubarTopLevelMiddleNavMenuContainer>
  );
};

export const MenubarMiddleNavTabsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
`;

export const MenubarMiddleNavTabElement = styled.div`
  font-size: 14px;
  color: ${({theme}) => theme.colorPalette.fs_main.gray_400};
  cursor: pointer;
  white-space: nowrap;
  transition: color 0.3s, font-wight 0.3s;
  &:hover {
    color: #fff;
  }
  &.selected {
    color: #fff;
    font-weight: 700;
  }
`;
export const MenubarMiddleNavOptionInnerContainer = styled.div`
  .subitem {
    color: ${({theme}) => theme.colorPalette.fs_main.gray_400};
    font-size: 12px;
  }
`;
export const MenubarMiddleNavIndicatorsInnerContainer = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 15px;

  .dropdown-icon {
    color: ${({theme}) => theme.color.stroke.strong};
  }

  .workspace-label {
    color: ${({theme}) => theme.color.stroke.strong};
    font-weight: 700;
    font-size: 14px;
  }
`;
const MenubarTopLevelMiddleNavMenuContainer = styled.div`
  display: flex;
  flex-grow: 1;
  justify-content: center;
  position: relative;
  opacity: 1;
  transition: opacity 0.4s; // perfect time to hide blinking
  .middle-nav-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  &.hidden {
    opacity: 0;
  }
`;
