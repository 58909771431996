import {t, FormattedMessage} from 'i18n-utils';
import {Button} from 'react-md';
import {FluroDialog, InfoBlock} from 'components';
import React from 'react';
import EditMarkerComp from './edit-marker';
import type {SamplingPoint} from '../../types';
import TSUpload from '../../tissue-sampling-upload/ts-upload';
import type {ConnectedProps} from 'react-redux';
import {connect} from 'react-redux';
import {toggleDrawingMode} from '../../actions';
import {savePoint} from '../../actions/sampling-points';

type Props = ConnectedProps<typeof connector> & {
  visible: boolean;
  currentPointId: string | number;
  currentPoint: SamplingPoint;
  isReadOnly: boolean;
  onHide(): void;
};

const EditPointPopUp = ({
  onHide,
  visible,
  currentPointId,
  currentPoint,
  isReadOnly,
  toggleDrawingMode,
  savePoint,
}: Props) => {
  const startDrawMarker = () => {
    onHide();
    toggleDrawingMode(true, 'marker');
  };

  return (
    <FluroDialog
      title={t({id: 'Add point'})}
      onHide={onHide}
      visible={visible}
      id={'add-new-marker'}
      isDraggable
      width={'auto'}
      portal
    >
      <div className={'add-new-marker-dialog-content'}>
        {!!currentPointId ? (
          <EditMarkerComp
            id="map-edit-marker-form"
            onSave={savePoint}
            marker={currentPoint}
            isReadOnly={isReadOnly}
          />
        ) : (
          <>
            <div className="buttons-wrapper">
              <Button id="draw-a-point" primary raised onClick={startDrawMarker}>
                {t({id: 'Drop a pin'})}
              </Button>

              <TSUpload onCloseDialog={onHide} />
            </div>

            <div className={'help-text'}>
              <InfoBlock className="dialog-info-block">
                <FormattedMessage
                  id="You can upload your tissue sampling file (.csv, .kml, .xlsx, .xls)"
                  defaultMessage="You can upload your tissue sampling file (.csv, .kml, .xlsx, .xls) to be parsed automatically. Make sure you have the right farm selected first, then download the template <a>here</a> and fill it with your results before uploading."
                  values={{
                    a: (txt: string) => (
                      <a href="/assets/samples/ts_sample.xlsx" target="_blank">
                        {txt}
                      </a>
                    ),
                  }}
                />
              </InfoBlock>
            </div>
          </>
        )}
      </div>
    </FluroDialog>
  );
};
const connector = connect(null, {
  toggleDrawingMode,
  savePoint,
});
export default connector(EditPointPopUp);
