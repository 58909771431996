import React, {useEffect, useMemo, useState} from 'react';
import {InputWrapper} from './fluro-textarea.styled';
import cn from 'classnames';
import {genKey} from '_utils/utils2';
import {Text} from 'components';

type Props = {
  value?: string;
  onChange?: (value: string) => void;
  placeholder?: string;
  label?: string;
  error?: string;
  name?: string;
  id?: string;
  'data-cy'?: string;
  className?: string;
  defaultValue?: string;
  style?: React.CSSProperties;
  disabled?: boolean;
  rows?: number;
  /**
   * In px
   */
  maxHeight?: number;
  maxLength?: number;
};

/**
 *  TODO: Refactor, remove the internal state.
 */
export const FluroTextarea = ({
  className,
  error,
  placeholder,
  label,
  value,
  onChange,
  name,
  id,
  defaultValue,
  style,
  disabled,
  rows = 2,
  maxHeight = 150,
  maxLength,
  'data-cy': dataCy,
}: Props) => {
  const rnd = useMemo(() => genKey(), []);
  const _id = id || `input-${name}-${rnd}`;
  const [_value, setValue] = useState<string | undefined>(value);

  useEffect(() => {
    if (value !== _value) {
      setValue(value);
    }
  }, [_value, value]);

  return (
    <InputWrapper
      data-cy={dataCy}
      error={!!error}
      maxHeight={maxHeight}
      className={cn(className, {'not-empty': !!_value})}
      style={style}
    >
      {label ? (
        // TODO: fixme a11y
        // eslint-disable-next-line jsx-a11y/label-has-for
        <label className="label" htmlFor={_id}>
          {label}
        </label>
      ) : null}
      <textarea
        rows={rows}
        name={name}
        className="textarea"
        id={_id}
        disabled={disabled}
        defaultValue={defaultValue}
        placeholder={placeholder}
        value={_value}
        onChange={e => {
          const v = e.target.value;
          if (maxLength && v.length > maxLength) {
            return;
          }

          onChange?.(v);
          setValue(v);
        }}
      />

      {error && <div className="md-text md-text-field-message md-text--error">{error}</div>}

      {maxLength && (
        <Text secondary variant="small-thin" noMargin>
          {_value?.length || 0}/{maxLength}
        </Text>
      )}
    </InputWrapper>
  );
};
