import type {ComponentType} from 'react';
import React from 'react';
import {useAppDispatch, useAppSelector} from '_hooks';
import {
  selectAreaUnits,
  selectCoverCropsTrendChartData,
} from 'modules/sustainability-insights/selectors';
import {TrendChart} from '../trend-chart';
import {setActiveGeometriesFilter} from 'modules/sustainability-insights/actions';

type Props = {};

export const CoverCropsTrendChart: ComponentType<Props> = () => {
  const dispatch = useAppDispatch();
  const trendChartData = useAppSelector(selectCoverCropsTrendChartData);
  const units = useAppSelector(selectAreaUnits);

  const handleClear = () => {
    dispatch(setActiveGeometriesFilter(null));
  };

  return <TrendChart data={trendChartData} units={units} onClearSelection={handleClear} />;
};
