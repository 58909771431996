import {t, FormattedMessage} from 'i18n-utils';
import React from 'react';
import {FontIcon, SelectField, TextField} from 'react-md';
import {FluroButton, FluroDialog} from 'components';
import Yup from 'yup';
import CropStatus from '../crop-status-icon';
import type {NRxSeason} from 'containers/map/features/nrx';
import {
  getPropertiesFromSeasons,
  isSetNrxSeasonSowingData,
  isSetNrxSeasonCropMatch,
} from 'containers/map/features/nrx';
import {capitalizeFirstLetter} from '_utils/pure-utils';
import {useAppDispatch, useAppSelector} from '_hooks';
import {selectNRxCropSubTypes, selectNRxListsData} from 'containers/map/features/nrx/nrx-selectors';
import {useForm} from 'react-hook-form';
import {updateNRxCropTypeTypeParams} from 'containers/map/actions/nitrogen-recommendation-actions';

import {yupResolver} from '@hookform/resolvers/yup';

type FormData = {
  cropID: number;
  cropVarietyID: number;
};

type Props = {
  selectedNRxSeasons: NRxSeason[];
  togglePopUp: (popUp: string, value: boolean, seasonToSet?: number) => void;
};

const validationSchema = Yup.object().shape({
  cropID: Yup.number().min(1, t({id: 'This field is required'})),
  cropVarietyID: Yup.number().min(1, t({id: 'This field is required'})),
});

const MatchCropPopUp = ({selectedNRxSeasons, togglePopUp}: Props) => {
  const dispatch = useAppDispatch();
  const listsData = useAppSelector(selectNRxListsData);

  let {seasonID, cropType, cropSubtype, cropVarietyID, cropID} =
    getPropertiesFromSeasons(selectedNRxSeasons);

  cropType = Array.isArray(cropType) ? t({id: 'Multiple crops'}) : capitalizeFirstLetter(cropType);

  cropSubtype = Array.isArray(cropSubtype)
    ? t({id: 'Multiple varieties'})
    : capitalizeFirstLetter(cropSubtype || t({id: 'Empty'}));

  /**
   * Start form setup
   */

  const {
    register,
    setValue,
    handleSubmit,
    watch,
    reset,
    formState: {errors},
  } = useForm<FormData>({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      cropID: Array.isArray(cropID) ? 0 : cropID,
      cropVarietyID: Array.isArray(cropVarietyID) ? 0 : cropVarietyID,
    },
  });

  register('cropID');
  register('cropVarietyID');

  const values = watch();

  /**
   * End form setup
   */

  const onHandleSubmit = async () => {
    await dispatch(updateNRxCropTypeTypeParams(seasonID, values.cropID, values.cropVarietyID));

    if (!selectedNRxSeasons.every(isSetNrxSeasonSowingData))
      return togglePopUp('plantingSoilPopUpOpen', true, Array.isArray(seasonID) ? null : seasonID);

    togglePopUp('matchCropPopUpOpen', false);
  };

  const onChange = (prop: keyof FormData, value: any) => {
    setValue(prop, value, {shouldValidate: true});
    if (prop === 'cropID') setValue('cropVarietyID', 0);
  };

  const onHide = () => {
    const confirm = selectedNRxSeasons.every(isSetNrxSeasonCropMatch)
      ? true
      : window.confirm(
          t({
            id: 'You need to set all parameters to start using the model, are you sure you want to cancel?',
          })
        );

    if (confirm) {
      togglePopUp('matchCropPopUpOpen', false);
      reset();
    }
  };

  const cropSubTypes = useAppSelector(s => selectNRxCropSubTypes(s, values.cropID));
  return (
    <FluroDialog
      id={`match-crop-type-pop-up`}
      dialogClassName={'match-crop-type-pop-up'}
      visible={true}
      isDraggable
      title={t({id: 'Match crop type and varieties to available models'})}
      onHide={onHide}
      focusOnMount={false}
    >
      <div className={'info-text'}>
        <FontIcon>help_outline</FontIcon>
        {t({
          id: 'APSIM does not have models available for your selected (crop type or variety). Please select the closest (crop type or variety) available from the following list:',
        })}
      </div>

      <div className={'columns-container'}>
        <div className={'column'}>
          <div className={'title'}>{t({id: 'Your selection'})}</div>
          <div className={'d-flex align-center'}>
            <TextField
              id="crop-type"
              label={t({id: 'Your crop type'})}
              lineDirection="center"
              value={cropType}
              disabled
            />
            <CropStatus
              status={Boolean(values.cropID)}
              message={
                <span>
                  <FormattedMessage
                    id={'Crop is not <br /> available in the <br /> model'}
                    values={{br: () => <br />}}
                  />
                </span>
              }
            />
          </div>

          <div className={'d-flex align-center'}>
            <TextField
              id="crop-custom-sub-type"
              label={t({id: 'Your variety'})}
              lineDirection="center"
              value={cropSubtype}
              disabled
            />

            <CropStatus
              status={Boolean(values.cropVarietyID)}
              message={
                <span>
                  <FormattedMessage
                    id={'Variety is not <br /> available in the <br /> model'}
                    values={{br: () => <br />}}
                  />
                </span>
              }
            />
          </div>
        </div>
        <div className={'column'}>
          <div className={'title'}>{t({id: 'APSIM match'})}</div>
          <SelectField
            id="crop-sub-type"
            label={t({id: 'APSIM crop type'})}
            placeholder={t({id: 'Select crop type'})}
            menuItems={listsData.cropTypesList}
            simplifiedMenu={true}
            value={values.cropID}
            error={!!errors.cropID}
            errorText={errors.cropID?.message}
            onChange={value => onChange('cropID', value)}
          />

          <SelectField
            id="crop-sub-type"
            label={t({id: 'APSIM variety'})}
            placeholder={t({id: 'Select a variety'})}
            menuItems={cropSubTypes}
            simplifiedMenu={true}
            value={values.cropVarietyID}
            error={!!errors.cropVarietyID}
            errorText={errors.cropVarietyID?.message}
            onChange={value => onChange('cropVarietyID', value)}
            disabled={!cropSubTypes.length}
            onClick={() => (cropSubTypes.length ? null : alert(t({id: 'Select crop type first'})))}
          />
        </div>
      </div>

      <div className={'button-container'}>
        <FluroButton raised primary onClick={handleSubmit(() => onHandleSubmit())}>
          {t({id: 'Save changes'})}
        </FluroButton>
      </div>
    </FluroDialog>
  );
};

export default MatchCropPopUp;
