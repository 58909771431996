import type {AppStore} from 'reducers';
import {defaultNrxSeason} from 'containers/map/features/nrx';
import {
  selectCurrentFarm,
  selectCurrentField,
  selectCurrentSeasonId,
  selectIsNitrogenZoningTab,
  selectMapFields,
} from 'containers/map/reducer/selectors';
import type {Field} from '../../types';
import {createSelector} from '@reduxjs/toolkit';
import {selectUser} from 'containers/login/login-selectors';
import {createCachingSelector} from '_utils/pure-utils';
import type {TNrxSeasonProp, SoilType, NRxFertilizerListItem} from 'containers/map/features/nrx';
import {
  NRxAutomaticSoilTypeId,
  NRxAutomaticSoilTypeLabel,
  NRxAutomaticSoilDepthLabel,
} from 'containers/map/features/nrx/nrx-constants';

export const selectNRecommendation = (state: AppStore) => state.map.nRecommendation;

export const selectIsNRxEnabled = (state: AppStore) =>
  selectIsNitrogenZoningTab(state) && selectNRecommendation(state).method === 'apsim';

export const selectCurrentNRxSeason = (s: AppStore) => {
  const fields = selectMapFields(s);
  const currentSeasonId = selectCurrentSeasonId(s);

  let season = defaultNrxSeason;
  fields.forEach(f =>
    f.Seasons?.forEach(s => {
      if (s.nrx && s.nrx.seasonID === currentSeasonId) season = s.nrx;
    })
  );
  return season;
};

export const selectNRxListsData = (s: AppStore) => s.map.listsData;

export const selectNrxSeason = (s: AppStore, seasonId: number, customFields?: Field[]) => {
  const fields = customFields || selectMapFields(s);

  let season = defaultNrxSeason;
  fields.forEach(f =>
    f.Seasons?.forEach(s => {
      if (s.nrx && s.nrx.seasonID === seasonId) season = s.nrx;
    })
  );
  return season;
};

export const selectNrxFertilizerListItemData = createSelector(
  [selectNRxListsData, (s: AppStore, key: number | string) => key],
  (listsData, key) => {
    return listsData.fertilizerTypes.find(f =>
      typeof key === 'number' ? f.typeID === key : f.type === key
    );
  }
);

export const selectNRxLogsData = createSelector(
  [selectCurrentNRxSeason, selectUser, selectCurrentField, selectCurrentFarm],
  (nrxSeason, user, field, farm) => {
    return {
      nrxSeasonId: nrxSeason.nrxSeasonID,
      userId: user.id,
      fieldId: field.ID,
      farmId: farm.id,
      fieldName: field.Name,
      farmName: farm.name,
      orgName: farm.organizationName,
    };
  }
);

export const selectNRxCropTypeBySubtype = createCachingSelector(
  [selectNRxListsData, (s: AppStore, varietyId: number) => varietyId],
  (listsData, varietyId) => {
    const foundCropType = listsData.cropTypesList.find(cropType =>
      cropType.variety.find(v => v.value === varietyId)
    );
    return foundCropType || ({} as TNrxSeasonProp);
  }
);

export const selectNRxCropSubTypeLabel = createCachingSelector(
  [selectNRxListsData, (s: AppStore, subTypeId: number) => subTypeId],
  (listsData, subTypeId) => {
    let varietyLabel = '';
    listsData.cropTypesList.forEach(cropType =>
      cropType.variety.forEach(v => (varietyLabel = v.value === subTypeId ? v.label : varietyLabel))
    );
    return varietyLabel;
  }
);

export const selectNRxCropSubTypes = createCachingSelector(
  [selectNRxListsData, (s: AppStore, cropTypeId: number) => cropTypeId],
  (listsData, cropTypeId = 0) => {
    const foundCropType = listsData.cropTypesList.find(cropType => cropType.value === cropTypeId);
    return foundCropType ? foundCropType.variety : [];
  }
);

export const selectNRxSoilDepthsRecordsBySoilLabel = createSelector(
  [selectNRxListsData],
  listsData => {
    const result: Record<string, {label: string; value: number}[]> = {
      [NRxAutomaticSoilTypeLabel]: [
        {value: NRxAutomaticSoilTypeId, label: NRxAutomaticSoilDepthLabel},
      ],
    };

    listsData.soilTypesRawList.forEach(soilType => {
      if (result[soilType.type]) {
        result[soilType.type].push({value: soilType.id, label: soilType.depth});
      } else {
        result[soilType.type] = [{value: soilType.id, label: soilType.depth}];
      }
    });

    return result;
  }
);
export const selectNRxSoilTypeRecordsBySoilTypeId = createSelector(
  [selectNRxListsData],
  listsData => {
    const result: Record<number, SoilType> = {
      [NRxAutomaticSoilTypeId]: {
        type: NRxAutomaticSoilTypeLabel,
        id: NRxAutomaticSoilTypeId,
        apsoilID: 0,
        depth: NRxAutomaticSoilDepthLabel,
      },
    };

    listsData.soilTypesRawList.forEach(soilType => {
      result[soilType.id] = soilType;
    });

    return result;
  }
);

export const selectNRxFertilizersRecordsByTypeId = createSelector(
  [selectNRxListsData],
  listsData => {
    const result: Record<number, NRxFertilizerListItem> = {};
    listsData.fertilizerTypes.forEach(f => {
      result[f.typeID] = f;
    });
    return result;
  }
);
