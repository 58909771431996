import {createSelector} from '@reduxjs/toolkit';
import chroma from 'chroma-js';
import {sortByKeyReverseUnsafe, sortByKeyReverse} from '_utils/sorters';
import {calculatePercentage, createCachingSelector} from '_utils/pure-utils';
import {SUMMARY_BARS_PREVIEW_THRESHOLD} from '../constants';
import type {
  AreaId,
  GroupedSummaryChartData,
  MetricEmissionsFactorStatDatum,
  SIStateChildMeta,
  SocGhgSummaryChartData,
} from '../types';
import {SIGeometryFilterType} from '../types';
import {calculateScale, isInRange, parseAreaId} from '../utils';
import {
  getAreaId,
  selectActiveGeometriesIds,
  selectAggLevel,
  selectAreaUnits,
  selectCurrentMeta,
  selectGeometriesColors,
  selectIsYearsDiff,
  selectMetrics,
  selectSIChartGroupingType,
  selectSIFilter,
  selectStatesMeta,
  selectYearsFilterRange,
} from './selectors';
import groupBy from 'lodash/groupBy';
import {
  classifyExtremaPerUnitsFromValues,
  classifySocGhgStateStats,
  getDefaultExtremaPerUnits,
} from 'containers/map/features/sustainability-insights/filters-panel/cards/utils';

export const selectEmissionsFactorCardState = createSelector(
  [selectSIFilter],
  f => f[SIGeometryFilterType.EmissionsFactor]
);
export const selectEmissionsFactorChartTab = createSelector(
  [selectEmissionsFactorCardState],
  cardState => cardState.chartTab
);
export const selectEmissionsFactorCardRange = createSelector(
  [selectEmissionsFactorCardState],
  cardState => cardState.range
);

export const selectEmissionsFactorStats = createSelector(
  [
    selectActiveGeometriesIds,
    selectMetrics,
    selectYearsFilterRange,
    selectGeometriesColors,
    selectCurrentMeta,
  ],
  (
    activeIds,
    data,
    yearsRange,
    colorsMap,
    currentMeta
  ): Record<number, MetricEmissionsFactorStatDatum> => {
    if (!data || !activeIds?.length || !yearsRange?.length) return {};

    const numValuesPerId: Record<AreaId, number> = {}; // store values per id to prevent double calculations
    let totalValues = 0; // classify total values sum to calculate percentage later

    activeIds.forEach(id => {
      const dataPerYear = data[id];
      if (!dataPerYear) {
        return;
      }
      let efValuePerEntityAndYears = 0;

      yearsRange.forEach((year: number) => {
        efValuePerEntityAndYears = efValuePerEntityAndYears + Number(dataPerYear[year]?.ef); // get total value per years
      });

      if (!efValuePerEntityAndYears) return;
      numValuesPerId[id] = efValuePerEntityAndYears;
      totalValues = totalValues + efValuePerEntityAndYears;
    });

    const stats = activeIds.reduce<{[key: number]: MetricEmissionsFactorStatDatum}>(
      (acc, id: number) => {
        const dataPerYear = data[id];
        const entityTotalValue = numValuesPerId[id];

        if (!dataPerYear || entityTotalValue === undefined) {
          return acc;
        }

        const {name, statefp} = (currentMeta[id] || {}) as SIStateChildMeta;

        acc[id] = {
          // classify a chart entity
          id,
          name,
          statefp,
          values: {
            num: entityTotalValue,
            pct: calculatePercentage(entityTotalValue, totalValues),
            rawNumValue: entityTotalValue, // use to get later the total values
          },
          color: colorsMap[id],
        };

        return acc;
      },
      {}
    );

    return stats;
  }
);

export const selectEmissionsFactorStateStats = createSelector(
  [selectAggLevel, selectEmissionsFactorStats],
  (aggLevel, stats) => classifySocGhgStateStats(aggLevel, stats)
);

export const selectActiveEmissionsFactorStatsList = createSelector(
  [selectActiveGeometriesIds, selectEmissionsFactorStats],
  (ids, stats) => {
    if (!stats) return [];
    return ids.reduce((acc: MetricEmissionsFactorStatDatum[], id: number) => {
      const stat = stats[id];
      if (!stat) return acc;
      return [...acc, stat];
    }, []);
  }
);

export const selectEmissionsFactorStatsById = createSelector(
  [selectEmissionsFactorStats, getAreaId],
  (stats, id) => stats?.[id]
);

type Extrema = {num: {min: number; max: number}; pct: {min: number; max: number}};

export const selectEmissionsFactorExtremaPerUnits = createSelector(
  [selectActiveEmissionsFactorStatsList],
  (stats): Extrema => {
    if (!stats?.length) {
      return getDefaultExtremaPerUnits();
    }

    const values: {num: number[]; pct: number[]} = {num: [], pct: []};

    stats.forEach(stat => {
      const numStat = stat?.values?.num || 0;
      const pctStat = stat?.values?.pct || 0;
      values.num.push(numStat);
      values.pct.push(pctStat);
    });

    return classifyExtremaPerUnitsFromValues(values);
  }
);
export const selectEmissionsFactorGroupedExtremaPerUnits = createSelector(
  [
    selectActiveEmissionsFactorStatsList,
    selectEmissionsFactorStateStats,
    selectSIChartGroupingType,
  ],
  (stats, stateStats, groupingType): Extrema => {
    // get min/max values including grouped state values that might be higher than not grouped
    const values: {num: number[]; pct: number[]} = {num: [], pct: []};

    if (!stats.length || groupingType !== 'grouped' || !stateStats)
      return getDefaultExtremaPerUnits();

    stats.forEach(stat => {
      const numStat = stat?.values?.num || 0;
      const pctStat = stat?.values?.pct || 0;
      values.num.push(numStat);
      values.pct.push(pctStat);
    });

    Object.values(stateStats).forEach(stat => {
      const numStat = stat?.num || 0;
      const pctStat = stat?.pct || 0;
      values.num.push(numStat);
      values.pct.push(pctStat);
    });

    return classifyExtremaPerUnitsFromValues(values);
  }
);

export const selectEmissionsFactorStatsExtrema = createSelector(
  [selectAreaUnits, selectEmissionsFactorExtremaPerUnits],
  (units, extrema) => extrema[units]
);
export const selectEmissionsFactorGroupedStatsExtrema = createSelector(
  [selectAreaUnits, selectEmissionsFactorGroupedExtremaPerUnits],
  (units, extrema) => extrema[units]
);

export const selectEmissionsFactorColorScaleValues = createSelector(
  [selectEmissionsFactorStatsExtrema],
  ({min, max}) => {
    if (max <= 0) {
      return {colors: ['#37681c', '#fff']};
    } else if (min >= 0) {
      return {colors: ['#fff', 'red']};
    }

    const zero = calculateScale(0, min, max);

    return {colors: ['#37681c', 'white', 'red'], domain: [0, zero, 1]};
  }
);

export const selectEmissionsFactorColorScale = createSelector(
  [selectIsYearsDiff, selectEmissionsFactorColorScaleValues],
  (isDiff, {colors, domain}) => {
    // if (!isDiff) return {colors: ['#ffffff', '#37681c']};

    return {colors, domain};
  }
);

export const selectEmissionsFactorChromaScale = createSelector(
  selectEmissionsFactorColorScale,
  ({colors, domain}) => {
    const scale = chroma.scale(colors);
    if (domain) {
      scale.domain(domain);
    }

    return scale;
  }
);

export const selectEmissionsFactorColorById = createSelector(
  [
    selectEmissionsFactorStatsById,
    selectEmissionsFactorStatsExtrema,
    selectEmissionsFactorChromaScale,
    selectAreaUnits,
  ],
  (stats, {max, min}, colorScale, units) => {
    if (!stats) return;
    const value = stats.values?.[units];
    const scale = calculateScale(value, min, max);
    const color = colorScale(scale).css();

    return color;
  }
);

export const selectEmissionsFactorSummaryChartData = createSelector(
  [
    selectActiveEmissionsFactorStatsList,
    selectEmissionsFactorStateStats,
    selectEmissionsFactorCardRange,
    selectAreaUnits,
    selectStatesMeta,
    selectSIChartGroupingType,
  ],
  (stats, stateStats, range, units, statesMeta, groupingType): SocGhgSummaryChartData | null => {
    if (!stats?.length) return null;
    let totalValue = 0;
    let values: number[] = [];
    const valuesById: Record<number, number> = {};
    let chartData = stats;
    const chartGeometries: Record<string, boolean> = {};
    const chartGeometriesIds: number[] = [];

    chartData.forEach(stat => {
      const value = stat.values[units] || 0;
      const rawNumValue = stat.values?.rawNumValue || 0;
      stat.value = value;

      valuesById[stat.id] = value;

      values = values.concat(value);
      totalValue = totalValue + rawNumValue;
    });

    // Apply filters for chart data
    const preview = chartData.length > SUMMARY_BARS_PREVIEW_THRESHOLD;
    if (preview && range) {
      chartData = chartData.filter(d => isInRange(Number(d.value), range));
    }

    chartData.forEach(stat => {
      // add geometries after range filter
      chartGeometries[stat.id] = true;
      chartGeometriesIds.push(stat.id);
    });

    if (groupingType === 'grouped') {
      const groupedByState = groupBy(sortByKeyReverse(chartData, 'value'), 'statefp');
      chartData = sortByKeyReverseUnsafe(
        Object.keys(groupedByState).reduce((acc: GroupedSummaryChartData[], stateFP: string) => {
          const value = stateStats?.[Number(stateFP)]?.[units];
          if (!value) return acc;
          const name = statesMeta[parseAreaId(stateFP)]?.name?.toUpperCase?.();
          const data = groupedByState[stateFP];

          return [
            ...acc,
            {
              data,
              name,
              value,
            } as GroupedSummaryChartData,
          ];
        }, []),
        'value'
      );
    }

    chartData = sortByKeyReverse(chartData, 'value');

    return {
      type: groupingType,
      chartData,
      values,
      valuesById,
      preview,
      totalValue,
      chartGeometries,
      chartGeometriesIds,
    };
  }
);

export const selectEmissionsFactorGeometryValueById = createCachingSelector(
  [selectEmissionsFactorSummaryChartData, getAreaId],
  (summaryData, id) => summaryData?.valuesById?.[id]
);

export const isGeometryInEmissionsFactorSummaryChart = createSelector(
  [selectEmissionsFactorSummaryChartData, getAreaId],
  (data, id) => Boolean(data?.chartGeometries?.[id])
);
