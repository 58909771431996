import {createSelector} from '@reduxjs/toolkit';
import {featureCollection} from '@turf/turf';
import chroma from 'chroma-js';
import intersection from 'lodash/intersection';
import pick from 'lodash/pick';
import range from 'lodash/range';
import {selectErrors, selectIsLoading} from 'modules/helpers/selectors';
import type {AppStore} from 'reducers';
import type {Option} from 'types';
import {selectIsAdmin} from '../../../containers/login/login-selectors';
import {metricGroupsSet, METRIC_GROUPS, SI_AREA_AGG_AVAILABLE, SUPPLY_SHEDS} from '../constants';
import type {
  AreaId,
  CountyFIPS,
  SIAggLevel,
  SIDataAggState,
  SIFilterState,
  SIMetricColumn,
  SIMetricGroup,
  SIStateChildMeta,
  SIStateMeta,
  StateFP,
  SupplyShedId,
} from '../types';
import {ActionType, SIGeometryFilterType} from '../types';
import {getStateFP, isYearsDiff} from '../utils';
import {createCachingSelector} from '_utils/pure-utils';
import {isDefined, isNonEmptyArray} from '_utils/typeGuards';

export const getAreaId = (s: AppStore, id: AreaId) => id;

export const selectSIFilter = (s: AppStore) => s.sustainabilityInsights.filter;
export const selectSIData = (s: AppStore) => s.sustainabilityInsights.data;

export const selectAdopterTypeFilter = createCachingSelector(
  [
    selectSIFilter,
    (s: AppStore, adopterLevel: 'fieldAdopterType' | 'operationAdopterType') => adopterLevel,
  ],
  (f, adopterLevel) => f[adopterLevel]
);
export const selectActiveAdopterTypeFilter = createCachingSelector(
  [
    s => selectAdopterTypeFilter(s, 'fieldAdopterType'),
    s => selectAdopterTypeFilter(s, 'operationAdopterType'),
  ],
  (fieldAdopterType, operationAdopterType) => fieldAdopterType || operationAdopterType
);

export const selectIsDemographicsFilterSet = createCachingSelector(
  [
    selectSIFilter,
    (s: AppStore, adopterLevel: 'fieldAdopterType' | 'operationAdopterType') => adopterLevel,
  ],
  (f, adopterLevel) => {
    const secondFilterProp =
      adopterLevel === 'fieldAdopterType' ? 'operationStatus' : 'operationSize';

    return f[adopterLevel] || f[secondFilterProp];
  }
);
export const selectAggLevel = createSelector([selectSIFilter], f => f.aggLevel);

export const selectActionTypeError = createSelector(
  [selectErrors, (_: AppStore, actionType: string) => actionType],
  (errors, actionType) => {
    return errors[actionType];
  }
);

export const selectIsSIDataLoading = (s: AppStore) => {
  const isLoading = selectIsLoading(s, [
    ActionType.FETCH_METRICS,
    ActionType.FETCH_GEOMETRIES,
    ActionType.FETCH_AVAILABLE_AREA,
  ]);

  return isLoading;
};

export const selectCurrentAggLevelData = createSelector(
  [selectSIData, selectAggLevel],
  (data, aggLevel) => data[aggLevel]
);

export const selectCurrentAggGeometries = createSelector(
  [selectCurrentAggLevelData],
  data => data.geometries
);

export const selectGeometries = createSelector(
  [selectCurrentAggLevelData],
  data => data.geometries
);
export const selectCurrentMeta = createSelector([selectCurrentAggLevelData], data => data.meta);
export const selectMetrics = createSelector([selectCurrentAggLevelData], data => data.metrics);
export const selectSummary = createSelector([selectCurrentAggLevelData], data => data.summary);

export const selectStatesMeta = createSelector(
  [selectSIData],
  data => data.state.meta as Record<StateFP, SIStateMeta>
);
export const selectCRDMeta = createSelector([selectSIData], data => data.crd.meta);
export const selectCountiesMeta = createSelector([selectSIData], data => data.county.meta);
export const selectHUC8Meta = createSelector([selectSIData], data => data.huc8.meta);
export const selectHUC10Meta = createSelector([selectSIData], data => data.huc10.meta);
export const selectHUC12Meta = createSelector([selectSIData], data => data.huc12.meta);

export const selectPolicyData = createSelector(selectSIData, data => data.policy);

export const selectAvailableStatesIds = createSelector([selectPolicyData], policy => policy.state);
export const selectAvailableCRDIds = createSelector([selectPolicyData], policy => policy.crd);
export const selectAvailableCountiesIds = createSelector(
  [selectPolicyData],
  policy => policy.county
);
export const selectAvailableHuc8Ids = createSelector([selectPolicyData], policy => policy.huc8);
export const selectAvailableHuc10Ids = createSelector([selectPolicyData], policy => policy.huc10);
export const selectAvailableHuc12Ids = createSelector([selectPolicyData], policy => policy.huc12);

export const selectAvailableYears = createSelector(
  [selectPolicyData],
  policy => policy.yearsAvailable
);
export const selectLastAvailableYear = createSelector(
  [selectAvailableYears],
  years => years[years.length - 1]
);

export const selectAvailableAggLevels = createSelector([selectPolicyData], policy =>
  intersection(SI_AREA_AGG_AVAILABLE, policy.aggLevelsAvailable)
);

export const selectAvailableMetricGroups = createSelector(
  [selectPolicyData, selectIsAdmin],
  (policy, isAdmin) => (isAdmin ? METRIC_GROUPS : policy.metricGroupsAvailable)
);

export const selectAvailableMetrics = createSelector(
  [
    selectAvailableMetricGroups,
    selectActiveAdopterTypeFilter,
    s => selectAdopterTypeFilter(s, 'operationAdopterType'),
    s => selectAdopterTypeFilter(s, 'fieldAdopterType'),
  ],
  (metricGroups, adopterTypeFilter, operationsAdopterType, fieldsAdopterType) => {
    const metricGroupSets = pick(metricGroupsSet, metricGroups);

    if (metricGroupSets.grower_demographics && adopterTypeFilter) {
      // replace the regular cc_operations_nb with appropriate metric accordingly to the adopter type filter
      metricGroupSets.grower_demographics = metricGroupSets.grower_demographics.map(metric => {
        if (metric === 'cc_operations_nb' && adopterTypeFilter === 'new') {
          return 'cc_operations_new_nb';
        } else if (metric === 'cc_operations_nb' && adopterTypeFilter === 'consistent') {
          return 'cc_operations_consistent_nb';
        }
        return metric;
      });
    }
    if (metricGroupSets.grower_demographics && operationsAdopterType) {
      // replace the regular cc_operations_nb with appropriate metric accordingly to the adopter type filter
      metricGroupSets.grower_demographics.push('cc_operations_ac');
      if (operationsAdopterType === 'new') {
        metricGroupSets.grower_demographics.push('cc_operations_new_ac');
      } else if (operationsAdopterType === 'consistent') {
        metricGroupSets.grower_demographics.push('cc_operations_consistent_ac');
      }
    }

    if (metricGroupSets.grower_demographics && fieldsAdopterType) {
      if (fieldsAdopterType === 'new') {
        metricGroupSets.grower_demographics.push('cc_new_area_ac');
      } else if (fieldsAdopterType === 'consistent') {
        metricGroupSets.grower_demographics.push('cc_consistent_area_ac');
      }
    }
    return metricGroupSets;
  }
);

export const selectMetaById = createSelector(
  [selectCurrentMeta, getAreaId],
  (meta, id) => meta?.[id]
);
export const selectNameById = createSelector([selectMetaById], meta => meta?.name);

export const selectAreaUnits = createSelector([selectSIFilter], f => f.areaUnits);
export const selectAreaUnitsLabel = createSelector([selectSIFilter], f =>
  f.areaUnits === 'pct' ? '%' : f.metricsType === 'ac' ? 'ac' : 'ops'
);
export const selectGeometryTypeFilter = createSelector([selectSIFilter], f => f.activeGeometryType);
export const selectOperationSizeFilter = createSelector([selectSIFilter], f => f.operationSize);
export const selectOperationStatusFilter = createSelector([selectSIFilter], f => f.operationStatus);
export const selectVisibleGeometriesIds = createSelector(
  [selectSIFilter],
  f => f.visibleGeometriesIds
);
export const selectActiveGeometriesIds = createSelector(
  [selectSIFilter],
  f => f.activeGeometriesIds
);
export const selectSummerCropTypesFilter = createSelector([selectSIFilter], f => f.summerCropTypes);
export const selectMetricsTypeFilter = createSelector([selectSIFilter], f => f.metricsType);

export const selectCurrentAggAvailableIds = createSelector(
  [selectPolicyData, selectAggLevel],
  (policy, aggLevel): AreaId[] => policy[aggLevel] || []
);

export const selectAreEveryGeometrySelected = createSelector(
  [selectCurrentAggAvailableIds, selectActiveGeometriesIds],
  (availableIds, activeIds) => {
    const isEverythingSelected =
      isNonEmptyArray(availableIds) &&
      isNonEmptyArray(activeIds) &&
      availableIds.length === activeIds.length;

    return isEverythingSelected;
  }
);

export const selectIsEveryVisibleGeometrySelected = createSelector(
  [selectVisibleGeometriesIds, selectActiveGeometriesIds],
  (visibleIds, activeIds) => visibleIds?.length && visibleIds.length === activeIds.length
);

export const selectVisibleGeometriesIdsMap = createSelector([selectVisibleGeometriesIds], ids =>
  ids.reduce<Record<number | string, boolean>>(
    (acc, id) => ({
      ...acc,
      [getStateFP(id as CountyFIPS)]: true,
      [id]: true,
    }),
    {}
  )
);

export const selectActiveGeometriesIdsMap = createSelector([selectActiveGeometriesIds], ids =>
  ids.reduce<Record<number | string, boolean>>(
    (acc, id) => ({
      ...acc,
      [getStateFP(id as CountyFIPS)]: true,
      [id]: true,
    }),
    {}
  )
);

export const selectYearsFilter = createSelector([selectSIFilter], f => f.years);

export const selectYearsFilterRange = createSelector([selectYearsFilter], years =>
  isDefined(years) && years.length > 1 ? range(years[0], years[1]).concat(years[1]) : years
);

export const selectGeometriesColors = createSelector([selectVisibleGeometriesIds], ids => {
  return ids.reduce<Record<AreaId, string>>((acc, id) => {
    acc[id] = chroma.random().css();
    return acc;
  }, {});
});

export const selectSIGeometriesBounds = createSelector(
  [selectVisibleGeometriesIds, selectGeometries],
  (geometryIds, geometries) => {
    const geometriesList = geometryIds.map(id => geometries[id]).filter(Boolean);
    const collection = featureCollection(geometriesList);
    // @ts-expect-error featureCollection returns type that L.geoJSON doesn't expect, just misunderstanding between two libraries
    const bounds = L.geoJSON(collection).getBounds();

    return bounds;
  }
);

export const selectIsGeometryVisibleOnMap = createSelector(
  [selectVisibleGeometriesIdsMap, getAreaId],
  (visibleGeometries, id) => !!visibleGeometries[id]
);

export const selectIsGeometryActive = createSelector(
  [selectActiveGeometriesIdsMap, getAreaId],
  (activeGeometries, id) => !!activeGeometries[id]
);

export const selectMetricsById = createSelector(
  [selectMetrics, getAreaId],
  (metrics, id) => metrics[id]
);
export const selectSIGeometryById = createSelector(
  [selectGeometries, getAreaId],
  (data, id) => data?.[id]
);

export const selectIsYearsDiff = createSelector([selectYearsFilter], years =>
  isDefined(years) ? isYearsDiff(years) : false
);

export type SelectOption<V = string, L = string> = {
  value: V;
  label: L;
};

export const selectStatesOptions = createSelector(
  [selectAvailableStatesIds, selectStatesMeta],
  (statesIds, meta): Array<SelectOption<AreaId>> => {
    return statesIds.map(id => {
      const {name} = meta[id];

      return {value: id, label: name};
    });
  }
);

export const selectSupplySheds = () => SUPPLY_SHEDS;

export const selectSupplyShedsOptions = createSelector(
  [selectSupplySheds],
  (supplySheds): Array<SelectOption<AreaId>> => {
    return Object.keys(supplySheds).map(id => {
      return {value: Number(id), label: supplySheds[Number(id)]};
    });
  }
);

const classifyCountyOptions = (
  countiesIds: number[],
  countiesMeta: SIDataAggState['meta'],
  statesMeta: SIDataAggState['meta']
) => {
  const options = Object.values(
    countiesIds.reduce<
      Record<
        string,
        {
          value: number | string;
          label: string;
          options: {value: number | string; label: string}[];
        }
      >
    >((acc, countyId) => {
      const stateFP = getStateFP(countyId);

      if (!statesMeta[stateFP]) {
        return acc;
      }

      const countyMeta = countiesMeta[countyId];
      const stateMeta = statesMeta[stateFP];
      acc[stateFP] = acc[stateFP] || {value: stateFP, label: stateMeta.name, options: []};
      acc[stateFP].options = acc[stateFP].options.concat({
        value: countyId,
        label: countyMeta.name,
      });

      return acc;
    }, {})
  );

  return options;
};

export const selectCountiesOptions = createSelector(
  [selectAvailableCountiesIds, selectCountiesMeta, selectStatesMeta],
  classifyCountyOptions
);

export const selectHighlightGeometryId = createSelector(selectSIFilter, f => f.highlightGeometryId);
export const selectIsGeometryHighlighted = createSelector(
  [selectHighlightGeometryId, getAreaId],
  (highlightId, id) => id === highlightId
);

const prepareAreaOptions =
  (_aggLevel: SIAggLevel) =>
  (ids: number[], meta: SIDataAggState['meta'], statesMeta: SIDataAggState['meta']): Option[] => {
    if (!ids || !meta) return [];
    const options = Object.values(
      ids.reduce<Record<StateFP, Option>>((acc, id) => {
        const {name, statefp} = meta[id] as SIStateChildMeta;
        const stateMeta = statesMeta[statefp];

        if (!stateMeta) return acc;

        const option = {label: name, value: id, statefp};
        acc[statefp] = acc[statefp] ?? {value: statefp, label: stateMeta?.name, options: []};
        acc[statefp].options = (acc[statefp].options ?? []).concat(option);

        return acc;
      }, {})
    );

    return options;
  };

const prepareHucOptions =
  (aggLevel: SIAggLevel) =>
  (ids: number[], meta: SIDataAggState['meta'], statesMeta: SIDataAggState['meta']): Option[] => {
    if (!ids || !meta) return [];
    const options = Object.values(
      ids.reduce<Record<StateFP, Option>>((acc, id) => {
        const {statefp} = meta[id] as SIStateChildMeta;
        const stateMeta = statesMeta[statefp];
        // @ts-expect-error error leftover from convertion to strict mode, please fix
        if (!stateMeta?.[aggLevel]?.length) return acc;
        acc[statefp] = acc[statefp] || {value: statefp, label: stateMeta?.name};

        return acc;
      }, {})
    );

    return options;
  };

export const selectCRDOptions = createSelector(
  [selectAvailableCRDIds, selectCRDMeta, selectStatesMeta],
  prepareAreaOptions('crd')
);

export const selectHuc8Options = createSelector(
  [selectAvailableHuc8Ids, selectHUC8Meta, selectStatesMeta],
  prepareAreaOptions('huc8')
);

export const selectHuc10Options = createSelector(
  [selectAvailableHuc10Ids, selectHUC10Meta, selectStatesMeta],
  prepareHucOptions('huc10')
);

export const selectHuc12Options = createSelector(
  [selectAvailableHuc12Ids, selectHUC12Meta, selectStatesMeta],
  prepareHucOptions('huc12')
);

const GEOMETRY_TYPE_TO_METRICS_GROUP: Record<SIGeometryFilterType, SIMetricGroup[]> = {
  abatementPotential: ['carbon'],
  coverCrops: ['cover_crops', 'grower_demographics'],
  tillage: ['tillage_practices', 'grower_demographics'],
  ghg: ['ghg'],
  soc: ['soc_sequestration'],
  yield: ['crop_yield'],
  fertilizer: ['fertilizer'],
  ef: ['ef'],
};

export const selectStateIdsRequestParam = (s: AppStore) =>
  s.sustainabilityInsights.filter.stateIdsRequestParam;

export const selectSupplyShedsIdsRequestParam = createSelector(
  [selectSIFilter],
  filter => filter.supplyShedIdsRequestParam
);

export const selectAreasIdsRequestParam = createSelector(
  [selectAggLevel, selectStateIdsRequestParam, selectSupplyShedsIdsRequestParam],
  (aggLevel, statesQuery, supplyShedsQuery) =>
    aggLevel === 'supply_shed' ? supplyShedsQuery : statesQuery
);

export const selectActiveStatesMeta = createSelector(
  [selectStatesMeta, selectStateIdsRequestParam],
  (statesMeta, stateIds) => {
    const states: SIStateMeta[] = stateIds.map(id => statesMeta[id]);

    return states;
  }
);

export const selectCurrentGeometriesIdsRequestParam = createSelector(
  [selectCurrentAggGeometries, selectAggLevel, selectAreasIdsRequestParam, selectStatesMeta],
  (aggGeometries, aggLevel, activeAreasIds, statesMeta) => {
    if (!Object.values(aggGeometries).length) return activeAreasIds;

    if (aggLevel === 'state' || aggLevel === 'supply_shed') {
      const statesIdsWithoutGeometries = activeAreasIds
        .map(Number)
        .reduce<Array<StateFP | SupplyShedId>>((acc, id) => {
          if (aggGeometries[id]) return acc;
          return acc.concat(id);
        }, []);

      return statesIdsWithoutGeometries;
    }

    const statesIdsWithoutGeometries = activeAreasIds
      .map(Number)
      .reduce((acc: Array<number>, stateId) => {
        const childIds: AreaId[] = statesMeta[stateId][aggLevel] ?? [];
        if (childIds.some(id => !aggGeometries[id])) return acc.concat(stateId);
        return acc;
      }, []);

    return statesIdsWithoutGeometries;
  }
);

export const selectCurrentMetricsColumns = createSelector(
  [selectGeometryTypeFilter, selectAvailableMetrics],
  (activeGeometryType, availableMetrics) => {
    const metricsGroups = GEOMETRY_TYPE_TO_METRICS_GROUP[activeGeometryType];
    const metricsCols = metricsGroups.reduce((acc: Array<SIMetricColumn>, groupKey) => {
      const cols = availableMetrics[groupKey] ?? [];

      return [...acc, ...cols];
    }, []);

    return metricsCols;
  }
);

export const selectMetricsFilters = createSelector(
  [
    state => selectAdopterTypeFilter(state, 'fieldAdopterType'),
    state => selectAdopterTypeFilter(state, 'operationAdopterType'),
    selectOperationStatusFilter,
    selectOperationSizeFilter,
    selectSummerCropTypesFilter,
    selectGeometryTypeFilter,
  ],
  (
    fieldAdopterType,
    operationAdopterType,
    operationStatus,
    operationSize,
    summerCropTypes,
    activeCard
  ) => ({
    fieldAdopterType,
    operationAdopterType,
    operationStatus:
      activeCard === SIGeometryFilterType.CoverCrops // allow only for CC
        ? operationStatus
        : null,
    operationSize: activeCard === SIGeometryFilterType.CoverCrops ? operationSize : null,
    summerCropTypes,
  })
);

export const selectSIChartGroupingType = createSelector([selectAggLevel], aggLevel =>
  ['supply_shed', 'state'].includes(aggLevel) ? 'normal' : 'grouped'
);

export const selectSIFilterPropsToSave = createSelector(
  [
    selectGeometryTypeFilter,
    selectOperationSizeFilter,
    selectOperationStatusFilter,
    selectStateIdsRequestParam,
    selectYearsFilter,
    selectAggLevel,
    selectMetricsTypeFilter,
    selectAreaUnits,
    state => selectAdopterTypeFilter(state, 'fieldAdopterType'),
    state => selectAdopterTypeFilter(state, 'operationAdopterType'),
    selectSummerCropTypesFilter,
    selectVisibleGeometriesIds,
    selectActiveGeometriesIds,
  ],
  (
    activeGeometryType,
    operationSize,
    operationStatus,
    stateIdsRequestParam,
    years,
    aggLevel,
    metricsType,
    areaUnits,
    fieldAdopterType,
    operationAdopterType,
    summerCropTypes,
    visibleGeometriesIds,
    activeGeometriesIds
  ): Partial<SIFilterState> => {
    return {
      activeGeometryType,
      operationSize,
      operationStatus,
      stateIdsRequestParam,
      years,
      aggLevel,
      metricsType,
      areaUnits,
      fieldAdopterType,
      operationAdopterType,
      summerCropTypes,
      visibleGeometriesIds,
      activeGeometriesIds,
    };
  }
);
