// @ts-nocheck
import {t} from 'i18n-utils';
import * as React from 'react';
import cn from 'classnames';
import type {CSGViewModel} from './types';
import type {PeriodicData} from './helpers/periodic';
import {PeriodicHeader} from './helpers/periodic';
import './table-view-csg-headers.scss';
import {FluroTableColumn, FluroTableRow, SortButton, SortButtonContainer} from 'components';
import type {ColumnContentType} from 'components/fluro-table-components/fluro-table-components';

type Header<T> = {
  label: string;
  value: T;
  type?: ColumnContentType;
};

export const tableHeaders: Header<keyof CSGViewModel>[] = [
  {label: 'Field name', value: 'fieldName'},
  {label: 'Farm name', value: 'farmName'},
  {label: 'Crop type', value: 'cropType'},
  {label: 'Area', value: 'fieldArea', type: 'number'},
  {label: 'Last imagery', value: 'lastImageryDate', type: 'date'},
  {label: 'Crop status', value: 'cropStatus'},
  {label: 'Crop growth', value: 'growth'},
  {label: 'Biomass', value: 'smoothSatelliteNdvi', type: 'number'},
  {label: 'Cumulative Biomass', value: 'cumulativeSmoothSatelliteNdvi', type: 'number'},
];

export type TableHeadersProps = {
  periodic: PeriodicData;
  sortType: keyof CSGViewModel;
  sortOrder: boolean;
  showFarmName: boolean;
  onClick: (value: keyof CSGViewModel) => void;
  onChangePeriod: (options: {next?: boolean; prev?: boolean}) => void;
};

export const TableHeaders = ({
  periodic,
  sortType,
  sortOrder,
  showFarmName,
  onClick,
  onChangePeriod,
}: TableHeadersProps) => {
  const baseHeaders = showFarmName
    ? tableHeaders
    : tableHeaders.filter(h => h.value !== 'farmName');
  const headers = [
    ...baseHeaders,
    {
      label: <PeriodicHeader period={periodic?.period} onChangePeriod={onChangePeriod} />,
      value: 'periodic',
      type: 'date' as ColumnContentType, // just to center the label
    },
  ];
  return (
    <FluroTableRow className="headers">
      {headers.map(({label, value, type}) => (
        <TableHeader
          type={type}
          key={value}
          label={label}
          value={value}
          sortType={sortType}
          sortOrder={sortOrder}
          onClick={onClick}
        />
      ))}
    </FluroTableRow>
  );
};

export const TableHeader = <T extends string>({
  label,
  value,
  sortType,
  sortOrder,
  onClick,
  type,
}: {
  label: string | JSX.Element;
  value: T;
  sortType: T;
  sortOrder: boolean;
  onClick: (value: T) => void;
  type: ColumnContentType;
}) => {
  const active = sortType === value;
  return (
    <FluroTableColumn
      type={type}
      className={cn({
        'table-header': true,
        [value]: true,
        active: active,
      })}
      onClick={() => onClick(value)}
    >
      <SortButtonContainer>
        {typeof label === 'string' ? t({id: label}) : label}
        <SortButton selected={active} descending={active && !sortOrder} />
      </SortButtonContainer>
    </FluroTableColumn>
  );
};
