// @ts-nocheck
import type {ComponentType, HTMLAttributes} from 'react';
import React from 'react';
import cn from 'classnames';
import './text.scss';

export type Props = {
  elementType?: React.ElementType;
  primary?: boolean; // colors the text in $main-gray-600
  secondary?: boolean; // colors the text in $main-gray-600
  tertiary?: boolean; // colors the text in $main-gray-700
  error?: boolean; // colors the text in $error-shape-color
  link?: boolean; // colors the text in $color-text-link
  italic?: boolean;
  /**
   * sets font-weight: bold
   */
  bold?: boolean;
  inline?: boolean;
  nowrap?: boolean;
  variant?: 'h1' | 'h2' | 'h3' | 'medium' | 'small' | 'small-thin';
  inheritColor?: boolean;
  noMargin?: boolean;
  /**
   * Adds an mb-2.
   * @deprecated - needs to be refactored. Use noMargin instead.
   */
  marginBottom?: boolean;
  textCase?: 'default' | 'lower' | 'upper' | 'capitalize';
  fontWeight?: 300 | 400 | 500 | 900; // defined in src/styles/variables/typography.scss
  fullWidth?: boolean;
};

type ElementTypesToVariant = 'h1' | 'h2' | 'h3' | 'p' | 'small';

const elementTypesVariants = {
  h1: 'h1',
  h2: 'h2',
  h3: 'h3',
  p: 'medium',
  small: 'small',
};

const variantToElementType = {
  h1: 'h1', // 1.5 rem / 24px
  h2: 'h2', // 1.25 rem / 20px
  h3: 'h3', // 1 rem / 16px
  medium: 'p', // 0.875 rem / 14px
  small: 'small', // 0.75 rem / 12px
  'small-thin': 'small',
};

export const Text: ComponentType<Props & HTMLAttributes<HTMLElement>> = ({
  children,
  className,
  elementType,
  secondary = false,
  tertiary = false,
  inline = false,
  nowrap = false,
  variant,
  inheritColor = true,
  marginBottom = false,
  textCase = 'default',
  fontWeight,
  link,
  error,
  italic,
  fullWidth,
  primary,
  bold,
  noMargin,
  ...elementProps
}) => {
  const textVariant =
    variant || elementTypesVariants[elementType as ElementTypesToVariant] || 'medium';
  const TextElement = elementType || variantToElementType[variant] || 'p';

  return (
    <TextElement
      className={cn(className, 'text-element', {
        inline,
        italic,
        primary: primary && !link,
        secondary: secondary && !link,
        tertiary: tertiary && !link,
        error: error && !link,
        bold: bold,
        link: !secondary && link,
        'm-0': noMargin,
        'mb-2': marginBottom,
        [`font-weight-${fontWeight}`]: !!fontWeight,
        [`text-element--${textVariant}`]: textVariant,
        [`text-element--inherit-color`]: inheritColor,
        [`text-element--case-${textCase}`]: textCase !== 'default',
        'text-element--nowrap': nowrap,
        'text-element--wrap': !nowrap,
        'element-full-width': fullWidth,
      })}
      {...elementProps}
    >
      {children}
    </TextElement>
  );
};

Text.displayName = 'Text';
