// @ts-nocheck
import type {Field} from 'containers/map/types';
import type {AppStore} from 'reducers';
import {showNotification} from 'components/notification/notification';
import {t} from 'i18n-utils';
import type {AppDispatch} from 'store';
import {EnrollmentStep} from './enrollment/carbon-store';
import {CarbonPractice} from './enrollment/base/base';
import {selectHasFarmsOrGroupsIds} from '../login/login-selectors';
import moment from 'moment';
import {GLOBAL_FORMAT_DATE} from '../../_constants';
import {useHistory} from 'react-router-dom';
import {MRV_HOME} from './routes';
import {useParsedMatchParams} from '../../_hooks/use-parsed-match-params';
import {applyPathParams} from '_utils/pure-utils';
import {MRVPhasesDateFormat} from './constants';
import {useEffect, useMemo, useState} from 'react';
import {MRVApi} from '../../_api';
import {reportError} from '../error-boundary';
import {selectProjectFieldsList, selectProjectId} from './monitoring/module/selectors';
import Mixpanel from '_utils/mixpanel-utils';
import _config from '_environment';

export const toggleFieldsInsideFarm = (
  fields: {[fieldId: number]: Field},
  fieldInAllowedRegion: {[fieldId: number]: boolean},
  value: boolean
) => {
  const selection: {[fieldId: number]: boolean} = {};
  Object.values(fields).forEach(f => {
    if (fieldInAllowedRegion[f.ID]) {
      selection[f.ID] = value;
    }
  });
  return selection;
};

export const getFarmIdsThatHasFields = (
  fieldsByFarmId: AppStore['map']['fieldsByFarmId'],
  fields: {[fieldId: number]: boolean}
) => {
  return Object.keys(fieldsByFarmId)
    .map(Number)
    .filter(farmId => Object.values(fieldsByFarmId[farmId]).some(field => fields[field.ID]));
};

export const showNoTSelectableFieldWarning =
  (reason: 'Threshold' | 'Practice' | 'mixed' | '', singleField: boolean) => () => {
    let message = '';

    switch (reason) {
      case 'Practice':
        message = singleField
          ? t({
              id: 'You can not add this field to this practice because the selected practice is not considered as additional to the baseline.',
            })
          : t({
              id: 'You can not add more fields to this practice because the practice is not considered as additional to the baseline.',
            });
        break;

      case 'Threshold':
        message = singleField
          ? t({
              id: 'You can not add this field to this practice because it does not meet the minimum forecasted emission reductions criteria.',
            })
          : t({
              id: "You can not add more fields to this practice because they don't meet the minimum forecasted emission reductions criteria.",
            });
        break;

      case 'mixed':
        message = singleField
          ? t({
              id: 'You can not add this field to this practice because it does not meet the minimum forecasted emission reductions criteria and the practice is not considered as additional to the baseline.',
            })
          : t({
              id: 'You can not add more fields to this practice because it does not meet the minimum forecasted emission reductions criteria and the practice is not considered as additional to the baseline.',
            });
        break;
    }
    if (message) {
      showNotification({
        title: t({id: 'note.warning'}),
        message,
        type: 'warning',
      });
    }
  };

export const allowToChangeToTheStep =
  (stepToSet: EnrollmentStep, displayMessage: boolean = true, customSelectedFieldsList?: Field[]) =>
  (dispatch: AppDispatch, getState: () => AppStore) => {
    const state = getState();
    let allowStep = true;
    const hasFarms = selectHasFarmsOrGroupsIds(state);
    const projectFields = selectProjectFieldsList(state, selectProjectId(state));

    let message = '';

    switch (stepToSet) {
      case EnrollmentStep.CropPractices:
        allowStep = projectFields.length // allow unselect enrolled fields
          ? true
          : (customSelectedFieldsList && !!customSelectedFieldsList.length) ||
            Object.values(state.carbon.enrolledFields).some(Boolean); // if some field is selected === true

        message = allowStep
          ? message
          : hasFarms
          ? t({id: 'Select fields to enroll first'})
          : t({id: 'Import your fields first'}); // farmless user's message
        break;
    }

    if (!allowStep && displayMessage && message) {
      showNotification({
        title: t({id: 'note.warning', defaultMessage: 'Warning'}),
        message,
        type: 'warning',
      });
    }
    return allowStep;
  };

export const userFacingPracticeLabel = (practice: CarbonPractice) => {
  switch (practice) {
    case CarbonPractice.CoverCrops:
      return t({id: 'Conventional Till + Cover Crops'});
    default:
      return practice;
  }
};

export const formatLabelsForPhases = (startDate?: string, endDate?: string) => {
  if (!startDate && !endDate) return;

  const today = moment();
  const momentStartDate = moment(startDate, GLOBAL_FORMAT_DATE);
  const momentEndDate = moment(endDate, GLOBAL_FORMAT_DATE);

  const formattedStartDate = momentStartDate.format(MRVPhasesDateFormat);
  const formattedEndDate = momentEndDate.format(MRVPhasesDateFormat);

  if (today.isBefore(momentStartDate)) {
    return t({id: 'Open {date}', defaultMessage: 'Open {date}'}, {date: formattedStartDate}); // closed yet, but will open on X date - "Open {date}"
  } else if (isPhaseActive(startDate, endDate)) {
    return `${formattedStartDate} - ${formattedEndDate}`; // active phase - "Start date - End Date"
  } else {
    return t({id: 'Closed: View only'}); // closed
  }
};

export const isPhaseActive = (startDate?: string, endDate?: string) => {
  const today = moment();

  if (!startDate || !endDate) return false;

  // today is same or after start_date and NOT after end_date
  return (
    today.isSameOrAfter(moment(startDate, GLOBAL_FORMAT_DATE), 'day') &&
    !today.isAfter(moment(endDate, GLOBAL_FORMAT_DATE), 'day')
  );
};

export const useHistoryToRedirectToHome = () => {
  const history = useHistory();
  const {projectId} = useParsedMatchParams();

  return () => history.push(applyPathParams(MRV_HOME, {projectId}));
};

export const syncProcessStartedInfoText = (platformName: string) =>
  t(
    {
      id: '{platformName} import started. It can take up to 5 minutes, depending on the number of fields being imported. Feel free to go and make a cup of coffee ☕. We’ll let you know when the import is done.',
    },
    {platformName}
  );

export const mergeUserInfoFromOldProjects = (
  existingProjects: AppStore['monitoring']['entities']['projects'],
  updatedProjects: AppStore['monitoring']['entities']['projects']
) => {
  // "simple function" to merge existing users_info data with newly fetched
  // it handles a case when we are searching producers in the admin page,
  // the search endpoint returns user_info: null. merge the value for the prop we previously got from the fetchWholeProject()
  Object.keys(updatedProjects || {}).forEach(projectId => {
    updatedProjects[projectId].permissions.forEach((permissionsObject, index) => {
      const existingPermissionObject = existingProjects?.[projectId]?.permissions?.[index];
      const existingUserInfo =
        existingPermissionObject?.id === permissionsObject.id
          ? existingPermissionObject.user_info
          : null;
      permissionsObject.user_info = permissionsObject.user_info || existingUserInfo;
    });
  });
};

export const useRegionsList = (country: string) => {
  const [regions, setRegions] = useState<Record<string, {value: string; label: string}[]>>({});

  const countriesToClassify: Record<string, string> = useMemo(() => {
    return {
      'United States': 'USA',
    };
  }, []);

  useEffect(() => {
    if (!regions[country] && country) {
      MRVApi.getRegions(countriesToClassify[country] || country)
        .then(({data}) => {
          const regionNames = data.map(({name: stateName}) => ({
            value: stateName,
            label: stateName,
          }));
          setRegions({...regions, [country]: regionNames});
        })
        .catch(err => reportError(`useRegionsList err = ${err}`));
    }
  }, [country]);

  return regions[country] || [];
};

export const useCountriesList = () => {
  const [countries, setCountries] = useState<{value: string; label: string}[]>([]);
  // const dispatch = useAppDispatch();
  // const countries = useAppSelector(state => state.pickers.countries);

  useEffect(() => {
    fetch(`/assets/countries.json`).then(data => {
      data.json().then(countries => {
        setCountries(countries.map((country: string) => ({value: country, label: country})));
      });
    });
  }, []);

  return countries;
};

export async function viewTheContract(projectId: number) {
  try {
    const response = await MRVApi.getProjectContract(projectId);
    const blob = new Blob([response.data], {type: 'application/pdf'});
    const url = window.URL.createObjectURL(blob);
    window.open(url, '_blank');

    Mixpanel.track('View contract');
  } catch (error) {
    reportError(error);
  }
}
