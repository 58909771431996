import {reportError} from 'containers/error-boundary';
import {setDNDCPercentCompleted, setOFSProgress} from 'containers/mrv/enrollment/carbon-store';
import {useMonitoringUrlParams} from 'containers/mrv/monitoring/hooks';
import {useCallback, useEffect} from 'react';
import {mutate} from 'swr';
import {MRVApi} from '_api';
import {useAppDispatch, useAppSelector} from '_hooks';
import {useRecursivePoll} from '_hooks/use-recursive-poll';
import {unique} from '_utils/pure-utils';
import {getMRVValuesBaseRequestKey} from './data/base';
import {
  updateFieldDNDCStatus,
  updateFieldOFSStatus,
  updateProjectDNDCStatus,
  updateProjectOFSStatus,
} from './monitoring/module/reducer';
import {
  selectCompletion,
  selectCurrentProject,
  selectCurrentProjectFieldsAwaitingOptis,
  selectCurrentProjectFieldsList,
  selectEnabledPhaseStages,
  selectProjectValues,
} from './monitoring/module/selectors';
import {fetchStageCompletion} from './monitoring/module/thunks';
import type {MRVNotification, MRVPhaseType} from './types';
import {DNDCStatus, MRVNotificationCode, OFSStatus, ProjectValueSource} from './types';

//TODO replace with a selector
function useOFSProgress() {
  const dispatch = useAppDispatch();
  const fields = useAppSelector(selectCurrentProjectFieldsList);
  const values = useAppSelector(selectProjectValues);

  return useEffect(() => {
    const valueArray = Object.values(values);
    const hasUserValues = valueArray.some(v => v.value && v.source === ProjectValueSource.user);
    const hasOptisValues = valueArray.some(v => v.value && v.source === ProjectValueSource.optis);
    const totalFields = fields?.length || 0;
    const processedFields = fields?.filter(
      field => field.ofs_status === OFSStatus.Success || field.ofs_status === OFSStatus.Failed
    );
    const processedFieldsCount = processedFields?.length || 0;
    const percentCompleted = totalFields
      ? Math.round((processedFieldsCount * 100) / totalFields)
      : 0;

    dispatch(setOFSProgress({hasUserValues, hasOptisValues, percentCompleted}));
  }, [fields, values, dispatch]);
}

//TODO replace with a selector
function useDNDCProgress() {
  const dispatch = useAppDispatch();
  const fields = useAppSelector(selectCurrentProjectFieldsList);

  useEffect(() => {
    const totalFields = fields?.length || 0;
    const totalCompletedFields =
      fields?.filter(
        field => field.dndc_status === DNDCStatus.Success || field.dndc_status === DNDCStatus.Failed
      ).length || 0;

    const percentCompleted = totalFields
      ? Math.round((totalCompletedFields * 100) / totalFields)
      : 0;

    dispatch(setDNDCPercentCompleted(percentCompleted));
  }, [dispatch, fields]);
}

/**
 * ## Todo
 * Replace recursive polling functionality with web sockets.
 *
 * ## Description
 * This hook recursively polls the notifications endpoint and fetches the new values whenever possible.
 * It also updates the **DNDC* and **Optis* status of the project and it's fields.
 *
 * When the `values` endpoint gets hit, `notifications` are automatically marked as read and not returned,
 *
 * The `values` get updated automatically on the backend, when we fetch `notifications`
 * we can see the new values that have appeared.
 *
 * ### Optis notifications
 *
 * Guessed practices for crop history stage of enrollment.
 *
 * ### DNDC notifications
 *
 * Estimated GHG reductions and Payment outcomes for enrollment.
 */
export function useNotifications() {
  const dispatch = useAppDispatch();
  useDNDCProgress();
  useOFSProgress();
  const project = useAppSelector(selectCurrentProject);
  const optisUnprocessedFields = useAppSelector(selectCurrentProjectFieldsAwaitingOptis);

  const {ofs_status, dndc_status, id: projectId} = project || {};

  const getNotifications = useCallback(async () => {
    let notifications: MRVNotification[] = [];
    const optisUnprocessedFieldsRest = new Set(optisUnprocessedFields);

    try {
      const response = await MRVApi.getNotifications(projectId);
      notifications = response.data;
    } catch (error) {
      if (ofs_status === OFSStatus.InProgress) {
        dispatch(updateProjectOFSStatus({projectId, status: OFSStatus.Failed}));
      }

      if (dndc_status === DNDCStatus.InProgress) {
        dispatch(updateProjectDNDCStatus({projectId, status: DNDCStatus.Failed}));
      }

      reportError(error);
    }

    for (const n of notifications) {
      if (ofs_status === OFSStatus.InProgress && n.source === 'optis') {
        if (n.entity === 'Fields' && n.body.code === MRVNotificationCode.AllDone) {
          // The success status for the field will be updated later down the function
          // when the values for the fields will be fetched.
          optisUnprocessedFieldsRest.delete(n.entity_id);
        }

        if (n.entity === 'Projects') {
          switch (n.body.code) {
            case MRVNotificationCode.AllDone:
              // If the client didn't receive all the notifications (i.e. close the browser),
              // in case they'll go straight to field upload next time and enrol a new field,
              // the client gonna first receive the old project complete notification.
              // We don't wanna be tricked here, so we'll wait until all the fields are ready.
              if (optisUnprocessedFieldsRest.size === 0) {
                dispatch(updateProjectOFSStatus({projectId, status: OFSStatus.Success}));
              }
              break;

            case MRVNotificationCode.Error:
              dispatch(updateProjectOFSStatus({projectId, status: OFSStatus.Failed}));
              break;
          }
        }
      }

      if (dndc_status === DNDCStatus.InProgress && n.source === 'dndc') {
        if (n.entity === 'Fields') {
          switch (n.body.code) {
            case MRVNotificationCode.AllDone:
              dispatch(updateFieldDNDCStatus({fieldId: n.entity_id, status: DNDCStatus.Success}));
              break;

            case MRVNotificationCode.Error:
              dispatch(updateFieldDNDCStatus({fieldId: n.entity_id, status: DNDCStatus.Failed}));
              break;
          }
        }

        if (n.entity === 'Projects') {
          switch (n.body.code) {
            case MRVNotificationCode.AllDone:
              dispatch(updateProjectDNDCStatus({projectId, status: DNDCStatus.Success}));
              break;

            case MRVNotificationCode.Error:
              dispatch(updateProjectDNDCStatus({projectId, status: DNDCStatus.Failed}));
              break;
          }
        }
      }
    }

    if (!notifications.length) return;

    try {
      const fieldIds = unique(
        notifications
          .filter(n => n.source === 'optis' && n.entity === 'Fields')
          .map(n => n.entity_id)
      );
      if (fieldIds.length) {
        // Revalidate all the values for all the stages.
        mutate(
          key => typeof key === 'string' && key.startsWith(getMRVValuesBaseRequestKey(projectId))
        );

        // Update fields statuses after the new optis values got fetched.
        fieldIds.forEach(mrvFieldId => {
          dispatch(updateFieldOFSStatus({mrvFieldId, status: OFSStatus.Success}));
        });
      }

      await MRVApi.deleteNotifications(
        projectId,
        notifications.map(n => n.id)
      );
    } catch (error) {
      reportError(error);
    }
  }, [dndc_status, ofs_status, projectId, optisUnprocessedFields, dispatch]);

  const inProgress =
    ofs_status === OFSStatus.InProgress ||
    dndc_status === DNDCStatus.InProgress ||
    optisUnprocessedFields.length > 0;
  const shouldRun = !!projectId && inProgress;

  useRecursivePoll(getNotifications, 5000, shouldRun);
}

export function useFetchStageCompletion(mrvPhaseType: MRVPhaseType) {
  const dispatch = useAppDispatch();
  const {projectId} = useMonitoringUrlParams();
  const stages = useAppSelector(s => selectEnabledPhaseStages(s, mrvPhaseType));
  const fields = useAppSelector(selectCurrentProjectFieldsList);
  const completion = useAppSelector(selectCompletion);

  useEffect(() => {
    stages.forEach(({id: stageId}) => {
      if (!completion[stageId]) {
        dispatch(fetchStageCompletion({projectId, stageId}));
      }
    });
  }, [dispatch, projectId, stages, fields]); // when the fields are updated, the completion should be invalidated
}
