import {Flex} from 'components';
import {Span} from 'components/text/span';
import {setFilter} from 'modules/sustainability-insights/actions';
import {METRIC_TYPE_LABELS} from 'modules/sustainability-insights/constants';
import {
  selectIsDemographicsFilterSet,
  selectMetricsTypeFilter,
} from 'modules/sustainability-insights/selectors';
import type {ComponentType} from 'react';
import React from 'react';
import {SelectionControlGroup} from 'react-md';
import {useAppDispatch, useAppSelector} from '_hooks';

type Props = {};

export const MetricsTypeSwitch: ComponentType<Props> = () => {
  const dispatch = useAppDispatch();
  const value = useAppSelector(selectMetricsTypeFilter);
  const fieldsFilterSet = useAppSelector(s => selectIsDemographicsFilterSet(s, 'fieldAdopterType'));

  const handleMetricsTypeSelect = (value: 'ac' | 'op') => {
    dispatch(setFilter({metricsType: value}));
  };

  return (
    <Flex alignItems="center">
      <Span secondary>for</Span>
      <SelectionControlGroup
        id="metrics-type-select"
        name="radio"
        type="radio"
        className="si-toggle-group si-toggle-group--inline select-metrics-type"
        inline
        value={value}
        onChange={(v: string) => handleMetricsTypeSelect(v as 'ac' | 'op')}
        disabled={Boolean(fieldsFilterSet)}
        controls={[
          {
            label: METRIC_TYPE_LABELS.ac,
            value: 'ac',
          },
          {
            label: METRIC_TYPE_LABELS.op,
            value: 'op',
          },
        ]}
      />
    </Flex>
  );
};
