import {
  Flex,
  FluroButton,
  FluroDataTable,
  FluroDialog,
  FluroInput,
  FluroTableBody,
  FluroTableColumn,
  FluroTableRowLite,
  GeometryPreview,
  MultiKeysPressed,
  Text,
} from 'components';
import cn from 'classnames';
import colors from 'variables.scss';
import {FluroCheckbox} from 'components/fluro-checkbox/fluro-checkbox';
import {FluroSelectLite} from 'components/fluro-select-lite/fluro-select-lite';
import {FieldSystemProp} from 'containers/map/features/farm/new-fields/types';
import {selectFieldGeometries, selectHighlightedFieldId} from 'containers/map/reducer/selectors';
import {
  selectCurrentProjectFieldMapKmlId2MrvId,
  selectStageFieldValuesMap,
} from 'containers/mrv/monitoring/module/selectors';
import {toggleFieldsInsideFarm} from 'containers/mrv/utils';
import {t} from 'i18n-utils';
import {bulkUpdateCLUFieldBoundariesProp, updateCLUFieldBoundariesProp} from 'modules/add-fields';
import type {CLUFieldBoundaryProps} from 'modules/add-fields/types';
import {selectFarmsList} from 'modules/farms/selectors';
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {useDispatch} from 'react-redux';
import {useAppSelector} from '_hooks';
import {convertUnit, getFarmById, sortFieldsByProp} from '_utils';
import {enrollFields} from '../../carbon-store';
import {selectEnrollmentReadOnly} from '../../carbon-store/selectors';
import {Controller, useForm} from 'react-hook-form';
import * as Yup from 'yup';
import {yupResolver} from '@hookform/resolvers/yup';
import {
  AddNewFarmBtn,
  AddNewFarmInputComponent,
} from 'containers/map/features/farm/new-fields/field-properties-parser/field-properties-parser.styled';
import {farmStringSchema} from 'containers/farm/edit';
import {FontIcon, TextField} from 'react-md';
import {saveFarm} from 'modules/farms/actions';
import type {Field} from 'containers/map/types';
import {selectMeasurement} from 'containers/login/login-selectors';
import styled from 'styled-components';
import {GEOMETRY_THUMB_SIZE, handleFieldFeature} from '../utils';
import {EligibleMessage} from '../../base/messages';
import {showNotification} from 'components/notification/notification';
import {useParsedMatchParams} from '_hooks/use-parsed-match-params';
import {bulkUpdateFieldsFarm} from 'containers/mrv/monitoring/module/thunks';
import {loadFarmsFieldsWithGeometries, saveBulkFieldData} from 'containers/map/actions';
import type {BulkUpdateFieldData} from 'containers/mrv/types';

const DEMO_FARM_ID = 136;

const farmNameSchema = Yup.object().shape({
  farmName: farmStringSchema,
});

export const FarmFieldsEditDialog = ({
  stageId,
  currentFarmId,
  fieldsByFarmId,
  onClose,
}: {
  stageId: number;
  currentFarmId: number;
  fieldsByFarmId: {
    [farmId: number]: {
      [kmlId: number]: Field;
    };
  };
  onClose: () => void;
}) => {
  const dispatch = useDispatch();
  const carbon = useAppSelector(s => s.carbon);
  const {isReadOnly} = useAppSelector(selectEnrollmentReadOnly);
  const kmlIdToMrvId = useAppSelector(selectCurrentProjectFieldMapKmlId2MrvId);
  const historicPracticesPerField = useAppSelector(s => selectStageFieldValuesMap(s, stageId));
  const {projectId} = useParsedMatchParams();
  const measurement = useAppSelector(selectMeasurement);
  const fieldGeometries = useAppSelector(selectFieldGeometries);
  const highlightedFieldId = useAppSelector(selectHighlightedFieldId);

  const farmIsSelected = (farmId: number) => {
    const kmlIds = Object.keys(fieldsByFarmId[farmId]).map(Number);
    return kmlIds.length > 0 && kmlIds.every(kmlId => carbon.enrolledFields[kmlId]);
  };

  const isFarmSelected = farmIsSelected(currentFarmId);
  const fields = Object.values(fieldsByFarmId[currentFarmId]);
  const selectedFields = fields.filter(f => carbon.enrolledFields[f.ID]);
  const selectedFieldIds = selectedFields.map(field => field.ID.toString());

  const farms = useAppSelector(selectFarmsList).filter(farm => farm.id !== DEMO_FARM_ID);
  const menuItems = useMemo(
    () => farms.filter(f => !f.readOnly).map(f => ({label: f.name, value: f.id})),
    [farms]
  );

  const [preparedFieldFarms, setPreparedFieldFarms] = useState<Record<string, number | string>>({});
  const [addNewFarm, toggleAddNewFarm] = useState(false);
  const [sortedFields, setSortedFields] = useState<Field[]>([]);

  useEffect(() => {
    const newFields = sortFieldsByProp(fields, 'Name', 'string');
    setSortedFields(newFields);
  }, []);

  const {
    formState: {errors},
    control,
    reset,
    watch,
  } = useForm<{
    farmName: string;
  }>({
    mode: 'onChange',
    resolver: yupResolver(farmNameSchema),
    defaultValues: {farmName: ''},
  });

  const values = watch();

  /**
   * @ Funcs start: select field(s) checkbox
   */

  const toggleFarm = (farmId: number, forceValue?: boolean) => {
    const value = forceValue !== undefined ? forceValue : !farmIsSelected(farmId);
    const selection = toggleFieldsInsideFarm(
      fieldsByFarmId[farmId],
      carbon.eligibleRegionFields,
      value
    );
    if (value) {
      dispatch(enrollFields(selection));
      return;
    }

    const hasHistoricPractices = Object.keys(selection)
      .map(Number)
      .some(kmlId => {
        const mrvFieldId = kmlIdToMrvId[kmlId];
        return historicPracticesPerField[mrvFieldId]?.find(v => v.value && v.source === 'user');
      });
    if (!hasHistoricPractices) {
      dispatch(enrollFields(selection));
      return;
    }

    const userAgreesWithHistoricPracticesLoss = confirm(
      t({
        id: 'If you decide to enroll this farm again, you will need to re-enter its cropping practices.',
      })
    );
    if (!userAgreesWithHistoricPracticesLoss) return;

    dispatch(enrollFields(selection));
  };

  const onSelectField = useCallback(
    (id: string, value: boolean) => {
      dispatch(updateCLUFieldBoundariesProp(id, FieldSystemProp.Checked, value));
    },
    [dispatch]
  );

  /**
   * @ Funcs start: Update the farm for fields
   */
  const onBulkUpdateFieldsProp = useCallback(
    (ids: string[], value: any, prop: FieldSystemProp) => {
      const classifiedFieldIds = ids;
      dispatch(
        bulkUpdateCLUFieldBoundariesProp(
          classifiedFieldIds,
          prop as keyof CLUFieldBoundaryProps,
          value
        )
      );
    },
    [dispatch]
  );

  const onBulkSelectFields = useCallback(
    (value: boolean, kmlIds: string[]) => {
      onBulkUpdateFieldsProp(kmlIds, value, FieldSystemProp.Checked);
    },
    [onBulkUpdateFieldsProp]
  );

  const onSelectFarm = useCallback(
    async (farmId: number, kmlId: string) => {
      const newlyPreparedFieldFarms = {...preparedFieldFarms};
      if (kmlId) {
        newlyPreparedFieldFarms[kmlId] = farmId;
        onSelectField(kmlId, false); // unselect field we assigned a arm to
      } else {
        const ids = selectedFieldIds.map(id => (newlyPreparedFieldFarms[id] = farmId).toString());
        onBulkSelectFields(false, ids); // unselect fields we assigned a farm to
      }
      setPreparedFieldFarms(newlyPreparedFieldFarms);
    },
    [onBulkSelectFields, onSelectField, preparedFieldFarms, selectedFieldIds]
  );

  /**
   * @ Funcs start: create new Farm and assign to selected fields
   */
  const createNewFarm = useCallback(
    async (farmName: string) => {
      const newFarm = await dispatch(saveFarm({id: 0, name: farmName}));
      // @ts-expect-error error leftover from convertion to strict mode, please fix
      return newFarm?.id;
    },
    [dispatch]
  );

  const onSubmitNewFarmName = useCallback(
    async (kmlId?: string) => {
      const trimmedFarmName = values.farmName.trim();
      if (!(await farmNameSchema.isValid({farmName: trimmedFarmName}))) {
        // simulate disabled state
        return;
      }

      const existingFarmWithSameName = menuItems.find(
        f => f.label.toLowerCase() === trimmedFarmName.toLowerCase()
      );

      let farmId = existingFarmWithSameName?.value;

      if (!farmId) {
        farmId = await createNewFarm(trimmedFarmName);
      }

      if (farmId && kmlId) {
        await onSelectFarm(farmId, kmlId);
      }

      toggleAddNewFarm(false);
      reset();
    },
    [createNewFarm, menuItems, onSelectFarm, reset, values.farmName]
  );

  const confirmFieldsUpdate = async () => {
    // Update the fields which assign to other farms
    // const groupedFieldIdsByFarmId: Record<number, number[]> = {};
    const groupedFieldIdsByFarmId: Record<number, BulkUpdateFieldData[]> = {};
    Object.entries(preparedFieldFarms).forEach(item => {
      const kmlId = Number(item[0]);
      const field = sortedFields.find(f => f.ID == kmlId);
      if (field) {
        const mrvFieldId = kmlIdToMrvId[kmlId];
        const farmId = Number(item[1]);
        if (groupedFieldIdsByFarmId[farmId]) {
          groupedFieldIdsByFarmId[farmId].push({
            fs_field_id: field.FieldID,
            mrv_field_id: mrvFieldId,
          });
        } else {
          groupedFieldIdsByFarmId[farmId] = [
            {fs_field_id: field.FieldID, mrv_field_id: mrvFieldId},
          ];
        }
      }
    });

    Object.entries(groupedFieldIdsByFarmId).forEach(async item => {
      await bulkUpdateFieldsFarm({projectId, target_farm_id: Number(item[0]), field_data: item[1]});
    });

    // Update the fields name (it has to be run after farm relocation, otherwise the new farm id cannot be found for the name)
    fields.forEach(f => {
      sortedFields.forEach(async sortedField => {
        if (f.ID === sortedField.ID && f.Name !== sortedField.Name) {
          await dispatch(
            saveBulkFieldData(
              'Name',
              sortedField.Name,
              Number(preparedFieldFarms[sortedField.ID]) || currentFarmId,
              [sortedField.ID]
            )
          );
        }
      });
    });

    // // reload data store
    const farm_ids = farms.map(f => f.id);
    await dispatch(loadFarmsFieldsWithGeometries(farm_ids));

    // Close the dialog
    onClose();
  };

  const fixedToTopItems = useCallback(
    (kmlId?: string) => {
      const currentFarm = getFarmById(farms, currentFarmId);
      const items: {
        value: any;
        label: React.ReactElement | string;
        customSelectFunction?: () => void;
      }[] = [];

      if (addNewFarm) {
        items.push({
          customSelectFunction: () => null, // just neutralize the click
          value: 'new-farm',
          label: (
            <AddNewFarmInputComponent>
              <Controller
                name="farmName"
                control={control}
                render={({field: {value, onChange}}) => (
                  <FluroInput
                    topMargin={false}
                    onChange={v => onChange(v)}
                    value={value}
                    errorText={errors?.farmName?.message}
                    error={!!errors.farmName}
                  />
                )}
              />
              <FluroButton
                className={'add-btn'}
                raised
                primary
                onClick={() => {
                  onSubmitNewFarmName(kmlId);
                }}
              >
                {t({id: 'Add'})}
              </FluroButton>
              <MultiKeysPressed callback={() => onSubmitNewFarmName(kmlId)} keys={['Enter']} />
            </AddNewFarmInputComponent>
          ),
        });
      } else {
        items.push({
          customSelectFunction: () => toggleAddNewFarm(true),
          value: 'new-farm',
          label: (
            <AddNewFarmBtn>
              <FontIcon>add</FontIcon>
              {t({id: 'Add farm'})}
            </AddNewFarmBtn>
          ),
        });
      }

      if (currentFarm && menuItems.length > 1) {
        items.push({
          value: currentFarm.id,
          label: t({id: 'Upload to current farm: {farmName}'}, {farmName: currentFarm.name}),
        });
      }

      return items;
    },
    [
      addNewFarm,
      control,
      currentFarmId,
      errors.farmName,
      farms,
      menuItems.length,
      onSubmitNewFarmName,
    ]
  );

  const showIneligibleNote = useCallback(
    () =>
      showNotification({
        title: 'Ineligible location.',
        message: <EligibleMessage />,
        type: 'warning',
      }),
    []
  );

  const setFieldName = (kmlId: number, value: string) => {
    const newSortedFields = sortedFields.map(field => {
      if (field.ID === kmlId) {
        return {
          ...field,
          Name: value,
        };
      }

      return field;
    });

    setSortedFields(newSortedFields);
  };

  return (
    <FluroDialog
      title={t({id: 'Edit fields'})}
      onHide={onClose}
      visible={true}
      id={'edit-farms-fields'}
      width={600}
      style={{overflow: 'hidden'}}
    >
      <Text elementType={'span'} variant={'medium'}>
        {t({
          id: 'Below you can edit the fields in this farm. You can rename the fields or assign them to a new farm.',
        })}
      </Text>

      {sortedFields.length > 1 ? (
        <Flex
          className={'margin-top-10 pl-5 pb-2'}
          alignItems={'center'}
          justifyContent={'space-between'}
        >
          <FluroCheckbox
            value={isFarmSelected}
            halfChecked={selectedFields.length > 0}
            disabled={isReadOnly}
            label={
              <div className={'subtext'}>
                {t(
                  {id: '{count1} / {count2} fields selected'},
                  {count1: selectedFields.length, count2: fields.length}
                )}
              </div>
            }
            onChange={() =>
              toggleFarm(currentFarmId, isFarmSelected ? false : !selectedFields.length)
            }
          />

          {selectedFields.length ? (
            <FluroSelectLite
              items={menuItems}
              isSearchable
              fixedToTopItems={fixedToTopItems()}
              selectedValue={null}
              // @ts-expect-error error leftover from convertion to strict mode, please fix
              onSelect={onSelectFarm}
              Button={({onClick}) => (
                <FluroButton onClick={onClick} blank grayBorder raised>
                  {t({id: 'BtnLabel.AssignToFarm'})}
                </FluroButton>
              )}
            />
          ) : null}
        </Flex>
      ) : null}

      <StyledTable>
        <FluroTableBody>
          {sortedFields.map(field => {
            const mrvFieldId = kmlIdToMrvId[field.ID];
            const geometry = fieldGeometries[field.MD5];
            const editable = !field.Seasons?.length && !field.external_service;
            const highlighted = highlightedFieldId === field.ID;
            const enrolled = carbon.enrolledFields[field.ID];
            const ineligible = field.FieldID ? carbon.ineligibleRegionFields[field.FieldID] : false;
            const checkboxIsDisabled = isReadOnly || !carbon.eligibleRegionFields[field.ID];
            const historicPractices = historicPracticesPerField[mrvFieldId];
            const hasHistoricPractices = historicPractices?.some(
              v => v.value && v.source === 'user'
            );
            const fieldArea = `${convertUnit(measurement, 'ac', field.Area)} ${t({
              id: measurement,
            })}`;
            const fieldPreparedFarm = preparedFieldFarms[field.ID] || currentFarmId;

            const toggleField = (kmlId: number, forceValue?: boolean) => {
              const value = forceValue !== undefined ? forceValue : !enrolled;
              if (value || !hasHistoricPractices) {
                dispatch(enrollFields({[kmlId]: value}));
                return;
              }

              dispatch(enrollFields({[kmlId]: value}));
            };

            return (
              <FluroTableRowLite className="pl-2 mt-1 mb-1" key={field.FieldID}>
                <FluroTableColumn className={cn({highlighted})}>
                  <FluroCheckbox
                    value={enrolled}
                    disabled={checkboxIsDisabled}
                    onChange={() => {
                      if (!checkboxIsDisabled) {
                        // simulate onChange here because onChange doesn't work because of onClick action of the parent div (weird issue)
                        toggleField(field.ID);
                      }

                      if (!isReadOnly && ineligible) {
                        showIneligibleNote();
                      }
                    }}
                  />
                </FluroTableColumn>

                {geometry && (
                  <FluroTableColumn className="field-geometry-thumb">
                    <GeometryPreview
                      data={geometry}
                      size={GEOMETRY_THUMB_SIZE}
                      onEachFeature={handleFieldFeature}
                    />
                  </FluroTableColumn>
                )}

                <FluroTableColumn>
                  <TextField
                    disabled={!editable}
                    id={`edit-field-name-${field.ID}`}
                    lineDirection="center"
                    value={field.Name}
                    onChange={value => setFieldName(field.ID, value as string)}
                  />
                </FluroTableColumn>

                <FluroTableColumn>
                  <Text className="mb-0" secondary>
                    {fieldArea}
                  </Text>
                </FluroTableColumn>

                <FluroTableColumn>
                  <FluroSelectLite
                    disabled={!editable}
                    placeholder={
                      fieldPreparedFarm ? String(fieldPreparedFarm) : t({id: 'Select farm'})
                    }
                    items={menuItems}
                    isSearchable
                    fixedToTopItems={fixedToTopItems(field.ID.toString())}
                    selectedValue={fieldPreparedFarm}
                    onSelect={async (farmId: number) => {
                      await onSelectFarm(farmId, field.ID.toString());
                    }}
                    className={cn('field-row-farm-selector', {
                      unselected: !fieldPreparedFarm,
                    })}
                  />
                </FluroTableColumn>
              </FluroTableRowLite>
            );
          })}
        </FluroTableBody>
      </StyledTable>

      <Flex className={'margin-top-15'} justifyContent={'flex-end'} gap={'10px'}>
        <FluroButton raised blank onClick={onClose}>
          {t({id: 'BtnLabel.Cancel', defaultMessage: 'Cancel'})}
        </FluroButton>
        <FluroButton primary raised onClick={confirmFieldsUpdate}>
          {t({id: 'BtnLabel.Done', defaultMessage: 'Done'})}
        </FluroButton>
      </Flex>
    </FluroDialog>
  );
};

const StyledTable = styled(FluroDataTable)`
  max-height: 270px;
  box-shadow: none !important;
  border: 1px solid ${colors['main-gray-300']};
  border-radius: 4px;
  width: 100%;
`;
