import {CardHr} from 'components/card/card-styled';
import type {ComponentType} from 'react';
import React, {useCallback, useMemo, useState} from 'react';
import {FontIcon} from 'react-md';
import type {BarChartProps} from './bar-chart';
import {BarChart} from './bar-chart';

type GroupedData = {data: BarChartProps['data']; name: string; value: number};

type GroupProps = GroupedData & BarChartProps & {defaultExpand?: boolean};

const SummaryChartGroup: ComponentType<GroupProps> = ({
  name,
  data,
  value,
  defaultExpand = false,
  ...barChartProps
}) => {
  const [expand, setExpand] = useState(defaultExpand);
  const headerData = useMemo(() => [{id: name, value}], [value, name]);
  const headerName = useCallback(() => name, [name]);

  return (
    <div className="summary-chart-group">
      <div className="summary-chart-group__heading">
        <span
          className="summary-chart-group__expand"
          role="button"
          onClick={() => {
            setExpand(e => !e);
          }}
        >
          <FontIcon>{expand ? 'expand_less' : 'expand_more'}</FontIcon>
        </span>
        <BarChart collapse data={headerData} {...barChartProps} nameLabel={headerName} />
      </div>
      {expand && (
        <div className="mb-1">
          <BarChart collapse data={data} {...barChartProps} />
          <CardHr />
        </div>
      )}
    </div>
  );
};

type Props = {
  data: GroupedData[];
  defaultExpand?: boolean;
} & BarChartProps;

export const SummaryChartGrouped: ComponentType<Props> = ({data: groups, ...barChartProps}) => {
  return (
    <>
      {groups.map(({name, data, value}) => {
        return (
          <SummaryChartGroup
            key={name}
            defaultExpand={groups.length === 1}
            name={name}
            data={data}
            value={value}
            {...barChartProps}
          />
        );
      })}
    </>
  );
};
