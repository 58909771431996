import React, {useState} from 'react';
import type {ComponentType} from 'react';
import {FontIcon, ListItem, MenuButton} from 'react-md';
import {Link} from 'react-router-dom';
import {Flex, FluroButton, FluroChip} from 'components';
import {Tooltip} from 'components/tooltip/tooltip';
import {useAppSelector} from '_hooks';
import {classifyAreaWithUnits, getRandomColor} from '_utils';
import {applyPathParams, calcPlural} from '_utils/pure-utils';
import {SI_SUPPLY_SHED_ADD} from 'containers/si/routes';
import {selectIsAdmin, selectMeasurement} from 'containers/login/login-selectors';
import {selectCropTypesCDLIdMap} from 'modules/global/selectors';
import type {SISupplyShed} from 'containers/si/types';
import {SISupplyShedNamePopup} from '../supply-shed-dialogs/supply-shed-name-popup';
import {RecomputePopup} from 'containers/si/supply-shed/components/recompute-popup';
import {LoadingIcon} from 'containers/mrv/icons';
import {ErrorIcon} from 'containers/map/icons';
import {SIHeaderColor} from 'containers/si/si-styled-components';
import '../supply-shed.scss';

type Props = {
  supplyShed: SISupplyShed;
  deleteArea: (areaType: 'shed' | 'region', supplyShedId: number, subsectionId?: number) => void;
  updateName: (
    areaType: 'shed' | 'region',
    name: string,
    supplyShedId: number,
    subsectionId: number | null
  ) => void;
};

export const SupplyShedCard: ComponentType<Props> = ({
  supplyShed,
  deleteArea,
  updateName,
}: Props) => {
  const isAdmin = useAppSelector(selectIsAdmin);
  const cropsById = useAppSelector(selectCropTypesCDLIdMap);
  const measurement = useAppSelector(selectMeasurement);
  const [supplyShedVisible, setSupplyShedVisible] = useState(false);
  const [areaType, setAreaType] = useState<'shed' | 'region' | null>(null);
  const [renameSubsectionId, setRenameSubsectionId] = useState<number | null>(null);
  const [newName, setNewName] = useState('');
  const [reprocessPopup, setReprocessPopup] = useState(false);
  const color = supplyShed.color || getRandomColor();

  const renamePopUpConfig = {
    shed: {
      key: 'shed',
      title: 'Rename Supply Shed',
      name: supplyShed.name,
    },
    region: {
      key: 'region',
      title: 'Rename Subregion',
      name: newName,
    },
  } as {[key: string]: {key: string; title: string; name: string}};

  const menuItems = [
    <ListItem
      key={1}
      onClick={e => {
        e.stopPropagation();
        setAreaType('shed');
      }}
      leftIcon={<FontIcon>edit</FontIcon>}
      primaryText="Rename Supply Shed"
    />,
    <ListItem
      key={2}
      leftIcon={<FontIcon>delete</FontIcon>}
      onClick={() => deleteArea('shed', supplyShed.id)}
      primaryText={'Delete Supply Shed'}
    />,
  ];

  if (isAdmin) {
    menuItems.push(
      <ListItem
        key={3}
        leftIcon={<FontIcon>update</FontIcon>}
        onClick={() => setReprocessPopup(true)}
        primaryText={'Recompute'}
      />
    );
  }

  return (
    <div className="supply-shed-card margin-bottom-16">
      <div
        className="supply-shed-header"
        onClick={() => {
          setSupplyShedVisible(!supplyShedVisible);
        }}
      >
        <SIHeaderColor headerColor={color} />
        <Flex direction="row" alignItems="center">
          <FontIcon forceSize={35} forceFontSize>
            {supplyShedVisible ? 'expand_more' : 'chevron_right'}
          </FontIcon>
          <div className="supply-shed-name">{supplyShed.name}</div>
        </Flex>
        <Flex direction="row" alignItems="center">
          <div className="supply-shed-area">
            {classifyAreaWithUnits(
              Math.round(Number(supplyShed?.total_field_area_ha)),
              measurement
            )}
          </div>
          <MenuButton
            id="edit-supply-shed-settings"
            icon
            onClick={ev => {
              ev.stopPropagation();
            }}
            menuItems={menuItems}
          >
            <FontIcon inherit>more_vert</FontIcon>
          </MenuButton>
        </Flex>
      </div>
      {supplyShedVisible && (
        <div className="supply-shed-subsections">
          {supplyShed?.subsections?.map(subsection => (
            <Flex
              className="subsection"
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              nowrap
              key={subsection.id}
            >
              <Flex direction="row" alignItems="center" nowrap>
                <div>
                  <div className="subsection-name">{subsection.name}</div>
                  <Flex direction="row" alignItems="center" nowrap>
                    {subsection?.commodities && subsection?.commodities?.length > 0 && (
                      <Tooltip
                        id="crop-info"
                        place="left"
                        content={subsection.commodities
                          .map(c => cropsById[Number(c.id)]?.label)
                          .join(', ')}
                      >
                        <FluroChip
                          key={'item'}
                          label={`${subsection.commodities.length} ${calcPlural(
                            'crop',
                            subsection.commodities.length
                          )}`}
                          tone={'dimmed'}
                          size={'small'}
                          className="ml-0 crop-tooltip"
                          disabled
                        />
                      </Tooltip>
                    )}
                    {subsection?.status === 'PROCESSING' && (
                      <FluroChip
                        key={'processing'}
                        label={'Region Processing'}
                        tone={'dimmed'}
                        size={'small'}
                        className="ml-8"
                        leftIcon={<LoadingIcon />}
                        disabled
                      />
                    )}
                    {subsection?.status === 'FAILED' && (
                      <FluroChip
                        key={'failed'}
                        label={'Processing Failed'}
                        tone={'dimmed'}
                        size={'small'}
                        className="ml-8"
                        leftIcon={<ErrorIcon />}
                        disabled
                      />
                    )}
                  </Flex>
                </div>
              </Flex>
              <Flex direction="row" alignItems="center" nowrap>
                <div className="subsection-area">
                  {classifyAreaWithUnits(
                    Math.round(Number(subsection?.field_area_ha)),
                    measurement
                  )}
                </div>
                <MenuButton
                  id="edit-subsection-settings"
                  icon
                  menuItems={[
                    <ListItem
                      key={1}
                      onClick={() => {
                        setNewName(subsection.name);
                        setAreaType('region');
                        setRenameSubsectionId(subsection.id);
                      }}
                      leftIcon={<FontIcon>edit</FontIcon>}
                      primaryText="Rename Subregion"
                    />,
                    <ListItem
                      key={2}
                      leftIcon={<FontIcon>delete</FontIcon>}
                      primaryText={'Delete Subregion'}
                      onClick={() => deleteArea('region', supplyShed.id, subsection.id)}
                    />,
                  ]}
                >
                  <FontIcon inherit>more_vert</FontIcon>
                </MenuButton>
              </Flex>
            </Flex>
          ))}
        </div>
      )}
      <FluroButton
        raised
        blank
        className="add-subregion-button"
        component={Link}
        to={{
          pathname: applyPathParams(SI_SUPPLY_SHED_ADD, {
            programId: supplyShed.program_id,
            supplyShedId: supplyShed.id,
          }),
        }}
      >
        + Sub-Region
      </FluroButton>
      {areaType && (
        <SISupplyShedNamePopup
          title={renamePopUpConfig[areaType].title}
          name={renamePopUpConfig[areaType].name}
          onHide={() => setAreaType(null)}
          onSave={savedName => {
            updateName(areaType, savedName, supplyShed.id, renameSubsectionId);
            setAreaType(null);
            setRenameSubsectionId(null);
          }}
        />
      )}
      {reprocessPopup && (
        <RecomputePopup
          programId={Number(supplyShed?.program_id)}
          onHide={() => setReprocessPopup(false)}
        />
      )}
    </div>
  );
};
