// @ts-nocheck
import {createSelector} from '@reduxjs/toolkit';
import {EnrollmentStep} from './types';
import {YearQuarter} from '../base/crop-practice';
import {selectAllFieldsList} from 'containers/map/reducer/selectors';
import type {AppStore} from 'reducers';
import {
  selectCurrentProject,
  selectMRVAttributes,
  selectPhase,
} from 'containers/mrv/monitoring/module/selectors';
import moment from 'moment';
import {getToday} from '_utils/pure-utils';
import type {MRVAttribute, MRVProjectNormalized, MRVPhaseNormalized} from 'containers/mrv/types';
import {
  DNDCStatus,
  MRVAttributeType,
  MRVEnrollmentContractType,
  PhaseReadOnlyReason,
  MRVPhaseType,
  MRVStageType,
} from 'containers/mrv/types';
import {
  selectEnrollment,
  selectHistoricPracticesStage,
} from 'containers/mrv/monitoring/module/enrollment.selectors';
import {getCropItems} from 'containers/mrv/base';
import {t} from 'i18n-utils';
import {
  selectCropLabelPropMRV,
  selectIsImpersonatorNotSuperAdmin,
} from 'containers/login/login-selectors';
import type {CropType} from 'modules/global/types';

export const selectEnrollmentStep = (s: AppStore) => s.carbon.step;
export const selectCarbon = (s: AppStore) => s.carbon;

export const selectCarbonYear = createSelector([selectCarbon], carbon => carbon.year);

export const selectCarbonQuarter = createSelector([selectCarbon], carbon => carbon.quarter);

/**
 * Get summer crops and winter crops based on the attribute options.
 */
export const selectMrvCrops = createSelector(
  [selectHistoricPracticesStage, selectMRVAttributes, selectCropLabelPropMRV],
  (historicPracticesStage, attrs, labelProp) => {
    let summerCropAttr: MRVAttribute;
    let winterCropAttr: MRVAttribute;
    historicPracticesStage?.attributes.forEach(a => {
      if (attrs[a]?.type === MRVAttributeType.SummerCropType) {
        summerCropAttr = attrs[a];
      }
      if (attrs[a]?.type === MRVAttributeType.WinterCropType) {
        winterCropAttr = attrs[a];
      }
    });
    return {
      // Not sure how to universally type the Options, so gonna cast the crop type options here.
      summerCropItems: getCropItems(summerCropAttr?.options as CropType[], labelProp),
      winterCropItems: getCropItems(winterCropAttr?.options as CropType[], labelProp),
    };
  }
);

/**
 * Enrolled fields are the fields selected on the Fields & Boundaries stage of Enrollment.
 * On any other screen use the project's field list.
 */
export const selectEnrolledFields = (s: AppStore) => s.carbon.enrolledFields;

export const selectImportedFields = (s: AppStore) => s.carbon.importedFields;

export const selectContractIsGenerated = createSelector([selectCurrentProject], project => {
  return Boolean(project?.contracts?.length);
});

export const selectUsingContractTemplate = createSelector([selectEnrollment], enrollment => {
  return Boolean(enrollment?.contract_type === MRVEnrollmentContractType.ContractTemplate);
});

export const selectEnrolmentContractIsSigned = createSelector(
  // TODO - eventually we must move to the commented out code below.
  // When producerAgreement is treated as an actual stage.
  //
  // [
  //   selectCurrentProject,
  //   (s: AppStore) => selectPhaseStageByType(s, MRVPhaseType.Enrollment, MRVStageType.Contract),
  // ],
  // (project, producerAgreementStage) => {
  //   return Boolean(
  //     producerAgreementStage?.enabled &&
  //       project?.contracts?.length &&
  //       project.contracts[0].docusign_status === 'completed' &&
  //       project.contract_link
  //   );
  // }
  [selectCurrentProject, (s: AppStore) => selectPhase(s, MRVPhaseType.Enrollment)],
  (project, phase) => {
    return Boolean(
      phase?.show_contract &&
        project?.contracts?.length &&
        project.contracts[0].docusign_status === 'completed' &&
        project.contract_link
    );
  }
);

export const selectContractExists = createSelector(
  [selectUsingContractTemplate, selectContractIsGenerated, selectEnrolmentContractIsSigned],
  (usingContractTemplate, contractGenerated, contractSigned) => {
    return usingContractTemplate ? contractGenerated : contractSigned;
  }
);

export const selectContractDeletionRequested = createSelector([selectCurrentProject], project => {
  return !!project?.contracts?.find(contract => !!contract.deletion_reason);
});

export const selectDNDC = createSelector(
  [selectCurrentProject, selectCarbon],
  (project, carbon) => {
    return {
      percentCompleted: carbon?.dndcPercentCompleted,
      status: project?.dndc_status,
      payment: carbon?.dndcResults.payment,
      tonsSequestered: carbon?.dndcResults.tonsSequestered,
      detailedItems: carbon?.dndcResults.detailedItems,
    };
  }
);

export const selectOFS = createSelector([selectCurrentProject, selectCarbon], (project, carbon) => {
  const {hasUserValues, hasOptisValues, percentCompleted} = carbon?.ofsProgress;

  return {
    hasUserValues,
    hasOptisValues,
    percentCompleted,
    status: project?.ofs_status,
  };
});

export const selectEnrollmentReadOnly = createSelector(
  [
    selectIsImpersonatorNotSuperAdmin,
    selectEnrolmentContractIsSigned,
    selectCurrentProject,
    selectEnrollment,
    getToday,
    selectContractIsGenerated,
    selectDNDC,
  ],
  // Scenario Planning Tool contract is signed OR MRV contract is signed.
  (
    isImpersonatorNotSuperAdmin,
    contractIsSigned,
    project,
    enrollment,
    date,
    contractGenerated,
    dndc
  ): {isReadOnly: boolean; reason: PhaseReadOnlyReason; detailedReason: string} => {
    return enrollmentReadOnlyCheck(
      isImpersonatorNotSuperAdmin,
      contractIsSigned,
      enrollment,
      date,
      contractGenerated,
      dndc,
      project
    );
  }
);

export const enrollmentReadOnlyCheck = (
  isImpersonatorNotSuperAdmin: boolean | undefined,
  contractIsSigned: boolean,
  enrollment: MRVPhaseNormalized | undefined,
  date: string,
  contractGenerated: boolean,
  dndc: any,
  project?: MRVProjectNormalized
): {isReadOnly: boolean; reason: PhaseReadOnlyReason; detailedReason: string} => {
  const customContractType =
    enrollment?.contract_type === MRVEnrollmentContractType.ContractTemplate;
  const isTooEarly = enrollment && moment(date).isBefore(moment(enrollment.start_date));
  const isTooLate = enrollment && moment(date).isAfter(moment(enrollment.end_date));
  const isOutsideEnrollmentDates = isTooEarly || isTooLate;
  const calculatingDNDC = dndc.status === DNDCStatus.InProgress;
  const isReadOnly =
    isImpersonatorNotSuperAdmin ||
    contractIsSigned ||
    isOutsideEnrollmentDates ||
    (customContractType && contractGenerated) ||
    calculatingDNDC;
  let reason = null;
  let detailedReason = '';

  const alreadyEnrolledInProgram = t(
    {
      id: 'PhaseReadOnlyReason.EnrolledInProgram',
      defaultMessage: 'you already enrolled in {program}',
    },
    {program: project?.program_name}
  );

  switch (true) {
    case isImpersonatorNotSuperAdmin:
      reason = PhaseReadOnlyReason.IsImpersonatorNotSuperAdmin;
      detailedReason = t({
        id: 'PhaseReadOnlyReason.IsImpersonatorNotSuperAdmin',
        defaultMessage: `you're logged in as another user`,
      });
      break;

    case contractIsSigned:
      reason = PhaseReadOnlyReason.ContractIsSigned;
      detailedReason = alreadyEnrolledInProgram;
      break;

    case customContractType && contractGenerated:
      reason = PhaseReadOnlyReason.ContractIsSigned;
      detailedReason = alreadyEnrolledInProgram;
      break;

    case isTooEarly:
      reason = PhaseReadOnlyReason.TooEarly;
      detailedReason = t(
        {
          id: 'PhaseReadOnlyReason.EnrollmentStartsOnDate',
          defaultMessage: 'enrollment starts on {date}',
        },
        {date: moment(enrollment?.start_date).format('MMMM D, YYYY')}
      );
      break;

    case isTooLate:
      reason = PhaseReadOnlyReason.TooLate;
      detailedReason = t(
        {
          id: 'PhaseReadOnlyReason.EnrollmentHasEndedOnDate',
          defaultMessage: 'enrollment has ended on {date}',
        },
        {date: moment(enrollment?.end_date).format('MMMM D, YYYY')}
      );
      break;

    case calculatingDNDC:
      reason = PhaseReadOnlyReason.CalculatingDNDC;
      detailedReason = t({
        id: 'PhaseReadOnlyReason.OutcomesAreBeingCalculated',
        defaultMessage:
          'outcomes are being calculated. You can continue editing your fields once this process is done',
      });
      break;

    default:
      reason = PhaseReadOnlyReason.Unknown;
      detailedReason = '';
  }
  return {
    isReadOnly,
    reason,
    detailedReason,
  };
};

export const selectEnrolledFieldsList = createSelector([selectEnrolledFields], enrolledFields =>
  Object.keys(enrolledFields)
    .map(Number)
    .filter(fieldId => enrolledFields[fieldId])
);

export const selectEnrolledFieldObjects = createSelector(
  [selectAllFieldsList, selectEnrolledFields],
  (fields, enrolledFields) => fields.filter(f => enrolledFields[f.ID])
);

export const selectImportedFieldObjects = createSelector(
  [selectAllFieldsList, selectImportedFields],
  (fields, importedFields) => fields.filter(f => importedFields[f.ID])
);

export const quarterToAttribute = {
  [YearQuarter.FallTillage]: MRVAttributeType.FallTillagePractice,
  [YearQuarter.SummerCrop]: MRVAttributeType.SummerCropType,
  [YearQuarter.SpringTillage]: MRVAttributeType.SpringTillagePractice,
  [YearQuarter.WinterCrop]: MRVAttributeType.WinterCropType,
};

export const EnrollmentStepNameAliases = {
  [EnrollmentStep.Fields]: () =>
    t({id: 'EnrollmentStep.Fields & Boundaries', defaultMessage: 'Fields & Boundaries'}),
  [EnrollmentStep.CropPractices]: () =>
    t({id: 'EnrollmentStep.Crop history', defaultMessage: 'Crop history'}),
  [EnrollmentStep.AssignPractices]: () =>
    t({id: 'EnrollmentStep.Assign Practices', defaultMessage: 'Assign Practices'}),
  [EnrollmentStep.ViewOutcomes]: () =>
    t({id: 'EnrollmentStep.View Outcomes', defaultMessage: 'View Outcomes'}),
  [EnrollmentStep.Survey]: () => t({id: 'EnrollmentStep.Survey', defaultMessage: 'Survey'}),
};

export const stageTypeNames: Record<MRVStageType, () => string> = {
  [MRVStageType.FieldBoundaries]: () =>
    t({id: `MRVStageType.${MRVStageType.FieldBoundaries}`, defaultMessage: 'Fields & Boundaries'}),
  [MRVStageType.ConfirmHistory]: () =>
    t({id: `MRVStageType.${MRVStageType.ConfirmHistory}`, defaultMessage: 'Crop history'}),
  [MRVStageType.AssignPractices]: () =>
    t({id: `MRVStageType.${MRVStageType.AssignPractices}`, defaultMessage: 'Assign Practices'}),
  [MRVStageType.ViewOutcomes]: () =>
    t({id: `MRVStageType.${MRVStageType.ViewOutcomes}`, defaultMessage: 'View Outcomes'}),
  [MRVStageType.HistoricalCropRotation]: () =>
    t({
      id: `EnrollmentStep.${MRVStageType.HistoricalCropRotation}`,
      defaultMessage: 'Crop history',
    }),
  [MRVStageType.HistoricalTillage]: () =>
    t({id: `MRVStageType.${MRVStageType.HistoricalTillage}`, defaultMessage: 'Tillage History'}),
  [MRVStageType.IntendedCommodityCrops]: () =>
    t({
      id: `MRVStageType.${MRVStageType.IntendedCommodityCrops}`,
      defaultMessage: 'Intended Commodity Crops',
    }),
  [MRVStageType.Survey]: () =>
    t({id: `MRVStageType.${MRVStageType.Survey}`, defaultMessage: 'Survey'}),
  [MRVStageType.SummerCrops]: () =>
    t({id: `MRVStageType.${MRVStageType.SummerCrops}`, defaultMessage: 'Summer Crops'}),
  [MRVStageType.WinterCrops]: () =>
    t({id: `MRVStageType.${MRVStageType.WinterCrops}`, defaultMessage: 'Winter Crops'}),
  [MRVStageType.Tillage]: () =>
    t({id: `MRVStageType.${MRVStageType.Tillage}`, defaultMessage: 'Tillage'}),
  [MRVStageType.NutrientManagement]: () =>
    t({
      id: `MRVStageType.${MRVStageType.NutrientManagement}`,
      defaultMessage: 'Nutrient Management',
    }),
  [MRVStageType.NutrientManagementIntended]: () =>
    t({
      id: `MRVStageType.${MRVStageType.NutrientManagementIntended}`,
      defaultMessage: 'Intended Nutrient Management',
    }),
  [MRVStageType.Contract]: () =>
    t({id: `MRVStageType.${MRVStageType.Contract}`, defaultMessage: 'Contract'}),
  [MRVStageType.Eligibility]: () =>
    t({id: `MRVStageType.${MRVStageType.Eligibility}`, defaultMessage: 'Eligibility'}),
  [MRVStageType.FieldInformation]: () =>
    t({id: `MRVStageType.${MRVStageType.FieldInformation}`, defaultMessage: 'Field Information'}),
  [MRVStageType.Irrigation]: () =>
    t({id: `MRVStageType.${MRVStageType.Irrigation}`, defaultMessage: 'Irrigation'}),
  [MRVStageType.RiceCropHistory]: () =>
    t({id: `MRVStageType.${MRVStageType.RiceCropHistory}`, defaultMessage: 'Crop History'}),
  [MRVStageType.MobHistory]: () =>
    t({id: `MRVStageType.${MRVStageType.MobHistory}`, defaultMessage: 'Mob History'}),
  [MRVStageType.MobMovement]: () =>
    t({id: `MRVStageType.${MRVStageType.MobMovement}`, defaultMessage: 'Mob Movement'}),
  [MRVStageType.FarmLevelManagement]: () =>
    t({
      id: `MRVStageType.${MRVStageType.FarmLevelManagement}`,
      defaultMessage: 'Farm Level Management',
    }),
};

export const selectAssignPracticesSelectedFieldIds = (s: AppStore) =>
  s.carbon.assignPracticesSelectedFields;
