// @ts-nocheck
import React, {Fragment, useCallback, useMemo, useState} from 'react';
import {SelectionControl} from 'react-md';
import moment from 'moment';

import {
  CropInfo,
  FluroDataTable,
  FluroDatePicker,
  FluroTableBody,
  FluroTableColumn,
  FluroTableHeader,
  FluroTablePagination,
  FluroTableRow,
  Ln,
  TableSubtext,
} from 'components';

import FilterControl from '../filter-control';
import {OneCrop} from '../shared';
import {genKey} from '_utils/pure-utils';
import {GLOBAL_FORMAT_DATE} from '_constants';
import type {FField, FTag, SeasonWithFeatures} from '../types';
import {FeatureEntity, Tags} from '../types';
import {switchBulkFeature, switchFeature, updateFeatureValue} from '../actions';
import {applyAdvancedFilters, columns} from '../filter-utils';

import {calcRowsPerPageItems} from 'containers/admin/index';
import {TABS} from './premium-apps';
import {getCropDate} from '../../../map/features/farm/field/season-list';
import cn from 'classnames';
import {showNotification} from 'components/notification/notification';
import {t} from 'i18n-utils';
import {useAppDispatch, useAppSelector} from '_hooks';
import {selectCropTypes} from 'modules/global/selectors';

type FItem = {value: string; selected: boolean};

type Props = {
  fields: FField[];
  tagKeys: Tags[];
  handlePagination: (start: number, perPage: number, page: number) => void;
  pagination: {start: number; perPage: number; page: number};
  seasonTagValue: string;
};

const Table = ({seasonTagValue, pagination, fields, handlePagination}: Props) => {
  const dispatch = useAppDispatch();
  const cropTypes = useAppSelector(selectCropTypes);

  const currentTabObj = useMemo(
    () => TABS.find(tag => tag.value === seasonTagValue) || TABS[0],
    [seasonTagValue]
  );

  const [filterData, onSetFilterData] = useState<any>({data: []});
  /*
   * prepare clop columns
   * */
  const cropColumn = useMemo(() => {
    const col = columns.find(el => el.value === 'cropType');

    const filterData: any = {
      selectedAll: col.isSelectedAllFilter,
      data: {},
    };

    fields.forEach(
      (field: any) => {
        let labels: any[] = [];
        let key: string = '';

        col.filterKeys.forEach(fk => {
          key += field[fk.prop] + ';;';
          labels.push(
            <Fragment key={genKey()}>
              {fk.onSet ? fk.onSet(cropTypes, field[fk.prop]) : field[fk.prop]}
            </Fragment>
          );
        });

        filterData.data[key] = {
          label: labels,
          value: key,
          selected: !!col.isSelectedAllFilter,
        };
      },
      [filterData]
    );

    filterData.data = Object.values(filterData.data);

    onSetFilterData((f: any) => {
      return {
        ...filterData,
        selectedAll: f.selectedAll !== undefined ? f.selectedAll : filterData.selectedAll,
        data: filterData.data.map((item: FItem) => {
          const oldItem = f.data.find((oldItem: FItem) => {
            return item.value === oldItem.value;
          });

          if (oldItem) {
            return {...item, selected: oldItem.selected};
          }

          return item;
        }),
      };
    });

    return col;
  }, [fields]);

  /*
   * onFilterChange
   * */
  const onFilterChange = useCallback(
    (column: string, filterKey: string, value: boolean) => {
      const filter = {
        ...filterData,
        selectedAll:
          filterKey === '__selectedAll'
            ? value
            : value &&
              filterData.data
                .filter((el: any) => el.value !== filterKey)
                .every((el: any) => el.selected),
        data: filterData.data.map((el: any) => {
          if (el.value === filterKey || filterKey === '__selectedAll') {
            el.selected = value;
          }

          return {...el};
        }),
      };

      onSetFilterData(filter);
    },
    [filterData]
  );

  /*
   * onClearFilter
   * */
  const onClearFilter = useCallback(
    (column: string) => {
      const col = columns.find((c: any) => c.value === column);

      if (col) {
        onSetFilterData({
          ...filterData,
          selectedAll: !!col.isSelectedAllFilter,
          data: filterData.data.map((el: any) => {
            return {...el, selected: !!col.isSelectedAllFilter};
          }),
        });
      }
    },
    [filterData]
  );

  /*
   * filteredFields
   * */
  const filteredFields = useMemo(
    () => applyAdvancedFilters(columns, {cropType: filterData}, fields),
    [filterData, fields, pagination]
  );

  const slicedList: FField[] = useMemo(
    () => filteredFields.slice(pagination.start, pagination.start + pagination.perPage),
    [filteredFields, pagination]
  );

  const getRetroDemoDefaultValue = useCallback((season: SeasonWithFeatures) => {
    const seasonStartDate = moment(season.startDate, GLOBAL_FORMAT_DATE);
    const seasonEndDate = moment(season.endDate, GLOBAL_FORMAT_DATE);
    const nrxActivationDate = moment();

    return JSON.stringify({
      retro_demo: season.id ? !nrxActivationDate.isBetween(seasonStartDate, seasonEndDate) : false,
      startDate: seasonStartDate.format(),
      endDate: seasonStartDate.add(4, 'months').format(), // start date + 4 month
    });
  }, []);

  /*
   *
   * Actions
   *
   * */
  const updateFeatureValueCb = useCallback(
    (entityId: number, entityType: FeatureEntity, key: Tags, id: number, value: any) => {
      return dispatch(updateFeatureValue(entityId, entityType, key, id, value));
    },
    []
  );

  const switchFeatureCb = useCallback(
    (
      value: boolean | string,
      key: Tags,
      entityId: number,
      entityType: FeatureEntity,
      tag: FTag
    ) => {
      dispatch(switchFeature(value, key, entityId, entityType, tag));
    },
    []
  );

  const switchBulkFeatureCb = useCallback(
    (value: boolean, key: Tags, entityType: FeatureEntity, fields: FField[]) => {
      dispatch(switchBulkFeature(value, key, entityType, fields));
    },
    []
  );

  const isBySeasons = currentTabObj.value === Tags.NRx || currentTabObj.value === Tags.CropStress;

  const tableHeader = (entityType: FeatureEntity) => (
    <FluroTableHeader>
      <FluroTableRow className="features__header-tr">
        <FluroTableColumn>Subscription</FluroTableColumn>
        <FluroTableColumn>Organization</FluroTableColumn>
        <FluroTableColumn>Farm</FluroTableColumn>
        <FluroTableColumn>Field</FluroTableColumn>
        {!isBySeasons ? (
          <FluroTableColumn>
            <div style={{width: 210}}>
              <FilterControl
                id={`features-column-crop-${cropColumn.value}-filter`}
                title={cropColumn.title}
                menuItems={filterData.data}
                onFilterChange={onFilterChange}
                onClearFilter={onClearFilter}
                column={cropColumn.value}
                selectedAll={filterData.selectedAll}
                onSort={null}
                sortColumn={'cropType'}
                sortOrder={true}
                isFilterWithSearch={cropColumn.isFilterWithSearch}
              />
            </div>
          </FluroTableColumn>
        ) : (
          <FluroTableColumn>Crop</FluroTableColumn>
        )}

        {
          <FluroTableColumn className="features__table-toggle-col">
            <div>{currentTabObj.label}</div>
            {!isBySeasons && (
              <div>
                <SelectionControl
                  id={`master-switch-key`}
                  type="switch"
                  label=""
                  name={`master-switch-key`}
                  checked={filteredFields.every(
                    field => field?.features?.[currentTabObj.value]?.value
                  )}
                  onChange={(value: boolean) => {
                    const fieldsToUpdate = filteredFields.filter(
                      field => field?.features?.[currentTabObj.value]?.value !== value
                    );

                    if (
                      window.confirm(
                        `You are about the ${value ? 'activate' : 'deactivate'} ${
                          currentTabObj.label
                        } on ${fieldsToUpdate.length} crop(s)`
                      )
                    ) {
                      switchBulkFeatureCb(value, currentTabObj.value, entityType, fieldsToUpdate);
                    }
                  }}
                />
              </div>
            )}
          </FluroTableColumn>
        }

        {/* NRx */}
        {currentTabObj.value === Tags.NRx && (
          <FluroTableColumn type={'date'}>Start date</FluroTableColumn>
        )}
        {currentTabObj.value === Tags.NRx && (
          <FluroTableColumn type={'date'}>End date</FluroTableColumn>
        )}
      </FluroTableRow>
    </FluroTableHeader>
  );

  if (isBySeasons) {
    return (
      <FluroDataTable fixedDividers={true} baseId="features-page-by-season">
        {tableHeader(FeatureEntity.Season)}

        <FluroTableBody>
          {slicedList
            .map((field, i: number) => {
              if (!field.seasons?.length) {
                return (
                  <FluroTableRow key={`farm-ft-${i}`} hoverBg={false}>
                    <FluroTableColumn>
                      <span className="features__names">{field.subscriptionName}</span>{' '}
                      <TableSubtext>#{field.subscriptionId}</TableSubtext>
                    </FluroTableColumn>

                    <FluroTableColumn>
                      <span className="features__names">{field.organizationName}</span>
                      <TableSubtext>#{field.organizationId}</TableSubtext>
                    </FluroTableColumn>

                    <FluroTableColumn>
                      <span className="features__names">{field.farmName}</span>{' '}
                      <TableSubtext>#{field.farmId}</TableSubtext>
                    </FluroTableColumn>

                    <FluroTableColumn>
                      <span className="features__names">{field.fieldName}</span>{' '}
                      <TableSubtext>#{field.fieldId}</TableSubtext>
                    </FluroTableColumn>

                    <FluroTableColumn className="default-value">No seasons</FluroTableColumn>

                    <FluroTableColumn className="default-value">
                      <SelectionControl
                        id={`switch-key-${i}-${field.fieldId}`}
                        type="switch"
                        label=""
                        name={`switch-key-${i}-${field.fieldId}`}
                        disabled
                      />
                    </FluroTableColumn>

                    <FluroTableColumn className="default-value">No seasons</FluroTableColumn>

                    <FluroTableColumn className="default-value">No seasons</FluroTableColumn>
                  </FluroTableRow>
                );
              }

              return field.seasons.map((s, seasonIndex) => {
                let tagValue: any = null;

                try {
                  tagValue = JSON.parse(s?.features?.[currentTabObj.value]?.value);
                } catch (e) {}

                return (
                  <FluroTableRow key={`farm-ft-${s.id}`} hoverBg={false}>
                    {!seasonIndex && (
                      <FluroTableColumn rowSpan={field.seasons.length}>
                        <span className="features__names">{field.subscriptionName}</span>{' '}
                        <TableSubtext>#{field.subscriptionId}</TableSubtext>
                      </FluroTableColumn>
                    )}

                    {!seasonIndex && (
                      <FluroTableColumn rowSpan={field.seasons.length}>
                        <span className="features__names">{field.organizationName}</span>
                        <TableSubtext>#{field.organizationId}</TableSubtext>
                      </FluroTableColumn>
                    )}

                    {!seasonIndex && (
                      <FluroTableColumn rowSpan={field.seasons.length}>
                        <span className="features__names">{field.farmName}</span>{' '}
                        <TableSubtext>#{field.farmId}</TableSubtext>
                      </FluroTableColumn>
                    )}

                    {!seasonIndex && (
                      <FluroTableColumn rowSpan={field.seasons.length}>
                        <span className="features__names">
                          <Ln map href={`${field.farmId}/${field.fieldId}`}>
                            {field.fieldName}
                          </Ln>
                        </span>{' '}
                        <TableSubtext>#{field.fieldId}</TableSubtext>
                      </FluroTableColumn>
                    )}

                    <FluroTableColumn>
                      <CropInfo
                        cropType={s.cropType}
                        cropSubType={s.params?.cropSubType}
                        startDate={getCropDate(s.startDate)}
                        endDate={getCropDate(s.endDate)}
                        hasGeometry={!!s.geometry}
                      />
                    </FluroTableColumn>

                    <FluroTableColumn>
                      <SelectionControl
                        id={`switch-key-${i}-${s.id}`}
                        type="switch"
                        label=""
                        name={`switch-key-${i}-${s.id}`}
                        checked={!!tagValue}
                        onChange={() => {
                          return switchFeatureCb(
                            getRetroDemoDefaultValue(s),
                            currentTabObj.value,
                            s.id,
                            FeatureEntity.Season,
                            s?.features[currentTabObj.value]
                          );
                        }}
                      />
                    </FluroTableColumn>

                    {currentTabObj.value === Tags.NRx && (
                      <>
                        <FluroTableColumn
                          type={'date'}
                          className={cn('tag-date-col', {
                            'default-value': tagValue && !tagValue?.startDate,
                          })}
                        >
                          {!tagValue ? (
                            '-'
                          ) : (
                            <FluroDatePicker
                              id={`start-date-${s.id}`}
                              name={`start-date-${s.id}`}
                              selected={
                                tagValue?.startDate
                                  ? moment(tagValue?.startDate)
                                  : moment(s.startDate)
                              }
                              minDate={moment(s.startDate)}
                              maxDate={moment(s.endDate)}
                              onChange={date => {
                                if (!s.features[currentTabObj.value].id) {
                                  showNotification({
                                    title: t({id: 'note.warning'}),
                                    message: 'Enable the feature before set the date.',
                                    type: 'warning',
                                  });
                                  return;
                                }

                                updateFeatureValueCb(
                                  s.id,
                                  FeatureEntity.Season,
                                  currentTabObj.value,
                                  s?.features?.nrx?.id,
                                  JSON.stringify({
                                    ...(tagValue ? tagValue : {}),
                                    startDate: date.format(),
                                  })
                                );
                              }}
                              hideFormat
                              autoComplete="no"
                              hasPopper
                            />
                          )}
                        </FluroTableColumn>

                        <FluroTableColumn
                          type={'date'}
                          className={cn('tag-date-col', {
                            'default-value': tagValue && !tagValue?.endDate,
                          })}
                        >
                          {!tagValue ? (
                            '-'
                          ) : (
                            <FluroDatePicker
                              id={`end-date-${s.id}`}
                              name={`end-date-${s.id}`}
                              selected={
                                tagValue?.endDate
                                  ? moment(tagValue.endDate)
                                  : moment(s.startDate).add(4, 'months') // default date is startDate + 4 months
                              }
                              onChange={date => {
                                if (!s.features[currentTabObj.value].id) {
                                  showNotification({
                                    title: t({id: 'note.warning'}),
                                    message: 'Enable the feature before set the date.',
                                    type: 'warning',
                                  });
                                  return;
                                }

                                updateFeatureValueCb(
                                  s.id,
                                  FeatureEntity.Season,
                                  Tags.NRx,
                                  s?.features?.nrx?.id,
                                  JSON.stringify({
                                    ...(tagValue ? tagValue : {}),
                                    endDate: date.format(),
                                  })
                                );
                              }}
                              hideFormat
                              autoComplete="no"
                              hasPopper
                            />
                          )}
                        </FluroTableColumn>
                      </>
                    )}
                  </FluroTableRow>
                );
              });
            })
            .flat()}
        </FluroTableBody>
        <FluroTablePagination
          page={pagination.page}
          className="features__pagination"
          rows={filteredFields.length}
          rowsPerPage={pagination.perPage}
          rowsPerPageLabel="Fields"
          onPagination={handlePagination}
          rowsPerPageItems={calcRowsPerPageItems(filteredFields.length)}
        />
      </FluroDataTable>
    );
  }

  return (
    <FluroDataTable fixedDividers={true} baseId="features-page">
      {tableHeader(FeatureEntity.Field)}

      <FluroTableBody>
        {slicedList.map((field, i: number) => {
          return (
            <FluroTableRow key={`farm-ft-${i}`}>
              <FluroTableColumn>
                <span className="features__names">{field.subscriptionName}</span>{' '}
                <TableSubtext>#{field.subscriptionId}</TableSubtext>
              </FluroTableColumn>
              <FluroTableColumn>
                <span className="features__names">{field.organizationName}</span>
                <TableSubtext>#{field.organizationId}</TableSubtext>
              </FluroTableColumn>
              <FluroTableColumn>
                <span className="features__names">{field.farmName}</span>{' '}
                <TableSubtext>#{field.farmId}</TableSubtext>
              </FluroTableColumn>

              <FluroTableColumn>
                <Ln map href={`${field.farmId}/${field.fieldId}`}>
                  {field.fieldName}
                </Ln>
                <TableSubtext>#{field.fieldId}</TableSubtext>
              </FluroTableColumn>

              <FluroTableColumn>
                <OneCrop field={field} />
              </FluroTableColumn>

              <FluroTableColumn>
                <SelectionControl
                  id={`switch-key-${i}-${field.fieldId}`}
                  type="switch"
                  label=""
                  name={`switch-key-${i}-${field.fieldId}`}
                  checked={!!field?.features?.[currentTabObj.value]?.value}
                  onChange={(value: boolean) => {
                    switchFeatureCb(
                      value,
                      currentTabObj.value,
                      field.fieldId,
                      FeatureEntity.Field,
                      field?.features[currentTabObj.value]
                    );
                  }}
                />
              </FluroTableColumn>
            </FluroTableRow>
          );
        })}
      </FluroTableBody>
      <FluroTablePagination
        page={pagination.page}
        className="features__pagination"
        rows={filteredFields.length}
        rowsPerPage={pagination.perPage}
        rowsPerPageLabel="Fields"
        onPagination={handlePagination}
        rowsPerPageItems={calcRowsPerPageItems(filteredFields.length)}
      />
    </FluroDataTable>
  );
};

export default Table;
