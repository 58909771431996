import type React from 'react';
import {useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import {setGlobalParam} from 'modules/global/actions';
import {showNotification} from 'components/notification/notification';
import {useAppSelector, usePrevious} from '_hooks';
import {isAgxFarm} from '_utils/farm-utils';
import {loadFields} from '../actions';
import {t} from 'i18n-utils';
import {DontHaveAccess} from '_api/messages';
import {selectFarmsList} from 'modules/farms/selectors';
import {useParsedMatchParams} from '../../../_hooks/use-parsed-match-params';
import {selectFarm} from 'modules/farms/actions';
import {selectCurrentFarmId} from '../reducer/selectors';

export const LoadFields: React.ComponentType = () => {
  const dispatch = useDispatch();
  const [farmLoaded, setFarmLoaded] = useState(0);
  const farmsList = useAppSelector(selectFarmsList);
  const {farmId} = useParsedMatchParams();
  const currentFarmId = useAppSelector(selectCurrentFarmId);

  const prev = usePrevious({
    farmsList,
  });

  useEffect(
    function unsureCurrentFarmIdIsSet() {
      if (!prev?.farmsList.length && farmsList.length && !currentFarmId && !farmId) {
        dispatch(selectFarm(farmsList[0].id));
      }
    },
    [farmsList, currentFarmId, farmId]
  );

  useEffect(() => {
    if (!farmsList.length) return;
    if (farmId === farmLoaded) return;
    if (isNaN(farmId)) return;

    let currentFarm = farmsList.find(g => g.id === farmId);

    if (!currentFarm) {
      showNotification({
        title: t({id: 'note.error', defaultMessage: 'Error'}),
        message: DontHaveAccess(),
        type: 'error',
      });
      return;
    }
    const growerName = isAgxFarm(currentFarm) ? currentFarm.growerName ?? '' : '';
    dispatch(setGlobalParam('currentGroupId', currentFarm.id));
    dispatch(setGlobalParam('growerName', growerName));
    dispatch(loadFields(currentFarm.id));

    setFarmLoaded(currentFarm.id);
  }, [farmsList, farmId]);

  return null;
};
