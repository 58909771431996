import React, {useCallback, useEffect, useMemo} from 'react';
import {fetchCropMatching, setSort, setPagination} from './crop-types-matching.module';
import {useDispatch, useSelector} from 'react-redux';
import type {AppStore} from 'reducers';
import CropTypesMatchingRow from './crop-types-matching-row';
import {CTMWrapper} from './crop-types-matching.styled';
import {SortableColumn} from 'containers/admin/logs/shared';
import {calcRowsPerPageItems} from 'containers/admin';
import {AsyncStatusType, Status} from '../../../modules/helpers';
import {
  FluroDataTable,
  FluroTableBody,
  FluroTableColumn,
  FluroTableHeader,
  FluroTablePagination,
  FluroTableRow,
} from 'components';

const CropTypesMatchingTable = () => {
  const dispatch = useDispatch();

  const list = useSelector((state: AppStore) => state.admin.cropTypesMatching.list);
  const _sortBy = useSelector((state: AppStore) => state.admin.cropTypesMatching.sortBy);
  const _desc = useSelector((state: AppStore) => state.admin.cropTypesMatching.desc);

  const page = useSelector((state: AppStore) => state.admin.cropTypesMatching.page);
  const perPage = useSelector((state: AppStore) => state.admin.cropTypesMatching.perPage);
  const startIndex = useSelector((state: AppStore) => state.admin.cropTypesMatching.startIndex);

  const status = useSelector(
    (state: AppStore) => state.helpers.asyncStatuses[AsyncStatusType.cropTypesMatching].status
  );

  useEffect(() => {
    dispatch(fetchCropMatching());
  }, []);

  const onSort = useCallback(
    (sortBy: string, selectedSortBy: string) => {
      dispatch(setSort(sortBy, sortBy === selectedSortBy ? !_desc : _desc));
    },
    [_desc]
  );

  const handlePagination = useCallback((startIndex: number, perPage: number, page: number) => {
    dispatch(setPagination(page, perPage, startIndex));
  }, []);

  const slicedList = useMemo(
    () => list.slice(startIndex, startIndex + perPage),
    [perPage, startIndex, list]
  );

  return (
    <CTMWrapper>
      {status === Status.Pending ? (
        <div>Loading...</div>
      ) : (
        <FluroDataTable plain baseId="crop-types-matching-table">
          <FluroTableHeader>
            <FluroTableRow>
              <SortableColumn
                sortBy="wrongCrop"
                selectedSortBy={_sortBy}
                desc={_desc}
                onSort={onSort}
              >
                Unmatched Crop Types
              </SortableColumn>

              <SortableColumn sortBy="status" selectedSortBy={_sortBy} desc={_desc} onSort={onSort}>
                Status
              </SortableColumn>

              <SortableColumn
                sortBy="seasonNumber"
                selectedSortBy={_sortBy}
                desc={_desc}
                onSort={onSort}
                type={'number'}
              >
                #Crops
              </SortableColumn>

              <SortableColumn
                sortBy="correctCrop"
                selectedSortBy={_sortBy}
                desc={_desc}
                onSort={onSort}
              >
                Regrow Crop Type
              </SortableColumn>

              <FluroTableColumn />
            </FluroTableRow>
          </FluroTableHeader>

          <FluroTableBody>
            {slicedList.map((item, i) => (
              <CropTypesMatchingRow key={i} data={item} />
            ))}
          </FluroTableBody>

          <FluroTablePagination
            className="features__pagination"
            page={page}
            rows={list.length}
            rowsPerPage={perPage}
            rowsPerPageLabel="Entities"
            onPagination={handlePagination}
            rowsPerPageItems={calcRowsPerPageItems(list.length)}
          />
        </FluroDataTable>
      )}
    </CTMWrapper>
  );
};

export default CropTypesMatchingTable;
