import TagManager from 'react-gtm-module';
import GoogleTagManager from '@redux-beacon/google-tag-manager';
import {createMiddleware} from 'redux-beacon';
import {LoginActionTypes} from '../login/types';
import Events from './event-definitions';
import envConfig from '_environment';

if (envConfig.env === 'production' && envConfig.metriks.gtmId) {
  TagManager.initialize({gtmId: envConfig.metriks.gtmId});
}

// TagManager.initialize({gtmId: 'GTM-TTFHW4L'}); // debug only

const eventsMapper: any = (action: any): any => {
  switch (action.type) {
    case LoginActionTypes.LOGIN:
      return Events.login;

    default:
      return [];
  }
};

const gtm = GoogleTagManager();
const gtmMiddleware = createMiddleware(eventsMapper, gtm);

export default gtmMiddleware;
