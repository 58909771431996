import {MRVStageIconComponent} from 'components/mrv/mrv-stage-icon';
import {reportError} from 'containers/error-boundary';
import React, {useCallback, useEffect, useState} from 'react';
import {useAppDispatch, useAppSelector} from '_hooks';
import {selectEnrollmentReadOnly} from '../enrollment/carbon-store/selectors';
import {selectMonitoringReadOnly, selectPhaseStageByType} from '../monitoring/module/selectors';
import type {MRVStageNormalized, MRVSurvey} from '../types';
import {MRVPhaseType, MRVStageType} from '../types';
import {SurveyDialog} from '../home-page-dialogs/mrv-survey-dialog';
import {MRVApi} from '_api';
import {useMonitoringUrlParams} from '../monitoring/hooks';
import type {ProgramStageSubItemProps} from 'components/mrv/home-page-components/home-page-components';
import {createProgramStageSubItem} from 'components/mrv/home-page-components/home-page-components';
import {fetchStageCompletion} from '../monitoring/module/thunks';
import {SurveyView} from '../monitoring/form/mrv-table/alternative-views/survey-view';
import {useRecursivePoll} from '_hooks/use-recursive-poll';
import {t} from 'i18n-utils';
import {useIsStageDisabled} from '../monitoring/hooks/use-is-stage-disabled';
import {getStageName} from 'containers/mrv/base';

/**
 * Returns rendered survey item and survey dialog elements for mrv home screen.
 * Can be used for both Enrollment and Monitoring surveys.
 *
 * @TODO (stas): surveyItem and surveyDialog are used in enrollment.
 * Get rid of the dialog and use surveyView instead, the same way it's used in monitoring.
 */
export const useSurveyItem = (
  phaseType: MRVPhaseType
): {
  surveyItem: ProgramStageSubItemProps;
  surveyDialog: JSX.Element | null;
  surveyView: JSX.Element | null;
} => {
  const dispatch = useAppDispatch();
  const {projectId, stageId} = useMonitoringUrlParams();
  const stage = useAppSelector(s => selectPhaseStageByType(s, phaseType, MRVStageType.Survey));
  const completion = useAppSelector(s => s.monitoring.completion);
  const isStageDisabled = useIsStageDisabled(phaseType);

  const surveyIsOpen = stage?.id === stageId;

  const [loading, setLoading] = useState(false);
  const [dialogVisible, setDialogVisible] = useState(false);
  const [survey, setSurvey] = useState<MRVSurvey>();
  const [shouldPollPdf, setShouldPollPdf] = useState(false);
  const actionLabel = useButtonLabel(phaseType, loading, stage, survey);

  useRecursivePoll(
    async () => {
      if (!stage) return;
      const newSurvey = await getSurvey(projectId, stage.id);
      if (newSurvey?.results_pdf_url) {
        setShouldPollPdf(false);
        setLoading(false);
      }
    },
    5000,
    shouldPollPdf
  );

  const getSurvey = async (projectId: number, stageId: number) => {
    if (!projectId || !stageId) return;
    try {
      const r = await MRVApi.getSurvey(projectId, stageId);
      const survey = r.data;
      if (!survey) return;
      setSurvey(survey);
      return survey;
    } catch (e) {
      reportError(`Couldn't fetch survey for project "${projectId}" and stage "${stageId}": ${e}`);
    }
  };

  const submitSurvey = useCallback(
    async (submissionId: string) => {
      if (!stage || !submissionId) return;
      try {
        setLoading(true);

        const stageId = stage.id;
        const r = await MRVApi.postSurvey(projectId, stageId, submissionId);
        const survey = r.data;
        setSurvey(survey);
        dispatch(fetchStageCompletion({projectId, stageId}));

        // Wait some time (5 seconds was picked by trial) so Paperform will generate the pdf.
        // We wait straight away, because if user will try to click and view pdf while it's not ready,
        // if we wait for too long after the user action and before showing the popup, the browser will block it.
        setShouldPollPdf(true);
        // Don't turn off the loading. It'll be turned off in the useRecursivePoll.
        // setLoading(false);
      } catch (e) {
        setLoading(false);
        reportError(`Couldn't post survey "${stage.id}" with submission id "${submissionId}"`);
      }
    },
    [projectId, stageId]
  );

  // Used for dialog.
  const showSurvey = async () => {
    if (!stage) return;
    setDialogVisible(true);

    // Create a new survey for this project if it doesn't exist yet.
    if (survey?.completed == null) {
      try {
        const r = await MRVApi.postSurvey(projectId, stage.id);
        const survey = r.data;
        setSurvey(survey);
      } catch (e) {
        reportError(
          `Couldn't post survey for project "${projectId}" and stage "${stage.id}": ${e}`
        );
      }
    }
  };

  useEffect(() => {
    if (!stage?.survey_id || !projectId) return;

    const fetchSurvey = async () => {
      setLoading(true);
      const newSurvey = await getSurvey(projectId, stage?.id);
      setLoading(false);

      // Create a new survey for this project if it doesn't exist yet.
      if (newSurvey?.completed == null) {
        try {
          const r = await MRVApi.postSurvey(projectId, stage.id);
          const survey = r.data;
          setSurvey(survey);
        } catch (e) {
          reportError(
            `Couldn't post survey for project "${projectId}" and stage "${stage.id}": ${e}`
          );
        }
      }
    };
    fetchSurvey();
  }, [projectId, stage?.id]);

  const surveyItem: ProgramStageSubItemProps = !stage
    ? createProgramStageSubItem()
    : {
        title: getStageName(stage),
        icon: <MRVStageIconComponent iconUrl={stage?.icon} stageType={stage?.type_} />,
        onClick: showSurvey,
        percentComplete: completion?.[stage?.id]?.percentage_complete || 0,
        actionLabel,
        disabled: !survey?.survey_id || (stage && isStageDisabled(stage)),
        loading,
        order: stage?.order,
      };

  const surveyDialog =
    dialogVisible && survey?.survey_id && stage ? (
      <SurveyDialog
        stage={stage}
        surveyId={survey?.survey_id}
        onHide={() => setDialogVisible(false)}
        onSubmit={submitSurvey}
      />
    ) : null;

  const surveyView =
    !surveyIsOpen || !survey?.survey_id ? null : (
      <SurveyView
        surveyId={survey?.survey_id}
        loading={loading}
        pdfUrl={survey?.results_pdf_url}
        onSubmit={submitSurvey}
      />
    );

  return {surveyItem, surveyDialog, surveyView};
};

const useButtonLabel = (
  phaseType: MRVPhaseType,
  loading: boolean,
  stage?: MRVStageNormalized,
  survey?: MRVSurvey
) => {
  const completion = useAppSelector(s => s.monitoring.completion);
  const {isReadOnly} = useAppSelector(s => {
    switch (phaseType) {
      case MRVPhaseType.Enrollment:
        return selectEnrollmentReadOnly(s);
      case MRVPhaseType.Monitoring:
        return selectMonitoringReadOnly(s);
      default:
        reportError(`useSurveyItem is not implemented for the phase type: "${phaseType}"`);
    }
    return {isReadOnly: false};
  });

  if (loading) {
    return t({id: 'BtnLabel.Mrv.loading', defaultMessage: 'loading'});
  }
  if (!stage || !survey?.survey_id) {
    return t({id: 'BtnLabel.Mrv.unconfigured', defaultMessage: 'unconfigured'});
  }
  if (completion[stage?.id]?.is_completed || isReadOnly) {
    return t({id: 'BtnLabel.Mrv.view', defaultMessage: 'view'});
  }
  if (completion[stage?.id]?.percentage_complete !== 0 || survey?.completed === false) {
    return t({id: 'BtnLabel.Mrv.continue', defaultMessage: 'continue'});
  }
  return t({id: 'BtnLabel.Mrv.start', defaultMessage: 'start'});
};
