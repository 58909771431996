import {schema} from 'normalizr';

export const SeasonSchema = new schema.Entity('seasons');

export const FieldSchema = new schema.Entity(
  'fields',
  {Seasons: [SeasonSchema]},
  {idAttribute: 'FieldID'}
);

export const FarmSchema = new schema.Entity(
  'farms',
  {
    fields: [FieldSchema],
  },
  {
    processStrategy: (value, parent) => {
      if (!Array.isArray(parent.fields)) return value;
      value.fields = parent.fields;
      return value;
    },
  }
);

export const KmlGroupResponseSchema = new schema.Object({group: FarmSchema, fields: [FieldSchema]});
export const KmlGroupsListResponseSchema = new schema.Object({
  farms: [KmlGroupResponseSchema],
});
