import {createSelector} from '@reduxjs/toolkit';
import type {MRVAttribute, MRVAttributeType} from 'containers/mrv/types';
import {MRVPhaseType} from 'containers/mrv/types';
import type {AppStore} from 'reducers';
import {
  selectCurrentProjectFieldsList,
  selectCurrentProjectValues,
  selectMRVAttributes,
  selectPhaseStages,
} from './selectors';

export const selectCurrentProgramMonitoringAttributes = createSelector(
  [s => selectPhaseStages(s, MRVPhaseType.Monitoring), selectMRVAttributes],
  (stages, attrs) => {
    const attributes: MRVAttribute[] = [];
    stages?.forEach(stage => {
      stage.attributes.forEach(a => {
        attributes.push(attrs[a]);
      });
    });
    return attributes;
  }
);

export const selectCurrentProjectMonitoringValues = createSelector(
  [selectCurrentProjectValues, s => selectPhaseStages(s, MRVPhaseType.Monitoring)],
  (values, stages) => {
    if (!values?.length || !stages?.length) return [];

    const attrs: {[id: number]: true} = {};
    stages.forEach(stage => {
      stage.attributes.forEach(attrId => {
        attrs[attrId] = true;
      });
    });

    const monitoringValues = values.filter(value => !!attrs[value.attribute_id]);
    return monitoringValues;
  }
);

export const selectCurrentProgramMonitoringAttributeByType = createSelector(
  [
    selectCurrentProgramMonitoringAttributes,
    (s: AppStore, attributeType: MRVAttributeType) => attributeType,
  ],
  (attributes, attributeType) => attributes?.find(a => a.type === attributeType)
);

export const selectCurrentProjectMonitoringValuesForAttributeByType = createSelector(
  [
    selectCurrentProjectValues,
    selectCurrentProgramMonitoringAttributes,
    (s: AppStore, attributeType: MRVAttributeType) => attributeType,
  ],
  (values, attributes, attributeType) => {
    if (!values?.length || !attributes?.length) return [];

    const attr = attributes.find(a => a.type === attributeType);

    if (!attr) return [];

    return values.filter(value => value.attribute_id === attr.id);
  }
);

// Farm and crops filters at the top of monitoring table (and saved into URL) affect this request.
export const selectCurrentProjectFieldsByFarmIdAndSummerCrop = createSelector(
  [
    selectCurrentProjectFieldsList,
    selectCurrentProjectMonitoringValuesForAttributeByType,
    (s: AppStore, attributeType: MRVAttributeType, crops: string[], farmId?: number) => farmId,
    (s: AppStore, attributeType: MRVAttributeType, crops: string[], _farmId?: number) => crops,
  ],
  (fields, values, farmId, _crops) => {
    const validIds: {[fieldId: number]: boolean} = {};

    // By default all the fields are valid. Each following filter would set some ids to false.
    fields.forEach(field => {
      validIds[field.id] = true;
    });

    if (farmId) {
      fields.forEach(field => {
        const fieldId = field.id;
        if (validIds[fieldId]) {
          validIds[fieldId] = field.farm_id === farmId;
        }
      });
    }

    return fields.filter(f => validIds[f.id]);
  }
);
