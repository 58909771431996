import {useEffect, useState} from 'react';
import {getAnalyticsData} from '../actions/analytics-actions';
import {useAppDispatch, useAppSelector, usePrevious} from '_hooks';
import {
  selectCurrentPointsGroup,
  selectCurrentSeason,
  selectCurrentTab,
  selectRemoteSensingFilterProps,
} from '../reducer/selectors';
import {ActionTypes} from 'containers/map/reducer/types';

/**
 * The component doesn't have any rendering, it exists only to handle when to call the analytic request.
 */
const AnalyticEffects = (): null => {
  const dispatch = useAppDispatch();
  const [shouldLoadAnalytic, setShouldLoadAnalytic] = useState(false);

  const feature = useAppSelector(selectCurrentTab);
  const sourceFilters = useAppSelector(selectRemoteSensingFilterProps);
  const currentPoints = useAppSelector(selectCurrentPointsGroup);
  const currentSeason = useAppSelector(selectCurrentSeason);
  const prev = usePrevious({
    currentPoints,
  });

  const onSetShouldLoadAnalytic = (value: boolean) =>
    shouldLoadAnalytic !== value && setShouldLoadAnalytic(value);

  useEffect(() => {
    if (shouldLoadAnalytic && feature === 'analytics') {
      setTimeout(() => {
        // might have several reasons to load the analytic in the same time, so to avoid it the timeout is here
        // - 1. season is set, one trigger. 2. in the new set season there are sampling points, amount of the points is changed it is trigger 2
        onSetShouldLoadAnalytic(false);
        dispatch(getAnalyticsData());
      }, 500);
    }
  }, [shouldLoadAnalytic, feature]);

  useEffect(() => {
    onSetShouldLoadAnalytic(true);
    dispatch({
      type: ActionTypes.MAP_SET_FIELD_SMOOTHED_VALUES,
      allDates: [],
      fieldData: [],
    }); // reset mean values in case the source was changed
  }, [sourceFilters]);

  useEffect(
    function checkSamplingPointsForChanges() {
      const pointsAmountOrPositionChanged =
        prev?.currentPoints?.length &&
        !prev?.currentPoints.every(loopPoint => {
          const samePoint = currentPoints.find(p => p.id === loopPoint.id);
          if (!samePoint) return false;
          const sameCoordinates =
            samePoint.geometry.coordinates[0] === loopPoint.geometry.coordinates[0] &&
            samePoint.geometry.coordinates[1] === loopPoint.geometry.coordinates[1];
          return samePoint && sameCoordinates;
        });
      if (prev?.currentPoints?.length !== currentPoints?.length || pointsAmountOrPositionChanged) {
        onSetShouldLoadAnalytic(true);
      }
    },
    [currentPoints]
  );
  useEffect(
    function observeSeasonChanges() {
      if (currentSeason?.id) {
        onSetShouldLoadAnalytic(true);
      }
    },
    [currentSeason]
  );

  return null;
};

export default AnalyticEffects;
