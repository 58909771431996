import React, {useEffect} from 'react';
import {batch} from 'react-redux';
import {applyPathParams} from '_utils/pure-utils';
import {useAppDispatch, useAppSelector} from '_hooks';
import {useParsedMatchParams} from '_hooks/use-parsed-match-params';
import {CircularProgress, FontIcon} from 'react-md';
import {push} from 'connected-react-router';
import {Flex, FluroButton, Text} from 'components';
import {FluroCheckbox} from 'components/fluro-checkbox/fluro-checkbox';
import {
  selectReportDetails,
  selectReportPageIndex,
  selectReportPageSize,
  selectReportRecordCount,
  selectReportOnlyTopScenarios,
  selectReportData,
} from 'containers/si/module/selectors';
import {selectIsLoading, selectIsSucceed} from 'modules/helpers/selectors';
import {selectIsAdmin} from 'containers/login/login-selectors';
import {ActionType} from 'containers/si/module/types';
import {SIReportModelLabel} from 'containers/si/constants';
import {SI_REPORT_LIST} from 'containers/si/routes';
import {setDashboardPageIndex, setDashboardTopScenarios} from 'containers/si/module/reducer';
import {fetchAPReportScenarios} from 'containers/si/module/thunks';
import {ScenarioOrderDropdown} from 'containers/si/planning-report/dashboard/components/scenario-order-dropdown';
import {UnitSelect} from 'containers/si/planning-report/dashboard/components/unit-select';
import {SourcingRegionsFilter} from 'containers/si/planning-report/dashboard/components/sourcing-regions-filter';
import {CropTypeFilter} from 'containers/si/planning-report/dashboard/components/crop-type-filter';
import {InterventionFilter} from 'containers/si/planning-report/dashboard/components/intervention-filter';
import {ScenarioTable} from 'containers/si/planning-report/dashboard/components/scenario-table';
import {BarCharts} from 'containers/si/planning-report/dashboard/cards/bar-charts/bar-charts';
import {EmissionFactorCard} from 'containers/si/planning-report/dashboard/cards/emission-factor/emission-factor';
import {ReductionsRemovalsAllCard} from 'containers/si/planning-report/dashboard/cards/reductions-removals-all';
import {StyledReportBackButton} from './styled-components';
import {
  SIStyledStickyHeader,
  SIPageTitle,
  SISectionContainer,
} from 'containers/si/si-styled-components';
import {isNil, isDefined} from '_utils/typeGuards';

export const AbatementPotentialReport = () => {
  const dispatch = useAppDispatch();
  const {programId, reportId} = useParsedMatchParams();
  const report = useAppSelector(s => selectReportDetails(s, reportId));
  const pageIndex = useAppSelector(selectReportPageIndex);
  const pageSize = useAppSelector(selectReportPageSize);
  const recordCount = useAppSelector(selectReportRecordCount);
  const onlyTopScenarios = useAppSelector(selectReportOnlyTopScenarios);
  const reportData = useAppSelector(selectReportData);
  const isAdmin = useAppSelector(selectIsAdmin);

  const startNumber = pageIndex * pageSize + 1;
  const endNumber = Math.min((pageIndex + 1) * pageSize, recordCount);

  const reportFetched = useAppSelector(s =>
    selectIsSucceed(s, [ActionType.FETCH_REPORT_SCENARIOS_AP])
  );

  useEffect(() => {
    if (!report || report?.id !== reportId || !reportFetched || isNil(reportData[reportId])) {
      dispatch(fetchAPReportScenarios({programId, reportId}));
    }
  }, [dispatch, programId, reportId, report, reportFetched, reportData]);

  const isLoadingReport = useAppSelector(s =>
    selectIsLoading(s, [ActionType.FETCH_REPORT_SCENARIOS_AP])
  );

  const getNextPage = () => {
    dispatch(setDashboardPageIndex(pageIndex + 1));
  };

  const getPreviousPage = () => {
    dispatch(setDashboardPageIndex(pageIndex - 1));
  };

  // This is to catch all instances of switching back and forth between reports and report types
  const reportFetchedAndNotLoading =
    !isLoadingReport &&
    reportFetched &&
    report &&
    report.id === reportId &&
    isDefined(reportData[reportId]);

  return !reportFetchedAndNotLoading ? (
    <div>
      <Flex className="margin-bottom-16" justifyContent="space-between">
        <Flex alignItems="baseline" gap="10px">
          <Text className="mb-0" bold variant="h1">
            Loading Report
          </Text>
        </Flex>
      </Flex>
      <CircularProgress className={'progress'} id={'si-reports-table'} />
    </div>
  ) : (
    <>
      <Flex
        direction="row"
        className="margin-top-24 margin-bottom-24 padding-left-20 padding-right-20"
        justifyContent="space-between"
        nowrap
        key={reportId}
        alignItems="center"
      >
        <Flex direction="column">
          <StyledReportBackButton
            flat
            secondary
            iconEl={<FontIcon>keyboard_arrow_left</FontIcon>}
            onClick={() => {
              dispatch(
                push(
                  applyPathParams(SI_REPORT_LIST, {
                    programId: programId,
                  })
                )
              );
            }}
          >
            Back to All Reports
          </StyledReportBackButton>
          <Flex alignItems={'center'}>
            <SIPageTitle noPadding noMargin>
              Abatement potential
            </SIPageTitle>
            <Text className="mb-0 margin-left-12" variant="h1">
              {report?.name}{' '}
              {isAdmin ? (
                <Text className="margin-left-8" tertiary variant="small-thin">
                  (#{report?.id})
                </Text>
              ) : (
                ' '
              )}
            </Text>
          </Flex>
          <Text>
            Compare top scenarios between geographies and crops and identify opportunities with the
            greatest impact on GHG reductions, SOC removals and emission factors.
          </Text>
        </Flex>

        <Flex direction="row" nowrap alignItems="center">
          <Flex direction="row" nowrap className="margin-right-24">
            <FontIcon style={{fontSize: '16px'}} inherit>
              calendar_today
            </FontIcon>
            <Text bold nowrap className="margin-left-8 margin-right-8">
              Program years:
            </Text>
            <Text nowrap>
              {report?.start_year} - {report?.end_year}
            </Text>
          </Flex>
          <Flex direction="row" nowrap className="margin-right-24">
            <FontIcon style={{fontSize: '16px'}} inherit>
              science
            </FontIcon>
            <Text bold nowrap className="margin-left-8 margin-right-8">
              Scientific model:
            </Text>
            <Text nowrap>{report?.model ? SIReportModelLabel[report?.model] : ''}</Text>
          </Flex>
        </Flex>
      </Flex>
      <SIStyledStickyHeader position="top">
        <Flex
          direction="row"
          justifyContent="space-between"
          alignItems="baseline"
          className="margin-bottom-12"
        >
          <Flex direction="row" alignItems="center">
            <Text bold className="mb-0 margin-right-8">
              Rank by:
            </Text>
            <ScenarioOrderDropdown />
            <FluroCheckbox
              value={onlyTopScenarios}
              onChange={() => {
                batch(() => {
                  dispatch(setDashboardTopScenarios(!onlyTopScenarios));
                  dispatch(setDashboardPageIndex(0));
                });
              }}
              label={
                <Text className="mb-0">Only show top scenario for each geography and crop</Text>
              }
              className="margin-left-12"
            />
          </Flex>
          <Flex direction="row" alignItems="center">
            <Text bold className="mb-0 margin-right-8">
              Data display:
            </Text>
            <UnitSelect />
          </Flex>
        </Flex>

        <Flex direction="row" justifyContent="space-between" alignItems="baseline">
          <Flex direction="row" alignItems="center">
            <Text bold className="mb-0 margin-right-8">
              Filter by:
            </Text>
            <SourcingRegionsFilter />
            <CropTypeFilter />
            <InterventionFilter />
          </Flex>
          <Flex direction="row" alignItems="center" gap="12px">
            <Text bold className="mb-0">
              Showing {startNumber} - {endNumber} of {recordCount}:
            </Text>
            <FluroButton
              primary
              raised
              disabled={pageIndex === 0}
              onClick={getPreviousPage}
              iconBefore={true}
              iconEl={<FontIcon>keyboard_arrow_left</FontIcon>}
              className="report-dashboard-previous-button"
            >
              Previous {pageSize}
            </FluroButton>
            <FluroButton
              primary
              raised
              disabled={endNumber >= recordCount}
              onClick={getNextPage}
              iconBefore={false}
              iconEl={<FontIcon>keyboard_arrow_right</FontIcon>}
              className="report-dashboard-next-button"
            >
              Next {pageSize}
            </FluroButton>
          </Flex>
        </Flex>
      </SIStyledStickyHeader>

      <SISectionContainer>
        <ScenarioTable />
        <div className="report-dashboard-row">
          <BarCharts />
        </div>
        <div className="report-dashboard-row">
          <EmissionFactorCard />
        </div>
        <div className="report-dashboard-row">
          <ReductionsRemovalsAllCard />
        </div>
      </SISectionContainer>
    </>
  );
};
