// @ts-nocheck
import React, {useState} from 'react';

import {EnrollFieldsPopup} from './enrolled-fields-popup';
import type {Field} from 'containers/map/types';
import {enrollFields, EnrollmentStep} from 'containers/mrv/enrollment/carbon-store';

import {Flex, FluroButton, FluroDialog, Text} from 'components';
import './re-enroll-fields-dialog.scss';
import {useAppDispatch, useAppSelector} from '_hooks';
import {allowToChangeToTheStep} from 'containers/mrv/utils';
import {fetchStageCompletion} from 'containers/mrv/monitoring/module/thunks';
import {selectAllFieldsList} from 'containers/map/reducer/selectors';
import {useParsedMatchParams} from '_hooks/use-parsed-match-params';
import type {UserSettings} from 'containers/admin/users/types';
import {setProfileSettings} from 'containers/login/actions';
import {selectUserSettings} from 'containers/login/login-selectors';
import {toggleMapBar} from 'containers/map/actions';
import {dialogToggle, DialogType} from 'modules/helpers';
import {selectEnabledPhaseStages} from 'containers/mrv/monitoring/module/selectors';
import {MRVPhaseType} from 'containers/mrv/types';
import {saveEnrolledFields} from 'containers/mrv/enrollment/carbon-store/carbon-thunks-complex';
import {t} from 'i18n-utils';

enum Steps {
  ImportFields = 1,
  AddMoreFields = 2,
}

const ReEnrollFieldsDialog = ({
  onCloseDialog,
  navigateToStep,
}: {
  onCloseDialog: () => void;
  navigateToStep: (step: EnrollmentStep) => void;
}) => {
  const [step, setStep] = useState<number>(Steps.ImportFields);
  const [fieldsToReEnroll, setFieldsToReEnroll] = useState<Field[]>([]);
  const dispatch = useAppDispatch();
  const allFields = useAppSelector(selectAllFieldsList);
  const enrollmentStages = useAppSelector(s =>
    selectEnabledPhaseStages(s, MRVPhaseType.Enrollment)
  );
  const {projectId} = useParsedMatchParams();
  const userSettings = useAppSelector(selectUserSettings);
  const [isEnrolling, setIsEnrolling] = useState(false);

  const onConfirmSelection = async () => {
    setIsEnrolling(true);
    const allowChangeStep = dispatch(
      allowToChangeToTheStep(EnrollmentStep.CropPractices, true, fieldsToReEnroll)
    );

    if (allowChangeStep) {
      const enrollFieldsState: Record<number, boolean> = {};
      allFields.forEach(f => {
        enrollFieldsState[f.ID] = !!fieldsToReEnroll.find(importedF => importedF.ID === f.ID);
      });
      dispatch(enrollFields(enrollFieldsState));

      // @ts-expect-error unwrap the payloadmode
      const saveFieldsError = await dispatch(saveEnrolledFields()).unwrap();
      if (saveFieldsError) return; // means has error

      enrollmentStages.forEach(stage => {
        // update stages completion status
        dispatch(fetchStageCompletion({projectId, stageId: stage.id}));
      });
      setStep(Steps.AddMoreFields);
    }
    setIsEnrolling(false);
  };

  const handleAddFields = () => {
    onHide();
    dispatch(toggleMapBar(false));
    navigateToStep(EnrollmentStep.Fields);
    dispatch(dialogToggle(DialogType.addNewField, true, {projectId}));
  };

  const onHide = () => {
    onCloseDialog();
    const updatedSettings: UserSettings = {
      ...userSettings,
      onboarding: {...userSettings.onboarding, re_enroll_fields_onboarded: true},
    };
    dispatch(setProfileSettings(updatedSettings, false, true));
  };

  return (
    <FluroDialog
      id={'fields-pop-up'}
      title={''}
      portal
      visible={true}
      width={600}
      onHide={onHide}
      className="fields-popup-container"
    >
      <div className="header-image">
        <img src={'/assets/mrv/re-enroll-fields-pop-up-background.jpg'} alt="fields-on-map" />
      </div>
      <div className="fields-popups">
        <Flex direction="column">
          <Text variant={'small'}>
            {t({id: 'Step {step} of 2', defaultMessage: 'Step {step} of 2 '}, {step})}
          </Text>
          <Text variant={'h2'} className={'margin-top-5'}>
            {step === Steps.ImportFields
              ? t({id: 'Easily re-enroll your fields'})
              : t({id: 'Add more fields'})}
          </Text>
          {step === Steps.ImportFields ? (
            <>
              <Text variant={'h3'} className={'margin-bottom-15'}>
                {t({
                  id: 'Re-enroll your fields from last year with one click. Your cropping history will be copied as well.',
                })}
              </Text>
              <div className="enroll-popup-container">
                <EnrollFieldsPopup
                  onChange={fieldsSelected => setFieldsToReEnroll(fieldsSelected)}
                />
              </div>
            </>
          ) : (
            <Text>
              {t({
                id: 'Continue your carbon farming journey, sequester more carbon and get paid even more! Add fields today.',
              })}
            </Text>
          )}
        </Flex>
        {step === Steps.ImportFields ? (
          <Flex alignItems={'center'} gap={'10px'} justifyContent="flex-end" className="mt-2">
            <Text elementType={'span'} secondary>
              {t(
                {
                  id: 'ReEnrollSelectedFields',
                  defaultMessage:
                    '{numberFields} {numberFields, plural, one {field} other {fields}} selected',
                },
                {numberFields: fieldsToReEnroll.length}
              )}
            </Text>
            <FluroButton disabled={isEnrolling} raised primary onClick={onConfirmSelection}>
              {t({id: 'Import fields and records'})}
            </FluroButton>
          </Flex>
        ) : (
          <Flex justifyContent="flex-end" className="mt-2">
            <FluroButton raised blank onClick={onHide}>
              {t({id: 'BtnLabel.Skip', defaultMessage: 'skip'})}
            </FluroButton>
            <FluroButton raised primary onClick={handleAddFields}>
              {t({id: 'BtnLabel.AddMoreFields', defaultMessage: 'Add more fields'})}
            </FluroButton>
          </Flex>
        )}
      </div>
    </FluroDialog>
  );
};

export default ReEnrollFieldsDialog;
