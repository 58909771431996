import styled from 'styled-components';
import variables from 'variables.scss';

const panelPadding = variables['panel-padding'];

export const InputLabel = styled.div`
  color: rgba(0, 0, 0, 0.54);
  font-size: 12px;
`;

export const ContainersSeparator = styled.hr`
  margin-left: -${panelPadding};
  margin-right: -${panelPadding};
  border: 1px solid ${variables['main-gray-200']};
  border-top: 0;
`;
