import {Flex, Text} from 'components';
import {CardBase} from 'components/card/card-base';
import {setFilter} from 'modules/sustainability-insights/actions';
import {selectIsSIDataLoading} from 'modules/sustainability-insights/selectors';
import {SIGeometryFilterType} from 'modules/sustainability-insights/types';
import type {ComponentType} from 'react';
import React from 'react';
import {useAppDispatch, useAppSelector} from '_hooks';
import {YearsFilterRangeLabel} from 'containers/map/features/sustainability-insights/years-filter-range-label';
import {FieldEmissionFactorSummaryChart} from './field-emission-factor-summary-chart';
import {CardToolTip} from '../components/card-tooltip';
import {LearnMore} from '../components/learn-more';
import {TOOLTIPS} from 'containers/si/constants';

type Props = {
  isActive?: boolean;
};

export const FieldEmissionsFactorCard: ComponentType<Props> = ({isActive = false}) => {
  const dispatch = useAppDispatch();
  const isLoading = useAppSelector(selectIsSIDataLoading);

  const onFilterClick = () => {
    if (isActive || isLoading) return;
    dispatch(
      setFilter({
        activeGeometryType: SIGeometryFilterType.EmissionsFactor,
      })
    );
  };

  return (
    <CardBase
      active={isActive}
      className={'sustainability-card emission-card'}
      heading={
        <Flex nowrap justifyContent="space-between">
          <Text className="m-0" elementType="h3">
            Field Emission Factor <YearsFilterRangeLabel />
          </Text>
          <CardToolTip
            id="field-emission-factor-info"
            place="left"
            content={TOOLTIPS.KPI.fieldEmissionFactor}
          />
        </Flex>
      }
      onClick={onFilterClick}
    >
      {isActive && (
        <div>
          <FieldEmissionFactorSummaryChart />
          <LearnMore articleId={6479120} />
        </div>
      )}
    </CardBase>
  );
};
