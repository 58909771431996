import Server from './server';

export default class {
  static getMatchingRules() {
    return Server.get('crop_matching_rules', {params: {__skipPreloader: true}});
  }

  static getUnmatchedCrops() {
    return Server.get('seasons/unmatched_crops', {params: {__skipPreloader: true}});
  }

  static updateRule(data: {id: number; wrong_crop_value: string; correct_crop_value: string}) {
    return Server.put(`crop_matching_rules/${data.id}`, data);
  }

  static createRule(data: {wrong_crop_value: string; correct_crop_value: string}) {
    return Server.post(`crop_matching_rules`, data);
  }

  static applyRule(id: number) {
    return Server.get(`seasons/match_crops/${id}`);
  }
}
