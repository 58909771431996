import type {ReactNode, MutableRefObject, CSSProperties} from 'react';
import React from 'react';
import cn from 'classnames';
import {Flex} from 'components/flex';
import {Circle} from 'components/shapes/circle';
import {FontIcon} from 'react-md';
import './fluro-chip.scss';

export type Size = 'small' | 'medium';
export type Tone = 'light' | 'dimmed';

export type FluroChipProps = {
  /**
   * Display the toggle icon as an X.
   */
  active?: boolean;
  disabled?: boolean;
  dropdown?: boolean;
  leftIcon?: ReactNode;
  rightIcon?: ReactNode;
  hasToggleIcon?: boolean;
  id?: string;
  label: ReactNode;
  onClick?: (label: string) => void;
  size?: Size;
  tone?: Tone;
  className?: string;
  dragHandleRef?: MutableRefObject<HTMLButtonElement>;
  onRemove?: () => void;
  noMargin?: boolean;
  fitContent?: boolean;
  noHover?: boolean;
  style?: CSSProperties;
};

export const FluroChip: React.ComponentType<FluroChipProps> = ({
  active,
  disabled,
  dropdown,
  leftIcon,
  rightIcon,
  hasToggleIcon,
  id,
  label,
  onClick,
  size = 'medium',
  tone = 'light',
  className = '',
  dragHandleRef,
  onRemove,
  noMargin,
  fitContent,
  noHover,
  style,
}: FluroChipProps) => {
  const handleRemove = () => {
    onRemove?.();
  };
  return (
    <button
      ref={dragHandleRef}
      aria-disabled={disabled}
      disabled={disabled}
      style={style}
      className={cn('fluro-chip', size, className, {
        active,
        dropdown,
        [tone]: !disabled,
        disabled,
        noMargin,
        fitContent,
        noHover,
      })}
      onClick={() => {
        if (typeof onClick !== 'function') return;
        const value = id || (label as string);
        onClick(value);
      }}
    >
      <Flex alignItems="center" className={'flex-nowrap'}>
        {leftIcon && (
          <Flex alignItems="center" className="mr-05">
            {leftIcon}
          </Flex>
        )}
        {label}
        {dropdown && <FontIcon className="right-icon">arrow_drop_down</FontIcon>}
        {hasToggleIcon && (
          <Circle onClick={handleRemove} size={18} className="add-remove-icon-circle">
            <FontIcon onClick={handleRemove} className="add-remove-icon">
              add
            </FontIcon>
          </Circle>
        )}
        {rightIcon}
      </Flex>
    </button>
  );
};
