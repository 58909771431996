import type {Field} from 'containers/map/types';
import type {AppStore} from 'reducers';
import {cropStressLabelColors, CropStressChartLabels} from '../types';
import CropStressCard from './crop-stress-card';

export default CropStressCard;

export const getCropStressMapChartData = (
  fields: Field[],
  cropStress: AppStore['cropPerformance']['cropStressChartData']
) => {
  const cropStressCount: {[stress: string]: number} = {};
  fields.forEach(f => {
    const label = cropStress[f.ID];
    cropStressCount[label] = (cropStressCount[label] || 0) + 1;
  });
  const orderedLabels = [
    CropStressChartLabels.NoImages,
    CropStressChartLabels.NotActivated,
    CropStressChartLabels.NoStressDetected,
    CropStressChartLabels.StressDetected,
  ];
  const labels = orderedLabels.filter(label => cropStressCount[label]);
  const data = labels.map(label => ({
    id: label,
    label,
    value: cropStressCount[label],
  }));

  const getColor = (slice: any) => cropStressLabelColors[slice.id as CropStressChartLabels];
  return {data, getColor};
};
