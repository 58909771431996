import type {PaymentFor} from '_api/mrv.types';
import type {YearQuarter} from '../base/crop-practice';

export enum EnrollmentStep {
  Fields = 'Enroll fields',
  AssignPractices = 'Assign Practices',
  ViewOutcomes = 'View Outcomes',
  CropPractices = 'Confirm history',
  Survey = 'Survey',
}

export type CarbonState = {
  step: EnrollmentStep;
  year: number; // Year selected in panel that should be reflected on the map.
  quarter: YearQuarter; // Practice selected in panel that should be reflected on the map.
  farmId: number;
  /**
   * Selected fields on Fields&Boundaries stage of Enrollment.
   * On any other screen use the field list from the project.
   */
  enrolledFields: {[fieldId: number]: boolean};
  importedFields: {[fieldId: number]: boolean}; // Fields to import from previously enrolled fields
  // If a field is untoggled, we'd find all the tuples it was participating in
  // and if the new tuple's length is 1, we can just remove it from the list of overlaps
  // without calling an expensive check again.
  // See an illustration in PR https://github.com/regrowag/flurosense-ui/pull/1751
  overlapFields: number[][]; // Fields that spatially overlap for more than 50%. A part of step 1 validation. List of tuples of intersections.
  // DEPRECATED in favor of ineligibleRegionFields
  eligibleRegionFields: {[fieldId: number]: boolean}; // Fields that belong to eligible regions (Ohio, Tennesse, etc)
  // Field id is a unique field id here, not a kml id.
  ineligibleRegionFields: {[fieldId: number]: boolean}; // Fields that DON'T belong to eligible regions (regions are specified in program.enrollment config)
  assignPracticesSelectedFields: number[];
  dndcResults: DNDCResults;
  dndcPercentCompleted: number;
  ofsProgress: OFSProgress;
};

export type DNDCResults = {
  payment: number;
  tonsSequestered: number;
  detailedItems?: DNDCResultsDetailedItem[];
};

export type DNDCResultsDetailedItem = {
  contract_id: number;
  field_area: number;
  field_id: number;
  id: number;
  quantity: number;
  value: number;
  units: PaymentFor;
  note: {
    farm_name: string;
    field_name: string;
  };
};

export type OFSProgress = {
  hasUserValues: boolean;
  hasOptisValues: boolean;
  percentCompleted: number;
};
