// @ts-nocheck
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {DialogFooter} from 'containers/admin/admin.styled';
import {AccessibleFakeButton, Button, DropdownMenu, TextField, SelectField} from 'react-md';
import {t} from 'i18n-utils';
import {Controller, useForm} from 'react-hook-form';
import Dialog from 'components/fluro-dialog';
import {genKey} from '_utils/pure-utils';
import {useDispatch, useSelector} from 'react-redux';
import {dialogToggle, DialogType} from 'modules/helpers';
import {yupResolver} from '@hookform/resolvers/yup';
import Yup from 'yup';
import {showNotification} from 'components/notification/notification';
import {DialogContentWrapper} from 'containers/admin/logs/email/email-logs.styled';
import {SketchPicker} from 'react-color';
import type {AppStore} from 'reducers';
import {CropAvatar} from 'components/crop-avatar';
import {AdminApi} from '../../../_api';
import {addCropType, updateCropType} from 'modules/global/actions';
import {useAppSelector} from '../../../_hooks';
import {selectDialogData} from '../../../modules/helpers/selectors';

type Payload = {
  id?: number;
  label: string;
  'label_ru-ru': string;
  'label_pt-br': string;
  type: string;
  color: string;
  icon: string;
};

const cropTypeItems = [
  {label: 'NONE', value: 'none'},
  {label: 'Tree', value: 'tree'},
];

export const EditCropDialog = () => {
  const id = useMemo(() => genKey(), []);
  const dispatch = useDispatch();

  const {payload: crop = {}} = useAppSelector(s => selectDialogData(s, DialogType.editCrop));

  const cropTypes = useSelector((state: AppStore) => state.global.cropTypes);

  const [isColorPicker, setIsColorPicker] = useState(false);

  const onCancel = useCallback(() => dispatch(dialogToggle(DialogType.editCrop, false)), []);

  const {
    handleSubmit,
    formState: {errors},
    control,
    register,
    setValue,
    watch,
    setError,
  } = useForm<Payload>({
    resolver: yupResolver(
      Yup.object().shape({
        id: Yup.number(),
        label: Yup.string().required(),
        'label_ru-ru': Yup.string().required(),
        'label_pt-br': Yup.string().required(),
        color: Yup.string().required(),
        icon: Yup.string(),
      })
    ),
    defaultValues: {
      'label_pt-br': '',
      'label_ru-ru': '',
      ...(crop
        ? crop
        : {
            type: 'none',
            label: '',
            color: '#efefef',
            icon: '',
          }),

      type: crop?.type ? crop.type : 'none',
    },
  });

  useEffect(() => {
    register('id');
    register('icon');
    register('color');
    register('type');
  }, []);

  const color = watch('color');

  const onSave = useCallback(
    async (payload: Payload) => {
      try {
        const {type, ...rest} = payload;

        const newValue = `${payload.label}`.toLowerCase().replace(/\W/, '_');

        if (!payload.id && Object.values(cropTypes || {}).some(c => c.value === newValue)) {
          setError('label', {message: 'Crop already exist'});
          return;
        }

        const value = crop?.id ? crop.value : newValue;

        const {data} = await AdminApi.updateCrop([
          {...rest, value, type: type === 'none' ? '' : type},
        ]);

        const resultData = data.result?.[0];

        if (resultData) {
          if (crop?.id) {
            dispatch(updateCropType(resultData));
          } else {
            dispatch(addCropType(resultData));
          }
        }

        showNotification({
          title: t({id: 'note.success', defaultMessage: 'Success'}),
          message: 'Crop was saved.',
          type: 'success',
        });

        onCancel();
      } catch (e) {
        showNotification({
          title: t({id: 'note.error', defaultMessage: 'Error'}),
          message: e.message,
          type: 'error',
        });
      }
    },
    [crop]
  );

  return (
    <Dialog
      id={`edit-crop-${id}`}
      visible={true}
      title={crop ? 'Edit crop' : 'New crop'}
      focusOnMount={false}
      isDraggable
      onHide={onCancel}
      width={400}
      portal
      autosizeContent={false}
      actions={
        <DialogFooter fullWidth>
          <Button onClick={onCancel} raised>
            {t({id: 'Cancel'})}
          </Button>

          <Button type="submit" primary raised form={`edit-crop-form-${id}`}>
            {t({id: 'Save'})}
          </Button>
        </DialogFooter>
      }
    >
      <DialogContentWrapper>
        <form id={`edit-crop-form-${id}`} onSubmit={handleSubmit(onSave)}>
          <div style={{display: 'flex', alignItems: 'center'}}>
            {crop ? (
              <CropAvatar
                style={{width: 30, height: 30, marginRight: 10, overflow: 'visible'}}
                cropType={crop.value}
              />
            ) : null}
            <Controller
              name="label"
              render={({field: {value, onChange}}) => (
                <TextField
                  id={`label-${id}`}
                  label="Crop label"
                  name={`label-${id}`}
                  value={value}
                  onChange={onChange}
                  error={!!errors?.label?.message}
                  errorText={errors?.label?.message}
                  className="one-email__email_input"
                />
              )}
              control={control}
            />

            <div>
              <DropdownMenu
                id={id}
                simplifiedMenu={false}
                listHeightRestricted={false}
                menuItems={
                  <li>
                    <SketchPicker
                      color={color}
                      onChange={color => {
                        setValue('color', color.hex);
                      }}
                    />
                  </li>
                }
                centered
                onVisibilityChange={setIsColorPicker}
                visible={isColorPicker}
              >
                <AccessibleFakeButton>
                  <div style={{width: 20, height: 20, background: color || '#efefef'}} />
                </AccessibleFakeButton>
              </DropdownMenu>
            </div>
          </div>

          <div>
            <Controller
              name="type"
              render={({field: {value, onChange}}) => (
                <SelectField
                  id={`type-${id}`}
                  label="Type"
                  menuItems={cropTypeItems}
                  value={value}
                  onChange={onChange}
                  simplifiedMenu={false}
                  style={{width: '100%'}}
                />
              )}
              control={control}
            />
          </div>

          <div>
            <Controller
              name="label_pt-br"
              render={({field: {value, onChange}}) => (
                <TextField
                  id={`label_pt-br-${id}`}
                  label="Pt"
                  name={`label_pt-br-${id}`}
                  value={value}
                  onChange={onChange}
                  error={!!errors?.['label_pt-br']?.message}
                  errorText={'Pt translation is required'}
                />
              )}
              control={control}
            />
          </div>

          <div>
            <Controller
              name="label_ru-ru"
              render={({field: {value, onChange}}) => (
                <TextField
                  id={`label_ru-ru-${id}`}
                  label="Ru"
                  name={`label_ru-ru-${id}`}
                  value={value}
                  onChange={onChange}
                  error={!!errors?.['label_ru-ru']?.message}
                  errorText={'Ru translation is required'}
                  className="one-email__email_input"
                />
              )}
              control={control}
            />
          </div>
        </form>
      </DialogContentWrapper>
    </Dialog>
  );
};
