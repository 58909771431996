// @ts-nocheck
import {t} from 'i18n-utils';
import React from 'react';
import {Button} from 'react-md';
import '../index.scss';
import {toggleLowPerformingAreas, togglePremiumAnomalies} from '../actions/anomalies-actions';
import {Tags as PremiumAppTag} from 'containers/admin/features/types';
import {toggleMapButton} from '../actions';
import {RequestStatus} from '../../../types';
import {WarningSvg} from 'components/icons';
import {checkSeasonTagExist} from '../utils';
import {useAppDispatch, useAppSelector} from '_hooks';
import {selectCurrentSeason} from '../reducer/selectors';
import {
  selectLowPerformingAnomalies,
  selectPremiumAnomalies,
} from '../features/anomalies/anomalies-selectors';

const ToggleAnomalies = () => {
  const dispatch = useAppDispatch();

  const currentSeason = useAppSelector(selectCurrentSeason);
  const visibleLowPerfAnomalies = useAppSelector(selectLowPerformingAnomalies).isVisible;
  const premiumAnomaliesLoaded =
    useAppSelector(selectPremiumAnomalies).status !== RequestStatus.Idle;

  const premiumAnomaliesEnabled =
    checkSeasonTagExist(PremiumAppTag.AnomalyDetection, currentSeason) ||
    checkSeasonTagExist(PremiumAppTag.CropStress, currentSeason);

  const active = premiumAnomaliesEnabled ? premiumAnomaliesLoaded : visibleLowPerfAnomalies;

  const handleClick = () => {
    premiumAnomaliesEnabled
      ? dispatch(togglePremiumAnomalies(!active))
      : dispatch(toggleLowPerformingAreas(!visibleLowPerfAnomalies));
    dispatch(toggleMapButton('Empty'));
  };

  return (
    <Button
      id="toggle-anomaly-btn"
      title={t({id: 'Crop stress', defaultMessage: 'Crop stress'})}
      className={`map-toggle-button ${active ? 'active' : ''}`}
      name="toggle-anomaly"
      floating
      iconEl={<WarningSvg />}
      onClick={handleClick}
      mini
    />
  );
};

export default ToggleAnomalies;
