import * as React from 'react';
import {DonutChart} from 'components/chart/donut-chart';

export const ClusterMarker = ({
  dataAndColors,
}: {
  dataAndColors: {
    data: ClusterChartData[];
    getColor: (slice: any) => string;
  };
}) => {
  if (!dataAndColors.data.length) {
    return null;
  }
  const values: number[] = [];
  const colors: string[] = [];
  dataAndColors.data.forEach(d => {
    values.push(d.value);
    colors.push(dataAndColors.getColor(d));
  });

  return (
    <div className="crop-type-chart-plot">
      <DonutChart values={values.sort((a, b) => b - a)} colors={colors} />
    </div>
  );
};

export type ClusterChartData = {
  id: string | number;
  label?: string;
  value: number;
};
