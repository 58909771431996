// @ts-nocheck
import {t} from 'i18n-utils';
import React, {useEffect, useMemo} from 'react';
import {FluroDialog, FluroButton} from 'components';
import {convertTileDateToNormal, sensorView} from '_utils';
import {SelectionControl, SelectionControlGroup} from 'react-md';
import Img from 'components/img/index';
import {DownloadIcon} from '../../icons';
import {useAppSelector} from '_hooks';
import {onChangeLayerOption, remoteSensingOnChangeLayer} from '../../actions';
import {useDispatch} from 'react-redux';
import {SourceType} from '../../types';
import type {AppStore} from 'reducers';

type Props = {
  onHide: () => void;
};

const DownloadImageLayerDialog = ({onHide}: Props) => {
  const dispatch = useDispatch();

  const currentRemoteSensingImage = useAppSelector(s => s.map.currentRemoteSensingImage);
  const isRemoteSensingDialog = useAppSelector(s => s.map.isRemoteSensingDialog);
  const remoteSensingLayersSelected = useAppSelector(s => s.map.remoteSensingLayersSelected);
  const currentIsSetSpectra = useAppSelector(s => s.map.currentIsSetSpectra);
  const remoteSensingLayersOptions = useAppSelector(s => s.map.remoteSensingLayersOptions);
  const field = useAppSelector(s => s.map.field);

  const isHighResolutionImagery = currentRemoteSensingImage.Type === SourceType.SatelliteHD;

  useEffect(() => {
    // reset formats to allowed for the image type
    if (!isRemoteSensingDialog) return;
    Object.keys(remoteSensingLayersOptions).forEach(
      (formatType: keyof AppStore['map']['remoteSensingLayersOptions']) => {
        if (
          (isHighResolutionImagery
            ? !SatelliteHdAllowedFormats.includes(formatType)
            : formatType === 'formatGeotiff') &&
          remoteSensingLayersOptions[formatType]
        ) {
          onChangeFileFormat(formatType, false);
        }
      }
    );
    if (isHighResolutionImagery && !Object.values(remoteSensingLayersOptions).some(Boolean)) {
      // set a default value for HD
      onChangeFileFormat('formatGeotiff', true);
    }
  }, [isRemoteSensingDialog]);

  const disableDownloading = useMemo(() => {
    return !(
      Object.keys(remoteSensingLayersSelected).filter(
        key => remoteSensingLayersSelected[key].checked
      ).length &&
      Object.keys(remoteSensingLayersOptions).filter(
        (key: keyof AppStore['map']['remoteSensingLayersOptions']) =>
          remoteSensingLayersOptions[key]
      ).length
    );
  }, [remoteSensingLayersSelected, remoteSensingLayersOptions]);

  const downloadGeotiff = () => {
    let url = '';

    switch (currentRemoteSensingImage.Type) {
      case SourceType.SatelliteHD:
        url = `/services/data-service/archive/indices/${field.MD5}/${currentRemoteSensingImage.Date}?newBucket=true`;
        break;

      default:
        if (currentRemoteSensingImage[currentIsSetSpectra[0]]) {
          url = currentRemoteSensingImage[currentIsSetSpectra[0]].url.replace(
            'https://storage.googleapis.com/flurosat-us-west1-predate-regrow/processed-v1',
            '/load'
          );
        }
        break;
    }

    if (url) {
      window.open(url + buildGetParam(currentRemoteSensingImage.Type));
    }
  };

  const buildGetParam = (type: SourceType) => {
    switch (type) {
      case SourceType.SatelliteHD:
        let urlParams = `&indices=${getSelectedIndexes(true).join(',')}`;
        if (remoteSensingLayersOptions.formatGeotiff) {
          // satellite HD support only one format per download
          urlParams = urlParams + '&format=tif';
        }
        return urlParams;

      default:
        const options = [
          `formatPng=${remoteSensingLayersOptions.formatPng}`,
          `formatGeotiff=${remoteSensingLayersOptions.formatGeotiff}`,
          `rangeGrey=${remoteSensingLayersOptions.rangeGrey}`,
          `rangeFloat=${remoteSensingLayersOptions.rangeFloat}`,
        ];

        return `?layers${getSelectedIndexes().join(',')}&${options.join('&')}`;
    }
  };

  const getSelectedIndexes = (lowerCased: boolean = false) => {
    const layers: string[] = [];
    Object.keys(remoteSensingLayersSelected).forEach(layer => {
      if (remoteSensingLayersSelected[layer] && remoteSensingLayersSelected[layer].checked) {
        layers.push(lowerCased ? layer.toLowerCase() : layer);
      }
    });

    return layers;
  };

  const onChangeLayer = (layer: string, value: boolean) => {
    dispatch(remoteSensingOnChangeLayer(layer, value));
  };

  const onChangeFileFormat = (
    layer: keyof AppStore['map']['remoteSensingLayersOptions'],
    value: boolean
  ) => {
    dispatch(onChangeLayerOption(layer, value));
    if (isHighResolutionImagery && SatelliteHdAllowedFormats.includes(layer)) {
      // reset other formats
      Object.keys(remoteSensingLayersOptions).forEach(
        (formatType: keyof AppStore['map']['remoteSensingLayersOptions']) => {
          if (formatType !== layer && remoteSensingLayersOptions[formatType]) {
            dispatch(onChangeLayerOption(formatType, false));
          }
        }
      );
    }
  };

  const allLayersSelected = Object.values(remoteSensingLayersSelected).every(
    ({checked}) => checked
  );

  return (
    <FluroDialog
      focusOnMount={false}
      id="remote-sensing-dialog-container"
      visible={!!isRemoteSensingDialog}
      style={{zIndex: 999}}
      title={t({id: 'Download layers'})}
      isDraggable
      onHide={onHide}
      width={450}
      height={500}
      portal
    >
      <div className="remote-sensing__popup">
        <table>
          <thead>
            <tr>
              <th className="remote-sensing__checkbox">
                <SelectionControl
                  id={`remote-sensor-select-all`}
                  name={`remote-sensor-select-all`}
                  label=""
                  type="checkbox"
                  checked={allLayersSelected}
                  onChange={(val: boolean) => onChangeLayer('all', val)}
                />
              </th>
              <th colSpan={2} style={{textAlign: 'left'}}>
                {t({id: 'All layers'})}
              </th>
            </tr>
          </thead>
          <tbody>
            {currentRemoteSensingImage ? (
              currentIsSetSpectra.map((spectraKey, i: number) => (
                <tr key={`remote-sensing-tile-${spectraKey}-${i}`}>
                  <td className="remote-sensing__checkbox">
                    <div>
                      <SelectionControl
                        id={`remote-sensor-one-checkbox-${i}-${spectraKey}`}
                        name={`remote-sensor-one-checkbox-${i}-${spectraKey}`}
                        label=""
                        type="checkbox"
                        checked={remoteSensingLayersSelected[spectraKey].checked}
                        onChange={(val: boolean) => onChangeLayer(spectraKey, val)}
                      />
                    </div>
                  </td>
                  <td className="remote-sensing__link">
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{display: 'inline-block'}}
                      href={
                        spectraKey === 'TCI' || spectraKey === 'NC'
                          ? currentRemoteSensingImage[spectraKey].url
                          : currentRemoteSensingImage[spectraKey].url.replace(
                              '.png',
                              `_singleDate.png`
                            )
                      }
                      title={spectraKey}
                    >
                      <Img
                        alt={'remote-sensing'}
                        wrapperstyle={{
                          border: '1px solid #333',
                          background: '#f1f1f1',
                        }}
                        style={{height: '65px', maxWidth: '90px'}}
                        src={
                          spectraKey === 'TCI' || spectraKey === 'NC'
                            ? currentRemoteSensingImage[spectraKey].url
                            : currentRemoteSensingImage[spectraKey].url.replace(
                                '.png',
                                `_singleDate.png`
                              )
                        }
                      />
                    </a>

                    {/*<div style={{width: '100%', textAlign: 'center'}}>*/}
                    {/*{props.tile[`Tif${spectraKey}`] ? <div>Download</div> : null}*/}
                  </td>
                  <td>
                    <b style={{marginLeft: '10px'}}>{sensorView(spectraKey)}</b>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td>{t({id: 'Spectra not found'})}</td>
              </tr>
            )}
          </tbody>
        </table>

        <div style={{marginTop: '15px', marginLeft: '20px'}}>
          <div>
            <b>
              {currentRemoteSensingImage
                ? `${t({id: 'Date'})}: ` + convertTileDateToNormal(currentRemoteSensingImage.Date)
                : t({id: 'Not Found Date'})}
            </b>
          </div>

          <table style={{marginTop: '30px'}}>
            <thead>
              <tr>
                <th>
                  <b>{t({id: 'File Format'})}:</b>
                </th>
              </tr>
            </thead>
            <tbody>
              {!isHighResolutionImagery ? (
                <>
                  <tr>
                    <td className="remote-sensing__checkbox">
                      <SelectionControl
                        id={`remote-sensor-one-checkbox2`}
                        name={`remote-sensor-one-checkbox2`}
                        label={t({id: 'Geotiff [0,255]'})}
                        type="checkbox"
                        checked={remoteSensingLayersOptions.rangeGrey}
                        onChange={(val: boolean) => onChangeFileFormat('rangeGrey', val)}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td className="remote-sensing__checkbox">
                      <SelectionControl
                        id={`remote-sensor-one-checkbox`}
                        name={`remote-sensor-one-checkbox`}
                        label={t({id: 'Geotiff [-1,1]'})}
                        type="checkbox"
                        checked={remoteSensingLayersOptions.rangeFloat}
                        onChange={(val: boolean) => onChangeFileFormat('rangeFloat', val)}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td className="remote-sensing__checkbox">
                      <SelectionControl
                        id={`remote-sensor-one-checkbox4`}
                        name={`remote-sensor-one-checkbox4`}
                        label="Png"
                        type={'checkbox'}
                        checked={remoteSensingLayersOptions.formatPng}
                        onChange={(val: boolean) => onChangeFileFormat('formatPng', val)}
                      />
                    </td>
                  </tr>
                </>
              ) : (
                <SelectionControlGroup
                  id="selection-control-formats"
                  className={'remote-sensing__radios-group'}
                  name="formats-selection"
                  type="radio"
                  label=""
                  value={remoteSensingLayersOptions.formatGeotiff ? 'formatGeotiff' : 'formatPng'}
                  onChange={(val: keyof AppStore['map']['remoteSensingLayersOptions']) =>
                    onChangeFileFormat(val, true)
                  }
                  controls={[
                    {
                      label: 'Geotiff',
                      value: 'formatGeotiff',
                    },
                    {
                      label: 'Png',
                      value: 'formatPng',
                    },
                  ]}
                />
              )}
            </tbody>
          </table>
        </div>
      </div>

      <div className={'remote-sensing__download-btn-container'}>
        <FluroButton
          id={'download-layers'}
          onClick={downloadGeotiff}
          iconEl={<DownloadIcon />}
          disabled={disableDownloading}
          raised
          primary
        >
          {t({id: 'Download'})}
        </FluroButton>
      </div>
    </FluroDialog>
  );
};

const SatelliteHdAllowedFormats = ['formatPng', 'formatGeotiff'];

export default DownloadImageLayerDialog;
