import React from 'react';
import {useAppSelector} from '_hooks';
import {selectCurrentTab, selectIsWholeFarmView} from 'containers/map/reducer/selectors';
import {TreeZoningFieldsDropdown} from 'containers/map/features/zoning/tree-detection-whole-farm-fields-dropdown';
import SelectorField from './selector-fields';
import {selectIsAddingFields} from 'modules/add-fields/selectors';

type Props = {
  mobileView?: boolean; // used to display data in FluroChips in certain areas (not in the toggle header) for mobiles
};

export const FluroFieldsSelector = ({mobileView}: Props) => {
  const feature = useAppSelector(selectCurrentTab);
  const isWholeFarmView = useAppSelector(selectIsWholeFarmView);
  const farmId = useAppSelector(s => s.global.currentGroupId);
  const isAddingFields = useAppSelector(selectIsAddingFields);

  if (isAddingFields || feature === 'crop-performance' || !farmId) return null;

  return feature === 'zoning' && isWholeFarmView ? (
    <TreeZoningFieldsDropdown mobileView={mobileView} />
  ) : (
    <SelectorField mobileView={mobileView} />
  );
};
