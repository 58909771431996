import {CardBase} from 'components/card/card-base';
import type {ComponentType} from 'react';
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import './tillage-card.scss';
import {SelectionControlGroup, MenuButton, FontIcon} from 'react-md';
import {useAppSelector} from '_hooks';
import {
  selectTillageChartTab,
  selectIsSIDataLoading,
  selectIsYearsDiff,
  selectSelectedTillagePracticeLabel,
  selectSelectedTillagePractice,
} from 'modules/sustainability-insights/selectors';
import {TillageSummaryChart} from './tillage-summary-chart';
import {Flex, FluroTabs, Text} from 'components';
import {YearsFilterRangeLabel} from 'containers/map/features/sustainability-insights/years-filter-range-label';
import {TillageTrendChart} from './tillage-trend-chart';
import {useDispatch} from 'react-redux';
import type {SIChartTab, TillagePractice} from 'modules/sustainability-insights/types';
import {SIGeometryFilterType} from 'modules/sustainability-insights/types';
import {
  setChartTab,
  setFilter,
  setSelectedTillagePractice,
} from 'modules/sustainability-insights/actions';
import {tillagePracticeMenuItems} from 'modules/sustainability-insights/constants';
import {CardToolTip} from '../components/card-tooltip';
import {TOOLTIPS} from 'containers/si/constants';

type Props = {isActive?: boolean};

const InnerTillageCard = () => {
  const dispatch = useDispatch();
  const selectedTab = useAppSelector(selectTillageChartTab);
  const isRange = useAppSelector(selectIsYearsDiff);

  const setSelectedTab = (tab: SIChartTab) => {
    dispatch(setChartTab(SIGeometryFilterType.Tillage, tab));
  };

  useEffect(() => {
    if (!isRange && selectedTab === 'trend') {
      setSelectedTab('summary');
    }
    // setSelectedTab should not trigger the re-render
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRange, selectedTab]);

  const tabs = [
    {value: 'summary', label: 'Summary'},
    {value: 'trend', label: 'Trend'},
  ];

  return (
    <>
      {isRange && (
        <div style={{marginLeft: '-1rem', marginRight: '-1rem'}}>
          <FluroTabs tabs={tabs} selectedTab={selectedTab} onTabClick={setSelectedTab} />
        </div>
      )}
      <div>
        {selectedTab === 'summary' && <TillageSummaryChart />}
        {selectedTab === 'trend' && <TillageTrendChart />}
      </div>
    </>
  );
};

export const TillageCard: ComponentType<Props> = ({isActive = false}) => {
  const isLoading = useAppSelector(selectIsSIDataLoading);
  const selectedPracticeLabel = useAppSelector(selectSelectedTillagePracticeLabel);
  const selectedPractice = useAppSelector(selectSelectedTillagePractice);
  const [selectPracticeMenuOpen, setSelectPracticeMenuOpen] = useState(false);

  const dispatch = useDispatch();

  const onCardClick = () => {
    if (isActive || isLoading) return;
    dispatch(
      setFilter({
        activeGeometryType: SIGeometryFilterType.Tillage,
      })
    );
  };

  const onChangeSelectedPractice = useCallback((practice: TillagePractice) => {
    dispatch(setSelectedTillagePractice(practice));
    setSelectPracticeMenuOpen(false); // close the menu
  }, []);

  const menuItems = useMemo(() => {
    return (
      <SelectionControlGroup
        id="select-abatement-potential-metric"
        className={'select-abatement-potential-metric'}
        name="radio"
        type="radio"
        label=""
        onChange={(value: any) => onChangeSelectedPractice(value as TillagePractice)}
        value={selectedPractice}
        controls={tillagePracticeMenuItems}
      />
    );
  }, [selectedPractice]);

  return (
    <>
      <CardBase
        active={isActive}
        onClick={onCardClick}
        className={'sustainability-card tillage-card'}
        heading={
          <Flex nowrap justifyContent="space-between" alignItems={'center'}>
            <Flex alignItems="center" className="heading-with-ellipsis">
              <MenuButton
                visible={selectPracticeMenuOpen}
                onVisibilityChange={setSelectPracticeMenuOpen}
                id={'select-tillage-practice'}
                icon
                menuItems={menuItems}
                position="tl"
              >
                <FontIcon>more_vert</FontIcon>
              </MenuButton>
              <Text className="m-0" elementType="h3">
                {selectedPracticeLabel} <YearsFilterRangeLabel />
              </Text>
            </Flex>
            <CardToolTip id="tillage-info" content={TOOLTIPS.KPI.tillage} place="left" />
          </Flex>
        }
      >
        {isActive && <InnerTillageCard />}
      </CardBase>
    </>
  );
};
