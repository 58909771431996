import {DialogType} from 'modules/helpers/types';
import {selectDialogVisibility} from 'modules/helpers/selectors';
import {createSelector} from 'reselect';
import type {AppStore} from 'reducers';
import {addingFieldsPreviewSteps, isAddingFieldsStep} from './base';

export function selectAddingFieldsStep(store: AppStore) {
  return store.addFields.addFieldCurrentStep;
}

export const selectIsAddingFields = createSelector(
  [s => selectDialogVisibility(s, DialogType.addNewField), selectAddingFieldsStep],
  (dialogIsVisible, currentStep) => dialogIsVisible && isAddingFieldsStep(currentStep)
);

export const selectIsPreviewingAddingFields = createSelector([selectAddingFieldsStep], step =>
  addingFieldsPreviewSteps.includes(step)
);

export const selectCLUFieldBoundaries = (s: AppStore) => s.addFields.cluFieldBoundaries;

export const selectCLUFieldBoundariesIds = createSelector([selectCLUFieldBoundaries], boundaries =>
  Object.keys(boundaries)
);

export const selectCLUFieldBoundariesList = createSelector([selectCLUFieldBoundaries], boundaries =>
  Object.values(boundaries)
);

export const selectBoundaryIdsToUpload = (s: AppStore) => s.addFields.boundaryIdsToUpload;

export const selectNotUploadedFields = (s: AppStore) => s.addFields.notUploadedFields;

export const selectFarmsFieldsAddedTo = (s: AppStore) => s.addFields.farmsFieldsAddedTo;

export const selectUploadedFieldsNumber = (s: AppStore) => s.addFields.uploadedFieldsNumber;

export const selectBoundaryIdsToUploadMap = createSelector(
  [selectBoundaryIdsToUpload],
  (ids: string[]) => {
    const boundaryIdsToUpload: {[id: string]: boolean} = {};
    ids.forEach(id => {
      boundaryIdsToUpload[id] = true;
    });
    return boundaryIdsToUpload;
  }
);

export const selectBoundariesToUpload = createSelector(
  [selectCLUFieldBoundaries, selectBoundaryIdsToUpload],
  (boundaries, toUpload) => toUpload.map(id => boundaries[id])
);

export const selectProtectedAreaBoundaries = (s: AppStore) => s.addFields.protectedAreaBoundaries;

export const selectGeoJsonFiles = (s: AppStore) => s.addFields.geoJsonFiles;
