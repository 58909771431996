import React, {useState} from 'react';
import {Flex, FluroButton, FluroDialog, Text} from 'components';
import {TextField} from 'react-md';

export const ReportURLPopup = ({
  url,
  onSave,
  onHide,
  portal = true,
}: {
  url: string;
  onSave: (url: string) => void;
  onHide: () => void;
  portal?: boolean;
}) => {
  const [newURL, setNewURL] = useState(url);

  return (
    <FluroDialog
      id={'report-url-popup'}
      title={'Report URL'}
      onHide={onHide}
      visible={true}
      width={400}
      portal={portal}
    >
      <Flex direction="column">
        <Text secondary>Report URL</Text>
        <TextField
          type="text"
          id="report-url"
          name="report-name"
          value={newURL}
          className="mt-0"
          onChange={p => setNewURL(String(p))}
        />
      </Flex>
      <Flex justifyContent="space-between" className="mt-2">
        <FluroButton raised blank onClick={onHide}>
          Cancel
        </FluroButton>
        <FluroButton raised primary onClick={() => onSave(newURL)}>
          Save
        </FluroButton>
      </Flex>
    </FluroDialog>
  );
};
