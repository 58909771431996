import {Text} from 'components';
import React from 'react';
import styled from 'styled-components';

export const ReportTileHeader = ({
  titleText,
  startIcon,
}: {
  titleText: string;
  startIcon?: React.ReactElement;
}) => (
  <ReportTileHeaderWrapper>
    {startIcon}
    <Text variant={'h3'} bold noMargin>
      {titleText}
    </Text>
  </ReportTileHeaderWrapper>
);

export const ReportTile = styled.div`
  border-radius: 8px;
  border: ${({theme}) => `1px solid ${theme.colorPalette.fs_main.gray_300}`};
  background-color: ${({theme}) => theme.color.surface.main};
  display: flex;
  flex-direction: column;
  padding: 12px 15px;
  gap: 10px;
`;

const ReportTileHeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;
