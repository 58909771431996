import React, {useEffect, useState} from 'react';
import {Flex, FluroButton, FluroDialog, Text} from 'components';
import {useAppDispatch, useAppSelector} from '_hooks';
import {useMonitoringUrlParams} from '../monitoring/hooks';
import {selectMonitoringReadOnly, selectPhase} from '../monitoring/module/selectors';
import {selectEnrollmentReadOnly} from '../enrollment/carbon-store/selectors';
import moment from 'moment';
import {GLOBAL_FORMAT_DATE} from '../../../_constants';
import {MRVPhasesDateFormat} from '../constants';
import {MRVPhaseType, PhaseReadOnlyReason} from '../types';
import './mrv-next-phase-dialog.scss';
import {Circle} from 'components/shapes/circle';
import {EnrollmentIcon, MonitoringIcon} from '../icons';
import colors from 'variables.scss';
import {t} from 'i18n-utils';
import {logout} from 'containers/login/actions';

export const MRVNextPhaseDialog = () => {
  const dispatch = useAppDispatch();
  const {isMRVHomePage} = useMonitoringUrlParams();
  const monitoring = useAppSelector(s => selectPhase(s, MRVPhaseType.Monitoring));

  const {isReadOnly: enrollmentReadOnly, reason: enrollmentReason} =
    useAppSelector(selectEnrollmentReadOnly);
  const {isReadOnly: monitoringReadOnly, reason: monitoringReason} =
    useAppSelector(selectMonitoringReadOnly);

  const [showPopup, setShowPopup] = useState(false);

  useEffect(() => {
    if (
      enrollmentReadOnly &&
      enrollmentReason === PhaseReadOnlyReason.ContractIsSigned &&
      monitoringReadOnly &&
      monitoringReason === PhaseReadOnlyReason.TooEarly
    ) {
      setShowPopup(true);
    }
  }, [enrollmentReason, monitoringReason]);

  const onHide = (withLogout?: boolean) => {
    withLogout && dispatch(logout());
    setShowPopup(false);
  };

  const date = moment(monitoring?.start_date, GLOBAL_FORMAT_DATE).format(MRVPhasesDateFormat);

  return (
    <FluroDialog
      id={'mrv-next-phase-dialog'}
      title={
        <div className="phases-items">
          <Flex className="phases-item" justifyContent="space-between">
            <Flex gap="5px" alignItems="center">
              <Circle className="crop-icon" color={colors['main-green-600']}>
                <EnrollmentIcon />
              </Circle>
              <span>{t({id: 'EnrollmentStepName.Enrollment', defaultMessage: 'Enrollment'})}</span>
            </Flex>

            <div className="program-stage-item active phase-dates">
              {t({id: 'Complete', defaultMessage: 'Complete'})}
            </div>
          </Flex>
          <Flex className="phases-item" justifyContent="space-between">
            <Flex gap="5px" alignItems="center">
              <Circle className="crop-icon" color={colors['main-gray-200']}>
                <MonitoringIcon fill={colors['main-gray-600']} />
              </Circle>
              <span>
                {t({id: 'MonitoringStageStep.Measurement', defaultMessage: 'Measurement'})}
              </span>
            </Flex>

            <div className="program-stage-item disabled">
              {t({id: 'Measurement.Open date', defaultMessage: 'Open {date}'}, {date})}
            </div>
          </Flex>
        </div>
      }
      onHide={onHide}
      visible={showPopup && isMRVHomePage}
      width={450}
      portal={true}
      className="mrv-next-phase-dialog"
    >
      <div className="content-wrapper">
        <h3>
          {t({id: 'Enrollment phase complete.', defaultMessage: 'Enrollment phase complete.'})}
        </h3>
        <Text>
          {t(
            {
              id: 'EnrollmentCongratulationsMessage',
              defaultMessage:
                'Congratulations! You have successfully completed the enrollment phase. The Measurement Phase begins {date}.',
            },
            {date}
          )}
        </Text>
        <Flex justifyContent="flex-end" className="mrv-next-phase-controls">
          <FluroButton primary raised onClick={() => onHide(true)}>
            {t({id: 'Logout', defaultMessage: 'Logout'})}
          </FluroButton>
        </Flex>
      </div>
    </FluroDialog>
  );
};
