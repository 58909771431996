import {Flex, Text} from 'components';
import type {ComponentType} from 'react';
import React from 'react';

type Props = {
  data: any[];
};

export const TrendChartLegend: ComponentType<Props> = ({data}) => {
  return (
    <Flex justifyContent="center">
      {data.map(({color, name}) => {
        return (
          <Flex
            key={color + name}
            className="trend-chart-legend__item"
            gap={'5px'}
            alignItems="center"
          >
            <span className="trend-chart-legend__dot" style={{backgroundColor: color}} />
            <Text elementType="span" variant="small" className="m-0">
              {name}
            </Text>
          </Flex>
        );
      })}
    </Flex>
  );
};
