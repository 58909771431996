// @ts-nocheck
import React, {useCallback} from 'react';
import {UploadFileZone} from 'components';
import {parseGeometryFile} from '../../../utils/file';
import {t} from 'i18n-utils';
import {reportError} from 'containers/error-boundary';
import type {FeatureCollectionWithFilename} from 'shpjs';
import {
  separateGeometry,
  PLAIN_SEASON_PROPS,
  geojsonhintExeptions,
  getNonPolygonMessage,
} from '_utils/geometry';
import {normalizeFirstLastPointOfGeometry, getRandomColor} from '_utils';
import {genKey} from '_utils/pure-utils';
import {useDispatch} from 'react-redux';
import {showNotification} from 'components/notification/notification';
//@ts-expect-error error leftover from convertion to strict mode, please fix
import geojsonhint from '@mapbox/geojsonhint';
import {setGeometry} from './planting-area-season.module';

export const PlantingAreaUploadFields = () => {
  const dispatch = useDispatch();

  const onChangeFiles = useCallback(async (files: File[]) => {
    const result = await Promise.all(files.map(f => parseGeometryFile(f)));

    const geoJSONs: any = [];
    const nonpolygons: string[] = [];

    const featureCollections = result.reduce((acc, r) => {
      return [...acc, ...(Array.isArray(r.features) ? r.features : [r.features])];
    }, []);

    featureCollections.forEach(gj => {
      if (!gj) {
        return;
      }

      try {
        if (Array.isArray(gj)) {
          gj.forEach(handleFeatureCollection);
        } else {
          handleFeatureCollection(gj);
        }
      } catch (e) {}
    });

    const parsingErrors = result.reduce((acc, r) => [...acc, ...r.errors], []);

    parsingErrors.forEach(e => {
      showNotification({
        title: t({id: 'note.warning', defaultMessage: 'Warning'}),
        message: e,
        type: 'warning',
      });
    });

    if (nonpolygons.length) {
      const {error, note} = getNonPolygonMessage(nonpolygons, 'planting areas');
      reportError(error);
      showNotification({
        title: t({id: 'note.warning', defaultMessage: 'Warning'}),
        message: note,
        type: 'warning',
      });
    }

    function handleFeatureCollection(featureCollection: FeatureCollectionWithFilename) {
      const {allowedGeometries, notAllowedGeometries} = separateGeometry(
        featureCollection.features
      );

      if (notAllowedGeometries.length) {
        nonpolygons.push(...notAllowedGeometries);
      }

      allowedGeometries.forEach(feature => {
        normalizeFirstLastPointOfGeometry(feature);

        const arrayIssues = geojsonhint
          .hint(feature)
          .filter((err: any) => !geojsonhintExeptions.find(exept => exept === err.message));
        if (arrayIssues.length) {
          return showNotification({
            title: t({id: 'note.error', defaultMessage: 'Error'}),
            message: t({id: 'KML error, file unable to be uploaded.'}),
            type: 'error',
          });
        }

        geoJSONs.push(feature);
      });
    }

    /*
     *
     * Set Result
     *
     * */

    geoJSONs.forEach((plgn: GeoJSON.Feature) => {
      plgn.properties = {
        ...PLAIN_SEASON_PROPS,
        id: genKey(),
        name: 'Geometry',
        fill: getRandomColor(),
      };

      dispatch(setGeometry(plgn));
    });
  }, []);

  return (
    <div style={{marginTop: 25}}>
      <UploadFileZone
        id="map-upload-planting-areas"
        accept={'.kml,.kmz,.zip'}
        multiple
        onChange={onChangeFiles}
        disabled={false}
        isUploading={false}
      />
    </div>
  );
};
