import * as React from 'react';
import type {PropsWithChildren} from 'react';
import {useState} from 'react';
import cn from 'classnames';
import {FontIcon} from 'react-md';
// Moved from css to styled component here. Let's give it a week or two until removing the css file.

import {
  CardStyled,
  CardTitle,
  CardTitleText,
  CollapseButton,
  Controls,
  HelpButton,
  CardSubtitle,
  ResetFilterButton,
  CardInfo,
} from './card-styled';

export const Card = ({
  children,
  active,
  title,
  subtitle,
  resetFilterDisabled = true,
  info,
  onClick,
  onFilterClear,
  isReportView = false,
}: PropsWithChildren<{
  active: boolean;
  title: string;
  subtitle?: string;
  resetFilterDisabled?: boolean;
  info?: JSX.Element;
  onClick: () => void;
  onFilterClear?: () => void;
  isReportView?: boolean;
}>) => {
  const [infoExpanded, setInfoExpanded] = useState(false);
  return (
    <CardStyled onClick={onClick} className={cn({card: true, active: active})}>
      <CardTitle className="card-title">
        <CardTitleText>
          {title}
          {subtitle && <CardSubtitle>({subtitle})</CardSubtitle>}
        </CardTitleText>
        {!isReportView && (
          <Controls className="controls">
            {info && (
              <HelpButton className="info-btn" onClick={() => setInfoExpanded(e => !e)}>
                help_outline
              </HelpButton>
            )}
            {onFilterClear && (
              <ResetFilterButton
                className={'clear-filter-btn'}
                disabled={resetFilterDisabled || !onFilterClear}
                onClick={e => {
                  e.stopPropagation();
                  onFilterClear();
                }}
              >
                <img src="/assets/filter.svg" alt="filter" title="Clear filters" width="20" />
              </ResetFilterButton>
            )}
          </Controls>
        )}
      </CardTitle>
      <CardInfo>
        {infoExpanded && (
          <>
            {info}
            <CollapseButton className="collapse-btn" onClick={() => setInfoExpanded(e => !e)}>
              <FontIcon>keyboard_arrow_up</FontIcon>
            </CollapseButton>
          </>
        )}
      </CardInfo>
      {children}
    </CardStyled>
  );
};
