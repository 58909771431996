import React, {useCallback} from 'react';
import type {ComponentType} from 'react';
import {WHOLE_TABLE_VIEW_FEATURES} from '_constants';
import {useAppSelector, useAppDispatch} from '_hooks';
import {selectCurrentTab, selectIsEditingMode, selectIsMapBarOpen} from '../reducer/selectors';
import {useMapBarView} from 'containers/map/map-bar/map-bar-view';
import Mixpanel from '_utils/mixpanel-utils';
import {toggleDrawingMode, toggleTableView, toggleMapBar} from '../actions';
import styled, {useTheme} from 'styled-components';
import {CollapseTableViewIcon, ExpandTableViewIcon} from 'components/fluro-icons';
import {DialogType} from 'modules/helpers';
import {selectDialogVisibility} from '../../../modules/helpers/selectors';
import {Icon} from '@regrow-internal/design-system';
import {FluroButton} from 'components';
import {useWorkspace} from '_hooks/use-workspace';

export const MapBarToggle: ComponentType<{hideTableToggle: boolean}> = ({hideTableToggle}) => {
  return (
    <TogglePanel>
      <TableViewButton hideTableToggle={hideTableToggle} />
      <MapBarMinimizeBtn />
    </TogglePanel>
  );
};

const TableViewButton: ComponentType<{hideTableToggle: boolean}> = ({hideTableToggle}) => {
  const dispatch = useAppDispatch();
  const [wholeTableView] = useMapBarView();
  const feature = useAppSelector(selectCurrentTab);
  const {workspace} = useWorkspace();
  const theme = useTheme();

  const showExpandButton = workspace !== 'mrv' && WHOLE_TABLE_VIEW_FEATURES.includes(feature);

  const onToggleTableView = useCallback(() => {
    const value = wholeTableView ? undefined : feature;

    if (value) {
      Mixpanel.openExpandedView(value);
    }
    dispatch(toggleTableView(value));
    dispatch(toggleDrawingMode(false, 'marker'));
  }, [dispatch, feature, wholeTableView]);

  if (!showExpandButton || hideTableToggle) return null;

  return (
    <FluroButton className={'round-button'} icon onClick={onToggleTableView}>
      {wholeTableView ? (
        <CollapseTableViewIcon size={32} style={{color: theme.color.text.secondary}} />
      ) : (
        <ExpandTableViewIcon size={32} style={{color: theme.color.text.secondary}} />
      )}
    </FluroButton>
  );
};

export const MapBarMinimizeBtn: ComponentType<{openMapBar?: boolean}> = ({openMapBar = false}) => {
  const dispatch = useAppDispatch();
  const isMapBarOpen = useAppSelector(selectIsMapBarOpen);
  const isAddingFields = useAppSelector(s => selectDialogVisibility(s, DialogType.addNewField));
  const carbonSuccessPopUp = useAppSelector(s =>
    selectDialogVisibility(s, DialogType.carbonSuccessfulSigned)
  );
  const isEditingMode = useAppSelector(selectIsEditingMode);
  const [wholeTableView] = useMapBarView();
  const theme = useTheme();

  const toggleBar = useCallback(() => dispatch(toggleMapBar(!isMapBarOpen)), [isMapBarOpen]);

  if (carbonSuccessPopUp || wholeTableView || isAddingFields || isEditingMode) return null;

  return (
    <FluroButton
      className={openMapBar ? 'show-right-panel-btn' : 'round-button'}
      onClick={toggleBar}
      icon={!openMapBar}
      raised={openMapBar}
    >
      <Icon
        size={32}
        color={theme.color.text.secondary}
        type={isMapBarOpen ? 'chevron-up' : 'chevron-down'}
      />
    </FluroButton>
  );
};

const TogglePanel = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 12px;
  height: 38px;
  min-height: 38px;
  padding: 0 16px;
  background-color: #fff;
  position: sticky;
  top: 0;
  z-index: 1;
  &:not(:empty) {
    border-bottom: 1px solid ${({theme}) => theme.color.surface.secondary};
  }
  &:empty {
    display: none;
  }

  .round-button {
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
  }
`;
