import cn from 'classnames';
import type {PopperProps} from 'react-popper';
import {usePopper} from 'react-popper';
import {Sticky} from 'components/sticky/sticky';
import {useStickyHeadings} from 'components/sticky/useStickyHeadings';
import {Flex} from 'components/flex';
import type {
  ComponentType,
  CSSProperties,
  FunctionComponent,
  MouseEvent,
  ReactElement,
  ReactNode,
} from 'react';
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {FontIcon, TextField} from 'react-md';
import {Waypoint} from 'react-waypoint';
import {capitalizeFirstLetterOfEachWord, genKey} from '_utils/pure-utils';
import {getNodeText, getScrollParent, onClickOutsideAndEsc} from '_utils/utils2';
import {Portal} from '../fluro-dialog/portal';
import './fluro-select-lite.scss';
import {CropAvatarLite} from 'components/crop-avatar/crop-avatar-lite';

export type FluroSelectLiteProps = {
  id?: string;
  items: FluroSelectLiteItem[];
  fixedToTopItems?: FluroSelectLiteItem[];
  selectedValue: FluroSelectLiteItem['value'];
  placeholder?: string;
  subtitle?: ReactNode;
  Button?: FunctionComponent<{onClick: () => void}>;
  onSelect?: (
    value: FluroSelectLiteItem['value'],
    category?: FluroSelectLiteItem['category']
  ) => void;
  isSearchable?: boolean;
  searchPlaceholderText?: string;
  disabled?: boolean;
  autoWidth?: boolean;
  helpComponent?: ReactElement;
  selectedItemComponent?: {value: any; label: ReactNode};
  label?: string;
  className?: string;
  /**
   * Remove side padding from the main component's selected item.
   */
  noSidePadding?: boolean;
  /**
   * Style for the nested FluroSelectLiteItemView component.
   */
  itemStyle?: CSSProperties;
  selectedItemAutoWidth?: boolean;
  error?: boolean;
  errorText?: string;
  onClose?: () => void;
  /**
   * Sometimes we receive unexpected values from Optis or FMS.
   * Setting this as `true` allows us to go with the value
   * received rather than force the user to select a different option.
   */
  allowUnexpectedValue?: boolean;
  paginated?: boolean;
  placement?: PopperProps<{}>['placement'];
  nowrap?: boolean;
  noPadding?: boolean;
  hasBorder?: boolean;
  testId?: string;
};

export const FluroSelectLite: ComponentType<FluroSelectLiteProps> = ({
  id: idProp,
  testId,
  items,
  fixedToTopItems = [],
  selectedValue,
  selectedItemComponent,
  placeholder,
  subtitle,
  Button,
  onSelect,
  isSearchable = false,
  searchPlaceholderText = 'Search',
  disabled,
  autoWidth,
  helpComponent,
  label,
  className,
  noSidePadding,
  itemStyle,
  selectedItemAutoWidth,
  error,
  errorText,
  onClose,
  allowUnexpectedValue,
  paginated,
  placement = 'top',
  nowrap,
  noPadding,
  hasBorder,
}) => {
  const id = useMemo(() => idProp || genKey(), [idProp]);
  const [referenceElement, setReferenceElement] = useState<HTMLDivElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null);
  const [expanded, setExpanded] = useState(false);
  const [searchText, setSearchText] = useState<string>('');
  const [pagination, setPagination] = useState(50);
  const {getHeadingIsStuck, updateStuckHeadings} = useStickyHeadings();
  const {styles, attributes} = usePopper(referenceElement, popperElement, {placement});

  const close = useCallback(() => {
    if (expanded) {
      setExpanded(false);
      onClose?.();
    }
  }, [expanded, onClose]);

  // TODO combine these two useEffects (they use the same deps array)
  useEffect(() => {
    if (paginated) {
      setPagination(50);
    }
    return onClickOutsideAndEsc([popperElement, referenceElement], expanded, close);
  }, [close, expanded, paginated, popperElement, referenceElement]);

  useEffect(() => {
    const parentElement = referenceElement?.parentElement;
    const scrollableParent = getScrollParent(parentElement);

    if (!scrollableParent || !expanded) {
      return;
    }

    scrollableParent.addEventListener('scroll', close);

    return () => {
      scrollableParent.removeEventListener('scroll', close);
    };
  }, [expanded, close, referenceElement?.parentElement]);

  const onSelectItem = (
    value: FluroSelectLiteItem['value'],
    category: FluroSelectLiteItem['category']
  ) => {
    onSelect?.(value, category);
    close();
  };

  const placeholderItem: FluroSelectLiteItem =
    allowUnexpectedValue && typeof selectedValue === 'string'
      ? {
          value: selectedValue,
          label: capitalizeFirstLetterOfEachWord(selectedValue.replace(/[-_]/g, ' ')),
          icon: <CropAvatarLite cropType={selectedValue} />,
        }
      : {
          value: 'unselected',
          label: placeholder,
        };

  const menuItems = useMemo(
    () => items.filter(item => item.value !== selectedValue),
    [items, selectedValue]
  );

  const filteredMenuItems = useMemo(() => {
    let filteredItems: FluroSelectLiteItem[] = [];

    if (isSearchable && searchText) {
      const query = searchText.toLowerCase();

      filteredItems = menuItems
        .filter(item => {
          const itemLabel =
            typeof item.label === 'string' ? item.label.toLowerCase() : getNodeText(item.label);

          // always display category headings
          return item.isHeading || itemLabel.includes(query);
        })
        // unless there are no items within that category
        .filter((item, _, arr) => !item.isHeading || arr.some(i => i.category === item.value));

      if (paginated) {
        filteredItems = filteredItems.slice(0, pagination);
      }
    } else {
      filteredItems = paginated
        ? [...fixedToTopItems, ...menuItems.slice(0, pagination)]
        : [...fixedToTopItems, ...menuItems];
    }

    return filteredItems;
  }, [fixedToTopItems, isSearchable, menuItems, paginated, pagination, searchText]);

  const isDisabled = disabled || (menuItems.length === 0 && filteredMenuItems.length === 0);

  const loadMore = () => {
    setPagination(state => state + 50);
  };

  const selectedItem = items.find(item => item.value === selectedValue);

  return (
    <div
      ref={setReferenceElement}
      className={cn(className, 'fluro-select-lite', {isDisabled, autoWidth})}
      data-testid={testId}
    >
      <div className="selected-item">
        {Button ? (
          <Button onClick={() => !isDisabled && setExpanded(e => !e)} />
        ) : (
          <FluroSelectLiteItemView
            style={itemStyle}
            noSidePadding={noSidePadding}
            selectedItemAutoWidth={selectedItemAutoWidth}
            item={selectedItemComponent || selectedItem || placeholderItem}
            label={label}
            subtitle={subtitle}
            rightIcon={
              helpComponent ? (
                <div className="right-icon-block">
                  {helpComponent} <FontIcon>arrow_drop_down</FontIcon>
                </div>
              ) : (
                <FontIcon className="right-icon">arrow_drop_down</FontIcon>
              )
            }
            disabled={isDisabled}
            onClick={() => !isDisabled && setExpanded(e => !e)}
            nowrap={nowrap}
            hasBorder={hasBorder}
            noPadding={noPadding}
          />
        )}
      </div>
      {expanded && (
        <Portal id="fluro-select-lite-portal">
          <div
            ref={setPopperElement}
            style={styles.popper}
            {...attributes.popper}
            className="list-container"
          >
            {isSearchable && menuItems.length > 5 && (
              <div className="fluro-select-lite__search-container">
                <TextField
                  id={`search-input-${id}`}
                  placeholder={searchPlaceholderText}
                  value={searchText}
                  onChange={v => setSearchText(String(v))}
                  inlineIndicator={
                    <FontIcon className="clear-search-btn" onClick={() => setSearchText('')}>
                      clear
                    </FontIcon>
                  }
                />
              </div>
            )}
            <div className="list">
              {filteredMenuItems.map((item, i) => (
                <FluroSelectLiteItemView
                  // Some items can have the same value but a different category,
                  // preventing search from working correctly (unmanaged react keys).
                  key={item.category ? `${item.value}-${item.category}-${i}` : `${item.value}-${i}`}
                  item={item}
                  onClick={(v, e, c) => {
                    e.stopPropagation();
                    if (item.customSelectFunction) {
                      item.customSelectFunction();
                    } else {
                      onSelectItem(v, c);
                    }
                  }}
                  stuck={getHeadingIsStuck(i)}
                  onStuck={stuck => updateStuckHeadings(stuck, i)}
                  nowrap={nowrap}
                />
              ))}
              {paginated && menuItems.length > pagination && (
                <Waypoint key="lazy-load" onEnter={loadMore} />
              )}
            </div>
          </div>
        </Portal>
      )}

      {error && errorText ? (
        <div className="md-text md-text-field-message md-text--error">{errorText}</div>
      ) : null}
    </div>
  );
};

export type FluroSelectLiteItem<V = any, C = string> = {
  /**
   * TODO make generic, or even better make it always a string
   */
  value: V;
  label: ReactNode;
  icon?: string | JSX.Element;
  customSelectFunction?: () => void;
  /**
   * This item is a Category heading, and sticks to the top of the list until replaced by the next Heading.
   */
  isHeading?: boolean;
  category?: C;
};

export const FluroSelectLiteItemView = ({
  item,
  subtitle,
  rightIcon,
  disabled,
  stuck,
  onClick,
  onStuck,
  label,
  noSidePadding,
  selectedItemAutoWidth,
  style,
  nowrap,
  noPadding,
  hasBorder,
}: {
  item: FluroSelectLiteItem;
  subtitle?: ReactNode;
  rightIcon?: JSX.Element;
  disabled?: boolean;
  label?: string;
  stuck?: boolean;
  onClick?: (
    value: FluroSelectLiteItem['value'],
    e: MouseEvent,
    category?: FluroSelectLiteItem['category']
  ) => void;
  onStuck?: (stuck: boolean) => void;
  noSidePadding?: boolean;
  selectedItemAutoWidth?: boolean;
  style?: CSSProperties;
  nowrap?: boolean;
  noPadding?: boolean;
  hasBorder?: boolean;
}) => {
  const className = cn('fluro-select-lite-item', {
    disabled,
    'has-top-label': label,
    'no-side-padding': noSidePadding,
    'width-auto': selectedItemAutoWidth,
    heading: item.isHeading,
    'no-padding': noPadding,
  });

  const content = (
    <>
      {label && <div className="top-label">{label}</div>}

      <Flex
        alignItems="center"
        justifyContent="space-between"
        className={cn('item-title', {
          'has-border': hasBorder,
        })}
        nowrap={nowrap}
      >
        <div className="main">
          {item.icon || <span />}
          <div className="label">
            <span className={cn('input', {'title--heading': item.isHeading})}>{item.label}</span>
            {subtitle && <span className="subtitle">{subtitle}</span>}
          </div>
        </div>

        {rightIcon}
      </Flex>
    </>
  );

  if (item.isHeading) {
    return (
      <Sticky style={style} className={className} position="top" stuck={stuck} onStuck={onStuck}>
        {content}
      </Sticky>
    );
  }

  return (
    <div style={style} className={className} onClick={e => onClick?.(item.value, e, item.category)}>
      {content}
    </div>
  );
};

export const ColoredCircle = ({color}: {color: string}) => {
  return <div className="colored-circle" style={{backgroundColor: color}} />;
};
