import type {AppStore} from '../reducers';

enum ActionType {
  SaveImage = 'images-cache/SaveImage',
  RemoveImage = 'images-cache/RemoveImage',
}

type ImageItem = {
  data: string;
  meta: any;
};

export type ImagesCacheStore = {
  data: {[key: string]: ImageItem};
};

/*
 *
 * key is image url
 * data is base64 image
 *
 * */

export const saveImage = (key: string, data: string, meta?: any) => ({
  type: ActionType.SaveImage,
  key,
  data,
  meta,
});

export const removeImage = (key: string) => ({
  type: ActionType.RemoveImage,
  key,
});

const initialState: ImagesCacheStore = {
  data: {},
};

export const selectImageCache = (s: AppStore) => s.imagesCache;

export const reducer = (state = initialState, action: any) => {
  switch (action.type) {
    case ActionType.SaveImage: {
      return {
        ...state,
        data: {...state.data, [action.key]: {data: action.data, meta: action.meta}},
      };
    }

    case ActionType.RemoveImage: {
      const data = {...state.data};

      delete data[action.key];

      return {
        ...state,
        data,
      };
    }

    default:
      return state;
  }
};
