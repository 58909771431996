// @ts-nocheck
import {t} from 'i18n-utils';
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {SelectField, Button} from 'react-md';
import {genKey} from '_utils/pure-utils';
import {createParsedPropertiesMap} from '_utils/farm-utils';
import {useDispatch} from 'react-redux';
import {FieldSystemProp} from '../types';
import {
  setFieldPropsMapping,
  setFieldPropMapping,
  convertPropertyValue,
  setAddFieldCurrentStep,
  bulkSetUploadFieldsProp,
  setUploadFields,
  setUploadedFieldFilesNames,
  setFieldsValueFromMappingProps,
  selectAddFieldsPropsMapping,
  selectUploadedFieldFilesNames,
} from 'modules/add-fields';
import {
  PropSelectWrapper,
  MappingTableWrapper,
  ParsedDataTableWrapper,
} from './field-properties-parser.styled';

import {
  FluroButton,
  FluroDataTable,
  FluroTableBody,
  FluroTableColumn,
  FluroTableHeader,
  FluroTableRow,
  Flex,
  InfoBlock,
  Text,
  FluroSelect,
} from 'components';
import type {Farm} from '../../../../types';

import {useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import {farmNameSchema} from '../assign-farms-dialog';
import {useAppSelector} from '_hooks';
import {selectFarmsList} from 'modules/farms/selectors';
import './field-properties-parser.scss';
import {selectCurrentFarmId} from '../../../../reducer/selectors';
import {selectGeoJsonFiles} from 'modules/add-fields/selectors';

const FieldPropertiesParser = () => {
  const id = useMemo(() => genKey(), []);
  const dispatch = useDispatch();

  const {} = useForm<{
    farmName: string;
  }>({
    resolver: yupResolver(farmNameSchema),
    mode: 'onChange',
    defaultValues: {farmName: ''},
  });

  const geoJSONs = useAppSelector(selectGeoJsonFiles);
  const farmId = useAppSelector(selectCurrentFarmId);
  const farms = useAppSelector(selectFarmsList);
  const propsMap = useAppSelector(selectAddFieldsPropsMapping);
  const uploadedFileNames = useAppSelector(selectUploadedFieldFilesNames);

  const onClearUpload = () => {
    dispatch(setUploadFields([]));
    dispatch(setUploadedFieldFilesNames([]));
    dispatch(setAddFieldCurrentStep('select-files-to-upload'));
  };

  const currentFarm = useMemo(
    () => farms.filter((f: Farm) => !f.readOnly).find(f => f.id === farmId),
    [farmId, farms]
  );

  const [fieldParseIndex, setFieldParseIndex] = useState(0);

  const [previewRowsNumber, setPreviewRowsNumber] = useState(3);

  useEffect(() => {
    setPreviewRowsNumber(Math.min(3, geoJSONs.length - 1));
  }, [geoJSONs.length]);

  const previewRowsNumberItems = useMemo(() => {
    const items = [];
    for (let i = 1; i <= geoJSONs.length; i++) {
      items.push({value: i, label: t({id: '{count} row(s)'}, {count: i})});
    }

    return items;
  }, [geoJSONs]);

  const properties = useMemo(() => {
    return geoJSONs[fieldParseIndex]?.properties || {};
  }, [geoJSONs.length, fieldParseIndex]);

  const propKeys = useMemo(
    // filter system props
    () => {
      const systemProps = Object.values(FieldSystemProp);
      return Object.keys(properties).filter(k => !systemProps.includes(k as FieldSystemProp));
    },
    [properties]
  );

  const propKeysMenuItems = useMemo(() => {
    return [
      {label: t({id: '-- File name --'}), value: FieldSystemProp.FileName},
      ...propKeys.map(k => ({label: k, value: k})),
    ];
  }, [propKeys]);

  const propKeysMenuItemsForFarms = useMemo(() => {
    if (currentFarm) {
      return [
        {
          label: t({id: 'Upload to current farm {farmName}'}, {farmName: currentFarm.name}),
          // we us random value - '__currentFarm__'+id for create not exist property to trigger select current farm at the farm select dropdown
          value: '__currentFarm__',
        },
        ...propKeysMenuItems,
      ];
    }
    return propKeysMenuItems;
  }, [currentFarm, propKeysMenuItems]);

  useEffect(() => {
    dispatch(setFieldPropsMapping(createParsedPropertiesMap(propKeys)));
  }, [propKeys]);

  const onChangePropsMap = useCallback((key: string, value: string) => {
    dispatch(setFieldPropMapping(key, value));
  }, []);

  const slicedGeoJsonObjects = useMemo(
    () => geoJSONs.slice(0, previewRowsNumber),
    [geoJSONs, previewRowsNumber, propKeys]
  );

  const columnType = (prop: string) =>
    prop?.includes('id') || prop?.includes('area') ? 'number' : 'text';

  const moveToTheNextStep = () => {
    if (propsMap.farmName.includes('__currentFarm__') && currentFarm) {
      // apply the current farm id
      dispatch(
        bulkSetUploadFieldsProp(
          geoJSONs.map(f => {
            return f?.properties?.[FieldSystemProp.Id];
          }),
          FieldSystemProp.FarmId,
          currentFarm.id
        )
      );
    } else {
      dispatch(setFieldsValueFromMappingProps('farmName'));
    }

    dispatch(setFieldsValueFromMappingProps('fieldName'));
    dispatch(setAddFieldCurrentStep('view-fields-from-files'));
  };

  const selectedFarmColumn = propKeysMenuItemsForFarms.find(
    item => item.value === propsMap.farmName
  )?.value;
  const selectedFieldColumn = propKeysMenuItems.find(
    item => item.value === propsMap.fieldName
  )?.value;

  return (
    <ParsedDataTableWrapper className="field-properties-parser">
      {uploadedFileNames.length > 1 && (
        <PropSelectWrapper first>
          <SelectField
            id={`parse-structure-select-${id}`}
            label={t({id: 'Select the file you want to use to parse the properties'})}
            placeholder={t({id: 'Select field'})}
            menuItems={uploadedFileNames.map((name, i) => ({label: name, value: i}))}
            simplifiedMenu={true}
            value={fieldParseIndex}
            onChange={(v: number) => setFieldParseIndex(v)}
          />
        </PropSelectWrapper>
      )}

      <Flex nowrap justifyContent="space-between" alignItems="baseline">
        <Text variant="h3" nowrap className="mr-1">
          {t({id: 'Here is a sample of your data'})}
        </Text>
        {geoJSONs.length > 1 && (
          <SelectField
            fullWidth={false}
            id={`parse-structure-select-${id}`}
            className="rows-number-selector"
            label={t({id: 'Preview'})}
            placeholder={t({id: 'Select rows number'})}
            menuItems={previewRowsNumberItems}
            simplifiedMenu={true}
            value={previewRowsNumber}
            onChange={(v: number) => setPreviewRowsNumber(v)}
          />
        )}
      </Flex>

      <MappingTableWrapper>
        <FluroDataTable mini>
          <FluroTableHeader>
            <FluroTableRow>
              {propKeys.map((prop, i) => {
                return (
                  <FluroTableColumn type={columnType(prop)} adjusted={false} key={prop + i}>
                    {prop}
                  </FluroTableColumn>
                );
              })}
            </FluroTableRow>
          </FluroTableHeader>

          <FluroTableBody>
            {slicedGeoJsonObjects.map(obg => (
              <FluroTableRow key={obg.properties[FieldSystemProp.Id]}>
                {propKeys.map((prop, i) => (
                  <FluroTableColumn
                    type={columnType(prop)}
                    adjusted={false}
                    key={`${prop}-val-${i}`}
                  >
                    {convertPropertyValue(obg.properties[prop])}
                  </FluroTableColumn>
                ))}
              </FluroTableRow>
            ))}
          </FluroTableBody>
        </FluroDataTable>
      </MappingTableWrapper>

      <div className="mt-4">
        <FluroSelect
          id="select-field-name"
          placeholder={t({id: 'Select column that represents field name'})}
          options={propKeysMenuItems}
          value={selectedFieldColumn}
          onChange={value => onChangePropsMap('fieldName', value)}
        />
        {!selectedFieldColumn && (
          <InfoBlock mini appearance="info" color="info">
            We couldn't find a column that represents the field name. But don't worry, you can
            select it later.
          </InfoBlock>
        )}
        <FluroSelect
          placeholder={t({id: 'Select column that represents farm name'})}
          options={propKeysMenuItemsForFarms}
          value={selectedFarmColumn}
          onChange={value => onChangePropsMap('farmName', value)}
        />
        {!selectedFarmColumn && (
          <InfoBlock mini appearance="info" color="info">
            We couldn't find a column that represents the farm name. But don't worry, you can select
            it later.
          </InfoBlock>
        )}
      </div>

      <div className="new-fields-nav-container sticky mt-4">
        <FluroButton raised blank noPadding onClick={onClearUpload}>
          {t({id: 'BtnLabel.Back'})}
        </FluroButton>

        <Button id={'go-next'} raised primary onClick={moveToTheNextStep}>
          {t({id: 'Next'})}
        </Button>
      </div>
    </ParsedDataTableWrapper>
  );
};

export default FieldPropertiesParser;
