// Uses both carbon (enrollment) and monitoring. To avoid circular dependencies it's in a separate file.

import {selectEnrollmentReadOnly} from 'containers/mrv/enrollment/carbon-store/selectors';
import {MRVPhaseType} from 'containers/mrv/types';
import type {AppStore} from 'reducers';
import {createCachingSelector} from '_utils/pure-utils';
import {selectMonitoringReadOnly} from './selectors';

export const isPhaseReadonly = createCachingSelector(
  [
    (s: AppStore, phaseType: MRVPhaseType) =>
      phaseType === MRVPhaseType.Monitoring
        ? selectMonitoringReadOnly(s)
        : selectEnrollmentReadOnly(s),
  ],
  readonly => readonly
);
