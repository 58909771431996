import styled from 'styled-components';
import {CloudFilledIcon as ClFI, CloudStrokeIcon as ClS} from 'containers/map/icons';

export const CloudFilled = styled(ClFI)`
  position: relative;
  margin-left: 8px;

  > path {
    fill: lightgrey;
  }
`;

export const CloudStroke = styled(ClS)`
  position: relative;
  margin-left: 8px;

  > path {
    fill: #fff;
    stroke: lightgrey;
  }
`;

export const CloudyText = styled.span`
  color: grey;
  padding-left: 6px;

  &.modified {
    color: #43a047;
  }
`;
