import {DragCircleMode} from './circle-draw-mode';
import MapboxDraw from '@mapbox/mapbox-gl-draw';

export interface StyledDrawControlProps {
  activeFillColor: string;
  activeLineColor: string;
  activeFillOpacity?: number;
  activeLineWidth?: number;
  inactiveFillColor: string;
  inactiveLineColor: string;
  inactiveFillOpacity?: number;
  inactiveLineWidth?: number;
}

export class StyledDrawControl extends MapboxDraw {
  constructor(props: StyledDrawControlProps) {
    const options = {
      defaultMode: 'simple_select',
      displayControlsDefault: false,
      controls: {
        polygon: false,
        trash: false,
      },
      modes: {
        ...MapboxDraw.modes,
        drag_circle: DragCircleMode,
      },
      styles: [
        {
          id: 'gl-draw-line',
          type: 'line',
          filter: ['all', ['==', '$type', 'LineString'], ['!=', 'mode', 'static']],
          layout: {
            'line-cap': 'round',
            'line-join': 'round',
          },
          paint: {
            'line-color': props.activeLineColor,
            'line-width': props.activeLineWidth ? props.activeLineWidth : 2,
          },
        },
        {
          id: 'gl-draw-polygon-fill',
          type: 'fill',
          filter: ['all', ['==', '$type', 'Polygon'], ['!=', 'mode', 'static']],
          paint: {
            'fill-color': props.activeFillColor,
            'fill-outline-color': props.activeLineColor,
            'fill-opacity': props.activeFillOpacity ? props.activeFillOpacity : 0.25,
          },
        },
        {
          id: 'gl-draw-polygon-midpoint',
          type: 'circle',
          filter: ['all', ['==', '$type', 'Point'], ['==', 'meta', 'midpoint']],
          paint: {
            'circle-radius': 2,
            'circle-color': props.activeLineColor,
          },
        },
        {
          id: 'gl-draw-polygon-stroke-active',
          type: 'line',
          filter: ['all', ['==', '$type', 'Polygon'], ['!=', 'mode', 'static']],
          layout: {
            'line-cap': 'round',
            'line-join': 'round',
          },
          paint: {
            'line-color': props.activeLineColor,
            'line-width': props.inactiveLineWidth ? props.inactiveLineWidth : 2,
          },
        },
        // vertex point halos
        {
          id: 'gl-draw-polygon-and-line-vertex-halo-active',
          type: 'circle',
          filter: [
            'all',
            ['==', 'meta', 'vertex'],
            ['==', '$type', 'Point'],
            ['!=', 'mode', 'static'],
          ],
          paint: {
            'circle-radius': 2,
            'circle-color': '#FFF',
          },
        },
        // vertex points
        {
          id: 'gl-draw-polygon-and-line-vertex-active',
          type: 'circle',
          filter: [
            'all',
            ['==', 'meta', 'vertex'],
            ['==', '$type', 'Point'],
            ['!=', 'mode', 'static'],
          ],
          paint: {
            'circle-radius': 2,
            'circle-color': props.activeLineColor,
          },
        },

        // INACTIVE (static, already drawn)
        // line stroke
        {
          id: 'gl-draw-line-static',
          type: 'line',
          filter: ['all', ['==', '$type', 'LineString'], ['==', 'mode', 'static']],
          layout: {
            'line-cap': 'round',
            'line-join': 'round',
          },
          paint: {
            'line-color': props.inactiveLineColor,
            'line-width': 3,
          },
        },
        // polygon fill
        {
          id: 'gl-draw-polygon-fill-static',
          type: 'fill',
          filter: ['all', ['==', '$type', 'Polygon'], ['==', 'mode', 'static']],
          paint: {
            'fill-color': props.inactiveFillColor,
            'fill-outline-color': props.inactiveLineColor,
            'fill-opacity': props.inactiveFillOpacity ? props.inactiveFillOpacity : 0.5,
          },
        },
        // polygon outline
        {
          id: 'gl-draw-polygon-stroke-static',
          type: 'line',
          filter: ['all', ['==', '$type', 'Polygon'], ['==', 'mode', 'static']],
          layout: {
            'line-cap': 'round',
            'line-join': 'round',
          },
          paint: {
            'line-color': props.inactiveLineColor,
            'line-width': 3,
          },
        },
      ],
    };
    super(options);
  }
}
