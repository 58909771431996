// @ts-nocheck
import {FormattedMessage, t} from 'i18n-utils';
import type {ReactElement} from 'react';
import React, {useMemo} from 'react';
import moment from 'moment';
import SimplePreloader from 'components/simple-preloader';
import {FontIcon, SelectField, SelectionControl, TextField} from 'react-md';
import {setCurrentFieldId, setFeature} from '../../actions';
import {
  exportSuggestedPointsToKml,
  loadSuggestedPoints,
  loadZoningData,
  saveSuggestedPoints,
  setZoning,
  updateZonesRange,
} from '../../actions/zoning-actions';
import {pointsSetGroupDate} from '../../actions/sampling-points';
import {setProfileSettings} from '../../../login/actions';
import {convertUnit, FeetToMeter, formatDate, formatUnit} from '_utils';

import {toFixedFloatUnsafe} from '_utils/number-formatters';
import {DownloadIcon} from '../../icons';
import NitrogenRecommendation from './nitrogen-recommendation';
import Zones from './zones';
import {NRxZones} from './nitrogen-recommendation/nrx-zoning';

import cn from 'classnames';

import './index.scss';
import {ZoningTab} from 'containers/map/types';
import {Tags as PremiumAppTag} from 'containers/admin/features/types';
import {Flex, FluroButton, Ln, ReadOnly, Text} from 'components';
import {
  MaxAreaValue,
  MinAreaValue,
  ParamsToDelay,
  RegularZoningMethods,
  TreesZoningMethods,
  ZoningBuffers,
  ZoningClasses,
} from './zoning-constants';
import Mixpanel from '_utils/mixpanel-utils';
import {AsyncStatusType} from 'modules/helpers';
import {ZoningTabs} from './tabs';
import {ProductivityMap} from './productivity-map';
import {useAppDispatch, useAppSelector} from '_hooks';
import {
  selectCurrentDate,
  selectCurrentFarm,
  selectCurrentField,
  selectCurrentSensor,
  selectCurrentTab,
  selectImagesStatus,
  selectIsCloudy,
  selectMapFields,
  selectTreeDetectionLayerType,
  selectTreeDetectionZoningScale,
  selectWholeFarmData,
  selectZoning,
} from 'containers/map/reducer/selectors';
import {CustomZoningForm} from './custom-zoning';
import {ContainersSeparator} from 'components/reusable-conponents.styled';
import {selectMeasurement, selectUserSettings} from '../../../login/login-selectors';
import {getTreesCustomZoningDefaultValue} from 'containers/map/utils/trees';

type ContainerProps = {
  mode: Mode;
};

type Mode = 'productivityMap' | 'nrx' | 'none';

const Container = ({mode, children}: React.PropsWithChildren<ContainerProps>) => {
  return (
    <div className={`section-container ${mode}`}>
      <h3 className="element-full-width tab-title">
        {t({id: 'Zone Management'})} <ZoningPreloader mode={mode} />
      </h3>
      <ZoningTabs />
      {children}
    </div>
  );
};

const ZoningPreloader = ({mode}: {mode: Mode}) => {
  switch (mode) {
    case 'productivityMap':
      return <SimplePreloader statusKeys={[AsyncStatusType.productivityMap]} />;
    case 'nrx':
      return (
        <SimplePreloader
          statusKeys={[
            AsyncStatusType.NRxRecommendation,
            AsyncStatusType.NRXSettings,
            AsyncStatusType.NRxListsData,
          ]}
        />
      );
    default:
      return null;
  }
};

type LabelValue = {
  label: string;
  value: string;
};

type ZoningProp = 'method' | 'area' | 'classes' | 'bufferValue' | 'treeZoningPercentage';

const MapZoning = () => {
  const dispatch = useAppDispatch();
  const zoning = useAppSelector(selectZoning);
  const wholeFarm = useAppSelector(selectWholeFarmData);
  const currentDate = useAppSelector(selectCurrentDate);
  const currentSensor = useAppSelector(selectCurrentSensor);
  const feature = useAppSelector(selectCurrentTab);
  const fields = useAppSelector(selectMapFields);
  const userSettings = useAppSelector(selectUserSettings);
  const farm = useAppSelector(selectCurrentFarm);
  const measurement = useAppSelector(selectMeasurement);
  const treeDetectionLayerType = useAppSelector(selectTreeDetectionLayerType);
  const imageStatus = useAppSelector(selectImagesStatus);
  const isCloudyState = useAppSelector(selectIsCloudy);
  const treeDetectionZoningScale = useAppSelector(selectTreeDetectionZoningScale);

  const field = useAppSelector(selectCurrentField);
  const isTreeAnalysis = treeDetectionLayerType !== 'default';

  let timeOut: ReturnType<typeof setTimeout>;

  const {isFavoriteMethod, method: zoningMethod, treeZoningPercentage} = zoning;
  const favoriteMethodProps =
    userSettings.zoning.favoriteZoningMethod && userSettings.zoning.favoriteZoningMethod[farm.id];
  const {isNoImagery} = imageStatus;

  const percentageSelect =
    isTreeAnalysis && TreesZoningMethods.find(m => m.value === zoningMethod && m.percentage);

  const validateZoningRequestData = (prop: ZoningProp, value: any) => {
    const treeZoningPercentageValue =
      prop === 'treeZoningPercentage' ? value : treeZoningPercentage;
    return isTreeAnalysis && percentageSelect
      ? !(
          !treeZoningPercentageValue ||
          treeZoningPercentageValue < 1 ||
          treeZoningPercentageValue > 99
        )
      : true;
  };
  const onChange = (prop: ZoningProp, value: any) => {
    const isFavoriteMethod = prop === 'method' ? value.includes('-favorite') : false;
    const isValidRequest = validateZoningRequestData(prop, value);

    isTreeAnalysis
      ? Mixpanel.changeTreeZoningSettings(prop, value)
      : Mixpanel.changeRegularZoningSettings(prop, value);

    if (prop === 'area') {
      value = parseFloat(value);
      if (value < MinAreaValue || value > MaxAreaValue) value = 0;
    }

    if (
      (prop === 'method' && value === 'custom') ||
      (prop === 'classes' && zoning.method === 'custom')
    ) {
      // set default value for zonesRange
      const rangeLength = parseInt(prop === 'classes' ? value : zoning.classes) - 1;

      const defaultRangeValue = getTreesCustomZoningDefaultValue(
        treeDetectionZoningScale.min,
        treeDetectionZoningScale.max,
        rangeLength
      );
      dispatch(updateZonesRange(defaultRangeValue));
    }

    if (isFavoriteMethod) {
      const {area, bufferValue, classes, method} =
        userSettings.zoning.favoriteZoningMethod &&
        userSettings.zoning.favoriteZoningMethod[farm.id];
      dispatch(
        setZoning({
          isFavoriteMethod: true,
          area,
          bufferValue,
          classes,
          method: method.replace('-favorite', ''),
          selectedByUser: true,
        })
      );
    } else {
      dispatch(
        setZoning({
          [prop]: value,
          isFavoriteMethod: false,
          selectedByUser: true,
        })
      );
    }

    if (ParamsToDelay.includes(prop)) {
      timeOut && clearTimeout(timeOut);
      timeOut = setTimeout(() => isValidRequest && dispatch(loadZoningData()), 750);
      return;
    }

    isValidRequest && dispatch(loadZoningData()); // cause for tree analysis there is button to run zoning
  };

  const savePoints = () => {
    dispatch(saveSuggestedPoints());
    dispatch(setFeature('tsp'));
    dispatch(pointsSetGroupDate(moment(currentDate, 'DD/MM/YYYY').format(formatDate())));
  };

  const saveTemplate = () => {
    const favoriteMethodValue = isFavoriteMethod ? null : zoning; // allow user to toggle favorite method
    const updatedSettings = {
      ...userSettings,
      zoning: {
        ...userSettings.zoning,
        favoriteZoningMethod: {
          ...userSettings.zoning.favoriteZoningMethod,
          [farm.id]: favoriteMethodValue,
        },
      },
    };
    dispatch(setProfileSettings(updatedSettings));
    dispatch(setZoning({isFavoriteMethod: !isFavoriteMethod}));
  };

  const getFavoriteMethodItem = (favoriteMethod: LabelValue) => {
    return {
      label: favoriteMethod.label,
      favorite: true,
      value: favoriteMethod.value + '-favorite',
    };
  };

  const zoningMethods = useMemo(() => {
    const selectedMethod = isFavoriteMethod ? zoningMethod + '-favorite' : zoningMethod;
    const favoriteMethod = RegularZoningMethods.find(
      method => method.value === favoriteMethodProps?.method
    );
    let computedRegularZoningMethods: {
      label: string | ReactElement;
      value: string;
      favorite?: boolean;
    }[] = RegularZoningMethods;

    if (favoriteMethod) {
      computedRegularZoningMethods = [
        getFavoriteMethodItem(favoriteMethod),
        ...computedRegularZoningMethods,
      ];
    }

    const methods = isTreeAnalysis ? TreesZoningMethods : computedRegularZoningMethods;

    return (
      <div className="methods">
        {methods.map((method: {label: string; value: string; favorite?: boolean}) => {
          return (
            <div
              key={method.value}
              onClick={() => onChange('method', method.value)}
              className={cn('method', {
                selected: method.value === selectedMethod,
                favorite: !!method?.favorite,
              })}
            >
              {t({id: method.label as string})}
              {method?.favorite ? <FontIcon>star</FontIcon> : null}
            </div>
          );
        })}
      </div>
    );
  }, [isTreeAnalysis, favoriteMethodProps, zoning.method, zoning.isFavoriteMethod, zoning.classes]);

  const maxMinZonesArea = toFixedFloatUnsafe(convertUnit(measurement, 'ac', field.Area / 3), 1);

  const toggleWholeFarm = (value: boolean) => {
    if (value) {
      dispatch(setCurrentFieldId('WholeFarm'));
    } else {
      const treeFields = fields.filter(f => f.tags.includes(PremiumAppTag.TreeAnalysis));
      if (treeFields.length) {
        const theFieldToSet = field.ID
          ? treeFields.find(f => f.ID === field.ID) || treeFields[0]
          : treeFields[0]; // get the previously selected field or the first from the tree fields list
        dispatch(setCurrentFieldId(theFieldToSet.ID));
      } else {
        dispatch(setCurrentFieldId(fields[0].ID));
      }
    }
  };

  if (zoning.tab === ZoningTab.ProdMap) {
    return (
      <Container mode="productivityMap">
        <ProductivityMap />
      </Container>
    );
  }

  if (zoning.tab === ZoningTab.NitrogenRx) {
    return (
      <Container mode="nrx">
        <NitrogenRecommendation />
        <NRxZones />
      </Container>
    );
  }

  if (isNoImagery || isCloudyState) {
    return <Container mode="none" />;
  }

  const haveSuggestedPoints = !!Object.keys(zoning.points).length;

  return (
    <Container mode={'none'}>
      <Text elementType="h2" className={'regular-zoning-settings-title'}>
        {t({id: 'Settings'})}
      </Text>
      <div className="zoning-method-container">
        {isTreeAnalysis && (
          <SelectionControl // toggle to switch back to the single field view
            id="toggle-whole-farm"
            label={t({id: 'Select multiple fields'})}
            className="whole-farm-toggle"
            name={'whole-farm-toggle'}
            type={'switch'}
            onChange={toggleWholeFarm}
            checked={wholeFarm.isWholeFarmView}
            labelBefore={true}
          />
        )}

        <div className="title">
          {t({id: 'Zoning method'})}
          <Ln
            blank
            external
            className="zoning-help-method"
            href="https://help.flurosense.com/en/articles/4484906-zone-management"
          >
            <FontIcon>help_outline</FontIcon>
          </Ln>
          {!isTreeAnalysis && (
            <ReadOnly>
              <FluroButton
                className={cn('save-zoning-template-icon', {highlighted: isFavoriteMethod})}
                onClick={saveTemplate}
                tooltipPosition={'left'}
                tooltipLabel={
                  <FormattedMessage
                    id={'zoning.save-template-btn-hover-label'}
                    defaultMessage="Save template"
                  />
                }
                icon
              >
                star_border
              </FluroButton>
            </ReadOnly>
          )}
        </div>

        {zoningMethods}
      </div>

      {!percentageSelect ? (
        <div className={'zoning-classes-container'}>
          <div className={'title'}>{t({id: 'Zones'})}</div>
          <div className="zoning-classes">
            {ZoningClasses.map(zoningClass => (
              <div
                key={zoningClass.value}
                onClick={() => onChange('classes', zoningClass.value)}
                className={cn('class', {
                  selected: zoningClass.value === zoning.classes,
                })}
              >
                {zoningClass.label}
              </div>
            ))}
          </div>
        </div>
      ) : null}

      {zoning.method === 'custom' ? <CustomZoningForm /> : null}

      {percentageSelect ? (
        <TextField
          id="select-percentage"
          label={`% ${percentageSelect.label}`}
          lineDirection="center"
          className="select-zoning-percentage"
          type="number"
          min={1}
          max={99}
          value={treeZoningPercentage}
          error={!treeZoningPercentage || treeZoningPercentage < 1 || treeZoningPercentage > 99}
          errorText={'The value should be round number between 1 and 99'}
          onChange={val => onChange('treeZoningPercentage', parseFloat(`${val}`))}
        />
      ) : null}

      {!isTreeAnalysis && (
        <Flex>
          <div className={'min-zone-container'}>
            <TextField
              id="select-zoning-area"
              label={t({id: 'Min zone area'})}
              lineDirection="center"
              placeholder={t({id: 'Min zone area'})}
              className="select-min-area"
              type="number"
              max={maxMinZonesArea}
              error={zoning.area > maxMinZonesArea}
              errorText={`Min zone value can't be > ${maxMinZonesArea} ${formatUnit(
                measurement,
                'ha'
              )}`}
              value={zoning.area}
              onChange={onChange.bind(this, 'area')}
            />
            <span className={'units'}>{t({id: measurement})}</span>
          </div>

          <SelectField
            id="select-buffer-value"
            placeholder={t({id: 'Buffer value'})}
            label={t({id: 'Buffer'})}
            className="select-buffer"
            menuItems={ZoningBuffers.map(el => ({
              label:
                measurement === 'ha'
                  ? FeetToMeter(el.label) + ` ${t({id: 'm'})}`
                  : el.label + ` ${t({id: 'ft'})}`,
              value: el.value,
            }))}
            onChange={onChange.bind(this, 'bufferValue')}
            value={
              isFavoriteMethod && favoriteMethodProps
                ? favoriteMethodProps.bufferValue
                : zoning.bufferValue
            }
            simplifiedMenu={true}
          />
        </Flex>
      )}

      <ContainersSeparator />

      <Zones />

      {!isTreeAnalysis && zoning.zones.length !== 0 && (
        <div className={'zoning-buttons-holder'}>
          {!haveSuggestedPoints && (
            <FluroButton
              readonly
              disabled={
                haveSuggestedPoints ||
                farm.readOnly ||
                !(feature === 'zoning' && currentDate && currentSensor !== 'NONE')
              }
              onClick={() => dispatch(loadSuggestedPoints())}
              className="suggest-sampling-points element-full-width"
              raised
              secondary={!haveSuggestedPoints}
            >
              <SimplePreloader
                content={<span>{t({id: 'Suggest Sampling Points'})}</span>}
                loadingContent={<span>{t({id: 'Suggest Sampling Points'})}</span>}
                statusKeys={[AsyncStatusType.loadSuggestedPoints]}
              />
            </FluroButton>
          )}

          {haveSuggestedPoints ? (
            <div className={'suggested-points-actions'}>
              <FluroButton
                onClick={savePoints}
                data-cy="Save suggested points"
                tooltipLabel={t({id: 'Save points as Tissue Sampling'})}
                tooltipPosition="right"
                className="zoning-button"
                raised
                secondary
              >
                {t({id: 'Save points'})}
              </FluroButton>

              <FluroButton
                onClick={() => dispatch(exportSuggestedPointsToKml())}
                className="zoning-button"
                iconEl={<DownloadIcon />}
                raised
                primary
              >
                {t({id: 'Export points'})}
              </FluroButton>
            </div>
          ) : null}
        </div>
      )}
    </Container>
  );
};

export default MapZoning;
