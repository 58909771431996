import React, {Component} from 'react';
import type {ConnectedProps} from 'react-redux';
import {connect} from 'react-redux';
import InviteUserForm from './invite-user-form';
import InviteUserList from './invite-user-list';
import FluroDialog from '../../components/fluro-dialog';
import {genKey} from '_utils/pure-utils';

import {removeUserFromFarm, getOne, sendInvite} from 'modules/farms/actions';
import type {IInitialMapState} from '../map/types';
import type {TUser, TExternalUser} from './types';
import InfoMessage from './info-message';
import {InviteWrapper} from './invite-users.styled';

import './index.scss';

type Props = ConnectedProps<typeof connector> & {
  groupId: number;
  isVisible: boolean;
  toggleInviteUser: () => void;
};

interface IState {
  users: TUser[];
  externalUsers: TExternalUser[];
}

class InviteUsers extends Component<Props> {
  state: IState = {
    users: [],
    externalUsers: [],
  };

  componentDidUpdate(prevProps: Props) {
    if (!prevProps.isVisible && this.props.isVisible) this.loadUsers();
  }

  loadUsers = () => {
    this.props.getOne(this.props.groupId, true).then(({users = [], externalUsers = {}} = {}) => {
      externalUsers = Object.keys(externalUsers).map(key => ({...externalUsers[key], type: key}));
      this.setState({users, externalUsers});
    });
  };

  addUserEmail = (email: string[], resetForm: () => void) => {
    const {sendInvite, groupId} = this.props;

    sendInvite(groupId, email).then(() => {
      resetForm();
      this.setState({
        users: [...this.state.users, ...email.map((el: string) => ({id: genKey(), email: el}))],
      });
    });
  };

  removeUserEmail = (userId: number) => {
    if (window.confirm(`Are you sure?`)) {
      this.props.removeUserFromFarm(this.props.groupId, userId).then(() => {
        const users = this.state.users.filter(({id}: TUser) => userId !== id);
        this.setState({users});
      });
    }
  };

  render() {
    const {externalUsers, users} = this.state;
    const {isVisible, toggleInviteUser, isReadOnly = false, user} = this.props;
    return (
      <FluroDialog
        id="invite-user-dialog"
        isDraggable
        dialogClassName="invite-user"
        visible={isVisible}
        onHide={toggleInviteUser}
        portal
      >
        <InviteWrapper>
          <div className="invite-user__container">
            <InviteUserForm addUserEmail={this.addUserEmail} />
            <InviteUserList
              externalUsers={externalUsers}
              users={users}
              user={user}
              isReadOnly={isReadOnly}
              removeUserEmail={this.removeUserEmail}
            />
          </div>

          <InfoMessage />
        </InviteWrapper>
      </FluroDialog>
    );
  }
}

const mapStateToProps = ({map, login}: {map: IInitialMapState; login: any}) => ({
  user: login.user,
  isReadOnly: map.group.readOnly,
});

const connector = connect(mapStateToProps, {
  removeUserFromFarm,
  getOne,
  sendInvite,
});

export default connector(InviteUsers);
