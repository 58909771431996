import {Text} from 'components';
import {FluroGeoJson} from 'components/fluro-leaflet';
import {SIAreaDefaultFillColor} from 'modules/sustainability-insights/constants';
import {
  isGeometryInCoverCropsSummaryChart,
  selectCoverCropsChartTab,
  selectCropColorById,
  selectSIGeometryById,
} from 'modules/sustainability-insights/selectors';
import type {ComponentType} from 'react';
import React from 'react';
import {Tooltip} from 'react-leaflet';
import {useAppSelector} from '_hooks';
import {CoverCropsTooltip} from './cover-crops-tooltip';
import type {GeometryProps} from './types';

export const CoverCropsGeometry: ComponentType<GeometryProps> = ({
  id,
  name,
  isHighlighted,
  isActive,
  onClick,
}) => {
  const geometry = useAppSelector(s => selectSIGeometryById(s, id));
  const color = useAppSelector(s => selectCropColorById(s, id));
  const selectedTab = useAppSelector(selectCoverCropsChartTab);
  const isInChart = useAppSelector(s => isGeometryInCoverCropsSummaryChart(s, id));

  if (!geometry) return null;

  const eventHandlers = {
    click: onClick,
  };

  const fillColor = color || SIAreaDefaultFillColor;
  const defaultPathOptions = {weight: 1};
  const resolveGeometryPathOptions = () => {
    if (!isActive) {
      return {
        fillColor: SIAreaDefaultFillColor,
        fillOpacity: 0.5,
        color: 'black',
      };
    }

    if (selectedTab === 'summary' && !isInChart) {
      return {
        fillColor: SIAreaDefaultFillColor,
        fillOpacity: 1,
        color: '#464646',
      };
    }

    return {
      fillColor,
      fillOpacity: 1,
      color: 'black',
      weight: isHighlighted ? 4 : 1,
    };
  };
  const geometryPathOptions = resolveGeometryPathOptions();

  const pathOptions = {
    ...defaultPathOptions,
    ...geometryPathOptions,
  };

  return (
    <FluroGeoJson data={geometry} pathOptions={pathOptions} eventHandlers={eventHandlers}>
      <Tooltip>
        <Text variant="small">{isActive ? <CoverCropsTooltip id={id} name={name} /> : name}</Text>
      </Tooltip>
    </FluroGeoJson>
  );
};
