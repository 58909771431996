// @ts-nocheck
import type {LoginState} from './types';
import {LoginActionTypes} from './types';
import {isAdminPerm} from '../../_utils';
import {DEFAULT_LOCALE} from '../../i18n-utils';
import {FarmActionTypes} from '../../modules/farms/types';
import {InitialUser} from '../admin/users/base';

export const initialState: LoginState = {
  message: [''],

  user: {...InitialUser},
  isImpersonated: false,
  isImpersonatorNotSuperAdmin: false,
  isAuth: false,
  token: '',
  farmEndDialog: false,
};

const loginReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case LoginActionTypes.LOGIN:
      const {user, token, is_impersonated, is_impersonator_not_super_admin} = action.response;
      return {
        ...state,
        isAuth: true,
        isImpersonated: !!is_impersonated,
        isImpersonatorNotSuperAdmin: !!is_impersonator_not_super_admin,
        user: {
          ...user,
          demoFarms: initialState.user.demoFarms,
          groupIds: user.groupIds?.length
            ? user.groupIds
            : isAdminPerm(user.perm)
            ? [136] // manually assign a demo farm for an admin user without farms
            : user.groupIds,
          settings: {
            ...initialState.user.settings,
            ...user.settings,
            // locale: user.settings.locale || initialState.user.settings.locale,
            langLocale:
              user.settings.langLocale || initialState.user.settings.langLocale || DEFAULT_LOCALE,
            measurement: user.settings.measurement || initialState.user.settings.measurement,
            timezone: user.settings.timezone || initialState.user.settings.timezone,
          },
        },
        token,
      };

    case LoginActionTypes.UPDATE_USER_SETTINGS:
      return {
        ...state,
        user: {
          ...state.user,
          settings: {
            ...initialState.user.settings,
            ...action.settings,
          },
        },
      };

    case LoginActionTypes.UPDATE_USER_DATA:
      return {
        ...state,
        user: {
          ...state.user,
          ...action.data,
        },
      };

    case LoginActionTypes.UPDATE_USER_WORKSPACES:
      return {
        ...state,
        user: {
          ...state.user,
          workspaces: {...state.user.workspaces, ...action.payload.workspaces},
        },
      };

    case LoginActionTypes.LOGOUT:
      return {
        ...state,
        isAuth: false,
        isImpersonated: false,
        user: {...InitialUser},
      };

    case LoginActionTypes.ERROR:
      const {status, message} = action.response;
      return Object.assign({}, state, {
        status: status,
        message: message,
      });

    case FarmActionTypes.CREATE_FARM: {
      return {
        ...state,
        user: {
          ...state.user,
          groupIds: [...state.user.groupIds, action.farm.id],
        },
      };
    }
    case FarmActionTypes.REMOVE_FARM: {
      return {
        ...state,
        user: {
          ...state.user,
          groupIds: state.user.groupIds.filter(farmId => farmId !== action.farmId),
        },
      };
    }

    default:
      return state;
  }
};

export default loginReducer;
