const SET_ID = 'help-popup/set-id';

const initialState = {
  currentPopUpId: '',
};

type Action = {
  type: string;
  id: number | string;
};

export default (state = initialState, action: Action) => {
  switch (action.type) {
    case SET_ID:
      return {
        currentPopUpId: action.id,
      };

    default:
      return state;
  }
};

export const setId = (id: number | string) => ({
  type: SET_ID,
  id,
});
