import {Route} from 'react-router-dom';
import Users from './users';
import React, {memo} from 'react';
import UserActivity from 'containers/admin/user-activity';
import PremiumApps from 'containers/admin/features/premium-apps/premium-apps';
import CropTypeMatching from 'containers/admin/crop-types-matching';

const Routes = memo(() => {
  return (
    <>
      <Route path="*/global-admin/users" component={Users} />
      <Route exact path={'*/global-admin/user-activity'} component={UserActivity} />
      <Route exact path={'*/global-admin/features'} component={PremiumApps} />
      <Route path="*/global-admin/crop" component={CropTypeMatching} />
    </>
  );
});

export default Routes;
