import {reportError} from 'containers/error-boundary';
import type {CropType} from 'modules/global/types';
import {Tillage} from './base';
import {getCropItems} from 'containers/mrv/base';

export enum YearQuarter {
  SpringTillage = 'Spring tillage',
  SummerCrop = 'Summer crop',
  FallTillage = 'Fall tillage',
  WinterCrop = 'Winter crop',
}

export const isPracticeConfident = (confidence?: number | null) =>
  confidence !== null && confidence !== undefined && confidence > 40;

export const tillServerToApp = (till: string) => {
  if (!till) return;
  switch (till) {
    case Tillage.Conservation: // TODO (stas): This line is a fix from Illia
    case 'no till':
      return Tillage.NoTill;
    case 'reduced till':
      return Tillage.Low;
    case 'conventional till':
      return Tillage.Conventional;
    default:
      reportError(`Guessed to App tillage mapping not implemented for ${till}`);
      return till;
  }
};

export const tillAppToServer = (till: string) => {
  if (!till) return;
  switch (till) {
    case Tillage.Conservation:
    case Tillage.NoTill:
      return 'no till';
    case Tillage.Low:
      return 'reduced till';
    case Tillage.Conventional:
      return 'conventional till';
    default:
      reportError(`App to Guessed tillage mapping not implemented for ${till}`);
      return till;
  }
};

export const cropServerToApp = (crop: string) => {
  switch (crop) {
    case 'no cover':
      return 'fallow';
    case 'commodity crop':
      return 'winter_commodity_crop';
    default:
      return crop;
  }
};

export const cropAppToServer = (crop: string) => {
  switch (crop) {
    case 'fallow':
      return 'no cover';
    case 'winter_commodity_crop':
      return 'commodity crop';
    default:
      return crop;
  }
};

export const getCarbonCrops = (crops: Record<number, CropType>, programId: number) => {
  const summerCropItems = getCropItems(
    summerCropIds
      .map(cropId => crops[cropId])
      .filter(crop => crop && !cropsHiddenForPrograms[crop.id]?.includes(programId))
  );
  const winterCropItems = getCropItems(
    winterCropIds
      .map(cropId => crops[cropId])
      .filter(crop => crop && !cropsHiddenForPrograms[crop.id]?.includes(programId))
  );

  // Patch the labels.
  winterCropItems.forEach(crop => {
    if (crop.value === 'winter_commodity_crop') {
      crop.label = 'Winter Wheat';
    }
  });

  return {summerCropItems, winterCropItems};
};

type CropId = number;
type ProgramId = number;
const MrvProgramIds = [21, 68, 95, 116, 117, 118];
// FSB-11696: Hide crops from the UI for certain programs.
const cropsHiddenForPrograms: Record<CropId, ProgramId[]> = {
  134: MrvProgramIds, // flax
};

// FS (FluroSense) crop ids
// Crops sorted alphabetically.
// IMPORTANT if this list is updated, the list in the backend must also be updated
const summerCropIds = [
  4, // alfalfa
  16, // barley
  446, // spring barley (tech debt, needed to capture the season planted without a season column)
  62, // canola
  105, // corn
  106, // cotton
  118, // dry_beans
  127, // fallow
  134, // flax
  149, // grass_pasture
  161, // hay
  181, // lentils
  215, // millet
  225, // oats
  234, // other
  239, // pasture
  241, // peanuts
  244, // peas
  424, // popcorn
  260, // potatoes
  265, // pumpkins
  273, // rice
  276, // rye
  426, // small_grains
  299, // sorghum
  302, // soybeans
  314, // sugar_beets
  315, // sugarcane
  319, // sunflowers
  320, // sweet_corn
  343, // triticale
  356, // wheat_spring
];
const winterCropIds = [
  443, // basic cover crop
  445, // winter barley (tech debt, needed to capture the season planted without a season column)
  48, // buckwheat
  62, // canola
  86, // clover
  127, // fallow
  433, // full cover
  181, // lentils
  225, // oats
  234, // other
  244, // peas
  442, // premium cover crop
  267, // radishes
  276, // rye
  299, // sorghum
  343, // triticale
  345, // turnips
  347, // vetch
  357, // wheat_winter
];
