export const OAUTH_USER_NOT_ACTIVE = 'oauth/not-active';

const initialState = {
  message: '',
};

export default (state = initialState, action) => {
  switch (action.type) {
    case OAUTH_USER_NOT_ACTIVE:
      return Object.assign({}, state, {
        message: action.message,
      });

    default:
      return state;
  }
};
