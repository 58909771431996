import React, {useEffect, useMemo, useState} from 'react';
import {t} from 'i18n-utils';
import type {CSGViewModel} from '../types';
import {Card} from 'components/card/card';
import {useDispatch, useSelector} from 'react-redux';
import type {AppStore} from 'reducers';
import {CPFilterType} from '_reducers/crop-performance-filter/field-filter-types';
import {FluroDialog, FluroTabs} from 'components';
import './benchmark-card.scss';
import '../helpers/legend-list.scss';
import 'chartjs-plugin-zoom';
import type {UpdatedDataSet} from './helpers';
import {
  classifyCropsByNDVI,
  classifyDataSets,
  classifyLabels,
  classifyMinMaxChartValues,
} from './helpers';
import {setRelevantBenchmarkRecords} from '../reducer';
import ChartView from './chart-view';
import RankingView from './ranking-view';

type Props = {
  filteredRecords: CSGViewModel[];
  active: boolean;
  onWidgetClick: () => void;
  onFilterToggle: (seasonId: number, color: string) => void;
};

export const BenchmarkCard = ({filteredRecords, active, onWidgetClick, onFilterToggle}: Props) => {
  const dispatch = useDispatch();
  const cropPerformance = useSelector((store: AppStore) => store.cropPerformance);
  const cropPerformanceFilter = useSelector((store: AppStore) => store.cropPerformanceFilter);
  const cropPerformanceBenchmarkRecords = useSelector(
    (store: AppStore) => store.cropPerformance.benchmark.recordsToProcess
  );
  const [highlightedDataSetIds, updateHighlightedDataSetIds] = useState<number[]>([]);
  const [popupVisible, togglePopUpVisibility] = useState(false);
  const benchmarkFilters = cropPerformanceFilter.filters[CPFilterType.BIOMASS_OVER_TIME];
  const isReportView = useSelector((store: AppStore) => store.global.isReportView);
  const [selectedTab, setSelectedTab] = useState<'trends' | 'ranking'>('trends');

  /**
   * data is a prepared object with chart data enriched with seasons props
   * datasets contains: 2 automatically calculated best and 2 worst seasons + other seasons that user might add (select)
   * */
  const data = useMemo(() => {
    const data: {labels: string[]; datasets: UpdatedDataSet[]} = {labels: [], datasets: []};

    if (!filteredRecords.length) return data; // early return

    const {bestCrops, worstCrops, addedCrops} = classifyCropsByNDVI(
      filteredRecords,
      cropPerformanceBenchmarkRecords
    ); // worstCrops: [record1, record2], worstCrops: [record[records.length-1],record[records.-2]]
    data.labels = classifyLabels([...bestCrops, ...worstCrops, ...addedCrops], cropPerformance); // [20191121, 20191122, 20191123, ...]
    data.datasets = classifyDataSets(
      data.labels,
      [...bestCrops, ...worstCrops, ...addedCrops],
      cropPerformance,
      cropPerformanceFilter
    );
    return data;
  }, [filteredRecords, cropPerformanceBenchmarkRecords]);

  /**
   * hasHighlightedData() detects do we have selected legend items (equal to filtered chart data)
   * */
  const hasHighlightedData = useMemo(() => {
    return !!highlightedDataSetIds.filter(seasonId =>
      data.datasets.find(dataSet => dataSet.seasonId === seasonId)
    ).length;
  }, [data, highlightedDataSetIds]);

  /**
   * this effect controls seasons in filter, clear old filtered data
   * */
  useEffect(() => {
    const alreadyAddedSeasons = benchmarkFilters.map(filter => filter);
    const relevantRecords: {[seasonId: number]: boolean} = {};
    data.datasets.forEach(({seasonId, backgroundColor}, index) => {
      if (index >= 4) {
        // add only manually picked crops
        relevantRecords[seasonId] = true;
      }
      // add new seasons as existing
      // or update it's color
      if (
        (!hasHighlightedData || highlightedDataSetIds.includes(seasonId)) &&
        alreadyAddedSeasons.findIndex(({value}) => value === seasonId) === -1
      ) {
        onFilterToggle(seasonId, backgroundColor);
      }
    });

    benchmarkFilters.forEach(filter => {
      // remove old seasons from filter if they don't exist in dataSets anymore
      if (data.datasets.findIndex(({seasonId}) => filter.value === seasonId) === -1) {
        onFilterToggle(filter.value as number, filter.color);
      }
    });
    dispatch(setRelevantBenchmarkRecords(relevantRecords));
  }, [data]);

  const onHighlightDataSets = (seasonId: number) => {
    const updatedArray = highlightedDataSetIds.includes(seasonId)
      ? highlightedDataSetIds.filter(id => id !== seasonId)
      : [...highlightedDataSetIds, seasonId];

    updateHighlightedDataSetIds(updatedArray);
  };

  useEffect(() => {
    const alreadyAddedSeasons = benchmarkFilters.map(filter => filter.value);

    data.datasets.forEach(({seasonId, backgroundColor}) => {
      const isAddedToFilter = alreadyAddedSeasons.includes(seasonId);
      if (
        (highlightedDataSetIds.includes(seasonId) && !isAddedToFilter) || // add a crop to filter (map)
        (!highlightedDataSetIds.includes(seasonId) && isAddedToFilter) // remove a crop from filter (map)
      ) {
        onFilterToggle(seasonId, backgroundColor);
      }
    });
  }, [highlightedDataSetIds]);

  const {filteredDataSets, maxDataSetValue, minDataSetValue} = useMemo(() => {
    const result: {
      filteredDataSets: UpdatedDataSet[];
      maxDataSetValue: number;
      minDataSetValue: number;
    } = {
      filteredDataSets: [],
      maxDataSetValue: 0,
      minDataSetValue: 0,
    };
    result.filteredDataSets = data.datasets.filter(
      dataSet => !hasHighlightedData || highlightedDataSetIds.includes(dataSet.seasonId)
    );
    const {maxValue, minValue} = classifyMinMaxChartValues(result.filteredDataSets);
    result.maxDataSetValue = maxValue;
    result.minDataSetValue = minValue;

    return result;
  }, [hasHighlightedData, data]);

  const TheContent = useMemo(() => {
    // use it twice, in the right panel and the pop-up
    return (
      <>
        {!isReportView && (
          <FluroTabs
            onTabClick={(value: 'trends' | 'ranking') => setSelectedTab(value)}
            selectedTab={selectedTab}
            tabs={[
              {label: t({id: 'Trends'}), value: 'trends'},
              {label: t({id: 'Field ranking'}), value: 'ranking'},
            ]}
          />
        )}
        {selectedTab === 'trends' ? (
          <ChartView
            data={data}
            filteredRecords={filteredRecords}
            filteredDataSets={filteredDataSets}
            maxDataSetValue={maxDataSetValue}
            minDataSetValue={minDataSetValue}
            hasHighlightedData={hasHighlightedData}
            highlightedDataSetIds={highlightedDataSetIds}
            onHighlightDataSets={onHighlightDataSets}
            popupVisible={popupVisible}
            togglePopUpVisibility={togglePopUpVisibility}
          />
        ) : (
          <RankingView
            data={data}
            filteredRecords={filteredRecords}
            popupVisible={popupVisible}
            togglePopUpVisibility={togglePopUpVisibility}
          />
        )}
      </>
    );
  }, [data, filteredDataSets, popupVisible, selectedTab]);

  if (!data.labels.length || !filteredRecords.length) return null; // early return

  return (
    <Card
      active={active}
      title={t({id: 'CP.benchmark-card.title'})}
      onClick={onWidgetClick}
      info={<span>{t({id: 'CP.benchmark-card.info-text'})}</span>}
      isReportView={isReportView}
    >
      {!popupVisible && TheContent}

      <FluroDialog
        id={'cp-benchmark-pop-up'}
        title={t({id: 'CP.benchmark-card.title'})}
        isDraggable
        hideOnClickOutside={false}
        portal
        onHide={() => togglePopUpVisibility(false)}
        visible={popupVisible}
      >
        {TheContent}
      </FluroDialog>
    </Card>
  );
};
