import {t} from 'i18n-utils';
import React, {useEffect, useState} from 'react';
import FormUploader from './upload-layers-form';
import {FluroDialog, FluroButton} from 'components';
import {UploadIcon} from 'containers/map/icons';
import '../index.scss';
import OrderPlanetImagery from '../order-hight-resolution-imagery';

const UploadLayers = ({isReadOnly}: {isReadOnly: boolean}) => {
  const [visible, toggleVisibility] = useState(false);
  const [orderHighResolutionVisible, toggleOrderHighResolutionVisibility] = useState(false);

  useEffect(() => {
    // reset the prop
    if (orderHighResolutionVisible) toggleOrderHighResolutionVisibility(false);
  }, [orderHighResolutionVisible]);

  return (
    <>
      <FluroDialog
        id="upload-layers-pop-up"
        visible={visible}
        title={t({id: 'Upload my layers'})}
        isDraggable
        focusOnMount={false}
        onHide={() => toggleVisibility(false)}
        portal
      >
        <FormUploader />
      </FluroDialog>

      <FluroButton
        readonly
        primary
        raised
        disabled={isReadOnly}
        iconEl={<UploadIcon />}
        className="element-full-width margin-bottom-20 margin-top-5"
        onClick={() => toggleVisibility(true)}
      >
        {t({id: 'Upload my layers'})}
      </FluroButton>

      {!isReadOnly && (
        <>
          <OrderPlanetImagery openByProps={orderHighResolutionVisible} />
          <div
            className={'order-high-resolution-imagery-btn'}
            onClick={() => toggleOrderHighResolutionVisibility(true)}
          >
            {t({id: 'Order high resolution imagery'})}
          </div>
        </>
      )}
    </>
  );
};

export default UploadLayers;
