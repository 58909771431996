import {t} from 'i18n-utils';
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {dialogToggle, DialogType} from 'modules/helpers';
import {Button, SelectionControl} from 'react-md';
import Dialog from 'components/fluro-dialog';
import {genKey} from '_utils/pure-utils';
import {DialogFooter} from 'containers/admin/admin.styled';
import {CommonDialogContentWrapper} from './dialogs.styled';
import {FluroButton} from 'components';
import {useAppDispatch, useAppSelector} from '../../_hooks';
import {selectDialogData} from '../../modules/helpers/selectors';

const ConfirmDialog = () => {
  const id = useMemo(() => genKey(), []);
  const dispatch = useAppDispatch();
  const {visible, payload} = useAppSelector(s => selectDialogData(s, DialogType.confirm));

  const [isAgree, setAgree] = useState(false);

  useEffect(() => {
    if (payload.agreementsMessage) {
    }
  }, [payload.agreementsMessage]);

  const title = useMemo(() => {
    return payload?.title || 'Confirm';
  }, [payload]);

  const okLabel = useMemo(() => {
    return payload?.okLabel || 'Ok';
  }, [payload]);

  const message = useMemo(() => {
    return (
      <>
        <div>
          {payload?.message ||
            t({id: 'ConfirmDialogDefaultTitle', defaultMessage: 'Are you sure?'})}
        </div>
        {payload?.agreementsMessage ? (
          <div className="agreements-message">
            <SelectionControl
              id={`checkbox-${id}`}
              name={`checkbox-${id}`}
              type={'checkbox'}
              onChange={() => setAgree(!isAgree)}
              checked={isAgree}
              label={payload.agreementsMessage}
            />
          </div>
        ) : null}
      </>
    );
  }, [payload, isAgree]);

  const onCancel = useCallback(() => dispatch(dialogToggle(DialogType.confirm, false)), []);
  const onOk = useCallback(() => {
    if (payload?.okAction) {
      payload.okAction();
      onCancel();
    }
  }, [payload]);

  return (
    <Dialog
      id={`confirm-dialog-${id}`}
      visible={visible}
      title={title}
      focusOnMount={false}
      isDraggable
      onHide={onCancel}
      width={payload?.width || 400}
      portal
      actions={
        !payload?.hideButtons ? (
          <DialogFooter fullWidth>
            <FluroButton blank raised onClick={onCancel}>
              {t({id: 'Cancel'})}
            </FluroButton>

            <Button primary raised onClick={onOk} disabled={payload?.agreementsMessage && !isAgree}>
              {okLabel}
            </Button>
          </DialogFooter>
        ) : null
      }
    >
      <CommonDialogContentWrapper>{message}</CommonDialogContentWrapper>
    </Dialog>
  );
};

export default ConfirmDialog;
