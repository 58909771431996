import {NDVI} from 'containers/si/colors';

export const getCropYearValues = (prevYearMonths: number[], currentYear: number[]) => {
  const prevYearLastTwoMonths = prevYearMonths.slice(-2);
  const currentYearFirstTenMonths = currentYear.slice(0, 10);

  return prevYearLastTwoMonths.concat(currentYearFirstTenMonths);
};
export const getColorFromValue = (value: number): string => {
  const ndviIndex = Math.floor(value);
  const ndviValue = NDVI[ndviIndex];

  return ndviValue ? ndviValue.color : '';
};

export const getNdviValueRangeFromColor = (color: string): string => {
  const foundValue = NDVI.find(ndviValue => ndviValue.color === color);

  return foundValue ? foundValue.range : 'No data';
};
