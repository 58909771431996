import React from 'react';
import styled from 'styled-components';

type Props = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  hierachy: 'primary' | 'secondary';
};

const ButtonPrimary = styled.button`
  /* Hierachy=Primary, Icon=Default, State=Default */

  cursor: pointer;

  box-sizing: border-box;

  /* Auto layout */
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 8px 16px;
  gap: 7.82px;

  height: 31px;

  /* FS Brand/Green 600 */
  background: #399d2b;
  mix-blend-mode: normal;
  border: 1.11765px solid rgba(0, 0, 0, 0.0001);
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 1.11765px 0px 1.11765px rgba(0, 0, 0, 0.14),
    -1.11765px 0px 1.11765px rgba(0, 0, 0, 0.14), 0px 1px 1px rgba(0, 0, 0, 0.14);
  border-radius: 3px;

  &:active {
    /* FS Brand/Green 700 */
    background: #2e8023;
  }
  &:hover {
    /* FS Brand/Green 400 */
    background: #5fb854;
  }

  //   Text inside the button
  > span {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 15px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.5px;
    text-transform: uppercase;

    /* FS Brand/White */
    color: #ffffff;
  }
`;

const ButtonSecondary = styled.button`
  /* Hierachy=Secondary, Icon=Default, State=Default */

  cursor: pointer;

  box-sizing: border-box;

  /* Auto layout */
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 8px 16px;
  gap: 9px;

  height: 31px;

  background: #ffffff;
  mix-blend-mode: normal;

  /* FS Brand/Gray 300 */
  border: 1px solid #e0e0e0;
  border-radius: 3px;
`;

const Button = ({hierachy, onClick, children, ...rest}: Props) => {
  switch (hierachy) {
    case 'primary':
      return (
        <ButtonPrimary onClick={onClick} {...rest}>
          <span>{children}</span>
        </ButtonPrimary>
      );
    case 'secondary':
      return (
        <ButtonSecondary onClick={onClick} {...rest}>
          <span>{children}</span>
        </ButtonSecondary>
      );
    default:
      throw new Error('Invalid hierachy provided to Button');
  }
};

export default Button;
