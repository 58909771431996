import type {ComponentType} from 'react';
import React, {useMemo} from 'react';
import {useAppDispatch, useAppSelector} from '_hooks';
import {naturalSortAlphaNum} from '_utils/sorters';
import {Flex} from 'components';
import {
  selectReportInterventionFilter,
  selectReportAvailableInterventions,
} from 'containers/si/module/selectors';
import {selectIsAdmin} from 'containers/login/login-selectors';
import {
  setDashboardInterventionFilter,
  setDashboardPageIndex,
  toggleDashboardInterventionFilter,
} from 'containers/si/module/reducer';
import {AreaSelectMenu} from 'containers/map/features/sustainability-insights/area-select/area-select-menu';
import {batch} from 'react-redux';
import type {Props as OptionsGroupsProps} from 'components/grouped-selection-control/options-group';

const dropdownStyles = {width: 'max-content', paddingBottom: 0, paddingTop: '5px'};

export const InterventionFilter: ComponentType = () => {
  const dispatch = useAppDispatch();
  const availableInterventions = useAppSelector(selectReportAvailableInterventions);
  const selected = useAppSelector(selectReportInterventionFilter);
  const isAdmin = useAppSelector(selectIsAdmin);

  const controls = useMemo(() => {
    const interventionsSets = Object.entries(availableInterventions).map(intervention => {
      const [interventionId, label] = intervention;

      return {
        label: isAdmin ? `${label} (#${interventionId})` : label,
        value: interventionId,
      };
    });

    return naturalSortAlphaNum(interventionsSets, 'label');
  }, [availableInterventions, isAdmin]);

  const allSelected = selected.length === controls?.length;

  const handleClear = () => {
    dispatch(setDashboardInterventionFilter([]));
  };

  const handleSelect: OptionsGroupsProps['onChange'] = selectedChange => {
    if (typeof selectedChange === 'boolean') {
      //Means we clicked select all button
      batch(() => {
        dispatch(
          setDashboardInterventionFilter(
            selectedChange ? Object.keys(availableInterventions).map(Number) || [] : []
          )
        );
        dispatch(setDashboardPageIndex(0));
      });
      return;
    } else {
      //Means we clicked any other value
      batch(() => {
        dispatch(toggleDashboardInterventionFilter(Number(selectedChange.value)));
        dispatch(setDashboardPageIndex(0));
      });
    }
  };

  return (
    <Flex alignItems="center" nowrap>
      <AreaSelectMenu
        className="intervention-filter-menu"
        allowSelectAll
        allowSearch
        allLabel={`Select all interventions`}
        selectedLabel={'interventions'}
        options={controls || []}
        selected={selected.map(String)}
        disabled={false}
        allSelected={allSelected}
        onSelect={handleSelect}
        onClear={handleClear}
        style={dropdownStyles}
      />
    </Flex>
  );
};
