// @ts-nocheck
import React, {PureComponent} from 'react';
import cn from 'classnames';
import {genKey, escapeRegExp} from '_utils/pure-utils';
import {FontIcon, DropdownMenu, AccessibleFakeButton, Checkbox, TextField} from 'react-md';
import {FilterIcon} from '../../map/icons';

interface IProps {
  id: string;
  title: any;
  menuItems: any[];
  column: string;
  selectedAll: boolean;
  isFilterWithSearch?: boolean;
  onFilterChange: (column: string, filterKey: string, value: boolean) => void;
  onClearFilter?: (column: string) => void;
  Label?: React.ComponentType<{value: any}>;

  onSort?: (value: any, order?: boolean) => void;
  sortColumn?: string;
  sortOrder: any;
}

export default class FilterControl extends PureComponent<IProps, any> {
  state = {
    searchValue: '',
  };

  onSearch = (searchValue: string) => {
    this.setState({searchValue});
  };

  search = (items: any[]) => {
    const r = new RegExp(escapeRegExp(this.state.searchValue), 'i');
    if (!this.state.searchValue.trim()) return items;
    return items.filter(item => r.test(item.label));
  };

  onClear = () => {
    this.setState({searchValue: ''});
    this.props.onClearFilter(this.props.column);
  };

  render() {
    const props: IProps = this.props;

    return (
      <>
        <DropdownMenu
          id={props.id}
          simplifiedMenu={true}
          // listHeightRestricted={false}
          menuItems={[
            <div key={`${props.id}__filter-item-search`} className="filter-ctrl__top-bl">
              <div className="filter-ctrl__clear-bl">
                <span onClick={this.onClear}>Clear Filter</span>
              </div>

              {props.onSort ? (
                <ul className="filter-ctrl__sort">
                  <li onClick={() => props.onSort(props.column)}>
                    <span
                      className={cn({
                        cp__sort_label: true,
                        'cp__sort_label--active': props.sortColumn === props.column,
                      })}
                    >
                      Sort{' '}
                      <FontIcon>{props.sortOrder ? 'arrow_downward' : 'arrow_upward'}</FontIcon>
                    </span>
                  </li>
                </ul>
              ) : null}

              {props.isFilterWithSearch ? (
                <div className="filter-ctrl__search">
                  <TextField
                    id={`${props.id}__filter-item-input`}
                    placeholder="Search"
                    value={this.state.searchValue}
                    onChange={this.onSearch}
                  />
                </div>
              ) : null}
            </div>,

            /* --------------------- */

            <div key={`${props.id}__filter-item-select-all`} className="filter-item">
              <Checkbox
                // disabled={false}
                id={genKey()}
                name={`${props.id}__filter-item-select-all`}
                checked={props.selectedAll}
                onChange={(value: boolean) => {
                  props.onFilterChange(props.column, '__selectedAll', value);
                }}
                label={<div style={{whiteSpace: 'nowrap'}}>(Select All)</div>}
              />
            </div>,

            /* ---------------------- */

            ...this.search(props.menuItems).map((el: any, i: number) => {
              const checkBoxKey: string = genKey();
              const label = props.Label ? <props.Label value={el.value} /> : el.label;

              return (
                <div key={`${props.id}__filter-item-${i}`} className="filter-item">
                  <Checkbox
                    // disabled={false}
                    id={checkBoxKey}
                    name={checkBoxKey}
                    checked={el.selected}
                    onChange={(value: boolean) => {
                      props.onFilterChange(props.column, el.value, value);
                    }}
                    label={<div style={{whiteSpace: 'nowrap'}}>{label}</div>}
                  />
                </div>
              );
            }),
          ]}
          centered
          className="filter-ctrl"
        >
          <AccessibleFakeButton>
            <div className="filter-ctrl__title">
              <span className="filter-ctrl__title-text md-text--secondary">{props.title}</span>{' '}
              <FilterIcon />
            </div>
          </AccessibleFakeButton>
        </DropdownMenu>
      </>
    );
  }
}
