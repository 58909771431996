import type {PayloadAction} from '@reduxjs/toolkit';
import {createSlice} from '@reduxjs/toolkit';
import {LoginActionTypes} from 'containers/login/types';
import {toggle} from '_utils/pure-utils';
import {isDefined} from '_utils/typeGuards';
import type {SI2State} from '../types';
import type {SIProgramKPI} from 'containers/si/types';
import {fetchKPISubsectionArea, fetchAllKPIFilters, setAllKPIFilters} from '../thunks';
import type {KPIFilter} from 'containers/si/api/apiTypes';

const initialState: SI2State['kpiFilter'] = {
  years: [2020],
  visibleSubsectionIds: [],
  selectedCropTypes: [],
  cropHaBySubsectionIdsByYear: {},
  saved: [],
};

export const kpiFilterSlice = createSlice({
  name: 'si/kpiFilter',
  initialState,
  reducers: {
    setSIYearsFilter: (state, action: PayloadAction<number[]>) => {
      return {
        ...state,
        years: action.payload,
      };
    },
    setSIVisibleSubsectionIds: (state, action: PayloadAction<number[]>) => {
      return {
        ...state,
        visibleSubsectionIds: action.payload,
      };
    },
    toggleSISubsectionVisibility: (state, action: PayloadAction<number>) => {
      return {
        ...state,
        visibleSubsectionIds: toggle(state.visibleSubsectionIds, action.payload),
      };
    },
    setSISelectedCropTypes: (state, action: PayloadAction<number[]>) => {
      return {
        ...state,
        selectedCropTypes: action.payload,
      };
    },
    setCurrentKPIFilter: (state, action: PayloadAction<{filter: Omit<KPIFilter, 'name'>}>) => {
      const {selected_crop_types, visible_subsection_ids, years} = action.payload.filter;

      return {
        ...state,
        years: years,
        visibleSubsectionIds: visible_subsection_ids ?? [], //TODO: Revisit this once KPIFilters type updates visible_subsection_ids to be required
        selectedCropTypes: selected_crop_types,
      };
    },
  },
  extraReducers: builder => {
    builder
      .addCase<string, PayloadAction<SIProgramKPI>>(
        fetchKPISubsectionArea.fulfilled.type,
        (state, action) => {
          const {subsection_summary, year} = action.payload;

          if (isDefined(subsection_summary)) {
            Object.entries(subsection_summary).forEach(value => {
              const [subsectionId, area] = value as [string, number];

              state.cropHaBySubsectionIdsByYear = {
                ...state.cropHaBySubsectionIdsByYear,
                [Number(subsectionId)]: {
                  ...((state.cropHaBySubsectionIdsByYear &&
                    state.cropHaBySubsectionIdsByYear[Number(subsectionId)]) ||
                    {}),
                  [year]: area / 10000 || 0,
                },
              };
            });
          }
        }
      )
      .addCase<string, PayloadAction<KPIFilter[]>>(
        fetchAllKPIFilters.fulfilled.type,
        (state, action) => {
          state.saved = action.payload;
        }
      )
      .addCase<string, PayloadAction<KPIFilter[]>>(
        setAllKPIFilters.fulfilled.type,
        (state, action) => {
          state.saved = action.payload;
        }
      )
      .addCase(LoginActionTypes.LOGOUT, state => {
        return {
          ...state,
          ...initialState,
        };
      });
  },
});
