import React, {useMemo, useEffect} from 'react';
import type {ComponentType} from 'react';
import {useAppDispatch, useAppSelector} from '_hooks';
import {
  selectIsAdmin,
  selectUser,
  selectUserIsImpersonated,
} from 'containers/login/login-selectors';
import styled from 'styled-components';
import Select, {components} from 'react-select';
import type {StylesConfig, ValueContainerProps, OptionProps} from 'react-select';
import {
  workspaces,
  workspaceLabels,
  workspaceLinks,
  getWorkspace,
} from 'containers/admin/users/types';
import {useTranslation} from 'i18n-utils';
import {useWorkspace} from '_hooks/use-workspace';
import {useHistory, useLocation} from 'react-router-dom';
import cn from 'classnames';
import {
  selectCurrentProgram,
  selectSIProgramById,
  selectSIProgramsList,
} from 'containers/si/module/selectors';
import {
  selectMRVProgramById,
  selectMRVProgramsList,
  selectUserProjects,
} from 'containers/mrv/monitoring/module/selectors';
import {useMonitoringUrlParams} from 'containers/mrv/monitoring/hooks';
import {SI_HOME, SI_KPI} from 'containers/si/routes';
import {
  MenubarControlStyles,
  MenubarIndicatorsContainerStyles,
  MenubarMenuContainerStyles,
  MenubarMenuListStyles,
  MenubarMenuOptionStyles,
  MenubarMenuStyles,
  MenubarValueContainerStyles,
  OptionInnerLineSeparator,
  OptionInnerTitle,
} from 'containers/menubar/menubar.styled';
import {MenubarArrowDropdown} from 'containers/menubar/menubar-components';
import {GLOBAL_ADMIN_PATH} from '_environment';
import {useParsedMatchParams} from '_hooks/use-parsed-match-params';
import {
  MRV_ADMIN_PROGRAM,
  MRV_ADMIN_PROGRAM_CONFIGURE,
  MRV_PROJECT,
  MRV_PROJECT_SELECT,
} from 'containers/mrv/routes';
import {getFeaturesLabels} from '_utils/translations';
import {MRV_ADMIN} from '../mrv/routes';
import {naturalSortAlphaNum} from '_utils/sorters';
import {applyPathParams} from '_utils/pure-utils';
import {selectIsLoading, selectIsSucceed} from 'modules/helpers/selectors';
import {ActionType} from 'containers/si/module/types';
import {setCurrentProgram} from 'containers/si/module/reducer';
import {fetchSIPrograms} from 'containers/si/module/thunks';
import {ProgramTab} from 'containers/mrv/admin/types';
import {isSingleOption} from 'containers/menubar/menubar-utils';
import {getLocalStorage} from '_hooks/use-storage';

type Option = {
  value: string;
  label: string;
  onClick: (value?: string) => void;
  title?: string; // the prop is used to insert a title before the option, example title: Workspaces
  topHorizontalSeparator?: boolean;
};

const IndicatorsContainer: ComponentType<ValueContainerProps<Option>> = ({...props}) => {
  const {pathname} = useLocation();
  const currentWorkspace = getWorkspace(pathname);
  const t = useTranslation();

  const isGlobalAdminsPage = pathname.includes(GLOBAL_ADMIN_PATH);
  const workspaceLabel = isGlobalAdminsPage
    ? t({id: 'Regrow Internal Tools'})
    : workspaceLabels[currentWorkspace];
  const {workspaceBreadcrumb, workspaceBreadcrumbTitle} =
    useWorkspaceBreadcrumbs(isGlobalAdminsPage);

  return (
    <components.IndicatorsContainer {...props}>
      <IndicatorsInnerContainer>
        <img src="/assets/logos/regrow/menubar_regrow_logo_white_outlined.svg" alt="regrow-logo" />
        <div className={cn({'workspace-label': true, 'with-breadcrumbs': !!workspaceBreadcrumb})}>
          {workspaceLabel}
          {workspaceBreadcrumb ? (
            <>
              <div className="separator-line" />
              <span className="breadcrumbs" title={workspaceBreadcrumbTitle}>
                {workspaceBreadcrumb}
              </span>
            </>
          ) : null}
        </div>
        {props.options.length > 1 ? <MenubarArrowDropdown /> : null}
      </IndicatorsInnerContainer>
    </components.IndicatorsContainer>
  );
};

const useWorkspaceBreadcrumbs = (isGlobalAdminPage = false) => {
  const currentWorkspace = getWorkspace(window.location.pathname);
  const siProgramId = useAppSelector(selectCurrentProgram);
  const siProgram = useAppSelector(s => selectSIProgramById(s, Number(siProgramId)));
  const {programId: mrvProgramId} = useMonitoringUrlParams();
  const {programId: mrvAdminProgramId} = useParsedMatchParams(MRV_ADMIN_PROGRAM);

  const mrvProgram = useAppSelector(s =>
    selectMRVProgramById(s, mrvAdminProgramId || mrvProgramId)
  );
  const {pathname} = useLocation();

  if (isGlobalAdminPage) {
    return {workspaceBreadcrumb: '', workspaceBreadcrumbTitle: ''};
  }

  switch (currentWorkspace) {
    case 'mrv':
      return {
        workspaceBreadcrumb: mrvProgram?.name,
        workspaceBreadcrumbTitle: `${mrvProgram?.name} #${mrvProgram?.id}`,
      };
    case 'si':
      const siProgramName = pathname === SI_HOME ? '' : siProgram?.name; // don't display the program name if it is the Home page}
      return {
        workspaceBreadcrumb: siProgramName,
        workspaceBreadcrumbTitle: `${siProgramName} #${siProgram?.id}`,
      };

    default:
      return {workspaceBreadcrumb: '', workspaceBreadcrumbTitle: ''};
  }
};

const OptionComponent: ComponentType<OptionProps<Option>> = ({children, ...props}) => {
  const {topHorizontalSeparator, title} = props.data;
  return (
    <>
      {topHorizontalSeparator ? <OptionInnerLineSeparator /> : null}
      {title ? (
        <OptionInnerTitle className={cn({topPadding: topHorizontalSeparator})}>
          {title}
        </OptionInnerTitle>
      ) : null}
      <components.Option {...props}>{children}</components.Option>
    </>
  );
};

export const MenubarWorkspacesComponent: ComponentType = () => {
  const user = useAppSelector(selectUser);
  const t = useTranslation();
  const isAdmin = useAppSelector(selectIsAdmin);
  const {workspaceLink} = useWorkspace();
  const history = useHistory();
  const featureLabels = getFeaturesLabels();
  const mrvRecentPrograms = useMrvRecentPrograms();
  const SIRecentPrograms = useSIRecentPrograms();

  const workspacesOptions = useMemo(() => {
    const menuOptions: Option[] = workspaces
      .filter(w => user.workspaces?.[w])
      .map(w => ({
        label: workspaceLabels[w],
        value: w,
        onClick: () => history.push(`${workspaceLinks[w]}`),
      }));

    if (isAdmin) {
      menuOptions.push({
        label: featureLabels['admin'],
        value: 'admin',
        onClick: () => history.push(`${workspaceLink}/global-admin/users`),
      });
    }

    if (menuOptions.length) {
      menuOptions[0].title = t({id: 'Workspaces'});
    }

    return menuOptions;
  }, [user?.workspaces, isAdmin, workspaceLink, history, featureLabels, t]);

  return (
    <MenubarWorkspacesContainer>
      <Select
        // menuIsOpen
        isMulti={false}
        styles={stylesOverwrites}
        onChange={value => {
          if (isSingleOption(value) && value?.onClick) {
            value.onClick();
          }
        }}
        options={[...workspacesOptions, ...mrvRecentPrograms, ...SIRecentPrograms]}
        components={{IndicatorsContainer, Option: OptionComponent}}
      />
    </MenubarWorkspacesContainer>
  );
};

const useMrvRecentPrograms = () => {
  const {isWorkspaceMrv} = useWorkspace();
  const history = useHistory();
  const {pathname} = useLocation();
  const t = useTranslation();
  const isGlobalAdminsPage = pathname.includes(GLOBAL_ADMIN_PATH);
  const isAdmin = useAppSelector(selectIsAdmin);
  const programs = useAppSelector(selectMRVProgramsList);
  const projects = useAppSelector(selectUserProjects);
  const impersonating = useAppSelector(selectUserIsImpersonated);

  const userId = useAppSelector(selectUser).id;

  const mrvRecentPrograms: Option[] = useMemo(() => {
    if (!isWorkspaceMrv || isGlobalAdminsPage || impersonating) {
      return [];
    }

    const recentProgramsByUserId = getLocalStorage('recentMRVProgramsByUserId') || {};
    const usersRecentPrograms = recentProgramsByUserId[`${userId}`] || [];

    if (usersRecentPrograms.length === 0) {
      return [];
    }

    const first5Programs = usersRecentPrograms
      .filter(savedProgram => {
        // make sure the program or project still exists
        return isAdmin
          ? programs.find(p => p.id === savedProgram.programId)
          : projects.find(p => p.program_id === savedProgram.programId);
      })
      .slice(0, 5);

    const programsOptions = first5Programs.map((p, index) => ({
      title: index === 0 ? t({id: 'Your MRV programs'}) : undefined,
      topHorizontalSeparator: index === 0,
      label: p.programName,
      value: p.programName,

      onClick: () => {
        if (isAdmin) {
          history.push(
            applyPathParams(MRV_ADMIN_PROGRAM_CONFIGURE, {
              programId: p.programId,
              tab: ProgramTab.Configuration,
            })
          );

          return;
        }

        history.push(
          applyPathParams(MRV_PROJECT, {
            projectId: p.projectId,
          })
        );
      },
    }));

    return [
      ...programsOptions,
      {
        label: `${t({id: 'Show all', defaultMessage: 'Show all'})}...`,
        value: 'navigate to the programs list',
        onClick: () => history.push(isAdmin ? MRV_ADMIN : MRV_PROJECT_SELECT),
      },
    ];
    // Hack: Using pathname to trigger the effect when the pathname changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    history,
    t,
    userId,
    isWorkspaceMrv,
    isGlobalAdminsPage,
    pathname,
    isAdmin,
    programs,
    projects,
    impersonating,
  ]);

  return mrvRecentPrograms;
};

const useSIRecentPrograms = () => {
  const dispatch = useAppDispatch();
  const {workspace} = useWorkspace();
  const isSI = workspace === 'si';
  const history = useHistory();
  const t = useTranslation();
  const {pathname} = useLocation();
  const isGlobalAdminsPage = pathname.includes(GLOBAL_ADMIN_PATH);
  const isLoading = useAppSelector(s => selectIsLoading(s, [ActionType.FETCH_ALL_SI_PROGRAMS]));
  const programsFetched = useAppSelector(s =>
    selectIsSucceed(s, [ActionType.FETCH_ALL_SI_PROGRAMS])
  );

  const programs = useAppSelector(selectSIProgramsList);

  useEffect(() => {
    if (programsFetched || isLoading || !isSI) return;
    // This should only fetch the programs that the user has access to
    dispatch(fetchSIPrograms());
  }, [programsFetched, dispatch, isLoading, isSI]);

  const siRecentPrograms: Option[] = useMemo(() => {
    if (!isSI || isGlobalAdminsPage || programs?.length <= 1 || isLoading) return [];
    const sortedPrograms = naturalSortAlphaNum(programs, 'id'); // duplicate sorting rules from the src/containers/si/program-select/program-select.tsx
    const first5Programs = sortedPrograms.slice(0, 5); // display only first 5 programs
    const programOptions = first5Programs.map((program, index) => ({
      title: index === 0 ? t({id: 'Recent Sustainability Insights programs'}) : undefined, // put the title at the top
      topHorizontalSeparator: index === 0, // add the line separator before the title
      label: program.name,
      value: program.name,
      onClick: () => {
        dispatch(setCurrentProgram(program.id));
        history.push(applyPathParams(SI_KPI, {programId: program.id}));
      },
    }));

    return [
      ...programOptions,
      {
        label: `${t({id: 'Show all', defaultMessage: 'Show all'})}...`,
        value: 'navigate to the programs list',
        onClick: () => history.push(SI_HOME),
      },
    ];
  }, [isSI, programs, isLoading, isGlobalAdminsPage, history, t, dispatch]);

  return siRecentPrograms;
};

const stylesOverwrites: StylesConfig<Option> = {
  container: base => ({...base, ...MenubarMenuContainerStyles}),
  option: base => ({
    ...base,
    ...MenubarMenuOptionStyles,
  }),
  valueContainer: base => ({...base, ...MenubarValueContainerStyles}), // hide the value container
  indicatorsContainer: base => ({
    ...base,
    ...MenubarIndicatorsContainerStyles,
  }),

  menu: base => ({
    ...base,
    ...MenubarMenuStyles,
    left: '8px', // create the same space as from the top (actual space from the menubar to the menu component, even so there is 12px margin)
  }),
  control: base => ({
    ...base,
    ...MenubarControlStyles,
    paddingLeft: '10px',
  }),
  menuList: base => {
    return {...base, ...MenubarMenuListStyles};
  },
};

const MenubarWorkspacesContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  height: 100%; // keep it 100% for proper hover area
  align-items: center;
`;

const IndicatorsInnerContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  cursor: pointer;

  .dropdown-icon {
    color: ${({theme}) => theme.color.stroke.strong};
  }

  .workspace-label {
    color: ${({theme}) => theme.color.stroke.strong};
    font-weight: 700;
    font-size: 14px;
    &.with-breadcrumbs {
      display: flex;
      gap: 10px;
      color: ${({theme}) => theme.colorPalette.fs_main.gray_400};
      .separator-line {
        border-top: 2px solid #aba5a0;
        width: 9px;
        height: 2px;
        margin-top: 9px;
      }
      .breadcrumbs {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        max-width: 250px;
        color: ${({theme}) => theme.color.stroke.strong};
      }
    }
  }
`;
