import React, {useEffect} from 'react';
import {useAppDispatch, useAppSelector} from '_hooks';
import {useParsedMatchParams} from '_hooks/use-parsed-match-params';
import {CircularProgress} from 'react-md';
import {Flex, Text} from 'components';
import {selectSISupplySheds, selectReportDetails} from 'containers/si/module/selectors';
import {fetchSISupplySheds} from 'containers/si/module/thunks';
import {AbatementPotentialReport} from 'containers/si/planning-report/dashboard/abatement-potential-report';
// import {ProgramPlanReport} from 'containers/si/planning-report/dashboard/program-plan-report';
import {ProgramPlanReport} from 'containers/si/planning-report/programPlan/ProgramPlanReport';
import {
  setCurrentProgram,
  setDashboardProgramId,
  setDashboardReportId,
} from 'containers/si/module/reducer';

export const ReportDashboard = () => {
  const dispatch = useAppDispatch();
  const {programId, reportId} = useParsedMatchParams();
  const supplySheds = useAppSelector(selectSISupplySheds);
  const report = useAppSelector(s => selectReportDetails(s, reportId));

  useEffect(() => {
    if (!supplySheds[programId]) {
      dispatch(fetchSISupplySheds(programId));
    }
    dispatch(setCurrentProgram(programId));
    dispatch(setDashboardProgramId(programId));
    dispatch(setDashboardReportId(reportId));
  }, [dispatch, programId, reportId, supplySheds]);

  const loadingPage = (
    <div>
      <Flex className="margin-bottom-16" justifyContent="space-between">
        <Flex alignItems="baseline" gap="10px">
          <Text className="mb-0" bold variant="h1">
            Loading Report
          </Text>
        </Flex>
      </Flex>
      <CircularProgress className={'progress'} id={'si-reports-table'} />
    </div>
  );

  switch (report?.report_type) {
    case 'program_plan':
      return <ProgramPlanReport report={report} />;
    case 'abatement_potential':
      return <AbatementPotentialReport />;
    default:
      return loadingPage;
  }
};
