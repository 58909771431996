// @ts-nocheck
import type {CSSProperties, ReactElement} from 'react';
import React from 'react';
import './index.scss';
import {FontIcon} from 'react-md';
import cn from 'classnames';
import type {AppearanceValues} from './types';
import {infoBlockTypes} from './types';
import colors from 'variables.scss';
import {ErrorIcon, InfoIcon, SuccessIcon} from '../../containers/map/icons';
import {Text} from 'components/text/text';
import {Flex} from 'components/flex/flex';
import {LoadingIcon} from 'containers/mrv/icons';

export type InfoBlockProps = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
> & {
  children: React.ReactNode;
  hideIcon?: boolean;
  noBackground?: boolean;
  icon?: React.ReactNode;
  visible?: boolean;
  appearance?: AppearanceValues;
  color?: AppearanceValues;
  iconColor?: string;
  onIconClick?: () => void;
  containerStyle?: CSSProperties;
  bordered?: boolean;
  fullWidth?: boolean;
  mini?: boolean;
  title?: string | React.ReactElement;
  actions?: React.ReactElement;
};

/*
 *  ----------------------
 *   ℹ️ Info block message
 *  ----------------------
 * */
export const InfoBlock = ({
  appearance = 'default',
  color = 'default',
  icon,
  children,
  className = '',
  hideIcon,
  visible = true,
  iconColor,
  onIconClick,
  containerStyle = {},
  noBackground,
  bordered,
  fullWidth,
  mini,
  title,
  actions,
  ...rest
}: InfoBlockProps) => {
  if (!visible) {
    return null;
  }

  const classifiedAppearance: AppearanceValues = classifyAppearance(appearance);
  const classifiedColors: AppearanceValues = classifyAppearance(color);

  return (
    <div
      className={cn(
        'info-block',
        `info-block__appearance-${infoBlockConfigs[classifiedAppearance].key}`,
        `info-block--color-${classifiedColors}`,
        {
          [className]: !!className,
          'info-block--no-bg': noBackground,
          'info-block--mini': mini,
          'info-block--bordered': bordered,
          'info-block--full-width': fullWidth,
          clickable: !!rest.onClick,
        }
      )}
      style={containerStyle}
      {...rest}
    >
      <Flex nowrap fullWidth alignItems={'center'}>
        <InfoBlockIconWrap
          onIconClick={onIconClick}
          hideIcon={hideIcon}
          appearance={classifiedAppearance}
          icon={
            <InfoBlockIcon appearance={classifiedAppearance} icon={icon} iconColor={iconColor} />
          }
        />
        <div className={'info-block__content'}>
          {title && (
            <Text variant={'small'} fontWeight={500} className={'margin-right-5'}>
              {title}
            </Text>
          )}
          {children}
        </div>
      </Flex>
      {Boolean(actions) ? (
        <Flex className={'info-block__actions'} gap={'10px'} fullWidth justifyContent={'flex-end'}>
          {actions}
        </Flex>
      ) : null}
    </div>
  );
};

export const InfoBlockIcon = ({
  appearance,
  icon,
  iconColor,
}: Pick<InfoBlockProps, 'appearance' | 'icon' | 'iconColor'>) => {
  const isFontIcon = typeof infoBlockConfigs[appearance].icon === 'string';
  const color = iconColor || infoBlockConfigs[appearance].iconColor;
  switch (true) {
    case !!icon && typeof icon === 'string':
      return <FontIcon style={{color}}>{icon}</FontIcon>;
    case !!icon:
      return icon;
    case isFontIcon:
      return (
        <FontIcon style={{color, fontSize: '16px'}}>{infoBlockConfigs[appearance].icon}</FontIcon>
      );
    case typeof infoBlockConfigs[appearance].icon === 'function':
      return infoBlockConfigs[appearance].icon(color);
    default:
      return null;
  }
};

const InfoBlockIconWrap = ({
  appearance,
  icon,
  hideIcon,
  onIconClick,
}: Pick<InfoBlockProps, 'appearance' | 'icon' | 'hideIcon' | 'onIconClick'>) => {
  if (hideIcon) return null;
  return (
    <span
      className={cn(
        'info-block__info-icon',
        `info-block__icon-${infoBlockConfigs[appearance].key}`,
        {
          _clickable: !!onIconClick,
        }
      )}
      onClick={onIconClick}
    >
      {icon}
    </span>
  );
};

type Config = {
  [key in AppearanceValues]: {
    key: string;
    icon?: string | ((color: string) => ReactElement);
    iconColor?: string;
  };
};

const infoBlockConfigs: Config = {
  info: {
    icon: color => <InfoIcon style={{width: '16px', color}} />,
    key: 'info',
    iconColor: colors['dodger-blue'],
  },

  warning: {
    icon: color => <ErrorIcon style={{width: '16px', color}} />,
    key: 'warning',
    iconColor: colors['saffron'],
  },

  gray: {
    key: 'gray',
  },

  error: {
    icon: color => <ErrorIcon style={{width: '16px', color}} />,
    key: 'error',
    iconColor: colors['NDVI-lowest'],
  },

  success: {
    icon: color => <SuccessIcon style={{width: '16px', color}} />,
    iconColor: colors['main-green-400'],
    key: 'confirm',
  },

  default: {
    icon: 'help_outline',
    key: 'default',
    iconColor: colors['main-gray-600'],
  },
  processing: {
    icon: color => <LoadingIcon size={16} style={{color}} />,
    key: 'processing',
    iconColor: colors['dodger-blue'],
  },
  add: {
    icon: 'add_circle_outline',
    key: 'add',
    iconColor: colors['main-gray-600'],
  },
};

const classifyAppearance = (appearance: string): AppearanceValues => {
  if (infoBlockTypes.includes(appearance as AppearanceValues))
    return appearance as AppearanceValues;
  let classifiedAppearance: AppearanceValues = 'default';
  // some of the values are coming from the back-end, classify them if need
  switch (appearance) {
    case 'info-strong':
      classifiedAppearance = 'info';
      break;
    case 'warning-strong':
      classifiedAppearance = 'warning';
      break;
    case 'confirmation':
      classifiedAppearance = 'success';
      break;
    default:
      classifiedAppearance = 'default';
      break;
  }
  return classifiedAppearance;
};
export default InfoBlock;
