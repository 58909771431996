// @ts-nocheck
import React, {useMemo, useRef, useState} from 'react';
import {t} from 'i18n-utils';
import {Autocomplete, Button, FontIcon, SelectField} from 'react-md';
import {FluroAutocomplete, MultiKeysPressed} from 'components';
import {CropAvatar} from 'components/crop-avatar';
import ErrorBoundary from 'containers/error-boundary';
import {Bar} from 'react-chartjs-2';
import type {UpdatedDataSet} from './helpers';
import {detectRecordsGroupTitle, options} from './helpers';
import cn from 'classnames';
import {updateBenchmarkRecord} from '../reducer';
import {useDispatch, useSelector} from 'react-redux';
import type {AppStore} from 'reducers';
import type {CSGViewModel} from '../types';
import {ColoredBullet, ItemName} from '../../../../../components/legend-list/legend-list';

type Props = {
  data: {labels: string[]; datasets: UpdatedDataSet[]};
  filteredRecords: CSGViewModel[];
  filteredDataSets: UpdatedDataSet[];
  maxDataSetValue: number;
  minDataSetValue: number;
  popupVisible: boolean;
  togglePopUpVisibility: (value: boolean) => void;
  hasHighlightedData: boolean;
  highlightedDataSetIds: number[];
  onHighlightDataSets: (seasonId: number) => void;
};

const ChartView = ({
  data,
  filteredRecords,
  filteredDataSets,
  maxDataSetValue,
  minDataSetValue,
  popupVisible,
  togglePopUpVisibility,
  hasHighlightedData,
  highlightedDataSetIds,
  onHighlightDataSets,
}: Props) => {
  const dispatch = useDispatch();
  const measurement = useSelector((store: AppStore) => store.login.user.settings.measurement);
  const isReportView = useSelector((store: AppStore) => store.global.isReportView);
  const cropPerformanceBenchmarkRecords = useSelector(
    (store: AppStore) => store.cropPerformance.benchmark.recordsToProcess
  );

  const [shiftPressed, changeShiftPressedState] = useState(false);
  const [chartViewModified, setChartViewModified] = useState(false);

  const chartRef = useRef(null);

  const resetChartZoom = () => {
    if (chartRef.current) {
      chartRef.current.chartInstance.resetZoom();
      setChartViewModified(false);
    }
  };

  const onUpdateRecordsToProcess = (seasonId: number, ev?: React.MouseEvent<HTMLElement>) => {
    ev?.stopPropagation(); // prevent click on the CropItem li
    dispatch(updateBenchmarkRecord(seasonId, !cropPerformanceBenchmarkRecords[seasonId]));
  };

  const cropItemsToSearch = useMemo(() => {
    return (
      filteredRecords
        .filter(
          ({seasonId}) =>
            !cropPerformanceBenchmarkRecords[seasonId] && // filter already added records
            !data.datasets.find(dataSet => dataSet.seasonId === seasonId) // filter default added records
        )
        // and create menuItems[] for <Autocomplete />
        .map(({seasonName, seasonId, fieldName}) => ({
          label: `${fieldName} ${seasonName || ''}`,
          value: seasonId,
        }))
    );
  }, [filteredRecords, cropPerformanceBenchmarkRecords, data]);

  const onShiftToggle = (value: boolean) => {
    if (value !== shiftPressed) {
      changeShiftPressedState(value);
    }
  };
  return (
    <>
      <div onClick={popupVisible ? null : () => togglePopUpVisibility(true)}>
        {popupVisible && (
          <div className={'zoom-and-pan-container'}>
            <ul className={'pan-helper-text'}>
              <li>{t({id: 'CP.benchmark-card.draw-a-rectangle-to-zoom'})}</li>
              <li>{t({id: 'CP.benchmark-card.hold-shift-to-pan'})}</li>
            </ul>

            <Button
              className={'reset-zoom-btn'}
              raised
              onClick={resetChartZoom}
              disabled={!chartViewModified}
            >
              {t({id: 'CP.benchmark-card.reset-chart-zoom'})}
            </Button>

            <MultiKeysPressed
              keysUpCallback={() => onShiftToggle(false)}
              callback={() => onShiftToggle(true)}
              keys={['Shift']}
            />
          </div>
        )}
        <ErrorBoundary expectedError={'time scale fails during update'}>
          <Bar
            ref={chartRef}
            data={{
              labels: data.labels,
              datasets: filteredDataSets,
            }}
            options={options({
              measurement,
              isPopUp: popupVisible,
              shiftPressed,
              onZoomOrPanChange: () => setChartViewModified(true),
              maxDataSetValue,
              minDataSetValue,
              labels: data.labels,
            })}
            width={popupVisible ? 520 : 320}
            height={popupVisible ? 300 : 200}
          />
        </ErrorBoundary>
      </div>
      <div className="legend-list-selected-count">
        {t(
          {id: '{count1} / {count2} crops selected'},
          {
            count1: filteredDataSets.length,
            count2: filteredRecords.length,
          }
        )}
      </div>

      <ul className={cn('legend-list')}>
        {data.datasets.slice(0, 4).map(
          // shows only automatically added crops
          (data, index, arr) => {
            const groupLabel = detectRecordsGroupTitle(index, arr);

            return (
              <React.Fragment key={data.seasonId}>
                {groupLabel ? <li className={'legend-list-title'}>{groupLabel}</li> : null}
                <CropItem
                  data={data}
                  automaticallyCalculated
                  popupVisible={popupVisible}
                  hasHighlightedData={hasHighlightedData}
                  highlightedDataSetIds={highlightedDataSetIds}
                  onUpdateRecordsToProcess={onUpdateRecordsToProcess}
                  onHighlightDataSets={onHighlightDataSets}
                />
              </React.Fragment>
            );
          }
        )}
      </ul>

      {!isReportView && (
        <FluroAutocomplete
          id={`search-crops-autocomplete`}
          label={''}
          placeholder={t({id: 'CP.benchmark-card.search-crops-placeholder'})}
          menuItems={cropItemsToSearch}
          onAutocomplete={(seasonId: number) => onUpdateRecordsToProcess(seasonId)}
          value=""
          className={'search-crops-autocomplete'}
          simplifiedMenu={false}
          position={Autocomplete.Positions.TOP_LEFT}
          anchor={{
            x: SelectField.HorizontalAnchors.INNER_RIGHT,
            y: SelectField.VerticalAnchors.TOP,
          }}
        />
      )}

      {data.datasets.length > 4 && ( // shows only manually added crops
        <ul className={cn('legend-list')}>
          {data.datasets.slice(4).map(data => {
            return (
              <CropItem
                key={data.seasonId}
                data={data}
                popupVisible={popupVisible}
                hasHighlightedData={hasHighlightedData}
                highlightedDataSetIds={highlightedDataSetIds}
                onUpdateRecordsToProcess={onUpdateRecordsToProcess}
                onHighlightDataSets={onHighlightDataSets}
              />
            );
          })}
        </ul>
      )}
    </>
  );
};

type CropItemProps = {
  data: UpdatedDataSet;
  automaticallyCalculated?: boolean;
  popupVisible: boolean;
  hasHighlightedData: boolean;
  onHighlightDataSets: (seasonId: number) => void;
  highlightedDataSetIds: number[];
  onUpdateRecordsToProcess: (seasonId: number, event: React.MouseEvent<HTMLElement>) => void;
};

const CropItem = ({
  data,
  automaticallyCalculated,
  onHighlightDataSets,
  hasHighlightedData,
  highlightedDataSetIds,
  popupVisible,
  onUpdateRecordsToProcess,
}: CropItemProps) => {
  const {seasonId, backgroundColor, seasonName, cropType, cropVariety, fieldName} = data;
  return (
    <li
      key={seasonId}
      onClick={() => onHighlightDataSets(seasonId)}
      className={cn({
        'legend-list-item': true,
        deselected: hasHighlightedData && !highlightedDataSetIds.includes(seasonId),
        'crop-item': true,
        'pop-up-visible': popupVisible,
      })}
    >
      <div>
        <ItemName>
          <span className={'field-name'}>
            <ColoredBullet style={{backgroundColor}} />
            <CropAvatar cropType={cropType} />
            {fieldName}
          </span>
          <div className="crop-info-container">
            {cropVariety ? <span className={'crop-variety'}>[{cropVariety}]</span> : null}
            {seasonName ? <span className={'season-name'}> {seasonName}</span> : null}
          </div>
        </ItemName>
      </div>
      {!automaticallyCalculated && (
        <FontIcon onClick={ev => onUpdateRecordsToProcess(seasonId, ev)} className={'item-remove'}>
          close
        </FontIcon>
      )}
    </li>
  );
};

export default ChartView;
