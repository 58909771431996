import {t} from 'i18n-utils';
import type {ComponentType} from 'react';
import React from 'react';
import L from 'leaflet';
import './index.sass';
import {GeoJSON} from 'react-leaflet';
import moment from 'moment';
import AnomalyLabelsDropdown from '../labels-dropdown';

import {setDate, togglePopup} from '../../../actions';
import {removePremiumAnomaly, updatePremiumAnomaly} from 'containers/map/actions/anomalies-actions';
import {getAnalyticsData} from 'containers/map/actions/analytics-actions';
import {GeometriesPopup} from 'components/anomalies-ui/geometries-popup/geometries-popup';

import {COLOR_GRAY, COLOR_BLUE, COLOR_DARK_BLUE} from '_constants/geometries-colors';
import type {IAnomaly, TAnomalyProps, AnomalyHistoryItem} from '../types';
import type {Feature} from 'geojson';
import {RequestStatus} from '../../../../../types';
import Mixpanel from '_utils/mixpanel-utils';
import {resolveEventHandlers} from '../../../../../components/fluro-leaflet/fix-click-handler';
import {useAppDispatch, useAppSelector} from '_hooks';
import {
  selectCurrentDates,
  selectCurrentTab,
  selectGeometriesOnMap,
  selectIsReadOnly,
  selectMapOpenPopUpId,
} from 'containers/map/reducer/selectors';
import {selectPremiumAnomalies} from 'containers/map/features/anomalies/anomalies-selectors';
import {selectAnalyticsItemColor} from 'containers/map/features/analytics/analytics-selectors';

const PremiumAnomaliesOverlay: ComponentType = () => {
  const geometriesOnMap = useAppSelector(selectGeometriesOnMap);
  const {list, status} = useAppSelector(selectPremiumAnomalies);

  const sortedAnomaliesRings = [
    ...list.filter(a => !a.properties.clogged_nozzle),
    ...list.filter(a => a.properties.clogged_nozzle),
  ];

  if (!geometriesOnMap || status === RequestStatus.Idle) {
    return null;
  }
  return (
    <>
      {sortedAnomaliesRings.map((anomaly, i) => (
        <Anomaly key={'anomaly-overlay' + i} anomaly={anomaly} />
      ))}
    </>
  );
};

type AnomalyProps = {
  anomaly: IAnomaly;
};

const Anomaly: ComponentType<AnomalyProps> = ({anomaly}) => {
  const dispatch = useAppDispatch();
  const currentDates = useAppSelector(selectCurrentDates);
  const currentTab = useAppSelector(selectCurrentTab);
  const openPopupId = useAppSelector(selectMapOpenPopUpId);
  const readOnly = useAppSelector(selectIsReadOnly);
  const nextPointColor = useAppSelector(selectAnalyticsItemColor);

  const onChangeProp = <K extends keyof TAnomalyProps>(
    prop: keyof TAnomalyProps,
    value: TAnomalyProps[K],
    anomaly: IAnomaly
  ) => {
    dispatch(
      updatePremiumAnomaly({
        id: anomaly.properties.anomaly_id,
        prop,
        value,
        anomaly,
      })
    );
  };

  const openAnomalyDate = (anomaly: AnomalyHistoryItem) => {
    const anomalyDate = moment(anomaly.sensing_date, 'YYYY-MM-DD');
    const existingDate = Object.keys(currentDates).find(date => {
      return moment(date, 'DD/MM/YYYY').isSame(anomalyDate, 'day');
    });
    if (!existingDate) {
      return;
    }
    dispatch(setDate(existingDate));
    dispatch(togglePopup(anomaly.id));
  };

  const onEachFeature = (feature: Feature, layer: L.Path) => {
    const {anomaly_id, snoozed, checked, label, color, priority} = feature.properties || {};
    layer.setStyle({
      fillOpacity: 0.8,
    });

    if (priority === 'new') {
      layer.setStyle({
        color: '#30614F',
        fillColor: '#435DA0',
      });
    }

    if (priority === 'low') {
      layer.setStyle({
        color: '#30614F',
        fillColor: '#43A047',
      });
    }

    if (priority === 'med') {
      layer.setStyle({
        color: '#30614F',
        fillColor: '#F1CF1E',
      });
    }

    if (priority === 'high') {
      layer.setStyle({
        color: '#30614F',
        fillColor: '#FA6767',
      });
    }

    if (label === 'irrigation_issue') {
      layer.setStyle({
        color: '#db03fc',
        weight: 1.6,
        fillOpacity: 0,
      });
    }

    if (snoozed) {
      layer.setStyle({
        color: COLOR_GRAY,
        fillOpacity: 0.2,
      });
    }

    if (openPopupId === anomaly_id) {
      layer.setStyle({
        color: COLOR_DARK_BLUE,
        fillColor: COLOR_BLUE,
      });
    }

    if (checked) {
      layer.setStyle({
        color: COLOR_DARK_BLUE,
        fillColor: COLOR_BLUE,
      });
    }

    if (!label) {
      layer.setStyle({
        dashArray: '4',
      });
    }

    const isAnalitycs = currentTab === 'analytics';
    if (isAnalitycs && color) layer.setStyle({color});

    layer.on({
      click: resolveEventHandlers({
        click: e => {
          const anomaly = e.target.feature;
          if (isAnalitycs) {
            L.DomEvent.stopPropagation(e);
            const {
              properties: {color, anomaly_id},
            } = anomaly;
            const layerColor = !color ? nextPointColor : null;
            dispatch(
              updatePremiumAnomaly({
                id: anomaly_id,
                prop: 'color',
                value: layerColor,
                anomaly,
              })
            );
            if (layerColor) {
              Mixpanel.displayCropStressOnAnalytics(anomaly_id);
              dispatch(getAnalyticsData());
            }
          } else {
            dispatch(togglePopup(anomaly_id));
          }
        },
      })?.click,
    });
  };

  const onDeleteAnomaly = (anomalyId: number) => {
    dispatch(removePremiumAnomaly([anomalyId]));
  };

  const {anomaly_id} = anomaly.properties;
  const popup = openPopupId && openPopupId === anomaly_id;
  return (
    <>
      <GeoJSON
        data={anomaly}
        pane={'field-geometry'}
        onEachFeature={onEachFeature}
        key={new Date().getTime()}
      />
      {popup && (
        <GeometriesPopup
          isAnomaly
          data={anomaly}
          type={'low'}
          sectionName={t({id: 'Stress detected', defaultMessage: 'Stress detected'})}
          onClose={() => dispatch(togglePopup(undefined))}
          onDelete={() => onDeleteAnomaly(anomaly.properties.anomaly_id)}
          onSnooze={() =>
            dispatch(
              updatePremiumAnomaly({
                id: anomaly.properties.anomaly_id,
                prop: 'snoozed',
                value: anomaly.properties.snoozed ? 0 : 1,
                anomaly,
              })
            )
          }
          LabelDropdown={({label}) => (
            <AnomalyLabelsDropdown
              miniButton
              disabled={readOnly}
              anomaly={anomaly}
              label={label}
              onChange={(prop, value, anomaly) => onChangeProp(prop, value, anomaly as IAnomaly)}
            />
          )}
          sensingDate={anomaly.properties.sensing_date}
          onHistoryItemClick={(anomaly: AnomalyHistoryItem) => {
            openAnomalyDate(anomaly);
          }}
        />
      )}
    </>
  );
};

export default PremiumAnomaliesOverlay;
