import type {Zone} from '../../../types';
import {t} from 'i18n-utils';
import React from 'react';

export const TreesAndProductAmount = ({zones}: {zones: Zone[]}) => {
  const isTree = zones.every(z => z.isTree);

  const amountTrees = isTree ? zones.reduce((res, z) => (z.number ? res + z.number : res), 0) : 0;

  return (
    <>
      {amountTrees ? (
        <div className={'zones-entities-amount'}>
          <div className={'entity-name'}>{t({id: 'Total number of trees'})}:</div>
          <div className={'entity-value'}>{t({id: '{count} tree'}, {count: amountTrees})}</div>
        </div>
      ) : null}
    </>
  );
};
