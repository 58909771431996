import React, {useMemo} from 'react';
import {t} from 'i18n-utils';
import {sensorView} from '_utils';
import {Range, createSliderWithTooltip} from 'rc-slider';
import {useAppDispatch, useAppSelector} from '_hooks';
import {loadZoningData, updateZonesRange} from '../../actions/zoning-actions';
import {
  selectCurrentSensor,
  selectTreeDetectionZoningScale,
  selectZoning,
} from 'containers/map/reducer/selectors';

export const CustomZoningForm = () => {
  const dispatch = useAppDispatch();
  const zoning = useAppSelector(selectZoning);
  const currentSensor = useAppSelector(selectCurrentSensor);

  const {min, max} = useAppSelector(selectTreeDetectionZoningScale);

  const onSliderStop = (values: number[]) => {
    dispatch(updateZonesRange(values));
    dispatch(loadZoningData());
  };

  const rangeLength = parseInt(zoning.classes) - 1; // the length of the range is always "number of zones" -1

  const RangeSlider = useMemo(() => {
    const RangeWithTooltip = createSliderWithTooltip(Range); // create a new one to apply "defazoning.zonesRangeultValue" and avoid handling the "value" prop

    return rangeLength !== 0 ? (
      <RangeWithTooltip
        className={'range-slider'}
        defaultValue={zoning.zonesRange}
        onAfterChange={onSliderStop}
        step={0.01}
        count={rangeLength}
        min={min}
        max={max}
        tipFormatter={(value: number) => `${value}`}
      />
    ) : null;
  }, [zoning.zonesRange, min, max, rangeLength]);

  return (
    <div className="custom-zoning-container">
      <div className="title">{t({id: 'Custom method'})}</div>
      <div className={'sensor-range'}>
        {sensorView(currentSensor)} {zoning.zonesRange.join(' - ')}
      </div>
      {RangeSlider}
    </div>
  );
};
