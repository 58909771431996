import React, {useEffect, useState} from 'react';
import {useAppSelector} from '_hooks';
import {
  selectReportAvailableSourcingRegions,
  selectReportDashboardData,
  selectReportPageIndex,
  selectReportPageSize,
} from 'containers/si/module/selectors';
import {selectCropTypesCDLIdMap} from 'modules/global/selectors';
import {getRegionName} from 'containers/si/planning-report/dashboard/utils/utils';
import {BarChart} from 'containers/si/kpi/charts/components/bar-chart/bar-chart';
import {REPORT_DASHBOARD_COLORS} from 'containers/si/colors';
import {Text} from 'components';
import {AdjustableCard} from '../../styled-components';

type LabelBarChartProps = {
  label: string;
  value: number;
  barDetails: {
    color: string;
    scenario: number;
  };
};

export const BarChartLabelCard = () => {
  const dashboardData = useAppSelector(selectReportDashboardData);
  const cropsById = useAppSelector(selectCropTypesCDLIdMap);
  const availableSourcingRegions = useAppSelector(selectReportAvailableSourcingRegions);

  // Used for the numbering in the circle
  const pageIndex = useAppSelector(selectReportPageIndex);
  const pageSize = useAppSelector(selectReportPageSize);
  const startNumber = pageIndex * pageSize + 1;

  const [barChartData, setBarChartData] = useState<LabelBarChartProps[]>([]);

  useEffect(() => {
    // Generate Chart Data
    const cardData: LabelBarChartProps[] = [];

    dashboardData.map((scenario, index) => {
      const cropName = cropsById[Number(scenario.crop_type)]?.label || scenario.crop_type;
      const regionName = getRegionName(scenario, availableSourcingRegions);

      // Bars don't allow duplicate names, so need to figure out how to allow that since the name might be duplicated with multiple scenarios
      const cardLabel = `${index}+++${regionName}+++${cropName}+++${scenario.id}`;

      return cardData.push({
        barDetails: {
          color: REPORT_DASHBOARD_COLORS[index],
          scenario: startNumber + index,
        },
        label: cardLabel,
        value: 0,
      });
    });

    setBarChartData(cardData);
  }, [availableSourcingRegions, cropsById, dashboardData, startNumber]);

  return (
    <AdjustableCard className="emission-card" min={200} max={240}>
      <Text bold className="scenarios" variant="h3">
        Scenarios
      </Text>
      <div className="chart-container">
        <BarChart
          data={barChartData}
          colors={[]}
          margin={{bottom: 40, left: 138}}
          minValue={0}
          maxValue={0}
          enableLabel={false}
          enableGridX={false}
          enableGridY={false}
          sorted={false}
          isInteractive={false}
          axisBottom={null}
          layers={['axes']}
          axisLeft={{
            renderTick: tick => {
              const xValue = tick.x - 100; // position of the circle and text
              const xCircleValue = xValue - 15 - 4;
              // The tickIndex is flipped, so take the length of the data and subtract the index, then add the start number
              const circleIndex = barChartData.length - tick.tickIndex - 1 + startNumber;
              const colorIndex = barChartData.length - tick.tickIndex - 1;
              let regionName = tick.value.split('+++')[1] || 'No data';
              regionName =
                regionName.length > 30 ? `${regionName.substring(0, 30)}...` : regionName;
              const cropName = tick.value.split('+++')[2] || 'No data';

              return (
                <g transform={`translate(${tick.x},${tick.y})`}>
                  <circle r={12} fill={REPORT_DASHBOARD_COLORS[colorIndex]} cx={xCircleValue} />
                  <text textAnchor="middle" alignmentBaseline="middle" x={xCircleValue}>
                    {circleIndex}
                  </text>
                  <text
                    textAnchor="start"
                    x={xValue}
                    style={{
                      fontSize: '12px',
                      fill: '#000',
                    }}
                    dy={0}
                  >
                    <tspan x={xValue} style={{fontWeight: 600}}>
                      {regionName}
                    </tspan>
                    <tspan x={xValue} dy={'12px'}>
                      {cropName}
                    </tspan>
                  </text>
                </g>
              );
            },
          }}
        />
      </div>
    </AdjustableCard>
  );
};
