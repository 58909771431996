// @ts-nocheck
import React, {useEffect, useMemo, useState, useContext, useRef} from 'react';
import {
  ListWrapper,
  ParsedDataTableWrapper,
} from '../field-properties-parser/field-properties-parser.styled';
import {FontIcon} from 'react-md';
import {t} from 'i18n-utils';
import {
  bulkSetUploadFieldsProp,
  removeUploadFields,
  setPropDrawnFieldGeometry,
  bulkSetPropDrawnFieldGeometry,
  setUploadFieldProp,
  removeDrawnFieldsGeometry,
  setAddFieldCurrentStep,
  saveDrawFields,
  uploadFields,
  bulkUpdateCLUFieldBoundariesProp,
  saveSelectedBoundaries,
  updateCLUFieldBoundariesProp,
  removeCLUFieldBoundaries,
  selectDrawnFieldGeometries,
} from 'modules/add-fields';
import {
  selectAddingFieldsStep,
  selectBoundariesToUpload,
  selectBoundaryIdsToUpload,
  selectGeoJsonFiles,
} from 'modules/add-fields/selectors';
import {FieldSystemProp, NewFieldError} from '../types';
import {AutoSizer, CellMeasurerCache, List} from 'react-virtualized';
import {useAppDispatch, useAppSelector, usePrevious} from '_hooks';
import type {Farm} from '../../../../types';
import {AssignFarmsDialog} from '../assign-farms-dialog';
import {FluroButton, InfoBlock, ReadOnly, Text, Flex} from 'components';
import {AsyncStatusType, Status} from 'modules/helpers';
import {showNotification} from 'components/notification/notification';
import {skeletonContext} from 'skeleton';
import {selectIsAdmin} from 'containers/login/login-selectors';
import {selectFarms} from 'modules/farms/selectors';
import {selectCurrentFarmId, selectIsEditingMode} from 'containers/map/reducer/selectors';
import {selectAsyncRequestStatus} from 'modules/global/selectors';
import {ContainersSeparator} from 'components/reusable-conponents.styled';
import {saveFarm} from 'modules/farms/actions';
import type {CLUFieldBoundaryProps} from 'modules/add-fields/types';
import {useFieldErrors} from '../base/use-field-errors';
import {NewFieldPreviewRow} from '../base/new-field-preview-row';

/**
 * Important! things too keep in mind
 * currently, there are three sources to get fields for this component:
 * 1. view-fields-from-files
 * 2. view-drawn-fields
 * 3. view-selected-boundaries
 *
 * all main fields .properties{} are unified through all sources using SystemProps
 * there is a fieldsToProcess() which classifies fields to display/update etc.
 * have a glance at the whole file structure before adding something new, thank you
 */

export const PreviewFieldsStep = () => {
  const dispatch = useAppDispatch();
  const geoJsonFiles = useAppSelector(selectGeoJsonFiles);
  const drawnFieldsGeometries = useAppSelector(selectDrawnFieldGeometries);
  const farms = useAppSelector(selectFarms);
  const currentStep = useAppSelector(selectAddingFieldsStep);
  const isEditingMode = useAppSelector(selectIsEditingMode);
  const currentFarmId = useAppSelector(selectCurrentFarmId);
  const boundaryIdsToUpload = useAppSelector(selectBoundaryIdsToUpload);
  const boundariesToUpload = useAppSelector(selectBoundariesToUpload);
  const isAdmin = useAppSelector(selectIsAdmin);
  const isUploading =
    useAppSelector(s => selectAsyncRequestStatus(s, AsyncStatusType.uploadingFieldsToBackend)) ===
    Status.Pending;

  const {onFieldsUploaded} = useContext(skeletonContext);

  const [assignFarmsPopUpVisible, toggleAssignFarmsPopUpVisibility] = useState(false);

  const prev = usePrevious({currentFarmId});
  const rvRef = useRef();

  const fieldsToProcess = useMemo(() => {
    switch (currentStep) {
      case 'view-fields-from-files':
        return geoJsonFiles;

      case 'view-drawn-fields':
        return drawnFieldsGeometries;

      case 'view-selected-boundaries':
        return boundariesToUpload;
    }
  }, [currentStep, geoJsonFiles, drawnFieldsGeometries, boundariesToUpload]);

  const selectedFieldIds = useMemo(() => {
    return fieldsToProcess
      .filter(f => f.properties[FieldSystemProp.Checked])
      .map(f => f.properties[FieldSystemProp.Id]);
  }, [fieldsToProcess]);

  const {errors, getFieldErrors} = useFieldErrors(fieldsToProcess);

  useEffect(
    function handleAllFieldsRemovingCase() {
      if (!fieldsToProcess.length && !isUploading) {
        switch (currentStep) {
          case 'view-fields-from-files':
            dispatch(setAddFieldCurrentStep('select-files-to-upload'));
            break;
          case 'view-drawn-fields':
            dispatch(setAddFieldCurrentStep('draw-fields'));
            break;
          case 'view-selected-boundaries':
            dispatch(setAddFieldCurrentStep('select-boundaries'));
            break;
        }
      }
    },
    [fieldsToProcess.length, isUploading]
  );

  useEffect(() => {
    // clear the cache so that all the row heights get recalculated
    rowHeightCache.clearAll();
  }, [fieldsToProcess]);

  useEffect(
    function onCurrentFarmChange() {
      /**
       * update field's farmId prop for associated with the current farm fields
       */
      const fieldsWithCurrentFarmId = fieldsToProcess.filter(
        f => f.properties[FieldSystemProp.FarmId] === prev?.currentFarmId
      );

      if (prev?.currentFarmId && currentFarmId && fieldsWithCurrentFarmId.length) {
        setFarmForFields(
          currentFarmId,
          fieldsWithCurrentFarmId.map(f => f.properties[FieldSystemProp.Id])
        );
      }
    },
    [currentFarmId]
  );

  const onUpdateFieldProp = (id: string, prop: FieldSystemProp, value: any) => {
    switch (currentStep) {
      case 'view-fields-from-files':
        dispatch(setUploadFieldProp(id, prop, value));
        break;
      case 'view-drawn-fields':
        dispatch(setPropDrawnFieldGeometry(id, prop, value));
        break;
      case 'view-selected-boundaries':
        dispatch(updateCLUFieldBoundariesProp(id, prop as keyof CLUFieldBoundaryProps, value));
    }
  };

  const onChangeFieldName = (id: string, value: number | string) => {
    onUpdateFieldProp(id, FieldSystemProp.FieldName, value);
  };

  const onSelectField = (id: string, value: boolean) => {
    onUpdateFieldProp(id, FieldSystemProp.Checked, value);
  };

  const onBulkUpdateFieldsProp = (ids: string[], value: any, prop: FieldSystemProp) => {
    switch (currentStep) {
      case 'view-fields-from-files': {
        const classifiedFieldIds = ids
          ? ids
          : geoJsonFiles.map(f => f.properties[FieldSystemProp.Id]);
        dispatch(bulkSetUploadFieldsProp(classifiedFieldIds, prop, value));
        break;
      }

      case 'view-drawn-fields': {
        const classifiedFieldIds = ids
          ? ids
          : drawnFieldsGeometries.map(f => f.properties[FieldSystemProp.Id]);
        dispatch(bulkSetPropDrawnFieldGeometry(classifiedFieldIds, prop, value));
        break;
      }

      case 'view-selected-boundaries': {
        const classifiedFieldIds = ids || boundaryIdsToUpload;

        dispatch(
          bulkUpdateCLUFieldBoundariesProp(
            classifiedFieldIds,
            prop as keyof CLUFieldBoundaryProps,
            value
          )
        );
      }
    }
  };

  const onRemoveField = (id: string) => {
    const ids = [id];
    switch (currentStep) {
      case 'view-fields-from-files':
        dispatch(removeUploadFields(ids));
        break;

      case 'view-drawn-fields':
        dispatch(removeDrawnFieldsGeometry(ids));
        break;

      case 'view-selected-boundaries':
        dispatch(removeCLUFieldBoundaries(ids));
        break;
    }
  };

  const setFarmForFields = (farmId: number, forceFieldIds?: string[]) => {
    const fieldIdsToProcess = forceFieldIds || selectedFieldIds;
    const selectedFarm: Farm = farms[farmId];

    if (selectedFarm) {
      onBulkUpdateFieldsProp(fieldIdsToProcess, '', FieldSystemProp.NewFarmName); // reset possible custom farm name value
      onBulkUpdateFieldsProp(fieldIdsToProcess, farmId, FieldSystemProp.FarmId);
    }
  };

  const setNewFarmForSelectedFields = (farmName: string, fields: string[]) => {
    switch (currentStep) {
      case 'view-fields-from-files':
        dispatch(bulkSetUploadFieldsProp(fields, FieldSystemProp.FarmId, 0)); // reset farm ID when set a custom farm name
        dispatch(bulkSetUploadFieldsProp(fields, FieldSystemProp.NewFarmName, farmName)); //in uploadFields() will create a new farm
        break;

      case 'view-drawn-fields':
        dispatch(bulkSetPropDrawnFieldGeometry(fields, FieldSystemProp.FarmId, 0)); // reset farm ID when set a custom farm name
        dispatch(bulkSetPropDrawnFieldGeometry(fields, FieldSystemProp.NewFarmName, farmName));
        break;
      case 'view-selected-boundaries':
        dispatch(bulkUpdateCLUFieldBoundariesProp(fields, FieldSystemProp.FarmId, 0)); // reset farm ID when set a custom farm name
        dispatch(bulkUpdateCLUFieldBoundariesProp(fields, FieldSystemProp.NewFarmName, farmName));
        break;
    }
  };

  const createNewFarm = async (farmName: string) => {
    const newFarm = await dispatch(saveFarm({id: 0, name: farmName}));
    return newFarm?.id;
  };

  const onMoveToThePrevStep = () => {
    switch (currentStep) {
      case 'view-fields-from-files':
        dispatch(
          // reset possible farmId values
          bulkSetUploadFieldsProp(
            geoJsonFiles.map(f => f.properties[FieldSystemProp.Id]),
            FieldSystemProp.FarmId,
            0
          )
        );
        dispatch(setAddFieldCurrentStep('parse-uploading-files'));
        break;
      case 'view-drawn-fields':
        dispatch(setAddFieldCurrentStep('draw-fields'));
        break;
      case 'view-selected-boundaries':
        dispatch(setAddFieldCurrentStep('select-boundaries'));
    }
  };

  const tryUploadFields = async () => {
    if (errors[NewFieldError.FieldNameError]?.size) {
      showNotification({
        title: t({id: 'note.warning', defaultMessage: 'Warning'}),
        message: t({id: 'Some fields have invalid names, please correct.'}),
        type: 'warning',
      });
      return;
    }

    if (errors[NewFieldError.FieldFarmNameError]?.size) {
      showNotification({
        title: t({id: 'note.warning', defaultMessage: 'Warning'}),
        message: t({id: 'Make sure you select a farm first.'}),
        type: 'warning',
      });
      return;
    }

    if (isEditingMode) {
      showNotification({
        title: t({id: 'note.warning', defaultMessage: 'Warning'}),
        message: t({id: '`Please save or cancel your changes before saving'}),
        type: 'warning',
      });
      return;
    }

    switch (currentStep) {
      case 'view-fields-from-files':
        dispatch(uploadFields(onFieldsUploaded));
        break;
      case 'view-drawn-fields':
        dispatch(saveDrawFields(onFieldsUploaded));
        break;
      case 'view-selected-boundaries':
        dispatch(saveSelectedBoundaries(onFieldsUploaded));
    }
  };

  return (
    <>
      <ParsedDataTableWrapper className={'preview-fields'}>
        <Text variant={'medium'}>
          {t({
            id: 'Make sure all of your fields are assigned to a farm. You can also rename your fields. Once all of your fields are assigned to a farm, you will be able to upload them.',
          })}
        </Text>
        <ContainersSeparator />

        {errors[NewFieldError.FieldFarmNameError]?.size > 0 && (
          <InfoBlock mini color={'error'} appearance={'error'} title={'Missing farms.'}>
            {t(
              {id: '{count} {count, plural, one {field} other {fields}} not assigned to farms.'},
              {count: errors[NewFieldError.FieldFarmNameError]?.size}
            )}
          </InfoBlock>
        )}

        <Flex
          className={'margin-top-15 margin-bottom-10'}
          alignItems={'center'}
          fullWidth
          justifyContent={'space-between'}
        >
          <Text elementType={'div'} secondary variant={'medium'}>
            {t(
              {
                id: '{count1}/{count2} fields assigned to farms.',
              },
              {
                count1: fieldsToProcess.length - errors[NewFieldError.FieldFarmNameError]?.size,
                count2: fieldsToProcess.length,
              }
            )}
          </Text>
          <FluroButton
            raised
            grayBorder
            blank={!errors[NewFieldError.FieldFarmNameError]?.size}
            primary={!!errors[NewFieldError.FieldFarmNameError]?.size}
            className={'assign-to-farms-btn'}
            onClick={() => toggleAssignFarmsPopUpVisibility(true)}
          >
            {t({id: 'Assign to farms'})}
          </FluroButton>
        </Flex>

        <ListWrapper>
          <AutoSizer>
            {({width, height}) => {
              return (
                <List
                  ref={rvRef}
                  createFolder
                  width={width}
                  height={height}
                  rowHeight={rowHeightCache.rowHeight}
                  deferredMeasurementCache={rowHeightCache}
                  rowRenderer={props => {
                    const field = fieldsToProcess[props.index];
                    const fieldId = field.properties?.[FieldSystemProp.Id];
                    return (
                      <NewFieldPreviewRow
                        field={field}
                        errors={getFieldErrors(fieldId)}
                        rowHeightCache={rowHeightCache}
                        onRemoveField={onRemoveField}
                        onChangeFieldName={onChangeFieldName}
                        {...props}
                      />
                    );
                  }}
                  rowCount={fieldsToProcess.length}
                  overscanRowCount={10}
                />
              );
            }}
          </AutoSizer>
        </ListWrapper>

        <div className="new-fields-nav-container in-right-panel">
          <FluroButton
            id={currentStep === 'view-fields-from-files' ? 'back-btn' : 'add-more-fields'}
            raised
            blank
            noPadding
            iconEl={currentStep === 'view-fields-from-files' ? null : <FontIcon>add</FontIcon>}
            onClick={onMoveToThePrevStep}
          >
            {currentStep === 'view-fields-from-files'
              ? t({id: 'BtnLabel.Back'})
              : t({id: 'Add fields'})}
          </FluroButton>

          <ReadOnly>
            <FluroButton
              id={'save-new-fields'}
              raised
              primary
              onClick={tryUploadFields}
              loading={isUploading}
              disabled={
                isUploading ||
                errors[NewFieldError.FieldNameError]?.size > 0 ||
                errors[NewFieldError.FieldFarmNameError]?.size > 0 ||
                (errors[NewFieldError.FieldSizeError]?.size > 0 && !isAdmin) // If the user is an admin, allow the large field upload.
              }
            >
              {t({id: 'Upload fields'})}
            </FluroButton>
          </ReadOnly>
        </div>
      </ParsedDataTableWrapper>

      <AssignFarmsDialog
        visible={assignFarmsPopUpVisible}
        fieldsToProcess={fieldsToProcess}
        onBulkSelectFields={(value, fieldIds) =>
          onBulkUpdateFieldsProp(fieldIds, value, FieldSystemProp.Checked)
        }
        getFieldErrors={getFieldErrors}
        onSelectField={onSelectField}
        onChangeFieldName={onChangeFieldName}
        selectedFieldIds={selectedFieldIds}
        setFarmForFields={setFarmForFields}
        setNewFarmForSelectedFields={setNewFarmForSelectedFields}
        closeDialog={() => toggleAssignFarmsPopUpVisibility(false)}
        createNewFarm={createNewFarm}
      />
    </>
  );
};

const rowHeightCache = new CellMeasurerCache({
  fixedWidth: true,
  defaultHeight: 55,
});
