import React from 'react';
import cn from 'classnames';
import {FontIcon} from 'react-md';
import './expandable-table-items.scss';
import styled from 'styled-components';

type ExpandableAreaProps = {
  onClick(): void;
  expanded: boolean;
};

export const ExpandableArea = ({onClick, expanded}: ExpandableAreaProps) => {
  return (
    <div onClick={onClick} className={cn('expandable-area', {_expanded: expanded})}>
      <FontIcon>{expanded ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}</FontIcon>
    </div>
  );
};

export const ExpandableRowContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0 15px 0 0;
  border-bottom: 1px solid #ccc;
  width: 100%;

  .md-selection-control-container,
  label {
    max-width: 300px;
  }
  span {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &:last-of-type {
    border-bottom: 0;
  }
  &.selected {
    .md-selection-control-container label {
      // color the checkbox label to green
      color: #43a047;
    }
  }
`;

// same as .sub-entities-container
export const SubContainer = styled.div`
  padding-top: 10px;
  padding-left: 20px;
  border-bottom: 1px solid #ccc;
`;

// same as .sub-entity-item
export const SubItem = styled.div`
  display: flex;
`;

export const NoItemsTitle = styled.h4`
  margin-left: 15px;
  margin-top: 5px;
  font-weight: 500;
`;
