import moment from 'moment';
import {t} from 'i18n-utils';
import tokml from '_utils/tokml';
import {featureCollection} from '@turf/turf';

import {ActionTypes} from '../reducer/types';
import type {SamplingPoint, SamplingPointProps} from '../types';
import {ActivityApi, KmlApi} from '_api';

import {GLOBAL_FORMAT_DATE} from '_constants';
import SAMPLING_GROWTH_STAGES from '_constants/growth-stages';

import {showNotification} from 'components/notification/notification';
import {formatDate} from '_utils';
import {downloadFile} from '_utils/pure-utils';
import {changeSeason, setSensor} from '../actions';
import {isDifferentDatesPoints, getPointById} from '../utils/sampling-points';
import {reportError} from '../../error-boundary';
import Mixpanel from '_utils/mixpanel-utils';
import type {AppStore} from 'reducers';
import type {AppDispatch} from 'store';
import {
  selectCurrentFarm,
  selectCurrentField,
  selectCurrentPointsGroups,
  selectCurrentSeason,
  selectCurrentSensor,
  selectIsReadOnly,
  selectPointsCurrentGroupDate,
} from 'containers/map/reducer/selectors';
import {selectAnalyticsItemColor} from 'containers/map/features/analytics/analytics-selectors';

const NON_REQUEST_PROPS = ['checked']; // send request only if it is necessary

export const setCurrentMarker =
  (id: any, position: Array<any> = []) =>
  (dispatch: any) => {
    dispatch({
      type: ActionTypes.MAP_POINTS_SET_CURRENT,
      id,
      position,
    });
  };

export const savePoint =
  (point: SamplingPoint, suggestedPoints = false) =>
  (dispatch: AppDispatch, getState: () => AppStore) => {
    const state = getState();
    const currentSeason = selectCurrentSeason(state);
    const pointsGroups = selectCurrentPointsGroups(state);
    const pointBeforeUpdate = getPointById(point.id, pointsGroups.all);

    return ActivityApi.updateSamplingPoint(currentSeason?.kmlId, Number(currentSeason?.id), point)
      .then(({data}) => {
        const isNew = point.id === 'new';

        if (isNew) {
          point.id = data;
          point.properties = point.properties || {};
          point.properties.color = selectAnalyticsItemColor(state);
          Mixpanel.addNewPoint(point);
        }

        dispatch({
          type: ActionTypes.MAP_POINTS_SAVE,
          point,
          suggestedPoints,
        });

        if (!suggestedPoints) {
          showNotification({
            title: t({id: 'note.success', defaultMessage: 'Success'}),
            message: isNew ? t({id: 'Point was saved.'}) : t({id: 'Point was updated.'}),
            type: 'success',
          });
        }
        if (
          isNew
            ? point?.properties?.n_result
            : pointBeforeUpdate?.properties?.n_result !== point?.properties?.n_result
        ) {
          // show message is N result was changed
          dispatch(checkNmapchanges());
        }

        try {
          // @ts-expect-error lefeletElement exists
          window.leafletElement.closePopup();
        } catch (e) {}
      })
      .catch();
  };

export const updatePointProps =
  <K extends keyof SamplingPointProps>(
    point: SamplingPoint,
    properties: {[key in keyof SamplingPointProps]: SamplingPointProps[K]}
  ) =>
  (dispatch: any, getState: any) => {
    const {currentSeason} = getState().map;

    const updatedPoint = {
      ...point,
      properties: {
        ...point.properties,
        ...properties,
      },
    };

    dispatch({
      type: ActionTypes.MAP_POINTS_SAVE,
      point: updatedPoint,
    });

    if (Object.keys(properties).some(p => !NON_REQUEST_PROPS.includes(p))) {
      return ActivityApi.updateSamplingPoint(
        currentSeason.kmlId,
        currentSeason.id,
        updatedPoint
      ).then(() => {
        if (properties.n_result !== undefined) {
          dispatch(checkNmapchanges());
        }
        showNotification({
          title: t({id: 'note.success', defaultMessage: 'Success'}),
          message: t({id: 'Point was updated.'}),
          type: 'success',
        });
      });
    }
  };
export const removeSamplingPoint =
  (pointId: number | 'new') => async (dispatch: AppDispatch, getState: () => AppStore) => {
    const state = getState();
    const readOnly = selectIsReadOnly(state);
    const currentSeason = selectCurrentSeason(state);
    const pointsCurrentGroupDate = selectPointsCurrentGroupDate(state);
    const currentSensor = selectCurrentSensor(state);
    const pointsGroups = selectCurrentPointsGroups(state);

    const shouldDisableNmap = // disable NMAP if not enough points with n_result
      pointsGroups[pointsCurrentGroupDate]?.filter(
        (p: SamplingPoint) => p.id !== pointId && p?.properties?.n_result > 0
      )?.length < 3;

    if (readOnly) {
      showNotification({
        title: t({id: 'note.warning', defaultMessage: 'Warning'}),
        message: t({id: 'readOnlyFarm'}),
        type: 'warning',
      });
      return;
    }

    if (pointId !== 'new') {
      await ActivityApi.removeSamplingPoint(
        currentSeason?.kmlId,
        Number(currentSeason?.id),
        pointId
      ).catch();
      showNotification({
        title: t({id: 'note.success', defaultMessage: 'Success'}),
        message: t({id: 'Point was deleted.'}),
        type: 'success',
      });
      if (currentSensor === 'NMAP' && shouldDisableNmap) {
        dispatch(setSensor('NDVI'));
      } else {
        dispatch(checkNmapchanges());
      }
    }

    dispatch({
      type: ActionTypes.MAP_POINTS_REMOVE_ONE,
      id: pointId,
      recalculateDates: shouldDisableNmap, // to remove NMAP from available layers
    });
  };

export const updateTSMarkersPosition =
  (positions: any) => (dispatch: AppDispatch, getState: () => AppStore) => {
    const currentSeason = selectCurrentSeason(getState());

    dispatch({
      type: ActionTypes.MAP_POINTS_UPDATE_POSITION,
      positions,
    });

    if (!currentSeason) {
      return;
    }

    const pointsToUpdate = currentSeason?.tissueSampling
      ?.filter((ts: SamplingPoint) => positions[ts.id] && ts.id !== 'new')
      ?.map((point: SamplingPoint) =>
        ActivityApi.updateSamplingPoint(currentSeason.kmlId, Number(currentSeason?.id), point)
      );

    Promise.all(pointsToUpdate || []).then(() => {
      showNotification({
        title: t({id: 'note.success', defaultMessage: 'Success'}),
        message: t({id: 'Your points position was updated.'}),
        type: 'success',
      });

      dispatch(checkNmapchanges());
    });
  };

const checkNmapchanges = () => (dispatch: AppDispatch, getState: () => AppStore) => {
  // show message if the nmap related things were changed
  const currentSensor = selectCurrentSensor(getState());

  if (currentSensor === 'NMAP') {
    showNotification({
      title: t({id: 'note.success', defaultMessage: 'Success'}),
      message: t({id: 'The nitrogen map was updated.'}),
      type: 'success',
    });
  }
};

export const pointsSetGroupDate = (value: string) => (dispatch: any) => {
  dispatch({
    type: ActionTypes.MAP_POINTS_SET_GROUP_DATE,
    value,
  });

  dispatch({
    type: ActionTypes.MAP_UPDATE_N_DATA,
    data: [],
  });
};

export const bulkUpdatePointsProp =
  (pointIds: number[], pointProperty: keyof SamplingPointProps, value: any) =>
  async (dispatch: AppDispatch, getState: () => AppStore) => {
    const currentSeason = selectCurrentSeason(getState());

    if (!currentSeason) {
      return;
    }

    dispatch({
      type: ActionTypes.MAP_POINTS_BULK_UPDATE_PROP,
      pointIds,
      pointProperty,
      value,
    });

    if (!NON_REQUEST_PROPS.includes(pointProperty)) {
      const preparedPointsToUpdate = currentSeason?.tissueSampling
        ?.filter((point: SamplingPoint) => pointIds.includes(point.id))
        ?.map((point: SamplingPoint) => {
          const updatedPoint = {
            ...point,
            properties: {
              ...point.properties,
              [pointProperty]: value,
            },
          };
          return ActivityApi.updateSamplingPoint(
            currentSeason.kmlId,
            Number(currentSeason?.id),
            updatedPoint
          );
        });

      await Promise.all(preparedPointsToUpdate || []).catch();

      showNotification({
        title: t({id: 'note.success', defaultMessage: 'Success'}),
        message: t({id: 'Your points group was updated.'}),
        type: 'success',
      });
    }
  };

export const generateNitrogenMap =
  (groupdId: number, fieldId: number | string, UpdatedDates: any) =>
  (dispatch: AppDispatch, getState: () => AppStore) => {
    KmlApi.updateNitrogenDates(groupdId, fieldId, UpdatedDates)
      .then(() => {
        dispatch({
          type: ActionTypes.GENERATE_NITROGEN_MAP,
          date: UpdatedDates,
        });

        showNotification({
          title: t({id: 'note.success', defaultMessage: 'Success'}),
          message: t({id: 'Nitrogen map was generated.'}),
          type: 'success',
        });
        dispatch(changeSeason(getState().map.currentSeasonId, 'NMAP'));
      })
      .catch(err => reportError(`generateNitrogenMap() err = ${err}`));
  };

export const exportPointsToKml = () => (dispatch: AppDispatch, getState: () => AppStore) => {
  const state = getState();
  const field = selectCurrentField(state);
  const farm = selectCurrentFarm(state);
  const currentSeason = selectCurrentSeason(state);
  const pointsCurrentGroupDate = selectPointsCurrentGroupDate(state);
  const pointsGroups = selectCurrentPointsGroups(state);

  const fileName = `${farm?.name}_${field.Name}`;
  const pointsToExport = [] as any;
  const checkedPoints = pointsGroups[pointsCurrentGroupDate]?.filter(
    (point: SamplingPoint) => point?.properties?.checked
  );
  const isMixedDates = pointsCurrentGroupDate === 'all' && isDifferentDatesPoints(checkedPoints);
  const groupFormattedDate = isMixedDates
    ? 'mixed_dates'
    : pointsCurrentGroupDate === 'all'
    ? moment(checkedPoints?.[0]?.properties?.timedate, GLOBAL_FORMAT_DATE).format(
        GLOBAL_FORMAT_DATE
      ) // if all is selected but there are points only for one date
    : moment(pointsCurrentGroupDate, formatDate()).format(GLOBAL_FORMAT_DATE);

  checkedPoints.forEach((point: SamplingPoint) => {
    let _point = {
      ...point,
      geometry: {
        type: 'Point',
        coordinates: [...point.geometry.coordinates],
      },
    };

    _point.geometry.coordinates = [_point.geometry.coordinates[1], _point.geometry.coordinates[0]];
    const {title, n_result, n_result2, timedate} = _point.properties || {};
    _point.properties = {
      fieldName: field?.Name,
      farmName: farm.name,
      Sample_ID: title,
      Nitrogen: n_result,
      Nitrate_PPM: n_result2 || '',
      GroupDate: moment(timedate).format('YYYY-MM-DD'),
      Classes: _point?.properties?.classes || 0,
      SamplingType: _point?.properties?.samplingType || '',
      Description: _point?.properties?.description || '',
    };
    //@ts-expect-error error leftover from convertion to strict mode, please fix
    if (currentSeason.cropType && SAMPLING_GROWTH_STAGES[currentSeason.cropType]) {
      //@ts-expect-error error leftover from convertion to strict mode, please fix
      const gs = SAMPLING_GROWTH_STAGES[currentSeason.cropType].find(
        (el: string) => el === point?.properties?.growthStage
      );
      _point.properties.Growth_Stage = gs || '';
    }

    pointsToExport.push(_point);
  });

  downloadFile(
    tokml(featureCollection(pointsToExport)),
    `Sampling_Points_${fileName}_${groupFormattedDate}.kml`
  );
};
