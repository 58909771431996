// @ts-nocheck
import React, {useCallback, useMemo} from 'react';
import FluroAutocomplete from '../autocomplete';
import StateChip from './state-chip';
import Ln from 'components/ln';
import {Wrapper} from './multi-select-chips.styled';

type Props = {
  id?: string;
  placeholder?: string;
  className?: string;
  items: {value: number | string; label: any; group?: string | number}[];
  label?: string;
  selectedItems: any[];
  onSelectChip: (values: any[]) => void;
  hasLinksToFarms?: boolean;
  error?: boolean;
  errorText?: string;
  notRemovableList?: (number | string)[];
  onType?: (value: number | string) => void;
};

const MultiSelectChips = ({
  id,
  placeholder = '',
  className = '',
  items = [],
  onSelectChip,
  selectedItems = [],
  label = 'Select farm',
  hasLinksToFarms,
  error,
  errorText,
  notRemovableList = [],
  onType,
}: Props) => {
  const addChip = useCallback(
    (value: number) => {
      onSelectChip([...selectedItems, value]);
    },
    [selectedItems]
  );

  const removeChip = useCallback(
    (item: any) => {
      onSelectChip(selectedItems.filter(selected => item.value !== selected));
    },
    [selectedItems]
  );

  const stopEvent = useCallback((e: any) => {
    e.stopPropagation();
  }, []);

  const chips = useMemo(() => {
    const selItems = items.filter((g: any) => selectedItems.includes(g.value));

    const groups: {[group: string]: any[]} = {};

    selItems.forEach(item => {
      const g = item.group || '';
      if (groups[g]) {
        groups[g].push(item);
      } else {
        groups[g] = [item];
      }
    });

    return Object.keys(groups).map(group => {
      return (
        <div key={group}>
          {group ? <div className="g-name">{group}</div> : null}
          <div>
            {groups[group].map(item => (
              <StateChip
                key={item.value}
                state={{
                  ...item,
                  label: hasLinksToFarms ? (
                    <Ln map onClick={stopEvent} blank href={item.value + ''}>
                      {item.label}
                    </Ln>
                  ) : (
                    item.label
                  ),
                }}
                removable={!notRemovableList.includes(item.value)}
                onClick={removeChip}
              />
            ))}
          </div>
        </div>
      );
    });
  }, [items, selectedItems, hasLinksToFarms, notRemovableList]);

  return (
    <Wrapper>
      <FluroAutocomplete
        id={`${id}-autocomplete`}
        label={label}
        placeholder={placeholder}
        menuItems={items.filter((item: any) => !selectedItems.includes(item.value))}
        onAutocomplete={addChip}
        className={className}
        value=""
        autoComplete="chrome-off"
        simplifiedMenu={false}
        error={error}
        errorText={errorText}
        onType={onType}
      />
      {chips}
    </Wrapper>
  );
};

export default MultiSelectChips;
