import type {AppStore} from 'reducers';
import {FieldGeometryApi, KmlApi} from '_api';
import {reportError} from '../../error-boundary';
import {showNotification} from 'components/notification/notification';
import {updateEachFeatureProperties} from '_utils/geometry';
import {ActionTypes} from '../reducer/types';
import {getFieldSoilLayer, getFieldsWithAllDates} from '../utils/field';
import {getDatesFromAllFields} from '../utils';
import {t} from 'i18n-utils';
import type {Field, ITreeData} from '../types';
import {Tags as PremiumAppTag} from 'containers/admin/features/types';
import {loadFarmTreeData} from '../utils/trees';
import {AsyncStatusType, setRequestStatus, Status} from '../../../modules/helpers';
import axios from 'axios';
import {
  selectCurrentFarmId,
  selectFieldGeometries,
  selectMapFields,
  selectWholeFarmData,
} from 'containers/map/reducer/selectors';
import {selectAnalyticsItemColor} from 'containers/map/features/analytics/analytics-selectors';
import {chunkArray} from '_utils/pure-utils';
import type {AppDispatch} from 'store';

const loadFieldsForWholeFarm =
  (farmId: number, fieldIds: number[]) => async (dispatch: AppDispatch) => {
    dispatch(setRequestStatus(AsyncStatusType.fieldsData, Status.Pending));

    let fetchedFields: Field[] = [];

    const loadFieldsPromise = (fieldIds: number[]) => {
      return KmlApi.getFileListByIDs(farmId, fieldIds)
        .then(({data}) => {
          dispatch(setRequestStatus(AsyncStatusType.fieldsData, Status.Done));
          const {fields} = data.result;
          fetchedFields = [...fetchedFields, ...fields];
        })
        .catch(e => {
          reportError(`Couldn't loadFieldsForWholeFarm for farmId: ${farmId}. Error: ${e}`);
          dispatch(setRequestStatus(AsyncStatusType.fieldsData, Status.Done));
        });
    };

    // for large farms, we need to chunk the requests to avoid overloading the server
    // example of a huge farm - crop-insights/14592/ - it has 398 fields, the server just fails and the response size is 545MB, so split it
    const chunkedFieldsArray = chunkArray(fieldIds, 150);

    for (let i = 0; i < chunkedFieldsArray.length; i++) {
      await loadFieldsPromise(chunkedFieldsArray[i]);
    }

    return fetchedFields;
  };
/**
 * Fetch both kml geometries and field data for all the fields in the farm. + try to load tree detection data
 */
export const loadWholeFarmData = () => async (dispatch: any, getState: () => AppStore) => {
  const state = getState();
  const fields = selectMapFields(state);
  const mapFieldGeometries = selectFieldGeometries(state);
  const wholeFarm = selectWholeFarmData(state);
  const farmId = selectCurrentFarmId(state);

  const fieldsToRequest = fields.filter(f => !f.files || !mapFieldGeometries[f.MD5]);
  const fieldsInAmerica = fields.filter(f => f.Country === 'United States');
  const geometriesToRequest = fieldsToRequest
    .filter(f => !wholeFarm.fieldsWithDates[f.MD5] || !mapFieldGeometries[f.MD5])
    .map(f => f.MD5);

  const shouldLoadTreeDetection = fieldsToRequest.find(f =>
    f.tags?.includes(PremiumAppTag.TreeAnalysis)
  );

  let fieldGeometries: any,
    rawSoilMapData: string | null = null,
    fieldsResponse: Field[] = [],
    treeDetectionData: {[fieldId: number]: ITreeData[]} = {};

  const soilMapRequest = fieldsInAmerica.length
    ? KmlApi.loadFieldSoilMap(fields.map(({MD5}) => MD5))
        .then(({data}) => data)
        .catch(e => {
          if (!axios.isCancel(e)) {
            reportError(`loadFieldSoilMap err=${e}`);
          }
        })
    : Promise.resolve(null);

  fields.forEach(field => {
    if (field.treeDetectionRawData) {
      treeDetectionData[Number(field.FieldID)] = field.treeDetectionRawData;
    }
  });

  const loadedBeforeFields = fields.filter(f => f.files && mapFieldGeometries[f.MD5]);
  if (fieldsToRequest.length) {
    dispatch(setRequestStatus(AsyncStatusType.wholeFarmData, Status.Pending)); // set request status Pending
    // make a call only if it is necessary
    [fieldGeometries, fieldsResponse, treeDetectionData, rawSoilMapData] = await Promise.all([
      FieldGeometryApi.featureCollections(geometriesToRequest).then(({data}) => data.data),
      dispatch(
        loadFieldsForWholeFarm(
          farmId,
          fieldsToRequest.map(f => f.ID)
        )
      ),
      shouldLoadTreeDetection ? loadFarmTreeData(farmId) : Promise.resolve({}),
      soilMapRequest,
    ]);
  }

  if ((!fieldGeometries || !fieldsResponse.length) && !loadedBeforeFields.length) {
    dispatch(setRequestStatus(AsyncStatusType.wholeFarmData, Status.Done)); // set request status Done
    const message = t({
      id: 'Failed to load some of the fields for the whole farm view.',
      defaultMessage: 'Failed to load some of the fields for the whole farm view.',
    });
    reportError(message);
    showNotification({
      title: t({id: 'note.error', defaultMessage: 'Error'}),
      message,
      type: 'error',
    });
    return;
  }

  const preparedSoilLayersData = rawSoilMapData ? JSON.parse(rawSoilMapData) : null;
  const fieldsToProcess = fieldsResponse?.length
    ? [...loadedBeforeFields, ...fieldsResponse]
    : loadedBeforeFields;

  fieldsToProcess.forEach(f => {
    if (!fieldGeometries?.[f.MD5]) return;
    updateEachFeatureProperties(fieldGeometries[f.MD5], {fluro_id: f.ID});
  });

  dispatch({
    type: ActionTypes.MAP_SET_FIELD_GEOMETRIES,
    fieldGeometries,
  });

  const {fieldsObject, tsps} = getFieldsWithAllDates(fieldsToProcess, treeDetectionData);

  const allFarmTSP = tsps.map(tsp => {
    const currentColors = tsps
      .filter(tsp => tsp?.properties?.color)
      .map(tsp => tsp?.properties?.color);
    tsp.properties = tsp.properties || {};
    tsp.properties.color = selectAnalyticsItemColor(getState(), currentColors);
    return tsp;
  });

  dispatch({
    type: ActionTypes.MAP_SET_WHOLE_FARM_FIELDS_GEOMETRY,
    allDates: getDatesFromAllFields(fieldsObject, fieldsToProcess),
    fieldsWithDates: fieldsObject,
    allFarmTSP,
    fields: fieldsToProcess.map((f: Field) => {
      const oldField = fields.find((stateF: Field) => stateF.ID === f.ID) || {};
      const season = f.Seasons && f.Seasons.length && f.Seasons[f.Seasons.length - 1];

      return {
        ...oldField,
        ...f,
        _selected: false,
        files: fieldsObject[f.MD5],
        treeDetectionRawData: treeDetectionData[Number(f.FieldID)],
        GDD: (season && season?.gdd) || 0,
        NDVI: (season && season?.ndvi) || 0,
        Delta: (season && season?.delta) || 0,
        soilLayer: getFieldSoilLayer(preparedSoilLayersData, f.MD5),
      };
    }),
  });
  // especially set the status just at the end, to prevent loading regular whole farm images when there is tree detection, FSB-4297
  dispatch(setRequestStatus(AsyncStatusType.wholeFarmData, Status.Done)); // set request status Done
};
