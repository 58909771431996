// @ts-nocheck
import React from 'react';
import {useDispatch} from 'react-redux';
import EditGeometryForm from './edit-geometry-form';
import {toggleEditingMode} from '../../actions';
import {
  removeAreaOfInterest,
  changeAreaOfInterestProp,
  toggleEditAreaOfInterest,
  updateAreaOfInterest,
  createAreaOfInterest,
} from '../../actions/areas-of-interest-actions';
import FluroDialog from 'components/fluro-dialog';
import type {IAnomaly} from './types';
import {useAppSelector} from '_hooks';
import {useLeafletContext} from '@react-leaflet/core';
import {selectMeasurement} from '../../../login/login-selectors';
import {selectViewport} from 'modules/global/selectors';
import {
  selectCurrentDate,
  selectCurrentSeason,
  selectCurrentSensor,
  selectEditGeometry,
} from 'containers/map/reducer/selectors';

/**
 * Add Area of Interest dialog.
 */
export const AddAreaOfInterestDialog = () => {
  const dispatch = useDispatch();
  const editGeometry = useAppSelector(selectEditGeometry);
  const measurement = useAppSelector(selectMeasurement);
  const currentSensor = useAppSelector(selectCurrentSensor);
  const currentSeason = useAppSelector(selectCurrentSeason);
  const currentDate = useAppSelector(selectCurrentDate);
  const viewport = useAppSelector(selectViewport);

  const leafletElement = useLeafletContext().map;
  const {state, geometry}: {state: boolean; geometry: IAnomaly} = editGeometry;

  const removeDraftGeometryFromMap = (id: number) => {
    leafletElement.eachLayer(l => {
      // @ts-expect-error fluroGeometryID is not a standard part of a leaflet layer.
      if (l.fluroGeometryID === id) {
        leafletElement.removeLayer(l);
      }
    });
  };

  const remove = (geometry: IAnomaly) => {
    removeDraftGeometryFromMap(geometry.properties.id);
    dispatch(removeAreaOfInterest(geometry.properties.id));
    dispatch(toggleEditAreaOfInterest(false));
  };

  const removeOnClose = (geometry: IAnomaly) => {
    dispatch(toggleEditAreaOfInterest(false));
    if (geometry.properties.id === 0) {
      removeDraftGeometryFromMap(geometry.properties.id);
    }
  };

  const enableEditGeometry = (geometry: IAnomaly) => {
    dispatch(changeAreaOfInterestProp(geometry, 'saved', 'edit'));
    dispatch(toggleEditAreaOfInterest(false, geometry));
    dispatch(toggleEditingMode(true));
  };

  const saveGeometry = async (geometry: IAnomaly) => {
    if (geometry.properties.id === 0) {
      await dispatch(createAreaOfInterest(geometry));
    } else {
      await dispatch(updateAreaOfInterest(geometry));
    }

    // remove draft geometry from map, because it will appear as geojson shape
    removeDraftGeometryFromMap(geometry.properties.id);
    dispatch(toggleEditAreaOfInterest(false));
  };

  return (
    <FluroDialog
      id="geometry-show-pop-up"
      portal
      isDraggable
      fullPage={viewport.width < 561}
      centered={viewport.width >= 561}
      visible={state}
      onHide={() => removeOnClose(geometry)}
    >
      <EditGeometryForm
        geometry={geometry}
        measurement={measurement}
        currentSensor={currentSensor}
        currentSeason={currentSeason}
        currentDate={currentDate}
        onClose={() => removeOnClose(geometry)}
        onEdit={() => enableEditGeometry(geometry)}
        onDelete={() => remove(geometry)}
        onSubmit={saveGeometry}
      />
    </FluroDialog>
  );
};
