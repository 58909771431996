export default {
  cotton: ['Emergence', 'Square', 'First white bloom', 'Peak bloom', 'First open ball'],
  wheat: [
    'Germination/emergence',
    'Tillering (1-5)',
    'Stem elongation (6-10)',
    'Booting',
    'Heading/flowering (10)',
    'Grain-fill/ripening (11)',
  ],
  barley: [
    'Germination/emergence (1-11)',
    'Tillering',
    'Stem elongation',
    'Booting (12)',
    'Heading/flowering (13-14)',
    'Grain-fill/ripening (15)',
  ],
  corn: [
    'VE (emergence)',
    'V1 (first leaf)',
    'V2 (second leaf)',
    'V3 (third leaf)',
    'V(n) (nth leaf)',
    'VT (tasseling)',
    'R1 (silking)',
    'R2 (blister)',
    'R3 (milk)',
    'R4 (dough)',
    'R5 (dent)',
    'R6 (physiological maturity)',
  ],
  lentils: [
    'VE (emergence)',
    'V1 (first leaf)',
    'V2 (second leaf)',
    'V3 (third leaf)',
    'V(n) (nth leaf)',
    'VT (tasseling)',
    'R1 (early bloom)',
    'R2 (full bloom)',
    'R3 (early pod visible)',
    'R4 (flat pod)',
    'R5 (early seed)',
    'R6 (full seed)',
  ],
  chickpeas: [
    'VE (emergence)',
    'V1 (first leaf)',
    'V2 (second leaf)',
    'V3 (third leaf)',
    'V(n) (nth leaf)',
    'VT (tasseling)',
    'R1 (early bloom)',
    'R2 (full bloom)',
    'R3 (early pod visible)',
    'R4 (flat pod)',
    'R5 (early seed)',
    'R6 (full seed)',
  ],
  rice: [
    'Germination/emergence',
    'Transplanting',
    'Tillering',
    'Booting',
    'Panicle initiation ',
    'Heading/flowering',
    'Grain-fill/ripening',
    'Maturity',
  ],
  canola: [
    'Germination',
    'Sprouting Development',
    'Leaf Development',
    'Stem Elongation',
    'Inflorescence Emergence',
    'Flowering',
    'Development of Seed',
    'Ripening',
    'Senescence',
  ],
  potatoes: [
    'Sprout development',
    'Vegetative growth',
    'Tuber initiation',
    'Tuber Bulking',
    'Maturation',
  ],
  tomatoes: [
    'Planting',
    'Vegetative',
    'First flowering',
    'First fruit set',
    'Fruit growth ',
    'Starting harvest',
  ],
  cassava: ['Seedling', 'Vegetative stage', 'Reproductive stage', 'Maturation stage'],
  almonds: ['Blossom', 'Maturity', 'Hull split', 'Harvest'],
  oranges: [
    'Pre-flower',
    'Flowering',
    'Fruit Set',
    'Fruit Enlargement and Maturation',
    'Harvest',
    'Post Harvest',
    'Senescence',
  ],
  lemon: [
    'Pre-flower',
    'Flowering',
    'Fruit Set',
    'Fruit Enlargement and Maturation',
    'Harvest',
    'Post Harvest',
    'Senescence',
  ],
  lime: [
    'Pre-flower',
    'Flowering',
    'Fruit Set',
    'Fruit Enlargement and Maturation',
    'Harvest',
    'Post Harvest',
    'Senescence',
  ],
  pistachios: [
    'Pre-flower',
    'Flowering',
    'Fruit Set',
    'Fruit Enlargement and Maturation',
    'Harvest',
    'Post Harvest',
    'Senescence',
  ],
  cherries: [
    'Pre-flower',
    'Flowering',
    'Fruit Set',
    'Fruit Enlargement and Maturation',
    'Harvest',
    'Post Harvest',
    'Senescence',
  ],
  carrots: [
    'Seedling emergence',
    '1-4 true leaves',
    '5-6 true leaves',
    'Mature/harvest ',
    'First potential harvest',
  ],
  spinach: [
    'Seedling emergence',
    '1-4 true leaves',
    '5-6 true leaves',
    'Mature/harvest ',
    'First potential harvest',
  ],
};
