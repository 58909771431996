// @ts-nocheck
import type {PayloadAction} from '@reduxjs/toolkit';
import {createSlice} from '@reduxjs/toolkit';
import {LoginActionTypes} from '../../../../login/types';

export const accessSlice = createSlice({
  name: 'monitoring/access',
  initialState: {producer: [], admin: []},
  reducers: {
    addAccess: (state, action: PayloadAction<{producer: number[]; admin: number[]}>) => {
      state.producer = action.payload.producer;
      state.admin = action.payload.admin;
    },
  },
  extraReducers: builder => {
    builder.addCase(LoginActionTypes.LOGOUT, state => {
      return {
        ...state,
        producer: [],
        admin: [],
      };
    });
  },
});
