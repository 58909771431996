import React, {Component} from 'react';
import {connect} from 'react-redux';
import {parseNumber, EmptyStringNumber} from '_utils';
import {calcPlural} from '_utils/pure-utils';
import {
  FluroDatePicker,
  FluroTableColumn,
  FluroTableHeader,
  FluroTableRow,
  FluroDataTable,
  FluroTableBody,
  FluroButton,
} from 'components';
import {
  clearMarkers,
  saveMarkers,
  onSetMapping,
  handleOpenTS,
  testTSPMarkers,
  toggleTSPUploadConfirmationPopup,
} from './reducer';
import {t} from 'i18n-utils';

import {GLOBAL_FORMAT_DATE} from '_constants';

import {TextField, SelectField} from 'react-md';
import moment from 'moment';

import Yup from 'yup';
import {withFormik} from 'formik';
import './index.scss';
import {reportError} from '../../error-boundary';
import {WarningSvg} from 'components/icons';

const MappingSelect = props => (
  <SelectField
    id={`tsp-mapping-select-${props.id}`}
    label={''}
    className={'table-header-select'}
    placeholder={props.title}
    menuItems={props.menuItems}
    onChange={props.onSetMapping}
    value={props.value}
    simplifiedMenu={false}
  />
);

const applyMapping = (tsp = [], cellMaping = {}) => {
  let _tsp = [];
  const keys = Object.keys(cellMaping);

  tsp.forEach(m => {
    let _m = {};

    keys.forEach(key => {
      _m[cellMaping[key].mappedKey] = m[key];
    });

    _tsp.push(_m);
  });

  return _tsp;
};

const genMarkersId = (markers = []) => {
  return markers.map((m, i) => {
    m.id = i + 1;
    return {...m};
  });
};

class TSGrid extends Component {
  checkDuplicateMapping = () => {
    const {cellMapping} = this.props;

    const keys = {};
    const duplicatedColumns = [];
    Object.keys(cellMapping).forEach(key => {
      const cellKey = cellMapping[key].mappedKey;
      if (keys[cellKey]) duplicatedColumns.push(cellMapping[key].title);
      keys[cellKey] = key;
    });

    return duplicatedColumns;
  };

  drawCell = ({value, type, index, label, key, width}) => {
    const {errors} = this.props;

    switch (type) {
      case 'text':
      case 'number':
        return (
          <TextField
            type={type}
            style={{width}}
            id={`tsp-${key}-value-${index}`}
            label={label}
            lineDirection="center"
            placeholder={label}
            value={value}
            onChange={value =>
              this.props.setFieldValue(
                `markers[${index}].${key}`,
                type === 'number' ? parseNumber(value) : value
              )
            }
            error={!!(errors.markers && errors.markers[index] && errors.markers[index][key])}
            errorText={errors.markers && errors.markers[index] && errors.markers[index][key]}
          />
        );

      case 'date':
        return (
          <div style={{width}}>
            <FluroDatePicker
              id={`ts-${key}-marker-${index}`}
              selected={moment(value, GLOBAL_FORMAT_DATE)}
              onChange={value => {
                this.props.setFieldValue(`markers[${index}].${key}`, value.format());
              }}
              error={!!(errors.markers && errors.markers[index] && errors.markers[index][key])}
            />
          </div>
        );

      default:
        return <b>{t({id: 'Undefined data type'})}</b>;
    }
  };

  render() {
    const {values, handleSubmit, cellMapping, fileNames} = this.props;

    const cellMappingKeys = Object.keys(cellMapping || {});
    const cellMappingKeysH = ['No', ...cellMappingKeys];
    const hasDuplicatedColumns = this.checkDuplicateMapping();

    return (
      <form className={'tsp-up-form'} onSubmit={handleSubmit}>
        <div className={'selected-files-name'}>
          <b>Selected {calcPlural('file', fileNames)}:</b>
          {fileNames.map((n, i) => (
            <div key={`tsp-selected-file-${i}`}>{n}</div>
          ))}
        </div>
        <div className={'tsp-grid-upload'}>
          <FluroDataTable
            className="tsp-grid-upload-table"
            baseId="ts-markers-table"
            elevated={false}
          >
            <FluroTableHeader>
              <FluroTableRow>
                {cellMappingKeysH
                  .filter(key => !['samplingType', 'growthStage'].includes(key))
                  .map((key, i) =>
                    !i ? (
                      <FluroTableColumn
                        className={'table-header-number'}
                        key={`tsp-col-mapping-${key}-${i}`}
                      >
                        {key}.
                      </FluroTableColumn>
                    ) : (
                      <FluroTableColumn key={`tsp-col-mapping-${key}-${i}`}>
                        <MappingSelect
                          id={`tsp-col-mapping-${key}-${i}`}
                          value={cellMapping[key].mappedKey}
                          title={cellMapping[cellMapping[key].mappedKey].title}
                          menuItems={cellMappingKeys.map(c => ({
                            label: cellMapping[c].title,
                            value: c,
                          }))}
                          onSetMapping={this.props.onSetMapping.bind(this, key)}
                        />
                      </FluroTableColumn>
                    )
                  )}
              </FluroTableRow>
            </FluroTableHeader>

            <FluroTableBody>
              {values.markers.map((m, i) => (
                <FluroTableRow key={`field-tsp-row-${i}`} className="ts-upload-row">
                  <FluroTableColumn className={'table-body-number'}>
                    <img width={10} src="/assets/new-tsp-marker.svg" alt={`point-${i}`} /> {i + 1}
                  </FluroTableColumn>
                  <FluroTableColumn>
                    {this.drawCell({
                      value: m.sample_id,
                      type: cellMapping[cellMapping.sample_id.mappedKey].type,
                      index: i,
                      label: cellMapping.sample_id.title,
                      key: 'sample_id',
                      width: '80px',
                    })}
                  </FluroTableColumn>

                  <FluroTableColumn>
                    {this.drawCell({
                      value: m.sample_date,
                      type: cellMapping[cellMapping.sample_date.mappedKey].type,
                      index: i,
                      label: cellMapping.sample_date.title,
                      key: 'sample_date',
                      width: '150px',
                    })}
                  </FluroTableColumn>

                  <FluroTableColumn>
                    {this.drawCell({
                      value: m.lat,
                      type: cellMapping[cellMapping.lat.mappedKey].type,
                      index: i,
                      label: cellMapping.lat.title,
                      key: 'lat',
                      width: '100px',
                    })}
                  </FluroTableColumn>

                  <FluroTableColumn>
                    {this.drawCell({
                      value: m.lng,
                      type: cellMapping[cellMapping.lng.mappedKey].type,
                      index: i,
                      label: cellMapping.lng.title,
                      key: 'lng',
                      width: '100px',
                    })}
                  </FluroTableColumn>

                  <FluroTableColumn>
                    {this.drawCell({
                      value: m.n_result,
                      type: cellMapping[cellMapping.n_result.mappedKey].type,
                      index: i,
                      label: cellMapping.n_result.title,
                      key: 'n_result',
                      width: '80px',
                    })}
                  </FluroTableColumn>

                  <FluroTableColumn>
                    {this.drawCell({
                      value: m.n_result2,
                      type: cellMapping[cellMapping.n_result2.mappedKey].type,
                      index: i,
                      label: cellMapping.n_result2.title,
                      key: 'n_result2',
                      width: '80px',
                    })}
                  </FluroTableColumn>

                  {/*New*/}

                  <FluroTableColumn>
                    {this.drawCell({
                      value: m.total_K,
                      type: cellMapping[cellMapping.total_K.mappedKey].type,
                      index: i,
                      label: cellMapping.total_K.title,
                      key: 'total_K',
                      width: '80px',
                    })}
                  </FluroTableColumn>

                  <FluroTableColumn>
                    {this.drawCell({
                      value: m.total_P,
                      type: cellMapping[cellMapping.total_P.mappedKey].type,
                      index: i,
                      label: cellMapping.total_P.title,
                      key: 'total_P',
                      width: '80px',
                    })}
                  </FluroTableColumn>

                  <FluroTableColumn>
                    {this.drawCell({
                      value: m.Ca,
                      type: cellMapping[cellMapping.Ca.mappedKey].type,
                      index: i,
                      label: cellMapping.Ca.title,
                      key: 'Ca',
                      width: '80px',
                    })}
                  </FluroTableColumn>

                  <FluroTableColumn>
                    {this.drawCell({
                      value: m.Mg,
                      type: cellMapping[cellMapping.Mg.mappedKey].type,
                      index: i,
                      label: cellMapping.Mg.title,
                      key: 'Mg',
                      width: '80px',
                    })}
                  </FluroTableColumn>

                  <FluroTableColumn>
                    {this.drawCell({
                      value: m.Na,
                      type: cellMapping[cellMapping.Na.mappedKey].type,
                      index: i,
                      label: cellMapping.Na.title,
                      key: 'Na',
                      width: '80px',
                    })}
                  </FluroTableColumn>

                  <FluroTableColumn>
                    {this.drawCell({
                      value: m.S,
                      type: cellMapping[cellMapping.S.mappedKey].type,
                      index: i,
                      label: cellMapping.S.title,
                      key: 'S',
                      width: '80px',
                    })}
                  </FluroTableColumn>

                  <FluroTableColumn>
                    {this.drawCell({
                      value: m.Zn_ppm,
                      type: cellMapping[cellMapping.Zn_ppm.mappedKey].type,
                      index: i,
                      label: cellMapping.Zn_ppm.title,
                      key: 'Zn_ppm',
                      width: '80px',
                    })}
                  </FluroTableColumn>

                  <FluroTableColumn>
                    {this.drawCell({
                      value: m.Mn_ppm,
                      type: cellMapping[cellMapping.Mn_ppm.mappedKey].type,
                      index: i,
                      label: cellMapping.Mn_ppm.title,
                      key: 'Mn_ppm',
                      width: '80px',
                    })}
                  </FluroTableColumn>

                  <FluroTableColumn>
                    {this.drawCell({
                      value: m.Fe_ppm,
                      type: cellMapping[cellMapping.Fe_ppm.mappedKey].type,
                      index: i,
                      label: cellMapping.Fe_ppm.title,
                      key: 'Fe_ppm',
                      width: '80px',
                    })}
                  </FluroTableColumn>

                  <FluroTableColumn>
                    {this.drawCell({
                      value: m.Cu_ppm,
                      type: cellMapping[cellMapping.Cu_ppm.mappedKey].type,
                      index: i,
                      label: cellMapping.Cu_ppm.title,
                      key: 'Cu_ppm',
                      width: '80px',
                    })}
                  </FluroTableColumn>

                  <FluroTableColumn>
                    {this.drawCell({
                      value: m.B_ppm,
                      type: cellMapping[cellMapping.B_ppm.mappedKey].type,
                      index: i,
                      label: cellMapping.B_ppm.title,
                      key: 'B_ppm',
                      width: '80px',
                    })}
                  </FluroTableColumn>

                  <FluroTableColumn>
                    {this.drawCell({
                      value: m.Cl,
                      type: cellMapping[cellMapping.Cl.mappedKey].type,
                      index: i,
                      label: cellMapping.Cl.title,
                      key: 'Cl',
                      width: '80px',
                    })}
                  </FluroTableColumn>

                  <FluroTableColumn>
                    {this.drawCell({
                      value: m.Mo_ppm,
                      type: cellMapping[cellMapping.Mo_ppm.mappedKey].type,
                      index: i,
                      label: cellMapping.Mo_ppm.title,
                      key: 'Mo_ppm',
                      width: '80px',
                    })}
                  </FluroTableColumn>
                </FluroTableRow>
              ))}
            </FluroTableBody>
          </FluroDataTable>
        </div>

        <div className="tsp-up-toolbar">
          <FluroButton
            id="tsp-up-clear-points"
            raised
            onClick={this.props.handleOpenTS.bind(this, false)}
          >
            {t({id: 'Clear points'})}
          </FluroButton>

          {hasDuplicatedColumns.length !== 0 ? (
            <div className={'duplicated-columns'}>
              <WarningSvg /> {t({id: 'cannot save TSPs with duplicate columns :'})}{' '}
              {hasDuplicatedColumns.join(', ')}
            </div>
          ) : (
            <FluroButton id="tsp-up-save-points" raised primary type="submit">
              {t({id: 'Save points'})}
            </FluroButton>
          )}
        </div>
      </form>
    );
  }
}

// Yup.addMethod(yup.mixed, 'defined', function (msg = '$path must be defined') {
//   return this.test('defined', msg, (value) => value !== undefined)
// });

const emptyStringNumber = new EmptyStringNumber();

const FormicForm = withFormik({
  enableReinitialize: true,

  mapPropsToValues: props => ({
    markers: props.markers,
  }),

  validationSchema: props => {
    let itemSchema = {
      lat: emptyStringNumber.min(-90, 'Min -90').max(90, 'Max 90').required(),
      lng: emptyStringNumber.min(-180, 'Min -180').max(180, 'Max 180').required(),
      sample_date: Yup.string(),
      sample_id: Yup.string().max(30, 'Max length 30').required('Required field'),
      n_result: emptyStringNumber.max(100, 'Max 100').positive('Must be positive'),
      n_result2: emptyStringNumber.positive('Must be positive'),
      // new
      total_K: emptyStringNumber.max(100, 'Max 100').positive('Must be positive'),
      total_P: emptyStringNumber.max(100, 'Max 100').positive('Must be positive'),
      Ca: emptyStringNumber.max(100, 'Max 100').positive('Must be positive'),
      Mg: emptyStringNumber.max(100, 'Max 100').positive('Must be positive'),
      Na: emptyStringNumber.max(100, 'Max 100').positive('Must be positive'),
      S: emptyStringNumber.max(100, 'Max 100').positive('Must be positive'),
      Zn_ppm: emptyStringNumber.positive('Must be positive'),
      Mn_ppm: emptyStringNumber.positive('Must be positive'),
      Fe_ppm: emptyStringNumber.positive('Must be positive'),
      Cu_ppm: emptyStringNumber.positive('Must be positive'),
      B_ppm: emptyStringNumber.positive('Must be positive'),
      Cl: emptyStringNumber.max(100, 'Max 100').positive('Must be positive'),
      Mo_ppm: emptyStringNumber.positive('Must be positive'),
      growthStage: Yup.string(),
      samplingType: Yup.string(),
    };

    let fianlSchema = {};

    Object.keys(props.cellMapping).forEach(key => {
      fianlSchema[key] = itemSchema[props.cellMapping[key].mappedKey];
    });

    return Yup.object().shape({
      markers: Yup.array().of(Yup.object().shape(fianlSchema)),
    });
  },

  handleSubmit: (values, {props, setSubmitting}) => {
    setSubmitting(true);

    const markers = genMarkersId(applyMapping(values.markers, props.cellMapping) || []);

    props
      .testTSPMarkers(markers)
      .then(({result}) => {
        setSubmitting(false);

        // skip the confirmation popup
        if (result && !result.doesNotIntersect) {
          props.saveMarkers(markers, setSubmitting);
        } else {
          props.toggleTSPUploadConfirmationPopup(result, markers, setSubmitting);
        }
      })
      .catch(err => reportError(`TSGrid/>,  testTSPMarkers() err= ${err}`));
  },

  displayName: 'TSGrid',
})(TSGrid);

const mapStateToProps = state => ({
  markers: state.tsUpload.markers,
  cellMapping: state.tsUpload.cellMapping,
  fileNames: state.tsUpload.fileNames,
});

export default connect(mapStateToProps, {
  clearMarkers,
  saveMarkers,
  onSetMapping,
  handleOpenTS,
  testTSPMarkers,
  toggleTSPUploadConfirmationPopup,
})(FormicForm);
