import React from 'react';
import type {AvatarProps} from 'react-md';
import {Avatar} from 'react-md';
import cn from 'classnames';
import './index.scss';
import _config from '_environment';

type Props = AvatarProps & {
  cropType: string;
  iconSrc?: string;
  letter?: string;
  version?: number;
};

export const CropAvatar = ({
  className = '',
  cropType,
  iconSrc,
  letter,
  version,
  ...otherProps
}: Props) => {
  const cropLetter = letter || String(cropType).charAt(0).toUpperCase() || '?';
  const safeCropType = encodeURIComponent(cropType);
  const cacheBuster = version ? `?${version}` : '';
  const src =
    iconSrc || (cropType && `${_config.baseUrl}api/v1/crops/${safeCropType}/icon${cacheBuster}`);

  return (
    <Avatar
      {...otherProps}
      className={cn(`fluro-crop-avatar ${className}`)}
      suffix="white"
      src={src}
    >
      {cropLetter}
    </Avatar>
  );
};
