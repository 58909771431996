import styled from 'styled-components';
import {FluroTableColumn} from 'components/fluro-table-components';

type MatchStatusLabelProps = {
  color: string;
};

export const MatchStatusLabel = styled.span`
  padding: 5px 15px;
  border-radius: 3px;
  color: ${({color}: MatchStatusLabelProps) => color};
  border: 1px solid ${({color}: MatchStatusLabelProps) => color};
  white-space: nowrap;
`;

export const CTMWrapper = styled.div`
  padding: 0 15px;

  .md-table-column:first-child {
    padding-left: 5px;
  }
`;

export const ActionsColumn = styled(FluroTableColumn)`
  width: 100px;
  padding: 0;
  text-align: center;
`;

export const CropColumn = styled(FluroTableColumn)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;
