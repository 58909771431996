import type {ReactChildren, ReactElement, ReactNode} from 'react';
import React from 'react';
import {Wrapper, WrapperGroup, CPBadgeWrapper} from './badge.styled';
import variables from 'variables.scss';
import cn from 'classnames';

type Appearance = 'success' | 'warning' | 'alert';

type Props = {
  value: number | string | ReactChildren;
  appearance?: Appearance;
};

const COLORS: {[key in Appearance]: string} = {
  success: '#43a047',
  warning: variables['orange-papaya'],
  alert: '#f2405d',
};

export const Badge = ({appearance = 'warning', value}: Props) => (
  <Wrapper style={{backgroundColor: COLORS[appearance]}}>{value}</Wrapper>
);

export const BadgeGroup = ({children}: {children: ReactElement[]}) => (
  <WrapperGroup>{children}</WrapperGroup>
);

type CPBadgeProps = {
  backgroundColor?: string;
  color?: string;
  children: ReactNode;
  actionable?: boolean; // adds cursor: pointer;
  className?: string;
};

export const CPBadge = ({
  backgroundColor = '#fff',
  color = 'inherit',
  children,
  actionable,
  className,
}: CPBadgeProps) => (
  <CPBadgeWrapper
    className={cn(className, {'fluro-cp-badge': true, actionable})}
    style={{
      backgroundColor,
      color,
    }}
  >
    {children}
  </CPBadgeWrapper>
);
