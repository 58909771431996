import type {ComponentType, HTMLAttributes} from 'react';
import React from 'react';
import cn from 'classnames';
import './flex.scss';

export type FlexProps = HTMLAttributes<HTMLElement> & {
  alignItems?: React.CSSProperties['alignItems'];
  direction?: React.CSSProperties['flexDirection'];
  elementType?: React.ElementType;
  inline?: boolean;
  justifyContent?: React.CSSProperties['justifyContent'];
  nowrap?: boolean;
  basis?: string;
  gap?: React.CSSProperties['gap'];
  fullWidth?: boolean;
  margin?: string;
};

export const Flex: ComponentType<FlexProps> = ({
  alignItems,
  children,
  className,
  direction = 'row',
  elementType: RenderElement = 'div',
  inline = false,
  justifyContent,
  nowrap = false,
  style,
  basis,
  gap,
  fullWidth,
  margin,
  ...elementProps
}) => {
  return (
    <RenderElement
      className={cn(className, 'flex-element', {
        'flex-element--inline': inline,
        'flex-element--full-width': fullWidth,
      })}
      style={{
        flexWrap: nowrap ? 'nowrap' : 'wrap',
        basis,
        justifyContent,
        alignItems,
        flexDirection: direction,
        gap: gap,
        margin,
        ...style,
      }}
      {...elementProps}
      inherit={'true'}
    >
      {children}
    </RenderElement>
  );
};

Flex.displayName = 'Flex';
