import {Flex, FluroTabs, Text} from 'components';
import {CardBase} from 'components/card/card-base';
import {setChartTab, setFilter} from 'modules/sustainability-insights/actions';
import {
  selectCoverCropsChartTab,
  selectIsSIDataLoading,
  selectIsYearsDiff,
} from 'modules/sustainability-insights/selectors';
import type {SIChartTab} from 'modules/sustainability-insights/types';
import {SIGeometryFilterType} from 'modules/sustainability-insights/types';
import type {ComponentType} from 'react';
import React, {useEffect} from 'react';
import {useAppDispatch, useAppSelector} from '_hooks';
import {YearsFilterRangeLabel} from 'containers/map/features/sustainability-insights/years-filter-range-label';
import {CoverCropsSummaryChart} from './cover-crops-summary-chart';
import {CoverCropsTrendChart} from './cover-crops-trend-chart';
import {CardToolTip} from '../components/card-tooltip';
import {TOOLTIPS} from 'containers/si/constants';

type Props = {
  isActive?: boolean;
};

const InnerCoverCropsCard = () => {
  const dispatch = useAppDispatch();
  const selectedTab = useAppSelector(selectCoverCropsChartTab);
  const isRange = useAppSelector(selectIsYearsDiff);

  const setSelectedTab = (tab: SIChartTab) => {
    dispatch(setChartTab(SIGeometryFilterType.CoverCrops, tab));
  };

  useEffect(() => {
    if (!isRange && selectedTab === 'trend') {
      setSelectedTab('summary');
    }
    // setSelectedTab should not trigger the re-render
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRange, selectedTab]);

  const tabs = [
    {value: 'summary', label: 'Summary'},
    {value: 'trend', label: 'Trend'},
  ];

  return (
    <>
      {isRange && (
        <div style={{marginLeft: '-1rem', marginRight: '-1rem'}}>
          <FluroTabs tabs={tabs} selectedTab={selectedTab} onTabClick={setSelectedTab} />
        </div>
      )}
      <div>
        {selectedTab === 'summary' && <CoverCropsSummaryChart />}
        {selectedTab === 'trend' && <CoverCropsTrendChart />}
      </div>
    </>
  );
};

export const CoverCropsCard: ComponentType<Props> = ({isActive = false}) => {
  // const [openDialog, setOpenDialog] = useState(false);
  const dispatch = useAppDispatch();
  const isLoading = useAppSelector(selectIsSIDataLoading);

  const onFilterClick = () => {
    if (isActive || isLoading) return;
    dispatch(
      setFilter({
        activeGeometryType: SIGeometryFilterType.CoverCrops,
      })
    );
  };

  return (
    <CardBase
      active={isActive}
      className={'sustainability-card'}
      heading={
        <Flex nowrap justifyContent="space-between">
          <Text className="m-0" elementType="h3">
            Cover Crops <YearsFilterRangeLabel />
          </Text>
          <CardToolTip id="cover-crops-info" content={TOOLTIPS.KPI.coverCrops} place="left" />
        </Flex>
      }
      onClick={onFilterClick}
    >
      {isActive && <InnerCoverCropsCard />}
    </CardBase>
  );
};
