import {t} from 'i18n-utils';
import React, {useEffect, useMemo} from 'react';
import LowPerformingAnomalies from './low-perf-anomalies';
import StressDetectedList from './stress-detected-list';
import AreasList from './areas-list';
import AreasHistoryList from './areas-history-list';
import AnomaliesPanel from './anomalies-panel/index';

import {ExternalService} from '../../types';

import './index.scss';
import {toggleFieldGeometries} from '../../actions';
import {isAgxField} from '_utils/farm-utils';
import {ReadOnly, Sticky} from 'components';
import {AccessibleFakeButton, MenuButton} from 'react-md';
import {DownloadIcon} from '../../icons';
import {
  exportAnomaliesToKml,
  exportAnomaliesToShp,
  onExportToAgworld,
  onExportToAgX,
} from './anomalies-utils';
import {useAppDispatch, useAppSelector} from '_hooks';
import {
  selectAnomalies,
  selectIsAnomaliesTagExist,
  selectLowPerformingAnomalies,
  selectPerformanceGeometries,
  selectPremiumAnomalies,
} from './anomalies-selectors';
import {
  selectCurrentField,
  selectCurrentSeason,
  selectGeometriesOnMap,
} from '../../reducer/selectors';
import {useMapBarView} from '../../map-bar/map-bar-view';
import {DialogType} from 'modules/helpers';
import {selectDialogVisibility} from 'modules/helpers/selectors';
import NotAllowedGeometriesToExportToAgx from 'containers/dialogs/anomalies-to-agx';
import AddNewAnomalies from 'containers/dialogs/add-new-anomaly';

/**
 * Crop stress panel.
 */
const MapCrop = () => {
  const dispatch = useAppDispatch();
  const field = useAppSelector(selectCurrentField);
  const [wholeTableView] = useMapBarView();
  const geometriesOnMap = useAppSelector(selectGeometriesOnMap);
  const anomaliesSelectedTab = useAppSelector(selectAnomalies).anomaliesSelectedTab;
  const premiumAnomaliesList = useAppSelector(selectPremiumAnomalies).list;
  const visibleHistorySection = useAppSelector(selectAnomalies).historyOpen;
  const currentSeason = useAppSelector(selectCurrentSeason);
  const visibleLowPerformingAnomalies = useAppSelector(selectLowPerformingAnomalies).isVisible;
  const isAgworld = field.external_service === ExternalService.Agworld;
  const isAgx = isAgxField(field);
  const isCropStressTabSelected = anomaliesSelectedTab === 'crop-stress';
  const performanceGeometries = useAppSelector(selectPerformanceGeometries);
  const notAllowedGeometriesToExportToAgxDialogVisibility = useAppSelector(s =>
    selectDialogVisibility(s, DialogType.geometriesNotAllowedToExportToAgx)
  );
  const addNewAnomalyDialogVisible = useAppSelector(s =>
    selectDialogVisibility(s, DialogType.addNewAnomaly)
  );

  useEffect(() => {
    if (!geometriesOnMap) {
      dispatch(toggleFieldGeometries(true));
    }
  }, []);

  const lowPerformingAnomaliesAndRoi = useMemo(() => {
    return visibleHistorySection
      ? [...performanceGeometries.geometryToShow, ...performanceGeometries.historyGeometries]
      : performanceGeometries.geometryToShow;
  }, [performanceGeometries, visibleHistorySection]);

  const selectedAnomalies = isCropStressTabSelected
    ? premiumAnomaliesList.filter(a => a.properties.checked)
    : lowPerformingAnomaliesAndRoi.filter(({properties}) => properties.checked);

  const disabled = selectedAnomalies.length === 0;

  const displayExportButton =
    !visibleLowPerformingAnomalies &&
    !wholeTableView &&
    !!(isCropStressTabSelected ? premiumAnomaliesList.length : lowPerformingAnomaliesAndRoi.length);

  const exportMenuItems = useMemo(() => {
    const items = [];

    items.push(
      <AccessibleFakeButton
        key={`export-to-kml`}
        className="anomaly-header__menu-item export-kml"
        onClick={() => dispatch(exportAnomaliesToKml(selectedAnomalies))}
        disabled={disabled}
      >
        To KML
      </AccessibleFakeButton>
    );

    if (isAgworld && isCropStressTabSelected)
      items.push(
        <AccessibleFakeButton
          key={`export-to-agworld`}
          className="anomaly-header__menu-item export-to-agworld"
          onClick={() => dispatch(onExportToAgworld(selectedAnomalies))}
          disabled={disabled}
        >
          To Agworld
        </AccessibleFakeButton>
      );

    if (isAgx)
      items.push(
        <AccessibleFakeButton
          key={`export-to-agx`}
          className="anomaly-header__menu-item export-to-agx"
          onClick={() => dispatch(onExportToAgX(selectedAnomalies, anomaliesSelectedTab))}
          disabled={disabled}
        >
          To agX
        </AccessibleFakeButton>
      );

    items.push(
      <AccessibleFakeButton
        key={`export-to-shp`}
        className="anomaly-header__menu-item export-to-shape"
        onClick={() => dispatch(exportAnomaliesToShp(selectedAnomalies))}
        disabled={disabled}
      >
        To SHP
      </AccessibleFakeButton>
    );
    return items;
  }, [selectedAnomalies, disabled, isAgx, isAgworld, isCropStressTabSelected]);

  const tabTitle = selectIsAnomaliesTagExist(currentSeason)
    ? t({id: 'Crop Stress', defaultMessage: 'Crop Stress'})
    : t({id: 'Areas of Interest', defaultMessage: 'Areas of Interest'});
  const cropStressTabSelected = anomaliesSelectedTab === 'crop-stress';

  return (
    <div className={'section-container anomalies-tab'}>
      {!wholeTableView ? <h3 className="tab-title">{tabTitle}</h3> : null}

      {wholeTableView && <AnomaliesPanel tabTitle={tabTitle} />}
      {(wholeTableView || !cropStressTabSelected) && <AreasList />}
      {(wholeTableView || cropStressTabSelected) && <StressDetectedList />}

      {displayExportButton && (
        <Sticky className={'sticky-export-btn-container'}>
          <ReadOnly containerClass={'md-inline-block'}>
            <MenuButton
              id={`export-anomaly`}
              primary
              className="btn-with-icon"
              listClassName="anomaly-header__menu-list"
              raised
              disabled={disabled}
              iconEl={<DownloadIcon />}
              menuItems={exportMenuItems}
              position={'tr'}
            >
              {t({id: 'Export'})}
            </MenuButton>
          </ReadOnly>
        </Sticky>
      )}

      {!wholeTableView && !cropStressTabSelected ? (
        <>
          <LowPerformingAnomalies />
          <AreasHistoryList />
        </>
      ) : null}

      {notAllowedGeometriesToExportToAgxDialogVisibility && <NotAllowedGeometriesToExportToAgx />}
      {addNewAnomalyDialogVisible && <AddNewAnomalies />}
    </div>
  );
};

export default MapCrop;
