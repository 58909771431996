// @ts-nocheck
import {t, FormattedMessage} from 'i18n-utils';
import type {ComponentType} from 'react';
import React, {useMemo, useState} from 'react';
import {FontIcon, ListItem, MenuButton} from 'react-md';
import {ReadOnly, FluroButton} from 'components';

import {deleteBulkSeason, removeField} from '../../../actions';
import {DownloadIcon, EditIcon, UploadIcon} from '../../../icons';
import {CloneFieldPopup} from './clone-field';
import EditBulkSeason from './pop-ups/edit-bulk-season';
import type {Season} from 'containers/map/types';
import {dialogToggle, DialogType} from 'modules/helpers';
import {doesFieldHasSeasonWithNRx} from 'containers/map/features/nrx';
import {downloadFieldsBoundary} from '_utils/geometry';
import {useAppDispatch, useAppSelector} from '_hooks';
import {
  selectCurrentFarm,
  selectIsReadOnly,
  selectMapFields,
  selectMapSelectedFields,
} from '../../../reducer/selectors';

type Props = {
  togglePopUp: (key: string, value: boolean, seasonId?: number) => void;
  selectedSeasons: Season[];
  selectedNRXSeasons: any[];
};

const ButtonsPanel: ComponentType<Props> = ({
  togglePopUp,
  selectedSeasons,
  selectedNRXSeasons,
}: Props) => {
  const dispatch = useAppDispatch();
  const currentFarm = useAppSelector(selectCurrentFarm);
  const fields = useAppSelector(selectMapFields);
  const selectedFields = useAppSelector(selectMapSelectedFields);
  const isReadOnly = useAppSelector(selectIsReadOnly);
  const farmId = currentFarm?.id;
  const farmName = currentFarm?.name;

  const [cropDialogStatus, setCropDialogStatus] = useState<'edit' | 'add'>(null);
  const [cloneDialogVisibility, toggleCloneDialogVisibility] = useState(false);

  const hasFieldWithNRx = useMemo(() => {
    return fields.some(doesFieldHasSeasonWithNRx);
  }, [fields]);

  const fieldsControlsDisabled = !selectedFields.length || isReadOnly;

  const deleteFields = () => {
    const fIDs = selectedFields.map(f => f.ID);
    dispatch(removeField(currentFarm?.id, fIDs)).catch();
  };

  const downloadZip = () => {
    const md5s = selectedFields.map(field => field.MD5);
    dispatch(downloadFieldsBoundary(md5s, farmId, farmName));

    if ((window as any).Cypress) {
      setTimeout(() => window.location.reload(), 3000);
    }
  };

  const onDeleteCrops = () => {
    if (
      window.confirm(
        t({id: 'Are you sure you want to delete crop?'}, {count: selectedSeasons.length})
      )
    ) {
      dispatch(deleteBulkSeason(farmId, selectedSeasons, fields)).then(() =>
        setCropDialogStatus(null)
      );
    }
  };

  return (
    <div className={'fields-table-view__header-buttons'}>
      <ReadOnly>
        <FluroButton
          id="goFile"
          raised
          primary
          onClick={() => dispatch(dialogToggle(DialogType.addNewField, true))}
          iconEl={<FontIcon>add</FontIcon>}
          className={'add-field'}
          disabled={isReadOnly}
        >
          <FormattedMessage id="Add fields" />
        </FluroButton>
      </ReadOnly>

      <ReadOnly>
        <FluroButton
          id="import-seasons-btn"
          raised
          primary
          onClick={() => dispatch(dialogToggle(DialogType.importSeasons, true))}
          iconEl={<UploadIcon />}
          className={'upload-seasons'}
        >
          <FormattedMessage id="Import crops" />
        </FluroButton>
      </ReadOnly>

      <span className={'category-name'}>{t({id: 'Selected fields'})}: </span>

      <ReadOnly>
        <FluroButton
          raised
          primary
          onClick={() => setCropDialogStatus('add')}
          iconEl={<FontIcon>add</FontIcon>}
          className={'on-boarding-add-bulk-crop'}
          disabled={fieldsControlsDisabled}
        >
          <FormattedMessage id="Add crop" />
        </FluroButton>
      </ReadOnly>

      <ReadOnly>
        <MenuButton
          id="edit-field-settings"
          raised
          primary
          className="dots-button"
          disabled={!selectedFields.length || isReadOnly}
          menuItems={[
            <ListItem
              key={1}
              onClick={() => toggleCloneDialogVisibility(true)}
              leftIcon={<FontIcon>content_copy</FontIcon>}
              primaryText="Copy field"
            />,
            <ListItem
              key={3}
              onClick={downloadZip}
              leftIcon={<DownloadIcon />}
              primaryText="Kml"
            />,
            <ListItem
              className={'delete-field-btn'}
              key={2}
              leftIcon={<FontIcon>delete</FontIcon>}
              onClick={() =>
                dispatch(
                  dialogToggle(DialogType.deleteDialog, true, {
                    title: t({id: 'Delete field?'}),
                    onSubmit: deleteFields,
                  })
                )
              }
              primaryText={t({id: 'Delete field'})}
            />,
          ]}
        >
          <FontIcon inherit>more_vert</FontIcon>
        </MenuButton>
      </ReadOnly>

      <div className={'season-buttons'}>
        <span className={'category-name'}>{t({id: 'Selected crops:'})} </span>
        {hasFieldWithNRx ? (
          <MenuButton
            id="nrx-menu-button"
            className={'menu-button btn-with-icon edit-crop-button'}
            raised
            primary
            iconEl={<FontIcon inherit>keyboard_arrow_down</FontIcon>}
            disabled={!selectedSeasons.length || isReadOnly}
            menuItems={[
              <React.Fragment key={'unique-key'}>
                <ListItem
                  key={0}
                  onClick={() => setCropDialogStatus('edit')}
                  primaryText={t({id: 'Crop data'})}
                  className="edit-crop-button__crop-data"
                />
                <ListItem
                  key={1}
                  onClick={() => togglePopUp('matchCropPopUpOpen', true)}
                  disabled={!selectedNRXSeasons.length}
                  primaryText={t({id: 'Crop type variety'})}
                />
                <ListItem
                  key={2}
                  onClick={() => togglePopUp('plantingSoilPopUpOpen', true)}
                  disabled={!selectedNRXSeasons.length}
                  primaryText={t({id: 'Planting / soil parameters'})}
                />
              </React.Fragment>,
            ]}
          >
            <FormattedMessage id="Edit crop" />
          </MenuButton>
        ) : (
          <FluroButton
            primary
            raised
            onClick={() => setCropDialogStatus('edit')}
            iconEl={<EditIcon className={'edit-icon'} />}
            className={'edit-crop-button'}
            disabled={!selectedSeasons.length || isReadOnly}
          >
            <FormattedMessage id="edit crop" />
          </FluroButton>
        )}
        <ReadOnly>
          <FluroButton
            raised
            onClick={onDeleteCrops}
            iconEl={<FontIcon>delete</FontIcon>}
            disabled={!selectedSeasons.length || isReadOnly}
          >
            <FormattedMessage id="delete crop" />
          </FluroButton>
        </ReadOnly>
      </div>

      <CloneFieldPopup
        togglePopUp={(v: boolean) => toggleCloneDialogVisibility(v)}
        visible={cloneDialogVisibility}
      />

      <EditBulkSeason
        selectedSeasons={selectedSeasons}
        visible={!!cropDialogStatus}
        type={cropDialogStatus}
        onDeleteCrops={onDeleteCrops}
        onClose={() => setCropDialogStatus(null)}
      />
    </div>
  );
};

export default ButtonsPanel;
