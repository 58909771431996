export type AppearanceValues =
  | 'info'
  | 'warning'
  | 'error'
  | 'success'
  | 'default'
  | 'processing'
  | 'add'
  | 'gray';

export const infoBlockTypes: AppearanceValues[] = [
  'info',
  'warning',
  'error',
  'success',
  'default',
  'processing',
  'add',
  'gray',
];
