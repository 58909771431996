import React, {useCallback, useMemo} from 'react';
import {genKey} from '_utils/pure-utils';
import {useDispatch} from 'react-redux';
import {dialogToggle, DialogType} from 'modules/helpers';
import {FormattedMessage, t} from 'i18n-utils';
import Dialog from 'components/fluro-dialog';
import {Wrapper} from './carbon-resources-dialog.styled';
import {mixpanelCarbon} from '_utils/mixpanel-utils';

export const CarbonResourcesDialog = () => {
  const id = useMemo(() => genKey(), []);
  const dispatch = useDispatch();

  const onClose = useCallback(() => {
    dispatch(dialogToggle(DialogType.carbonResources, false));
  }, []);

  return (
    <Dialog
      id={`carbon-resource-center--dialog-${id}`}
      visible={true}
      title={t({id: 'Resource Center'})}
      focusOnMount={false}
      isDraggable
      onHide={onClose}
      width={500}
      portal
    >
      <Wrapper>
        <a
          href="https://youtu.be/1fj0OB_A7AQ"
          target="_blank"
          rel="noopener noreferrer"
          className="resource-link"
          onClick={() => {
            mixpanelCarbon.clickOnYouTubeVideo();
          }}
        >
          <img className="item-icon" src="/assets/carbon/resources/yt.svg" alt="Video icon" />
          <div>
            <FormattedMessage
              id="CarbonRes.Video Guide"
              defaultMessage="<div1>Video Guide</1div><div2>Our How to Video will make it easy to enroll in the program.</div2>"
              values={{
                div1: (msg: string) => <div className="item-title">{msg}</div>,
                div2: (msg: string) => <div className="item-description">{msg}</div>,
              }}
            />
          </div>
        </a>

        <a
          href="https://app.hubspot.com/documents/4430549/view/235051381?accessId=9f5f5e"
          target="_blank"
          rel="noopener noreferrer"
          className="resource-link"
        >
          <img
            className="item-icon"
            src="/assets/carbon/resources/user_guide.svg"
            alt="User guide icon"
          />
          <div>
            <FormattedMessage
              id="CarbonRes.User Guide"
              defaultMessage="<div1>User Guide</1div><div2>Use our step by step guide to help walk you through the FluroSense platform.</div2>"
              values={{
                div1: (msg: string) => <div className="item-title">{msg}</div>,
                div2: (msg: string) => <div className="item-description">{msg}</div>,
              }}
            />
          </div>
        </a>

        <a
          href="https://app.hubspot.com/documents/4430549/view/235051595?accessId=35fc68"
          target="_blank"
          rel="noopener noreferrer"
          className="resource-link"
        >
          <img className="item-icon" src="/assets/carbon/resources/faq.svg" alt="faq item" />
          <div>
            <FormattedMessage
              id="CarbonRes.FAQ"
              defaultMessage="<div1>Frequently Asked Questions</1div><div2>Our Frequently Asked Questions will help answer all of your questions.</div2>"
              values={{
                div1: (msg: string) => <div className="item-title">{msg}</div>,
                div2: (msg: string) => <div className="item-description">{msg}</div>,
              }}
            />
          </div>
        </a>

        <a
          href="https://app.hubspot.com/documents/4430549/view/237525906?accessId=a513a7"
          target="_blank"
          rel="noopener noreferrer"
          className="resource-link"
        >
          <img
            className="item-icon"
            src="/assets/carbon/resources/satellite-main-green.svg"
            alt="Satellite icon"
            style={{padding: '18px'}}
          />
          <div>
            <FormattedMessage
              id="CarbonRes.OPTIS Guide"
              defaultMessage="<div1>OPTIS Guide</1div><div2>The Operational Tillage Information System uses remote sensing data to monitor cover crops and tillage systems adoption.</div2>"
              values={{
                div1: (msg: string) => <div className="item-title">{msg}</div>,
                div2: (msg: string) => <div className="item-description">{msg}</div>,
              }}
            />
          </div>
        </a>

        <a
          href="https://hubs.ly/H0SzTX20"
          target="_blank"
          rel="noopener noreferrer"
          className="resource-link"
        >
          <img
            className="item-icon"
            src="/assets/carbon/resources/user_guide.svg"
            alt="Satellite icon"
          />
          <div>
            <FormattedMessage
              id="CarbonRes.Tillage Guide"
              defaultMessage="<div1>Tillage Guide</1div><div2>Use this table to determine the tillage classification type based on your tillage
              activity and depth.</div2>"
              values={{
                div1: (msg: string) => <div className="item-title">{msg}</div>,
                div2: (msg: string) => <div className="item-description">{msg}</div>,
              }}
            />
          </div>
        </a>

        <a
          href="https://app.hubspot.com/documents/4430549/view/235495755?accessId=219151"
          target="_blank"
          rel="noopener noreferrer"
          className="resource-link"
        >
          <img
            className="item-icon"
            src="/assets/carbon/resources/user_guide.svg"
            alt="Satellite icon"
          />
          <div>
            <div className="item-title">Carbon e-book</div>
            <div className="item-description">Get started with Carbon Markets</div>

            <FormattedMessage
              id="CarbonRes.Carbon e-book"
              defaultMessage="<div1>Carbon e-book</1div><div2>Get started with Carbon Markets</div2>"
              values={{
                div1: (msg: string) => <div className="item-title">{msg}</div>,
                div2: (msg: string) => <div className="item-description">{msg}</div>,
              }}
            />
          </div>
        </a>
      </Wrapper>
    </Dialog>
  );
};
