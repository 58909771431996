import styled from 'styled-components';
import {colors} from 'components/mrv/colors';

type InputWrapperProps = {
  error?: boolean;
};

export const InputWrapper = styled.div`
  position: relative;

  .label {
    color: #464646;
    box-sizing: border-box;
    display: block;
    font-size: 0.8125em;
    line-height: 1;
    opacity: 0;
    overflow: hidden;
    padding: 0.5em 1em;
    pointer-events: none;
    position: absolute;
    text-overflow: ellipsis;
    top: 0;
    text-align: left;
    transform: translateY(3px);
    transition: all 0.2s ease-out;
    user-select: none;
    white-space: nowrap;
    width: 100%;
    z-index: 1;
  }

  &.not-empty {
    .label {
      opacity: 1;
      transform: none;
    }
  }

  .input {
    color: ${colors.carbonGreen};
    background-color: #fff;
    border: 1px solid ${(p: InputWrapperProps) => (p.error ? 'red' : '#f1f1f1')};
    border-radius: 4px;
    font-size: 14px;
    padding: 12px 10px;
    transition: all 0.2s ease-out;
    width: 100%;
    &:hover,
    &:focus {
      outline: 0;
      border-color: ${colors.carbonGreen};
    }
  }

  &.not-empty {
    .input {
      padding-bottom: 6px;
      padding-top: 18px;
    }
  }
`;
