import React from 'react';
import type {FluroInputProps} from 'components';
import {FluroInput, Text} from 'components';
import {validatePassword} from '_utils/validate-password';
import {StrengthBar} from './strength-bar';
import {t} from 'i18n-utils';
import {TextField} from 'react-md';
import styled from 'styled-components';
import colors from '../../variables.scss';

type Props = FluroInputProps & {
  appearance?: 'fluro' | 'md';
  isDirty?: boolean;
  value: string;
  autoComplete?: string;
};

const Wrapper = styled.div`
  .strength-help-text {
    color: ${colors['main-gray-600']};
  }
`;

export const InputPassword = ({isDirty, appearance = 'md', ...props}: Props) => {
  return (
    <Wrapper>
      {appearance === 'md' ? (
        <TextField
          {...props}
          onChange={v => props?.onChange?.(`${v}`)}
          label={props.label}
          type="password"
        />
      ) : (
        <FluroInput {...props} type="password" />
      )}
      <StrengthBar value={!isDirty ? -1 : validatePassword(props.value || '', !!props.errorText)} />

      <Text variant="small" className="strength-help-text">
        {t({
          id: 'InputPasswordStrengthMessage',
          defaultMessage:
            'Create a strong password by using uppercase letters, lowercase letters, numbers, special characters, and uncommon words.',
        })}
      </Text>
    </Wrapper>
  );
};
