import './index.scss';
import {unreachableError, capitalizeFirstLetter} from '_utils/pure-utils';
import {toFixedFloatUnsafe} from '_utils/number-formatters';
import moment from 'moment';
import type {CSGViewModel, CropPerformanceState, WithFieldArea} from '../types';
import {Aggregation} from '../types';
import {RequestStatus} from 'types';

export const aggregate = (aggregation: Aggregation, data: WithFieldArea) => {
  switch (aggregation) {
    case Aggregation.COUNT:
      return 1;
    case Aggregation.SUM:
      return data.fieldArea;
    default:
      unreachableError(aggregation, `Not implemented aggregation: ${aggregation}`);
  }
};

export const cropPerfIsLoading = (cropPerformance: CropPerformanceState) => {
  return Object.values(cropPerformance.farms).some(farm => farm.status === RequestStatus.Loading);
};

export const getDateNumber = (type: any = 'none', date: string) => {
  if (type !== 'none') {
    type = type.split(':')[1];
  }

  switch (type) {
    case 'week':
      return capitalizeFirstLetter(type) + ' ' + moment.utc(date).format('w');

    case 'month':
      return moment.utc(date).format('MMMM');

    default:
      return '';
  }
};

export const formatAvgNDVI = (value: number) => {
  if (value === null || !isFinite(value) || value === 0) return '—';

  const result = toFixedFloatUnsafe(value);

  return result < 0.1 ? '<0.1' : result;
};

export const getCPSeasonDataByRecord = (
  record: CSGViewModel,
  cropPerformance: CropPerformanceState
) => {
  return (
    cropPerformance.farms?.[record.farmId]?.csgs?.[record.fieldID]?.seasons?.[record.seasonId] ||
    ({} as {dates: null})
  );
};

export {default as ProcessingMessage} from './processing-message';
export {default as CropStatus} from './crop-status';
export {default as CropGrowth} from './crop-growth';
export {default as ChangeScale} from './change-scale';
export {default as NoValueReason} from './no-value-reason';
export {default as StatusAlert, AlertIcon} from './status-alert';
export {default as ClearFilterButton} from './clear-filter-button';
