// @ts-nocheck
import React, {useCallback, useEffect, useMemo} from 'react';
import {t} from 'i18n-utils';
import type {RouteComponentProps} from 'react-router-dom';
import {useForm, Controller} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import {validationSchema} from './user-edit.utils';
import {getOne, saveUser, rmUser, loadOrganizations} from '../actions';
import {useSelector} from 'react-redux';
import type {AppStore} from 'reducers';
import {dialogToggle, DialogType} from 'modules/helpers';
import {history} from '_utils/history-utils';
import {SubscriptionsApi} from '_api';
import {ImpersonateButton} from '../impersonate-button';
import type {User, Workspace} from '../types';
import {SelectField, SelectionControl, TextField, FontIcon} from 'react-md';
import {genKey} from '_utils/pure-utils';
import IntlTelInput from 'react-intl-tel-input';
import 'react-intl-tel-input/dist/main.css';
import DefaultOrganization from './default-organization';
import SelectFarmChips from 'containers/select-farm-chips';
import {MAPPING_ROLES_TO_PROPS} from '_constants';
import AccessView from '../access-view/AccessView';
import {showNotification} from 'components/notification/notification';
import {
  MainTitleBlock,
  ContentWrapper,
  CardBlock,
  InlineBlock,
  ConfirmEmailWrapper,
  BackWrapper,
  UserDetailsItem,
} from './user-edit.styled';
import {Sticky, FluroButton, Flex, FluroAutocomplete, InfoBlock} from 'components';
import {SIAccess} from '../../../login/types';
import {useCountriesList, useRegionsList} from '../../../mrv/utils';
import {useAppDispatch} from '_hooks';
import {useWorkspace} from '_hooks/use-workspace';
import {InputPassword} from 'components/input-password';

type FormUserData = User & {
  phoneFullNumber: string;
  isPhoneValid: boolean;
};

const PERMISSIONS = Object.keys(MAPPING_ROLES_TO_PROPS).map(k => ({
  value: parseInt(k, 10),
  label: MAPPING_ROLES_TO_PROPS[k].prop,
}));

const NEW_USER_ID = 'new';

const sustainabilityInsights = [
  {
    label: 'All tabs, excluding sustainability insights',
    value: SIAccess.Off,
  },
  {label: 'All tabs, including sustainability insights', value: SIAccess.On},
  {label: 'Only sustainability insights tab', value: SIAccess.SIOnly},
];

export const UserEdit = ({match}: RouteComponentProps<{0: string; userId: string}>) => {
  const id = useMemo(() => genKey(), []);
  const dispatch = useAppDispatch();
  const {workspaceLink} = useWorkspace();

  const usersUrl = `${match.params[0]}/global-admin/users`;

  const editingUserId = useMemo(() => match.params.userId, [match.params.userId]);
  const currentUserId = useSelector((state: AppStore) => state.login.user.id);

  const {
    handleSubmit,
    formState: {errors},
    register,
    control,
    setValue,
    watch,
    trigger,
  } = useForm<FormUserData>({
    mode: 'onChange',
    resolver: yupResolver(validationSchema(editingUserId)),
    defaultValues: {
      isPhoneValid: true,
    },
  });

  const name = watch('name');
  const surname = watch('surname');
  const email = watch('email');
  const editingUserWorkspaces = watch('workspaces');

  useEffect(() => {
    (async () => {
      const user = await getOne(editingUserId)();

      register('id');
      register('isPhoneValid');
      register('phoneFullNumber');
      register('settings');

      setValue('id', editingUserId === 'new' ? 'new' : parseInt(editingUserId));
      setValue('name', user.name);
      setValue('email', user.email);
      setValue('surname', user.surname);
      setValue('surname', user.surname);
      setValue('surname', user.surname);
      setValue('phone', user?.phone || '');
      setValue('emailConfirmed', !!user.emailConfirmed);
      setValue('groupIds', user.groupIds);
      setValue('active', user.active);
      setValue('perm', user.perm);
      setValue('workspaces', user.workspaces);
      setValue('password', '');
      setValue('defaultOrganizationID', user.defaultOrganizationID);
      setValue('settings', {...user.settings});
      setValue('settings.company', {...user.settings.company});
      setValue('settings.si_tool_access', user.settings.si_tool_access);
      setValue('phoneFullNumber', user.phone);
    })();
  }, [editingUserId]);

  const isMe = useMemo(
    () => parseInt(editingUserId, 10) === currentUserId,
    [editingUserId, currentUserId]
  );

  const onRemoveUser = useCallback(() => {
    dispatch(
      dialogToggle(DialogType.confirm, true, {
        okLabel: 'Delete user',
        okAction: () => {
          dispatch(rmUser(parseInt(editingUserId), usersUrl));
        },

        title: 'Delete user',
        message: 'Are you sure?',
      })
    );
  }, [match.params.userId]);

  const toggleWorkspace = (
    workspace: Workspace,
    enabled: boolean,
    onChange: (...event: any[]) => void
  ) => {
    const newWorkspaces = {...(editingUserWorkspaces || {}), [workspace]: enabled};
    onChange(newWorkspaces);
  };

  const backToList = useCallback(() => {
    history.push(`${workspaceLink}/global-admin/users/`);
  }, []);

  const defaultOrganizationName = useCallback(
    () => `Organization - ${name} ${surname} (${email})`,
    [name, surname, email]
  );

  const createDefaultOrg = async () => {
    // 1 - is default organization id
    const response = await SubscriptionsApi.createOrganization(1, {
      name: defaultOrganizationName(),
    });

    const newCreatedOrgId = response?.data?.result?.id || 0;

    if (newCreatedOrgId) {
      await dispatch(loadOrganizations());
      setValue('defaultOrganizationID', newCreatedOrgId);
    }
  };

  const onSave = useCallback(async data => {
    const phone = data.phoneFullNumber?.replace(/ /g, '');

    if (phone) {
      data.phone = phone;
    }

    await saveUser(
      {
        ...data,
        perm: parseInt(data.perm),
        emailConfirmed: !!data.emailConfirmed,
        settings: {
          ...data.settings,
          phone: data.phone,
        },
      },
      usersUrl
    )(dispatch);

    showNotification({
      title: `Success`,
      message:
        data.id === NEW_USER_ID
          ? 'New user created, please contact the user to share the login details'
          : 'User was updated',
      type: 'success',
    });
  }, []);

  const invalidFormValuesNotificator = () => {
    showNotification({
      title: t({id: 'note.warning', defaultMessage: 'Warning'}),
      message: 'Some inputs are not valid or empty, please check the form values.',
      type: 'warning',
    });
  };

  const selectedCountry = watch('settings.company.country');
  const regionsList = useRegionsList(selectedCountry);
  const countriesList = useCountriesList() as {label: string; value: string}[];

  return (
    <ContentWrapper>
      <BackWrapper onClick={backToList}>
        <FontIcon>arrow_back</FontIcon>
      </BackWrapper>
      <MainTitleBlock>
        <h3 className="margin-bottom-20">User details</h3>
        {editingUserId !== NEW_USER_ID && (
          <ImpersonateButton email={email} variant="button" userId={parseInt(editingUserId)} />
        )}
      </MainTitleBlock>

      <form
        id={`user-edit-form-${id}`}
        autoComplete="off"
        onSubmit={handleSubmit(onSave, invalidFormValuesNotificator)}
      >
        <CardBlock>
          <h3>{t({id: 'General'})}</h3>
          <Flex justifyContent={'space-between'} gap={'10px'}>
            <UserDetailsItem>
              <Controller
                name="name"
                render={({field: {value, onChange}}) => (
                  <TextField
                    id={`name-${id}`}
                    label="First Name"
                    placeholder="First Name"
                    value={value}
                    onChange={onChange}
                    error={!!errors.name}
                    errorText={errors.name?.message}
                    //@ts-expect-error error leftover from convertion to strict mode, please fix
                    autoComplete="no"
                  />
                )}
                control={control}
                defaultValue=""
              />
            </UserDetailsItem>
            <UserDetailsItem>
              <Controller
                name="surname"
                render={({field: {value, onChange}}) => (
                  <TextField
                    id={`surname-${id}`}
                    label="Surname"
                    placeholder="Surname"
                    value={value}
                    onChange={onChange}
                    error={!!errors.surname}
                    errorText={errors.surname?.message}
                    //@ts-expect-error error leftover from convertion to strict mode, please fix
                    autoComplete="no"
                  />
                )}
                control={control}
                defaultValue=""
              />
            </UserDetailsItem>
            <UserDetailsItem>
              <Controller
                name="email"
                render={({field: {value, onChange}}) => (
                  <TextField
                    id={`email-${id}`}
                    label="Email"
                    placeholder="Email"
                    value={value}
                    disabled={editingUserId !== NEW_USER_ID}
                    onChange={onChange}
                    error={!!errors.email}
                    errorText={errors.email?.message}
                    //@ts-expect-error error leftover from convertion to strict mode, please fix
                    autoComplete="no"
                  />
                )}
                control={control}
                defaultValue=""
              />
            </UserDetailsItem>

            <UserDetailsItem>
              <Controller
                name="password"
                render={({field, fieldState: {isDirty, isTouched}}) => (
                  <InputPassword
                    id="reset-password"
                    className="sign-up-form-control"
                    label={t({id: 'New Password'})}
                    {...field}
                    error={!!(isTouched && errors?.password)}
                    errorText={errors?.password?.message}
                    isDirty={isDirty}
                    autoComplete="off"
                  />
                )}
                control={control}
                defaultValue=""
              />
            </UserDetailsItem>
            <UserDetailsItem>
              <Controller
                name="phone"
                render={({field: {value, onChange}}) => (
                  <div className={`custom-phone-input input-half ${errors.phone ? 'error' : ''}`}>
                    <IntlTelInput
                      preferredCountries={['us', 'au']}
                      defaultCountry="au"
                      onPhoneNumberChange={(
                        isValidPhoneNumber: boolean,
                        value: any,
                        params: any,
                        fullValue: string
                      ) => {
                        onChange(value);
                        setValue('phoneFullNumber', fullValue);
                        setValue('isPhoneValid', isValidPhoneNumber);
                        isValidPhoneNumber && trigger('phone');
                      }}
                      autoHideDialCode={true}
                      dropdownContainer="div"
                      value={value}
                      nationalMode={false}
                      autoComplete="no"
                    />
                    {errors.phone ? (
                      <div className="error-text">{errors.phone?.message}</div>
                    ) : null}
                  </div>
                )}
                control={control}
                defaultValue=""
              />
            </UserDetailsItem>
            <UserDetailsItem>
              <ConfirmEmailWrapper>
                <Controller
                  name="emailConfirmed"
                  render={({field: {value, onChange}}) => (
                    <SelectionControl
                      id={`emailConfirmed-${id}`}
                      type="switch"
                      label="Confirm email"
                      name="emailConfirmed"
                      labelBefore={true}
                      checked={!!value}
                      onChange={onChange}
                    />
                  )}
                  control={control}
                />
              </ConfirmEmailWrapper>
            </UserDetailsItem>
          </Flex>
        </CardBlock>

        <CardBlock>
          <h3>{t({id: 'Address'})}</h3>
          <Flex justifyContent={'space-between'} gap={'10px'}>
            <UserDetailsItem>
              <Controller
                name="settings.company.country"
                render={({field: {value, onChange}}) => (
                  <FluroAutocomplete
                    id="user-country"
                    label={t({id: 'Country'})}
                    title={t({id: 'Country'})}
                    menuItems={countriesList}
                    onAutocomplete={onChange}
                    value={value}
                  />
                )}
                control={control}
              />
            </UserDetailsItem>
            <UserDetailsItem>
              <Controller
                name="settings.company.state"
                control={control}
                render={({field: {value, onChange}}) =>
                  regionsList.length ? (
                    <FluroAutocomplete
                      id="user-region"
                      label={t({id: 'Region'})}
                      menuItems={regionsList}
                      onAutocomplete={onChange}
                      value={value}
                      className="select-element"
                    />
                  ) : (
                    <TextField
                      id="user-region"
                      label={t({id: 'Region'})}
                      value={value}
                      onChange={onChange}
                    />
                  )
                }
              />
            </UserDetailsItem>
            <UserDetailsItem>
              <Controller
                name="settings.company.city"
                control={control}
                render={({field, fieldState: {isTouched, error}}) => (
                  <TextField
                    id="user-city"
                    label={t({id: 'Reg.City'})}
                    error={!!(isTouched && error?.message)}
                    errorText={error?.message}
                    {...field}
                  />
                )}
              />
            </UserDetailsItem>
            <UserDetailsItem>
              <Controller
                name="settings.company.street"
                control={control}
                render={({field, fieldState: {isTouched, error}}) => (
                  <TextField
                    id="user-street"
                    label={t({id: 'Street'})}
                    error={!!(isTouched && error?.message)}
                    errorText={error?.message}
                    {...field}
                  />
                )}
              />
            </UserDetailsItem>
            <UserDetailsItem>
              <Controller
                name="settings.company.zip"
                control={control}
                render={({field, fieldState: {isTouched, error}}) => (
                  <TextField
                    id="user-zip"
                    label={t({id: 'Reg.Zip'})}
                    error={!!(isTouched && error?.message)}
                    errorText={error?.message}
                    {...field}
                  />
                )}
              />
            </UserDetailsItem>
          </Flex>
        </CardBlock>
        <CardBlock>
          <Controller
            name="defaultOrganizationID"
            render={({field: {value, onChange}}) => (
              <DefaultOrganization
                onSetOrg={onChange}
                defaultOrganizationID={value}
                createDefaultOrg={createDefaultOrg}
                createDefaultOrgName={defaultOrganizationName()}
              />
            )}
            control={control}
            // using empty string for the empty number input value
            // @ts-expect-error error leftover from convertion to strict mode, please fix
            defaultValue=""
          />
        </CardBlock>

        <CardBlock>
          <h3>User's farms and growers</h3>

          <Controller
            name="groupIds"
            render={({field: {value, onChange}}) => (
              <SelectFarmChips
                label="Select or search a farm to add it to the user"
                onSelectCallback={onChange}
                selectedItems={value}
                error={!!errors.groupIds}
              />
            )}
            control={control}
          />
          {errors.groupIds ? (
            <div style={{color: 'red', fontSize: '12px'}}>Farm is required</div>
          ) : null}
        </CardBlock>

        <CardBlock>
          <h3>User's roles and permissions</h3>

          <InlineBlock style={{justifyContent: 'space-between', alignItems: 'center'}}>
            <Controller
              name="perm"
              render={({field: {value, onChange}}) => (
                <SelectField
                  id={`perm-${id}`}
                  label="Permission"
                  style={{width: '300px'}}
                  placeholder="Permission"
                  menuItems={PERMISSIONS}
                  simplifiedMenu={false}
                  value={value}
                  onChange={onChange}
                  error={!!errors.perm}
                  errorText={errors.perm?.message}
                />
              )}
              control={control}
              // using empty string for the empty number input value
              // @ts-expect-error error leftover from convertion to strict mode, please fix
              defaultValue=""
            />
          </InlineBlock>

          <AccessView userId={parseInt(editingUserId)} />
        </CardBlock>

        <CardBlock>
          <h3>Workspaces</h3>

          <Controller
            name="workspaces"
            render={({field: {value, onChange}, fieldState: {error}}) => {
              return (
                <Flex direction="column">
                  <SelectionControl
                    id={`ci-${id}`}
                    type="switch"
                    label="Crop Insights"
                    name="cropInsights"
                    checked={value?.ci}
                    onChange={v => toggleWorkspace('ci', Boolean(v), onChange)}
                  />
                  <SelectionControl
                    id={`mrv-${id}`}
                    type="switch"
                    label="MRV"
                    name="mrv"
                    checked={value?.mrv}
                    onChange={v => toggleWorkspace('mrv', Boolean(v), onChange)}
                  />
                  <SelectionControl
                    id={`si-${id}`}
                    type="switch"
                    label="Sustainability Insights"
                    name="sustainabilityInsights"
                    checked={value?.si}
                    onChange={v => toggleWorkspace('si', Boolean(v), onChange)}
                  />
                  {error && (
                    <InfoBlock appearance="error" color="error">
                      {error.message}
                    </InfoBlock>
                  )}
                </Flex>
              );
            }}
            control={control}
            // using empty string for the empty number input value
            // @ts-expect-error error leftover from convertion to strict mode, please fix
            defaultValue=""
          />
        </CardBlock>

        <CardBlock>
          <h3>Tab access</h3>

          <Controller
            name="settings.si_tool_access"
            render={({field: {value, onChange}}) => (
              <SelectField
                id={`si_tool_access-${id}`}
                label=""
                style={{minWidth: 300, width: 'auto'}}
                placeholder="Select..."
                menuItems={sustainabilityInsights}
                simplifiedMenu={false}
                value={value}
                onChange={onChange}
                error={!!errors?.settings?.si_tool_access}
                errorText={errors?.settings?.si_tool_access?.message}
              />
            )}
            control={control}
            // using empty string for the empty number input value
            // @ts-expect-error error leftover from convertion to strict mode, please fix
            defaultValue=""
          />
        </CardBlock>

        <Controller
          name="active"
          render={({field: {value, onChange}}) => (
            <SelectionControl
              id={`active-${id}`}
              type="switch"
              label="Active account"
              name="active"
              labelBefore={true}
              checked={!!value}
              onChange={value => onChange(value ? 1 : 0)}
              disabled={isMe}
            />
          )}
          control={control}
          // using empty string for the empty number input value
          // @ts-expect-error error leftover from convertion to strict mode, please fix
          defaultValue=""
        />

        <Sticky>
          <FluroButton className={'close-btn'} blank onClick={backToList} raised>
            Close
          </FluroButton>

          {editingUserId !== NEW_USER_ID && !isMe ? (
            <FluroButton blank raised onClick={onRemoveUser}>
              Delete user
            </FluroButton>
          ) : null}

          <FluroButton raised primary type="submit">
            Save
          </FluroButton>
        </Sticky>
      </form>
    </ContentWrapper>
  );
};
