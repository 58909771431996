import type {PieCustomLayerProps} from '@nivo/pie';
import {selectMeasurement} from 'containers/login/login-selectors';
import React, {Fragment} from 'react';
import {useAppSelector} from '_hooks';
import {kiloFormatter} from '_utils/number-formatters';

export const PieChartCenteredMetric = ({
  dataWithArc,
  centerX,
  centerY,
}: PieCustomLayerProps<{value: number}>) => {
  const measurement = useAppSelector(selectMeasurement);
  let total = 0;
  dataWithArc.forEach(datum => {
    total += datum.value;
  });

  return (
    <Fragment>
      {total > 0 && (
        <text
          x={centerX}
          y={centerY - 8}
          textAnchor="middle"
          dominantBaseline="central"
          style={{
            fontSize: '15px',
            fontWeight: 600,
          }}
        >
          {kiloFormatter(total)}
        </text>
      )}
      <text
        x={centerX}
        y={centerY + 8}
        textAnchor="middle"
        dominantBaseline="central"
        style={{
          fontSize: '12px',
          fontWeight: 300,
        }}
      >
        {total > 0 ? `Total ${measurement === 'ac' ? 'acres' : 'hectares'}` : 'No Data'}
      </text>
    </Fragment>
  );
};
