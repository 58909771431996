import {Flex, Text} from 'components';
import {ChromaScale} from 'components/chroma-scale/chroma-scale';
import type {ComponentType} from 'react';
import React from 'react';
import {useAppSelector} from '_hooks';
import {
  selectAreaUnits,
  selectTillageExtrema,
  selectIsYearsDiff,
  selectYearsFilter,
  selectSelectedTillagePracticeLabel,
  selectTillageColorScale,
  selectTillageChartTab,
  selectAreaUnitsLabel,
} from 'modules/sustainability-insights/selectors';
import {valueFormatter} from '../filters-panel/cards/utils';
import './filter-status.scss';

export const TillageFilterStatus: ComponentType<{}> = () => {
  const {colors, domain} = useAppSelector(selectTillageColorScale);
  const units = useAppSelector(selectAreaUnits);
  const unitsLabel = useAppSelector(selectAreaUnitsLabel);
  const {min, max} = useAppSelector(selectTillageExtrema);
  const isDiff = useAppSelector(selectIsYearsDiff);
  const years = useAppSelector(selectYearsFilter);
  const chartTab = useAppSelector(selectTillageChartTab);
  const selectedPracticeLabel = useAppSelector(selectSelectedTillagePracticeLabel);

  if (!years?.length || !Number.isFinite(min) || !Number.isFinite(max)) return null;

  if (chartTab !== 'summary') return null;

  const [yearFrom, yearTo] = years;

  return (
    <>
      <Flex alignItems="center">
        <Text className="mb-0 mr-2 is-diff-short">
          {isDiff ? (
            <>
              {selectedPracticeLabel} in {yearFrom} - {yearTo} ({unitsLabel})
            </>
          ) : (
            <>
              {selectedPracticeLabel} in {yearFrom} ({unitsLabel})
            </>
          )}
        </Text>
        <ChromaScale
          min={valueFormatter(min, units, true)}
          max={valueFormatter(max, units, true)}
          colors={colors}
          domain={domain}
        />
      </Flex>
    </>
  );
};
