import {Flex, FluroButton, GeometryPreview, Text} from 'components';
import {FluroProgressIcon} from 'components/fluro-progress-icon/fluro-progress-icon';
import {CheckIcon} from 'components/icons';
import type {MRVStageNormalized} from 'containers/mrv/types';
import React, {useEffect, useState} from 'react';
import {MRVApi} from '_api/mrv';
import {useAppDispatch, useAppSelector} from '_hooks';
import './eligibility-view.scss';
import {removeProjectFields} from 'containers/mrv/monitoring/module/thunks';
import {
  selectCurrentProjectFieldMapMrvId2KmlId,
  selectFields,
} from 'containers/mrv/monitoring/module/selectors';
import {GEOMETRY_THUMB_SIZE, handleFieldFeature} from 'containers/mrv/enrollment/form/utils';
import {removeFields} from 'containers/mrv/enrollment/carbon-store';
import {setStageCompletion} from 'containers/mrv/monitoring/module/reducer';
import {reportError} from 'containers/error-boundary';
import {createStageCompletion} from 'containers/mrv/base';
import {t} from 'i18n-utils';
import {PlaceholderLoader} from 'components/placeholder-loader/placeholder-loader';

export const EligibilityView = ({
  projectId,
  stage,
}: {
  projectId: number;
  stage: MRVStageNormalized;
}) => {
  const dispatch = useAppDispatch();
  const fields = useAppSelector(selectFields);
  const mrvId2KmlId = useAppSelector(selectCurrentProjectFieldMapMrvId2KmlId);
  // TODO (stas): store the previous result, so we don't have blinking while there's a network request.
  const [ineligibleFieldIds, setIneligibleFieldIds] = useState<number[]>([]); // mrv ids
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!stage?.eligibility_method) return;
    setLoading(true);
    MRVApi.getStageEligibility(projectId, stage?.id)
      .then(({data}) => {
        setTimeout(() => setLoading(false), 500); // timeout to prevent loader blinking too fast

        const ineligibleIds = Object.keys(data)
          .map(Number)
          .filter(id => !data[id].eligible);

        setIneligibleFieldIds(ineligibleIds);
        dispatch(setStageCompletion(createStageCompletion(stage?.id, !ineligibleIds.length)));
      })
      .catch(e => {
        setTimeout(() => setLoading(false), 500); // timeout to prevent loader blinking too fast
        reportError(
          `Error fetching stage eligibility project: ${projectId}, stage: ${stage?.id}, error: ${e}`
        );
      });
  }, [dispatch, projectId, stage?.eligibility_method, stage?.id]);

  const removeIneligibleFields = async () => {
    try {
      await dispatch(removeProjectFields({projectId, mrvFieldIds: ineligibleFieldIds})); // remove from mrv
      const fieldIds = ineligibleFieldIds.map(mrvId => mrvId2KmlId[mrvId]);
      dispatch(removeFields({fieldIds})); // remove from global store
      if (stage?.id) {
        dispatch(setStageCompletion(createStageCompletion(stage.id, true)));
      }
      setIneligibleFieldIds([]);
    } catch (e) {}
  };
  return (
    <Flex direction="column" gap="40px" alignItems="center" className="eligibility-view">
      <div></div>
      <img src={'/assets/mrv/eligibility_illustration.svg'} alt="eligibility" />
      {loading ? (
        <PlaceholderLoader width={230} height={50} />
      ) : ineligibleFieldIds.length ? (
        <>
          <Text variant="h3">
            {t({
              id: 'IneligibleFieldsMessage',
              defaultMessage:
                'The fields below are ineligible for this program based on past management practices.',
            })}
          </Text>

          <Flex direction="column" className="ineligible-fields">
            {ineligibleFieldIds
              .map(id => {
                const field = fields[id];
                if (!field) {
                  return null;
                }
                const {field_name, geometry} = field.core_attributes;

                return (
                  <Flex alignItems="center" gap="10px" className="ineligible-fields--item" key={id}>
                    <GeometryPreview
                      data={geometry}
                      size={GEOMETRY_THUMB_SIZE}
                      onEachFeature={handleFieldFeature}
                    />
                    <Text inline>{field_name}</Text>
                  </Flex>
                );
              })
              .filter(Boolean)}
          </Flex>

          <FluroButton raised primary onClick={removeIneligibleFields}>
            {t({id: 'Remove ineligible fields'})}
          </FluroButton>
        </>
      ) : (
        <Flex alignItems="center" gap="10px">
          <FluroProgressIcon
            icon={<CheckIcon style={{width: 30, height: 30}} />}
            outlined
            active
            progressValue={100}
            hideProgressLabel={true}
            id="success-eligibility"
          />
          <Text variant="h3" inline>
            {t({id: 'Eligibility check passed!'})}
          </Text>
        </Flex>
      )}
    </Flex>
  );
};
