import {
  selectAllAnalyticNDVIPoints,
  selectAnalyticHiddenPointColors,
  selectAnalyticRequestSourceType,
} from 'containers/map/features/analytics/analytics-selectors';

import type {AnalyticsPoint} from 'containers/map/types';
import type {ComponentType} from 'react';
import React, {useCallback} from 'react';
import cn from 'classnames';
import {t} from 'i18n-utils';

import {useAppDispatch, useAppSelector} from '_hooks';
import {toggleTooltip} from 'modules/global/actions';
import {LeafIcon} from 'containers/map/icons';
import {
  AnalyticRequestSourceType,
  toggleAnalyticPointVisibility,
} from 'containers/map/actions/analytics-actions';
import {InfoBlock} from 'components';

export const AnalyticChartLabels: ComponentType = () => {
  const analyticPoints = useAppSelector(selectAllAnalyticNDVIPoints);
  const analyticRequestSources = useAppSelector(selectAnalyticRequestSourceType);

  return (
    <div className={'points-container'}>
      <span className={'subtitle'}>{t({id: 'Legend:'})}</span>

      {analyticPoints.map(point => (
        <LabelPointElement key={point.color} point={point} />
      ))}

      {analyticRequestSources === AnalyticRequestSourceType.Both ? (
        <InfoBlock className="tab-info-block" mini>
          {t({
            id: 'Use the data layers tab to show / hide the trends based on their imagery source',
          })}
        </InfoBlock>
      ) : null}
    </div>
  );
};

const LabelPointElement: ComponentType<{point: AnalyticsPoint}> = ({point}) => {
  const dispatch = useAppDispatch();
  const hiddenPointColors = useAppSelector(selectAnalyticHiddenPointColors);
  const isHidden = hiddenPointColors.includes(point.color);
  const isTSP = point.type === 'tsp';

  const togglePointVisibility = useCallback(
    () => dispatch(toggleAnalyticPointVisibility(point.color)),
    [point.color]
  );

  if (point.type === 'field') {
    // const source =
    //   point.sourceType === AnalyticRequestSourceType.Planet ? ' (SatelliteHR)' : ' (Satellite)';
    return (
      <div
        style={{backgroundColor: point.color}}
        data-tip={''}
        data-for={point.color}
        onMouseEnter={() =>
          dispatch(
            toggleTooltip({
              id: point.color,
              content: t({id: 'Whole field smoothed average.'}),
              place: 'right',
            })
          )
        }
        onClick={togglePointVisibility}
        className={cn('analytic-point', 'smoothed', {
          'hidden-point': isHidden,
        })}
      />
    );
  }

  if (point.type === 'polygon') {
    return (
      <div
        title={t({
          id: 'Click to hide/show this geometry data from trend.',
          defaultMessage: 'Click to hide/show this geometry data from trend.',
        })}
        onClick={togglePointVisibility}
        className={cn('analytic-point geometry', {
          'hidden-point': isHidden,
        })}
        style={{borderColor: point.color}}
      />
    );
  }
  return (
    <div
      title={t({
        id: 'Click to hide/show this point data from trend.',
        defaultMessage: 'Click to hide/show this point data from trend.',
      })}
      onClick={togglePointVisibility}
      className={cn('analytic-point', {'hidden-point': isHidden, custom: !isTSP})}
      style={{backgroundColor: point.color}}
    >
      {isTSP ? <LeafIcon /> : null}
    </div>
  );
};
