// @ts-nocheck
import {t} from 'i18n-utils';
import type {Farm, Field, Season} from '../../../types';
import ReactDOM from 'react-dom';
import type {MeasurementSystem} from '_utils';
import {convertUnit, formatUnit} from '_utils';
import {capitalizeFirstLetter} from '_utils/pure-utils';
import React from 'react';
import {GeometryPreview} from 'components';
import {FontIcon} from 'react-md';
import {Provider} from 'react-redux';
import store from 'store';
import {NRxRecommendationSettings} from '../nitrogen-recommendation/nrx-recommendation-settings';
import type {GeoJSON} from 'react-leaflet';
import cn from 'classnames';

export type ExportToPdfProps = {
  features: GeoJSON.Feature | GeoJSON.Feature[];
  field: Field;
  farm: Farm;
  currentSeason: Season;
  measurement: string;
  recommendationDate: string;
};

export const getMainContainer = () => {
  const mainContainer = document.createElement('div');
  mainContainer.className = 'export-zoning-to-pdf';
  document.querySelector('.app-loader').appendChild(mainContainer);
  return mainContainer;
};

export const getImageAndHeight = async (
  container: HTMLElement,
  html2canvas: any,
  specificValueToNormalizeHeight?: number
) => {
  const sectionImage = (
    await html2canvas(container, {
      logging: true,
      letterRendering: 1,
      allowTaint: false,
      useCORS: true,
    })
  ).toDataURL('image/jpeg,1.0');
  const imageHeight = container.offsetHeight / (specificValueToNormalizeHeight || 3.75);
  container.remove(); // remove the container once the image is done
  return [sectionImage, imageHeight];
};
export const getZonesPreviewAndValuesImg = async (data: any, html2canvas: any, isNrx = false) => {
  const mainContainer = getMainContainer();
  const zonesContainer = document.createElement('div');
  zonesContainer.className = 'export-zones-container';
  // it is important to append container before render the map, otherwise the geojson won't be rendered properly
  mainContainer.appendChild(zonesContainer);

  ReactDOM.render(
    <h2 className={'section-title'}>
      {isNrx ? t({id: 'N-recommendation'}) : t({id: 'Management zones'})}
      <div className="dot" />
    </h2>,
    zonesContainer
  );
  if (isNrx) {
    await appendGeometryPreview(data, zonesContainer);
  }

  appendZonesData(zonesContainer, isNrx);
  if (!isNrx) {
    appendImagePreview(data, zonesContainer);
  }

  mainContainer.appendChild(zonesContainer);

  return getImageAndHeight(mainContainer, html2canvas);
};

export const appendZonesData = (parentContainer: HTMLElement, isNrx = false) => {
  const zonesContainer = document.createElement('div');
  zonesContainer.className = 'zones-values';

  // get zones container and pick particular children
  const zoningMethod = isNrx
    ? null
    : document.querySelector('.zoning-method-container')?.cloneNode(true);
  const zoningZonesSelected = isNrx
    ? null
    : document.querySelector('.zoning-classes-container')?.cloneNode(true);
  const tabZonesContainer = document.querySelector('.zones-container');
  const zonesTitle = tabZonesContainer.querySelector('.zones-title')?.cloneNode(true);
  const rxContainer = tabZonesContainer.querySelector('.rx-gdd-container')?.cloneNode(true);
  const zones = tabZonesContainer.querySelector('.zoning-statistics')?.cloneNode(true);

  // append selected blocks to prepared container
  zoningMethod && zonesContainer.appendChild(zoningMethod);
  zoningZonesSelected && zonesContainer.appendChild(zoningZonesSelected);
  zonesTitle && zonesContainer.appendChild(zonesTitle);
  rxContainer && zonesContainer.appendChild(rxContainer);
  zones && zonesContainer.appendChild(zones);

  parentContainer.appendChild(zonesContainer);
};

export const getFieldAndSettingsDetails = (
  farm: Farm,
  field: Field,
  currentSeason: Season,
  measurement: MeasurementSystem,
  html2canvas: any,
  isNrx = false
) => {
  const mainContainer = getMainContainer();

  const fieldAndSettingsDetails = document.createElement('div');
  fieldAndSettingsDetails.className = 'field-and-settings-details';

  const Detail = ({name, value}: {name: string; value: string}) =>
    name && value ? (
      <div className={'detail'}>
        <div className={'name'}>{name}</div>
        <div className={'value'}>{value}</div>
      </div>
    ) : null;

  ReactDOM.render(
    <>
      <div className={cn('field-details-section', {trees: !isNrx})}>
        <h2 className={'section-title'}>
          Field details <div className="dot" />
        </h2>
        <div className="details-wrapper">
          <Detail name={'Grower:'} value={farm.growerName} />
          <Detail name={'Farm:'} value={farm.name} />
          <Detail name={'Field:'} value={field.Name} />
          <Detail
            name={'Crop:'}
            value={`${capitalizeFirstLetter(currentSeason.cropType)} (${convertUnit(
              measurement,
              'ac',
              field.Area
            )} ${formatUnit(measurement, 'ha')})`}
          />
          {currentSeason.params?.cropSubType && (
            <Detail name={'Variety:'} value={currentSeason.params.cropSubType} />
          )}
        </div>
      </div>
    </>,
    fieldAndSettingsDetails
  );

  if (isNrx) {
    fieldAndSettingsDetails.appendChild(getNrxSettings());
  }

  mainContainer.appendChild(fieldAndSettingsDetails);
  return getImageAndHeight(mainContainer, html2canvas);
};

export const appendGeometryPreview = (data: any, parentContainer: HTMLElement) => {
  const geometryContainer = document.createElement('div');
  geometryContainer.className = 'zones-preview';
  // it is important to append container before render the map, otherwise the geojson won't be rendered properly
  parentContainer.appendChild(geometryContainer);

  // append zones kml to geometryContainer
  ReactDOM.render(
    <GeometryPreview
      data={data}
      style={{width: '400px', height: '400px', backgroundColor: '#fff'}}
      onEachFeature={(feature: any, layer: any) =>
        layer.setStyle({
          fillOpacity: 1,
          color: feature.properties.color,
        })
      }
    />,
    geometryContainer
  );
  // add a delay to allow GeoJson to be rendered, then do a screenshot
  return new Promise(resolve => setTimeout(() => resolve(true), 300));
};

export const appendImagePreview = (src: string, parentContainer: HTMLElement) => {
  const geometryContainer = document.createElement('div');
  geometryContainer.className = 'zones-preview';
  parentContainer.appendChild(geometryContainer);

  const image = new Image();
  image.src = src;
  image.className = 'zone-image-preview';
  geometryContainer.appendChild(image);
};

const getNrxSettings = (): HTMLElement => {
  const containerToRenderIn = document.createElement('div');
  containerToRenderIn.className = 'nitrogen-zoning-container';
  // render Nrx-settings pop-up to get its content
  ReactDOM.render(
    <Provider store={store}>
      <NRxRecommendationSettings exportView />
    </Provider>,
    containerToRenderIn
  );
  // get the settings content
  const recommendationSettings = containerToRenderIn.querySelector('.export-settings-container');

  ReactDOM.render(
    <>
      <h2 className={'section-title'}>
        {t({id: 'Recommendation settings'})} <div className="dot" />
      </h2>
      <div className={'nrx-recommendation-settings'} />
    </>,
    containerToRenderIn
  );
  containerToRenderIn
    .querySelector('.nrx-recommendation-settings')
    .appendChild(recommendationSettings);
  return containerToRenderIn;
};

export const getHeaderImage = (recommendationDate: string, html2canvas: any, isNrx = false) => {
  const mainContainer = getMainContainer();
  const headerContainer = document.createElement('div');
  headerContainer.className = 'header';

  ReactDOM.render(
    <>
      <img
        className={'logo-img'}
        src="/assets/logos/regrow/regrow_white_logo.png"
        alt="regrow-logo"
      />
      <div>
        <h1>{isNrx ? 'Nrx- Nitrogen recommendation' : 'Tree analysis report'}</h1>
        <h2>
          {isNrx ? 'Recommendation date: ' : 'Imagery date: '}
          {recommendationDate}
        </h2>
      </div>
    </>,
    headerContainer
  );

  mainContainer.appendChild(headerContainer);
  return getImageAndHeight(mainContainer, html2canvas, 3.9);
};

export const getFooterImg = (html2canvas: any) => {
  const mainContainer = getMainContainer();
  const footerContainer = document.createElement('div');
  footerContainer.className = 'footer-container';
  ReactDOM.render(
    <>
      <div className="contacts-item">
        <FontIcon>phone</FontIcon>
        <div className="values">
          <div>AU (+61) 498 019-346</div>
          <div>US (+1) 435 754-9299</div>
        </div>
      </div>

      <div className="contacts-item">
        <FontIcon>location_on</FontIcon>
        <div className="values">
          <div>2-4 cornwallis St, 2015</div>
          <div>Eveleigh, NSW, Sydney</div>
        </div>
      </div>

      <div className="contacts-item">
        <FontIcon>mail</FontIcon>
        <div className="values">
          <div>support@regrow.ag</div>
          <div>sales@regrow.ag</div>
        </div>
      </div>
    </>,
    footerContainer
  );

  mainContainer.appendChild(footerContainer);
  return getImageAndHeight(mainContainer, html2canvas);
};
