import {t} from 'i18n-utils';
import React, {useCallback} from 'react';
import {AdminApi} from '_api';
import {showNotification} from 'components/notification/notification';
import {KeyIcon} from 'components/icons';
import styled, {css} from 'styled-components';
import {dialogToggle, DialogType} from 'modules/helpers';
import {safeLocalStorage} from '_utils/safe-local-storage';
import {useAppDispatch} from '_hooks';
import {useWorkspace} from '_hooks/use-workspace';
import {getErrorMessage} from '_utils/error';
import {FluroButton} from 'components';
import {setLocalStorage} from '_hooks/use-storage';

type ImpersonateButtonProps = {
  userId: number;
  email: string;
  variant?: 'button' | 'icon';
  noMargin?: boolean;
  redirectUrl?: string;
};
export function ImpersonateButton({
  email,
  userId,
  variant = 'icon',
  noMargin,
  redirectUrl,
}: ImpersonateButtonProps) {
  const dispatch = useAppDispatch();
  const {workspaceLink} = useWorkspace();

  const onLogin = useCallback(() => {
    try {
      dispatch(
        dialogToggle(DialogType.confirm, true, {
          okLabel: 'login',
          okAction: async () => {
            const {data} = await AdminApi.impersonateAsUser(userId);
            safeLocalStorage.setItem('token', data.result.token);

            if (redirectUrl) {
              setLocalStorage('unImpersonateRedirectUrl', redirectUrl);
            }

            window.location.assign(workspaceLink);
          },
          title: 'Login as user',
          message: (
            <span>
              Do you want to login as <b>{email}</b>?
            </span>
          ),
        })
      );
    } catch (error) {
      showNotification({
        title: t({id: 'note.warning', defaultMessage: 'Warning'}),
        message: getErrorMessage(error),
        type: 'warning',
      });
    }
  }, [userId, email, redirectUrl, dispatch, workspaceLink]);

  if (variant === 'button') {
    return (
      <FluroButton iconEl={<KeyIcon size={16} />} onClick={onLogin} raised>
        {t({id: 'Login as user'})}
      </FluroButton>
    );
  }

  return <KeyIconButton $noMargin={noMargin} size={16} onClick={onLogin} />;
}

type KeyIconButtonProps = {
  $noMargin?: boolean;
};
const KeyIconButton = styled(KeyIcon)<KeyIconButtonProps>`
  cursor: pointer;
  min-width: 16px;

  ${({$noMargin}) =>
    !$noMargin &&
    css`
      margin-left: 15px;
    `}
`;
