import type {FluroSelectLiteItem} from 'components/fluro-select-lite/fluro-select-lite';
import {t} from 'i18n-utils';
import {capitalizeFirstLetter} from '_utils/pure-utils';
import type {MRVAttributeOption} from '../types';

export const getAttributeOptionLabel = (option: MRVAttributeOption) =>
  typeof option === 'string' ? option : option.label;

export const getAttributeOptionValue = (option: MRVAttributeOption) =>
  typeof option === 'string' ? option : option.value;

export const attributeOptionsToListItems = (options: MRVAttributeOption[]): FluroSelectLiteItem[] =>
  typeof options[0] === 'string'
    ? (options.map(option => ({
        label: capitalizeFirstLetter(
          t({
            id: `Option.${option}`,
            defaultMessage: typeof option === 'string' ? option : '',
          })
        ),
        value: option,
      })) as FluroSelectLiteItem[])
    : (options as FluroSelectLiteItem[]);
