import type {FC} from 'react';
import React, {useState, Fragment} from 'react';
import type {Settings} from 'react-slick';
import Slider from 'react-slick';
import {Card, Portal, PortalContainer, SliderContainer} from '../styled-components';
import type {ChartHeaderProps} from './components/chart-header';
import {ChartHeader} from './components/chart-header';
import './components/slider/slick.css';
import './components/slider/slick-theme.css';
import {CircularProgress, FontIcon} from 'react-md';

/**
 * @param portalId: Provides a portal to take out objects from the overflow hidden.
 */
interface MultiChartKPICardProps extends ChartHeaderProps, Settings {
  className?: string;
  portalId?: string;
  loading?: boolean;
}

const NextArrow = (props: any) => {
  const {className, style, onClick} = props;

  return (
    <div
      className={className}
      onClick={onClick}
      style={{
        ...style,
      }}
    >
      <FontIcon forceFontSize={true} forceSize={16}>
        arrow_forward_ios
      </FontIcon>
    </div>
  );
};
const PrevArrow = (props: any) => {
  const {className, style, onClick} = props;

  return (
    <div
      className={className}
      onClick={onClick}
      style={{
        ...style,
      }}
    >
      <FontIcon forceFontSize={true} forceSize={16}>
        arrow_back_ios
      </FontIcon>
    </div>
  );
};

export const MultiChartKPICard: FC<MultiChartKPICardProps> = ({
  className,
  portalId,
  title,
  subtitle,
  tooltip,
  children,
  loading,
  ...sliderProps
}) => {
  const [sliding, setSliding] = useState(false);
  const [mousePosition, setMousePosition] = useState({x: 0, y: 0});

  const settings: Settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };

  return (
    <Card
      className={className}
      onMouseMove={e => {
        setMousePosition({x: e.pageX, y: e.pageY});
      }}
    >
      <ChartHeader {...{title, subtitle, tooltip}} />
      {loading ? (
        <CircularProgress id="multikpi-loading" />
      ) : (
        <Fragment>
          <PortalContainer>
            <Portal id={portalId} mousePosition={mousePosition} />
          </PortalContainer>

          <SliderContainer sliding={sliding}>
            <Slider
              className="slider"
              {...settings}
              {...sliderProps}
              beforeChange={(_currentSlide, _nextSlide) => {
                setSliding(true);
              }}
              afterChange={currentSlide => {
                !!sliderProps.afterChange && sliderProps.afterChange(currentSlide);
                setSliding(false);
              }}
            >
              {children}
            </Slider>
          </SliderContainer>
        </Fragment>
      )}
    </Card>
  );
};
