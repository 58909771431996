import type {Season} from 'containers/map/types';
import type {MRVStageNormalized} from 'containers/mrv/types';
import {MRVStageType} from 'containers/mrv/types';
import {t} from 'i18n-utils';

export enum Tillage {
  Conservation = 'ConsTill', // this is a custom value, a sum of NoTill + High
  NoTill = 'NoTill',
  High = 'RedTillHighRC',
  Low = 'RedTillLowRC',
  Conventional = 'ConvTill',
  // NoData = 'NoData',
}

export const tillageColor = {
  [Tillage.Conservation]: '#5cc5ef',
  [Tillage.NoTill]: '#5cc5ef',
  [Tillage.High]: '#344d90',
  [Tillage.Low]: '#ffb745',
  [Tillage.Conventional]: '#e7552c',
};

export const tillageLabels = {
  [Tillage.Conservation]: () =>
    t({id: 'TillageLabels.ConservationTillage', defaultMessage: 'Conservation Tillage'}),
  [Tillage.NoTill]: () => t({id: 'TillageLabels.NoTillage', defaultMessage: 'No Tillage'}),
  [Tillage.High]: () =>
    t({
      id: 'TillageLabels.ReducedTillageHighResidue',
      defaultMessage: 'Reduced Tillage High Residue',
    }),
  [Tillage.Low]: () => t({id: 'TillageLabels.ReducedTillage', defaultMessage: 'Reduced Tillage'}),
  [Tillage.Conventional]: () =>
    t({id: 'TillageLabels.ConventionalTillage', defaultMessage: 'Conventional Tillage'}),
  // [Tillage.NoData]: 'No data',
};

// TODO: review to delete
// export enum CarbonPlanType {
//   Recommended = 'Recommended plan',
//   Custom = 'Custom plan',
// }

export enum CarbonPractice {
  NoTillCoverCrops = 'No Till + Cover Crops',
  NoTill = 'No Till',
  CoverCrops = 'Cover Crops',
  ReducedTill = 'Reduced Till',
  ReducedTillCoverCrops = 'Reduced Till + Cover Crops',
  NoValue = '',
}

// TODO: review to delete
// export const carbonPracticeOrder = [
//   CarbonPractice.NoTillCoverCrops,
//   CarbonPractice.ReducedTillCoverCrops,
//   CarbonPractice.CoverCrops,
//   CarbonPractice.NoTill,
//   CarbonPractice.ReducedTill,
// ];

export const practiceColors = {
  [CarbonPractice.CoverCrops]: '#b49fda',
  [CarbonPractice.NoTill]: '#5cc5ef',
  [CarbonPractice.NoTillCoverCrops]: '#47ab95',
  [CarbonPractice.ReducedTill]: '#ffb745',
  [CarbonPractice.ReducedTillCoverCrops]: '#6aa84f',
  [CarbonPractice.NoValue]: '#999',
};

export type SeasonPayload = {
  farmId?: number;
  fieldId: number;
  seasonId?: number;
  year: number;
  summerCrop?: string;
  winterCrop?: string;
  springTillage?: string;
  fallTillage?: string;
  season?: Season;
};

export const currentYear = new Date().getFullYear();
export const getActiveYears = (stage?: MRVStageNormalized) => {
  return !!stage
    ? [...Array(stage.year_end - stage.year_start + 1).keys()].map(i => stage.year_end - i)
    : [];
};

export const carbonTillageOrder: [Tillage.NoTill, Tillage.Low, Tillage.Conventional] = [
  Tillage.NoTill,
  Tillage.Low,
  Tillage.Conventional,
];

export const defaultEnrollmentStageTypes = [
  MRVStageType.AssignPractices,
  MRVStageType.FieldBoundaries,
  MRVStageType.ViewOutcomes,
  MRVStageType.Contract,
  MRVStageType.Eligibility,
  MRVStageType.Survey,
];
