import './home-page-components.scss';
import type {ComponentType, ReactElement, ReactNode} from 'react';
import React, {useEffect, useState} from 'react';
import cn from 'classnames';
import {Flex, FluroButton, Text} from 'components';
import type {ProgressItemProps} from 'components/fluro-progress-icon/fluro-progress-icon';
import {FluroProgressIcon} from 'components/fluro-progress-icon/fluro-progress-icon';
import styled from 'styled-components';
import {InfoIcon} from '../../../containers/map/icons';
import {CheckIcon} from 'components/icons';
import colors from '../../../variables.scss';
import {MRVStageIconComponent} from '../mrv-stage-icon';
import {t} from 'i18n-utils';
import {capitalizeFirstLetter} from '_utils/pure-utils';

// EVEN THO THESE COMPONENTS ARE NOT USED ANYWHERE, WE NEED THE CSS,
// BECAUSE WE INCLUDE HTML FROM DATABASE AND IT NEEDS TO BE STYLED.

export const ProgramStageItemsContainer = styled.div`
  margin-top: 11px;
`;

type ProgramStageItemProps = {
  active?: boolean;
  defaultCollapsed: boolean;
  title: string;
  subtitle?: string;
  programStageSubItems: ProgramStageSubItemProps[];
  infoIconAction?: () => void;
  disabled?: boolean;
};

export const ProgramStageItem = ({
  defaultCollapsed = true,
  title,
  subtitle,
  programStageSubItems,
  active,
  disabled,
  infoIconAction,
}: ProgramStageItemProps) => {
  const [collapsed, setCollapsed] = useState(defaultCollapsed);

  useEffect(() => {
    setCollapsed(defaultCollapsed);
  }, [defaultCollapsed]);

  const onInfoIconClick = (ev: React.MouseEvent<HTMLElement>) => {
    ev.preventDefault();
    ev.stopPropagation();
    infoIconAction && infoIconAction();
  };

  return (
    <div className={'element-full-width margin-bottom-15'}>
      <Flex
        onClick={disabled ? undefined : () => setCollapsed(!collapsed)}
        justifyContent={'space-between'}
        alignItems={'center'}
        className={cn({'program-stage-item': true, active, disabled})}
      >
        <Flex alignItems={'center'} gap={'10px'}>
          <Text className={'title'} fontWeight={500} elementType={'div'} variant={'h3'}>
            {title}
          </Text>
          {infoIconAction && <InfoIcon className={'info-icon'} onClick={onInfoIconClick} />}
        </Flex>

        <Flex alignItems={'center'} gap={'10px'}>
          <Text className={'subtitle'} variant={'small'}>
            {subtitle}
          </Text>
          <FluroButton disabled={disabled} icon>
            {collapsed ? 'keyboard_arrow_right' : 'keyboard_arrow_down'}
          </FluroButton>
        </Flex>
      </Flex>
      {!collapsed && (
        <div className={'program-stage-subitems-container'}>
          {programStageSubItems.map(item => (
            <ProgramStageSubItem key={item.title} {...item} />
          ))}
        </div>
      )}
    </div>
  );
};

export type ProgramStageSubItemProps = {
  title: string;
  subtitle?: ReactNode;
  badge?: ProgressItemProps['badge'];
  icon: ReactElement;
  percentComplete: number;
  outlined?: boolean;
  disabled?: boolean;
  loading?: boolean;
  dateOpen?: string;
  onClick?: () => void;
  actionLabel?: string;
  customAction?: ReactElement;
  message?: ReactNode;
  order: number;
};

export const createProgramStageSubItem = (): ProgramStageSubItemProps => ({
  title: '',
  icon: <MRVStageIconComponent />,
  percentComplete: 0,
  order: 0,
});

const ProgramStageSubItem = ({
  onClick,
  title,
  subtitle,
  badge,
  percentComplete,
  outlined,
  icon,
  actionLabel,
  disabled,
  loading,
  customAction,
  message,
}: ProgramStageSubItemProps) => {
  const itemIsDone = percentComplete === 100;
  const actionButtonLabel =
    percentComplete && !itemIsDone && actionLabel === 'start' ? 'continue' : actionLabel;

  return (
    <div className={cn('program-stage-subitem', {done: itemIsDone, disabled})}>
      <Flex
        key={title}
        fullWidth
        gap={'20px'}
        alignItems={'center'}
        onClick={!disabled && actionLabel && onClick ? onClick : undefined}
        nowrap
      >
        <FluroProgressIcon
          id={title}
          badge={badge}
          icon={
            itemIsDone ? (
              <CheckIcon
                circleColor={'transparent'}
                checkMarkColor={colors['main-green-600']}
                className="done-icon"
              />
            ) : (
              icon
            )
          }
          progressValue={percentComplete}
          outlined={!itemIsDone && outlined}
          disabled={disabled}
          active
        />
        <Flex direction="column">
          <div className={'label'} data-testid={`program-stage-subItem-title--${title}`}>
            {title}
          </div>
          {subtitle && (
            <Text style={{margin: 0}} variant="small" secondary>
              {subtitle}
            </Text>
          )}
        </Flex>

        {customAction ? (
          customAction
        ) : actionLabel && onClick ? (
          <FluroButton
            className={'action-button'}
            onClick={ev => {
              ev.stopPropagation();
              ev.preventDefault();
              onClick();
            }}
            raised
            disabled={disabled}
            loading={loading}
            primary={['start', 'continue'].includes(actionLabel)}
            blank={!['start', 'continue'].includes(actionLabel)}
            data-testid={`program-stage-subItem-action-button--${title}`}
          >
            {t({
              id: `BtnLabel.${capitalizeFirstLetter(actionButtonLabel as string)}`,
              defaultMessage: actionButtonLabel,
            })}
          </FluroButton>
        ) : null}
      </Flex>
      {message}
    </div>
  );
};

type ResourceItemProps = {
  icon: ReactElement;
  title: string;
  content: string;
  link: string;
};

export const ResourceItem = ({icon, content, title, link}: ResourceItemProps) => {
  return (
    <a className="resource-item" href={link} target="_blank" rel="noopener noreferrer">
      <div className="resource-item__icon-container">{icon}</div>
      <div>
        <div className="resource-item__title">{title}</div>
        <div className="resource-item__content">{content}</div>
      </div>
    </a>
  );
};

export const ResourcePhaseTitle: ComponentType = ({children}) => {
  return <h2 className={'resource-phase-title'}>{children}</h2>;
};

export const ResourcePhaseContent: ComponentType = ({children}) => {
  return <p className={'resource-phase-content'}>{children}</p>;
};

export const ResourceItemsContainer: ComponentType = ({children}) => {
  return <div className={'resource-items-container'}>{children}</div>;
};
