// @ts-nocheck
import type {Serie} from '@nivo/line';
import {ResponsiveLine} from '@nivo/line';
import {InfoBlock, Text} from 'components';
import {TREND_THRESHOLD} from 'modules/sustainability-insights/constants';
import type {SIAreaUnits} from 'modules/sustainability-insights/types';
import type {ComponentType} from 'react';
import React from 'react';
import {NoSelectedAreaMessage} from './messages';
import {TrendChartLegend} from './trend-chart-legend';
import './trend-chart.scss';
import {valueFormatter} from './utils';

type Props = {
  data: Serie[];
  units: SIAreaUnits;
  onClearSelection?: () => void;
  numUnitsLabel?: string;
};

export const TrendChart: ComponentType<Props> = ({
  data,
  units,
  onClearSelection,
  numUnitsLabel,
}) => {
  if (!data?.length) return <NoSelectedAreaMessage />;

  const getLineColor = (item: any) => {
    return item.color;
  };

  if (data.length > TREND_THRESHOLD) {
    return (
      <InfoBlock iconColor={'orange'} appearance={'warning'} className={'trends-data-limit-info'}>
        <div className={'bolded-title'}>You have reached the trend limit</div>
        <p>A maximum of {TREND_THRESHOLD} counties can be chosen when in trend mode. </p>
        {typeof onClearSelection === 'function' && (
          <Text role="button" link onClick={onClearSelection}>
            Clear selection
          </Text>
        )}
      </InfoBlock>
    );
  }

  return (
    <>
      <div className="chart-container mb-2">
        <ResponsiveLine
          data={data}
          lineWidth={1}
          pointSize={4}
          colors={getLineColor}
          margin={{top: 16, right: 24, bottom: 32, left: 65}}
          xScale={{type: 'point'}}
          yScale={{type: 'linear', min: 'auto', max: 'auto', stacked: false}}
          axisTop={null}
          enableGridX={false}
          enableGridY
          axisRight={null}
          enableCrosshair
          yFormat={(value: number) =>
            `${valueFormatter(value, units)} ${
              units === 'num' && numUnitsLabel ? numUnitsLabel : ''
            }`
          }
          axisBottom={{
            tickSize: 0,
            tickPadding: 16,
            tickValues: 4,
            tickRotation: 0,
          }}
          axisLeft={{
            tickSize: 0,
            tickPadding: 16,
            tickRotation: 0,
            tickValues: 4,
            format: value =>
              `${valueFormatter(value, units)} ${
                units === 'num' && numUnitsLabel ? numUnitsLabel : ''
              }`,
          }}
          useMesh={true}
        />
      </div>
      <TrendChartLegend data={data} />
    </>
  );
};
