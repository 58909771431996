import type {Props, Messages} from '../types';
import React from 'react';
import {Footer} from '../helpers';

import {
  CropStressIcon,
  ZoningIcon,
  FarmIcon,
  GrassIcon,
  TreeDetectionIcon,
  GlassIcon,
  ShapeIcon,
  EyeIcon,
  SatelliteIcon,
  PlaneIcon,
  WeatherIcon,
  RemoteSensingIcon,
  BugIcon,
  TreeIcon,
  TargetIcon,
  AnalyticsIcon,
  PolygonIcon,
  LeafIcon,
  FrostIcon,
  MachineryIcon,
  DroneIcon,
  PinIcon,
  TrialIcon,
  BarleyIcon,
  StarIcon,
} from '../icons.styled';

const data: Messages = {
  icons: {
    element: (props: Props) => (
      <div className="status-alert-dialog">
        <div className="status-alert-dialog__main">
          <div>
            Loren <CropStressIcon /> loren <ZoningIcon /> loren <FarmIcon /> loren <GrassIcon />{' '}
            loren <TreeDetectionIcon /> loren <GlassIcon /> loren <ShapeIcon /> loren <EyeIcon />{' '}
            loren <SatelliteIcon /> loren <PlaneIcon /> loren <WeatherIcon /> loren{' '}
            <RemoteSensingIcon /> loren <BugIcon /> loren <TreeIcon /> loren <TargetIcon /> loren{' '}
            <AnalyticsIcon /> loren <PolygonIcon /> loren <LeafIcon /> loren <FrostIcon /> loren{' '}
            <MachineryIcon /> loren <DroneIcon /> loren <PinIcon /> loren <TrialIcon /> loren{' '}
            <BarleyIcon /> lorem <StarIcon />
          </div>
        </div>

        <Footer {...props} />
      </div>
    ),
  },
};

export default data;
