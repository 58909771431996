import styled from 'styled-components';
import {
  main_gray_100,
  main_info_color,
  main_gray_900,
  main_gray_600,
  main_green_600,
} from '_utils/colors';

export const FarmCreateSelectorWrapper = styled.div`
  display: flex;
  align-items: center;

  .select {
    width: 130px;
  }

  .select-in-row {
    width: 150px;
  }
  @media (max-width: 460px) {
    .select {
      width: 210px;
    }
    .select-in-row {
      width: 110px;
      [class$="indicatorContainer"]{
      padding: 0px
    }
  }
`;

type PropSelectWrapperProps = {
  first?: boolean;
};

export const PropSelectWrapper = styled.div`
  width: 100%;
  margin-top: ${({first}: PropSelectWrapperProps) => (first ? 0 : '20px')};

  > .md-menu--select-field {
    width: 100%;
  }

  .md-select-field--text-field {
    overflow: hidden;
  }

  td {
    white-space: nowrap;
  }
`;

export const MappingTableWrapper = styled.div`
  color: ${main_info_color};
  background-color: rgba(207, 216, 220, 0.2);
  margin-top: 10px;

  .fluro-data-table {
    max-height: 300px;
  }

  td {
    white-space: nowrap;
  }
`;

export const ImportTitle = styled.div`
  margin-top: 25px;
  font-size: 18px;
`;

export const ParsedDataTableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;

  @media (max-width: 460px) {
    .md-table-column--adjusted {
      padding-right: 10px;
    }
  }
  .md-text-field-message {
    white-space: nowrap;
  }

  .md-table-column--adjusted {
    padding-right: 20px;
  }
`;

export const ToolbarWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding-right: 13px;

  color: ${main_gray_900};
  background-color: ${main_gray_100};
  margin-bottom: 10px;
  border-top-right-radius: 3px;
  border-top-left-radius: 3px;

  .select-farm {
    width: 130px;
    margin-left: 12px;

    .md-autocomplete-list {
      width: auto !important; // the menu can be wider than the parent component
      max-width: 200px;
    }
  }

  ${FarmCreateSelectorWrapper} {
    margin-right: 10px;
    margin-left: 10px;
  }
  @media (max-width: 460px) {
    padding-right: 5px;
    ${FarmCreateSelectorWrapper} {
      margin-right: 10px;
      width: 187px;
    }
  }
`;

export const FieldsCountLabel = styled.div`
  color: ${main_gray_600};
  font-size: 14px;
`;

export const ListWrapper = styled.div`
  overflow-x: auto;
  overflow-y: hidden;
  flex-grow: 1;
  flex-basis: 1;
  min-height: ${({minHeight}: {minHeight?: number}) => (minHeight ? `${minHeight}px` : 'auto')};
  border-radius: 3px;
  box-shadow: 0px 0px 3px rgba(63, 63, 68, 0.1), 0px -1px 0px rgba(224, 226, 228, 0.8),
    -1px 0px 0px rgba(224, 226, 228, 0.8), 0px 1px 0px rgba(224, 226, 228, 0.8),
    1px 0px 0px rgba(224, 226, 228, 0.8);
`;

export const AddNewFarmInputComponent = styled.div`
  display: flex;
  .input {
    padding: 7px 10px;
    border-color: ${main_gray_600};
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
  }
  .add-btn {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
    width: 48px;
    height: 32px;
    margin: 0;
    padding: 0;
    min-width: auto;
    box-shadow: none;
  }

  .md-text--error {
    margin-bottom: -4px; // fix the fluro-select-lite item
  }
`;
export const AddNewFarmBtn = styled.div`
  display: flex;
  align-items: center;
  font-weight: 500;
  text-transform: uppercase;
  gap: 5px;
  color: ${main_green_600};
  .md-icon {
    color: inherit;
  }
`;
