import type {DrawMode} from '@mapbox/mapbox-gl-draw';
import MapboxDraw from '@mapbox/mapbox-gl-draw';
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';
import {circle, helpers as turfHelpers, distance} from '@turf/turf';

const modes = {
  DRAW_LINE_STRING: 'draw_line_string',
  DRAW_POLYGON: 'draw_polygon',
  DRAW_POINT: 'draw_point',
  SIMPLE_SELECT: 'simple_select',
  DIRECT_SELECT: 'direct_select',
  STATIC: 'static',
};

const geojsonTypes = {
  FEATURE: 'Feature',
  POLYGON: 'Polygon',
  LINE_STRING: 'LineString',
  POINT: 'Point',
  FEATURE_COLLECTION: 'FeatureCollection',
  MULTI_PREFIX: 'Multi',
  MULTI_POINT: 'MultiPoint',
  MULTI_LINE_STRING: 'MultiLineString',
  MULTI_POLYGON: 'MultiPolygon',
};

const cursors = {
  ADD: 'add',
  MOVE: 'move',
  DRAG: 'drag',
  POINTER: 'pointer',
  NONE: 'none',
};

const types = {
  POLYGON: 'polygon',
  LINE: 'line_string',
  POINT: 'point',
};

// const classes = {
//   CONTROL_BASE: 'mapboxgl-ctrl',
//   CONTROL_PREFIX: 'mapboxgl-ctrl-',
//   CONTROL_BUTTON: 'mapbox-gl-draw_ctrl-draw-btn',
//   CONTROL_BUTTON_LINE: 'mapbox-gl-draw_line',
//   CONTROL_BUTTON_POLYGON: 'mapbox-gl-draw_polygon',
//   CONTROL_BUTTON_POINT: 'mapbox-gl-draw_point',
//   CONTROL_BUTTON_TRASH: 'mapbox-gl-draw_trash',
//   CONTROL_BUTTON_COMBINE_FEATURES: 'mapbox-gl-draw_combine',
//   CONTROL_BUTTON_UNCOMBINE_FEATURES: 'mapbox-gl-draw_uncombine',
//   CONTROL_GROUP: 'mapboxgl-ctrl-group',
//   ATTRIBUTION: 'mapboxgl-ctrl-attrib',
//   ACTIVE_BUTTON: 'active',
//   BOX_SELECT: 'mapbox-gl-draw_boxselect',
// };

const dragPan = {
  enable(ctx: any) {
    setTimeout(() => {
      ctx.map.dragPan.enable();
    }, 0);
  },
  disable(ctx: any) {
    setTimeout(() => {
      if (!ctx.map || !ctx.map.doubleClickZoom) return;
      // Always disable here, as it's necessary in some cases.
      ctx.map.dragPan.disable();
    }, 0);
  },
};

const DragCircleMode = {...MapboxDraw.modes.draw_polygon};

DragCircleMode.onSetup = function () {
  const polygon = this.newFeature({
    type: geojsonTypes.FEATURE as any,
    properties: {
      center: [],
    },
    geometry: {
      type: geojsonTypes.POLYGON as any,
      coordinates: [[]],
    },
  });

  this.addFeature(polygon);
  dragPan.disable(this);
  this.clearSelectedFeatures();
  this.updateUIClasses({mouse: cursors.ADD});
  this.activateUIButton(types.POLYGON);
  return {
    polygon,
    currentVertexPosition: 0,
  };
};

DragCircleMode.onMouseDown = DragCircleMode.onTouchStart = function (state: any, e: any) {
  const currentCenter = state.polygon.properties.center;
  if (currentCenter.length === 0) {
    state.polygon.properties.center = [e.lngLat.lng, e.lngLat.lat];
  }
};

DragCircleMode.onDrag = DragCircleMode.onMouseMove = function (state: any, e: any) {
  const center = state.polygon.properties.center;
  if (center.length > 0) {
    const distanceInKm = distance(
      turfHelpers.point(center),
      turfHelpers.point([e.lngLat.lng, e.lngLat.lat]),
      {units: 'kilometers'}
    );
    const circleFeature = circle(center, distanceInKm);
    state.polygon.incomingCoords(circleFeature.geometry.coordinates);
    state.polygon.properties.radiusInKm = distanceInKm;
  }
};

DragCircleMode.onMouseUp = DragCircleMode.onTouchEnd = function (state: any) {
  dragPan.enable(this);
  return this.changeMode(modes.SIMPLE_SELECT as DrawMode, {featureIds: [state.polygon.id]});
};

DragCircleMode.onClick = DragCircleMode.onTap = function (state: any) {
  // don't draw the circle if its a tap or click event
  state.polygon.properties.center = [];
};

export {dragPan, DragCircleMode};
