import {setActiveGeometriesFilter} from 'modules/sustainability-insights/actions';
import {
  selectAggLevel,
  selectIsEveryVisibleGeometrySelected,
  selectIsGeometryActive,
  selectIsGeometryHighlighted,
  selectNameById,
} from 'modules/sustainability-insights/selectors';
import {setStateIdsRequestParamThunk} from 'modules/sustainability-insights/thunks';
import type {AreaId} from 'modules/sustainability-insights/types';
import {SIGeometryFilterType} from 'modules/sustainability-insights/types';
import React from 'react';
import {batch} from 'react-redux';
import {useAppDispatch, useAppSelector} from '_hooks';
import SICardTypeRouter from '../base/si-card-type-router';
import {AbatementPotentialGeometry} from './abatement-potential-geometry';
import {CoverCropsGeometry} from './cover-crops-geometry';
import {TillageGeometry} from './tillage-geometry';
import {SOCGeometry} from './soc-geometry';
import {GHGGeometry} from './ghg-geometry';
import {YieldGeometry} from './yield-geometry';
import {FertilizerGeometry} from './fertilizer-geometry';
import {EmissionsFactorGeometry} from './emissions-factor-geometry';

type Props = {
  id: AreaId;
};

export const SIGeometry = ({id}: Props) => {
  const dispatch = useAppDispatch();
  const name = useAppSelector(s => selectNameById(s, id));
  const isHighlighted = useAppSelector(s => selectIsGeometryHighlighted(s, id));
  const isActive = useAppSelector(s => selectIsGeometryActive(s, id));
  const allSelected = useAppSelector(selectIsEveryVisibleGeometrySelected);

  const aggLevel = useAppSelector(selectAggLevel);

  const handleGeometryClick = () => {
    // Multiselect should work for default click without a meta/ctrl/shift key
    // but there is an exception of when every geometry is selected
    const multiselect = !allSelected;
    batch(() => {
      dispatch(setActiveGeometriesFilter(id, {multiselect}));
      if (aggLevel === 'state') {
        dispatch(setStateIdsRequestParamThunk(id, multiselect));
      }
    });
  };

  return (
    <>
      <SICardTypeRouter type={SIGeometryFilterType.CoverCrops}>
        <CoverCropsGeometry
          id={id}
          name={name}
          isActive={isActive}
          isHighlighted={isHighlighted}
          onClick={handleGeometryClick}
        />
      </SICardTypeRouter>

      <SICardTypeRouter type={SIGeometryFilterType.AbatementPotential}>
        <AbatementPotentialGeometry
          id={id}
          name={name}
          isActive={isActive}
          isHighlighted={isHighlighted}
          onClick={handleGeometryClick}
        />
      </SICardTypeRouter>

      <SICardTypeRouter type={SIGeometryFilterType.Tillage}>
        <TillageGeometry
          id={id}
          name={name}
          isActive={isActive}
          isHighlighted={isHighlighted}
          onClick={handleGeometryClick}
        />
      </SICardTypeRouter>

      <SICardTypeRouter type={SIGeometryFilterType.SOC}>
        <SOCGeometry
          id={id}
          name={name}
          isActive={isActive}
          isHighlighted={isHighlighted}
          onClick={handleGeometryClick}
        />
      </SICardTypeRouter>

      <SICardTypeRouter type={SIGeometryFilterType.GHG}>
        <GHGGeometry
          id={id}
          name={name}
          isActive={isActive}
          isHighlighted={isHighlighted}
          onClick={handleGeometryClick}
        />
      </SICardTypeRouter>
      <SICardTypeRouter type={SIGeometryFilterType.Yield}>
        <YieldGeometry
          id={id}
          name={name}
          isActive={isActive}
          isHighlighted={isHighlighted}
          onClick={handleGeometryClick}
        />
      </SICardTypeRouter>

      <SICardTypeRouter type={SIGeometryFilterType.Fertilizer}>
        <FertilizerGeometry
          id={id}
          name={name}
          isActive={isActive}
          isHighlighted={isHighlighted}
          onClick={handleGeometryClick}
        />
      </SICardTypeRouter>

      <SICardTypeRouter type={SIGeometryFilterType.EmissionsFactor}>
        <EmissionsFactorGeometry
          id={id}
          name={name}
          isActive={isActive}
          isHighlighted={isHighlighted}
          onClick={handleGeometryClick}
        />
      </SICardTypeRouter>
    </>
  );
};
