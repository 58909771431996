// @ts-nocheck
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {useDispatch} from 'react-redux';
import type {ImageOverlayProps} from 'react-leaflet';
import {ImageOverlay} from 'react-leaflet';
import type {LatLngBoundsExpression} from 'leaflet';
import Mixpanel from '_utils/mixpanel-utils';
import {AsyncStatusType, setRequestStatus, Status} from 'modules/helpers';
import axios from 'axios';
import {reportError} from '../../containers/error-boundary';
import {saveImage, selectImageCache} from 'modules/images-cache.module';
import {resolveEventHandlers} from './fix-click-handler';
import {
  selectColorSchema,
  selectCurrentFieldId,
  selectImageLayerOpacity,
  selectIsCompareOn,
} from '../../containers/map/reducer/selectors';
import {useAppSelector} from '../../_hooks';
import {selectAsyncRequestStatus} from 'modules/global/selectors';
import {addColorSchemaToImageUrl} from '_utils/imagery-utils';

type Props = {
  url: string;
  id?: string;
  onErrorCallback?(): void;
  lRef?: any;
  bounds: LatLngBoundsExpression;
  isCompare?: boolean;
  interactive?: boolean; // to emit mouse events
  isZoning?: boolean;
  onStartLoadBinary?: () => void;
  onEndLoadBinary?: () => void;
  eventHandlers?: ImageOverlayProps['eventHandlers'];
};

export const FluroImageOverlay = ({
  url,
  onErrorCallback,
  lRef,
  id,
  bounds,
  isCompare,
  interactive,
  eventHandlers,
  isZoning,
  onStartLoadBinary,
  onEndLoadBinary,
}: Props) => {
  const dispatch = useDispatch();

  const imageLayerOpacity = useAppSelector(selectImageLayerOpacity);
  const selectedFieldId = useAppSelector(selectCurrentFieldId);
  const isCompareOn = useAppSelector(selectIsCompareOn);
  const loadingImageLayer =
    useAppSelector(s => selectAsyncRequestStatus(s, AsyncStatusType.imageLayer)) === Status.Pending;
  const loadingCompareLayer =
    useAppSelector(s => selectAsyncRequestStatus(s, AsyncStatusType.binaryImageLayerData)) ===
      Status.Pending && isCompareOn;

  const isImageLayerLoading = loadingImageLayer || loadingCompareLayer;
  const cachedImages = useAppSelector(selectImageCache);
  const colorSchema = useAppSelector(selectColorSchema);

  const urlWithColorSchema = useMemo(() => {
    if (isZoning) {
      return url; // no need to add the GET param to zoning + it already has get params so adding a new one with ? breaks the request
    }
    return addColorSchemaToImageUrl(url, colorSchema);
  }, [colorSchema, url, isZoning]);

  const [imageDataUrl, setImageDataUrl] = useState<string>('');

  useEffect(function mountUnmountHooks() {
    return () => {
      toggleLoading(false);
    };
  }, []);

  const toggleLoading = useCallback(
    (value: boolean) => {
      // for zoning we do not want to block screen
      const requestImageType = isZoning
        ? AsyncStatusType.zoningImageOverlay
        : AsyncStatusType.imageLayer;
      const status = value ? Status.Pending : Status.Done;

      dispatch(setRequestStatus(requestImageType, status));
    },
    [isZoning]
  );

  const isOriginDomainUrl = useCallback((url: string) => url?.startsWith('/'), []);

  const onLoad = () => {
    if (!isOriginDomainUrl(url)) {
      toggleLoading(false);
    }
  };

  const onError = () => {
    if (!isOriginDomainUrl(url)) {
      onErrorCallback && onErrorCallback();
      toggleLoading(false);
    }
  };

  useEffect(
    function handleUrlChange() {
      if (selectedFieldId !== 'WholeFarm') {
        !isCompare && Mixpanel.pageView();
        toggleLoading(true);
      }
    },
    [url]
  );

  useEffect(() => {
    (async () => {
      try {
        /*
         *
         * Regular images loading
         * (in feature regular images will be loading from Origin domain /service)
         * */
        if (!isOriginDomainUrl(urlWithColorSchema)) {
          setImageDataUrl(urlWithColorSchema);
        } else {
          /*
           *
           * Planet images loading.
           * Prefetch image data need for extract http headers with min,max scale information to build color schema scale
           *
           * */

          if (cachedImages.data[urlWithColorSchema]) {
            toggleLoading(false);
            return;
          }

          onStartLoadBinary?.();

          const response = await axios.get(urlWithColorSchema, {
            responseType: 'blob',
            params: {__skipPreloader: true},
          });

          const reader = new FileReader();
          reader.readAsDataURL(response.data);
          reader.onload = function () {
            setImageDataUrl(reader.result as string);

            let meta: any;

            try {
              meta = JSON.parse(response.headers['fs-index-scale'] || null);
            } catch (e) {
              reportError(
                `Invalid json value at fs-index-scale ${response.headers['fs-index-scale']}, Image URL: ${urlWithColorSchema}`
              );
            }

            dispatch(saveImage(urlWithColorSchema, reader.result as string, meta));

            toggleLoading(false);
            onEndLoadBinary?.();
          };
        }
      } catch (e) {
        onErrorCallback && onErrorCallback();
        toggleLoading(false);

        if (!isOriginDomainUrl(urlWithColorSchema)) {
          onEndLoadBinary?.();
        }
      }
    })();
  }, [urlWithColorSchema, cachedImages]);

  return useMemo(() => {
    if (!imageDataUrl && !cachedImages.data[urlWithColorSchema]) return null;

    const opacity = isImageLayerLoading ? 0 : imageLayerOpacity * 0.01;
    const overlayUrl = cachedImages.data[urlWithColorSchema]
      ? cachedImages.data[urlWithColorSchema].data
      : imageDataUrl;
    const handlers = resolveEventHandlers({
      load: onLoad,
      error: onError,
      ...eventHandlers,
    });

    return (
      <ImageOverlay
        // for some reason the changing props do not trigger Image overlay re-render
        // to resolve the problem - added the key prop, to directly trigger the re-render
        key={overlayUrl}
        bounds={bounds}
        eventHandlers={handlers}
        interactive={interactive}
        opacity={opacity}
        ref={l => (lRef ? lRef(l) : '')}
        url={overlayUrl}
      />
    );
  }, [id, urlWithColorSchema, imageLayerOpacity, isImageLayerLoading, imageDataUrl, cachedImages]);
};
