// @ts-nocheck
import {t} from 'i18n-utils';
import React, {useCallback, useState, useEffect} from 'react';
import {TextField} from 'react-md';
import {FluroButton, FluroDialog} from 'components';
import {useDispatch} from 'react-redux';
import {DialogType, dialogToggle} from 'modules/helpers';
import {useAppSelector} from '../../_hooks';
import {selectDialogData} from '../../modules/helpers/selectors';

const DeleteDialog = () => {
  const dispatch = useDispatch();
  const [confirmValue, setConfirmValue] = useState('');
  const {title, onSubmit} =
    useAppSelector(s => selectDialogData(s, DialogType.deleteDialog))?.payload || {};

  const onHide = useCallback(() => {
    dispatch(dialogToggle(DialogType.deleteDialog, false));
  }, []);

  useEffect(() => {
    const onKeyPress = (e: KeyboardEvent) => {
      switch (e.code) {
        case 'Escape':
          onHide();
          break;
        case 'Enter':
          if (confirmValue === t({id: 'TypeToConfirm.DELETE', defaultMessage: 'DELETE'})) {
            onSubmit();
          }
          break;
      }
    };
    document.addEventListener('keydown', onKeyPress);
    return () => {
      document.removeEventListener('keydown', onKeyPress);
    };
  });

  const autocompleteProp = {
    autoComplete: 'off',
  };

  return (
    <FluroDialog // delete dialog
      id="delete-dialog"
      visible={true}
      onHide={onHide}
      title={title}
      isDraggable
      portal
      focusOnMount
      initialFocus={'#confirm-delete-input'}
    >
      <TextField
        id="confirm-delete-input"
        label={t({id: "Type in the word 'DELETE' to confirm"})}
        value={confirmValue}
        onChange={(v: string) => setConfirmValue(v)}
        {...autocompleteProp}
      />

      <div className={'dialog-footer-buttons'}>
        <FluroButton raised blank onClick={onHide}>
          {t({id: 'Cancel'})}
        </FluroButton>
        <FluroButton
          id={'confirm-delete-btn'}
          raised
          primary
          disabled={!(confirmValue === t({id: 'TypeToConfirm.DELETE', defaultMessage: 'DELETE'}))}
          onClick={onSubmit}
        >
          {t({id: 'Confirm'})}
        </FluroButton>
      </div>
    </FluroDialog>
  );
};

export default DeleteDialog;
