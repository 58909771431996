import React from 'react';
import styled from 'styled-components';
import {CircularProgress, Portal} from 'react-md';

const Wrapper = styled.div`
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  top: 0;
  z-index: 11;
  pointer-events: none;
  display: flex;
  justify-content: center;
  align-items: center;

  .box {
    width: 40px;
    height: 40px;
    box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12),
      0 2px 4px -1px rgba(0, 0, 0, 0.4);
    background-color: rgba(255, 255, 255, 0.3);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .md-circular-progress-path {
    stroke: #fff;
  }
`;

export const MapLoader = ({loading}: {loading: boolean}) => {
  if (!loading) {
    return null;
  }

  return (
    <Portal visible>
      <Wrapper>
        <div className="box">
          <CircularProgress id="image-layer-preloader" />
        </div>
      </Wrapper>
    </Portal>
  );
};
