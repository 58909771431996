// @ts-nocheck
import React, {useMemo} from 'react';
import './soil-map-schema.scss';
import {useSelector} from 'react-redux';
import type {AppStore} from 'reducers';
import {getSoilColorByType} from '_utils/colors';
import {removeDuplicates} from '_utils/pure-utils';
import {useAppSelector} from '../../../../_hooks';
import {selectIsWholeFarmView} from '../../reducer/selectors';

const SoilMapSchema = () => {
  const fields = useSelector((state: AppStore) => state.map.fields);
  const field = useSelector((state: AppStore) => state.map.field);
  const isWholeFarmView = useAppSelector(selectIsWholeFarmView);

  const soilTypesList = useMemo(() => {
    const preparedFeatures = isWholeFarmView
      ? fields.filter(f => f.soilLayer).flatMap(f => f.soilLayer.features)
      : field.soilLayer
      ? field.soilLayer.features
      : [];

    return removeDuplicates(
      preparedFeatures.map(feature => ({
        label: feature.properties.texture,
        backgroundColor: getSoilColorByType(feature.properties.texture),
      })),
      'label'
    );
  }, [fields, field, isWholeFarmView]);

  return soilTypesList.length ? (
    <div className="soil-map-schema-container">
      {soilTypesList.map(({backgroundColor, label}) => (
        <div key={label} style={{backgroundColor}} className={'soil-type-item'}>
          {label}
        </div>
      ))}
    </div>
  ) : null;
};

export default SoilMapSchema;
