import type {ComponentType} from 'react';
import React from 'react';
import {useAppSelector} from '_hooks';
import {selectYearsFilter} from 'modules/sustainability-insights/selectors';
import {Text} from 'components';

type Props = {};

export const YearsFilterRangeLabel: ComponentType<Props> = () => {
  const years = useAppSelector(selectYearsFilter);

  if (!years) return null;
  const label = years.filter(Boolean).join(' - ');

  return (
    <Text variant={'h3'} elementType="span" fontWeight={300}>
      ({label})
    </Text>
  );
};
