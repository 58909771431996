import {useAppDispatch, useAppSelector} from '_hooks';
import {useParsedMatchParams} from '_hooks/use-parsed-match-params';
import {isDefined} from '_utils/typeGuards';
import {setCurrentProgram} from 'containers/si/module/reducer';
import {selectCurrentProgram} from 'containers/si/module/selectors';
import {SI_FEATURE_GROUPS} from 'containers/si/routes';
import React from 'react';

export const useProgramId = () => {
  const dispatch = useAppDispatch();

  const {programId} = useParsedMatchParams<{programId: string | undefined}>(SI_FEATURE_GROUPS);
  const paramsProgramId = isDefined(programId) ? Number(programId) : undefined;
  const stateProgramId = useAppSelector(selectCurrentProgram);

  React.useEffect(() => {
    if (isDefined(paramsProgramId) && paramsProgramId !== stateProgramId) {
      dispatch(setCurrentProgram(paramsProgramId));
    }
  }, [stateProgramId, dispatch, paramsProgramId]);

  return {paramsProgramId, stateProgramId};
};
