import React from 'react';
import type {ComponentType} from 'react';
import {FontIcon} from 'react-md';
import {Flex, FluroButton} from 'components';
import {useAppSelector, useAppDispatch} from '_hooks';
import {showNotification} from 'components/notification/notification';
import {CircleIcon, PartFieldIcon, ShapeIcon} from 'containers/map/icons';
import {
  selectSubsectionGeometries,
  selectSubsectionSelectedFeatures,
} from 'containers/si/module/selectors';
import {
  removeSubsectionGeometries,
  removeSubsectionSelectedFeatures,
} from 'containers/si/module/reducer';
import '../supply-shed.scss';

type Props = {
  color: string;
};

export const SubsectionCard: ComponentType<Props> = ({color}: Props) => {
  const dispatch = useAppDispatch();

  // Selected States / Counties / etc. on the map
  const subsectionSelectedFeatures = useAppSelector(selectSubsectionSelectedFeatures);
  const subsectionGeometries = useAppSelector(selectSubsectionGeometries);

  const areas =
    Object.keys(subsectionSelectedFeatures).length + Object.keys(subsectionGeometries).length;

  const deleteFeature = (featureID: string, name: string) => {
    if (confirm(`Are you sure you want to delete ${name}?`)) {
      showNotification({
        type: 'success',
        title: 'Success',
        message: `${name} was removed.`,
      });
      dispatch(removeSubsectionSelectedFeatures({featureID}));
    }
  };

  const deleteGeometry = (featureID: string, name: string) => {
    if (confirm(`Are you sure you want to delete this ${name}?`)) {
      showNotification({
        type: 'success',
        title: 'Success',
        message: `The ${name} was removed.`,
      });
      dispatch(removeSubsectionGeometries({featureID}));
    }
  };

  return (
    <div className="subsection-card mt-3">
      <div className="subsection-header">
        <div className="subsection-color" style={{borderLeftColor: color}}></div>
        <Flex direction="row" alignItems="center">
          <div className="subsection-name">
            {areas === 0 ? 'Select an area on the map' : `${areas} areas selected`}
          </div>
        </Flex>
      </div>
      {/* States and Counties */}
      {Object.values(subsectionSelectedFeatures).map(feature => {
        return (
          <SubsectionRow
            id={feature?.properties?.id || feature?.properties?.FIPS}
            key={feature?.properties?.id || feature?.properties?.FIPS}
            subsectionName={
              feature?.properties?.feature_name
                ? feature?.properties?.feature_name
                : `${feature?.properties?.name}`
            }
            color={color}
            deleteGeometry={deleteFeature}
          />
        );
      })}
      {/* Circles and Polygons */}
      {Object.values(subsectionGeometries).map(geo => {
        return (
          <SubsectionRow
            id={geo.id}
            key={geo.id}
            subsectionName={geo.properties.center ? 'Circle' : 'Polygon'}
            color={color}
            deleteGeometry={deleteGeometry}
          />
        );
      })}
    </div>
  );
};

type SubsectionRowProps = {
  id: string;
  subsectionName: string;
  color: string;
  deleteGeometry: (id: string, name: string) => void;
};

const SubsectionRow: ComponentType<SubsectionRowProps> = ({
  id,
  subsectionName,
  color,
  deleteGeometry,
}: SubsectionRowProps) => {
  return (
    <Flex
      className="subsection-row"
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      gap="8px"
      nowrap
      key={id}
    >
      <FontIcon style={{color: color}}>
        {subsectionName === 'Circle' ? (
          <CircleIcon />
        ) : subsectionName === 'Polygon' ? (
          <ShapeIcon />
        ) : (
          <PartFieldIcon fill="inherit" />
        )}
      </FontIcon>
      <div className="subsection-row-name">{subsectionName}</div>
      <FluroButton
        icon
        iconEl={<FontIcon>delete</FontIcon>}
        onClick={() => deleteGeometry(id, subsectionName)}
        tooltipPosition={'left'}
        tooltipLabel={'Remove subsection'}
        tooltipStyle={{left: '-40px'}} // tooltip was flashing a scrollbar, so moved it over
      />
    </Flex>
  );
};
