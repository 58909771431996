import type {ComponentType} from 'react';
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {t} from 'i18n-utils';
import {Button, CircularProgress, SelectField, SelectionControl, TextField} from 'react-md';
import cn from 'classnames';
import IntegrationCard from './helpers/integration-card';
import {sortByStringKey} from 'containers/profile/integration/helpers/utils';
import {JohnDeereApi} from '_api';
import {
  ExpandableArea,
  ExpandableRowContainer,
  NoItemsTitle,
  SubContainer,
  SubItem,
} from 'components/expandable-table-items/expandable-table-items';
import {RequestStatus} from '../../../types';
import Mixpanel from '_utils/mixpanel-utils';
import {ExternalService} from '../../map/types';
import {
  setPlatformAuthStatus,
  syncJohnDeereOrganizations,
  syncJohnDeereOrganizationsForMRV,
} from './actions';
import {reportError} from '../../error-boundary';
import {FluroButton, FluroDataTable, FluroTablePagination, Ln, Sticky} from 'components';
import {FormattedMessage} from 'react-intl';
import config from '_environment';
import {useAppDispatch, useAppSelector} from '_hooks';
import {Flex} from 'components/flex/flex';
import {MRVFeature} from 'components/mrv/mrv-feature';
import {selectIntegrationPlatform} from './integration-selectors';
import {
  selectEnrollmentProjectIdRedirectUrlForFMS,
  selectPhase,
  selectProjectId,
} from '../../mrv/monitoring/module/selectors';
import type {IntegrationComponentProps} from './types';
import {SelectedPlatformBackButton} from './helpers/selected-platform-back-btn';
import {SelectFarmsAndFieldsToImportRow} from './helpers/select-farms-fields-to-import-row';
import {MRVPhaseType} from 'containers/mrv/types';

type JohnDeereEntity = {
  name: string;
  id: string;
  connections?: string;
};

export type JohnDeereRequestPayload = {
  orgs?: string[];
  clients?: {
    org_id: string;
    client_id: string;
  }[];
  farms?: {
    org_id: string;
    farm_id: string;
  }[];
  fields?: {
    org_id: string;
    field_id: string;
  }[];
};

type JDEntityType = 'clients' | 'farms' | 'fields' | 'organizations';

export const classifyJohnDeereHighestEntity = (data: JohnDeereRequestPayload) => {
  if (data.clients) {
    return t({id: 'clients'});
  } else if (data.farms) {
    return t({id: 'farms'});
  } else if (data.fields) {
    return t({id: 'fields'});
  } else {
    return t({id: 'organizations'});
  }
};

const noEntities: JohnDeereEntity[] = [{name: 'no-entities', id: 'no-entities'}];

/**
 * John Deere has a hierarchy of data that goes: Organization -> Client -> Farm -> Field
 */

const IntegrationJohnDeere: ComponentType<IntegrationComponentProps> = ({expanded}) => {
  const dispatch = useAppDispatch();
  const platform = useAppSelector(s => selectIntegrationPlatform(s, ExternalService.JohnDeere));
  const authorized = !!platform?.authorized;
  const loginRedirectUrl = useAppSelector(selectEnrollmentProjectIdRedirectUrlForFMS);

  // TODO (stas): MRV stuff shouldn't be done like that in a profile component,
  // that is wider than MRV. It's also used in Crop insights, etc.
  // MRV stuff should be injected more elegantly,
  // but this popup gets opened from /map/add-fields flow,
  // which is also not mrv-specific and is hard to plug into.
  const projectId = useAppSelector(selectProjectId);
  // We need to pass any phase at the point of enrolling the fields.
  const phase = useAppSelector(s => selectPhase(s, MRVPhaseType.Enrollment));

  const [organizations, setOrganizations] = useState<JohnDeereEntity[]>([]);
  const [selectedOrganizations, setSelectedOrganizations] = useState<string[]>([]);
  const [expandedOrganizations, setExpandedOrganizations] = useState<{[growerId: string]: boolean}>(
    {}
  );

  const [clients, setClients] = useState<{[orgId: string]: JohnDeereEntity[]}>({});
  const [selectedClients, setSelectedClients] = useState<{[orgId: string]: string[]}>({});
  const [expandedClients, setExpandedClients] = useState<{[clientId: string]: boolean}>({});

  const [farms, setFarms] = useState<{[clientId: string]: JohnDeereEntity[]}>({});
  const [selectedFarms, setSelectedFarms] = useState<{[clientId: string]: string[]}>({});
  const [expandedFarms, setExpandedFarms] = useState<{[farmId: string]: boolean}>({});

  const [fields, setFields] = useState<{[farmId: string]: JohnDeereEntity[]}>({});
  const [selectedFields, setSelectedFields] = useState<{[farmId: string]: string[]}>({});

  const [requests, setRequests] = useState<{[entityId: string]: RequestStatus}>({});
  const [pagination, setPagination] = useState({start: 0, perPage: 20, page: 1});

  // search
  const [filterBy, setFilterBy] = useState<JDEntityType>('organizations');
  const [filterString, setFilterString] = useState('');

  const onAuthorize = () => {
    window.location.assign(`${config.baseUrl}login/johndeere${loginRedirectUrl}`);
  };

  const getOrganizations = useCallback(() => {
    if (!expanded || !authorized || organizations.length) {
      return;
    }

    setRequests(state => ({...state, organizations: RequestStatus.Loading}));

    JohnDeereApi.getOrganizations()
      .then(({data}) => {
        const sortedOrganizations = sortByStringKey(data.data || [], 'name');

        setRequests(state => ({...state, organizations: RequestStatus.Success}));
        setOrganizations(sortedOrganizations);
      })
      .catch(err => {
        if (err.status !== 400) {
          reportError(`John Deere getOrganizations err ${err}`);
        }
        setRequests(state => ({...state, organizations: RequestStatus.Idle}));
        dispatch(setPlatformAuthStatus(ExternalService.JohnDeere, false));
      });
  }, [authorized, dispatch, expanded, organizations.length]);

  useEffect(() => {
    getOrganizations();
  }, [authorized, expanded, getOrganizations]);

  const getClients = (orgId: string) => {
    setRequests(r => ({...r, [orgId]: RequestStatus.Loading}));

    JohnDeereApi.getClients(orgId)
      .then(({data}) => {
        const sortedClients = sortByStringKey(data.data || noEntities, 'name');

        setClients(currentClients => ({...currentClients, [orgId]: sortedClients}));
        setRequests(r => ({...r, [orgId]: RequestStatus.Success}));
        if (selectedOrganizations.includes(orgId)) {
          // If the expanded org was selected, select all the including clients automatically.
          setSelectedClients(state => ({
            ...state,
            [orgId]: sortedClients.map(client => client.id),
          }));
        }
      })
      .catch(e => {
        setClients(currentClients => ({...currentClients, [orgId]: noEntities}));
        setRequests(r => ({...r, [orgId]: RequestStatus.Error}));
        reportError(`John Deere sync error during fetching clients for org ${orgId}: ${e}`);
      });
  };

  const getFarms = (organizationId: string, clientId: string) => {
    setRequests(r => ({...r, [clientId]: RequestStatus.Loading}));

    JohnDeereApi.getFarms(organizationId, clientId)
      .then(({data}) => {
        const sortedFarms = sortByStringKey(data.data || noEntities, 'name');

        setFarms(state => ({...state, [clientId]: sortedFarms}));
        setRequests(r => ({...r, [clientId]: RequestStatus.Success}));
        if (selectedClients[organizationId]?.includes(clientId)) {
          // If the expanded client was selected, select all the including farms automatically.
          setSelectedFarms(state => ({
            ...state,
            [clientId]: sortedFarms.map((farm: JohnDeereEntity) => farm.id),
          }));
        }
      })
      .catch(e => {
        setFarms(state => ({...state, [clientId]: noEntities}));
        setRequests(r => ({...r, [clientId]: RequestStatus.Error}));
        reportError(`John Deere error during fetching farms for client ${clientId}: ${e}`);
      });
  };

  const getFields = (organizationId: string, clientId: string, farmId: string) => {
    setRequests(r => ({...r, [farmId]: RequestStatus.Loading}));

    JohnDeereApi.getFields(organizationId, farmId)
      .then(({data}) => {
        const sortedFields = sortByStringKey(data.data || noEntities, 'name');

        setFields(state => ({...state, [farmId]: sortedFields}));
        setRequests(r => ({...r, [farmId]: RequestStatus.Success}));
        if (selectedFarms[clientId]?.includes(farmId)) {
          // If the expanded farm was selected, select all the including fields automatically.
          setSelectedFields(state => ({
            ...state,
            [farmId]: sortedFields.map((field: JohnDeereEntity) => field.id),
          }));
        }
      })
      .catch(e => {
        setFields(state => ({...state, [farmId]: noEntities}));
        setRequests(r => ({...r, [farmId]: RequestStatus.Error}));
        reportError(`John Deere error during fetching fields for farm ${farmId}: ${e}`);
      });
  };

  const onFilterStringChange = (value: string) => {
    setFilterString(value);

    if (filterBy === 'organizations') {
      setPagination({start: 0, perPage: 20, page: 1}); // reset pagination
    }
  };

  const sync = () => {
    // Don't send the names, this list can be enormous.
    Mixpanel.importSync(ExternalService.JohnDeere, []);
    const requestPayload: JohnDeereRequestPayload = {};

    /**
     * The next lines are just to ensure we are not sending lover level entities if they are all selected
     * for example - if all fields per farm are selected, then send just farm. if all the farms are selected, send only client, etc.
     */

    organizations.forEach(({id: orgId}) => {
      if (selectedOrganizations.includes(orgId)) {
        // if the org is selected
        if (!requestPayload.orgs) requestPayload.orgs = []; // create orgs prop
        requestPayload.orgs.push(orgId);
      } else {
        // if the org is not selected, check if there are some selected clients
        clients[orgId]?.forEach(({id: clientId}) => {
          if (selectedClients[orgId]?.includes(clientId)) {
            // if the client is selected
            if (!requestPayload.clients) requestPayload.clients = []; // create clients prop
            requestPayload.clients.push({client_id: clientId, org_id: orgId});
          } else {
            // check for selected farms per client
            farms[clientId]?.forEach(({id: farmId}) => {
              // if the farm is selected
              if (selectedFarms[clientId]?.includes(farmId)) {
                if (!requestPayload.farms) requestPayload.farms = []; // create farms prop
                requestPayload.farms.push({farm_id: farmId, org_id: orgId});
              } else {
                // check for selected fields
                fields[farmId]?.forEach(field => {
                  if (selectedFields[farmId]?.includes(field.id)) {
                    if (!requestPayload.fields) requestPayload.fields = []; // create fields prop
                    requestPayload.fields.push({field_id: field.id, org_id: orgId});
                  }
                });
              }
            });
          }
        });
      }
    });

    const hasMRVIds = !!phase?.id && !!projectId;
    if (hasMRVIds) {
      dispatch(
        syncJohnDeereOrganizationsForMRV({payload: requestPayload, phaseId: phase.id, projectId})
      );
    } else {
      dispatch(syncJohnDeereOrganizations({payload: requestPayload}));
    }
  };

  const expandOrganization = (orgId: string) => {
    const newValue = !expandedOrganizations[orgId];
    const organization = organizations.find(org => org.id === orgId);
    setExpandedOrganizations(x => ({
      ...x,
      [orgId]: newValue,
    }));
    if (newValue && !clients[orgId] && !organization?.connections) {
      getClients(orgId);
    }
  };

  const expandClient = (orgId: string, clientId: string) => {
    const newValue = !expandedClients[clientId];
    setExpandedClients(x => ({
      ...x,
      [clientId]: newValue,
    }));
    if (newValue && !farms[clientId]) {
      getFarms(orgId, clientId);
    }
  };

  const expandFarm = (orgId: string, clientId: string, farmId: string) => {
    const newValue = !expandedFarms[farmId];
    setExpandedFarms(x => ({
      ...x,
      [farmId]: newValue,
    }));
    if (newValue && !fields[farmId]) {
      getFields(orgId, clientId, farmId);
    }
  };

  const toggleField = (
    orgId: string,
    clientId: string,
    farmId: string,
    fieldId: string,
    value: boolean
  ) => {
    const oldFields = selectedFields[farmId] || [];
    const newFields = value ? [...oldFields, fieldId] : oldFields.filter(x => x !== fieldId);

    setSelectedFields(state => ({
      ...state,
      [farmId]: newFields,
    }));

    // select/unselect parent farm
    const isParentFarmShouldBeSelected = fields[farmId].length === newFields.length;

    const currentClientFarms = selectedFarms[clientId] || [];
    const newFarms = isParentFarmShouldBeSelected
      ? {...selectedFarms, [clientId]: [...currentClientFarms, farmId]}
      : {...selectedFarms, [clientId]: currentClientFarms.filter(x => x !== farmId)};
    setSelectedFarms(newFarms);

    // select/unselect parent client
    const isParentClientShouldBeSelected = farms[clientId].length === newFarms[clientId].length;

    const currentOrgClients = selectedClients[orgId] || [];
    const newSelectedClients = isParentClientShouldBeSelected
      ? {...selectedClients, [orgId]: [...currentOrgClients, clientId]}
      : {...selectedClients, [orgId]: currentOrgClients.filter(x => x !== clientId)};
    setSelectedClients(newSelectedClients);

    // select/unselect parent org
    const isParentOrgShouldBeSelected = clients[orgId].length === newSelectedClients[orgId].length;
    setSelectedOrganizations(selected =>
      isParentOrgShouldBeSelected ? [...selected, orgId] : selected.filter(x => x !== orgId)
    );
  };

  const toggleFarm = (orgId: string, clientId: string, farmId: string, value: boolean) => {
    const oldFarms = selectedFarms[clientId] || [];
    const newFarms = value ? [...oldFarms, farmId] : oldFarms.filter(x => x !== farmId);

    setSelectedFarms(selected => ({
      ...selected,
      [clientId]: newFarms,
    }));

    // select/unselect client
    const isParentClientShouldBeSelected = farms[clientId].length === newFarms.length;

    const oldClients = selectedClients[orgId] || [];
    const newClients = isParentClientShouldBeSelected
      ? {...selectedClients, [orgId]: [...oldClients, clientId]}
      : {...selectedClients, [orgId]: oldClients.filter(x => x !== clientId)};
    setSelectedClients(newClients);

    // select/unselect org
    const isParentOrgShouldBeSelected = clients[orgId].length === newClients[orgId].length;
    setSelectedOrganizations(selected =>
      isParentOrgShouldBeSelected ? [...selected, orgId] : selected.filter(x => x !== orgId)
    );

    // update fields
    if (fields[farmId]) {
      setSelectedFields({...selectedFields, [farmId]: value ? fields[farmId].map(f => f.id) : []});
    }
  };

  const toggleClient = (orgId: string, clientId: string, value: boolean) => {
    const oldClients = selectedClients[orgId] || [];
    const newClients = value ? [...oldClients, clientId] : oldClients.filter(x => x !== clientId);
    setSelectedClients(selected => ({
      ...selected,
      [orgId]: newClients,
    }));
    const isParentEntitySelectedNow = clients[orgId].length === newClients.length;
    const farmsForTheClient = farms[clientId];

    setSelectedOrganizations(selected =>
      isParentEntitySelectedNow ? [...selected, orgId] : selected.filter(id => id !== orgId)
    );

    if (farmsForTheClient?.length) {
      // update farms
      setSelectedFarms(selected => ({
        ...selected,
        [clientId]: value ? farmsForTheClient.map(f => f.id) : [],
      }));

      // update fields
      const newFields: {[farmId: string]: string[]} = {};
      farmsForTheClient.forEach(farm => {
        newFields[farm.id] = value && fields[farm.id] ? fields[farm.id].map(f => f.id) : [];
      });
      setSelectedFields({...selectedFields, ...newFields});
    }
  };

  const toggleOrganization = (orgId: string, value: boolean) => {
    setSelectedOrganizations(state => {
      return value ? [...state, orgId] : state.filter(x => x !== orgId);
    });

    // If we have clients loaded for the org, update them.
    if (clients[orgId]) {
      setSelectedClients(selected =>
        value
          ? {...selected, [orgId]: clients[orgId].map(client => client.id)}
          : {...selected, [orgId]: []}
      );

      const newFarms: {[clientId: string]: string[]} = {};
      clients[orgId]?.forEach(client => {
        newFarms[client.id] =
          value && farms[client.id] ? farms[client.id].map(farm => farm.id) : [];
      });
      setSelectedFarms({...selectedFarms, ...newFarms});

      const newFields: {[farmId: string]: string[]} = {};
      clients[orgId]?.forEach(client => {
        farms[client.id]?.forEach(farm => {
          newFields[farm.id] = value && fields[farm.id] ? fields[farm.id].map(f => f.id) : [];
        });
      });
      setSelectedFields({...selectedFields, ...newFields});
    }
  };

  const toggleAllOrganizations = (value: boolean) => {
    // TODO stas: update all the children (clients and farms and fields).
    setSelectedOrganizations(value ? organizations.map(g => g.id) : []);

    const newClients: {[orgId: string]: string[]} = {};
    Object.entries(clients).forEach(([org, entities]) => {
      newClients[org] = value ? entities.map(client => client.id) : [];
    });
    setSelectedClients(newClients);

    const newFarms: {[clientId: string]: string[]} = {};
    Object.entries(farms).forEach(([clientId, entities]) => {
      newFarms[clientId] = value ? entities.map(farm => farm.id) : [];
    });
    setSelectedFarms(newFarms);

    const newFields: {[farmId: string]: string[]} = {};
    Object.entries(fields).forEach(([farmId, entities]) => {
      newFields[farmId] = value ? entities.map(field => field.id) : [];
    });
    setSelectedFields(newFields);
  };

  const filteredOrganizations = useMemo(() => {
    if (filterBy !== 'organizations') return organizations;

    const regExp = new RegExp(filterString, 'i');
    return organizations.filter(org => org.name.match(regExp));
  }, [filterBy, organizations, filterString]);

  const slicedList = useMemo(() => {
    return filteredOrganizations.slice(pagination.start, pagination.start + pagination.perPage);
  }, [pagination, filteredOrganizations]);

  const hasSomeSelectedEntity = useMemo(() => {
    return [
      ...selectedOrganizations,
      ...Object.values(selectedClients),
      ...Object.values(selectedFarms),
      ...Object.values(selectedFields),
    ].length;
  }, [selectedOrganizations, selectedClients, selectedFarms, selectedFields]);

  const searchEntities = useMemo(() => {
    const entities = [];

    if (organizations.length)
      entities.push({label: t({id: 'Organizations'}), value: 'organizations'});

    if (Object.values(clients).some(clientsByOrg => clientsByOrg.length))
      entities.push({label: t({id: 'Clients'}), value: 'clients'});

    if (Object.values(farms).some(farmsByClient => farmsByClient.length))
      entities.push({label: t({id: 'Farms'}), value: 'farms'});

    if (Object.values(fields).some(fieldsByFarm => fieldsByFarm.length))
      entities.push({label: t({id: 'Fields'}), value: 'fields'});

    return entities;
  }, [organizations, clients, farms, fields]);

  const onLogout = async () => {
    await JohnDeereApi.logOut();
    dispatch(setPlatformAuthStatus(ExternalService.JohnDeere, false));
  };

  const areOrganizationsLoading = requests.organizations === RequestStatus.Loading;
  const isSyncInProgress = platform?.syncStatus === RequestStatus.Loading;
  const isReady = authorized && !!organizations.length;

  const reauthComponent = useMemo(() => {
    return (
      <Flex alignItems="center">
        <div>
          <FluroButton blank className={'reauthorize-btn'} raised onClick={onAuthorize}>
            {t({id: 'Re-authorize'})}
          </FluroButton>

          <MRVFeature>
            <FluroButton blank className={'reauthorize-btn'} raised onClick={onLogout}>
              {t({id: 'Log Out'})}
            </FluroButton>
          </MRVFeature>
        </div>
      </Flex>
    );
  }, []);

  return (
    <div className={cn('integration-platform john-deere', {selected: expanded})}>
      <IntegrationCard
        authorized={authorized}
        expanded={expanded}
        onAuthorize={onAuthorize}
        reauthComponent={reauthComponent}
        platform={ExternalService.JohnDeere}
        loading={requests['organizations'] && !organizations.length}
      />

      {expanded && (
        <>
          {isReady && (
            <>
              <SelectFarmsAndFieldsToImportRow />
              {searchEntities.length !== 0 && (
                <div className="filter-by">
                  <SelectField
                    id="john-deere-select-filter-by"
                    label={t({id: 'Filter by'})}
                    menuItems={searchEntities}
                    className="filter-by--selector"
                    value={filterBy}
                    onChange={value => setFilterBy(value as JDEntityType)}
                    simplifiedMenu={true}
                  />

                  <TextField
                    id="filter-by-input"
                    label={searchEntities.find(({value}) => value === filterBy)?.label}
                    className="filter-by--text-input"
                    value={filterString}
                    onChange={value => onFilterStringChange(`${value}`)}
                  />
                </div>
              )}
              <div className={'entities-to-import-container'}>
                {organizations.length > 1 && (
                  <>
                    <SelectionControl
                      id={'select-all-orgs'}
                      name={'select-all-orgs'}
                      label={t(
                        {id: 'Select all organizations ({count1}/{count2})'},
                        {count1: selectedOrganizations?.length || 0, count2: organizations.length}
                      )}
                      type={'checkbox'}
                      className={'select-all-checkbox'}
                      checked={organizations.length === selectedOrganizations.length}
                      onChange={v => toggleAllOrganizations(!!v)}
                    />
                  </>
                )}
                {slicedList.map(org => {
                  const isOrgSelected = selectedOrganizations.includes(org.id);
                  const orgId = org.id;
                  const filteredClients = filterSubEntity(
                    clients[orgId],
                    'clients',
                    filterBy,
                    filterString
                  );

                  return (
                    <React.Fragment key={orgId}>
                      <ExpandableRowContainer
                        className={cn({
                          selected: isOrgSelected,
                        })}
                      >
                        <SelectionControl
                          id={orgId}
                          name={'grower selection'}
                          label={
                            org.name +
                            selectedEntitiesNumber(clients[orgId], selectedClients[orgId])
                          }
                          className={'highest-platform-entity'}
                          type={'checkbox'}
                          checked={isOrgSelected}
                          onChange={v => toggleOrganization(orgId, !!v)}
                        />
                        <ExpandableArea
                          onClick={() => expandOrganization(orgId)}
                          expanded={expandedOrganizations[orgId]}
                        />
                      </ExpandableRowContainer>
                      {expandedOrganizations[orgId] && (
                        <SubContainer>
                          {requests[orgId] === RequestStatus.Loading && (
                            <span className="preloader">
                              <CircularProgress centered={false} id={orgId} />
                            </span>
                          )}
                          {org.connections ? (
                            <div className="request-to-connect">
                              <FormattedMessage
                                id="Please <a>allow Regrow</a> to access this organization first."
                                values={{
                                  a: (txt: string) => (
                                    <Ln href={org.connections} blank external>
                                      {txt}
                                    </Ln>
                                  ),
                                }}
                              />
                            </div>
                          ) : (
                            filteredClients?.map(client => {
                              if (clients[orgId]?.length === 1 && client.name === 'no-entities') {
                                return (
                                  <NoItemsTitle key={client.id}>
                                    {t({id: 'No clients found'})}
                                  </NoItemsTitle>
                                );
                              }
                              const clientId = client.id;
                              const isClientSelected = (selectedClients[orgId] || []).includes(
                                clientId
                              );
                              const filteredFarms = filterSubEntity(
                                farms[clientId],
                                'farms',
                                filterBy,
                                filterString
                              );

                              return (
                                <React.Fragment key={clientId}>
                                  <SubItem
                                    className={cn({
                                      selected: isClientSelected,
                                    })}
                                  >
                                    <SelectionControl
                                      id={clientId}
                                      name={'client selection'}
                                      label={
                                        client.name +
                                        selectedEntitiesNumber(
                                          clients[clientId],
                                          selectedClients[clientId]
                                        )
                                      }
                                      className={'medium-platform-entity'}
                                      type={'checkbox'}
                                      checked={isClientSelected}
                                      onChange={v => toggleClient(orgId, clientId, !!v)}
                                    />
                                    <ExpandableArea
                                      onClick={() => expandClient(orgId, clientId)}
                                      expanded={expandedClients[clientId]}
                                    />
                                  </SubItem>
                                  {expandedClients[clientId] && (
                                    <SubContainer>
                                      {requests[clientId] === RequestStatus.Loading && (
                                        <span className="preloader">
                                          <CircularProgress centered={false} id={clientId} />
                                        </span>
                                      )}
                                      {filteredFarms?.map(farm => {
                                        if (
                                          farms[clientId].length === 1 &&
                                          farm.name === 'no-entities'
                                        ) {
                                          return (
                                            <NoItemsTitle key={farm.name}>
                                              {t({id: 'No farms found'})}
                                            </NoItemsTitle>
                                          );
                                        }
                                        const farmId = farm.id;
                                        const filteredFields = filterSubEntity(
                                          fields[farmId],
                                          'fields',
                                          filterBy,
                                          filterString
                                        );

                                        return (
                                          <React.Fragment key={farmId}>
                                            <SubItem>
                                              <SelectionControl
                                                id={farmId}
                                                name={'farm selection'}
                                                className={'small-platform-entity'}
                                                label={
                                                  farm.name +
                                                  selectedEntitiesNumber(
                                                    farms[farmId],
                                                    selectedFarms[farmId]
                                                  )
                                                }
                                                type={'checkbox'}
                                                checked={(selectedFarms[clientId] || []).includes(
                                                  farmId
                                                )}
                                                onChange={v =>
                                                  toggleFarm(orgId, clientId, farmId, !!v)
                                                }
                                              />
                                              <ExpandableArea
                                                onClick={() => expandFarm(orgId, clientId, farmId)}
                                                expanded={expandedFarms[farmId]}
                                              />
                                            </SubItem>
                                            {expandedFarms[farmId] && (
                                              <SubContainer>
                                                {requests[farmId] === RequestStatus.Loading && (
                                                  <span className="preloader">
                                                    <CircularProgress
                                                      centered={false}
                                                      id={farmId}
                                                    />
                                                  </span>
                                                )}
                                                {filteredFields?.map(field => {
                                                  if (
                                                    fields[farmId].length === 1 &&
                                                    field.name === 'no-entities'
                                                  ) {
                                                    return (
                                                      <NoItemsTitle key={field.name}>
                                                        {t({id: 'No fields found'})}
                                                      </NoItemsTitle>
                                                    );
                                                  }
                                                  const fieldId = field.id;
                                                  const isFieldSelected = (
                                                    selectedFields[farmId] || []
                                                  ).includes(fieldId);

                                                  return (
                                                    <SubItem key={fieldId}>
                                                      <SelectionControl
                                                        id={fieldId}
                                                        name={'field selection'}
                                                        label={field.name}
                                                        type={'checkbox'}
                                                        className={'smallest-platform-entity'}
                                                        checked={isFieldSelected}
                                                        onChange={v =>
                                                          toggleField(
                                                            orgId,
                                                            clientId,
                                                            farmId,
                                                            fieldId,
                                                            !!v
                                                          )
                                                        }
                                                      />
                                                    </SubItem>
                                                  );
                                                })}
                                              </SubContainer>
                                            )}
                                          </React.Fragment>
                                        );
                                      })}
                                    </SubContainer>
                                  )}
                                </React.Fragment>
                              );
                            })
                          )}
                        </SubContainer>
                      )}
                    </React.Fragment>
                  );
                })}
              </div>

              <div className="pagination">
                {filteredOrganizations.length >= 20 && (
                  <FluroDataTable elevated={false} baseId={'growers-list'}>
                    <FluroTablePagination
                      page={pagination.page}
                      rows={filteredOrganizations.length}
                      rowsPerPage={pagination.perPage}
                      rowsPerPageLabel={t({id: 'Growers'})}
                      onPagination={(start: number, perPage: number, page: number) =>
                        setPagination({start, perPage, page})
                      }
                    />
                  </FluroDataTable>
                )}
              </div>
            </>
          )}

          <Sticky className="action-buttons-holder">
            <SelectedPlatformBackButton />

            {isReady && (
              <Flex alignItems="center">
                {isSyncInProgress && (
                  <CircularProgress
                    id="integration-john-deere-progress"
                    className="margin-0 margin-right-5"
                  />
                )}

                <Button
                  disabled={!hasSomeSelectedEntity || isSyncInProgress}
                  raised
                  primary
                  onClick={sync}
                >
                  {t({id: 'Sync'})}
                </Button>
              </Flex>
            )}
          </Sticky>

          {!authorized && areOrganizationsLoading && (
            <div>{t({id: 'Loading organizations list...'})}</div>
          )}
        </>
      )}
    </div>
  );
};

const filterSubEntity = (
  entitiesToFilter: JohnDeereEntity[] = [],
  entityType: JDEntityType,
  filterByEntity: JDEntityType,
  filterValue: string
): JohnDeereEntity[] => {
  const regExp = new RegExp(filterValue, 'i');

  if (entityType !== filterByEntity) {
    return entitiesToFilter; // return the initial array if the filter is not by it
  }

  return entitiesToFilter.filter(entity => entity.name.match(regExp));
};

const selectedEntitiesNumber = (list: JohnDeereEntity[], selected?: string[]) => {
  if (!list) {
    return '';
  }
  return ` (${selected?.length || 0}/${list.length})`;
};

export default IntegrationJohnDeere;
