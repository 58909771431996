import type {ComponentType} from 'react';
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {Flex, FluroButton, FluroDialog, InfoBlock, Sticky, Text} from 'components';
import {useMonitoringUrlParams} from '../hooks';
import {useAppDispatch, useAppSelector} from '_hooks';
import {
  selectEnrolledFieldsFromFMS,
  selectFMSOfEnrolledFieldsFromFMS,
  selectImportedValues,
  selectPhase,
  selectProjectId,
} from '../module/selectors';
import './fms-sync-dialog.scss';
import {AsyncStatusType, dialogToggle, DialogType, Status} from '../../../../modules/helpers';
import {calcPlural} from '_utils/pure-utils';
import {selectDialogData, selectDialogVisibility} from '../../../../modules/helpers/selectors';
import {
  ExpandableArea,
  SubContainer,
  SubItem,
} from 'components/expandable-table-items/expandable-table-items';
import type {MRVField} from '../../types';
import {MRVPhaseType} from '../../types';
import type {JohnDeereRequestPayload} from '../../../profile/integration/integration-john-deere';
import {ExternalService} from '../../../map/types';
import {
  getAuthorizedPlatforms,
  logOutFromPlatform,
  syncClimateFields,
  syncJohnDeereOrganizationsForMRV,
} from '../../../profile/integration/actions';
import {ExternalServiceLabels} from 'containers/profile/integration/integration-utils';
import {mixpanelCarbon} from '_utils/mixpanel-utils';
import {selectAsyncRequestStatus} from '../../../../modules/global/selectors';
import {selectIntegrationPlatforms} from 'containers/profile/integration/integration-selectors';
import {ClimateApi, JohnDeereApi} from '_api';
import type {IntegrationPlatform} from 'containers/profile/integration/types';
import {t} from 'i18n-utils';
import {FluroCheckbox} from 'components/fluro-checkbox/fluro-checkbox';
import {useQueryParams} from '_hooks/use-parsed-match-params';

export const FMSSyncDialog: ComponentType = () => {
  const dispatch = useAppDispatch();
  const {projectId} = useMonitoringUrlParams();
  const visible = useAppSelector(s => selectDialogVisibility(s, DialogType.fmsSyncDialog));
  const {payload} = useAppSelector(s => selectDialogData(s, DialogType.fmsSyncDialog));
  const phaseId = payload?.phaseId as number | undefined;

  const [selectedFields, setSelectedFields] = useState<string[]>([]);
  const [expandedFarms, setExpandedFarms] = useState<{[farmId: string]: boolean}>({});

  const importedFields = useAppSelector(s => selectEnrolledFieldsFromFMS(s, projectId));
  const externalPlatform = useAppSelector(s => selectFMSOfEnrolledFieldsFromFMS(s, projectId));

  useAuthPlatforms();

  const onHide = useCallback(() => {
    dispatch(dialogToggle(DialogType.fmsSyncDialog, false));
  }, [dispatch]);

  const externalServiceLabel = ExternalServiceLabels[externalPlatform];

  const {farmIds, farms, flatFieldsObjects} = useMemo(() => {
    const _farms: Record<number, {fields: MRVField[]; name: string}> = {};
    const _flatFieldsObjects: Record<string, MRVField> = {};
    importedFields.forEach(f => {
      _flatFieldsObjects[f.core_attributes.external_service_id] = f;

      if (!_farms[f.farm_id]) {
        _farms[f.farm_id] = {fields: [], name: f.core_attributes.farm_name};
      }

      _farms[f.farm_id].fields.push(f);
    });
    return {
      farmIds: Object.keys(_farms).map(farmId => parseInt(farmId, 10)),
      farms: _farms,
      flatFieldsObjects: _flatFieldsObjects,
    };
  }, [importedFields]);

  const toggleAllFields = (value: boolean) => {
    if (value) {
      setSelectedFields(importedFields.map(f => f?.core_attributes?.external_service_id));
    } else {
      setSelectedFields([]);
    }
  };

  const toggleFarm = (farmFields: MRVField[], value: boolean) => {
    if (value) {
      setSelectedFields([
        ...new Set([
          ...selectedFields,
          ...farmFields.map(f => f.core_attributes.external_service_id),
        ]),
      ]);
    } else {
      setSelectedFields(
        selectedFields.filter(
          fieldId => !farmFields.find(f => f.core_attributes.external_service_id === fieldId)
        )
      );
    }
  };

  const expandFarm = (farmId: number) => {
    setExpandedFarms({...expandedFarms, [farmId]: !expandedFarms[farmId]});
  };

  const toggleField = (fieldId: string, value: boolean) => {
    if (value) {
      setSelectedFields([...new Set([...selectedFields, fieldId])]);
    } else {
      setSelectedFields(selectedFields.filter(id => fieldId !== id));
    }
  };

  const onSync = () => {
    const climatePayload: string[] = [];
    const johnDeerePayload: JohnDeereRequestPayload = {fields: []};

    selectedFields.forEach(externalId => {
      const field = flatFieldsObjects[externalId];
      if (!field) alert(`no field ${externalId}`);
      if (field.core_attributes.external_service === ExternalService.JohnDeereLong) {
        const externalField = {
          field_id: externalId,
          org_id: field.core_attributes.external_grower_id,
        };
        if (johnDeerePayload.fields) {
          johnDeerePayload.fields.push(externalField);
        } else {
          johnDeerePayload.fields = [externalField];
        }
      } else if (field.core_attributes.external_service === ExternalService.Climate) {
        climatePayload.push(externalId);
      }
    });

    if (climatePayload.length) {
      dispatch(syncClimateFields({fieldIds: climatePayload, phaseId}));
    }

    if (johnDeerePayload.fields?.length && phaseId && projectId) {
      dispatch(syncJohnDeereOrganizationsForMRV({payload: johnDeerePayload, phaseId, projectId}));
    }

    mixpanelCarbon.track('FMS_sync_operations');

    onHide();
  };

  const onDisconnectFromFMS = async () => {
    if (externalPlatform === ExternalService.JohnDeere) {
      await JohnDeereApi.logOut();
    } else if (externalPlatform === ExternalService.Climate) {
      await ClimateApi.logOut();
    }
    onHide();
    dispatch(dialogToggle(DialogType.fmsSyncLoggedOutDialog, true));
    dispatch(logOutFromPlatform(externalPlatform as IntegrationPlatform));
  };

  const selectedFieldsLabel = `${selectedFields.length} ${calcPlural(
    'field',
    selectedFields
  )} selected`;

  return (
    <FluroDialog
      onHide={onHide}
      dialogClassName={'fms-sync-dialog'}
      title={t({id: 'Sync operations from your farm management system'})}
      visible={visible}
      id={'fms-sync-dialog'}
      portal
    >
      {t(
        {
          id: 'Fms.Sync.OperationsMessage',
          defaultMessage:
            'Your previously imported fields from {externalServiceLabel}. Sync your fields to retrieve crop types, planting and harvest dates, yield.',
        },
        {externalServiceLabel}
      )}
      <div className="table-container">
        {farmIds.length > 1 && (
          <FluroCheckbox
            onChange={(v: boolean) => toggleAllFields(v)}
            value={importedFields.length === selectedFields.length}
            label={t({id: 'SelectLabel.Mrv.Select all', defaultMessage: 'Select all'})}
            className="pl-1"
          />
        )}
        {farmIds.map(farmId => {
          const farmFields = farms[farmId].fields;
          const farmName = farms[farmId].name;
          const selectedFarmFields = farmFields.filter(f =>
            selectedFields.includes(f.core_attributes.external_service_id)
          );
          const farmSelected = farmFields.length === selectedFarmFields.length;

          return (
            <React.Fragment key={farmId}>
              <SubItem>
                <FluroCheckbox
                  onChange={(v: boolean) => toggleFarm(farmFields, v)}
                  value={farmSelected}
                  label={farmName + selectedEntitiesNumber(farmFields, selectedFarmFields)}
                  className="pl-1"
                />
                <ExpandableArea
                  onClick={() => expandFarm(farmId)}
                  expanded={expandedFarms[farmId]}
                />
              </SubItem>
              {expandedFarms[farmId] && (
                <SubContainer>
                  {farmFields?.map(field => {
                    const fieldId = field.core_attributes.external_service_id;
                    const isFieldSelected = selectedFields.includes(fieldId);

                    return (
                      <SubItem key={fieldId}>
                        <FluroCheckbox
                          onChange={(v: boolean) => toggleField(fieldId, v)}
                          value={isFieldSelected}
                          label={field.core_attributes.field_name}
                          className="pl-1"
                        />
                      </SubItem>
                    );
                  })}
                </SubContainer>
              )}
            </React.Fragment>
          );
        })}
      </div>
      <div className="disconnect-fms-container">
        <Flex fullWidth alignItems={'center'} justifyContent={'space-between'}>
          {t({
            id: 'Fms.Sync.WantLogOutMessage',
            defaultMessage: 'Want to log out of your Farm Management System?',
          })}
          <FluroButton grayBorder onClick={onDisconnectFromFMS} blank raised>
            {t(
              {id: 'BtnLabel.LogoutOf', defaultMessage: 'Logout of {externalServiceLabel}'},
              {externalServiceLabel}
            )}
          </FluroButton>
        </Flex>

        <InfoBlock className={'margin-top-5'} color="warning" appearance={'warning'} mini>
          <Text variant={'small'}>
            {t({
              id: 'Fms.Sync.LogoutConditionMessage',
              defaultMessage:
                'If you logout, you will have to enter all your practice details manually.',
            })}
          </Text>
        </InfoBlock>
      </div>
      <Sticky alwaysElevated className={'action-buttons'}>
        <FluroButton blank raised onClick={onHide}>
          {t({id: 'BtnLabel.Back', defaultMessage: 'Back'})}
        </FluroButton>

        <Flex gap={'10px'} alignItems={'center'}>
          {selectedFieldsLabel}

          <FluroButton disabled={!selectedFields.length} primary raised onClick={onSync}>
            {t({id: 'BtnLabel.SyncOperations', defaultMessage: 'Sync operations'})}
          </FluroButton>
        </Flex>
      </Sticky>
    </FluroDialog>
  );
};

const selectedEntitiesNumber = (list: MRVField[], selected: MRVField[]) => {
  if (!list) {
    return '';
  }
  return ` (${selected?.length || 0}/${list.length})`;
};

const useAuthPlatforms = () => {
  const dispatch = useAppDispatch();
  const projectId = useAppSelector(selectProjectId);
  const importedValues = useAppSelector(selectImportedValues);
  const enrolledFieldsFromFMS = useAppSelector(s => selectEnrolledFieldsFromFMS(s, projectId));
  const externalPlatform = useAppSelector(s => selectFMSOfEnrolledFieldsFromFMS(s, projectId));
  const integrationPlatforms = useAppSelector(selectIntegrationPlatforms);
  const phase = useAppSelector(s => selectPhase(s, MRVPhaseType.Enrollment));
  const payload = useAppSelector(s => selectDialogData(s, DialogType.fmsSyncDialog));
  const phaseId = payload.phaseId || phase?.id; // By default use enrollment phase id.

  const authorizedPlatformsLoadingStatus = useAppSelector(s =>
    selectAsyncRequestStatus(s, AsyncStatusType.authorizedPlatforms)
  );

  const [shouldOpen, setShouldOpen] = useState(true);
  const queryParams = useQueryParams();
  const usingTableView = queryParams.get('tableView') === 'true';

  useEffect(() => {
    if (authorizedPlatformsLoadingStatus === Status.Todo) {
      dispatch(getAuthorizedPlatforms());
    }
  }, []);

  /**
   * Auto open sync operations dialog if a user is on the tableView, has enrolled fields from FMS, and has not imported any values yet.
   */
  useEffect(() => {
    if (
      shouldOpen &&
      phaseId &&
      usingTableView &&
      authorizedPlatformsLoadingStatus === Status.Done &&
      integrationPlatforms[externalPlatform]?.authorized &&
      enrolledFieldsFromFMS?.length &&
      !importedValues.length
    ) {
      dispatch(dialogToggle(DialogType.fmsSyncDialog, true, {phaseId}));
      setShouldOpen(false); // open only once
    }
  }, [
    importedValues,
    enrolledFieldsFromFMS,
    authorizedPlatformsLoadingStatus,
    externalPlatform,
    integrationPlatforms,
    shouldOpen,
    phaseId,
    dispatch,
    usingTableView,
  ]);
};
