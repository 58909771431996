import {
  // FlexGridItem,
  // FlexGridRow,
  SIPageTitle,
  SISectionContainer,
  SIStyledStickyHeader,
} from 'containers/si/si-styled-components';
import React from 'react';

export const ComparisonDashboard = () => (
  <SISectionContainer>
    <SIPageTitle>Supply Shed Comparison</SIPageTitle>
    {/* TODO: padding in this SIStyledStickyHeader is adjusting for Plan pages; 
    fix those and remove extra padding from styled component */}
    <SIStyledStickyHeader position="top" style={{padding: '0'}}>
      {/* TODO: this is where filters go */}
      No comparisons available
      {/* <FlexGridRow style={{height: 100, background: 'white'}}>
        <FlexGridItem>1</FlexGridItem>
        <FlexGridItem>1</FlexGridItem>
        <FlexGridItem>1</FlexGridItem>
        <FlexGridItem>1</FlexGridItem>
      </FlexGridRow> */}
    </SIStyledStickyHeader>
    {/* <div style={{height: 900}}></div> */}
  </SISectionContainer>
);
