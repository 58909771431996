import {t} from 'i18n-utils';
import React, {useMemo} from 'react';
import {useDispatch} from 'react-redux';
import {toggleMapBar, toggleDrawingMode} from '../../../../actions';
import {FluroButton, InfoBlock} from 'components';
import {FontIcon} from 'react-md';
import {PolygonIcon, CircleIcon} from '../../../../icons';
import {aNewFieldSizeLimitHa} from '_constants';
import cn from 'classnames';
import {useAppSelector} from '_hooks';
import {useIntercom} from 'react-use-intercom';
import {selectDrawnFieldGeometries} from 'modules/add-fields';

const DrawFieldsStep = ({onHide}: {onHide?: () => void}) => {
  const dispatch = useDispatch();
  const drawnFieldsGeometries = useAppSelector(selectDrawnFieldGeometries);
  const {startTour, trackEvent} = useIntercom();

  const toShowLimitSizeWarning = useMemo(
    () =>
      drawnFieldsGeometries.filter((f: any) => f.properties.area * 0.0001 > aNewFieldSizeLimitHa),
    [drawnFieldsGeometries]
  );

  const DrawingButtons = useMemo(
    () => (
      <div className={'draw-buttons-container'}>
        {onHide && (
          <FluroButton className={'btn-with-icon'} raised onClick={onHide}>
            {t({id: 'BtnLabel.Back'})}
          </FluroButton>
        )}

        <FluroButton
          id={'draw-a-new-polygon-field'}
          className={'btn-with-icon'}
          raised
          onClick={() => startDraw('polygon')}
          iconEl={<PolygonIcon />}
        >
          {t({id: 'polygon'})}
        </FluroButton>

        <FluroButton
          id={'draw-a-new-circle-field'}
          className={'btn-with-icon'}
          raised
          onClick={() => startDraw('circle')}
          iconEl={<CircleIcon />}
        >
          {t({id: 'circle'})}
        </FluroButton>
      </div>
    ),
    []
  );

  const startDraw = (shape = 'polygon') => {
    dispatch(toggleMapBar(false));
    dispatch(toggleDrawingMode(true, shape, 'farm'));
  };

  const showDrawingTutorial = () => {
    trackEvent('draw-fields-tutorial');
    startTour(213433);
  };

  return (
    <>
      <div className={cn('draw-fields-container')}>
        <div className="how-to-draw-container">
          {t({id: 'how-to-draw-field-instruction'})}
          <FontIcon onClick={showDrawingTutorial} className={'how-to-draw-your-fields-info'}>
            help_outline
          </FontIcon>
        </div>

        <div className={'draw-field-info'}>{DrawingButtons}</div>
      </div>

      {toShowLimitSizeWarning.length !== 0 && (
        <InfoBlock className={'size-warning-info inside-a-pop-up'}>
          {t(
            {
              id: 'tooBigDrawArea',
              defaultMessage:
                'The size of the {count, plural, one {field} other {fields}} you just drew is higher than expected for this area. Please make sure this boundary has no more than one field for correct analysis.',
            },
            {count: toShowLimitSizeWarning.length}
          )}
        </InfoBlock>
      )}
    </>
  );
};

export default DrawFieldsStep;
