import React, {useEffect, useState} from 'react';
import {KPIBarChart} from '../charts/kpi-bar-chart-card';
import {useAppSelector} from '_hooks';
import {kiloFormatter} from '_utils/number-formatters';
import {calculatePercentage} from '_utils/pure-utils';
import {selectSISelectedCropTypes} from 'containers/si/module/selectors';
import {selectCropTypesCDLIdMap} from 'modules/global/selectors';
import {selectMeasurement} from 'containers/login/login-selectors';
import {convertArea} from '@turf/turf';
import type {SIAreaUnits} from 'modules/sustainability-insights/types';
import {TOOLTIPS} from 'containers/si/constants';
import {SparklingChartCard} from '../charts/sparkling-chart-card';
import {t} from 'i18n-utils';
import {useFetchKPI} from 'containers/si/hooks/useFetchKPI';
import {useSparklingChart} from 'containers/si/hooks/useSparklingChart';

export const AreaByCropCard = () => {
  const cropsById = useAppSelector(selectCropTypesCDLIdMap);
  const selectedCropTypes = useAppSelector(selectSISelectedCropTypes);
  const measurement = useAppSelector(selectMeasurement);

  const [unit, setUnit] = useState<SIAreaUnits>('num');
  const {response: cropResponse, loading} = useFetchKPI({
    kpi: 'crop_area',
    summarizeBy: ['crop_type', 'annualized'],
  });
  const {sparklingChartData, dataset, metric, average, comparisonValues} =
    useSparklingChart(cropResponse);

  const [barChartData, setBarChartData] = useState<{label: string; value: number}[]>([]);

  const chartSubtitle = measurement === 'ac' ? 'in acres' : 'in hectares';
  const chartUnits = measurement === 'ac' ? 'acres' : 'hectares';

  useEffect(() => {
    if (!cropResponse?.crop_type_summary) return;

    if (selectedCropTypes.length > 1) {
      // Generate Chart Data
      const cropData = Object.entries(cropResponse.crop_type_summary).map(
        ([cropType, cropArea]) => {
          const cropName = cropsById[Number(cropType)]?.label || cropType;
          const expectedAreaUnit = measurement === 'ac' ? 'acres' : 'hectares';
          const convertedCropArea = convertArea(cropArea, 'meters', expectedAreaUnit);

          return {
            label: cropName,
            value: convertedCropArea,
          };
        }
      );

      const totalArea =
        cropData.length > 0 ? cropData.map(crop => crop.value).reduce((a, b) => a + b) : 0;

      setBarChartData(
        unit === 'pct'
          ? cropData.map(crop => ({
              ...crop,
              value: calculatePercentage(crop.value, totalArea),
            }))
          : cropData
      );
    }
  }, [cropResponse, cropsById, measurement, unit, selectedCropTypes]);

  const onUnitsChange = (v: SIAreaUnits) => {
    setUnit(v);
  };

  return selectedCropTypes.length > 1 ? (
    <KPIBarChart
      title={'Area by crop type'}
      subtitle={chartSubtitle}
      data={barChartData}
      colors={['#60b854']}
      kiloConvert
      enableUnitsSwitch
      onUnitsChange={onUnitsChange}
      tooltip={TOOLTIPS.KPI.cropArea}
      loading={loading}
    />
  ) : (
    <SparklingChartCard
      title={t({id: 'Area by crop type'})}
      subtitle={chartSubtitle}
      cardCenterNumber={metric === 0 ? 'No Data' : `${String(kiloFormatter(metric)).toUpperCase()}`}
      cardCenterUnits={''}
      comparisonValues={comparisonValues}
      sparklingChartCaption={`${sparklingChartData.datasets.length} year avg`}
      sparklingChartCaptionBolded={`${kiloFormatter(average)} ${chartUnits}`}
      chartData={{labels: sparklingChartData.labels, datasets: dataset}}
      chartDataMidline={average}
      tooltip={TOOLTIPS.KPI.cropArea}
      linechartLabelSuffix={chartUnits}
      loading={loading}
    />
  );
};
