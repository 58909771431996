import type {ComponentType} from 'react';
import React, {useMemo, useState} from 'react';
import MultiSelectChips from 'components/multi-select-chips';
import type {Farm} from '../map/types';
import {useAppSelector} from '../../_hooks';
import {selectFarmsList} from '../../modules/farms/selectors';

type Props = {
  selectedItems?: number[];
  skipReadOnly?: boolean;
  label?: string;
  onSelectCallback: (values: number[]) => void;
  error?: boolean;
};

const SelectFarmChips: ComponentType<Props> = ({
  onSelectCallback,
  skipReadOnly,
  label,
  error,
  selectedItems,
}) => {
  const farmsList = useAppSelector(selectFarmsList);
  const [farmIds, setFarmIds] = useState<number[]>([]);

  const onSelect = (values: number[]) => {
    setFarmIds([...values]);

    onSelectCallback(values);
  };

  const preparedFarmsList = useMemo(() => {
    if (skipReadOnly) {
      return farmsList.filter((f: Farm) => !f.readOnly);
    }

    return farmsList;
  }, [farmsList, skipReadOnly]);

  return preparedFarmsList.length ? (
    <MultiSelectChips
      label={label}
      id="multi-farms-selector"
      onSelectChip={onSelect}
      items={preparedFarmsList.map((g: Farm) => ({
        label: `${g.name} #${g.id}`,
        value: g.id,
        group: g.growerName,
      }))}
      selectedItems={selectedItems || farmIds}
      hasLinksToFarms
      error={error}
    />
  ) : null;
};

export default SelectFarmChips;
