import {FormattedMessage} from 'i18n-utils';
import * as React from 'react';
import {useEffect, useCallback} from 'react';
import _config from '_environment';
import {showNotification} from 'components/notification/notification';
import {reportError} from 'containers/error-boundary';
import {safeLocalStorage} from '_utils/safe-local-storage';

/**
 * Every 30 minutes fetches an app-config.js
 * to check if there is a new version released.
 */
const useAppAutoUpdater = () => {
  const checkUpdate = useCallback(async () => {
    try {
      // Prevent caching by adding timestamp get parameter.
      const resp = await fetch(`/assets/app-config.js?t=${+new Date()}`);
      const text = await resp.text();
      // The app-config.js looks like:
      // window.FLUROSENSE_CONFIG = { app_version: ..., };
      const appVersionRegexp = /app_version: '(.*)'/;
      const [, appVersion] = appVersionRegexp.exec(text);
      if (_config.app_version !== appVersion) {
        showNotification({
          title: '🚀 Update',
          message: (
            <FormattedMessage
              id="A new version of Regrow has been released"
              defaultMessage="A new version of Regrow has been released. <a>Reload the page</a> to get the best of Regrow and to make sure everything works as expected."
              values={{
                a: (txt: string) => (
                  // eslint-disable-next-line jsx-a11y/anchor-is-valid
                  <a href="" onClick={() => safeLocalStorage.setItem('reloadPage', 'true')}>
                    {txt}
                  </a>
                ),
              }}
            />
          ),
          type: 'info',
          autoClose: false,
        });
      }
    } catch (e) {
      reportError(`Could not fetch or parse new config:${e}`);
    }
  }, []);
  useEffect(() => {
    // @ts-expect-error Add check update function to the global space, so it can be easily tested.
    window.checkUpdate = checkUpdate;
    // Check for an update every half an hour.
    setInterval(checkUpdate, 60 * 1000 * 30);
    // Reload all the other tabs with flurosat when a user choses to reload one (FSB-3075).
    window.addEventListener('storage', ({key, newValue}) => {
      if (key === 'reloadPage' && newValue != null) {
        window.location.reload();
        safeLocalStorage.removeItem('reloadPage');
      }
    });
  }, []);
};

export default useAppAutoUpdater;
