import {t} from 'i18n-utils';
import React, {useState} from 'react';
import type {Moment} from 'moment';
import moment from 'moment';
import {FluroDialog, FluroDatePicker, FluroButton} from 'components';
import {GLOBAL_FORMAT_DATE} from '_constants';
import {useAppSelector} from '_hooks';
import {selectCurrentSeason} from 'containers/map/reducer/selectors';

type Props = {
  onHide(): void;
  saveNewDates(startDate: string, endDate: string): void;
};

const ChangeAreasOfInterestDates = ({onHide, saveNewDates}: Props) => {
  const currentSeason = useAppSelector(selectCurrentSeason);
  const [startDate, setStartDate] = useState(
    currentSeason?.id ? moment(currentSeason.startDate, GLOBAL_FORMAT_DATE) : moment()
  );
  const [endDate, setEndDate] = useState(
    currentSeason?.id ? moment(currentSeason.endDate, GLOBAL_FORMAT_DATE) : moment()
  );

  const onSaveDates = () => {
    saveNewDates(startDate.format(GLOBAL_FORMAT_DATE), endDate.format(GLOBAL_FORMAT_DATE));
  };

  const incorrectDates = !startDate.isBefore(endDate, 'day');

  return (
    <FluroDialog
      id={'change-geometries-dates'}
      title={t({id: 'Set geometries dates', defaultMessage: 'Set geometries dates'})}
      visible={true}
      onHide={onHide}
      className={'change-geometries-dates'}
    >
      <FluroDatePicker
        id={'select-start-date'}
        selected={startDate}
        label={t({id: 'Starting date', defaultMessage: 'Starting date'})}
        hideFormat
        error={incorrectDates}
        errorText={t({
          id: 'Minimal range between dates is 1 day',
          defaultMessage: 'Minimal range between dates is 1 day',
        })}
        onChange={(date: Moment) => setStartDate(date)}
      />
      <FluroDatePicker
        id={'select-end-date'}
        selected={endDate}
        label={t({id: 'Est. end date', defaultMessage: 'Est. end date'})}
        hideFormat
        onChange={(date: Moment) => setEndDate(date)}
      />

      <div className={'margin-top-10 md-text-right'}>
        <FluroButton
          id={'save-new-dates-btn'}
          disabled={incorrectDates}
          raised
          primary
          onClick={onSaveDates}
        >
          {t({id: 'Update dates', defaultMessage: 'Update dates'})}
        </FluroButton>
      </div>
    </FluroDialog>
  );
};

export default ChangeAreasOfInterestDates;
