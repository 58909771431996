import type {ComponentType} from 'react';
import React, {useEffect, useState} from 'react';
import {UploadIcon} from 'containers/map/icons';
import {syncProcessStartedInfoText} from 'containers/mrv/utils';
import {useAppSelector, usePrevious} from '_hooks';
import {selectSyncingOperationsStatus} from '../../module/selectors';
import {Ln} from 'components';
import {LoadingIcon} from 'containers/mrv/icons';
import {FormattedMessage, t} from 'i18n-utils';

export const FMSSyncMessageIndicator: ComponentType = () => {
  const [syncIsComplete, setSyncIsComplete] = useState(false);
  const {isSync, syncingPlatformName} = useAppSelector(selectSyncingOperationsStatus);
  const prev = usePrevious({isSync});

  useEffect(() => {
    if (prev?.isSync === true && isSync === false) {
      // sync is complete
      setSyncIsComplete(true);
    }
  }, [isSync]);

  if (!isSync && !syncIsComplete) return null;

  if (syncIsComplete && !isSync) {
    return (
      <div className={'sync-indicator-message'}>
        <UploadIcon className={'upload-icon'} size={14} />
        <span className={'title'}>
          {t({id: 'Fms.Sync.Import is complete', defaultMessage: 'Import is complete.'})}
        </span>
        <FormattedMessage
          id="Fms.Sync.ForMoreInfoMessage"
          defaultMessage="For more information on how your data was imported, please refer to our <a>user guide</a>."
          values={{
            a: (msg: string) => (
              <Ln blank external href={'https://hubs.ly/Q012LMkJ0'}>
                {msg}
              </Ln>
            ),
          }}
        />
      </div>
    );
  }

  return (
    <div className={'sync-indicator-message'}>
      <LoadingIcon className={'upload-icon'} size={14} />
      <span className={'title'}>
        {t({id: 'Fms.Sync.Import in progress', defaultMessage: 'Import in progress.'})}
      </span>
      {syncProcessStartedInfoText(syncingPlatformName)}
    </div>
  );
};
