// @ts-nocheck
import type {InitialState, ActionPayload} from './types';
import {ActionType} from './types';
import {getNewUserModel} from './actions';

const initialState: InitialState = {
  list: [],
  organizations: [],
  subscriptions: [],
  user: getNewUserModel(),
};

export default (state = initialState, action: ActionPayload) => {
  switch (action.type) {
    case ActionType.SET_ORGS:
      return {...state, organizations: [...action.organizations]};
    case ActionType.SET_SUBS:
      return {...state, subscriptions: [...action.subscriptions]};
    case ActionType.LOAD_USERS_LIST:
      return {...state, list: [...action.list]};

    case ActionType.LOAD_USERS_ONE:
      return {
        ...state,
        user: {
          ...state.user,
          ...action.user,
        },
      };

    default:
      return state;
  }
};
