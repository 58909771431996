// @ts-nocheck
import React, {useMemo, useState} from 'react';
import {t} from 'i18n-utils';
import {showNotification} from 'components/notification/notification';
import {cancelDrawingPivotCenter, setCurrentFieldId} from 'containers/map/actions';
import type {ExternalService} from 'containers/map/types';
import {FluroAutocomplete, MultiKeysPressed, InfoBlock, FluroChip} from 'components';
import cn from 'classnames';
import {isBrowser} from 'react-device-detect';

import {AsyncStatusType, dialogToggle, DialogType, Status} from 'modules/helpers';
import type {Messages} from 'containers/info';
import {messages} from 'containers/info';
import {DemoIcon, SelectorFieldWrapper} from '../selectors-toolbar.styled';

import {reportError} from '../../error-boundary';
import ExternalServiceIcon from 'components/external-service-icon/external-service-icon';
import {useAppDispatch, useAppSelector} from '_hooks';
import {
  selectCurrentDemoFarmURL,
  selectCurrentFieldId,
  selectCurrentTab,
  selectIsWholeFarmView,
  selectSortedByNameMapFields,
} from '../../map/reducer/selectors';
import {selectIsAdmin} from '../../login/login-selectors';
import {selectAsyncRequestStatus} from 'modules/global/selectors';
import {useMapBarView} from '../../map/map-bar/map-bar-view';

const WHOLE_FARM_LABEL = 'Whole Farm';

type MenuItem = {
  value: number | string;
  label: JSX.Element | string;
  // The x-y-z case is so it can be added as a DOM property due to how react-md uses menuItems.
  'data-searchable-label': string; // String representation of the label, so it can be filtered.
  className?: string;
};

const ButtonsNavInstruction = (
  <li onClick={ev => ev.stopPropagation()} key="field-help-container" className="md-list-item">
    <InfoBlock>​Use Shift + Arrow Up or Arrow Down to switch fields.</InfoBlock>
  </li>
);

type Props = {
  mobileView?: boolean; // used to display data in FluroChips in certain areas (not in the toggle header) for mobiles
};

const SelectorField = ({mobileView}: Props) => {
  const dispatch = useAppDispatch();
  const fields = useAppSelector(selectSortedByNameMapFields);
  const [wholeTableView] = useMapBarView();
  const feature = useAppSelector(selectCurrentTab);
  const selectedFieldId = useAppSelector(selectCurrentFieldId);
  const isWholeFarmView = useAppSelector(selectIsWholeFarmView);
  const currentDemoFarmsURL = useAppSelector(selectCurrentDemoFarmURL);
  const isAdmin = useAppSelector(selectIsAdmin);
  const loadingFieldsStatus = useAppSelector(s =>
    selectAsyncRequestStatus(s, AsyncStatusType.loadFields)
  );
  const isLoadingFields = !loadingFieldsStatus || loadingFieldsStatus === Status.Pending;

  const [focused, toggleFocus] = useState(false);

  const selectField = (v: string | number) => {
    if (v === selectedFieldId) return;

    dispatch(setCurrentFieldId(v));
    dispatch(cancelDrawingPivotCenter());
  };

  const onAutocompleteField = (
    suggestion: string | number,
    suggestionIndex: number,
    matches: any
  ) => {
    if (matches[suggestionIndex]) {
      selectField(suggestion);
    }
  };

  const userFacingValue = useMemo(() => {
    const selectedFieldsAmount = fields.filter(f => f._selected).length;

    let value = '';

    switch (true) {
      case selectedFieldsAmount && selectedFieldsAmount !== fields.length:
        value = `${selectedFieldsAmount} field(s) selected`;
        break;
      case (isWholeFarmView || (wholeTableView && feature === 'farm')) &&
        (!selectedFieldsAmount || selectedFieldsAmount === fields.length):
        value = WHOLE_FARM_LABEL;
        break;

      default:
        value = fields.find(f => f.ID === selectedFieldId)?.Name || '';
    }

    return value;
  }, [fields, selectedFieldId, wholeTableView, isWholeFarmView, feature]);

  const fieldsMenuItems = useMemo((): MenuItem[] | any => {
    const returnLabel = (
      name: string,
      classNames: string,
      index: number,
      externalService?: ExternalService
    ) => (
      <span data-index={index} className={classNames}>
        {name}

        {externalService && isAdmin ? <ExternalServiceIcon value={externalService} /> : null}
      </span>
    );

    const result = [
      {
        label: returnLabel(
          WHOLE_FARM_LABEL,
          cn({'selected-field': selectedFieldId === 'WholeFarm'}),
          0
        ),
        'data-searchable-label': 'Whole Farm',
        value: 'WholeFarm',
      },
      ...fields.map((f, index) => {
        return {
          label: returnLabel(
            f.Name,
            cn('field-name', {
              'selected-field': f.ID === selectedFieldId,
            }),
            index,
            f.external_service
          ),
          'data-searchable-label': f.Name,
          value: f.ID,
        };
      }),
    ];

    if (isBrowser) {
      result.push(ButtonsNavInstruction as any);
    }

    return result;
  }, [fields, selectedFieldId]);

  const openDemoPopup = () => {
    const params = new URLSearchParams(currentDemoFarmsURL);
    const messageId = params.get('message') as keyof Messages;

    if (messageId && messages[messageId]) {
      dispatch(dialogToggle(DialogType.info, true, messageId));
    }
  };

  const onMenuOpen = () => {
    try {
      // scroll to selected field
      const selectedItem = document.querySelector('#selector-fields .selected-field');

      if (selectedItem) {
        const index = parseInt(selectedItem.getAttribute('data-index'));

        // do not scroll for fist 5 element
        if (index > 5) {
          selectedItem.scrollIntoView({block: 'center'});
        }
      }
    } catch (e) {
      reportError(`Cannot scroll to the selected field: ${e.message}`);
    }
  };

  const selectedFieldIndex = useMemo(() => {
    if (isWholeFarmView) return -1;

    return fieldsMenuItems.findIndex((f: MenuItem) => f.value === selectedFieldId);
  }, [selectedFieldId, fields, isWholeFarmView]);

  const selectNextField = () => {
    if (selectedFieldIndex < 0) return;
    // menuItems.length - 2 cuz the last item is not field item, it is - info message block
    if (selectedFieldIndex < fieldsMenuItems.length - 2) {
      selectField(fieldsMenuItems[selectedFieldIndex + 1].value);
    } else {
      showNotification({
        title: t({id: 'note.warning', defaultMessage: 'Warning'}),
        message: 'This is the last field in the list.',
        type: 'warning',
      });
    }
  };

  const selectPrevField = () => {
    selectedFieldIndex > 0 && selectField(fieldsMenuItems[selectedFieldIndex - 1].value);
  };

  if (isLoadingFields) {
    return null;
  }

  if (!fields.length || !selectedFieldId) {
    return <span className="no-fields-select">No fields</span>;
  }

  if (mobileView) {
    return <FluroChip className={'farm-field-mobile-chip'} label={userFacingValue} />;
  }

  return (
    <>
      <SelectorFieldWrapper id="selector-fields">
        <FluroAutocomplete
          id={'map__select-field'}
          className={cn('selectors-toolbar__select-field white-autocomplete', {focused})}
          searchIcon={focused}
          placeholder="Fields"
          onAutocomplete={onAutocompleteField}
          value={userFacingValue}
          searchKey={'data-searchable-label'}
          menuItems={fieldsMenuItems}
          onMenuOpen={onMenuOpen}
          onBlur={() => toggleFocus(false)}
          onFocus={() => toggleFocus(true)}
          shouldNotResetOnFocus
        />
        {currentDemoFarmsURL ? <DemoIcon onClick={openDemoPopup}>help_outline</DemoIcon> : null}
      </SelectorFieldWrapper>

      <MultiKeysPressed callback={selectPrevField} keys={['Shift', 'ArrowUp']} />
      <MultiKeysPressed callback={selectNextField} keys={['Shift', 'ArrowDown']} />
    </>
  );
};

export default SelectorField;
