import React, {useEffect} from 'react';
import {Redirect, Route, Switch} from 'react-router-dom';
import {useAppDispatch, useAppSelector} from '../../_hooks';
import {
  fetchProgramBoundaries,
  fetchUserPermissions,
  fetchWholeProject,
} from './monitoring/module/thunks';
import MRVProjectSelect from './mrv-project-select/mrv-project-select';
import {
  MRV_ENROLLMENT,
  MRV_PROJECT,
  MRV_PROJECT_SELECT,
  MRV_HOME,
  MONITORING_PROJECT_FORM,
  MRV_ADMIN,
  SIGN_UP_CONFIRMATION,
  MRV_ENROLLMENT_RE,
  SELF_REGISTER,
  MRV_DOCUSIGN_CONSENT_PAGE,
  MRV_FARMLOGS_INVITE_REDEEM,
  ENROLLMENT_PROJECT_FORM,
  MRV_PROFILE,
  MRV_GLOBAL_ADMIN,
} from './routes';
import {TiledMap} from '../map/tiled-map';
import {TableRowsSelectionProvider} from './monitoring/form/table-rows-selection';
import {MapBar} from '../map/map-bar/map-bar';
import {EnrollmentForm} from './enrollment/form/enrollment-form';
import {MonitoringPanel} from './monitoring/monitoring-panel';
import {MonitoringOverlay} from './monitoring/monitoring-overlay';
import {EditControl2} from '../map/edit-control-2';
import {CarbonEditFieldButtonsContainer} from './enrollment/map/carbon-edit-field-buttons-container';
import {GetParamEffects} from '../map/effects/get-param-effects';
import {selectUserProjectIds} from './monitoring/module/selectors';
import {selectIsLoading} from '../../modules/helpers/selectors';
import {ActionType} from './monitoring/module/types';
import {EnrollmentOverlay} from './enrollment/enrollment-overlay';
import './mrv-entry.scss';
import {PrivateRoute} from 'containers';
import {MRVHome} from './mrv-home';
import {OverlayContextProvider} from './context';
import {EmbedLogin} from 'containers/login/embed/embed-login-container';
import {MonitoringOnboardingDialog} from './monitoring/onboarding-dialog';
import {selectIsAuth} from '../login/login-selectors';
import {MRVNextPhaseDialog} from './home-page-dialogs/mrv-next-phase-dialog';
import {MRVEnrollmentContactNotSignedDialog} from './home-page-dialogs/mrv-enrollment-contract-is-not-signed-dialog';
import {DocusignConsentPage} from './admin/docusign-consent';
import {RegionsMaskOverlay} from './regions-mask-overlay';
import {useNotifications} from 'containers/mrv/hooks';
import {useMonitoringUrlParams} from './monitoring/hooks';
import {FMSSyncReportDialog} from './monitoring/fms-sync/fms-sync-report';
import {FMSSyncLoginDialog} from './monitoring/fms-sync/fms-sync-login-dialog';
import {FMSSyncDialog} from './monitoring/fms-sync/fms-sync-dialog';
import {FMSLoggedOutDialog} from './monitoring/fms-sync/fms-logged-out-dialog';
import {SWRConfig} from 'swr';
import {MRVApi} from '_api';
import {ProgramAnnouncementBanner} from './announcement/program-announcement-banner';
import {useWindowDimensions} from '_hooks/useWindowDimensions';
import {PleaseContinueOnMobile} from 'components/mrv/home-page-components/please-continue-on-mobile';
import Server from '_api/server';
import {MRV_SERVICE_URI} from '_api/mrv';
import {RedirectFromProfilePageToProfileDialog} from 'containers/profile/index';

const MobileCutoffMinimumWidth = 768;

const MRVProjectBase = () => {
  const dispatch = useAppDispatch();
  const isLoading = useAppSelector(s =>
    selectIsLoading(s, [ActionType.FETCH_USER_PERMISSIONS, fetchUserPermissions.pending.type])
  );
  const {projectId, programId} = useMonitoringUrlParams();
  const userProjectIds = useAppSelector(selectUserProjectIds);
  const sessionExpired = useAppSelector(s => s.global.sessionExpired);
  const {width} = useWindowDimensions();

  useNotifications();

  useEffect(() => {
    if (!isNaN(parseInt(projectId))) {
      dispatch(fetchWholeProject({projectId}));
      // The record values at this point should be already created when
      // a user added fields or mobs.
      // But in case they have already enrolled fields/mobs and an admin
      // changes the stage years -- this call will make sure the user has
      // the needed rows.
      MRVApi.createRecordYears(projectId);
    }
  }, [dispatch, projectId]);

  useEffect(() => {
    programId && dispatch(fetchProgramBoundaries(programId));
  }, [programId]);

  if (!isLoading && userProjectIds.length && !userProjectIds.includes(projectId)) {
    return <Redirect to={MRV_PROJECT_SELECT} />;
  }

  if (width < MobileCutoffMinimumWidth) {
    return <PleaseContinueOnMobile />;
  }

  return (
    <div className="map-v2__container">
      {sessionExpired && (
        <EmbedLogin id="session-expired-embed-login" style={{minWidth: '400px'}} visible />
      )}
      <TiledMap trackResize={false} layersSource="mapbox">
        <OverlayContextProvider>
          <TableRowsSelectionProvider>
            <MapBar>
              <div className="section-container mrv-right-panel-section-container">
                <Switch>
                  <Route exact path={MRV_HOME} component={MRVHome} />
                  <Route
                    path={[MRV_ENROLLMENT, ENROLLMENT_PROJECT_FORM]}
                    component={EnrollmentForm}
                  />
                  <Route path={MONITORING_PROJECT_FORM} component={MonitoringPanel} />

                  <Redirect to={MRV_HOME} />
                </Switch>
              </div>
            </MapBar>

            <RegionsMaskOverlay />
            <Route path={[MRV_ENROLLMENT, MRV_ENROLLMENT_RE]} component={EnrollmentOverlay} />
            <Route path={[MONITORING_PROJECT_FORM, MRV_HOME]} component={MonitoringOverlay} />

            {/* <MonitoringOverlay/> */}
          </TableRowsSelectionProvider>
          <EditControl2 />
        </OverlayContextProvider>

        <CarbonEditFieldButtonsContainer />
      </TiledMap>
      <GetParamEffects />
      {/* currently hardcoded for program 95 */}
      {Number(programId) === 95 && <MonitoringOnboardingDialog />}
      <MRVNextPhaseDialog />
      <MRVEnrollmentContactNotSignedDialog />

      <FMSSyncReportDialog />
      <FMSSyncLoginDialog />
      <FMSSyncDialog />
      <FMSLoggedOutDialog />
      <ProgramAnnouncementBanner />
    </div>
  );
};

const MRVEntry = () => {
  const isAuth = useAppSelector(selectIsAuth);
  const MrvAdmin = React.lazy(() => import('containers/mrv/admin/admin'));
  const MrvSelfRegister = React.lazy(() => import('containers/mrv/register'));
  const MrvAcceptRegistrationInvite = React.lazy(
    () => import('containers/mrv/sign-up-confirmation')
  );
  const MrvFarmlogs = React.lazy(() => import('containers/profile/integration/farmlogs-connect'));

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (isAuth) {
      dispatch(fetchUserPermissions());
    }
  }, [isAuth]);

  return (
    <EnhancedSWRConfig>
      <Switch>
        <PrivateRoute exact path={MRV_PROJECT_SELECT} component={MRVProjectSelect} />
        <PrivateRoute path={MRV_PROJECT} component={MRVProjectBase} />
        <PrivateRoute exact path={MRV_DOCUSIGN_CONSENT_PAGE} component={DocusignConsentPage} />
        <PrivateRoute path={MRV_ADMIN} component={MrvAdmin} />

        {/* Public Registration */}
        <Route path={SELF_REGISTER} component={MrvSelfRegister} />

        {/* Accept an invite you received via email to join a program*/}
        <Route path={SIGN_UP_CONFIRMATION} component={MrvAcceptRegistrationInvite} />

        <Route path={MRV_FARMLOGS_INVITE_REDEEM} component={MrvFarmlogs} />

        <PrivateRoute path={MRV_PROFILE} component={RedirectFromProfilePageToProfileDialog} />
        <PrivateRoute
          isAdminOnly
          path={MRV_GLOBAL_ADMIN}
          component={React.lazy(() => import('containers/admin'))}
        />

        <Redirect to={MRV_PROJECT_SELECT} />
      </Switch>
    </EnhancedSWRConfig>
  );
};

type EnhancedSWRConfigProps = {
  children: React.ReactNode;
};
const EnhancedSWRConfig = ({children}: EnhancedSWRConfigProps) => {
  return (
    <SWRConfig
      value={{
        fetcher: async resource => {
          const response = await Server.get(`${MRV_SERVICE_URI}${resource}`);
          return response.data;
        },
      }}
    >
      {children}
    </SWRConfig>
  );
};

export default MRVEntry;
