import {Icon} from '@regrow-internal/design-system';
import {Flex, Text} from 'components';
import {
  PLANNING_REPORT_INTERVENTION_REVERSE_MAPPING,
  SIReportModelLabel,
  SIReportProtocolLabel,
} from 'containers/si/constants';
import {practiceKPIIcons} from 'containers/si/icons';
import {ReportTile, ReportTileHeader} from 'containers/si/planning-report/components/ReportTile';
import {RegionItem} from 'containers/si/planning-report/programPlan/pages/ProgramDetail/RegionItem';
import {FlexGridItem, FlexGridRow} from 'containers/si/si-styled-components';
import type {PlanningReport} from 'containers/si/types';
import React from 'react';
import {FontIcon} from 'react-md';
import styled from 'styled-components';

export const ProgramDetails = ({report}: {report: PlanningReport}) => {
  return (
    <StyledFlexGridRow>
      {/* Program Parameters Column */}
      <StackedFlexGridItem>
        <ColumnHeader headerText="1. Program parameters" />
        <ReportTile>
          <ReportTileHeader
            titleText={'Projected program duration'}
            startIcon={<Icon type="calendar" />}
          />
          <Text bold variant="h2">{`${report.start_year}-${report.end_year}`}</Text>
        </ReportTile>
        <ReportTile>
          <ReportTileHeader
            titleText={'Measurement method used'}
            startIcon={<FontIcon>science</FontIcon>}
          />
          <Text bold variant="h2">
            {SIReportModelLabel[report.model] ?? report.model}
          </Text>
        </ReportTile>
        <ReportTile>
          <ReportTileHeader titleText={'Protocol'} startIcon={<FontIcon>list_alt</FontIcon>} />
          <Text bold variant="h2">
            {SIReportProtocolLabel[report.protocol] ?? report.protocol}
          </Text>
        </ReportTile>
      </StackedFlexGridItem>
      {/* Geographies and Crops Column*/}
      <StackedFlexGridItem>
        <ColumnHeader headerText="2. Geographies and crops" />
        {report.sourcing_regions.map(region => (
          <RegionItem key={region.unit_id} region={region} />
        ))}
      </StackedFlexGridItem>
      {/* Practice Interventions Column */}
      <StackedFlexGridItem>
        <ColumnHeader headerText="3. Practice interventions" />
        {report.intervention_scenarios.map((scenarios, i) => (
          <ReportTile key={i}>
            {scenarios.map(({intervention_type, percentage}) => {
              const {label, interventionOption} =
                PLANNING_REPORT_INTERVENTION_REVERSE_MAPPING[intervention_type];

              return (
                <Flex key={intervention_type} gap={8} alignItems="center">
                  {practiceKPIIcons[interventionOption]} {label(percentage)}
                </Flex>
              );
            })}
          </ReportTile>
        ))}
      </StackedFlexGridItem>
    </StyledFlexGridRow>
  );
};

const ColumnHeader = ({headerText}: {headerText: string}) => (
  <Flex gap={8} alignItems="center">
    <Text bold variant={'h2'} noMargin>
      {headerText}
    </Text>
    <FontIcon>lock</FontIcon>
  </Flex>
);

const StyledFlexGridRow = styled(FlexGridRow)`
  flex-wrap: wrap;
`;

const StackedFlexGridItem = styled(FlexGridItem)`
  flex-direction: column;
  gap: 10px;
  padding: 10px;
  min-width: 200px;
`;
