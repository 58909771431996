// redux-toolkit's async thunks statuses
export enum RequestStatusRTK {
  fulfilled = 'fulfilled',
  pending = 'pending',
  rejected = 'rejected',
}

export type SetRequestStatusAction = {
  type: ActionType.SetRequestStatus;
  key: AsyncStatusType;
  status: Status;
  errors?: any;
};

export enum AsyncStatusType {
  imageLayer = 'imageLayer',

  // admin
  adminDashboardCropStress = 'adminDashboardCropStress',
  adminDashboardNrx = 'adminDashboardNrx',
  adminSearch = 'adminSearch',

  farmsList = 'farms',
  loadOneFarm = 'loadOneFarm',

  loadFields = 'loadFields',
  fieldsData = 'fieldsData',
  allFieldsData = 'allFieldsData',
  fieldGeometries = 'fieldGeometries',
  fieldAreasOfInterest = 'fieldAreasOfInterest',

  // zoning
  mainZoning = 'mainZoning',
  loadSuggestedPoints = 'loadSuggestedPoints',
  zoningImageOverlay = 'zoningImageOverlay',
  NRxRecommendation = 'NRxRecommendation',
  NRxListsData = 'NRxListsData',
  NRXSettings = 'NRXSettings',
  treeDetectionZoning = 'treeDetectionZoning',

  // histogram
  histogram = 'histogram',

  // analytics
  analyticsGeometriesAndPoints = 'analyticsGeometriesAndPoints',
  analyticsFieldArableData = 'analyticsFieldArableData',
  // weather
  loadFieldWeather = 'loadFieldWeather',

  // Whole farm
  wholeFarmData = 'wholeFarmData',

  productivityMap = 'productivityMap',

  // Field boundaries
  cluFieldBoundaries = 'cluFieldBoundaries',

  // Upload fields
  parseUploadedFields = 'parseUploadedFields',
  uploadingFieldsToBackend = 'uploadingFieldsToBackend',

  // --

  cropTypesMatching = 'cropTypesMatching',

  binaryImageLayerData = 'binaryImageLayerData',

  usersActivity = 'usersActivity',

  // integrations
  authorizedPlatforms = 'authorizedPlatforms',

  // SI
  SILoadAllStates = 'SILoadAllStates',
  updateSIPolicy = 'updateSIPolicy',
}

export enum DialogType {
  statusAlert = 'statusAlert',
  adminAssignOrganizationToFarm = 'adminAssignOrganizationToFarm',
  adminAssignSubscriptionToOrganization = 'adminAssignSubscriptionToOrganization',
  adminSubscriptionsCreateFarm = 'adminSubscriptionsCreateFarm',
  info = 'info',
  histogram = 'histogram',
  cloudyTip = 'cloudyTip',
  assignMultipleFarms = 'assignMultipleFarms',
  assignMultipleOrgs = 'assignMultipleOrgs',
  emailLogsSend = 'emailLogsSend',
  emailLogsDiscard = 'emailLogsDiscard',
  cropStressDetailed = 'cropStressDetailed',

  fieldInfo = 'fieldInfo',
  confirm = 'confirm',
  alert = 'alert',
  editFieldName = 'editFieldName',
  editCloudy = 'editCloudy',
  editSelectedSeasonsProductivityMap = 'editSelectedSeasonsProductivityMap',
  exportEOS = 'exportEOS',
  cropInsightsReport = 'cropInsightsReport',
  editCrop = 'editCrop',
  importSeasons = 'importSeasons',
  addNewAnomaly = 'addNewAnomaly',
  addNewField = 'addNewField',

  integrationPlatforms = 'integrationPlatforms',
  fmsSyncReport = 'fmsSyncReport',
  fmsSyncDialog = 'fmsSyncDialog',
  fmsSyncLoggedOutDialog = 'fmsSyncLoggedOutDialog',
  fmsSyncLoginToPlatformDialog = 'fmsSyncLoginToPlatformDialog',
  geometriesNotAllowedToExportToAgx = 'geometriesNotAllowedToExportToAgx',

  carbonLogin = 'carbonLogin',
  carbonSignup = 'carbonSignup',
  carbonSignupConfirmation = 'carbonSignupConfirmation',
  carbonRestorePassword = 'carbonRestorePassword',
  editFarm = 'editFarm',
  editFarmName = 'editFarmName',
  deleteDialog = 'deleteDialog',
  carbonResources = 'carbonResources',

  profile = 'profile',

  // mrv
  mrvPhasesInfo = 'mrvPhasesInfo',
  mrvMonitoringOnboardingDialog = 'mrvMonitoringOnboardingDialog',
  carbonSuccessfulSigned = 'carbonSuccessfulSigned',

  cropPerformanceReport = 'cropPerformanceReport',
  endOfSeasonReport = 'endOfSeasonReport',
  pivothermoReport = 'pivothermoReport',
  cropStressReport = 'cropStressReport',
  requestFSAccess = 'requestFSAccess',
  noContractInfo = 'noContractInfo',
}

export type HelpersStore = {
  // Redux Toolkit async thunks statuses
  asyncRTK: {status: Record<string, RequestStatusRTK>; errors: Record<string, string>};
  asyncStatuses: {[key in AsyncStatusType]: AsyncStatus};

  dialogs: {[key in DialogType]?: DialogState};

  filters: {[filterName: string]: any};

  intercom: {visibility: boolean; unreadMessages: number};
};

export enum ActionType {
  DialogToggle = '@helpers/dialog/toggle',
  CloseAllDialogs = '@helpers/dialog/close-all',
  SetFilter = '@helpers/filter/set',
  SetRequestStatus = '@helpers/ui/set-request-status',
}

export type DialogToggleAction = {
  type: ActionType.DialogToggle;
  dialogKey: keyof HelpersStore['dialogs'];
  visible: boolean;
  payload?: any;
};

export type HelpersDialogsCloseAllAction = {
  type: ActionType.CloseAllDialogs;
};

export type SetFilterAction = {
  type: ActionType.SetFilter;
  name: string;
  value: any;
};

export enum Status {
  Todo = 'Todo',
  Pending = 'Pending',
  Done = 'Done',
}

export type AsyncStatus = {
  status?: Status;
  errors?: any;
};

export type DialogState = {
  visible?: boolean;
  payload?: any;
  phaseId?: number; // used in fmsSyncDialog
};
