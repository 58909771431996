import styled from 'styled-components';
export const SelectCheckboxWrapper = styled.div``;

export const SelectCheckboxItemWrapper = styled.div`
  min-width: 100px;
  max-width: 250px;
  overflow: hidden;
`;

export const SelectCheckboxMenuWrapper = styled.div`
  padding-right: 20px;
  min-height: ${({minHeight}: {minHeight: boolean}) => (minHeight ? '300px' : 'none')};
  min-width: 250px;
`;

export const SelectCheckboxSearchWrapper = styled.div`
  padding-left: 20px;

  .md-text-field {
    color: #000000de !important;
  }
`;

export const SelectCheckboxButtonWrapper = styled.div`
  padding: 10px 5px;
  min-width: 200px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  > div {
    display: flex;
    justify-content: space-between;
  }
`;

export const LabelWrapper = styled.div`
  width: 90%;
  display: flex;

  b {
    color: rgba(0, 0, 0, 0.6);
  }
`;
