import type {PayloadAction} from '@reduxjs/toolkit';
import type {NormalizedSchema} from 'normalizr';

export enum RequestStatus {
  Idle = 'Idle',
  Loading = 'Loading',
  Skipped = 'Skipped',
  Error = 'Error',
  Success = 'Success',
}

export type RequestMethod = 'get' | 'post' | 'put' | 'delete';

export enum SIFeatureType {
  Admin = 'si-admin',
  ComparisonDashboard = 'si-comparison-dashboard',
  Home = 'si-home',
  Map = 'si-map',
  Kpi = 'si-kpi',
  Abatement = 'si-abatement-potential',
  SupplyShed = 'si-supply-shed',
  PlanDashboard = 'si-plan-dashboard',
  DataExport = 'si-data-export',
  PlaningReport = 'si-planning-reports',
}

export type SIFeatureTypes =
  | SIFeatureType.Admin
  | SIFeatureType.ComparisonDashboard
  | SIFeatureType.Home
  | SIFeatureType.Map
  | SIFeatureType.Kpi
  | SIFeatureType.Abatement
  | SIFeatureType.SupplyShed
  | SIFeatureType.DataExport
  | SIFeatureType.PlanDashboard
  | SIFeatureType.PlaningReport;

export type MRVFeatureType =
  | 'carbon'
  | 'monitoring'
  | 'mrv-admin'
  | 'mrv-home'
  | 'mrv-admin-program-dashboard'
  | 'mrv-admin-program-configure';

export type TFeature =
  | SIFeatureTypes
  | MRVFeatureType
  | 'farm'
  | 'crop' // anomalies, crop stress
  | 'data-layers'
  | 'crop-performance'
  | 'sustainability-insights'
  | 'enrollment'
  | 'analytics'
  | 'zoning'
  | 'tsp'
  | 'workspace'
  | 'profile'
  | 'faq'
  | 'admin'
  | 'ci-admin'
  | 'log-out';

export const isFeature = (feature: string): feature is TFeature => {
  switch (feature) {
    case 'farm':
    case 'crop':
    case 'data-layers':
    case 'crop-performance':
    case 'sustainability-insights':
    case 'carbon':
    case 'monitoring':
    case 'analytics':
    case 'zoning':
    case 'tsp':
    case 'nrx':
    case 'profile':
      return true;
    default:
      return false;
  }
};

export type TSensor =
  | 'CCCI'
  | 'MSAVI'
  | 'NDRE'
  | 'NMAP'
  | 'NDVI'
  | 'RNDVI'
  | 'NDWI'
  | 'PGI'
  | 'TCI'
  | 'RGB'
  | 'TIRS'
  | 'VVI'
  | 'DIAMETER'
  | 'NONE'
  | 'EC'
  | 'ELEVATION'
  | 'PH'
  | 'YIELD'
  | 'N-application'
  | 'PTIRS'
  | 'NC'
  | 'TIRS-P'
  | 'RGB-S';

export const isSensor = (sensor: string): sensor is TSensor => {
  switch (sensor) {
    case 'CCCI':
    case 'MSAVI':
    case 'NDRE':
    case 'NMAP':
    case 'NDVI':
    case 'RNDVI':
    case 'NDWI':
    case 'PGI':
    case 'TCI':
    case 'RGB':
    case 'TIRS':
    case 'VVI':
    case 'DIAMETER':
    case 'EC':
    case 'ELEVATION':
    case 'PH':
    case 'YIELD':
    case 'N-application':
    case 'PTIRS':
    case 'NC':
    case 'NONE':
      return true;
    default:
      return false;
  }
};

export function parseSensor(sensor: string): TSensor {
  switch (sensor) {
    case 'CCCI':
      return 'CCCI';
    case 'MSAVI':
      return 'MSAVI';
    case 'NDRE':
      return 'NDRE';
    case 'NDVI':
      return 'NDVI';
    case 'RNDVI':
      return 'RNDVI';
    case 'NDWI':
      return 'NDWI';
    case 'PGI':
      return 'PGI';
    case 'TCI':
      return 'TCI';
    case 'TIRS':
      return 'TIRS';
    case 'VVI':
      return 'VVI';
    case 'DIAMETER':
      return 'DIAMETER';
    case 'NONE':
      return 'NONE';
    case 'PTIRS':
      return 'PTIRS';
    case 'NC':
      return 'NC';
    default:
      return 'NONE';
  }
}

export type Dialogs = 'editField' | 'editFarm' | 'editSeason' | 'editPivotTableView' | 'demoFarms';

export type Option = {
  label: string;
  value: string | number;
  options?: Option[];
};

/**
 * Date string with no timezone information.
 *
 * We have not been storing timezone information and so we can't use Date as a type.
 */
export type DateStringWithNoTZ = string; // '2021-10-05T01:56:11'

export type NormalizedSchemaPayloadAction<E = any, R = any> = PayloadAction<NormalizedSchema<E, R>>;

export type AsyncThunkActionMeta<A = any> = {
  arg: A;
  requestId: string;
};

export type AsyncThunkFulfilledAction<P = any, A = any> = PayloadAction<
  P,
  string,
  AsyncThunkActionMeta<A>
>;

export type Override<T1, T2> = Omit<T1, keyof T2> & T2;
