import {RequestStatus} from 'types';
import type {IntegrationsState} from './types';
import type {Action} from './actions';
import {ExternalService} from '../../map/types';
import {getTypedKeys} from '_utils/object';

export enum ActionType {
  AGWORLD_SYNC_GROWERS = 'integrations/sync-agworld-growers',
  CLIMATE_SYNC_FIELDS = 'integrations/sync-climate-fields',
  EFC_SYNC_GROWERS = 'integrations/sync-efc-growers',
  JOHN_DEERE_SYNC_GROWERS = 'integrations/sync-john-deere-growers',
  AGX_SYNC_GROWERS = 'integrations/sync-agx-growers',
  AGX_TOGGLE_FARMS_IMPORTED = 'integrations/agx-toggle-farms-imported',
  SELECT_PLATFORM_TO_IMPORT = 'integrations/select-platform-to-import',
  SET_PRESELECTED_PLATFORMS = 'integrations/set-preselected-platform',
  LOG_OUT_FROM_PLATFORM = 'integrations/log-out-from-platform',
  SET_PLATFORM_AUTH_VALUE = 'integrations/set-platform-auth-status',
  PW_SYNC_FARMS = 'integrations/sync-productionwise-farms',
  FARMLOGS_SYNC_FIELDS = 'integrations/sync-farmlogs-fields',
}

const defaultPlatformState = {
  syncStatus: RequestStatus.Idle,
  selected: false,
  authorized: false,
} as const;

const initialState: IntegrationsState = {
  selectedPlatformToImport: null,
  platforms: {
    [ExternalService.Agrian]: defaultPlatformState,
    [ExternalService.Agworld]: defaultPlatformState,
    [ExternalService.Climate]: defaultPlatformState,
    [ExternalService.Agx]: defaultPlatformState,
    [ExternalService.EFC]: defaultPlatformState,
    [ExternalService.JohnDeere]: defaultPlatformState,
    [ExternalService.ProductionWise]: defaultPlatformState,
    [ExternalService.FarmLogs]: defaultPlatformState,
    [ExternalService.JohnDeereLong]: null, // not used
    [ExternalService.Terravion]: null,
    [ExternalService.Google]: null,
    [ExternalService.Proagrica]: null,
  },
} as const;

const IntegrationReducer = (
  state: IntegrationsState = initialState,
  action: Action
): IntegrationsState => {
  switch (action.type) {
    case ActionType.AGWORLD_SYNC_GROWERS:
      return {
        ...state,
        platforms: {
          ...state.platforms,
          [ExternalService.Agworld]: {
            ...state.platforms[ExternalService.Agworld],
            syncStatus: action.syncStatus,
          },
        },
      };

    case ActionType.AGX_SYNC_GROWERS:
      return {
        ...state,
        platforms: {
          ...state.platforms,
          [ExternalService.Agx]: {
            ...state.platforms[ExternalService.Agx],
            syncStatus: action.syncStatus,
          },
        },
      };

    case ActionType.AGX_TOGGLE_FARMS_IMPORTED:
      return {
        ...state,
        platforms: {
          ...state.platforms,
          [ExternalService.Agx]: {
            ...state.platforms[ExternalService.Agx],
            farmsImported: action.farmsImported,
          },
        },
      };

    case ActionType.CLIMATE_SYNC_FIELDS:
      return {
        ...state,
        platforms: {
          ...state.platforms,
          [ExternalService.Climate]: {
            ...state.platforms[ExternalService.Climate],
            syncStatus: action.syncStatus,
          },
        },
      };

    case ActionType.EFC_SYNC_GROWERS:
      return {
        ...state,
        platforms: {
          ...state.platforms,
          [ExternalService.EFC]: {
            ...state.platforms[ExternalService.EFC],
            syncStatus: action.syncStatus,
          },
        },
      };

    case ActionType.JOHN_DEERE_SYNC_GROWERS:
      return {
        ...state,
        platforms: {
          ...state.platforms,
          [ExternalService.JohnDeere]: {
            ...state.platforms[ExternalService.JohnDeere],
            syncStatus: action.syncStatus,
          },
        },
      };

    case ActionType.SELECT_PLATFORM_TO_IMPORT:
      return {
        ...state,
        selectedPlatformToImport: action.platform,
      };

    case ActionType.SET_PRESELECTED_PLATFORMS:
      const updatedPlatforms = {...state.platforms};
      getTypedKeys(action.platforms).forEach(platform => {
        let platformToUpdate = updatedPlatforms[platform];
        if (platformToUpdate !== null) {
          platformToUpdate = {
            ...platformToUpdate,
            selected: action.platforms[platform],
            authorized: action.platforms[platform],
          };
        }
        // @ts-expect-error - Some platforms are null as they aren't used, but we have already asserted that the platform is non-null before updating fields
        updatedPlatforms[platform] = platformToUpdate;
      });

      return {
        ...state,
        platforms: updatedPlatforms,
      };

    case ActionType.LOG_OUT_FROM_PLATFORM:
      return {
        ...state,
        platforms: {
          ...state.platforms,
          [action.platform]: {...state.platforms[action.platform], authorized: false},
        },
      };

    case ActionType.PW_SYNC_FARMS:
      return {
        ...state,
        platforms: {
          ...state.platforms,
          [ExternalService.ProductionWise]: {
            ...state.platforms[ExternalService.ProductionWise],
            syncStatus: action.syncStatus,
          },
        },
      };
    case ActionType.FARMLOGS_SYNC_FIELDS:
      return {
        ...state,
        platforms: {
          ...state.platforms,
          [ExternalService.FarmLogs]: {
            ...state.platforms[ExternalService.FarmLogs],
            syncStatus: action.syncStatus,
          },
        },
      };

    default:
      return state;
  }
};

export default IntegrationReducer;
