import {AdminApi} from '_api';
import {rowsPerPage} from 'containers/admin';

enum ActionType {
  SetFields = '@fields-lookup/fields/set',
  SetSearchParams = '@fields-lookup/search-params/set',
  SetSearchPageParams = '@fields-lookup/search-page-params/set',
  SetSortParam = '@fields-lookup/sort-params/set',
  ClearFilters = '@fields-lookup/search-params/clear',
  ShowAllColumns = '@fields-lookup/show-all-columns-toggle',
}

export type SortBy =
  | 'name'
  | 'farm_name'
  | 'crop_type'
  | 'region_name'
  | 'country_name'
  | 'organization_name'
  | 'subscription_name';

export type SortByParams = {
  field: SortBy;
  desc: boolean;
};

export type FieldsLookupSearchParams = {
  page: number;
  per_page: number;
  subscription_ids: number[];
  organization_ids: number[];
  countries: string[];
  region_ids: number[];
  crop_types: string[];
  crop_varieties: string[];
  years: number[];
  sort_by: SortByParams[];
};

export type LookupSeason = {
  season_id: number;
  start_date: string;
  end_date: string;
  crop_type: string;
  crop_variety: string;
};

export type LookupField = {
  id: number;
  name: string;
  farm_id: number;
  kml_id: number;
  farm_name: string;
  seasons: LookupSeason[];
  region_id: 155;
  region_name: string;
  country_name: string;
  organization_id: number;
  organization_name: string;
  subscription_id: number;
  subscription_name: string;
};

type Column = {
  title: string;
  value: SortBy;
  sortDisabled?: boolean;
  visible: boolean;
};

export type FieldsLookupStore = {
  table_columns: Column[];
  total_records: number;
  total_fields_area: number;
  search_params: FieldsLookupSearchParams;
  fields: LookupField[];
};

const initialState: FieldsLookupStore = {
  table_columns: [
    {title: 'Field', value: 'name', visible: true},
    {title: 'Farm', value: 'farm_name', visible: true},
    {title: 'Crop/Variety', value: 'crop_type', sortDisabled: true, visible: true},
    {title: 'Country', value: 'country_name', visible: true},
    {title: 'Region', value: 'region_name', visible: true},
    {title: 'Organization', value: 'organization_name', visible: true},
    {title: 'Subscription', value: 'subscription_name', visible: false},
  ],
  total_records: 0,
  total_fields_area: 0,
  fields: [],
  search_params: {
    page: 1,
    per_page: rowsPerPage,
    subscription_ids: [],
    organization_ids: [],
    countries: [],
    region_ids: [],
    crop_types: [],
    crop_varieties: [],
    years: [],
    sort_by: [
      {
        field: 'name',
        desc: false,
      },
    ],
  },
};

export const filterParamToSearchParam: any = {
  countries: 'countries',
  regions: 'region_ids',
  organizations: 'organization_ids',
  subscriptions: 'subscription_ids',
  crops: 'crop_types',
  cropVarieties: 'crop_varieties',
  years: 'years',
};

export const loadFieldsLookup = (search_params: FieldsLookupSearchParams) => (dispatch: any) => {
  return AdminApi.getFieldsLookup(search_params).then(({data}) => {
    const result_data = data?.result.fields
      ? data?.result
      : {total_records: 0, total_fields_area: 0, fields: []};
    dispatch({type: ActionType.SetFields, ...result_data});
  });
};

export const setSearchParams = (searchProp: string, value: any[]) => ({
  type: ActionType.SetSearchParams,
  searchProp,
  value,
});

export const setSearchPageParams = (page: number, per_page: number) => ({
  type: ActionType.SetSearchPageParams,
  page,
  per_page,
});

export const setSortParams = (sortByParams: SortByParams) => ({
  type: ActionType.SetSortParam,
  sortByParams,
});

export const clearFilters = () => ({
  type: ActionType.ClearFilters,
});

export const showAllColumns = (value: boolean) => ({
  type: ActionType.ShowAllColumns,
  value,
});

const hiddenColumns: string[] = ['subscription_name'];

export const reducer = (state = initialState, action: any) => {
  switch (action.type) {
    case ActionType.SetSortParam: {
      return {
        ...state,
        search_params: {
          ...state.search_params,
          sort_by: [action.sortByParams],
        },
      };
    }

    case ActionType.SetFields: {
      return {
        ...state,
        fields: action.fields,
        total_records: action.total_records,
        total_fields_area: action.total_fields_area,
      };
    }

    case ActionType.SetSearchParams: {
      return {
        ...state,
        search_params: {
          ...state.search_params,
          [action.searchProp]: [...action.value],
        },
      };
    }

    case ActionType.ClearFilters: {
      return {
        ...state,
        search_params: {...initialState.search_params},
      };
    }

    case ActionType.SetSearchPageParams: {
      return {
        ...state,
        search_params: {
          ...state.search_params,
          page: action.page,
          per_page: action.per_page,
        },
      };
    }

    case ActionType.ShowAllColumns:
      return {
        ...state,
        table_columns: state.table_columns.map(c => {
          if (hiddenColumns.includes(c.value)) {
            return {...c, visible: action.value};
          }

          return c;
        }),
      };

    default: {
      return state;
    }
  }
};
