import React, {useMemo} from 'react';
import type {ComponentType} from 'react';
import {useAppDispatch, useAppSelector} from '_hooks';
import Select, {components} from 'react-select';
import type {ValueContainerProps, OptionProps, StylesConfig} from 'react-select';
import type {TFeature} from 'types';
import {CropInsightsFeatures, CropInsightsAdminFeatures} from '_constants/feature-links';
import {selectCurrentTab} from 'containers/map/reducer/selectors';
import {setFeature} from 'containers/map/actions';
import cn from 'classnames';
import {
  MenubarMiddleNavTabsContainer,
  MenubarMiddleNavOptionInnerContainer,
  MenubarMiddleNavIndicatorsInnerContainer,
  MenubarMiddleNavTabElement,
} from 'containers/menubar/menubar-middle-nav/menubar-middle-nav';
import {
  MenubarMenuOptionStyles,
  MenubarMenuStyles,
  MenubarControlStyles,
  MenubarIndicatorsContainerStyles,
  MenubarValueContainerStyles,
  MenubarMenuListStyles,
} from 'containers/menubar/menubar.styled';
import {MenubarArrowDropdown} from 'containers/menubar/menubar-components';
import {useHistory} from 'react-router-dom';
import {selectCurrentFarmId, selectCurrentFieldId} from '../../map/reducer/selectors';
import {selectIsAdmin} from 'containers/login/login-selectors';
import {getFeaturesLabels} from '_utils/translations';
import {selectViewport} from 'modules/global/selectors';
import {CI_ADMIN} from 'containers/map/routes';
import {isSingleOption} from 'containers/menubar/menubar-utils';
import {CI_ROOT_PATH} from '_environment';

type Option = {
  value: TFeature;
  label: string;
  onClick?: (value?: string) => void;
  title?: string;
  topHorizontalSeparator?: boolean;
};

const IndicatorsContainer: ComponentType<ValueContainerProps<Option>> = ({...props}) => {
  const currentTab = useAppSelector(selectCurrentTab);
  const featureLabels = getFeaturesLabels();

  return (
    <components.IndicatorsContainer {...props}>
      <MenubarMiddleNavIndicatorsInnerContainer>
        <div className="workspace-label">{featureLabels[currentTab]}</div>
        <MenubarArrowDropdown />
      </MenubarMiddleNavIndicatorsInnerContainer>
    </components.IndicatorsContainer>
  );
};
const OptionComponent: ComponentType<OptionProps<Option>> = ({children, ...props}) => {
  const currentTab = useAppSelector(selectCurrentTab);
  const isSelected = props.data.value === currentTab;
  return (
    <MenubarMiddleNavOptionInnerContainer>
      <components.Option {...props} isSelected={isSelected}>
        {children}
      </components.Option>
    </MenubarMiddleNavOptionInnerContainer>
  );
};

export const CropInsightsNavigationComponent: ComponentType = () => {
  const currentTab = useAppSelector(selectCurrentTab);
  const dispatch = useAppDispatch();
  const history = useHistory();
  const farmId = useAppSelector(selectCurrentFarmId);
  const fieldId = useAppSelector(selectCurrentFieldId);
  const featureLabels = getFeaturesLabels();
  const viewport = useAppSelector(selectViewport);
  const isAdmin = useAppSelector(selectIsAdmin);

  const displayDropdownMenu = viewport.width <= 1400;

  const onSetFeature = (feature: TFeature) => {
    dispatch(setFeature(feature));

    let cropInsightsLink = '';

    if (feature === 'ci-admin') {
      cropInsightsLink = CI_ADMIN;
    } else {
      const url = new URLSearchParams(window.location.search);
      url.set('tab', feature as string);
      cropInsightsLink = `/${CI_ROOT_PATH}/${farmId || ''}${
        fieldId ? `/${fieldId}` : ''
      }?${url.toString()}`;
    }

    history.push(cropInsightsLink);
  };

  const cropInsightsTabs: Option[] = useMemo(() => {
    const computedFeatures = isAdmin
      ? [...CropInsightsFeatures, ...CropInsightsAdminFeatures]
      : CropInsightsFeatures;
    return computedFeatures.map(feature => ({
      value: feature,
      label: featureLabels[feature],
      onClick: () => onSetFeature(feature),
    }));
  }, [currentTab, featureLabels, isAdmin]);

  return displayDropdownMenu ? (
    <Select
      // menuIsOpen
      isMulti={false}
      styles={TopLevelNavSelectStyles}
      onChange={value => {
        if (isSingleOption(value) && value?.onClick) {
          value.onClick();
        }
      }}
      options={cropInsightsTabs}
      components={{IndicatorsContainer, Option: OptionComponent}}
    />
  ) : (
    <MenubarMiddleNavTabsContainer className="middle-nav-container">
      {cropInsightsTabs.map(feature => (
        <MenubarMiddleNavTabElement
          onClick={() => onSetFeature(feature.value)}
          className={cn({selected: currentTab === feature.value})}
          key={feature.label}
        >
          {feature.label}
        </MenubarMiddleNavTabElement>
      ))}
    </MenubarMiddleNavTabsContainer>
  );
};

export const TopLevelNavSelectStyles: StylesConfig<Option> = {
  option: (base, state) => ({
    ...base,
    ...MenubarMenuOptionStyles,
    fontWeight: state.isSelected ? 700 : 400,
  }),
  valueContainer: base => ({...base, ...MenubarValueContainerStyles}),
  indicatorsContainer: base => ({
    ...base,
    ...MenubarIndicatorsContainerStyles,
  }),

  menu: base => ({
    ...base,
    ...MenubarMenuStyles,
    left: 0,
  }),
  control: base => ({
    ...base,
    ...MenubarControlStyles,
  }),
  menuList: base => {
    return {...base, ...MenubarMenuListStyles};
  },
};
