import type {ComponentType} from 'react';
import React, {useEffect, useState} from 'react';
import {t} from 'i18n-utils';

import {KPIBarChart} from 'containers/si/kpi/charts/kpi-bar-chart-card';
import {useAppSelector} from '_hooks';
import {convertUnit} from '_utils';
import {kiloFormatter, toFixedFloat} from '_utils/number-formatters';
import {selectSISelectedCropTypes, selectSIYearsFilter} from 'containers/si/module/selectors';
import {selectMeasurement} from 'containers/login/login-selectors';
import {selectCropTypesCDLIdMap} from 'modules/global/selectors';
import {TOOLTIPS} from 'containers/si/constants';
import {useFetchKPI} from 'containers/si/hooks/useFetchKPI';
import {convertArea} from '@turf/turf';
import {SparklingChartCard} from '../charts/sparkling-chart-card';
import type {ChartDataSets} from 'chart.js';
import {calculateScale, safeDivision} from 'modules/sustainability-insights/utils';

export const CropYieldCard: ComponentType = () => {
  const cropsById = useAppSelector(selectCropTypesCDLIdMap);
  const measurement = useAppSelector(selectMeasurement);
  const yearsFilter = useAppSelector(selectSIYearsFilter);
  const selectedCropTypes = useAppSelector(selectSISelectedCropTypes);
  const [sparklingChartData, setSparklingChartData] = useState<{
    labels: string[];
    datasets: number[];
  }>({
    labels: [],
    datasets: [],
  });
  const [metric, setMetric] = useState<string>('0');
  const [average, setAverage] = useState(0);
  const [pctFromAverage, setPctFromAverage] = useState(0);
  const [pctFromPrev, setPctFromPrev] = useState<number | null>(0);
  const {response: cropAreaResponse, loading} = useFetchKPI({
    kpi: 'crop_area',
    summarizeBy: ['crop_type', 'annualized'],
  });
  const {response: yieldResponse} = useFetchKPI({
    kpi: 'yield',
    summarizeBy: ['crop_type', 'annualized'],
  });
  const [chartData, setChartData] = useState<{label: string; value: number}[]>([]);

  useEffect(() => {
    if (!cropAreaResponse || !yieldResponse) return;

    if (selectedCropTypes.length > 1) {
      const getKilogramsByAcre = (cropId: string, value: number): number => {
        const cropM2 = cropAreaResponse.crop_type_summary?.[cropId] ?? 0;
        const cropAcres = convertUnit('ac', 'm2', cropM2, true); // in ac

        return toFixedFloat(value / cropAcres, 2);
      };

      const transformedData = Object.entries(yieldResponse?.crop_type_summary ?? []).map(crop => {
        const [cropId, kilograms] = crop;

        return {
          label: cropsById[Number(cropId)]?.label,
          value: getKilogramsByAcre(cropId, kilograms),
        };
      });

      setChartData(transformedData);
    } else {
      // Generate Chart Data
      const data: {labels: string[]; datasets: number[]} = {labels: [], datasets: []};

      // [Year]:ghg_kg -> tons Data for spark-line
      Object.entries(yieldResponse?.annualized_summary ?? []).forEach(([year, yieldKg]) => {
        data.labels.push(String(year));

        const cropArea = cropAreaResponse?.annualized_summary?.hasOwnProperty(year)
          ? cropAreaResponse.annualized_summary[year]
          : 0;

        const acres = convertArea(cropArea, 'meters', 'acres');
        const SummaryYieldPerArea = safeDivision(yieldKg, acres);
        data.datasets.push(toFixedFloat(SummaryYieldPerArea || 0, 0));
      });
      setSparklingChartData(data);

      // yield_kg / area_m2 -> kgs per acre
      const acres = convertArea(cropAreaResponse?.metric?.value ?? 0, 'meters', 'acres');
      const yieldKg = yieldResponse?.metric?.value || 0 / acres; // in kg from API
      const yieldPerAcre = yieldKg / acres;
      setMetric(String(kiloFormatter(yieldPerAcre)) || '0'); // in kg/acre

      // Calculate kg / acre over years average
      const cropSum = data.datasets.reduce((a, c) => a + c, 0);
      const cropAverage = cropSum / data.datasets.length;
      setAverage(cropAverage || 0);

      // Calculate percentage change from average to current year
      const calcAvgPercent = ((yieldPerAcre - cropAverage) / yieldPerAcre) * 100;
      setPctFromAverage(Math.round(calcAvgPercent) || 0);

      // Calculate percentage change from previous year to current year
      const previousYear = yearsFilter[0] - 1;
      const prevYearIndex = data.labels.findIndex(label => Number(label) === previousYear);
      const previousYearYieldPerAcre = data.datasets[prevYearIndex];
      const calcPrevPercent =
        (previousYearYieldPerAcre &&
          ((yieldPerAcre - previousYearYieldPerAcre) / Math.abs(yieldPerAcre)) * 100) ||
        null;

      const formatRoundPercentage = (pct: number): number => {
        //Rounds percentage considering edge 0 - 0.99% or -0.99$ - 0.
        return pct < 1 ? (pct < 0 && pct > -1 ? Math.floor(pct) : Math.ceil(pct)) : Math.round(pct);
      };

      setPctFromPrev(calcPrevPercent && formatRoundPercentage(calcPrevPercent));
    }
  }, [cropsById, cropAreaResponse, yieldResponse, measurement, selectedCropTypes, yearsFilter]);

  const data: ChartDataSets[] = [
    {
      borderColor: '#4988EF',
      pointBorderWidth: 1,
      pointRadius: 1,
      fill: 'start',
      data: sparklingChartData.datasets,
      // TODO: types are wrong in chartjs; revisit when upgraded from 2.x
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      backgroundColor: (context: any) => {
        const ctx = context.chart.ctx;
        const yScale = context.chart.scales['y-axis-0'].height || 100;
        const midline = context.chart.scales['y-axis-0'].getPixelForValue(average) || 50;
        const midlineScale = calculateScale(midline, 0, yScale);
        const gradient = ctx.createLinearGradient(0, 0, 0, yScale);
        gradient.addColorStop(0, 'rgba(73, 136, 239)');
        gradient.addColorStop(midlineScale, 'rgba(73, 136, 239, 0.1)');
        gradient.addColorStop(1, 'rgba(73, 136, 239, 0)');
        return gradient;
      },
    },
  ];

  const comparisonValues: {
    label: string;
    value: string | number;
    direction: 'positive' | 'negative';
  }[] = [
    {
      label: `Compared to ${sparklingChartData.datasets.length} year avg.`,
      value: `${Math.abs(pctFromAverage)}%`,
      direction: pctFromAverage < 0 ? 'negative' : 'positive',
    },
  ];
  pctFromPrev &&
    comparisonValues.unshift({
      label: `Compared to ${yearsFilter[0] - 1}`,
      value: `${Math.abs(pctFromPrev)}%`,
      direction: pctFromPrev < 0 ? 'negative' : 'positive',
    });

  return selectedCropTypes.length > 1 ? (
    <KPIBarChart
      title={t({id: 'Crop yield'})}
      subtitle={t({
        id: 'in kg/acre',
      })}
      colors={['#CC639C']}
      data={chartData}
      kiloConvert
      tooltip={TOOLTIPS.KPI.yield}
      loading={loading}
    />
  ) : (
    <SparklingChartCard
      title={t({id: 'Crop yield'})}
      subtitle={t({id: 'in kg/acre'})}
      cardCenterNumber={metric === '0' ? 'No Data' : `${metric.toUpperCase()}`}
      cardCenterUnits={''}
      comparisonValues={comparisonValues}
      sparklingChartCaption={`${sparklingChartData.datasets.length} year avg`}
      sparklingChartCaptionBolded={`${kiloFormatter(average)}`}
      chartData={{labels: sparklingChartData.labels, datasets: data}}
      chartDataMidline={average}
      tooltip={TOOLTIPS.KPI.cropArea}
      loading={loading}
    />
  );
};
