import type {InputHTMLAttributes} from 'react';
import React from 'react';
import styled, {useTheme} from 'styled-components';
import {FSInput} from '../FSInput';

export const FSInputGroup = styled.div<{flexGrow?: React.CSSProperties['flexGrow']}>`
  /* Auto layout */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 8px;

  /* Inside auto layout */
  // flex: none;
  align-self: stretch;
  flex-grow: ${({flexGrow}) => flexGrow || 0};
  // flex-basis: 0;
`;

interface Props {
  disabled?: HTMLInputElement['disabled'];
  errorText?: string;
  fullWidth: boolean;
  id: string;
  inputMode?: InputHTMLAttributes<HTMLInputElement>['inputMode'];
  label: string;
  name?: HTMLInputElement['name'];
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  testId?: string;
  type?: InputHTMLAttributes<HTMLInputElement>['type'];
  value?: HTMLInputElement['value'];
}

export const Input = ({
  disabled,
  errorText,
  fullWidth,
  id,
  inputMode,
  label,
  name,
  onChange,
  testId,
  type,
  value,
}: Props) => {
  const theme = useTheme();
  return (
    <FSInputGroup flexGrow={1}>
      <label htmlFor={id}>{label}</label>
      <FSInput
        data-testid={testId}
        disabled={disabled}
        fullWidth={fullWidth}
        id={id}
        inputMode={inputMode}
        name={name}
        onChange={onChange}
        type={type}
        value={value}
      />
      {errorText ? <div style={{color: theme.color.text.error}}>{errorText}</div> : null}
    </FSInputGroup>
  );
};
