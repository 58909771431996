import styled from 'styled-components';
import variables from 'variables.scss';

export const Menu = styled.li`
  padding: 10px 15px;
  list-style: none;
  .practice-item {
    margin-top: 5px;
    cursor: pointer;
    transition: all 0.2s;

    img,
    span {
      filter: grayscale(1);
    }

    &.active {
      img,
      span {
        filter: grayscale(0);
      }
    }
  }
`;

export const TitleComponent = styled.div`
  .title {
    color: ${variables['main-gray-900']};
  }

  .sub-text {
    color: ${variables['main-gray-600']};
    margin-top: 5px;
    font-size: 0.8571em;
  }
`;

export const Avatar = styled.img`
  border-radius: 50%;
`;

export const SimpleAvatar = styled.span`
  border-radius: 50%;
  border: 2px solid #fff;
  width: 30px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  font-size: 1.3rem;
  background-color: #00bfbf;
  display: inline-block;
`;

export const CirclesWrapper = styled.div`
  display: flex;
  max-width: 150px;

  .practice-avatar {
    transition: box-shadow 0.4s;
  }
  &.clickable:hover {
    .practice-avatar {
      transition: box-shadow 0.4s;
      box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    }
  }

  .avatar-offset {
    display: inline-block;
    position: relative;
    margin-right: -10px;

    > img {
      width: 30px;
      height: 30px;
    }

    &:last-child {
      margin-right: 0px;
    }
  }

  &.no-circles {
    color: ${variables['main-gray-600']};
  }
`;
