import {Sticky} from 'components';
import {MENUBAR_HEIGHT} from 'containers/menubar/menubar-constants';
import styled from 'styled-components';

export const SI_MAX_WIDTH = 1600;

export const CenterContent = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
`;

export const SIPageContainer = styled.div`
  min-height: 0; /* this is important for making flex-grow work */
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  background-color: ${({theme}) => theme.color.surface.secondary};
  width: 100%;
  max-width: ${SI_MAX_WIDTH}px;
  margin: auto;
`;

interface SIPageTitleProps {
  noPadding?: boolean;
  noMargin?: boolean;
}

export const SIPageTitle = styled.h1<SIPageTitleProps>`
  font-size: 34px;
  font-weight: 700;
  color: ${({theme}) => theme.color.text.main};
  margin-bottom: ${props => (props.noMargin ? '0px' : '16px')};
  padding-top: ${props => (props.noPadding ? '0px' : '32px')};
`;

export const SISectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0px 16px 8px 16px;
  flex-grow: 1;
`;

export const SIHeaderColor = styled.div<{headerColor?: string}>`
  position: absolute;
  top: -1px;
  left: -1px;
  bottom: -1px;
  display: inline-block;
  width: 8px;
  border-radius: 4px 0px 0px 4px;
  border-left: 8px solid #9fd8dc;
  border-color: ${({theme}) => theme.color.stroke.strong};
  border-left-color: ${({headerColor}) => headerColor ?? '#9fd8dc'};
`;

export const SIStyledStickyHeader = styled(Sticky)`
  background-color: ${({theme}) => theme.color.surface.secondary};
  max-width: ${SI_MAX_WIDTH}px;
  margin: 0;
  padding: 0px 20px 8px 20px;
  && {
    top: ${MENUBAR_HEIGHT}px;
  }
`;

export const FlexGridRow = styled.div`
  display: flex;
  align-items: stretch;
  flex-wrap: nowrap;
`;

export const FlexGridItem = styled.div`
  display: flex;
  flex-grow: 1;
`;
