// @ts-nocheck
import {yupResolver} from '@hookform/resolvers/yup';
import cn from 'classnames';
import FluroAutocomplete from 'components/autocomplete';
import {Col} from 'components/flex/col';
import {Flex} from 'components/flex/flex';
import {Text} from 'components/text/text';
import {reportError} from 'containers/error-boundary';
import {selectUser} from 'containers/login/login-selectors';
import {langInfo, t} from 'i18n-utils';
import keyBy from 'lodash/keyBy';
import type {ComponentType} from 'react';
import React from 'react';
import {Controller, useForm} from 'react-hook-form';
import IntlTelInput from 'react-intl-tel-input';
import {SelectField, SelectionControlGroup, TextField} from 'react-md';
import Yup from 'yup';
import type {Timezone} from '_constants/timezones';
import timezones from '_constants/timezones.json';
import {useAppDispatch, useAppSelector} from '_hooks';
import {formats} from '_utils';
import {safeLocalStorage} from '_utils/safe-local-storage';
import {checkAuth, updateUserProfile} from '../login/actions';
import type {User} from '../admin/users/types';
import {FluroButton} from 'components';
import {getCountries, getStates, getFilteredCountries} from 'country-state-picker';
import {selectIsSI} from 'modules/global/selectors';

type Props = {};

type FormData = {
  city: string;
  country: string;
  langLocale: string;
  locale: string;
  measurement: string;
  name: string;
  phone: string;
  phoneFullNumber: string;
  showDemoFarms: boolean;
  state: string;
  street: string;
  surname: string;
  timezone: string;
  _validPhone: boolean;
  zip: string;
};

type FormValues = {
  timezone: Timezone['id'];
  measurement: User['settings']['measurement'];
  locale: User['settings']['locale'];
  langLocale: User['settings']['langLocale'];
  phone: User['phone'];
  phoneFullNumber?: string;
  name: User['name'];
  surname: User['surname'];
  country: User['settings']['company']['country'];
  street: User['settings']['company']['street'];
  state: User['settings']['company']['state'];
  city: User['settings']['company']['city'];
  zip: User['settings']['company']['zip'];
  user: User;
  _validPhone: boolean;
  showDemoFarms: User['settings']['showDemoFarms'];
};

const formatOptions = Object.keys(formats).map((el, i) => ({
  value: el,
  label: Object.values(formats)[i],
}));
const timezonesMap = keyBy(timezones, 'id');
const formValidationSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, t({id: 'minimum length {value}'}, {value: 2}))
    .max(64, t({id: 'maximum length {value}'}, {value: 64}))
    .required(t({id: 'First name is a required field'})),
  surname: Yup.string()
    .min(2, t({id: 'minimum length {value}'}, {value: 2}))
    .max(64, t({id: 'maximum length {value}'}, {value: 64}))
    .required(t({id: 'Surname is a required field'})),
  phone: Yup.string().test('phone', '', function () {
    return this.parent._validPhone
      ? true
      : this.createError({path: this.path, message: t({id: 'Invalid phone number'})});
  }),
  country: Yup.string().max(100, 'Max length of country is 100').required(),
  street: Yup.string()
    .min(3, 'Street is required (min 3 characters)')
    .max(100, 'Max length of address is 100')
    .required(),
  city: Yup.string().max(100, 'Max length of city is 100').required(),
  zip: Yup.string()
    .min(3, 'Zip required (min 3 characters)')
    .max(100, 'Max length of zip is 100')
    .required(),
  state: Yup.string().min(3, 'Region is required').required('Region is required'),
});

// SI does not require address fields
const formValidationSISchema = Yup.object().shape({
  name: Yup.string()
    .min(2, t({id: 'minimum length {value}'}, {value: 2}))
    .max(64, t({id: 'maximum length {value}'}, {value: 64}))
    .required(t({id: 'First name is a required field'})),
  surname: Yup.string()
    .min(2, t({id: 'minimum length {value}'}, {value: 2}))
    .max(64, t({id: 'maximum length {value}'}, {value: 64}))
    .required(t({id: 'Surname is a required field'})),
  phone: Yup.string().test('phone', '', function () {
    return this.parent._validPhone
      ? true
      : this.createError({path: this.path, message: t({id: 'Invalid phone number'})});
  }),
  country: Yup.string().max(100, 'Max length of country is 100'),
  street: Yup.string().max(100, 'Max length of address is 100'),
  city: Yup.string().max(100, 'Max length of city is 100'),
  zip: Yup.string().max(100, 'Max length of zip is 100'),
  state: Yup.string(),
});

export const ProfileCommon: ComponentType<Props> = ({}) => {
  const dispatch = useAppDispatch();
  const user = useAppSelector(selectUser);
  const isSI = useAppSelector(selectIsSI);

  const defaultValues: FormValues = {
    locale: !formats[user.settings.locale] ? Object.keys(formats)[0] : user.settings.locale,
    langLocale: user.settings.langLocale,
    measurement: user.settings.measurement,
    timezone: user.settings.timezone,
    phone: user.phone,
    name: user.name,
    surname: user.surname,
    country: user.settings.company.country,
    street: user.settings.company.street,
    state: user.settings.company.state,
    city: user.settings.company.city,
    zip: user.settings.company.zip,
    user: user,
    _validPhone: true,
    showDemoFarms: user.settings.showDemoFarms,
  };
  const {
    handleSubmit,
    control,
    setValue,
    formState: {errors},
    watch,
  } = useForm<FormData>({
    resolver: isSI ? yupResolver(formValidationSISchema) : yupResolver(formValidationSchema),
    defaultValues,
  });

  const submitProfileForm = async (values: FormValues) => {
    if (values.phoneFullNumber) values.phone = values.phoneFullNumber.replace(/\s/g, '');

    try {
      await dispatch(
        updateUserProfile({
          ...user.settings,
          ...values,
          company: {
            ...user.settings.company,
            country: values.country,
            street: values.street,
            state: values.state,
            city: values.city,
            zip: values.zip,
          },
        })
      );

      dispatch(checkAuth());
      safeLocalStorage.setItem('lang', values.langLocale);
    } catch (error) {
      reportError(error);
    }
  };
  const values = watch();
  const countriesList = getCountries().map(({name}: {name: string}) => ({
    label: name,
    value: name,
  }));

  const onSetValue = (prop: keyof FormData, value: any) => {
    if (prop === 'country') {
      setValue('state', '', {shouldValidate: false});
    }
    setValue(prop, value, {shouldValidate: true});
  };

  const onPhoneNumberChange = (
    isValidPhoneNumber: boolean,
    value: string,
    params: any,
    fullValue: string
  ) => {
    setValue('_validPhone', !value ? true : isValidPhoneNumber);
    setValue('phoneFullNumber', fullValue);
    onSetValue('phone', value);
  };

  const selectedCountry = getFilteredCountries([values.country])?.[0];
  const currentCountryStates = getStates(selectedCountry?.code) || [];

  return (
    <form onSubmit={handleSubmit(submitProfileForm)}>
      <div>
        <Text elementType="h2">{t({id: 'General'})}</Text>
        <Flex gap="16px 32px" className="margin-bottom-20">
          <Col basis="calc(50% - 16px)">
            <TextField
              id="user-name"
              name="name"
              value={values.name}
              label={t({id: 'First name'})}
              error={!!errors?.name}
              errorText={errors?.name?.message}
              onChange={(value: string) => onSetValue('name', value)}
            />
          </Col>

          <Col basis="calc(50% - 16px)">
            <TextField
              name="surname"
              value={values.surname}
              id="user-surname"
              label={t({id: 'Surname'})}
              error={!!errors?.surname}
              errorText={errors?.surname?.message}
              onChange={(value: string) => onSetValue('surname', value)}
            />
          </Col>

          <Col basis="calc(50% - 16px)">
            <TextField
              id="user-email-readonly"
              label={t({id: 'Email'})}
              value={user.email}
              // This props applied successfully,
              // despite the react-md doesn't have it in type declarations
              // @ts-expect-error error leftover from convertion to strict mode, please fix
              readOnly
            />
          </Col>

          <Col basis="calc(50% - 16px)">
            <div className={cn('user-phone', {error: errors?.phone?.message && values.phone})}>
              <div>
                <Text
                  secondary
                  variant="small"
                  elementType="label"
                  className={cn('user-phone__label', {
                    error: errors?.phone?.message && values.phone,
                  })}
                >
                  {t({id: 'Phone number'})}
                </Text>
              </div>
              <IntlTelInput
                inputClassName="phone-tel-input"
                preferredCountries={['us', 'au']}
                defaultCountry={selectedCountry?.code || 'us'}
                onPhoneNumberChange={onPhoneNumberChange}
                autoHideDialCode
                dropdownContainer="div"
                value={values?.phone}
                nationalMode={false}
              />
              {errors?.phone ? (
                <div className="md-text md-text--error">{errors.phone?.message}</div>
              ) : null}
            </div>
          </Col>
        </Flex>

        <Text elementType="h2">{t({id: 'Address'})}</Text>
        <Flex gap="16px 32px" className="margin-bottom-20">
          <Col basis="calc(50% - 16px)">
            <FluroAutocomplete
              id="user-country"
              label={t({id: 'Country'})}
              title={t({id: 'Country'})}
              menuItems={countriesList}
              onAutocomplete={(value: string) => onSetValue('country', value)}
              value={values?.country}
              className="select-element"
              error={!!errors.country}
            />

            {errors?.country ? (
              <div className="md-text md-text--error">{errors?.country?.message}</div>
            ) : null}
          </Col>

          <Col basis="calc(50% - 16px)">
            {currentCountryStates.length ? (
              <FluroAutocomplete
                id="user-region"
                label={t({id: 'Region'})}
                title={t({id: 'Region'})}
                onAutocomplete={(value: string) => onSetValue('state', value)}
                placeholder={'Region'}
                menuItems={currentCountryStates}
                value={values.state}
                error={!!errors.state}
                errorText={errors.state?.message}
              />
            ) : (
              <TextField
                id="user-region"
                label={t({id: 'Region'})}
                value={values.state}
                onChange={(value: string) => onSetValue('state', value)}
                error={!!errors.state}
                errorText={errors.state?.message}
              />
            )}
          </Col>

          <Col basis="calc(50% - 16px)">
            <TextField
              name="city"
              value={values.city}
              id="user-city"
              label={t({id: 'Reg.City'})}
              error={!!errors?.city}
              errorText={errors?.city?.message}
              onChange={(value: string) => onSetValue('city', value)}
            />
          </Col>

          <Col basis="calc(50% - 16px)">
            <TextField
              name="street"
              id="user-street"
              label={t({id: 'Reg.Street'})}
              value={values.street}
              error={!!errors?.street}
              errorText={errors?.street?.message}
              onChange={(value: string) => onSetValue('street', value)}
            />
          </Col>

          <Col basis="calc(50% - 16px)">
            <TextField
              name="zip"
              id="user-zip"
              label={t({id: 'Reg.Zip'})}
              value={values.zip}
              error={!!errors?.zip}
              errorText={errors?.zip?.message}
              onChange={(value: string) => onSetValue('zip', value)}
            />
          </Col>
        </Flex>

        <Text elementType="h2">{t({id: 'Preferences'})}</Text>

        <Flex gap="20px 32px">
          <Col basis="calc(50% - 16px)">
            <Controller
              control={control}
              name="langLocale"
              render={({field}) => (
                <SelectField
                  id="lang-select"
                  className="select-element"
                  label={t({id: 'Language'})}
                  menuItems={langInfo}
                  simplifiedMenu={true}
                  {...field}
                />
              )}
            />
          </Col>

          <Col basis="calc(50% - 16px)">
            <Controller
              name="timezone"
              control={control}
              render={renderProps => {
                const {
                  field: {value, onChange},
                } = renderProps;
                const timezone = timezonesMap[value];

                return (
                  <FluroAutocomplete
                    id="timezone-field"
                    label={t({id: 'Timezone'})}
                    title={timezone?._label || ''}
                    menuItems={timezones}
                    dataLabel={'text'}
                    dataValue={'id'}
                    onAutocomplete={onChange}
                    value={timezonesMap[value].text}
                    className="select-element"
                  />
                );
              }}
            ></Controller>
          </Col>
          <Col basis="calc(50% - 16px)">
            <Controller
              name="locale"
              control={control}
              render={({field}) => (
                <SelectField
                  id="date-format-select-field"
                  className="select-element"
                  label={t({id: 'Date format'})}
                  menuItems={formatOptions}
                  simplifiedMenu
                  {...field}
                />
              )}
            />
          </Col>
          <Col basis="calc(50% - 16px)">
            <Controller
              name="measurement"
              control={control}
              render={({field}) => (
                <SelectionControlGroup
                  id="selection-control-group-radios"
                  name="radio"
                  type="radio"
                  className={'select-units'}
                  label={
                    <Text variant="small" elementType="label" secondary>
                      {t({id: 'Measurement units'})}
                    </Text>
                  }
                  inline
                  defaultValue={null}
                  value={field.value}
                  onChange={value => {
                    setValue('measurement', value);
                    if (value === 'ac') {
                      setValue('locale', 'en-US');
                    } else if (value === 'ha') {
                      setValue('locale', 'uk-UA');
                    }
                  }}
                  controls={[
                    {
                      label: t({id: 'Metric Units'}),
                      value: 'ha',
                    },
                    {
                      label: t({id: 'U.S. Units'}),
                      value: 'ac',
                    },
                  ]}
                />
              )}
            />
          </Col>
        </Flex>
      </div>

      <Flex justifyContent="flex-end" className="padding-top-20">
        <FluroButton type="submit" raised primary>
          {t({id: 'Save'})}
        </FluroButton>
      </Flex>
    </form>
  );
};
