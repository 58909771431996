import type {ComponentType} from 'react';
import React, {memo} from 'react';
import type {AttributeValue} from 'containers/mrv/types';
import {MRVAttributeType} from 'containers/mrv/types';
import {InputBoolean} from './input-boolean';
import {InputCalendar} from './input-calendar';
import {Provider} from './input-context';
import {InputSelect} from './input-select';
import {InputText} from './input-text';
import {CropSelect} from './crop-select';
import {ResidueHarvestedSelect} from './residue-harvested-select';
import {DepthSelect} from './depth-select';
import {TillagePeriodSelect} from './tillage-period-select';
import {TillageSelect} from './tillage-select';
import type {BaseInputProps} from './types';
import {InputNumber} from './input-number';
import {ProductSelect} from 'containers/mrv/monitoring/form/inputs/product-select';
import {InputYear} from './input-year';
import {InputFarmNumber} from './input-farm-number';
import {useAppSelector} from '_hooks';
import {selectMeasurement} from 'containers/login/login-selectors';
import {
  irrigationRateUnitOptions,
  nutrientManagementAppRateUnitOptions,
  yieldRateUnitOptions,
} from 'containers/mrv/monitoring/form/inputs/constants';
import './inputs.scss';
import {t} from 'i18n-utils';
import {capitalizeFirstLetter, toTitleCase} from '_utils/pure-utils';
import {selectCurrentProgramId} from '../../module/selectors';
import {
  getAppRateUnitOptsOrDefaults,
  getIrrigationRateUnitOptsOrDefaults,
} from './hacky-rate-unit-opts';
import {attributeOptionsToListItems} from 'containers/mrv/base/base';

export type InputComponentProps = {
  type: MRVAttributeType;
  onChange: (
    value: any,
    /**
     * Should save the value to the server or just update the draft.
     * Pass false for user typing inputs.
     */
    shouldSave?: boolean,
    /**
     * Additional values that will be changed.
     */
    attributeValues?: AttributeValue[]
  ) => void;
} & Omit<BaseInputProps, 'onChange'>;

export const InputComponent: ComponentType<InputComponentProps> = props => {
  const {onChange, ...inputProps} = props;
  const {type, name, options} = inputProps;

  return (
    <Provider value={inputProps}>
      <InputComponentInner name={name} type={type} options={options} onChange={onChange} />
    </Provider>
  );
};

export const InputComponentMemo = memo(InputComponent);

export type InputComponentInnerProps = ComponentType<{
  type: MRVAttributeType;
  name: string;
  options?: InputComponentProps['options'];
  onChange: InputComponentProps['onChange'];
}>;

const InputComponentInner: InputComponentInnerProps = ({type, options, onChange}) => {
  const measurement = useAppSelector(selectMeasurement);
  const programId = useAppSelector(selectCurrentProgramId);
  const isMetric = measurement === 'ha';

  switch (type) {
    case MRVAttributeType.FallTillageDepth:
    case MRVAttributeType.SpringTillageDepth:
    case MRVAttributeType.TillageDepth:
      return (
        <DepthSelect
          testId="MRV-table-cell-input-select--Tillage depth"
          title={t({
            id: 'MRVAttributeType.tillage_depth',
            defaultMessage: 'Tillage Depth',
          })}
          onChange={onChange}
        />
      );

    case MRVAttributeType.NutrientManagementAppDepth:
      return (
        <DepthSelect
          title={t({
            id: 'MRVAttributeType.application_depth',
            defaultMessage: 'Application Depth',
          })}
          onChange={onChange}
        />
      );

    case MRVAttributeType.TillagePeriod:
      return <TillagePeriodSelect onChange={onChange} />;

    case MRVAttributeType.SpringTillagePractice:
    case MRVAttributeType.TillagePractice:
    case MRVAttributeType.FallTillagePractice:
      return (
        <TillageSelect testId="MRV-table-cell-input-select--Tillage practice" onChange={onChange} />
      );

    case MRVAttributeType.SummerResidueHarvested:
    case MRVAttributeType.WinterResidueHarvested:
    case MRVAttributeType.ResidueHarvested:
      return <ResidueHarvestedSelect onChange={onChange} />;

    case MRVAttributeType.SummerCropType:
    case MRVAttributeType.WinterCropType:
    case MRVAttributeType.CropType:
      return <CropSelect onChange={onChange} />;

    case MRVAttributeType.NutrientManagementAppProduct:
      return <ProductSelect onChange={onChange} />;

    case MRVAttributeType.SummerHarvestDate:
    case MRVAttributeType.WinterPlantingDate:
    case MRVAttributeType.PlantingDate:
    case MRVAttributeType.HarvestDate:
    case MRVAttributeType.TillageDate:
    case MRVAttributeType.StartDate:
    case MRVAttributeType.EndDate:
      return <InputCalendar onChange={onChange} />;

    case MRVAttributeType.SummerPlantingDate:
    case MRVAttributeType.WinterHarvestDate:
    case MRVAttributeType.FallTillageDate:
    case MRVAttributeType.SpringTillageDate:
    case MRVAttributeType.NutrientManagementAppDate:
    case MRVAttributeType.Date:
      return <InputCalendar onChange={onChange} />;

    case MRVAttributeType.WinterCropCommitment:
    case MRVAttributeType.Bool:
    case MRVAttributeType.SoilInversion:
    case MRVAttributeType.IrrigationEnabled:
    case MRVAttributeType.NutrientManagementEnabled:
      return <InputBoolean onChange={onChange} />;

    case MRVAttributeType.ResidueBurnt:
      return <InputBoolean onChange={onChange} getLabel={v => (v ? 'Burnt' : 'Unburnt')} />;

    case MRVAttributeType.Number:
    case MRVAttributeType.CropYield:
    case MRVAttributeType.SummerDryYield:
    case MRVAttributeType.WinterDryYield:
    case MRVAttributeType.NutrientManagementAppRate:
    case MRVAttributeType.NutrientManagementAppArea:
    case MRVAttributeType.HeadCount:
    case MRVAttributeType.AreaTilledForPastureRenewalPct:
    case MRVAttributeType.AreaTilledTotalPct:
    case MRVAttributeType.LandscapeModificationsFuelUsage:
    case MRVAttributeType.IrrigationFuelUsage:
    case MRVAttributeType.IrrigationElectricityUsage:
    case MRVAttributeType.TotalEnergyUsage:
    case MRVAttributeType.TotalFuelUsage:
    case MRVAttributeType.TotalArea:
    case MRVAttributeType.IrrigationDepth:
      return <InputNumber onChange={onChange} totalDecimalPlaces={3} />;

    case MRVAttributeType.String:
    case MRVAttributeType.IrrigationElectricityGridName:
    case MRVAttributeType.FarmName:
    case MRVAttributeType.Other:
      return <InputText onChange={onChange} />;

    case MRVAttributeType.FarmNumber:
      return <InputFarmNumber onChange={onChange} />;

    case MRVAttributeType.RecordYear:
      return <InputYear onChange={onChange} />;

    case MRVAttributeType.NutrientManagementAppRateUnit: {
      const userDefinedOpts = isMetric
        ? nutrientManagementAppRateUnitOptions.metric
        : nutrientManagementAppRateUnitOptions.imperial;
      const hackedOpts = getAppRateUnitOptsOrDefaults(programId, userDefinedOpts);
      return (
        <InputSelect
          onChange={onChange}
          options={
            // TODO options should be sent from the server. Server isn't currently aware of users measurement setting.
            hackedOpts
          }
        />
      );
    }

    case MRVAttributeType.YieldRateUnit:
      return (
        <InputSelect
          testId={`MRV-table-cell-input-select--Yield unit`}
          onChange={onChange}
          options={isMetric ? yieldRateUnitOptions.metric : yieldRateUnitOptions.imperial}
        />
      );

    case MRVAttributeType.IrrigationMethod:
    case MRVAttributeType.NutrientManagementAppMethod:
    case MRVAttributeType.PlantingSeason:
    case MRVAttributeType.CoverCropMix:
      return (
        <InputSelect
          testId={`MRV-table-cell-input-select--${toTitleCase(type)}`}
          onChange={onChange}
          options={
            options?.map(item => ({
              value: item,
              label: capitalizeFirstLetter(
                t({
                  id: `Option.${item}`,
                  defaultMessage: typeof item === 'string' ? item : '',
                })
              ),
            })) || []
          }
        />
      );

    case MRVAttributeType.IrrigationRateUnit: {
      // TODO options should be sent from the server. Server isn't currently aware of users measurement setting.
      const userDefinedOpts = isMetric
        ? irrigationRateUnitOptions.metric
        : irrigationRateUnitOptions.imperial;
      const hackedOpts = getIrrigationRateUnitOptsOrDefaults(programId, userDefinedOpts);
      return <InputSelect onChange={onChange} options={hackedOpts} />;
    }

    case MRVAttributeType.LivestockClass:
      if (!options) return null;
      return <InputSelect options={attributeOptionsToListItems(options)} onChange={onChange} />;
  }

  if (options?.length) {
    return <InputSelect onChange={onChange} />;
  }

  return null;
};
