type Nil = null | undefined;
type Falsy = false | '' | 0 | null | undefined;
type Truthy<T> = T extends Falsy ? never : T;

/**
 * Tests whether or not an argument is null (type guard)
 */
export const isNull = (x: unknown): x is null => x === null;

/**
 * Tests whether or not an argument is undefined (type guard)
 */
export const isUndefined = (x: unknown): x is undefined => x === undefined;

/**
 * Tests whether or not an argument is null or undefined (type guard)
 */
export const isNil = (x: unknown): x is Nil => isNull(x) || isUndefined(x);

/**
 * Tests whether or not an argument is null or undefined (type guard)
 */
export const isDefined = <T>(x: T | Nil): x is NonNullable<T> => !isNil(x);

export const isBoolean = (x: unknown): x is boolean => x === true || x === false;

/**
 * Type guard for the `false` literal of the `boolean` primitive
 */
const isFalse = (x: unknown): x is false => typeof x === 'boolean' && x === false;

/**
 * Type guard for the `0` literal of the `number` primitive
 */
const isZero = (x: unknown): x is 0 => isNumber(x) && x === 0;

/**
 * Type guard for the Falsy type
 */
export const isFalsy = (x: unknown): x is Falsy =>
  isNil(x) || isFalse(x) || isEmptyString(x) || isZero(x) || (isNumber(x) && Number.isNaN(x));

/**
 * Type guard for the Truthy type
 */
export const isTruthy = <T>(x: T): x is Truthy<T> => !isFalsy(x);

/**
 * Type guard for the `string` primitive
 */
export const isString = (x: unknown): x is string => typeof x === 'string';

/**
 *
 * @param x Type guard for not the `string` primitive
 */
export const isNotString = <T>(x: T): x is Exclude<T, string> => typeof x !== 'string';

/**
 * Type guard for the `''` literal of the `string` primitive
 */
export const isEmptyString = (x: unknown): x is '' => isString(x) && x === '';

/**
 * Type guard for `string` primitives that are not `''`
 */
export const isNonEmptyString = (x: unknown): x is string => isString(x) && !isEmptyString(x);

/**
 * Type guard for the `number` primitive
 */
export const isNumber = (x: unknown): x is number => typeof x === 'number';

/**
 * The opposite of isNaN (built-in)
 */
export const isNotNaN = (x: number): x is number => isNumber(x) && !isNaN(x);

/**
 * Type guard for the `object` type
 */
export const isObject = (x: unknown): x is object =>
  !isNil(x) && typeof x === 'object' && x instanceof Object;

/**
 * Type guard for records. Functionally the same as `isObject` but the guarded
 * type has some subtle differences.
 */
export const isRecord = (x: unknown): x is Readonly<Record<PropertyKey, unknown>> => isObject(x);

/**
 * Type guard for the `Array` type
 */
export const isArray = <T>(as: T): as is T & ReadonlyArray<unknown> => Array.isArray(as);

/**
 * Type guard for the `Array` type with `.length === 0`
 */
export const isEmptyArray = <T>(as: Array<T> | unknown): as is Array<T> =>
  isArray(as) && as.length === 0;

/**
 * Type guard for the `Array` type with `.length > 0`
 */
export const isNonEmptyArray = <T>(as: Array<T> | unknown): as is Array<T> =>
  isArray(as) && as.length > 0;

/**
 * Type guard to check for an instance of Error
 */
export const isError = (a: unknown): a is Error => a instanceof Error;
