import React, {useEffect, useMemo, useRef, useState} from 'react';
import {useAppDispatch, useAppSelector} from '_hooks';
import {kiloFormatter} from '_utils/number-formatters';
import {Flex, Text} from 'components';
import {centerOfMass, convertArea} from '@turf/turf';
import {t} from 'i18n-utils';
import {selectMeasurement} from 'containers/login/login-selectors';
import {selectIsSucceed} from 'modules/helpers/selectors';
import {
  selectCurrentProgram,
  selectSIVisibleSubsectionArea,
  selectSIHaveAllVisibleSubsectionByYear,
  selectSIYearsFilter,
  selectSIVisibleSubsectionIds,
} from 'containers/si/module/selectors';
import {fetchKPISubsectionArea} from 'containers/si/module/thunks';
import {ActionType} from 'containers/si/module/types';
import {toggleSISubsectionVisibility} from 'containers/si/module/reducer';
import {clickableFeaturesFillLayer, programGeometriesLayer} from 'containers/si/map/map-style';
import {generateFeatureCollection} from 'containers/si/module/base';
import {CircularProgress} from 'react-md';
import type {MapRef} from 'react-map-gl';
import {Source, Layer} from 'react-map-gl';
import {SIBaseMap} from 'containers/si/map/map';
import type {FeatureCollection} from 'geojson';
import {useFetchGeometries} from 'containers/si/hooks/useFetchGeometries';
import {CenterContent} from 'containers/si/si-styled-components';

const kpiMapStyle: React.CSSProperties = {
  width: '100%',
  height: '100%',
  position: 'relative',
};

export const KPIsMap = () => {
  const mapRef = useRef<MapRef>();
  const dispatch = useAppDispatch();
  const currentProgram = useAppSelector(selectCurrentProgram);
  const visibleSubsectionIds = useAppSelector(selectSIVisibleSubsectionIds);
  const yearsFilter = useAppSelector(selectSIYearsFilter);
  const visibleSubsectionHa = useAppSelector(s => selectSIVisibleSubsectionArea(s, yearsFilter[0]));
  const haveAreas = useAppSelector(s => selectSIHaveAllVisibleSubsectionByYear(s, yearsFilter[0]));
  const measurement = useAppSelector(selectMeasurement);
  const acresFetched = useAppSelector(s =>
    selectIsSucceed(s, [ActionType.FETCH_KPI_SUBSECTION_AREA, ActionType.FETCH_SI_SUPPLY_SHEDS])
  );

  const [filteredGeometries, setFilteredGeometries] = useState<FeatureCollection | null>(null);

  const {isLoading, currentProgramGeometries, allGeometryFeatures} =
    useFetchGeometries('sub-section');

  // Area comes from API in ac, convert if needed
  const regionArea = useMemo(() => {
    if (!visibleSubsectionHa) return 0;
    return kiloFormatter(
      measurement === 'ac'
        ? convertArea(visibleSubsectionHa, 'hectares', 'acres')
        : visibleSubsectionHa
    );
  }, [visibleSubsectionHa, measurement]);

  useEffect(() => {
    // Generate the filtered geometries to display selected Supply Sheds on the map
    const loadGeometry = generateFeatureCollection({
      geometries: currentProgramGeometries,
      idsToInclude: visibleSubsectionIds,
      type: 'sub-section',
    });
    setFilteredGeometries(loadGeometry);
  }, [currentProgramGeometries, visibleSubsectionIds]);

  useEffect(() => {
    // Center the map on the Filtered Supply Sheds
    if (filteredGeometries && filteredGeometries.features.length > 0) {
      const center = centerOfMass(filteredGeometries);
      mapRef &&
        mapRef.current?.flyTo({
          center: [center.geometry.coordinates[0], center.geometry.coordinates[1]],
          zoom: 3,
        });
    }
  }, [filteredGeometries]);

  useEffect(() => {
    if (!currentProgram) return;
    if (yearsFilter.length === 0) return;
    if (haveAreas) return;

    dispatch(fetchKPISubsectionArea({programId: currentProgram, year: yearsFilter[0]}));
  }, [currentProgram, dispatch, haveAreas, yearsFilter]);

  const handleLayerClick = (evt: mapboxgl.MapLayerMouseEvent) => {
    if (evt.features && evt.features.length) {
      const feature = evt.features[0];
      const subsectionId = feature.properties?.id;
      dispatch(toggleSISubsectionVisibility(Number(subsectionId)));
    }
  };

  return (
    <div className="map-container">
      <Flex className="margin-8" alignItems="center" justifyContent="space-between">
        <Flex>
          <Text className="mb-0" variant="h3" bold>
            {t({id: 'Sourcing regions'})}:{' '}
          </Text>
          {acresFetched ? (
            <Text className="mb-0 margin-left-5" variant="h3">
              {regionArea} {measurement}
            </Text>
          ) : (
            <CircularProgress id="kpi-map-loading" scale={0.5} className={'m-0'} />
          )}
        </Flex>
      </Flex>
      <div className={'kpi-map'}>
        {isLoading ? (
          <CenterContent>
            <CircularProgress id="loading-map" />
          </CenterContent>
        ) : (
          <SIBaseMap
            mapRef={mapRef}
            styleSelectors={false}
            style={kpiMapStyle}
            overrideMapStyle={'light'}
            onClick={handleLayerClick}
            interactiveLayerIds={['clickable-features-fill', 'project-geometries-fill']}
          >
            {/* This layer is a background layer with ALL Supply Sheds */}
            {allGeometryFeatures && (
              <Source id="kpi-geometries" type={'geojson'} data={allGeometryFeatures}>
                <Layer {...clickableFeaturesFillLayer} />
              </Source>
            )}
            {/* This is the clickable layer to turn on and off Supply Sheds */}
            {filteredGeometries && (
              <Source id="kpi-clickable-geometries" type={'geojson'} data={filteredGeometries}>
                <Layer {...programGeometriesLayer} />
              </Source>
            )}
          </SIBaseMap>
        )}
      </div>
    </div>
  );
};
