import React, {useState} from 'react';
import {FluroButton, FluroDialog, InfoBlock, UploadFileZone} from 'components';
import {startDrawShape} from '../map/actions';
import {uploadAnomalies} from '../map/actions/anomalies-actions';
import {useAppDispatch} from '_hooks';
import {dialogToggle, DialogType} from 'modules/helpers';
import {useTranslation} from 'i18n-utils';

const AddNewAnomalies = () => {
  const [isUpload, setUpload] = useState(false);
  const dispatch = useAppDispatch();
  const t = useTranslation();

  const onHide = () => dispatch(dialogToggle(DialogType.addNewAnomaly, false));
  const onDrawNew = () => {
    onHide();
    dispatch(startDrawShape());
  };
  const onUpload = (files: File[]) => {
    dispatch(uploadAnomalies(files));
  };
  return (
    <FluroDialog
      id={'add-new-anomalies'}
      title={t({id: 'Add a new shape', defaultMessage: 'Add a new shape'})}
      visible={true}
      onHide={onHide}
      portal
      className={'add-new-anomalies'}
    >
      {isUpload ? (
        <div className="upload-new-anomalies-container">
          <UploadFileZone
            id={'map-upload-kml'}
            accept={'.kml,.kmz,.zip'}
            multiple
            onChange={files => onUpload(files as File[])}
          />
          <InfoBlock className={'inside-a-pop-up'}>
            <div>
              {t({
                id: 'You can upload .kml, .kmz or .shp files.',
                defaultMessage: 'You can upload .kml, .kmz or .shp files.',
              })}
            </div>
            <div>
              {t({
                id: 'If you are uploading .shp files, zip archive them before sending and make sure the archive contains the following files extensions: .shp, .shx and .dbf.',
                defaultMessage:
                  'If you are uploading .shp files, zip archive them before sending and make sure the archive contains the following files extensions: .shp, .shx and .dbf.',
              })}
            </div>
          </InfoBlock>
          <FluroButton className={'back-button'} raised onClick={() => setUpload(false)}>
            {t({id: 'Back', defaultMessage: 'Back'})}
          </FluroButton>
        </div>
      ) : (
        <div className="add-new-anomalies-container">
          <FluroButton
            id={'draw-new-geometry-btn'}
            primary
            raised
            iconBefore={false}
            onClick={onDrawNew}
          >
            {t({id: 'Draw a new shape', defaultMessage: 'Draw a new shape'})}
          </FluroButton>
          <div className={'buttons-delimiter'}> {t({id: 'Or', defaultMessage: 'Or'})} </div>
          <FluroButton
            id={'upload-new-geometry-btn'}
            raised
            iconBefore={false}
            onClick={() => setUpload(true)}
          >
            {t({id: 'Upload a shape', defaultMessage: 'Upload a shape'})}
          </FluroButton>
        </div>
      )}
    </FluroDialog>
  );
};

export default AddNewAnomalies;
