import React, {useCallback} from 'react';
import {FontIcon} from 'react-md';

import {setId} from './reducer';
import {useAppDispatch, useAppSelector} from '_hooks';

type Props = {
  id: string;
  title?: string;
  className?: string;
  icon?: string;
};

const HelpBtn = ({id, title = '', className = '', icon}: Props) => {
  const dispatch = useAppDispatch();
  const currentPopUpId = useAppSelector(s => s.helpPopup.currentPopUpId);

  const onSetId = useCallback(() => {
    dispatch(setId(id === currentPopUpId ? '' : id));
  }, [currentPopUpId, id]);

  return (
    <FontIcon
      //@ts-expect-error error leftover from convertion to strict mode, please fix
      title={title}
      className={`help-popup__btn ${className}`}
      onClick={onSetId}
    >
      {icon ? icon : 'help_outline'}
    </FontIcon>
  );
};

export default HelpBtn;
