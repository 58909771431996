import React from 'react';
import {Avatar} from 'react-md';
import cn from 'classnames';
import {getCropSrcServer} from '_utils/pure-utils';
import './index.scss';

type CropAvatarLiteProps = {
  className?: string;
  cropType: string;
  iconSrc?: string;
  icon?: React.ReactNode;
  letter?: string;
};

/**
 * The difference from CropAvatar is that Lite doesn't use hackGetState to get the crop first char.
 *
 * Can be used in storybook.
 *
 * ```
 * <CropAvatarLite
 *   cropType="cotton"
 *   iconSrc="/assets/crops/cotton.png"
 *   letter="c"
 *  />
 * ```
 */
export const CropAvatarLite = ({
  className,
  cropType,
  iconSrc,
  letter,
  icon,
}: CropAvatarLiteProps) => {
  return (
    <Avatar
      className={cn(`fluro-crop-avatar`, className, {
        bordered: !iconSrc && !icon,
      })}
      icon={icon}
      suffix="white"
      src={iconSrc || getCropSrcServer(cropType)}
    >
      {letter}
    </Avatar>
  );
};
