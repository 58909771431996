import {t} from 'i18n-utils';
import React, {useEffect} from 'react';
import {SelectField, TextField} from 'react-md';
import {useForm} from 'react-hook-form';
import {
  FluroDatePicker,
  FluroDialog,
  FluroAutocomplete,
  InfoBlock,
  Flex,
  FluroButton,
} from 'components';
import Yup from 'yup';
import type {Moment} from 'moment';
import moment from 'moment';
import {GLOBAL_FORMAT_DATE} from '_constants';
import type {NRxSeason} from 'containers/map/features/nrx';
import {
  getPropertiesFromSeasons,
  isSetNrxSeasonSowingData,
  isSetNrxSeasonCropMatch,
  getPrevSeasonMaxHarvestDate,
  defaultSowingParams,
  NASSInfoIcon,
  isAutomaticSoil,
  NRxAutomaticSoilTypeId,
} from 'containers/map/features/nrx';
import type {MeasurementSystem} from '_utils';
import {formatUnit} from '_utils';
import {yupResolver} from '@hookform/resolvers/yup';
import {useAppDispatch, useAppSelector} from '_hooks';
import {setUpdateSeasonNrxData} from 'containers/map/actions/nitrogen-recommendation-actions';
import {selectMeasurement} from 'containers/login/login-selectors';
import {
  selectNRxListsData,
  selectNRxSoilDepthsRecordsBySoilLabel,
} from 'containers/map/features/nrx/nrx-selectors';

type Props = {
  togglePopUp(pop: string, val: boolean, seasonId?: number | null): void;
  selectedNRxSeasons: NRxSeason[];
};
const fieldIsRequiredString = t({id: 'Field is required'});
type Payload = {
  soilTypeLabelID: string;
  prevCropTypeID: number;
  soilTypeID: number;
  sowingDepth: number;
  sowingDensity: number;
  rowSpacing: number;
  prevHarvestDate: string;
  haySilage: number;
  irrigated: boolean;
  NASSPlantingParams: boolean;
};

const schema = (measurement: MeasurementSystem) => {
  const isMetricUnits = measurement === 'ha';
  const maxDepth = isMetricUnits ? 255 : 9.8;
  const maxDensity = isMetricUnits ? 600 : 2428200;
  const maxRowSpacing = isMetricUnits ? 1100 : 43.3;
  return Yup.object().shape({
    soilTypeLabelID: Yup.string().min(1, fieldIsRequiredString),
    sowingDepth: Yup.number()
      .typeError(fieldIsRequiredString)
      .min(1, `${t({id: 'The min value is'})} 1`)
      .max(maxDepth, `${t({id: 'The max value is'})} ${maxDepth} ${formatUnit(measurement, 'mm')}`),
    sowingDensity: Yup.number()
      .typeError(fieldIsRequiredString)
      .min(1, `${t({id: 'The min value is'})} 1`)
      .max(
        maxDensity,
        `${t({id: 'The max value is'})} ${maxDensity} ${formatUnit(measurement, 'plants/m²')}`
      ),
    rowSpacing: Yup.number()
      .typeError(fieldIsRequiredString)
      .min(1, `${t({id: 'The min value is'})} 1`)
      .max(
        maxRowSpacing,
        `${t({id: 'The max value is'})} ${maxRowSpacing} ${formatUnit(measurement, 'mm')}`
      ),
    haySilage: Yup.number().min(0, fieldIsRequiredString),
    prevCropTypeID: Yup.number().min(1, fieldIsRequiredString),
    prevHarvestDate: Yup.string().min(1, fieldIsRequiredString),
  });
};

const PlantingSoilParams = ({togglePopUp, selectedNRxSeasons}: Props) => {
  const dispatch = useAppDispatch();

  const measurement = useAppSelector(selectMeasurement);
  const {
    prevCropTypesList,
    silageHayList,
    soilTypesList,
    fieldsNearestSoilTypesList,
    irrigationList,
  } = useAppSelector(selectNRxListsData);
  const soilDepthListBySoilTypeLabel = useAppSelector(selectNRxSoilDepthsRecordsBySoilLabel);

  // Init consts
  const currentFieldId = selectedNRxSeasons.length === 1 ? selectedNRxSeasons[0]?.kmlID : 0;
  const preparedSoilTypeList = fieldsNearestSoilTypesList[currentFieldId] || soilTypesList; // specific list of soil type for Australian fields
  const seasonData = getPropertiesFromSeasons(selectedNRxSeasons);
  const defaultParams =
    !seasonData.sowingDepth && !seasonData.rowSpacing ? defaultSowingParams(5, measurement) : {};

  // ------           -------

  // Init form
  const {
    handleSubmit,
    setValue,
    register,
    watch,
    formState: {errors},
    reset,
    trigger,
  } = useForm<Payload>({
    mode: 'onChange',
    resolver: yupResolver(schema(measurement)),
    defaultValues: {
      soilTypeLabelID: seasonData.soilTypeLabelID,
      prevCropTypeID: seasonData.prevCropTypeID,
      soilTypeID: seasonData.soilTypeID,
      sowingDepth: seasonData.sowingDepth,
      sowingDensity: seasonData.sowingDensity,
      rowSpacing: seasonData.rowSpacing,
      haySilage: seasonData.haySilage,
      irrigated: seasonData.irrigated,
      NASSPlantingParams: seasonData.NASSPlantingParams,
      ...defaultParams,
      prevHarvestDate: seasonData.prevHarvestDate
        ? getPrevSeasonMaxHarvestDate(
            Array.isArray(seasonData.prevHarvestDate)
              ? seasonData.prevHarvestDate.find((date: string) => date)
              : seasonData.prevHarvestDate,
            true
          )
        : getPrevSeasonMaxHarvestDate(seasonData.startDate),
    },
  });
  useEffect(() => {
    register('soilTypeLabelID');
    register('prevCropTypeID');
    register('soilTypeID');
    register('sowingDepth');
    register('sowingDensity');
    register('rowSpacing');
    register('prevHarvestDate');
    register('haySilage');
    register('irrigated');
    register('NASSPlantingParams');
  }, [register]);

  const values = watch();

  // ------           -------

  // Calculated values
  const automaticSoilSelection = isAutomaticSoil(values.soilTypeLabelID);

  // ------           -------

  // Methods

  const onChange = async (prop: keyof Payload, value: number | string | boolean) => {
    setValue(prop, value);
    if (prop === 'soilTypeLabelID') {
      const soilTypeID = soilDepthListBySoilTypeLabel[String(value)]?.[0]?.value;
      setValue('soilTypeID', soilTypeID);
      await trigger('soilTypeID');
    }
    if (['rowSpacing', 'sowingDensity'].includes(prop) && values.NASSPlantingParams) {
      setValue('NASSPlantingParams', false); // if default NASS params were changed, removed the flag
    }
    await trigger(prop);
  };

  const onHide = () => {
    const confirm =
      selectedNRxSeasons.every(isSetNrxSeasonSowingData) ||
      window.confirm(
        t({
          id: 'You need to set all parameters to start using the model, are you sure you want to cancel?',
        })
      );

    if (confirm) {
      reset();
      togglePopUp('plantingSoilPopUpOpen', false);
    }
  };
  const onSubmit = () => {
    dispatch(setUpdateSeasonNrxData(seasonData.seasonID, values));

    if (!selectedNRxSeasons.every(isSetNrxSeasonCropMatch))
      return togglePopUp(
        // open next pop-up to fill Nrx season data
        'matchCropPopUpOpen',
        true,
        Array.isArray(seasonData.seasonID) ? null : seasonData.seasonID
      );

    togglePopUp('plantingSoilPopUpOpen', false);
  };

  // ------           -------

  return (
    <FluroDialog
      id={`planting-soil-params-pop-up`}
      dialogClassName={'planting-soil-params-pop-up'}
      visible={true}
      isDraggable
      title={t({id: 'Planting and Soil Parameters'})}
      onHide={onHide}
      focusOnMount={false}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={'rows-container'}>
          <div className={'title'}>{t({id: 'Soil parameters'})}</div>
          <div className={'soil-type-row-container'}>
            <FluroAutocomplete
              id="soil-type"
              label={t({id: 'Soil Type'})}
              title={values.soilTypeLabelID}
              menuItems={preparedSoilTypeList}
              fixedToTop={[
                {
                  value: 'Automatic soil selection',
                  label: t({id: 'Automatic soil selection'}),
                },
              ]}
              onAutocomplete={value => onChange('soilTypeLabelID', String(value))}
              value={values.soilTypeLabelID}
              className={`soil-type-select`}
              error={!!errors?.soilTypeLabelID?.message}
              errorText={errors.soilTypeLabelID?.message}
            />
            <SelectField
              id="soil-depth"
              label={t({id: 'Soil Depth'})}
              placeholder={t({id: 'Select a value'})}
              block
              className={'soil-depth-select'}
              menuItems={soilDepthListBySoilTypeLabel[values.soilTypeLabelID] || []}
              simplifiedMenu={true}
              value={automaticSoilSelection ? NRxAutomaticSoilTypeId : values.soilTypeID}
              error={!!errors.soilTypeID?.message}
              errorText={errors.soilTypeID?.message}
              disabled={!values.soilTypeLabelID || automaticSoilSelection}
              onClick={() =>
                values.soilTypeLabelID ? null : alert(t({id: 'Please, select soil type first'}))
              }
              onChange={value => onChange('soilTypeID', value)}
            />
          </div>

          {automaticSoilSelection && (
            <InfoBlock className={'soil-type-info inside-a-pop-up'}>
              {t({
                id: 'This soil type was automatically selected from the APsoil database based on the field’s geographic location. You may select other soils from this region by clicking on the “Soil Type” dropdown menu.',
              })}
            </InfoBlock>
          )}

          <div className={'title'}>{t({id: 'Planting parameters'})}</div>
          <div className={'planting-params-container'}>
            <TextField
              id="sowing-depth"
              label={`${t({id: 'Sowing depth'})} (${formatUnit(measurement, 'mm')})`}
              placeholder={t({id: 'Enter a value'})}
              className={'half-width'}
              type={'number'}
              value={values.sowingDepth}
              error={!!errors.sowingDepth?.message}
              errorText={errors.sowingDepth?.message}
              onChange={value => onChange('sowingDepth', parseFloat(String(value)))}
            />

            <Flex className={'half-width'} alignItems={'center'}>
              <TextField
                id="density"
                label={`${t({id: 'Planting density'})} (${t({
                  id: formatUnit(measurement, 'plants/m²'),
                })})`}
                placeholder={t({id: 'Enter a value'})}
                className={'flex-grow'}
                type={'number'}
                value={values.sowingDensity}
                error={!!errors.sowingDensity?.message}
                errorText={errors.sowingDensity?.message}
                onChange={value => onChange('sowingDensity', parseFloat(String(value)))}
              />
              {dispatch(NASSInfoIcon('sowingDensity', values.NASSPlantingParams))}
            </Flex>

            <Flex alignItems={'center'} className={'half-width'}>
              <TextField
                id="row-spacing"
                label={`${t({id: 'Row spacing'})} (${formatUnit(measurement, 'mm')})`}
                placeholder={t({id: 'Select a value'})}
                className={'flex-grow'}
                type={'number'}
                value={values.rowSpacing}
                error={!!errors.rowSpacing?.message}
                errorText={errors.rowSpacing?.message}
                onChange={value => onChange('rowSpacing', parseFloat(String(value)))}
              />
              {dispatch(NASSInfoIcon('rowSpacing', values.NASSPlantingParams))}
            </Flex>

            <SelectField
              id="irrigation"
              block
              label={t({id: 'Irrigation'})}
              placeholder={t({id: 'Select a value'})}
              menuItems={irrigationList}
              className={'half-width'}
              simplifiedMenu={true}
              value={values.irrigated ? 1 : 0}
              onChange={value => onChange('irrigated', !!value)}
              error={!!errors.irrigated?.message}
              errorText={errors.irrigated?.message}
            />
          </div>

          <div className="title">{t({id: 'Previous crop'})}</div>
          <div className="prev-crop-params-container">
            <SelectField
              id="crop-type"
              block
              label={t({id: 'Crop type'})}
              menuItems={prevCropTypesList}
              simplifiedMenu={true}
              className={'half-width'}
              value={values.prevCropTypeID}
              onChange={value => onChange('prevCropTypeID', value)}
              error={!!errors.prevCropTypeID?.message}
              errorText={errors.prevCropTypeID?.message}
            />

            <FluroDatePicker
              id="application-date"
              label={t({id: 'Harvest date'})}
              className={'half-width'}
              onChange={(value: Moment) =>
                onChange('prevHarvestDate', value.format(GLOBAL_FORMAT_DATE))
              }
              selected={moment(values.prevHarvestDate, GLOBAL_FORMAT_DATE)}
              maxDate={moment(
                getPrevSeasonMaxHarvestDate(seasonData.startDate, true),
                GLOBAL_FORMAT_DATE
              )}
              hideFormat={true}
            />

            <SelectField
              id="silage-hay"
              block
              label={t({id: 'Silage or hay?'})}
              placeholder={t({id: 'Select a value'})}
              className={'half-width'}
              menuItems={silageHayList}
              simplifiedMenu={true}
              value={values.haySilage}
              error={!!errors.haySilage?.message}
              errorText={errors.haySilage?.message}
              onChange={value => onChange('haySilage', value)}
            />
          </div>
        </div>

        <div className={'button-container'}>
          <FluroButton raised primary type={'submit'}>
            {t({id: 'Save changes'})}
          </FluroButton>
        </div>
      </form>
    </FluroDialog>
  );
};
export default PlantingSoilParams;
