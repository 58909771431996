// @ts-nocheck
import {featureCollection} from '@turf/turf';
import * as React from 'react';
import type {ComponentType} from 'react';
import {useEffect, useMemo, useState} from 'react';
import {useMap} from 'react-leaflet';
import type {FitBoundsOptions} from 'leaflet';
import L from 'leaflet';
import {useAppDispatch, useAppSelector} from '_hooks';
import {useClusters} from './clusters';
import {FluroGeoJson} from 'components/fluro-leaflet';
import {FluroMapPopup} from 'components';
import {
  selectFieldGeometries,
  selectFieldsByFarmId,
  selectIsEditingMode,
} from '../../../map/reducer/selectors';
import {selectHighlightedFieldId} from 'containers/map/reducer/selectors';
import {highlightField} from 'containers/map/actions';
import {getShapeCoordinates} from '_utils/geometry';
import {centerHighlightedField} from '_utils/map-utils';
import {selectEnrollmentStep} from '../carbon-store/selectors';
import {selectDialogVisibility} from 'modules/helpers/selectors';
import {DialogType} from 'modules/helpers';
import colors from 'variables.scss';

type Props = {
  fitBounds: (bounds?: L.LatLngBounds, options?: FitBoundsOptions) => void;
  fieldIds?: {[fieldId: number]: boolean};
  fieldColors: {[fieldId: number]: string};
  fieldColorsBorder?: {[fieldId: number]: string};
  fieldCategories?: {[fieldId: number]: string};
  onGeometryClick?: (fieldId: number) => void;
  CustomPopup?: ComponentType<{fieldId: number}>;
  saveBoundsToFit: (bounds?: L.LatLngBounds) => void;
};

export const MultifarmFieldGeometriesOverlay = ({
  fitBounds,
  fieldIds,
  fieldColors,
  fieldColorsBorder,
  fieldCategories = {},
  onGeometryClick,
  CustomPopup,
  saveBoundsToFit,
}: Props) => {
  const dispatch = useAppDispatch();
  const fieldsByFarmId = useAppSelector(selectFieldsByFarmId);
  const fieldGeometries = useAppSelector(selectFieldGeometries);
  const isEditingMode = useAppSelector(selectIsEditingMode);
  const highlightedFieldId = useAppSelector(selectHighlightedFieldId);
  const [visibleGeometryIds, setVisibleGeometriesId] = useState<{[fieldId: string]: boolean}>({});
  const enrollmentStep = useAppSelector(selectEnrollmentStep);
  const isAddingFields = useAppSelector(s => selectDialogVisibility(s, DialogType.addNewField));
  const carbon = useAppSelector(s => s.carbon);

  const leafletElement = useMap();

  const overlapIds = useMemo(() => new Set(carbon.overlapFields.flat()), [carbon.overlapFields]);

  const geometries = useMemo(() => {
    const idsAccumulator: number[] = [];

    const geometries: {[fieldId: number]: GeoJSON.FeatureCollection} = {};
    Object.values(fieldsByFarmId).forEach(fields =>
      Object.values(fields).forEach(f => {
        if (!fieldIds || fieldIds[f.ID]) {
          geometries[f.ID] = fieldGeometries[f.MD5];
          if (geometries[f.ID]) {
            idsAccumulator.push(f.ID); // fill the accumulator

            const [lng = 0, lat = 0] = getShapeCoordinates(fieldGeometries[f.MD5]);
            geometries[f.ID].features[0].properties.position = {lat, lng};
          }
        }
      })
    );

    classifyVisibleGeometriesIds(idsAccumulator);

    return geometries;
  }, [fieldsByFarmId, fieldGeometries, fieldIds]);
  //
  useEffect(() => {
    const geometryList = Object.values(geometries).filter(Boolean);
    if (!geometryList.length) {
      return;
    }

    const selectedGeometries = geometryList.filter(
      geometry => !!fieldColors[geometry?.features?.[0]?.properties?.fluro_id]
    );
    const geometriesTyCenterBy = selectedGeometries.length ? selectedGeometries : geometryList;

    const collection = featureCollection(geometriesTyCenterBy.flatMap(fc => fc.features));
    // @ts-expect-error featureCollection returns type that L.geoJSON doesn't expect, just misunderstanding between two libraries
    const geoJSON = L.geoJSON(collection);
    const bounds = geoJSON.getBounds();

    saveBoundsToFit(bounds);
  }, [visibleGeometryIds, fieldColors]);

  useEffect(() => {
    centerHighlightedField(geometries[Number(highlightedFieldId)], leafletElement, fitBounds);
  }, [highlightedFieldId]);

  useEffect(
    function closeFieldPopupHandler() {
      dispatch(highlightField(null));
    },
    [enrollmentStep, isAddingFields]
  );

  function classifyVisibleGeometriesIds(geometryIds: number[]) {
    const sameLength = geometryIds.length === Object.keys(visibleGeometryIds).length;
    const sameIds = geometryIds.every(fieldId => visibleGeometryIds[fieldId]);

    if (sameLength && sameIds) return;

    const newGeometryIdsObject: {[fieldId: number]: boolean} = {};

    geometryIds.forEach(fieldId => {
      newGeometryIdsObject[fieldId] = true;
    });

    setVisibleGeometriesId(newGeometryIdsObject);
  }

  const geometryEntries = useMemo(
    () => Object.entries(geometries).filter(([_id, geometry]) => geometry),
    [geometries]
  );

  const {unclusteredIds, clusterMarkersRendered} = useClusters(
    leafletElement,
    () => null,
    geometries,
    fieldColors,
    fieldCategories,
    isEditingMode
  );

  const onFieldClick = React.useCallback(
    (fieldId: number) => {
      if (isEditingMode) return;

      dispatch(highlightField(fieldId));
      onGeometryClick?.(fieldId);
    },
    [onGeometryClick, isEditingMode]
  );

  return (
    <>
      {geometryEntries.map(([id_, g]) => {
        if (!unclusteredIds[id_]) return null;

        const id = Number(id_);
        const color = isAddingFields ? undefined : fieldColors[id]; // do not color fields during adding fields flow
        const shouldShowPopup = highlightedFieldId === id;
        const properties = g?.features?.[0]?.properties;
        const fieldName = fieldsByFarmId?.[properties?.fluro_farm_id]?.[properties?.fluro_id]?.Name;

        let borderColor = fieldColorsBorder?.[id] || 'white';

        if (overlapIds.has(id)) {
          borderColor = colors['error-shape-color'];
        }

        return (
          <React.Fragment key={id}>
            <GeometryWrapper
              id={id}
              geometry={g}
              onFieldClick={onFieldClick}
              color={color}
              borderColor={borderColor}
            />
            {shouldShowPopup &&
              (CustomPopup ? (
                <CustomPopup fieldId={id} />
              ) : (
                <FluroMapPopup
                  autoPan={false} // because we are centering using centerHighlightedField()
                  onClose={() => undefined}
                  position={properties?.position}
                >
                  <div className="margin-right-10">{fieldName}</div>
                </FluroMapPopup>
              ))}
          </React.Fragment>
        );
      })}
      {clusterMarkersRendered}
    </>
  );
};

const GeometryWrapper = ({
  id,
  geometry,
  color,
  borderColor,
  onFieldClick,
}: {
  id: number;
  geometry: GeoJSON.FeatureCollection;
  color: string;
  borderColor: string;
  onFieldClick: (id: number) => void;
}) => {
  const eventHandlers = useMemo(() => ({click: () => onFieldClick(id)}), [onFieldClick, id]);
  return (
    <>
      <FluroGeoJson
        key={id}
        // Flatten the path options so they can be memoized.
        weight={2}
        opacity={1}
        fillColor={color || 'white'}
        fillOpacity={color ? 0.95 : 0.25}
        color={borderColor}
        data={geometry}
        eventHandlers={eventHandlers}
      />
    </>
  );
};

export type GeometryColor = {
  fillColor?: string;
  fillOpacity?: number; // 0..1
  color?: string;
  opacity?: number; // 0..1
};
