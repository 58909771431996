import type {ReactNode} from 'react';
import React, {useEffect} from 'react';
// @ts-expect-error react-md.d.ts doesn't export ResizeObserver :(
import {ResizeObserver} from 'react-md';
import cn from 'classnames';
import type {RouteProps} from 'react-router-dom';
import GlobalLoader from 'components/global-loader';
import {resizeViewport} from '_reducers/viewport';
import {Helmet} from 'react-helmet';
import {SkeletonProvider} from 'skeleton';
import {useAppDispatch, useAppSelector} from '../_hooks';
import {
  selectCurrentFarm,
  selectCurrentField,
  selectIsWholeFarmView,
} from './map/reducer/selectors';
import {selectIsMapView} from '../modules/global/selectors';
import {selectIsAuth, selectUserSettings} from './login/login-selectors';
import {useWorkspace} from '_hooks/use-workspace';
import {setProfileSettings} from 'containers/login/actions';
import type {UserSettings} from 'containers/admin/users/types';
import {closeAllDialogs} from 'modules/helpers';
import {Menubar} from 'containers/menubar/menubar';
import {useScrollToTop} from '_hooks/useScrollToTop';

type Props = {
  children: ReactNode;
};

const BaseLayout = ({children}: RouteProps & Props) => {
  const dispatch = useAppDispatch();
  const scrollContainerRef = React.useRef<HTMLDivElement>(null);
  const isAuth = useAppSelector(selectIsAuth);
  const isMapView = useAppSelector(selectIsMapView);
  const isWholeFarmView = useAppSelector(selectIsWholeFarmView);
  const fieldName = useAppSelector(selectCurrentField)?.Name;
  const farmName = useAppSelector(selectCurrentFarm)?.name;
  const userSettings = useAppSelector(selectUserSettings);
  const {workspace} = useWorkspace();

  const externalServices = ['/reportV2'];

  // Scrolls page content to top on location change
  useScrollToTop(scrollContainerRef);

  useEffect(
    function updateMostRecentWorkspaceVariable() {
      if (isAuth && workspace !== userSettings?.mostRecentWorkspace) {
        const newSettings = {...userSettings, mostRecentWorkspace: workspace} as UserSettings;
        dispatch(setProfileSettings(newSettings, false));
      }
    },
    [isAuth, userSettings?.mostRecentWorkspace, workspace]
  );

  useEffect(() => {
    if (!isAuth) {
      // close Add field dialog if open when logout
      dispatch(closeAllDialogs());
    }
  }, [isAuth]);

  useEffect(() => {
    const fixVH = () => {
      // hack for webkit VH issue
      // calc(var(--vh, 1vh) * 100)
      // https://css-tricks.com/the-trick-to-viewport-units-on-mobile/
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    };

    fixVH();
    window.addEventListener('resize', fixVH);
  }, []);

  const handleResize = ({width, height}: {width: number; height: number}) => {
    dispatch(resizeViewport(width, height));
  };

  const renderContent = () => {
    if (!isAuth) {
      return (
        <div className="unauthorized-container">
          {/*<div className="md-cell md-cell--12">*/}
          {children}
          <GlobalLoader />
          {/*</div>*/}
        </div>
      );
    }

    if (externalServices.includes(location.pathname)) {
      // currently is used to render report service
      return <div className={'external-service'}>{children}</div>;
    }

    return (
      <div
        id="global-drawer-nav"
        className={cn({
          'map-v2': isMapView,
        })}
      >
        <div ref={scrollContainerRef} className="md-navigation-drawer-content">
          <Menubar />
          {children}
          <GlobalLoader />
        </div>
      </div>
    );
  };

  const farmTitle = `${farmName ? `${farmName} -` : ''} ${
    !isWholeFarmView ? (fieldName ? `${fieldName} -` : '') : 'Whole Farm -'
  }`;

  return (
    <>
      <Helmet>
        <link rel="shortcut icon" href="/favicon.png" key="regrow-favicon" />,
        <title key="regrow-favicon">{`${farmTitle} Regrow`}</title>,
      </Helmet>

      <SkeletonProvider>
        {renderContent()}
        <ResizeObserver watchWidth watchHeight onResize={handleResize} />
      </SkeletonProvider>
    </>
  );
};

export default BaseLayout;
