// @ts-nocheck
import {t} from 'i18n-utils';
import React from 'react';
import {useDispatch} from 'react-redux';
import {SelectField, Slider} from 'react-md';
import {getLayersItems} from 'containers/map/utils';
import {sensorModel, sensorView} from '_utils';
import {setImageOverlayOpacity, setSensor} from 'containers/map/actions';
import type {TSensor} from 'types';
import Mixpanel from '_utils/mixpanel-utils';
import {Ln, InfoBlock, FluroChip} from 'components';
import {useAppSelector} from '_hooks';
import {
  selectCurrentSeason,
  selectCurrentSensor,
  selectCurrentSensors,
  selectCurrentTab,
  selectIsNitrogenZoningTab,
  selectWholeFarmData,
} from 'containers/map/reducer/selectors';

type Props = {
  mobileView?: boolean; // used to display data in FluroChips in certain areas (not in the toggle header) for mobiles
};

const SelectorLayer = ({mobileView}: Props) => {
  const dispatch = useDispatch();

  const currentSeason = useAppSelector(selectCurrentSeason);
  const currentSensor = useAppSelector(selectCurrentSensor);
  const currentSensors = useAppSelector(selectCurrentSensors);
  const feature = useAppSelector(selectCurrentTab);
  const imageLayerOpacity = useAppSelector(s => s.map.imageLayerOpacity);
  const wholeFarm = useAppSelector(selectWholeFarmData);
  const isNitrogenTab = useAppSelector(selectIsNitrogenZoningTab);

  const addFeatureToLayersMenu = (layersArray: any[]) => {
    layersArray.unshift(
      <li key={'select-layers-label'} className={'md-list-item'}>
        <div className={'select-layers-label'}>layer type</div>
      </li>
    );
    layersArray.push(
      <React.Fragment key={'layer-opacity'}>
        <Slider
          id="opacity-image-overlay"
          label="Layer Opacity"
          defaultValue={100}
          className={'image-opacity-slider'}
          max={100}
          min={0}
          value={imageLayerOpacity}
          onChange={value => dispatch(setImageOverlayOpacity(value))}
        />
        <li className="md-list-item opacity-item">
          <InfoBlock>
            <Ln
              blank
              external
              href="https://help.flurosense.com/en/articles/4496648-vegetation-indices"
              className="layer-help-link global-help-link"
            >
              Layer help
            </Ln>
          </InfoBlock>
        </li>
      </React.Fragment>
    );
    return layersArray;
  };

  let layers = getLayersItems();
  if (layers.length) layers = addFeatureToLayersMenu(layers);

  const selectSensor = (sensor: TSensor) => {
    Mixpanel.layerChange(sensor);
    dispatch(setSensor(sensorModel(sensor)));
  };

  if (isNitrogenTab && feature === 'zoning' && currentSeason.cropType !== 'cotton') {
    return null;
  }

  const userFacingValue = sensorView(currentSensor);

  if (mobileView) {
    return <FluroChip label={userFacingValue} />;
  }

  return (
    <SelectField
      id="select-field-index"
      placeholder={t({id: 'Select Layer'})}
      className="select-layer-control"
      menuItems={layers}
      disabled={wholeFarm.isWholeFarmView ? !layers.length : !currentSensors.length}
      value={userFacingValue}
      onChange={selectSensor}
      position={'br'}
    />
  );
};

export default SelectorLayer;
