import type {FluroSelectLiteItem} from 'components/fluro-select-lite/fluro-select-lite';
import type {Measurement} from 'containers/login/types';
import {CustomDepth} from 'containers/mrv/monitoring/form/inputs/depth-select';
import type {FieldValuesRow, MRVAttribute} from 'containers/mrv/types';
import {MRVAttributeType} from 'containers/mrv/types';
import {t} from 'i18n-utils';
import {getTypedEntries} from '_utils/object';

export function getHackyOptions(
  type: MRVAttributeType,
  row: FieldValuesRow,
  attributes: Record<string, MRVAttribute>,
  programId: number,
  measurement: Measurement
): FluroSelectLiteItem[] | undefined {
  const unitType =
    measurement === 'ha'
      ? t({id: 'Unit.cm', defaultMessage: 'cm'})
      : t({id: 'Unit.in', defaultMessage: 'in'});

  const rowValues = getTypedEntries(row.values).map(([_, value]) => value);

  const tillagePracticeCell = rowValues.find(
    cell => attributes[cell.attribute_id]?.type === MRVAttributeType.TillagePractice
  );

  const tillagePeriodCell = rowValues.find(
    cell => attributes[cell.attribute_id]?.type === MRVAttributeType.TillagePeriod
  );

  if (programId === 68 && type === MRVAttributeType.TillageDepth) {
    if (tillagePeriodCell?.value === 'fall') {
      if (tillagePracticeCell?.value === 'conventional till') {
        return measurement === 'ha'
          ? [
              {label: `11-18 ${unitType}`, value: '18'},
              {label: `25+ ${unitType}`, value: CustomDepth},
            ]
          : [
              {label: `6-9 ${unitType}`, value: '18'},
              {label: `12+ ${unitType}`, value: CustomDepth},
            ];
      }

      if (tillagePracticeCell?.value === 'reduced till') {
        return measurement === 'ha'
          ? [
              {label: `0-3 ${unitType}`, value: '3'},
              {label: `3-6 ${unitType}`, value: '6'},
              {label: `6-11 ${unitType}`, value: '11'},
            ]
          : [
              {label: `0-1 ${unitType}`, value: '3'},
              {label: `1-3 ${unitType}`, value: '6'},
              {label: `3-6 ${unitType}`, value: '11'},
            ];
      }
    } else if (tillagePeriodCell?.value === 'spring') {
      if (tillagePracticeCell?.value === 'conventional till') {
        return measurement === 'ha'
          ? [
              {label: `6-11 ${unitType}`, value: '11'},
              {label: `11-18 ${unitType}`, value: '18'},
              {label: `25+ ${unitType}`, value: CustomDepth},
            ]
          : [
              {label: `3-6 ${unitType}`, value: '11'},
              {label: `6-9 ${unitType}`, value: '18'},
              {label: `12+ ${unitType}`, value: CustomDepth},
            ];
      }

      if (tillagePracticeCell?.value === 'reduced till') {
        return measurement === 'ha'
          ? [
              {label: `0-3 ${unitType}`, value: '3'},
              {label: `3-6 ${unitType}`, value: '6'},
            ]
          : [
              {label: `0-1 ${unitType}`, value: '3'},
              {label: `1-3 ${unitType}`, value: '6'},
            ];
      }
    }
  }
}
