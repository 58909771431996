import React, {useMemo} from 'react';
import type {AsyncStatusType} from 'modules/helpers';
import {Status} from 'modules/helpers';
import {AdminTitle, AdminTitleLoaderWrapper} from '../admin.styled';
import CircularProgresslink from 'react-md/lib/Progress/CircularProgress';
import {useSelector} from 'react-redux';
import type {AppStore} from 'reducers';
import {genKey} from '_utils/pure-utils';

type Props = {
  title: string;
  statusKey: AsyncStatusType;
};

const AdminTitleWithLoader = ({title, statusKey}: Props) => {
  const id = useMemo(() => genKey(), []);

  const status = useSelector((state: AppStore) => state.helpers.asyncStatuses[statusKey].status);

  return (
    <AdminTitle className={'with-loader'}>
      {title}
      {status === Status.Pending && (
        <AdminTitleLoaderWrapper>
          <CircularProgresslink id={'progress-' + id} />
        </AdminTitleLoaderWrapper>
      )}
    </AdminTitle>
  );
};

export default AdminTitleWithLoader;
