// @ts-nocheck
import React, {useMemo, memo} from 'react';
import {CropSelectWrapper} from './crop-select.styled';
import {CropAvatar} from 'components/crop-avatar';
import type {StylesConfig} from 'react-select';
import Select from 'react-select';
import {useSelector} from 'react-redux';
import type {AppStore} from 'reducers';
import {naturalSortAlphaNum} from '_utils/sorters';
import type {CropType} from 'modules/global/types';

type Props = {
  value: string;
  onChange: (value: string) => void;
};

const formatOptionLabel = (option: CropType) => {
  return (
    <div id={`cy-crop-select-${option.value}`} style={{display: 'flex', alignItems: 'center'}}>
      <CropAvatar cropType={option.value} className="crop-type-select__avatar" />

      <span
        className="label"
        style={{
          paddingLeft: 8,
          paddingBottom: 0,
        }}
      >
        {option.label}
      </span>
    </div>
  );
};

const CropSelect = memo(({value, onChange}: Props) => {
  const crops = useSelector((state: AppStore) => state.global.cropTypes);

  const menuItems = useMemo(() => {
    const values = Object.values(crops);

    return naturalSortAlphaNum(values, 'label');
  }, [crops]);

  const current = useMemo(() => {
    return menuItems.find(item => item.value === value);
  }, [menuItems, value]);
  return (
    <CropSelectWrapper>
      <Select
        placeholder="Select a crop type"
        value={current}
        formatOptionLabel={formatOptionLabel}
        options={menuItems}
        onChange={(item: {label: string; value: string}) => {
          onChange(item.value);
        }}
        menuPortalTarget={document.body}
        styles={borderedStyle}
      />
      {/*{value}*/}
    </CropSelectWrapper>
  );
});

const borderedStyle: StylesConfig = {
  menuPortal: (base: any) => ({
    ...base,
    zIndex: 9999,
  }),
  control: (base: any) => ({
    ...base,
    border: '1px solid lightgray',
    '&:hover': {borderColor: 'gray'},
    boxShadow: 'none',
  }),
};

export default CropSelect;
