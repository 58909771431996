import type {FluroSelectLiteItem} from 'components/fluro-select-lite/fluro-select-lite';
import {t} from 'i18n-utils';
import type {
  AppRateCategory,
  ImperialAppRateUnit,
  ImperialIrrigationRateUnit,
  MetricAppRateUnit,
  MetricIrrigationRateUnit,
} from './constants';

type AppRateUnitOpts = FluroSelectLiteItem<
  ImperialAppRateUnit | MetricAppRateUnit | AppRateCategory,
  AppRateCategory
>[];

// KLUDGE Hardcoded for Programs 129 (South Pole) & 133 (Sustain Connect). This should
// be fixed properly in https://regrow.atlassian.net/browse/FSB-12360.
export function getAppRateUnitOptsOrDefaults(
  programId: number | undefined,
  defaults: AppRateUnitOpts
): AppRateUnitOpts {
  switch (programId) {
    // South Pole
    case 129: {
      return [{label: t({id: 'Unit.t/ha', defaultMessage: 't/ha'}), value: 't1ha-1'}];
    }
    // Sustain Connect
    case 133: {
      return [
        {label: t({id: 'Unit.t/ha', defaultMessage: 't/ha'}), value: 't1ha-1'},
        {label: t({id: 'Unit.kg/ha', defaultMessage: 'kg/ha'}), value: 'kg1ha-1'},
      ];
    }

    default: {
      return defaults;
    }
  }
}

type IrrigationRateUnitOpts = FluroSelectLiteItem<
  ImperialIrrigationRateUnit | MetricIrrigationRateUnit | AppRateCategory,
  AppRateCategory
>[];

export function getIrrigationRateUnitOptsOrDefaults(
  programId: number | undefined,
  defaults: IrrigationRateUnitOpts
): IrrigationRateUnitOpts {
  switch (programId) {
    // Sustain Connect
    case 133: {
      return [
        {label: t({id: 'Unit.ML/ha', defaultMessage: 'ML/ha'}), value: 'mL1ha-1'},
        {label: t({id: 'Unit.mm/ha', defaultMessage: 'mm/ha'}), value: 'mm1ha-1'},
      ];
    }

    default: {
      return defaults;
    }
  }
}
