import React, {useMemo} from 'react';
import Select from 'react-select';
import {FontIcon} from 'react-md';
import cn from 'classnames';

const DropdownIndicator = () => <FontIcon>arrow_drop_down</FontIcon>;
type Props = {
  id?: string;
  value: number | string;
  options: {label: string; value: number | string; id?: number}[];
  onChange: (value: number | string) => void;
  className?: string;
  selectClassName?: string;
  theme?: 'primary' | 'green';
  disabled?: boolean;
};

export const SimpleSelector = ({
  id,
  options,
  onChange,
  value,
  className = '',
  selectClassName = '',
  theme = 'primary',
  disabled = false,
}: Props) => {
  const valueObj = useMemo(() => {
    return options.find(item => item.value === value) || {label: String(value), value};
  }, [value, options]);

  const checkColorsByTheme = () => {
    if (theme === 'green') {
      return {
        singleValue: {
          color: '#2E8023',
        },
        control: {
          background: '#D7EBD5',
          border: 'none',
        },
        dropdownIndicator: {
          color: '#2E8023',
        },
      };
    } else {
      // default to 'primary' theme
      return {
        singleValue: {
          color: '#7E54D8',
        },
        control: {
          background: 'white',
          border: '1px solid #e0e0e0',
        },
        dropdownIndicator: {
          color: '#7A7A7A',
        },
      };
    }
  };

  return (
    <div className={cn({'fluro-select': true, [className]: !!className, 'm-0': true})}>
      <Select
        id={id}
        instanceId={id}
        components={{DropdownIndicator}}
        isClearable={false}
        options={options}
        onChange={(option: any) => {
          onChange(option.value);
        }}
        value={valueObj}
        menuPortalTarget={document.body}
        className={selectClassName}
        isDisabled={disabled}
        theme={t => ({
          ...t,
          colors: {
            ...t.colors,
            primary: 'rgba(0,0,0,.12)',
            primary25: 'rgba(0,0,0,.12)',
            primary50: 'rgba(0,0,0,.12)',
          },
        })}
        styles={{
          option: (css, state) => ({
            ...css,
            ...(state.isSelected && {color: '#000'}),
          }),
          placeholder: css => ({
            ...css,
            fontSize: '14px',
          }),
          valueContainer: base => ({
            ...base,
            fontSize: '14px',
            fontWeight: 400,
            height: '30px',
            padding: '0 6px',
            flexWrap: 'nowrap',
          }),
          singleValue: base => ({
            ...base,
            color: checkColorsByTheme().singleValue.color,
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
          }),
          indicatorSeparator: () => ({display: 'none', height: '30px'}),
          menuPortal: base => ({
            ...base,
            zIndex: 9999,
          }),
          input: provided => ({
            ...provided,
            margin: '0px',
          }),
          control: base => ({
            ...base,
            boxShadow: 'none',
            background: checkColorsByTheme().control.background,
            border: checkColorsByTheme().control.border,
            fontSize: '1rem',
            padding: '0px 10px',
            borderRadius: 20,
            height: 30,
            minHeight: 30,
            cursor: 'pointer',
          }),
          dropdownIndicator: base => ({
            ...base,
            color: checkColorsByTheme().dropdownIndicator.color,
          }),
        }}
      />
    </div>
  );
};
