import React from 'react';
import type {CSSProperties, ReactNode} from 'react';
import {Collapse, FontIcon} from 'react-md';
import styled, {css} from 'styled-components';
import {toggleTooltip} from 'modules/global/actions';
import {useAppDispatch} from '_hooks';
import {Flex} from 'components';

export type CollapsibleProps = {
  id: string;
  title: ReactNode;
  children: ReactNode;
  onToggle: () => void;
  isOpen: boolean;
  className?: string;
  style?: CSSProperties;
  noBorder?: boolean;
  tooltip?: string | JSX.Element;
  /**
   * Appears to the right of the title, next to the caret icon
   */
  info?: ReactNode;
  fullWidth?: boolean;
};
export const Collapsible = ({
  id,
  title,
  children,
  className,
  style,
  noBorder,
  tooltip,
  info,
  fullWidth,
  isOpen,
  onToggle,
}: CollapsibleProps) => {
  const dispatch = useAppDispatch();
  const tooltipId = `${id}-tooltip`;

  return (
    <Container
      style={style}
      className={className}
      noBorder={noBorder}
      collapsed={!isOpen}
      fullWidth={fullWidth}
    >
      {
        // TODO: fixme a11y
        // eslint-disable-next-line styled-components-a11y/click-events-have-key-events, styled-components-a11y/no-static-element-interactions
        <Title data-testid={id} onClick={onToggle} collapsed={!isOpen}>
          <Flex alignItems="center" gap="8px">
            {title}
            {tooltip && (
              <FontIcon
                style={{cursor: 'pointer'}}
                data-for={tooltipId}
                data-tip={''}
                onMouseEnter={() =>
                  dispatch(
                    toggleTooltip({
                      id: tooltipId,
                      content: tooltip,
                      place: 'bottom',
                      width: '310px',
                    })
                  )
                }
              >
                help_outline
              </FontIcon>
            )}
          </Flex>
          <Flex alignItems="center" gap="8px">
            {info}
            <FontIcon>{isOpen ? 'keyboard_arrow_down' : 'keyboard_arrow_right'}</FontIcon>
          </Flex>
        </Title>
      }
      <Collapse animate={false} collapsed={!isOpen}>
        <CollapseContent>{children}</CollapseContent>
      </Collapse>
    </Container>
  );
};

type TitleProps = {collapsed: boolean};
const Title = styled.div<TitleProps>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  gap: 8px;

  ${({collapsed}) =>
    collapsed &&
    css`
      padding: 8px;
    `}
`;

type ContainerProps = Pick<CollapsibleProps, 'noBorder' | 'fullWidth'> & {collapsed: boolean};
const Container = styled.div<ContainerProps>`
  ${({noBorder, collapsed}) =>
    !noBorder &&
    css`
      border-radius: 4px;
      border: 1px solid ${({theme}) => theme.color.stroke.strong};

      ${!collapsed &&
      css`
        padding: 8px;
      `};
    `};

  ${({fullWidth}) =>
    fullWidth &&
    css`
      width: 100%;
    `};
`;

const CollapseContent = styled.div`
  margin-top: 8px;
`;
