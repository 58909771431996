import React, {useState} from 'react';
import {Flex, FluroButton, FluroDialog} from 'components';
import {FluroCheckbox} from 'components/fluro-checkbox/fluro-checkbox';
import type {TabAccess} from 'containers/si/api/apiTypes';
import {tabLabel} from 'containers/si/constants';
import {toggle} from '_utils/pure-utils';
import {getTypedEntries} from '_utils/object';

type TabsPopupProps = {
  title: string;
  tab_accesses?: TabAccess[];
  onSave: (item: TabAccess[]) => void;
  onHide: () => void;
  portal?: boolean;
};

export const TabsPopup = ({
  title,
  tab_accesses = [],
  onHide,
  onSave,
  portal = true,
}: TabsPopupProps) => {
  const [tabsChecked, setTabsChecked] = useState(tab_accesses);

  return (
    <FluroDialog
      id={'edit-tabs-popup'}
      key={'edit-tabs-popup'}
      title={title}
      onHide={onHide}
      visible={true}
      width={400}
      portal={portal}
    >
      <Flex direction="column">
        {getTypedEntries(tabLabel).map(value => {
          const [item, label] = value;
          const active = tab_accesses.includes(item);

          return (
            <FluroCheckbox
              key={`checkbox-${item}`}
              value={active}
              label={label}
              onChange={() => setTabsChecked(toggle(tabsChecked, item))}
              aria-label={`select ${label}`}
              disabled={item === 'admin'}
            />
          );
        })}
      </Flex>
      <Flex justifyContent="flex-end">
        <FluroButton
          primary
          raised
          onClick={() => {
            onSave(tabsChecked);
            onHide();
          }}
          tone="primary"
        >
          Save
        </FluroButton>
      </Flex>
    </FluroDialog>
  );
};
