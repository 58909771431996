import React, {useMemo, useRef, useState} from 'react';
import {FormattedMessage, t} from 'i18n-utils';
import {FontIcon, ListItem, MenuButton} from 'react-md';
import FluroDialog from 'components/fluro-dialog';
import moment from 'moment';
import {setFeature} from 'containers/map/actions';
import 'chartjs-plugin-zoom';

import './index.scss';
import {getDataSource} from '_utils';
import {downloadFile} from '_utils/pure-utils';
import Slider from 'react-slick';

import ReadOnly from 'components/read-only-container';
import {TrendsIndex} from '../../types';

import {analyticsToCSV} from './helpers';
import ErrorBoundary from '../../../error-boundary';
import SimplePreloader, {HideWhileLoading} from 'components/simple-preloader';
import {AsyncStatusType} from '../../../../modules/helpers';
import {useAppDispatch, useAppSelector} from '_hooks';
import {
  selectCurrentDate,
  selectCurrentDates,
  selectCurrentFarm,
  selectCurrentField,
  selectCurrentSeason,
  selectCurrentSensor,
  selectImagesStatus,
  selectIsReadOnly,
  selectRemoteSensingCloudCover,
} from 'containers/map/reducer/selectors';
import {
  selectAnalyticNotHiddenPoints,
  selectMapAnalyticsTrendsData,
} from 'containers/map/features/analytics/analytics-selectors';
import {AnalyticChart} from 'containers/map/features/analytics/analytic-chart';
import {AnalyticChartLabels} from 'containers/map/features/analytics/analytic-chart-labels';
import {InfoBlock, Ln} from 'components';
import Mixpanel from '_utils/mixpanel-utils';

const Analytics = () => {
  const dispatch = useAppDispatch();
  const trendsData = useAppSelector(selectMapAnalyticsTrendsData);

  const currentSeason = useAppSelector(selectCurrentSeason);
  const isReadOnly = useAppSelector(selectIsReadOnly);
  const remoteSensingCloudCover = useAppSelector(selectRemoteSensingCloudCover);
  const imageStatus = useAppSelector(selectImagesStatus);
  const currentDate = useAppSelector(selectCurrentDate);
  const field = useAppSelector(selectCurrentField);
  const farm = useAppSelector(selectCurrentFarm);
  const currentDates = useAppSelector(selectCurrentDates);
  const currentSensor = useAppSelector(selectCurrentSensor);
  const allVisibleAnalyticPoints = useAppSelector(selectAnalyticNotHiddenPoints);

  const sliderRef = useRef(null);
  const [exportStatus, setExportStatus] = useState(false);
  const [popUpOpen, togglePopUpState] = useState(false);
  const [elementToShow, setElementToShow] = useState(1);

  const popUpOpenRef = useRef(false);
  const exportChartsRef = useRef<any>({});

  const togglePopUp = (value: boolean) => {
    popUpOpenRef.current = value;
    togglePopUpState(value);
  };

  const exportToFile = async (fileType: string) => {
    setExportStatus(true);
    Mixpanel.analyticsExport(fileType);

    await new Promise(resolve => setTimeout(() => resolve(true), 1000)); // await export charts appear in the DOM
    const formatDate = moment(currentDate, 'DD/MM/YYYY').format('DD-MM-YYYY');
    const selectedChart = (index: TrendsIndex): any => exportChartsRef.current[`${index}Chart`];

    const zipName = `${farm.name} ${field.Name.replace(/\.[^/.]+$/, '')} ${formatDate}`
      .split(' ')
      .join('_');
    const fileName = (index: string) =>
      `${farm.name} ${field.Name.replace(/\.[^/.]+$/, '')} ${formatDate} ${index}.${fileType}`
        .split(' ')
        .join('_');

    const type = getDataSource(currentDates[currentDate][currentSensor]);

    const JSZip = await import('jszip');

    const zip = new JSZip.default();

    [TrendsIndex.NDVI, TrendsIndex.CCCI, TrendsIndex.NDRE, TrendsIndex.MSAVI].forEach(
      (index: TrendsIndex) => {
        if (!allVisibleAnalyticPoints.find(point => point.index === index)) {
          // no data available for the index chart
          return;
        }

        const chart = selectedChart(index);
        if (!chart) {
          return;
        }
        const itemName = `${zipName}/${fileType}/${fileName(index)}`;
        if (fileType === 'png')
          zip.file(
            itemName,
            chart.chartInstance.canvas.toDataURL('image/png').split('base64,')[1],
            {
              base64: true,
            }
          );
        if (fileType === 'csv') {
          const dates = chart.props.options.categories;

          const points = chart.props.data.datasets.map((el: any) => ({
            values: el.data.map((data: number) => (isNaN(data) ? '-' : data)),
            name: el.pointLabel,
          }));
          zip.file(itemName, analyticsToCSV([dates, points, type]));
        }
      }
    );

    zip.generateAsync({type: 'blob'}).then((content: any) => {
      downloadFile(content, `${zipName}.zip`);
      setExportStatus(false);
    });
  };

  const openPop = (selectedIndex: string) => {
    let slideToOpen = 1;
    document
      .querySelectorAll('.analytics-trend-container')
      .forEach(
        (el, index) => (slideToOpen = el.classList.contains(selectedIndex) ? index : slideToOpen)
      );

    togglePopUp(true);
    setElementToShow(slideToOpen);
  };

  const handleOnShow = () => {
    // @ts-expect-error the slider lib is not typed enough
    if (sliderRef?.current) sliderRef?.current?.slickGoTo(elementToShow, true);
  };

  const closePop = () => {
    togglePopUp(false);
    sliderRef.current = null;
  };

  const shouldNotShowContent = useMemo(() => {
    const {isImagesProcessing, isNoImagery} = imageStatus;

    return (
      (isImagesProcessing || !currentSeason?.id || !Object.keys(currentDates).length) &&
      !isNoImagery
    );
  }, [currentDates, imageStatus, currentSeason?.id]);

  const warningMessage = useMemo(() => {
    return !!trendsData.length && remoteSensingCloudCover > 5 ? (
      <InfoBlock appearance={'warning'} className={`tab-info-block`}>
        <FormattedMessage
          id="[analytics] Some of your imagery have more than 5% cloud"
          defaultMessage="
          Some of your imagery have more than 5% cloud. If you dont want the cloudy data points to
          appear on the charts, please hide the cloudy imagery in the <span>data layers</span> tab first.
        "
          values={{
            span: (txt: string) => (
              <span onClick={() => dispatch(setFeature('data-layers'))} className={'global-link'}>
                {txt}
              </span>
            ),
          }}
        />
      </InfoBlock>
    ) : null;
  }, [remoteSensingCloudCover, trendsData.length]);

  return (
    <div className={'section-container analytics-container'}>
      <h3 className={'tab-title'}>
        {t({id: 'Analytics', defaultMessage: 'Analytics'})}{' '}
        <Ln
          blank
          external
          className="help-ln"
          href={'https://help.flurosense.com/en/articles/4484911-time-based-analytics'}
        >
          <FontIcon>help_outline</FontIcon>
        </Ln>
        <SimplePreloader
          statusKeys={[
            AsyncStatusType.analyticsGeometriesAndPoints,
            AsyncStatusType.analyticsFieldArableData,
          ]}
        />
      </h3>
      {shouldNotShowContent ? null : (
        <>
          <HideWhileLoading
            statusKeys={[
              AsyncStatusType.analyticsGeometriesAndPoints,
              AsyncStatusType.analyticsFieldArableData,
            ]}
            content={isLoading => (
              <div className={'analytics-export'}>
                <ReadOnly>
                  <MenuButton
                    id={`menu-button-export`}
                    className="export-button"
                    listClassName="export-button__menu-list"
                    icon
                    disabled={isReadOnly || isLoading}
                    menuItems={[
                      <ListItem
                        key={1}
                        onClick={() => exportToFile('png')}
                        primaryText={t({id: 'Export {ext}'}, {ext: '.png'})}
                      />,
                      <ListItem
                        key={2}
                        onClick={() => exportToFile('csv')}
                        primaryText={t({id: 'Export {ext}'}, {ext: '.csv'})}
                      />,
                    ]}
                  >
                    <FontIcon>more_vert</FontIcon>
                  </MenuButton>
                </ReadOnly>
              </div>
            )}
          />

          <AnalyticChartLabels />

          {warningMessage}

          {!popUpOpen && (
            <div className={'analytics-trends'}>
              <AnalyticChart
                exportStatus={exportStatus}
                openPop={openPop}
                exportChartsRef={exportChartsRef.current}
                index={TrendsIndex.NDVI}
              />
              <AnalyticChart
                exportStatus={exportStatus}
                openPop={openPop}
                exportChartsRef={exportChartsRef.current}
                index={TrendsIndex.CCCI}
              />
              <AnalyticChart
                exportStatus={exportStatus}
                openPop={openPop}
                exportChartsRef={exportChartsRef.current}
                index={TrendsIndex.MSAVI}
              />
              <AnalyticChart
                exportStatus={exportStatus}
                openPop={openPop}
                exportChartsRef={exportChartsRef.current}
                index={TrendsIndex.NDRE}
              />
            </div>
          )}

          <FluroDialog
            id="analytic-trend-pop"
            portal
            className={'analytic-trend-pop'}
            visible={popUpOpen}
            onHide={closePop}
            isDraggable
            onShow={handleOnShow}
            title={t({id: 'Trends', defaultMessage: 'Trends'})}
            width="560px"
            focusOnMount={false}
          >
            <AnalyticChartLabels />
            <ErrorBoundary>
              <Slider
                ref={sliderRef}
                className={'trends-slider'}
                vertical
                verticalSwiping
                speed={500}
                adaptiveHeight
              >
                <AnalyticChart
                  isPopUpTrend
                  openPop={openPop}
                  exportChartsRef={exportChartsRef.current}
                  index={TrendsIndex.NDVI}
                />
                <AnalyticChart
                  isPopUpTrend
                  openPop={openPop}
                  exportChartsRef={exportChartsRef.current}
                  index={TrendsIndex.CCCI}
                />
                <AnalyticChart
                  isPopUpTrend
                  openPop={openPop}
                  exportChartsRef={exportChartsRef.current}
                  index={TrendsIndex.MSAVI}
                />
                <AnalyticChart
                  isPopUpTrend
                  openPop={openPop}
                  exportChartsRef={exportChartsRef.current}
                  index={TrendsIndex.NDRE}
                />
              </Slider>
            </ErrorBoundary>
          </FluroDialog>
        </>
      )}
    </div>
  );
};

export default Analytics;
