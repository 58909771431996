import type {AxisTickProps} from '@nivo/axes';
import {REPORT_DASHBOARD_COLORS} from 'containers/si/colors';
import React from 'react';
import {isTruthy} from '_utils/typeGuards';

interface StackedBarTickProps extends AxisTickProps<any> {
  startNumber: number;
  dataLength: number;
  textOffset?: number;
  region?: boolean;
  circle?: boolean;
}

export const StackedBarTick = ({
  startNumber,
  dataLength,
  region,
  circle,
  textOffset,
  ...tick
}: StackedBarTickProps) => {
  // Setting a custom tick to be the circle and text, so that it wil lbe centered on the grouped bars
  const xValue = tick.x - (textOffset ?? 278); // position of the circle and text
  const xCircleValue = xValue - 15 - 4;
  // The tickIndex is flipped, so take the length of the data and subtract the index, then add the start number
  const circleIndex = dataLength - tick.tickIndex - 1 + startNumber;
  const colorIndex = dataLength - tick.tickIndex - 1;
  let regionName = tick.value.split('+++')[1] || 'No data';
  regionName = regionName.length > 30 ? `${regionName.substring(0, 30)}...` : regionName; // truncate the name if it's too long
  const cropName = region ? tick.value.split('+++')[2] || 'No data' : tick.value;

  return (
    <g transform={`translate(${tick.x},${tick.y})`}>
      {isTruthy(circle) && (
        <circle r={12} fill={REPORT_DASHBOARD_COLORS[colorIndex]} cx={xCircleValue} />
      )}
      {isTruthy(circle) && (
        <text textAnchor="middle" alignmentBaseline="middle" x={xCircleValue}>
          {circleIndex}
        </text>
      )}
      <text
        textAnchor="start"
        x={xValue}
        style={{
          fontSize: '12px',
          fill: '#000',
        }}
        dy={0}
      >
        {isTruthy(region) && (
          <tspan x={xValue} style={{fontWeight: 600}}>
            {regionName}
          </tspan>
        )}
        <tspan x={xValue} dy={isTruthy(region) ? '12px' : 5}>
          {cropName}
        </tspan>
      </text>
    </g>
  );
};
