import React, {useEffect} from 'react';
import {Text, Flex} from 'components';

import {t} from 'i18n-utils';
import {YearsSelect} from 'containers/si/kpi/filter/years-filter';
import {RegionsSelect} from 'containers/si/kpi/filter/regions-filter';
import {CropTypeFilter as CropTypeSelect} from 'containers/si/kpi/filter/crop-type-filter';
import {SaveKPIFilter} from 'containers/si/kpi/filter/save-kpi-filter';
import {
  setSISelectedCropTypes,
  setSIVisibleSubsectionIds,
  setSIYearsFilter,
} from 'containers/si/module/reducer';
import {useAppDispatch, useAppSelector} from '_hooks';
import {
  selectAvailableCropIDs,
  selectCurrentProgramCrops,
  selectSIAvailableSubSectionIds,
  selectSIRegionOptions,
  selectSISelectedCropTypes,
  selectSIVisibleSubsectionIds,
  selectSIYearsAvailable,
  selectSIYearsFilter,
} from 'containers/si/module/selectors';
import {difference, intersection} from 'lodash';

const RegionsFilter = () => {
  const dispatch = useAppDispatch();
  const selectedSubsectionIds = useAppSelector(selectSIVisibleSubsectionIds);
  const availableSubsectionIds = useAppSelector(selectSIAvailableSubSectionIds);
  const options = useAppSelector(selectSIRegionOptions);
  const onChange = React.useCallback(
    (ids: Array<number>) => {
      dispatch(setSIVisibleSubsectionIds(ids));
    },
    [dispatch]
  );

  return (
    <RegionsSelect
      selectedIds={selectedSubsectionIds}
      availableSubsectionIds={availableSubsectionIds}
      options={options}
      onChange={onChange}
    />
  );
};

const CropTypeFilter = () => {
  const dispatch = useAppDispatch();
  const cropTypeOptions = useAppSelector(selectCurrentProgramCrops);
  const selectedCropIds = useAppSelector(selectSISelectedCropTypes);
  const availableCropIds = useAppSelector(selectAvailableCropIDs);
  const onChange = React.useCallback(
    (ids: Array<number>) => dispatch(setSISelectedCropTypes(ids)),
    [dispatch]
  );
  useEffect(() => {
    if (difference(selectedCropIds, availableCropIds).length > 0) {
      dispatch(setSISelectedCropTypes(intersection(selectedCropIds, availableCropIds)));
    }
  }, [availableCropIds, dispatch, selectedCropIds]);

  return (
    <CropTypeSelect
      cropTypeOptions={cropTypeOptions}
      selectedCropIds={selectedCropIds}
      availableCropIds={availableCropIds}
      onChange={onChange}
    />
  );
};

const YearsFilter = () => {
  const dispatch = useAppDispatch();
  const filteredYears = useAppSelector(selectSIYearsFilter);
  const allAvailableYears = useAppSelector(selectSIYearsAvailable).sort((a, b) => a - b);

  const onChange = React.useCallback(
    (years: Array<number>) => dispatch(setSIYearsFilter(years)),
    [dispatch]
  );

  return (
    <YearsSelect
      selectedYears={filteredYears}
      allAvailableYears={allAvailableYears}
      onChange={onChange}
    />
  );
};

export const KPIFilter = () => {
  return (
    <div className="filter-container">
      <Flex alignItems="center" gap="12px">
        <Text className="mb-0" bold variant="h3">
          {t({id: 'Filter by'})}:
        </Text>
        <RegionsFilter />
        <CropTypeFilter />
        <YearsFilter />
        <SaveKPIFilter />
      </Flex>
    </div>
  );
};
