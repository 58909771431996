import {FluroButton} from 'components';
import {MRVPhaseType} from 'containers/mrv/types';
import {t} from 'i18n-utils';
import React, {useState} from 'react';
import {FinishPhaseDialog} from './finish-phase-dialog';

type Props = {
  disabled: boolean;
  phaseType: MRVPhaseType;
};

export const FinishPhaseDialogContainer: React.FC<Props> = ({disabled, phaseType}) => {
  const [visible, setVisible] = useState(false);

  const finishPhaseType =
    phaseType === MRVPhaseType.Monitoring
      ? `${t({id: 'MonitoringStageStep.Measurement', defaultMessage: 'Measurement'})}`
      : `${t({id: 'EnrollmentStepName.Enrollment', defaultMessage: 'Enrollment'})}`;

  return (
    <>
      {visible && (
        <FinishPhaseDialog finishPhaseType={finishPhaseType} onHide={() => setVisible(false)} />
      )}
      <FluroButton primary raised onClick={() => setVisible(true)} disabled={disabled}>
        {t({
          // id: 'BtnLabel.FinishMeasurementInputs',
          defaultMessage: `Finish ${finishPhaseType} inputs`,
        })}
      </FluroButton>
    </>
  );
};
