// @ts-nocheck
import {t} from 'i18n-utils';
import React from 'react';
import {useDispatch} from 'react-redux';
import {removeFarm} from 'modules/farms/actions';
import {FluroDialog} from 'components';
import FarmEdit from 'containers/farm/edit';
import {useAppSelector} from '_hooks';
import {dialogToggle, DialogType} from '../../../../../modules/helpers';
import {selectDialogData} from '../../../../../modules/helpers/selectors';
import {selectIsAdmin} from '../../../../login/login-selectors';
import {selectFieldsByFarmId} from 'containers/map/reducer/selectors';
import {removeProjectFields} from 'containers/mrv/monitoring/module/thunks';
import {useParsedMatchParams} from '_hooks/use-parsed-match-params';
import {selectCurrentProjectFieldMapKmlId2MrvId} from 'containers/mrv/monitoring/module/selectors';
import {removeFields} from 'containers/mrv/enrollment/carbon-store';

export const EditFarmDialog = () => {
  const dispatch = useDispatch();
  const {projectId} = useParsedMatchParams();
  const userDefaultOrganization = useAppSelector(store => store.login.user.defaultOrganizationID);
  const isAdmin = useAppSelector(selectIsAdmin);
  const farm = useAppSelector(s => selectDialogData(s, DialogType.editFarm))?.payload || {};
  const fieldsByFarmId = useAppSelector(selectFieldsByFarmId);
  const kmlIdToMrvId = useAppSelector(selectCurrentProjectFieldMapKmlId2MrvId);

  const onSubmit = () => {
    const fieldIds = Object.keys(fieldsByFarmId[farm.id]).map(Number);
    dispatch(removeFields({fieldIds}));

    const mrvFieldIds = fieldIds.map(id => kmlIdToMrvId[id]).filter(Boolean);

    if (projectId && mrvFieldIds.length) {
      dispatch(removeProjectFields({projectId, mrvFieldIds}));
    }

    dispatch(removeFarm(farm.id));
  };
  const onHide = () => {
    dispatch(dialogToggle(DialogType.editFarm, false));
  };

  return (
    <>
      <FluroDialog
        id="farm-edit--dialog"
        visible={true}
        isDraggable
        title={farm.id ? t({id: 'Edit farm'}) : t({id: 'Add new farm'})}
        focusOnMount={false}
        portal
        onHide={onHide}
        width={500}
      >
        <FarmEdit
          onSaveDone={onHide}
          onDelete={() => {
            dispatch(
              dialogToggle(DialogType.deleteDialog, true, {
                title: t({id: 'Delete farm?'}),
                onSubmit,
              })
            );
          }}
          farm={farm}
          userDefaultOrganization={userDefaultOrganization}
          isAdmin={isAdmin}
        />
      </FluroDialog>
    </>
  );
};
