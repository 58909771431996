// @ts-nocheck
import Yup from 'yup';
import {getLocaleDateFormat} from '_utils';
import {cropMinDateParams} from '../../../../utils';
import Moment from 'moment';
import {extendMoment} from 'moment-range';
import type {SeasonRange} from '../../types';
import {t} from 'i18n-utils';

//@ts-expect-error error leftover from convertion to strict mode, please fix
const moment = extendMoment(Moment);

export const validationSchema = (
  datesDisable: boolean,
  multiSeason: boolean,
  existingSeasons: SeasonRange[],
  lastStartDate?: string
) => {
  const datesValidation = datesDisable
    ? {}
    : datesSchema(multiSeason, lastStartDate, existingSeasons);

  return Yup.object().shape({
    cropType: Yup.string().nullable(),
    cropSubType: Yup.string().max(50, t({id: 'Maximum length'}, {length: 50})),
    ...datesValidation,
  });
};

/**
 * @param multiSeason form is opened for a multiple selected seasons
 * @param lastStartDate max start date for the selected seasons. For example:
 *                      { season1: [1,30], season2: [4,30] } => 4
 */
const datesSchema = (
  multiSeason: boolean,
  lastStartDate: string,
  existingSeasons: SeasonRange[]
) => {
  const minDate = cropMinDateParams().date;
  const maxDate = moment().add(1, 'years');
  const required = !multiSeason; // For multiseason case none of the dates fields are required.

  return {
    startDate: Yup.string()
      .nullable()
      .test('start-date', '', function (value: string) {
        // moment(undefined) will create a current date, moment(null) will create Invalid date
        const startDate = moment(value || null).startOf('day');
        return startDateTest.call(this, startDate, minDate, maxDate, required);
      }),
    endDate: Yup.string()
      .nullable()
      .test('end-date', '', function (value: string) {
        // moment(undefined) will create a current date, moment(null) will create Invalid date
        const startDate = moment(this.parent.startDate || lastStartDate || null).startOf('day');
        const endDate = moment(value || null).startOf('day');
        return endDateTest.call(
          this,
          startDate,
          endDate,
          minDate,
          maxDate,
          existingSeasons,
          required
        );
      }),
  };
};

/**
 * Needs to be called with the Yup.test `this` context, for example:
 * startDateTest.call(this, startDate, minDate, maxDate, required)
 */
function startDateTest(
  startDate: Moment.Moment,
  minDate: Moment.Moment,
  maxDate: Moment.Moment,
  required: boolean
): boolean | Yup.ValidationError {
  if (!startDate.isValid()) {
    if (required) {
      return this.createError({
        path: this.path,
        message: t({id: 'Sowing date is required'}),
      });
    }
    return true; // don't run checks if startDate isn't required and is invalid
  }
  if (startDate.isBefore(minDate)) {
    return this.createError({
      path: this.path,
      message: t(
        {id: 'Sowing date should be after date'},
        {date: minDate.format(getLocaleDateFormat())}
      ),
    });
  }
  if (startDate.isAfter(maxDate)) {
    return this.createError({
      path: this.path,
      message: t(
        {id: 'Sowing date should be before date'},
        {date: maxDate.format(getLocaleDateFormat())}
      ),
    });
  }
  return true;
}

/**
 * Needs to be called with the Yup.test `this` context, for example:
 * endDateTest.call(this, startDate, endDate, minDate, maxDate, existingSeasons, required)
 */
function endDateTest(
  startDate: Moment.Moment,
  endDate: Moment.Moment,
  minDate: Moment.Moment,
  maxDate: Moment.Moment,
  existingSeasons: SeasonRange[],
  required: boolean
): boolean | Yup.ValidationError {
  if (!endDate.isValid()) {
    if (required) {
      return this.createError({
        path: this.path,
        message: t({id: 'Harvesting date is required'}),
      });
    }
    return true;
  }
  if (endDate.isBefore(minDate)) {
    return this.createError({
      path: this.path,
      message: t(
        {id: 'Harvesting date should be after date'},
        {date: minDate.format(getLocaleDateFormat())}
      ),
    });
  }
  if (endDate.isAfter(maxDate)) {
    return this.createError({
      path: this.path,
      message: t(
        {id: 'Harvesting date should be before date'},
        {date: maxDate.format(getLocaleDateFormat())}
      ),
    });
  }
  // It's not the actual overlap check, it just checks if the endDate is one of the filled dates.
  const overlap = existingSeasons.some(seasonRange =>
    seasonRange.range.some(d => moment(d).isSame(endDate))
  );
  if (overlap) {
    return this.createError({
      path: this.path,
      message: t({id: 'Harvesting date should not overlap other seasons'}),
    });
  }
  if (startDate.isValid() && endDate.diff(startDate, 'months') < 1) {
    return this.createError({
      path: this.path,
      message: t({id: 'Harvesting date should be at least 1 month after the sowing date'}),
    });
  }

  const someIsOverlapping = false;
  // TODO: add seasons overlap check
  if (someIsOverlapping) {
    return this.createError({
      path: this.path,
      message: t({id: 'New season dates should not overlap with any existing season'}),
    });
  }
  return true;
}
