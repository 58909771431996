import type {Feature} from 'geojson';
import {main_gray_600} from '_utils/colors';

export const GEOMETRY_THUMB_SIZE: [number, number] = [40, 40];

const GEOMETRY_FEATURE_STYLE = {
  stroke: true,
  color: main_gray_600,
  fillColor: main_gray_600,
  fillOpacity: 0.1,
  weight: 1,
};

export const handleFieldFeature = (feature: Feature, layer: L.Path) => {
  layer.setStyle(GEOMETRY_FEATURE_STYLE);
};
