// @ts-nocheck
import type {SeasonRange} from './types';
import type {Field, Season} from 'containers/map/types';

import Moment from 'moment';
import {extendMoment} from 'moment-range';
//@ts-expect-error error leftover from convertion to strict mode, please fix
const moment = extendMoment(Moment);

/**
 * Returns a season range which is an analogue of Season,
 * but instead of endDate field it has a `range` array,
 * with all the dates between startDate and endDate.
 */
export const createSeasonRange = ({kmlId, id, startDate, endDate}: Season): SeasonRange => ({
  kmlId,
  id,
  startDate,
  range: [...moment.range(moment(startDate), moment(endDate)).by('days')].map(d => d.format()),
});

/**
 * Returns a season range including one month before the start date.
 * Since our minimal range for a season is one month, we want to make sure
 * that user can't choose an overlapping season when they'll be creating a
 * new season on the existing field.
 */
export const protectSeasonRange = (seasonRange: SeasonRange) => ({
  ...seasonRange,
  range: [
    ...moment
      .range(
        moment(seasonRange.startDate).subtract(1, 'M'),
        moment(seasonRange.range[seasonRange.range.length - 1])
      )
      .by('days'),
  ].map(d => d.format('YYYY-MM-DD')),
});

export const calcFieldsSummary = (
  fields: Field[],
  idsToFilter: number[] = []
): {
  selectedFieldsArea: number;
  selectedFields: Field[];
  allFieldsArea: number;
  pivotFields: Field[];
  preparedFields: Field[];
  regions: string[];
  selectedFieldsRegions: string[];
} => {
  const preparedFields = idsToFilter.length
    ? fields.filter(f => !idsToFilter.includes(f.ID))
    : fields;

  const {
    sA: selectedFieldsArea = 0,
    sF: selectedFields = [],
    aA: allFieldsArea = 0,
    regions = {},
    selectedFieldsRegions = {},
  } = preparedFields.reduce(
    (acc, f) =>
      f._selected
        ? {
            sA: acc.sA + f.Area,
            sF: [...acc.sF, f],
            aA: acc.aA + f.Area,
            regions: {...acc.regions, [f.CountryRegion]: ''},
            selectedFieldsRegions: {...acc.selectedFieldsRegions, [f.CountryRegion]: ''},
          }
        : {
            ...acc,
            aA: acc.aA + f.Area,
            regions: {...acc.regions, [f.CountryRegion]: ''},
            selectedFieldsRegions: {...acc.selectedFieldsRegions},
          },
    {sA: 0, sF: [], aA: 0, regions: {}, selectedFieldsRegions: {}}
  );

  const pivotFields = (selectedFields.length ? selectedFields : preparedFields).filter(
    f => f.Pivot
  );

  return {
    selectedFieldsArea,
    selectedFields,
    allFieldsArea,
    pivotFields,
    preparedFields,
    regions: Object.keys(regions).filter(r => r),
    selectedFieldsRegions: Object.keys(selectedFieldsRegions).filter(r => r),
  };
};
