import {Flex, Text} from 'components';
import {CardHr} from 'components/card/card-styled';
import type {ComponentType} from 'react';
import React from 'react';
import cn from 'classnames';
import {CircularProgress} from 'react-md';

type Props = {
  title: string;
  sticky?: boolean;
  isLoading?: boolean;
};

export const MapBarHeader: ComponentType<Props> = ({title, sticky = false, isLoading}) => {
  return (
    <div className={cn('mab-bar__header', {sticky})}>
      <Flex alignItems="center">
        <Text variant="h1" className="mb-0">
          {title}
        </Text>

        {isLoading && (
          <CircularProgress
            className="mt-0 mb-0 ml-05 mr-0"
            scale={0.7}
            id="map-bar-header-progress"
          />
        )}
      </Flex>

      <CardHr />
    </div>
  );
};
