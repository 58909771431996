import {Flex} from 'components';
import {CardSubtitle, CardTitle} from 'containers/si/kpi/styled-components';
import type {ReactElement} from 'react';
import React, {Fragment} from 'react';
import {CardToolTip} from 'containers/map/features/sustainability-insights/filters-panel/cards/components/card-tooltip';
import {genKey} from '_utils/pure-utils';

export interface ChartHeaderProps {
  title?: string;
  subtitle?: string;
  tooltip?: string | ReactElement | number;
}

export const ChartHeader = ({title, subtitle, tooltip}: ChartHeaderProps) => {
  return (
    <Fragment>
      <Flex justifyContent={'space-between'}>
        {title && <CardTitle>{title}</CardTitle>}
        {tooltip && <CardToolTip id={genKey()} content={tooltip} place="bottom" />}
      </Flex>
      {subtitle && <CardSubtitle>{subtitle}</CardSubtitle>}
    </Fragment>
  );
};
