import {FontIcon} from 'react-md';
import styled from 'styled-components';

export const DemoIcon = styled(FontIcon)`
  &.md-icon {
    cursor: pointer;
    position: absolute;
    right: -25px;
    top: 10px;

    @media (max-width: 1040px) {
      font-size: 18px;
      top: 13px;
      right: 25px;
    }

    @media (max-width: 959px) {
      top: 3px;
    }

    @media (max-width: 560px) {
      top: 13px;
    }
  }
`;

export const SelectorFieldWrapper = styled.div`
  position: relative;

  .md-list {
    padding-top: 0; // fix for the mobile view

    .md-list-item:first-child {
      position: sticky;
      top: 0;
      z-index: 9;
      background: #fff;
    }
  }
`;

export const FarmsLoaderWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.88);
  border-radius: 5px;
  font-size: 16px;
  height: 43px;
  gap: 15px;
  padding: 0 15px;
`;
