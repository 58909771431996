import {DialogType} from '../../../modules/helpers';
import type {Season} from '../../map/types';
import type {
  AreaId,
  CountyFIPS,
  SIAggLevel,
  SIPolicy,
  SIPolicyRequestBody,
  StateFP,
} from 'modules/sustainability-insights/types';

export type AreaMeta = {
  id: AreaId;
  name: string;
  statefp?: StateFP;
  areaType: SIAggLevel;
};

export type AdminFeaturesStore = {
  fields: FField[];
  tagKeys: Tags[];
  result: FField[];
  farms: any[];
  entities: any[];
  rawFieldsData: FField[];
  SIPolices: SIPolicyRequestBody[];
  SIData: {
    statesIds: StateFP[];
    countiesIds: CountyFIPS[];
    meta: Record<AreaId, AreaMeta>;
  };
};

export type SwitchFeatureAction = {
  type: ActionType.SWITCH_FEATURE;
  key: Tags;
  value: boolean;
  entityId: number;
  entityType: FeatureEntity;
  keyId: number;
};

export type SwitchBulkFeatureAction = {
  type: ActionType.BULK_SWITCH_FEATURE;
  createdKeys: any[];
  key: Tags;
  entityType: FeatureEntity;
  value: boolean;
};

export type SetSearchResultAction = {
  type: ActionType.SET_SEARCH_RESULT;
  fields: FField[];
};

export type SetTagKeysAction = {
  type: ActionType.SET_TAG_KEYS;
  tagKeys: Tags[];
};

export type SetEntitiesAction = {
  type: ActionType.SET_ENTITIES;
  entities: any[];
};

export type SetActivationResultAction = {
  type: ActionType.SET_ACTIVATION_RESULT;
  result: any[];
};

export type SetFarmsResultAction = {
  type: ActionType.SET_FARMS_RESULT;
  farms: any[];
  rawFieldsData?: FField[];
};

export type SetEmailsAction = {
  type: ActionType.SET_EMAILS;
  farmOrSeasonId: number;
  emails: string[];
  manualReview: string;
  entityId: number;
  entityType: EntityType;
};

export type ExtendEntitiesAction = {
  type: ActionType.EXTEND_ENTITIES;
  entities: any[];
};

export type SetSeasonSelectorAction = {
  type: ActionType.SET_SELECTOR_SEASON;
  farmId: number;
  fieldId: number;
  season: BaseSeason;
};

export type LoadSIPolices = {
  type: ActionType.SI_TAGGING_LOAD_POLICES;
  polices: SIPolicy[];
};
export type UpdateSIPolicy = {
  type: ActionType.SI_TAGGING_UPDATE_POLICY;
  policy: SIPolicyRequestBody;
};
export type DeleteSIPolices = {
  type: ActionType.SI_TAGGING_DELETE_POLICY;
  userId: number;
};
export type LoadSIAllStates = {
  type: ActionType.SI_TAGGING_LOAD_ALL_STATES;
  payload: AdminFeaturesStore['SIData'] & {
    yearsAvailable: number[];
    metricGroupsAvailable: string[];
    aggLevelsAvailable: string[];
  };
};

export type FeatureAction =
  | SwitchFeatureAction
  | SwitchBulkFeatureAction
  | SetSearchResultAction
  | SetTagKeysAction
  | SetEntitiesAction
  | SetActivationResultAction
  | SetFarmsResultAction
  | SetEmailsAction
  | ExtendEntitiesAction
  | SetSeasonSelectorAction
  | LoadSIPolices
  | UpdateSIPolicy
  | DeleteSIPolices
  | LoadSIAllStates;

export enum ActionType {
  SWITCH_FEATURE = 'features/switch-feature',
  BULK_SWITCH_FEATURE = 'features/bulk-switch-feature',
  SET_SEARCH_RESULT = 'features/set-search-result',
  SET_TAG_KEYS = 'features/set-tag-keys',
  SET_ENTITIES = 'features/set-entities',
  SET_ACTIVATION_RESULT = 'features/set-activation-result',
  SET_FARMS_RESULT = 'features/set-farms-result',
  SET_EMAILS = 'features/set-emails',
  EXTEND_ENTITIES = 'features/extend-entities',
  SET_SELECTOR_SEASON = 'features/set-season-selector',
  SI_TAGGING_LOAD_POLICES = 'features/SI-tagging/load-polices',
  SI_TAGGING_UPDATE_POLICY = 'features/SI-tagging/update-policy',
  SI_TAGGING_DELETE_POLICY = 'features/SI-tagging/delete-policy',
  SI_TAGGING_LOAD_ALL_STATES = 'features/SI-tagging/load-states',
}

export type EntityType = 'farm' | 'field' | 'season';

export enum OneTimeReportType {
  CropStress = 'crop-stress-one-time-email',
  CropPerformance = 'one-time-email',
  PivoThermo = 'pivothermo-one-time-email',
  EndOfSeason = 'end-of-season',
  SeasonSelector = 'season-selector',
}

export const featureIdToDialog: {[key in OneTimeReportType]?: DialogType} = {
  [OneTimeReportType.CropPerformance]: DialogType.cropPerformanceReport,
  [OneTimeReportType.CropStress]: DialogType.cropStressReport,
  [OneTimeReportType.PivoThermo]: DialogType.pivothermoReport,
  [OneTimeReportType.EndOfSeason]: DialogType.endOfSeasonReport,
};

export type RowType = 'farm' | 'field';

export type BaseSeason = {
  seasonId: number;
  cropType: string;
  cropVariety: string;
  startDate: string;
  endDate: string;
};

export enum Tags {
  AnomalyDetection = 'anomaly_detection',
  NRx = 'nrx',
  CropStress = 'crop_stress',
  TreeAnalysis = 'tree_analysis',
  PivothermoReport = 'pivothermo_report',
  SustainabilityInsights = 'sustainability_insights', // no back-end tag value for this one (different implementation)
}

export type FFeature = {
  [key: string]: FTag;
};

export type FTag = {
  id: number;
  key: Tags;
  value?: any;
  created_at: string;
};

export type FField = {
  fieldName: string;
  fieldId: number;
  farmName: string;
  farmId: number;
  seasonId: number;
  cropType: string;
  cropVariety: string;
  startDate: string;
  endDate: string;
  organizationName: string;
  organizationId: number;
  subscriptionName: string;
  subscriptionId: number;
  regionName: string;
  regionId: number;
  direct_tags: FTag[];
  features: FFeature;
  seasons: SeasonWithFeatures[];
};

export const tagAliases: Record<Tags, string> = {
  [Tags.NRx]: 'Crop Nutrition',
  [Tags.AnomalyDetection]: 'Crop Stress (old)',
  [Tags.CropStress]: 'Crop Stress',
  [Tags.TreeAnalysis]: 'Tree Analysis',
  [Tags.PivothermoReport]: 'Pivothermo Report',
  [Tags.SustainabilityInsights]: 'Sustainability Insights',
};

export type SeasonWithFeatures = Season & {
  features: FFeature;
  tags: FTag[];
};

export enum FeatureEntity {
  Field = 'field',
  Season = 'season',
}
// premium apps

export enum FilterType {
  Years = 'years',
  Crops = 'crop_types',
  Entities = 'entities',
  ActivatedState = 'tags',
}

export type PerSeasonFilter = {
  [FilterType.Years]: number[];
  [FilterType.Crops]: string[];
  [FilterType.Entities]: string[];
  [FilterType.ActivatedState]: string;
};

export type SearchOptionalParams = {
  with_seasons?: boolean;
  [FilterType.Years]?: number[];
  [FilterType.Crops]?: string[];
  [FilterType.ActivatedState]?: string;
};
