import styled from 'styled-components';
import {theme} from '@regrow-internal/design-system';

import colors from 'variables.scss';

type InputWrapperProps = {
  error?: boolean;
  topMargin: boolean;
  isPassWithIcon?: boolean;
};

export const InputWrapper = styled.div`
  position: relative;
  margin-top: ${({topMargin}: InputWrapperProps) => (topMargin ? '18px' : '0')};

  .label {
    color: ${colors['main-gray-600']};
    box-sizing: border-box;
    margin-bottom: 4px;
    display: block;
    font-size: 12px;
    line-height: 1;
    text-overflow: ellipsis;
    text-align: left;
    user-select: none;
    white-space: nowrap;
    width: 100%;
  }

  .input-container {
    border: 1px solid
      ${(p: InputWrapperProps) => (p.error ? theme.color.stroke.error : theme.color.stroke.subtle)};
    border-radius: ${({theme}) => theme.borderRadius};
    display: flex;
    align-items: center;

    &:hover,
    &:focus {
      outline: 0;
      border-color: ${({theme}) => theme.color.stroke.subtle};
    }
  }

  .input {
    color: ${colors['main-gray-900']};
    background-color: #fff;
    border: none;
    font-size: 14px;
    padding: 13px 10px;
    flex-grow: 1;

    &:hover,
    &:focus {
      outline: 0;
    }
    &:disabled {
      background-color: ${({theme}) => theme.color.surface.main};
      color: ${colors['main-gray-600']};
      cursor: not-allowed;
    }
  }

  .pass-btn {
    display: flex;
    margin-right: 10px;
  }
`;
