import type {GeoJSON as GeoJSONType, PathOptions} from 'leaflet';
import React, {forwardRef, memo, useEffect, useRef} from 'react';
import type {GeoJSONProps} from 'react-leaflet';
import {GeoJSON} from 'react-leaflet';
import {resolveEventHandlers} from './fix-click-handler';

type FluroGeoJsonProps = GeoJSONProps &
  // Flatten the path options, so they can be memoized.
  Pick<PathOptions, 'weight' | 'opacity' | 'fillColor' | 'fillOpacity' | 'color' | 'className'>;
export const FluroGeoJson = memo(
  forwardRef<GeoJSONType, FluroGeoJsonProps>(
    (
      {eventHandlers, weight, opacity, fillColor, fillOpacity, color, className, ...geoJsonProps},
      propRef
    ) =>
      // ref2 // TODO (stas): I don't really know what it was done this way. I'm using the regular ref instead.
      {
        const ref = useRef<GeoJSONType>(null);
        // We need to unsubscribe from the event handlers when the component is unmounted,
        // to prevent multiple calls when hotreload happens or just clicking CLU boundaries.
        // For some reason React-leaflet seems to be doing bad job, or we're using it in a wrong way.
        useEffect(() => {
          return () => {
            if (!eventHandlers) return;
            Object.keys(eventHandlers).forEach(key => {
              propRef ?? ref.current?.off(key);
            });
          };
        }, [eventHandlers, propRef]);

        const hackedEventHandlers: GeoJSONProps['eventHandlers'] =
          resolveEventHandlers(eventHandlers);
        return (
          <GeoJSON
            ref={propRef ?? ref}
            pathOptions={{weight, opacity, fillColor, fillOpacity, color, className}}
            {...geoJsonProps}
            eventHandlers={hackedEventHandlers}
          />
        );
      }
  )
);
