import {t} from 'i18n-utils';
import React, {useCallback, useMemo} from 'react';
import {GeoJSON} from 'react-leaflet';
import type {LeafletMouseEvent} from 'leaflet';
import L from 'leaflet';
import {COLOR_DARK_BLUE, COLOR_GREEN, COLOR_DARK_GRAY} from '_constants/geometries-colors';
import {GeometriesPopup} from 'components/anomalies-ui/geometries-popup/geometries-popup';

import {togglePopup, setFeature} from 'containers/map/actions';
import {
  toggleEditAreaOfInterest,
  removeAreaOfInterest,
  changeAreaOfInterestProp,
} from 'containers/map/actions/areas-of-interest-actions';
import {getAnalyticsData} from 'containers/map/actions/analytics-actions';
import {
  changeLowPerfAnomalyProp,
  toggleLowPerformingAreas,
  changeAnyTypeGeometryProp,
} from 'containers/map/actions/anomalies-actions';

import {showNotification} from 'components/notification/notification';
import {isPermanentAnomalyLabel} from './anomalies-utils';
import AnomalyLabelsDropdown from './labels-dropdown';
import {useAppDispatch, useAppSelector} from '_hooks';
import type {IAnomaly} from './types';
import {resolveEventHandlers} from 'components/fluro-leaflet/fix-click-handler';
import {selectAllVisibleGeometries, selectLowPerformingAnomalies} from './anomalies-selectors';
import {
  selectCurrentTab,
  selectGeometriesOnMap,
  selectMapOpenPopUpId,
} from 'containers/map/reducer/selectors';
import {selectAnalyticsItemColor} from 'containers/map/features/analytics/analytics-selectors';

const GeometriesOverlay = () => {
  const lowPerfAnomalies = useAppSelector(selectLowPerformingAnomalies);
  const allVisibleGeometries = useAppSelector(selectAllVisibleGeometries);
  const visibleLowPerfAnomalies = lowPerfAnomalies.isVisible;
  const geometriesOnMap = useAppSelector(selectGeometriesOnMap);
  const nextGeometryColor = useAppSelector(selectAnalyticsItemColor);

  const geometriesToShow = useMemo(() => {
    return visibleLowPerfAnomalies
      ? allVisibleGeometries
      : allVisibleGeometries.filter(el => (el.properties.isLowPerf ? el.properties.label : true));
  }, [visibleLowPerfAnomalies, allVisibleGeometries]);

  const dispatch = useAppDispatch();
  const selectedTab = useAppSelector(selectCurrentTab);
  const openPopupId = useAppSelector(selectMapOpenPopUpId);

  const onEachFeature = (feature: IAnomaly, layer: L.Path) => {
    const {properties} = feature;
    const {checked, id, anomaly_id} = properties;
    const isAnalytics = selectedTab === 'analytics';
    const isRoi = !properties.isLowPerf;

    const color = properties.color
      ? properties.color
      : isPermanentAnomalyLabel(properties.type)
      ? COLOR_DARK_GRAY
      : COLOR_DARK_BLUE;

    if (isPermanentAnomalyLabel(properties.type)) {
      layer.setStyle({color: 'transparent', fillColor: COLOR_DARK_GRAY, fillOpacity: 0.6});
    }

    if (openPopupId === id) layer.setStyle({color: COLOR_DARK_GRAY});
    if (checked) layer.setStyle({color: COLOR_GREEN});
    if (isAnalytics)
      layer.setStyle({color: color ? color : checked ? COLOR_GREEN : COLOR_DARK_BLUE});

    layer.on({
      click: resolveEventHandlers({
        click: (event: LeafletMouseEvent) => {
          if (isAnalytics) {
            L.DomEvent.stopPropagation(event);
            const color = properties.color ? null : nextGeometryColor;
            if (allVisibleGeometries.filter(g => g.properties.color).length >= 10 && color) {
              return showNotification({
                title: t({id: 'note.warning', defaultMessage: 'Warning'}),
                message: t({
                  id: 'You can select up to 10 geometries.',
                  defaultMessage: 'You can select up to 10 geometries.',
                }),
                type: 'warning',
              });
            }
            isRoi
              ? dispatch(changeAreaOfInterestProp(feature, 'color', color))
              : dispatch(changeLowPerfAnomalyProp(feature, 'color', color));
            layer.setStyle({color: color || COLOR_DARK_BLUE});
            color && dispatch(getAnalyticsData());
          } else {
            const isOpen = openPopupId === id || anomaly_id;
            dispatch(togglePopup(isOpen ? undefined : id || anomaly_id));
          }
        },
      })?.click,
    });
  };

  const onEdit = (geometry: IAnomaly) => {
    selectedTab !== 'crop' && dispatch(setFeature('crop'));
    geometry.properties.isLowPerf
      ? dispatch(toggleLowPerformingAreas(true))
      : dispatch(toggleEditAreaOfInterest(true, geometry));
  };

  const onDeleteGeometry = useCallback((geometry: IAnomaly) => {
    if (geometry.properties.isLowPerf) {
      dispatch(changeLowPerfAnomalyProp(geometry, 'label', ''));
      dispatch(togglePopup(undefined));
      return dispatch(toggleLowPerformingAreas(false));
    } else {
      dispatch(removeAreaOfInterest(geometry.properties.id));
    }
  }, []);

  const onChangeLabel = useCallback((prop: string, value: string, geometry: IAnomaly) => {
    dispatch(changeAnyTypeGeometryProp(geometry, 'label', value));
  }, []);

  const onPopUpClose = useCallback(() => dispatch(togglePopup(undefined)), []);

  if (!geometriesOnMap) return null;

  return (
    <>
      {geometriesToShow.map((el, i) => (
        <Geometry
          key={'geometry-overlay' + i}
          geometry={el}
          onEdit={onEdit}
          onDeleteGeometry={onDeleteGeometry}
          onEachFeature={onEachFeature}
          onChangeLabel={onChangeLabel}
          onPopUpClose={onPopUpClose}
        />
      ))}
    </>
  );
};

interface GeometryProps {
  geometry: IAnomaly;
  onEachFeature: (feature: IAnomaly, layer: L.Path) => void;
  onDeleteGeometry: (geometry: IAnomaly) => void;
  onEdit: (geometry: IAnomaly) => void;
  onChangeLabel: (prop: string, value: string, geometry: IAnomaly) => void;
  onPopUpClose: () => void;
}

const Geometry = ({
  geometry,
  onEachFeature,
  onDeleteGeometry,
  onEdit,
  onChangeLabel,
  onPopUpClose,
}: GeometryProps) => {
  const openPopupId = useAppSelector(s => s.map.openPopupId);
  const readOnly = useAppSelector(s => s.map.group.readOnly);

  const {id, anomaly_id} = geometry.properties;
  const popup = openPopupId && (openPopupId === id || openPopupId === anomaly_id);

  return (
    <React.Fragment key={anomaly_id}>
      <GeoJSON
        data={geometry}
        pane={'field-geometry'}
        onEachFeature={onEachFeature}
        key={new Date().getTime()}
      />
      {popup && (
        <GeometriesPopup
          data={geometry}
          onClose={onPopUpClose}
          onDelete={() => onDeleteGeometry(geometry)}
          onEdit={() => onEdit(geometry)}
          sectionName={t({id: 'Area of interest', defaultMessage: 'Area of interest'})}
          sensingDate={geometry.properties.startDate}
          LabelDropdown={({label}) => (
            <AnomalyLabelsDropdown
              miniButton
              disabled={readOnly}
              anomaly={geometry}
              label={label}
              onChange={(prop, value, anomaly) =>
                onChangeLabel(prop, String(value), anomaly as IAnomaly)
              }
            />
          )}
        />
      )}
    </React.Fragment>
  );
};

export default GeometriesOverlay;
