import Server from './server';
import cancelTokenStore from './cancel-tokens-store';

export default class {
  static getKeysForEntity(entity: string) {
    return Server.get(`admin/tags/keys/${entity}`);
  }

  static getTagEntities(entity_type: string, key: string) {
    cancelTokenStore.cancel(`getTagEntities`);
    const source = cancelTokenStore.generateSource(`getTagEntities`);
    return Server.get('admin/tags', {
      cancelToken: source.token,
      params: {__skipPreloader: true, entity_type, key},
    });
  }

  static createEntity(tagObj: any) {
    return Server.post(`admin/tags`, tagObj);
  }

  static updateEntity(tagObj: any) {
    return Server.put(`admin/tags`, tagObj);
  }

  static removeEntity(tagID: number) {
    return Server.delete(`admin/tags/${tagID}`);
  }

  static removeTag(tag: string, entityType: string) {
    return Server.delete(`admin/tags`, {params: {entity_type: entityType, key: tag}});
  }

  static bulkRemoveTag(IDs: number[]) {
    return Server.delete(`admin/tags`, {data: {id: IDs}});
  }
}
