import initialState from '../initial-state';
import type {IAction, IInitialMapState} from '../../types';
import {ActionTypes} from '../types';
import {toggle} from '_utils/pure-utils';

const AnalyticsReducer = (state = initialState, action: IAction): IInitialMapState => {
  switch (action.type) {
    case ActionTypes.MAP_ADD_ANALYTIC_POINT:
      return {
        ...state,
        analytics: {
          ...state.analytics,
          addedPoints: [...state.analytics.addedPoints, action.point],
        },
      };

    case ActionTypes.MAP_UPDATE_ANALYTIC_POINT:
      return {
        ...state,
        analytics: {
          ...state.analytics,
          addedPoints: [
            ...state.analytics.addedPoints.map(point => {
              if (action.positions[point.color]) {
                point.latlng = action.positions[point.color];
              }

              return point;
            }),
          ],
        },
      };

    case ActionTypes.MAP_REMOVE_ANALYTIC_POINT:
      return {
        ...state,
        analytics: {
          ...state.analytics,
          addedPoints: state.analytics.addedPoints.filter(point => point.color !== action.color),
          trendsData: state.analytics.trendsData.filter(point => point.color !== action.color),
        },
      };

    case ActionTypes.MAP_ANALYTIC_TOGGLE_POINT_VISIBILITY: {
      return {
        ...state,
        analytics: {
          ...state.analytics,
          hiddenPointColors: toggle(state.analytics.hiddenPointColors, action.color),
        },
      };
    }

    case ActionTypes.MAP_SET_FIELD_SMOOTHED_VALUES: {
      return {
        ...state,
        analytics: {
          ...state.analytics,
          fieldSmoothedCategories: action.allDates,
          fieldSmoothedData: action.fieldData,
        },
      };
    }

    case ActionTypes.MAP_ADD_ANALYTIC_TRENDS_DATA: {
      return {
        ...state,
        analytics: {
          ...state.analytics,
          trendsData: [...state.analytics.trendsData, ...action.values],
          categories: action.allDates,
        },
      };
    }

    case ActionTypes.MAP_SET_ARABLE_SENSOR_DATA: {
      return {
        ...state,
        analytics: {
          ...state.analytics,
          arableData: action.data,
        },
      };
    }

    default:
      return state;
  }
};

export default AnalyticsReducer;
