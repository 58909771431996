import {Flex, Text} from 'components';
import {CropAvatarLite} from 'components/crop-avatar/crop-avatar-lite';
import type {FluroSelectLiteItem} from 'components/fluro-select-lite/fluro-select-lite';
import {FluroSelectLite} from 'components/fluro-select-lite/fluro-select-lite';
import {selectCropLabelPropMRV} from 'containers/login/login-selectors';
import {t} from 'i18n-utils';
import type {CropType} from 'modules/global/types';
import type {ComponentType} from 'react';
import React, {useContext, useMemo} from 'react';
import {useAppSelector} from '_hooks';
import {getGetURLParam} from '_utils/pure-utils';
import {InputContext} from './input-context';
import {MRVSelectContainer} from './styled';
import {cropAppToServer, cropServerToApp} from 'containers/mrv/enrollment/base/crop-practice';

type Props = {
  options?: FluroSelectLiteItem[];
  onChange: (value: string) => void;
};

export const CropSelect: ComponentType<Props> = ({options: optionsProp, onChange}) => {
  const {
    id,
    label,
    value,
    defaultValue,
    subtitle,
    options: attributeOptions,
    disabled,
    placeholder,
  } = useContext(InputContext);
  // TODO (stas): send the right id (or cdl id) when it's decided what we want to store. Until then use a string value.
  // const defaultValueId = useAppSelector(s => selectCropType(s, defaultValue))?.id;
  // const idFromValue = useAppSelector(s => selectCropType(s, value))?.id || value;

  // TODO (stas): Pass this prop from above. Parsing the url in each cell is gonna kill the memory.
  const showDebugValues = getGetURLParam('debugValues') === 'true';

  const labelProp = useAppSelector(selectCropLabelPropMRV);

  // Options list has crops with ids instead of cdl_ids.
  const options = useMemo(() => {
    if (optionsProp?.length) return optionsProp;
    if (!attributeOptions) return [];
    // Not sure how to universally type the Options, so gonna cast the crop type options here.
    return (attributeOptions as CropType[]).map(crop => {
      return {
        // TODO (stas): send the right id (or cdl id) when it's decided what we want to store. Until then use a string value.
        // value: crop.id,
        value: crop.value,
        icon: <CropAvatarLite cropType={crop.value} iconSrc={crop.icon_url} />,
        label: crop?.[labelProp] || crop.label,
      };
    });
  }, [optionsProp, attributeOptions]);

  // project values can be saved only as string type
  // in this case we should convert string to int to find crop by int crop ID
  // TODO (stas): send the right id (or cdl id) when it's decided what we want to store. Until then use a string value.
  // const v = parseInt(idFromValue || defaultValueId || defaultValue, 10);
  const v = value || defaultValue;

  // Hack FSB-11273 to not show optis confidence for crop values that are not enabled.
  let finalSubtitle = subtitle;
  const hasValue = options.some(o => o.value === v);
  if (!hasValue && v) {
    finalSubtitle = undefined;
  }

  return (
    <MRVSelectContainer>
      <FluroSelectLite
        id={id}
        items={options}
        disabled={disabled}
        selectedValue={cropServerToApp(v)}
        placeholder={
          placeholder || t({id: 'Placeholder.Select crop', defaultMessage: 'Select crop'})
        }
        label={label}
        subtitle={finalSubtitle}
        onSelect={v => onChange(cropAppToServer(v))}
        className={'input-crop-select'}
        isSearchable
      />
      {showDebugValues && (
        <Flex gap="4px">
          <Text secondary variant="small-thin">
            {id}
          </Text>
          <Text secondary variant="small-thin" className="font-weight-500">
            {value}
          </Text>
        </Flex>
      )}
    </MRVSelectContainer>
  );
};
