import React, {useEffect, useMemo, useState} from 'react';
import {useDispatch} from 'react-redux';
import type {RouteComponentProps} from 'react-router-dom';
import {Link} from 'react-router-dom';
import {getUsersList} from '../actions';
import {MAPPING_ROLES_TO_PROPS} from '_constants';
import {history} from '_utils/history-utils';
import {FontIcon, TextField} from 'react-md';

import {
  FluroTableColumn,
  FluroTableHeader,
  FluroTableRow,
  FluroDataTable,
  FluroTableBody,
  FluroTablePagination,
  FluroButton,
  FluroChip,
  Flex,
} from 'components';

import {calcRowsPerPageItems, rowsPerPage} from 'containers/admin';
import {ImpersonateButton} from 'containers/admin/users/impersonate-button';
import {useAppSelector} from '_hooks';
import {AdminContainer, AdminContentWrapper, AdminControls, AdminTitle} from '../../admin.styled';
import {isWorkspace, workspaceLabels} from '../types';
import {RequestStatusRTK} from 'modules/helpers';
import {PlaceholderLoader} from 'components/placeholder-loader/placeholder-loader';
import {makeSureEndsWithSlash} from '_utils/pure-utils';
import {useWorkspace} from '_hooks/use-workspace';

const UserList = ({match}: RouteComponentProps) => {
  const dispatch = useDispatch();
  const rawList = useAppSelector(store => store.users.list);
  const isLoading = useAppSelector(
    s => s.helpers.asyncRTK.status[getUsersList.typePrefix] === RequestStatusRTK.pending
  );
  const [searchString, onSearch] = useState('');
  const [pagination, onChangePagination] = useState({start: 0, perPage: rowsPerPage, page: 1});
  const {workspaceLink} = useWorkspace();

  const preparedList = useMemo(() => {
    return rawList.map(user => {
      const loweredName = (user.name || '').toLowerCase();
      const loweredSurname = (user.surname || user.settings?.surname || '').toLowerCase();
      return {
        ...user,
        loweredEmail: user.email.toLowerCase(),
        loweredName,
        loweredSurname,
        loweredFullName: `${loweredName} ${loweredSurname}`,
      };
    });
  }, [rawList]);

  useEffect(() => {
    dispatch(getUsersList());
  }, []);

  const handlePagination = (start: number, perPage: number, page: number) => {
    onChangePagination({start, perPage, page});
  };

  const filteredList = useMemo(() => {
    const lowerSearchString = searchString.toLowerCase();
    onChangePagination({start: 0, perPage: pagination.perPage, page: 1});
    return preparedList.filter(
      user =>
        user.loweredName.includes(lowerSearchString) ||
        user.loweredEmail.includes(lowerSearchString) ||
        user.loweredFullName.includes(lowerSearchString) ||
        user.loweredSurname.includes(lowerSearchString)
    );
  }, [preparedList, searchString]);

  const slicedList = useMemo(() => {
    return filteredList.slice(pagination.start, pagination.start + pagination.perPage);
  }, [pagination, filteredList]);

  const addNew = () => {
    history.push(`${workspaceLink}/global-admin/users/new`);
  };

  return (
    <AdminContainer className={'users-list'}>
      <AdminControls
        className={'header-controls'}
        alignItems={'flex-end'}
        justifyContent={'space-between'}
      >
        <AdminTitle>Users</AdminTitle>
        <div className={'search-wrapper'}>
          <FontIcon>search</FontIcon>
          <TextField
            id="search-user"
            label="Search a user"
            className="search-a-user"
            value={searchString}
            onChange={value => onSearch(`${value}`)}
          />
        </div>

        <FluroButton
          id={'add-new-user-btn'}
          className={'add-button'}
          raised
          primary
          onClick={addNew}
          iconEl={<FontIcon>add</FontIcon>}
        >
          Add new user
        </FluroButton>
      </AdminControls>

      <AdminContentWrapper>
        <FluroDataTable fixedDividers={true} baseId="users-admin-list">
          <FluroTableHeader>
            <FluroTableRow>
              <FluroTableColumn type={'number'}>ID</FluroTableColumn>
              <FluroTableColumn>Email</FluroTableColumn>
              <FluroTableColumn>Name</FluroTableColumn>
              <FluroTableColumn>Workspaces</FluroTableColumn>
              <FluroTableColumn>Permission</FluroTableColumn>
              <FluroTableColumn>Active</FluroTableColumn>
              <FluroTableColumn>Login as</FluroTableColumn>
            </FluroTableRow>
          </FluroTableHeader>

          <FluroTableBody>
            {isLoading
              ? mockList.map(i => (
                  <FluroTableRow key={i}>
                    <FluroTableColumn type={'number'}>
                      <PlaceholderLoader borderRadius="m" height={20} width={30} />
                    </FluroTableColumn>

                    <FluroTableColumn>
                      <PlaceholderLoader borderRadius="m" height={20} />
                    </FluroTableColumn>

                    <FluroTableColumn>
                      <PlaceholderLoader borderRadius="m" height={20} />
                    </FluroTableColumn>

                    <FluroTableColumn>
                      <PlaceholderLoader borderRadius="m" height={20} />
                    </FluroTableColumn>

                    <FluroTableColumn>
                      <PlaceholderLoader borderRadius="m" height={20} />
                    </FluroTableColumn>

                    <FluroTableColumn>
                      <PlaceholderLoader borderRadius="m" height={20} />
                    </FluroTableColumn>
                    <FluroTableColumn>
                      <PlaceholderLoader borderRadius="m" height={20} width={30} />
                    </FluroTableColumn>
                  </FluroTableRow>
                ))
              : slicedList.map(u => (
                  <FluroTableRow key={'u-row-' + u.id}>
                    <FluroTableColumn type={'number'}>#{u.id}</FluroTableColumn>

                    <FluroTableColumn>
                      <Link to={`${makeSureEndsWithSlash(match.url)}${u.id}`}>{u.email}</Link>
                    </FluroTableColumn>

                    <FluroTableColumn>{`${u.name} ${
                      u.surname || u.settings?.surname
                    }`}</FluroTableColumn>

                    <FluroTableColumn>
                      <Flex gap="5px" className="m-05">
                        {Object.keys(u.workspaces || {})
                          .filter(isWorkspace)
                          .filter(w => u.workspaces?.[w])
                          .map(w => (
                            <FluroChip key={w} active={true} label={workspaceLabels[w]} />
                          ))}
                      </Flex>
                    </FluroTableColumn>

                    <FluroTableColumn>{MAPPING_ROLES_TO_PROPS[u.perm].prop}</FluroTableColumn>

                    <FluroTableColumn>{u.active ? 'Yes' : 'No'}</FluroTableColumn>
                    <FluroTableColumn>
                      <ImpersonateButton email={u.email} userId={u.id as number} />
                    </FluroTableColumn>
                  </FluroTableRow>
                ))}
          </FluroTableBody>
          <FluroTablePagination
            page={pagination.page}
            rows={filteredList.length}
            rowsPerPage={pagination.perPage}
            onPagination={handlePagination}
            rowsPerPageItems={calcRowsPerPageItems(filteredList.length)}
          />
        </FluroDataTable>
      </AdminContentWrapper>
    </AdminContainer>
  );
};

const mockList = Array.from({length: 10}, (_, i) => i);

export default UserList;
