import type {AppStore} from 'reducers';
import type {CSGViewModel} from './types';
import {cropGrowthStageColors} from './types';
import {createSelector} from '@reduxjs/toolkit';
import type {CPFilterType} from '../../../../_reducers/crop-performance-filter/field-filter-types';

export const selectGrowthStageChartData = (records: CSGViewModel[]) => {
  const result: {
    [growthStageLabel: string]: {
      area: number;
      value: number;
      color: string;
    };
  } = {};
  records.forEach(r => {
    if (!r.growthStage || !r.growthStageCode) return;

    const KeyInObject = r.growthStage;
    if (!result[KeyInObject]) {
      result[KeyInObject] = {
        value: 0,
        color: cropGrowthStageColors[r.growthStageCode],
        area: 0,
      };
    }
    result[KeyInObject].value = result[KeyInObject].value + 1;
    result[KeyInObject].area = result[KeyInObject].area + r.fieldArea;
  });

  const values = Object.values(result);

  if (!values.length) return null;
  return {
    labels: Object.keys(result),
    values: values.map(({value}) => value),
    colors: values.map(({color}) => color),
    areas: values.map(({area}) => area),
  };
};

export const selectCropPerformance = (state: AppStore) => state.cropPerformance;

export const selectCropPerformanceAggregation = createSelector(
  [selectCropPerformance],
  cropPerformance => cropPerformance.aggregation
);

export const selectCropPerformanceFilter = (state: AppStore) => state.cropPerformanceFilter;

export const selectCropPerformanceFilterValues = createSelector(
  [
    selectCropPerformanceFilter,
    (state: AppStore, filterType: CPFilterType) => filterType,
    (state: AppStore, filterType: CPFilterType, defaultValue = '') => defaultValue,
  ],
  (cropPerformanceFilter, filterType: CPFilterType, defaultValue = '') => {
    return cropPerformanceFilter.filters[filterType].map(f => (f.value as string) || defaultValue);
  }
);
