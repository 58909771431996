import React from 'react';
import {connect} from 'react-redux';
import {handleOpenTS, saveMarkersFromStore} from './reducer';
import TSGrid from './ts-grid';
import {TSConfirm} from './ts-confirm';
import FluroDialog from 'components/fluro-dialog';

const TSUploadDialog = props => (
  <FluroDialog
    id="ts-upload--dialog"
    visible={props.isOpenDialog}
    className="upload-tsp-dialog"
    title="Upload sampling points"
    focusOnMount={false}
    isDraggable
    portal
    onHide={props.handleOpenTS.bind(this, false)}
  >
    {props.markers.length && !Object.keys(props.confirmData || {}).length ? <TSGrid /> : null}
    {Object.keys(props.confirmData || {}).length ? (
      <TSConfirm
        onHide={props.handleOpenTS.bind(this, false)}
        markers={props.markers}
        saveMarkers={props.saveMarkersFromStore}
        data={props.confirmData}
      />
    ) : null}
  </FluroDialog>
);

const mapStateToProps = ({tsUpload}) => ({
  isOpenDialog: tsUpload.isOpenDialog,
  markers: tsUpload.markers,
  confirmData: tsUpload.confirmData,
});

export default connect(mapStateToProps, {handleOpenTS, saveMarkersFromStore})(TSUploadDialog);
