import React from 'react';
import {Flex, FluroChip, FluroDialog, Text} from 'components';
import type {OutcomeKPI, PracticeKPI} from 'containers/si/api/apiTypes';
import {outcomeKPILabel, practiceKPILabel} from 'containers/si/constants';

type KPIsPopupProps = {
  title: string;
  outcome_kpis?: OutcomeKPI[];
  practice_kpis?: PracticeKPI[];
  onAddValue: (item: string) => void;
  onRemoveValue: (item: string) => void;
  onHide: () => void;
  portal?: boolean;
  SecondaryButton?: React.ComponentType;
};

export const KPIsPopup = ({
  title,
  outcome_kpis = [],
  practice_kpis = [],
  onAddValue,
  onRemoveValue,
  onHide,
  portal = true,
  SecondaryButton,
}: KPIsPopupProps) => {
  return (
    <FluroDialog
      id={'edit-kpis-popup'}
      title={title}
      onHide={onHide}
      visible={true}
      width={400}
      portal={portal}
    >
      <Flex>
        <Text>Select agriculture practices to monitor:</Text>
        {Object.entries(practiceKPILabel).map(value => {
          const [item, label] = value as [PracticeKPI, string];
          const active = practice_kpis.includes(item);

          return (
            <FluroChip
              key={item}
              label={label}
              tone={'dimmed'}
              hasToggleIcon
              active={active}
              className="mb-05"
              onRemove={() => onRemoveValue(item)}
              onClick={_ => {
                active ? onRemoveValue(item) : onAddValue(item);
              }}
            />
          );
        })}
        <Text>Select sustainability outcomes to monitor:</Text>
        {Object.entries(outcomeKPILabel).map(value => {
          const [item, label] = value as [OutcomeKPI, string];
          const active = outcome_kpis.includes(item);

          return (
            <FluroChip
              key={item}
              label={label}
              tone={'dimmed'}
              hasToggleIcon
              active={active}
              className="mb-05"
              onRemove={() => onRemoveValue(item)}
              onClick={_ => {
                active ? onRemoveValue(item) : onAddValue(item);
              }}
            />
          );
        })}
      </Flex>
      {SecondaryButton && <SecondaryButton />}
    </FluroDialog>
  );
};
