import React, {useState, memo, useCallback} from 'react';
import type {CropMatchListItem} from './crop-types-matching.module';
import {updateRule} from './crop-types-matching.module';
import MatchStatus from './match-status';
import CropSelect from 'components/crop-select';
import {dialogToggle, DialogType} from 'modules/helpers';
import {getCropLabelById} from '_utils/pure-utils';
import {ActionsColumn, CropColumn} from './crop-types-matching.styled';
import {WarningSvg} from 'components/icons';
import {FluroTableColumn, FluroTableRow, InfoBlock, FluroButton} from 'components';
import {useAppDispatch, useAppSelector} from '_hooks';
import {selectCropTypes} from 'modules/global/selectors';

type Props = {
  data: CropMatchListItem;
};

/*
* ID               uint      `json:"id"`
	WrongCropValue   string    `json:"wrong_crop_value"`
	CorrectCropValue string    `json:"correct_crop_value"`
	CreatedBy        uint      `json:"created_by"`
	CreatedAt        time.Time `json:"created_at"`
	UpdatedAt        time.Time `json:"updated_at"`
* */

const CropTypesMatchingRow = memo(({data}: Props) => {
  const [correctCrop, setCorrectCrop] = useState(data.correctCrop);
  const [touched, setTouched] = useState(false);
  const dispatch = useAppDispatch();
  const cropTypes = useAppSelector(selectCropTypes);

  const onSave = useCallback(() => {
    dispatch(
      dialogToggle(DialogType.confirm, true, {
        okLabel: 'fix',
        okAction: async () => {
          dispatch(updateRule(data.id, data.wrongCrop, correctCrop));
        },
        title: 'Apply rule',
        message: (
          <div>
            <div>Do you want to apply rule?</div>
            <p>
              <b>
                {data.wrongCrop} to {getCropLabelById(cropTypes, correctCrop)}
              </b>
            </p>
          </div>
        ),
      })
    );
  }, [data, correctCrop]);

  const onWarning = useCallback(() => {
    dispatch(
      dialogToggle(DialogType.alert, true, {
        title: 'Warning',
        message: (
          <div>
            <InfoBlock className={'inside-a-pop-up'} appearance="info">
              Once the crop type is matched, selecting another matching crop type will not update
              the previously matched crop type, only future crop types will be updated.
            </InfoBlock>
          </div>
        ),
      })
    );
  }, []);

  const onChangeCrop = useCallback((crop: string) => {
    setCorrectCrop(crop);
    setTouched(true);
  }, []);

  return (
    <FluroTableRow>
      <FluroTableColumn>{data.wrongCrop}</FluroTableColumn>
      <FluroTableColumn>
        <MatchStatus value={data.status} />
      </FluroTableColumn>
      <FluroTableColumn type={'number'}>{data.seasonNumber}</FluroTableColumn>
      <CropColumn>
        <CropSelect value={correctCrop} onChange={onChangeCrop} />

        {correctCrop && touched ? (
          <WarningSvg onClick={onWarning} className="status-info-icon" />
        ) : null}
      </CropColumn>
      <ActionsColumn>
        <FluroButton onClick={onSave} disabled={!correctCrop || !touched} primary raised>
          fix
        </FluroButton>
      </ActionsColumn>
    </FluroTableRow>
  );
});

export default CropTypesMatchingRow;
