import React, {useEffect, useState} from 'react';
import {CircularProgress} from 'react-md';
import {Flex, Text} from 'components';
import {useAppDispatch, useAppSelector} from '_hooks';
import {useParsedMatchParams} from '_hooks/use-parsed-match-params';
import {toggle} from '_utils/pure-utils';
import {fetchSISupplySheds} from 'containers/si/module/thunks';
import {setNewAreas} from 'containers/si/module/reducer';
import {
  selectSISupplySheds,
  selectSISupplyShedsListByProgramId,
  selectCurrentProgramSupplyShedAndSubsectionIds,
  selectNewReportAreas,
  selectNewReportType,
} from 'containers/si/module/selectors';
import {selectIsLoading} from 'modules/helpers/selectors';
import {ActionType} from 'containers/si/module/types';
import {AreasMap} from './areas-map';
import {AreasDropdown} from './areas-dropdown';
import '../create-report.scss';
import {getTypedEntries, getTypedKeys} from '_utils/object';

export const AreasStep = () => {
  const dispatch = useAppDispatch();
  const {programId} = useParsedMatchParams();
  const supplySheds = useAppSelector(selectSISupplySheds);
  const reportType = useAppSelector(selectNewReportType);
  const allSelectedAreas = useAppSelector(selectNewReportAreas); // supplyShedId: subsectionIds[]
  const programSupplySheds = useAppSelector(s => selectSISupplyShedsListByProgramId(s, programId));
  const supplyShedAndSubsectionIds = useAppSelector(selectCurrentProgramSupplyShedAndSubsectionIds);

  const isLoading = useAppSelector(s =>
    selectIsLoading(s, [ActionType.FETCH_SI_SUPPLY_SHEDS, ActionType.FETCH_KPI_SUBSECTION_AREA])
  );

  // SupplySheds are only in this list if ALL subsections are selected
  const [selectedEntireSupplyShedsList, setSelectedEntireSupplyShedsList] = useState<number[]>(
    getTypedEntries(allSelectedAreas)
      .filter(
        ([supplyShedId, subsectionIds]) =>
          getTypedKeys(supplyShedAndSubsectionIds).includes(supplyShedId) &&
          supplyShedAndSubsectionIds[Number(supplyShedId)].length === subsectionIds.length
      )
      .map(([supplyShedId]) => Number(supplyShedId))
  );
  const [selectedSubsections, setSelectedSubsections] = useState<number[]>(
    Object.values(allSelectedAreas).flat()
  );

  useEffect(() => {
    if (!supplySheds[programId]) {
      dispatch(fetchSISupplySheds(programId));
    }
  }, [dispatch, programId, supplySheds]);

  useEffect(() => {
    // The Abatement Potential report can include a combination of multiple Supply Sheds and multiple Subsections
    // These all need to be combined into a single object
    // Combine the selected supply sheds and subsections
    const selectedObject = {} as Record<number, number[]>;

    programSupplySheds.forEach(supplyShed => {
      const id = supplyShed.id;
      const subsectionIdArray: Array<number> = [];
      supplyShed.subsections.map(
        subsection =>
          selectedSubsections.includes(subsection.id) && subsectionIdArray.push(subsection.id)
      );
      if (subsectionIdArray.length > 0) {
        return (selectedObject[id] = subsectionIdArray);
      }
    });
    dispatch(setNewAreas(selectedObject));
  }, [
    selectedSubsections,
    programSupplySheds,
    dispatch,
    reportType,
    selectedEntireSupplyShedsList,
    supplyShedAndSubsectionIds,
  ]);

  // Needed for the Abatement Potential report
  const handleSupplyShedCheckboxChange = (supplyShedId: number) => {
    const supplyShedUnChecked = selectedEntireSupplyShedsList.includes(supplyShedId);

    if (!supplyShedUnChecked) {
      // If the supply shed was checked, add all the subsections to `selectedSubsections`
      const subsectionIdsToAdd: number[] = [];
      supplyShedAndSubsectionIds[supplyShedId].forEach(subsectionId => {
        !selectedSubsections.includes(subsectionId) && subsectionIdsToAdd.push(subsectionId);
      });

      setSelectedSubsections([...selectedSubsections, ...subsectionIdsToAdd]);
    } else {
      // If the supply shed was unchecked, remove all the subsections from `selectedSubsections`
      const subsectionIdsToRemove: number[] = [];
      supplyShedAndSubsectionIds[supplyShedId].forEach(subsectionId => {
        selectedSubsections.includes(subsectionId) && subsectionIdsToRemove.push(subsectionId);
      });

      setSelectedSubsections(
        selectedSubsections.filter(subsectionId => !subsectionIdsToRemove.includes(subsectionId))
      );
    }

    setSelectedEntireSupplyShedsList(toggle(selectedEntireSupplyShedsList, supplyShedId));
  };

  // Needed for the Abatement Potential report
  const handleSubsectionCheckboxChange = (supplyShedId: number, subsectionId: number) => {
    const subsectionUnChecked = selectedSubsections.includes(subsectionId);

    const allSubsectionsChecked =
      supplyShedAndSubsectionIds[supplyShedId].length ===
      selectedSubsections.filter(r => supplyShedAndSubsectionIds[supplyShedId].includes(r)).length +
        1;

    // If the subsection was checked, if it is the last subsection for the supply shed, add the supply shed to `selectedEntireSupplyShedsList`
    if (!subsectionUnChecked && allSubsectionsChecked) {
      !selectedEntireSupplyShedsList.includes(supplyShedId) &&
        setSelectedEntireSupplyShedsList(toggle(selectedEntireSupplyShedsList, supplyShedId));
    }

    // If the subsection was unchecked, AND supplyShedId in selectedEntireSupplyShedsList remove the supply shed from `selectedEntireSupplyShedsList`
    if (subsectionUnChecked && selectedEntireSupplyShedsList.includes(supplyShedId)) {
      setSelectedEntireSupplyShedsList(toggle(selectedEntireSupplyShedsList, supplyShedId));
    }

    setSelectedSubsections(toggle(selectedSubsections, subsectionId));
  };

  return (
    <div className="areas-step">
      <div className="select-areas-column">
        <div className="areas-select-row">
          <Text bold nowrap>
            Select Areas
          </Text>
          {isLoading && (
            <div className={'areas-loading'}>
              <CircularProgress className={'progress'} id={'si-ap-areas-step'} />
            </div>
          )}
        </div>

        <Flex direction="column">
          {programSupplySheds?.map(supplyShed => {
            return (
              <AreasDropdown
                key={supplyShed.id}
                supplyShed={supplyShed}
                selectedAreas={selectedEntireSupplyShedsList}
                selectedSubsections={selectedSubsections}
                handleSupplyShedCheckboxChange={handleSupplyShedCheckboxChange}
                handleSubsectionCheckboxChange={handleSubsectionCheckboxChange}
              />
            );
          })}
        </Flex>
      </div>
      <div className="map-column">
        <AreasMap selectedSubsectionIds={selectedSubsections} />
      </div>
    </div>
  );
};
