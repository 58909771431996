import {t} from 'i18n-utils';
import * as React from 'react';
import {useCallback, useMemo} from 'react';
import moment from 'moment';
import {formatDate, sortByDateKey, convertFromSquareMetersToMeasure} from '_utils';

import {isObjectPropValuesTheSame} from '_utils/pure-utils';
import {toFixedFloatUnsafe} from '_utils/number-formatters';
import type {Season} from 'containers/map/types';
import {GLOBAL_FORMAT_DATE} from '_constants';
import {useSelectSeason} from './use-select-season';
import {CropInfo, FluroButton} from 'components';
import {SeasonName, SeasonListItemWrapper} from './field.styled';
import {feature, area as turfArea} from '@turf/turf';
import cn from 'classnames';
import ExternalServiceIcon from 'components/external-service-icon/external-service-icon';
import {useAppSelector} from '_hooks';
import {selectIsAdmin, selectMeasurement} from 'containers/login/login-selectors';
import {naturalSortAlphaNum} from '_utils/sorters';

type Props = {
  seasons: Season[] | undefined;
  selectedSeasonId: number;
  isReadOnly: boolean;
  showOnlySelectedCrops: boolean;
  toggleEditSeasonPopup(sId: number): void;
};

export const SeasonList = ({
  seasons = [],
  selectedSeasonId,
  isReadOnly,
  showOnlySelectedCrops,
  toggleEditSeasonPopup,
}: Props) => {
  const selectSeason = useSelectSeason();
  const measurement = useAppSelector(selectMeasurement);
  const isAdmin = useAppSelector(selectIsAdmin);

  const sortedSeasons = useMemo(() => {
    // check if all planting area seasons
    // has the same sowing date - sort by planting area name
    const pantingAreaSeasons = seasons.filter(s => s.geometry_id);
    if (pantingAreaSeasons.length && isObjectPropValuesTheSame(pantingAreaSeasons, 'startDate')) {
      return naturalSortAlphaNum(seasons, 'name');
    }

    return sortByDateKey(seasons, 'startDate', true);
  }, [seasons]);

  const renderSeason = useCallback(
    (s: Season) => {
      const {cropType, params, id, startDate, endDate, geometry, name, external_service} = s;
      const cropSubType = (params && params.cropSubType) || '';
      const selected = selectedSeasonId === id; //&& seasonIntersectsWithCurrentDate(s);

      if (showOnlySelectedCrops && !selected) {
        return null;
      }

      return (
        <SeasonListItemWrapper
          key={id}
          selected={selected}
          onClick={() => !selected && selectSeason(s)}
        >
          {geometry ? (
            <div className="top-block">
              <SeasonName className={cn({selected})}>{name ? name : null}</SeasonName>

              <div className="top-block__right">
                <span>
                  (
                  {toFixedFloatUnsafe(
                    convertFromSquareMetersToMeasure(turfArea(feature(geometry)), measurement)
                  )}
                  {t({id: measurement})})
                </span>
              </div>
            </div>
          ) : null}

          <div className="bottom-block">
            <CropInfo
              cropType={cropType}
              cropSubType={cropSubType}
              startDate={getCropDate(startDate)}
              endDate={getCropDate(endDate)}
              hasGeometry={!!geometry}
            />

            <div className="edit-icon-wrapper">
              {external_service && isAdmin ? (
                <div className="external-icon">
                  <ExternalServiceIcon staticPosition value={external_service} />
                </div>
              ) : null}

              {!isReadOnly && (
                <FluroButton
                  icon
                  className="edit-season"
                  tooltipPosition="left"
                  tooltipLabel={t({id: 'Edit crop'})}
                  onClick={e => {
                    e.stopPropagation();
                    toggleEditSeasonPopup(Number(s.id));
                  }}
                >
                  edit
                </FluroButton>
              )}
            </div>
          </div>
        </SeasonListItemWrapper>
      );
    },
    [toggleEditSeasonPopup, selectSeason]
  );

  return <div>{sortedSeasons.map(renderSeason)}</div>;
};

export const getCropDate = (date: string) => moment(date, GLOBAL_FORMAT_DATE).format(formatDate());
