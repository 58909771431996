import type {PayloadAction} from '@reduxjs/toolkit';
import {createSlice} from '@reduxjs/toolkit';
import type {SI2State} from '../types';
import type {SIMapInteractionType, SIMapLayerType} from 'containers/si/types';
import type {Feature, FeatureCollection} from 'geojson';

export const supplyShedMapSlice = createSlice({
  name: 'si/supplyShedMap',
  initialState: {
    interactionType: 'select',
    selection: {
      layerID: 'admin1',
    },
    showTools: true,
    subsectionGeometries: {},
    subsectionSelectedFeatures: {},
    programGeometries: null,
    showProgramGeometries: true,
  } as SI2State['supplyShedMap'],
  reducers: {
    setSelectLayerID(state, action: PayloadAction<SIMapLayerType>) {
      state.selection.layerID = action.payload;
    },
    setInteractionType(state, action: PayloadAction<SIMapInteractionType>) {
      state.interactionType = action.payload;
    },
    setShouldShowMapTools(state, action: PayloadAction<boolean>) {
      state.showTools = action.payload;
    },
    setSubsectionGeometries(state, action: PayloadAction<Object>) {
      state.subsectionGeometries = action.payload;
    },
    addSubsectionGeometries(state, action: PayloadAction<{feature: Feature}>) {
      const {feature} = action.payload;
      const featureId = feature.id as string;
      state.subsectionGeometries[featureId] = feature;
    },
    removeSubsectionGeometries(state, action: PayloadAction<{featureID: string}>) {
      const {featureID} = action.payload;
      delete state.subsectionGeometries[featureID];
    },
    setSubsectionSelectedFeatures(state, action: PayloadAction<Object>) {
      state.subsectionSelectedFeatures = action.payload;
    },
    addSubsectionSelectedFeatures(
      state,
      action: PayloadAction<{featureID: string; feature: Feature}>
    ) {
      const {featureID, feature} = action.payload;
      state.subsectionSelectedFeatures[featureID] = feature;
    },
    removeSubsectionSelectedFeatures(state, action: PayloadAction<{featureID: string}>) {
      const {featureID} = action.payload;
      delete state.subsectionSelectedFeatures[featureID];
    },
    setProgramGeometries(state, action: PayloadAction<FeatureCollection | null>) {
      state.programGeometries = action.payload;
    },
    setShowProgramGeometries(state, action: PayloadAction<boolean>) {
      state.showProgramGeometries = action.payload;
    },
  },
});
