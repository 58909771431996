import {t} from 'i18n-utils';
import type {ReactElement} from 'react';
import React, {useCallback, useMemo} from 'react';
import {SelectionControl, TextField} from 'react-md';
import {genKey} from '_utils/pure-utils';
import {MAIN_SHAPE_COLOR} from '_constants';
import {GeometryPreview, FluroButton, Text} from 'components';
import {DeleteIcon} from 'containers/map/icons';
import colors from 'variables.scss';
import './new-field-preview.scss';
import {NewFieldError} from '../types';
import type {FieldErrorsViews} from './use-field-errors';
import {useAppDispatch, useAppSelector} from '_hooks';
import {selectHighlightedFieldId} from 'containers/map/reducer/selectors';
import cn from 'classnames';
import {highlightField} from 'containers/map/actions';

type Props = {
  selected?: boolean;
  onSelect?: (value: boolean) => void;
  fieldName: any;
  id: string;
  size?: number;
  errors?: FieldErrorsViews;
  preview: GeoJSON.Feature;
  farmsSelector?: ReactElement;
  farmName?: string | number;
  fieldArea: string;
  removeField?(): void;
  onBlur?(): void;
  onFocus?(): void;
  onChangeName(id: string | number, value: number | string): void;
};

export const NewFieldPreview = ({
  selected,
  onSelect,
  fieldName,
  id,
  errors,
  onChangeName,
  removeField,
  onBlur,
  onFocus,
  preview,
  farmName,
  fieldArea,
  farmsSelector = undefined,
}: Props) => {
  const dispatch = useAppDispatch();
  const uniqId = useMemo(() => id || genKey(), []);
  const highlightedFieldId = useAppSelector(selectHighlightedFieldId);

  const protectedAreaStatus = useMemo(
    () => Boolean(errors?.[NewFieldError.FieldProtectedAreaError]),
    [Boolean(errors?.[NewFieldError.FieldProtectedAreaError])]
  );

  const onEachFeature = useCallback(
    (feature: any, layer: any) => {
      layer.setStyle({
        color: protectedAreaStatus ? colors['saffron'] : MAIN_SHAPE_COLOR,
        weight: 1,
        fillColor: MAIN_SHAPE_COLOR,
      });
    },
    [protectedAreaStatus]
  );

  const missingName =
    (!farmsSelector && errors?.[NewFieldError.FieldFarmNameError]) ||
    errors?.[NewFieldError.FieldNameError];

  return (
    <>
      <div
        onClick={farmsSelector ? undefined : () => dispatch(highlightField(id))}
        className={cn({
          'field-upload-item': true,
          clickable: Boolean(!farmsSelector),
          highlighted: Boolean(!farmsSelector) && highlightedFieldId === id,
        })}
      >
        {onSelect ? (
          <SelectionControl
            id={`select-${id}-field`}
            name={`select-${id}-field`}
            type="checkbox"
            label=""
            checked={!!selected}
            onChange={value => onSelect(Boolean(value))}
          />
        ) : null}

        <div className="field-upload-item__preview">
          <GeometryPreview
            key={'str-' + protectedAreaStatus}
            onEachFeature={onEachFeature}
            size={[40, 40]}
            data={preview}
          />
          {/* {errors[NewFieldError.FieldProtectedAreaError] ? (
            <InfoBlockIcon appearance="warning" />
          ) : errors[NewFieldError.FieldProtectedAreaError] ? (
            <InfoBlockIcon appearance="error" />
          ) : null} */}
        </div>

        <div className="field-upload-item__controls">
          <TextField
            id={`field-name-${uniqId}`}
            label=""
            lineDirection="center"
            className={'field-upload-item__field-name'}
            placeholder={t({id: 'Field name'})}
            value={fieldName}
            error={Boolean(errors?.[NewFieldError.FieldNameError])}
            onChange={value => onChangeName(id, String(value))}
            onBlur={onBlur}
            onFocus={onFocus}
            helpText={
              missingName ? ( // use helpText to display errors because of the case when we need to display Missing farm error without error state of the TextField
                <Text error variant={'small'}>
                  {missingName}
                </Text>
              ) : farmName ? (
                <Text secondary variant={'small'}>
                  {farmName}
                </Text>
              ) : null
            }
          />
        </div>

        <Text variant={'small'} nowrap className={'field-upload-item__area'}>
          {fieldArea}
        </Text>

        {farmsSelector}

        {removeField ? (
          <FluroButton
            {...{title: t({id: 'Delete this field'})}}
            noPadding
            className={'delete-field-btn'}
            onClick={removeField}
            icon
            centerIconInside
            iconEl={<DeleteIcon />}
          />
        ) : null}
      </div>

      {errors?.[NewFieldError.FieldSizeError]}

      {!farmsSelector && errors?.[NewFieldError.FieldProtectedAreaError]}
    </>
  );
};
