// @ts-nocheck
// This files is created to avoid cross dependencies, so storybook can show them.

export const genKey = function () {
  return Math.random().toString(36).substring(7);
};

/**
 * Subscribes to click outside the element and ESC keydown.
 *
 * @returns a cleanup function. (!) Don't forget to return it from your useEffect.
 */
export function onClickOutsideAndEsc<E extends HTMLElement | null>(
  element: E | E[],
  active: boolean,
  callback: () => void
) {
  if (!active) return;
  const onMouseDown = (e: MouseEvent) => {
    if (!active) return;

    const isOutsideElements = Array.isArray(element) // check all elements
      ? element.every(elem => !elementContainsTarget(elem, e.target as E))
      : !elementContainsTarget(element, e.target as E);

    if (isOutsideElements) {
      callback();
    }
  };
  const onEscPress = (e: KeyboardEvent) => {
    if (e.code === 'Escape') {
      callback();
    }
  };
  document.addEventListener('mousedown', onMouseDown);
  document.addEventListener('keydown', onEscPress);
  return () => {
    document.removeEventListener('mousedown', onMouseDown);
    document.removeEventListener('keydown', onEscPress);
  };
}

export function elementContainsTarget<E extends HTMLElement>(
  element: E | null,
  target: E
): boolean {
  if (element === target) {
    return true;
  }
  if (!target.parentElement) {
    return false;
  }
  return elementContainsTarget(element, target.parentElement);
}

export const getNodeText = (node: any): string => {
  if (['string', 'number'].includes(typeof node)) return node;
  if (node instanceof Array) return node.map(getNodeText).join('');
  if (typeof node === 'object' && node) return getNodeText(node.props.children);
};

export const getScrollParent = (node: any): HTMLElement => {
  if (node == null) {
    return null;
  }

  if (node.scrollHeight > node.clientHeight) {
    return node;
  } else {
    return getScrollParent(node.parentNode);
  }
};
