import {convertArea} from '@turf/turf';
import type {Measurement} from 'containers/login/types';
import type {KPI} from 'containers/si/api/apiTypes';
import convert from 'convert-units';

//Makes sure to return converted values based on requirement from each KPI
export const calcValueByKPI = (value: number, kpiName: KPI, measurement: Measurement) => {
  switch (kpiName) {
    case 'crop_area':
    case 'cover_crop':
    case 'lulc':
      return convertArea(value, 'meters', measurement === 'ac' ? 'acres' : 'hectares');
    case 'ghg':
    case 'soc':
    case 'net_ghg_emissions':
      return convert(value).from('kg').to('mt');
    case 'living_root_cover':
      return value * 100;
    default:
      return value;
  }
};
