import axios from 'axios';

class CancelTokensStore {
  sources: any = {};

  generateSource(name?: string) {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    name && this.setSource(name, source);
    return source;
  }

  setSource(name: string, token: any) {
    this.sources[name] = token;
  }

  getSource(name: string) {
    return this.sources[name];
  }

  cancel(name: string) {
    if (this.sources[name]) {
      this.sources[name].cancel(`__canceled: ${name}`);
      delete this.sources[name];
    }
  }
}

const cancelTokensStore = new CancelTokensStore();

export default cancelTokensStore;
