import {t} from 'i18n-utils';
import {FluroAutocomplete} from '../index';
import {CropAvatar} from 'components/crop-avatar';
import {genKey} from '_utils/pure-utils';
import React, {useMemo} from 'react';
import type {CropType} from 'modules/global/types';
import './index.scss';
import {useAppSelector} from '_hooks';
import {selectCropTypeLabelByCropId, selectCropTypesList} from 'modules/global/selectors';

type Props = {
  cropTypes?: Partial<CropType>[];
  cropType: string;
  errorMessage?: string;
  label?: string;
  onChange(crop: string): void;
  className?: string;
  simplifiedMenu?: boolean;
};

const CropSelectMd = ({
  className,
  errorMessage,
  cropType,
  label = 'Crop Type',
  cropTypes,
  onChange,
  simplifiedMenu = false,
}: Props) => {
  const id = useMemo(() => genKey(), []);
  const globalCropTypes = useAppSelector(selectCropTypesList);
  const cropTypeLabel = useAppSelector(s => selectCropTypeLabelByCropId(s, cropType));
  const cropTypesList = cropTypes || globalCropTypes;

  const formattedValue = () => {
    if (cropType === 'all') {
      return 'All crops';
    }
    return cropTypeLabel;
  };

  const _cropTypesList = useMemo(() => {
    return cropTypesList.map(item => ({...item, label: t({id: item.value})}));
  }, [cropTypesList]);

  return (
    <div className={`crop-type-select ${className || ''}`}>
      <CropAvatar
        className={'crop-type-select__avatar'}
        style={{marginRight: 10}}
        cropType={cropType}
      />

      <FluroAutocomplete
        id={`crop-type-selector-${id}`}
        label={t({id: label})}
        placeholder={t({id: 'Crop type'})}
        title={t({id: cropType})}
        menuItems={_cropTypesList}
        value={formattedValue()}
        onAutocomplete={val => onChange(String(val))}
        className={`crop-type-select__autocomplete ${cropType ? '' : 'without-crop'}`}
        error={!!errorMessage}
        errorText={errorMessage}
        simplifiedMenu={simplifiedMenu}
      />
    </div>
  );
};

export default CropSelectMd;
