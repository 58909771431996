import React, {useEffect} from 'react';
import Draggable from 'react-draggable';
import Histogram from 'components/histogram';
import {AsyncStatusType, dialogToggle, DialogType} from 'modules/helpers';
import {loadHistogramData} from '../actions';
import {SimplePreloader, FluroButton} from 'components';
import './histogram-overlay.scss';
import {useAppDispatch, useAppSelector} from '_hooks';
import {selectDialogVisibility} from '../../../modules/helpers/selectors';
import {
  selectCurrentDate,
  selectCurrentImageObj,
  selectCurrentSensor,
} from 'containers/map/reducer/selectors';
import {t} from 'i18n-utils';

const HistogramOverlay = () => {
  const dispatch = useAppDispatch();
  const currentDate = useAppSelector(selectCurrentDate);
  const currentSensor = useAppSelector(selectCurrentSensor);
  const currentImageObj = useAppSelector(selectCurrentImageObj);

  const isVisibleHistogram = useAppSelector(s => selectDialogVisibility(s, DialogType.histogram));

  useEffect(() => {
    if (isVisibleHistogram && currentImageObj) {
      dispatch(loadHistogramData());
    }
  }, [isVisibleHistogram, currentImageObj]);

  const disabled = currentSensor === 'NONE' || !currentDate;

  const closeHistogram = () => dispatch(dialogToggle(DialogType.histogram, false));

  return isVisibleHistogram ? (
    <Draggable
      handle=".map-histogram__handle"
      defaultPosition={{x: 50, y: 10}}
      defaultClassName={`map-histogram`}
      position={undefined}
      grid={[25, 25]}
    >
      <div>
        <h4 className="map-histogram__handle">
          {t({id: 'Histogram'})}
          <SimplePreloader statusKeys={[AsyncStatusType.histogram]} />
        </h4>
        <FluroButton className={'close-dialog-button'} icon onClick={closeHistogram}>
          close
        </FluroButton>
        {!disabled ? (
          <Histogram />
        ) : (
          <div className={'md-text-center'}>
            <b>{t({id: 'Please select Observation Date and Sensor in the right bar'})}</b>
          </div>
        )}
      </div>
    </Draggable>
  ) : null;
};

export default HistogramOverlay;
