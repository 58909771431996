import {useAppDispatch} from '../../../../_hooks';
import {toFixedFloatUnsafe} from '_utils/number-formatters';
import type {Zone} from '../../types';
import {ZoneType} from '../../types';
import {t} from '../../../../i18n-utils';
import {MergedIcon} from '../../icons';
import {toggleTooltip} from '../../../../modules/global/actions';
import React from 'react';
import type {Measurement} from 'containers/login/types';
import cn from 'classnames';

type ZoneElementProps = {
  zone: Zone;
  nRecommendationToggled?: boolean;
  index: number;
  editable: boolean;
  setZoneParam: (prop: string, index: number, value: any, isTreeAnalysis: boolean) => any;
  mergedZones?: boolean; // represents is any zone is merged
  isTreeAnalysis?: boolean;
  measurement: Measurement;
};

export const ZoneElement = ({
  zone,
  index,
  nRecommendationToggled,
  editable,
  setZoneParam,
  mergedZones,
  isTreeAnalysis = false,
  measurement,
}: ZoneElementProps) => {
  const dispatch = useAppDispatch();
  const zoneArea = toFixedFloatUnsafe(zone.area, 1);
  if (!zone) return;
  const zonePercent = toFixedFloatUnsafe(zone.percent, Number(zone.percent) > 1 ? 0 : 1); // round to one if the percent is >1 (Kelly's request)
  const selectable = nRecommendationToggled;
  const mergedZone = zone.merged === 'merged';
  const rxValue =
    Number.isInteger(parseInt(`${zone.value}`)) || zone.value === '-' ? zone.value : '';

  const isTreeZone = zone.type === ZoneType.Tree;
  const isProductivityZone = zone.type === ZoneType.Productivity;

  return (
    <div
      onClick={
        selectable
          ? () => setZoneParam('selected', zone.id, !zone.selected, !!zone.isTree)
          : undefined
      }
      className={cn(`zoning-zone`, {selected: zone.selected, selectable})}
    >
      <div
        className={cn('zoning-zone__name', {
          merged: mergedZones,
        })}
        style={{background: zone.color}}
      >
        <input
          disabled={!editable}
          className="name-input"
          value={zone.name !== undefined ? zone.name : t({id: 'Zone {index}'}, {index})}
          onChange={({target}) => setZoneParam('name', zone.id, target.value, !!zone.isTree)}
        />

        {mergedZone && (
          <MergedIcon
            data-tip={''}
            data-for={`merged-zones-${zone.id}`}
            onMouseEnter={() =>
              dispatch(
                toggleTooltip({
                  id: `merged-zones-${zone.id}`,
                  content: t({id: 'Zones were merged'}),
                  tooltipClassName: 'zoning-zone__merged-tooltip',
                  place: 'top',
                  width: 'auto',
                })
              )
            }
          />
        )}
      </div>

      <div className={cn('zoning-zone__data-cell')}>
        <div className={'zone-values-top'}>
          {isTreeZone
            ? t({id: '{count} tree'}, {count: zone.number})
            : `${zoneArea < 0.1 ? '<0.1' : zoneArea} ${measurement}`}
        </div>
        {!isTreeAnalysis && zone.percent !== undefined && (
          // add zone percent value to the column if the nrx is On (to save some space)
          <div className={'zone-values-bottom'}>{zonePercent < 0.1 ? '<0.1' : zonePercent}%</div>
        )}
      </div>

      {!nRecommendationToggled && zone.mid !== undefined && (
        <div className={cn('zoning-zone__layer-value')}>{zone.mid.toFixed(2)}</div>
      )}

      {isProductivityZone && <div className={cn('zoning-zone__layer-value')}>{zone.value}</div>}

      {nRecommendationToggled && zone.nitrogenAmount !== undefined && (
        <div className={'zoning-zone__nitrogen-amount'}>{zone.nitrogenAmount}</div>
      )}

      {!isProductivityZone && (
        <input
          placeholder={t({id: 'Value'})}
          className={cn('zoning-zone__rx-input')}
          type="tel"
          pattern="[0-9\-]+"
          value={rxValue}
          maxLength={50}
          onChange={({target}) => setZoneParam('value', zone.id, target.value, isTreeZone)}
        />
      )}
    </div>
  );
};
