import {toggleMapBar, loadFields} from '../actions';
import {t} from 'i18n-utils';
import {tsProcessor} from '_utils/ts-file-processor';
import {showNotification} from 'components/notification/notification';
import {ActivityApi} from '../../../_api';
import type {AppStore} from 'reducers';

const TS_UPLOAD_OPEN = 'ts-upload/open-dialog';
const TS_UPLOAD_SET_MARKERS = 'ts-upload/set-markers';
const TS_UPLOAD_CLEAR_MARKERS = 'ts-upload/clear-markers';
const TS_UPLOAD_SET_MAPPING_CELL = 'ts-upload-set-mapping-cell';
const TS_UPLOAD_TOGGLE_CONFIRMATION_POPUP = 'ts-upload-toggle-confirmation-popup';

type State = {
  isOpenDialog: boolean;
  markers: [];
  fileNames: [];
  confirmData: any;
  pereparedMarkers: [];
  setSubmitting: any;
  cellMapping: {[key in CellKey]: Cell};
};
type Cell = {
  title: string;
  mappedKey: CellKey;
  type: CellType;
};
type CellKey =
  | 'sample_id'
  | 'sample_date'
  | 'lat'
  | 'lng'
  | 'n_result'
  | 'n_result2'
  | 'total_K'
  | 'total_P'
  | 'Ca'
  | 'Mg'
  | 'Na'
  | 'S'
  | 'Cl'
  | 'Zn_ppm'
  | 'Mn_ppm'
  | 'Fe_ppm'
  | 'Cu_ppm'
  | 'B_ppm'
  | 'Mo_ppm'
  | 'growthStage'
  | 'samplingType';
type CellType = 'text' | 'date' | 'number';

const initialState: State = {
  isOpenDialog: false,
  markers: [],
  fileNames: [],
  confirmData: null,
  pereparedMarkers: [],
  setSubmitting: null,

  cellMapping: {
    sample_id: {
      title: t({id: 'Point ID'}),
      mappedKey: 'sample_id',
      type: 'text',
    },

    sample_date: {
      title: t({id: 'Sample Date'}),
      mappedKey: 'sample_date',
      type: 'date',
    },

    lat: {
      title: t({id: 'Lat'}),
      mappedKey: 'lat',
      type: 'number',
    },

    lng: {
      title: t({id: 'Lng'}),
      mappedKey: 'lng',
      type: 'number',
    },

    n_result: {
      title: t({id: 'Total N (%)'}),
      mappedKey: 'n_result',
      type: 'number',
    },

    n_result2: {
      title: t({id: 'Nitrate (ppm)'}),
      mappedKey: 'n_result2',
      type: 'number',
    },

    total_K: {
      title: t({id: 'Total K (%)'}),
      mappedKey: 'total_K',
      type: 'number',
    },

    total_P: {
      title: t({id: 'Total P (%)'}),
      mappedKey: 'total_P',
      type: 'number',
    },

    Ca: {
      title: 'Ca (%)',
      mappedKey: 'Ca',
      type: 'number',
    },

    Mg: {
      title: 'Mg (%)',
      mappedKey: 'Mg',
      type: 'number',
    },

    Na: {
      title: 'Na (%)',
      mappedKey: 'Na',
      type: 'number',
    },

    S: {
      title: 'S (%)',
      mappedKey: 'S',
      type: 'number',
    },

    Zn_ppm: {
      title: 'Zn (ppm)',
      mappedKey: 'Zn_ppm',
      type: 'number',
    },

    Mn_ppm: {
      title: 'Mn (ppm)',
      mappedKey: 'Mn_ppm',
      type: 'number',
    },

    Fe_ppm: {
      title: 'Fe (ppm)',
      mappedKey: 'Fe_ppm',
      type: 'number',
    },

    Cu_ppm: {
      title: 'Cu (ppm)',
      mappedKey: 'Cu_ppm',
      type: 'number',
    },

    B_ppm: {
      title: 'B (ppm)',
      mappedKey: 'B_ppm',
      type: 'number',
    },

    Cl: {
      title: 'Cl (%)',
      mappedKey: 'Cl',
      type: 'number',
    },

    Mo_ppm: {
      title: 'Mo (ppm)',
      mappedKey: 'Mo_ppm',
      type: 'number',
    },

    growthStage: {
      title: t({id: 'Growth Stage'}),
      mappedKey: 'growthStage',
      type: 'text',
    },

    samplingType: {
      title: t({id: 'Plant Material Type'}),
      mappedKey: 'samplingType',
      type: 'text',
    },
  },
};

export default (state = initialState, action: any) => {
  switch (action.type) {
    case TS_UPLOAD_OPEN:
      return {
        ...state,
        isOpenDialog: action.value,
        cellMapping: action.value ? state.cellMapping : {...initialState.cellMapping},
      };

    case TS_UPLOAD_SET_MARKERS:
      return {
        ...state,
        markers: [...action.markers],
        fileNames: [...action.fileNames],
      };

    case TS_UPLOAD_CLEAR_MARKERS:
      return {
        ...state,
        markers: [],
        fileNames: [],
        isOpenDialog: false,
        cellMapping: {...initialState.cellMapping},
      };

    case TS_UPLOAD_SET_MAPPING_CELL:
      const {key, mappedKey} = action as {key: CellKey; mappedKey: CellKey};
      return {
        ...state,
        cellMapping: {
          ...state.cellMapping,
          [key]: {
            ...state.cellMapping[key],
            mappedKey: mappedKey,
          },
        },
        // markers,
      };

    case TS_UPLOAD_TOGGLE_CONFIRMATION_POPUP:
      return {
        ...state,
        confirmData: {...action.confirmData},
        pereparedMarkers: [...action.markers],
        setSubmitting: action.setSubmitting,
      };

    default:
      return state;
  }
};

export const handleOpenTS = (value: boolean, e?: Event) => (dispatch: any) => {
  // prevent close popup after click outside
  // @ts-expect-error since target is unknown, we can't be sure that classList exists
  if (e?.target.classList?.contains('md-dialog-container')) {
    return;
  }

  value && dispatch(toggleMapBar(false));

  if (!value) {
    dispatch(clearMarkers());
    dispatch(toggleMapBar(true));
    dispatch(toggleTSPUploadConfirmationPopup(null));

    clearInputField();
  }

  dispatch({
    type: TS_UPLOAD_OPEN,
    value,
  });
};

export const handleFile = (files: File[]) => (dispatch: any) => {
  const samplingType = 'Petiole sample';

  tsProcessor(files).then(result => {
    let markers: State['markers'] = [];

    result.forEach(r => {
      if (r.status === 'error') {
        showNotification({
          title: t({id: 'note.warning', defaultMessage: 'Warning'}),
          message: r.message,
          type: 'warning',
        });
        return;
      }

      markers = [...markers, ...(r.data as State['markers'])];
    });

    if (markers.length) {
      dispatch(toggleMapBar(false));

      dispatch({
        type: TS_UPLOAD_OPEN,
        value: true,
      });

      // @ts-expect-error we don't the type of the marker
      markers = markers.map(marker => ({...marker, samplingType}));

      dispatch({
        type: TS_UPLOAD_SET_MARKERS,
        markers,
        fileNames: files.map(f => f.name),
      });
    } else {
      dispatch(clearMarkers());
      dispatch(toggleTSPUploadConfirmationPopup(null));
    }

    clearInputField();
  });
};

function clearInputField() {
  const tspupbtn = document.getElementById('ts-upload-btn');
  // @ts-expect-error HTMLInputElement doesn't have 'value' property :|
  if (tspupbtn) tspupbtn.value = '';
}

export const clearMarkers = () => ({
  type: TS_UPLOAD_CLEAR_MARKERS,
});

export const saveMarkers =
  (markers: State['markers'] = [], setSubmitting: State['setSubmitting'], shouldMerge = false) =>
  (dispatch: any, getState: () => AppStore) => {
    const {group, selectedFieldId} = getState().map;

    return ActivityApi.saveBulk(
      markers,
      group.id,
      Number(selectedFieldId),
      false,
      shouldMerge
    ).then(() => {
      setSubmitting?.(false);

      dispatch(clearMarkers());
      dispatch(toggleTSPUploadConfirmationPopup(null));
      dispatch(toggleMapBar(true));

      dispatch(loadFields(group.id, selectedFieldId)).then(() => {
        showNotification({
          title: t({id: 'note.success', defaultMessage: 'Success'}),
          message: 'Your tissue sampling points were uploaded',
          type: 'success',
        });
      });
    });
  };

export const saveMarkersFromStore =
  (shouldMerge = false) =>
  (dispatch: any, getSate: () => AppStore) => {
    const {
      tsUpload: {pereparedMarkers, setSubmitting},
    } = getSate();
    return saveMarkers(pereparedMarkers, setSubmitting, shouldMerge)(dispatch, getSate);
  };

export const testTSPMarkers =
  (markers: State['markers'] = []) =>
  (dispatch: any, getState: () => AppStore) => {
    const {group, selectedFieldId} = getState().map;
    return ActivityApi.saveBulk(markers, group.id, Number(selectedFieldId), true).then(
      ({data}: any) => {
        return data;
      }
    );
  };

export const toggleTSPUploadConfirmationPopup = (
  result: State['confirmData'],
  markers: State['markers'] = [],
  setSubmitting?: State['setSubmitting']
) => ({
  type: TS_UPLOAD_TOGGLE_CONFIRMATION_POPUP,
  confirmData: result,
  markers,
  setSubmitting,
});

export const onSetMapping = (key: CellKey, mappedKey: CellKey) => ({
  type: TS_UPLOAD_SET_MAPPING_CELL,
  mappedKey,
  key,
});

export const openTS = (value: boolean) => ({
  type: TS_UPLOAD_OPEN,
  value,
});
