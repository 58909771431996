import styled from 'styled-components';

export const BodyText16 = styled.p`
  /* FS Type/Body Text 16 */
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;

  /* or 150% */
  text-align: center;

  /* FS Semantic Primaries/Secondary text */
  color: #7a7a7a;

  /* Inside auto layout */
  flex: none;
  align-self: stretch;
  flex-grow: 0;
`;
