// @ts-nocheck
import {AdminApi, AnomaliesApi, KmlApi, SustainabilityInsightsAPI, TagsApi} from '../../../_api';
import {t} from 'i18n-utils';
import type {
  BaseSeason,
  EntityType,
  FFeature,
  FField,
  SeasonWithFeatures,
  FTag,
  SearchOptionalParams,
  AdminFeaturesStore,
} from './types';
import {ActionType, Tags, FeatureEntity} from './types';
import type {Season} from 'containers/map/types';
import type {AxiosResponse} from 'axios';
import axios from 'axios';
import moment from 'moment';
import {formatDate, sortByDateKey} from '_utils';

import service from '_api/service';
import {reportError} from '../../error-boundary';
import {showNotification} from 'components/notification/notification';
import {LogoType} from './one-time-reports/shared';
import type {AppDispatch} from 'store';
import {AsyncStatusType, setRequestStatus, Status} from '../../../modules/helpers';
import type {CountyResponseMeta, SIPolicyRequestBody} from 'modules/sustainability-insights/types';
import {
  METRIC_GROUPS,
  SI_AREA_AGG_AVAILABLE,
  SI_YEARS_AVAILABLE,
} from 'modules/sustainability-insights/constants';

export const searchFields =
  (query: string, searchBy: string, tagKeys: string[], optionalParams?: SearchOptionalParams) =>
  (dispatch: AppDispatch) => {
    dispatch(setRequestStatus(AsyncStatusType.adminSearch, Status.Pending));
    return AdminApi.search(query, searchBy, optionalParams)
      .then((response: any) => {
        dispatch(setRequestStatus(AsyncStatusType.adminSearch, Status.Done));

        const fields = (response.data?.result || []).map((field: any) => ({
          ...normalizeFieldObject(field),
          /*
           * below function return prepared settings object for feature toggles
           *
           * EXAMPLE RESULT:
           * features: {
           *   nrx: {
           *     id: 10,
           *     value: true,
           *   },
           *   trees: {
           *     id: 20,
           *     value: false
           *   }
           * }
           *
           * */
          features: (() => {
            let result: FFeature = {};

            tagKeys.forEach((key: Tags) => {
              const tag = (field?.direct_tags || []).find((t: any) => t.key === key);

              result[key] = {
                id: tag ? tag.id : 0,
                value: !!tag,
                key,
                created_at: tag?.created_at || '',
              };
            });

            return result;
          })(),

          seasons: (field?.seasons || []).map((season: Season) => {
            return {
              ...season,
              features: (() => {
                let result: FFeature = {};

                //TODO: make not static array values
                [Tags.NRx, Tags.AnomalyDetection, Tags.CropStress].forEach((key: Tags) => {
                  //@ts-expect-error error leftover from convertion to strict mode, please fix
                  const tag = (season.direct_tags || []).find((t: any) => t.key === key) as FTag;
                  result[key] = {
                    id: tag ? tag.id : 0,
                    value: key === Tags.NRx && tag?.value ? tag.value : !!tag,
                    key,
                    created_at: tag?.created_at || '',
                  };
                });

                field.direct_tags?.forEach(({key: tagKey}: {key: Tags}) => {
                  if (tagKey !== Tags.NRx) {
                    const tag = (field.direct_tags || []).find(
                      (t: any) => t.key === tagKey
                    ) as FTag;
                    result[tagKey] = {
                      id: tag ? tag.id : 0,
                      value: !!tag,
                      key: tagKey,
                      created_at: tag?.created_at || '',
                    };
                  }
                });
                return result;
              })(),
            };
          }),
        }));

        dispatch({
          type: ActionType.SET_SEARCH_RESULT,
          fields,
        });

        dispatch(setActivationResult(fields));
      })
      .catch(err => {
        dispatch(setRequestStatus(AsyncStatusType.adminSearch, Status.Done));
        if (!axios.isCancel(err)) {
          reportError(
            `ERR admin searchFields request query=${query}, searchBy=${searchBy}, ${err}`
          );
        }
      });
  };

export const getTagKeys = () => (dispatch: AppDispatch) => {
  return TagsApi.getKeysForEntity('field')
    .then(({data}) => {
      const tagKeys = [...(data.result || []).sort()];

      dispatch({
        type: ActionType.SET_TAG_KEYS,
        tagKeys,
      });

      return tagKeys;
    })
    .catch(err => {
      reportError(`loadOrganizations() err = ${err}`);
      return [];
    });
};

export const switchBulkFeature =
  (value: boolean, key: Tags, entityType: FeatureEntity, fields: FField[]) => (dispatch: any) => {
    return new Promise(resolve => {
      if (value) {
        let tagsToCreate;

        if (entityType === FeatureEntity.Field) {
          tagsToCreate = fields.map((field: FField) => ({
            value: '',
            key,
            entity_type: FeatureEntity.Field,
            entity_id: field.fieldId,
          }));
        }

        if (entityType === FeatureEntity.Season) {
          tagsToCreate = fields
            .map((field: FField) =>
              (field.seasons || []).map((season: SeasonWithFeatures) => ({
                value: '',
                key,
                entity_type: FeatureEntity.Season,
                entity_id: season.id,
              }))
            )
            .flat();
        }

        TagsApi.createEntity(tagsToCreate)
          .then((result: any) => {
            dispatch({
              type: ActionType.BULK_SWITCH_FEATURE,
              key,
              value,
              createdKeys: result.data.result || [],
              entityType,
            });

            resolve('ok');
          })
          .catch(err => {
            showNotification({
              title: t({id: 'note.error', defaultMessage: 'Error'}),
              message: t({id: 'errorMsgMakeScreenshot'}),
              type: 'error',
            });
            reportError(`TagsApi.createEntity err ${err}`);
          });
      } else {
        let tagsToRemove;

        if (entityType === FeatureEntity.Field) {
          tagsToRemove = fields.map((field: FField) => field.features[key].id);
        }

        if (entityType === FeatureEntity.Season) {
          tagsToRemove = fields
            .map((field: FField) =>
              (field.seasons || []).map((season: SeasonWithFeatures) => season.features[key].id)
            )
            .flat();
        }

        TagsApi.bulkRemoveTag(tagsToRemove)
          .then(() => {
            dispatch({
              type: ActionType.BULK_SWITCH_FEATURE,
              key,
              value,
              createdKeys: fields.map(f => ({entity_id: f.fieldId})), // we should populate createdKeys because response does not return removed entities list
              entityType,
            });

            resolve('ok');
          })
          .catch(err => {
            showNotification({
              title: t({id: 'note.error', defaultMessage: 'Error'}),
              message: t({id: 'errorMsgMakeScreenshot'}),
              type: 'error',
            });
            reportError(`TagsApi.bulkRemoveTag err ${err}`);
          });
      }
    });
  };

export const switchFeature =
  (value: boolean | string, key: Tags, entityId: number, entityType: FeatureEntity, tag?: FTag) =>
  (dispatch: any) => {
    return new Promise(resolve => {
      if (!tag?.id) {
        TagsApi.createEntity([
          {
            entity_id: entityId,
            entity_type: entityType,
            key,
            value: key === Tags.NRx ? value : '',
          },
        ]).then((response: any) => {
          dispatch({
            type: ActionType.SWITCH_FEATURE,
            value,
            key,
            entityId,
            entityType,
            keyId: response.data?.result?.[0]?.id || 0,
          });

          resolve('ok');
        });
      } else {
        TagsApi.removeEntity(tag?.id).then(() => {
          dispatch({
            type: ActionType.SWITCH_FEATURE,
            value: false,
            key,
            entityId,
            entityType,
            keyId: 0,
          });
          resolve('ok');
        });
      }
    });
  };

export const updateFeatureValue =
  (entityId: number, entityType: FeatureEntity, key: Tags, id: number, value: string) =>
  (dispatch: any) => {
    return TagsApi.updateEntity({
      id,
      value,
    }).then(() => {
      dispatch({
        type: ActionType.SWITCH_FEATURE,
        value,
        key,
        entityId,
        keyId: id,
        entityType,
      });
    });
  };

function normalizeFieldObject(field: any) {
  return {
    fieldName: field.field_name,
    fieldId: field.kml_id,

    farmName: field.farm_name,
    farmId: field.farm_id,

    seasonId: field.season_id || 0,
    cropType: field.crop_type || '',
    cropVariety: field?.params?.cropSubtype || '',
    startDate: field.start_date || null,
    endDate: field.end_date || null,
    organizationName: field.organization_name,
    organizationId: field.organization_id,
    subscriptionName: field.subscription_name,
    subscriptionId: field.subscription_id,
    regionName: field.region_name,
    regionId: field.region_id,
    tags: [...(field?.direct_tags || [])],
    seasons: [...(field?.seasons || [])],
  };
}

/*
 *
 * Email activation actions below:
 *
 * */

export const searchFarms =
  (query: string, searchBy: string, keysToCheck: string[], entities: any[], reportType: string) =>
  (dispatch: AppDispatch) => {
    dispatch(setRequestStatus(AsyncStatusType.adminSearch, Status.Pending));

    return AdminApi.search(query, searchBy, {with_seasons: true})
      .then((response: any) => {
        dispatch(setRequestStatus(AsyncStatusType.adminSearch, Status.Done));

        const result = (response.data?.result || []).map((field: any) => ({
          ...normalizeFieldObject(field),
        }));
        let farms = [];

        // match emails from seasons
        if (reportType === 'pivothermo_report') {
          farms = mapEmailsToFieldSeason(result, entities);
        }

        // match emails from farms
        if (
          ['stress_detection_report', 'crop_performance_report', 'end_of_season_report'].includes(
            reportType
          )
        ) {
          farms = mapEmailsToFarms(groupFieldsByFarm(result, keysToCheck, reportType), entities);
        }

        dispatch({
          type: ActionType.SET_FARMS_RESULT,
          farms,
          rawFieldsData: reportType === 'end_of_season_report' ? result : [],
        });

        dispatch(setActivationResult(farms));
      })
      .catch(err => {
        dispatch(setRequestStatus(AsyncStatusType.adminSearch, Status.Done));

        if (!axios.isCancel(err)) {
          reportError(`ERR admin searchFarms query=${query}, searchBy=${searchBy}`);
        }
      });
  };

export const setActivationResult = (result: any[]) => ({
  type: ActionType.SET_ACTIVATION_RESULT,
  result,
});

function mapEmailsToFieldSeason(result: any[], entities: any[]): any[] {
  return result.map((field: any) => {
    const entity = entities.find((entity: any) => entity.entity_id === field.seasonId);
    let emails: string[] = [];
    let entityId = 0;
    let manualReview = false;

    if (entity) {
      try {
        entityId = entity.id;

        const data = JSON.parse(entity.value);
        emails = data?.emails || [];
        manualReview = !!data?.manual_review;
      } catch (e) {}
    }

    return {
      ...field,
      // activated only for fields with anomaly detection tag;
      isActivated: field.seasons.some((s: any) =>
        s.direct_tags.some(
          (tag: any) => tag.key === Tags.AnomalyDetection || tag.key === Tags.CropStress
        )
      ),
      entityId,
      emails,
      manualReview: manualReviewMapValue(manualReview),
      hasSeasons: !!field.seasonId,
    };
  });
}

function mapEmailsToFarms(farms: any[], entities: any[]) {
  const farmIDsWithSearchTag = entities.map((el: any) => el.entity_id);

  return farms.map((farm: any) => {
    let emails: string[] = [];
    let entityId = 0;
    let manualReview = false;
    let header_logo_url = LogoType.Regrow;

    if (farmIDsWithSearchTag.includes(farm.farmId)) {
      const entity = entities.find((el: any) => el.entity_id === farm.farmId);

      entityId = entity.id;

      try {
        const data = JSON.parse(entity.value);
        emails = data?.emails || [];
        manualReview = !!data?.manual_review;
        header_logo_url = data?.header_logo_url || LogoType.Regrow;
      } catch (e) {}
    }

    return {
      ...farm,
      entityId: entityId,
      emails: [...emails],
      manualReview: manualReviewMapValue(manualReview),
      header_logo_url,
    };
  });
}

function groupFieldsByFarm(fields: any[], keysToCheck: string[], reportType: string): any[] {
  let farmsObj = {} as any;
  let farmsObjDirty = {} as any;

  fields.forEach((field: any) => {
    if (farmsObjDirty[field.farmId]) {
      farmsObjDirty[field.farmId].push(field);
    } else {
      farmsObjDirty[field.farmId] = [field];
    }
  });

  Object.keys(farmsObjDirty).forEach((k: string) => {
    // prepare data for Latest Crop column
    const fieldsWithSeasons = farmsObjDirty[k].filter((el: any) => el.seasonId);
    const byStartDate = sortByDateKey(fieldsWithSeasons, 'startDate');
    const byEndDate = sortByDateKey(fieldsWithSeasons, 'endDate');
    farmsObjDirty[k] = {
      totalFieldsNumber: farmsObjDirty[k].length,
      totalSeasonsNumber: farmsObjDirty[k].map((f: any) => f?.seasons || []).flat().length,
      firstSowingDate: byStartDate.length
        ? moment(byStartDate[byStartDate.length - 1]?.['startDate']).format(formatDate())
        : '',
      latestHarvestDate: byEndDate.length
        ? moment(byEndDate[byEndDate.length - 1]?.['endDate']).format(formatDate())
        : '',

      activatedSeasonsNumber: farmsObjDirty[k].reduce((count: number, field: any) => {
        let c = count;
        (field?.seasons || []).forEach((s: any) => {
          if (s?.direct_tags?.some((t: any) => keysToCheck.includes(t.key))) {
            c += 1;
          }
        });

        return c;
      }, 0),

      activatedFieldsNumber:
        farmsObjDirty[k].reduce((count: number, field: any) => {
          if (field.tags?.some((t: any) => keysToCheck.includes(t.key))) {
            return count + 1;
          }

          return count;
        }, 0) ||
        keysToCheck[0] === 'cp' ||
        !keysToCheck.length,

      hasSeasons: !!byStartDate.length,
    };
  });

  fields.forEach((field: any) => {
    if (
      !farmsObj[field.farmId] ||
      (farmsObj[field.farmId] && !farmsObj[field.farmId].isActivated)
    ) {
      farmsObj[field.farmId] = {
        ...field,
        isActivated:
          reportType === 'stress_detection_report'
            ? !!farmsObjDirty[field.farmId].activatedSeasonsNumber
            : !!farmsObjDirty[field.farmId].activatedFieldsNumber,
        ...farmsObjDirty[field.farmId],
      };
      delete farmsObj[field.farmId].tags;
    }
  });

  return Object.values(farmsObj);
}

export const loadEntitiesByKey = (entityType: EntityType, key: string) => (dispatch: any) => {
  return TagsApi.getTagEntities(entityType, key)
    .then(({data}: any) => {
      const r = data?.result || [];

      dispatch({
        type: ActionType.SET_ENTITIES,
        entities: [...r],
      });

      return r;
    })
    .catch(err => {
      if (!axios.isCancel(err)) {
        reportError(`ERR admin loadEntitiesByKey entityType=${entityType}, key=${key}`);
      }
    });
};

export const setTagData =
  (farmOrSeasonId: number, entityId: number, reportType: string, entityType: string, data: any) =>
  (dispatch: any) => {
    const value = JSON.stringify({
      emails: data.emails,
      manual_review: manualReviewUnmapValue(data.manualReview),
      header_logo_url: data.header_logo_url,
    });

    // if we do not have emails we should remove exist entity
    if (!data?.emails?.length) {
      // remove entity if exist one
      entityId &&
        TagsApi.removeEntity(entityId).then(() => {
          dispatch({
            type: ActionType.SET_EMAILS,
            emails: data.emails,
            manualReview: data.manualReview,
            header_logo_url: data.header_logo_url,
            farmOrSeasonId,
            entityId: 0,
            entityType,
          });
        });

      return;
    }

    if (entityId) {
      TagsApi.updateEntity({
        id: entityId,
        value,
      }).then(() => {
        dispatch({
          type: ActionType.SET_EMAILS,
          emails: data.emails,
          manualReview: data.manualReview,
          header_logo_url: data.header_logo_url,
          farmOrSeasonId,
          entityId,
          entityType,
        });
      });
    } else {
      TagsApi.createEntity([
        {
          entity_id: farmOrSeasonId,
          entity_type: entityType,
          key: reportType,
          value,
        },
      ]).then((response: any) => {
        const entities = response?.data?.result || [];

        if (entities.length) {
          dispatch({
            type: ActionType.EXTEND_ENTITIES,
            entities,
          });

          dispatch({
            type: ActionType.SET_EMAILS,
            emails: data.emails,
            manualReview: data.manualReview,
            header_logo_url: data.header_logo_url,
            farmOrSeasonId,
            entityId: entities[0].id,
            entityType,
          });
        }
      });
    }
  };

export const sendOneTimeEmailReport = (data: any) => {
  return service.post('report-service/send_crop_performance_report', data, {
    params: {upload: 1},
  });
};
export const sendEndOfSeasonReport = (data: any) => {
  return service.post('report-service/crop_performance_reports/end_of_season', data);
};

export const sendCropStressOneTimeEmail = (data: any) => {
  return service.post('report-service/crop_stress_reports/once_off', data);
};

export const pivothermoOneTimeEmail = (data: any) => {
  return service.post('report-service/pivothermo_reports/once_off', data);
};

/*
*
* Converting Whole Farm response result to items for selected list component
*
* Original object example:
*
* fields: [
*   {
*     Seasons: [
*       infoExt: [
*         {
*           // dates here...
*         }
*       ]
*     ]
*   }
* ]
*
* Converted object example;
*
* [
*   {
*     source: '', source type satellite|plane etc.
      date: '', short date of image, it is need for generate uniq key of date
      dateTime: '', original date of image
      fields: object of field IDs, will converted to fields number
      totalFieldsNumber
*   }
* ]
*
* */
export const loadDates = (farmId: number) => () => {
  return KmlApi.getFileList(farmId, true).then(response => {
    const fields = response?.data?.result?.fields || [];
    const totalFieldsNumber = fields.length;
    const dates = fields
      .map(f =>
        (f?.Seasons || []).map(s =>
          (s?.infoExt || [])
            .filter(
              d =>
                // filter all hidden and clouded images
                !d.Cloud &&
                !d.Hidden &&
                // only images with NDVI sensor support email reports
                d.NDVI
            )
            .map(d => ({
              fieldId: f.ID,
              date: moment(d.NDVI.date).format('YYYYMMDD'),
              dateTime: d.NDVI.date, // keep original date;
              source: d.Type,
              TIRSDate: d.TIRS ? d.Date : null,
            }))
        )
      )
      .flat(2);

    let datesObj = {} as any;

    dates.forEach(d => {
      const key = d.date + d.source;

      if (datesObj[key]) {
        if (datesObj[key].fields[d.fieldId]) {
          datesObj[key].fields[d.fieldId]++;
        } else {
          datesObj[key].fields[d.fieldId] = 1;
        }
      } else {
        datesObj[key] = {
          source: d.source,
          date: d.date,
          dateTime: moment.utc(d.dateTime, 'YYYYMMDDThhmmss').format(),
          TIRSDate: d.TIRSDate ? moment.utc(d.TIRSDate, 'YYYYMMDDThhmmss').format() : null,
          fields: {[d.fieldId]: 1},
          hasTIRS: !!d.TIRSDate,
          totalFieldsNumber,
        };
      }
    });

    return sortByDateKey(Object.values(datesObj), 'dateTime').reverse();
  });
};

export const loadStressedFieldsDates = (farmId: number) => () => {
  return AnomaliesApi.getStressedFieldsPerDate(farmId).then(r => r.data?.result);
};

export const loadStressedFieldsDatesExactTime = (farmId: number) => () => {
  return AnomaliesApi.getStressedFieldsPerDateExactTime(farmId).then(r => r.data?.result);
};

export const loadBaseDataSeasons = (farmId: number, fieldId: number): Promise<BaseSeason[]> => {
  return KmlApi.getKmlOne(farmId, fieldId).then((response: AxiosResponse) => {
    return (response?.data?.result?.kml?.Seasons || []).map((s: Season) => ({
      seasonId: s.id,
      cropType: s.cropType,
      cropVariety: s?.params?.cropSubType || '',
      startDate: s.startDate,
      endDate: s.endDate,
    }));
  });
};

export const selectSeason = (farmId: number, fieldId: number, season: BaseSeason) => ({
  type: ActionType.SET_SELECTOR_SEASON,
  farmId,
  fieldId,
  season,
});

function manualReviewMapValue(value: boolean) {
  return value ? 'queued' : 'automated';
}

function manualReviewUnmapValue(value: string) {
  return value === 'queued';
}

/** Sustainability insights part */
export const fetchStatesList = () => async (dispatch: AppDispatch) => {
  try {
    dispatch(setRequestStatus(AsyncStatusType.SILoadAllStates, Status.Pending));

    const response = await SustainabilityInsightsAPI.getCounties();
    dispatch(setRequestStatus(AsyncStatusType.SILoadAllStates, Status.Done));

    const {states} = response.data;
    const normalizedResponse = states.reduce<AdminFeaturesStore['SIData']>(
      (acc, state) => {
        const {statefp: stateId, state_name, counties} = state;

        acc.statesIds = acc.statesIds.concat(stateId);
        acc.meta[stateId] = acc.meta[stateId] || {
          id: stateId,
          areaType: 'state',
          name: state_name,
        };

        acc.meta = Array.isArray(counties)
          ? counties.reduce(
              (meta: AdminFeaturesStore['SIData']['meta'], county: CountyResponseMeta) => {
                const {county_fips: countyId, county_name} = county;
                acc.countiesIds = acc.countiesIds.concat(countyId);
                meta[countyId] = meta[countyId] || {
                  id: countyId,
                  name: county_name,
                  areaType: 'county',
                  statefp: stateId,
                };
                return meta;
              },
              acc.meta
            )
          : acc.meta;

        return acc;
      },
      {meta: {}, countiesIds: [], statesIds: []}
    );

    const payload = {
      ...normalizedResponse,
      yearsAvailable: SI_YEARS_AVAILABLE,
      metricGroupsAvailable: METRIC_GROUPS,
      aggLevelsAvailable: SI_AREA_AGG_AVAILABLE,
    };

    dispatch({type: ActionType.SI_TAGGING_LOAD_ALL_STATES, payload});
  } catch (error) {
    dispatch(setRequestStatus(AsyncStatusType.SILoadAllStates, Status.Done));
    reportError(error);
  }
};

export const loadSIPolices = () => (dispatch: AppDispatch) => {
  return SustainabilityInsightsAPI.loadPolices().then(({data}) => {
    dispatch({type: ActionType.SI_TAGGING_LOAD_POLICES, polices: data.result});
  });
};

export const updateSIPolicy = (policy: SIPolicyRequestBody) => (dispatch: AppDispatch) => {
  dispatch(setRequestStatus(AsyncStatusType.updateSIPolicy, Status.Pending));

  return SustainabilityInsightsAPI.updatePolicy(policy)
    .then(({data}) => {
      dispatch(setRequestStatus(AsyncStatusType.updateSIPolicy, Status.Done));
      dispatch({
        type: ActionType.SI_TAGGING_UPDATE_POLICY,
        policy: {user_ids: policy.user_ids, ...data.result},
      });
    })
    .catch(() => {
      dispatch(setRequestStatus(AsyncStatusType.updateSIPolicy, Status.Todo));
    });
};

export const deleteSiPolicy = (userId: number) => (dispatch: AppDispatch) => {
  return SustainabilityInsightsAPI.deletePolicy(userId).then(() => {
    dispatch({type: ActionType.SI_TAGGING_DELETE_POLICY, userId});
    showNotification({
      title: t({id: 'note.success', defaultMessage: 'Success'}),
      message: `The SI policy for user with id ${userId} was successfully deleted.`,
      type: 'success',
    });
  });
};

/** END Sustainability insights part */
