import {SI} from '_environment';

export const PROGRAM_ID_PATTERN = ':programId' as const;
export const SUPPLY_SHED_ID_PATTERN = ':supplyShedId' as const;
export const REPORT_ID_PATTERN = ':reportId' as const;

// Top level routes
export const SI_HOME = `/${SI}/programs` as const;
export const SI_ADMIN = `/${SI}/admin` as const;
export const SI_GLOBAL_ADMIN = `/${SI}/global-admin` as const;
export const SI_PROFILE = `/${SI}/profile` as const;

// Program detail Routes
export const SI_FEATURE_GROUPS = `${SI_HOME}/${PROGRAM_ID_PATTERN}` as const;
export const SI_CONFIGURE = `${SI_FEATURE_GROUPS}/configure` as const;
export const SI_MONITOR = `${SI_FEATURE_GROUPS}/monitor` as const;
export const SI_PLAN = `${SI_FEATURE_GROUPS}/plan` as const;

// Configure routes
export const SI_SUPPLY_SHED_CONFIG = `${SI_CONFIGURE}/supply-sheds` as const;
export const SI_SUPPLY_SHED_ADD = `${SI_SUPPLY_SHED_CONFIG}/${SUPPLY_SHED_ID_PATTERN}/add` as const;
export const SI_PROGRAM_SETTINGS = `${SI_CONFIGURE}/settings` as const;

// Monitor routes
export const SI_KPI = `${SI_MONITOR}/kpi` as const;
export const SI_MAP = `${SI_MONITOR}/map` as const;
export const SI_COMPARE = `${SI_MONITOR}/compare` as const;
export const SI_ABATEMENT_POTENTIAL = `${SI_MONITOR}/abatement` as const;
export const SI_DATA_EXPORT = `${SI_MONITOR}/export` as const;

// Plan routes
export const SI_REPORT_LIST = `${SI_PLAN}/reports` as const;
export const SI_REPORT_DASHBOARD = `${SI_REPORT_LIST}/${REPORT_ID_PATTERN}` as const;
