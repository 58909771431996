// @ts-nocheck
import React, {useCallback, useMemo} from 'react';
import {FontIcon, SelectField} from 'react-md';
import {Wrapper, Flag} from './select-language.styled';
import {langInfo, langInfoForRegenConnect, Locales} from 'i18n-utils';
import {useDispatch, useSelector} from 'react-redux';
import type {AppStore} from 'reducers';
import {LoginActionTypes} from 'containers/login/types';
import {safeLocalStorage} from '_utils/safe-local-storage';
import {getRegenConnectState} from 'containers/mrv/regenconnect-landing/regenconnect-landing.utils';
import {Flex} from 'components/flex';
import {push} from 'connected-react-router';

const isRegenConnect = getRegenConnectState();

export const SelectLanguage = () => {
  const dispatch = useDispatch();
  const settings = useSelector((state: AppStore) => state.login.user.settings);
  const langLocale = useSelector((state: AppStore) => state.login.user.settings.langLocale);

  const menuItems = useMemo(() => {
    return (isRegenConnect ? langInfoForRegenConnect : langInfo).map(option => ({
      label: (
        <Flex alignItems="center" className="lang-menu-item" nowrap>
          <FontIcon className="lang-icon">language</FontIcon>
          <Flag className={`iti-flag ${option.flag || 'en'}`} />
          <span
            style={{
              paddingLeft: 8,
              paddingBottom: 0,
            }}
          >
            {option.label}
          </span>
        </Flex>
      ),
      value: option.value,
    }));
  }, []);

  const onChange = useCallback(
    value => {
      settings.langLocale = value;

      dispatch({
        type: LoginActionTypes.UPDATE_USER_DATA,
        data: settings,
      });

      safeLocalStorage.setItem('lang', value);

      if (value === Locales.EnUS) {
        dispatch(push('/en'));
      }

      if (value === Locales.FrCH) {
        dispatch(push('/'));
      }
    },
    [settings, langLocale]
  );

  return (
    <Wrapper>
      <SelectField
        id="select-language"
        menuItems={menuItems}
        dropdownIcon={<FontIcon>keyboard_arrow_down</FontIcon>}
        leftIconStateful
        value={langLocale}
        onChange={onChange}
        simplifiedMenu={true}
        anchor={{
          x: SelectField.HorizontalAnchors.CENTER,
          y: SelectField.VerticalAnchors.TOP,
        }}
      />
    </Wrapper>
  );
};
