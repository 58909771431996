import type {Marker as MarkerT} from 'leaflet';
import React, {forwardRef} from 'react';
import type {MarkerProps} from 'react-leaflet';
import {Marker} from 'react-leaflet';
import {resolveEventHandlers} from './fix-click-handler';

export const FluroMarker = forwardRef<MarkerT, MarkerProps>(
  ({eventHandlers: eventHandlersProp, ...markerProps}, ref) => {
    const eventHandlers: MarkerProps['eventHandlers'] = resolveEventHandlers(eventHandlersProp);

    return <Marker ref={ref} {...markerProps} eventHandlers={eventHandlers} />;
  }
);
