// @ts-nocheck
import {Flex} from 'components';
import {CropAvatar} from 'components/crop-avatar';
import {Col} from 'components/flex';
import {FluroChipDropdown} from 'components/fluro-dropdown/fluro-chip-dropdown';
import {FluroSelectionControlGroup} from 'components/fluro-selection-control-group/fluro-selection-control-group';
import {Span} from 'components/text/span';
import {setFilter} from 'modules/sustainability-insights/actions';
import {
  selectSummerCropTypeOptions,
  selectSummerCropTypesFilter,
  selectAdopterTypeFilter,
  selectOperationSizeFilter,
  selectOperationStatusFilter,
} from 'modules/sustainability-insights/selectors';
import {fetchSummerCropTypes} from 'modules/sustainability-insights/thunks';
import type {ComponentType} from 'react';
import React, {useEffect} from 'react';
import {useDispatch} from 'react-redux';
import {useAppSelector} from '_hooks';
import {calcPlural} from '_utils/pure-utils';

type Props = {};

const CropTypeOption: ComponentType<{
  cropType: string;
  label: string;
}> = ({cropType, label}) => {
  return (
    <Flex alignItems="center" nowrap>
      <Col auto>
        <CropAvatar className="crop-type-select__avatar mr-1" cropType={cropType} />
      </Col>
      <Span>{label}</Span>
    </Flex>
  );
};

export const SummerCropTypeFilter: ComponentType<Props> = () => {
  const dispatch = useDispatch();
  const selected = useAppSelector(selectSummerCropTypesFilter);
  const summerTypesOptions = useAppSelector(selectSummerCropTypeOptions);

  // Grower Demographics Filters
  const adopterTypeFields = useAppSelector(s => selectAdopterTypeFilter(s, 'fieldAdopterType'));
  const adopterTypeOperations = useAppSelector(s =>
    selectAdopterTypeFilter(s, 'operationAdopterType')
  );
  const operationSize = useAppSelector(selectOperationSizeFilter);
  const operationStatus = useAppSelector(selectOperationStatusFilter);
  const anyDemographicFilter = Boolean(
    adopterTypeFields || adopterTypeOperations || operationStatus || operationSize
  );

  useEffect(() => {
    dispatch(fetchSummerCropTypes());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const controls = summerTypesOptions.map(option => {
    return {
      key: option.value,
      label: <CropTypeOption key={option.value} cropType={option.type} label={option.label} />,
      value: option.value,
    };
  });

  const handleFilterChange = (summerCropTypes: string) => {
    dispatch(setFilter({summerCropTypes}));
  };

  const handleClear = () => {
    dispatch(setFilter({summerCropTypes: ''}));
  };

  const chipLabel = selected ? `${selected.split(',').length} selected` : 'All Crops';
  return (
    // use 6px to ensure the years crop types selectors don't wrap when range of years and some crops are selected
    <Flex alignItems="center" gap={'6px'} nowrap>
      <span>Crop {calcPlural('type', controls)}</span>
      <FluroChipDropdown
        active={!!selected}
        onClear={handleClear}
        label={chipLabel}
        dropdownStyle={{left: '-130px'}}
        disabled={anyDemographicFilter}
      >
        <FluroSelectionControlGroup
          id="summer-crop-types-select"
          name="summer-crop-types-select"
          type="checkbox"
          value={selected}
          onChange={handleFilterChange}
          controls={controls}
        />
      </FluroChipDropdown>
    </Flex>
  );
};
