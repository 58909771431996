// @ts-nocheck
import type {ComponentType} from 'react';
import React, {useEffect, useState} from 'react';
import {Flex, FluroButton, FluroDialog, FluroTextarea, Text} from 'components';
import {FluroSelectLite} from 'components/fluro-select-lite/fluro-select-lite';
import {useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import Yup from 'yup';
import {t} from 'i18n-utils';

type Props = {
  onHide: () => void;
  requestContractDeletion: (reason: string) => void;
};

export const RequestContractDeletionDialog: ComponentType<Props> = ({
  onHide,
  requestContractDeletion,
}) => {
  const [reasonItem, setReasonItem] = useState<string>();

  const {
    handleSubmit,
    formState: {errors},
    watch,
    register,
    setValue,
  } = useForm<{reason: string}>({
    resolver: yupResolver(
      Yup.object().shape({
        reason: Yup.string()
          .min(1, t({id: 'ContractDeleteValidator.Reason is required'}))
          .required(t({id: 'ContractDeleteValidator.Reason is required'})),
      })
    ),
    defaultValues: {
      reason: '',
    },
  });

  useEffect(() => {
    let classifiedValue = reasonItem;
    if (reasonItem === 'Other') {
      classifiedValue = ''; // the reason for other must be specified separately
    }
    setValue('reason', classifiedValue, {shouldValidate: !!classifiedValue});
  }, [reasonItem]);

  register('reason');
  const values = watch();

  const submitReason = ({reason}: {reason: string}) => {
    requestContractDeletion(reason);
    onHide();
  };

  const isOther = reasonItem === 'Other';

  return (
    <FluroDialog
      id={'contract-wip'}
      title={t({id: 'Contract deletion request'})}
      onHide={onHide}
      visible={true}
      width={400}
      portal={true}
    >
      <Text variant={'h3'}>{t({id: 'Reason for contract deletion'})}</Text>
      <Text secondary variant={'medium'}>
        {t({
          id: 'ContractDeletionChooseReason',
          defaultMessage:
            'Please choose which reasons describe why you want to delete your agreement below.',
        })}
      </Text>

      <FluroSelectLite
        label={t({id: 'Reason for return'})}
        items={ReasonItems}
        onSelect={setReasonItem}
        selectedValue={reasonItem}
        noSidePadding
        selectedItemAutoWidth
        className={'element-full-width'}
        error={isOther ? null : !!errors['reason']?.message} // for Other the error will be visible next to the Textarea
        errorText={isOther ? null : errors['reason']?.message}
      />
      {isOther && (
        <FluroTextarea
          rows={3}
          value={values.reason}
          placeholder={t({
            id: 'ExplainWhyDeleteContract',
            defaultMessage:
              'Please explain why you want to delete your contract so that we can better support you...',
          })}
          error={errors['reason']?.message}
          onChange={(value: string) => setValue('reason', value, {shouldValidate: true})}
        />
      )}

      <Flex className={'margin-top-15'} justifyContent={'flex-end'} gap={'10px'}>
        <FluroButton raised onClick={onHide} primary>
          {t({id: 'Cancel'})}
        </FluroButton>
        <FluroButton grayBorder raised onClick={handleSubmit(submitReason)} blank>
          {t({id: 'Submit request'})}
        </FluroButton>
      </Flex>
    </FluroDialog>
  );
};

const ReasonItems = [
  {label: t({id: 'ContractDeleteReason.Change in lease status'}), value: 'Change in lease status'},
  {
    label: t({id: 'ContractDeleteReason.Unable to perform practices'}),
    value: 'Unable to perform practices',
  },
  {label: t({id: 'ContractDeleteReason.Other'}), value: 'Other'},
];
