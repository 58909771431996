// @ts-nocheck
import React, {memo, useCallback} from 'react';
import {t} from 'i18n-utils';
import {useSelector, shallowEqual} from 'react-redux';
import {showNotification} from 'components/notification/notification';
import type {IInitialMapState} from 'containers/map/types';
import cn from 'classnames';
import styled from 'styled-components';

type Props = {
  children: any;
  isReadOnly?: boolean;
  message?: string;
  containerClass?: string;
  containerStyle?: any;
  fullWidth?: boolean;
  stopPropagation?: boolean;
};

const Container = styled.div`
  &.read-only-container {
    position: relative;
    cursor: pointer;
    width: ${({fullWidth}: Props) => (fullWidth ? '100%' : 'auto')};

    &::after {
      content: '';
      position: absolute;
      display: block;
      cursor: not-allowed;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 1;
    }
  }
`;

export const ReadOnly = memo(
  ({
    containerClass,
    containerStyle,
    fullWidth,
    message,
    children,
    isReadOnly,
    stopPropagation,
  }: Props) => {
    const _isReadOnly = useSelector(
      ({map}: {map: IInitialMapState}) =>
        isReadOnly !== undefined ? isReadOnly : map.group.readOnly,
      shallowEqual
    );

    const handleClick = useCallback(
      (ev: React.MouseEvent<HTMLElement>) => {
        if (stopPropagation) {
          ev.stopPropagation();
        }
        showNotification({
          title: t({id: 'note.warning', defaultMessage: 'Warning'}),
          message: t({
            id: 'readOnlyFarm',
            defaultMessage:
              'This farm is read-only, create your own farm to use this functionality.',
          }),
          type: 'warning',
        });
      },
      [message, _isReadOnly]
    );

    if (_isReadOnly) {
      const _children =
        children && typeof children === 'object' && children.props
          ? React.cloneElement(children, {disabled: true})
          : children;

      return (
        // TODO: fixme a11y
        // eslint-disable-next-line styled-components-a11y/click-events-have-key-events, styled-components-a11y/no-static-element-interactions
        <Container
          className={cn('read-only-container', {[containerClass]: !!containerClass})}
          fullWidth={!!fullWidth}
          style={{
            ...(containerStyle || {}),
          }}
          onClick={handleClick}
        >
          {_children}
        </Container>
      );
    }

    return children;
  }
);
export default ReadOnly;
