// @ts-nocheck
import type {ComponentType} from 'react';
import React, {useCallback} from 'react';
import {useUrlParams} from '_hooks/use-url-params';

type Props = {};

export const useMapBarView = (): [boolean, (set: boolean) => void] => {
  const [params, setParams] = useUrlParams<{tableView: boolean}>();
  const isTableView = params.tableView;
  const setTableView = useCallback(
    (set: boolean) => {
      setParams({tableView: set ? true : undefined});
    },
    [setParams]
  );
  return [isTableView, setTableView];
};

export const MapBarTableView: ComponentType<Props> = ({children}) => {
  const [isTableView] = useMapBarView();
  if (!isTableView) return null;
  return <>{children}</>;
};

export const MapBarMapView: ComponentType<Props> = ({children}) => {
  const [isTableView] = useMapBarView();
  if (isTableView) return null;
  return <>{children}</>;
};
