import chroma from 'chroma-js';
import type {ComponentType} from 'react';
import React from 'react';
import {Flex} from '../flex';
import './chroma-scale.scss';

type Props = {
  colors: string[];
  domain?: number[];
  max: string;
  min: string;
};

export const ChromaScale: ComponentType<Props> = ({colors, domain, min, max}) => {
  let scale = chroma.scale(colors);
  if (domain) {
    scale = scale.domain(domain);
  }

  const scaleSteps = new Array(100).fill(0).map((v, i) => i / 100);

  return (
    <div className="chroma-scale__container">
      <Flex alignItems="stretch" className="chroma-scale__scale">
        {scaleSteps.map(step => (
          <span className="chroma-scale__step" style={{background: scale(step).css()}} key={step} />
        ))}
      </Flex>
      <Flex justifyContent="space-between" className="chroma-scale__labels">
        {!!min && <span>{min}</span>}
        {!!max && <span>{max}</span>}
      </Flex>
    </div>
  );
};
