import type {FC} from 'react';
import React, {Fragment, useMemo, useState} from 'react';
import type {BarItemProps, BarSvgProps} from '@nivo/bar';
import {ResponsiveBar} from '@nivo/bar';
import {NoData} from 'containers/si/kpi/styled-components';
import {Text} from 'components';
import {sortByKeyReverse} from '_utils/sorters';
import {kiloFormatter} from '_utils/number-formatters';
import type {SIAreaUnits} from 'modules/sustainability-insights/types';
import type {BarDataType} from 'containers/si/types';
import type {Box} from '@nivo/core';
import type {AxisTickProps} from '@nivo/axes';
import {ChartNav} from '../chart-nav';

interface BarDataTypeSecondary extends BarDataType {
  secondaryValue?: string;
}

export interface BarChartProps extends BarSvgProps {
  colors: string[];
  data: BarDataTypeSecondary[];
  kiloConvert?: boolean;
  unit?: string;
  margin?: Box;
  chartDimension?: {width?: number; height?: number};
  customRenderTick?: (props: AxisTickProps<any>) => JSX.Element;
  sorted?: boolean;
}

export const BarChart: FC<BarChartProps> = ({
  data,
  colors,
  kiloConvert,
  unit,
  margin = {},
  chartDimension = {},
  customRenderTick,
  sorted = true,
  ...barProps
}) => {
  const [page, setPage] = useState(0);

  const sortedData = useMemo(() => {
    return sorted ? sortByKeyReverse(data, 'value') : data; // use sortByKeyReverse to get values from bigger to smaller
  }, [data, sorted]);

  const dataPerPage = useMemo(() => {
    const pageToDataNumber = page * 10;
    if (sortedData.length <= 10) return sortedData.reverse();
    return sortedData.slice(pageToDataNumber, pageToDataNumber + 10).reverse(); // reverse the data for the chart one more time
  }, [page, sortedData]);

  const showPagesNav = sortedData.length > 10;

  return (
    <Fragment>
      {data.length ? (
        <div style={{width: '100%', height: 270, ...chartDimension}}>
          <ResponsiveBar
            data={dataPerPage}
            indexBy="label"
            layout="horizontal"
            enableGridY={false}
            margin={{top: 0, right: 40, bottom: 0, left: 75, ...margin}}
            padding={0.3}
            enableLabel={false}
            colors={b => colors[b.index] || colors[0] || '#60b854'}
            isInteractive={false}
            axisLeft={{
              tickSize: 0,
              renderTick: customRenderTick ? customRenderTick : undefined,
            }}
            layers={[
              'grid',
              'axes',
              'bars',
              'markers',
              //   'legends',
              props => <ValueOutside {...props} kiloConvert={kiloConvert} selectedUnit={unit} />,
            ]}
            legends={[]}
            role="application"
            {...barProps}
          />
        </div>
      ) : (
        <NoData>
          <Text variant="h1" bold>
            No Data
          </Text>
        </NoData>
      )}
      {showPagesNav && (
        <ChartNav
          dataLength={data.length}
          itemsPerPage={10}
          onPageChange={chartPage => setPage(chartPage)}
        />
      )}
    </Fragment>
  );
};

const ValueOutside = ({
  bars,
  kiloConvert,
  selectedUnit = 'num',
}: {
  bars: Array<BarItemProps & {key: string}>;
  kiloConvert: boolean;
  selectedUnit: SIAreaUnits;
}) => {
  return (
    <>
      {bars.map((bar, index) => {
        const {
          key,
          width,
          height,
          x,
          y,
          data: {
            value,
            data: {secondaryValue},
          },
        } = bar;

        return (
          <g key={`${key}${index}`} transform={`translate(${x}, ${y})`}>
            <text
              transform={`translate(${value >= 0 ? width + 5 : -5}, ${height / 2 + 3})`}
              textAnchor={`${value >= 0 ? 'start' : 'end'}`}
              fontSize="12px"
              fontWeight={600}
            >
              {kiloConvert ? kiloFormatter(value) : value}
              {selectedUnit === 'pct' ? '%' : ''}
              {secondaryValue && (
                <tspan fontSize="12px" fontWeight={300}>
                  {` ${secondaryValue}`}
                </tspan>
              )}
            </text>
          </g>
        );
      })}
    </>
  );
};
