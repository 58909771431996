import {SIAccess} from 'containers/login/types';
import {getRegenConnectState} from 'containers/mrv/regenconnect-landing/regenconnect-landing.utils';
import {DEFAULT_LOCALE, isAllowedLocale, Locales} from 'i18n-utils';
import {safeLocalStorage} from '_utils/safe-local-storage';
import {applyWorkspace} from './types';
import type {UserSettings, User} from './types';

const isRegenConnect = getRegenConnectState();

export const InitialUser: User = {
  email: '',
  name: '',
  perm: 0,
  id: 0,
  groupIds: [],
  agxSync: false,
  photo: '',
  active: 0,
  emailConfirmed: false,
  demoFarms: [],

  settings: {
    showDemoFarms: true,
    zoning: {
      doNotShowConfirmPopUp: false,
      favoriteZoningMethod: {},
    },
    langLocale: (() => {
      if (isRegenConnect && window.location.pathname === '/') {
        safeLocalStorage.setItem('lang', Locales.FrCH);

        return safeLocalStorage.getItem('lang') as Locales;
      }

      if (isRegenConnect && window.location.pathname === '/en') {
        safeLocalStorage.setItem('lang', Locales.EnUS);

        return safeLocalStorage.getItem('lang') as Locales;
      }

      return isAllowedLocale(safeLocalStorage.getItem('lang'))
        ? (safeLocalStorage.getItem('lang') as Locales)
        : DEFAULT_LOCALE;
    })(),
    // leave prop locale for backward compatibility
    locale: 'uk-UA',
    // metricSystem: '',
    measurement: applyWorkspace({
      mrv: 'ac',
      other: 'ha',
    }),
    onboarding: {
      monitoring_video_onboarded: false,
      re_enroll_fields_onboarded: false,
    },
    company: {
      companyName: '',
      businessActivity: '',
      jobTitle: '',
      country: '',
      street: '',
      state: '',
      city: '',
      zip: '',
      licenses: {
        PCA: false,
        CCA: false,
        CPCC: false,
      },
    },
    pushInfo: null,
    timezone: '2eirus',
    // notificationFrequency: 3,
    tspGroups: [], // [{fieldId: '', groups: [{date: '', gs: '', tsp: [1, 2, 3]}]}]
    si_tool_access: SIAccess.Off,
    si_saved_filters: {},
    favorite_platforms: {} as UserSettings['favorite_platforms'],
    mostRecentWorkspace: undefined,
  },
};
