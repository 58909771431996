import type {ReactNode, ReactElement} from 'react';
import React from 'react';
import {t} from 'i18n-utils';
import './index.scss';
import cn from 'classnames';
import DatePicker, {CalendarContainer, registerLocale} from 'react-datepicker';
import {showNotification} from 'components/notification/notification';
import type {Moment} from 'moment';
import moment from 'moment';
import {FontIcon, Portal} from 'react-md';
import {useAppSelector} from '../../_hooks';
import {selectUserLocale} from '../../containers/login/login-selectors';
import fr from 'date-fns/locale/fr';

registerLocale('fr', fr);

type Props = {
  id: string;
  name?: string;
  label?: string;
  selected?: Moment;
  portal?: boolean; // if true is provided the dialog-root will be used as the portal container, otherwise a normal datepicker will be rendered
  hideFormat?: boolean; // DEPRECATED. We didn't hide format only twice. Let's kill this property and always hide it.
  disabled?: boolean;
  excludeDates?: Date[]; // dates to disable
  showMonthDropdown?: boolean;
  showYearDropdown?: boolean;
  placeholderText?: string;
  highlightDates?: Array<{[className: string]: Date[]} | Date>;
  minDate?: Moment;
  maxDate?: Moment;
  autoComplete?: string;
  className?: string;
  error?: boolean;
  errorText?: string;
  onDisabledClickMessage?: string;
  hasPopper?: boolean;
  hasLabelIndent?: boolean;
  locale?: string;
  openToDate?: Date;
  fullWidth?: boolean;
  icon?: boolean;
  calendarContainer?(props: {children: React.ReactNode[]}): React.ReactNode;
  onChange(date: Moment): void;
  onChangeRaw?(event: React.FocusEvent<HTMLInputElement>): void;
  onContainerClick?(e: MouseEvent): void;
};

const CalendarPopper = ({children}: any) => {
  return <Portal visible>{children}</Portal>;
};

export const FluroDatePicker = (props: Props) => {
  const userLocale = useAppSelector(selectUserLocale);
  const onContainerClick_ =
    props.onContainerClick ||
    ((e: MouseEvent) => {
      const isOnDisable = (e.target as any).closest('.react-datepicker__day--disabled');

      if (isOnDisable) {
        props.onDisabledClickMessage &&
          showNotification({
            title: t({id: 'note.warning', defaultMessage: 'Warning'}),
            message: props.onDisabledClickMessage,
            type: 'warning',
          });
      }
    });

  return (
    <FluroDatePickerPure {...props} locale={userLocale} onContainerClick={onContainerClick_} />
  );
};

export const FluroDatePickerPure = ({
  id,
  name,
  selected,
  portal,
  placeholderText,
  highlightDates,
  excludeDates,
  onChange,
  onContainerClick,
  disabled,
  className = '',
  label,
  autoComplete,
  minDate = moment('07-01-2015'),
  maxDate = moment().add(2, 'years'),
  error,
  errorText,
  showMonthDropdown = true,
  showYearDropdown = true,
  calendarContainer,
  hasPopper = false,
  hasLabelIndent = true,
  locale,
  openToDate,
  fullWidth = true,
  icon = true,
}: Props) => {
  // @ts-expect-error error leftover from convertion to strict mode, please fix
  const customArrows = {
    nextMonthButtonLabel: <FontIcon>keyboard_arrow_right</FontIcon>,
    previousMonthButtonLabel: <FontIcon>keyboard_arrow_left</FontIcon>,
  } as {nextMonthButtonLabel: string; previousMonthButtonLabel: string};

  const handleChange = (date: Date) => {
    if (typeof onChange !== 'function') return;

    if (Array.isArray(date)) {
      onChange(moment(date[0]));
    } else {
      onChange(moment(date));
    }
  };

  return (
    <div
      onClick={(ev: any) => onContainerClick?.(ev)}
      className={cn(
        `fluro-datepicker md-text-field-container md-text-field-container--input ${className}`,
        {disabled, 'md-full-width': fullWidth}
      )}
    >
      {
        // TODO: fixme a11y
        // eslint-disable-next-line jsx-a11y/label-has-associated-control, jsx-a11y/label-has-associated-control, jsx-a11y/label-has-for
        <label
          className={cn('md-floating-label md-floating-label--floating md-text--secondary', {
            'md-floating-label--icon-offset': hasLabelIndent,
          })}
        >
          {label}
        </label>
      }
      <div className="md-text-field-icon-container">
        {icon && (
          <i className="md-icon material-icons md-text-field-icon md-text-field-icon--positioned">
            date_range
          </i>
        )}
        <div className="md-text-field-divider-container md-text-field-divider-container--grow">
          <DatePicker
            id={id}
            name={name || id}
            selected={selected && selected.isValid() ? selected?.toDate() : null}
            dateFormat={locale ? getDateFormat(locale) : undefined}
            highlightDates={highlightDates}
            excludeDates={excludeDates}
            locale={locale}
            onChange={handleChange}
            autoComplete={autoComplete}
            placeholderText={placeholderText}
            showMonthDropdown={showMonthDropdown}
            showYearDropdown={showYearDropdown}
            minDate={minDate.toDate()}
            maxDate={maxDate.toDate()}
            className={`"md-text-field ${
              label ? 'md-text-field--floating-margin' : 'md-text-field'
            } md-full-width md-text md-pointer--hover"`}
            calendarContainer={calendarContainer}
            showPopperArrow={false}
            portalId={portal ? 'dialog-root' : ''}
            popperContainer={hasPopper ? CalendarPopper : undefined}
            disabled={disabled}
            openToDate={openToDate}
            {...customArrows}
          />

          <hr
            className={cn('md-divider md-divider--text-field md-divider--expand-from-left', {
              'md-divider--error': error,
            })}
          />
        </div>
      </div>

      {error && errorText ? (
        <div className="md-text-field-message-container md-text-field-message-container--left-icon-offset md-full-width md-text--error">
          <div className="md-text-field-message md-text-field-message--active">{errorText}</div>
        </div>
      ) : null}
    </div>
  );
};

export const FluroCalendarContainer = ({
  defaultCalendar,
  children,
  className = '',
}: {
  className?: string;
  defaultCalendar?: ReactNode;
  children: ReactElement;
}) => {
  return (
    <CalendarContainer className={`react-datepicker ${className}`}>
      <div>{defaultCalendar}</div>
      {children}
    </CalendarContainer>
  );
};

const getDateFormat = (userLocale: string) => {
  // we need this function because the datepicker doesn't support DD or YYYY date formats and throws an error
  let dateFormat = 'dd MMM yyyy';
  if (predefinedDateFormats[userLocale]) dateFormat = predefinedDateFormats[userLocale];

  return dateFormat;
};

const predefinedDateFormats = {
  'en-US': 'MM/dd/yyyy',
  'uk-UA': 'dd MMM yyyy',
} as {[locale: string]: string};
