import React, {useEffect, useMemo, useState} from 'react';
import {useAppDispatch, useAppSelector} from '_hooks';
import {useParsedMatchParams} from '_hooks/use-parsed-match-params';
import convert from 'convert-units';
import {FontIcon} from 'react-md';
import {Flex, FluroButton, FluroDialog, Text} from 'components';
import {ReportingIcon} from 'containers/mrv/icons';
import {CropInsightsIcon, PartFieldIcon} from 'containers/map/icons';
import {DetailsStep} from './details/details-step';
import {AreasStep} from './areas/areas-step';
import {CropsStep} from './crops/crops-step';
import {InterventionsStep} from './interventions/interventions-step';
import {SummaryStep} from './summary/summary-step';
import {fetchSISupplySheds, addPlanningReport} from 'containers/si/module/thunks';
import {
  selectSISupplySheds,
  selectNewReportAreas,
  selectNewReportCrops,
  selectCropStepBlocked,
  selectNewInterventions,
  selectNewReportName,
  selectNewReportStartYear,
  selectNewReportEndYear,
  selectNewReportType,
  selectNewReportModel,
  selectNewReportProtocol,
} from 'containers/si/module/selectors';
import {selectUserVisibleName} from 'containers/login/login-selectors';
import type {StepsItem} from 'containers/si/types';
import {PLANNING_REPORT_INTERVENTION_SCENARIOS} from 'containers/si/constants';
import type {InterventionType, SourcingRegionConfig} from 'containers/si/api/apiTypes';
import {getTypedEntries, getTypedKeys} from '_utils/object';
import {InlineStepper} from 'containers/si/components/InlineStepper';

export const CreateReportPopup = ({onHide}: {onHide: () => void}) => {
  const dispatch = useAppDispatch();
  const {programId} = useParsedMatchParams();
  const supplySheds = useAppSelector(selectSISupplySheds);
  const allSelectedAreas = useAppSelector(selectNewReportAreas);
  const allSelectedCrops = useAppSelector(selectNewReportCrops);
  const cropStepBlocked = useAppSelector(selectCropStepBlocked);
  const allGeneratedInterventions = useAppSelector(selectNewInterventions);
  const reportName = useAppSelector(selectNewReportName);
  const reportStartYear = useAppSelector(selectNewReportStartYear);
  const reportEndYear = useAppSelector(selectNewReportEndYear);
  const reportType = useAppSelector(selectNewReportType);
  const reportModel = useAppSelector(selectNewReportModel);
  const reportProtocol = useAppSelector(selectNewReportProtocol);
  const userVisibleName = useAppSelector(s => selectUserVisibleName(s, true));

  const [currentStep, setCurrentStep] = useState(0);

  useEffect(() => {
    if (!supplySheds[programId]) {
      dispatch(fetchSISupplySheds(programId));
    }
  }, [dispatch, programId, supplySheds]);

  const convertCrop = (mass: number, unit: string) => {
    switch (unit) {
      case 'lb':
        return convert(mass).from('lb').to('kg');
      case 't':
        return convert(mass).from('t').to('kg');
      case 'mt':
        return convert(mass).from('mt').to('kg');
      case 'kg':
        return mass;
      default:
        return mass;
    }
  };

  const saveNewReport = () => {
    const sourcingRegions: Array<SourcingRegionConfig> = [];

    getTypedEntries(allSelectedCrops).map(([supplyShedId, selectedObject]) => {
      if (selectedObject.commodities) {
        // Add all the commodities in the Supply Shed to the array
        const supplyShedCommodities = selectedObject.commodities.map(crop => ({
          id: crop.id,
          volume: convertCrop(crop.volume, crop.volume_units),
          volume_units: crop.volume_units,
        }));

        // Add the Supply Shed to the sourcingRegions array
        return sourcingRegions.push({
          unit_id: supplyShedId,
          unit_type: 'supply_shed',
          commodities: supplyShedCommodities,
        });
      } else {
        return (
          selectedObject?.subsection &&
          getTypedEntries(selectedObject?.subsection).map(
            ([subsectionId, subsectionCommodities]) => {
              const subsectionCommoditiesArray = subsectionCommodities.map(crop => {
                return {
                  id: crop.id,
                  volume: convertCrop(crop.volume, crop.volume_units),
                  volume_units: crop.volume_units,
                };
              });

              return sourcingRegions.push({
                unit_id: subsectionId,
                unit_type: 'subsection',
                commodities: subsectionCommoditiesArray,
              });
            }
          )
        );
      }
    });

    const interventionScenarios = allGeneratedInterventions.map(intervention => {
      const interventionArray: {intervention_type: InterventionType; percentage: number}[] = [];

      if (getTypedKeys(PLANNING_REPORT_INTERVENTION_SCENARIOS).includes(intervention.tillage)) {
        const tillage =
          PLANNING_REPORT_INTERVENTION_SCENARIOS[
            intervention.tillage as keyof typeof PLANNING_REPORT_INTERVENTION_SCENARIOS
          ];
        const tillageScenario = {
          intervention_type: tillage.intervention_type,
          percentage: tillage.percentage,
        };
        interventionArray.push(tillageScenario);
      }
      if (
        getTypedKeys(PLANNING_REPORT_INTERVENTION_SCENARIOS).includes(intervention.cover_cropping)
      ) {
        const coverCropping =
          PLANNING_REPORT_INTERVENTION_SCENARIOS[
            intervention.cover_cropping as keyof typeof PLANNING_REPORT_INTERVENTION_SCENARIOS
          ];
        const coverCroppingScenario = {
          intervention_type: coverCropping.intervention_type,
          percentage: coverCropping.percentage,
        };
        interventionArray.push(coverCroppingScenario);
      }
      if (
        getTypedKeys(PLANNING_REPORT_INTERVENTION_SCENARIOS).includes(intervention.fertilizer_usage)
      ) {
        const fertilizerUsage =
          PLANNING_REPORT_INTERVENTION_SCENARIOS[
            intervention.fertilizer_usage as keyof typeof PLANNING_REPORT_INTERVENTION_SCENARIOS
          ];
        const fertilizerUsageScenario = {
          intervention_type: fertilizerUsage.intervention_type,
          percentage: fertilizerUsage.percentage,
        };
        interventionArray.push(fertilizerUsageScenario);
      }

      return interventionArray;
    });

    const newReport = {
      name: reportName,
      owner: userVisibleName,
      report_type: reportType,
      model: reportModel,
      protocol: reportProtocol,
      sourcing_regions: sourcingRegions,
      intervention_scenarios: interventionScenarios,
      aggregation_units: ['admin_2'],
      start_year: reportStartYear,
      end_year: reportEndYear,
    };

    dispatch(addPlanningReport({programId, report: newReport}));
    onHide();
  };

  const items: StepsItem[] = useMemo(() => {
    return [
      {
        label: 'Report details',
        icon: <PartFieldIcon fill="white" />,
        screen: <DetailsStep />,
        disabled: false,
      },
      {
        label: 'Areas',
        icon: <PartFieldIcon fill="white" />,
        screen: <AreasStep />,
        disabled: reportName === '',
      },
      {
        label: 'Crops',
        icon: <CropInsightsIcon />,
        screen: <CropsStep />,
        disabled: Object.keys(allSelectedAreas).length === 0,
      },
      {
        label: 'Interventions',
        icon: <FontIcon style={{color: 'white'}}>dashboard</FontIcon>,
        screen: <InterventionsStep />,
        disabled: cropStepBlocked,
      },
      {
        label: 'Summary',
        icon: <ReportingIcon />,
        screen: <SummaryStep />,
        disabled: allGeneratedInterventions.length === 0,
      },
    ];
  }, [allGeneratedInterventions, allSelectedAreas, cropStepBlocked, reportName]);

  return (
    <FluroDialog
      id={'create-report-popup'}
      onHide={onHide}
      visible={true}
      width={'80%'}
      portal={true}
    >
      <Text variant="h2" className="mt-2 margin-bottom-8" bold>
        New planning report
      </Text>
      <Text variant="h3" className="mb-0.5" secondary>
        Identify areas of opportunity and plan reduction / removal programs.
      </Text>

      <InlineStepper
        stepItems={items}
        currentStep={currentStep}
        onUpdateStep={setCurrentStep}
        finalStepComponent={
          <FluroButton raised primary onClick={saveNewReport}>
            Request
          </FluroButton>
        }
      />

      {items[currentStep]?.screen}

      <Flex className="mt-4" justifyContent="flex-end">
        <FluroButton raised className="mr-2" onClick={onHide}>
          Cancel
        </FluroButton>
      </Flex>
    </FluroDialog>
  );
};
