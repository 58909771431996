import Server from '../server';
import type {PWSyncRequestPayload} from 'containers/profile/integration/integration-productionwise';

export default class ProductionWiseIntegrationApi {
  static getFarms() {
    return Server.get(`sync/productionwise/farms`, {params: {__skipPreloader: true}});
  }

  static getFields(farm_id: string) {
    return Server.get(`sync/productionwise/farms/${farm_id}/paddocks`, {
      params: {__skipPreloader: true},
    });
  }

  static syncFarms(payload: PWSyncRequestPayload, mrvProjectId: number) {
    const requestParams: Record<string, any> = {__skipPreloader: true};

    if (mrvProjectId) {
      requestParams.mrv_project_id = mrvProjectId;
    }

    return Server.post(`sync/productionwise/import`, payload, {
      params: requestParams,
    });
  }

  static getImportStatus(jobId: number) {
    return Server.get(`sync/productionwise/import/${jobId}`, {params: {__skipPreloader: true}});
  }
}
