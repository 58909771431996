// @ts-nocheck
import {
  getFieldAndSettingsDetails,
  getHeaderImage,
  getFooterImg,
  getZonesPreviewAndValuesImg,
} from './export-to-pdf-utils';
import type {IInitialMapState} from '../../../types';
import moment from 'moment';
import {formatDate} from '_utils';
import Mixpanel from '_utils/mixpanel-utils';
import {selectMeasurement} from '../../../../login/login-selectors';

const exportTreeZoningToPdf = () => async (dispatch: any, getState: any) => {
  Mixpanel.exportTreeZones('PDF');
  const state = getState();
  const {group: farm, field, currentSeason, currentDate, zoning}: IInitialMapState = state.map;
  const measurement = selectMeasurement(state);
  const recommendationDate = moment(currentDate, 'DD/MM/YYYY').format(formatDate());

  const html2canvas = (await import('html2canvas')).default;
  const jsPDF = (await import('jspdf')).default;

  const [headerImg, headerImgHeight] = await getHeaderImage(recommendationDate, html2canvas);

  const [fieldAndSettingsImg, fieldAndSettingsImgHeight] = await getFieldAndSettingsDetails(
    farm,
    field,
    currentSeason,
    measurement,
    html2canvas
  );
  const [zonesPreviewAndValuesImg, zonesPreviewImgHeight] = await getZonesPreviewAndValuesImg(
    zoning.treeZonesImage,
    html2canvas
  );
  const [footerImg, footerImgHeight] = await getFooterImg(html2canvas);

  const NrxPdf = new jsPDF();
  // hint -  210 / 297 is the default A4 page size
  // page 1
  NrxPdf.addImage(headerImg, 'JPEG', 0, 0, 210, headerImgHeight);
  NrxPdf.addImage(fieldAndSettingsImg, 'JPEG', 0, headerImgHeight, 210, fieldAndSettingsImgHeight);
  NrxPdf.addImage(
    zonesPreviewAndValuesImg,
    'JPEG',
    0,
    headerImgHeight + fieldAndSettingsImgHeight,
    210,
    zonesPreviewImgHeight
  );
  NrxPdf.addImage(footerImg, 'JPEG', 0, 297 - footerImgHeight, 210, footerImgHeight);

  NrxPdf.save(`${field.Name} - Tree analysis`);
};

export default exportTreeZoningToPdf;
