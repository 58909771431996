import {Flex, FluroButton, FluroDialog, Text} from 'components';
import {TextField} from 'react-md';
import React, {useState} from 'react';

// Returns number or string, someday fix so it only returns the type of value passed in
type SIInputProps<T extends string | number> = {
  title: string;
  subtitle?: string;
  type: 'text' | 'number';
  value: T;
  onSave: (value: T) => void;
  onHide: () => void;
  saveText?: string;
  noCancel?: boolean;
  disabled?: boolean;
  portal?: boolean;
  maxLength?: number; // used only for text, someday fix for only number props
};

export const SIInputPopup = <T extends string | number>({
  title,
  subtitle,
  value,
  onSave,
  onHide,
  type = 'text',
  saveText = 'Save',
  noCancel = false,
  disabled = false,
  portal = true,
  maxLength = 50,
}: SIInputProps<T>) => {
  const [newValue, setNewValue] = useState<T>(value);

  return (
    <FluroDialog
      id={'si-text-input-popup'}
      title={title}
      onHide={onHide}
      visible={true}
      width={400}
      portal={portal}
    >
      <Flex direction="column">
        {subtitle && <Text secondary>{subtitle}</Text>}
        <TextField
          type={type}
          id="si-text-input"
          name="si-text-input"
          value={newValue}
          className="mt-0"
          maxLength={type === 'text' ? maxLength : undefined}
          onChange={v => {
            if (maxLength && String(v).length > maxLength) {
              return;
            }
            setNewValue(v as T);
          }}
        />
      </Flex>
      <Flex justifyContent="space-between" className="mt-2">
        {noCancel ? (
          <></>
        ) : (
          <FluroButton raised blank onClick={onHide}>
            Cancel
          </FluroButton>
        )}
        <FluroButton raised primary onClick={() => onSave(newValue)} disabled={disabled}>
          {saveText}
        </FluroButton>
      </Flex>
    </FluroDialog>
  );
};
