import {isDefined, isEmptyString} from '_utils/typeGuards';

export type Validation<T> = [predicate: (v: T) => boolean, message: string];

// default validations
export const emptyValidation: Validation<string> = [isEmptyString, 'Required'];
export const validNumberValidation: Validation<string> = [
  (v: string) => isNaN(Number(v)),
  'Invalid input',
];

// Range-based validation
export const minValidation = (min?: number): Array<Validation<string>> =>
  isDefined(min) ? [[(v: string) => Number(v) < min, `Value must be at least ${min}`]] : [];
export const maxValidation = (max?: number): Array<Validation<string>> =>
  isDefined(max) ? [[(v: string) => Number(v) > max, `Value must be at most ${max}`]] : [];
