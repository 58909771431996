import {Flex, FluroChip} from 'components';
import {CropAvatarLite} from 'components/crop-avatar/crop-avatar-lite';
import {selectCropLabelPropMRV} from 'containers/login/login-selectors';
import {MRVAttributeType} from 'containers/mrv/types';
import {t} from 'i18n-utils';
import {uniq} from 'lodash';
import {selectCropTypes, selectCropTypesCDLIdMap} from 'modules/global/selectors';
import type {ComponentType} from 'react';
import React, {useMemo} from 'react';
import {useAppSelector} from '_hooks';
import {useUrlParams} from '_hooks/use-url-params';
import {getCropSrcServer} from '_utils/pure-utils';
import {mixpanelCarbon} from '_utils/mixpanel-utils';
import {selectCurrentProjectMonitoringValuesForAttributeByType} from '../module/monitoring.selectors';

type Props = {};

export const CropTypesFilter: ComponentType<Props> = () => {
  const [{summerCrops}, setParams] = useUrlParams();
  const cropTypesByName = useAppSelector(selectCropTypes);
  const cropTypesByCDLId = useAppSelector(selectCropTypesCDLIdMap);
  const uniqueSummerCrops = useAppSelector(s =>
    uniq(
      selectCurrentProjectMonitoringValuesForAttributeByType(
        s,
        MRVAttributeType.SummerCropType
      ).map(({value}) => value)
    )
  );
  const labelProp = useAppSelector(selectCropLabelPropMRV);

  const cropsList = useMemo(
    () => (summerCrops ? String(summerCrops).split(',') : []),
    [summerCrops]
  );

  const onCropToggle = (crop: string) => {
    const newSummerCrops = cropsList.includes(crop)
      ? cropsList.filter(c => c !== crop)
      : [...cropsList, crop];
    setParams({summerCrops: newSummerCrops});

    mixpanelCarbon.track('measurement_crop_filter');
  };

  return (
    <Flex alignItems="center" gap="0.5rem" className="ml-3">
      {t({id: 'Summer crop'})}
      <Flex>
        {uniqueSummerCrops.map(crop => {
          const cropLabel = cropTypesByCDLId?.[crop]?.[labelProp] || cropTypesByName[crop]?.label;

          return (
            <FluroChip
              key={crop}
              id={crop}
              label={cropLabel || crop}
              leftIcon={<CropAvatarLite cropType={crop} iconSrc={getCropSrcServer(crop)} />}
              active={cropsList.includes(crop)}
              onClick={onCropToggle}
            />
          );
        })}
      </Flex>
    </Flex>
  );
};
