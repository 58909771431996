import Leaflet from 'leaflet';
import {PANEL_PADDING} from '../_constants';
import type GeoJSON from 'geojson';
import type {AnyProps, ClusterFeature, PointFeature} from 'supercluster';

/*
 https://flurosat.atlassian.net/browse/FSB-3458

 NOTE: the dates array MUST be sorted newest to oldest dates
 */
export function preselectCompareDate(datesObj: {[key: string]: any}, currentDate: string) {
  const dates = Object.keys(datesObj);

  if (!dates.length || dates.length === 1) return currentDate;

  const currentDateIndex = dates.findIndex(date => date === currentDate);

  if (currentDateIndex) {
    return dates[currentDateIndex - 1];
  }

  return dates[1];
}

export function centerHighlightedField(
  fieldGeometry: GeoJSON.GeoJSON,
  leafletMap: Leaflet.Map,
  fitBounds: (bounds?: Leaflet.LatLngBounds, options?: Leaflet.FitBoundsOptions) => void
) {
  if (!fieldGeometry) return;

  const fieldBounds = Leaflet.geoJSON(fieldGeometry).getBounds();

  const zoomToSet = 15;

  const fieldIsInView = leafletMap.getBounds().contains(fieldBounds);
  const zoomLevelIsFine = leafletMap.getZoom() >= zoomToSet;

  if (fieldIsInView && zoomLevelIsFine) return; // no need to zoom then

  fitBounds(fieldBounds, {maxZoom: zoomToSet, ...PANEL_PADDING}); // center the field, not zoom in too much
}

export function toLatLng(coordinates: GeoJSON.Position): Leaflet.LatLng {
  return Leaflet.latLng(coordinates[1], coordinates[0]);
}

export function toBBox(bounds: Leaflet.LatLngBounds): GeoJSON.BBox {
  return [bounds.getWest(), bounds.getSouth(), bounds.getEast(), bounds.getNorth()];
}

/**
 * Determines whether a feature is a ClusterFeature or a PointFeature.
 *
 * Created because the supercluster package types aren't optimal.
 */
export function isCluster(
  feature: ClusterFeature<AnyProps> | PointFeature<AnyProps>
): feature is ClusterFeature<AnyProps> {
  return feature.properties.cluster === true;
}
