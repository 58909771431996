// @ts-nocheck
import React from 'react';
import type {RouteProps} from 'react-router-dom';
import {Redirect, Route} from 'react-router-dom';
import {CI_ROOT_PATH, MRV, SI} from '_environment';
import {useAppSelector} from '_hooks';
import {useWorkspace} from '_hooks/use-workspace';
import {selectIsAdmin, selectUser} from '../login/login-selectors';

type Props = RouteProps & {
  isAdminOnly?: boolean;
};

const PrivateRoute = ({isAdminOnly, children, component: Component, ...rest}: Props) => {
  const isAuth = useAppSelector(state => state.login.isAuth);
  const isAdmin = useAppSelector(selectIsAdmin);
  const user = useAppSelector(selectUser);
  const {isWorkspaceMrv, workspace} = useWorkspace();

  // if user logged-in but not admin - redirect to root page
  if (isAuth && isAdminOnly && !isAdmin) {
    return <Redirect to="/" />;
  }

  const hasAccess = isAdmin || user.workspaces?.[workspace]; // allow admins to access any workspace

  return (
    <Route
      {...rest}
      render={props => {
        switch (true) {
          case isAuth && !hasAccess:
            // If the user doesn't have access to requested workspace,
            // but has access to any other workspace -- send them to this workspace.
            if (user.workspaces?.mrv) return <Redirect to={`/${MRV}`} />;
            if (user.workspaces?.si) return <Redirect to={`/${SI}`} />;
            if (user.workspaces?.ci) return <Redirect to={`/${CI_ROOT_PATH}`} />;
            // If the user doesn't have any acces, send them to the root.
            return <Redirect to={'/'} />;
          case isAuth:
            return <Component {...props} />;
          case isWorkspaceMrv:
            if (window.location.pathname.includes('sign-up-confirmation'))
              return <Component {...props} />;
            return (
              <Redirect
                to={{
                  pathname: '/mrv/login',
                  search: `?redirect=${
                    encodeURIComponent(window.location.pathname) +
                    (props.location.search ? props.location.search.replace('?', '&') : '')
                  }`,
                }}
              />
            );
          default:
            return (
              <Redirect
                to={`/login?redirect=${
                  encodeURIComponent(window.location.pathname) +
                  (window.location.search ? window.location.search.replace('?', '&') : '')
                }`}
              />
            );
        }
      }}
    >
      {children}
    </Route>
  );
};

export default PrivateRoute;
