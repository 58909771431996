import {Flex, FluroDataTable as Table, FluroTableBody as Body, Text} from 'components';
import type {ComponentType} from 'react';
import React, {useContext} from 'react';
import {useAppSelector} from '_hooks';
import {useMonitoringUrlParams} from '../../hooks';
import {
  selectMonitoringReadOnly,
  selectStageById,
  selectSyncingOperationsStatus,
} from '../../module/selectors';
import {BulkEditButton} from '../edit-field-dialog/bulk-edit-button';
import {TableRowsSelectionContext} from '../table-rows-selection';
import {MRVPanelRow} from '../mrv-panel/mrv-panel-row';
import cn from 'classnames';
import {t} from 'i18n-utils';
import {MRVPhaseType} from 'containers/mrv/types';
import {getEntityId} from 'containers/mrv/value-utils';
import {useMRVValues} from 'containers/mrv/data/use-mrv-values';
import {MobEditButton} from '../edit-mob-dialog/mob-edit-button';

export const MRVPanel: ComponentType<{phaseType: MRVPhaseType}> = ({phaseType}) => {
  const {stageId, projectId} = useMonitoringUrlParams();
  const stage = useAppSelector(s => selectStageById(s, stageId));
  const [selectedRows, setSelectedRows] = useContext(TableRowsSelectionContext);
  const isSyncingOperations = useAppSelector(selectSyncingOperationsStatus).isSync;
  const {isReadOnly} = useAppSelector(selectMonitoringReadOnly);
  const {valueRows} = useMRVValues(phaseType, projectId, stage);

  const allSelected = valueRows.length === selectedRows.length;

  const selectedRowsMap = selectedRows.reduce<Record<string, boolean>>(
    (acc, selectedRow) => ({...acc, [`${selectedRow.entityId}-${selectedRow.rowId}`]: true}),
    {}
  );

  const handleSelectField = (entityId: number, rowId: number, selected: boolean) => {
    setSelectedRows(rows =>
      selected
        ? rows.concat({entityId, rowId})
        : rows.filter(item => `${item.entityId}-${item.rowId}` !== `${entityId}-${rowId}`)
    );
  };

  const handleSelectAll = (selected: boolean) => {
    if (isSyncingOperations || isReadOnly) return;
    setSelectedRows(
      selected ? valueRows.map(row => ({entityId: getEntityId(row), rowId: row.row_id})) : []
    );
  };

  return (
    <div className="monitoring-map-view-container">
      <Flex className={'margin-bottom-10'} alignItems={'center'} justifyContent={'space-between'}>
        <Text elementType={'div'} onClick={() => handleSelectAll(!allSelected)} link>
          {t({id: 'SelectLabel.Mrv.Select all', defaultMessage: 'Select all'})}
        </Text>
        <Flex alignItems="center" gap="1rem">
          {stage?.entity_type === 'mob' ? (
            <MobEditButton phaseType={phaseType}></MobEditButton>
          ) : null}
          <BulkEditButton phaseType={MRVPhaseType.Monitoring} />
        </Flex>
      </Flex>
      <Table
        className={cn({'mrv-table-small': true, 'syncing-operations': isSyncingOperations})}
        responsive={false}
      >
        <Body>
          {valueRows.length ? (
            valueRows.map(row => (
              <MRVPanelRow
                phaseType={phaseType}
                key={`${row.field_id}-${row.row_id}`}
                row={row}
                onSelect={handleSelectField}
                isSelected={!!selectedRowsMap[`${row.field_id}-${row.row_id}`]}
                readOnly={isSyncingOperations || isReadOnly}
              />
            ))
          ) : (
            <>No data</>
          )}
        </Body>
      </Table>
    </div>
  );
};
