import type {ReactElement, ReactNode, ComponentType} from 'react';
import React, {useEffect, useMemo, useState} from 'react';
import {toggleTooltip} from 'modules/global/actions';
import {FontIcon, Slider, TextField} from 'react-md';
import {
  updateRecommendationSettings,
  getNRxRecommendation,
  toggleNRxSettingsVisibility,
  updateRecommendationDate,
  toggleNRxResultsVisibility,
} from 'containers/map/actions/nitrogen-recommendation-actions';
import {
  Flex,
  FluroAutocomplete,
  FluroButton,
  FluroCalendarContainer,
  FluroDatePicker,
  Text,
} from 'components';
import type {NRxRecomendationSettings} from 'containers/map/features/nrx';
import {
  classifyYieldUnits,
  getCropPriceUnit,
  getLastAvailableRecommendationDate,
} from 'containers/map/features/nrx';
import Mixpanel from '_utils/mixpanel-utils';
import {t} from 'i18n-utils';
import {useAppDispatch, useAppSelector} from '_hooks';
import {selectNRecommendation, selectNrxSeason} from 'containers/map/features/nrx/nrx-selectors';

import {selectCurrentSeasonId, selectIsNitrogenZoningTab} from '../../../reducer/selectors';
import {selectNRxListsData} from '../../nrx/nrx-selectors';
import {selectMeasurement} from '../../../../login/login-selectors';
import {GLOBAL_FORMAT_DATE} from '_constants';
import moment from 'moment';
import {setFeature, toggleTableView} from '../../../actions';
import {ContainersSeparator} from 'components/reusable-conponents.styled';

const CustomSlider = ({
  id,
  label,
  value,
  onChange,
  leftLabel,
  rightLabel,
}: {
  id: string;
  label: ReactElement;
  value: number;
  leftLabel: string;
  rightLabel: string;
  onChange(val: number): void;
}) => {
  return (
    <div className={'nrx-slider-container'}>
      <Slider
        id={id}
        className={'slider'}
        label={label}
        defaultValue={2}
        max={3}
        min={1}
        step={1}
        value={value}
        onChange={onChange}
      />

      <div className={'slider-dots-container'}>
        <div className={'dot'} />
        <div className={'dot'} />
        <div className={'dot'} />
      </div>

      <div className={'slider-labels'}>
        <span>{leftLabel}</span>

        <span>{rightLabel}</span>
      </div>
    </div>
  );
};

type Props = {exportView?: boolean};

export const NRxRecommendationSettings: ComponentType<Props> = ({exportView}) => {
  const dispatch = useAppDispatch();
  const nRecommendation = useAppSelector(selectNRecommendation);

  const fertilizerTypes = useAppSelector(selectNRxListsData).fertilizerTypes;
  const {nrxRecommendationSettings, displaySettings} = nRecommendation;
  const measurement = useAppSelector(selectMeasurement);
  const nrxSeason = useAppSelector(s => selectNrxSeason(s, selectCurrentSeasonId(s)));
  const isNitrogenTab = useAppSelector(selectIsNitrogenZoningTab);
  const isSettingsVisible = displaySettings && isNitrogenTab;

  const lastAvailableRecommendationDate = getLastAvailableRecommendationDate(
    nrxSeason.recommendationDates
  );

  const recommendationDate = nRecommendation.nrxRecommendationSettings.recommendation_date
    ? moment(nRecommendation.nrxRecommendationSettings.recommendation_date, GLOBAL_FORMAT_DATE)
    : moment(lastAvailableRecommendationDate, GLOBAL_FORMAT_DATE);

  const [settingsValues, setSettingsValues] = useState<NRxRecomendationSettings>({
    ...nrxRecommendationSettings,
  });

  useEffect(() => {
    if (isSettingsVisible) {
      setSettingsValues({...nrxRecommendationSettings});
    }
  }, [nrxRecommendationSettings, isSettingsVisible]);

  useEffect(() => {
    if (settingsValues.max_yield_goal === undefined && nrxRecommendationSettings.max_yield_goal) {
      setSettingsValues({
        ...settingsValues, // set properties if they appeared in props
        max_yield_goal: nrxRecommendationSettings.max_yield_goal,
        min_yield_goal: nrxRecommendationSettings.min_yield_goal,
      });
    }
  }, [nrxRecommendationSettings.max_yield_goal]);

  const onChange = (value: any, prop: keyof NRxRecomendationSettings) => {
    const checkForNull = ['N_constrain', 'crop_price', 'N_price'];

    if (prop === 'product') {
      const product = fertilizerTypes.find(p => p.type === value);
      return setSettingsValues({
        ...settingsValues,
        ...product,
        product: value,
        n_percentage: Number(product?.nitrogenPercentage),
      });
    } else if (checkForNull.includes(prop)) {
      return setSettingsValues({...settingsValues, [prop]: value >= 0 ? value : 0});
    }

    setSettingsValues({...settingsValues, [prop]: value});
  };

  const goToFarmTableView = () => {
    dispatch(setFeature('farm'));
    dispatch(toggleTableView('farm'));
  };

  const revertValue = (value: number) => (value === 1 ? 3 : value === 3 ? 1 : value);

  const updateRecommendation = (wrongNPercentage: boolean) => {
    if (wrongNPercentage) {
      return alert('Please set a correct fertilizer N% value');
    }

    dispatch(updateRecommendationSettings(settingsValues, true));
    dispatch(getNRxRecommendation());
    dispatch(toggleNRxSettingsVisibility(false));
    dispatch(toggleNRxResultsVisibility(true));
    Mixpanel.updateNRxSettings();
  };

  const recommendationMaxDate = useMemo(() => {
    const lastSeasonDate = nrxSeason.nrxSeasonEndDate
      ? moment(nrxSeason.nrxSeasonEndDate, GLOBAL_FORMAT_DATE)
      : moment(nrxSeason.endDate, GLOBAL_FORMAT_DATE);

    const mNow = moment();
    return lastSeasonDate.isBefore(mNow) || nrxSeason.nrxSeasonEndDate ? lastSeasonDate : mNow;
  }, [nrxSeason]);

  const recommendationMinDate = useMemo(() => {
    return nrxSeason.nrxSeasonStartDate
      ? moment(nrxSeason.nrxSeasonStartDate)
      : moment(nrxSeason.startDate);
  }, [nrxSeason]);

  const highlightDates = [
    {
      'recommendation-ready': nrxSeason.recommendationDates.map((d: string) =>
        moment(d, GLOBAL_FORMAT_DATE).toDate()
      ),
    },
  ];

  const datePickerProps = ({className, children}: {className?: string; children: ReactNode}) => {
    return (
      <FluroCalendarContainer defaultCalendar={children} className={className}>
        <div className={'calendar-colors-info'}>
          <div className={'calendar-colors-info__item calendar-colors-info__item_gray'}>
            {t({id: 'Processing'})}
          </div>
          <div className={'calendar-colors-info__item calendar-colors-info__item_green'}>
            {t({id: 'Results ready'})}
          </div>
        </div>
      </FluroCalendarContainer>
    );
  };

  const measurementUnit = 't';
  const cropPriceUnit = measurement === 'ac' ? getCropPriceUnit(Number(nrxSeason.cropID)) : 't';
  const yieldUnits = classifyYieldUnits(measurement, Number(nrxSeason.cropID));
  const bushelUnit = 'bu/ac';
  const wrongNPercentage =
    settingsValues.product === 'Other (solid)' && !settingsValues.n_percentage;
  const showMinMaxGoal =
    settingsValues.min_yield_goal !== undefined && settingsValues.max_yield_goal !== undefined;

  return (
    <div className={'nrx-recommendation-settings'}>
      <ContainersSeparator />
      <Flex
        onClick={() => {
          dispatch(toggleNRxSettingsVisibility(!nRecommendation.displaySettings));
          dispatch(toggleNRxResultsVisibility(false));
        }}
        className={'cursor-pointer margin-bottom-5'}
        alignItems={'center'}
        justifyContent={'space-between'}
      >
        <Text className={'margin-bottom-0'} elementType="h2">
          {t({id: 'Recommendation settings'})}
        </Text>

        <FluroButton data-cy={'toggle-nrx-settings-btn'} icon blank>
          {nRecommendation.displaySettings ? 'keyboard_arrow_down' : 'keyboard_arrow_right'}
        </FluroButton>
      </Flex>
      {exportView || nRecommendation.displaySettings ? (
        <>
          <Text className={'margin-bottom-0'} elementType="h3">
            {t({id: 'Crop and products'})}
          </Text>
          <div className={'export-settings-container'}>
            {!exportView && (
              <div className="nrx-date-container">
                <FluroDatePicker
                  id="set-recommendation-date"
                  label={t({id: 'Recommendation date'})}
                  onChange={val =>
                    dispatch(updateRecommendationDate(val.format(GLOBAL_FORMAT_DATE)))
                  }
                  onChangeRaw={ev => ev.preventDefault()}
                  selected={recommendationDate}
                  hideFormat
                  highlightDates={highlightDates}
                  minDate={recommendationMinDate}
                  maxDate={recommendationMaxDate}
                  calendarContainer={datePickerProps}
                />

                {nRecommendation.method === 'apsim' && (
                  <span onClick={goToFarmTableView} className={'global-link'}>
                    {t({id: 'Edit fields parameters'})}
                  </span>
                )}
              </div>
            )}

            <div className={'inputs-container'}>
              <div className={'inputs-container__item fertilizer'}>
                <FluroAutocomplete
                  id="fertilizer"
                  label={t({id: 'Fertilizer'})}
                  menuItems={fertilizerTypes}
                  dataLabel={'type'}
                  dataValue={'type'}
                  onAutocomplete={(value: any) => onChange(value, 'product')}
                  value={settingsValues.product}
                  className={`select-product`}
                />

                <TextField
                  id="select-product-value"
                  label={t({id: 'N%'})}
                  className="select-product-value"
                  type="number"
                  value={settingsValues.n_percentage}
                  min={0}
                  max={100}
                  onChange={value =>
                    onChange(
                      value > 100 ? 100 : value < 0 ? 0 : parseInt(`${value}`),
                      'n_percentage'
                    )
                  }
                  disabled={settingsValues.product !== 'Other (solid)'}
                  errorText={t({id: 'enter N%'})}
                  error={wrongNPercentage}
                />
              </div>
              <div className={'inputs-container__item fertilizer-price'}>
                <TextField
                  id="select-urea-price"
                  label={t({id: 'Fertilizer price'})}
                  className="input-type-number"
                  type="number"
                  value={settingsValues.N_price}
                  min={0}
                  onChange={value => onChange(value, 'N_price')}
                />
                <span className={'units'}>$/{measurementUnit}</span>
              </div>
            </div>

            <div className={'inputs-container'}>
              <div className={'inputs-container__item crop-price'}>
                <TextField
                  id="select-grain-price"
                  label={t({id: 'Crop price'})}
                  className="input-type-number"
                  type="number"
                  value={settingsValues.crop_price}
                  min={0}
                  step={measurement === 'ha' ? 5 : 0.01}
                  onChange={value => onChange(value, 'crop_price')}
                />
                <span className={'units'}>$/{cropPriceUnit}</span>
              </div>

              <div className={'inputs-container__item fertilizer-amount'}>
                <TextField
                  id="total-amount-of-nitrogen"
                  label={t({id: 'Fert. available (opt.)'})}
                  type="number"
                  value={settingsValues.N_constrain}
                  step={0.1}
                  min={0}
                  onChange={value => onChange(value, 'N_constrain')}
                />
                <span className={'units'}>{measurementUnit}</span>
              </div>
            </div>

            <div className={'inputs-container'}>
              <div className={'inputs-container__item full-width'}>
                <TextField
                  id="Historical yield average"
                  label={t({id: 'Historical yield average (opt.)'})}
                  className="input-type-number bushels"
                  type="number"
                  value={settingsValues.historical_yield_avg}
                  min={0}
                  step={1}
                  onChange={value => onChange(value, 'historical_yield_avg')}
                />
                <span className={'units'}>{yieldUnits}</span>
              </div>
            </div>

            {showMinMaxGoal && (
              <div className={'inputs-container'}>
                <div className={'inputs-container__item'}>
                  <TextField
                    id="min-yield-goal"
                    label={t({id: 'Min. yield goal'})}
                    className="input-type-number bushels"
                    type="number"
                    value={settingsValues.min_yield_goal}
                    min={0}
                    step={1}
                    onChange={value => onChange(value, 'min_yield_goal')}
                  />
                  <span className={'units'}>{bushelUnit}</span>
                </div>

                <div className={'inputs-container__item'}>
                  <TextField
                    id="max-yield-goal"
                    label={t({id: 'Max. yield goal'})}
                    className="input-type-number bushels"
                    type="number"
                    value={settingsValues.max_yield_goal}
                    step={1}
                    min={0}
                    onChange={value => onChange(value, 'max_yield_goal')}
                  />
                  <span className={'units'}>{bushelUnit}</span>
                </div>
              </div>
            )}

            <div className={'outlook-slider-container'}>
              <Slider
                id="season-outlook-slider"
                className={'season-outlook-slider'}
                trackClassName={'season-outlook-track'}
                label={t({id: 'Season outlook'})}
                defaultValue={5}
                max={20}
                min={0}
                value={settingsValues.season_outlook}
                onChange={value => onChange(value, 'season_outlook')}
              />
              <span className={'slider-value-label min'}>{t({id: 'Bad'})}</span>
              <span className={'slider-value-label mid'}>{t({id: 'Average'})}</span>
              <span className={'slider-value-label max'}>{t({id: 'Good'})}</span>
            </div>

            <CustomSlider
              id="attitude-towards-risk-slider"
              label={
                <div className={'info-text-container'}>
                  {t({id: 'Attitude towards risk'})}
                  <FontIcon
                    data-tip={''}
                    data-for={'towards-risk'}
                    onMouseEnter={() =>
                      dispatch(
                        toggleTooltip({
                          id: 'towards-risk',
                          content: (
                            <>
                              {t({
                                id: 'Risk is measured using the return on investment (ROI) concept.',
                              })}
                              <ul className={'attitude-message'}>
                                <li>{t({id: 'Low risk: ROI is at least 3'})}</li>
                                <li>{t({id: 'Moderate risk: ROI is between 2 and 3'})}</li>
                                <li>{t({id: 'High risk: ROI is between 1 and 2'})}</li>
                              </ul>
                            </>
                          ),
                        })
                      )
                    }
                  >
                    help_outline
                  </FontIcon>
                </div>
              }
              value={revertValue(settingsValues.ROI_setting)}
              leftLabel={t({id: 'Low risk'})}
              rightLabel={t({id: 'High risk'})}
              onChange={value => onChange(revertValue(value), 'ROI_setting')} //revert values
            />
          </div>

          <div className={'disclaimer'}>
            {t({
              id: "Disclaimer: Economic risk levels are inferred from historical weather data and user-selected settings. Actual risk levels will be influenced by season's weather, crop price/quality, and other agronomic factors.",
            })}
          </div>

          <div className="md-text-center margin-top-10 margin-bottom-5">
            <FluroButton
              className={'update-recommendation-button'}
              raised
              primary
              onClick={() => updateRecommendation(wrongNPercentage)}
            >
              {t({id: 'Display recommendation'})}
            </FluroButton>
          </div>
        </>
      ) : null}
    </div>
  );
};
