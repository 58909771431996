import React, {useMemo, useState} from 'react';
import {FontIcon} from 'react-md';
import {convertUnit} from '_utils';
import styled, {useTheme} from 'styled-components';
import {
  Flex,
  FluroButton,
  FluroChip,
  FluroDataTable,
  FluroTableBody,
  FluroTableColumn,
  FluroTableRow,
  Text,
} from 'components';
import {Circle} from 'components/shapes/circle';
import {useAppDispatch, useAppSelector} from '_hooks';
import {useParsedMatchParams} from '_hooks/use-parsed-match-params';
import {updateSIProgram} from 'containers/si/module/thunks';
import {selectSIProgramById} from 'containers/si/module/selectors';
import {selectIsAdmin, selectMeasurement} from '../../../login/login-selectors';
import {TabsPopup} from './tabs-popup';
import {KPIsPopup} from './kpis-popup';
import {SimpleSelector} from './simple-selector';
import {showNotification} from 'components/notification/notification';
import {SIInputPopup} from 'containers/si/components/si-input-popup';
import {PlaceholderLoader} from 'components/placeholder-loader/placeholder-loader';
import type {ProgramResponse, TabAccess} from 'containers/si/api/apiTypes';
import {outcomeKPILabel, practiceKPILabel, tabLabel} from 'containers/si/constants';
import {SurveyIcon} from 'containers/mrv/icons';

const StyledFontIcon = styled(FontIcon)`
  color: ${({theme}) => theme.colorPalette.fs_main.green_600} !important;
`;

export const SIProgramParameters = () => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const {programId} = useParsedMatchParams();
  const program = useAppSelector(s => selectSIProgramById(s, programId));

  const [kpiPopUp, setKpiPopUp] = useState(false);
  const [tabsPopUp, setTabsPopUp] = useState(false);

  const [configurationPopUp, setConfigurationPopUp] = useState<
    'Area' | 'Program description' | null
  >(null);

  const measurement = useAppSelector(selectMeasurement);
  const formattedArea = convertUnit(measurement, 'ac', program?.acreage_limit_ha).toFixed();
  const currentYear = new Date().getFullYear();

  const isAdmin = useAppSelector(selectIsAdmin);
  const programsFetched = true;

  const configurationPopUpConfig = {
    Area: {
      key: 'acreage_limit_ha',
      type: 'number',
      defaultValue: formattedArea,
    },
  } as {
    [field: string]: {
      key: 'acreage_limit_ha';
      type: 'number';
      defaultValue?: number | null;
    };
  };

  const getYearsList = useMemo(() => {
    const tenYearsAgo = currentYear - 10;
    const fiveYearsLater = currentYear + 5;
    const years = [];

    for (let i = fiveYearsLater; i >= tenYearsAgo; i--) {
      years.push({
        label: i.toString(),
        value: i,
      });
    }

    return years;
  }, [currentYear]);

  const callbacks = {
    kpis: {
      onAdd: async (value: string) => {
        const key = Object.keys(practiceKPILabel).includes(value)
          ? 'practice_kpis'
          : 'outcome_kpis';
        const newKPIs = [...(program?.[key] ?? []), value];
        const updatedProgram: Partial<ProgramResponse> = {[key]: newKPIs};
        dispatch(updateSIProgram({programId, program: updatedProgram}));
      },
      onRemove: async (value: string) => {
        const key = Object.keys(practiceKPILabel).includes(value)
          ? 'practice_kpis'
          : 'outcome_kpis';
        const newKPIs = [...(program?.[key] ?? [])].filter(x => x !== value);
        const updatedProgram: Partial<ProgramResponse> = {[key]: newKPIs};
        dispatch(updateSIProgram({programId, program: updatedProgram}));
      },
    },
    tabs: {
      onAdd: async (value: string) => {
        const newTabs = [...(program?.tab_accesses ?? []), value as TabAccess];
        const updatedProgram: Partial<ProgramResponse> = {tab_accesses: newTabs};
        dispatch(updateSIProgram({programId, program: updatedProgram}));
      },
      onRemove: async (value: string) => {
        const newTabs: TabAccess[] = (program?.tab_accesses ?? []).filter(x => x !== value);
        const updatedProgram: Partial<ProgramResponse> = {tab_accesses: newTabs};
        dispatch(updateSIProgram({programId, program: updatedProgram}));
      },
      onSave: async (tabs: TabAccess[]) => {
        const updatedProgram: Partial<ProgramResponse> = {tab_accesses: tabs};
        dispatch(updateSIProgram({programId, program: updatedProgram}));
      },
    },
  };

  const handleUpdateProgramConfiguration = ({
    key,
    value,
  }: {
    key: 'acreage_limit_ha' | 'name' | 'crop_year_start' | 'crop_year_end';
    value: string | number;
  }) => {
    if (key === 'acreage_limit_ha') {
      value = measurement === 'ha' ? value : convertUnit(measurement, 'ha', value as number);
    }
    if (key === 'crop_year_start') {
      if (program?.crop_year_end && Number(value) >= program?.crop_year_end) {
        showNotification({
          title: 'Error',
          message: `The start year must be before the end year.`,
          type: 'error',
        });
        return;
      }
    }
    if (key === 'crop_year_end') {
      if (program?.crop_year_start && Number(value) <= program?.crop_year_start) {
        showNotification({
          title: 'Error',
          message: `The end year must be after the start year.`,
          type: 'error',
        });
        return;
      }
    }
    const updatedProgram: Partial<ProgramResponse> = {[key]: value};
    dispatch(updateSIProgram({programId, program: updatedProgram}));
    setConfigurationPopUp(null);
  };

  return (
    <>
      <FluroDataTable className="program-config-table">
        <FluroTableBody>
          <FluroTableRow hoverBg={false}>
            <FluroTableColumn>
              <Flex nowrap alignItems="center">
                <Circle>
                  <StyledFontIcon>straighten</StyledFontIcon>
                </Circle>
                <Text nowrap inline className="ml-05">
                  {/* Acreage */}
                  {measurement === 'ha' ? 'Hectares' : 'Acreage'}
                </Text>
              </Flex>
            </FluroTableColumn>
            <FluroTableColumn>
              {formattedArea || 0} {measurement}
            </FluroTableColumn>
            <FluroTableColumn>
              <Flex justifyContent="flex-end">
                <FluroButton
                  flat
                  blank
                  disabled={!isAdmin}
                  onClick={() => setConfigurationPopUp('Area')}
                >
                  edit
                </FluroButton>
              </Flex>
            </FluroTableColumn>
          </FluroTableRow>

          <FluroTableRow hoverBg={false}>
            <FluroTableColumn>
              <Flex nowrap alignItems="center">
                <Circle>
                  <StyledFontIcon>date_range</StyledFontIcon>
                </Circle>
                <Text nowrap inline className="ml-05">
                  Years
                </Text>
              </Flex>
            </FluroTableColumn>
            <FluroTableColumn>
              <div className="pt-2 pb-2">
                <Flex nowrap alignItems="center">
                  <div style={{width: '100px'}}>
                    <SimpleSelector
                      options={getYearsList}
                      value={program?.crop_year_start || currentYear}
                      onChange={value =>
                        handleUpdateProgramConfiguration({
                          key: 'crop_year_start',
                          value,
                        })
                      }
                      disabled={!isAdmin}
                    />
                  </div>
                  <span className="pl-1 pr-1">-</span>
                  <div style={{width: '100px'}}>
                    <SimpleSelector
                      options={getYearsList}
                      value={program?.crop_year_end || currentYear}
                      onChange={value =>
                        handleUpdateProgramConfiguration({
                          key: 'crop_year_end',
                          value,
                        })
                      }
                      disabled={!isAdmin}
                    />
                  </div>
                </Flex>
              </div>
            </FluroTableColumn>
          </FluroTableRow>

          <FluroTableRow hoverBg={false}>
            <FluroTableColumn>
              <Flex nowrap alignItems="center">
                <Circle>
                  <SurveyIcon fill={theme.colorPalette.fs_main.green_600} />
                </Circle>
                <Text nowrap inline className="ml-05">
                  Data Products (KPIs)
                </Text>
              </Flex>
            </FluroTableColumn>
            <FluroTableColumn>
              <Flex gap="4px">
                {programsFetched ? (
                  <>
                    {program?.practice_kpis?.map(asset => (
                      <FluroChip
                        tone="light"
                        size="small"
                        label={practiceKPILabel[asset]}
                        key={asset}
                      />
                    ))}
                    {program?.outcome_kpis?.map(asset => (
                      <FluroChip
                        tone="light"
                        size="small"
                        label={outcomeKPILabel[asset]}
                        key={asset}
                      />
                    ))}
                  </>
                ) : (
                  <PlaceholderLoader height={24} />
                )}
              </Flex>
            </FluroTableColumn>
            <FluroTableColumn>
              <Flex justifyContent="flex-end">
                <FluroButton flat blank disabled={!isAdmin} onClick={() => setKpiPopUp(true)}>
                  edit
                </FluroButton>
              </Flex>
            </FluroTableColumn>
          </FluroTableRow>

          <FluroTableRow hoverBg={false}>
            <FluroTableColumn>
              <Flex nowrap alignItems="center">
                <Circle>
                  <StyledFontIcon>check_circle</StyledFontIcon>
                </Circle>
                <Text nowrap inline className="ml-05">
                  Tab Access
                </Text>
              </Flex>
            </FluroTableColumn>
            <FluroTableColumn>
              <Flex gap="4px">
                {programsFetched ? (
                  <>
                    {program?.tab_accesses?.map(asset => (
                      <FluroChip tone="light" size="small" label={tabLabel[asset]} key={asset} />
                    ))}
                  </>
                ) : (
                  <PlaceholderLoader height={24} />
                )}
              </Flex>
            </FluroTableColumn>
            <FluroTableColumn>
              <Flex justifyContent="flex-end">
                <FluroButton flat blank disabled={!isAdmin} onClick={() => setTabsPopUp(true)}>
                  edit
                </FluroButton>
              </Flex>
            </FluroTableColumn>
          </FluroTableRow>
        </FluroTableBody>
      </FluroDataTable>
      {configurationPopUp && (
        <SIInputPopup
          title={measurement === 'ha' ? 'Set Program Hectares' : ' Set Program Acreage'}
          type={'number'}
          value={formattedArea}
          onSave={value => {
            handleUpdateProgramConfiguration({
              key: configurationPopUpConfig[configurationPopUp].key,
              value,
            });
          }}
          onHide={() => setConfigurationPopUp(null)}
        />
      )}
      {kpiPopUp && (
        <KPIsPopup
          title={'Data Products'}
          outcome_kpis={program?.outcome_kpis || []}
          practice_kpis={program?.practice_kpis || []}
          onAddValue={attr => callbacks['kpis'].onAdd(attr)}
          onRemoveValue={attr => callbacks['kpis'].onRemove(attr)}
          onHide={() => setKpiPopUp(false)}
        />
      )}
      {tabsPopUp && (
        <TabsPopup
          title={'Product Features'}
          tab_accesses={program?.tab_accesses || []}
          onSave={attr => callbacks['tabs'].onSave(attr)}
          onHide={() => setTabsPopUp(false)}
        />
      )}
    </>
  );
};
