/**
 * Create a new object updating a target with the source properties.
 */
export const mergeEntities = <T extends {[index: string]: any}>(target: T, source: T): T => {
  const keys: (keyof T)[] = Object.keys(source || {});
  const merged = keys.reduce<T>(
    (result, key) => {
      result[key] = {...result[key], ...source[key]};
      return result;
    },
    {...target}
  );
  return merged;
};
