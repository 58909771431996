import {AutoSizer, CellMeasurerCache, List} from 'react-virtualized';
import type {ComponentType} from 'react';
import React, {useCallback, useMemo, useRef, useState} from 'react';
import {useAppSelector} from '_hooks';
import {selectFarmsList} from 'modules/farms/selectors';
import {selectCurrentFarmId} from '../../../reducer/selectors';
import {Controller, useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import {getFarmById} from '_utils';
import {FluroInput} from 'components/fluro-form-components';
import {Flex, FluroButton, FluroDialog, Text} from 'components';
import {useTranslation} from 'i18n-utils';
import MultiKeysPressed from 'components/key-handler';
import * as Yup from 'yup';
import {FontIcon, SelectionControl} from 'react-md';
import {
  FieldsCountLabel,
  ListWrapper,
  AddNewFarmInputComponent,
  AddNewFarmBtn,
} from './field-properties-parser/field-properties-parser.styled';
import {IndeterminateCheckboxIcon, UncheckedCheckboxIcon} from 'components/fluro-icons';
import cn from 'classnames';
import {FluroSelectLite} from 'components/fluro-select-lite/fluro-select-lite';
import type {FieldBoundary} from './types';
import {FieldSystemProp} from './types';
import {farmStringSchema} from 'containers/farm/edit';
import {NewFieldPreviewRow} from './base/new-field-preview-row';
import type {FieldErrorsViews} from './base/use-field-errors';

export const AssignFarmsDialog: ComponentType<{
  visible: boolean;
  fieldsToProcess: FieldBoundary[];
  getFieldErrors: (fieldId: string | number) => FieldErrorsViews;
  onBulkSelectFields: (value: boolean, fieldIds?: string[]) => void;
  onSelectField: (fieldId: string, value: boolean) => void;
  onChangeFieldName: (id: string, value: number | string) => void;
  selectedFieldIds: string[]; // | number[];
  setFarmForFields: (farmId: number, forceFieldIds?: string[]) => void;
  setNewFarmForSelectedFields: (farmName: string, fieldIds: string[]) => void;
  closeDialog: () => void;
  createNewFarm: (farmName: string) => Promise<number>;
}> = ({
  visible,
  onBulkSelectFields,
  fieldsToProcess,
  getFieldErrors,
  selectedFieldIds,
  setFarmForFields,
  setNewFarmForSelectedFields,
  closeDialog,
  onSelectField,
  onChangeFieldName,
  createNewFarm,
}) => {
  const t = useTranslation();
  const rvRef = useRef<List | null>(null);
  const farms = useAppSelector(selectFarmsList).filter(farm => farm.id !== DEMO_FARM_ID);
  const currentFarmId = useAppSelector(selectCurrentFarmId);

  const [addNewFarm, toggleAddNewFarm] = useState(false);
  const [preparedFieldFarms, setPreparedFieldFarms] = useState<Record<string, number | string>>({});

  const {
    formState: {errors},
    control,
    reset,
    watch,
  } = useForm<{
    farmName: string;
  }>({
    mode: 'onChange',
    resolver: yupResolver(farmNameSchema),
    defaultValues: {farmName: ''},
  });

  const values = watch();

  const menuItems = useMemo(
    () => farms.filter(f => !f.readOnly).map(f => ({label: f.name, value: f.id})),
    [farms]
  );

  const onSelectFarm = useCallback(
    async (farmId: number, fieldId?: string) => {
      const newlyPreparedFieldFarms = {...preparedFieldFarms};
      if (fieldId) {
        newlyPreparedFieldFarms[fieldId] = farmId;
        onSelectField(fieldId, false); // unselect field we assigned a arm to
      } else {
        selectedFieldIds.forEach(id => (newlyPreparedFieldFarms[id] = farmId));
        onBulkSelectFields(false, selectedFieldIds); // unselect fields we assigned a farm to
      }
      setPreparedFieldFarms(newlyPreparedFieldFarms);
    },
    [onBulkSelectFields, onSelectField, preparedFieldFarms, selectedFieldIds]
  );

  const onSubmitNewFarmName = useCallback(
    async (fieldId?: string) => {
      const trimmedFarmName = values.farmName.trim();
      if (!(await farmNameSchema.isValid({farmName: trimmedFarmName}))) {
        // simulate disabled state
        return;
      }

      const existingFarmWithSameName = menuItems.find(
        f => f.label.toLowerCase() === trimmedFarmName.toLowerCase()
      );
      let farmId = existingFarmWithSameName?.value;
      if (!farmId) {
        farmId = await createNewFarm(trimmedFarmName);
      }

      await onSelectFarm(farmId, fieldId);
      toggleAddNewFarm(false);
      reset();
    },
    [createNewFarm, menuItems, onSelectFarm, reset, values.farmName]
  );

  const onFarmsDropdownClose = () => {
    toggleAddNewFarm(false);
    reset();
  };

  const onHide = () => {
    closeDialog();
    // clear the cache so that all the row heights get recalculated
    rowHeightCache.clearAll();
    setPreparedFieldFarms({}); // reset selection
    onBulkSelectFields(
      // select all fields back
      true,
      fieldsToProcess.map(f => f.properties[FieldSystemProp.Id])
    );
  };

  const fixedToTopItems = useCallback(
    (fieldId?: string) => {
      const currentFarm = getFarmById(farms, currentFarmId);
      const items: {
        value: any;
        label: React.ReactElement | string;
        customSelectFunction?: () => void;
      }[] = [];

      if (addNewFarm) {
        items.push({
          customSelectFunction: () => null, // just neutralize the click
          value: 'new-farm',
          label: (
            <AddNewFarmInputComponent>
              <Controller
                name="farmName"
                control={control}
                render={({field: {value, onChange}}) => (
                  <FluroInput
                    topMargin={false}
                    onChange={v => onChange(v)}
                    value={value}
                    errorText={errors?.farmName?.message}
                    error={!!errors.farmName}
                  />
                )}
              />
              <FluroButton
                className={'add-btn'}
                raised
                primary
                onClick={() => {
                  onSubmitNewFarmName(fieldId);
                }}
              >
                {t({id: 'Add'})}
              </FluroButton>
              <MultiKeysPressed callback={() => onSubmitNewFarmName(fieldId)} keys={['Enter']} />
            </AddNewFarmInputComponent>
          ),
        });
      } else {
        items.push({
          customSelectFunction: () => toggleAddNewFarm(true),
          value: 'new-farm',
          label: (
            <AddNewFarmBtn>
              <FontIcon>add</FontIcon>
              {t({id: 'Add farm'})}
            </AddNewFarmBtn>
          ),
        });
      }

      if (currentFarm && menuItems.length > 1) {
        items.push({
          value: currentFarm.id,
          label: t({id: 'Upload to current farm: {farmName}'}, {farmName: currentFarm.name}),
        });
      }

      return items;
    },
    [
      addNewFarm,
      control,
      currentFarmId,
      errors.farmName,
      farms,
      menuItems.length,
      onSubmitNewFarmName,
    ]
  );

  const confirmFarmsSelection = () => {
    const collectedFieldsPerFarms: Record<number | string, string[]> = {};
    fieldsToProcess.forEach(f => {
      const fieldId = f.properties[FieldSystemProp.Id];
      const farmId = preparedFieldFarms[fieldId];
      if (!farmId) return;

      if (!collectedFieldsPerFarms[farmId]) {
        collectedFieldsPerFarms[farmId] = [];
      }

      collectedFieldsPerFarms[farmId].push(fieldId);
    });

    Object.keys(collectedFieldsPerFarms).forEach(farmId => {
      // set farms for fields
      if (typeof farmId === 'string') {
        setNewFarmForSelectedFields(farmId, collectedFieldsPerFarms[farmId]);
      }
      setFarmForFields(Number(farmId), collectedFieldsPerFarms[Number(farmId)]);
    });

    onHide();
  };

  return (
    <FluroDialog
      title={t({id: 'Assign fields to farms'})}
      onHide={onHide}
      portal
      visible={visible}
      id={'assign-farms-to-fields'}
      width={600}
    >
      <Text elementType={'span'} variant={'medium'}>
        {t({id: 'Select fields and assign them to a farm. You can also rename your fields.'})}
      </Text>

      {fieldsToProcess.length > 1 && (
        <Flex className={'margin-top-10'} alignItems={'center'} justifyContent={'space-between'}>
          <SelectionControl
            label={
              <FieldsCountLabel>
                {t({id: '{count} fields selected'}, {count: selectedFieldIds.length})}
              </FieldsCountLabel>
            }
            id="parser-select-all-fields"
            name="parser-select-all-fields"
            type="checkbox"
            checked={selectedFieldIds.length === fieldsToProcess.length}
            uncheckedCheckboxIcon={
              selectedFieldIds.length !== 0 ? (
                <IndeterminateCheckboxIcon />
              ) : (
                <UncheckedCheckboxIcon />
              )
            }
            // The type IS a boolean, but this version of ReactMD isn't typed correctly.
            onChange={value => onBulkSelectFields(value as boolean)}
          />

          {selectedFieldIds.length ? (
            <FluroSelectLite
              items={menuItems}
              isSearchable
              fixedToTopItems={fixedToTopItems()}
              selectedValue={null}
              onSelect={onSelectFarm}
              onClose={() => {
                onFarmsDropdownClose();
                // clear the cache so that all the row heights get recalculated
                rowHeightCache.clearAll();
              }}
              Button={({onClick}) => (
                <FluroButton onClick={onClick} blank grayBorder raised>
                  {t({id: 'BtnLabel.AssignToFarm'})}
                </FluroButton>
              )}
            />
          ) : null}
        </Flex>
      )}

      <ListWrapper className={'field-items-container margin-top-10'} minHeight={260}>
        <AutoSizer>
          {({width, height}) => {
            return (
              <List
                ref={rvRef}
                createFolder
                width={width}
                height={height}
                rowHeight={rowHeightCache.rowHeight}
                deferredMeasurementCache={rowHeightCache}
                rowRenderer={props => {
                  const field = fieldsToProcess[props.index];
                  const fieldId = field.properties?.[FieldSystemProp.Id];
                  const farmId = field.properties?.[FieldSystemProp.FarmId];
                  const fieldPreparedFarm = preparedFieldFarms[fieldId] || farmId;

                  return (
                    <NewFieldPreviewRow
                      field={field}
                      errors={getFieldErrors(fieldId)}
                      rowHeightCache={rowHeightCache}
                      onSelectField={onSelectField}
                      onChangeFieldName={onChangeFieldName}
                      farmsSelector={
                        <FluroSelectLite
                          placement="bottom"
                          placeholder={
                            fieldPreparedFarm ? String(fieldPreparedFarm) : t({id: 'Select farm'})
                          }
                          items={menuItems}
                          isSearchable
                          fixedToTopItems={fixedToTopItems(fieldId)}
                          selectedValue={fieldPreparedFarm}
                          onSelect={async (id: number) => {
                            await onSelectFarm(id, fieldId);
                            // when the farm name wraps to the next line, we need to recalculate the height
                            rowHeightCache.clear(props.index, props.columnIndex);
                          }}
                          className={cn('field-row-farm-selector', {
                            unselected: !fieldPreparedFarm,
                          })}
                          onClose={onFarmsDropdownClose}
                        />
                      }
                      farm={fieldPreparedFarm}
                      {...props}
                    />
                  );
                }}
                rowCount={fieldsToProcess.length}
                overscanRowCount={10}
              />
            );
          }}
        </AutoSizer>
      </ListWrapper>

      <Flex className={'margin-top-15'} justifyContent={'flex-end'} gap={'10px'}>
        <FluroButton raised blank onClick={onHide}>
          {t({id: 'BtnLabel.Cancel', defaultMessage: 'Cancel'})}
        </FluroButton>
        <FluroButton primary raised onClick={confirmFarmsSelection}>
          {t({id: 'BtnLabel.Done', defaultMessage: 'Done'})}
        </FluroButton>
      </Flex>
    </FluroDialog>
  );
};

const DEMO_FARM_ID = 136;

const rowHeightCache = new CellMeasurerCache({
  fixedWidth: true,
  defaultHeight: 55,
});

export const farmNameSchema = Yup.object().shape({
  farmName: farmStringSchema,
});
