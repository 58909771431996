import {Flex, FluroButton, FluroDialog, Text} from 'components';
import React, {useState} from 'react';
import {FluroInput} from 'components/fluro-form-components';

export const AddSupplyShedPopup = ({
  name,
  onSave,
  onHide,
  portal = true,
}: {
  name: string;
  description?: string;
  onSave: (name: string) => void;
  onHide: () => void;
  portal?: boolean;
}) => {
  const [newName, setNewName] = useState(name || '');
  // const [newDescription, setNewDescription] = useState(description || '');

  return (
    <FluroDialog
      id={'add-supply-shed-popup'}
      title={'Add supply shed'}
      onHide={onHide}
      visible={true}
      // width={400}
      width={520}
      portal={portal}
    >
      <Flex direction="column">
        <Text variant="medium">
          A supply shed is a geographic area whose boundary is defined by a collection of
          sub-sections.
        </Text>
        <Text secondary className="mt-1">
          Name
        </Text>
        <FluroInput
          type="text"
          id="supply-shed-name"
          name="supply-shed-name"
          value={newName}
          className="mt-0"
          onChange={p => setNewName(String(p))}
        />
        {/* <Text secondary className="mt-1">
          Description (optional)
        </Text>
        <FluroInput
          type="text"
          id="supply-shed-description"
          name="supply-shed-description"
          value={newDescription}
          className="mt-0"
          onChange={p => setNewDescription(String(p))}
        /> */}
      </Flex>
      <Flex justifyContent="flex-end" className="mt-2" gap="10px">
        <FluroButton raised blank onClick={onHide}>
          Cancel
        </FluroButton>
        <FluroButton raised primary onClick={() => onSave(newName)}>
          Save
        </FluroButton>
      </Flex>
    </FluroDialog>
  );
};
