import {useAppSelector} from '_hooks';
import {applyPathParams} from '_utils/pure-utils';
import {isNil} from '_utils/typeGuards';
import PrivateRoute from 'containers/private-route';
import {SIConfigure} from 'containers/si/feature-groups/configure';
import {SIMonitor} from 'containers/si/feature-groups/monitor';
import {SIPlan} from 'containers/si/feature-groups/plan';
import {useProgramId} from 'containers/si/hooks/useProgramId';
import {setCurrentProgram} from 'containers/si/module/reducer';
import {selectSIPrograms} from 'containers/si/module/selectors';
import {SI_CONFIGURE, SI_MONITOR, SI_PLAN, SI_HOME} from 'containers/si/routes';
import {isEmpty} from 'lodash';
import React from 'react';
import {CircularProgress} from 'react-md';
import {Redirect, Route, Switch} from 'react-router-dom';

export const FeatureGroupsEntry = () => {
  const {paramsProgramId} = useProgramId();
  const programs = useAppSelector(selectSIPrograms);

  React.useEffect(() => {
    // unset programId when navigating away from program detail
    return () => {
      setCurrentProgram(null);
    };
  }, []);

  if (isNil(paramsProgramId)) {
    return <Redirect to={SI_HOME} />;
  }

  return isEmpty(programs) ? (
    <CircularProgress id={'Loading Programs...'} />
  ) : (
    <Switch>
      <PrivateRoute
        path={SI_CONFIGURE}
        component={() => <SIConfigure programId={paramsProgramId} />}
      />
      <PrivateRoute path={SI_MONITOR} component={() => <SIMonitor programId={paramsProgramId} />} />
      <PrivateRoute path={SI_PLAN} component={() => <SIPlan programId={paramsProgramId} />} />
      <Route
        component={() => (
          <Redirect
            to={
              isNil(paramsProgramId)
                ? SI_HOME
                : applyPathParams(SI_MONITOR, {programId: paramsProgramId})
            }
          />
        )}
      />
    </Switch>
  );
};
