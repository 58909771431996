import {convertUnit} from '_utils';
import {toFixedFloatUnsafe} from '_utils/number-formatters';
import {t} from 'i18n-utils';
import type {NRxSeason} from './nrx-types';
import type {Measurement} from '../../../login/types';

export const ZONES_COLORS = [
  'rgb(246, 230, 32)',
  'rgb(96, 198, 104)',
  'rgb(31, 160, 136)',
  'rgb(39, 126, 142)',
  'rgb(55, 91, 141)',
  'rgb(70, 50, 126)',
  'rgb(68, 1, 84)',
];

export enum NRxCropTypes {
  Maize = 1,
  Sorghum = 2,
  Cotton = 3,
  Potato = 4,
  Wheat = 5,
  Other = 13,
  Barley = 14,
}

export const defaultNrxSeason: NRxSeason = {
  cropSubtype: '',
  cropType: '',
  cropVarietyID: 0,
  endDate: '',
  haySilage: 0,
  kmlID: 0,
  nrxSeasonID: 0,
  prevCropTypeID: 0,
  prevHarvestDate: '',
  recommendationDates: [],
  rowSpacing: 0,
  seasonID: 0,
  soilTypeID: 0,
  sowingDensity: 0,
  sowingDepth: 0,
  startDate: '',
  cropID: 0,
  irrigated: false,
  fertilizerApplications: [],
};

export const defaultSowingParams = (
  cropID: number,
  measurement: Measurement
): Partial<NRxSeason> => {
  const types: {[id: number]: Partial<NRxSeason>} = {
    [NRxCropTypes.Maize]: {
      sowingDepth: 30,
      rowSpacing: 900,
      sowingDensity: 4,
      irrigated: false,
      NASSPlantingParams: false,
    },
    [NRxCropTypes.Sorghum]: {
      sowingDepth: 35,
      rowSpacing: 1000,
      sowingDensity: 5,
      irrigated: false,
      NASSPlantingParams: false,
    },
    [NRxCropTypes.Cotton]: {
      sowingDepth: 40,
      rowSpacing: 950,
      sowingDensity: 9,
      irrigated: false,
      NASSPlantingParams: false,
    },
    [NRxCropTypes.Potato]: {
      sowingDepth: 100,
      rowSpacing: 850,
      sowingDensity: 50,
      irrigated: true,
      NASSPlantingParams: false,
    },
    [NRxCropTypes.Wheat]: {
      sowingDepth: 45,
      rowSpacing: 250,
      sowingDensity: 100,
      irrigated: false,
      NASSPlantingParams: false,
    },
  };
  if (!types[cropID]) return {};
  if (measurement === 'ha') return types[cropID];

  return {
    sowingDepth: toFixedFloatUnsafe(convertUnit('ac', 'mm', types[cropID].sowingDepth), 0),
    sowingDensity: toFixedFloatUnsafe(convertUnit('ac', 'm2', types[cropID].sowingDensity), 0),
    rowSpacing: toFixedFloatUnsafe(convertUnit('ac', 'mm', types[cropID].rowSpacing), 0),
    irrigated: cropID === NRxCropTypes.Potato,
  };
};

export const defaultNaasMaizePlantingSettings: Partial<NRxSeason> = {
  rowSpacing: 762,
  sowingDensity: 7,
};

export const RISK_LEVELS = {
  1: () => t({id: 'nrx.High'}),
  2: () => t({id: 'nrx.Moderate'}),
  3: () => t({id: 'nrx.Low'}),
};

export const riskLevelToLabel = (riskLevel: any) => {
  //@ts-expect-error error leftover from convertion to strict mode, please fix
  return RISK_LEVELS[riskLevel]?.() || '';
};

export const measurementUnits: any = () => ({
  ha: [
    {label: t({id: 'kg / ha'}), value: 'lb'},
    {label: t({id: 'L / ha'}), value: 'gal'},
    {label: t({id: 'seeds / ha'}), value: 'seeds'},
  ],
  ac: [
    {label: t({id: 'lb / ac'}), value: 'lb'},
    {label: t({id: 'gal / ac'}), value: 'gal'},
    {label: t({id: 'seeds / ac'}), value: 'seeds'},
  ],
});

export const ObjectiveLabels = {
  balanced: 'Balanced',
  max_roi: 'Max ROI',
  max_yield: 'Max yield',
};

export const NrxCropTypeLabels: {[key: number]: string} = {
  // data is taken form api/v1/app/nrx/crop/
  [NRxCropTypes.Maize]: 'Maize', // Corn
  [NRxCropTypes.Sorghum]: 'Sorghum',
  [NRxCropTypes.Cotton]: 'Cotton',
  [NRxCropTypes.Potato]: 'Potato',
  [NRxCropTypes.Wheat]: 'Wheat',
  [NRxCropTypes.Other]: 'Other',
  [NRxCropTypes.Barley]: 'Barley',
};

export const NRxAutomaticSoilTypeId = 3404;

export const NRxAutomaticSoilTypeLabel = t({id: 'Automatic soil selection'});
export const NRxAutomaticSoilDepthLabel = t({id: 'Automatically calculated'});
