import {
  selectAnalyticCategories,
  selectAnalyticChartData,
  selectCurrentDateAsChartProp,
} from 'containers/map/features/analytics/analytics-selectors';
import {
  selectCurrentFarm,
  selectCurrentSeason,
  selectCurrentField,
  selectPreparedTemperatureData,
  selectCurrentPointsGroups,
  selectCurrentDates,
} from 'containers/map/reducer/selectors';
import type {TrendsIndex} from 'containers/map/types';
import type {GrowthStageLabel} from 'containers/temperature-chart/temperature-chart';
import moment from 'moment';
import type {ComponentType} from 'react';
import React, {useCallback, useMemo, useEffect, useState, useRef} from 'react';
import {Line} from 'react-chartjs-2';
import type {ChartDataSets} from 'chart.js';
import Chart from 'chart.js';

import {GLOBAL_FORMAT_DATE} from '_constants';
import {useAppDispatch, useAppSelector} from '_hooks';
import {formatDate} from '_utils';
import {reportError} from 'containers/error-boundary';
import {setDate, setSensor} from 'containers/map/actions';
import {showNotification} from 'components/notification/notification';
import {t} from 'i18n-utils';

const leafImg = new Image();
leafImg.src = '/assets/leaf.svg';

export const AnalyticChart: ComponentType<{
  index: TrendsIndex;
  exportChartsRef: any; // i don't know how to type this)
  isPopUpTrend?: boolean;
  exportStatus?: boolean;
  openPop: (selectedIndex: TrendsIndex) => void;
}> = ({index, exportChartsRef, isPopUpTrend = false, openPop, exportStatus}) => {
  const dispatch = useAppDispatch();
  const chartData = useAppSelector(s => selectAnalyticChartData(s, index));
  const categories = useAppSelector(selectAnalyticCategories);
  const currentDateAsChartProp = useAppSelector(selectCurrentDateAsChartProp);
  const farm = useAppSelector(selectCurrentFarm);
  const field = useAppSelector(selectCurrentField);
  const currentSeason = useAppSelector(selectCurrentSeason);
  const currentDates = useAppSelector(selectCurrentDates);
  const preparedTemperatureData = useAppSelector(selectPreparedTemperatureData);
  const currentPointsGroups = useAppSelector(selectCurrentPointsGroups);

  const popUpOpenRef = useRef(false);
  const [growthStageLabels, setGrowthStageLabels] = useState<GrowthStageLabel[]>([]);

  const hasPreparedTemperatureData = !!Object.keys(preparedTemperatureData).length;

  useEffect(() => {
    return unregisterDrawFunctions;
  }, []);

  useEffect(() => {
    unregisterDrawFunctions();
    registerDrawFunctions();
  }, [preparedTemperatureData, growthStageLabels]);

  const unregisterDrawFunctions = () =>
    Chart.plugins.unregister(
      Chart.plugins.getAll().filter(p => p.id === 'fluro-analytics-chart')[0]
    );

  const registerDrawFunctions = () =>
    Chart.pluginService.register({
      id: 'fluro-analytics-chart',
      beforeDraw: chart => drawGSLabels(chart, true),
      afterRender: chart => drawGSLabels(chart, false),
    });

  const samplingPointGroupsDates = useMemo(() => {
    return Object.keys(currentPointsGroups || {}).map(date =>
      moment(date, formatDate()).format(GLOBAL_FORMAT_DATE)
    );
  }, [currentPointsGroups]);

  const allowDrawGrowthLabels = (chart: any) => {
    return Object.keys(preparedTemperatureData).length && chart.data?.labels?.length;
  };

  const setGrowthStages = (growthStages: GrowthStageLabel[]) => {
    setGrowthStageLabels(growthStages);
  };

  const drawOnChart = (ctx: any, point: any, chart: any, leaf = false, isCurrentDate = false) => {
    ctx.save();
    ctx.beginPath();
    ctx.moveTo(point.x, chart.chartArea.top);
    ctx.strokeStyle = isCurrentDate ? '#43a047' : 'rgba(0, 0, 0, 0.6)';
    ctx.lineWidth = 1;
    if (!leaf && !isCurrentDate) {
      // for the Sampling points leaf the line is dashed
      ctx.setLineDash([5, 5]);
    }
    ctx.lineTo(point.x, chart.scales['x-axis-0'].top);
    ctx.stroke();
    if (leaf) {
      // draw Leaf image for the Sampling point date
      ctx.drawImage(leafImg, point.x - 15, chart.chartArea.top - 17);
    }
    ctx.restore();
  };

  const drawGSLabels = (chart: any, beforeDraw = false) => {
    if (allowDrawGrowthLabels(chart)) {
      try {
        const line = chart.getDatasetMeta(0);
        const chartArea = chart.chartArea;
        const ctx = chart.chart.ctx;

        if (line.type === 'line') {
          let growthLabels: GrowthStageLabel[] = [];

          chart.config.options?.categories?.forEach((labelDate: string, i: number) => {
            const model = line.data?.[i]?.getCenterPoint();
            const dateGrowingState = preparedTemperatureData[labelDate]?.GrowingStage;

            if (chart.config.options.currentDate === labelDate) {
              drawOnChart(ctx, model, chart, false, true);
            }

            if (beforeDraw && !isNaN(model.x) && samplingPointGroupsDates.includes(labelDate)) {
              //draw Sampling points leaf
              drawOnChart(ctx, model, chart, true);
            }

            // ===== collect GS Labels + Positions =====
            if (
              dateGrowingState &&
              !growthLabels.find(d => d.growingStage === dateGrowingState) // because there is range of dates with the same GS
            ) {
              growthLabels.push({
                model,
                growingStage: dateGrowingState,
              });
            }
          });

          if (beforeDraw && chart.config.options.shouldShowGSLabels) {
            // ===== draw GS lines =====
            // growthLabels.forEach((lbl, i) => {
            //   // draw only last GS line for right panel charts && draw all GS lines for pop-up charts
            //   if (popUpOpenRef.current || i === growthLabels.length - 1)
            //     drawOnChart(ctx, lbl.model, chart);
            // });
          } else {
            // ===== calculate GS labels and positions =====
            if (growthLabels.length && !popUpOpenRef.current) {
              // left only latest GS label for non-pop-up chart
              growthLabels = [growthLabels[growthLabels.length - 1]];
            }

            // ===== calculate GS maxWidth =====
            growthLabels = growthLabels.map((gsc, i) => {
              let maxWidth =
                i < growthLabels.length - 1 // not the last one
                  ? growthLabels[i + 1].model.x - gsc.model.x
                  : chartArea.right - gsc.model.x;

              gsc.maxWidth = maxWidth < 15 ? 15 : maxWidth;
              return gsc;
            });

            setGrowthStages(growthLabels);
          }
        }
      } catch (e) {
        reportError(e);
      }
    }
  };

  const exportChartTitle = [
    `${farm.name} - ${field.Name.replace(/\.[^/.]+$/, '')}`.split('_').join(' '),
    `${currentSeason?.uiName} - ${index}`,
  ];

  const tooltips = useMemo(() => {
    return {
      titleFontStyle: 'normal',
      callbacks: {
        title(
          item: Chart.ChartTooltipItem[],
          {datasets}: {datasets: ChartDataSets[]}
        ): string | string[] {
          const isSatelliteHd = !!datasets[Number(item[0].datasetIndex)]?.borderDash?.length;
          const label = isSatelliteHd ? 'SatelliteHR' : 'Satellite';

          // @ts-expect-error error leftover from convertion to strict mode, please fix // not typed things
          const date = moment(this._chart.options.categories[item[0].index], 'YYYY-MM-DD').format(
            formatDate()
          );

          return `${label} - ${date}`;
        },
      },
    };
  }, []);

  const onClickChartPoint = useCallback(
    (e, element) => {
      if (element[0]) {
        // do not open chart dialog if click on the pin
        e.stopPropagation();

        try {
          const {sensor, categories} = element[0]._chart.options;
          const selectedDate = categories[element[0]._index];
          const formattedDateLabel = moment(selectedDate, 'YYYY-MM-DD').format('DD/MM/YYYY');
          const currentDatesArr = Object.keys(currentDates);
          const date = currentDatesArr.find(d => d.startsWith(formattedDateLabel));

          if (date) {
            dispatch(setDate(date));
            dispatch(setSensor(sensor));
          } else {
            showNotification({
              title: t({id: 'note.warning', defaultMessage: 'Warning'}),
              message: t({
                id: 'The date is not available.',
                defaultMessage: 'The date is not available.',
              }),
              type: 'warning',
            });
          }
        } catch (e) {}
      }
    },
    [currentDates]
  );

  return (
    <div
      key={index}
      className={`analytics-trend-container ${index} ${isPopUpTrend ? `pop-up` : ''}`}
      onClick={() => (isPopUpTrend ? null : openPop(index))}
    >
      <Line
        data={chartData}
        datasetKeyProvider={() => Math.random()} // redraw on rerender
        options={{
          //@ts-expect-error error leftover from convertion to strict mode, please fix
          currentDate: currentDateAsChartProp,
          id: `analytics-chart-${index}`, // used to set growth stages
          categories,
          shouldShowGSLabels: true,
          sensor: index,
          spanGaps: true,
          hover: {mode: 'nearest'},
          legend: {display: false},
          title: {
            display: true,
            text: index,
            lineHeight: isPopUpTrend ? '3.5' : '2.8',
            fontColor: '#666',
            fontSize: 18,
            padding: growthStageLabels.length ? 25 : 5,
          },
          layout: {
            padding: {
              right: 5,
            },
          },
          scales: {
            yAxes: [
              {
                ticks: {
                  maxTicksLimit: 8,
                  padding: 5,
                },
                gridLines: {
                  drawBorder: false, // draw the vertical line near ticks
                  drawTicks: false, // additional dash between tick and actual tick line
                },
              },
            ],
            xAxes: [
              {
                gridLines: {
                  display: false,
                },
                ticks: {
                  maxTicksLimit: 5,
                  maxRotation: 0,
                  labelOffset: 3,
                },
              },
            ],
          },
          animation: {
            duration: 0,
          },
          responsiveAnimationDuration: 0,
          onClick: onClickChartPoint,
          tooltips: tooltips,
        }}
        width={1000}
        height={1000}
      />
      {hasPreparedTemperatureData ? (
        <GrowthStageLabels
          isPopup={isPopUpTrend}
          cropType={String(currentSeason?.cropType)}
          data={growthStageLabels}
        />
      ) : null}

      {!isPopUpTrend && exportStatus && (
        <div className="trends-export">
          <Line
            data={chartData}
            ref={chart => {
              if (exportChartsRef) exportChartsRef[`${index}Chart`] = chart;
            }}
            options={{
              //@ts-expect-error error leftover from convertion to strict mode, please fix
              currentDate: currentDateAsChartProp,
              categories,
              spanGaps: true,
              legend: {display: false},
              title: {
                display: true,
                text: exportChartTitle,
                lineHeight: '1.4',
                fontColor: '#666',
                fontSize: 32,
                padding: growthStageLabels.length ? 25 : 15,
              },
              layout: {
                padding: {
                  left: 10,
                  right: 0,
                  bottom: 10,
                  top: 10,
                },
              },
              scales: {
                xAxes: [
                  {
                    ticks: {
                      maxTicksLimit: 8,
                      labelOffset: 3,
                      fontSize: 30,
                      maxRotation: 55,
                      minRotation: 55,
                      padding: 5,
                    },
                    gridLines: {
                      display: false,
                    },
                  },
                ],
                yAxes: [
                  {
                    ticks: {
                      labelOffset: 3,
                      fontSize: 30,
                      padding: 5,
                    },
                    gridLines: {
                      drawBorder: false, // draw the vertical line near ticks
                      drawTicks: false, // additional dash between tick and actual tick line
                    },
                  },
                ],
              },
              animation: {
                duration: 0,
              },
            }}
            datasetKeyProvider={() => `${Math.random()}_export`}
            width={1000}
            height={1000}
          />
        </div>
      )}
    </div>
  );
};

const GrowthStageLabels = ({
  cropType,
  data,
  isPopup,
}: {
  cropType: string;
  data: GrowthStageLabel[];
  isPopup: boolean;
}) =>
  data && data.length ? (
    <>
      {data.map((l, i) => (
        <div
          key={`gs-label-key-${i}`}
          title={l.growingStage}
          style={{
            top: isPopup ? 55 : 52,
            left: l.model.x,
            maxWidth: isPopup ? l.maxWidth : 'auto',
            transform: isPopup ? 'unset' : 'translateX(-50%)',
          }}
          className="gs-label"
        >
          <div className="gs-label__icon" />
          <div className="gs-label__label">{`[${cropType}] ${l.growingStage}`}</div>
        </div>
      ))}
    </>
  ) : null;
