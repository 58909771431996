import React from 'react';
import {Route, Switch} from 'react-router-dom';
import {CI_ADMIN, CI_GLOBAL_ADMIN, CI_HOME, CI_PROFILE} from './routes';
import {PrivateRoute, BasicAllocation} from 'containers';
import FlurosenseMap from './flurosense-map';
import {selectHasFarmsOrGroupsIds} from 'containers/login/login-selectors';
import {useAppSelector} from '_hooks';
import {RedirectFromProfilePageToProfileDialog} from 'containers/profile';

export const CIEntry = () => {
  const hasFarmsOrGroups = useAppSelector(selectHasFarmsOrGroupsIds);
  return (
    <Switch>
      <PrivateRoute path={CI_PROFILE} component={RedirectFromProfilePageToProfileDialog} />
      <PrivateRoute
        isAdminOnly
        path={CI_GLOBAL_ADMIN}
        component={React.lazy(() => import('containers/admin'))}
      />
      <PrivateRoute
        isAdminOnly
        path={CI_ADMIN}
        component={React.lazy(() => import('containers/admin/ci-admin'))}
      />
      {hasFarmsOrGroups ? <PrivateRoute exact path={CI_HOME} component={BasicAllocation} /> : null}

      <Route path={CI_HOME} component={FlurosenseMap} />
    </Switch>
  );
};

export default CIEntry;
