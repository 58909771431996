import React, {useEffect, useState} from 'react';
import {useAppSelector} from '_hooks';
import {Flex, Text} from 'components';
import {Circle} from 'components/shapes/circle';
import {ResponsiveScatterPlot} from '@nivo/scatterplot';
import {
  selectAllReportDashboardData,
  selectReportRecordCount,
  selectReportDataUnits,
  selectReportAvailableSourcingRegions,
  selectReportPageIndex,
  selectReportPageSize,
} from 'containers/si/module/selectors';
import {selectCropTypesCDLIdMap} from 'modules/global/selectors';
import {selectMeasurement} from 'containers/login/login-selectors';
import {getRegionName} from 'containers/si/planning-report/dashboard/utils/utils';
import {SIScenarioKPIs} from 'containers/si/types';
import {PRECISION_UNITS, SIScenarioKPIsLabel} from 'containers/si/constants';
import {REPORT_DASHBOARD_COLORS} from 'containers/si/colors';
import {calculateKPIValue} from 'containers/si/planning-report/dashboard/utils/calculate-scenario';
import {AdjustableCard} from '../styled-components';
import {ReductionRemovalCardTooltip} from './reduction-removal-matrix/reduction-removal-card-tooltip';

export const ReductionsRemovalsAllCard = () => {
  const dashboardData = useAppSelector(selectAllReportDashboardData);
  const recordLength = useAppSelector(selectReportRecordCount);
  const units = useAppSelector(selectReportDataUnits);
  const cropsById = useAppSelector(selectCropTypesCDLIdMap);
  const availableSourcingRegions = useAppSelector(selectReportAvailableSourcingRegions);
  const pageIndex = useAppSelector(selectReportPageIndex);
  const pageSize = useAppSelector(selectReportPageSize);
  const measurement = useAppSelector(selectMeasurement);

  const startNumber = pageIndex * pageSize + 1;

  const [scatterPlotData, setScatterPlotData] = useState<
    {
      id: string;
      data: [
        {
          x: number;
          y: number;
          xData: {value: number; unit: string};
          yData: {value: number; unit: string};
        }
      ];
    }[]
  >([]);

  // We only want to show colors for the scenarios that are on the current page
  // To do that, we need to generate an array of colors that is the length of the total number of scenarios -> `colorArray`
  // The first part of the array `colorArray0` is the color for the scenarios that are on previous pages
  // The second part of the array `colorArray1` is the color for the scenarios that are on the current page
  // The third part of the array `colorArray2` is the color for the scenarios that are on future pages
  const colorArray0 = [...Array(pageIndex * pageSize)].map(() => '#909090');
  const colorArray1 = [...Array(pageSize)].map((_, index) => REPORT_DASHBOARD_COLORS[index]);
  const colorArray2 = [...Array(Math.abs(recordLength - pageSize - pageIndex * pageSize))].map(
    () => '#909090'
  );
  const colorArray = colorArray0.concat(colorArray1).concat(colorArray2);

  useEffect(() => {
    // Generate Chart Data
    const cardData: {
      id: string;
      data: [
        {
          x: number;
          y: number;
          xData: {value: number; unit: string};
          yData: {value: number; unit: string};
        }
      ];
    }[] = [];

    dashboardData.map(scenario => {
      const cropName = cropsById[Number(scenario.crop_type)]?.label || scenario.crop_type;
      const regionName = getRegionName(scenario, availableSourcingRegions);

      const cardLabel = `${regionName}+++${cropName}+++${scenario.id}`; // using the scenario id to make sure the name is unique
      const ghgData = calculateKPIValue({
        scenario,
        kpi: SIScenarioKPIs.ghg_reductions,
        units,
        measurement,
        round: true,
        precision: PRECISION_UNITS,
      }); // Show the user selected value in the tooltip (% or #)

      const socData = calculateKPIValue({
        scenario,
        kpi: SIScenarioKPIs.soc_removals,
        units,
        measurement,
        round: true,
        precision: PRECISION_UNITS,
      }); // Show the user selected value in the tooltip (% or #)

      return cardData.push({
        id: cardLabel,
        data: [
          {
            x: socData.value,
            xData: socData, // Show the user selected value in the tooltip
            y: ghgData.value,
            yData: ghgData,
          },
        ],
      });
    });

    setScatterPlotData(cardData);
  }, [availableSourcingRegions, cropsById, dashboardData, measurement, units]);

  return (
    <AdjustableCard max={1560} base={1560} height={500}>
      <Flex justifyContent="space-between" nowrap>
        <Text variant="h3" bold>
          Reduction and removal potential
        </Text>
        <ReductionRemovalCardTooltip />
      </Flex>
      <Flex justifyContent="space-between" alignItems="center">
        <Text tertiary variant="medium">
          Intervention scenarios and crops by % reduction and removal
        </Text>
      </Flex>
      <div className="chart-container">
        <ResponsiveScatterPlot
          data={scatterPlotData}
          margin={{top: 16, right: 16, bottom: 40, left: 42}}
          xScale={{type: 'linear', min: 'auto', max: 'auto'}}
          gridXValues={[0]}
          yScale={{type: 'linear', min: 'auto', max: 'auto'}}
          gridYValues={[0]}
          nodeSize={12}
          useMesh={false}
          colors={colorArray}
          layers={['grid', 'axes', QuadrantColor, 'nodes', 'markers']}
          tooltip={({node}: {node: any}) => (
            <Flex direction="column" className="scatterplot-tooltip">
              <Flex
                direction="row"
                nowrap
                justifyContent="flex-start"
                alignItems="center"
                className="margin-bottom-8"
                gap={8}
              >
                <Circle
                  size={24}
                  color={colorArray[node.index] || '#60b854'}
                  className="scatterplot-circle"
                >
                  {node.index + 1}
                </Circle>
                <Flex className="mb-0" direction="column">
                  <Text variant="medium" bold noMargin>
                    {node.data.serieId.split('+++')[0] || 'No data'}
                  </Text>
                  <Text elementType={'span'} noMargin>
                    {node.data.serieId.split('+++')[1] || 'No data'}
                  </Text>
                </Flex>
              </Flex>

              <Flex justifyContent="space-between">
                <Text className="margin-right-12">GHG reduction</Text>
                <Text>
                  {node.data.yData.value} {node.data.yData.unit}
                </Text>
              </Flex>
              <Flex justifyContent="space-between">
                <Text className="margin-right-12">SOC removal</Text>
                <Text>
                  {node.data.xData.value} {node.data.xData.unit}
                </Text>
              </Flex>
            </Flex>
          )}
          axisBottom={{
            tickSize: 0,
            tickPadding: 10,
            tickRotation: 0,
            tickValues: 3,
            legend: SIScenarioKPIsLabel.soc_removals,
            legendPosition: 'middle',
            legendOffset: 32,
            format: (d: number) => (units === 'pct' ? `${d} %` : d),
          }}
          axisLeft={{
            tickSize: 0,
            tickPadding: 20,
            tickRotation: -90,
            tickValues: 3,
            legend: SIScenarioKPIsLabel.ghg_reductions,
            legendPosition: 'middle',
            legendOffset: -36,
            format: (d: number) => (units === 'pct' ? `${d} %` : d),
          }}
          renderNode={({node, x, y, size, color, onMouseEnter, onMouseMove, onMouseLeave}: any) => {
            const scenarioIndex = REPORT_DASHBOARD_COLORS.indexOf(color);

            return (
              <g transform={`translate(${x},${y})`}>
                <circle
                  r={scenarioIndex >= 0 ? size : size - 4}
                  fill={colorArray[node.index]}
                  onMouseEnter={onMouseEnter}
                  onMouseMove={onMouseMove}
                  onMouseLeave={onMouseLeave}
                  opacity={scenarioIndex >= 0 ? 1 : 0.5}
                />
                <text
                  textAnchor="middle"
                  alignmentBaseline="middle"
                  onMouseEnter={onMouseEnter}
                  onMouseMove={onMouseMove}
                  onMouseLeave={onMouseLeave}
                >
                  {scenarioIndex >= 0 ? startNumber + scenarioIndex : ''}
                </text>
              </g>
            );
          }}
        />
      </div>
    </AdjustableCard>
  );
};

const QuadrantColor = ({innerWidth, innerHeight, xScale, yScale}: any) => {
  const [xLowest, xHighest] = xScale.domain(); // values of the lowest and highest x values -> in 'values'
  const [yLowest, yHighest] = yScale.domain(); // values of the lowest and highest y values -> in 'values'

  const graphWidth = innerWidth; // Width of the graph -> in pixels
  const graphHeight = innerHeight; // Height of the graph -> in pixels

  const xValueSpan = xHighest - xLowest; // Span between the lowest and highest x values -> in 'values'
  const yValueSpan = yHighest - yLowest; // Span between the lowest and highest y values -> in 'values'

  const xGraphScale = graphWidth / xValueSpan; // Scaling factor to go from X 'values' to pixels
  const yGraphScale = graphHeight / yValueSpan; // Scaling factor to go from Y 'values' to pixels

  // To determine the x and y axis positions, we need to know where the 0 value is on the graph
  // To get the x axis, we need to "move" the lowest value to the 0, and then scale it to pixels
  // To get the y axis, we need to "move" the lowest value to the 0, and then scale it to pixels, then subtract it from the graph height
  // The y axis is subtracted from graphHeight because the y axis is inverted
  const xAxis = (0 - xLowest) * xGraphScale; // Where is the x axis on the graph -> in pixels
  const yAxis = graphHeight - (0 - yLowest) * yGraphScale; // Where is the y axis on the graph (+2px) -> in pixels

  const greenWidth = graphWidth - xAxis;
  const greenHeight = graphHeight - yAxis;

  const redWidth = xAxis;
  const redHeight = yAxis;

  // The green area is in the lower right quadrant
  const showGreen = greenWidth >= 0 && greenHeight >= 0;
  // The red area is in the upper left quadrant
  const showRed = redWidth >= 0 && redHeight >= 0;

  return (
    <g>
      <defs>
        <linearGradient id="green-grad" x1="0.15" y1="0.15" x2="0.85" y2="0.85">
          <stop offset="0%" stopColor="rgb(255, 255, 255, .2)" />
          <stop offset="100%" stopColor="rgb(95, 184, 84, .4)" />
        </linearGradient>
        <linearGradient id="red-grad" x1="0.15" y1="0.15" x2="0.85" y2="0.85">
          <stop offset="0%" stopColor="rgb(227, 102, 125, .4)" />
          <stop offset="100%" stopColor="rgb(255, 255, 255, .2)" />
        </linearGradient>
      </defs>
      {showGreen && (
        <rect x={xAxis} y={yAxis} width={greenWidth} height={greenHeight} fill="url(#green-grad)" />
      )}
      {showRed && <rect x={0} y={0} width={redWidth} height={redHeight} fill="url(#red-grad)" />}
    </g>
  );
};
