import {FluroDatePicker} from 'components';
import type {Moment} from 'moment';
import moment from 'moment';
import React, {useContext} from 'react';
import {InputContext} from './input-context';

type Props = {
  onChange: (value: string) => void;
  openToDate?: Date;
};

export const InputCalendar: React.ComponentType<Props> = ({onChange, openToDate}) => {
  const {id, value, defaultValue, label, disabled, placeholder} = useContext(InputContext);
  const val = value || defaultValue;
  const date = val ? moment(val) : undefined;

  const handleChange = (date: Moment) => {
    onChange(date.toISOString());
  };

  return (
    <FluroDatePicker
      autoComplete="false"
      disabled={disabled}
      hideFormat
      id={id}
      label={label}
      placeholderText={placeholder}
      onChange={handleChange}
      selected={date}
      hasLabelIndent={false}
      portal
      className={'input-date'}
      openToDate={openToDate}
    />
  );
};
