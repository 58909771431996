import {t} from 'i18n-utils';
import React, {useMemo, useState} from 'react';
import {Button, FontIcon, MenuButton, AccessibleFakeButton, SelectField} from 'react-md';
import AnomalyLabelsDropdown from 'containers/map/features/anomalies/labels-dropdown';
import {ReadOnly, CustomCheckbox, FluroTabs} from 'components';
import type {IAnomaly, TAnomalyProps, TSort} from 'containers/map/features/anomalies/types';
import {onChangeAnomalyTab} from 'containers/map/actions/anomalies-actions';
import './index.scss';
import {useAppDispatch, useAppSelector} from '_hooks';
import {
  selectAnomalies,
  selectLowPerformingAnomalies,
  selectPerformanceGeometries,
  selectPremiumAnomalies,
} from 'containers/map/features/anomalies/anomalies-selectors';
import {dialogToggle, DialogType} from 'modules/helpers';

type Props = {
  title: string;
  subtitle?: string;
  isCheckedAll: boolean;
  isReadOnly: boolean;
  labelItems?: IAnomaly[];
  onSort?(val: any, sortBy: boolean): void;
  sortBy?: TSort;
  onSelectAll(val: boolean): void;
  onChangeLabel(
    label: keyof TAnomalyProps,
    value: any,
    selectedAnomalies: IAnomaly[] | IAnomaly
  ): void;
  onSnooze?(): void;
  onDelete?(): void;
  toggleDatesPopUp?(): void;
};

const AnomalyHeader = ({
  title,
  subtitle,
  onSelectAll,
  isCheckedAll,
  onChangeLabel,
  onSnooze,
  onDelete,
  toggleDatesPopUp,
  isReadOnly,
  labelItems,
  onSort,
  sortBy,
}: Props) => {
  const sortTypes = useMemo(
    () => [
      {label: t({id: 'Size'}), value: 'size'},
      {label: t({id: 'Priority'}), value: 'priority'},
      {label: t({id: 'Date detected'}), value: 'date'},
    ],
    []
  );

  const dispatch = useAppDispatch();
  const premiumAnomalies = useAppSelector(selectPremiumAnomalies);
  const simpleAnomalies = useAppSelector(selectAnomalies);

  const anomaliesSelectedTab = simpleAnomalies.anomaliesSelectedTab;
  const visibleHistorySection = simpleAnomalies.historyOpen;
  const premiumAnomaliesList = premiumAnomalies.list;
  const visibleLowPerfAnomalies = useAppSelector(selectLowPerformingAnomalies).isVisible;
  const [menuOpened, toggleMenu] = useState(false);

  const isCropStressTabSelected = anomaliesSelectedTab === 'crop-stress';

  const performanceGeometries = useAppSelector(selectPerformanceGeometries);

  const lowPerformingAnomaliesAndRoi = visibleHistorySection
    ? [...performanceGeometries.geometryToShow, ...performanceGeometries.historyGeometries]
    : performanceGeometries.geometryToShow;

  const selectedAnomalies = isCropStressTabSelected
    ? premiumAnomaliesList.filter((a: any) => a.properties.checked)
    : lowPerformingAnomaliesAndRoi.filter(({properties}) => properties.checked);

  const disabled = selectedAnomalies.length === 0;

  const changeGeometryActions = useMemo(() => {
    const items = [];

    if (toggleDatesPopUp) {
      items.push(
        <AccessibleFakeButton
          disabled={disabled}
          key={`date-change`}
          className="anomaly-header__menu-item"
          onClick={() => {
            toggleDatesPopUp();
            toggleMenu(false);
          }}
        >
          EDIT DATES
        </AccessibleFakeButton>
      );
    }

    if (onSnooze) {
      items.push(
        <AccessibleFakeButton
          disabled={disabled}
          key={`1`}
          className="anomaly-header__menu-item"
          onClick={onSnooze}
        >
          SNOOZE
        </AccessibleFakeButton>
      );
    }

    items.push(
      <div key={`2`}>
        <AnomalyLabelsDropdown
          primary
          disabled={disabled}
          label="LABEL"
          iconEl={<FontIcon iconClassName={'fas fa-tag'} />}
          onChange={onChangeLabel}
          anomaly={labelItems}
          position={'br'}
          menuButtonEl={
            <AccessibleFakeButton
              className="anomaly-header__menu-item"
              disabled={disabled || isReadOnly}
            >
              LABEL
            </AccessibleFakeButton>
          }
        />
      </div>
    );

    if (onDelete) {
      items.push(
        <AccessibleFakeButton
          disabled={disabled}
          key={`3`}
          className="anomaly-header__menu-item"
          onClick={() => {
            onDelete();
            toggleMenu(false);
          }}
        >
          DELETE
        </AccessibleFakeButton>
      );
    }

    return items;
  }, [onSnooze, onDelete, disabled, isReadOnly, labelItems, toggleDatesPopUp]);

  return (
    <div className="anomaly-header">
      <FluroTabs
        onTabClick={value => dispatch(onChangeAnomalyTab(value))}
        selectedTab={anomaliesSelectedTab}
        tabs={[
          {label: t({id: 'Crop Stress'}), value: 'crop-stress'},
          {label: t({id: 'Areas of Interest'}), value: 'areas-of-interest'},
        ]}
      />

      {(isCropStressTabSelected
        ? !!premiumAnomaliesList.length
        : !!lowPerformingAnomaliesAndRoi.length && !visibleLowPerfAnomalies) && (
        <div className={'actions-panel'}>
          <CustomCheckbox isChecked={isCheckedAll} onCheck={() => onSelectAll(!isCheckedAll)} />

          <div>
            <div className={'d-flex'}>
              <h3 className="anomaly-header__title">{title}</h3>
              <span className="anomaly-header__subtitle">{subtitle}</span>
            </div>

            {onSort && (
              <div className="anomaly-header__sorting">
                <span>{t({id: 'Sort by'})}:</span>
                <SelectField
                  id="crop-stress-sort-select"
                  className={'select-sort-type'}
                  label=""
                  value={sortBy?.type}
                  onChange={val => onSort(val, Boolean(sortBy?.order))}
                  menuItems={sortTypes}
                />
                <FontIcon
                  className={'order-icon'}
                  onClick={() => onSort(sortBy?.type, !sortBy?.order)}
                >
                  {sortBy?.order ? 'arrow_drop_down' : 'arrow_drop_up'}
                </FontIcon>
              </div>
            )}
          </div>

          <div className={'actions-panel__buttons'}>
            {!isCropStressTabSelected && (
              <ReadOnly containerClass={'md-inline-block'}>
                <Button
                  id={`add-new-anomaly`}
                  className={'add-new-anomaly'}
                  primary
                  raised
                  iconEl={<FontIcon>add</FontIcon>}
                  iconBefore={false}
                  onClick={() => {
                    dispatch(dialogToggle(DialogType.addNewAnomaly, true));
                  }}
                />
              </ReadOnly>
            )}

            <ReadOnly containerClass={'md-inline-block'}>
              <MenuButton
                id={`anomalies-change-menu-button`}
                className="anomaly-header__menu"
                listClassName="anomaly-header__menu-list"
                icon
                visible={menuOpened}
                onVisibilityChange={val => toggleMenu(val)}
                menuItems={changeGeometryActions}
              >
                <FontIcon>more_vert</FontIcon>
              </MenuButton>
            </ReadOnly>
          </div>
        </div>
      )}
    </div>
  );
};

export default AnomalyHeader;
