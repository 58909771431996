// @ts-nocheck
import {t} from 'i18n-utils';
import React, {memo, useMemo} from 'react';
import Moment from 'moment';
import {extendMoment} from 'moment-range';

import {EditSeasonForm} from '../../forms/edit-season-form';
import FluroDialog from 'components/fluro-dialog';
import type {Season, Field} from 'containers/map/types';
import type {CropPayload, SeasonRange} from '../../types';
import {createSeasonRange} from '../../utils';
import {saveBulkCropAndSync} from 'containers/map/actions';
import {calcPlural} from '_utils/pure-utils';
import {GLOBAL_FORMAT_DATE} from '_constants';
import {showNotification} from 'components/notification/notification';
import {useAppDispatch, useAppSelector} from '../../../../../../_hooks';
import {
  selectCurrentFarmId,
  selectMapFields,
  selectMapSelectedFields,
} from '../../../../reducer/selectors';

//@ts-expect-error error leftover from convertion to strict mode, please fix
const moment = extendMoment(Moment);

type Props = {
  visible: boolean;
  type: string;
  onClose: () => void;
  selectedSeasons: Season[];
  onDeleteCrops: () => void;
};

const EditBulkSeason = memo(({visible, onClose, type, selectedSeasons, onDeleteCrops}: Props) => {
  const dispatch = useAppDispatch();

  const fields = useAppSelector(selectMapFields);
  const farmId = useAppSelector(selectCurrentFarmId);

  const isNew = type !== 'edit';
  const selectedFields = useAppSelector(selectMapSelectedFields);

  const seasonDates = (
    isNew ? selectedFields : excludeSelectedSeasonsFromFields(fields, selectedSeasons)
  ).map((field: Field) => {
    return field.Seasons.filter(s => !s.geometry_id).map(s => [s.startDate, s.endDate]);
  });

  const fieldNamesById = useMemo(() => {
    const result: Record<number, string> = {};

    fields.forEach(f => {
      result[f.ID] = f.Name;
    });
    return result;
  }, fields);

  const {lastStartDate} = useMemo(
    () => getExcludeDates(selectedSeasons, fields, isNew),
    [selectedSeasons, fields, isNew]
  );

  const seasonToEdit = useMemo(
    () => getSeasonToEdit(selectedSeasons, isNew),
    [selectedSeasons, isNew]
  );

  const fieldsOrSeasons = isNew
    ? calcPlural('field', selectedFields)
    : calcPlural('season', selectedSeasons);
  const selectedFieldsOrSeasonsAmount = isNew ? selectedFields.length : selectedSeasons.length;

  // TODO: refactor this confused function
  const onSaveCrops = useMemo(
    () => (data: CropPayload, touched: any) => {
      const {
        cropType,
        startDate,
        endDate,
        cropSubType,
        isCustomCropSubType,
        multipleSeasons,
        name = '',
      } = data;

      const dates = {
        startDate: moment(startDate).format('YYYY-MM-DD'),
        endDate: moment(endDate).format('YYYY-MM-DD'),
      };

      let seasonValues: any = {};

      if (touched.startDate || isNew) {
        seasonValues.startDate = dates.startDate;
      }

      // When we edit a single season, we should always have an endDate passed.
      // For multiple seasons we should respect default values if they were not touched.
      // For new crops we always need to pass an end date.
      if (!multipleSeasons || touched.endDate || isNew) {
        seasonValues.endDate = dates.endDate;
      }

      // TODO: multiedit sets cropType to empty, so it treats like touched
      // and erases the crop types for all the selected seasons
      if (cropType && (isNew || touched.cropType || touched.cropSubType)) {
        seasonValues = {
          ...seasonValues,
          cropType,
          params: {
            cropSubType,
            isCustomCropSubType,
          },
        };
      }

      const fieldsData = isNew
        ? selectedFields.map(f => ({
            kml_id: f.ID,
            name: f.Name,
            seasons: [{id: null, ...seasonValues}],
          }))
        : selectedSeasons.reduce((fieldsData, season) => {
            const newSeason = {
              id: season.id,
              cropType: season.cropType,
              params: season.params,
              geometry: season.geometry,
              name,
              startDate: moment(season.startDate).format('YYYY-MM-DD'),
              endDate: moment(season.endDate).format('YYYY-MM-DD'),
              ...seasonValues,
            };

            const index = fieldsData.findIndex(f => f.kml_id === season.kmlId);

            if (index === -1) {
              return [
                ...fieldsData,
                {
                  kml_id: season.kmlId,
                  name: fieldNamesById[season.kmlId],
                  seasons: [newSeason],
                },
              ];
            } else {
              fieldsData[index].seasons = [...fieldsData[index].seasons, {...newSeason}];
              return fieldsData;
            }
          }, []);

      let dateErrorCount = 0;

      fieldsData.forEach(f => {
        f.seasons = f.seasons.filter((s: Season) => {
          const isAfter = moment(s.startDate, 'YYYY-MM-DD').isAfter(
            moment(s.endDate, 'YYYY-MM-DD')
          );

          if (isAfter) {
            dateErrorCount++;
            return false;
          }

          return !isAfter;
        });
      });

      if (dateErrorCount) {
        showNotification({
          title: t({id: 'note.warning', defaultMessage: 'Warning'}),
          type: 'warning',
          message: t(
            {
              id: "New dates won't be saved for {count} {count, plural, one {season} other {seasons}}: harvest date is before sowing/planting date",
            },
            {count: dateErrorCount}
          ),
        });
      }

      dispatch(saveBulkCropAndSync(farmId, fieldsData));
      onClose();
    },
    [selectedSeasons, farmId, isNew, fieldNamesById]
  );

  return (
    <FluroDialog
      id="add/edit-crop-dialog"
      title={isNew ? t({id: 'Add new crop'}) : t({id: 'Crop settings'})}
      className={'edit-season-dialog'}
      visible={visible}
      onHide={onClose}
      isDraggable
      focusOnMount={false}
    >
      <div className="edit-field__subtitle">
        {t(
          {id: 'For {count1} selected {count2}'},
          {count1: selectedFieldsOrSeasonsAmount, count2: fieldsOrSeasons}
        )}
      </div>
      <EditSeasonForm
        seasonDates={seasonDates}
        season={seasonToEdit}
        onSubmit={onSaveCrops}
        onDelete={onDeleteCrops}
        lastStartDate={lastStartDate}
        multipleSeasons={selectedFieldsOrSeasonsAmount > 1}
        isAddingCrop={isNew}
      />
    </FluroDialog>
  );
});

const getExcludeDates = (selectedSeasons: Season[], fields: Field[], isNew: boolean) => {
  const selectedSeasonIds = selectedSeasons.map(s => s.id);
  const selectedSeasonsFieldsIds = selectedSeasons.map(s => s.kmlId);

  let filledRanges: SeasonRange[] = [];
  let lastStartDate;

  if (isNew) {
    const selectedFieldsSeasons = fields
      .filter(f => f._selected)
      .map(f => f.Seasons)
      .flat();
    filledRanges = selectedFieldsSeasons.map(createSeasonRange);
  } else {
    const fieldsWithSelectedCrops = fields.filter(f => selectedSeasonsFieldsIds.includes(f.ID));
    const unselectedSeasons = fieldsWithSelectedCrops
      .map(f => f.Seasons.filter(s => !selectedSeasonIds.includes(s.id)))
      .flat();
    filledRanges = unselectedSeasons.map(createSeasonRange);
    lastStartDate = moment
      .max(selectedSeasons.map(s => moment.utc(s.startDate)))
      .format('YYYY-MM-DD');
  }

  return {
    filledRanges,
    lastStartDate,
  };
};

const getSeasonToEdit = (selectedSeasons: Season[], isNew: boolean) => {
  if (!selectedSeasons.length || isNew)
    return {
      startDate: moment().format('YYYY-MM-DD'),
      endDate: moment().format('YYYY-MM-DD'),
      cropType: 'unknown',
      params: {cropSubType: ''},
    };

  if (selectedSeasons.length === 1) {
    return selectedSeasons[0];
  }
  const formattedSeasons = selectedSeasons.map(s => ({
    // get only used params
    startDate: moment(s.startDate, GLOBAL_FORMAT_DATE).format(GLOBAL_FORMAT_DATE),
    endDate: moment(s.endDate, GLOBAL_FORMAT_DATE).format(GLOBAL_FORMAT_DATE),
    cropType: s.cropType,
    params: s.params,
  }));
  return formattedSeasons.reduce(
    // checks if the crop data (cropType, endDate, startDate, customCropType) are the same for the selected seasons, if not, reset to default
    (resultSeason, season) => {
      if (resultSeason.cropType !== 'unknown') {
        resultSeason.cropType =
          season.cropType === resultSeason.cropType ? season.cropType : 'unknown';
      }
      if (resultSeason.startDate !== '') {
        resultSeason.startDate =
          season.startDate === resultSeason.startDate ? season.startDate : '';
      }
      if (resultSeason.endDate !== '') {
        resultSeason.endDate = season.endDate === resultSeason.endDate ? season.endDate : '';
      }
      if (resultSeason.params.cropSubType !== '') {
        resultSeason.params.cropSubType =
          season.params.cropSubType === resultSeason.params.cropSubType
            ? season.params.cropSubType
            : '';
      }
      return resultSeason;
    },
    {
      ...formattedSeasons[0],
    } as Season
  );
};

// const ensureEndDateIsMonthLater = (startDate: string, endDate: string) => {
//   if (!startDate) {
//     return endDate;
//   }
//   const a = moment(startDate);
//   const b = moment(endDate);
//   return b.diff(a, 'months') < 1 ? a.add(1, 'months').format('YYYY-MM-DD') : endDate;
// };

const excludeSelectedSeasonsFromFields = (fields: Field[], seasons: Season[]) => {
  const seasonIds = seasons.map(s => s.id);

  return fields.map(f => {
    return {...f, Seasons: f.Seasons.filter(s => !seasonIds.includes(s.id))};
  });
};

export default EditBulkSeason;
