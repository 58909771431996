import type {ComponentType} from 'react';
import React from 'react';
import './filter-status.scss';

import SICardTypeRouter from '../base/si-card-type-router';
import {SIGeometryFilterType} from 'modules/sustainability-insights/types';
import {SICoverCropsFilterStatus} from './cover-crops-filter-status';
import {SIAbatementPotentialFilterStatus} from './abatement-potential-filter-status';
import {TillageFilterStatus} from './tillage-filter-status';
import {SocFilterStatus} from './soc-filter-status';
import {GhgFilterStatus} from './ghg-filter-status';
import {YieldFilterStatus} from './yield-filter-status';
import {FertilizerFilterStatus} from './fertilizers-status';
import {EmissionFactorFilterStatus} from './emission-factor-status';

export const SIFilterStatus: ComponentType<{}> = () => {
  return (
    <>
      <SICardTypeRouter type={SIGeometryFilterType.CoverCrops}>
        <SICoverCropsFilterStatus />
      </SICardTypeRouter>

      <SICardTypeRouter type={SIGeometryFilterType.AbatementPotential}>
        <SIAbatementPotentialFilterStatus />
      </SICardTypeRouter>

      <SICardTypeRouter type={SIGeometryFilterType.Tillage}>
        <TillageFilterStatus />
      </SICardTypeRouter>

      <SICardTypeRouter type={SIGeometryFilterType.SOC}>
        <SocFilterStatus />
      </SICardTypeRouter>
      <SICardTypeRouter type={SIGeometryFilterType.GHG}>
        <GhgFilterStatus />
      </SICardTypeRouter>
      <SICardTypeRouter type={SIGeometryFilterType.Yield}>
        <YieldFilterStatus />
      </SICardTypeRouter>
      <SICardTypeRouter type={SIGeometryFilterType.Fertilizer}>
        <FertilizerFilterStatus />
      </SICardTypeRouter>
      <SICardTypeRouter type={SIGeometryFilterType.EmissionsFactor}>
        <EmissionFactorFilterStatus />
      </SICardTypeRouter>
    </>
  );
};
