import initialState from '../initial-state';
import type {IAction} from '../../types';
import {ActionTypes} from '../types';
import {RequestStatus} from 'types';
import {userFacingPriority} from '../../features/anomalies/anomalies-utils';

export default (state = initialState, action: IAction) => {
  switch (action.type) {
    case ActionTypes.MAP_TOGGLE_LOW_PERF_ANOMALIES:
      return {
        ...state,
        isMapBarOpen: true,
        feature: action.value ? 'crop' : state.feature,
        lowPerfAnomalies: {
          ...state.lowPerfAnomalies,
          isVisible: action.value,
        },
      };

    case ActionTypes.MAP_SET_LOW_PERF_ANOMALIES:
      return {
        ...state,
        lowPerfAnomalies: {
          ...state.lowPerfAnomalies,
          list: action.lowPerformingAreas,
          isLoaded: true,
        },
      };

    case ActionTypes.MAP_UPDATE_LOW_PERF_ANOMALIES:
      return {
        ...state,
        lowPerfAnomalies: {
          ...state.lowPerfAnomalies,
          list: {
            ...state.lowPerfAnomalies.list,
            ...action.lowPerformingAreas,
          },
        },
        geometriesOnMap: true,
      };
    /*
    Upload user's geometries.
    During the upload they are displayed similar to low performing areas.
    After the upload is finished they become ROI. (Region of interest).
    Open the anomalies tab, show the geometries, close the wholeTableView.
     */
    case ActionTypes.MAP_UPLOAD_AREAS_OF_INTEREST:
      return {
        ...state,
        lowPerfAnomalies: {
          ...state.lowPerfAnomalies,
          list: {
            ...state.lowPerfAnomalies.list,
            ...action.lowPerformingAreas,
          },
          isVisible: true,
        },
        geometriesOnMap: true,
        wholeTableViewOpen: undefined,
      };

    case ActionTypes.MAP_AREAS_OF_INTEREST_TOGGLE_HISTORY: {
      return {
        ...state,
        anomalies: {
          ...state.anomalies,
          historyOpen: action.value,
        },
      };
    }

    case ActionTypes.MAP_LOAD_PREMIUM_ANOMALIES:
      return {
        ...state,
        premiumAnomalies: {
          list: [],
          status: RequestStatus.Loading,
        },
      };

    case ActionTypes.MAP_PREMIUM_ANOMALIES_LOADED:
      return {
        ...state,
        premiumAnomalies: {
          list: action.data,
          status: RequestStatus.Success,
        },
      };

    // Update a single anomaly with a given prop.
    // action.data: {id, prop, value}
    case ActionTypes.MAP_UPDATE_PREMIUM_ANOMALY_PROP:
      return {
        ...state,
        premiumAnomalies: {
          ...state.premiumAnomalies,
          list: state.premiumAnomalies.list.map(anomaly => {
            const {prop, value} = action.data;
            if (anomaly.properties.anomaly_id === action.data.id) {
              return {
                ...anomaly,
                properties: {
                  ...anomaly.properties,
                  [prop]: value,
                },
              };
            }
            return anomaly;
          }),
        },
      };

    // Update a list of anomalies.
    // action.list
    case ActionTypes.MAP_UPDATE_PREMIUM_ANOMALY_LIST:
      return {
        ...state,
        premiumAnomalies: {
          ...state.premiumAnomalies,
          list: state.premiumAnomalies.list.map(anomaly => {
            const {anomaly_id} = anomaly.properties;
            const anomalyToUpdate = action.list.find((upd: any) => anomaly_id === upd.id);
            if (anomalyToUpdate) {
              const {label, description, snoozed} = anomalyToUpdate;
              const priority = userFacingPriority(anomalyToUpdate.priority, label);

              return {
                ...anomaly,
                properties: {
                  ...anomaly.properties,
                  label,
                  description,
                  snoozed,
                  priority,
                },
              };
            }
            return anomaly;
          }),
        },
      };

    // Update a list of anomalies with a given prop.
    // action.data: {anomalies, prop, value}
    case ActionTypes.MAP_BULK_UPDATE_PREMIUM_ANOMALY_PROP:
      return {
        ...state,
        premiumAnomalies: {
          ...state.premiumAnomalies,
          list: state.premiumAnomalies.list.map((anomaly: any) => {
            const isEqualId = action.data.anomalies.some(
              (el: any) => anomaly.properties.anomaly_id === el.properties.anomaly_id
            );
            if (isEqualId) {
              return {
                ...anomaly,
                properties: {
                  ...anomaly.properties,
                  [action.data.prop]: action.data.value,
                },
              };
            }
            return anomaly;
          }),
        },
      };

    case ActionTypes.MAP_PREMIUM_ANOMALIES_HIDE:
      return {
        ...state,
        premiumAnomalies: {
          ...state.premiumAnomalies,
          status: RequestStatus.Idle,
        },
      };

    case ActionTypes.MAP_REMOVE_PREMIUM_ANOMALIES:
      return {
        ...state,
        premiumAnomalies: {
          ...state.premiumAnomalies,
          list: state.premiumAnomalies.list.filter(
            anomaly => !action.anomalyIds.includes(anomaly.properties.anomaly_id)
          ),
        },
      };

    case ActionTypes.MAP_CHANGE_ANOMALIES_TAB:
      return {
        ...state,
        anomalies: {
          ...state.anomalies,
          anomaliesSelectedTab: action.value,
        },
      };
    case ActionTypes.MAP_UPLOAD_AREAS_OF_INTEREST_SET_STATUS:
      return {
        ...state,
        anomalies: {
          ...state.anomalies,
          uploadingAOIStatus: action.value,
        },
      };

    default:
      return state;
  }
};
