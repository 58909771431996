import {selectMeasurement, selectUser} from 'containers/login/login-selectors';
import type {Measurement} from 'containers/login/types';
import type {ImperialYieldRateUnit} from 'containers/mrv/monitoring/form/inputs/constants';
import type {FieldValuesRow, MRVAttribute} from 'containers/mrv/types';
import {MRVAttributeType} from 'containers/mrv/types';
import moment from 'moment';
import {useMemo} from 'react';
import {useAppSelector} from '_hooks';
import {getTypedEntries} from '_utils/object';
import {useMonitoringUrlParams} from '../../hooks';
import {selectMRVAttributes} from '../../module/selectors';

/**
 * BIG HACK
 *
 * We have to validate across a field's rows and cells.
 * The backend team were flat out busy with other things,
 * and so we had to hack this together in the frontend to meet the deadline.
 *
 * This MUST be done on the backend, preferably at the database level.
 */
export const useHackyValidateFieldRows = (
  fieldRows: FieldValuesRow[],
  ignoredWarnings: ValidationWarning[],
  ignoredSimilarWarnings: ValidationWarning[]
) => {
  const {programId} = useMonitoringUrlParams();
  const attributes = useAppSelector(selectMRVAttributes);
  const measurement = useAppSelector(selectMeasurement);
  const user = useAppSelector(selectUser);

  const validation = useMemo(() => {
    if (user.id === 'new') {
      return {errors: [], warnings: []};
    }

    return hackyValidateFieldRows(
      fieldRows,
      attributes,
      programId,
      measurement,
      user.id,
      ignoredWarnings,
      ignoredSimilarWarnings
    );
  }, [
    attributes,
    ignoredSimilarWarnings,
    ignoredWarnings,
    measurement,
    programId,
    fieldRows,
    user.id,
    // Require a deep compare of the values in the field rows
    JSON.stringify(fieldRows.map(row => row.values)),
  ]);

  return validation;
};

const percentageBasedAttributes = [
  MRVAttributeType.AreaTilledTotalPct,
  MRVAttributeType.AreaTilledForPastureRenewalPct,
];

function hackyValidateFieldRows(
  fieldRows: FieldValuesRow[],
  attributes: Record<string, MRVAttribute>,
  programId: number,
  measurement: Measurement,
  userId: number,
  ignoredWarnings: ValidationWarning[],
  ignoredSimilarWarnings: ValidationWarning[]
): {errors: ValidationError[]; warnings: ValidationWarning[]} {
  const errors: ValidationError[] = [];
  const warnings: ValidationWarning[] = [];

  function isWarningIgnored(warning: ValidationWarning): boolean {
    // Always check that attributeId and userId match

    // ignore similar warnings (from clicking "Suppress similar warnings" on the tooltip)
    switch (warning.type) {
      case 'YieldHigherThanExpectedWarning':
        if (
          ignoredSimilarWarnings.some(
            ignoredSimilarWarning =>
              IsYieldHigherThanExpectedWarning(ignoredSimilarWarning) &&
              ignoredSimilarWarning.attributeId === warning.attributeId &&
              ignoredSimilarWarning.userId === warning.userId &&
              ignoredSimilarWarning.cropType === warning.cropType &&
              ignoredSimilarWarning.yieldUnit === warning.yieldUnit
          )
        ) {
          // don't want to return false here, we need to check the id as well
          return true;
        }
        break;

      case 'YieldZeroWarning':
        if (
          ignoredSimilarWarnings.some(
            ignoredSimilarWarning =>
              IsYieldZeroWarning(ignoredSimilarWarning) &&
              ignoredSimilarWarning.attributeId === warning.attributeId &&
              ignoredSimilarWarning.userId === warning.userId
          )
        ) {
          // don't want to return false here, we need to check the id as well
          return true;
        }
        break;
    }

    // ignore warning by valueId and type (from clicking "Ignore" on the tooltip)
    return ignoredWarnings.some(
      ignoredWarning =>
        ignoredWarning.attributeId === warning.attributeId &&
        ignoredWarning.userId === warning.userId &&
        ignoredWarning.valueId === warning.valueId &&
        ignoredWarning.type === warning.type
    );
  }

  for (let fr = 0; fr < fieldRows.length; fr++) {
    const fieldRowValues = getTypedEntries(fieldRows[fr]?.values).map(([_, value]) => value);

    for (let c = 0; c < fieldRowValues.length; c++) {
      const cell = fieldRowValues[c];
      const attribute = attributes[cell?.attribute_id];

      // If empty, skip
      if (!cell || !attribute) {
        continue;
      }

      const {type: attributeType} = attribute;

      // Program-Specific validations
      if (programId === 68 && measurement === 'ac') {
        if (
          attributeType === MRVAttributeType.WinterCropType ||
          attributeType === MRVAttributeType.SummerCropType
        ) {
          const yieldProjectValue = fieldRowValues.find(
            x =>
              attributes[x.attribute_id]?.enabled &&
              (attributes[x.attribute_id]?.type === MRVAttributeType.SummerDryYield ||
                attributes[x.attribute_id]?.type === MRVAttributeType.WinterDryYield)
          );
          if (!yieldProjectValue || !yieldProjectValue.id) {
            continue;
          }

          const yieldNumber = parseInt(yieldProjectValue.value);
          const yieldValue: number | null = isNaN(yieldNumber) ? null : yieldNumber;

          if (yieldValue === null) {
            continue;
          }

          if (yieldValue === 0) {
            const warning: ValidationWarning<YieldZeroWarning> = {
              type: 'YieldZeroWarning',
              attributeId: yieldProjectValue.attribute_id,
              rowId: cell.row_id,
              userId,
              valueId: yieldProjectValue.id,
              message:
                'Generally the yield should be greater than 0. Please double check the value.',
            };

            if (!isWarningIgnored(warning)) {
              warnings.push(warning);
            }

            continue;
          }

          const unit: ImperialYieldRateUnit = fieldRowValues.find(
            x =>
              attributes[x.attribute_id]?.type === MRVAttributeType.YieldRateUnit &&
              attributes[x.attribute_id]?.enabled
          )?.value;
          if (!unit) {
            continue;
          }

          if (
            (cell.value === 'corn' && unit === 'bu/ac' && yieldValue > 350) ||
            (cell.value === 'soybeans' && unit === 'bu/ac' && yieldValue > 150) ||
            (cell.value === 'sugar_beets' && unit === 'T/ac' && yieldValue > 60) ||
            (cell.value === 'sorghum' && unit === 'bu/ac' && yieldValue > 200) ||
            (cell.value === 'wheat_spring' && unit === 'bu/ac' && yieldValue > 150) ||
            (cell.value === 'small_grains' && unit === 'bu/ac' && yieldValue > 200) ||
            (cell.value === 'hay' && unit === 'lb/ac' && yieldValue > 15000) ||
            (cell.value === 'cotton' && unit === 'lb/ac' && yieldValue > 2000) ||
            (cell.value === 'alfalfa' && unit === 'T/ac' && yieldValue > 26) ||
            (cell.value === 'oat' && unit === 'bu/ac' && yieldValue > 200) ||
            (cell.value === 'canola' && unit === 'bu/ac' && yieldValue > 120) ||
            (unit === 'lb/ac' && yieldValue > 6000) ||
            (cell.value === 'lentils' && unit === 'lb/ac' && yieldValue > 4000) ||
            (cell.value === 'spring_barley' && unit === 'bu/ac' && yieldValue > 120) ||
            (cell.value === 'millet' && unit === 'bu/ac' && yieldValue > 140) ||
            (cell.value === 'peanuts' && unit === 'lb/ac' && yieldValue > 6800) ||
            (cell.value === 'pumpkins' && unit === 'lb/ac' && yieldValue > 60000) ||
            (cell.value === 'peas' && unit === 'bu/ac' && yieldValue > 180) ||
            (cell.value === 'sunflowers' && unit === 'bu/ac' && yieldValue > 150) ||
            (unit === 'lb/ac' && yieldValue > 2600) ||
            (cell.value === 'triticale' && unit === 'bu/ac' && yieldValue > 160) ||
            (unit === 'lb/ac' && yieldValue > 16000) ||
            (cell.value === 'sweet_corn' && unit === 'bu/ac' && yieldValue > 400) ||
            (cell.value === 'rice' && unit === 'lb/ac' && yieldValue > 18000) ||
            (cell.value === 'potatoes' && unit === 'lb/ac' && yieldValue > 100000) ||
            (cell.value === 'dry_beans' && unit === 'bu/ac' && yieldValue > 80) ||
            (cell.value === 'winter_barley' && unit === 'bu/ac' && yieldValue > 150) ||
            (cell.value === 'buckwheat' && unit === 'lb/ac' && yieldValue > 4000) ||
            (cell.value === 'clover' && unit === 'lb/ac' && yieldValue > 8000) ||
            (cell.value === 'vetch' && unit === 'lb/ac' && yieldValue > 12000) ||
            (cell.value === 'rye' && unit === 'bu/ac' && yieldValue > 200) ||
            (cell.value === 'wheat_winter' && unit === 'bu/ac' && yieldValue > 150)
          ) {
            const warning: ValidationWarning<YieldHigherThanExpectedWarning> = {
              type: 'YieldHigherThanExpectedWarning',
              message:
                'This number looks higher than expected for the given yield unit. Please double check the value.',
              attributeId: yieldProjectValue.attribute_id,
              rowId: cell.row_id,
              userId,
              valueId: yieldProjectValue.id,
              cropType: cell.value,
              yieldUnit: unit,
            };

            if (!isWarningIgnored(warning)) {
              warnings.push(warning);
            }
            continue;
          }
        }
      }

      // Validate Tillage Practice
      if (attributeType === MRVAttributeType.TillagePractice) {
        const noTillValue = 'no till';
        const message =
          'You cannot have a field with both no tillage and tillage during the same tillage period.';

        if (cell.value !== noTillValue) {
          continue;
        }

        const recordYearInNoTillRow = fieldRowValues.find(
          x =>
            attributes[x.attribute_id]?.type === MRVAttributeType.RecordYear &&
            attributes[x.attribute_id]?.enabled
        )?.value;

        const tillagePeriodInNoTillRow = fieldRowValues.find(
          x =>
            attributes[x.attribute_id]?.type === MRVAttributeType.TillagePeriod &&
            attributes[x.attribute_id]?.enabled
        )?.value;

        const rowsWithSameRecordYearAndTillagePeriod = fieldRows.filter(x => {
          const row = getTypedEntries(x?.values).map(([_, v]) => v);
          const recordYear = row.find(
            y =>
              attributes[y.attribute_id]?.type === MRVAttributeType.RecordYear &&
              attributes[y.attribute_id]?.enabled
          )?.value;

          if (!recordYear) {
            return false;
          }

          const tillagePeriod = row.find(
            y =>
              attributes[y.attribute_id]?.type === MRVAttributeType.TillagePeriod &&
              attributes[y.attribute_id]?.enabled
          )?.value;

          if (!tillagePeriod) {
            return false;
          }

          const tillagePractice = row.find(
            y =>
              attributes[y.attribute_id]?.type === MRVAttributeType.TillagePractice &&
              attributes[y.attribute_id]?.enabled
          )?.value;

          if (!tillagePractice) {
            return false;
          }

          return (
            recordYear === recordYearInNoTillRow &&
            tillagePeriod === tillagePeriodInNoTillRow &&
            tillagePractice !== noTillValue
          );
        });

        if (rowsWithSameRecordYearAndTillagePeriod.length > 0) {
          errors.push({
            attributeId: cell.attribute_id,
            rowId: cell.row_id,
            message,
          });
        }

        for (let r = 0; r < rowsWithSameRecordYearAndTillagePeriod.length; r++) {
          const rowWithSameRecordYearAndTillagePeriod =
            rowsWithSameRecordYearAndTillagePeriod[r].values[cell.attribute_id];

          if (!rowWithSameRecordYearAndTillagePeriod) {
            continue;
          }

          if (rowWithSameRecordYearAndTillagePeriod.value !== noTillValue) {
            errors.push({
              attributeId: rowWithSameRecordYearAndTillagePeriod.attribute_id,
              rowId: rowWithSameRecordYearAndTillagePeriod.row_id,
              message,
            });
          }
        }
      }

      // Validate percentage-based number attributes
      if (percentageBasedAttributes.includes(attributeType)) {
        if (cell.value > 100) {
          errors.push({
            attributeId: cell.attribute_id,
            rowId: cell.row_id,
            message: 'The percentage cannot be greater than 100%. Please check your values.',
          });
        }
      }

      if ((attribute.min_val || attribute.max_val) && cell.value) {
        const message = 'The value you have entered is out of range.';
        const error = {
          attributeId: cell.attribute_id,
          rowId: cell.row_id,
          message,
        };

        const parse = (v: string | null) => {
          return cell.value.includes('-')
            ? Date.parse(moment(v).format('YYYY-MM-DD'))
            : parseInt(v || '');
        };

        const currentNumber = parse(cell.value);

        const {min_val, max_val} = attribute;

        if (
          (min_val && currentNumber < parse(min_val)) ||
          (max_val && currentNumber > parse(max_val))
        ) {
          errors.push(error);
        }
      }
    }
  }

  return {errors, warnings};
}

export type ValidationError = {
  attributeId: number;
  rowId: number;
  message: string;
};

export type ValidationWarning<WarningData = YieldHigherThanExpectedWarning | YieldZeroWarning> = {
  attributeId: number;
  rowId: number;
  message?: string;
  userId: number;
  valueId: number;
} & WarningData;

export type YieldHigherThanExpectedWarning = {
  type: 'YieldHigherThanExpectedWarning';
  /**
   * e.g. sugar_beets
   */
  cropType: string;
  yieldUnit: ImperialYieldRateUnit;
};

function IsYieldHigherThanExpectedWarning(
  warning: ValidationWarning
): warning is ValidationWarning<YieldHigherThanExpectedWarning> {
  return warning.type === 'YieldHigherThanExpectedWarning';
}

export type YieldZeroWarning = {
  type: 'YieldZeroWarning';
};

function IsYieldZeroWarning(
  warning: ValidationWarning
): warning is ValidationWarning<YieldZeroWarning> {
  return warning.type === 'YieldZeroWarning';
}
