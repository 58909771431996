import type {ComponentType} from 'react';
import React from 'react';
import {PracticeAvatar} from './practice-avatar';
import {Practice} from 'containers/mrv/types';
import {CirclesWrapper} from './practice.styled';
import {Circle} from 'components/shapes/circle';
import cn from 'classnames';

type Props = {value: Practice[]; id: string | number; onClick?: () => void};

export const PracticePreviewCircles: ComponentType<Props> = ({value, id, onClick}) => {
  if (!value.length) {
    return (
      <CirclesWrapper onClick={onClick} className="no-circles">
        No practices selected
      </CirclesWrapper>
    );
  }

  return (
    <CirclesWrapper
      className={cn({clickable: !!onClick})}
      onClick={onClick}
      title={value.join(', ')}
    >
      {value
        .slice(0, 3)
        // don't show icons for "no cover crop" practices
        .filter(p => p !== Practice.NoCoverCrop)
        .map((p, i) => (
          <div className="avatar-offset" style={{zIndex: value.length - i}} key={id + p + i}>
            <PracticeAvatar value={p} />
          </div>
        ))}
      {value.length > 3 && (
        <div className="avatar-offset" style={{zIndex: 1}}>
          <Circle className={'practice-avatar'} size={30}>
            +{value.length - 3}
          </Circle>
        </div>
      )}
    </CirclesWrapper>
  );
};
