// @ts-nocheck
import React from 'react';
import cn from 'classnames';
import moment from 'moment';
import {setOpacity} from '_utils/colors';
import {formatAvgNDVI, NoValueReason} from './';
import type {Biomass} from '../types';
import {biomassColors, ReasonCode} from '../types';
import {PlaneIcon, SatelliteIcon} from 'containers/map/icons';

export const NdviView = ({
  iconType,
  ndvi,
  status,
  predicted,
  date,
  reason,
  isTableView = false,
}: {
  iconType?: 'satellite' | 'plane';
  ndvi: number;
  status?: Biomass;
  predicted?: boolean;
  date?: string;
  reason?: ReasonCode;
  isTableView?: boolean;
}) => {
  if (ndvi == null) {
    return <NoValueReason reasonCode={reason || ReasonCode.Unknown} />;
  }
  const color = biomassColors[status];
  return (
    <div className="cp__ndvi-container">
      {iconType === 'plane' ? <PlaneIcon /> : iconType === 'satellite' ? <SatelliteIcon /> : null}
      <div
        className={cn('crop-perf-cell', 'cp__ndvi', {
          _high: status === 'high',
          _medium: status === 'medium',
          _low: status === 'low',
        })}
        style={{
          color,
          background: predicted ? '' : setOpacity(color, 0.1, true),
          border: predicted ? `1px dashed ${color}` : '',
        }}
      >
        {formatAvgNDVI(ndvi)}
        {isTableView && date && (
          <span className="crop-perf-sensor-date">({moment.utc(date).format('DD MMM')})</span>
        )}
      </div>
    </div>
  );
};
