import React from 'react';
import {Text} from 'components';
import {FSInputGroup} from 'containers/login/TO_GO_INTO_DS/FsInputGroup';
import {FSInput} from 'containers/login/TO_GO_INTO_DS/FSInput';
import styled from 'styled-components';
import type {Validation} from 'containers/si/planning-report/programPlan/components/NumericInput/validation';
import {
  emptyValidation,
  validNumberValidation,
  maxValidation,
  minValidation,
} from 'containers/si/planning-report/programPlan/components/NumericInput/validation';

interface NumericInputProps {
  setValue: (val: number) => void;
  value: number;
  min?: number;
  max?: number;
  customValidation?: Array<Validation<string>>;
}

// This component is designed to be reusable in the limited use cases in Program Plan;
// it could be further adapted to be reusable in more cases as well.
export const NumericInput = ({
  value,
  setValue,
  min,
  max,
  customValidation = [],
}: NumericInputProps) => {
  const [error, setError] = React.useState('');
  const defaultValue = React.useRef(value);

  // Reject all key presses that are non-numeric
  const allowOnlyNumbers = React.useCallback((e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key.length === 1 && !/[\d.]/.test(e.key)) {
      e.preventDefault();
    }
  }, []);

  const predicates: Array<Validation<string>> = React.useMemo(
    () => [
      emptyValidation,
      validNumberValidation,
      ...minValidation(min),
      ...maxValidation(max),
      ...customValidation,
    ],
    [customValidation, max, min]
  );

  const allPassValidation = React.useCallback(
    (newValue: string) =>
      predicates.every(([predicate, message]) => {
        if (predicate(newValue)) {
          return setError(message); // every stops iterating when a falsey value is returned
        }
        return true;
      }),
    [predicates]
  );

  const handleChange = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const newValue = e.target.value;
      setError('');
      const allPass = allPassValidation(newValue);
      if (allPass) {
        setValue(Number(newValue));
      }
    },
    [allPassValidation, setValue]
  );

  return (
    <StyledFSInputGroup>
      <FSInput
        type="number"
        defaultValue={defaultValue.current}
        min={min}
        max={max}
        onKeyDown={allowOnlyNumbers}
        onChange={handleChange}
      />
      <ErrorText noMargin variant="small">
        {error}
      </ErrorText>
    </StyledFSInputGroup>
  );
};

const StyledFSInputGroup = styled(FSInputGroup)`
  max-width: 204px;
`;

const ErrorText = styled(Text)`
  min-height: 1.4em;
  color: ${({theme}) => theme.color.text.error} !important;
`;
