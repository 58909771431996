import type {
  FertilizerApplication,
  NRxSeason,
  NRxObjectiveResponseFeature,
  NRxObjectiveType,
  NRxRecomendationSettings,
  NRxResultsResponse,
  NrxTabRate,
  NRxZone,
  NRxZonesCollection,
} from 'containers/map/features/nrx';
import {
  checkForPreparedSeasons,
  convertFertilizerValues,
  convertNRxSeasonValues,
  defaultNaasMaizePlantingSettings,
  defaultSowingParams,
  prepareApplication,
  prepareSeasonUpdateRequests,
  checkResponseForHighRoi,
  classifyYieldGoal,
  classifyYieldUnits,
  convertUSValues,
  getLastAvailableRecommendationDate,
  getTotalNitrogenFromValue,
  NRxAutomaticallyChangedROISettingsMessage,
  ZONES_COLORS,
  getPreparedNRxRequestData,
} from 'containers/map/features/nrx';
import {area as turfArea, union as turfUnion} from '@turf/turf';

import {ActionTypes} from '../reducer/types';
import {showNotification} from 'components/notification/notification';
import {t} from 'i18n-utils';
import type {AppStore} from 'reducers';
import {NrxApi} from '_api';
import type {AppDispatch} from 'store';
import {sortByKey, sortByKey2} from '_utils/sorters';
import {clamp, convertFromSquareMetersToMeasure, convertUnit} from '_utils';
import {getGetURLParam, setGetParamToURL} from '_utils/pure-utils';
import {getTypedKeys} from '_utils/object';
import {toFixedFloatUnsafe} from '_utils/number-formatters';

import moment from 'moment';
import {GLOBAL_FORMAT_DATE} from '_constants';
import type {NRecommendationMethod, Zone} from '../types';
import {ZoningTab} from '../types';
import {AsyncStatusType, setRequestStatus, Status} from '../../../modules/helpers';
import cancelTokenStore from '_api/cancel-tokens-store';

import {reportError} from '../../error-boundary';
import {selectMeasurement} from '../../login/login-selectors';
import {
  selectCurrentField,
  selectCurrentSeasonId,
  selectMapFieldById,
  selectZoning,
} from '../reducer/selectors';
import {
  selectNRecommendation,
  selectNrxSeason,
  selectNRxFertilizersRecordsByTypeId,
} from 'containers/map/features/nrx/nrx-selectors';
import axios from 'axios';

export const setUpdateSeasonNrxData =
  (seasonID: number | number[], seasonData: Partial<NRxSeason>) =>
  (dispatch: AppDispatch, getState: () => AppStore) => {
    const state = getState();
    const measurement = selectMeasurement(state);
    const nrxSeasons = Array.isArray(seasonID)
      ? seasonID.map(id => selectNrxSeason(state, id))
      : [selectNrxSeason(state, seasonID)];

    if (!dispatch(checkForPreparedSeasons(seasonID, seasonData))) {
      return Promise.resolve(
        dispatch({
          type: ActionTypes.MAP_NRX_UPDATE_SEASON_NRX_DATA,
          seasonID,
          data: seasonData,
        })
      );
    }

    const requests = prepareSeasonUpdateRequests(nrxSeasons, seasonData, measurement);

    return Promise.all(requests)
      .then(data => {
        data.forEach(({data}) => {
          dispatch({
            type: ActionTypes.MAP_NRX_UPDATE_SEASON_NRX_DATA,
            seasonID: data.result.seasonID,
            data: {...seasonData, ...convertNRxSeasonValues(data.result, true, measurement)},
          });
        });
        return showNotification({
          title: t({id: 'note.success', defaultMessage: 'Success'}),
          message: t({id: 'Processing has started. Results will be ready in 1 to 2 hours.'}),
          type: 'success',
        });
      })
      .catch();
  };

export const fApplicationAdd =
  (payload: FertilizerApplication) => (dispatch: AppDispatch, getState: () => AppStore) => {
    const state = getState();
    const measurement = selectMeasurement(state);
    const fertilizersByTypeId = selectNRxFertilizersRecordsByTypeId(state);

    return NrxApi.addFertilizerApplication(
      prepareApplication(
        payload,
        measurement,
        Boolean(fertilizersByTypeId[Number(payload.typeID)].isLiquid)
      )
    )
      .then(({data}) => {
        dispatch({
          type: ActionTypes.MAP_NRX_FERTILIZER_APP_SET,
          nrxSeason: {
            nrxSeasonID: data.result.nrxSeasonID,
            seasonID: data.result.seasonID,
            kmlID: data.result.kmlID,
            recommendationDates: data.result.recommendationDates || [],
            fertilizerApplications: (data.result?.fertiliserApplications || []).map(
              (item: FertilizerApplication) => ({
                ...(fertilizersByTypeId[Number(item.typeID)] || {}),
                ...convertFertilizerValues(
                  item,
                  true,
                  measurement,
                  Boolean(fertilizersByTypeId[Number(item.typeID)].isLiquid)
                ),
              })
            ),
          },
        });
        showNotification({
          title: t({id: 'note.success', defaultMessage: 'Success'}),
          message: t({id: 'Your application was successfully added.'}),
          type: 'success',
        });
      })
      .catch();
  };

export const fApplicationUpdate =
  (payload: FertilizerApplication) => (dispatch: AppDispatch, getState: () => AppStore) => {
    const state = getState();
    const measurement = selectMeasurement(state);
    const fertilizersByTypeId = selectNRxFertilizersRecordsByTypeId(state);

    return NrxApi.updateFertilizerApplication(
      prepareApplication(
        payload,
        measurement,
        Boolean(fertilizersByTypeId[Number(payload.typeID)].isLiquid)
      )
    )
      .then(({data}) => {
        dispatch({
          type: ActionTypes.MAP_NRX_FERTILIZER_APP_SET,
          nrxSeason: {
            nrxSeasonID: data.result.nrxSeasonID,
            seasonID: data.result.seasonID,
            kmlID: data.result.kmlID,
            recommendationDates: data.result.recommendationDates || [],
            fertilizerApplications: (data.result?.fertiliserApplications || []).map(
              (item: FertilizerApplication) => ({
                ...(fertilizersByTypeId[Number(item.typeID)] || {}),
                ...convertFertilizerValues(
                  item,
                  true,
                  measurement,
                  Boolean(fertilizersByTypeId[Number(item.typeID)].isLiquid)
                ),
              })
            ),
          },
        });
        showNotification({
          title: t({id: 'note.success', defaultMessage: 'Success'}),
          message: t({id: 'Your application data was successfully updated.'}),
          type: 'success',
        });
      })
      .catch();
  };

export const fApplicationRemove =
  (fieldId: number, appId: number, nrxSeasonId: number) =>
  (dispatch: AppDispatch, getState: () => AppStore) => {
    const state = getState();
    const measurement = selectMeasurement(state);
    const fertilizersByTypeId = selectNRxFertilizersRecordsByTypeId(state);

    return NrxApi.deleteFertilizerApplication(nrxSeasonId, appId)
      .then(({data}) => {
        dispatch({
          type: ActionTypes.MAP_NRX_FERTILIZER_APP_SET,
          nrxSeason: {
            nrxSeasonID: data.result.nrxSeasonID,
            seasonID: data.result.seasonID,
            kmlID: data.result.kmlID,
            recommendationDates: data.result.recommendationDates || [],
            fertilizerApplications: (data.result?.fertiliserApplications || []).map(
              (item: FertilizerApplication) => ({
                ...(fertilizersByTypeId[Number(item.typeID)] || {}),
                ...convertFertilizerValues(
                  item,
                  true,
                  measurement,
                  Boolean(fertilizersByTypeId[Number(item.typeID)].isLiquid)
                ),
              })
            ),
          },
        });
        showNotification({
          title: t({id: 'note.success', defaultMessage: 'Success'}),
          message: t({id: 'Your application was successfully removed.'}),
          type: 'success',
        });
      })
      .catch();
  };

export const updateNRxCropTypeTypeParams =
  (seasonIds: number | number[], cropID: number, cropVarietyID: number) =>
  async (dispatch: AppDispatch, getState: () => AppStore) => {
    const getDefaultPlantingParams = (seasonId: number): Promise<Partial<NRxSeason>> => {
      const state = getState();
      const nrxSeason = selectNrxSeason(state, seasonId);
      const field = selectMapFieldById(state, nrxSeason.kmlID);
      const fieldCountry = field?.Country;
      const fieldRegion = field?.CountryRegion;
      const isCropTypeChanged = nrxSeason.cropID !== cropID;
      const measurement = selectMeasurement(state);

      return new Promise(resolve => {
        if (isCropTypeChanged) {
          if (fieldCountry === 'United States') {
            NrxApi.getDefaultCornSettings(
              moment(nrxSeason.startDate, GLOBAL_FORMAT_DATE).format(GLOBAL_FORMAT_DATE),
              String(fieldRegion)
            )
              .then(({data}) => {
                resolve({
                  ...defaultSowingParams(cropID, measurement),
                  sowingDensity:
                    data.planting_info.plant_density ||
                    defaultNaasMaizePlantingSettings['sowingDensity'],
                  rowSpacing:
                    data.planting_info.row_spacing ||
                    defaultNaasMaizePlantingSettings['rowSpacing'],
                  NASSPlantingParams: Boolean(
                    data.planting_info.plant_density || data.planting_info.row_spacing
                  ),
                });
              })
              .catch(() => {
                resolve({
                  ...defaultSowingParams(cropID, measurement),
                  ...defaultNaasMaizePlantingSettings,
                }); // extra default params from FSB-4287
              });
          } else {
            resolve(defaultSowingParams(cropID, measurement));
          }
        } else {
          resolve({});
        }
      });
    };

    if (Array.isArray(seasonIds)) {
      return Promise.all(
        seasonIds.map(async seasonId => {
          const defaultParams: Partial<NRxSeason> = await getDefaultPlantingParams(seasonId);
          return dispatch(
            setUpdateSeasonNrxData(seasonId, {cropID, cropVarietyID, ...defaultParams})
          );
        })
      );
    } else {
      const defaultParams = await getDefaultPlantingParams(seasonIds);
      return dispatch(setUpdateSeasonNrxData(seasonIds, {cropID, cropVarietyID, ...defaultParams}));
    }
  };

export const toggleNRecommendationMethod =
  (method: NRecommendationMethod) => (dispatch: AppDispatch) => {
    dispatch({
      type: ActionTypes.MAP_SET_N_RECOMMENDATION_METHOD,
      nRecommendationMethod: method,
    });
  };

// NRx

export const getNRxRecommendation =
  () => async (dispatch: AppDispatch, getState: () => AppStore) => {
    const REQUEST_NUMBER = 3; // try request NRx tree times
    const state = getState();
    const {nrxRecommendationSettings} = selectNRecommendation(state);
    const field = selectCurrentField(state);
    const zoning = selectZoning(state);
    const currentSeasonId = selectCurrentSeasonId(state);
    const measurement = selectMeasurement(state);

    const isNRxEnabled = zoning.tab === ZoningTab.NitrogenRx;
    const season = selectNrxSeason(state, currentSeasonId);

    const preparedNRxRequestData = getPreparedNRxRequestData(state, season);

    if (
      !nrxRecommendationSettings.recommendation_date &&
      preparedNRxRequestData.recommendation_date
    ) {
      dispatch(
        updateRecommendationSettings({
          recommendation_date: preparedNRxRequestData.recommendation_date,
        })
      );
    }

    if (!getGetURLParam('nrx-date') && preparedNRxRequestData.recommendation_date) {
      setGetParamToURL('nrx-date', preparedNRxRequestData.recommendation_date); // set nrx-date URL param if it misses by default
    }

    if (isNRxEnabled && preparedNRxRequestData.recommendation_date) {
      // try to cancel prev requests
      for (let i = 0; i <= REQUEST_NUMBER; i++) {
        cancelTokenStore.cancel(`getNRxRecommendation${i}`);
      }

      dispatch(setRequestStatus(AsyncStatusType.NRxRecommendation, Status.Pending));

      let resultResponse: (NRxResultsResponse & {resultRoiSettings?: number}) | undefined =
        undefined;
      const resultUnits = preparedNRxRequestData.isLiquid ? 'gal/ac' : 'lbs/ac';

      for (let i = preparedNRxRequestData.ROI_setting || 0; i <= 3; i++) {
        // here we are trying to get some NRx results by increasing ROI settings
        if (!resultResponse) {
          try {
            if (i !== preparedNRxRequestData.ROI_setting) {
              dispatch(
                updateRecommendationSettings({...nrxRecommendationSettings, ROI_setting: i})
              ); // keep the pop-up values consistent
            }

            const response = await NrxApi.getNRxRecommendation(
              {...preparedNRxRequestData, ROI_setting: i},
              i // pass index to identify request for create cancel token
            );
            if (checkResponseForHighRoi(response.data) || i === REQUEST_NUMBER) {
              resultResponse = response.data;
              resultResponse.resultRoiSettings = i;
              if (i !== nrxRecommendationSettings.ROI_setting) {
                showNotification({
                  title: t({id: 'note.info', defaultMessage: 'Info'}),
                  message: NRxAutomaticallyChangedROISettingsMessage(
                    nrxRecommendationSettings.ROI_setting,
                    i,
                    () => {
                      dispatch(toggleNRxSettingsVisibility(true));
                      dispatch(toggleNRxResultsVisibility(false));
                    }
                  ),
                  type: 'info',
                  autoClose: 30000,
                });
              }
            }
          } catch (err) {
            if (!axios.isCancel(err)) {
              dispatch(
                setRequestStatus(AsyncStatusType.NRxRecommendation, Status.Done, {
                  fieldId: field.ID,
                })
              );
              reportError(`getNRxRecommendation err = ${err}`);

              showNotification({
                title: t({id: 'note.warning', defaultMessage: 'Warning'}),
                message: t({
                  id: 'The model encountered an issue, please contact our support Team.',
                }),
                type: 'warning',
              });
            }

            return {data: {}};
          }
        }
      }

      const resultRecommendation = {
        balanced: {} as NRxZonesCollection,
        max_roi: {} as NRxZonesCollection,
        max_yield: {} as NRxZonesCollection,
      };

      if (resultResponse) {
        getTypedKeys(resultResponse).forEach(objective => {
          if (objective === 'resultRoiSettings') return;

          if (!resultResponse?.[objective]?.map?.features?.length) return;

          const currentObjective = resultResponse[objective];

          resultRecommendation[objective] = {
            type: 'FeatureCollection',
            avgNrx: toFixedFloatUnsafe(
              convertUnit(measurement, resultUnits, currentObjective.avg_nrx),
              0
            ),
            yield_potential_units: classifyYieldUnits(measurement, Number(season.cropID)),
            yield_potential_max: classifyYieldGoal(
              resultResponse[objective].yield_potential_max,
              measurement,
              Number(season.cropID)
            ),
            yield_potential_min: classifyYieldGoal(
              resultResponse[objective].yield_potential_min,
              measurement,
              Number(season.cropID)
            ),
            features: sortByKey2(
              currentObjective.map.features.map((f: NRxObjectiveResponseFeature) => {
                const rawProperties = {...f.properties};
                const zoneArea = convertFromSquareMetersToMeasure(turfArea(f), measurement);
                const fieldArea = convertUnit(measurement, 'ac', field.Area);
                const zonePercentSize = (zoneArea / fieldArea) * 100;
                //convert result if need
                let rxValue = toFixedFloatUnsafe(
                  convertUnit(measurement, resultUnits, f.properties.val),
                  0
                );
                const nitrogenAmount = getTotalNitrogenFromValue(
                  rxValue,
                  Number(preparedNRxRequestData.n_percentage),
                  Boolean(preparedNRxRequestData.isLiquid),
                  Number(preparedNRxRequestData.specific_gravity),
                  measurement
                );

                if (
                  // if only one zone was returned with an empty value, format the output
                  resultResponse?.resultRoiSettings === 3 &&
                  currentObjective.map.features.length === 1 &&
                  rxValue === 0
                ) {
                  rxValue = '-';
                }
                const additionalProps: Partial<Zone> = {};

                if (f.properties.crop_yield) {
                  additionalProps.yield_goal_units = classifyYieldUnits(
                    measurement,
                    Number(season.cropID)
                  );
                  additionalProps.yield_goal = classifyYieldGoal(
                    f.properties.crop_yield,
                    measurement,
                    Number(season.cropID)
                  );
                }

                return {
                  ...f,
                  raw_properties: rawProperties,
                  properties: {
                    resultROISettings: resultResponse?.resultRoiSettings,
                    area: zoneArea,
                    value: rxValue,
                    nitrogenAmount,
                    percent: clamp(0, zonePercentSize, 100),
                    ...additionalProps,
                  },
                };
              }),
              item => item.properties.value
            )
              .reverse() // reverse sorted fields
              // TODO (stas): this `zone: any` is because i fixed the type of sortByKey, but what this next function returns
              // is not a friend of what `features` expects.
              .map((zone: any, i, arr) => ({
                // set properties that are relied on the index after sorting to avoid unordered zones names and colors
                ...zone,
                properties: {
                  ...zone.properties,
                  id: i + 1,
                  name: `Zone ${i + 1}`,
                  color:
                    arr.length > 1 && i + 1 === arr.length
                      ? ZONES_COLORS[ZONES_COLORS.length - 1]
                      : ZONES_COLORS[i],
                },
              })), // from bigger to lower value
          };
        });
      }

      dispatch({
        type: ActionTypes.MAP_NRX_SET_RECOMMENDATION_RESULT,
        value: resultRecommendation,
      });

      dispatch(setRequestStatus(AsyncStatusType.NRxRecommendation, Status.Done));

      return Promise.resolve(true);
    }

    // try to cancel prev requests
    for (let i = 0; i <= REQUEST_NUMBER; i++) {
      cancelTokenStore.cancel(`getNRxRecommendation${i}`);
    }
    dispatch(setRequestStatus(AsyncStatusType.NRxRecommendation, Status.Done));

    return Promise.resolve(false);
  };

export const updateNRxRecommendationProps = (data: any) => ({
  type: ActionTypes.MAP_NRX_UPDATE_RECOMMENDATION_PROPS,
  data,
});

export const updateRecommendationSettings =
  (value: Partial<NRxRecomendationSettings>, saveValues = false) =>
  (dispatch: AppDispatch, getState: () => AppStore) => {
    if (saveValues) {
      const state = getState();
      const measurement = selectMeasurement(state);
      const currentSeasonId = selectCurrentSeasonId(state);
      const nrxSeason = selectNrxSeason(state, currentSeasonId);

      const clearedValues = {
        ...convertUSValues(value, Number(nrxSeason.cropID), 'ha'), // to clear the values
        historical_yield_avg: classifyYieldGoal(
          Number(value.historical_yield_avg),
          measurement,
          Number(nrxSeason.cropID),
          true
        ),
        recommendation_date:
          value.recommendation_date ||
          getLastAvailableRecommendationDate(nrxSeason.recommendationDates),
        units: measurement,
      };

      dispatch(setRequestStatus(AsyncStatusType.NRXSettings, Status.Pending));
      NrxApi.saveRecommendationSettings(clearedValues, nrxSeason.nrxSeasonID)
        .then(() => {
          // update nrx season in state
          dispatch({
            type: ActionTypes.MAP_NRX_UPDATE_SEASON_NRX_DATA,
            seasonID: currentSeasonId,
            data: {roiSettings: {...(nrxSeason.roiSettings || {}), ...clearedValues}},
          });
          dispatch(setRequestStatus(AsyncStatusType.NRXSettings, Status.Done));
        })
        .catch(err => {
          dispatch(setRequestStatus(AsyncStatusType.NRXSettings, Status.Done, err));
        });
    }
    dispatch({
      type: ActionTypes.MAP_NRX_UPDATE_POP_UP_SETTINGS,
      value,
    });
  };

export const updateRecommendationDate =
  (date: string) => (dispatch: AppDispatch, getState: () => AppStore) => {
    const state = getState();
    const {nrxRecommendationSettings} = selectNRecommendation(state);
    const currentSeasonId = selectCurrentSeasonId(state);

    const {recommendationDates} = selectNrxSeason(state, currentSeasonId);
    let resultValue = '';

    if (recommendationDates.includes(date)) {
      resultValue = date;
    } else {
      if (
        !resultValue &&
        !nrxRecommendationSettings.recommendation_date &&
        recommendationDates.length
      )
        // set the last recommendation date if any is selected
        resultValue = getLastAvailableRecommendationDate(recommendationDates);

      showNotification({
        title: t({id: 'note.info', defaultMessage: 'Info'}),
        message: t({
          id: 'Results are not available for this recommendation date, please select another date.',
        }),
        type: 'info',
      });
    }

    if (resultValue && nrxRecommendationSettings.recommendation_date !== resultValue) {
      dispatch(updateRecommendationSettings({recommendation_date: resultValue}));
      setGetParamToURL('nrx-date', date);
      dispatch(getNRxRecommendation());
    }
  };

export const toggleNRxTabRate =
  (value: NrxTabRate) => (dispatch: AppDispatch, getState: () => AppStore) => {
    const {nrxResult, selectedObjective} = selectNRecommendation(getState());

    dispatch({
      type: ActionTypes.MAP_NRX_TOGGLE_RATE_TAB,
      value,
    });

    if (!nrxResult?.[value]?.[selectedObjective]?.features) {
      // load new-opened tab recommendation
      dispatch(getNRxRecommendation());
    }
  };

export const selectNRxObjective = (value: NRxObjectiveType) => ({
  type: ActionTypes.MAP_NRX_SELECT_OBJECTIVE,
  value,
});

export const calculateMergedZonesName = (zones: NRxZone[]): {name: string; ids: number[]} => {
  const mergedZonesIds = zones // get sorted zones id that will be merged
    .map(z => (z.properties.merged === 'merged' ? z.properties.mergedZonesIds : z.properties.id))
    .flat() // flat the array if there are mergedZonesIds
    .filter(id => id) // remove 0 ids
    .sort();

  const preparedIds = mergedZonesIds.map((id, index) => {
    const nextId = mergedZonesIds[index + 1];
    const prevId = mergedZonesIds[index - 1];
    const comaOrDash = nextId ? (nextId - Number(id) > 1 ? ', ' : '-') : '';
    const typedId = Number(id);

    if (nextId && prevId && typedId - prevId === 1 && nextId - typedId === 1) return null; // intermediate element

    return `${id}${comaOrDash}`;
  });

  return {name: preparedIds.join(''), ids: mergedZonesIds.map(Number)};
};

export const mergeNRxZones = () => (dispatch: AppDispatch, getState: () => AppStore) => {
  const state = getState();
  const {nrxResult, nrxTabRate, selectedObjective} = selectNRecommendation(state);
  const field = selectCurrentField(state);
  const measurement = selectMeasurement(state);

  const zonesToMerge = [] as NRxZone[];
  const currentZones = nrxResult?.[nrxTabRate]?.[selectedObjective]?.features
    .map((zone: NRxZone) => {
      if (zone.properties.selected) {
        zonesToMerge.push(zone);
        if (zone.properties.merged === 'merged') return zone; // makes able filtering old merged zones

        zone.properties.selected = false; // reset selected prop;
        zone.properties.merged = 'initial'; // set a prop to recognize the initial zone in future
      }
      return zone;
    })
    .filter((z: any) => !(z.properties.selected && z.properties.merged === 'merged')); // filter old merged zones (if won't do it after unmergining they will appear as regular zones)

  const fieldArea = convertUnit(measurement, 'ac', field.Area);

  const mergedZones = zonesToMerge.reduce(
    (resultZone: any, currentZone: any, index: number) => {
      if (!index) return currentZone; // skip the first zone because it is the default value
      const zone1Props = resultZone.properties;
      const zone2Props = currentZone.properties;
      const resultGeometry = turfUnion(resultZone, currentZone); // merge geometries
      const zoneArea = resultGeometry
        ? convertFromSquareMetersToMeasure(turfArea(resultGeometry), measurement)
        : 0; // regular area calculating
      const properties = {} as Zone;

      const calculateAvgValueByProp = (zone1: any, zone2: any, prop: string) => {
        return toFixedFloatUnsafe(
          (zone1.properties.area * zone1.properties[prop] + // the formula is ((val1 * area1)+(val2 * area2))/(area1 + area2)
            zone2.properties.area * zone2.properties[prop]) /
            (zone1.properties.area + zone2.properties.area),
          0
        );
      };
      properties.id =
        Number(nrxResult?.[nrxTabRate]?.[selectedObjective]?.features?.length) +
        Number(currentZones?.filter(f => f.properties.merged === 'initial').length); // get all the zones length and add initial zones length to get a uniq index
      // RECALCULATE ZONE VALUES
      properties.area = zoneArea;
      properties.selected = false;
      properties.merged = 'merged'; // important flag to find the result merged zones in future
      properties.color = zone1Props.color; // take the color from the first geometry (this one can be changed)
      properties.resultROISettings = zone2Props.resultROISettings; // this one is the same for all the NRx zones
      properties.value = calculateAvgValueByProp(resultZone, currentZone, 'value');
      properties.nitrogenAmount = calculateAvgValueByProp(
        resultZone,
        currentZone,
        'nitrogenAmount'
      );
      properties.yield_goal = calculateAvgValueByProp(resultZone, currentZone, 'yield_goal');
      properties.percent = clamp(0, (zoneArea / fieldArea) * 100, 100);

      return {...resultGeometry, properties};
    },
    {...zonesToMerge[0]}
  );

  const {name, ids} = calculateMergedZonesName(zonesToMerge);
  mergedZones.properties.name = name;
  mergedZones.properties.mergedZonesIds = ids;

  dispatch({
    type: ActionTypes.MAP_NRX_MERGE_ZONES,
    zones: sortByKey([mergedZones, ...(currentZones || [])], 'properties.value').reverse(),
  });
};

export const revertMergeNRxZones = () => (dispatch: AppDispatch, getState: () => AppStore) => {
  const {nrxResult, nrxTabRate, selectedObjective} = selectNRecommendation(getState());
  dispatch({
    type: ActionTypes.MAP_NRX_REVERT_MERGE_ZONES,
    zones: nrxResult?.[nrxTabRate]?.[selectedObjective]?.features
      .filter(f => f.properties.merged !== 'merged') // simply filter merged zones
      .map(f => ({...f, properties: {...f.properties, merged: false}})), // reset merged prop for the rest of the zones to make them "pure" again
  });
};

export const toggleNRxSettingsVisibility = (value: boolean) => ({
  type: ActionTypes.MAP_NRX_TOGGLE_SETTINGS_VISIBILITY,
  value,
});

export const toggleNRxResultsVisibility = (value: boolean) => ({
  type: ActionTypes.MAP_NRX_TOGGLE_RESULTS_VISIBILITY,
  value,
});
