// @ts-nocheck
import axios from 'axios';
import {v1 as uuidv1} from 'uuid';
import {t} from 'i18n-utils';
import _config from '_environment';
import {showNotification} from 'components/notification/notification';
import {responseWarningMessage} from './messages';
import {
  requestLoading,
  responseLoading,
  responseLoadingError,
  responseUnathorized401,
  responseUnathorized401Error,
} from './base';
import {isLocalhost} from '_utils';
import Mixpanel from '_utils/mixpanel-utils';
import {getAdditionalHeaders} from './api-utils';
import {getUserToken} from '_utils/safe-local-storage';
import {reportError} from '../containers/error-boundary';

const {baseUrl, authHeader} = _config;

if (!isLocalhost()) {
  // Add version header to each request (FSB-2932).
  axios.interceptors.request.use(config => {
    config.headers['x-fs-app-version'] = _config.app_version;
    return config;
  });
}

// add X-Request-ID, X-Correlation-ID headers with the same uniq value for each request, FSB-5053
// axios.interceptors.request.use(config => {
//   const uniqKey = Date.now() + Math.random().toString(36);
//   config.headers['x-request-id'] = uniqKey;
//   config.headers['x-correlation-id'] = uniqKey;
//   return config;
// });

axios.interceptors.request.use(requestLoading, function (error) {
  return Promise.reject(error);
});
axios.interceptors.response.use(responseLoading, function (error) {
  return Promise.reject(responseLoadingError(error));
});

//TODO: need to have the discussion about - do we need session expired dialog when we call Axios directly
// axios.interceptors.response.use(
//   response => response,
//   function(error) {
//     return Promise.reject(responseUnathorized401(error));
//   }
// );

// add speed interceptor

axios.interceptors.request.use(onUploadProgress, function (error) {
  return Promise.reject(error);
});

// ---

const Server = axios.create({
  baseURL: `${baseUrl}api/v1/`,
  validateStatus: function (status) {
    return status < 500;
  },
  headers: {
    [authHeader]: getUserToken(),
    // Add version header to each request (FSB-2932).
    ...(!isLocalhost() ? {'x-fs-app-version': _config.app_version} : {}),
    ...getAdditionalHeaders(),
  },
});

Server.interceptors.request.use(requestLoading);

Server.interceptors.response.use(responseLoading, function (error) {
  return Promise.reject(responseLoadingError(error));
});

Server.interceptors.response.use(responseUnathorized401, function (error) {
  return Promise.reject(responseUnathorized401Error(error));
});

Server.interceptors.request.use(onUploadProgress, function (error) {
  return Promise.reject(error);
});

Server.interceptors.request.use(
  function (config) {
    const uuid = uuidv1();
    config.headers['X-Request-ID'] = uuid;
    config.headers['X-Correlation-ID'] = uuid;
    config.headers[authHeader] = getUserToken();
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

const skipErrorUrlPatterns: Array<string | [string, number]> = [
  'tree_detection',
  'sync/agworld/growers', // we do this request also just to check if user is authorized
  // 'emails/to_review'
  'crop_performance', // multifarm crop perf can be requested outside the seasons, we handle it in ui
  'sync/climate', // this is request to get climate farms, if it returns 404 - it means the user is unauthorized
  'sync/efc/growers', // tries to get EFC growers by current user. returns 404 if the user is not added to EFC
  'syncv2/agx', // this is request to sync or get agx growers, it has it's own error handlers so shouldn't display the default error message
  'sync/johndeere/organizations', // returns 400 if the current user is not connected
  'mrv-sign-up', // there are several different cases UI can handle (expired link, already signed-up)
  ['/run_dndc', 429], // we have special dialog to handle 429 status code for the url (https://regrow.atlassian.net/browse/FSB-9403?focusedCommentId=35930)
  'sync/farmlogs/fields', // there is personal handlers
  'sync/farmlogs/import', // there is personal handlers
  'farmlogs/users/invite', // manually handle errors
];
/**
 * Pass {params: {__skipError: true}} to the request, to have silent errors.
 */
const shouldSkipError = (url: string, statusCode?: number, data?: string) => {
  try {
    const parsed = JSON.parse(data);
    parsed.params = parsed.params || {};
    if (parsed.params.__skipError) {
      return true;
    }
  } catch (e) {}

  return skipErrorUrlPatterns.some(p =>
    Array.isArray(p) ? url.includes(p[0]) && p[1] === statusCode : url.includes(p)
  );
};

/** We default to notifying the user for a 400-5xx range reponse before rejecting the promise, sometimes we want to use the error code to customise the feedback before it goes to a user (or ignore entirely)
 * Add a regex for any paths you wish to perform your own error handling.
 * Note: this means it won't reject the promise.
 */
const excludedUrls = [/mrv\/program_codes\/[a-zA-Z0-9-]*\/enrol/];

Server.interceptors.response.use(
  response => {
    if (
      response.data &&
      response.status >= 400 &&
      response.status !== 401 &&
      !excludedUrls.some(url => url.test(response.request.responseURL))
    ) {
      if (responseWarningMessage(response.data.result || response?.data?.detail?.message)) {
        const errorMessage = responseWarningMessage(
          response.data.result || response?.data?.detail?.message
        );
        showNotification({
          type: 'error',
          ...errorMessage,
        });
        Mixpanel.errorMessage(String(errorMessage.message), response);
      } else if (
        !shouldSkipError(response.request.responseURL, response.status, response.config.data)
      ) {
        const defaultMessage = t({
          id: 'errorTryReloadPage',
          defaultMessage: 'An error occurred, try to reload the page.',
        });
        const errorMessage = response?.data?.detail?.message || response?.data?.result;

        // TODO: avoid magic numbers (3)
        // Do not know what kinds of messages the backend might send, guessed that 3 should be the minimum length
        const message = errorMessage?.length > 3 ? errorMessage : defaultMessage;

        Mixpanel.errorMessage(
          errorMessage || 'An error occurred, try to reload the page.',
          response
        );
        showNotification({
          title: t({id: 'note.error', defaultMessage: 'Error'}),
          message: message,
          type: 'error',
        });
        reportError(
          JSON.stringify({
            requestError: true,
            status: response,
            statusText: response.statusText,
            message: response.data ? response.data.result : 'NONE',
          })
        );
      }

      return Promise.reject(response);
    }
    return response;
  },
  error => Promise.reject(error)
);

export default Server;

function onUploadProgress(config: any) {
  if (!config.onUploadProgress) return config;

  const oldProgress = config.onUploadProgress;
  config.uploaded = 0;
  config.lastUpTime = 0;

  config.onUploadProgress = function (ev: any) {
    const endTime = new Date().getTime();
    const uploadSpeed = (
      ((ev.loaded - config.uploaded) * 1000) /
      ((endTime - config.lastUpTime) * 1024)
    ).toFixed(2);
    config.uploaded = ev.loaded;
    config.lastUpTime = endTime;
    ev.fluroconfig = config;
    return oldProgress(ev, uploadSpeed);
  };

  return config;
}
