// @ts-nocheck
import type {ComponentType} from 'react';
import React, {useEffect, useState} from 'react';
import {useUrlParams} from '_hooks/use-url-params';
import {CircularProgress} from 'react-md';
import {Flex, Text} from 'components';
import {MRVApi} from '_api';
import {contactSupportTeam} from '_utils/pure-utils';
import styled from 'styled-components';

const ContentContainer = styled.div`
  padding: 20px;
  text-align: center;
`;

export const DocusignConsentPage: ComponentType = () => {
  const [requestSent, setRequestStatus] = useState(false);
  const [errorMessage, setErrorMessage] = useState<React.ReactElement>(null);
  const [{state, code}] = useUrlParams();

  useEffect(() => {
    if (state && code) {
      MRVApi.callDocusignCallBack({state, code})
        .then(() => {
          setRequestStatus(true);
          setErrorMessage(null);
        })
        .catch(() =>
          setErrorMessage(
            <Text variant={'h1'}>
              Request error, try again later. Or {contactSupportTeam('contact support team')}
            </Text>
          )
        );
    } else if (!state || !code) {
      setErrorMessage(
        <Text variant={'h1'}>
          Missing <b>state</b> or <b>code</b> parameters, please check if the link is correct. Or{' '}
          {contactSupportTeam('contact support team')}
        </Text>
      );
    }
  }, [state, code]);

  if (errorMessage) return <ContentContainer>{errorMessage}</ContentContainer>;

  return (
    <ContentContainer>
      {requestSent ? (
        <Text className={'md-text-center element-full-width margin-top-20'} variant={'h1'}>
          Request is successful
        </Text>
      ) : (
        <Flex gap={'10px'} fullWidth justifyContent={'center'} alignItems={'center'}>
          <Text className={'margin-bottom-0'} variant={'h1'}>
            Making the request
          </Text>
          <CircularProgress centered={false} id={'DocusignConsentPage'} />
        </Flex>
      )}
    </ContentContainer>
  );
};
