import type {BarExtendedDatum, BarItemProps} from '@nivo/bar';
import React from 'react';
import type {BarDetails} from './bar-charts';
import {BarTooltip} from './bar-tooltip';

export const RoundedBar = ({
  data,
  x,
  y,
  width,
  height,
  color,
  showTooltip,
  hideTooltip,
}: BarItemProps) => {
  const getXLocation = () => {
    const isPositive = data.value > 0;

    return x - (isPositive ? 5 : 0);
  };

  return (
    <g
      onMouseMove={(event): void => {
        showTooltip(
          <BarTooltip data={data as BarExtendedDatum & {data: {barDetails: BarDetails}}} />,
          event
        );
      }}
      onMouseLeave={hideTooltip}
    >
      <clipPath id={`rounded-${data.index}${data.value}`}>
        <rect x={getXLocation()} y={y} width={width + 5} height={height} rx="5" ry="5" />
      </clipPath>
      <rect
        x={x}
        y={y}
        width={width}
        height={height}
        fill={color}
        clipPath={`url(#rounded-${data.index}${data.value})`}
      />
    </g>
  );
};
