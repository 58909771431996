import type {ComponentType} from 'react';
import React, {useMemo} from 'react';
import type {BarChartProps} from './bar-chart';
import {BarChart} from './bar-chart';
import './summary-chart.scss';

export const SummaryChart: ComponentType<BarChartProps> = ({
  data,
  keys = [],
  nameLabel,
  ...barChartProps
}) => {
  const chartData = useMemo(() => {
    if (!data?.length) return;
    const allNegatives = data.every(d => keys.every(k => d[k] < 0));
    const dataResult = allNegatives
      ? // mind blowing hack to fix the negative values bars overflow the container 🥲
        // see details here: https://github.com/plouc/nivo/issues/1709#issuecomment-893550780
        [{...data[0], [keys[0]]: -0.000000000000000000001}, ...data]
      : data;

    return dataResult;
  }, [data, keys]);

  if (!chartData?.length) return null;

  return <BarChart data={chartData} keys={keys} nameLabel={nameLabel} {...barChartProps} />;
};
