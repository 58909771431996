import {FormattedMessage, t} from 'i18n-utils';
import React from 'react';
import {
  AgworldApi,
  AgxApi,
  ClimateApi,
  EFCApi,
  FarmlogsApi,
  IntegrationsApi,
  JohnDeereApi,
  PWApi,
  UserApi,
} from '_api';
import {showNotification} from 'components/notification/notification';
import {RequestStatus} from '../../../types';
import {reportError} from '../../error-boundary';
import {getFarmsList, selectFarm} from 'modules/farms/actions';
import {sortByStringKey} from 'containers/profile/integration/helpers/utils';
import {contactSupportTeam} from '_utils/pure-utils';
import type {AppStore} from '../../../reducers';
import {ActionType} from './reducer';
import type {Farm} from '../../map/types';
import {ExternalService} from '../../map/types';
import type {EFCSyncRequestPayload} from './integration-efc';
import {classifyHighestEntity} from './integration-efc';
import type {JohnDeereRequestPayload} from './integration-john-deere';
import {classifyJohnDeereHighestEntity} from './integration-john-deere';
import type {IntegrationPlatform, SyncResponseData} from './types';
import type {AgworldSyncRequestPayload} from './integration-agworld';
import {classifyAgworldHighestEntity} from './integration-agworld';
import type {PWSyncRequestPayload} from './integration-productionwise';
import type {AppDispatch} from 'store';
import {setLogin} from '../../login/actions';
import {
  AsyncStatusType,
  dialogToggle,
  DialogType as HelpersDialogToggle,
  setRequestStatus,
  Status,
} from '../../../modules/helpers';
import {classifySyncResponseData} from '../../mrv/monitoring/module/thunks';
import {loadFarmsFieldsWithGeometries, toggleMapBar} from 'containers/map/actions';
import {selectProjectId} from 'containers/mrv/monitoring/module/selectors';
import {syncProcessStartedInfoText} from 'containers/mrv/utils';
import {ExternalServiceLabels} from 'containers/profile/integration/integration-utils';
import {checkWorkspace} from 'containers/admin/users/types';

export type Action =
  | AgworldSyncGrowers
  | AgxSyncGrowers
  | AgxToggleFarmsImported
  | ClimateImportFields
  | EFCImportGrowers
  | JohnDeereImportGrowers
  | LoadPreselectedPlatforms
  | PWSyncFarms
  | SetPlatformAuthStatus
  | SetSelectedPlatformToImport
  | LogOutFromPlatform
  | ImportFarmlogsFields;

type SetPlatformAuthStatus = {
  type: ActionType.SET_PLATFORM_AUTH_VALUE;
  platform: IntegrationPlatform;
  value: boolean;
};

type AgworldSyncGrowers = {
  type: ActionType.AGWORLD_SYNC_GROWERS;
  syncStatus: RequestStatus;
};

type EFCImportGrowers = {
  type: ActionType.EFC_SYNC_GROWERS;
  syncStatus: RequestStatus;
};

type AgxSyncGrowers = {
  type: ActionType.AGX_SYNC_GROWERS;
  syncStatus: RequestStatus;
};

type AgxToggleFarmsImported = {
  type: ActionType.AGX_TOGGLE_FARMS_IMPORTED;
  farmsImported: boolean;
};

type ClimateImportFields = {
  type: ActionType.CLIMATE_SYNC_FIELDS;
  syncStatus: RequestStatus;
};

type JohnDeereImportGrowers = {
  type: ActionType.JOHN_DEERE_SYNC_GROWERS;
  syncStatus: RequestStatus;
};

type LoadPreselectedPlatforms = {
  type: ActionType.SET_PRESELECTED_PLATFORMS;
  platforms: {[platform in ExternalService]: boolean};
};

type PWSyncFarms = {
  type: ActionType.PW_SYNC_FARMS;
  syncStatus: RequestStatus;
};

type SetSelectedPlatformToImport = {
  type: ActionType.SELECT_PLATFORM_TO_IMPORT;
  platform: IntegrationPlatform;
};
type LogOutFromPlatform = {
  type: ActionType.LOG_OUT_FROM_PLATFORM;
  platform: IntegrationPlatform;
};

type ImportFarmlogsFields = {
  type: ActionType.FARMLOGS_SYNC_FIELDS;
  syncStatus: RequestStatus;
};

export const setSelectedPlatformToImport = (platform: IntegrationPlatform | null) => ({
  type: ActionType.SELECT_PLATFORM_TO_IMPORT,
  platform,
});

export const logOutFromPlatform = (platform: IntegrationPlatform) => ({
  type: ActionType.LOG_OUT_FROM_PLATFORM,
  platform,
});

export const setPlatformAuthStatus =
  (platform: IntegrationPlatform, value: boolean) => (dispatch: AppDispatch) => {
    dispatch({
      type: ActionType.SET_PLATFORM_AUTH_VALUE,
      platform,
      value,
    });
  };

// Agworld

export const importAgworldGrowers =
  (payload: AgworldSyncRequestPayload) => (dispatch: AppDispatch, getState: () => AppStore) => {
    const mrvProjectId = selectProjectId(getState());

    showNotification({
      title: t({id: 'note.info', defaultMessage: 'Info'}),
      message: t({id: 'agWorldImportStarted'}),
      type: 'info',
      autoClose: 10000,
    });
    dispatch({
      type: ActionType.AGWORLD_SYNC_GROWERS,
      syncStatus: RequestStatus.Loading,
    });

    const highestEntity = classifyAgworldHighestEntity(payload);
    AgworldApi.syncGrowers(payload, mrvProjectId)
      .then(({data}: any) => {
        if (data.data.status === 'ready' && highestEntity) {
          dispatch(checkAgworldImportStatus(data.data.id, highestEntity));
        }
      })
      .catch(e => {
        reportError(`Couldn't sync Agworld growers. Error: ${e}`);
        showNotification({
          title: t({id: 'note.error', defaultMessage: 'Error'}),
          message: t({id: 'agWorldSyncErr', defaultMessage: "Couldn't sync Agworld growers."}),
          type: 'error',
          autoClose: 60000,
        });
        dispatch({
          type: ActionType.AGWORLD_SYNC_GROWERS,
          syncStatus: RequestStatus.Error,
        });
      });
  };

const checkAgworldImportStatus =
  (jobId: number, highestEntity: string) => (dispatch: AppDispatch) => {
    return AgworldApi.getImportStatus(jobId)
      .then(({data}) => {
        if (data.data.status === 'succeeded') {
          // set request status
          dispatch({
            type: ActionType.AGWORLD_SYNC_GROWERS,
            syncStatus: RequestStatus.Success,
          });
          // load new farms
          dispatch(loadNewFarms(ExternalService.Agworld));
          // show a success message
          showNotification({
            title: t({id: 'note.success', defaultMessage: 'Success'}),
            message: t({
              id: 'agWorldSyncDoneOk',
              defaultMessage: `Agworld growers synchronized successfully!\n
              Check the Farm dropdown in the top left of the screen.`,
            }),
            type: 'success',
            autoClose: 60000,
          });

          // close the integrations pop-up
          dispatch(dialogToggle(HelpersDialogToggle.integrationPlatforms, false));
        } else if (['ready', 'importing'].includes(data.data.status)) {
          setTimeout(() => dispatch(checkAgworldImportStatus(jobId, highestEntity)), 5000); // add 5 seconds delay to not call the endpoint too often
        } else if (data.data.status === 'failed') {
          // if status is not success and processing it means some issue happened (status can be "error" or "unknown id")
          dispatch(AgworldSyncError(`Sync status === ${data.data.status}`, highestEntity));
        }
      })
      .catch(err => dispatch(AgworldSyncError(err, highestEntity)));
  };

const AgworldSyncError = (err: any, highestEntity: string) => (dispatch: AppDispatch) => {
  dispatch({
    type: ActionType.AGWORLD_SYNC_GROWERS,
    syncStatus: RequestStatus.Error,
  });

  reportError(`Agworld sync err, err= ${err}`);

  return showNotification({
    title: t({id: 'note.error', defaultMessage: 'Error'}),
    message: (
      <FormattedMessage
        id="agWorldSyncErr"
        values={{highestEntity, a: (txt: string) => contactSupportTeam(txt)}}
      />
    ),
    type: 'error',
  });
};

export const loadNewFarms =
  (externalService: ExternalService, farmId?: number) => (dispatch: AppDispatch) => {
    UserApi.getProfile().then(({data}) => {
      dispatch(setLogin(data.result)); // update user.groupIds
    });

    const {isWorkspaceMrv} = checkWorkspace();

    dispatch(getFarmsList())
      // @ts-expect-error error leftover from convertion to strict mode, please fix
      .then((data: Farm[]) => {
        if (isWorkspaceMrv) {
          dispatch(toggleMapBar(true));
          const farmsToLoadFields = data
            .filter(farm => (farm.external_service || farm.srcType) === externalService)
            .map(f => f.id);
          dispatch(loadFarmsFieldsWithGeometries(farmsToLoadFields));
        } else {
          const sortedGroupsBySource = sortByStringKey(
            data.filter(farm => (farm.external_service || farm.srcType) === externalService),
            'name'
          );
          const newGroupId = farmId || sortedGroupsBySource?.[0]?.id || data[0].id;
          dispatch(selectFarm(newGroupId));
        }
      })
      .catch((e: any) => {
        reportError(`Error loading Regrow farms during integration sync: ${e}`);
      });
  };

export const syncAgxGrowersV2 =
  (payload: {fieldIds: string[]; farmIds: string[]; growerIds: string[]}) =>
  (dispatch: AppDispatch, getState: () => AppStore) => {
    const mrvProjectId = selectProjectId(getState());

    showNotification({
      title: t({id: 'note.info', defaultMessage: 'Info'}),
      message: t({id: 'agxImportStarted'}),
      type: 'info',
      autoClose: 10000,
    });
    dispatch({
      type: ActionType.AGX_SYNC_GROWERS,
      syncStatus: RequestStatus.Loading,
    });
    return AgxApi.syncV2(payload, mrvProjectId)
      .then(() => {
        dispatch(loadNewFarms(ExternalService.Agx));

        dispatch(agxGrowersSynced());
        dispatch({
          type: ActionType.AGX_TOGGLE_FARMS_IMPORTED,
          farmsImported: true,
        });
      })
      .catch(() => {
        showNotification({
          title: t({id: 'note.error', defaultMessage: 'Error'}),
          message: t({id: 'agxSyncErr'}),
          type: 'error',
          autoClose: 60000,
        });
        dispatch({
          type: ActionType.AGX_SYNC_GROWERS,
          syncStatus: RequestStatus.Error,
        });
      });
  };

export const agxGrowersSynced = () => (dispatch: AppDispatch) => {
  showNotification({
    title: t({id: 'note.success', defaultMessage: 'Success'}),
    message: t({id: 'agxGrowersSyncOk'}),
    type: 'success',
    autoClose: 60000,
  });
  dispatch({
    type: ActionType.AGX_SYNC_GROWERS,
    syncStatus: RequestStatus.Success,
  });
  // close the integrations pop-up
  dispatch(dialogToggle(HelpersDialogToggle.integrationPlatforms, false));
};

// Climate

export const syncClimateFields =
  ({fieldIds, phaseId}: {fieldIds: string[]; phaseId?: number}) =>
  (dispatch: AppDispatch, getState: () => AppStore) => {
    const mrvProjectId = selectProjectId(getState());

    dispatch({
      type: ActionType.CLIMATE_SYNC_FIELDS,
      syncStatus: RequestStatus.Loading,
    });

    ClimateApi.syncFields(fieldIds, mrvProjectId, phaseId)
      .then(({data}: any) => {
        if (data.status === 'accepted' && data.result.job_id) {
          showNotification({
            title: t({id: 'note.info', defaultMessage: 'Info'}),
            message: t({id: 'climateFieldsImportStarted'}),
            type: 'info',
          });
          dispatch(checkClimateStatus(data.result.job_id));
        }
      })
      .catch(err => {
        dispatch(climateSyncError(err));
      });
  };

const checkClimateStatus = (jobId: string) => (dispatch: AppDispatch) => {
  return ClimateApi.getSyncStatus(jobId)
    .then(({data}) => {
      const requestStatus = data.result.status || data.result.data.status;
      if (requestStatus === 'done') {
        const importedFarmId = data?.result?.data?.status_desc?.results?.farms?.[0]?.id;
        dispatch(classifySyncResponseData(data?.result));
        // set request status
        dispatch({
          type: ActionType.CLIMATE_SYNC_FIELDS,
          syncStatus: RequestStatus.Success,
        });
        // load new farms
        dispatch(loadNewFarms(ExternalService.Climate, importedFarmId));
        // show a success message
        showNotification({
          title: t({id: 'note.success', defaultMessage: 'Success'}),
          message: t({id: 'climateFieldsImportOk'}),
          type: 'success',
          autoClose: 60000,
        });

        // close the integrations pop-up
        dispatch(dialogToggle(HelpersDialogToggle.integrationPlatforms, false));
      } else if (requestStatus === 'processing') {
        setTimeout(() => dispatch(checkClimateStatus(jobId)), 5000); // add 5 seconds delay to not call the endpoint too often
      } else {
        // if status is not success and processing it means some issue happened (status can be "error" or "unknown id")
        dispatch(climateSyncError(`Sync status === ${requestStatus}`));
      }
    })
    .catch(err => dispatch(climateSyncError(err)));
};

const climateSyncError = (err: any) => (dispatch: AppDispatch) => {
  dispatch({
    type: ActionType.CLIMATE_SYNC_FIELDS,
    syncStatus: RequestStatus.Error,
  });

  reportError(`Climate sync err, err= ${err}`);

  return showNotification({
    title: t({id: 'note.error', defaultMessage: 'Error'}),
    message: (
      <FormattedMessage
        id="climateFieldsImportErr"
        values={{a: (txt: string) => contactSupportTeam(txt)}}
      />
    ),
    type: 'error',
  });
};

// EFC

export const syncEFCGrowers =
  (payload: EFCSyncRequestPayload) => (dispatch: AppDispatch, getState: () => AppStore) => {
    const mrvProjectId = selectProjectId(getState());

    dispatch({
      type: ActionType.EFC_SYNC_GROWERS,
      syncStatus: RequestStatus.Loading,
    });

    EFCApi.syncGrowers(payload, mrvProjectId)
      .then(({data}) => {
        const highestEntity = classifyHighestEntity(payload);
        if (data.job_id && highestEntity) {
          showNotification({
            title: t({id: 'note.info', defaultMessage: 'Info'}),
            message: t({id: 'efcGrowersImportStarted'}, {highestEntity}),
            type: 'info',
          });
          dispatch(checkEFCStatus(data.job_id, highestEntity));
        }
      })
      .catch(err => {
        dispatch(EFCSyncError(err));
      });
  };

const checkEFCStatus = (jobId: string, highestEntity: string) => (dispatch: AppDispatch) => {
  return EFCApi.getSyncStatus(jobId)
    .then(({data}) => {
      if (data.status === 'done') {
        // set request status
        dispatch({
          type: ActionType.EFC_SYNC_GROWERS,
          syncStatus: RequestStatus.Success,
        });
        // load new farms
        dispatch(loadNewFarms(ExternalService.EFC));
        // show a success message
        showNotification({
          title: t({id: 'note.success', defaultMessage: 'Success'}),
          message: t({id: 'efcGrowersImportOk'}, {highestEntity}),
          type: 'success',
          autoClose: 60000,
        });

        // close the integrations pop-up
        dispatch(dialogToggle(HelpersDialogToggle.integrationPlatforms, false));
      } else if (data.status === 'processing') {
        setTimeout(() => dispatch(checkEFCStatus(jobId, highestEntity)), 5000); // add 5 seconds delay to not call the endpoint too often
      } else {
        // if status is not success and processing it means some issue happened (status can be "error" or "unknown id")
        dispatch(EFCSyncError(`Sync status === ${data.status}`));
      }
    })
    .catch(err => dispatch(EFCSyncError(err)));
};

const EFCSyncError = (err: any) => (dispatch: AppDispatch) => {
  dispatch({
    type: ActionType.EFC_SYNC_GROWERS,
    syncStatus: RequestStatus.Error,
  });

  reportError(`EFC sync err, err= ${err}`);

  return showNotification({
    title: t({id: 'note.error', defaultMessage: 'Error'}),
    message: (
      <FormattedMessage
        id="efcGrowersImportErr"
        values={{a: (txt: string) => contactSupportTeam(txt)}}
      />
    ),
    type: 'error',
  });
};

export const connectEfcUsers = (userIds: number[], selectedPartner: string) => () => {
  return EFCApi.connectUsers(userIds, selectedPartner).catch(() => {
    showNotification({
      title: t({id: 'note.error'}),
      message: t({id: 'errorTryReloadPage'}),
      type: 'error',
    });
    return false;
  });
};

export const disconnectEfcUsers = (userIds: number[]) => () => {
  return EFCApi.disconnectUsers(userIds).catch(() => {
    showNotification({
      title: t({id: 'note.error'}),
      message: t({id: 'errorTryReloadPage'}),
      type: 'error',
    });
    return false;
  });
};

// John Deere

export const syncJohnDeereOrganizations =
  ({payload}: {payload: JohnDeereRequestPayload}) =>
  (dispatch: AppDispatch) => {
    dispatch({
      type: ActionType.JOHN_DEERE_SYNC_GROWERS,
      syncStatus: RequestStatus.Loading,
    });

    const highestEntity = classifyJohnDeereHighestEntity(payload);
    JohnDeereApi.importData(payload)
      .then(({data}: any) => {
        if (data.data.status === 'ready') {
          showNotification({
            title: t({id: 'note.info', defaultMessage: 'Info'}),
            message: t({id: 'johnDeereImportStarted'}, {highestEntity}),
            type: 'info',
          });
          dispatch(checkJohnDeereImportStatus(data.data.id, highestEntity));
        }
      })
      .catch(err => {
        dispatch(JohnDeereSyncError(err, highestEntity));
      });
  };

export const syncJohnDeereOrganizationsForMRV =
  ({
    payload,
    projectId,
    phaseId,
  }: {
    payload: JohnDeereRequestPayload;
    projectId: number;
    phaseId: number;
  }) =>
  (dispatch: AppDispatch) => {
    dispatch({
      type: ActionType.JOHN_DEERE_SYNC_GROWERS,
      syncStatus: RequestStatus.Loading,
    });

    const highestEntity = classifyJohnDeereHighestEntity(payload);
    JohnDeereApi.importDataForMRV(payload, projectId, phaseId)
      .then(({data}: any) => {
        if (data.data.status === 'ready') {
          showNotification({
            title: t({id: 'note.info', defaultMessage: 'Info'}),
            message: t({id: 'johnDeereImportStarted'}, {highestEntity}),
            type: 'info',
          });
          dispatch(checkJohnDeereImportStatus(data.data.id, highestEntity));
        }
      })
      .catch(err => {
        dispatch(JohnDeereSyncError(err, highestEntity));
      });
  };

const checkJohnDeereImportStatus =
  (jobId: number, highestEntity: string) => (dispatch: AppDispatch) => {
    return JohnDeereApi.getImportStatus(jobId)
      .then(({data}: {data: SyncResponseData}) => {
        if (data.data.status === 'succeeded') {
          const importedFarmId = data?.data?.status_desc?.results?.farms?.[0]?.id;
          dispatch(classifySyncResponseData(data));

          // set request status
          dispatch({
            type: ActionType.JOHN_DEERE_SYNC_GROWERS,
            syncStatus: RequestStatus.Success,
          });
          // load new farms
          dispatch(loadNewFarms(ExternalService.JohnDeereLong, importedFarmId));
          // show a success message
          showNotification({
            title: t({id: 'note.success', defaultMessage: 'Success'}),
            message: t({id: 'johnDeereImportOk'}, {highestEntity}),
            type: 'success',
            autoClose: 60000,
          });

          // close the integrations pop-up
          dispatch(dialogToggle(HelpersDialogToggle.integrationPlatforms, false));
        } else if (['ready', 'importing'].includes(data.data.status)) {
          setTimeout(() => dispatch(checkJohnDeereImportStatus(jobId, highestEntity)), 5000); // add 5 seconds delay to not call the endpoint too often
        } else if (data.data.status === 'failed') {
          // if status is not success and processing it means some issue happened (status can be "error" or "unknown id")
          dispatch(JohnDeereSyncError(`Sync status === ${data.data.status}`, highestEntity));
        }
      })
      .catch(err => dispatch(JohnDeereSyncError(err, highestEntity)));
  };

const JohnDeereSyncError = (err: any, highestEntity: string) => (dispatch: AppDispatch) => {
  dispatch({
    type: ActionType.JOHN_DEERE_SYNC_GROWERS,
    syncStatus: RequestStatus.Error,
  });

  reportError(`JD sync err, err= ${err}`);

  return showNotification({
    title: t({id: 'note.error', defaultMessage: 'Error'}),
    message: (
      <FormattedMessage
        id="johnDeereImportErr"
        values={{highestEntity, a: (txt: string) => contactSupportTeam(txt)}}
      />
    ),
    type: 'error',
  });
};

export const getAuthorizedPlatforms = () => (dispatch: AppDispatch) => {
  dispatch(setRequestStatus(AsyncStatusType.authorizedPlatforms, Status.Pending));

  IntegrationsApi.getAuthorizedPlatforms()
    .then(({data}) => {
      dispatch({
        type: ActionType.SET_PRESELECTED_PLATFORMS,
        platforms: data.result,
      });
    })
    .finally(() => dispatch(setRequestStatus(AsyncStatusType.authorizedPlatforms, Status.Done)));
};

// Production wise

export const importPWFarms =
  (payload: PWSyncRequestPayload) => (dispatch: AppDispatch, getState: () => AppStore) => {
    const mrvProjectId = selectProjectId(getState());

    showNotification({
      title: t({id: 'note.info', defaultMessage: 'Info'}),
      message: `Import started.\n It can take up to 5 minutes, depending on the amount of fields being imported.\n Feel free to go and make a cup of coffee ☕ or continue browsing.\n We'll notify you when the import is done.`,
      type: 'info',
      autoClose: 10000,
    });
    dispatch({
      type: ActionType.PW_SYNC_FARMS,
      syncStatus: RequestStatus.Loading,
    });

    PWApi.syncFarms(payload, mrvProjectId)
      .then(({data}: any) => {
        if (data.data.status === 'ready') {
          dispatch(checkPWImportStatus(data.data.id));
        }
      })
      .catch(e => {
        reportError(`Couldn't sync ProductionWise farms. Error: ${e}`);
        showNotification({
          title: t({id: 'note.error', defaultMessage: 'Error'}),
          message: "Couldn't sync ProductionWise farms.",
          type: 'error',
          autoClose: 60000,
        });
        dispatch({
          type: ActionType.PW_SYNC_FARMS,
          syncStatus: RequestStatus.Error,
        });
      });
  };

const checkPWImportStatus = (jobId: number) => (dispatch: AppDispatch) => {
  return PWApi.getImportStatus(jobId)
    .then(({data}) => {
      if (data.data.status === 'succeeded') {
        // set request status
        dispatch({
          type: ActionType.PW_SYNC_FARMS,
          syncStatus: RequestStatus.Success,
        });
        // load new farms
        dispatch(loadNewFarms(ExternalService.ProductionWise));
        // show a success message
        showNotification({
          title: t({id: 'note.success', defaultMessage: 'Success'}),
          message: `ProductionWise farms synchronized successfully!\n
            Check the Farm dropdown in the top left of the screen.`,
          type: 'success',
          autoClose: 60000,
        });

        // close the integrations pop-up
        dispatch(dialogToggle(HelpersDialogToggle.integrationPlatforms, false));
      } else if (['ready', 'importing'].includes(data.data.status)) {
        setTimeout(() => dispatch(checkPWImportStatus(jobId)), 5000); // add 5 seconds delay to not call the endpoint too often
      } else if (data.data.status === 'failed') {
        // if status is not success and processing it means some issue happened (status can be "error" or "unknown id")
        dispatch(PWSyncError(`Sync status === ${data.data.status}`));
      }
    })
    .catch(err => dispatch(PWSyncError(err)));
};

const PWSyncError = (err: any) => (dispatch: AppDispatch) => {
  dispatch({
    type: ActionType.PW_SYNC_FARMS,
    syncStatus: RequestStatus.Error,
  });

  reportError(`ProductionWise sync err, err= ${err}`);

  return showNotification({
    title: t({id: 'note.error', defaultMessage: 'Error'}),
    message: (
      <FormattedMessage
        id="Couldn't sync ProductionWise data"
        defaultMessage="Couldn't sync ProductionWise data"
        values={{a: (txt: string) => contactSupportTeam(txt)}}
      />
    ),
    type: 'error',
  });
};

// FarmLogs

export const importFarmlogsFields = (fieldIds: number[]) => (dispatch: AppDispatch) => {
  showNotification({
    title: t({id: 'note.info', defaultMessage: 'Info'}),
    message: syncProcessStartedInfoText(ExternalServiceLabels[ExternalService.FarmLogs]),
    type: 'info',
  });

  dispatch({
    type: ActionType.FARMLOGS_SYNC_FIELDS,
    syncStatus: RequestStatus.Loading,
  });

  FarmlogsApi.importFields(fieldIds)
    .then(({data}) => {
      dispatch(checkFarmLogsImportStatus(data.data.id));
    })
    .catch(e => dispatch(FarmlogsSyncError(e)));
};

const checkFarmLogsImportStatus = (jobId: number) => (dispatch: AppDispatch) => {
  return FarmlogsApi.getImportStatus(jobId)
    .then(({data}) => {
      if (data.data.status === 'succeeded') {
        // set request status
        dispatch({
          type: ActionType.FARMLOGS_SYNC_FIELDS,
          syncStatus: RequestStatus.Success,
        });
        // load new farms
        dispatch(loadNewFarms(ExternalService.FarmLogs));

        // show a success message
        showNotification({
          title: t({id: 'note.success', defaultMessage: 'Success'}),
          message: t({id: 'Fields successfully imported.'}),
          type: 'success',
        });

        // close the integrations pop-up
        dispatch(dialogToggle(HelpersDialogToggle.integrationPlatforms, false));
      } else if (['ready', 'importing'].includes(data.data.status)) {
        setTimeout(() => dispatch(checkFarmLogsImportStatus(jobId)), 5000); // add 5 seconds delay to not call the endpoint too often
      } else if (data.data.status === 'failed') {
        // if status is not success and processing it means some issue happened (status can be "error" or "unknown id")
        dispatch(FarmlogsSyncError(`Sync status === ${data.data.status}`));
      }
    })
    .catch(err => dispatch(FarmlogsSyncError(err)));
};

const FarmlogsSyncError = (err: any) => (dispatch: AppDispatch) => {
  reportError(`Couldn't sync FarmLogs farms. Error: ${err}`);
  showNotification({
    title: t({id: 'note.error', defaultMessage: 'Error'}),
    message: t({id: "Couldn't sync FarmLogs fields."}),
    type: 'error',
    autoClose: 60000,
  });
  dispatch({
    type: ActionType.FARMLOGS_SYNC_FIELDS,
    syncStatus: RequestStatus.Error,
  });
};
