import type {PropsWithChildren} from 'react';
import React from 'react';
import {RawIntlProvider} from 'react-intl';
import {useLocale} from './i18n-utils';

export const TranslationsProvider = ({children}: PropsWithChildren<{}>) => {
  const {intl} = useLocale();

  return <RawIntlProvider value={intl}>{children}</RawIntlProvider>;
};
