import type {ComponentType} from 'react';
import React, {useContext} from 'react';
import type {TextFieldTypes} from 'react-md';
import {TextField} from 'react-md';
import {InputContext} from './input-context';

type Props = {
  type?: TextFieldTypes;
  onChange: (value: number | string) => void;
  disabled?: boolean;
};

export const InputText: ComponentType<Props> = ({type, onChange, disabled}) => {
  const {id, value = '', label, placeholder, disabled: attrDisabled} = useContext(InputContext);

  return (
    <TextField
      type={type}
      id={id}
      value={value}
      label={label}
      placeholder={placeholder}
      disabled={disabled || attrDisabled}
      onChange={v => onChange(v)}
    />
  );
};
