import React from 'react';
import {FluroDialog, InfoBlock, Ln} from 'components';
import {useAppDispatch} from '_hooks';
import {dialogToggle, DialogType} from 'modules/helpers';

const NotAllowedGeometriesToExportToAgx = () => {
  const dispatch = useAppDispatch();

  return (
    <FluroDialog
      id={'not-allow-export-to-agx'}
      title={'Export to agX - PREMIUM Subscription only'}
      visible={true}
      onHide={() => dispatch(dialogToggle(DialogType.geometriesNotAllowedToExportToAgx, false))}
      className={'not-allow-export-to-agx'}
      portal
      width={450}
    >
      <InfoBlock className={'inside-a-pop-up'}>
        This low performing area is generated as an example of crop stress detection in a Basic
        subscription, and can not be exported to agX. For continuous crop stress monitoring and
        export to partner platforms, please, activate{' '}
        <Ln
          blank
          external
          href={'https://help.flurosense.com/en/articles/4484912-crop-stress-detection'}
        >
          Automated Crop Stress detection{' '}
        </Ln>
        (paid feature). To enable it, contact us using the chat button below or email us at
        <a href={'mailto:support@regrow.ag'}>support@regrow.ag.</a>
      </InfoBlock>
    </FluroDialog>
  );
};

export default NotAllowedGeometriesToExportToAgx;
