import {schema} from 'normalizr';
import type {SIStateMeta} from './types';

export const CountySchema = new schema.Entity(
  'county',
  {},
  {
    idAttribute: c => c.county_fips || c.fips_code,
    processStrategy(county, state) {
      const {id} = state;
      const {county_fips, fips_code, county_name, name} = county;

      return {statefp: id, id: county_fips || fips_code, name: county_name || name};
    },
  }
);

export const CRDSchema = new schema.Entity(
  'crd',
  {},
  {
    idAttribute: crd => crd.crd_id || crd.id,
    processStrategy(crd, state) {
      const {id: statefp} = state;
      const {crd_id, id, crd_name, name} = crd;

      return {statefp: statefp, id: crd_id || id, name: `CRD ${crd_name || name}`};
    },
  }
);

export const HUC8Schema = new schema.Entity(
  'huc8',
  {},
  {
    idAttribute: huc => huc.huc8_id || huc.id,
    processStrategy(huc8, state) {
      const {id: statefp} = state;
      const {huc8_id, id, huc8_name, name} = huc8;

      return {statefp, id: huc8_id || id, name: huc8_name || name};
    },
  }
);

export const HUC10Schema = new schema.Entity(
  'huc10',
  {},
  {
    idAttribute: huc => huc.huc10_id || huc.id,
    processStrategy(huc10, state) {
      const {id: statefp} = state;
      const {huc10_id, id, huc10_name, name} = huc10;

      return {statefp, id: huc10_id || id, name: huc10_name || name};
    },
  }
);

export const HUC12Schema = new schema.Entity(
  'huc12',
  {},
  {
    idAttribute: huc => huc.huc12_id || huc.id,
    processStrategy(huc12, state) {
      const {id: statefp} = state;
      const {huc12_id, id, huc12_name, name} = huc12;

      return {statefp, id: huc12_id || id, name: huc12_name || name};
    },
  }
);

export const StateSchema = new schema.Entity(
  'state',
  {
    crd: [CRDSchema],
    county: [CountySchema],
    huc8: [HUC8Schema],
    huc10: [HUC10Schema],
    huc12: [HUC12Schema],
  },
  {
    idAttribute: s => s.statefp || s.fips_code,
    processStrategy(state) {
      const {statefp, fips_code, state_name, name, counties, crds, huc8s, huc10s, huc12s, ...rest} =
        state;
      const transformedState: SIStateMeta = {
        id: statefp || fips_code,
        name: name || state_name,
      };

      if (crds) transformedState.crd = crds;
      if (counties) transformedState.county = counties;
      if (huc8s) transformedState.huc8 = huc8s;
      if (huc10s) transformedState.huc10 = huc10s;
      if (huc12s) transformedState.huc12 = huc12s;

      return {
        ...transformedState,
        ...rest,
      };
    },
  }
);

export const StatesResponseSchema = new schema.Object({
  states: [StateSchema],
});
