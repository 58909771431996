import {fetchUserPermissions} from 'containers/mrv/monitoring/module/thunks';
import {MrvUserProfileProjectSettings} from 'containers/mrv/mrv-user-profile-project-settings';
import React, {useEffect} from 'react';
import {Redirect, useLocation} from 'react-router-dom';
import {useAppDispatch, useAppSelector} from '_hooks';
import {useWorkspace} from '_hooks/use-workspace';
import './index.scss';
import Effects from './profile-effects';
import {ProfileSettings} from './profile-settings';
import {FluroDialog} from 'components';
import {closeProfileDialog, openProfileDialog} from 'containers/profile/actions';
import {dialogToggle, DialogType} from 'modules/helpers';
import {selectDialogVisibility} from '../../modules/helpers/selectors';

export const Profile = () => {
  const dispatch = useAppDispatch();
  const {isWorkspaceMrv} = useWorkspace();

  useEffect(() => {
    if (isWorkspaceMrv) {
      dispatch(fetchUserPermissions());
    }
  }, []);

  return (
    <FluroDialog
      id="profile-dialog"
      className={'profile-dialog'}
      visible
      onHide={() => dispatch(closeProfileDialog())}
    >
      <div className="user-settings-container">
        <Effects />

        <div className="user-settings-content">
          <ProfileSettings />
          {isWorkspaceMrv && <MrvUserProfileProjectSettings />}
        </div>
      </div>
    </FluroDialog>
  );
};

export const RedirectFromProfilePageToProfileDialog = () => {
  const {workspaceLink} = useWorkspace();
  const dispatch = useAppDispatch();
  const profileDialogIsOpen = useAppSelector(s => selectDialogVisibility(s, DialogType.profile));
  const integrationsDialogIsOpen = useAppSelector(s =>
    selectDialogVisibility(s, DialogType.integrationPlatforms)
  );
  const location = useLocation();

  const shouldDisplayIntegrations = location.pathname.includes('profile/integration');

  useEffect(() => {
    if (!shouldDisplayIntegrations && !profileDialogIsOpen) {
      dispatch(openProfileDialog());
    } else if (shouldDisplayIntegrations && !integrationsDialogIsOpen) {
      dispatch(dialogToggle(DialogType.integrationPlatforms, true));
    }
  }, [dispatch, profileDialogIsOpen, shouldDisplayIntegrations]);

  // as other temporary redirects can be removed in a year, 02.03.2024;
  return <Redirect to={workspaceLink} />;
};

export default Profile;
