import React, {useState} from 'react';
import '../index.scss';
import {AdminContainer, AdminControls} from '../../admin.styled';
import {FluroTabs} from 'components';
import {tagAliases, Tags} from 'containers/admin/features/types';

import {AsyncStatusType} from 'modules/helpers';
import AdminTitleWithLoader from '../../shared/title-with-loader';
import SITagRepresentative from './SI-tag-representative';
import RegularTagsRepresentative from './regular-tags-representative';
import './premium-apps.scss';

export const TABS: {value: Tags; label: string}[] = [
  {
    value: Tags.SustainabilityInsights,
    label: tagAliases[Tags.SustainabilityInsights],
  },
  {
    value: Tags.NRx,
    label: tagAliases[Tags.NRx],
  },
  {
    value: Tags.CropStress,
    label: tagAliases[Tags.CropStress],
  },
  {
    value: Tags.TreeAnalysis,
    label: tagAliases[Tags.TreeAnalysis],
  },
];

const PremiumApps = () => {
  const [tabValue, setTabValue] = useState(Tags.SustainabilityInsights);

  return (
    <AdminContainer>
      <AdminControls
        justifyContent={'flex-start'}
        alignItems={'center'}
        className="features__search-form"
      >
        <AdminTitleWithLoader title={'Premium apps'} statusKey={AsyncStatusType.adminSearch} />

        <FluroTabs
          containerClassName={'top-positioned'}
          onTabClick={(value: Tags) => {
            setTabValue(value);
          }}
          selectedTab={tabValue}
          tabs={TABS}
        />
      </AdminControls>

      {tabValue === Tags.SustainabilityInsights ? (
        <SITagRepresentative />
      ) : (
        <RegularTagsRepresentative tabValue={tabValue} />
      )}
    </AdminContainer>
  );
};

export default PremiumApps;
