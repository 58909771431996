import {hot} from 'react-hot-loader/root';
import React from 'react';
import {Route, Switch, Redirect} from 'react-router-dom';
import {ConnectedRouter} from 'connected-react-router';
import store from './store';
import {history} from '_utils/history-utils';
import ErrorBoundary from 'containers/error-boundary';
import DemoFarmsDialog from 'containers/dialogs/demo-farms';
import useAppAutoUpdater from './use-app-auto-updater';
import {TranslationsProvider} from './translations-provider';
import {Provider} from 'react-redux';
import {CARBON, CI_ROOT_PATH, CI_ROOT_PATH_OLD, isLocal, MRV, SI} from '_environment';
import MRVEntry from 'containers/mrv/mrv-entry';
import SIEntry from 'containers/si/si-entry';
import {NotFound, Notification, FluroTooltip} from 'components';
import CommonDialogs from 'components/common-dialogs';
import {
  BootAuthContainer,
  BaseLayout,
  PrivateRoute,
  BasicAllocation,
  InfoDialog,
  ScrollToTop,
} from 'containers';
import 'normalize.css';
import IntercomComponent from './components/intercom/intercom';
import {useHubSpotChatInit} from '_hooks/use-hubspot-chat-init';
import {useAppSelector} from './_hooks';
import {selectIsAuth} from './containers/login/login-selectors';
import {getRegenConnectState} from 'containers/mrv/regenconnect-landing/regenconnect-landing.utils';
import CIEntry from 'containers/map/crop-insights-entry';
import {ThemeProvider} from 'styled-components';
// eslint-disable-next-line no-restricted-imports -- this is the only case where we should import `theme` directly
import {theme} from '@regrow-internal/design-system';
import {CI_FIELD_BY_MD5, CI_HOME} from 'containers/map/routes';
import {isRegrowDomain} from '_utils/pure-utils';
import {RedirectFromProfilePageToProfileDialog} from 'containers/profile/index';

// if (process.env.NODE_ENV === 'development') {
//   const whyDidYouRender = require('@welldone-software/why-did-you-render');
//   whyDidYouRender(React, {
//     trackAllPureComponents: true,
//   });
// }

// shared paths are extracted to the enum
export enum AppPath {
  Login = '/(mrv)?/login',
  SignUp = '/(mrv)?/sign-up/:uuid?/:from?',
  ResetPassword = '/(mrv)?/reset-password/',
  ResetPasswordConfirm = '/(mrv)?/reset-password/:uuid',
  ProductionWiseLand = '/pw-land',
}

const isRegenConnect = getRegenConnectState();

const Base = () => {
  const isAuth = useAppSelector(selectIsAuth);
  useAppAutoUpdater();
  useHubSpotChatInit();

  return (
    <>
      <BaseLayout>
        <Switch>
          {isRegenConnect && (
            <Route
              path="/"
              component={React.lazy(() => import('containers/mrv/regenconnect-landing'))}
            />
          )}

          {isRegenConnect && (
            <Route
              path="/en"
              component={React.lazy(() => import('containers/mrv/regenconnect-landing'))}
            />
          )}

          {/* remove the /app prefix from legacy CI links to the new one, remove in a year - 24.02.2024 */}
          {isRegrowDomain() || isLocal ? (
            <Route
              path={`/app`}
              render={props => (
                <Redirect
                  to={props.location.pathname.replace('/app', '') + props.location.search}
                />
              )}
            />
          ) : null}
          {/* redirect from legacy CI links to the new one, remove in a year - 24.02.2024 */}
          <Route
            path={`/${CI_ROOT_PATH_OLD}`}
            render={props => (
              <Redirect
                to={
                  props.location.pathname.replace(CI_ROOT_PATH_OLD, CI_ROOT_PATH) +
                  props.location.search
                }
              />
            )}
          />
          <PrivateRoute exact path="/" component={BasicAllocation} />
          <Route
            path={AppPath.Login}
            component={React.lazy(() => import('containers/app-index-page'))}
          />

          <Route
            path={AppPath.ProductionWiseLand}
            component={React.lazy(() => import('containers/login/pw-land'))}
          />

          <Route
            path="/oauth/:source/:code"
            component={React.lazy(() => import('containers/oauth'))}
          />
          <Route
            path={AppPath.SignUp}
            component={React.lazy(() => import('containers/app-index-page'))}
          />

          <Route
            path="/sign-up-confirmation/:uuid"
            component={React.lazy(() => import('containers/sign-up-confirmation'))}
          />

          <Route
            path={AppPath.ResetPasswordConfirm}
            component={React.lazy(() => import('containers/reset-password/reset-password-confirm'))}
          />

          <Route
            path={AppPath.ResetPassword}
            component={React.lazy(() => import('containers/reset-password/reset-password'))}
          />

          <Route
            path="/farm-invite/:uuid"
            component={React.lazy(() => import('containers/farm/accept-farm-invite'))}
          />

          <PrivateRoute
            path={CI_FIELD_BY_MD5}
            isAdminOnly
            component={React.lazy(() => import('containers/admin/goto-field-by-md5'))}
          />

          <PrivateRoute path={CI_HOME} component={CIEntry} />

          <PrivateRoute
            path="/reportV2"
            component={React.lazy(() => import('containers/reportV2/reportV2'))}
          />

          <Route path={`/${CARBON}`} component={MRVEntry} />

          <Route path={`/${MRV}`} component={MRVEntry} />

          <Route path={`/${SI}`} component={SIEntry} />

          <PrivateRoute
            isAdminOnly
            path="/admin"
            component={React.lazy(() => import('containers/admin'))}
          />

          <Route path="/profile" component={RedirectFromProfilePageToProfileDialog} />

          <Route
            path="/privacypolicy"
            component={React.lazy(() => import('containers/privacypolicy/privacypolicy'))}
          />

          <Route component={NotFound} />
        </Switch>

        {isAuth && <InfoDialog />}

        <CommonDialogs />
        <DemoFarmsDialog />
      </BaseLayout>
      <FluroTooltip />
      <Notification /> {/* moved it here to prevent showing old messages after login/logout*/}
    </>
  );
};

const App = () => {
  return (
    <Provider store={store}>
      <ErrorBoundary>
        <React.Suspense fallback={<></>}>
          <ConnectedRouter history={history}>
            <BootAuthContainer>
              <IntercomComponent>
                <TranslationsProvider>
                  <ScrollToTop>
                    <ThemeProvider theme={theme}>
                      <Base />
                    </ThemeProvider>
                  </ScrollToTop>
                </TranslationsProvider>
              </IntercomComponent>
            </BootAuthContainer>
          </ConnectedRouter>
        </React.Suspense>
      </ErrorBoundary>
    </Provider>
  );
};

export default hot(App);
