import React, {useEffect, useRef, useState} from 'react';
import {Text} from 'components';
import {Collapsible} from 'components/collapsible';
import InfoBlock from 'components/info-block';
import {selectDNDC} from 'containers/mrv/enrollment/carbon-store/selectors';
import {selectCurrentProgram} from 'containers/mrv/monitoring/module/selectors';
import {DNDCStatus, MRVProgramUnits} from 'containers/mrv/types';
import styled from 'styled-components';
import {useAppThunkDispatch, useAppSelector} from '_hooks';
import {getDNDCResults} from 'containers/mrv/enrollment/carbon-store/carbon-thunks';
import {useParsedMatchParams} from '_hooks/use-parsed-match-params';
import {convertUnit} from '_utils';
import {kiloFormatter} from '_utils/number-formatters';
import {FormattedMessage} from 'react-intl';
import {t} from 'i18n-utils';
import {selectMeasurement} from 'containers/login/login-selectors';
import {PaymentFor} from '_api/mrv.types';
import type {DNDCResultsDetailedItem} from 'containers/mrv/enrollment/carbon-store';

export const OutcomeCalculation = ({phaseId}: {phaseId?: number}) => {
  const dispatch = useAppThunkDispatch();
  const program = useAppSelector(selectCurrentProgram);
  const {projectId} = useParsedMatchParams();
  const dndc = useAppSelector(selectDNDC);

  const shouldGetDNDCRef = useRef(true);
  const measurement = useAppSelector(selectMeasurement);
  const [loadingDNDCResults, setLoadingDNDCResults] = useState(false);
  const [outcomeCollapseIsOpen, setOutcomeCollapseIsOpen] = useState(false);

  useEffect(() => {
    const fetchDNDCResults = async () => {
      if (
        !shouldGetDNDCRef.current ||
        !projectId ||
        !phaseId ||
        dndc.tonsSequestered ||
        dndc.status !== DNDCStatus.Success
      ) {
        return;
      }

      setLoadingDNDCResults(true);
      await dispatch(getDNDCResults({projectId, phaseId}));
      setLoadingDNDCResults(false);
      shouldGetDNDCRef.current = false;
    };

    fetchDNDCResults();
  }, [dispatch, dndc.status, dndc.tonsSequestered, phaseId, projectId]);

  const carbonSequesteredGHGOutcome = dndc?.detailedItems?.find(
    item => item.units === PaymentFor.TONS_CO2E
  );
  const enrolledFieldAreaOutcomes = dndc?.detailedItems?.filter(
    item => item.units !== PaymentFor.TONS_CO2E
  );
  const totalAreaEnrolled = enrolledFieldAreaOutcomes?.reduce(
    (sum, outcome) => sum + outcome.field_area,
    0
  );
  const payment_for: DNDCResultsDetailedItem['units'] | undefined =
    dndc?.detailedItems?.find(item => item.units === 'Tons CO2e')?.units === 'Tons CO2e'
      ? PaymentFor.TONS_CO2E
      : PaymentFor.HECTARES;

  return (
    <StyledInfoBlock
      bordered
      color="gray"
      $dndcIncomplete={dndc.status !== DNDCStatus.Success}
      fullWidth
      hideIcon
      mini
    >
      {carbonSequesteredGHGOutcome && (
        <Calculation>
          <span>
            <FormattedMessage
              id="CarbonSequesteredGHG"
              defaultMessage="Carbon sequestered {br} and net GHG reductions"
              values={{br: <br />}}
            />
          </span>
          <Value>
            <strong>{kiloFormatter(dndc.tonsSequestered)} T</strong> CO
            <sub>2</sub>eq
          </Value>
        </Calculation>
      )}

      {enrolledFieldAreaOutcomes && enrolledFieldAreaOutcomes.length > 0 && (
        <Calculation>
          <span>
            <FormattedMessage
              id="TotalAreaEnrolled"
              defaultMessage={t({id: 'Total area enrolled'})}
              values={{br: <br />}}
            />
          </span>

          <Value>
            <strong>
              {convertUnit(measurement, 'ac', totalAreaEnrolled)} {measurement}
            </strong>
          </Value>
        </Calculation>
      )}

      <Calculation>
        <span>{t({id: 'Outcomes payment'})}</span>
        <Value>
          {program?.currency_char}{' '}
          <strong>
            {dndc.status !== DNDCStatus.Success || loadingDNDCResults
              ? '-'
              : kiloFormatter(dndc.payment, 2) || '-'}
          </strong>
        </Value>
      </Calculation>

      <Collapsible
        id="outcome-calculation__collapsible"
        style={{marginTop: '10px'}}
        title={
          <Text elementType="p" variant="small" inline secondary>
            {t({id: 'How is this calculated?'})}
          </Text>
        }
        onToggle={() => setOutcomeCollapseIsOpen(state => !state)}
        isOpen={outcomeCollapseIsOpen}
        noBorder
      >
        <FormattedMessage
          id="Enroll.HowIsItCalculatedV2"
          defaultMessage="<text1>Calculated using {programName} pricing structure of {currencyType} {carbonPrice} per {measurementUnit}. </text1>
          <text2>Above is an estimation - the final amount will be based on the measurement and verification process conducted before the end of the contract period.</text2>"
          values={{
            text1: (msg: string) => (
              <Text style={{marginTop: '8px'}} elementType="p" variant="small" inline secondary>
                {msg}
              </Text>
            ),
            text2: (msg: string) => (
              <Text style={{margin: 0, fontSize: '12px'}} secondary>
                {msg}
              </Text>
            ),
            span: (msg: string) => (
              <span style={{fontSize: '12px', whiteSpace: 'nowrap'}}>{msg}</span>
            ),
            currencyType: program?.currency_char,
            carbonPrice: program?.carbon_price || ' -',
            programName: program?.name,
            measurementUnit:
              payment_for === PaymentFor.HECTARES
                ? program?.units === MRVProgramUnits.METRIC
                  ? 'ha'
                  : 'ac'
                : PaymentFor.TONS_CO2E,
          }}
        />
      </Collapsible>
    </StyledInfoBlock>
  );
};

const StyledInfoBlock = styled(InfoBlock)<{
  $dndcIncomplete: boolean;
}>`
  opacity: ${({$dndcIncomplete}) => ($dndcIncomplete ? '0.5' : 'initial')};
`;

const Value = styled.span`
  color: ${props => props.theme.color.text.success};
  margin-left: auto;
  font-weight: bold;
  font-size: 16px;

  strong {
    font-size: larger;
  }
`;

const Calculation = styled.div`
  display: flex;
  align-items: center;

  & + & {
    margin-top: 10px;
  }
`;
