import {SubscriptionEntityType} from './subscriptions.types';

export const getFilterKey = (key: SubscriptionEntityType) => {
  switch (key) {
    case SubscriptionEntityType.Subscription:
      return 'subscriptionsSubId';
    case SubscriptionEntityType.Organization:
      return 'subscriptionsOrgId';
    case SubscriptionEntityType.Farm:
      return 'subscriptionsFarmId';
  }
};

export const applyFilters = (
  entities: any[],
  levelTitle: SubscriptionEntityType,
  filters: any,
  filterKey: string,
  levelLoadId?: number
) => {
  if (!levelLoadId && filters[filterKey]) {
    return entities.filter(e => {
      return (
        e.id === filters[filterKey] ||
        (typeof filters[filterKey] === 'string' &&
          (e.name + '').toLowerCase().includes((filters[filterKey] + '').toLowerCase()))
      );
    });
  }

  return entities;
};
