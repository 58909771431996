// @ts-nocheck
import {useEffect} from 'react';
import {getGetURLParam, setGetParamToURL} from '_utils/pure-utils';
import {setZoningTab} from 'containers/map/actions/zoning-actions';
import {
  toggleNRecommendationMethod,
  updateRecommendationDate,
} from 'containers/map/actions/nitrogen-recommendation-actions';
import moment from 'moment';
import {GLOBAL_FORMAT_DATE} from '_constants';
import type {NRecommendationMethod} from 'containers/map/types';
import {ZoningTab} from 'containers/map/types';
import {useAppDispatch} from '_hooks';

type Props = {
  calculatedMethod: NRecommendationMethod;
  isNRXRecommendationAllowed: boolean;
  recommendationDates: string[];
  lastAvailableRecommendationDate: string;
  recommendationDate: string;
  currentMethod: string;
  nRecommendationToggled: boolean;
};

const URLParamsEffect = ({
  recommendationDates,
  isNRXRecommendationAllowed,
  calculatedMethod,
  lastAvailableRecommendationDate,
  recommendationDate,
  currentMethod,
  nRecommendationToggled,
}: Props): null => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (currentMethod && currentMethod !== 'apsim') {
      setGetParamToURL('nrx-date', null);
    }

    if (isNRXRecommendationAllowed) {
      const isOn = getGetURLParam('nrx-toggle') === 'true';
      const dateParam = getGetURLParam('nrx-date');
      const date = moment(dateParam, GLOBAL_FORMAT_DATE);

      dispatch(toggleNRecommendationMethod(calculatedMethod));

      if (recommendationDate && recommendationDates.includes(recommendationDate)) return;

      if (
        date &&
        date.isValid() &&
        recommendationDates.includes(dateParam) &&
        recommendationDate !== dateParam
      ) {
        dispatch(updateRecommendationDate(dateParam));
      } else {
        lastAvailableRecommendationDate && setGetParamToURL('nrx-date', recommendationDate);
      }
      if (!nRecommendationToggled && isOn) {
        dispatch(setZoningTab(isOn ? ZoningTab.NitrogenRx : ZoningTab.MainZoning));
      }
    } else {
      setGetParamToURL('nrx-toggle', null);
      setGetParamToURL('nrx-date', null);
    }
  }, [
    calculatedMethod,
    isNRXRecommendationAllowed,
    lastAvailableRecommendationDate,
    recommendationDate,
    recommendationDates,
    currentMethod,
    nRecommendationToggled,
  ]);

  return null;
};

export default URLParamsEffect;
