import {Flex, FluroButton} from 'components';
import {FluroChipDropdownControllable as FluroChipDropdown} from 'components/fluro-dropdown/fluro-chip-dropdown';
import uniq from 'lodash/uniq';
import type {ComponentType} from 'react';
import React, {useState} from 'react';
import {FontIcon, List, ListItem, SelectField, SelectionControl} from 'react-md';
import {useDispatch} from 'react-redux';
import {useAppSelector, useAppDispatch} from '_hooks';
import {setFilterYears} from 'modules/sustainability-insights/actions';
import {selectAvailableYears, selectYearsFilter} from 'modules/sustainability-insights/selectors';
import {toggleTooltip} from 'modules/global/actions';
import styled from 'styled-components';

type YearsInputProps = {
  defaultValue: number[];
  yearsAvailable: number[];
  onChange: (range: number[]) => void;
};

const YearsInput: ComponentType<YearsInputProps> = ({
  defaultValue = [],
  onChange,
  yearsAvailable,
}) => {
  const rangeAvailable = yearsAvailable?.length > 1;
  const [enableRange, setEnableRange] = useState(
    rangeAvailable && Number.isFinite(defaultValue[0]) && Number.isFinite(defaultValue[1])
  );
  const [value, setValue] = useState<number[]>(defaultValue);

  const handleChange = () => {
    const update = uniq(value.filter(Boolean));
    onChange(update);
  };

  const [yearFrom, yearTo] = value;
  const input = enableRange ? (
    <>
      <Flex justifyContent="space-between" className="mb-2" nowrap>
        <SelectField
          id="years-select-year-from"
          name="years-select-year-from"
          menuItems={yearsAvailable}
          value={yearFrom}
          onChange={year => {
            const newValue = [yearFrom, yearTo || yearFrom];
            if (newValue[1] < year) {
              setValue([year as number, year as number]);
            } else {
              newValue[0] = year as number;
              setValue(newValue);
            }
          }}
        />
        <SelectField
          key={yearFrom}
          id="years-select-min-year-to"
          name="years-select-min-year-to"
          menuItems={yearsAvailable.filter(year => year >= yearFrom)}
          value={yearTo || yearFrom}
          onChange={year => {
            const newValue = [...value];
            newValue[1] = year as number;
            setValue(newValue);
          }}
        />
      </Flex>
      <Flex justifyContent="center">
        <FluroButton flat onClick={handleChange}>
          APPLY
        </FluroButton>
      </Flex>
    </>
  ) : (
    <List>
      {yearsAvailable.map(y => {
        const isActive = [yearFrom, yearTo].includes(y);

        return (
          <ListItem
            key={y}
            primaryText={y}
            primaryTextStyle={{color: isActive ? 'green' : 'black'}}
            role="button"
            onClick={() => {
              onChange([y]);
            }}
          />
        );
      })}
    </List>
  );

  return (
    <>
      {rangeAvailable && (
        <SelectionControl
          type="switch"
          id="range-switch"
          label="DATE RANGE"
          defaultChecked={enableRange}
          name="range-switch"
          onChange={checked => {
            if (!checked) setValue([yearFrom]);
            setEnableRange(checked as boolean);
          }}
        />
      )}
      {input}
    </>
  );
};

export const YearsSelect: ComponentType<{}> = () => {
  const [expand, setExpand] = useState(false);
  const dispatch = useDispatch();
  const years = useAppSelector(selectYearsFilter);
  const yearsAvailable = useAppSelector(selectAvailableYears);
  const appDispatch = useAppDispatch();

  const handleChange = (years: number[]) => {
    setExpand(false);
    dispatch(setFilterYears(years));
  };

  if (!years) return null;
  const btnLabel = years.filter(Boolean).join(' - ');

  const [yearFrom, yearTo] = years;

  const showTooltip = () => {
    appDispatch(
      toggleTooltip({
        id: 'years-select',
        place: 'top',
        content: (
          <>
            <p>
              A crop year is defined as <NoWrap>1 November</NoWrap> of the previous year through{' '}
              <NoWrap>31 October</NoWrap> of the crop year.
            </p>
            <p>
              For example, the 2020 crop year extends from <NoWrap>1 November 2019</NoWrap> through{' '}
              <NoWrap>31 October 2020</NoWrap>.
            </p>
          </>
        ),
      })
    );
  };

  return (
    // use 6px to ensure the years crop types selectors don't wrap when range of years and some crops are selected
    <Flex alignItems="center" gap={'6px'}>
      <Flex alignItems="center">
        Crop year(s)
        <FontIcon data-tip="" data-for="years-select" onMouseEnter={showTooltip}>
          help_outline
        </FontIcon>
      </Flex>
      <FluroChipDropdown
        expand={expand}
        setExpand={setExpand}
        label={btnLabel}
        dropdownStyle={{overflow: 'visible'}}
      >
        <YearsInput
          yearsAvailable={yearsAvailable}
          defaultValue={[yearFrom, yearTo]}
          onChange={handleChange}
        />
      </FluroChipDropdown>
    </Flex>
  );
};

const NoWrap = styled.span`
  white-space: nowrap;
`;
