// @ts-nocheck
import {t} from 'i18n-utils';
import type {CropPerformanceState, CSGViewModel} from '../types';
import {getCPSeasonDataByRecord} from '../helpers';
import {sortByKey} from '_utils/sorters';
import {getTheNextColorFromPredefinedList} from '_utils';
import {toFixedFloatUnsafe} from '_utils/number-formatters';
import moment from 'moment';
import type Chart from 'chart.js';
import type {ChartDataSets, ChartOptions, ChartPoint} from 'chart.js';
import type {CPFilterState} from '_reducers/crop-performance-filter/field-filter-types';
import {CPFilterType} from '_reducers/crop-performance-filter/field-filter-types';
import {showNotification} from 'components/notification/notification';

export type UpdatedDataSet = ChartDataSets & {
  seasonId: number;
  backgroundColor: string;
  cropType: string;
  cropVariety: string;
  seasonName: string;
  fieldName: string;
};

export const detectRecordsGroupTitle = (index: number, dataSets: any[]) => {
  switch (true) {
    case dataSets.length > 1 && index === 0:
      return t({id: 'Best performing'});

    case dataSets.length === 1:
    case dataSets.length === 2 && index === 1:
    case dataSets.length === 3 && index === 1:
    case dataSets.length > 3 && index === 2:
      return t({id: 'Worst performing'});

    default:
      return '';
  }
};

export const classifyDataSets = (
  labels: string[],
  records: CSGViewModel[],
  cropPerformance: CropPerformanceState,
  cropPerformanceFilter: CPFilterState
): UpdatedDataSet[] => {
  const result: UpdatedDataSet[] = [];
  if (!labels.length || !records.length) return result; // early return if some required data is missing
  const recordIds = records.map(({seasonId}) => seasonId);

  const currentCropColors = cropPerformanceFilter.filters[CPFilterType.BIOMASS_OVER_TIME]
    .filter(({value}) => recordIds.includes(value as number)) // make sure the color will be used by a record
    .map(({value, color}) => ({seasonId: value, color}));

  const predefinedColors = currentCropColors.map(({color}) => color); // create an array once to use below
  records.forEach((record, index) => {
    const seasonDates = getCPSeasonDataByRecord(record, cropPerformance).dates;
    const color =
      currentCropColors.find(({seasonId}) => record.seasonId === seasonId)?.color || // try get the same color first
      getTheNextColorFromPredefinedList([
        ...result.map(dataSet => dataSet.backgroundColor),
        ...predefinedColors,
      ]);
    const seasonName = record.seasonName ? `[${record.seasonName}]` : '';
    const dataSetData: UpdatedDataSet = {
      type: 'line',
      lineTension: 0.2,
      borderWidth: 2, // line width
      cubicInterpolationMode: index % 2 === 0 ? 'default' : 'monotone',
      steppedLine: false,
      borderColor: color,
      backgroundColor: color,
      borderCapStyle: 'butt',
      borderDashOffset: 0.0,
      pointBorderWidth: 2.5, // point at line radius
      pointHoverRadius: 5,
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: color,
      pointHoverBorderWidth: 1,
      pointRadius: 0,
      pointHitRadius: 10,
      // add season id in parentheses to create a uniq label for Chart.js, but remove it then to have a clear tooltip
      label: `${record.fieldName} ${seasonName} (${record.seasonId})`,
      data: [],
      fill: false,
      seasonId: record.seasonId,
      seasonName: record.seasonName,
      cropVariety: record.cropVariety,
      cropType: record.cropType,
      fieldName: record.fieldName,
    };
    if (!seasonDates) return; // early return if no season dates found

    labels.forEach(date => {
      const point: ChartPoint = {
        x: moment(date).toDate(),
        y: seasonDates?.[date]?.smoothSatelliteNdvi
          ? toFixedFloatUnsafe(seasonDates[date].smoothSatelliteNdvi, 3)
          : null,
      };
      // @ts-expect-error error leftover from convertion to strict mode, please fix
      dataSetData.data.push(point);
    });

    if (!dataSetData.data.some((point: any) => point.y)) {
      return;
    }
    result.push(dataSetData);
  });

  return result;
};

export const classifyLabels = (
  records: CSGViewModel[],
  cropPerformance: CropPerformanceState
): string[] => {
  let theOldestRecord = records[0];
  records.forEach(r => {
    // get the oldest record
    if (moment(r.date, 'YYYYMMDD').isBefore(moment(theOldestRecord.date, 'YYYYMMDD'))) {
      theOldestRecord = r;
    }
  });

  const {farmId, fieldID, seasonId, date} = theOldestRecord;
  const cropDates = Object.keys(
    cropPerformance.farms[farmId]?.csgs?.[fieldID]?.seasons?.[seasonId]?.dates || {}
  );

  if (!cropDates.length) return []; // return []

  return cropDates.slice(0, cropDates.findIndex(d => d === date) + 1);
};

export const classifyCropsByNDVI = (
  records: CSGViewModel[],
  additionalCropIds: {[seasonId: number]: boolean}
) => {
  const result: {
    bestCrops: CSGViewModel[];
    worstCrops: CSGViewModel[];
    addedCrops: CSGViewModel[]; // added - means selected manually by a user
  } = {
    worstCrops: [],
    bestCrops: [],
    addedCrops: [],
  };
  const sortedRecords = sortByKey(records, 'cumulativeSmoothSatelliteNdvi');

  switch (true) {
    case records.length === 1: {
      result.worstCrops = sortedRecords;
      break;
    }
    case records.length === 2: {
      result.worstCrops = [sortedRecords[0]];
      result.bestCrops = [sortedRecords[1]];
      break;
    }
    case records.length === 3:
    case records.length === 4: {
      result.worstCrops = sortedRecords.slice(0, 2);
      result.bestCrops = sortedRecords.slice(2);
      break;
    }
    case records.length > 4: {
      result.worstCrops = sortedRecords.slice(0, 2);
      result.bestCrops = sortedRecords.slice(records.length - 2); // get the last two
      result.addedCrops = sortedRecords.filter(
        ({seasonId}) =>
          additionalCropIds[seasonId] && // it was selected
          [...result.worstCrops, ...result.bestCrops].find(record => record.seasonId !== seasonId) // and it is not a part of the automatic selection
      );
      break;
    }
  }
  return result;
};

export const classifyMinMaxChartValues = (dataSets: UpdatedDataSet[]) => {
  // retrieves the max value of dataset to limit the yAxis to it
  let maxValue = 0;
  let minValue = 0;
  dataSets.forEach(({data}) =>
    data.forEach((dataPoint: any) => {
      if (isFinite(dataPoint?.y)) {
        maxValue = dataPoint?.y > maxValue ? dataPoint?.y : maxValue;
        minValue = dataPoint?.y < minValue ? dataPoint?.y : minValue;
      }
    })
  );
  if (maxValue === 0) {
    maxValue = 1;
  } else if (maxValue < 0.95) {
    maxValue = maxValue + 0.05; // add an additional space
  }
  return {maxValue, minValue};
};

export const options = ({
  onZoomOrPanChange,
  isPopUp,
  measurement,
  shiftPressed,
  maxDataSetValue,
  minDataSetValue,
  labels,
}: {
  onZoomOrPanChange: () => void;
  isPopUp: boolean;
  measurement: string;
  shiftPressed: boolean;
  maxDataSetValue: number;
  minDataSetValue: number;
  labels: string[];
}): ChartOptions => {
  const dateDisplayFormat = measurement === 'ha' ? 'DD-MMM' : 'MMM-DD';
  return {
    layout: {
      padding: {
        left: 0,
        right: 0,
        bottom: 0,
        top: 30,
      },
    },

    tooltips: {
      mode: 'index',
      position: 'nearest',
      callbacks: {
        // this callback need for rounding NDVI value to 2 decimal (don't do it before to ensure the chart lines are smooze)
        label(tooltipItem: Chart.ChartTooltipItem, data: Chart.ChartData): string | string[] {
          const chartLine: any = data.datasets[tooltipItem.datasetIndex];
          let label = chartLine.label;
          // add season id in parentheses to create a uniq label for Chart.js, but remove it then to have a clear tooltip
          label = label.replace(/ *\([^)]*\) */g, '');
          let value = chartLine.data[tooltipItem.index]?.y;

          if (value && isFinite(value)) {
            value = toFixedFloatUnsafe(value);
          }

          return `${label} ${value}`;
        },
      },
    },

    legend: {
      display: false,
    },
    scales: {
      xAxes: [
        {
          type: 'time',
          time: {
            tooltipFormat: dateDisplayFormat,
            displayFormats: {
              day: dateDisplayFormat,
              week: dateDisplayFormat,
              month: dateDisplayFormat,
            },
          },
          ticks: {
            maxRotation: 0,
            maxTicksLimit: 5,
          },
          gridLines: {
            display: false,
          },
        },
      ],

      yAxes: [
        {
          ticks: {
            maxTicksLimit: 4,
            max: maxDataSetValue, // limit the y Axis max value
            min: minDataSetValue,
          },
          gridLines: {
            drawBorder: false, // do not draw the vertical line near ticks
            drawTicks: false, // do not draw additional dash between tick and actual tick line
          },
        },
      ],
    },
    plugins: {
      zoom: !isPopUp // disable in the right panel
        ? {}
        : {
            pan: {
              enabled: shiftPressed,
              mode: 'xy', // x | y | xy, enable on (x) axes
              speed: 20, // On category scale, factor of pan velocity
              threshold: 10, // Minimal pan distance required before actually applying pan

              rangeMin: {
                // Format of min pan range depends on scale type
                x: moment(labels[0]), // use moment since the x scale represents time
                y: 0, // 0 is y min because the NDVI chart data values are not less than 0
              },
              rangeMax: {
                // Format of max pan range depends on scale type
                x: moment(labels[labels.length - 1]),
                y: maxDataSetValue,
              },
              onPanComplete: onZoomOrPanChange,

              // A function that is called as the user is panning and returns the
              // available directions can also be used:
              //   mode: function({ chart }) {
              //     return 'xy';
              //   },

              // onPan: function({chart}: any) {
              // },
            },

            zoom: {
              enabled: !shiftPressed, // Boolean to enable zooming
              mode: 'xy',
              speed: 0.1, // Speed of zoom via mouse wheel // (percentage of zoom on a wheel event)
              threshold: 2, // Minimal zoom distance required before actually applying zoom
              sensitivity: 3, // On category scale, minimal zoom level before actually applying zoom
              rangeMax: {
                // Format of max zoom range depends on scale type
                x: null,
                y: 1,
              },
              rangeMin: {
                // Format of min zoom range depends on scale type
                x: null,
                y: 0,
              },
              onZoomComplete: onZoomOrPanChange,

              // Drag-to-zoom effect can be customized
              drag: shiftPressed // boolean on such an object
                ? false
                : {
                    borderColor: '$main-gray-200',
                    borderWidth: 1,
                    backgroundColor: 'transparent',
                    animationDuration: 0,
                  },

              // Zooming directions. Remove the appropriate direction to disable
              // Eg. 'y' would only allow zooming in the y direction
              // A function that is called as the user is zooming and returns the
              // available directions can also be used:
              //   mode: function({ chart }) {
              //     return 'xy';
              //   },
              // mode: 'x',

              // Function called while the user is zooming
              // onZoom: function({chart}: any) {
              // },
              // Function called once zooming is completed
            },
          },
    },
  };
};

export const getBenchmarkMapChartData = (
  records: CSGViewModel[],
  cropPerformanceFilter: CPFilterState
) => {
  const benchmarkFilters = cropPerformanceFilter.filters[CPFilterType.BIOMASS_OVER_TIME];
  const cropsDisplayedOnChart = records.filter(({seasonId}) =>
    benchmarkFilters.find(({value}) => value === seasonId)
  );
  const cropsNotDisplayedOnChart = records.filter(
    ({seasonId}) => !benchmarkFilters.find(({value}) => value === seasonId)
  );

  const sortedRecords = [...cropsDisplayedOnChart, ...cropsNotDisplayedOnChart]; // get crops with colors first to make <Pie/> more beautiful :)
  const data = sortedRecords.map(({seasonId, seasonName, fieldName}) => ({
    id: seasonId,
    label: `${fieldName} ${seasonName || ''}`,
    value: 1, // because it is always only one crop
  }));

  const getColor = (slice: any) =>
    benchmarkFilters.find(({value}) => value === slice.id)?.color || '#EFEFEF';
  return {data, getColor};
};

export const cropsLimitWarning = () =>
  showNotification({
    title: t({id: 'note.warning'}),
    message: t({
      id: 'CP.benchmark.limit-crops-message',
    }),
    type: 'warning',
  });
