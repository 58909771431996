import cn from 'classnames';
import React, {useState} from 'react';
import {onClickOutsideAndEsc} from '_utils/utils2';
import './fluro-dropdown.scss';

// Just an unstyled dropdown mechanism.
// Same as <Dropdown/>.
// TODO (stas): decide which one to keep.
export type FluroDropdownProps = {
  className?: string;
  Button?: React.FunctionComponent<{onClick: () => void}>;
  expand: boolean;
  dropdownStyle?: React.CSSProperties;
  onExpand: () => void;
  onClose: () => void;
};
export const FluroDropdown: React.ComponentType<FluroDropdownProps> = ({
  className,
  Button,
  expand,
  children,
  onExpand,
  onClose,
  dropdownStyle,
}) => {
  const [dropdownElement, setDropdownElement] = useState<HTMLDivElement | null>(null);
  React.useEffect(() => {
    return onClickOutsideAndEsc(dropdownElement, expand, onClose);
  }, [dropdownElement, expand, onClose]);

  const toggleExpand = () => {
    expand ? onClose() : onExpand();
  };

  return (
    <div ref={setDropdownElement} className={cn(className, 'fluro-dropdown-base')}>
      <div className="fluro-dropdown-base__button-wrap">
        {Button && <Button onClick={toggleExpand} />}
      </div>
      {expand && (
        <div className="fluro-dropdown-base__dropdown" style={dropdownStyle}>
          {children}
        </div>
      )}
    </div>
  );
};
