import cn from 'classnames';
import {Portal} from 'components/fluro-dialog/portal';
import type {ComponentType} from 'react';
import React, {useEffect} from 'react';
import {useAppDispatch, useAppSelector} from '_hooks';
import {toggleMapBar} from '../actions';
import {selectCurrentFieldId, selectCurrentTab, selectIsMapBarOpen} from '../reducer/selectors';
import {useMapBarView} from './map-bar-view';
import {useMediaQuery} from 'react-responsive';
import './map-bar.scss';
import {useWorkspace} from '_hooks/use-workspace';
import {MapBarMinimizeBtn, MapBarToggle} from 'containers/map/map-bar/map-bar-toggle';

export const MapBar: ComponentType<{className?: string; hideExpand?: boolean}> = ({
  children,
  className,
  hideExpand = false,
}) => {
  const dispatch = useAppDispatch();
  const [wholeTableView] = useMapBarView();
  const isMapBarOpen = useAppSelector(selectIsMapBarOpen);
  const feature = useAppSelector(selectCurrentTab);
  const currentFieldId = useAppSelector(selectCurrentFieldId);
  const isSmallScreen = useMediaQuery({query: '(max-width: 560px)'});
  const {workspace} = useWorkspace();

  useEffect(() => {
    if (isSmallScreen) {
      // On mobile when we change the current field we want to see the map, so closing the map bar.
      dispatch(toggleMapBar(false));
    }
  }, [currentFieldId]);

  return (
    <Portal id="map-bar-root">
      {/* On small screens we show different topbar control */}

      <div
        className={cn('map-bar', className, {
          'whole-table-view': wholeTableView,
          'mrv-feature-pack': workspace === 'mrv',
          'si-feature-pack': workspace === 'si',
        })}
      >
        {!isMapBarOpen && <MapBarMinimizeBtn openMapBar />}

        <div
          className={cn('map-bar__panel', `map-bar__feature-${feature}`, {
            'map-bar__panel--open': isMapBarOpen,
          })}
        >
          <MapBarToggle hideTableToggle={hideExpand} />
          {children}
        </div>
      </div>
    </Portal>
  );
};
