import {Flex} from 'components';
import React, {useEffect, useState} from 'react';
import type {FC} from 'react';
import {BarChartNav} from '../../styled-components';
import cn from 'classnames';
import {FontIcon} from 'react-md';
import {t} from 'i18n-utils';

interface ChartNavProps {
  dataLength: number;
  itemsPerPage: number;
  onPageChange: (page: number) => void;
}

export const ChartNav: FC<ChartNavProps> = ({dataLength, itemsPerPage, onPageChange}) => {
  const [page, setPage] = useState(0);

  const moreItems = itemsPerPage * (page + 1) < dataLength;

  useEffect(() => {
    onPageChange(page);
  }, [page, onPageChange]);

  const nextPage = () => {
    if (moreItems) {
      setPage(page + 1);
    }
  };
  const prevPage = () => {
    if (page >= 1) {
      setPage(page - 1);
    }
  };

  return (
    <BarChartNav>
      <Flex
        onClick={prevPage}
        className={cn({'nav-btn': true, disabled: page === 0})}
        alignItems="center"
      >
        <>
          <FontIcon>navigate_before</FontIcon>
          {t({id: 'Previous'})} {itemsPerPage}
        </>
      </Flex>

      <Flex
        className={cn({'nav-btn': true, disabled: !moreItems})}
        alignItems="center"
        onClick={nextPage}
      >
        <>
          {t({id: 'Next'})} {itemsPerPage}
          <FontIcon>navigate_next</FontIcon>
        </>
      </Flex>
    </BarChartNav>
  );
};
