import React, {useMemo} from 'react';
import {ZoningTabsWrapper} from './productivity-map';
import type {SingleFluroTabProps} from 'components/fluro-tabs';
import FluroTabs, {FluroTabsSkin} from 'components/fluro-tabs';
import {FontIcon} from 'react-md';
import {toggleTooltip} from 'modules/global/actions';
import {t} from 'i18n-utils';
import {ZoningTab} from 'containers/map/types';
import {useAppDispatch, useAppSelector} from '_hooks';
import {selectZoningTab} from 'containers/map/reducer/selectors';
import {setZoningTab} from 'containers/map/actions/zoning-actions';
import {selectIsAdmin} from 'containers/login/login-selectors';
import {useNitrogenRx} from './nitrogen-recommendation/use-nitrogen-rx';

export const ZoningTabs = () => {
  const dispatch = useAppDispatch();
  const isAdmin = useAppSelector(selectIsAdmin);

  const zoningTab = useAppSelector(s => selectZoningTab(s));

  const {isNrxAllowed, isNutrilogicAllowed} = useNitrogenRx();

  const onTabClick = (value: ZoningTab) => {
    dispatch(setZoningTab(value));
  };

  const zoningTabs = useMemo(() => {
    const tabs: SingleFluroTabProps<ZoningTab>[] = [
      {label: t({id: 'Zones'}), value: ZoningTab.MainZoning, className: ZoningTab.MainZoning},
    ];

    if (isAdmin) {
      tabs.push({
        label: (
          <>
            {t({id: 'Productivity map'})}{' '}
            <span
              data-tip=""
              data-for="productivity-map-info"
              onMouseEnter={() =>
                dispatch(
                  toggleTooltip({
                    id: 'productivity-map-info',
                    content: t({
                      id: 'Productivity map gives an indication of yield potential by combining all NDVI images across the growing season to highlight consistent zones.',
                    }),
                    place: 'left',
                  })
                )
              }
              className="help-icon-tab"
            >
              <FontIcon>help_outline</FontIcon>
            </span>
          </>
        ),
        value: ZoningTab.ProdMap,
        className: ZoningTab.ProdMap,
      });
    }

    if (isNrxAllowed || isNutrilogicAllowed) {
      tabs.push({
        label: t({id: 'Nitrogen Rx'}),
        value: ZoningTab.NitrogenRx,
        className: ZoningTab.NitrogenRx,
      });
    }

    return tabs;
  }, [isNrxAllowed, isNutrilogicAllowed, isAdmin]);

  if (zoningTabs.length === 1 && zoningTabs[0].value === ZoningTab.MainZoning) {
    return null;
  }

  return (
    <ZoningTabsWrapper>
      <FluroTabs
        stopPropagation
        selectedTab={zoningTab}
        onTabClick={onTabClick}
        tabs={zoningTabs}
        skin={FluroTabsSkin.Toggle}
      />
    </ZoningTabsWrapper>
  );
};
