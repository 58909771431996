import type {User} from '../admin/users/types';

export type LoginPayload = {
  email: string;
  password: string;
};

export type ProductionWiseAuthPayload = {
  id_token: string;
  state: string;
};

export type UpdatePasswordPayload = {
  password: string;
  confirmPassword: string;
  uuid?: string;
};

export type LoginResponse = {
  status: string;
  result: {token: string; user: User};
};

export enum LoginActionTypes {
  LOGIN = 'login/LOGIN',
  LOGOUT = 'login/LOGOUT',
  ERROR = 'login/ERROR',
  UPDATE_USER_SETTINGS = 'login/update-user-settings',
  UPDATE_USER_DATA = 'user/update-user-settings',
  UPDATE_USER_WORKSPACES = 'user/update-user-workspaces',
}

export interface LoginState {
  message: string[];
  user: User;
  isAuth: boolean;
  token: string;
  farmEndDialog: boolean;
  isImpersonated: boolean;
  isImpersonatorNotSuperAdmin: boolean;
}

export enum SIAccess {
  Off = 'off',
  On = 'on',
  SIOnly = 'si_only',
}

export type Measurement = 'ha' | 'ac';

export type CompanyLicenses = {
  PCA: boolean;
  CCA: boolean;
  CPCC: boolean;
};
