// @ts-nocheck
import React, {useCallback, useMemo, useState, useEffect, useRef} from 'react';
import {Button, SelectionControl, FontIcon} from 'react-md';
import {useAppDispatch, useAppSelector, useAppThunkDispatch} from '_hooks';
import './assign-practices.scss';
import {t} from 'i18n-utils';
import {applyPathParams} from '_utils/pure-utils';
import {getTypedValues} from '_utils/object';
import {toFixedFloat} from '_utils/number-formatters';
import {convertUnit, sortFieldsByProp} from '_utils';
import {isDefined} from '_utils/typeGuards';
import {Flex, FluroButton, GeometryPreview, InfoBlock, Sticky, Text} from 'components';
import cn from 'classnames';
import {
  selectFieldGeometries,
  selectFieldsByFarmId,
  selectHighlightedFieldId,
} from 'containers/map/reducer/selectors';
import {IndeterminateCheckboxIcon, UncheckedCheckboxIcon} from 'components/fluro-icons';
import {selectHasFarmsOrGroupsIds, selectMeasurement} from 'containers/login/login-selectors';
import type {Farm, Field} from 'containers/map/types';
import {PracticeHelp} from 'components/practice-select';
import {
  selectEnabledPhaseStages,
  selectMRVProgramById,
  selectPhaseStageByType,
  selectProjectById,
  selectProjectFieldsList,
  selectProjectPractices,
} from 'containers/mrv/monitoring/module/selectors';
import {useParsedMatchParams} from '_hooks/use-parsed-match-params';
import {GEOMETRY_THUMB_SIZE, handleFieldFeature} from 'containers/mrv/enrollment/form/utils';
import type {MRVProjectEligibility, Practice, MRVField, MRVValueInput} from 'containers/mrv/types';
import {MRVStageType, MRVPhaseType, MRVProgress} from 'containers/mrv/types';
import {
  fetchStageCompletion,
  removeProjectFields,
  removeProjectValues,
  updateMRVValues,
  validateExistingAssignedPractices,
} from 'containers/mrv/monitoring/module/thunks';
import {selectFarms} from 'modules/farms/selectors';
import {
  selectAssignPracticeAttribute,
  selectAssignPracticesStage,
} from 'containers/mrv/monitoring/module/enrollment.selectors';
import {
  selectAssignPracticesSelectedFieldIds,
  selectEnrollmentReadOnly,
} from 'containers/mrv/enrollment/carbon-store/selectors';
import {
  enrollFields,
  setAssignPracticeSelectedFields,
} from 'containers/mrv/enrollment/carbon-store';
import {ReadonlyMessage} from 'containers/mrv/enrollment/form/readonly-message';
import {useHistoryToRedirectToHome} from 'containers/mrv/utils';
import {highlightField, toggleTableView} from 'containers/map/actions';
import {MRVApi} from '_api';
import {ErrorIcon} from 'containers/map/icons';
import IneligibleFieldDialog from './ineligible-field-dialog';
import {AssignPracticesDialog} from './assign-practices-dialog';
import RemoveFieldDialog from './remove-field-from-enrollment-dialog';
import {PracticePreviewCircles} from 'components/practice-select/practice-preview-circles';
import {PlaceholderLoader} from 'components/placeholder-loader/placeholder-loader';
import {reportError} from 'containers/error-boundary';
import {StageMessageView} from 'containers/mrv/admin/base/stage-message';
import {FinishPhaseDialogContainer} from 'containers/mrv/monitoring/form/finish-phase-dialog-container';
import {MRVFMSSyncBtn} from 'containers/mrv/monitoring/fms-sync/fms-sync-button';
import {Link} from 'react-router-dom';
import {applyUrlParams} from '_hooks/use-url-params';
import {ENROLLMENT_PROJECT_FORM, MRV_HOME} from 'containers/mrv/routes';
import {getStageName} from 'containers/mrv/base';
import {useIsStageDisabled} from 'containers/mrv/monitoring/hooks/use-is-stage-disabled';
import {useMRVValues} from 'containers/mrv/data/use-mrv-values';

export const AssignPractices = () => {
  const dispatch = useAppDispatch();
  const {projectId} = useParsedMatchParams();
  const backToHomePage = useHistoryToRedirectToHome();
  const {isReadOnly} = useAppSelector(selectEnrollmentReadOnly);
  const fetchedStageCompletionRef = useRef(false);
  const practicesList = useAppSelector(s => selectProjectPractices(s, projectId));
  const projectFields = useAppSelector(s => selectProjectFieldsList(s, projectId));
  // const removedFieldIds = useAppSelector(s => selectProjectRemovedFieldIds(s, projectId));

  // const selectedFieldIds = useAppSelector(selectAssignPracticesSelectedFieldIds);

  const project = useAppSelector(s => selectProjectById(s, projectId));
  const program = useAppSelector(s => selectMRVProgramById(s, project?.program_id));
  // const assignPracticesAttribute = useAppSelector(selectAssignPracticeAttribute);
  const assignPracticesStage = useAppSelector(s =>
    selectPhaseStageByType(s, MRVPhaseType.Enrollment, MRVStageType.AssignPractices)
  );

  const [bulkAssign, _toggleBulkAssign] = useState(false);
  const [fieldsEligibility, setFieldsEligibility] = useState<MRVProjectEligibility | null>(null);

  const phaseType = MRVPhaseType.Enrollment;

  const fieldsByFarmId = useAppSelector(selectFieldsByFarmId);
  const farms = useAppSelector(selectFarms);

  useEffect(() => {
    dispatch(toggleTableView(null));
  }, []);

  useEffect(() => {
    if (!projectId || !assignPracticesStage?.id) {
      return;
    }
    if (!assignPracticesStage?.eligibility_method) {
      reportError(
        `Assign practices stage is not configured with ELIGIBILITY METHOD and a grower is trying to use it.`
      );
      return;
    }
    MRVApi.getStageEligibility(projectId, assignPracticesStage?.id).then(({data}) => {
      dispatch(validateExistingAssignedPractices()); // validate on the beginning
      setFieldsEligibility(data);
    });
  }, [projectId, assignPracticesStage?.id, assignPracticesStage?.eligibility_method]);

  useEffect(() => {
    if (!projectFields.length) {
      backToHomePage();
    }
  }, [projectFields, backToHomePage]);

  const isFieldEnrolled = useCallback(
    (field: Field) => {
      const boolByFieldId: {[fieldId: number]: true} = {};
      projectFields.forEach(f => (boolByFieldId[f.fs_field_id] = true));
      return boolByFieldId[field.FieldID];
    },
    [projectFields]
  );

  const farmsToProcess = useMemo(() => {
    return Object.keys(fieldsByFarmId).reduce((acc, farmKey) => {
      const farmId = Number(farmKey);
      const farm = farms[farmId];

      const farmHasEnrolledFields = Object.values(fieldsByFarmId[farmId]).some(isFieldEnrolled);
      if (!farmHasEnrolledFields || !farm) {
        return acc;
      }

      return [...acc, farm];
    }, [] as Farm[]);
  }, [fieldsByFarmId, farms, isFieldEnrolled]);

  // const isAllFieldsSelected = projectFields.length === selectedFieldIds.length;

  // const onSelectAllFarms = (value: boolean) => {
  //   dispatch(setAssignPracticeSelectedFields(value ? [] : [...projectFields.map(p => p.id)]));
  // };

  const mrvFieldsByFSFieldId = useMemo(() => {
    const fields: Record<number, MRVField> = {};
    projectFields.forEach(f => {
      fields[f.fs_field_id] = f;
    });
    return fields;
  }, [projectFields]);

  useEffect(() => {
    if (!isReadOnly && assignPracticesStage?.id && !fetchedStageCompletionRef.current) {
      fetchedStageCompletionRef.current = true;
      dispatch(fetchStageCompletion({projectId, stageId: assignPracticesStage.id}));
    }
  }, [isReadOnly, projectId, assignPracticesStage?.id, dispatch]);

  // const allFieldsAssigned = () => {
  //   if (!assignPracticesAttribute) return false;

  //   const fields = farmsToProcess.flatMap(farm =>
  //     Object.values(fieldsByFarmId[farm.id]).filter(isFieldEnrolled)
  //   );
  //   const fieldsWithValues = fields.filter(field => {
  //     const mrvFieldId = mrvFieldsByFSFieldId[field.FieldID].id;
  //     const fieldHasValues = entityValueRows[mrvFieldId].find(
  //       row => !!row.values[assignPracticesAttribute.id]?.value
  //     );
  //     return fieldHasValues;
  //   });

  //   return projectFields.length === fieldsWithValues.length;
  // };

  return (
    <div className="enrollment-form__assign-field-practice">
      <div className="description mt-1">
        {isReadOnly ? (
          <>
            {t(
              {
                id: 'Enroll.AssignPracticesReadonlyMessage',
                defaultMessage:
                  'Below are the new and expanded practices you plan to implement during the next growing season. You can view your selection but are unable to edit at this point because you are already enrolled in {program}',
              },
              {program: program?.name}
            )}
            .
          </>
        ) : (
          <>
            {t({
              id: 'Enroll.AssignPracticesMessage',
              defaultMessage:
                'Assign new and expanded practices to be implemented during the next growing season.',
            })}
          </>
        )}
      </div>
      {assignPracticesStage?.messages?.map((message, i) => (
        <StageMessageView key={i} message={message} />
      ))}
      {isReadOnly && <PracticeHelp items={practicesList} />}

      {!assignPracticesStage?.eligibility_method && (
        <InfoBlock appearance="error" color="error">
          {t({
            id: 'Enroll.AssignPracticesEligibilityMethod',
            defaultMessage:
              'Eligibility method is not configured correctly. Please contact your program administrator.',
          })}
        </InfoBlock>
      )}

      {/*TEMPORARY COMMENTED UNTIL FINISH IMPLEMENTATION*/}

      {/*{!isReadOnly && (*/}
      {/*  <Flex justifyContent="space-between" alignItems="center" className="mt-1">*/}
      {/*    <SelectionControl*/}
      {/*      id={'bulk-assign'}*/}
      {/*      name={'bulk-assign'}*/}
      {/*      label={'Bulk assign'}*/}
      {/*      type={'switch'}*/}
      {/*      onChange={(value: boolean) => toggleBulkAssign(value)}*/}
      {/*      checked={bulkAssign}*/}
      {/*    />*/}
      {/*    /!*{farmsToProcess.length > 1 ? (*!/*/}
      {/*    /!*  <div className={'global-link'} onClick={() => onSelectAllFarms(isAllFieldsSelected)}>*!/*/}
      {/*    /!*    {isAllFieldsSelected ? 'Unselect' : 'Select'} all*!/*/}
      {/*    /!*  </div>*!/*/}
      {/*    /!*) : (*!/*/}
      {/*    /!*  <div />*!/*/}
      {/*    /!*)}*!/*/}
      {/*    {bulkAssign && (*/}
      {/*      <PracticeMultiSelectDropDown*/}
      {/*        id="assign-practice-dd"*/}
      {/*        disabled={!selectedFieldIds.length}*/}
      {/*        items={practicesList}*/}
      {/*        value={projectValues.map(p => p.value) || []}*/}
      {/*        isPrimary={!allFieldsAssigned()}*/}
      {/*        onChange={values => {*/}
      {/*          dispatch(*/}
      {/*            assignPractices({*/}
      {/*              projectId,*/}
      {/*              payload: selectedFieldIds.reduce((acc, mrvFieldId) => {*/}
      {/*                acc[mrvFieldId] = values;*/}
      {/*                return acc;*/}
      {/*              }, {} as Record<number, Practice[]>),*/}
      {/*            })*/}
      {/*          );*/}
      {/*        }}*/}
      {/*      />*/}
      {/*    )}*/}
      {/*  </Flex>*/}
      {/*)}*/}
      <FieldsPerFarmList
        phaseType={phaseType}
        projectId={projectId}
        farms={farmsToProcess}
        isFieldEnrolled={isFieldEnrolled}
        mrvFieldsByFSFieldId={mrvFieldsByFSFieldId}
        fieldsEligibility={fieldsEligibility}
        bulkAssign={bulkAssign}
      />
    </div>
  );
};

const FieldsPerFarmList = ({
  phaseType,
  projectId,
  farms,
  isFieldEnrolled,
  mrvFieldsByFSFieldId,
  fieldsEligibility,
  bulkAssign,
}: {
  phaseType: MRVPhaseType;
  projectId: number;
  farms: Farm[];
  isFieldEnrolled: (field: Field) => boolean;
  mrvFieldsByFSFieldId: Record<number, MRVField>;
  fieldsEligibility: MRVProjectEligibility | null;
  bulkAssign: boolean;
}) => {
  const dispatch = useAppThunkDispatch();
  const {isReadOnly} = useAppSelector(selectEnrollmentReadOnly);
  const projectFields = useAppSelector(s => selectProjectFieldsList(s, projectId));
  const fieldsByFarmId = useAppSelector(selectFieldsByFarmId);
  const geometries = useAppSelector(selectFieldGeometries);
  const hasFarms = useAppSelector(selectHasFarmsOrGroupsIds);
  const selectedFieldIds = useAppSelector(selectAssignPracticesSelectedFieldIds);
  const measurement = useAppSelector(selectMeasurement);
  const highlightedFieldId = useAppSelector(selectHighlightedFieldId);

  const assignPracticesStage = useAppSelector(selectAssignPracticesStage);
  const assignPracticesAttribute = useAppSelector(selectAssignPracticeAttribute);
  const [ineligibleFieldId, setIneligibleFieldId] = useState<number | null>(null);
  const [fieldToAssignPractice, setFieldToAssignPractice] =
    useState<{fieldId: number; practices: Practice[]}>(null);
  const [fieldIdToRemoveFromEnrollment, setFieldIdToRemoveFromEnrollment] = useState<number>(null);

  const [collapsedFarms, setCollapsedFarms] = useState<{[farmId: number]: boolean}>({});

  const isStageDisabled = useIsStageDisabled(MRVPhaseType.Enrollment);
  const stagesList = useAppSelector(s => selectEnabledPhaseStages(s, MRVPhaseType.Enrollment));

  const completion = useAppSelector(s => s.monitoring.completion);
  const currentStageIndex = stagesList.findIndex(s => s.type_ === MRVStageType.AssignPractices);

  const {entityValueRows, updateAssignPractices, revalidate} = useMRVValues(
    phaseType,
    projectId,
    assignPracticesStage
  );

  const isFirst = currentStageIndex === 0;
  const isLast = currentStageIndex === stagesList.length - 1;
  const prevStage = !isFirst ? stagesList[currentStageIndex - 1] : null;
  const nextStage = !isLast ? stagesList[currentStageIndex + 1] : null;

  const backButtonLocation =
    prevStage && !isReadOnly
      ? {
          pathname: applyPathParams(ENROLLMENT_PROJECT_FORM, {projectId}),
          search: applyUrlParams(location.search, {stageId: prevStage.id, tableView: true}),
        }
      : {
          pathname: applyPathParams(MRV_HOME, {projectId}),
        };

  const allIncompletedStages = stagesList
    .map(stage => {
      if (!completion[stage.id]?.is_completed) {
        return Number(stage.id);
      }
      return null;
    })
    .filter(res => res !== null);

  useEffect(() => {
    return () => {
      if (!isReadOnly && assignPracticesStage?.id) {
        dispatch(fetchStageCompletion({projectId, stageId: assignPracticesStage.id}));
      }
    };
  }, [isReadOnly, projectId, assignPracticesStage?.id, dispatch]);

  const toggleExpandFarm = (farmId: number, forceValue?: boolean) => {
    setCollapsedFarms(e => ({...e, [farmId]: forceValue !== undefined ? forceValue : !e[farmId]}));
  };

  const farmIsSelected = (farmId: number) => {
    return (
      projectFields.length > 0 &&
      projectFields.filter(f => f.farm_id === farmId).every(f => selectedFieldIds.includes(f.id))
    );
  };

  const selectFarm = (farmId: number, value: boolean) => {
    const farmMrvFieldIds = projectFields.filter(f => f.farm_id === farmId).map(f => f.id);
    const newFieldIds = value
      ? [...selectedFieldIds, ...farmMrvFieldIds]
      : selectedFieldIds.filter(v => !farmMrvFieldIds.includes(v));
    dispatch(setAssignPracticeSelectedFields(newFieldIds));
  };

  const selectField = (mrvFieldId: number, value: boolean) => {
    const newFieldIds = value
      ? [...selectedFieldIds, mrvFieldId]
      : selectedFieldIds.filter(v => v !== mrvFieldId);
    dispatch(setAssignPracticeSelectedFields(newFieldIds));
  };

  const onFieldClick = (fieldId: number) => {
    dispatch(highlightField(fieldId));
  };

  const onRemoveField = (fieldId: number) => {
    const fsFieldId = projectFields.find(f => f.id === fieldId)?.core_attributes?.kml_id;

    dispatch(removeProjectFields({projectId, mrvFieldIds: [fieldId]})); // remove for back-end
    dispatch(enrollFields({[fsFieldId]: false})); // remove for UI

    setFieldIdToRemoveFromEnrollment(null); // hide RemoveFieldDialog
  };

  const assignPracticesToAField = async (entityId: number, practices: Practice[]) => {
    if (!assignPracticesStage || !assignPracticesAttribute) {
      return;
    }

    const entityRows = entityValueRows[entityId] || [];
    const existingValues = entityRows.map(row => row.values[assignPracticesAttribute.id]);

    const entityType = assignPracticesStage.entity_type;
    const valuesToRemove = existingValues.filter(v => !practices.includes(v.value));

    if (valuesToRemove.length) {
      await dispatch(
        removeProjectValues({
          valueIds: valuesToRemove.map(v => v.id).filter(isDefined),
          projectId,
          entityType,
        })
      );
    }

    const existingPractices = existingValues.map(v => v.value);
    const practicesToAdd = practices.filter(p => !existingPractices.includes(p));
    let rowIdCounter = existingValues.length
      ? Math.max(...existingValues.map(p => p.row_id)) || 0
      : 0;
    const valuesToAdd: MRVValueInput[] = practicesToAdd.map(value => {
      return {
        value,
        entity_type: entityType,
        locked: false,
        confirmed: true,
        attribute_id: assignPracticesAttribute.id,
        progress: MRVProgress.Enrolment,
        row_id: ++rowIdCounter,
      };
    });

    if (valuesToAdd.length) {
      await dispatch(updateMRVValues({update: {[entityId]: valuesToAdd}, projectId, entityType}));
    }

    const updatedValues = [
      ...existingValues.filter(
        existingValue =>
          isDefined(existingValue) &&
          !valuesToRemove.some(valueToRemove => valueToRemove.value === existingValue.value)
      ),
      ...valuesToAdd,
    ];

    // update swr state
    await updateAssignPractices(entityId, updatedValues);

    // refetch mrv values
    await revalidate();

    // fetch completion for AssignPractices stage
    dispatch(fetchStageCompletion({projectId, stageId: assignPracticesStage.id}));

    // Close the AssignPracticesDialog.
    setFieldToAssignPractice(null);
  };

  // Hack: prevents the user from navigating to next stage if some fields have ineligible practices
  // should have been done in the backend but they don't have time to implement it,
  // and the legacy Assign Practices stage is supposed to be getting removed soon
  const someFieldsHaveIneligiblePractices = getTypedValues(entityValueRows).some(rows =>
    rows.some(row => {
      const value = row.values[assignPracticesAttribute?.id];
      const projectEligibility = fieldsEligibility?.[value.field_id];

      return (
        value &&
        !projectEligibility?.eligible_practices?.some(ep => {
          return ep.some(fp => fp === value.value);
        })
      );
    })
  );

  const nextButtonIsDisabled = isStageDisabled(nextStage) || someFieldsHaveIneligiblePractices;

  if (!hasFarms) {
    return <div>{t({id: 'No farms'})}</div>;
  }

  return (
    <>
      {Boolean(fieldIdToRemoveFromEnrollment) && (
        <RemoveFieldDialog
          onHide={() => setFieldIdToRemoveFromEnrollment(null)}
          removeField={() => onRemoveField(fieldIdToRemoveFromEnrollment)}
        />
      )}
      {assignPracticesStage && Boolean(ineligibleFieldId) && (
        <IneligibleFieldDialog
          eligibilityMethod={assignPracticesStage.eligibility_method}
          fieldId={ineligibleFieldId}
          onHide={() => setIneligibleFieldId(null)}
          removeField={() => setFieldIdToRemoveFromEnrollment(ineligibleFieldId)}
          fieldsEligibility={fieldsEligibility}
        />
      )}

      {fieldToAssignPractice && (
        <AssignPracticesDialog
          onHide={() => setFieldToAssignPractice(null)}
          onRemoveField={() => setFieldIdToRemoveFromEnrollment(fieldToAssignPractice.fieldId)}
          onAssignPractices={(practices: Practice[]) =>
            assignPracticesToAField(fieldToAssignPractice.fieldId, practices)
          }
          practicesItems={
            fieldsEligibility?.[fieldToAssignPractice.fieldId]?.eligible_practices || []
          }
          fieldPractices={fieldToAssignPractice.practices}
          eligibilityMethod={assignPracticesStage.eligibility_method}
        />
      )}

      <ReadonlyMessage />
      <div className="farm-list mt-1">
        {farms.map(farm => {
          const farmId = farm.id;
          const fields = Object.values(fieldsByFarmId[farmId]).filter(isFieldEnrolled);

          if (!fields.length) return null;

          const farmSelectedFields = fields.filter(f =>
            selectedFieldIds.includes(mrvFieldsByFSFieldId[f.FieldID]?.id)
          );

          const farmName = farm.name;
          const farmArea = convertUnit(
            measurement,
            'ac',
            fields.reduce((acc, f) => acc + f.Area, 0)
          );

          const classifiedFarmArea =
            farmArea > 1000 ? `${toFixedFloat(farmArea / 1000, 1)}k ` : farmArea;
          const isFarmSelected = farmIsSelected(farmId);
          const isExpanded = !collapsedFarms[farmId];

          const farmLabel = (
            <div title={farmName} className={'checkbox-label'}>
              {farmName}
              {bulkAssign && (
                <div className={'subtext'}>
                  {t(
                    {id: '{count1} / {count2} fields selected'},
                    {count1: farmSelectedFields.length, count2: fields.length}
                  )}
                </div>
              )}
            </div>
          );

          return (
            <div className={cn('farm', {'farm--expanded': isExpanded})} key={farmId}>
              <div className={cn('farm__row  pr-1')}>
                {bulkAssign ? (
                  <div className={'farm-checkbox-container'}>
                    <SelectionControl
                      id={farmId}
                      name="farm selection"
                      disabled={isReadOnly}
                      uncheckedCheckboxIcon={
                        farmSelectedFields.length ? (
                          <IndeterminateCheckboxIcon defaultGreenColor={!isReadOnly} />
                        ) : (
                          <UncheckedCheckboxIcon />
                        )
                      }
                      label={farmLabel}
                      type={'checkbox'}
                      checked={isFarmSelected}
                      onChange={() =>
                        selectFarm(farmId, isFarmSelected ? false : !farmSelectedFields.length)
                      }
                    />
                  </div>
                ) : (
                  <div className={'margin-left-15'}>{farmLabel}</div>
                )}

                <Button className={'expand-btn'} icon onClick={() => toggleExpandFarm(farmId)}>
                  {isExpanded ? 'keyboard_arrow_down' : 'keyboard_arrow_right'}
                </Button>

                <div className={'farm-area'}>
                  {classifiedFarmArea} {t({id: measurement})}
                </div>
              </div>
              {isExpanded && (
                <div>
                  {sortFieldsByProp(fields, 'Name', 'string').map(field => {
                    const mrvFieldId = mrvFieldsByFSFieldId[field.FieldID]?.id;
                    const fieldPractices: Practice[] = [
                      ...new Set(
                        entityValueRows[mrvFieldId]?.map(
                          row => row.values[assignPracticesAttribute?.id]?.value
                        )
                      ),
                    ];
                    const selected = selectedFieldIds.includes(mrvFieldId);
                    const fieldEligibility = fieldsEligibility?.[mrvFieldId];
                    const fieldIsEligibleWithPractices =
                      fieldEligibility?.eligible === true &&
                      !!fieldEligibility?.eligible_practices?.length;

                    const fieldLabel = (
                      <Flex nowrap>
                        {geometries[field.MD5] && (
                          <div className="field-geometry-thumb">
                            <GeometryPreview
                              data={geometries[field.MD5]}
                              size={GEOMETRY_THUMB_SIZE}
                              onEachFeature={handleFieldFeature}
                            />
                          </div>
                        )}
                        <div>
                          <div className="field-name field-name-disabled">{field.Name}</div>
                          <div className="field-area">
                            <Text secondary>
                              {convertUnit(measurement, 'ac', field.Area)} {t({id: measurement})}
                            </Text>
                          </div>
                        </div>
                      </Flex>
                    );

                    return (
                      <div
                        className={cn({
                          field: true,
                          'field-highlighted': highlightedFieldId === field.ID,
                        })}
                        onClick={() => onFieldClick(field.ID)}
                        key={field.ID}
                      >
                        {bulkAssign ? (
                          <SelectionControl
                            id={field.ID}
                            //@ts-expect-error error leftover from convertion to strict mode, please fix
                            title={field.Name}
                            className="field-checkbox"
                            name={'field selection'}
                            disabled={isReadOnly}
                            label={fieldLabel}
                            type={'checkbox'}
                            checked={selected}
                            onClick={ev => {
                              if (!isReadOnly) {
                                ev.preventDefault();
                                // simulate onChange here because onChange doesn't work because of onClick action of the parent div (weird issue)
                                selectField(mrvFieldsByFSFieldId[field.FieldID].id, !selected);
                              }
                            }}
                          />
                        ) : (
                          <div className={'margin-left-15'}>{fieldLabel}</div>
                        )}

                        <div className="field-item-right-block">
                          {fieldPractices.length &&
                          fieldIsEligibleWithPractices &&
                          // filter out practices that are not eligible
                          fieldEligibility.eligible_practices.some(eps =>
                            eps.every(eligiblePractice => fieldPractices.includes(eligiblePractice))
                          ) ? (
                            <PracticePreviewCircles
                              id={field?.ID}
                              value={fieldPractices}
                              onClick={() => {
                                if (!isReadOnly) {
                                  setFieldToAssignPractice({
                                    fieldId: mrvFieldId,
                                    practices: fieldPractices,
                                  });
                                }
                              }}
                            />
                          ) : fieldIsEligibleWithPractices ? (
                            <FontIcon
                              onClick={() => {
                                if (!isReadOnly) {
                                  setFieldToAssignPractice({
                                    fieldId: mrvFieldId,
                                    practices: fieldPractices,
                                  });
                                }
                              }}
                              className={'eligibility__icon-add clickable'}
                            >
                              add
                            </FontIcon>
                          ) : fieldsEligibility ? (
                            <ErrorIcon
                              onClick={() => {
                                if (!isReadOnly) {
                                  setIneligibleFieldId(mrvFieldId);
                                }
                              }}
                              className={'eligibility__icon-error clickable'}
                            />
                          ) : (
                            <PracticesPreloader />
                          )}
                        </div>
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          );
        })}
      </div>
      <Sticky variant="container">
        <Flex alignItems="center" fullWidth justifyContent="space-between" gap="1em">
          <FluroButton flat component={Link} to={backButtonLocation}>
            {t({id: 'BtnLabel.Back', defaultMessage: 'Back'})}
          </FluroButton>

          <Flex gap="10px">
            <MRVFMSSyncBtn phaseType={MRVPhaseType.Enrollment} />
            {!isReadOnly &&
              (nextStage ? (
                <FluroButton
                  primary
                  raised
                  component={Link}
                  disabled={nextButtonIsDisabled}
                  to={{
                    pathname: applyPathParams(ENROLLMENT_PROJECT_FORM, {projectId}),
                    search: applyUrlParams(location.search, {stageId: nextStage.id}),
                  }}
                  onClick={e => {
                    // Our react md button + link combination doesn't respect the disabled prop,
                    // So we need to prevent the default action if the button is disabled.
                    if (nextButtonIsDisabled) {
                      e.preventDefault();
                    }
                  }}
                >
                  {t({id: 'BtnLabel.Next', defaultMessage: 'Next'})}: {getStageName(nextStage)}
                </FluroButton>
              ) : (
                <FinishPhaseDialogContainer
                  phaseType={MRVPhaseType.Enrollment}
                  disabled={!!allIncompletedStages.length}
                />
              ))}
          </Flex>
        </Flex>
      </Sticky>
    </>
  );
};

const PracticesPreloader = () => (
  <PlaceholderLoader style={{borderRadius: '50%'}} width={30} height={30} />
);
