import {CPBadge, Flex, InfoBlock} from 'components';
import {t} from 'i18n-utils';
import React from 'react';
import {FontIcon} from 'react-md';
import colors from '../variables.scss';

export const ProtectedFieldAreaBadge = () => (
  <CPBadge backgroundColor="#FDF3D9" className="protected-badge">
    <Flex alignItems="center" gap="10px">
      <FontIcon style={{color: colors['saffron']}}>warning</FontIcon>{' '}
      <span>{t({id: 'Field in protected area'})}</span>
    </Flex>
  </CPBadge>
);

export const ProtectedFieldAreaWarning = ({className = ''}: {className?: string}) => (
  <InfoBlock
    className={className}
    title={t({id: 'Overlapping protected area.'})}
    mini
    appearance="warning"
    color="warning"
  >
    {t({id: 'This field will require additional review if selected.'})}
  </InfoBlock>
);
