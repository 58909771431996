// @ts-nocheck
import * as React from 'react';
import {useCallback, useMemo, useState, useEffect} from 'react';
import {SelectionControl} from 'react-md';
import {useAppDispatch, useAppSelector} from '_hooks';
import './enrolled-fields-popup.scss';
import {t} from 'i18n-utils';
import {setEligibleRegionFields} from 'containers/mrv/enrollment/carbon-store/carbon-reducer';
import {importFields} from 'containers/mrv/enrollment/carbon-store/carbon-thunks';
import {
  selectCarbon,
  selectImportedFieldObjects,
  selectEnrollmentReadOnly,
} from 'containers/mrv/enrollment/carbon-store/selectors';
import {selectUser} from 'containers/login/login-selectors';

import {loadFarmsFields} from 'containers/map/actions';
import {getFarmById, sortFieldsByProp} from '_utils';
import {Flex, Text, FluroButton} from 'components';
import {toggleTooltip} from 'modules/global/actions';
import {showNotification} from 'components/notification/notification';

import cn from 'classnames';
import {
  selectAllFieldsList,
  selectCurrentFarm,
  selectFieldGeometries,
  selectFieldsByFarmId,
  selectHighlightedFieldId,
} from 'containers/map/reducer/selectors';
import {IndeterminateCheckboxIcon, UncheckedCheckboxIcon} from 'components/fluro-icons';
import {selectFarmsList} from 'modules/farms/selectors';
import {FieldSystemProp} from 'containers/map/features/farm/new-fields/types';
import {EligibleMessage} from 'containers/mrv/enrollment/base/messages';
import {toggleFieldsInsideFarm} from 'containers/mrv/utils';
import {eligibleMsg, overlapMsg} from 'containers/mrv/enrollment/form/enroll-fields/base';

export const EnrollFieldsPopup = ({onChange}: {onChange: (fields: any[]) => void}) => {
  const dispatch = useAppDispatch();
  const [isShowAllFields, setShowAllFields] = useState<boolean>(false);

  const user = useAppSelector(selectUser);
  const carbon = useAppSelector(selectCarbon);
  const farms = useAppSelector(selectFarmsList);
  const fieldsByFarmId = useAppSelector(selectFieldsByFarmId);
  const currentFarm = useAppSelector(selectCurrentFarm);
  const fieldsToSet = useAppSelector(selectImportedFieldObjects);

  const allFields = useAppSelector(selectAllFieldsList);
  const fieldGeometries = useAppSelector(selectFieldGeometries);
  const {isReadOnly} = useAppSelector(selectEnrollmentReadOnly);
  const highlightedFieldId = useAppSelector(selectHighlightedFieldId);
  const overlapIds = useMemo(() => new Set(carbon.overlapFields.flat()), [carbon.overlapFields]);

  const [collapsedFarms, setCollapsedFarms] = useState<{[farmId: number]: boolean}>({});

  const DEMO_FARM_ID = 136;

  useEffect(() => {
    (async () => {
      if (carbon.enrolledFields) {
        await dispatch(importFields(carbon.enrolledFields));
      }
    })();
  }, [carbon.enrolledFields]);

  useEffect(() => {
    if (carbon.importedFields) {
      onChange(fieldsToSet);
    }
  }, [carbon.importedFields, fieldsToSet]);

  useEffect(() => {
    (async () => {
      const farmIdsToLoad = user.groupIds?.filter(id => id !== DEMO_FARM_ID && !fieldsByFarmId[id]);
      if (!farmIdsToLoad.length) return;
      await dispatch(loadFarmsFields(farmIdsToLoad));
    })();
  }, []);

  useEffect(() => {
    const eligibility: {[fieldId: number]: boolean} = {};
    allFields.forEach(f => {
      // const geom = fieldGeometries[f.MD5];
      // eligibility[f.ID] = featureCollectionContains(pppx as GeoJSON.FeatureCollection, geom);
      eligibility[f.ID] = true; // pass all fields for now https://regrowag.slack.com/archives/C02EZPYF399/p1642583309083700?thread_ts=1642575495.076600&cid=C02EZPYF399
    });
    dispatch(setEligibleRegionFields(eligibility));
  }, [allFields, fieldGeometries]);

  const farmsToProcess = useMemo(() => {
    return Object.keys(fieldsByFarmId).reduce((acc, farmKey) => {
      const farmId = Number(farmKey);
      const farm = getFarmById(farms, farmId);
      const fields = [
        ...Object.values(fieldsByFarmId[farmId]).filter(f => carbon.enrolledFields[f.ID]),
      ];

      if (!farm || !fields.length) return acc;
      return [...acc, farm];
    }, []);
  }, [fieldsByFarmId, farms, carbon.enrolledFields]);

  const farmIsSelected = (farmId: number) => {
    const fieldIds = Object.keys(fieldsByFarmId[farmId])
      .map(Number)
      .filter(id => carbon.enrolledFields[id]);
    return fieldIds.length > 0 && fieldIds.every(fieldId => carbon.importedFields[fieldId]);
  };

  const toggleExpandFarm = (farmId: number, forceValue?: boolean) => {
    setCollapsedFarms(e => ({...e, [farmId]: forceValue !== undefined ? forceValue : !e[farmId]}));
  };

  const showIneligibleNote = useCallback(
    () =>
      showNotification({
        title: 'Ineligible location.',
        message: <EligibleMessage />,
        type: 'warning',
      }),
    []
  );

  const toggleFarm = (farmId: number, forceValue?: boolean) => {
    const value = forceValue !== undefined ? forceValue : !farmIsSelected(farmId);
    const selection = toggleFieldsInsideFarm(
      fieldsByFarmId[farmId],
      carbon.eligibleRegionFields,
      value
    );
    if (value) {
      dispatch(importFields(selection));
      return;
    }

    dispatch(importFields(selection));
  };

  const toggleField = (fieldId: number, forceValue?: boolean) => {
    const value = forceValue !== undefined ? forceValue : !carbon.importedFields[fieldId];
    dispatch(importFields({[fieldId]: value}));
  };

  const someFieldsSelected = Object.values(carbon.importedFields).some(Boolean);
  const allFarmsSelected = farmsToProcess.every(farm => farmIsSelected(farm.id));

  return (
    <div className="farm-container">
      <Flex justifyContent={'space-between'} className="farm-container-header">
        <div
          className={'farm-checkbox-container'}
          onClick={() => {
            // expand farm when click on th master checkbox and if all fields are ineligible
          }}
        >
          <SelectionControl
            id={'select-all-farms'}
            name={'farm selection'}
            label="All fields selected"
            type={'checkbox'}
            checked={allFarmsSelected}
            disabled={isReadOnly}
            uncheckedCheckboxIcon={
              someFieldsSelected ? (
                <IndeterminateCheckboxIcon defaultGreenColor={!isReadOnly} />
              ) : (
                <UncheckedCheckboxIcon />
              )
            }
            onChange={() => {
              farmsToProcess.forEach(farm => {
                toggleFarm(farm.id, allFarmsSelected ? false : !someFieldsSelected);
              });
            }}
          />
        </div>
        <FluroButton
          className={'expand-btn'}
          icon
          onClick={() => setShowAllFields(!isShowAllFields)}
        >
          {isShowAllFields ? 'keyboard_arrow_down' : 'keyboard_arrow_right'}
        </FluroButton>
      </Flex>
      {isShowAllFields && (
        <div className="farm-list">
          {farmsToProcess.map(farm => {
            const farmId = farm.id;
            const fields = [
              ...Object.values(fieldsByFarmId[farmId]).filter(f => carbon.enrolledFields[f.ID]),
            ];
            const fieldIds = Object.keys(fieldsByFarmId[farmId]).map(Number);
            const overlap = fieldIds.some(id => overlapIds.has(id));

            if (!fields.length) return null;

            const farmName =
              farms.find(f => f.id === farmId)?.name ||
              (currentFarm.id === farmId && currentFarm.name) || // if the list is not there and we're filling the current farm, use it
              '';

            const selectedFields = fields.filter(f => carbon.importedFields[f.ID]);
            const isFarmSelected = farmIsSelected(farmId);
            const isIneligibleFarm = fields.every(f => carbon.ineligibleRegionFields[f.FieldID]);
            const isExpanded = !collapsedFarms[farmId];

            return (
              <div
                className={cn('farm', {'farm--expanded': isExpanded}, 'clickeable')}
                key={farmId}
              >
                <div className={cn('farm__row', {overlap})}>
                  <div
                    className={'farm-checkbox-container'}
                    onClick={() => {
                      // expand farm when click on th master checkbox and if all fields are ineligible
                      if (isIneligibleFarm) {
                        toggleExpandFarm(farmId, true);
                      }
                    }}
                  >
                    <SelectionControl
                      id={farmId}
                      onClick={() => {
                        if (isIneligibleFarm) {
                          showIneligibleNote();
                        }
                      }}
                      name={'farm selection'}
                      uncheckedCheckboxIcon={
                        selectedFields.length ? (
                          <IndeterminateCheckboxIcon defaultGreenColor={!isReadOnly} />
                        ) : (
                          <UncheckedCheckboxIcon />
                        )
                      }
                      label={
                        <div title={farmName} className={'checkbox-label'}>
                          {farmName}
                        </div>
                      }
                      type={'checkbox'}
                      checked={isFarmSelected}
                      disabled={isReadOnly}
                      onChange={() =>
                        toggleFarm(farmId, isFarmSelected ? false : !selectedFields.length)
                      }
                    />
                  </div>

                  <Text secondary variant="small">
                    {t(
                      {id: '{count1} / {count2} fields selected'},
                      {count1: selectedFields.length, count2: fields.length}
                    )}
                  </Text>

                  <FluroButton
                    className={'expand-btn'}
                    icon
                    onClick={() => toggleExpandFarm(farmId)}
                  >
                    {isExpanded ? 'keyboard_arrow_down' : 'keyboard_arrow_right'}
                  </FluroButton>

                  {/*<div className={'farm-area'}>*/}
                  {/*  {classifiedFarmArea} {t({id: measurement})}*/}
                  {/*</div>*/}
                </div>
                {isExpanded && (
                  <div>
                    {sortFieldsByProp(fields, 'Name', 'string').map(field => {
                      const fieldId = field.ID;
                      const overlap = overlapIds.has(field.ID);
                      const highlighted = highlightedFieldId === fieldId;

                      const hasProtectedArea = !!fieldGeometries[field.MD5]?.features.some(
                        f => f.properties?.[FieldSystemProp.ProtectedArea]?.length
                      );

                      const ineligibleRegion = carbon.ineligibleRegionFields[field.FieldID];
                      return (
                        <div
                          key={field.ID}
                          onMouseEnter={() => {
                            (ineligibleRegion || overlap) &&
                              dispatch(
                                toggleTooltip({
                                  id: `tooltip-${fieldId}`,
                                  content: overlap ? overlapMsg() : eligibleMsg(),
                                  place: 'top',
                                })
                              );
                          }}
                        >
                          <div
                            className={cn('field', {
                              overlap,
                              highlighted,
                              'no-border': hasProtectedArea || ineligibleRegion,
                            })}
                          >
                            <SelectionControl
                              id={fieldId}
                              //@ts-expect-error error leftover from convertion to strict mode, please fix
                              title={field.Name}
                              className={'field-checkbox'}
                              name={'field selection'}
                              label={
                                <Flex nowrap>
                                  <div className="field-name field-name-disabled">{field.Name}</div>
                                </Flex>
                              }
                              type={'checkbox'}
                              checked={!!carbon.importedFields[field.ID]}
                              onMouseUp={() => toggleField(field.ID)}
                            />
                          </div>
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};
