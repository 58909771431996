import React, {useRef, useState, useCallback, useEffect, useMemo} from 'react';
import {Flex, FluroButton, Text} from 'components';
import {SIBaseMap} from './map';
import type {MapRef} from 'react-map-gl';
import {Source, Layer, Popup} from 'react-map-gl';
import {useAppSelector, useAppDispatch} from '_hooks';
import {convertUnit, classifyAreaWithUnits} from '_utils';
import {selectMeasurement} from 'containers/login/login-selectors';
import {
  selectInteractionType,
  selectShouldShowMapInputTools,
  selectSubsectionGeometries,
  selectSubsectionSelectedFeatures,
  selectProgramGeometries,
  selectShouldShowProgramGeometries,
  selectSelectLayerID,
} from './../module/selectors';
import {MapInputTools} from './map-input-tools';
import {area, centroid} from '@turf/turf';
import {
  addSubsectionGeometries,
  removeSubsectionGeometries,
  addSubsectionSelectedFeatures,
  removeSubsectionSelectedFeatures,
} from '../module/reducer';
import type {StyledDrawControlProps} from './styled-draw-control';
import {StyledDrawControl} from './styled-draw-control';
import {
  clickableFeaturesFillLayer,
  clickableFeaturesLineLayer,
  programGeometriesLayer,
  selectedFeaturesFillLayer,
  selectedFeaturesLineLayer,
  shapeDrawProps,
} from './map-style';
import type {SIMapInteractionType, SIMapLayerType, SIMapLayerParams} from 'containers/si/types';
import {mapboxTilesetParams} from 'containers/si/types';
import {isDefined, isNil} from '_utils/typeGuards';

export const SupplyShedMap = () => {
  const mapRef = useRef<MapRef>();

  // handle creation, selection and deletion of the drawn features
  const [drawInitialized, setDrawInitialized] = useState<boolean>(false);
  const [drawControl, setDrawControl] = useState<any>(null);
  const [selectedFeatureID, setSelectedFeatureID] = useState<string>('');
  const [selectedFeature, setSelectedFeature] = useState<any>(null);

  const dispatch = useAppDispatch();

  const [interactiveLayerIds, setInteractiveLayerIds] = useState<string[]>([]);

  const interactionType = useAppSelector(selectInteractionType);
  const shouldShowMapTools = useAppSelector(selectShouldShowMapInputTools);
  const subsectionGeometries = useAppSelector(selectSubsectionGeometries);
  const subsectionSelectedFeatures = useAppSelector(selectSubsectionSelectedFeatures);
  const programGeometries = useAppSelector(selectProgramGeometries);
  const shouldShowProgramGeometries = useAppSelector(selectShouldShowProgramGeometries);
  const selectLayerID = useAppSelector(selectSelectLayerID);
  const measurement = useAppSelector(selectMeasurement);

  useEffect(() => {
    if (interactionType === 'select') {
      setInteractiveLayerIds(['clickable-features-fill']); // enables selection
    } else {
      const availableLayerIDs = Object.keys(mapboxTilesetParams).map(key => {
        return `selected-features-${key}-fill`;
      });
      setInteractiveLayerIds(availableLayerIDs); //enables popup with delete
    }
  }, [interactionType]);

  // interact with the geometries through drawing
  const deleteGeometryFeatureByID = (featureID: string) => {
    dispatch(removeSubsectionGeometries({featureID}));
  };

  // interact with the map-selection features through the vector tiles
  const addSelectionFeature = (featureID: string, feature: any) => {
    dispatch(addSubsectionSelectedFeatures({featureID, feature}));
  };

  const deleteSelectionFeatureByID = (featureID: string) => {
    dispatch(removeSubsectionSelectedFeatures({featureID}));
  };

  // feature activation is shared between select and draw features
  const activateFeature = (featureID: string, feature: any) => {
    setSelectedFeature(feature);
    setSelectedFeatureID(featureID);
  };

  const deActivateFeature = () => {
    setSelectedFeature(null);
    setSelectedFeatureID('');
  };

  const lookupCollectionIDFromSourceLayer = (sourceLayer: string) => {
    const layers = Object.values(mapboxTilesetParams);
    for (let i = 0; i < layers.length; i++) {
      const layer = layers[i];
      if (layer.layer === sourceLayer) {
        return layer.collection_id;
      }
    }
    return null;
  };

  const lookupFeatureName = (feature: any, sourceLayer: string) => {
    // Uses the name_getter function in the source layer definition
    // to find the correct label for the given feature
    // In the future, internationalization of the value could happen here
    const layers = Object.values(mapboxTilesetParams);
    for (let i = 0; i < layers.length; i++) {
      const layer = layers[i];
      if (layer.layer === sourceLayer) {
        return layer.name_getter(feature);
      }
    }
    return feature.properties.name;
  };

  const handleLayerClick = (evt: mapboxgl.MapLayerMouseEvent) => {
    if (evt.features && evt.features.length) {
      const feature = evt.features[0];
      if (isNil(feature?.properties)) {
        return;
      }

      const featureID = feature.properties.id || feature.properties.FIPS;

      const sourceLayer = feature.sourceLayer;
      feature.properties.collection_id = lookupCollectionIDFromSourceLayer(sourceLayer);
      feature.properties.feature_name = lookupFeatureName(feature, sourceLayer);

      // toggle between selections when in select mode
      if (interactionType === 'select') {
        if (subsectionSelectedFeatures[featureID]) {
          deleteSelectionFeatureByID(featureID);
        } else {
          addSelectionFeature(featureID, feature);
        }
      } else {
        activateFeature(featureID, feature);
      }
    }
  };

  // resets the drawing mode
  const handleRequestAddNewFeature = useCallback(
    (featureType: SIMapInteractionType) => {
      if (drawControl && drawInitialized) {
        if (featureType === 'circle') {
          drawControl.changeMode('drag_circle');
        } else if (featureType === 'polygon') {
          drawControl.changeMode('draw_polygon');
        }
      }
    },
    [drawControl, drawInitialized]
  );

  // called when a feature has been edited or completed
  const onDrawUpdate = useCallback(
    e => {
      for (const feature of e.features) {
        dispatch(addSubsectionGeometries({feature}));
      }
      if (e.action === 'move') {
        deActivateFeature();
      }
    },
    [dispatch]
  );

  // called when a feature has been selected or de-selected
  const onSelection = useCallback(evt => {
    if (evt.features && evt.features.length) {
      const selectID = evt.features[0].id;
      activateFeature(selectID, evt.features[0]);
    }
  }, []);

  // load the drawing controls
  // and configure them to work on the map
  useEffect(() => {
    if (mapRef.current) {
      // only initialize the draw control once
      if (!drawInitialized) {
        const drawProps = {
          activeFillColor: shapeDrawProps.activeFillColor,
          activeFillOpacity: shapeDrawProps.activeFillOpacity,
          activeLineColor: shapeDrawProps.activeLineColor,
          activeLineWidth: shapeDrawProps.activeLineWidth,
          inactiveFillColor: shapeDrawProps.inactiveFillColor,
          inactiveLineColor: shapeDrawProps.inactiveLineColor,
          inactiveFillOpacity: shapeDrawProps.inactiveFillOpacity,
          inactiveLineWidth: shapeDrawProps.inactiveLineWidth,
        } as StyledDrawControlProps;
        const styledDrawControl = new StyledDrawControl(drawProps);
        setDrawInitialized(true);
        setDrawControl(styledDrawControl);
        mapRef.current.addControl(styledDrawControl);

        mapRef.current.on('draw.create', onDrawUpdate);
        mapRef.current.on('draw.update', onDrawUpdate);
        mapRef.current.on('draw.selectionchange', onSelection);
      }
      // set the draw mode
      if (isDefined(drawControl)) {
        if (interactionType === 'circle') {
          drawControl.changeMode('drag_circle');
        } else if (interactionType === 'polygon') {
          drawControl.changeMode('draw_polygon');
        } else {
          drawControl.changeMode('simple_select');
        }
      }
    }
  }, [interactionType, drawControl, drawInitialized, onDrawUpdate, onSelection]);

  useEffect(() => {
    if (drawControl) {
      // If the subsectionGeometries have changed in Redux, update the map to match
      drawControl.set({
        type: 'FeatureCollection',
        features: Object.values(subsectionGeometries),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subsectionGeometries]);

  // locate the popup anchor point
  const selectedFeatureCentroid = useMemo(() => {
    if (selectedFeature && selectedFeatureID) {
      return centroid(selectedFeature);
    }
  }, [selectedFeature, selectedFeatureID]);

  // calculate the area in ha of the clicked feature
  const selectedFeatureHa = useMemo(() => {
    if (selectedFeature && selectedFeatureID) {
      // area() function returns in square meters
      // area(selectedFeature) / 10000 is in ha
      return convertUnit('ha', 'ac', area(selectedFeature) / 10000 || 0).toFixed();
    }
  }, [selectedFeature, selectedFeatureID]);

  const selectedFeatureLabel = useMemo(() => {
    if (selectedFeature && selectedFeatureID) {
      if (selectedFeature.source === 'selected-features') {
        return selectedFeatureID;
      } else if (selectedFeature.properties && selectedFeature.properties.center) {
        return 'Custom Circle';
      } else {
        return 'Custom Polygon';
      }
    }
  }, [selectedFeature, selectedFeatureID]);

  // remove a feature when deleted
  const handleFeatureDelete = (featureID: string) => {
    if (drawControl && drawInitialized) {
      deleteGeometryFeatureByID(featureID);
      deleteSelectionFeatureByID(featureID);
      deActivateFeature();
    }
  };

  const shouldShowPopup =
    // a feature has been activated
    selectedFeatureID &&
    selectedFeature &&
    // properties have been computed for the active feature
    selectedFeatureCentroid &&
    selectedFeatureHa &&
    selectedFeatureLabel;

  const activeFeaturesFilter = useMemo(() => {
    const featureIDs = Object.keys(subsectionSelectedFeatures);

    return [
      'any',
      ['in', ['get', 'id'], ['literal', featureIDs]],
      ['in', ['get', 'FIPS'], ['literal', featureIDs]],
    ];
  }, [subsectionSelectedFeatures]);

  const selectLayers = useMemo(() => {
    const layerArr: any[] = [];
    Object.entries(mapboxTilesetParams).forEach(value => {
      const [layerName, layerParams] = value as [SIMapLayerType, SIMapLayerParams];
      layerArr.push(
        <Source
          key={`selected-features-${layerName}`}
          id={`selected-features-${layerName}`}
          type="vector"
          url={layerParams.url}
        >
          <Layer
            key={`selected-features-${layerName}-fill`}
            id={`selected-features-${layerName}-fill`}
            source-layer={layerParams.layer}
            {...selectedFeaturesFillLayer}
            filter={activeFeaturesFilter}
          />
          <Layer
            key={`selected-features-${layerName}-line`}
            id={`selected-features-${layerName}-line`}
            source-layer={layerParams.layer}
            {...selectedFeaturesLineLayer}
            filter={activeFeaturesFilter}
          />
        </Source>
      );
    });
    return layerArr;
  }, [activeFeaturesFilter]);

  const {layer, url} = mapboxTilesetParams[selectLayerID];

  return (
    <SIBaseMap mapRef={mapRef} interactiveLayerIds={interactiveLayerIds} onClick={handleLayerClick}>
      {/* Project geometries -- these are not selectable and should not be shown in editing state */}
      {shouldShowProgramGeometries && programGeometries && (
        <Source id="project-geometries" type={'geojson'} data={programGeometries}>
          <Layer {...programGeometriesLayer} />
        </Source>
      )}
      {/* Map features that show the boundaries of standard map units */}
      {interactionType === 'select' && (
        <Source key={layer} type="vector" url={url}>
          <Layer key={`${layer}-fill`} source-layer={layer} {...clickableFeaturesFillLayer} />
          <Layer key={`${layer}-line`} source-layer={layer} {...clickableFeaturesLineLayer} />
        </Source>
      )}
      {/* Selected States and Counties on the map */}
      {[...selectLayers]}

      {/* Tools for the user to interact with the map */}
      {shouldShowMapTools && (
        <MapInputTools
          mapRef={mapRef}
          canAddNewFeature={interactionType === 'circle' || interactionType === 'polygon'}
          handleAddNewFeature={handleRequestAddNewFeature}
        />
      )}

      {/* Popup to allow feature deletion on the map */}
      {shouldShowPopup && (
        <Popup
          longitude={selectedFeatureCentroid.geometry.coordinates[0]}
          latitude={selectedFeatureCentroid.geometry.coordinates[1]}
          anchor={'bottom'}
          onClose={() => {
            deActivateFeature();
          }}
        >
          {/* <Text>{selectedFeatureLabel}</Text> */}
          <Text>
            Total Area: {classifyAreaWithUnits(Math.round(selectedFeatureHa), measurement)}
          </Text>
          <Flex justifyContent="flex-end">
            <FluroButton
              raised
              grayBorder
              blank
              onClick={() => {
                handleFeatureDelete(selectedFeatureID);
              }}
            >
              Delete
            </FluroButton>
          </Flex>
        </Popup>
      )}
    </SIBaseMap>
  );
};
