import type {AppStore} from 'reducers';
import type {User, Organization} from './types';
import type {Subscription} from '../../../modules/subscriptions';

export const selectUsersList = (state: AppStore): User[] => state.users.list;

export const selectUserById = (users: User[], userId: number) => users.find(u => u.id === userId);

export const selectUserSubscription = (state: AppStore): Subscription[] =>
  state.users.subscriptions;

export const selectSubscriptionById = (subscriptions: Subscription[], subscriptionId: number) =>
  subscriptions.find(s => s.id === subscriptionId);

export const selectUserOrganizations = (state: AppStore): Organization[] =>
  state.users.organizations;
