import React, {useCallback, useMemo} from 'react';
import {GeoJSON} from 'react-leaflet';
import {useSelector} from 'react-redux';
import type {AppStore} from 'reducers';
import {featureCollection} from '@turf/turf';
import {genKey} from '_utils/pure-utils';

export const SeasonGeometriesAddingOverlay = () => {
  const geometries = useSelector((state: AppStore) => state.partFieldSeason.geometry);

  const [id, fc] = useMemo(() => [genKey(), featureCollection(geometries)], [geometries]);

  const onEachFeature = useCallback((feature: any, layer: any) => {
    layer.setStyle({
      color: feature.properties.fill,
      fillOpacity: feature.properties.fillOpacity || 0.5,
    });
  }, []);

  if (geometries.length) {
    return <GeoJSON key={id} data={fc} onEachFeature={onEachFeature} />;
  }

  return null;
};

export default SeasonGeometriesAddingOverlay;
