import type {SIGeometryFilterType} from 'modules/sustainability-insights/types';
import type {ReactElement} from 'react';
import React from 'react';
import {useAppSelector} from '_hooks';

type Props = {
  type: SIGeometryFilterType;
  children: ReactElement | ReactElement[];
};

const SICardTypeRouter = ({type, children}: Props) => {
  const activeGeometryType = useAppSelector(
    s => s.sustainabilityInsights.filter.activeGeometryType
  );

  if (type !== activeGeometryType) {
    return null;
  }

  return <>{children}</>;
};

export default SICardTypeRouter;
