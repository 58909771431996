import React, {useCallback} from 'react';
import {dialogToggle, DialogType} from 'modules/helpers';
import {selectMRVProgramById, selectPhaseById} from './monitoring/module/selectors';
import {useAppDispatch, useAppSelector} from '_hooks';
import {selectDialogData} from '../../modules/helpers/selectors';
import {FluroDialog, FluroButton, Text} from 'components';
import styled from 'styled-components';
import {t} from 'i18n-utils';

import {useMonitoringUrlParams} from './monitoring/hooks';
import {sanitize} from 'dompurify';

export const MRVNoContractDialog = () => {
  const {visible = false, payload} = useAppSelector(s =>
    selectDialogData(s, DialogType.noContractInfo)
  );

  const {programId} = useMonitoringUrlParams();
  const program = useAppSelector(s => selectMRVProgramById(s, programId));
  const phase = useAppSelector(s => selectPhaseById(s, payload?.phaseId));

  const dispatch = useAppDispatch();

  const onHide = useCallback(() => dispatch(dialogToggle(DialogType.noContractInfo, false)), []);

  return (
    <FluroDialog
      id={'mrv-no-contract-dialog'}
      dialogClassName={'mrv-no-contract-dialog'}
      title={t({id: 'Contract not yet available'})}
      onHide={onHide}
      visible={visible}
      width={400}
      portal={true}
      isClosable={false}
    >
      {phase?.no_contract_message ? (
        <div
          dangerouslySetInnerHTML={{
            __html: sanitize(phase?.no_contract_message),
          }}
        />
      ) : (
        <>
          <p>Thank you for participating in {program?.name}.</p>
          <p>
            A signature will be required to participate in the program, but is not yet available.
          </p>
          <p>Return later to sign your contract.</p>
        </>
      )}

      {payload?.error && (
        <Text secondary inline>
          {payload.error}
        </Text>
      )}

      <Buttons>
        <FluroButton raised primary onClick={onHide}>
          ok
        </FluroButton>
      </Buttons>
    </FluroDialog>
  );
};

const Buttons = styled.div`
  width: 100%;
  margin-top: 16px;
  display: flex;
  justify-content: flex-end;
`;
