import type {FieldValuesRow, MRVEntityType, MRVValueInput} from 'containers/mrv/types';
import {MRVAttributeType, MRVPhaseType, MRVProgress} from 'containers/mrv/types';
import {keyBy} from 'lodash';
import {createRow} from '../value-utils';

export type PopulateValue = {attributeType: MRVAttributeType; attributeId: number; value: string};
export const createNewRowValues = (
  phaseType: MRVPhaseType,
  entityType: MRVEntityType,
  rowId: number,
  populateValues: PopulateValue[]
): MRVValueInput[] => {
  return populateValues.map(v => {
    return {
      entity_type: entityType,
      row_id: rowId,
      attribute_id: v.attributeId,
      value: v.value,
      locked: false,
      confirmed: true,
      progress:
        phaseType === MRVPhaseType.Enrollment ? MRVProgress.Enrolment : MRVProgress.Monitoring,
    };
  });
};

export const getIndexesPerYear = (rows: {year: number}[]) => {
  const firstRowIndexPerYear: {[year: number]: number} = {};
  const lastRowIndexPerYear: {[year: number]: number} = {};
  (rows || []).forEach((row, i) => {
    if (!row.year) return;
    if (firstRowIndexPerYear[row.year] == null) {
      firstRowIndexPerYear[row.year] = i;
    }
    lastRowIndexPerYear[row.year] = i;
  });
  return {firstRowIndexPerYear, lastRowIndexPerYear};
};

/**
 * Mutates `rows` AND returns the new values.
 */
export const populateMissingYearRows = (
  phaseType: MRVPhaseType,
  entityType: MRVEntityType,
  fieldId: number,
  rows: FieldValuesRow[],
  nextRowIdRef: {current: number},
  missingYears: number[],
  currentYear: number,
  attributeIds: Partial<Record<MRVAttributeType, number>>
) => {
  // Precreate missing year rows.
  const newValues: MRVValueInput[] = [];
  missingYears.forEach(year => {
    // Keep recalculating indexes in each iteration, because we mutate rows on each iteration.
    const {lastRowIndexPerYear} = getIndexesPerYear(rows);

    let shouldReplaceRow = false;
    // Since years are descending, the previous year should be + 1.
    // I.e. we have 2022 and we're looking for 2021. We're gonna check for the 2021 + 1 row.
    const prevYearRowIndex = lastRowIndexPerYear[year + 1];

    const populateValues: PopulateValue[] = [];
    const yearAttributeId = attributeIds[MRVAttributeType.RecordYear];
    if (yearAttributeId) {
      shouldReplaceRow = !rows[prevYearRowIndex]?.values[yearAttributeId]?.value;
      populateValues.push({
        attributeId: yearAttributeId,
        attributeType: MRVAttributeType.RecordYear,
        value: String(year),
      });
    }
    const tillagePeriodAttributeId = attributeIds[MRVAttributeType.TillagePeriod];
    if (tillagePeriodAttributeId) {
      const prevYearValue = rows[prevYearRowIndex]?.values[tillagePeriodAttributeId]?.value;
      populateValues.push({
        attributeId: tillagePeriodAttributeId,
        attributeType: MRVAttributeType.TillagePeriod,
        value: prevYearValue === 'spring' ? 'fall' : 'spring',
      });
    }
    const plantingSeasonAttributeId = attributeIds[MRVAttributeType.PlantingSeason];
    if (plantingSeasonAttributeId) {
      const prevYearValue = rows[prevYearRowIndex]?.values[plantingSeasonAttributeId]?.value;
      populateValues.push({
        attributeId: plantingSeasonAttributeId,
        attributeType: MRVAttributeType.PlantingSeason,
        value: prevYearValue === 'summer' ? 'winter' : 'summer',
      });
    }

    // We already bumped nextRowId. If we need to replace the row, we need to get 1 back.
    if (shouldReplaceRow) {
      nextRowIdRef.current = nextRowIdRef.current - 1;
    }
    const rowId = nextRowIdRef.current;
    nextRowIdRef.current = nextRowIdRef.current + 1;

    // 1. Create new values.
    const newRowValues = createNewRowValues(phaseType, entityType, rowId, populateValues);

    // 2. Update the values to send with the bulk update to the server via onAddValuesToBulk.
    newValues.push(...newRowValues);

    // 3. Update the local `rows` state.
    const newRow = createRow(
      entityType,
      fieldId,
      rowId,
      Number(newRowValues.find(v => v.attribute_id === yearAttributeId)?.value) || currentYear,
      keyBy(newRowValues, 'attribute_id')
    );

    const rowIndex =
      prevYearRowIndex == null ? 0 : shouldReplaceRow ? prevYearRowIndex : prevYearRowIndex + 1;
    rows.splice(rowIndex, shouldReplaceRow ? 1 : 0, newRow);
  });

  return newValues;
};
