import React from 'react';
import type {BarSvgProps} from '@nivo/bar';
import {Flex} from 'components';
import {Circle} from 'components/shapes/circle';

// The legend component is in the upper right corner of the chart, so the <g> element is set to the width of the chart
// The <foreignObject> element is set to overflow: 'visible' and height: 1 so that the <div> can use absolute positioning
export const EFLegend = ({legends, margin, outerWidth}: BarSvgProps & {outerWidth: number}) => {
  return (
    <>
      {legends?.map(legend => (
        <g key={'legend'} transform={`translate(${0 - (margin?.left || 310)}, 8)`}>
          <foreignObject style={{overflow: 'visible'}} width={outerWidth} height="1">
            <div className="ef-legend">
              {legend?.data?.map(value => {
                const {id, label, color} = value as unknown as {
                  id: string;
                  label: string;
                  color: string;
                };

                return (
                  <Flex key={id} direction="row" nowrap alignItems="flex-start" fullWidth gap="4px">
                    <Circle inline size={12} color={color} className="ef-legend-circle" />
                    <div className="ef-legend-text">{label}</div>
                  </Flex>
                );
              })}
            </div>
          </foreignObject>
        </g>
      ))}
    </>
  );
};
