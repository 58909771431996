import {ActionTypes} from '../reducer/types';
import {t} from 'i18n-utils';
import {showNotification} from 'components/notification/notification';
import moment from 'moment';
import {area as turfArea} from '@turf/turf';
import {
  getAnomalyLabelValue,
  getLabelDataByValue,
  getLabelNameByValue,
  GLOBAL_FORMAT_DATE,
} from '_constants';
import {ActivityApi, AnomaliesApi} from '_api';
import {getClosestGeometryDate, getNearestGeometryDate, mapBarScrollTo} from '../utils';
import type {AnomalyTab, IAnomaly, TAnomalyProps} from '../features/anomalies/types';
import {
  isPermanentAnomaly,
  isPermanentAnomalyLabel,
  userFacingPriority,
  prepareAllLowPerfAnomalies,
  setLowPerfAnomalyProps,
} from '../features/anomalies/anomalies-utils';
import {setDate, setFeature, setSensor, toggleFieldGeometries} from '../actions';
import {changeAreaOfInterestProp} from './areas-of-interest-actions';
import {
  booleanIntersectCurrentField,
  denormalizeSensorIndex,
  getDateType,
  sensorView,
} from '_utils';
import {isDateInRange} from '_utils/pure-utils';
import type {AppStore} from 'reducers';
import {reportError} from 'containers/error-boundary';
import Mixpanel from '_utils/mixpanel-utils';
import type {RequestStatus} from '../../../types';
import {parseSensor} from '../../../types';
import {parseGeometryFile} from '../utils/file';
import {
  selectAnomalies,
  selectIsAnomaliesTagExist,
  selectLowPerformingAnomalies,
} from '../features/anomalies/anomalies-selectors';
import {
  selectCurrentDate,
  selectCurrentDates,
  selectCurrentFarmId,
  selectCurrentField,
  selectCurrentFieldId,
  selectCurrentFieldKml,
  selectCurrentImageName,
  selectCurrentImageObj,
  selectCurrentSeason,
  selectCurrentSeasonId,
  selectCurrentSensor,
  selectCurrentTab,
  selectGeometriesOnMap,
} from '../reducer/selectors';
import type {AppDispatch} from 'store';
import {dialogToggle, DialogType} from 'modules/helpers';

// PREMIUM ANOMALIES

export const togglePremiumAnomalies =
  (value: boolean) => (dispatch: AppDispatch, getState: () => AppStore) => {
    const state = getState();
    const feature = selectCurrentTab(state);
    const geometriesOnMap = selectGeometriesOnMap(state);
    const {anomaliesSelectedTab} = selectAnomalies(state);

    if (value) {
      feature !== 'crop' && dispatch(setFeature('crop'));
      !geometriesOnMap && dispatch(toggleFieldGeometries(true));
      !geometriesOnMap && dispatch(toggleFieldGeometries(true));
      anomaliesSelectedTab !== 'crop-stress' && dispatch(onChangeAnomalyTab('crop-stress'));
    } else {
      feature === 'crop' && dispatch(setFeature('farm'));
      dispatch({
        type: ActionTypes.MAP_PREMIUM_ANOMALIES_HIDE,
      });
    }
  };

export const getPremiumAnomalies = () => (dispatch: AppDispatch, getState: () => AppStore) => {
  const state = getState();
  const currentSeason = selectCurrentSeason(state);
  const field = selectCurrentField(state);
  const farmId = selectCurrentFarmId(state);
  const currentDate = selectCurrentDate(state);
  const currentDates = selectCurrentDates(state);

  if (!currentSeason || !selectIsAnomaliesTagExist(currentSeason)) {
    return;
  }

  const {kmlId} = currentSeason;
  if (kmlId !== field.ID) {
    return;
  }

  dispatch({type: ActionTypes.MAP_LOAD_PREMIUM_ANOMALIES});

  AnomaliesApi.getPremiumAnomalies(
    farmId,
    kmlId,
    currentDates[currentDate]?.Date,
    currentDates[currentDate]?.Date
  )
    .then(({data}) => {
      dispatch({
        type: ActionTypes.MAP_PREMIUM_ANOMALIES_LOADED,
        data: (data.result.features || []).map((anomaly: IAnomaly) => {
          const {anomaly_ndvi, priority, label} = anomaly.properties;
          anomaly.properties = {
            ...anomaly.properties,
            priority: userFacingPriority(priority, anomaly.properties.label),
            checked: false,
            area: turfArea(anomaly),
            startDate: anomaly.properties.first_sensing_date, // TODO remove these fields, track usages
            endDate: anomaly.properties.last_sensing_date,
            mean: Math.round(denormalizeSensorIndex(anomaly_ndvi, -1)),
            label: getAnomalyLabelValue(label),
          };
          return anomaly;
        }),
      });
    })
    .catch();
};

export const updatePremiumAnomaly =
  (data: any) => (dispatch: AppDispatch, getState: () => AppStore) => {
    const state = getState();
    const farmId = selectCurrentFarmId(state);
    const kmlId = selectCurrentFieldId(state);

    const {prop, anomaly} = data;
    let {value} = data;
    const isNeedRequest = ['label', 'description', 'snoozed'].includes(prop);
    const resultData = {[prop]: value} as Partial<TAnomalyProps>;

    if (prop === 'label' && isPermanentAnomalyLabel(value)) {
      // if user sets a permanent anomaly label, snooze the shape
      resultData.snoozed = 1;
    }

    if (prop === 'snoozed' && !value && isPermanentAnomaly(anomaly)) {
      return showNotification({
        title: t({id: 'note.warning', defaultMessage: 'Warning'}),
        message: t({
          id: 'This shape is labeled as a permanent anomaly and can not be un-snoozed.',
        }),
        type: 'warning',
      });
    }

    if (prop === 'label') {
      Mixpanel.labelCropStress(getLabelNameByValue(value), anomaly.properties.anomaly_id);
    }

    if (prop === 'snoozed') {
      Mixpanel.toggleCropStress(value, anomaly.properties.anomaly_id);
    }

    if (isNeedRequest) {
      const {anomaly_id} = anomaly.properties;
      const requestData = [
        {
          anomaly_id,
          snoozed: anomaly.properties.snoozed,
          label: anomaly.properties.label,
          description: anomaly.properties.description,
          // We need to pass all the other rewritable values,
          // so PUT won't rewrite them with empty values.
          ...resultData,
        },
      ];
      AnomaliesApi.update(farmId, kmlId, requestData).then(({data}) => {
        dispatch({
          type: ActionTypes.MAP_UPDATE_PREMIUM_ANOMALY_LIST,
          list: data.result?.updated || [],
        });
        showNotification({
          title: t({id: 'note.success', defaultMessage: 'Success'}),
          message: t({id: 'Anomalies were saved'}),
          type: 'success',
        });
      });
    } else {
      dispatch({
        type: ActionTypes.MAP_UPDATE_PREMIUM_ANOMALY_PROP,
        data,
      });
    }
  };

export const updateBulkPremiumAnomaly =
  (data: {prop: string; value: any; anomalies: IAnomaly[]}) =>
  (dispatch: AppDispatch, getState: () => AppStore) => {
    const state = getState();
    const farmId = selectCurrentFarmId(state);
    const kmlId = selectCurrentFieldId(state);

    const {prop, value, anomalies} = data;
    const filteredAnomalies =
      prop === 'snoozed' && !value ? anomalies.filter(a => !isPermanentAnomaly(a)) : anomalies;
    const isNeedRequest = ['label', 'description', 'snoozed'].includes(prop);

    if (!filteredAnomalies.length || anomalies.length - filteredAnomalies.length) {
      showNotification({
        title: t({id: 'note.warning', defaultMessage: 'Warning'}),
        message: t({
          id: 'Some of the shapes are labeled as permanent anomalies and can not be un-snoozed.',
        }),
        type: 'warning',
      });
    }

    if (isNeedRequest && filteredAnomalies.length) {
      const requestData = filteredAnomalies.map(
        ({properties: {anomaly_id, label, description, snoozed}}: any) => ({
          anomaly_id,
          snoozed,
          label,
          description,
          [prop]: value,
        })
      );
      AnomaliesApi.update(farmId, kmlId, requestData).then(({data}) => {
        dispatch({
          type: ActionTypes.MAP_UPDATE_PREMIUM_ANOMALY_LIST,
          list: data.result.updated || [],
        });
        showNotification({
          title: t({id: 'note.success', defaultMessage: 'Success'}),
          message: t({id: 'Anomalies were saved'}),
          type: 'success',
        });
      });
    } else {
      dispatch({
        type: ActionTypes.MAP_BULK_UPDATE_PREMIUM_ANOMALY_PROP,
        data: {...data, anomalies: filteredAnomalies},
      });
    }
  };

export const removePremiumAnomaly = (anomalyIds: number[]) => (dispatch: AppDispatch) => {
  return Promise.all(anomalyIds.map(AnomaliesApi.removePremiumAnomaly)).then(() => {
    dispatch({
      type: ActionTypes.MAP_REMOVE_PREMIUM_ANOMALIES,
      anomalyIds,
    });
    showNotification({
      title: t({id: 'note.success', defaultMessage: 'Success'}),
      message: t({id: 'saveAnomalyPlural'}, {count: anomalyIds.length}),
      type: 'success',
    });
  });
};

// Agworld

export const uploadAnomaliesToAgworld =
  (anomalies: IAnomaly[]) => (dispatch: AppDispatch, getState: () => AppStore) => {
    const state = getState();
    const currentSensor = selectCurrentSensor(state);
    const currentSeasonId = selectCurrentSeasonId(state);
    const date = selectCurrentImageObj(state)?.date;

    ActivityApi.uploadAnomaliesToAgworld(
      `${currentSeasonId}/${currentSensor}/${date}`,
      anomalies.map(a => ({anomalyId: String(a.properties.anomaly_id)}))
    )
      .then(() => {
        showNotification({
          title: t({id: 'note.success', defaultMessage: 'Success'}),
          message: t({id: 'Anomalies were uploaded to Agworld.'}),
          type: 'success',
        });
      })
      .catch(err => {
        reportError('Failed to export anomalies to Agworld: ' + err);
      });
  };

// agX

export const uploadAnomaliesToAgX =
  (anomalies: IAnomaly[], type: AnomalyTab) =>
  (dispatch: AppDispatch, getState: () => AppStore) => {
    const state = getState();
    const currentSensor = selectCurrentSensor(state);
    const currentSeasonId = selectCurrentSeasonId(state);
    const date = selectCurrentImageObj(state)?.date;

    let requests = [];
    if (type === 'areas-of-interest') {
      const lowPerfAnomalies = anomalies.filter(el => el.properties.isLowPerf);
      const manuallyAnomalies = anomalies.filter(el => !el.properties.isLowPerf);
      if (manuallyAnomalies.length) {
        requests.push(
          ActivityApi.uploadAnomaliesToAgX(
            `${currentSeasonId}/${currentSensor}/${date}?type=geometries`,
            manuallyAnomalies.map((s: any) => ({
              anomalyId: `${s.properties.id}`,
              ...agxLabelsPrpsPart(s.properties.label),
            }))
          )
        );
      }
      if (lowPerfAnomalies.length) {
        requests.push(
          ActivityApi.uploadAnomaliesToAgX(
            `${currentSeasonId}/${currentSensor}/${date}?type=old`,
            lowPerfAnomalies.map((s: any) => {
              const {imageID, groupID, kmlID} = s;

              return {
                imageID,
                groupID,
                kmlID,
                anomalyID: `${s.properties.id}`,
                ...agxLabelsPrpsPart(s.properties.label),
              };
            })
          )
        );
      }
    } else {
      if (anomalies.length) {
        requests.push(
          ActivityApi.uploadAnomaliesToAgX(
            `${currentSeasonId}/${currentSensor}/${date}?type=anomalies`,
            anomalies.map((s: any) => ({
              anomalyId: `${s.properties.anomaly_id}`,
              ...agxLabelsPrpsPart(s.properties.label),
            }))
          )
        );
      }
    }

    Promise.all(requests)
      .then(() => {
        showNotification({
          title: t({id: 'note.success', defaultMessage: 'Success'}),
          message: t({id: 'Anomalies were uploaded to agX.'}),
          type: 'success',
        });
      })
      .catch();
  };

function agxLabelsPrpsPart(label: any) {
  const lbl = getLabelDataByValue(label);

  return {
    anomalyType: lbl && lbl.anomalyType ? lbl.anomalyType : '',
    subTypeId: lbl && lbl.subTypeId ? lbl.subTypeId : '',
    anomalyValue: lbl && lbl.value ? lbl.value : '',
  };
}

// SIMPLE (LOW PERF) ANOMALIES

export const toggleLowPerformingAreas =
  (value: boolean, anomaly?: IAnomaly) => (dispatch: AppDispatch, getState: () => AppStore) => {
    const state = getState();
    const currentDates = selectCurrentDates(state);
    const {list} = selectLowPerformingAnomalies(state);
    const currentSensor = selectCurrentSensor(state);
    const currentDate = selectCurrentDate(state);
    const currentSeasonId = selectCurrentSeasonId(state);
    const currentImageName = selectCurrentImageName(state);

    const currentDateAnomalies = list[currentImageName];

    if (value && (!currentDate || !currentSeasonId)) {
      return showNotification({
        title: !currentSeasonId ? 'Season data missing' : 'Oops',
        message: !currentSeasonId
          ? t({id: 'Set up your season data first'})
          : t({
              id: 'Your season data is empty. Please, select another season, or field.',
              defaultMessage: '',
            }),
        type: 'warning',
      });
    }

    if (anomaly && value) {
      const anomalyDate = `${moment(anomaly.date, GLOBAL_FORMAT_DATE).format('DD/MM/YYYY')}-${
        anomaly.dateType
      }`;

      if (currentDates[anomalyDate] && currentDate !== anomalyDate) dispatch(setDate(anomalyDate));

      if (anomaly.index && currentSensor !== anomaly.index) dispatch(setSensor(anomaly.index));
    }

    value && mapBarScrollTo('#anomalies');

    dispatch({
      type: ActionTypes.MAP_TOGGLE_LOW_PERF_ANOMALIES,
      value,
    });

    if (!value && list.uploadedROI?.features) {
      dispatch({
        type: ActionTypes.MAP_UPDATE_LOW_PERF_ANOMALIES,
        lowPerformingAreas: {uploadedROI: {}},
      });
    }

    if (!anomaly && value && !currentDateAnomalies) {
      // show anomalies component, get data from server
      dispatch(generateLowPerfAnomalies());
    }
  };

export const getLowPerfAnomalies = () => (dispatch: AppDispatch, getState: () => AppStore) => {
  const state = getState();
  const selectedFieldId = selectCurrentFieldId(state);
  const farmId = selectCurrentFarmId(state);

  return ActivityApi.getLowPerfAnomalies(farmId, selectedFieldId).then(({data}) => {
    dispatch({
      type: ActionTypes.MAP_SET_LOW_PERF_ANOMALIES,
      lowPerformingAreas: prepareAllLowPerfAnomalies(data.result || []),
    });
  });
};

export const generateLowPerfAnomalies = () => (dispatch: AppDispatch, getState: () => AppStore) => {
  const state = getState();
  const currentDates = selectCurrentDates(state);
  const currentSensor = selectCurrentSensor(state);
  const currentDate = selectCurrentDate(state);
  const selectedFieldId = selectCurrentFieldId(state);
  const farmId = selectCurrentFarmId(state);
  const currentImageName = selectCurrentImageName(state);

  const isCorrectSensor = !['NONE', 'TCI', 'NC'].includes(currentSensor);
  const [normalizedCurrentDate, currentDateType] = currentDate.split('-');

  if (!Object.keys(currentDates).length || !isCorrectSensor) {
    if (!isCorrectSensor) {
      showNotification({
        title: t({id: 'note.warning', defaultMessage: 'Warning'}),
        message: t(
          {
            id: 'cannotGenerateLowPerAnomalies',
            defaultMessage:
              'We can not generate low performing areas on a {sensor} layer. Please select another layer.',
          },
          {sensor: sensorView(currentSensor)}
        ),
        type: 'warning',
      });
    }
    return dispatch({
      type: ActionTypes.MAP_UPDATE_LOW_PERF_ANOMALIES,
      lowPerformingAreas: {},
    });
  }

  return ActivityApi.generateLowPerfAnomalies(farmId, selectedFieldId, currentImageName)
    .then(({data}) => {
      data.result.date = moment(normalizedCurrentDate, 'DD/MM/YYYY').format(GLOBAL_FORMAT_DATE);
      data.result.dateType = currentDateType;
      const lowPerformingAreas = data.result
        ? {
            ...data.result,
            key: moment().valueOf(),
            features: data.result.features
              ? setLowPerfAnomalyProps(data.result, currentSensor, true)
              : [],
          }
        : {};

      dispatch({
        type: ActionTypes.MAP_UPDATE_LOW_PERF_ANOMALIES,
        lowPerformingAreas: {[currentImageName]: lowPerformingAreas},
      });
    })
    .catch(() => {
      showNotification({
        title: t({id: 'Oops'}),
        message: t({
          id: 'Something wrong with anomalies for this layer, try to change to another one or try later.',
        }),
        type: 'warning',
      });
    });
};

export const changeLowPerfAnomalyProp =
  <K extends keyof TAnomalyProps>(
    anomalyToChange: IAnomaly[] | IAnomaly,
    prop: K,
    value: TAnomalyProps[K]
  ) =>
  (dispatch: AppDispatch, getState: () => AppStore) => {
    const state = getState();
    const currentDates = selectCurrentDates(state);
    const {list, isVisible} = selectLowPerformingAnomalies(state);

    const currentDatesLength = Object.keys(currentDates).length;
    const {date, dateType, index, properties} = Array.isArray(anomalyToChange)
      ? anomalyToChange[0]
      : anomalyToChange;
    const anomalyDate = `${moment(date, GLOBAL_FORMAT_DATE).format('DD/MM/YYYY')}-${dateType}`;
    const anomalyName = properties.isLowPerf
      ? currentDates[anomalyDate] && currentDates[anomalyDate]?.[parseSensor(String(index))]?.name
      : 'uploadedROI';

    if (!properties.uploading && !currentDates[anomalyDate] && currentDatesLength) {
      showNotification({
        title: t({id: 'note.error', defaultMessage: 'Error'}),
        message: t({
          id: 'imgCloudyOrHidden',
          defaultMessage:
            'Your imagery is cloudy or hidden, go to the remote sensing tab to display it.',
        }),
        type: 'warning',
      });
    }

    const currentDateAnomalies = list[String(anomalyName)];

    if (!currentDateAnomalies) return false;

    const updatedAnomalies = {
      [String(anomalyName)]: {
        ...currentDateAnomalies,
        features: currentDateAnomalies.features.map((anomaly: IAnomaly) => {
          if (
            Array.isArray(anomalyToChange)
              ? anomalyToChange.find((a: IAnomaly) => a.properties.id === anomaly.properties.id)
              : anomalyToChange.properties.id === anomaly.properties.id
          )
            return {
              ...anomaly,
              properties: {
                ...anomaly.properties,
                [prop]: value,
              },
            };

          return anomaly;
        }),
      },
    };
    dispatch({
      type: ActionTypes.MAP_UPDATE_LOW_PERF_ANOMALIES,
      lowPerformingAreas: updatedAnomalies,
    });

    if (anomalyName !== 'uploadedROI' && !isVisible && prop === 'label') {
      dispatch(saveLowPerfAnomalies(anomalyDate, updatedAnomalies[String(anomalyName)]));
    }
  };

export const toggleAnomaliesHistory = (value: boolean) => ({
  type: ActionTypes.MAP_AREAS_OF_INTEREST_TOGGLE_HISTORY,
  value,
});

export const uploadAnomalies =
  (files: File[]) => (dispatch: AppDispatch, getState: () => AppStore) => {
    const state = getState();
    const currentSensor = selectCurrentSensor(state);
    const currentDate = selectCurrentDate(state);
    const currentSeason = selectCurrentSeason(state);
    const currentFieldKml = selectCurrentFieldKml(state);

    const {
      list: {uploadedROI = {} as any},
    } = selectLowPerformingAnomalies(state);

    const featureFromUploadedFile: any = {};

    Promise.all(files.map(f => parseGeometryFile(f)))
      .then(result => {
        if (result.find(file => file.errors.length)) {
          const parsingErrors = result.reduce((acc: string[], r) => [...acc, ...r.errors], []);

          Array.isArray(parsingErrors) &&
            parsingErrors.forEach(e => {
              showNotification({
                title: t({id: 'note.warning', defaultMessage: 'Warning'}),
                //TODO: i18n
                message: e,
                type: 'warning',
              });
            });
          return;
        }
        dispatch(dialogToggle(DialogType.addNewAnomaly, false));
        const features = result
          .map((f: any) => f.features[0].features)
          .flat(1)
          .map(f => ({
            ...f,
            properties: {
              ...f.properties,
              startDate: moment(currentSeason?.startDate, GLOBAL_FORMAT_DATE).format(
                GLOBAL_FORMAT_DATE
              ),
              endDate: moment(currentSeason?.endDate, GLOBAL_FORMAT_DATE).format(
                GLOBAL_FORMAT_DATE
              ),
            },
          }));
        featureFromUploadedFile.features = features;
        featureFromUploadedFile.key = moment().valueOf();
        featureFromUploadedFile.dateType = currentDate ? getDateType(currentDate) : '';
        featureFromUploadedFile.savedAsRoi = true; // mark uploading geometries to identify them later
        featureFromUploadedFile.features = setLowPerfAnomalyProps(
          featureFromUploadedFile,
          currentSensor
        ).filter((anomaly: any) => booleanIntersectCurrentField(currentFieldKml, anomaly));

        if (featureFromUploadedFile.features.length) {
          const filteredDuplicates = featureFromUploadedFile.features.filter((f: any) => {
            const duplicate = uploadedROI.features
              ? uploadedROI.features.find((g: any) => g.properties.area === f.properties.area)
              : false;
            if (duplicate)
              showNotification({
                title: t({id: 'note.warning', defaultMessage: 'Warning'}),
                message:
                  "You've tried to upload the already existing geometry, please select another one.",
                type: 'warning',
              });

            return !duplicate;
          });

          dispatch({
            type: ActionTypes.MAP_UPLOAD_AREAS_OF_INTEREST,
            lowPerformingAreas: {
              uploadedROI: uploadedROI.features
                ? {
                    ...uploadedROI, // set or update
                    features: [...uploadedROI.features, ...filteredDuplicates],
                  }
                : featureFromUploadedFile,
            },
          });

          if (features.length > featureFromUploadedFile.features.length) {
            showNotification({
              title: t({id: 'note.warning', defaultMessage: 'Warning'}),
              message:
                'Some uploaded shapes do not overlap the current field, they were filtered out.',
              type: 'warning',
            });
          }
        } else {
          showNotification({
            title: t({id: 'note.error', defaultMessage: 'Error'}),
            message:
              'None of the uploaded shapes overlap the current field. Change the field or try uploading an another file.',
            type: 'warning',
          });
        }
      })
      .catch();
  };

export const saveLowPerfAnomalies =
  (date: string, anomalies: any, isFromForm?: boolean) =>
  (dispatch: AppDispatch, getState: () => AppStore) => {
    const state = getState();
    const currentDates = selectCurrentDates(state);
    const currentSensor = selectCurrentSensor(state);
    const fieldId = selectCurrentFieldId(state);
    const farmId = selectCurrentFarmId(state);

    let url = '';
    if (
      currentDates?.[date]?.[currentSensor] &&
      currentSensor !== 'NONE' &&
      currentSensor !== 'TCI'
    ) {
      url = `${farmId}/${fieldId}/${currentDates[date][currentSensor]?.name}`;
    }

    return ActivityApi.saveLowPerfAnomalies(url, anomalies)
      .then(() => {
        isFromForm &&
          showNotification({
            title: t({id: 'note.success', defaultMessage: 'Success'}),
            message: t({id: 'Anomalies were saved'}),
            type: 'success',
          });
      })
      .catch();
  };

/**
 * Chenge a prop of any geometry (area of interest, low perf anomaly or premium anomaly).
 */
export const changeAnyTypeGeometryProp =
  (geometry: IAnomaly, prop: keyof TAnomalyProps, value: any) =>
  (dispatch: AppDispatch, getState: () => AppStore) => {
    const state = getState();
    const currentDate = selectCurrentDate(state);
    const currentDates = selectCurrentDates(state);

    const isLowPerf = geometry.properties.isLowPerf;
    const isEdit = prop === 'saved';
    const isLabel = prop === 'label';

    dispatch(toToggleFieldGeometries(prop, value));

    if (isLowPerf) {
      dispatch(changeLowPerfAnomalyProp(geometry, prop, value));
    } else {
      let dateToSwitch = getNearestGeometryDate(geometry);

      const {startDate, endDate} = geometry.properties;
      const isInterSelect = isDateInRange(
        moment.utc(currentDate, 'DD/MM/YYYY').format(),
        startDate,
        endDate
      );

      if (!isInterSelect && !isEdit && !isLabel) {
        dateToSwitch = getClosestGeometryDate(geometry);

        if (!dateToSwitch && !Object.keys(currentDates)) {
          return showNotification({
            title: t({id: 'note.warning', defaultMessage: 'Warning'}),
            message:
              'This shape cannot be displayed for the selected season. Select a season between the starting date and the end date of the region of interest.',
            type: 'warning',
          });
        }
      }

      dispatch(changeAreaOfInterestProp(geometry, prop, value));

      // dateToSwitch && dispatch(setDate(dateToSwitch));
      if (prop === 'saved' && !value) {
        mapBarScrollTo(`#geometry-${geometry.properties.id}`);
      }
    }
  };

export const toToggleFieldGeometries =
  (prop: keyof TAnomalyProps, value: any) => (dispatch: AppDispatch, getState: () => AppStore) => {
    if (['openPopUp', 'checked'].includes(prop) && value && !selectGeometriesOnMap(getState())) {
      dispatch(toggleFieldGeometries(true));
    }
  };

export const onChangeAnomalyTab = (value: AnomalyTab) => ({
  type: ActionTypes.MAP_CHANGE_ANOMALIES_TAB,
  value,
});

export const setUploadingAnomaliesStatus = (value: RequestStatus) => ({
  type: ActionTypes.MAP_UPLOAD_AREAS_OF_INTEREST_SET_STATUS,
  value,
});
