import React, {useMemo, memo} from 'react';
import {CropAvatar} from 'components/crop-avatar';
import Select from 'react-select';
import {naturalSortAlphaNum} from '_utils/sorters';
import {useAppSelector} from '_hooks';
import type {SICropTypeOptions} from 'containers/si/types';
import {selectSICropTypeOptions} from 'containers/si/module/selectors';

type Props = {
  cropValue: string;
  onChange: (value: string, label: string, type: string) => void;
  availableCommodities: number[];
};

const formatOptionLabel = (option: SICropTypeOptions) => {
  return (
    <div id={`ap-crop-select-${option.value}`} style={{display: 'flex', alignItems: 'center'}}>
      <CropAvatar cropType={option.type} className="crop-type-select__avatar" />

      <span
        className="label"
        style={{
          paddingLeft: 8,
          paddingBottom: 0,
        }}
      >
        {option.label}
      </span>
    </div>
  );
};

export const CropSelect = memo(({cropValue, onChange, availableCommodities}: Props) => {
  const allCropsOptions = useAppSelector(selectSICropTypeOptions);

  const menuItems = useMemo(() => {
    const values = Object.values(
      allCropsOptions.filter(crop => availableCommodities.includes(Number(crop.value)))
    );

    return naturalSortAlphaNum(values, 'label');
  }, [allCropsOptions, availableCommodities]);

  const current = useMemo(() => {
    return menuItems.find(item => item.value === cropValue);
  }, [menuItems, cropValue]);

  return (
    <Select
      placeholder="Select a crop"
      value={current}
      formatOptionLabel={formatOptionLabel}
      options={menuItems}
      onChange={item => onChange(item?.value || '', item?.label || '', item?.type || '')}
      menuPortalTarget={document.body}
      styles={{
        menuPortal: (base: any) => ({
          ...base,
          zIndex: 9999,
        }),
        control: (base: any) => ({
          ...base,
          border: '1px solid lightgray',
          '&:hover': {borderColor: 'gray'},
          boxShadow: 'none',
        }),
      }}
    />
  );
});
