import React from 'react';
import {useAppSelector} from '_hooks';
import convert from 'convert-units';
import {Flex, Text, FluroChip} from 'components';
import {CropAvatar} from 'components/crop-avatar';
import {selectCropTypesCDLIdMap} from 'modules/global/selectors';
import type {ReportType} from 'containers/si/api/apiTypes';

export const AreaCropContainer = ({
  areaId,
  areaName,
  color,
  commodities,
  unitType,
  reportType = 'abatement_potential',
  convertUnits = false, // If true, the crop.volume is in `kg` and needs to be converted to `crop.volume_units`
}: {
  areaId: number;
  areaName: string;
  color: string;
  commodities: {id: number; volume: number; volume_units?: string}[];
  unitType: 'supply_shed' | 'subsection';
  reportType?: ReportType;
  convertUnits?: boolean;
}) => {
  const cropsById = useAppSelector(selectCropTypesCDLIdMap);

  const convertCrop = (mass: number, unit: string) => {
    switch (unit) {
      case 'lb':
        return convert(mass).from('lb').to('kg');
      case 't':
        return convert(mass).from('t').to('kg');
      case 'mt':
        return convert(mass).from('mt').to('kg');
      case 'kg':
        return mass;
      default:
        return mass;
    }
  };

  return (
    <div key={areaId} className="summary-container">
      <div className="shed-color" style={{borderLeftColor: color}}></div>
      <Flex
        direction="row"
        alignItems={'baseline'}
        nowrap
        gap={'4px'}
        justifyContent={'space-between'}
      >
        <Text variant="h2" className="mb-0.5" primary>
          {areaName}
        </Text>
        <FluroChip
          key={areaId}
          label={unitType === 'supply_shed' ? 'Supply Shed' : 'Subregion'}
          tone={'dimmed'}
          size={'small'}
          className="margin-right-4"
          disabled
        />
      </Flex>
      <div className={'crop-col'}>
        {commodities.map(crop => (
          <div className={'crop-row'} key={crop.id}>
            <Flex direction="row" alignItems={'center'} nowrap gap={'4px'}>
              <CropAvatar
                cropType={cropsById[Number(crop.id)]?.value}
                className="crop-type-select__avatar"
              />
              <div>{cropsById[Number(crop.id)]?.label} </div>
            </Flex>
            {reportType === 'abatement_potential' && (
              <Flex>
                <Text bold className="ml-1">
                  {convertUnits
                    ? convertCrop(crop?.volume, crop?.volume_units || 'kg')
                    : crop?.volume}
                </Text>
                <Text bold className="ml-1">
                  {crop?.volume_units || 'kg'}
                </Text>
              </Flex>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};
