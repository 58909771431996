import Server from './server';
import type {UserSettings} from 'containers/admin/users/types';
import type {AxiosPromise} from 'axios';
import type {LoginResponse} from '../containers/login/types';

export default class {
  static getProfile(): AxiosPromise<LoginResponse> {
    return Server.get('profile');
  }

  static update(data: UserSettings) {
    // TODO this endpoint is used to update settings, need to split this functionalities
    return Server.put('profile', data);
  }

  static updatePassword(data: any) {
    return Server.put('profile/updatePassword', data);
  }

  static updateProfileSettings(settings: UserSettings, skipPreloader = false) {
    return Server.put('profile', settings, {params: {__skipPreloader: skipPreloader}});
  }

  static updateDemoFarmsList(demoFarms: number[]) {
    return Server.put('demoFarms', demoFarms);
  }

  static accessRoles(id: number) {
    return Server.get(`users/${id}/access_roles`);
  }
}
