import Server from '../server';
import type {EFCSyncRequestPayload} from 'containers/profile/integration/integration-efc';

export default class {
  static connectUsers(userIds: number[], selectedPartner: string) {
    return Server.post(
      `admin/efc/user?multiple=true`,
      {
        connections: userIds.map(userId => ({
          fs_user_id: userId,
          partner_name: selectedPartner,
        })),
      },
      {params: {__skipPreloader: true}}
    );
  }

  static disconnectUsers(userIds: number[]) {
    return Server.post(
      `admin/efc/user/delete`,
      {
        fs_user_ids: userIds,
      },
      {params: {__skipPreloader: true}}
    );
  }

  static getPartners() {
    return Server.get(`admin/efc/partner`, {
      params: {__skipPreloader: true},
    });
  }

  static getConnectedUsers(partnerName: string) {
    return Server.get(`admin/efc/partner/user?partner_name=${partnerName}`, {
      params: {__skipPreloader: true},
    });
  }

  static getGrowers() {
    return Server.get('sync/efc/growers', {
      params: {__skipPreloader: true},
    });
  }

  static getFarms(growerId: string) {
    return Server.get(`sync/efc/growers/${growerId}/farms`, {
      params: {__skipPreloader: true},
    });
  }
  static getFields(farmId: string) {
    return Server.get(`sync/efc/farms/${farmId}/fields`, {
      params: {__skipPreloader: true},
    });
  }

  static getSyncStatus(jobId: string) {
    return Server.get(`sync/efc/import/${jobId}`, {params: {__skipPreloader: true}});
  }

  static syncGrowers(payload: EFCSyncRequestPayload, mrvProjectId: number) {
    const requestParams: Record<string, any> = {
      __skipPreloader: true,
    };

    if (mrvProjectId) {
      requestParams.mrv_project_id = mrvProjectId;
    }
    return Server.post(`sync/efc/import`, payload, {
      params: requestParams,
    });
  }

  static getListPartners() {
    return Server.post(`admin/efc/partner`, {params: {__skipPreloader: true}});
  }
}
