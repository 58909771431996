import React from 'react';
import {CloudFilled, CloudStroke} from './clouds.styled';

type Props = {
  percent: number;
};

const Clouds = ({percent}: Props) => {
  if (!percent) return null;

  if (percent <= 5) {
    return <CloudStroke />;
  }

  return <CloudFilled />;
};

export default Clouds;
