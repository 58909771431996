import {Flex, FluroButton, FluroChip, FluroDialog, InfoBlock, Text} from 'components';
import type {ComponentType} from 'react';
import React from 'react';
import type {SubmitHandler} from 'react-hook-form';
import {useForm} from 'react-hook-form';
import type {MRVAttribute, MRVStageNormalized} from 'containers/mrv/types';
import {MRVAttributeType} from 'containers/mrv/types';
import {InputComponent} from '../inputs/input-component';
import {UnlockFieldButton} from '../mrv-table/unlock-field-button';
import './bulk-edit-dialog.scss';
import {Circle} from 'components/shapes/circle';
import colors from 'variables.scss';
import type {SelectedFieldRow} from '../table-rows-selection';
import {useMixpanelTrackSwitchStage} from '../mrv-table/use-mixpanel-track-switch-stage';
import {t} from 'i18n-utils';
import {monitoringStageIcons} from 'containers/mrv/admin/base/base';
import {getAttributeLabel, getStageName} from 'containers/mrv/base';
import type {MRVBulkEditValue, MRVBulkEditValues} from './types';

type Props = {
  attributes: MRVAttribute[];
  stage: MRVStageNormalized;
  defaultValues: MRVBulkEditValues;
  selectedFields: SelectedFieldRow[];
  selectedYears?: number[];
  onHide: () => void;
  onSubmit: (values: {[attributeId: string]: any}) => void;
};

export const MonitoringBulkEditFieldsDialog: ComponentType<Props> = ({
  stage,
  defaultValues,
  attributes,
  selectedFields,
  selectedYears,
  onHide,
  onSubmit,
}) => {
  useMixpanelTrackSwitchStage('bulk-edit');

  // The attribute id has to be a string to accomodate the input.name attribute type.
  const form = useForm<Record<string, MRVBulkEditValue>>({
    defaultValues,
  });
  const {
    handleSubmit,
    watch,
    setValue,
    formState: {dirtyFields, isDirty},
  } = form;

  const values = watch();

  const submit: SubmitHandler<typeof defaultValues> = values => {
    if (!isDirty) return;

    const updatedValues = Object.keys(dirtyFields).reduce((updatedValuesAcc, dirtyKey) => {
      updatedValuesAcc[Number(dirtyKey)] = values[Number(dirtyKey)];
      return updatedValuesAcc;
    }, {} as typeof values);
    onSubmit(updatedValues);
  };

  const getDirtyFieldsWarningMessage = () => {
    const dirtyAttributes = attributes.filter(attr => dirtyFields[attr.id]);
    if (!dirtyAttributes.length) return '';
    const dirtyLabels = dirtyAttributes.map(attr => attr.name).join(', ');
    const rowsToUpdateCount = Math.max(
      ...dirtyAttributes.map(attr => {
        const value = defaultValues[attr.id];
        return value.totalRowsNumber - value.disabledRowsNumber;
      })
    );
    return (
      <>
        {t(
          {
            id: 'EditField.UpdateFieldsMessage',
            defaultMessage: '{dirtyLabels} will be updated for {selectedFields} row(s)',
          },
          {dirtyLabels, selectedFields: rowsToUpdateCount}
        )}
        .
      </>
    );
  };

  const dirtyFieldsWarningMessage = getDirtyFieldsWarningMessage();

  return (
    <FluroDialog
      portal
      autosizeContent={false}
      id={'monitoring-bulk-edit-fields-dialog'}
      title={
        <>
          <Flex alignItems="center" nowrap justifyContent="space-between">
            <Flex alignItems="center" gap="15px">
              <Circle className="crop-icon" color={colors['main-green-600']}>
                {monitoringStageIcons[stage.type_]}
              </Circle>
              <Text variant={'h2'} className="mb-0">
                {getStageName(stage)}
              </Text>
            </Flex>

            <FluroButton onClick={onHide} icon>
              close
            </FluroButton>
          </Flex>

          <Text elementType={'p'} className="mt-1" secondary variant={'small'}>
            {t(
              {id: 'SelectedRowsNumber', defaultMessage: '{numberRows} row(s) selected'},
              {numberRows: selectedFields.length}
            )}
          </Text>

          <Flex gap="4px">
            {selectedYears?.map(year => (
              <FluroChip
                className="crop-practices-year-chips ml-0"
                active={true}
                size="small"
                key={year}
                label={year}
              />
            ))}
          </Flex>
        </>
      }
      isClosable={false}
      className={'monitoring-bulk-edit-fields-dialog'}
      onHide={onHide}
      visible
      width={400}
    >
      <form onSubmit={handleSubmit(submit)}>
        {attributes
          .filter(attr => attr.type !== MRVAttributeType.RecordYear)
          .map((attr, i) => {
            const {name, type, options, locked, enabled, default_value} = attr;
            const localLabel = getAttributeLabel(attr);
            const inputId = `bulk-edit-${attr.id}`;
            const data = values[attr.id];
            const inputName = String(attr.id);
            const isLocked = locked || data.isLocked;

            const disabledByDependencyRules = data.disabledRowsNumber === data.totalRowsNumber;

            return enabled && !disabledByDependencyRules ? (
              <Flex key={i} alignItems="flex-end" justifyContent="space-between">
                <div style={{width: isLocked ? '85%' : '100%'}}>
                  <InputComponent
                    key={inputId}
                    id={inputId}
                    disabled={isLocked}
                    defaultValue={data.isMultipleValuesSelected ? null : default_value}
                    type={type}
                    name={inputName}
                    value={data.isMultipleValuesSelected ? null : data.value}
                    label={
                      isLocked && data.lockedRowsNumber > 1
                        ? `${localLabel} (Locked ${data.lockedRowsNumber} of ${data.totalRowsNumber} row(s))`
                        : localLabel
                    }
                    placeholder={
                      data.isMultipleValuesSelected ? 'Multiple Values Selected' : localLabel
                    }
                    options={options}
                    onChange={value => {
                      // Since data.value is only the value of the first row,
                      // in case of multiselect we wanna update other rows with that value.
                      if (!data.isMultipleValuesSelected && data.value === value) {
                        return;
                      }

                      setValue(
                        inputName,
                        {...data, value, isLocked: false, isMultipleValuesSelected: false},
                        {shouldDirty: true}
                      );
                    }}
                  />
                </div>
                {isLocked && (
                  <UnlockFieldButton
                    practice={name}
                    isDialogView
                    onUnlock={() =>
                      setValue(
                        inputName,
                        {...data, ...values[attr.id], isLocked: false},
                        {shouldDirty: true}
                      )
                    }
                  />
                )}
              </Flex>
            ) : null;
          })}

        {dirtyFieldsWarningMessage && (
          <InfoBlock color="info">{dirtyFieldsWarningMessage}</InfoBlock>
        )}

        <InfoBlock color="info">
          {t({
            id: 'BulkEditDialog.ValuesOnlyBeUpdatedIfValid',
            defaultMessage:
              'Values will only be updated if they are valid and available for the cell.',
          })}
        </InfoBlock>

        <Flex
          className={'buttons-holder'}
          justifyContent={'flex-end'}
          alignItems={'center'}
          gap={'10px'}
        >
          <FluroButton onClick={onHide} raised blank>
            {t({id: 'Cancel'})}
          </FluroButton>

          <FluroButton onClick={handleSubmit(submit)} raised primary>
            {t({id: 'BtnLabel.ConfirmChanges', defaultMessage: 'Confirm changes'})}
          </FluroButton>
        </Flex>
      </form>
    </FluroDialog>
  );
};
