// @ts-nocheck
import {t} from 'i18n-utils';
import * as React from 'react';
import {Suspense} from 'react';
import type {DialogContainerProps} from 'react-md';
import cn from 'classnames';
import {FluroDialog} from 'components';
import './embed-login.scss';

const LoginDeferred = React.lazy(() => import(`containers/login/embed/embed-login`));

type Props = DialogContainerProps & {
  showEvenWhenLoggedIn?: boolean;
};

export const EmbedLogin = ({showEvenWhenLoggedIn, ...dialogProps}: Props) => {
  return (
    <FluroDialog
      id="embed-login"
      dialogClassName={cn({
        'embed-login': true,
        closeable: showEvenWhenLoggedIn,
      })}
      visible
      portal
      autosizeContent={false}
      {...dialogProps}
    >
      <Suspense fallback={<div>{t({id: 'Loading...'})}</div>}>
        <LoginDeferred embed={true} showEvenWhenLoggedIn={showEvenWhenLoggedIn} />
      </Suspense>
    </FluroDialog>
  );
};
