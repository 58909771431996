import React from 'react';
import {shallowEqual, useSelector} from 'react-redux';
import type {IInitialMapState} from 'containers/map/types';
import {formatDate} from '_utils';
import moment from 'moment';

const SeasonInFuture = () => {
  const {currentSeason} = useSelector(
    ({map}: {map: IInitialMapState}) => ({currentSeason: map.currentSeason}),
    shallowEqual
  );
  return (
    <React.Fragment>
      We will start retrieving and processing imagery on the{' '}
      {moment(currentSeason?.startDate).format(formatDate())}, when we reach the sowing date.
    </React.Fragment>
  );
};
export default SeasonInFuture;
