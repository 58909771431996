import {CardHr} from 'components/card/card-styled';
import {ENROLLMENT_PROJECT_FORM, MONITORING_PROJECT_FORM} from 'containers/mrv/routes';
import {applyPathParams} from '_utils/pure-utils';
import {MapBarMapView, MapBarTableView} from 'containers/map/map-bar/map-bar-view';
import React, {useContext, useMemo, useEffect} from 'react';
import type {ComponentType} from 'react';
import {Redirect, useLocation} from 'react-router-dom';
import {useAppDispatch, useAppSelector} from '_hooks';
import {applyUrlParams} from '_hooks/use-url-params';
import {useMonitoringUrlParams} from '../hooks';
import {
  selectPhase,
  selectStageById,
  selectCurrentProjectFarms,
  selectSyncingOperationsStatus,
} from '../module/selectors';
import {CropTypesFilter} from './crop-types-filter';
import {BulkEditButton} from './edit-field-dialog/bulk-edit-button';
import {FarmSelect, MobSelect} from './entity-select';
import './mrv-form.scss';
import {MRVTable} from './mrv-table/mrv-table';
import {MRVPanel} from './mrv-panel/mrv-panel';
import {MRVTimelineMemo} from './mrv-timeline';
import {Flex, Text} from 'components';
import {MRVAttributeType, MRVPhaseType, MRVStageType} from 'containers/mrv/types';
import {selectCurrentProjectFieldsByFarmIdAndSummerCrop} from '../module/monitoring.selectors';
import {FMSSyncMessageIndicator} from './messages/fms-sync-message-indicator';
import {StageMessageView} from 'containers/mrv/admin/base/stage-message';
import {MRVEntityAllEntitiesId} from '../../constants';
import {NavigationHighlightContextProvider} from './navigation-highlight-context';
import {isCustomStageView} from 'containers/mrv/base';
import {t} from 'i18n-utils';
import {useIsStageDisabled} from '../hooks/use-is-stage-disabled';
import {useFetchStageCompletion} from 'containers/mrv/hooks';
import {OptisStatusMessage} from 'containers/mrv/enrollment/form/optis-status-message';
import {useMobs} from '../../data/use-mobs';
import {MobEditButton} from './edit-mob-dialog/mob-edit-button';
import {MRVBottomBar} from 'containers/mrv/monitoring/form/mrv-table/mrv-bottom-bar';
import {isPhaseReadonly} from 'containers/mrv/monitoring/module/selectors-complex';
import {FluroCheckbox} from 'components/fluro-checkbox/fluro-checkbox';
import {TableRowsSelectionContext} from 'containers/mrv/monitoring/form/table-rows-selection';
import {isDefined} from '_utils/typeGuards';
import {useMRVValues} from 'containers/mrv/data/use-mrv-values';
import {getEntityId} from 'containers/mrv/value-utils';
import {toggleTableView} from 'containers/map/actions';

export const MRVForm = ({phaseType}: {phaseType: MRVPhaseType}) => (
  <NavigationHighlightContextProvider>
    <MRVFormInner phaseType={phaseType} />
  </NavigationHighlightContextProvider>
);

// TODO: this component possibly has performance issues when updating fields, check with perf tools
export const MRVFormInner: ComponentType<{phaseType: MRVPhaseType}> = ({phaseType}) => {
  const dispatch = useAppDispatch();
  const {projectId, programId, stageId, entityId, summerCrops} = useMonitoringUrlParams();
  const location = useLocation();
  const monitoringPhase = useAppSelector(s => selectPhase(s, MRVPhaseType.Monitoring));
  const stage = useAppSelector(s => selectStageById(s, stageId));
  const farms = useAppSelector(selectCurrentProjectFarms);

  useEffect(() => {
    dispatch(toggleTableView('enrollment'));
  }, []);

  const fields = useAppSelector(s =>
    selectCurrentProjectFieldsByFarmIdAndSummerCrop(
      s,
      MRVAttributeType.SummerCropType,
      summerCrops,
      entityId === -1 ? undefined : entityId
    )
  );
  const {mobs: allMobs} = useMobs(projectId, stage?.entity_type);
  const mobs = useMemo(
    () => (entityId === -1 ? allMobs : allMobs.filter(m => m.id === entityId)),
    [allMobs, entityId]
  );
  const entities = useMemo(() => {
    switch (stage?.entity_type) {
      case 'farm':
        return farms;

      case 'field':
        return fields;

      case 'mob':
        return mobs;
    }

    return [];
  }, [farms, fields, mobs, stage?.entity_type]);

  const isReadOnly = useAppSelector(s => isPhaseReadonly(s, phaseType).isReadOnly);
  const [selectedRows, setSelectedRows] = useContext(TableRowsSelectionContext);
  const isSyncingOperations = useAppSelector(selectSyncingOperationsStatus).isSync;
  const {entityValueRows, valueRows, loading, updateValues} = useMRVValues(
    phaseType,
    projectId,
    stage
  );

  useFetchStageCompletion(MRVPhaseType.Monitoring);
  useFetchStageCompletion(MRVPhaseType.Enrollment);

  const isStageDisabled = useIsStageDisabled(phaseType);

  if (!monitoringPhase?.stages?.length) return null;

  const path =
    phaseType === MRVPhaseType.Monitoring ? MONITORING_PROJECT_FORM : ENROLLMENT_PROJECT_FORM;
  if (!stageId || (stage && isStageDisabled(stage))) {
    const [firstStageId] = monitoringPhase.stages;
    if (!firstStageId) return <>Program has no stages</>;
    return (
      <Redirect
        to={{
          pathname: applyPathParams(path, {projectId}),
          search: applyUrlParams(location.search, {stageId: firstStageId, programId}),
        }}
      />
    );
  } else if (!entityId) {
    const [firstFarm] = farms;
    if (!firstFarm?.id) return <>{t({id: 'Project has no fields'})}</>;
    return (
      <Redirect
        to={{
          pathname: applyPathParams(path, {projectId}),
          search: applyUrlParams(location.search, {
            stageId,
            entityId: MRVEntityAllEntitiesId,
            programId,
          }),
        }}
      />
    );
  }

  function selectAllRows(selected: boolean) {
    setSelectedRows(
      selected
        ? valueRows
            .map(row =>
              getEntityId(row) ? {entityId: getEntityId(row), rowId: row.row_id} : undefined
            )
            .filter(isDefined)
        : []
    );
  }

  return (
    <div className={'mrv-form'}>
      <MapBarMapView>
        <Text className={'description'}>
          {t({id: 'Select fields from the list below or map to fill in operation information.'})}
        </Text>
      </MapBarMapView>
      <MapBarTableView>
        <Text className={'description'}>
          {stage?.description ||
            t({
              id: 'MonitFillInfoMessage',
              defaultMessage:
                'Fill out your management information for each of the fields below. To move on to the next operation, use the horizontal navigation or the button at the bottom of the screen.',
            })}
        </Text>
      </MapBarTableView>

      <MRVTimelineMemo phaseType={phaseType} />

      {phaseType === MRVPhaseType.Enrollment && <OptisStatusMessage />}

      {stage?.messages?.map((message, i) => (
        <StageMessageView key={i} message={message} />
      ))}

      {/* Custom stages have their implementation inside MonitoringTable and MRVPanel. */}
      {/* TODO (stas): Check if we can their views here. */}
      {!isCustomStageView(stage?.type_) && (
        <div>
          <CardHr className="mb-2" />
          <Flex
            className="mb-2"
            alignItems="center"
            justifyContent="space-between"
            data-cy="monitoring-filters-panel"
          >
            <Flex alignItems="center">
              <FluroCheckbox
                value={valueRows.length === selectedRows.length}
                halfChecked={selectedRows.length > 0}
                disabled={isSyncingOperations || isReadOnly}
                onChange={selectAllRows}
              />
              {stage?.type_ === MRVStageType.MobHistory ? <MobSelect /> : <FarmSelect />}
              {stage?.type_ === MRVStageType.SummerCrops && <CropTypesFilter />}
            </Flex>
            <MapBarTableView>
              <Flex alignItems="center" gap="1rem">
                {/* for the map view it is inserted in another place*/}
                {stage?.entity_type === 'mob' ? (
                  <MobEditButton phaseType={phaseType}></MobEditButton>
                ) : null}
                <BulkEditButton phaseType={phaseType} />
              </Flex>
            </MapBarTableView>

            <FMSSyncMessageIndicator />
          </Flex>
        </div>
      )}

      <MapBarTableView>
        <MRVTable
          phaseType={phaseType}
          entities={entities}
          loading={loading}
          entityValueRows={entityValueRows}
          onUpdate={updateValues}
        />
        {valueRows.length !== 0 && <MRVBottomBar phaseType={phaseType} isReadOnly={isReadOnly} />}
      </MapBarTableView>
      <MapBarMapView>
        <MRVPanel phaseType={phaseType} />
      </MapBarMapView>
    </div>
  );
};
