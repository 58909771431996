import React, {useEffect, Fragment} from 'react';
import type {ChartDataSets, ChartTooltipCallback} from 'chart.js';
import ChartJs from 'chart.js';
import {Flex, Text} from 'components';

import {Line} from 'react-chartjs-2';
import {FontIcon} from 'react-md';

import cn from 'classnames';
import {Tooltip} from 'components/tooltip/tooltip';
import {kiloFormatter} from '_utils/number-formatters';
import {SparklineContainer} from '../../styled-components';
export interface SparklineProps {
  sparklingChartCaption: string;
  sparklingChartCaptionBolded?: string;
  comparisonValues: {
    label: string;
    value: string | number;
    direction: 'positive' | 'negative';
    tooltip?: string;
    color?: 'red' | 'green';
  }[];
  chartData: {labels: Array<string | number>; datasets: ChartDataSets[]};
  chartDataMidline: number; // represents baseline
  linechartLabelSuffix?: string;
  /** @param decimals: How many decimals are going to be displayed in center number avg line and sparkline summary. */
  decimals?: number;
  tooltipCallbacks?: ChartTooltipCallback;
}

export const SparklineChart = ({
  comparisonValues,
  sparklingChartCaption,
  sparklingChartCaptionBolded,
  chartData,
  chartDataMidline,
  decimals,
  linechartLabelSuffix,
  tooltipCallbacks,
}: SparklineProps) => {
  useEffect(() => {
    unregisterDrawFunctions();
    registerDrawFunctions();
  }, []);

  const unregisterDrawFunctions = () =>
    ChartJs.plugins.unregister(
      ChartJs.plugins.getAll().filter(p => p.id === 'fluro-analytics-chart')[0]
    );

  const registerDrawFunctions = () =>
    ChartJs.pluginService.register({
      id: 'draw-chart-baseline',
      beforeDraw: chart => drawBaseline(chart),
      afterDraw: chart => drawTooltipOnHover(chart),
    });

  return (
    <Fragment>
      {comparisonValues.length
        ? comparisonValues.map(({label, value, direction, tooltip, color}) => {
            const positiveDirection = direction === 'positive';
            const greenColor = color === 'green';
            const redColor = color === 'red';

            return (
              <Flex justifyContent="space-between" key={label}>
                <Text variant="medium" elementType={'div'}>
                  {label}
                </Text>
                <Tooltip
                  id={`${label}-${value}-pct-info`}
                  content={tooltip || ''}
                  place="bottom"
                  disabled={!tooltip}
                >
                  <Flex
                    alignItems="center"
                    className={cn({
                      'card-dynamic-value__green': greenColor || (!redColor && positiveDirection),
                      'card-dynamic-value__red': redColor || !positiveDirection,
                    })}
                  >
                    <FontIcon style={{color: 'inherit'}}>
                      {positiveDirection ? 'arrow_upward' : 'arrow_downward'}
                    </FontIcon>
                    <Text elementType={'div'} bold variant="medium">
                      {value}
                    </Text>
                  </Flex>
                </Tooltip>
              </Flex>
            );
          })
        : null}
      <Flex alignItems="center" gap={'5px'} className="margin-top-25">
        <div className="dashed-line" />
        <Text variant="small">{sparklingChartCaption}</Text>
        <Text bold variant="small">
          {sparklingChartCaptionBolded}
        </Text>
      </Flex>
      <SparklineContainer>
        <Line
          data={chartData}
          options={{
            maintainAspectRatio: false,
            legend: {display: false},
            layout: {padding: 0},
            scales: {
              xAxes: [
                {
                  ticks: {
                    maxTicksLimit: 1,
                    padding: 0,
                  },
                  gridLines: {
                    display: false,
                  },
                },
              ],
              yAxes: [
                {
                  ticks: {
                    beginAtZero: true,
                    maxTicksLimit: 1,
                    display: false,
                  },
                  gridLines: {
                    display: false,
                  },
                },
              ],
            },
            animation: {
              duration: 0,
            },
            //@ts-expect-error error leftover from convertion to strict mode, please fix // custom prop
            horizontalLine: {
              y: chartDataMidline,
              style: '#C2C2C2',
            },
            tooltips: {
              mode: 'index',
              intersect: false,
              callbacks: {
                label: tooltipItem => {
                  return `${kiloFormatter(
                    Number(tooltipItem.value),
                    decimals ? decimals : undefined
                  )} ${linechartLabelSuffix ? linechartLabelSuffix : ''}
                  `;
                },
                ...tooltipCallbacks,
              },
              displayColors: false,
            },
          }}
        />
      </SparklineContainer>
    </Fragment>
  );
};

const drawBaseline = (chartInstance: any) => {
  const yScale = chartInstance.scales['y-axis-0'];
  const canvas = chartInstance.chart;
  const ctx = canvas.ctx;
  let line;
  let style;

  if (chartInstance.options.horizontalLine) {
    line = chartInstance.options.horizontalLine;

    if (!line.style) {
      style = 'rgba(169,169,169, .6)';
    } else {
      style = line.style;
    }

    let yValue = undefined;

    if (line.y) {
      yValue = yScale.getPixelForValue(line.y);
    } else {
      yValue = 0;
    }

    ctx.lineWidth = 2;

    if (yValue) {
      ctx.beginPath();
      ctx.moveTo(15, yValue);
      ctx.setLineDash([6, 3]);
      ctx.lineTo(canvas.width - 15, yValue);
      ctx.strokeStyle = style;
      ctx.stroke();
    }

    return;
  }
};

const drawTooltipOnHover = (chartInstance: any) => {
  if (chartInstance.tooltip._active && chartInstance.tooltip._active.length) {
    const activePoint = chartInstance.controller.tooltip._active[0];
    const ctx = chartInstance.ctx;
    const x = activePoint.tooltipPosition().x;
    const topY = activePoint.tooltipPosition().y;
    const bottomY = chartInstance.scales['y-axis-0'].bottom;
    ctx.save();
    ctx.beginPath();
    ctx.setLineDash([]);
    ctx.moveTo(x, topY);
    ctx.lineTo(x, bottomY);
    ctx.lineWidth = 1;
    ctx.strokeStyle = 'rgba(73, 136, 239, 0.2)';
    ctx.stroke();
    ctx.restore();
  }
};
