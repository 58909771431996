import config from '_environment';
import {convertFromMesureToSquareMeters} from '_utils';

import {toFixedFloatUnsafe} from '_utils/number-formatters';
import type {AppStore} from 'reducers';
import {createSelector} from '@reduxjs/toolkit';
import {selectCurrentField, selectCurrentImageObj, selectZoning} from '../../reducer/selectors';
import {selectMeasurement} from '../../../login/login-selectors';
import type {ZoningParams} from '../../types';

// this is a conversion of the required name for DS
const methodMap: {[key: string]: string} = {
  stddev: 'std',
  jenks: 'jenks',
  equalInterval: 'intervals',
  equalRecords: 'records',
};

// zoning url from data-service/zoning
export const selectZoningUrl = createSelector(
  [
    selectCurrentImageObj,
    selectZoning,
    selectCurrentField,
    selectMeasurement,
    (state: AppStore, params: ZoningParams) => params,
  ],
  (currentImage, zoning, field, measurement, params) => {
    if (!currentImage) return '';

    const zoningMethod = zoning.method;
    const baseUrl = config.baseUrl.replace(/\/$/, '');
    const classificationService = `/services/data-service/zoning`;
    const index = currentImage.sensor.toLowerCase();
    const date = currentImage.date;
    const url = `${baseUrl}${classificationService}/${index}/${field.MD5}/${date}/${methodMap[zoningMethod]}`;
    const query = {
      // area is ha and data_service expects m2
      min_area: toFixedFloatUnsafe(
        convertFromMesureToSquareMeters(zoning.area || 0, measurement),
        0
      ).toString(),
      buffer: zoning.bufferValue.toString(),
      nb_classes: zoning.classes,
      ...params,
    };

    return `${url}?${new URLSearchParams(query)}`;
  }
);
