import React, {useEffect} from 'react';
import {useAppSelector} from '_hooks';
import {FluroButton, Flex, Text} from 'components';
import {CardBlock} from 'containers/admin/users/edit/user-edit.styled';
import './mrv-user-profile-project-settings.scss';
import {t, useTranslation} from 'i18n-utils';
import {Col} from 'components/flex';
import {TextField} from 'react-md';
import {selectUser} from 'containers/login/login-selectors';
import {selectUserProjectIds} from '../monitoring/module/selectors';

import {yupResolver} from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import {useForm, useFieldArray, Controller} from 'react-hook-form';
import {MRVApi} from '_api';
import {MrvUserCustomInputsForm} from './mrv-user-custom-inputs';
import {useCustomInputs} from './use-custom-inputs';
import {showNotification} from 'components/notification/notification';

const accountIdsSchema = Yup.object().shape({
  cargill_account_numbers: Yup.array().of(
    Yup.object().shape({
      project_id: Yup.number().required(),
      account_id: Yup.string()
        .nullable()
        .transform((v, o) => (o === '' ? null : v))
        .matches(
          /^$|^[0-9]+$/,
          t({id: 'UserProjSett.Must be only digits', defaultMessage: 'Must be only digits'})
        )
        .min(
          6,
          t({id: 'UserProjSett.Minimum length 6 digits', defaultMessage: 'Minimum length 6 digits'})
        )
        .max(
          10,
          t({
            id: 'UserProjSett.Maximum length 10 digits',
            defaultMessage: 'Maximum length 10 digits',
          })
        ),
    })
  ),
});

type FormPayload = {
  cargill_account_numbers: {project_id: number; account_id?: string}[];
};

export const MrvUserProfileProjectSettings = () => {
  const t = useTranslation();
  const user = useAppSelector(selectUser);

  const userProjectIds = useAppSelector(selectUserProjectIds);
  const {isLoading, projectConfigs, updateProjectConfig} = useCustomInputs(userProjectIds);

  const {control, formState, setError, handleSubmit} = useForm<FormPayload>({
    resolver: yupResolver(accountIdsSchema),
    mode: 'onChange',
    defaultValues: {
      cargill_account_numbers: [],
    },
  });

  const {fields, append, remove} = useFieldArray({name: 'cargill_account_numbers', control});

  useEffect(() => {
    if (isLoading) {
      return;
    }
    if (projectConfigs.length !== fields.length) {
      append(
        projectConfigs.map(p => ({
          project_id: p.id,
          account_id: p.config?.extra_signup_fields?.value || '',
        }))
      );
    }
  }, [isLoading, projectConfigs, userProjectIds, fields.length, append, remove]);

  const onSave = (payload: FormPayload) => {
    payload.cargill_account_numbers.forEach(async (item, i) => {
      // skip update if field is not changed
      if (!formState.dirtyFields.cargill_account_numbers?.[i]) {
        return;
      }

      try {
        // only validate against api if account id is number
        let isValid = true;
        if (/^\d+$/.test(item.account_id || '')) {
          isValid = !!(await MRVApi.validateCargill(Number(item.account_id)));
        }
        if (isValid) {
          await updateProjectConfig({projectId: item.project_id, accountId: item.account_id || ''});
        }
        showNotification({
          title: t({id: 'note.success', defaultMessage: 'Success'}),
          message: 'Updated',
          type: 'success',
        });
      } catch (e) {
        setError(`cargill_account_numbers.${i}.account_id`, {
          message: t({id: 'Invalid Account ID'}),
        });
      }
    });
  };

  if (user.id === 'new' || !userProjectIds.length) {
    return null;
  }

  return (
    <Flex direction="column">
      {!!fields.length && (
        <form onSubmit={handleSubmit(onSave)}>
          <Text elementType="h1" className={'admin-and-profile-title'}>
            {t({id: 'Custom registration inputs'})}
          </Text>

          {fields.map((f, i) => {
            const programName = projectConfigs.find(p => p.id === f.project_id)?.program_name || '';

            return (
              <CardBlock key={f.project_id}>
                <Text elementType="h2">{programName}</Text>

                <Flex gap="16px 32px" className="margin-bottom-20">
                  <Col basis="calc(50% - 16px)">{t({id: 'Cargill account number'})}</Col>

                  <Col basis="calc(50% - 16px)">
                    <Controller
                      control={control}
                      name={`cargill_account_numbers.${i}.account_id`}
                      render={({field: {value, onChange}}) => (
                        <TextField
                          id={`f-${i}`}
                          value={value}
                          onChange={onChange}
                          error={
                            !!formState.errors?.cargill_account_numbers?.[i]?.account_id?.message
                          }
                          errorText={
                            formState.errors?.cargill_account_numbers?.[i]?.account_id?.message
                          }
                        />
                      )}
                    />
                  </Col>
                </Flex>
              </CardBlock>
            );
          })}

          {!!projectConfigs.length && (
            <Flex justifyContent="flex-end" className="padding-top-20">
              <FluroButton type="submit" raised primary>
                {t({id: 'Save'})}
              </FluroButton>
            </Flex>
          )}
        </form>
      )}

      <MrvUserCustomInputsForm />
    </Flex>
  );
};
