import React from 'react';
import {useAppSelector} from '_hooks';
import {calculateScale} from 'modules/sustainability-insights/utils';
import {toFixedFloat} from '_utils/number-formatters';
import type {ChartDataSets} from 'chart.js';
import {t} from 'i18n-utils';
import {SparklingChartCard} from 'containers/si/kpi/charts/sparkling-chart-card';
import {selectMeasurement} from 'containers/login/login-selectors';
import {TOOLTIPS} from 'containers/si/constants';
import {useFetchKPI} from 'containers/si/hooks/useFetchKPI';
import {useSparklingChart} from 'containers/si/hooks/useSparklingChart';
import {convertArea} from '@turf/turf';

export const FertilizerApplicationCard = () => {
  const measurement = useAppSelector(selectMeasurement);
  //const [pieData, setPieData] = useState<any[]>([]);
  const {response: fertResponse, loading} = useFetchKPI({
    kpi: 'fertilizer',
    summarizeBy: ['annualized', 'kpi_subtype'],
  });
  const {sparklingChartData, average, perArea, comparisonValues} = useSparklingChart(fertResponse, {
    negativeIsGain: true,
    tooltip: {
      up: TOOLTIPS.KPI.fertilizerApplication.pctUp,
      down: TOOLTIPS.KPI.fertilizerApplication.pctDown,
    },
  });

  const mappedDatasetToMetersPerArea = sparklingChartData.datasets.map(data =>
    toFixedFloat(
      data /
        convertArea(
          fertResponse?.crop_area?.value || 0,
          'meters',
          measurement === 'ac' ? 'acres' : 'hectares'
        )
    )
  );

  const metersPerArea =
    average /
    convertArea(
      fertResponse?.crop_area?.value || 0,
      'meters',
      measurement === 'ac' ? 'acres' : 'hectares'
    );

  const data: ChartDataSets[] = [
    {
      borderColor: '#4988EF',
      pointBorderWidth: 1,
      pointRadius: 1,
      fill: 'start',
      data: mappedDatasetToMetersPerArea,
      // TODO: chartjs types are entirely wrong. Revisit when upgraded from 2.x
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      backgroundColor: (context: any) => {
        const ctx = context.chart.ctx;
        const yScale = context.chart.scales['y-axis-0'].height || 100;
        const midline = context.chart.scales['y-axis-0'].getPixelForValue(metersPerArea) || 50;
        const midlineScale = calculateScale(midline, 0, yScale);
        const gradient = ctx.createLinearGradient(0, 0, 0, yScale);
        gradient.addColorStop(0, 'rgba(73, 136, 239)');
        gradient.addColorStop(midlineScale, 'rgba(73, 136, 239, 0.1)');
        gradient.addColorStop(1, 'rgba(73, 136, 239, 0)');
        return gradient;
      },
    },
  ];

  return (
    <SparklingChartCard
      title={t({id: 'Fertilizer application'})}
      subtitle={measurement === 'ac' ? t({id: 'in kg/acre'}) : t({id: 'in kg/ha'})}
      cardCenterNumber={perArea ? `${toFixedFloat(perArea, 2)}` : 'No Data'}
      cardCenterUnits=""
      comparisonValues={comparisonValues}
      sparklingChartCaption={`${sparklingChartData.datasets.length} year avg`}
      sparklingChartCaptionBolded={`${toFixedFloat(metersPerArea, 2)} ${
        measurement === 'ac' ? 'kg/ac' : 'kg/ha'
      }`}
      chartData={{labels: sparklingChartData.labels, datasets: data}}
      chartDataMidline={metersPerArea}
      tooltip={TOOLTIPS.KPI.fertilizerApplication.card}
      loading={loading}
    />
  );
};
