import Server from './server';
import axios from 'axios';
import config from '_environment';
import type {SamplingPoint} from '../containers/map/types';
import {reportError} from '../containers/error-boundary';
import cancelTokenStore from './cancel-tokens-store';
import service from './service';
import type {
  AnalyticRequestSourceType,
  SmoothedVIResponse,
} from 'containers/map/actions/analytics-actions';
import type {TSensor} from 'types';

export class ActivityAPI {
  static updateSamplingPoint(kmlId: number | string, seasonId: number, point: SamplingPoint) {
    const isNew = point.id === 'new';
    return Server[isNew ? 'post' : 'put'](
      `tissue_sampling/${kmlId}/${seasonId}${isNew ? '' : '/' + point.id}`,
      point
    );
  }

  static removeSamplingPoint(kmlId: number | string, seasonId: number, point: number) {
    return Server.delete(`tissue_sampling/${kmlId}/${seasonId}/${point}`);
  }

  static saveBulk(
    points: Array<SamplingPoint>,
    groupId: number,
    fieldID: number,
    isTest: boolean,
    shouldMerge = false
  ) {
    return Server.post(
      `tissue_sampling/${groupId}?kmlId=${fieldID}${isTest ? '&test=1' : ''}${
        shouldMerge ? '&merge=1' : ''
      }`,
      points
    );
  }

  // anomalies
  static getLowPerfAnomalies(groupId: number, fieldId: number | string) {
    return Server.get(`anomaly/${groupId}/${fieldId}`);
  }

  static generateLowPerfAnomalies(groupId: number, fieldId: number | string, imageName: string) {
    return Server.get(`anomaly/${groupId}/${fieldId}/${imageName}?generate=1`);
  }

  static saveLowPerfAnomalies(url: string, data: any) {
    return Server.post(`anomaly/${url}`, data);
  }

  static updateAnomalyProp(url: any, data: any) {
    return Server.post(`anomaly/${url}`, data);
  }

  static updateBulkAnomalyProp(url: string, data: any) {
    return Server.post(`anomalies/${url}`, data);
  }

  static uploadAnomaliesToAgX(url: string, data: any) {
    return Server.post(`anomalies/agxV2/${url}`, data);
  }

  static uploadAnomaliesToAgworld(url: string, data: any) {
    return Server.post(`anomalies/agworld/${url}`, data);
  }

  static getHistogram(index: string, md5: string, datetime: string) {
    return service.get<{bins: number[]; percentage: number[]}>(
      `data-service/histogram/${index}/${md5}/${datetime}`,
      {
        params: {__skipPreloader: true},
      }
    );
  }

  static getPointsSilverIndex(path: string, arrayPoints: number[][]) {
    return axios.post(`${config.baseUrl}histogram/vals?png=${path}`, arrayPoints);
  }

  static getMeanIndex(
    path: string,
    coordinates: Record<number, number[][]>,
    wholeFarm?: boolean
  ): Promise<{data: {Means: Record<number, number>}}> {
    if (!path) {
      return new Promise(resolve => resolve({data: {Means: {}}}));
    }

    return axios.post(
      `${config.baseUrl}mean?png=${path}${wholeFarm ? `&wholeFarm=${wholeFarm}` : ''}`,
      {
        ...coordinates,
      }
    );
  }

  static getProductTypes() {
    return Server.get(`agxdev/productTypes/`);
  }

  static getProducts(productType: string) {
    return Server.get(`agxdev/productType/${productType}/`);
  }

  static getApplicationMethods() {
    return Server.get(`agxdev/applicationMethods/`);
  }

  static getApplicationTiming() {
    return Server.get(`agxdev/applicationTimings/`);
  }

  static getApplicationRei() {
    return Server.get(`agxdev/reiUnits/`);
  }

  // static getTreeDataByDate(groupId: number, fieldId: number, currentDate: string) {
  //   return Server.get(`app/tree_detection/${groupId}/${fieldId}/${currentDate}Z`);
  // }

  static getTreeDataByField(groupId: number, fieldId: number) {
    return Server.get(`app/tree_detection/${groupId}/${fieldId}`, {
      params: {__skipPreloader: true},
    });
  }

  static getBulkTreeData(groupId: number) {
    return Server.get(`app/tree_detection/${groupId}`, {params: {__skipPreloader: true}});
  }

  static getCropTypesList(lang?: string) {
    return Server.get(`crops`, {params: {lang}});
  }

  static getCloudyV2Data(md5: string) {
    return axios
      .get(`${config.baseUrl}fense/avg/${md5}`)
      .then(({data}) => data)
      .catch(err => {
        reportError(err);
      });
  }

  static getAnalyticsChartsData(
    data: {
      points: {id: string; lat: number; lon: number}[];
      polygons: {id: string; coords: any}[];
    },
    index: string,
    md5: string,
    startDate: string,
    endDate: string,
    sourceType: AnalyticRequestSourceType
  ) {
    const cancelTokenString = `loadAnalyticsData-${index}-${sourceType}`;
    cancelTokenStore.cancel(cancelTokenString);
    const source = cancelTokenStore.generateSource(cancelTokenString);

    return service.post<{
      dates: string[];
      points: Record<string, number[]>;
      polygons: Record<string, number[]>;
    }>(`data-service/trend/${index}/${md5}/${startDate}/${endDate}?source=${sourceType}`, data, {
      cancelToken: source.token,
      params: {__skipPreloader: true},
    });
  }

  static getArableDevicesData(fieldId: number) {
    cancelTokenStore.cancel('loadFieldArableData');
    const source = cancelTokenStore.generateSource('loadFieldArableData');

    return Server.get(`arable/${fieldId}/daily`, {
      cancelToken: source.token,
      params: {__skipPreloader: true},
    })
      .then(({data}) => data.result || []) // result can be null
      .catch(err => {
        if (!axios.isCancel(err)) {
          reportError(`getArableDevicesData err = ${err}`);
        }
        return [];
      });
  }

  static getColorSchemaMinMax = (url: string) => {
    return axios.get(`${config.baseUrl}${url}`).catch(err => {
      reportError(err);
    });
  };

  static getSmoothedData = (data: {
    seasons: {
      start_date: string;
      end_date: string;
      crop_type: string;
      key: number; // any id
      md5: string;
    }[];
    data_source: AnalyticRequestSourceType;
  }) => {
    return service.post<SmoothedVIResponse>(
      `crop-status-growth/crop_performance/smoothed_vi`,
      {...data, vegetation_indexes: ['ndvi', 'ndre', 'ccci', 'msavi']},
      {params: {__skipPreloader: true}}
    );
  };

  static getFieldCompareImagesAndLayers = (data: {
    index: TSensor;
    md5: string;
    dates: string[];
    isTreeDetection?: boolean;
    geometryId?: string;
  }) => {
    const {index, md5, dates, isTreeDetection, geometryId} = data;
    const normalizedIndex = index.toLowerCase();
    const datesParam = dates.map(date => `&datetime_str_list=${date}`).join('');

    return Server.get<Blob>(`indices-compare/${normalizedIndex}/${md5}?${datesParam}`, {
      responseType: 'blob',
      params: {
        is_tree_detection: isTreeDetection,
        geometry_id: geometryId ? geometryId : null,
        __skipPreloader: true,
      },
    });
  };
}

export default ActivityAPI;
