// @ts-nocheck
import {useEffect, useState} from 'react';
import type {ConnectedProps} from 'react-redux';
import {connect} from 'react-redux';
import {loadWeatherData} from '../actions';
import type {AppStore} from '../../../reducers';
import {selectIsNitrogenZoningTab} from '../reducer/selectors';
type Props = ConnectedProps<typeof connector>;

/**
 * The component doesn't have any rendering, it exists only to handle when to load the weather.
 */
const EffectTemperatureData = ({
  //props
  isNutrilogicToggled,
  isWeatherToggled,
  isFieldInfoToggled,
  hasTemperatureData,
  loadWeatherData,
  feature,
  currentSeason,
}: Props): null => {
  const [shouldLoadWeather, setShouldLoadWeather] = useState(false);

  useEffect(() => {
    if (shouldLoadWeather && currentSeason.startDate && currentSeason.endDate) {
      setShouldLoadWeather(false);
      loadWeatherData();
    }
  }, [shouldLoadWeather, currentSeason]);

  useEffect(
    function checkWeatherAndLoadIfNecessary() {
      if (hasTemperatureData) return;

      if (
        isNutrilogicToggled ||
        isWeatherToggled ||
        isFieldInfoToggled ||
        ['analytics'].includes(feature)
      ) {
        setShouldLoadWeather(true);
      }
    },
    [
      isNutrilogicToggled,
      isWeatherToggled,
      feature,
      isFieldInfoToggled,
      hasTemperatureData,
      currentSeason, // to handle when the season was changed
    ]
  );

  return null;
};

const mapStateToProps = (s: AppStore) => ({
  currentSeason: s.map.currentSeason,
  isNutrilogicToggled:
    selectIsNitrogenZoningTab(s) && s.map.nRecommendation.method === 'nutrilogic',
  isWeatherToggled: s.map.toggledButtonOnMap === 'Weather',
  isFieldInfoToggled: s.map.toggledButtonOnMap === 'FieldInfo',
  feature: s.map.feature,
  hasTemperatureData: !!s.map.temperatureData.length,
});

const connector = connect(mapStateToProps, {
  loadWeatherData,
});
export default connector(EffectTemperatureData);
