// @ts-nocheck
import {geometryCollection} from '@turf/turf';
import L from 'leaflet';
import type {ComponentType} from 'react';
import React, {useContext, useEffect, useState} from 'react';
import {SELECTED_FIELD} from '../../../_constants/geometries-colors';
import {useAppDispatch, useAppSelector} from '../../../_hooks';
import {useMapBarView} from '../../map/map-bar/map-bar-view';
import GoHomeBtn from '../../map/map-buttons/go-home-btn';
import {OverlayContext} from '../context';
import {MRVPhaseType} from '../types';
import {TableRowsSelectionContext} from './form/table-rows-selection';
import {useMonitoringUrlParams} from './hooks';
import {selectProjectFieldsGeometries, selectStageById} from './module/selectors';
import {FluroMapPopup, FluroGeoJson} from 'components';
import {highlightField} from 'containers/map/actions';
import {selectHighlightedFieldId} from 'containers/map/reducer/selectors';
import {MonitoringColorsOverview} from './monitoring-colors-overview';
import {centerHighlightedField} from '_utils/map-utils';
import {useMap} from 'react-leaflet';
import {useMRVValues} from '../data/use-mrv-values';
import {getEntityId} from '../value-utils';

export const MonitoringOverlay: ComponentType<{}> = () => {
  const {fitBounds} = useContext(OverlayContext);
  const dispatch = useAppDispatch();
  const [isTableView] = useMapBarView();
  const {projectId, stageId} = useMonitoringUrlParams();
  const stage = useAppSelector(s => selectStageById(s, stageId));
  const fieldGeometries = useAppSelector(s => selectProjectFieldsGeometries(s, projectId));
  const [selectedRows, setSelectedFieldRows] = useContext(TableRowsSelectionContext);

  const highlightedFieldId = useAppSelector(selectHighlightedFieldId);

  const leafletElement = useMap();

  const phaseType = MRVPhaseType.Monitoring;

  const {valueRows} = useMRVValues(phaseType, projectId, stage);

  const [shouldFitBounds, setShouldFitBounds] = useState(false);

  const getAllBounds = () => {
    const geoJSON = L.geoJSON(geometryCollection(fieldGeometries));
    const bounds = geoJSON.getBounds();
    return bounds;
  };

  const onPopupClose = () => {
    dispatch(highlightField(null));
  };

  useEffect(() => {
    const fieldToCenter =
      highlightedFieldId &&
      fieldGeometries.find(geometry => geometry.properties.fieldId === highlightedFieldId);

    centerHighlightedField(fieldToCenter, leafletElement, fitBounds);
  }, [highlightedFieldId]);

  useEffect(() => {
    if (shouldFitBounds && fieldGeometries?.length) {
      const bounds = getAllBounds();
      fitBounds(bounds);
      setShouldFitBounds(false);
    }
  }, [shouldFitBounds, fieldGeometries?.length, fitBounds]);

  useEffect(() => {
    if (!isTableView) {
      setShouldFitBounds(true);
    }
  }, [isTableView, dispatch]);

  const onFieldClick = (fieldId: number, selected: boolean) => {
    dispatch(highlightField(fieldId));

    setSelectedFieldRows(ids =>
      selected
        ? ids.concat(
            valueRows
              .filter(row => getEntityId(row) === fieldId)
              .map(row => ({entityId: getEntityId(row), rowId: row.row_id}))
          )
        : ids.filter(row => row.entityId !== fieldId)
    );
  };

  if (!fieldGeometries?.length) return null;

  return (
    <>
      {fieldGeometries.map(fieldGeometry => {
        const {fieldId, position} = fieldGeometry.properties;
        const selected = selectedRows.some(row => row.entityId === fieldId);
        const shouldShowPopup = highlightedFieldId === fieldId;

        return (
          <React.Fragment key={fieldId}>
            <FluroGeoJson
              key={fieldId}
              pathOptions={{
                weight: 2,
                opacity: 1,
                fillColor: SELECTED_FIELD,
                fillOpacity: selected ? 1 : 0,
                color: 'white',
              }}
              data={fieldGeometry}
              eventHandlers={{
                click: () => onFieldClick(fieldId, !selected),
              }}
            />

            {shouldShowPopup ? (
              <FluroMapPopup
                autoPan={false} // because we are centering using centerHighlightedField()
                position={position}
                minWidth={60}
                onClose={onPopupClose}
              >
                <div className="margin-right-10">{fieldGeometry.properties.name}</div>
              </FluroMapPopup>
            ) : null}
          </React.Fragment>
        );
      })}

      <GoHomeBtn fitBounds={() => fitBounds(getAllBounds())} />
      <MonitoringColorsOverview />
    </>
  );
};
