import React, {useEffect, useRef, useState} from 'react';
import {useAppDispatch, useAppSelector} from '_hooks';
import {centerOfMass} from '@turf/turf';
import {
  selectCurrentProgram,
  selectSIHaveAllVisibleSubsectionByYear,
  selectSIYearsFilter,
} from 'containers/si/module/selectors';
import {fetchKPISubsectionArea} from 'containers/si/module/thunks';
import {clickableFeaturesFillLayer, programGeometriesLayer} from 'containers/si/map/map-style';
import {generateFeatureCollection} from 'containers/si/module/base';
import type {MapRef} from 'react-map-gl';
import {Source, Layer} from 'react-map-gl';
import {SIBaseMap} from 'containers/si/map/map';
import type {FeatureCollection} from 'geojson';
import {useFetchGeometries} from 'containers/si/hooks/useFetchGeometries';
import {CircularProgress} from 'react-md';
import {CenterContent} from 'containers/si/si-styled-components';

const kpiMapStyle: React.CSSProperties = {
  width: '100%',
  height: '100%',
  position: 'relative',
};

type AreasMapProps = {
  selectedSubsectionIds: number[];
};

export const AreasMap = ({selectedSubsectionIds}: AreasMapProps) => {
  const mapRef = useRef<MapRef>();
  const dispatch = useAppDispatch();
  const currentProgram = useAppSelector(selectCurrentProgram);
  const yearsFilter = useAppSelector(selectSIYearsFilter);
  const haveAreas = useAppSelector(s => selectSIHaveAllVisibleSubsectionByYear(s, yearsFilter[0]));

  const [filteredGeometries, setFilteredGeometries] = useState<FeatureCollection | null>(null);

  const {isLoading, currentProgramGeometries, allGeometryFeatures} =
    useFetchGeometries('supply-shed');

  useEffect(() => {
    // Generate the filtered geometries to display selected Supply Sheds on the map
    const filtered = generateFeatureCollection({
      geometries: currentProgramGeometries,
      idsToInclude: selectedSubsectionIds,
      type: 'sub-section',
    });
    setFilteredGeometries(filtered);
  }, [currentProgramGeometries, selectedSubsectionIds]);

  useEffect(() => {
    // Center the map on the Filtered Supply Sheds
    if (filteredGeometries && filteredGeometries.features.length > 0) {
      const center = centerOfMass(filteredGeometries);
      mapRef &&
        mapRef.current?.flyTo({
          center: [center.geometry.coordinates[0], center.geometry.coordinates[1]],
          zoom: 3,
        });
    }
  }, [filteredGeometries]);

  useEffect(() => {
    if (!currentProgram) return;
    if (yearsFilter.length === 0) return;
    if (haveAreas) return;

    dispatch(fetchKPISubsectionArea({programId: currentProgram, year: yearsFilter[0]}));
  }, [currentProgram, dispatch, haveAreas, yearsFilter]);

  return (
    <div className="map-container">
      <div className={'kpi-map'}>
        {isLoading ? (
          <CenterContent>
            <CircularProgress id="loading-map" />
          </CenterContent>
        ) : (
          <SIBaseMap
            mapRef={mapRef}
            styleSelectors={false}
            style={kpiMapStyle}
            overrideMapStyle={'light'}
            interactiveLayerIds={['clickable-features-fill']}
          >
            {/* This layer is a background layer with ALL Supply Sheds */}
            {allGeometryFeatures && (
              <Source id="kpi-geometries" type={'geojson'} data={allGeometryFeatures}>
                <Layer {...clickableFeaturesFillLayer} />
              </Source>
            )}
            {/* This is the clickable layer to turn on and off Supply Sheds */}
            {filteredGeometries && (
              <Source id="kpi-clickable-geometries" type={'geojson'} data={filteredGeometries}>
                <Layer {...programGeometriesLayer} />
              </Source>
            )}
          </SIBaseMap>
        )}
      </div>
    </div>
  );
};
