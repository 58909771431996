import {FormattedMessage, t} from 'i18n-utils';
import * as React from 'react';
import cn from 'classnames';
import IntegrationCard from './helpers/integration-card';
import {Ln, Sticky, FluroButton} from 'components';
import {ExternalService} from '../../map/types';
import type {ComponentType} from 'react';
import type {IntegrationComponentProps} from './types';
import {SelectedPlatformBackButton} from './helpers/selected-platform-back-btn';

const IntegrationClimate: ComponentType<IntegrationComponentProps> = ({expanded}) => {
  return (
    <div className={cn('integration-platform agrian', {selected: expanded})}>
      <IntegrationCard
        authorized={true}
        expanded={expanded}
        onAuthorize={() => null}
        platform={ExternalService.Agrian}
      />

      {expanded && (
        <>
          <div className="integration-help-text">
            <FormattedMessage
              id="Please contact us if you want to import data from Agrian, we'll need access to your account details and the list of farms you would like to import. <a>Read more about the connection process.</a>"
              defaultMessage="Please contact us if you want to import data from Agrian, we'll need access to your account details and the list of farms you would like to import. <a>Read more about the connection process.</a>"
              values={{
                a: (txt: string) => (
                  <Ln
                    href={
                      'https://help.flurosense.com/en/articles/4790718-how-to-connect-your-agrian-or-efc-fieldalytics-account'
                    }
                    external
                    blank
                  >
                    {txt}
                  </Ln>
                ),
              }}
            />
          </div>

          <Sticky className="action-buttons-holder">
            <SelectedPlatformBackButton />
            <FluroButton href={'mailto:support@regrow.ag'} raised primary>
              {t({id: 'Contact us'})}
            </FluroButton>
          </Sticky>
        </>
      )}
      {/*<ColoredBorder colorStart={'rgb(43, 69, 124)'} colorEnd={'rgba(43, 69, 124, 0.2)'} />*/}
    </div>
  );
};

export default IntegrationClimate;
