import {t} from 'i18n-utils';
import React, {useCallback} from 'react';
type Props = {
  disabled?: boolean;
  onClick?: () => void;
};

const ClearFilterButton = ({onClick, disabled}: Props) => {
  const _onClick = useCallback(
    e => {
      e.stopPropagation();
      onClick && onClick();
    },
    [onClick]
  );

  return (
    <button className="clear-filter-btn card-filter-button" disabled={disabled} onClick={_onClick}>
      <img src="/assets/filter.svg" alt="filter" title={t({id: 'Clear filters'})} width="20" />
    </button>
  );
};

export default ClearFilterButton;
