import React from 'react';
import styled from 'styled-components';
import {ProgramAnnouncement} from './program-announcement';
import {useAppSelector} from '_hooks';
import {selectCurrentProgram} from 'containers/mrv/monitoring/module/selectors';

export const ProgramAnnouncementBanner = () => {
  const program = useAppSelector(selectCurrentProgram);
  if (!program?.display_announcement) return null;
  return (
    <ProgramAnnouncementBannerContainer>
      <ProgramAnnouncement program={program} />
    </ProgramAnnouncementBannerContainer>
  );
};

const ProgramAnnouncementBannerContainer = styled.div`
  position: absolute;
  left: 15px;
  bottom: 100px;
  width: 250px;
  z-index: 400; // to be on top of the map tiles
`;
