import {t} from 'i18n-utils';
import React from 'react';
import {FontIcon} from 'react-md';
import {dialogToggle, DialogType} from 'modules/helpers';
import {useAppDispatch, useAppSelector} from '_hooks';
import {
  selectAppProcessingStatus,
  selectCurrentSeasonId,
  selectImagesStatus,
  selectRemoteSensingFilterProps,
} from '../map/reducer/selectors';
import {FluroChip} from 'components/fluro-chip/fluro-chip';

type Props = {
  mobileView?: boolean; // used to display data in FluroChips in certain areas (not in the toggle header) for mobiles
};

const NoData = ({mobileView}: Props) => {
  const dispatch = useAppDispatch();
  const appProcessingStatus = useAppSelector(selectAppProcessingStatus);
  const remoteSensingFilterProps = useAppSelector(selectRemoteSensingFilterProps);
  const imageStatus = useAppSelector(selectImagesStatus);
  const seasonId = useAppSelector(selectCurrentSeasonId);

  const {isImagesProcessing, isNoImagery, isFuture} = imageStatus;

  let noDataContent = null;

  if (!noDataContent && isImagesProcessing) {
    noDataContent = (
      <div>{isFuture ? t({id: 'No imagery yet'}) : t({id: 'Processing data...'})}</div>
    );
  }

  if (!noDataContent && ((isNoImagery && !remoteSensingFilterProps.length) || !seasonId)) {
    noDataContent = <div>{t({id: 'No imagery'})}</div>;
  }

  if (!noDataContent && appProcessingStatus === 'manually-hidden') {
    noDataContent = <div>{t({id: 'Hidden imagery'})}</div>;
  }

  if (!noDataContent) {
    noDataContent = (
      <>
        <div>
          {remoteSensingFilterProps.length
            ? t({id: 'Imagery is filtered'})
            : t({id: 'Cloudy imagery only'})}
        </div>
        <FontIcon onClick={() => dispatch(dialogToggle(DialogType.cloudyTip, true))}>
          help_outline
        </FontIcon>
      </>
    );
  }

  if (mobileView) {
    return <FluroChip label={noDataContent} />;
  }

  if (!noDataContent) return null;

  return <div className="only-cloudy">{noDataContent}</div>;
};

export default NoData;
