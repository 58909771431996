import type {FillLayer, LineLayer} from 'react-map-gl';

// Supply Shed and other selected program geometries
export const programGeometriesLayer: FillLayer = {
  id: 'project-geometries-fill',
  type: 'fill',
  paint: {
    'fill-color': ['get', 'color'],
    'fill-opacity': 0.8,
    'fill-outline-color': 'white',
  },
};

// Background clickable features (State, County, etc)
export const clickableFeaturesFillLayer: FillLayer = {
  id: 'clickable-features-fill',
  type: 'fill',
  paint: {
    'fill-color': '#7a7a7a',
    'fill-opacity': 0.3,
  },
};
export const clickableFeaturesLineLayer: LineLayer = {
  id: 'clickable-features-line',
  type: 'line',
  paint: {
    'line-color': 'white',
    'line-opacity': 1,
    'line-width': 1,
  },
};

// Selected geometries (State, County, etc)
export const selectedFeaturesFillLayer: Omit<FillLayer, 'id'> = {
  type: 'fill',
  paint: {
    'fill-color': '#4988ef',
    'fill-opacity': 0.8,
    'fill-outline-color': 'white',
  },
};
export const selectedFeaturesLineLayer: Omit<LineLayer, 'id'> = {
  type: 'line',
  paint: {
    'line-color': 'white',
    'line-opacity': 1,
    'line-width': 2,
  },
};

// Circle and Polygon drawing options
export const shapeDrawProps = {
  activeFillColor: '#4988ef',
  activeFillOpacity: 0.8,
  activeLineColor: 'white',
  activeLineWidth: 2,
  inactiveFillColor: '#4988ef',
  inactiveFillOpacity: 0.8,
  inactiveLineColor: 'white',
  inactiveLineWidth: 2,
};
