import {createSelector} from '@reduxjs/toolkit';
import type {
  SISupplyShed,
  SICropTypeOptions,
  PlanningReport,
  SIScenario,
} from 'containers/si/types';
import type {ProgramResponse, TabAccess} from 'containers/si/api/apiTypes';

import type {AccessType} from './types';
import {SI_CROPS, PLANNING_REPORT_INTERVENTION_REVERSE_MAPPING} from 'containers/si/constants';
import type {AppStore} from 'reducers';
import {selectMeasurement} from 'containers/login/login-selectors';
import {createCachingSelector, createDeepEqualSelector} from '_utils/pure-utils';
import {selectCropTypes} from 'modules/global/selectors';
import {calculateKPIValue} from 'containers/si/planning-report/dashboard/utils/calculate-scenario';

export const selectEntities = (s: AppStore) => s.si.entities;
export const selectAccess = createSelector([(s: AppStore) => s.si], e => e.access);
export const selectReports = (s: AppStore) => s.si.reports;

const retrieveIdParam = (s: AppStore, id: number) => id;

export const selectSIPrograms = createSelector([selectEntities], e => e.programs);

export const selectCurrentProgram = (s: AppStore) => s.si.programs.current;
export const selectProgramProcessing = (s: AppStore) => s.si.programs.processing;
export const selectDataExportAgreement = (s: AppStore) => s.si.programs.dataExportAgreement;

export const selectKPIData = (s: AppStore) => s.si.kpis;

export const selectUserIsSIProgramAdmin = createSelector([selectSIPrograms], programs => {
  return Object.values(programs).some(p => p.current_user_role === 'admin');
});

export const selectSIProgramById = createSelector(
  [selectSIPrograms, retrieveIdParam],
  (programs, id): ProgramResponse | null => {
    return programs[id] || null;
  }
);

export const selectSIAccessByProgramId = createSelector(
  [selectAccess, retrieveIdParam],
  (access, id): AccessType | null => {
    return access[id] || null;
  }
);

export const selectSIProgramUsersByProgramId = createSelector(
  [selectSIAccessByProgramId],
  access => {
    return access?.users || [];
  }
);

export const selectSIProgramAdminsByProgramId = createSelector(
  [selectSIAccessByProgramId],
  access => {
    return access?.admins || [];
  }
);

export const selectSIProgramsList = createSelector([selectSIPrograms], programs => {
  return Object.entries(programs)
    .sort((a, b) => Number(b[0]) - Number(a[0]))
    .map(([_id, program]) => program);
});

// SI Map
export const selectMapParams = (s: AppStore) => s.si.map;
export const selectSupplyShedMapParams = (s: AppStore) => s.si.supplyShedMap;

export const selectMapStyle = createSelector([selectMapParams], mapParams => {
  return mapParams.style;
});

export const selectMapInitialView = createSelector([selectMapParams], mapParams => {
  return mapParams.initialView;
});

export const selectSelectLayerID = createSelector([selectSupplyShedMapParams], mapParams => {
  return mapParams.selection.layerID;
});

export const selectInteractionType = createSelector([selectSupplyShedMapParams], mapParams => {
  return mapParams.interactionType;
});

export const selectShouldShowMapInputTools = createSelector(
  [selectSupplyShedMapParams],
  mapParams => {
    return mapParams.showTools;
  }
);

export const selectSubsectionGeometries = createSelector([selectSupplyShedMapParams], mapParams => {
  return mapParams.subsectionGeometries;
});

export const selectSubsectionSelectedFeatures = createSelector(
  [selectSupplyShedMapParams],
  mapParams => {
    return mapParams.subsectionSelectedFeatures;
  }
);

export const selectProgramGeometries = createSelector([selectSupplyShedMapParams], mapParams => {
  return mapParams.programGeometries;
});

export const selectShouldShowProgramGeometries = createSelector(
  [selectSupplyShedMapParams],
  mapParams => {
    return mapParams.showProgramGeometries;
  }
);

// This selector looks through a users CURRENT PROGRAM and returns a list of their 'tab_accesses'
export const selectTabsUserHasAccessTo = createCachingSelector(
  [(s: AppStore) => selectSIProgramById(s, Number(selectCurrentProgram(s)))],
  program => {
    const userTabs = new Set<TabAccess>(program?.tab_accesses);
    userTabs.add('admin'); // Everyone always has access to the admin tab

    return Array.from(userTabs);
  }
);

// Supply Sheds
export const selectSISupplySheds = createDeepEqualSelector([selectEntities], e => e.supplySheds);

export const selectSISupplyShedsListByProgramId = createSelector(
  [selectSISupplySheds, retrieveIdParam],
  (programs: Record<number, SISupplyShed[]>, programId: number) => {
    return programs[programId] ?? [];
  }
);

export const selectSISupplyShedsObjectByProgramId = createSelector(
  [selectSISupplySheds, retrieveIdParam],
  (programs: Record<number, SISupplyShed[]>, programId: number) => {
    const supplyShedsObject: Record<number, SISupplyShed> = {};
    programs &&
      programs[programId]?.map(supplyShed => {
        return (supplyShedsObject[supplyShed.id] = supplyShed);
      });

    return supplyShedsObject;
  }
);

export const selectSICurrentSupplyShedsList = createDeepEqualSelector(
  [(s: AppStore) => selectSISupplyShedsListByProgramId(s, Number(selectCurrentProgram(s)))],
  supplySheds => {
    return supplySheds ?? [];
  }
);

export const selectSIProgramGeometries = (s: AppStore) => s.si.entities.geometries;

export const selectSICurrentProgramGeometries = createDeepEqualSelector(
  [selectSIProgramGeometries, retrieveIdParam],
  (programGeometries, id) => {
    return programGeometries[id] ?? [];
  }
);

export const selectSIAreCurrentSupplyShedsProcessing = createSelector(
  [selectSICurrentSupplyShedsList],
  supplySheds => {
    return supplySheds.some(shed => {
      return shed.subsections.some(sub => sub.status !== 'ACTIVE');
    });
  }
);

export const selectSISupplyShedsBySupplyShedId = createSelector(
  [selectSISupplySheds, retrieveIdParam],
  (programs: Record<number, SISupplyShed[]>, id: number) => {
    if (programs) {
      return (
        Object.values(programs)
          .map((shedList: SISupplyShed[]) => {
            if (shedList.length > 0) {
              return shedList?.find(shed => shed.id === id);
            }
            return null;
          })
          .filter(shed => shed !== null)[0] || null
      );
    }
    return null;
  }
);

export const selectSICropTypeOptions = createDeepEqualSelector([selectCropTypes], cropTypes => {
  const options = Object.entries(SI_CROPS)
    .map(([key, value]) => {
      if (!value || !cropTypes[value]) {
        return null;
      }
      const {label, color, icon} = cropTypes[value];

      return {label, value: key, color, icon, type: value};
    })
    .filter(Boolean);

  return options as SICropTypeOptions[];
});

export const selectSI2Filter = (state: AppStore) => state.si.kpiFilter;

export const selectSIVisibleSubsectionIds = createDeepEqualSelector(
  [selectSI2Filter],
  filter => filter.visibleSubsectionIds
);

// This selector looks through all crops in each of the programs VISIBLE Supply Sheds and returns a list the cropIds
// The Visible Supply Sheds are determined by the RegionsSelect filter
export const selectCurrentProgramCrops = createDeepEqualSelector(
  [selectSICurrentSupplyShedsList, selectSIVisibleSubsectionIds, selectSICropTypeOptions],
  (supplySheds, visibleSubsectionIds, cropTypes) => {
    const programCrops = new Set<SICropTypeOptions>();
    supplySheds.forEach(shed => {
      shed.subsections.forEach(sub => {
        if (visibleSubsectionIds.includes(sub.id)) {
          sub?.commodities?.forEach(crop => {
            const cropOption = cropTypes.find(c => String(c.value) === String(crop.id));
            if (cropOption) {
              programCrops.add(cropOption);
            }
          });
        }
      });
    });
    return Array.from(programCrops);
  }
);

// Returns a cropId[] of all crops in the visible supply sheds
export const selectAvailableCropIDs = createSelector([selectCurrentProgramCrops], crops => {
  return crops.map(crop => Number(crop.value));
});

export const selectSISavedFilters = createSelector([selectSI2Filter], filter => filter.saved);

export const selectSICurrentFilter = createSelector([selectSI2Filter], filter => {
  return {
    years: filter.years,
    selectedCropTypes: filter.selectedCropTypes,
    visibleSubsectionIds: filter.visibleSubsectionIds,
  };
});

export const selectSIYearsFilter = createSelector([selectSI2Filter], filter => filter.years);

export const selectSIYearsAvailable = createSelector(
  [(s: AppStore) => selectSIProgramById(s, Number(selectCurrentProgram(s)))],
  currentProgram => {
    return [
      currentProgram?.crop_year_start || 2000,
      currentProgram?.crop_year_end || new Date().getFullYear(),
    ];
  }
);

export const selectSIAvailableSubSectionIds = createDeepEqualSelector(
  [selectSICurrentSupplyShedsList],
  supplySheds => supplySheds.map(shed => shed.subsections.map(subsect => subsect.id)).flat()
);

export const selectSIAvailableSupplyShedIds = createDeepEqualSelector(
  [selectSICurrentSupplyShedsList],
  supplySheds => supplySheds.map(shed => shed.id)
);

export const selectSIRegionOptions = createSelector([selectSICurrentSupplyShedsList], supplySheds =>
  supplySheds.map(shed => ({
    label: shed.name,
    //Concats the supply shed id with the word 'supply-shed' to make it unique for the dropdown group
    value: `${shed.id}-supply-shed`,
    options: shed.subsections.map(subsect => ({label: subsect.name, value: subsect.id})),
  }))
);

export const selectSISelectedCropTypes = createSelector(
  [selectSI2Filter],
  filter => filter.selectedCropTypes
);

export const selectSICropHaBySubsectionIdsByYear = createSelector(
  [selectSI2Filter],
  filter => filter.cropHaBySubsectionIdsByYear || {}
);

export const selectSIVisibleSubsectionArea = createDeepEqualSelector(
  [selectSICropHaBySubsectionIdsByYear, selectSIVisibleSubsectionIds, retrieveIdParam],
  (subsectionByIdByYear, visibleSubsectionIds, year) => {
    const subsectionArea = Object.entries(subsectionByIdByYear).map(
      ([subsectionId, supplyShed]) => {
        if (visibleSubsectionIds.includes(Number(subsectionId))) {
          return Number(supplyShed[year]) || 0;
        }
        return 0;
      }
    );

    return subsectionArea.reduce((acc, supplyShed) => {
      return acc + supplyShed;
    }, 0);
  }
);

export const selectSIHaveAllVisibleSubsectionByYear = createSelector(
  [selectSICropHaBySubsectionIdsByYear, selectSIVisibleSubsectionIds, retrieveIdParam],
  (subsectionByIdByYear, visibleSubsectionIds, year) => {
    return Object.keys(subsectionByIdByYear).some(supplyShedId => {
      return (
        visibleSubsectionIds.includes(Number(supplyShedId)) &&
        subsectionByIdByYear[Number(supplyShedId)][year]
      );
    });
  }
);

export const selectSICurrentPracticeKPIs = createSelector(
  [(s: AppStore) => selectSIProgramById(s, Number(selectCurrentProgram(s)))],
  currentProgram => {
    return currentProgram?.practice_kpis || [];
  }
);

export const selectSICurrentOutcomeKPIs = createSelector(
  [(s: AppStore) => selectSIProgramById(s, Number(selectCurrentProgram(s)))],
  currentProgram => {
    return currentProgram?.outcome_kpis || [];
  }
);

// Scenario Modeling / Planning Reports / Abatement Potential / IMT
export const selectPlanningReports = createSelector([selectReports], e => e.planningReports);
export const selectNewReport = createSelector([selectReports], e => e.new);
export const selectNewReportAreas = createSelector([selectNewReport], e => e.areas);
export const selectNewReportCrops = createSelector([selectNewReport], e => e.crops);
export const selectCropStepBlocked = createSelector([selectNewReport], e => e.cropStepBlocked);
export const selectNewInterventions = createSelector([selectNewReport], e => e.interventions);
export const selectNewReportName = createSelector([selectNewReport], e => e.name);
export const selectNewReportStartYear = createSelector([selectNewReport], e => e.startYear);
export const selectNewReportEndYear = createSelector([selectNewReport], e => e.endYear);
export const selectNewReportType = createSelector([selectNewReport], e => e.reportType);
export const selectNewReportModel = createSelector([selectNewReport], e => e.model);
export const selectNewReportProtocol = createSelector([selectNewReport], e => e.protocol);

export const selectPlanningReportsListByProgramId = createSelector(
  [selectPlanningReports, retrieveIdParam],
  (reports: Record<number, PlanningReport[]>, programId: number) => {
    return reports[programId] ?? [];
  }
);

// Returns {[supplyShedId]: subsectionId[]}
export const selectCurrentProgramSupplyShedAndSubsectionIds = createSelector(
  [selectSICurrentSupplyShedsList],
  supplySheds => {
    const supplyShedObject = {} as Record<number, number[]>;

    supplySheds.map(supplyShed => {
      const id = supplyShed.id;
      const subsectionIdArray = supplyShed.subsections.map(subsection => subsection.id);

      return (supplyShedObject[id] = subsectionIdArray);
    });

    return supplyShedObject;
  }
);

// Returns {[subsectionId]: supplyShedId}
export const selectCurrentProgramSupplyShedIdBySubsectionId = createSelector(
  [selectCurrentProgramSupplyShedAndSubsectionIds],
  supplyShedAndSubsectionIds => {
    const subsectionObject = {} as Record<number, number>;

    Object.entries(supplyShedAndSubsectionIds).map(([supplyShedId, subsectionIdArray]) => {
      return subsectionIdArray.map(subsectionId => {
        return (subsectionObject[subsectionId] = Number(supplyShedId));
      });
    });

    return subsectionObject;
  }
);

// Scenario Modeling / Planning Report Dashboard
export const selectReportData = createSelector([selectReports], e => e.data);
export const selectReportDataById = createSelector(
  [selectReportData, retrieveIdParam],
  (data, reportId) => {
    return data[reportId] ?? [];
  }
);

export const selectReportDashboard = createSelector([selectReports], e => e.dashboard);

export const selectReportProgramId = createSelector([selectReportDashboard], e => e.programId);
export const selectReportId = createSelector([selectReportDashboard], e => e.reportId);
export const selectReportType = createSelector([selectReportDashboard], e => e.reportType);
export const selectReportPageIndex = createSelector([selectReportDashboard], e => e.pageIndex);
export const selectReportPageSize = createSelector([selectReportDashboard], e => e.pageSize);
export const selectReportOrderBy = createSelector([selectReportDashboard], e => e.orderBy);
export const selectReportOrderDirection = createSelector(
  [selectReportDashboard],
  e => e.orderDirection
);
export const selectReportOnlyTopScenarios = createSelector(
  [selectReportDashboard],
  e => e.onlyTopScenarios
);
export const selectReportDataUnits = createSelector([selectReportDashboard], e => e.dataUnits);
export const selectReportCropFilter = createSelector([selectReportDashboard], e => e.cropFilter);
export const selectReportSourcingRegionFilter = createSelector(
  [selectReportDashboard],
  e => e.sourcingRegionFilter
);
export const selectReportInterventionFilter = createSelector(
  [selectReportDashboard],
  e => e.interventionFilter
);
export const selectShowIneligible = createSelector([selectReportDashboard], e => e.showIneligible);
export const selectCarbonPrice = createSelector([selectReportDashboard], e => e.carbonPrice);
export const selectCarbonUnit = createSelector([selectReportDashboard], e => e.carbonUnit);
// export const selectSelectedScenario = createSelector(
//   [selectReportDashboard],
//   e => e.selectedScenario
// );
export const selectPracticeAdoption = createSelector([selectReportDashboard], e => e.adoption);

export const selectReportDetails = createSelector(
  [
    (s: AppStore) => selectPlanningReportsListByProgramId(s, Number(selectReportProgramId(s))),
    retrieveIdParam,
  ],
  (reports, reportId) => {
    return reports.find(report => report.id === Number(reportId));
  }
);

// ALL Dashboard data, not paginated
export const selectAllReportDashboardData = createSelector(
  [
    (s: AppStore) => selectReportDataById(s, Number(selectReportId(s))),
    selectReportOrderBy,
    selectReportOrderDirection,
    selectReportDataUnits,
    selectReportCropFilter,
    selectReportSourcingRegionFilter,
    selectReportInterventionFilter,
    selectReportOnlyTopScenarios,
    selectReportType,
    selectShowIneligible,
    selectMeasurement,
  ],
  (
    data,
    orderBy,
    orderDirection,
    dataUnits,
    cropFilter,
    sourcingRegionFilter,
    interventionFilter,
    onlyTopScenarios,
    reportType,
    showIneligible,
    measurement
  ) => {
    let filteredData: SIScenario[] = data;
    const dir = orderDirection === 'asc' ? 1 : -1;

    // SourcingRegion Filter
    filteredData = filteredData.filter(d => {
      if (d.tag === 'abatement_potential') {
        return sourcingRegionFilter.includes(
          `${d.sourcing_region_id.unit_id}+++${d.sourcing_region_id.unit_type}`
        );
      } else if (d.tag === 'program_plan') {
        return sourcingRegionFilter.includes(
          `${d.geography.feature_id}+++${d.geography.collection_id}`
        );
      }
      return false;
    });

    // Crop Type Filter
    filteredData = filteredData.filter(d => cropFilter.includes(d.crop_type));

    // Intervention Filter
    filteredData = filteredData.filter(d => interventionFilter.includes(d.scenario_id));

    // Intervention Filter
    if (onlyTopScenarios) {
      // only want to show the best scenario for orderBy for each SourcingRegion and Crop combination
      const uniqueSourcingRegions = new Set<string>();
      const uniqueCrops = new Set<number>();
      const uniqueScenarios = new Set<number>();

      filteredData.forEach(scenario => {
        if (scenario.tag === 'abatement_potential') {
          uniqueSourcingRegions.add(
            `${scenario.sourcing_region_id.unit_id}+++${scenario.sourcing_region_id.unit_type}`
          ); // concat the type to the id to make it unique, since SupplyShedId and SubsectionId could be the same
          uniqueCrops.add(scenario.crop_type);
        } else if (scenario.tag === 'program_plan') {
          uniqueSourcingRegions.add(
            `${scenario.geography.feature_id}+++${scenario.geography.collection_id}`
          ); // concat the type to the id to make it unique, since SupplyShedId and SubsectionId could be the same
          uniqueCrops.add(scenario.crop_type);
        }
      });

      uniqueSourcingRegions.forEach(area => {
        const uniqueIdOne = area.split('+++')[0]; // unit_id or feature_id
        const uniqueIdTwo = area.split('+++')[1]; // unit_type or collection_id

        uniqueCrops.forEach(crop => {
          let scenarios: SIScenario[] = [];
          scenarios = filteredData.filter(scenario => {
            if (scenario.tag === 'abatement_potential') {
              const scenarioMatch =
                scenario.sourcing_region_id.unit_id === Number(uniqueIdOne) &&
                scenario.sourcing_region_id.unit_type === uniqueIdTwo &&
                scenario.crop_type === crop;

              return scenarioMatch;
            } else if (scenario.tag === 'program_plan') {
              const scenarioMatch =
                scenario.geography.feature_id === uniqueIdOne &&
                scenario.geography.collection_id === uniqueIdTwo &&
                scenario.crop_type === crop;

              return scenarioMatch;
            }
            return false;
          });

          const bestScenario = scenarios.sort((a, b) => {
            const scenarioA = calculateKPIValue({
              scenario: a,
              kpi: orderBy,
              units: dataUnits,
              measurement,
              round: false,
            }).value;
            const scenarioB = calculateKPIValue({
              scenario: b,
              kpi: orderBy,
              units: dataUnits,
              measurement,
              round: false,
            }).value;

            if (scenarioA < scenarioB) {
              return dir;
            }
            if (scenarioA > scenarioB) {
              return dir * -1;
            }

            return 0;
          })[0];

          bestScenario && uniqueScenarios.add(bestScenario.id);
        });
      });
      filteredData = filteredData.filter(scenario => uniqueScenarios.has(scenario.id));
    }

    // Ineligible Filter (only for Program Plan Reports)
    if (showIneligible === false && reportType === 'program_plan') {
      // This will look at `baseline.scenario_adoption` and compare it to the not yet built adoption threshold for each scenario
    }

    // Need to order by 'ef_reductions' | 'ghg_reductions' | 'soc_removals' | 'net_ghg'
    if (orderBy) {
      filteredData = filteredData.sort((a, b) => {
        const scenarioA = calculateKPIValue({
          scenario: a,
          kpi: orderBy,
          units: dataUnits,
          measurement,
          round: false,
        }).value;
        const scenarioB = calculateKPIValue({
          scenario: b,
          kpi: orderBy,
          units: dataUnits,
          measurement,
          round: false,
        }).value;

        if (scenarioA < scenarioB) {
          return dir;
        }
        if (scenarioA > scenarioB) {
          return dir * -1;
        }

        return 0;
      });
    }
    return filteredData || [];
  }
);

// Takes ALL dashboard data and paginates it
export const selectReportDashboardData = createSelector(
  [selectAllReportDashboardData, selectReportPageIndex, selectReportPageSize],
  (data, pageIndex, pageSize) =>
    data.slice(pageIndex * pageSize, pageIndex * pageSize + pageSize) || []
);

// Gets the length of the filtered data
export const selectReportRecordCount = createSelector(
  [selectAllReportDashboardData],
  e => e.length || 0
);

// This selector looks through all scenarios in the report (by reportId) and returns a list of unique crop types
export const selectReportCrops = createCachingSelector(
  [selectReportDataById, selectSICropTypeOptions],
  (data, cropTypes) => {
    const scenarioCrops = new Set<SICropTypeOptions>();

    data.forEach(scenario => {
      const cropOption = cropTypes.find(c => String(c.value) === String(scenario.crop_type));

      if (cropOption) {
        scenarioCrops.add(cropOption);
      }
    });
    return Array.from(scenarioCrops) as SICropTypeOptions[];
  }
);

// Returns a cropId[] of all crops in the report (by reportId)
export const selectReportAvailableCropIDs = createSelector([selectReportCrops], crops => {
  return crops.map(crop => Number(crop.value));
});

export const selectReportAreAllCropsSelected = createSelector(
  [selectReportAvailableCropIDs, selectReportCropFilter],
  (available, selected) => available.length === selected.length
);

// Looks through all SupplySheds and Subsections in order to get their names. Only returns the ones that are in the report
export const selectReportAvailableSourcingRegions = createSelector(
  [
    selectSICurrentSupplyShedsList,
    (s: AppStore) => selectReportDetails(s, Number(selectReportId(s))),
    selectReportType,
    (s: AppStore) => selectReportDataById(s, Number(selectReportId(s))),
  ],
  (supplySheds, report, reportType, data) => {
    const allSourcingRegions: {[key: string]: string} = {};

    // If the reportType is 'abatement_potential', then the report will have a combination of SupplySheds and Subsections
    // If the reportType is 'program_plan', then the report will only be a single SupplyShed, but we will need to get the counties in that SupplyShed from the API call
    switch (reportType) {
      case 'abatement_potential':
        const reportSourcingRegions = report?.sourcing_regions?.map(area => [
          area.unit_id,
          area.unit_type,
        ]);
        //returns a list of supply sheds that are in the report, concat the type to the id to make it unique, since SupplyShedId and SubsectionId could be the same
        supplySheds.map(supplyShed => {
          if (
            reportSourcingRegions?.some(
              area => (area[0] === supplyShed.id && area[1] === 'supply_shed') || null
            )
          ) {
            allSourcingRegions[`${supplyShed.id}+++supply_shed`] = supplyShed.name;
          }
          return supplyShed.subsections.map(subsection => {
            if (
              reportSourcingRegions?.some(
                area => (area[0] === subsection.id && area[1] === 'subsection') || null
              )
            ) {
              return (allSourcingRegions[
                `${subsection.id}+++subsection`
              ] = `${supplyShed.name} / ${subsection.name}`);
            } else {
              return null;
            }
          });
        });
        break;
      case 'program_plan':
        data.forEach(scenario => {
          // Need to loop through the scenarios and get the unique scenario.geography.feature_id
          if (scenario.tag === 'program_plan') {
            const countyId = `${scenario.geography.feature_id}+++${scenario.geography.collection_id}`;
            const countyName = scenario.geography.feature_name;
            allSourcingRegions[countyId] = countyName;
          }
        });
        break;
      default:
        const _exhaustiveCheck: never = reportType;

        return _exhaustiveCheck;
    }
    return allSourcingRegions;
  }
);

export const selectReportAreAllSourcingRegionsSelected = createSelector(
  [selectReportAvailableSourcingRegions, selectReportSourcingRegionFilter],
  (available, selected) => Object.keys(available).length === selected.length
);

export const selectReportAvailableInterventions = createSelector(
  [(s: AppStore) => selectReportDetails(s, Number(selectReportId(s)))],
  report => {
    const allInterventions: {[key: string]: string} = {};

    report?.intervention_scenarios?.map(intervention => {
      const intArr: string[] = intervention.map(int => {
        const label: string = PLANNING_REPORT_INTERVENTION_REVERSE_MAPPING[
          int.intervention_type
        ].label(int.percentage);

        return label;
      });

      return (allInterventions[Number(intervention[0].scenario_id)] = intArr.join(' + '));
    });

    return allInterventions;
  }
);

// KPI Data
export const selectGHGEmissionsFactor = createSelector(
  selectKPIData,
  kpis => kpis.emission_factors.ghg
);

export const selectNetEmissionsFactor = createSelector(
  selectKPIData,
  kpis => kpis.emission_factors.net
);

export const selectFertilizerEmissionsFactor = createSelector(
  selectKPIData,
  kpis => kpis.emission_factors.fertilizer
);

export const selectBookValues = createSelector(selectKPIData, kpis => kpis.bookValues);
