// @ts-nocheck
import {useAppSelector} from '_hooks';
import {
  selectCurrentField,
  selectCurrentSeason,
  selectCurrentTab,
  selectInfoExt,
} from '../../../reducer/selectors';
import {doesFieldHasSeasonWithNRx} from 'containers/map/features/nrx';
import {selectCurrentNRxSeason} from '../../nrx/nrx-selectors';

export const useNitrogenRx = () => {
  const field = useAppSelector(selectCurrentField);
  const infoExt = useAppSelector(selectInfoExt);
  const currentPointId = useAppSelector(s => s.map.currentPointId);
  const isAustralianFieldOrBowles = useAppSelector(s => s.map.isAustralianFieldOrBowles);
  const currentSeason = useAppSelector(selectCurrentSeason);
  const nrxSeason = useAppSelector(selectCurrentNRxSeason);
  const currentTab = useAppSelector(selectCurrentTab);

  const isNrxAllowed = () => {
    return Boolean(
      (infoExt?.length || (currentTab === 'zoning' && nrxSeason)) &&
        doesFieldHasSeasonWithNRx(field)
    );
  };

  const isNutrilogicAllowed = () => {
    return Boolean(
      infoExt?.length &&
        isAustralianFieldOrBowles &&
        currentSeason.cropType === 'cotton' &&
        !currentPointId &&
        currentSeason.tissueSampling &&
        currentSeason.tissueSampling.length
    );
  };

  return {
    isNrxAllowed: isNrxAllowed(),
    isNutrilogicAllowed: isNutrilogicAllowed(),
  };
};
