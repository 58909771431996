import React from 'react';
import {FluroButton} from 'components';
import {t} from 'i18n-utils';

type ZoomIsTooLowStepProps = {
  onHide?: () => void;
};
export function ZoomIsTooLowStep({onHide}: ZoomIsTooLowStepProps) {
  return (
    <>
      {onHide && (
        <FluroButton id="back-btn" onClick={onHide} raised blank>
          {t({id: 'BtnLabel.Back'})}
        </FluroButton>
      )}
    </>
  );
}
