import React, {useMemo} from 'react';
import {Popup} from 'react-leaflet';
import {getShapeCoordinates} from '_utils/geometry';
import {togglePopup} from './actions';
import {PanelViewCard, PanelViewSimpleCard} from './features/crop-performance/panel-view-card';
import {classifyNdvi} from './features/crop-performance/biomass/biomass';
import {useAppDispatch, useAppSelector} from '_hooks';
import {selectCropPerformance} from 'containers/map/features/crop-performance/crop-performance-selectors';
import {
  selectFieldGeometries,
  selectFieldsByFarmId,
  selectMapOpenPopUpId,
} from 'containers/map/reducer/selectors';

export const CropPerformancePopup = () => {
  const dispatch = useAppDispatch();
  const cropPerformance = useAppSelector(selectCropPerformance);
  const fieldGeometries = useAppSelector(selectFieldGeometries);
  const fieldsByFarmId = useAppSelector(selectFieldsByFarmId);
  const openPopupId = useAppSelector(selectMapOpenPopUpId);
  const records = cropPerformance.records;
  const fields = useMemo(
    () =>
      Object.keys(fieldsByFarmId)
        .filter(farmId => cropPerformance.farms[Number(farmId)])
        .flatMap(farmId => Object.values(fieldsByFarmId[Number(farmId)])),
    [fieldsByFarmId, cropPerformance.farms]
  );
  if (!openPopupId) {
    return null;
  }
  const record = records.find(r => r.seasonId === openPopupId);
  const field = fields.find(f => f.Seasons?.find(s => s.id === openPopupId));
  const season = field?.Seasons?.find(s => s.id === openPopupId);
  const geometry = (season && season.geometry) || fieldGeometries[String(field?.MD5)];
  if (!geometry) {
    return null;
  }
  const [lng = 0, lat = 0] = getShapeCoordinates(geometry);
  const position = {lat, lng};

  // Use field and simplified card when there is no crop performance record yet.
  if (!record) {
    if (!season) {
      return null;
    }
    return (
      <Popup
        position={position}
        className={'map-popup'}
        autoPanPaddingBottomRight={[420, 20]}
        autoPanPaddingTopLeft={[60, 10]}
        onClose={() => dispatch(togglePopup(undefined))}
      >
        <PanelViewSimpleCard
          record={{
            fieldName: String(field?.Name),
            cropType: String(season?.cropType || String(field?.CropValue)),
            cropVariety: String(field?.CropSubtype),
            startDate: season?.startDate || String(field?.PlantingDate),
            endDate: season?.endDate || String(field?.HarvestDate),
          }}
        />
      </Popup>
    );
  }

  // Use crop performance record and rich card.
  const biomass = classifyNdvi(
    record.smoothSatelliteNdvi,
    cropPerformance.ndviQuartiles[`${record.cropType}_${record.cropVariety}`]?.smoothSatellite
  );
  return (
    <Popup
      position={position}
      className={'map-popup'}
      autoPanPaddingBottomRight={[420, 20]}
      autoPanPaddingTopLeft={[60, 10]}
      onClose={() => dispatch(togglePopup(undefined))}
    >
      <PanelViewCard
        record={record}
        biomass={biomass}
        showFarmName={Object.keys(cropPerformance.farms).length > 1}
      />
    </Popup>
  );
};
