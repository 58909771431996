import type {AxiosPromise} from 'axios';
import axios from 'axios';
import type {IZoning} from '../containers/map/types';
import type {TSensor} from '../types';
import config from '_environment';
import service from '_api/service';
import cancelTokenStore from '_api/cancel-tokens-store';
import type {ProductivityMapMetaData} from '../containers/map/features/zoning/productivity-map/productivity-map.module';
import {PRODUCTIVITY_MAP_ASSETS_HOST} from '../containers/map/features/zoning/productivity-map/productivity-map.module';

export type ZoningInfoHeader = {
  [zonaName: string]: {
    average_index: number;
    area: number;
    percentage: number;
    color: [R, G, B, A];
  };
};

type R = number;
type G = number;
type B = number;
type A = number;
type GetZoningResponse = {colors: [R, G, B, A][]; sampling_points: [number, number][]};
export default class {
  static getZoning = (url: string): AxiosPromise<GetZoningResponse> => {
    cancelTokenStore.cancel('loadZoningData');
    const source = cancelTokenStore.generateSource('loadZoningData');
    return axios({
      method: 'get',
      url,
      cancelToken: source.token,
      params: {__skipPreloader: true},
    });
  };

  static getTreeZoning = (zoning: IZoning, date: string, md5: string, currentIndex: TSensor) => {
    cancelTokenStore.cancel(`loadZoningData-${md5}`);
    const source = cancelTokenStore.generateSource(`loadZoningData-${md5}`);

    const {method, classes, treeZoningPercentage} = zoning;
    const arg = ['BestPerforming', 'WorstPerforming'].includes(method)
      ? treeZoningPercentage * 0.01
      : classes;
    const params = `md5=${md5}&sensing_date=${date}&index=${currentIndex.toLowerCase()}&method=${method}&arg=${arg}`;
    return service.get(`tree-detection/zoning?${params}`, {
      cancelToken: source.token,
      params: {__skipPreloader: true},
    });
  };

  static getBulkTreeZoning = (
    zoning: IZoning,
    fields: {md5: string; sensing_date: string}[],
    currentIndex: TSensor
  ) => {
    cancelTokenStore.cancel(`loadZoningData-bulk`);
    const source = cancelTokenStore.generateSource(`loadZoningData-bulk`);
    const {method, classes, treeZoningPercentage} = zoning;

    const arg = ['BestPerforming', 'WorstPerforming'].includes(method)
      ? treeZoningPercentage * 0.01
      : classes;

    return service.post(
      'tree-detection/zoning',
      {
        fields,
        method,
        arg,
        index: currentIndex.toLowerCase(),
      },
      {
        cancelToken: source.token,
        params: {__skipPreloader: true},
      }
    );
  };

  static loadSuggestedPoints = (url: string, options: any) => {
    cancelTokenStore.cancel('loadSuggestedPoints');
    const source = cancelTokenStore.generateSource('loadSuggestedPoints');

    return axios.get(`${config.baseUrl.slice(0, -1)}${url}`, {
      cancelToken: source.token,
      params: {__skipPreloader: true, ...(options.params ? options.params : {})},
    });
  };

  static productivityMap = async (
    md5: string,
    sensor: string,
    startDate: string,
    endDate: string,
    shapefile_name: string
  ): Promise<ProductivityMapMetaData> => {
    cancelTokenStore.cancel(`productivityMap-${md5}`);
    cancelTokenStore.cancel(`productivityMap-geojson-${md5}`);

    const source = cancelTokenStore.generateSource(`productivityMap-${md5}`);
    const source2 = cancelTokenStore.generateSource(`productivityMap-geojson-${md5}`);

    const response = await service.get(
      `data-service/stack/${sensor}/${md5}/${startDate}/${endDate}`,
      {
        cancelToken: source.token,
        params: {
          __skipPreloader: true,
          export_shapefile: shapefile_name ? 'True' : 'False',
          shapefile_name,
        },
      }
    );

    if (response.status >= 400 && response.status < 500) {
      throw new Error(response.data?.detail || 'Server error');
    }

    if (response?.data?.geojson) {
      const geoJsonResponse = await axios.get(
        PRODUCTIVITY_MAP_ASSETS_HOST + response?.data?.geojson,
        {
          cancelToken: source2.token,
          headers: {
            'Access-Control-Allow-Origin': window.location.protocol + '//' + window.location.host,
          },
          params: {
            __skipPreloader: true,
          },
        }
      );

      if (geoJsonResponse.status >= 400 && geoJsonResponse.status < 500) {
        throw new Error('Server error');
      }

      response.data.geoJsonData = geoJsonResponse.data;
    }

    return response.data;
  };
}
