import type {PayloadAction} from '@reduxjs/toolkit';
import {createSlice} from '@reduxjs/toolkit';

export const intercomReducer = createSlice({
  name: 'monitoring/filters',
  initialState: {visibility: false, unreadMessages: 0},
  reducers: {
    toggleMessagesVisibility: (state, action: PayloadAction<boolean>) => {
      state.visibility = action.payload;
    },
    setUnreadMessagesCount: (state, action: PayloadAction<number>) => {
      state.unreadMessages = action.payload;
    },
  },
});

const {toggleMessagesVisibility, setUnreadMessagesCount} = intercomReducer.actions;

export {toggleMessagesVisibility, setUnreadMessagesCount};
