import {useIntercom} from 'react-use-intercom';
import type {FC, ReactElement} from 'react';
import React from 'react';
import {Link} from './styled-components';

type ArticleLinkProps = {
  articleId: number;
  content: string | ReactElement | JSX.Element;
  className?: string;
};

export const ArticleLink: FC<ArticleLinkProps> = ({articleId, content, className}) => {
  const {showArticle} = useIntercom();
  const handleShowArticle = () => {
    showArticle(articleId);
  };
  return (
    // TODO: fixme a11y
    // eslint-disable-next-line jsx-a11y/anchor-is-valid
    <Link className={className} onClick={handleShowArticle}>
      {content}
    </Link>
  );
};
