import type {ValueFormatter} from '@nivo/bar';
import {Text} from 'components';
import {CardHr} from 'components/card/card-styled';
import {FluroRheostat} from 'components/fluro-rheostat';
import {debounce} from 'lodash';
import {
  setFilterAreaUnits,
  setHighlightGeometry,
  setYieldFilter,
} from 'modules/sustainability-insights/actions';
import {
  AGG_LEVEL_TO_LABEL,
  SUMMARY_BARS_PREVIEW_THRESHOLD,
} from 'modules/sustainability-insights/constants';
import {
  selectActiveGeometriesIds,
  selectAggLevel,
  selectAreaUnits,
  selectCurrentMeta,
} from 'modules/sustainability-insights/selectors';
import {
  selectYieldChromaScale,
  selectYieldCardRange,
  selectYieldStatsExtrema,
  selectYieldSummaryChartData,
  selectYieldGroupedStatsExtrema,
} from 'modules/sustainability-insights/selectors/yield';
import type {
  GroupedSummaryChartData,
  SetYieldFilterAction,
} from 'modules/sustainability-insights/types';
import {calculateScale, getSummaryChartLabel} from 'modules/sustainability-insights/utils';
import type {ComponentType} from 'react';
import React, {useCallback, useEffect, useRef} from 'react';
import {useAppDispatch, useAppSelector} from '_hooks';
import {kiloFormatter} from '_utils/number-formatters';
import {NoSelectedAreaMessage, NoDataMessage} from '../messages';
import {SummaryChart, SummaryChartGrouped} from '../summary-chart';
import {formatValueKgPerHa} from '../utils';

const CHART_KEYS = ['value'];

export const YieldSummaryChart: ComponentType<{}> = () => {
  const dispatch = useAppDispatch();

  const units = useAppSelector(selectAreaUnits);
  const data = useAppSelector(selectYieldSummaryChartData);
  const colorScale = useAppSelector(selectYieldChromaScale);
  const {min, max} = useAppSelector(selectYieldStatsExtrema);
  const {max: groupedMax} = useAppSelector(selectYieldGroupedStatsExtrema);
  const meta = useAppSelector(selectCurrentMeta);
  const aggLevel = useAppSelector(selectAggLevel);
  const range = useAppSelector(selectYieldCardRange);
  const selectedIds = useAppSelector(selectActiveGeometriesIds);
  const rangeMax = Math.ceil(max);
  const rangeMin = Math.floor(min);

  const setCardFilter = useCallback(
    (p: SetYieldFilterAction['payload']) => {
      dispatch(setYieldFilter(p));
    },
    [dispatch]
  );

  const setCardFilterDebounceRef = useRef(debounce(setCardFilter, 100));

  useEffect(() => {
    // setup initial range
    const newRange: [number, number] = [rangeMin, rangeMax];
    if (range || !newRange.every(Number.isFinite)) return;
    setCardFilter({range: newRange});
  }, [setCardFilter, rangeMin, rangeMax, range]);

  useEffect(() => {
    if (units === 'pct') {
      dispatch(setFilterAreaUnits('num')); // set num by default
    }
  }, []);

  const getColor = useCallback(
    (value: number) => {
      const scale = calculateScale(value, min, max);
      const color = colorScale(scale).css();
      return color;
    },
    [colorScale, max, min]
  );

  const nameLabel: string | ValueFormatter = (id: number) => {
    return getSummaryChartLabel(meta?.[id]?.name, aggLevel === 'state');
  };

  const setRange = (range: [number, number]) => {
    setCardFilterDebounceRef.current({range});
  };

  const handleBarMouseEnter = ({data: {id}}: any) => {
    dispatch(setHighlightGeometry(id));
  };

  const handleBarMouseLeave = () => {
    dispatch(setHighlightGeometry(null));
  };

  const chartValuesFormatter = (value: number) => {
    return formatValueKgPerHa(value, units);
  };

  if (!selectedIds) return <NoSelectedAreaMessage />;
  if (!data) return <NoDataMessage />;

  const {type, chartData, chartGeometriesIds, values, preview, totalValue} = data;
  const geometryTypeLabel = AGG_LEVEL_TO_LABEL[aggLevel] || aggLevel;

  // These are border values to make grouped chart's bars positions connected between each other
  // minValue must be zero or negative, otherwise horizontal bar's left position will break
  const maxValue = type === 'grouped' ? Math.ceil(groupedMax) : undefined;
  const minValue = type === 'grouped' ? Math.min(Math.floor(min), 0) : undefined;

  return (
    <>
      {!!(preview && range) && (
        <>
          <div className="chart-range chart-range__container mb-2">
            <FluroRheostat
              colorScale={colorScale}
              name={`yield-summary-${units}`}
              inputChar={units === 'pct' ? '%' : 'kg/ha'}
              min={rangeMin}
              max={rangeMax}
              data={values}
              onChange={setRange}
            />
          </div>
          <CardHr />
        </>
      )}

      {!!chartGeometriesIds?.length && (
        <>
          <Text secondary elementType={'p'} variant={'small'}>
            {chartGeometriesIds.length} {geometryTypeLabel}, {kiloFormatter(totalValue)} t match
            current filters
          </Text>
          <CardHr />
        </>
      )}

      <div className="mb-1">
        {type === 'normal' ? (
          <SummaryChart
            keys={CHART_KEYS}
            data={chartData}
            getColor={getColor}
            nameLabel={nameLabel}
            onMouseEnter={handleBarMouseEnter}
            onMouseLeave={handleBarMouseLeave}
            maxVisibleItems={SUMMARY_BARS_PREVIEW_THRESHOLD}
            format={chartValuesFormatter}
            maxValue={maxValue}
            minValue={minValue}
            allowShowMore={aggLevel !== 'huc10' && aggLevel !== 'huc12'}
          />
        ) : (
          <SummaryChartGrouped
            keys={CHART_KEYS}
            data={chartData as GroupedSummaryChartData[]}
            getColor={getColor}
            nameLabel={nameLabel}
            onMouseEnter={handleBarMouseEnter}
            onMouseLeave={handleBarMouseLeave}
            maxVisibleItems={SUMMARY_BARS_PREVIEW_THRESHOLD}
            format={chartValuesFormatter}
            maxValue={maxValue}
            minValue={minValue}
            allowShowMore={aggLevel !== 'huc10' && aggLevel !== 'huc12'}
          />
        )}
      </div>
    </>
  );
};
