// @ts-nocheck
import React from 'react';
import {
  ColoredBullet,
  ItemCount,
  ItemName,
  ItemAreaLight,
  ItemDataInfo,
  LegendListItem,
  LegendListStyled,
  SelectedCount,
} from 'components/legend-list/legend-list';
import cn from 'classnames';
import {FormattedMessage} from 'react-intl';

import type {CardProps} from '../crop-performance-card';
import {cropGrowthStageLabelsToValues} from '../types';
import {classifyAreaWithUnits} from '_utils';
import {t} from 'i18n-utils';
import {useAppSelector} from '../../../../../_hooks';
import {selectMeasurement} from '../../../../login/login-selectors';

type Props = {
  selectedCount?: string;
  className?: string;
  data: CardProps['data'];
  onClick?(val: string, e: React.MouseEvent): void;
  selectedLabels: string[];
};

export const GrowthStagesLegendList = ({data, onClick, selectedCount, selectedLabels}: Props) => {
  const measurement = useAppSelector(selectMeasurement);
  return (
    <>
      {selectedCount && <SelectedCount>{selectedCount}</SelectedCount>}

      <LegendListStyled>
        <LegendListItem
          className={cn({
            'legend-list-item': true,
            'four-columns': true,
            'heading-row': true,
          })}
        >
          <div>Growth stage</div>
          <div>BBCH</div>
        </LegendListItem>

        {data.labels.map((label, index) => {
          const value = data.values[index];
          const area = data.areas[index];
          const backgroundColor = data.colors[index];
          const growthStageCode = cropGrowthStageLabelsToValues[label];
          const deselected = selectedLabels.length && !selectedLabels.includes(label);

          return (
            <LegendListItem
              key={label}
              onClick={e => onClick?.(label, e)}
              className={cn({
                'legend-list-item': true,
                'four-columns': true,
                deselected,
              })}
            >
              <div>
                <ColoredBullet
                  className={'apply-deselected-opacity'}
                  style={{
                    backgroundColor,
                    border: backgroundColor === '#ffffff' ? '1px solid black' : undefined,
                  }}
                />
                <ItemName>
                  <FormattedMessage id={label} defaultMessage={label} />
                </ItemName>
              </div>
              <ItemDataInfo className={'text-left'}>{growthStageCode}</ItemDataInfo>
              <ItemCount>{t({id: '{count} crops'}, {count: value})}</ItemCount>
              <ItemAreaLight>({classifyAreaWithUnits(area, measurement)})</ItemAreaLight>
            </LegendListItem>
          );
        })}
      </LegendListStyled>
    </>
  );
};
