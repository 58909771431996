import {ActionTypes} from '../reducer/types';
import {t} from 'i18n-utils';
import type {SamplingPoint, AnalyticAddedPoint, AnalyticsPoint} from '../types';
import {getLayerIndexByString, SMOOTHED_LINE_COLOR_SATELLITE} from '../features/analytics/helpers';
import {changeAreaOfInterestProp} from './areas-of-interest-actions';
import {changeLowPerfAnomalyProp, updatePremiumAnomaly} from './anomalies-actions';
import {reportError} from '../../error-boundary';
import {ActivityApi} from '_api';
import Mixpanel from '_utils/mixpanel-utils';
import {showNotification} from 'components/notification/notification';
import type {AppStore} from 'reducers';
import {GLOBAL_FORMAT_DATE, SERVER_FORMAT_DATE} from '_constants';
import moment from 'moment';
import axios from 'axios';
import {AsyncStatusType, setRequestStatus, Status} from '../../../modules/helpers';
import {
  selectAllVisibleGeometries,
  selectPremiumAnomalies,
} from '../features/anomalies/anomalies-selectors';
import {
  selectCurrentField,
  selectCurrentPointsGroup,
  selectCurrentSeason,
  selectMapOpenPopUpId,
} from '../reducer/selectors';
import {
  selectAnalyticRequestSourceType,
  selectAnalyticsItemColor,
  selectMapAnalytics,
  selectMapAnalyticsTrendsData,
} from 'containers/map/features/analytics/analytics-selectors';
import type {AppDispatch} from 'store';
import {sortDates} from '_utils/pure-utils';
import {getTypedKeys} from '_utils/object';
import {toFixedFloatUnsafe} from '_utils/number-formatters';

export enum AnalyticRequestSourceType {
  None = 'none',
  Satellite = 'sentinel2',
  Planet = 'planet',
  Both = 'both',
}

type SmoothedVISensorResponse = {
  [AnalyticRequestSourceType.Satellite]?: Record<number, {vi_smooth: number; day: number}>;
  [AnalyticRequestSourceType.Planet]?: Record<number, {vi_smooth: number; day: number}>;
};

export type SmoothedVIResponse = {
  ccci: SmoothedVISensorResponse;
  msavi: SmoothedVISensorResponse;
  ndre: SmoothedVISensorResponse;
  ndvi: SmoothedVISensorResponse;
};

export const addAnalyticPoint =
  (point: AnalyticAddedPoint) => (dispatch: AppDispatch, getState: () => AppStore) => {
    const color = selectAnalyticsItemColor(getState());
    dispatch({
      type: ActionTypes.MAP_ADD_ANALYTIC_POINT,
      point: {...point, color},
    });
  };

export const removeAnalyticPoint = (color: string) => ({
  type: ActionTypes.MAP_REMOVE_ANALYTIC_POINT,
  color,
});

export const updateAnalyticPointPosition = (positions: any) => (dispatch: AppDispatch) => {
  dispatch({
    type: ActionTypes.MAP_UPDATE_ANALYTIC_POINT,
    positions,
  });
  dispatch(getAnalyticsData());
};

export const getSmoothedAnalyticsData =
  () => async (dispatch: AppDispatch, getState: () => AppStore) => {
    const state = getState();
    const field = selectCurrentField(state);
    const currentSeason = selectCurrentSeason(state);
    const analytics = selectMapAnalytics(state);
    const analyticsRequestSourceFilter = selectAnalyticRequestSourceType(state);

    if (analytics.fieldSmoothedData.length) return; // already fetched
    if (analyticsRequestSourceFilter === AnalyticRequestSourceType.None) return; // Both Planet and Satellite images are hidden

    const categories: string[] = [];
    const result: AnalyticsPoint[] = [];

    await ActivityApi.getSmoothedData({
      data_source: analyticsRequestSourceFilter,
      seasons: [
        {
          start_date: currentSeason?.startDate || '',
          end_date: currentSeason?.endDate || '',
          key: currentSeason?.kmlId || '',
          md5: field.MD5,
          crop_type: currentSeason?.cropType || '',
        },
      ],
    }).then(({data}) => {
      getTypedKeys(data).forEach(sensor => {
        const planeSensorData = data[sensor][AnalyticRequestSourceType.Planet];
        const satelliteSensorData = data[sensor][AnalyticRequestSourceType.Satellite];
        const planeSensorRecord: Record<string, number> = {};
        const satelliteSensorRecord: Record<string, number> = {};

        if (planeSensorData) {
          getTypedKeys(planeSensorData).forEach(date => {
            const formattedDate = moment(date, SERVER_FORMAT_DATE).format(GLOBAL_FORMAT_DATE);
            !categories.includes(formattedDate) && categories.push(formattedDate);
            planeSensorRecord[formattedDate] = toFixedFloatUnsafe(
              planeSensorData[date].vi_smooth,
              3
            );
          });

          result.push({
            values: planeSensorRecord,
            color: SMOOTHED_LINE_COLOR_SATELLITE,
            index: getLayerIndexByString(sensor),
            type: 'field',
            sourceType: AnalyticRequestSourceType.Planet,
          });
        }
        if (satelliteSensorData) {
          getTypedKeys(satelliteSensorData).forEach(date => {
            const formattedDate = moment(date, SERVER_FORMAT_DATE).format(GLOBAL_FORMAT_DATE);
            !categories.includes(formattedDate) && categories.push(formattedDate);
            satelliteSensorRecord[formattedDate] = toFixedFloatUnsafe(
              satelliteSensorData[date].vi_smooth,
              3
            );
          });
          result.push({
            values: satelliteSensorRecord,
            color: SMOOTHED_LINE_COLOR_SATELLITE,
            index: getLayerIndexByString(sensor),
            type: 'field',
            sourceType: AnalyticRequestSourceType.Satellite,
          });
        }
      });
    });

    dispatch({
      type: ActionTypes.MAP_SET_FIELD_SMOOTHED_VALUES,
      allDates: sortDates(categories, GLOBAL_FORMAT_DATE),
      fieldData: result,
    });

    return result;
  };

export const getTrendsData =
  (sourceType: AnalyticRequestSourceType) =>
  async (dispatch: AppDispatch, getState: () => AppStore) => {
    const state = getState();
    const currentSeason = selectCurrentSeason(state);
    const field = selectCurrentField(state);

    const pointsToGet = getPreparedPointsToFetch(dispatch, getState, sourceType);
    const geometryToGet = getPreparedGeometryToFetch(dispatch, getState, sourceType);
    const pointsGroups = selectCurrentPointsGroup(state);

    if (!pointsToGet.length && !geometryToGet.length) return;

    const indexesToRequest =
      sourceType === AnalyticRequestSourceType.Planet
        ? ['msavi', 'ndvi'] // Planet doesn't have data to calculate ccci & ndre data
        : ['ccci', 'msavi', 'ndvi', 'ndre'];

    const arrayRequests = indexesToRequest.map(sensorIndex => {
      return ActivityApi.getAnalyticsChartsData(
        {
          points: pointsToGet,
          polygons: geometryToGet,
        },
        sensorIndex,
        field.MD5,
        moment(currentSeason?.startDate).format(GLOBAL_FORMAT_DATE),
        moment(currentSeason?.endDate).format(GLOBAL_FORMAT_DATE),
        sourceType
      )
        .then(({data}) => {
          const {points = {}, polygons = {}, dates} = data;

          const classifiedDates = dates.map(date =>
            moment(date, SERVER_FORMAT_DATE).format(GLOBAL_FORMAT_DATE)
          );

          const classifyData = (
            data: Record<string, number[]>,
            type: AnalyticsPoint['type']
          ): AnalyticsPoint[] => {
            return Object.keys(data).map(color => {
              const pointValues = data[color];
              const values: AnalyticsPoint['values'] = {};
              const pointType = pointsGroups?.find(point => point?.properties?.color === color)
                ? 'tsp'
                : type;

              classifiedDates.forEach((date, index) => (values[date] = pointValues[index]));
              return {
                index: getLayerIndexByString(sensorIndex),
                type: pointType,
                sourceType,
                color,
                values,
              };
            });
          };

          return {
            data: [...classifyData(points, 'point'), ...classifyData(polygons, 'polygon')],
            dates: classifiedDates,
          };
        })
        .catch(err => {
          if (!axios.isCancel(err)) {
            Mixpanel.errorMessage('An error occurred, try to reload the page.', err.response);
            showNotification({
              title: t({id: 'note.warning', defaultMessage: 'Warning'}),
              message: t({id: 'errorTryReloadPage'}),
              type: 'warning',
            });
            reportError(err);
          }

          return {data: [], dates: []};
        });
    });

    dispatch(setRequestStatus(AsyncStatusType.analyticsGeometriesAndPoints, Status.Pending));

    Promise.all(arrayRequests)
      .then(response => {
        const isCanceled = response.every(response => response?.data?.length === 0);

        if (isCanceled) return;

        const typedResponse = response as {data: AnalyticsPoint[]; dates: string[]}[];
        dispatch(setRequestStatus(AsyncStatusType.analyticsGeometriesAndPoints, Status.Done));

        const allPoints: AnalyticsPoint[] = [];
        const dates: string[] = [];

        if (response) {
          typedResponse.forEach(({data}) => allPoints.push(...data));
          dates.push(...typedResponse[0].dates);

          dispatch({
            type: ActionTypes.MAP_ADD_ANALYTIC_TRENDS_DATA,
            allDates: dates,
            values: allPoints,
          });
        }
      })

      .catch(() =>
        dispatch(setRequestStatus(AsyncStatusType.analyticsGeometriesAndPoints, Status.Todo))
      );
  };

export const getAnalyticsData = () => async (dispatch: AppDispatch, getState: () => AppStore) => {
  const state = getState();
  const analyticsRequestSourceFilter = selectAnalyticRequestSourceType(state);

  if (analyticsRequestSourceFilter === AnalyticRequestSourceType.None) return; // Both Planet and Satellite images are hidden

  dispatch(getSmoothedAnalyticsData());
  if (analyticsRequestSourceFilter === AnalyticRequestSourceType.Both) {
    dispatch(getTrendsData(AnalyticRequestSourceType.Satellite));
    dispatch(getTrendsData(AnalyticRequestSourceType.Planet));
  } else {
    dispatch(getTrendsData(analyticsRequestSourceFilter));
  }
};

const getPreparedPointsToFetch = (
  dispatch: AppDispatch,
  getState: () => AppStore,
  sourceType: AnalyticRequestSourceType
): {lat: number; lon: number; id: string}[] => {
  const state = getState();
  const analytics = selectMapAnalytics(state);
  const pointsGroups = selectCurrentPointsGroup(state);
  const trendData = selectMapAnalyticsTrendsData(state);

  const tspPoints = pointsGroups
    ? pointsGroups
        .filter(
          point =>
            !trendData.find(
              analyticPoint =>
                analyticPoint.color === point?.properties?.color &&
                analyticPoint.sourceType === sourceType // filter already fetched points with data
            )
        )
        .map((point: SamplingPoint) => {
          return {
            lat: point.geometry.coordinates[0],
            lon: point.geometry.coordinates[1],
            id: String(point?.properties?.color),
          };
        })
    : [];

  const analyticPoints = analytics.addedPoints
    .filter(
      point =>
        !trendData.find(
          analyticPoint =>
            analyticPoint.color === point.color && analyticPoint.sourceType === sourceType
        ) // filter already fetched points with data
    )
    .map(point => {
      return {
        lat: point.latlng.lat,
        lon: point.latlng.lng,
        id: point.color,
      };
    });

  return [...tspPoints, ...analyticPoints];
};

const getPreparedGeometryToFetch = (
  dispatch: AppDispatch,
  getState: () => AppStore,
  sourceType: AnalyticRequestSourceType
): {id: string; coords: number[]}[] => {
  const state = getState();
  const allVisibleGeometries = selectAllVisibleGeometries(state);
  const premiumAnomalies = selectPremiumAnomalies(state);
  const openPopupId = selectMapOpenPopUpId(state);
  const trendData = selectMapAnalyticsTrendsData(state);

  allVisibleGeometries.forEach(g => {
    const popup =
      openPopupId && (openPopupId === g.properties.id || openPopupId === g.properties.anomaly_id);

    if ((g.properties.checked || popup) && !g.properties.color) {
      !g.properties.isLowPerf
        ? dispatch(changeAreaOfInterestProp(g, 'color', selectAnalyticsItemColor(getState())))
        : dispatch(changeLowPerfAnomalyProp(g, 'color', selectAnalyticsItemColor(getState())));
    }
  });

  const geometryToGet = allVisibleGeometries
    .filter(
      g =>
        g.properties.color &&
        !trendData.find(
          point => point.color === g.properties.color && point.sourceType === sourceType // filter already fetched geometries
        )
    )
    .map(g => {
      let coordinates = [];
      try {
        coordinates =
          g.geometry.type === 'GeometryCollection'
            ? g.geometry.geometries.map((g: GeoJSON.Polygon) => g.coordinates[0]).flat()
            : g.geometry.coordinates[0];
      } catch (err) {
        reportError(
          `error in the getTrendsData() in preparedGeometries.map, URL = ${window.location.href}; geometryID = ${g.properties.id}`
        );
      }

      return {
        id: g.properties.color || '',
        coords: coordinates,
      };
    });
  premiumAnomalies.list.forEach(g => {
    const popup = openPopupId && openPopupId === g.properties.anomaly_id;
    if (g.properties.color || (!g.properties.checked && !popup)) {
      return;
    }
    dispatch(
      updatePremiumAnomaly({
        id: g.properties.anomaly_id,
        prop: 'color',
        value: selectAnalyticsItemColor(getState()),
        g,
      })
    );
  });

  const filteredAnomalies = premiumAnomalies.list.filter(
    a =>
      a.properties.color &&
      !trendData.find(
        point => point.color === a.properties.color && point.sourceType === sourceType // filter already fetched geometries
      )
  );

  const anomalyToGet = filteredAnomalies.map(a => ({
    id: a.properties.color || '',
    coords: a.geometry.coordinates[0],
  }));

  return [...geometryToGet, ...anomalyToGet];
};

export const toggleAnalyticSmoothedData = (value: boolean) => ({
  type: ActionTypes.MAP_TOGGLE_SMOOTHED_DATA,
  value,
});

export const toggleAnalyticPointVisibility = (color: string) => ({
  type: ActionTypes.MAP_ANALYTIC_TOGGLE_POINT_VISIBILITY,
  color,
});
