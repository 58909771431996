// @ts-nocheck
import {t} from 'i18n-utils';
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {genKey} from '_utils/pure-utils';
import {useDispatch, useSelector} from 'react-redux';
import type {AppStore} from 'reducers';
import {dialogToggle, DialogType} from 'modules/helpers';
import {DialogFooter} from 'containers/admin/admin.styled';
import {Button, TextField} from 'react-md';
import {CommonDialogContentWrapper} from 'components/common-dialogs/dialogs.styled';
import Dialog from 'components/fluro-dialog';
import {InfoBlock} from 'components';
import ImageDateSelector from './image-date-selector';
import {MultipleCloudsFilledIcon} from 'containers/map/icons';
import {AdminApi} from '_api';
import {updateImageCloudy} from 'containers/map/actions';
import Yup from 'yup';
import cn from 'classnames';
import {Controls} from './edit-cloudy.styled';
import {useAppSelector} from '../../../../../_hooks';
import {selectDialogData} from '../../../../../modules/helpers/selectors';

const cloudSchema = Yup.number().min(0).max(100);

const EditCloudyDialog = () => {
  const id = useMemo(() => genKey(), []);
  const dispatch = useDispatch();
  const {visible, payload} = useAppSelector(s => selectDialogData(s, DialogType.editCloudy));

  const md5 = useSelector((state: AppStore) => state.map.field.MD5);
  const userId = useSelector((state: AppStore) => state.login.user.id);

  const [cloud, setCloud] = useState(payload.Cloud);
  const [cloudValueError, setCloudValueError] = useState(false);

  useEffect(() => {
    setCloud(payload.Cloud);
  }, [payload]);

  const onCancel = useCallback(() => dispatch(dialogToggle(DialogType.editCloudy, false)), []);

  const onSave = useCallback(async () => {
    try {
      cloudSchema.validateSync(cloud);

      const cloud_value = Math.round(parseFloat(cloud));

      await AdminApi.updateCloudyPercent({
        md5,
        cloud_value,
        sensing: payload.Date,
        updated_by: userId,
      });

      dispatch(updateImageCloudy(payload.Date, cloud_value));

      onCancel();
    } catch (e) {
      setCloudValueError(true);
    }
  }, [cloud, md5, payload, userId]);

  const message = useMemo(
    () =>
      payload.cloudValueModified ? (
        <span>
          {t({
            id: 'The cloud cover % was adjusted manually. You can overwrite this value by entering a new cloud %.',
          })}
        </span>
      ) : (
        <span>
          {t({
            id: 'This will overwrite the cloud % value generated automatically by the cloud detection algorithm. This action cannot be undone.',
          })}
        </span>
      ),
    [payload.cloudValueModified]
  );

  return (
    <Dialog
      id={`edit-cloudy--dialog-${id}`}
      visible={visible}
      title={t({id: 'Edit cloud cover %'})}
      focusOnMount={false}
      isDraggable
      onHide={onCancel}
      width={400}
      portal
      actions={
        <DialogFooter fullWidth>
          <Button raised onClick={onCancel}>
            {t({id: 'Cancel'})}
          </Button>
          <Button primary raised onClick={onSave}>
            {t({id: 'Save'})}
          </Button>
        </DialogFooter>
      }
    >
      <CommonDialogContentWrapper>
        <InfoBlock appearance="info" className="inside-a-pop-up">
          {message}
        </InfoBlock>

        <Controls>
          <ImageDateSelector />

          <TextField
            id={`input-cloudy-percent-${id}`}
            label={`New cloud % (current cloud %: ${payload.Cloud})`}
            className={cn({modified: payload.cloudValueModified})}
            type="number"
            value={cloud}
            onChange={value => {
              setCloud(value);
              setCloudValueError(false);
            }}
            min={0}
            max={100}
            error={cloudValueError}
            errorText={t({id: 'Cloud value mast be in range 0-100'})}
            leftIcon={<MultipleCloudsFilledIcon />}
          />
        </Controls>
      </CommonDialogContentWrapper>
    </Dialog>
  );
};

export default EditCloudyDialog;
