import React, {useCallback} from 'react';
import {AreaSelectMenu} from 'containers/map/features/sustainability-insights/area-select/area-select-menu';
import type {Props as OptionsGroupsProps} from 'components/grouped-selection-control/options-group';
import {getNewIdsFromSelectedChange} from 'containers/si/kpi/filter/helpers';

type RegionsSelectProps = {
  selectedIds: Array<number>;
  availableSubsectionIds: Array<number>;
  options: Array<{
    label: string;
    value: string;
    options?: {label: string; value: number}[];
  }>;
  onChange: (subsectionIds: Array<number>) => void;
};

export const RegionsSelect = ({
  selectedIds,
  availableSubsectionIds,
  options,
  onChange,
}: RegionsSelectProps) => {
  const allAreSelected = React.useMemo(
    () => selectedIds.length !== 0 && selectedIds.length === availableSubsectionIds.length,
    [availableSubsectionIds.length, selectedIds.length]
  );

  const handleChange = useCallback(
    (newIds: Array<number>) => {
      onChange(newIds);
    },
    [onChange]
  );

  const handleClear = useCallback(() => {
    handleChange([]);
  }, [handleChange]);

  const handleSelect: OptionsGroupsProps['onChange'] = selectedChange => {
    const newIds = getNewIdsFromSelectedChange({
      selectedIds,
      allAvailableIds: availableSubsectionIds,
    })(selectedChange);
    handleChange(newIds);
  };

  return (
    <AreaSelectMenu
      className="area-select-menu"
      allowSelectAll
      allowSearch
      allLabel={`Select all regions`}
      selectedLabel={'sub-regions'}
      options={options}
      selected={selectedIds.map(String)}
      disabled={false}
      allSelected={allAreSelected}
      onSelect={handleSelect}
      onClear={handleClear}
    />
  );
};
