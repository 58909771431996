import React, {memo, useCallback} from 'react';
import {Route, useHistory, useLocation} from 'react-router-dom';
import {FluroTabs, FluroButton} from 'components';
import CropTypesMatching from './crop-types-matching';
import {EditCrop} from 'containers/admin/edit-crop/edit-crop';
import {AdminContainer, AdminContentWrapper, AdminControls, AdminTitle} from '../admin.styled';
import AdminTitleWithLoader from '../shared/title-with-loader';
import {AsyncStatusType, dialogToggle, DialogType} from 'modules/helpers';
import {useDispatch} from 'react-redux';
import {useWorkspace} from '_hooks/use-workspace';

const Container = memo(() => {
  const dispatch = useDispatch();
  const {pathname} = useLocation();
  const history = useHistory();
  const onChangeTab = useCallback((tabUrl: string) => history.push(tabUrl), [history]);

  const onEditCrop = useCallback(() => {
    dispatch(dialogToggle(DialogType.editCrop, true));
  }, []);

  const {workspaceLink} = useWorkspace();

  return (
    <AdminContainer>
      <AdminControls>
        {pathname.endsWith('edit-crop') ? (
          <AdminTitle className={'d-flex align-center justify-between'}>
            Crop types manager
            <FluroButton raised onClick={onEditCrop}>
              New crop type
            </FluroButton>
          </AdminTitle>
        ) : (
          <AdminTitleWithLoader
            title="Crop matching"
            statusKey={AsyncStatusType.cropTypesMatching}
          />
        )}

        <FluroTabs
          onTabClick={onChangeTab}
          selectedTab={pathname}
          tabs={[
            {
              value: `${workspaceLink}/global-admin/crop/edit-crop`,
              label: 'Crop types translations',
            },
            {
              value: `${workspaceLink}/global-admin/crop/crop-types-manager`,
              label: 'Match crop types from FMS',
            },
          ]}
        />
      </AdminControls>
      <AdminContentWrapper>
        <Route exact path="*/global-admin/crop/edit-crop" component={EditCrop} />
        <Route exact path="*/global-admin/crop/crop-types-manager" component={CropTypesMatching} />
      </AdminContentWrapper>
    </AdminContainer>
  );
});

export default Container;
