import type {ComponentType} from 'react';
import ReactDOM from 'react-dom';

type Props = {
  id: string;
};

export const Portal: ComponentType<Props> = ({id, children}) => {
  const portalRoot = document.getElementById(id);
  if (!portalRoot) return null;
  return ReactDOM.createPortal(children, portalRoot);
};
