import styled from 'styled-components';
import {Button} from 'react-md';
import {main_gray_600, main_info_color} from '_utils/colors';

export const PartFieldSeasonWrapper = styled.div`
  min-height: 235px;
  position: relative;

  .continue-btn-wrapper {
    margin-top: 25px;
    display: flex;
    justify-content: space-between;
  }
`;

export const SelectAddShapeStrategyWrapper = styled.div`
  margin-top: 30px;
  text-align: center;

  > button {
    width: 200px;
  }

  > span {
    color: ${main_info_color};
    display: block;
    margin-top: 15px;
    margin-bottom: 15px;
  }
`;

export const FooterButtonGroup = styled.div`
  width: 100%;
  display: flex;
  margin-top: 24px;
  justify-content: flex-start;
  align-items: center;
`;

export const DrawToolButton = styled.div`
  width: 100%;
  margin: 20px 0;
  border: 1px solid #d6d8da;
  border-radius: 4px;
  padding: 10px 24px;
  transition: all 0.2s;
  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: flex-start;
  text-transform: uppercase;

  :hover {
    border: 1px solid ${main_gray_600};
  }

  > i {
    display: block;
    width: 96px;
    height: 90px;
    background-color: rgba(0, 0, 0, 0.1);
    background-size: cover;
    background-position: center center;
    position: relative;
    margin-right: 15px;
  }
`;

export const DrawPolygonButton = styled(DrawToolButton)`
  > i {
    background-image: url('/assets/polygon_tool.png');
  }
`;

export const SplitFieldButton = styled(DrawToolButton)`
  > i {
    background-image: url('/assets/split_tool.png');
  }
`;

export const ClearButton = styled(Button)``;

export const GeometryItem = styled.div`
  margin-bottom: 15px;

  .first-block {
    input {
      width: 100%;
      border: none;
      background: #f1f1f1;
      margin: 0;
      padding: 4px 15px;
      outline: none;
    }
  }

  .name-error {
    margin-top: 4px;
    margin-left: 15px;
  }

  .second-block {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
  }

  .preview {
    margin-left: 15px;
    box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 1px 5px 0 rgb(0 0 0 / 12%),
      0 3px 1px -2px rgb(0 0 0 / 20%);
    padding: 4px;
    cursor: pointer;

    .leaflet-container {
      background: #fff;
    }
  }

  .season-settings {
    width: 480px;
  }

  .crop-select {
    margin-right: 15px;

    .md-avatar {
      overflow: visible;
    }
  }

  .crop-select,
  .sub-type {
    width: 220px;
  }

  .date-select {
    margin-right: 15px;
    width: 220px;
  }

  .delete-btn-wrapper {
    cursor: pointer;
    margin-right: 15px;

    > svg {
      height: 20px !important;
    }
  }
`;

type PartFieldSeasonWrapperProps = {
  visible: boolean;
};

export const PartFieldSeasonDisplay = styled.div`
  display: ${({visible}: PartFieldSeasonWrapperProps) => (visible ? 'block' : 'none')};
`;

export const SeasonsListTitle = styled.div`
  color: #323c47;
  font-size: 16px;
  margin-top: 15px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;

  .md-icon {
    color: #323c47;
    cursor: pointer;
  }
`;

export const SeasonName = styled.div`
  max-width: 130px;
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  &.selected {
    color: #43a047;
  }
`;

export const TabsDialogWrapper = styled.div`
  margin-left: -24px;
  margin-right: -24px;

  .fluro-tabs-container .tab {
    padding: 0 22px 7px;
  }
`;

type SeasonListItemWrapperProps = {
  selected: boolean;
};

export const SeasonListItemWrapper = styled.div`
  width: 100%;
  margin-top: 5px;
  padding: 4px 2px 5px 16px;
  border-radius: 4px;
  transition: all 0.2s;
  cursor: pointer;

  .external-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
  }

  .edit-icon-wrapper {
    display: flex;
  }

  .top-block {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &__right {
      display: flex;
      align-items: center;
      justify-content: space-between;

      > span,
      > button {
        color: rgba(0, 0, 0, 0.54);
        font-size: 13px;
        font-weight: 100;
      }
    }

    span,
    svg {
      ${({selected}: SeasonListItemWrapperProps) => (selected ? '' : 'filter: grayscale(100%);')};
    }
  }

  .bottom-block {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    .md-avatar-img,
    svg {
      ${({selected}: SeasonListItemWrapperProps) => (selected ? '' : 'filter: grayscale(100%);')};
    }
  }
`;
