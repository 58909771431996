import React, {useMemo, useState} from 'react';
import {InputWrapper} from './carbon-input.styled';
import cn from 'classnames';
import {genKey} from '_utils/pure-utils';

type Value = string | number;

type Props = {
  value?: Value;
  onChange?: (value: Value) => void;
  type?: 'text' | 'number' | 'email' | 'password';
  placeholder?: string;
  error?: boolean;
  errorText?: string;
  name: string;
  style?: React.CSSProperties;
  testId?: string;
};

export const CarbonInput = ({
  error = false,
  placeholder,
  type = 'text',
  onChange,
  errorText,
  name,
  style,
  testId,
}: Props) => {
  const id = useMemo(() => genKey(), []);
  const [_value, setValue] = useState<Value>('');

  // useEffect(() => {
  //   setValue(value);
  // }, [value]);

  return (
    <InputWrapper
      error={error}
      className={cn({'carbon-input': true, 'not-empty': !!_value})}
      style={style}
    >
      {placeholder ? (
        // TODO: fixme a11y
        // eslint-disable-next-line jsx-a11y/label-has-for
        <label className="label" htmlFor={`input-email-${id}`}>
          {placeholder}
        </label>
      ) : null}
      <input
        name={name}
        type={type}
        className="input"
        id={`input-${name}-${id}`}
        placeholder={placeholder}
        value={_value}
        data-testid={testId}
        onChange={e => {
          const v = e.target.value;

          onChange?.(v);
          setValue(v);
        }}
      />

      {errorText ? <div className="md-text md-text--error">{errorText}</div> : null}
    </InputWrapper>
  );
};
