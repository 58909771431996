import React from 'react';
import cn from 'classnames';
import {SelectionControlGroup} from 'react-md';
import './fluro-selection-control-group.scss';

export type FluroSelectionControlGroupOption<Value extends string> = {
  key?: string | number | undefined;
  label: React.ReactNode;
  value: Value;
};
type FluroSelectionControlGroupProps<Value extends string> = {
  id?: string;
  name?: string;
  className?: string;
  controlClassName?: string;
  controls: FluroSelectionControlGroupOption<Value>[];
  onChange?: (value: Value, event: Event) => void;
  type: 'radio' | 'checkbox';
  value?: Value;
  inline?: boolean;
};
export function FluroSelectionControlGroup<Value extends string>({
  id,
  name,
  className,
  controlClassName = 'nowrap',
  controls,
  onChange,
  type,
  value,
  inline,
}: FluroSelectionControlGroupProps<Value>) {
  return (
    <SelectionControlGroup
      id={id}
      name={name}
      className={cn(className, 'fluro-selection-control-group', {inline})}
      controlClassName={controlClassName}
      controls={controls}
      onChange={(v, e) => onChange?.(v as Value, e)}
      type={type}
      value={value}
    />
  );
}
