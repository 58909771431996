import {checkWorkspace, workspaceLinks} from 'containers/admin/users/types';
import {useMemo} from 'react';
import {useLocation} from 'react-router-dom';

export const useWorkspace = () => {
  const location = useLocation();
  const {workspace, isWorkspaceMrv, isWorkspaceCi, isWorkspaceSi} = checkWorkspace(
    location.pathname
  );
  const values = useMemo(
    () => ({
      workspace,
      workspaceLink: workspaceLinks[workspace],
      isWorkspaceMrv,
      isWorkspaceCi,
      isWorkspaceSi,
    }),
    [workspace]
  );
  return values;
};
