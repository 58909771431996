import React from 'react';
import styled from 'styled-components';
import {FontIcon} from 'react-md';
export const FieldsInfoLineWrapper = styled.div`
  margin-top: 3px;
`;

export const FieldsInfoLineValue = styled.span`
  color: #333;
  margin-right: 4px;
`;

export const FieldsInfoLineLabel = styled.span`
  color: #747474;
`;

type FieldsInfoLineItemProps = {
  invert?: boolean;
};

export const FieldsInfoLineItem = styled.span`
  display: inline;
  margin-right: 15px;

  &.no-line-break {
    white-space: nowrap;
  }

  ${(props: FieldsInfoLineItemProps) =>
    props.invert ? `> ${FieldsInfoLineLabel} {color: #333}` : ''};

  ${(props: FieldsInfoLineItemProps) =>
    props.invert ? `> ${FieldsInfoLineValue} {color: #747474}` : ''};
`;

const StyledFontIcon = styled(FontIcon)`
  position: relative;
  top: 4px;
  &.material-icons {
    font-size: 17px;
  }
`;

export const RegionIcon = () => <StyledFontIcon>place</StyledFontIcon>;
