import styled from 'styled-components';

export const Link = styled.span`
  color: ${({theme}) => theme.color.text.success};
  cursor: pointer;

  :hover {
    text-decoration: underline;
  }
`;
