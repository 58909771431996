import {selectMeasurement} from 'containers/login/login-selectors';
import {TOOLTIPS} from 'containers/si/constants';
import {useFetchKPI} from 'containers/si/hooks/useFetchKPI';
import {useSparklingChart} from 'containers/si/hooks/useSparklingChart';
import {t} from 'i18n-utils';
import React from 'react';
import {useAppSelector} from '_hooks';
import {kiloFormatter, toFixedFloat} from '_utils/number-formatters';
import {ChartMiddleData} from '../charts/components/chart-middle-data';
import {SparklineChart} from '../charts/components/sparkline-chart';
import {MultiChartKPICard} from '../charts/multi-chart-kpi-card';
import {BarContainer} from '../styled-components';
import {NetEmissionBarCard} from './net-emission-bar-card';

export const NetEmissionsCard = () => {
  const measurement = useAppSelector(selectMeasurement);
  const {response: netEmissionsResponse, loading} = useFetchKPI({
    kpi: 'net_ghg_emissions',
    summarizeBy: ['annualized'],
  });
  const {sparklingChartData, metric, perArea, dataset, comparisonValues, average} =
    useSparklingChart(netEmissionsResponse, {
      negativeIsGain: true,
      tooltip: {
        up: TOOLTIPS.KPI.netEmissions.pctUp,
        down: TOOLTIPS.KPI.netEmissions.pctDown,
      },
    });

  return (
    <MultiChartKPICard
      title={t({id: 'Net emissions'})}
      subtitle={t({id: 'in metric tonnes CO2 eq.'})}
      tooltip={TOOLTIPS.KPI.netEmissions.card}
      lazyLoad="ondemand"
      loading={loading}
    >
      <div>
        <ChartMiddleData
          cardCenterNumber={
            metric === 0 ? 'No Data' : `${String(kiloFormatter(metric)).toUpperCase()} tonnes`
          }
          cardCenterUnits={
            measurement === 'ac'
              ? `${toFixedFloat(perArea, 4)} tonnes per acre`
              : `${toFixedFloat(perArea, 4)} tonnes per hectare`
          }
        />
        <SparklineChart
          sparklingChartCaption={`${sparklingChartData.datasets.length} year avg`}
          sparklingChartCaptionBolded={`${kiloFormatter(average)} tonnes`}
          comparisonValues={comparisonValues}
          chartData={{
            labels: sparklingChartData.labels,
            datasets: dataset,
          }}
          chartDataMidline={average}
          linechartLabelSuffix="tonnes"
        />
      </div>
      <BarContainer barHeight={280}>
        <NetEmissionBarCard />
      </BarContainer>
    </MultiChartKPICard>
  );
};
