import {t} from 'i18n-utils';
import React, {useState} from 'react';
import {useDispatch} from 'react-redux';
import {saveFarm} from 'modules/farms/actions';
import {FluroDialog, FluroButton} from 'components';
import {useAppSelector} from '_hooks';
import {dialogToggle, DialogType} from 'modules/helpers';
import {TextField} from 'react-md';
import {showNotification} from 'components/notification/notification';
import {selectDialogData} from '../../../../../modules/helpers/selectors';
import {MRVApi} from '_api';
import {selectCurrentProjectFarms} from 'containers/mrv/monitoring/module/selectors';
import {fetchProject} from 'containers/mrv/monitoring/module/thunks';

const EditFarmNameDialog = () => {
  const dispatch = useDispatch();
  const {projectId, farmId, farmName} =
    useAppSelector(s => selectDialogData(s, DialogType.editFarmName))?.payload || {};
  const mrvFarms = useAppSelector(selectCurrentProjectFarms);

  const [newFarmName, setNewFarmName] = useState(farmName || '');

  const onSaveFarmName = async (ev: any) => {
    ev.preventDefault();

    await dispatch(saveFarm({id: farmId, name: newFarmName}));
    const mrvFarmId = mrvFarms.find(f => f.core_farm_group_id === farmId)?.id;
    if (mrvFarmId) {
      await MRVApi.updateFarm({projectId, mrvFarmId, farm: {farm_name: newFarmName}});
      await dispatch(fetchProject({projectId}));
    }

    showNotification({
      title: t({id: 'note.success', defaultMessage: 'Success'}),
      message: t({id: '{type} was successfully saved'}, {type: t({id: 'Name'})}),
      type: 'success',
    });
    onHide();
  };
  const onHide = () => {
    dispatch(dialogToggle(DialogType.editFarmName, false));
  };

  const farmNameError = newFarmName.length > 50 || newFarmName.length < 2;

  return (
    <FluroDialog
      id="farm-edit-name--dialog"
      visible={true}
      isDraggable
      title={t({id: 'Edit farm name'})}
      initialFocus={'#farm-name'}
      focusOnMount
      portal
      onHide={onHide}
      width={400}
    >
      <form onSubmit={onSaveFarmName}>
        <TextField
          id="farm-name"
          label="Farm name"
          placeholder={t({id: 'Farm name'})}
          value={newFarmName}
          onChange={text => setNewFarmName(text)}
          error={farmNameError}
          errorText={t({id: 'Farm name must be range characters'}, {range: '2-50'})}
        />

        <FluroButton
          type={'submit'}
          className={'margin-top-10 element-full-width'}
          disabled={farmNameError}
          raised
          primary
        >
          {t({id: 'Save name'})}
        </FluroButton>
      </form>
    </FluroDialog>
  );
};

export default EditFarmNameDialog;
