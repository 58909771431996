import type {ComponentType} from 'react';
import React from 'react';
import cn from 'classnames';
import {Flex, FluroButton} from '../../../../components';
import {AsyncStatusType, dialogToggle, DialogType, Status} from 'modules/helpers';
import {useAppDispatch, useAppSelector} from '_hooks';
import {useMonitoringUrlParams} from '../hooks';
import {
  selectEnrolledFieldsFromFMS,
  selectFMSOfEnrolledFieldsFromFMS,
  selectPhase,
  selectSyncingOperationsStatus,
} from '../module/selectors';
import {showNotification} from 'components/notification/notification';
import {UploadIcon} from '../../../map/icons';
import {syncProcessStartedInfoText} from '../../utils';
import {selectIntegrationPlatforms} from '../../../profile/integration/integration-selectors';
import {selectAsyncRequestStatus} from '../../../../modules/global/selectors';
import {setSelectedPlatformToImport} from '../../../profile/integration/actions';
import type {IntegrationPlatform} from '../../../profile/integration/types';
import {t} from 'i18n-utils';
import type {MRVPhaseType} from 'containers/mrv/types';
import {FontIcon} from 'react-md';

export const MRVFMSSyncBtn: ComponentType<{phaseType: MRVPhaseType}> = ({phaseType}) => {
  const dispatch = useAppDispatch();
  const {projectId} = useMonitoringUrlParams();
  const enrolledFieldsFromFMS = useAppSelector(s => selectEnrolledFieldsFromFMS(s, projectId));
  const {isSync, syncingPlatformName} = useAppSelector(selectSyncingOperationsStatus);
  const integrationPlatforms = useAppSelector(selectIntegrationPlatforms);
  const externalPlatform = useAppSelector(s => selectFMSOfEnrolledFieldsFromFMS(s, projectId));
  const phase = useAppSelector(s => selectPhase(s, phaseType));

  const authorizedPlatformsLoaded =
    useAppSelector(s => selectAsyncRequestStatus(s, AsyncStatusType.authorizedPlatforms)) ===
    Status.Done;

  if (!projectId || !phase?.id || !enrolledFieldsFromFMS.length || !authorizedPlatformsLoaded)
    return null;

  const onClick = () => {
    if (isSync) {
      showNotification({
        autoClose: false,
        title: (
          <Flex alignItems={'center'} gap={'10px'}>
            <UploadIcon style={{color: 'currentColor'}} size={18} />
            {t({id: 'Fms.Sync.Import in progress', defaultMessage: 'Import in progress'})}
          </Flex>
        ),
        message: syncProcessStartedInfoText(syncingPlatformName),
        type: 'info',
      });
      return;
    }

    if (integrationPlatforms[externalPlatform]?.authorized) {
      dispatch(dialogToggle(DialogType.fmsSyncDialog, true, {phaseId: phase.id}));
    } else {
      dispatch(dialogToggle(DialogType.fmsSyncLoginToPlatformDialog, true));
      dispatch(setSelectedPlatformToImport(externalPlatform as IntegrationPlatform));
    }
  };

  return (
    <FluroButton
      blank
      raised
      onClick={onClick}
      disabled={isSync}
      iconEl={<FontIcon className={cn({'animation--spin': isSync})}>sync</FontIcon>}
    >
      FMS Sync
    </FluroButton>
  );
};
