import React from 'react';
import {useAppSelector} from '_hooks';
import {kiloFormatter, toFixedFloat} from '_utils/number-formatters';
import {t} from 'i18n-utils';
import {SparklingChartCard} from 'containers/si/kpi/charts/sparkling-chart-card';
import {selectMeasurement} from 'containers/login/login-selectors';
import {TOOLTIPS} from 'containers/si/constants';
import {useFetchKPI} from 'containers/si/hooks/useFetchKPI';
import {useSparklingChart} from 'containers/si/hooks/useSparklingChart';

export const SocSequestrationCard = () => {
  const measurement = useAppSelector(selectMeasurement);

  const {response: socResponse, loading} = useFetchKPI({kpi: 'soc', summarizeBy: ['annualized']});
  const {sparklingChartData, metric, perArea, dataset, comparisonValues, average} =
    useSparklingChart(socResponse, {
      tooltip: {
        up: TOOLTIPS.KPI.soc.pctUp,
        down: TOOLTIPS.KPI.soc.pctDown,
      },
    });

  return (
    <SparklingChartCard
      title={t({id: 'dSOC sequestration'})}
      subtitle={t({id: 'in metric tonnes CO2 eq.'})}
      cardCenterNumber={
        metric === 0 ? 'No Data' : `${String(kiloFormatter(metric)).toUpperCase()} tonnes`
      }
      cardCenterUnits={
        measurement === 'ac'
          ? `${toFixedFloat(perArea, 4)} tonnes per acre`
          : `${toFixedFloat(perArea, 4)} tonnes per hectare`
      }
      comparisonValues={comparisonValues}
      sparklingChartCaption={`${sparklingChartData.datasets.length} year avg`}
      sparklingChartCaptionBolded={`${kiloFormatter(average)} tonnes`}
      chartData={{labels: sparklingChartData.labels, datasets: dataset}}
      chartDataMidline={average}
      tooltip={TOOLTIPS.KPI.soc.card}
      linechartLabelSuffix="tonnes"
      loading={loading}
    />
  );
};
