import * as React from 'react';
import type {Coords} from './base';
import {defaultColors, sum} from './base';

export const DonutChart = ({
  values,
  colors = defaultColors,
  size = 60,
}: {
  /**
   * Don't send empty values (0)
   */
  values: number[];
  colors?: string[];
  size?: number;
}) => {
  const border = 1;
  const donutWidth = size / 4 - border;
  const outerRadius = size / 2 - border;
  const innerRadius = size / 2 - border - donutWidth;

  const total = sum(values);
  const percents = values.map(v => v / total);
  const o = {x: size / 2, y: size / 2};

  const paths =
    percents.length === 1
      ? getSingleSlice(o, innerRadius, outerRadius, colors[0])
      : getSlices(o, innerRadius, outerRadius, percents, colors);

  return (
    <svg width={size} height={size} xmlns="http://www.w3.org/2000/svg">
      <g>
        {getTotal(o, outerRadius, total)}
        {paths}
      </g>
    </svg>
  );
};

const getSingleSlice = (o: Coords, r1: number, r2: number, color: string) => {
  const d = getSingleSlicePathData(o, r1, r2);
  return [getSlicePath(d, color, 0)];
};

const getSlices = (o: Coords, r1: number, r2: number, percents: number[], colors: string[]) => {
  const paths = [];
  let a1 = {x: o.x, y: o.y - r1}; // start pointing up
  let a2 = {x: o.x, y: o.y - r2}; // start pointing up
  let sumAngle = -Math.PI / 2; // start pointing up

  for (let i = 0; i < percents.length; i++) {
    const p = percents[i];
    const c = colors[i];
    const {b1, b2, angle, largeArc} = getSlicePoints(o, r1, r2, p, sumAngle);
    const d = getSlicePathData(o, a1, a2, b1, b2, r1, r2, largeArc);

    paths.push(getSlicePath(d, c, i));
    a1 = b1;
    a2 = b2;
    sumAngle = angle;
  }
  return paths;
};

const getSlicePoints = (o: Coords, r1: number, r2: number, pct: number, sumAngle: number) => {
  const a = Math.PI * 2 * pct;
  const angle = sumAngle + a;
  const b1 = {
    x: Math.cos(angle) * r1 + o.x,
    y: Math.sin(angle) * r1 + o.y,
  };
  const b2 = {
    x: Math.cos(angle) * r2 + o.x,
    y: Math.sin(angle) * r2 + o.y,
  };
  return {b1, b2, angle, largeArc: a > Math.PI ? 1 : 0};
};

// A rx ry x-axis-rotation large-arc-flag sweep-flag x y
// https://developer.mozilla.org/en-US/docs/Web/SVG/Tutorial/Paths#arcs
const getSlicePathData = (
  o: Coords,
  a1: Coords,
  a2: Coords,
  b1: Coords,
  b2: Coords,
  r1: number,
  r2: number,
  largeArc: number
) => `
M ${a1.x} ${a1.y}
A ${r1} ${r1} 0 ${largeArc} 1 ${b1.x} ${b1.y}
L ${b2.x} ${b2.y}
A ${r2} ${r2} 0 ${largeArc} 0 ${a2.x} ${a2.y}
Z`;

const getSingleSlicePathData = (o: Coords, r1: number, r2: number) => {
  const hair = 0.999999;
  const a1 = {x: o.x + hair, y: o.y - r1};
  const a2 = {x: o.x + hair, y: o.y - r2};
  const b1 = {x: o.x - hair, y: o.y + r1};
  const b2 = {x: o.x - hair, y: o.y + r2};
  return `
M ${a1.x} ${a1.y}
A ${r1} ${r1} 0 1 1 ${b1.x} ${b1.y}
A ${r1} ${r1} 0 1 1 ${a1.x} ${a1.y}
M ${a2.x} ${a2.y}
A ${r2} ${r2} 0 1 0 ${b2.x} ${b2.y}
A ${r2} ${r2} 0 1 0 ${a2.x} ${a2.y}
Z`;
};

const getSlicePath = (d: string, color: string, key: number) => {
  return <path key={key} d={d} fill={color} stroke="white" strokeWidth={1} />;
};

const getTotal = (o: Coords, r: number, v: number) => {
  return (
    <>
      <circle cx={o.x} cy={o.y} r={r} fill="white" />
      <text x={o.x} y={o.y} textAnchor="middle" dominantBaseline="central">
        {v}
      </text>
    </>
  );
};
