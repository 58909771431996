import type {ComponentType} from 'react';
import React, {useEffect, useState} from 'react';
import {useAppDispatch, useAppSelector} from '_hooks';
import {deepCopy} from '_utils';
import cn from 'classnames';
import {FontIcon, SelectionControl} from 'react-md';
import {Flex, FluroButton} from 'components';
import {FluroChipDropdownControllable as FluroChipDropdown} from 'components/fluro-dropdown/fluro-chip-dropdown';
import {setCurrentKPIFilter} from 'containers/si/module/reducer';
import {
  selectCurrentProgram,
  selectSICurrentFilter,
  selectSISavedFilters,
} from 'containers/si/module/selectors';
import {fetchAllKPIFilters, setAllKPIFilters} from 'containers/si/module/thunks';
import {FilterNameForm} from './save-kpi-filter-popup';
import type {KPIFilter} from 'containers/si/api/apiTypes';

const dropdownStyles = {width: 'max-content', right: 0, paddingBottom: 0, paddingTop: '5px'};

export const SaveKPIFilter: ComponentType = () => {
  const dispatch = useAppDispatch();
  const currentProgram = useAppSelector(selectCurrentProgram);
  const siFilter = useAppSelector(selectSICurrentFilter); //v2
  const userSavedFilters = useAppSelector(selectSISavedFilters); //v2

  const [expand, setExpand] = useState(false);
  const [filterApplied, setFilterApplied] = useState('');
  const [filterToEdit, setFilterToEdit] = useState<KPIFilter>();
  const [filterRename, setFilterRename] = useState(false);
  const [saveNewFilterFormVisible, toggleSaveNewFilterFormVisibility] = useState(false);
  const [initialFilter, setInitialFilter] = useState<Omit<KPIFilter, 'name'>>({
    years: siFilter.years,
    selected_crop_types: siFilter.selectedCropTypes,
    visible_subsection_ids: siFilter.visibleSubsectionIds,
  });

  useEffect(() => {
    if (!currentProgram) return;

    dispatch(fetchAllKPIFilters({programId: currentProgram}));
  }, [dispatch, currentProgram]);

  useEffect(() => {
    setFilterApplied(''); // reset applied filter when some changes where applied
  }, [siFilter.selectedCropTypes, siFilter.visibleSubsectionIds, siFilter.years]); // We listen to this particular properties to update the applied filter

  const hideSaveNewFilterDialog = () => toggleSaveNewFilterFormVisibility(false);

  const onClearFilters = () => {
    setFilterApplied(''); // reset applied filter when some changes where applied
    dispatch(setCurrentKPIFilter({filter: initialFilter}));
  };

  const applySelectedFilter = (name: string) => {
    if (!filterApplied) {
      setInitialFilter({
        years: siFilter.years,
        selected_crop_types: siFilter.selectedCropTypes,
        visible_subsection_ids: siFilter.visibleSubsectionIds,
      });
    }
    const filter = userSavedFilters.filter(f => f.name === name)[0];
    filter &&
      dispatch(
        setCurrentKPIFilter({
          filter: {
            years: filter.years,
            selected_crop_types: filter.selected_crop_types,
            visible_subsection_ids: filter.visible_subsection_ids,
          },
        })
      );
    setExpand(false);
    setTimeout(() => setFilterApplied(name), 1000); // wait until all internal filter updates are made
  };

  const saveFilter = async (filterName: string) => {
    hideSaveNewFilterDialog();
    if (filterToEdit && currentProgram) {
      const allFilters: KPIFilter[] = deepCopy(userSavedFilters);

      if (filterRename) {
        allFilters.map(f => {
          if (f.name === filterToEdit.name) {
            f.name = filterName;
            f.years = filterToEdit.years;
            f.selected_crop_types = filterToEdit.selected_crop_types;
            f.visible_subsection_ids = filterToEdit.visible_subsection_ids;
          }
          return f;
        });
        setFilterRename(false);
      } else {
        const newFilter = {
          name: filterName,
          years: filterToEdit.years,
          selected_crop_types: filterToEdit.selected_crop_types,
          visible_subsection_ids: filterToEdit.visible_subsection_ids,
        };
        allFilters.push(newFilter);
      }

      dispatch(setAllKPIFilters({programId: currentProgram, filters: allFilters}));

      dispatch(
        setCurrentKPIFilter({
          filter: {
            years: filterToEdit.years,
            selected_crop_types: filterToEdit.selected_crop_types,
            visible_subsection_ids: filterToEdit.visible_subsection_ids,
          },
        })
      );
      setTimeout(() => setFilterApplied(filterName), 1000); // wait until all internal filter updates are made
    }
  };

  const onRenameFilter = (name: string) => {
    const filter = userSavedFilters.filter(f => f.name === name)[0];
    setFilterToEdit(filter);
    setFilterRename(true);
    toggleSaveNewFilterFormVisibility(true);
  };

  const onCreateNewFilter = () => {
    setFilterToEdit({
      name: '',
      years: siFilter.years,
      selected_crop_types: siFilter.selectedCropTypes,
      visible_subsection_ids: siFilter.visibleSubsectionIds,
    });
    toggleSaveNewFilterFormVisibility(true);
  };

  const onDeleteFilter = (name: string) => {
    if (confirm('Are you sure? This will delete the filter for all users.') && currentProgram) {
      const filterDeleted = userSavedFilters.filter(f => f.name !== name);

      dispatch(setAllKPIFilters({programId: currentProgram, filters: filterDeleted}));
      setExpand(false);
      onClearFilters();
    }
  };

  return (
    <>
      <FluroChipDropdown
        active={!!filterApplied}
        expand={expand}
        dropdownStyle={dropdownStyles}
        setExpand={setExpand}
        onClear={onClearFilters}
        label={filterApplied || 'Shared Program views'}
      >
        <>
          {userSavedFilters?.map(filter => {
            return (
              <Flex alignItems="center" nowrap gap={'5px'} key={filter.name}>
                <SelectionControl
                  id={filter.name}
                  name={filter.name}
                  type="radio"
                  label={filter.name}
                  checked={filterApplied === filter.name}
                  onClick={() => applySelectedFilter(filter.name)}
                />
                <FluroButton
                  className="filter-action-btn margin-left-auto"
                  icon
                  iconEl={<FontIcon>edit</FontIcon>}
                  onClick={() => onRenameFilter(filter.name)}
                />
                <FluroButton
                  className="filter-action-btn"
                  icon
                  iconEl={<FontIcon>delete</FontIcon>}
                  onClick={() => onDeleteFilter(filter.name)}
                />
              </Flex>
            );
          })}
          <Flex
            className={cn({'save-new-view-container': true, bordered: userSavedFilters.length > 0})}
            fullWidth
            justifyContent="center"
          >
            <FluroButton raised blank transparent onClick={() => onCreateNewFilter()}>
              + Save new view
            </FluroButton>
          </Flex>
        </>
      </FluroChipDropdown>

      {saveNewFilterFormVisible && (
        <FilterNameForm
          onHide={hideSaveNewFilterDialog}
          name={filterToEdit?.name}
          submit={saveFilter}
        />
      )}
    </>
  );
};
