import React, {useState} from 'react';
import {useAppDispatch, useAppSelector} from '_hooks';
import {Flex, FluroButton, Text} from 'components';
import type {Intervention, InterventionOptions} from 'containers/si/types';
import {selectNewInterventions, selectNewReportType} from 'containers/si/module/selectors';
import {setNewInterventions} from 'containers/si/module/reducer';
import {InterventionsCard} from './interventions-card';
import {InterventionScenarios} from './interventions-scenarios';
import '../create-report.scss';

export const InterventionsStep = () => {
  const dispatch = useAppDispatch();
  const allGeneratedInterventions = useAppSelector(selectNewInterventions);
  const reportType = useAppSelector(selectNewReportType);

  const [allInterventionScenarios, setAllInterventionScenarios] = useState<Set<Intervention>>(
    new Set([...allGeneratedInterventions])
  );

  const interventionOptions: InterventionOptions[] = [
    'tillage',
    'cover_cropping',
    'fertilizer_usage',
  ];

  // Use 'interventionOptions' keys to create the initial unchecked state
  const initialEmptyScenarios = {tillage: '', cover_cropping: '', fertilizer_usage: ''};
  const [allSelectedScenarios, setAllSelectedScenarios] =
    useState<Partial<Intervention>>(initialEmptyScenarios);

  const onSelectIntervention = (
    interventionName: InterventionOptions,
    interventionSelected: string
  ) => {
    // Program Plan only allows one intervention to be selected
    const updatedScenarios =
      reportType === 'program_plan'
        ? {[interventionName]: interventionSelected}
        : {...allSelectedScenarios, [interventionName]: interventionSelected};
    setAllSelectedScenarios(updatedScenarios);
  };

  const addNewIntervention = () => {
    const combinedScenarios = [...allInterventionScenarios, allSelectedScenarios];

    // To ensure all Intervention Scenarios are unique, use a Set with JSON.stringify
    const interventionSet = new Set<string>();
    combinedScenarios.forEach(
      obj => !interventionSet.has(JSON.stringify(obj)) && interventionSet.add(JSON.stringify(obj))
    );

    const combinedInterventions = new Set([...interventionSet].map(o => JSON.parse(o)));

    setAllInterventionScenarios(combinedInterventions);
    // Clear checkboxes
    setAllSelectedScenarios(initialEmptyScenarios);

    dispatch(setNewInterventions([...combinedInterventions]));
  };

  const removeIntervention = (intervention: Intervention) => {
    const updatedInterventions = new Set(
      [...allInterventionScenarios].filter(i => i !== intervention)
    );
    setAllInterventionScenarios(updatedInterventions);
    dispatch(setNewInterventions([...updatedInterventions]));
  };

  return (
    <div className="interventions-step">
      <Text bold nowrap>
        Choose which intervention scenarios to model then click "Add New Scenario" to add it to the
        list.
      </Text>
      <div className="intervention-cards-container">
        {interventionOptions.map(kpi => (
          <InterventionsCard
            intervention={kpi}
            key={kpi}
            onSelect={onSelectIntervention}
            allSelectedScenarios={allSelectedScenarios}
          />
        ))}
      </div>
      <Flex justifyContent={'center'}>
        <FluroButton
          raised
          primary
          onClick={addNewIntervention}
          disabled={Object.values(allSelectedScenarios).every(scenario => scenario === '')}
          className="add-intervention-button"
        >
          + Add Scenario
        </FluroButton>
      </Flex>
      <Text bold nowrap variant="h2">
        Scenarios
      </Text>
      {Array.from(allInterventionScenarios).map((intervention: Intervention, index: number) => {
        return (
          <InterventionScenarios
            key={index}
            scenarioNumber={index + 1}
            interventionScenarioArray={
              Object.entries(intervention) as [InterventionOptions, string][]
            }
            removeIntervention={() => removeIntervention(intervention)}
          />
        );
      })}
    </div>
  );
};
