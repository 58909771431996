import {t} from 'i18n-utils';
import React, {useEffect, useState, useMemo} from 'react';
import moment from 'moment';
import {formatDate, classifyAreaWithUnits, convertUnit, formatUnit, getAvgProp} from '_utils';
import {toFixedFloatUnsafe} from '_utils/number-formatters';
import {getGetURLParam} from '_utils/pure-utils';
import {GLOBAL_FORMAT_DATE} from '_constants';
import type {IterisTemperatureDay, ITreeData} from '../types';
import {getTreeDataByDate} from '../utils/trees';
import {FarmApi} from '_api';
import {useAppSelector} from '_hooks';
import {selectCropTypeById} from 'modules/global/selectors';
import {
  selectCurrentDate,
  selectCurrentDates,
  selectCurrentFarm,
  selectCurrentField,
  selectCurrentSeason,
  selectCurrentSensor,
  selectPreparedTemperatureData,
} from 'containers/map/reducer/selectors';
import {selectMeasurement} from 'containers/login/login-selectors';

const FieldInfoRow = ({
  display,
  label,
  value,
  classNameValue,
}: {
  display: any;
  label: string;
  value: any;
  classNameValue?: string;
}) => {
  return display ? (
    <tr>
      <td>{label}:</td>
      <td className={classNameValue ? classNameValue : ''}>{value}</td>
    </tr>
  ) : null;
};

type Props = {toggled: boolean};

// const fieldMean = (mean: any, range: number) => {
//   switch (mean.status) {
//     case RequestStatus.Idle:
//     case RequestStatus.Error:
//       return t({id: 'N/A'});
//     case RequestStatus.Loading:
//       return t({id: 'Loading...'});
//     case RequestStatus.Success:
//       return normalizeSensorIndex(mean.value, range);
//   }
// };

const FieldInfoOverlay = ({toggled}: Props) => {
  const {startDate, endDate, cropType, params} = useAppSelector(selectCurrentSeason) || {
    cropType: '',
  };
  const currentSensor = useAppSelector(selectCurrentSensor);
  const field = useAppSelector(selectCurrentField);
  const currentDate = useAppSelector(selectCurrentDate);
  const currentDates = useAppSelector(selectCurrentDates);
  const measurement = useAppSelector(selectMeasurement);
  const farm = useAppSelector(selectCurrentFarm);
  const preparedTemperatureData = useAppSelector(selectPreparedTemperatureData);
  const [canopyCover, setCanopyCover] = useState('N/A');
  // const currentFieldMean = useAppSelector(selectCurrentFieldMean);
  // const layerType = useAppSelector(selectTreeDetectionLayerType);
  // const range = useAppSelector(selectHistogram).range;

  // https://flurosat.atlassian.net/browse/FSB-2999
  const avgValue = useMemo(() => {
    const value = currentDates?.[currentDate]?.[getAvgProp(currentSensor)];

    if (value) {
      return value;
    }

    return 'N/A';
  }, [currentSensor, currentDates, currentDate]);

  const cropSubtype = params ? params.cropSubType : null;
  const showDate = startDate !== endDate;
  const currentWeatherDay =
    preparedTemperatureData[moment(currentDate, 'DD/MM/YYYY').format(GLOBAL_FORMAT_DATE)] ||
    ({} as IterisTemperatureDay);

  const currentDayTBase = `${convertUnit(measurement, 'C', currentWeatherDay.TBase)} °${formatUnit(
    measurement,
    'C'
  )}`;
  const growthStage = currentWeatherDay.GrowingStage ? currentWeatherDay.GrowingStage : null;
  const GDD = currentWeatherDay.dayDegrees ? currentWeatherDay.dayDegrees.toFixed(0) : null;
  const treeData = getTreeDataByDate(currentDate)?.[field.MD5] as ITreeData;
  const isTreeType = useAppSelector(s => selectCropTypeById(s, cropType) === 'tree');
  // const avgIndex =
  //   treeData && layerType !== 'default'
  //     ? get(treeData, `avg_${currentSensor.toLowerCase()}`, 'N/A')
  //     : fieldMean(currentFieldMean, range);

  const displayCanopyCover =
    getGetURLParam('enable_canopy_cover') ||
    farm.subscriptionID === 16 ||
    farm.subscriptionID === 23 ||
    farm.organizationID === 8 ||
    farm.organizationID === 392 ||
    farm.id === 12764 ||
    farm.id === 14620 ||
    farm.id === 15136 || // Nutrien- House
    farm.id === 15179 || // Danone 2021_West
    farm.id === 15180 || // Danone 2021_Center_Wes
    farm.id === 15181 || // Danone 2021_Center_East
    farm.id === 15182 || // Danone 2021_East
    farm.id === 15126 || // All farm - McCain 2021
    farm.id === 14619;

  // FSB-2439 for the demo we have this crutch, because we only calculate good
  // canopy cover for potatoes and corn and cotton. UPD FSB-3326, remove the cropType restriction
  // const isQualityCanopyCover = useMemo(
  //   () => cropType === 'potatoes' || cropType === 'corn' || cropType === 'cotton',
  //   [cropType]
  // );

  useEffect(() => {
    if (displayCanopyCover && toggled) {
      const dateTime = currentDates?.[currentDate]?.[currentSensor]?.date;

      if (dateTime) {
        setCanopyCover(t({id: 'Loading...'}));
        FarmApi.canopyCover(field.MD5, dateTime, cropType)
          .then(result => {
            const cc = result?.data?.canopy_cover || 0;

            setCanopyCover(`${cc ? `${toFixedFloatUnsafe(cc * 100, 1)}%` : t({id: 'N/A'})}`);
          })
          .catch(() => setCanopyCover('Error'));
      }
    }
  }, [currentDate, currentSensor, field, toggled]);

  return (
    <div className={'pop-up field-info'} data-cy="field-info-window">
      {field ? <div className={'field-name'}>{field.Name.replace('.kml', '')}</div> : null}
      <div className={'delimiter'} />

      <table className={'field-info-table'}>
        <tbody>
          <FieldInfoRow
            display={field.Area}
            label={t({id: 'Field Area'})}
            value={classifyAreaWithUnits(field.Area, measurement)}
          />

          <FieldInfoRow
            display={field.CountryRegion}
            label={t({id: 'Region'})}
            value={field.CountryRegion}
          />

          <FieldInfoRow
            display={showDate}
            label={isTreeType ? t({id: 'previous harvest date'}) : t({id: 'sowing date'})}
            value={moment(startDate).format(formatDate())}
          />

          <FieldInfoRow
            display={showDate}
            label={t({id: 'harvest date'})}
            value={moment(endDate).format(formatDate())}
          />
          <FieldInfoRow
            display={cropType}
            label={t({id: 'crop type'})}
            value={cropType ? cropType.charAt(0).toUpperCase() + cropType.slice(1) : ''}
          />

          <FieldInfoRow display={cropSubtype} label={'variety'} value={cropSubtype} />

          <FieldInfoRow
            display={GDD}
            label={t({id: 'grow. day degrees'})}
            value={`${GDD} (${t({id: 'Tb'})} = ${currentDayTBase})`}
          />

          <FieldInfoRow
            display={growthStage}
            label={t({id: 'est. growth stage'})}
            value={growthStage}
          />

          <FieldInfoRow
            display={currentSensor !== 'NONE' && currentSensor !== 'TCI' && currentSensor !== 'NC'}
            label={`${t({id: 'raw'})} ${currentSensor}`}
            value={avgValue}
          />

          {treeData ? (
            <React.Fragment>
              <FieldInfoRow
                display={treeData.tree_count}
                label={t({id: 'number of trees'})}
                value={treeData.tree_count}
              />
              <FieldInfoRow
                display={currentSensor === 'DIAMETER' && treeData.average_diameter}
                label={t({id: 'avg. diameter'})}
                value={`${convertUnit(measurement, 'm', treeData.average_diameter).toFixed(
                  2
                )} ${formatUnit(measurement, 'm')}`}
              />
            </React.Fragment>
          ) : null}

          <FieldInfoRow
            display={displayCanopyCover}
            label={t({id: 'Canopy cover'})}
            value={canopyCover}
          />
        </tbody>
      </table>
    </div>
  );
};

export default FieldInfoOverlay;
