import colors from 'variables.scss';
import {getTypedKeys} from '_utils/object';
import {toFixedFloat} from '_utils/number-formatters';
import type {
  AbatementPotentialFilterRange,
  SIAggLevel,
  SIMetricColumn,
  SIMetricGroup,
} from './types';
import {CoverCropsType, TillagePractice} from './types';
import {isUndefined} from '_utils/typeGuards';

export const TREND_THRESHOLD = 15;

export const SI_AREA_AGG_AVAILABLE: SIAggLevel[] = [
  'supply_shed',
  'state',
  'crd',
  'county',
  'huc8',
  'huc10',
  'huc12',
];

export const AGG_LEVEL_TO_LABEL: Record<SIAggLevel, string> = {
  supply_shed: 'supply sheds',
  state: 'states',
  county: 'counties',
  crd: 'CRD',
  huc8: 'huc8',
  huc10: 'huc10',
  huc12: 'huc12',
};

export const coverCropsOrder = [
  CoverCropsType.Perennial,
  CoverCropsType.WinterCommodity,
  CoverCropsType.CoverCrop,
];

export const coverCropsFullOrder = [
  CoverCropsType.Perennial,
  CoverCropsType.WinterCommodity,
  CoverCropsType.CoverCrop,
  CoverCropsType.NoCoverCrop,
];

export const coverCropsLabels = {
  [CoverCropsType.Perennial]: 'Perennial',
  [CoverCropsType.WinterCommodity]: 'Winter Commodity',
  [CoverCropsType.CoverCrop]: 'Cover Crop',
  [CoverCropsType.NoCoverCrop]: 'No Winter Crop',
  // [CoverCropsType.NoData]: 'No Data',
};

export const coverCropsColor = {
  [CoverCropsType.Perennial]: '#ffbb00',
  [CoverCropsType.WinterCommodity]: '#375e97',
  [CoverCropsType.CoverCrop]: '#37681c',
  [CoverCropsType.NoCoverCrop]: '#C2C2C2',
};

export const coverCropsColorScale = {
  [CoverCropsType.Perennial]: ['#ffffff', coverCropsColor[CoverCropsType.Perennial]],
  [CoverCropsType.WinterCommodity]: ['#ffffff', coverCropsColor[CoverCropsType.WinterCommodity]],
  [CoverCropsType.CoverCrop]: ['#ffffff', coverCropsColor[CoverCropsType.CoverCrop]],
  [CoverCropsType.NoCoverCrop]: ['#ffffff', coverCropsColor[CoverCropsType.NoCoverCrop]],
};

export const SIAreaDefaultFillColor = '#777';

//  Tillage
export const tillagePracticeLabel = {
  [TillagePractice.NoTill]: 'No till',
  [TillagePractice.Conventional]: 'Conventional till',
  [TillagePractice.Reduced]: 'Reduced till',
};

export const tillagePracticeMenuItems = [
  {label: tillagePracticeLabel[TillagePractice.NoTill], value: TillagePractice.NoTill},
  {label: tillagePracticeLabel[TillagePractice.Reduced], value: TillagePractice.Reduced},
  {label: tillagePracticeLabel[TillagePractice.Conventional], value: TillagePractice.Conventional},
];

export const tillageColors = {
  min: '#e7552c',
  mid: '#fff',
  [TillagePractice.NoTill]: '#4988EF',
  [TillagePractice.Conventional]: '#2A3B68',
  [TillagePractice.Reduced]: '#ffb745',
};

//  END Tillage

export const negativeScaleColor = '#ED625C';

export const METRIC_GROUPS: SIMetricGroup[] = [
  'carbon',
  'cover_crops',
  'grower_demographics',
  'tillage_practices',
  'ghg',
  'soc_sequestration',
  'crop_yield',
  'fertilizer',
  'ef',
];

export const metricGroupsSet: Record<SIMetricGroup, SIMetricColumn[]> = {
  carbon: ['carbon_all', 'carbon_all_corn', 'carbon_corn_rotation', 'total_arable_area_ac'],
  cover_crops: ['cc_area_ac', 'total_arable_area_ac'],
  grower_demographics: ['cc_operations_nb', 'count_operations'],
  tillage_practices: ['tillage', 'total_arable_area_ac'],
  ghg: ['ghg', 'carbon_area_ac'],
  soc_sequestration: ['soc', 'carbon_area_ac'],
  crop_yield: ['crop_yield', 'kpi_area_ha'],
  fertilizer: ['fertilizer', 'kpi_area_ha'],
  ef: ['ef'],
};

export const metricGroupsLabels = {
  carbon: 'Abatement potential',
  cover_crops: 'Cover crops',
  grower_demographics: 'Grower demographics',
  tillage_practices: 'Tillage practices',
  ghg: 'GHG',
  soc_sequestration: 'SOC Sequestration',
  crop_yield: 'Yield',
  fertilizer: 'Fertilizer',
  ef: 'Emissions factor',
};

export const SI_YEARS_AVAILABLE = [2015, 2016, 2017, 2018, 2019, 2020, 2021];

export const SUMMER_CROP_TYPE_TO_CROP_TYPE_MAP: {[index: string]: string} = {
  1: 'corn',
  2: 'cotton',
  3: 'rice',
  4: 'sorghum',
  5: 'soybeans',
  6: 'sunflowers',
  10: 'peanuts',
  11: 'tobacco',
  12: 'sweet_corn',
  13: 'popcorn',
  14: 'spearmint', // to check - Mint
  21: 'barley',
  23: 'wheat_spring',
  24: 'wheat_winter',
  25: 'small_grains',
  26: 'double_wheat_winter/soybeans',
  27: 'rye',
  28: 'oats',
  29: 'millet',
  30: 'speltz', // no crop type
  31: 'canola',
  32: 'flaxseed', // no crop type
  33: 'safflowers',
  34: 'brassica',
  35: 'mustard',
  36: 'alfalfa',
  37: 'hay',
  38: 'camelina',
  39: 'buckwheat',
  41: 'sugar_beets',
  42: 'dry_beans',
  43: 'potatoes',
  44: 'other',
  45: 'sugarcane',
  46: 'sweet_potatoes',
  47: 'Misc VegsVegs & Fruits', // no crop type
  48: 'watermelon',
  49: 'onions',
  50: 'cucumbers',
  51: 'chickpeas',
  52: 'lentils',
  53: 'peas',
  54: 'tomatoes',
  55: 'caneberries',
  56: 'hops',
  57: 'herbs',
  58: 'clover', // to check  Clover/Wildflowers
  59: 'grass_seed',
  60: 'switchgrass',
  61: 'fallow',
  66: 'cherries',
  67: 'peaches',
  68: 'apples',
  69: 'grapes',
  70: 'trees,conifer',
  71: 'trees',
  72: 'citrus',
  74: 'pecans',
  75: 'almonds',
  76: 'walnuts',
  77: 'pears',
  92: 'aquaculture', // not crop Aquaculture
  111: 'open_water', // no crop type Open Water
  121: 'Developed/Open Space', // no crop type Developed/Open Space
  122: 'Developed/Low Intensity', // no crop type Developed/Low Intensity
  123: 'Developed/Med Intensity', // no crop type Developed/Med Intensity
  124: 'Developed/High Intensity', // no crop type Developed/High Intensity
  131: 'Barren', // no crop type Barren
  141: 'trees,deciduous',
  142: 'Evergreen Forest', // no crop type Evergreen Forest
  143: 'Mixed Forest', // no crop type Mixed Forest
  152: 'shrubland',
  176: 'grass_pasture',
  190: 'woody_wetland',
  195: 'herbaceous_wetland',
  204: 'pistachios',
  205: 'triticale',
  206: 'carrots',
  207: 'asparagus',
  208: 'garlic',
  209: 'cantaloupe',
  211: 'olives',
  212: 'oranges',
  213: 'melon', // to check - Honeydew Melons
  214: 'broccoli',
  216: 'peppers',
  217: 'pomegranate',
  218: 'nectarines',
  219: 'greens',
  220: 'plums',
  221: 'strawberries',
  222: 'squash',
  224: 'vetch',
  225: 'double_wheat_winter/corn',
  226: 'double_oats/corn',
  227: 'lettuce',
  228: 'double_triticale/corn',
  229: 'pumpkins',
  231: 'double_lettuce/cantaloupe',
  232: 'double_lettuce/cotton',
  233: 'double_lettuce/barley',
  236: 'double_wheat_winter/sorghum',
  237: 'double_barley/corn',
  238: 'double_wheat_winter/cotton',
  239: 'double_soybeans/cotton',
  240: 'double_soybeans/oats',
  241: 'double_corn/soybeans',
  242: 'blueberries',
  243: 'cabbage',
  244: 'cauliflower',
  245: 'celery',
  246: 'radishes',
  247: 'turnips',
  248: 'eggplant',
  249: 'gourds',
  250: 'cranberries',
  254: 'double_barley/soybeans',
};

// Maximum number of items in the Summary chart to display without "See more" button
// and before showing range filter slider
// refs: https://regrow.atlassian.net/browse/FSB-5820?focusedCommentId=29456
export const SUMMARY_BARS_PREVIEW_THRESHOLD = 10;

// Abatement potential
export const lowAbatementPotentialColor = '#fff';
export const abatementPotentialColors: string[] = [
  colors['main-green-100'],
  colors['main-green-400'],
  '#169F97',
  colors['main-shape-color'],
  '#165A8C',
];

export const AbatementNoDataLabel = 'No Data';

export const getAbatementPotentialColor = (
  range: AbatementPotentialFilterRange,
  value?: number
) => {
  if (isUndefined(value) || !Number.isFinite(value) || !range.length) return SIAreaDefaultFillColor;
  else if (value < LOW_ABATEMENT_THRESHOLD) return lowAbatementPotentialColor;

  let resultColor = '';
  range.forEach((rangeValue, i) => {
    if (resultColor) return;

    if (toFixedFloat(value, 1) <= rangeValue) resultColor = abatementPotentialColors[i];
  });
  return resultColor || SIAreaDefaultFillColor;
};

export const abatementPotentialMetricItems = [
  {label: 'All crop types', value: 'carbon_all'},
  {label: 'Corn rotation', value: 'carbon_corn_rotation'},
  {label: 'Continuous corn', value: 'carbon_all_corn'},
];

export const METRIC_TYPE_LABELS = {
  ac: 'acres',
  op: 'operations',
};

export const LOW_ABATEMENT_THRESHOLD = 0.1;

export const SUPPLY_SHEDS: Record<number, string> = {
  1: 'California Central Valley',
  2: 'Great Lakes/Corn Belt',
  3: 'Mid-Atlantic',
  4: 'Mountain West',
  5: 'Northern Plains',
  6: 'Quebec',
  7: 'Southern Plains',
  8: 'Western Canada',
};

export const SUPPLY_SHEDS_META = getTypedKeys(SUPPLY_SHEDS).reduce((acc, id) => {
  return {...acc, [id]: {name: SUPPLY_SHEDS[id], id}};
}, {});
