import {CheckIcon} from 'components/icons';
import {t} from 'i18n-utils';
import React from 'react';
import type {HTMLAttributes, ComponentType} from 'react';
import './confirm-button.scss';

export const ConfirmButton: ComponentType<HTMLAttributes<HTMLDivElement>> = ({
  className,
  ...props
}) => {
  return (
    <div
      role="button"
      className={['confirm-button', className].join(' ')}
      title={t({id: 'BtnLabel.Confirm', defaultMessage: 'Confirm'})}
      data-testid="button--confirm"
      {...props}
    >
      <CheckIcon className="check-icon" />
    </div>
  );
};
