import React, {useCallback, useEffect} from 'react';
import FluroDialog from 'components/fluro-dialog';

import {setId} from './reducer';
import {useAppDispatch, useAppSelector} from '../../_hooks';

type Props = {
  id: string;
  title?: string;
  width?: number | string;
  height?: number | string;
  children: React.ReactNode;
};

const HelpModal = ({id, title = '', width, height, children}: Props) => {
  const dispatch = useAppDispatch();
  const currentPopUpId = useAppSelector(s => s.helpPopup.currentPopUpId);

  const onHide = useCallback(() => {
    dispatch(setId(''));
  }, []);

  useEffect(() => {
    return () => {
      onHide();
    };
  });

  return (
    <FluroDialog
      id={`help-popup-${id}`}
      visible={currentPopUpId === id}
      title={title || ''}
      focusOnMount={false}
      isDraggable
      onHide={onHide}
      width={width === undefined ? null : width}
      height={height === undefined ? null : height}
      autosizeContent={false}
    >
      <div>{children}</div>
    </FluroDialog>
  );
};

export default HelpModal;
