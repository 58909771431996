// @ts-nocheck
import {t} from 'i18n-utils';
import React from 'react';
import {ListItem, MenuButton} from 'react-md';
import {useDispatch} from 'react-redux';
import exportTreeZoningToPdf from './export-tree-zoning-to-pdf';
import exportNRxToPdf from './export-nrx-to-pdf';
import {
  exportNrxToAgworld,
  exportNrxToAgx,
  exportNrxToKml,
  exportNrxToShp,
  exportTreesToShapefile,
  exportZoningShapeFile,
} from 'containers/map/actions/zoning-actions';
import {FluroButton, ReadOnly} from 'components';
import {DownloadIcon} from '../../../icons';
import {getFieldExternalServiceType, isAgxField} from '_utils/farm-utils';
import {useAppSelector} from '_hooks';
import {selectIsNRxEnabled, selectNRecommendation} from 'containers/map/features/nrx/nrx-selectors';

import {
  selectCurrentField,
  selectTreeDetectionLayerType,
  selectWholeFarmData,
  selectZoning,
} from '../../../reducer/selectors';
import cn from 'classnames';
import {ExternalService, ZoningTab} from '../../../types';

const ExportZoning = () => {
  const exportOptions = [];
  const dispatch = useDispatch();
  const isNrx = useAppSelector(selectIsNRxEnabled);

  const {nrxResult, nrxTabRate, selectedObjective} = useAppSelector(selectNRecommendation);
  const zoning = useAppSelector(selectZoning);
  const currentField = useAppSelector(selectCurrentField);
  const isTreeAnalysis = useAppSelector(selectTreeDetectionLayerType) !== 'default';
  const {isWholeFarmView, treeZoning: wholeFarmTreeZoning} = useAppSelector(selectWholeFarmData);
  const haveSuggestedPoints = !!Object.keys(zoning.points).length;

  const useBlankButtonStyles = haveSuggestedPoints && zoning.tab === ZoningTab.MainZoning;

  const showNRecommendationButtons = isNrx && nrxResult[nrxTabRate][selectedObjective].type;

  const onExport = (exportFunc: () => void) => {
    dispatch(exportFunc());
  };

  if (showNRecommendationButtons) {
    if (nrxTabRate !== 'blanket') {
      exportOptions.push({
        key: 'export-nrx-to-shp',
        onClick: () => onExport(exportNrxToShp),
        primaryText: t({id: 'Export {ext}'}, {ext: 'SHP'}),
      });
    }
    exportOptions.push(
      {
        key: 'export-nrx-to-pdf',
        onClick: () => onExport(exportNRxToPdf),
        primaryText: t({id: 'Export {ext}'}, {ext: 'PDF'}),
      },
      {
        key: 'export-nrx-to-kml',
        onClick: () => onExport(exportNrxToKml),
        primaryText: t({id: 'Export {ext}'}, {ext: 'kml'}),
      }
    );

    if (isAgxField(currentField)) {
      exportOptions.push({
        key: 'export-nrx-to-agx',
        onClick: () => onExport(exportNrxToAgx),
        primaryText: t({id: 'Export to {external_service}'}, {external_service: 'agX'}),
      });
    }

    if (getFieldExternalServiceType(currentField) === ExternalService.Agworld) {
      exportOptions.push({
        key: 'export-nrx-to-agworld',
        onClick: () => onExport(exportNrxToAgworld),
        primaryText: t({id: 'Export to {external_service}'}, {external_service: 'Agworld'}),
      });
    }
  }

  if (!isWholeFarmView && isTreeAnalysis && !!zoning.treeZones.length) {
    exportOptions.push({
      key: 'export-tree-to-pdf',
      onClick: () => onExport(exportTreeZoningToPdf),
      primaryText: t({id: 'Export {ext}'}, {ext: 'PDF'}),
    });
  }
  if (
    isTreeAnalysis && isWholeFarmView
      ? wholeFarmTreeZoning.treeZoningShapefileSrc
      : zoning.treeZoningShapefileSrc
  ) {
    exportOptions.push({
      key: 'export-tree-to-shapefile',
      onClick: () => onExport(exportTreesToShapefile),
      primaryText: t({id: 'Export {ext}'}, {ext: 'SHP'}),
    });
  }

  if (!isNrx && !isTreeAnalysis && zoning.zones.length !== 0) {
    exportOptions.push({
      key: 'export-regular-to-shp',
      onClick: () => onExport(exportZoningShapeFile),
      primaryText: t({id: 'Export {ext}'}, {ext: 'SHP'}),
    });
  }

  if (exportOptions.length === 0) return null;

  return exportOptions.length === 1 ? (
    <FluroButton
      className={'element-full-width'}
      readonlyFullWidth
      raised
      primary
      readonly
      onClick={exportOptions[0].onClick}
    >
      {exportOptions[0].primaryText}
    </FluroButton>
  ) : (
    <ReadOnly fullWidth>
      <MenuButton
        id="export-zoning-menu-btn"
        className={cn({
          'btn-with-icon': true,
          'element-full-width': true,
          'fluro-button': true,
          blank: useBlankButtonStyles,
          'gray-bordered': true,
        })}
        raised
        primary={!useBlankButtonStyles}
        block
        iconEl={<DownloadIcon />}
        menuItems={exportOptions.map(({primaryText, onClick, key}) => (
          <ListItem key={key} onClick={onClick} primaryText={primaryText} />
        ))}
        simplifiedMenu={false}
        anchor={{
          x: 'center',
          y: 'overlap',
        }}
      >
        {t({id: 'Export'})}
      </MenuButton>
    </ReadOnly>
  );
};

export default ExportZoning;
