import type {ChartDataSets} from 'chart.js';
import type {FC} from 'react';
import React from 'react';
import {Line} from 'react-chartjs-2';
import {isDefined} from '_utils/typeGuards';

interface LineChartProps {
  data: {labels: string[] | number[]; datasets: ChartDataSets[]};
}

export const LineChart: FC<LineChartProps> = ({data}) => (
  <Line
    data={data}
    height={200}
    options={{
      legend: {
        position: 'bottom',
        align: 'start',
        labels: {
          usePointStyle: true,
          padding: 20,
          fontSize: 12,
          boxWidth: 5,
          generateLabels: chart => {
            const datasets = chart.config.data?.datasets ?? [];

            return datasets.map(dataset => ({
              text: dataset.label,
              fillStyle: dataset.borderColor as string,
              strokeStyle: dataset.borderColor as string,
            }));
          },
        },
      },
      scales: {
        xAxes: [
          {
            ticks: {
              beginAtZero: false,
              padding: 10,
            },
            gridLines: {
              drawOnChartArea: false,
              drawTicks: false,
            },
          },
        ],
        yAxes: [
          {
            ticks: {
              min: 0,
              max: 100,
              beginAtZero: true,
              maxTicksLimit: 10,
              padding: 5,
            },
            gridLines: {
              drawTicks: false,
            },
          },
        ],
      },
      tooltips: {
        callbacks: {
          label: (props, tooltipData) => {
            const {datasetIndex, value} = props;

            return isDefined(datasetIndex) && isDefined(tooltipData.datasets?.[datasetIndex].label)
              ? `${tooltipData.datasets?.[datasetIndex].label}: ${value}%`
              : `${value}`;
          },
        },
        displayColors: false,
      },
    }}
  />
);
