import {t, FormattedMessage} from 'i18n-utils';
import React from 'react';
import {NrxApi} from '_api';
import type {MeasurementSystem} from '_utils';
import {formatDate, formatUnit, convertUnit, clamp, isAustralianField} from '_utils';
import {toFixedFloatUnsafe} from '_utils/number-formatters';
import {capitalizeFirstLetter} from '_utils/pure-utils';
import {getTypedKeys} from '_utils/object';
import type {AppDispatch} from 'store';
import type {Field, Season} from '../../types';
import {Tags as PremiumAppTag} from 'containers/admin/features/types';
import {GLOBAL_FORMAT_DATE} from '_constants';
import moment from 'moment';
import {ActionTypes} from '../../reducer/types';
import {Flex, FluroStatusCircle, InfoBlock} from 'components';
import type {AppearanceValues} from 'components/info-block/types';
import type {AppStore} from 'reducers';
import {AsyncStatusType, setRequestStatus, Status} from '../../../../modules/helpers';
import {checkSeasonTagExist} from '../../utils';
import type {
  NRxSeason,
  NRxRecomendationSettings,
  FertilizerApplication,
  SoilType,
  NRxFertilizerListItem,
  NRxResultsResponse,
  NRxObjectiveType,
} from 'containers/map/features/nrx';
import {
  NRxCropTypes,
  defaultNrxSeason,
  NrxCropTypeLabels,
  riskLevelToLabel,
  NRxAutomaticSoilTypeId,
} from 'containers/map/features/nrx';
import {toggleTooltip} from 'modules/global/actions';
import {FontIcon} from 'react-md';
import {selectCurrentField, selectCurrentSeasonId, selectMapFields} from '../../reducer/selectors';
import {
  selectNRecommendation,
  selectNrxFertilizerListItemData,
  selectNrxSeason,
  selectNRxCropTypeBySubtype,
  selectNRxSoilTypeRecordsBySoilTypeId,
  selectNRxListsData,
  selectNRxFertilizersRecordsByTypeId,
} from 'containers/map/features/nrx/nrx-selectors';

import {selectMeasurement} from '../../../login/login-selectors';
import type {Measurement} from '../../../login/types';
import {reportError} from 'containers/error-boundary';

/// constants

export const getDefaultNSettingValues = (
  nrxSeason?: NRxSeason,
  measurement: Measurement = 'ha',
  productData?: NRxFertilizerListItem
): NRxRecomendationSettings => {
  let additionalProps: Partial<NRxRecomendationSettings> = {};
  let crop_price = 250;
  let N_price = 500;

  if (nrxSeason) {
    const isUS = measurement !== 'ha';

    crop_price = isUS ? getDefaultCropPrice(Number(nrxSeason.cropID)) : crop_price;
    N_price = isUS ? 365 : N_price;

    if (nrxSeason.roiSettings) {
      additionalProps = {
        ...additionalProps,
        ...convertUSValues(
          {
            ...nrxSeason.roiSettings,
            crop_price: toFixedFloatUnsafe(
              nrxSeason.roiSettings.crop_price,
              measurement === 'ha' ? 0 : 1
            ),
          },
          Number(nrxSeason.cropID),
          'ha'
        ),
        historical_yield_avg: classifyYieldGoal(
          Number(nrxSeason.roiSettings.historical_yield_avg),
          measurement,
          Number(nrxSeason.cropID)
        ),
        isLiquid: !!productData?.isLiquid,
        specific_gravity: productData?.specific_gravity || 1,
      };
    }
  }

  return {
    crop_price,
    N_price,
    n_percentage: 46,
    season_outlook: 10,
    N_constrain: 0,
    ROI_setting: 2,
    product: 'Urea',
    isLiquid: false,
    specific_gravity: 1,
    historical_yield_avg: 0,
    ...additionalProps,
  };
};

const metricRatio = 0.907185;

export const convertUSValues = (
  data: Partial<NRxRecomendationSettings>,
  cropType: number,
  measurement: string,
  forRequest = false
): Partial<NRxRecomendationSettings> => {
  const resultData = {...data};
  if (measurement !== 'ha') {
    resultData.crop_price = covertCropPriceFromUS(cropType, Number(resultData.crop_price));
    resultData.N_price = toFixedFloatUnsafe(Number(resultData.N_price) * metricRatio, 0);
    resultData.N_constrain = clamp(0, Number(resultData.N_constrain) * metricRatio, 1000);
  }

  if (resultData.min_yield_goal !== undefined) {
    resultData.min_yield_goal = toFixedFloatUnsafe(resultData.min_yield_goal || 0, 0);
    resultData.max_yield_goal = toFixedFloatUnsafe(resultData.max_yield_goal || 0, 0);
  }
  // quick win, will be replaced by form in n-recommendation component
  resultData.N_price = toFixedFloatUnsafe(resultData.N_price || 0, 0);
  resultData.N_constrain = toFixedFloatUnsafe(resultData.N_constrain || 0, 0);
  resultData.crop_price = toFixedFloatUnsafe(resultData.crop_price || 0, forRequest ? 0 : 1);

  return resultData;
};

/**
 * convertNRxSeasonValues converts US values to Metric and vise-versa (if needs)
 */
export const convertNRxSeasonValues = (
  data: Partial<NRxSeason>,
  fromServer = false,
  measurement: Measurement
): Partial<NRxSeason> => {
  const defaultDigitsNumber = measurement === 'ha' ? 1 : 0;

  const propsToConvert = [
    {prop: 'sowingDepth', units: 'mm'},
    {prop: 'sowingDensity', units: 'm2'},
    {prop: 'rowSpacing', units: 'mm'},
  ] as {prop: keyof NRxSeason; units: string}[];

  propsToConvert.forEach(({prop, units}) => {
    if (data[prop] === undefined) return;

    const numberDecimals =
      ['sowingDensity'].includes(prop) && !fromServer ? 6 : defaultDigitsNumber; // for accuracy, convert the values with 6 decimals
    data[prop] = data[prop]
      ? // @ts-expect-error error leftover from convertion to strict mode, please fix
        toFixedFloatUnsafe(convertUnit(measurement, units, data[prop], !fromServer), numberDecimals)
      : data[prop];
  });

  return data;
};

/**
 * prepareFertilizers filter fertilizer list from duplicates and convert quantity value (if needs). FSB-3251
 */

export const convertFertilizerValues = (
  fertilizer: FertilizerApplication,
  fromServer = false,
  measurement: Measurement,
  isLiquid: boolean
) => {
  fertilizer.quantity = toFixedFloatUnsafe(
    convertUnit(measurement, isLiquid ? 'gal/ac' : 'lb/ac', fertilizer.quantity, !fromServer),
    0
  );
  return fertilizer;
};

const prepareFertilizers =
  (fertilizers: FertilizerApplication[], measurement: Measurement) =>
  (dispatch: AppDispatch, getState: () => AppStore) => {
    const fertilizersByTypeId = selectNRxFertilizersRecordsByTypeId(getState());
    const fertilizerIds: number[] = [];
    return fertilizers
      .filter(f => {
        if (!fertilizerIds.includes(Number(f.id))) {
          fertilizerIds.push(Number(f.id));
          return true;
        }
        return false;
      })
      .map(f => {
        if (measurement === 'ha') return f;
        return convertFertilizerValues(
          f,
          true,
          measurement,
          Boolean(fertilizersByTypeId[Number(f?.typeID)]?.isLiquid)
        );
      });
  };

/// requests

export const loadNrxData = () => async (dispatch: AppDispatch, getState: () => AppStore) => {
  const state = getState();
  const fields = selectMapFields(state);
  const measurement = selectMeasurement(state);
  const currentSeasonId = selectCurrentSeasonId(state);

  if (
    fields.some(
      // (f: Field) => checkFieldTagExist(PremiumAppTag.NRx, f)
      (f: Field) => f.Seasons?.find(s => checkSeasonTagExist(PremiumAppTag.NRx, s) && !s.nrx)
    )
  ) {
    const australianFieldCoordinates = fields
      .filter((f: Field) => f.Country === 'Australia' && doesFieldHasSeasonWithNRx(f))
      .map(f => ({
        id: f.ID,
        coords: [Number(f.SouthLat), Number(f.WestLon)],
      }));

    dispatch(setRequestStatus(AsyncStatusType.NRxListsData, Status.Pending));
    await dispatch(loadListsData(australianFieldCoordinates));

    // @ts-expect-error error leftover from convertion to strict mode, please fix // learn how to properly handle Dispatch's promise
    return dispatch(loadNrxSeasonsData()).then((fields: Field[]) => {
      dispatch(setRequestStatus(AsyncStatusType.NRxListsData, Status.Done));
      const nrxSeason = selectNrxSeason(state, currentSeasonId, fields);
      const productData = selectNrxFertilizerListItemData(
        getState(),
        String(nrxSeason?.roiSettings?.product)
      );

      dispatch({
        type: ActionTypes.MAP_NRX_LOAD_SEASONS_DATA,
        fields,
        nrxRecommendationSettings: getDefaultNSettingValues(nrxSeason, measurement, productData),
      });
    });
  }
  return Promise.resolve();
};

export const loadListsData =
  (australianFieldCoordinates: {id: number; coords: number[]}[]) =>
  (dispatch: AppDispatch, getState: () => AppStore) => {
    const listsData = selectNRxListsData(getState());

    const requests: Array<Promise<any>> = [];

    if (!listsData.fertilizerTypes.length) {
      // @ts-expect-error error leftover from convertion to strict mode, please fix // TS doesn't understand next line
      requests.push(dispatch(loadFertilizerTypes()));
    }

    if (!listsData.cropTypesList.length) {
      // @ts-expect-error error leftover from convertion to strict mode, please fix // TS doesn't understand next line
      requests.push(dispatch(loadCropTypes()));
    }

    if (
      (Object.keys(listsData.fieldsNearestSoilTypesList).length &&
        !australianFieldCoordinates.length) || // request a full soilTypes list, the list for nearest fields includes only 10 types
      !listsData.soilTypesList.length || // data wasn't loaded
      australianFieldCoordinates.length // there is a need to load closest soils
    ) {
      // @ts-expect-error error leftover from convertion to strict mode, please fix // TS doesn't understand next line
      requests.push(dispatch(loadSoilParams(australianFieldCoordinates)));
    }
    return Promise.all(requests);
  };

export const loadCropTypes = () => (dispatch: AppDispatch) => {
  return NrxApi.getCropTypes().then(({data}) => {
    const prepareCropObjects = data.result.map((cropType: any) => ({
      value: cropType.id,
      label: capitalizeFirstLetter(cropType.name),
      variety: cropType.variety.map((variety: any) => ({
        value: variety.id,
        label: variety.name,
      })),
    }));

    const result = {
      cropTypesList: prepareCropObjects.filter((cT: any) =>
        currentlyAllowedCropTypes.includes(cT.label.toLowerCase())
      ),
      prevCropTypesList: prepareCropObjects.filter((cT: any) =>
        currentlyAllowedPrevCropTypes.includes(cT.label.toLowerCase())
      ),
    };

    dispatch({
      type: ActionTypes.MAP_NRX_SET_LISTS_DATA,
      lists: result,
    });
  });
};

export const loadSoilParams =
  (australianFieldCoordinates: {id: number; coords: number[]}[]) => (dispatch: AppDispatch) => {
    return (
      australianFieldCoordinates.length
        ? NrxApi.getNearestSoilType(australianFieldCoordinates) // load nearest (suggested) soils per field
        : NrxApi.getSoilType()
    )
      .then(({data}) => {
        dispatch({
          type: ActionTypes.MAP_NRX_SET_LISTS_DATA,
          lists: modifySoilParams(data.result),
        });
      })
      .catch(err => {
        if (australianFieldCoordinates.length) {
          dispatch(loadSoilParams([])); // provide empty list of AUS fields to call NrxApi.getSoilType instead of NrxApi.getNearestSoilType as a callback
        } else {
          reportError(err);
        }
      });
  };

const modifySoilParams = (data: {fields?: Record<number, number[]>; soilType: SoilType[]}) => {
  const {soilType, fields = {}} = data;

  return {
    fieldsNearestSoilTypesList: Object.keys(fields).reduce(
      (result, key) => ({
        ...result,
        [key]: prepareSoilMenuLists(
          soilType.filter((soilType: SoilType) => fields[Number(key)].includes(soilType.apsoilID))
        ),
      }),
      {}
    ),
    soilTypesRawList: soilType,
    soilTypesList: prepareSoilMenuLists(soilType),
  };
};

const prepareSoilMenuLists = (list: SoilType[]) => {
  return list.reduce(
    (result: Array<{value: string; label: string}>, soilT: SoilType) =>
      result.find(v => v.label === soilT.type)
        ? result
        : [
            ...result,
            {
              value: soilT.type,
              label: soilT.type,
            },
          ],
    [{value: 'Automatic soil selection', label: t({id: 'Automatic soil selection'})}]
  );
};

const prepareFertilizerDosesList = (
  list: number[],
  measurement: MeasurementSystem,
  isLiquid: boolean
) =>
  list.map(d => ({
    value: d,
    label: `${toFixedFloatUnsafe(
      convertUnit(measurement, isLiquid ? 'gal/ac' : 'lb/ac', d),
      0
    )} ${formatUnit(measurement, isLiquid ? 'l / ha' : 'kg / ha')}`,
  }));

export const getPreparedNRxRequestData = (state: AppStore, nrxSeason: NRxSeason) => {
  const {nrxRecommendationSettings, nrxTabRate} = selectNRecommendation(state);
  const measurement = selectMeasurement(state);
  const field = selectCurrentField(state);

  const preparedData: Partial<NRxRecomendationSettings> & {
    md5: string;
    season_startdate: string;
    nrx_season_id: number;
    blanket_rate: boolean;
  } = {
    crop_price: nrxRecommendationSettings.crop_price,
    N_price: nrxRecommendationSettings.N_price,
    n_percentage: nrxRecommendationSettings.n_percentage,
    season_outlook: nrxRecommendationSettings.season_outlook,
    N_constrain: nrxRecommendationSettings.N_constrain,
    ROI_setting: nrxRecommendationSettings.ROI_setting,
    specific_gravity: nrxRecommendationSettings.specific_gravity,
    recommendation_date:
      nrxRecommendationSettings.recommendation_date ||
      getLastAvailableRecommendationDate(nrxSeason.recommendationDates),
    md5: field.MD5,
    season_startdate: moment(nrxSeason.startDate, GLOBAL_FORMAT_DATE).format(GLOBAL_FORMAT_DATE),
    nrx_season_id: nrxSeason.nrxSeasonID,
    isLiquid: nrxRecommendationSettings.isLiquid,
    blanket_rate: nrxTabRate === 'blanket',
    historical_yield_avg: classifyYieldGoal(
      Number(nrxRecommendationSettings.historical_yield_avg),
      measurement,
      Number(nrxSeason.cropID),
      true
    ),
  };

  return convertUSValues(preparedData, Number(nrxSeason.cropID), measurement, true);
};

export const loadFertilizerTypes = () => (dispatch: AppDispatch, getState: () => AppStore) => {
  const measurement = selectMeasurement(getState());

  return NrxApi.getFertilizerParams()
    .then(({data}) => {
      const dosesRawList = data.result?.doseApplied || [];
      const result = {
        fertilizerTypes: [
          ...(data.result?.types || []).map((t: any) => ({
            typeID: t.id,
            type: t.name,
            nitrogenPercentage: t.nitrogen_percentage,
            isLiquid: t.liquid,
            specific_gravity: t.specific_gravity,
          })),
          {
            typeID: 0,
            type: t({id: 'Other (solid)'}),
            nitrogenPercentage: 0,
          },
        ],
        fertilizerDosesList: prepareFertilizerDosesList(dosesRawList, measurement, false),
        liquidFertilizerDosesList: prepareFertilizerDosesList(dosesRawList, measurement, true),
      };

      dispatch({
        type: ActionTypes.MAP_NRX_SET_LISTS_DATA,
        lists: result,
      });
    })
    .catch();
};

export const loadNrxSeasonsData = () => (dispatch: AppDispatch, getState: () => AppStore) => {
  const state = getState();
  const fields = selectMapFields(state);
  const measurement = selectMeasurement(state);
  const soilTypeRecordsBySoilTypeId = selectNRxSoilTypeRecordsBySoilTypeId(state);

  return NrxApi.getNRxSeasons(
    fields
      .flatMap(f => f?.Seasons?.filter(s => checkSeasonTagExist(PremiumAppTag.NRx, s)))
      .map(s => s?.id)
      .join(',')
  ).then(({data}) => {
    return fields.map((currentF: Field) => {
      const fieldHasNRxSeason = doesFieldHasSeasonWithNRx(currentF);
      const isAustralian = isAustralianField(currentF);

      if (fieldHasNRxSeason) {
        return {
          ...currentF,
          Seasons: currentF?.Seasons?.map(currentS => {
            const nrxSeason = checkSeasonTagExist(PremiumAppTag.NRx, currentS)
              ? data.result.find((s: NRxSeason) => s.seasonID === currentS.id) ||
                classifyDefaultNRxSeason(currentS)
              : null;

            if (nrxSeason) {
              if (nrxSeason.seasonID) {
                nrxSeason.cropID = selectNRxCropTypeBySubtype(
                  state,
                  nrxSeason.cropVarietyID
                )?.value;
                nrxSeason.soilTypeID = nrxSeason.soilTypeID
                  ? nrxSeason.soilTypeID
                  : isAustralian
                  ? NRxAutomaticSoilTypeId //Automatic soil detection
                  : 0;

                nrxSeason.soilTypeLabelID = soilTypeRecordsBySoilTypeId[nrxSeason.soilTypeID]?.type;
                nrxSeason.prevHarvestDate =
                  nrxSeason.prevHarvestDate ||
                  moment(currentS.startDate, GLOBAL_FORMAT_DATE).subtract(3, 'months').format();

                nrxSeason.recommendationDates = classifyNRxSeasonRecommendationDates(nrxSeason);
              }

              nrxSeason.irrigated = classifyNRxSeasonIrrigation(currentF, nrxSeason);
              nrxSeason.fertilizerApplications = dispatch(
                prepareFertilizers(
                  nrxSeason.fertilizerApplications || nrxSeason.fertiliserApplications,
                  measurement
                )
              );

              return {
                ...currentS,
                nrx: {...nrxSeason, ...convertNRxSeasonValues(nrxSeason, true, measurement)},
              };
            } else {
              return currentS;
            }
          }),
        };
      }
      return currentF;
    });
  });
};

const classifyNRxSeasonRecommendationDates = (season: NRxSeason) => {
  const seasonStartDate = season.nrxSeasonStartDate || season.startDate;
  const seasonEndDate = season.nrxSeasonEndDate || season.endDate;

  const momentStartDate = moment(seasonStartDate, GLOBAL_FORMAT_DATE);
  const momentEndDate = moment(seasonEndDate, GLOBAL_FORMAT_DATE);

  const recommendationDates = season.recommendationDates || [];

  return recommendationDates.filter(
    // we might have recommendation date out of the season, filter such dates
    (date: string) => {
      return moment(date).isBetween(momentStartDate, momentEndDate, undefined, '[]'); //  '[]' - means including the start/end dates too}
    }
  );
};

export const checkForPreparedSeasons =
  (seasonsIds: Array<number> | number, seasonData: Partial<NRxSeason>) =>
  (dispatch: AppDispatch, getState: () => AppStore) => {
    const state = getState();
    return Array.isArray(seasonsIds)
      ? seasonsIds.every(seasonId =>
          isAllNrxSeasonPropsSet(selectNrxSeason(state, seasonId), seasonData)
        )
      : isAllNrxSeasonPropsSet(selectNrxSeason(state, seasonsIds), seasonData);
  };

export const isAllNrxSeasonPropsSet = (season: NRxSeason, seasonData: Partial<NRxSeason>) => {
  const seasonToCheck: NRxSeason = {...season, ...seasonData};
  if (season.nrxSeasonID) return true; // having nrxSeasonID - means all properties already set
  return [
    'sowingDensity',
    'sowingDepth',
    'rowSpacing',
    'soilTypeID',
    'cropVarietyID',
    'prevCropTypeID',
    'prevHarvestDate',
    'soilTypeLabelID',
  ].every(prop => Boolean(seasonToCheck[prop as keyof NRxSeason]));
};

export const checkSeasonStatus = (season: NRxSeason, action?: any, infoBlock = false) => {
  if (!season) return {};
  const allPropertiesSet = Boolean(
    isSetNrxSeasonCropMatch(season) &&
      isSetNrxSeasonSowingData(season) &&
      season.fertilizerApplications.length
  );
  const sowingDataMissing = isSetNrxSeasonCropMatch(season) && !isSetNrxSeasonSowingData(season);
  const fertilizerDataMissing =
    isSetNrxSeasonCropMatch(season) &&
    isSetNrxSeasonSowingData(season) &&
    !season.fertilizerApplications.length;

  const pleaseAddSettings = action ? (
    <span>
      , please{' '}
      <span onClick={action} className={'global-link'}>
        add
      </span>
    </span>
  ) : null;

  const InfoBlockContainer = ({
    text,
    action = true,
    appearance = 'warning',
  }: {
    text: string;
    action?: boolean;
    appearance?: AppearanceValues;
  }) => {
    return (
      <InfoBlock className={'nrx-season-status tab-info-block'} appearance={appearance}>
        {text}
        {action && pleaseAddSettings}
      </InfoBlock>
    );
  };

  const RegularStatusContainer = ({
    text,
    action = true,
    icon,
  }: {
    text: string;
    action?: boolean;
    icon: React.ReactElement;
  }) => {
    return (
      <div className={'season-status'}>
        <Flex gap="10px" alignItems="center" nowrap>
          {icon}
          {text}
        </Flex>
        {action && pleaseAddSettings}
      </div>
    );
  };

  if (sowingDataMissing)
    return infoBlock ? (
      <InfoBlockContainer text={t({id: 'Missing soil settings'})} />
    ) : (
      <RegularStatusContainer
        text={t({id: 'Missing soil settings'})}
        icon={<FluroStatusCircle status={'yellow'} />}
      />
    );

  if (fertilizerDataMissing)
    return infoBlock ? (
      <InfoBlockContainer text={t({id: 'Missing fertilizer application'})} />
    ) : (
      <RegularStatusContainer
        text={t({id: 'Missing fertilizer application'})}
        icon={<FluroStatusCircle status={'yellow'} />}
      />
    );

  if (!allPropertiesSet)
    return infoBlock ? (
      <InfoBlockContainer text={t({id: 'Missing settings'})} />
    ) : (
      <RegularStatusContainer
        text={t({id: 'Missing settings'})}
        icon={<FluroStatusCircle status={'yellow'} />}
      />
    );

  if (allPropertiesSet && !season.recommendationDates.length)
    return infoBlock ? (
      <InfoBlockContainer text={t({id: 'Processing'})} appearance={'processing'} action={false} />
    ) : (
      <RegularStatusContainer
        text={t({id: 'Processing'})}
        icon={<FluroStatusCircle status={'gray'} />}
        action={false}
      />
    );

  if (allPropertiesSet && season.recommendationDates.length)
    return infoBlock ? null : (
      <Flex gap="10px" className={'season-status'}>
        <FluroStatusCircle status={'green'} />
        <span>
          Results ready {!action && <br />}{' '}
          <span className={'date'}>{`${moment(
            getLastAvailableRecommendationDate(season.recommendationDates),
            GLOBAL_FORMAT_DATE
          ).format(formatDate())}`}</span>
        </span>
      </Flex>
    );

  return infoBlock ? (
    <InfoBlockContainer text={'Missing settings'} />
  ) : (
    <RegularStatusContainer
      text={'Missing settings'}
      icon={<FluroStatusCircle status={'yellow'} />}
    />
  );
};

const checkSeasons = (
  season: NRxSeason | NRxSeason[],
  checkFunc: (season: NRxSeason) => boolean
) => {
  // single season
  if (typeof season === 'object' && !Array.isArray(season)) return checkFunc(season);
  // array of season ids
  else if (Array.isArray(season) && season.every(s => typeof s === 'object'))
    return season.every(s => checkFunc(s)); // array of seasons
};

export const isSetNrxSeasonCropMatch = (season: NRxSeason) => {
  return checkSeasons(season, (season: NRxSeason) =>
    Boolean(season.nrxSeasonID || season.cropVarietyID)
  );
};

export const isSetNrxSeasonSowingData = (season: NRxSeason) => {
  const checkFunc = (season: NRxSeason) => {
    if (season.nrxSeasonID) return true; // having nrxSeasonID - means all properties already set

    return [
      'sowingDensity',
      'sowingDepth',
      'rowSpacing',
      'soilTypeID',
      'prevCropTypeID',
      'prevHarvestDate',
      'soilTypeLabelID',
    ].every(prop => season[prop as keyof NRxSeason]);
  };

  return checkSeasons(season, checkFunc);
};

export const getPropertiesFromSeasons = (seasons: Array<NRxSeason> = []) => {
  const result: any = {};
  if (Array.isArray(seasons)) {
    if (seasons.length === 1) return seasons[0];
    seasons.forEach((season, i, arr) => {
      getTypedKeys(season).forEach(key => {
        result[key] = result[key]
          ? result[key].includes(season[key])
            ? result[key]
            : [...result[key], season[key]]
          : [season[key]];

        if (arr.length === i + 1 && result[key].length === 1) {
          result[key] = result[key][0];
        }
      });
    });
  }
  return result;
};

export const prepareSeasonUpdateRequests = (
  nrxSeasons: NRxSeason[],
  data: Partial<NRxSeason>,
  measurement: Measurement
) => {
  return nrxSeasons.map(season => {
    const payloadData = convertNRxSeasonValues({...season, ...data}, false, measurement);
    return isNrxSeasonExist(season)
      ? NrxApi.updateSeasonNrxData(payloadData)
      : NrxApi.setSeasonNrxData(payloadData);
  });
};

export const isNrxSeasonExist = (s: NRxSeason) => {
  return s && s.nrxSeasonID;
};

export const getPrevSeasonMaxHarvestDate = (
  seasonStartDate: Array<string> | string,
  doNotSubtract: boolean = false
) => {
  const latestDate = moment(
    Array.isArray(seasonStartDate)
      ? seasonStartDate.reduce(
          (latestDate: string, date: string) =>
            moment(latestDate, GLOBAL_FORMAT_DATE).isAfter(moment(date, GLOBAL_FORMAT_DATE))
              ? latestDate
              : date,
          seasonStartDate[0]
        ) // get latest day from array
      : seasonStartDate
  );

  return doNotSubtract
    ? latestDate.format(GLOBAL_FORMAT_DATE)
    : latestDate.subtract(1, 'day').format(GLOBAL_FORMAT_DATE);
};

export const getLastAvailableRecommendationDate = (dates: Array<string> = []): string => {
  return dates.reduce(
    (lastDate: string, d) =>
      !lastDate || moment(lastDate, GLOBAL_FORMAT_DATE).isBefore(moment(d, GLOBAL_FORMAT_DATE))
        ? d
        : lastDate,
    ''
  );
};

export const prepareApplication = (
  application: FertilizerApplication,
  measurement: Measurement,
  isLiquid: boolean
) => {
  const {date, seasonID, fieldID, quantity, option, typeID, nitrogenPercentage, nrxSeasonID} =
    application;
  const isCustom = typeID === 0;
  const result = {
    date,
    seasonID,
    fieldID,
    quantity:
      measurement === 'ha'
        ? quantity
        : convertFertilizerValues(application, false, measurement, isLiquid).quantity,
    option,
    nitrogenPercentage,
    [isCustom ? 'nitrogenPercentage' : 'typeID']: isCustom ? nitrogenPercentage : typeID, // if it is custom product ({type: Other, typeID: 0}) send nitrogenPercentage otherwise send onl;y typeID
  };
  if (application.id) result.id = application.id;
  if (!isCustom) result.typeID = typeID;
  if (nrxSeasonID) result.nrxSeasonID = nrxSeasonID;

  return result;
};

/**
 * areFieldsInAustralia check are fields are in Australia
 * @param ids = field ids to check
 */

/// APSIM Crop types
const currentlyAllowedCropTypes = ['wheat', 'potato', 'cotton', 'sorghum', 'maize', 'barley'];
const currentlyAllowedPrevCropTypes = [
  'barley',
  'canola',
  'chickpea',
  'irrigated cotton',
  'rainfed cotton',
  'maize',
  'mungbean',
  'oats',
  'peanut',
  'potato',
  'sorghum',
  'wheat',
  'other',
  'soybeans',
];

export const getCropPriceUnit = (cropID: number) => {
  const cropLabel = getNrxCropLabelById(cropID);
  switch (cropLabel) {
    case 'Wheat':
    case 'Maize':
    case 'Sorghum':
    case 'Barley':
      return 'bu';
    case 'Potato':
      return 'cwt';
    case 'Cotton':
      return 'lb';

    default:
      return 'T';
  }
};

const covertCropPriceFromUS = (cropID: number, priceValue: number) => {
  let resultPrice = priceValue;
  const cropLabel = getNrxCropLabelById(cropID);
  if (['Wheat', 'Barley', 'Maize', 'Sorghum'].includes(cropLabel)) {
    const lb_per_bu = cropLabel === 'Wheat' ? 60 : cropLabel === 'Barley' ? 48 : 58;
    resultPrice = priceValue * (1000 / (lb_per_bu * 0.453592));
  } else if (cropLabel === 'Potato') {
    resultPrice = priceValue * 22.0462;
  } else if (cropLabel === 'Cotton') {
    resultPrice = priceValue * 2204.62;
  }
  return toFixedFloatUnsafe(resultPrice);
};

// const covertCropPriceToUS = (cropID: number, priceValue: number) => {
//   let resultPrice = priceValue;
//   const cropLabel = getNrxCropLabelById(cropID);
//   if (['Wheat', 'Barley', 'Maize', 'Sorghum'].includes(cropLabel)) {
//     const lb_per_bu = cropLabel === 'Wheat' ? 60 : cropLabel === 'Barley' ? 48 : 58;
//     resultPrice = priceValue / (1000 / (lb_per_bu * 0.453592));
//   } else if (cropLabel === 'Potato') {
//     resultPrice = priceValue / 22.0462;
//   } else if (cropLabel === 'Cotton') {
//     resultPrice = priceValue / 2204.62;
//   }
//   return toFixedFloat(resultPrice, 0);
// };

export const getNrxCropLabelById = (cropId: number) => NrxCropTypeLabels[cropId] || '';

const getDefaultCropPrice = (cropID: number): number => {
  switch (
    cropID // FSB-3012 (comments)
  ) {
    case NRxCropTypes.Maize:
      return 4;
    case NRxCropTypes.Sorghum:
      return 3.5;
    case NRxCropTypes.Cotton:
      return 0.6;
    case NRxCropTypes.Potato:
      return 9;
    case NRxCropTypes.Wheat:
      return 5;
    case NRxCropTypes.Other:
      return 5;
    case NRxCropTypes.Barley:
      return 5;
    default:
      return 5;
  }
};
/// APSIM crop types end  ////
export const checkResponseForHighRoi = (response: NRxResultsResponse) => {
  return getTypedKeys(response).some(
    (objective: NRxObjectiveType) =>
      response[objective].map.features?.length > 1 ||
      response[objective].map.features[0]?.properties?.val > 0
  );
};

export const getTotalNitrogenFromValue = (
  // calculates total "pure" nitrogen for a zone
  value: number,
  nitrogenPercent: number,
  isLiquid: boolean,
  specificGravity: number,
  measurement: string
) => {
  if (value === 0) return '-';
  let resultValue = (value * (nitrogenPercent / 100)) as any; // get "clear" nitrogen amount

  if (measurement === 'ac' && isLiquid) {
    // make a correction for the US units
    resultValue = resultValue * 9.35396 * 0.892179;
  }

  resultValue = toFixedFloatUnsafe(isLiquid ? resultValue * specificGravity : resultValue, 0); // add modifications related to specific gravity

  return resultValue;
};

export const convertProductToTon = (
  productSpecificGravity: number,
  productValue: number,
  productMeasure: string
) => {
  switch (productMeasure) {
    case 'kg':
    case 'l':
      return toFixedFloatUnsafe((productValue * productSpecificGravity) / 1000, 1);
    case 'lb':
      return toFixedFloatUnsafe(productValue / 2000, 1);
    case 'gal':
      return toFixedFloatUnsafe(((productValue * productSpecificGravity) / 1000) * 1.10231, 1);
    default:
      return toFixedFloatUnsafe(productValue / 1000, 1);
  }
};

export const classifyYieldGoal = (
  value: number,
  measurement: string,
  cropId: number,
  reverse?: boolean // from settings pop-up
) => {
  if (!value) return value;

  if (measurement === 'ha') {
    switch (cropId) {
      case NRxCropTypes.Maize:
      case NRxCropTypes.Sorghum:
      case NRxCropTypes.Wheat:
      case NRxCropTypes.Barley:
        return reverse ? toFixedFloatUnsafe(value * 1000, 1) : toFixedFloatUnsafe(value / 1000, 1);

      case NRxCropTypes.Potato:
        return reverse ? toFixedFloatUnsafe(value * 1000, 1) : toFixedFloatUnsafe(value / 1000, 1); // convert to Tones

      case NRxCropTypes.Cotton:
        return toFixedFloatUnsafe(value, 0);
    }
  } else {
    switch (cropId) {
      case NRxCropTypes.Maize:
      case NRxCropTypes.Sorghum:
        return reverse
          ? toFixedFloatUnsafe(value / 0.0159, 0)
          : toFixedFloatUnsafe(value * 0.0159, 0);

      case NRxCropTypes.Wheat:
        return reverse
          ? toFixedFloatUnsafe(value / 0.0149, 0)
          : toFixedFloatUnsafe(value * 0.0149, 0);

      case NRxCropTypes.Barley:
        return reverse
          ? toFixedFloatUnsafe(value / 0.0186, 0)
          : toFixedFloatUnsafe(value * 0.0186, 0);

      case NRxCropTypes.Potato:
        return reverse
          ? toFixedFloatUnsafe(value / 0.00796589, 0)
          : toFixedFloatUnsafe(value * 0.00796589, 0);

      case NRxCropTypes.Cotton:
        return reverse
          ? toFixedFloatUnsafe(value / 0.892179, 0)
          : toFixedFloatUnsafe(value * 0.892179, 0);
    }
  }
};

export const classifyYieldUnits = (measurement: string, cropId: number) => {
  if (measurement === 'ha') {
    switch (cropId) {
      case NRxCropTypes.Maize:
      case NRxCropTypes.Sorghum:
      case NRxCropTypes.Wheat:
      case NRxCropTypes.Barley:
        return t({id: 'T/ha'});

      case NRxCropTypes.Potato:
        return t({id: 'T/ha'});

      case NRxCropTypes.Cotton:
        return t({id: 'kg/ha'});
    }
  } else {
    switch (cropId) {
      case NRxCropTypes.Maize:
      case NRxCropTypes.Sorghum:
      case NRxCropTypes.Wheat:
      case NRxCropTypes.Barley:
        return t({id: 'bu/ac'});

      case NRxCropTypes.Potato:
        return t({id: 'cwt/ac'});

      case NRxCropTypes.Cotton:
        return t({id: 'lb/ac'});
    }
  }
};

export const NRxAutomaticallyChangedROISettingsMessage = (
  prevROIValue: number,
  currentROIValue: number,
  displayNRxSettings: () => void
) => (
  <FormattedMessage
    id="NRxAutomaticallyChangedROISettingsMessage"
    defaultMessage="We couldn’t find a {prevRiskLabel} risk nitrogen application with <span>these settings.</span> {currentRiskLabel} risk application is shown instead."
    values={{
      prevRiskLabel: riskLevelToLabel(prevROIValue).toLowerCase(),
      currentRiskLabel: riskLevelToLabel(currentROIValue),
      span: (txt: string) => (
        <span className={'global-link'} onClick={displayNRxSettings}>
          {txt}
        </span>
      ),
    }}
  />
);

/**
 * if the irrigated prop is set - use it, otherwise calc default value - FSB-4652
 */
export const classifyNRxSeasonIrrigation = (field: Field, season: NRxSeason) => {
  return season.nrxSeasonID && season.irrigated !== undefined
    ? season.irrigated
    : field.Pivot || season.cropID === NRxCropTypes.Potato;
};

export const doesFieldHasSeasonWithNRx = (field: Field) => {
  // return !!checkFieldTagExist(PremiumAppTag.NRx, field);
  return !!field?.Seasons?.find(s => checkSeasonTagExist(PremiumAppTag.NRx, s));
};

export const doesFieldHasLoadedNRxSeason = (field: Field) => {
  return (
    !!field?.Seasons?.length &&
    field.Seasons.some(s => checkSeasonTagExist(PremiumAppTag.NRx, s) && s?.nrx)
  );
};

//

const classifyDefaultNRxSeason = (s: Season): NRxSeason => {
  return {
    ...defaultNrxSeason,
    cropType: s.cropType,
    cropSubtype: s.params?.cropSubType || '',
    startDate: s.startDate,
    endDate: s.endDate,
    kmlID: s.kmlId,
    seasonID: Number(s.id),
  };
};

export const NASSInfoIcon = (id: string, visible: boolean) => (dispatch: AppDispatch) => {
  return visible ? (
    <div
      className={'NASS-icon'}
      data-tip=""
      data-for={id}
      onMouseEnter={() =>
        dispatch(
          toggleTooltip({
            id,
            content: t({
              id: 'This planting parameter was estimated using the NASS API. This product is not endorsed or certified by NASS.',
            }),
            place: 'top',
          })
        )
      }
    >
      <FontIcon forceSize={20}>help_outline</FontIcon>
    </div>
  ) : null;
};

export const isAutomaticSoil = (label: string) =>
  ['Automatic soil detection', 'Automatic soil selection'].includes(label);
