import React, {useState} from 'react';
import {useAppSelector} from '_hooks';
import {selectIsAdmin} from 'containers/login/login-selectors';
import {Flex, FluroButton} from 'components';
import {PlanningReportTable} from './planning-report-table';
import {CreateReportPopup} from './create-report/create-report-popup';
import {SISectionContainer, SIPageTitle} from 'containers/si/si-styled-components';

export const PlanningReportList = () => {
  const [reportPopup, setReportPopup] = useState(false);
  const isAdmin = useAppSelector(selectIsAdmin);

  return (
    <SISectionContainer>
      <Flex justifyContent="space-between" className="mb-1" nowrap alignItems="center">
        <SIPageTitle>Scenario Modeling</SIPageTitle>
        {isAdmin && (
          <FluroButton primary raised onClick={() => setReportPopup(true)}>
            New Report
          </FluroButton>
        )}
      </Flex>
      <PlanningReportTable />
      {reportPopup && <CreateReportPopup onHide={() => setReportPopup(false)} />}
    </SISectionContainer>
  );
};
