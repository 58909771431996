import React, {useEffect, useState} from 'react';
import {useAppDispatch, useAppSelector} from '_hooks';
import {useParsedMatchParams} from '_hooks/use-parsed-match-params';
import {downloadFile} from '_utils/pure-utils';
import {CircularProgress} from 'react-md';
import {showNotification} from 'components/notification/notification';
import {fetchSISupplySheds} from 'containers/si/module/thunks';
import {
  selectSISupplySheds,
  selectSISupplyShedsListByProgramId,
} from 'containers/si/module/selectors';
import {selectIsLoading} from 'modules/helpers/selectors';
import {ActionType} from 'containers/si/module/types';
import {DataExportCard} from './components/data-export-card';
import SIApi from 'containers/si/api/si';
import {SIPageTitle, SISectionContainer} from 'containers/si/si-styled-components';

export const DataExportList = () => {
  const dispatch = useAppDispatch();
  const {programId} = useParsedMatchParams();
  const supplySheds = useAppSelector(selectSISupplySheds);
  const programSupplySheds = useAppSelector(s => selectSISupplyShedsListByProgramId(s, programId));
  const isLoading = useAppSelector(s =>
    selectIsLoading(s, [ActionType.FETCH_SI_SUPPLY_SHEDS, ActionType.FETCH_KPI_SUBSECTION_AREA])
  );
  const [downloadingCSV, setDownloadingCSV] = useState(false);

  useEffect(() => {
    if (!supplySheds[programId]) {
      dispatch(fetchSISupplySheds(programId));
    }
  }, [dispatch, programId, supplySheds]);

  if (isLoading) return <CircularProgress className={'progress'} id={'si-ap-areas-step'} />;

  const downloadCSV = async (
    reportName: string,
    supplyShedId: number,
    aggregationUnit: string,
    cropIds: number[],
    selectedYear: string
  ) => {
    setDownloadingCSV(true);
    try {
      const response = await SIApi.getSupplyShedCSV(
        programId,
        aggregationUnit,
        [supplyShedId],
        cropIds,
        selectedYear === 'all' ? undefined : Number(selectedYear)
      );

      const filename = `${new Date().toISOString().split('T')[0]}_${reportName}_${aggregationUnit}${
        selectedYear === 'all' ? '' : `_${selectedYear}`
      }.csv`;

      downloadFile(response.data, filename, 'text/csv');
    } catch (error) {
      showNotification({
        title: 'Error',
        message: `The ${reportName} report could not be downloaded.`,
        type: 'error',
      });
    }
    setDownloadingCSV(false);
  };

  return (
    <SISectionContainer>
      <SIPageTitle>Data Export</SIPageTitle>
      {programSupplySheds?.map(supplyShed => {
        return (
          <DataExportCard
            supplyShed={supplyShed}
            downloadCSV={downloadCSV}
            key={supplyShed.id}
            downloadingCSV={downloadingCSV}
          />
        );
      })}
    </SISectionContainer>
  );
};
