import {kiloFormatter, toFixedFloatUnsafe} from '_utils/number-formatters';
import type {
  MetricSocGhgStatDatum,
  SIAggLevel,
  SIAreaUnits,
  StateFP,
} from 'modules/sustainability-insights/types';
import groupBy from 'lodash/groupBy';
import reduce from 'lodash/reduce';
import {parseAreaId} from '../../../../../../modules/sustainability-insights/utils';

export const pctFormatter = (value: number): string => {
  const pctValue = Math.abs(value) < 1 ? toFixedFloatUnsafe(value, 2) : String(Math.round(value));
  return `${pctValue}%`;
};

export const numFormatter = (value: number, units = ''): string => {
  return `${kiloFormatter(value)} ${units}`;
};

export const valueFormatter = (value: number, units: SIAreaUnits, acceptZero = false): string => {
  if (!value && !(acceptZero && value === 0)) return '';
  if (units === 'pct') return pctFormatter(value);
  if (units === 'num') return numFormatter(value);
  return String(value);
};

export const formatValueTPerAcres = (value: number, units: SIAreaUnits) => {
  const verySmallNumber = Math.abs(value) < 0.01;
  return units === 'pct'
    ? pctFormatter(value)
    : value < 0 && verySmallNumber
    ? '< -0.01 t/ac'
    : value > 0 && verySmallNumber
    ? '> 0.01 t/ac'
    : numFormatter(value, 't/ac');
};

export const formatValueKgPerHa = (value: number, units: SIAreaUnits) => {
  const verySmallNumber = Math.abs(value) < 0.01;
  return units === 'pct'
    ? pctFormatter(value)
    : value < 0 && verySmallNumber
    ? '< -0.01 kg/ha'
    : value > 0 && verySmallNumber
    ? '> 0.01 kg/ha'
    : numFormatter(value, 'kg/ha');
};

export const getDefaultExtremaPerUnits = () => ({
  num: {
    min: Infinity,
    max: -Infinity,
  },
  pct: {
    min: Infinity,
    max: -Infinity,
  },
});

export const classifyExtremaPerUnitsFromValues = (
  values: {num: number[]; pct: number[]} = {num: [], pct: []}
) => {
  return {
    num: {
      min: Math.min(...values.num),
      max: Math.max(...values.num),
    },
    pct: {
      min: Math.min(...values.pct),
      max: Math.max(...values.pct),
    },
  };
};

export const classifySocGhgStateStats = (
  aggLevel: SIAggLevel,
  stats: Record<number, MetricSocGhgStatDatum>
): Record<StateFP, {num: number; pct: number}> | undefined => {
  let stateStats = undefined;

  if (!['state', 'supply_shed'].includes(aggLevel)) {
    const groupedByState = groupBy(Object.values(stats), 'statefp');
    stateStats = reduce<any, Record<StateFP, {num: number; pct: number}>>(
      groupedByState,
      (acc, stateItems, statefp) => {
        let totalStateValue = 0;
        let totalStatePercentage = 0;

        stateItems.forEach((item: MetricSocGhgStatDatum) => {
          totalStateValue = totalStateValue + Number(item.values.num);
          totalStatePercentage = totalStatePercentage + Number(item.values.pct);
        });

        acc[parseAreaId(statefp)] = {
          num: totalStateValue / stateItems.length,
          pct: totalStatePercentage,
        };

        return acc;
      },
      {}
    );
  }

  return stateStats;
};
