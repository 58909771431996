import {t} from 'i18n-utils';
import React, {useCallback} from 'react';
import {Button} from 'react-md';
import {useDispatch} from 'react-redux';
import {InfoIcon} from '../icons';
import {toggleMapButton} from '../actions';
import FieldInfoOverlay from '../overlays/field-info-overlay';
import {useAppSelector} from '_hooks';

const ToggleFieldInfo = () => {
  const toggledButtonOnMap = useAppSelector(store => store.map.toggledButtonOnMap);
  const dispatch = useDispatch();
  const thisButtonName = 'FieldInfo';
  const toggled = toggledButtonOnMap === thisButtonName;
  const onButtonClick = useCallback(() => {
    dispatch(toggleMapButton(thisButtonName));
  }, []);

  return (
    <div className={`btn-on-map-pop-up ${toggled ? 'active' : ''}`}>
      <Button
        id="toggle-field-info-btn"
        title={t({id: 'Field info'})}
        className={`map-toggle-button`}
        name="toggle-field-info"
        iconEl={<InfoIcon />}
        floating
        onClick={onButtonClick}
        mini
      />
      <FieldInfoOverlay toggled={toggled} />
    </div>
  );
};

export default ToggleFieldInfo;
