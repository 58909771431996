import React, {useCallback, useMemo} from 'react';
import type {ComponentType} from 'react';
import {useHistory, useLocation, matchPath} from 'react-router-dom';
import {selectViewport} from 'modules/global/selectors';
import {getFeaturesLabels} from '_utils/translations';
import {
  MRV_ADMIN,
  MRV_ADMIN_PROGRAM_CONFIGURE,
  MRV_ADMIN_PROGRAM_DASHBOARD,
  MRV_PROJECT_SELECT,
} from 'containers/mrv/routes';
import {useParsedMatchParams} from '_hooks/use-parsed-match-params';
import {
  MenubarMiddleNavTabsContainer,
  MenubarMiddleNavTabElement,
  MenubarMiddleNavOptionInnerContainer,
  MenubarMiddleNavIndicatorsInnerContainer,
} from 'containers/menubar/menubar-middle-nav/menubar-middle-nav';
import {MRV_PROJECT, MRV_HOME, MRV_ADMIN_PROGRAM} from '../../mrv/routes';
import {useTranslation} from 'i18n-utils';
import {applyPathParams} from '_utils/pure-utils';
import {useAppSelector} from '_hooks';
import Select, {components} from 'react-select';
import type {ValueContainerProps, OptionProps, StylesConfig} from 'react-select';
import type {MRVFeatureType} from 'types';
import {MRVAdminFeatureGroups, MRVFeatureLinks} from '_constants/feature-links';
import cn from 'classnames';
import {
  MenubarMenuOptionStyles,
  MenubarMenuStyles,
  MenubarControlStyles,
  MenubarIndicatorsContainerStyles,
  MenubarValueContainerStyles,
  MenubarMenuListStyles,
} from 'containers/menubar/menubar.styled';
import {MenubarArrowDropdown} from 'containers/menubar/menubar-components';
import {isSingleOption} from 'containers/menubar/menubar-utils';
import {useGetProgramDashboardsRelations} from 'components/cube-renderer/api/cube-api-hooks';

export const MRVNavigationComponent: ComponentType = () => {
  const t = useTranslation();
  const {pathname} = useLocation();
  const featureLabels = getFeaturesLabels();
  const history = useHistory();

  const {programId: mrvAdminProgramId} = useParsedMatchParams(MRV_ADMIN_PROGRAM);
  const {projectId} = useParsedMatchParams(MRV_PROJECT);
  const isAdminPage = matchPath(pathname, {path: MRV_ADMIN});
  const isSelectProgramPage = matchPath(pathname, {path: MRV_PROJECT_SELECT});

  const viewportWidth = useAppSelector(s => selectViewport(s)?.width);

  const navigateToProgramSteps = () => {
    history.push(applyPathParams(MRV_HOME, {projectId}));
  };

  const onSetAdminTab = useCallback(
    (feature: MRVFeatureType) => {
      history.push(
        applyPathParams(MRVFeatureLinks[feature], {
          programId: `${mrvAdminProgramId}`,
        })
      );
    },
    [history, mrvAdminProgramId]
  );

  const isAdminFeatureActive = (feature: MRVFeatureType) => {
    const featureLink = MRVFeatureLinks[feature];

    return !!featureLink && matchPath(pathname, {path: featureLink, strict: false});
  };

  const {data: programDashboardRelationData} = useGetProgramDashboardsRelations(mrvAdminProgramId);

  const mrvAdminMenuItems = useMemo(() => {
    const menuItems = MRVAdminFeatureGroups.map(feature => ({
      value: feature,
      label: featureLabels[feature],
      onClick: () => onSetAdminTab(feature),
    }));
    // If there are no relations between the program and any dashboards, remove the program dashboard tab
    if (!programDashboardRelationData?.length) {
      const programDashboardIndex = MRVAdminFeatureGroups.findIndex(
        group => group === 'mrv-admin-program-dashboard'
      );
      if (programDashboardIndex > -1) {
        menuItems.splice(programDashboardIndex, 1);
      }
    }

    return menuItems;
  }, [featureLabels, onSetAdminTab, programDashboardRelationData?.length]);

  const displayDropdownMenu = viewportWidth <= 900 && mrvAdminMenuItems.length > 1;

  return (
    <MenubarMiddleNavTabsContainer>
      {isAdminPage && mrvAdminProgramId ? (
        displayDropdownMenu ? (
          <Select
            // menuIsOpen
            isMulti={false}
            styles={TopLevelNavSelectStyles}
            onChange={value => {
              if (isSingleOption(value) && value?.onClick) {
                value.onClick();
              }
            }}
            options={mrvAdminMenuItems}
            components={{IndicatorsContainer, Option: OptionComponent}}
          />
        ) : (
          mrvAdminMenuItems.map(feature => (
            <MenubarMiddleNavTabElement
              key={feature.label}
              onClick={feature.onClick}
              className={cn({selected: isAdminFeatureActive(feature.value)})}
            >
              {feature.label}
            </MenubarMiddleNavTabElement>
          ))
        )
      ) : null}

      {!isAdminPage && !isSelectProgramPage && projectId ? (
        <MenubarMiddleNavTabElement onClick={navigateToProgramSteps} className="selected">
          {t({id: 'Program steps', defaultMessage: 'Program steps'})}
        </MenubarMiddleNavTabElement>
      ) : null}
    </MenubarMiddleNavTabsContainer>
  );
};

type Option = {
  value: MRVFeatureType;
  label: string;
  onClick?: (value?: string) => void;
};

const IndicatorsContainer: ComponentType<ValueContainerProps<Option>> = ({...props}) => {
  const {pathname} = useLocation();
  const featureLabels = getFeaturesLabels();

  let selectedFeatureGroup: MRVFeatureType | null = null;

  switch (true) {
    case !!matchPath(pathname, {path: MRV_ADMIN_PROGRAM_CONFIGURE, strict: false}):
      selectedFeatureGroup = 'mrv-admin-program-configure';
      break;
    case !!matchPath(pathname, {path: MRV_ADMIN_PROGRAM_DASHBOARD, strict: false}):
      selectedFeatureGroup = 'mrv-admin-program-dashboard';
      break;
    default:
      selectedFeatureGroup = null;
  }

  return (
    <components.IndicatorsContainer {...props}>
      <MenubarMiddleNavIndicatorsInnerContainer>
        <div className="workspace-label">
          {selectedFeatureGroup ? featureLabels[selectedFeatureGroup] : null}
        </div>
        <MenubarArrowDropdown />
      </MenubarMiddleNavIndicatorsInnerContainer>
    </components.IndicatorsContainer>
  );
};
const OptionComponent: ComponentType<OptionProps<Option>> = ({children, ...props}) => {
  const {pathname} = useLocation();
  const featureLink = MRVFeatureLinks[props.data.value];

  const isSelected = !!featureLink && !!matchPath(pathname, {path: featureLink, strict: false});

  return (
    <MenubarMiddleNavOptionInnerContainer>
      <components.Option {...props} isSelected={isSelected}>
        {children}
      </components.Option>
    </MenubarMiddleNavOptionInnerContainer>
  );
};

export const TopLevelNavSelectStyles: StylesConfig<Option> = {
  option: (base, state) => ({
    ...base,
    ...MenubarMenuOptionStyles,
    fontWeight: state.isSelected ? 700 : 400,
  }),
  valueContainer: base => ({...base, ...MenubarValueContainerStyles}),
  indicatorsContainer: base => ({
    ...base,
    ...MenubarIndicatorsContainerStyles,
  }),

  menu: base => ({
    ...base,
    ...MenubarMenuStyles,
    left: 0,
  }),
  control: base => ({
    ...base,
    ...MenubarControlStyles,
  }),
  menuList: base => {
    return {...base, ...MenubarMenuListStyles};
  },
};
