import {t} from 'i18n-utils';
import React from 'react';
import {FontIcon, ListItem, MenuButton} from 'react-md';

export const eligibleMsg = () => t({id: 'The field is located outside the eligible regions.'});
export const overlapMsg = () => t({id: 'The field overlaps with some other field.'});

export const ActionsButton = ({
  id,
  disabled,
  actions,
}: {
  id: string;
  disabled?: boolean;
  actions: {label: string; onClick: () => void}[];
}) => {
  if (!actions.length) return null;
  return (
    <MenuButton
      id={id}
      className="actions"
      listClassName="actions-button__menu-list"
      icon
      disabled={disabled}
      menuItems={actions
        .filter(item => item)
        .map(({onClick, label}, index) => (
          <ListItem key={index} onClick={onClick} primaryText={label} />
        ))}
    >
      <FontIcon>more_vert</FontIcon>
    </MenuButton>
  );
};
