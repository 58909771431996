import {InfoBlock} from 'components';
import {t, FormattedMessage} from 'i18n-utils';
import React from 'react';

export const OutcomeEstimationErrorMessage = () => {
  return (
    <InfoBlock
      mini
      appearance={'error'}
      color={'error'}
      title={t({id: 'OutcomeEstimationErrorMessage.Title'})}
    >
      <FormattedMessage
        id="OutcomeEstimationErrorMessage"
        defaultMessage="We noticed you have calculated outcomes many times, are you experiencing difficulties? Please
      contact support by calling +1 (319) 214-3303 or emailing support@regrow.ag."
      />
    </InfoBlock>
  );
};
