import type {ComponentType} from 'react';
import React, {useCallback, useContext, useEffect, useState} from 'react';
import {useAppSelector} from '_hooks';
import {selectAttributeById, selectCurrentProgramId, selectStageById} from '../../module/selectors';
import type {MRVValueInput, MRVValue, MRVPhaseType, MRVEntityType} from 'containers/mrv/types';
import {MRVAttributeType, Confidence, phaseTypeToProgress} from 'containers/mrv/types';
import type {InputComponentProps} from 'containers/mrv/monitoring/form/inputs/input-component';
import {InputComponentMemo} from 'containers/mrv/monitoring/form/inputs/input-component';
import {useIsAttributeDisabled} from 'containers/mrv/monitoring/module/useIsAttributeDisabled';
import {isPracticeConfident} from 'containers/mrv/enrollment/base/crop-practice';
import {FluroTableColumn as Column} from 'components/fluro-table-components/fluro-table-components';
import {isDefined} from '_utils/typeGuards';
import type {
  ValidationError,
  ValidationWarning,
} from 'containers/mrv/monitoring/form/mrv-table/hacky-validation';
import type {FluroSelectLiteItem} from 'components/fluro-select-lite/fluro-select-lite';
import {navigationHighlightContext} from '../navigation-highlight-context';
import {Flex} from 'components';
import {Blink} from 'components/blink/blink';
import {UnlockFieldButton} from './unlock-field-button';
import {ConfirmButton} from '../inputs/confirm-button';
import {CellStatusPopover} from './cell-status-popover';
import {Icon} from '@regrow-internal/design-system';
import {error_text, warning_text} from '_utils/colors';
import {useMonitoringUrlParams} from 'containers/mrv/monitoring/hooks';
import {getAttributeOptionValue} from 'containers/mrv/base/base';

/**
 * Cell of the mrv table:
 * 1. Renders the `input` component for the attribute.
 * 2. Renders the `unlock` button if the value is locked (it came from the previous year program).
 * 3. Renders the `confirm` button if the value is not confident (it came from OPTIS).
 * 4. Renders the `blink` component if the user clicked "highlight incomplete cells".
 * 5. Renders the `status` component with the error/warning popover.
 */
export const MRVTableCell: ComponentType<{
  phaseType: MRVPhaseType;
  entityType: MRVEntityType;
  attributeId: number;
  entityId: number;
  rowId: number; // Actual unique (per field) row id.
  rowIndex: number; // Index of the row in the table, as opposed to row_id, which doesn't guarantee the "order", i.e. if the rows are sorted by years first.
  values: Record<number, MRVValue | MRVValueInput>;
  projectValue: MRVValue | MRVValueInput;
  onChange: (entityId: number, rowIndex: number, values: MRVValueInput[]) => void;
  readOnly?: boolean;
  error?: ValidationError;
  warning?: ValidationWarning;
  hackyOptions?: FluroSelectLiteItem[];
}> = ({
  phaseType,
  entityType,
  attributeId,
  entityId,
  rowId,
  rowIndex,
  values,
  projectValue,
  onChange,
  readOnly,
  error,
  warning,
  hackyOptions,
}) => {
  const {stageId} = useMonitoringUrlParams();
  const stage = useAppSelector(s => selectStageById(s, stageId));
  const programId = useAppSelector(selectCurrentProgramId);
  const attribute = useAppSelector(s => selectAttributeById(s, attributeId));
  const isAttributeDisabled = useIsAttributeDisabled();

  /**
   * This is a hack to make the table compatible with the older programs, which may not have record year in the database
   */
  const withRecordYearFallback = useCallback(
    (value: any) => {
      if (
        // only enable this fallback on specific programs, when attribute is record year and value is undefined
        [68, 21].includes(programId as number) &&
        attribute.type === MRVAttributeType.RecordYear &&
        projectValue?.value === undefined
      ) {
        return stage ? stage.year_end - rowId : undefined;
      }
      return value;
    },
    [stage, attribute.type, projectValue?.value, rowId, programId]
  );

  /**
   * TODO `draftValue` creates multiple sources of truth when using _controlled_ input components.
   * Remove the `draftValue` state from this middle layer, and if required
   * move it down to the lowest level where it's needed (at the input component level).
   *
   * Draft value allows us to update the UI instantly when a user updates it,
   * without waiting for the API response or the redux machinery.
   *
   * It also allows us to show the "confirm" button when the user is typing.
   */
  const [draftValue, setDraftValue] = useState(withRecordYearFallback(projectValue?.value));
  useEffect(() => {
    setDraftValue(withRecordYearFallback(projectValue?.value));
  }, [projectValue?.value, withRecordYearFallback]);

  const change: InputComponentProps['onChange'] = useCallback(
    (value, shouldSave = true, attributeValues = []) => {
      setDraftValue(value);

      // Use shouldSave=false for user typing inputs. In this case we show the confirm button.
      if (!shouldSave) {
        return;
      }

      const values: MRVValueInput[] = [
        {
          id: projectValue?.id,
          confirmed: true,
          locked: false,
          value,
          progress: phaseTypeToProgress[phaseType],
          attribute_id: attribute.id,
          entity_type: entityType,
          row_id: rowId,
        },
      ];

      for (let i = 0; i < attributeValues.length; i++) {
        const {attribute: attr, value: attrValue} = attributeValues[i];

        values.push({
          id: undefined,
          confirmed: true,
          locked: false,
          value: attrValue,
          progress: phaseTypeToProgress[phaseType],
          attribute_id: attr.id,
          entity_type: entityType,
          row_id: rowId,
        });
      }

      onChange(entityId, rowIndex, values);
    },
    [onChange, entityType, entityId, rowIndex, rowId, phaseType, attribute.id, projectValue?.id]
  );

  const saveValue = () => {
    change(draftValue || defaultValue);
  };

  const {highlighting} = useContext(navigationHighlightContext);

  if (!attribute) return null;

  const {type, visible, name, label, default_value, min_val, max_val} = attribute;

  const disabledByDependencyRules = isAttributeDisabled(attribute, values);

  if (!readOnly && disabledByDependencyRules)
    return <Column style={{backgroundColor: '#f7f7f7'}} />;

  if (
    !attribute.enabled ||
    !visible ||
    // Hack to hide the column from the user, need to come up with a solution for config
    type === MRVAttributeType.NutrientManagementAppProductType
  ) {
    return null;
  }

  if (readOnly && !draftValue) return <Column style={{backgroundColor: '#f7f7f7'}}>-</Column>;

  const defaultValue = default_value;
  const confirmed = draftValue === projectValue?.value && projectValue?.confirmed;
  const locked = projectValue?.locked;
  const subtitle =
    projectValue?.confirmed || !isDefined(projectValue?.confidence)
      ? ''
      : isPracticeConfident(projectValue?.confidence)
      ? Confidence.High
      : Confidence.Low;

  const canBeHighlighted = canCellBeHighlighted(type, draftValue ? '' : defaultValue);
  const noValue = !draftValue && !defaultValue;
  const shouldHighlight = !readOnly && (noValue || !confirmed || canBeHighlighted);

  const options = hackyOptions || attribute.options;

  const confirmable =
    !locked &&
    !confirmed &&
    (draftValue || defaultValue) &&
    // Hack FSB-11273 to not show confirm button for crop values that are not enabled.
    !(
      type === MRVAttributeType.CropType &&
      !options?.some(o => getAttributeOptionValue(o) === draftValue)
    );

  const inputId = `E${entityId}-A${attributeId}-R${rowId}-V${projectValue?.id}`;
  const popoverId = `validation-popover-${inputId}`;

  const warningWhenNeeded = (projectValue?.id && warning) || undefined;

  return (
    <>
      <Column id={popoverId} highlight={shouldHighlight}>
        {shouldHighlight && <Blink blinking={highlighting} />}
        <Flex alignItems="center" gap=".5em" nowrap data-testid={`MRV-table--cell--${attributeId}`}>
          <InputComponentMemo
            key={default_value}
            id={popoverId}
            type={type}
            name={name}
            value={draftValue}
            defaultValue={default_value || projectValue?.value}
            options={options}
            disabled={locked || readOnly}
            subtitle={subtitle}
            minValue={Number(min_val) || undefined}
            maxValue={Number(max_val) || undefined}
            onChange={change}
          />
          {error && <Icon type="cross-circled" color={error_text} />}
          {warning && <Icon type="info-outlined" color={warning_text} />}
          {!readOnly && locked && <UnlockFieldButton practice={label} onUnlock={saveValue} />}
          {!readOnly && confirmable && <ConfirmButton onClick={saveValue} />}
        </Flex>
      </Column>
      {(error || warningWhenNeeded) && (
        <CellStatusPopover popoverId={popoverId} error={error} warning={warningWhenNeeded} />
      )}
    </>
  );
};

const canCellBeHighlighted = (type: MRVAttributeType, value: any) => {
  return (
    type !== MRVAttributeType.WinterCropCommitment &&
    (value === 'No Irrigation' || value === '0%' || value === 'other')
  ); //TODO: move it to a better place (https://regrow.atlassian.net/browse/FSB-7251?focusedCommentId=31756)
};
