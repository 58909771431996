import type {ComponentType} from 'react';
import React from 'react';
import {useAppDispatch, useAppSelector} from '_hooks';
import {selectAreaUnits} from 'modules/sustainability-insights/selectors';
import {selectGhgTrendChartData} from 'modules/sustainability-insights/selectors/ghg';
import {TrendChart} from '../trend-chart';
import {setActiveGeometriesFilter} from 'modules/sustainability-insights/actions';

type Props = {};

export const GhgTrendChart: ComponentType<Props> = () => {
  const dispatch = useAppDispatch();
  const trendChartData = useAppSelector(selectGhgTrendChartData);
  const units = useAppSelector(selectAreaUnits);

  const handleClear = () => {
    dispatch(setActiveGeometriesFilter(null));
  };

  return (
    <TrendChart
      data={trendChartData}
      units={units}
      onClearSelection={handleClear}
      numUnitsLabel={'t/ac'}
    />
  );
};
