import {useAppSelector} from '_hooks';
import {selectStageAttributeTypeIds} from '../monitoring/module/selectors';
import {MRVAttributeType} from '../types';
import {getMRVValuesBaseRequestKey} from './base';

export const useMRVValuesRequestKey = (projectId: number, stageId?: number) => {
  const attributeIds = useAppSelector(s => selectStageAttributeTypeIds(s, stageId));
  const yearAttrId = attributeIds[MRVAttributeType.RecordYear];

  const sortKey = yearAttrId ? 'year' : 'row_id';
  const sortOrder = yearAttrId ? 'desc' : 'asc';
  const base = getMRVValuesBaseRequestKey(projectId);
  const requestKey = `${base}?stage_id=${stageId}&sort_key=${sortKey}&order=${sortOrder}`;

  return requestKey;
};
