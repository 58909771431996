import styled from 'styled-components';
export const TabContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  flex-wrap: wrap;
  margin-bottom: 15px;

  .source-type-tab {
    background: #fff;
    padding: 0 19px 9px;
    border: 1px solid #ccc;
    width: auto;
    height: auto;
    border-right: none;
    text-transform: uppercase;
    cursor: pointer;
    color: rgba(0, 0, 0, 0.8);

    &.active {
      background-color: #43a047;
      color: #fff;
      border: 1px solid #43a047;
    }
  }

  .source-type-tab:last-of-type {
    border-right: 1px solid #ccc;
  }

  .source-type-icon {
    position: relative;
    top: 7px;
    margin-right: 10px;
    height: 26px;
    width: 25px;
    color: inherit;
  }
`;

export const CommonBlockWrapper = styled.div`
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  padding: 20px 22px;

  width: calc(100% + 48px);
  margin: 10px -24px 0;
`;

export const CommonFieldsWrapper = styled.div`
  .top-block {
    display: flex;
    justify-content: space-between;

    .select-field {
      width: 240px;
    }

    .fluro-datepicker {
      width: 240px;
    }
  }

  .layer-type-select {
    width: 240px;
  }

  .upload-to-whole-farm-checkbox {
    margin-left: -15px;
  }
`;
