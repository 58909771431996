import {createAsyncThunk} from '@reduxjs/toolkit';
import type {
  SISupplyShed,
  SISupplyShedInput,
  SISubsection,
  SISubsectionInput,
  PlanningReport,
  PlanningReportInput,
  SIScenarioAP,
  SIScenarioPP,
  SIScenarioResponsePP,
} from 'containers/si/types';
import {normalize} from 'normalizr';
import type {AppStore} from 'reducers';
import type {KPIFilter, ProgramRequest, ProgramResponse} from 'containers/si/api/apiTypes';
import SIApi from 'containers/si/api/si';
import {loadSIProgramsList} from './reducer';
import {normalizeSIPrograms, normalizeSIProgram, applyColorKeyToListObjects} from './base';
import {ProgramSchema} from './normalize-schemas';
import {ActionType} from './types';
import {naturalSortAlphaNum} from '_utils/sorters';
import {
  selectSISupplyShedsListByProgramId,
  selectPlanningReportsListByProgramId,
} from 'containers/si/module/selectors';

// The thunkAPI needs to be the second argument, so the _: void allows there to be an empty first argument.
export const fetchSIPrograms = createAsyncThunk(
  ActionType.FETCH_ALL_SI_PROGRAMS,
  async (_: void, thunkAPI) => {
    const response = await SIApi.getPrograms();
    const normalizedPrograms = normalizeSIPrograms(response.data);

    const programIds = normalizedPrograms.result;
    if (programIds?.length) {
      thunkAPI.dispatch(loadSIProgramsList(programIds));
    }

    return normalizedPrograms;
  }
);
export const fetchSIProgram = createAsyncThunk(
  ActionType.FETCH_SI_PROGRAM,
  async (programId: number) => {
    const response = await SIApi.getProgram(programId);
    const normalized = normalizeSIProgram(response.data);

    return normalized;
  }
);

export const updateSIProgram = createAsyncThunk(
  ActionType.UPDATE_SI_PROGRAM,
  async ({programId, program}: {programId: number; program: Partial<ProgramResponse>}) => {
    const response = await SIApi.updateProgram(programId, program);
    const normalized = normalize(response.data, ProgramSchema);

    return normalized;
  }
);

export const addSIProgram = createAsyncThunk<
  ReturnType<typeof normalize>,
  {program: ProgramRequest},
  {state: AppStore}
>(ActionType.ADD_SI_PROGRAM, async ({program}) => {
  const response = await SIApi.addProgram(program);
  const normalized = normalizeSIPrograms(response?.data);

  return normalized;
});

export const removeSIProgram = createAsyncThunk<number, {programId: number}, {state: AppStore}>(
  ActionType.REMOVE_SI_PROGRAM,
  async ({programId}) => {
    await SIApi.removeProgram(programId);
    return programId;
  }
);

// Users
export const fetchSIProgramUsers = createAsyncThunk(
  ActionType.FETCH_SI_PROGRAM_USERS,
  async (programId: number) => {
    const response = await SIApi.getProgramUsers(programId);
    const programUsers = {[programId]: {users: response.data}};

    return programUsers;
  }
);

export const fetchSIProgramAdmins = createAsyncThunk(
  ActionType.FETCH_SI_PROGRAM_ADMINS,
  async (programId: number) => {
    const response = await SIApi.getProgramAdmins(programId);

    const programAdmins = {[programId]: {admins: response.data}};

    return programAdmins;
  }
);

export const removeSIProgramUser = createAsyncThunk(
  ActionType.REMOVE_SI_PROGRAM_USER,
  async ({
    programId,
    user_id,
    role,
  }: {
    programId: number;
    user_id: number;
    role: 'admin' | 'user';
  }) => {
    await SIApi.deleteProgramUsers(programId, [user_id]);

    return {programId, user_id, role};
  }
);

// Geometries
export const fetchProgramGeometries = createAsyncThunk(
  ActionType.FETCH_SI_PROGRAM_GEOMETRIES,
  async (programId: number) => {
    const response = await SIApi.getProgramGeometries(programId);

    const normalizedGeometries = {
      entities: {
        geometries: {
          [programId]: naturalSortAlphaNum(response.data, 'id'),
        },
      },
    };

    return normalizedGeometries;
  }
);

// Supply Shed
export const fetchSISupplySheds = createAsyncThunk(
  ActionType.FETCH_SI_SUPPLY_SHEDS,
  async (programId: number) => {
    const response = await SIApi.getSupplySheds(programId);

    // The response might not be sorted by oldest to newest, so sort it.
    // This is to ensure the same color is used for the same supplyShed.
    const sortedResponse = naturalSortAlphaNum(response.data, 'id');
    const colorResponse = applyColorKeyToListObjects(sortedResponse);

    const normalizedSupplySheds = {
      entities: {
        supplySheds: {
          [programId]: colorResponse,
        },
      },
    };

    return normalizedSupplySheds;
  }
);

export const fetchSISupplyShedById = createAsyncThunk(
  ActionType.FETCH_SI_SUPPLY_SHEDS_BY_ID,
  async ({programId, supplyShedId}: {programId: number; supplyShedId: number}) => {
    const response = await SIApi.getSupplyShedById(programId, supplyShedId);

    const colorResponse = applyColorKeyToListObjects([response.data]);

    const normalizedSupplySheds = {
      entities: {
        supplySheds: {
          [programId]: colorResponse,
        },
      },
    };

    return normalizedSupplySheds;
  }
);

export const addSISupplyShed = createAsyncThunk<
  any,
  {programId: number; supplyShed: SISupplyShedInput},
  {state: AppStore}
>(ActionType.ADD_SI_SUPPLY_SHED, async ({programId, supplyShed}, thunkAPI) => {
  const response = await SIApi.addSupplyShed(programId, supplyShed);

  // Grab the original supplySheds
  const originalSupplySheds = selectSISupplyShedsListByProgramId(thunkAPI.getState(), programId);

  // Add the new supplyShed to the end of the list
  const newSupplySheds = [...originalSupplySheds, response.data[0]];

  // The newSupplySheds list might not be sorted by oldest to newest, so sort it.
  // This is to ensure the same color is used for the same supplyShed.
  const sortedResponse = naturalSortAlphaNum(newSupplySheds, 'id');
  const colorResponse = applyColorKeyToListObjects(sortedResponse);

  const normalizedSupplyShed = {
    entities: {
      supplySheds: {
        [programId]: colorResponse,
      },
    },
  };

  return normalizedSupplyShed;
});

export const updateSISupplyShed = createAsyncThunk<
  any,
  {programId: number; supplyShedId: number; supplyShed: Partial<SISupplyShed>},
  {state: AppStore}
>(ActionType.UPDATE_SI_SUPPLY_SHED, async ({programId, supplyShedId, supplyShed}, thunkAPI) => {
  const response = await SIApi.updateSupplyShed(programId, supplyShedId, supplyShed);

  // Grab the original supplySheds so we can get the color
  const originalSupplySheds = selectSISupplyShedsListByProgramId(thunkAPI.getState(), programId);

  const updatedSupplySheds = originalSupplySheds.map(shed =>
    shed.id === supplyShedId ? {...shed, ...response.data} : shed
  );

  const normalizedSupplyShed = {
    entities: {
      supplySheds: {
        [programId]: updatedSupplySheds,
      },
    },
  };

  return normalizedSupplyShed;
});

export const removeSISupplyShed = createAsyncThunk(
  ActionType.REMOVE_SI_SUPPLY_SHED,
  async ({programId, supplyShedId}: {programId: number; supplyShedId: number}) => {
    await SIApi.removeSupplyShed(programId, supplyShedId);

    return {programId, supplyShedId};
  }
);

export const addSISubsection = createAsyncThunk<
  any,
  {
    programId: number;
    subsection: SISubsectionInput;
  },
  {state: AppStore}
>(ActionType.ADD_SI_SUBSECTION, async ({programId, subsection}, thunkAPI) => {
  const response = await SIApi.addSupplyShedSubsection(programId, subsection);

  const originalSupplySheds = selectSISupplyShedsListByProgramId(thunkAPI.getState(), programId);

  const updatedSupplySheds = originalSupplySheds.map(shed => {
    if (shed.id === subsection.supply_shed_id) {
      return {
        ...shed,
        subsections: [...shed.subsections, response.data[0]],
      };
    }
    return shed;
  });

  const normalizedSupplyShed = {
    entities: {
      supplySheds: {
        [programId]: updatedSupplySheds,
      },
    },
  };

  return normalizedSupplyShed;
});

export const updateSISubsection = createAsyncThunk<
  any,
  {
    programId: number;
    supplyShedId: number;
    subsectionId: number;
    subsection: Partial<SISubsection>;
  },
  {state: AppStore}
>(
  ActionType.UPDATE_SI_SUBSECTION,
  async ({programId, supplyShedId, subsectionId, subsection}, thunkAPI) => {
    const response = await SIApi.updateSupplyShedSubsection(
      programId,
      supplyShedId,
      subsectionId,
      subsection
    );

    const originalSupplySheds = selectSISupplyShedsListByProgramId(thunkAPI.getState(), programId);

    const updatedSupplySheds = originalSupplySheds.map(shed => {
      if (shed.id === supplyShedId) {
        const updatedSubsections = shed.subsections.map(sub =>
          sub.id === subsectionId ? {...sub, ...response.data} : sub
        );

        return {...shed, subsections: updatedSubsections};
      } else {
        return shed;
      }
    });

    const normalizedSupplyShed = {
      entities: {
        supplySheds: {
          [programId]: updatedSupplySheds,
        },
      },
    };

    return normalizedSupplyShed;
  }
);

export const removeSISubsection = createAsyncThunk(
  ActionType.REMOVE_SI_SUBSECTION,
  async ({
    programId,
    supplyShedId,
    subsectionId,
  }: {
    programId: number;
    supplyShedId: number;
    subsectionId: number;
  }) => {
    await SIApi.removeSupplyShedSubsection(programId, supplyShedId, subsectionId);

    return {programId, supplyShedId, subsectionId};
  }
);

// Sustainability Reporting / KPI Dashboard
export const fetchKPISubsectionArea = createAsyncThunk(
  ActionType.FETCH_KPI_SUBSECTION_AREA,
  async ({programId, year}: {programId: number; year: number}) => {
    const kpiUrl = `crop_area?year_of_interest=${year}&summarize_by=subsection`;
    const response = await SIApi.getKPIData(programId, kpiUrl);

    return response.data;
  }
);

// KPI Filters
export const fetchAllKPIFilters = createAsyncThunk(
  ActionType.FETCH_KPI_FILTERS,
  async ({programId}: {programId: number}) => {
    const response = await SIApi.getAllKPIFilters(programId);

    return response.data;
  }
);

export const setAllKPIFilters = createAsyncThunk(
  ActionType.SET_KPI_FILTERS,
  async ({programId, filters}: {programId: number; filters: KPIFilter[]}) => {
    await SIApi.setAllKPIFilters(programId, filters);
    return filters;
  }
);

//KPIS response
export const fetchKPIData = createAsyncThunk(
  ActionType.FETCH_KPI,
  async ({programId, kpiUrl}: {programId: number; kpiUrl: string}) => {
    const {data} = await SIApi.getKPIData(programId, kpiUrl);

    return data;
  }
);

export const fetchBookValues = createAsyncThunk(
  ActionType.FETCH_BOOK_VALUES,
  async ({programId}: {programId: number}) => {
    const {data} = await SIApi.getBookValues(programId);

    return data;
  }
);

// Scenario Modeling / Planning Reports / Abatement Potential / IMT
export const fetchPlanningReports = createAsyncThunk(
  ActionType.FETCH_PLANNING_REPORTS,
  async (programId: number) => {
    const response = await SIApi.getPlanningReports(programId);

    const normalizedReports = {
      reports: {
        planningReports: {
          [programId]: response.data,
        },
      },
    };

    return normalizedReports;
  }
);

export const addPlanningReport = createAsyncThunk<
  any,
  {programId: number; report: PlanningReportInput},
  {state: AppStore}
>(ActionType.ADD_PLANNING_REPORT, async ({programId, report}, thunkAPI) => {
  const response = await SIApi.addPlanningReport(programId, report);

  const originalReports = selectPlanningReportsListByProgramId(thunkAPI.getState(), programId);

  const normalizedReports = {
    reports: {
      planningReports: {
        [programId]: [...originalReports, response.data[0]],
      },
    },
  };

  return normalizedReports;
});

export const updatePlanningReportURL = createAsyncThunk<
  any,
  {programId: number; reportId: number; reportURL: string},
  {state: AppStore}
>(ActionType.UPDATE_PLANNING_REPORT_URL, async ({programId, reportId, reportURL}, thunkAPI) => {
  await SIApi.updatePlanningReportURL(programId, reportId, reportURL);

  const originalReports = selectPlanningReportsListByProgramId(thunkAPI.getState(), programId);

  const updatedReports: PlanningReport[] = originalReports.map(r =>
    r.id === reportId ? {...r, results_url: reportURL, status: 'COMPLETE'} : r
  );

  const normalizedReports = {
    reports: {
      planningReports: {
        [programId]: updatedReports,
      },
    },
  };

  return normalizedReports;
});

export const updatePlanningReport = createAsyncThunk<
  any,
  {programId: number; reportId: number; report: Partial<PlanningReport>},
  {state: AppStore}
>(ActionType.UPDATE_PLANNING_REPORT, async ({programId, reportId, report}, thunkAPI) => {
  const response = await SIApi.updatePlanningReport(programId, reportId, report);

  const originalReports = selectPlanningReportsListByProgramId(thunkAPI.getState(), programId);

  const updatedReports = originalReports.map(r =>
    r.id === reportId ? {...r, ...response.data} : r
  );

  const normalizedReports = {
    reports: {
      planningReports: {
        [programId]: updatedReports,
      },
    },
  };

  return normalizedReports;
});

export const fetchAPReportScenarios = createAsyncThunk(
  ActionType.FETCH_REPORT_SCENARIOS_AP,
  async ({programId, reportId}: {programId: number; reportId: number}) => {
    const response = await SIApi.getAPReportScenarios(programId, reportId); // Until backend is ready use demo data
    // Add id to each scenario using array index
    const responseWithIndex: SIScenarioAP[] = response.data.map((s, i) => ({
      ...s,
      id: i,
      tag: 'abatement_potential',
    }));

    return {scenarios: responseWithIndex, programId, reportId};
  }
);

const demo = [
  {
    dndc_execution_id: 1,
    crop_type: 1,
    scenario_id: 45,
    supply_shed_id: 20,
    geography: {
      feature_id: '666c99c35a07e8207918406bf8063910', // '1a392eab51c40702b61c870cbebbb343', // Showing a couple states since they are easier to see on the map
      collection_id: 'admin_1_geoboundaries_cgazgeojsonld', //'admin_2_geoboundaries_cgaz',
      feature_name: 'Kossuth County',
    },
    baseline: {
      soc_kg_per_m2: 11,
      ghg_kg_per_m2: 21,
      scenario_adoption: 0.5,
    },
    simulation: {
      soc_kg_per_m2: 12,
      ghg_kg_per_m2: 22,
    },
  },
  {
    dndc_execution_id: 1,
    crop_type: 1,
    scenario_id: 45,
    supply_shed_id: 20,
    geography: {
      feature_id: 'aca404cb19368c1e36ba54cc65ef277c', // 'f913e0e829f867ca9cb084415be1db8c',
      collection_id: 'admin_1_geoboundaries_cgazgeojsonld', //'admin_2_geoboundaries_cgaz',
      feature_name: 'Allegan County',
    },
    baseline: {
      soc_kg_per_m2: 14,
      ghg_kg_per_m2: 20,
      scenario_adoption: 0.05,
    },
    simulation: {
      soc_kg_per_m2: 15,
      ghg_kg_per_m2: 20,
    },
  },
  {
    dndc_execution_id: 3,
    crop_type: 2,
    scenario_id: 49,
    supply_shed_id: 20,
    geography: {
      feature_id: '666c99c35a07e8207918406bf8063910', // '1a392eab51c40702b61c870cbebbb343',
      collection_id: 'admin_1_geoboundaries_cgazgeojsonld', //'admin_2_geoboundaries_cgaz',
      feature_name: 'Kossuth County',
    },
    baseline: {
      soc_kg_per_m2: 19,
      ghg_kg_per_m2: 19,
      scenario_adoption: 0.5,
    },
    simulation: {
      soc_kg_per_m2: 18,
      ghg_kg_per_m2: 15,
    },
  },
  {
    dndc_execution_id: 4,
    crop_type: 2,
    scenario_id: 49,
    supply_shed_id: 20,
    geography: {
      feature_id: 'aca404cb19368c1e36ba54cc65ef277c', // 'f913e0e829f867ca9cb084415be1db8c',
      collection_id: 'admin_1_geoboundaries_cgazgeojsonld', //'admin_2_geoboundaries_cgaz',
      feature_name: 'Allegan County',
    },
    baseline: {
      soc_kg_per_m2: 17,
      ghg_kg_per_m2: 24,
      scenario_adoption: 0.05,
    },
    simulation: {
      soc_kg_per_m2: 19,
      ghg_kg_per_m2: 21,
    },
  },
];

export const fetchPPReportScenarios = createAsyncThunk(
  ActionType.FETCH_REPORT_SCENARIOS_PP,
  async ({programId, reportId}: {programId: number; reportId: number}) => {
    // const response = await SIApi.getPPReportScenarios(programId, reportId); // Until backend is ready use demo data
    // Add id to each scenario using array index
    // const responseWithIndex: SIScenarioResponsePP[] = response.data.map((s, i) => ({...s, id: i}));

    const response: SIScenarioResponsePP[] = demo;
    const responseWithIndex: SIScenarioPP[] = response.map((s, i) => ({
      ...s,
      id: i,
      tag: 'program_plan',
    }));

    return {scenarios: responseWithIndex, programId, reportId};
  }
);
