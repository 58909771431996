import type {Farm, FieldNormalized, Season} from 'containers/map/types';
import type {EntitiesState} from 'modules/redux-utils/types';

export type FarmState = {
  list: number[];
  totalCount: number;
  demoFarmsObject: DemoFarm;
  demoFarmsIdsList: number[];
  entities: {
    farms: EntitiesState<Farm>;
    fields: EntitiesState<FieldNormalized>;
    demoFarms: EntitiesState<DemoFarm>;
    seasons: EntitiesState<Season>;
  };
};

export enum FarmActionTypes {
  CREATE_FARM = 'farms/create-farm',
  UPDATE_FARM = 'farms/update-farm',
  REMOVE_FARM = 'farms/remove-farm',
  RELOAD_FARM = 'farms/reload-farm',
  FETCH_FARMS_KML_BY_IDS = 'farms/fetch-farms-kml-by-ids',
}

export type FarmAction = {
  type: FarmActionTypes;
  farm: Farm;
  farmId: number;
  value: any;
  farms: {
    list: Farm[];
    totalCount: number;
  };
  demoFarms: DemoFarm;
};

export type DemoFarm = {[key: string]: {name: string; icon: string; id: number}};
