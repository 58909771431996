import {Flex, FluroButton, FluroDialog, Text} from 'components';
import {FormattedMessage, t} from 'i18n-utils';
import type {ComponentType} from 'react';
import React, {useState} from 'react';
import {Button} from 'react-md';
import {useAppSelector} from '_hooks';
import {mixpanelCarbon} from '_utils/mixpanel-utils';
import {useMonitoringUrlParams} from '../../hooks';
import {selectStageById} from '../../module/selectors';

type Props = {
  practice?: string;
  onUnlock?: () => void;
  isDialogView?: boolean;
};

export const UnlockFieldButton: ComponentType<Props> = ({practice, onUnlock, isDialogView}) => {
  const [dialog, setDialog] = useState(false);

  const {stageId} = useMonitoringUrlParams();
  const stage = useAppSelector(s => selectStageById(s, stageId));

  const title = (
    <Text variant={'h2'}>
      {t({id: 'UnlockField.Report a Practice Change', defaultMessage: 'Report a Practice Change'})}
    </Text>
  );

  const button = (
    <Button
      icon
      onClick={() => {
        setDialog(true);
        if (stage) {
          mixpanelCarbon.measurementUnlock(stage?.type_);
        }
      }}
    >
      lock
    </Button>
  );

  const content = (
    <>
      <Text>
        <FormattedMessage
          id="UnlockField.Thanks"
          defaultMessage="Thank you for reporting changes to your <strong>{practice} practice</strong>."
          values={{practice, strong: (msg: string) => <strong>{msg}</strong>}}
        />
      </Text>
      <Text>
        {t({id: 'We will use this change to ensure your payment is as accurate as possible.'})}
      </Text>

      <Flex justifyContent="flex-end" alignItems="center" gap="1em">
        <FluroButton text onClick={() => setDialog(false)} raised blank>
          {t({id: 'Cancel'})}
        </FluroButton>
        <FluroButton
          onClick={() => {
            setDialog(false);
            onUnlock?.();
          }}
          raised
          primary
        >
          {t({id: 'BtnLabel.Continue', defaultMessage: 'Continue'})}
        </FluroButton>
      </Flex>
    </>
  );

  if (isDialogView) {
    return (
      <>
        {button}
        {dialog && (
          <div className="unlock-field-block">
            {title}
            {content}
          </div>
        )}
      </>
    );
  }

  return (
    <>
      {button}

      <FluroDialog
        id="unlock-practice-change"
        title={title}
        onHide={() => setDialog(false)}
        visible={dialog}
        width={400}
        portal
      >
        {content}
      </FluroDialog>
    </>
  );
};
