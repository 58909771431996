// @ts-nocheck
import {t} from 'i18n-utils';
import React, {useMemo, useState} from 'react';
import * as Leaflet from 'leaflet';
import {FluroImageOverlay} from 'components/fluro-leaflet';
import {SourceType, ZoningTab} from '../types';
import {setNitrogenMarker} from '../actions';
import NitrogenMarkersOverlay, {NitrogenPopup} from './nitrogen-markers-overlay';
import BinaryReader from '../binary-reader';
import {getGetURLParam} from '_utils/pure-utils';
import ZoningPoint from '../features/zoning/zoning-points';
import get from 'lodash.get';
import {showNotification} from 'components/notification/notification';
import {defaultMarkerIcon} from '../icons';
import {bbox} from '@turf/turf';
import {AsyncStatusType, setRequestStatus, Status} from 'modules/helpers';

import {Marker, useMap} from 'react-leaflet';
import FieldSoilMapOverlay from './field-soil-map-overlay';
import {useAppDispatch, useAppSelector} from '_hooks';
import {
  selectCurrentDate,
  selectCurrentDates,
  selectCurrentField,
  selectCurrentFieldId,
  selectCurrentSeason,
  selectCurrentSensor,
  selectCurrentTab,
  selectFieldGeometries,
  selectIsWholeFarmView,
  selectMapFields,
  selectZoning,
  selectDrawControl,
  selectIsZoning,
  selectTreeDetectionLayerType,
  selectDateTimeByDate,
  selectIsCompareOn,
  selectColorSchema,
} from '../reducer/selectors';
import {selectNRecommendation} from 'containers/map/features/nrx/nrx-selectors';
import {useSelectCurrentFieldLayerImageUrl} from '../selectors';
import {selectZoningUrl} from 'containers/map/features/zoning/zoning-selectors';

type Props = {
  setOriginalImageOverlayRef: (ref: L.ImageOverlay) => void;
};

const MainFieldOverlay = ({setOriginalImageOverlayRef}: Props) => {
  const dispatch = useAppDispatch();

  const isTree = useAppSelector(selectTreeDetectionLayerType) !== 'default';
  const field = useAppSelector(selectCurrentField);
  const fields = useAppSelector(selectMapFields);
  const soilMapLayer = useAppSelector(s => s.map.soilMapLayer);
  const fieldGeometries = useAppSelector(selectFieldGeometries);
  const selectedFieldId = useAppSelector(selectCurrentFieldId);
  const currentDate = useAppSelector(selectCurrentDate);
  const currentDates = useAppSelector(selectCurrentDates);
  const currentSensor = useAppSelector(selectCurrentSensor);
  const currentSeason = useAppSelector(selectCurrentSeason);
  const feature = useAppSelector(selectCurrentTab);
  const isWholeFarmView = useAppSelector(selectIsWholeFarmView);
  const isZoning = useAppSelector(selectIsZoning);
  const zoning = useAppSelector(selectZoning);
  const nRecommendation = useAppSelector(selectNRecommendation);
  const globalDialogs = useAppSelector(s => s.global.dialogsState);
  const drawingEntity = useAppSelector(selectDrawControl).drawingEntity;
  const isProductivityMap = useAppSelector(s => s.productivityMap.isOn);
  const zoningUrl = useAppSelector(s =>
    selectZoningUrl(s, currentSeason?.geometry_id ? {geometryId: currentSeason?.geometry_id} : {})
  );
  const {selectTreeLayerImageUrl, selectFieldLayerImageUrl, selectPlantingAreaLayerImageUrl} =
    useSelectCurrentFieldLayerImageUrl();

  const [nitrogenPopupVisible, setNitrogenPopupVisibility] = useState(false);
  const [nitrogenPopupPosition, setNitrogenPopupPosition] = useState({x: 0, y: 0});
  const [nitrogenValue, setNitrogenValue] = useState(0.0);
  const currentDateTime = useAppSelector(s => selectDateTimeByDate(s, currentDate));
  const isCompareOn = useAppSelector(selectIsCompareOn);
  const colorSchema = useAppSelector(selectColorSchema);

  let nitrogenHandler: any = null;

  const leafletElement = useMap();

  const onError = () => {
    const isPlanetImage = currentDate?.endsWith(SourceType.SatelliteHD);
    if (isPlanetImage || isCompareOn) return; // for these cases images are coming from imagesCache, skip the error then

    showNotification({
      title: t({id: 'note.error', defaultMessage: 'Error'}),
      message: 'An error occurred, please try again later',
      type: 'error',
    });
  };

  const loadBinaryEffect = (status: Status) => {
    dispatch(setRequestStatus(AsyncStatusType.binaryImageLayerData, status));
  };

  const pixelHandler = (f: any) => {
    nitrogenHandler = f;
  };

  const selectedFields = useMemo(() => {
    return fields.find(f => f.ID === selectedFieldId)
      ? []
      : fields.filter(f => f._selected && fieldGeometries[f.MD5] && f.ID !== selectedFieldId);
  }, [fields, selectedFieldId]);

  const getTooltipValue = (e: any) => {
    if (nitrogenHandler) {
      const overlayImage = e.sourceTarget.getElement();
      const imgRect = overlayImage.getBoundingClientRect();

      // Mapping client image pixels to original image pixels
      const yR = overlayImage.clientHeight / overlayImage.naturalHeight;
      const xR = overlayImage.clientWidth / overlayImage.naturalWidth;
      const x = (e.originalEvent.clientX - imgRect.left) / xR;
      const y = (e.originalEvent.clientY - imgRect.top) / yR;

      // --

      const mapNode = leafletElement.getContainer();
      const rect = mapNode.getBoundingClientRect();

      const data = nitrogenHandler(x, y, overlayImage.naturalWidth, overlayImage.naturalHeight);

      // tooltip
      setNitrogenPopupPosition({
        x: e.originalEvent.clientX - rect.left + 10,
        y: e.originalEvent.clientY - rect.top + 10,
      });
      setNitrogenValue(data);
    }
  };

  const onSetNitrogenMarker = (e: any) => {
    dispatch(setNitrogenMarker(e.latlng, nitrogenValue));
  };

  const onSetOriginalImageOverlayRef = (ref: any) => {
    if (ref) {
      setOriginalImageOverlayRef(ref);
    }
  };

  const getZoningImage = () => {
    const {treeZonesImage} = zoning;

    if (isTree) return treeZonesImage;

    return zoningUrl;
  };

  const getImageUrl = () => {
    if (isCompareOn && colorSchema === 'singleDate') {
      return `${currentDateTime}_${currentSensor}`;
    }

    if (currentSeason?.geometry_id && feature !== 'zoning') {
      return selectPlantingAreaLayerImageUrl();
    }

    if (
      field &&
      field.SouthLat &&
      currentSensor !== 'NONE' &&
      selectedFieldId &&
      !isWholeFarmView &&
      currentDate &&
      currentDates[currentDate]
    ) {
      const isApsim = zoning.tab === ZoningTab.NitrogenRx && nRecommendation.method === 'apsim';

      // FSB-3388 hack to test cloud masks
      if (getGetURLParam('cloud') === 'mask') {
        const md5 = field.MD5;
        const date = currentDates[currentDate].Date;
        return `https://app.regrow.ag/services/data-service/cloudmask/${md5}/${date}`;
      }
      // FSB-3428 hack to test cloud probability
      if (getGetURLParam('cloud') === 'proba') {
        const md5 = field.MD5;
        const date = currentDates[currentDate].Date;
        return `https://app.regrow.ag/services/data-service/cloudmask/${md5}/${date}?prob_mask=true`;
      }
      if (isZoningEnabled && !isApsim) {
        return getZoningImage();
      }
      if (!isZoningEnabled && !selectedFields.length) {
        return selectTreeLayerImageUrl() || selectFieldLayerImageUrl();
      }
    }

    return '';
  };
  const isZoningEnabled = feature === 'zoning' || (feature === 'tsp' && isZoning);

  const {editField, editPivotTableView} = globalDialogs;

  const imageUrl = getImageUrl();

  const pivotCoordinates =
    editField?.PivotCenterCoordinates || editPivotTableView?.PivotCenterCoordinates;

  const isNitrogenFeature = currentSensor === 'NMAP';

  const nitrogenUrl = useMemo(() => {
    return field && isNitrogenFeature ? get(currentDates, `${currentDate}.NDRE`, false) : false;
  }, [isNitrogenFeature, currentDates, currentDate, field]);

  const plantingAreaBox = currentSeason.geometry_id ? bbox(currentSeason.geometry) : null;

  const eventHandlers = isNitrogenFeature
    ? {
        mousemove: getTooltipValue,
        mouseover: () => setNitrogenPopupVisibility(true),
        mouseout: () => setNitrogenPopupVisibility(false),
        click: onSetNitrogenMarker,
      }
    : undefined;

  return (
    <>
      {!!(imageUrl && !isProductivityMap) && (
        <FluroImageOverlay
          url={imageUrl}
          isZoning={isZoningEnabled}
          interactive={true}
          eventHandlers={eventHandlers}
          onErrorCallback={onError}
          lRef={onSetOriginalImageOverlayRef}
          onStartLoadBinary={() => loadBinaryEffect(Status.Pending)}
          onEndLoadBinary={() => loadBinaryEffect(Status.Done)}
          bounds={
            plantingAreaBox
              ? [
                  [plantingAreaBox[1], plantingAreaBox[0]],
                  [plantingAreaBox[3], plantingAreaBox[2]],
                ]
              : [
                  [field.SouthLat, field.WestLon],
                  [field.NorthLat, field.EastLon],
                ]
          }
        />
      )}

      {soilMapLayer && field.soilLayer && (
        <FieldSoilMapOverlay id={field.ID} data={field.soilLayer} />
      )}

      {drawingEntity === 'pivot' && pivotCoordinates && (
        <Marker
          icon={defaultMarkerIcon}
          position={Leaflet.latLng(pivotCoordinates?.Lat, pivotCoordinates?.Lon)}
        />
      )}

      {nitrogenUrl ? (
        <BinaryReader getPixelHandler={pixelHandler} baseSrc={nitrogenUrl.classify} />
      ) : null}

      {nitrogenPopupVisible && nitrogenHandler ? (
        <NitrogenPopup value={nitrogenValue} position={nitrogenPopupPosition} />
      ) : null}

      {/* Nitrogen tooltip markers */}
      {isNitrogenFeature ? <NitrogenMarkersOverlay /> : null}

      {/* Zoning points */}
      <ZoningPoint />
    </>
  );
};

export default MainFieldOverlay;
