import React, {memo, useCallback, useEffect, useMemo, useState} from 'react';
import Dialog from 'components/fluro-dialog';
import {dialogToggle, DialogType} from 'modules/helpers';
import {genKey} from '_utils/pure-utils';
import './info-dialog.scss';
import messages from './popups';
import type {Messages} from './types';
import {useAppDispatch, useAppSelector} from '../../_hooks';
import {selectCurrentDemoFarmURL, selectCurrentFarmId} from '../map/reducer/selectors';
import {selectDialogData} from '../../modules/helpers/selectors';

const InfoDialog = memo(() => {
  const dispatch = useAppDispatch();
  const id = useMemo(() => genKey(), []);
  const dialog = useAppSelector(s => selectDialogData(s, DialogType.info));
  const currentDemoFarmsURL = useAppSelector(selectCurrentDemoFarmURL);
  const farmId = useAppSelector(selectCurrentFarmId);

  const dialogHandler = useCallback(
    (value: boolean) => dispatch(dialogToggle(DialogType.info, value)),
    []
  );
  const [msg, setMsg] = useState('');

  // show dialog if open from (fields dropdown) /selector-fields.ts
  useEffect(() => {
    if (dialog.payload && messages[dialog.payload as keyof Messages]) {
      setMsg(dialog.payload);
    }
  }, [dialog.payload]);

  useEffect(() => {
    dialogHandler(false);
  }, [farmId]);

  const message = useMemo(() => messages?.[msg as keyof Messages], [msg, messages]);

  const close = useCallback(() => {
    dialogHandler(false);
  }, []);

  const reset = useCallback(() => {
    if (!currentDemoFarmsURL) return;
    window.location.assign(
      `${window.location.origin}${window.location.pathname}${currentDemoFarmsURL}`
    );
  }, [currentDemoFarmsURL]);

  return (
    <Dialog
      id={`info-dialog-${id}`}
      visible={dialog.visible}
      title={message?.title || 'Info'}
      focusOnMount={false}
      isDraggable
      onHide={close}
      autosizeContent={false}
      width={message && message?.width ? message?.width : 500}
      portal
      borderedTitle
    >
      {message ? message.element({close, currentDemoFarmsURL, reset}) : msg}
    </Dialog>
  );
});

export default InfoDialog;
