import styled from 'styled-components';
import colors from 'variables.scss';

export const MainTheme = styled.label`
  & {
    cursor: pointer;
  }

  > input {
    position: absolute;
    z-index: -1;
    opacity: 0;
  }

  > div {
    display: inline-flex;
    align-items: center;
    user-select: none;
    font-size: 1rem;
  }

  &.halfChecked > div::before {
    content: '\\e909';
    color: ${colors['main-green-600']};
  }

  > div::before {
    content: '\\e835';
    font-size: 1.5rem;
    font-family: 'Material Icons';
    color: ${colors['main-gray-600']};
    display: inline-block;
    flex-shrink: 0;
    flex-grow: 0;
  }

  & .label {
    margin-left: 0.5rem;
  }

  > input:not(:disabled):not(:checked) + div:hover::before {
    color: ${colors['main-green-600']};
  }

  > input:checked + div::before {
    color: ${colors['main-green-600']};
    content: '\\e834';
  }

  > input:disabled + div::before {
    color: ${colors['main-gray-600']};
  }
`;
