import {t} from 'i18n-utils';
import type {TFeature} from 'types';

export const getFeaturesLabels = (): Record<TFeature, string> => ({
  farm: t({id: 'farm.title', defaultMessage: 'Farm set-up'}),
  'crop-performance': t({id: 'Crop Performance', defaultMessage: 'Crop Performance'}),
  analytics: t({id: 'Analytics', defaultMessage: 'Analytics'}),
  crop: t({id: 'Areas of Interest', defaultMessage: 'Areas of Interest'}),
  'data-layers': t({id: 'Data Layers', defaultMessage: 'Data Layers'}),
  'sustainability-insights': t({
    id: 'Sustainability insights',
    defaultMessage: 'Sustainability insights',
  }),
  carbon: t({id: 'Carbon', defaultMessage: 'Carbon'}),
  monitoring: t({id: 'Monitoring', defaultMessage: 'Monitoring'}),
  enrollment: t({id: 'Enrollment', defaultMessage: 'Enrollment'}),
  'mrv-admin': t({id: 'Program settings', defaultMessage: 'Program settings'}),
  'mrv-home': t({id: 'Home', defaultMessage: 'Home'}),
  'mrv-admin-program-dashboard': t({id: 'Dashboards', defaultMessage: 'Dashboards'}),
  'mrv-admin-program-configure': t({id: 'Program settings', defaultMessage: 'Program settings'}),
  zoning: t({id: 'Zone Management', defaultMessage: 'Zone Management'}),
  tsp: t({id: 'Points', defaultMessage: 'Points'}),
  workspace: t({id: 'Workspace', defaultMessage: 'Workspace'}),
  profile: t({id: 'Profile', defaultMessage: 'Profile'}),
  faq: t({id: 'Help Center', defaultMessage: 'Help Center'}),
  admin: t({id: 'Regrow Internal Tools', defaultMessage: 'Regrow Internal Tools'}),
  'ci-admin': t({id: 'Settings', defaultMessage: 'Settings'}),
  'log-out': t({id: 'Log Out', defaultMessage: 'Log Out'}),
  'si-admin': t({id: 'Program Administration', defaultMessage: 'Program Administration'}),
  'si-home': '',
  'si-map': t({id: 'Geospatial Analysis', defaultMessage: 'Geospatial Analysis'}),
  'si-kpi': t({id: 'Sustainability Reporting', defaultMessage: 'Sustainability Reporting'}),
  'si-abatement-potential': t({
    id: 'Abatement Potential Simulations',
    defaultMessage: 'Abatement Potential Simulations',
  }),
  'si-supply-shed': t({
    id: 'Supply sheds',
    defaultMessage: 'Supply Sheds',
  }),
  'si-comparison-dashboard': t({
    id: 'Supply Shed Comparison',
    defaultMessage: 'Supply Shed Comparison',
  }),
  'si-plan-dashboard': t({id: 'Plan Dashboard', defaultMessage: 'Plan Dashboard'}),
  'si-data-export': t({id: 'Data Export', defaultMessage: 'Data Export'}),
  'si-planning-reports': t({id: 'Scenario Modeling', defaultMessage: 'Scenario Modeling'}),
});
