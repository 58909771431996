import React from 'react';
// eslint-disable-next-line no-restricted-imports
import type {ButtonProps} from 'react-md';
import {Button} from 'react-md';
import './index.scss';
import cn from 'classnames';
import {LoadingIcon} from 'containers/mrv/icons';

export type FluroButtonProps = ButtonProps & {
  /**
   * Remove the box-shadow.
   */
  blank?: boolean;
  /**
   * Tandem for the blank, makes the background-color: transparent;
   */
  transparent?: boolean;
  noMargin?: boolean;
  noPadding?: boolean;
  /**
   * Wrap the button inside <ReadOnly> from 'components/read-only-container/read-only-container'.
   */
  readonly?: boolean;
  /**
   * Sets the <ReadOnly> width to 100%.
   */
  readonlyFullWidth?: boolean;
  /**
   * Use main-gray-300 border when blank=true && disabled=false.
   */
  grayBorder?: boolean;
  /**
   * Centers the icon inside of an icon button.
   */
  centerIconInside?: boolean;
  /**
   * Shows a loading state, also disables the button.
   */
  loading?: boolean;
  skin?: 'warning';
};

export const FluroButton = ({
  readonly = false,
  readonlyFullWidth,
  blank = false,
  transparent = false,
  noMargin,
  noPadding,
  className = '',
  secondary,
  disabled,
  iconEl,
  grayBorder,
  icon,
  primary,
  centerIconInside,
  loading,
  skin,
  ...otherProps
}: FluroButtonProps) => {
  return (
    <Container isFullWidth={Boolean(readonlyFullWidth)} readonly={Boolean(readonly)}>
      <Button
        {...otherProps}
        icon={icon}
        primary={primary}
        secondary={secondary}
        disabled={disabled || loading}
        iconEl={loading ? <LoadingIcon /> : iconEl}
        className={cn(`fluro-button ${className}`, {
          blank,
          noMargin,
          noPadding,
          transparent,
          secondary,
          disabled,
          warning: skin === 'warning',
          'with-icon': !!iconEl && !icon, // use with-icon styles only for real btns not for circled icons
          'gray-bordered': grayBorder,
          'center-icon': Boolean(icon && centerIconInside),
          'primary-icon': Boolean(icon && primary),
        })}
      />
    </Container>
  );
};

type ContainerProps = {
  children: React.ReactElement;
  readonly: boolean;
  isFullWidth: boolean;
};

const Container = ({children, readonly, isFullWidth}: ContainerProps) => {
  if (readonly) {
    const ReadOnly = React.lazy(() => import('components/read-only-container/read-only-container'));
    return <ReadOnly fullWidth={isFullWidth}>{children}</ReadOnly>;
  }
  return <>{children}</>;
};
