import {FluroChipDropdown} from 'components/fluro-dropdown/fluro-chip-dropdown';
import {FluroSelectionControlGroup} from 'components/fluro-selection-control-group/fluro-selection-control-group';
import {useTranslation} from 'i18n-utils';
import type {ComponentType} from 'react';
import React from 'react';
import {useAppDispatch, useAppSelector} from '_hooks';
import {setFilter} from 'modules/sustainability-insights/actions';
import {
  selectIsDemographicsFilterSet,
  selectOperationSizeFilter,
  selectSummerCropTypesFilter,
} from 'modules/sustainability-insights/selectors';

type Props = {};

const operationSizeOptions = [
  {value: '1', label: '< 1000 ac'},
  {value: '2', label: '1001 - 3000 ac'},
  {value: '3', label: '3001 - 10,000 ac'},
  {value: '4', label: '>10,000 ac'},
];

export const OperationSizeFilter: ComponentType<Props> = () => {
  const t = useTranslation();
  const dispatch = useAppDispatch();
  const operationSize = useAppSelector(selectOperationSizeFilter);
  const summerCropTypes = useAppSelector(selectSummerCropTypesFilter);
  const fieldsFilterSet = useAppSelector(s => selectIsDemographicsFilterSet(s, 'fieldAdopterType'));

  const handleFilterChange = (value: string) => {
    const payload = {operationSize: value};
    dispatch(setFilter(payload));
  };

  const handleClear = () => {
    dispatch(setFilter({operationSize: ''}));
  };

  const values = operationSize && operationSize.split(',');
  const label = values?.length
    ? `${operationSizeOptions.find(i => i.value === values[0])?.label}${
        values.length > 1 ? ', ...' : ''
      }`
    : t('Operation Size');

  return (
    <FluroChipDropdown
      active={!!operationSize}
      className="grower-demographics-chip-dropdown"
      label={label}
      onClear={handleClear}
      disabled={Boolean(summerCropTypes || fieldsFilterSet)}
    >
      <FluroSelectionControlGroup
        id="operation-size-check"
        name="operationSize"
        type="checkbox"
        value={operationSize}
        onChange={handleFilterChange}
        controls={operationSizeOptions}
      />
    </FluroChipDropdown>
  );
};
