import type {InterventionOptions, CropUnits, APReportStatus} from 'containers/si/types';
import {SIScenarioKPIs} from 'containers/si/types';
import type {
  EmissionsFactorDetailsKey,
  OutcomeKPI,
  PracticeKPI,
  ReportType,
  TabAccess,
  InterventionType,
  Model,
  Protocol,
} from 'containers/si/api/apiTypes';

export const tabLabel: Record<TabAccess, string> = {
  admin: 'Program configuration',
  supply_shed: 'Supply Shed Configuration',
  kpi: 'Sustainability Reporting',
  map: 'Geospatial Analysis',
  abatement_potential: 'Abatement Potential',
  plan_dashboard: 'Plan Dashboard',
  data_export: 'Data Export',
  comparison_dashboard: 'Supply Shed Comparison',
};

export const SI_CROPS: {[cdl_id: string]: string} = {
  36: 'alfalfa',
  21: 'barley',
  34: 'rapeseed',
  31: 'canola',
  1: 'corn',
  2: 'cotton',
  42: 'dry_beans',
  32: 'flax',
  28: 'oats',
  37: 'hay',
  10: 'peanuts',
  53: 'peas',
  13: 'popcorn',
  43: 'potatoes',
  229: 'pumpkins',
  3: 'rice',
  27: 'rye',
  4: 'sorghum',
  5: 'soybeans',
  41: 'sugar_beets',
  6: 'sunflowers',
  12: 'sweet_corn',
  22: 'durum_wheat',
  23: 'wheat_spring',
  24: 'wheat_winter',
  75: 'almonds',
  69: 'grapes',
  56: 'hops',
  61: 'fallow',
};

export const outcomeKPILabel: Record<OutcomeKPI, string> = {
  ghg_emission: 'GHG emission',
  emission_factor: 'Emission Factors (3)',
  ef_book_values: 'EF Book Values',
  soc_sequestration: 'SOC sequestration',
  yield: 'Yield',
};

export const practiceKPILabel: Record<PracticeKPI, string> = {
  cover_cropping: 'Cover crops',
  crop_rotation: 'Crop rotation',
  tillage: 'Tillage',
  fertilizer_usage: 'Fertilizer Application',
  living_root: 'Living root',
  lulc: 'LULC',
};

export const SIReportModelLabel: Record<Model, string> = {
  dndc: 'DNDC v9.5',
  mems: 'MEMS v1.1',
};

export const SIReportProtocolLabel: Record<Protocol, string> = {
  vm0042: 'VM0042',
  CAR_SEP: 'CAR SEP v1',
};

export const SIReportTypeLabel: Record<ReportType, string> = {
  abatement_potential: 'Abatement Potential',
  program_plan: 'Program Plan',
};

export const SIScenarioKPIsLabel: Record<SIScenarioKPIs, string> = {
  ef_reductions: 'EF change',
  ghg_reductions: 'Reductions (ΔGHG)',
  soc_removals: 'Removals (ΔSOC)',
  net_ghg: 'Net emissions Δ',
};

export const TOOLTIPS = {
  KPI: {
    cropArea:
      'A crop type is defined as the commodity or cash crop that is cultivated during the main growing season. Sustainability insights currently includes data for over 30 different crop types. Please see our product documentation in the Sustainability Resources tab for the full list.',
    coverCrops:
      'Cover crop: a crop grown for the protection and enrichment of the soil during the fall, winter, or spring seasons.',
    tillage:
      'Tillage: a soil management approach, conservation tillage (no-till or reduced tillage) for regenerative agriculture fields aims to minimize the frequency or intensity of a tillage operation event in an effort to promote certain economic and environmental benefits.',
    fertilizerApplication: {
      card: 'Fertilizer: a substance containing one or more recognized plant nutrients applied to crops to provide a production benefit, where the production benefits should exceed any temporary negative impact on soil biology.',
      pctUp: '',
      pctDown: '',
    },
    ghg: {
      card: 'Greenhouse gas emissions: N20 and CH4 emissions from crop production, regenerative agriculture practices have the ability to reduce greenhouse gas emissions from crop production and this is generally referred to as “reductions”.',
      pctUp: 'GHG increase',
      pctDown: 'GHG reduction',
    },
    soc: {
      card: 'Soil Organic Carbon: the ability of soil to store carbon, regenerative agriculture practices have the ability to help accelerate the soil’s ability to remove greenhouse gas from the atmosphere and this is generally referred to as “removals”.',
      pctUp: 'Soil Carbon Gain',
      pctDown: 'Soil Carbon Loss',
    },
    netEmissions: {
      card: 'Net emissions: the combination of GHG emissions and SOC sequestration into a singular value representing the net impact on the soil from agricultural activities.',
      pctUp: 'Net emission increase',
      pctDown: 'Net emission reduction',
    },
    yield:
      'Yield: the measurement of the amount of agricultural production harvested per unit of land area.',
    fieldEmissionFactor:
      'Field emission factor: a singular, representative value of GHG / yield that attempts to quantify the amount of greenhouse gasses that is released to the atmosphere that is associated with a set of field activities such as the planting, fertilization and harvesting of a crop.',
    fieldNetEmissionFactor:
      'Field net emission factor: a singular, representative value of net emissions (GHG and SOC) per yield that quantifies the net amount of greenhouse gases released into the atmosphere that is associated with a set of field activities such as the planting, fertilization and harvesting of a crop.',

    fertilizerEmissionFactor:
      'Fertilizer emission factor: a singular, representative value of fertilizer application / yield that attempts to quantify the amount of greenhouse gasses that is released to the atmosphere to produce the amount of fertilizer that is applied to fields each year.',
    livingRoot: {
      sparklineChart:
        'The living root KPI represents if a plant is above the ground to protect the soil from erosion and provides potential for grazing and weed control for the following crop while helping to reduce leaching of nutrients and improve soil infiltration.',
      barChart:
        'The Regrow OpTIS scientific model calculates the health of the living root by mapping satellite imagery pixels of continuous living cover from the Normalized Difference Vegetation Index (NDVI) on an averaged monthly time series. The confidence in this statistic is derived from the availability of usable satellite imagery.',
    },
    cropRotation:
      'The crop rotation KPI has three sub-metrics to measure the various components of crop diversity that help improve the efficiency of the crops, minimize soil wear and control the development of diseases and pests. Each sub-metric is based on a temporal crop sequence analysis for each field segment considering the sequence of crops and rotations (including fallow) over a 5 year rolling historical time series.',
    lulc: 'The land-use land change KPI measures if there is an alteration in the way land is managed by humans or a change in certain continuous characteristics of the land such as vegetation type, soil properties, etc. The current measurement approach is via an OpTIS grassland / grazing scientific model to track the land use change that occurs when grasslands are converted for agricultural production.',
    efStackedBarChart:
      'The emission factor is shown in collaboration with Quantis as the book value number is the number from the World Food LCA Database with no adjustments (per country when available, otherwise global). Regrow baseline replaces the Field Emissions and Fertilizer components with Regrow calculated values specific to the supply shed. For clarity, any component in the black to grey spectrum is NOT updated by Regrow. This chart is sorted according to the largest book value number per the filter settings at the top of the dashboard.',
  },
  dashboard: {
    reductionsAndRemovals: 'Intervention scenarios and crops by % reduction and removal',
  },
};

export const PLANNING_REPORT_VOLUME_OPTIONS: Record<CropUnits, string> = {
  kg: 'Kilograms (kg)',
  lb: 'Pounds (lbs)',
  mt: 'Metric Tons (mt)',
  t: 'Tons (t)',
};

export const PLANNING_REPORT_INTERVENTION_OPTIONS: Record<
  InterventionOptions,
  Array<keyof typeof PLANNING_REPORT_INTERVENTION_SCENARIOS>
> = {
  tillage: ['No-till on 100% of acres', 'Reduced till on 100% of acres'],
  cover_cropping: ['Cover crop on 100% of acres'],
  fertilizer_usage: ['Reduce N by 20%'],
};

export const PLANNING_REPORT_INTERVENTION_SCENARIOS: Record<
  string,
  {
    intervention_type: InterventionType;
    percentage: number;
    interventionOption: InterventionOptions;
  }
> = {
  'No-till on 100% of acres': {
    intervention_type: 'no_till',
    percentage: 100,
    interventionOption: 'tillage',
  },
  'Reduced till on 100% of acres': {
    intervention_type: 'reduce_till',
    percentage: 100,
    interventionOption: 'tillage',
  },
  'Cover crop on 100% of acres': {
    intervention_type: 'cover_crop',
    percentage: 100,
    interventionOption: 'cover_cropping',
  },
  'Reduce N by 20%': {
    intervention_type: 'reduce_nitrogen',
    percentage: 20,
    interventionOption: 'fertilizer_usage',
  },
  'Use 30% manure': {
    intervention_type: 'use_manure',
    percentage: 30,
    interventionOption: 'fertilizer_usage',
  },
};
export const PLANNING_REPORT_INTERVENTION_REVERSE_MAPPING: Record<
  InterventionType,
  {
    label: (percentage: number) => string;
    interventionOption: InterventionOptions;
  }
> = {
  add_till: {
    label: (percentage: number) => `Add till on ${percentage}% of acres`, // TODO: this copy is not verified
    interventionOption: 'tillage',
  },
  no_till: {
    label: (percentage: number) => `No-till on ${percentage}% of acres`,
    interventionOption: 'tillage',
  },
  reduce_till: {
    label: (percentage: number) => `Reduced till on ${percentage}% of acres`,
    interventionOption: 'tillage',
  },
  cover_crop: {
    label: (percentage: number) => `Cover crop on ${percentage}% of acres`,
    interventionOption: 'cover_cropping',
  },
  reduce_cover: {
    label: (percentage: number) => `Reduced cover crop on ${percentage}% of acres`, // TODO: this copy is not verified
    interventionOption: 'cover_cropping',
  },
  reduce_nitrogen: {
    label: (percentage: number) => `Reduce N by ${percentage}%`,
    interventionOption: 'fertilizer_usage',
  },
  use_manure: {
    label: (percentage: number) => `Use ${percentage}% manure`,
    interventionOption: 'fertilizer_usage',
  },
};

// Not currently used on the Scenario Modeling / Planning Report List, but will be in the future
export const PLANNING_REPORT_STATUS: Record<APReportStatus, string> = {
  ACCEPTED: 'In Review',
  IN_PROGRESS: 'In Progress',
  COMPLETE: 'Completed',
};

export const PLANNING_REPORT_TYPE_OPTIONS: {label: string; value: ReportType}[] = [
  {label: SIReportTypeLabel.abatement_potential, value: 'abatement_potential'},
  {label: SIReportTypeLabel.program_plan, value: 'program_plan'},
];

export const PLANNING_REPORT_MODEL_OPTIONS: {label: string; value: Model}[] = [
  {label: SIReportModelLabel.dndc, value: 'dndc'},
  {label: SIReportModelLabel.mems, value: 'mems'},
];

export const PLANNING_REPORT_PROTOCOL_OPTIONS: {label: string; value: Protocol}[] = [
  {label: SIReportProtocolLabel.vm0042, value: 'vm0042'},
];

export const REPORT_DASHBOARD_RANK_OPTIONS_AP: {label: string; value: SIScenarioKPIs}[] = [
  {label: SIScenarioKPIsLabel.ghg_reductions, value: SIScenarioKPIs.ghg_reductions},
  {label: SIScenarioKPIsLabel.soc_removals, value: SIScenarioKPIs.soc_removals},
  {label: SIScenarioKPIsLabel.net_ghg, value: SIScenarioKPIs.net_ghg},
  {label: SIScenarioKPIsLabel.ef_reductions, value: SIScenarioKPIs.ef_reductions},
];

export const REPORT_DASHBOARD_RANK_OPTIONS_PP: {label: string; value: SIScenarioKPIs}[] = [
  {label: SIScenarioKPIsLabel.ghg_reductions, value: SIScenarioKPIs.ghg_reductions},
  {label: SIScenarioKPIsLabel.soc_removals, value: SIScenarioKPIs.soc_removals},
  {label: SIScenarioKPIsLabel.net_ghg, value: SIScenarioKPIs.net_ghg},
];

export const REPORT_DASHBOARD_EF_DETAILS: Record<
  EmissionsFactorDetailsKey,
  {label: string; color: string; order: number}
> = {
  ghg_emissions_factor: {
    label: 'Field emissions from fertilizer application',
    color: '#E1AA58',
    order: 9,
  },
  fert_emissions_factor: {
    label: 'Fertilizer production',
    color: '#ECE071',
    order: 8,
  },
  trace_metals: {label: 'Trace metals', color: '#F1F1F1', order: 7},
  crop_residues: {label: 'Crop residues management', color: '#E0E0E0', order: 6},
  seeds_seedlings_orchards: {label: 'Seeds, seedlings and orchard', color: '#C2C2C2', order: 5},
  irrigation: {label: 'Irrigation', color: '#909090', order: 4},
  luluc: {label: 'LULUC', color: '#7A7A7A', order: 3},
  plant_protection: {label: 'Plant protection', color: '#606060', order: 2},
  other: {label: 'Other inputs and farm activities', color: '#464646', order: 1},
};

export const KPI_DASHBOARD_EF_DETAILS: Record<
  EmissionsFactorDetailsKey,
  {label: string; color: string; order: number}
> = {
  ...REPORT_DASHBOARD_EF_DETAILS,
  ghg_emissions_factor: {
    label: 'Field emissions',
    color: '#E1AA58',
    order: 9,
  },
  fert_emissions_factor: {
    label: 'Fertilizer',
    color: '#ECE071',
    order: 8,
  },
  crop_residues: {label: 'Residue management', color: '#E0E0E0', order: 6},
  seeds_seedlings_orchards: {label: 'Seeds', color: '#C2C2C2', order: 5},
  other: {label: 'Other', color: '#464646', order: 1},
};

export const REPORT_DASHBOARD_EF_BAR_LABELS = {
  book: 'Book value',
  baseline: 'Regrow baseline',
  scenario: 'With interventions',
};
export const commonPieChartProps = {
  colors: {datum: 'data.color'},
  innerRadius: 0.7,
  padAngle: 4,
  cornerRadius: 0,
  activeOuterRadiusOffset: 5,
  borderWidth: 0,
  enableArcLabels: false,
  arcLinkLabelsSkipAngle: 10,
  arcLinkLabelsDiagonalLength: 12,
  arcLinkLabelsStraightLength: 5,
  arcLinkLabelsTextColor: '#333333',
  arcLinkLabelsThickness: 0.5,
  arcLinkLabelsColor: 'black',
  arcLabelsSkipAngle: 10,
};

export const PRECISION_UNITS = 3;

export const KG_PER_M2_TO_TONNES_PER_HECTARE = 10;

export const KG_PER_M2_TO_TONNES_PER_ACRE = 4.046856422400006;

export const EMPTY_EMISSION_FACTORS = {
  fert_emissions_factor: 0,
  ghg_emissions_factor: 0,
  trace_metals: 0,
  crop_residues: 0,
  seeds_seedlings_orchards: 0,
  irrigation: 0,
  luluc: 0,
  plant_protection: 0,
  other: 0,
};
export const KPI_FETCH_DEBOUNCE_MS = 1500;
