import Server from './server';
import type {AxiosPromise} from 'axios';

export class FieldsApi {
  // Checks for spatial intersection of fields.
  // Returns a list of tuples. Each tuple is at least two intersecting field ids.
  // FieldId is not kml_id, but field_id.
  static fieldsIntersecting(fieldIds: number[]): AxiosPromise<{status: 'ok'; result: number[][]}> {
    return Server.get(`fields/intersecting?field_ids=${fieldIds}`, {
      params: {__skipPreloader: true},
    });
  }
}

export default FieldsApi;
