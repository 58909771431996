// @ts-nocheck
import {Flex} from 'components';
import {Col} from 'components/flex';
import {IndeterminateCheckboxIcon, UncheckedCheckboxIcon} from 'components/fluro-icons';
import type {ComponentType} from 'react';
import React, {useState} from 'react';
import {FontIcon, SelectionControl} from 'react-md';
import type {Option} from 'types';

export interface SelectedEvent {
  selected: boolean;
  value: any;
  values: any[] | null;
}

export interface GroupSelectedEvent extends SelectedEvent {
  type: 'group';
}

export interface OptionSelectedEvent extends SelectedEvent {
  type: 'option';
  groupValue: SelectedEvent['value'];
  selectedOptionsLength: number;
}

export type Props = {
  id: string;
  label: Option['label'];
  name: string;
  options?: Option[];
  value: Option['value'];
  search?: string;
  selectedMap: Record<Option['value'], boolean>;
  onChange: (selected: boolean | GroupSelectedEvent | OptionSelectedEvent, e: Event) => void;
};

export const OptionsGroup: ComponentType<Props> = ({
  id,
  name,
  value: groupValue,
  label,
  options,
  search,
  selectedMap,
  onChange,
}) => {
  const [expand, setExpand] = useState(false);
  const selectedOptions: number[] = [];
  const searchMatchesPrimaryOption = !search || label.toLowerCase().includes(search.toLowerCase());

  const handleGroupSelect = (selected: boolean, event: Event) => {
    const values = options?.map(o => o.value) || null;
    onChange(
      {
        selected,
        value: groupValue,
        values,
        type: 'group',
      },
      event
    );
  };

  const handleOptionSelect = (selected: boolean, event: Event) => {
    const {value} = event.target as HTMLInputElement;
    onChange(
      {
        groupValue,
        selected,
        selectedOptionsLength: selectedOptions?.length,
        type: 'option',
        value,
        values: [value],
      },
      event
    );
  };

  const optionsList = options?.length
    ? options
        .map((option: Option) => {
          const searchSecondaryOption =
            !search || option.label.toLowerCase().includes(search.toLowerCase());
          if (!searchMatchesPrimaryOption && !searchSecondaryOption) return null;

          const isSelected = !!selectedMap[option.value];
          if (isSelected) {
            selectedOptions.push(Number(option.value));
          }

          return (
            <SelectionControl
              key={option.value}
              checked={isSelected}
              type="checkbox"
              name={`${name}-${groupValue}`}
              id={option.value}
              label={option.label}
              value={option.value}
              onChange={handleOptionSelect}
            />
          );
        })
        .filter(Boolean)
    : null;
  if (options?.length && !optionsList?.length) return null;
  const expandable = !!optionsList;
  const isExpanded = expandable && (expand || search);
  const allSelected = Boolean(options?.length && selectedOptions.length === options.length);

  return (
    <div>
      <Flex alignItems="center" justifyContent="space-between" nowrap>
        <Col>
          <SelectionControl
            uncheckedCheckboxIcon={
              selectedOptions.length ? <IndeterminateCheckboxIcon /> : <UncheckedCheckboxIcon />
            }
            checked={Boolean(selectedMap[groupValue] || allSelected)}
            type="checkbox"
            name=""
            id={id || `${groupValue}-${name}`}
            label={`${label}${expandable ? ` (${selectedOptions?.length})` : ''}`}
            value={groupValue}
            onChange={handleGroupSelect}
          />
        </Col>
        {expandable && (
          <Col auto>
            <span role="button" className="pl-1 pr-1" onClick={() => setExpand(e => !e)}>
              <FontIcon>{isExpanded ? 'expand_less' : 'expand_more'}</FontIcon>
            </span>
          </Col>
        )}
      </Flex>
      {isExpanded && <div style={{marginTop: '-5px', marginLeft: '20px'}}>{optionsList}</div>}
    </div>
  );
};
