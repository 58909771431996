import type {ValidationWarning} from 'containers/mrv/monitoring/form/mrv-table/hacky-validation';
import type {Dispatch, SetStateAction} from 'react';
import {useEffect, useState} from 'react';
import type {RecentMRVProgram} from 'containers/menubar/types';

type LocalStorage = {
  ignoredWarnings: ValidationWarning[];
  ignoredSimilarWarnings: ValidationWarning[];
  unImpersonateRedirectUrl: string;
  recentMRVProgramsByUserId: Record<string, RecentMRVProgram[]>;
};

export function getLocalStorage<K extends keyof LocalStorage>(key: K): LocalStorage[K] | null {
  const item = localStorage.getItem(key);
  if (item) {
    return JSON.parse(item);
  }

  return null;
}

export function setLocalStorage<K extends keyof LocalStorage>(
  key: K,
  value: LocalStorage[K]
): void {
  localStorage.setItem(key, JSON.stringify(value));
}

export function removeLocalStorage<K extends keyof LocalStorage>(key: K): void {
  localStorage.removeItem(key);
}

/**
 * Think of it as a useState hook that also stores the updates in localStorage for retrieval on mount.
 */
export function useLocalStorage<K extends keyof LocalStorage>(
  key: K,
  initialValue: LocalStorage[K]
): [LocalStorage[K], Dispatch<SetStateAction<LocalStorage[K]>>] {
  const [value, setValue] = useState(() => getLocalStorage(key) ?? initialValue);

  useEffect(() => {
    setLocalStorage(key, value);
  }, [key, value]);

  return [value, setValue];
}

type SessionStorage = {};

export function getSessionStorage<K extends keyof SessionStorage>(
  key: K
): SessionStorage[K] | null {
  const item = sessionStorage.getItem(key);
  if (item) {
    return JSON.parse(item);
  }

  return null;
}

export function setSessionStorage<K extends keyof SessionStorage>(
  key: K,
  value: SessionStorage[K]
): void {
  sessionStorage.setItem(key, JSON.stringify(value));
}

export function removeSessionStorage<K extends keyof SessionStorage>(key: K): void {
  sessionStorage.removeItem(key);
}

/**
 * Think of it as a useState hook that also stores the updates in sessionStorage for retrieval on mount.
 */
export function useSessionStorage<K extends keyof SessionStorage>(
  key: K,
  initialValue: SessionStorage[K]
): [SessionStorage[K], Dispatch<SetStateAction<SessionStorage[K]>>] {
  const [value, setValue] = useState(() => getSessionStorage(key) ?? initialValue);

  useEffect(() => {
    setSessionStorage(key, value);
  }, [key, value]);

  return [value, setValue];
}
