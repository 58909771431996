import type {AppStore} from 'reducers';

import {createSelector} from '@reduxjs/toolkit';
import type {AnalyticsPoint, SamplingPoint} from 'containers/map/types';
import {SourceType, TrendsIndex} from 'containers/map/types';
import {getTheNextColorFromPredefinedList} from '_utils';
import {toFixedFloatUnsafe} from '_utils/number-formatters';
import {
  selectAllVisibleGeometries,
  selectPremiumAnomalies,
} from 'containers/map/features/anomalies/anomalies-selectors';
import {
  selectCurrentDate,
  selectCurrentSeason,
  selectInfoExt,
  selectRemoteSensingFilterProps,
} from 'containers/map/reducer/selectors';
import moment from 'moment';
import {selectMeasurement} from 'containers/login/login-selectors';
import {GLOBAL_APP_DATE_FORMAT, GLOBAL_FORMAT_DATE} from '_constants';
import {AnalyticRequestSourceType} from 'containers/map/actions/analytics-actions';
import {setOpacity} from '_utils/colors';

export const selectMapAnalytics = (s: AppStore) => s.map.analytics;
export const selectMapAnalyticsTrendsData = createSelector(
  [selectMapAnalytics],
  analytics => analytics.trendsData
);

export const selectAnalyticRequestSourceType = createSelector(
  [selectRemoteSensingFilterProps, selectInfoExt],
  (remoteSensingFilterProps, infoExt) => {
    const hasPlanetDate = !!infoExt.find(date => date.Type === SourceType.SatelliteHD);
    const hasSatelliteDate = !!infoExt.find(date => date.Type === SourceType.Satellite);

    let dataSource = AnalyticRequestSourceType.None;

    switch (true) {
      case hasSatelliteDate && // only Satellite dates available
        (!hasPlanetDate || remoteSensingFilterProps.includes(SourceType.SatelliteHD)):
        dataSource = AnalyticRequestSourceType.Satellite;
        break;
      case hasPlanetDate && // only Planet dates available
        (!hasSatelliteDate || remoteSensingFilterProps.includes(SourceType.Satellite)):
        dataSource = AnalyticRequestSourceType.Planet;
        break;

      case hasPlanetDate && // has both types of dates
        hasSatelliteDate &&
        !remoteSensingFilterProps.includes(SourceType.Satellite) && // and sources are not filtered
        !remoteSensingFilterProps.includes(SourceType.SatelliteHD):
        dataSource = AnalyticRequestSourceType.Both;
        break;
    }

    return dataSource;
  }
);

export const selectHasTrendsData = createSelector([selectMapAnalytics], analytics => {
  return Boolean([...analytics.fieldSmoothedData, ...analytics.trendsData].length);
});

export const selectAnalyticsItemColor = createSelector(
  [
    selectCurrentSeason,
    selectMapAnalytics,
    selectPremiumAnomalies,
    selectAllVisibleGeometries,
    (state: AppStore, additionalArray?: Array<any>) => additionalArray,
  ],
  (currentSeason, analytics, premiumAnomalies, allVisibleGeometries, additionalArray = []) => {
    const geometriesColors = allVisibleGeometries
      .filter(g => g.properties.color)
      .map(g => g.properties.color);

    const currentColorsTSP: string[] = Array.isArray(currentSeason?.tissueSampling)
      ? currentSeason?.tissueSampling
          .filter((tsp: SamplingPoint) => tsp?.properties?.color)
          .map((tsp: SamplingPoint) => tsp?.properties?.color || '') || []
      : [];
    const currentColorsAnalytics = analytics.addedPoints
      .filter(point => point.color)
      .map(point => point.color);

    const premiumAnomaliesColors = premiumAnomalies.list.map(anomaly => anomaly.properties.color);
    const currentColors = [
      ...currentColorsTSP,
      ...currentColorsAnalytics,
      ...geometriesColors,
      ...additionalArray,
      ...premiumAnomaliesColors,
    ];
    return getTheNextColorFromPredefinedList(currentColors);
  }
);

export const selectAnalyticCategories = createSelector([selectMapAnalytics], analytics => {
  return analytics.fieldSmoothedCategories.length
    ? analytics.fieldSmoothedCategories // pick this one first because the list of dates is bigger
    : analytics.categories;
});

export const selectAnalyticLabels = createSelector(
  [selectAnalyticCategories, selectMeasurement],
  (categories, measurement) => {
    return categories.map(date => moment(date).format(measurement === 'ha' ? 'DD-MMM' : 'MMM-DD'));
  }
);

export const selectAnalyticsPoints = createSelector(
  [selectMapAnalytics, selectAnalyticRequestSourceType],
  (analytics, sourceType): AnalyticsPoint[] => {
    return [...analytics.fieldSmoothedData, ...analytics.trendsData].filter(point => {
      if (
        sourceType === AnalyticRequestSourceType.Both || // pass all if source type === Both
        point.sourceType === sourceType // filter by source type
      ) {
        return true;
      }
    });
  }
);

export const selectAllAnalyticNDVIPoints = createSelector([selectAnalyticsPoints], points => {
  const pointColors: string[] = [];

  return points.filter(point => {
    if (point.index !== TrendsIndex.NDVI) return false; // get all from NDVI index, assume the list is complete
    if (pointColors.includes(point.color)) return false; // get all fields, filter duplicated other colors
    pointColors.push(point.color);

    return true;
  });
});

export const selectAnalyticHiddenPointColors = createSelector([selectMapAnalytics], analytics => {
  return analytics.hiddenPointColors;
});

export const selectAnalyticNotHiddenPoints = createSelector(
  [selectAnalyticsPoints, selectAnalyticHiddenPointColors],
  (points, hiddenColors) => {
    return points.filter(p => !hiddenColors.includes(p.color));
  }
);

export const selectAnalyticChartData = createSelector(
  [
    (s: AppStore, index: TrendsIndex) => index,
    selectAnalyticNotHiddenPoints,
    selectAnalyticCategories,
    selectAnalyticLabels,
  ],
  (index, notHiddenPoints, categories, labels) => {
    const pointsByIndex = notHiddenPoints.filter(point => point.index === index);

    return {
      labels,
      datasets: pointsByIndex.map(point => {
        const isField = point.type === 'field';
        const satelliteHD = point.sourceType === AnalyticRequestSourceType.Planet;
        const isFieldOrPolygon = ['field', 'polygon'].includes(point.type);

        const pointColor = satelliteHD ? setOpacity(point.color, 0.99) : point.color; // just make same colors different for the chart

        return {
          backgroundColor: pointColor,
          borderColor: pointColor,
          data: categories.map(date =>
            point.values[date] !== undefined ? toFixedFloatUnsafe(point.values[date], 3) : null
          ),
          type: 'line',
          lineTension: 0.2,
          borderWidth: isFieldOrPolygon ? 2 : 1.2, // line width
          borderDash: satelliteHD ? [4, 4] : [],
          borderDashOffset: 0.0,
          pointBorderWidth: satelliteHD || isFieldOrPolygon ? 2.5 : 1.5, // point at line radius
          pointHoverRadius: 5,
          pointHoverBackgroundColor: '#fff',
          pointHoverBorderColor: pointColor,
          pointHoverBorderWidth: 1,
          pointRadius: satelliteHD || isFieldOrPolygon ? 0 : 1,
          pointHitRadius: 10,
          pointLabel: isField ? 'Field avg.' : pointColor,
          fill: false,
        };
      }),
    };
  }
);

export const selectCurrentDateAsChartProp = createSelector([selectCurrentDate], date =>
  moment(date, GLOBAL_APP_DATE_FORMAT).format(GLOBAL_FORMAT_DATE)
);
