// @ts-nocheck
import type {PayloadAction} from '@reduxjs/toolkit';
import {createSlice} from '@reduxjs/toolkit';

export const filtersSlice = createSlice({
  name: 'monitoring/filters',
  initialState: {programs: []},
  reducers: {
    toggleFilter: (state, action: PayloadAction<{filterType: 'programs'; value: number}>) => {
      const {filterType, value} = action.payload;
      state[filterType] = state[filterType].includes(value)
        ? state[filterType].filter(item => item !== value)
        : [...state[filterType], value];
    },
  },
});
