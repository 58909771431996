import type {AxiosResponse} from 'axios';
import type {
  SICRDResponse,
  SIGeometriesRequestParams,
  SIGeometriesResponse,
  SIHUC10Response,
  SIHUC12Response,
  SIHUC8Response,
  SIMetricsRequestParams,
  SIMetricsResponse,
  SIPolicy,
  SIStatesCountiesResponse,
  SIStatesResponse,
  SummerCropTypesResponse,
} from 'modules/sustainability-insights/types';
import Server from './server';

export class SustainabilityInsightsAPI {
  static getSiMetrics(params: SIMetricsRequestParams): Promise<AxiosResponse<SIMetricsResponse>> {
    return Server.get('si/metrics', {
      params: {
        ...params,
        __skipPreloader: true,
      },
    });
  }

  static getStates(): Promise<AxiosResponse<SIStatesResponse>> {
    return Server.get('si/states', {
      params: {
        __skipPreloader: true,
      },
    });
  }

  static getCRDList(): Promise<AxiosResponse<SICRDResponse>> {
    return Server.get('si/states/crd', {
      params: {
        __skipPreloader: true,
      },
    });
  }

  static getCounties(): Promise<AxiosResponse<SIStatesCountiesResponse>> {
    return Server.get('si/states/counties', {
      params: {
        __skipPreloader: true,
      },
    });
  }

  static getHUC8List(): Promise<AxiosResponse<SIHUC8Response>> {
    return Server.get('/si/states/huc8', {params: {__skipPreloader: true}});
  }

  static getHUC10List(): Promise<AxiosResponse<SIHUC10Response>> {
    return Server.get('/si/states/huc10', {params: {__skipPreloader: true}});
  }

  static getHUC12List(): Promise<AxiosResponse<SIHUC12Response>> {
    return Server.get('/si/states/huc12', {params: {__skipPreloader: true}});
  }

  static loadPolices(): Promise<AxiosResponse<{result: SIPolicy[]}>> {
    return Server.get('si/policies_all', {
      params: {
        __skipPreloader: true,
      },
    });
  }

  static deletePolicy(userId: number) {
    return Server.delete(`si/policy?user_id=${userId}`, {
      params: {
        __skipPreloader: true,
      },
    });
  }

  static updatePolicy(policy: SIPolicy): Promise<AxiosResponse<{result: SIPolicy}>> {
    return Server.put(
      'si/policy',
      {...policy},
      {
        params: {
          __skipPreloader: true,
        },
      }
    );
  }

  static getUserPolicy(): Promise<AxiosResponse<{result: SIPolicy}>> {
    return Server.get(`si/policy`, {
      params: {
        __skipPreloader: true,
      },
    });
  }

  static getSummerCropTypes(): Promise<AxiosResponse<SummerCropTypesResponse>> {
    return Server.get(`si/summer_crop_types`, {
      params: {
        __skipPreloader: true,
      },
    });
  }

  static getGeometries(
    params: SIGeometriesRequestParams
  ): Promise<AxiosResponse<SIGeometriesResponse>> {
    return Server.get('si/geometries', {
      params: {
        ...params,
        __skipPreloader: true,
      },
    });
  }
}
