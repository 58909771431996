import {combineReducers} from 'redux';
import asyncRTKReducer from './async-rtk';
import asyncStatusesReducer from './async-statuses';
import dialogsReducer from './dialogs';
import filtersReducer from './filters';
import type {HelpersStore} from './types';
import {intercomReducer} from 'components/intercom/reducer';

export const reducer = combineReducers<HelpersStore>({
  asyncRTK: asyncRTKReducer,
  asyncStatuses: asyncStatusesReducer,
  dialogs: dialogsReducer,
  filters: filtersReducer,
  intercom: intercomReducer.reducer,
});
