import styled from 'styled-components';
import colors from 'variables.scss';

export const Wrapper = styled.div`
  .g-name {
    font-size: 14px;
    font-weight: 500;
    padding: 10px 0;
    margin-bottom: 10px;
    border-top: 1px solid #eeeeee;
    border-bottom: 1px solid #eeeeee;
  }

  .md-chip {
    margin-top: 15px;
    margin-right: 2px;
    margin-bottom: 5px;
    background: #f1f1f1;

    &.md-chip--remove:hover {
      cursor: pointer;
      .md-chip-text--hover {
        color: ${colors['main-app-color']};
      }
    }
  }
`;
