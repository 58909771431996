import type {ComponentType} from 'react';
import React from 'react';
import type {PopupProps} from 'react-leaflet/types/Popup';
import {Popup} from 'react-leaflet';
import cn from 'classnames';
import './fluro-map-popup.scss';

type Props = PopupProps & {};

export const FluroMapPopup: ComponentType<Props> = props => {
  const {className = '', children, ...otherProps} = props;
  return (
    <Popup className={cn(className, {'map-popup': true})} {...otherProps}>
      {children}
    </Popup>
  );
};
