import type {PayloadAction} from '@reduxjs/toolkit';
import {createSlice} from '@reduxjs/toolkit';
import type {SI2State} from '../types';
import type {SIMapStyleType} from 'containers/si/types';
import {mapboxMapStyle} from 'containers/si/types';

export const mapSlice = createSlice({
  name: 'si/map',
  initialState: {
    style: mapboxMapStyle.satellite,
    selection: {
      layerID: 'admin0',
    },
    initialView: {
      longitude: -90,
      latitude: 40,
      zoom: 4,
    },
  } as SI2State['map'],
  reducers: {
    switchMapStyle(state, action: PayloadAction<SIMapStyleType>) {
      const mapStyleName = action.payload;
      if (mapStyleName === 'road') {
        state.style = mapboxMapStyle.road;
      } else if (mapStyleName === 'satellite') {
        state.style = mapboxMapStyle.satellite;
      } else if (mapStyleName === 'light') {
        state.style = mapboxMapStyle.light;
      } else {
        throw new Error(`Unknown map style target: ${mapStyleName}`);
      }
    },
  },
});
