import React, {useCallback, useMemo} from 'react';
import {GeoJSON} from 'react-leaflet';
import {genKey} from '_utils/pure-utils';
import {selectCurrentSeason, selectGeometriesOnMap} from 'containers/map/reducer/selectors';
import {useAppSelector} from '_hooks';
import {selectCropColorById} from 'modules/global/selectors';

export const SeasonGeometryOverlay = () => {
  const currentSeason = useAppSelector(selectCurrentSeason);
  const geometriesOnMap = useAppSelector(selectGeometriesOnMap);
  const cropColor = useAppSelector(s => selectCropColorById(s, String(currentSeason?.cropType)));

  const id = useMemo(() => genKey(), [currentSeason]);

  const onEachFeature = useCallback((feature: any, layer: any) => {
    layer.setStyle({color: cropColor});
    layer.setStyle({fillOpacity: 0});
  }, []);

  return currentSeason?.geometry && geometriesOnMap ? (
    <GeoJSON key={id} data={currentSeason.geometry} onEachFeature={onEachFeature} />
  ) : null;
};

export default SeasonGeometryOverlay;
