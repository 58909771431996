// @ts-nocheck
import initialState from '../initial-state';
import type {IAction, IInitialMapState} from '../../types';

import {ActionTypes} from '../types';
import type {NRxZone} from 'containers/map/features/nrx';

export default (state = initialState, action: IAction): Partial<IInitialMapState> => {
  switch (action.type) {
    case ActionTypes.MAP_NRX_SET_LISTS_DATA: {
      return {
        ...state,
        listsData: {
          ...state.listsData,
          ...action.lists,
        },
      };
    }

    case ActionTypes.MAP_NRX_UPDATE_POP_UP_SETTINGS: {
      const {nrxTabRate, nrxResult} = state.nRecommendation;
      const notCurrentTabRate = nrxTabRate === 'variable' ? 'blanket' : 'variable';
      return {
        ...state,
        nRecommendation: {
          ...state.nRecommendation,
          nrxRecommendationSettings: {
            ...state.nRecommendation.nrxRecommendationSettings,
            ...action.value,
          },
          nrxResult: {
            ...nrxResult,
            [notCurrentTabRate]: {
              balanced: {},
              max_roi: {},
              max_yield: {},
            }, // reset not selected tab rate values, they will be requested once the tab is selected
          },
        },
      };
    }

    case ActionTypes.MAP_NRX_SET_RECOMMENDATION_RESULT: {
      return {
        ...state,
        nRecommendation: {
          ...state.nRecommendation,
          nrxResult: {
            ...state.nRecommendation.nrxResult,
            [state.nRecommendation.nrxTabRate]: action.value,
          },
        },
      };
    }

    case ActionTypes.MAP_NRX_UPDATE_RECOMMENDATION_PROPS:
      const {nrxResult, nrxTabRate, selectedObjective} = state.nRecommendation;
      return {
        ...state,
        nRecommendation: {
          ...state.nRecommendation,
          nrxResult: {
            ...nrxResult,
            [nrxTabRate]: {
              ...nrxResult[nrxTabRate],
              [selectedObjective]: {
                ...nrxResult[nrxTabRate][selectedObjective],
                features: nrxResult[nrxTabRate][selectedObjective].features.map((f: NRxZone) => {
                  if (f.properties.id === action.data.id) {
                    return {
                      ...f,
                      properties: {
                        ...f.properties,
                        ...action.data,
                      },
                    };
                  }
                  return f;
                }),
              },
            },
          },
        },
      };

    case ActionTypes.MAP_NRX_TOGGLE_RATE_TAB: {
      return {
        ...state,
        nRecommendation: {
          ...state.nRecommendation,
          nrxTabRate: action.value,
        },
      };
    }
    case ActionTypes.MAP_NRX_SELECT_OBJECTIVE: {
      return {
        ...state,
        nRecommendation: {
          ...state.nRecommendation,
          selectedObjective: action.value,
        },
      };
    }

    case ActionTypes.MAP_NRX_LOAD_SEASONS_DATA: {
      return {
        ...state,
        fields: action.fields,
        nRecommendation: {
          ...state.nRecommendation,
          nrxRecommendationSettings: action.nrxRecommendationSettings,
        },
      };
    }

    case ActionTypes.MAP_NRX_UPDATE_SEASON_NRX_DATA: {
      let fields = [...state.fields];
      const findSeason = (sId: any) =>
        Array.isArray(action.seasonID)
          ? action.seasonID.find(id => id === sId)
          : action.seasonID === sId;

      fields = fields.map(f => {
        if (Array.isArray(f.Seasons)) {
          f.Seasons = f.Seasons.map(s => {
            return findSeason(s.id) ? {...s, nrx: {...s.nrx, ...action.data}} : s;
          });
        }
        return f;
      });

      return {
        ...state,
        fields,
      };
    }

    case ActionTypes.MAP_NRX_FERTILIZER_APP_SET: {
      return {
        ...state,
        fields: state.fields.map(f => {
          if (action.nrxSeason.kmlID === f.ID) {
            return {
              ...f,
              Seasons: f.Seasons.map(s => {
                // update the nrxSeasonID, fertilizers list, recommendationDates
                // nrxSeasonID is updated each time we update some nrx season's values
                if (s.id === action.nrxSeason.seasonID) {
                  return {
                    ...s,
                    nrx: {
                      ...s.nrx,
                      ...action.nrxSeason,
                    },
                  };
                }
                return s;
              }),
            };
          }

          return f;
        }),
      };
    }

    case ActionTypes.MAP_NRX_MERGE_ZONES: {
      const {nrxResult, nrxTabRate, selectedObjective} = state.nRecommendation;
      return {
        ...state,
        nRecommendation: {
          ...state.nRecommendation,
          nrxResult: {
            ...nrxResult,
            [nrxTabRate]: {
              ...nrxResult[nrxTabRate],
              [selectedObjective]: {
                ...nrxResult[nrxTabRate][selectedObjective],
                features: action.zones,
              },
            },
          },
        },
      };
    }

    case ActionTypes.MAP_NRX_REVERT_MERGE_ZONES: {
      const {nrxResult, nrxTabRate, selectedObjective} = state.nRecommendation;
      return {
        ...state,
        nRecommendation: {
          ...state.nRecommendation,
          nrxResult: {
            ...nrxResult,
            [nrxTabRate]: {
              ...nrxResult[nrxTabRate],
              [selectedObjective]: {
                ...nrxResult[nrxTabRate][selectedObjective],
                features: action.zones,
              },
            },
          },
        },
      };
    }

    case ActionTypes.MAP_NRX_TOGGLE_SETTINGS_VISIBILITY: {
      return {
        ...state,
        nRecommendation: {
          ...state.nRecommendation,
          displaySettings: action.value, // don't forget to set displayResults to false toggleNRxResultsVisibility(false)
        },
      };
    }
    case ActionTypes.MAP_NRX_TOGGLE_RESULTS_VISIBILITY: {
      return {
        ...state,
        nRecommendation: {
          ...state.nRecommendation,
          displayResults: action.value, // don't forget to set displaySettings to false toggleNRxSettingsVisibility(false)
        },
      };
    }

    default:
      return state;
  }
};
