import React from 'react';
import styled from 'styled-components';

enum EType {
  DatePicker = 'Date picker',
  Dropdown = 'Dropdown',
  Number = 'Number',
  PasswordHidden = 'Password hidden',
  PasswordVisible = 'Password visible',
  Text = 'Text',
  Textarea = 'Textarea',
  DatePickerClearable = 'Date picker clearable',
  Search = 'Search',
}

type Props = React.InputHTMLAttributes<HTMLInputElement> & {
  size?: 'medium14';
  inputType?: EType;
  state?: 'Normal' | 'Placeholder' | 'Active' | 'Error';
  fullWidth?: boolean;
};

const FSInputElement = styled.input`
  box-sizing: border-box;

  /* Auto layout */
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px 16px;
  gap: 10px;

  height: 44px;

  /* FS Brand/Gray 300 */
  border: 1px solid #e0e0e0;
  border-radius: 4px;
`;

export const FSInput = ({fullWidth, id, name, ...rest}: Props) => {
  const inputId = id || name;
  const inputName = name || id;
  return (
    <FSInputElement
      {...rest}
      style={{width: fullWidth ? '100%' : 'initial'}}
      id={inputId}
      name={inputName}
    />
  );
};
