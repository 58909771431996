import type {PayloadAction} from '@reduxjs/toolkit';
import {createSlice} from '@reduxjs/toolkit';
import type {StageCompletion} from 'containers/mrv/types';
import {fetchStageCompletion} from '../thunks';
import type {MonitoringState} from '../types';
import {LoginActionTypes} from '../../../../login/types';

/**
 * The completion (progress) of a user filling the project values.
 */
export const completionSlice = createSlice({
  name: 'monitoring/completion',
  initialState: {} as MonitoringState['completion'],
  reducers: {
    setStageCompletion: (state, action: SetStageCompletionAction) => {
      state[action.payload.stageId] = action.payload.completion;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(LoginActionTypes.LOGOUT, _state => {
        return {} as MonitoringState['completion'];
      })
      .addCase<string, SetStageCompletionAction>(
        fetchStageCompletion.fulfilled.type,
        (state, action) => {
          state[action.payload.stageId] = action.payload.completion;
        }
      );
  },
});

type SetStageCompletionAction = PayloadAction<{stageId: number; completion: StageCompletion}>;
