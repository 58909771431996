// @ts-nocheck
import cn from 'classnames';
import type {ComponentType, LegacyRef, ReactElement} from 'react';
import React, {useEffect, useRef, useState} from 'react';
import {FontIcon} from 'react-md';
import {Carousel} from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
// import {useAppSelector} from '_hooks';
// import {useUrlParams} from '_hooks/use-url-params';
import {toFixedFloatUnsafe} from '_utils/number-formatters';
import {FluroStep} from './fluro-step';
import './fluro-steps.scss';
import {useUrlParams} from '_hooks/use-url-params';

export type FluroStepsItem = {
  label: any;
  value: string | number;
  icon?: string | ReactElement;
  progressValue?: number;
  disabled?: boolean;
};

type Props = {
  containerClassName?: string;
  onItemClick(value: string | number): void;
  selectedItem: string | number;
  greenSkin?: boolean;
  withProgress?: boolean;
  items: FluroStepsItem[];
  sliderPercentageSeed?: number;
};

const ITEM_WIDTH = 130; // duplicates CSS property width of an .item

export const FluroSteps: ComponentType<Props> = ({
  items,
  selectedItem,
  containerClassName = '',
  onItemClick,
  greenSkin = false,
  sliderPercentageSeed,
}) => {
  // const viewportWidth = useAppSelector(store => store.viewport.width);
  const [params] = useUrlParams<{tableView: 'true'}>();

  const containerRef: LegacyRef<HTMLDivElement> = useRef(null);

  const [useSlider, toggleUseSlider] = useState(false);
  const [slidePercentage, setSlidePercentage] = useState(100);
  const [selectedSlide, setSelectedSlide] = useState(0);

  useEffect(() => {
    if (!useSlider) return;
    const selectedItemIndex = items.findIndex(({value}) => value === selectedItem);
    setSelectedSlide(selectedItemIndex);
  }, [useSlider, selectedItem, selectedSlide, items, location]);

  const recalculateSliderState = () => {
    if (!containerRef.current) return;
    const cs = getComputedStyle(containerRef.current);
    const paddings = parseInt(cs.paddingLeft) + parseInt(cs.paddingRight);
    const containerWidth = containerRef?.current?.offsetWidth - paddings;
    const shouldDisplaySlider = items.length * ITEM_WIDTH - containerWidth > ITEM_WIDTH / 2; // enable slider only when list of items doesn't fit
    const slidePercentage = toFixedFloatUnsafe((ITEM_WIDTH / containerWidth) * 100, 0);
    toggleUseSlider(shouldDisplaySlider);
    setSlidePercentage(slidePercentage);
  };

  // useEffect(() => {
  //   recalculateSliderState();
  // }, [viewportWidth, items]);

  // useEffect(() => {
  //   setTimeout(recalculateSliderState, 500); // recalculate after transition
  // }, [params.tableView]);

  useEffect(() => {
    setTimeout(recalculateSliderState, 500); // recalculate after transition in case of tableView change
  }, [sliderPercentageSeed, items]);

  const InternalContainer = ({children}: {children: ReactElement[]}) => {
    if (useSlider) {
      return (
        <Carousel
          showThumbs={false}
          showIndicators={false}
          showStatus={false}
          className="fluro-steps-slider"
          centerMode
          infiniteLoop={false}
          centerSlidePercentage={slidePercentage}
          emulateTouch
          renderArrowNext={(click, hasNext) =>
            hasNext && (
              <FontIcon className={'control-btn next'} onClick={click}>
                navigate_next
              </FontIcon>
            )
          }
          renderArrowPrev={(click, hasPrev) =>
            hasPrev && (
              <FontIcon className={'control-btn prev'} onClick={click}>
                navigate_before
              </FontIcon>
            )
          }
          selectedItem={selectedSlide}
        >
          {children}
        </Carousel>
      );
    }

    return <>{children}</>;
  };
  return (
    <div
      ref={containerRef}
      className={cn(
        'fluro-steps-container',
        {
          [containerClassName]: !!containerClassName,
          greenSkin,
        },
        {smallStepperContainer: !params.tableView}
      )}
    >
      <InternalContainer>
        {items.map((item, i) => {
          const {icon, value, label, progressValue, disabled} = item;
          const itemIsActive = selectedItem === value;
          return (
            <FluroStep
              key={value}
              onClick={() => (!disabled ? onItemClick(value) : undefined)}
              active={itemIsActive}
              label={label}
              icon={icon}
              line={i > 0}
              progress={progressValue}
              disabled={disabled}
            />
          );
        })}
      </InternalContainer>
    </div>
  );
};

export default FluroSteps;

export const FluroStepsMemo = React.memo(FluroSteps);
