import React, {memo} from 'react';
import {Flex, Text} from 'components';
import {Circle} from 'components/shapes/circle';
import type {REPORT_DASHBOARD_EF_DETAILS} from 'containers/si/constants';
import {getTooltipValuesByKPI} from 'containers/si/planning-report/dashboard/utils/calculate-scenario';
import type {BarChartData} from './emission-factor';
import {useAppSelector} from '_hooks';
import {selectMeasurement} from 'containers/login/login-selectors';
import {DataRow} from './emmision-factor-styled-components';
import type {EmissionsFactorDetailsKey} from 'containers/si/api/apiTypes';

type EFTooltipProps = {
  details: typeof REPORT_DASHBOARD_EF_DETAILS[EmissionsFactorDetailsKey];
  detailedData: BarChartData['detailedData'];
  efComponent: EmissionsFactorDetailsKey;
};

export const EFTooltip = memo(({details, detailedData, efComponent}: EFTooltipProps) => {
  const measurement = useAppSelector(selectMeasurement);

  const {
    book,
    baseline,
    withInterventions: scenario,
  } = getTooltipValuesByKPI(detailedData.fullScenario, efComponent, true, 3, measurement);

  return (
    <div className="ef-tooltip" style={{left: '-100 !important'}}>
      <Flex
        direction="row"
        nowrap
        justifyContent="flex-start"
        alignItems="center"
        className="margin-bottom-8"
      >
        <Circle inline size={12} color={details?.color} id="ef-tooltip-circle" />
        <Text bold className="margin-bottom-0">
          {details?.label}
        </Text>
      </Flex>
      <Flex direction="column">
        {book !== undefined && (
          <DataRow>
            <Text className="margin-bottom-0">Book value</Text>
            <Text className="margin-bottom-0" bold>
              {book}
            </Text>
          </DataRow>
        )}
        <DataRow>
          <Text className="margin-bottom-0">Baseline</Text>
          <Text className="margin-bottom-0" bold>
            {baseline}
          </Text>
        </DataRow>
        <DataRow>
          <Text>With interventions</Text>
          <Text bold>{scenario}</Text>
        </DataRow>
      </Flex>
    </div>
  );
});
