// @ts-nocheck
import * as React from 'react';
import {useMemo, useState, useEffect, useCallback} from 'react';
import {Button} from 'react-md';
import {mutate} from 'swr';
import {useDispatch} from 'react-redux';
import {useAppSelector} from '_hooks';
import './enroll-fields.scss';
import {FormattedMessage, t} from 'i18n-utils';
import {
  EnrollmentStep,
  enrollFields,
  setEligibleRegionFields,
  setOverlapFields,
  removeFields,
} from '../../carbon-store';
import {
  allowToChangeToTheStep,
  toggleFieldsInsideFarm,
  useHistoryToRedirectToHome,
} from '../../../utils';
import {backToHomeLabel} from '../../../constants';
import {dialogToggle, DialogType} from 'modules/helpers';
import {toggleMapBar, toggleTableView} from 'containers/map/actions';
import {convertUnit, getFarmById, sortFieldsByProp} from '_utils';
import {toFixedFloatUnsafe} from '_utils/number-formatters';
import {FluroButton, InfoBlock, ReadOnly, Sticky} from 'components';
import {main_error_500} from '_utils/colors';
import {showNotification} from 'components/notification/notification';
import type {Farm} from 'containers/map/types';
import {reportError} from 'containers/error-boundary';
import cn from 'classnames';
import {mixpanelCarbon} from '_utils/mixpanel-utils';
import {selectEnrollmentReadOnly, selectEnrolledFieldObjects} from '../../carbon-store/selectors';
import {
  selectAllFieldsList,
  selectCurrentFarm,
  selectFieldGeometries,
  selectFieldsByFarmId,
  selectHighlightedFieldId,
  selectIsEditingMode,
} from 'containers/map/reducer/selectors';
import NewFields from 'containers/map/features/farm/new-fields/new-fields';
import {
  selectHasFarmsOrGroupsIds,
  selectIsAdmin,
  selectMeasurement,
  selectUserIsImpersonated,
} from 'containers/login/login-selectors';
import {selectFarmsList} from 'modules/farms/selectors';
import {useParsedMatchParams} from '_hooks/use-parsed-match-params';
import {FieldsApi, MRVApi} from '_api';
import {
  selectCurrentProject,
  selectCurrentProjectFarms,
  selectCurrentProjectFieldMapKmlId2MrvId,
  selectEnabledPhaseStages,
  selectMRVProgramById,
  selectPhase,
  selectStageFieldValuesMap,
} from '../../../monitoring/module/selectors';
import {ReadonlyMessage} from '../readonly-message';
import {ErrorIcon} from 'containers/map/icons';
import {MRVPhaseType} from 'containers/mrv/types';
import {selectHistoricPracticesStage} from 'containers/mrv/monitoring/module/enrollment.selectors';
import {
  deleteFarm,
  fetchStageCompletion,
  removeProjectFields,
} from '../../../monitoring/module/thunks';
import {selectProtectedAreaBoundaries} from 'modules/add-fields/selectors';
import {saveEnrolledFields} from '../../carbon-store/carbon-thunks-complex';
import {EnrollFieldsFieldItem} from './field-item';
import {ActionsButton} from './base';
import {FluroCheckbox} from 'components/fluro-checkbox/fluro-checkbox';
import {removeFarm} from 'modules/farms/actions';
import {FarmFieldsEditDialog} from './farm-fields-edit-dialog';
import {getMRVValuesBaseRequestKey} from 'containers/mrv/data/base';

export const EnrollFields = () => {
  const dispatch = useDispatch();
  const carbon = useAppSelector(s => s.carbon);
  const fieldsByFarmId = useAppSelector(selectFieldsByFarmId);
  const farms = useAppSelector(selectFarmsList);
  const mrvFarms = useAppSelector(selectCurrentProjectFarms);
  const currentFarm = useAppSelector(selectCurrentFarm);
  const measurement = useAppSelector(selectMeasurement);
  const fieldGeometries = useAppSelector(selectFieldGeometries);
  const isEditingMode = useAppSelector(selectIsEditingMode);
  const fields = useAppSelector(selectEnrolledFieldObjects);
  const allFields = useAppSelector(selectAllFieldsList);
  const kmlId2MrvId = useAppSelector(selectCurrentProjectFieldMapKmlId2MrvId);
  const hasFarms = useAppSelector(selectHasFarmsOrGroupsIds);
  const {projectId} = useParsedMatchParams();
  const {isReadOnly} = useAppSelector(selectEnrollmentReadOnly);
  const project = useAppSelector(selectCurrentProject);
  const highlightedFieldId = useAppSelector(selectHighlightedFieldId);
  const [confirming, setConfirming] = useState(false);
  const enrollmentStages = useAppSelector(s =>
    selectEnabledPhaseStages(s, MRVPhaseType.Enrollment)
  );
  const protectedAreaBoundaries = useAppSelector(selectProtectedAreaBoundaries);
  const userIsImpersonated = useAppSelector(selectUserIsImpersonated);
  const isAdmin = useAppSelector(selectIsAdmin);
  const canDeleteFields = isAdmin || userIsImpersonated;

  const backToHomePage = useHistoryToRedirectToHome();

  const program = useAppSelector(s => selectMRVProgramById(s, project?.program_id));
  const enrollment = useAppSelector(s => selectPhase(s, MRVPhaseType.Enrollment));
  const historicPracticesStage = useAppSelector(selectHistoricPracticesStage);
  const historicPracticesPerField = useAppSelector(s =>
    selectStageFieldValuesMap(s, historicPracticesStage?.id)
  );
  const kmlIdToMrvId = useAppSelector(selectCurrentProjectFieldMapKmlId2MrvId);

  const maxEnrollmentArea = enrollment?.params?.limit_project_ha || Infinity;

  const [collapsedFarms, setCollapsedFarms] = useState<{[farmId: number]: boolean}>({});
  const [farmFieldsEditOpen, setFarmFieldsEditOpen] = useState<number | null>(null);

  useEffect(() => {
    dispatch(toggleTableView(null));
  }, []);

  const {totalEnrolledFieldsArea, enrollmentMaxArea} = useMemo(() => {
    const totalArea = fields.reduce((acc, f) => acc + f.Area, 0);
    const area = convertUnit(measurement, 'ac', totalArea);
    const max = convertUnit(measurement, 'ac', maxEnrollmentArea) || Infinity;
    return {totalEnrolledFieldsArea: area, enrollmentMaxArea: max};
  }, [fields, measurement, maxEnrollmentArea]);

  const farmsToProcess = useMemo(() => {
    return Object.keys(fieldsByFarmId).reduce((acc, farmKey) => {
      const farmId = Number(farmKey);
      const farm = getFarmById(farms, farmId);

      if (!farm) return acc;

      return [...acc, farm];
    }, [] as Farm[]);
  }, [fieldsByFarmId, farms]);

  useEffect(() => {
    if (farmsToProcess) {
      setCollapsedFarms({[farmsToProcess[0]?.id]: true});
    }
  }, [farmsToProcess]);

  useEffect(() => {
    const eligibility: {[fieldId: number]: boolean} = {};
    allFields.forEach(f => {
      // const geom = fieldGeometries[f.MD5];
      // eligibility[f.ID] = featureCollectionContains(pppx as GeoJSON.FeatureCollection, geom);
      eligibility[f.ID] = true; // pass all fields for now https://regrowag.slack.com/archives/C02EZPYF399/p1642583309083700?thread_ts=1642575495.076600&cid=C02EZPYF399
    });
    dispatch(setEligibleRegionFields(eligibility));
  }, [allFields, fieldGeometries]);

  const toggleExpandFarm = (farmId: number, forceValue?: boolean) => {
    setCollapsedFarms(e => ({...e, [farmId]: forceValue !== undefined ? forceValue : !e[farmId]}));
  };

  const farmIsSelected = (farmId: number) => {
    const fieldIds = Object.keys(fieldsByFarmId[farmId]).map(Number);
    return fieldIds.length > 0 && fieldIds.every(fieldId => carbon.enrolledFields[fieldId]);
  };

  const toggleFarm = (farmId: number, forceValue?: boolean) => {
    const value = forceValue !== undefined ? forceValue : !farmIsSelected(farmId);
    const selection = toggleFieldsInsideFarm(
      fieldsByFarmId[farmId],
      carbon.eligibleRegionFields,
      value
    );
    if (value) {
      dispatch(enrollFields(selection));
      return;
    }

    const hasHistoricPractices = Object.keys(selection)
      .map(Number)
      .some(fieldId => {
        const mrvFieldId = kmlIdToMrvId[fieldId];
        return historicPracticesPerField[mrvFieldId]?.find(v => v.value && v.source === 'user');
      });
    if (!hasHistoricPractices) {
      dispatch(enrollFields(selection));
      return;
    }

    const userAgreesWithHistoricPracticesLoss = confirm(
      t({
        id: 'If you decide to enroll this farm again, you will need to re-enter its cropping practices.',
      })
    );
    if (!userAgreesWithHistoricPracticesLoss) return;
    dispatch(enrollFields(selection));
  };

  const addFields = useCallback(() => {
    dispatch(toggleMapBar(false));
    dispatch(dialogToggle(DialogType.addNewField, true, {projectId}));
  }, [dispatch, projectId]);

  const onConfirmSelection = async () => {
    if (isReadOnly) {
      backToHomePage();
      mixpanelCarbon.clickOnConfirmSelection();
      return;
    }
    try {
      setConfirming(true);
      const allowChangeStep = dispatch(allowToChangeToTheStep(EnrollmentStep.CropPractices));
      const overlaps = fields.length
        ? await FieldsApi.fieldsIntersecting(fields.map(f => f.FieldID))
        : null;

      if (overlaps && overlaps.data.result.length) {
        // TODO (stas): Have a map {fieldId: field}. Right now we only have {farmId: {kmlId: field}}.
        const overlapsKmlIds = overlaps.data.result.map(intersection =>
          intersection.map(fieldId => fields.find(f => f.FieldID === fieldId)?.ID)
        );

        showNotification({
          title: t({id: 'note.warning', defaultMessage: 'Warning'}),
          message: t({
            id: 'OverlappingFieldsMessage',
            defaultMessage:
              'Overlapping fields. Fields with an error message and a red outline on the map overlap another field. Please deselect one of them or edit the boundaries.',
          }),
          type: 'warning',
        });
        dispatch(setOverlapFields(overlapsKmlIds));
        return;
      }

      if (allowChangeStep) {
        // @ts-expect-error wrap the payload
        const saveFieldsError = await dispatch(saveEnrolledFields()).unwrap();
        if (saveFieldsError) return; // means has error

        // Revalidate the mrv values cache (set it to `undefined`),
        // so when the new fields are added, when the user enters the stage,
        // the values are gonna be re-fetched.
        mutate(
          key => typeof key === 'string' && key.startsWith(getMRVValuesBaseRequestKey(projectId)),
          undefined,
          {revalidate: false}
        );

        // Create the record year values for the new fields,
        // so when the user enters the stage, there are rows for them to fill.
        MRVApi.createRecordYears(projectId);

        enrollmentStages.forEach(stage => {
          // update stages completion status
          dispatch(fetchStageCompletion({projectId, stageId: stage.id}));
        });
        backToHomePage();

        mixpanelCarbon.clickOnConfirmSelection();
      }
    } catch (error) {
      reportError(error);
    } finally {
      setConfirming(false);
    }
  };

  const overlapIds = useMemo(() => new Set(carbon.overlapFields.flat()), [carbon.overlapFields]);

  return (
    <div className="enrollment__enroll-fields">
      <div className="description">
        {!allFields.length ? (
          <>
            {t({
              id: 'Lets start by selecting your fields. Select the import format you prefer.',
            })}
          </>
        ) : isReadOnly ? (
          <>
            {t(
              {
                id: 'Enroll.ReadOnlyHelpMessage',
                defaultMessage:
                  'Below are the fields you plan to implement new or expanded practices during the next growing season. You can view your selection but are unable to edit at this point because you are already enrolled in {program}.',
              },
              {program: program?.name}
            )}
          </>
        ) : (
          <>{t({id: 'Please select the fields you would like to enroll in this program.'})}</>
        )}
      </div>
      {/*
        When the user has no farms, we embed the new fields flow into the panel,
        instead of a regular dialog.
       */}
      {!hasFarms ? (
        <NewFields />
      ) : (
        <>
          <ReadonlyMessage />
          {allFields.length > 0 && (
            <div className="selected-fields-info">
              {t(
                {
                  id: 'Enroll.NumberSelectedFieldsWithArea',
                  defaultMessage:
                    '{selected}/{totalSelected} field(s) selected, {totalArea}{measurement}',
                },
                {
                  selected: fields.length,
                  totalSelected: allFields.length,
                  totalArea: totalEnrolledFieldsArea,
                  measurement: t({id: measurement}),
                }
              )}
            </div>
          )}
          {!isReadOnly && totalEnrolledFieldsArea > enrollmentMaxArea && (
            <InfoBlock
              icon={<ErrorIcon fill={main_error_500} />}
              mini
              appearance="error"
              color="error"
              className="mb-1 notice-warn-block"
            >
              <FormattedMessage
                id="Enroll.MaxAreaSizeMessage"
                defaultMessage="<span>You have reached the enrollment limit.</span> A maximum of {enrollmentMaxArea} can be enrolled. Deselect some fields from the list below to proceed."
                values={{
                  span: (msg: string) => <span className="title">{msg}</span>,
                  enrollmentMaxArea: enrollmentMaxArea + t({id: measurement}),
                }}
              />
            </InfoBlock>
          )}
          <div className="farm-list">
            {farmsToProcess.map(farm => {
              const farmId = farm.id;
              const availableFields = Object.values(fieldsByFarmId[farmId]);
              const fieldIds = Object.keys(fieldsByFarmId[farmId]).map(Number);
              const overlap = fieldIds.some(id => overlapIds.has(id));

              if (!availableFields.length) return null;

              const farmName =
                farms.find(f => f.id === farmId)?.name ||
                (currentFarm.id === farmId && currentFarm.name) || // if the list is not there and we're filling the current farm, use it
                '';
              const farmArea = convertUnit(
                measurement,
                'ac',
                availableFields.reduce((acc, f) => acc + f.Area, 0)
              );

              const classifiedFarmArea =
                farmArea > 1000 ? toFixedFloatUnsafe(farmArea / 1000, 1) + 'k ' : farmArea;

              const selectedFields = availableFields.filter(f => carbon.enrolledFields[f.ID]);
              const isFarmSelected = farmIsSelected(farmId);
              const isIneligibleFarm = availableFields.every(
                f => !carbon.eligibleRegionFields[f.ID]
              );
              const isCollapsed = collapsedFarms[farmId];
              // Don't bother sorting if the farm is not expanded.
              const sortedFields = isCollapsed
                ? sortFieldsByProp(availableFields, 'Name', 'string')
                : fields;
              const farmActions = [
                {
                  label: t({id: 'Edit name'}),
                  onClick: () =>
                    dispatch(
                      dialogToggle(DialogType.editFarmName, true, {
                        farmId,
                        farmName,
                        projectId,
                      })
                    ),
                },
                {
                  label: t({id: 'Move field(s) to another farm'}),
                  onClick: () => setFarmFieldsEditOpen(farmId),
                },
              ];
              if (canDeleteFields) {
                farmActions.push({
                  label: t({id: 'Delete'}),
                  onClick: () =>
                    dispatch(
                      dialogToggle(DialogType.deleteDialog, true, {
                        title: t({id: 'Delete farm?'}),
                        onSubmit: () => {
                          dispatch(removeFields({fieldIds}));
                          const mrvFieldIds = fieldIds
                            .map(fieldId => kmlIdToMrvId[fieldId])
                            .filter(Boolean);
                          dispatch(removeProjectFields({projectId, mrvFieldIds}));
                          dispatch(removeFarm(farmId, true));
                          const mrvFarmId = mrvFarms.find(f => f.core_farm_group_id === farmId)?.id;
                          deleteFarm({projectId, mrvFarmId});
                        },
                      })
                    ),
                });
              }

              return (
                <div className={cn('farm', {'farm--expanded': isCollapsed})} key={farmId}>
                  <div className={cn('farm__row', {overlap})}>
                    <div
                      className="farm-checkbox-container ml-05"
                      onClick={() => {
                        // expand farm when click on th master checkbox and if all fields are ineligible
                        if (isIneligibleFarm) {
                          toggleExpandFarm(farmId, true);
                        }
                      }}
                    >
                      <FluroCheckbox
                        value={isFarmSelected}
                        halfChecked={selectedFields.length > 0}
                        disabled={isReadOnly}
                        label={
                          <div title={farmName} className={'checkbox-label'}>
                            {farmName}
                            <div className={'subtext'}>
                              {t(
                                {id: '{count1} / {count2} fields selected'},
                                {count1: selectedFields.length, count2: availableFields.length}
                              )}
                            </div>
                          </div>
                        }
                        onChange={() =>
                          toggleFarm(farmId, isFarmSelected ? false : !selectedFields.length)
                        }
                      />
                    </div>

                    <Button className={'expand-btn'} icon onClick={() => toggleExpandFarm(farmId)}>
                      {isCollapsed ? 'keyboard_arrow_down' : 'keyboard_arrow_right'}
                    </Button>

                    <div className={'farm-area'}>
                      {classifiedFarmArea} {t({id: measurement})}
                    </div>

                    <ReadOnly containerClass={'read-only-container'} isReadOnly={farm.readOnly}>
                      <ActionsButton
                        id={'fields-menu-button-export'}
                        disabled={isReadOnly}
                        actions={farmActions}
                      />
                    </ReadOnly>
                  </div>
                  {isCollapsed && (
                    <div>
                      {sortedFields.map(field => {
                        const mrvFieldId = kmlId2MrvId[field.ID];

                        const geometry = fieldGeometries[field.MD5];

                        const editable = !field.Seasons?.length && !field.external_service;
                        const highlighted = highlightedFieldId === field.ID;
                        const isOverlap = overlapIds.has(field.ID);
                        const enrolled = carbon.enrolledFields[field.ID];
                        const ineligible = carbon.ineligibleRegionFields[field.FieldID];

                        const checkboxIsDisabled =
                          isReadOnly || !carbon.eligibleRegionFields[field.ID];
                        const hasProtectedArea = geometry?.features.some(
                          // TODO: is this supposd to be checking f or is using field correct?
                          // if it's supposed to be checking `field`, why use `some`?
                          // eslint-disable-next-line @typescript-eslint/no-unused-vars
                          f => !!protectedAreaBoundaries[field.MD5]
                        );
                        const historicPractices = historicPracticesPerField[mrvFieldId];
                        const hasHistoricPractices = historicPractices?.some(
                          v => v.value && v.source === 'user'
                        );
                        const fieldArea = `${convertUnit(measurement, 'ac', field.Area)} ${t({
                          id: measurement,
                        })}`;

                        return (
                          <EnrollFieldsFieldItem
                            key={field.ID}
                            geometry={geometry}
                            farmId={farm.id}
                            fieldId={field.ID}
                            fieldName={field.Name}
                            fieldMD5={field.MD5}
                            fieldArea={fieldArea}
                            field={field}
                            editable={editable}
                            highlighted={highlighted}
                            ineligible={ineligible}
                            enrolled={enrolled}
                            overlap={isOverlap}
                            checkboxIsDisabled={checkboxIsDisabled}
                            hasProtectedArea={hasProtectedArea}
                            hasHistoricPractices={hasHistoricPractices}
                            isReadOnly={isReadOnly}
                            canDeleteFields={canDeleteFields}
                          />
                        );
                      })}
                    </div>
                  )}
                </div>
              );
            })}
          </div>
          <Sticky variant="container">
            <FluroButton blank raised onClick={addFields} disabled={isReadOnly || isEditingMode}>
              + {t({id: 'Add fields'})}
            </FluroButton>
            <FluroButton
              raised
              primary
              loading={confirming}
              disabled={totalEnrolledFieldsArea > enrollmentMaxArea}
              onClick={onConfirmSelection}
            >
              {t({
                id: isReadOnly
                  ? backToHomeLabel
                  : t({id: 'BtnLabel.ConfirmSelection', defaultMessage: 'Confirm Selection'}),
              })}
            </FluroButton>
          </Sticky>
        </>
      )}

      {farmFieldsEditOpen !== null && (
        <FarmFieldsEditDialog
          stageId={historicPracticesStage.id}
          currentFarmId={farmFieldsEditOpen}
          fieldsByFarmId={fieldsByFarmId}
          onClose={() => {
            setFarmFieldsEditOpen(null);
          }}
        />
      )}
    </div>
  );
};
