// This module captures all statuses for actions dispatched from async thunks that are created
// via createAsyncThunk from redux-toolkit (RTK)
import type {Reducer} from 'redux';
import {combineReducers} from 'redux';
import type {HelpersStore} from './types';
import {RequestStatusRTK} from './types';
import {LoginActionTypes} from '../../containers/login/types';

const initialState = {errors: {}, status: {}};

const matchAsyncActionType = (
  asyncActionType: string
): {actionType: string; requestStatus: RequestStatusRTK} | null => {
  const regexp = /(.+)\/(fulfilled|pending|rejected)$/i;
  const match = asyncActionType.match(regexp);
  if (!match) return null;
  const [, actionType, requestStatus] = match;
  return {actionType, requestStatus: requestStatus as RequestStatusRTK};
};

const errorsReducer: Reducer<HelpersStore['asyncRTK']['errors']> = (
  state = initialState.errors,
  action
) => {
  if (action.type === LoginActionTypes.LOGOUT) {
    // clear the requests when logout to request fresh data on login
    return initialState.errors;
  }

  const match = matchAsyncActionType(action.type);
  if (!match) return state;
  const {actionType, requestStatus} = match;

  return {
    ...state,
    [actionType]: requestStatus === RequestStatusRTK.rejected ? action.error : null,
  };
};

const statusReducer: Reducer<HelpersStore['asyncRTK']['status']> = (
  state = initialState.status,
  action
) => {
  if (action.type === LoginActionTypes.LOGOUT) {
    // clear the requests when logout to request fresh data on login
    return initialState.status;
  }

  const match = matchAsyncActionType(action.type);
  if (!match) return state;

  const {actionType, requestStatus: RequestStatusRTK} = match;
  return {
    ...state,
    [actionType]: RequestStatusRTK,
  };
};

const asyncRTKReducer = combineReducers<HelpersStore['asyncRTK']>({
  errors: errorsReducer,
  status: statusReducer,
});

export default asyncRTKReducer;
