import React, {useState, useEffect} from 'react';
import {ArrowDropDown, QuestionCircledIcon} from 'components/fluro-icons';
import {theme} from '@regrow-internal/design-system';

export const MenubarArrowDropdown = () => (
  <ArrowDropDown size={8} style={{color: theme.colorPalette.fs_main.gray_400}} />
);

export const HubspotChatLaunchIcon = () => {
  const [showHelpIcon, setHelpIconVisibility] = useState(false);

  useEffect(() => {
    let numberOfTries: number = 0;
    const interval = setInterval(() => {
      numberOfTries = numberOfTries + 1;
      if (numberOfTries >= 120) {
        // try for two minutes, if the hubspot doesn't appear, stop trying
        clearInterval(interval);
      }
      // @ts-expect-error error leftover from convertion to strict mode, please fix
      if (window.HubSpotConversations && window.HubSpotConversations?.widget?.status?.().loaded) {
        setHelpIconVisibility(true);
        clearInterval(interval);
      }
    }, 1000);
  }, []);

  const launchHubspotChat = () => {
    // @ts-expect-error error leftover from convertion to strict mode, please fix
    window.HubSpotConversations.widget.open();
  };

  if (!showHelpIcon) return null;

  return <QuestionCircledIcon onClick={launchHubspotChat} className="help-icon" />;
};
