// @ts-nocheck
import mixpanel from 'mixpanel-browser';
import type {
  Farm,
  Field,
  Season,
  SamplingPoint,
  TLayersSource,
  TreesTypes,
} from '../containers/map/types';
import {ExternalService} from '../containers/map/types';
import type {TFeature} from '../types';
import type {AxiosResponse} from 'axios';
import config from '_environment';
import {biomassLabels, cropGrowthLabels} from '../containers/map/features/crop-performance/types';
import {checkWorkspace} from '../containers/admin/users/types';
import type {User} from '../containers/admin/users/types';
import {SAMPLING_POINTS_LABELS} from '_constants';
import {isLoginAllowedAnalytics} from './analytics';
import type {MRVStageType} from 'containers/mrv/types';

let MixpanelInitialized = false;

// prevent sending actions for local env
if (window.location.hostname === 'localhost') {
  config.mp_token = '';
}

const Mixpanel = {
  identify: (userId: number | string) => {
    mixpanel.identify(`${userId}`);
  },

  alias: (id: string) => {
    if (!MixpanelInitialized) return;
    mixpanel?.alias(id);
  },

  track: (name: string, props?: any) => {
    if (!MixpanelInitialized) return;

    mixpanel?.track(name, props);
  },

  people: {
    set: (props: any) => {
      if (!MixpanelInitialized) return;
      mixpanel.people.set(props);
    },
  },

  // do not track regular page view for the carbon cuz we have carbon separate page view tracker
  pageView: () => {
    const {isWorkspaceMrv} = checkWorkspace();
    !isWorkspaceMrv && Mixpanel.track('Page View');
  },

  login: (user: User, loginType: string) => {
    const {isWorkspaceMrv} = checkWorkspace();

    const token = isWorkspaceMrv ? config.mp_token_cargill : config.mp_token;

    if (token && isLoginAllowedAnalytics(user.email)) {
      // Types definition wants the name (the 3rd argument) to be mandatory,
      // but actually it should be optional. We'll tsignore it for now.
      // https://github.com/DefinitelyTyped/DefinitelyTyped/pull/51267#discussion_r577011382
      // @ts-expect-error error leftover from convertion to strict mode, please fix

      // do not init if Cargill cuz it is already initialized when main page loaded
      if (!isWorkspaceMrv) {
        mixpanel.init(token);
        MixpanelInitialized = true;
      }

      Mixpanel.people.set({
        $name: `${user.name} ${user.surname}`,
        $email: user.email,
        'Account Type': user.apiUser || user.settings.scenarioPlanningTool,
      });
      Mixpanel.identify(user.id);
      Mixpanel.track('Login', {Type: loginType});
    }
  },

  logout: () => Mixpanel.track('Logout'),

  changeMapLayer: (layer: TLayersSource) =>
    Mixpanel.track('Change Map Layer', {'Layer Name': layer}),

  treeLayerClicked: (layer: TreesTypes) =>
    Mixpanel.track('Tree Layer Clicked', {'Layer Selected': layer}),

  locateMe: () => Mixpanel.track('Locate me'),

  sendCoordinates: (coords: any) =>
    Mixpanel.track('[Locate me] coordinates not in the current field', coords),

  dateChange: (date: string) => Mixpanel.track('Date change', {Date: date}),

  layerChange: (layer: string) => {
    Mixpanel.track('Layer change', {'Layer Name': layer});
  },

  tabChange: (tab: TFeature) => Mixpanel.track('Tab change', {'Tab name': tab}),

  openExpandedView: (tab: TFeature) => Mixpanel.track('Open Expanded View', {Tab: tab}),

  changeDate: (date: string) => {
    Mixpanel.track('Change Date', {Date: date});
  },

  addNewField: (field: Field | Field[]) => {
    Array.isArray(field)
      ? field.map(f => Mixpanel.track('Field Creation', {'Field Name': f.Name, Hectares: f.Area}))
      : Mixpanel.track('Field Creation', {'Field Name': field.Name, Hectares: field.Area});
  },

  addNewFarm: (farm: Farm) => Mixpanel.track('Farm Creation', {'Farm Name': farm.name}),

  addNewPoint: (point: SamplingPoint) => {
    const getSamplingPointLabelByValue = (value = '') => {
      const item = SAMPLING_POINTS_LABELS().find(l => l.value === value.toLowerCase());
      return item?.label || value || 'No label';
    };
    Mixpanel.track('Point Creation', {
      Label: getSamplingPointLabelByValue(point.properties.samplingPointType),
      Date: point.properties.timedate,
      Description: point.properties.description || '',
    });
  },

  addNewSeason: (cropData: any[]) => {
    cropData.forEach(data =>
      data.seasons.forEach(
        (s: Partial<Season>) =>
          !s.id &&
          Mixpanel.track('Season Creation', {
            'Season Crop': s.cropType,
            'Season Start Date': s.startDate,
            'Season End Date': s.endDate,
          })
      )
    );
  },

  errorMessage: (message: string, response: AxiosResponse) => {
    Mixpanel.track('Error Message', {
      Message: message,
      URL: response.config.url,
      Method: response.config.method,
    });
  },

  exportAnomalies: (exportTo: string) => {
    Mixpanel.track('CS Export', {'Export to': exportTo});
  },

  // zoning
  exportNRx: (exportTo: string) => {
    Mixpanel.track('CN Export', {'Export to': exportTo});
  },

  exportTreeZones: (exportTo: string) => {
    Mixpanel.track('Tree analysis Export', {'Export to': exportTo});
  },

  exportZoning: (exportTo: string) => {
    Mixpanel.track('Export zoning', {'Export to': exportTo});
  },

  updateNRxSettings: () => {
    Mixpanel.track('NRx Settings Update');
  },

  getNutrilogic: () => {
    Mixpanel.track('Get Nutrilogic');
  },

  changeTreeZoningSettings: (prop: string, value: any) => {
    Mixpanel.track('Tree zoning settings updated', {'Prop changed': prop, Value: value});
  },

  changeRegularZoningSettings: (prop: string, value: any) => {
    Mixpanel.track('Regular zoning settings updated', {'Prop changed': prop, Value: value});
  },

  // end zoning

  importSync: (syncWith: string, entityNames: string[]) => {
    let entityName = '';

    switch (syncWith) {
      case ExternalService.Agx:
      case ExternalService.Agworld:
        entityName = 'Growers';
        break;
      case ExternalService.Climate:
        entityName = 'Fields';
        break;
    }

    Mixpanel.track('Import-Sync', {'External Service': syncWith, [entityName]: entityNames});
  },

  loadWeatherData: () => {
    Mixpanel.track('Call to the weather');
  },

  // crop stress / AOI

  displayCropStressOnAnalytics: (anomalyId: number) => {
    Mixpanel.track('Visualize stress in the analytics tab', {AnomalyId: anomalyId});
  },

  labelCropStress: (label: string, anomalyId: number) => {
    Mixpanel.track('Labeled a crop stress', {Label: label, AnomalyId: anomalyId});
  },
  toggleCropStress: (value: boolean, anomalyId: number) => {
    Mixpanel.track('Snoozed a crop stress', {Value: value, AnomalyId: anomalyId});
  },

  addGeometry: (method: 'Upload' | 'Draw') => {
    Mixpanel.track('Created an AOI', {Method: method});
  },

  // end crop stress / AOI

  openKnowledgeBase: (link: string) => {
    Mixpanel.track('Call knowledge base', {Link: link});
  },

  // Crop performance
  cropPerformance_SelectCard: (card: string) => {
    Mixpanel.track('CP Select Card', {Card: card});
  },

  cropPerformance_ApplyFilter: (filterType: string, valueApplied: any, valuesApplied: any[]) => {
    let resultValue = valueApplied;
    let resultValues = valuesApplied;

    switch (filterType) {
      // use labels instead of the values
      case 'cropGrowth': {
        resultValue = cropGrowthLabels[valueApplied];
        resultValues = resultValues.map(value => cropGrowthLabels[value]);
        break;
      }
      case 'biomass': {
        // @ts-expect-error error leftover from convertion to strict mode, please fix
        resultValue = biomassLabels[valueApplied];
        // @ts-expect-error error leftover from convertion to strict mode, please fix
        resultValues = resultValues.map(value => biomassLabels[value]);
      }
    }

    Mixpanel.track('CP Apply filter', {
      'Filter Type': filterType,
      'Value Applied': resultValue,
      'Result Values': resultValues,
    });
  },

  cropPerformance_SetTab: (tab: string) => {
    Mixpanel.track('CP Select Tab', {Tab: tab});
  },

  cropPerformance_Export: (fileType: string) => {
    Mixpanel.track('CP Export', {'Export to': fileType});
  },

  //Analytics

  analyticsExport: (fileType: string) => {
    Mixpanel.track('Analytics Graphs Export', {'Export to': fileType});
  },
};

/*
 *
 * Track carbon events
 *
 * */

class MixpanelCarbon {
  constructor() {
    const {isWorkspaceMrv} = checkWorkspace();
    if (config.mp_token_cargill && isWorkspaceMrv) {
      mixpanel.init(config.mp_token_cargill);
      MixpanelInitialized = true;
    }
  }

  track(event: string, props?: any) {
    if (!MixpanelInitialized) return;

    Mixpanel.track(event, props);
  }

  signUp() {
    this.track('Register');
  }

  loginLanding() {
    this.track('Landing Login');
  }

  registerLanding() {
    this.track('Landing Register');
  }

  clickOnYouTubeVideo() {
    this.track('Youtube video');
  }

  clickOnRegenerativeAgDiagram() {
    this.track('Regenerative ag diagram');
  }

  clickOnCarbonMarketEBook() {
    this.track('Carbon market ebook');
  }

  clickOnConfirmSelection() {
    this.track('Confirm selection');
  }

  clickOnConfirmPractices() {
    this.track('Confirm practices');
  }

  onPageConfirmEmail() {
    this.track('Confirm email');
  }

  clickOnSetPassword() {
    this.track('Set password');
  }

  urlChange() {
    this.track('Carbon Page View');
  }

  measurementTableOpen(type: MRVStageType) {
    this.track(`measurement_${type}_table`);
  }

  measurementBulkEdit(type: MRVStageType) {
    this.track(`measurement_${type}_bulk_edit`);
  }

  measurementUnlock(type: MRVStageType) {
    this.track(`measurement_${type}_unlock`);
  }
}

export const mixpanelCarbon = new MixpanelCarbon();

export default Mixpanel;
