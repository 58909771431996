export enum ActionType {
  SetEntities = '@subscriptions/entities/set',
  SetUsers = '@subscriptions/users/set',
  HasFarmsUpdate = '@subscriptions/farms/update',
  ClearCache = '@subscriptions/clear/cache',
}

interface BaseItem {
  type: SubscriptionEntityType;
  id: number;
  name: string;

  farms: number;
  fields: number;
  fields_area: number;
  direct_users: number;
  organization_name: string;

  /*
   *
   * Indirect users - are users which in the current entity but not in the top entity
   *
   * EXAMPLE:
   *
   *  -- [Test Subscription]
   *         Users
   *           Kelly,
   *
   *   -- [Test Organization]
   *         Users:
   *           Jon, <- indirect user
   *           Kelly, <- direct user
   *
   *
   * */
  indirect_users: number;

  /*
   *
   * We have 3 levels of loads
   * -- Subscriptions
   *   -- Organizations
   *     -- Farms
   *
   * [levelLoadId] - means Id of the current loading level.
   *
   * EXAMPLE:
   * GET: /subscriptions/1 - (levelLoadId is 1)
   * GET: /organizations/200 - (levelLoadId is 200)
   * The farms level does not have load level ID cuz it is the bottom of loadings
   *
   * */
  levelLoadId?: number;
}

export interface Subscription extends BaseItem {
  type: SubscriptionEntityType.Subscription;
  start_date: string;
  end_date: string;
  created_at: string;
  organizations: number;
}

export interface Organization extends BaseItem {
  type: SubscriptionEntityType.Organization;
  created_by: number;
  subscription_id: number;
  created_at: string;
}

export interface Farm extends BaseItem {
  type: SubscriptionEntityType.Farm;
  subscription_name?: string;
  organization_id?: number;
}

export type SubscriptionsStore = {
  subscriptions: Subscription[];
  organizations: Organization[];
  farms: Farm[];
  hasFarmsUpdate: boolean;

  organizationsBySubscriptionId: {[subsId: number]: CommonType[]};
  farmsByOrganizationId: {[orgId: number]: CommonType[]};

  // utils data
  users: User[];
};

export type SetUsersAction = {
  type: ActionType.SetUsers;
  users: User[];
};

export type Action = SetUsersAction;

export enum SubscriptionEntityType {
  Subscription = 'Subscription',
  Organization = 'Organization',
  Farm = 'Farm',
}

export type CommonType = Subscription | Organization | Farm;

export type User = {
  id: number;
  email: string;
  name?: string;
  surname?: string;
  roles?: {[keY: string]: number[]};
};

export enum RoleType {
  Read = 'read',
  Write = 'write',
  Admin = 'admin',
}

export enum RolePrefix {
  Sub = 'subs.',
  Org = 'orgs.',
  Farms = 'farms.',
}

export enum EntityAlias {
  Sub = 'subs',
  Org = 'orgs',
  Farm = 'farms',
}
