import React, {Fragment, useMemo, useState} from 'react';
import type {ReactElement, FC} from 'react';
import {ResponsiveBar} from '@nivo/bar';
import type {BarExtendedDatum} from '@nivo/bar';
import {EFReductionValueOutside} from '../../cards/emission-factor/emission-factor-value-outside';
import {EFLegend} from '../../cards/emission-factor/emission-factor-legend';
import {EFStackedBar} from '../../cards/emission-factor/emission-factor-stacked-bar';
import type {BarChartData} from '../../cards/emission-factor/emission-factor';
import type {AxisTickProps} from '@nivo/axes';
import {REPORT_DASHBOARD_COLORS} from 'containers/si/colors';
import {ChartNav} from 'containers/si/kpi/charts/components/chart-nav';
import {isDefined} from '_utils/typeGuards';
import {NavWrapper} from './styled-components';
import type {Box} from '@nivo/core';

interface StackedBarChartProps {
  barChartData: object[];
  reverse: boolean;
  keys: Array<string>; //['book', 'baseline', 'scenario'];
  layout: 'horizontal' | 'vertical';
  customTick: (props: AxisTickProps<any>) => JSX.Element;
  pagination?: {
    itemsPerPage: number;
  };
  renderTooltip?: (
    data: BarExtendedDatum & {data: BarChartData},
    key: string
  ) => string | ReactElement | number;
  legends?: Array<{
    id: string;
    label: string;
    color: string;
  }>;
  margin?: Box;
}

export const StackedBarChart: FC<StackedBarChartProps> = ({
  barChartData,
  reverse,
  keys,
  layout,
  customTick,
  pagination,
  renderTooltip,
  legends,
  margin,
}) => {
  const data = useMemo(() => {
    return reverse ? barChartData.reverse() : barChartData;
  }, [barChartData, reverse]);

  const [page, setPage] = useState(0);

  const paginatedData = useMemo(() => {
    if (isDefined(pagination)) {
      const {itemsPerPage} = pagination;
      const pageToDataNumber = page * itemsPerPage;
      if (data.length <= itemsPerPage) return data;
      const slicedData = data.slice(pageToDataNumber, pageToDataNumber + itemsPerPage);

      return slicedData.reverse();
    } else return data;
  }, [page, data, pagination]);

  return (
    <Fragment>
      <ResponsiveBar
        data={paginatedData}
        indexBy="index"
        keys={keys}
        layout={layout} //
        enableGridY={false}
        margin={{top: 0, right: 200, bottom: 30, left: 310, ...margin}}
        padding={0.2}
        innerPadding={2}
        enableLabel={false}
        colors={b => REPORT_DASHBOARD_COLORS[b.index] || REPORT_DASHBOARD_COLORS[0] || '#60b854'}
        isInteractive={false}
        axisLeft={{
          tickSize: 5,
          tickPadding: 10,
          renderTick: customTick,
        }}
        barComponent={props => (
          <EFStackedBar
            x={props.x}
            y={props.y}
            width={props.width}
            height={props.height}
            data={props.data as BarExtendedDatum & {data: BarChartData}}
            renderTooltip={renderTooltip}
          />
        )}
        groupMode="grouped" // Group the bars so the [book, baseline, scenario] bars are grouped together
        layers={[
          'grid',
          'axes',
          'bars',
          'markers',
          EFLegend,
          props => <EFReductionValueOutside {...props} />,
        ]}
        legends={[
          {
            dataFrom: 'indexes',
            data: legends,
            anchor: 'top-right',
            direction: 'column',
            justify: false,
            translateX: 220,
            translateY: 0,
            itemWidth: 120,
            itemHeight: 200,
          },
        ]}
      />
      {isDefined(pagination) && (
        <NavWrapper>
          <ChartNav
            dataLength={data.length}
            itemsPerPage={pagination.itemsPerPage}
            onPageChange={chartPage => setPage(chartPage)}
          />
        </NavWrapper>
      )}
    </Fragment>
  );
};
