import {Flex} from 'components';
import React, {useMemo} from 'react';
import {naturalSortAlphaNum} from '_utils/sorters';
import {AreaSelectMenu} from 'containers/map/features/sustainability-insights/area-select/area-select-menu';
import type {Props as OptionsGroupsProps} from 'components/grouped-selection-control/options-group';
import type {SICropTypeOptions} from 'containers/si/types';
import {getNewIdsFromSelectedChange} from 'containers/si/kpi/filter/helpers';

type CropTypeFilterProps = {
  cropTypeOptions: Array<SICropTypeOptions>;
  availableCropIds: Array<number>;
  selectedCropIds: Array<number>;
  onChange: (cropIds: Array<number>) => void;
};

export const CropTypeFilter = ({
  cropTypeOptions,
  availableCropIds,
  selectedCropIds,
  onChange,
}: CropTypeFilterProps) => {
  const allSelected = useMemo(
    () => availableCropIds.length === selectedCropIds.length,
    [availableCropIds.length, selectedCropIds.length]
  );

  const controls = useMemo(() => {
    const unsortedCrops = cropTypeOptions.map(cropType => {
      return {
        label: cropType.label,
        value: cropType.value,
      };
    });

    return naturalSortAlphaNum(unsortedCrops, 'value');
  }, [cropTypeOptions]);

  const handleClear = () => {
    onChange([]);
  };

  const handleSelect: OptionsGroupsProps['onChange'] = selectedChange => {
    const newIds = getNewIdsFromSelectedChange({
      selectedIds: selectedCropIds,
      allAvailableIds: availableCropIds,
    })(selectedChange);
    onChange(newIds);
  };

  return (
    <Flex alignItems="center" nowrap>
      <AreaSelectMenu
        className="area-select-menu"
        allowSelectAll
        allowSearch
        allLabel={`Select all crops`}
        selectedLabel={'crops'}
        options={controls}
        selected={selectedCropIds.map(String)}
        disabled={false}
        allSelected={allSelected}
        onSelect={handleSelect}
        onClear={handleClear}
      />
    </Flex>
  );
};
