import type {Reducer} from 'redux';
import type {HelpersStore, SetRequestStatusAction} from './types';
import {ActionType, AsyncStatusType, Status} from './types';
import {LoginActionTypes} from '../../containers/login/types';

const initialState = {
  [AsyncStatusType.imageLayer]: {},
  [AsyncStatusType.adminDashboardCropStress]: {},
  [AsyncStatusType.adminDashboardNrx]: {},
  [AsyncStatusType.adminSearch]: {},
  [AsyncStatusType.farmsList]: {},
  [AsyncStatusType.loadOneFarm]: {},
  [AsyncStatusType.fieldsData]: {},
  [AsyncStatusType.allFieldsData]: {},
  [AsyncStatusType.loadFields]: {},
  [AsyncStatusType.fieldGeometries]: {},
  [AsyncStatusType.fieldAreasOfInterest]: {},
  // zoning
  [AsyncStatusType.mainZoning]: {},
  [AsyncStatusType.loadSuggestedPoints]: {},
  [AsyncStatusType.zoningImageOverlay]: {},
  [AsyncStatusType.NRxRecommendation]: {},
  [AsyncStatusType.NRxListsData]: {},
  [AsyncStatusType.NRXSettings]: {},
  [AsyncStatusType.treeDetectionZoning]: {},
  // histogram
  [AsyncStatusType.histogram]: {},
  // analytics
  [AsyncStatusType.analyticsGeometriesAndPoints]: {},
  [AsyncStatusType.analyticsFieldArableData]: {},
  // weather
  [AsyncStatusType.loadFieldWeather]: {},
  // whole farm
  [AsyncStatusType.wholeFarmData]: {},

  [AsyncStatusType.productivityMap]: {},

  // Field boundaries
  [AsyncStatusType.cluFieldBoundaries]: {},

  // Upload fields
  [AsyncStatusType.parseUploadedFields]: {},
  [AsyncStatusType.uploadingFieldsToBackend]: {},

  [AsyncStatusType.cropTypesMatching]: {},

  [AsyncStatusType.binaryImageLayerData]: {},
  [AsyncStatusType.usersActivity]: {},

  // integrations
  [AsyncStatusType.authorizedPlatforms]: {status: Status.Todo},

  // SI
  [AsyncStatusType.SILoadAllStates]: {},
  [AsyncStatusType.updateSIPolicy]: {},
};

const asyncStatusesReducer: Reducer<
  HelpersStore['asyncStatuses'],
  SetRequestStatusAction | {type: LoginActionTypes.LOGOUT}
> = (state = initialState, action) => {
  switch (action.type) {
    case ActionType.SetRequestStatus: {
      return {
        ...state,
        [action.key]: {
          status: action.status,
          errors: action.errors || null,
        },
      };
    }

    case LoginActionTypes.LOGOUT: {
      // clear async statuses after logout
      return {...state, ...initialState};
    }

    default:
      return state;
  }
};

export default asyncStatusesReducer;
