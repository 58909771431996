import type {
  SIProgramEntities,
  SIProgramResult,
  SISupplyShedInput,
  SISubsectionInput,
} from 'containers/si/types';
import type {
  ProgramRequest,
  ProgramResponse,
  SupplyShedGeometries,
} from 'containers/si/api/apiTypes';
import {SI_COLORS} from 'containers/si/colors';
import {normalize} from 'normalizr';
import {SIProgramSchema} from './normalize-schemas';
import type {FeatureCollection} from 'geojson';
import {isDefined} from '_utils/typeGuards';
import compact from 'lodash/compact';

const getDeterministicColor = (seed: number) => {
  const letters = '0123456789ABCDEF';
  let color = '#';
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor((seed * (i + 1)) % 16)];
  }
  return color;
};

export const defaultSIProgram: ProgramRequest = {
  name: 'New SI program',
  acreage_limit_ha: 0,
  crop_year_start: new Date().getFullYear(),
  crop_year_end: new Date().getFullYear(),
  practice_kpis: [],
  outcome_kpis: [],
  tab_accesses: ['admin'],
};

export const defaultSISupplyShed: SISupplyShedInput = {
  name: 'New Supply Shed',
  description: '',
  commodities: [],
  subsections: [],
};

export const defaultSISubsection: SISubsectionInput = {
  name: 'New Subsection',
  supply_shed_id: 0,
  commodities: [],
};

export const normalizeSIPrograms = (programs: ProgramResponse[]) => {
  return normalize<ProgramResponse, SIProgramEntities, SIProgramResult>(programs, [
    SIProgramSchema,
  ]);
};

export const normalizeSIProgram = (program: ProgramResponse) => {
  return normalize<ProgramResponse, SIProgramEntities, SIProgramResult>(
    [program],
    [SIProgramSchema]
  );
};

// Adds a 'color' key to a list of objects. The color comes from the SI_COLORS array.
// If all the SI_COLORS are used, a random color is assigned.
export const applyColorKeyToListObjects = <T extends object & {id: number}>(list: T[]) => {
  return list.map((value, i) => ({
    ...value,
    color:
      i >= SI_COLORS.length ? getDeterministicColor(value.id) : SI_COLORS[i % SI_COLORS.length],
  }));
};

/**
 * [21/02/2023 YV]: Generate FeatureCollection from a list of Supply Sheds. Uses the supply shed color for the 'color' key on each feature .
 *
 * @param {Array<SupplyShedGeometries>} geometries - List of supply sheds to generate the FeatureCollection from.
 * @param {'supply-shed' | 'sub-section'} type - Type of feature collection. Defaults to: 'supply-shed'.
 * @param {number[]} idsToInclude - List of ids to include relative to type param; filters OUT all others
 *
 * @returns {FeatureCollection} - returns Feature(s) to be rendered in a map.
 */
type FeatureGeneratorConfig = {
  geometries: Array<SupplyShedGeometries>;
  type: 'supply-shed' | 'sub-section';
  idsToInclude?: number[];
};

export const generateFeatureCollection = ({
  geometries,
  idsToInclude,
  type,
}: FeatureGeneratorConfig) => {
  const shouldFilterOutId = (id: number, typeToCheck: 'supply-shed' | 'sub-section') =>
    isDefined(idsToInclude) && type === typeToCheck && !idsToInclude.includes(id);

  // Supply sheds use ordering to have a consistent color; use the same thing for geometries
  // so they match
  const coloredGeometries = applyColorKeyToListObjects(geometries);

  const featureGeometry = coloredGeometries
    .map(shedGeom =>
      shouldFilterOutId(shedGeom.id, 'supply-shed')
        ? []
        : compact(
            shedGeom.subsection_geometries.map(ss => {
              if (shouldFilterOutId(ss.id, 'sub-section')) {
                return null; // compact will filter these out
              }
              // Create new geojson feature
              const newFeature = {
                type: 'Feature' as const,
                ...ss.geometries,
                properties: {
                  ...(ss.geometries.properties ?? {}),
                  color: shedGeom.color,
                  //includes id to access when interacting with map function depending on feature type
                  id: type === 'supply-shed' ? shedGeom.id : ss.id,
                },
              };

              return newFeature;
            })
          )
    )
    .flat();

  return {type: 'FeatureCollection', features: featureGeometry} as FeatureCollection;
};
