// @ts-nocheck
import type {ComponentType} from 'react';
import React, {createContext, useCallback, useEffect} from 'react';
import {useLeafletContext} from '@react-leaflet/core';
import type L from 'leaflet';
import type {FitBoundsOptions} from 'leaflet';
import {validateBounds} from '../../_utils';
import {PANEL_PADDING} from '../../_constants';
import {reportError} from '../error-boundary';

export const OverlayContext = createContext<{
  fitBounds: (bounds?: L.LatLngBounds, options?: FitBoundsOptions) => void;
}>({
  fitBounds: () => null,
});

export const OverlayContextProvider: ComponentType<{}> = ({children}) => {
  const leafletElement = useLeafletContext().map;
  useEffect(() => {
    // @ts-expect-error - leafletElement exists
    window.leafletElement = leafletElement;
    leafletElement.createPane('field-geometry');
    leafletElement.getPane('field-geometry').style.zIndex = '500';
  }, [leafletElement]);

  const fitBounds = useCallback(
    (bounds?: L.LatLngBounds, options?: FitBoundsOptions) => {
      try {
        if (!leafletElement || !validateBounds(bounds)) {
          return;
        }
        leafletElement.fitBounds(bounds, options ? options : PANEL_PADDING);
      } catch (err) {
        reportError(`fitBounds Err = ${err}`);
      }
    },
    [leafletElement]
  );

  return <OverlayContext.Provider value={{fitBounds}}>{children}</OverlayContext.Provider>;
};
