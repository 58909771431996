import type {AppStore} from 'reducers';
import type {AsyncStatusType, AsyncStatus} from 'modules/helpers';
import {createSelector} from '@reduxjs/toolkit';
import type {CropType} from './types';
import {
  createCachingSelector,
  cropSubtype2Label,
  getCropColorById,
  getCropLabelById,
} from '_utils/pure-utils';

export const selectAsyncRequestStatus = (state: AppStore, statusType: AsyncStatusType) =>
  state.helpers.asyncStatuses[statusType]?.status;

export const selectAsyncRequest = (state: AppStore, statusType: AsyncStatusType): AsyncStatus =>
  state.helpers.asyncStatuses[statusType];

// Mapped by values: {'corn': {id: 105, cdl_id: 1, value: 'corn', label: 'Corn'}, ...}
export const selectCropTypes = (state: AppStore) => state.global.cropTypes;

export const selectCropTypesList = createSelector([selectCropTypes], cropTypes =>
  Object.values(cropTypes)
);

export const selectCropTypeLabelByCropId = createCachingSelector(
  [selectCropTypes, (s: AppStore, cropType: string) => cropType],
  (cropTypes, cropType) => getCropLabelById(cropTypes, cropType)
);

export const selectCropTypeById = createCachingSelector(
  [selectCropTypes, (s: AppStore, cropType: string) => cropType],
  (cropTypes, cropType) => (cropTypes[cropType] ? cropTypes[cropType].type : '')
);
export const selectCropColorById = createCachingSelector(
  [selectCropTypes, (s: AppStore, cropType: string) => cropType],
  (cropTypes, cropType) => getCropColorById(cropTypes, cropType)
);

export const selectCropSubtypeLabelBy = createCachingSelector(
  [
    selectCropTypes,
    (s: AppStore, cropType: string) => cropType,
    (s: AppStore, cropType: string, cropSubType: string) => cropSubType,
  ],
  (cropTypes, cropType, cropSubtype) => cropSubtype2Label(cropTypes, cropType, cropSubtype)
);

// Mapped by ids: {105: {id: 105, cdl_id: 1, value: 'corn', label: 'Corn'}, ...}
export const selectCropTypesIdMap = createSelector([selectCropTypes], cropTypes => {
  const o: Record<number, CropType> = {};

  Object.values(cropTypes).forEach(c => {
    o[c.id] = c;
  });

  return o;
});

// Mapped by CDL ids: {1: {id: 105, cdl_id: 1, value: 'corn', label: 'Corn'}, ...}
export const selectCropTypesCDLIdMap = createSelector([selectCropTypes], cropTypes => {
  const o: Record<number, CropType> = {};

  Object.values(cropTypes).forEach(c => {
    o[Number(c.cdl_id)] = c;
  });

  return o;
});

export const selectCropType = createSelector(
  [selectCropTypes, (_: AppStore, cropType: string) => cropType],
  (cropTypes, type) => cropTypes[type]
);

export const selectIsMapView = (state: AppStore) => state.global.isMapView;

export const selectIsMapLoading = (state: AppStore) => state.global.isMapLoading;

export const selectIsReportView = (state: AppStore) => state.global.isReportView;

export const selectIsSI = (state: AppStore) =>
  state.router.location.pathname.startsWith('/sustainability');

export const selectViewport = (state: AppStore) => state.viewport;

export const selectSessionExpired = (state: AppStore) => state.global.sessionExpired;
