import React, {useMemo, memo} from 'react';
import {getCurrentAppHost} from '_utils/pure-utils';
import Mixpanel from '_utils/mixpanel-utils';
import {CI_ROOT_PATH} from '_environment';

type Props = React.DetailedHTMLProps<
  React.AnchorHTMLAttributes<HTMLAnchorElement>,
  HTMLAnchorElement
> & {
  external?: boolean; // if external the app host will not be added;
  blank?: boolean; // open in new tab;
  map?: boolean; // if direct to the farm or field
  onClick?(val: any): void;
  prevent?: boolean;
};

/*
 *
 * The main reason of the component
 * to make the creating of non redux Links more declarative
 * without confused host concatenations in the HREF prop
 *
 * EXAMPLE: <Ln map blank href="24">AusCot</Ln>
 *
 * */
export const Ln = memo((props: Props) => {
  const {prevent, external, map, blank, ...rest} = props;

  const href = useMemo(() => {
    if (external) {
      return String(props.href);
    }

    if (map) {
      return getCurrentAppHost() + `/${CI_ROOT_PATH}/` + props.href;
    }

    return getCurrentAppHost() + props.href;
  }, [props.href]);

  const handleClick = (ev: any) => {
    rest.onClick && rest.onClick(ev);
    if (href.includes('help.flurosense.com')) {
      Mixpanel.openKnowledgeBase(href);
    }

    if (prevent) {
      ev.preventDefault();
      return false;
    }
  };

  return (
    <a {...rest} onClick={handleClick} href={href} {...(blank ? {target: '_blank'} : {})}>
      {props.children}
    </a>
  );
});

export default Ln;
