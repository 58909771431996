import * as React from 'react';
import type {ComponentType} from 'react';
import 'react-toastify/dist/ReactToastify.css';
import './notification.scss';
import type {ToastOptions, TypeOptions} from 'react-toastify';
import {toast, ToastContainer} from 'react-toastify';
import {Text} from '../text/text';
import {ErrorIcon, InfoIcon, SuccessIcon} from '../../containers/map/icons';
import colors from '../../variables.scss';
import cn from 'classnames';
import {useWorkspace} from '_hooks/use-workspace';

const NotificationIcons: Record<TypeOptions, JSX.Element | null> = {
  success: <SuccessIcon style={{width: '16px', color: colors['main-green-400']}} />,
  warning: <ErrorIcon style={{width: '16px', color: colors['saffron']}} />,
  info: <InfoIcon style={{width: '16px', color: colors['dodger-blue']}} />,
  default: null,
  error: <ErrorIcon style={{width: '16px', color: colors['NDVI-lowest']}} />,
};

export const showNotification = (
  noteOptions: ToastOptions & {
    /**
     * Don't use a full-stop, it is inserted automatically.
     */
    title?: string | JSX.Element;
    /**
     * Don't place anything here that can't go inside a span.
     */
    message: string | JSX.Element;
    /**
     * Adds background color to the notification item, disabled by default.
     */
    coloredNotification?: boolean;
  }
) => {
  const {
    title,
    message,
    type = 'default',
    coloredNotification,
    autoClose = 4000,
    ...restProps
  } = noteOptions;
  const notificationMessage = (
    <>
      {title && (
        <Text fontWeight={500} elementType={'span'}>
          {title}
          {'. '}
        </Text>
      )}
      <Text elementType={'span'}>{message}</Text>
    </>
  );
  return toast(notificationMessage, {
    position: 'bottom-right',
    autoClose,
    closeOnClick: true,
    icon: NotificationIcons[type],
    type,
    toastId: restProps.toastId || String(message) + String(title),
    className: cn({colored: coloredNotification}),
    ...restProps,
  });
};

const Notification: ComponentType = () => {
  const {isWorkspaceMrv} = useWorkspace();
  return <ToastContainer className={cn({mrv: isWorkspaceMrv})} hideProgressBar />;
};

export default Notification;
