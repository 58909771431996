import type {GeoJSONProps} from 'react-leaflet';
import type {LeafletMouseEvent} from 'leaflet';

/**
 * This is a workaround to the bug which the leaflet@1.7.1 introduced
 * where click handler gets called twice for the same click.
 * https://github.com/Leaflet/Leaflet/issues/7255
 *
 * P.S: I've tried to use the <MapContainer tap={false}/> but somehow it didn't resolve the issue
 */
export const fixClickHandler =
  (handler: (event: LeafletMouseEvent) => void) => (event: LeafletMouseEvent) => {
    // the _simulated not added in type, but actually appears in the event
    // @ts-expect-error error leftover from convertion to strict mode, please fix
    if (event?.originalEvent?._simulated) return;
    handler(event);
  };

export const resolveEventHandlers = (eventHandlersProp: GeoJSONProps['eventHandlers']) => {
  const handler = eventHandlersProp?.click;
  if (typeof handler !== 'function') return eventHandlersProp;
  const result = eventHandlersProp || {};
  result.click = fixClickHandler(handler);
  return result;
};
