import type {AddFieldStep} from './types';

export const addingFieldsFromMapSteps = [
  'zoom-is-too-low',
  'search-location',
  'select-boundaries',
  'draw-fields',
];

export const addingFieldsPreviewSteps: AddFieldStep[] = [
  'view-fields-from-files',
  'view-drawn-fields',
  'view-selected-boundaries',
];

export const isAddingFieldsStep = (step: AddFieldStep) =>
  [...addingFieldsFromMapSteps, ...addingFieldsPreviewSteps].includes(step);
