import type {Serie} from '@nivo/line';
import {createSelector} from '@reduxjs/toolkit';
import chroma from 'chroma-js';
import {sortByKeyReverseUnsafe, sortByKey} from '_utils/sorters';
import {calculatePercentage, createCachingSelector} from '_utils/pure-utils';
import {SUMMARY_BARS_PREVIEW_THRESHOLD} from '../constants';
import type {
  AreaId,
  GroupedSummaryChartData,
  MetricSocGhgStatDatum,
  SIMetricData,
  SIStateChildMeta,
  SocGhgSummaryChartData,
} from '../types';
import {SIGeometryFilterType} from '../types';
import {calculateScale, isInRange, isYearsDiff, parseAreaId} from '../utils';
import {
  getAreaId,
  selectActiveGeometriesIds,
  selectAggLevel,
  selectAreaUnits,
  selectCurrentMeta,
  selectGeometriesColors,
  selectIsYearsDiff,
  selectMetrics,
  selectMetricsTypeFilter,
  selectSIChartGroupingType,
  selectSIFilter,
  selectStatesMeta,
  selectYearsFilterRange,
} from './selectors';
import groupBy from 'lodash/groupBy';
import {
  classifyExtremaPerUnitsFromValues,
  classifySocGhgStateStats,
  getDefaultExtremaPerUnits,
} from 'containers/map/features/sustainability-insights/filters-panel/cards/utils';

const calculateGhgNumValue = (ghgValue: number, acres: number) => {
  if (ghgValue === undefined || acres === undefined) return 0;
  return ghgValue / acres;
};

export const selectGhgCardState = createSelector(
  [selectSIFilter],
  f => f[SIGeometryFilterType.GHG]
);
export const selectGhgChartTab = createSelector(
  [selectGhgCardState],
  cardState => cardState.chartTab
);
export const selectGhgCardRange = createSelector(
  [selectGhgCardState],
  cardState => cardState.range
);

const prepareTrendData = (
  yearsRange: number[],
  dataPerYear: {[year: number]: SIMetricData},
  totalValues: number
): MetricSocGhgStatDatum['values']['trends'] => {
  const result: MetricSocGhgStatDatum['values']['trends'] = {num: [], pct: []};
  yearsRange.forEach((year, index) => {
    const valuePerYear = dataPerYear[year]?.ghg;

    if (index === 0) {
      result.num.push({
        x: year,
        y: calculateGhgNumValue(valuePerYear, dataPerYear[year]?.carbon_area_ac),
      });
    } else {
      let value = 0;
      for (let i = index; i >= 0; i--) {
        value =
          value +
          calculateGhgNumValue(
            dataPerYear[yearsRange[i]]?.ghg,
            dataPerYear[yearsRange[i]]?.carbon_area_ac
          );
      }

      result.num.push({
        x: year,
        y: value,
      });

      result.pct.push({
        x: year,
        y: calculatePercentage(valuePerYear, totalValues),
      });
    }
  });

  return result;
};

export const selectGhgStats = createSelector(
  [
    selectActiveGeometriesIds,
    selectMetrics,
    selectYearsFilterRange,
    selectGeometriesColors,
    selectAggLevel,
    selectCurrentMeta,
  ],
  (
    activeIds,
    data,
    yearsRange,
    colorsMap,
    aggLevel,
    currentMeta
  ): Record<number, MetricSocGhgStatDatum> => {
    if (!data || !activeIds?.length || !yearsRange?.length) return {};

    const isDiff = isYearsDiff(yearsRange);

    const numValuesPerId: Record<AreaId, {totalValue: number; totalArea: number}> = {}; // store values per id to prevent double calculations
    let totalValues = 0; // classify total values sum to calculate percentage later

    activeIds.forEach(id => {
      const dataPerYear = data[id];
      if (!dataPerYear) {
        return;
      }
      let ghgValuePerEntityAndYears = 0;
      let ghgAreaPerEntityAndYears = 0;

      yearsRange.forEach((year: number) => {
        ghgValuePerEntityAndYears = ghgValuePerEntityAndYears + Number(dataPerYear[year]?.ghg); // get total value per years
        ghgAreaPerEntityAndYears =
          ghgAreaPerEntityAndYears + Number(dataPerYear[year]?.carbon_area_ac); //  get total area per years
      });

      if (!ghgValuePerEntityAndYears) return;
      numValuesPerId[id] = {
        totalValue: ghgValuePerEntityAndYears,
        totalArea: ghgAreaPerEntityAndYears,
      };
      totalValues = totalValues + ghgValuePerEntityAndYears;
    });

    const stats = activeIds.reduce<{[key: number]: MetricSocGhgStatDatum}>((acc, id: number) => {
      const dataPerYear = data[id];
      const entityTotalValue = numValuesPerId[id]?.totalValue;
      const entityTotalArea = numValuesPerId[id]?.totalArea;

      if (!dataPerYear || entityTotalValue === undefined) {
        return acc;
      }

      const {name, statefp} = (currentMeta[id] || {}) as SIStateChildMeta;

      acc[id] = {
        // classify a chart entity
        id,
        name,
        statefp,
        values: {
          num: calculateGhgNumValue(entityTotalValue, entityTotalArea),
          pct: calculatePercentage(entityTotalValue, totalValues),
          rawNumValue: entityTotalValue, // use to get later the total values
          trends: isDiff ? prepareTrendData(yearsRange, dataPerYear, totalValues) : undefined,
        },
        color: colorsMap[id],
      };

      return acc;
    }, {});

    return stats;
  }
);

export const selectGhgStateStats = createSelector(
  [selectAggLevel, selectGhgStats],
  (aggLevel, stats) => classifySocGhgStateStats(aggLevel, stats)
);

export const selectActiveGhgStatsList = createSelector(
  [selectActiveGeometriesIds, selectGhgStats],
  (ids, stats) => {
    if (!stats) return [];
    return ids.reduce((acc: MetricSocGhgStatDatum[], id: number) => {
      const stat = stats[id];
      if (!stat) return acc;
      return [...acc, stat];
    }, []);
  }
);

export const selectGhgStatsById = createSelector(
  [selectGhgStats, getAreaId],
  (stats, id) => stats?.[id]
);

type Extrema = {num: {min: number; max: number}; pct: {min: number; max: number}};

export const selectGhgExtremaPerUnits = createSelector(
  [selectActiveGhgStatsList],
  (stats): Extrema => {
    if (!stats?.length) {
      return getDefaultExtremaPerUnits();
    }

    const values: {num: number[]; pct: number[]} = {num: [], pct: []};

    stats.forEach(stat => {
      const numStat = stat?.values?.num || 0;
      const pctStat = stat?.values?.pct || 0;
      values.num.push(numStat);
      values.pct.push(pctStat);
    });

    return classifyExtremaPerUnitsFromValues(values);
  }
);
export const selectGhgGroupedExtremaPerUnits = createSelector(
  [selectActiveGhgStatsList, selectGhgStateStats, selectSIChartGroupingType],
  (stats, stateStats, groupingType): Extrema => {
    // get min/max values including grouped state values that might be higher than not grouped
    const values: {num: number[]; pct: number[]} = {num: [], pct: []};

    if (!stats.length || groupingType !== 'grouped' || !stateStats)
      return getDefaultExtremaPerUnits();

    stats.forEach(stat => {
      const numStat = stat?.values?.num || 0;
      const pctStat = stat?.values?.pct || 0;
      values.num.push(numStat);
      values.pct.push(pctStat);
    });

    Object.values(stateStats).forEach(stat => {
      const numStat = stat?.num || 0;
      const pctStat = stat?.pct || 0;
      values.num.push(numStat);
      values.pct.push(pctStat);
    });

    return classifyExtremaPerUnitsFromValues(values);
  }
);

export const selectGhgStatsExtrema = createSelector(
  [selectAreaUnits, selectGhgExtremaPerUnits],
  (units, extrema) => extrema[units]
);
export const selectGhgGroupedStatsExtrema = createSelector(
  [selectAreaUnits, selectGhgGroupedExtremaPerUnits],
  (units, extrema) => extrema[units]
);

export const selectGhgColorScaleValues = createSelector([selectGhgStatsExtrema], ({min, max}) => {
  if (max <= 0) {
    return {colors: ['#37681c', '#fff']};
  } else if (min >= 0) {
    return {colors: ['#fff', '#37681c']};
  }

  const zero = calculateScale(0, min, max);

  return {colors: ['red', 'white', '#37681c'], domain: [0, zero, 1]};
});

export const selectGhgColorScale = createSelector(
  [selectIsYearsDiff, selectGhgColorScaleValues],
  (isDiff, {colors, domain}) => {
    // if (!isDiff) return {colors: ['#ffffff', '#37681c']};

    return {colors, domain};
  }
);

export const selectGhgChromaScale = createSelector(selectGhgColorScale, ({colors, domain}) => {
  const scale = chroma.scale(colors);
  if (domain) {
    scale.domain(domain);
  }

  return scale;
});

export const selectGhgColorById = createSelector(
  [
    selectGhgStatsById,
    selectGhgStatsExtrema,
    selectGhgChromaScale,
    selectGhgChartTab,
    selectAreaUnits,
  ],
  (stats, {max, min}, colorScale, tab, units) => {
    if (!stats) return;
    if (tab === 'trend') return stats.color;
    const value = stats.values?.[units];
    const scale = calculateScale(value, min, max);
    const color = colorScale(scale).css();

    return color;
  }
);

export const selectGhgSummaryChartData = createSelector(
  [
    selectActiveGhgStatsList,
    selectGhgStateStats,
    selectGhgCardRange,
    selectAreaUnits,
    selectMetricsTypeFilter,
    selectStatesMeta,
    selectAggLevel,
    selectSIChartGroupingType,
  ],
  (
    stats,
    stateStats,
    range,
    units,
    metricType,
    statesMeta,
    aggLevel,
    groupingType
  ): SocGhgSummaryChartData | null => {
    if (!stats?.length) return null;
    let totalValue = 0;
    let values: number[] = [];
    const valuesById: Record<number, number> = {};
    let chartData = stats;
    const chartGeometries: Record<string, boolean> = {};
    const chartGeometriesIds: number[] = [];

    chartData.forEach(stat => {
      const value = stat.values[units] || 0;
      const rawNumValue = stat.values?.rawNumValue || 0;
      stat.value = value;

      valuesById[stat.id] = value;

      values = values.concat(value);
      totalValue = totalValue + rawNumValue;
    });

    // Apply filters for chart data
    const preview = chartData.length > SUMMARY_BARS_PREVIEW_THRESHOLD;
    if (preview && range) {
      chartData = chartData.filter(d => isInRange(Number(d.value), range));
    }

    chartData.forEach(stat => {
      // add geometries after range filter
      chartGeometries[stat.id] = true;
      chartGeometriesIds.push(stat.id);
    });

    if (groupingType === 'grouped') {
      const groupedByState = groupBy(sortByKeyReverseUnsafe(chartData, 'value'), 'statefp');
      chartData = sortByKeyReverseUnsafe(
        Object.keys(groupedByState).reduce((acc: GroupedSummaryChartData[], stateFP: string) => {
          const value = stateStats?.[Number(stateFP)]?.[units];
          if (!value) return acc;
          const name = statesMeta[parseAreaId(stateFP)]?.name?.toUpperCase?.();
          const data = groupedByState[stateFP];

          return [
            ...acc,
            {
              data,
              name,
              value,
            } as GroupedSummaryChartData,
          ];
        }, []),
        'value'
      );
    }

    chartData = sortByKeyReverseUnsafe(chartData, 'value');

    return {
      type: groupingType,
      chartData,
      values,
      valuesById,
      preview,
      totalValue,
      chartGeometries,
      chartGeometriesIds,
    };
  }
);

export const selectGhgGeometryValueById = createCachingSelector(
  [selectGhgSummaryChartData, getAreaId],
  (summaryData, id) => summaryData?.valuesById?.[id]
);

export const isGeometryInGhgSummaryChart = createSelector(
  [selectGhgSummaryChartData, getAreaId],
  (data, id) => Boolean(data?.chartGeometries?.[id])
);

export const selectGhgTrendChartData = createSelector(
  [selectActiveGhgStatsList, selectCurrentMeta, selectAreaUnits],
  (stats, currentMeta, units): Serie[] => {
    if (!stats?.length) return [] as Serie[];
    const chartData: Serie[] = stats
      .filter(datum => datum?.values?.trends?.[units])
      .map(datum => {
        const {
          id,
          color,
          values,
          values: {trends},
        } = datum;
        const {name} = currentMeta?.[id] || {};

        return {
          id,
          color,
          data: trends?.[units] || [{x: 0, y: 0}],
          name,
          value: values[units],
        };
      });

    return sortByKey(chartData, 'value');
  }
);
