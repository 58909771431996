import React from 'react';
import {Button} from 'react-md';
import {WarningSvg} from 'components/icons';
import {t} from 'i18n-utils';

export const TSConfirm = ({data, saveMarkers, onHide, markers}) => (
  <div>
    {data.doesNotIntersect && data.doesNotIntersect.length ? (
      <div className="tsp-up-step">
        <div className="tsp-up-step__content">
          <h3>Skipped points</h3>
          <div className={'d-flex align-center'}>
            <WarningSvg />
            {markers.length - data.doesNotIntersect.length
              ? `${markers.length - data.doesNotIntersect.length} of ${
                  markers.length
                } will be added. `
              : null}
            {data.doesNotIntersect.length} point(s) do(es) not intersect with fields and this
            (these) points will be skipped
          </div>
        </div>
      </div>
    ) : null}

    <Button id={'close-ts-confirm-pop'} icon className="tsp-up-merge-close" onClick={onHide}>
      close
    </Button>

    {/*{data.willBeAddedToExistGroups && data.willBeAddedToExistGroups.length ? (*/}
    {/*  <div className="tsp-up-step">*/}
    {/*    <div>*/}
    {/*                 <WarningSvg/>
    {/*      /!*<img src="/assets/tsp_group.png" alt="tsp group" />*!/ /!* icon doesn't exist *!/*/}
    {/*    </div>*/}

    {/*    <div className="tsp-up-step__content">*/}
    {/*      <h3>Add to group?</h3>*/}
    {/*      <div>*/}
    {/*        You already have TSPs in the following field(s) for the same date, but at different coordinates:*/}
    {/*        <br />*/}
    {/*        <b>{data.willBeAddedToExistGroups.map(p => p.name).join(', ')}</b>*/}
    {/*      </div>*/}
    {/*    </div>*/}
    {/*  </div>*/}
    {/*) : null}*/}

    {/*{data.willBeMergedToExistPoints && data.willBeMergedToExistPoints.length ? (*/}
    {/*  <div className="tsp-up-step">*/}
    {/*    <div>*/}
    {/*         /!*                 <WarningSvg/>
    {/*      /!*<img src="/assets/merge_tsp.png" alt="merge tsp" />*!/ /!* icon doesn't exist *!/*/}
    {/*    </div>*/}

    {/*    <div className="tsp-up-step__content">*/}
    {/*      <h3>Merge results?</h3>*/}
    {/*      <div>*/}
    {/*        You already have TSPs in the following field(s) for the same date, at the same coordinates:*/}
    {/*        <br />*/}
    {/*        <b>{data.willBeMergedToExistPoints.map(p => p.name).join(', ')}</b>*/}
    {/*      </div>*/}
    {/*    </div>*/}
    {/*  </div>*/}
    {/*) : null}*/}

    <div style={{marginTop: 15}}>
      {/*{data.willBeMergedToExistPoints && data.willBeMergedToExistPoints.length ? (*/}
      {/*  <Button*/}
      {/*    id={'keep-results-ts-confirm-pop'}*/}
      {/*    raised*/}
      {/*    onClick={saveMarkers.bind(this, false)}*/}
      {/*    style={{marginRight: 10}}*/}
      {/*  >*/}
      {/*    No, keep my results in separated points*/}
      {/*  </Button>*/}
      {/*) : null}*/}
      {data.doesNotIntersect && markers.length === data.doesNotIntersect.length ? null : (
        <Button
          id={'let-us-try-ts-confirm-pop'}
          primary
          raised
          onClick={saveMarkers.bind(this, false)}
        >
          {t({id: "Let's do it"})}
        </Button>
      )}
    </div>
  </div>
);
