import React, {useEffect} from 'react';
import cn from 'classnames';
import {Portal} from 'components/fluro-dialog/portal';
import {useAppDispatch, useAppSelector} from '_hooks';
import {selectIsMapBarOpen} from '../map/reducer/selectors';
import {FluroFarmsSelector} from './farms-fields-selectors/fluro-farms-selector';
import {FluroFieldsSelector} from './farms-fields-selectors/fluro-fields-selector';
import DateAndLayerControls from './date-layer-selectors/date-and-layer-controls';
import {FontIcon} from 'react-md';
import {toggleHeader} from 'modules/global/actions';
import {Flex} from 'components';
import './selectors-toolbar.scss';
import {selectIsMapView, selectViewport} from 'modules/global/selectors';
import {useWorkspace} from '_hooks/use-workspace';

type Props = {
  // use different types of toolbar to slightly change styles depending on the surrounding elements
  type: 'table-view-toolbar' | 'map-toolbar' | 'right-panel-toolbar';
};

export const SelectorsToolbar = ({type}: Props) => {
  const dispatch = useAppDispatch();
  const viewport = useAppSelector(selectViewport);
  const mapBarOpen = useAppSelector(selectIsMapBarOpen);
  const isHeaderExpanded = useAppSelector(s => s.global.isHeaderExpanded);
  const isMapView = useAppSelector(selectIsMapView);
  const {workspace} = useWorkspace();

  const displayMobileVersion = viewport.width <= 560;

  useEffect(() => {
    if (viewport.width > 560 && isHeaderExpanded) {
      dispatch(toggleHeader(false)); // close toolbar
    }
  }, [viewport.width, isHeaderExpanded]);

  const onHeaderClick = () => {
    if (displayMobileVersion && !isHeaderExpanded) {
      dispatch(toggleHeader(true));
    }
  };

  // Only show in Crop insights and outside admin/profile tabs
  if (workspace !== 'ci' || !isMapView) return null;

  return (
    <div
      onClick={onHeaderClick}
      className={cn('selectors-toolbar', {
        [type]: true,
      })}
    >
      {type === 'map-toolbar' && displayMobileVersion && mapBarOpen ? null : (
        <>
          <Flex gap={'10px'} className={'element-full-width'}>
            <FluroFarmsSelector mobileView={displayMobileVersion} />
            <FluroFieldsSelector mobileView={displayMobileVersion} />
          </Flex>

          {displayMobileVersion && <DateAndLayerControls mobileView={true} />}
        </>
      )}

      {type === 'map-toolbar' && (
        <Portal id={'ci-mobile-selectors-toolbar-root'}>
          <div
            className={cn('selectors-toolbar ci-mobile-selectors-holder', {
              expanded: isHeaderExpanded,
            })}
          >
            {displayMobileVersion && (
              /*use hidden .inner-container to prevent lagging when components are calculating personal data on mounting TODO optimize selectors calculations*/
              <div className={cn('inner-container', {expanded: isHeaderExpanded})}>
                <div
                  onClick={() => dispatch(toggleHeader(false))}
                  className={'toggle-mobile-header-btn'}
                >
                  <FontIcon>keyboard_arrow_up</FontIcon>
                </div>

                <FluroFarmsSelector />
                <FluroFieldsSelector />

                <DateAndLayerControls />
              </div>
            )}
          </div>
        </Portal>
      )}
    </div>
  );
};

export default SelectorsToolbar;
