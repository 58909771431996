import store from 'store';
import {urlsToReason} from '_constants';
import _config from '_environment';
import {pathToRegexp} from 'path-to-regexp';
import {genKey} from '_utils/pure-utils';
import {
  showLoading,
  hideLoading,
  toggleSessionExpired,
  addSessionExpiredRequest,
} from 'modules/global/actions';
import type {AxiosError, AxiosResponse} from 'axios';
import axios from 'axios';
import {getUserToken} from '_utils/safe-local-storage';

const urlsToReasonKeys = Object.keys(urlsToReason);
const hasKey = (pattern: string, key: string) => {
  const obj = new URLSearchParams(pattern.split('?')?.[1] || '');
  return obj.has(key);
};

export function requestLoading(config: any) {
  let path = '';

  try {
    const url = new URL(config.url);
    path = url.pathname.replace('/api/v1', '');
  } catch (e) {
    path = config.url.split('?')[0];
  }

  if (/^\//.test(path)) {
    path = path.replace('/', '');
  }

  let pattern = null;

  for (let i = 0; i < urlsToReasonKeys.length; i++) {
    const patternKey = urlsToReasonKeys[i].split('?')?.[0];
    const regexp = pathToRegexp(patternKey);

    if (regexp.exec(path)) {
      pattern = urlsToReasonKeys[i];
      break;
    }
  }

  // set undefined path
  if (!pattern) {
    if (_config.env === 'development') {
      pattern = path;
    } else {
      pattern = '';
    }
  }

  const key = genKey();

  config.loadingURLPattern = {
    key,
    pattern,
    skipPreloader: config.params?.['__skipPreloader'],
  };

  // __skipPreloader: param allow skip display loader
  if (!hasKey(pattern, 'skip') && !config.params?.['__skipPreloader'] && store.dispatch) {
    store.dispatch(showLoading(pattern, config.method, key));
  }

  if (config.params?.['__skipPreloader']) {
    delete config.params['__skipPreloader'];
  }

  return config;
}

export function responseLoading(response: AxiosResponse) {
  if (response.config && store.dispatch) {
    // @ts-expect-error We mutate config in requestLoading with loadingURLPattern
    const {loadingURLPattern, method} = response.config;
    if (!loadingURLPattern.skipPreloader) {
      store.dispatch(hideLoading(loadingURLPattern.pattern, method, loadingURLPattern.key));
    }
  }

  return response;
}

export function responseLoadingError(error: AxiosError) {
  const responseConfig = error?.response?.config || error?.config;

  if (!axios.isCancel(error) && responseConfig && store.dispatch) {
    // @ts-expect-error We mutate config in requestLoading with loadingURLPattern
    const {loadingURLPattern, method} = responseConfig;
    if (!loadingURLPattern.skipPreloader) {
      store.dispatch(hideLoading(loadingURLPattern.pattern, method, loadingURLPattern.key));
    }
  }

  return error;
}

export function responseUnathorized401(response: any) {
  if (
    !axios.isCancel(response) &&
    response.status === 401 &&
    !response.config.url.includes('/api/v1/profile')
  ) {
    return new Promise((resolve, reject) => {
      store.dispatch(toggleSessionExpired(true));
      const token = getUserToken();
      if (token) {
        store.dispatch(
          addSessionExpiredRequest({
            config: response.config,
            resolve,
            reject,
          })
        );
      }
    });
  }
  return response;
}

export function responseUnathorized401Error(error: AxiosError) {
  if (
    !axios.isCancel(error) &&
    error.response?.status === 401 &&
    !error.response.config.url?.includes('/api/v1/profile')
  ) {
    return new Promise((resolve, reject) => {
      store.dispatch(toggleSessionExpired(true));
      store.dispatch(
        addSessionExpiredRequest({
          config: error.response?.config,
          resolve,
          reject,
        })
      );
    });
  }
  return error;
}
