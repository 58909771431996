import type {Action} from 'redux';
import {combineReducers} from 'redux';
import type {ThunkAction, ThunkDispatch} from 'redux-thunk';
import type {RouterState} from 'connected-react-router';
import {connectRouter} from 'connected-react-router';
import type {Selector} from 'react-redux';

import login from 'containers/login/reducer';
import type {LoginState} from 'containers/login/types';
import oAuth from 'containers/oauth/reducer';
import farmsReducer from 'modules/farms/reducer';
import type {FarmState} from 'modules/farms/types';
import global from './modules/global/reducer';
import type {GlobalState} from './modules/global/types';
import cropPerformanceFilter from '_reducers/crop-performance-filter/field-filter-reducer';
import type {CPFilterState} from '_reducers/crop-performance-filter/field-filter-types';
import type {State as ViewportState} from './_reducers/viewport';
import viewport from './_reducers/viewport';
import users from 'containers/admin/users/reducer';
import type {InitialState as UsersStore} from 'containers/admin/users/types';
import features from 'containers/admin/features/reducer';
import registration from 'containers/registration/reducer';
import integrations from 'containers/profile/integration/reducer';
import type {IntegrationsState} from 'containers/profile/integration/types';
import map from 'containers/map/reducer';
import type {IInitialMapState as MapState} from 'containers/map/types';
import cropPerformance from 'containers/map/features/crop-performance/reducer';
import type {CropPerformanceState} from 'containers/map/features/crop-performance/types';
import sustainabilityInsights from 'modules/sustainability-insights/reducer';
import type {SIState} from 'modules/sustainability-insights/types';
import type {SI2State} from 'containers/si/module/types';
import tsUpload from 'containers/map/tissue-sampling-upload/reducer';
import helpPopup from 'containers/help-popup/reducer';
import userActivity from 'containers/admin/user-activity/reduсer';
import type {SubscriptionsStore} from 'modules/subscriptions';
import {reducer as subscriptionsReducer} from 'modules/subscriptions';
import type {HelpersStore} from 'modules/helpers';
import {reducer as helpersReducer} from 'modules/helpers';
import type {LogsStore} from 'modules/logs.module';
import {reducer as logsReducer} from 'modules/logs.module';
import type {UserAccessStore} from 'containers/admin/users/access-view/access-view.module';
import {reducer as userAccessReducer} from 'containers/admin/users/access-view/access-view.module';
import type {FieldsLookupStore} from 'modules/fields-lookup.module';
import {reducer as fieldsLookupReducer} from 'modules/fields-lookup.module';
import type {PickersStore} from 'modules/pickers.module';
import {reducer as pickersReducer} from 'modules/pickers.module';
import {reducer as addFieldsReducer} from 'modules/add-fields';
import type {CropTypesMatchingStore} from 'containers/admin/crop-types-matching/crop-types-matching.module';
import {reducer as cropTypesMatchingReducer} from 'containers/admin/crop-types-matching/crop-types-matching.module';
import type {AdminFeaturesStore} from './containers/admin/features/types';
import type {PartOfFieldSeasonStore} from 'containers/map/features/farm/field/planting-area-season.module';
import {reducer as partFieldSeasonReducer} from 'containers/map/features/farm/field/planting-area-season.module';
import type {ProductivityMapStore} from 'containers/map/features/zoning/productivity-map/productivity-map.module';
import {reducer as productivityMapReducer} from 'containers/map/features/zoning/productivity-map/productivity-map.module';
import {carbonSlice} from 'containers/mrv/enrollment/carbon-store/carbon-reducer';
import type {CarbonState} from 'containers/mrv/enrollment/carbon-store/types';
import monitoringReducer from 'containers/mrv/monitoring/module/reducer';
import siReducer from 'containers/si/module/reducer'; // SI v2

import type {ImagesCacheStore} from 'modules/images-cache.module';
import {reducer as imageCacheReducer} from 'modules/images-cache.module';
import type {StateUserActivity} from './containers/admin/user-activity/types';
import type {MonitoringState} from 'containers/mrv/monitoring/module/types';
import type {History} from 'history';
import type {AddNewFieldsStore} from 'modules/add-fields/types';

export type AppThunk<ActionType extends Action, ReturnType = void> = ThunkAction<
  ReturnType,
  AppStore,
  unknown,
  ActionType
>;

export type AppThunkDispatch<ActionType extends Action> = ThunkDispatch<
  AppStore,
  unknown,
  ActionType
>;

// TODO: add all reducer types;
export type AppStore = {
  helpers: HelpersStore;
  router: RouterState;
  login: LoginState;
  global: GlobalState;
  cropPerformanceFilter: CPFilterState;
  viewport: ViewportState;
  oAuth: any;
  farms: FarmState;
  users: UsersStore;
  registration: any;
  integrations: IntegrationsState;
  map: MapState;
  cropPerformance: CropPerformanceState;
  sustainabilityInsights: SIState;
  si: SI2State; // SI v2
  carbon: CarbonState;
  monitoring: MonitoringState;
  tsUpload: any;
  helpPopup: any;
  userActivity: StateUserActivity;
  pickers: PickersStore;
  addFields: AddNewFieldsStore;
  admin: {
    subscriptions: SubscriptionsStore;
    logs: LogsStore;
    features: AdminFeaturesStore;
    userAccess: UserAccessStore;
    fieldsLookup: FieldsLookupStore;
    cropTypesMatching: CropTypesMatchingStore;
  };
  partFieldSeason: PartOfFieldSeasonStore;
  productivityMap: ProductivityMapStore;
  imagesCache: ImagesCacheStore;
  // entities: EntitiesState;
};

export type AppSelector<TProps, TOwnProps = null> = Selector<AppStore, TProps, TOwnProps>;

// const store = {
//   routing: routerReducer,

// };

// export default store;

const createRootReducer = (history: History) =>
  combineReducers({
    router: connectRouter(history),
    login,
    global,
    cropPerformanceFilter,
    viewport,
    oAuth,
    farms: farmsReducer,
    users,
    registration,
    integrations,
    map,
    cropPerformance,
    sustainabilityInsights,
    si: siReducer, // SI v2
    carbon: carbonSlice.reducer,
    monitoring: monitoringReducer,
    tsUpload,
    helpPopup,
    userActivity,
    pickers: pickersReducer,
    addFields: addFieldsReducer,
    admin: combineReducers({
      subscriptions: subscriptionsReducer,
      features,
      userAccess: userAccessReducer,
      logs: logsReducer,
      fieldsLookup: fieldsLookupReducer,
      cropTypesMatching: cropTypesMatchingReducer,
    }),

    helpers: helpersReducer,
    partFieldSeason: partFieldSeasonReducer,
    productivityMap: productivityMapReducer,
    imagesCache: imageCacheReducer,
    // entities: entitiesReducer,
  });

export default createRootReducer;
