import {useEffect} from 'react';
import {setGlobalParam} from 'modules/global/actions';
import {setCurrentFieldId} from '../map/actions';
import {useAppDispatch, useAppSelector, usePrevious} from '_hooks';
import {selectDemoFarmsListIds, selectFarmsList} from 'modules/farms/selectors';
import {selectUser} from 'containers/login/login-selectors';
import {selectCurrentFarm} from 'containers/map/reducer/selectors';
/**
 * The component doesn't have any rendering, it exists just for the sake of running effects.
 */
const Effects = (): null => {
  const dispatch = useAppDispatch();
  const user = useAppSelector(selectUser);
  const currentFarm = useAppSelector(selectCurrentFarm);
  const farmsList = useAppSelector(selectFarmsList);
  const demoFarmsIdsList = useAppSelector(selectDemoFarmsListIds);
  const prev = usePrevious({
    // first render always returns undefined, always use prev.?
    user,
  });
  useEffect(() => {
    if (prev?.user?.settings?.showDemoFarms && !user?.settings?.showDemoFarms) {
      // if demo farms were hidden
      if (demoFarmsIdsList.includes(currentFarm.id)) {
        // if current selected farm is one of demo farms, switch to not demo one
        const newFarm = farmsList.find(f => !demoFarmsIdsList.includes(f.id));
        dispatch(setGlobalParam('currentGroupId', `${newFarm?.id}`));
        dispatch(setCurrentFieldId(0));
      }
    }
  }, [user?.settings?.showDemoFarms]);

  return null;
};

export default Effects;
