// @ts-nocheck
import {Text} from 'components';
import {FluroGeoJson} from 'components/fluro-leaflet';
import {SIAreaDefaultFillColor} from 'modules/sustainability-insights/constants';
import {
  getTillageAreaValuesById,
  isGeometryInTillageSummaryChart,
  selectAreaUnits,
  selectIsYearsDiff,
  selectSelectedTillagePracticeLabel,
  selectSIGeometryById,
  selectTillageGeometryColorById,
  selectYearsFilter,
} from 'modules/sustainability-insights/selectors';
import type {ComponentType} from 'react';
import React from 'react';
import {Tooltip} from 'react-leaflet';
import {useAppSelector} from '_hooks';
import {kiloFormatter} from '_utils/number-formatters';
import {valueFormatter} from '../filters-panel/cards/utils';
import type {GeometryProps} from './types';

export const TillageGeometry: ComponentType<GeometryProps> = ({
  id,
  name,
  isHighlighted,
  isActive,
  onClick,
}) => {
  const geometry = useAppSelector(s => selectSIGeometryById(s, id));
  const color = useAppSelector(s => selectTillageGeometryColorById(s, id));
  const {value, totalArableArea} = useAppSelector(s => getTillageAreaValuesById(s, id)) || {};

  const isInChart = useAppSelector(s => isGeometryInTillageSummaryChart(s, id));
  const areaUnits = useAppSelector(selectAreaUnits);
  const tillagePracticeLabel = useAppSelector(selectSelectedTillagePracticeLabel);
  const years = useAppSelector(selectYearsFilter);
  const isDiff = useAppSelector(selectIsYearsDiff);

  if (!geometry) return null;

  const eventHandlers = {
    click: onClick,
  };

  const fillColor = color || SIAreaDefaultFillColor;
  const defaultPathOptions = {weight: 1};
  const resolveGeometryPathOptions = () => {
    if (!isActive) {
      return {
        fillColor: SIAreaDefaultFillColor,
        fillOpacity: 0.5,
        color: 'black',
      };
    }

    if (!isInChart) {
      return {
        fillColor: SIAreaDefaultFillColor,
        fillOpacity: 1,
        color: '#464646',
      };
    }

    if (isHighlighted) {
      return {
        fillColor,
        fillOpacity: 1,
        color: 'black',
        weight: 4,
      };
    }

    return {
      fillColor,
      fillOpacity: 1,
      color: 'black',
    };
  };
  const geometryPathOptions = resolveGeometryPathOptions();

  const pathOptions = {
    ...defaultPathOptions,
    ...geometryPathOptions,
  };

  const signLabel =
    Math.sign(value) === 1 ? (
      <strong className="color-success">increase</strong>
    ) : (
      <strong className="color-danger">decrease</strong>
    );

  return (
    <>
      <FluroGeoJson data={geometry} pathOptions={pathOptions} eventHandlers={eventHandlers}>
        <Tooltip>
          <Text variant="small">
            <p className="mb-0">
              <strong>{name}</strong>
            </p>
            {isActive && (
              <>
                {value ? (
                  <>
                    {isDiff ? (
                      <>
                        {/* 13% increase in ac under No Till from 2019-2020 */}
                        {valueFormatter(value, areaUnits)} {areaUnits === 'num' ? ' ac' : ''}{' '}
                        {signLabel} in acres with {tillagePracticeLabel} from{' '}
                        {(years ?? []).join('-')}
                      </>
                    ) : (
                      <>
                        {/* 39k of 130k ac under No till for 2020*/}
                        <p className="mb-0">
                          {kiloFormatter(totalArableArea)} acres match filter settings
                        </p>
                        {valueFormatter(value, areaUnits)} of {kiloFormatter(totalArableArea)} acres
                        with {tillagePracticeLabel}
                      </>
                    )}
                  </>
                ) : (
                  <>No data available for {name}</>
                )}
              </>
            )}
          </Text>
        </Tooltip>
      </FluroGeoJson>
    </>
  );
};
