import {Flex, Text} from 'components';
import {CardHr} from 'components/card/card-styled';
import {FluroRheostat} from 'components/fluro-rheostat';
import {Span} from 'components/text/span';
import {setHighlightGeometry, setTillageRangeValues} from 'modules/sustainability-insights/actions';
import {SUMMARY_BARS_PREVIEW_THRESHOLD} from 'modules/sustainability-insights/constants';
import {
  selectAggLevel,
  selectAreaUnits,
  selectCurrentMeta,
  selectSelectedTillagePracticeLabel,
  selectTillageCardRange,
  selectTillageChartSummaryData,
  selectTillageChromaScale,
} from 'modules/sustainability-insights/selectors';
import type {
  GroupedSummaryChartData,
  SetTillageRangeValues,
} from 'modules/sustainability-insights/types';
import {
  aggregationLabelPlural,
  calculateScale,
  getSummaryChartLabel,
} from 'modules/sustainability-insights/utils';
import type {ComponentType} from 'react';
import React, {useCallback, useEffect} from 'react';
import {useDispatch} from 'react-redux';
import {useAppSelector} from '_hooks';
import {kiloFormatter} from '_utils/number-formatters';
import {LearnMore} from '../components/learn-more';
import {NoSelectedAreaMessage} from '../messages';
import {SummaryChart, SummaryChartGrouped} from '../summary-chart';
import {valueFormatter} from '../utils';
import type {AxisProps} from '@nivo/axes';

const CHART_KEYS = ['value'];

export const TillageSummaryChart: ComponentType<{}> = () => {
  const dispatch = useDispatch();

  const selectedPracticeLabel = useAppSelector(selectSelectedTillagePracticeLabel);
  const units = useAppSelector(selectAreaUnits);
  const colorScale = useAppSelector(selectTillageChromaScale);
  const meta = useAppSelector(selectCurrentMeta);
  const aggLevel = useAppSelector(selectAggLevel);
  const tillageSummaryChartData = useAppSelector(selectTillageChartSummaryData);
  const range = useAppSelector(selectTillageCardRange);
  const aggLevelLabel = aggregationLabelPlural(aggLevel);

  const {
    type,
    chartData,
    minValue,
    maxValue,
    preview,
    values,
    totalValue,
    valuesById = {},
  } = tillageSummaryChartData || {};

  const rangeMin = Math.floor(minValue);
  const rangeMax = Math.ceil(maxValue);

  const getColor = useCallback(
    (value: number) => {
      const scale = calculateScale(value, minValue, maxValue);
      const color = colorScale(scale).css();
      return color;
    },
    [colorScale, maxValue, minValue]
  );

  const setRange = useCallback(
    (newRange: SetTillageRangeValues['range']) => {
      dispatch(setTillageRangeValues(newRange));
    },
    [dispatch]
  );

  useEffect(() => {
    const innerRange: [number, number] = [rangeMin, rangeMax];
    if (innerRange.every(Number.isFinite)) {
      setRange(innerRange);
    }
  }, [rangeMin, rangeMax, selectedPracticeLabel, setRange]);

  const nameLabel: AxisProps['format'] = (id: number) => {
    return getSummaryChartLabel(meta?.[id]?.name, aggLevel === 'state');
  };

  const handleBarMouseEnter = ({data: {id}}: any) => {
    dispatch(setHighlightGeometry(id));
  };

  const handleBarMouseLeave = () => {
    dispatch(setHighlightGeometry(null));
  };

  const chartValuesFormatter = (value: number) => valueFormatter(value, units, true);

  if (!tillageSummaryChartData) return <NoSelectedAreaMessage />;

  return (
    <>
      {!!(preview && range) && (
        <>
          <div className="chart-range chart-range__container mb-2">
            <FluroRheostat
              colorScale={colorScale}
              name={`crop-summary-${units}`}
              inputChar={units === 'pct' ? '%' : 'ac'}
              min={rangeMin}
              max={rangeMax}
              data={values}
              onChange={setRange}
            />
          </div>
          <CardHr />
        </>
      )}

      {totalValue !== 0 && (
        <>
          <Text secondary elementType={'p'} variant={'small'}>
            {kiloFormatter(totalValue)} ac over {Object.keys(valuesById).length} {aggLevelLabel}{' '}
            under {selectedPracticeLabel} match current filters
          </Text>
          <CardHr />
        </>
      )}

      {type === 'normal' ? (
        <SummaryChart
          keys={CHART_KEYS}
          data={chartData}
          getColor={getColor}
          nameLabel={nameLabel}
          onMouseEnter={handleBarMouseEnter}
          onMouseLeave={handleBarMouseLeave}
          maxVisibleItems={SUMMARY_BARS_PREVIEW_THRESHOLD}
          format={chartValuesFormatter}
          maxValue={chartData?.length > 1 ? maxValue : undefined}
          minValue={chartData?.length > 1 ? minValue : undefined}
        />
      ) : (
        <SummaryChartGrouped
          keys={CHART_KEYS}
          data={chartData as GroupedSummaryChartData[]}
          getColor={getColor}
          nameLabel={nameLabel}
          onMouseEnter={handleBarMouseEnter}
          onMouseLeave={handleBarMouseLeave}
          maxVisibleItems={SUMMARY_BARS_PREVIEW_THRESHOLD}
          format={chartValuesFormatter}
          maxValue={chartData?.length > 1 ? maxValue : undefined}
          minValue={chartData?.length > 1 ? minValue : undefined}
        />
      )}

      <Flex justifyContent="space-between">
        <LearnMore articleId={6479122} />
        <Span secondary>
          {selectedPracticeLabel} ({units === 'pct' ? '%ac' : '#ac'})
        </Span>
      </Flex>
    </>
  );
};
