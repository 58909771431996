import React, {useMemo} from 'react';
import type {ConnectedProps} from 'react-redux';
import {connect} from 'react-redux';
import {ExpansionList, ExpansionPanel} from 'react-md';
import type {SamplingPoint} from '../../types';
import PointsList from './points-list';
import PointGroupHeader from './group-header';
import {getPointsGroupKeys} from '../../utils/sampling-points';

type Props = ConnectedProps<typeof connector> & {
  isReadOnly: boolean;
  pointsGroups: {[key: string]: SamplingPoint[]};
  toggleNmapPopUp(groupDate: string): void;
};

const AllPointsGroupsList = ({isReadOnly, pointsGroups, toggleNmapPopUp}: Props) => {
  const pointGroupsDates = useMemo(getPointsGroupKeys, [pointsGroups]);

  return (
    <ExpansionList className={'all-points-group-list'}>
      {pointGroupsDates.map(groupDate => {
        return (
          <ExpansionPanel
            className={'all-points-group-list__item'}
            key={groupDate}
            footer={null}
            defaultExpanded={true}
            label={
              <PointGroupHeader
                currentGroupDate={groupDate}
                isReadOnly={isReadOnly}
                toggleNmapPopUp={toggleNmapPopUp}
                pointsGroups={pointsGroups}
              />
            }
          >
            <PointsList currentGroupPoints={pointsGroups[groupDate]} />
          </ExpansionPanel>
        );
      })}
    </ExpansionList>
  );
};
const connector = connect(null, {});
export default connector(AllPointsGroupsList);
