// @ts-nocheck
import {createAsyncThunk} from '@reduxjs/toolkit';
import type {
  MRVStageInput,
  MRVProgram,
  MRVProgramEntities,
  MRVProgramResult,
  MRVValuesInput,
  MRVAttributeInput,
  MRVStage,
  MRVProgramInput,
  MRVCropType,
  MRVRegion,
  MRVPhaseNormalized,
  MRVProjectEligibility,
  MRVProject,
  MRVProjectConfig,
  AddAUserToAProgramFormData,
  MRVCommercialRuleData,
  MRVStageNormalized,
  MRVProgramCustomRegInput,
  MRVCustomInput,
  BulkUpdateFieldData,
  MRVValue,
  MRVEntityType,
} from 'containers/mrv/types';
import {MRVPhaseType, MRVStageType} from 'containers/mrv/types';
import type {NormalizedSchema} from 'normalizr';
import {normalize} from 'normalizr';
import type {AppStore} from 'reducers';
import MRVApi from '_api/mrv';
import {
  selectEnabledPhaseStages,
  selectPhaseStageByType,
  selectProjectFieldsIds,
  selectProjectFieldsList,
  selectProjectId,
  selectStageById,
} from './selectors';
import {
  addAccess,
  setBoundaries,
  setLoaded,
  setStageCompletion,
  updateProjectDNDCStatus,
  setProgramInvites,
  addProgramInvites,
  removeProgramInvite,
  removeStage,
} from './reducer';
import {normalizePrograms} from './base';
import {
  MonitoringAttributesSchema,
  MRVStageSchema,
  ProgramSchema,
  ProjectSchema,
} from './normalize-schemas';
import type {DocusignContractEvent} from './types';
import {ActionType} from './types';
import {enrollFields} from 'containers/mrv/enrollment/carbon-store/carbon-thunks';
import {
  setDNDCPercentCompleted,
  setDNDCResults,
} from 'containers/mrv/enrollment/carbon-store/carbon-reducer';
import type {AppDispatch, RootState} from 'store';
import {dialogToggle, DialogType} from 'modules/helpers';
import {
  selectAssignPracticeAttribute,
  selectExistingProjectValuesByAttributeId,
} from './enrollment.selectors';
import type {SyncResponseData} from 'containers/profile/integration/types';
import {reportError} from 'containers/error-boundary';
import groupBy from 'lodash/groupBy';
import type {GetProjectParams} from '_api/mrv.types';
import {showNotification} from 'components/notification/notification';
import {calcPlural} from '_utils/pure-utils';
import {createStageCompletion} from 'containers/mrv/base';
import {getEntityId} from 'containers/mrv/value-utils';
import {checkWorkspace} from 'containers/admin/users/types';

export const fetchPrograms = createAsyncThunk<
  NormalizedSchema<MRVProgramEntities, MRVProgramResult>,
  {pageToFetch: number; fetchStats?: boolean},
  {state: RootState}
>(ActionType.FETCH_ALL_PROGRAMS, async ({pageToFetch, fetchStats = true}, thunkAPI) => {
  const response = await MRVApi.getPrograms({page: pageToFetch});
  const {page, size, total, items} = response.data;

  const normalizedPrograms = normalizePrograms(items);

  thunkAPI.dispatch(setLoaded({page, size, total}));

  if (fetchStats) {
    const programIds = normalizedPrograms.result;
    if (programIds?.length) {
      thunkAPI.dispatch(fetchProgramStats(programIds));
    }
  }

  return normalizedPrograms;
});

export const fetchProgramStats = createAsyncThunk(
  ActionType.FETCH_PROGRAM_STATS,
  async (programIds: number[]) => {
    const response = await MRVApi.getProgramStats(programIds);
    const stats = response.data;
    return {entities: {stats}};
  }
);

export const fetchProgram = createAsyncThunk(
  ActionType.FETCH_PROGRAM,
  async ({programId}: {programId: number}) => {
    const response = await MRVApi.getProgram(programId);
    const program = response.data;

    const normalized = normalize<MRVProgram, MRVProgramEntities, MRVProgramResult>(
      program,
      ProgramSchema
    );

    return normalized;
  }
);

export const addCropType = createAsyncThunk(
  ActionType.ADD_CROP_TYPE,
  async ({programId, cropType}: {programId: number; cropType: MRVCropType}) => {
    const response = await MRVApi.addCropType(programId, [cropType]);
    const program = response.data;
    const normalized = normalize(program, ProgramSchema);
    return normalized;
  }
);

export const removeCropType = createAsyncThunk(
  ActionType.REMOVE_CROP_TYPE,
  async ({programId, mrvCropTypeId}: {programId: number; mrvCropTypeId: number}) => {
    const response = await MRVApi.removeCropType(programId, [mrvCropTypeId]);
    const program = response.data;
    const normalized = normalize(program, ProgramSchema);
    return normalized;
  }
);

export const fetchProject = createAsyncThunk(
  ActionType.FETCH_PROJECT,
  async ({projectId, params}: {projectId: number; params?: GetProjectParams}) => {
    const response = await MRVApi.getProject(projectId, params);
    const [project] = response.data;
    const normalized = normalize(project, ProjectSchema);
    return normalized;
  }
);

export const updateContractStatus = createAsyncThunk(
  ActionType.UPDATE_CONTRACT_STATUS,
  async ({projectId, event}: {projectId: number; event: DocusignContractEvent}) => {
    const response = await MRVApi.updateDocusignContractStatus({projectId, event});
    const project = response.data;
    const normalized = normalize(project, ProjectSchema);
    return normalized;
  }
);

export const requestContractRemoving = createAsyncThunk(
  ActionType.REQUEST_CONTRACT_REMOVING,
  async ({projectId, reason}: {projectId: number; reason: string}) => {
    const response = await MRVApi.requestEnrollmentContractRemoving(projectId, reason);
    return response.data;
  }
);

export const deleteProjectContract = createAsyncThunk(
  ActionType.DELETE_PROJECT_CONTRACT,
  async (projectId: number) => {
    await MRVApi.deleteProjectContract(projectId);
    return {projectId};
  }
);

export const fetchProjectsList = createAsyncThunk(
  ActionType.FETCH_PROJECTS_LIST,
  async ({ids}: {ids: number[]}, {dispatch}) => {
    const responses = await Promise.all(ids.map(projectId => dispatch(fetchProject({projectId}))));
    return responses;
  }
);

export const fetchProgramProjects = createAsyncThunk(
  ActionType.FETCH_PROGRAM_PROJECTS,
  async ({
    programId,
    pageToFetch,
    searchText,
  }: {
    programId: number;
    pageToFetch: number;
    searchText?: string;
  }) => {
    const response = await MRVApi.getProgramProjects({programId, pageToFetch, searchText});
    const {items, page, size, total} = response.data;
    const normalized = normalize(items, [ProjectSchema]);
    return {entities: normalized.entities, result: normalized.result, total, page, size};
  }
);

export const fetchProgramProjectsWithDeletionRequest = createAsyncThunk<
  {result: number[]},
  {
    programId: number;
    searchText?: string;
    onlyWithContractDeletionReason?: boolean;
  }
>(
  ActionType.FETCH_PROGRAM_PROJECTS_WITH_CONTRACT_DELETION,
  async ({programId, searchText, onlyWithContractDeletionReason}) => {
    const response = await MRVApi.getProgramProjects({
      programId,
      pageToFetch: 1,
      searchText,
      perPage: 100,
      onlyWithContractDeletionReason,
    });
    const normalized = normalize(response.data.items, [ProjectSchema]);
    return {result: normalized.result};
  }
);

export const updateMRVValues = createAsyncThunk<
  MRVValue[],
  {
    projectId: number;
    update: MRVValuesInput;
    entityType: MRVEntityType;
    shouldValidateAssignPractice?: boolean;
  },
  {state: AppStore}
>(
  ActionType.UPDATE_PROJECT_VALUES,
  async (
    {
      projectId,
      update,
      entityType,
      shouldValidateAssignPractice,
    }: {
      projectId: number;
      update: MRVValuesInput;
      entityType: MRVEntityType;
      shouldValidateAssignPractice?: boolean;
    },
    thunkApi
  ) => {
    clearDNDCData(projectId, thunkApi);

    const response = await MRVApi.updateEntityValues(projectId, update, entityType, true);

    if (shouldValidateAssignPractice) {
      thunkApi.dispatch(validateExistingAssignedPractices());
    }

    const stageId = response.data.stage_id;
    const completion = response.data.completion;
    thunkApi.dispatch(setStageCompletion({stageId, completion}));

    // Turn off eligibility completion when any value before eligibility stage changes.
    // The user will need to go to eligibility stage and rerun it.
    const eligibilityStage = selectPhaseStageByType(
      thunkApi.getState(),
      MRVPhaseType.Monitoring,
      MRVStageType.Eligibility
    );
    const updatedStage = selectStageById(thunkApi.getState(), stageId);
    if (eligibilityStage && updatedStage && updatedStage.order < eligibilityStage.order) {
      thunkApi.dispatch(setStageCompletion(createStageCompletion(eligibilityStage.id, false)));
    }

    return response.data.values;
  }
);

export const updateProjectConfig = createAsyncThunk<
  Partial<MRVProject>,
  {projectId: number; config: Partial<MRVProjectConfig>},
  {state: AppStore}
>(
  ActionType.UPDATE_PROJECT_CONFIG,
  async ({projectId, config}: {projectId: number; config: Partial<MRVProjectConfig>}) => {
    const response = await MRVApi.updateProjectConfig(projectId, config);

    return response.data;
  }
);

export const removeProjectValues = createAsyncThunk(
  ActionType.REMOVE_PROJECT_VALUES,
  async (
    {
      projectId,
      valueIds,
      entityType,
    }: {projectId: number; valueIds: number[]; entityType: MRVEntityType},
    thunkApi
  ) => {
    const response = await MRVApi.removeProjectValues(projectId, valueIds, entityType);

    const stageId = response.data.stage_id;
    const completion = response.data.completion;
    thunkApi.dispatch(setStageCompletion({stageId, completion}));

    return {projectId, valueIds};
  }
);

export const validateExistingAssignedPractices =
  (externalFieldsEligibility?: MRVProjectEligibility) =>
  async (dispatch: AppDispatch, getState: () => AppStore) => {
    const state = getState();
    const projectId = selectProjectId(state);
    const assignPracticesStage = selectPhaseStageByType(
      state,
      MRVPhaseType.Enrollment,
      MRVStageType.AssignPractices
    );
    const entityType = assignPracticesStage?.entity_type;
    const assignPracticesAttribute = selectAssignPracticeAttribute(state);
    const projectFieldsIds = selectProjectFieldsIds(state, projectId);
    const fieldPractices = selectExistingProjectValuesByAttributeId(
      state,
      assignPracticesAttribute?.id
    ).filter(p => projectFieldsIds.includes(getEntityId(p)));
    if (!fieldPractices.length) return; // break in case there is no field practices

    let fieldsEligibility = externalFieldsEligibility;

    if (!fieldsEligibility) {
      const eligibilityResponse = await MRVApi.getStageEligibility(
        projectId,
        assignPracticesStage?.id
      );
      fieldsEligibility = eligibilityResponse.data;
    }

    const valuesGroupedByField = groupBy(fieldPractices, 'field_id');

    const valuesToRemove: number[] = [];
    Object.values(valuesGroupedByField).forEach(fieldValues => {
      const entityId = fieldValues[0] ? getEntityId(fieldValues[0]) : undefined;

      const eligibleFieldAndPractices =
        fieldsEligibility?.[entityId]?.eligible !== false && // field should be eligible
        fieldsEligibility?.[entityId]?.eligible_practices?.some(eligiblePractices => {
          // and assigned practices should be in the list of eligible practices
          return (
            fieldValues.length === eligiblePractices.length && // handle case when a field has only one practice
            fieldValues.every(practice => eligiblePractices.includes(practice.value))
          );
        });

      if (!eligibleFieldAndPractices) {
        valuesToRemove.push(...fieldValues.map(({id}) => id));
      }
    });

    if (valuesToRemove.length && entityType) {
      dispatch(removeProjectValues({projectId, valueIds: valuesToRemove, entityType}));
    }
  };

export const fetchWholeProject = createAsyncThunk<void, {projectId: number}, {state: AppStore}>(
  ActionType.FETCH_WHOLE_PROJECT,
  async function fetchWholeProjectThunk({projectId}, {dispatch, getState}) {
    const projectRequest = dispatch(
      fetchProject({projectId, params: {include_dndc_status: true, include_ofs_status: true}})
    );

    const {
      payload: {
        // FIXME:
        // @ts-expect-error error leftover from convertion to strict mode, please fix
        result,
        // @ts-expect-error error leftover from convertion to strict mode, please fix
        entities: {projects},
      },
    } = await projectRequest;
    const project = projects[result];
    dispatch(fetchProgram({programId: project.program_id}));

    // set enrolled fields
    const projectFields = selectProjectFieldsList(getState(), projectId);
    const enrolledFields: Record<number, boolean> = {};
    projectFields.forEach(f => {
      enrolledFields[f.core_attributes?.kml_id] = true;
    });
    dispatch(enrollFields(enrolledFields));
  }
);

type RemoveProjectFieldsParams = {projectId: number; mrvFieldIds: number[]};
export const removeProjectFields = createAsyncThunk<
  RemoveProjectFieldsParams,
  RemoveProjectFieldsParams
>(ActionType.REMOVE_PROJECT_FIELDS, async ({projectId, mrvFieldIds}, thunkApi) => {
  clearDNDCData(projectId, thunkApi);

  await MRVApi.removeProjectFields(projectId, mrvFieldIds).catch(reportError);

  return {projectId, mrvFieldIds};
});

/**
 * Should be used when making any changes that would invalidate the DNDC Outcome Estimations
 */
function clearDNDCData(projectId: number, thunkApi: any) {
  thunkApi.dispatch(updateProjectDNDCStatus({projectId, status: null}));
  thunkApi.dispatch(setDNDCPercentCompleted(0));
  thunkApi.dispatch(
    setDNDCResults({
      payment: 0,
      tonsSequestered: 0,
    })
  );
}

export const fetchUserPermissions = createAsyncThunk(
  ActionType.FETCH_USER_PERMISSIONS,
  async (_, {dispatch}) => {
    const response = await MRVApi.getUserPermissions();
    const access = response.data;
    const userProjects = access.project_producer;
    const adminPrograms = access.program_admin;
    dispatch(addAccess({admin: adminPrograms, producer: userProjects}));
    return access;
  }
);

export const updateProgramPhase = createAsyncThunk(
  ActionType.UPDATE_PROGRAM_PHASE,
  async ({programId, phase}: {programId: number; phase: Partial<MRVPhaseNormalized>}) => {
    let response = null;
    if (phase.id) {
      response = await MRVApi.updateProgramPhase(programId, phase.id, phase);
    } else {
      response = await MRVApi.addProgramPhase(programId, phase);
    }
    const normalized = normalizePrograms([response.data]);
    return normalized;
  }
);

export const addProgram = createAsyncThunk<
  ReturnType<typeof normalize>,
  {program: MRVProgramInput},
  {state: AppStore}
>(ActionType.ADD_PROGRAM, async ({program}) => {
  const response = await MRVApi.addProgram(program);
  const normalized = normalizePrograms(response?.data);
  return normalized;
});

export const removeProgram = createAsyncThunk<number, {programId: number}, {state: AppStore}>(
  ActionType.REMOVE_PROGRAM,
  async ({programId}) => {
    await MRVApi.removeProgram(programId);
    return programId;
  }
);

export const addProgramStage = createAsyncThunk<
  ReturnType<typeof normalize>,
  {
    programId: number;
    phaseId: number;
    stage: MRVStageInput;
  },
  {state: AppStore}
>(ActionType.ADD_PROGRAM_MONITORING_STAGE, async ({programId, phaseId, stage}) => {
  const response = await MRVApi.addStage(programId, phaseId, stage);
  const normalized = normalize(response.data, [MRVStageSchema]);
  return normalized;
});

export const removeProgramStage = createAsyncThunk<
  void,
  {
    programId: number;
    phaseId: number;
    stageId: number;
  },
  {state: AppStore}
>(ActionType.REMOVE_PROGRAM_MONITORING_STAGE, async ({programId, phaseId, stageId}, thunkAPI) => {
  MRVApi.removeStage(programId, phaseId, stageId);
  thunkAPI.dispatch(removeStage({stageId}));
});

export const updateProgramStage = createAsyncThunk<
  ReturnType<typeof normalize>,
  {
    programId: number;
    phaseId: number;
    stageId: number;
    stage: Partial<MRVStage>;
  },
  {state: AppStore}
>(ActionType.UPDATE_PROGRAM_STAGE, async ({programId, phaseId, stageId, stage}) => {
  const response = await MRVApi.updateStage(programId, phaseId, stageId, stage);
  const normalized = normalize(response.data, MRVStageSchema);
  return normalized;
});

export const updateProgramStages = createAsyncThunk<
  ReturnType<typeof normalize>,
  {
    programId: number;
    phaseId: number;
    stages: Partial<MRVStageNormalized[]>;
  }
>(ActionType.UPDATE_PROGRAM_STAGES, async ({programId, phaseId, stages}) => {
  const response = await MRVApi.updateStages(programId, phaseId, stages);
  const normalized = normalize(response.data, [MRVStageSchema]);
  return normalized;
});

export const addProgramAttributes = createAsyncThunk<
  ReturnType<typeof normalize>,
  {
    programId: number;
    phaseId: number;
    stageId: number; // is used in the entities callback to remove the attribute from the stage
    attributes: MRVAttributeInput[];
  },
  {state: AppStore}
>(ActionType.ADD_PROGRAM_MONITORING_ATTRIBUTE, async ({programId, phaseId, attributes}) => {
  const response = await MRVApi.addAttributes(programId, phaseId, attributes);
  const normalized = normalize(response.data, [MonitoringAttributesSchema]);
  return normalized;
});

export const updateProgramAttribute = createAsyncThunk<
  ReturnType<typeof normalize>,
  {
    programId: number;
    phaseId: number;
    attributeId: number;
    attribute: MRVAttributeInput;
  },
  {state: AppStore}
>(
  ActionType.UPDATE_PROGRAM_MONITORING_ATTRIBUTE,
  async ({programId, phaseId, attributeId, attribute}) => {
    const response = await MRVApi.updateAttribute(programId, phaseId, attributeId, attribute);
    const normalized = normalize(response.data, MonitoringAttributesSchema);
    return normalized;
  }
);

export const updateProgramAttributes = createAsyncThunk<
  ReturnType<typeof normalize>,
  {
    programId: number;
    phaseId: number;
    attributes: MRVAttributeInput[];
  },
  {state: AppStore}
>(ActionType.UPDATE_PROGRAM_MONITORING_ATTRIBUTE, async ({programId, phaseId, attributes}) => {
  const response = await MRVApi.updateAttributes(programId, phaseId, attributes);
  const normalized = normalize(response.data, [MonitoringAttributesSchema]);
  return normalized;
});

export const removeProgramAttribute = createAsyncThunk<
  number,
  {
    programId: number;
    stageId: number; // is used in the entities callback to remove the attribute from the stage
    phaseId: number;
    attributeId: number;
  },
  {state: AppStore}
>(ActionType.REMOVE_PROGRAM_MONITORING_ATTRIBUTE, async ({programId, phaseId, attributeId}) => {
  await MRVApi.removeAttribute(programId, phaseId, attributeId);
  return attributeId;
});

export const updateProgramAssets = createAsyncThunk(
  ActionType.UPDATE_PROGRAM_ASSETS,
  async ({programId, assets}: {programId: number; assets: string[]}) => {
    const response = await MRVApi.addProgramAssets(programId, assets);
    const normalized = normalize(response.data, ProgramSchema);
    return normalized;
  }
);

export const removeProgramAssets = createAsyncThunk(
  ActionType.REMOVE_PROGRAM_ASSETS,
  async ({programId, assets}: {programId: number; assets: string[]}) => {
    const response = await MRVApi.removeProgramAssets(programId, assets);
    const normalized = normalize(response.data, ProgramSchema);
    return normalized;
  }
);

export const updateProgramPracticeChanges = createAsyncThunk(
  ActionType.UPDATE_PROGRAM_PRACTICE_CHANGES,
  async ({programId, practices}: {programId: number; practices: string[]}) => {
    const response = await MRVApi.addProgramPracticeChanges(programId, practices);
    const normalized = normalize(response.data, ProgramSchema);
    return normalized;
  }
);

export const removeProgramPracticeChanges = createAsyncThunk(
  ActionType.UPDATE_PROGRAM_PRACTICE_CHANGES,
  async ({programId, practices}: {programId: number; practices: string[]}) => {
    const response = await MRVApi.removeProgramPracticeChanges(programId, practices);
    const normalized = normalize(response.data, ProgramSchema);
    return normalized;
  }
);

export const updateProgram = createAsyncThunk(
  ActionType.UPDATE_PROGRAM,
  async (program: Partial<MRVProgram>) => {
    const response = await MRVApi.updateProgram(program);
    const normalized = normalize(response.data, ProgramSchema);
    return normalized;
  }
);

export const classifySyncResponseData =
  (response: SyncResponseData) => (dispatch: AppDispatch, getState: () => AppStore) => {
    const state = getState();
    const stages = selectEnabledPhaseStages(state, MRVPhaseType.Monitoring);
    const {isWorkspaceMrv} = checkWorkspace();

    const mrv = response?.data?.status_desc?.results?.mrv;
    if (!isWorkspaceMrv || !mrv) return;

    const projectId = response?.data?.payload?.mrv_project_id;
    if (projectId) {
      dispatch(fetchWholeProject({projectId}));
      stages?.forEach(stage => {
        dispatch(fetchStageCompletion({projectId, stageId: stage.id}));
      });
    }
    dispatch(
      dialogToggle(DialogType.fmsSyncReport, true, {
        data: mrv.values,
      })
    );
  };

export const fetchStageCompletion = createAsyncThunk(
  ActionType.FETCH_STAGE_COMPLETION,
  async ({projectId, stageId}: {projectId: number; stageId: number}) => {
    const response = await MRVApi.getStageCompletion(projectId, stageId);
    return {stageId, completion: response.data};
  }
);

export const generateEnrollmentDocusignLink = createAsyncThunk<
  string,
  {projectId: number; phaseId: number; redirect_url?: string}
>(
  ActionType.GENERATE_ENROLLMENT_PROJECT_CONTRACT,
  async ({projectId, phaseId, redirect_url}, {rejectWithValue, dispatch}) => {
    try {
      const response = await MRVApi.generateProjectContract({projectId, phaseId, redirect_url});
      return response?.data || rejectWithValue('');
    } catch (e) {
      const error = e?.data?.detail?.message ? `Error: ${e?.data?.detail?.message}` : undefined;
      dispatch(
        dialogToggle(DialogType.noContractInfo, true, {
          phaseId,
          error,
        })
      );
    }
  }
);

export const fetchRegions = createAsyncThunk(
  ActionType.FETCH_REGIONS,
  async ({regionName = '', stateName = ''}: {regionName?: string; stateName?: string}) => {
    const response = await MRVApi.getRegionsByName(regionName, stateName);
    const regions = response.data;
    return regions;
  }
);

export const fetchProgramBoundaries = createAsyncThunk(
  ActionType.FETCH_PROGRAM_BOUNDARIES,
  async (programId: number, {dispatch}) => {
    try {
      const response = await MRVApi.getProgramBoundaries(programId);
      // TODO (stas): handle the error properly
      if (response.status !== 200) return;
      dispatch(setBoundaries({programId, boundaries: response.data}));
      return response.data;
    } catch (e) {
      reportError(e);
    }
  }
);

export const fetchRegionGeography = async (region: MRVRegion) => {
  try {
    const response = await MRVApi.getRegionGeometry(region);
    return response.data;
  } catch (e) {
    reportError(e);
  }
};

export const fetchProgramInvites = createAsyncThunk(
  ActionType.FETCH_PROGRAM_INVITES,
  async (programId: number, {dispatch}) => {
    try {
      const response = await MRVApi.getProgramInvites(programId);
      dispatch(setProgramInvites({programId, invites: response.data.result}));
    } catch (e) {
      reportError(e);
    }
  }
);
export const renewProgramInvite = createAsyncThunk(
  ActionType.RENEW_PROGRAM_INVITE,
  async ({inviteId, programId}: {inviteId: string; programId: number}, {dispatch}) => {
    try {
      const response = await MRVApi.renewProgramInvite(inviteId);
      dispatch(removeProgramInvite({programId, inviteId}));
      dispatch(addProgramInvites({invites: [response.data.result]}));
    } catch (e) {
      reportError(e);
    }
  }
);

export const addProducer = createAsyncThunk(
  ActionType.FETCH_PROGRAM_INVITES,
  async (formData: AddAUserToAProgramFormData, {dispatch}) => {
    try {
      const response = await MRVApi.inviteMRvProducer(formData);
      const result = response.data.result;
      const createdProjects = result.projects[formData.email];

      if (createdProjects?.length) {
        createdProjects.forEach(project => {
          dispatch(fetchProject({projectId: project.id}));
        });
        showNotification({
          type: 'success',
          title: 'Success',
          message: `A new producer was added to ${calcPlural('project', createdProjects)}`,
        });
      } else if (result.new_invites[formData.email]) {
        showNotification({
          type: 'success',
          title: 'Success',
          message: `An invite was sent to the user ${formData.email}`,
        });
        dispatch(
          addProgramInvites({
            //@ts-expect-error error leftover from convertion to strict mode, please fix
            invites: Array.isArray(result.new_invites[formData.email]) // temporary ts-ignore it, wait back-end to update the response to ProgramInvite[]
              ? result.new_invites[formData.email]
              : [result.new_invites[formData.email]],
          })
        );
      }
    } catch (error) {
      showNotification({
        type: 'error',
        title: 'Error',
        message: `Couldn't add producer to the project`,
      });
    }
  }
);

export const getCommercialRulePaymentTypes = async (programId: number) => {
  try {
    const response = await MRVApi.getCommercialRulePaymentTypes(programId);
    return response.data;
  } catch (e) {
    reportError(e);
  }
};

export const getCommercialRule = async ({
  programId,
  phaseId,
}: {
  programId: number;
  phaseId: number;
}) => {
  try {
    const response = await MRVApi.getCommercialRules({programId, phaseId});
    return response.data;
  } catch (e) {
    reportError(e);
  }
};

export const createCommercialRule = async ({
  programId,
  phaseId,
  commercialRule,
}: {
  programId: number;
  phaseId: number;
  commercialRule: MRVCommercialRuleData;
}) => {
  try {
    const response = await MRVApi.createCommercialRule({programId, phaseId, commercialRule});
    return response.data;
  } catch (e) {
    reportError(e);
  }
};

export const updateCommercialRule = async ({
  programId,
  phaseId,
  commercialRule,
}: {
  programId: number;
  phaseId: number;
  commercialRule: MRVCommercialRuleData;
}) => {
  try {
    const response = await MRVApi.updateCommercialRule({programId, phaseId, commercialRule});
    return response;
  } catch (e) {
    reportError(e);
  }
};

export const deleteCommercialRule = async ({
  programId,
  phaseId,
  commercialRuleId,
}: {
  programId: number;
  phaseId: number;
  commercialRuleId: number;
}) => {
  try {
    const response = await MRVApi.deleteCommercialRule({programId, phaseId, commercialRuleId});
    return response;
  } catch (e) {
    reportError(e);
  }
};

export const getFmsOptions = async () => {
  try {
    const response = await MRVApi.getFmsOptions();
    return response.data;
  } catch (e) {
    reportError(e);
  }
};

export const getProtocols = async () => {
  try {
    const response = await MRVApi.getProtocols();
    return response.data;
  } catch (e) {
    reportError(e);
  }
};

export const addFmsOptions = async ({programId, option}: {programId: number; option: string}) => {
  try {
    await MRVApi.addFmsOptions({programId, option});
  } catch (e) {
    reportError(e);
  }
};

export const deleteFmsOptions = async ({
  programId,
  option,
}: {
  programId: number;
  option: string;
}) => {
  try {
    await MRVApi.deleteFmsOptions({programId, option});
  } catch (e) {
    reportError(e);
  }
};

export const addCustomRegistrationInput = async ({
  registrationInput,
  programId,
}: {
  registrationInput: MRVProgramCustomRegInput;
  programId: number;
}) => {
  try {
    const result = await MRVApi.addCustomRegistrationInput({
      registrationInput,
      programId,
    });
    return result.data[0];
  } catch (e) {
    reportError(e);
  }
};

export const updateCustomRegistrationInput = async ({
  registrationInput,
  programId,
}: {
  registrationInput: MRVProgramCustomRegInput;
  programId: number;
}) => {
  try {
    const result = await MRVApi.updateCustomRegistrationInput({
      registrationInput,
      programId,
    });
    return result.data[0];
  } catch (e) {
    reportError(e);
  }
};

export const deleteCustomRegistrationInput = async ({
  inputId,
  programId,
}: {
  inputId: number;
  programId: number;
}) => {
  try {
    await MRVApi.deleteCustomRegistrationInput({
      inputId,
      programId,
    });
  } catch (e) {
    reportError(e);
  }
};

export const getMrvProjectCustomInputs = async ({projectId}: {projectId: number}) => {
  try {
    const response = await MRVApi.getMrvProjectCustomInputs({
      projectId,
    });
    return response.data;
  } catch (e) {
    reportError(e);
  }
};

export const createMrvProjectCustomInputsValue = async ({
  projectId,
  input,
}: {
  projectId: number;
  input: MRVCustomInput;
}) => {
  try {
    const response = await MRVApi.createMrvProjectCustomInputsValue({
      projectId,
      input,
    });
    return response.data;
  } catch (e) {
    reportError(e);
  }
};

export const updateMrvProjectCustomInputsValue = async ({
  projectId,
  input,
}: {
  projectId: number;
  input: MRVCustomInput;
}) => {
  try {
    const response = await MRVApi.updateMrvProjectCustomInputsValue({
      projectId,
      input,
    });
    return response.data;
  } catch (e) {
    reportError(e);
  }
};

export const fetchCredentials = async () => {
  try {
    const response = await MRVApi.fetchCredentials();
    return response.data;
  } catch (e) {
    reportError(e);
  }
};

export const fetchCropTypes = async ({
  program_id,
  phase_id,
  stage_id,
  attribute_id,
}: {
  program_id: number;
  phase_id: number;
  stage_id: number;
  attribute_id: number;
}) => {
  try {
    const response = await MRVApi.fetchCropTypes({
      program_id,
      phase_id,
      stage_id,
      attribute_id,
    });
    return response.data;
  } catch (e) {
    reportError(e);
  }
};

export const deleteCropType = async ({
  program_id,
  phase_id,
  stage_id,
  attribute_id,
  crop_type_ids,
}: {
  program_id: number;
  phase_id: number;
  stage_id: number;
  attribute_id: number;
  crop_type_ids: number[];
}) => {
  try {
    const response = await MRVApi.deleteCropType({
      program_id,
      phase_id,
      stage_id,
      attribute_id,
      crop_type_ids,
    });
    return response.data;
  } catch (e) {
    reportError(e);
  }
};

export const addNewCropType = async ({
  program_id,
  phase_id,
  stage_id,
  attribute_id,
  crop_type_ids,
}: {
  program_id: number;
  phase_id: number;
  stage_id: number;
  attribute_id: number;
  crop_type_ids: number[];
}) => {
  try {
    const response = await MRVApi.addNewCropType({
      program_id,
      phase_id,
      stage_id,
      attribute_id,
      crop_type_ids,
    });
    return response.data;
  } catch (e) {
    reportError(e);
  }
};

export const getProgram = async (programId: number) => {
  try {
    const response = await MRVApi.getProgram(programId);
    return response.data;
  } catch (e) {
    reportError(e);
  }
};

export const bulkUpdateFieldsFarm = async ({
  projectId,
  field_data,
  target_farm_id,
}: {
  projectId: number;
  field_data: BulkUpdateFieldData[];
  target_farm_id: number;
}) => {
  try {
    const response = await MRVApi.bulkUpdateFieldsFarm({
      projectId,
      field_data,
      target_farm_id,
    });
    return response.data;
  } catch (e) {
    reportError(e);
  }
};

export const deleteFarm = async ({
  projectId,
  mrvFarmId,
}: {
  projectId: number;
  mrvFarmId: number;
}) => {
  try {
    const response = await MRVApi.deleteFarm({projectId, mrvFarmId});
    return response.data;
  } catch (e) {
    reportError(e);
  }
};
