import React from 'react';
import {FontIcon} from 'react-md';
import {Flex, FluroButton} from 'components';
import {practiceKPIIcons} from 'containers/si/icons';
import type {InterventionOptions} from 'containers/si/types';
import '../create-report.scss';

type InterventionScenariosProps = {
  scenarioNumber: number;
  interventionScenarioArray: [InterventionOptions, string][];
  removeIntervention?: () => void;
};

export const InterventionScenarios = ({
  scenarioNumber,
  interventionScenarioArray,
  removeIntervention,
}: InterventionScenariosProps) => {
  return (
    <div className="intervention-scenario">
      <div className="intervention-info">
        <div className="intervention-label">Scenario {scenarioNumber}: </div>
        {interventionScenarioArray.map(scenario => {
          const [type, label] = scenario; //as [InterventionOptions, string];

          return label === '' ? null : (
            <Flex
              key={label}
              className={'margin-left-12'}
              direction={'row'}
              alignItems={'center'}
              nowrap
              gap={'4px'}
            >
              {practiceKPIIcons[type]} {label}
            </Flex>
          );
        })}
      </div>
      <div>
        {removeIntervention && (
          <FluroButton
            icon
            iconEl={<FontIcon>delete</FontIcon>}
            onClick={removeIntervention}
            tooltipPosition={'left'}
            tooltipLabel={'Remove intervention'}
            tooltipStyle={{left: '-40px'}}
          />
        )}
      </div>
    </div>
  );
};
