import type {ComponentType} from 'react';
import React from 'react';
import moment from 'moment';
import './index.scss';
import type {TAnomalyProps} from '../types';

import {formatDate} from '_utils';

import AnomaliesTablePanel from './anomalies-table-panel';
import {
  exportAnomaliesToShp,
  exportAnomaliesToKml,
  selectedPremiumAnomaliesChangeProp,
} from '../anomalies-utils';

import {bulkUpdateAreasOfInterest} from '../../../actions/areas-of-interest-actions';

import {
  changeLowPerfAnomalyProp,
  updateBulkPremiumAnomaly,
} from 'containers/map/actions/anomalies-actions';
import {useAppDispatch, useAppSelector} from '_hooks';
import {selectCurrentDate, selectIsReadOnly} from 'containers/map/reducer/selectors';
import {
  selectPerformanceGeometries,
  selectPremiumAnomalies,
} from 'containers/map/features/anomalies/anomalies-selectors';
import {dialogToggle, DialogType} from 'modules/helpers';

const AnomaliesPanel: ComponentType<{tabTitle?: string}> = ({tabTitle = ''}) => {
  const dispatch = useAppDispatch();
  const currentDate = useAppSelector(selectCurrentDate);
  const isReadOnly = useAppSelector(selectIsReadOnly);
  const premiumAnomalies = useAppSelector(selectPremiumAnomalies).list;
  const {geometryToShow} = useAppSelector(selectPerformanceGeometries);

  const bulkUpdateProp = <K extends keyof TAnomalyProps>(prop: K, value: TAnomalyProps[K]) => {
    const selectedPremiumAnomalies = premiumAnomalies.filter(a => a.properties.checked);

    const lowPerfAnomalies = geometryToShow.filter(
      shape => shape.properties && shape.properties.isLowPerf && shape.properties.checked
    );
    const ROI = geometryToShow.filter(
      shape => shape.properties && !shape.properties.isLowPerf && shape.properties.checked
    );

    lowPerfAnomalies.length && dispatch(changeLowPerfAnomalyProp(lowPerfAnomalies, prop, value));

    if (ROI.length) {
      dispatch(bulkUpdateAreasOfInterest(ROI, prop, value));
    }

    if (selectedPremiumAnomalies.length) {
      dispatch(
        updateBulkPremiumAnomaly({
          anomalies: selectedPremiumAnomalies,
          prop,
          value,
        })
      );
    }
  };

  const selectedPremiumAnomalies = premiumAnomalies.filter(a => a.properties.checked);
  const selectedGeometries = geometryToShow.filter(({properties}) => properties.checked);

  return (
    <div className="anomalies-panel">
      <AnomaliesTablePanel
        isReadOnly={isReadOnly}
        disabled={!selectedPremiumAnomalies.length && !selectedGeometries.length}
        onAddNew={() => dispatch(dialogToggle(DialogType.addNewAnomaly, true))}
        onExportShp={() =>
          dispatch(exportAnomaliesToShp([...selectedPremiumAnomalies, ...selectedGeometries]))
        }
        onExportKml={() =>
          dispatch(exportAnomaliesToKml([...selectedPremiumAnomalies, ...selectedGeometries]))
        }
        onSnooze={() => dispatch(selectedPremiumAnomaliesChangeProp('snoozed'))}
        itemList={[...selectedPremiumAnomalies, ...selectedGeometries]}
        snoozeItems={selectedPremiumAnomalies}
        currentDate={moment(currentDate, 'DD/MM/YYYY').format(formatDate())}
        onChangeLabel={bulkUpdateProp}
        tabTitle={tabTitle}
      />
    </div>
  );
};

export default AnomaliesPanel;
