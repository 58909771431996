// @ts-nocheck
import {t} from 'i18n-utils';
import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import type {AppStore} from '../../../../../reducers';
import {FontIcon} from 'react-md';
import {toggleTooltip} from 'modules/global/actions';
import type {NRxObjectiveType} from 'containers/map/features/nrx';
import {ObjectiveLabels} from 'containers/map/features/nrx';
import cn from 'classnames';
import {selectNRxObjective} from 'containers/map/actions/nitrogen-recommendation-actions';
import {formatUnit} from '_utils';

const EconomicObjectiveTabs = () => {
  const dispatch = useDispatch();
  const measurement = useSelector((store: AppStore) => store.login.user.settings.measurement);
  const {
    selectedObjective,
    nrxResult,
    nrxTabRate,
    nrxRecommendationSettings: {isLiquid},
  } = useSelector((store: AppStore) => store.map.nRecommendation);
  const currentObjectives = nrxResult[nrxTabRate];
  const productMeasure = formatUnit(measurement, isLiquid ? 'l/ha' : 'kg/ha');
  const objectivesDisplayOrder = ['max_roi', 'balanced', 'max_yield'] as NRxObjectiveType[];

  return (
    <div className={'economic-objective-tabs'}>
      <div className={'labels-container'}>
        <div className={'label-item'}>
          <span>{t({id: 'Goal'})}</span>
          <FontIcon
            className={'help-icon'}
            data-tip={''}
            data-for={'objective-info'}
            onMouseEnter={() =>
              dispatch(
                toggleTooltip({
                  id: `objective-info`,
                  content: t({
                    id: 'This section allows you to select between nitrogen applications that result in the highest return on investment (ROI), the highest yield, or a balance between ROI and yield.',
                  }),
                  place: 'top',
                })
              )
            }
          >
            help_outline
          </FontIcon>
        </div>
        <div className="label-item">
          <span>
            Avg. Rx <div className={'units'}>{productMeasure}</div>
          </span>
        </div>
      </div>

      <div className="objectives-container">
        {objectivesDisplayOrder.map(objective => {
          if (!currentObjectives[objective]?.features?.length) return null;

          const loopObjective = currentObjectives[objective];
          return (
            <div
              key={objective}
              className={cn('objective-item', {selected: selectedObjective === objective})}
              onClick={() => dispatch(selectNRxObjective(objective))}
            >
              <div className="objective-label">{ObjectiveLabels[objective]}</div>
              <div className="nitrogen-value">{loopObjective.avgNrx}</div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default EconomicObjectiveTabs;
