import type {FC} from 'react';
import React from 'react';
import {GradientColors, GradientHalf, GradientPanel} from '../styled-components';
import {Text} from 'components';
import {useGreenToRedColors} from '../hooks/useGreenToRedColors';

type Props = {
  leftLabel: string;
  rightLabel: string;
  inverted?: boolean;
};

export const ScenarioBarsGradients: FC<Props> = ({leftLabel, rightLabel, inverted = false}) => {
  const {highestColorNegative, lowestColorNegative, lowestColorPositive, highestColorPositive} =
    useGreenToRedColors();

  return (
    <GradientPanel>
      <GradientHalf direction="left">
        <GradientColors
          startColor={inverted ? highestColorPositive : highestColorNegative}
          endColor={inverted ? lowestColorPositive : lowestColorNegative}
        />
        <Text variant="small">{leftLabel}</Text>
      </GradientHalf>
      <GradientHalf direction="right">
        <GradientColors
          startColor={inverted ? lowestColorNegative : lowestColorPositive}
          endColor={inverted ? highestColorNegative : highestColorPositive}
        />

        <Text variant="small">{rightLabel}</Text>
      </GradientHalf>
    </GradientPanel>
  );
};
