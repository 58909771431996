import React from 'react';
import type {ReactElement} from 'react';
import type {BarExtendedDatum} from '@nivo/bar';
import {REPORT_DASHBOARD_EF_DETAILS, REPORT_DASHBOARD_EF_BAR_LABELS} from 'containers/si/constants';
import type {BarChartData} from './emission-factor';
import {Tooltip} from 'components/tooltip/tooltip';
import type {EmissionsFactorDetailsKey} from 'containers/si/api/apiTypes';

// The chart can't do a Stacked AND Grouped bar chart, so we set the chart to grouped, and then use this component to render the stacked bars
type StackedBarProps = {
  x: number;
  y: number;
  width: number;
  height: number;
  data: BarExtendedDatum & {data: BarChartData};
  renderTooltip?: (
    data: BarExtendedDatum & {data: BarChartData},
    key: string
  ) => string | ReactElement | number;
};
export const EFStackedBar = ({x, y, width, height, data, renderTooltip}: StackedBarProps) => {
  // this is the detailed data for each bar as an object
  const detailedData = data?.data?.detailedData;

  const selectedEFComponentDataUnsorted = detailedData[data.id as 'book' | 'baseline' | 'scenario']; // this is the detailed data for the selected bar using the id of the bar "book" | "baseline" | "scenario

  // need to sort the selectedEFComponentDataUnsorted object by the REPORT_DASHBOARD_EF_DETAILS[key].order value
  // returns a sorted [key: SIScenarioEFComponents, value: bar value][]
  const sortedEFComponentDataArray = Object.entries(selectedEFComponentDataUnsorted).sort(
    (a, b) => {
      const aOrder = REPORT_DASHBOARD_EF_DETAILS[a[0] as EmissionsFactorDetailsKey].order;
      const bOrder = REPORT_DASHBOARD_EF_DETAILS[b[0] as EmissionsFactorDetailsKey].order;

      if (aOrder < bOrder) return -1;
      if (aOrder > bOrder) return 1;
      return 0;
    }
  ) as [EmissionsFactorDetailsKey, number][];

  // 'width' is the total width of the bar
  const widthValue = data.value;
  const widthScalePercentage = width / widthValue;

  // Calculate the 'x' of each EF component by multiplying the EF bar value with the scale percentage, then and adding the width to the previously computed bar widths
  const barX = sortedEFComponentDataArray.reduce((acc, [key, value], index) => {
    if (index === 0) {
      acc[key] = {x: x, width: value * widthScalePercentage};
    } else {
      acc[key] = {
        x:
          acc[Object.keys(acc)[index - 1] as EmissionsFactorDetailsKey].x +
          acc[Object.keys(acc)[index - 1] as EmissionsFactorDetailsKey].width,
        width: value * widthScalePercentage,
      };
    }
    return acc;
  }, {} as Record<EmissionsFactorDetailsKey, {x: number; width: number}>);

  return (
    <g>
      {sortedEFComponentDataArray.map(
        ([key, value]: [key: EmissionsFactorDetailsKey, value: number]) => {
          return (
            <foreignObject
              style={{overflow: 'visible'}}
              width={width}
              height={height}
              x={barX[key].x}
              y={y}
              key={key}
            >
              <Tooltip
                id="ef-tooltip-container"
                tooltipClassName="ef-tooltip"
                place="left" // If the placement is anything else, the tooltip will be off be a couple hundred pixels
                content={renderTooltip && renderTooltip(data, key)}
              >
                <div
                  style={{
                    backgroundColor: REPORT_DASHBOARD_EF_DETAILS[key].color || '#464646',
                    height: height,
                    width: value * widthScalePercentage,
                  }}
                ></div>
              </Tooltip>
            </foreignObject>
          );
        }
      )}
      <text
        x={x - 4}
        y={y + height / 2}
        fill="#1B1B1B"
        textAnchor="end"
        alignmentBaseline="middle"
        fontSize={10}
      >
        {REPORT_DASHBOARD_EF_BAR_LABELS[data.id as keyof typeof REPORT_DASHBOARD_EF_BAR_LABELS]}
      </text>
    </g>
  );
};
