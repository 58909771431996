import type {ComponentType} from 'react';
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import type {ProgramStageSubItemProps} from 'components/mrv/home-page-components/home-page-components';
import {
  ProgramStageItem,
  ProgramStageItemsContainer,
} from 'components/mrv/home-page-components/home-page-components';
import {EnrollmentStep, setStep} from 'containers/mrv/enrollment/carbon-store';
import {PolygonIcon} from 'containers/map/icons';
import {useAppDispatch, useAppSelector, useAppThunkDispatch} from '_hooks';
import {ContractSignIcon, OutcomesIcon, PracticesIcon} from 'containers/mrv/icons';
import colors from '../../variables.scss';
import {applyPathParams, getGetURLParam} from '_utils/pure-utils';
import {getStageName} from 'containers/mrv/base';
import {
  ENROLLMENT_PROJECT_FORM,
  MONITORING_PROJECT_FORM,
  MRV_ENROLLMENT,
  MRV_HOME,
} from 'containers/mrv/routes';
import {useHistory} from 'react-router-dom';
import {
  selectCurrentProjectFieldsList,
  selectMonitoringReadOnly,
  selectMRVProgramById,
  selectEnabledPhaseStages,
  selectPhase,
  selectProjectById,
  selectPhaseStages,
} from 'containers/mrv/monitoring/module/selectors';
import {formatLabelsForPhases, isPhaseActive, viewTheContract} from 'containers/mrv/utils';
import {
  EnrollmentStepNameAliases,
  selectEnrollmentReadOnly,
  selectContractDeletionRequested,
  selectContractExists,
  selectDNDC,
} from 'containers/mrv/enrollment/carbon-store/selectors';
import type {MRVStageNormalized} from './types';
import {DNDCStatus, MRVPhaseType, MRVStageType, PhaseReadOnlyReason} from './types';
import {useMonitoringUrlParams} from './monitoring/hooks';
import {dialogToggle, DialogType} from '../../modules/helpers';
import {MRVPhasesInfoDialog} from './mrv-resources-dialog';
import {MRVNoContractDialog} from './mrv-no-contract-dialog';
import {selectUserSettings, selectUserVisibleName} from '../login/login-selectors';
import {
  generateEnrollmentDocusignLink,
  requestContractRemoving,
  updateContractStatus,
} from './monitoring/module/thunks';
import {Dropdown, Flex, FluroButton, InfoBlock} from 'components';
import {CircularProgress, ListItem} from 'react-md';
import {selectIsLoading} from '../../modules/helpers/selectors';
import {ActionType} from './monitoring/module/types';
import {MRVStageIconComponent} from '../../components/mrv/mrv-stage-icon';
import {stringifyUrlParams, useUrlParams} from '../../_hooks/use-url-params';
import {MRVEntityAllEntitiesId} from './constants';
import {RequestContractDeletionDialog} from './home-page-dialogs/mrv-request-contract-deletion-dialog';
import ReEnrollFieldsDialog from '../../components/mrv/home-page-components/re-enroll-fields-dialog';
import {DNDCMessage} from 'containers/mrv/enrollment/form/view-outcomes/dndc-message';
import {CheckIcon} from 'components/icons';
import {MrvCargillAccountCheckDialog} from './mrv-cargill-account-check-dialog';
import {useFetchStageCompletion} from 'containers/mrv/hooks';
import {useOpenDNDCModal} from 'containers/mrv/enrollment/form/view-outcomes/utils';
import {useSurveyItem} from './mrv-home/use-survey-item';
import {FormattedMessage} from 'react-intl';
import {t} from 'i18n-utils';
import {useIsStageDisabled} from './monitoring/hooks/use-is-stage-disabled';
import {defaultEnrollmentStageTypes} from './enrollment/base/base';
import Mixpanel from '_utils/mixpanel-utils';
import {logout} from 'containers/login/actions';

type Phases = 'enrollment' | 'monitoring' | 'reporting';

export const MRVHome = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();

  const {programId, projectId, contractEvent} = useMonitoringUrlParams();
  const userVisibleName = useAppSelector(selectUserVisibleName);
  const {isReadOnly} = useAppSelector(selectEnrollmentReadOnly);

  const isLoading = useAppSelector(s =>
    selectIsLoading(s, [ActionType.FETCH_PROGRAM, ActionType.GENERATE_ENROLLMENT_PROJECT_CONTRACT])
  );
  const [{programId: programIdFromParams}, setParams] = useUrlParams();

  const project = useAppSelector(s => selectProjectById(s, projectId));
  const program = useAppSelector(s => selectMRVProgramById(s, programId));
  const userSettings = useAppSelector(selectUserSettings);
  const importFields = getGetURLParam('importFields');

  const [reEnrollFieldsDialogVisibility, toggleReEnrollFieldsDialogVisibility] =
    useState<boolean>(false);

  useEffect(() => {
    if (!programId || !program) return;
    if (programId !== 68) return; // https://regrow.atlassian.net/browse/FSB-8832?focusedCommentId=34970
    if (importFields) return; // prioritize the GET param
    if (userSettings?.onboarding?.re_enroll_fields_onboarded === true) return; // do not open if the value is something different from the default state

    if (project.created_at < program.phases?.[0]?.start_date) {
      toggleReEnrollFieldsDialogVisibility(true);
    }
  }, [
    importFields,
    program,
    programId,
    project,
    userSettings?.onboarding?.re_enroll_fields_onboarded,
  ]);

  useEffect(() => {
    if (importFields) {
      toggleReEnrollFieldsDialogVisibility(true);
    }
  }, [importFields]);

  useEffect(() => {
    // set program id to url param. support wanna track it from hubspot
    if (programId && programId !== programIdFromParams) {
      setParams({programId});
    }
  }, [programId, programIdFromParams, setParams]);

  useEffect(() => {
    if (contractEvent === 'signing_complete') {
      dispatch(updateContractStatus({projectId, event: contractEvent}));
      Mixpanel.track('Sign contract');
    }
  }, [contractEvent, dispatch, projectId]);

  const onInfoDialogToggle = useCallback(
    (phase: Phases) => dispatch(dialogToggle(DialogType.mrvPhasesInfo, true, {phase})),
    [dispatch]
  );

  /**
   * @deprecated Todo - refactor to navigateToStage
   */
  const navigateToStep = (step: EnrollmentStep, stageId?: number) => {
    const urlParams: Record<string, string | number | boolean | undefined> = {
      programId,
      enrollmentStep: step,
      stageId,
    };

    if (step === EnrollmentStep.CropPractices) {
      urlParams['tableView'] = true;
    }

    history.push(
      `${applyPathParams(MRV_ENROLLMENT, {projectId})}?${stringifyUrlParams(urlParams)}`
    );
    dispatch(setStep({step, isReadOnly}));
  };

  return (
    <>
      {reEnrollFieldsDialogVisibility ? (
        <ReEnrollFieldsDialog
          onCloseDialog={() => toggleReEnrollFieldsDialogVisibility(false)}
          navigateToStep={(step: EnrollmentStep) => navigateToStep(step)}
        />
      ) : null}
      <Flex alignItems={'center'} gap={'10px'}>
        <h3 className="tab-title mrv-home-title">
          {program?.name || t({id: 'ProgramDefaultName', defaultMessage: 'Program'})}
        </h3>
        {isLoading ? <CircularProgress centered={false} id={'program-loader'} /> : null}
      </Flex>
      <div className="divider" />
      <div className="mrv-home-subtitle">
        <FormattedMessage
          id="HomePage.WelcomeUser"
          defaultMessage="<b>Welcome, {userVisibleName}!</b> You will be guided through each of the program phases.
        The open phase is highlighted in blue. All phases must be completed to receive credits."
          values={{userVisibleName, b: (msg: string) => <b>{msg}</b>}}
        />
      </div>

      <ProgramStageItemsContainer>
        {program?.phases.some(p => p.type_ === MRVPhaseType.Enrollment && p.enabled) && (
          <EnrollmentPhaseItem onInfoDialogToggle={onInfoDialogToggle} />
        )}

        {program?.phases.some(p => p.type_ === MRVPhaseType.Monitoring && p.enabled) && (
          <MonitoringPhaseItem onInfoDialogToggle={onInfoDialogToggle} />
        )}
      </ProgramStageItemsContainer>
      <MRVPhasesInfoDialog />
    </>
  );
};

type PhaseProps = {
  onInfoDialogToggle: (phase: Phases) => void;
};

export const EnrollmentPhaseItem: ComponentType<PhaseProps> = ({onInfoDialogToggle}) => {
  const dispatch = useAppDispatch();
  const thunkDispatch = useAppThunkDispatch();
  const {projectId, programId} = useMonitoringUrlParams();
  const history = useHistory();

  const enrollment = useAppSelector(s => selectPhase(s, MRVPhaseType.Enrollment));
  const {isReadOnly} = useAppSelector(selectEnrollmentReadOnly);
  const projectFieldsList = useAppSelector(selectCurrentProjectFieldsList);
  const contractExists = useAppSelector(selectContractExists);
  const contractDeletionRequested = useAppSelector(selectContractDeletionRequested);
  const completion = useAppSelector(s => s.monitoring.completion);
  const outcomeEstimationIsEnabled = enrollment?.params?.outcome_estimation;
  const isGeneratingContractLink = useAppSelector(s =>
    selectIsLoading(s, [ActionType.GENERATE_ENROLLMENT_PROJECT_CONTRACT])
  );
  const stages = useAppSelector(s => selectEnabledPhaseStages(s, MRVPhaseType.Enrollment));
  const allStages = useAppSelector(s => selectPhaseStages(s, MRVPhaseType.Enrollment));
  const fieldsAndBoundariesStage = allStages.find(
    stage => stage.type_ === MRVStageType.FieldBoundaries
  );
  const stageList = useAppSelector(s => selectPhaseStages(s, MRVPhaseType.Enrollment));
  const dndc = useAppSelector(selectDNDC);
  // TODO (stas): Comment for now, until we can test OPTIS.
  // const ofs = useAppSelector(selectOFS);
  const surveyStage = stages.find(stage => stage.type_ === MRVStageType.Survey);
  const {surveyItem, surveyDialog} = useSurveyItem(MRVPhaseType.Enrollment);
  const isStageDisabled = useIsStageDisabled(MRVPhaseType.Enrollment);

  const activePhase = isPhaseActive(enrollment?.start_date, enrollment?.end_date);

  const [deleteContractDialogVisible, toggleDeleteContractDialogVisibility] = useState(false);

  useFetchStageCompletion(MRVPhaseType.Enrollment);

  const navigateToStep = useCallback(
    (step: EnrollmentStep, stageId?: number) => {
      const urlParams: Record<string, string | number | boolean | undefined> = {
        programId,
        enrollmentStep: step,
        stageId,
      };

      history.push(
        `${applyPathParams(MRV_ENROLLMENT, {projectId})}?${stringifyUrlParams(urlParams)}`
      );
      dispatch(setStep({step, isReadOnly}));
    },
    [dispatch, history, isReadOnly, programId, projectId]
  );

  const {canOpenDNDCModal, openDNDCModal} = useOpenDNDCModal(navigateToStep);

  /**
   * Duplicated code in `src/containers/mrv/enrollment/view-outcomes/index.tsx`
   * Reason: circular dependency issue occurred when using as a custom hook.
   */
  const generateProjectContract = useCallback(async () => {
    if (!enrollment?.id) return;

    const generatedContractLinkAction = await thunkDispatch(
      generateEnrollmentDocusignLink({
        projectId,
        phaseId: enrollment?.id,
        redirect_url: `${location.origin}${applyPathParams(MRV_HOME, {
          projectId,
        })}`,
      })
    );

    if (generatedContractLinkAction.meta.requestStatus === 'fulfilled') {
      window.open(generatedContractLinkAction.payload as string, '_self');
    }
  }, [thunkDispatch, enrollment?.id, projectId]);

  const GeneratedContractActions = useCallback(
    ({disabled}: {disabled: boolean}) => {
      return (
        <Dropdown
          className={'margin-left-auto margin-right-16'}
          button={
            <FluroButton disabled={disabled} icon>
              more_vert
            </FluroButton>
          }
          anchor={'right'}
        >
          <ListItem
            key={'view'}
            onClick={() => viewTheContract(projectId)}
            className={'list-style-none'}
            primaryText={t({id: 'ContractActions.View', defaultMessage: 'View'})}
          />
          <ListItem
            key={'delete'}
            className={'list-style-none'}
            onClick={() => toggleDeleteContractDialogVisibility(true)}
            primaryText={t({id: 'ContractActions.Delete', defaultMessage: 'Delete'})}
          />
        </Dropdown>
      );
    },
    [projectId]
  );

  const requestDocusignContractDeletion = (reason: string) => {
    dispatch(requestContractRemoving({projectId, reason}));
  };

  const navigateToStage = useCallback(
    (stageId: number) => {
      const urlParams: Record<string, string | number | boolean> = {
        programId,
        stageId,
        tableView: true,
        entityId: MRVEntityAllEntitiesId,
      };

      history.push(
        `${applyPathParams(ENROLLMENT_PROJECT_FORM, {projectId, programId})}?${stringifyUrlParams(
          urlParams
        )}`
      );
    },
    [history, programId, projectId]
  );

  const enrollmentPhaseItems = useMemo(() => {
    const fieldsAndBoundariesIsCompleted = !!projectFieldsList.length;
    const programStageSubItems: ProgramStageSubItemProps[] = [
      {
        title: EnrollmentStepNameAliases[EnrollmentStep.Fields](),
        icon: <PolygonIcon fill={colors['main-green-600']} />,
        outlined: true,
        // on older programs, fields and boundaries doesn't exist as a stage
        onClick: () =>
          fieldsAndBoundariesStage?.id
            ? navigateToStage(fieldsAndBoundariesStage.id)
            : navigateToStep(EnrollmentStep.Fields),
        actionLabel: isReadOnly ? 'view' : fieldsAndBoundariesIsCompleted ? 'edit' : 'start',
        percentComplete: fieldsAndBoundariesIsCompleted ? 100 : 0,
        order: -1,
      },
    ];

    const restStages =
      stageList?.filter(
        stage => stage.enabled && !defaultEnrollmentStageTypes.includes(stage.type_)
      ) || [];

    const restStageItems = restStages.map((stage: MRVStageNormalized): ProgramStageSubItemProps => {
      const {icon, id, type_, order} = stage;
      const isCompleted = completion?.[id]?.is_completed;

      return {
        title: getStageName(stage),
        icon: <MRVStageIconComponent iconUrl={icon} stageType={type_} />,
        onClick: () => navigateToStage(id),
        percentComplete: completion?.[id]?.percentage_complete || 0,
        actionLabel: isReadOnly ? 'view' : isCompleted ? 'edit' : 'start',
        disabled: isStageDisabled(stage),
        order,
      };
    });

    programStageSubItems.push(...restStageItems);

    // TODO (stas): Comment for now, until we can test OPTIS.
    // The stage will still appear in mrv-home because of the restStages.
    //
    // const cropPracticesStage = stages.find(s => s.type_ === MRVStageType.ConfirmHistory);
    // if (cropPracticesStage?.enabled) {
    //   const cropPracticesPercent = completion[cropPracticesStage.id]?.percentage_complete;
    //   const cropPracticesIsCompleted = cropPracticesPercent === 100;
    //   const cropPracticesStageIsDisabled = isStageDisabled(cropPracticesStage);

    //   function getCropPracticesAction() {
    //     if (isReadOnly) {
    //       return 'view';
    //     }

    //     if (cropPracticesIsCompleted) {
    //       return 'edit';
    //     }

    //     if (ofs.status === OFSStatus.Success || ofs.status === OFSStatus.InProgress) {
    //       return 'continue';
    //     }

    //     if (cropPracticesStageIsDisabled) {
    //       return 'start';
    //     }

    //     return 'view';
    //   }

    //   function getCropPracticesSubtitle() {
    //     if (!ofs.status || cropPracticesIsCompleted) {
    //       return;
    //     }

    //     if (ofs.status === OFSStatus.Failed) {
    //       return t({id: 'Error detecting crop history'});
    //     }

    //     if (ofs.percentCompleted === 100) {
    //       return (
    //         <>
    //           {t({id: 'Detection completed'})}
    //           <CheckIcon size={14} style={{marginLeft: '6px', verticalAlign: 'bottom'}} />
    //         </>
    //       );
    //     }

    //     return t(
    //       {id: 'Detecting Percent', defaultMessage: 'Detecting... {percentCompleted}% complete'},
    //       {percentCompleted: ofs.percentCompleted}
    //     );
    //   }

    //   programStageSubItems.push({
    //     title: EnrollmentStepNameAliases[EnrollmentStep.CropPractices](),
    //     subtitle: getCropPracticesSubtitle(),
    //     badge:
    //       ofs.hasOptisValues && !cropPracticesIsCompleted
    //         ? 'info'
    //         : ofs.status === OFSStatus.Failed
    //         ? 'error'
    //         : undefined,
    //     icon: <HistoryIcon fill={colors['main-green-600']} />,
    //     outlined: true,
    //     onClick: () => navigateToStep(EnrollmentStep.CropPractices, cropPracticesStage?.id),
    //     actionLabel: getCropPracticesAction(),
    //     percentComplete: cropPracticesPercent,
    //     disabled: cropPracticesStageIsDisabled,
    //     order: cropPracticesStage?.order,
    //   });
    // }

    const assignPracticesStage = stages.find(s => s.type_ === MRVStageType.AssignPractices);
    if (assignPracticesStage?.enabled) {
      const assignPracticesCompletion = completion[assignPracticesStage.id];

      programStageSubItems.push({
        title: EnrollmentStepNameAliases[EnrollmentStep.AssignPractices](),
        icon: <PracticesIcon size={30} fill={colors['main-green-600']} />,
        outlined: true,
        onClick: () => navigateToStage(assignPracticesStage.id),
        actionLabel: isReadOnly
          ? 'view'
          : assignPracticesCompletion?.is_completed
          ? 'edit'
          : 'start',
        percentComplete: assignPracticesCompletion?.percentage_complete || 0,
        disabled: isStageDisabled(assignPracticesStage),
        order: assignPracticesStage.order,
      });
    }

    const viewOutcomesIsCompleted = contractExists;
    const viewOutcomesStage = stages.find(s => s.type_ === MRVStageType.ViewOutcomes);

    if (outcomeEstimationIsEnabled && viewOutcomesStage) {
      function getViewOutcomesAction() {
        if (isReadOnly) {
          return 'view';
        }

        if (canOpenDNDCModal) {
          return 'start';
        }

        if (dndc.status === DNDCStatus.Success) {
          return 'continue';
        }

        return 'view';
      }

      function getViewOutcomesSubtitle() {
        if (!dndc.status || viewOutcomesIsCompleted) {
          return;
        }

        if (dndc.status === DNDCStatus.Failed) {
          return t({id: 'Error calculating outcomes'});
        }

        if (dndc.status === DNDCStatus.Success) {
          return (
            <>
              {t({id: 'Calculation completed'})}
              <CheckIcon size={14} style={{marginLeft: '6px', verticalAlign: 'bottom'}} />
            </>
          );
        }

        return (
          <>
            {t({id: 'Calculating...'})}
            {/*
        Currently the DNDC notifications return all at once, so the percentage jumps from 0 to 100% in one instant.
        Add this code back in once the notifications are returned in chunks.
         {dndc.percentCompleted}% complete 
         */}
          </>
        );
      }

      programStageSubItems.push({
        title: EnrollmentStepNameAliases[EnrollmentStep.ViewOutcomes](),
        subtitle: getViewOutcomesSubtitle(),
        badge:
          dndc.percentCompleted === 100 && !contractExists
            ? 'info'
            : dndc.status === DNDCStatus.Failed
            ? 'error'
            : undefined,
        icon: <OutcomesIcon size={30} fill={colors['main-green-600']} />,
        outlined: true,
        disabled: isStageDisabled(viewOutcomesStage),
        onClick: () => {
          if (canOpenDNDCModal) {
            openDNDCModal();
          } else {
            navigateToStage(viewOutcomesStage.id);
          }
        },
        message: <DNDCMessage isOnMainPage />,
        actionLabel: getViewOutcomesAction(),
        percentComplete: dndc.status === DNDCStatus.Success ? 100 : 0,
        order: viewOutcomesStage?.order,
      });
    }

    if (surveyStage) {
      programStageSubItems.push(surveyItem);
    }

    // TODO add to EnrollmentStep when mrv-service sends it
    // const producerAgreementStage = stages.find(s => s.type_ === MRVStageType.Contract);
    const producerAgreementStage = stages.find(s => s.type_ === MRVStageType.Contract);

    if (
      // producerAgreementStage?.enabled &&
      enrollment?.show_contract &&
      producerAgreementStage
    ) {
      const producerAgreementIsDisabled =
        isStageDisabled(producerAgreementStage) || isGeneratingContractLink;

      const onClick = contractExists ? () => viewTheContract(projectId) : generateProjectContract;
      const actionLabel = contractExists || isReadOnly ? 'view' : 'start';

      const customAction =
        contractExists && !contractDeletionRequested ? (
          <GeneratedContractActions disabled={producerAgreementIsDisabled} />
        ) : undefined;

      programStageSubItems.push({
        title: t({id: 'EnrollmentStep.Producer agreement', defaultMessage: 'Producer agreement'}),
        icon: <ContractSignIcon size={30} fill={colors['main-green-600']} />,
        outlined: true,
        disabled: producerAgreementIsDisabled,
        percentComplete: contractExists ? 100 : 0,
        onClick: customAction ? undefined : onClick,
        actionLabel: customAction ? undefined : actionLabel,
        customAction,
        order: producerAgreementStage?.order,
      });
    }

    programStageSubItems.sort((a, b) => a.order - b.order);

    const result = programStageSubItems.map(item => {
      if (projectFieldsList.length) {
        return item;
      }

      return {
        ...item,
        disabled: item.order !== -1,
      };
    });

    return result;
  }, [
    projectFieldsList.length,
    isReadOnly,
    stages,
    stageList,
    completion,
    navigateToStage,
    navigateToStep,
    isStageDisabled,
    contractExists,
    outcomeEstimationIsEnabled,
    isGeneratingContractLink,
    enrollment?.show_contract,
    dndc.percentCompleted,
    dndc.status,
    canOpenDNDCModal,
    openDNDCModal,
    contractDeletionRequested,
    GeneratedContractActions,
    generateProjectContract,
    projectId,
    surveyStage,
    surveyItem,
    fieldsAndBoundariesStage,
  ]);

  return (
    <>
      <ProgramStageItem
        title={t({id: 'EnrollmentStepName.Enrollment', defaultMessage: 'Enrollment'})}
        subtitle={formatLabelsForPhases(enrollment?.start_date, enrollment?.end_date)}
        active={activePhase && !isReadOnly}
        defaultCollapsed={!activePhase}
        programStageSubItems={enrollmentPhaseItems}
        infoIconAction={enrollment?.resources ? () => onInfoDialogToggle('enrollment') : undefined}
      />
      {contractDeletionRequested && (
        <InfoBlock
          mini
          appearance={'info'}
          color={'info'}
          actions={
            <FluroButton onClick={() => dispatch(logout())} blank raised>
              {t({id: 'Logout', defaultMessage: 'Logout'})}
            </FluroButton>
          }
          className="margin-bottom-15"
        >
          {t({
            id: 'DeleteProducerAgreementRequestMessage',
            defaultMessage:
              'A request to delete your producer agreement has been sent to your program admin. You will be notified about next steps soon.',
          })}
        </InfoBlock>
      )}

      {deleteContractDialogVisible && (
        <RequestContractDeletionDialog
          requestContractDeletion={requestDocusignContractDeletion}
          onHide={() => toggleDeleteContractDialogVisibility(false)}
        />
      )}

      {surveyDialog}

      <MrvCargillAccountCheckDialog />
      <MRVNoContractDialog />
    </>
  );
};

export const MonitoringPhaseItem: ComponentType<PhaseProps> = ({onInfoDialogToggle}) => {
  const {projectId, programId} = useMonitoringUrlParams();
  const stages = useAppSelector(s => selectEnabledPhaseStages(s, MRVPhaseType.Monitoring));
  const completion = useAppSelector(s => s.monitoring.completion);
  const monitoring = useAppSelector(s => selectPhase(s, MRVPhaseType.Monitoring));
  const history = useHistory();
  const {isReadOnly, reason} = useAppSelector(selectMonitoringReadOnly);
  const {surveyDialog} = useSurveyItem(MRVPhaseType.Monitoring);

  const phaseIsActive = isPhaseActive(monitoring?.start_date, monitoring?.end_date);

  useFetchStageCompletion(MRVPhaseType.Monitoring);

  const isStageDisabled = useIsStageDisabled(MRVPhaseType.Monitoring);

  const navigateToStage = useCallback(
    (stageId: number) => {
      const urlParams: Record<string, string | number | boolean> = {
        programId,
        stageId,
        tableView: true,
        entityId: MRVEntityAllEntitiesId,
      };

      history.push(
        `${applyPathParams(MONITORING_PROJECT_FORM, {projectId, programId})}?${stringifyUrlParams(
          urlParams
        )}`
      );
    },
    [history, programId, projectId]
  );

  const programItems: ProgramStageSubItemProps[] = useMemo(() => {
    return (stages || []).map((stage: MRVStageNormalized): ProgramStageSubItemProps => {
      const {icon, id, type_, order} = stage;
      const isCompleted = completion?.[id]?.is_completed;

      return {
        title: getStageName(stage),
        icon: <MRVStageIconComponent iconUrl={icon} stageType={type_} />,
        onClick: () => navigateToStage(id),
        percentComplete: completion?.[id]?.percentage_complete || 0,
        actionLabel: isReadOnly ? 'view' : isCompleted ? 'edit' : 'start',
        disabled: isStageDisabled(stage),
        order,
      };
    });
  }, [stages, completion, isStageDisabled, isReadOnly, navigateToStage]);

  return (
    <>
      <ProgramStageItem
        title={t({id: 'ProgramStageItem.Measuring', defaultMessage: 'Measuring'})}
        subtitle={formatLabelsForPhases(monitoring?.start_date, monitoring?.end_date)}
        defaultCollapsed={!phaseIsActive || reason === PhaseReadOnlyReason.NoFieldsEnrolled}
        programStageSubItems={programItems}
        active={phaseIsActive && !isReadOnly}
        infoIconAction={monitoring?.resources ? () => onInfoDialogToggle('monitoring') : undefined}
      />
      {surveyDialog}
    </>
  );
};
