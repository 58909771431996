// @ts-nocheck
import get from 'lodash.get';
import {getTreeDataByDate} from '../utils/trees';
import type {AppStore} from '../../../reducers';
import {useAppSelector} from '_hooks';
import config from '_environment';
import {
  selectColorSchema,
  selectCurrentField,
  selectCurrentSeason,
  selectCurrentSensor,
  selectCurrentCompareDate,
  selectCurrentCompareSensor,
  selectCurrentDate,
  selectCurrentDates,
  selectTreeDetection,
  selectWholeFarmData,
  selectCurrentTab,
  selectNitrogenOverlayURL,
} from '../reducer/selectors';
import {isLegacyImageryURL} from '_utils/imagery-utils';
import {useCallback} from 'react';

const ExcludedSensors = ['TCI', 'NC'];

type SelectTreeLayerImageProps = Pick<
  AppStore['map'],
  | 'currentSensor'
  | 'currentDate'
  | 'currentSensorCompare'
  | 'currentCompareDate'
  | 'feature'
  | 'field'
> & {
  layerType: AppStore['map']['treeDetection']['layerType'];
  isWholeFarmView: AppStore['map']['wholeFarm']['isWholeFarmView'];
  treeZoning: AppStore['map']['wholeFarm']['treeZoning'];
};

export const selectTreeLayerImageUrlHook = (
  props: SelectTreeLayerImageProps,
  isCompare = false,
  fieldMD5?: string
) => {
  const {
    currentSensor,
    currentDate,
    currentSensorCompare,
    currentCompareDate,
    layerType,
    isWholeFarmView,
    treeZoning,
    feature,
    field,
  } = props;

  if (isWholeFarmView && feature === 'zoning') {
    // get tree detection image
    const treeImage =
      treeZoning.fields[fieldMD5]?.selected && treeZoning.fields[fieldMD5]?.zoningImageSrc;

    return treeImage || '';
  }

  const sensor = isCompare ? currentSensorCompare : currentSensor;
  const date = isCompare ? currentCompareDate : currentDate;
  const treeData = layerType !== 'default' && getTreeDataByDate(date)?.[fieldMD5 || field.MD5];
  return get(treeData, `${sensor?.toLowerCase()}_tree_${layerType}`, '');
};

type SelectFieldLayerImageProps = Pick<
  AppStore['map'],
  | 'field'
  | 'currentSensor'
  | 'nitrogenOverlayURL'
  | 'currentDate'
  | 'currentDates'
  | 'currentSensorCompare'
  | 'currentCompareDate'
>;
const selectFieldLayerImageUrlHook = (
  storeProps: SelectFieldLayerImageProps,
  isCompare = false
) => {
  const {
    field,
    currentSensor,
    nitrogenOverlayURL,
    currentDate,
    currentDates,
    currentCompareDate,
    currentSensorCompare,
  } = storeProps;

  if (currentSensor === 'NMAP' && nitrogenOverlayURL) {
    return nitrogenOverlayURL;
  }

  const d = isCompare ? currentCompareDate : currentDate;
  const cs = isCompare ? currentSensorCompare : currentSensor;

  const imageDate = currentDates[d]?.[cs];

  let url = imageDate?.url;

  if (imageDate?.url && isLegacyImageryURL(imageDate?.url)) {
    const sensor = currentSensor.toLowerCase();
    url = `/services/data-service/indices/${sensor}/${field.MD5}/${imageDate.date}`;
  }

  return url;
};

type SelectPlantingAreaImageProps = Pick<
  AppStore['map'],
  | 'field'
  | 'colorSchema'
  | 'currentSensor'
  | 'currentSeason'
  | 'currentDate'
  | 'currentDates'
  | 'currentSensorCompare'
  | 'currentCompareDate'
>;

const selectPlantingAreaImageUrl = (
  storeProps: SelectPlantingAreaImageProps,
  isCompare = false
) => {
  const {
    currentDate,
    currentSensor,
    currentDates,
    currentSeason,
    field,
    colorSchema,
    currentSensorCompare,
    currentCompareDate,
  } = storeProps;
  const classifiedCurrentDate = isCompare ? currentCompareDate : currentDate;
  const classifiedCurrentSensor = isCompare ? currentSensorCompare : currentSensor;

  const currentImageDate =
    currentDates[classifiedCurrentDate]?.[classifiedCurrentSensor]?.date || '';

  const isTirs = currentSensor === 'TIRS';

  if (currentImageDate) {
    const url = `${config.baseUrl}services/data-service/indices/${currentSensor.toLowerCase()}/${
      field.MD5
    }/${currentImageDate}?geometryId=${currentSeason.geometry_id}`;

    return colorSchema === 'default' || ExcludedSensors.includes(currentSensor)
      ? url
      : url + `&${isTirs ? 'singleDate' : colorSchema}=true`;
  }

  return '';
};

export const useSelectCurrentFieldLayerImageUrl = () => {
  const currentSensor = useAppSelector(selectCurrentSensor);
  const currentDate = useAppSelector(selectCurrentDate);
  const currentDates = useAppSelector(selectCurrentDates);
  const currentSensorCompare = useAppSelector(selectCurrentCompareSensor);
  const currentCompareDate = useAppSelector(selectCurrentCompareDate);
  const {layerType} = useAppSelector(selectTreeDetection);
  const {isWholeFarmView, treeZoning} = useAppSelector(selectWholeFarmData);
  const feature = useAppSelector(selectCurrentTab);
  const field = useAppSelector(selectCurrentField);
  const colorSchema = useAppSelector(selectColorSchema);
  const nitrogenOverlayURL = useAppSelector(selectNitrogenOverlayURL);
  const currentSeason = useAppSelector(selectCurrentSeason);

  const selectTreeLayerImageUrl = useCallback(
    (isCompare = false, fieldMD5?: string) =>
      selectTreeLayerImageUrlHook(
        {
          currentSensor,
          currentDate,
          currentSensorCompare,
          currentCompareDate,
          layerType,
          isWholeFarmView,
          treeZoning,
          feature,
          field,
        },
        isCompare,
        fieldMD5
      ),
    [
      currentSensor,
      currentDate,
      currentSensorCompare,
      currentCompareDate,
      layerType,
      isWholeFarmView,
      treeZoning,
      feature,
      field,
    ]
  );

  const selectFieldLayerImageUrl = useCallback(
    (isCompare = false) =>
      selectFieldLayerImageUrlHook(
        {
          field,
          currentSensor,
          nitrogenOverlayURL,
          currentDate,
          currentDates,
          currentCompareDate,
          currentSensorCompare,
        },
        isCompare
      ),
    [
      field,
      currentSensor,
      nitrogenOverlayURL,
      currentDate,
      currentDates,
      currentCompareDate,
      currentSensorCompare,
    ]
  );

  const selectPlantingAreaLayerImageUrl = useCallback(
    (isCompare = false) =>
      selectPlantingAreaImageUrl(
        {
          currentDate,
          currentSensor,
          currentDates,
          currentSeason,
          field,
          colorSchema,
          currentSensorCompare,
          currentCompareDate,
        },
        isCompare
      ),
    [
      currentDate,
      currentSensor,
      currentDates,
      currentSeason,
      field,
      colorSchema,
      currentSensorCompare,
      currentCompareDate,
    ]
  );

  const selectCurrentFieldDefaultImageUrl = useCallback(
    (isCompare = false, fieldMD5?: string) =>
      selectTreeLayerImageUrl(isCompare, fieldMD5) ||
      selectPlantingAreaLayerImageUrl(isCompare) ||
      selectFieldLayerImageUrl(isCompare),
    [selectTreeLayerImageUrl, selectFieldLayerImageUrl, selectFieldLayerImageUrl]
  );

  return {
    selectTreeLayerImageUrl,
    selectFieldLayerImageUrl,
    selectPlantingAreaLayerImageUrl,
    selectCurrentFieldDefaultImageUrl,
  };
};
