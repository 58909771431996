import Server from '../server';
import type {AxiosPromise} from 'axios';

class FarmlogsApi {
  static getFields(): AxiosPromise<{data: {id: number; name: string}[]}> {
    return Server.get(`sync/farmlogs/fields`, {
      params: {__skipPreloader: true},
    });
  }
  static importFields(fieldIds: number[]): AxiosPromise<{data: {id: number; status: string}}> {
    return Server.post(
      `sync/farmlogs/import`,
      {field_ids: fieldIds},
      {
        params: {__skipPreloader: true},
      }
    );
  }

  static getImportStatus(jobId: number) {
    return Server.get(`sync/farmlogs/import/${jobId}`, {params: {__skipPreloader: true}});
  }
}

export default FarmlogsApi;
