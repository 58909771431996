import {FormattedMessage} from 'i18n-utils';
import React from 'react';
import {useDispatch} from 'react-redux';
import {setFeature} from 'containers/map/actions';
import {dialogToggle, DialogType} from 'modules/helpers';

const NoFields = () => {
  const dispatch = useDispatch();
  const handleClick = () => {
    dispatch(setFeature('farm'));
    dispatch(dialogToggle(DialogType.addNewField, true));
  };

  return (
    <FormattedMessage
      id="NoFields"
      defaultMessage="Please <span>create your fields</span> and crops first. Once set up, we can retrieve the satellite imagery and generate the map layers required to use this tool."
      values={{
        span: (txt: string) => (
          <span onClick={handleClick} className={'global-link'}>
            {txt}
          </span>
        ),
      }}
    />
  );
};
export default NoFields;
