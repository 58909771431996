import React, {useEffect} from 'react';
import {Marker, useMap} from 'react-leaflet';
import {defaultMarkerIcon} from '../icons';
import L from 'leaflet';
import {FoundLocationZoomLevel} from 'modules/add-fields';
import {useAppSelector} from '_hooks';
import {selectHasFarmsOrGroupsIds} from 'containers/login/login-selectors';

const LocationMarker = () => {
  const locationMarkerPosition = useAppSelector(s => s.map.locationMarkerPosition);
  const hasFarms = useAppSelector(selectHasFarmsOrGroupsIds);

  const leafletElement = useMap();

  useEffect(() => {
    if (
      (locationMarkerPosition[0] || locationMarkerPosition[1]) && // not [0,0]
      leafletElement?.getZoom() < FoundLocationZoomLevel &&
      hasFarms
    ) {
      leafletElement.setZoom(FoundLocationZoomLevel);
    }
  }, [locationMarkerPosition, hasFarms]);

  return leafletElement && locationMarkerPosition[0] && locationMarkerPosition[1] ? (
    <Marker
      ref={l => {
        if (l) {
          // @ts-expect-error error leftover from convertion to strict mode, please fix
          l.fluroId = 'location-marker';
        }
      }}
      position={L.latLng(locationMarkerPosition[0], locationMarkerPosition[1])}
      icon={defaultMarkerIcon}
    />
  ) : null;
};

export default LocationMarker;
