// @ts-nocheck
import {t} from 'i18n-utils';
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {Bar} from 'react-chartjs-2';
import {useDispatch, useSelector} from 'react-redux';
import moment from 'moment';
import type {RainData, GddData} from './temperature-chart-info';
import TemperatureChartInfo from './temperature-chart-info';
import Draggable from 'react-draggable';
import {formatDate, formatUnit, convertTemperatureModel, convertUnit, clamp} from '_utils';
import {Button, Portal, SelectField} from 'react-md';
import './index.scss';
import type {ChartDataSets} from 'chart.js';
import {GLOBAL_APP_DATE_FORMAT, GLOBAL_FORMAT_DATE} from '_constants';
import {toggleMapButton} from '../map/actions';
import type {AppStore} from '../../reducers';
import type {AnalyticsArablePoint} from '../map/types';
import {usePrevious} from '../../_hooks';
import {ArableIcon} from '../map/icons';
import cn from 'classnames';
import {CropInfo} from 'components';
import SimplePreloader from '../../components/simple-preloader';
import {AsyncStatusType} from '../../modules/helpers';

interface UpdatedChartDatasets extends Omit<ChartDataSets, 'data'> {
  data: (number | string | null)[];
  unitsType?: 'mm' | 'degrees';
}

type ChartData = {
  labels: (string | number)[];
  datasets: UpdatedChartDatasets[];
};

export type GrowthStageLabel = {
  maxWidth?: number | 'unset';
  model: {x: number; y: number};
  growingStage: string;
};

const drawSlider = (chart: any, x: any, txt?: string) => {
  const ctx = chart.chart.ctx;
  const chartArea = chart.chartArea;

  if (!isNaN(x)) {
    ctx.save();
    ctx.beginPath();
    ctx.lineWidth = 2;
    ctx.strokeStyle = chart.options.customLine.color;
    ctx.moveTo(x, chartArea.bottom);
    ctx.lineTo(x, chartArea.top);
    ctx.stroke();

    ctx.beginPath();
    ctx.moveTo(x - 10, chartArea.top);
    ctx.lineTo(x + 10, chartArea.top);
    ctx.lineTo(x, chartArea.top + 10);
    ctx.fillStyle = chart.options.customLine.color;
    ctx.fill();

    const text = txt ? txt : chart.options.customLine.getTooltipLabel();

    const textW = ctx.measureText(text).width;

    ctx.textAlign =
      x - chartArea.left < textW / 2
        ? 'left'
        : x + textW / 2 > chartArea.right
        ? 'right'
        : 'center';
    ctx.font = `normal 300 12px arial`;
    ctx.fillStyle = chart.options.customLine.color;
    ctx.fillText(text, x, 18);

    ctx.restore();
  }
};

const sliderPlugin = {
  beforeEvent: (chart: any, e: any) => {
    chart.options.customLine.eventType = e.type;

    if (e.type === 'mousemove' && e.x >= e.chart.chartArea.left && e.x <= e.chart.chartArea.right) {
      chart.options.customLine.x = e.x;
    }
  },

  afterDraw: (chart: any) => {
    if (
      chart.options.customLine.eventType === 'mouseout' ||
      chart.options.customLine.eventType === undefined
    ) {
      const date = chart.options.customLine.getObservationDate();

      const index = chart.data.labels.findIndex((l: string) => l === date);
      const x = chart.getDatasetMeta(0)?.data?.[index]?._model?.x;

      drawSlider(chart, x, date);
    } else {
      drawSlider(chart, chart.options.customLine.x);
    }
  },
};

const GrowthStageLabels = ({cropType, data}: {cropType: string; data: GrowthStageLabel[]}) =>
  data && data.length ? (
    <>
      {data.map((l, i) => (
        <div
          key={`gs-label-key-${i}`}
          title={l.growingStage}
          style={{
            left: l.model.x,
            maxWidth: l.maxWidth,
          }}
          className="gs-label"
        >
          <div className="gs-label__icon" />
          <div className="gs-label__label">{t({id: `[${cropType}] ${l.growingStage}`})}</div>
        </div>
      ))}
    </>
  ) : null;

const ChartLegend = ({
  data,
  selectedDataset,
  toggleDatasetVisibility,
}: {
  data: UpdatedChartDatasets[];
  selectedDataset: string;
  toggleDatasetVisibility: (label: string) => void;
}) => {
  return (
    <div className={'chart-legend'}>
      {data.map(({label, borderColor}) => {
        const disabled = selectedDataset && selectedDataset !== label;
        return (
          <div
            key={label}
            onClick={() => toggleDatasetVisibility(label)}
            className="chart-legend__item"
          >
            <div style={{backgroundColor: `${borderColor}`}} className={'icon'} />
            <span className={cn('label', {disabled})}>{label}</span>
          </div>
        );
      })}
    </div>
  );
};

export type WeatherSources = 'iteris' | 'arable';

const TemperatureChart = () => {
  const dispatch = useDispatch();
  const temperatureData =
    useSelector((store: AppStore) =>
      convertTemperatureModel(store.login.user.settings.measurement, store.map.temperatureData)
    ) || [];
  const cropType = useSelector((store: AppStore) => store.map.currentSeason.cropType);
  const measurement = useSelector((store: AppStore) => store.login.user.settings.measurement);
  const weather = useSelector((store: AppStore) => store.map.preparedTemperatureData);
  const currentSeason = useSelector((store: AppStore) => store.map.currentSeason);
  const arableData = useSelector((store: AppStore) => store.map.analytics.arableData);
  const currentDate = useSelector((store: AppStore) => store.map.currentDate);
  const viewportWidth = useSelector((store: AppStore) => store.viewport.width);
  const isSmallScreen = viewportWidth < 568;

  const [growthStageLabels, setGrowthStageLabels] = useState<GrowthStageLabel[]>([]);
  const [selectedSource, setSelectedSource] = useState<WeatherSources>('iteris');
  const [selectedDataset, setSelectedDataset] = useState('');
  const [observationDate, setObservationDate] = useState(
    moment(currentDate, GLOBAL_APP_DATE_FORMAT).format(formatDate())
  );

  const prev = usePrevious({arableData});

  useEffect(() => {
    if (!prev?.arableData?.length && arableData.length) {
      // select the arable by default source if there is device data
      setSelectedSource('arable');
    }
  }, [arableData]);

  useEffect(() => {
    setSelectedDataset('');
  }, [selectedSource]);

  const classifiedArableData: AnalyticsArablePoint[] = useMemo((): AnalyticsArablePoint[] => {
    // filters dates that are out of the season
    if (!arableData.length || !currentSeason.id) return [];
    const seasonStartDate = moment(currentSeason.startDate, GLOBAL_FORMAT_DATE);
    const seasonEndDate = moment(currentSeason.endDate, GLOBAL_FORMAT_DATE);

    return arableData
      .filter(date => {
        const momentDate = moment(date.time, GLOBAL_FORMAT_DATE);
        return (
          momentDate.isSameOrBefore(seasonEndDate) || !momentDate.isSameOrAfter(seasonStartDate)
        );
      })
      .map(date => ({
        ...date,
        meant: parseFloat(convertUnit(measurement, 'C', date.meant)),
        maxt: parseFloat(convertUnit(measurement, 'C', date.maxt)),
        mint: parseFloat(convertUnit(measurement, 'C', date.mint)),
        tdew: parseFloat(convertUnit(measurement, 'C', date.tdew)),
        cgdd: parseFloat(convertUnit(measurement, 'gdd', date.cgdd)),
        precip: parseFloat(convertUnit(measurement, 'mm', date.precip || 0)),
        et: parseFloat(convertUnit(measurement, 'mm', date.et)),
        etc: parseFloat(convertUnit(measurement, 'mm', date.etc)),
        crop_water_demand: parseFloat(convertUnit(measurement, 'mm', date.crop_water_demand)),
      }))
      .reverse();
  }, [arableData, currentSeason]);

  const weatherInfo = useMemo(() => {
    const resultData: RainData & GddData = {
      totalRain: 0,
      lastRain: 0,
      totalGdd: 0,
      lastGdd: 0,
      tBase: 0,
    };

    const observationDateFormatted = moment.utc(observationDate, formatDate()).format();

    if (selectedSource === 'iteris' && temperatureData.length) {
      const index = temperatureData.findIndex(item => item.date === observationDateFormatted);
      const lastDayIndex = index === -1 ? temperatureData.length - 1 : index;

      resultData.totalRain = temperatureData[lastDayIndex].TotalRain;
      resultData.totalGdd = temperatureData[lastDayIndex].dayDegrees;
      resultData.tBase = temperatureData[lastDayIndex].TBase;

      const lastSevenDays = temperatureData.slice(lastDayIndex - 7, lastDayIndex);

      lastSevenDays.forEach(day => {
        resultData.lastRain = resultData.lastRain + day.rainFall;
        resultData.lastGdd = // calc gdd manually
          resultData.lastGdd +
          clamp(0, (day.minTemperature + day.maxTemperature) / 2 - day.TBase, 10000); // prevent gdd < 0 because of negative min/max temperature
      });
    } else if (selectedSource === 'arable' && classifiedArableData.length) {
      const index = classifiedArableData.findIndex(
        item => moment.utc(item.time, GLOBAL_FORMAT_DATE).format() === observationDateFormatted
      );
      const lastDayIndex = index === -1 ? classifiedArableData.length - 1 : index;

      resultData.totalGdd = classifiedArableData[lastDayIndex].gdd_cumulative || 0;
      classifiedArableData.forEach(
        date => (resultData.totalRain = resultData.totalRain + date.precip)
      );
      const lastSevenDays = classifiedArableData.slice(lastDayIndex - 7, lastDayIndex);

      resultData.lastGdd = resultData.totalGdd - (lastSevenDays[0].gdd_cumulative || 0);
      lastSevenDays.forEach(day => {
        resultData.lastRain = resultData.lastRain + day.precip;
      });
    }

    return resultData;
  }, [classifiedArableData, temperatureData, selectedSource, observationDate]);

  const weatherSource = useMemo(() => {
    const sourcesLabels = {
      arable: (
        <div className="weather-popup__source">
          <ArableIcon className={'icon'} />
          <span className="text">Arable - {arableData[0]?.device}</span>
        </div>
      ),
      iteris: (
        <div className="weather-popup__source">
          <img className={'icon'} src={`/assets/DTN-logo.svg`} alt={'DTN-logo'} />
          <span className="text">
            ClearAg <sup>®</sup>
          </span>
        </div>
      ),
    };

    const availableSources: WeatherSources[] = [];

    arableData.length && availableSources.push('arable');
    temperatureData.length && availableSources.push('iteris');

    if (availableSources.length === 1) {
      return sourcesLabels[availableSources[0]];
    }

    if (availableSources.length > 1) {
      return (
        <SelectField
          id="select-weather-source"
          label=""
          menuItems={availableSources.map(source => ({
            label: sourcesLabels[source],
            value: source,
          }))}
          className="weather-popup__source-selector"
          value={selectedSource}
          onChange={(value: WeatherSources) => setSelectedSource(value)}
        />
      );
    }

    return null;
  }, [arableData, temperatureData, selectedSource]);

  // const drawGS = (
  //   ctx: any,
  //   point: any,
  //   chart: any,
  //   gsTitle: string,
  //   gsIndex: number,
  //   fSize?: number
  // ) => {
  //   ctx.save();
  //
  //   ctx.beginPath();
  //   ctx.moveTo(point.x, 5);
  //   ctx.strokeStyle = 'rgba(0, 0, 0, 0.6)';
  //   ctx.setLineDash([5, 5]);
  //   ctx.lineWidth = 1;
  //   ctx.lineTo(point.x, chart.scales['y-axis-0'].maxHeight - 25);
  //   ctx.stroke();
  //
  //   ctx.textAlign = 'center';
  //   ctx.font = `normal 500 ${fSize ? fSize : 14}px arial`;
  //   ctx.fillStyle = '#333';
  //   ctx.fillText(gsTitle, 2 + point.x + ctx.measureText(gsTitle).width / 2, gsIndex % 2 ? 40 : 70);
  //   ctx.restore();
  // };

  const drawUnits = (chart: any, text = '', position = 'left') => {
    const ctx = chart.chart.ctx;
    ctx.restore();

    ctx.save();

    ctx.textAlign = 'center';
    ctx.font = `normal 300 12px arial`;
    ctx.fillStyle = '#666';
    ctx.fillText(
      text,
      position === 'left'
        ? ctx.measureText(text).width
        : chart.width - ctx.measureText(text).width / 2,
      10
    );
    // ctx.restore();
  };

  const beforeDraw = (chart: any) => {
    drawUnits(chart, `(°${formatUnit(measurement, 'C')})`, 'left');
    drawUnits(chart, `(${formatUnit(measurement, 'mm')})`, 'right');
    //     const ctx = chart.chart.ctx;
    //     const growthLabels = Object.keys(weather).find(d => weather[d].GrowingStage);
    //
    //     if (chart.config.options.shouldShowGSLabels && growthLabels) {
    //       const line = chart.getDatasetMeta(1);
    //
    //       if (line.type === 'line') {
    //         let alreadyAddedGSLabels: any[] = [];
    //         let growthLabels: any[] = [];
    //         chart.config.data.labels.forEach((labelDate: string, i: number) => {
    //           const formatedDateKey = moment(labelDate, formatDate()).format('YYYY-MM-DD');
    //
    //           if (
    //             weather[formatedDateKey] &&
    //             !alreadyAddedGSLabels.includes(weather[formatedDateKey].GrowingStage) &&
    //             weather[formatedDateKey].GrowingStage
    //           ) {
    //             if (line.data[i]) {
    //               try {
    //                 growthLabels.push({
    //                   model: line.data[i].getCenterPoint(),
    //                   weather: weather[formatedDateKey],
    //                 });
    //
    //                 alreadyAddedGSLabels.push(weather[formatedDateKey].GrowingStage);
    //               } catch (e) {
    //               }
    //             }
    //           }
    //         });
    // growthLabels.forEach(lbl => {
    //   drawGS(ctx, lbl.model, chart, '', lbl.index);
    // });
    //       }
    //     }
  };

  const beforeRender = (chart: any) => {
    if (!chart.data.datasets.length) return; // all datasets are filtered

    const prevChartMaxValue = chart.config.options.scales.yAxes[1].ticks.max;
    // Kelly: just the rain scale to change : if max rainfall > Tmax/2, change the rain scale to : max =max rainfall
    const max = Math.round(Math.max(...chart.data.datasets[0].data)) || 1;
    chart.config.options.scales.yAxes[1].ticks.max = max;

    if (measurement === 'ha' && max < chart.scales['y-axis-0'].max / 2) {
      chart.config.options.scales.yAxes[1].ticks.max = Math.floor(chart.scales['y-axis-0'].max / 2);
    }

    if (prevChartMaxValue !== chart.config.options.scales.yAxes[1].ticks.max) {
      chart.update(); // update the chart in case the values are different.
      // it prevents a bug when we changed the data source but the chart max mm value is old
    }
  };

  const afterRender = (chart: any) => {
    const growthLabels = Object.keys(weather).find(d => weather[d].GrowingStage);
    !growthLabels && growthStageLabels.length && setGrowthStageLabels([]);

    try {
      //@ts-expect-error error leftover from convertion to strict mode, please fix
      if (chart.config.options.shouldShowGSLabels && growthLabels) {
        const line = chart.getDatasetMeta(1);
        const alreadyAddedGSLabels: string[] = [];
        let growthLabels: GrowthStageLabel[] = [];
        chart.config.data.labels.forEach((labelDate: string, i: number) => {
          const formattedDateKey = moment(labelDate, formatDate()).format('YYYY-MM-DD');

          if (
            line.type === 'line' &&
            weather[formattedDateKey] &&
            !alreadyAddedGSLabels.includes(weather[formattedDateKey].GrowingStage) &&
            weather[formattedDateKey].GrowingStage
          ) {
            if (line.data[i]) {
              try {
                growthLabels.push({
                  model: line.data[i].getCenterPoint(),
                  growingStage: weather[formattedDateKey].GrowingStage,
                });

                alreadyAddedGSLabels.push(weather[formattedDateKey].GrowingStage);
              } catch (e) {}
            }
          }
        });

        growthLabels = growthLabels.map((gsc, i) => {
          if (i < growthLabels.length - 1) {
            gsc.maxWidth = growthLabels[i + 1].model.x - gsc.model.x;
          } else {
            gsc.maxWidth = 'unset';
          }

          return gsc;
        });

        if (
          growthLabels.length !== growthStageLabels.length ||
          growthLabels.some(
            (item, index) =>
              item.maxWidth !== growthStageLabels[index].maxWidth ||
              item.growingStage !== growthStageLabels[index].growingStage
          )
        ) {
          setGrowthStageLabels([...growthLabels]);
        }
      }
    } catch (e) {}
  };

  const calcMonthLabels = (dates: string[], months: string[]) => {
    // this is custom func that allows to gracefully spread month below x-axis
    const monthDelimiter = Math.round(
      dates.length / months.length + dates.length / months.length / months.length
    );
    return months.reduce(
      (result, month, index) => ({
        ...result,
        [index * monthDelimiter]: month,
      }),
      {} as {[key: number]: string}
    );
  };

  const onToggleDatasetVisibility = (label: string) => {
    setSelectedDataset(label === selectedDataset ? '' : label);
  };

  const onClickChartPoint = useCallback(function () {
    try {
      const selectedDate = this.chart.options.customLine.getTooltipLabel();

      setObservationDate(selectedDate);
    } catch (e) {}
  }, []);

  const getChart = () => {
    const arrayMonths: string[] = [];
    const dates: string[] = [];
    const avgTemperature: string[] = [];
    const minTemperature: string[] = [];
    const maxTemperature: string[] = [];
    const rainData: string[] = [];
    const dayDegrees: string[] = [];
    const cropWaterDemand: string[] = [];
    const et: string[] = [];
    const etc: string[] = [];
    const kc: string[] = [];
    const cl: string[] = [];
    const tdew: string[] = [];

    if (selectedSource === 'arable') {
      classifiedArableData.forEach(date => {
        const currentLoopDateMonth = moment(date.time, GLOBAL_FORMAT_DATE).format('MMM');

        if (!arrayMonths.includes(currentLoopDateMonth)) arrayMonths.push(currentLoopDateMonth);

        dates.push(moment(date.time, GLOBAL_FORMAT_DATE).format(formatDate())); // date
        avgTemperature.push(date.meant.toFixed(1));
        maxTemperature.push(date.maxt.toFixed(1));
        minTemperature.push(date.mint.toFixed(1));
        rainData.push(date.precip && date.precip !== 0 ? date.precip.toFixed(1) : '0');
        dayDegrees.push(
          date.gdd_cumulative && date.gdd_cumulative !== 0 ? date.gdd_cumulative.toFixed() : null
        );
        cropWaterDemand.push(date.crop_water_demand.toFixed(1));
        et.push(date.et.toFixed(1));
        etc.push(date.etc.toFixed(1));
        kc.push(date.kc?.toFixed(1) || null);
        cl.push(date.cl?.toFixed(1) || null);
        tdew.push(date.tdew.toFixed(1));
      });
    } else {
      temperatureData.forEach(date => {
        const currentLoopDateMonth = moment(date.date).format('MMM');

        if (!arrayMonths.includes(currentLoopDateMonth)) arrayMonths.push(currentLoopDateMonth);

        dates.push(moment(date.date).format(formatDate())); // date
        avgTemperature.push(date.AvgTemp.toFixed(1));
        maxTemperature.push(date.maxTemperature.toFixed(1));
        minTemperature.push(date.minTemperature.toFixed(1));
        rainData.push(date.rainFall.toFixed(1));
        dayDegrees.push(date.dayDegrees.toFixed());
      });
    }

    const data: ChartData = {
      labels: dates,
      datasets: [
        {
          type: 'bar',
          unitsType: 'mm',
          label: t({id: 'Precipitation'}),
          data: rainData,
          fill: false,
          borderColor: '#41A4F5',
          hoverBackgroundColor: '#41A4F5',
          hoverBorderColor: '#41A4F5',
          backgroundColor: '#41A4F5',
          borderWidth: 2,
          pointBorderWidth: 2,
          yAxisID: 'y-axis-1',
        },
        {
          type: 'line',
          unitsType: 'degrees',
          label: t({id: 'Avg. temperature'}),
          lineTension: 0.4,
          borderWidth: 1,
          borderColor: '#7A7A7A',
          borderCapStyle: 'butt',
          borderDashOffset: 0.0,
          pointBorderWidth: 1,
          pointHoverRadius: 1,
          pointHoverBackgroundColor: '#7A7A7A',
          pointHoverBorderColor: '#7A7A7A',
          backgroundColor: '#7A7A7A',
          pointHoverBorderWidth: 2,
          pointRadius: 0,
          pointHitRadius: 10,
          data: avgTemperature,
          fill: false,
        },
        {
          type: 'line',
          unitsType: 'degrees',
          label: t({id: 'Air max. temperature'}),
          lineTension: 0.4,
          borderWidth: 1,
          borderColor: '#D32F2F',
          borderCapStyle: 'butt',
          borderDashOffset: 0.0,
          pointBorderWidth: 1,
          pointHoverRadius: 1,
          pointHoverBackgroundColor: '#D32F2F',
          pointHoverBorderColor: '#D32F2F',
          pointHoverBorderWidth: 2,
          backgroundColor: '#D32F2F',
          pointRadius: 0,
          pointHitRadius: 10,
          fill: false,
          data: maxTemperature,
        },
        {
          type: 'line',
          unitsType: 'degrees',
          label: t({id: 'Air min. temperature'}),
          lineTension: 0.4,
          borderWidth: 1,
          borderColor: '#CCE7FF',
          borderCapStyle: 'butt',
          borderDashOffset: 0.0,
          pointBorderWidth: 1,
          pointHoverRadius: 1,
          pointHoverBackgroundColor: '#CCE7FF',
          pointHoverBorderColor: '#CCE7FF',
          pointHoverBorderWidth: 2,
          backgroundColor: '#CCE7FF',
          pointRadius: 0,
          pointHitRadius: 10,
          fill: false,
          data: minTemperature,
        },
      ],
    };

    if (cropWaterDemand.length) {
      data.datasets.push({
        type: 'line',
        unitsType: 'mm',
        label: t({id: 'Crop water demand'}),
        lineTension: 0.4,
        borderWidth: 1,
        borderColor: '#E7D000',
        borderCapStyle: 'butt',
        borderDashOffset: 0.0,
        pointBorderWidth: 1,
        pointHoverRadius: 1,
        pointHoverBackgroundColor: '#E7D000',
        pointHoverBorderColor: '#E7D000',
        pointHoverBorderWidth: 2,
        backgroundColor: '#E7D000',
        pointRadius: 0,
        pointHitRadius: 10,
        data: cropWaterDemand,
        fill: false,
        yAxisID: 'y-axis-1',
      });
    }
    if (et.length) {
      data.datasets.push({
        type: 'line',
        unitsType: 'mm',
        label: t({id: 'et0'}),
        lineTension: 0.4,
        borderWidth: 1,
        borderColor: '#12753D',
        borderCapStyle: 'butt',
        borderDashOffset: 0.0,
        pointBorderWidth: 1,
        pointHoverRadius: 1,
        pointHoverBackgroundColor: '#12753D',
        pointHoverBorderColor: '#12753D',
        pointHoverBorderWidth: 2,
        backgroundColor: '#12753D',
        pointRadius: 0,
        pointHitRadius: 10,
        data: et,
        fill: false,
        yAxisID: 'y-axis-1',
      });
    }
    if (etc.length) {
      data.datasets.push({
        type: 'line',
        unitsType: 'mm',
        label: t({id: 'etc'}),
        lineTension: 0.4,
        borderWidth: 1,
        borderColor: '#00FFB2',
        borderCapStyle: 'butt',
        borderDashOffset: 0.0,
        pointBorderWidth: 1,
        pointHoverRadius: 1,
        pointHoverBackgroundColor: '#00FFB2',
        pointHoverBorderColor: '#00FFB2',
        pointHoverBorderWidth: 2,
        backgroundColor: '#00FFB2',
        pointRadius: 0,
        pointHitRadius: 10,
        data: etc,
        fill: false,
        yAxisID: 'y-axis-1',
      });
    }

    if (tdew.length) {
      data.datasets.push({
        type: 'line',
        unitsType: 'degrees',
        label: t({id: 'tdew'}),
        lineTension: 0.4,
        borderWidth: 1,
        borderColor: '#9900FF',
        borderCapStyle: 'butt',
        borderDashOffset: 0.0,
        pointBorderWidth: 1,
        pointHoverRadius: 1,
        pointHoverBackgroundColor: '#9900FF',
        pointHoverBorderColor: '#9900FF',
        pointHoverBorderWidth: 2,
        backgroundColor: '#9900FF',
        pointRadius: 0,
        pointHitRadius: 10,
        data: tdew,
        fill: false,
        yAxisID: 'y-axis-1',
      });
    }

    if (cl.length) {
      data.datasets.push({
        type: 'line',
        label: t({id: 'cl (Chlorophyll index)'}),
        lineTension: 0.4,
        borderWidth: 1,
        borderColor: '#B49FDA',
        borderCapStyle: 'butt',
        borderDashOffset: 0.0,
        pointBorderWidth: 1,
        pointHoverRadius: 1,
        pointHoverBackgroundColor: '#B49FDA',
        pointHoverBorderColor: '#B49FDA',
        pointHoverBorderWidth: 2,
        backgroundColor: '#B49FDA',
        pointRadius: 0,
        pointHitRadius: 10,
        data: cl,
        fill: false,
        yAxisID: 'y-axis-1',
      });
    }

    if (kc.length) {
      data.datasets.push({
        type: 'line',
        label: t({id: 'kc'}),
        lineTension: 0.4,
        borderWidth: 1,
        borderColor: '#FC40A4',
        borderCapStyle: 'butt',
        borderDashOffset: 0.0,
        pointBorderWidth: 1,
        pointHoverRadius: 1,
        pointHoverBackgroundColor: '#FC40A4',
        pointHoverBorderColor: '#FC40A4',
        pointHoverBorderWidth: 2,
        backgroundColor: '#FC40A4',
        pointRadius: 0,
        pointHitRadius: 10,
        data: kc,
        fill: false,
        yAxisID: 'y-axis-1',
      });
    }

    const monthLabels = calcMonthLabels(dates, arrayMonths);

    const options = {
      layout: {
        padding: {
          left: 0,
          right: 0,
          bottom: 0,
          top: 30,
        },
      },
      shouldShowGSLabels: selectedSource === 'iteris',
      onClick: onClickChartPoint,
      tooltips: {
        mode: 'index',
        position: 'nearest',
        intersect: false,
        callbacks: {
          title(item: Chart.ChartTooltipItem[]): string | string[] {
            // TODO: find better way pass label to slider stick title
            this._chart.options.customLine.getTooltipLabel = () => item?.[0]?.label;

            return item?.[0]?.label;
          },
          label: (tooltipItem: any, data: any) => {
            const label = data.datasets[tooltipItem.datasetIndex].label;
            const datasetUnitsType = data.datasets[tooltipItem.datasetIndex].unitsType;

            if (datasetUnitsType === 'mm') {
              // handle different value type labels
              return `${label}: ${tooltipItem.yLabel} ${formatUnit(measurement, 'mm')}`;
            } else if (datasetUnitsType === 'degrees') {
              return `${label}: ${tooltipItem.yLabel} °${formatUnit(measurement, 'C')}`;
            }
            return `${label}: ${tooltipItem.yLabel}`;
          },
          afterBody: (tooltipItem: any[]) => {
            const {index} = tooltipItem[0];
            if (dayDegrees[index] !== null) {
              return `• ${t({id: 'GDD since sowing'})}: ${dayDegrees[index]}`;
            }
          },
        },
        // for a custom tooltip  https://www.chartjs.org/docs/latest/configuration/tooltip.html
      },
      customLine: {
        color: 'green',
        // getTooltipLabel: () => '',
        getObservationDate: () => observationDate,
      },

      legend: {
        display: false,
      },
      scales: {
        xAxes: [
          {
            ticks: {
              maxRotation: 0,
              autoSkip: false,
              callback: (label: string, index: number) => monthLabels[index] || '',
            },
            gridLines: {
              display: false,
            },
          },
        ],

        // do not change order y axes in the below array!
        yAxes: [
          {
            id: 'y-axis-0',
            type: 'linear',
            position: 'left',
            ticks: {
              maxTicksLimit: 6,
              padding: 5,
            },
            gridLines: {
              drawBorder: false, // draw the vertical line near ticks
              drawTicks: false, // additional dash between tick and actual tick line
            },
          },
          {
            id: 'y-axis-1',
            type: 'linear',
            position: 'right',
            gridLines: {
              display: false,
            },
            ticks: {
              min: 0,
              maxTicksLimit: 5,
            },
          },
        ],
      },
    };

    const filteredDatasets = data.datasets.filter(
      ({label}) => !selectedDataset || selectedDataset === label
    );

    return (
      <div
        className={cn('temperature-chart-inline', {
          'has-growth-stages': selectedSource === 'iteris' && growthStageLabels.length,
        })}
      >
        {/*
        //@ts-expect-error error leftover from convertion to strict mode, please fix*/}
        <Bar
          id={`temp-bar-chart=${selectedSource}`}
          data={{labels: data.labels, datasets: filteredDatasets}}
          width={600}
          height={300}
          options={options}
          plugins={[{beforeDraw}, {afterRender}, {beforeRender}, sliderPlugin]}
        />

        <ChartLegend
          data={data.datasets}
          selectedDataset={selectedDataset}
          toggleDatasetVisibility={onToggleDatasetVisibility}
        />

        {selectedSource === 'iteris' && Object.keys(weather) ? (
          <GrowthStageLabels cropType={cropType} data={growthStageLabels} />
        ) : null}
      </div>
    );
  };

  return (
    <Portal visible>
      <Draggable
        handle=".weather-popup__title"
        defaultPosition={{x: isSmallScreen ? -40 : 0, y: isSmallScreen ? 30 : 0}}
        position={null}
        grid={[25, 25]}
      >
        <div className={`temperature-chart-container`}>
          <div className="weather-popup__header">
            <h3 className={'pop-up-title weather-popup__title'}>
              {/*{isCotton ? t({id: 'Weather and growth stages'}) : t({id: 'Weather'})}*/}
              {t(
                {id: 'Weather on {observationDate}'},
                {
                  observationDate,
                }
              )}
              <SimplePreloader statusKeys={[AsyncStatusType.loadFieldWeather]} />
            </h3>
            {temperatureData.length || arableData.length ? (
              <div className="weather-popup__dates-and-source">
                <CropInfo
                  cropType={currentSeason.cropType}
                  cropSubType={currentSeason.params?.cropSubType}
                  startDate={moment(currentSeason.startDate, GLOBAL_FORMAT_DATE).format('MMM YYYY')}
                  endDate={moment(currentSeason.endDate, GLOBAL_FORMAT_DATE).format('MMM YYYY')}
                />
                {weatherSource}
              </div>
            ) : null}

            <Button
              className={'close-dialog-button'}
              icon
              onClick={() => dispatch(toggleMapButton('Empty'))}
            >
              close
            </Button>
          </div>

          <div className="weather-popup__info" />

          <div className="content">
            {temperatureData.length ? (
              <>
                <TemperatureChartInfo
                  source={selectedSource}
                  totalRain={weatherInfo.totalRain}
                  lastRain={weatherInfo.lastRain}
                  totalGdd={weatherInfo.totalGdd}
                  lastGdd={weatherInfo.lastGdd}
                  tBase={weatherInfo.tBase}
                  measurement={measurement}
                />
                {getChart()}
              </>
            ) : (
              <h4 className={'no-weather-data'}>{t({id: 'No weather data for this season'})}</h4>
            )}
          </div>
        </div>
      </Draggable>
    </Portal>
  );
};

export default TemperatureChart;
