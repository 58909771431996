import styled from 'styled-components';
export const LanguageLoginWrapper = styled.div`
  position: absolute;
  right: 20px;
  top: 20px;
  display: flex;
  justify-content: center;
  z-index: 1;
`;

export const Wrapper = styled.div`
  background-color: rgba(255, 255, 255, 0.7);
  border-radius: 4px;
  padding: 0 15px;
  min-width: 130px;
  display: flex;
  align-items: center;

  .md-text,
  .md-icon {
    color: #167a69;
    font-weight: 500;
  }

  .md-text-field--margin {
    margin-top: 0;
  }

  border: 2px solid #167a69;

  .md-divider {
    display: none;
  }

  .iti-flag {
    display: none;
  }

  .lang-icon {
    display: inline-block;
  }

  .md-list-item {
    .lang-icon {
      display: none;
    }

    .lang-menu-item {
      .iti-flag {
        display: block;
      }
    }
  }
`;

export const Flag = styled.i`
  width: 20px;
  height: 11px;
  display: block;
  background-image: url(/static/media/flags.5c58d0e1.png);
`;
