import React from 'react';
import {t} from 'i18n-utils';
import {NewFieldError} from '../types';
import {InfoBlock} from 'components';
import {FormattedMessage} from 'react-intl';
import {ProtectedFieldAreaWarning} from 'components/protected-field-area-helpers';
import {useAppSelector} from '_hooks';
import {selectFieldSizeLimit, selectIsAdmin} from 'containers/login/login-selectors';

const NewFieldProtectedAreaError = () => (
  <ProtectedFieldAreaWarning className={'info-block-wrapper'} />
);

const NewFieldSizeError = () => {
  const fieldSizeLimit = useAppSelector(selectFieldSizeLimit);
  const isAdmin = useAppSelector(selectIsAdmin);

  return isAdmin ? (
    <InfoBlock className="info-block-wrapper" mini appearance="warning" color="warning">
      <FormattedMessage
        id="admin field size warning"
        values={{
          t: (txt: string) => <span className="font-weight--medium">{txt}</span>,
          fieldSizeLimit,
        }}
        defaultMessage="<t>Field size</t> is > {fieldSizeLimit}. Verify before uploading."
      />
    </InfoBlock>
  ) : (
    <InfoBlock className="info-block-wrapper" mini appearance="error" color="error">
      <FormattedMessage
        id="field size error"
        values={{
          t: (txt: string) => <span className="font-weight--medium">{txt}</span>,
          fieldSizeLimit,
        }}
        defaultMessage="<t>Field size</t> is > {fieldSizeLimit}. This field won't be uploaded."
      />
    </InfoBlock>
  );
};

export const newFieldErrorMessages = {
  [NewFieldError.FieldNameError]: () =>
    t({id: 'Field name must be range characters'}, {range: '1-50'}),
  [NewFieldError.FieldFarmNameError]: () => t({id: 'No farm selected'}),
  [NewFieldError.FieldSizeError]: () => <NewFieldSizeError />,
  [NewFieldError.FieldProtectedAreaError]: () => <NewFieldProtectedAreaError />,
};
