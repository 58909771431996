import type {ComponentType} from 'react';
import React from 'react';
import {SelectionControlGroup} from 'react-md';
import {selectReportDataUnits} from 'containers/si/module/selectors';
import {useAppDispatch, useAppSelector} from '_hooks';
import {setDashboardDataUnits} from 'containers/si/module/reducer';
import type {SIAreaUnits} from 'modules/sustainability-insights/types';

export const UnitSelect: ComponentType = () => {
  const dispatch = useAppDispatch();
  const dataUnits = useAppSelector(selectReportDataUnits);

  return (
    <SelectionControlGroup
      id="dashboard-unit-type-select"
      name="radio"
      type="radio"
      className="si-toggle-group si-toggle-group--inline select-units"
      inline
      value={dataUnits}
      onChange={v => dispatch(setDashboardDataUnits(v as SIAreaUnits))}
      controls={[
        {
          label: 'Percentage (%)',
          value: 'pct',
        },
        {
          label: 'Number (#)',
          value: 'num',
        },
      ]}
    />
  );
};
