import {t, FormattedMessage} from 'i18n-utils';
import React, {useMemo, useState} from 'react';
import {
  FluroButton,
  FluroDataTable,
  FluroTableBody,
  FluroTableColumn,
  FluroTableHeader,
  FluroTableRow,
  InfoBlock,
  UploadFileZone,
} from 'components';
import {downloadFile} from '_utils/pure-utils';
import Papa from 'papaparse';
import moment from 'moment';
import {GLOBAL_APP_DATE_FORMAT, GLOBAL_FORMAT_DATE} from '_constants';
import SeasonRow from './season-row';
import {saveBulkCropAndSync} from 'containers/map/actions';
import {showNotification} from 'components/notification/notification';
import {useAppDispatch, useAppSelector} from '_hooks';
import {
  selectCurrentFarm,
  selectMapFields,
  selectMapSelectedFields,
} from 'containers/map/reducer/selectors';
import {selectCropTypesList} from 'modules/global/selectors';
import {dialogToggle, DialogType} from 'modules/helpers';

export type ImportSeason = {
  farm_id: number;
  farm_name: string;
  field_id: number;
  field_name: string;
  crop_type: string;
  variety: string;
  sowing_date: string;
  harvest_date: string;
  harvest_date_error?: boolean;
  uniqId: number;
};

const getResultDate = (date = '') => {
  const dateMoment = moment(date);
  return (
    moment(date, GLOBAL_APP_DATE_FORMAT).isValid() // try using DD/MM/YYYY format to get the correct date
      ? moment(date, GLOBAL_APP_DATE_FORMAT)
      : dateMoment.isValid()
      ? dateMoment
      : moment()
  ).format(GLOBAL_FORMAT_DATE);
};

const isHarvestDateError = (sowingDate: string, harvestDate: string) => {
  const momentSowingDate = moment(sowingDate, GLOBAL_FORMAT_DATE);
  const momentHarvestDate = moment(harvestDate, GLOBAL_FORMAT_DATE);
  return !(
    momentHarvestDate.isAfter(momentSowingDate) &&
    momentHarvestDate.diff(momentSowingDate, 'days') > 30
  );
};

const PreparedTableHeader = () => (
  <FluroTableHeader>
    <FluroTableRow>
      <FluroTableColumn>
        <FormattedMessage id="Field" />
      </FluroTableColumn>
      <FluroTableColumn>
        <FormattedMessage id="Farm" />
      </FluroTableColumn>
      <FluroTableColumn>
        <FormattedMessage id="Crop type" />
      </FluroTableColumn>
      <FluroTableColumn>
        <FormattedMessage id="Variety" />
      </FluroTableColumn>
      <FluroTableColumn type={'date'}>
        <FormattedMessage id="Sowing date" />
      </FluroTableColumn>
      <FluroTableColumn type={'date'}>
        <FormattedMessage id="Harvest date" />
      </FluroTableColumn>
      <FluroTableColumn />
    </FluroTableRow>
  </FluroTableHeader>
);

const ImportSeasonsForm = () => {
  const [seasonsData, setSeasonsData] = useState<ImportSeason[]>([]);
  const dispatch = useAppDispatch();
  const selectedFields = useAppSelector(selectMapSelectedFields);
  const currentFarm = useAppSelector(selectCurrentFarm);
  const fields = useAppSelector(selectMapFields);
  const cropTypes = useAppSelector(selectCropTypesList);
  const tableHeaders = `farm_id,farm_name,field_id,field_name,crop_type,variety,sowing_date,harvest_date`;

  const onSetFile = (file: File) => {
    Papa.parse(file, {
      skipEmptyLines: true,
      header: true,
      complete: ({data}: {data: ImportSeason[]}) => {
        const formattedData = data
          .filter(
            // filter empty seasons
            ({sowing_date, harvest_date, crop_type, variety}) =>
              sowing_date || harvest_date || crop_type || variety
          )
          .map((season, index) => {
            season.uniqId = index;
            season.field_id = parseInt(`${season.field_id}`);
            season.sowing_date = getResultDate(season.sowing_date);
            season.harvest_date = getResultDate(season.harvest_date);
            season.harvest_date_error = isHarvestDateError(season.sowing_date, season.harvest_date);
            return season;
          });
        if (!formattedData.length) {
          showNotification({
            title: t({id: 'note.warning', defaultMessage: 'Warning'}),
            type: 'warning',
            message: t({
              id: 'This file does not have data, please make sure you fill in at least one of following columns: crop_type, variety, sowing_date, or harvest_date.',
            }),
          });
        }
        setSeasonsData(formattedData);
      },
    });
  };

  const onImport = () => {
    const allFieldsToSetCropTo = [...new Set(seasonsData.map(season => season.field_id))];
    // format the values to match saveBulkCropAndSync() cropData structure
    const preparedData = allFieldsToSetCropTo.map(field_id => {
      const currentFieldSeasons = seasonsData.filter(season => season.field_id === field_id);
      return {
        kml_id: field_id,
        name: currentFieldSeasons[0].field_name,
        seasons: currentFieldSeasons.map(({crop_type, sowing_date, harvest_date, variety}) => ({
          id: null, // if this one is null back-end with create a new season
          cropType: crop_type,
          startDate: sowing_date,
          endDate: harvest_date,
          params: {cropSubType: variety, isCustomCropSubType: true},
        })),
      };
    });
    dispatch(saveBulkCropAndSync(currentFarm.id, preparedData));
    dispatch(dialogToggle(DialogType.importSeasons, true));
  };

  const onChangeSeasonProp = (id: number, prop: keyof ImportSeason, value: string | number) => {
    setSeasonsData(
      seasonsData.map(season => {
        if (season.uniqId === id) {
          season = {...season, [prop]: value};

          if (prop === 'sowing_date' || prop === 'harvest_date') {
            season.harvest_date_error = isHarvestDateError(season.sowing_date, season.harvest_date);
          }
        }

        return season;
      })
    );
  };

  const onDeleteSeason = (id: number) => {
    if (window.confirm(t({id: 'Are you sure?'}))) {
      return setSeasonsData(seasonsData.filter(season => season.uniqId !== id));
    }
  };

  const generateTemplate = () => {
    let bodyItems = [];
    if (fields.length) {
      bodyItems = selectedFields.length
        ? selectedFields.map(field => [
            `${currentFarm.id},${currentFarm.name},${field.ID},${field.Name},`,
          ])
        : fields.map(field => [`${currentFarm.id},${currentFarm.name},${field.ID},${field.Name},`]);
    } else {
      bodyItems = [`${currentFarm.id},${currentFarm.name},`];
    }

    let result = [tableHeaders, ...bodyItems].join('\n');

    downloadFile(result, 'import-seasons-template.csv', 'text/csv');
  };

  const foundErrors = useMemo(
    () => seasonsData.filter(season => season.harvest_date_error),
    [seasonsData]
  );

  if (!seasonsData.length)
    return (
      <div className={'upload-seasons'}>
        <InfoBlock className={'inside-a-pop-up'}>
          <FormattedMessage
            id="You can only import crops for one farm at the time."
            values={{
              name: <i>{currentFarm.name}</i>,
              span: (txt: string) => (
                <span onClick={generateTemplate} className={'global-link'}>
                  &nbsp;{txt}&nbsp;
                </span>
              ),
            }}
          />
          <br />
          <FormattedMessage id="Upload season date formats" />
        </InfoBlock>
        <UploadFileZone id={'upload-season'} accept={'.csv'} onChange={f => onSetFile(f as File)} />
      </div>
    );

  return (
    <div>
      <FluroDataTable className={'import-seasons-table'}>
        <PreparedTableHeader />
        <FluroTableBody>
          {seasonsData.map(season => (
            <SeasonRow
              key={season.uniqId}
              onChange={onChangeSeasonProp}
              onDelete={onDeleteSeason}
              cropTypes={cropTypes}
              season={season}
            />
          ))}
        </FluroTableBody>
      </FluroDataTable>

      <div className={'footer-part'}>
        {!!foundErrors.length && (
          <div className={'errors-counter'}>
            <FormattedMessage
              id="Please, correct before importing."
              defaultMessage="{count} {count, plural, one {Error} other {Errors}} found. Please, correct before importing."
              values={{count: foundErrors.length}}
            />
          </div>
        )}

        <FluroButton raised disabled={!!foundErrors.length} primary onClick={onImport}>
          <FormattedMessage id="Import" />
        </FluroButton>
      </div>
    </div>
  );
};

export default ImportSeasonsForm;
