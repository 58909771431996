import {Flex, Text} from 'components';
import {CardBase} from 'components/card/card-base';
import {setFilter} from 'modules/sustainability-insights/actions';
import {selectIsSIDataLoading} from 'modules/sustainability-insights/selectors';
import {SIGeometryFilterType} from 'modules/sustainability-insights/types';
import type {ComponentType} from 'react';
import React from 'react';
import {useAppDispatch, useAppSelector} from '_hooks';
import {YearsFilterRangeLabel} from 'containers/map/features/sustainability-insights/years-filter-range-label';
import {YieldSummaryChart} from './yield-summary-chart';
import {CardToolTip} from '../components/card-tooltip';
import {LearnMore} from '../components/learn-more';
import {TOOLTIPS} from 'containers/si/constants';

type Props = {
  isActive?: boolean;
};

export const YieldCard: ComponentType<Props> = ({isActive = false}) => {
  const dispatch = useAppDispatch();
  const isLoading = useAppSelector(selectIsSIDataLoading);

  const onFilterClick = () => {
    if (isActive || isLoading) return;
    dispatch(
      setFilter({
        activeGeometryType: SIGeometryFilterType.Yield,
      })
    );
  };

  return (
    <CardBase
      active={isActive}
      className={'sustainability-card yield-card'}
      heading={
        <Flex nowrap justifyContent="space-between">
          <Text className="m-0" elementType="h3">
            Yield <YearsFilterRangeLabel />
          </Text>
          <CardToolTip id="yield-info" place="left" content={TOOLTIPS.KPI.yield} />
        </Flex>
      }
      onClick={onFilterClick}
    >
      {isActive && (
        <div>
          <YieldSummaryChart />
          <LearnMore articleId={6479120} />
        </div>
      )}
    </CardBase>
  );
};
