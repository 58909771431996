import type {MRVEntityType, MRVFarm, MRVField, MRVMob} from 'containers/mrv/types';
import {MRVAttributeType} from 'containers/mrv/types';

export const tableHeaderColWidth: {[key in MRVAttributeType]?: string} = {
  [MRVAttributeType.SummerDryYield]: '120px',
  [MRVAttributeType.WinterDryYield]: '120px',
  [MRVAttributeType.RecordYear]: '50px',
};

export const isFarm = (
  entityType: MRVEntityType,
  entity: MRVFarm | MRVField | MRVMob
): entity is MRVFarm => entityType === 'farm';

export const isMob = (
  entityType: MRVEntityType,
  entity: MRVFarm | MRVField | MRVMob
): entity is MRVMob => entityType === 'mob';

export const isField = (
  entityType: MRVEntityType,
  entity: MRVFarm | MRVField | MRVMob
): entity is MRVField => entityType === 'field';
