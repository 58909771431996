import type {FluroSelectLiteItem} from 'components/fluro-select-lite/fluro-select-lite';
import type {AppearanceValues} from 'components/info-block/types';
import type {DateStringWithNoTZ, Override} from 'types';
import type {ExternalService} from '../map/types';
import type {LatLngLiteral} from 'leaflet';
import type {Locales} from 'i18n-utils';
import {t} from 'i18n-utils';
import type {PaymentFor} from '_api/mrv.types';

export enum MRVProgramUnits {
  IMPERIAL = 'US_IMPERIAL',
  METRIC = 'METRIC',
}
// Program = Config
export type MRVProgram = {
  id: number;
  name: string;
  description: string;
  version: number;
  editable: boolean;
  crediting_year: number; // i.e. 2020
  currency_char?: string;
  currency_code?: string;
  owner_org: number;
  permissions: ProgramAdminUser[];
  assets: string[];
  practice_changes: Practice[];
  carbon_price: number;
  phases: MRVPhase[];
  locale: Locales;
  units: MRVProgramUnits;
  is_optis_enabled: boolean;

  fms_options?: string[];
  protocol?: string | null;

  company_name?: string;
  privacy_policy_url?: string;
  login_html?: string;

  program_code?: string | null;
  self_enrol: boolean;

  login_image_blob_id?: string | null;
  login_page_program_desc_subtext?: string | null;
  logo_blob_id?: string | null;
  signup_vertical_banner_blob_id?: string | null;
  signup_vertical_banner_image_url?: string | null;

  support_inbox?: string | null;
  support_phone_number?: string | null;

  docusign_client_id?: string;
  docusign_status?: MRVProgramDocusignStatus;

  config?: Record<MRVProgramConfigItemKey, MRVProgramConfigItemObj>;
  custom_reg_inputs: MRVProgramCustomRegInput[];
  fallback_coordinate_system?: CoordinateSystem;

  display_announcement?: boolean;
  announcement_title?: string;
  announcement_description?: string;
};

export enum CoordinateSystem {
  EPSG2154 = 'EPSG:2154',
  EPSG4326 = 'EPSG:4326',
}

type MRVProgramConfigItemKey = 'extra_signup_fields';
type MRVProgramConfigItemObj = {
  key: string;
  value: string;
};

export type MRVProgramNormalized = Override<
  MRVProgram,
  {
    phases: number[];
    // FIXME: normalize permissions?
    // permissions: number[];
  }
>;

export type MRVProgramInput = Omit<MRVProgram, 'id' | 'phases' | 'permissions'>;

export type MRVCropType = {
  crop_type: number; // core crop id
  season: 'winter' | 'summer';
  id?: number;
  program_id?: number;
};

export enum OFSStatus {
  Success = 'success',
  Failed = 'failed',
  InProgress = 'in_progress',
}

export enum DNDCStatus {
  Success = 'success',
  Failed = 'failed',
  InProgress = 'in_progress',
}

export type MRVProjectConfig = {
  extra_signup_fields: {
    key: string;
    value: string;
  };
};

export type MRVProject = {
  id: number;
  program_id: number;
  created_at: DateStringWithNoTZ;
  updated_at: DateStringWithNoTZ;
  deleted_at: DateStringWithNoTZ;
  field_area_ha: number;
  program_name: string;
  program?: MRVProgram;
  fields?: MRVField[];
  farms?: MRVFarm[];
  values?: MRVValue[];
  permissions?: ProjectProducerUser[];
  region?: {region: string; country: string};
  contract_link: string;
  contracts?: MRVContract[];
  ofs_status: OFSStatus | null;
  dndc_status: DNDCStatus | null;
  config?: MRVProjectConfig;
  custom_reg_values: MRVCustomInput[];
};

export type MRVRegion = {
  id?: number;
  level: 'STATE' | 'COUNTY';
  country?: string;
  name: string;
  geom?: GeoJSON.MultiPolygon;
};

export type MRVProjectNormalized = Override<
  MRVProject,
  {
    fields: number[];
    program: number;
    values: number[];
  }
>;

export type MRVProgramEntities = {
  programs: Record<number, MRVProgramNormalized>;
  phases: Record<number, MRVPhaseNormalized>;
  stages: Record<number, MRVStageNormalized>;
  attributes: Record<number, MRVAttribute>;
};

export type MRVProgramResult = number[];

export type ProgramAdminUser = {
  // programs: number[];
  // area_ha: number;
  id: number;
  user_id: number;
  program_id: number;
  details: any;
  user_info?: {
    email?: string;
    first_name?: string;
    last_name?: string;
    last_login_time?: string;
  };
};

export type ProjectProducerUser = {
  id: number;
  user: number;
  details: null;
  deleted_at?: string;
  project: number;
  program_id: number;
  user_info?: {
    email?: string;
    first_name?: string;
    last_name?: string;
    last_login_time?: string;
  };

  user_sync_info?: UserSyncInfo;
};

export type UserSyncInfo = {
  last_login_time: string;
  latest_syncs: {
    external_service: ExternalService;
    latest_import: string;
  }[];
};

export type ProducerUser = {
  email: string;
  first_name: string;
  last_name: string;
  role: string;
  programs: number[];
  id?: number;
  area_ha?: number;
};

export enum MRVProgress {
  Enrolment = 'Enrolment',
  Monitoring = 'Monitoring',
  Verification = 'Verification',
  Stratification = 'Stratification',
  QAQC = 'QA/QC',
}

export enum MRVPhaseType {
  Monitoring = 'MONITORING',
  Enrollment = 'ENROLMENT',
}

// `Progress` was introduced before the concept of `phases`. Values use Progress.
// Everything else is phases. So here we link phase to progress.
export const phaseTypeToProgress: {[phaseType in MRVPhaseType]: MRVProgress} = {
  [MRVPhaseType.Monitoring]: MRVProgress.Monitoring,
  [MRVPhaseType.Enrollment]: MRVProgress.Enrolment,
};

export enum MRVStageType {
  FieldBoundaries = 'FIELD_BOUNDARIES',
  AssignPractices = 'ASSIGN_PRACTICES',
  ConfirmHistory = 'CONFIRM_HISTORY',
  ViewOutcomes = 'VIEW_OUTCOMES',
  Survey = 'SURVEY',
  HistoricalCropRotation = 'HISTORICAL_CROP_ROTATION',
  HistoricalTillage = 'HISTORICAL_TILLAGE',
  IntendedCommodityCrops = 'INTENDED_COMMODITY_CROPS',
  SummerCrops = 'SUMMER_CROPS',
  WinterCrops = 'WINTER_CROPS',
  Tillage = 'TILLAGE',
  NutrientManagement = 'NUTRIENT_MGMT',
  NutrientManagementIntended = 'NUTRIENT_MGMT_INTENDED',
  Contract = 'CONTRACT',
  Eligibility = 'ELIGIBILITY',
  FieldInformation = 'FIELD_INFORMATION',
  Irrigation = 'IRRIGATION',
  RiceCropHistory = 'RICE_CROP_HISTORY', // kellogs program
  MobHistory = 'MOB_HISTORY',
  MobMovement = 'MOB_MOVEMENT',
  FarmLevelManagement = 'FARM_LEVEL_MANAGEMENT',
}

export type MRVDashboardType = 'conflicts' | 'overview';

export enum MRVProgramConfigStep {
  Assets = 'assets',
  Dashboards = 'dashboards',
  Enrollment = 'enrollment',
  Overview = 'overview',
  Monitoring = 'monitoring',
}

export type StageCompletion = {
  completed: number;
  to_complete: number;
  total: number;
  percentage_complete: number;
  is_completed: boolean;
};

export enum ProjectValueSource {
  user = 'user', // manually entered
  agrian = 'agrian',
  agworld = 'agworld',
  agx = 'agx',
  climate = 'climate',
  efc = 'efc',
  john_deere = 'john_deere',
  optis = 'optis',
  other_fms = 'other_fms',
  productionwise = 'productionwise',
  terravion = 'terravion',
}
export enum MRVProgramDocusignStatus {
  CredentialsProvided = 'Credentials Provided',
  CredentialsNotProvided = 'Credentials Not Provided',
}
export enum MRVEnrollmentContractType {
  ContractTemplate = 'Contract Template',
  Docusign = 'Docusign',
}

export type MRVContract = {
  created_at: string; // DATE_TIME = 'YYYY-MM-DDTHH:mm:ss';
  deletion_reason: string;
  id: number;
  project: number;
  user: number;
  docusign_status: 'sent' | 'completed';
};

export enum Confidence {
  Low = 'Low confidence',
  High = 'High confidence',
  Confirmed = 'Confirmed',
}

export type WithValue = {
  value: any;
};

export type MRVValueBase = WithValue & {
  id: number;
  attribute_id: number;
  entity_type: MRVEntityType;
  /**
   * Generally it's 0...n, but it can have holes in case of deletions.
   * Don't rely on it as an order. Usually rows should be sorted first by year and only then by row_id.
   */
  row_id: number;
  confidence?: number;
  category?: string;
  confirmed: boolean;
  locked: boolean;
  read_only?: boolean;
  source: ProjectValueSource;
  progress: MRVProgress;
  validation_error?: string;
};

export type MRVFieldValue = MRVValueBase & {
  entity_type: 'field';
  /**
   * Mrv field id (as opposed to core field_id or kml_id).
   */
  field_id: number;
};

export type MRVFarmValue = MRVValueBase & {
  entity_type: 'farm';
  entity_id: number;
};

export type MRVMobValue = MRVValueBase & {
  entity_type: 'mob';
  entity_id: number;
};

export type MRVValue = MRVFieldValue | MRVFarmValue | MRVMobValue;

export type MRVValueInput = Pick<
  MRVValueBase,
  | 'confirmed'
  | 'locked'
  | 'progress'
  | 'entity_type'
  | 'attribute_id'
  | 'row_id'
  | 'value'
  | 'confidence'
  | 'validation_error'
> & {
  id?: number;
  entity_id?: number;
  field_id?: number;
};

type MrvFieldId = number;
export type MRVValuesInput = Record<MrvFieldId, MRVValueInput[]>;

type MRVValuesRowBase = {
  entity_id?: number;
  entity_type: MRVEntityType;
  field_id?: number;
  row_id: number;
  values: {[attributeId: string]: MRVValue | MRVValueInput};
  year: number;
};

export type MRVFieldValuesRow = MRVValuesRowBase & {
  entity_type: 'field';
  field_id: number;
};

export type MRVFarmValuesRow = MRVValuesRowBase & {
  entity_type: 'farm';
  entity_id: number;
};

export type MRVMobValuesRow = MRVValuesRowBase & {
  entity_type: 'mob';
  entity_id: number;
};

export type MRVValuesRow = MRVFieldValuesRow | MRVFarmValuesRow | MRVMobValuesRow;

export type FieldValuesRow = MRVValuesRow;

export type MonitoringFieldGeometry = GeoJSON.Polygon & {
  properties: {fieldId: number; name: string; position?: LatLngLiteral};
};

export type FieldCoreAttributes = {
  geometry: MonitoringFieldGeometry;
  external_service: ExternalService;
  external_grower_id: string;
  external_service_id: string; // id of a field in the external service
  farm_name: string;
  field_name: string;
  kml_id: number;
  field_area: number; // ha
};

export type MRVFarm = Entity & {
  core_farm_group_id: number;
  farm_name: string;
};

export type MRVField = Entity & {
  /**
   * Field.FieldID
   */
  fs_field_id: number;
  farm_id: number;
  owner: number;
  selected?: boolean;
  core_attributes: FieldCoreAttributes;
  ofs_status: OFSStatus | null;
  dndc_status: DNDCStatus | null;
  /**
   * TODO This will be hooked up to the backend in future work, once this is complete https://regrow.atlassian.net/browse/FSB-12522
   *
   * A field is eligible based on the protocol selected (not built for version 1, currently using hardcoded logic in the backend)
   * and the field's existing history (could be from any historical stages).
   *
   * The protocol that is selected determines which historical stages are relevant.
   */
  eligible: boolean;
};

export type MRVMob = Entity & {
  mob_name: string;
};

type Entity = {
  id: number;
  parent_project_id: number;
  created_at: DateStringWithNoTZ;
  updated_at: DateStringWithNoTZ;
  deleted_at: DateStringWithNoTZ | null;
};

export type MRVEntity = MRVFarm | MRVField | MRVMob;

export enum MRVAttributeType {
  SummerCropType = 'summer_crop_type',
  SummerHarvestDate = 'summer_harvest_date',
  SummerDryYield = 'summer_dry_yield',
  SummerResidueHarvested = 'summer_residue_harvested',
  SummerPlantingDate = 'summer_planting_date',

  WinterCropCommitment = 'winter_crop_commitment',
  WinterCropType = 'winter_crop_type',
  WinterCropTermination = 'winter_crop_termination',
  WinterHarvestDate = 'winter_harvest_date',
  WinterDryYield = 'winter_dry_yield',
  WinterResidueHarvested = 'winter_residue_harvested',
  WinterPlantingDate = 'winter_planting_date',

  FallTillagePractice = 'fall_tillage_practice',
  FallTillageDate = 'fall_tillage_date',
  FallTillageDepth = 'fall_tillage_depth',

  SpringTillagePractice = 'spring_tillage_practice',
  SpringTillageDate = 'spring_tillage_date',
  SpringTillageDepth = 'spring_tillage_depth',

  Harvest = 'harvest',
  Planting = 'planting',
  CropType = 'crop_type',
  CropYield = 'crop_yield',
  PlantingSeason = 'planting_season',
  PlantingDate = 'planting_date',
  HarvestDate = 'harvest_date',
  StartDate = 'start_date',
  EndDate = 'end_date',

  ResidueHarvested = 'residue_harvested',
  TillagePeriod = 'tillage_period',
  TillagePractice = 'tillage_practice',
  TillageDate = 'tillage_date',
  TillageDepth = 'tillage_depth',

  RecordYear = 'record_year',
  NutrientManagementAppProduct = 'application_product',
  NutrientManagementAppProductType = 'application_product_type',
  NutrientManagementAppDate = 'application_date',
  NutrientManagementAppRate = 'application_rate',
  NutrientManagementAppRateUnit = 'application_rate_unit',
  NutrientManagementAppArea = 'application_area',
  NutrientManagementAppDepth = 'application_depth',
  NutrientManagementAppMethod = 'application_method',
  YieldRateUnit = 'yield_rate_unit',

  FarmNumber = 'farm_number',
  CoverCropMix = 'cover_crop_mix',
  SoilInversion = 'soil_inversion',
  IrrigationEnabled = 'irrigation_enabled',
  IrrigationMethod = 'irrigation_method',
  IrrigationRateUnit = 'irrigation_rate_unit',
  IrrigationDepth = 'irrigation_depth',

  EnergySource = 'energy_source',
  FuelType = 'fuel_type',
  PesticideFarmprintGAFF = 'pesticide_farmprint_gaff',

  ChangeType = 'change_type',
  HeadCount = 'head_count',
  LivestockClass = 'livestock_class',

  AreaSown = 'area_sown',
  FarmName = 'farm_name',
  ResidueBurnt = 'residue_burnt',
  AreaTilledTotalPct = 'area_tilled_total_pct',
  AreaTilledForPastureRenewalPct = 'area_tilled_for_pasture_renewal_pct',
  NutrientManagementEnabled = 'nutrient_management_enabled',
  LandscapeModificationsFuelUsage = 'landscape_modifications_fuel_usage',
  LandscapeModificationsFuelType = 'landscape_modifications_fuel_type',
  IrrigationFuelUsage = 'irrigation_fuel_usage',
  IrrigationFuelType = 'irrigation_fuel_type',
  IrrigationElectricityUsage = 'irrigation_electricity_usage',
  IrrigationElectricitySource = 'irrigation_electricity_source',
  IrrigationElectricityGridName = 'irrigation_electricity_grid_name',

  TotalArea = 'total_area',
  TotalEnergyUsage = 'total_energy_usage',
  TotalFuelUsage = 'total_fuel_usage',

  Practice = 'Practice', // assign practices attribute

  Date = 'date',
  Bool = 'bool',
  Other = 'other',
  String = 'string',
  Number = 'number',
}

// Attribute = Column of inputs
export interface Attribute {
  id: number;
  parent_stage_id: number;
  name: string;
  type: MRVAttributeType;
  /**
   * Do not use order to sort the order in the UI.
   * This can be used to set the order on the server.
   */
  order: number;
  /**
   * Turned on in config.
   * Whether the column is rendered.
   */
  enabled: boolean;
  locked: boolean;
  default_value: string | null;
  label: string;
  description: string;
  owner_org: number;
  deleted_at: DateStringWithNoTZ | null;
  options: MRVAttributeOption[];
  unit_id: number;
  units: {};
  dependencies: (Dependency | DependencyGroup)[];
  max_val: string | null;
  min_val: string | null;
  custom_name?: string | null;
  /**
   * Whether the column is greyed out.
   */
  visible: boolean;
}

export type MRVAttributeOption = string | FluroSelectLiteItem;

export type BooleanOperator = 'and' | 'or';
export type DependencyGroup = {
  operator: BooleanOperator;
  dependencies: (Dependency | DependencyGroup)[];
};

export type EqualityOperator = 'is' | 'is_not';
export type Dependency = {
  /**
   * The attributeId that the attribute depends on.
   */
  id: number;
  operator: EqualityOperator;
  value: string;
};

export type MRVAttribute = Attribute;

export type EnrolmentAttribute = Attribute;

export interface MRVAttributeInput
  extends Omit<
    MRVAttribute,
    'id' | 'name' | 'label' | 'owner_org' | 'deleted_at' | 'options' | 'unit_id' | 'units'
  > {
  name?: string;
  label?: string;
  options?: MRVAttributeOption[];
  unit_id?: number;
  units?: {};
}

// Stage = Step
export interface MRVStage {
  attributes: MRVAttribute[];
  entity_type: MRVEntityType;
  description: string;
  id: number;
  type_: MRVStageType;
  custom_name: string | null;
  order: number;
  icon: string;
  icon_url?: string;
  owner_org?: number;
  fmi_import_end_date: DateStringWithNoTZ;
  fmi_import_start_date: DateStringWithNoTZ;
  year_start: number;
  year_end: number;
  /**
   * Turned on in config.
   */
  enabled: boolean;
  num_rows: number;
  messages: StageMessage[] | null;
  config_description: unknown;
  survey_id: string | null;
  locked: boolean;
  phase_id: number;
  deleted_at: unknown;
  phase_type: unknown;
  eligibility_method: EligibilityMethod | null;
  /**
   * Whether the stage has a required entry section in the config.
   */
  has_required_entry_section: boolean;
  /**
   * Whether the stage is required to be completed, changing how the completion logic works.
   */
  required: boolean;
  /**
   * Whether the stage has a timeline section in the config.
   */
  has_timeline_section: boolean;
  /**
   * Whether the timeline is visible on the producer view.
   * Also displays other timeline related controls in the config.
   */
  timeline_enabled: boolean;
  timeline_start_date: DateStringWithNoTZ | null;
  timeline_end_date: DateStringWithNoTZ | null;
  has_interventions_section: boolean;
  interventions_description: string;
}

export type StageMessage = {type: AppearanceValues; text?: string | JSX.Element; title?: string};

export type MRVStageNormalized = Override<MRVStage, {attributes: number[]}>;

export type MRVStageInput = Pick<
  MRVStage,
  | 'type_'
  | 'custom_name'
  | 'description'
  | 'entity_type'
  | 'order'
  | 'fmi_import_end_date'
  | 'fmi_import_start_date'
  | 'enabled'
  | 'icon'
  | 'num_rows'
  | 'locked'
  // to be removed when https://regrow.atlassian.net/browse/FSB-12723 is completed
  | 'required'
>;

export type MRVPhase = {
  stages: MRVStage[];
  /**
   * Turned on in config.
   */
  enabled?: boolean;
  params?: {
    limit_program_ha?: number;
    limit_project_ha?: number;
    outcome_estimation?: boolean;
  };
  created_at: DateStringWithNoTZ;
  deleted_at?: DateStringWithNoTZ;
  end_date: DateStringWithNoTZ;
  id: number;
  program_id: number;
  start_date: DateStringWithNoTZ;
  updated_at?: DateStringWithNoTZ;
  resources?: string; // info html blob
  contract: boolean;
  contract_template?: string;
  contract_type?: string;
  no_contract_message?: string; // the popup content user will see if there's no contract template set up yet
  show_contract?: boolean;
  type_: MRVPhaseType;
  name: string;
};

export type MRVPhaseNormalized = Override<MRVPhase, {stages: number[]}>;

export interface MrvProgramConfig {
  stages: MRVStage[];
  created_at: DateStringWithNoTZ;
  deleted_at?: DateStringWithNoTZ;
  end_date: DateStringWithNoTZ;
  id: number;
  program_id: number;
  start_date: DateStringWithNoTZ;
  updated_at?: DateStringWithNoTZ;
  resources?: string; // info html blob
}
export type MonitoringConfig = MrvProgramConfig;
export interface EnrollmentConfig extends MrvProgramConfig {
  limit_program_ha: number;
  limit_project_ha: number;
  outcome_estimation?: boolean;
  contract_links?: string[];
  contracts?: string[];
  contract: boolean;
  contract_templates: [];
  contract_type: MRVEnrollmentContractType;
  show_contract: boolean;
}

export type MonitoringConfigNormalized = Override<MonitoringConfig, {stages: number[]}>;
export type EnrollmentConfigNormalized = Override<EnrollmentConfig, {stages: number[]}>;

export const monitoringAttributeTypes = [
  MRVAttributeType.SummerCropType,
  MRVAttributeType.SummerHarvestDate,
  MRVAttributeType.SummerDryYield,
  MRVAttributeType.SummerResidueHarvested,
  MRVAttributeType.IrrigationMethod,
  MRVAttributeType.IrrigationEnabled,

  MRVAttributeType.WinterCropCommitment,
  MRVAttributeType.WinterCropType,
  MRVAttributeType.WinterPlantingDate,

  MRVAttributeType.TillagePeriod,
  MRVAttributeType.FallTillagePractice,
  MRVAttributeType.FallTillageDate,
  MRVAttributeType.FallTillageDepth,

  MRVAttributeType.RecordYear,
  MRVAttributeType.NutrientManagementAppProduct,
  MRVAttributeType.NutrientManagementAppDate,
  MRVAttributeType.NutrientManagementAppRate,
  MRVAttributeType.NutrientManagementAppRateUnit,
  MRVAttributeType.NutrientManagementAppArea,
  MRVAttributeType.NutrientManagementAppDepth,
];

const translateMRVAttrLabel = (type: MRVAttributeType, defaultMessage: string) =>
  t({id: `MRVAttributeType.${type}`, defaultMessage});

const translateMRVAttrLabelReportView = (type: MRVAttributeType, defaultMessage: string) =>
  t({id: `ReportView.MRVAttributeType.${type}`, defaultMessage});

export const mrvAttributeTypesLabels: Record<MRVAttributeType, () => string> = {
  [MRVAttributeType.SummerCropType]: () =>
    translateMRVAttrLabel(MRVAttributeType.SummerCropType, 'Crop type'),
  [MRVAttributeType.SummerHarvestDate]: () =>
    translateMRVAttrLabel(MRVAttributeType.SummerHarvestDate, 'Harvest date'),
  [MRVAttributeType.SummerDryYield]: () =>
    translateMRVAttrLabel(MRVAttributeType.SummerDryYield, 'Est. yield'),
  [MRVAttributeType.SummerResidueHarvested]: () =>
    translateMRVAttrLabel(MRVAttributeType.SummerResidueHarvested, 'Residue harvested'),
  [MRVAttributeType.IrrigationMethod]: () =>
    translateMRVAttrLabel(MRVAttributeType.IrrigationMethod, 'Irrigation method'),
  [MRVAttributeType.SummerPlantingDate]: () =>
    translateMRVAttrLabel(MRVAttributeType.SummerPlantingDate, 'Planting date'),

  [MRVAttributeType.WinterCropCommitment]: () =>
    translateMRVAttrLabel(MRVAttributeType.WinterCropCommitment, 'Winter crop'),
  [MRVAttributeType.WinterCropType]: () =>
    translateMRVAttrLabel(MRVAttributeType.WinterCropType, 'Crop type'),
  [MRVAttributeType.WinterCropTermination]: () =>
    translateMRVAttrLabel(MRVAttributeType.WinterCropTermination, 'Termination method'),
  [MRVAttributeType.WinterHarvestDate]: () =>
    translateMRVAttrLabel(MRVAttributeType.WinterHarvestDate, 'Termination date'),
  [MRVAttributeType.WinterDryYield]: () =>
    translateMRVAttrLabel(MRVAttributeType.WinterDryYield, 'Yield'),
  [MRVAttributeType.WinterResidueHarvested]: () =>
    translateMRVAttrLabel(MRVAttributeType.WinterResidueHarvested, 'Residue harvested'),
  [MRVAttributeType.IrrigationEnabled]: () =>
    translateMRVAttrLabel(MRVAttributeType.IrrigationEnabled, 'Irrigation'),
  [MRVAttributeType.WinterPlantingDate]: () =>
    translateMRVAttrLabel(MRVAttributeType.WinterPlantingDate, 'Planting date'),

  [MRVAttributeType.PlantingSeason]: () =>
    translateMRVAttrLabel(MRVAttributeType.PlantingSeason, 'Planting season'),
  [MRVAttributeType.PlantingDate]: () =>
    translateMRVAttrLabel(MRVAttributeType.PlantingDate, 'Planting date'),
  [MRVAttributeType.HarvestDate]: () =>
    translateMRVAttrLabel(MRVAttributeType.HarvestDate, 'Harvest date'),
  [MRVAttributeType.StartDate]: () =>
    translateMRVAttrLabel(MRVAttributeType.StartDate, 'Start date'),
  [MRVAttributeType.EndDate]: () => translateMRVAttrLabel(MRVAttributeType.EndDate, 'End date'),

  [MRVAttributeType.FallTillagePractice]: () =>
    translateMRVAttrLabel(MRVAttributeType.FallTillagePractice, 'Fall tillage practice'),
  [MRVAttributeType.FallTillageDate]: () =>
    translateMRVAttrLabel(MRVAttributeType.FallTillageDate, 'Fall tillage date'),
  [MRVAttributeType.FallTillageDepth]: () =>
    translateMRVAttrLabel(MRVAttributeType.FallTillageDepth, 'Fall tillage depth'),

  [MRVAttributeType.SpringTillagePractice]: () =>
    translateMRVAttrLabel(MRVAttributeType.SpringTillagePractice, 'Spring tillage practice'),
  [MRVAttributeType.SpringTillageDate]: () =>
    translateMRVAttrLabel(MRVAttributeType.SpringTillageDate, 'Spring tillage date'),
  [MRVAttributeType.SpringTillageDepth]: () =>
    translateMRVAttrLabel(MRVAttributeType.SpringTillageDepth, 'Spring tillage depth'),

  [MRVAttributeType.Harvest]: () => translateMRVAttrLabel(MRVAttributeType.Harvest, 'Harvest'),
  [MRVAttributeType.Planting]: () => translateMRVAttrLabel(MRVAttributeType.Planting, 'Planting'),
  [MRVAttributeType.CropType]: () => translateMRVAttrLabel(MRVAttributeType.CropType, 'Crop type'),
  [MRVAttributeType.CropYield]: () =>
    translateMRVAttrLabel(MRVAttributeType.CropYield, 'Crop yield'),

  [MRVAttributeType.ResidueHarvested]: () =>
    translateMRVAttrLabel(MRVAttributeType.ResidueHarvested, 'Residue harvested'),
  [MRVAttributeType.TillagePeriod]: () =>
    translateMRVAttrLabel(MRVAttributeType.TillagePeriod, 'Tillage period'),
  [MRVAttributeType.TillagePractice]: () =>
    translateMRVAttrLabel(MRVAttributeType.TillagePractice, 'Tillage practice'),
  [MRVAttributeType.TillageDate]: () =>
    translateMRVAttrLabel(MRVAttributeType.TillageDate, 'Tillage date'),
  [MRVAttributeType.TillageDepth]: () =>
    translateMRVAttrLabel(MRVAttributeType.TillageDepth, 'Tillage depth'),

  [MRVAttributeType.RecordYear]: () => translateMRVAttrLabel(MRVAttributeType.RecordYear, 'Year'),
  [MRVAttributeType.NutrientManagementAppProduct]: () =>
    translateMRVAttrLabel(MRVAttributeType.NutrientManagementAppProduct, 'Product'),
  [MRVAttributeType.NutrientManagementAppProductType]: () =>
    translateMRVAttrLabel(MRVAttributeType.NutrientManagementAppProductType, 'Product type'),
  [MRVAttributeType.NutrientManagementAppDate]: () =>
    translateMRVAttrLabel(MRVAttributeType.NutrientManagementAppDate, 'Application date'),
  [MRVAttributeType.NutrientManagementAppRate]: () =>
    translateMRVAttrLabel(MRVAttributeType.NutrientManagementAppRate, 'Rate amount'),
  [MRVAttributeType.NutrientManagementAppRateUnit]: () =>
    translateMRVAttrLabel(MRVAttributeType.NutrientManagementAppRateUnit, 'Rate unit'),
  [MRVAttributeType.NutrientManagementAppArea]: () =>
    translateMRVAttrLabel(MRVAttributeType.NutrientManagementAppArea, 'Area applied'),
  [MRVAttributeType.NutrientManagementAppDepth]: () =>
    translateMRVAttrLabel(MRVAttributeType.NutrientManagementAppDepth, 'Application depth'),
  [MRVAttributeType.NutrientManagementAppMethod]: () =>
    translateMRVAttrLabel(MRVAttributeType.NutrientManagementAppMethod, 'Application method'),

  [MRVAttributeType.YieldRateUnit]: () =>
    translateMRVAttrLabel(MRVAttributeType.YieldRateUnit, 'Yield unit'),
  [MRVAttributeType.Practice]: () => translateMRVAttrLabel(MRVAttributeType.Practice, 'Practice'),
  [MRVAttributeType.FarmNumber]: () =>
    translateMRVAttrLabel(MRVAttributeType.FarmNumber, 'Farm number'),
  [MRVAttributeType.CoverCropMix]: () =>
    translateMRVAttrLabel(MRVAttributeType.CoverCropMix, 'Cover crop'),
  [MRVAttributeType.SoilInversion]: () =>
    translateMRVAttrLabel(MRVAttributeType.SoilInversion, 'Soil inversion'),
  [MRVAttributeType.IrrigationRateUnit]: () =>
    translateMRVAttrLabel(MRVAttributeType.IrrigationRateUnit, 'Irrigation unit'),
  [MRVAttributeType.IrrigationDepth]: () =>
    translateMRVAttrLabel(MRVAttributeType.IrrigationDepth, 'Irrigation depth'),
  [MRVAttributeType.EnergySource]: () =>
    translateMRVAttrLabel(MRVAttributeType.EnergySource, 'Energy source'),
  [MRVAttributeType.FuelType]: () => translateMRVAttrLabel(MRVAttributeType.FuelType, 'Fuel type'),
  [MRVAttributeType.PesticideFarmprintGAFF]: () =>
    translateMRVAttrLabel(MRVAttributeType.PesticideFarmprintGAFF, 'Product (Pesticide)'),

  [MRVAttributeType.ChangeType]: () =>
    translateMRVAttrLabel(MRVAttributeType.ChangeType, 'Change type'),
  [MRVAttributeType.HeadCount]: () =>
    translateMRVAttrLabel(MRVAttributeType.HeadCount, 'Head count'),
  [MRVAttributeType.LivestockClass]: () =>
    translateMRVAttrLabel(MRVAttributeType.LivestockClass, 'Livestock class'),

  [MRVAttributeType.AreaSown]: () => translateMRVAttrLabel(MRVAttributeType.AreaSown, 'Area sown'),
  [MRVAttributeType.FarmName]: () => translateMRVAttrLabel(MRVAttributeType.FarmName, 'Farm name'),
  [MRVAttributeType.ResidueBurnt]: () =>
    translateMRVAttrLabel(MRVAttributeType.ResidueBurnt, 'Residue burnt'),
  [MRVAttributeType.AreaTilledTotalPct]: () =>
    translateMRVAttrLabel(MRVAttributeType.AreaTilledTotalPct, 'Area tilled total percentage'),
  [MRVAttributeType.AreaTilledForPastureRenewalPct]: () =>
    translateMRVAttrLabel(
      MRVAttributeType.AreaTilledTotalPct,
      'Area tilled for pasture renewal percentage'
    ),
  [MRVAttributeType.NutrientManagementEnabled]: () =>
    translateMRVAttrLabel(
      MRVAttributeType.NutrientManagementEnabled,
      'Nutrient management enabled'
    ),
  [MRVAttributeType.LandscapeModificationsFuelUsage]: () =>
    translateMRVAttrLabel(
      MRVAttributeType.LandscapeModificationsFuelUsage,
      'Landscape modifications fuel usage'
    ),
  [MRVAttributeType.LandscapeModificationsFuelType]: () =>
    translateMRVAttrLabel(
      MRVAttributeType.LandscapeModificationsFuelType,
      'Landscape modifications fuel type'
    ),
  [MRVAttributeType.IrrigationFuelUsage]: () =>
    translateMRVAttrLabel(MRVAttributeType.IrrigationFuelUsage, 'Irrigation fuel usage'),
  [MRVAttributeType.IrrigationFuelType]: () =>
    translateMRVAttrLabel(MRVAttributeType.IrrigationFuelType, 'Irrigation fuel type'),
  [MRVAttributeType.IrrigationElectricityUsage]: () =>
    translateMRVAttrLabel(
      MRVAttributeType.IrrigationElectricityUsage,
      'Irrigation electricity usage'
    ),
  [MRVAttributeType.IrrigationElectricitySource]: () =>
    translateMRVAttrLabel(
      MRVAttributeType.IrrigationElectricitySource,
      'Irrigation electricity source'
    ),
  [MRVAttributeType.IrrigationElectricityGridName]: () =>
    translateMRVAttrLabel(
      MRVAttributeType.IrrigationElectricityGridName,
      'Irrigation electricity grid name'
    ),
  [MRVAttributeType.TotalArea]: () =>
    translateMRVAttrLabel(MRVAttributeType.TotalArea, 'Total area'),
  [MRVAttributeType.TotalEnergyUsage]: () =>
    translateMRVAttrLabel(MRVAttributeType.TotalEnergyUsage, 'Total energy usage'),
  [MRVAttributeType.TotalFuelUsage]: () =>
    translateMRVAttrLabel(MRVAttributeType.TotalFuelUsage, 'Total fuel usage'),

  [MRVAttributeType.Date]: () => translateMRVAttrLabel(MRVAttributeType.Date, 'Date'),
  [MRVAttributeType.Bool]: () => translateMRVAttrLabel(MRVAttributeType.Bool, 'Bool'),
  [MRVAttributeType.Other]: () => translateMRVAttrLabel(MRVAttributeType.Other, 'Other'),
  [MRVAttributeType.Number]: () => translateMRVAttrLabel(MRVAttributeType.Number, 'Number'),
  [MRVAttributeType.String]: () => translateMRVAttrLabel(MRVAttributeType.String, 'String'),
};

/**
 * Overrides `mrvAttributeTypesLabels` for the report view.
 */
export const mrvAttributeTypesLabelsReportView: Partial<Record<MRVAttributeType, string>> = {
  [MRVAttributeType.WinterCropCommitment]: translateMRVAttrLabelReportView(
    MRVAttributeType.WinterCropCommitment,
    'Winter crop (Winter)'
  ),

  [MRVAttributeType.WinterCropType]: translateMRVAttrLabelReportView(
    MRVAttributeType.WinterCropType,
    'Crop type (Winter)'
  ),
  [MRVAttributeType.WinterCropTermination]: translateMRVAttrLabelReportView(
    MRVAttributeType.WinterCropTermination,
    'Termination method (Winter)'
  ),
  [MRVAttributeType.WinterHarvestDate]: translateMRVAttrLabelReportView(
    MRVAttributeType.WinterHarvestDate,
    'Termination date (Winter)'
  ),
  [MRVAttributeType.WinterDryYield]: translateMRVAttrLabelReportView(
    MRVAttributeType.WinterDryYield,
    'Yield (Winter)'
  ),
  [MRVAttributeType.WinterResidueHarvested]: translateMRVAttrLabelReportView(
    MRVAttributeType.WinterResidueHarvested,
    'Residue harvested (Winter)'
  ),
  [MRVAttributeType.IrrigationEnabled]: translateMRVAttrLabelReportView(
    MRVAttributeType.IrrigationEnabled,
    'Irrigation'
  ),
  [MRVAttributeType.WinterPlantingDate]: translateMRVAttrLabelReportView(
    MRVAttributeType.WinterPlantingDate,
    'Planting date (Winter)'
  ),

  [MRVAttributeType.FallTillagePractice]: translateMRVAttrLabelReportView(
    MRVAttributeType.FallTillagePractice,
    'Tillage practice (Fall)'
  ),
  [MRVAttributeType.FallTillageDate]: translateMRVAttrLabelReportView(
    MRVAttributeType.FallTillageDate,
    'Tillage date (Fall)'
  ),
  [MRVAttributeType.FallTillageDepth]: translateMRVAttrLabelReportView(
    MRVAttributeType.FallTillageDepth,
    'Tillage depth (Fall)'
  ),

  [MRVAttributeType.SpringTillagePractice]: translateMRVAttrLabelReportView(
    MRVAttributeType.SpringTillagePractice,
    'Tillage practice (Spring)'
  ),
  [MRVAttributeType.SpringTillageDate]: translateMRVAttrLabelReportView(
    MRVAttributeType.SpringTillageDate,
    'Tillage date (Spring)'
  ),
  [MRVAttributeType.SpringTillageDepth]: translateMRVAttrLabelReportView(
    MRVAttributeType.SpringTillageDepth,
    'Tillage depth (Spring)'
  ),
};

export type MRVStageIcon = {id: number; name: string};

export type MRVProgramStats = {
  area_ha: number;
  num_fields: number;
  num_projects: number;
  exceeds_program_area_limit: boolean;
};

export enum Practice {
  TillageReduction = 'Tillage Reduction',
  CoverCrops = 'Cover Crops',
  NutrientManagement = 'Nutrient Management',
  IntegratedGrazing = 'Integrated Grazing',
  ConversionToPerennial = 'Conversion to perennial',
  CropRotation = 'Crop Rotation',

  ConservationPractices = 'Conservation Practices',
  FertilizerReduction = 'Fertilizer Reduction',
  Composting = 'Composting',
  WholeOrchardRecycling = 'Whole Orchard Recycling',
  ReducedTill = 'Reduced Till',
  NoTill = 'No Till',
  IrrigationManagement = 'Irrigation Management',

  BasicCoverCrops = 'Basic Cover Crops',
  PremiumCoverCrops = 'Premium Cover Crops',

  NoCoverCrop = 'No Cover Crop',
  ConventionalTill = 'Conventional Till',

  StubbleRetention = 'Stubble retention',
  StockingIntensity = 'Stocking intensity',
  StockingDuration = 'Stocking duration',
  SoilAmendments = 'Soil amendments',
  IncorporateLegumes = 'Incorporate legumes',

  SeedingOrPastureCropping = 'Seeding or pasture cropping',
  LandscapeModification = 'Landscape modification',
  ThinningWoodyVegetation = 'Thinning woody vegetation',
  ClearWoodyVegetation = 'Clear woody vegetation',
}

export const practiceTypeToIcon: {[p in Practice]?: string} = {
  [Practice.TillageReduction]: 'tillage_reduction.svg',
  [Practice.CoverCrops]: 'grass.svg',
  [Practice.NutrientManagement]: 'seeds.svg',
  [Practice.IntegratedGrazing]: 'cow.svg',
  [Practice.ConversionToPerennial]: 'tree.svg',
  [Practice.CropRotation]: 'corn.svg',

  [Practice.ConservationPractices]: 'conservation.svg',
  [Practice.FertilizerReduction]: '',
  [Practice.Composting]: 'composting.svg',
  [Practice.WholeOrchardRecycling]: 'whole_orchard_recycling.svg',
  [Practice.IrrigationManagement]: 'drip.svg',
  [Practice.NoTill]: 'no_till.svg',
  [Practice.ReducedTill]: 'reduce_till.svg',

  [Practice.BasicCoverCrops]: 'basic_cover_crops.svg',
  [Practice.PremiumCoverCrops]: 'premium_cover_crops.svg',

  [Practice.StubbleRetention]: 'grass.svg',

  [Practice.StockingIntensity]: 'stocking_intensity.svg',
  [Practice.StockingDuration]: 'stocking_duration.svg',
  [Practice.SoilAmendments]: 'soil_amendment.svg',
  [Practice.IncorporateLegumes]: 'incorporate_legumes.svg',

  [Practice.SeedingOrPastureCropping]: 'seeding_or_pasture_cropping.svg',
  [Practice.LandscapeModification]: 'landscape_modification.svg',
  [Practice.ThinningWoodyVegetation]: 'thinning_woody_vegetation.svg',
  [Practice.ClearWoodyVegetation]: 'clear_woody_vegetation.svg',
};

export type SignUpFormCustomInput = {
  type_: 'Regex' | 'Dropdown';
  key: string;
  value: string;
};

export type SignUpFormData = {
  first_name: string;
  last_name: string;
  country: string;
  address: string;
  city: string;
  zip: string;
  state: string;
  email: string;
  phone: string;
  _validPhone: boolean;
  phoneFullNumber: string;
  password: string;
  confirm_password: string;
  account_id?: string;
  [key: `custom_input_${number}`]: string;
  custom_inputs?: SignUpFormCustomInput[];
};

export type AddAUserToAProgramFormData = {
  user_id: number;
  programs: number[];
  email: string;
  first_name?: string;
  last_name?: string;
  prevent_email?: boolean; // prevent the Core from sending an invitation email, should be true only for existing users
};

export type InviteMRVUserPayload = {
  program_id: number;
  email: string;
  first_name: string;
  last_name: string;
  prevent_email?: boolean;
};

export type InvitedNewUser = {
  admin_name: string;
  company_name?: string;
  custom_reg_inputs?: MRVProgramCustomRegInput[];
  email: string;
  first_name: string;
  last_name: string;
  locale?: Locales;
  login_image_blob_url: string;
  logo_blob_url: string;
  permission: string;
  privacy_policy_url?: string;
  program_code?: string;
  program_name: string;
  signup_vertical_banner_blob_url: string;
};

export type ProgramInvite = {
  created_at: string;
  email: string;
  expires_at: string; //"2022-06-01T02:28:25"
  first_name: string;
  id: string;
  last_name: string;
  prevent_email: boolean;
  program_id: number;
  redeemed_at: string; //"2022-06-01T02:28:25"
};

export enum PhaseReadOnlyReason {
  TooEarly = 'TooEarly',
  TooLate = 'TooLate',
  ContractIsSigned = 'ContractIsSigned',
  Unknown = 'Unknown',
  CalculatingDNDC = 'CalculatingDNDC',
  NoFieldsEnrolled = 'NoFieldsEnrolled',
  IsImpersonatorNotSuperAdmin = 'IsImpersonatorNotSuperAdmin',
}

export type EnrollmentImageBlobType = 'logo_image' | 'signup_vertical_banner_image' | 'login_image';

export type MRVNotification = {
  body: MRVNotificationBody;
  created_at: string;
  dismissed_at?: string;
  entity: 'Projects' | 'Fields';
  entity_id: number;
  first_sent_at?: string;
  id: number;
  last_sent_at: string;
  number_sent: number;
  program_id?: number;
  project_id: number;
  source: NotificationSource;
};

export type NotificationSource = 'optis' | 'dndc';

export type MRVProjectEligibility = Record<
  number,
  {
    eligible_practices: Practice[][];
    eligible: boolean;
    message: string;
    title: string;
    crop_types: string[];
  }
>;

export type MRVNotificationBody = {
  url: string;
  code: MRVNotificationCode;
  method: 'GET';
  message: string;
};

export enum MRVNotificationCode {
  Processing = 0,
  /**
   * e.g. A year of the field data was updated.
   */
  NewData = 1,
  NoData = 2,
  /**
   * All years of the field data were updated.
   *
   * OR
   *
   * All fields in the project were updated.
   */
  AllDone = 3,
  Error = 4,
}

export type MRVSurvey = {
  submission_id?: string;
  survey_id?: string;
  results_pdf_url?: string;
  completed: boolean;
};

export type MRVSurveySubmission = {
  data: unknown; // It's a Paperform schema. Don't wanna type it since we're not using it anyways.
  form_id: string; // "4rjy6act"
  submission_id: string; // "6296bbe2ffbfa6457341ed8b"
  total: number;
};

export type MRVCommercialRuleData = {
  start_date: string;
  end_date: string;
  payment: number;
  payment_for: PaymentFor;
  attribute_id?: number | null;
  id?: number;
  phase_id?: number;
};

export type AttributeValue = {
  attribute: MRVAttribute;
  value: any;
};

export type EligibilityMethodItem = {
  name: string;
  description: string;
  enum: EligibilityMethod;
};

export enum EligibilityMethod {
  CargillGrain2022 = 'CARGILL_GRAIN_2022',
  CargillCotton2022 = 'CARGILL_COTTON_2022',
  CargillEU2022 = 'CARGILL_EU_2022',
  AlwaysEligible = 'ELIGIBILITY_ALWAYS_TRUE',
}

export type FmsOption = {
  logo_url: string;
  fms_type: string;
};

export type MRVProgramCustomRegInput = {
  id: number;
  order: number;
  program_id?: number;
  name: string;
  type_: 'Regex' | 'Dropdown';
  validation_rule: 'Cargill Grain API' | 'None';
  config: string;
};

export type MRVCustomInput = {
  type_: 'Dropdown' | 'Regex';
  key: string;
  value: string;
  project_id?: number;
  name?: string;
  config?: string;
  program_id?: number;
  program_name?: string;
  id?: string | null;
};

export type BulkUpdateFieldData = {fs_field_id?: number; mrv_field_id?: number};

export type MRVEntityType = 'field' | 'farm' | 'mob';
