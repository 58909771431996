// @ts-nocheck
import {t} from 'i18n-utils';
import type {CSSProperties} from 'react';
import React, {useMemo} from 'react';
import {useDispatch} from 'react-redux';
import Compare from './compare';
import {drawRemoteSensingDateIcon as drawDateIcon} from '../../utils';
import {FontIcon, SelectionControl, Slider} from 'react-md';
import {AutoSizer, List} from 'react-virtualized';
import {Ln, MultiKeysPressed, ReadOnly, Flex} from 'components';
import {isProd, convertTileDateToNormal} from '_utils';
import {removeDuplicates, getGetURLParam} from '_utils/pure-utils';
import {DownloadIcon, HistogramIcon} from '../../icons';
import {
  hideDateImage,
  imageSensingSetCurrent,
  imageSensingToggleDialog,
  remoteSensingSetCloudCoverFilter,
  setDate,
  setDatesFilterByType,
  toggleCompare,
} from '../../actions';

import UploadLayers from './upload-layers/upload-layers';

import type {SourceType, TInfoExt} from '../../types';
import cn from 'classnames';

import {TypeIcon} from 'components/icons';

import DownloadImageLayerDialog from './download-image-layer-dialog';

import {AsyncStatusType, dialogToggle, DialogType} from 'modules/helpers';

import {showNotification} from 'components/notification/notification';
import {countFieldsWithTreeDataByDay} from '../../utils/trees';
import {useAppSelector} from '_hooks';
import {
  selectCurrentDate,
  selectCurrentDates,
  selectImagesStatus,
  selectIsCompareOn,
  selectIsReadOnly,
  selectRemoteSensingCloudCover,
  selectRemoteSensingFilterProps,
  selectSourcesMeta,
  selectWholeFarmData,
} from '../../reducer/selectors';
import {selectIsAdmin} from '../../../login/login-selectors';
import SimplePreloader from 'components/simple-preloader';

const RemoteSensingList = () => {
  const dispatch = useDispatch();
  const currentDates = useAppSelector(selectCurrentDates);
  const currentDate = useAppSelector(selectCurrentDate);
  const isCompareOn = useAppSelector(selectIsCompareOn);
  const wholeFarm = useAppSelector(selectWholeFarmData);
  const remoteSensingCloudCover = useAppSelector(selectRemoteSensingCloudCover);
  const remoteSensingFilterProps = useAppSelector(selectRemoteSensingFilterProps);
  const isReadOnly = useAppSelector(selectIsReadOnly);
  const imageStatus = useAppSelector(selectImagesStatus);
  const sourcesMeta = useAppSelector(selectSourcesMeta);
  const isAdmin = useAppSelector(selectIsAdmin);

  const rsi = useAppSelector(s => s.map.remoteSensingImages);
  const remoteSensingImages = useMemo(() => removeDuplicates(rsi, 'Date'), [rsi]);

  const openHistogramDialog = () => {
    dispatch(dialogToggle(DialogType.histogram, true));
  };

  const openTile = (im: TInfoExt) => {
    dispatch(imageSensingSetCurrent(im));
    dispatch(imageSensingToggleDialog(true));
  };

  const onHideDialog = () => {
    dispatch(imageSensingToggleDialog(false));
  };

  const setRemoteSensingImage = (image: TInfoExt) => {
    const date = getImageDate(image);
    dispatch(imageSensingSetCurrent(image));
    currentDates[date] && dispatch(setDate(date));
  };

  const setImageDate = (direction: string) => {
    const list = remoteSensingImages.filter((im: any) => !im.Hidden);
    const currentImageIndex = list.findIndex((img: any) => currentDate === getImageDate(img)) || 0;
    const currentImage =
      list.find((img: any) => currentDate === getImageDate(img)) || remoteSensingImages[0];
    const nextImage = list[currentImageIndex - 1] || currentImage;
    const prevImage = list[currentImageIndex + 1] || currentImage;

    if (direction === 'next') setRemoteSensingImage(nextImage);
    if (direction === 'prev') setRemoteSensingImage(prevImage);
  };
  const setNextDate = () => setImageDate('next');
  const setPrevDate = () => setImageDate('prev');

  const getImageDate = (image: any) => `${convertTileDateToNormal(image.Date, true)}-${image.Type}`;

  const checkNmap = (date: string, type: string) => {
    const sameDate = currentDates[`${convertTileDateToNormal(date, true)}-${type}`];
    return sameDate ? !!sameDate.NMAP : false;
  };

  const filterDatesByType = (type: SourceType, toAdd: boolean) => {
    if (toAdd) {
      const emulateFinalFilter = [...remoteSensingFilterProps, type];
      if (sourcesMeta.filter(s => s.available).every(s => emulateFinalFilter.includes(s.source))) {
        showNotification({
          title: t({id: 'note.warning', defaultMessage: 'Warning'}),
          message: t({
            id: 'You need to have at least one imagery source selected.',
          }),
          type: 'warning',
        });

        return;
      }
    }

    dispatch(setDatesFilterByType(type, toAdd));
  };

  const formatSourceLabel = (source: string) => {
    switch (source) {
      case 'dron':
        return t({id: 'DRONE'});
      case 'satellite_hd':
        return t({id: 'SATELLITE HR'});
      default:
        return t({id: source.replace('_', ' ').toUpperCase()});
    }
  };

  const openHistogram = (image: any) => {
    setRemoteSensingImage(image);
    openHistogramDialog();
  };
  const {isNoImagery} = imageStatus;

  const renderRow = ({index, style, key}: {key: string; index: number; style: CSSProperties}) => {
    const im: TInfoExt = remoteSensingImages[index];

    const fieldsWithTreeData = wholeFarm.isWholeFarmView && countFieldsWithTreeDataByDay(im);

    const appIcons = fieldsWithTreeData
      ? [{name: 'tree_analysis', count: fieldsWithTreeData}]
      : im.appName?.map(app => ({name: app, count: 0}));

    return (
      <div
        className={cn('remote-sensing-date-row', {
          active: getImageDate(im) === currentDate,
          'hidden-imagery': im.Hidden,
        })}
        key={key}
        style={style}
      >
        {drawDateIcon({
          type: im.Hidden ? 'eye-hidden' : im.Type,
          date: convertTileDateToNormal(im.Date, true),
          nmap: checkNmap(im.Date, im.Type),
          events: {
            iconEvent: () => dispatch(hideDateImage(im.Date, im.Hidden)),
            labelEvent: im.Hidden ? undefined : () => setRemoteSensingImage(im),
            iconLabel: im.Hidden ? t({id: 'Show imagery'}) : t({id: 'Hide imagery'}),
            onEditCloudy: () =>
              isProd() || getGetURLParam('testTeam')
                ? dispatch(dialogToggle(DialogType.editCloudy, true, im))
                : showNotification({
                    title: t({id: 'note.warning', defaultMessage: 'Warning'}),
                    message: t({
                      id: 'note.editCloudyNotAllow',
                      defaultMessage: 'You can only edit cloud cover % on production.',
                    }),
                    type: 'warning',
                  }),
          },
          appIcons: appIcons,
          cloudy: im.Cloud,
          cloudV2: im.CloudV2,
          isAdmin,
          isCloudValueModified: im.cloudValueModified,
        })}

        <div className="dl__item-controls">
          {!wholeFarm.isWholeFarmView && (
            <>
              <div title="Download Geotiff" className="dl__item-control dl__geo-tiff-btn">
                <ReadOnly>
                  <DownloadIcon
                    onClick={() => (!im.Hidden ? openTile(im) : null)}
                    className={cn({
                      'dl__btn-disabled': isReadOnly,
                    })}
                  />
                </ReadOnly>
              </div>

              <div
                title="Histogram"
                onClick={() => openHistogram(im)}
                className="dl__item-control dl__histogram-btn"
              >
                <HistogramIcon />
              </div>
            </>
          )}
        </div>
      </div>
    );
  };

  return (
    <div
      className={cn('section-container remote-sensing-feature', {
        'images-exist': remoteSensingImages.length,
      })}
    >
      <h3 className={'tab-title'}>
        <Flex gap={'10px'}>
          {t({id: 'Data layers'})}
          <SimplePreloader statusKeys={[AsyncStatusType.binaryImageLayerData]} />
        </Flex>
      </h3>

      {!isNoImagery ? (
        <div className={'toggle-compare'}>
          <SelectionControl
            id="toggle-compare"
            type="switch"
            label={t({id: 'Compare / Split view'})}
            name="toggle-compare"
            onChange={val => dispatch(toggleCompare(Boolean(val)))}
            checked={isCompareOn}
          />
          <Ln
            blank
            external
            href={'https://help.flurosense.com/en/articles/4484904-upload-your-data-layers'}
          >
            <FontIcon>help_outline</FontIcon>
          </Ln>
        </div>
      ) : null}

      {!wholeFarm.isWholeFarmView && !isCompareOn ? <UploadLayers isReadOnly={isReadOnly} /> : null}

      {!isCompareOn && !isNoImagery ? (
        <Slider
          id="remote-sensor-cloud-slider"
          className={'cloud-cover-slider'}
          label={t({id: 'Cloud Cover {count}%'}, {count: remoteSensingCloudCover})}
          defaultValue={100}
          max={100}
          min={0}
          discreteTicks={25}
          step={5}
          tickWidth={2}
          value={remoteSensingCloudCover}
          onChange={value => dispatch(remoteSensingSetCloudCoverFilter(value))}
          discrete
        />
      ) : null}

      {Object.keys(currentDates).length ? (
        <div className={'remote-dates-filter'}>
          {sourcesMeta.map((option: any) => {
            const disabled = !option.available;

            const active = !remoteSensingFilterProps.includes(option.source);
            const allSources = sourcesMeta.length === 5;

            return (
              <div
                id={`filter-item-${option.source}`}
                key={option.source}
                className={cn('filter-item', {active, disabled, allSources})}
                onClick={() => (disabled ? null : filterDatesByType(option.source, active))}
              >
                <TypeIcon type={option.source} />
                <div className={'filter-item__name'}>{formatSourceLabel(option.source)}</div>
              </div>
            );
          })}
        </div>
      ) : null}

      {isCompareOn && <Compare />}

      {isCompareOn ? null : remoteSensingImages.length ? (
        <React.Fragment>
          <div className="remote-sensing-list-header">
            <span>{t({id: 'Date'})}</span>
            <span>{t({id: 'Cloud cover'})}</span>
          </div>

          <MultiKeysPressed callback={setNextDate} keys={['Shift', 'ArrowRight']} />
          <MultiKeysPressed callback={setPrevDate} keys={['Shift', 'ArrowLeft']} />
          <div className={'auto-sizer-container'}>
            <AutoSizer>
              {({width, height}) => {
                return (
                  <List
                    rowCount={remoteSensingImages.length}
                    rowHeight={52}
                    width={width}
                    height={height}
                    rowRenderer={renderRow}
                  />
                );
              }}
            </AutoSizer>
          </div>
        </React.Fragment>
      ) : null}

      {!isCompareOn && <DownloadImageLayerDialog onHide={onHideDialog} />}
    </div>
  );
};

export default RemoteSensingList;
