import type {Farm} from 'containers/map/types';
import {AsyncStatusType, RequestStatusRTK} from 'modules/helpers';
import {selectActionAsyncStatus} from 'modules/helpers/selectors';
import type {AppStore} from 'reducers';
import {createSelector} from 'reselect';

const retrieveIdParam = (s: AppStore, id: number) => id;

export const selectFarmsIdsList = (s: AppStore) => s.farms.list;
export const selectFarms = (s: AppStore) => s.farms.entities.farms;
export const selectFields = (s: AppStore) => s.farms.entities.fields;

export const selectFarmsList = createSelector(
  [selectFarms, selectFarmsIdsList],
  (farms, ids): Farm[] => ids.map(id => farms[id])
);

export const selectFarmById = createSelector(
  [selectFarms, retrieveIdParam],
  (farms, farmId) => farms[farmId]
);

export const selectFieldById = createSelector(
  [selectFields, retrieveIdParam],
  (fields, fieldId) => fields[fieldId]
);

export const selectFarmListIsPending = (s: AppStore) => {
  const status = selectActionAsyncStatus(s, AsyncStatusType.farmsList);
  return status === RequestStatusRTK.pending;
};

export const selectDemoFarmsObject = (s: AppStore) => s.farms.demoFarmsObject;

export const selectDemoFarmsListIds = (s: AppStore) => s.farms.demoFarmsIdsList;
