import type {JustifyContentProperty} from 'csstype';

import styled from 'styled-components';
export const AdminContainer = styled.section`
  flex-basis: 0;
  flex-grow: 1;
  height: 100%;
  overflow: auto;
  position: relative;
  background-color: #f7f7f7;
  display: flex;
  flex-direction: column;
`;

export const AdminTitle = styled.h3`
  width: 100%;
  font-size: 23px;
  font-weight: 300;

  &.with-loader {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    .simple-preloader {
      margin-left: 15px;
    }
  }
`;

export const AdminContentWrapper = styled.div`
  margin: 20px;
  overflow: auto;
  flex: 1 0;

  .fluro-data-table {
    height: 100%;
  }

  .md-table-column--header .md-icon {
    font-size: 24px;
  }

  @media screen and (min-width: 1025px) {
    .material-icons.md-icon {
      font-size: 20px;
    }
  }
`;

type AdminControlsProps = {
  justifyContent?: JustifyContentProperty;
  alignItems?: JustifyContentProperty;
};
export const AdminControls = styled.div`
  margin: 20px;
  display: flex;
  justify-content: ${({justifyContent = 'initial'}: AdminControlsProps) => justifyContent};
  align-items: ${({alignItems = 'initial'}: AdminControlsProps) => alignItems};
  flex-wrap: wrap;
  padding: 10px 15px 35px 15px;
  background-color: #fff;
  box-shadow: 0 2px 2px 0 rgb(0 0 0 / 8%), 0 1px 5px 0 rgb(0 0 0 / 5%),
    0 3px 1px -2px rgb(0 0 0 / 2%);
  border-radius: 4px;

  .fluro-tabs-container {
    margin-left: -15px;
    margin-right: -15px;
    margin-bottom: -15px;
    width: calc(100% + 30px);
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;

    &.top-positioned {
      margin-bottom: -36px;
    }

    .tab:first-of-type {
      padding-left: 16px;
    }
  }
`;

type DialogFooterProps = {
  fullWidth?: boolean;
};

export const DialogFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
  ${({fullWidth}: DialogFooterProps) =>
    fullWidth
      ? 'width: 100%; justify-content: space-between; button {margin-left: 0}'
      : 'button {margin-left: 15px;}'}

  button {
    text-transform: uppercase;
  }
`;

export const AdminTitleLoaderWrapper = styled.div`
  overflow: hidden;
  width: 24px;
  height: 24px;
  margin-left: 15px;

  > svg {
    margin: 0;
  }
`;
