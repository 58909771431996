// @ts-nocheck
import {FormattedMessage, t} from 'i18n-utils';
import React from 'react';
import type {
  AnalyticsArablePoint,
  ButtonOnMap,
  CloudV2,
  DrawingEntity,
  Farm,
  Field,
  IInitialMapState,
  ITreeData,
  SaveFieldsAction,
  ReplaceFieldsAction,
  Season,
  TInfoExt,
  TLayersSource,
  TreesTypes,
} from './types';
import {ZoningTab} from './types';
import {Tags as PremiumAppTag} from 'containers/admin/features/types';
import type {Dispatch} from 'redux';

import type {TFeature, TSensor} from 'types';
import {isFeature, isSensor} from 'types';

import {ActionTypes} from './reducer/types';

import {toggleGlobalDialog} from 'modules/global/actions';
import {showNotification} from 'components/notification/notification';
import type {AppStore} from 'reducers';

import {ActivityApi, FarmApi, FieldGeometryApi, KmlApi, SeasonApi, WeatherApi} from '_api';
import {
  getCropLabelById,
  getGetURLParam,
  setFieldIDToURL,
  setGetParamToURL,
  contactSupportTeam,
} from '_utils/pure-utils';
import {geoJsonObjectToPolygon, getFieldIDFromURL, sortFieldsByProp} from '_utils';
import {preselectCompareDate} from '_utils/map-utils';
import {runNutrilogicRecommendation, setZoningTab} from './actions/zoning-actions';
import {loadWholeFarmData} from './actions/whole-farm-actions';
import {populateColors} from '_utils/colors';
import {getClosestDate, preselectSensor} from './utils';

import {getAndSetAreasOfInterestMeanIndex} from './actions/areas-of-interest-actions';

import {handleOpenTS} from './tissue-sampling-upload/reducer';

import moment from 'moment';
import {
  ALLOWED_WHOLE_FARM_FEATURES,
  GLOBAL_APP_DATE_FORMAT,
  GLOBAL_FORMAT_DATE,
  WHOLE_TABLE_VIEW_FEATURES,
} from '_constants';

import {AsyncStatusType, dialogToggle, DialogType, setRequestStatus, Status} from 'modules/helpers';
import type {Messages} from 'containers/info';
import {messages} from 'containers/info';

import type {AppDispatch} from 'store';
import {history} from '_utils/history-utils';
import {geometryCollection} from '@turf/turf';
import {disableDrawShape, disableEdit, enableDrawShape, enableEdit} from './utils/draw';
//nrx
import {doesFieldHasSeasonWithNRx, getDefaultNSettingValues} from 'containers/map/features/nrx';

import {calculateFieldSeason, getFieldSoilLayer, updateSeasonData} from './utils/field';

import {onFieldsUpdated} from '_reducers/crop-performance-filter/field-filter-reducer';
import {
  beforeTreeLayerTypeChanged,
  handleTreeAnalysisSensors,
  loadFieldTreeData,
} from './utils/trees';
import type {FieldPayload} from './features/farm/types';
import {reportError} from 'containers/error-boundary';
import DEMO_FARMS_URLS from '_constants/demo-fields-urls';
import type {ProcessingStatus} from 'components/app-processing-status';
import {selectFarm} from 'modules/farms/actions';
import Mixpanel from '_utils/mixpanel-utils';
import axios from 'axios';
import tokml from '_utils/tokml';
import {enhanceGeometryProperties} from '_utils/geometry';
import {createAsyncThunk} from '@reduxjs/toolkit';
import {selectMeasurement, selectSIAccessSetting, selectIsAdmin} from '../login/login-selectors';
import {SIAccess} from '../login/types';
import {
  selectCurrentFieldId,
  selectCurrentImageObj,
  selectCurrentSensor,
  selectCurrentTab,
  selectIsEditingMode,
  selectIsWholeFarmView,
  selectMapFields,
  selectCurrentDates,
  selectCurrentDate,
  selectCurrentField,
  selectCurrentFarm,
  selectIsWholeTableView,
  selectMapFieldsSorting,
  selectSeasonById,
  selectFieldGeometries,
  selectTreeDetection,
} from './reducer/selectors';
import {selectDialogVisibility} from 'modules/helpers/selectors';
import {selectCropTypes} from 'modules/global/selectors';
import {selectIsAddingFields} from 'modules/add-fields/selectors';
import {selectNrxFertilizerListItemData, selectNrxSeason} from './features/nrx/nrx-selectors';

export const toggleSeasonCheckbox = (seasonID: number, value: boolean) => (dispatch: any) => {
  return dispatch({
    type: ActionTypes.MAP_SEASON_TOGGLE,
    seasonID,
    value,
  });
};

export const toggleAllSeasonsCheckboxes = (value: boolean, onlyLatest = false) => ({
  type: ActionTypes.MAP_SEASONS_TOGGLE,
  value,
  onlyLatest,
});

export const saveFields = (
  farmId: number,
  fieldsById: {[fieldId: number]: Field},
  cropVarietyColors?: {[cropVariety: string]: string}
): SaveFieldsAction => ({
  type: ActionTypes.MAP_SAVE_FIELDS,
  farmId,
  fieldsById,
  cropVarietyColors,
});

export const replaceFields = (
  farmId: number,
  fieldsById: {[fieldId: number]: Field},
  cropVarietyColors?: {[cropVariety: string]: string}
): ReplaceFieldsAction => ({
  type: ActionTypes.MAP_REPLACE_FIELDS,
  farmId,
  fieldsById,
  cropVarietyColors,
});

/**
 * loadFarmsFields() accepts ids of farms and loads fields for them
 */
export const loadFarmsFields = createAsyncThunk(
  'map/loadFarmsFields',
  async (farmIds: number[], thunkAPI) => {
    const response: {[fieldId: number]: Field}[] = await Promise.all(
      farmIds.map(farmId => thunkAPI.dispatch(loadFields2(farmId)))
    );
    return response;
  }
);

export const loadFarmsFieldsWithGeometries = createAsyncThunk(
  'map/loadFarmsFields',
  async (farmIds: number[], thunkAPI) => {
    const fieldsGeometries = selectFieldGeometries(thunkAPI.getState() as AppStore);
    if (!farmIds.length) return;
    const response: {[fieldId: number]: Field}[] = await Promise.all(
      farmIds.map(farmId => thunkAPI.dispatch(loadFields2(farmId)))
    );

    const geometriesToRequest = response.flatMap((groupedByFarm, index) => {
      const farmId = farmIds[index];
      const fieldsWithMissingGeometries: {farmId: number; fieldId: number; md5: string}[] = [];
      Object.values(groupedByFarm).forEach(field => {
        if (!fieldsGeometries[field.MD5]) {
          fieldsWithMissingGeometries.push({
            farmId,
            fieldId: field.ID,
            md5: field.MD5,
          });
        }
      });
      return fieldsWithMissingGeometries;
    });

    if (geometriesToRequest.length) {
      await thunkAPI.dispatch(loadFieldGeometries(geometriesToRequest));
    }

    return response;
  }
);

/**
 * Just loads the fields without any side effects.
 * Used in multifarm crop perf and carbon.
 */
export const loadFields2 = (farmId: number) => (dispatch: Dispatch) => {
  dispatch(setRequestStatus(AsyncStatusType.fieldsData, Status.Pending));
  const fieldsById: {[fieldId: number]: Field} = {};
  return KmlApi.getFileList(farmId)
    .then(({data}) => {
      dispatch(setRequestStatus(AsyncStatusType.fieldsData, Status.Done));
      const {fields} = data.result;
      fields.forEach(f => {
        fieldsById[f.ID] = f;
      });
      const cropVarietyColors = populateColors(fields, {});
      dispatch(replaceFields(farmId, fieldsById, cropVarietyColors));

      return fieldsById;
    })
    .catch(e => {
      reportError(`Couldn't loadFields2 for farmId: ${farmId}. Error: ${e}`);
      dispatch(setRequestStatus(AsyncStatusType.fieldsData, Status.Done));
      return fieldsById;
    });
};

export const loadFields =
  (groupId: number, fieldID: number | string | null = null, shouldShowKmlLayer: boolean = false) =>
  (dispatch: any, getState: () => AppStore) => {
    const map = getState().map;
    dispatch(hardClear());
    dispatch(handleOpenTS(false));
    dispatch(setRequestStatus(AsyncStatusType.loadFields, Status.Pending));
    return KmlApi.getFileList(groupId)
      .then(({data}) => {
        const sortedFields = data.result.fields
          ? sortFieldsByProp(data.result.fields, 'Name', 'string')
          : [];
        const fields = sortedFields.map(f => {
          const season = f.Seasons?.[f.Seasons?.length - 1];
          return {
            ...f,
            GDD: season?.gdd || 0,
            NDVI: season?.ndvi || 0,
            Delta: season?.delta || 0,
          };
        });
        const cropVarietyColors = populateColors(sortedFields, map.cropVarietyColors);

        dispatch({
          type: ActionTypes.MAP_LOAD_FIELDS,
          fields,
          group: data.result.group, // updating group because `fileCount` and `area` got updated
          cropVarietyColors,
        });
        dispatch(onFieldsUpdated(fields));
        if (fieldID) {
          dispatch(setCurrentFieldId(fieldID, true, shouldShowKmlLayer));
          return;
        }

        if (data.result.fields && data.result.fields.length) {
          const fieldIDFromURL = getFieldIDFromURL().fieldId;
          const resultField = sortedFields.find((f: Field) => f.ID === fieldIDFromURL);

          const fieldId =
            fieldIDFromURL === 'WholeFarm'
              ? 'WholeFarm'
              : resultField
              ? resultField.ID
              : sortedFields[0].ID;

          if (!resultField && fieldIDFromURL !== 0 && fieldId !== 'WholeFarm') {
            showNotification({
              title: t({id: 'note.warning', defaultMessage: 'Warning'}),
              message: (
                <FormattedMessage
                  id="theFieldIdNotExist"
                  values={{fieldIDFromURL, a: (txt: string) => contactSupportTeam(txt)}}
                />
              ),
              type: 'warning',
              autoClose: 20000, // the message is long, let it stay longer
            });
          }

          dispatch(setCurrentFieldId(fieldId, true, shouldShowKmlLayer));
        }
      })
      .catch()
      .finally(() => {
        dispatch(setRequestStatus(AsyncStatusType.loadFields, Status.Done));
      });
  };

export const loadFieldData =
  (fieldId: number) => async (dispatch: any, getState: () => AppStore) => {
    const {
      map: {currentDate, group},
    } = getState();

    if (!group.id) {
      showNotification({
        title: t({id: 'note.error', defaultMessage: 'Error'}),
        message: t({id: 'farmIdNotFound'}, {id: group.id}),
        type: 'error',
      });

      return Promise.resolve();
    }

    return KmlApi.getKmlOne(group.id, fieldId)
      .then(async ({data}) => {
        const {kml} = data.result;
        let fieldSoilLayer = null;
        const treeData: Array<ITreeData> = (kml?.tags || []).includes(PremiumAppTag.TreeAnalysis)
          ? await loadFieldTreeData(group.id, fieldId)
          : [];

        if (kml.Country === 'United States') {
          // we have soil map data only in US
          try {
            const soilLayerResult = await KmlApi.loadFieldSoilMap([kml.MD5]);
            fieldSoilLayer = soilLayerResult?.data
              ? getFieldSoilLayer(JSON.parse(soilLayerResult?.data), kml.MD5)
              : null;
          } catch (e) {
            if (!axios.isCancel(e)) {
              reportError(`loadFieldSoilMap() err= ${e}`);
            }
          }
        }

        const cloudV2Data: CloudV2 | undefined =
          getGetURLParam('cloudv2_test') === 'true'
            ? await ActivityApi.getCloudyV2Data(kml.MD5)
            : undefined;

        dispatch({
          type: ActionTypes.MAP_LOAD_FIELD_DATA,
          field: {
            ...kml,
            generatedNMapDates: kml.Properties,
            soilLayer: fieldSoilLayer,
            Seasons:
              kml.Seasons && kml.Seasons.length
                ? updateSeasonData(getState, kml.Seasons, treeData, cloudV2Data)
                : [],
          },
        });

        const urlFeature = getGetURLParam('tab');
        if (urlFeature && isFeature(urlFeature)) {
          // fix issue when the page is loaded with a specific tab in the URL, but app still store the default value
          dispatch(setFeature(urlFeature));
        }
        const urlSensor = getGetURLParam('layer');
        if (urlSensor && isSensor(urlSensor)) {
          setSensor(urlSensor);
        }

        const seasonIdFromUrl = parseInt(getGetURLParam('season'));
        if (kml.Seasons && kml.Seasons.length) {
          const preparedSeason = calculateFieldSeason(kml.Seasons, currentDate);
          if (seasonIdFromUrl) {
            const newSeason = kml.Seasons.find((s: Season) => s.id === seasonIdFromUrl);
            dispatch(selectFieldSeason(newSeason || preparedSeason));
          } else {
            dispatch(selectFieldSeason(preparedSeason));
          }
        }

        if (doesFieldHasSeasonWithNRx(kml) && getGetURLParam('nrx-toggle') !== 'false') {
          dispatch(setZoningTab(ZoningTab.NitrogenRx));
        }
      })
      .catch(err => reportError(`loadFieldData() err= ${err}`));
  };

const setDemoFarmsUrlParams = (
  farmId: number,
  fieldId: number,
  currentFieldUrl: string,
  dispatch: any
) => {
  if (currentFieldUrl) {
    const {history, location} = window;
    history.replaceState(null, null, `${location.origin}${location.pathname}${currentFieldUrl}`);

    const params = new URLSearchParams(currentFieldUrl);

    const messageId = params.get('message') as keyof Messages;
    if (messageId && messages[messageId]) {
      dispatch(dialogToggle(DialogType.info, true, messageId));
    }
  }
};

export const loadHistogramData = () => (dispatch: AppDispatch, getState: () => AppStore) => {
  const state = getState();
  const currentSensor = selectCurrentSensor(state);
  const field = selectCurrentField(state);
  const currentImage = selectCurrentImageObj(state);

  if (currentImage) {
    dispatch(setRequestStatus(AsyncStatusType.histogram, Status.Pending));
    return ActivityApi.getHistogram(currentSensor.toLowerCase(), field.MD5, currentImage.date)
      .then(({data}) => {
        dispatch(setHistogramData(data));
        dispatch(setRequestStatus(AsyncStatusType.histogram, Status.Done));
      })
      .catch(err => {
        dispatch(setRequestStatus(AsyncStatusType.histogram, Status.Todo));
        reportError(`loadHistogramData ERR = ${err}`);
      });
  }

  return Promise.resolve();
};

/**
 * Highlight field in the panel view .
 * Can be triggered by clicking the field boundaries on the map.
 */
export const highlightField = (fieldId?: number | string) => (dispatch: Dispatch) => {
  dispatch({
    type: ActionTypes.MAP_HIGHLIGHT_FIELD,
    fieldId,
  });
};

// TODO: reduce parameters;
export const setCurrentFieldId =
  (fieldId: any, tableView?: boolean, shouldShowKmlLayer?: boolean, farmId?: number) =>
  (dispatch: any, getState: () => AppStore) => {
    const {
      map: {feature, group, treeDetection},
    } = getState();

    if (farmId != null && farmId !== group.id) {
      dispatch(selectFarm(farmId));
    }

    let keepTableView = tableView != null ? tableView : WHOLE_TABLE_VIEW_FEATURES.includes(feature);

    const hasDEmoFarmURL = !!DEMO_FARMS_URLS[`${group.id}/${fieldId}`];

    // if selected farm is demo reset url params
    if (hasDEmoFarmURL) {
      setDemoFarmsUrlParams(group.id, fieldId, DEMO_FARMS_URLS[`${group.id}/${fieldId}`], dispatch);
    }

    setFieldIDToURL(fieldId, history.push);

    dispatch({
      type: ActionTypes.SET_SELECTED_FIELD_ID,
      fieldId,
      keepTableView,
      shouldShowKmlLayer,
    });

    if (fieldId === 'WholeFarm') {
      dispatch(loadWholeFarmData());
      dispatch(toggleFieldGeometries(false));
      dispatch(toggleFieldKml(true));
      setGetParamToURL('season', null);

      const urlFeature = getGetURLParam('tab');
      if (
        urlFeature &&
        isFeature(urlFeature) &&
        (ALLOWED_WHOLE_FARM_FEATURES.includes(urlFeature) || hasDEmoFarmURL)
      ) {
        // fix issue when the page is loaded with a specific tab in the URL, but app still store the default value
        dispatch(setFeature(urlFeature));
      } else if (!(urlFeature === 'zoning' && treeDetection.layerType !== 'default')) {
        dispatch(setFeature('farm'));
      }
    }
  };

export const toggleTableView = (value?: TFeature) => {
  setGetParamToURL('tableView', value ? 'true' : undefined);
  return {
    type: ActionTypes.MAP_TOGGLE_WHOLE_TABLE_VIEW,
    value,
  };
};

export const toggleMapBar =
  (value: boolean) => (dispatch: AppDispatch, getState: () => AppStore) => {
    const addingFields = selectDialogVisibility(getState(), DialogType.addNewField);
    if (addingFields && value) return; //do not open the right panel during adding fields

    dispatch({
      type: ActionTypes.MAP_TOGGLE_BAR,
      value,
    });
  };

export const changeSeason =
  (newSeasonId: number, newSensor = '') =>
  (dispatch: AppDispatch, getState: () => AppStore) => {
    const state = getState();
    const currentSensor = selectCurrentSensor(state);
    const measurement = selectMeasurement(state);
    const seasonInfoExt = selectSeasonById(state, newSeasonId)?.infoExt || [];
    const calculatedSensor = newSensor || getGetURLParam('layer') || currentSensor;
    const field = selectCurrentField(state);
    const defaultSeason = {id: 0, cropType: '', startDate: '', endDate: ''} as Season;
    const season =
      Number(newSeasonId) && field.Seasons?.length
        ? field.Seasons.find(s => s.id === newSeasonId) || field.Seasons[0]
        : defaultSeason;
    const nrxSeason = selectNrxSeason(state, season?.id);
    const productData = selectNrxFertilizerListItemData(
      state,
      String(nrxSeason?.roiSettings?.product)
    );

    const sensorToSet =
      calculatedSensor === 'NONE' && seasonInfoExt.length ? 'NDVI' : (calculatedSensor as TSensor);

    dispatch({
      type: ActionTypes.SET_CURRENT_SEASON,
      currentSeasonId: newSeasonId,
      currentSensor: sensorToSet,
      season,
      nrxRecommendationSettings: getDefaultNSettingValues(nrxSeason, measurement, productData),
    });

    dispatch(setSensor(sensorToSet));
  };

export const loadWeatherData = () => (dispatch: any, getState: () => AppStore) => {
  const {selectedFieldId, field, feature, currentSeason, nRecommendation, zoning} = getState().map;

  if (selectedFieldId === 'WholeFarm') return;
  let {startDate, endDate, cropType} = currentSeason;
  if (!startDate || !endDate)
    return dispatch({
      type: ActionTypes.MAP_SET_TEMPERATURE_DATA,
      data: [],
    });

  dispatch(getArableData());

  startDate = moment(startDate, GLOBAL_FORMAT_DATE).format('YYYYMMDD');
  endDate = moment(endDate, GLOBAL_FORMAT_DATE).format('YYYYMMDD');

  dispatch(setRequestStatus(AsyncStatusType.loadFieldWeather, Status.Pending));

  WeatherApi.getRangeByCropType(field.MD5, startDate, endDate, cropType)
    .then(({data: {weather}}) => {
      dispatch(setRequestStatus(AsyncStatusType.loadFieldWeather, Status.Done));
      Mixpanel.loadWeatherData();
      dispatch({
        type: ActionTypes.MAP_SET_TEMPERATURE_DATA,
        data: weather || [],
      });

      if (
        zoning.tab === ZoningTab.NitrogenRx &&
        feature === 'zoning' &&
        nRecommendation.method === 'nutrilogic'
      ) {
        dispatch(runNutrilogicRecommendation());
      }
    })
    .catch(err => {
      dispatch(setRequestStatus(AsyncStatusType.loadFieldWeather, Status.Todo));

      if (!axios.isCancel(err)) {
        reportError(`loadWeatherData Err ${err}`);
      }
    });
};

export const getArableData = () => async (dispatch: any, getState: () => AppStore) => {
  const {analytics, field} = getState().map;

  let arableSensorData: AnalyticsArablePoint[] = [];

  if (analytics.arableData.length) {
    arableSensorData = analytics.arableData;
  } else {
    dispatch(setRequestStatus(AsyncStatusType.analyticsFieldArableData, Status.Pending));
    arableSensorData = await ActivityApi.getArableDevicesData(field.ID);
    dispatch(setRequestStatus(AsyncStatusType.analyticsFieldArableData, Status.Done));
  }

  if (!analytics.arableData.length) {
    dispatch({
      type: ActionTypes.MAP_SET_ARABLE_SENSOR_DATA,
      data: arableSensorData,
    });
  }

  return arableSensorData;
};

export const setDate = (date: string) => (dispatch: AppDispatch, getState: () => AppStore) => {
  getGetURLParam('layerDate') !== date && setGetParamToURL('layerDate', date);

  const currentDate = selectCurrentDate(getState());
  if (currentDate === date) return;

  if (date) {
    Mixpanel.changeDate(moment(date, GLOBAL_APP_DATE_FORMAT).format(GLOBAL_FORMAT_DATE));
  }

  dispatch({
    type: ActionTypes.MAP_SET_CURRENT_DATE,
    date,
  });

  // Update Areas of Interest mean index.
  dispatch(getAndSetAreasOfInterestMeanIndex());
};

export const setClosestDate = () => (dispatch: AppDispatch, getState: () => AppStore) => {
  const state = getState();
  const currentDate = selectCurrentDate(state);
  const currentDates = selectCurrentDates(state);
  const dateToSet = getClosestDate(currentDate, currentDates);
  dispatch(setDate(dateToSet));
};

export const setSensor = (sensor: TSensor) => (dispatch: any, getState: () => AppStore) => {
  const {currentSensor, currentDate, currentDates} = getState().map;
  dispatch(handleTreeAnalysisSensors(sensor));
  const sensorToSet = preselectSensor(currentDate, currentDates, sensor);

  setGetParamToURL('layer', sensorToSet);
  if (currentSensor === sensorToSet) return true;

  dispatch({
    type: ActionTypes.MAP_SET_SENSOR,
    sensor: sensorToSet,
  });

  // dispatch(setHistogramOptions('range', preselectRange(sensorToSet)));

  // Update Areas of Interest mean index.
  dispatch(getAndSetAreasOfInterestMeanIndex());

  return true;
};

export const setCompareDate = (date: any) => ({
  type: ActionTypes.MAP_SET_COMPARE_DATE,
  date,
});

export const setSensorCompare = (sensor: TSensor) => ({
  type: ActionTypes.MAP_SET_SENSOR_COMPARE,
  sensor,
});
export const toggleCompare = (value: boolean) => (dispatch: any, getState: () => AppStore) => {
  const map = getState().map;
  const currentCompareValue = map.isCompareOn;

  if (value !== currentCompareValue) {
    dispatch({
      type: ActionTypes.MAP_TOGGLE_COMPARE,
      value,
    });
  }

  value && dispatch(setCompareDate(preselectCompareDate(map.currentDates, map.currentDate)));
};

export const toggleFieldGeometries = (value: boolean) => ({
  type: ActionTypes.MAP_TOGGLE_GEOMETRIES_ON_MAP,
  value,
});

export const toggleFieldKml = (value: boolean) => ({
  type: ActionTypes.MAP_TOGGLE_FIELD_KML,
  value,
});

//TODO: refactor the function cuz it is to smart
export const setFeature =
  (newFeature: TFeature, updateTab: boolean = true) =>
  (dispatch: AppDispatch, getState: () => AppStore) => {
    const state = getState();
    const feature = selectCurrentTab(state);

    if (newFeature === feature) {
      return;
    }

    if (feature === 'log-out') {
      Mixpanel.logout();
    } else {
      Mixpanel.tabChange(newFeature);
    }

    // do not allow set SI feature if SI is OFF
    const siAccessSetting = selectSIAccessSetting(state);
    if (newFeature === 'sustainability-insights' && siAccessSetting === SIAccess.Off) {
      setGetParamToURL('tab', feature);
      return;
    }

    if (updateTab) {
      setGetParamToURL('tab', newFeature);
    }

    dispatch({
      type: ActionTypes.MAP_SET_FEATURE,
      feature: newFeature,
    });
  };

export const onTabClick = (tab: TFeature) => (dispatch: AppDispatch, getState: () => AppStore) => {
  const state = getState();
  const isWholeFarmView = selectIsWholeFarmView(state);
  const fields = selectMapFields(state);
  const treeDetection = selectTreeDetection(state);
  const isAddingFields = selectIsAddingFields(state);
  const isEditingMode = selectIsEditingMode(state);

  const isNotWholeFarmFeature = (feature: TFeature) => {
    const includes = ALLOWED_WHOLE_FARM_FEATURES.includes(feature);
    if (feature === 'zoning' && treeDetection.layerType !== 'default') return false; // allow whole farm zoning for tree detection
    return isWholeFarmView && !includes;
  };

  let message = '';

  if (tab !== 'log-out' && isAddingFields) {
    showNotification({
      title: t({id: 'Not allowed'}),
      message: t({id: 'You cannot change tabs while adding fields'}),
      type: 'warning',
    });
    return;
  }

  if (tab !== 'log-out' && isEditingMode) {
    showNotification({
      title: t({id: 'Not allowed'}),
      message: t({id: 'You cannot change tabs while editing a field'}),
      type: 'warning',
    });
    return;
  }

  if (isNotWholeFarmFeature(tab)) {
    dispatch(setCurrentFieldId(fields[0].ID));
    dispatch(setFeature(tab));
    message = t({id: 'You can only use this tab when one field is selected'});
  } else {
    return dispatch(setFeature(tab));
  }

  if (message) {
    showNotification({
      title: t({id: 'Not allowed'}),
      message,
      type: 'warning',
    });
  }
};

// -------- fields section --------

export const sortFields = (byProp: string, sortType = 'number', descending?: boolean) => ({
  type: ActionTypes.MAP_SORT_FIELDS,
  byProp,
  sortType,
  descending,
});

// -------- fields section end --------

export const setHistogramData = (data: {bins: number[]; percentage: number[]}) => ({
  type: ActionTypes.MAP_SET_HISTOGRAM_DATA,
  bins: data.bins,
  percentages: data.percentage,
});

export const setHistogramOptions = (t: any, value: any) => ({
  type: ActionTypes.MAP_SET_HISTOGRAM_OPTIONS,
  t,
  value,
});

export const setSchema = (value: string) => ({
  type: ActionTypes.MAP_SET_COLOR_SCHEMA,
  value,
});

export const setImageOverlayOpacity = (value: number | string) => ({
  type: ActionTypes.MAP_SET_IMAGE_OVERLAY_OPACITY,
  value,
});

export const setNitrogenScaleUrl = (url: string) => ({
  type: ActionTypes.MAP_SET_NITROGEN_SCALE_URL,
  url,
});

export const setNitrogenMarker = (position: any, value: any) => ({
  type: ActionTypes.MAP_SET_NITROGEN_MARKER,
  position,
  value,
});

export const removeNitrogenMarker = (index: any) => ({
  type: ActionTypes.MAP_REMOVE_NITROGEN_MARKER,
  index,
});

export const setKmlCoordinates = (data: any) => (dispatch: Dispatch<any>) => {
  const geoJson =
    data.length > 1
      ? geometryCollection(geoJsonObjectToPolygon(data, true), data[0].properties)
      : geoJsonObjectToPolygon(data)[0];

  dispatch({
    type: ActionTypes.MAP_SET_FIELD_KML,
    data: geoJson,
  });
};

export const imageSensingSetCurrent = (image: TInfoExt) => ({
  type: ActionTypes.MAP_REMOTE_SENSING_SET_CURRENT,
  image,
});

export const imageSensingToggleDialog = (value: boolean) => ({
  type: ActionTypes.MAP_REMOTE_SENSING_TOGGLE_DIALOG,
  value,
});

export const remoteSensingOnChangeLayer = (layer: string, value: boolean) => ({
  type: ActionTypes.MAP_REMOTE_SENSING_ON_CHANGE_LAYER,
  value,
  layer,
});

export const remoteSensingSetCloudCoverFilter = (value: any) => ({
  type: ActionTypes.MAP_REMOTE_SENSING_SET_CLOUD_COVER,
  value,
});

export const hideDateImage =
  (date: string, value: number) => (dispatch: any, getState: () => AppStore) => {
    const {group, field, wholeFarm, fields} = getState().map;

    const flag = value ? 0 : 1;
    const kmls = wholeFarm.isWholeFarmView ? fields.map((f: Field) => f.ID) : [field.ID];

    return KmlApi.hideTile(group.id, field.ID, {kmlid: kmls, date, flag})
      .then(() => {
        showNotification({
          title: t({id: 'note.success', defaultMessage: 'Success'}),
          message: value ? t({id: 'The image was shown.'}) : t({id: 'The image was hidden.'}),
          type: 'success',
        });

        dispatch({
          type: ActionTypes.MAP_REMOTE_SENSING_TOGGLE_HIDDEN,
          date,
          value: flag,
        });
      })
      .catch();
  };

export const setDatesFilterByType =
  (filter: string, toAdd: boolean) => (dispatch: Dispatch<any>) => {
    dispatch({
      type: ActionTypes.MAP_REMOTE_SENSING_SET_FILTER,
      filter,
      toAdd,
    });
  };

export const onChangeLayerOption = (
  prop: keyof AppStore['map']['remoteSensingLayersOptions'],
  value: boolean
) => ({
  type: ActionTypes.MAP_REMOTE_SENSING_TOGGLE_OPTION,
  value,
  prop,
});

export const setNitrogenOverlayUrl = (url: string) => ({
  type: ActionTypes.MAP_SET_NITROGEN_OVERLAY_URL,
  url,
});

export const hardClear = (setInitialState = false) => ({
  type: ActionTypes.MAP_HARD_CLEAR_STATE,
  setInitialState,
});

export const removeField =
  (groupId: any, kmlIds: Array<any> = [], isWorkspaceMrv = false) =>
  (dispatch: any, getState: () => AppStore) => {
    return Promise.all(kmlIds.map(kmlId => KmlApi.dropField(groupId, kmlId)))
      .then(() => {
        const {fields, field} = getState().map;
        const updatedFields = fields.filter((field: Field) => !kmlIds.includes(field.ID));
        const isCurrentFieldRemoved = kmlIds.includes(field.ID);
        const newFieldToSet = isCurrentFieldRemoved
          ? updatedFields.length
            ? updatedFields[0].ID
            : ''
          : field.ID;

        showNotification({
          title: t({id: 'note.success', defaultMessage: 'Success'}),
          message: t({id: 'Field was removed.'}),
          type: 'success',
        });

        dispatch({
          type: ActionTypes.MAP_DELETE_FIELD,
          kmlId: kmlIds,
          farmId: groupId,
        });

        if (!isWorkspaceMrv) {
          if (!newFieldToSet) {
            dispatch(hardClear());
          } else {
            dispatch(setCurrentFieldId(newFieldToSet, true, false));
          }
        }

        dispatch(dialogToggle(DialogType.deleteDialog, false));
      })
      .catch();
  };

export const addNewField = (farmId: number, field: Field | Field[]) => {
  Mixpanel.addNewField(field);

  return {
    type: ActionTypes.MAP_ADD_NEW_FIELD,
    farmId,
    field,
  };
};

export const saveFieldName = (name: string, groupId: any, kmlId: any) => (dispatch: any) => {
  return KmlApi.saveData(groupId, kmlId, {name}).then(() => {
    showNotification({
      title: t({id: 'note.success', defaultMessage: 'Success'}),
      message: t({id: 'Field Name was changed.'}),
      type: 'success',
    });

    dispatch({
      type: ActionTypes.MAP_CHANGE_FIELD_NAME,
      name,
      kmlId,
    });
  });
};

export const toggleDrawingMode =
  (value: any, layerType: string, entityType?: DrawingEntity) => (dispatch: AppDispatch) => {
    dispatch({
      type: ActionTypes.MAP_TOGGLE_DRAWING_MODE,
      value,
      layerType,
      entityType,
    });

    value ? enableDrawShape(layerType) : disableDrawShape(layerType);
  };

export const toggleEditingMode = (value: boolean) => (dispatch: any) => {
  value ? enableEdit() : disableEdit();

  dispatch({
    type: ActionTypes.MAP_TOGGLE_EDITING_MODE,
    value,
  });
};

export const toggleEditingMode2 = (value: boolean, fieldId?: number) => (dispatch: any) => {
  dispatch({
    type: ActionTypes.MAP_TOGGLE_EDITING_MODE,
    value,
    fieldId,
  });
};

export const loadFieldGeometries =
  (fields: {farmId: Farm['id']; fieldId: Field['ID']; md5: Field['MD5']}[]) =>
  async (dispatch: Dispatch) => {
    const md5s = fields.map(f => f.md5);

    dispatch(setRequestStatus(AsyncStatusType.fieldGeometries, Status.Pending));
    const fieldGeometries = await FieldGeometryApi.featureCollections(md5s).then(
      ({data}) => data.data || {}
    );
    dispatch(setRequestStatus(AsyncStatusType.fieldGeometries, Status.Done));

    if (!fieldGeometries) {
      reportError(`No field geometries for md5s ${md5s} at data2/fields api`);
      return;
    }

    const md5ToKmlFieldId: Record<string, number> = {};
    fields.forEach(f => {
      if (fieldGeometries[f.md5]) {
        enhanceGeometryProperties(fieldGeometries[f.md5], f.farmId, f.fieldId);
      }
      md5ToKmlFieldId[f.md5] = f.fieldId;
    });

    dispatch(updateFieldGeometries(fieldGeometries));
    return fieldGeometries;
  };

export const updateFieldGeometries = (fieldGeometries: {
  [md5: string]: GeoJSON.FeatureCollection;
}) => ({
  type: ActionTypes.MAP_SET_FIELD_GEOMETRIES,
  fieldGeometries,
});

/**
 * Toggle popup for areas of interest and anomalies (both low perf and premium).
 */
export const togglePopup =
  (id?: number, type: 'anomaly' | 'crop-performance' = 'anomaly') =>
  (dispatch: any, getState: () => {map: IInitialMapState}) => {
    const {openPopupId} = getState().map;
    if (type === 'anomaly') {
      setGetParamToURL('anomaly', !id || id === openPopupId ? undefined : id);
    }
    dispatch({
      type: ActionTypes.MAP_TOGGLE_POPUP,
      id,
    });
  };

export const toggleMapButton =
  (value: ButtonOnMap | false) => (dispatch: any, getState: () => AppStore) => {
    const {toggledButtonOnMap} = getState().map;
    dispatch({
      type: ActionTypes.MAP_TOGGLE_BUTTON_ON_MAP,
      value: toggledButtonOnMap === value ? 'Empty' : value,
    });
  };

export const changeLayersSource = (value: TLayersSource) => ({
  type: ActionTypes.MAP_TOGGLE_LAYER_SOURCE,
  value,
});

export const toggleSoilMapLayer = (value: boolean) => ({
  type: ActionTypes.MAP_TOGGLE_SOIL_MAP_LAYER,
  value,
});

export const toggleFieldCheckbox = (fieldId: number | string, value: boolean) => ({
  type: ActionTypes.MAP_TOGGLE_FIELD_CHECKBOX,
  fieldId,
  value,
});

export const toggleAllFieldsCheckboxes =
  (value: boolean, includedIds?: Array<number>) =>
  (dispatch: any, getState: () => {map: IInitialMapState}) => {
    const {fields} = getState().map;
    const filteredFields = includedIds
      ? fields.filter((f: Field) => includedIds.includes(f.ID))
      : fields;

    return dispatch({
      type: ActionTypes.MAP_TOGGLE_ALL_FIELDS_CHECKBOX,
      items: filteredFields.map((field: Field) => field.MD5),
      value,
    });
  };

export const selectFieldSeason = (season: Season) => (dispatch: any, getState: () => AppStore) => {
  const currentFieldId = selectCurrentFieldId(getState());

  if (currentFieldId === season.kmlId) {
    dispatch(changeSeason(season.id));
    setGetParamToURL('season', season.id);
  }

  dispatch({
    type: ActionTypes.MAP_CHANGE_FIELD_SEASON,
    season,
  });
};

export const bulkSelectFieldsSeasons =
  (seasons: Season[]) => (dispatch: AppDispatch, getState: () => AppStore) => {
    const currentFieldId = selectCurrentFieldId(getState());
    const currentFieldSeason = seasons.find(s => s.kmlId === currentFieldId);

    if (currentFieldSeason) {
      dispatch(changeSeason(currentFieldSeason.id));
      setGetParamToURL('season', currentFieldSeason.id);
    }

    dispatch({
      type: ActionTypes.MAP_BULK_CHANGE_FIELD_SEASON,
      seasons,
    });
  };

export const saveBulkCrop =
  (farmId: any, cropData: any[], allowOverlapping = true, silent = false) =>
  () => {
    Mixpanel.addNewSeason(cropData);
    return SeasonApi.saveBulkCrop(farmId, cropData, allowOverlapping)
      .then(r => {
        if (!silent) {
          showNotification({
            title: t({id: 'note.success', defaultMessage: 'Success'}),
            message: t({id: 'Your crop details were saved successfully.'}),
            type: 'success',
          });
        }
        return r.data?.result;
      })
      .catch(err => {
        if (
          typeof err?.data?.result === 'string' && // because in some cases (a wrong start/end date) the back returns {msg: string, bulk_index: number, season_index: number}
          !err?.data?.result?.includes('season dates overlap with another season')
        ) {
          reportError(`saveBulkCrop() err= ${err}`); // show the error only if it is really error
        }

        return Promise.reject(err);
      });
  };

export const saveBulkCropAndSync =
  (farmId: any, cropData: any[], allowOverlapping = true, silent = false) =>
  async (dispatch: Dispatch<any>, getState: () => AppStore) => {
    const {wholeTableViewOpen} = getState().map;
    const effectedFieldsIds = cropData.map(data => data.kml_id);

    await dispatch(saveBulkCrop(farmId, cropData, allowOverlapping, silent));

    KmlApi.getFileListByIDs(farmId, effectedFieldsIds, true)
      .then(({data}) => dispatch(syncFieldSeasons(farmId, cropData, data)))
      .then(() => (wholeTableViewOpen ? dispatch(toggleAllFieldsCheckboxes(false)) : null));
  };

export const saveFieldData =
  (fieldData: any, groupId: number, kmlId: number) => (dispatch: any) => {
    return KmlApi.saveData(groupId, kmlId, fieldData)
      .then(() => {
        dispatch({
          type: ActionTypes.MAP_CHANGE_FIELD_DATA,
          fieldData,
          kmlId,
        });
        showNotification({
          title: t({id: 'note.success', defaultMessage: 'Success'}),
          message: t({id: 'Field data was successfully saved.'}),
          type: 'success',
        });
      })
      .catch();
  };

export const saveBulkFieldData =
  (type: string, value: any, farmId: number, selectedKmlIds: number[]) => (dispatch: any) => {
    return Promise.all(
      selectedKmlIds.map((kmlId: number) => KmlApi.saveData(farmId, kmlId, {[type]: value}))
    )
      .then(() => {
        dispatch({
          type: ActionTypes.MAP_BULK_CHANGE_FIELD_DATA,
          fieldData: {[type]: value},
          selectedKmlIds,
          farmId,
        });

        showNotification({
          title: t({id: 'note.success', defaultMessage: 'Success'}),
          message: t({id: '{type} was successfully saved'}, {type}),
          type: 'success',
        });

        dispatch(toggleAllFieldsCheckboxes(false));
      })
      .catch();
  };

export const deleteSeason =
  (groupId: any, kmlId: any, seasonId: number) => (dispatch: any, getState: () => AppStore) => {
    const {selectedFieldId, field} = getState().map;

    return SeasonApi.deleteSeason(groupId, kmlId, seasonId)
      .then(() => {
        const restSeasons = field.Seasons.filter((s: Season) => s.id !== seasonId);

        const seasonToSet = restSeasons.length
          ? (field.SeasonID !== seasonId &&
              field.Seasons.find((s: Season) => s.id === field.SeasonID)) ||
            field.Seasons[restSeasons.length - 1]
          : {
              kmlId,
              uiName: '',
              cropType: '',
              startDate: '',
              endDate: '',
              id: 0,
            };

        dispatch({
          type: ActionTypes.MAP_DELETE_SEASON,
          seasons: restSeasons,
          kmlId,
        });

        dispatch({
          type: ActionTypes.MAP_CHANGE_FIELD_SEASON,
          season: seasonToSet,
        });

        if (selectedFieldId === field.ID) {
          dispatch(changeSeason(seasonToSet.id));
        }

        showNotification({
          title: t({id: 'note.success', defaultMessage: 'Success'}),
          message: t({id: 'Your crop was deleted successfully.'}),
          type: 'success',
        });

        if (selectIsWholeFarmView(getState())) {
          dispatch(loadWholeFarmData());
        }
      })
      .catch();
  };

export const deleteBulkSeason =
  (groupId: number, seasons: Season[], fields?: Field[]) => (dispatch: any) => {
    const requests: Promise<any>[] = [];
    seasons.map(s => requests.push(SeasonApi.deleteSeason(groupId, s.kmlId, s.id)));

    return Promise.all(requests)
      .then(() => {
        const changedFields = fields.filter(f => [...seasons.map(s => s.kmlId)].includes(f.ID));

        changedFields.forEach(f => {
          const restSeasons = f.Seasons.filter(
            (s: Season) => ![...seasons.map(s => s.id)].includes(s.id)
          );
          dispatch({
            type: ActionTypes.MAP_DELETE_SEASON,
            seasons: restSeasons,
            kmlId: f.ID,
          });
        });

        showNotification({
          title: t({id: 'note.success', defaultMessage: 'Success'}),
          message: t({id: 'deleteSeasonPlural'}, {count: seasons?.length || 0}),
          type: 'success',
        });
      })
      .catch(err => reportError(`deleteBulkSeason() err= ${err}`));
  };

export const syncFieldSeasons =
  (farmId: number, newData: any, resultFields: any) =>
  (dispatch: AppDispatch, getState: () => AppStore) => {
    const state = getState();
    const currentField = selectCurrentField(state);
    const farm = selectCurrentFarm(state);
    const wholeTableViewOpen = selectIsWholeTableView(state);
    const {descending} = selectMapFieldsSorting(state);
    const cropTypes = selectCropTypes(state);

    const getFieldOptions = (season: Season, fieldSeasons: Array<Season>) => {
      const {
        kmlId: ID,
        id: SeasonID,
        cropType,
        startDate: PlantingDate,
        endDate: HarvestDate,
        params,
        uiName: CropName,
        ndvi: NDVI = 0,
        delta: Delta = 0,
        gdd: GDD = 0,
      } = season;
      return {
        ID,
        CropName,
        CropSubtype: params ? params.cropSubType : '',
        CropType: getCropLabelById(cropTypes, cropType),
        PlantingDate,
        HarvestDate,
        SeasonID,
        Delta,
        NDVI,
        GDD,
        Seasons: fieldSeasons,
      };
    };

    const result = newData.map((field: any) => {
      const fieldId = field.kml_id;
      const changedSeason = field.seasons[0]; // ToDo add multiple seasons support
      const currentChangedLoopField = resultFields.result.fields.find(
        (f: Field) => f.ID === fieldId
      );
      if (currentChangedLoopField.Seasons.length) {
        const isChangedSeason = changedSeason.id
          ? currentChangedLoopField.Seasons.find((s: Season) => s.id === changedSeason.id)
          : currentChangedLoopField.Seasons.reduce((acc: any, s: Season) =>
              s.id > acc.id ? s : acc
            ); // way to get newest seasons by season.id property

        if (fieldId === currentField.ID) {
          SeasonApi.getSeasonList(farm.id, currentField.ID).then(({data}) => {
            dispatch({
              type: ActionTypes.MAP_ADD_SEASON,
              seasons: data.result || [],
            });

            dispatch(changeSeason(isChangedSeason.id));
          });
        }

        return {
          ...getFieldOptions(isChangedSeason, currentChangedLoopField.Seasons),
        };
      }

      return {};
    });

    // Fetch the fields into the store that's used in crop performance.
    // Possibly we need to replace everything above with just this.
    dispatch(loadFields2(farmId));

    dispatch({
      type: ActionTypes.MAP_SYNC_FIELD_SEASONS,
      fields: result || [],
    });
    dispatch(sortFields(wholeTableViewOpen ? 'CropType' : 'Name', 'string', descending));
  };

export const setTreeLayerType = (layer: TreesTypes) => (dispatch: any) => {
  dispatch(beforeTreeLayerTypeChanged(layer));

  // set selected value to the url
  setGetParamToURL('treeDetectionLayerType', `${layer}`);

  return dispatch({
    type: ActionTypes.MAP_SET_TREE_LAYER_TYPE,
    layer,
  });
};

export const goToSetUpCrop = () => (dispatch: any) => {
  dispatch(setFeature('farm'));
};

export const addPivotMarker =
  (formValues: FieldPayload, tableView = false) =>
  (dispatch: any, getState: () => AppStore) => {
    const state = getState();
    const field = selectCurrentField(state);
    const isWholeFarmView = selectIsWholeFarmView(state);

    const {
      global: {dialogsState},
    } = getState();
    const dialog = dialogsState.editField?.visible ? 'editField' : 'editPivotTableView';
    const fieldId = dialogsState[dialog]?.fieldId;
    if (fieldId && (field.ID !== fieldId || isWholeFarmView)) {
      dispatch(setCurrentFieldId(fieldId));
    }

    tableView && dispatch(toggleTableView());
    dispatch(toggleGlobalDialog(dialog, {visible: true, ...formValues})); // save current form values in the store
    setTimeout(
      () => {
        dispatch(toggleDrawingMode(true, 'marker', 'pivot'));
      },
      isWholeFarmView || !field.SeasonID ? 1000 : 0
    );
  };

export const cancelDrawingPivotCenter = () => (dispatch: any, getState: () => AppStore) => {
  const {
    dialogsState: {editField, editPivotTableView},
  } = getState().global;
  const openDialog = editField?.visible
    ? 'editField'
    : editPivotTableView?.visible
    ? 'editPivotTableView'
    : false;

  if (openDialog) {
    dispatch(toggleGlobalDialog(openDialog, {visible: false}, true));
    dispatch(toggleDrawingMode(false, 'marker'));
  }
};

export const setAppProcessingStatus =
  (value: ProcessingStatus) => (dispatch: any, getState: () => AppStore) => {
    const currentProcessingStatus = getState().map.appProcessingStatus;
    if (value !== currentProcessingStatus) {
      dispatch({
        type: ActionTypes.MAP_SET_APP_PROCESSING_STATUS,
        value,
      });
    }
  };

export const startDrawShape =
  (shape = 'polygon', entityType?: DrawingEntity) =>
  (dispatch: any) => {
    dispatch(toggleMapBar(false));
    dispatch(toggleDrawingMode(true, shape, entityType));
  };

export const clearOriginalLayerDateFormURL = () => ({
  type: ActionTypes.CLEAR_ORIGINAL_LAYER_DATE_FROM_URL,
});

export const updateImageCloudy = (date: string, value: number) => ({
  type: ActionTypes.UPDATE_IMAGE_CLOUDY,
  date,
  value,
});

export const setLocationMarkerCoordinates = (lat: number, lon: number) => ({
  type: ActionTypes.SET_LOCATION_MARKER_COORDINATES,
  markerPosition: [lat, lon],
});

export const saveEditField =
  (data: GeoJSON.Feature) => async (dispatch: any, getState: () => AppStore) => {
    try {
      const s = getState();
      const isAdmin = selectIsAdmin(getState()); // admins can upload big fields

      const result = await FarmApi.saveFields(
        [
          {
            farm_id: s.global.currentGroupId,
            name: s.map.field.Name,
            kml: tokml(data),
          },
        ],
        isAdmin
      );

      await KmlApi.dropField(s.global.currentGroupId, s.map.field.ID);

      await dispatch(loadFields(s.global.currentGroupId, result.data.result[0].field.ID, true));
    } catch (e) {}
  };

export const resetSensitiveParams = () => ({
  type: ActionTypes.RESET_FARM_SENSITIVE_PARAMS,
});

/*
 *
 * The below action should be used only at carbon app,
 * where one source of truth is fieldsByFarmId object
 *
 * */
export const replaceFieldAtFieldsByFarmId = (farmId: number, fieldId: number, field: Field) => ({
  type: ActionTypes.REPLACE_FIELD_AT_FIELDS_BY_FARM_ID,
  farmId,
  fieldId,
  field,
});
