import type {Scale} from 'chroma-js';
import {Flex} from 'components';
import {Range} from 'rc-slider';
import type {ChangeEvent, ComponentType} from 'react';
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {main_gray_400} from '_utils/colors';
import {Bars} from './bars';
import './fluro-rheostat.scss';

const CHAR_WIDTH = 9;

type Props = {
  name: string;
  data: number[];
  min: number;
  max: number;
  color?: string;
  barsCount?: number;
  inputChar?: string;
  colorScale: Scale;
  onChange?: (values: [number, number]) => void;
};

const calculatePitPoints = ({
  data,
  step,
  min,
  max,
}: {
  data: number[];
  step: number;
  min: number;
  max: number;
}): {id: number; value: number}[] => {
  const stepPoints = (() => {
    let current = min;
    const results = [];
    while (current < max + step) {
      results.push(current);
      current = current + step;
    }
    return results;
  })();

  const pitPoints = data
    .reduce((acc, num) => {
      const currentStep = stepPoints.findIndex(from => {
        const to = from + step;
        return num >= from && num < to;
      });
      acc[currentStep] = (acc[currentStep] || 0) + 1;
      return acc;
    }, new Array(stepPoints.length).fill(0))
    .map((value, index) => {
      const id = stepPoints[index];
      return {id, value};
    });

  return pitPoints;
};

const RangeInput: ComponentType<any> = ({addon = null, ...restProps}) => {
  return (
    <Flex className="fluro-rheostat__input">
      <input type="number" {...restProps} />
      {addon}
    </Flex>
  );
};

export const FluroRheostat: ComponentType<Props> = ({
  name,
  data,
  min,
  max,
  barsCount = 20,
  color,
  inputChar = '',
  onChange,
  colorScale,
}) => {
  const themeColor = useMemo(() => {
    if (color) return color;
    return colorScale(1).css();
  }, [color, colorScale]);
  const [values, setValues] = useState<[string, string]>([String(min), String(max)]);
  const numValues = useMemo(() => {
    return values.map(v => Number(v));
  }, [values]);

  const handleChange = useCallback((valuesUpdate: [number, number]) => {
    if (
      typeof onChange !== 'function' ||
      !(Number.isFinite(valuesUpdate[0]) && Number.isFinite(valuesUpdate[1]))
    )
      return;

    // @ts-expect-error error leftover from convertion to strict mode, please fix
    const update: [number, number] = [Number(valuesUpdate[0]), Number(valuesUpdate[1])].sort(
      (a, b) => a - b
    );
    onChange(update);
    // FIXME: this might cause logic breakage if user component relies on the `onChange`'s prop updates
  }, []);

  useEffect(() => {
    setValues([String(min), String(max)]);
    // FIXME: to prevent double calculation, the new range value
    //  should be calculated prior to render on each change of filters, data display parameters etc
    handleChange([min, max]);
  }, [min, max, handleChange]);

  const step = Math.ceil(Math.abs(max - min) / barsCount);
  const barsData = useMemo(
    () => calculatePitPoints({data, step, min, max}),
    [data, step, min, max]
  );

  const getColor = ({data: {id: value}}: {data: {id: number}}) => {
    const [minValue, maxValue] = values;
    const rangeMin = Number(minValue);
    const rangeMax = Number(maxValue);
    const currentMin = value;
    const currentMax = value + step;

    const isActive =
      (rangeMin <= currentMin && rangeMax >= currentMax) ||
      (rangeMin >= currentMin && rangeMin <= currentMax) ||
      (rangeMax >= currentMin && rangeMax <= currentMax);

    if (!isActive) return main_gray_400;
    const scale = Math.sign(value);
    const color = colorScale(scale).css();
    return color;
  };

  const handleRangeInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const {name, value} = event.target;

    const newValues: [string, string] = [...values];
    newValues[name === 'min' ? 0 : 1] = value;
    setValues(newValues);
  };

  const handleRangeInputBlur = (event: ChangeEvent<HTMLInputElement>) => {
    const {name, value: stringValue} = event.target;
    const newValues: [string, string] = [...values];
    newValues[name === 'min' ? 0 : 1] = stringValue;
    const updateValues: [number, number] = [Number(newValues[0]), Number(newValues[1])];
    handleChange(updateValues);
  };

  const handleRangeChange = (range: number[]) => {
    const [from, to] = range;
    setValues([String(from), String(to)]);
    handleChange([from, to]);
  };

  return (
    <>
      <div className="fluro-rheostat__bars">
        <Bars key={name} data={barsData} colors={getColor} min={min} max={max} />
      </div>
      <div className="mb-3 fluro-rheostat__slider">
        <Range
          min={min}
          max={max}
          value={numValues}
          onChange={handleRangeChange}
          pushable
          trackStyle={[{backgroundColor: themeColor}]}
          handleStyle={[
            {backgroundColor: themeColor, borderColor: themeColor},
            {backgroundColor: themeColor, borderColor: themeColor},
          ]}
        />
      </div>
      <Flex justifyContent="space-between" nowrap>
        <span
          className="fluro-rheostat__input-wrapper"
          style={{
            minWidth: `${
              (String(values[0]).length + String(inputChar).length) * CHAR_WIDTH + 14
            }px`,
            maxWidth: '50%',
          }}
        >
          <RangeInput
            name="min"
            onChange={handleRangeInputChange}
            onBlur={handleRangeInputBlur}
            style={{themeColor, width: String(values[0]).length * CHAR_WIDTH}}
            value={values[0]}
            addon={inputChar}
          />
        </span>
        <span
          className="fluro-rheostat__input-wrapper"
          style={{
            minWidth: `${
              (String(values[1]).length + String(inputChar).length) * CHAR_WIDTH + 14
            }px`,
            maxWidth: '50%',
          }}
        >
          <RangeInput
            name="max"
            onChange={handleRangeInputChange}
            onBlur={handleRangeInputBlur}
            style={{themeColor, width: String(values[1]).length * CHAR_WIDTH}}
            value={values[1]}
            addon={inputChar}
          />
        </span>
      </Flex>
    </>
  );
};
