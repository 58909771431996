import React, {useState} from 'react';
import {batch} from 'react-redux';
import {useAppDispatch} from '_hooks';
import {genKey} from '_utils/pure-utils';
import {SelectionControlGroup} from 'react-md';
import {Flex, FluroButton, FluroDialog, InfoBlock, Text, UploadFileZone} from 'components';
import {showNotification} from 'components/notification/notification';
import {addSubsectionGeometries} from 'containers/si/module/reducer';
import SIApi from 'containers/si/api/si';
import './si-map.scss';

type UploadBoundariesPopupProps = {
  onHide: () => void;
};

export const UploadBoundariesPopup = ({onHide}: UploadBoundariesPopupProps) => {
  const dispatch = useAppDispatch();
  const [uploadedFile, setUploadedFile] = useState<File>();
  const [mergeSelection, setMergeSelection] = useState('merge');
  const [loading, setLoading] = useState(false);

  const onUpload = async () => {
    setLoading(true);

    try {
      const response = await SIApi.convertFileToFeature(
        uploadedFile as File,
        mergeSelection === 'merge' ? true : false
      );
      batch(() => {
        for (const feature of response.data.features) {
          const featureID = feature?.properties?.id || feature?.properties?.FIPS || genKey();
          feature.id = featureID;
          dispatch(addSubsectionGeometries({feature}));
        }
      });
      showNotification({
        type: 'success',
        title: 'Success',
        message: `The boundary was successfully uploaded.`,
      });
    } catch (error: any) {
      const errorMessage =
        (error?.data?.detail as string) || 'There was a problem uploading the boundary.';

      showNotification({
        type: 'error',
        title: 'Error',
        message: errorMessage,
      });
    }
    setLoading(false);
    onHide();
  };

  return (
    <FluroDialog
      id={'upload-boundaries'}
      title={'Upload boundaries'}
      visible={true}
      onHide={onHide}
      className={'upload-boundaries'}
      portal={true}
    >
      <div>
        {!uploadedFile && (
          <>
            <UploadFileZone
              id={'boundary-upload'}
              accept={'.kml,.geojson'}
              onChange={file => setUploadedFile(file as File)}
            />
            <InfoBlock className={'inside-a-pop-up'}>
              <div>You can upload either a .kml or .geojson file.</div>
              <div>
                If the file has more than one feature, it can be merged into one boundary area, or
                kept as separate areas.
              </div>
            </InfoBlock>
          </>
        )}
        {uploadedFile && (
          <>
            <Text variant="h2">File</Text>
            <Text bold className={'padding-left-16'}>
              {uploadedFile.name}
            </Text>

            <SelectionControlGroup
              id="merge-files-selection"
              type="radio"
              name="merge-files-selection"
              label={
                <Text className={'margin-top-16 margin-bottom-0'} variant="h2">
                  For the selected file:
                </Text>
              }
              onChange={value => setMergeSelection(value)}
              value={mergeSelection}
              className="margin-0 padding-0"
              controls={[
                {
                  label: (
                    <Text className={'margin-bottom-0'} variant={'medium'}>
                      Merge features into a single boundary area
                    </Text>
                  ),
                  value: 'merge',
                },
                {
                  label: (
                    <Text className={'margin-bottom-0'} variant={'medium'}>
                      Keep each feature as a separate area
                    </Text>
                  ),
                  value: 'separate',
                },
              ]}
            />
          </>
        )}
        <Flex justifyContent={'space-between'}>
          <FluroButton className={'margin-top-16'} secondary raised onClick={() => onHide()}>
            Cancel
          </FluroButton>
          <FluroButton
            className={'margin-top-16'}
            primary
            raised
            onClick={() => onUpload()}
            loading={loading}
            disabled={!uploadedFile}
          >
            Add
          </FluroButton>
        </Flex>
      </div>
    </FluroDialog>
  );
};
