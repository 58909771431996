import type {ComponentType} from 'react';
import React from 'react';
/**
 * This component allows to declaratively hide Carbon-only features
 */
import {useWorkspace} from '_hooks/use-workspace';

export const MRVFeature: ComponentType = ({children}) => {
  const {isWorkspaceMrv} = useWorkspace();
  if (!isWorkspaceMrv) return null;
  return <>{children}</>;
};
