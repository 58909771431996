import {InfoBlock} from 'components';
import {navigationHighlightContext} from 'containers/mrv/monitoring/form/navigation-highlight-context';
import type {StageMessage} from 'containers/mrv/types';
import React, {useContext, useMemo} from 'react';

const linkRegex = /(.+(\((.)+\)))+/g;
const bracketRegex = /\[(.*?)\]\(([^)]+)\)/;
const wordBreakRegex = /\[.*?.*?\]\(.*?.*?\)|\S+/g;

// You can use hyperlinks by supplying strings with brackets appended with the link, such as:
// "[message text](/link)"

export const StageMessageView = ({
  message,
  untouchedCells,
  RightButton,
  className,
}: {
  message: StageMessage;
  className?: string;
  untouchedCells?: boolean;
  RightButton?: React.FunctionComponent;
}) => {
  const {highlightUntouchedCells} = useContext(navigationHighlightContext);
  const onClick = untouchedCells ? highlightUntouchedCells : undefined;

  // Displays the message with associated hyperlinks if they are available
  // Such as: "[message text](/link)"
  const displayMessage = () => {
    const displayElement = message.text;
    if (typeof displayElement === 'string' && displayElement.matchAll(linkRegex)) {
      const wordSplit = displayElement.match(wordBreakRegex);
      return wordSplit?.reduce((acc, cur, index) => {
        if (!cur || cur === ' ') {
          return acc;
        }
        let value: string | JSX.Element = cur;

        if (bracketRegex.test(value)) {
          // @ts-expect-error error leftover from convertion to strict mode, please fix
          const [_, text, link] = bracketRegex.exec(value);

          value = (
            <a href={link} target="_blank" rel="noopener noreferrer">
              {text}
            </a>
          );
        }
        return (
          <>
            {acc}
            {value}
            {index < wordSplit?.length - 1 ? <>&nbsp;</> : ''}
          </>
        );
      }, <></>);
    } else {
      return displayElement;
    }
  };

  const displayMessageElement = useMemo(displayMessage, [message.text]);

  return (
    <InfoBlock
      className={className}
      title={message.title}
      appearance={message.type}
      color={message.type}
      mini
      onClick={onClick}
      actions={RightButton && <RightButton />}
    >
      <div>{displayMessageElement}</div>
    </InfoBlock>
  );
};
