// @ts-nocheck
import type {Field} from 'containers/map/types';
import React, {createContext, useState} from 'react';

export type Skeleton = {
  onFieldsUploaded?: (fields: Field[]) => void;
  setOnFieldsUploaded: (onFieldsUploaded: Skeleton['onFieldsUploaded']) => void;
};

export const skeletonContext = createContext<Skeleton>({
  onFieldsUploaded: undefined,
  setOnFieldsUploaded: undefined,
});

export const SkeletonProvider = (props: React.PropsWithChildren<{}>) => {
  const [onFieldsUploaded, setOnFieldsUploaded] = useState<Skeleton['onFieldsUploaded']>(undefined);
  return (
    <skeletonContext.Provider value={{onFieldsUploaded, setOnFieldsUploaded}}>
      {props.children}
    </skeletonContext.Provider>
  );
};

// Usage example:
// const skeleton = useContext(skeletonContext);
// useEffect(() => {
// const onFieldsUploaded = (fields: Field[]) =>
//   dispatch(loadFieldGeometries(fields.map(f => f.MD5)));
// skeleton.setOnFieldsUploaded(prev => onFieldsUploaded);
// return () => {
//   skeleton.setOnFieldsUploaded(undefined);
// };
// }, []);
