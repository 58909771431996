// @ts-nocheck
import type {CSGViewModel} from '../types';
import {cropGrowthStageColors, cropGrowthStageLabels, CropGrowthStages} from '../types';

export * from './crop-growth-stages-legend-list';

export const getGrowthStagesMapChartData = (records: CSGViewModel[]) => {
  const dataCount: Record<string, number> = {};

  records.forEach(r => {
    if (!dataCount[r.growthStageCode]) {
      dataCount[r.growthStageCode] = 0;
    }
    dataCount[r.growthStageCode] = dataCount[r.growthStageCode] + 1;
  });

  const order = CropGrowthStages.filter(growthStageCode => dataCount[growthStageCode]);

  const data = order.map(growthStageCode => ({
    id: growthStageCode,
    label: cropGrowthStageLabels[growthStageCode],
    value: dataCount[growthStageCode],
  }));

  const getColor = (slice: any) => cropGrowthStageColors[slice.id] || '#EFEFEF';
  return {data, getColor};
};
