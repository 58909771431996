import React from 'react';
import {FluroButton, FluroDialog, FluroInput, Text} from 'components';
import {useParsedMatchParams} from '_hooks/use-parsed-match-params';
import {FluroCheckbox} from 'components/fluro-checkbox/fluro-checkbox';
import {MRVApi} from '_api';
import styled from 'styled-components';
import {selectIsAdmin} from 'containers/login/login-selectors';
import {useAppSelector} from '_hooks';

const ProgramDumpButton = () => {
  const {programId} = useParsedMatchParams<{programId: number}>();
  const isAdmin = useAppSelector(selectIsAdmin);

  const [dumpModalOpen, setDumpModalOpen] = React.useState(false);

  const [deletedFields, setDeletedFields] = React.useState(false);
  const [deletedProjects, setDeletedProjects] = React.useState(false);
  const [emailFilter, setEmailFilter] = React.useState<string | number>('');

  if (!isAdmin) {
    return null;
  }

  const onSubmit = () => {
    MRVApi.getProgramDump(programId, deletedFields, deletedProjects, emailFilter).then(() =>
      setDumpModalOpen(false)
    );
  };

  return (
    <Container>
      <FluroButton
        raised
        primary
        onClick={() => setDumpModalOpen(true)}
        style={{textTransform: 'none'}}
      >
        Export all program data
      </FluroButton>
      <FluroDialog
        visible={dumpModalOpen}
        hideOnClickOutside
        isClosable
        width="400px"
        title="Export all program data"
        onHide={() => setDumpModalOpen(false)}
        id={'program-dump-button--dialog'}
      >
        <Text>Every data collection stage in the program will be exported as a .csv file</Text>
        <FluroCheckbox
          label={'Include deleted fields'}
          value={deletedFields}
          onChange={setDeletedFields}
        />
        <FluroCheckbox
          label={'Include deleted projects'}
          value={deletedProjects}
          onChange={setDeletedProjects}
        />
        <FluroInput
          type="text"
          placeholder="Email filter"
          value={emailFilter}
          onChange={setEmailFilter}
        />
        <SubmitContainer>
          <FluroButton raised primary onClick={onSubmit}>
            Submit
          </FluroButton>
        </SubmitContainer>
      </FluroDialog>
    </Container>
  );
};

const Container = styled.div``;
const SubmitContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
`;

export default ProgramDumpButton;
