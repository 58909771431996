// @ts-nocheck
import type {Field} from 'containers/map/types';
import moment from 'moment';
import type {AppStore} from 'reducers';
import {GLOBAL_APP_DATE_FORMAT, SERVER_FORMAT_TIME} from '_constants';
import {getFieldSeasons} from '_hooks/use-current-season';
import {CPFilterType} from '_reducers/crop-performance-filter/field-filter-types';
import {unreachableError} from '_utils/pure-utils';
import {classifyNdvi} from '../biomass/biomass';
import {
  biomassColors,
  cropGrowthColors,
  cropStressLabelColors,
  fieldsVariabilityColors,
  cropGrowthStageColors,
} from '../types';

export const getGeometryColors = (
  fields: Field[],
  cropPerformance: AppStore['cropPerformance'],
  filter: AppStore['cropPerformanceFilter'],
  currentDate: string,
  cropTypes: AppStore['global']['cropTypes'],
  cropVarietyMode: AppStore['map']['cropVarietyMode'],
  cropVarietyColors: AppStore['map']['cropVarietyColors']
) => {
  const colors: {
    background: {[seasonId: string]: string};
    stroke: {[seasonId: string]: string};
  } = {background: {}, stroke: {}};
  const date = moment(currentDate, GLOBAL_APP_DATE_FORMAT).format(SERVER_FORMAT_TIME);
  const records = cropPerformance.records;
  switch (cropPerformance.representation) {
    // Representation is never variety. If it's variety, we'll use `cropVarietyMode` flag.
    // Keep the CROP_VARIETY case here just to satisfy the `unreachableError`.
    case CPFilterType.FIELDS_WITH_PLANTING_AREAS:
      return colors;

    case CPFilterType.CROP_TYPE:
    case CPFilterType.CROP_VARIETY:
      fields.forEach(f => {
        const seasons = getFieldSeasons(f, date);
        if (!seasons.length) {
          colors.background[f.ID] = '#eee';
          return;
        }

        let fillColor: string;
        seasons.forEach(season => {
          if (!filter.filterStatus[season.id]) {
            if (cropVarietyMode) {
              fillColor = season?.params?.cropSubType
                ? cropVarietyColors[season?.params?.cropSubType]
                : cropTypes[season?.cropType]?.color; // For no variety we show crop type color.
            } else {
              fillColor = cropTypes[season?.cropType]?.color;
            }
            colors.background[season.id] = fillColor || '#eee';
          }
        });
      });
      return colors;

    case CPFilterType.FIELDS_VARIABILITY:
      fields.forEach(field =>
        getFieldSeasons(field, date).forEach(season => {
          if (!filter.filterStatus[season.id]) {
            const variability = cropPerformance.fieldsVariability[season.id]?.status;
            colors.background[season.id] = fieldsVariabilityColors[variability];
          }
        })
      );
      return colors;

    case CPFilterType.CROP_GROWTH:
      records.forEach(r => {
        if (!filter.filterStatus[r.seasonId]) {
          const growth =
            r.cropStatus === 'No images' || (!r.reliable && !cropPerformance.useUnreliableData)
              ? -10
              : r.growth;
          colors.background[r.seasonId] = cropGrowthColors[growth];
        }
      });
      return colors;

    case CPFilterType.TREE_STATUS:
    case CPFilterType.CROP_STATUS:
      records.forEach(r => {
        if (!filter.filterStatus[r.seasonId]) {
          const status =
            !r.reliable && !cropPerformance.useUnreliableData ? 'No images' : r.cropStatus;
          colors.background[r.seasonId] = cropPerformance.info?.colours[status];
        }
      });
      return colors;

    case CPFilterType.CROP_STRESS:
      records.forEach(r => {
        if (!filter.filterStatus[r.seasonId]) {
          // TODO CP plating areas adjust crop-stress-card
          if (cropPerformance.cropStressChartData[r.fieldID]) {
            colors.background[r.seasonId] =
              cropStressLabelColors[cropPerformance.cropStressChartData[r.fieldID]];
          }
        }
      });

      return colors;

    case CPFilterType.BIOMASS:
      records.forEach(r => {
        if (!filter.filterStatus[r.seasonId]) {
          const ndvi =
            r.cropStatus === 'No images' || (!r.reliable && !cropPerformance.useUnreliableData)
              ? 'noimages'
              : classifyNdvi(
                  r.smoothSatelliteNdvi,
                  cropPerformance.ndviQuartiles[`${r.cropType}_${r.cropVariety}`]?.smoothSatellite
                );
          colors.background[r.seasonId] = biomassColors[ndvi];
        }
      });
      return colors;

    case CPFilterType.BIOMASS_OVER_TIME:
      records.forEach(r => {
        const recordData = filter.filters[CPFilterType.BIOMASS_OVER_TIME].find(
          ({value}) => value === r.seasonId
        );
        if (recordData) {
          colors.background[r.seasonId] = recordData.color;
        }
      });
      return colors;

    case CPFilterType.CROP_GROWTH_STAGE:
      records.forEach(r => {
        if (!filter.filterStatus[r.seasonId]) {
          colors.background[r.seasonId] = cropGrowthStageColors[r.growthStageCode];
        }
      });
      return colors;

    default:
      unreachableError(
        cropPerformance.representation,
        `Whole farm overlay error: Unsupported crop performance representation: ${cropPerformance.representation}`
      );
  }
};
