import React from 'react';
import {Flex, FluroButton, FluroDialog, Text} from 'components';

export const ProcessingPopup = ({onHide}: {onHide: () => void}) => {
  return (
    <FluroDialog id={'si-processing-popup'} onHide={onHide} visible={true} width={500} portal>
      <Flex direction="column" className="p-3">
        <Text variant="h2" bold>
          Processing Sourcing Regions...
        </Text>
        <Text variant="h3">
          We’re re-calculating all of your Practice and Outcome metrics based on your updated supply
          shed geographies.
        </Text>
        <Text variant="h3">
          This may take up to 60 minutes or more to complete. Your Sustainability Dashboard will
          automatically refresh with the updated metrics once processing is complete.
        </Text>
      </Flex>
      <Flex justifyContent="flex-end">
        <FluroButton raised primary onClick={onHide}>
          Continue
        </FluroButton>
      </Flex>
    </FluroDialog>
  );
};
