import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import type {GeoJSONProps} from 'react-leaflet';
import {useMap, useMapEvent} from 'react-leaflet';
import cn from 'classnames';
import {
  updateCLUFieldBoundariesProp,
  setAddFieldCurrentStep,
  maybeEnterSelectBoundariesStepAndFetchCLUs,
  AddingFieldsZoomThreshold,
  selectDrawnFieldGeometries,
  toggleBoundaryIdToUpload,
} from 'modules/add-fields';
import {DialogType} from 'modules/helpers';
import {FieldSystemProp} from '../features/farm/new-fields/types';
import {MAIN_SHAPE_COLOR} from '_constants';
import {useAppDispatch, useAppSelector} from '_hooks';
import {selectDialogVisibility} from 'modules/helpers/selectors';
import colors from 'variables.scss';
import {selectIsDrawingMode} from '../reducer/selectors';
import {
  selectAddingFieldsStep,
  selectBoundaryIdsToUploadMap,
  selectCLUFieldBoundariesList,
  selectProtectedAreaBoundaries,
} from 'modules/add-fields/selectors';
import {FluroGeoJson} from 'components/fluro-leaflet';
import type {CLUFieldBoundary} from 'modules/add-fields/types';
import {useWorkspace} from '_hooks/use-workspace';

export const CLUFieldBoundariesOverlay = () => {
  const dispatch = useAppDispatch();
  const fieldBoundaries = useAppSelector(selectCLUFieldBoundariesList);
  const boundaryIdsToUploadMap = useAppSelector(selectBoundaryIdsToUploadMap);
  const protectedAreaBoundaries = useAppSelector(selectProtectedAreaBoundaries);
  const drawnFieldsGeometries = useAppSelector(selectDrawnFieldGeometries);
  const addFieldCurrentStep = useAppSelector(selectAddingFieldsStep);
  const isDrawingMode = useAppSelector(selectIsDrawingMode);
  const {workspace} = useWorkspace();

  const shouldFetchPadusProtectedAreaIntersections = workspace === 'mrv';

  const addFieldDialogOpened = useAppSelector(s =>
    selectDialogVisibility(s, DialogType.addNewField)
  );
  const leafletElement = useMap();
  // provide a ref to access to prop in closure functions invoked by leaflet methods
  const searchBoundariesStateRef = useRef<boolean>(false);
  const [geometryKey, setGeometryKey] = useState(1);
  const searchBoundariesState =
    ['zoom-is-too-low', 'search-location', 'select-boundaries'].includes(addFieldCurrentStep) ||
    ('draw-fields' === addFieldCurrentStep && !drawnFieldsGeometries.length);

  useEffect(
    function provideARefForSearchBoundariesState() {
      searchBoundariesStateRef.current = searchBoundariesState;
    },
    [searchBoundariesState]
  );

  const onMapPanned = useCallback(() => {
    if (!searchBoundariesStateRef.current) return; // ensure we are not doing things without accurate app state | can be refactored once move to react-leaflet >3.0

    const leafletZoom = leafletElement.getZoom();
    if (leafletZoom >= AddingFieldsZoomThreshold) {
      // request boundaries only if zoom level is more than 14
      const center = leafletElement.getCenter();
      dispatch(
        maybeEnterSelectBoundariesStepAndFetchCLUs(
          center.lat,
          center.lng,
          shouldFetchPadusProtectedAreaIntersections
        )
      );
    } else {
      checkZoomLevel();
    }
  }, []);

  const checkZoomLevel = () => {
    if (!searchBoundariesStateRef.current) return; // ensure we are not doing things without accurate app state

    const zoomLevel = leafletElement.getZoom();
    if (zoomLevel < AddingFieldsZoomThreshold) {
      dispatch(setAddFieldCurrentStep('zoom-is-too-low'));
    }
  };

  useEffect(
    function searchBoundariesOnceStartedAddFlow() {
      if (addFieldCurrentStep === 'search-location') {
        onMapPanned();
      }
    },
    [addFieldCurrentStep]
  );

  useMapEvent('moveend', () => {
    if (searchBoundariesState) {
      onMapPanned();
    }
  });

  useMapEvent('zoomend', () => {
    if (addFieldDialogOpened && searchBoundariesState) {
      checkZoomLevel();
    }
  });

  useEffect(() => {
    // Force rerender.
    setGeometryKey(geometryKey + 1);
  }, [fieldBoundaries, addFieldCurrentStep, protectedAreaBoundaries, boundaryIdsToUploadMap]);

  return (
    searchBoundariesState &&
    !isDrawingMode && (
      <>
        {/* TODO (stas): right now we rerender the geometries that were already rendered.
        Figure out how to avoid it. The problem is that I suspect the geometry (boundary)
        is a different object than it was on a prveious render. Track back where it get's recreated
        and try not to recreate it.
       */}
        {fieldBoundaries.map(boundary => {
          const properties = boundary.properties;
          const id = properties?.id || properties[FieldSystemProp.Id];
          const warning = protectedAreaBoundaries[String(id)];
          const selected = boundaryIdsToUploadMap[String(id)];
          if (!id) return null;
          return (
            <GeometryWrapper
              key={id}
              id={id}
              geometry={boundary}
              selected={selected}
              warning={Boolean(warning)}
              isDrawFields={addFieldCurrentStep === 'draw-fields'}
            />
          );
        })}
      </>
    )
  );
};

const GeometryWrapper = ({
  id,
  geometry,
  selected,
  warning,
  isDrawFields,
}: {
  id: string;
  geometry: CLUFieldBoundary;
  selected: boolean;
  warning: boolean;
  isDrawFields: boolean;
}) => {
  const dispatch = useAppDispatch();
  const eventHandlers = useMemo(
    () =>
      ({
        click: () => {
          if (!id) return;

          dispatch(toggleBoundaryIdToUpload(id));
          dispatch(
            updateCLUFieldBoundariesProp(
              id,
              FieldSystemProp.Checked,
              !geometry.properties?.[FieldSystemProp.Checked]
            )
          );
          if (isDrawFields) {
            // switch back to select-boundary mode
            dispatch(setAddFieldCurrentStep('select-boundaries'));
          }
        },
        mouseover: e => {
          // highlight on hover
          const layer = e?.target;
          layer?.setStyle?.({fillColor: MAIN_SHAPE_COLOR});
        },
        mouseout: e => {
          // remove highlighting on mouse out
          const layer = e?.target;
          layer?.setStyle?.({fillColor: selected ? MAIN_SHAPE_COLOR : 'transparent'});
        },
      } as GeoJSONProps['eventHandlers']),
    [id, selected]
  );
  return (
    <>
      <FluroGeoJson
        key={id}
        fillColor={selected ? MAIN_SHAPE_COLOR : 'transparent'}
        className={cn('field-boundary-shape', {selected})} // add the class for cypress needs
        color={warning ? colors['saffron'] : '#fff'}
        fillOpacity={1}
        weight={2}
        data={geometry}
        eventHandlers={eventHandlers}
      />
    </>
  );
};
