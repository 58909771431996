import type {Dispatch} from 'redux';

export const VIEWPORT_RESIZED = 'viewport/resized';

// Actions.

type Typed = {
  type: string;
};

type ResizeViewportAction = Typed & {
  width: number;
  height: number;
};
export const resizeViewport = (width: number, height: number) => (dispatch: Dispatch<Action>) => {
  return dispatch({
    type: VIEWPORT_RESIZED,
    width,
    height,
  });
};

// Reducer.

export type State = {
  width: number;
  height: number;
};

type Action = ResizeViewportAction;

const initialState: State = {
  width: window?.innerWidth || 0,
  height: window?.innerHeight || 0,
};

const reducer = (state = initialState, action: Action) => {
  switch (action.type) {
    case VIEWPORT_RESIZED:
      return {...state, width: action.width, height: action.height};
    default:
      return state;
  }
};

export default reducer;
