import React from 'react';
import cn from 'classnames';
import {Circle} from 'components/shapes/circle';

export type FluroStatusCircleProps = 'green' | 'light-green' | 'yellow' | 'gray' | 'red';

const colors = {
  green: '#5fb854',
  'light-green': '#a5d0a4',
  yellow: '#f9d76d',
  gray: '#c2c2c2',
  red: '#ff0611',
};

type Props = {
  status: FluroStatusCircleProps;
  className?: string;
};
export const FluroStatusCircle = ({status, className = ''}: Props) => {
  return (
    <Circle
      inline
      size={13}
      color={colors[status]}
      className={cn({'fluro-status-circle': true, [status]: true, [className]: true})}
    />
  );
};
