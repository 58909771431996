// @ts-nocheck
import React, {useCallback, useMemo, useState} from 'react';
import {Wrapper, SearchInputWrapper, TopControlWrapper} from './edit-crop.styled';
import {useSelector} from 'react-redux';
import type {AppStore} from 'reducers';
import {FontIcon} from 'react-md';
import {
  FluroTableColumn,
  FluroTableHeader,
  FluroTableRow,
  FluroDataTable,
  FluroTableBody,
  FluroTablePagination,
  UploadFileZone,
  FluroDialog,
  Text,
} from 'components';
import {SortableColumn} from 'containers/admin/logs/shared';
import type {CropType, CropTypeTranslation} from 'modules/global/types';
import {CropAvatar} from 'components/crop-avatar';
import {EditCropDialog} from './edit-crop-dialog';
import {DialogType} from '../../../modules/helpers';
import {calcRowsPerPageItems, rowsPerPage} from '../index';
import {naturalSortAlphaNum} from '_utils/sorters';
import {InlineEditForm} from './inline-edit-form';
import Select, {components} from 'react-select';
import {Flag} from 'components/select-language';
import CropsApi from '_api/crops';
import {useAppSelector} from '../../../_hooks';
import {selectDialogVisibility} from '../../../modules/helpers/selectors';
import './edit-crop.scss';

export const TRANSLATION_ITEMS: {label: string; value: CropTypeTranslation; flag?: string}[] = [
  {
    label: 'Russian',
    value: 'label_ru-ru',
    flag: 'ru',
  },
  {
    label: 'Portuguese (BR)',
    value: 'label_pt-br',
    flag: 'br',
  },
  {
    label: 'French (FR)',
    value: 'label_fr-fr',
    flag: 'fr',
  },
];

const DropdownIndicator = (props: any) => {
  return (
    <components.DropdownIndicator {...props}>
      <FontIcon>arrow_drop_down</FontIcon>
    </components.DropdownIndicator>
  );
};

export const EditCrop = () => {
  const crops = useSelector((state: AppStore) => state.global.cropTypes);
  const cropsArray: CropType[] = useMemo(() => Object.values(crops), [crops]);
  const [cropTypeSearch, setCropTypeSearch] = useState<any>('');
  const [pagination, onChangePagination] = useState({start: 0, perPage: rowsPerPage, page: 1});
  const [sortBy, setSortBy] = useState<keyof CropType>('label');
  const [desc, setDesc] = useState(false);
  const [editCropKey, setEditCropKey] = useState('');
  const [editTranslation, setEditTranslation] = useState(TRANSLATION_ITEMS[0].value);
  const [uploadCropIcon, setUploadCropIcon] = useState<string | undefined>(undefined);
  const [iconVersions, setIconVersions] = useState<{[key: string]: number}>({});

  const isEditCropDialogVisible = useAppSelector(s =>
    selectDialogVisibility(s, DialogType.editCrop)
  );

  const onSort = useCallback((_sortBy: keyof CropType, selectedSortBy: string) => {
    if (_sortBy !== selectedSortBy) {
      setSortBy(_sortBy);
    } else {
      setDesc(v => !v);
    }
  }, []);

  const handlePagination = (start: number, perPage: number, page: number) => {
    onChangePagination({start, perPage, page});
  };

  const selectedTransItem = useMemo(
    () => TRANSLATION_ITEMS.find(item => item.value === editTranslation),
    [TRANSLATION_ITEMS.find(item => item.value === editTranslation)]
  );

  const filteredList = useMemo(() => {
    onChangePagination({start: 0, perPage: pagination.perPage, page: 1});

    let result = cropsArray.filter((item: any) =>
      [item.label, item[selectedTransItem.value] || ''].some(val =>
        val.toLowerCase().includes(cropTypeSearch.toLowerCase())
      )
    );

    if (desc) {
      return naturalSortAlphaNum(result, sortBy)?.reverse();
    }

    return naturalSortAlphaNum(result, sortBy);
  }, [cropsArray, cropTypeSearch, sortBy, desc, selectedTransItem]);

  const slicedList = useMemo(() => {
    return filteredList.slice(pagination.start, pagination.start + pagination.perPage);
  }, [pagination, filteredList]);

  const uploadIcon = useCallback((crop: CropType, file: File | File[]) => {
    CropsApi.putIcon(crop.id, file)
      .then(() => {
        setUploadCropIcon(undefined);
        setIconVersions(versions => ({
          ...versions,
          [crop.value]: (versions[crop.value] || 0) + 1,
        }));
      })
      .catch();
  }, []);

  return (
    <Wrapper className="edit-crop">
      <TopControlWrapper>
        <Select
          formatOptionLabel={option => {
            return (
              <div style={{display: 'flex', alignItems: 'center'}}>
                <Flag className={`iti-flag ${option.flag || 'en'}`} />{' '}
                <span style={{marginLeft: 10}}>{option.label}</span>
              </div>
            );
          }}
          onChange={(option: any) => {
            setEditTranslation(option?.value);
            setEditCropKey(undefined);
          }}
          value={selectedTransItem}
          className="lang-select"
          options={TRANSLATION_ITEMS}
          menuPortalTarget={document.body}
          components={{DropdownIndicator}}
          styles={{
            menuPortal: (base: any) => ({
              ...base,
              zIndex: 9999,
            }),
            indicatorSeparator: () => ({
              display: 'none',
            }),
            dropdownIndicator: (base: any) => ({
              ...base,
              padding: 6,
            }),
            valueContainer: (base: any) => ({
              ...base,
              color: 'rgba(0, 0, 0, 0.87)',
            }),
            placeholder: (base: any) => ({
              ...base,
              color: 'rgba(0, 0, 0, 0.87)',
            }),
            control: (base: any) => ({
              ...base,
              border: '1px solid rgba(0, 0, 0, 0.1)',
              '&:hover': {borderColor: 'rgba(0, 0, 0, 0.1)'},
              boxShadow: 'none',
              height: 34,
              minHeight: 34,
            }),
          }}
        />

        <SearchInputWrapper>
          <FontIcon>search</FontIcon>
          <input
            className="md-text-field md-text"
            style={{width: 300}}
            id="search-crops"
            placeholder="Search a crop type"
            value={cropTypeSearch}
            onChange={e => {
              const v = e.target.value;
              setCropTypeSearch(v);
            }}
          />
        </SearchInputWrapper>
      </TopControlWrapper>

      <FluroDataTable plain baseId="crop-types-manager-table">
        <FluroTableHeader>
          <FluroTableRow>
            <SortableColumn sortBy="label" selectedSortBy={sortBy} desc={desc} onSort={onSort}>
              Crop type
            </SortableColumn>

            <SortableColumn
              sortBy={selectedTransItem.value}
              selectedSortBy={sortBy}
              desc={desc}
              onSort={onSort}
            >
              {selectedTransItem.label}
            </SortableColumn>

            <SortableColumn
              sortDisabled
              sortBy="color"
              selectedSortBy={sortBy}
              desc={desc}
              onSort={onSort}
            >
              Color
            </SortableColumn>

            <SortableColumn
              sortDisabled
              sortBy="cdl_id"
              selectedSortBy={sortBy}
              desc={desc}
              onSort={onSort}
            >
              Cdl id
            </SortableColumn>
          </FluroTableRow>
        </FluroTableHeader>

        <FluroTableBody>
          {slicedList.map(item => (
            // Add this 'uploading' suffix for the crops that got uploaded, to rerender them and rerequest their icon.
            <FluroTableRow key={item.value}>
              <FluroTableColumn adjusted={false}>
                <div style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-start'}}>
                  <CropAvatar
                    style={{marginRight: 10, cursor: 'pointer'}}
                    cropType={item.value}
                    version={iconVersions[item.value]}
                    onClick={() => setUploadCropIcon(item.value)}
                  />
                  {item.label}
                  <Text secondary variant="small-thin" className="ml-05">
                    #{item.id}
                  </Text>
                </div>
              </FluroTableColumn>

              <FluroTableColumn
                adjusted={false}
                onClick={() => setEditCropKey(`${selectedTransItem.value}${item.id}}`)}
              >
                {editCropKey === `${selectedTransItem.value}${item.id}}` ? (
                  <InlineEditForm
                    onClose={() => setEditCropKey(undefined)}
                    dataKey={selectedTransItem.value}
                    data={item}
                  />
                ) : (
                  item[selectedTransItem.value] || '-'
                )}
              </FluroTableColumn>

              <FluroTableColumn adjusted={false}>
                <InlineEditForm key={item.color + item.id} dataKey="color" data={item} />
              </FluroTableColumn>
              <FluroTableColumn adjusted={false}>
                <Text>{item.cdl_id || '-'}</Text>
              </FluroTableColumn>
              {/*<FluroTableColumn>*/}
              {/*  <Button primary raised onClick={() => onEditCrop(item)}>*/}
              {/*    Edit*/}
              {/*  </Button>*/}
              {/*</FluroTableColumn>*/}
            </FluroTableRow>
          ))}
        </FluroTableBody>

        <FluroTablePagination
          page={pagination.page}
          rows={filteredList.length}
          rowsPerPage={pagination.perPage}
          rowsPerPageLabel="Crops"
          onPagination={handlePagination}
          rowsPerPageItems={calcRowsPerPageItems(filteredList.length)}
        />
      </FluroDataTable>

      {uploadCropIcon && (
        <CropAvatarUploader
          crop={crops[uploadCropIcon]}
          onHide={() => setUploadCropIcon(undefined)}
          onChange={uploadIcon}
        />
      )}

      {isEditCropDialogVisible && <EditCropDialog />}
    </Wrapper>
  );
};

const CropAvatarUploader = ({
  crop,
  onHide,
  onChange,
}: {
  crop: CropType;
  onHide: () => void;
  onChange: (crop: CropType, file: File | File[]) => void;
}) => {
  return (
    <FluroDialog
      id={'upload-crop-icon-dialog'}
      title={`Upload a crop icon for ${crop.label}`}
      visible={true}
      onHide={onHide}
    >
      <UploadFileZone
        id={'upload-crop-icon'}
        accept={'.png'}
        onChange={file => onChange(crop, file)}
      />
    </FluroDialog>
  );
};
