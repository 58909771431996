import Server from '../server';
import type {AxiosPromise} from 'axios';

type IntegrationsResponse = {
  status: string;
  result: {
    agrian: boolean;
    agworld: boolean;
    agx: boolean;
    climate: boolean;
    efc: boolean;
    jd: boolean;
    pw: boolean;
  };
};

export default class {
  static getAuthorizedPlatforms(): AxiosPromise<IntegrationsResponse> {
    return Server.get(`sync/connected_integrations`, {params: {__skipPreloader: true}});
  }
}
