import {PracticeAdoptionOutcomesTable} from 'containers/si/planning-report/programPlan/pages/PracticeAdoption/PracticeAdoptionOutcomesTable';
import type {PlanningReport} from 'containers/si/types';
import React from 'react';
import {Flex, Text} from 'components';
import {NumericInput} from 'containers/si/planning-report/programPlan/components/NumericInput/NumericInput';
import styled from 'styled-components';
import type {EstimatedOutcomes} from 'containers/si/planning-report/programPlan/types';

const fakeAnnualAverage: EstimatedOutcomes = {
  estNet: 1200,
  estReductions: 800,
  estRemovals: 400,
  baseline: 9000,
};

const fakeTotal: EstimatedOutcomes = {
  estNet: 12000,
  estReductions: 8000,
  estRemovals: 4000,
  baseline: 90000,
};

export const PracticeAdoptions = ({report: _report}: {report: PlanningReport}) => {
  const [priceOfCarbon, setPriceOfCarbon] = React.useState(20);

  return (
    <Flex direction="column" gap={12}>
      {/* TODO: insert Selected scenarios summary here */}
      <div>
        <Flex justifyContent="space-between" gap={16} alignItems="flex-end">
          <Flex direction="column">
            <Text variant="h2" bold>
              Practice adoption and program outcomes by year
            </Text>

            <Text>
              Estimated outcomes based on successful practice adoption per the specified program
              duration
            </Text>
          </Flex>
          <Flex gap={8} alignItems="flex-start" style={{marginBottom: '-12px'}}>
            <Text elementType={'span'} style={{margin: '15px 0 0 0'}}>
              Price of carbon per tonne (USD): $
            </Text>
            <CarbonPriceInput value={priceOfCarbon} setValue={setPriceOfCarbon} min={1} />
          </Flex>
        </Flex>
      </div>
      <PracticeAdoptionOutcomesTable
        priceOfCarbon={priceOfCarbon}
        yearlyAverageData={fakeAnnualAverage}
        lifetimeData={fakeTotal}
      />
      {/* TODO: insert annual outcomes table here */}
      {/* TODO: insert line graph here */}
      {/* TODO: insert warning */}
    </Flex>
  );
};

// Give numeric input a useful name
const CarbonPriceInput = styled(NumericInput)``;
