import React, {useMemo, useState} from 'react';
import type {ComponentType} from 'react';
import {CircularProgress, FontIcon} from 'react-md';
import {batch} from 'react-redux';
import {push} from 'connected-react-router';
import {t} from 'i18n-utils';
import {
  Flex,
  FluroButton,
  FluroChip,
  FluroDataTable,
  FluroTableBody,
  FluroTableColumn,
  FluroTableHeader,
  FluroTableRow,
  Text,
} from 'components';
import {useAppDispatch, useAppSelector} from '_hooks';
import {naturalSortAlphaNum} from '_utils/sorters';
import {convertUnit} from '_utils';
import {applyPathParams} from '_utils/pure-utils';
import {defaultSIProgram} from 'containers/si/module/base';
import {selectSIProgramsList} from 'containers/si/module/selectors';
import {SI_MONITOR, SI_CONFIGURE} from 'containers/si/routes';
import {selectIsAdmin, selectMeasurement} from 'containers/login/login-selectors';
import {selectIsLoading} from 'modules/helpers/selectors';
import {addSIProgram, removeSIProgram} from 'containers/si/module/thunks';
import {setCurrentProgram} from 'containers/si/module/reducer';
import {ActionType} from 'containers/si/module/types';
import {outcomeKPILabel, practiceKPILabel} from 'containers/si/constants';
import {showNotification} from 'components/notification/notification';
import {SIInputPopup} from 'containers/si/components/si-input-popup';
import {SISearchInput} from 'containers/si/components/si-search-input';
import {GearIcon} from 'components/fluro-icons';

export const SIProgramsTable: ComponentType<{}> = () => {
  const dispatch = useAppDispatch();
  const programs = useAppSelector(selectSIProgramsList);
  const measurement = useAppSelector(selectMeasurement);
  const [createProgramPopupVisible, setCreateProgramPopupVisible] = useState(false);
  const isAdmin = useAppSelector(selectIsAdmin);
  const [searchText, setSearchText] = useState('');
  const isLoadingPrograms = useAppSelector(s =>
    selectIsLoading(s, [ActionType.FETCH_ALL_SI_PROGRAMS, ActionType.FETCH_SI_PROGRAM])
  );

  const createProgram = async (name: string | number) => {
    const program = {...defaultSIProgram, name: String(name)};
    dispatch(addSIProgram({program}));
  };

  const closeProgram = async (programId: number, programName: string) => {
    const confirm = window.confirm(`Are you sure you want to delete the "${programName}" program?`);

    if (confirm) {
      dispatch(removeSIProgram({programId}));
      showNotification({
        title: t({id: 'note.success', defaultMessage: 'Success'}),
        message: `The "${programName}" program was deleted.`,
        type: 'success',
      });
    }
  };

  const preparedPrograms = useMemo(() => {
    return programs.map(p => ({
      ...p,
      searchString: `${(p.name || '').toLowerCase()}${p.id}`,
    }));
  }, [programs]);

  const filteredPrograms = useMemo(() => {
    const lowerSearchString = searchText.toLowerCase();

    return naturalSortAlphaNum(
      preparedPrograms.filter(p => p.searchString.includes(lowerSearchString)),
      'name'
    );
  }, [preparedPrograms, searchText]);

  const selectProgramAndNavigate = (
    pathToApply: string,
    programId: number,
    e?: React.MouseEvent<HTMLElement>
  ) => {
    e?.stopPropagation?.();
    batch(() => {
      dispatch(setCurrentProgram(programId));
      dispatch(push(applyPathParams(pathToApply, {programId})));
    });
  };

  return (
    <div>
      <Flex justifyContent="space-between" className="mb-1" nowrap>
        <SISearchInput
          isLoading={isLoadingPrograms}
          value={searchText}
          onChange={setSearchText}
          placeholder={'Search Programs'}
          testId="program-admin-program-search--SI"
        />
        {isAdmin && (
          <FluroButton
            primary
            raised
            onClick={() => setCreateProgramPopupVisible(true)}
            data-testid="program-admin-program-create--SI"
          >
            Create a program
          </FluroButton>
        )}
      </Flex>
      {filteredPrograms.length > 0 && (
        <>
          <FluroDataTable>
            <FluroTableHeader>
              <FluroTableRow>
                <FluroTableColumn>Program</FluroTableColumn>
                <FluroTableColumn>Users</FluroTableColumn>
                <FluroTableColumn>Years</FluroTableColumn>
                <FluroTableColumn>
                  <span className="table-header">
                    {measurement === 'ac' ? 'Acres' : 'Hectares'}
                  </span>{' '}
                  enrolled
                </FluroTableColumn>
                <FluroTableColumn>Data Products</FluroTableColumn>
                <FluroTableColumn>{/* Action buttons */}</FluroTableColumn>
              </FluroTableRow>
            </FluroTableHeader>
            <FluroTableBody>
              {filteredPrograms.map(p => {
                return (
                  <FluroTableRow
                    key={p.id}
                    onClick={e => selectProgramAndNavigate(SI_MONITOR, p.id, e)}
                    className="cursor-pointer"
                  >
                    <FluroTableColumn>
                      <Flex alignItems="center">
                        {p.name}{' '}
                        <Text className="ml-05" secondary variant="small-thin">
                          #{p.id}
                        </Text>
                      </Flex>
                    </FluroTableColumn>
                    <FluroTableColumn>{p.total_user_count || 0}</FluroTableColumn>
                    <FluroTableColumn>
                      {`${p.crop_year_start} - ${p.crop_year_end}`}
                    </FluroTableColumn>
                    <FluroTableColumn>
                      <Flex direction="row" alignItems="center">
                        {/* hectares / acres */}
                        {convertUnit(measurement, 'ac', p?.acreage_limit_ha || 0).toFixed()}{' '}
                        {measurement}
                      </Flex>
                    </FluroTableColumn>
                    <FluroTableColumn>
                      <Flex alignItems="center" nowrap>
                        <Flex gap="4px" className="ml-05">
                          {p.practice_kpis &&
                            p.practice_kpis.map(asset => (
                              <FluroChip
                                tone="light"
                                size="small"
                                label={practiceKPILabel[asset]}
                                key={asset}
                              />
                            ))}
                          {p.outcome_kpis &&
                            p.outcome_kpis.map(asset => (
                              <FluroChip
                                tone="light"
                                size="small"
                                label={outcomeKPILabel[asset]}
                                key={asset}
                              />
                            ))}
                        </Flex>
                      </Flex>
                    </FluroTableColumn>
                    <FluroTableColumn>
                      <Flex gap="10px" nowrap alignItems="center">
                        <FluroButton
                          primary
                          raised
                          onClick={e => {
                            selectProgramAndNavigate(SI_MONITOR, p.id, e);
                          }}
                        >
                          View Program
                        </FluroButton>

                        <FluroButton
                          icon
                          iconEl={<GearIcon />}
                          tooltipPosition={'left'}
                          tooltipLabel={'Program configuration'}
                          onClick={e => {
                            selectProgramAndNavigate(SI_CONFIGURE, p.id, e);
                          }}
                        />
                        {isAdmin && (
                          <FluroButton
                            icon
                            iconEl={<FontIcon>delete</FontIcon>}
                            onClick={e => {
                              closeProgram(p.id, p.name);
                              e.stopPropagation();
                            }}
                            tooltipPosition={'left'}
                            tooltipLabel={'Delete program'}
                          />
                        )}
                      </Flex>
                    </FluroTableColumn>
                  </FluroTableRow>
                );
              })}
            </FluroTableBody>
          </FluroDataTable>
        </>
      )}
      {isLoadingPrograms ? (
        <CircularProgress className={'progress'} id={'si-programs-table'} />
      ) : filteredPrograms.length === 0 ? (
        programs.length !== 0 && searchText ? (
          `No program found for search = ${searchText}`
        ) : (
          'The list programs is empty you can create a program.'
        )
      ) : null}
      {createProgramPopupVisible && (
        <SIInputPopup
          title="Create a new program"
          subtitle="Program Name"
          type="text"
          value=""
          onHide={() => setCreateProgramPopupVisible(false)}
          onSave={createProgram}
        />
      )}
    </div>
  );
};
