import {useLeafletContext} from '@react-leaflet/core';
import {GeoJSON} from 'leaflet';
import cn from 'classnames';
import {ReadOnly} from 'components/read-only-container/read-only-container';
import {GeometryPreview} from 'components/geometry-preview';
import {InfoBlock} from 'components/info-block';
import {Flex} from 'components/flex/flex';
import {showNotification} from 'components/notification/notification';
import {ProtectedFieldAreaWarning} from 'components/protected-field-area-helpers';
import {
  highlightField,
  removeField,
  toggleEditingMode2,
  toggleMapBar,
} from 'containers/map/actions';
import type {Field} from 'containers/map/types';
import {t} from 'i18n-utils';
import {toggleTooltip} from 'modules/global/actions';
import {dialogToggle, DialogType} from 'modules/helpers';
import React, {memo, useCallback} from 'react';
import {useAppDispatch} from '_hooks';
import {downloadFieldsBoundary} from '_utils/geometry';
import {EligibleMessage} from '../../base/messages';
import {enrollFields, removeFields} from '../../carbon-store';
import {GEOMETRY_THUMB_SIZE, handleFieldFeature} from '../utils';
import {reportError} from 'containers/error-boundary';
import {ActionsButton, eligibleMsg, overlapMsg} from './base';
import {FluroCheckbox} from 'components/fluro-checkbox/fluro-checkbox';

export const EnrollFieldsFieldItem = memo(
  ({
    geometry,
    farmId,
    fieldId,
    fieldName,
    fieldMD5,
    field,
    fieldArea,
    editable,
    highlighted,
    ineligible,
    enrolled,
    overlap,
    checkboxIsDisabled,
    hasProtectedArea,
    hasHistoricPractices,
    isReadOnly,
    canDeleteFields,
  }: {
    geometry?: GeoJSON.FeatureCollection;
    farmId: number;
    fieldId: Field['ID'];
    fieldName: Field['Name'];
    fieldMD5: Field['MD5'];
    field: Field;
    fieldArea: string;
    editable: boolean;
    highlighted: boolean;
    ineligible: boolean;
    enrolled: boolean;
    overlap: boolean;
    checkboxIsDisabled: boolean;
    hasProtectedArea: boolean;
    hasHistoricPractices: boolean;
    isReadOnly: boolean;
    canDeleteFields: boolean;
  }) => {
    const dispatch = useAppDispatch();
    const leafletElement = useLeafletContext().map;

    const onEditField = useCallback(() => {
      try {
        dispatch(toggleMapBar(false));

        const layer = new GeoJSON(geometry);
        leafletElement.fitBounds(layer.getBounds(), {
          padding: [50, 50],
        });

        // added timeout to wait for fit bounds finished
        setTimeout(() => dispatch(toggleEditingMode2(true, fieldId)), 500);
      } catch (e) {
        reportError('Edit field boundary');
      }
    }, [fieldId, geometry /*leafletElement*/]);

    const toggleField = (field_id: number, forceValue?: boolean) => {
      const value = forceValue !== undefined ? forceValue : !enrolled;
      if (value || !hasHistoricPractices) {
        dispatch(enrollFields({[field_id]: value}));
        return;
      }

      dispatch(enrollFields({[field_id]: value}));
    };

    const actions: {label: string; onClick: () => void}[] = [
      {
        label: t({id: 'Download field boundary'}),
        onClick: () => dispatch(downloadFieldsBoundary([fieldMD5], farmId, fieldName)),
      },
      {
        label: t({id: 'Edit name'}),
        onClick: () =>
          dispatch(
            dialogToggle(DialogType.editFieldName, true, {
              field: field,
              farmId: farmId,
            })
          ),
      },
    ];
    if (canDeleteFields) {
      actions.push({
        label: t({id: 'Delete'}),
        onClick: () =>
          dispatch(
            dialogToggle(DialogType.deleteDialog, true, {
              title: t({id: 'Delete field?'}),
              onSubmit: () => {
                dispatch(removeFields({fieldIds: [fieldId]}));
                dispatch(removeField(farmId, [fieldId], true));
              },
            })
          ),
      });
    }

    if (editable) {
      actions.unshift({
        label: t({id: 'Edit boundary'}),
        onClick: onEditField,
      });
    }

    const onFieldRowClick = useCallback(
      () => dispatch(highlightField(fieldId)),
      [dispatch, fieldId]
    );

    const showIneligibleNote = useCallback(
      () =>
        showNotification({
          title: 'Ineligible location.',
          message: <EligibleMessage />,
          type: 'warning',
        }),
      []
    );

    return (
      <div
        key={fieldId}
        onClick={onFieldRowClick}
        onMouseEnter={() => {
          (ineligible || overlap) &&
            dispatch(
              toggleTooltip({
                id: `tooltip-${fieldId}`,
                content: overlap ? overlapMsg() : eligibleMsg(),
                place: 'top',
              })
            );
        }}
      >
        <div
          className={cn('field pl-6', {
            overlap,
            highlighted,
            'no-border': hasProtectedArea || ineligible,
          })}
        >
          <FluroCheckbox
            label={
              <Flex nowrap>
                {geometry && (
                  <div className="field-geometry-thumb">
                    <GeometryPreview
                      data={geometry}
                      size={GEOMETRY_THUMB_SIZE}
                      onEachFeature={handleFieldFeature}
                    />
                  </div>
                )}
                <div className="field-name field-name-disabled">{fieldName}</div>
              </Flex>
            }
            value={enrolled}
            disabled={checkboxIsDisabled}
            onChange={() => {
              if (!checkboxIsDisabled) {
                // simulate onChange here because onChange doesn't work because of onClick action of the parent div (weird issue)
                toggleField(fieldId);
              }

              if (!isReadOnly && ineligible) {
                showIneligibleNote();
              }
            }}
          />
          <div className="field-item-right-block">
            <div className="field-area">{fieldArea}</div>

            <ReadOnly containerClass={'read-only-container'} isReadOnly={isReadOnly}>
              <ActionsButton
                id={'fields-menu-button-export'}
                disabled={isReadOnly}
                actions={actions}
              />
            </ReadOnly>
          </div>
        </div>

        {hasProtectedArea && (
          <div className="message-wrapper protected-area-block">
            <ProtectedFieldAreaWarning />
          </div>
        )}

        {(ineligible || overlap) && (
          <div className="message-wrapper protected-area-block">
            <InfoBlock
              title={t({id: 'Overlapping fields.'})}
              appearance="error"
              color="error"
              mini
              className="mt-0"
            >
              {t({
                id: 'This field overlaps another field. Please deselect one of them or edit the boundaries.',
              })}
            </InfoBlock>
          </div>
        )}

        {ineligible && (
          <div className="message-wrapper">
            <InfoBlock
              title={t({id: 'Ineligible location.'})}
              appearance="error"
              color="error"
              mini
              className="mt-0"
            >
              {t({id: 'This field is outside the program boundary, please deselect it.'})}
            </InfoBlock>
          </div>
        )}
      </div>
    );
  }
);
