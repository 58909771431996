import {selectCurrentProgramMonitoringAttributeByType} from 'containers/mrv/monitoring/module/monitoring.selectors';
import {
  selectCurrentProgramId,
  selectPhaseStages,
} from 'containers/mrv/monitoring/module/selectors';
import type {MRVAttribute, WithValue} from 'containers/mrv/types';
import {MRVAttributeType, MRVStageType, MRVPhaseType} from 'containers/mrv/types';
import {useAppSelector} from '_hooks';
import {isAttributeDisabledByDependencyRules} from './attribute-dependency-logic';

/**
 * Checks whether an attribute should be disabled
 * either by the dependency rules
 * or by the program 21 hack.
 */
export function useIsAttributeDisabled() {
  const programId = useAppSelector(selectCurrentProgramId);

  const stages = useAppSelector(s => selectPhaseStages(s, MRVPhaseType.Monitoring));

  const winterCropTerminationAttribute = useAppSelector(s =>
    selectCurrentProgramMonitoringAttributeByType(s, MRVAttributeType.WinterCropTermination)
  );
  const summerCropTypeAttribute = useAppSelector(s =>
    selectCurrentProgramMonitoringAttributeByType(s, MRVAttributeType.SummerCropType)
  );
  const recordYearAttribute = useAppSelector(s =>
    selectCurrentProgramMonitoringAttributeByType(s, MRVAttributeType.RecordYear)
  );
  const winterCropCommitmentAttribute = useAppSelector(s =>
    selectCurrentProgramMonitoringAttributeByType(s, MRVAttributeType.WinterCropCommitment)
  );
  const tillagePracticeAttribute = useAppSelector(s =>
    selectCurrentProgramMonitoringAttributeByType(s, MRVAttributeType.TillagePractice)
  );

  function isAttributeDisabled(attribute: MRVAttribute, values: Record<number, WithValue>) {
    return (
      isAttributeDisabledByDependencyRules(attribute.dependencies, values) ||
      (programId === 21 && !isProgram21AttributeEnabledHack(attribute, values))
    );
  }

  /**
   * This hack is needed to override "Cargill RegenConnect™️ 2021" (#21), because the custom dependency logic OR feature isn't implemented yet.
   * Currently waiting on UI designs to be approved. And work to be prioritized.
   * https://regrow.atlassian.net/browse/FSB-10438
   */
  function isProgram21AttributeEnabledHack(
    attribute: MRVAttribute,
    values: Record<number, WithValue>
  ): boolean {
    const inSummerCrops = stages.some(
      s => s.type_ === MRVStageType.SummerCrops && s.id === attribute.parent_stage_id
    );
    const inWinterCrops = stages.some(
      s => s.type_ === MRVStageType.WinterCrops && s.id === attribute.parent_stage_id
    );
    const inTillage = stages.some(
      s => s.type_ === MRVStageType.Tillage && s.id === attribute.parent_stage_id
    );

    if (inSummerCrops) {
      const cropTypeIsNotFallow =
        !!summerCropTypeAttribute?.id &&
        !!values[summerCropTypeAttribute.id]?.value &&
        values[summerCropTypeAttribute.id]?.value !== 'fallow';

      const recordYear = !!recordYearAttribute?.id && values[recordYearAttribute.id]?.value;

      if (
        attribute.type === MRVAttributeType.SummerDryYield ||
        attribute.type === MRVAttributeType.IrrigationMethod ||
        attribute.type === MRVAttributeType.YieldRateUnit
      ) {
        return cropTypeIsNotFallow;
      }

      if (
        attribute.type === MRVAttributeType.SummerHarvestDate ||
        attribute.type === MRVAttributeType.SummerResidueHarvested
      ) {
        return cropTypeIsNotFallow && recordYear === '2021';
      }

      if (attribute.type === MRVAttributeType.SummerPlantingDate) {
        return cropTypeIsNotFallow && recordYear === '2022';
      }
    }

    if (inWinterCrops) {
      const cropTerminationIsGrainOrForageHarvest =
        !!winterCropTerminationAttribute?.id &&
        (values[winterCropTerminationAttribute.id]?.value === 'Grain Harvest' ||
          values[winterCropTerminationAttribute.id]?.value === 'Forage Harvest');

      const winterCropCommitmentIsOn =
        !!winterCropCommitmentAttribute?.id &&
        values[winterCropCommitmentAttribute.id]?.value === '1';

      if (
        attribute.type === MRVAttributeType.WinterDryYield ||
        attribute.type === MRVAttributeType.YieldRateUnit
      ) {
        return cropTerminationIsGrainOrForageHarvest && winterCropCommitmentIsOn;
      }

      if (
        attribute.type === MRVAttributeType.WinterCropType ||
        attribute.type === MRVAttributeType.WinterPlantingDate ||
        attribute.type === MRVAttributeType.WinterHarvestDate ||
        attribute.type === MRVAttributeType.WinterCropTermination
      ) {
        return winterCropCommitmentIsOn;
      }
    }

    if (inTillage) {
      const tillagePracticeIsNotNoTill =
        !!tillagePracticeAttribute?.id &&
        !!values[tillagePracticeAttribute.id]?.value &&
        values[tillagePracticeAttribute.id]?.value !== 'no till';

      if (
        attribute.type === MRVAttributeType.TillageDate ||
        attribute.type === MRVAttributeType.TillageDepth
      ) {
        return tillagePracticeIsNotNoTill;
      }
    }

    return true;
  }

  return isAttributeDisabled;
}
