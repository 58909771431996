import {PracticeAdoptions} from 'containers/si/planning-report/programPlan/pages/PracticeAdoption/PracticeAdoption';
import {ProgramDetails} from 'containers/si/planning-report/programPlan/pages/ProgramDetail/ProgramDetails';
import {ScenarioSelection} from 'containers/si/planning-report/programPlan/pages/ScenarioSelection';
import {SIPageTitle, SISectionContainer} from 'containers/si/si-styled-components';
import type {PlanningReport} from 'containers/si/types';
import React from 'react';
import {Text} from 'components';
import {EstimatedProgramOutcomes} from 'containers/si/planning-report/programPlan/components/EstimatedProgramOutcomes';
import styled from 'styled-components';
import {ProgramPlanStepper} from 'containers/si/planning-report/programPlan/components/ProgramPlanStepper';

interface ProgramPlanReportProps {
  report: PlanningReport;
}

export const ProgramPlanReport = ({report}: ProgramPlanReportProps) => {
  const [currentStep, setCurrentStep] = React.useState<number>(0);

  const stepScreens = React.useMemo(
    () => [
      <ProgramDetails key={'program-details'} report={report} />,
      <ScenarioSelection key={'scenario-selection'} report={report} />,
      <PracticeAdoptions key={'practice-adoption'} report={report} />,
    ],
    [report]
  );

  return (
    <StyledSISectionContainer>
      <div>
        <SIPageTitle>Program design{report.name && `: ${report.name}`}</SIPageTitle>
        <Text>
          Examine a specific area and identify crops and intervention scenarios to generate ex-ante
          estimates for carbon outcomes and financial estimates of generated credits.
        </Text>
      </div>

      <ProgramPlanStepper currentStep={currentStep} setCurrentStep={setCurrentStep} />

      {currentStep > 0 && <EstimatedProgramOutcomes />}

      <div>{stepScreens[currentStep]}</div>
    </StyledSISectionContainer>
  );
};

const StyledSISectionContainer = styled(SISectionContainer)`
  gap: 24px;
`;
