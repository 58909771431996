import {t} from 'i18n-utils';
import type {ComponentType} from 'react';
import React, {useEffect, useMemo, useState} from 'react';
import IntegrationAgworld from './integration-agworld';
import IntegrationAgx from './integration-agx';
import IntegrationClimate from './integration-climate';
import IntegrationAgrian from './integration-agrian';
import IntegrationEFC from './integration-efc';
import IntegrationJohnDeere from './integration-john-deere';
import IntegrationPW from './integration-productionwise';
import OrderPlanetImagery from '../../map/features/data-layers/order-hight-resolution-imagery';
import cn from 'classnames';
import './integration.scss';
import {useAppDispatch, useAppSelector} from '_hooks';
import {CircularProgress, Collapse, FontIcon} from 'react-md';
import {getAuthorizedPlatforms, setSelectedPlatformToImport} from './actions';
import {ExternalService} from '../../map/types';
import type {IntegrationComponentProps, IntegrationPlatform} from './types';
import {AsyncStatusType, Status} from '../../../modules/helpers';
import {selectAsyncRequestStatus} from '../../../modules/global/selectors';
import {selectSelectedPlatformToImport, selectUserFavoritePlatforms} from './integration-selectors';
import {contactSupportTeam} from '_utils/pure-utils';
import IntegrationFarmlogs from 'containers/profile/integration/integration-farmlogs';
import {selectCurrentProgram} from 'containers/mrv/monitoring/module/selectors';
import {useWorkspace} from '_hooks/use-workspace';

type PlatformRecord = {
  platform: IntegrationPlatform;
  component: ComponentType<IntegrationComponentProps>;
};

const IntegrationPlatformComponents: PlatformRecord[] = [
  {platform: ExternalService.JohnDeere, component: IntegrationJohnDeere},
  {platform: ExternalService.Climate, component: IntegrationClimate},
  {platform: ExternalService.Agx, component: IntegrationAgx},
  {platform: ExternalService.Agworld, component: IntegrationAgworld},
  {platform: ExternalService.EFC, component: IntegrationEFC},
  {platform: ExternalService.Agrian, component: IntegrationAgrian},
  {platform: ExternalService.ProductionWise, component: IntegrationPW},
  {platform: ExternalService.FarmLogs, component: IntegrationFarmlogs},
];

enum IntergrationPlatformNameConverter {
  'John Deere' = 'JohnDeere',
  'Climate FieldView' = 'Climate',
  Proagrica = 'Agx',
  Agworld = 'Agworld',
  FieldAlytics = 'EFC',
  Agrian = 'Agrian',
  ProductionWise = 'ProductionWise',
  FarmLogs = 'FarmLogs',
}

const ProfileIntegration = ({isPopUpView}: {isPopUpView: boolean}) => {
  const {isWorkspaceMrv} = useWorkspace();
  const dispatch = useAppDispatch();
  const program = useAppSelector(selectCurrentProgram);

  const userFavoritePlatforms = useAppSelector(selectUserFavoritePlatforms);
  const selectedPlatformToImport = useAppSelector(selectSelectedPlatformToImport);

  const authorizedPlatformsStatus = useAppSelector(s =>
    selectAsyncRequestStatus(s, AsyncStatusType.authorizedPlatforms)
  );
  const isLoadingConnection = authorizedPlatformsStatus === Status.Pending;

  const [showMoreCollapsed, toggleShowMore] = useState(true);

  useEffect(() => {
    if (authorizedPlatformsStatus === Status.Todo) {
      dispatch(getAuthorizedPlatforms());
    }
    return () => {
      dispatch(setSelectedPlatformToImport(null)); // unselect a platform when leaving this feature
    };
  }, []);

  const {favoritePlatformsComponents, notFavoritePlatformsComponents} = useMemo(() => {
    const classifiedPlatformsList = IntegrationPlatformComponents.filter(({platform}) => {
      const activeOptions = (program?.fms_options ?? []).map(option => {
        // @ts-expect-error error leftover from convertion to strict mode, please fix
        return ExternalService[IntergrationPlatformNameConverter[option]];
      });
      return isWorkspaceMrv ? activeOptions.includes(platform) : true;
    });

    let favoritePlatforms = classifiedPlatformsList.filter(
      ({platform}) => userFavoritePlatforms[platform]
    );

    let notFavoritePlatforms = classifiedPlatformsList.filter(
      ({platform}) => !userFavoritePlatforms[platform]
    );

    if (!favoritePlatforms.length) {
      favoritePlatforms = classifiedPlatformsList;
      notFavoritePlatforms = [];
    }

    const returnPlatformComponent = ({component: PlatformComponent}: PlatformRecord) => (
      <PlatformComponent expanded={false} />
    );

    return {
      favoritePlatformsComponents: favoritePlatforms.map(returnPlatformComponent),
      notFavoritePlatformsComponents: notFavoritePlatforms.map(returnPlatformComponent),
    };
  }, [dispatch, userFavoritePlatforms]);

  const SelectedPlatform = useMemo(() => {
    return (
      IntegrationPlatformComponents.find(({platform}) => platform === selectedPlatformToImport)
        ?.component || null
    );
  }, [selectedPlatformToImport]);

  const titleText = useMemo(() => {
    if (!selectedPlatformToImport) return t({id: 'Connect to your farm management system'});

    return isWorkspaceMrv
      ? t({id: 'Import operations from your farm management system'})
      : t({id: 'Import fields from your farm management system'});
  }, [isWorkspaceMrv, selectedPlatformToImport]);

  return isPopUpView && isLoadingConnection ? (
    <div className="integrations-loader-container">
      <CircularProgress id="loading-connection-status" />
    </div>
  ) : (
    <div
      className={cn('integration-platforms', {
        'pop-up': isPopUpView,
      })}
    >
      <h3>{titleText}</h3>

      {selectedPlatformToImport ? null : (
        <h5 className={'section-subtitle'}>
          {t({
            id: 'Select which system you would like to connect with so we can import your fields and management records.',
          })}
        </h5>
      )}

      {!SelectedPlatform ? (
        <div className="integration-platforms-container">
          {favoritePlatformsComponents}

          {notFavoritePlatformsComponents.length ? (
            <>
              <div className={'show-more-row'} onClick={() => toggleShowMore(!showMoreCollapsed)}>
                Show more
                <FontIcon>
                  {showMoreCollapsed ? 'keyboard_arrow_right' : 'keyboard_arrow_down'}
                </FontIcon>
              </div>
              <Collapse collapsed={showMoreCollapsed}>
                <>{notFavoritePlatformsComponents}</>
              </Collapse>
            </>
          ) : null}
        </div>
      ) : null}

      <OrderPlanetImagery />

      {SelectedPlatform ? <SelectedPlatform expanded={true} /> : null}

      {!selectedPlatformToImport ? (
        <div className={'contact-us-container'}>
          {t({id: "Don't see your FMS?"})} {contactSupportTeam(`${t({id: 'Contact us'})}.`)}
        </div>
      ) : null}
    </div>
  );
};

export default ProfileIntegration;
