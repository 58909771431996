import {convertUnit} from '_utils';
import {kiloFormatter} from '_utils/number-formatters';
import type {Measurement} from 'containers/login/types';

/**
 * This is modified from a similar function in `_utils/index`
 * It should be replaced when _utils/index supports a better translation (currently 'ha': 'ha' and 'ac':'ac')
 */
export function classifyAreaWithUnits({
  area,
  measurement,
  abbreviated = false,
}: {
  area: number | undefined;
  measurement: Measurement;
  abbreviated?: boolean;
}) {
  const mDisplay = measurement === 'ha' ? 'hectares' : 'acres';

  return `${kiloFormatter(convertUnit(measurement, 'ac', area ?? 0))} ${
    abbreviated ? measurement : mDisplay
  }`;
}
