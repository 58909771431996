import Ln from 'components/ln';
import React from 'react';
import type {Props, Messages} from '../types';
import {IrrigationImageIcon} from 'containers/map/icons';
import {Footer} from '../helpers';

import {
  CropStressIcon,
  TreeIcon,
  AnalyticsIcon,
  PolygonIcon,
  GreenBoldText,
  DroneIcon,
  TargetIcon,
  PinIcon,
  ZoningIcon,
} from '../icons.styled';

import InfoBlock from 'components/info-block';

const data: Messages = {
  211027: {
    title: 'Premium feature: tree detection',
    width: 430,
    element: (props: Props) => (
      <div className="status-alert-dialog">
        <div className="status-alert-dialog__main">
          <div>
            To turn imagery into <b>Tree detection</b> - click <TreeIcon /> tree icon on the lower
            left. Here is how we enable it:
            <br />
            <br />
            <GreenBoldText>1.</GreenBoldText> Retrieve and process <b>high-resolution imagery</b>,
            <br />
            <GreenBoldText>2.</GreenBoldText> Detect and remove of the <b>soil</b>, <b>shadow</b>{' '}
            and <b>all non-tree</b> pixels, creating a <span>tree focus</span> layer,
            <br />
            <GreenBoldText>3.</GreenBoldText> Output a <span>tree detection</span> layer (each
            circle = tree) by analysing the <b>planting pattern</b> in an orchard.
            <br />
            <br />
            Switch between these layers to see how it works!
          </div>
        </div>

        <Footer {...props} />
      </div>
    ),
  },

  220036: {
    title: 'Irrigation patterns in orchards',
    element: (props: Props) => (
      <div className="status-alert-dialog">
        <div className="status-alert-dialog__main">
          <div style={{paddingRight: 20}}>
            <IrrigationImageIcon />
          </div>
          <div>
            Here is a block of <b>almonds trees</b> with distinct vertical stripes - signature{' '}
            <b>pattern of drip irrigation</b>. Browse through the Date dropdown to see how trees
            respond to the variable irrigation over time.
          </div>
        </div>

        <Footer {...props} />
      </div>
    ),
  },

  148770: {
    title: 'Detect and address disease in trees',
    width: 430,
    element: (props: Props) => (
      <div className="status-alert-dialog">
        <div className="status-alert-dialog__main">
          <div>
            Explore how <DroneIcon /> <b>drone imagery</b> can be used to <b>detect disease</b> (in
            this case, chlorosis) in orchards.
            <br />
            <br />
            The last row of trees in this block appears redder than neighboring trees. After{' '}
            <b>targeted</b> <TargetIcon /> <b>scouting</b> and <b>tissue testing</b> it was
            identified that those trees had high bicarbonate levels from irrigation water, which was
            addressed by flushing the soils.
            <br />
            <br />
            Switch to the <b>Zoning tab</b> <ZoningIcon /> and select the{' '}
            <b>Worst performing 15%</b> to find the trees that got diseased and then switch to image
            from <b>7th, June</b> to see how they recovered.
          </div>
        </div>

        <Footer {...props} />
      </div>
    ),
  },

  312: {
    title: 'Visualise spatial variability over time',
    width: 430,
    element: (props: Props) => (
      <div className="status-alert-dialog">
        <div className="status-alert-dialog__main">
          <div>
            Analytics tab <AnalyticsIcon />
            allows you to combine analysis of <i>spatial variability</i> with <b>time-series</b> to
            get powerful insights.
            <br />
            <br />
            To plot a trend simply click <PinIcon /> anywhere on the field. To plot an average trend
            of an area, you will need to first draw a polygon <PolygonIcon /> in Areas of Interest{' '}
            <CropStressIcon />
            .
            <br />
            <br />
            Try selecting management blocks in this orchard.
          </div>
        </div>

        <Footer {...props} />
      </div>
    ),
  },

  161362: {
    title: 'Detection of issues with irrigation system',
    element: (props: Props) => (
      <div className="status-alert-dialog">
        <div className="status-alert-dialog__main">
          <div style={{paddingRight: 20}}>
            <IrrigationImageIcon />
          </div>
          <div>
            <b>Pipe leaks</b> and <b>blockages</b> in orchards can be detected using satellite
            imagery.
            <br />
            <br />
            Click on <span>Detection History</span> in the <CropStressIcon />
            crop stress popup on the map to see how stress evolved over time.
          </div>
        </div>

        <InfoBlock appearance="info">
          <i>
            Want to detect issues with your irrigation?{' '}
            <Ln
              external
              blank
              href="https://www.regrow.ag/post/detecting-irrigation-issues-in-an-olive-grove"
            >
              Read the case study here.
            </Ln>
          </i>
        </InfoBlock>

        <Footer {...props} />
      </div>
    ),
  },

  153018: {
    title: 'Tracking changes in tree health',
    width: 430,
    element: (props: Props) => (
      <div className="status-alert-dialog">
        <div className="status-alert-dialog__main">
          <div>
            With consistent <TreeIcon /> tree detection, you can see how <b>systematic changes</b>{' '}
            in orchard management result in <b>improved</b> orchard <b>performance</b>.
            <br />
            <br />
            Explore how the health of these Hazelnuts changed <b>over time</b>, and which area
            performed best <b>overall</b>.
          </div>
        </div>

        <Footer {...props} />
      </div>
    ),
  },
};

export default data;
