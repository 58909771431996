import React, {useEffect, useMemo, useState} from 'react';
import L from 'leaflet';
import {selectDrawnFieldGeometries} from 'modules/add-fields';
import {
  selectAddingFieldsStep,
  selectBoundariesToUpload,
  selectBoundaryIdsToUploadMap,
  selectGeoJsonFiles,
  selectIsPreviewingAddingFields,
  selectProtectedAreaBoundaries,
} from 'modules/add-fields/selectors';
import {FieldSystemProp} from '../features/farm/new-fields/types';
import {reportError} from '../../error-boundary';
import {MAIN_SHAPE_COLOR, PANEL_WIDTH} from '_constants';
import {useAppDispatch, useAppSelector} from '_hooks';
import colors from 'variables.scss';
import {MapPreloader} from 'components/image-layer-preloader';
import {AsyncStatusType} from 'modules/helpers';
import {FluroGeoJson, FluroMapPopup} from 'components/fluro-leaflet';
import {selectHighlightedFieldId} from '../reducer/selectors';
import {highlightField} from '../actions';
import {getShapeCoordinates} from '_utils/geometry';
import {featureCollection} from '@turf/turf';
import {resolveEventHandlers} from 'components/fluro-leaflet/fix-click-handler';

type Props = {
  fitBounds: (bounds?: L.LatLngBounds, forceRightMargin?: boolean) => void;
};

export const AddFieldsOverlay = ({fitBounds}: Props) => {
  const dispatch = useAppDispatch();
  const drawnFieldsGeometries = useAppSelector(selectDrawnFieldGeometries);
  const geoJsonFiles = useAppSelector(selectGeoJsonFiles);
  const currentStep = useAppSelector(selectAddingFieldsStep);
  const boundariesToUpload = useAppSelector(selectBoundariesToUpload);
  const [geometryKey, setGeometryKey] = useState(1);
  const previewFields = useAppSelector(selectIsPreviewingAddingFields);
  const boundaryIdsToUploadMap = useAppSelector(selectBoundaryIdsToUploadMap);
  const protectedAreaBoundaries = useAppSelector(selectProtectedAreaBoundaries);
  const highlightedFieldId = useAppSelector(selectHighlightedFieldId);

  const fieldsToProcess = useMemo(() => {
    setGeometryKey(geometryKey + 1);

    switch (currentStep) {
      case 'view-fields-from-files':
        return geoJsonFiles;

      case 'view-drawn-fields':
      case 'draw-fields': // we should see drawn geometries both when we draw and when we preview fields
        return drawnFieldsGeometries;

      case 'view-selected-boundaries':
        return boundariesToUpload;

      default:
        return [];
    }
  }, [currentStep, geoJsonFiles, drawnFieldsGeometries, boundariesToUpload]);

  useEffect(() => {
    if (currentStep === 'view-fields-from-files') {
      onFitBounds(); // center the map when open uploaded fields preview
    }
  }, [currentStep]);

  useEffect(() => {
    if (!fieldsToProcess.length) return; // avoid centering for no geometries case
    if (fieldsToProcess.length === 1 && currentStep === 'draw-fields') return; // do not zoom to the first drew fields (it is already in the view)

    onFitBounds(); // reflect on fields number changing
  }, [fieldsToProcess.length]);

  const onFitBounds = () => {
    if (fieldsToProcess.length) {
      try {
        const collection = featureCollection(fieldsToProcess);
        const geoJSON = L.geoJSON(collection);
        // @ts-expect-error error leftover from convertion to strict mode, please fix
        fitBounds(geoJSON.getBounds(), undefined, true);
      } catch (err) {
        reportError(`tried fitBounds in addning-new-fields-geometries.tsx ${err}`);
      }
    }
  };

  if (
    ![
      'view-fields-from-files',
      'view-drawn-fields',
      'view-selected-boundaries',
      'draw-fields',
      'select-boundaries',
    ].includes(currentStep)
  )
    return null;

  const onPopupClose = (fieldId: string) => {
    if (fieldId === highlightedFieldId) {
      dispatch(highlightField(undefined));
    }
  };

  return (
    <>
      {fieldsToProcess.map(field => {
        const id = field.properties[FieldSystemProp.Id];
        const shouldShowPopup = id === highlightedFieldId;
        const selected =
          previewFields || boundaryIdsToUploadMap[id] || field.properties[FieldSystemProp.Checked]; // keep all selected during preview step
        const isProtected = protectedAreaBoundaries[id];
        const [lng = 0, lat = 0] = shouldShowPopup ? getShapeCoordinates(field) : [];

        return (
          <React.Fragment key={id}>
            <FluroGeoJson
              pathOptions={{
                color: isProtected ? colors['saffron'] : '#fff',
                fillColor: selected ? MAIN_SHAPE_COLOR : 'transparent',
                className: `field-boundary-shape ${selected ? 'selected' : ''}`, // add the class for cypress needs
                weight: 2,
                fillOpacity: 1,
              }}
              data={field}
              eventHandlers={resolveEventHandlers({
                click: () => dispatch(highlightField(id)),
              })}
            />
            {shouldShowPopup ? (
              <FluroMapPopup
                autoPanPaddingBottomRight={[100, PANEL_WIDTH]}
                autoPanPaddingTopLeft={[100, 100]}
                onClose={() => onPopupClose(id)}
                position={{lat, lng}}
              >
                <div className="margin-right-10">{field.properties[FieldSystemProp.FieldName]}</div>
              </FluroMapPopup>
            ) : null}
          </React.Fragment>
        );
      })}
      <MapPreloader requestTypes={[AsyncStatusType.cluFieldBoundaries]} />
    </>
  );
};

export default AddFieldsOverlay;
