import {Card} from 'containers/si/kpi/styled-components';
import type {ComponentType} from 'react';
import React, {Fragment} from 'react';
import {CircularProgress} from 'react-md';
import type {ChartHeaderProps} from './components/chart-header';
import {ChartHeader} from './components/chart-header';
import type {ChartMiddleDataProps} from './components/chart-middle-data';
import {ChartMiddleData} from './components/chart-middle-data';
import type {SparklineProps} from './components/sparkline-chart';
import {SparklineChart} from './components/sparkline-chart';
import {CenterContent} from 'containers/si/si-styled-components';

export interface SparklingChartProps
  extends ChartHeaderProps,
    ChartMiddleDataProps,
    SparklineProps {
  className?: string;
  loading?: boolean;
}
export const SparklingChartCard: ComponentType<SparklingChartProps> = ({
  title,
  subtitle,
  cardCenterNumber,
  cardCenterUnits,
  className,
  tooltip,
  loading,
  ...sparklineProps
}) => {
  return (
    <Card className={className}>
      <ChartHeader title={title} subtitle={subtitle} tooltip={tooltip} />
      {loading ? (
        <CenterContent>
          <CircularProgress id="sparkline-loading" />
        </CenterContent>
      ) : (
        <Fragment>
          <ChartMiddleData cardCenterNumber={cardCenterNumber} cardCenterUnits={cardCenterUnits} />
          <SparklineChart {...sparklineProps} />
        </Fragment>
      )}
    </Card>
  );
};
