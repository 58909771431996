import React, {useCallback} from 'react';
import type {Map} from 'leaflet';
import {EditFieldControlButtons} from 'components/edit-field-control-buttons';
import {reportError} from '../../error-boundary';
import {t} from '../../../i18n-utils';
import {saveEditField, toggleEditingMode2, toggleFieldKml, toggleMapBar} from '../actions';
import {useDispatch} from 'react-redux';
import {showNotification} from 'components/notification/notification';
import {useAppSelector} from '../../../_hooks';
import {fitBoundCurrentEditFieldKml} from '../../../_utils';

export const EditFieldButtonsContainer = ({map}: {map: Map}) => {
  const dispatch = useDispatch();
  const isEditingMode = useAppSelector(s => s.map.drawControl.isEditingMode);
  const fieldId = useAppSelector(s => s.map.drawControl.fieldId);

  const finishEdit = useCallback(
    (shouldSave = false) => {
      map.pm.disableGlobalEditMode();
      const layers: any = [];

      if (shouldSave) {
        map.eachLayer(layer => {
          //@ts-expect-error error leftover from convertion to strict mode, please fix
          if (layer.__fluroLayerToEdit) {
            layers.push(layer);
          }
        });

        if (layers.some((layer: any) => layer.__fluroLayerEdited)) {
          const data = layers.map((layer: any) => layer.toGeoJSON());

          if (data.length >= 2) {
            reportError('Feature collection field edit error');

            showNotification({
              title: t({id: 'note.warning', defaultMessage: 'Warning'}),
              message: t({
                id: 'Feature collection field edit error',
              }),
              type: 'warning',
            });

            return;
          }

          if (data.length) {
            dispatch(saveEditField(data[0]));
          }
        }
        return;
      }

      // show/hide the shape to undo changes
      dispatch(toggleFieldKml(false));
      dispatch(toggleFieldKml(true));
      dispatch(toggleMapBar(true));

      fitBoundCurrentEditFieldKml(true);

      dispatch(toggleEditingMode2(false));
    },
    [map]
  );

  const onCancel = useCallback(() => finishEdit(), [finishEdit]);

  const onSave = useCallback(() => finishEdit(true), [finishEdit]);

  return isEditingMode && fieldId ? (
    <EditFieldControlButtons onSave={onSave} onCancel={onCancel} />
  ) : null;
};
