// @ts-nocheck
import type {FluroButtonProps} from 'components/fluro-button/fluro-button';
import {FluroButton} from 'components/fluro-button/fluro-button';
import {PolygonIcon} from 'containers/map/icons';
import {
  AddMins,
  EnrollmentIcon,
  FertilizationIcon,
  InfoIcon,
  OutcomesIcon,
  SummerCropIcon,
  SurveyIcon,
  TillageIcon,
  WinterCropIcon,
} from 'containers/mrv/icons';
import type {
  AddAUserToAProgramFormData,
  InviteMRVUserPayload,
  MRVPhaseNormalized,
} from 'containers/mrv/types';
import {MRVPhaseType, MRVStageType} from 'containers/mrv/types';
import {t} from 'i18n-utils';
import moment from 'moment';
import React from 'react';
import {FontIcon} from 'react-md';
import cn from 'classnames';
import {DATE_TIME} from '_constants';

type EditButtonProps = FluroButtonProps & {
  testId: string;
  callToAction?: boolean;
};
export const EditButton = ({testId, callToAction, ...rest}: EditButtonProps) => {
  return (
    <FluroButton
      flat
      blank
      {...rest}
      data-testid={testId}
      className={cn({
        'animation--pulsate-shadow': callToAction,
      })}
    >
      edit
    </FluroButton>
  );
};

export const AddButton = (props: FluroButtonProps) => (
  <FluroButton flat blank iconEl={<FontIcon>add</FontIcon>} {...props}>
    {t({id: 'BtnLabel.Add', defaultMessage: 'add'})}
  </FluroButton>
);

// icons are fetched from backend, we just need a value defined here for type checking
// this will be refactored to be removed at some point
// as a result we just default to SummerCropIcon
export const monitoringStageIcons: {[type in MRVStageType]: JSX.Element} = {
  [MRVStageType.FieldBoundaries]: <PolygonIcon style={{color: '#fff'}} />,
  [MRVStageType.AssignPractices]: <AddMins />,
  [MRVStageType.ConfirmHistory]: <SummerCropIcon />,
  [MRVStageType.SummerCrops]: <SummerCropIcon />,
  [MRVStageType.WinterCrops]: <WinterCropIcon />,
  [MRVStageType.Tillage]: <TillageIcon />,
  [MRVStageType.NutrientManagement]: <FertilizationIcon />,
  [MRVStageType.NutrientManagementIntended]: <FertilizationIcon />,
  [MRVStageType.ViewOutcomes]: <OutcomesIcon />,
  [MRVStageType.Survey]: <SurveyIcon />,
  [MRVStageType.Contract]: <EnrollmentIcon />,
  [MRVStageType.Eligibility]: <EnrollmentIcon />,
  [MRVStageType.FieldInformation]: <InfoIcon />,
  [MRVStageType.RiceCropHistory]: <SummerCropIcon />,
  [MRVStageType.HistoricalCropRotation]: <SummerCropIcon />,
  [MRVStageType.HistoricalTillage]: <TillageIcon />,
  [MRVStageType.IntendedCommodityCrops]: <SummerCropIcon />,
  [MRVStageType.Irrigation]: <SummerCropIcon />,
  [MRVStageType.MobHistory]: <SummerCropIcon />,
  [MRVStageType.MobMovement]: <SummerCropIcon />,
  [MRVStageType.FarmLevelManagement]: <SummerCropIcon />,
};

export const classifyInviteUsersPayload = (formData: AddAUserToAProgramFormData) => {
  return formData.programs.map(programId => {
    const resultPayload: InviteMRVUserPayload = {
      program_id: programId,
      email: formData.email,
      first_name: formData.first_name,
      last_name: formData.last_name,
    };
    if (formData.prevent_email) {
      resultPayload.prevent_email = true;
    }

    return resultPayload;
  });
};

export const createPhase = (phaseType?: MRVPhaseType, startDate?: string, endDate?: string) => {
  let phase: Partial<MRVPhaseNormalized> = {
    type_: phaseType,
    start_date: startDate || moment().format(DATE_TIME),
    end_date: endDate || moment().format(DATE_TIME),
  };

  if (phaseType === MRVPhaseType.Enrollment) {
    phase = {
      ...phase,
      ...{
        show_contract: false,
        contract_type: 'Docusign',
        params: {
          limit_program_ha: 0,
          limit_project_ha: 0,
          outcome_estimation: true,
        },
      },
    };
  }
  return phase;
};
