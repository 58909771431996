import {FluroDialog} from 'components';
import React, {useEffect} from 'react';
import styled from 'styled-components';
import type {MRVStageNormalized, MRVSurveySubmission} from '../types';

export const SurveyDialog = ({
  stage,
  surveyId,
  onSubmit,
  onHide,
}: {
  stage: MRVStageNormalized;
  surveyId: string;
  onSubmit: (submissionId: string) => void;
  onHide: () => void;
}) => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://paperform.co/__embed.min.js';
    document.body.appendChild(script);
    const handleSubmit = (e: CustomEvent<MRVSurveySubmission>) => {
      const submissionId = e.detail.submission_id;
      onSubmit(submissionId);
    };
    document.addEventListener('PaperformSubmission', handleSubmit);
    return () => {
      document.removeEventListener('PaperformSubmission', handleSubmit);
    };
  }, [stage?.id]);

  return (
    <FluroDialog id={'survey'} onHide={onHide} width={600} visible portal>
      <Content data-paperform-id={surveyId} data-spinner={true}></Content>
    </FluroDialog>
  );
};

const Content = styled.div`
  iframe {
    max-height: 600px !important;
    margin-top: 24px;
  }
`;
