import {SubscriptionsApi, AdminApi, ActivityApi} from '_api';
import {naturalSortAlphaNum} from '_utils/sorters';

enum ActionType {
  SetPickerData = '@pickers/set',
}

export type PickerItem = {
  label: string;
  value: string | number;
  /*
   *
   * PARENT examples:
   *
   * organization => parent: subscription
   * variety => parent: crop type
   * region => parent: country
   *
   * */
  parent?: string | number;
};

export type PickersStore = {
  countries: PickerItem[];
  regions: PickerItem[];
  organizations: PickerItem[];
  subscriptions: PickerItem[];
  crops: PickerItem[];
  cropVarieties: PickerItem[];
  years: PickerItem[];
};

const initialState: PickersStore = {
  countries: [],
  regions: [],
  organizations: [],
  subscriptions: [],
  crops: [],
  cropVarieties: [],
  years: [
    {label: '2015', value: 2015},
    {label: '2016', value: 2016},
    {label: '2017', value: 2017},
    {label: '2018', value: 2018},
    {label: '2019', value: 2019},
    {label: '2020', value: 2020},
    {label: '2021', value: 2021},
    {label: '2022', value: 2022},
  ],
};

export const setPickerData = (pickerType: keyof PickersStore, data: any[]) => ({
  type: ActionType.SetPickerData,
  pickerType,
  data: naturalSortAlphaNum(data, 'label'),
});

export const loadCountries = () => (dispatch: any) => {
  return AdminApi.getCountries().then(({data}) => {
    dispatch(
      setPickerData(
        'countries',
        (data.result?.countries || []).map((value: string) => ({label: value, value}))
      )
    );
  });
};

export const loadRegions = () => (dispatch: any) => {
  return AdminApi.getRegions().then(({data}) => {
    dispatch(
      setPickerData(
        'regions',
        (data.result?.regions || []).map((el: any) => ({
          label: el.name,
          value: el.id,
          parent: el.country_name,
        }))
      )
    );
  });
};

export const loadOrganizations = () => (dispatch: any) => {
  return SubscriptionsApi.loadAllOrganizations().then(({data}) => {
    dispatch(
      setPickerData(
        'organizations',
        (data.result || []).map((el: any) => ({
          label: el.name,
          value: el.id,
          parent: el.subscription_id,
        }))
      )
    );
  });
};

export const loadSubscriptions = () => (dispatch: any) => {
  return SubscriptionsApi.loadSubscriptions().then(({data}) => {
    dispatch(
      setPickerData(
        'subscriptions',
        (data.result || []).map((el: any) => ({label: el.name, value: el.id}))
      )
    );
  });
};

export const loadCrops = () => (dispatch: any) => {
  return ActivityApi.getCropTypesList().then(({data}) => {
    const crops = data.result || [];

    dispatch(
      setPickerData(
        'crops',
        crops.map((el: any) => ({label: el.label, value: el.value}))
      )
    );

    const subtypes = crops
      .filter((c: any) => c.subtypes)
      .map((c: any) => c.subtypes.map((st: PickerItem) => ({...st, parent: c.value})))
      .flat();

    dispatch(setPickerData('cropVarieties', subtypes));
  });
};

export const reducer = (state = initialState, action: any) => {
  switch (action.type) {
    case ActionType.SetPickerData: {
      return {
        ...state,
        [action.pickerType]: action.data,
      };
    }

    default: {
      return state;
    }
  }
};
