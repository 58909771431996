import {Text} from 'components';
import {FluroGeoJson} from 'components/fluro-leaflet';
import {LOW_ABATEMENT_THRESHOLD} from 'modules/sustainability-insights/constants';
import {selectAbatementPotentialGeometryData} from 'modules/sustainability-insights/selectors';
import type {ComponentType} from 'react';
import React from 'react';
import {Tooltip} from 'react-leaflet';
import {useAppSelector} from '_hooks';
import {toFixedFloatUnsafe} from '_utils/number-formatters';
import type {GeometryProps} from './types';
import {isDefined} from '_utils/typeGuards';

export const AbatementPotentialGeometry: ComponentType<GeometryProps> = ({
  id,
  name,
  isHighlighted,
  isActive,
  onClick,
}) => {
  const geometryData = useAppSelector(s => selectAbatementPotentialGeometryData(s, id));

  if (!geometryData || !geometryData.geometry) return null;

  const {geometry, color, value} = geometryData;

  const defaultFillColor = '#777';
  const fillColor = color || defaultFillColor;
  const defaultPathOptions = {weight: 1};
  const resolveGeometryPathOptions = () => {
    if (!isActive) {
      return {
        fillColor: defaultFillColor,
        fillOpacity: 0.5,
        color: 'black',
      };
    }

    if (isHighlighted) {
      return {
        fillColor,
        fillOpacity: 1,
        color: 'black',
        weight: 4,
      };
    }

    return {
      fillColor,
      fillOpacity: 1,
      color: 'black',
    };
  };
  const geometryPathOptions = resolveGeometryPathOptions();

  const pathOptions = {
    ...defaultPathOptions,
    ...geometryPathOptions,
  };

  const eventHandlers = {
    click: onClick,
  };

  return (
    <FluroGeoJson data={geometry} pathOptions={pathOptions} eventHandlers={eventHandlers}>
      <Tooltip>
        <Text data-id={id} variant="small">
          {!isActive && name}
          {isActive && (
            <>
              {isDefined(value) && Number.isFinite(value) ? (
                value > LOW_ABATEMENT_THRESHOLD ? (
                  <>
                    Abatement potential for {name} is {toFixedFloatUnsafe(value, 1)} t/ac
                  </>
                ) : (
                  <>Low abatement potential for {name}</>
                )
              ) : (
                <>No data for {name}</>
              )}
            </>
          )}
        </Text>
      </Tooltip>
    </FluroGeoJson>
  );
};
