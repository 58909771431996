import React from 'react';
import {Flex} from 'components';
import {FluroCheckbox} from 'components/fluro-checkbox/fluro-checkbox';
import {practiceKPIIcons} from 'containers/si/icons';
import type {Intervention, InterventionOptions} from 'containers/si/types';
import {practiceKPILabel, PLANNING_REPORT_INTERVENTION_OPTIONS} from 'containers/si/constants';
import {Circle} from 'components/shapes/circle';
import '../create-report.scss';

type InterventionsCardProps = {
  intervention: InterventionOptions;
  allSelectedScenarios: Partial<Intervention>;
  onSelect: (intervention: InterventionOptions, selected: string) => void;
};

export const InterventionsCard = ({
  intervention,
  allSelectedScenarios,
  onSelect,
}: InterventionsCardProps) => {
  return (
    <div className="intervention-card">
      <Flex className="intervention-card-header">
        <Circle>{practiceKPIIcons[intervention]}</Circle>
        {practiceKPILabel[intervention]}
      </Flex>

      <div className="intervention-card-body">
        <>
          {PLANNING_REPORT_INTERVENTION_OPTIONS[intervention].map(scenario => {
            return (
              <FluroCheckbox
                key={scenario}
                label={scenario}
                value={allSelectedScenarios[intervention] === scenario}
                onChange={value => onSelect(intervention, value ? scenario : '')}
              />
            );
          })}
        </>
      </div>
    </div>
  );
};
