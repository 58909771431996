import type {FieldBoundary} from 'containers/map/features/farm/new-fields/types';
import {FieldSystemProp} from 'containers/map/features/farm/new-fields/types';
import type {Farm, Field} from 'containers/map/types';
import {ExternalService} from 'containers/map/types';
import type {EntitiesState} from 'modules/redux-utils/types';

export const isAgxFarm = (farm: Farm) => {
  const externalService = farm.external_service || farm.srcType || null;
  return externalService === ExternalService.Agx;
};

export const getFieldExternalServiceType = (field: Field): ExternalService | null => {
  // field.SrcType is deprecated
  // https://flurosat.atlassian.net/browse/FSB-3547?focusedCommentId=21519
  return field.external_service || field.SrcType || null;
};

export const isAgxField = (field: Field) =>
  getFieldExternalServiceType(field) === ExternalService.Agx;

export const createParsedPropertiesMap = (keys: string[]) => {
  return {
    farmName: keys.find(k => /^(farm)((\W|_|-)+name)/i.test(k.trim())) || '__currentFarm__',
    fieldName:
      keys.find(k => /^((field|item)(\W|_|-)+)?name|field$/i.test(k.trim())) ||
      FieldSystemProp.FileName,
    growerName: keys.find(k => /^grower$/i.test(k.trim())),
  };
};

export const getFieldFarmName = (
  field: FieldBoundary,
  farms: EntitiesState<Farm>,
  forcedFarm?: number | string
) => {
  const farm = forcedFarm || field.properties?.[FieldSystemProp.FarmId];
  const farmName = farm
    ? farms[farm]?.name || farm
    : field.properties?.[FieldSystemProp.NewFarmName] || forcedFarm;

  return farmName;
};
