import type {AppStore} from 'reducers';
import {createSelector} from '@reduxjs/toolkit';
import type {Field, Season} from '../types';
import {ZoningTab} from '../types';
import {isAustralianField, sortFieldsByProp} from '_utils';
import {toFixedFloatUnsafe} from '_utils/number-formatters';
import {getImagePath, createCachingSelector} from '_utils/pure-utils';
import type {TDateLayer} from 'containers/map/types';
import DEMO_FARMS_URLS from '_constants/demo-fields-urls';
import moment from 'moment';
import {selectAsyncRequestStatus} from 'modules/global/selectors';
import {AsyncStatusType, Status} from 'modules/helpers';
import {getTreeDataByDate} from 'containers/map/utils/trees';
import {isLegacyImageryURL} from '_utils/imagery-utils';

// Field selectors

export const selectFieldsByFarmId = (state: AppStore) => state.map.fieldsByFarmId;
export const selectCurrentField = (state: AppStore) => state.map.field;
export const selectCurrentFieldMean = (state: AppStore) => state.map.currentFieldMean;
export const selectCurrentFieldId = (state: AppStore) => state.map.selectedFieldId;
export const selectCurrentFieldKml = (state: AppStore) => state.map.currentFieldKml;
export const selectFieldGeometries = (s: AppStore) => s.map.fieldGeometries;
export const selectMapFields = (s: AppStore) => s.map.fields;
export const selectMapSelectedFields = createSelector([selectMapFields], fields =>
  fields.filter(f => f._selected)
);
export const selectMapFieldsSorting = (state: AppStore) => state.map.sortFields;

export const selectMapFieldById = createCachingSelector(
  [selectMapFields, (s: AppStore, fieldId: number) => fieldId],
  (fields, fieldId) => fields?.find(f => f.ID === fieldId)
);

export const selectSortedByNameMapFields = createSelector([selectMapFields], fields =>
  sortFieldsByProp(fields, 'Name', 'string')
);

export const selectHighlightedFieldId = (store: AppStore) => store.map.highlightedFieldId;

/**
 * A simple current field that is available right after groups are loaded.
 * As opposed to selectCurrentField which is a patched field with imageries,
 * which is loaded in map/index.
 */
export const selectCurrentField2 = createSelector(
  [selectMapFields, selectCurrentFieldId],
  (fields, fieldId) => {
    const field = fields.find(f => f.ID === fieldId);
    return field;
  }
);

type id = number;

export const selectFarmField = createSelector(
  [
    (s: AppStore, farmId: id, _kmlFieldId: id) => farmId,
    (s, _farmId, kmlFieldId: id) => kmlFieldId,
    selectFieldsByFarmId,
  ],
  (farmId, kmlFieldId, fieldsByFarmId) => {
    const field = fieldsByFarmId?.[farmId]?.[kmlFieldId];
    return field;
  }
);

// Get all the fields inside all the farms.
export const selectAllFields = createSelector([selectFieldsByFarmId], fieldsByFarmId => {
  let fields: {[fieldId: number]: Field} = {};
  Object.values(fieldsByFarmId).forEach(fs => {
    fields = {...fields, ...fs};
  });
  return fields;
});

// Get all the fields inside all the farms, and key them by field_id instead of kml_id.
export const selectAllFieldsByFieldId = createSelector([selectFieldsByFarmId], fieldsByFarmId => {
  const fields: {[fieldId: number]: Field} = {};
  Object.values(fieldsByFarmId).forEach(fs => {
    Object.values(fs).forEach(f => {
      fields[Number(f.FieldID)] = f;
    });
  });
  return fields;
});

/**
 * Very suboptimal implementation, don't use in performance critical code.
 */
export const selectFarmIdByFieldId = createSelector(
  [selectFieldsByFarmId, (s: AppStore, fieldId: id) => fieldId],
  (fieldsByFarmId, fieldId) => {
    const farmId = Object.keys(fieldsByFarmId)
      .map(Number)
      .find(farmId => fieldsByFarmId[farmId][fieldId]);
    return farmId;
  }
);

export const selectAllFieldsList = createSelector([selectAllFields], fields =>
  Object.values(fields)
);

// Season selectors

export const selectCurrentSeason = (state: AppStore) => state.map.currentSeason;

export const selectCurrentSeasonId = (state: AppStore) => state.map.currentSeasonId;

export const selectSeasonById = createSelector(
  [selectMapFields, selectCurrentField, (state: AppStore, seasonId: number) => seasonId],
  (fields, field, seasonId): Season | undefined => {
    let resultSeason: Season | undefined = undefined;

    field?.Seasons?.forEach(s => (resultSeason = s.id === seasonId ? s : undefined)); // search in the current field first (cause current field's season has more data)

    fields.forEach(f =>
      f.Seasons?.forEach(s => {
        if (resultSeason) return;
        resultSeason = s.id === seasonId ? s : undefined;
      })
    );
    return resultSeason;
  }
);

// Zoning selectors

export const selectZoning = (state: AppStore) => state.map.zoning;

export const selectIsZoning = (state: AppStore) => state.map.isZoning;

export const selectZoningTab = (state: AppStore) => selectZoning(state).tab;

export const selectIsNitrogenZoningTab = (state: AppStore) =>
  selectZoningTab(state) === ZoningTab.NitrogenRx;

// General map selectors

export const selectInfoExt = (state: AppStore) => state.map.infoExt;

export const selectFeatureGroupReDrawId = (state: AppStore) => state.map.featureGroupReDrawId;

export const selectCurrentTab = (state: AppStore) => state.map.feature;

export const selectCropVarietiesColors = (state: AppStore) => state.map.cropVarietyColors;
export const selectCropVarietiesMode = (state: AppStore) => state.map.cropVarietyMode;

export const selectIsWholeTableView = (state: AppStore) => state.map.wholeTableViewOpen;

// Whole farm selectors

export const selectWholeFarmData = (s: AppStore) => s.map.wholeFarm;

export const selectIsWholeFarmView = createSelector(
  [selectWholeFarmData],
  wholeFarmData => wholeFarmData.isWholeFarmView
);

// Global selectors

export const selectCurrentFarm = (state: AppStore) => state.map.group;

export const selectCurrentFarmId = createSelector([selectCurrentFarm], farm => farm.id);

export const selectIsMapBarOpen = (state: AppStore) => state.map.isMapBarOpen;

export const selectCurrentDemoFarmURL = createSelector(
  [selectCurrentFieldId, selectCurrentFarmId, selectIsWholeFarmView],
  (fieldId, farmId, isWholeFarm) =>
    DEMO_FARMS_URLS[`${farmId}/${isWholeFarm ? 'WholeFarm' : fieldId}`]
);

export const selectIsReadOnly = createSelector([selectCurrentFarm], farm => Boolean(farm.readOnly));

export const selectDrawControl = (store: AppStore) => store.map.drawControl;

export const selectIsEditingMode = (store: AppStore) => store.map.drawControl.isEditingMode;

export const selectIsDrawingMode = (store: AppStore) => store.map.drawControl.isDrawingMode;

export const selectEditGeometry = (store: AppStore) => store.map.editGeometry;

export const selectMapOpenPopUpId = (s: AppStore) => s.map.openPopupId;
export const selectPreparedTemperatureData = (s: AppStore) => s.map.preparedTemperatureData;
export const selectTemperatureData = (s: AppStore) => s.map.temperatureData;
export const selectHistogram = (s: AppStore) => s.map.histogram;

export const selectAppProcessingStatus = (s: AppStore) => s.map.appProcessingStatus;

// Sampling points selectors

export const selectCurrentPointsGroups = (state: AppStore) => state.map.pointsGroups;

export const selectCurrentPointId = (state: AppStore) => state.map.currentPointId;

export const selectPointsCurrentGroupDate = (state: AppStore) => state.map.pointsCurrentGroupDate;

export const selectCurrentPointsGroup = createSelector(
  [selectCurrentPointsGroups, selectPointsCurrentGroupDate],
  (groups, date) => groups?.[date] || []
);

export const selectFieldGeometry = createSelector(
  [selectFieldGeometries, selectMapFields, (s: AppStore, fieldId: number) => fieldId],
  (fieldGeometries, fields, fieldId) => {
    const field = fields.find(f => f.ID === fieldId);
    const geometry = fieldGeometries[String(field?.MD5)];
    return geometry;
  }
);

export const selectCurrentFieldGeometry = createSelector(
  [selectFieldGeometries, selectCurrentField2],
  (fieldGeometries, field) => {
    const geometry = fieldGeometries[String(field?.MD5)];
    return geometry;
  }
);

export const selectGeometriesOnMap = (s: AppStore) => s.map.geometriesOnMap;

export const selectMapGeometry = (s: AppStore) => s.map.geometry;

export const selectImageLayerOpacity = (s: AppStore) => s.map.imageLayerOpacity;

export const selectFieldsInAustralia = (s: AppStore, fieldIds: number[]) => {
  const {fields} = s.map;
  return fieldIds.every(id => fields.find(f => f.ID === id && isAustralianField(f)));
};

/**
 * Dates related selectors
 */

export const selectCurrentSensor = (s: AppStore) => s.map.currentSensor;

export const selectCurrentCompareSensor = (s: AppStore) => s.map.currentSensorCompare;

export const selectCurrentCompareSensors = (s: AppStore) => s.map.currentSensorsCompare;

export const selectColorSchema = (s: AppStore) => s.map.colorSchema;

export const selectCurrentSensors = (s: AppStore) => s.map.currentSensors;

export const selectCurrentDate = (s: AppStore) => s.map.currentDate;

export const selectCurrentCompareDate = (s: AppStore) => s.map.currentCompareDate;

export const selectIsCompareOn = (s: AppStore) => s.map.isCompareOn;

export const selectCurrentDates = (s: AppStore) => s.map.currentDates;

export const selectSourcesMeta = (s: AppStore) => s.map.sourcesMeta;

export const selectNitrogenOverlayURL = (s: AppStore) => s.map.nitrogenOverlayURL;

export const selectCurrentDateObj = createSelector(
  [selectCurrentDates, selectCurrentDate],
  (dates, date) => dates?.[date]
);

export const selectDateObj = createSelector(
  [selectCurrentDates, (s: AppStore, date: string) => date],
  (dates, date) => dates?.[date]
);

export const selectDateTimeByDate = createSelector([selectDateObj], date => date?.Date);

export const selectCurrentImageObj = createSelector(
  [selectCurrentDateObj, selectCurrentSensor, selectCurrentField],
  (date, sensor, field) => {
    if (date?.[sensor] && date[sensor]?.url && isLegacyImageryURL(String(date?.[sensor]?.url))) {
      // @ts-expect-error strange issue "date[sensor] is possible undefined", it can't be undefined because of the if statement before
      date[sensor].url = `/services/data-service/indices/${sensor.toLowerCase()}/${field.MD5}/${
        date.Date
      }`;
    }

    return date?.[sensor] as TDateLayer;
  }
);

export const selectCurrentImagePngPath = createSelector([selectCurrentImageObj], date => {
  const url = date?.url;
  if (!url) return '';

  const png = url.replace('.png', '');
  return getImagePath(png);
});

export const selectCurrentImageName = createSelector(
  [selectCurrentImageObj],
  imageObj => imageObj?.name
);

export const selectImagesStatus = createSelector(
  [
    selectWholeFarmData,
    selectInfoExt,
    selectCurrentSeason,
    selectMapFields,
    selectCurrentField,
    (s: AppStore) => selectAsyncRequestStatus(s, AsyncStatusType.wholeFarmData) === Status.Pending,
  ],
  (wholeFarm, infoExt, currentSeason, fields, field, isWholeFarmLoading) => {
    const {isWholeFarmView, wholeFarmDates} = wholeFarm;

    const isWholeFarmLayersDataMissing =
      isWholeFarmView && !isWholeFarmLoading && !Object.keys(wholeFarmDates).length;

    let isImagesProcessing;
    let isFuture = false;

    if (isWholeFarmView) {
      const allFieldsWithNoLayersData =
        !isWholeFarmLoading &&
        fields.length &&
        fields.every((f: Field) => f.Seasons?.length && f.Seasons.every((s: Season) => !s.infoExt));
      isImagesProcessing = allFieldsWithNoLayersData || isWholeFarmLayersDataMissing;
    } else if (field.Seasons?.length) {
      isImagesProcessing = currentSeason?.startDate ? !currentSeason?.infoExt?.length : false;
      isFuture = !moment(currentSeason?.startDate).isBefore(moment());
    }

    return {
      isImagesProcessing,
      isFuture,
      isNoImagery: isWholeFarmView ? isWholeFarmLayersDataMissing : !infoExt.length,
    };
  }
);

// Remote sensing

export const selectRemoteSensingFilterProps = (s: AppStore) => s.map.remoteSensingFilterProps;
export const selectRemoteSensingCloudCover = (s: AppStore) => s.map.remoteSensingCloudCover;

export const selectIsCloudy = createSelector(
  [selectInfoExt, selectCurrentDates],
  (infoExt, currentDates) => {
    return infoExt.length && !Object.keys(currentDates).length;
  }
);

// Tree detection

export const selectTreeDetection = (s: AppStore) => s.map.treeDetection;

export const selectTreeDetectionLayerType = createSelector(
  [selectTreeDetection],
  treeDetection => treeDetection.layerType
);

export const selectTreeDetectionScale = createSelector(
  [selectCurrentSensor, selectTreeDetectionLayerType, selectCurrentDate],
  (currentSensor, treeDetectionLayer, currentDate) => {
    const treeData = treeDetectionLayer !== 'default' && getTreeDataByDate(currentDate);

    if (!treeData) {
      return undefined;
    }
    const firstFieldData = treeData?.[Object.keys(treeData)?.[0]]; // get data from the first field until Victor provides a new logic
    const scaleObject =
      typeof firstFieldData?.scale === 'string' // back-end may send a JSON strings instead of an Object
        ? JSON.parse(firstFieldData?.scale)
        : firstFieldData?.scale;
    return scaleObject?.[`tree_${treeDetectionLayer}_${currentSensor.toLowerCase()}`];
  }
);

export const selectTreeDetectionZoningScale = createSelector([selectTreeDetectionScale], scale => {
  const [min = 0, max = 1] = scale || [];
  return {min: toFixedFloatUnsafe(min, 2), max: toFixedFloatUnsafe(max, 2)};
});
