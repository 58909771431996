import React, {useEffect, useState} from 'react';
import {FluroDialog, Text} from 'components';
import {useAppSelector} from '_hooks';
import {useMonitoringUrlParams} from '../monitoring/hooks';
import {selectMonitoringReadOnly} from '../monitoring/module/selectors';
import {selectEnrollmentReadOnly} from '../enrollment/carbon-store/selectors';
import {PhaseReadOnlyReason} from '../types';
import {t} from 'i18n-utils';

export const MRVEnrollmentContactNotSignedDialog = () => {
  const {isMRVHomePage} = useMonitoringUrlParams();

  const {isReadOnly: enrollmentReadOnly, reason: enrollmentReason} =
    useAppSelector(selectEnrollmentReadOnly);
  const {isReadOnly: monitoringReadOnly, reason: monitoringReason} =
    useAppSelector(selectMonitoringReadOnly);

  const [showPopup, setShowPopup] = useState(false);

  useEffect(() => {
    if (
      enrollmentReadOnly &&
      enrollmentReason === PhaseReadOnlyReason.TooLate &&
      monitoringReadOnly &&
      monitoringReason === PhaseReadOnlyReason.TooEarly
    ) {
      setShowPopup(true);
    }
  }, [enrollmentReason, monitoringReason]);

  const onHide = () => setShowPopup(false);

  return (
    <FluroDialog
      id={'mrv-enrollment-contract-is-not-singed'}
      title={t({id: 'Enrollment contract is not signed'})}
      onHide={onHide}
      visible={showPopup && isMRVHomePage}
      width={400}
      portal={true}
    >
      <Text>
        {t({
          id: 'YouDidNotSignContractMessage',
          defaultMessage:
            'You did not sign a contract for this program before the end of the enrollment period, please contact your program administrator for support.',
        })}
      </Text>
    </FluroDialog>
  );
};
