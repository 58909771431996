import React from 'react';
import styled from 'styled-components';
import {Flex} from 'components/flex';
import {t} from 'i18n-utils';

enum Colors {
  ToShort = '#f44336',
  Weak = '#f44336',
  Okay = 'orange',
  Good = '#43a047',
  Strong = 'green',
}

const Wrapper = styled.div`
  margin-top: 8px;
  margin-bottom: 8px;
  font-size: 12px;

  .scale {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: no-wrap;
    gap: 4px;

    > span {
      display: block;
      height: 4px;
      width: 32px;
      background: rgba(0, 0, 0, 0.2);
    }
  }

  // too short
  &.password-strong-score-0 {
    color: ${Colors.ToShort};
    .scale {
      > span:nth-child(1) {
        background-color: ${Colors.ToShort};
      }
    }
  }

  &.password-strong-score-1 {
    color: ${Colors.Weak};
    .scale {
      > span:nth-child(1),
      > span:nth-child(2) {
        background-color: ${Colors.Weak};
      }
    }
  }

  &.password-strong-score-2 {
    color: ${Colors.Okay};
    .scale {
      > span:nth-child(1),
      > span:nth-child(2),
      > span:nth-child(3) {
        background-color: ${Colors.Okay};
      }
    }
  }

  &.password-strong-score-3 {
    color: ${Colors.Good};
    .scale {
      > span:nth-child(1),
      > span:nth-child(2),
      > span:nth-child(3),
      > span:nth-child(4) {
        background-color: ${Colors.Good};
      }
    }
  }

  &.password-strong-score-4 {
    color: ${Colors.Strong};
    .scale {
      > span {
        background-color: ${Colors.Strong};
      }
    }
  }
`;

const getLabel = (score = 0) => {
  switch (score) {
    case 0:
      return t({id: 'PassValidation.Too short', defaultMessage: 'Too short'});
    case 1:
      return t({id: 'PassValidation.Weak', defaultMessage: 'Weak'});
    case 2:
      return t({id: 'PassValidation.Okay', defaultMessage: 'Okay'});
    case 3:
      return t({id: 'PassValidation.Good', defaultMessage: 'Good'});
    case 4:
      return t({id: 'PassValidation.Strong', defaultMessage: 'Strong'});
  }
};

export const StrengthBar = ({value}: {value: number}) => {
  return (
    <Wrapper className={`password-strong-score-${value}`}>
      <Flex alignItems="center" justifyContent="space-between" gap="15px">
        <div className="scale">
          <span />
          <span />
          <span />
          <span />
          <span />
        </div>

        <span>{getLabel(value)}</span>
      </Flex>
    </Wrapper>
  );
};
