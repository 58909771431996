import React, {useMemo, useState} from 'react';
import {useAppDispatch, useAppSelector} from '_hooks';
import {classifyAreaWithUnits} from '_utils';
import {FontIcon} from 'react-md';
import {Flex, FluroSelect, FluroButton, Text} from 'components';
import {selectMeasurement} from 'containers/login/login-selectors';
import {selectDataExportAgreement} from 'containers/si/module/selectors';
import {setDataExportAgreement} from 'containers/si/module/reducer';
import type {SISupplyShed} from 'containers/si/types';
import {CropDropdown} from './crop-dropdown';
import {SIDialogPopup} from 'containers/si/components/si-dialog-popup';
import {SIHeaderColor} from 'containers/si/si-styled-components';
import styled from 'styled-components';

export const StyledExportContainer = styled.div`
  border-radius: 4px;
  border: 1px solid ${({theme}) => theme.color.stroke.strong};
  margin-bottom: 16px;
`;

export const StyledExportHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  position: relative;
  padding: 10px 8px 10px 16px;
  background-color: white;
  border-radius: 4px;

  .data-export-card-name {
    font-size: 16px;
    margin-bottom: 0;
  }

  .data-export-card-area {
    font-size: 16px;
    font-weight: 600;
  }
`;

export const StyledExportRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 12px;
  flex-wrap: nowrap;
  background-color: white;
  border-radius: 4px;

  .export-selection-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: inherit;
    gap: 24px;
  }

  .export-drop-down {
    margin: 0;
    width: inherit;
    flex: 0 1 300px;

    .fluro-select-label {
      color: #7a7a7a;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      display: block;
      font-size: 0.8125em;
      line-height: 1;
      overflow: hidden;
      padding: 0.5em 0;
      pointer-events: none;
      text-overflow: ellipsis;
      text-align: left;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      white-space: nowrap;
      width: 100%;
    }
  }

  .export-button {
    margin-top: 22px;
  }
`;

type DataExportCardProps = {
  supplyShed: SISupplyShed;
  downloadCSV: (
    reportName: string,
    supplyShedId: number,
    aggregationUnit: string,
    cropIds: number[],
    selectedYear: string
  ) => void;
  downloadingCSV: boolean;
};

export const DataExportCard = ({supplyShed, downloadCSV, downloadingCSV}: DataExportCardProps) => {
  const dispatch = useAppDispatch();
  const measurement = useAppSelector(selectMeasurement);
  const dataExportAgreement = useAppSelector(selectDataExportAgreement);

  const [cardVisible, setCardVisible] = useState(false);
  const [selectedAggregationLevel, setSelectedAggregationLevel] = useState('admin_1');
  const [selectedYear, setSelectedYear] = useState('all');
  const [selectedCrops, setSelectedCrops] = useState<number[]>([]);
  const [showDataAgreementPopup, setShowDataAgreementPopup] = useState(false);

  const dataExportAggregationOptions = [
    {
      label: 'States and Provinces',
      value: 'admin_1',
    },
    {
      label: 'Counties and Districts',
      value: 'admin_2',
    },
    {
      label: 'Supply Shed Subregions',
      value: 'subsection',
    },
  ];

  const yearOptions = useMemo(() => {
    const currentYear = new Date().getFullYear();
    const years = [...Array(currentYear - 2015 + 1).keys()].map(i => currentYear - i);

    return [
      {
        label: 'All years',
        value: 'all',
      },
      ...years.map(year => ({label: year.toString(), value: year.toString()})),
    ];
  }, []);

  const availableCommodities = supplyShed?.subsections.reduce(
    (acc, subsection) => [...acc, ...subsection?.commodities?.map(c => c.id)],
    [] as number[]
  );

  const onClickDownload = () => {
    // First check to see if the popup agreement has been accepted
    if (dataExportAgreement) {
      onDownloadCSV();
    } else {
      setShowDataAgreementPopup(true);
    }
  };

  const onDownloadCSV = () => {
    dispatch(setDataExportAgreement(true));
    downloadCSV(
      'Data Export',
      supplyShed.id,
      selectedAggregationLevel,
      selectedCrops,
      selectedYear
    );
    setShowDataAgreementPopup(false);
  };

  return (
    <StyledExportContainer key={supplyShed.id}>
      <StyledExportHeader
        role={'button'}
        onClick={() => {
          setCardVisible(!cardVisible);
        }}
        onKeyDown={() => {
          setCardVisible(!cardVisible);
        }}
        tabIndex={0}
      >
        <SIHeaderColor headerColor={supplyShed?.color} />
        <Flex direction="row" alignItems="center" nowrap basis="500px">
          <FontIcon forceSize={35} forceFontSize>
            {cardVisible ? 'expand_more' : 'chevron_right'}
          </FontIcon>
          <div className="data-export-card-name">{supplyShed.name}</div>
        </Flex>
        <Flex direction="row" alignItems="center">
          <div className="data-export-card-area">
            {classifyAreaWithUnits(
              Math.round(Number(supplyShed?.total_field_area_ha)),
              measurement
            )}
          </div>
        </Flex>
      </StyledExportHeader>
      {cardVisible && (
        <StyledExportRow>
          <div className={'export-selection-container'}>
            <FluroSelect
              placeholder="Select export aggregation level"
              value={selectedAggregationLevel}
              onChange={level => setSelectedAggregationLevel(level)}
              options={dataExportAggregationOptions}
              className="export-drop-down"
            />
            <CropDropdown
              onChange={crops => {
                setSelectedCrops(
                  crops.reduce((acc, crop) => [...acc, Number(crop.value)], [] as number[])
                );
              }}
              availableCommodities={availableCommodities}
            />
            <FluroSelect
              placeholder="Select years to export"
              value={selectedYear}
              onChange={year => setSelectedYear(year)}
              options={yearOptions}
              className="export-drop-down"
            />
          </div>
          <div className={'export-button'}>
            <FluroButton
              primary
              raised
              iconEl={<FontIcon>download_for_offline</FontIcon>}
              className="btn-with-icon"
              onClick={() => onClickDownload()}
              disabled={selectedCrops.length === 0}
              loading={downloadingCSV}
            >
              Download CSV
            </FluroButton>
          </div>
        </StyledExportRow>
      )}
      {showDataAgreementPopup && (
        <SIDialogPopup
          title="Data Agreement"
          text={
            <Text variant="h3">
              Customer shall not, directly, indirectly or through its Authorized Users, employees
              and/or the services of independent contractors: (a) attempt to sell, transfer, assign,
              rent, lend, lease, sublicense or otherwise provide third parties rights to the data;
              (b) "frame," "mirror," copy or otherwise enable third parties to use the data (or any
              component thereof); (c) allow access to the data by multiple individuals impersonating
              a single end user; (d) use the data in a manner that interferes with, degrades, or
              disrupts the integrity or performance of any Regrow technologies, services, data,
              systems or other offerings, including data transmission, storage and backup; (e) use
              the data for the purpose of developing a product or service that competes with the
              Regrow online products and services; (f) circumvent or disable any security features
              or functionality associated with the data; or (g) use the data in any manner
              prohibited by law. All rights not expressly granted to Customer are reserved by
              Regrow, its suppliers and licensor.
            </Text>
          }
          onHide={() => setShowDataAgreementPopup(false)}
          onConfirm={() => onDownloadCSV()}
          saveText="I accept these conditions"
          width={600}
        />
      )}
    </StyledExportContainer>
  );
};
