import {FluroChipDropdownControllable as FluroChipDropdown} from 'components/fluro-dropdown/fluro-chip-dropdown';
import {GroupedSelectionControl} from 'components/grouped-selection-control';
import type {Props as GroupedSelectionControlProps} from 'components/grouped-selection-control/grouped-selection-control';
import type {ComponentType} from 'react';
import React, {useMemo, useState} from 'react';
import type {Option} from 'types';

type Props = {
  onSelect: GroupedSelectionControlProps['onChange'];
  options: Option[];
  // List of selected values
  selected: string[];
  // Label to show in the placeholder
  selectedLabel?: string;
  allLabel?: string;
  allowSearch?: boolean;
  allowSelectAll?: boolean;
  selectedMap?: Record<number | string, boolean>;
  allSelected?: boolean;
  disabled?: boolean;
  className?: string;
  onClear?: () => void;
  style?: React.CSSProperties;
  active?: boolean;
};

export const AreaSelectMenu: ComponentType<Props> = ({
  allLabel,
  allSelected = false,
  allowSearch,
  allowSelectAll,
  className,
  onSelect,
  options,
  selected,
  selectedLabel,
  disabled = false,
  selectedMap: selectedMapProp,
  onClear,
  style,
  active,
}) => {
  const [expand, setExpand] = useState(false);

  const selectedMap = useMemo(() => {
    return (
      selectedMapProp ||
      selected.reduce<Record<number | string, boolean>>(
        (acc, value) => ({
          ...acc,
          [value]: true,
        }),
        {}
      )
    );
  }, [selected, selectedMapProp]);

  const handleSelect: Props['onSelect'] = (selectedMenu, event) => {
    // FIXME: trying to postpone select calculation to put the dropdown animation through.
    // this should be replaced with transition feature from React 18 once we have it
    requestAnimationFrame(() => {
      onSelect(selectedMenu, event);
    });
  };

  const label = `${selected.length} ${selectedLabel || ''} selected`;

  return (
    <FluroChipDropdown
      className={className}
      expand={expand && !disabled}
      setExpand={setExpand}
      label={label}
      disabled={disabled}
      active={active}
      dropdownStyle={{minWidth: '250px', ...style}}
      onClear={onClear}
    >
      {expand && !disabled && (
        <GroupedSelectionControl
          allLabel={allLabel}
          allowSearch={allowSearch}
          allowSelectAll={allowSelectAll}
          allSelected={allSelected}
          id="area-counties-select"
          name="counties"
          onChange={handleSelect}
          options={options}
          selected={selected}
          selectedMap={selectedMap}
        />
      )}
    </FluroChipDropdown>
  );
};
