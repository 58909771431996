import React from 'react';
import {t} from 'i18n-utils';
import './edit-field-control-buttons.scss';
import {FontIcon} from 'react-md';

type Props = {
  onSave: () => void;
  onCancel: () => void;
};

export const EditFieldControlButtons = ({onSave, onCancel}: Props) => {
  return (
    <div className="edit-field-control-container">
      <div className="edit-field-wrapper">
        <div className="edit-field-control-buttons">
          <button onClick={onCancel}>
            {t({id: 'Cancel'})}
            <FontIcon>close</FontIcon>
          </button>
          <button onClick={onSave}>{t({id: 'Save'})}</button>
        </div>
      </div>

      <div className="edit-field-control-msg">
        {t({id: 'Drag the points to edit the boundary. Click right to delete a point.'})}
      </div>
    </div>
  );
};
