// @ts-nocheck
import type {ActionPayload, User} from './types';
import {ActionType} from './types';
import type {Dispatch} from 'redux';
import {UsersApi, SubscriptionsApi} from '_api';
import type {RouterAction} from 'connected-react-router';
import {push} from 'connected-react-router';
import {reportError} from '../../error-boundary';
import {InitialUser} from './base';
import {showNotification} from 'components/notification/notification';
import {LoginActionTypes} from 'containers/login/types';
import {createAsyncThunk} from '@reduxjs/toolkit';

export const getUsersList = createAsyncThunk('getUsersList', (_, thunkAPI) => {
  return UsersApi.getAll()
    .then(({data}) => {
      thunkAPI.dispatch({
        type: ActionType.LOAD_USERS_LIST,
        list: [...data.result],
      });

      return data.result;
    })
    .catch(err => reportError(`getUsersList() err = ${err}`));
});

export const getNewUserModel = (): User => ({
  ...InitialUser,
  id: 'new',
  settings: {...InitialUser.settings},
});

export const getOne = (userId: number | string) => (): Promise<User> => {
  if (userId === 'new') {
    return Promise.resolve(getNewUserModel());
  }

  userId = typeof userId === 'number' ? userId : parseInt(userId);

  return new Promise<User>(res => {
    UsersApi.getOne(userId as number)
      .then(({data}: any) => {
        res(data.result as User);
      })
      .catch(err => {
        reportError(`Users, getOne() err = ${err}`);
        res(getNewUserModel());
      });
  });
};

export const saveUser = (user: User, usersUrl: string) => (dispatch: Dispatch<ActionPayload>) => {
  return UsersApi[user.id === 'new' ? 'newUser' : 'saveUser'](user)
    .then(({data}) => {
      if (data && data.result && user.id === 'new') {
        dispatch(push(`${usersUrl}/${data.result.id}`));
      }
      return data.result;
    })
    .catch();
};

export const updateUserWorkspaces =
  ({userId, workspaces}: {userId: number; workspaces: {[workspace: string]: boolean}}) =>
  (dispatch: Dispatch<ActionPayload>) => {
    return UsersApi.updateWorkspaces(userId, workspaces)
      .then(() => {
        dispatch({type: LoginActionTypes.UPDATE_USER_WORKSPACES, payload: {workspaces}});
      })
      .catch(err => {
        showNotification({
          type: 'error',
          message: err ? String(err) : 'Could not update user workspaces',
        });
      });
  };

export const rmUser = (id: number, usersUrl: string) => (dispatch: Dispatch<RouterAction>) => {
  return UsersApi.rm(id)
    .then(() => {
      dispatch(push(usersUrl));
    })
    .catch();
};

export const loadOrganizations = () => (dispatch: Dispatch<ActionPayload>) => {
  return SubscriptionsApi.loadAllOrganizations()
    .then(({data}) => {
      dispatch({type: ActionType.SET_ORGS, organizations: data.result || []});
      return data.result || [];
    })
    .catch(err => reportError(`loadOrganizations() err = ${err}`));
};

export const loadSubscriptions = () => (dispatch: Dispatch<ActionPayload>) => {
  return SubscriptionsApi.loadSubscriptions()
    .then(({data}) => {
      dispatch({type: ActionType.SET_SUBS, subscriptions: data.result || []});
      return data.result || [];
    })
    .catch(err => reportError(`loadSubscriptions() err = ${err}`));
};
