import {createAsyncThunk} from '@reduxjs/toolkit';
import {FieldSystemProp} from 'containers/map/features/farm/new-fields/types';
import type {AppStore} from 'reducers';
import {PadUsApi} from '_api';
import {AddProtectedAreas} from '.';

export const fetchPadusProtectedAreaIntersections = createAsyncThunk<
  AppStore['addFields']['protectedAreaBoundaries'],
  {fieldBoundaries: AppStore['addFields']['cluFieldBoundaries']},
  {state: AppStore}
>('@add-fields/protected-areas/fetch', async ({fieldBoundaries}, thunkAPI) => {
  const fieldBoundariesArray = Object.keys(fieldBoundaries).map(id => ({
    geom: fieldBoundaries[id],
    geometry_id: fieldBoundaries[id].properties[FieldSystemProp.Id] || id,
  }));

  return new Promise(async resolve => {
    const intersections: AppStore['addFields']['protectedAreaBoundaries'] = {};
    const {data} = await PadUsApi.intersections(fieldBoundariesArray);
    if (!data?.length) {
      resolve(intersections);
      return;
    }

    let hasIntersections = false;
    data.forEach(d => {
      if (!fieldBoundaries[d.geometry_id]) return;
      intersections[d.geometry_id] = d.areas[0];
      hasIntersections = true;
    });

    if (hasIntersections) {
      thunkAPI.dispatch(AddProtectedAreas(intersections));
    }

    resolve(intersections);
  });
});
