// @ts-nocheck
import {t} from 'i18n-utils';
import React, {useMemo, memo, useCallback} from 'react';
import Dialog from 'components/fluro-dialog';
import {dialogToggle, DialogType} from 'modules/helpers';
import {genKey} from '_utils/pure-utils';
import {toggleGlobalDialog} from 'modules/global/actions';
import {setFeature, setCurrentFieldId, toggleTableView} from 'containers/map/actions';
import {FontIcon, Button} from 'react-md';
import InfoBlock from 'components/info-block';
import {useAppDispatch, useAppSelector} from '../../../../../_hooks';
import {selectDialogData} from '../../../../../modules/helpers/selectors';
import {selectCurrentFieldId} from '../../../reducer/selectors';

/*
 *
 * Crop status alert Dialog
 *
 * */
const StatusAlert = memo(() => {
  const dispatch = useAppDispatch();
  const {visible, payload = {}} = useAppSelector(s => selectDialogData(s, DialogType.statusAlert));
  const selectedFieldId = useAppSelector(selectCurrentFieldId);

  const {message, fieldId, seasonId} = payload;

  const id = useMemo(() => genKey(), []);

  const onClose = useCallback(() => dispatch(dialogToggle(DialogType.statusAlert, false)), []);

  const onEdit = useCallback(() => {
    // close current status alert dialog
    onClose();

    // switch to farm tab cuz edit season dialog can be open only from the farm tab
    dispatch(setFeature('farm'));

    // close table view cuz edit season dialog working only with closed table view
    dispatch(toggleTableView());

    // if we the season not in the current field then switch to season's field
    if (selectedFieldId !== fieldId) {
      dispatch(setCurrentFieldId(fieldId));
    }

    // open edit season dialog
    dispatch(toggleGlobalDialog('editSeason', {visible: true, fieldId, seasonId}, true));
  }, [fieldId, seasonId, selectedFieldId]);

  return (
    <Dialog
      id={`status-alert-dialog-${id}`}
      visible={visible}
      title="Crop dates seem incorrect"
      focusOnMount={false}
      isDraggable
      onHide={onClose}
      autosizeContent={false}
      width={380}
      portal
    >
      <div className="status-alert-dialog">
        <div className="status-alert-dialog__main">
          <div>{message}</div>
        </div>

        <InfoBlock>
          {t({id: 'This has been identified by unexpected changes in the NDVI trend of the crop.'})}
        </InfoBlock>

        <div className="status-alert-dialog__btns">
          <Button raised onClick={onClose}>
            {t({id: 'Ignore'})}
          </Button>
          <Button onClick={onEdit} primary raised>
            {t({id: 'Edit crop'})}
          </Button>
        </div>
      </div>
    </Dialog>
  );
});

export default StatusAlert;

/*
 *
 * Crop status alert open button
 *
 * */
type AlertIconProps = {
  message: string;
  fieldId: number;
  seasonId: number;
};

export const AlertIcon = memo(({fieldId, seasonId, message = ''}: AlertIconProps) => {
  const dispatch = useAppDispatch();

  return (
    <FontIcon
      className="status-alert-dialog__w-icon-btn"
      onClick={() =>
        dispatch(dialogToggle(DialogType.statusAlert, true, {message, fieldId, seasonId}))
      }
    >
      error_outline
    </FontIcon>
  );
});
