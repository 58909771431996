import type {PayloadAction} from '@reduxjs/toolkit';
import {createSlice} from '@reduxjs/toolkit';
import {mergeEntities} from 'modules/redux-utils/merge-entities';
import {LoginActionTypes} from '../../../login/types';
import type {SI2State, AccessType} from '../types';
import {fetchSIProgramAdmins, fetchSIProgramUsers, removeSIProgramUser} from '../thunks';

export const accessSlice = createSlice({
  name: 'si/access',
  initialState: {} as SI2State['access'], // TODO: unsafe initial state; doesn't match type
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(LoginActionTypes.LOGOUT, _state => {
        return {};
      })
      .addCase<string, PayloadAction<Record<string, AccessType>>>(
        fetchSIProgramUsers.fulfilled.type,
        (state, action) => {
          return mergeEntities(state, action.payload);
        }
      )
      .addCase<string, PayloadAction<Record<string, AccessType>>>(
        fetchSIProgramAdmins.fulfilled.type,
        (state, action) => {
          return mergeEntities(state, action.payload);
        }
      )
      .addCase<string, PayloadAction<{programId: number; user_id: number; role: 'admin' | 'user'}>>(
        removeSIProgramUser.fulfilled.type,
        (state, action) => {
          const {programId, user_id, role} = action.payload;
          const roles = role === 'user' ? 'users' : 'admins';

          state[programId][roles] = (state[programId][roles] ?? []).filter(
            user => user.user_id !== user_id
          );
        }
      );
  },
});
