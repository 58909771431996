/**
 * In incognito mode browsers won't allow localStorage access.
 * We don't ensure that app is gonna work as expected so far, but at least it won't crash.
 */
class SafeLocalStorage {
  storage: Storage | undefined;

  constructor() {
    try {
      this.storage = localStorage;
    } catch (e) {
      // do nothing
    }
  }

  getItem(key: string) {
    try {
      return this.storage?.getItem(key);
    } catch (e) {
      return null;
    }
  }

  setItem(key: string, value: string) {
    try {
      this.storage?.setItem(key, value);
    } catch (e) {
      // do nothing
    }
  }

  removeItem(key: string) {
    try {
      this.storage?.removeItem(key);
    } catch (e) {
      // do nothing
    }
  }
}

export const safeLocalStorage = new SafeLocalStorage();

export const getUserToken = () => {
  return safeLocalStorage.getItem('token');
};
