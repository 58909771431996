import React from 'react';
import {Link} from 'react-router-dom';
import {Card, CardTitle, CardText} from 'react-md';

const style = {maxWidth: 650, marginTop: '100px'};

const NotFound = () => (
  <div>
    <Card style={style} className="md-block-centered" tableCard>
      <CardTitle title={`404 Not Found`} />
      <CardText>
        <Link to={`/`}>Home</Link>
      </CardText>
    </Card>
  </div>
);

export default NotFound;
