// @ts-nocheck
import {t} from 'i18n-utils';
import React, {useEffect, useMemo, useState} from 'react';
import {GLOBAL_FORMAT_DATE} from '_constants';

import {
  fApplicationAdd,
  fApplicationUpdate,
  fApplicationRemove,
} from 'containers/map/actions/nitrogen-recommendation-actions';

import ApplicationItem from './fertilizer-app-item';
import {Button} from 'react-md';
import FluroDialog from 'components/fluro-dialog';
import moment from 'moment';
import type {FertilizerApplication, NRxSeason} from 'containers/map/features/nrx';
import {useAppDispatch, useAppSelector} from '_hooks';
import {useForm} from 'react-hook-form';
import {
  selectNRxFertilizersRecordsByTypeId,
  selectNRxListsData,
} from 'containers/map/features/nrx/nrx-selectors';

type Props = {
  particularSeason: NRxSeason;
  hidePopUp(): void;
};

type FormData = {
  quantity: number;
  typeID: number;
  nitrogenPercentage: number;
  date: string;
  type: string;
};

const FertilizerPopUp = ({particularSeason, hidePopUp}: Props) => {
  const dispatch = useAppDispatch();
  const listsData = useAppSelector(selectNRxListsData);
  const fertilizersByTypeId = useAppSelector(selectNRxFertilizersRecordsByTypeId);

  const [preparedFertilizers, setPreparedFertilizers] = useState<
    Array<FertilizerApplication & {edited?: boolean; edit?: boolean}>
  >(particularSeason.fertilizerApplications);

  const preparedFertilizerTypes = useMemo(() => {
    return listsData.fertilizerTypes.map(item => ({value: item.typeID, label: item.type}));
  }, [listsData.fertilizerTypes]);

  /**
   * Start form setup
   */

  const {register, setValue, handleSubmit, watch, reset} = useForm<FormData>({
    defaultValues: {
      typeID: -1,
      quantity: 1,
      nitrogenPercentage: 0,
      date: moment(particularSeason.prevHarvestDate, GLOBAL_FORMAT_DATE).format(GLOBAL_FORMAT_DATE),
    },
  });

  register('typeID');
  register('quantity');
  register('nitrogenPercentage');
  register('date');
  register('type');

  const values = watch();

  /**
   * End form setup
   */

  useEffect(() => {
    setPreparedFertilizers(
      particularSeason.fertilizerApplications.map(app => {
        const oldFertilizerApp = preparedFertilizers.find(oldApp => oldApp.id === app.id);
        if (oldFertilizerApp) {
          return {...app, ...oldFertilizerApp};
        }
        return app;
      })
    );
  }, [particularSeason.fertilizerApplications]);

  const onChange = (prop: keyof FormData, value: any) => {
    setValue(prop, value);
    if (prop === 'typeID' && values.typeID !== value) {
      const fertilizer = fertilizersByTypeId[value];

      if (fertilizer) {
        setValue(prop, value);
        setValue('type', fertilizer.type);
        setValue('nitrogenPercentage', fertilizer.nitrogenPercentage);
      }
    }
  };

  const onChangePreviousApplication = (
    item: FertilizerApplication,
    prop: keyof FertilizerApplication,
    value: any
  ) => {
    let data = {
      ...item,
      edited: true,
      [prop]: value,
    };

    const result = preparedFertilizers.map(a => (a.id === item.id ? {...a, ...data} : a));
    setPreparedFertilizers(result);
  };

  const onDeleteApplication = (appId: number) => {
    dispatch(fApplicationRemove(particularSeason.kmlID, appId, particularSeason.nrxSeasonID));
  };

  const onHandleSubmit = (addNewApp = false) => {
    if (addNewApp) {
      const newItem = {
        seasonID: particularSeason.seasonID,
        nrxSeasonID: particularSeason.nrxSeasonID,
        fieldID: particularSeason.kmlID,
        quantity: values.quantity,
        option: 25,
        type: values.type,
        typeID: values.typeID,
        nitrogenPercentage: values.nitrogenPercentage,
        date: values.date,
      };
      dispatch(fApplicationAdd(newItem));
      reset();
    } else {
      preparedFertilizers.forEach(item => {
        if (item.edited) {
          dispatch(
            fApplicationUpdate({
              ...item,
              nrxSeasonID: particularSeason.nrxSeasonID,
            })
          );
        }
      });
      hidePopUp();
    }
  };

  const onHide = () => {
    const confirm =
      particularSeason.fertilizerApplications.length ||
      window.confirm(
        t({
          id: 'You need to set at least one fertilizer application to start using the model, are you sure you want to cancel?',
        })
      );

    if (confirm) {
      hidePopUp();
    }
  };

  const isCurrentSeasonApplication = (date: string) => {
    return moment(date, GLOBAL_FORMAT_DATE).isSameOrBefore(
      moment(particularSeason.prevHarvestDate, GLOBAL_FORMAT_DATE)
    );
  };

  const maxDate = useMemo(() => {
    const {endDate} = particularSeason;
    return moment(endDate, GLOBAL_FORMAT_DATE).isBefore(moment())
      ? moment(endDate, GLOBAL_FORMAT_DATE)
      : moment();
  }, [particularSeason]);

  const disableAddNew =
    (!values.typeID && values.typeID !== 0) || !values.quantity || !values.nitrogenPercentage;

  return (
    <FluroDialog
      id={`fertilizer-pop-up`}
      dialogClassName={'fertilizer-pop-up'}
      visible={true}
      title={t({id: 'Previous fertilizer applications'})}
      isDraggable
      onHide={onHide}
      focusOnMount={false}
    >
      <div className={'fertilizer-pop-up-content'}>
        <div className={'add-new-container'}>
          <div className={'title'}>{t({id: 'Add'})}</div>

          <ApplicationItem
            item={{
              id: 0,
              typeID: values.typeID,
              nitrogenPercentage: values.nitrogenPercentage,
              quantity: values.quantity,
              date: values.date,
              fieldID: particularSeason.kmlID,
              option: 25,
            }}
            prevSeason={isCurrentSeasonApplication(values.date)}
            fertilizerTypes={preparedFertilizerTypes}
            onChange={(prop: keyof FormData, value: any) => onChange(prop, value)}
            onDelete={null}
            maxDate={maxDate}
          />

          <Button
            disabled={disableAddNew}
            raised
            primary
            onClick={handleSubmit(() => onHandleSubmit(true))}
          >
            {t({id: 'Add'})}
          </Button>
        </div>

        {preparedFertilizers.length !== 0 && (
          <div className={'season-application-container'}>
            <div className={'title'}>{t({id: 'Application records'})}</div>

            {preparedFertilizers.map(item => (
              <ApplicationItem
                key={item.id}
                item={item}
                prevSeason={isCurrentSeasonApplication(item.date)}
                fertilizerTypes={preparedFertilizerTypes}
                onDelete={() => onDeleteApplication(item.id)}
                onChange={(prop: keyof FertilizerApplication, value: any) =>
                  onChangePreviousApplication(item, prop, value)
                }
                maxDate={maxDate}
              />
            ))}
          </div>
        )}
      </div>

      <div className={`button-container end`}>
        {particularSeason.fertilizerApplications.length !== 0 && (
          <Button
            disabled={preparedFertilizers.some(f => f.quantity < 1 || f.quantity > 2000)}
            raised
            primary
            onClick={handleSubmit(() => onHandleSubmit())}
          >
            {t({id: 'Save'})}
          </Button>
        )}
      </div>
    </FluroDialog>
  );
};

export default FertilizerPopUp;
