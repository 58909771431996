import React from 'react';
import {Redirect, Route, Switch} from 'react-router-dom';
import {useAppDispatch, useAppSelector} from '_hooks';
import {selectIsLoading} from 'modules/helpers/selectors';
import {SI_REPORT_LIST, SI_REPORT_DASHBOARD} from '../routes';
import {fetchPlanningReports} from 'containers/si/module/thunks';
import {ActionType} from 'containers/si/module/types';
import {SIPageContainer} from 'containers/si/si-styled-components';
import {PlanningReportList} from 'containers/si/planning-report/planning-report-entry';
import {ReportDashboard} from 'containers/si/planning-report/dashboard/report-dashboard';
import PrivateRoute from 'containers/private-route';
import {applyPathParams} from '_utils/pure-utils';
import {selectPlanningReports} from 'containers/si/module/selectors';
import {isNil} from '_utils/typeGuards';

export const SIPlan = ({programId}: {programId: number}) => {
  const dispatch = useAppDispatch();

  const reportsLoading = useAppSelector(s =>
    selectIsLoading(s, [ActionType.FETCH_PLANNING_REPORTS])
  );

  const reports = useAppSelector(selectPlanningReports);

  React.useEffect(() => {
    // Reports are fetched one programId at a time, so a successful fetch
    // doesn't necessarily mean the reports were successfully fetched for
    // THIS programId. (this will be improved by using SWR in the future)
    if (!reportsLoading && isNil(reports[programId])) {
      dispatch(fetchPlanningReports(programId));
    }
  }, [dispatch, programId, reportsLoading, reports]);

  return (
    <SIPageContainer>
      <Switch>
        <PrivateRoute path={SI_REPORT_DASHBOARD} component={ReportDashboard} />
        <PrivateRoute path={SI_REPORT_LIST} component={PlanningReportList} />
        <Route component={() => <Redirect to={applyPathParams(SI_REPORT_LIST, {programId})} />} />
      </Switch>
    </SIPageContainer>
  );
};
