import type {
  SISupplyShed,
  SIMapInteractionType,
  SIMapLayerType,
  PlanningReport,
  ReportSelectedSupplyShedCrops,
  Intervention,
  SIScenario,
  SIScenarioKPIs,
  SIPracticeAdoption,
} from 'containers/si/types';
import type {SIAreaUnits} from 'modules/sustainability-insights/types';
import type {FeatureCollection} from 'geojson';
import type {
  Model,
  ProgramResponse,
  ProgramUserResponse,
  ReportType,
  Protocol,
  KPIFilter,
  SupplyShedGeometries,
  CropBookValues,
} from 'containers/si/api/apiTypes';

export enum ActionType {
  ADD_SI_PROGRAM = 'si/add-program',
  FETCH_ALL_SI_PROGRAMS = 'si/fetch-all-programs',
  FETCH_SI_PROGRAM = 'si/fetch-program',
  REMOVE_SI_PROGRAM = 'si/remove-program',
  UPDATE_SI_PROGRAM = 'si/update-program',
  FETCH_SI_PROGRAM_USERS = 'si/fetch-program-users',
  FETCH_SI_PROGRAM_ADMINS = 'si/fetch-program-admins',
  REMOVE_SI_PROGRAM_USER = 'si/remove-program-user',
  FETCH_SI_PROGRAM_GEOMETRIES = 'si/fetch-program-geometries',
  FETCH_SI_SUPPLY_SHEDS = 'si/fetch-supply-sheds',
  FETCH_SI_SUPPLY_SHEDS_BY_ID = 'si/fetch-supply-shed',
  ADD_SI_SUPPLY_SHED = 'si/add-supply-shed',
  UPDATE_SI_SUPPLY_SHED = 'si/update-supply-shed',
  REMOVE_SI_SUPPLY_SHED = 'si/remove-supply-shed',
  ADD_SI_SUBSECTION = 'si/add-subsection',
  UPDATE_SI_SUBSECTION = 'si/update-subsection',
  REMOVE_SI_SUBSECTION = 'si/remove-subsection',
  FETCH_KPI_SUBSECTION_AREA = 'si/fetch-kpi-subsection-area',
  FETCH_KPI_FILTERS = 'si/fetch-kpi-filters',
  SET_KPI_FILTERS = 'si/set-kpi-filters',
  FETCH_KPI = 'si/fetch-kpi',
  FETCH_BOOK_VALUES = 'si/fetch-book-values',
  FETCH_PLANNING_REPORTS = 'si/fetch-planning-reports',
  ADD_PLANNING_REPORT = 'si/add-planning-report',
  UPDATE_PLANNING_REPORT = 'si/update-planning-report',
  UPDATE_PLANNING_REPORT_URL = 'si/update-planning-report-url',
  ACKNOWLEDGE_PLANNING_REPORT = 'si/acknowledge-planning-report',
  REPORT_PLANNING_REPORT = 'si/report-planning-report',
  FETCH_REPORT_SCENARIOS_AP = 'si/fetch-report-scenarios-ap',
  FETCH_REPORT_SCENARIOS_PP = 'si/fetch-report-scenarios-pp',
}

export type AccessType = {admins?: ProgramUserResponse[]; users?: ProgramUserResponse[]};

export type SelectedFeature = {
  [key: string]: any;
};

export type SI2State = {
  access: Record<string, AccessType>;
  programs: {
    list: number[];
    current: number | null;
    processing: boolean;
    dataExportAgreement: boolean;
  };
  entities: {
    programs: Record<string, ProgramResponse>;
    supplySheds: Record<ProgramResponse['id'], SISupplyShed[]>;
    geometries: Record<ProgramResponse['id'], SupplyShedGeometries[]>;
  };
  map: {
    style: string;
    initialView: {
      longitude: number;
      latitude: number;
      zoom: number;
    };
  };
  supplyShedMap: {
    showTools: boolean;
    interactionType: SIMapInteractionType;
    selection: {
      layerID: SIMapLayerType;
    };
    subsectionGeometries: SelectedFeature;
    subsectionSelectedFeatures: SelectedFeature;
    programGeometries: FeatureCollection | null;
    showProgramGeometries: boolean;
    cropTypes: Record<number, {id: number; label: string}>;
    regions: Record<number, {id: number; name: string}>;
    geometries: Record<
      string,
      {id: string; name: string; geometry: GeoJSON.Feature; color: string}
    >;
  };
  kpiFilter: {
    years: number[];
    visibleSubsectionIds: number[];
    selectedCropTypes: number[];
    cropHaBySubsectionIdsByYear: Record<number, {[year: number]: number}>;
    saved: KPIFilter[];
  };
  reports: {
    planningReports: Record<ProgramResponse['id'], PlanningReport[]>;
    new: {
      programId: number | null;
      name: string;
      startYear: number;
      endYear: number;
      reportType: ReportType;
      model: Model;
      protocol: Protocol;
      areas: Record<number, number[]>;
      crops: ReportSelectedSupplyShedCrops;
      interventions: Intervention[];
      cropStepBlocked: boolean;
    };
    data: Record<PlanningReport['id'], SIScenario[]>;
    dashboard: {
      programId: number | null;
      reportId: number | null;
      reportType: ReportType;
      pageIndex: number;
      pageSize: number;
      orderBy: SIScenarioKPIs;
      orderDirection: 'asc' | 'desc';
      onlyTopScenarios: boolean;
      dataUnits: SIAreaUnits;
      cropFilter: number[];
      sourcingRegionFilter: string[]; // concat the type to the id to make it unique, since SupplyShedId and SubsectionId could be the same. e.g. 1+++supply_shed, or 1+++subsection
      interventionFilter: number[];
      showIneligible: boolean;
      carbonPrice: number;
      carbonUnit: string;
      selectedScenario: {
        crop_id: null;
        scenario_id: null;
      };
      adoption: SIPracticeAdoption;
    };
  };
  kpis: {
    emission_factors: {
      ghg: {cropSummary: Record<number, number>};
      net: {cropSummary: Record<number, number>};
      fertilizer: {cropSummary: Record<number, number>};
    };
    bookValues: CropBookValues[];
  };
};
