import {ResponsivePie} from '@nivo/pie';
import {commonPieChartProps} from 'containers/si/constants';
import type {FC} from 'react';
import React from 'react';
import {kiloFormatter} from '_utils/number-formatters';
import {ChartTooltip} from '../styled-components';

interface TillagePieProps {
  data: {
    id: string;
    label: string;
    value: number;
    color: string;
  }[];
}

export const TillageResponsivePie: FC<TillagePieProps> = ({data}) => (
  <ResponsivePie
    {...commonPieChartProps}
    data={data}
    margin={{top: 70, right: 10, bottom: 60, left: 10}}
    arcLinkLabelComponent={props => {
      return (
        <g>
          <path
            fill="none"
            stroke={props.style.linkColor.get()}
            strokeWidth={props.style.thickness}
            d={props.style.path.get()}
          />
          <text
            transform={props.style.textPosition.get()}
            textAnchor={props.style.textAnchor.get()}
            dominantBaseline="central"
            style={{
              fill: props.style.textColor.get(),
            }}
            y={-8}
          >
            {props.datum.label}
          </text>
          <text
            transform={props.style.textPosition.get()}
            textAnchor={props.style.textAnchor.get()}
            dominantBaseline="central"
            style={{
              fill: props.style.textColor.get(),
            }}
            y={8}
            fontWeight={600}
          >
            {`${kiloFormatter(props.datum.value)}%`}
          </text>
        </g>
      );
    }}
    tooltip={e => {
      return <ChartTooltip>{`${e.datum.value}%`}</ChartTooltip>;
    }}
  />
);
