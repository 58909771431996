// @ts-nocheck
import {t} from 'i18n-utils';
import type {ComponentType} from 'react';
import React, {useCallback} from 'react';
import {FluroDialog} from 'components';
import {useAppDispatch, useAppSelector} from '_hooks';
import {selectDialogVisibility} from 'modules/helpers/selectors';
import {dialogToggle, DialogType} from 'modules/helpers';
import './fms-logged-out-dialog.scss';
import {
  selectMonitoringProjectIdRedirectUrlForFMS,
  selectFMSOfEnrolledFieldsFromFMS,
} from '../module/selectors';
import {useMonitoringUrlParams} from '../hooks';
import IntegrationCard from 'containers/profile/integration/helpers/integration-card';
import type {IntegrationPlatform} from 'containers/profile/integration/types';
import {ExternalService} from 'containers/map/types';
import config from '_environment';
import {reportError} from 'containers/error-boundary';
import {setSelectedPlatformToImport} from '../../../profile/integration/actions';

export const FMSSyncLoginDialog: ComponentType = () => {
  const dispatch = useAppDispatch();
  const {projectId} = useMonitoringUrlParams();
  const externalPlatform = useAppSelector(s => selectFMSOfEnrolledFieldsFromFMS(s, projectId));
  const authRedirectUrl = useAppSelector(selectMonitoringProjectIdRedirectUrlForFMS);

  const visible = useAppSelector(s =>
    selectDialogVisibility(s, DialogType.fmsSyncLoginToPlatformDialog)
  );

  const onHide = useCallback(() => {
    dispatch(dialogToggle(DialogType.fmsSyncLoginToPlatformDialog, false));
    // use delay to prevent possible blinking of the login btn https://regrow.atlassian.net/browse/FSB-7397?focusedCommentId=32718
    setTimeout(() => dispatch(setSelectedPlatformToImport(null)), 1000);
  }, []);

  const onAuthorize = useCallback(() => {
    if (externalPlatform === ExternalService.JohnDeere) {
      window.location.assign(`${config.baseUrl}login/johndeere${authRedirectUrl}`);
      return;
    } else if (externalPlatform === ExternalService.Climate) {
      window.location.assign(`${config.baseUrl}login/climate${authRedirectUrl}`);
    } else {
      reportError(`Monitoring sync login unsupported FMS ${externalPlatform}`);
    }
  }, [externalPlatform]);

  return (
    <FluroDialog
      id={'fms-sync-logged-out-dialog'}
      title={t({id: 'Connect to your farm management system'})}
      dialogClassName={'fms-sync-logged-out-dialog'}
      onHide={onHide}
      visible={visible}
      width={440}
      portal
    >
      <IntegrationCard
        authorized={false}
        expanded={false}
        platform={externalPlatform as IntegrationPlatform}
        onAuthorize={onAuthorize}
      />
    </FluroDialog>
  );
};
