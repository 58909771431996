import React from 'react';
import type {ComponentType} from 'react';
import {CircularProgress, FontIcon} from 'react-md';
import {CarbonInput} from 'components/mrv/carbon-input';
import styled from 'styled-components';

export const StyledSearchInput = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  height: 52px; // includes space for loader
  .search-icon {
    position: absolute;
    left: 8px;
    z-index: 1;
  }
  .carbon-input {
    width: 450px;
    .input,
    .label {
      padding-left: 30px; // create space for the .search-icon
    }
  }

  .progress {
    margin-left: 10px;
  }
`;

type Props = {
  value: string;
  onChange: (value: string) => void;
  placeholder: string;
  isLoading?: boolean;
  testId?: string;
};
export const SISearchInput: ComponentType<Props> = ({
  value,
  onChange,
  placeholder,
  isLoading,
  testId,
}) => {
  return (
    <StyledSearchInput data-testid={testId}>
      <FontIcon className={'search-icon'}>search</FontIcon>
      <CarbonInput
        name="search"
        type="text"
        value={value}
        onChange={v => onChange(String(v))}
        placeholder={placeholder}
      />
      {isLoading ? <CircularProgress className={'progress'} id={placeholder} /> : null}
    </StyledSearchInput>
  );
};
