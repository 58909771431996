import ReactTooltip from 'react-tooltip';
import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import type {AppStore} from 'reducers';
import {InfoBlock} from 'components/info-block';
import classNames from 'classnames';
import './index.scss';

const FluroTooltip = () => {
  const {
    id = '',
    content,
    place = 'right',
    tooltipClassName,
    width = '250px',
    disabled = false,
  } = useSelector((s: AppStore) => s.global.tooltip);
  const [uuid, setUuid] = useState(id);

  useEffect(() => {
    setUuid(`${uuid}` + 1);
    ReactTooltip.rebuild(); // is used to draw and hide the tooltip
  }, [id, content]);

  return (
    <ReactTooltip
      uuid={`${uuid}`}
      className={classNames('fluro-tooltip', tooltipClassName)}
      id={`${id}`}
      effect="solid"
      type="light"
      place={place}
      border={false}
      getContent={() => (
        <InfoBlock containerStyle={{width}} className={'no-top'} hideIcon>
          {content}
        </InfoBlock>
      )}
      disable={disabled}
    />
  );
};

export default FluroTooltip;
