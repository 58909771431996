// @ts-nocheck
import {loadFarmsFields, loadFieldGeometries, toggleTableView} from 'containers/map/actions';
import React, {useCallback, useEffect, useState} from 'react';
import {useAppDispatch, useAppSelector} from '_hooks';
import {unreachableError} from '_utils/pure-utils';
import type {Field} from 'containers/map/types';
import {EnrollmentStep, enrollFields, setEligibleRegionFields, setFarmId} from '../carbon-store';
import {selectPhase, selectStageById} from '../../monitoring/module/selectors';
import {MRVPhaseType, MRVStageType} from 'containers/mrv/types';
import './enrollment-form.scss';
import {EnrollFields} from './enroll-fields/enroll-fields';
import {getFarmIdsThatHasFields} from '../../utils';
import {Flex} from 'components';
import {selectUser} from 'containers/login/login-selectors';
import {selectFieldsByFarmId} from 'containers/map/reducer/selectors';
import {AssignPractices} from './assign-practices/assign-practices';
import {selectCurrentProjectFieldsList} from 'containers/mrv/monitoring/module/selectors';
import {ViewOutcomes} from 'containers/mrv/enrollment/form/view-outcomes';
import {useFetchStageCompletion} from 'containers/mrv/hooks';
import {useTranslation} from 'i18n-utils';
import {useMonitoringUrlParams} from 'containers/mrv/monitoring/hooks';
import {fetchProgram} from 'containers/mrv/monitoring/module/thunks';
import {RequestStatusRTK} from 'modules/helpers';
import {MRVForm} from 'containers/mrv/monitoring/form/mrv-form';
import {defaultEnrollmentStageTypes} from '../base/base';
import {getStageName} from 'containers/mrv/base';

export const DEMO_FARM_ID = 136;

const EnrolmentFormHeader = () => {
  const t = useTranslation();
  const {stageId} = useMonitoringUrlParams();
  const stage = useAppSelector(s => selectStageById(s, stageId));
  const enrollmentConfig = useAppSelector(s => selectPhase(s, MRVPhaseType.Enrollment));
  const enrollmentHeaderTitle = getStageName(stage);

  return (
    <div className={'carbon-header'}>
      <Flex alignItems={'center'} justifyContent={'space-between'}>
        <h3 className="tab-title">
          {enrollmentConfig && enrollmentHeaderTitle
            ? enrollmentHeaderTitle
            : //  on older programs, fields and boundaries doesn't exist as a stage
              t({id: 'Enroll.Fields and boundaries', defaultMessage: 'Fields and boundaries'})}
        </h3>
      </Flex>
    </div>
  );
};

export const EnrollmentForm = () => {
  const [didInit, setDidInit] = useState(false);

  const dispatch = useAppDispatch();
  const {stageId} = useMonitoringUrlParams();
  const stage = useAppSelector(s => selectStageById(s, stageId));
  const carbon = useAppSelector(s => s.carbon);
  const user = useAppSelector(selectUser);
  const fieldsByFarmId = useAppSelector(selectFieldsByFarmId);
  const projectFields = useAppSelector(selectCurrentProjectFieldsList);
  const programFetched = useAppSelector(
    s => s.helpers.asyncRTK.status[fetchProgram.typePrefix] === RequestStatusRTK.fulfilled
  );

  useFetchStageCompletion(MRVPhaseType.Enrollment);

  useEffect(() => {
    if (stage?.type_ === MRVStageType.ConfirmHistory) {
      dispatch(toggleTableView('monitoring'));
    }
  }, [dispatch, stage]);

  // When enrolled fields are updated, make sure the current farm is present.
  useEffect(() => {
    const farmIds = getFarmIdsThatHasFields(fieldsByFarmId, carbon.enrolledFields);
    if (!farmIds.includes(carbon.farmId)) {
      dispatch(setFarmId(farmIds[0]));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fieldsByFarmId, carbon.enrolledFields, carbon.farmId]);

  const loadFieldsAndGeometries = useCallback(
    async (farmIds: number[]) => {
      // 1. Fields.
      const farmIdsToLoad = farmIds.filter(id => id !== DEMO_FARM_ID && !fieldsByFarmId[id]);
      if (!farmIdsToLoad.length) return;

      const farmsResponse = await dispatch(loadFarmsFields(farmIdsToLoad));
      const farms = farmsResponse.payload as {[fieldId: number]: Field}[];
      const fields = farms.flatMap(farm => Object.values(farm));
      const geometriesToRequest = farms.flatMap((farm, i) =>
        Object.values(farm).map(field => ({
          farmId: farmIdsToLoad[i],
          fieldId: field.ID,
          md5: field.MD5,
        }))
      );

      // 2. Geometries.
      const geometries = fields.length
        ? await dispatch(loadFieldGeometries(geometriesToRequest))
        : {};

      // 3. Eligibility.
      const eligibility: {[fieldId: number]: boolean} = {};
      fields.forEach(f => {
        const geom = geometries[f.MD5];
        if (geom) {
          // eligibility[f.ID] = featureCollectionContains(pppx as GeoJSON.FeatureCollection, geom);
          eligibility[f.ID] = true; // pass all fields for now https://regrowag.slack.com/archives/C02EZPYF399/p1642583309083700?thread_ts=1642575495.076600&cid=C02EZPYF399
        }
      });
      dispatch(setEligibleRegionFields(eligibility));

      return {fields, geometries, eligibility};
    },
    [fieldsByFarmId]
  );

  useEffect(() => {
    if (didInit && user.groupIds?.length) {
      loadFieldsAndGeometries(user.groupIds);
      return;
    }

    const enrolledFields: Record<number, boolean> = {};
    projectFields.forEach(f => {
      enrolledFields[f.core_attributes?.kml_id] = true;
    });
    dispatch(enrollFields(enrolledFields));
    setDidInit(true);
  }, [user.groupIds, projectFields, didInit]);

  const panel = React.useMemo(() => {
    if (!programFetched) {
      return null; // Can return some loader.
    }

    if (stage && !defaultEnrollmentStageTypes.includes(stage?.type_)) {
      return <MRVForm phaseType={MRVPhaseType.Enrollment} />;
    }

    // Alternative stages that don't use MRVTable.
    switch (stage?.type_) {
      case MRVStageType.FieldBoundaries:
        return <EnrollFields />;
      case MRVStageType.AssignPractices:
        return <AssignPractices />;
      case MRVStageType.ViewOutcomes:
        return <ViewOutcomes />;
    }

    // Deprecated usage of carbon.step instead of stage.type_.
    switch (carbon.step) {
      case EnrollmentStep.Fields:
        return <EnrollFields />;
      case EnrollmentStep.CropPractices:
        return <MRVForm phaseType={MRVPhaseType.Enrollment} />;
      case EnrollmentStep.AssignPractices:
        return <AssignPractices />;
      case EnrollmentStep.ViewOutcomes:
        return <ViewOutcomes />;
      case EnrollmentStep.Survey:
        // All good, we don't have a separate view for the survey, it's a dialog with embedded paperform.
        return;
      default:
        unreachableError(carbon.step, `Carbon panel for step ${carbon.step} is not implemented`);
    }
  }, [carbon.step, stage?.type_, programFetched]);

  return (
    <>
      <EnrolmentFormHeader />
      {panel}
    </>
  );
};
