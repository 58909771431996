// @ts-nocheck
import {MapLoader} from 'components/map-loader';
import {selectHasFarmsOrGroupsIds} from 'containers/login/login-selectors';
import {EditControlButton} from 'containers/map/map-buttons/edit-control';
import SearchLocation from 'containers/map/map-buttons/search-location';
import {CLUFieldBoundariesOverlay} from 'containers/map/overlays/clu-field-boundaries-overlay';
import type L from 'leaflet';
import type {FitBoundsOptions} from 'leaflet';
import {AsyncStatusType, Status} from 'modules/helpers';
import React, {useCallback, useContext, useEffect, useMemo, useState} from 'react';
import {FeatureGroup, ZoomControl} from 'react-leaflet';
import {MAIN_SHAPE_COLOR, ERROR_SHAPE_COLOR} from '_constants';
import {useAppDispatch, useAppSelector, usePrevious} from '_hooks';
import {unreachableError} from '_utils/pure-utils';
import {t} from '../../../i18n-utils';
import {showNotification} from 'components/notification/notification';
import GoHomeBtn from '../../map/map-buttons/go-home-btn';
import {AddFieldsOverlay} from 'containers/map/overlays/add-fields-overlay';
import {OverlayContext} from '../context';
import {EnrollmentStep, setAssignPracticeSelectedFields, toggleEnrollField} from './carbon-store';
import {
  selectEnrollmentReadOnly,
  selectAssignPracticesSelectedFieldIds,
} from './carbon-store/selectors';
import {MultifarmFieldGeometriesOverlay} from './map/multifarm-field-geometries-overlay';
import {selectProjectFieldsList} from '../monitoring/module/selectors';
import {
  selectAllFields,
  selectAllFieldsByFieldId,
  selectFeatureGroupReDrawId,
  selectFieldsByFarmId,
  selectIsEditingMode,
} from 'containers/map/reducer/selectors';
import {useMonitoringUrlParams} from '../monitoring/hooks';
import {CarbonColorsOverview} from './map/carbon-colors-overview';
import {selectCropTypes} from '../../../modules/global/selectors';
import {AssignPracticesPopup} from './form/assign-practices/assign-practices-popup';
import {selectProtectedAreaBoundaries} from 'modules/add-fields/selectors';
import colors from 'variables.scss';
import {eligibleMsg} from './form/enroll-fields/base';

export const EnrollmentOverlay = () => {
  const {fitBounds} = useContext(OverlayContext);
  const dispatch = useAppDispatch();
  const crops = useAppSelector(selectCropTypes);
  const carbon = useAppSelector(s => s.carbon);
  const {isReadOnly} = useAppSelector(selectEnrollmentReadOnly);
  const fieldsByFarmId = useAppSelector(selectFieldsByFarmId);
  const featureGroupReDrawId = useAppSelector(selectFeatureGroupReDrawId);
  const asyncStatuses = useAppSelector(s => s.helpers.asyncStatuses);
  const hasFarms = useAppSelector(selectHasFarmsOrGroupsIds);
  const [lastBounds, setLastBounds] = useState<L.LatLngBounds>(null);
  const isEditingMode = useAppSelector(selectIsEditingMode);
  const {projectId, isEnrollment} = useMonitoringUrlParams();
  const assignPracticesSelectedFieldIds = useAppSelector(selectAssignPracticesSelectedFieldIds);
  const prev = usePrevious({lastBounds});
  const protectedAreaBoundaries = useAppSelector(selectProtectedAreaBoundaries);
  const fieldsByKmlId = useAppSelector(selectAllFields);
  const fieldsByFieldId = useAppSelector(selectAllFieldsByFieldId);
  const projectFields = useAppSelector(s => selectProjectFieldsList(s, projectId));

  const loading =
    asyncStatuses[AsyncStatusType.allFieldsData].status === Status.Pending ||
    asyncStatuses[AsyncStatusType.fieldGeometries].status === Status.Pending;

  const onFitBounds = (bounds?: L.LatLngBounds, options?: FitBoundsOptions) => {
    fitBounds(bounds, options);
  };

  useEffect(() => {
    lastBounds && onFitBounds(lastBounds);
  }, [carbon.step]);

  useEffect(() => {
    // initial fitBounds
    !prev?.lastBounds && lastBounds && onFitBounds(lastBounds);
  }, [lastBounds]);

  // Make sure enrollField doesn't depend on all-the-fields-state, so it can be memoized
  // down the chain at MultifarmFieldGeometriesOverlay and FluroGeoJson.
  const enrollField = useCallback(
    (fieldId: number) => {
      if (isReadOnly) {
        return;
      }

      const isIneligible = carbon.ineligibleRegionFields?.[fieldsByKmlId?.[fieldId]?.FieldID];
      if (isIneligible) {
        showNotification({
          title: t({id: 'note.warning', defaultMessage: 'Warning'}),
          message: eligibleMsg(),
          type: 'warning',
        });
        return;
      }
      dispatch(toggleEnrollField({fieldId}));
    },
    [isReadOnly, eligibleMsg, carbon.ineligibleRegionFields]
  );

  const stepComponents = useMemo(() => {
    switch (carbon.step) {
      case EnrollmentStep.Fields:
      case EnrollmentStep.CropPractices: {
        const fieldColors: {[fieldId: number]: string} = {};
        const fieldColorsBorder: {[fieldId: number]: string} = {};
        const fieldCategories: {[fieldId: number]: string} = {};
        for (let fieldId in carbon.enrolledFields) {
          if (carbon.enrolledFields[fieldId]) {
            const overlap = carbon.overlapFields.find(overlap => overlap.includes(Number(fieldId)));
            const isIneligible = carbon.ineligibleRegionFields?.[fieldsByKmlId?.[fieldId]?.FieldID];
            const isProtected = protectedAreaBoundaries[fieldId];

            fieldColorsBorder[fieldId] = isIneligible
              ? 'red'
              : isProtected
              ? colors['saffron']
              : undefined;
            fieldColors[fieldId] = isIneligible ? ERROR_SHAPE_COLOR : MAIN_SHAPE_COLOR;
            fieldCategories[fieldId] = overlap ? 'overlap' : 'enrolled';
          } else {
            fieldCategories[fieldId] = 'notenrolled';
          }
        }

        return (
          <>
            {/* EditControl is an external library that should be inside FeatureGroup */}
            <FeatureGroup key={featureGroupReDrawId}>
              <EditControlButton />
              <CLUFieldBoundariesOverlay />
            </FeatureGroup>

            <MultifarmFieldGeometriesOverlay
              fitBounds={onFitBounds}
              fieldColors={fieldColors}
              fieldColorsBorder={fieldColorsBorder}
              fieldCategories={fieldCategories}
              onGeometryClick={enrollField}
              saveBoundsToFit={setLastBounds}
            />
            <AddFieldsOverlay fitBounds={onFitBounds} />
          </>
        );
      }

      case EnrollmentStep.AssignPractices: {
        const fieldColors: {[fieldId: number]: string} = {};

        const enrolledFieldIds: Record<number, boolean> = {};

        projectFields.forEach(f => {
          const kmlId = fieldsByFieldId[f.fs_field_id]?.ID;
          if (kmlId) {
            fieldColors[kmlId] = assignPracticesSelectedFieldIds.includes(f.id)
              ? MAIN_SHAPE_COLOR
              : '';
            enrolledFieldIds[kmlId] = true;
          }
        });

        return (
          <>
            <MultifarmFieldGeometriesOverlay
              fitBounds={onFitBounds}
              fieldIds={enrolledFieldIds}
              fieldColors={fieldColors}
              CustomPopup={AssignPracticesPopup}
              saveBoundsToFit={setLastBounds}
              onGeometryClick={kmlId => {
                const mrvFieldId = projectFields.find(f => f.core_attributes?.kml_id === kmlId)?.id;
                if (mrvFieldId) {
                  const isSelected = assignPracticesSelectedFieldIds.includes(mrvFieldId);

                  if (isSelected) {
                    dispatch(
                      setAssignPracticeSelectedFields(
                        assignPracticesSelectedFieldIds.filter(id => id !== mrvFieldId)
                      )
                    );
                  } else {
                    dispatch(
                      setAssignPracticeSelectedFields([
                        ...assignPracticesSelectedFieldIds,
                        mrvFieldId,
                      ])
                    );
                  }
                }
              }}
            />
          </>
        );
      }

      case EnrollmentStep.ViewOutcomes: {
        const fieldColors: {[fieldId: number]: string} = {};
        const enrolledFieldIds: Record<number, boolean> = {};

        projectFields.forEach(f => {
          const kmlId = fieldsByFieldId[f.fs_field_id]?.ID;
          if (kmlId) {
            fieldColors[kmlId] = '';
            enrolledFieldIds[kmlId] = true;
          }
        });

        return (
          <>
            <MultifarmFieldGeometriesOverlay
              fitBounds={onFitBounds}
              fieldIds={enrolledFieldIds}
              fieldColors={fieldColors}
              saveBoundsToFit={setLastBounds}
            />
          </>
        );
      }

      case EnrollmentStep.Survey:
        // All good, no map for the survey.
        return;

      default:
        unreachableError(carbon.step, `Carbon overlay for step ${carbon.step} is not implemented`);
    }
  }, [
    carbon,
    crops,
    fieldsByFarmId,
    fieldsByKmlId,
    featureGroupReDrawId,
    protectedAreaBoundaries,
    enrollField,
  ]);

  return (
    <div className={'carbon-overlay'}>
      {/* Location buttons is needed for field add step, it includes "find place" part. Or when we need to create a farm */}
      {(carbon.step === EnrollmentStep.Fields || !hasFarms) && !isEditingMode && <SearchLocation />}

      {isEnrollment && hasFarms && !isEditingMode && (
        <GoHomeBtn fitBounds={() => (lastBounds ? onFitBounds(lastBounds) : null)} />
      )}

      <ZoomControl position="topleft" />

      {stepComponents}

      <MapLoader loading={loading} />
      <CarbonColorsOverview />
    </div>
  );
};
