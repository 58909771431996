import {useEffect} from 'react';
import {getGetURLParam, setGetParamToURL} from '_utils/pure-utils';
import {useAppDispatch, useAppSelector} from '_hooks';
import {useMapBarView} from '../map-bar/map-bar-view';
import {WHOLE_TABLE_VIEW_FEATURES} from '_constants';
import {toggleTableView} from '../actions';
import {dialogToggle, DialogType as HelpersDialogToggle} from '../../../modules/helpers';
import {selectCurrentTab} from '../reducer/selectors';
import {selectDialogVisibility} from 'modules/helpers/selectors';
import {openProfileDialog} from 'containers/profile/actions';
import {useLocation} from 'react-router-dom';
import {MRVStageType} from 'containers/mrv/types';
import {useMonitoringUrlParams} from 'containers/mrv/monitoring/hooks';
import {selectStageById} from 'containers/mrv/monitoring/module/selectors';
import {useWorkspace} from '_hooks/use-workspace';

export const GetParamEffects = (): null => {
  const {isWorkspaceMrv} = useWorkspace();
  const dispatch = useAppDispatch();
  const currentTab = useAppSelector(selectCurrentTab);
  const [wholeTableView] = useMapBarView();
  const profilePopUpVisibility = useAppSelector(s =>
    selectDialogVisibility(s, HelpersDialogToggle.profile)
  );
  const location = useLocation();
  const {stageId} = useMonitoringUrlParams();
  const stage = useAppSelector(s => selectStageById(s, stageId));

  useEffect(() => {
    if (getGetURLParam('integration-pop-up') === 'true') {
      setGetParamToURL('integration-pop-up', null);
      dispatch(dialogToggle(HelpersDialogToggle.integrationPlatforms, true));
    }
  }, [location?.search]);

  useEffect(() => {
    setTimeout(() => {
      if (getGetURLParam('profile-dialog') === 'true' && !profilePopUpVisibility) {
        dispatch(openProfileDialog());
      }
    }, 100); // add small delay before checking the GET param, this avoids recursive opening the profile dialog
  }, [location.search, profilePopUpVisibility]);

  useEffect(() => {
    if (!isWorkspaceMrv) {
      // close table view and remove URL param in case the new tab doesn't support table view
      if (wholeTableView && !WHOLE_TABLE_VIEW_FEATURES.includes(currentTab)) {
        if (stage?.type_ === MRVStageType.ConfirmHistory) {
          return;
        }

        dispatch(toggleTableView());
        return;
      }
    }
  }, [currentTab, dispatch, stage?.type_, wholeTableView, isWorkspaceMrv]);

  return null;
};
