import styled from 'styled-components';

export const Headline24 = styled.span`
  /* FS Type/Headline 24 (Bold) */
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;

  /* or 133% */

  color: #000000;

  /* Inside auto layout */
  flex: none;
  flex-grow: 0;
`;
