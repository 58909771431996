import {t} from 'i18n-utils';
import React from 'react';
import {Button, SelectField, FontIcon} from 'react-md';

type Props = {
  disabled?: boolean;
  uploadedFile: any; //TODO: type,
  onRemoveFile: (f: File) => void;
  onChangeIndex: (uploadedFile: any, value: any) => void;
  mosaicBands: any[];
};

const UploadFile = ({mosaicBands, disabled, uploadedFile, onRemoveFile, onChangeIndex}: Props) => {
  const loadedPercent = uploadedFile.percentLoaded || null;
  const uploadSpeed = uploadedFile.uploadSpeed || null;

  return (
    <div className="files-upload-container">
      <div className="holder">
        <SelectField
          id={`selected-layer-index-${uploadedFile.file.name || 'new-file'}`}
          label={t({id: 'Index type'})}
          placeholder={t({id: 'Index'})}
          disabled={disabled}
          className="index-type"
          menuItems={mosaicBands}
          value={uploadedFile.mosaicIndex}
          onChange={value => onChangeIndex(uploadedFile, value)}
          simplifiedMenu={false}
        />
      </div>
      <div className="holder">
        <div className={'uploaded-field-info'}>
          <div title={uploadedFile.file.name} className="uploaded-file-name">
            {uploadedFile.file.name}
          </div>

          <div className="load-status">
            {loadedPercent ? (
              loadedPercent === 100 ? (
                <FontIcon className="done-icon">done</FontIcon>
              ) : (
                <div className="progress-bar">{loadedPercent}%</div>
              )
            ) : (
              <Button
                className="delete-icon"
                title={t({id: 'remove this file from list files to upload'})}
                icon
                forceIconFontSize={true}
                forceIconSize={20}
                onClick={() => onRemoveFile(uploadedFile.file)}
                disabled={disabled}
              >
                {t({id: 'delete'})}
              </Button>
            )}
          </div>

          {uploadSpeed && loadedPercent !== 100 && (
            <span className={'load-speed'}>
              {t(
                {id: 'upload speed', defaultMessage: 'Upload speed: {uploadSpeed} bit/s'},
                {uploadSpeed}
              )}
            </span>
          )}
        </div>
      </div>
    </div>
  );
};

export default UploadFile;
