import {t} from 'i18n-utils';
import type {ComponentType} from 'react';
import React, {useContext} from 'react';
import {SelectionControl} from 'react-md';
import {InputContext} from './input-context';

type Props = {
  getLabel?: (value: boolean) => string;
  onChange: (value: string) => void;
};

export const InputBoolean: ComponentType<Props> = ({onChange, getLabel}) => {
  const {id, defaultValue, value, name, disabled, label, placeholder} = useContext(InputContext);
  // The backend expects all of these values to be strings, so we need to convert them to boolean for the component,
  // then back as "1" or "0" for the backend.
  const v = Boolean(Number(value || defaultValue));

  const p = label ? ` (${placeholder})` : '';

  return (
    <SelectionControl
      defaultChecked={v}
      checked={v}
      id={`${id}|${Math.random()}`}
      label={
        getLabel
          ? getLabel(v)
          : (v
              ? t({id: 'BooleanLabelYes', defaultMessage: 'Yes'})
              : t({id: 'BooleanLabelNo', defaultMessage: 'No'})) + p
      }
      aria-label={label}
      disabled={disabled}
      // react-md: hack because this Type expects name to never be undefined
      name={name || ''}
      type="switch"
      // react-md: hack because this Type expects string | number | boolean
      onChange={val => onChange(Boolean(val) ? '1' : '0')}
    />
  );
};
