import {t} from 'i18n-utils';
import React from 'react';
import type {SummaryUserActivity} from '../types';
import type {MeasurementSystem} from '_utils';
import {formatBigNumber, convertUnit} from '_utils';

type Props = {
  data: SummaryUserActivity;
  measurement?: MeasurementSystem;
};

const UserActivitySummary = React.memo(({data, measurement}: Props) => {
  const {total_users, total_farms, total_fields, total_area_ha} = data;
  const formattedArea = parseFloat(convertUnit(measurement, 'ac', total_area_ha));

  return (
    <div className="user-activity__summary">
      <div className="user-activity__summary-content">
        <div className="user-activity__summary-item">
          <span>{total_users}</span>
          Users
        </div>
        <div className="user-activity__summary-item">
          <span>{formatBigNumber(formattedArea)}</span>
          {t({id: measurement})}
        </div>
        <div className="user-activity__summary-item">
          <span>{formatBigNumber(total_farms)}</span>
          {t({id: 'Farms'})}
        </div>
        <div className="user-activity__summary-item">
          <span>{formatBigNumber(total_fields)}</span>
          {t({id: 'Fields'})}
        </div>
      </div>
    </div>
  );
});

export default UserActivitySummary;
