import type {ComponentType} from 'react';
import React from 'react';
import {scaleSymlog} from 'd3-scale';
import {Flex} from 'components';

type BarProps = {
  data: {id: number; value: number}[];
  colors: (props: {data: {id: number; value: number}; index: number}) => string;
  min: number;
  max: number;
};

export const Bars: ComponentType<BarProps> = ({data, colors}) => {
  const values = data.map(d => d.value);
  const max = Math.max(...values);
  const min = Math.min(...values);
  const width = `calc(${100 / data.length}% - 2px)`;
  const heightScale = scaleSymlog([min, max], [0, 100]);

  return (
    <Flex style={{height: '43px'}} alignItems="flex-end" justifyContent="space-between">
      {data.map((d, index) => {
        const {id, value} = d;
        const color = colors({data: {id, value}, index});
        const height = `${heightScale(value)}%`;

        return (
          <div
            key={`${index}-${value}-${color}`}
            className="fluro-rheostat__bar"
            style={{backgroundColor: color, height, width}}
          />
        );
      })}
    </Flex>
  );
};
