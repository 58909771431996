import {t} from 'i18n-utils';
import {TrendsIndex} from '../../types';
export const SMOOTHED_LINE_COLOR_SATELLITE = '#000';
export const WHOLE_FIELD_LINE_COLOR = '#43a047';

export function analyticsToCSV(data: any): string {
  if (!Array.isArray(data)) return '';
  let pointsCounter = 0;

  let result = '';
  data[0].forEach((label: string, i: number) => {
    let points = '';
    data[1].forEach((point: any) => {
      points += `${point.values[i]},`;
    });

    result += (`${label},` + points + `${data[2]},`).replace(/,$/, '\n');
  });

  let header = `${t({id: '[analyticsToCSV] dates'})}, ${data[1]
    .map((p: any) => {
      pointsCounter++;
      return p.name || `${t({id: '[analyticsToCSV] point'})} ${pointsCounter}`;
    })
    .join(',')} ,${t({id: '[analyticsToCSV] source'})} \n`;

  header += result;

  return header;
}

export const getLayerIndexByString = (value: string) => {
  switch (value) {
    case 'ccci':
      return TrendsIndex.CCCI;
    case 'ndvi':
      return TrendsIndex.NDVI;
    case 'ndre':
      return TrendsIndex.NDRE;
    case 'msavi':
      return TrendsIndex.MSAVI;

    default:
      return TrendsIndex.CCCI;
  }
};
