import type {PayloadAction} from '@reduxjs/toolkit';
import {createSlice} from '@reduxjs/toolkit';
import type {MRVStageIcon} from 'containers/mrv/types';

export const stageIconsSlice = createSlice({
  name: 'monitoring/stageIcons',
  initialState: [] as MRVStageIcon[],
  reducers: {
    setStageIcons: (state, action: PayloadAction<MRVStageIcon[]>) => {
      state = action.payload;
    },
    addStageIcon: (state, action: PayloadAction<MRVStageIcon>) => {
      state = [...state, action.payload];
    },
  },
});
