import colors from '../../variables.scss';
import styled from 'styled-components';

export const CommonDialogContentWrapper = styled.div`
  .agreements-message {
    margin-top: 15px;
    color: ${colors['main-gray-900']};
    display: flex;
    align-items: flex-start;

    .md-selection-control-container {
      margin-left: -10px;
    }
  }
`;
