import type {ComponentType} from 'react';
import React from 'react';

import type {RectangleProps} from './rectangle';
import {Rectangle} from './rectangle';

export const Circle: ComponentType<RectangleProps> = props => {
  const {size, ...rest} = props;
  return (
    <Rectangle {...rest} size={size || 30} padding={false} borderRadius={'100%'}>
      {props.children}
    </Rectangle>
  );
};
