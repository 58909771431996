import type {ComponentType} from 'react';
import React, {useState} from 'react';
import {t, FormattedMessage} from 'i18n-utils';
import moment from 'moment';

import AnomalyHeader from 'components/anomalies-ui/anomalies-header';
import AnomalyItem from 'components/anomalies-ui/anomaly-item';
import AnomalyLabelsDropdown from './labels-dropdown';
import LabelIcon from 'components/label-icon';
import {FontIcon} from 'react-md';
import SortButton, {SortButtonContainer} from 'components/sort-button';
import {getLabelNameByValue} from '_constants';

import {sortAnomalies, normalizeSensorIndex} from '_utils';
import {isDateInRange} from '_utils/pure-utils';

import {sumAreas, userFacingArea} from './anomalies-utils';
import {togglePopup} from '../../actions';
import {showNotification} from 'components/notification/notification';

import {
  bulkUpdateAreasOfInterest,
  removeAreaOfInterest,
  toggleEditAreaOfInterest,
  updateAreaOfInterest,
} from '../../actions/areas-of-interest-actions';

import {
  changeLowPerfAnomalyProp,
  toggleLowPerformingAreas,
  changeAnyTypeGeometryProp,
} from '../../actions/anomalies-actions';

import type {TSort, IAnomaly, SortTypes, TAnomalyProps} from './types';
import {
  InfoBlock,
  CustomCheckbox,
  FluroTableRow,
  FluroTableColumn,
  FluroDataTable,
  FluroTableHeader,
  FluroTableBody,
  FluroButton,
} from 'components';
import {WarningSvg} from 'components/icons';
import ChangeAreasOfInterestDates from './change-geometry-dates';
import type {ColumnContentType} from 'components/fluro-table-components/fluro-table-components';
import {VigorIcon} from '../../icons';
import {
  selectCurrentDate,
  selectCurrentDates,
  selectHistogram,
  selectImagesStatus,
  selectIsReadOnly,
  selectIsWholeTableView,
  selectMapOpenPopUpId,
} from 'containers/map/reducer/selectors';
import {selectMeasurement} from '../../../login/login-selectors';
import {
  selectAnomalies,
  selectLowPerformingAnomalies,
  selectPerformanceGeometries,
} from './anomalies-selectors';
import {useAppDispatch, useAppSelector} from '_hooks';
import {dialogToggle, DialogType} from 'modules/helpers';

const AreasList = () => {
  const dispatch = useAppDispatch();
  const measurement = useAppSelector(selectMeasurement);
  const currentDate = useAppSelector(selectCurrentDate);
  const isReadOnly = useAppSelector(selectIsReadOnly);
  const wholeTableView = useAppSelector(selectIsWholeTableView);
  const currentDates = useAppSelector(selectCurrentDates);
  const range = useAppSelector(selectHistogram).range;
  const lowPerfAnomalies = useAppSelector(selectLowPerformingAnomalies);
  const openPopupId = useAppSelector(selectMapOpenPopUpId);
  const imageStatus = useAppSelector(selectImagesStatus);
  const anomaliesHistoryOpen = useAppSelector(selectAnomalies).historyOpen;

  const {geometryToShow, historyGeometries} = useAppSelector(selectPerformanceGeometries);

  const [editDatesPopUpVisible, setEditDatesPopUpVisibility] = useState(false);
  const [sortBy, setSortBy] = useState<{type: SortTypes; order: boolean}>({
    type: 'ndvi',
    order: true,
  });

  const getAnomalyItem = (anomaly: IAnomaly, i: number) => {
    const {area, checked, endDate, startDate, label, title, id, priority} = anomaly.properties;
    return (
      <AnomalyItem
        label={
          <AnomalyLabelsDropdown
            disabled={isReadOnly}
            anomaly={anomaly}
            label={label}
            miniButton
            onChange={(prop, value, geometry) =>
              dispatch(changeAnyTypeGeometryProp(geometry as IAnomaly, prop, value))
            }
          />
        }
        isChecked={checked}
        area={userFacingArea(area, measurement)}
        key={`${id}-${i}`}
        startDate={startDate}
        labelName={label}
        endDate={endDate}
        title={title}
        openPopUp={openPopupId === id}
        onCheck={value => dispatch(changeAnyTypeGeometryProp(anomaly, 'checked', Boolean(value)))}
        onView={() => dispatch(togglePopup(id))}
        onEdit={() => toggleEditAnomaly(anomaly, true)}
        priority={priority}
        measurement={measurement}
      />
    );
  };

  const toggleEditAnomaly = (anomaly: IAnomaly, value: boolean) => {
    if (anomaly.properties.isLowPerf) {
      return dispatch(toggleLowPerformingAreas(value));
    }
    dispatch(toggleEditAreaOfInterest(value, anomaly));
  };

  const getTableRows = (data: IAnomaly[]) => {
    return data.map((anomaly: IAnomaly, i: number) => {
      const {label, title, area, checked, startDate, endDate, mean, type} = anomaly.properties;
      const meanIndex = mean ? normalizeSensorIndex(mean, range) : null;

      return (
        <FluroTableRow
          onClick={() => dispatch(changeAnyTypeGeometryProp(anomaly, 'checked', !checked))}
          selected={checked}
          key={i}
        >
          <FluroTableColumn>
            <div className="label-container">
              {checked ? <LabelIcon label={'done'} /> : <LabelIcon label={label} />}
              <span>{getLabelNameByValue(label)}</span>
            </div>
          </FluroTableColumn>
          <FluroTableColumn>{title}</FluroTableColumn>
          <FluroTableColumn type={'number'}>{userFacingArea(area, measurement)}</FluroTableColumn>
          <FluroTableColumn type={'date'}>
            {`${moment(startDate).format('DD MMM YYYY')} - ${moment(endDate).format(
              'DD MMM YYYY'
            )}`}
          </FluroTableColumn>
          <FluroTableColumn type={'number'}>
            <span className="anomaly-ndvi-value">
              <VigorIcon type={type} />
              {meanIndex}
            </span>
          </FluroTableColumn>
        </FluroTableRow>
      );
    });
  };

  const onAllChangeGeometryProp = (
    prop: keyof TAnomalyProps,
    value: any,
    geometries: IAnomaly[]
  ) => {
    geometries.forEach(geometry => {
      const {startDate, endDate, isLowPerf} = geometry.properties;
      const isInterSelect = isLowPerf
        ? true
        : // isDateInRange(moment.utc(this.props.currentDate, 'DD/MM/YYYY').format(), geometry.date, geometry.date)
          isDateInRange(moment.utc(currentDate, 'DD/MM/YYYY').format(), startDate, endDate);
      if (!isInterSelect && !Object.keys(currentDates).length) {
        return cantDisplayGeometryWarning();
      }
      isLowPerf
        ? dispatch(changeLowPerfAnomalyProp(geometry, prop, value))
        : dispatch(changeAnyTypeGeometryProp(geometry, prop, value));
    });
  };

  const cantDisplayGeometryWarning = () => {
    return showNotification({
      title: t({id: 'note.warning', defaultMessage: 'Warning'}),
      message:
        'This shape cannot be displayed for the selected season. Select a season between the starting date and the end date of the region of interest.',
      type: 'warning',
    });
  };

  const bulkUpdateProp = (prop: keyof TAnomalyProps, value: any, selectedShapes: IAnomaly[]) => {
    if (!['startDate', 'endDate', 'label'].includes(prop)) {
      return;
    }

    const lowPerfAnomalies = selectedShapes.filter(
      shape => shape.properties && shape.properties.isLowPerf
    );
    const ROI = selectedShapes.filter(shape => shape.properties && !shape.properties.isLowPerf);

    if (lowPerfAnomalies.length) {
      dispatch(changeLowPerfAnomalyProp(lowPerfAnomalies, prop, value));
      if (!value) {
        dispatch(togglePopup(undefined));
      }
    }

    if (ROI.length) {
      dispatch(bulkUpdateAreasOfInterest(ROI, prop, value));
    }
  };

  const deleteGeometries = (geometries: IAnomaly[]) => {
    const lowPerformingAnomalies = geometries.filter(({properties}) => properties.isLowPerf);
    const AOIs = geometries.filter(({properties}) => !properties.isLowPerf);

    if (lowPerformingAnomalies.length) {
      // "remove" low performing
      dispatch(changeLowPerfAnomalyProp(lowPerformingAnomalies, 'label', ''));
    }

    if (AOIs.length) {
      // remove AOI
      AOIs.forEach(aoi => dispatch(removeAreaOfInterest(aoi.properties.id, AOIs.length > 1)));
    }
  };

  const bulkChangeAreasOfInterestDates = (
    startDate: string,
    endDate: string,
    selectedAOI: IAnomaly[]
  ) => {
    selectedAOI.forEach(AOI => {
      // update all AOI one by one
      dispatch(
        updateAreaOfInterest(
          {...AOI, properties: {...AOI.properties, startDate, endDate}},
          selectedAOI.length > 1
        )
      );
    });
    toggleDatesPopUp(false);
  };

  const toggleDatesPopUp = (value: boolean) => setEditDatesPopUpVisibility(value);

  const sortTable = (val: SortTypes) => {
    setSortBy({type: val, order: !sortBy.order});
  };

  const openAddNewAnomalyPopUp = () => {
    dispatch(dialogToggle(DialogType.addNewAnomaly, true));
  };

  const visibleGeometries = anomaliesHistoryOpen
    ? [...geometryToShow, ...historyGeometries]
    : geometryToShow; // to allow actions on history geometries FSB-3896
  const filteredGeometries = sortAnomalies(geometryToShow);
  const tableBody = getTableRows(filteredGeometries);
  const isCheckedAll = geometryToShow.every(({properties}) => properties.checked);
  const selectedGeometries = visibleGeometries.filter(({properties}) => properties.checked);
  const selectedAOI = selectedGeometries.filter(({properties}) => !properties.isLowPerf);
  const {isNoImagery} = imageStatus;

  return (
    <>
      {editDatesPopUpVisible && (
        <ChangeAreasOfInterestDates
          onHide={() => toggleDatesPopUp(false)}
          saveNewDates={(startDate, endDate) =>
            bulkChangeAreasOfInterestDates(startDate, endDate, selectedAOI)
          }
        />
      )}

      {!wholeTableView && (
        <AnomalyHeader
          isCheckedAll={isCheckedAll}
          title={t(
            {id: '{count} {plural, count, one {area} other {areas}}'},
            {count: geometryToShow.length}
          )}
          subtitle={sumAreas(geometryToShow, measurement)}
          isReadOnly={isReadOnly}
          onSelectAll={(value: any) => onAllChangeGeometryProp('checked', value, geometryToShow)}
          onChangeLabel={bulkUpdateProp}
          labelItems={selectedGeometries}
          onDelete={() => deleteGeometries(selectedGeometries)}
          toggleDatesPopUp={selectedAOI.length ? () => toggleDatesPopUp(true) : undefined}
        />
      )}
      {geometryToShow.length && !lowPerfAnomalies.isVisible ? (
        <>
          {wholeTableView ? (
            <>
              <div className={'anomaly-table-header-title'}>
                <CustomCheckbox
                  isChecked={isCheckedAll}
                  onCheck={(value: boolean) =>
                    onAllChangeGeometryProp('checked', value, geometryToShow)
                  }
                />
                <h4 className={'anomaly-table__title'}>{t({id: 'Areas of Interest'})}</h4>
              </div>
              <FluroDataTable
                baseId={'ROI-table-view'}
                selectableRows={false}
                className={`anomaly-table`}
              >
                <FluroTableHeader>
                  <TableHeader sortTable={sortTable} order={sortBy.order} type={sortBy.type} />
                </FluroTableHeader>
                <FluroTableBody>{tableBody}</FluroTableBody>
              </FluroDataTable>
            </>
          ) : (
            geometryToShow.map(getAnomalyItem)
          )}
        </>
      ) : (
        !lowPerfAnomalies.isVisible &&
        !isNoImagery && (
          <div className={'no-AOI-detection'}>
            <div className={'header-part'}>
              <div>{t({id: 'No current areas of interest'})}</div>
              <FluroButton
                id={'add-farm-btn'}
                className="add-button" // add-button - global class for buttons with + icon
                onClick={openAddNewAnomalyPopUp}
                raised
                primary
                iconEl={<FontIcon>add</FontIcon>}
              >
                {t({id: 'Add'})}
              </FluroButton>
            </div>
            <InfoBlock appearance={'info'} className={'tab-info-block'}>
              <div className={'align-center flex-wrap'}>
                <span>
                  <FormattedMessage
                    id="Or use the <icon></icon> button on the left hand side"
                    defaultMessage="Or use the <icon></icon> button on the left hand side
                    of the map to view low performing areas for this image."
                    values={{icon: () => <WarningSvg className={'info-color'} />}}
                  />
                </span>
              </div>
            </InfoBlock>
          </div>
        )
      )}
    </>
  );
};

const TableHeader: ComponentType<TSort & {sortTable: (val: SortTypes) => void}> = ({
  order,
  type,
  sortTable,
}) => {
  const dataTypes: {
    type?: ColumnContentType;
    name: any;
    property: SortTypes;
    sorted?: boolean;
  }[] = [
    {
      name: <FormattedMessage id="[anomaly] Label" defaultMessage="Label" />,
      property: 'label',
      sorted: true,
    },
    {
      name: <FormattedMessage id="Title" defaultMessage="Title" />,
      property: 'title',
      sorted: true,
    },
    {
      type: 'number',
      name: <FormattedMessage id="Area" defaultMessage="Area" />,
      property: 'size',
      sorted: true,
    },
    {
      type: 'date',
      name: <FormattedMessage id="Dates detected" defaultMessage="Dates detected" />,
      property: 'date',
      sorted: true,
    },
    {
      type: 'number',
      name: <FormattedMessage id="Avg NDVI" defaultMessage="Avg NDVI" />,
      property: 'ndvi',
      sorted: true,
    },
  ];

  return (
    <FluroTableRow>
      {dataTypes.map(el => (
        <FluroTableColumn type={el.type} key={`element-${el.property}`}>
          <SortButtonContainer onClick={() => (el.sorted ? sortTable(el.property) : null)}>
            {el.name}
            {el.sorted && (
              <SortButton
                selected={el.property === type}
                descending={type === el.property ? order : false}
              />
            )}
          </SortButtonContainer>
        </FluroTableColumn>
      ))}
    </FluroTableRow>
  );
};

export default AreasList;
