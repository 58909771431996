import {selectUser} from 'containers/login/login-selectors';
import {loadFarmsFields} from 'containers/map/actions';
import {selectFieldsByFarmId} from 'containers/map/reducer/selectors';
import {useEffect} from 'react';
import {useAppDispatch, useAppSelector} from '_hooks';
import {useParsedMatchParams} from '_hooks/use-parsed-match-params';
import {useUrlParams} from '_hooks/use-url-params';
import {DEMO_FARM_ID} from '../enrollment/form/enrollment-form';
import {selectProjectById} from './module/selectors';
import {useLocation} from 'react-router-dom';
import type {DocusignContractEvent} from './module/types';

export const useMonitoringUrlParams = () => {
  const {projectId, programId} = useParsedMatchParams();
  const [{stageId, programId: programId_, entityId, event, summerCrops, configView}] =
    useUrlParams();
  const location = useLocation();
  const project = useAppSelector(s => selectProjectById(s, projectId));
  const programIdToSet = project?.program_id || programId || programId_;

  return {
    projectId,
    programId: programIdToSet,
    stageId: parseInt(String(stageId)),
    entityId: parseInt(String(entityId)),
    contractEvent: event as DocusignContractEvent,
    summerCrops: summerCrops ? String(summerCrops).split(',') : [],
    configView,
    isMonitoring: location.pathname.includes('monitoring'),
    isEnrollment: location.pathname.includes('enrollment'),
    isMRVHomePage: location.pathname.includes('home'),
  };
};

export const useLoadFsFields = () => {
  const user = useAppSelector(selectUser);
  const fieldsByFarmId = useAppSelector(selectFieldsByFarmId);
  const dispatch = useAppDispatch();

  useEffect(() => {
    (async () => {
      const farmIdsToLoad = user?.groupIds?.filter(
        id => id !== DEMO_FARM_ID && !fieldsByFarmId[id]
      );
      if (!farmIdsToLoad?.length) return;

      await dispatch(loadFarmsFields(farmIdsToLoad));
    })();
  }, [user?.groupIds, fieldsByFarmId]);
};
