import {FluroButton} from 'components';
import styled, {createGlobalStyle} from 'styled-components';

export const StyledReportBackButton = styled(FluroButton)`
  width: 200px;
  margin-bottom: 20px;
  padding: 12px;
  color: ${props => props.theme.color.text.main} !important;
  border-color: ${props => props.theme.color.stroke.strong} !important;

  .md-icon-text {
    line-height: 1;
    padding-left: 0px;
    text-transform: uppercase;
  }
`;

export const GlobalEFTooltipStyles = createGlobalStyle`
  &#ef-tooltip-container,
  #ef-tooltip-container .info-block {
    background-color: #1b1b1b;
    color: white;
    border-radius: 4px;
    padding: 8px;
    width: 200px !important;

    .info-block__content {
      width: 200px;

      #ef-tooltip-circle {
        flex: 0 0 12px;
        margin-right: 8px;
      }
    }
  }

  #ef-tooltip-container::after {
    border-left-color: #1b1b1b;
  }
`;

export const StyledEmissionsSection = styled.div`
  border: 1px solid ${({theme}) => theme.color.stroke.strong};
  border-radius: 4px;
  padding: 16px 12px 16px 12px;
  background-color: ${props => props.theme.color.surface.main}; // #ffffff
  .bar-charts {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
  }
`;

export const StyledGHGHeader = styled.div`
  position: relative;
  display: flex;
  align-items: flex-end;
  width: 70%;
  .scenarios {
    height: 22px;
    margin-bottom: 10px;
  }
  .chart-header {
    position: relative;
    left: 160px;
  }
`;

export const StyledEmissionsBarTooltip = styled.div<{color: string; scenarioRank: number}>`
  position: relative;
  border-radius: 4px;
  color: white;
  background-color: black;
  padding: 8px;
  max-width: 270px;
  ::before {
    content: '';
    position: absolute;
    bottom: -13px;
    left: calc(50% - 10px);
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 13px solid black;
  }

  .scenario-name {
    display: flex;
    flex-direction: column;
    position: relative;
    width: calc(100% - 29px);
    left: 29px;
    top: 5px;
    margin-bottom: 15px;
    ::before {
      position: absolute;
      display: flex;
      content: '${props => props.scenarioRank}';
      width: 24px;
      height: 24px;
      text-align: center;
      left: -29px;
      top: 0px;
      background-color: ${props => props.color};
      color: black;
      border-radius: 50%;
      justify-content: center;
      align-items: center;
    }
  }
`;

export const ReportDashboardCard = styled.div`
  background-color: white;
  border: 1px solid ${({theme}) => theme.color.stroke.strong};
  border-radius: 4px;
  padding: 20px 16px 0;
  position: relative;
  top: auto;
  right: auto;
  width: auto;
  height: 357px;

  .chart-container {
    position: absolute;
    top: 86px;
    bottom: 0;
    width: calc(100% - 32px);
    height: calc(100% - 86px - 16px);

    .responsive-chart {
      height: 100%;
      width: 100%;
    }

    &.bar-charts {
      width: 100%;
    }
  }

  &.emission-card {
    border: none;
    padding: 0;
    height: 315px;

    .chart-container {
      top: 40px;
    }
  }

  .scatterplot-tooltip {
    background-color: ${({theme}) => theme.colorPalette.fs_main.black};
    color: white;
    border: 1px solid ${({theme}) => theme.color.stroke.strong};
    border-radius: 4px;
    padding: 8px;

    .scatterplot-circle {
      color: ${({theme}) => theme.colorPalette.fs_main.black};
    }
  }

  .ef-legend {
    position: absolute;
    top: 0;
    right: 0;
    width: 120px;
    display: flex;
    flex-direction: column;
    gap: 4px;

    .ef-legend-circle {
      flex: 0 0 12px;
    }

    .ef-legend-text {
      font-size: 10px;
      line-height: 12px;
      color: ${({theme}) => theme.color.text.main};
    }
  }
`;

export const AdjustableCard = styled(ReportDashboardCard)<{
  max?: number;
  min?: number;
  base?: number;
  height?: number;
}>`
  max-width: ${props => props.max || 504}px;
  min-width: ${props => props.min || 300}px;
  flex: 1 1 ${props => props.base || 504}px;
  height: ${props => props.height || 375}px;
`;

export const GradientPanel = styled.div`
  position: relative;
  top: 240px;
  padding-left: 12px;
  padding-right: 40px;
  flex-wrap: wrap;
  display: flex;
  justify-content: space-between;
`;

export const GradientColors = styled.div<{startColor: string; endColor: string}>`
  margin-bottom: 5px;
  height: 8px;
  background: linear-gradient(
    90deg,
    ${props => props.startColor} 0%,
    ${props => props.endColor} 100%
  );
`;

export const GradientHalf = styled.div<{direction: 'right' | 'left'}>`
  display: flex;
  flex-direction: column;
  flex-basis: 49%;
  ${GradientColors} {
    border-radius: ${({direction}) => (direction === 'right' ? '0 2px 2px 0' : '2px 0 0 2px')};
  }
  text-align: ${({direction}) => direction};
`;
