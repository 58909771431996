import React, {useEffect, useState} from 'react';
import {CircularProgress} from 'react-md';
import {useAppDispatch, useAppSelector} from '_hooks';
import {classifyAreaWithUnits} from '_utils';
import {useParsedMatchParams} from '_hooks/use-parsed-match-params';
import {Flex, FluroButton, Text} from 'components';
import {showNotification} from 'components/notification/notification';
import {AddSupplyShedPopup} from './supply-shed-dialogs/add-supply-shed-popup';
import {ProcessingPopup} from './supply-shed-dialogs/processing-popup';
import {SupplyShedCard} from './components/supply-shed-card';
import {ActionType} from 'containers/si/module/types';
import {
  fetchSISupplySheds,
  addSISupplyShed,
  updateSISupplyShed,
  removeSISupplyShed,
  updateSISubsection,
  removeSISubsection,
} from 'containers/si/module/thunks';
import {selectIsLoading} from 'modules/helpers/selectors';
import {selectMeasurement} from 'containers/login/login-selectors';
import {
  setCurrentProgram,
  setProcessing,
  setInteractionType,
  setProgramGeometries,
  setShouldShowMapTools,
  setShowProgramGeometries,
} from 'containers/si/module/reducer';
import {
  selectProgramProcessing,
  selectSIProgramById,
  selectSISupplySheds,
  selectSISupplyShedsListByProgramId,
} from 'containers/si/module/selectors';
import {defaultSISupplyShed} from 'containers/si/module/base';
import {useFetchGeometries} from 'containers/si/hooks/useFetchGeometries';

export const SupplyShedConfig = () => {
  const dispatch = useAppDispatch();
  const {programId} = useParsedMatchParams();
  const supplySheds = useAppSelector(selectSISupplySheds);
  const programSupplySheds = useAppSelector(s => selectSISupplyShedsListByProgramId(s, programId));
  const program = useAppSelector(s => selectSIProgramById(s, programId));
  const measurement = useAppSelector(selectMeasurement);
  const programProcessing = useAppSelector(selectProgramProcessing);
  const [processingPopupVisible, setProcessingPopupVisible] = useState(false);
  const [supplyShedPopupVisible, setSupplyShedPopupVisible] = useState(false);
  const [supplyShedTotalArea, setSupplyShedTotalArea] = useState(0);
  const isLoadingSupplySheds = useAppSelector(s =>
    selectIsLoading(s, [ActionType.FETCH_SI_SUPPLY_SHEDS])
  );

  const {isLoading: isLoadingGeometries, allGeometryFeatures} = useFetchGeometries('supply-shed');

  if (programProcessing && !processingPopupVisible) {
    dispatch(setProcessing(false));
    setProcessingPopupVisible(true);
  }

  useEffect(() => {
    dispatch(setShouldShowMapTools(false));
    dispatch(setInteractionType('none'));
    dispatch(setShowProgramGeometries(true));
  }, [dispatch]);

  useEffect(() => {
    if (!supplySheds[programId]) {
      dispatch(fetchSISupplySheds(programId));
    }
    dispatch(setCurrentProgram(programId));
  }, [dispatch, programId, supplySheds]);

  useEffect(() => {
    // TODO: These geometry features are stored in redux for some reason
    // assess whether that is necessary
    dispatch(setProgramGeometries(allGeometryFeatures));
  }, [dispatch, allGeometryFeatures]);

  useEffect(() => {
    // Calculate total area of all supply sheds
    const totalArea = programSupplySheds?.reduce((acc, supplyShed) => {
      return acc + (supplyShed?.total_field_area_ha || 0);
    }, 0);
    setSupplyShedTotalArea(totalArea);
  }, [programSupplySheds]);

  const createSupplyShed = async (name: string) => {
    setSupplyShedPopupVisible(false);
    const newSupplyShed = {...defaultSISupplyShed, name};
    dispatch(addSISupplyShed({programId, supplyShed: newSupplyShed}));
  };

  const deleteArea = (areaType: 'shed' | 'region', supplyShedId: number, subsectionId?: number) => {
    if (areaType === 'shed') {
      if (
        confirm(
          `Are you sure you want to delete the supply shed? All subregions will also be deleted.`
        )
      ) {
        dispatch(removeSISupplyShed({programId, supplyShedId}));

        showNotification({
          type: 'success',
          title: 'Success',
          message: 'The supply shed was removed.',
        });
      }
    } else if (subsectionId && areaType === 'region') {
      if (confirm(`Are you sure you want to delete the subregion?`)) {
        dispatch(removeSISubsection({programId, supplyShedId, subsectionId}));

        showNotification({
          type: 'success',
          title: 'Success',
          message: 'The subregion was removed.',
        });
      }
    }
  };

  const updateName = (
    areaType: 'shed' | 'region',
    name: string,
    supplyShedId: number,
    subsectionId: number | null
  ) => {
    if (areaType === 'shed') {
      dispatch(updateSISupplyShed({programId, supplyShedId, supplyShed: {name}}));
    } else if (subsectionId && areaType === 'region') {
      dispatch(updateSISubsection({programId, supplyShedId, subsectionId, subsection: {name}}));
    }
  };

  return (
    <>
      <Flex alignItems={'center'} fullWidth justifyContent={'space-between'}>
        <div>
          <Text variant="h1" className="mt-2 margin-bottom-8">
            Supply Sheds
          </Text>
          <Text variant="h3" className="mb-0.5">
            {classifyAreaWithUnits(Math.round(supplyShedTotalArea), measurement)}
            {' / '}
            {classifyAreaWithUnits(Math.round(program?.acreage_limit_ha || 0), measurement)}
          </Text>
        </div>
        <FluroButton raised grayBorder blank onClick={() => setSupplyShedPopupVisible(true)}>
          + Supply Shed
        </FluroButton>
      </Flex>
      <div className="divider" />
      <Flex className={'margin-bottom-16'} fullWidth justifyContent={'center'}>
        {isLoadingSupplySheds || isLoadingGeometries ? (
          <CircularProgress className={'progress'} id="loading-supply-sheds" />
        ) : programSupplySheds?.length === 0 ? (
          <Text secondary> Add a supply shed to get started</Text>
        ) : null}
      </Flex>
      {programSupplySheds?.map(supplyShed => (
        <SupplyShedCard
          key={supplyShed.id}
          supplyShed={supplyShed}
          deleteArea={deleteArea}
          updateName={updateName}
        />
      ))}
      {supplyShedPopupVisible && (
        <AddSupplyShedPopup
          name=""
          description="Description"
          onHide={() => setSupplyShedPopupVisible(false)}
          onSave={createSupplyShed}
        />
      )}
      {processingPopupVisible && (
        <ProcessingPopup onHide={() => setProcessingPopupVisible(false)} />
      )}
    </>
  );
};
