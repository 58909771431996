import {FluroButton, FluroChip} from 'components';
import {currentYear} from 'containers/mrv/enrollment/base/base';
import React, {useContext} from 'react';
import {Icon} from '@regrow-internal/design-system';
import type {InputComponentProps} from './input-component';
import {InputContext} from './input-context';
import {useTheme} from 'styled-components';
import {TextField} from 'react-md';
import {useAppSelector} from '_hooks';
import {selectIsImpersonatorSuperAdmin} from 'containers/login/login-selectors';

export const InputYear = ({onChange}: {onChange: InputComponentProps['onChange']}) => {
  const {id, value, defaultValue, label, placeholder, disabled} = useContext(InputContext);
  const theme = useTheme();
  const impersonatorIsSuperAdmin = useAppSelector(selectIsImpersonatorSuperAdmin);

  function change(v: string) {
    const formattedValue = v
      // Replace anything that isn't alphanumeric, dots or dashes
      .replace(/[^\w.-]/g, '');

    // Just update the draft, don't update the server value yet.
    onChange(formattedValue, false);
  }

  // Update the server value.
  const update = () => onChange(value);
  const clear = () => onChange(undefined);

  // For regular users just show the readonly chips all the time.
  if (!impersonatorIsSuperAdmin) {
    return <FluroChip label={value || currentYear} tone={'dimmed'} disabled />;
  }

  /**
   * For super admins we show the input when there's no server value.
   * That mechanism also allows to enter any year.
   *
   * `defaultValue` is the latest server value.
   * `value` is the latest draft value.
   *
   * 1. If we have a server value, we show it as a chip.
   *
   * 2. A user can remove it with a cross button,
   * then there's no server value (defaultValue == null), so we show the input
   * that updates the draft value (value).
   *
   * 3. Until a user blurs or clicks confirm,
   * which would update the server value and show the chip instead of an input again.
   */
  return defaultValue ? (
    <>
      <FluroChip label={value || currentYear} tone={'dimmed'} disabled />
      <FluroButton
        icon
        iconEl={<Icon type="cross-circled" color={theme.color.text.secondary} />}
        onClick={clear}
      />
    </>
  ) : (
    <TextField
      type={'text'}
      id={id}
      value={value}
      label={label}
      placeholder={placeholder}
      disabled={disabled}
      onChange={v => change(v as string)}
      onBlur={update}
    />
  );
};
