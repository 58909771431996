import React from 'react';
import {Flex} from 'components/flex/flex';
import {FluroDialog} from 'components/fluro-dialog';
import {FluroButton} from 'components/fluro-button/fluro-button';
import {t} from 'i18n-utils';
import {useAppDispatch} from '_hooks';
import {logout} from 'containers/login/actions';
import {Circle} from 'components/shapes/circle';
import {Text} from 'components';
import colors from 'variables.scss';
import {EnrollmentIcon, MonitoringIcon} from 'containers/mrv/icons';
import styled from 'styled-components';

export type FinishPhaseDialogProps = {
  finishPhaseType: string;
  onHide?: () => void;
};
export const FinishPhaseDialog = ({finishPhaseType, onHide}: FinishPhaseDialogProps) => {
  const dispatch = useAppDispatch();

  return (
    <StyledFluroDialog
      id="FinishPhaseDialog"
      title={
        <PhaseItems>
          <Phase justifyContent="space-between">
            <Flex gap="5px" alignItems="center">
              <Circle className="crop-icon" color={colors['main-green-600']}>
                <EnrollmentIcon />
              </Circle>
              <PhaseTitle>
                {t({id: 'EnrollmentStepName.Enrollment', defaultMessage: 'Enrollment'})}
              </PhaseTitle>
            </Flex>

            <Badge className="program-stage-item active">
              {t({id: 'Complete', defaultMessage: 'Complete'})}
            </Badge>
          </Phase>
          <Phase justifyContent="space-between">
            <Flex gap="5px" alignItems="center">
              <Circle className="crop-icon" color={colors['main-green-600']}>
                <MonitoringIcon />
              </Circle>
              <PhaseTitle>
                {t({id: 'MonitoringStageStep.Measurement', defaultMessage: 'Measurement'})}
              </PhaseTitle>
            </Flex>

            <Badge className="program-stage-item active">
              {t({id: 'Complete', defaultMessage: 'Complete'})}
            </Badge>
          </Phase>
        </PhaseItems>
      }
      visible
      width={450}
      onHide={onHide}
    >
      <Content>
        <h3>
          {t({
            defaultMessage: `${finishPhaseType} phase complete.`,
          })}
        </h3>
        <Text>
          {t({
            defaultMessage: `Congratulations! You have successfully completed the ${finishPhaseType} phase. We will now review the information and contact you soon if there is any further information required for your account.`,
          })}
        </Text>
        <Flex justifyContent="flex-end">
          <FluroButton onClick={() => dispatch(logout())} primary raised>
            {t({id: 'Logout', defaultMessage: 'Logout'})}
          </FluroButton>
        </Flex>
      </Content>
    </StyledFluroDialog>
  );
};

const StyledFluroDialog = styled(FluroDialog)`
  .fluro-dialog__content {
    background-color: ${colors['main-gray-200']};
  }

  .mrv-next-phase-controls {
    margin-top: 30px;
  }
`;

const Content = styled.div`
  background-color: #fff;
  margin: -24px;
  margin-top: unset;
  padding: 24px;
`;

const PhaseItems = styled.div`
  margin-top: 25px;
  margin-bottom: 25px;
`;

const Phase = styled(Flex)`
  border-radius: 5px;
  background-color: #fff;
  padding: 15px;
  margin-top: 15px;
`;

const PhaseTitle = styled.span`
  font-size: 14px;
`;

const Badge = styled.div`
  color: ${colors['dodger-blue']} !important;
  font-size: 12px;
  padding: 0 10px;
  height: 30px;
  line-height: 30px;
`;
