import {FluroButton} from 'components';
import {useMRVValues} from 'containers/mrv/data/use-mrv-values';
import type {MRVPhaseType, MRVValueInput} from 'containers/mrv/types';
import {MRVProgress} from 'containers/mrv/types';
import {getEntityId} from 'containers/mrv/value-utils';
import type {ComponentType} from 'react';
import React, {useState} from 'react';
import {useDispatch} from 'react-redux';
import {useAppSelector} from '_hooks';
import {EditIcon} from '../../../../map/icons';
import {useMonitoringUrlParams} from '../../hooks';
import {selectStageById, selectStageEnabledAttributes} from '../../module/selectors';
import {updateMRVValues} from '../../module/thunks';
import {useIsAttributeDisabled} from '../../module/useIsAttributeDisabled';
import {MonitoringBulkEditFieldsDialog} from './bulk-edit-dialog';
import type {MRVBulkEditValues} from './types';

export const EditFieldButton: ComponentType<{
  phaseType: MRVPhaseType;
  entityId: number;
  rowId: number;
  disabled?: boolean;
}> = ({phaseType, entityId, rowId, disabled}) => {
  const dispatch = useDispatch();
  const [dialogVisibility, setDialogVisibility] = useState(false);

  const {projectId, stageId} = useMonitoringUrlParams();
  const stage = useAppSelector(s => selectStageById(s, stageId));
  const attributes = useAppSelector(s => selectStageEnabledAttributes(s, stageId));
  const isAttributeDisabled = useIsAttributeDisabled();

  const {entityValueRows} = useMRVValues(phaseType, projectId, stage);
  const rows = entityValueRows[entityId];

  const valueRow = rows?.find(row => getEntityId(row) === rowId);

  if (!stage || !valueRow) return null;

  const defaultValues: MRVBulkEditValues = attributes.reduce((acc, attr) => {
    const disabledByRules = isAttributeDisabled(attr, valueRow.values);
    return {
      ...acc,
      [attr.id]: {
        totalRowsNumber: rows?.length,
        lockedRowsNumber: valueRow?.values[attr.id]?.locked ? 1 : 0,
        disabledRowsNumber: disabledByRules ? 1 : 0,
        isLocked: valueRow?.values[attr.id]?.locked,
        value: valueRow?.values[attr.id]?.value || '',
      },
    };
  }, {});

  const handleFormSubmit = async (values: MRVBulkEditValues) => {
    const update = Object.entries(values).map(([attributeIdKey, item]) => {
      const attribute_id = parseInt(attributeIdKey, 10);
      return {
        entity_type: stage.entity_type,
        row_id: valueRow?.row_id,
        attribute_id,
        value: item.value,
        locked: item.isLocked,
        confirmed: true,
        progress: MRVProgress.Monitoring,
      } as MRVValueInput;
    });

    await dispatch(
      updateMRVValues({
        update: {
          [entityId]: update,
        },
        projectId,
        entityType: stage.entity_type,
      })
    );
    setDialogVisibility(false);
  };

  return (
    <>
      <FluroButton
        disabled={disabled}
        onClick={() => setDialogVisibility(true)}
        icon
        iconEl={<EditIcon />}
      />
      {dialogVisibility && (
        <MonitoringBulkEditFieldsDialog
          defaultValues={defaultValues}
          selectedFields={[{entityId, rowId}]}
          stage={stage}
          attributes={attributes}
          onHide={() => setDialogVisibility(false)}
          onSubmit={handleFormSubmit}
        />
      )}
    </>
  );
};
