import cn from 'classnames';
import {FluroButton, Sticky} from 'components';
import {CardBase} from 'components/card/card-base';
import {setFilter} from 'modules/sustainability-insights/actions';
import {
  selectGeometryTypeFilter,
  selectIsSIDataLoading,
} from 'modules/sustainability-insights/selectors';
import {selectIsSI} from 'modules/global/selectors';
import {SIGeometryFilterType} from 'modules/sustainability-insights/types';
import type {ComponentType} from 'react';
import React from 'react';
import {FontIcon} from 'react-md';
import {useDispatch} from 'react-redux';
import {useAppSelector} from '_hooks';
import './abatement-potential-card.scss';
import {AbatementChart} from './abatement-potential-chart';

type Props = {isActive?: boolean};

export const AbatementCard: ComponentType<Props> = ({isActive = false}) => {
  const isSI2 = useAppSelector(selectIsSI); // Is in SIv2 selector
  return (
    <>
      {/* Hide the card if in SIv2 */}
      {!isSI2 && <AbatementPotentialToggleButton />}

      <div className={cn('abatement-potential-card-container', {active: isActive})}>
        <CardBase active={isActive} className={'sustainability-card'}>
          {isActive && <AbatementChart />}
        </CardBase>
      </div>
    </>
  );
};

const AbatementPotentialToggleButton = () => {
  const dispatch = useDispatch();
  const activeFilterType = useAppSelector(selectGeometryTypeFilter);
  const isLoading = useAppSelector(selectIsSIDataLoading);
  const isActive = activeFilterType === SIGeometryFilterType.AbatementPotential;

  const onFilterClick = () => {
    if (isLoading) return;

    dispatch(
      setFilter({
        activeGeometryType: isActive
          ? SIGeometryFilterType.CoverCrops
          : SIGeometryFilterType.AbatementPotential,
      })
    );
  };

  return (
    <Sticky className={cn('abatement-potential-sticky-btn', {active: isActive})}>
      <FluroButton
        onClick={onFilterClick}
        blank
        raised
        iconBefore={false}
        iconEl={<FontIcon className={'arrow-icon'}>trending_flat</FontIcon>}
      >
        Abatement potential
      </FluroButton>
    </Sticky>
  );
};
