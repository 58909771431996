import React from 'react';
// Properly importing mapbox-gl is a pain due to transpilation + babel
// see details at: https://github.com/mapbox/mapbox-gl-js/issues/10173
import Map from 'react-map-gl';
import mapboxgl from 'mapbox-gl';
// @ts-expect-error error leftover from convertion to strict mode, please fix
import MapboxWorker from 'worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker'; // eslint-disable-line
import 'mapbox-gl/dist/mapbox-gl.css';
import {useAppDispatch, useAppSelector} from '_hooks';
import {switchMapStyle} from './../module/reducer';
import {selectMapInitialView, selectMapStyle} from './../module/selectors';
import type {SIMapStyleType} from 'containers/si/types';
import {mapboxMapStyle} from 'containers/si/types';
import {FluroButton} from 'components';
import './si-map.scss';

interface BaseMapProps {
  children?: any;
  mapRef?: React.RefObject<any>;
  interactiveLayerIds?: string[];
  onClick?: (e: mapboxgl.MapLayerMouseEvent) => void;
  style?: React.CSSProperties;
  styleSelectors?: boolean;
  overrideMapStyle?: SIMapStyleType | null;
}

const fullMapStyle: React.CSSProperties = {
  position: 'absolute',
  width: '100%',
  height: 'auto',
  bottom: 0,
  right: 0,
  left: 0,
  top: '48px', // 48px is the right space to appear right below the second level navigation
};

// @ts-expect-error error leftover from convertion to strict mode, please fix
mapboxgl.workerClass = MapboxWorker;

export const SIBaseMap = ({
  children,
  mapRef,
  interactiveLayerIds,
  onClick,
  style = fullMapStyle,
  styleSelectors = true,
  overrideMapStyle = null,
}: BaseMapProps) => {
  const dispatch = useAppDispatch();

  const mapStyle = useAppSelector(selectMapStyle);
  const initialView = useAppSelector(selectMapInitialView);

  const handleMapStyleSwitch = (selectedMapStyle: SIMapStyleType) => {
    dispatch(switchMapStyle(selectedMapStyle));
  };

  return (
    <Map
      ref={mapRef}
      initialViewState={initialView}
      mapboxAccessToken={
        'pk.eyJ1IjoiZ3JlYXRvem1lbiIsImEiOiJjbDc4NTB5am8wNWFlM3Bxcjc1Mnd0OW15In0.pnN6uS4xwXMVMqz81gRPcg'
      }
      style={style}
      mapStyle={overrideMapStyle ? mapboxMapStyle[overrideMapStyle] : mapStyle}
      hash={true}
      interactiveLayerIds={interactiveLayerIds}
      onClick={onClick}
    >
      {children};
      {styleSelectors && (
        <div className="si-map-style-selectors">
          <FluroButton
            flat
            blank
            primary
            onClick={() => handleMapStyleSwitch('road')}
            value={'road'}
            className="margin-right-4"
          >
            Road
          </FluroButton>
          <FluroButton
            flat
            blank
            primary
            onClick={() => handleMapStyleSwitch('satellite')}
            value={'satellite'}
          >
            Satellite
          </FluroButton>
        </div>
      )}
    </Map>
  );
};
