import React, {useMemo, useState} from 'react';
import {Text} from 'components';
import {InputWrapper} from './fluro-input.styled';
import cn from 'classnames';
import {genKey} from '_utils/utils2';
import type {TextFieldProps} from 'react-md';
import {FontIcon} from 'react-md';

/**
 * TODO refactor to use proper generic types.
 */
export type FluroInputProps = {
  value?: string | number;
  onChange?: (value: string) => void;
  type?: TextFieldProps['type'];
  label?: string;
  placeholder?: string;
  error?: boolean;
  errorText?: string;
  helpText?: string;
  name?: string;
  id?: string;
  testId?: string;
  className?: string;
  defaultValue?: string | number;
  style?: React.CSSProperties;
  disabled?: boolean;
  onClick?: (ev: React.MouseEvent<HTMLElement>) => void;
  topMargin?: boolean;
  passwordIcon?: TextFieldProps['passwordIcon'];
};

export const FluroInput = ({
  className,
  error = false,
  label: placeholder,
  placeholder: placeholderText,
  type = 'text',
  value,
  onChange,
  errorText,
  helpText,
  testId,
  name,
  id,
  defaultValue,
  style,
  disabled,
  onClick,
  topMargin = true,
  passwordIcon,
  ..._rest
}: FluroInputProps) => {
  const rnd = useMemo(() => genKey(), []);
  const _id = id || `input-${name}-${rnd}`;
  const [_value, setValue] = useState<string | number | undefined>(value);
  const [_type, setType] = useState<TextFieldProps['type']>(type);

  const onchangePasswordVisibility = () => {
    setType(_type === 'text' ? 'password' : 'text');
  };

  const _passwordIcon = useMemo(() => {
    if (passwordIcon === null) return null;

    if (type === 'password') {
      return (
        <div className="pass-btn" onClick={onchangePasswordVisibility}>
          {_type === 'text' ? <FontIcon>visibility</FontIcon> : <FontIcon>visibility_off</FontIcon>}
        </div>
      );
    }

    return null;
  }, [passwordIcon, _type]);

  return (
    <InputWrapper
      error={error}
      className={cn(className, {'not-empty': !!_value})}
      style={style}
      topMargin={topMargin}
      isPassWithIcon={type === 'password' && passwordIcon !== null}
    >
      {placeholder ? (
        // TODO: fixme a11y
        // eslint-disable-next-line jsx-a11y/label-has-for
        <label className="label" htmlFor={_id}>
          {placeholder}
        </label>
      ) : null}
      <div className="input-container">
        <input
          onClick={onClick}
          name={name}
          type={_type}
          className="input"
          id={_id}
          disabled={disabled}
          defaultValue={defaultValue}
          placeholder={placeholderText}
          value={_value}
          data-testid={testId}
          onChange={e => {
            const v = e.target.value;

            onChange?.(v);
            setValue(v);
          }}
        />

        {_passwordIcon}
      </div>

      {helpText && !errorText ? (
        <Text variant={'small'} secondary className="md-text md-text-field-message">
          {helpText}
        </Text>
      ) : null}
      {errorText ? (
        <div className="md-text md-text-field-message md-text--error">{errorText}</div>
      ) : null}
    </InputWrapper>
  );
};
