// @ts-nocheck
import React, {useEffect, useState, useMemo} from 'react';
import {useDispatch} from 'react-redux';
import './index.scss';
import UserActivityTable from './components/user-activity-table';
import UserActivitySummary from './components/user-activity-summary';
import UserActivityControls from './components/user-activity-controls';
import {loadUserActivityList, updateSummary} from './actions';
import type {ItemUserActivity} from './types';
import {download, getCsvFromArray} from '_utils';
import {TABLE_COLUMNS} from './constants';
import {useAppSelector} from '_hooks';
import {AsyncStatusType} from 'modules/helpers';
import {AdminContainer, AdminControls} from '../admin.styled';
import AdminTitleWithLoader from '../shared/title-with-loader';

const IsEmpty = (srchParam: string) => (
  <h2 className={`user-activity__empty`}>
    <b>{srchParam}</b> - No match to any user!
  </h2>
);

const UserActivity = () => {
  const dispatch = useDispatch();
  const list = useAppSelector(store => store.userActivity.list);
  const summary = useAppSelector(store => store.userActivity.summary);
  const loaded = useAppSelector(store => store.userActivity.loaded);
  const measurement = useAppSelector(store => store.login.user.settings.measurement);

  const [tags, setTags] = useState<Array<{label: string; value: string}>>([]);

  const [searchParams, setSearchParams] = useState({type: '', value: ''});
  const activityList = useMemo(() => {
    return !searchParams.value && !tags.length
      ? list
      : list.filter(
          ({name = '', surname = '', email}: ItemUserActivity) =>
            (searchParams.value && `${name} ${surname} ${email}`.includes(searchParams.value)) ||
            tags.some(t => t.value === email)
        );
  }, [searchParams, list.length, tags.length]);

  useEffect(() => {
    !loaded && dispatch(loadUserActivityList());
  }, []);
  useEffect(() => {
    const ids = [...activityList.map(el => el.id)];
    if (ids.length) dispatch(updateSummary(ids));
  }, [searchParams.value, tags.length]);

  const handleExport = () => {
    const csv = getCsvFromArray<ItemUserActivity[]>(
      TABLE_COLUMNS.map(el => el.name),
      activityList
    );

    download('user-activity.csv', csv);
  };

  const handleSearch = (value: string, type: string) => setSearchParams(() => ({value, type}));

  const searchData = useMemo(
    () =>
      list.map(({email, name, surname}: ItemUserActivity) => ({
        label: `${name} ${surname} ${email}`,
        value: email,
      })),
    [list.length]
  );
  const isEmptyList = !activityList.length;

  return (
    <AdminContainer>
      <AdminControls>
        <AdminTitleWithLoader title={'User activity'} statusKey={AsyncStatusType.usersActivity} />

        {!loaded ? null : <UserActivitySummary measurement={measurement} data={summary} />}
      </AdminControls>
      {!loaded ? null : (
        <>
          <UserActivityControls
            data={searchData}
            onSearch={handleSearch}
            onExport={handleExport}
            isEmpty={isEmptyList}
            tags={tags}
            onSetTags={setTags}
          />
          {isEmptyList ? (
            IsEmpty(searchParams.value)
          ) : (
            <UserActivityTable measurement={measurement} list={activityList} />
          )}
        </>
      )}
    </AdminContainer>
  );
};

export default UserActivity;
