import type {PayloadAction} from '@reduxjs/toolkit';
import {createSlice} from '@reduxjs/toolkit';
import {LoginActionTypes} from '../../../login/types';
import type {SI2State} from '../types';

export const programsSlice = createSlice({
  name: 'si/programs',
  initialState: {
    list: [],
    current: null,
    processing: false,
    dataExportAgreement: false,
  } as SI2State['programs'],
  reducers: {
    loadSIProgramsList(state, action: PayloadAction<number[]>) {
      state.list = action.payload || [];
    },
    setCurrentProgram(state, action: PayloadAction<number | null>) {
      state.current = action.payload;
    },
    setProcessing(state, action: PayloadAction<boolean>) {
      state.processing = action.payload;
    },
    setDataExportAgreement(state, action: PayloadAction<boolean>) {
      state.dataExportAgreement = action.payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(LoginActionTypes.LOGOUT, state => {
      return {
        ...state,
        list: [],
      };
    });
  },
});
