import React from 'react';
import SortButton from 'components/sort-button';
import {FluroTableColumn} from 'components';

type SortableColumnProps = {
  desc: boolean;
  sortBy: string;
  children: any;
  selectedSortBy: string;
  onSort: (sortBy: string, selectedSortBy: string) => void;
  sortDisabled?: boolean;
  align?: 'center' | 'right' | 'left';
  nowrap?: boolean;
  type?: 'number' | 'date' | 'text';
};

export const SortableColumn = ({
  sortDisabled = false,
  selectedSortBy,
  children,
  onSort,
  sortBy,
  desc,
  align,
  nowrap = false,
  type,
}: SortableColumnProps) => (
  <FluroTableColumn
    onClick={sortDisabled ? undefined : () => onSort(sortBy, selectedSortBy)}
    sorted={!sortDisabled}
    nowrap={nowrap}
    type={type}
    sortIcon={
      sortDisabled ? (
        <span />
      ) : (
        <SortButton descending={desc} selected={selectedSortBy === sortBy} />
      )
    }
    sortIconBefore={false}
    className={align ? `md-text-${align}` : ''}
  >
    {children}
  </FluroTableColumn>
);
