import {Flex} from 'components/flex/flex';
import type {ReactElement} from 'react';
import React, {useEffect, useState} from 'react';
import {Menu} from './practice.styled';
import cn from 'classnames';
import {PracticeAvatar} from './practice-avatar';
import type {SharedProps} from './types';
import type {Practice} from 'containers/mrv/types';
import {FluroButton} from 'components/fluro-button/fluro-button';

type Props = SharedProps & {
  titleComponent?: ReactElement | string | number;
  className?: string;
  onClick?: (ev: React.MouseEvent<HTMLElement>) => void;
};

export const PracticeSelect = ({
  className = '',
  items,
  titleComponent,
  value = [],
  onChange,
  onClick,
}: Props) => {
  const [selectedItems, setSelected] = useState<Practice[]>([]);

  useEffect(() => {
    setSelected([...value]);
  }, [value]);

  const onSelect = (p: Practice) => {
    if (selectedItems.includes(p)) {
      setSelected(selectedItems.filter(_p => _p !== p));
      return;
    }

    setSelected([...selectedItems, p]);
  };

  const onApply = () => {
    onChange?.(selectedItems);
  };

  return (
    <Menu onClick={onClick} className={className}>
      <div>
        {titleComponent && <div className="mb-1">{titleComponent}</div>}

        <div>
          {items.map(p => (
            <Flex
              key={p}
              className={cn({active: selectedItems.includes(p)}, 'practice-item')}
              alignItems="center"
              gap="10px"
              onClick={() => onSelect(p)}
            >
              <PracticeAvatar value={p} />
              <div>{p}</div>
            </Flex>
          ))}
        </div>

        <FluroButton
          raised
          className="mt-1"
          style={{width: '100%'}}
          readonlyFullWidth
          onClick={onApply}
        >
          Apply
        </FluroButton>
      </div>
    </Menu>
  );
};
