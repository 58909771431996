import type {ComponentType} from 'react';
import React, {useState} from 'react';
import {Card, CardTitle, CardSubtitle} from 'containers/si/kpi/styled-components';
import {Flex} from 'components';
import {CircularProgress, SelectionControlGroup} from 'react-md';
import type {SIAreaUnits} from 'modules/sustainability-insights/types';
import {CardToolTip} from 'containers/map/features/sustainability-insights/filters-panel/cards/components/card-tooltip';
import type {BarChartProps} from './components/bar-chart/bar-chart';
import {BarChart} from './components/bar-chart/bar-chart';

interface Props extends Omit<BarChartProps, 'tooltip'> {
  title: string;
  subtitle: string;
  /** @param enableUnits: Enables the controls to select from percentage or number */
  enableUnitsSwitch?: boolean;
  onUnitsChange?: (v: SIAreaUnits) => void;
  tooltip?: string;
  loading?: boolean;
}

export const KPIBarChart: ComponentType<Props> = ({
  title,
  subtitle,
  enableUnitsSwitch = false,
  onUnitsChange,
  tooltip,
  loading,
  ...barChartProps
}) => {
  if (enableUnitsSwitch && !onUnitsChange)
    throw new Error(
      '<KPIBarChart>: You must provide onUnitsChange prop to control units selection'
    );
  const [selectedUnit, setSelectedUnit] = useState<SIAreaUnits>('num');

  const handleChange = (v: SIAreaUnits) => {
    setSelectedUnit(v);
    onUnitsChange && onUnitsChange(v);
  };

  return (
    <Card>
      <Flex justifyContent="space-between">
        <CardTitle>{title}</CardTitle>
        {tooltip && <CardToolTip id="crop-area-info" content={tooltip} place="bottom" />}
      </Flex>
      <Flex justifyContent="space-between" alignItems="center">
        <CardSubtitle>{subtitle}</CardSubtitle>
        {enableUnitsSwitch && (
          <SelectionControlGroup
            id="area-units-type-select"
            name="radio"
            type="radio"
            className="si-toggle-group si-toggle-group--inline select-units"
            inline
            value={selectedUnit}
            onChange={v => handleChange(v as SIAreaUnits)}
            controls={[
              {
                label: 'num (#)',
                value: 'num',
              },
              {
                label: 'pct (%)',
                value: 'pct',
              },
            ]}
          />
        )}
      </Flex>
      {loading ? (
        <CircularProgress id="kpi-bar-chart-loading" />
      ) : (
        <BarChart {...barChartProps} unit={selectedUnit} />
      )}
    </Card>
  );
};
