import {yupResolver} from '@hookform/resolvers/yup';
import {Flex, FluroButton, FluroInput, FluroSelect} from 'components';
import {CardBlock} from 'containers/admin/users/edit/user-edit.styled';
import {t} from 'i18n-utils';
import {omit, mapValues, groupBy} from 'lodash';
import {Text} from 'components/text/text';
import React, {useEffect} from 'react';
import {reportError} from 'containers/error-boundary';
import {useForm} from 'react-hook-form';
import * as Yup from 'yup';
import {useAppSelector} from '_hooks';
import {selectUserProjectIds} from '../monitoring/module/selectors';
import {showNotification} from 'components/notification/notification';
import type {MRVCustomInput} from '../types';
import {useCustomInputs} from './use-custom-inputs';
import {getTypedEntries} from '_utils/object';

type CustomInputFormData = Record<string, string>;

type CustomInputsValidation = Record<
  string,
  {
    regex?: string;
    message: string;
    label?: string;
  }
>;

enum InputNameKeyMap {
  type_ = 0,
  project_id = 1,
  program_id = 2,
  key = 3,
  id = 4,
}

enum InputNameKey {
  TYPE_ = 'type_',
  PROJECT_ID = 'project_id',
  PROGRAM_ID = 'program_id',
  KEY = 'key',
  ID = 'id',
}

const inputNameGenerator = (input: MRVCustomInput) => {
  const props: InputNameKey[] = [
    InputNameKey.TYPE_,
    InputNameKey.PROJECT_ID,
    InputNameKey.PROGRAM_ID,
    InputNameKey.KEY,
    InputNameKey.ID,
  ];
  const name = props.map(prop => input[prop]).join('_');
  return name;
};

const validationSchema = ({
  customInputsValidation,
}: {
  customInputsValidation: CustomInputsValidation;
}) => {
  // We need to override the customInputsValidation(input data) to create the validation schema, and the object is dynamic so typescript can't determine the indexes.
  Object.keys(customInputsValidation).map(key => {
    const {regex, label, message} = customInputsValidation[key];
    if (regex) {
      // @ts-expect-error error leftover from convertion to strict mode, please fix
      customInputsValidation[key] = Yup.string()
        .required(`${label} is a required field`)
        .test(key, message, value => {
          return value.match(new RegExp(regex));
        });
    } else {
      // @ts-expect-error error leftover from convertion to strict mode, please fix
      customInputsValidation[key] = Yup.string().required(`${label} is a required field`);
    }
  });
  // Sometimes the custom input won't return any data so we are asserting the type to be object.
  return Yup.object().shape(customInputsValidation as object);
};

const MrvUserCustomInputsForm = () => {
  const userProjectIds = useAppSelector(selectUserProjectIds);
  const {
    customInputs: allCustomInputs,
    isLoading,
    updateCustomInput,
  } = useCustomInputs(userProjectIds);

  const customInputsDefaultValue: Record<string, string> = {};
  const customInputsValidation: CustomInputsValidation = {};

  allCustomInputs?.forEach(input => {
    const inputName = inputNameGenerator(input);
    const {name, type_, value, config} = input;
    customInputsDefaultValue[inputName] = value || '';
    customInputsValidation[inputName] = {
      regex: type_ === 'Regex' ? config : '',
      message: 'Invalid Value',
      label: name,
    };
  });

  const groupedByProgramName = mapValues(groupBy(allCustomInputs, 'program_name'), clist =>
    clist.map(input => omit(input, 'program_name'))
  );

  const {handleSubmit, watch, register, setValue, formState} = useForm<CustomInputFormData>({
    resolver: yupResolver(
      validationSchema({
        customInputsValidation,
      })
    ),
    defaultValues: {
      ...customInputsDefaultValue,
    },
  });

  useEffect(() => {
    if (isLoading) {
      return;
    }

    allCustomInputs.forEach(input => {
      return register(`${input.type_}_${input.project_id}_${input.program_id}_${input.key}`);
    });

    for (const [key, value] of getTypedEntries(customInputsDefaultValue)) {
      setValue(key, value);
    }
  }, [isLoading, setValue, allCustomInputs, register]);

  const values = watch();

  const handleSetValue = (prop: string, value: string) => {
    setValue(prop, value, {shouldValidate: true});
  };

  const handleOnSubmit = async () => {
    Object.keys(values).forEach(key => {
      if (values[key] === undefined) {
        delete values[key];
      }
    });

    for (const property in values) {
      const props = property.split('_');
      const inputId = props[props.length - 1];

      if (values[property] !== undefined) {
        const input: MRVCustomInput = {
          type_: props[InputNameKeyMap[InputNameKey.TYPE_]] as 'Dropdown' | 'Regex',
          project_id: Number(props[InputNameKeyMap[InputNameKey.PROJECT_ID]]),
          key: props[InputNameKeyMap[InputNameKey.KEY]],
          value: values[property],
          ...(inputId === 'new'
            ? undefined
            : {
                id: inputId,
              }),
        };

        // skip if value is not changed
        if (input.value === customInputsDefaultValue[property]) {
          return;
        }

        try {
          const projectId = Number(props[1]);
          await updateCustomInput({projectId, input});
          showNotification({
            title: t({id: 'note.success', defaultMessage: 'Success'}),
            message: 'Updated',
            type: 'success',
          });
        } catch (error) {
          reportError(`Update Custom Input Error ${error}`);
        }
      }
    }
  };

  return (
    <form autoComplete={'off'} className="padding-20" onSubmit={handleSubmit(handleOnSubmit)}>
      {!!allCustomInputs.length &&
        Object.keys(groupedByProgramName)
          .sort((a, b) => a.localeCompare(b))
          .map(programName => {
            return (
              <CardBlock key={programName} className="form-row">
                <Text elementType="h2">{programName}</Text>

                {groupedByProgramName[programName].map(input => {
                  const {type_, value, config} = input;
                  const inputName = inputNameGenerator(input);

                  if (type_ === 'Regex') {
                    return (
                      <div key={inputName} style={{flexGrow: 1}}>
                        <FluroInput
                          id={inputName}
                          name={inputName}
                          value={values[inputName] || value}
                          label={input.name}
                          error={!!formState.errors[inputName]}
                          errorText={formState.errors[inputName]?.message}
                          onChange={v => handleSetValue(inputName, v)}
                        />
                      </div>
                    );
                  }

                  if (type_ === 'Dropdown') {
                    const customInputOptions: {
                      label: string;
                      value: string;
                    }[] =
                      config?.split(',')?.map(option => {
                        return {
                          label: option,
                          value: option,
                        };
                      }) || [];

                    return (
                      <div key={inputName} style={{flexGrow: 1}}>
                        <FluroSelect
                          id={inputName}
                          value={values[inputName]}
                          error={!!formState.errors[inputName]}
                          errorText={formState.errors[inputName]?.message}
                          placeholder={input.name}
                          onChange={v => handleSetValue(inputName, v)}
                          options={customInputOptions}
                        />
                      </div>
                    );
                  }
                })}
              </CardBlock>
            );
          })}

      {!!allCustomInputs.length && (
        <Flex justifyContent="flex-end" className="padding-top-20">
          <FluroButton type="submit" raised primary>
            {t({id: 'Save'})}
          </FluroButton>
        </Flex>
      )}
    </form>
  );
};

export {MrvUserCustomInputsForm};
