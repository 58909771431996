import React, {Fragment, useEffect, useState} from 'react';
import {useAppDispatch, useAppSelector} from '_hooks';
import {useParsedMatchParams} from '_hooks/use-parsed-match-params';
import {t} from 'i18n-utils';
import {Flex, FluroChip, Text} from 'components';
import {LoadingIcon} from 'containers/mrv/icons';
import {
  setCurrentProgram,
  setSISelectedCropTypes,
  setSIVisibleSubsectionIds,
} from 'containers/si/module/reducer';
import {
  selectSISupplySheds,
  selectSIAreCurrentSupplyShedsProcessing,
  selectSICurrentPracticeKPIs,
  selectSICurrentOutcomeKPIs,
  selectSIAvailableSubSectionIds,
  selectAvailableCropIDs,
} from 'containers/si/module/selectors';
import {fetchBookValues, fetchSISupplySheds} from 'containers/si/module/thunks';
import {KPIFilter} from 'containers/si/kpi/filter/kpi-filter';
import {KPIsMap} from 'containers/si/kpi/map';
import {CropYieldCard} from 'containers/si/kpi/cards/crop-yield-card';
import {FieldGHGEmissionFactorsCard} from 'containers/si/kpi/cards/field-ghg-emission-factor-card';
import {GHGEmissionCard} from 'containers/si/kpi/cards/ghg-emission-card';
import {SocSequestrationCard} from 'containers/si/kpi/cards/soc-sequestration-card';
import {AreaByCropCard} from './cards/area-by-crop-card';
import {CoverCroppingCard} from './cards/cover-cropping-card';
import {FertilizerApplicationCard} from './cards/fertilizer-application-card';
import {TillageCard} from './cards/tillage-card';
import {
  KPIContainer,
  HeaderContainer,
  StyledAGPracticesContainer,
  StyledStickyHeader,
} from './styled-components';
import {ProcessingPopup} from 'containers/si/supply-shed/supply-shed-dialogs/processing-popup';
import {FertilizerEmissionFactorCard} from './cards/fertilizer-emission-factor';
import {NetEmissionsCard} from './cards/net-emissions-card';
import {FieldNetEmissionFactorCard} from './cards/field-net-emission-factor-card';
import {LandUseLandChangeCard} from './cards/land-use-land-change-card';
import {ArticleLink} from 'components/article-link/ArticleLink';
import {LivingRootCard} from './cards/living-root-card/living-root-card';
import {CropRotationCard} from './cards/crop-rotation-card/crop-rotation-card';
import {FieldToFarmCard} from './cards/field-to-farm-emissions-factors';
import type {OutcomeKPI, PracticeKPI} from '../api/apiTypes';
import {isNil} from '_utils/typeGuards';

export const KPIDashboard = () => {
  const dispatch = useAppDispatch();
  const {programId} = useParsedMatchParams();
  const supplySheds = useAppSelector(selectSISupplySheds);
  const availableCropIds = useAppSelector(selectAvailableCropIDs);
  const practiceKPIs = useAppSelector(selectSICurrentPracticeKPIs);
  const outcomeKPIs = useAppSelector(selectSICurrentOutcomeKPIs);
  const availableSubsectionIds = useAppSelector(selectSIAvailableSubSectionIds);
  const processing = useAppSelector(selectSIAreCurrentSupplyShedsProcessing);

  const [processingPopupVisible, setProcessingPopupVisible] = useState(false);
  const [dashboardLoading, setDashboardLoading] = useState(true);
  const [availableKPIs, setAvailableKPIs] = useState<
    Partial<Record<PracticeKPI | OutcomeKPI, boolean>>
  >({});

  const [firstLoaded, setFirstLoaded] = useState(false);

  useEffect(() => {
    if (firstLoaded) return;
    if (availableCropIds.length > 0) {
      dispatch(setSISelectedCropTypes(availableCropIds)); // set all Current Program crop types as selected
      setFirstLoaded(true);
    }
  }, [dispatch, availableCropIds, firstLoaded]);

  useEffect(() => {
    if (isNil(supplySheds[programId])) {
      dispatch(fetchSISupplySheds(programId));
      setDashboardLoading(true);
    } else {
      setDashboardLoading(false);
    }
    dispatch(setCurrentProgram(programId));
  }, [dispatch, programId, supplySheds]);

  useEffect(() => {
    dispatch(setSIVisibleSubsectionIds(availableSubsectionIds));
  }, [dispatch, availableSubsectionIds]);

  useEffect(() => {
    setProcessingPopupVisible(processing);
  }, [processing]);

  useEffect(() => {
    let newAvailableKPIs = {};
    practiceKPIs.forEach(kpi => (newAvailableKPIs = {...newAvailableKPIs, [kpi]: true}));
    outcomeKPIs.forEach(kpi => (newAvailableKPIs = {...newAvailableKPIs, [kpi]: true}));
    setAvailableKPIs(newAvailableKPIs);
  }, [practiceKPIs, outcomeKPIs]);

  useEffect(() => {
    dispatch(fetchBookValues({programId}));
  }, [dispatch, programId]);

  return (
    <>
      <KPIContainer>
        <StyledStickyHeader elevateStuck={false} position="top">
          <HeaderContainer>
            <Flex className="margin-bottom-16" justifyContent="space-between">
              <Flex alignItems="baseline" gap="10px">
                <Text className="mb-0" bold variant="h1">
                  {t({id: 'Sustainability Reporting'})}
                </Text>
                {processing && (
                  <FluroChip
                    key={'processing'}
                    label={'Processing Sourcing Regions'}
                    tone={'dimmed'}
                    size={'small'}
                    className="ml-8"
                    leftIcon={<LoadingIcon />}
                    disabled
                  />
                )}
                {dashboardLoading && (
                  <FluroChip
                    key={'dashboard-loading'}
                    label={'Dashboard Loading - Please wait'}
                    tone={'dimmed'}
                    size={'small'}
                    className="ml-8"
                    leftIcon={<LoadingIcon />}
                    disabled
                  />
                )}
              </Flex>
            </Flex>

            <KPIFilter />
          </HeaderContainer>
        </StyledStickyHeader>

        <Flex gap={'10px'}>
          <KPIsMap />
          <AreaByCropCard />
        </Flex>

        {outcomeKPIs.length > 0 && (
          <Fragment>
            <Text className="section-title" variant="h2">
              <b>{t({id: 'Sustainability Outcomes'})}</b>
            </Text>
            <Text className="mb-2 section-description" variant="h3">
              {t({
                id: 'Baseline, monitor and report how regenerative adoption impacts greenhouse gas emissions, carbon sequestration (removals) and crop yields in your supply sheds. Learn more ',
              })}
              <ArticleLink content="here" articleId={6479120} />
              {t({
                id: ' about calculations and methodology for ESG reporting.',
              })}
            </Text>
          </Fragment>
        )}
        <Flex gap={'10px'}>
          {availableKPIs['ghg_emission'] && <GHGEmissionCard />}
          {availableKPIs['soc_sequestration'] && <SocSequestrationCard />}
          {availableKPIs['soc_sequestration'] && availableKPIs['ghg_emission'] && (
            <NetEmissionsCard />
          )}
          {availableKPIs['yield'] && <CropYieldCard />}
        </Flex>

        {availableKPIs['emission_factor'] && (
          <Fragment>
            <Text className="section-title" variant="h2">
              <b>{t({id: 'Emission Factors'})}</b>
            </Text>
            <Text variant="h3" className="section-description">
              {t({
                id: 'Supply shed specific emission factors to help quantify Scope 3 emissions. Learn more ',
              })}
              {<ArticleLink content="here" articleId={6662482} />}
              {t({id: ' about Regrow’s calculations and methodology.'})}
            </Text>
            <Flex gap={'10px'}>
              <FieldGHGEmissionFactorsCard />
              <FieldNetEmissionFactorCard />
              <FertilizerEmissionFactorCard />
            </Flex>
          </Fragment>
        )}

        {availableKPIs['ef_book_values'] && (
          <Flex className="ef-stacked-chart">
            <FieldToFarmCard />
          </Flex>
        )}

        {practiceKPIs.length > 0 && (
          <Fragment>
            <Text className="section-title" variant="h2">
              <b>{t({id: 'Agricultural practices'})}</b>{' '}
            </Text>
            <Text variant="h3" className="section-description">
              {t({
                id: 'Monitor trends in regenerative practice adoption and analyze supply shed specific patterns over time for management practices such as conservation tillage, cover cropping, fertilizer application, crop rotation and land-use change. Learn more ',
              })}
              {<ArticleLink content="here" articleId={6479122} />}
              {t({id: ' about Regrow’s calculations and methodology for ESG reporting.'})}
            </Text>
          </Fragment>
        )}
        <StyledAGPracticesContainer gap="10px">
          {availableKPIs['living_root'] && <LivingRootCard />}
          {availableKPIs['cover_cropping'] && <CoverCroppingCard />}
          {availableKPIs['crop_rotation'] && <CropRotationCard />}
          {availableKPIs['lulc'] && <LandUseLandChangeCard />}
          {availableKPIs['tillage'] && <TillageCard />}
          {availableKPIs['fertilizer_usage'] && <FertilizerApplicationCard />}
        </StyledAGPracticesContainer>
        {processingPopupVisible && (
          <ProcessingPopup onHide={() => setProcessingPopupVisible(false)} />
        )}
      </KPIContainer>
    </>
  );
};
