// @ts-nocheck
import {t, FormattedMessage} from 'i18n-utils';
import React, {useCallback, useMemo} from 'react';
import {useForm, Controller} from 'react-hook-form';
import Yup from 'yup';
import {TextField, Button, SelectField} from 'react-md';
import type {Field} from '../../../types';
import type {FieldPayload} from '../types';
import {fitBoundCurrentEditFieldKml} from '_utils';
import {toFixedFloatUnsafe} from '_utils/number-formatters';
import {yupResolver} from '@hookform/resolvers/yup';
import {Flex, FluroButton} from 'components';
import {toggleGlobalDialog} from 'modules/global/actions';
import {useDispatch} from 'react-redux';
import {toggleEditingMode2, toggleFieldKml, toggleMapBar} from '../../../actions';
import {useAppSelector} from '_hooks';
import {EditIcon} from '../../../icons';
import {selectIsWholeFarmView} from '../../../reducer/selectors';

type Props = {
  field: Field;
  onSubmit(data: FieldPayload): void;
  onDelete(): void;
  addPivotMarker?(values: FieldPayload): void;
};

const validationSchema = Yup.object().shape({
  Name: Yup.string()
    .min(1, t({id: 'Max length'}, {length: 1}))
    .max(50, t({id: 'Max length'}, {length: 50}))
    .required(t({id: 'Field name is required'})),
  Pivot: Yup.boolean(),
  PivotCenterCoordinates: Yup.mixed().when(['Pivot'], {
    is: true,
    then: Yup.object().required(t({id: 'Coordinates are required for a center pivot field'})),
    otherwise: Yup.mixed(),
  }),
});

const EditFieldForm = ({field, onSubmit, onDelete, addPivotMarker}: Props) => {
  const {Name = '', Pivot = false, PivotCenterCoordinates = null} = field || {};
  const dispatch = useDispatch();
  const isWholeFarmView = useAppSelector(selectIsWholeFarmView);

  const isEditable = useMemo(() => {
    return !field.Seasons?.length && !field.external_service && !isWholeFarmView;
  }, [field, isWholeFarmView]);

  const {
    handleSubmit,
    control,
    formState: {errors},
    watch,
    register,
    setValue,
  } = useForm<FieldPayload>({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      Name,
      Pivot,
      PivotCenterCoordinates,
    },
  });

  register('PivotCenterCoordinates');

  const values = watch();

  const onEditBoundary = useCallback(() => {
    dispatch(toggleMapBar(false));
    dispatch(toggleFieldKml(true));

    // async call to give chance for draw kml boundary layer on the map
    setTimeout(() => {
      fitBoundCurrentEditFieldKml();
      dispatch(toggleGlobalDialog('editField', {visible: false}));
      dispatch(toggleEditingMode2(true, field.ID));
    }, 0);
  }, []);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Controller
        name={'Name'}
        control={control}
        render={({field: {value, onChange}}) => (
          <TextField
            id="edit-farm-settings-field-name"
            placeholder={t({id: 'Field name'})}
            lineDirection="center"
            label={t({id: 'Field name'})}
            className="edit-farm-settings__input"
            type="text"
            value={value}
            onChange={onChange}
            error={!!errors?.Name?.message}
            errorText={errors?.Name?.message}
          />
        )}
      />
      <div className={'field-pivot-props'}>
        <Controller
          name={'Pivot'}
          control={control}
          render={({field: {onChange, value}}) => (
            <SelectField
              id="field-centre-pivot"
              className="pivot-field-select"
              value={value ? 1 : 0}
              onChange={value => {
                if (!value) setValue('PivotCenterCoordinates', null);
                onChange(value);
              }}
              defaultValue={Pivot ? 1 : 0}
              label={t({id: 'Pivot field'})}
              menuItems={[
                {label: <FormattedMessage id="Yes" />, value: 1},
                {label: <FormattedMessage id="No" />, value: 0},
              ]}
              simplifiedMenu={true}
            />
          )}
        />

        {values.Pivot ? (
          <div className="pivot-coordinates">
            <TextField
              id="pivot-coordinates"
              label={t({id: 'Field center'})}
              ref={r => {
                //@ts-expect-error error leftover from convertion to strict mode, please fix
                if (r && r._container) {
                  //@ts-expect-error error leftover from convertion to strict mode, please fix
                  r._container.querySelector('input').disabled = true;
                }
              }}
              rightIcon={
                <Button
                  disabled={!values.Pivot}
                  onClick={() => addPivotMarker(values)}
                  icon
                  iconEl={<EditIcon />}
                />
              }
              defaultValue={`${t({id: 'Lat'})}: ${toFixedFloatUnsafe(
                values.PivotCenterCoordinates?.Lat,
                6
              )} ${t({id: 'Long'})}: ${toFixedFloatUnsafe(values.PivotCenterCoordinates?.Lon, 6)}`}
              error={!!(values.Pivot && errors.PivotCenterCoordinates)}
              //@ts-expect-error error leftover from convertion to strict mode, please fix
              errorText={errors.PivotCenterCoordinates?.message}
            />
          </div>
        ) : null}
      </div>

      <Flex justifyContent="space-between">
        <FluroButton onClick={onDelete} raised>
          {t({id: 'Delete field'})}
        </FluroButton>
        {/* hide disabled button regarding to the comment https://regrow.atlassian.net/browse/OPS-884?focusedCommentId=35555 */}
        {!isWholeFarmView && (
          <FluroButton flat disabled={!isEditable} onClick={onEditBoundary}>
            {t({id: 'Edit boundary'})}
          </FluroButton>
        )}

        <FluroButton type="submit" raised primary id="edit-farm-settings-save-button">
          {t({id: 'Save changes'})}
        </FluroButton>
      </Flex>
    </form>
  );
};

export default EditFieldForm;
