import React, {useState} from 'react';
import {FontIcon} from 'react-md';
import {Flex, Text} from 'components';
import {Circle} from 'components/shapes/circle';
import {FluroCheckbox} from 'components/fluro-checkbox/fluro-checkbox';
import {useAppSelector} from '_hooks';
import {classifyAreaWithUnits} from '_utils';
import {selectMeasurement} from 'containers/login/login-selectors';
import {selectCurrentProgramSupplyShedAndSubsectionIds} from 'containers/si/module/selectors';
import type {SISupplyShed} from 'containers/si/types';
import {SI_COLORS} from 'containers/si/colors';
import '../create-report.scss';

export const AreasDropdown = ({
  supplyShed,
  selectedAreas,
  selectedSubsections,
  handleSupplyShedCheckboxChange,
  handleSubsectionCheckboxChange,
}: {
  supplyShed: SISupplyShed;
  selectedAreas: number[];
  selectedSubsections: number[];
  handleSupplyShedCheckboxChange: (supplyShedId: number) => void;
  handleSubsectionCheckboxChange: (supplyShedId: number, subsectionId: number) => void;
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const supplyShedAndSubsectionIds = useAppSelector(selectCurrentProgramSupplyShedAndSubsectionIds);

  const measurement = useAppSelector(selectMeasurement);

  const isSelected = selectedAreas.includes(supplyShed.id);

  const filteredSelectedSubsections = selectedSubsections.filter(r =>
    supplyShedAndSubsectionIds[supplyShed.id].includes(r)
  );

  return (
    <>
      <Flex
        key={supplyShed.id}
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        nowrap
        fullWidth
      >
        <Flex direction="row" alignItems="center" nowrap>
          <FluroCheckbox
            value={isSelected}
            onChange={() => handleSupplyShedCheckboxChange(supplyShed.id)}
            halfChecked={
              supplyShedAndSubsectionIds[supplyShed.id].length !==
                filteredSelectedSubsections.length && filteredSelectedSubsections.length !== 0
            }
          />
          <div onClick={() => setIsOpen(!isOpen)}>
            <FontIcon forceSize={24} forceFontSize>
              {isOpen ? 'expand_more' : 'chevron_right'}
            </FontIcon>
          </div>
          <Circle
            inline
            size={13}
            color={supplyShed.color || SI_COLORS[0]}
            className="area-circle"
          />{' '}
          <Text inline bold={isSelected} className={'ml-1'}>
            {supplyShed.name}
          </Text>
        </Flex>
        <div>
          <Text bold={isSelected}>
            {classifyAreaWithUnits(Math.round(supplyShed.total_field_area_ha || 0), measurement)}
          </Text>
        </div>
      </Flex>
      {isOpen && (
        <Flex className="margin-left-24 subsection-dropdown" direction="column">
          <Flex direction="column">
            {supplyShed.subsections.map(sub => {
              const isSubSelected = selectedSubsections.includes(sub.id);

              return (
                <Flex
                  key={sub.id}
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  nowrap
                  fullWidth
                >
                  <Flex direction="row" alignItems="center" nowrap>
                    <FluroCheckbox
                      value={isSubSelected}
                      onChange={() => handleSubsectionCheckboxChange(supplyShed.id, sub.id)}
                    />
                    <Circle
                      inline
                      size={13}
                      color={supplyShed.color || SI_COLORS[0]}
                      className="area-circle"
                    />
                    <Text inline bold={isSubSelected} className={'ml-1'}>
                      {sub.name}
                    </Text>
                  </Flex>
                  <div>
                    <Text bold={isSubSelected} nowrap>
                      {classifyAreaWithUnits(Math.round(sub.field_area_ha || 0), measurement)}
                    </Text>
                  </div>
                </Flex>
              );
            })}
          </Flex>
        </Flex>
      )}
    </>
  );
};
