import {getOwnPropertyNames} from './object';

type ErrorWithMessage = {
  message: string;
};

function isErrorWithMessage(error: unknown): error is ErrorWithMessage {
  return (
    typeof error === 'object' &&
    error !== null &&
    'message' in error &&
    typeof (error as Record<string, unknown>).message === 'string'
  );
}

function toErrorWithMessage(error: unknown): ErrorWithMessage {
  if (isErrorWithMessage(error)) {
    return error;
  }

  try {
    return new Error(JSON.stringify(error));
  } catch {
    // Fallback in case there's an error stringifying
    // like with circular references.
    return new Error(String(error));
  }
}

/**
 * @returns The error.message from an unknown error caught in a catch block.
 *
 * Falls back to stringify and toString if the object doesn't have an error.
 */
export function getErrorMessage(error: unknown): string {
  return toErrorWithMessage(error).message;
}

export function stringifyError(error: unknown): string {
  return JSON.stringify(error, replaceErrors);
}

function replaceErrors(key: string, value: unknown) {
  if (value instanceof Error) {
    const error: Record<string, unknown> = {};

    getOwnPropertyNames(value).forEach(name => {
      error[name] = value[name];
    });

    return error;
  }

  return value;
}
