import {t} from 'i18n-utils';
import type {ReactElement} from 'react';
import React, {useCallback} from 'react';
import {ArrowInGearIcon, DrawMarkerIcon, UploadArrowInCircleIcon} from '../../../../icons';
import {dialogToggle, DialogType} from 'modules/helpers';
import type {AddFieldStep} from 'modules/add-fields/types';
import {toggleMapBar} from 'containers/map/actions';
import {reportError} from 'containers/error-boundary';
import {useAppDispatch, useAppSelector} from '_hooks';
import cn from 'classnames';
import {selectHasFarmsOrGroupsIds} from 'containers/login/login-selectors';
import {selectCurrentProgram} from 'containers/mrv/monitoring/module/selectors';
import {useWorkspace} from '_hooks/use-workspace';

type Props = {
  changeCurrentStep: (step: AddFieldStep) => void;
};

const AddFieldsStep = ({changeCurrentStep}: Props) => {
  const {isWorkspaceMrv} = useWorkspace();
  const dispatch = useAppDispatch();
  const hasFarms = useAppSelector(selectHasFarmsOrGroupsIds);

  const program = useAppSelector(selectCurrentProgram);
  const onDrawFields = useCallback(() => {
    const locationButton: HTMLElement | null = document.querySelector('.location-button');
    if (!locationButton) {
      reportError('Tried to draw fields without the location button on the map');
      return;
    }
    locationButton.click();
    const searchLocation: HTMLElement | null = document.querySelector('#search-on-map-btn');
    searchLocation && searchLocation.click();
    changeCurrentStep('search-location');
    dispatch(toggleMapBar(false));
  }, []);

  const onUploadFields = useCallback(() => {
    changeCurrentStep('select-files-to-upload');
    dispatch(toggleMapBar(false));
  }, []);

  const onImportFields = useCallback(() => {
    dispatch(dialogToggle(DialogType.addNewField, false));

    // made async call to await toggleMapBar(true) after close 'add fields dialog' and then close map bar again
    setTimeout(() => {
      dispatch(toggleMapBar(false));
      dispatch(dialogToggle(DialogType.integrationPlatforms, true));
    }, 0);
  }, []);

  const onOptionSelected = () => {
    if (!hasFarms) {
      dispatch(dialogToggle(DialogType.addNewField, true)); // because the component is not in the dialog
    }
  };

  return (
    <div className={'add-new-fields-container'}>
      {(!isWorkspaceMrv || (isWorkspaceMrv && !!program?.fms_options?.length)) && (
        <Option
          title={t({
            id: 'importAFieldFromFarmManagementSystems',
            defaultMessage: 'Import from farm management system',
          })}
          subtitle={'MyJohnDeere, Climate, EFC, ...'}
          iconClassName={'import'}
          icon={<ArrowInGearIcon />}
          onClick={onImportFields}
          hasFarms={hasFarms}
        />
      )}
      <Option
        title={t({id: 'uploadBoundary', defaultMessage: 'Upload boundaries'})}
        subtitle={'.KML or SHP'}
        iconClassName={'upload'}
        icon={<UploadArrowInCircleIcon />}
        onClick={() => {
          onOptionSelected();
          onUploadFields();
        }}
        hasFarms={hasFarms}
      />
      <Option
        title={t({id: 'selectOrDrawAField', defaultMessage: 'Select or draw fields on the map'})}
        iconClassName={'draw'}
        icon={<DrawMarkerIcon />}
        onClick={() => {
          onOptionSelected();
          onDrawFields();
        }}
        hasFarms={hasFarms}
      />
    </div>
  );
};

type OptionProps = {
  title: string;
  subtitle?: string;
  icon: ReactElement;
  iconClassName: 'draw' | 'upload' | 'import';
  onClick: () => void;
  hasFarms: boolean;
};

const Option = ({title, subtitle, icon, iconClassName, onClick, hasFarms}: OptionProps) => {
  return (
    <div
      onClick={onClick}
      className={cn('global-bordered-container add-fields-option', {
        [iconClassName]: true,
        farmless: !hasFarms,
      })}
    >
      <div className={`icon-container ${iconClassName}`}>{icon}</div>
      <div className="text-container">
        <div className="title">{title}</div>
        {subtitle && <div className="subtitle">{subtitle}</div>}
      </div>
    </div>
  );
};

export default AddFieldsStep;
