// @ts-nocheck
import {t} from 'i18n-utils';
import React from 'react';
import {FluroButton, InfoBlock, UploadFileZone} from 'components';
import {uploadFieldsFiles} from 'modules/add-fields';
import {useAppSelector} from '_hooks';
import {AsyncStatusType, Status} from 'modules/helpers';
import {useDispatch} from 'react-redux';
import type {AddFieldStep} from 'modules/add-fields/types';
import {selectCoordinateSystem} from 'containers/mrv/monitoring/module/selectors';

type Props = {
  changeCurrentStep(step: AddFieldStep): void;
};
const UploadStep = ({changeCurrentStep}: Props) => {
  const dispatch = useDispatch();
  const asyncStatuses = useAppSelector(s => s.helpers.asyncStatuses);
  const isParsing = asyncStatuses[AsyncStatusType.parseUploadedFields].status === Status.Pending;
  const isUploading =
    asyncStatuses[AsyncStatusType.uploadingFieldsToBackend].status === Status.Pending;

  const coordinateSystem = useAppSelector(selectCoordinateSystem);

  const onSetFields = (files: File[]) => {
    dispatch(uploadFieldsFiles(files, coordinateSystem));
  };

  return (
    <>
      <div className={'add-new-fields-container'}>
        <UploadFileZone
          id={'map-upload-kml'}
          accept={'.kml,.kmz,.zip'}
          multiple
          onChange={onSetFields}
          disabled={isParsing || isUploading}
          isUploading={isParsing}
        />
      </div>

      <InfoBlock className={'inside-a-pop-up'}>
        <div>{t({id: 'You can upload .kml, .kmz or .shp files.'})}</div>
        <div>
          {t({
            id: 'If are uploading .shp files, zip archive them before sending and make sure the archive contains the following files extensions: .shp, .shx and .dbf.',
          })}
        </div>
      </InfoBlock>

      <div className="new-fields-nav-container">
        <FluroButton
          className={'control-btn back'}
          raised
          blank
          noPadding
          onClick={() => changeCurrentStep('add-fields')}
        >
          {t({id: 'BtnLabel.Back'})}
        </FluroButton>
      </div>
    </>
  );
};

export default UploadStep;
