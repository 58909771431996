import React, {useContext} from 'react';
import {Popover} from 'components/popover';
import type {ValidationError, ValidationWarning} from './hacky-validation';
import {Flex, FluroButton} from 'components';
import {getLocalStorage} from '_hooks/use-storage';
import {ValidationContext} from 'containers/mrv/data/ValidationContext';

export const CellStatusPopover = ({
  popoverId,
  error,
  warning,
}: {
  popoverId: string;
  error?: ValidationError;
  warning?: ValidationWarning;
}) => {
  const {setIgnoredSimilarWarnings, setIgnoredWarnings} = useContext(ValidationContext);
  return (
    <Popover targetId={popoverId} placement="right">
      {error && <>{error.message}</>}
      {warning && (
        <>
          {warning.message}
          <Flex fullWidth justifyContent="space-between" style={{marginTop: '16px'}}>
            <FluroButton
              onClick={() => {
                const ignoredWarnings = getLocalStorage('ignoredWarnings') || [];
                setIgnoredWarnings([...ignoredWarnings, warning]);
              }}
            >
              Ignore
            </FluroButton>
            <FluroButton
              onClick={() => {
                const ignoredSimilarWarnings = getLocalStorage('ignoredSimilarWarnings') || [];
                setIgnoredSimilarWarnings([...ignoredSimilarWarnings, warning]);
              }}
            >
              Suppress similar warnings
            </FluroButton>
          </Flex>
        </>
      )}
    </Popover>
  );
};
