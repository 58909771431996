import {useAppDispatch, useAppSelector} from '_hooks';
import {useParsedMatchParams} from '_hooks/use-parsed-match-params';
import {isDefined, isNil} from '_utils/typeGuards';
import {generateFeatureCollection} from 'containers/si/module/base';
import {selectSIProgramGeometries} from 'containers/si/module/selectors';
import {fetchProgramGeometries} from 'containers/si/module/thunks';
import {ActionType} from 'containers/si/module/types';
import type {FeatureCollection} from 'geojson';
import {selectIsLoading} from 'modules/helpers/selectors';
import React from 'react';

export const useFetchGeometries = (mapType: 'sub-section' | 'supply-shed') => {
  const dispatch = useAppDispatch();

  const {programId} = useParsedMatchParams<{programId?: number}>();
  const programGeometries = useAppSelector(selectSIProgramGeometries);
  const isLoading = useAppSelector(s =>
    selectIsLoading(s, [ActionType.FETCH_SI_PROGRAM_GEOMETRIES])
  );

  const [allGeometryFeatures, setAllGeometryFeatures] = React.useState<FeatureCollection | null>(
    null
  );

  const currentProgramGeometries = React.useMemo(
    () => (isDefined(programId) ? programGeometries[programId] ?? [] : []),
    [programGeometries, programId]
  );

  React.useEffect(() => {
    if (isDefined(programId) && isNil(programGeometries[programId]) && !isLoading) {
      dispatch(fetchProgramGeometries(programId));
    }
  }, [programId, dispatch, programGeometries, isLoading]);

  React.useEffect(() => {
    // Generate geometry features to display ALL Supply Sheds/subsections on the map
    const allGeneratedGeometryFeatures = generateFeatureCollection({
      geometries: currentProgramGeometries,
      type: mapType,
    });
    setAllGeometryFeatures(allGeneratedGeometryFeatures);
  }, [currentProgramGeometries, mapType]);

  return {isLoading, currentProgramGeometries, allGeometryFeatures};
};
