import {useLeafletContext} from '@react-leaflet/core';
import type {ComponentType} from 'react';
import React, {useEffect} from 'react';
import type {TileLayerProps} from 'react-leaflet';
import {MapContainer, TileLayer} from 'react-leaflet';
import ReactLeafletGoogleLayer from 'react-leaflet-google-layer';
import './hacks/increase-tiles-by-1-px';
import type {TLayersSource} from './types';

export const access_token =
  'pk.eyJ1IjoiZ3JlYXRvem1lbiIsImEiOiJjaXNqemZkZ2cwMDNtMnNuNTI4bnY2YXR5In0.uqqfcJnOLENaCge8RTlGJA';
export const MAP_TILE_URL = `https://api.mapbox.com/styles/v1/mapbox/{id}/tiles/{z}/{x}/{y}?access_token=${access_token}`;

type Props = {
  trackResize: boolean;
  onRefMap?: (node: any) => void;
  layersSource: TLayersSource;
  className?: string;
  hasFarms?: boolean;
  eventHandlers?: TileLayerProps['eventHandlers'];
  preferCanvas?: boolean;
};

const MapHack: ComponentType<{layersSource: TLayersSource}> = ({layersSource}) => {
  const leafletElement = useLeafletContext().map;
  useEffect(() => {
    if (!leafletElement) return;
    if (layersSource === 'google') {
      // dirty huck to resolve the FSB-3299,3280 (google map needs to be zoomed to display the tiles)
      setTimeout(() => leafletElement.fire('zoomend'), 1000);
    }
  }, [layersSource, leafletElement]);
  return null;
};

export const TiledMap: ComponentType<Props> = ({
  children,
  layersSource,
  trackResize,
  onRefMap,
  className = '',
  hasFarms = false,
  eventHandlers,
  preferCanvas = false,
}) => {
  return (
    <MapContainer
      zoomControl={false}
      trackResize={trackResize}
      cursor={true}
      id="fluro-map"
      editable={true}
      whenCreated={onRefMap}
      className={`map-v2__main ${className}`}
      center={[0, 0]}
      tap={false} // fixes issue with the drawing on the touch devices, it just works...
      keyboard={false}
      zoom={hasFarms ? 14 : 3}
      // zoomDelta - https://regrow.atlassian.net/browse/FSB-5047
      zoomDelta={1.1}
      zoomSnap={0}
      preferCanvas={preferCanvas}
    >
      <MapHack layersSource={layersSource} />
      {layersSource === 'google' ? (
        <ReactLeafletGoogleLayer
          apiKey="AIzaSyAvzAOadSua8fIzgBHaXlCht80zt42lP9I"
          type={'satellite'}
        />
      ) : (
        <TileLayer
          attribution="Regrow"
          id="satellite-streets-v9"
          url={MAP_TILE_URL}
          maxZoom={20}
          subdomains={['otile1', 'otile2', 'otile3', 'otile4']}
          minZoom={1.5}
          eventHandlers={eventHandlers}
        />
      )}
      {children}
    </MapContainer>
  );
};
