import {
  FluroTableColumn as Column,
  FluroTableRow as Row,
} from 'components/fluro-table-components/fluro-table-components';
import type {FieldValuesRow, MRVPhaseType} from 'containers/mrv/types';
import React from 'react';
import {Checkbox} from 'react-md';
import {useAppDispatch, useAppSelector} from '_hooks';
import {Text} from 'components';
import {Flex} from 'components/flex';
import {selectMeasurement} from 'containers/login/login-selectors';
import {selectFieldById} from '../../module/selectors';
import {EditFieldButton} from '../edit-field-dialog/edit-field-button';

import {MRVFieldPreview} from '../mrv-table/mrv-field-preview';
import {convertUnit, formatLocaleNumber, formatUnit} from '_utils';
import {selectHighlightedFieldId} from 'containers/map/reducer/selectors';
import {highlightField} from 'containers/map/actions';
import cn from 'classnames';
import {getEntityId} from 'containers/mrv/value-utils';

export const MRVPanelRow = ({
  phaseType,
  row,
  readOnly,
  isSelected,
  onSelect,
}: {
  phaseType: MRVPhaseType;
  row: FieldValuesRow;
  readOnly?: boolean;
  isSelected: boolean;
  onSelect: (fieldId: number, rowId: number, isSelected: boolean) => void;
}) => {
  const dispatch = useAppDispatch();
  const {row_id: rowId} = row;
  const entityId = getEntityId(row);
  const field = useAppSelector(s => selectFieldById(s, entityId));
  const handleCheckbox = (value: boolean) => onSelect(entityId, rowId, value);
  const measurement = useAppSelector(selectMeasurement);
  const farmName = field?.core_attributes?.farm_name;
  const fieldName = field?.core_attributes?.field_name;
  const fieldArea = field?.core_attributes?.field_area;
  const highlightFieldRow = useAppSelector(selectHighlightedFieldId) === entityId;

  const highlightTheField = () => {
    dispatch(highlightField(entityId));
  };

  return (
    <Row
      onClick={highlightTheField}
      className={cn({'field-map-row': true, highlighted: highlightFieldRow})}
    >
      <Column className={'mrv-form__checkbox-column'}>
        <Checkbox
          id={`field-${entityId}-${rowId}`}
          name={`field-${entityId}-${rowId}`}
          onChange={handleCheckbox}
          disabled={readOnly}
          checked={isSelected}
          aria-label="select field"
        />
      </Column>
      <Column className={'mrv-form__field-preview-name-column'}>
        <Flex gap={'10px'} nowrap alignItems={'center'}>
          <MRVFieldPreview geometry={field?.core_attributes?.geometry} />
          <div>
            <Text className="field-name" elementType="div" variant="medium" title={fieldName}>
              {fieldName}
            </Text>

            <Text className="farm-name" elementType="div" variant="small" title={farmName}>
              {farmName}
            </Text>
          </div>
        </Flex>
      </Column>
      <Column nowrap type={'number'}>
        <Text secondary elementType={'small'}>
          {formatLocaleNumber(convertUnit(measurement, 'ac', fieldArea))}
          {formatUnit(measurement, 'ha')}
        </Text>
      </Column>
      <Column>
        <EditFieldButton
          phaseType={phaseType}
          disabled={readOnly}
          entityId={entityId}
          rowId={rowId}
        />
      </Column>
    </Row>
  );
};
