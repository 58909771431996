import {yupResolver} from '@hookform/resolvers/yup';
import {Flex} from 'components/flex/flex';
import {Text} from 'components/text/text';
import {reportError} from 'containers/error-boundary';
import {t} from 'i18n-utils';
import type {ComponentType} from 'react';
import React from 'react';
import {Controller, useForm} from 'react-hook-form';
import {Button, TextField} from 'react-md';
import Yup from 'yup';
import {equalTo} from '_utils';
import {updateUserPassword} from '../login/actions';
import {useAppDispatch} from '_hooks';
import {validatePasswordSchema} from '_utils/validate-password';
import {InputPassword} from 'components/input-password';

type Props = {};
type FormValues = {
  password: string;
  confirmPassword: string;
};

Yup.addMethod(Yup.string, 'equalTo', equalTo);
const validationSchema = Yup.object().shape({
  password: validatePasswordSchema('password'),
  // @ts-expect-error error leftover from convertion to strict mode, please fix
  confirmPassword: Yup.string().equalTo(
    Yup.ref('password'),
    t({id: 'confirm new password must be the same as new password'})
  ),
});

export const ProfileSecurity: ComponentType<Props> = () => {
  const dispatch = useAppDispatch();

  const defaultValues: FormValues = {
    password: '',
    confirmPassword: '',
  };

  const {handleSubmit, control, reset} = useForm<any>({
    mode: 'onChange',
    resolver: yupResolver(validationSchema),
    defaultValues,
  });

  const submitSecurityForm = async (values: FormValues) => {
    try {
      await dispatch(updateUserPassword(values));
      reset();
    } catch (error) {
      reportError(error);
    }
  };

  return (
    <form onSubmit={handleSubmit(submitSecurityForm)}>
      <Text elementType="h2">
        {t({id: 'ResetPasswordTitle', defaultMessage: 'Reset password'})}
      </Text>

      <Controller
        control={control}
        name="password"
        render={({field, fieldState: {isTouched, isDirty, error}}) => {
          return (
            <InputPassword
              id="reset-password"
              label={t({id: 'New Password'})}
              className="sign-up-form-control"
              {...field}
              error={!!(isTouched && error?.message)}
              errorText={error?.message}
              isDirty={isDirty}
              autoComplete="off"
            />
          );
        }}
      />

      <Controller
        name="confirmPassword"
        control={control}
        render={({field, fieldState: {isTouched, error}}) => (
          <TextField
            id="rest-confirm-password"
            label={t({id: 'Confirm New Password'})}
            type="password"
            lineDirection="center"
            className="sign-up-form-control"
            passwordIcon={undefined}
            error={!!(isTouched && error?.message)}
            errorText={error?.message}
            {...field}
          />
        )}
      />

      <Flex justifyContent="flex-end" className=" padding-top-20">
        <Button raised primary type="submit">
          {t({id: 'Reset'})}
        </Button>
      </Flex>
    </form>
  );
};
