import {Flex, FluroButton, FluroDialog, Text} from 'components';
import {TextField} from 'react-md';
import React, {useState} from 'react';

export const SISupplyShedNamePopup = ({
  title,
  name,
  onSave,
  onHide,
  portal = true,
}: {
  title: string;
  name: string;
  onSave: (name: string) => void;
  onHide: () => void;
  portal?: boolean;
}) => {
  const [newName, setNewName] = useState(name);

  return (
    <FluroDialog
      id={'si-supply-shed-name-popup'}
      title={title}
      onHide={onHide}
      visible={true}
      width={400}
      portal={portal}
    >
      <Flex direction="column">
        <Text secondary>Name</Text>
        <TextField
          type="text"
          id="si-supply-shed-name"
          name="si-supply-shed-name"
          value={newName}
          className="mt-0"
          onChange={p => setNewName(String(p))}
        />
      </Flex>
      <Flex justifyContent="space-between" className="mt-2">
        <FluroButton raised blank onClick={onHide}>
          Cancel
        </FluroButton>
        <FluroButton raised primary onClick={() => onSave(newName)}>
          Save
        </FluroButton>
      </Flex>
    </FluroDialog>
  );
};
