import {t} from 'i18n-utils';
import cn from 'classnames';
import IntegrationCard from './helpers/integration-card';
import {ExternalService} from 'containers/map/types';
import {useAppDispatch, useAppSelector} from '_hooks';
import type {ComponentType} from 'react';
import React, {useEffect, useMemo, useState} from 'react';
import {RequestStatus} from 'types';
import {FarmlogsApi} from '_api';
import {showNotification} from 'components/notification/notification';
import {sortByStringKey} from 'containers/profile/integration/helpers/utils';
import {toggle} from '_utils/pure-utils';
import {CircularProgress, SelectionControl} from 'react-md';
import {Flex} from 'components/flex/flex';
import {selectIntegrationPlatform} from './integration-selectors';
import type {IntegrationComponentProps} from './types';
import {SelectedPlatformBackButton} from './helpers/selected-platform-back-btn';
import {FluroButton, Sticky, Ln} from 'components';
import {SelectFarmsAndFieldsToImportRow} from './helpers/select-farms-fields-to-import-row';
import {importFarmlogsFields} from 'containers/profile/integration/actions';
import {reportError} from 'containers/error-boundary';

const IntegrationFarmlogs: ComponentType<IntegrationComponentProps> = ({expanded}) => {
  const platform = useAppSelector(s => selectIntegrationPlatform(s, ExternalService.FarmLogs));
  const authorized = Boolean(platform?.authorized);
  const dispatch = useAppDispatch();

  const [loading, setLoading] = useState(false);

  const [fields, setFields] = useState<{id: number; name: string}[]>([]);
  const [selectedFields, setSelectedFields] = useState<number[]>([]);

  const getFields = () => {
    if (!expanded || fields.length || loading || !authorized) return;

    setLoading(true);
    FarmlogsApi.getFields()
      .then(({data}) => {
        setFields(sortByStringKey(data.data, 'name'));
        setLoading(false);
      })
      .catch(e => {
        reportError(`Error getting FarmLogs fields err=${e}`);
        setLoading(false);
        showNotification({
          title: t({id: 'note.error', defaultMessage: 'Error'}),
          message: "Couldn't fetch FarmLogs fields.",
          type: 'error',
        });
      });
  };

  useEffect(getFields, [expanded]);

  const toggleField = (fieldId: number) => {
    setSelectedFields(toggle(selectedFields, fieldId));
  };
  const toggleAllFields = (value: boolean) => {
    setSelectedFields(value ? fields.map(f => f.id) : []);
  };

  const onImportFields = () => {
    dispatch(importFarmlogsFields(selectedFields));
  };

  const isSyncInProgress = platform?.syncStatus === RequestStatus.Loading;
  const fieldsLoading = loading && !fields.length;
  const isReady = Boolean(authorized && !fieldsLoading);

  const authButton = useMemo(() => {
    return (
      <FluroButton
        raised
        primary
        component={Ln}
        external
        href={'https://app.farmlogs.com/#carbon_enrollment/regrow'}
      >
        {t({id: 'Connect'})}
      </FluroButton>
    );
  }, []);

  return (
    <div className={cn('integration-platform farmlogs', {selected: expanded})}>
      <IntegrationCard
        authorized={authorized}
        expanded={expanded}
        onAuthorize={null}
        platform={ExternalService.FarmLogs}
        loading={fieldsLoading}
        customAuthButton={authorized ? EmptyDiv : authButton} // use empty div to hide any button when authorized, because we don't have logout logic, yet
      />

      {expanded && (
        <>
          {isReady && (
            <>
              <SelectFarmsAndFieldsToImportRow message={t({id: 'Select fields to import.'})} />

              <div className={'entities-to-import-container'}>
                {fields.length > 1 && (
                  <SelectionControl
                    id={'select-all-farmlogs-fields'}
                    name={'select-all-farmlogs-farms'}
                    label={t(
                      {id: 'Select all fields ({count1}/{count2})'},
                      {count1: selectedFields.length, count2: fields.length}
                    )}
                    type={'checkbox'}
                    className={'select-all-checkbox'}
                    checked={selectedFields.length === fields.length}
                    onChange={v => toggleAllFields(Boolean(v))}
                  />
                )}
                {fields.map(field => {
                  const isFarmSelected = selectedFields.includes(field.id);
                  return (
                    <SelectionControl
                      key={field.id}
                      id={field.id}
                      name={'field selection'}
                      className={'highest-platform-entity'}
                      label={field.name}
                      type={'checkbox'}
                      checked={isFarmSelected}
                      onChange={() => toggleField(field.id)}
                    />
                  );
                })}
              </div>
            </>
          )}

          <Sticky className="action-buttons-holder">
            <SelectedPlatformBackButton />

            {isReady && (
              <Flex alignItems="center">
                {isSyncInProgress && (
                  <CircularProgress
                    className="margin-0 margin-right-5"
                    id="integration-farmlogs-progress"
                  />
                )}

                <FluroButton
                  raised
                  primary
                  className="md-btn--right"
                  disabled={!selectedFields.length || isSyncInProgress}
                  onClick={onImportFields}
                >
                  {isSyncInProgress ? t({id: 'Importing...'}) : t({id: 'Import'})}
                </FluroButton>
              </Flex>
            )}
          </Sticky>
        </>
      )}
    </div>
  );
};

const EmptyDiv = <div />;

export default IntegrationFarmlogs;
