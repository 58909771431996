import React from 'react';
import {
  FluroDataTable,
  FluroTableHeader,
  FluroTableRow,
  FluroTableBody,
  FluroTableColumn,
} from 'components';
import {ReportTile, ReportTileHeader} from 'containers/si/planning-report/components/ReportTile';
import {StackedHeaderText} from 'containers/si/planning-report/programPlan/components/StackedHeaderText';
import type {EstimatedOutcomes} from 'containers/si/planning-report/programPlan/types';
import {FluroNumberColumn} from 'containers/si/planning-report/programPlan/components/columns';
import {toFixedFloat} from '_utils/number-formatters';

interface PracticeAdoptionOutcomesTableProps {
  priceOfCarbon: number;
  yearlyAverageData: EstimatedOutcomes;
  lifetimeData: EstimatedOutcomes;
}

const TableRow = ({
  timeframe,
  data,
  priceOfCarbon,
}: {
  timeframe: 'avg' | 'lifetime';
  data: EstimatedOutcomes;
  priceOfCarbon: number;
}) => (
  <FluroTableRow data-test-id={timeframe === 'avg' ? 'yearly-average-row' : 'lifetime-row'}>
    <FluroTableColumn data-test-id={'timeframe'}>
      {timeframe === 'avg' ? 'Yearly average' : 'Program Lifetime'}
    </FluroTableColumn>
    <FluroNumberColumn data-test-id={'baseline'}>{data.baseline}</FluroNumberColumn>
    <FluroNumberColumn data-test-id={'reductions'}>{data.estReductions}</FluroNumberColumn>
    <FluroNumberColumn data-test-id={'removals'}>{data.estRemovals}</FluroNumberColumn>
    <FluroNumberColumn data-test-id={'net'}>{data.estNet}</FluroNumberColumn>
    <FluroNumberColumn data-test-id={'credits'}>{`$${toFixedFloat(
      data.estReductions * priceOfCarbon,
      2
    )}`}</FluroNumberColumn>
  </FluroTableRow>
);

export const PracticeAdoptionOutcomesTable = ({
  priceOfCarbon,
  yearlyAverageData,
  lifetimeData,
}: PracticeAdoptionOutcomesTableProps) => (
  <ReportTile>
    <ReportTileHeader titleText="Estimated program lifetime outcomes and annual averages" />
    <FluroDataTable data-test-id={'practice-adoption-aggregates-table'} elevated={false}>
      <FluroTableHeader>
        <FluroTableRow>
          <FluroTableColumn data-test-id={'timeframe-header-column'}>Timeframe</FluroTableColumn>
          <FluroNumberColumn>
            <StackedHeaderText headerText={'Baseline'} subText={'Metric tons CO2e'} />
          </FluroNumberColumn>
          <FluroNumberColumn data-test-id={'reductions-header-column'}>
            <StackedHeaderText headerText={'Estimated reduction'} subText={'Metric tons CO2e'} />
          </FluroNumberColumn>
          <FluroNumberColumn data-test-id={'removals-header-column'}>
            <StackedHeaderText headerText={'Estimated removal'} subText={'Metric tons CO2e'} />
          </FluroNumberColumn>
          <FluroNumberColumn data-test-id={'net-header-column'}>
            <StackedHeaderText headerText={'Estimated net'} subText={'Metric tons CO2e'} />
          </FluroNumberColumn>
          <FluroNumberColumn>Estimated credits</FluroNumberColumn>
        </FluroTableRow>
      </FluroTableHeader>
      <FluroTableBody>
        {/* Yearly Average */}
        <TableRow priceOfCarbon={priceOfCarbon} timeframe="avg" data={yearlyAverageData} />
        {/* Program Lifetime */}
        <TableRow priceOfCarbon={priceOfCarbon} timeframe="lifetime" data={lifetimeData} />
      </FluroTableBody>
    </FluroDataTable>
  </ReportTile>
);
