import {Flex, FluroButton, FluroTabs} from 'components';
import React, {useState} from 'react';
import {Link, Redirect} from 'react-router-dom';
import {useAppDispatch, useAppSelector} from '_hooks';
import {useParsedMatchParams} from '_hooks/use-parsed-match-params';
import {selectIsAdmin} from '../../login/login-selectors';
import {selectSIProgramById, selectUserIsSIProgramAdmin} from '../module/selectors';
import {updateSIProgram} from '../module/thunks';
import {SI_ADMIN, SI_HOME} from '../routes';
import {SIProgramAdmins} from './program-admins/si-program-admins';
import {SIProgramUsers} from './program-users/si-program-users';
import {SIProgramParameters} from './program-parameters/si-program-parameters';
import {PlaceholderLoader} from 'components/placeholder-loader/placeholder-loader';
import {FontIcon} from 'react-md';
import {SIInputPopup} from 'containers/si/components/si-input-popup';
import {setCurrentProgram} from 'containers/si/module/reducer';
import {SIPageTitle, SISectionContainer} from 'containers/si/si-styled-components';

type Tab = 'admins' | 'parameters' | 'users';
const tabs: {label: string; value: Tab}[] = [
  {label: 'Program Parameters', value: 'parameters'},
  {label: 'Admins', value: 'admins'},
  {label: 'Users', value: 'users'},
];

export const ProgramAdministration = () => {
  const dispatch = useAppDispatch();
  const [tab, setTab] = useState<Tab>('parameters');
  const [programNamePopupOpen, setProgramNamePopupOpen] = useState(false);
  const {programId} = useParsedMatchParams();
  const program = useAppSelector(s => selectSIProgramById(s, programId));
  const isAdmin = useAppSelector(selectIsAdmin);
  const isProgramAdmin = useAppSelector(selectUserIsSIProgramAdmin);

  const onUpdateProgramName = (name: string | number) => {
    dispatch(updateSIProgram({programId, program: {name: String(name)}}));
    setProgramNamePopupOpen(false);
  };

  if (!Boolean(isAdmin || isProgramAdmin)) {
    return <Redirect to={SI_HOME} />;
  }

  return (
    <SISectionContainer>
      <Flex alignItems="baseline">
        <SIPageTitle>
          {program?.name ? (
            `${program?.name} (#${program?.id})`
          ) : (
            <PlaceholderLoader width={200} height={26} />
          )}
        </SIPageTitle>

        <FluroButton
          icon
          iconEl={<FontIcon>edit</FontIcon>}
          onClick={() => setProgramNamePopupOpen(true)}
          className="ml-2"
        />
      </Flex>
      <FluroTabs
        tabs={tabs}
        selectedTab={tab}
        onTabClick={value => {
          setTab(value);
        }}
      />
      {tab === 'parameters' && <SIProgramParameters />}
      {tab === 'admins' && <SIProgramAdmins selectedProgramId={programId} />}
      {tab === 'users' && <SIProgramUsers selectedProgramId={programId} />}

      <FluroButton
        onClick={() => dispatch(setCurrentProgram(0))}
        component={Link}
        to={SI_ADMIN}
        iconEl={<FontIcon>keyboard_arrow_left</FontIcon>}
        className={'configure-back-btn'}
        flat
        secondary
      >
        Back
      </FluroButton>

      {programNamePopupOpen && (
        <SIInputPopup
          title="Edit Program Name"
          subtitle="Program Name"
          type="text"
          value={program?.name || ''}
          onSave={onUpdateProgramName}
          onHide={() => setProgramNamePopupOpen(false)}
        />
      )}
    </SISectionContainer>
  );
};
