import React, {useMemo, useState, memo} from 'react';
import type {ComponentType} from 'react';
import type {OptionProps, ValueContainerProps, Options} from 'react-select';
import Select, {components} from 'react-select';
import {CropAvatar} from 'components/crop-avatar';
import {useAppSelector} from '_hooks';
import {naturalSortAlphaNum} from '_utils/sorters';
import {FluroCheckbox} from 'components/fluro-checkbox/fluro-checkbox';
import {FontIcon} from 'react-md';
import {selectSICropTypeOptions} from 'containers/si/module/selectors';
import type {SICropTypeOptions} from 'containers/si/types';

type Props = {
  onChange: (selectedCropList: SICropTypeOptions[]) => void;
  availableCommodities: number[];
};

const controlStyle = {
  boxShadow: 'none',
  color: 'rgba(0, 0, 0, 0.54)',
  '&:hover': {borderColor: '#f1f1f1'},
  border: '1px solid #f1f1f1',
  borderRadius: '4px',
  fontSize: '1rem',
  padding: '3px 10px',
  height: 44,
};

const DropdownIndicator = () => <FontIcon>arrow_drop_down</FontIcon>;

// There is a bug where the select_all checkbox only works if you click the checkbox and not the row
const Option = (props: OptionProps<SICropTypeOptions, boolean>) => {
  if (props.data.value === 'select_all') {
    const allChecked = props.options.some(
      (option: any) => option.label === 'unselected' && option.options.length === 0
    );
    const allUnchecked = props.options.some(
      (option: any) => option.label === 'selected' && option.options.length === 0
    );
    const halfChecked = !allChecked && !allUnchecked;

    return (
      <components.Option {...props}>
        <FluroCheckbox
          key="checkbox-select-all"
          label="Select All"
          aria-label="Select All"
          value={allChecked}
          halfChecked={halfChecked}
          onChange={() => props.data?.handleSelectAll?.(halfChecked)}
        />
      </components.Option>
    );
  }

  return (
    <div>
      <components.Option {...props}>
        <FluroCheckbox
          key={`checkbox-${props.data.value}`}
          value={props.isSelected}
          label={
            <>
              <CropAvatar cropType={props.data.type} className="crop-type-select__avatar" />
              <span
                className="label"
                style={{
                  paddingLeft: 8,
                  paddingBottom: 0,
                }}
              >
                {props.data.label}
              </span>
            </>
          }
          onChange={() => null}
          aria-label={`select ${props.data.label}`}
        />
      </components.Option>
    </div>
  );
};

const ValueContainer: ComponentType<ValueContainerProps<SICropTypeOptions, boolean>> = ({
  children,
  ...props
}) => {
  if (props.hasValue) {
    const value = props.getValue();

    return (
      <components.ValueContainer {...props}>{value?.length} selected</components.ValueContainer>
    );
  }
  return <components.ValueContainer {...props}>{children}</components.ValueContainer>;
};

export const CropDropdown = memo(({onChange, availableCommodities}: Props) => {
  const cropsOptions = useAppSelector(selectSICropTypeOptions);

  const menuItems: SICropTypeOptions[] = useMemo(() => {
    const values = Object.values(cropsOptions).filter(crop =>
      availableCommodities.includes(Number(crop.value))
    );

    return naturalSortAlphaNum(values, 'label');
  }, [availableCommodities, cropsOptions]);

  const [optionSelected, setSelected] = useState<SICropTypeOptions[]>([]);
  const [optionUnSelected, setUnSelected] = useState(menuItems);

  const handleSelectAll = (halfChecked: boolean) => {
    if (halfChecked) {
      setSelected(menuItems);
      setUnSelected([]);
      onChange(menuItems);
    } else {
      setSelected([]);
      setUnSelected(menuItems);
      onChange([]);
    }
  };

  const groupedOptions: Options<{label: string; options: SICropTypeOptions[]}> = [
    {
      label: 'select_all',
      options: [
        {
          label: 'Select All',
          value: 'select_all',
          icon: '',
          type: '',
          color: '',
          handleSelectAll,
        },
      ],
    },
    {
      label: 'selected',
      options: optionSelected,
    },
    {
      label: 'unselected',
      options: optionUnSelected,
    },
  ];

  const handleChange = (selected: SICropTypeOptions[]) => {
    const newOptionsUnselected = menuItems.filter(item => {
      return !selected.includes(item);
    });

    setSelected(selected);
    setUnSelected(newOptionsUnselected);
    const filteredSelected = selected.filter(item => item.value !== 'select_all');
    onChange(filteredSelected);
  };

  return (
    <div className="export-drop-down">
      <div className="fluro-select-label">Select crops to export</div>
      <Select
        placeholder="Select a crop type"
        isMulti
        closeMenuOnSelect={false}
        hideSelectedOptions={false}
        value={optionSelected}
        options={groupedOptions}
        onChange={value => handleChange(value as SICropTypeOptions[])}
        styles={{
          menuPortal: base => ({...base, zIndex: 9999}),
          control: base => ({
            ...base,
            ...controlStyle,
          }),
          group: base => ({...base, borderBottom: '1px solid #E0E0E0'}),
          groupHeading: base => ({...base, display: 'none'}),
          indicatorSeparator: base => ({...base, display: 'none'}),
          option: (styles, {isDisabled, isFocused, isSelected}) => ({
            ...styles,
            backgroundColor: isDisabled
              ? undefined
              : isSelected
              ? undefined //'#e0e0e0'
              : isFocused
              ? '#F1F1F1'
              : undefined,
            color: isDisabled ? '#ccc' : isSelected ? 'black' : isFocused ? 'black' : 'black',
          }),
          placeholder: base => ({...base, fontSize: '1rem'}),
          valueContainer: base => ({...base, padding: '3px 10px 3px 0px', fontSize: '1rem'}),
        }}
        components={{DropdownIndicator, Option, ValueContainer}}
      />
    </div>
  );
});
