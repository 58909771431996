// @ts-nocheck
import {FormattedMessage, t} from 'i18n-utils';
import React, {useEffect, useMemo} from 'react';
import {useDispatch} from 'react-redux';
import {SelectField} from 'react-md';
import './index.scss';
import {setFeature, toggleTableView} from 'containers/map/actions';
import {runNutrilogicRecommendation} from 'containers/map/actions/zoning-actions';
import {updateRecommendationSettings} from 'containers/map/actions/nitrogen-recommendation-actions';
import {pointsSetGroupDate} from 'containers/map/actions/sampling-points';
import {HelpBtn, HelpModal} from '../../../../help-popup';
import {NRxRecommendationSettings} from './nrx-recommendation-settings';
import {checkSeasonStatus, getLastAvailableRecommendationDate} from 'containers/map/features/nrx';
import moment from 'moment';
import {GLOBAL_FORMAT_DATE} from '_constants';
import type {NRecommendationMethod} from '../../../types';
import URLParamsEffect from './URLParamsEffect';
import {formatDate} from '_utils';
import {setGetParamToURL} from '_utils/pure-utils';
import {useAppSelector} from '_hooks';
import {
  selectCurrentNRxSeason,
  selectNRecommendation,
} from 'containers/map/features/nrx/nrx-selectors';

import {selectCurrentSeason, selectIsNitrogenZoningTab} from '../../../reducer/selectors';
import {useNitrogenRx} from './use-nitrogen-rx';

const recommendationMethods = [
  {label: <FormattedMessage id="NutriLogic" defaultMessage="NutriLogic" />, value: 'nutrilogic'},
  {
    label: <FormattedMessage id="APSIM-based N Rx" defaultMessage="APSIM-based N Rx" />,
    value: 'apsim',
  },
];

const NRecommendation = () => {
  const dispatch = useDispatch();
  const nRecommendation = useAppSelector(selectNRecommendation);
  const isNitrogenTab = useAppSelector(selectIsNitrogenZoningTab);
  const pointsGroups = useAppSelector(s => s.map.pointsGroups);
  const currentSeason = useAppSelector(selectCurrentSeason);
  const pointsCurrentGroupDate = useAppSelector(s => s.map.pointsCurrentGroupDate);
  const nrxSeason = useAppSelector(selectCurrentNRxSeason);

  const {isNrxAllowed, isNutrilogicAllowed} = useNitrogenRx();

  useEffect(() => {
    if (
      isNitrogenTab &&
      nRecommendation.method === 'nutrilogic' &&
      currentSeason.cropType === 'cotton'
    ) {
      dispatch(runNutrilogicRecommendation());
    }
  }, []);

  useEffect(() => {
    const {method} = nRecommendation;

    if (isNitrogenTab && method === 'nutrilogic' && !isNutrilogicAllowed) {
      //reset the method if conditions changed
      changeMethod((isNrxAllowed && 'apsim') || '');
    }
    if (isNitrogenTab && method === 'apsim' && !isNrxAllowed) {
      //reset the method if conditions changed
      changeMethod((isNutrilogicAllowed && 'nutrilogic') || '');
    }

    // if (toggled && prevProps.nRecommendation.method !== 'nutrilogic' && method === 'nutrilogic') {
    //   dispatch(runNutrilogicRecommendation());
    // }
  }, []);

  const changeMethod = (value: NRecommendationMethod) => {
    dispatch(updateRecommendationSettings({method: value}));
  };

  const calculatedMethod = useMemo(() => {
    return isNutrilogicAllowed && isNrxAllowed
      ? nRecommendation.method
      : isNutrilogicAllowed
      ? 'nutrilogic'
      : isNrxAllowed
      ? 'apsim'
      : nRecommendation.method;
  }, [isNutrilogicAllowed, isNrxAllowed, nRecommendation.method]);

  const goToFarmTableView = () => {
    dispatch(setFeature('farm'));
    dispatch(toggleTableView('farm'));
  };

  const selectTSDate = (value: string) => {
    setGetParamToURL('nrx-date', moment(value, formatDate()).format('YYYY-MM-DD'));
    dispatch(pointsSetGroupDate(value));
    dispatch(runNutrilogicRecommendation());
  };

  const currentMethod = nRecommendation.method;
  const lastAvailableRecommendationDate = getLastAvailableRecommendationDate(
    nrxSeason.recommendationDates
  );
  const recommendationDate = nRecommendation.nrxRecommendationSettings.recommendation_date
    ? moment(nRecommendation.nrxRecommendationSettings.recommendation_date, GLOBAL_FORMAT_DATE)
    : moment(lastAvailableRecommendationDate, GLOBAL_FORMAT_DATE);

  const apsimModelStatus = isNrxAllowed
    ? checkSeasonStatus(nrxSeason, goToFarmTableView, true)
    : null;

  const pointsGroupsValues = Object.keys(pointsGroups)
    .map(value => ({
      label: value,
      value,
    }))
    .filter(el => el.value !== 'all' && el.value !== 'add');

  return (
    <>
      <div className={'nitrogen-zoning-container'}>
        {isNutrilogicAllowed && isNrxAllowed ? (
          <SelectField
            id="select-recommendation-method"
            placeholder={t({id: 'Select method'})}
            className="element-full-width"
            label={t({id: 'Select recommendation method'})}
            menuItems={recommendationMethods}
            value={currentMethod}
            onChange={changeMethod}
            simplifiedMenu={true}
          />
        ) : null}

        {isNutrilogicAllowed && currentMethod === 'nutrilogic' ? (
          <>
            <div className={'header-part'}>
              <HelpBtn id="nutrilogic-help" className="help-method-icon" />

              <HelpModal title="Nitrogen Recommendation" id="nutrilogic-help" width={400}>
                <div className="helper-text">
                  {t({
                    id: 'The tool uses your TSP nitrate (NO3) results to elaborate tailored N recommendation. The recommendation is extrapolated to the zone your TSP(s) belong too. When exporting you recommendations, remember to adapt the N values according to the type of fertiliser you are using.',
                  })}
                </div>
              </HelpModal>
            </div>
            <SelectField
              id="map-tissue-sampling-date"
              placeholder={t({id: 'Tissue Sampling Group'})}
              className="element-full-width"
              label={t({id: 'Tissue Sampling Group'})}
              menuItems={pointsGroupsValues}
              value={pointsCurrentGroupDate}
              onChange={selectTSDate}
              simplifiedMenu={true}
            />
          </>
        ) : null}

        {isNrxAllowed && currentMethod === 'apsim' && lastAvailableRecommendationDate ? (
          <NRxRecommendationSettings />
        ) : null}
        {apsimModelStatus}
      </div>

      <URLParamsEffect
        isNRXRecommendationAllowed={isNutrilogicAllowed || isNrxAllowed}
        calculatedMethod={calculatedMethod}
        currentMethod={currentMethod}
        recommendationDates={nrxSeason.recommendationDates}
        recommendationDate={recommendationDate.format(GLOBAL_FORMAT_DATE)}
        lastAvailableRecommendationDate={lastAvailableRecommendationDate}
        nRecommendationToggled={isNitrogenTab}
      />
    </>
  );
};

export default NRecommendation;
