import styled from 'styled-components';
import type {CSSObject} from 'styled-components';
import {theme} from '@regrow-internal/design-system';

export const MenubarMenuContainerStyles: CSSObject = {
  height: '100%', // keep it 100% for proper hover area
  ':hover': {
    backgroundColor: theme.colorPalette.fs_main.gray_800,
  },
};
export const MenubarMenuOptionStyles: CSSObject = {
  ':active': {
    backgroundColor: 'transparent',
  },
  ':hover': {
    backgroundColor: theme.colorPalette.fs_main.gray_800,
  },
  transition: 'background-color 0.3s',
  color: '#fff',
  background: 'none',
  backgroundColor: 'transparent',
  fontSize: '14px',
  padding: '4px 16px',
  cursor: 'pointer',
  maxWidth: '350px',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
};

export const MenubarMenuStyles: CSSObject = {
  backgroundColor: '#191411',
  borderRadius: '9px',
  boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.25)',
  padding: '12px 0',
  marginTop: '12px',
  width: 'fit-content',
  whiteSpace: 'nowrap',
};

export const MenubarControlStyles: CSSObject = {
  backgroundColor: 'transparent',
  borderWidth: 0,
  boxShadow: 'none',
  height: '100%', // keep it 100% for proper hover area
  cursor: 'pointer',
};

export const MenubarIndicatorsContainerStyles: CSSObject = {
  paddingRight: '10px',
  paddingLeft: '10px',
};

export const MenubarValueContainerStyles: CSSObject = {
  // hide the value container
  width: 0,
  opacity: 0,
  padding: 0,
};

export const MenubarMenuListStyles: CSSObject = {
  maxHeight: '342px', // 342px handles 3 workspaces + Regrow internal tools + 5 recent programs and Show all.. without displaying a scroll
  padding: 0,
};

export const OptionInnerLineSeparator = styled.div`
  height: 1px;
  margin: 8px 0;
  background-color: ${({theme}) => theme.colorPalette.fs_main.gray_800};
`;

export const OptionInnerTitle = styled.div`
  color: ${({theme}) => theme.colorPalette.fs_main.gray_400};
  font-size: 12px;
  padding: 0 12px 3px;
  &.topPadding {
    padding-top: 4px; // add this padding to keep title aligned with option item before the line separator
  }
`;
