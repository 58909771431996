import cn from 'classnames';
import {AppProcessingStatus} from 'components';
import {MapPreloader} from 'components/image-layer-preloader';
import {EmbedLogin} from 'containers/login/embed/embed-login-container';
import SelectorsToolbar from 'containers/selectors-toolbar/selectors-toolbar';
import type {Map} from 'leaflet';
import L from 'leaflet';
import 'leaflet-draw/dist/leaflet.draw.css';
import 'leaflet-measure';
import 'leaflet-measure/dist/leaflet-measure.css';
import 'leaflet/dist/leaflet.css';
import {AsyncStatusType, DialogType, Status} from 'modules/helpers';
import React, {useCallback, useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import {useMediaQuery} from 'react-responsive';
import {Redirect, Route, Switch} from 'react-router-dom';
import {mapLoading, toggleMapView} from 'modules/global/actions';
import _config, {CI_ROOT_PATH} from '_environment';
import {useAppSelector} from '_hooks';
import {selectAsyncRequestStatus} from 'modules/global/selectors';
import {validateBounds} from '_utils/';
import {getPanelRightPadding} from '../../_constants';
import CloudyTip from '../cloudy-tip';
import {reportError} from '../error-boundary';
import {selectHasFarmsOrGroupsIds, selectSIAccessSetting} from '../login/login-selectors';
import {SIAccess} from '../login/types';
import TemperatureChart from '../temperature-chart';
import {Provider} from './context';
import {CropPerformanceFiltersDescription} from './crop-performance-filters-description';
import {EditControl2} from './edit-control-2';
import Effects from './effects';
import {FarmMap} from './farm-map';
import {SIOverlay as SustainabilityInsightsOverlay} from './features/sustainability-insights/overlay';
import SIContainer from './features/sustainability-insights/si-container';
import {FlurosensePanel} from './flurosense-panel';
import {markerIcon} from './icons';
import './index.scss';
import './lib/fluro-measurement';
import './lib/svg-icon';
import {MapBar} from './map-bar/map-bar';
import TopControlsBar from './map-bar/top-controls-bar';
import {EditFieldButtonsContainer} from './map-buttons/edit-field-buttons-container';
import {MapFilterStatus} from './map-filter-status';
import CursorTooltipOverlay from './overlays/cursor-tooltip-overlay';
import HistogramOverlay from './overlays/histogram-overlay';
import {
  selectCurrentTab,
  selectIsMapBarOpen,
  selectIsReadOnly,
  selectIsWholeFarmView,
  selectZoningTab,
} from './reducer/selectors';
import MapColorSchema from './schemes-on-map/color-schema/color-schema-overlay';
import SoilMapSchema from './schemes-on-map/soil-map-schema/soil-map-schema';
import {TiledMap} from './tiled-map';
import TSUploadDialog from './tissue-sampling-upload/dialog';
import {ZoningTab} from './types';
import {selectDialogVisibility} from '../../modules/helpers/selectors';

// FIXME:
// https://github.com/PaulLeCam/react-leaflet/issues/255
//@ts-expect-error error leftover from convertion to strict mode, please fix
delete L.Icon.Default.prototype._getIconUrl;
Object.keys(markerIcon.options).forEach(key => {
  // @ts-expect-error error leftover from convertion to strict mode, please fix
  L.Icon.Default.prototype.options[key] = markerIcon.options[key];
});
// for some reason Marker doesn't responsible for i a pin marker anymore and was replaced by lines above
// L.Marker.prototype.options.icon = markerIcon;

//@ts-expect-error error leftover from convertion to strict mode, please fix
L.drawLocal.draw.handlers.marker.tooltip.start = '';

const FlurosenseMap = () => {
  const dispatch = useDispatch();
  const feature = useAppSelector(selectCurrentTab);
  const isReadOnly = useAppSelector(selectIsReadOnly);
  const isMapBarOpen = useAppSelector(selectIsMapBarOpen);
  const soilMapLayer = useAppSelector(s => s.map.soilMapLayer);
  const isVisibleWeather = useAppSelector(s => s.map.toggledButtonOnMap === 'Weather');
  const isVisibleHistogram = useAppSelector(s => selectDialogVisibility(s, DialogType.histogram));
  const isVisibleCloudyTip = useAppSelector(s => selectDialogVisibility(s, DialogType.cloudyTip));
  const layersSource = useAppSelector(s => s.map.layersSource);
  const isSmallScreen = useMediaQuery({query: '(max-width: 560px)'});
  const siAccessSetting = useAppSelector(selectSIAccessSetting);
  const sessionExpired = useAppSelector(s => s.global.sessionExpired);
  const hasFarms = useAppSelector(selectHasFarmsOrGroupsIds);
  const zoningTab = useAppSelector(selectZoningTab);
  const isWholeFarmView = useAppSelector(selectIsWholeFarmView);

  const showAppProcessingStatus =
    !['sustainability-insights', 'carbon'].includes(feature) &&
    hasFarms &&
    !([ZoningTab.NitrogenRx, ZoningTab.ProdMap].includes(zoningTab) && feature === 'zoning');

  const isFarmsLoading =
    useAppSelector(s => selectAsyncRequestStatus(s, AsyncStatusType.farmsList)) === Status.Pending;

  const [leafletElement, setLeafletElement] = useState<L.Map | null>(null);

  useEffect(() => {
    dispatch(toggleMapView(true));
    return () => {
      dispatch(toggleMapView(false));
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onRefMap = (node: Map) => {
    if (!node) return;

    setLeafletElement(node);
    //@ts-expect-error error leftover from convertion to strict mode, please fix
    window.leafletElement = node;
    node.createPane('field-geometry');
    //@ts-expect-error error leftover from convertion to strict mode, please fix
    node.getPane('field-geometry').style.zIndex = '500';
  };

  const patchedFitBounds = useCallback(
    (bounds: L.LatLngBounds, forceRightMargin?: boolean) => {
      try {
        if (!bounds || !leafletElement) {
          return;
        }
        const validBounds = validateBounds(bounds);
        if (!validBounds) return;
        const marginRight = getPanelRightPadding(forceRightMargin || isMapBarOpen);
        leafletElement.fitBounds(validBounds, {
          // we do not need padding bounds in the small screens
          paddingBottomRight: isSmallScreen ? [0, 70] : [marginRight, 70],
        });
      } catch (err) {
        reportError(`fitBounds Err = ${err}`);
      }
    },
    [leafletElement, isMapBarOpen, isSmallScreen]
  );

  const mapEventHandlers = {
    loading: () => {
      dispatch(mapLoading(true));
    },
    load: () => {
      dispatch(mapLoading(false));
    },
  };

  return (
    <div
      className={cn({
        'map-v2__container': true,
        transparent: feature === 'crop-performance' || feature === 'sustainability-insights',
      })}
    >
      <Provider
        //@ts-expect-error error leftover from convertion to strict mode, please fix
        value={{patchedFitBounds}}
      >
        <Effects />

        {sessionExpired && <EmbedLogin id="session-expired-embed-login" visible={true} />}

        {leafletElement && <CursorTooltipOverlay leafletElement={leafletElement} />}

        {isVisibleHistogram ? <HistogramOverlay /> : null}

        {!['crop-performance', 'sustainability-insights'].includes(feature) &&
          (soilMapLayer ? <SoilMapSchema /> : <MapColorSchema />)}

        <TSUploadDialog />

        {isVisibleCloudyTip && <CloudyTip />}

        <SelectorsToolbar type={'map-toolbar'} />

        <TiledMap
          trackResize={!isReadOnly}
          layersSource={layersSource}
          onRefMap={onRefMap}
          hasFarms={hasFarms}
          preferCanvas={isWholeFarmView}
          eventHandlers={mapEventHandlers}
        >
          <EditControl2 />
          <MapBar
            className={cn({
              'no-farms': !hasFarms && !isFarmsLoading,
            })}
          >
            <Switch>
              <Route
                exact
                path={`/${CI_ROOT_PATH}/sustainability-insights`}
                component={SIContainer}
              />
              <Route
                path={`/${CI_ROOT_PATH}/:farmId?/:fieldId?`}
                render={() => (
                  <>
                    {isSmallScreen ? (
                      <SelectorsToolbar type={'right-panel-toolbar'} />
                    ) : (
                      <TopControlsBar />
                    )}
                    <FlurosensePanel />
                  </>
                )}
              />
            </Switch>
            {showAppProcessingStatus && <AppProcessingStatus />}
          </MapBar>

          <Switch>
            {siAccessSetting !== SIAccess.Off && (
              <Route
                exact
                path={`/${CI_ROOT_PATH}/sustainability-insights`}
                component={SustainabilityInsightsOverlay}
              />
            )}
            {siAccessSetting === SIAccess.SIOnly ? (
              <Redirect to={`/${CI_ROOT_PATH}/sustainability-insights`} />
            ) : (
              <Route path={`/${CI_ROOT_PATH}/:farmId?/:fieldId?`} component={FarmMap} />
            )}
          </Switch>
        </TiledMap>

        <EditFieldButtonsContainer
          //@ts-expect-error error leftover from convertion to strict mode, please fix
          map={leafletElement}
        />

        {isVisibleWeather ? <TemperatureChart /> : null}

        {_config.env === 'development' && <div className="git-branch">{_config.branch}</div>}

        {feature === 'crop-performance' && <CropPerformanceFiltersDescription />}

        <MapPreloader requestTypes={[AsyncStatusType.imageLayer]} />

        <MapFilterStatus />
      </Provider>
    </div>
  );
};

export default FlurosenseMap;
