import Server from './server';
import type {AxiosPromise} from 'axios';
import type {StressedFieldsPerDate} from 'containers/map/features/anomalies/types';

export default class {
  static getPremiumAnomalies(
    farmId: number,
    fieldId: number | string,
    startDate: string,
    endDate: string
  ) {
    return Server.get(
      `app/anomaly/${farmId}/${fieldId}/geojson?from=${startDate}&till=${endDate}&fast=true`,
      {params: {__skipPreloader: true}}
    );
  }

  static getStressedFieldsPerDate(farmId: number): AxiosPromise<{result: StressedFieldsPerDate[]}> {
    return Server.get(`app/anomaly/${farmId}/dates`, {params: {__skipPreloader: true}});
  }

  static removePremiumAnomaly = (anomalyId: number) => {
    return Server.delete(`app/anomaly/${anomalyId}`);
  };

  /**
   * FSB-3223 A temp hack until backend use the grouping service.
   *
   * The `/dates` without `?exact_times` returns dates with T00:00:00Z,
   * anc crop stress expects this format. While pivo thermo still expects
   * exact dates+times format.
   *
   * When the grouping service is done, we'll be able to remove this method.
   */
  static getStressedFieldsPerDateExactTime(
    farmId: number
  ): AxiosPromise<{result: StressedFieldsPerDate[]}> {
    return Server.get(`app/anomaly/${farmId}/dates?exact_times`);
  }

  static update(farmId: number, fieldId: number | string, data: any) {
    return Server.put(`app/anomaly/${farmId}/${fieldId}`, data);
  }
}
