import type {
  MRVAttribute,
  MRVStageIcon,
  MRVStageNormalized,
  MRVProgramNormalized,
  MRVProgramStats,
  MRVProjectNormalized,
  MRVField,
  MRVValue,
  StageCompletion,
  MRVPhaseNormalized,
  MRVRegion,
  ProgramInvite,
} from 'containers/mrv/types';

export enum ActionType {
  UPDATE_PROGRAM = 'mrv/update-program',
  FETCH_ALL_PROGRAMS = 'monitoring/fetch-all-programs',
  FETCH_PROGRAM_STATS = 'monitoring/fetch-program-stats',
  FETCH_PROGRAM = 'monitoring/fetch-program',
  FETCH_PROJECT = 'monitoring/fetch-project',
  FETCH_PROJECTS_LIST = 'monitoring/fetch-projects-list',
  FETCH_PROJECT_VALUES = 'monitoring/fetch-project-values',
  FETCH_PROJECT_WITH_VALUES = 'monitoring/fetch-project-with-values',
  FETCH_PROGRAM_PROJECTS = 'monitoring/fetch-program-projects',
  FETCH_PROGRAM_PROJECTS_WITH_CONTRACT_DELETION = 'monitoring/fetch-program-projects-with-contract-deletion',
  FETCH_WHOLE_PROJECT = 'monitoring/fetch-whole-project',
  FETCH_USER_PERMISSIONS = 'monitoring/fetch-user-permissions',
  UPDATE_PROJECT_VALUES = 'monitoring/update-project-values',
  UPDATE_PROJECT_CONFIG = 'monitoring/update-project-config',
  REMOVE_PROJECT_VALUES = 'monitoring/remove-project-values',
  UPDATE_PROGRAM_ENROLLMENT = 'mrv/update-program-enrollment',
  UPDATE_PROGRAM_PHASE = 'mrv/update-program-phase',
  ADD_PROGRAM = 'mrv/add-program',
  REMOVE_PROGRAM = 'mrv/remove-program',
  ADD_PROGRAM_MONITORING_STAGE = 'mrv/add-program-monitoring-stage',
  REMOVE_PROGRAM_MONITORING_STAGE = 'mrv/remove-program-monitoring-stage',
  ADD_PROGRAM_MONITORING_ATTRIBUTE = 'mrv/add-program-monitoring-attribute',
  UPDATE_PROGRAM_MONITORING_ATTRIBUTE = 'mrv/update-program-monitoring-attribute',
  REMOVE_PROGRAM_MONITORING_ATTRIBUTE = 'mrv/remove-program-monitoring-attribute',
  UPDATE_PROGRAM_STAGE = 'mrv/update-program-stage',
  UPDATE_PROGRAM_STAGES = 'mrv/update-program-stages',
  UPDATE_PROGRAM_ASSETS = 'mrv/update-program-assets',
  REMOVE_PROGRAM_ASSETS = 'mrv/remove-program-assets',
  UPDATE_PROGRAM_PRACTICE_CHANGES = 'mrv/update-program-practice-changes',
  REMOVE_PROGRAM_PRACTICE_CHANGES = 'mrv/remove-program-practice-changes',
  REMOVE_PROJECT_FIELDS = 'mrv/remove-project-fields',
  REMOVE_PROJECT_VALUES_ROW = 'mrv/remove-project-values-row',
  FETCH_STAGE_COMPLETION = 'mrv/fetch-stage-completion',
  GENERATE_ENROLLMENT_PROJECT_CONTRACT = 'mrv/generate-enrollment-project-contract',
  ADD_CROP_TYPE = 'mrv/add-crop-type',
  REMOVE_CROP_TYPE = 'mrv/remove-crop-type',
  UPDATE_CONTRACT_STATUS = 'monitoring/update-contract-status',
  REQUEST_CONTRACT_REMOVING = 'monitoring/request_contract_removing',
  DELETE_PROJECT_CONTRACT = 'monitoring/delete-project-contract',
  FETCH_REGIONS = 'mrv/fetch-regions',
  ADD_PROGRAM_BOUNDARY = 'mrv/add-program-boundary',
  DELETE_PROGRAM_BOUNDARY = 'mrv/delete-program-boundary',
  GET_DNDC_RESULTS = 'mrv/get-dndc-results',
  FETCH_PROGRAM_BOUNDARIES = 'monitoring/fetch-program-boundaries',
  FETCH_PROGRAM_INVITES = 'mrv/admin/fetch-program-invites',
  RENEW_PROGRAM_INVITE = 'mrv/admin/renew-program-invite',
}

// Info is a bunch lists of options for dropdowns/chips.
export type ProgramInfoKey = 'assets' | 'practice_changes';

export type DocusignContractEvent = 'signing_complete';

export type MonitoringStateFilters = {
  programs: number[];
};

export type MonitoringState = {
  access: {admin: number[]; producer: number[]}; // admin is program id list, producer is project id list
  programs: {
    loaded: {page: number; total: number; size: number};
    info: {[key in ProgramInfoKey]: string[]};
    attributeLabels: {[attributeType: string]: string}; // {attributeType: attributeLabel}
  }; // configs
  stageIcons: MRVStageIcon[];
  entities: {
    // MRV Program
    programs: Record<string, MRVProgramNormalized>;
    stats: Record<string, MRVProgramStats>; // growers, fields, hectares per program. Key is programId.
    phases: Record<string, MRVPhaseNormalized>;
    stages: Record<string, MRVStageNormalized>;
    attributes: Record<string, MRVAttribute>;
    invites: Record<string, ProgramInvite[]>;

    // MRV Project
    fields: Record<string, MRVField>;
    projects: Record<string, MRVProjectNormalized>;
    projectValues: Record<string, MRVValue>;
  };
  filters: MonitoringStateFilters;
  completion: {[stageId: number]: StageCompletion};
  programBoundaries: Record<string, MRVRegion[]>;
};

export type ProjectValuesProgress = {[stageId: number]: number};
