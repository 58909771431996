import type {AppStore} from 'reducers';
import type {Season, TInfoExt} from '../../types';
import {checkSeasonTagExist} from '../../utils';
import {Tags as PremiumAppTag} from '../../../admin/features/types';
import {getGetURLParam, isDateInRange} from '_utils/pure-utils';
import type {IAnomaly, LowPerformingAnomaliesStore} from './types';
import moment from 'moment';
import {GLOBAL_APP_DATE_FORMAT, GLOBAL_FORMAT_DATE} from '_constants';
import {
  selectCurrentDate,
  selectCurrentDates,
  selectCurrentImageName,
  selectCurrentSeason,
  selectCurrentSensor,
  selectGeometriesOnMap,
  selectIsWholeFarmView,
  selectMapGeometry,
} from 'containers/map/reducer/selectors';

export const selectLowPerformingAnomalies = (s: AppStore) => s.map.lowPerfAnomalies;

export const selectPremiumAnomalies = (s: AppStore) => s.map.premiumAnomalies;

export const selectAnomaliesSelectedTab = (s: AppStore) => s.map.anomalies.anomaliesSelectedTab;

export const selectIsAnomaliesTagExist = (season?: Season) => {
  if (!season) return false;
  return (
    checkSeasonTagExist(PremiumAppTag.AnomalyDetection, season) ||
    checkSeasonTagExist(PremiumAppTag.CropStress, season) ||
    // FSB-4061 HACK: Unblocking Victor to test anomalies without the field tag, but using the url flag
    getGetURLParam('premium-anomalies')
  );
};

export const selectAnomalies = (state: AppStore) => state.map.anomalies;

export const selectAllVisibleGeometries = (state: AppStore) => {
  const lowPerfAnomalies = selectLowPerformingAnomalies(state);
  const geometry = selectMapGeometry(state);
  const currentDate = selectCurrentDate(state);
  const currentDates = selectCurrentDates(state);
  const geometriesOnMap = selectGeometriesOnMap(state);
  const isWholeFarmView = selectIsWholeFarmView(state);
  const currentImageName = selectCurrentImageName(state);

  const geometriesToShow = geometriesOnMap
    ? getGeometriesToShow(geometry, currentDate, currentDates, isWholeFarmView)
    : [];
  const anomaliesToShow =
    geometriesOnMap || lowPerfAnomalies.isVisible
      ? getAnomaliesToShow(lowPerfAnomalies, currentImageName)
      : [];

  return [...geometriesToShow, ...anomaliesToShow];
};

export const getAnomaliesToShow = (
  lowPerfAnomalies: LowPerformingAnomaliesStore,
  currentImageName: string
) => {
  const currentDateAnomalies = lowPerfAnomalies.list.uploadedROI?.features
    ? lowPerfAnomalies.list.uploadedROI
    : lowPerfAnomalies.list[currentImageName];

  return currentDateAnomalies ? currentDateAnomalies.features : [];
};

export const getGeometriesToShow = (
  geometry: IAnomaly[],
  currentDate: string,
  currentDates: Record<string, TInfoExt>,
  isWF: boolean
) => {
  const currentDatesLength = Object.keys(currentDates).length;
  geometry = geometry.filter(
    g =>
      (!currentDatesLength || currentDate) &&
      g.properties.saved !== undefined &&
      g.properties.saved !== 'edit'
  );
  return isWF
    ? geometry
    : geometry.filter(
        g =>
          !currentDatesLength ||
          (moment(currentDate, GLOBAL_APP_DATE_FORMAT).isSameOrBefore(
            moment(g.properties.endDate, GLOBAL_FORMAT_DATE)
          ) &&
            moment(currentDate, GLOBAL_APP_DATE_FORMAT).isSameOrAfter(
              moment(g.properties.startDate, GLOBAL_FORMAT_DATE)
            ))
      );
};

export const selectPerformanceGeometries = (
  state: AppStore
): {geometryToShow: IAnomaly[]; historyGeometries: IAnomaly[]} => {
  const lowPerfAnomalies = selectLowPerformingAnomalies(state);
  const geometry = selectMapGeometry(state);
  const currentSeason = selectCurrentSeason(state);
  const currentSensor = selectCurrentSensor(state);
  const currentDate = selectCurrentDate(state);

  try {
    const allDatesLowPerfAnomalies = Object.values(lowPerfAnomalies.list)
      .map(featureCollection => {
        if (!featureCollection.features) return false;

        const labeledFeaturesInCurrentSeason = featureCollection.features.filter(
          feature =>
            isDateInRange(
              String(feature.date),
              String(currentSeason?.startDate),
              String(currentSeason?.endDate)
            ) && // should be in this season
            feature.index === currentSensor && // sensor should be equal
            feature.properties.label // anomaly should have label
        );

        return (
          !featureCollection.savedAsRoi &&
          labeledFeaturesInCurrentSeason.length &&
          labeledFeaturesInCurrentSeason
        );
      })
      .filter(feature => feature)
      .flat(1);

    const geometriesToShow = geometry.filter(
      g => g.properties.saved !== undefined && g.properties.saved !== 'edit'
    );

    const isSameCurrentDate = (anomaly: IAnomaly) => {
      const {date, dateType} = anomaly;
      const anomalyDate = `${moment(date, GLOBAL_FORMAT_DATE).format(
        GLOBAL_APP_DATE_FORMAT
      )}-${dateType}`;
      return currentDate === anomalyDate;
    };

    const isInterSelect = (anomaly: IAnomaly) => {
      const {startDate, endDate} = anomaly.properties;
      return isDateInRange(
        moment.utc(currentDate, GLOBAL_APP_DATE_FORMAT).format(),
        startDate,
        endDate
      );
    };

    return {
      geometryToShow: [
        ...geometriesToShow.filter(el => isInterSelect(el)),
        ...allDatesLowPerfAnomalies.filter(
          el => el && isSameCurrentDate(el) && el.index === currentSensor
        ),
      ],
      historyGeometries: [
        ...geometriesToShow.filter(el => !isInterSelect(el)),
        ...allDatesLowPerfAnomalies.filter(
          el => el && !isSameCurrentDate(el) && el?.index === currentSensor
        ),
      ],
    };
  } catch (err) {
    return {geometryToShow: [], historyGeometries: []};
  }
};
