import type {InputComponentProps} from 'containers/mrv/monitoring/form/inputs/input-component';
import type {ComponentType} from 'react';
import React, {useContext, useEffect, useState} from 'react';
import {TextField} from 'react-md';
import {InputContext} from './input-context';

type InputFarmNumberProps = {
  onChange: InputComponentProps['onChange'];
};

export const InputFarmNumber: ComponentType<InputFarmNumberProps> = ({onChange}) => {
  const {id, value, label, placeholder, disabled} = useContext(InputContext);
  const [localValue, setLocalValue] = useState(value);

  useEffect(() => {
    if (value !== localValue) {
      setLocalValue(value);
    }
  }, [value]);

  function change(v: string) {
    const formattedValue = v
      // Replace anything that isn't alphanumeric, dots or dashes
      .replace(/[^\w.-]/g, '');

    setLocalValue(formattedValue);

    // Just update the draft, don't update the server value yet.
    onChange(formattedValue, false);
  }

  function update() {
    // Update the server value.
    onChange(localValue);
  }

  return (
    <TextField
      type={'text'}
      id={id}
      value={localValue}
      label={label}
      placeholder={placeholder}
      disabled={disabled}
      onChange={v => change(v as string)}
      onBlur={update}
    />
  );
};
