import React, {useMemo} from 'react';
import type {RouteComponentProps} from 'react-router-dom';
import {useHistory} from 'react-router-dom';
import './index.scss';
import Routes from './routes';
import useEmailToReviewNotification from 'containers/admin/use-email-to-review-notification';
import {FluroTabs} from 'components';
import {GLOBAL_ADMIN_PATH} from '_environment';
import {useWorkspace} from '_hooks/use-workspace';

/*
 *
 * Tabs configuration object
 *
 * */

const configTabs = [
  {
    label: 'Users',
    value: 'users',
  },
  {
    label: 'Premium Apps',
    value: 'features',
    exact: true,
  },

  {
    label: 'User Activity',
    value: 'user-activity',
  },

  {
    label: 'Crop types manager',
    value: 'crop/edit-crop',
  },
];

const Admin = ({location}: RouteComponentProps) => {
  useEmailToReviewNotification();
  const {push} = useHistory();
  const {workspaceLink} = useWorkspace();

  const selectedTab = useMemo(() => {
    return String(configTabs.find(({value}) => location.pathname.includes(value))?.value);
  }, [location]);

  return (
    <div className={'admin-panel'}>
      <FluroTabs
        containerClassName="second-level-menu-tabs"
        tabs={configTabs}
        selectedTab={selectedTab}
        onTabClick={tab => push(`${workspaceLink}${GLOBAL_ADMIN_PATH}/${tab}`)}
      />
      <Routes />
    </div>
  );
};

export const rowsPerPageItems: any[] = [
  {label: 10, value: 10},
  {label: 50, value: 50},
  {label: 100, value: 100},
  {label: 500, value: 500},
];
export const calcRowsPerPageItems = (totalItems: number) => {
  const _r = rowsPerPageItems.filter(el => el.value < totalItems || el.value === 50);

  if (totalItems <= 1000) {
    return [..._r, {label: 'All', value: totalItems}];
  }

  return _r;
};

export const rowsPerPage = 50;

export default Admin;
