import {selectMeasurement} from 'containers/login/login-selectors';
import {TOOLTIPS} from 'containers/si/constants';
import {useFetchKPI} from 'containers/si/hooks/useFetchKPI';
import {useSparklingChart} from 'containers/si/hooks/useSparklingChart';
import {selectSISelectedCropTypes} from 'containers/si/module/selectors';
import {t} from 'i18n-utils';
import {selectCropTypesCDLIdMap} from 'modules/global/selectors';
import React, {useEffect, useState} from 'react';
import {useAppSelector} from '_hooks';
import {} from '_hooks/use-url-params';
import {kiloFormatter, toFixedFloat} from '_utils/number-formatters';
import {KPIBarChart} from '../charts/kpi-bar-chart-card';
import {SparklingChartCard} from '../charts/sparkling-chart-card';

export const FertilizerEmissionFactorCard = () => {
  const cropsById = useAppSelector(selectCropTypesCDLIdMap);
  const selectedCropTypes = useAppSelector(selectSISelectedCropTypes);
  const measurement = useAppSelector(selectMeasurement);
  const {response: fertEmissionFactorResponse, loading} = useFetchKPI({
    kpi: 'fert_emissions_factor',
    summarizeBy: ['crop_type', 'annualized'],
  });
  const [chartData, setChartData] = useState<{label: string; value: number}[]>([]);
  const {sparklingChartData, dataset, metric, average, comparisonValues} = useSparklingChart(
    fertEmissionFactorResponse
  );

  useEffect(() => {
    if (!fertEmissionFactorResponse?.crop_type_summary) return;

    if (selectedCropTypes.length > 1) {
      const transformedData = Object.entries(fertEmissionFactorResponse.crop_type_summary).map(
        crop => {
          const [cropId, factor] = crop;

          return {
            label: cropsById[Number(cropId)]?.label,
            value: toFixedFloat(factor),
          };
        }
      );

      setChartData(transformedData);
    }
  }, [cropsById, fertEmissionFactorResponse, measurement, selectedCropTypes]);

  return selectedCropTypes.length > 1 ? (
    <KPIBarChart
      title={t({id: 'Fertilizer EF'})}
      subtitle={t({
        id: 'in kg fertilizer/1 kg of yield',
      })}
      colors={['#A571CB']}
      data={chartData}
      tooltip={TOOLTIPS.KPI.fertilizerEmissionFactor}
      loading={loading}
    />
  ) : (
    <SparklingChartCard
      title={t({id: 'Fertilizer EF'})}
      subtitle={t({id: 'in kg fertilizer/1 kg of yield'})}
      cardCenterNumber={
        metric === 0 ? 'No Data' : `${String(kiloFormatter(metric, 3)).toUpperCase()}`
      }
      cardCenterUnits={''}
      comparisonValues={comparisonValues}
      sparklingChartCaption={`${sparklingChartData.datasets.length} year avg`}
      sparklingChartCaptionBolded={`${kiloFormatter(average, 3)}`}
      chartData={{labels: sparklingChartData.labels, datasets: dataset}}
      chartDataMidline={average}
      tooltip={TOOLTIPS.KPI.cropArea}
      decimals={3}
      loading={loading}
    />
  );
};
