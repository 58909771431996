import React from 'react';
import type {ComponentType} from 'react';
import type {MRVAttribute} from 'containers/mrv/types';
import {MRVAttributeType} from 'containers/mrv/types';
import {tableHeaderColWidth} from './base';
import {
  Flex,
  FluroTableColumn as Column,
  FluroTableHeader as Header,
  FluroTableRow as Row,
} from 'components';
import {t} from 'i18n-utils';
import {FontIcon} from 'react-md';
import {useAppDispatch} from '_hooks';
import {toggleTooltip} from 'modules/global/actions';
import {getAttributeLabel} from 'containers/mrv/base';

type Props = {
  attributes: MRVAttribute[];
  /**
   * FMS sync report.
   */
  reportView?: boolean;
};
export const MRVTableHeaders: ComponentType<Props> = ({attributes, reportView = false}) => {
  return (
    <Header data-testid="MRV-table--header">
      <Row>
        <Column></Column>
        {attributes.map(attr => {
          const {id, type, label: attrLabel, description, enabled, visible, custom_name} = attr;

          if (
            !enabled ||
            !visible ||
            // Hack to hide the column from the user, need to come up with a solution for config
            type === MRVAttributeType.NutrientManagementAppProductType
          ) {
            return null;
          }

          const localLabel = getAttributeLabel(attr, reportView);
          const label = custom_name || localLabel || attrLabel;
          const colWidth = tableHeaderColWidth[type] || '140px';

          return (
            <MRVTableHeader
              key={id}
              label={label}
              description={
                description &&
                t({
                  id: `AdminPanelConfigMessage.${description}`,
                  defaultMessage: description,
                })
              }
              minWidth={colWidth}
              reportView={reportView}
            />
          );
        })}
      </Row>
    </Header>
  );
};

const MRVTableHeader: ComponentType<{
  label: string;
  description?: string;
  minWidth?: string;
  reportView?: boolean; // in the report dialog
}> = ({label, description, minWidth, reportView}) => {
  const dispatch = useAppDispatch();

  const showTooltip = () => {
    if (!description) return;
    dispatch(
      toggleTooltip({
        id: TT_ID,
        place: 'top',
        content: description,
      })
    );
  };

  return (
    <Column nowrap>
      <Flex alignItems="center" gap="5px" nowrap style={{minWidth}}>
        {label}{' '}
        {description && !reportView ? (
          <FontIcon {...{'data-for': TT_ID, 'data-tip': ''}} onMouseEnter={showTooltip}>
            help_outline
          </FontIcon>
        ) : (
          ''
        )}
      </Flex>
    </Column>
  );
};

const TT_ID = 'tooltip-baby';
