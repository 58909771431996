import Server from '../server';
import type {JohnDeereRequestPayload} from 'containers/profile/integration/integration-john-deere';
import type {SyncResponseData} from '../../containers/profile/integration/types';

export default class JohnDeereApi {
  static getOrganizations() {
    return Server.get('sync/johndeere/organizations', {
      params: {__skipPreloader: true},
    });
  }

  static getClients(organizationId: string) {
    return Server.get(`sync/johndeere/organizations/${organizationId}/clients`, {
      params: {__skipPreloader: true},
    });
  }

  static getFarms(organizationId: string, clientId: string) {
    return Server.get(`sync/johndeere/organizations/${organizationId}/clients/${clientId}/farms`, {
      params: {__skipPreloader: true},
    });
  }

  static getFields(organizationId: string, farmId: string) {
    return Server.get(`sync/johndeere/organizations/${organizationId}/farms/${farmId}/fields`, {
      params: {__skipPreloader: true},
    });
  }

  static importData(dataToImport: JohnDeereRequestPayload) {
    return Server.post(`sync/johndeere/import`, dataToImport, {
      params: {
        __skipPreloader: true,
      },
    });
  }

  static importDataForMRV(
    dataToImport: JohnDeereRequestPayload,
    mrv_project_id: number,
    mrv_phase_id: number
  ) {
    return Server.post(`sync/johndeere/import`, dataToImport, {
      params: {
        __skipPreloader: true,
        mrv_project_id,
        mrv_phase_id,
      },
    });
  }

  static getImportStatus(jobId: number) {
    return Server.get<SyncResponseData>(`sync/johndeere/import/${jobId}`, {
      params: {__skipPreloader: true},
    });
  }

  static logOut() {
    return Server.delete(`sync/johndeere/delete_user`);
  }
}
