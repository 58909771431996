import React, {useEffect, useState} from 'react';
import {useAppDispatch, useAppSelector} from '_hooks';
import {useParsedMatchParams} from '_hooks/use-parsed-match-params';
import {getRandomColor} from '_utils';
import {Text} from 'components';
import {SelectCropsCard} from './select-crops-card';
import {setCropStepBlocked, setNewCrops} from 'containers/si/module/reducer';
import {
  selectSISupplyShedsObjectByProgramId,
  selectCurrentProgramSupplyShedAndSubsectionIds,
  selectNewReportAreas,
  selectNewReportCrops,
} from 'containers/si/module/selectors';
import type {ReportSelectedSupplyShedCrops, ReportSelectedCommodities} from 'containers/si/types';
import '../create-report.scss';

export const CropsStep = () => {
  const dispatch = useAppDispatch();
  const {programId} = useParsedMatchParams();
  const supplySheds = useAppSelector(s => selectSISupplyShedsObjectByProgramId(s, programId));
  const supplyShedAndSubsectionIds = useAppSelector(selectCurrentProgramSupplyShedAndSubsectionIds);
  const allSelectedAreas = useAppSelector(selectNewReportAreas); // supplyShedId: subsectionIds[]
  const allSelectedCrops = useAppSelector(selectNewReportCrops);

  const [allSelectedCropsObject, setAllSelectedCropsObject] =
    useState<ReportSelectedSupplyShedCrops>(allSelectedCrops);

  useEffect(() => {
    // Calculate the number of Supply Sheds and subsections that NEED crops selected
    const shedAreas = Object.entries(allSelectedAreas).map(value => {
      const [supplyShedId, subsectionIdArray] = value;

      const entireSupplyShed =
        supplyShedAndSubsectionIds[Number(supplyShedId)].length === subsectionIdArray.length;

      return entireSupplyShed ? 1 : subsectionIdArray.length;
    });
    const areasThatNeedCropsSelected = shedAreas.reduce((a, b) => a + b, 0);

    // Calculate the number of Supply Sheds and subsections that HAVE crops selected
    const areasThatHaveCropsSelected = Object.values(allSelectedCrops).reduce((acc, c) => {
      if (c?.commodities && c?.commodities?.length > 0) return (acc += 1);
      else if (c.subsection) {
        return Object.values(c?.subsection)?.reduce((a, sub) => (sub.length > 0 ? a + 1 : a), acc);
      }
      return acc;
    }, 0);

    dispatch(setCropStepBlocked(areasThatNeedCropsSelected !== areasThatHaveCropsSelected));
  }, [allSelectedAreas, allSelectedCrops, dispatch, supplyShedAndSubsectionIds]);

  const onSelectSupplyShedCrops = (
    supplyShedId: number,
    unitId: number,
    unitType: 'supplyShed' | 'subsection',
    commodities: ReportSelectedCommodities[]
  ) => {
    const allAreaCommodities = {...allSelectedCropsObject};
    if (unitType === 'supplyShed') {
      allAreaCommodities[supplyShedId] = {commodities: commodities};
    } else {
      allAreaCommodities[supplyShedId] = {
        ...allAreaCommodities[supplyShedId],
        subsection: {
          ...(allAreaCommodities[supplyShedId] && allAreaCommodities[supplyShedId]['subsection']),
          [unitId]: commodities,
        },
      };
    }

    setAllSelectedCropsObject(allAreaCommodities);
    dispatch(setNewCrops(allAreaCommodities));
  };

  return (
    <div className="crops-step">
      <Text bold nowrap>
        Specify crop and sourcing volume for each area
      </Text>

      {Object.entries(allSelectedAreas).map(value => {
        const [supplyShedId, subsectionIdArray] = value;
        const shed = supplySheds[Number(supplyShedId)];

        const entireSupplyShed =
          supplyShedAndSubsectionIds[Number(supplyShedId)].length === subsectionIdArray.length;

        const color = shed?.color || getRandomColor();
        const supplyShedName = shed?.name || '';

        if (entireSupplyShed) {
          const availableCommodities = shed?.subsections.reduce(
            (acc, subsection) => [...acc, ...subsection?.commodities?.map(c => c.id)],
            [] as number[]
          );

          return (
            <SelectCropsCard
              key={supplyShedId}
              supplyShedId={Number(supplyShedId)}
              areaId={Number(supplyShedId)}
              onSelectSupplyShedCrops={onSelectSupplyShedCrops}
              color={color}
              name={supplyShedName}
              areaHa={shed?.total_field_area_ha || 0}
              areaType="supplyShed"
              selectedCommodities={
                allSelectedCropsObject[Number(supplyShedId)]?.commodities || [
                  {id: 0, label: '', type: '', volume_units: 'lb', volume: 0},
                ]
              }
              availableCommodities={availableCommodities || []}
            />
          );
        } else {
          return subsectionIdArray.map(subsectionId => {
            const subsection = shed?.subsections.filter(s => s.id === Number(subsectionId))[0];
            const availableCommodities = subsection?.commodities?.reduce(
              (acc, commodity) => [...acc, commodity?.id],
              [] as number[]
            );

            return (
              <SelectCropsCard
                key={subsection.id}
                supplyShedId={Number(supplyShedId)}
                areaId={Number(subsectionId)}
                onSelectSupplyShedCrops={onSelectSupplyShedCrops}
                color={color}
                name={`${supplyShedName}: ${subsection?.name || ''}`}
                areaHa={subsection?.field_area_ha || 0}
                areaType="subsection"
                selectedCommodities={
                  allSelectedCropsObject[Number(supplyShedId)]?.subsection?.[
                    Number(subsectionId)
                  ] || [{id: 0, label: '', type: '', volume_units: 'lb', volume: 0}]
                }
                availableCommodities={availableCommodities || []}
              />
            );
          });
        }
      })}
    </div>
  );
};
