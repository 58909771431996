// @ts-nocheck
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {Button} from 'react-md';
import {loadOrganizations} from '../actions';
import {useSelector, useDispatch, shallowEqual} from 'react-redux';
import type {InitialState, Organization} from '../types';
import {sortByKey} from '_utils/sorters';
import Autocomplete from 'components/autocomplete';
import InfoBlock from 'components/info-block';
import {dialogToggle, DialogType} from 'modules/helpers';
import {InlineBlock} from './user-edit.styled';

type Props = {
  createDefaultOrgName: string;
  defaultOrganizationID: number | null;
  onSetOrg: (id: number) => void;
  createDefaultOrg: () => void;
};

type AddOrganizationProps = {
  id: number;
  onSetOrg: (id: number) => void;
  onCreateOrg: () => void;
};

const buildLabel = (org: Organization) => `${org.subscription_name} | ${org.name} #${org.id}`;

const AddOrganization = ({onSetOrg, id, onCreateOrg}: AddOrganizationProps) => {
  const [search, setSearch] = useState('');

  const dispatch = useDispatch();

  const {organizations} = useSelector(
    ({users}: {users: InitialState}) => ({
      organizations: users.organizations,
    }),
    shallowEqual
  );

  useEffect(() => {
    !organizations.length && dispatch(loadOrganizations());
  }, []);

  useEffect(() => {
    const org = organizations.find((org: any) => org.id === id);

    if (org) {
      setSearch(buildLabel(org));
    }
  }, [organizations, id]);

  const data = useMemo(
    () => [
      {label: 'None', value: 0},
      ...sortByKey(
        organizations.map((org: any) => ({label: buildLabel(org), value: org.id})),
        'label'
      ),
    ],
    [organizations]
  );

  const handleAutocomplete = (
    suggestion: string | number,
    suggestionIndex: number,
    matches: any[]
  ) => {
    onSetOrg(matches[suggestionIndex].value);
  };

  return (
    <div>
      <InfoBlock appearance="info" className="inside-a-pop-up">
        By default, the farms will be automatically created in that organization
      </InfoBlock>

      <InlineBlock>
        <Autocomplete
          id="default-organization-select"
          name="default-organization-select"
          placeholder="Select default organization"
          label="Select default organization"
          className={'default-organization__autocomplete'}
          menuItems={data}
          value={search}
          dataLabel="label"
          dataValue="value"
          onChange={(value: string) => setSearch(value)}
          onAutocomplete={handleAutocomplete}
          autoComplete="no"
        />

        <Button primary raised onClick={onCreateOrg}>
          + NEW ORGANIZATION
        </Button>
      </InlineBlock>
    </div>
  );
};

/*
 *
 * Main component
 *
 * */
const DefaultOrganization = ({
  createDefaultOrg,
  onSetOrg,
  defaultOrganizationID,
  createDefaultOrgName,
}: Props) => {
  const dispatch = useDispatch();

  const onCreateOrg = useCallback(() => {
    dispatch(
      dialogToggle(DialogType.confirm, true, {
        okAction: createDefaultOrg,
        title: 'Create default organization',
        message: (
          <>
            <div className="default-organization__name">{createDefaultOrgName}</div>
            <InfoBlock appearance="warning" className="inside-a-pop-up">
              Don’t forget to click on “Save” before leaving the page.
            </InfoBlock>
          </>
        ),
      })
    );
  }, [createDefaultOrg, createDefaultOrgName]);

  return (
    <>
      <h3>Default organization</h3>
      <AddOrganization onSetOrg={onSetOrg} onCreateOrg={onCreateOrg} id={defaultOrganizationID} />
    </>
  );
};

export default DefaultOrganization;
