import React from 'react';
import cn from 'classnames';
import './index.scss';
import {FontIcon} from 'react-md';

export enum FluroTabsSkin {
  Default = 'defaultSkin',
  Toggle = 'toggleSkin',
  Chips = 'chipsSkin',
}

export type FluroTabsProps<T extends string> = {
  onTabClick(value: T): void;
  selectedTab: T;
  tabs: SingleFluroTabProps<T>[];
  containerClassName?: string;
  skin?: FluroTabsSkin;
  hiddenTabs?: SingleFluroTabProps<T>['label'][];
  sticky?: boolean;
  stopPropagation?: boolean;
  fullWidth?: boolean;
  noMargin?: boolean;
};

export type SingleFluroTabProps<T> = {
  label: any;
  value: T;
  className?: string;
};

export const FluroTabs = <T extends string>({
  skin = FluroTabsSkin.Default,
  tabs,
  hiddenTabs,
  selectedTab,
  containerClassName = '',
  onTabClick,
  sticky,
  stopPropagation = false,
  fullWidth,
  noMargin,
}: FluroTabsProps<T>) => {
  return (
    <div
      className={cn('fluro-tabs-container', containerClassName, skin, {
        sticky,
        fullWidth,
        noMargin,
      })}
    >
      {tabs.map(tab => {
        // Do not show hidden tabs unless selected
        if (hiddenTabs?.includes(tab) && tab.value !== selectedTab) {
          return null;
        }
        return (
          <div
            key={tab.value}
            onClick={e => {
              if (stopPropagation) {
                e.stopPropagation();
              }
              onTabClick(tab.value);
            }}
            className={cn(`tab ${tab.className || ''}`, {active: selectedTab === tab.value})}
          >
            {tab.label}
          </div>
        );
      })}
    </div>
  );
};

type HelpButtonProps = {
  label: any;
  onClick: (e: React.MouseEvent<HTMLElement>) => void;
  icon?: string;
};

export const TabWithSubButton = ({label, onClick, icon = 'help_outline'}: HelpButtonProps) => (
  <div className="fluro-tabs-help-btn">
    {label} <FontIcon onClick={onClick}>{icon}</FontIcon>
  </div>
);

export default FluroTabs;
