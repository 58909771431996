import {InfoBlock} from 'components/info-block';
import {OFSStatus} from 'containers/mrv/types';
import React from 'react';
import {useAppSelector} from '_hooks';
import colors from 'variables.scss';
import {LoadingIcon} from 'containers/mrv/icons';
import {selectOFS} from 'containers/mrv/enrollment/carbon-store/selectors';
import {Flex} from 'components/flex/flex';
import {CheckIcon} from 'components/icons';
import {useMonitoringUrlParams} from 'containers/mrv/monitoring/hooks';
import {FormattedMessage} from 'react-intl';
import {t} from 'i18n-utils';
import {StageMessageView} from 'containers/mrv/admin/base/stage-message';
import {selectCurrentProjectFieldsAwaitingOptis} from 'containers/mrv/monitoring/module/selectors';

export const OptisStatusMessage = () => {
  const ofs = useAppSelector(selectOFS);
  const fieldIdsAwaitingOptis = useAppSelector(selectCurrentProjectFieldsAwaitingOptis);
  const completion = useAppSelector(s => s.monitoring.completion);
  const {stageId} = useMonitoringUrlParams();
  const totalCellsIncomplete = completion[stageId]?.to_complete;

  // We can't rely on the projects `ofs.status === OFSStatus.InProgress`. Checking the fields is more reliable.
  if (fieldIdsAwaitingOptis.length) {
    return (
      <InfoBlock
        icon={<LoadingIcon style={{color: colors['main-blue-600']}} />}
        mini
        appearance="info"
        color="info"
        fullWidth
      >
        <Flex fullWidth justifyContent="space-between">
          <span>
            <FormattedMessage
              id="OptisStatusInProgressMessage"
              defaultMessage="<strong>Detecting crop history.</strong> As fields load you can confirm the crop
            history. You will also be notified by email when this process is complete."
              values={{strong: (msg: string) => <strong>{msg}</strong>}}
            />
          </span>
          <strong>
            {t(
              {id: 'Optis percent% complete', defaultMessage: '{percentCompleted}% complete'},
              {percentCompleted: ofs.percentCompleted}
            )}
          </strong>
        </Flex>
      </InfoBlock>
    );
  }

  if (ofs.status === OFSStatus.Failed) {
    return (
      <InfoBlock
        mini
        appearance="error"
        color="error"
        title={t({id: 'Unable to detect crop history.'})}
      >
        {t({id: 'Please fill in your information manually.'})}
      </InfoBlock>
    );
  }

  if (totalCellsIncomplete) {
    return (
      <StageMessageView
        untouchedCells
        message={{
          type: 'warning',
          title: t(
            {
              id: 'OptisTotalCellsIncompleteTitle',
              defaultMessage: '{totalCellsIncomplete} cell(s) incomplete for Crop History.',
            },
            {totalCellsIncomplete}
          ),
          text: (
            <FormattedMessage
              id="OptisTotalCellIncompleteMessage"
              values={{icon: <CheckIcon style={{verticalAlign: 'bottom'}} />}}
            />
          ),
        }}
      />
    );
  }

  return null;
};
