import styled from 'styled-components';
export const ImageDateSelectorWrapper = styled.div`
  display: flex;
  align-items: center;

  > div {
    width: 100%;
  }

  > .md-icon {
    padding: 10px;
    padding-left: 0;
  }

  .date-item {
    height: 20px;
    overflow: hidden;
    display: flex;
    align-items: center;
  }
`;

export const Controls = styled.span`
  .modified input {
    color: #43a047;
    font-weight: bold;
  }
`;
