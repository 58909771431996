import type {ValidationWarning} from 'containers/mrv/monitoring/form/mrv-table/hacky-validation';
import type {Dispatch, SetStateAction} from 'react';
import {createContext} from 'react';

type ValidationContextType = {
  setIgnoredSimilarWarnings: Dispatch<SetStateAction<ValidationWarning[]>>;
  setIgnoredWarnings: Dispatch<SetStateAction<ValidationWarning[]>>;
  ignoredSimilarWarnings: ValidationWarning[];
  ignoredWarnings: ValidationWarning[];
};

export const ValidationContext = createContext<ValidationContextType>({
  setIgnoredSimilarWarnings: () => {},
  setIgnoredWarnings: () => {},
  ignoredSimilarWarnings: [],
  ignoredWarnings: [],
});
