import L from 'leaflet';

L.Control.Measure.include({
  _baseHandleMeasureClick: L.Control.Measure.prototype._handleMeasureClick,
  _handleMeasureClick: function (evt) {
    this._baseHandleMeasureClick(evt);
    const model = L.extend({}, this._resultsModel, {points: this._latlngs});
    this._map.fire('measureclick', model, false);
  },
  update: function (options) {
    L.setOptions(this, {...this.options, ...options});
    this._updateResults();
  },
});
