import type {RequestStatus} from 'types';

export type SIMetricsType = 'ac' | 'op'; // acres | operations
export type SIAreaUnits = 'pct' | 'num'; // percents | number
export type SIChartTab = 'summary' | 'trend';

export type StateFP = number;
export type CRDId = number;
export type CountyFIPS = number;
export type HUCId = number;
export type SupplyShedId = number;
export type AreaId = StateFP | CRDId | CountyFIPS | HUCId | SupplyShedId;

export interface AreaMeta {
  id: AreaId;
  name: string;
}

export interface SIStateMeta extends AreaMeta {
  id: StateFP;
  name: string;
  crd?: CRDId[];
  county?: CountyFIPS[];
  huc8?: HUCId[];
  huc10?: HUCId[];
  huc12?: HUCId[];
}

export interface SIStateChildMeta extends AreaMeta {
  id: AreaId;
  name: string;
  statefp: StateFP;
}

export type SIAreaMeta = SIStateChildMeta | SIStateMeta;

export enum SIGeometryFilterType {
  AbatementPotential = 'abatementPotential',
  CoverCrops = 'coverCrops',
  Tillage = 'tillage',
  GHG = 'ghg',
  SOC = 'soc',
  Yield = 'yield',
  Fertilizer = 'fertilizer',
  EmissionsFactor = 'ef',
}

export type SIFilterState = {
  // Metrics parameters
  aggLevel: SIAggLevel;
  years: number[] | null;

  stateIdsRequestParam: StateFP[];
  supplyShedIdsRequestParam: SupplyShedId[];
  activeGeometriesIds: AreaId[];
  visibleGeometriesIds: AreaId[];
  highlightGeometryId: AreaId | null;

  // Filters
  fieldAdopterType: SIAdopterType | null;
  operationAdopterType: SIAdopterType | null;
  operationSize: string;
  operationStatus?: SIOperationStatus;
  summerCropTypes: string;

  // Filter cards UI
  activeGeometryType: SIGeometryFilterType;
  areaUnits: SIAreaUnits;
  metricsType: SIMetricsType;
  [SIGeometryFilterType.Tillage]: {
    chartTab: SIChartTab;
    selectedPractice: TillagePractice;
    range: [number, number] | null;
  };
  [SIGeometryFilterType.CoverCrops]: {
    chartTab: SIChartTab;
    includeCategories: CoverCropsType[];
    range: [number, number] | null;
  };
  [SIGeometryFilterType.AbatementPotential]: {
    chartTab: SIChartTab;
    selectedMetric: 'carbon_all' | 'carbon_all_corn' | 'carbon_corn_rotation'; // the abatement potential chart can use values from different metrics
    filterRange: AbatementPotentialFilterRange;
  };
  [SIGeometryFilterType.SOC]: {
    chartTab: SIChartTab;
    includeCategories: CoverCropsType[];
    range: [number, number] | null;
  };
  [SIGeometryFilterType.GHG]: {
    chartTab: SIChartTab;
    includeCategories: CoverCropsType[];
    range: [number, number] | null;
  };
  [SIGeometryFilterType.Yield]: {
    chartTab: SIChartTab;
    includeCategories: CoverCropsType[];
    range: [number, number] | null;
  };
  [SIGeometryFilterType.EmissionsFactor]: {
    chartTab: SIChartTab;
    includeCategories: CoverCropsType[];
    range: [number, number] | null;
  };
  [SIGeometryFilterType.Fertilizer]: {
    chartTab: SIChartTab;
    includeCategories: CoverCropsType[];
    range: [number, number] | null;
  };
};

export type SIDataAggState = {
  geometries: Record<AreaId, GeoJSON.Feature>;
  meta: Record<AreaId, SIAreaMeta>;
  metrics: Record<AreaId, Record<number, SIMetricData>>;
  summary: Record<number, SIMetricData>;
};

export type SIDataPolicyState = {
  aggLevelsAvailable: SIAggLevel[];
  metricGroupsAvailable: SIMetricGroup[];
  yearsAvailable: number[];
} & Record<SIAggLevel, AreaId[]>;

export type SIDataState = {
  summerCropTypes: Record<number, SummerCropType>;
  policy: SIDataPolicyState;
} & Record<SIAggLevel, SIDataAggState>;

export type SIState = Readonly<{
  filter: SIFilterState;
  data: SIDataState;
}>;

export type SIDataType = 'meta' | 'tillage' | 'coverCrops' | 'geometries';

export enum CoverCropsType {
  Perennial = 'Perennial',
  WinterCommodity = 'WinterCommodity',
  CoverCrop = 'CC',
  NoCoverCrop = 'NoCC',
  // NoData = 'NoData',
}

/**
 * The value is in Acres.
 */
export type SIItemCoverCrops = {
  [CoverCropsType.Perennial]: number;
  [CoverCropsType.WinterCommodity]: number;
  [CoverCropsType.CoverCrop]: number;
  [CoverCropsType.NoCoverCrop]: number;
  // [CoverCropsType.NoData]: number;
};

export type SIAggLevel = 'state' | 'crd' | 'county' | 'huc8' | 'huc10' | 'huc12' | 'supply_shed';

export const aggLevelLabel: Record<SIAggLevel, string> = {
  state: 'State',
  crd: 'CRD',
  county: 'County',
  huc8: 'HUC8',
  huc10: 'HUC10',
  huc12: 'HUC12',
  supply_shed: 'Supply shed',
};

export enum ResidueCover {
  High = 'HighRC',
  Moderate = 'ModRC',
  Low = 'LowRC',
  VeryLow = 'VLowRC',
  // NoData = 'NoData',
}

export type SIMetricColumn =
  | 'total_arable_area_ac'
  | 'cc_area_ac'
  | 'tillage'
  | 'cc_operations_nb'
  | 'cc_operations_new_nb'
  | 'cc_operations_consistent_nb'
  | 'cc_operations_ac'
  | 'cc_operations_new_ac'
  | 'cc_operations_consistent_ac'
  | 'cc_new_area_ac'
  | 'cc_consistent_area_ac'
  | 'cc_owner_operator_area_ac'
  | 'cc_rented_leased_area_ac'
  | 'count_operations'
  | 'carbon_all'
  | 'carbon_all_corn'
  | 'carbon_corn_rotation'
  | 'ghg'
  | 'soc'
  | 'carbon_area_ac'
  | 'crop_yield'
  | 'kpi_area_ha'
  | 'fertilizer'
  | 'ef';

export type SIMetricGroup =
  | 'cover_crops'
  | 'tillage_practices'
  | 'grower_demographics'
  | 'carbon'
  | 'ghg'
  | 'soc_sequestration'
  | 'crop_yield'
  | 'fertilizer'
  | 'ef';

export type SIAdopterType = 'consistent' | 'new';
export type SIOperationStatus = 'owner/operator' | 'rented/leased';

export type SIMetricData = {
  carbon_all_corn: {value: number; area: number};
  carbon_all: {value: number; area: number};
  carbon_corn_rotation: {value: number; area: number};
  cc_area_ac: {0: number; 1: number};
  cc_operations_nb: {0: number; 1: number};
  /**
   * where the value is the number of operations that have a “new” adopter type
   */
  cc_operations_new_nb: number;
  /**
   * where the value is the number of operations that have a “consistent” adopter type
   */
  cc_operations_consistent_nb: number;
  cc_new_area_ac: number;
  cc_owner_operator_area_ac: number;
  cc_rented_leased_area_ac: number;
  cc_consistent_area_ac: number;
  count_operations: number;
  tillage: {1: number; 2: number; 3: number};
  total_arable_area_ac: number;
  soc: number;
  ghg: number;
  carbon_area_ac: number;
  kpi_area_ha: number;
  crop_yield: number;
  ef: number;
  fertilizer: number;
};

export type MetricTypeKeys = Record<
  SIMetricsType,
  [SIMetricColumn | 'cc_area_ac.1', SIMetricColumn | 'cc_area_ac.1']
>;

export type SIMetric = {
  county_fips: CountyFIPS;
  county_name: string;
  geometry: GeoJSON.Feature;
  report_year: number;
  state_name: string;
  statefp: StateFP;
} & SIMetricData;

export type MetricStatDatumValues_DEPRECATED<T = number> = {
  'ac-num': T;
  'ac-pct': T;
  'ac-from'?: T;
  'ac-to'?: T;

  'op-num': T;
  'op-pct': T;
  'op-from'?: T;
  'op-to'?: T;
};

export type StatsDatumValue = {
  num: number;
  pct: number;
  total?: number;
  numFrom?: number;
  numTo?: number;
  totalFrom?: number;
  totalTo?: number;
};

export type StatsDatum = Record<
  SIMetricsType,
  {
    num: number;
    pct: number;
    total?: number;
    totalFrom?: number;
    totalTo?: number;
  }
>;

export type MetricStatDatum = {
  id: AreaId;
  name: string;
  statefp?: StateFP;
  color: string;
  values: StatsDatum & {
    trends?: MetricStatDatumValues_DEPRECATED<Array<{x: number; y: number}>>;
  };
};
export type MetricSocGhgStatDatum = {
  id: AreaId;
  name: string;
  statefp?: StateFP;
  color: string;
  value?: number;
  values: {
    num: number;
    pct: number;
    rawNumValue: number;
    trends?: {num: {x: number; y: number}[]; pct: {x: number; y: number}[]};
  };
};

export type MetricYieldStatDatum = {
  id: AreaId;
  name: string;
  statefp?: StateFP;
  color: string;
  value?: number;
  values: {
    num: number;
    pct: number;
    rawNumValue: number;
    area: number;
  };
};

export type MetricEmissionsFactorStatDatum = {
  id: AreaId;
  name: string;
  statefp?: StateFP;
  color: string;
  value?: number;
  values: {
    num: number;
    pct: number;
    rawNumValue: number;
  };
};

export type MetricStatSummaryDatum = {
  values: {
    ac: Partial<StatsDatumValue>;
    op: Partial<StatsDatumValue>;
  } & {
    trends?: MetricStatDatumValues_DEPRECATED<Array<{x: number; y: number}>>;
  };
};

export type StateCCStats = {
  ac: {num: number; pct: number; diff: {num: number; pct: number}};
  op: {num: number; pct: number; diff: {num: number; pct: number}};
};

export type MetricsStats = {
  stats: Readonly<Record<AreaId, MetricStatDatum>>;
  stateStats?: Readonly<Record<AreaId, StateCCStats>>;
  summary?: Readonly<MetricStatSummaryDatum>;
};

export type SummaryChartDatum = {
  id: AreaId;
  name: string;
  value: number;
  statefp?: StateFP;
  area?: number;
};

export type GroupedSummaryChartData = {
  name: string;
  value: number;
  data: SummaryChartDatum[];
};

export type SummaryChartData = {
  type: 'normal' | 'grouped';
  chartData: SummaryChartDatum[] | GroupedSummaryChartData[];
  chartGeometries?: Record<AreaId, boolean>;
  chartGeometriesIds?: AreaId[];
  preview: boolean;
  totalValue: number;
};

export type GroupedSocGhgSummaryChartData = {
  name: string;
  value: number;
  data: MetricSocGhgStatDatum[];
};

export type SocGhgSummaryChartData = {
  type: 'normal' | 'grouped';
  chartData: MetricSocGhgStatDatum[] | GroupedSocGhgSummaryChartData[];
  chartGeometries?: Record<AreaId, boolean>;
  chartGeometriesIds?: AreaId[];
  preview: boolean;
  totalValue: number;
  values: number[];
  valuesById: Record<number, number>;
};

export type StateResponseMeta = {
  statefp: StateFP;
  state_name: string;
};

// There is no name for CRD so we use id for it. And CRD ids are no unique in US,
// but unique inside one state. So for unique crd id we use composite id :
// state id and crd id together.
// To sum up for CRD 20 in state Illinois (state id 17) crd_id will be 1770 and crd_name 70
// reference: https://regrow.atlassian.net/browse/FSB-5845?focusedCommentId=28951&page=com.atlassian.jira.plugin.system.issuetabpanels%3Acomment-tabpanel#comment-28951
export type CRDResponseMeta = {
  crd_id: CRDId;
  crd_name: number;
};

export type CountyResponseMeta = {
  county_fips: CountyFIPS;
  county_name: string;
};

export type HUC8ResponseMeta = {
  huc8_id: HUCId;
  huc8_name: string;
};

export type HUC10ResponseMeta = {
  huc10_id: HUCId;
  huc10_name: string;
};

export type HUC12ResponseMeta = {
  huc12_id: HUCId;
  huc12_name: string;
};

export type SIStatesResponse = {
  states: StateResponseMeta[];
};

export type SIStatesCountiesResponse = {
  states: (StateResponseMeta & {counties: CountyResponseMeta[]})[];
};

export type SICRDResponse = {
  states: (StateResponseMeta & {crd: CRDResponseMeta[]})[];
};

export type SIMetricAreaType = {
  name?: string;
  fips_code: AreaId;
};

export type SIPolicyStateHUC8 = {
  id: HUCId;
  name: string;
};

export type SIPolicyState = {
  name?: string;
  fips_code: AreaId;
  counties: SIMetricAreaType[] | '*';
  huc8s?: SIPolicyStateHUC8[] | '*';
};

export type SIPolicy = {
  years: number[];
  metric_groups: SIMetricGroup[];
  agg_levels: SIAggLevel[];
  states: SIPolicyState[];
};

export type SIPolicyRequestBody = SIPolicy & {
  user_ids?: number[];
  user_id?: number;
};

export type SIMetricResponseItem = {
  county_name?: string;
  statefp: StateFP;
  state_name: string;
  geometry: GeoJSON.Feature;
  metrics: (SIMetricData & {year: number})[];
};

export enum MetricsNew {
  cc_area_ac = 'cc_area_ac',
  total_arable_area_ac = 'total_arable_area_ac',
}

export type SIMetricsRequestParams = {
  agg_level: SIAggLevel;
  metrics: string;
  years: string;

  states?: string;
  counties?: string;
  huc8?: string;
  huc10?: string;
  huc12?: string;

  adopter_type?: SIAdopterType;
  operation_size?: string;
  operation_status?: SIOperationStatus;
  summer_crop_type?: string;

  // service params
  geoms: boolean;
};

export type SIMetricsResponseItem = {
  county_name: string;
  geometry?: GeoJSON.Feature;
  metrics: Record<number, SIMetricData>;
};

export type SIMetricsResponse = {
  metrics: Record<number, SIMetricData>;
  summary: Record<number, SIMetricData>;
};

export type SIHUC8Response = {
  states: Array<{
    statefp: StateFP;
    state_name: string;
    huc8: HUC8ResponseMeta[];
  }>;
};

export type SIHUC10Response = {
  states: Array<{
    statefp: StateFP;
    state_name: string;
    huc10: HUC10ResponseMeta[];
  }>;
};

export type SIHUC12Response = {
  states: Array<{
    statefp: StateFP;
    state_name: string;
    huc12: HUC12ResponseMeta[];
  }>;
};

// Abatement potential types

export type AbatementPotentialFilterRange = number[];

export enum ActionType {
  FETCH_STATES_LIST = 'si/fetch-states-list',
  FETCH_CRD_LIST = 'si/fetch-crd-list',
  FETCH_COUNTIES_LIST = 'si/fetch-counties-list',
  FETCH_HUC8_LIST = 'si/fetch-huc8-list',
  FETCH_HUC10_LIST = 'si/fetch-huc10-list',
  FETCH_HUC12_LIST = 'si/fetch-huc12-list',
  FETCH_AVAILABLE_AREA = 'si/fetch-available-area',
  FETCH_METRICS = 'si/fetch-metrics',
  FETCH_USER_POLICY = 'si/fetch-user-policy',
  FETCH_SUMMER_CROP_TYPES = 'si/fetch-summer-crop-types',
  FETCH_GEOMETRIES = 'si/fetch-geometries',

  SET_ACTIVE_GEOMETRIES_FILTER = 'si/set-active-geometries-filter',
  SELECT_ACTIVE_GEOMETRIES = 'si/select-active-geometries',
  SET_STATE_IDS_REQUEST_PARAM = 'si/set-state-ids-request-param',
  SET_SUPPLY_SHEDS_REQUEST_PARAM = 'si/set-supply-sheds-request-param',
  SET_AGG_LEVEL = 'si/set-agg-level',
  SET_AREA_UNITS = 'si/set-area-units',
  SET_CHART_TAB = 'si/set-chart-tab',
  SET_CROP_CHART_FILTER_ACTION = 'si/set-crop-chart-filter-action',
  SET_FILTER = 'si/set-filter',
  SET_CC_CARD_FILTER = 'si/set-cc-card-filter',
  SET_FILTER_KEY = 'si/set-filter-key',
  SET_FILTER_YEARS = 'si/set-filter-years',
  SET_HIGHLIGHT_GEOMETRY = 'si/set-highlight-geometry',
  SET_STATUS = 'si/set-status',
  ABATEMENT_POTENTIAL_SET_SELECTED_METRIC = 'si/abatement-potential/set-selected-metric',
  ABATEMENT_POTENTIAL_SET_FILTER_RANGE = 'si/abatement-potential/set-filter-range',

  //Tillage
  TILLAGE_SET_SELECTED_PRACTICE = 'si/tillage/set-selected-practice',
  TILLAGE_SET_RANGE_VALUES = 'si/tillage/set-range-values',

  // Soc / GHG
  SET_SOC_FILTER = 'si/set-soc-filter',
  SET_GHG_FILTER = 'si/set-ghg-filter',
  SET_YIELD_FILTER = 'si/set-yield-filter',
  SET_EMISSIONS_FACTOR_FILTER = 'si/set-emissions-factor-filter',
  SET_FERTILIZERS_FILTER = 'si/set-fertilizers-filter',
}

export type FilterPayload = Partial<
  Omit<SIFilterState, 'coverCrops' | 'abatementPotential' | 'tillage'>
>;

export type SetStatusAction = {
  type: ActionType.SET_STATUS;
  actionType: ActionType;
  status: RequestStatus;
};

export type SetFilterAction = {
  type: ActionType.SET_FILTER;
  payload: FilterPayload;
};

export type SetAggLevelAction = {
  type: ActionType.SET_AGG_LEVEL;
  aggLevel: SIAggLevel;
  nextAreaIds: AreaId[];
};

export type SetAreaUnitsAction = {
  type: ActionType.SET_AREA_UNITS;
  areaUnits: SIAreaUnits;
};

export type SetFilterYearsAction = {
  type: ActionType.SET_FILTER_YEARS;
  years: number[];
};

export type SetActiveGeometriesFilterAction = {
  type: ActionType.SET_ACTIVE_GEOMETRIES_FILTER;
  id: AreaId | AreaId[];
  multiselect: boolean;
  resetVisible: boolean;
};

export type SelectActiveGeometriesAction = {
  type: ActionType.SELECT_ACTIVE_GEOMETRIES;
  payload: {
    ids: AreaId[];
    selected: boolean;
    stateId?: StateFP;
    resetVisible?: boolean;
  };
};

export type SetChartTabAction = {
  type: ActionType.SET_CHART_TAB;
  tab: SIChartTab;
  cardType: SIGeometryFilterType;
  areEveryGeometrySelected: boolean;
};

export type SetHighlightGeometryAction = {
  type: ActionType.SET_HIGHLIGHT_GEOMETRY;
  geometryId: AreaId | null;
};

export type SetFilterKeyAction = {
  type: ActionType.SET_FILTER_KEY;
  key: 'fieldAdopterType' | 'operationAdopterType' | 'operationStatus';
  value: SIMetricColumn;
};

export type SetCoverCropsFilterAction = {
  type: ActionType.SET_CROP_CHART_FILTER_ACTION;
  payload: Partial<SIFilterState['coverCrops']>;
};

// Abatement potential reducer types

export type SetAbatementPotentialMetricAction = {
  type: ActionType.ABATEMENT_POTENTIAL_SET_SELECTED_METRIC;
  metric: SIFilterState['abatementPotential']['selectedMetric'];
};

export type SetAbatementPotentialFilterRange = {
  type: ActionType.ABATEMENT_POTENTIAL_SET_FILTER_RANGE;
  filterRange: AbatementPotentialFilterRange;
};

// END Abatement potential reducer types

export type SummerCropType = {label: string; summer_crop_type: number};
export type SummerCropTypesResponse = {crop_types: SummerCropType[]};

// Tillage

export enum TillagePractice {
  'Conventional' = '1',
  'Reduced' = '2',
  'NoTill' = '3',
}

export type TillageStats = {
  // {2020[year]: {12[areaId]: {'1'[Conservation till]: {num: 1300; pct: 28.1}, ...etc}
  [year: string]: Record<AreaId, Record<TillagePractice, Record<SIAreaUnits | 'total', number>>>;
};

export type SetSelectedTillagePractice = {
  type: ActionType.TILLAGE_SET_SELECTED_PRACTICE;
  practice: TillagePractice;
};

export type SetTillageRangeValues = {
  type: ActionType.TILLAGE_SET_RANGE_VALUES;
  range: [number, number] | null;
};

export type SetStateIdsRequestParam = {
  type: ActionType.SET_STATE_IDS_REQUEST_PARAM;
  payload: {statesIds: Array<StateFP | SupplyShedId>; selected?: boolean};
};

export type SetSupplyShedsRequestParam = {
  type: ActionType.SET_SUPPLY_SHEDS_REQUEST_PARAM;
  payload: {supplySheds: SupplyShedId[]; selected?: boolean};
};

export type SIGeometriesRequestParams = Pick<
  SIMetricsRequestParams,
  'agg_level' | 'states' | 'counties' | 'huc8' | 'huc10' | 'huc12'
>;

export type SIGeometriesResponse = {
  geometries: Record<
    AreaId,
    {
      county_name: string;
      geometry: GeoJSON.Feature;
    }
  >;
};

// Soc/ GHG charts actions

export type SetSocFilterAction = {
  type: ActionType.SET_SOC_FILTER;
  payload: Partial<SIFilterState[SIGeometryFilterType.SOC]>;
};

export type SetGhgFilterAction = {
  type: ActionType.SET_GHG_FILTER;
  payload: Partial<SIFilterState[SIGeometryFilterType.GHG]>;
};

export type SetYieldFilterAction = {
  type: ActionType.SET_YIELD_FILTER;
  payload: Partial<SIFilterState[SIGeometryFilterType.Yield]>;
};

export type SetEmissionsFactorFilterAction = {
  type: ActionType.SET_EMISSIONS_FACTOR_FILTER;
  payload: Partial<SIFilterState[SIGeometryFilterType.EmissionsFactor]>;
};

export type SetFertilizerFilterAction = {
  type: ActionType.SET_FERTILIZERS_FILTER;
  payload: Partial<SIFilterState[SIGeometryFilterType.Fertilizer]>;
};
