import {useCallback, useEffect} from 'react';
import L from 'leaflet';
import {useWorkspace} from '_hooks/use-workspace';

export const NoFarmsOverlay = ({leafletElement}: {leafletElement: L.Map}): null => {
  const {isWorkspaceMrv} = useWorkspace();

  const positionCb = useCallback((e: any) => {
    const latLng = {lat: e.coords.latitude, lng: e.coords.longitude};

    try {
      // can throw exception if un-correct unsubscribe after unmount the component
      leafletElement.fitBounds(L.latLngBounds(latLng, latLng), {maxZoom: 20});

      if (e.coords.latitude === 0 && e.coords.longitude === 0) {
        leafletElement.setZoom(3);
      } else {
        leafletElement.setZoom(16);
      }
    } catch (e) {}
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updatePosition = useCallback(result => {
    switch (result.state) {
      case 'granted':
      case 'prompt':
        navigator.geolocation.getCurrentPosition(positionCb);

        break;
      default:
        // central park - NY
        positionCb({coords: {latitude: 0, longitude: 0}});
        break;
    }
  }, []);

  useEffect(() => {
    if (isWorkspaceMrv) {
      return;
    }

    let isSubscribed = true;

    (async () => {
      // eslint-disable-next-line compat/compat -- Not supported in older iOS, but this doesn't matter here
      const result = await navigator.permissions.query({name: 'geolocation'});

      isSubscribed && updatePosition(result);

      result.onchange = function () {
        isSubscribed && updatePosition(result);
      };
    })();

    return () => {
      isSubscribed = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isWorkspaceMrv]);

  return null;
};
