import {createSelector} from '@reduxjs/toolkit';
import type {MRVAttribute, MRVAttributeType} from 'containers/mrv/types';
import {MRVPhaseType, MRVStageType} from 'containers/mrv/types';
import {groupBy, keyBy, sortBy} from 'lodash';
import {
  selectCurrentProjectFieldMrvIdByKmlId,
  selectCurrentProjectRemovedFieldIds,
  selectCurrentProjectValues,
  selectMRVAttributes,
  selectMRVStages,
  selectPhase,
  selectPhaseStages,
  selectProjectValues,
} from './selectors';
import type {AppStore} from 'reducers';
import {getEntityId} from 'containers/mrv/value-utils';

// Current program.

export const selectEnrollment = createSelector(
  [(s: AppStore) => selectPhase(s, MRVPhaseType.Enrollment)],
  enrollment => enrollment
);

export const selectAssignPracticesStage = createSelector(
  [s => selectPhaseStages(s, MRVPhaseType.Enrollment)],
  stages => stages?.find(s => s.type_ === MRVStageType.AssignPractices)
);

export const selectAssignPracticeAttribute = createSelector(
  [selectAssignPracticesStage, selectMRVAttributes],
  (practicesStage, attributes) => {
    if (!practicesStage) return;
    return Object.values(attributes).find(attr => attr.parent_stage_id === practicesStage.id);
  }
);

export const selectHistoricPracticesStage = createSelector(
  [s => selectPhaseStages(s, MRVPhaseType.Enrollment)],
  stages => {
    return stages?.find(s => s.type_ === MRVStageType.ConfirmHistory);
  }
);

const selectCurrentProgramEnrollmentStageById = createSelector(
  [selectMRVStages, (s: AppStore, stageId: number) => stageId],
  (stages, stageId) => stages[stageId]
);

export const selectCurrentProgramEnrollmentStageAttributes = createSelector(
  [selectCurrentProgramEnrollmentStageById, selectMRVAttributes],
  (stage, attrs) => {
    const attributes = stage?.attributes.map(a => attrs[a]);
    return attributes;
  }
);

export const selectCurrentProgramEnrollmentStageAttribute = createSelector(
  [
    selectCurrentProgramEnrollmentStageById,
    selectMRVAttributes,
    (s: AppStore, stageId: number, attrType: MRVAttributeType) => attrType,
  ],
  (stage, attrs, attrType) => {
    const attrId = stage?.attributes.find(a => attrs[a].type === attrType);
    return attrId && attrs[attrId];
  }
);

const selectCurrentProjectEnrollmentValuesForStage = createSelector(
  [
    selectCurrentProjectValues,
    s => selectPhaseStages(s, MRVPhaseType.Enrollment),
    (s: AppStore, stageId: number) => stageId,
  ],
  (values, stages, stageId) => {
    const stage = stages?.find(s => s.id === stageId);
    if (!values?.length || !stage?.attributes?.length) return [];
    const attributesMap = stage.attributes.reduce<{[index: number]: boolean}>(
      (acc, id) => ({...acc, [id]: true}),
      {}
    );
    const stageValues = values.filter(value => !!attributesMap[value.attribute_id]);
    return stageValues;
  }
);

const selectCurrentProjectEnrollmentValuesGroupByField = createSelector(
  [selectCurrentProjectEnrollmentValuesForStage],
  values => groupBy(values, 'field_id')
);

export const selectCurrentProjectEnrollmentFieldValues = createSelector(
  [selectCurrentProjectEnrollmentValuesGroupByField, selectCurrentProjectFieldMrvIdByKmlId],
  (fieldValuesMap, mrvFieldId) => {
    const fieldValues = fieldValuesMap[mrvFieldId];
    if (!fieldValues?.length) return [];
    const fieldRowsMap = groupBy(fieldValues, 'row_id');
    const fieldValueRows = sortBy(
      Object.entries(fieldRowsMap).map(([rowId, values]) => ({
        rowId: parseInt(rowId, 10),
        values: keyBy(values, 'attribute_id'),
      })),
      'row_id'
    );
    return fieldValueRows;
  }
);

// Program by id.

export const selectProjectValuesByAttributeId = createSelector(
  [selectProjectValues, (s: AppStore, attrId: number) => attrId],
  (values, attrId) => {
    return Object.values(values).filter(item => item.attribute_id === attrId);
  }
);

export const selectExistingProjectValuesByAttributeId = createSelector(
  [
    selectProjectValuesByAttributeId,
    selectCurrentProjectRemovedFieldIds,
    (s: AppStore, attrId: number) => attrId,
  ],
  (values, removedFields, _attrId) => {
    return values.filter(v => v.value !== null && !removedFields[getEntityId(v)]);
  }
);

export const selectCurrentProgramEnrollmentAttributes = createSelector(
  [s => selectPhaseStages(s, MRVPhaseType.Enrollment), selectMRVAttributes],
  (stages, attrs) => {
    const attributes: MRVAttribute[] = [];
    stages?.forEach(stage => {
      stage.attributes.forEach(a => {
        attributes.push(attrs[a]);
      });
    });
    return attributes;
  }
);

export const selectCurrentProjectEnrollmentAttributeByType = createSelector(
  [
    selectCurrentProgramEnrollmentAttributes,
    (s: AppStore, attributeType: MRVAttributeType) => attributeType,
  ],
  (attributes, attributeType) => attributes?.find(a => a.type === attributeType)
);
