import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {setFeature, toggleTableView} from 'containers/map/actions';
import {toggleGlobalDialog} from 'modules/global/actions';
import type {AppStore} from 'reducers';

const NoCropSet = () => {
  const dispatch = useDispatch();

  const selectedFieldId = useSelector((s: AppStore) => s.map.selectedFieldId);
  const fields = useSelector((s: AppStore) => s.map.fields);

  const handleClick = () => {
    const isAnyCropSet = fields.length > 1 && fields.filter(f => (f.Seasons ?? []).length).length;
    dispatch(setFeature('farm'));
    if (isAnyCropSet) {
      dispatch(toggleGlobalDialog('editSeason', {visible: true, fieldId: selectedFieldId}));
    } else {
      dispatch(toggleTableView('farm'));
    }
  };

  return (
    <React.Fragment>
      Please set up your crop
      <span onClick={handleClick} className={'global-link'}>
        {' '}
        here{' '}
      </span>
      first. Once the crop is set up, we can retrieve the satellite imagery and generate the map
      layers required to use this tool.
    </React.Fragment>
  );
};
export default NoCropSet;
