import type {PayloadAction} from '@reduxjs/toolkit';
import {createSlice} from '@reduxjs/toolkit';
import {LoginActionTypes} from '../../../../login/types';
import type {MonitoringState} from '../types';

export const programsSlice = createSlice({
  name: 'monitoring/programs',
  initialState: {
    loaded: {page: 0, total: 0, size: 0},
    info: {assets: [] as string[], practice_changes: [] as string[]}, // info contains lists of options for dropdowns
    attributeLabels: {}, // {attributeType: attributeLabel}
  } as MonitoringState['programs'],
  reducers: {
    setLoaded: (state, action: PayloadAction<{page: number; total: number; size: number}>) => {
      state.loaded = action.payload;
    },

    addInfoAssets: (state, action: PayloadAction<{infoAssets: string[]}>) => {
      state.info.assets = action.payload.infoAssets;
    },

    addInfoPracticeChanges: (state, action: PayloadAction<{infoPracticeChanges: string[]}>) => {
      state.info.practice_changes = action.payload.infoPracticeChanges;
    },
    addAttributeLabels: (
      state,
      action: PayloadAction<{attributeLabels: {[attributeType: string]: string}}>
    ) => {
      state.attributeLabels = action.payload.attributeLabels;
    },
  },
  extraReducers: builder => {
    builder.addCase(LoginActionTypes.LOGOUT, state => {
      return {
        ...state,
        info: {assets: [], practice_changes: []},
        loaded: {page: 0, total: 0, size: 0},
      };
    });
  },
});
