import styled from 'styled-components';
import {main_gray_600} from '_utils/colors';
type Props = {
  nowrap?: boolean;
};

export const TableSubtext = styled.div`
  color: ${main_gray_600};
  font-size: 12px;
  white-space: ${(p: Props) => (p.nowrap ? 'nowrap' : 'normal')};
`;
