import React from 'react';
import type {Feature} from 'geojson';
import {main_gray_600} from '_utils/colors';
import type {MonitoringFieldGeometry} from 'containers/mrv/types';
import {GeometryPreview} from 'components';

export const MRVFieldPreview = ({geometry}: {geometry: MonitoringFieldGeometry}) =>
  !geometry ? null : (
    <GeometryPreview
      onEachFeature={onEachFeature}
      style={style}
      data={geometry}
      size={previewSize}
    />
  );

const style = {backgroundColor: 'transparent'};
const previewSize: [number, number] = [40, 40];

const onEachFeature = (feature: Feature, layer: L.Path) => {
  layer.setStyle({
    color: main_gray_600,
    fillColor: main_gray_600,
    fillOpacity: 0.2,
    weight: 1,
  });
};
