import styled from 'styled-components';
import colors from 'variables.scss';

type InputWrapperProps = {
  error?: boolean;
  maxHeight: number;
};

export const InputWrapper = styled.div`
  position: relative;
  margin-top: 18px;

  .label {
    color: ${colors['main-gray-600']};
    box-sizing: border-box;
    margin-bottom: 4px;
    display: block;
    font-size: 12px;
    line-height: 1;
    text-overflow: ellipsis;
    text-align: left;
    user-select: none;
    white-space: nowrap;
    width: 100%;
  }

  .textarea {
    color: ${colors['main-gray-900']};
    background-color: #fff;
    border: 1px solid ${(p: InputWrapperProps) => (p.error ? 'red' : '#f1f1f1')};
    max-height: ${(p: InputWrapperProps) => p.maxHeight + 'px'};
    border-radius: 4px;
    font-size: 14px;
    padding: 13px 10px;
    transition: all 0.2s ease-out;
    width: 100%;
    max-width: 100%;
    &:hover,
    &:focus {
      outline: 0;
      border-color: ${colors['main-gray200']};
    }
  }
`;
