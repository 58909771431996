import {t} from 'i18n-utils';
import type {ComponentType} from 'react';
import React from 'react';
import {InputSelect} from './input-select';
import type {onValueChange} from './types';

type Props = {onChange: onValueChange};

export const TillagePeriodSelect: ComponentType<Props> = ({onChange}) => {
  const options = [
    {value: 'fall', label: t({id: 'TillagePeriodSelect.Fall', defaultMessage: 'Fall'})},
    {value: 'spring', label: t({id: 'TillagePeriodSelect.Spring', defaultMessage: 'Spring'})},
  ];

  return (
    <InputSelect
      testId="MRV-table-cell-input-select--Tillage period"
      options={options}
      onChange={onChange}
    />
  );
};
