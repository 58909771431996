import {t} from 'i18n-utils';
import React, {memo} from 'react';
import {setOpacity} from '_utils/colors';
import type {CropGrowth} from '../types';
import {cropGrowthColors, cropGrowthLabels} from '../types';

type Props = {
  value: CropGrowth;
  predicted?: boolean;
};

export default memo(({value, predicted}: Props) => {
  const color = cropGrowthColors[value];
  return (
    <div
      className={`cp__crop-growth cp__crop-growth--${value}`}
      style={{
        color,
        background: predicted ? '' : setOpacity(color, 0.1, true),
        border: predicted ? `1px dashed ${color}` : '',
      }}
    >
      {t({id: cropGrowthLabels[value]}) || ''}
    </div>
  );
});
