// @ts-nocheck
import {FormattedMessage, t} from 'i18n-utils';
import React, {useEffect, useState} from 'react';
import {sensorView} from '_utils';
import {SelectField} from 'react-md';
import {prepareMinArea} from '../../utils';
import {setTreeZoneParam, setZoningUnits, updateZoningZone} from '../../actions/zoning-actions';
import {Sticky, FluroButton} from 'components';
import type {Zone} from '../../types';
import {ZoneType} from '../../types';
import cn from 'classnames';
import {measurementUnits} from 'containers/map/features/nrx';
import SimplePreloader, {HideWhileLoading} from 'components/simple-preloader';
import {AsyncStatusType} from 'modules/helpers';
import {useAppDispatch, useAppSelector} from '_hooks';
import {
  selectCurrentSensor,
  selectTreeDetectionLayerType,
  selectWholeFarmData,
  selectZoning,
} from '../../reducer/selectors';
import ExportZoning from './export-zoning';
import {selectMeasurement} from 'containers/login/login-selectors';

import {ZoneElement} from './zoning-zones';
import {TreesAndProductAmount} from 'containers/map/features/zoning/trees/trees-and-product-amount';

const Zones = () => {
  const dispatch = useAppDispatch();
  const zoning = useAppSelector(selectZoning);
  const measurement = useAppSelector(selectMeasurement);
  const currentSensor = useAppSelector(selectCurrentSensor);
  const layerType = useAppSelector(selectTreeDetectionLayerType);
  const wholeFarm = useAppSelector(selectWholeFarmData);
  const [editZonesNameEnabled, toggleEditZonesName] = useState(false);

  useEffect(() => {
    if (!zoning.currentUnits) {
      dispatch(setZoningUnits(measurementUnits()[measurement][0]));
    }
  }, []);

  const setZoneParam = (prop: string, id: number, value: any, isTree = false) => {
    if (prop === 'value' && !isFinite(value)) return;
    if (isTree) return dispatch(setTreeZoneParam(id, {[prop]: value}));

    dispatch(updateZoningZone(id, prop, value));
  };

  const changeCurrentUnits = (val: string) => {
    const [currentUnits] = measurementUnits()[measurement].filter(({value}: any) => value === val);
    dispatch(setZoningUnits(currentUnits));
  };

  // const getWholeFarmTreeZones = () => {
  //   let zones: Zone[] = [];

  // Object.keys(wholeFarm.treeZoning.fields).forEach(fieldMD5 => {
  //   const currentLoopField = wholeFarm.treeZoning.fields[fieldMD5];
  //   if (currentLoopField.zones?.length && currentLoopField.selected !== false) {
  //     if (!zones.length) {
  //       zones = currentLoopField.zones.map(z => z);
  //     } else {
  //       zones = zones.map((zone, index) => ({
  //         ...zone,
  //         number: zone.number + currentLoopField.zones[index].number,
  //       }));
  //     }
  //   }
  // });
  // return zones;
  // };

  const minArea = prepareMinArea(zoning.area);
  const isTreeAnalysis = layerType !== 'default';

  let preparedZones: Zone[] = [];
  if (wholeFarm.isWholeFarmView) {
    // preparedZones = getWholeFarmTreeZones();
    preparedZones = wholeFarm.treeZoning.zones;
  } else {
    preparedZones = isTreeAnalysis ? zoning.treeZones : [...zoning.zones].reverse();
  }

  const zones: Zone[] = preparedZones.filter(({area, type}) => type === ZoneType.Tree || area > 0);
  const zonesLength = zones.length;
  const zonesFitSize = isTreeAnalysis ? zones : zones.filter(z => z.area >= minArea);

  const zonesSmallSize = zones.filter(z => z.area < minArea);
  const diffZone = preparedZones.length - zones.length;

  return (
    <div className="zones-container element-full-width">
      <h3 className={'zones-title'}>
        {t({id: 'Management zones'})}
        <SimplePreloader
          statusKeys={[
            AsyncStatusType.mainZoning,
            AsyncStatusType.zoningImageOverlay,
            AsyncStatusType.NRxRecommendation,
            AsyncStatusType.NRxListsData,
            AsyncStatusType.treeDetectionZoning,
          ]}
        />
      </h3>

      <div className="zoning-statistics">
        {zonesLength !== 0 ? (
          <>
            <div className="zone-list-header">
              <div className={cn('zone-list-header__name')}>
                {t({id: 'Zone name'})}
                <FluroButton
                  id={'toggle-edit-zones-name'}
                  className={`toggle-edit-zones-name-btn ${editZonesNameEnabled ? 'active' : ''}`}
                  icon
                  onClick={() => toggleEditZonesName(!editZonesNameEnabled)}
                >
                  edit
                </FluroButton>
              </div>
              <div className={'zone-list-header__area'}>
                {isTreeAnalysis ? (
                  <>
                    <FormattedMessage id="Number <br></br> of trees" values={{br: () => <br />}} />
                  </>
                ) : (
                  <>
                    {t({id: 'Area'})} <br />
                    {/*<span className={'units'}>{t({id: measurement})}</span>*/}
                  </>
                )}
              </div>

              {/*current sensor value*/}
              <div className={'zone-list-header__layer-value'}>{sensorView(currentSensor)}</div>

              <div className={cn('zone-list-header__rx')}>
                {t({id: 'Rx'})}

                <HideWhileLoading
                  statusKeys={[AsyncStatusType.mainZoning, AsyncStatusType.zoningImageOverlay]}
                  content={isLoading => (
                    <div className="unit-select">
                      <SelectField
                        id="units-selector"
                        className={'units-selector'}
                        label=""
                        menuItems={measurementUnits()[measurement]}
                        value={zoning.currentUnits?.value}
                        onChange={(value: string) => changeCurrentUnits(value)}
                        position={'br'}
                        disabled={isLoading}
                      />
                    </div>
                  )}
                />
              </div>
            </div>
            {zonesFitSize.map((zone, index) => (
              <ZoneElement
                key={zone.id}
                index={index + 1}
                zone={zone}
                isTreeAnalysis={isTreeAnalysis}
                editable={editZonesNameEnabled}
                setZoneParam={setZoneParam}
                measurement={measurement}
              />
            ))}
            {zonesSmallSize.length !== 0 ? (
              <React.Fragment>
                <h4>{t({id: 'Zones too small'})}:</h4>
                {zonesSmallSize.map((zone, index) => (
                  <ZoneElement
                    key={zone.id}
                    index={index + 1}
                    zone={zone}
                    isTreeAnalysis={isTreeAnalysis}
                    editable={editZonesNameEnabled}
                    setZoneParam={setZoneParam}
                    measurement={measurement}
                  />
                ))}
              </React.Fragment>
            ) : null}
            {diffZone ? (
              <span className="zoning-diff">
                {t(
                  {id: 'The selected zoning method allows a maximum of zones'},
                  {count: zones.length}
                )}
              </span>
            ) : null}

            <TreesAndProductAmount zones={[...zonesFitSize, ...zonesSmallSize]} />
          </>
        ) : null}
      </div>

      <Sticky className={cn({'zoning-sticky-btns-container': true})}>
        <ExportZoning />
      </Sticky>
    </div>
  );
};

export default Zones;
