import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {Flex, FluroAutocomplete, FluroButton, FluroDialog} from 'components';
import type {ComponentType} from 'react';
import {useForm} from 'react-hook-form';
import type {AddSIUserToAProgramFormData} from 'containers/si/types';
import {SIProgramChip} from './si-program-chip';
import {TextField} from 'react-md';
import {yupResolver} from '@hookform/resolvers/yup';
import Yup from 'yup';
import {useAppDispatch, useAppSelector} from '_hooks';
import {selectIsAdmin} from 'containers/login/login-selectors';
import {selectSIProgramsList} from 'containers/si/module/selectors';
import {selectUsersList} from 'containers/admin/users/users-selectors';
import {isEmptyArray} from '_utils/typeGuards';
import {getUsersList} from 'containers/admin/users/actions';
import Lo from 'lodash';

const validationSchema = Yup.object().shape({
  programs: Yup.array().min(1),
  email: Yup.string().email().required(),
  first_name: Yup.string(),
  last_name: Yup.string(),
});

type Props = {
  title: string;
  onHide: () => void;
  onAdd: (formData: AddSIUserToAProgramFormData) => void;
  defaultProgramId?: number;
  userToAddType: 'user' | 'admin';
};

export const AddUserToSIProgramPopUp: ComponentType<Props> = ({
  onHide,
  onAdd,
  title,
  defaultProgramId,
}) => {
  const dispatch = useAppDispatch();
  const usersList = useAppSelector(selectUsersList);

  const programs = useAppSelector(selectSIProgramsList);
  const isAdmin = useAppSelector(selectIsAdmin);
  const [disableNameInputs, setDisableNameInputs] = useState(false);

  useEffect(() => {
    if (isAdmin && isEmptyArray(usersList)) {
      dispatch(getUsersList());
    }
  }, [dispatch, isAdmin, usersList]);

  const {usersMenuList, usersRecordById} = useMemo(() => {
    // We do not want to allow adding users without SI access
    const filteredUsersList = usersList.filter(user => user.workspaces?.si === true);
    const byId = Lo.keyBy(filteredUsersList, 'id');
    const menu = filteredUsersList.map(u => ({label: u.email, value: u.id as number}));

    return {usersMenuList: menu, usersRecordById: byId};
  }, [usersList]);

  const {
    handleSubmit,
    watch,
    register,
    setValue,
    formState: {errors},
  } = useForm<AddSIUserToAProgramFormData>({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      user_id: 0,
      programs: [],
      email: '',
      first_name: '',
      last_name: '',
    },
  });

  useEffect(() => {
    register('first_name');
    register('last_name');
    register('email');
    register('programs');
    register('user_id'); // temporary prop will get rid of it once back-end will accept email
  }, [register]);

  const values = watch();

  useEffect(() => {
    if (defaultProgramId) {
      setValue('programs', [defaultProgramId]);
    }
  }, [defaultProgramId, setValue]);

  const programsMenuList = useMemo(() => {
    return programs
      .filter(({id}) => !values.programs.includes(id)) // filter already added programs
      .map(program => ({label: program.name, value: program.id}));
  }, [programs, values.programs]);

  const onSelectProgram = useCallback(
    (id: number) => {
      setValue('programs', [...values.programs, id]);
    },
    [setValue, values.programs]
  );

  const onRemoveProgram = useCallback(
    (id: number) => {
      setValue(
        'programs',
        values.programs.filter(existingId => existingId !== id)
      );
    },
    [setValue, values.programs]
  );

  const onTypeUserEmail = (email: string) => {
    setValue('email', email);
    setValue('first_name', '');
    setValue('last_name', '');
    setValue('user_id', 0);
    setDisableNameInputs(false);
  };

  const onSelectUser = (userId: number) => {
    const {name, surname, email} = usersRecordById[userId];
    setValue('email', email);
    setValue('first_name', name);
    setValue('last_name', surname);
    setValue('user_id', userId);
    setDisableNameInputs(true);
  };

  const onSubmit = (data: AddSIUserToAProgramFormData) => {
    onAdd(data);
  };

  return (
    <FluroDialog id={'add-si-user-popup'} title={title} onHide={onHide} visible={true} width={400}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FluroAutocomplete
          id={'select-si-program'}
          label={'Program access'}
          name={'select-si-program'}
          placeholder={'Select program'}
          menuItems={programsMenuList}
          value={''}
          onAutocomplete={programId => onSelectProgram(Number(programId))}
          error={!!errors.programs}
          errorText={'Program is required'}
        />

        {values.programs.length !== 0 && (
          <Flex gap={'10px'}>
            {values.programs.map(id => (
              <SIProgramChip key={id} active onRemove={() => onRemoveProgram(id)} programId={id} />
            ))}
          </Flex>
        )}

        {isAdmin ? (
          <FluroAutocomplete
            id={'select-si-user'}
            label={'Email address'}
            placeholder={'Type email address'}
            name={'select-si-user'}
            menuItems={usersMenuList}
            shouldNotResetOnFocus={Boolean(!values.user_id && values.email)}
            onChange={value => onTypeUserEmail(String(value))}
            value={values.email || ''}
            onAutocomplete={user_id => onSelectUser(Number(user_id))}
            error={!!errors.email}
            errorText={errors.email?.message}
          />
        ) : (
          <TextField
            id="email"
            value={values.email || ''}
            label={'Email address'}
            onChange={value => setValue('email', String(value))}
            disabled={disableNameInputs}
            error={!!errors.email}
            errorText={errors.email?.message}
          />
        )}

        {!values.user_id && (
          <>
            <TextField
              id="first-name"
              value={values.first_name}
              label="First name"
              error={!!errors.first_name}
              errorText={errors.first_name?.message}
              onChange={value => setValue('first_name', String(value))}
              disabled={disableNameInputs}
            />

            <TextField
              id="last-name"
              value={values.last_name}
              label="Last name"
              error={!!errors.last_name}
              errorText={errors.last_name?.message}
              onChange={value => setValue('last_name', String(value))}
              disabled={disableNameInputs}
            />
          </>
        )}
        <Flex className={'margin-top-10'} fullWidth justifyContent={'flex-end'}>
          <FluroButton primary raised onClick={handleSubmit(onSubmit)}>
            {title}
          </FluroButton>
        </Flex>
      </form>
    </FluroDialog>
  );
};
