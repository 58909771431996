import cottonDialogs from './cotton';
import cerealDialogs from './cereal';
import cornDialogs from './corn';
import teeFarmsDialogs from './tree-farms';
import berriesDialogs from './berries';
import potatoesDialogs from './potatoes';
import debugDialogs from './debug';
import type {Messages} from '../types';

const dialogs: Messages = {
  ...cornDialogs,
  ...cerealDialogs,
  ...cottonDialogs,
  ...teeFarmsDialogs,
  ...berriesDialogs,
  ...potatoesDialogs,
  ...debugDialogs,
};

export default dialogs;
