import React, {useEffect, useRef, useState} from 'react';
import {DialogContentWrapper} from '../admin/logs/email/email-logs.styled';
import {FluroDialog} from '../../components';
import {useAppDispatch, useAppSelector} from '../../_hooks';
import {dialogToggle} from '../../modules/helpers';
import {DialogType} from '../../modules/helpers/types';
import {selectDialogVisibility} from '../../modules/helpers/selectors';
//@ts-expect-error error leftover from convertion to strict mode, please fix
import Script from 'react-load-script';

export const RequestAccessDialog = () => {
  const containerRef = useRef(null);
  const dispatch = useAppDispatch();
  const visible = useAppSelector(s => selectDialogVisibility(s, DialogType.requestFSAccess));
  const [isScriptLoading, setIsScriptLoading] = useState(true);

  const handleScriptLoad = () => setIsScriptLoading(false);

  useEffect(() => {
    // @ts-expect-error error leftover from convertion to strict mode, please fix
    if (window.hbspt && visible && containerRef.current && !isScriptLoading) {
      // @ts-expect-error error leftover from convertion to strict mode, please fix
      window.hbspt.forms.create({
        portalId: '4430549',
        formId: 'f0a90902-8df2-4142-b7a7-c9e7a2f9d177',
        target: '#rq-form-container',
      });
    }
  }, [isScriptLoading, visible, containerRef.current]);

  return (
    <>
      <Script url="//js.hsforms.net/forms/shell.js" onLoad={handleScriptLoad} />
      <FluroDialog
        id="request-fs-access-dialog"
        visible={visible}
        width={500}
        onHide={() => dispatch(dialogToggle(DialogType.requestFSAccess, false))}
        dialogClassName="fix-dialog-zoom-issue"
      >
        <DialogContentWrapper excludedHeight={50}>
          <div id={'rq-form-container'} ref={containerRef} />
        </DialogContentWrapper>
      </FluroDialog>
    </>
  );
};
