import {Flex, FluroTabs, Text} from 'components';
import {CardBase} from 'components/card/card-base';
import {setChartTab, setFilter} from 'modules/sustainability-insights/actions';
import {selectIsSIDataLoading, selectIsYearsDiff} from 'modules/sustainability-insights/selectors';
import {selectSocChartTab} from 'modules/sustainability-insights/selectors/soc';
import type {SIChartTab} from 'modules/sustainability-insights/types';
import {SIGeometryFilterType} from 'modules/sustainability-insights/types';
import type {ComponentType} from 'react';
import React, {useEffect} from 'react';
import {useAppDispatch, useAppSelector} from '_hooks';
import {YearsFilterRangeLabel} from 'containers/map/features/sustainability-insights/years-filter-range-label';
import {SocSummaryChart} from './soc-summary-chart';
import {SocTrendChart} from './soc-trend-chart';
import {CardToolTip} from '../components/card-tooltip';
import {LearnMore} from '../components/learn-more';
import {TOOLTIPS} from 'containers/si/constants';

type Props = {
  isActive?: boolean;
};

const InnerSocCard = () => {
  const dispatch = useAppDispatch();
  const selectedTab = useAppSelector(selectSocChartTab);
  const isRange = useAppSelector(selectIsYearsDiff);

  const setSelectedTab = (tab: SIChartTab) => {
    dispatch(setChartTab(SIGeometryFilterType.SOC, tab));
  };

  useEffect(() => {
    if (!isRange && selectedTab === 'trend') {
      setSelectedTab('summary');
    }
  }, [isRange, selectedTab]);

  const tabs = [
    {value: 'summary', label: 'Summary'},
    {value: 'trend', label: 'Trend'},
  ];

  return (
    <>
      {isRange && (
        <div style={{marginLeft: '-1rem', marginRight: '-1rem'}}>
          <FluroTabs tabs={tabs} selectedTab={selectedTab} onTabClick={setSelectedTab} />
        </div>
      )}
      <div>
        {selectedTab === 'summary' && <SocSummaryChart />}
        {selectedTab === 'trend' && <SocTrendChart />}
      </div>
      <div className="margin-top-10">
        <LearnMore articleId={6479120} />
      </div>
    </>
  );
};

export const SocCard: ComponentType<Props> = ({isActive = false}) => {
  const dispatch = useAppDispatch();
  const isLoading = useAppSelector(selectIsSIDataLoading);

  const onFilterClick = () => {
    if (isActive || isLoading) return;
    dispatch(
      setFilter({
        activeGeometryType: SIGeometryFilterType.SOC,
      })
    );
  };

  return (
    <CardBase
      active={isActive}
      className={'sustainability-card soc-card'}
      heading={
        <Flex nowrap justifyContent="space-between">
          <Text className="m-0" elementType="h3">
            dSOC sequestration <YearsFilterRangeLabel />
          </Text>
          <CardToolTip id="dsoc-card-info" content={TOOLTIPS.KPI.soc.card} place="left" />
        </Flex>
      }
      onClick={onFilterClick}
    >
      {isActive && <InnerSocCard />}
    </CardBase>
  );
};
