// @ts-nocheck
import type {Dispatch} from '@reduxjs/toolkit';
import {createAsyncThunk} from '@reduxjs/toolkit';
import {reportError} from 'containers/error-boundary';
import xor from 'lodash/xor';
import type {NormalizedSchema} from 'normalizr';
import {normalize} from 'normalizr';
import type {AppStore} from 'reducers';
import type {NormalizedSchemaPayloadAction} from 'types';
import {SustainabilityInsightsAPI} from '_api/sustainability-insights';
import {setFilterYears, setStateIdsRequestParam} from './actions';
import {METRIC_GROUPS, SI_AREA_AGG_AVAILABLE, SI_YEARS_AVAILABLE} from './constants';
import {StatesResponseSchema} from './normalize-schemas';
import {selectStateIdsRequestParam} from './selectors';
import type {
  CountyFIPS,
  SIAggLevel,
  SIAreaMeta,
  SIDataAggState,
  SIGeometriesRequestParams,
  SIGeometriesResponse,
  SIMetricGroup,
  SIMetricsRequestParams,
  StateFP,
  SummerCropType,
  SummerCropTypesResponse,
} from './types';
import {ActionType} from './types';
import {parseAreaId} from './utils';

export const fetchMetrics = createAsyncThunk<SIDataAggState['metrics'], SIMetricsRequestParams>(
  ActionType.FETCH_METRICS,
  async (params: SIMetricsRequestParams): Promise<SIDataAggState['metrics']> => {
    const response = await SustainabilityInsightsAPI.getSiMetrics(params);

    return response.data;
  }
);

export const getUserPolicy = createAsyncThunk<
  NormalizedSchema<
    Record<SIAggLevel, SIAreaMeta>,
    {years: number[]; metric_groups: SIMetricGroup[]; agg_levels: SIAggLevel[]}
  >
>(ActionType.FETCH_USER_POLICY, async (_, thunkApi) => {
  const response = await SustainabilityInsightsAPI.getUserPolicy();
  const data = normalize<
    SIAreaMeta,
    Record<SIAggLevel, SIAreaMeta>,
    {years: number[]; metric_groups: SIMetricGroup[]; agg_levels: SIAggLevel[]}
  >(response.data.result, StatesResponseSchema);
  const {years} = data.result;
  const latestYear = years[years.length - 1];
  thunkApi.dispatch(setFilterYears([latestYear])); // set years to filter
  return data;
});

const normalizeSummerCropTypes = (
  response: SummerCropTypesResponse
): Record<number, SummerCropType> => {
  const {crop_types} = response;
  if (!crop_types) return {};
  const result = crop_types.reduce<Record<number, SummerCropType>>(
    (acc, type) => ({...acc, [type.summer_crop_type]: type}),
    {}
  );

  return result;
};

export const fetchSummerCropTypes = createAsyncThunk<Record<number, SummerCropType>>(
  ActionType.FETCH_SUMMER_CROP_TYPES,
  async () => {
    const response = await SustainabilityInsightsAPI.getSummerCropTypes();
    const data = normalizeSummerCropTypes(response.data);

    return data;
  }
);

export const fetchStatesList = createAsyncThunk<
  NormalizedSchema<
    {
      state: Record<StateFP, SIAreaMeta>;
    },
    {states: number[]}
  >
>(ActionType.FETCH_STATES_LIST, async () => {
  const response = await SustainabilityInsightsAPI.getStates();
  const normalized = normalize<
    typeof response.data,
    {
      state: Record<StateFP, SIAreaMeta>;
    },
    {states: number[]}
  >(response.data, StatesResponseSchema);

  return normalized;
});

export const fetchCountiesList = createAsyncThunk(ActionType.FETCH_COUNTIES_LIST, async () => {
  const response = await SustainabilityInsightsAPI.getCounties();
  const normalized = normalize<
    typeof response.data,
    {
      state: Record<StateFP, SIAreaMeta>;
      county: Record<CountyFIPS, SIAreaMeta>;
    }
  >(response.data, StatesResponseSchema);

  return normalized;
});

export const fetchCRDList = createAsyncThunk(ActionType.FETCH_CRD_LIST, async () => {
  const response = await SustainabilityInsightsAPI.getCRDList();
  const normalized = normalize(response.data, StatesResponseSchema);

  return normalized;
});

export const fetchHUC8List = createAsyncThunk(ActionType.FETCH_HUC8_LIST, async () => {
  const response = await SustainabilityInsightsAPI.getHUC8List();
  const normalized = normalize(response.data, StatesResponseSchema);

  return normalized;
});

export const fetchHUC10List = createAsyncThunk(ActionType.FETCH_HUC10_LIST, async () => {
  const response = await SustainabilityInsightsAPI.getHUC10List();
  const normalized = normalize(response.data, StatesResponseSchema);

  return normalized;
});

export const fetchHUC12List = createAsyncThunk(ActionType.FETCH_HUC12_LIST, async () => {
  const response = await SustainabilityInsightsAPI.getHUC12List();
  const normalized = normalize(response.data, StatesResponseSchema);

  return normalized;
});

export const fetchAvailableArea = createAsyncThunk(
  ActionType.FETCH_AVAILABLE_AREA,
  async (_, {dispatch}) => {
    try {
      const state = await dispatch(fetchStatesList());

      const statePayload = (state as NormalizedSchemaPayloadAction).payload;
      statePayload.result = {
        ...statePayload.result,
        years: SI_YEARS_AVAILABLE,
        metric_groups: METRIC_GROUPS,
        agg_levels: SI_AREA_AGG_AVAILABLE,
      };

      const policyAction = {
        type: getUserPolicy.fulfilled.type,
        payload: {
          entities: {
            state: statePayload.entities.state,
            crd: {},
            county: {},
            huc8: {},
            huc10: {},
            huc12: {},
          },
          result: statePayload.result,
        },
      };

      dispatch(policyAction);

      const latestYear = SI_YEARS_AVAILABLE[SI_YEARS_AVAILABLE.length - 1];
      dispatch(setFilterYears([latestYear]));

      const [county, crd, huc8, huc10, huc12] = await Promise.all([
        dispatch(fetchCountiesList()),
        dispatch(fetchCRDList()),
        dispatch(fetchHUC8List()),
        dispatch(fetchHUC10List()),
        dispatch(fetchHUC12List()),
      ]);

      const crdPayload = (crd as NormalizedSchemaPayloadAction).payload;
      const countyPayload = (county as NormalizedSchemaPayloadAction).payload;
      const huc8Payload = (huc8 as NormalizedSchemaPayloadAction).payload;
      const huc10Payload = (huc10 as NormalizedSchemaPayloadAction).payload;
      const huc12Payload = (huc12 as NormalizedSchemaPayloadAction).payload;

      const fullStateEntities2 = Object.keys(statePayload.entities.state).reduce((acc, key) => {
        return {
          ...acc,
          [key]: {
            ...statePayload.entities.state[key],
            county: countyPayload.entities.state[key]?.county || [],
            crd: crdPayload.entities.state[key]?.crd || [],
            huc8: huc8Payload.entities.state[key]?.huc8 || [],
            huc10: huc10Payload.entities.state[key]?.huc10 || [],
            huc12: huc12Payload.entities.state[key]?.huc12 || [],
          },
        };
      }, {});

      const policyAction2 = {
        type: getUserPolicy.fulfilled.type,
        payload: {
          entities: {
            state: fullStateEntities2,
            crd: crdPayload.entities.crd,
            county: countyPayload.entities.county,
            huc8: huc8Payload.entities.huc8,
            huc10: huc10Payload.entities.huc10,
            huc12: huc12Payload.entities.huc12,
          },
          result: statePayload.result,
        },
      };

      dispatch(policyAction2);
    } catch (error) {
      reportError(error);
    }
  }
);

const normalizeGeometriesResponse = (data: SIGeometriesResponse): SIDataAggState['geometries'] => {
  const {geometries} = data;
  const normalizedGeometries = Object.keys(geometries).reduce((acc, key) => {
    const id = parseAreaId(key);
    const geometry = geometries[id]?.geometry;

    return {
      ...acc,
      [id]: geometry,
    };
  }, {});

  return normalizedGeometries;
};

export const fetchGeometries = createAsyncThunk(
  ActionType.FETCH_GEOMETRIES,
  async (params: SIGeometriesRequestParams) => {
    const response = await SustainabilityInsightsAPI.getGeometries(params);
    const geometries = normalizeGeometriesResponse(response.data);

    return {geometries};
  }
);

export const setStateIdsRequestParamThunk =
  (id: number, multiselect: boolean) => (dispatch: Dispatch, getState: () => AppStore) => {
    const state = getState();
    const stateIdsRequestParam = selectStateIdsRequestParam(state);
    dispatch(setStateIdsRequestParam(multiselect ? xor(stateIdsRequestParam, [id]) : [id]));
  };
