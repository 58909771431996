// @ts-nocheck
import {MAPPING_ROLES_TO_PROPS, COLORS, ANOMALY_LABELS, SOURCES} from '_constants';
import {hackGetState} from '../store';
import Yup from 'yup';
import convert from 'convert-units';
import type {Moment} from 'moment';
import moment from 'moment';
import {kiloFormatter, toFixedFloatUnsafe} from './number-formatters';

import {pathToRegexp} from 'path-to-regexp';
import {booleanOverlap, booleanContains, lineToPolygon} from '@turf/turf';

import type {
  Field,
  TInfoExt,
  SourceMeta,
  TDateLayer,
  Farm,
  SourceType,
  IterisTemperatureDay,
} from 'containers/map/types';
import {GeoJsonType} from 'containers/map/types';

import type {TSensor} from 'types';
import type {IAnomaly} from 'containers/map/features/anomalies/types';
import type L from 'leaflet';
import config, {CI_ROOT_PATH} from '_environment';

import {capitalizeFirstLetter, getGetURLParam, setGetParamToURL, sortDates} from './pure-utils';
import {DEFAULT_LOCALE, t} from 'i18n-utils';
import type {Measurement} from 'containers/login/types';
import {reportError} from 'containers/error-boundary';
import {safeLocalStorage} from './safe-local-storage';
import {sortByKey, sortByKey2} from '_utils/sorters';

export const FeetToMeter = (ft: number) => {
  return ft === 0 ? 0 : (ft / 3.2808).toFixed(2);
};

export const isAdminPerm = (perm: number) => {
  return Boolean(MAPPING_ROLES_TO_PROPS[perm] && MAPPING_ROLES_TO_PROPS[perm].prop === 'admin');
};

export function debounce(func: any, wait: number, immediate: boolean) {
  let timeout: any;
  return function (...args) {
    const later = function () {
      timeout = null;
      if (!immediate) func.apply(this, args);
    };
    const callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(this, args);
  };
}

export const convertTileDateToNormal = (str: string, serverFormat = false) =>
  moment(str).format(serverFormat ? 'DD/MM/YYYY' : formatDate());

/**
 * @deprecated
 * Type unsafe version of naturalSortAlphaNum.
 * To get rid of it need to refactor all the places that call sortFieldsByProp.
 */
function naturalSortAlphaNumDEPRECATED(array: any[], key: string | number) {
  return array.sort((a, b) =>
    a[key]?.localeCompare(b[key], undefined, {numeric: true, sensitivity: 'base'})
  );
}

export function sortByDateKey(array: Array<any>, key: string | number, descent = false) {
  return array.sort(function (a, b) {
    if (!a[key]) return -1;
    if (!b[key]) return 1;
    if (moment(a[key]).isBefore(moment(b[key]))) return descent ? 1 : -1;
    if (moment(a[key]).isAfter(moment(b[key]))) return descent ? -1 : 1;
    return 0;
  });
}

export function sortFieldsByProp(
  fields: Field[],
  byProp: keyof Field,
  sortType: number | string
): Field[] {
  switch (sortType) {
    case 'date': {
      return sortByDateKey(fields, byProp);
    }
    case 'string': {
      return [...naturalSortAlphaNumDEPRECATED(fields, byProp)];
    }
    case 'number': {
      return sortByKey(fields, byProp);
    }

    default:
      return fields;
  }
}

export function guid() {
  function s4() {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  }

  return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
}

export function equalTo(ref: any, msg: string) {
  return this.test({
    name: 'equalTo',
    exclusive: false,
    message: msg || `${ref.path} must be the same as ${ref.key}`,
    params: {
      reference: ref.path,
    },
    test: function (value: any) {
      return value === this.resolve(ref);
    },
  });
}

export function classifyAreaWithUnits(area: any, measurement: Measurement) {
  return `${kiloFormatter(convertUnit(measurement, 'ac', area || 0))} ${t({id: measurement})}`;
}

// date formats
export const formats: {[key: string]: string} = {
  // "en-AU": "DD/MM/YYYY",
  // "ru-RU": "DD.MM.YYYY",
  'en-US': 'MM/DD/YYYY',
  'uk-UA': 'DD MMM YYYY',
};

export function getLocaleDateFormat(): string {
  const state: any = hackGetState();
  return formats[state.login.user.settings.locale] || 'DD MMM YYYY';
}

export function formatDate() {
  return getLocaleDateFormat();
}

export const formatLocaleNumber = (value: number, locale?: string) => {
  try {
    //TODO: made NumberFormat param variable
    return Intl.NumberFormat(locale || DEFAULT_LOCALE).format(value);
  } catch (e) {
    return value;
  }
};

export function download(filename: string, data: any, isImage = false) {
  const element = document.createElement('a');
  const href = isImage ? data : 'data:text/plain;charset=utf-8,' + encodeURIComponent(data);
  element.setAttribute('href', href);
  element.setAttribute('download', filename);

  element.style.display = 'none';
  document.body.appendChild(element);

  element.click();

  document.body.removeChild(element);
}

// convert to square meters
export const convertFromMesureToSquareMeters = (value: number, measure = 'ha') => {
  switch (measure) {
    case 'ha':
      return value * 10000;

    case 'ac':
      return value * 4046.86;

    default:
      reportError('Unknown measure at convertFromMesureToSquareMeters func');
      return 0;
  }
};

// convert from square meters to mesure
export const convertFromSquareMetersToMeasure = (value: number, measure: string = 'ha') => {
  switch (measure) {
    case 'ha':
      return value / 10000;

    case 'ac':
      return value / 4046.86;

    default:
      return 0;
  }
};

export const deepCopy = <T>(value: T): T => JSON.parse(JSON.stringify(value));

export const parseNumber = (n: any) => {
  if (n === null || n === undefined) return 0;
  n = n + ''.trim();
  return isFinite(n) ? (/\.{1}/.test(n) ? parseFloat(n) : n === '' ? n : parseInt(n, 10)) : n;
};

/**
 * Normalizes the value to the `range`, given that value is of the range 0..255.
 *
 * value  range  result
 * 0..255,  0 ->  0..1
 * 0..255, -1 -> -1..1
 *
 * @param range The beginning of the range: 0..1 or -1..1. Could be [0|-1]
 */
export const normalizeSensorIndex = (value: number, range: number, roundDigits = 2) => {
  switch (range) {
    case 0:
      return toFixedFloatUnsafe(value / 255, roundDigits);

    case -1:
      return toFixedFloatUnsafe((value * 2) / 255 - 1, roundDigits);

    default:
      return value.toFixed(0);
  }
};

/**
 * Demormalizes the value using the range.
 *
 * value range  result
 *  0..1,  0 -> 0..255
 * -1..1, -1 -> 0..255
 *
 * @param range The range was used to normalize the value. Could be [0|-1]
 */
export const denormalizeSensorIndex = (value: string | number, range: number) => {
  switch (range) {
    case 0:
      return +value * 255;

    case -1:
      return ((+value + 1) / 2) * 255;

    default:
      return +value;
  }
};

/**
 * Zoning color comes in [0..1, 0..1, 0..1, 0..1] format, while rgba is [0..255, 0..255, 0.255, 0..1].
 */
export const denormalizeZoningColor = (color: [number, number, number, number]) => {
  const [r, g, b, a] = color;
  return [r * 255, g * 255, b * 255, a];
};

export class EmptyStringNumber extends Yup.number {
  _typeCheck(value: any) {
    //@ts-expect-error error leftover from convertion to strict mode, please fix
    return value === '' || super._typeCheck(value);
  }

  required(message = 'Required String') {
    //@ts-expect-error error leftover from convertion to strict mode, please fix
    return this.test({
      message,
      name: 'requiredString',
      test: function (value: any) {
        return value !== '';
      },
    });
  }
}

export const getRandomColor = () => {
  const letters = '0123456789ABCDEF';
  let color = '#';
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
};

export const getTheNextColorFromPredefinedList = (alreadyTakenColors: string[]) => {
  const colorToReturn = COLORS.find(color => {
    return !alreadyTakenColors.includes(color);
  });

  return colorToReturn || getRandomColor();
};

/*
 *
 * KEY FORMAT: DD/MM/YYYY-source
 *
 * EXAMPLE: 20/12/1991-satellite
 *
 * */
export const getDateFromKey = (key = '') => {
  return {
    date: moment(key, 'DD/MM/YYYY').format(formatDate()),
    type: key.split('-')[1],
  };
};

export const classifyFieldDates = (infoExt: Array<any> = []): {[date: string]: TInfoExt} => {
  const currentDates: any = {};
  infoExt.forEach(d => {
    Object.keys(d).forEach(key => {
      const sensorDate = d[key].display;
      const sensor = d[key].sensor;
      const sourceType = d.Type;
      if (!sensorDate || !sensor || !sourceType) {
        return;
      }

      let date = currentDates[`${sensorDate}-${sourceType}`] || {};

      date[sensor] = d[key];
      date[sensor].Cloud = d.Cloud;
      date[sensor].Hidden = d.Hidden;
      date[sensor].type = d.Type;
      date.appName = d.appName || [];

      // normalization date object format between WholeFarm dates and single field dates
      date.Cloud = d.Cloud;
      date.Hidden = d.Hidden;
      date.Type = d.Type;
      date.Date = d.Date;
      // date.avgNdvi = d.avgNdvi; - DEPRECATED

      date = {
        ...d,
        ...date,
      };

      currentDates[`${sensorDate}-${sourceType}`] = date;
    });
  });
  return currentDates;
};

export const getGeometryLabelByValue = (value = '') => {
  const item = ANOMALY_LABELS().find(l => l.value === value.toLowerCase());
  return item?.label || value || 'No label';
};

export const getNextClosestDate = (target: Moment, dates: string[]): Moment | null => {
  let closest: Moment = undefined;

  dates.forEach(dateString => {
    const d = moment(dateString);
    if (d.isBefore(target)) {
      return;
    }
    if (!closest || d.isBefore(closest)) {
      closest = d;
      return;
    }
  });

  return closest;
};

export const getDateType = (date: string | TInfoExt): SourceType | '' => {
  if (typeof date === 'object') {
    return date?.Type || '';
  }
  return ((date || '').split('-')?.[1] as SourceType) || '';
};

/*
 * Convert to current system units system
 * @type string (HA - metrics, AC - U. S. Units)
 *
 * */
// https://github.com/ben-ng/convert-units

const _units: {[ket: string]: string} = {
  kg: 'lb',
  mm: 'in',
  C: 'F',
  'km/h': 'mph',
  l: 'gal',
  ml: 'fl-oz',
  g: 'oz',
  m: 'ft',
  ha: 'ac',
  AUD: 'USD',
  'kg / ha': 'lb / ac',
  'kg/ha': 'lb/ac',
  'l/ha': 'gal/ac',
  'l / ha': 'gal / ac',
  'BU / HA': 'BU / AC',
  'plants/m²': 'plants/ac',
};

/**
 * TODO use `'metric' | 'imperial'` instead.
 */
export type MeasurementSystem = 'ha' | 'ac';

/**
 * If type is metric, then simply return the value, otherwise convert to the imperial unit type.
 *
 * TODO refactor the function to have proper typings via `UnitOfMeasurement` and `const _units: Record<MetricUnit, ImperialUnit>`
 *
 * Converts `value` from metric to imperial system.
 * @param type 'ha' | 'ac', where 'ha' means metric and 'ac' means imperial
 * @param unit what's being measured – distance, weight, temperature, etc – takes the metric unit ('ha', 'kg', 'C')
 * @param value
 * @param reverse if true converts imperial to metric
 * @returns
 */
export function convertUnit(
  type: MeasurementSystem | null = 'ha',
  unit = 'none',
  value = 0,
  reverse = false
) {
  // Assuming that the `value` is already in metric and target system is metric, we just return the value.
  if (type === 'ha') {
    if (unit === 'ac') {
      return toFixedFloatUnsafe(value, 1);
    }
    return value;
  }

  // Convert metric to imperial units.
  switch (unit) {
    case 'kg': {
      return convert(value).from('kg').to('lb'); // lb (pound)
    }

    case 'mm': {
      if (reverse) {
        return convert(value).from('in').to('mm');
      }
      return toFixedFloatUnsafe(convert(value).from('mm').to('in'), 1); // in (inches)
    }

    case 'C': {
      return toFixedFloatUnsafe(convert(value).from('C').to('F'), 2); // F (Fahrenheit)
    }

    case 'km/h': {
      return convert(value).from('km/h').to('m/h'); // mph (miles per hour)
    }

    case 'l': {
      return convert(value).from('l').to('gal'); // gal (gallon)
    }

    case 'ml': {
      return convert(value).from('ml').to('fl-oz'); // fl oz (fluid ounce)
    }

    case 'g': {
      return convert(value).from('g').to('oz'); // oz (ounce)
    }

    case 'm': {
      return convert(value).from('m').to('ft'); // ft (feet)
    }

    case 'cm': {
      if (reverse) {
        return convert(value).from('in').to('cm'); // in (inch - 2.54cm)
      }
      return convert(value).from('cm').to('in'); // in (inch - 2.54cm)
    }

    case 'gdd': {
      return Math.round(value / (5 / 9));
    }

    case 'ac': {
      if (reverse) {
        return toFixedFloatUnsafe(convert(value).from('ac').to('ha'), 1); // ac to hectare
      }
      return toFixedFloatUnsafe(convert(value).from('ha').to('ac'), 1); // hectare to acre
    }

    case 'ha': {
      return toFixedFloatUnsafe(convert(value).from('ac').to('ha'), 1); // acre to hectare
    }

    case 'ft2': {
      return toFixedFloatUnsafe(convert(value).from('m2').to('ft2'), 2);
    }

    case 'lb/ac': {
      if (reverse) return value * 1.12085;
      return value / 1.12085;
    }
    case 'gal/ac': {
      if (reverse) return value * 9.35396;
      return value / 9.35396;
    }

    case 'm2': {
      if (reverse) return value / 4046.86;
      return value * 4046.86;
    }

    default: {
      return value;
    }
  }
}

export function formatUnit(type: MeasurementSystem | null = 'ha', unit = 'none') {
  if (type === 'ha') {
    return unit;
  }

  return _units[unit] ? _units[unit] : '';
}

export function convertTemperatureModel(
  units: any,
  temperatureData: IterisTemperatureDay[]
): IterisTemperatureDay[] {
  return temperatureData && temperatureData.length
    ? temperatureData.map(t => {
        return {
          ...t,
          AvgTemp: parseFloat(convertUnit(units, 'C', t.AvgTemp)),
          maxTemperature: parseFloat(convertUnit(units, 'C', t.maxTemperature)),
          minTemperature: parseFloat(convertUnit(units, 'C', t.minTemperature)),
          rainFall: parseFloat(convertUnit(units, 'mm', t.rainFall)),
          dayDegrees: parseFloat(convertUnit(units, 'gdd', t.dayDegrees)),
        };
      })
    : [];
}

export function getDataSource(data?: TDateLayer) {
  if (!data) return false;
  return data.url.includes('TERRAV') ? `Terravion-${data.type}` : data.type;
}

export const isTerravionType = (imageType: string) => imageType === 'plane';

export const isTerravionDate = (dateKay: string) => isTerravionType(getDateFromKey(dateKay).type);

export const isAustralianField = (field: Field) => field.Country === 'Australia';

export function getFieldIDFromURL(pathname = '') {
  const re = pathToRegexp(`/${CI_ROOT_PATH}/:groupID/:fieldID`);
  const result = re.exec(pathname || window.location.pathname);

  if (result) {
    return {
      farmId: parseInt(result[1]),
      fieldId: result[2] === 'WholeFarm' ? result[2] : parseInt(result[2]),
    };
  }

  return {farmId: 0, fieldId: 0};
}

/**
 * If the values is less than the minimum, returns the minimum.
 * If the values is more than the maximum, returns the maximum.
 * Otherwise just returns the value.
 */
export function clamp(min: number, val: number, max: number) {
  return Math.max(min, Math.min(val, max));
}

export const booleanIntersectCurrentField = (
  fieldGeometry: GeoJSON.Feature<any>,
  geometry: GeoJSON.Feature<GeoJSON.GeometryCollection>
) => {
  if (!fieldGeometry.type) return false;
  return booleanIntersectField(fieldGeometry, geometry);
};

export const booleanIntersectField = (
  a: GeoJSON.Feature<GeoJSON.GeometryCollection>,
  b: GeoJSON.Feature<GeoJSON.GeometryCollection>
) => {
  const isAGeometryCollection = checkForGeometryCollection(a);

  if (isAGeometryCollection === undefined) {
    return false; // catch when there is no field for farm
  }

  if (!isAGeometryCollection) {
    return intersect(a, b);
  }

  return a.geometry.geometries.some(a_ => {
    return intersect(a_, b);
  });
};

const intersect = (a: any, b: any): boolean => {
  return checkForGeometryCollection(b)
    ? b.geometry.geometries.every((g: any) => booleanIntersect(a, g))
    : booleanIntersect(a, b);
};

export const booleanIntersect = (feature1: any, feature2: any) => {
  return booleanOverlap(feature1, feature2) || booleanContains(feature1, feature2);
};

/* eslint-disable */
export const pointInside = (point: [number, number], vs: Array<any>) => {
  const x = point[0],
    y = point[1];
  let inside = false;
  for (let i = 0, j = vs.length - 1; i < vs.length; j = i++) {
    const xi = vs[i][0],
      yi = vs[i][1];
    const xj = vs[j][0],
      yj = vs[j][1];

    const intersect = yi > y !== yj > y && x < ((xj - xi) * (y - yi)) / (yj - yi) + xi;
    if (intersect) inside = !inside;
  }

  return inside;
};
/* eslint-enable */

export const checkForGeometryCollection = (feature: any): boolean | undefined => {
  return feature?.geometry?.type === 'GeometryCollection';
};

export function truncStr(str: string, limit = 100, ending = '...') {
  return str.length >= limit ? str.substring(0, limit - ending.length) + ending : str;
}

export function geoJsonObjectToPolygon(geoJson: Array<any>, toPolygons?: any) {
  return geoJson.map((el: any) => {
    if (el.geometry.type === 'LineString') return lineToPolygon(el);

    if (toPolygons) {
      return el.geometry;
    }
    return el;
  });
}

export function getDataFromRedirectUrl() {
  const urlQueryObj = new URLSearchParams(window.location.search);
  const redirect = urlQueryObj.get('redirect') || safeLocalStorage.getItem('redirectUrl');
  return getFieldIDFromURL(redirect);
}

export const sortAnomalies = (anomalies: IAnomaly[], type?: string, order?: boolean) => {
  // toDO move to anomalies utils
  const sortOrder = ['high', 'med', 'low', 'new'];
  let sortedItems = [...anomalies];
  if (type === 'priority') {
    sortedItems = sortedItems.sort(
      (a, b) => sortOrder.indexOf(a.properties.priority) - sortOrder.indexOf(b.properties.priority)
    );
  }
  if (type === 'label') {
    sortedItems = sortedItems.sort((a, b) => {
      return a.properties.label.localeCompare(b.properties.label);
    });
  }
  if (type === 'modality') {
    sortedItems = sortedItems.sort((a, b) => {
      return `${a.properties.modality}`.localeCompare(`${b.properties.modality}`);
    });
  }
  if (type === 'date') {
    sortedItems = sortedItems.sort((a, b) => {
      // @ts-expect-error 'start date' is not in the model, but it was used before premium anomalies
      const dateA = a['start date'] || a.properties.sensing_date;
      // @ts-expect-error 'start date' is not in the model, but it was used before premium anomalies
      const dateB = b['start date'] || b.properties.sensing_date;
      if (moment(dateA).isBefore(moment(dateB))) return -1;
      if (moment(dateA).isAfter(moment(dateB))) return 1;
      return 0;
    });
  }
  if (type === 'size') {
    sortedItems = sortByKey2(sortedItems, item => item.properties.area);
  }
  if (type === 'ndvi') {
    sortedItems = sortedItems.sort((a, b) => {
      const meanA = a.properties.mean || a.properties.anomaly_ndvi;
      const meanB = b.properties.mean || b.properties.anomaly_ndvi;
      return meanA - meanB;
    });
  }

  sortedItems = order ? sortedItems : sortedItems.reverse();

  if (sortedItems.some(el => el.properties.snoozed !== undefined)) {
    return [
      ...sortedItems.filter(el => !el.properties.snoozed),
      ...sortedItems.filter(el => el.properties.snoozed),
    ];
  }

  return sortedItems;
};

export const normalizeFirstLastPointOfGeometry = (
  feature: GeoJSON.Feature<GeoJSON.Polygon | GeoJSON.MultiPolygon | GeoJSON.GeometryCollection>
) => {
  // the first and last positions in coordinates must be the same

  //TODO: add support of GeoJSON.MultiPolygon and GeoJSON.GeometryCollection
  if (feature.geometry.type !== GeoJsonType.Polygon) {
    return;
  }

  if (!Array.isArray(feature.geometry.coordinates) || !feature.geometry.coordinates.length) return;

  const firstCord = feature.geometry.coordinates[0][0];
  const lastCord = feature.geometry.coordinates[0][feature.geometry.coordinates[0].length - 1];

  if (Array.isArray(firstCord) && Array.isArray(lastCord)) {
    if (firstCord[0] !== lastCord[0] || firstCord[1] !== lastCord[1]) {
      feature.geometry.coordinates[0].push([...firstCord]);
    }
  }
};

/**
 * Prepares sensor to be shown to users.
 */
export const sensorView = (sensor: string) => {
  switch (sensor) {
    case 'TCI':
      return 'RGB';
    case 'PTIRS':
      return 'TIRS-P';
    case 'NC':
      return 'RGB-S';
    default:
      return sensor;
  }
};

/**
 * Prepares sensor to be stored and transferred.
 */
export const sensorModel = (sensor: TSensor) => {
  // return sensor === 'RGB' ? 'TCI' : sensor;
  switch (sensor) {
    case 'RGB':
      return 'TCI';
    case 'TIRS-P':
      return 'PTIRS';
    case 'RGB-S':
      return 'NC';
    default:
      return sensor;
  }
};

/*
 * Type helper. check prop exist in object
 * */
export function checkExistProp<T, K extends keyof T>(obj: T, key: K) {
  return obj[key];
}

// It formats from 10000000.00 to 100,000,000.00
export const formatBigNumber = (n: number) => {
  try {
    return n.toLocaleString();
  } catch (e) {
    return n;
  }
};

export const getCsvFromArray = <C extends any[]>(headerArr: string[], contentArr: C) => {
  const csvHeader = `${headerArr.join(',')}\n`;
  const csvContent = contentArr
    .map(row => JSON.stringify(Object.values(row)))
    .join('\n')
    .replace(/(^\[)|(\]$)/gm, '');

  return csvHeader + csvContent;
};

/*
 * EXAMPLE:
 *
 * Input: data =  [
 *   {key: 1, foo: 2},
 *   {key: 1, foo: 3},
 *   {key: 10, foo: 2}
 * ]
 *
 * Output: data = [
 *   {key: 1, foo: 2},
 *   {key: 10, foo: 2}
 * ]
 *
 * Execute:
 *
 * getUniqArrayOfObjectsByProp(data, 'key');
 *
 * */
export const getUniqArrayOfObjectsByProp = (data: any[], key: string) => {
  // data deduplication, (not sure about time complexity of this filter with findIndex inside)
  return data.filter(
    (item: any, i: number, _data: any[]) => _data.findIndex(f => f[key] === item[key]) === i
  );
};

export const getFilterSourcesFromUrl = (sourcesMeta: SourceMeta[]) => {
  const paramSources = (getGetURLParam('filterSources') || '')
    .split(',')
    .map((el: any) => el.replace(/^drone$/, 'dron'))
    .filter(el => SOURCES.includes(el));

  const isFilteredAll = sourcesMeta
    .filter(s => s.available)
    .every(s => paramSources.includes(s.source));

  if (!isFilteredAll) {
    setGetParamToURL('filterSources', paramSources.length ? paramSources.join(',') : null);
    return paramSources;
  }

  const newParams = sourcesMeta.filter(s => s.available).map(s => s.source);

  // remove one filtered item  cuz we do not allow filter all sources
  newParams.shift();

  // set updated params
  setGetParamToURL('filterSources', newParams.length ? newParams.join(',') : null);

  return newParams;
};

export const validateBounds = (bounds: L.LatLngBounds) => {
  let valid = false;
  const validCoordinate = (val: number) => typeof val === 'number' && isFinite(val);
  if (bounds) {
    try {
      valid =
        bounds.isValid() &&
        validCoordinate(bounds.getEast()) &&
        validCoordinate(bounds.getNorth()) &&
        validCoordinate(bounds.getSouth()) &&
        validCoordinate(bounds.getWest());
    } catch (err) {
      valid = false;
    }
  }
  return valid ? bounds : undefined;
};

export const isSameBounds = (bounds1: L.LatLngBounds, bounds2: L.LatLngBounds) => {
  if ((!bounds1 && bounds2) || (bounds1 && !bounds2)) return false;
  if (
    bounds1.getEast() !== bounds2.getEast() ||
    bounds1.getWest() !== bounds2.getWest() ||
    bounds1.getNorth() !== bounds2.getNorth() ||
    bounds1.getSouth() !== bounds2.getSouth()
  ) {
    return false;
  }

  return true;
};

/*
 *
 * return array like [{source: 'drone': available: true}, ...]
 * if the satellite_hd source is present, filter one of the not available source type
 * to keep 4 source types filter items (FSB-1901, Anastasia's comment)
 * * */
export const getSourcesMeta = (images: TInfoExt[]): SourceMeta[] => {
  let resultSources = SOURCES.map(source => ({
    source,
    available: images.some(i => i.Type === source),
  }));

  if (resultSources.find(s => s.source === 'satellite_hd' && s.available)) {
    if (resultSources.find(s => s.source === 'plane' && !s.available))
      return resultSources.filter(s => s.source !== 'plane');
    else if (resultSources.find(s => s.source === 'dron' && !s.available))
      return resultSources.filter(s => s.source !== 'dron');
    else if (resultSources.find(s => s.source === 'machinery' && !s.available))
      return resultSources.filter(s => s.source !== 'machinery');
    else if (
      // if all the sources are present, don't filter them, show all
      ['machinery', 'dron', 'plane'].every(sourceLabel =>
        resultSources.find(st => st.source === sourceLabel && st.available)
      )
    )
      return resultSources;
  }

  return resultSources.filter(s => s.source !== 'satellite_hd');
};

export const sortObjectDates = (dates: any) => {
  let result: any = {};
  const keys = sortDates(Object.keys(dates || {})).reverse();

  for (let i = 0; i < keys.length; i++) {
    result[keys[i]] = dates[keys[i]];
  }

  return result;
};

/*
 *  check current host is local
 * */
export function isLocalhost() {
  return window.location.host.startsWith('localhost');
}

export const getFarmById = (farmsList: Farm[], id: number): Farm | null => {
  return farmsList.find(f => f.id === id) || null;
};

/*
 *
 * Convert current sensor to avgSensor
 * Example: NDVI => avgNdvi
 *
 * */
export function getAvgProp(sensor: TSensor): keyof TInfoExt {
  let _sensor = sensor + '';
  // @ts-expect-error error leftover from convertion to strict mode, please fix // ingore the fact that not all avgSensors are available
  return `avg${capitalizeFirstLetter(_sensor.toLowerCase())}`;
}

export const isProd = () => config.env === 'production';

export const fitBoundCurrentEditFieldKml = (withPanelPadding = false) => {
  //@ts-expect-error error leftover from convertion to strict mode, please fix
  if (!window.leafletElement) {
    return;
  }
  try {
    //@ts-expect-error error leftover from convertion to strict mode, please fix
    window.leafletElement.eachLayer(layer => {
      //@ts-expect-error error leftover from convertion to strict mode, please fix
      if (layer.__fluroLayerToEdit) {
        //@ts-expect-error error leftover from convertion to strict mode, please fix
        window.leafletElement.fitBounds(
          layer.getBounds(),
          withPanelPadding
            ? {
                paddingTopLeft: [32, 32],
                paddingBottomRight: [432, 32],
              }
            : {padding: [50, 50]}
        );
      }
    });
  } catch (e) {
    reportError('Cannot fit bounds current edit field kml');
  }
};
