import {t} from 'i18n-utils';
import {FontIcon} from 'react-md';
import React from 'react';
import {FluroButton} from 'components';

const CropStatus = ({
  status,
  labelPosition,
  message,
}: {
  status: boolean;
  labelPosition?: any;
  message: any;
}) => {
  return (
    <FluroButton
      icon
      iconBefore={false}
      className={`status-icon ${status ? 'ok' : ''}`}
      iconChildren={<FontIcon>{status ? 'check_circle' : 'error'}</FontIcon>}
      tooltipLabel={status ? <span>{t({id: 'Model is ready'})}</span> : message}
      tooltipPosition={labelPosition || 'right'}
    />
  );
};
export default CropStatus;
