import React, {useEffect, useState} from 'react';
import type {UpdatedSearchResult} from './search';
import Search from './search';
import './index.scss';
import {t} from 'i18n-utils';
import {useDispatch, useSelector} from 'react-redux';
import {showNotification} from 'components/notification/notification';
import type {AppStore} from 'reducers';
import {addingFieldsFromMapSteps} from 'modules/add-fields/base';
import {setLocationMarkerCoordinates} from '../../actions';
import {usePrevious} from '_hooks';
import {useMap} from 'react-leaflet';
import ReadOnly from 'components/read-only-container';
import {FontIcon} from 'react-md';
import cn from 'classnames';
import Control from 'containers/map/lib/control';

const SearchLocation = () => {
  const dispatch = useDispatch();
  const [search, setSearch] = useState('');
  const addFieldCurrentStep = useSelector((s: AppStore) => s.addFields.addFieldCurrentStep);
  const isMapBarOpen = useSelector((s: AppStore) => s.map.isMapBarOpen);
  const prev = usePrevious({addFieldCurrentStep});
  const regExp = /^([-+]?)([\d]{1,2})(((\.)(\d+)(,)))(\s*)(([-+]?)([\d]{1,3})((\.)(\d+))?)$/g;
  const activeFieldAddingState = addingFieldsFromMapSteps.includes(addFieldCurrentStep);
  const [searchActive, setSearchActive] = useState(false);
  const [toggledByBlur, setToggledByBlur] = useState(false);

  const timeoutRef = React.useRef<ReturnType<typeof setTimeout> | null>(null);

  useEffect(() => {
    if (
      // automatically close the search bar after field creating workflow was cancelled
      addingFieldsFromMapSteps.includes(prev.addFieldCurrentStep) &&
      !activeFieldAddingState
    ) {
      toggleSearch(false);
    }
  }, [addFieldCurrentStep]);

  const onSearchBlurred = () => {
    // prevent automatically closing search bar during fields creating workflow
    if (!activeFieldAddingState) {
      toggleSearch(false);
      setToggledByBlur(true); // a trick to avoid reopening search bar when click on the search icon again
    }
  };

  useEffect(() => {
    if (!toggledByBlur) return;
    if (timeoutRef.current) clearTimeout(timeoutRef.current);

    timeoutRef.current = setTimeout(() => {
      setToggledByBlur(false);
    }, 100);
  }, [toggledByBlur]);

  const leafletElement = useMap();

  if (!leafletElement) {
    return null;
  }

  const toggleSearch = (value: boolean) => {
    setSearchActive(value);
    if (value) {
      setTimeout(() => {
        document.getElementById('toolbar-search') &&
          document.getElementById('toolbar-search')?.focus();
      }, 200);
    }
  };

  const navigateToLocation = (location: UpdatedSearchResult) => {
    if (location.bounds) {
      leafletElement?.fitBounds(location.bounds, {
        paddingBottomRight: [isMapBarOpen ? 300 : 0, 70],
        maxZoom: 20,
      });
    } else {
      leafletElement?.panTo([location.y, location.x]);
    }
  };

  const handleAutocomplete = (location: UpdatedSearchResult) => {
    if (!location) return;
    handleChange(location.label);
    !activeFieldAddingState && toggleSearch(false); // do not hide the search if it is field creation stage
    if (leafletElement) {
      dispatch(setLocationMarkerCoordinates(location.y, location.x));
      navigateToLocation(location);
    }
  };

  const handleChange = (value: string) => {
    setSearch(value);
  };

  const navigateToCoordinates = () => {
    const bounds = search.split(',');
    handleAutocomplete({
      x: parseFloat(bounds[1]),
      y: parseFloat(bounds[0]),
      label: search,
    } as UpdatedSearchResult);
  };

  const handleEnterKey = (foundedItems: number) => {
    const isCoordinates = search.match(regExp);

    if (isCoordinates) {
      navigateToCoordinates();
      const firstMenuItem: HTMLElement | null = document.querySelector('.map-v2__main');
      firstMenuItem && firstMenuItem.click();
      return;
    }

    if (foundedItems === 1 && !isCoordinates) {
      const firstMenuItem: HTMLElement | null = document.querySelector(
        '#toolbar-search-menu-list .md-list-item'
      );
      firstMenuItem && firstMenuItem.click();
    } else if (foundedItems === 0 && !isCoordinates) {
      showNotification({
        title: t({id: 'warning', defaultMessage: 'Warning'}),
        message: t(
          {
            id: `cannotFindValue`,
            defaultMessage: `Cannot find {search}`,
          },
          {search}
        ),
        type: 'warning',
      });
    }
  };

  return (
    <Control position="topleft">
      <div className={'location-buttons'}>
        <ReadOnly>
          <FontIcon
            onClick={() => {
              if (!toggledByBlur) {
                toggleSearch(!searchActive);
              }
            }}
            className={cn('location-button', {active: activeFieldAddingState || searchActive})}
          >
            search
          </FontIcon>
        </ReadOnly>

        {searchActive && (
          <Search
            activeFieldCreation={activeFieldAddingState}
            title={search}
            value={search}
            onBlur={onSearchBlurred}
            onChange={handleChange}
            onAutocomplete={handleAutocomplete}
            onKeyDown={handleEnterKey}
          />
        )}
      </div>
    </Control>
  );
};

export default SearchLocation;
