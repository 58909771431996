import type {
  DialogToggleAction,
  HelpersDialogsCloseAllAction,
  HelpersStore,
  SetFilterAction,
  SetRequestStatusAction,
  Status,
} from './types';
import {ActionType} from './types';

export const dialogToggle = (
  dialogKey: keyof HelpersStore['dialogs'],
  visible: boolean,
  payload?: any
): DialogToggleAction => ({
  type: ActionType.DialogToggle,
  dialogKey,
  visible,
  payload,
});

export const closeAllDialogs = (): HelpersDialogsCloseAllAction => ({
  type: ActionType.CloseAllDialogs,
});

export const setFilter = (name: string, value: any): SetFilterAction => ({
  type: ActionType.SetFilter,
  name,
  value,
});

export const setRequestStatus = (
  key: keyof HelpersStore['asyncStatuses'],
  status: Status,
  errors?: any
): SetRequestStatusAction => ({
  type: ActionType.SetRequestStatus,
  key,
  status,
  errors,
});
