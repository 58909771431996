import React from 'react';
import {useAppSelector} from '_hooks';
import {kiloFormatter, toFixedFloat} from '_utils/number-formatters';
import {t} from 'i18n-utils';
import {selectMeasurement} from 'containers/login/login-selectors';
import {SparklingChartCard} from 'containers/si/kpi/charts/sparkling-chart-card';
import {TOOLTIPS} from 'containers/si/constants';
import {useFetchKPI} from 'containers/si/hooks/useFetchKPI';
import {useSparklingChart} from 'containers/si/hooks/useSparklingChart';

export const GHGEmissionCard = () => {
  // const yearsFilter = useAppSelector(selectSIYearsFilter);
  const measurement = useAppSelector(selectMeasurement);

  const {response: ghgResponse, loading} = useFetchKPI({
    kpi: 'ghg',
    summarizeBy: ['annualized', 'kpi_subtype'],
  });
  const {sparklingChartData, dataset, metric, perArea, average, comparisonValues} =
    useSparklingChart(ghgResponse, {
      negativeIsGain: true,
      tooltip: {
        up: TOOLTIPS.KPI.ghg.pctUp,
        down: TOOLTIPS.KPI.ghg.pctDown,
      },
    });

  // const [_pieData, setPieData] = useState<any[]>([]);
  // const theme = useTheme();

  // useEffect(() => {
  //   if (!ghgResponse) return;

  //   // "ghg_kg"
  //   const calcGHGkg = ghgResponse?.metric?.value || 0; // in kg from API

  //   const calculateFixedPercentage = (value: number) => {
  //     return toFixedFloat(calculatePercentage(value, calcGHGkg), 2);
  //   };

  //   //Calculate pie chart data
  //   const N2OIndirect = ghgResponse.kpi_subtype_summary?.n2o_indirect_kg || 0;
  //   const N2ODirect = ghgResponse.kpi_subtype_summary?.n2o_direct_kg || 0;
  //   const CH4 = ghgResponse.kpi_subtype_summary?.ch4_kg || 0;

  //   setPieData([
  //     {
  //       id: 'Indirect N20',
  //       label: 'Indirect N20',
  //       value: calculateFixedPercentage(N2OIndirect),
  //       color: theme.colorPalette.fs_main.green_400,
  //     },
  //     {
  //       id: 'Direct N20',
  //       label: 'Direct N20',
  //       value: calculateFixedPercentage(N2ODirect),
  //       color: theme.colorPalette.fs_main.green_600,
  //     },
  //     {
  //       id: 'Methane CH4',
  //       label: 'Methane CH4',
  //       value: calculateFixedPercentage(CH4),
  //       color: theme.colorPalette.fs_main.green_800,
  //     },
  //   ]);
  // }, [ghgResponse, measurement, yearsFilter, theme]);

  return (
    <SparklingChartCard
      title={t({id: 'GHG emissions'})}
      subtitle={t({id: 'in metric tonnes CO2 eq.'})}
      cardCenterNumber={
        metric === 0 ? 'No Data' : `${String(kiloFormatter(metric)).toUpperCase()} tonnes`
      }
      cardCenterUnits={
        measurement === 'ac'
          ? `${toFixedFloat(perArea, 3)} tonnes per acre`
          : `${toFixedFloat(perArea, 3)} tonnes per hectare`
      }
      comparisonValues={comparisonValues}
      sparklingChartCaption={`${sparklingChartData.datasets.length} year avg`}
      sparklingChartCaptionBolded={`${kiloFormatter(average)} tonnes`}
      chartData={{labels: sparklingChartData.labels, datasets: dataset}}
      chartDataMidline={average}
      // pieData={pieData}
      tooltip={TOOLTIPS.KPI.ghg.card}
      linechartLabelSuffix="tonnes"
      loading={loading}
    />
  );
};
