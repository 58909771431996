import styled from 'styled-components';
import {main_gray_600} from '_utils/colors';
import colors from 'variables.scss';

export const ZoningTabsWrapper = styled.div`
  margin: 10px 0;
  width: 100%;

  .fluro-tabs-container {
    .tab {
      display: flex;
      align-items: center;
    }
  }

  .help-icon-tab {
    margin-left: 5px;
    display: flex;
    align-items: center;

    .md-icon {
      color: ${colors['main-gray-400']};
    }
  }
`;

export const Wrapper = styled.div`
  width: 100%;
  margin-top: 10px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;

  .buttons-block {
    width: 100%;

    &.fluro-sticky {
      margin-top: auto;
      padding: 10px 0;
      background-color: #fff;
    }
  }
`;

export const CropItemWrapper = styled.div`
  margin-top: 10px;
`;

export const SelectedTitle = styled.div`
  color: rgba(0, 0, 0, 0.6);
  font-size: 12px;
`;

type CropItemCardProps = {
  selected?: boolean;
};

export const CropItemCard = styled.div`
  padding: 10px 0;
  border-bottom: 1px solid ${({selected}: CropItemCardProps) => (selected ? main_gray_600 : '#EEE')};

  transition: border 0.2s;

  display: flex;
  align-items: center;

  :hover {
    border-bottom: 1px solid ${main_gray_600};
  }
`;

export const Line = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  padding-bottom: 15px;
  border-bottom: 1px solid #eee;
`;
