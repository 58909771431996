import React, {useMemo} from 'react';
import {Switch, Redirect, Route, useLocation, useHistory} from 'react-router-dom';
import {SI_PROGRAM_SETTINGS, SI_SUPPLY_SHED_CONFIG, SI_HOME} from 'containers/si/routes';
import {PrivateRoute} from 'containers';
import {SupplyShed} from 'containers/si/supply-shed/supply-shed';
import {FluroTabs} from 'components';
import {getFeaturesLabels} from '_utils/translations';
import {useAppSelector} from '_hooks';
import {selectTabsUserHasAccessTo} from '../module/selectors';
import {applyPathParams} from '_utils/pure-utils';
import type {TabAccess} from 'containers/si/api/apiTypes';
import {ProgramAdministration} from 'containers/si/admin/program-administration-entry';
import {SIPageContainer} from 'containers/si/si-styled-components';

export const SIConfigure = ({programId}: {programId: number}) => {
  const featureLabels = getFeaturesLabels();
  const {push} = useHistory();
  const location = useLocation();
  const tabsAccess = useAppSelector(selectTabsUserHasAccessTo);

  // These are the available, second level Configure tabs
  const availableTabs = useMemo(() => {
    const siConfigureTabs: {accessKey: TabAccess; label: string; value: string}[] = [
      {
        accessKey: 'supply_shed',
        label: featureLabels['si-supply-shed'],
        value: applyPathParams(SI_SUPPLY_SHED_CONFIG, {programId}),
      },
      {
        accessKey: 'admin',
        label: featureLabels['si-admin'],
        value: applyPathParams(SI_PROGRAM_SETTINGS, {programId}),
      },
    ];

    return siConfigureTabs.filter(tabOption => tabsAccess.includes(tabOption.accessKey));
  }, [featureLabels, programId, tabsAccess]);

  const selectedTab = useMemo(() => {
    return String(availableTabs.find(({value}) => location.pathname.includes(value))?.value);
  }, [location, availableTabs]);

  if (!availableTabs.length) {
    return <Redirect to={SI_HOME} />; // redirect to main screen if a user doesn't have access to any tabs from the Configure page
  }

  return (
    <>
      <FluroTabs
        containerClassName="second-level-menu-tabs"
        tabs={availableTabs}
        selectedTab={selectedTab}
        onTabClick={tab => push(tab)}
      />
      <SIPageContainer>
        <Switch>
          <PrivateRoute path={SI_SUPPLY_SHED_CONFIG} component={SupplyShed} />
          <PrivateRoute path={SI_PROGRAM_SETTINGS} component={ProgramAdministration} />
          <Route
            component={() => <Redirect to={applyPathParams(SI_SUPPLY_SHED_CONFIG, {programId})} />}
          />
        </Switch>
      </SIPageContainer>
    </>
  );
};
