import {Flex, Text} from 'components';
import {CardBase} from 'components/card/card-base';
import {setFilter} from 'modules/sustainability-insights/actions';
import {selectIsSIDataLoading} from 'modules/sustainability-insights/selectors';
import {SIGeometryFilterType} from 'modules/sustainability-insights/types';
import type {ComponentType} from 'react';
import React from 'react';
import {useAppDispatch, useAppSelector} from '_hooks';
import {YearsFilterRangeLabel} from 'containers/map/features/sustainability-insights/years-filter-range-label';
import {FertilizerSummaryChart} from './fertilizer-summary-chart';
import {CardToolTip} from '../components/card-tooltip';
import {LearnMore} from '../components/learn-more';
import {TOOLTIPS} from 'containers/si/constants';

type Props = {
  isActive?: boolean;
};

export const FertilizerCard: ComponentType<Props> = ({isActive = false}) => {
  const dispatch = useAppDispatch();
  const isLoading = useAppSelector(selectIsSIDataLoading);

  const onFilterClick = () => {
    if (isActive || isLoading) return;
    dispatch(
      setFilter({
        activeGeometryType: SIGeometryFilterType.Fertilizer,
      })
    );
  };

  return (
    <CardBase
      active={isActive}
      className={'sustainability-card fertilizer-card'}
      heading={
        <Flex nowrap justifyContent="space-between">
          <Text className="m-0" elementType="h3">
            Fertilizer Application <YearsFilterRangeLabel />
          </Text>
          <CardToolTip
            id="fetilizer-info"
            place="left"
            content={TOOLTIPS.KPI.fertilizerApplication.card}
          />
        </Flex>
      }
      onClick={onFilterClick}
    >
      {isActive && (
        <div>
          <FertilizerSummaryChart />
          <Flex>
            <LearnMore articleId={6479122} />
          </Flex>
        </div>
      )}
    </CardBase>
  );
};
