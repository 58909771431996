import {useAppSelector} from '_hooks';
import {useParsedMatchParams} from '_hooks/use-parsed-match-params';
import {selectSISupplyShedsListByProgramId} from 'containers/si/module/selectors';
import {useCallback} from 'react';

export const useGetRegionById = () => {
  const {programId} = useParsedMatchParams<{programId: number}>();
  const supplySheds = useAppSelector(s => selectSISupplyShedsListByProgramId(s, programId));

  const getSupplyShedById = useCallback(
    (id: number) => supplySheds.find(shed => shed.id === id),
    [supplySheds]
  );
  const getSubsectionById = useCallback(
    (id: number) =>
      supplySheds.flatMap(shed => shed.subsections).find(subsection => subsection.id === id),
    [supplySheds]
  );

  return {getSubsectionById, getSupplyShedById};
};
