// @ts-nocheck
import type {
  CropType,
  SessionExpiredRequest,
  SetCropTypesAction,
  ToggleGlobalTooltipProps,
} from 'modules/global/types';
import {GlobalActionTypes} from 'modules/global/types';
import {ActivityApi} from '_api';
import type {Dialogs} from 'types';
import type {Method} from 'axios';
import axios from 'axios';
import type {Dispatch} from 'react';
import type {AppStore} from '../../reducers';
import {Locales} from '../../i18n-utils';
import {selectIsAdmin} from 'containers/login/login-selectors';

export const loadCropTypes =
  () => (dispatch: Dispatch<SetCropTypesAction>, getState: () => AppStore) => {
    const state = getState();
    const langLocale = state.login.user.settings?.langLocale;
    const isAdmin = selectIsAdmin(state);

    const setCropTypes = ({data}: {data: {result: CropType[]}}) => {
      const cropsList = data.result;
      const cropTypes = cropsList.reduce<SetCropTypesAction['payload']['cropTypes']>(
        (acc, crop) => {
          if (langLocale !== Locales.EnUS) {
            crop.label =
              crop.label ||
              (crop[`label_${langLocale?.toLowerCase()}` as keyof CropType] as string);
          }
          acc[crop.value] = crop;
          return acc;
        },
        {}
      );

      dispatch({
        type: GlobalActionTypes.SET_CROP_TYPES,
        payload: {
          cropTypes,
        },
      });
    };

    return ActivityApi.getCropTypesList(isAdmin ? undefined : langLocale?.toLowerCase())
      .then(setCropTypes)
      .catch(() => {
        axios
          .get(`/assets/crops/crop-types.json`)
          .then(setCropTypes)
          .catch(() => /* TODO: handle no crop constants */ undefined);
      });
  };

export const toggleHeader = (value: boolean) => ({
  type: GlobalActionTypes.TOGGLE_HEADER,
  value,
});

export const showLoading = (pattern = '', method: Method = 'get', key?: string) => ({
  type: GlobalActionTypes.ADD_LOADING,
  URLPattern: pattern,
  method,
  key: pattern === 'data2/:md5' ? '' : key,
});

export const hideLoading = (pattern = '', method: Method = 'get', key?: string) => ({
  type: GlobalActionTypes.REMOVE_LOADING,
  URLPattern: pattern,
  method,
  key: pattern === 'data2/:md5' ? '' : key,
});

export const mapLoading = (value: boolean) => ({
  type: GlobalActionTypes.MAP_LOADING,
  value,
});

export const toggleMapView = (value: boolean) => ({
  type: GlobalActionTypes.TOGGLE_MAP_VIEW,
  value,
});

export const setGlobalParam = (propName: string, value: string | number) => ({
  type: GlobalActionTypes.SET_GLOBAL_PARAM,
  propName,
  value,
});

export const toggleGlobalDialog = (
  dialog: Dialogs,
  properties: any = {},
  resetDialogState = false
) => ({
  type: GlobalActionTypes.TOGGLE_DIALOG,
  dialog,
  properties,
  resetDialogState,
});

export const toggleSessionExpired = (sessionExpired: boolean) => ({
  type: GlobalActionTypes.TOGGLE_SESSION_EXPIRED,
  sessionExpired,
});

export const addSessionExpiredRequest = (request: SessionExpiredRequest) => ({
  type: GlobalActionTypes.ADD_SESSION_EXPIRED_REQUEST,
  request,
});

export const setTabNotification = (tabKey: string, tabData: any) => ({
  type: GlobalActionTypes.SET_TAB_NOTIFICATION,
  tabKey,
  tabData,
});

export const toggleTooltip = (data: ToggleGlobalTooltipProps) => ({
  type: GlobalActionTypes.TOGGLE_GLOBAL_TOOLTIP,
  data,
});

export const updateCropType = (crop: CropType) => ({
  type: GlobalActionTypes.UPDATE_CROP_TYPE,
  crop,
});

export const addCropType = (crop: CropType) => ({
  type: GlobalActionTypes.ADD_CROP_TYPE,
  crop,
});

export const setReportView = (value: boolean) => ({
  type: GlobalActionTypes.SET_REPORT_VIEW,
  value,
});
