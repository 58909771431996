// @ts-nocheck
import initialState from '../initial-state';
import type {IAction, Field, Season} from '../../types';

import setCurrentSeasonCase, {classifyFieldPropertiesFromSeason} from './set-current-season';
import {ActionTypes} from '../types';
import {calculateLayerType} from '../../utils/trees';

import {preselectSensor, preselectSensors, preselectRange} from 'containers/map/utils';
import {RegularZoningMethods} from '../../features/zoning/zoning-constants';

export default (state = initialState, action: IAction) => {
  switch (action.type) {
    case ActionTypes.SET_CURRENT_SEASON:
      return setCurrentSeasonCase(state, action);

    case ActionTypes.MAP_CHANGE_FIELD_SEASON:
      return {
        ...state,
        fields: state.fields.map((field: Field) => {
          if (field.ID === action.season.kmlId) {
            return {...field, ...classifyFieldPropertiesFromSeason(action.season)};
          }
          return field;
        }),
      };

    case ActionTypes.MAP_BULK_CHANGE_FIELD_SEASON:
      return {
        ...state,
        fields: state.fields.map((field: Field) => {
          let fieldToSet = field;
          action.seasons.forEach(season => {
            if (field.ID === season.kmlId) {
              fieldToSet = {...field, ...classifyFieldPropertiesFromSeason(season)};
            }
          });

          return fieldToSet;
        }),
      };

    case ActionTypes.MAP_DELETE_SEASON: {
      const updatedField = {
        ...state.fields.find(f => f.ID === action.kmlId),
        Seasons: action.seasons,
      };

      return {
        ...state,
        fields: [...state.fields.filter(f => f.ID !== action.kmlId), updatedField],
        field: updatedField,
      };
    }

    case ActionTypes.MAP_ADD_SEASON: {
      return {
        ...state,
        field: {
          ...state.field,
          Seasons: action.seasons,
        },
      };
    }

    case ActionTypes.MAP_SEASON_TOGGLE:
      return {
        ...state,
        fields: state.fields.map((f: Field) => {
          if (Array.isArray(f.Seasons)) {
            f.Seasons = f.Seasons.map((s: Season) => {
              if (s.id === action.seasonID) {
                return {...s, _selected: action.value};
              }
              return s;
            });
          }
          return f;
        }),
      };

    case ActionTypes.MAP_SEASONS_TOGGLE:
      return {
        ...state,
        fields: state.fields.map((f: Field) => {
          if (Array.isArray(f.Seasons)) {
            f.Seasons = f.Seasons.map((s: Season, i) => {
              if (action.value && action.onlyLatest) {
                return f.Seasons.length === i + 1 ? {...s, _selected: action.value} : s;
              }
              return {...s, _selected: action.value};
            });
          }
          return f;
        }),
      };

    case ActionTypes.MAP_SYNC_FIELD_SEASONS: {
      const updatedFields = state.fields.map(field => {
        action.fields.forEach(fieldWithNewProps => {
          if (fieldWithNewProps.ID && fieldWithNewProps.ID === field.ID) {
            field = {...field, ...fieldWithNewProps};
          }
        });

        return field;
      });
      return {
        ...state,
        fields: updatedFields,
      };
    }

    case ActionTypes.MAP_SET_CURRENT_DATE: {
      const currentSensor = preselectSensor(action.date, state.currentDates, state.currentSensor);

      const layerType = calculateLayerType(
        action.date,
        state.treeDetection.layerType,
        currentSensor
      );

      // if we switch from tree zoning to regular we need set default method to regular one
      const shouldUpdateZoningMethod =
        state.treeDetection.layerType !== 'default' && state.treeDetection.layerType !== layerType;

      const range: number = preselectRange(currentSensor);

      return {
        ...state,
        currentDate: action.date,
        currentSensors: preselectSensors(action.date, state.currentDates, layerType),
        treeDetection: {
          ...state.treeDetection,
          layerType,
        },

        currentSensor,

        histogram: {
          ...state.histogram,
          range,
        },

        zoning: {
          ...state.zoning,
          method: shouldUpdateZoningMethod ? RegularZoningMethods[0].value : state.zoning.method,
          points: {},
        },
      };
    }

    case ActionTypes.MAP_SET_SENSOR:
      return {
        ...state,
        currentSensor: action.sensor,
        zoning: {
          ...state.zoning,
          points: {},
        },
        nRecommendation: {
          ...state.nRecommendation,
          zonesWithNData: action.sensor === 'NONE' ? [] : state.nRecommendation.zonesWithNData,
        },
      };

    default:
      return state;
  }
};
