// @ts-nocheck
import {t} from 'i18n-utils';
import {FontIcon} from 'react-md';
import moment from 'moment';
import React, {memo} from 'react';
import {Link} from 'react-router-dom';
import {HelpModal} from 'containers/help-popup';
import Yup from 'yup';
import {CropInfo} from 'components';
import {WarningSvg} from 'components/icons';
import {toggleTooltip} from 'modules/global/actions';
import {useDispatch} from 'react-redux';
import type {FField} from './types';

type OneCropProps = {
  field: any;
  arrow?: boolean;
};

export const OneCrop = memo(({field, arrow}: OneCropProps) =>
  !field.seasonId ? (
    <span className="features__no-seasons">No seasons</span>
  ) : (
    <div className="wf-crop__wrapper">
      <CropInfo
        cropType={field.cropType}
        cropSubType={field.cropVariety}
        startDate={moment.utc(field.startDate).format('DD MMM YYYY')}
        endDate={moment.utc(field.endDate).format('DD MMM YYYY')}
      />

      {arrow ? <FontIcon className="features__arrow">keyboard_arrow_down</FontIcon> : null}
    </div>
  )
);

export const Helps = memo(({label}: {label: string}) => (
  <>
    <HelpModal title="Premium Apps Activation" id="report-not-activated" width={400}>
      <div className="helper-text">
        Got the the <Link to="/admin/features">Premium Apps</Link> page for Crop Stress activation
      </div>
    </HelpModal>

    <HelpModal title="Notice" id="email-activation-not-available" width={400}>
      <div className="helper-text">
        This type of report ({label}) does not have field tag dependency
      </div>
    </HelpModal>

    <HelpModal title="Notice" id="pivothermo-disabled" width={400}>
      <div className="helper-text">
        This field does not have Seasons or Crop Stress is not activated for this field on the
        premium apps page
      </div>
    </HelpModal>
  </>
));

export const ErrorMessage = (
  <span style={{color: '#333'}}>
    The process encountered an issue, please try again once or contact the Team.
  </span>
);

export const SuccessMessage = (
  <span>
    We are preparing and send the email. Please do not close that window, you will be informed with
    a success/error message.
  </span>
);

export const NoDatesMessage = <span>No available dates</span>;

export const emailSchema = Yup.string().email();

export const DisposableEmailValidationSchema = Yup.object().shape({
  language: Yup.string(),
  reportDate: Yup.date(),
  recipients: Yup.array().of(emailSchema).min(1, 'You should add at least one email address'),
});

export const DisposableEmailValidationSchemaWithCropType = Yup.object().shape({
  crop_type: Yup.string().required('Crop type is required'),
  language: Yup.string(),
  region: Yup.string(),
  date: Yup.date().required('Report date is required'),
  recipients: Yup.array().of(emailSchema).min(1, 'You should add at least one email address'),
});

export const CropStressDisposableEmailValidationSchema = Yup.object().shape({
  language: Yup.string(),
  imageDate: Yup.string().required('Date is required'),
  recipients: Yup.array().of(emailSchema).min(1, 'You should add at least one email address'),
});

export const validateOneEmail = (value: string) =>
  Yup.string().email().required('Email is required').validateSync(value);

export const buildLabel = (searchBy: string, f: FField) => {
  let label = `${f.fieldName} (#${f.fieldId}), Farm: ${f.farmName} (#${f.farmId})`;

  if (searchBy.startsWith('farm')) {
    label = `${f.farmName} (#${f.farmId})`;
  } else if (searchBy.startsWith('organization')) {
    label = `${f.organizationName} (#${f.organizationId})`;
  } else if (searchBy.startsWith('subscription')) {
    label = `${f.subscriptionName} (#${f.subscriptionId})`;
  }

  return label;
};

export const prepareEmails = (value: string) => {
  const dirtyData = value
    .trim()
    .split(',')
    .map((el: string) => el.trim())
    .filter((el: string) => el);

  let addedEmails: string[] = [];

  dirtyData.forEach((el: string) => {
    try {
      emailSchema.validateSync(el);

      addedEmails.push(el);
    } catch (e) {}
  });

  return [...new Set(addedEmails)];
};

export const RecheckDate = ({id}: {id: number | string}) => {
  const dispatch = useDispatch();

  return (
    <WarningSvg
      className={'warning-icon'}
      data-tip=""
      data-for={`${id}-recheck-date-value`}
      onMouseEnter={() =>
        dispatch(
          toggleTooltip({
            id: `${id}-recheck-date-value`,
            content: t({id: 'admin.recheck-date-value'}),
            place: 'left',
          })
        )
      }
    />
  );
};
