import React, {useCallback, useMemo} from 'react';
import type {ComponentType} from 'react';
import {useAppDispatch, useAppSelector} from '_hooks';
import {
  selectIsImpersonatorSuperAdmin,
  selectMenubarUserNameOrEmail,
  selectUserIsImpersonated,
} from 'containers/login/login-selectors';
import {Text} from 'components';
import {AccountSettingIcon} from 'containers/map/icons';
import styled from 'styled-components';
import Select, {components} from 'react-select';
import type {StylesConfig, ValueContainerProps, OptionProps} from 'react-select';
import {
  MenubarMenuOptionStyles,
  MenubarMenuStyles,
  MenubarControlStyles,
  OptionInnerLineSeparator,
  MenubarMenuContainerStyles,
} from 'containers/menubar/menubar.styled';
import {openProfileDialog} from 'containers/profile/actions';
import cn from 'classnames';
import {t} from 'i18n-utils';
import {logout, unImpersonateUser} from 'containers/login/actions';
import {useMonitoringUrlParams} from 'containers/mrv/monitoring/hooks';
import {QuestionCircledIcon} from 'components/fluro-icons';
import {useWorkspace} from '_hooks/use-workspace';
import {isSingleOption} from 'containers/menubar/menubar-utils';
import {HubspotChatLaunchIcon} from 'containers/menubar/menubar-components';

type Option = {
  value: string;
  label: string;
  onClick: () => void;
  lineSeparator?: boolean;
};

const IndicatorsContainer: ComponentType<ValueContainerProps<Option>> = ({...props}) => {
  const impersonatedUser = useAppSelector(selectUserIsImpersonated);
  const userNameOrEmail = useAppSelector(selectMenubarUserNameOrEmail);

  return (
    <components.IndicatorsContainer {...props}>
      <IndicatorsStyledContainer className={cn({impersonated: impersonatedUser})}>
        <AccountSettingIcon className="profile-icon" />
        <Text title={userNameOrEmail} className="user-label" elementType={'div'} variant="medium">
          {impersonatedUser ? t({id: 'Using as'}) : ''} {userNameOrEmail}
        </Text>
      </IndicatorsStyledContainer>
    </components.IndicatorsContainer>
  );
};

const OptionComponent: ComponentType<OptionProps<Option>> = ({children, ...props}) => {
  return (
    <>
      <components.Option {...props}>{children}</components.Option>
      {props.data.lineSeparator ? <OptionInnerLineSeparator /> : null}
    </>
  );
};

export const MenubarProfileComponent: ComponentType = () => {
  const dispatch = useAppDispatch();
  const userIsImpersonated = useAppSelector(selectUserIsImpersonated);
  const userVisibleName = useAppSelector(selectMenubarUserNameOrEmail);
  const {programId} = useMonitoringUrlParams();
  const {workspace} = useWorkspace();
  const impersonatorIsSuperAdmin = useAppSelector(selectIsImpersonatorSuperAdmin);

  const onUnImpersonateUser = useCallback(() => {
    dispatch(unImpersonateUser(workspace, programId));
  }, [workspace, programId, dispatch]);

  const options: Option[] = useMemo(() => {
    const menuOptions: Option[] = [
      {
        label: t({id: 'Logout'}),
        value: 'logout',
        onClick: () => dispatch(logout()),
      },
    ];

    if (userIsImpersonated) {
      menuOptions.unshift({
        label: `${t({id: 'Stop using as', defaultMessage: 'Stop using as'})} ${userVisibleName}`,
        value: 'exit-impersonation',
        onClick: onUnImpersonateUser,
        lineSeparator: true,
      });
    }

    if (!userIsImpersonated || impersonatorIsSuperAdmin) {
      menuOptions.unshift({
        label: t({id: 'User profile'}),
        value: 'profile',
        onClick: () => dispatch(openProfileDialog()),
      });
    }

    return menuOptions;
  }, [
    dispatch,
    userIsImpersonated,
    onUnImpersonateUser,
    userVisibleName,
    impersonatorIsSuperAdmin,
  ]);

  return (
    <MenubarProfileContainer className="menubar-profile">
      <MenubarHelpComponent />

      <Select
        // menuIsOpen
        isMulti={false}
        styles={stylesOverwrites}
        onChange={value => {
          if (isSingleOption(value) && value?.onClick) {
            value.onClick();
          }
        }}
        options={options}
        components={{IndicatorsContainer, Option: OptionComponent}}
      />
    </MenubarProfileContainer>
  );
};

const MenubarHelpComponent = () => {
  const {workspace} = useWorkspace();

  return workspace === 'mrv' ? (
    <HubspotChatLaunchIcon />
  ) : (
    <QuestionCircledIcon id={'intercom-launch'} className="help-icon" />
  );
};

const stylesOverwrites: StylesConfig<Option> = {
  container: base => ({...base, ...MenubarMenuContainerStyles}),
  option: base => ({
    ...base,
    ...MenubarMenuOptionStyles,
  }),
  valueContainer: base => ({...base, width: 0, opacity: 0, padding: 0}), // hide the value container
  indicatorsContainer: base => ({
    ...base,
    paddingRight: '10px',
    paddingLeft: '10px',
  }),

  menu: base => ({
    ...base,
    ...MenubarMenuStyles,
    right: '8px', // create the same space as from the top (actual space from the menubar to the menu component, even so there is 12px margin)
  }),
  control: base => ({
    ...base,
    ...MenubarControlStyles,
    paddingRight: '10px',
  }),
  menuList: base => {
    return {...base, padding: 0};
  },
};

const MenubarProfileContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 6px;
  height: 100%; // keep it 100% for proper hover area
  .help-icon {
    cursor: pointer;
    color: #fff;
    width: 20px;
    height: 20px;
  }
`;

const IndicatorsStyledContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  cursor: pointer;
  color: ${({theme}) => theme.color.stroke.strong};

  .user-label {
    max-width: 150px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  .profile-icon {
    width: 16px;
    height: 16px;
    color: #fff;
  }
  &.impersonated {
    color: #f9d76d; // FIXME add color to the design system

    .profile-icon {
      color: #f9d76d; // FIXME add color to the design system
    }
  }
`;
