import {isDefined} from '_utils/typeGuards';
import {FluroButton} from 'components';
import FluroSteps from 'components/fluro-steps/fluro-steps-dynamic';
import type {StepsItem} from 'containers/si/types';
import React from 'react';
import {FontIcon} from 'react-md';
import styled from 'styled-components';

export const InlineStepper = ({
  stepItems,
  currentStep,
  onUpdateStep,
  finalStepComponent,
}: {
  onUpdateStep: (step: number) => void;
  currentStep: number;
  stepItems: Array<StepsItem>;
  finalStepComponent?: React.ReactElement;
}) => {
  const isLastStep = !(currentStep < stepItems.length - 1);

  const items = React.useMemo(() => stepItems.map((item, i) => ({...item, value: i})), [stepItems]);

  return (
    <StyledStepperWrapper>
      <FluroButton
        raised
        primary
        disabled={currentStep === 0}
        onClick={() => onUpdateStep(currentStep - 1)}
        iconBefore
        iconEl={<FontIcon>arrow_back</FontIcon>}
      >
        Back
      </FluroButton>
      <FluroSteps items={items} onItemClick={onUpdateStep} selectedItem={currentStep} />

      {isLastStep && isDefined(finalStepComponent) ? (
        finalStepComponent
      ) : (
        <FluroButton
          raised
          primary
          iconBefore={false}
          disabled={isLastStep || stepItems[currentStep + 1].disabled}
          onClick={() => onUpdateStep(currentStep + 1)}
          iconEl={<FontIcon>arrow_forward</FontIcon>}
        >
          Next
        </FluroButton>
      )}
    </StyledStepperWrapper>
  );
};

const StyledStepperWrapper = styled.div`
  display: flex;
  gap: 20px;
  align-items: flex-start;
  justify-content: center;

  /* Unfortunately, using styled(FluroSteps) doesn't work, and I have to target classname */
  .fluro-steps-container {
    width: fit-content;
    margin: -10px 0 0 0;
    padding: 0;
  }
  .fluro-step {
    width: unset;
    max-width: 150px;
    .line {
      top: 15px;
    }
  }
`;
