import type {MRVValue, MRVValuesRow} from './types';

/**
 * Returns the right entity id depending on the entity type.
 * field_id is legacy and is present only for the fields.
 * entity_id is the way to go and is present in farms and mobs.
 * The plan is to switch to entity_id everywhere and kill field_id.
 * https://regrow.atlassian.net/browse/FSB-12342
 */
export const getEntityId = (o: MRVValuesRow | MRVValue) =>
  o.entity_type === 'field' ? o.field_id : o.entity_id;

export const createRow = (
  entity_type: MRVValuesRow['entity_type'],
  entity_id: number,
  row_id: MRVValuesRow['row_id'],
  year: number,
  values: MRVValuesRow['values']
) => {
  const newRow: MRVValuesRow = {
    ...(entity_type === 'field' ? {entity_type, field_id: entity_id} : {entity_type, entity_id}),
    ...{
      row_id,
      year,
      values,
    },
  };
  return newRow;
};
