import zxcvbn from 'zxcvbn';
import Yup from 'yup';
import {t} from 'i18n-utils';

export const MIN_PASSWORD_LENGTH = 10;

export const validatePassword = (value: string, withError = false) => {
  if (withError) {
    return 1;
  }

  const result = zxcvbn(value || '');

  // if password length is valid and score 0 (too guessable) - replace score to avoid show 'Too short' message
  const score = value?.length >= MIN_PASSWORD_LENGTH && !result.score ? 1 : result.score;

  /**
   * Integer from 0-4 (useful for implementing a strength bar):
   * 0 too guessable: risky password. (guesses < 10^3)
   * 1 very guessable: protection from throttled online attacks. (guesses < 10^6)
   * 2 somewhat guessable: protection from unthrottled online attacks. (guesses < 10^8)
   * 3 safely unguessable: moderate protection from offline slow-hash scenario. (guesses < 10^10)
   * 4 very unguessable: strong protection from offline slow-hash scenario. (guesses >= 10^10)
   */
  return score;
};

export const validatePasswordSchema = (inputName = 'password', allowEmpty = false) => {
  return Yup.string().test(inputName, '', function (value) {
    if (allowEmpty && (value === '' || value === null)) {
      return true;
    }

    if (value === '' || value === null) {
      return this.createError({
        path: inputName,
        message: t({id: 'password is required field'}),
      });
    }

    if (value.length < MIN_PASSWORD_LENGTH) {
      return this.createError({
        path: inputName,
        message: t(
          {id: 'password must be at least {value} characters'},
          {value: MIN_PASSWORD_LENGTH}
        ),
      });
    }

    if (!/[A-Z]/.test(value)) {
      return this.createError({
        path: inputName,
        message: t({id: 'Reg.Validation.PassUppercaseMsg'}),
      });
    }

    if (!/[a-z]/.test(value)) {
      return this.createError({
        path: inputName,
        message: t({id: 'Reg.Validation.PassLowercaseMsg'}),
      });
    }

    if (!/\d/.test(value)) {
      return this.createError({
        path: inputName,
        message: t({id: 'Reg.Validation.PassNumberMsg'}),
      });
    }

    //any character except numbers and letters
    if (!/[^a-zA-Z0-9]/.test(value)) {
      return this.createError({
        path: inputName,
        message: t({id: 'Reg.Validation.PassSpecialCharactersMsg'}),
      });
    }

    const score = validatePassword(value);

    if (score < 2) {
      return this.createError({
        path: inputName,
        message: '',
      });
    }

    return true;
  });
};
