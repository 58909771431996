// @ts-nocheck
import {t} from 'i18n-utils';
import React from 'react';
import {FluroDatePicker, FluroAutocomplete} from 'components';
import moment from 'moment';
import {GLOBAL_FORMAT_DATE} from '_constants';
import {Button, TextField} from 'react-md';
import type {FertilizerApplication} from 'containers/map/features/nrx';
import {clamp, formatUnit} from '_utils';
import {FormattedMessage} from 'react-intl';
import {useAppSelector} from '_hooks';
import {selectMeasurement} from 'containers/login/login-selectors';
import {selectNrxFertilizerListItemData} from '../../../nrx/nrx-selectors';

type Props = {
  item: FertilizerApplication;
  prevSeason?: boolean;
  maxDate: any;
  fertilizerTypes: {value: number; label: string}[];
  onChange(label: string, value: any): void;
  onDelete(): void;
};
const ApplicationItem = ({
  item,
  prevSeason,
  maxDate,
  fertilizerTypes,
  onChange,
  onDelete,
}: Props) => {
  const measurement = useAppSelector(selectMeasurement);
  const {id, date, typeID, type, quantity, nitrogenPercentage, edit} = item;
  const fertilizerItem = useAppSelector(s => selectNrxFertilizerListItemData(s, typeID));

  const disabled = id !== 0 && !edit;
  const {type: typeValue = '', isLiquid = false} =
    type === 'Custom' ? {type: 'Other (solid)'} : fertilizerItem || {};
  const quantityLabelUnit = formatUnit(measurement, isLiquid ? 'l / ha' : 'kg / ha');

  return (
    <div key={id} className="application-item">
      <div className="application-item__date">
        <FluroDatePicker
          id={`application-date-${id}`}
          disabled={disabled}
          label={t({id: 'Application date'})}
          hideFormat={true}
          maxDate={maxDate}
          selected={moment(date, GLOBAL_FORMAT_DATE)}
          portal
          onChange={(value: any) => onChange('date', value.format(GLOBAL_FORMAT_DATE))}
        />
        <span className={'season'}>
          <FormattedMessage
            id={prevSeason ? 'previous <br></br> season' : 'current <br></br> season'}
            values={{br: () => <br />}}
          />
        </span>
      </div>

      <FluroAutocomplete
        id={`product-type-${id}`}
        label={t({id: 'Product applied'})}
        menuItems={fertilizerTypes}
        onAutocomplete={(value: any) => onChange('typeID', value)}
        value={typeValue}
        className={'application-item__select'}
        disabled={disabled}
        simplifiedMenu={false}
      />

      <TextField
        id={`product-nitrogen-value-${id}`}
        label="N%"
        placeholder={t({id: 'Enter N%'})}
        className="application-item__nitrogen-value"
        value={nitrogenPercentage}
        type={'number'}
        min={0}
        max={100}
        onChange={value => onChange('nitrogenPercentage', clamp(0, parseInt(`${value}`), 100))}
        disabled={disabled || typeID !== 0}
        errorText={t({id: 'Enter N%'})}
        error={typeID === 0 && !nitrogenPercentage}
      />

      <TextField
        id={`dose-applied-${id}`}
        label={`${t({id: 'Dose applied'})} ${quantityLabelUnit}`}
        placeholder={t({id: 'Enter a value'})}
        className="application-item__quantity"
        value={quantity}
        type={'number'}
        min={1}
        max={2000}
        onChange={(value: string) => onChange('quantity', parseFloat(value))}
        disabled={disabled}
        errorText={
          quantity < 1
            ? `${t({id: 'The min value is'})} 1`
            : `${t({id: 'The max value is'})} ${2000}`
        }
        error={quantity < 1 || quantity > 2000}
      />

      {id !== 0 && (
        <Button className={'edit'} icon onClick={() => onChange('edit', !edit)}>
          {t({id: 'edit'})}
        </Button>
      )}

      {onDelete && (
        <Button className={'delete'} icon onClick={onDelete}>
          {t({id: 'delete'})}
        </Button>
      )}
    </div>
  );
};

export default ApplicationItem;
