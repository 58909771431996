// @ts-nocheck
import Server from './server';
import type {AxiosPromise} from 'axios';
import axios from 'axios';
import {jobTitles} from 'containers/profile/constants';
import type {
  LoginPayload,
  LoginResponse,
  UpdatePasswordPayload,
  ProductionWiseAuthPayload,
} from 'containers/login/types';
import config from '_environment';
import type {User} from '../containers/admin/users/types';
import {isLoginAllowedAnalytics} from '_utils/analytics';

export default class AuthAPI {
  static authenticate(credentials: LoginPayload): AxiosPromise<LoginResponse> {
    return Server.post('login', credentials);
  }

  static logout(token: string) {
    return Server.get('logout', {params: {token}});
  }

  static googleAuth(code: string) {
    return Server.get(`login/google/${code}`);
  }

  static agxAuth(code: string) {
    return Server.get(`login/agx/${code}`);
  }

  static productionWiseAuth(
    productionWiseAuth: ProductionWiseAuthPayload
  ): AxiosPromise<LoginResponse> {
    return Server.post(
      `${config.baseUrl}login/productionwise?id_token=${productionWiseAuth.id_token}&state=${productionWiseAuth.state}`
    );
  }

  static verifyEmail(uuid: string) {
    return Server.get(`email-verification/${uuid}`);
  }

  static verifyEmailNew(uuid: string, user?: {password: string}) {
    return Server.post(`email-verification/${uuid}`, user);
  }

  static signUp(user: Partial<User>) {
    let invite = '';

    try {
      const search = new URLSearchParams(window.location.search).get('invite');
      invite = search ? `?invite=${search}` : '';
    } catch (e) {}

    return Server.post(`sign-up${invite}`, user);
  }

  static signUpCarbon(app = 'cargill', data: any) {
    return Server.post(`sign-up-carbon?program=${encodeURIComponent(app)}`, data);
  }

  static signUpHubspot({firstName, email, phone, surname, interestClass}: Partial<User>) {
    const {
      hubspot: {portalId = '4430549', formGuid = 'e3f6fe2a-31d1-49ac-b3f4-b4b14c93bf64'},
      env,
    } = config;

    if (env !== 'production' || !isLoginAllowedAnalytics(email)) return false;
    const jobTitle = jobTitles.find(t => t.value === interestClass).label;
    const fields = [
      {
        name: 'firstname',
        value: firstName,
      },
      {
        name: 'lastname',
        value: surname,
      },
      {
        name: 'email',
        value: email,
      },
      {
        name: 'tell_us_about_yourself',
        value: jobTitle,
      },
      {
        name: 'mobilephone',
        value: phone,
      },
    ];
    return axios.post(
      `https://api.hsforms.com/submissions/v3/integration/submit/${portalId}/${formGuid}`,
      {fields}
    );
  }

  static emailResetPassword(email: string) {
    return Server.put('email-reset-password', {email});
  }

  static emailResetPasswordCarbon(email: string, app = 'cargill') {
    return Server.put(`email-reset-password-carbon?program=${encodeURIComponent(app)}`, {email});
  }

  static resetPassword(params: UpdatePasswordPayload) {
    return Server.put('reset-password', params);
  }

  static updateUserPassword(params: UpdatePasswordPayload) {
    return Server.put('profile/update-user-password', params);
  }

  static getPreSignUp(uuid: string) {
    return Server.get(`presignup-data/${uuid}`);
  }

  static unImpersonateUser() {
    return Server.post<LoginResponse>(`login/original_user`, {params: {__skipPreloader: true}});
  }
}
