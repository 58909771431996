// @ts-nocheck
import {yupResolver} from '@hookform/resolvers/yup';
import {Flex, FluroButton, FluroDialog, Text} from 'components';
import type {Props as OptionsGroupProps} from 'components/grouped-selection-control/options-group';
import MultiSelectChips from 'components/multi-select-chips';
import {ContainersSeparator} from 'components/reusable-conponents.styled';
import SimplePreloader from 'components/simple-preloader';
import {AreaSelectMenu} from 'containers/map/features/sustainability-insights/area-select/area-select-menu';
import {t} from 'i18n-utils';
import range from 'lodash/range';
import {AsyncStatusType, Status} from 'modules/helpers';
import {
  metricGroupsLabels,
  SI_YEARS_AVAILABLE,
  AGG_LEVEL_TO_LABEL,
} from 'modules/sustainability-insights/constants';
import type {
  AreaId,
  SIAggLevel,
  SIMetricAreaType,
  SIMetricGroup,
  SIPolicy,
  SIPolicyRequestBody,
  SIPolicyState,
} from 'modules/sustainability-insights/types';
import type {ComponentType} from 'react';
import React, {useEffect, useMemo, useState} from 'react';
import {useForm} from 'react-hook-form';
import {SelectField, SelectionControl} from 'react-md';
import Yup from 'yup';
import {showNotification} from 'components/notification/notification';
import {useAppDispatch, useAppSelector} from '_hooks';
import {selectAsyncRequestStatus} from 'modules/global/selectors';
import {calcPlural, capitalizeFirstLetter} from '_utils/pure-utils';
import type {User} from '../../users/types';
import {selectUsersList} from '../../users/users-selectors';
import {deleteSiPolicy, fetchStatesList, updateSIPolicy} from '../actions';
import {
  selectAdminCountiesOptions,
  selectAdminCountyIds,
  selectAdminMeta,
  selectAdminStatesIds,
} from '../selectors';

type FormValues = Pick<
  SIPolicyRequestBody,
  'user_ids' | 'years' | 'metric_groups' | 'states' | 'agg_levels'
> & {
  areaType: SIAggLevel;
};
type Props = {
  visible: boolean;
  onHide: () => void;
  usersObject: {[userId: number]: User};
  defaultValues?: Partial<FormValues>;
};

function arrayIsNotEmpty<T>(this: Yup.ArraySchema<T>, message?: string) {
  return this.test('array should not be empty', message || '', value => value.length !== 0);
}
Yup.addMethod(Yup.array, 'arrayIsNotEmpty', arrayIsNotEmpty);

const StateAreaSelect: ComponentType<{
  selectedIds: number[];
  setSelectedIds: (ids: number[]) => void;
}> = ({selectedIds, setSelectedIds}) => {
  const statesIds = useAppSelector(selectAdminStatesIds);
  const SIMeta = useAppSelector(selectAdminMeta);
  const viewportHeight = useAppSelector(s => s.viewport.height);

  const options = useMemo(() => {
    return statesIds.map(id => ({value: parseInt(`${id}`), label: SIMeta[id].name}));
  }, [SIMeta, statesIds]);

  const handleSelect: OptionsGroupProps['onChange'] = selectedChange => {
    // select all
    if (typeof selectedChange === 'boolean') {
      const ids = selectedChange ? statesIds : [];
      setSelectedIds(ids);
      return;
    }

    const {selected, value} = selectedChange;
    const id = parseInt(value, 10);
    const ids = selected ? selectedIds.concat(id) : selectedIds.filter(v => v !== id);

    setSelectedIds(ids);
  };

  return (
    <AreaSelectMenu
      allowSelectAll
      allSelected={selectedIds.length && selectedIds.length === statesIds.length}
      allLabel="Select all states"
      options={options}
      selected={selectedIds.map(String)}
      onSelect={handleSelect}
      style={{maxHeight: viewportHeight * 0.4 + 'px'}}
      active={!!selectedIds.length}
    />
  );
};

const CountyAreaSelect: ComponentType<{
  selectedIds: number[];
  setSelectedIds: (ids: number[]) => void;
  options: any;
}> = ({selectedIds, setSelectedIds, options}) => {
  const countiesIds = useAppSelector(selectAdminCountyIds);
  const viewportHeight = useAppSelector(s => s.viewport.height);

  const handleSelect = (
    selectedChange: {selected: boolean; value: string; values: string[] | null} | boolean
  ) => {
    if (typeof selectedChange === 'boolean') {
      const ids = selectedChange ? countiesIds : [];
      setSelectedIds(ids);
      return;
    }
    const {selected, values} = selectedChange;
    const valueIds = values.map(v => parseInt(v, 10));
    const ids = selected
      ? selectedIds.concat(valueIds)
      : selectedIds.filter(v => !valueIds.includes(v));

    setSelectedIds(ids);
  };

  return (
    <AreaSelectMenu
      allowSelectAll
      allSelected={selectedIds?.length && selectedIds?.length === countiesIds?.length}
      allLabel="Select all counties"
      options={options}
      selected={selectedIds.map(String)}
      onSelect={handleSelect}
      style={{maxHeight: viewportHeight * 0.4 + 'px'}}
      active={!!selectedIds.length}
    />
  );
};

// const SupplyShedAreaSelect: ComponentType<{
//   selectedIds: number[];
//   setSelectedIds: (ids: number[]) => void;
// }> = ({selectedIds, setSelectedIds}) => {
//   const viewportHeight = useAppSelector(s => s.viewport.height);

//   const supplyShedIds = useMemo(() => getTypedKeys(SUPPLY_SHEDS), []);

//   const options = useMemo(() => {
//     return supplyShedIds.map(id => ({value: id, label: SUPPLY_SHEDS[id]}));
//   }, [supplyShedIds]);

//   const handleSelect: OptionsGroupProps['onChange'] = selectedChange => {
//     // select all
//     if (typeof selectedChange === 'boolean') {
//       const ids = selectedChange ? supplyShedIds : [];
//       setSelectedIds(ids);
//       return;
//     }

//     const {selected, value} = selectedChange;
//     const id = parseInt(value, 10);
//     const ids = selected ? selectedIds.concat(id) : selectedIds.filter(v => v !== id);

//     setSelectedIds(ids);
//   };

//   return (
//     <AreaSelectMenu
//       allowSelectAll
//       allSelected={selectedIds.length && selectedIds.length === supplyShedIds.length}
//       allLabel="Select all supply sheds"
//       options={options}
//       selected={selectedIds.map(String)}
//       onSelect={handleSelect}
//       style={{maxHeight: viewportHeight * 0.4 + 'px'}}
//     />
//   );
// };

const ManageSITagsPopUp = ({visible, onHide, defaultValues}: Props) => {
  const dispatch = useAppDispatch();
  const usersList = useAppSelector(selectUsersList);
  const classifiedStateOptions = useAppSelector(selectAdminCountiesOptions);
  const SIMeta = useAppSelector(selectAdminMeta);

  const isUpdatingSIPolicy =
    useAppSelector(s => selectAsyncRequestStatus(s, AsyncStatusType.updateSIPolicy)) ===
    Status.Pending;

  const [selectedAreaIds, setSelectedAreaIds] = useState<AreaId[]>([]);

  /**
   * Form related data
   */
  const {
    handleSubmit,
    formState: {errors},
    setValue,
    watch,
    register,
    reset: resetForm,
  } = useForm<FormValues>({
    reValidateMode: 'onChange',
    resolver: yupResolver(
      Yup.object().shape({
        // @ts-expect-error error leftover from convertion to strict mode, please fix
        user_ids: Yup.array().arrayIsNotEmpty(''),
        // @ts-expect-error error leftover from convertion to strict mode, please fix
        states: Yup.array().arrayIsNotEmpty(),
        // @ts-expect-error error leftover from convertion to strict mode, please fix
        metric_groups: Yup.array().arrayIsNotEmpty(),
        years: Yup.array(),
        'years[1]': Yup.number().test('custom date check', '', function () {
          return this.parent.years?.[0] <= this.parent.years?.[1];
        }),
      })
    ),
    defaultValues: {
      user_ids: [],
      years: [SI_YEARS_AVAILABLE[0], SI_YEARS_AVAILABLE[0]],
      metric_groups: [],
      states: [],
      agg_levels: ['state'],
      areaType: 'state',
    },
  });

  register('user_ids');
  register('years');
  register('metric_groups');
  register('states');
  register('areaType');
  register('agg_levels');

  const values = watch();

  const onSetValue = (prop: keyof FormValues, value: any) => {
    setValue(prop, value, {shouldValidate: true});
    if (prop === 'areaType' && value !== 'supply_shed') {
      // special case for supply_shed
      resetSelectedAreas();
    }
  };

  const onSetMetricGroup = (metricGroup: SIMetricGroup, value: boolean) => {
    onSetValue(
      'metric_groups',
      value
        ? [...values.metric_groups, metricGroup]
        : values.metric_groups.filter(group => group !== metricGroup)
    );
  };

  const onSubmit = () => {
    const requestData: SIPolicyRequestBody = {
      years: range(values.years[0] as number, values.years[1] as number).concat(
        values.years[1] as number
      ),
      user_ids: values.user_ids,
      metric_groups: values.metric_groups,
      agg_levels: values.agg_levels,
      states: values.states,
    };
    dispatch(updateSIPolicy(requestData)).then(() => {
      const userOrUsers = calcPlural('user', values.user_ids);
      const wasOrWere = calcPlural('was', values.user_ids, 'were');
      const addedOrUpdated = defaultValues?.user_ids ? 'updated' : 'added';

      showNotification({
        title: t({id: 'note.success', defaultMessage: 'Success'}),
        message: `The SI policy for ${userOrUsers} with id ${values.user_ids} ${wasOrWere} successfully ${addedOrUpdated}.`,
        type: 'success',
      });

      onHide();
    });
  };
  /** END Form related data*/

  /**
   * Initialization actions
   */

  const classifyDefaultSelectedAreaIds = (defaultValues: Partial<FormValues>) => {
    if (defaultValues.agg_levels.length === 1 && defaultValues.agg_levels[0] === 'state') {
      return defaultValues['states'].map(state => state.fips_code);
    }

    let selectedCountyIds: AreaId[] = [];
    defaultValues['states'].forEach(state => {
      if (Array.isArray(state.counties)) {
        selectedCountyIds = [
          ...selectedCountyIds,
          ...state.counties.map(county => county.fips_code),
        ];
      } else if (state.counties === '*') {
        const classifiedStateOption = classifiedStateOptions.find(
          stateOption => `${stateOption.value}` === `${state.fips_code}`
        );
        selectedCountyIds = classifiedStateOption.options.map(county => county.value as number);
      }
    });
    return selectedCountyIds;
  };

  useEffect(() => {
    // load states/counties list to use in the counties/state selector
    // if (isLoadingSIData || statesIds?.length) return;
    dispatch(fetchStatesList());
  }, [dispatch]);

  useEffect(() => {
    // spread defaultValues to formData
    if (visible && defaultValues) {
      if (!classifiedStateOptions.length) return; // wait for StateOptions ( used in classifyDefaultSelectedAreaIds() )
      Object.keys(defaultValues).forEach((key: keyof FormValues) => {
        if (key === 'states') {
          setSelectedAreaIds(classifyDefaultSelectedAreaIds(defaultValues));
        } else if (key === 'agg_levels') {
          // classify areaType depending on the scale value
          setValue(key, defaultValues[key]);
          setValue('areaType', defaultValues['agg_levels'].includes('county') ? 'county' : 'state');
        } else if (key === 'years') {
          const yearsValue = defaultValues.years;
          // get the first and the last date
          setValue(key, [yearsValue[0], yearsValue[yearsValue.length - 1]]);
        } else {
          setValue(key, defaultValues[key]);
        }
      });
    } else if (!visible) {
      resetForm();
      resetSelectedAreas();
    }
  }, [visible, defaultValues, classifiedStateOptions]);

  useEffect(() => {
    if (values.areaType === 'supply_shed' && !values.agg_levels.includes('supply_shed')) {
      onSetValue('agg_levels', [...values.agg_levels, 'supply_shed']); // special case for supply sheds
    }
  }, [values.areaType]);

  /** END Initialization actions */

  /**
   * Lists data preparation
   */

  const areaTypesMenuList = useMemo(() => {
    const availableAggLevels: SIAggLevel[] = ['supply_shed', 'state', 'county'];
    return availableAggLevels.map(areaType => ({
      value: areaType,
      label: capitalizeFirstLetter(AGG_LEVEL_TO_LABEL[areaType]),
    }));
  }, []);

  const preparedUsersList = useMemo(() => {
    return usersList.map(({id, email}) => ({value: id, label: email}));
  }, [usersList]);

  const aggregationsMenuList = useMemo(() => {
    const resultList = [
      {label: 'State', value: 'state'},
      {label: 'County', value: 'county'},
      {label: 'HUC8', value: 'huc8'},
      {label: 'HUC10', value: 'huc10'},
      {label: 'HUC12', value: 'huc12'},
      {label: 'CRD', value: 'crd'},
    ];

    if (values.agg_levels.includes('supply_shed')) {
      resultList.push({label: 'Supply Sheds', value: 'supply_shed'});
    }

    return resultList;
  }, [values.agg_levels]);

  const aggregationNotRemovableListItems = useMemo(() => {
    const resultList = ['state'];

    if (values.areaType === 'county') {
      resultList.push('county');
    }

    return resultList;
  }, [values.areaType]);

  /** END Lists data preparation */

  /**
   * Side effects, data manipulations
   */

  useEffect(() => {
    if (values.areaType === 'county' && !values.agg_levels.includes('county')) {
      onSetValue('agg_levels', [...values.agg_levels, 'county']); // automatically add "county" agg_level if areaType is "county"
    }
  }, [values.areaType]);

  useEffect(() => {
    // grower_demographics can be only selected additionally to any other metrics_group, but not as a single value
    if (values.metric_groups.length === 1 && values.metric_groups[0] === 'grower_demographics') {
      onSetValue('metric_groups', []);
    }
  }, [values.metric_groups]);

  useEffect(() => {
    /**
     * this big one classifies states & counties from selectedAreaIds[] to SIPolicyState format
     */
    if (Object.keys(SIMeta).length === 0) return;
    if (selectedAreaIds.length === 0 && values.states.length === 0) return; // prevent useless setValue

    const classifiedStates: Record<AreaId, SIPolicyState> = {};
    const areaObjs = selectedAreaIds.map(areaId => SIMeta[areaId]).filter(Boolean);

    areaObjs.forEach(area => {
      if (area.areaType === 'county') {
        if (!classifiedStates[area.statefp]) {
          // add a state obj
          classifiedStates[area.statefp] = {
            name: SIMeta[area.statefp].name,
            fips_code: area.statefp,
            counties: [],
          };
        }
        (classifiedStates[area.statefp].counties as SIMetricAreaType[]).push({
          // add a county to the state
          name: area.name,
          fips_code: parseInt(`${area.id}`),
        });
      } else {
        classifiedStates[area.id] = {
          name: area.name,
          fips_code: parseInt(`${area.id}`),
          counties: [],
        };
      }
    });

    const resultArray: SIPolicy['states'] = [];

    Object.values(classifiedStates).forEach(stateArea => {
      // make sure the fips_codes are numbers + replace list of all counties with * in case all are selected
      const classifiedStateOption = classifiedStateOptions.find(
        stateOption => `${stateOption.value}` === `${stateArea.fips_code}`
      );
      if (
        values.areaType === 'state' ||
        (classifiedStateOption &&
          stateArea.counties.length === classifiedStateOption.options.length)
      ) {
        resultArray.push({
          ...stateArea,
          fips_code: parseInt(`${stateArea.fips_code}`),
          counties: '*',
        });
      } else {
        resultArray.push({...stateArea, fips_code: parseInt(`${stateArea.fips_code}`)});
      }
    });

    onSetValue('states', resultArray);
  }, [selectedAreaIds, SIMeta]);
  /** END Side effects, data manipulations */

  /**
   * Methods
   */

  const resetSelectedAreas = () => {
    if (selectedAreaIds.length !== 0) {
      setSelectedAreaIds([]);
    }
  };

  const deleteTag = async () => {
    await dispatch(deleteSiPolicy(defaultValues.user_ids[0]));
    onHide();
  };

  /** END Methods */

  return (
    <FluroDialog
      onHide={onHide}
      title={
        <Flex alignItems={'center'}>
          Enable sustainability insights{' '}
          <SimplePreloader
            className={'margin-left-10'}
            statusKeys={[AsyncStatusType.updateSIPolicy]}
          />
        </Flex>
      }
      visible={visible}
      id={'manage-SI-tags-popup'}
      width={420}
    >
      <form>
        <Text className={'inputs-section-title'}>Users</Text>
        <MultiSelectChips
          className={'select-users-container'}
          label={'Search to add users'}
          items={preparedUsersList}
          selectedItems={values.user_ids}
          onSelectChip={(selectedUsers: number[]) => onSetValue('user_ids', selectedUsers)}
          error={Boolean(errors.user_ids)}
          errorText={'Select a user'}
          notRemovableList={defaultValues?.user_ids || []}
        />
        <ContainersSeparator />
        <Text className={'inputs-section-title'}>Years</Text>
        <Flex className={'dates-container'}>
          <SelectField
            id="select-from-date"
            className={'date-selector'}
            label={'From'}
            menuItems={SI_YEARS_AVAILABLE}
            onChange={(value: number) => onSetValue('years', [value, values.years[1]])}
            value={values.years[0]}
          />
          <SelectField
            id="select-till-date"
            label={'Until'}
            className={'date-selector'}
            menuItems={SI_YEARS_AVAILABLE}
            onChange={(value: number) => onSetValue('years', [values.years[0], value])}
            value={values.years[1]}
            error={Boolean(errors.years)}
            errorText={'Year till cannot be after Year from'}
          />
        </Flex>
        <ContainersSeparator />
        <Text className={'inputs-section-title'}>Product coverage</Text>
        <Flex className={'product-coverage-container'}>
          <SelectField
            id="area-type"
            label={'Area type'}
            className={'area-type-selector'}
            menuItems={areaTypesMenuList}
            onChange={(value: string) => onSetValue('areaType', value)}
            value={values.areaType}
          />

          <Flex className={'area-selector-container'}>
            {values.areaType === 'state' && (
              <StateAreaSelect selectedIds={selectedAreaIds} setSelectedIds={setSelectedAreaIds} />
            )}
            {values.areaType === 'county' && (
              <CountyAreaSelect
                options={classifiedStateOptions}
                selectedIds={selectedAreaIds}
                setSelectedIds={setSelectedAreaIds}
              />
            )}
            {/* {values.areaType === 'supply_shed' && (
              <SupplyShedAreaSelect
                selectedIds={selectedAreaIds}
                setSelectedIds={setSelectedAreaIds}
              />
            )} */}
            {errors.states && values.areaType !== 'supply_shed' && (
              <div
                className={
                  'select-area-error md-text-field-message-container md-full-width md-text--error'
                }
              >
                Select {values.areaType}
              </div>
            )}
          </Flex>
        </Flex>

        <MultiSelectChips
          className={'select-aggregation-container'}
          label={'Select aggregation by'}
          items={aggregationsMenuList}
          selectedItems={values.agg_levels}
          notRemovableList={aggregationNotRemovableListItems}
          onSelectChip={(values: SIAggLevel[]) => onSetValue('agg_levels', values)}
        />

        <ContainersSeparator />
        <Text className={'inputs-section-title'}>Metrics</Text>

        <Flex>
          <SelectionControl
            id={`cover_crops-metric-group`}
            name={''}
            label={metricGroupsLabels['cover_crops']}
            type={'checkbox'}
            checked={values.metric_groups.includes('cover_crops')}
            onChange={(value: boolean) => onSetMetricGroup('cover_crops', value)}
          />
          <SelectionControl
            id={`tillage_practices-metric-group`}
            name={''}
            label={metricGroupsLabels['tillage_practices']}
            type={'checkbox'}
            checked={values.metric_groups.includes('tillage_practices')}
            onChange={(value: boolean) => onSetMetricGroup('tillage_practices', value)}
          />
          <SelectionControl
            id={`ghg-metric-group`}
            name={''}
            label={metricGroupsLabels['ghg']}
            type={'checkbox'}
            checked={values.metric_groups.includes('ghg')}
            onChange={(value: boolean) => onSetMetricGroup('ghg', value)}
          />
          <SelectionControl
            id={`soc_sequestration-metric-group`}
            name={''}
            label={metricGroupsLabels['soc_sequestration']}
            type={'checkbox'}
            checked={values.metric_groups.includes('soc_sequestration')}
            onChange={(value: boolean) => onSetMetricGroup('soc_sequestration', value)}
          />
          <SelectionControl
            id={`yield-metric-group`}
            name={''}
            label={metricGroupsLabels['crop_yield']}
            type={'checkbox'}
            checked={values.metric_groups.includes('crop_yield')}
            onChange={(value: boolean) => onSetMetricGroup('crop_yield', value)}
          />
          <SelectionControl
            id={`fe-metric-group`}
            name={''}
            label={metricGroupsLabels['ef']}
            type={'checkbox'}
            checked={values.metric_groups.includes('ef')}
            onChange={(value: boolean) => onSetMetricGroup('ef', value)}
          />
          <SelectionControl
            id={`fertilizer-metric-group`}
            name={''}
            label={metricGroupsLabels['fertilizer']}
            type={'checkbox'}
            checked={values.metric_groups.includes('fertilizer')}
            onChange={(value: boolean) => onSetMetricGroup('fertilizer', value)}
          />
          <SelectionControl
            id={`carbon-metric-group`}
            name={''}
            label={metricGroupsLabels['carbon']}
            type={'checkbox'}
            checked={values.metric_groups.includes('carbon')}
            onChange={(value: boolean) => onSetMetricGroup('carbon', value)}
          />
          {errors.metric_groups && (
            <div
              className={
                'select-metric-error md-text-field-message-container md-full-width md-text--error'
              }
            >
              Select metric
            </div>
          )}
        </Flex>

        {values.metric_groups.length !== 0 && (
          <SelectionControl
            id={`grower_demographics-metric-group`}
            name={''}
            label={metricGroupsLabels['grower_demographics']}
            type={'switch'}
            checked={values.metric_groups.includes('grower_demographics')}
            onChange={(value: boolean) => onSetMetricGroup('grower_demographics', value)}
          />
        )}

        <Flex className={'action-bts-container'}>
          <FluroButton disabled={!defaultValues?.user_ids?.[0]} onClick={deleteTag} raised blank>
            Delete tag
          </FluroButton>

          <FluroButton
            disabled={isUpdatingSIPolicy}
            raised
            primary
            onClick={handleSubmit(onSubmit)}
          >
            Save and close
          </FluroButton>
        </Flex>
      </form>
    </FluroDialog>
  );
};

export default ManageSITagsPopUp;
