import React, {useEffect, useState} from 'react';
import {useAppSelector} from '_hooks';
import {Flex, Text} from 'components';
import {
  selectReportDashboardData,
  selectReportDataUnits,
  selectReportAvailableSourcingRegions,
  selectReportPageIndex,
  selectReportPageSize,
} from 'containers/si/module/selectors';
import {selectCropTypesCDLIdMap} from 'modules/global/selectors';
import type {SIScenarioAP} from 'containers/si/types';
import type {EmissionsFactorDetails} from 'containers/si/api/apiTypes';
import {SIScenarioKPIs} from 'containers/si/types';
import {selectMeasurement} from 'containers/login/login-selectors';
import {
  KG_PER_M2_TO_TONNES_PER_ACRE,
  KG_PER_M2_TO_TONNES_PER_HECTARE,
  REPORT_DASHBOARD_EF_DETAILS,
} from 'containers/si/constants';
import {calculateKPIValue} from 'containers/si/planning-report/dashboard/utils/calculate-scenario';
import {getRegionName} from 'containers/si/planning-report/dashboard/utils/utils';
import {AdjustableCard, GlobalEFTooltipStyles} from '../../styled-components';
import {EmissionFactorCardTooltip} from './emission-factor-card-tooltip';
import {StackedBarChart} from '../../components/stacked-bar-chart/stacked-bar-chart';
import {StackedBarTick} from '../../components/stacked-bar-chart/stacker-bar-tick';
import {getTypedValues} from '_utils/object';
import {EFTooltip} from './emission-factor-tooltip';
import {sum} from '_utils/pure-utils';

export type BarChartData = {
  index: string;
  book: number;
  baseline: number;
  scenario: number;
  ef: number;
  detailedData: {
    book: EmissionsFactorDetails;
    baseline: EmissionsFactorDetails;
    scenario: EmissionsFactorDetails;
    fullScenario: SIScenarioAP;
  };
};

export const EmissionFactorCard = () => {
  const dashboardData = useAppSelector(selectReportDashboardData) as SIScenarioAP[]; // This component is only used for AP reports
  const units = useAppSelector(selectReportDataUnits);
  const cropsById = useAppSelector(selectCropTypesCDLIdMap);
  const availableSourcingRegions = useAppSelector(selectReportAvailableSourcingRegions);
  const measurement = useAppSelector(selectMeasurement);

  // Convert
  // 1 kg_per_m2 === 10 Tonnes Per Hectare (t/ha)
  // 1 kg_per_m2 === 4.046856422400006 Tonnes Per Acre (t/ac)
  const unitConversion =
    measurement === 'ha' ? KG_PER_M2_TO_TONNES_PER_HECTARE : KG_PER_M2_TO_TONNES_PER_ACRE;

  // Used for the numbering in the circle
  const pageIndex = useAppSelector(selectReportPageIndex);
  const pageSize = useAppSelector(selectReportPageSize);
  const startNumber = pageIndex * pageSize + 1;

  const [barChartData, setBarChartData] = useState<BarChartData[]>([]);

  useEffect(() => {
    // Generate Chart Data
    const cardData: BarChartData[] = [];

    dashboardData.map((scenario, index) => {
      const cropName = cropsById[Number(scenario.crop_type)]?.label || scenario.crop_type;
      const regionName = getRegionName(scenario, availableSourcingRegions);

      const cardLabel = `${index}+++${regionName}+++${cropName}+++${scenario.id}`; // using the scenario id to make sure the name is unique
      const scenarioData = calculateKPIValue({
        scenario,
        kpi: SIScenarioKPIs.ef_reductions,
        units,
        measurement,
        round: true,
        decimals: 2,
      }).value;

      return cardData.push({
        index: cardLabel,
        book: sum(getTypedValues(scenario.book.emission_factors)),
        baseline: sum(getTypedValues(scenario.baseline.emission_factors)),
        scenario: sum(getTypedValues(scenario.simulation.emission_factors)),
        ef: scenarioData,
        detailedData: {
          book: scenario.book.emission_factors,
          baseline: scenario.baseline.emission_factors,
          scenario: scenario.simulation.emission_factors,
          fullScenario: scenario,
        },
      });
    });

    setBarChartData(cardData);
  }, [availableSourcingRegions, cropsById, dashboardData, measurement, unitConversion, units]);

  return (
    <AdjustableCard max={1560} min={823} base={1560} height={500}>
      <GlobalEFTooltipStyles />
      <Flex justifyContent="space-between" nowrap>
        <Text variant="h3" bold className="margin-bottom-4">
          Emission factor analysis
        </Text>
        <EmissionFactorCardTooltip />
      </Flex>
      <Text tertiary variant="medium" className="margin-bottom-4">
        Annual average change of CO2 eq. per kilogram of yield relative to the baseline
      </Text>
      <div className="chart-container">
        <StackedBarChart
          barChartData={barChartData}
          keys={['book', 'baseline', 'scenario']}
          layout="horizontal"
          reverse
          customTick={tickProps => (
            <StackedBarTick
              startNumber={startNumber}
              dataLength={barChartData.length}
              circle
              region
              {...tickProps}
            />
          )}
          renderTooltip={(data, key) => (
            <EFTooltip
              key={key}
              details={REPORT_DASHBOARD_EF_DETAILS[key as keyof EmissionsFactorDetails]}
              detailedData={data.data.detailedData}
              efComponent={key as keyof EmissionsFactorDetails}
            />
          )}
          legends={getTypedValues(REPORT_DASHBOARD_EF_DETAILS).map(item => {
            return {
              id: item.label,
              label: item.label,
              color: item.color,
            };
          })}
        />
      </div>
    </AdjustableCard>
  );
};
