// @ts-nocheck
import {t} from 'i18n-utils';
import React, {useMemo} from 'react';
import {convertUnit} from '_utils';
import {calcFieldsSummary} from './utils';
import {
  FieldsInfoLineWrapper,
  FieldsInfoLineItem,
  FieldsInfoLineValue,
  FieldsInfoLineLabel,
  RegionIcon,
} from './farm.styled';
import {useAppSelector} from '_hooks';

type Props = {
  filteredIds?: number[];
};

const FieldsInfoLine = ({filteredIds}: Props) => {
  const fields = useAppSelector(state => state.map.fields);
  const measurement = useAppSelector(state => state.login.user.settings.measurement);

  const {
    selectedFields,
    selectedFieldsArea,
    preparedFields,
    allFieldsArea,
    regions,
    selectedFieldsRegions,
  } = useMemo(() => calcFieldsSummary(fields, filteredIds), [fields, filteredIds, measurement]);

  if (selectedFields.length) {
    return (
      <FieldsInfoLineWrapper>
        <FieldsInfoLineItem className="no-line-break">
          <FieldsInfoLineValue>{selectedFields.length}</FieldsInfoLineValue>{' '}
          <FieldsInfoLineLabel>
            {t({id: 'fields plural selected'}, {count: selectedFields.length})}
          </FieldsInfoLineLabel>
        </FieldsInfoLineItem>

        <FieldsInfoLineItem>
          <FieldsInfoLineValue>
            {convertUnit(measurement, 'ac', selectedFieldsArea || 0)}
          </FieldsInfoLineValue>
          <FieldsInfoLineLabel>{t({id: measurement})}</FieldsInfoLineLabel>
        </FieldsInfoLineItem>

        {selectedFieldsRegions.length ? (
          <FieldsInfoLineItem invert>
            <FieldsInfoLineValue>
              <RegionIcon />
            </FieldsInfoLineValue>
            <FieldsInfoLineLabel>{selectedFieldsRegions.join(', ')}</FieldsInfoLineLabel>
          </FieldsInfoLineItem>
        ) : null}
      </FieldsInfoLineWrapper>
    );
  }

  return (
    <FieldsInfoLineWrapper>
      <FieldsInfoLineItem className="no-line-break">
        <FieldsInfoLineValue>{preparedFields.length}</FieldsInfoLineValue>
        <FieldsInfoLineLabel>
          {t({id: 'field plural'}, {count: preparedFields.length})}
        </FieldsInfoLineLabel>
      </FieldsInfoLineItem>

      <FieldsInfoLineItem>
        <FieldsInfoLineValue>
          {convertUnit(measurement, 'ac', allFieldsArea || 0)}
        </FieldsInfoLineValue>
        <FieldsInfoLineLabel>{t({id: measurement})}</FieldsInfoLineLabel>
      </FieldsInfoLineItem>

      <FieldsInfoLineItem invert>
        <FieldsInfoLineValue>
          <RegionIcon />
        </FieldsInfoLineValue>
        <FieldsInfoLineLabel>{regions.join(', ')}</FieldsInfoLineLabel>
      </FieldsInfoLineItem>
    </FieldsInfoLineWrapper>
  );
};

export default FieldsInfoLine;
