import type {Map} from 'leaflet';
import L from 'leaflet';
import 'leaflet-draw/dist/leaflet.draw.css';
import 'leaflet-measure';
import 'leaflet-measure/dist/leaflet-measure.css';
import 'leaflet/dist/leaflet.css';
import React, {useCallback, useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import {useMediaQuery} from 'react-responsive';
import {Redirect, useRouteMatch} from 'react-router-dom';
import {SI_MAP, SI_ABATEMENT_POTENTIAL} from './routes';
import {mapLoading, toggleMapView} from 'modules/global/actions';
import {selectIsSI} from 'modules/global/selectors';
import _config from '_environment';
import {useAppSelector} from '_hooks';
import {validateBounds} from '_utils/';
import {getPanelRightPadding} from '../../_constants';
import {reportError} from '../error-boundary';
import {SIGeometryFilterType} from 'modules/sustainability-insights/types';
import {Provider} from 'containers/map/context';
import {SIOverlay as SustainabilityInsightsOverlay} from 'containers/map/features/sustainability-insights/overlay';
import {markerIcon} from 'containers/map/icons';
import 'containers/map/index.scss';
import 'containers/map/lib/fluro-measurement';
import 'containers/map/lib/svg-icon';
import {MapBar} from 'containers/map/map-bar/map-bar';
import {MapFilterStatus} from 'containers/map/map-filter-status';
import {TiledMap} from 'containers/map/tiled-map';
import {Portal} from 'components/fluro-dialog/portal';
import {selectIsAdmin, selectIsHasAccessToSI} from 'containers/login/login-selectors';
import {setFeature} from 'containers/map/actions';
import {setFilter} from 'modules/sustainability-insights/actions';
import Helmet from 'react-helmet';
import {
  useSIMetrics,
  useSIPolicy,
} from 'containers/map/features/sustainability-insights/data-hooks';
import {SIFilterStatus} from 'containers/map/features/sustainability-insights/filter-status/filter-status';
import {FiltersPanel} from 'containers/map/features/sustainability-insights/filters-panel/filters-panel';
import {CI_HOME} from 'containers/map/routes';

// ************************************************************************************************
// Temporary container for the v1 map until we build the new one
// Copied from flurosense-map.tsx and si-container.tsx
// ************************************************************************************************

const SIContainerInner = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setFeature('sustainability-insights', false));
    // Needed on mount only
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useSIPolicy();
  useSIMetrics();

  return (
    <>
      <Helmet>
        <title>Sustainability insights - Regrow</title>
      </Helmet>

      <Portal id="map-filter-status">
        <SIFilterStatus />
      </Portal>

      <FiltersPanel />
    </>
  );
};

const SIv1Container = () => {
  const hasSIAccess = useAppSelector(selectIsHasAccessToSI);
  const isAdmin = useAppSelector(selectIsAdmin);
  const isAllowed = hasSIAccess || isAdmin;

  if (!isAllowed) return <Redirect to={CI_HOME} />;

  return <SIContainerInner />;
};

// FIXME:
// https://github.com/PaulLeCam/react-leaflet/issues/255
// @ts-expect-error error leftover from convertion to strict mode, please fix
delete L.Icon.Default.prototype._getIconUrl;
Object.keys(markerIcon.options).forEach(key => {
  // @ts-expect-error error leftover from convertion to strict mode, please fix
  L.Icon.Default.prototype.options[key] = markerIcon.options[key];
});
// for some reason Marker doesn't responsible for i a pin marker anymore and was replaced by lines above
// L.Marker.prototype.options.icon = markerIcon;

//@ts-expect-error error leftover from convertion to strict mode, please fix
L.drawLocal.draw.handlers.marker.tooltip.start = '';

// TODO: Remove Abatement Potential from SIv1Map
export const SIv1Map = () => {
  const dispatch = useDispatch();
  const isSI2 = useAppSelector(selectIsSI); // Is in SIv2 selector
  const isMap = useRouteMatch(SI_MAP);
  const isAP = useRouteMatch(SI_ABATEMENT_POTENTIAL);

  const isReadOnly = useAppSelector(s => s.map.group.readOnly);
  const isMapBarOpen = useAppSelector(s => s.map.isMapBarOpen);
  const layersSource = useAppSelector(s => s.map.layersSource);
  const isSmallScreen = useMediaQuery({query: '(max-width: 560px)'});

  const [leafletElement, setLeafletElement] = useState<L.Map | null>(null);

  useEffect(() => {
    // For SIv2 show / hide Abatement Potential based on the route
    if (!isSI2) return;

    if (isAP) {
      dispatch(setFilter({activeGeometryType: SIGeometryFilterType.AbatementPotential}));
    } else {
      dispatch(setFilter({activeGeometryType: SIGeometryFilterType.CoverCrops}));
    }
  }, [dispatch, isSI2, isMap, isAP]);

  useEffect(() => {
    dispatch(toggleMapView(true));
    return () => {
      dispatch(toggleMapView(false));
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onRefMap = (node: Map) => {
    if (!node) return;

    setLeafletElement(node);
    // @ts-expect-error error leftover from convertion to strict mode, please fix
    window.leafletElement = node;
    node.createPane('field-geometry');
    const pane = node.getPane('field-geometry');
    if (pane) {
      pane.style.zIndex = '500';
    }
  };

  const patchedFitBounds = useCallback(
    (bounds?: L.LatLngBounds, forceRightMargin?: boolean) => {
      try {
        if (!bounds || !leafletElement) {
          return;
        }
        const validBounds = validateBounds(bounds);
        if (!validBounds) return;
        const marginRight = getPanelRightPadding(forceRightMargin || isMapBarOpen);
        leafletElement.fitBounds(validBounds, {
          // we do not need padding bounds in the small screens
          paddingBottomRight: isSmallScreen ? [0, 70] : [marginRight, 70],
        });
      } catch (err) {
        reportError(`fitBounds Err = ${err}`);
      }
    },
    [leafletElement, isMapBarOpen, isSmallScreen]
  );

  const mapEventHandlers = {
    loading: () => {
      dispatch(mapLoading(true));
    },
    load: () => {
      dispatch(mapLoading(false));
    },
  };

  return (
    <div className={'map-v2__container transparent'}>
      <Provider value={{patchedFitBounds}}>
        <TiledMap
          trackResize={!isReadOnly}
          layersSource={layersSource}
          onRefMap={onRefMap}
          eventHandlers={mapEventHandlers}
        >
          <MapBar>
            <SIv1Container />
          </MapBar>
          <SustainabilityInsightsOverlay />
        </TiledMap>
        {_config.env === 'development' && <div className="git-branch">{_config.branch}</div>}
        <MapFilterStatus />
      </Provider>
    </div>
  );
};

export default SIv1Map;
