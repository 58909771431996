/*

Below selectors were created only for the project select page,
some of the selectors were copy-pasted from monitoring/enrollment.
The main point is to make the selectors independent from URL project id.

*/

import {selectIsImpersonatorNotSuperAdmin} from 'containers/login/login-selectors';
import {
  getById,
  selectPhases,
  selectPrograms,
  selectProjects,
  mapPhasesByType,
  monitoringReadOnlyCheck,
  selectHasEnrolledFields,
} from 'containers/mrv/monitoring/module/selectors';
import type {
  MRVProgramNormalized,
  MRVProjectNormalized,
  PhaseReadOnlyReason,
} from 'containers/mrv/types';
import {MRVPhaseType} from 'containers/mrv/types';
import type {AppStore} from 'reducers';
import {createSelector} from 'reselect';
import {getToday} from '_utils/pure-utils';
import {enrollmentReadOnlyCheck, selectCarbon} from '../enrollment/carbon-store/selectors';

export const selectProject = createSelector(
  [selectProjects, (s: AppStore, projectId: number) => projectId],
  (data, id): MRVProjectNormalized | undefined => getById<MRVProjectNormalized>(data, id)
);

const selectProjectProgram = createSelector(
  [selectProject, selectPrograms],
  (project, programs): MRVProgramNormalized | undefined => {
    return project?.program_id ? programs[project.program_id] : undefined;
  }
);

export const selectPhasesByType = createSelector(
  [selectPhases, selectProjectProgram],
  mapPhasesByType
);

export const selectContractIsSigned = createSelector(
  // TODO - eventually we must move to the commented out code below.
  // When producerAgreement is treated as an actual stage.
  //
  // [
  //   selectProject,
  //   selectProjectProgram,
  //   selectPhases,
  //   selectMRVStages,
  //   (s: AppStore, projectId: number, phaseType: MRVPhaseType) => phaseType,
  // ],
  // (project, program, phases, stages, phaseType) => {
  //   const phaseId = program?.phases?.find(id => phases[id]?.type_ === phaseType);
  //   if (!phaseId) {
  //     return false;
  //   }
  //   const phase = phases[phaseId];

  //   const stageId = phase?.stages?.find(id => stages[id]?.type_ === MRVStageType.Contract);
  //   if (!stageId) {
  //     return false;
  //   }
  //   const stage = stages[stageId];

  //   return Boolean(
  //     stage?.enabled &&
  //       project?.contracts?.length &&
  //       project.contracts[0].docusign_status === 'completed' &&
  //       project.contract_link
  //   );
  // }
  [
    selectProject,
    selectProjectProgram,
    selectPhases,
    (s: AppStore, projectId: number, phaseType: MRVPhaseType) => phaseType,
  ],
  (project, program, phases, phaseType) => {
    const phaseId = program?.phases?.find(id => phases[id]?.type_ === phaseType);
    if (!phaseId) {
      return false;
    }
    const phase = phases[phaseId];

    return Boolean(
      phase?.show_contract &&
        project?.contracts?.length &&
        project.contracts[0].docusign_status === 'completed' &&
        project.contract_link
    );
  }
);

export const selectContractIsGenerated = createSelector([selectProject], project => {
  return Boolean(project?.contracts?.length);
});

export const selectDNDC = createSelector([selectProject, selectCarbon], (project, carbon) => {
  return {
    percentCompleted: carbon?.dndcPercentCompleted,
    status: project?.dndc_status,
    payment: carbon?.dndcResults.payment,
    tonsSequestered: carbon?.dndcResults.tonsSequestered,
  };
});

export const selectEnrollmentReadOnly = createSelector(
  [
    selectIsImpersonatorNotSuperAdmin,
    (s: AppStore, projectId: number) =>
      selectContractIsSigned(s, projectId, MRVPhaseType.Enrollment),
    selectProject,
    selectPhasesByType,
    getToday,
    selectContractIsGenerated,
    selectDNDC,
  ],
  // Scenario Planning Tool contract is signed OR MRV contract is signed.
  (
    isImpersonatorNotSuperAdmin,
    signed,
    project,
    phases,
    date,
    contractGenerated,
    dndc
  ): {isReadOnly: boolean; reason: PhaseReadOnlyReason; detailedReason: string} => {
    const enrollment = phases[MRVPhaseType.Enrollment];

    return enrollmentReadOnlyCheck(
      isImpersonatorNotSuperAdmin,
      signed,
      enrollment,
      date,
      contractGenerated,
      dndc,
      project
    );
  }
);

export const selectMonitoringReadOnly = createSelector(
  [
    selectIsImpersonatorNotSuperAdmin,
    (s: AppStore, projectId: number) =>
      selectContractIsSigned(s, projectId, MRVPhaseType.Monitoring),
    selectProject,
    selectPhasesByType,
    getToday,
    selectHasEnrolledFields,
  ],
  (
    isImpersonatorNotSuperAdmin,
    contractIsSigned,
    project,
    phases,
    todayDate,
    hasEnrolledFields
  ): {isReadOnly: boolean; reason: PhaseReadOnlyReason; detailedReason: string} => {
    const monitoring = phases[MRVPhaseType.Monitoring];

    return monitoringReadOnlyCheck(
      isImpersonatorNotSuperAdmin,
      contractIsSigned,
      monitoring,
      todayDate,
      hasEnrolledFields,
      project
    );
  }
);
