// @ts-nocheck
import {IndeterminateCheckboxIcon, UncheckedCheckboxIcon} from 'components/fluro-icons';
import type {ComponentType} from 'react';
import React, {useState} from 'react';
import {SelectionControl, TextField} from 'react-md';
import type {Option} from 'types';
import type {Props as OptionsGroupProps} from './options-group';
import {OptionsGroup} from './options-group';

export type Props = {
  allLabel?: string;
  allSelected: boolean;
  id: string;
  name: string;
  options: Option[];
  selected: Array<string>;
  allowSearch?: boolean;
  allowSelectAll?: boolean;
  selectedMap?: Record<string, boolean>;
  onChange: OptionsGroupProps['onChange'];
};

export const GroupedSelectionControl: ComponentType<Props> = ({
  id,
  name,
  selectedMap: selectedMapProp,
  selected,
  options,
  onChange,
  allLabel = 'Select all',
  allSelected,
  allowSearch = false,
  allowSelectAll = false,
}) => {
  const [search, setSearch] = useState<string>('');
  const selectedMap =
    selectedMapProp || selected.reduce((acc, value) => ({...acc, [value]: true}), {});

  return (
    <div className="grouped-selection-control">
      {allowSearch && (
        <TextField
          id="county-select-search"
          className="color-dark"
          placeholder="Search"
          value={search}
          onChange={v => setSearch(String(v))}
        />
      )}

      {allowSelectAll && (
        <SelectionControl
          uncheckedCheckboxIcon={
            selected.length ? <IndeterminateCheckboxIcon /> : <UncheckedCheckboxIcon />
          }
          checked={allSelected}
          type="checkbox"
          name="all"
          id={`all-${id}`}
          label={allLabel}
          value="all"
          onChange={(selected: boolean, event: Event) => onChange(selected, event)}
        />
      )}
      {!!options?.length &&
        options?.map(({value, label, options: subOptions}: Option) => {
          const searchMatchesPrimaryOption = label.toLowerCase().includes(search.toLowerCase());

          if (allowSearch && !subOptions && !searchMatchesPrimaryOption) return null;

          return (
            <OptionsGroup
              key={`${id}-${value}`}
              id={`${id}-${value}`}
              search={search}
              name={`${name}-${value}`}
              selectedMap={selectedMap}
              value={value}
              label={label}
              options={subOptions}
              onChange={onChange}
            />
          );
        })}
    </div>
  );
};
