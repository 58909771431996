import moment from 'moment';
import type {Field, Season} from 'containers/map/types';
import {useMemo} from 'react';
import {GLOBAL_APP_DATE_FORMAT} from '_constants';
import {useAppSelector} from './index';
import {
  selectCurrentDate,
  selectCurrentDates,
  selectCurrentTab,
} from '../containers/map/reducer/selectors';
import {selectIsReportView} from 'modules/global/selectors';

export const useFieldCurrentSeason = (): {
  getFieldCurrentSeasons: (f: Field, filterByPlantingAreas?: boolean) => Season[];
} => {
  const feature = useAppSelector(selectCurrentTab);
  const currentDates = useAppSelector(selectCurrentDates);
  const currentDate = useAppSelector(selectCurrentDate);
  const isReportView = useAppSelector(selectIsReportView);

  const date = useMemo(
    () =>
      feature === 'crop-performance' || isReportView
        ? moment.utc(currentDate, GLOBAL_APP_DATE_FORMAT) // in crop perf the date could be outside currentDates
        : moment.utc(currentDates[currentDate]?.Date),
    [currentDates, currentDate, feature]
  );

  const getFieldCurrentSeasons = (f: Field, filterByPlantingAreas = false) => {
    if (!currentDate) {
      return [];
    }
    return filterByPlantingAreas
      ? getFieldSeasons(f, date.format()).filter(s => s.geometry)
      : getFieldSeasons(f, date.format());
  };
  return {getFieldCurrentSeasons};
};

export const getFieldSeasons = (f: Field, date: string) => {
  const d = moment.utc(date);
  return (
    f?.Seasons?.filter(
      s => d.isSameOrAfter(moment.utc(s.startDate)) && d.isSameOrBefore(moment.utc(s.endDate))
    ) || []
  );
};

export const getFieldLastFinishedSeason = (f: Field) => {
  const d = moment.utc();
  let prevSeasonIndex = -1;
  // Start the iteration from the latest season.
  for (let i = Number(f?.Seasons?.length || 0); i--; ) {
    const s = f?.Seasons?.[i];
    // And take the first season that both started and finished before the current date.
    if (d.isSameOrAfter(moment.utc(s?.startDate)) && d.isSameOrAfter(moment.utc(s?.endDate))) {
      prevSeasonIndex = i;
      break;
    }
  }
  return prevSeasonIndex > -1 ? f?.Seasons?.[prevSeasonIndex] : undefined;
};
