import React from 'react';
import cn from 'classnames';

import {
  AddFieldsStep,
  CompleteStep,
  DrawFieldsStep,
  SelectBoundariesOnMap,
  UploadStep,
} from './steps';
import {PreviewFieldsStep} from './steps/preview-fields';

import {setAddFieldCurrentStep} from 'modules/add-fields';
import {selectAddingFieldsStep, selectIsPreviewingAddingFields} from 'modules/add-fields/selectors';
import type {AddFieldStep} from 'modules/add-fields/types';
import './index.scss';
import FieldPropertiesParser from 'containers/map/features/farm/new-fields/field-properties-parser/field-properties-parser';
import {useAppDispatch, useAppSelector} from '_hooks';
import {ZoomIsTooLowStep} from 'containers/map/features/farm/new-fields/steps/zoom-is-too-low';
import {selectEnrollmentReadOnly} from 'containers/mrv/enrollment/carbon-store/selectors';
import {ReadonlyMessage} from 'containers/mrv/enrollment/form/readonly-message';

type Props = {
  onHide?: () => void;
};

const NewFields = ({onHide}: Props) => {
  const dispatch = useAppDispatch();

  const currentStep: AddFieldStep = useAppSelector(selectAddingFieldsStep);
  const changeCurrentStep = (value: AddFieldStep) => dispatch(setAddFieldCurrentStep(value));

  const {isReadOnly} = useAppSelector(selectEnrollmentReadOnly);

  const previewFields = useAppSelector(selectIsPreviewingAddingFields);

  /**
   * Prevent NewFields component from appearing after enrolment ends,
   * which would allow a user to upload fields when the program is no longer accepting enrolments.
   */
  if (isReadOnly) {
    return <ReadonlyMessage />;
  }

  return (
    <div
      className={cn('field-items-container', {
        'name-uploaded-fields': previewFields,
      })}
    >
      {currentStep === 'zoom-is-too-low' && <ZoomIsTooLowStep onHide={onHide} />}

      {currentStep === 'add-fields' && <AddFieldsStep changeCurrentStep={changeCurrentStep} />}

      {currentStep === 'select-boundaries' && <SelectBoundariesOnMap onHide={onHide} />}

      {currentStep === 'draw-fields' && <DrawFieldsStep onHide={onHide} />}

      {currentStep === 'select-files-to-upload' && (
        <UploadStep changeCurrentStep={changeCurrentStep} />
      )}

      {currentStep === 'parse-uploading-files' && <FieldPropertiesParser />}

      {previewFields && <PreviewFieldsStep />}

      {currentStep === 'complete' && <CompleteStep onHide={onHide} />}
    </div>
  );
};

export default NewFields;
