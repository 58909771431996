import styled from 'styled-components';
import {Portal} from 'react-md';

export const LoaderWrapper = styled(Portal)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.5);
  text-align: center;
  z-index: 1000;

  .loader {
    margin: 140px auto;
    display: flex;
    justify-content: center;
  }

  .loader-content {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 0;
    .md-progress {
      margin: 0;
    }

    b {
      margin-left: 10px;
    }
  }
`;
