import React from 'react';
import {Text, Flex} from 'components';

export const StackedHeaderText = ({headerText, subText}: {headerText: string; subText: string}) => (
  <Flex direction="column">
    <Text bold noMargin>
      {headerText}
    </Text>
    <Text noMargin variant="small-thin">
      {subText}
    </Text>
  </Flex>
);
