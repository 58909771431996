import {InlineStepper} from 'containers/si/components/InlineStepper';
import type {StepsItem} from 'containers/si/types';
import React from 'react';
import {Icon} from '@regrow-internal/design-system';
import styled, {useTheme} from 'styled-components';
import {TillageIcon, SummerCropIcon} from 'containers/mrv/icons';

interface ProgramPlanStepperProps {
  currentStep: number;
  setCurrentStep: (step: number) => void;
}

export const ProgramPlanStepper = ({currentStep, setCurrentStep}: ProgramPlanStepperProps) => {
  const theme = useTheme();
  const stepItems = React.useMemo(
    (): Array<StepsItem> => [
      {
        label: 'Program details',
        icon: <Icon type="settings" color={theme.colorPalette.fs_main.green_600} />,
        disabled: false,
      },
      {
        label: 'Scenario selection',
        icon: <Icon type="checkbox-checked" color={theme.colorPalette.fs_main.green_600} />,
        disabled: false,
      },
      {
        label: 'Practice adoption',
        icon: <TillageIcon fill={theme.colorPalette.fs_main.green_600} />,
        disabled: false,
      },
      {
        label: 'Launch MRV program (Coming soon)',
        icon: <SummerCropIcon fill={theme.colorPalette.fs_main.green_600} />,
        disabled: true,
      },
    ],
    [theme.colorPalette.fs_main.green_600]
  );

  return (
    <StepperWrapper>
      <InlineStepper
        currentStep={currentStep}
        stepItems={stepItems}
        onUpdateStep={setCurrentStep}
      />
    </StepperWrapper>
  );
};

const StepperWrapper = styled.div`
  margin-bottom: 16px;
`;
