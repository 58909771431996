import {ExternalService} from '../../map/types';
import {reportError} from '../../error-boundary';
import type {IntegrationPlatform} from './types';

const ExternalServicesArray: ExternalService[] = [
  ExternalService.Agrian,
  ExternalService.Agworld,
  ExternalService.Agx,
  ExternalService.Climate,
  ExternalService.EFC,
  ExternalService.JohnDeere,
  ExternalService.JohnDeereLong,
  ExternalService.Proagrica,
  ExternalService.ProductionWise,
  ExternalService.FarmLogs,
];
/**
 Check if a string is an external service
*/

export const stringIsExternalService = (string: string) =>
  ExternalServicesArray.includes(string as ExternalService);

export const IntegrationCardPlatformLogo: Record<IntegrationPlatform, string> = {
  [ExternalService.Agrian]: '/assets/integration/agrian-logo-round.svg',
  [ExternalService.Agworld]: '/assets/integration/agworld-logo-round.svg',
  [ExternalService.Agx]: '/assets/integration/proagrica-logo-round.svg',
  [ExternalService.Climate]: '/assets/integration/climate-logo-round.svg',
  [ExternalService.EFC]: '/assets/integration/efc-logo-round.svg',
  [ExternalService.JohnDeere]: '/assets/integration/john_deere-logo-round.svg',
  [ExternalService.ProductionWise]: '/assets/integration/productionwise-logo-round.svg',
  [ExternalService.FarmLogs]: '/assets/integration/farmlogs-logo-round.svg',
};

export const getIntegrationCardLogo = (platform: IntegrationPlatform) => {
  const logo = IntegrationCardPlatformLogo[platform] || '';
  if (!logo) reportError(`Missing logo link for ${platform}`);
  return logo;
};

export const ExternalServiceLabels: Record<ExternalService, string> = {
  [ExternalService.Agworld]: 'Agworld',
  [ExternalService.JohnDeereLong]: 'John Deere',
  [ExternalService.JohnDeere]: 'John Deere',
  [ExternalService.Agrian]: 'Agrian',
  [ExternalService.Climate]: 'Climate FieldView',
  [ExternalService.Agx]: 'agX',
  [ExternalService.EFC]: 'FieldView',
  [ExternalService.Terravion]: 'Terravion',
  [ExternalService.Google]: 'Google',
  [ExternalService.Proagrica]: 'Proagrica',
  [ExternalService.ProductionWise]: 'Production Wise',
  [ExternalService.FarmLogs]: 'FarmLogs',
};

export const IntegrationPlatformLabels: Record<IntegrationPlatform, string> = {
  [ExternalService.Agworld]: 'Agworld',
  [ExternalService.JohnDeere]: 'MyJohnDeere',
  [ExternalService.Agrian]: 'Agrian',
  [ExternalService.Climate]: 'Climate FieldView',
  [ExternalService.Agx]: 'agX',
  [ExternalService.EFC]: 'FieldAlytics',
  [ExternalService.ProductionWise]: 'Production Wise',
  [ExternalService.FarmLogs]: 'FarmLogs',
};

export const getIntegrationLabel = (platform: IntegrationPlatform) => {
  if ((platform as ExternalService) === ExternalService.JohnDeereLong)
    platform = ExternalService.JohnDeere;
  const label = IntegrationPlatformLabels[platform] || '';
  if (!label) reportError(`Missing label for ${platform}`);
  return label;
};

export const IntegrationPlatformCompanyName: Record<IntegrationPlatform, string> = {
  [ExternalService.Agworld]: 'Agworld',
  [ExternalService.JohnDeere]: 'John Deere',
  [ExternalService.Agrian]: 'Agrian',
  [ExternalService.Climate]: 'Climate',
  [ExternalService.Agx]: 'Proagrica',
  [ExternalService.EFC]: 'EFC',
  [ExternalService.ProductionWise]: 'ProductionWise',
  [ExternalService.FarmLogs]: 'FarmLogs',
};

export const getIntegrationCompanyLabel = (platform: IntegrationPlatform) => {
  if ((platform as ExternalService) === ExternalService.JohnDeereLong)
    platform = ExternalService.JohnDeere;
  const label = IntegrationPlatformCompanyName[platform] || '';
  if (!label) reportError(`Missing company label for ${platform}`);
  return label;
};
