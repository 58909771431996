import {Flex} from 'components';
import {
  selectReportAreAllCropsSelected,
  selectReportAvailableCropIDs,
  selectReportCrops,
  selectReportCropFilter,
} from 'containers/si/module/selectors';
import type {ComponentType} from 'react';
import React, {useMemo} from 'react';
import {useAppDispatch, useAppSelector} from '_hooks';
import {useParsedMatchParams} from '_hooks/use-parsed-match-params';
import {naturalSortAlphaNum} from '_utils/sorters';
import {
  setDashboardCropFilter,
  setDashboardPageIndex,
  toggleDashboardCropFilter,
} from 'containers/si/module/reducer';
import {selectIsAdmin} from 'containers/login/login-selectors';
import {AreaSelectMenu} from 'containers/map/features/sustainability-insights/area-select/area-select-menu';
import {batch} from 'react-redux';
import type {Props as OptionsGroupsProps} from 'components/grouped-selection-control/options-group';

export const CropTypeFilter: ComponentType = () => {
  const dispatch = useAppDispatch();
  const {reportId} = useParsedMatchParams();
  const cropTypes = useAppSelector(s => selectReportCrops(s, reportId));

  const selected = useAppSelector(selectReportCropFilter);
  const availableCropIds = useAppSelector(s => selectReportAvailableCropIDs(s, reportId));
  const allSelected = useAppSelector(s => selectReportAreAllCropsSelected(s, reportId));
  const isAdmin = useAppSelector(selectIsAdmin);

  const controls = useMemo(() => {
    const unsortedCrops = cropTypes.map(cropType => {
      return {
        label: isAdmin ? `${cropType.label} (#${cropType.value})` : cropType.label,
        value: cropType.value,
      };
    });

    return naturalSortAlphaNum(unsortedCrops, 'label');
  }, [cropTypes, isAdmin]);

  const handleClear = () => {
    dispatch(setDashboardCropFilter([]));
  };

  const handleSelect: OptionsGroupsProps['onChange'] = selectedChange => {
    if (typeof selectedChange === 'boolean') {
      //Means we clicked select all button
      batch(() => {
        dispatch(setDashboardCropFilter(selectedChange ? availableCropIds : []));
        dispatch(setDashboardPageIndex(0));
      });
      return;
    } else {
      //Means we clicked any other value
      batch(() => {
        dispatch(toggleDashboardCropFilter(Number(selectedChange.value)));
        dispatch(setDashboardPageIndex(0));
      });
    }
  };

  return (
    <Flex alignItems="center" nowrap className="margin-right-12">
      <AreaSelectMenu
        className="area-select-menu"
        allowSelectAll
        allowSearch
        allLabel={`Select all crops`}
        selectedLabel={'crops'}
        options={controls}
        selected={selected.map(String)}
        disabled={false}
        allSelected={allSelected}
        onSelect={handleSelect}
        onClear={handleClear}
      />
    </Flex>
  );
};
