import {useCallback, useMemo} from 'react';
import {useHistory, useLocation} from 'react-router-dom';

export type Params = {[index: string]: any};

export function parseParamsObj<T extends Params>(obj: T): T {
  const params = JSON.parse(JSON.stringify(obj), (key, value) => {
    try {
      return JSON.parse(value);
    } catch (err) {
      return value;
    }
  });
  return params;
}

export function parseUrlParams<T extends Params>(search: string): T {
  const searchParams = new URLSearchParams(search);
  const paramsObj = [...searchParams.entries()].reduce<T>(
    (acc, [key, value]) => ({...acc, [key]: value}),
    {} as T
  );
  const params = parseParamsObj(paramsObj);
  return params;
}

/**
 * Example:
 * input: { tableView: true, stageId: 1, farmId: 12345 } -> output: "tableView=true&stageId=1&farmId=12345"
 */
export function stringifyUrlParams<T extends Params>(params: T): string {
  const searchParams = new URLSearchParams();
  Object.entries(params).forEach(([key, value]) => {
    if (value == null) {
      searchParams.delete(key);
    } else {
      searchParams.set(key, value);
    }
  });
  const result = searchParams.toString();
  return result;
}

export const applyUrlParams = <T extends Params>(search: string, params: T): string => {
  const result = stringifyUrlParams({...parseUrlParams(search), ...params});
  return result;
};

/**
 * Hook to read/set URL params.
 * Usage example:
 *
 * URL: http://localhost:3000/mrv?tab=carbon&tableView=true&carbonStep=Confirm+practices
 *
 * ```js
 * const [params, setParams] = useUrlParams<{tab: string; tableView: string; carbonStep: 'Confirm practices' | 'Monitor' }>();
 *
 * 1. Read params:
 *  console.log(params); // -> { tab: 'carbon', tableView: 'true', carbonStep: 'Confirm practices' }
 *
 * 2. Update params in path:
 *  setParams({ tab: 'zoning' }) // -> { tab: 'zoning', tableView: 'true', carbonStep: 'Confirm practices' }
 *  URL: http://localhost:3000/mrv?tab=zoning&tableView=true&carbonStep=Confirm+practices
 *
 * 3. Clear params:
 *  setParams(null) -> {}
 *  URL: http://localhost:3000/mrv
 * ```
 */
export const useUrlParams = <T extends Params>(): [
  Partial<T>,
  (params: Partial<T> | null) => Partial<T>
] => {
  const history = useHistory();
  const location = useLocation();
  const params = useMemo(() => parseUrlParams<T>(location.search), [location.search]);

  const setParams = useCallback(
    (newParams: Partial<T> | null, merge = true): Partial<T> => {
      const paramsObj = newParams && merge ? {...params, ...newParams} : newParams;
      if (!paramsObj) return {};
      const search = stringifyUrlParams(paramsObj);

      // TL;DR:
      // Make changes on the next tick to prevent thunks
      // from failing with the error:
      // `Uncaught Error: You may not call store.getState() while the reducer is executing.`
      //
      // Explanation:
      // Because the `history` instance is used by the connected-react-router middleware,
      // any change to the history state triggers action dispatch to the store.
      // When thunks are setting URL params and then trying to call store.getState()
      // within the same tick it triggers redux to throw the error because it won't allow
      // to read the state when there is action dispatch in progress.
      setTimeout(() => history.push({search}));

      return paramsObj || ({} as Partial<T>);
    },
    [history, params]
  );

  return [params, setParams];
};
