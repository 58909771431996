// @ts-nocheck
import {combineReducers} from '@reduxjs/toolkit';
import {accessSlice} from './slices/access';
import {completionSlice} from './slices/completion';
import {entitiesSlice} from './slices/entities';
import {filtersSlice} from './slices/filters';
import {programsSlice} from './slices/programs';
import {stageIconsSlice} from './slices/stage-icons';
import {programBoundariesSlice} from './slices/program-boundaries';

import type {MonitoringState} from './types';

export const {addAttributeLabels, addInfoAssets, addInfoPracticeChanges, setLoaded} =
  programsSlice.actions;
export const {setStageIcons, addStageIcon} = stageIconsSlice.actions;
export const {
  addMonitoringPrograms,
  removeProgramAdmin,
  bulkAddProgramAdmin,
  updateFieldOFSStatus,
  updateProjectOFSStatus,
  updateFieldDNDCStatus,
  updateProjectDNDCStatus,
  setProgramInvites,
  addProgramInvites,
  removeProgramInvite,
  removeStage,
} = entitiesSlice.actions;
export const {toggleFilter} = filtersSlice.actions;
export const {addAccess} = accessSlice.actions;
export const {setStageCompletion} = completionSlice.actions;
export const {setBoundaries} = programBoundariesSlice.actions;

const monitoringReducer = combineReducers<MonitoringState>({
  access: accessSlice.reducer,
  programs: programsSlice.reducer,
  stageIcons: stageIconsSlice.reducer,
  entities: entitiesSlice.reducer,
  filters: filtersSlice.reducer,
  completion: completionSlice.reducer,
  programBoundaries: programBoundariesSlice.reducer,
});

export default monitoringReducer;
