// @ts-nocheck
import {useCallback, useEffect, useMemo, useState} from 'react';
import {useSelector} from 'react-redux';
import type {AppStore} from './reducers';
import axios from 'axios';
import {reportError} from './containers/error-boundary';
import type {IntlShape, MessageDescriptor} from 'react-intl';
import {createIntlCache, createIntl, useIntl} from 'react-intl';
import type {
  FormatXMLElementFn,
  PrimitiveType,
  Options as IntlMessageFormatOptions,
} from 'intl-messageformat';
import moment from 'moment';
import config from '_environment';

// will add more locales if need
import 'moment/locale/ru';
import 'moment/locale/fr-ch';
import 'moment/locale/pt-br';
import type {CropType} from 'modules/global/types';
import {getUserToken} from '_utils/safe-local-storage';

type UseLocale = {
  intl: IntlShape;
};

export enum Locales {
  EnUS = 'en-US',
  FrCH = 'fr',
  RuRU = 'ru-RU',
  PtBr = 'pt-BR',
}

export type LangInfo = {label: string; value: Locales; flag?: string};

const enItem: LangInfo = {
  label: 'English',
  value: Locales.EnUS,
  flag: 'us',
};

const ptItem = {
  label: 'Portuguese (BR)',
  value: Locales.PtBr,
  flag: 'pt',
};

const ruItem = {
  label: 'Russian',
  value: Locales.RuRU,
  flag: 'ru',
};

const frItem = {
  label: 'French',
  value: Locales.FrCH,
  flag: 'fr',
};

export const langInfo: LangInfo[] = [enItem, frItem, ruItem, ptItem];

export const langInfoForRegenConnect: LangInfo[] = [
  {...enItem, label: 'ENG', flag: 'gb'},
  {...frItem, label: 'FR'},
];

export const ALLOWED_LOCALES: Locales[] = [Locales.EnUS, Locales.PtBr, Locales.RuRU, Locales.FrCH];

export const DEFAULT_LOCALE = Locales.EnUS;

const SERVER_LOCALE_PROPS = ['label_pt-br', 'label_ru-ru'] as (keyof CropType)[];

export const isAllowedLocale = (value: unknown) => {
  return ALLOWED_LOCALES.includes(value as Locales);
};

const cache = createIntlCache();

// Create default intl object to start init the application
// locale is DEFAULT_LOCALE cuz while we loading user selected language we show defaultMessages on EN
let intlObj: IntlShape = createIntl(
  {
    locale: DEFAULT_LOCALE,
    defaultLocale: DEFAULT_LOCALE,
  },
  cache
);

export const useLocale = (): UseLocale => {
  const [intl, setIntl] = useState<IntlShape>(intlObj);

  const locale = useSelector((state: AppStore) => state.login.user.settings.langLocale);

  // FIXME: for some reason webpack fails in runtime when I use here selectIsAdmin selector
  const isAdmin = useSelector((state: AppStore) => state.login.user.perm === 3);

  // selectIsAuth selector is crashed webpack - TODO - investigate the reason
  const isAuth = useSelector((state: AppStore) => state.login.isAuth);

  const crops = useSelector((state: AppStore) => state.global.cropTypes);
  const cropsArray: CropType[] = useMemo(() => Object.values(crops), [crops]);

  const getMessages = useCallback(async () => {
    let allowedLocale = locale;

    try {
      if (!ALLOWED_LOCALES.includes(allowedLocale)) {
        reportError(`NOT SUPPORTED LOCALE ${allowedLocale}`);
        allowedLocale = DEFAULT_LOCALE;
      }

      const {data} = await axios.get(
        `/assets/translations/${allowedLocale}.json?${config.version}`,
        {params: {__skipPreloader: true}}
      );

      const key = `label_${locale.toLowerCase()}` as keyof CropType;

      let crops: {[id: string]: any} = {};

      cropsArray.forEach(crop => {
        crops[crop.value] = crop?.[key] || crop.label;
      });

      if (!isAdmin) {
        let shouldLoadCrops = false;

        if (!cropsArray.some(crop => crop[key]) && locale !== Locales.EnUS) {
          //check if non en-US current locale not loaded
          shouldLoadCrops = true;
        } else {
          // check if current locale is en-US and it not loaded
          if (
            locale === Locales.EnUS &&
            SERVER_LOCALE_PROPS.some(key => cropsArray.some(crop => crop[key]))
          ) {
            shouldLoadCrops = true;
          }
        }

        if (shouldLoadCrops && isAuth) {
          const r = await axios.get(config.baseUrl + 'api/v1/crops', {
            params: {lang: locale.toLowerCase()},
            headers: {[config.authHeader]: getUserToken()},
          });
          // const r = await ActivityApi.getCropTypesList(locale.toLowerCase());
          const cropTypes = r.data?.result || [];

          cropTypes.forEach((crop: CropType) => {
            crops[crop.value] = crop.label;
          });
        }
      }

      intlObj = createIntl(
        {
          locale,
          messages: {...crops, ...data},
          defaultLocale: DEFAULT_LOCALE,
        },
        cache
      );

      moment.locale(locale === 'fr' ? 'fr-ch' : locale || DEFAULT_LOCALE); // fix fr locale

      setIntl(intlObj);
    } catch (e) {
      reportError(`Lang key [${locale}], Error: ${e.message}`);
    }
  }, [locale, cropsArray]);

  useEffect(() => {
    getMessages();
  }, [locale]);

  return {
    intl,
  };
};

export const t = (
  descriptor: MessageDescriptor,
  values?: Record<string, PrimitiveType | FormatXMLElementFn<string, string>>
): string => {
  if (!descriptor.id) return (descriptor.defaultMessage as string) || '';

  return intlObj.formatMessage(
    descriptor.defaultMessage
      ? descriptor
      : {...descriptor, defaultMessage: descriptor.id as string},
    values
  );
};

export const useTranslation = () => {
  const intl = useIntl();
  const translate = (
    descriptor: string | MessageDescriptor,
    values?: Record<string, PrimitiveType | FormatXMLElementFn<string, string>>,
    opts?: IntlMessageFormatOptions
  ): string => {
    const messageDescriptor: MessageDescriptor =
      typeof descriptor === 'string'
        ? {id: descriptor, defaultMessage: descriptor}
        : {
            ...descriptor,
            defaultMessage: descriptor.defaultMessage || String(descriptor.id) || '',
          };

    return intl.formatMessage(messageDescriptor, values, opts);
  };

  return translate;
};

export * from 'react-intl';
