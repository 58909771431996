// @ts-nocheck
import React, {useState, useEffect} from 'react';
import {Button, FontIcon, SelectField, Autocomplete, Chip} from 'react-md';
import {DownloadIcon} from 'containers/map/icons';
import throttle from 'lodash.throttle';
import {SEARCH_TYPES} from '../constants';
import {AdminControls} from '../../admin.styled';

type Data = {label: string; value: string};
type Props = {
  onSearch(val: string, type: string): void;
  onExport(): void;
  data: Data[];
  isEmpty: boolean;
  tags: Data[];
  onSetTags: (tags: Data[]) => void;
};

const UserActivityControls = ({onSearch, onExport, data, isEmpty, onSetTags, tags}: Props) => {
  const [searchType, setSearchType] = useState<string>(() => SEARCH_TYPES[0].value);
  const [searchValue, setSearchValue] = useState(() => '');

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      onSearch(searchValue, searchType);
    }
  };

  const handleClear = throttle(() => {
    setSearchValue('');
    onSearch('', searchType);
  }, 500);
  useEffect(() => {
    !searchValue && onSearch('', searchType);
  }, [searchValue]);

  const handleChange = (val: string) => setSearchValue(val);

  const handleAutocomplete = (val: string, index: number, matches: Data[]) => {
    const el = matches[index];
    onSetTags([...tags.filter(t => t.value !== matches[index].value), el]);
    setSearchValue('');
    onSearch(matches[index].label, searchType);
  };

  const removeTag = (value: string) => {
    onSetTags([...tags.filter(t => t.value !== value)]);
  };

  const onFilter = (data: any[], searchText: any) =>
    data.filter((el: any) => el.label.includes(searchText)).slice(0, 30);

  return (
    <AdminControls alignItems={'center'}>
      <div className="user-activity__controls-left">
        <div className={'d-flex align-center'}>
          <FontIcon className={'search-icon'}>search</FontIcon>
          <Autocomplete
            id="user-activity-search"
            className="user-activity__controls-search"
            data={data}
            placeholder="Search"
            customSize="search"
            onChange={handleChange}
            onAutocomplete={handleAutocomplete}
            onKeyDown={handleKeyDown}
            label="Search"
            dataLabel="label"
            dataValue="value"
            filter={onFilter}
            value={searchValue}
            listClassName="toolbar-search__list"
            inlineIndicator={
              <Button onClick={handleClear} icon>
                clear
              </Button>
            }
          />
        </div>
        <span>in</span>
        <SelectField
          id="user-activity-search-type"
          label="Column"
          value={searchType}
          className="user-activity__controls-type"
          menuItems={SEARCH_TYPES}
          onChange={(val: string) => setSearchType(val)}
          simplifiedMenu
        />
      </div>
      <div className="user-activity__controls-right">
        <Button primary raised onClick={onExport} disabled={isEmpty} iconEl={<DownloadIcon />}>
          EXPORT .CSV
        </Button>
      </div>

      {tags.length ? (
        <div className="user-activity__tags">
          {tags.map((t: any, i: number) => (
            <Chip
              key={`user-tag-activity-${i}`}
              onClick={() => removeTag(t.value)}
              removable
              label={t.label}
            />
          ))}
        </div>
      ) : null}
    </AdminControls>
  );
};

export default UserActivityControls;
