import {useMemo} from 'react';
import {useRouteMatch, useLocation} from 'react-router-dom';
import type {Params} from './use-url-params';
import {parseParamsObj} from './use-url-params';

// This hook returns params object from the `useRouteMatch()`
// that has any string params parsed as values
export const useParsedMatchParams = <T extends Params>(routeString = ''): T => {
  const match = useRouteMatch<T>(routeString);

  const parsedParams = parseParamsObj(match?.params || ({} as T));
  return parsedParams;
};

// A custom hook that builds on useLocation to parse
// the query string for you.
export const useQueryParams = (): URLSearchParams => {
  const {search} = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
};
