import {FluroDialog, Text, FluroInput, Flex, FluroButton, InfoBlock} from 'components';
import type {ComponentType} from 'react';
import React, {useEffect, useState} from 'react';
import {MRVApi} from '_api';
import {showNotification} from 'components/notification/notification';
import {useAppDispatch, useAppSelector} from '_hooks';
import {selectMRVProgramById, selectProjectById} from './monitoring/module/selectors';
import {useMonitoringUrlParams} from './monitoring/hooks';
import {
  createMrvProjectCustomInputsValue,
  updateMrvProjectCustomInputsValue,
  updateProjectConfig,
} from './monitoring/module/thunks';
import {reportError} from '../error-boundary';
import {FormattedMessage, t} from 'i18n-utils';
import {logout} from 'containers/login/actions';
import keyBy from 'lodash/keyBy';
import type {MRVCustomInput} from './types';

const ProgramNeedExtraSignIn = {
  ids: [21, 68, 1, 155],
  fieldKey: 'cargill_account_id',
  fieldValidationRule: 'Cargill Grain API',
};

export const MrvCargillAccountCheckDialog: ComponentType = () => {
  const dispatch = useAppDispatch();
  const {projectId, programId} = useMonitoringUrlParams();

  const project = useAppSelector(s => selectProjectById(s, projectId));
  const program = useAppSelector(s => selectMRVProgramById(s, project?.program_id));

  const [dialogVisible, setDialogVisibility] = useState(false);
  const [value, setValue] = useState<string>();
  const [error, setError] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [accountIdCustomRegValue, setAccountIdCustomRegValue] = useState<MRVCustomInput>();

  useEffect(() => {
    // handle account id in custom reg values
    const customRegValues = keyBy(project?.custom_reg_values || [], 'key');
    const accountIdCustomInput = program?.custom_reg_inputs.find(
      customInput => customInput.validation_rule === ProgramNeedExtraSignIn.fieldValidationRule
    );
    if (accountIdCustomInput) {
      const key = accountIdCustomInput.id.toString();
      setAccountIdCustomRegValue(customRegValues[key]);
      // fallback extra_signup_fields.value for any legacy users
      const accountId = customRegValues[key]?.value || project?.config?.extra_signup_fields?.value;
      if (!accountId) {
        setDialogVisibility(true);
      } else if (accountId.match(/^\d+$/)) {
        // only verify against cargill api if id is number, other cases were guarded by regex during input
        verifyAccountId(accountId).then(data => {
          if (!data) {
            setValue(accountId);
            setDialogVisibility(true);
          }
        });
      }
    }

    // handle account id in extra signup fields
    if (
      ProgramNeedExtraSignIn.ids.includes(programId) &&
      program?.config?.extra_signup_fields?.key === ProgramNeedExtraSignIn.fieldKey
    ) {
      if (!project?.config?.extra_signup_fields?.value) {
        setDialogVisibility(true);
      } else {
        verifyAccountId(project?.config?.extra_signup_fields?.value).then(data => {
          if (!data) {
            setValue(project?.config?.extra_signup_fields?.value);
            setDialogVisibility(true);
          }
        });
      }
    }
  }, [program]);

  const verifyAccountId = async (accountId: string) => {
    if (programId === 68 && (accountId === 'FarmLogs2022' || /JohnDeere2022/i.test(accountId))) {
      return {
        data: {
          name: accountId,
        },
      };
    } else {
      setIsLoading(true);
      try {
        const result = await MRVApi.validateCargill(Number(accountId));
        setIsLoading(false);
        return result;
      } catch (err) {
        setIsLoading(false);
        setError(true);
        reportError(`Cargill account validation endpoint err ${err}`);
        return false;
      }
    }
  };

  const handleConfirmCargillAccountId = async (accountId: string) => {
    if (accountId) {
      const result = await verifyAccountId(accountId);
      if (result) {
        setError(false);
        setDialogVisibility(false);

        const accountIdCustomInput = program?.custom_reg_inputs.find(
          customInput => customInput.validation_rule === ProgramNeedExtraSignIn.fieldValidationRule
        );

        if (accountIdCustomInput) {
          // program configured to use custom reg inputs for account id
          try {
            if (accountIdCustomRegValue) {
              // update it if account id already exists in custom reg values,
              await updateMrvProjectCustomInputsValue({
                projectId,
                input: {...accountIdCustomRegValue, value: accountId},
              });
            } else {
              // create it if account id does not exist in custom reg values
              await createMrvProjectCustomInputsValue({
                projectId,
                input: {
                  key: `${accountIdCustomInput.id}`,
                  name: accountIdCustomInput.name,
                  config: accountIdCustomInput.config,
                  type_: accountIdCustomInput.type_,
                  program_name: program?.name,
                  program_id: program?.id,
                  project_id: projectId,
                  value: accountId,
                },
              });
            }
          } catch (err) {
            reportError(`Cargill account id update err ${err}`);
          }
        } else {
          // program that use hardcoded extra_signup_fields for account id
          dispatch(
            updateProjectConfig({
              projectId,
              config: {
                extra_signup_fields: {key: 'cargill_account_id', value: accountId},
              },
            })
          );
        }

        if (result?.data?.name) {
          showNotification({
            autoClose: 10000,
            title: t({id: 'Confirmed account ID'}),
            message: (
              <span>
                <br />
                {t(
                  {
                    id: 'CargillAccIdConfirmed',
                    defaultMessage: 'Cargill Account ID {accountId} is confirmed as {name}',
                  },
                  {accountId, name: result?.data?.name}
                )}
              </span>
            ),
            type: 'success',
          });
        }
      }
    }
  };

  return (
    <FluroDialog
      id={'mrv-cargill-account-check-dialog'}
      title={t({id: 'Enter Cargill Account ID'})}
      visible={dialogVisible}
      width={400}
      portal={true}
      isClosable={false}
    >
      <Text variant="small">
        {t({
          id: 'ProvideCargillAccIdMessage',
          defaultMessage:
            'To ensure the security of your information, please provide your Cargill Account ID. This Account ID will also be used to issue payment(s) per the Grower Agreement.',
        })}
      </Text>

      <FluroInput
        name="mrv-cargill-account-check-dialog-field"
        className="mt-2"
        placeholder={t({id: 'Cargill Account ID'})}
        value={value}
        onChange={(p: string) => {
          setValue(p);
          setError(false);
        }}
        error={error}
        errorText={error ? t({id: 'Invalid Account ID'}) : ''}
        helpText={t({id: 'CargillAccIdMinLength', defaultMessage: 'Minimum length 6 digits'})}
      />

      <InfoBlock appearance={'info'} color={'info'} mini className="mt-2 mb-2">
        <FormattedMessage
          id="CargillAccIdHelpInfo"
          defaultMessage="Need help with your Account ID? Contact the <a>Cargill support team</a>."
          values={{
            a: (msg: string) => <a href={'mailto:regenconnect@cargill.com'}>{msg}</a>,
          }}
        />
      </InfoBlock>

      <Flex justifyContent="flex-end" className="mt-2" gap={'10px'}>
        <FluroButton onClick={() => dispatch(logout())} raised blank>
          {t({id: 'Logout'})}
        </FluroButton>
        <FluroButton
          disabled={isLoading || !value || `${value}`.length < 6}
          raised
          primary
          onClick={() => value && handleConfirmCargillAccountId(value)}
        >
          {t({id: 'BtnLabel.Submit'})}
        </FluroButton>
      </Flex>
    </FluroDialog>
  );
};
