import {Flex, FluroButton, Text} from 'components';
import {FluroSteps} from 'components/fluro-steps/fluro-steps-dynamic';
import {selectIsLoading} from 'modules/helpers/selectors';
import type {ComponentType} from 'react';
import React, {useEffect, useMemo, useState} from 'react';
import {Link, Redirect} from 'react-router-dom';
import {useAppDispatch, useAppSelector, usePrevious} from '_hooks';
import {sortByDateKey} from '_utils';
import {selectIsAdmin, selectUser} from '../../login/login-selectors';
import {
  selectPhasesByTypeAndProgramId,
  selectProgram,
  selectUserIsProgramAdmin,
  selectUserProjectIds,
  selectUserProjects,
} from '../monitoring/module/selectors';
import {fetchProgram, fetchProjectsList, fetchUserPermissions} from '../monitoring/module/thunks';
import {ActionType} from '../monitoring/module/types';
import {MRV_ADMIN, MRV_HOME} from '../routes';
import type {MRVProjectNormalized} from '../types';
import {MRVPhaseType} from '../types';
import './mrv-project-select.scss';
import {EnrollmentIcon, MonitoringIcon, ReportingIcon} from '../icons';
import c from '_environment';
import {formatLabelsForPhases, isPhaseActive} from '../utils';
import {selectEnrollmentReadOnly, selectMonitoringReadOnly} from './project-select-selectors';
import type {AppStore} from 'reducers';
import {FormattedMessage, t} from 'i18n-utils';
import {ProgramAnnouncement} from '../announcement/program-announcement';
import {FlexDivider} from './flex-divider';
import {toFixedFloatUnsafe} from '_utils/number-formatters';
import {applyPathParams, calcPlural, capitalizeFirstLetter} from '_utils/pure-utils';
import {getLocalStorage, setLocalStorage} from '_hooks/use-storage';
import {useQueryParams} from '_hooks/use-parsed-match-params';
import {MRVApi} from '_api';
import {showNotification} from 'components/notification/notification';

const MRVProjectSelect: ComponentType<{}> = () => {
  const dispatch = useAppDispatch();
  const user = useAppSelector(selectUser);
  const isAdmin = useAppSelector(selectIsAdmin);
  const isProgramAdmin = useAppSelector(selectUserIsProgramAdmin);
  const queryParams = useQueryParams();
  const programCode = queryParams.get('program_code');
  const isLoading = useAppSelector(s =>
    selectIsLoading(s, [ActionType.FETCH_PROJECTS_LIST, fetchUserPermissions.pending.type])
  );

  const projects = useAppSelector(selectUserProjects);
  const userProjectIds = useAppSelector(selectUserProjectIds);

  const [shouldRedirectToAdminPage, setShouldRedirectToAdminPage] = useState(false);
  const prev = usePrevious({isProgramAdmin});

  const fetchProjects = async () => {
    if (!userProjectIds.length || userProjectIds.length === projects.length) return;
    dispatch(fetchProjectsList({ids: userProjectIds}));
  };

  useEffect(() => {
    // helps to redirect program-admins to the admin tab, but only after login
    if (prev?.isProgramAdmin === false && isProgramAdmin) {
      setShouldRedirectToAdminPage(true);
    }
  }, [isProgramAdmin]);

  useEffect(() => {
    if (!user?.id && user?.id !== 'new') return;
    fetchProjects();
  }, [dispatch, userProjectIds, user]);

  useEffect(() => {
    if (projects.length === userProjectIds.length) {
      projects.forEach(p => {
        dispatch(fetchProgram({programId: p.program_id}));
      });
    }
  }, [projects, userProjectIds]);

  /** Check for a `program_code` url parameter, and add user to the program if it exists */
  useEffect(() => {
    if (!programCode) return;

    MRVApi.enrollUserToProgram(programCode)
      .then(response => {
        if (response.status === 400 && response.data !== null) {
          switch (response.data.detail) {
            case MRVApi.enrollUserToProgramExceptionTypes.ALREADY_ENROLLED: {
              // no action necessary
              break;
            }
            case MRVApi.enrollUserToProgramExceptionTypes.ENROLLMENT_CLOSED: {
              showNotification({
                title: 'Error',
                message: 'Enrollment phase is closed.',
                type: 'error',
              });
              break;
            }
            case MRVApi.enrollUserToProgramExceptionTypes.USER_ID_HEADER_MISSING:
            case MRVApi.enrollUserToProgramExceptionTypes.PROGRAM_ID_MISSING: {
              // Wont be possible, we already check for programCode and the user is authenticated
              break;
            }
          }
        } else {
          // Kicking off fetchPermissions means that `userProjectIds` will be updated, which kicks off another useEffect to fetch the project details
          dispatch(fetchUserPermissions());
        }
      })
      .catch();
  }, [programCode, dispatch]);

  const userId = useAppSelector(selectUser).id;

  // Save all projects to recent programs
  useEffect(() => {
    if (projects.length === 0 || userId === 'new') {
      return;
    }

    const recentProgramsByUserId = getLocalStorage('recentMRVProgramsByUserId') || {};

    if (recentProgramsByUserId[userId]?.length > 0) {
      for (const project of projects) {
        const updatedRecentPrograms = recentProgramsByUserId[userId].filter(
          p =>
            p.projectId !== project.id &&
            // The program exists in the list of programs the user has access to
            projects.some(userProject => userProject.id === p.projectId)
        );

        updatedRecentPrograms.unshift({
          programId: project.program_id,
          programName: project.program_name,
          projectId: project.id,
        });

        if (updatedRecentPrograms.length > 5) {
          updatedRecentPrograms.pop();
        }

        recentProgramsByUserId[userId] = updatedRecentPrograms;
      }
    } else {
      recentProgramsByUserId[userId] = projects.map(p => ({
        programId: p.program_id,
        programName: p.program_name,
        projectId: p.id,
      }));
    }

    setLocalStorage('recentMRVProgramsByUserId', recentProgramsByUserId);
  }, [projects, userId]);

  const sortedProjects = useMemo(() => sortByDateKey(projects, 'created_at', true), [projects]);

  if ((isAdmin || isProgramAdmin) && shouldRedirectToAdminPage) {
    return <Redirect to={MRV_ADMIN} />;
  }

  if (
    !isLoading &&
    !programCode &&
    projects.length === 1 &&
    projects.length === userProjectIds.length
  ) {
    return <Redirect to={applyPathParams(MRV_HOME, {projectId: projects[0].id})} />;
  }

  return (
    <div className="producer-list-bg">
      <div className="mrv-project-select-container">
        <div className="inner-wrapper">
          <h1 className={'user-title'}>
            {t(
              {id: 'WelcomeBackUser', defaultMessage: 'Welcome Back, {name}!'},
              {name: user.name || user.email}
            )}
          </h1>

          <h3 className={'select-program-title'}>
            {t({id: 'Choose from your current programs listed below.'})}
          </h3>
          <div className="project-info-wrapper">
            {isLoading && <>{t({id: 'Loading...'})}</>}
            {!isLoading &&
              !!projects.length &&
              sortedProjects.map(project => <ProjectItem key={project.id} project={project} />)}
            {!isLoading && !projects.length && (
              <>
                {/* Do not shot an empty page to admins, redirect to the admin page instead  */}
                {(isAdmin || isProgramAdmin) && <Redirect to={MRV_ADMIN} />}
                <Text variant={'h3'}>
                  <FormattedMessage
                    id="NoProjectDetected"
                    defaultMessage="No projects detected, contact us using the chat button below or email us at <a>support@regrow.ag.</a>."
                    values={{
                      a: (msg: string) => <a href={'mailto:support@regrow.ag'}>{msg}</a>,
                    }}
                  />
                </Text>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

type ProjectItemProps = {
  project: MRVProjectNormalized;
};

const ProjectItem: ComponentType<ProjectItemProps> = ({project}) => {
  const program = useAppSelector(s => selectProgram(s, project?.program_id));
  const phases = useAppSelector(s => selectPhasesByTypeAndProgramId(s, project?.program_id));

  const enrollment = phases?.[MRVPhaseType.Enrollment];
  const monitoring = phases?.[MRVPhaseType.Monitoring];

  const {isReadOnly: enrollmentReadOnly} = useAppSelector((s: AppStore) =>
    selectEnrollmentReadOnly(s, project.id)
  );

  const {isReadOnly: monitoringReadOnly} = useAppSelector((s: AppStore) =>
    selectMonitoringReadOnly(s, project.id)
  );

  const items: any = [
    {
      label: '',
      value: '1',
      icon: <EnrollmentIcon />,
      active: isPhaseActive(enrollment?.start_date, enrollment?.end_date),
      buttonLabel: enrollmentReadOnly ? t({id: 'View Enrollment'}) : t({id: 'Enroll today'}),
      progressValue: 0,
      ...enrollment,
      name: t({id: 'EnrollmentStepName.Enrollment'}),
      isReadOnly: enrollmentReadOnly,
    },
    {
      label: '',
      value: '2',
      icon: <MonitoringIcon />,
      active: isPhaseActive(monitoring?.start_date, monitoring?.end_date),
      buttonLabel: monitoringReadOnly
        ? t({id: 'View Monitoring'})
        : t({id: 'Complete Measurement'}),
      progressValue: 0,
      ...monitoring,
      name: t({id: 'MonitoringStepName.Monitoring'}),
      isReadOnly: monitoringReadOnly,
    },
    {
      label: '',
      value: '3',
      progressValue: 0,
      icon: <ReportingIcon />,
    },
  ];

  const activePhase = items.find((item: any) => item.active);

  if (!project) return null;

  return (
    <Flex className="project-item" fullWidth nowrap>
      <div
        className="project-banner"
        style={{
          backgroundImage: `url(${
            program?.signup_vertical_banner_image_url ||
            `${c.baseUrl}api/v1/mrv/static/images/signup_vertical_banner_image.png`
          })`,
        }}
      />

      <div className="project-info">
        <div className="project-name">{project.program_name}</div>
        <div className="project-values">
          <span className="project-fields-number">
            {project.fields.length} {calcPlural('field', project.fields.length)} -{' '}
          </span>

          <span className="project-fields-area">
            {toFixedFloatUnsafe(project.field_area_ha, 2)}{' '}
            {calcPlural('acre', project.field_area_ha)}
          </span>
        </div>

        <FluroSteps items={items} onItemClick={() => {}} selectedItem={activePhase?.value} />

        <Flex alignItems="center" justifyContent="center" gap="10px" className="phase-block">
          {activePhase && (
            <span className="phase-name">{capitalizeFirstLetter(activePhase.name || '')}</span>
          )}
          {activePhase?.start_date && !activePhase?.isReadOnly && (
            <div className="program-stage-item active phase-dates">
              {formatLabelsForPhases(activePhase?.start_date, activePhase?.end_date)}
            </div>
          )}

          {activePhase?.isReadOnly && (
            <div className="program-stage-item disabled">{t({id: 'Closed: Read Only'})}</div>
          )}
        </Flex>

        <FluroButton
          component={Link}
          to={applyPathParams(MRV_HOME, {projectId: project.id})}
          raised
          primary
        >
          {activePhase?.buttonLabel || t({id: 'BtnLabel.View', defaultMessage: 'View'})}
        </FluroButton>
      </div>

      {program?.display_announcement && (
        <>
          <FlexDivider />
          <ProgramAnnouncement program={program} />
        </>
      )}
    </Flex>
  );
};

export default MRVProjectSelect;
