import React from 'react';
import DateAndLayerControls from 'containers/selectors-toolbar/date-layer-selectors/date-and-layer-controls';
import {useAppSelector} from '_hooks';
import {selectIsNRxEnabled} from 'containers/map/features/nrx/nrx-selectors';
import {selectHasFarmsOrGroupsIds, selectIsSITabOpenWithAccess} from '../../login/login-selectors';
import SelectorsToolbar from 'containers/selectors-toolbar/selectors-toolbar';
import {useMapBarView} from './map-bar-view';
import {selectDialogVisibility} from '../../../modules/helpers/selectors';
import {DialogType} from '../../../modules/helpers';

const TopControlsBar = () => {
  const isCompareOn = useAppSelector(s => s.map.isCompareOn);
  const feature = useAppSelector(s => s.map.feature);
  const hasFarms = useAppSelector(selectHasFarmsOrGroupsIds);
  const isAddingFields = useAppSelector(s => selectDialogVisibility(s, DialogType.addNewField));
  const isNRx = useAppSelector(selectIsNRxEnabled);
  const isSITabOpenWithAccess = useAppSelector(selectIsSITabOpenWithAccess);
  const [wholeTableView] = useMapBarView();

  const notInteractive =
    isAddingFields ||
    feature === 'carbon' ||
    (feature === 'data-layers' && isCompareOn) ||
    (feature === 'zoning' && isNRx);

  if (notInteractive || (!hasFarms && !isSITabOpenWithAccess)) {
    return null;
  }

  const hideDateAndLayersControls =
    feature === 'sustainability-insights' || (feature === 'farm' && wholeTableView);

  return (
    <div className="top-controls-bar">
      {wholeTableView && <SelectorsToolbar type={'table-view-toolbar'} />}
      <div id="top-controls-bar-root" className="observer-date-group">
        {!hideDateAndLayersControls && <DateAndLayerControls />}
      </div>
    </div>
  );
};

export default TopControlsBar;
