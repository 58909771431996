const L = require('leaflet');
require('./layout.scss');
require('./range.scss');

let mapWasDragEnabled;
let mapWasTapEnabled;

// Leaflet v0.7 backwards compatibility
function on(el, types, fn, context) {
  types.split(' ').forEach(function (type) {
    L.DomEvent.on(el, type, fn, context);
  });
}

// Leaflet v0.7 backwards compatibility
function off(el, types, fn, context) {
  types.split(' ').forEach(function (type) {
    L.DomEvent.off(el, type, fn, context);
  });
}

function getRangeEvent(rangeInput) {
  return 'oninput' in rangeInput ? 'input' : 'change';
}

function cancelMapDrag() {
  mapWasDragEnabled = this._map.dragging.enabled();
  mapWasTapEnabled = this._map.tap && this._map.tap.enabled();
  this._map.dragging.disable();
  this._map.tap && this._map.tap.disable();
}

function uncancelMapDrag(e) {
  this._refocusOnMap(e);
  if (mapWasDragEnabled) {
    this._map.dragging.enable();
  }
  if (mapWasTapEnabled) {
    this._map.tap.enable();
  }
}

// convert arg to an array - returns empty array if arg is undefined
function asArray(arg) {
  return arg === 'undefined' ? [] : Array.isArray(arg) ? arg : [arg];
}

function noop() {
  return null;
}
L.Control.SideBySide = L.Control.extend({
  options: {
    thumbSize: 42,
    padding: 0,
  },

  initialize: function (leftLayers, rightLayers, options) {
    this.setLeftLayers(leftLayers);
    this.setRightLayers(rightLayers);
    L.setOptions(this, options);
  },

  getPosition: function () {
    const rangeValue = this._range.value;
    const offset = (0.5 - rangeValue) * (2 * this.options.padding + this.options.thumbSize);
    return this._map.getSize().x * rangeValue + offset;
  },

  setPosition: noop,

  includes: L.Evented.prototype,

  addTo: function (map) {
    this.remove();
    this._map = map;

    const container = (this._container = L.DomUtil.create(
      'div',
      'leaflet-sbs',
      map._controlContainer
    ));

    this._divider = L.DomUtil.create('div', 'leaflet-sbs-divider', container);
    const range = (this._range = L.DomUtil.create('input', 'leaflet-sbs-range', container));
    range.type = 'range';
    range.min = 0;
    range.max = 1;
    range.step = 'any';
    range.value = 0.5;
    range.style.paddingLeft = range.style.paddingRight = this.options.padding + 'px';
    this._addEvents();
    this._updateLayers();
    return this;
  },

  remove: function () {
    if (!this._map) {
      return this;
    }

    if (this._leftLayer) {
      this._leftLayer.getElement().style.clip = '';
      (this._leftLayers || []).forEach(layer => (layer.getElement().style.clip = ''));
    }

    if (this._rightLayer) {
      this._rightLayer.getElement().style.clip = '';
      (this._rightLayers || []).forEach(layer => (layer.getElement().style.clip = ''));
    }

    this._removeEvents();
    L.DomUtil.remove(this._container);

    this._map = null;

    return this;
  },

  setLeftLayers: function (leftLayers) {
    this._leftLayers = asArray(leftLayers);
    this._updateLayers();
    return this;
  },

  setRightLayers: function (rightLayers) {
    this._rightLayers = asArray(rightLayers);
    this._updateLayers();
    return this;
  },

  _updateClip: function () {
    // const map = this._map;
    // const se = map.containerPointToLayerPoint(map.getSize());
    const dividerX = this.getPosition();

    this._divider.style.left = dividerX + 'px';
    this.fire('dividermove', {x: dividerX});

    if (!this._rightLayer) return;
    const bounds = this._rightLayer.getElement().getBoundingClientRect();
    const clipLeftRL = this._divider.getBoundingClientRect().x - bounds.x;

    const clipRight =
      'rect(' +
      [0, bounds.width, bounds.height, clipLeftRL < 0 ? 0 : clipLeftRL].join('px,') +
      'px)';

    if (this._rightLayer) {
      this._rightLayer.getElement().style.clip = clipRight;
      this._rightLayer.getElement().style.zIndex = 9999;
    }
  },

  _updateLeftClip: function () {
    const dividerX = this.getPosition();

    if (this._divider.style.left !== dividerX.toFixed(3) + 'px') {
      this._divider.style.left = dividerX + 'px';
      this.fire('dividermove', {x: dividerX});
    }
    if (!this._leftLayer) return;

    const bounds = this._leftLayer.getElement().getBoundingClientRect();
    const clipLeftRL = this._divider.getBoundingClientRect().x - bounds.x;

    this._leftLayer.getElement().style.clip =
      'rect(' + [0, clipLeftRL < 0 ? 0 : clipLeftRL, bounds.height, 0].join('px,') + 'px)';
    this._leftLayer.getElement().style.zIndex = 9999;
  },

  _updateLayers: function () {
    if (!this._map) {
      return this;
    }
    const prevLeft = this._leftLayer;
    const prevRight = this._rightLayer;
    this._leftLayer = this._rightLayer = null;
    this._leftLayers.forEach(function (layer) {
      if (this._map.hasLayer(layer)) {
        this._leftLayer = layer;
        this._updateLeftClip();
      }
    }, this);
    this._rightLayers.forEach(function (layer) {
      if (this._map.hasLayer(layer)) {
        this._rightLayer = layer;
        this._updateClip();
      }
    }, this);
    if (prevLeft !== this._leftLayer) {
      prevLeft && this.fire('leftlayerremove', {layer: prevLeft});
      this._leftLayer && this.fire('leftlayeradd', {layer: this._leftLayer});
    }
    if (prevRight !== this._rightLayer) {
      prevRight && this.fire('rightlayerremove', {layer: prevRight});
      this._rightLayer && this.fire('rightlayeradd', {layer: this._rightLayer});
    }
    this._updateClip();
  },

  _addEvents: function () {
    const range = this._range;
    const map = this._map;
    if (!map || !range) return;
    // map.on('move', this._updateClip, this);
    map.on('move', this._updateLayers, this);
    map.on('layeradd layerremove', this._updateLayers, this);
    // on(range, getRangeEvent(range), this._updateClip, this);
    on(range, getRangeEvent(range), this._updateLayers, this);
    on(range, L.Browser.touch ? 'touchstart' : 'mousedown', cancelMapDrag, this);
    on(range, L.Browser.touch ? 'touchend' : 'mouseup', uncancelMapDrag, this);
  },

  _removeEvents: function () {
    const range = this._range;
    const map = this._map;
    if (range) {
      // off(range, getRangeEvent(range), this._updateClip, this);
      off(range, getRangeEvent(range), this._updateLayers, this);
      off(range, L.Browser.touch ? 'touchstart' : 'mousedown', cancelMapDrag, this);
      off(range, L.Browser.touch ? 'touchend' : 'mouseup', uncancelMapDrag, this);
    }
    if (map) {
      map.off('layeradd layerremove', this._updateLayers, this);
      // map.off('move', this._updateClip, this)
      map.off('move', this._updateLayers, this);
    }
  },
});

L.control.sideBySide = function (leftLayers, rightLayers, options) {
  return new L.Control.SideBySide(leftLayers, rightLayers, options);
};

module.exports = L.Control.SideBySide;
