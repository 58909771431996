// @ts-nocheck
import type {ReactNode, ReactElement} from 'react';
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {t} from 'i18n-utils';
import {genKey} from '_utils/pure-utils';
import {DropdownMenu, AccessibleFakeButton, Checkbox, TextField} from 'react-md';
import {
  SelectCheckboxWrapper,
  SelectCheckboxItemWrapper,
  SelectCheckboxMenuWrapper,
  SelectCheckboxSearchWrapper,
  SelectCheckboxButtonWrapper,
  LabelWrapper,
} from './select-checkbox.styled';
import {FilterIcon} from 'containers/map/icons';
import {IndeterminateCheckboxIcon, UncheckedCheckboxIcon} from '../fluro-icons';

export type SelectCheckboxItem = {
  label: string | number | ReactElement;
  textLabel?: string;
  value: string | number;
};

type Props = {
  id?: string;
  title?: string;
  withSearch?: boolean;
  menuItems: SelectCheckboxItem[];
  onChange: (values: SelectCheckboxItem[]) => void;
  selectedValues: Array<number | string>;
  selectAllCheckbox?: boolean;
  triggerComponent?: (selectedItemsNumber: number) => ReactElement;
  searchChangeCallback?: (searchValue: string) => void;
  icon?: ReactNode;
  hideCount?: boolean;
  menuMinHeight?: boolean;
  error?: boolean;
  errorText?: string;
};

const mainTextClass: string = 'select-checkbox';

export const SelectCheckbox = ({
  selectedValues,
  onChange,
  title,
  menuItems,
  id,
  withSearch = false,
  selectAllCheckbox,
  triggerComponent,
  icon = <FilterIcon />,
  hideCount = false,
  searchChangeCallback,
  menuMinHeight = true,
  error,
  errorText,
}: Props) => {
  const [selectedItems, setSelectedItems] = useState<{[key in string | number]: boolean}>({});

  useEffect(() => {
    if (Array.isArray(selectedValues)) {
      if (selectedValues.length) {
        let result: any = [];

        selectedValues.forEach((value: any) => {
          result[value] = true;
        });

        setSelectedItems(result);
      } else {
        setSelectedItems({});
      }
    }
  }, [selectedValues]);

  const [search, setSearch] = useState('');
  const _id = useMemo(() => id || genKey(), [id]);

  const onSetSearch = (value: string) => {
    setSearch(value);
    searchChangeCallback?.(value);
  };

  const onSelect = useCallback(
    (id: number | string, value: boolean) => {
      let selectedResult: SelectCheckboxItem[] = [];

      if (id === 'all') {
        selectedResult = value ? menuItems : [];
      } else {
        const selectedItemsArray = menuItems.filter(item => selectedValues.includes(item.value));
        selectedResult = value
          ? [...selectedItemsArray, menuItems.find(item => item.value === id)]
          : selectedItemsArray.filter(item => item.value !== id);
      }

      onChange(selectedResult);
    },
    [selectedItems, menuItems]
  );

  const filteredMenuItems = useMemo(() => {
    if (search) {
      return menuItems
        .filter(item => {
          if (item.textLabel) {
            return item.textLabel.toLowerCase().includes(search?.toLowerCase());
          }

          return (
            typeof item.label === 'string' &&
            item.label.toLowerCase().includes(search?.toLowerCase())
          );
        })
        .slice(0, 300);
    }

    return menuItems.slice(0, 300);
  }, [search, menuItems]);

  return (
    <SelectCheckboxWrapper>
      <DropdownMenu
        id={_id}
        simplifiedMenu={true}
        position="bl"
        menuItems={
          <SelectCheckboxMenuWrapper minHeight={menuMinHeight}>
            <SelectCheckboxSearchWrapper>
              {withSearch && (
                <div>
                  <TextField
                    id={`${_id}-${mainTextClass}`}
                    placeholder="Search"
                    value={search}
                    onChange={(value: string) => onSetSearch(value)}
                  />
                </div>
              )}
            </SelectCheckboxSearchWrapper>

            {selectAllCheckbox && (
              <SelectCheckboxItemWrapper key={`${_id}-${mainTextClass}-item-select-all`}>
                <Checkbox
                  id={`${_id}-select-all`}
                  name={`${_id}-select-all`}
                  uncheckedIcon={
                    selectedValues.length ? (
                      <IndeterminateCheckboxIcon />
                    ) : (
                      <UncheckedCheckboxIcon />
                    )
                  }
                  checked={selectedValues.length === menuItems.length}
                  onChange={() =>
                    onSelect(
                      'all',
                      selectedValues.length === menuItems.length ? false : !selectedValues.length
                    )
                  }
                  label={<div style={{whiteSpace: 'nowrap'}}>{t({id: 'All'})}</div>}
                />
              </SelectCheckboxItemWrapper>
            )}

            {filteredMenuItems.map((el, i: number) => {
              const checkBoxKey: string = genKey();

              return (
                <SelectCheckboxItemWrapper key={`${_id}-${mainTextClass}-item-${i}`}>
                  <Checkbox
                    id={checkBoxKey}
                    name={checkBoxKey}
                    checked={!!selectedItems[el.value]}
                    onChange={(value: boolean) => onSelect(el.value, value)}
                    label={<div style={{whiteSpace: 'nowrap'}}>{el.label}</div>}
                  />
                </SelectCheckboxItemWrapper>
              );
            })}
          </SelectCheckboxMenuWrapper>
        }
        centered
      >
        <AccessibleFakeButton>
          {triggerComponent ? (
            triggerComponent(selectedValues.length)
          ) : (
            <SelectCheckboxButtonWrapper className={'select-checkbox-button-wrapper'}>
              <LabelWrapper>
                <span>{title}</span> {!hideCount && <b>{selectedValues.length}</b>}
              </LabelWrapper>
              {icon}
            </SelectCheckboxButtonWrapper>
          )}
        </AccessibleFakeButton>
      </DropdownMenu>
      {error && errorText && (
        <div className={'md-text-field-message-container md-full-width md-text--error'}>
          {errorText}
        </div>
      )}
    </SelectCheckboxWrapper>
  );
};
