import Server from './server';

export default class {
  static createPlanetOrder(payload: {
    sensor: string;
    start_date: string; // YYYY-MM-DD
    end_date: string; // YYYY-MM-DD
    note: string;
    field_ids: number[];
  }) {
    return Server.post(`planet_orders`, payload, {params: {__skipPreloader: true}});
  }

  static approvePlanetOrder(orderId: number) {
    return Server.put(`planet_orders/approve/${orderId}`, {}, {params: {__skipPreloader: true}});
  }

  static getPlanetOrders() {
    return Server.get(`planet_orders`, {params: {__skipPreloader: true}});
  }
}
