import {Col, Flex} from 'components/flex';
import {selectIsFailed, selectIsLoading, selectIsSucceed} from 'modules/helpers/selectors';
import React from 'react';
import {CircularProgress, FontIcon} from 'react-md';
import {Route, Switch} from 'react-router-dom';
import {useAppSelector} from '_hooks';
import {MONITORING_PROJECT_FORM} from '../routes';
import {MRVForm} from './form/mrv-form';
import {ActionType} from './module/types';
import './monitoring.scss';
import {t} from 'i18n-utils';
import {selectStageById} from 'containers/mrv/monitoring/module/selectors';
import {useMonitoringUrlParams} from 'containers/mrv/monitoring/hooks';
import {MRVPhaseType} from '../types';
import {getStageName} from 'containers/mrv/base';

const loadingActions = [
  ActionType.FETCH_PROGRAM,
  ActionType.FETCH_PROJECT,
  ActionType.FETCH_PROJECT_VALUES,
];
const updatingActions = [ActionType.UPDATE_PROJECT_VALUES];

export const MonitoringPanel = () => {
  const isLoading = useAppSelector(s => selectIsLoading(s, loadingActions));
  const isUpdating = useAppSelector(s => selectIsLoading(s, updatingActions));
  const isSaved = useAppSelector(s => selectIsSucceed(s, updatingActions));
  const isFailed = useAppSelector(s => selectIsFailed(s, updatingActions));

  const {stageId} = useMonitoringUrlParams();
  const stage = useAppSelector(s => selectStageById(s, stageId));

  return (
    <div className="monitoring-panel">
      <Flex alignItems="center" justifyContent="space-between">
        <Col>
          <Flex alignItems="center">
            <h2 className="tab-title">
              {getStageName(stage) ||
                t({id: 'MonitoringStageStep.Measurement', defaultMessage: 'Measurement'})}
            </h2>
            <div className="ml-1">
              <Flex alignItems="center">
                {isLoading && <FontIcon className="save-icon">sync</FontIcon>}
                {!isLoading && (
                  <>
                    {isUpdating && (
                      <FontIcon className="animation--blink save-icon">cloud_upload</FontIcon>
                    )}
                    {isSaved && <FontIcon className="save-icon">cloud_done</FontIcon>}
                    {isFailed && <FontIcon className="save-icon">sync_problem</FontIcon>}
                  </>
                )}
                {(isLoading || isUpdating) && (
                  <CircularProgress className="ml-05" scale={0.7} id="monitoring-panel-loader" />
                )}
              </Flex>
            </div>
          </Flex>
        </Col>
        {/* <Col>
          <MapBarViewTabs />
        </Col> */}
      </Flex>
      <Switch>
        <Route path={MONITORING_PROJECT_FORM} component={MonitoringForm} />
      </Switch>
    </div>
  );
};

const MonitoringForm = () => <MRVForm phaseType={MRVPhaseType.Monitoring} />;
