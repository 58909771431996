import type {Dependency, DependencyGroup, WithValue} from 'containers/mrv/types';

export function isAttributeDisabledByDependencyRules(
  attributeDependencies: (Dependency | DependencyGroup)[],
  values: Record<number, WithValue>
): boolean {
  for (let i = 0; i < attributeDependencies.length; i++) {
    if (!isDependantEnabledRecursive(attributeDependencies[i], values)) {
      return true;
    }
  }

  return false;
}

/**
 * An Attribute is enabled when `isDependantEnabled` returns `true` for all `attribute.dependencies` recursively
 */
function isDependantEnabledRecursive(
  dependency: Dependency | DependencyGroup,
  values: Record<number, WithValue>
): boolean {
  if (isAttributeDependency(dependency)) {
    return isDependantEnabled(dependency, values);
  }

  if (isAttributeDependencyGroup(dependency)) {
    const {dependencies, operator} = dependency;

    if (operator === 'and') {
      // A bug in TypeScript doesn't allow `.every` on arrays with `A[] | B[]` types, so we have to use `for`.
      // https://github.com/microsoft/TypeScript/issues/44373
      for (let i = 0; i < dependencies.length; i++) {
        if (!isDependantEnabledRecursive(dependencies[i], values)) {
          return false;
        }
      }

      return true;
    }

    return dependencies.some(d => isDependantEnabledRecursive(d, values));
  }

  return true;
}

function isDependantEnabled(dependency: Dependency, values: Record<number, WithValue>) {
  const {id: attributeId, operator, value} = dependency;
  const dependencyValue = values[attributeId]?.value;

  if (!dependencyValue) {
    return false;
  }

  if (operator === 'is') {
    return value === dependencyValue;
  }

  // operator === 'is_not';
  return value !== dependencyValue;
}

export function isAttributeDependency(
  dependency: Dependency | DependencyGroup
): dependency is Dependency {
  return (dependency as Dependency).id !== undefined;
}

export function isAttributeDependencyGroup(
  dependency: Dependency | DependencyGroup
): dependency is DependencyGroup {
  return (dependency as DependencyGroup).dependencies !== undefined;
}
