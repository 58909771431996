// @ts-nocheck
import type {ComponentType} from 'react';
import React, {useCallback} from 'react';
import {useAppDispatch} from '_hooks';
import {FluroButton} from 'components';
import {setSelectedPlatformToImport} from '../actions';

export const SelectedPlatformBackButton: ComponentType = () => {
  const dispatch = useAppDispatch();
  const onClick = useCallback(() => {
    dispatch(setSelectedPlatformToImport(null));
  }, []);

  return (
    <FluroButton raised blank onClick={onClick}>
      Back
    </FluroButton>
  );
};
