import {useEffect} from 'react';
import {onRecordsUpdated} from '_reducers/crop-performance-filter/field-filter-reducer';
import {useAppDispatch, useAppSelector} from '_hooks';
import {selectCropPerformance} from 'containers/map/features/crop-performance/crop-performance-selectors';

export const useUpdateFilter = () => {
  const dispatch = useAppDispatch();
  const cropPerformance = useAppSelector(selectCropPerformance);

  useEffect(() => {
    dispatch(onRecordsUpdated(cropPerformance.records));
  }, [cropPerformance.records]);
};
