import type {MRVStageNormalized} from 'containers/mrv/types';
import {MRVPhaseType, MRVStageType} from 'containers/mrv/types';
import {useCallback, useMemo} from 'react';
import {useAppSelector} from '_hooks';
import {
  selectCompletion,
  selectEnabledPhaseStages,
  selectCurrentProjectFieldsList,
} from '../module/selectors';

export const useIsStageDisabled = (phase: MRVPhaseType) => {
  const stages = useAppSelector(s => selectEnabledPhaseStages(s, phase));
  const completion = useAppSelector(selectCompletion);
  const oneIncompleteStageAtATime = phase === MRVPhaseType.Enrollment;
  const projectFieldsList = useAppSelector(selectCurrentProjectFieldsList);

  const isStageIncomplete = useCallback(
    (stage: MRVStageNormalized) => {
      if (stage.type_ === MRVStageType.FieldBoundaries) {
        // FieldBoundaries stage doesn't use the completion API
        return projectFieldsList.length === 0;
      }
      return completion?.[stage.id] && !completion[stage.id].is_completed;
    },
    [completion, projectFieldsList.length]
  );

  // 1. All stages after Eligibility are disabled if it's not complete.
  // 2. Eligibility is disabled when any of the previous stages are incomplete.
  // 3. If oneIncompleteStageAtATime, a stage is disabled when any of the previous stages are incomplete.
  const {disabledAfterOrder, eligibilityDisabled, contractDisabled} = useMemo(() => {
    const eligibilityStage = stages?.find(s => s.type_ === MRVStageType.Eligibility);
    const contractStage = stages?.find(s => s.type_ === MRVStageType.Contract);

    const _contractDisabled =
      !!contractStage && stages.some(s => s.order < contractStage?.order && isStageIncomplete(s));

    const _eligibilityDisabled =
      !!eligibilityStage &&
      stages.some(s => s.order < eligibilityStage?.order && isStageIncomplete(s));

    const eligibilityIncomplete = !!eligibilityStage && isStageIncomplete(eligibilityStage);

    let _disabledAfterOrder = -1;

    if (oneIncompleteStageAtATime) {
      for (let i = 0; i < stages.length; i++) {
        if (isStageIncomplete(stages[i])) {
          _disabledAfterOrder = stages[i].order;
          break;
        }
      }
    } else if (_eligibilityDisabled || eligibilityIncomplete) {
      _disabledAfterOrder = eligibilityStage.order;
    }

    return {
      disabledAfterOrder: _disabledAfterOrder,
      eligibilityDisabled: _eligibilityDisabled,
      contractDisabled: _contractDisabled,
    };
  }, [stages, isStageIncomplete, oneIncompleteStageAtATime]);

  /**
   * 1. Eligibility (and everything after it) is off when any of the previous stages are incomplete.
   *
   * Summer, Winter, Tillage, Eligibility, Nutrient, Contract.
   * [100%]  [100%]  [90%]      [off]       [off]      [off]
   *
   *
   * 2. Eligibility is on when all previous stages are complete.
   *    But all the stages after Eligibility are off, until Eligibility is complete.
   *
   * Summer, Winter, Tillage, Eligibility, Nutrient, Contract.
   * [100%]  [100%]  [100%]    [<100%]      [off]     [off]
   *
   *
   * 3. Contract is off when any of the previous stages are incomplete.
   *
   * Summer, Winter, Tillage, Eligibility, Nutrient, Contract.
   * [100%]  [100%]  [100%]     [100%]     [<100%]    [off]
   *
   *
   * 4. Contract is on when all previous stages are complete.
   *
   * Summer, Winter, Tillage, Eligibility, Nutrient, Contract.
   * [100%]  [100%]  [100%]     [100%]      [100%]    [on]
   */
  const isStageDisabled = useCallback(
    (stage: MRVStageNormalized) => {
      return (
        (disabledAfterOrder !== -1 && stage.order > disabledAfterOrder) ||
        (stage.type_ === MRVStageType.Contract && contractDisabled) ||
        (stage.type_ === MRVStageType.Eligibility && eligibilityDisabled)
      );
    },
    [disabledAfterOrder, eligibilityDisabled, contractDisabled]
  );

  return isStageDisabled;
};
