import type {CLUFieldBoundary, ProtectedArea} from 'modules/add-fields/types';
import Server from './server';
// import {reportError} from 'containers/error-boundary';

const SERVICE_PREFIX = 'padus';

export default class {
  static intersection(geom: GeoJSON.Polygon | CLUFieldBoundary | GeoJSON.Feature) {
    return Server.post<ProtectedArea[]>(
      `${SERVICE_PREFIX}/intersections`,
      {
        geom,
      },
      {params: {__skipPreloader: true}}
    ).catch(() => {
      // reportError('pad-us service error');
      return {data: []};
    });
  }

  static intersections(
    geoms: {
      geom: GeoJSON.Feature | GeoJSON.Polygon | CLUFieldBoundary;
      geometry_id: number | string;
    }[]
  ) {
    return Server.post<PadusResponse[]>(`${SERVICE_PREFIX}/intersections-multiple`, geoms, {
      params: {__skipPreloader: true},
    }).catch(() => {
      // reportError('pad-us service error');
      return {data: [] as PadusResponse[]};
    });
  }
}

type PadusResponse = {
  areas: ProtectedArea[];
  geometry_id: number | string;
};
