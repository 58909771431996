// @ts-nocheck
import {t} from 'i18n-utils';
import * as React from 'react';
import {sum} from '_utils/pure-utils';
import type {Props as LegendListProps} from 'components/legend-list/legend-list';
import {LegendList} from 'components/legend-list/legend-list';
import {GrowthStagesLegendList} from './crop-growth-stages/crop-growth-stages';
import {Card} from 'components/card/card';
import {useSelector} from 'react-redux';
import type {AppStore} from 'reducers';
import {Aggregation} from './types';
import {classifyAreaWithUnits} from '_utils';
import type {ChartData} from 'components/stacked-single-bar-chart/stacked-single-bar-chart';
import {StackedSingleBarChart} from 'components/stacked-single-bar-chart/stacked-single-bar-chart';
import {CPFilterType} from '_reducers/crop-performance-filter/field-filter-types';
import {useAppDispatch, useAppSelector} from '_hooks';
import {selectCropPerformance} from './crop-performance-selectors';
import {setRepresentation} from './reducer';
import {clearFilter} from '_reducers/crop-performance-filter/field-filter-reducer';
import {useCallback} from 'react';
import {selectMeasurement} from '../../../login/login-selectors';
import type {Measurement} from '../../../login/types';

export type CardProps = {
  type: CPFilterType;
  title: string;
  subtitle?: string;
  selectedLabels: string[];
  selectedCountLabel: string;
  data: ChartData & {areas?: number[]};
  info?: JSX.Element;
  onShowTooltip?: (id: string, content: JSX.Element) => void;
  onFilterToggle?: (value: string) => void;
};

export const CropPerformanceCard = ({
  type,
  title,
  subtitle,
  selectedLabels,
  selectedCountLabel,
  data,
  info,
  onShowTooltip,
  onFilterToggle,
}: CardProps) => {
  const dispatch = useAppDispatch();
  const cropPerformance = useAppSelector(selectCropPerformance);
  const measurement = useAppSelector(selectMeasurement);
  const isReportView = useSelector((store: AppStore) => store.global?.isReportView);

  const aggregation = cropPerformance.aggregation;

  const onWidgetClick = useCallback(() => dispatch(setRepresentation(type)), []);

  const onFilterClear = useCallback(() => dispatch(clearFilter(type)), []);

  return (
    <Card
      active={cropPerformance.representation === type}
      title={title}
      subtitle={subtitle}
      resetFilterDisabled={selectedLabels.length === 0}
      info={info}
      onClick={onWidgetClick}
      onFilterClear={onFilterClear}
      isReportView={isReportView}
    >
      <StackedSingleBarChart
        data={data}
        selectedLabels={selectedLabels}
        onFilterToggle={onFilterToggle}
        onShowTooltip={onShowTooltip}
      />
      {type === CPFilterType.CROP_GROWTH_STAGE ? (
        <GrowthStagesLegendList
          selectedCount={selectedCountLabel}
          onClick={onFilterToggle}
          data={data}
          selectedLabels={selectedLabels}
        />
      ) : (
        <LegendList
          selectedCount={selectedCountLabel}
          onClick={onFilterToggle}
          list={classifiedLegendListData({data, selectedLabels, aggregation, measurement})}
        />
      )}
    </Card>
  );
};

type ClassifiedLegendProps = Partial<CardProps> & {
  aggregation: Aggregation;
  measurement: Measurement;
};

const classifiedLegendListData = ({
  data,
  selectedLabels,
  aggregation,
  measurement,
}: ClassifiedLegendProps): LegendListProps['list'] => {
  const total = sum(data.values);

  return data.labels.map((label, i) => {
    const predicted = data.subvalues?.[i];
    const count = data.values[i];
    let countText;
    switch (true) {
      case aggregation === Aggregation.COUNT && !!predicted:
        countText =
          predicted === count
            ? t({id: '{count} predicted'}, {count})
            : t({id: '{predicted} / {count} predicted'}, {predicted, count});
        break;
      case aggregation === Aggregation.COUNT && !predicted:
        countText = t({id: '{count} crops'}, {count});
        break;
      case aggregation === Aggregation.SUM && !!predicted:
        const predictedArea = classifyAreaWithUnits(predicted, measurement);
        const countArea = classifyAreaWithUnits(count, measurement);
        countText =
          predicted === count
            ? `${countArea} predicted`
            : `${predictedArea} / ${countArea} predicted`;
        break;
      case aggregation === Aggregation.SUM && !predicted:
        countText = classifyAreaWithUnits(count, measurement);
        break;
    }
    return {
      label,
      value: label,
      deselected: selectedLabels.length && !selectedLabels.includes(label),
      backgroundColor: data.colors[i],
      percentage: Math.round((data.values[i] / total) * 100),
      count: countText,
    };
  });
};
