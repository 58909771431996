import {t} from 'i18n-utils';
import React from 'react';
import type {MeasurementSystem} from '_utils';
import {formatUnit, convertUnit} from '_utils';
import {toFixedFloat} from '_utils/number-formatters';
import type {WeatherSources} from './temperature-chart';

export type RainData = {totalRain: number; lastRain: number};
export type GddData = {totalGdd: number; lastGdd: number; tBase: number};

type Props = RainData &
  GddData & {
    measurement: MeasurementSystem;
    source: WeatherSources;
  };

const formatRainValue = (rainValue: number, measurement: MeasurementSystem) => {
  if (!rainValue) rainValue = 0;

  return `${toFixedFloat(convertUnit(measurement, 'mm', rainValue), 1)} ${formatUnit(
    measurement,
    'mm'
  )}`;
};
const formatGddValue = (gdd: number, measurement: MeasurementSystem) => {
  if (!gdd) gdd = 0;

  return `${convertUnit(measurement, 'gdd', gdd).toFixed()}°${formatUnit(measurement, 'C')}`;
};

const TemperatureChartInfo = ({
  measurement,
  totalGdd,
  totalRain,
  tBase,
  lastGdd,
  lastRain,
  source,
}: Props) => {
  const isIteris = source === 'iteris';

  return (
    <div className={'temperature-chart-info'}>
      <div className="info-item">
        <div className="info-item__title">Rain</div>
        <div className={'info-item__values'}>
          <div className="value color-black">
            {formatRainValue(totalRain, measurement)}
            <span className={'thin-font'}> {t({id: 'since sowing'})}</span>
          </div>
          <div className="value color-green">
            {formatRainValue(lastRain, measurement)}{' '}
            <span className={'thin-font'}> {t({id: 'last 7 days'})}</span>
          </div>
        </div>
      </div>

      {totalGdd !== 0 && (
        <div className="info-item">
          <div className="info-item__title">
            Day Degrees{' '}
            {isIteris && (
              <span className={'thin-font'}>
                ({t({id: 'Tbase'})} = {convertUnit(measurement, 'C', tBase)} °)
              </span>
            )}
          </div>
          <div className={'info-item__values'}>
            <div className="value color-black">
              {formatGddValue(totalGdd, measurement)}
              <span className={'thin-font'}> {t({id: 'since sowing'})}</span>
            </div>
            <div className="value color-green">
              {formatGddValue(lastGdd, measurement)}{' '}
              <span className={'thin-font'}> {t({id: 'last 7 days'})}</span>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default TemperatureChartInfo;
