import React from 'react';
import {ExternalService} from 'containers/map/types';
import styled from 'styled-components';

type Props = {
  value: ExternalService;
  staticPosition?: boolean;
};

type ExternalServiceIconWrapperProps = {
  staticPosition?: boolean;
};

const ExternalServiceIconWrapper = styled.div`
  ${({staticPosition}: ExternalServiceIconWrapperProps) =>
    staticPosition
      ? ''
      : `position: absolute;
  right: 8px;
  top: 10px;`}

  display: flex;
  align-items: center;
  justify-content: center;

  > img {
    width: 19px;
    height: 19px;
  }
`;

const ExternalServiceIcon = ({value, staticPosition = false}: Props) => {
  // common types are terravion, in this case we do not show the logo
  if (value === ExternalService.Terravion) return null;
  if (value === ExternalService.Agx) value = ExternalService.Proagrica; // show Proagrica logo instead

  return (
    <ExternalServiceIconWrapper staticPosition={staticPosition}>
      <img src={`/assets/integration/${value}-logo-round.svg`} alt={value} />
    </ExternalServiceIconWrapper>
  );
};

export default ExternalServiceIcon;
