// @ts-nocheck
import React from 'react';
import {EnrollmentStep} from 'containers/mrv/enrollment/carbon-store/types';
import {useMonitoringUrlParams} from 'containers/mrv/monitoring/hooks';
import {updateProjectDNDCStatus} from 'containers/mrv/monitoring/module/reducer';
import {selectProjectById} from 'containers/mrv/monitoring/module/selectors';
import {dialogToggle} from 'modules/helpers/actions';
import {DialogType} from 'modules/helpers/types';
import MRVApi from '_api/mrv';
import {useAppDispatch, useAppSelector} from '_hooks';
import {reportError} from 'containers/error-boundary';
import {DNDCStatus} from 'containers/mrv/types';
import {
  selectContractExists,
  selectDNDC,
  selectEnrollmentReadOnly,
} from 'containers/mrv/enrollment/carbon-store/selectors';
import {Text} from 'components/text/text';
import {FormattedMessage, useTranslation} from 'i18n-utils';

export function useOpenDNDCModal(
  navigateToStep?: (step: EnrollmentStep, stageId?: number) => void
) {
  const dispatch = useAppDispatch();
  const {projectId} = useMonitoringUrlParams();
  const project = useAppSelector(s => selectProjectById(s, projectId));
  const {isReadOnly} = useAppSelector(selectEnrollmentReadOnly);
  const contractExists = useAppSelector(selectContractExists);
  const dndc = useAppSelector(selectDNDC);
  const t = useTranslation();

  return {
    canOpenDNDCModal: !isReadOnly && !contractExists && dndc.status === null,
    openDNDCModal: () =>
      dispatch(
        dialogToggle(DialogType.confirm, true, {
          okLabel: t({id: 'BtnLabel.GetStarted', defaultMessage: 'Get Started'}),
          okAction: async () => {
            try {
              // Kick off the DNDC run! 🏃🏻‍♂️
              await MRVApi.runDNDC(projectId, project?.fields);
              // Set the state to "in_progress" in the UI to display loading and control user flow.
              // The backend returns `dndc_status: "in_progress"` at the project level if you refresh.
              dispatch(updateProjectDNDCStatus({projectId, status: DNDCStatus.InProgress}));
              navigateToStep?.(EnrollmentStep.ViewOutcomes);
            } catch (error) {
              dispatch(updateProjectDNDCStatus({projectId, status: DNDCStatus.Failed}));
              reportError(error);
            }
          },
          title: t({id: 'DNDCModalMessageTitle', defaultMessage: 'Are you sure?'}),
          message: (
            <FormattedMessage
              id="DNDCModalMessage"
              defaultMessage="<Text>Calculations may take a few moments.</Text>
          <Text>
            While outcomes are being calculated you will not be able to edit your fields.
          </Text>"
              values={{
                Text: (msg: string) => <Text>{msg}</Text>,
              }}
            />
          ),
        })
      ),
  };
}
