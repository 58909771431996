// @ts-nocheck
import {FluroChipDropdownControllable} from 'components/fluro-dropdown/fluro-chip-dropdown';
import {setAbatementPotentialMetric} from 'modules/sustainability-insights/actions';
import {abatementPotentialMetricItems} from 'modules/sustainability-insights/constants';
import {selectAbatementPotentialMetric} from 'modules/sustainability-insights/selectors';
import type {SIFilterState} from 'modules/sustainability-insights/types';
import React, {useCallback, useState} from 'react';
import {SelectionControl} from 'react-md';
import {useDispatch} from 'react-redux';
import {useAppSelector} from '_hooks';

const AbatementPotentialMetricFilter = () => {
  const dispatch = useDispatch();
  const abatementPotentialMetric = useAppSelector(selectAbatementPotentialMetric);
  const [expand, setExpand] = useState(false);

  const onChangeSelectedMetric = useCallback(
    (value: SIFilterState['abatementPotential']['selectedMetric']) => {
      dispatch(setAbatementPotentialMetric(value));
      setExpand(false);
    },
    []
  );

  const userFacingLabel =
    abatementPotentialMetricItems.find(({value}) => value === abatementPotentialMetric)?.label ??
    '';
  return (
    <>
      <FluroChipDropdownControllable
        active
        expand={expand}
        setExpand={setExpand}
        label={userFacingLabel}
      >
        {abatementPotentialMetricItems.map(({label, value}) => (
          <SelectionControl
            key={value}
            type="radio"
            checked={abatementPotentialMetric === value}
            name="area-type"
            id={`select-area-type-${value}`}
            value={value}
            label={label}
            onChange={onChangeSelectedMetric}
          />
        ))}
      </FluroChipDropdownControllable>
    </>
  );
};

export default AbatementPotentialMetricFilter;
