import type {CSGViewModel} from '../types';
import {cropGrowthLabels} from '../types';
import {downloadFile} from '_utils/pure-utils';
import {reportError} from 'containers/error-boundary';
import type {PeriodicData} from './periodic';
import {tableHeaders} from '../table-view-csg-headers';
import {t} from 'i18n-utils';

// Export table should have crop variety column.
const exportTableHeaders = [...tableHeaders];
const cropTypeIndex = exportTableHeaders.findIndex(h => h.value === 'cropType');
const cropVarietyIndex = cropTypeIndex + 1;
exportTableHeaders.splice(cropVarietyIndex, 0, {label: 'Crop variety', value: 'cropVariety'});

export const exportFile = async (
  fileType: 'csv' | 'xlsx',
  records: CSGViewModel[],
  periodic: PeriodicData
) => {
  // 1. Add biomass change column to headers.
  const headerRow = [
    ...exportTableHeaders.map(h => h.label),
    `Biomass change ${periodic.period}`,
  ].join('\t');
  const rows = [
    headerRow,
    ...records.map(r =>
      [
        ...exportTableHeaders.map(h => {
          if (h.value === 'growth') {
            return cropGrowthLabels[r[h.value]];
          }
          if (h.value === 'smoothSatelliteNdvi') {
            return r[h.value] ? r[h.value].toFixed(2) : 'No images';
          }
          if (h.value === 'cumulativeSmoothSatelliteNdvi') {
            return r[h.value] ? r[h.value].toFixed(1) : 'No images';
          }
          return r[h.value];
        }),
        // 2. Add biomass change column to table rows.
        periodic?.diffs[r.fieldID] == null
          ? t({id: 'Not available'})
          : `${periodic?.diffs[r.fieldID]}%`,
      ].join('\t')
    ),
  ];
  const csv = rows.join('\n') + '\n';
  switch (fileType) {
    case 'csv':
      downloadFile(csv, `crop_performance_report.csv`, 'text/csv');
      break;
    case 'xlsx':
      const XLSX = await import('xlsx');
      const wb = XLSX.read(csv, {type: 'string', raw: true});
      XLSX.writeFile(wb, 'crop_performance_report.xlsx');
      break;
    default:
      reportError(`Crop perf export error: file type "${fileType}" is not supported`);
  }
};
