import Button from 'containers/login/TO_GO_INTO_DS/Button';
import {BodyText16} from 'containers/login/TO_GO_INTO_DS/FsType/BodyText16';
import {Headline24} from 'containers/login/TO_GO_INTO_DS/FsType/Headlline24';
import React from 'react';
import {useHistory} from 'react-router-dom';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100vw;
  justify-content: center;
  align-items: center;
`;

export const PleaseContinueOnMobile = () => {
  const history = useHistory();

  return (
    <Container>
      <Headline24 style={{textAlign: 'center'}}>Please continue on a laptop or computer</Headline24>
      <BodyText16>Our MRV programs do not currently support mobile devices.</BodyText16>
      <Button hierachy="secondary" onClick={() => history.push('/mrv/project/select')}>
        BACK TO PROGRAMS
      </Button>
    </Container>
  );
};
