import type {Props, Messages} from '../types';
import InfoBlock from 'components/info-block';
import Ln from 'components/ln';
import React from 'react';
import {GrassImageIcon, WheatImageIcon} from 'containers/map/icons';
import {Footer} from '../helpers';

import {
  ZoningIcon,
  FarmIcon,
  GrassIcon,
  RemoteSensingIcon,
  LeafIcon,
  FrostIcon,
  MachineryIcon,
  GreenBoldText,
  BarleyIcon,
} from '../icons.styled';

const data: Messages = {
  // ------ 1_Cereal crops demo
  1430: {
    title: 'Frost damage',
    width: 425,
    element: (props: Props) => (
      <div className="status-alert-dialog">
        <div className="status-alert-dialog__main">
          <div>
            <b>Highlighted</b> in this <span>wheat field</span> is <FrostIcon /> frost damage. An
            agronomist or grower can use this to decide how much crop should be <b>cut for hay</b>.
            It can also be used to <b>report to your insurance</b> the size of the damaged area.
            <br />
            You can use the compare slider in <RemoteSensingIcon /> Data Layers tab to compare the
            area of frost detected on 07 Sept NDRE layer to the <b>yield map</b> (28 Nov).
          </div>
        </div>

        <InfoBlock appearance="info">
          <i>
            Frost damage is detected using the Normalised Difference Red Edge (NDRE) vegetation
            index. Interested in frost detection?{' '}
            <Ln
              external
              blank
              href="https://www.regrow.ag/post/understanding-frost-impact-through-remote-sensing-and-analytics"
            >
              Read more here.
            </Ln>
          </i>
        </InfoBlock>

        <Footer {...props} />
      </div>
    ),
  },

  147770: {
    title: 'Site-specific nitrogen recommendations',
    element: (props: Props) => (
      <div className="status-alert-dialog">
        <div className="status-alert-dialog__main">
          <div>
            <LeafIcon /> Site-specific nitrogen recommendations can be turned on in the{' '}
            <ZoningIcon /> Zone management tab. The model uses individual field’s history and
            in-season satellite imagery to tailor the recommendations.
            <br />
            <br />
            Try changing the <b>date of the N recommendation</b> to see how it follows the
            development of the crop. Also try clicking <b>Edit recommendation settings</b> button
            and adjust the economic and climatic parameters.
          </div>
        </div>

        <InfoBlock appearance="info">
          <i>
            Want to know more about nitrogen recommendation?{' '}
            <Ln external blank href="https://www.regrow.ag/post/optimise-nitrogen-top-dressing">
              Read the case study here.
            </Ln>
          </i>
        </InfoBlock>

        <Footer {...props} />
      </div>
    ),
  },

  133463: {
    title: 'Weeds caused yield damage',
    width: 550,
    element: (props: Props) => (
      <div className="status-alert-dialog">
        <div className="status-alert-dialog__main">
          <div style={{paddingRight: 30}}>
            <GrassImageIcon />
          </div>

          <div>
            {/*On the map you will see an image of a <b>fallow</b> field with some <b>weeds</b>{' '}*/}
            {/*detected in it.*/}
            {/*<br />*/}
            {/*<br />*/}
            {/*Compare these areas of weeds to the loss in yield:*/}
            {/*<br />*/}
            {/*<GreenBoldText>1.</GreenBoldText> On the Farm set up tab <FarmIcon /> switch to the{' '}*/}
            {/*<b>barley season</b> <BarleyIcon />,<br />*/}
            {/*<GreenBoldText>2.</GreenBoldText> Navigate to the Data Layers tab <RemoteSensingIcon />,*/}
            {/*<br />*/}
            {/*<GreenBoldText>3.</GreenBoldText> Find the <MachineryIcon /> Yield map on 10 Sep 2017.*/}
            On the map you will see an image of a <b>fallow</b> field with some <b>weeds</b>{' '}
            detected in it.
            <br />
            <br />
            Compare these areas of weeds to the loss in yield:
            <br />
            <GreenBoldText>1.</GreenBoldText> Navigate to to the <b>Farm set up tab</b> <FarmIcon />{' '}
            and switch from a Fallow to the <b>Barley season</b> <BarleyIcon />,<br />
            <GreenBoldText>2.</GreenBoldText> Now switch to the <b>Data Layers tab</b>{' '}
            <RemoteSensingIcon />, and
            <br />
            <GreenBoldText>3.</GreenBoldText> Find the <MachineryIcon /> <b>Yield map</b> on 10 Sep
            2017.
          </div>
        </div>

        <InfoBlock appearance="info">
          <i>
            Note that the yield map shows losses in the areas where the weeds were detected. Want to
            know more?{' '}
            <Ln
              external
              blank
              href="https://www.regrow.ag/post/roi-case-study-fallow-weeds-eradication-and-impact-on-the-yield"
            >
              Read the case study.
            </Ln>
          </i>
        </InfoBlock>

        <Footer {...props} />
      </div>
    ),
  },

  1434: {
    title: 'Wild rye infestation',
    element: (props: Props) => (
      <div className="status-alert-dialog">
        <div className="status-alert-dialog__main">
          <div style={{paddingRight: 30}}>
            <WheatImageIcon />
          </div>
          <div>
            This <span>barley field</span> has many features, including trees and native vegetation
            that were left on it. However, what caught our attention were <b>weeds</b>!{' '}
            <GrassIcon />
            <br />
            <br />
            <b>Click on the highlighted shapes</b> on the map and explore popups to see how we tell
            apart crop stress and the permanent features of the field.
          </div>
        </div>

        <Footer {...props} />
      </div>
    ),
  },
};

export default data;
