import {Flex, Text} from 'components';
import {Icon} from '@regrow-internal/design-system';
import {t} from 'i18n-utils';
import {sanitize} from 'dompurify';
import React from 'react';
import styled, {useTheme} from 'styled-components';
import type {MRVProgramNormalized} from 'containers/mrv/types';

type Props = {
  program: Pick<MRVProgramNormalized, 'announcement_title' | 'announcement_description'>;
};

export const ProgramAnnouncement = ({program}: Props) => {
  const theme = useTheme();
  return (
    <ProgramAnnouncementContainer>
      <BadgeContainer>
        <Icon color={theme.color.stroke.success} type="campaign" />
        {t({id: 'Announcement'})}
      </BadgeContainer>
      <Text variant="medium" fontWeight={500}>
        {program.announcement_title}
      </Text>
      <Description
        dangerouslySetInnerHTML={{
          __html: sanitize(program.announcement_description || ''),
        }}
      />
    </ProgramAnnouncementContainer>
  );
};

const ProgramAnnouncementContainer = styled.div`
  width: 250px;
  text-align: left;
  padding: 20px 15px;
  background-color: ${({theme}) => theme.color.surface.main};
  border-radius: ${({theme}) => theme.borderRadius.medium};
`;

const BadgeContainer = styled(Flex)`
  align-items: center;
  gap: 4px;
  width: fit-content;
  color: ${({theme}) => theme.color.text.success};
  background-color: ${({theme}) => theme.color.surface.success};
  padding: 5px 10px;
  border-radius: ${({theme}) => theme.borderRadius.medium};
  margin-bottom: 1em;
`;

const Description = styled.div`
  p {
    font-size: 13px; // override the font size change caused by media query
  }
`;
