// @ts-nocheck
import {InfoBlock, Text} from 'components';
import {CardFullWidth} from 'components/card/card-styled';
import {FluroChipDropdownControllable as FluroChipDropdown} from 'components/fluro-dropdown/fluro-chip-dropdown';
import {IndeterminateCheckboxIcon, UncheckedCheckboxIcon} from 'components/fluro-icons';
import type {
  OptionSelectedEvent,
  Props as OptionsGroupsProps,
} from 'components/grouped-selection-control/options-group';
import {
  selectActiveGeometries,
  setActiveGeometriesFilter,
  setAggLevel,
  setStateIdsRequestParam,
  setSupplyShedsRequestParam,
} from 'modules/sustainability-insights/actions';
import {
  selectActiveGeometriesIds,
  selectActiveStatesMeta,
  selectAggLevel,
  selectAreEveryGeometrySelected,
  selectAvailableAggLevels,
  selectAvailableCountiesIds,
  selectAvailableCRDIds,
  selectAvailableHuc10Ids,
  selectAvailableHuc12Ids,
  selectAvailableHuc8Ids,
  selectAvailableStatesIds,
  selectCountiesOptions,
  selectCRDOptions,
  selectHuc10Options,
  selectHuc12Options,
  selectHuc8Options,
  selectIsSIDataLoading,
  selectStateIdsRequestParam,
  selectStatesMeta,
  selectStatesOptions,
  selectSupplyShedsOptions,
} from 'modules/sustainability-insights/selectors';
import type {AreaId, SIAggLevel, SIStateMeta} from 'modules/sustainability-insights/types';
import {aggLevelLabel} from 'modules/sustainability-insights/types';
import {parseAreaId} from 'modules/sustainability-insights/utils';
import type {ComponentType} from 'react';
import React, {useEffect, useMemo, useState} from 'react';
import {SelectionControl} from 'react-md';
import {batch} from 'react-redux';
import type {Option} from 'types';
import {useAppDispatch, useAppSelector} from '_hooks';
import {AreaSelect} from '../area-select/area-select';
import {AreaSelectMenu} from '../area-select/area-select-menu';
import {isNonEmptyArray} from '_utils/typeGuards';

const StatesSelect: ComponentType<{}> = () => {
  const dispatch = useAppDispatch();
  const options = useAppSelector(selectStatesOptions);
  const selectedIds = useAppSelector(selectActiveGeometriesIds);
  const availableStatesIds = useAppSelector(selectAvailableStatesIds);
  const allSelected =
    isNonEmptyArray(availableStatesIds) &&
    isNonEmptyArray(selectedIds) &&
    availableStatesIds.length === selectedIds.length;

  const selectedMap = useMemo(() => {
    return selectedIds.reduce<Record<number | string, boolean>>(
      (acc, id) => ({
        ...acc,
        [id]: true,
      }),
      {}
    );
  }, [selectedIds]);

  const handleSelect: OptionsGroupsProps['onChange'] = selectedChange => {
    // Select all clicked
    if (typeof selectedChange === 'boolean') {
      const ids = selectedChange ? availableStatesIds : [];
      batch(() => {
        dispatch(setActiveGeometriesFilter(ids, {resetVisible: true}));
        dispatch(setStateIdsRequestParam(ids));
      });
      return;
    }

    const {selected, value} = selectedChange;
    const id = parseInt(value, 10);
    batch(() => {
      dispatch(selectActiveGeometries({ids: [id], selected, resetVisible: true}));
      dispatch(setStateIdsRequestParam([id], selected));
    });
  };

  const handleClear = () => {
    batch(() => {
      dispatch(setActiveGeometriesFilter([]));
      dispatch(setStateIdsRequestParam([]));
    });
  };

  return (
    <AreaSelectMenu
      className="area-select-menu"
      allowSelectAll
      allLabel="Select all states"
      options={options}
      selected={selectedIds.map(String)}
      selectedLabel="states"
      selectedMap={selectedMap}
      allSelected={allSelected}
      onSelect={handleSelect}
      onClear={handleClear}
      active={!!selectedIds.length}
    />
  );
};

const StateChildAreaSelect: ComponentType<{
  availableIds: AreaId[];
  options: Option[];
  selectedLabel?: string;
}> = ({availableIds, options, selectedLabel}) => {
  const dispatch = useAppDispatch();
  const selectedIds = useAppSelector(selectActiveGeometriesIds);
  const allSelected = useAppSelector(selectAreEveryGeometrySelected);
  const availableStatesIds = useAppSelector(selectAvailableStatesIds);
  const isLoading = useAppSelector(selectIsSIDataLoading);

  const handleSelect: OptionsGroupsProps['onChange'] = selectedChange => {
    // Select all clicked
    if (typeof selectedChange === 'boolean') {
      batch(() => {
        dispatch(setStateIdsRequestParam(selectedChange ? availableStatesIds : []));
        dispatch(
          setActiveGeometriesFilter(selectedChange ? availableIds : [], {resetVisible: true})
        );
      });
      return;
    }

    const {selected, values, value, type} = selectedChange;
    const stateId = parseAreaId(value);
    const ids = (values ?? []).map(parseAreaId);
    dispatch(selectActiveGeometries({ids, stateId, selected, resetVisible: true}));

    if (type === 'group') {
      dispatch(setStateIdsRequestParam([stateId], selected));
    } else if (type === 'option') {
      const {groupValue, selectedOptionsLength} = selectedChange as OptionSelectedEvent;
      const stateId = parseAreaId(groupValue);

      if (
        (selected && !selectedOptionsLength) ||
        (!selected && selectedOptionsLength === values?.length)
      ) {
        dispatch(setStateIdsRequestParam([stateId], selected));
      }
    }
  };

  const handleClear = () => {
    batch(() => {
      dispatch(setStateIdsRequestParam([]));
      dispatch(setActiveGeometriesFilter([]));
    });
  };

  return (
    <AreaSelectMenu
      className="area-select-menu"
      allowSelectAll
      allowSearch
      allLabel={`Select all ${selectedLabel}`}
      selectedLabel={selectedLabel}
      options={options}
      selected={selectedIds.map(String)}
      disabled={isLoading}
      allSelected={allSelected}
      onSelect={handleSelect}
      onClear={handleClear}
      active={!!selectedIds.length}
    />
  );
};

const StateChildAreaRadio: ComponentType<{
  id: string;
  aggLevel: 'huc10' | 'huc12';
  availableIds: AreaId[];
  options: Option[];
  selectedLabel?: string;
}> = ({id, aggLevel, options}) => {
  const [expand, setExpand] = useState(false);
  const dispatch = useAppDispatch();
  const [value] = useAppSelector(selectStateIdsRequestParam);
  const isLoading = useAppSelector(selectIsSIDataLoading);
  const statesMeta = useAppSelector(selectStatesMeta);
  const selectedIds = useAppSelector(selectActiveGeometriesIds);
  const activeIds = statesMeta[value]?.[aggLevel] || [];
  const isIndeterminate = selectedIds.length !== activeIds.length;

  const handleChange = (checked: boolean, event: Event) => {
    if (!checked) return;
    const {value} = event.target as HTMLInputElement;
    const stateId = parseAreaId(value);
    const nextActiveIds = statesMeta[stateId][aggLevel];

    batch(() => {
      dispatch(setActiveGeometriesFilter(nextActiveIds, {resetVisible: true}));
      dispatch(setStateIdsRequestParam([stateId]));
    });
  };

  const shouldRenderOptions = (expand || !value) && !isLoading;

  return (
    <FluroChipDropdown
      expand={!isLoading && (expand || !value)}
      setExpand={setExpand}
      label={value ? options.find(o => o.value === value)?.label : 'Select state'}
      active={!!value}
      disabled={isLoading}
      dropdownStyle={{minWidth: '250px'}}
    >
      {shouldRenderOptions &&
        options.map(option => (
          <SelectionControl
            key={option.value}
            uncheckedCheckboxIcon={
              isIndeterminate && value === option.value ? (
                <IndeterminateCheckboxIcon />
              ) : (
                <UncheckedCheckboxIcon />
              )
            }
            checked={!isIndeterminate && value === option.value}
            type="checkbox"
            name={aggLevel}
            id={`${id}-${option.value}`}
            label={option.label}
            value={option.value}
            onChange={handleChange}
          />
        ))}
    </FluroChipDropdown>
  );
};

const CRDSelect: ComponentType<{}> = () => {
  const options = useAppSelector(selectCRDOptions);
  const availableIds = useAppSelector(selectAvailableCRDIds);

  return <StateChildAreaSelect options={options} availableIds={availableIds} selectedLabel="CRD" />;
};

const CountiesSelect: ComponentType<{}> = () => {
  const options = useAppSelector(selectCountiesOptions);
  const availableCountiesIds = useAppSelector(selectAvailableCountiesIds);

  return (
    <StateChildAreaSelect
      options={options}
      availableIds={availableCountiesIds}
      selectedLabel="counties"
    />
  );
};

const HUC8Select: ComponentType<{}> = () => {
  const options = useAppSelector(selectHuc8Options);
  const huc8Ids = useAppSelector(selectAvailableHuc8Ids);

  return <StateChildAreaSelect options={options} availableIds={huc8Ids} selectedLabel="HUC8" />;
};

const HUC10Select: ComponentType<{}> = () => {
  const options = useAppSelector(selectHuc10Options);
  const huc10Ids = useAppSelector(selectAvailableHuc10Ids);

  return (
    <StateChildAreaRadio
      id="huc10-select-group"
      aggLevel="huc10"
      options={options}
      availableIds={huc10Ids}
      selectedLabel="HUC10"
    />
  );
};

const HUC12Select: ComponentType<{}> = () => {
  const options = useAppSelector(selectHuc12Options);
  const huc12Ids = useAppSelector(selectAvailableHuc12Ids);

  return (
    <StateChildAreaRadio
      id="huc12-select-group"
      aggLevel="huc12"
      options={options}
      availableIds={huc12Ids}
      selectedLabel="HUC12"
    />
  );
};

const HucsMessage: ComponentType<{}> = () => {
  const aggLevel = useAppSelector(selectAggLevel);
  const selectedIds = useAppSelector(selectActiveGeometriesIds);
  const isLoading = useAppSelector(selectIsSIDataLoading);
  const isHUCsAggLevel = aggLevel === 'huc10' || aggLevel === 'huc12';
  const show = isHUCsAggLevel && !isLoading && !selectedIds?.length;

  if (!show) return null;

  return (
    <CardFullWidth>
      <InfoBlock color="info" className="mb-1" appearance="info">
        Please select a state to explore {aggLevelLabel[aggLevel]} areas.
      </InfoBlock>
    </CardFullWidth>
  );
};

const SupplyShedSelect: ComponentType<{}> = () => {
  const dispatch = useAppDispatch();
  const options = useAppSelector(selectSupplyShedsOptions);
  const selectedIds = useAppSelector(selectActiveGeometriesIds);
  const allSelected =
    isNonEmptyArray(options) &&
    isNonEmptyArray(selectedIds) &&
    options.length === selectedIds.length;

  useEffect(() => {
    if (selectedIds.length === 0 && options.length) {
      // select all by default
      const ids = options.map(({value}) => value);
      dispatch(setActiveGeometriesFilter(ids, {resetVisible: true}));
      dispatch(setSupplyShedsRequestParam(ids));
    }
  }, []);

  const selectedMap = useMemo(() => {
    return selectedIds.reduce<Record<number | string, boolean>>(
      (acc, id) => ({
        ...acc,
        [id]: true,
      }),
      {}
    );
  }, [selectedIds]);

  const handleSelect: OptionsGroupsProps['onChange'] = selectedChange => {
    // Select all clicked
    if (typeof selectedChange === 'boolean') {
      const ids = selectedChange ? options.map(({value}) => value) : [];
      batch(() => {
        dispatch(setActiveGeometriesFilter(ids, {resetVisible: true}));
        dispatch(setSupplyShedsRequestParam(ids));
        // dispatch(setStateIdsRequestParam([], false));
      });
      return;
    }

    const {selected, value} = selectedChange;
    batch(() => {
      dispatch(selectActiveGeometries({ids: [value], selected, resetVisible: true}));
      dispatch(setSupplyShedsRequestParam([parseInt(value, 10)], selected));
    });
  };

  const handleClear = () => {
    batch(() => {
      dispatch(setActiveGeometriesFilter([]));
      dispatch(setSupplyShedsRequestParam([]));
    });
  };

  return (
    <AreaSelectMenu
      className="area-select-menu"
      allowSelectAll
      allLabel="Select all supply sheds"
      options={options}
      selected={selectedIds.map(String)}
      selectedLabel="supply sheds"
      selectedMap={selectedMap}
      allSelected={allSelected}
      onSelect={handleSelect}
      onClear={handleClear}
    />
  );
};

export const SIAreaSelect: ComponentType<{}> = () => {
  const dispatch = useAppDispatch();
  const aggLevel = useAppSelector(selectAggLevel);
  const availableAggLevels = useAppSelector(selectAvailableAggLevels);

  const activeStatesIds = useAppSelector(selectStateIdsRequestParam);
  const activeStatesMeta: SIStateMeta[] = useAppSelector(selectActiveStatesMeta);

  const options = availableAggLevels.map(value => ({
    label: aggLevelLabel[value],
    value,
  }));

  const handleAreaTypeChange = (aggLevel: SIAggLevel) => {
    let nextAreaIds: AreaId[] = [];

    if (aggLevel === 'state') {
      nextAreaIds = activeStatesIds;
    } else if (activeStatesIds.length === 1 || (aggLevel !== 'huc10' && aggLevel !== 'huc12')) {
      nextAreaIds = activeStatesMeta.reduce(
        // @ts-expect-error error leftover from convertion to strict mode, please fix
        (acc, meta) => acc.concat(meta[aggLevel] || []),
        []
      );
    }

    dispatch(setAggLevel(aggLevel as SIAggLevel, nextAreaIds));
  };

  return (
    <>
      <Text>Area Selection</Text>

      <HucsMessage />

      <AreaSelect
        selected={{value: aggLevel, label: aggLevelLabel[aggLevel]}}
        options={options}
        onChange={handleAreaTypeChange}
      >
        {aggLevel === 'supply_shed' && <SupplyShedSelect />}
        {aggLevel === 'state' && <StatesSelect />}
        {aggLevel === 'crd' && <CRDSelect />}
        {aggLevel === 'county' && <CountiesSelect />}
        {aggLevel === 'huc8' && <HUC8Select />}
        {aggLevel === 'huc10' && <HUC10Select />}
        {aggLevel === 'huc12' && <HUC12Select />}
      </AreaSelect>
    </>
  );
};
