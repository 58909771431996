import styled from 'styled-components';

export const MRVSelectContainer = styled.div`
  width: 100%;
  flex: 1;

  .input-crop-select {
    min-width: 140px;
  }

  > div {
    width: 100%;

    .fluro-select-lite-item {
      width: auto;
      .item-title {
        flex-wrap: nowrap !important;
      }
    }

    .selected-item .fluro-select-lite-item {
      padding: 8px 0;
    }

    .list {
      .fluro-select-lite-item {
        min-width: 200px;
      }
    }
  }
`;
