import {selectIsAdmin} from 'containers/login/login-selectors';
import {range} from 'lodash';
import debounce from 'lodash/debounce';
import intersection from 'lodash/intersection';
import difference from 'lodash/difference';
import omitBy from 'lodash/omitBy';
import {
  selectAggLevel,
  selectAreasIdsRequestParam,
  selectCurrentGeometriesIdsRequestParam,
  selectCurrentMetricsColumns,
  selectGeometryTypeFilter,
  selectMetricsFilters,
  selectYearsFilter,
} from 'modules/sustainability-insights/selectors';
import {
  fetchAvailableArea,
  fetchGeometries,
  fetchMetrics,
  getUserPolicy,
} from 'modules/sustainability-insights/thunks';
import {SIGeometryFilterType} from 'modules/sustainability-insights/types';
import type {
  AreaId,
  SIGeometriesRequestParams,
  SIMetricsRequestParams,
  StateFP,
} from 'modules/sustainability-insights/types';
import {useEffect, useMemo, useRef, useState} from 'react';
import {useDispatch} from 'react-redux';
import {useAppDispatch, useAppSelector} from '_hooks';

export const useSIMetrics = () => {
  const dispatch = useAppDispatch();

  const [fetchedFiltersString, setFetchedFiltersString] = useState<string>('');
  const [fetchedStatesIds, setFetchedStatesIds] = useState<AreaId[]>([]);
  const [fetchedSupplySheds, setFetchedSupplySheds] = useState<AreaId[]>([]);
  // FIXME: cache years
  // const [fetchedYears, setFetchedYears] = useState<number[]>([]);

  const activeGeometryType = useAppSelector(selectGeometryTypeFilter);
  const aggLevel = useAppSelector(selectAggLevel);
  const geometriesAreaIds: Array<StateFP | string> = useAppSelector(
    selectCurrentGeometriesIdsRequestParam
  );
  const metricsCols = useAppSelector(selectCurrentMetricsColumns);
  const metricsFilters = useAppSelector(selectMetricsFilters);
  const areasIdsRequestParam = useAppSelector(selectAreasIdsRequestParam);
  const years = useAppSelector(selectYearsFilter);

  const filtersString = useMemo(
    () =>
      [...Object.values(metricsFilters), aggLevel, activeGeometryType, metricsCols, years].join(),
    [activeGeometryType, aggLevel, metricsCols, metricsFilters, years]
  );

  useEffect(() => {
    if (filtersString !== fetchedFiltersString) {
      setFetchedStatesIds([]);
      setFetchedSupplySheds([]);
      // setFetchedYears([]);
    }
  }, [filtersString]);

  const areasIdsToFetch = useMemo(() => {
    const fetchedIds = aggLevel === 'supply_shed' ? fetchedSupplySheds : fetchedStatesIds;
    if (
      !fetchedIds.length ||
      !areasIdsRequestParam?.length ||
      filtersString !== fetchedFiltersString ||
      aggLevel === 'huc10' ||
      aggLevel === 'huc12'
    ) {
      return areasIdsRequestParam;
    }

    const intersected =
      aggLevel === 'supply_shed'
        ? intersection(areasIdsRequestParam.map(Number), fetchedSupplySheds)
        : intersection(areasIdsRequestParam.map(Number), fetchedStatesIds);

    if (activeGeometryType === SIGeometryFilterType.CoverCrops)
      // fetch all for CC card, to have correct summary calculations data FSB-11497
      return aggLevel === 'supply_shed' ? areasIdsRequestParam : areasIdsRequestParam;

    if (intersected.length === areasIdsRequestParam?.length) return [];

    return aggLevel === 'supply_shed'
      ? difference(areasIdsRequestParam.map(Number), fetchedSupplySheds)
      : difference(areasIdsRequestParam.map(Number), fetchedStatesIds);
  }, [
    aggLevel,
    filtersString,
    areasIdsRequestParam,
    fetchedSupplySheds,
    fetchedStatesIds,
    activeGeometryType,
  ]);

  const fetchMetricsDebounced = useRef(
    debounce(
      (requestQueryParams: SIMetricsRequestParams) => dispatch(fetchMetrics(requestQueryParams)),
      1000
    )
  );
  const fetchGeometriesDebounced = useRef(
    debounce((params: SIGeometriesRequestParams) => dispatch(fetchGeometries(params)), 1000)
  );

  useEffect(() => {
    if (
      !activeGeometryType ||
      !metricsCols ||
      !Object.keys(metricsCols).length ||
      !areasIdsToFetch?.length ||
      !years?.length
    ) {
      return;
    }

    const {operationStatus, operationSize, summerCropTypes} = metricsFilters;

    // We need the spread of years between two compared to have
    // data to render the Trend chart correctly
    const [yearFrom, yearTo] = years;
    const yearsRangeParam = yearFrom && yearTo ? range(yearFrom, yearTo + 1) : [yearFrom];

    if (aggLevel === 'supply_shed') {
      setFetchedSupplySheds(f => [...new Set(f.concat(areasIdsToFetch.map(Number)))]);
    } else {
      setFetchedStatesIds(f => [...new Set(f.concat(areasIdsToFetch.map(Number)))]);
    }
    // setFetchedYears(yearsRangeParam);
    setFetchedFiltersString(filtersString);

    const params = {
      // Parameters
      agg_level: aggLevel,
      metrics: metricsCols.join(),
      [aggLevel === 'supply_shed' ? 'supply_sheds' : 'states']: areasIdsToFetch.join(),
      years: yearsRangeParam.join(),
      // Filters
      operation_size: operationSize,
      operation_status: operationStatus,
      summer_crop_type: summerCropTypes,
    };
    const queryParams = omitBy(
      params,
      v => typeof v === undefined || v === null || v === ''
    ) as unknown as SIMetricsRequestParams;

    fetchMetricsDebounced.current(queryParams);
  }, [
    activeGeometryType,
    aggLevel,
    metricsCols,
    filtersString,
    areasIdsToFetch,
    years,
    metricsFilters,
  ]);

  useEffect(() => {
    if (!geometriesAreaIds.length) return;
    fetchGeometriesDebounced.current({
      agg_level: aggLevel,
      [aggLevel === 'supply_shed' ? 'supply_sheds' : 'states']: geometriesAreaIds.join(),
    });
  }, [aggLevel, geometriesAreaIds]);
};

export const useSIPolicy = () => {
  const isAdmin = useAppSelector(selectIsAdmin);
  const dispatch = useDispatch();

  const requestAvailableAreas = async () => {
    if (isAdmin) {
      dispatch(fetchAvailableArea());
    } else {
      dispatch(getUserPolicy());
    }
  };

  useEffect(() => {
    requestAvailableAreas();
    // the policies request should be sent once on mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
