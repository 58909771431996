import {isDefined} from '_utils/typeGuards';
import React from 'react';
import {useLocation} from 'react-router-dom';

/**
 * Scrolls page content to top of component ref on location change
 * @param ref The component you should scroll to the top of on page change
 */
export const useScrollToTop = (ref: React.RefObject<HTMLElement>) => {
  const location = useLocation();

  React.useLayoutEffect(() => {
    if (isDefined(ref.current) && ref.current.scrollTop !== 0) {
      ref.current.scrollTo(0, 0);
    }
  }, [location, ref]);
};
