import {combineReducers} from 'redux';
import type {SIState} from '../types';
import {dataReducer} from './data';
import {filterReducer} from './filter';

const sustainabilityInsightsReducer = combineReducers<SIState>({
  filter: filterReducer,
  data: dataReducer,
});

export default sustainabilityInsightsReducer;
