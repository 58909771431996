import React from 'react';
import {Text} from 'components';
import {CardToolTip} from 'containers/map/features/sustainability-insights/filters-panel/cards/components/card-tooltip';

export const EmissionFactorCardTooltip = () => (
  <CardToolTip
    id="emission-factor-card-tooltip"
    content={
      <>
        The emission factor is shown in collaboration with Quantis as the{' '}
        <Text variant="small" bold>
          Book value
        </Text>{' '}
        number is the number from the World Food LCA Database with no adjustments (per country when
        available, otherwise global).{' '}
        <Text variant="small" bold>
          Regrow baseline
        </Text>{' '}
        replaces the{' '}
        <Text variant="small" bold>
          Field emissions
        </Text>{' '}
        and{' '}
        <Text variant="small" bold>
          Fertilizer
        </Text>{' '}
        components with Regrow calculated values specific to the supply shed.{' '}
        <Text variant="small" bold>
          With Interventions
        </Text>{' '}
        incorporates the simulated practice change outcomes. For clarity, any component in the black
        to grey spectrum is NOT updated by Regrow. This chart is sorted according to the{' '}
        <Text variant="small" bold>
          Rank by
        </Text>{' '}
        filter at the top of the report.
      </>
    }
    place="left"
  />
);
