// @ts-nocheck
import React, {useEffect} from 'react';
import {
  setSensor,
  setSchema,
  toggleAllFieldsCheckboxes,
  setFeature,
  setTreeLayerType,
  bulkSelectFieldsSeasons,
} from '../actions';
import type {Field, Season, TreesTypes} from '../types';
import {bulkSetGetParamToURL, getGetURLParam, setGetParamToURL} from '_utils/pure-utils';
import {formatDate} from '_utils';
import moment from 'moment';
import {pointsSetGroupDate} from '../actions/sampling-points';
import {useAppDispatch, useAppSelector, usePrevious} from '_hooks';
import EffectTemperatureData from './temperature-data';
import AnalyticEffects from './analytic';
import ZoningEffects from './zoning-effects';
import NotificationEffects from './notification';
import CropPerformanceEffects from './crop-performance-effects';
import {useUpdateFilter} from './use-update-filters';
import {calculateLayerType} from '../utils/trees';
import {Route} from 'react-router-dom';
import {LoadFields} from './load-fields';
import {AnomaliesEffects} from './anomalies-effects';
import {GetParamEffects} from './get-param-effects';
import {GLOBAL_APP_DATE_FORMAT} from '_constants';
import {
  selectColorSchema,
  selectCurrentDate,
  selectCurrentDates,
  selectCurrentField,
  selectCurrentPointsGroups,
  selectPointsCurrentGroupDate,
  selectCurrentSeasonId,
  selectCurrentSensor,
  selectCurrentTab,
  selectMapFields,
  selectWholeFarmData,
  selectCurrentFarmId,
  selectTreeDetectionLayerType,
} from '../reducer/selectors';
import {selectFarmsList} from 'modules/farms/selectors';

/**
 * The component doesn't have any rendering, it exists just for the sake of running effects.
 */
const Index = () => {
  const dispatch = useAppDispatch();

  const field = useAppSelector(selectCurrentField);
  const fields = useAppSelector(selectMapFields);
  const currentSeasonId = useAppSelector(selectCurrentSeasonId);
  const currentSensor = useAppSelector(selectCurrentSensor);
  const currentDate = useAppSelector(selectCurrentDate);
  const currentDates = useAppSelector(selectCurrentDates);
  const feature = useAppSelector(selectCurrentTab);
  const pointsGroups = useAppSelector(selectCurrentPointsGroups);
  const colorSchema = useAppSelector(selectColorSchema);
  const pointsCurrentGroupDate = useAppSelector(selectPointsCurrentGroupDate);
  const wholeFarm = useAppSelector(selectWholeFarmData);
  const currentFarmId = useAppSelector(selectCurrentFarmId);
  const farmsList = useAppSelector(selectFarmsList);
  const treeDetectionLayerType = useAppSelector(selectTreeDetectionLayerType);

  const prev = usePrevious({
    date: currentDate,
    sensor: currentSensor,
    pointsCurrentGroupDate,
    wholeFarm,
    currentFarmId,
    treeDetectionLayerType,
    farmsList,
  });

  useUpdateFilter();

  useEffect(() => {
    const {generatedNMapDates = {}} = field;
    const formattedPointsDate = moment(pointsCurrentGroupDate, formatDate()).format('DD/MM/YYYY');

    if (currentSensor === 'NMAP' && (currentDate !== prev.date || currentSensor !== prev.sensor)) {
      colorSchema !== 'singleDate' && dispatch(setSchema('singleDate')); // set color schema to singleDate

      const currentDateNmapPointsGroup = Object.keys(generatedNMapDates)
        .filter(k => generatedNMapDates[k] === currentDate) // get point group dates where NMAP was generated
        .map(d => moment(d, 'DD/MM/YYYY').format(formatDate()));

      const dateToSet = currentDateNmapPointsGroup.find(
        // get a points date on which NMAP was generated for the current date
        d => d !== pointsCurrentGroupDate && pointsGroups[d]
      );
      // if the NMAP is not generated using current points date, switch to appropriate one
      if (!currentDateNmapPointsGroup.includes(pointsCurrentGroupDate) && dateToSet) {
        dispatch(pointsSetGroupDate(dateToSet));
      }
    }
    if (
      //  disable NMAP if current points group doesn't have generated NMAP
      currentSensor === 'NMAP' &&
      pointsCurrentGroupDate !== prev.pointsCurrentGroupDate &&
      generatedNMapDates[formattedPointsDate] !== currentDate
    ) {
      dispatch(setSensor('NDVI'));
    }
  }, [pointsCurrentGroupDate, currentDate, currentSensor]);

  useEffect(
    function handleWholeFarmToggle() {
      if (prev && prev.wholeFarm.isWholeFarmView && !wholeFarm.isWholeFarmView) {
        // unselect all fields if whole farm is not enabled anymore
        dispatch(toggleAllFieldsCheckboxes(false));
      }
    },
    [wholeFarm.isWholeFarmView]
  );

  useEffect(
    function clearURLParams() {
      if (prev?.currentFarmId) {
        bulkSetGetParamToURL({
          'nrx-toggle': null,
          'nrx-date': null,
          message: null,
          treeDetectionLayerType: null,
        });
      }
    },
    [currentFarmId]
  );

  useEffect(
    function unsureTabUrlParamsIsSet() {
      if (!prev?.currentFarmId && !getGetURLParam('tab')) {
        dispatch(setFeature(feature));
      }
    },
    [currentFarmId]
  );

  useEffect(
    function setTreeDetectionLayerTypeURL() {
      const treeLayerTypeURLValue = getGetURLParam('treeDetectionLayerType');
      if (
        prev?.treeDetectionLayerType &&
        prev?.treeDetectionLayerType !== 'default' &&
        treeDetectionLayerType === 'default'
      ) {
        return setGetParamToURL('treeDetectionLayerType', null); // clear layerType param if the new date doesn't have tree data
      }

      if (
        treeDetectionLayerType !== 'default' &&
        treeDetectionLayerType !== treeLayerTypeURLValue
      ) {
        setGetParamToURL('treeDetectionLayerType', treeDetectionLayerType);
      }
    },
    [currentDate, treeDetectionLayerType]
  );

  useEffect(
    function clearTreeDetectionLayerTypeURL() {
      const treeLayerTypeURLValue = getGetURLParam('treeDetectionLayerType');
      if (!currentSeasonId || !treeLayerTypeURLValue || !currentDates[currentDate]) return;

      if (treeLayerTypeURLValue !== 'default' && !currentDates[currentDate]?.treeData) {
        setGetParamToURL('treeDetectionLayerType', null); // clear layerType param if the date doesn't have tree data
      }
    },
    [currentDate, currentDates, currentSeasonId]
  );

  useEffect(
    function calculateTreeDetectionLayer() {
      const treeLayerTypeValue =
        (getGetURLParam('treeDetectionLayerType') as TreesTypes) || treeDetectionLayerType;
      const layerType = calculateLayerType(currentDate, treeLayerTypeValue, currentSensor);
      if (layerType !== treeDetectionLayerType) {
        dispatch(setTreeLayerType(layerType));
      }
    },
    [wholeFarm.fieldsWithDates]
  );

  useEffect(
    function setCorrectSeasonAccordinglyToTheDate() {
      if (wholeFarm.isWholeFarmView) {
        // set fields season to match date is available FSB-1682
        const seasonsToUpdate: Season[] = [];
        const mDateToSet = moment(currentDate, GLOBAL_APP_DATE_FORMAT);
        fields.forEach((f: Field) => {
          if (f.Seasons && f.Seasons.length > 1) {
            let seasonToSet: any = null;

            f.Seasons.forEach((s: Season) => {
              if (f.SeasonID === s.id) return; // if current season
              if (mDateToSet.isSameOrAfter(s.startDate) && mDateToSet.isSameOrBefore(s.endDate)) {
                seasonToSet = s;
              }
            });

            if (seasonToSet) {
              seasonsToUpdate.push(seasonToSet);
            }
          }
        });
        if (seasonsToUpdate.length) {
          dispatch(bulkSelectFieldsSeasons(seasonsToUpdate));
        }
      }
    },
    [currentDate, wholeFarm.isWholeFarmView, wholeFarm.fieldsWithDates]
  );

  return (
    <>
      <Route path={`/:view/:farmId?`} component={LoadFields} />
      <EffectTemperatureData />
      <AnomaliesEffects />
      <AnalyticEffects />
      <NotificationEffects />
      <CropPerformanceEffects />
      <ZoningEffects />
      <GetParamEffects />
    </>
  );
};

export default Index;
