import {ResponsivePie} from '@nivo/pie';
import {convertArea} from '@turf/turf';
import {selectMeasurement} from 'containers/login/login-selectors';
import {commonPieChartProps, TOOLTIPS} from 'containers/si/constants';
import {useFetchKPI} from 'containers/si/hooks/useFetchKPI';
import {selectSIYearsFilter} from 'containers/si/module/selectors';
import type {BarDataType} from 'containers/si/types';
import type {Color} from 'csstype';
import {selectCropTypesCDLIdMap} from 'modules/global/selectors';
import React, {Fragment, useEffect, useState} from 'react';
import {useAppSelector} from '_hooks';
import {kiloFormatter} from '_utils/number-formatters';
import {BarChart} from '../../charts/components/bar-chart/bar-chart';
import {MultiChartKPICard} from '../../charts/multi-chart-kpi-card';
import {CropRotationPieContainer} from '../../styled-components';
import {PieChartCenteredMetric} from './components/pie-chart-centered-metric';

type PieDataType = {
  id: string;
  label: string;
  value: number;
  color: Color;
};

export const CropRotationCard = () => {
  const cropsById = useAppSelector(selectCropTypesCDLIdMap);
  const measurement = useAppSelector(selectMeasurement);
  const [currentYear] = useAppSelector(selectSIYearsFilter);
  const [numCropsInRotationPieData, setNumCropsInRotationPieData] = useState<PieDataType[]>([]);
  const [fallowPieData, setFallowPieData] = useState<PieDataType[]>([]);
  const [commonRotationBarChartData, setCommonRotationBarChartData] = useState<BarDataType[]>([]);
  const [currentSlide, setCurrentSlide] = useState(0);

  const {response: cropRotationNumResponse, loading} = useFetchKPI({
    kpi: 'crop_rotation_number',
    summarizeBy: ['kpi_subtype'],
  });

  const {response: cropRotationCommonRotationsResponse} = useFetchKPI({
    kpi: 'crop_rotation_common_rotations',
    summarizeBy: ['crop_types'], //not crop_type
    topNCrops: 8,
  });

  const {response: cropRotationFallowResponse} = useFetchKPI({
    kpi: 'crop_rotation_fallow',
    summarizeBy: ['kpi_subtype'],
  });

  const processRotationNumberData = () => {
    if (!cropRotationNumResponse?.kpi_subtype_summary) return;

    const oneCrop = convertArea(
      cropRotationNumResponse.kpi_subtype_summary.croprtn_1_m2,
      'meters',
      measurement === 'ac' ? 'acres' : 'hectares'
    );
    const twoCrops = convertArea(
      cropRotationNumResponse.kpi_subtype_summary.croprtn_2_m2,
      'meters',
      measurement === 'ac' ? 'acres' : 'hectares'
    );
    const threeMoreCrops = convertArea(
      cropRotationNumResponse.kpi_subtype_summary.croprtn_3_m2,
      'meters',
      measurement === 'ac' ? 'acres' : 'hectares'
    );

    setNumCropsInRotationPieData([
      {
        id: '1-crop',
        label: '1 crop',
        value: oneCrop,
        color: '#ADE2A7',
      },
      {
        id: '2-crop',
        label: '2 crops',
        value: twoCrops,
        color: '#85C97D',
      },
      {
        id: '3-plus-crops',
        label: '3+ crops',
        value: threeMoreCrops,
        color: '#5FB854',
      },
    ]);
  };

  const processCommonRotationsData = () => {
    if (!cropRotationCommonRotationsResponse?.crop_types_summary) return;
    //Calculate crop rotation labels
    const cropEntries = Object.entries(cropRotationCommonRotationsResponse.crop_types_summary);

    const barData: Array<BarDataType> = cropEntries.map(([cropList, value]) => {
      //Transforms crop Ids into crop names
      const cropsIdList: Array<string> = cropList.split(',');
      const cropNames: Array<string> = cropsIdList.map(cropId => cropsById[Number(cropId)]?.label);
      const cropRotationString: string = cropNames.join(' - ');

      const cropValue = convertArea(value, 'meters', measurement === 'ac' ? 'acres' : 'hectares');

      return {label: cropRotationString, value: cropValue};
    });

    setCommonRotationBarChartData(barData);
  };

  const processFallowData = () => {
    if (!cropRotationFallowResponse?.kpi_subtype_summary) return;
    const noFallow = convertArea(
      cropRotationFallowResponse.kpi_subtype_summary.nofallow_m2,
      'meters',
      measurement === 'ac' ? 'acres' : 'hectares'
    );
    const oneFallow = convertArea(
      cropRotationFallowResponse.kpi_subtype_summary.lowfallow_m2,
      'meters',
      measurement === 'ac' ? 'acres' : 'hectares'
    );
    const twoFallow = convertArea(
      cropRotationFallowResponse.kpi_subtype_summary.midfallow_m2,
      'meters',
      measurement === 'ac' ? 'acres' : 'hectares'
    );

    const threeOrMoreFallow = convertArea(
      cropRotationFallowResponse.kpi_subtype_summary.highfallow_m2,
      'meters',
      measurement === 'ac' ? 'acres' : 'hectares'
    );

    setFallowPieData([
      {
        id: 'no-fallow',
        label: 'No fallow',
        value: noFallow,
        color: '#5FB854',
      },

      {
        id: '1-fallow',
        label: '1 fallow season',
        value: oneFallow,
        color: '#85C97D',
      },
      {
        id: '2-fallow-seasons',
        label: '2 fallow seasons',
        value: twoFallow,
        color: '#ADE2A7',
      },
      {
        id: '3-plus-fallow',
        label: '3+ fallow seasons',
        value: threeOrMoreFallow,
        color: '#CEE9CB',
      },
    ]);
  };

  useEffect(processRotationNumberData, [cropRotationNumResponse, measurement]);
  useEffect(processCommonRotationsData, [
    cropRotationCommonRotationsResponse,
    cropsById,
    measurement,
  ]);
  useEffect(processFallowData, [cropRotationFallowResponse, measurement]);

  const getSubtitle = (slide: number) => {
    const minimumYear = currentYear - 4 < 2015 ? 2015 : currentYear - 4;
    switch (slide) {
      case 0:
        return `Number of crops in rotation between ${minimumYear} and ${currentYear}`;
      case 1:
        return `Most common crop rotations in ${measurement === 'ac' ? 'acres' : 'hectares'}`;
      case 2:
        return `Frequency of fallow between ${minimumYear}-${currentYear}`;
    }
  };

  return (
    <MultiChartKPICard
      title="Crop rotation"
      subtitle={getSubtitle(currentSlide)}
      tooltip={TOOLTIPS.KPI.cropRotation}
      afterChange={currentSlideIndex => setCurrentSlide(currentSlideIndex)}
      loading={loading}
    >
      <CropRotationPieContainer>
        <ResponsivePie
          {...commonPieChartProps}
          data={numCropsInRotationPieData}
          margin={{bottom: 50, left: 70, right: 70, top: 40}}
          arcLinkLabelComponent={({style, datum}) => {
            const position = style.textPosition.get();

            return (
              <g>
                <path
                  fill="none"
                  stroke={style.linkColor.get()}
                  strokeWidth={style.thickness}
                  d={style.path.get()}
                />
                <text
                  transform={position}
                  textAnchor={style.textAnchor.get()}
                  dominantBaseline="central"
                  style={{
                    fill: style.textColor.get(),
                  }}
                  y={-10}
                >
                  {datum.label}
                </text>
                <text
                  transform={position}
                  textAnchor={style.textAnchor.get()}
                  dominantBaseline="central"
                  style={{
                    fill: style.textColor.get(),
                  }}
                  y={6}
                  fontWeight={600}
                >
                  {`${kiloFormatter(datum.value)} ${measurement === 'ac' ? 'ac' : 'ha'}`}
                </text>
              </g>
            );
          }}
          layers={['arcs', 'arcLabels', 'arcLinkLabels', 'legends', PieChartCenteredMetric]}
          isInteractive={false}
        />
      </CropRotationPieContainer>

      <BarChart
        kiloConvert
        colors={['#CC639C']}
        data={commonRotationBarChartData}
        margin={{left: 140}}
        customRenderTick={tick => {
          const getText = () => {
            const crops = tick.value.split(' - ');
            switch (crops.length) {
              case 1:
              case 2:
                return tick.value.length > 20 ? (
                  <Fragment>
                    <tspan y={-5}>{crops[0].concat(' - ')}</tspan>
                    <tspan x={0} y={6}>
                      {crops[1]}
                    </tspan>
                  </Fragment>
                ) : (
                  tick.value
                );
              case 3:
                return (
                  <Fragment>
                    <tspan y={-5}>{crops[0].concat(' - ', crops[1], ' - ')}</tspan>
                    <tspan x={0} y={6}>
                      {crops[2]}
                    </tspan>
                  </Fragment>
                );
              case 4:
                return (
                  <Fragment>
                    <tspan y={-5}>{crops[0].concat(' - ', crops[1], ' - ')}</tspan>
                    <tspan x={0} y={6}>
                      {crops[2].concat(' - ', crops[3])}
                    </tspan>
                  </Fragment>
                );
            }
          };

          return (
            <g transform={tick.animatedProps.transform.get()}>
              <text
                transform={tick.animatedProps.textTransform.get()}
                textAnchor={tick.textAnchor}
                dominantBaseline="middle"
                style={{
                  fill: 'rgb(51, 51, 51)',
                  fontSize: 11,
                }}
              >
                {getText()}
              </text>
            </g>
          );
        }}
      />
      <CropRotationPieContainer>
        <ResponsivePie
          {...commonPieChartProps}
          data={fallowPieData}
          margin={{bottom: 50, left: 80, right: 80, top: 40}}
          arcLinkLabelComponent={({style, datum}) => {
            const position = style.textPosition.get();

            const getLabel = () => {
              const words = datum.label.toString().split(' ');

              //Wraps text into tspans
              if (words.includes('season') || words.includes('seasons')) {
                const wrappedWords = [];
                const lastWord = words.pop();
                wrappedWords.push(words.join(' '), lastWord);
                return wrappedWords.map((word, index) => (
                  //This value depends on font-size
                  <tspan x={0} y={index * 14 - 20} key={word}>
                    {word}
                  </tspan>
                ));
              } else {
                return <tspan>{datum.label}</tspan>;
              }
            };

            return (
              <g>
                <path
                  fill="none"
                  stroke={style.linkColor.get()}
                  strokeWidth={style.thickness}
                  d={style.path.get()}
                />
                <text
                  transform={position}
                  textAnchor={style.textAnchor.get()}
                  dominantBaseline="central"
                  style={{
                    fill: style.textColor.get(),
                  }}
                  y={-8}
                >
                  {getLabel()}
                </text>
                <text
                  transform={position}
                  y={8}
                  fontWeight={600}
                  textAnchor={style.textAnchor.get()}
                  dominantBaseline="central"
                  style={{
                    fill: style.textColor.get(),
                  }}
                >
                  {`${kiloFormatter(datum.value)} ${measurement === 'ac' ? 'ac' : 'ha'}`}
                </text>
              </g>
            );
          }}
          layers={['arcs', 'arcLabels', 'arcLinkLabels', 'legends', PieChartCenteredMetric]}
          isInteractive={false}
        />
      </CropRotationPieContainer>
    </MultiChartKPICard>
  );
};
