import {
  MONITORING_PROJECT,
  MRV_ADMIN,
  MRV_ADMIN_PROGRAM_CONFIGURE,
  MRV_ADMIN_PROGRAM_DASHBOARD,
  MRV_HOME,
} from 'containers/mrv/routes';

import type {MRVFeatureType, TFeature} from '../types';

export const CropInsightsFeatures: TFeature[] = [
  'farm',
  'crop',
  'data-layers',
  'crop-performance',
  'zoning',
  'tsp',
  'analytics',
];
export const CropInsightsAdminFeatures: TFeature[] = ['ci-admin'];

export const MRVAdminFeatureGroups: MRVFeatureType[] = [
  'mrv-admin-program-dashboard',
  'mrv-admin-program-configure',
];

export const MRVFeatureLinks: Record<MRVFeatureType, string> = {
  'mrv-admin': MRV_ADMIN,
  'mrv-admin-program-dashboard': MRV_ADMIN_PROGRAM_DASHBOARD,
  'mrv-admin-program-configure': MRV_ADMIN_PROGRAM_CONFIGURE,
  carbon: MRV_HOME, // unconfirmed link
  monitoring: MONITORING_PROJECT, // unconfirmed link
  'mrv-home': MRV_HOME,
};
