import {t} from 'i18n-utils';
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {SelectionControl} from 'react-md';
import {saveFieldData, addPivotMarker} from '../../../actions';
import ButtonsPanel from './buttons-panel';
import Table from './table-body';
import TableViewHeader from 'components/table-view-header';
import FertilizerPopUp from './pop-ups/fertilizer';
import MatchCropPopUp from './pop-ups/match-crop';
import PlantingSoilParamsPopUp from './pop-ups/planting-soil-params';
import PivotFieldPopUp from './pop-ups/pivot';
import type {NRxSeason} from 'containers/map/features/nrx';
import {
  loadNrxData,
  doesFieldHasLoadedNRxSeason,
  defaultNrxSeason,
} from 'containers/map/features/nrx';
import './index.scss';
import {toggleGlobalDialog} from 'modules/global/actions';
import type {Field} from '../../../types';
import type {FieldPayload} from '../types';
import FieldsInfoLine from '../fields-info-line';
import {useAppDispatch, useAppSelector} from '_hooks';
import {
  selectCurrentFarmId,
  selectMapFieldById,
  selectMapFields,
} from 'containers/map/reducer/selectors';
import {selectNrxSeason} from 'containers/map/features/nrx/nrx-selectors';

const FieldsListContainer = () => {
  const dispatch = useAppDispatch();

  const farmId = useAppSelector(selectCurrentFarmId);
  const fields = useAppSelector(selectMapFields);
  const {editPivotTableView} = useAppSelector(s => s.global.dialogsState);
  const pivotField =
    useAppSelector(s => selectMapFieldById(s, Number(editPivotTableView?.fieldId))) ||
    ({} as Field);
  const classifiedPivotField: Field = {...pivotField, ...editPivotTableView};

  const [shouldLoadNrx, setShouldLoadNrx] = useState(false);
  const [onlyLatestSeasons, toggleOnlyLatestSeasons] = useState(false);
  const [particularSeasonId, setParticularSeasonId] = useState(0);
  const [openedPopUp, setOpenedPopUp] = useState('');

  useEffect(() => {
    setShouldLoadNrx(true);
  }, []);

  useEffect(() => {
    if (shouldLoadNrx && fields.length && !fields.some(doesFieldHasLoadedNRxSeason)) {
      dispatch(loadNrxData());
      setShouldLoadNrx(false);
    }
  }, [shouldLoadNrx, fields]);

  useEffect(() => {
    setShouldLoadNrx(true);
    togglePopUp('', false);
  }, [farmId]);

  const togglePopUp = useCallback((popUp: string, value: boolean, seasonID?: number) => {
    setParticularSeasonId(value ? Number(seasonID) : 0);
    setOpenedPopUp(value ? popUp : '');
  }, []);

  const togglePivotPopUp = (fieldId: number) => {
    dispatch(
      toggleGlobalDialog(
        'editPivotTableView',
        {
          visible: !editPivotTableView?.visible,
          fieldId,
        },
        true
      )
    );
  };

  const savePivotFieldData = (data: FieldPayload) => {
    dispatch(saveFieldData(data, farmId, editPivotTableView?.fieldId));
    togglePivotPopUp(0);
  };

  const selectedSeasons = useMemo(() => {
    return fields.flatMap(f => f.Seasons || []).filter(s => s._selected);
  }, [fields]);

  const particularSeason = useAppSelector(s => selectNrxSeason(s, particularSeasonId));
  const selectedNRXSeasons: NRxSeason[] = selectedSeasons.length
    ? selectedSeasons.map(s => s?.nrx || defaultNrxSeason).filter(s => Boolean(s.seasonID))
    : [particularSeason];

  return (
    <div className={'fields-table-view'}>
      <TableViewHeader
        tabTitle={
          <div>
            <div className={'tab-title'}>
              {t({id: 'farm.title', defaultMessage: 'Farm set-up'})}
            </div>
            <div className={'fields-info'}>
              <FieldsInfoLine />
            </div>
          </div>
        }
        buttons={
          <ButtonsPanel
            togglePopUp={togglePopUp}
            selectedSeasons={selectedSeasons}
            selectedNRXSeasons={selectedNRXSeasons}
          />
        }
      />

      <Table
        openPopUp={togglePopUp}
        togglePivotPopUp={togglePivotPopUp}
        onlyLatestSeasons={onlyLatestSeasons}
        tableNavigationComponent={
          <div className="fields-table-view__add-controls">
            <SelectionControl
              id="toggle-only-selected-crops"
              type="switch"
              label={t({id: 'Show only latest crops'})}
              name="toggle-only-selected-crops"
              onChange={v => toggleOnlyLatestSeasons(Boolean(v))}
              checked={onlyLatestSeasons}
            />
          </div>
        }
      />

      {openedPopUp === 'fertilizerPopUpOpen' && (
        <FertilizerPopUp
          hidePopUp={() => togglePopUp('fertilizerPopUpOpen', false)}
          particularSeason={particularSeason}
        />
      )}

      {openedPopUp === 'matchCropPopUpOpen' && (
        <MatchCropPopUp togglePopUp={togglePopUp} selectedNRxSeasons={selectedNRXSeasons} />
      )}

      {openedPopUp === 'plantingSoilPopUpOpen' && (
        <PlantingSoilParamsPopUp
          togglePopUp={togglePopUp}
          selectedNRxSeasons={selectedNRXSeasons}
        />
      )}

      {editPivotTableView?.visible && (
        <PivotFieldPopUp
          field={classifiedPivotField}
          onSubmit={(data: FieldPayload) => savePivotFieldData(data)}
          addPivotMarker={data => dispatch(addPivotMarker(data, true))}
          onHide={() => togglePivotPopUp(0)}
        />
      )}
    </div>
  );
};

export default FieldsListContainer;
