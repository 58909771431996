import type {PayloadAction} from '@reduxjs/toolkit';
import {createSlice} from '@reduxjs/toolkit';
import type {MRVRegion} from 'containers/mrv/types';

export const programBoundariesSlice = createSlice({
  name: 'monitoring/programBoundaries',
  initialState: {} as Record<string, MRVRegion[]>,
  reducers: {
    setBoundaries: (state, action: PayloadAction<{programId: number; boundaries: MRVRegion[]}>) => {
      state[action.payload.programId] = action.payload.boundaries;
    },
  },
});
