import React from 'react';
import {useAppSelector} from '_hooks';
import {useParsedMatchParams} from '_hooks/use-parsed-match-params';
import {Flex, Text} from 'components';
import {AreaCropContainer} from 'containers/si/planning-report/create-report/summary/area-crop-summary';
import {InterventionScenarios} from '../interventions/interventions-scenarios';
import {
  selectSISupplyShedsObjectByProgramId,
  selectNewReportCrops,
  selectNewInterventions,
  selectNewReportName,
  selectNewReportStartYear,
  selectNewReportEndYear,
  selectNewReportType,
  selectNewReportModel,
  selectNewReportProtocol,
} from 'containers/si/module/selectors';
import type {Intervention} from 'containers/si/types';
import {SI_COLORS} from 'containers/si/colors';
import {
  SIReportTypeLabel,
  SIReportModelLabel,
  SIReportProtocolLabel,
} from 'containers/si/constants';
import {getTypedEntries} from '_utils/object';
import {isNil} from '_utils/typeGuards';

export const SummaryStep = () => {
  const {programId} = useParsedMatchParams();
  const supplySheds = useAppSelector(s => selectSISupplyShedsObjectByProgramId(s, programId));
  const allSelectedCrops = useAppSelector(selectNewReportCrops);
  const allGeneratedInterventions = useAppSelector(selectNewInterventions);
  const newName = useAppSelector(selectNewReportName);
  const newStartYear = useAppSelector(selectNewReportStartYear);
  const newEndYear = useAppSelector(selectNewReportEndYear);
  const newReportType = useAppSelector(selectNewReportType);
  const newModel = useAppSelector(selectNewReportModel);
  const newProtocol = useAppSelector(selectNewReportProtocol);

  return (
    <Flex direction="row" justifyContent={'space-between'} className={'summary-step'}>
      <div className={'summary-column'}>
        <Text variant="h2" bold>
          Report Summary
        </Text>
        <div className="summary-card-container">
          <div className={'summary-card'}>
            <div className={'summary-card-header'}>Name</div>
            <div className={'summary-card-body'}>{newName}</div>
          </div>
          <div className={'summary-card'}>
            <div className={'summary-card-header'}>Type</div>
            <div className={'summary-card-body'}>{SIReportTypeLabel[newReportType]}</div>
          </div>
          <div className={'summary-card'}>
            <div className={'summary-card-header'}>Years</div>
            <div className={'summary-card-body'}>
              {newStartYear} - {newEndYear}
            </div>
          </div>
          <div className={'summary-card'}>
            <div className={'summary-card-header'}>Model</div>
            <div className={'summary-card-body'}>{SIReportModelLabel[newModel]}</div>
          </div>
          <div className={'summary-card'}>
            <div className={'summary-card-header'}>Protocol</div>
            <div className={'summary-card-body'}>{SIReportProtocolLabel[newProtocol]}</div>
          </div>
        </div>

        <Text variant="h2" className="mt-1">
          Areas and Crops
        </Text>

        <div>
          {getTypedEntries(allSelectedCrops).map(([supplyShedId, selectedObject]) => {
            const shed = supplySheds[supplyShedId];
            if (isNil(shed)) {
              return null;
            }
            if (selectedObject.commodities) {
              return (
                <AreaCropContainer
                  key={shed.id}
                  areaId={shed.id}
                  areaName={`${shed.name}`}
                  color={shed.color ?? SI_COLORS[0]}
                  commodities={selectedObject.commodities}
                  unitType={'supply_shed'}
                  reportType={newReportType}
                />
              );
            } else {
              // Subsection
              return (
                <div key={shed.id}>
                  {selectedObject?.subsection &&
                    getTypedEntries(selectedObject?.subsection).map(
                      ([subsectionId, subsectionCommodities]) => {
                        const subsection = shed.subsections.filter(
                          s => s.id === Number(subsectionId)
                        )[0];

                        return (
                          <AreaCropContainer
                            key={subsection?.id}
                            areaId={subsection?.id}
                            areaName={`${shed.name}: ${subsection?.name}`}
                            color={shed.color ?? SI_COLORS[0]}
                            commodities={subsectionCommodities}
                            unitType={'subsection'}
                            reportType={newReportType}
                          />
                        );
                      }
                    )}
                </div>
              );
            }
          })}
        </div>
        <Text variant="h2" className="mt-3">
          Intervention Scenarios
        </Text>
        {allGeneratedInterventions.map((intervention: Intervention, index: number) => {
          return (
            <InterventionScenarios
              key={index}
              scenarioNumber={index + 1}
              interventionScenarioArray={getTypedEntries(intervention)}
            />
          );
        })}
      </div>
    </Flex>
  );
};
