// @ts-nocheck
import {reportError} from 'containers/error-boundary';
import type {AppStore} from 'reducers';
import {CPFilterType} from '_reducers/crop-performance-filter/field-filter-types';
import {t} from 'i18n-utils';
import type {CSGViewModel, Aggregation} from '../types';
import {cropStatusWeight, CropStatusSequence} from '../types';
import {aggregate} from '../helpers';

export const getCropStatusData = ({
  farms,
  info,
  useUnreliableData,
  records,
  aggregation,
}: {
  farms: AppStore['cropPerformance']['farms'];
  info: AppStore['cropPerformance']['info'];
  useUnreliableData: AppStore['cropPerformance']['useUnreliableData'];
  records: CSGViewModel[];
  aggregation: Aggregation;
}) => {
  // The type for `sequences` is
  // {[sequence in CropStatusSequence]: CSGViewModel[]}
  // but for the sake of Leandro being able to add and test new sequences, we use string here.
  const sequences: {[sequence: string]: CSGViewModel[]} = {};
  records.forEach(r => {
    const sequence = farms[r.farmId]?.csgs?.[r.fieldID]?.seasons?.[r.seasonId]?.sequence;
    if (!sequence) {
      return;
    }
    if (!sequences[sequence]) {
      sequences[sequence] = [];
    }
    sequences[sequence].push(r);
  });

  return Object.keys(sequences)
    .map((sequence: CropStatusSequence) => {
      const records: CSGViewModel[] = sequences[sequence];
      const data = getCropStatusChartData(records, info?.colours, useUnreliableData, aggregation);
      if (!data.values.length) {
        return null;
      }
      if (!info?.status_seq[sequence as CropStatusSequence]) {
        reportError('There is no "info" for sequence: ' + sequence);
      }
      const displaySequence =
        sequence === CropStatusSequence.BROADCARE_CROPS ? t({id: 'Broadacre'}) : sequence;
      const filterType: CPFilterType.CROP_STATUS | CPFilterType.TREE_STATUS =
        sequence === CropStatusSequence.BROADCARE_CROPS
          ? CPFilterType.CROP_STATUS
          : CPFilterType.TREE_STATUS;
      return {sequence, displaySequence, filterType, records, data};
    })
    .filter(Boolean);
};

/**
 * Return chart data for chartjs library.
 */
export const getCropStatusChartData = (
  records: CSGViewModel[],
  colors: {[status: string]: string},
  useUnreliableData: boolean,
  aggregation: Aggregation
) => {
  const values: {[status: string]: number} = {};
  const subvalues: {[status: string]: number} = {};
  records.forEach(r => {
    const label = !r.reliable && !useUnreliableData ? 'No images' : r.cropStatus;
    if (!values[label]) {
      values[label] = 0;
    }
    values[label] += aggregate(aggregation, r);

    // To show "x / y predicted" instead of "y crops", we need gather subvalues.
    if (r.predicted) {
      if (!subvalues[label]) {
        subvalues[label] = 0;
      }
      subvalues[label] += aggregate(aggregation, r);
    }
  });
  const labels = Object.keys(values).sort((a, b) => {
    return cropStatusWeight[a] - cropStatusWeight[b];
  });
  return {
    labels: labels,
    values: labels.map(s => values[s]),
    colors: labels.map(s => colors && colors[s]),
    subvalues: labels.map(s => subvalues[s]),
  };
};

/**
 * Return chart data for nivo/pie library.
 */
export const getCropStatusMapChartData = (
  records: CSGViewModel[],
  colors: {[status: string]: string},
  useUnreliableData: boolean
) => {
  const cropStatusCount: {[status: string]: number} = {};
  records.forEach(r => {
    const label = !r.reliable && !useUnreliableData ? 'No images' : r.cropStatus;
    if (!cropStatusCount[label]) {
      cropStatusCount[label] = 0;
    }
    cropStatusCount[label]++;
  });
  const labels = Object.keys(cropStatusCount).sort((a, b) => {
    return cropStatusWeight[a] - cropStatusWeight[b];
  });
  const data = labels.map(label => ({
    id: label,
    label,
    value: cropStatusCount[label],
  }));
  const getColor = (slice: any) => colors[slice.id];
  return {data, getColor};
};
