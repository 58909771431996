import {useEffect, useState} from 'react';
import type {KPI, KPISummaryType} from 'containers/si/api/apiTypes';
import {useAppSelector, useAppDispatch} from '_hooks';
import {stringifyUrlParams} from '_hooks/use-url-params';
import {isFalsy, isNonEmptyArray} from '_utils/typeGuards';
import {
  selectCurrentProgram,
  selectSISelectedCropTypes,
  selectSIVisibleSubsectionIds,
  selectSIYearsFilter,
} from '../module/selectors';
import type {SIProgramKPI} from '../types';
import {fetchKPIData} from '../module/thunks';
import {sortAsc} from '_utils/sorters';
import SIApi from '../api/si';
import type {AxiosResponse} from 'axios';
import {KPI_FETCH_DEBOUNCE_MS} from '../constants';

interface IUseFetchParams {
  kpi: KPI;
  summarizeBy: Array<KPISummaryType>;
  year?: number;
  topNCrops?: number;
}

export const useFetchKPI = ({
  kpi,
  summarizeBy,
  year,
  topNCrops,
}: IUseFetchParams): {response: SIProgramKPI | undefined; loading: boolean} => {
  const currentProgram = useAppSelector(selectCurrentProgram);
  const selectedCropTypes = useAppSelector(selectSISelectedCropTypes);
  const yearsFilter = useAppSelector(selectSIYearsFilter);
  const selectedSubsections = useAppSelector(selectSIVisibleSubsectionIds);

  const [loading, setLoading] = useState<boolean>(false);
  const [response, setResponse] = useState<SIProgramKPI>();
  const dispatch = useAppDispatch();

  const summarizeByString = summarizeBy.sort(sortAsc).join(',');

  useEffect(() => {
    if (!currentProgram) return;
    if (!selectedCropTypes.length) return;
    if (!selectedSubsections.length) return;

    const fetchKPI = async (programId: number, kpiUrl: string) => {
      // This is done this way because the fetchKPIData thunk returns a promise  ActionType but dispatch is not ment to do it.
      // All that was done here is to use Redux with just some of the responses without affecting the ones that were not.

      const reduxSavedKPIs = [
        'ghg_emissions_factor',
        'net_ghg_emissions_factor',
        'fert_emissions_factor',
      ];

      const isReduxKPI = reduxSavedKPIs.includes(kpi);
      const kpiResponse = isReduxKPI
        ? // eslint-disable-next-line @typescript-eslint/await-thenable
          await dispatch(fetchKPIData({programId, kpiUrl}))
        : await SIApi.getKPIData(programId, kpiUrl);

      const {data} = kpiResponse as AxiosResponse<SIProgramKPI, number>;
      const {payload} = kpiResponse as unknown as {
        payload: SIProgramKPI;
      };
      setResponse(isReduxKPI ? payload : data);
    };

    setLoading(true);

    const timeout = setTimeout(() => {
      const shouldIncludeCropTypes = (kpiType: KPI) => {
        return !['lulc', 'crop_rotation_number', 'crop_rotation_fallow'].includes(kpiType);
      };

      // Generate URL
      const kpiCropData = {
        year_of_interest: year ? year : yearsFilter[0],
        summarize_by: summarizeByString,
      };
      const cropDataParams = stringifyUrlParams(kpiCropData);

      const includeCropTypes =
        isNonEmptyArray(selectedCropTypes) && shouldIncludeCropTypes(kpi)
          ? `&include_crop_types=${selectedCropTypes.join(`,`)}`
          : '';

      const includeSubsections = isNonEmptyArray(selectedSubsections)
        ? `&include_subsections=${selectedSubsections.join(`,`)}`
        : '';

      const topCrops = !isFalsy(topNCrops) ? `&top_n=${topNCrops}` : '';

      const kpiUrl = `${kpi}?${cropDataParams}${includeCropTypes}${includeSubsections}${topCrops}`;
      currentProgram && fetchKPI(Number(currentProgram), kpiUrl);
      setLoading(false);
    }, KPI_FETCH_DEBOUNCE_MS);

    return () => clearTimeout(timeout);
  }, [
    currentProgram,
    selectedCropTypes,
    selectedSubsections,
    yearsFilter,
    year,
    kpi,
    topNCrops,
    summarizeByString,
    dispatch,
  ]);

  return {response, loading};
};
