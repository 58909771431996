import {selectIsAuth, selectUser} from 'containers/login/login-selectors';
import {useMonitoringUrlParams} from 'containers/mrv/monitoring/hooks';
import {useEffect} from 'react';
import {useSelector} from 'react-redux';
import {useAppSelector} from '_hooks';
import {useWorkspace} from '_hooks/use-workspace';
import {isLoginAllowedAnalytics} from '_utils/analytics';

const ID = 4430549;

/**
 * Note: Our HubSpot account has configuration for which urls trigger this functionality.
 */
export const useHubSpotChatInit = () => {
  const isAuth = useAppSelector(selectIsAuth);
  const {programId} = useMonitoringUrlParams();
  const {email} = useSelector(selectUser);
  const {isWorkspaceMrv} = useWorkspace();

  useEffect(() => {
    /**
     * Comment this line out for debugging purposes, the HubSpot chat is currently configured to trigger on any localhost URLs.
     */
    if (window.location.hostname === 'localhost' || !isLoginAllowedAnalytics(email)) return;

    if (programId && isAuth && isWorkspaceMrv && !document.getElementById('hs-script-loader')) {
      const script = document.createElement('script');
      script.setAttribute('id', 'hs-script-loader');
      script.src = `//js.hs-scripts.com/${ID}.js`;
      script.async = true;
      document.body.appendChild(script);
    }
  }, [isAuth, programId, email, isWorkspaceMrv]);
};
