// @ts-nocheck
import {t} from 'i18n-utils';
import React, {useCallback, useEffect} from 'react';
import {DrawMarkerIcon} from '../../../../icons';
import {Flex, FluroButton} from 'components';
import {
  setAddFieldCurrentStep,
  bulkUpdateCLUFieldBoundariesProp,
  setBoundaryIdsToUpload,
} from 'modules/add-fields';
import {FieldSystemProp} from '../types';

import {CPBadge} from 'components/badge';
import {MAIN_SHAPE_COLOR} from '_constants';
import {ProtectedFieldAreaBadge} from 'components/protected-field-area-helpers';
import {useAppDispatch, useAppSelector} from '_hooks';
import {
  selectBoundaryIdsToUpload,
  selectCLUFieldBoundariesList,
  selectProtectedAreaBoundaries,
} from 'modules/add-fields/selectors';

/**
 * CLU boundaries selector.
 */
const SelectBoundariesOnMap = ({onHide}: {onHide?: () => void}) => {
  const dispatch = useAppDispatch();
  const fieldBoundaries = useAppSelector(selectCLUFieldBoundariesList);
  const boundaryIdsToUpload = useAppSelector(selectBoundaryIdsToUpload);
  const preotectedAreaBoundaries = useAppSelector(selectProtectedAreaBoundaries);

  useEffect(
    function handleAllBoundariesRemove() {
      if (!fieldBoundaries.length) {
        dispatch(setAddFieldCurrentStep('draw-fields'));
      }
    },
    [fieldBoundaries]
  );

  const onDeselectAllBoundaries = () => {
    dispatch(setBoundaryIdsToUpload([]));
    dispatch(bulkUpdateCLUFieldBoundariesProp([], FieldSystemProp.Checked, false));
  };

  const drawFields = useCallback(() => dispatch(setAddFieldCurrentStep('draw-fields')), []);

  const onConfirmSelectedBoundaries = () => {
    dispatch(setAddFieldCurrentStep('view-selected-boundaries'));
  };

  const hasProtectedArea = fieldBoundaries.some(
    f => preotectedAreaBoundaries[f.properties?.[FieldSystemProp.Id]]
  );

  return (
    <div className={'select-boundaries-container'}>
      <Flex className="fields-legend" gap="5px" nowrap>
        {hasProtectedArea && <ProtectedFieldAreaBadge />}
        {!!boundaryIdsToUpload.length && (
          <CPBadge backgroundColor={MAIN_SHAPE_COLOR} color="#fff">
            {t(
              {
                id: 'BudgeSelectedFieldsNumber',
                defaultMessage:
                  'Selected {fieldsNumber, plural, one {field} other {fields}} {fieldsNumber}',
              },
              {fieldsNumber: boundaryIdsToUpload.length}
            )}
          </CPBadge>
        )}
      </Flex>
      <div>
        {onHide && (
          <FluroButton id={'back-btn'} blank onClick={onHide} raised>
            {t({id: 'BtnLabel.Back'})}
          </FluroButton>
        )}
      </div>

      {boundaryIdsToUpload.length ? (
        <div className={'draw-fields-option'}>
          <div>{t({id: '{count} fields selected'}, {count: boundaryIdsToUpload.length})}</div>
          <FluroButton
            id={'clear-selected-boundaries-btn'}
            blank
            onClick={onDeselectAllBoundaries}
            raised
          >
            {t({id: 'Reset selection'})}
          </FluroButton>
          <FluroButton
            id={'save-selected-boundaries-btn'}
            primary
            onClick={onConfirmSelectedBoundaries}
            raised
          >
            {t({id: 'BtnLabel.Next'})}
          </FluroButton>
        </div>
      ) : (
        <div className="draw-fields-option">
          <div>{t({id: 'Or'})}</div>
          <FluroButton className={'draw-fields-btn'} onClick={drawFields} raised>
            {t({id: 'Draw fields'})} <DrawMarkerIcon />
          </FluroButton>
        </div>
      )}
    </div>
  );
};

export default SelectBoundariesOnMap;
