import {FluroChip} from 'components';
import {PlaceholderLoader} from 'components/placeholder-loader/placeholder-loader';
import {selectSIProgramById} from 'containers/si/module/selectors';
import React from 'react';
import {FontIcon} from 'react-md';
import {useAppSelector} from '_hooks';

type ProgramChipProps = {
  programId: number;
  onRemove?: () => void;
  active?: boolean;
};

export const SIProgramChip: React.ComponentType<ProgramChipProps> = ({
  programId,
  onRemove,
  active,
}) => {
  const program = useAppSelector(s => selectSIProgramById(s, programId));
  if (!program) return <PlaceholderLoader width={150} height={32} />;

  return (
    <FluroChip
      active={active}
      rightIcon={onRemove && <FontIcon onClick={onRemove}>clear</FontIcon>}
      label={
        <>
          {program.name} (#{programId})
        </>
      }
    />
  );
};
