import type {PayloadAction} from '@reduxjs/toolkit';
import {createSlice} from '@reduxjs/toolkit';
import {mergeEntities} from 'modules/redux-utils/merge-entities';
import type {NormalizedSchemaPayloadAction} from 'types';
import {
  addSIProgram,
  addSISupplyShed,
  addSISubsection,
  fetchSIProgram,
  fetchSIPrograms,
  fetchSISupplySheds,
  fetchSISupplyShedById,
  fetchProgramGeometries,
  removeSIProgram,
  removeSISupplyShed,
  removeSISubsection,
  updateSIProgram,
  updateSISupplyShed,
  updateSISubsection,
} from '../thunks';
import type {SI2State} from '../types';
import {LoginActionTypes} from 'containers/login/types';

const initialState: SI2State['entities'] = {
  programs: {},
  supplySheds: {},
  geometries: {},
};

export const entitiesSlice = createSlice({
  name: 'si/entities',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(LoginActionTypes.LOGOUT, state => {
        return {
          ...state,
          ...initialState,
        };
      })
      .addCase<string, PayloadAction<number>>(removeSIProgram.fulfilled.type, (state, action) => {
        const programId = action.payload;
        delete state.programs[programId];
      })
      .addCase<string, PayloadAction<{programId: number; supplyShedId: number}>>(
        removeSISupplyShed.fulfilled.type,
        (state, action) => {
          const {programId, supplyShedId} = action.payload;
          const supplySheds = state.supplySheds[programId];

          state.supplySheds[programId] = supplySheds.filter(shed => shed.id !== supplyShedId);
        }
      )
      .addCase<
        string,
        PayloadAction<{programId: number; supplyShedId: number; subsectionId: number}>
      >(removeSISubsection.fulfilled.type, (state, action) => {
        const {programId, supplyShedId, subsectionId} = action.payload;

        const supplySheds = state.supplySheds[programId];
        const updatedSupplySheds = supplySheds.map(shed => {
          if (shed.id === supplyShedId) {
            return {
              ...shed,
              subsections: shed.subsections.filter(subsection => subsection.id !== subsectionId),
            };
          }
          return shed;
        });
        state.supplySheds[programId] = updatedSupplySheds;
      })
      .addCase<string, NormalizedSchemaPayloadAction>(
        updateSIProgram.fulfilled.type,
        (state, action) => {
          return {
            ...state,
            ...action.payload.entities,
            programs: mergeEntities(state.programs, action.payload.entities.program),
          };
        }
      )
      .addMatcher(
        action =>
          [
            addSIProgram.fulfilled.type,
            fetchSIProgram.fulfilled.type,
            fetchSIPrograms.fulfilled.type,
            fetchSISupplySheds.fulfilled.type,
            fetchSISupplyShedById.fulfilled.type,
            fetchProgramGeometries.fulfilled.type,
            addSISupplyShed.fulfilled.type,
            addSISubsection.fulfilled.type,
            updateSISupplyShed.fulfilled.type,
            updateSISubsection.fulfilled.type,
          ].includes(action.type),
        (state, action) => {
          return mergeEntities(state, action.payload.entities);
        }
      );
  },
});
