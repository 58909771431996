import {Flex, InfoBlock, Text} from 'components';
import {
  FluroDataTable as Table,
  FluroTableBody as Body,
  FluroTableColumn as Column,
} from 'components/fluro-table-components/fluro-table-components';
import type {ComponentType} from 'react';
import React, {useCallback, useContext, useEffect, useMemo, useState} from 'react';
import {useMonitoringUrlParams} from '../../hooks';
import {TableRowsSelectionContext} from '../table-rows-selection';
import {MRVTableHeaders} from './mrv-table-headers';
import './mrv-table.scss';
import {useAppDispatch, useAppSelector, usePrevious} from '_hooks';
import cn from 'classnames';
import {
  selectSyncingOperationsStatus,
  selectStageAttributes,
  selectStageById,
  selectPhase,
} from '../../module/selectors';
import {useMixpanelTrackSwitchStage} from './use-mixpanel-track-switch-stage';
import {ProducerAgreement} from 'containers/mrv/monitoring/form/mrv-table/alternative-views/producer-agreement';
import type {MRVFarm, MRVField, MRVMob, MRVValueInput} from 'containers/mrv/types';
import {MRVPhaseType, MRVStageType, OFSStatus} from 'containers/mrv/types';
import {EligibilityView} from './alternative-views/eligibility-view';
import {useSurveyItem} from 'containers/mrv/mrv-home/use-survey-item';
import {FluroCheckbox} from 'components/fluro-checkbox/fluro-checkbox';
import {toggleTableView} from 'containers/map/actions';
import {isPhaseReadonly} from '../../module/selectors-complex';
import {useLocalStorage} from '_hooks/use-storage';
import {MRVTableEntityRows} from './mrv-table-entity-rows';
import {useAddRemoveRow} from 'containers/mrv/data/use-add-remove-row';
import {ValidationContext} from 'containers/mrv/data/ValidationContext';
import {isField} from './base';
import {EntityName} from './entity-name';
import {PlaceholderLoader} from 'components/placeholder-loader/placeholder-loader';
import {selectIsImpersonatorSuperAdmin} from 'containers/login/login-selectors';
import {Collapsible} from 'components/collapsible';
import styled, {useTheme} from 'styled-components';
import type {RowsPerEntity} from 'containers/mrv/data/use-mrv-values';
import {isDefined} from '_utils/typeGuards';

export const MRVTable: ComponentType<{
  phaseType: MRVPhaseType;
  entities: MRVFarm[] | MRVMob[] | MRVField[];
  loading: boolean;
  entityValueRows: RowsPerEntity;
  onUpdate: (entityId: number, rowIndex: number, values: MRVValueInput[]) => void;
}> = ({phaseType, entities, loading, entityValueRows, onUpdate}) => {
  const dispatch = useAppDispatch();
  const {stageId, projectId} = useMonitoringUrlParams();
  const attributes = useAppSelector(s => selectStageAttributes(s, stageId));
  const stage = useAppSelector(s => selectStageById(s, stageId));
  const [selectedRows, setSelectedRows] = useContext(TableRowsSelectionContext);
  const isSyncingOperations = useAppSelector(selectSyncingOperationsStatus).isSync;
  const isReadOnly = useAppSelector(s => isPhaseReadonly(s, phaseType).isReadOnly);
  const monitoringPhase = useAppSelector(s => selectPhase(s, MRVPhaseType.Monitoring));
  const isImpersonatorSuperAdmin = useAppSelector(selectIsImpersonatorSuperAdmin);
  const [openByEntityId, setOpenByEntityId] = useState<Record<string, boolean>>(
    getInitialOpenByEntityId(entities)
  );
  const prevStageId = usePrevious(stageId);

  const [ignoredSimilarWarnings, setIgnoredSimilarWarnings] = useLocalStorage(
    'ignoredSimilarWarnings',
    []
  );
  const [ignoredWarnings, setIgnoredWarnings] = useLocalStorage('ignoredWarnings', []);

  const {surveyView} = useSurveyItem(MRVPhaseType.Monitoring);

  useMixpanelTrackSwitchStage('table');

  const {addRow, removeRow} = useAddRemoveRow(phaseType, projectId, stage);

  const selectRow = useCallback(
    (entityId: number, rowId: number, selected: boolean) => {
      setSelectedRows(state =>
        selected
          ? state.concat({entityId, rowId})
          : state.filter(row => `${row.entityId}-${row.rowId}` !== `${entityId}-${rowId}`)
      );
    },
    [setSelectedRows]
  );

  // Make sure MRV table first opens in the full screen view.
  useEffect(() => {
    dispatch(toggleTableView('monitoring'));
  }, [dispatch]);

  useEffect(() => {
    // TODO refactor everything
    // Reset collapsible state when switching stages, was a request from the design team
    if (stageId !== prevStageId) {
      setOpenByEntityId(getInitialOpenByEntityId(entities));
    }
  }, [entities, prevStageId, stageId]);

  const selectedRowsMap = useMemo(
    () =>
      selectedRows.reduce<Record<string, boolean>>(
        (acc, selectedRow) => ({...acc, [`${selectedRow.entityId}-${selectedRow.rowId}`]: true}),
        {}
      ),
    [selectedRows]
  );

  function selectAllRowsInEntity(selected: boolean, entityId: number) {
    setSelectedRows(state => {
      const selectedRowsWithoutEntity = state.filter(row => row.entityId !== entityId);
      const selectedRowsForEntity =
        entityValueRows[entityId]?.map(row => ({entityId, rowId: row.row_id})) || [];

      if (selected) {
        return [...selectedRowsWithoutEntity, ...selectedRowsForEntity];
      }

      return selectedRowsWithoutEntity;
    });
  }

  const isSelected = useCallback(
    (entityId?: number, rowId?: number) => selectedRowsMap[`${entityId}-${rowId}`],
    [selectedRowsMap]
  );

  if (!stage?.entity_type) return null;

  if (!entities.length) {
    return <InfoBlock>No entities found. Try removing the crop filters above.</InfoBlock>;
  }

  if (stage.type_ === MRVStageType.Eligibility && projectId && stage) {
    return <EligibilityView projectId={projectId} stage={stage} />;
  }

  if (stage.type_ === MRVStageType.Contract && projectId && monitoringPhase?.id) {
    return (
      <ProducerAgreement
        projectId={projectId}
        phaseId={monitoringPhase.id}
        isReadOnly={isReadOnly}
      />
    );
  }

  if (stage.type_ === MRVStageType.Survey) {
    return surveyView;
  }

  return (
    <Flex
      direction="column"
      gap={10}
      style={{
        // offset the mrv-bottom-bar.tsx
        marginBottom: '20px',
      }}
    >
      {entities.map(entity => {
        const totalSelectedRowsInEntity = selectedRows.filter(
          row => row.entityId === entity.id
        )?.length;

        // Will be hooked up later once the mapping between rows and entities make more sense
        // if (
        //   stage.type_ === MRVStageType.NutrientManagementIntended &&
        //   isField(stage.entity_type, entity) &&
        //   !entity.eligible
        // ) {
        //   return (
        //     <Collapsed>
        //       <EntityName entityType={stage.entity_type} entity={entity} />
        //       <NutrientManagementEligibilityText />
        //     </Collapsed>
        //   );
        // }

        return (
          <Collapsible
            id={`mrv-table-${entity.id}`}
            key={entity.id}
            title={
              <>
                <SelectEntityRowsCheckbox
                  value={
                    isDefined(totalSelectedRowsInEntity) &&
                    totalSelectedRowsInEntity === entityValueRows[entity.id]?.length
                  }
                  halfChecked={totalSelectedRowsInEntity > 0}
                  disabled={isSyncingOperations || isReadOnly}
                  onChange={selected => selectAllRowsInEntity(selected, entity.id)}
                />
                <EntityName entityType={stage.entity_type} entity={entity} />
              </>
            }
            info={
              stage.type_ === MRVStageType.NutrientManagementIntended &&
              isField(stage.entity_type, entity) && (
                <NutrientManagementEligibilityText eligible={entity.eligible} />
              )
            }
            isOpen={openByEntityId[entity.id]}
            onToggle={() =>
              setOpenByEntityId(state => ({...state, [entity.id]: !state[entity.id]}))
            }
            fullWidth
          >
            <div className="mrv-table-scroll-container" data-testid="MRV-form--MRV-table">
              <Table
                className={cn('mrv-table', {'syncing-operations': isSyncingOperations})}
                responsive={false}
              >
                <MRVTableHeaders attributes={attributes} />

                <Body data-testid="MRV-table--body">
                  <ValidationContext.Provider
                    value={{
                      setIgnoredSimilarWarnings,
                      setIgnoredWarnings,
                      ignoredSimilarWarnings,
                      ignoredWarnings,
                    }}
                  >
                    {loading ? (
                      <tr data-testid="MRV-table--loading-row">
                        <Column>
                          {/* Checkbox */}
                          <PlaceholderLoader width={30} height={30} />
                        </Column>
                        {attributes.map((_, idx) => (
                          <Column key={idx}>
                            <PlaceholderLoader width={200} height={30} />
                          </Column>
                        ))}
                      </tr>
                    ) : (
                      <MRVTableEntityRows
                        key={entity.id}
                        phaseType={phaseType}
                        stageId={stageId}
                        entityId={entity.id}
                        rows={entityValueRows[entity.id] || []}
                        isImpersonatorSuperAdmin={isImpersonatorSuperAdmin}
                        readOnly={isSyncingOperations || isReadOnly}
                        isLoading={
                          // Only show loading state in the Enrollment Phase, Monitoring doesn't use Optis data.
                          phaseType === MRVPhaseType.Enrollment &&
                          isField(stage.entity_type, entity) &&
                          entity.ofs_status === OFSStatus.InProgress
                        }
                        isSelected={isSelected}
                        onSelectRow={selectRow}
                        onUpdateValues={onUpdate}
                        onAddRow={addRow}
                        onRemoveRow={removeRow}
                      />
                    )}
                  </ValidationContext.Provider>
                </Body>
              </Table>
            </div>
          </Collapsible>
        );
      })}
    </Flex>
  );
};

const SelectEntityRowsCheckbox = styled(FluroCheckbox)`
  .checkbox {
    margin-left: 5px;
  }
`;

type NutrientManagementEligibilityTextProps = {
  eligible?: boolean;
};
function NutrientManagementEligibilityText({eligible}: NutrientManagementEligibilityTextProps) {
  const theme = useTheme();

  if (eligible) {
    return (
      <Text style={{color: theme.color.text.success}} noMargin>
        This field has eligible history.
      </Text>
    );
  }

  return (
    <Text secondary noMargin>
      This field has ineligible history.
    </Text>
  );
}

// const Collapsed = styled.div`
//   border: 1px solid ${({theme}) => theme.color.stroke.strong};
//   border-radius: ${({theme}) => theme.borderRadius.small};
//   padding: 8px;
//   padding-left: 53px;
//   padding-right: 36px;
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
// `;

function getInitialOpenByEntityId(entities: MRVFarm[] | MRVMob[] | MRVField[]) {
  const initialState: Record<string, boolean> = {};

  for (let i = 0; i < entities.length; i++) {
    initialState[entities[i].id] = true;
  }

  return initialState;
}
