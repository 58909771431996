import {toRgbaType} from '_utils/colors';
import type {SIScenario} from 'containers/si/types';

// There are 2 ways to get the region name depending on the report type
export const getRegionName = (
  scenario: SIScenario,
  availableSourcingRegions: {[key: string]: string}
) => {
  let regionName = '';

  switch (scenario.tag) {
    case 'abatement_potential':
      regionName =
        availableSourcingRegions[
          `${scenario.sourcing_region_id.unit_id}+++${scenario.sourcing_region_id.unit_type}`
        ];
      break;
    case 'program_plan':
      regionName = scenario.geography.feature_name;
      break;
    default:
      const _exhaustiveCheck: never = scenario;

      return _exhaustiveCheck;
  }

  return regionName;
};

// For admins, it is helpful to see the region ID
export const getRegionId = (scenario: SIScenario, isAdmin: boolean = false) => {
  if (!isAdmin) return '';

  let regionId = '';

  switch (scenario.tag) {
    case 'abatement_potential':
      regionId = `${
        scenario.sourcing_region_id.unit_type === 'supply_shed' ? 'Supply Shed' : 'Subsection'
      } #${scenario.sourcing_region_id.unit_id}`;
      break;
    case 'program_plan':
      regionId = `#${scenario.geography.feature_id}`;
      break;
    default:
      const _exhaustiveCheck: never = scenario;

      return _exhaustiveCheck;
  }

  return regionId;
};

// Since the adoption percentage is only available in the PP report, we need to check the report type
export const getAdoptionPercentage = (scenario: SIScenario) => {
  switch (scenario.tag) {
    case 'abatement_potential':
      return '';
    case 'program_plan':
      return `${scenario.baseline.scenario_adoption * 100} %`;
    default:
      const _exhaustiveCheck: never = scenario;

      return _exhaustiveCheck;
  }
};

/**
 * Takes a background color in rgb and calculates whether the contrasting text
 * color should be white or black based on luminescense
 */
export const calculateContrastColor = (
  backgroundColorRGB: string,
  lightColor: string,
  darkColor: string
) => {
  const {r, g, b} = toRgbaType(backgroundColorRGB);

  // Formula for perceived luminance from here https://stackoverflow.com/a/596243
  const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;

  return luminance > 0.5 ? darkColor : lightColor;
};
