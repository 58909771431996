import React, {memo, useEffect, useMemo, useState} from 'react';
import cn from 'classnames';
import {CargillTheme} from './cargill-theme';
import {MainTheme} from './main-theme';
import {genKey, unreachableError} from '_utils/pure-utils';

type Theme = 'cargill' | 'main';
type Value = boolean;
export const FluroCheckbox = memo(
  ({
    theme = 'main',
    label,
    value,
    halfChecked,
    disabled,
    errorText,
    className,
    onChange,
  }: {
    theme?: Theme;
    value?: Value;
    label?: string | JSX.Element;
    halfChecked?: boolean; // in case of multiple checkboxes, half checked means some of the children checkboxes are checked
    disabled?: boolean;
    errorText?: string;
    className?: string;
    onChange?: (value: Value) => void;
  }) => {
    const id = useMemo(() => genKey(), []);
    const [_value, setValue] = useState<Value>(false);

    useEffect(() => {
      setValue(Boolean(value));
    }, [value]);

    return (
      <ThemeWrapper theme={theme} className={cn(className, {halfChecked})}>
        <input
          type="checkbox"
          id={`checkbox-${id}`}
          checked={_value}
          disabled={disabled}
          onChange={e => {
            const v = e.target.checked;

            if (!value && halfChecked) {
              // The case when the toggle is off, but half checked due to multicheckbox case,
              // we just trigger on change with the old value and let the parent manage the state.
              onChange?.(Boolean(value));
              return;
            }
            onChange?.(v);
            setValue(v);
          }}
        />
        <div className="checkbox">
          <div className="label">{label}</div>
        </div>

        {errorText ? <div className="md-text md-text--error">{errorText}</div> : null}
      </ThemeWrapper>
    );
  }
);

const ThemeWrapper = ({
  theme,
  className,
  children,
}: React.PropsWithChildren<{theme: Theme; className?: string}>) => {
  switch (theme) {
    case 'cargill':
      return <CargillTheme className={className}>{children}</CargillTheme>;
    case 'main':
      return <MainTheme className={className}>{children}</MainTheme>;
    default:
      unreachableError(theme, `Theme ${theme} is not implemented in FluroCheckbox`);
  }
  return null;
};
