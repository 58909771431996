import React from 'react';
import type {Props, Messages} from '../types';
import {
  ShapeIcon,
  EyeIcon,
  LeafIcon,
  GreenText,
  StarIcon,
  KmlIcon,
  RemoteSensingIcon,
  EyeHiddenIcon,
} from '../icons.styled';
import InfoBlock from 'components/info-block';
import {Footer} from '../helpers';
import {Button} from 'react-md';
import styled from 'styled-components';

const ExpandButton = styled(Button)`
  width: 30px;
  height: 30px;
  position: relative;
  top: 5px;
  left: 5px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0;
`;

const data: Messages = {
  potato_1: {
    title: 'Timing critical operations',
    element: (props: Props) => (
      <div className="status-alert-dialog">
        <div className="status-alert-dialog__main">
          <div>
            To time operations and agronomic decisions on a <span>potato farm</span> you can use the
            Crop Performance tab. For each field and each observation date you will find{' '}
            <b>crop status, biomass level, and % change</b>.
            <br />
            <br />
            Each of the metrics is quantified and{' '}
            <span style={{textDecoration: 'none', color: '#AA0428'}}>color</span>-
            <span style={{textDecoration: 'none', color: '#00683A'}}>coded</span> for ease of
            interpretation. You can use the Dates dropdown to view the metrics for the past dates.
          </div>
        </div>

        <InfoBlock appearance="info">
          <i>
            To transition between the Map view and the report view click “<b>Expanded View</b>”{' '}
            <ExpandButton floating mini>
              keyboard_arrow_left
            </ExpandButton>
            .
          </i>
        </InfoBlock>

        <Footer {...props} />
      </div>
    ),
  },

  178295: {
    title: 'Value of early crop stress detection',
    width: 420,
    element: (props: Props) => (
      <div className="status-alert-dialog">
        <div className="status-alert-dialog__main">
          <div>
            The <b>Crop Stress</b> areas outlined in this <span>potato field</span> are
            automatically detected by picking out the areas of the field with unusual crop growth,
            tracking crop vigour, chlorophyll and how they change over time.
            <br />
            <br />
            <b>Crop stress detected early</b> often requires you to ground-truth it by closely
            examining the crops even if they don’t yet look stresses to the naked eye.
          </div>
        </div>

        <InfoBlock appearance="info">
          <i>
            Browse the <b>Dates dropdown</b> to see how this unattended Crop Stress evolved in the
            season.
          </i>
        </InfoBlock>

        <Footer {...props} />
      </div>
    ),
  },

  178324: {
    title: 'Why accurate field boundaries matter',
    width: 420,
    element: (props: Props) => (
      <div className="status-alert-dialog">
        <div className="status-alert-dialog__main">
          <div>
            In this <span>potato pivot</span> you can see an area of{' '}
            <GreenText>
              <LeafIcon /> high vigor
            </GreenText>{' '}
            <b>Crop Stress</b> (triangle on the left side of the field) “detecting” an area of
            permanent vegetation that <span>has not been</span> excluded from the field boundary.
            <br />
            <br />
            Since it might not be possible to adjust boundaries during the season, users can{' '}
            <b>label</b> such areas as <StarIcon /> Permanent features or <KmlIcon /> Farm
            boundaries to <b>exclude</b> them from the Crop Stress analysis.
          </div>
        </div>

        <InfoBlock appearance="info">
          <i>
            Click on the <b>Crop stress shapes</b> on the map (rings or triangle) to see how you can{' '}
            <b>label</b> them as <b>Irrigation issue</b> of <b>Permanent area</b>.
          </i>
        </InfoBlock>

        <Footer {...props} />
      </div>
    ),
  },

  178342: {
    title: 'Browse layers to identify crop stress',
    width: 420,
    element: (props: Props) => (
      <div className="status-alert-dialog">
        <div className="status-alert-dialog__main">
          <div>
            The Crop Stress areas are detected based on all data and imagery available for the
            field, rather than any specific index.
            <br />
            <br />
            In this <span>potato field</span>, you see crop stress overlaid on the thermal layer
            (TIRS) that is capturing the earliest signs of crop stress.
          </div>
        </div>

        <InfoBlock appearance="info">
          <i>
            <b>Switch between</b> the TIRS and the NDVI layers to see what signs of Crop are visible
            in each of these layers
          </i>
        </InfoBlock>

        <Footer {...props} />
      </div>
    ),
  },

  178282: {
    title: 'Clogged pivot irrigation',
    width: 420,
    element: (props: Props) => (
      <div className="status-alert-dialog">
        <div className="status-alert-dialog__main">
          <div>
            Some crop stress has a particular pattern and therefore is easy to identify. In{' '}
            <span>potato pivot</span> you can see some “rings” that indicate{' '}
            <span>clogged nozzles</span>. 10-25% of pivots have this type of issue!
            <br />
            <br />
            To toggle detected “rings” on/off, click on the Drawing tools icon <ShapeIcon /> in the
            map menu on lower left, then <EyeIcon /> to show/hide shapes.
          </div>
        </div>

        <InfoBlock appearance="info">
          <i>
            The thermal layer (TIRS) captures the “ring” of crop heat stress before it appears in
            NDVI.
          </i>
        </InfoBlock>

        <Footer {...props} />
      </div>
    ),
  },

  178281: {
    title: 'How to compare the imagery layers',
    width: 420,
    element: (props: Props) => (
      <div className="status-alert-dialog">
        <div className="status-alert-dialog__main">
          <div>
            The best way to start <b>understanding imagery better</b> is by comparing the different
            sources of imagery and different layers against each other, and seeing what unique value
            each of them brings.
            <br />
            <br />
            Use the <b>Data layers tab</b> <RemoteSensingIcon /> in the right side menu to see and
            filter the imagery sources. Use <b>Compare toggle</b> to compare any 2 layers between
            each other.
          </div>
        </div>

        <InfoBlock appearance="info">
          <i>
            Tip: if you click on the satellite icon in the <b>Data layers list</b>, you will see it
            turn into <EyeHiddenIcon />. This is a way to “hide” imagery of low quality and exclude
            it from any analysis.
          </i>
        </InfoBlock>

        <Footer {...props} />
      </div>
    ),
  },
};

export default data;
