import {dialogToggle, DialogType} from 'modules/helpers';
import type {AppDispatch} from 'store';
import {setGetParamToURL} from '_utils/pure-utils';

export const openProfileDialog = () => (dispatch: AppDispatch) => {
  dispatch(dialogToggle(DialogType.profile, true));
  setGetParamToURL('profile-dialog', 'true');
};

export const closeProfileDialog = () => (dispatch: AppDispatch) => {
  dispatch(dialogToggle(DialogType.profile, false));
  setGetParamToURL('profile-dialog', null);
};
