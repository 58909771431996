import {Flex, FluroButton, FluroDialog, FluroInput, Text} from 'components';
import type {ComponentType} from 'react';
import React, {useEffect, useState} from 'react';
import type {MRVPhaseType, MRVStageNormalized} from 'containers/mrv/types';
import './mob-edit-dialog.scss';
import {selectProjectId} from 'containers/mrv/monitoring/module/selectors';
import {useAppSelector} from '_hooks';
import {useMobs} from 'containers/mrv/data/use-mobs';
import {useMRVValues} from 'containers/mrv/data/use-mrv-values';
import {t} from 'i18n-utils';
import type {EditableMob} from './mob-edit-row';
import {MobEditRow} from './mob-edit-row';

type Props = {
  phaseType: MRVPhaseType;
  stage: MRVStageNormalized;
  onHide: () => void;
};

export const MobEditDialog: ComponentType<Props> = ({phaseType, stage, onHide}) => {
  const projectId = useAppSelector(selectProjectId);
  const {mobs, loading, updateMobs} = useMobs(projectId, 'mob');
  const {revalidate} = useMRVValues(phaseType, projectId, stage);

  const [editableMobs, setEditableMobs] = useState<EditableMob[]>(
    mobs.map(mob => ({name: mob.mob_name, id: mob.id, isEditing: false}))
  );
  const [newMobName, setNewMobName] = useState<string>('');

  const handleMobChange = (mob: EditableMob | null, idx: number) => {
    const newMobs = [...editableMobs];
    if (!mob) {
      newMobs.splice(idx, 1);
    } else {
      newMobs[idx] = mob;
    }
    setEditableMobs(newMobs);
  };

  // hack to clear the new mob name input after adding
  useEffect(() => {
    if (!newMobName) {
      const input = document.getElementById('add-mob-input') as HTMLInputElement | null;
      if (input) {
        input.value = '';
      }
    }
  }, [newMobName]);

  const handleAddMob = () => {
    if (newMobName && newMobName.trim()) {
      setEditableMobs([...editableMobs, {name: newMobName.trim(), id: Infinity, isEditing: false}]);
      setNewMobName('');
    }
  };

  const handleSave = async () => {
    const existingMobs: Record<number, string> = {};
    mobs.forEach(mob => (existingMobs[mob.id] = mob.mob_name));

    const creates = editableMobs.filter(mob => mob.id === Infinity).map(mob => mob.name);

    const updates = editableMobs
      .filter(mob => mob.id !== Infinity && existingMobs[mob.id] !== mob.name)
      .map(mob => ({id: mob.id, name: mob.name}));

    editableMobs.forEach(mob => delete existingMobs[mob.id]);
    const deletes = Object.keys(existingMobs).map(id => parseInt(id, 10));

    await updateMobs({creates, updates, deletes});
    // hide dialog right after update is done
    onHide();
    // revalidate the mrv values to refresh the mob values
    revalidate();
  };

  return (
    <FluroDialog
      autosizeContent={false}
      id={'monitoring-mob-edit-fields-dialog'}
      title={
        <>
          <Flex direction="column" nowrap justifyContent="space-between">
            <Flex alignItems="center" nowrap justifyContent="space-between">
              <Text variant={'h2'} className="mb-0">
                {t({
                  id: 'EditMobsDialog.Title',
                  defaultMessage: 'Add or edit mobs',
                })}
              </Text>
              <FluroButton onClick={onHide} icon>
                close
              </FluroButton>
            </Flex>
            <Text variant={'medium'} className="mb-0">
              {t({
                id: 'EditMobsDialog.Description',
                defaultMessage:
                  'You can add mobs one at a time or you can add all of your mobs before returning to the table to input data.',
              })}
            </Text>
          </Flex>
        </>
      }
      isClosable={false}
      className={'mob-edit-dialog'}
      onHide={onHide}
      visible
      width={400}
    >
      <Flex direction="column" nowrap justifyContent="space-between">
        <FluroInput
          id="add-mob-input"
          value={newMobName}
          label={t({id: 'Add a new mob: mob name', defaultMessage: 'Add a new mob: mob name'})}
          onChange={setNewMobName}
        />
        <Flex className="mt-3" justifyContent={'flex-end'} alignItems={'center'} gap={'10px'}>
          <FluroButton onClick={handleAddMob} grayBorder raised blank>
            {t({id: '+ Add mob', defaultMessage: '+ Add mob'})}
          </FluroButton>
        </Flex>
        {editableMobs.length ? (
          <>
            <div className="mb-2 mt-2 mob-edit-divider" />
            <Text variant={'h2'} className="mb-2 mt-2">
              {t({
                id: 'Existing mobs',
                defaultMessage: 'Existing mobs',
              })}
            </Text>
            {editableMobs.map((mob, idx) => (
              <MobEditRow
                key={`${idx}-${mob.name}`}
                mob={mob}
                onChange={m => handleMobChange(m, idx)}
              />
            ))}
            <Flex justifyContent={'flex-end'} alignItems={'center'} className="mt-2">
              <FluroButton
                onClick={handleSave}
                raised
                primary
                disabled={loading || editableMobs.some(m => m.isEditing)}
              >
                {t({id: 'Done', defaultMessage: 'Done'})}
              </FluroButton>
            </Flex>
          </>
        ) : null}
      </Flex>
    </FluroDialog>
  );
};
