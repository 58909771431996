import {Flex, Text} from 'components';
import {Circle} from 'components/shapes/circle';
import type {CropBookValues, EmissionsFactorDetails} from 'containers/si/api/apiTypes';
import {
  EMPTY_EMISSION_FACTORS,
  KPI_DASHBOARD_EF_DETAILS,
  REPORT_DASHBOARD_EF_DETAILS,
  SI_CROPS,
  TOOLTIPS,
} from 'containers/si/constants';
import {
  selectBookValues,
  selectFertilizerEmissionsFactor,
  selectGHGEmissionsFactor,
  selectNetEmissionsFactor,
  selectSISelectedCropTypes,
} from 'containers/si/module/selectors';
import type {BarChartData} from 'containers/si/planning-report/dashboard/cards/emission-factor/emission-factor';
import {DataRow} from 'containers/si/planning-report/dashboard/cards/emission-factor/emmision-factor-styled-components';
import {StackedBarChart} from 'containers/si/planning-report/dashboard/components/stacked-bar-chart/stacked-bar-chart';
import {StackedBarTick} from 'containers/si/planning-report/dashboard/components/stacked-bar-chart/stacker-bar-tick';
import {GlobalEFTooltipStyles} from 'containers/si/planning-report/dashboard/styled-components';
import type {SIScenarioAP} from 'containers/si/types';
import {selectCropTypesCDLIdMap} from 'modules/global/selectors';
import React, {useEffect, useState} from 'react';
import {SelectionControlGroup} from 'react-md';
import styled from 'styled-components';
import {useAppSelector} from '_hooks';
import {getTypedValues} from '_utils/object';
import {sum} from '_utils/pure-utils';
import {isDefined} from '_utils/typeGuards';
import {ChartHeader} from '../charts/components/chart-header';
import {Card} from '../styled-components';

type BaselineType = 'ghg' | 'net_ghg';

export const FieldToFarmCard = () => {
  const cropsById = useAppSelector(selectCropTypesCDLIdMap);
  const GHGEF = useAppSelector(selectGHGEmissionsFactor);
  const NetEF = useAppSelector(selectNetEmissionsFactor);
  const FertEF = useAppSelector(selectFertilizerEmissionsFactor);
  const bookValues = useAppSelector(selectBookValues);
  const selectedCrops = useAppSelector(selectSISelectedCropTypes);

  const [barChartData, setBarChartData] = useState<BarChartData[]>([]);
  const [baselineType, setBaselineType] = useState<BaselineType>('ghg');

  useEffect(() => {
    const selectedCropsBookValues = bookValues.filter(crop => selectedCrops.includes(crop.crop_id));

    const getBaselineValues = (crop: CropBookValues) => {
      return Object.entries(crop.emission_factor_details ?? EMPTY_EMISSION_FACTORS).map(
        ([key, val]) => {
          if (key === 'ghg_emissions_factor')
            return [
              'ghg_emissions_factor',
              baselineType === 'ghg'
                ? GHGEF.cropSummary[crop.crop_id] ??
                  crop.emission_factor_details?.ghg_emissions_factor ??
                  0
                : NetEF.cropSummary[crop.crop_id] ?? 0,
            ];
          if (key === 'fert_emissions_factor')
            return [
              'fert_emissions_factor',
              FertEF.cropSummary[crop.crop_id] ??
                crop.emission_factor_details?.fert_emissions_factor ??
                0,
            ];
          return [key, val];
        }
      );
    };

    const getBookValues = (crop: CropBookValues) => {
      if (isDefined(crop.emission_factor_details)) {
        return sum(
          Object.values(
            baselineType === 'ghg'
              ? crop.emission_factor_details
              : {...crop.emission_factor_details, ghg_emissions_factor: 0}
          )
        );
      }

      return 0;
    };

    const data: BarChartData[] = [];
    selectedCropsBookValues.forEach(crop => {
      data.push({
        index: cropsById[crop.crop_id]?.label ?? SI_CROPS[crop.crop_id],
        book: getBookValues(crop),
        baseline: getBaselineValues(crop).reduce((acc, val) => acc + Number(val[1]), 0),
        //Both scenario and ef are set to 0 because we don't handle scenarios here
        scenario: 0,
        ef: 0,
        detailedData: {
          book: {
            ...(crop.emission_factor_details ?? EMPTY_EMISSION_FACTORS),
            ghg_emissions_factor:
              baselineType === 'net_ghg'
                ? 0
                : crop.emission_factor_details?.ghg_emissions_factor ?? 0,
          },
          baseline: {
            ...(crop.emission_factor_details ?? EMPTY_EMISSION_FACTORS),
            fert_emissions_factor:
              FertEF.cropSummary[crop.crop_id] ??
              crop.emission_factor_details?.fert_emissions_factor ??
              0,
            ghg_emissions_factor:
              baselineType === 'ghg'
                ? GHGEF.cropSummary[crop.crop_id] ??
                  crop.emission_factor_details?.ghg_emissions_factor ??
                  0
                : NetEF.cropSummary[crop.crop_id] ?? 0,
          },
          scenario: EMPTY_EMISSION_FACTORS,
          //fullScenario is set to {} because we don't handle scenarios here
          fullScenario: {} as SIScenarioAP,
        },
      });
    });

    data.sort((a, b) => b.book - a.book);

    setBarChartData(data);
  }, [selectedCrops, GHGEF, FertEF, NetEF, bookValues, baselineType, cropsById]);

  const handleChange = (selectedBaselineType: BaselineType) => {
    setBaselineType(selectedBaselineType);
  };

  return (
    <Card fullWidth>
      <GlobalEFTooltipStyles />
      <ChartHeader
        title="Field to Farm Gate Emission Factor"
        subtitle="In kg CO2e / 1kg of yield, sorted by descending book value"
        tooltip={TOOLTIPS.KPI.efStackedBarChart}
      />
      <div style={{position: 'relative'}}>
        <Controls>
          <div>Regrow baseline:</div>
          <SelectionControlGroup
            id="regrow-baseline-selection"
            name="radio"
            type="radio"
            className="si-toggle-group si-toggle-group--inline select-units"
            inline
            value={baselineType}
            onChange={v => handleChange(v as BaselineType)}
            controls={[
              {
                label: 'Field GHG EF',
                value: 'ghg',
              },
              {
                label: 'Field net EF',
                value: 'net_ghg',
              },
            ]}
          />
        </Controls>
      </div>

      <Container>
        <StackedBarChart
          reverse={false}
          barChartData={barChartData}
          keys={['book', 'baseline']}
          layout={'horizontal'}
          margin={{left: 185}}
          customTick={tickProps => (
            <StackedBarTick
              startNumber={0}
              textOffset={180}
              dataLength={barChartData.length}
              {...tickProps}
            />
          )}
          pagination={{itemsPerPage: 5}}
          renderTooltip={(data, key) => {
            const details = REPORT_DASHBOARD_EF_DETAILS[key as keyof EmissionsFactorDetails];
            const book =
              data.data.detailedData.book[key as keyof EmissionsFactorDetails].toPrecision(3);
            const baseline =
              data.data.detailedData.baseline[key as keyof EmissionsFactorDetails].toPrecision(3);

            return (
              <div className="ef-tooltip" style={{left: '-100 !important'}}>
                <Flex
                  direction="row"
                  nowrap
                  justifyContent="flex-start"
                  alignItems="center"
                  className="margin-bottom-8"
                >
                  <Circle inline size={12} color={details?.color} id="ef-tooltip-circle" />
                  <Text bold className="margin-bottom-0">
                    {details?.label}
                  </Text>
                </Flex>
                <Flex direction="column">
                  {book !== undefined && (
                    <DataRow>
                      <Text className="margin-bottom-0">Book value</Text>
                      <Text className="margin-bottom-0" bold>
                        {book}
                      </Text>
                    </DataRow>
                  )}
                  <DataRow>
                    <Text className="margin-bottom-0">Baseline</Text>
                    <Text className="margin-bottom-0" bold>
                      {baseline}
                    </Text>
                  </DataRow>
                </Flex>
              </div>
            );
          }}
          legends={getTypedValues(KPI_DASHBOARD_EF_DETAILS).map(item => {
            return {
              id: item.label,
              label: item.label,
              color: item.color,
            };
          })}
        />
      </Container>
    </Card>
  );
};

const Controls = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: absolute;
  right: 0px;
  top: -40px;
`;

const Container = styled.div`
  height: 300px;
  width: 100%;
  .responsive-chart {
    height: 100%;
    width: 100%;
  }

  &.bar-charts {
    width: 100%;
  }
  .ef-legend {
    position: absolute;
    top: 0;
    right: 0;
    width: 120px;
    display: flex;
    flex-direction: column;
    gap: 4px;

    .ef-legend-circle {
      flex: 0 0 12px;
    }

    .ef-legend-text {
      font-size: 12px;
      line-height: 12px;
      color: ${({theme}) => theme.color.text.main};
    }
  }
`;
