// @ts-nocheck
import {t} from 'i18n-utils';
import * as React from 'react';
import moment from 'moment';
import cn from 'classnames';
import type {Biomass, CSGAvgViewModel, CSGViewModel} from './types';
import './table-view-csg.scss';
import type {PeriodicData} from './helpers/periodic';
import {NdviView} from './helpers/ndvi';
import {CropGrowth, CropStatus} from './helpers';
import {
  CropInfo,
  FluroTableColumn,
  FluroTableHeader,
  FluroTableRow,
  FluroDataTable,
  FluroTableBody,
} from 'components';
import {APP_DATE_FORMAT} from '_constants';
import type {CropVarietyGroup} from './helpers/records';
import type {TableHeadersProps} from './table-view-csg-headers';
import {TableHeaders} from './table-view-csg-headers';
import {toFixedFloatUnsafe} from '_utils/number-formatters';
import {classifyAreaWithUnits} from '_utils';
import {getCropColorById, averageNumber} from '_utils/pure-utils';
import {calcAverage} from './helpers/average';
import type {Measurement} from '../../../login/types';
import type {AppStore} from 'reducers';

type Props = TableHeadersProps & {
  recordGroups: CropVarietyGroup[];
  biomasses: {[fieldId: number]: Biomass};
  periodic: PeriodicData;
  onChangeField: (farmId: number, fieldId: number) => void;
  onChangePeriod: (options: {next?: boolean; prev?: boolean}) => void;
  isCurrentDate: (date: string) => boolean;
  cropVarietyColors: {[cropVariety: string]: string};
  measurement: Measurement;
  cropTypes: AppStore['global']['cropTypes'];
};

export const TableView = ({
  recordGroups,
  periodic,
  biomasses,
  sortType,
  sortOrder,
  showFarmName,
  onClick,
  onChangeField,
  onChangePeriod,
  isCurrentDate,
  cropVarietyColors,
  measurement,
  cropTypes,
}: Props) => {
  return (
    <FluroDataTable className="crop-performance-table">
      <FluroTableHeader>
        <TableHeaders
          periodic={periodic}
          sortType={sortType}
          sortOrder={sortOrder}
          showFarmName={showFarmName}
          onClick={onClick}
          onChangePeriod={onChangePeriod}
        />
      </FluroTableHeader>
      <FluroTableBody>
        {recordGroups.map(g => {
          const {avg, avgPeriodicNdvis} = getAverage(g.records, periodic);
          const color =
            (avg.cropVariety && cropVarietyColors[avg.cropVariety]) ||
            getCropColorById(cropTypes, avg.cropType);
          return (
            <React.Fragment key={`${g.cropType}_${g.cropVariety}`}>
              <FluroTableRow className="average-row">
                <AverageColumns
                  measurement={measurement}
                  record={avg}
                  color={color}
                  showFarmName={showFarmName}
                />
                <BaseColumns record={avg} biomass={'medium'} periodic={avgPeriodicNdvis} />
              </FluroTableRow>
              {g.records.map(r => {
                const biomass = biomasses[r.fieldID];
                return (
                  <FluroTableRow key={r.seasonId}>
                    <RecordColumns
                      measurement={measurement}
                      record={r}
                      imageryIsCurrentDate={isCurrentDate(r.lastImageryDate)}
                      showFarmName={showFarmName}
                      onChangeField={onChangeField}
                    />
                    <BaseColumns
                      record={r}
                      biomass={biomass}
                      periodic={getSingleRecordPeriodic(periodic, r.fieldID)}
                    />
                  </FluroTableRow>
                );
              })}
            </React.Fragment>
          );
        })}
      </FluroTableBody>
    </FluroDataTable>
  );
};

const AverageColumns = ({
  record,
  color,
  showFarmName,
  measurement,
}: {
  record: CSGAvgViewModel;
  color: string;
  showFarmName: boolean;
  measurement: Measurement;
}) => {
  const r = record;
  return (
    <>
      <FluroTableColumn className={'average-crop-info'}>
        <div className="color" style={{backgroundColor: color}} />
        <CropInfo cropType={r.cropType} cropSubType={r.cropVariety} />
      </FluroTableColumn>
      {showFarmName && <FluroTableColumn />}
      <FluroTableColumn />
      <FluroTableColumn type={'number'} nowrap>
        {classifyAreaWithUnits(Math.round(record.fieldArea), measurement)}
      </FluroTableColumn>
      <FluroTableColumn />
    </>
  );
};

const RecordColumns = ({
  record,
  imageryIsCurrentDate,
  showFarmName,
  onChangeField,
  measurement,
}: {
  record: CSGViewModel;
  imageryIsCurrentDate: boolean;
  showFarmName: boolean;
  onChangeField: (farmId: number, fieldId: number) => void;
  measurement: Measurement;
}) => {
  const r = record;
  return (
    <>
      <FluroTableColumn>
        <div className="field-name" onClick={() => onChangeField(r.farmId, r.fieldID)}>
          {r.fieldName}
        </div>
        {r.isPlantingArea && <div className="season-name">{r.seasonName}</div>}
      </FluroTableColumn>
      {showFarmName && (
        <FluroTableColumn>
          <span className="farm-name">{r.farmName}</span>
        </FluroTableColumn>
      )}
      <FluroTableColumn nowrap>
        <CropInfo
          cropType={r.cropType}
          cropSubType={r.cropVariety}
          startDate={moment.utc(r.startDate).format('DD MMM YYYY')}
          endDate={moment.utc(r.endDate).format('DD MMM YYYY')}
          hasGeometry={r.isPlantingArea}
        />
      </FluroTableColumn>
      <FluroTableColumn type={'number'}>
        {classifyAreaWithUnits(Math.round(r.fieldArea), measurement)}
      </FluroTableColumn>
      <FluroTableColumn nowrap type={'date'}>
        <span className={cn({imagery: true, highlighted: !imageryIsCurrentDate})}>
          {moment(r.lastImageryDate).format(APP_DATE_FORMAT)}
        </span>
      </FluroTableColumn>
    </>
  );
};

const BaseColumns = ({
  record,
  biomass,
  periodic,
}: {
  record: CSGViewModel | CSGAvgViewModel;
  biomass: Biomass;
  periodic: {ndvi: number; diff: number};
}) => {
  const r = record;
  const p = periodic.diff == null ? t({id: 'Not available'}) : `${periodic.diff}%`; // periodic.diff can be undefined if the season haven't started on the periodic date
  return (
    <>
      <FluroTableColumn>
        <CropStatus value={r.cropStatus} predicted={r.predicted} />
      </FluroTableColumn>
      <FluroTableColumn>
        <CropGrowth value={r.growth} predicted={r.predicted} />
      </FluroTableColumn>
      <FluroTableColumn>
        <NdviView ndvi={r.smoothSatelliteNdvi} status={biomass} predicted={r.predicted} />
      </FluroTableColumn>
      <FluroTableColumn type={'number'}>
        {r.cumulativeSmoothSatelliteNdvi?.toFixed(1)}
      </FluroTableColumn>
      <FluroTableColumn>
        <div className="diff-container">
          <div className={cn({diff: true, 'not-available': periodic.diff == null})}>{p}</div>
        </div>
      </FluroTableColumn>
    </>
  );
};

const getSingleRecordPeriodic = (periodic: PeriodicData, fieldId: number) => {
  return {
    ndvi: periodic.ndvis[fieldId],
    diff: periodic.diffs[fieldId],
  };
};

const getAverage = (records: CSGViewModel[], periodic: PeriodicData) => {
  const avg = calcAverage(records);
  const ndvi = toFixedFloatUnsafe(
    averageNumber(records.map(r => periodic.ndvis[r.fieldID]).filter(x => x != null)),
    2
  );
  const diff = 100 - Math.round((ndvi / avg.smoothSatelliteNdvi) * 100);
  const avgPeriodicNdvis = {ndvi, diff};
  return {avg, avgPeriodicNdvis};
};
