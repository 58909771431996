// @ts-nocheck
import * as React from 'react';
import {useState, useEffect, useMemo} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import type {AppStore} from 'reducers';
import {EmbedLogin} from 'containers/login/embed/embed-login-container';
import config from '_environment';
import IntegrationCard from './helpers/integration-card';
import {ExternalService} from '../../map/types';
import {Button, CircularProgress, SelectionControl} from 'react-md';
import {t} from 'i18n-utils';
import {
  ExpandableArea,
  ExpandableRowContainer,
  SubContainer,
  SubItem,
} from 'components/expandable-table-items/expandable-table-items';
import {FluroButton, Sticky} from 'components';
import cn from 'classnames';
import {RequestStatus} from '../../../types';
import type {AgxFarm, AgxField, AgxGrowerV2, IntegrationComponentProps} from './types';
import {AgxApi} from '_api';
import {sortByStringKey} from 'containers/profile/integration/helpers/utils';
import {logout} from '../../login/actions';
import {showNotification} from 'components/notification/notification';
import {reportError} from '../../error-boundary';
import Mixpanel from '_utils/mixpanel-utils';
import {syncAgxGrowersV2} from './actions';
import {Flex} from 'components/flex/flex';
import {useAppSelector} from '../../../_hooks';
import {selectIntegrationPlatform} from './integration-selectors';
import type {ComponentType} from 'react';
import {SelectedPlatformBackButton} from './helpers/selected-platform-back-btn';
import {SelectFarmsAndFieldsToImportRow} from './helpers/select-farms-fields-to-import-row';

const IntegrationAgx: ComponentType<IntegrationComponentProps> = ({expanded}) => {
  const [showLogin, setShowLogin] = useState<boolean>(false);
  const authorized = useSelector((s: AppStore) => s.login.user.agxSync);

  const dispatch = useDispatch();
  const platform = useAppSelector(s => selectIntegrationPlatform(s, ExternalService.Agx));

  const [growers, setGrowers] = useState<AgxGrowerV2[]>([]);
  const [selectedGrowers, setSelectedGrowers] = useState<string[]>([]);
  const [expandedGrowers, setExpandedGrowers] = useState<{[growerId: string]: boolean}>({});

  const [farms, setFarms] = useState<{[growerId: string]: AgxFarm[]}>({});
  const [selectedFarms, setSelectedFarms] = useState<{[growerId: string]: string[]}>({});
  const [expandedFarms, setExpandedFarms] = useState<{[farmId: string]: boolean}>({});

  const [fields, setFields] = useState<{[farmId: string]: AgxField[]}>({});
  const [selectedFields, setSelectedFields] = useState<{[farmId: string]: string[]}>({});

  const [requests, setRequests] = useState<{[entityId: string]: RequestStatus}>({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getAgxGrowers();
  }, [authorized, expanded]);

  const getAgxGrowers = () => {
    if (!authorized || !expanded || growers.length || loading) return;

    setRequests(r => ({...r, allGrowers: RequestStatus.Loading}));
    setLoading(true);
    AgxApi.getGrowers()
      .then(({data}) => {
        const growers = sortByStringKey(data.result, 'Name');
        setGrowers(growers);
        setRequests(r => ({...r, allGrowers: RequestStatus.Success}));
        setLoading(false);
      })
      .catch(err => {
        setLoading(false);
        setRequests(r => ({...r, allGrowers: RequestStatus.Error}));
        if (!err?.message?.includes(500)) {
          dispatch(logout());
          showNotification({
            title: t({id: 'note.warning', defaultMessage: 'Warning'}),
            message: t({
              id: 'You are being logged out because we need to revalidate your agX credentials. Please log back in to finish the process.',
            }),
            type: 'warning',
          });
        }
      });
  };

  const getAgxFarms = (growerId: string) => {
    setRequests(r => ({...r, [growerId]: RequestStatus.Loading}));

    AgxApi.getFarms(growerId)
      .then(({data}) => {
        const f = sortByStringKey(data.result, 'Name');
        setFarms(farms => ({...farms, [growerId]: f}));
        setRequests(r => ({...r, [growerId]: RequestStatus.Success}));
        if (selectedGrowers.includes(growerId)) {
          // If the expanded grower was selected, select all the including farms automatically.
          setSelectedFarms(selectedFarms => ({
            ...selectedFarms,
            [growerId]: f.map(f => f.ID),
          }));
        }
      })
      .catch(e => {
        setRequests(r => ({...r, [growerId]: RequestStatus.Error}));
        onRequestError();
        reportError(`Agx sync v2 error fetching farms for grower ${growerId}: ` + e);
      });
  };

  const onRequestError = () => {
    showNotification({
      title: t({id: 'note.warning'}),
      message: t({
        id: 'errorTryReloadPage',
      }),
      type: 'warning',
    });
  };

  const getAgxFields = (growerId: string, farmId: string) => {
    setRequests(r => ({...r, [farmId]: RequestStatus.Loading}));

    AgxApi.getFields(growerId, farmId)
      .then(({data}) => {
        const f = sortByStringKey(data.result, 'Name');
        setFields(fields => ({...fields, [farmId]: f}));
        setRequests(r => ({...r, [farmId]: RequestStatus.Success}));
        if (selectedFarms[growerId]?.includes(farmId)) {
          // If the expanded farm was selected, select all the including fields automatically.
          setSelectedFields(selectedFields => ({
            ...selectedFields,
            [farmId]: f.map(f => f.ID),
          }));
        }
      })
      .catch(e => {
        onRequestError();
        setRequests(r => ({...r, [farmId]: RequestStatus.Error}));
        reportError(`Agx sync v2 error fetching fields for farm ${farmId}: ` + e);
      });
  };

  const expandGrower = (growerId: string) => {
    const newValue = !expandedGrowers[growerId];
    setExpandedGrowers(x => ({
      ...x,
      [growerId]: newValue,
    }));
    if (newValue && !farms[growerId]) {
      getAgxFarms(growerId);
    }
  };

  const expandFarm = (growerId: string, farmId: string) => {
    const newValue = !expandedFarms[farmId];
    setExpandedFarms(x => ({
      ...x,
      [farmId]: newValue,
    }));
    if (newValue && !fields[farmId]) {
      getAgxFields(growerId, farmId);
    }
  };

  const toggleField = (growerId: string, farmId: string, fieldId: string, value: boolean) => {
    const oldFields = selectedFields[farmId] || [];
    const newFields = value ? [...oldFields, fieldId] : oldFields.filter(x => x !== fieldId);
    setSelectedFields(selectedFields => ({
      ...selectedFields,
      [farmId]: newFields,
    }));

    // Check if the parent (farm) needs to be updated.
    if (selectedFarms[growerId]?.includes(farmId) && !newFields.length) {
      // The farm is selected but none of the fields inside this farm is selected anymore.
      // Deselect the farm.
      const oldFarms = selectedFarms[growerId];
      const newFarms = selectedFarms[growerId].filter(id => id !== farmId);
      setSelectedFarms(selectedFarms => ({
        ...selectedFarms,
        [growerId]: newFarms,
      }));

      // Check if parent (grower) needs to be updated.
      if (oldFarms.length > 0 && newFarms.length === 0) {
        // Some farms of the grower were selected and now none of them are selected.
        // Deselect the grower.
        setSelectedGrowers(growers => growers.filter(id => id !== growerId));
      }
    } else if (!selectedFarms[growerId]?.includes(farmId) && newFields.length) {
      // The farm is not selected but the field inside this farm is selected.
      // Select the farm.
      const oldFarms = selectedFarms[growerId] || [];
      const newFarms = [...oldFarms, farmId];
      setSelectedFarms(selectedFarms => ({
        ...selectedFarms,
        [growerId]: newFarms,
      }));

      // Check if parent (grower) needs to be updated.
      if (oldFarms.length === 0 && newFarms.length > 0) {
        // None of the farms of the grower were selected and now some of them are selected.
        // Select the grower.
        setSelectedGrowers(growers => [...growers, growerId]);
      }
    }
  };

  const toggleFarm = (growerId: string, farmId: string, value: boolean) => {
    const oldFarms = selectedFarms[growerId] || [];
    const newFarms = value ? [...oldFarms, farmId] : oldFarms.filter(x => x !== farmId);
    setSelectedFarms(selectedFarms => ({
      ...selectedFarms,
      [growerId]: newFarms,
    }));

    // Check if parent (grower) needs to be updated.
    if (oldFarms.length > 0 && newFarms.length === 0) {
      // Some farms of the grower were selected and now none of them are selected.
      // Deselect the grower.
      setSelectedGrowers(growers => growers.filter(id => id !== growerId));
    } else if (oldFarms.length === 0 && newFarms.length > 0) {
      // None of the farms of the grower were selected and now some of them are selected.
      // Select the grower.
      setSelectedGrowers(growers => [...growers, growerId]);
    }

    // If we have fields loaded for the farm, update them.
    if (fields[farmId]) {
      setSelectedFields(selectedFields =>
        value
          ? {...selectedFields, [farmId]: fields[farmId].map(f => f.ID)}
          : {...selectedFields, [farmId]: []}
      );
    }
  };

  const toggleGrower = (growerId: string, value: boolean) => {
    setSelectedGrowers(selectedGrowers => {
      return value ? [...selectedGrowers, growerId] : selectedGrowers.filter(x => x !== growerId);
    });

    // If we have farms loaded for the grower, update them.
    if (farms[growerId]) {
      setSelectedFarms(selectedFarms =>
        value
          ? {...selectedFarms, [growerId]: farms[growerId].map(f => f.ID)}
          : {...selectedFarms, [growerId]: []}
      );

      const newFields: {[farmId: string]: string[]} = {};
      farms[growerId].forEach(farm => {
        if (fields[farm.ID]) {
          newFields[farm.ID] = value ? fields[farm.ID].map(f => f.ID) : [];
        }
      });
      setSelectedFields(newFields);
    }
  };

  const toggleAllGrowers = (value: boolean) => {
    // TODO stas: update all the children (farms and fields).
    setSelectedGrowers(value ? growers.map(g => g.ID) : []);

    const newFarms: {[growerId: string]: string[]} = {};
    Object.entries(farms).forEach(([growerId, farms]) => {
      newFarms[growerId] = value ? farms.map(f => f.ID) : [];
    });
    setSelectedFarms(newFarms);

    const newFields: {[farmId: string]: string[]} = {};
    Object.entries(fields).forEach(([farmId, fields]) => {
      newFields[farmId] = value ? fields.map(f => f.ID) : [];
    });
    setSelectedFields(newFields);
  };

  const sync = () => {
    // Don't send the names with sync v2, this list can be enormous.
    Mixpanel.importSync(ExternalService.Agx, []);

    dispatch(
      syncAgxGrowersV2({
        fieldIds: Object.values(selectedFields).reduce((acc, v) => [...acc, ...v], []),
        // Take only selected farms that don't have fetched fields.
        farmIds: Object.values(selectedFarms).reduce(
          (acc, v) => [...acc, ...v.filter(farmId => !fields[farmId])],
          []
        ),
        // Take only selected growers that don't have fetched farms.
        growerIds: selectedGrowers.reduce(
          (acc, growerId) => (farms[growerId] ? acc : [...acc, growerId]),
          []
        ),
      })
    );
  };

  const isSyncInProgress = platform.syncStatus === RequestStatus.Loading;
  const isReady = Boolean(authorized && !loading);

  const reauthComponent = useMemo(() => {
    return (
      <FluroButton blank raised onClick={authorize}>
        {t({id: 'Re-authorize'})}
      </FluroButton>
    );
  }, []);

  return (
    <div className={cn('integration-platform agx-container', {selected: expanded})}>
      <IntegrationCard
        authorized={authorized}
        expanded={expanded}
        onAuthorize={authorize}
        reauthComponent={reauthComponent}
        platform={ExternalService.Agx}
        loading={loading}
      />

      {expanded && (
        <>
          {isReady && (
            <div className={'entities-to-import-container agx'}>
              <SelectFarmsAndFieldsToImportRow />

              {growers.length > 1 && (
                <SelectionControl
                  id={'select-all-growers'}
                  name={'select-all-growers'}
                  label={t(
                    {id: 'Select all growers ({count1}/{count2})'},
                    {count1: selectedGrowers?.length || 0, count2: growers.length}
                  )}
                  type={'checkbox'}
                  className={'select-all-checkbox'}
                  checked={growers.length === selectedGrowers.length}
                  onChange={(v: boolean) => toggleAllGrowers(v)}
                />
              )}
              {growers.map(g => {
                const isGrowerSelected = selectedGrowers.includes(g.ID);
                return (
                  <React.Fragment key={g.ID}>
                    <ExpandableRowContainer className={cn({selected: isGrowerSelected})}>
                      <SelectionControl
                        id={g.ID}
                        name={'grower selection'}
                        className={'highest-platform-entity'}
                        label={g.Name + selectedEntitiesNumber(farms[g.ID], selectedFarms[g.ID])}
                        type={'checkbox'}
                        checked={isGrowerSelected}
                        onChange={(v: boolean) => toggleGrower(g.ID, v)}
                      />
                      <ExpandableArea
                        onClick={() => expandGrower(g.ID)}
                        expanded={expandedGrowers[g.ID]}
                      />
                    </ExpandableRowContainer>
                    {expandedGrowers[g.ID] && (
                      <SubContainer>
                        {requests[g.ID] === RequestStatus.Loading && (
                          <span className="preloader">
                            <CircularProgress centered={false} id={g.ID} />
                          </span>
                        )}
                        {farms[g.ID]?.map(f => {
                          const isFarmSelected = (selectedFarms[g.ID] || []).includes(f.ID);
                          return (
                            <React.Fragment key={f.ID}>
                              <SubItem
                                className={cn({
                                  selected: isFarmSelected,
                                })}
                              >
                                <SelectionControl
                                  id={f.ID}
                                  name={'farm selection'}
                                  className={'medium-platform-entity'}
                                  label={
                                    f.Name +
                                    selectedEntitiesNumber(fields[f.ID], selectedFields[f.ID])
                                  }
                                  type={'checkbox'}
                                  checked={isFarmSelected}
                                  onChange={(v: boolean) => toggleFarm(g.ID, f.ID, v)}
                                />
                                <ExpandableArea
                                  onClick={() => expandFarm(g.ID, f.ID)}
                                  expanded={expandedFarms[f.ID]}
                                />
                              </SubItem>
                              {expandedFarms[f.ID] && (
                                <SubContainer>
                                  {requests[f.ID] === RequestStatus.Loading && (
                                    <span className="preloader">
                                      <CircularProgress centered={false} id={f.ID} />
                                    </span>
                                  )}
                                  {fields[f.ID]?.map(field => {
                                    return (
                                      <SubItem key={field.ID}>
                                        <SelectionControl
                                          id={field.ID}
                                          name={'field selection'}
                                          className={'small-platform-entity'}
                                          label={field.Name}
                                          type={'checkbox'}
                                          checked={(selectedFields[f.ID] || []).includes(field.ID)}
                                          onChange={(v: boolean) =>
                                            toggleField(g.ID, f.ID, field.ID, v)
                                          }
                                        />
                                      </SubItem>
                                    );
                                  })}
                                </SubContainer>
                              )}
                            </React.Fragment>
                          );
                        })}
                      </SubContainer>
                    )}
                  </React.Fragment>
                );
              })}
            </div>
          )}
          <Sticky className="action-buttons-holder">
            {isReady && !growers.length && (
              <div className={'margin-top-10'}>{t({id: 'The user has no growers.'})}</div>
            )}

            <SelectedPlatformBackButton />

            {isReady && growers.length !== 0 && (
              <Flex alignItems="center">
                {isSyncInProgress && (
                  <CircularProgress
                    id="integration-agx-progress"
                    className="margin-0 margin-right-5"
                  />
                )}

                {isReady && (
                  <Button
                    disabled={!selectedGrowers.length || isSyncInProgress}
                    raised
                    primary
                    onClick={sync}
                  >
                    {t({id: 'Sync'})}
                  </Button>
                )}
              </Flex>
            )}
          </Sticky>
        </>
      )}

      {showLogin && (
        <EmbedLogin
          id="agx-embed-login"
          visible={true}
          onHide={() => setShowLogin(false)}
          showEvenWhenLoggedIn={true}
        />
      )}

      {/*<ColoredBorder colorStart={'rgb(203, 101, 30)'} colorEnd={'rgba(203, 101, 30, 0.2)'} />*/}
    </div>
  );
};

const authorize = () => window.location.assign(`${config.baseUrl}api/v1/login/agx`);

const selectedEntitiesNumber = (
  list: (AgxGrowerV2 | AgxFarm | AgxField)[],
  selected?: string[]
) => {
  if (!list) {
    return '';
  }
  return ` (${selected?.length || 0}/${list.length})`;
};

export default IntegrationAgx;
