import React from 'react';
import {FluroDialog} from 'components';
import ImportSeasonsForm from '../map/features/farm/table-view/import-seasons';
import {useTranslation} from 'i18n-utils';
import {useAppDispatch} from '_hooks';
import {dialogToggle, DialogType} from 'modules/helpers';

const ImportSeasonsPopUp = () => {
  const dispatch = useAppDispatch();
  const t = useTranslation();

  return (
    <FluroDialog
      id={'import-seasons-pop-up'}
      className={'upload-seasons-pop-up'}
      title={t({id: 'Import crops', defaultMessage: 'Import crops'})}
      visible={true}
      onHide={() => dispatch(dialogToggle(DialogType.importSeasons, false))}
    >
      <ImportSeasonsForm />
    </FluroDialog>
  );
};

export default ImportSeasonsPopUp;
