import type {ChartDataSets} from 'chart.js';

export const getSparklineDataSetDefinition = (numDataSet: number[]): ChartDataSets[] => {
  return [
    {
      borderColor: '#4988EF',
      pointBorderWidth: 1,
      pointRadius: 1,
      fill: 'start',
      data: numDataSet,
      backgroundColor: context => getChartBackgroundGradient(context, '#4988ef'),
    },
  ];
};

export const getChartBackgroundGradient = (
  context: {
    chart?: Chart;
  },
  color: string
) => {
  const chart = context.chart;
  const ctx = chart?.ctx;
  const chartArea = chart?.chartArea;

  const gradient = ctx?.createLinearGradient(0, chartArea?.top ?? 0, 0, chartArea?.bottom ?? 0);
  gradient?.addColorStop(0, color);
  gradient?.addColorStop(0.5, `${color}80`);
  gradient?.addColorStop(1, `${color}00`);
  return gradient ?? 'red';
};
