export const MRV = 'mrv';
export const CARBON = 'carbon';
export const SI = 'sustainability'; // SI v2
export const CI_ROOT_PATH = 'crop-insights'; // crop insights
export const CI_ROOT_PATH_OLD = 'maps'; // crop insights
export const GLOBAL_ADMIN_PATH = `/global-admin`;

export type Config = {
  baseUrl: string;
  authHeader: string;
  env: 'production' | 'development';
  version: number;
  app_version: string;
  branch: string;

  metriks: {
    yMetrika: number;
    gAnalytics: string;
    gtmId?: string;
  };
  captcha: {
    sitekey: string;
    theme: string;
  };
  intercomSettings: {
    app_id: string;
    alignment: string;
  };
  hubspot: {
    portalId: string;
    formGuid: string;
  };

  mp_token?: string;
  mp_token_cargill?: string;
};

const window_ = typeof window !== 'undefined' ? window : ({location: {host: ''}} as Window);

const config: Config = {
  baseUrl: 'https://app.dev.regrow.ag/',
  authHeader: 'x-access-token',
  env: 'development',
  version: 0,
  app_version: '__APP_VERSION__',
  branch: '__BRANCH__',

  metriks: {
    yMetrika: 49021181,
    gAnalytics: 'UA-126135971-1',
  },
  captcha: {
    sitekey: '6LfN0DoUAAAAAIIoVzv6YGdeHOXNjonAaJXCP13a',
    theme: 'light',
  },
  intercomSettings: {
    app_id: 'gvmhgf3m',
    alignment: 'left',
  },
  hubspot: {
    portalId: '4430549',
    formGuid: 'e3f6fe2a-31d1-49ac-b3f4-b4b14c93bf64',
  },
};

//@ts-expect-error - config exists
const c: Config = {...config, ...(window_.FLUROSENSE_CONFIG || {})};

export const isProd = c.env === 'production';
export const isDev = c.env === 'development';
export const isLocal = window_?.location.host.includes('localhost');

export default c;
