/**
 * TODO: this is using hex codes, which is the old way of
 * doing colors. This file is gathering color maps that
 * had been defined elsewhere in the codebase, so the  git history will have to go through those files.
 */

import type {OutcomeKPI, PracticeKPI} from 'containers/si/api/apiTypes';

// moved from containers/si/types.ts
export const outcomeKPIColors: Record<OutcomeKPI, string> = {
  ghg_emission: 'rgb(147, 26, 174)',
  emission_factor: 'rgba(239, 45, 45, 1)',
  ef_book_values: '',
  soc_sequestration: 'rgba(73, 136, 239, 1)',
  yield: 'rgba(57, 157, 43, 1)',
};

export const practiceKPIColors: Record<PracticeKPI, string> = {
  cover_cropping: 'rgba(5, 116, 228, 1)',
  crop_rotation: 'rgba(107, 135, 97, 1)',
  tillage: 'rgba(234, 140, 0, 1)',
  fertilizer_usage: 'rgba(215, 94, 59, 1)',
  living_root: 'rgba(41, 143, 119, 1)',
  lulc: 'rgba(73, 14, 108, 1)',
};

export const SI_COLORS = [
  '#88DA9F',
  '#75CACF',
  '#6391DC',
  '#A571CB',
  '#CC639C',
  '#D05D5D',
  '#E1AA58',
  '#ECE071',
];

// moved from containers/si/constants.ts

export const REPORT_DASHBOARD_COLORS = [
  '#9FD8DC',
  '#A8BCDC',
  '#BE9CD8',
  '#D590B5',
  '#DDB478',
  '#F8EE9B',
  '#ADE2A7',
  '#75CACF',
  '#6391DC',
  '#A571CB',
  '#CC639C',
  '#E1AA58',
  '#ECE071',
  '#85C97D',
];

/**
    Floating point range 0-7 that is mapped to the following buckets:
    7: 0.7-1
    6: 0.6-0.7
    5: 0.5-0.6
    4: 0.4-0.5
    3: 0.25-0.4
    2: 0.0-0.25
    1: <=0.0
    0: NoData
 */
export const NDVI = [
  {range: 'No data', color: '#B96F0E'},
  {range: '<=0.0', color: '#E08918'},
  {range: '0.0-0.25', color: '#E1B558'},
  {range: '0.25-0.4', color: '#ECE071'},
  {range: '0.4-0.5', color: '#7DC9A8'},
  {range: '0.5-0.6', color: '#2B9D81'},
  {range: '0.6-0.7', color: '#147A70'},
  {range: '0.7-1', color: '#147A70'},
];
