// @ts-nocheck
import {t, FormattedMessage} from 'i18n-utils';
import React, {Component} from 'react';
import type {ConnectedProps} from 'react-redux';
import {connect} from 'react-redux';
import moment from 'moment';
import {
  updatePremiumAnomaly,
  updateBulkPremiumAnomaly,
  removePremiumAnomaly,
} from 'containers/map/actions/anomalies-actions';
import {togglePopup} from 'containers/map/actions';
import {ExpansionList, ExpansionPanel} from 'react-md';
import {
  SortButton,
  CustomCheckbox,
  FluroDataTable,
  FluroTableRow,
  FluroTableColumn,
  FluroTableHeader,
  FluroTableBody,
  SortButtonContainer,
} from 'components';
import LabelIcon, {getIcon} from 'components/label-icon';
import {VigorIcon} from 'containers/map/icons';
import AnomalyHeader from 'components/anomalies-ui/anomalies-header';
import AnomalyItem from 'components/anomalies-ui/anomaly-item';
import AnomalyLabelsDropdown from './labels-dropdown';
import {MutedIcon} from '../../icons';
import {sortAnomalies, normalizeSensorIndex} from '_utils';
import {sumAreas, userFacingArea} from './anomalies-utils';
import {getLabelNameByValue} from '_constants';
import type {TSort, SortTypes, IAnomaly, TAnomalyProps} from './types';
import {reportError} from 'containers/error-boundary';
import PermanentlyExcluded from 'components/anomalies-ui/permanently-excluded';
import type {ColumnContentType} from 'components/fluro-table-components/fluro-table-components';
import type {AppStore} from '../../../../reducers';
import {selectIsAdmin, selectMeasurement} from '../../../login/login-selectors';

type State = {
  sortBy: TSort;
};

type Props = ConnectedProps<typeof connector>;

class StressDetectedList extends Component<Props, State> {
  state = {
    sortBy: {
      type: 'priority' as SortTypes,
      order: true,
    },
  };

  render() {
    const {measurement, wholeTableView, isAdmin} = this.props;
    const anomaliesList = this.getSortedAnomalies();
    const tableHeader = this.getTableHeader();
    const tableBody = this.getTableRows(anomaliesList, measurement);
    const isCheckedAll = anomaliesList.every(({properties}) => properties.checked);

    const Header = () => (
      <AnomalyHeader
        isCheckedAll={isCheckedAll}
        title={t(
          {id: 'count stress detected', defaultMessage: '{count} stress detected'},
          {count: anomaliesList.length}
        )}
        subtitle={sumAreas(anomaliesList, measurement)}
        onDelete={isAdmin ? this.onDeletePremiumAnomalies : null}
        isReadOnly={this.props.isReadOnly}
        onSelectAll={this.onSelectAll}
        onChangeLabel={this.onBulkChangeProp}
        onSnooze={() => this.onBulkChangeProp('snoozed')}
        onSort={this.sortTable}
        sortBy={this.state.sortBy}
      />
    );

    if (wholeTableView) {
      return anomaliesList.length ? (
        <>
          {this.getTableHeaderTitle(isCheckedAll)}
          <FluroDataTable baseId={'table-view'} selectableRows={false} className={`anomaly-table`}>
            <FluroTableHeader>{tableHeader}</FluroTableHeader>
            <FluroTableBody>{tableBody}</FluroTableBody>
          </FluroDataTable>
        </>
      ) : null;
    }

    const {lowPerforming, highPerforming, cloggedNozzle} = groupAnomalies(anomaliesList);

    return (
      <>
        <Header />
        <ExpansionList className={'stress-detected-list'}>
          {cloggedNozzle.length > 0 && (
            <ExpansionPanel
              defaultExpanded={true}
              footer={null}
              label={
                <>
                  <span className="clogged-nozzle-icon">{getIcon('irrigation_issue')}</span> Clogged
                  nozzle
                </>
              }
            >
              {cloggedNozzle.map(anomaly => this.getAnomalyItem(anomaly))}
            </ExpansionPanel>
          )}
          {lowPerforming.length > 0 && (
            <ExpansionPanel
              defaultExpanded={true}
              footer={null}
              label={
                <>
                  <VigorIcon type="low_performing" />{' '}
                  {t({id: 'Low vigor', defaultMessage: 'Low vigor'})}
                </>
              }
            >
              {lowPerforming.map(anomaly => this.getAnomalyItem(anomaly))}
            </ExpansionPanel>
          )}
          {highPerforming.length > 0 && (
            <ExpansionPanel
              defaultExpanded={true}
              footer={null}
              label={
                <>
                  <VigorIcon type="high_performing" />{' '}
                  {t({id: 'High vigor', defaultMessage: 'High vigor'})}
                </>
              }
            >
              {highPerforming.map(anomaly => this.getAnomalyItem(anomaly))}
            </ExpansionPanel>
          )}
        </ExpansionList>
      </>
    );
  }

  getCheckedAnomalies = () => {
    return this.props.premiumAnomalies.filter((el: IAnomaly) => el.properties.checked);
  };

  onDeletePremiumAnomalies = () => {
    const checkedAnomaliesIds = this.getCheckedAnomalies().map(
      anomaly => anomaly.properties.anomaly_id
    );
    this.props.removePremiumAnomaly(checkedAnomaliesIds);
  };

  getAnomalyItem = (anomaly: IAnomaly) => {
    const {measurement, isReadOnly, openPopupId, togglePopup} = this.props;
    const {
      area,
      label,
      anomaly_id,
      sensing_date,
      snoozed,
      description,
      checked,
      startDate,
      endDate,
      priority,
      clogged_nozzle_distance,
      exclusion_flag,
    } = anomaly.properties;
    return (
      <AnomalyItem
        label={
          <AnomalyLabelsDropdown
            disabled={isReadOnly}
            anomaly={anomaly}
            label={label}
            miniButton
            onChange={this.onChangeProp}
          />
        }
        distanceToCenter={clogged_nozzle_distance}
        isChecked={checked}
        area={userFacingArea(area, measurement)}
        key={anomaly_id}
        startDate={startDate}
        endDate={endDate}
        sensingDate={sensing_date}
        title={description}
        snoozed={snoozed}
        openPopUp={openPopupId === anomaly_id}
        labelName={label}
        priority={priority}
        onCheck={(value: any) => this.onChangeProp('checked', value, anomaly)}
        onView={() => togglePopup(anomaly_id)}
        excluded={exclusion_flag}
        measurement={measurement}
      />
    );
  };

  getTableHeader = () => {
    const {order, type} = this.state.sortBy;
    const dataTypes: {
      type?: ColumnContentType;
      name: any;
      property: SortTypes;
      sorted?: boolean;
    }[] = [
      {
        name: <FormattedMessage id="[anomaly] Label" defaultMessage="Label" />,
        property: 'label',
        sorted: true,
      },
      {
        name: <FormattedMessage id="Priority" defaultMessage="Priority" />,
        property: 'priority',
        sorted: true,
      },
      {
        type: 'number',
        name: <FormattedMessage id="Area" defaultMessage="Area" />,
        property: 'size',
        sorted: true,
      },
      {
        type: 'date',
        name: <FormattedMessage id="Dates detected" defaultMessage="Dates detected" />,
        property: 'date',
        sorted: true,
      },
      {
        type: 'number',
        name: <FormattedMessage id="Avg NDVI" defaultMessage="Avg NDVI" />,
        property: 'ndvi',
        sorted: true,
      },
      {
        name: <FormattedMessage id="Info" defaultMessage="Info" />,
        property: 'modality',
        sorted: true,
      },
      {name: '', property: 'snoozed'},
    ];

    return (
      <FluroTableRow>
        {dataTypes.map((el, i: number) => (
          <FluroTableColumn type={el.type} key={`${i}element${el.property}`}>
            <SortButtonContainer onClick={() => (el.sorted ? this.sortTable(el.property) : null)}>
              {el.name}
              {el.sorted && (
                <SortButton
                  selected={el.property === type}
                  descending={type === el.property ? order : false}
                />
              )}
            </SortButtonContainer>
          </FluroTableColumn>
        ))}
      </FluroTableRow>
    );
  };

  getTableHeaderTitle = (isChecked: boolean) => {
    return (
      <div className={'anomaly-table-header-title'}>
        <CustomCheckbox isChecked={isChecked} onCheck={this.onSelectAll} />
        <h4 className={'anomaly-table__title'}>
          {t({id: 'Stress detected', defaultMessage: 'Stress detected'})}
        </h4>
      </div>
    );
  };

  getTableRows = (anomalies: IAnomaly[], measurement: string) => {
    return anomalies.map((anomaly, i) => {
      const {
        label,
        area,
        modality,
        snoozed,
        checked,
        startDate,
        endDate,
        priority,
        mean,
        type,
        exclusion_flag,
      } = anomaly.properties;
      return (
        <FluroTableRow
          onClick={() => this.onChangeProp('checked', !checked, anomaly)}
          selected={checked}
          className={`table-row`}
          key={i}
        >
          <FluroTableColumn>
            <div className="label-container">
              {checked ? <LabelIcon label={'done'} /> : <LabelIcon label={label} />}
              <span>{getLabelNameByValue(label)}</span>
            </div>
          </FluroTableColumn>
          <FluroTableColumn>
            <div className={`anomaly-item__type-priority ${priority || ''}`}>{priority}</div>
          </FluroTableColumn>

          <FluroTableColumn type={'number'}>{userFacingArea(area, measurement)}</FluroTableColumn>
          <FluroTableColumn type={'date'}>
            {endDate
              ? `${moment(startDate).format('DD MMM YYYY')} - ${moment(endDate).format(
                  'DD MMM YYYY'
                )}`
              : `${moment(startDate).format('DD MMM YYYY')}`}
          </FluroTableColumn>
          <FluroTableColumn type={'number'}>
            <span className="anomaly-ndvi-value">
              <VigorIcon type={type} />
              {normalizeSensorIndex(mean, this.props.range)}
            </span>
          </FluroTableColumn>
          <FluroTableColumn>{modality}</FluroTableColumn>
          <FluroTableColumn>
            {snoozed ? <MutedIcon className={'snoozed'} /> : null}
            {exclusion_flag ? <PermanentlyExcluded /> : null}
          </FluroTableColumn>
        </FluroTableRow>
      );
    });
  };

  getSortedAnomalies() {
    const {type, order}: {type: string; order: boolean} = this.state.sortBy;
    return sortAnomalies(this.props.premiumAnomalies, type, order);
  }

  onChangeProp = (prop: keyof TAnomalyProps, value: any, anomaly: IAnomaly) => {
    this.props.updatePremiumAnomaly({
      id: anomaly.properties.anomaly_id,
      prop,
      value,
      anomaly,
    });
  };

  onBulkChangeProp = (prop: keyof TAnomalyProps, val?: any) => {
    const {updateBulkPremiumAnomaly} = this.props;
    const checkedAnomalies = this.getCheckedAnomalies();
    const value =
      prop === 'snoozed' ? (checkedAnomalies.every(a => a.properties.snoozed) ? 0 : 1) : val;
    updateBulkPremiumAnomaly({
      anomalies: checkedAnomalies,
      prop,
      value,
    });
  };

  onSelectAll = (value: any) => {
    this.props.updateBulkPremiumAnomaly({
      anomalies: this.getSortedAnomalies(),
      prop: 'checked',
      value,
    });
  };

  sortTable = (val: SortTypes, order?: boolean) => {
    this.setState({sortBy: {type: val, order: order ? order : !this.state.sortBy.order}});
  };
}

const groupAnomalies = (anomalies: IAnomaly[]) => {
  const lowPerforming: IAnomaly[] = [];
  const highPerforming: IAnomaly[] = [];
  const cloggedNozzle: IAnomaly[] = [];

  anomalies.forEach(a => {
    switch (a.properties.type) {
      case 'low_performing':
        lowPerforming.push(a);
        break;
      case 'high_performing':
      //@ts-expect-error error leftover from convertion to strict mode, please fix
      case 'Not Good':
        highPerforming.push(a);
        break;
      default:
        // For clogged nozzle anomalies `type` is an empty string.
        if (a.properties.clogged_nozzle) {
          cloggedNozzle.push(a);
          return;
        }
        const msg = [
          `Unsupported anomaly type ${a.properties.type}`,
          `Farm id: ${a.properties.group_id}`,
          `Field id: ${a.properties.kml_id}`,
          `Anomaly id: ${a.properties.anomaly_id}`,
        ].join('\n');
        reportError(msg);
    }
  });
  return {lowPerforming, highPerforming, cloggedNozzle};
};

const mapStateToProps = (state: AppStore) => ({
  measurement: selectMeasurement(state),
  premiumAnomalies: state.map.premiumAnomalies.list,
  isReadOnly: state.map.group.readOnly,
  wholeTableView: state.map.wholeTableViewOpen,
  range: state.map.histogram.range,
  openPopupId: state.map.openPopupId,
  isAdmin: selectIsAdmin(state),
});

const connector = connect(mapStateToProps, {
  updatePremiumAnomaly,
  updateBulkPremiumAnomaly,
  togglePopup,
  removePremiumAnomaly,
});

export default connector(StressDetectedList);
