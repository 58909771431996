import React, {Component} from 'react';
import {connect} from 'react-redux';
import {MAPPING_ROLES_TO_PROPS} from '_constants';

class Access extends Component {
  render() {
    // simple user by default
    let ac = MAPPING_ROLES_TO_PROPS['3'];
    let userRoleId = this.props.user.perm;

    Object.keys(MAPPING_ROLES_TO_PROPS).forEach(perm => {
      if (this.props[MAPPING_ROLES_TO_PROPS[perm].prop]) {
        ac = MAPPING_ROLES_TO_PROPS[perm];
        return false;
      }
    });

    const Component = this.props.notAllowedComponent;

    // checking permissions (TODO: will add more smart checking)
    return userRoleId >= ac.access ? (
      typeof this.props.children === 'object' && !Array.isArray(this.props.children) ? ( // checking if single react component
        this.props.children
      ) : this.props.children ? (
        this.props.children
      ) : null
    ) : typeof Component === 'function' ? (
      <Component />
    ) : null;
  }
}

const mapStateToProps = state => ({
  user: state.login.user,
});

export default connect(mapStateToProps, {})(Access);
