// @ts-nocheck
import type {ComponentType} from 'react';
import React, {useCallback} from 'react';
import {FluroButton, FluroDialog, Text} from 'components';
import {useAppDispatch, useAppSelector} from '_hooks';
import {selectDialogVisibility} from 'modules/helpers/selectors';
import {dialogToggle, DialogType} from 'modules/helpers';
import './fms-logged-out-dialog.scss';
import {t} from 'i18n-utils';

export const FMSLoggedOutDialog: ComponentType = () => {
  const dispatch = useAppDispatch();
  const visible = useAppSelector(s => selectDialogVisibility(s, DialogType.fmsSyncLoggedOutDialog));

  const onHide = useCallback(
    () => dispatch(dialogToggle(DialogType.fmsSyncLoggedOutDialog, false)),
    []
  );

  return (
    <FluroDialog
      id={'fms-sync-logged-out-dialog'}
      title={t({id: 'Logged out of FMS'})}
      dialogClassName={'fms-sync-logged-out-dialog'}
      onHide={onHide}
      visible={visible}
      width={440}
    >
      <Text>
        {t({
          id: 'Fms.Sync.SuccessLoggedOutMessage',
          defaultMessage:
            'You have successfully logged out of your Farm Management System. We will not be able to reimport automatically your data until you log back in. Please manually enter your practice details.',
        })}
      </Text>

      <div className="action-container">
        <FluroButton className={'margin-top-5'} raised blank onClick={onHide}>
          {t({id: 'BtnLabel.Close'})}
        </FluroButton>
      </div>
    </FluroDialog>
  );
};
