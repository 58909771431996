import React, {useState} from 'react';
import {FontIcon} from 'react-md';
import {Flex, FluroButton, FluroSelect} from 'components';
import {FluroInput} from 'components/fluro-form-components';
import {useAppSelector} from '_hooks';
import {classifyAreaWithUnits} from '_utils';
import {calcPlural, genKey} from '_utils/pure-utils';
import {selectMeasurement} from 'containers/login/login-selectors';
import {selectNewReportType} from 'containers/si/module/selectors';
import {CropSelect} from './crop-select';
import type {
  CropUnits,
  ReportSelectCropsType,
  ReportSelectedCommodities,
} from 'containers/si/types';
import {PLANNING_REPORT_VOLUME_OPTIONS} from 'containers/si/constants';
import {SIHeaderColor} from 'containers/si/si-styled-components';
import '../create-report.scss';

type SelectCropsCardProps = {
  supplyShedId: number;
  areaId: number;
  onSelectSupplyShedCrops: (
    supplyShedId: number,
    unitId: number,
    unitType: 'supplyShed' | 'subsection',
    commodities: ReportSelectedCommodities[]
  ) => void;
  color: string;
  name: string;
  areaHa: number;
  areaType: 'supplyShed' | 'subsection';
  selectedCommodities: ReportSelectedCommodities[];
  availableCommodities: number[];
};

export const SelectCropsCard = ({
  supplyShedId,
  areaId,
  onSelectSupplyShedCrops,
  color,
  name,
  areaHa,
  areaType,
  selectedCommodities,
  availableCommodities,
}: SelectCropsCardProps) => {
  const reportType = useAppSelector(selectNewReportType);
  const measurement = useAppSelector(selectMeasurement);
  const [cropCardVisible, setCropCardVisible] = useState(true);

  const [selectedCrops, setSelectedCrops] = useState<ReportSelectCropsType[]>(
    selectedCommodities.map(c => ({
      key: genKey(),
      label: c?.label || '',
      type: c?.type || '',
      volume_units: c?.volume_units || ('' as CropUnits),
      value: String(c?.id) || '',
      volume: c?.volume || 0,
    }))
  );

  const onChangeCrops = (rowKey: string, value: string, label: string, type: string) => {
    const updatedCrops = selectedCrops.map(c =>
      c.key === rowKey ? {...c, value, label, type} : c
    );
    setSelectedCrops(updatedCrops);
    updateCrops(updatedCrops);
  };

  const onChangeVolume = (rowKey: string, volume: number) => {
    const updatedCrops = selectedCrops.map(c => (c.key === rowKey ? {...c, volume} : c));
    setSelectedCrops(updatedCrops);
    updateCrops(updatedCrops);
  };

  const onChangeVolumeUnits = (rowKey: string, volume_units: CropUnits) => {
    const updatedCrops = selectedCrops.map(c =>
      c.key === rowKey ? {...c, volume_units: volume_units as CropUnits} : c
    );
    setSelectedCrops(updatedCrops);
    updateCrops(updatedCrops);
  };

  const onDeleteCropRow = (rowKey: string) => {
    const updatedCrops = selectedCrops.filter(c => c.key !== rowKey);
    setSelectedCrops(updatedCrops);
    updateCrops(updatedCrops);
  };

  const updateCrops = (updatedCrops: ReportSelectCropsType[]) => {
    const commodities = updatedCrops.map(crop => {
      return {
        id: Number(crop?.value),
        label: crop?.label,
        type: crop?.type,
        volume_units: crop?.volume_units,
        volume: crop?.volume,
      };
    });
    onSelectSupplyShedCrops(supplyShedId, areaId, areaType, commodities);
  };

  return (
    <div className="selected-crops-card margin-bottom-16">
      <div
        className="selected-crops-card-header"
        onClick={() => {
          setCropCardVisible(!cropCardVisible);
        }}
      >
        <SIHeaderColor headerColor={color} />
        <Flex direction="row" alignItems="center">
          <FontIcon forceSize={35} forceFontSize>
            {cropCardVisible ? 'expand_more' : 'chevron_right'}
          </FontIcon>
          <div className="selected-crops-card-name">{name}</div>
        </Flex>
        <Flex direction="row" alignItems="center">
          <div className="selected-crops-card-area mr-2">
            {selectedCrops.length} {calcPlural('crop', selectedCrops.length)}
          </div>
          <div className="selected-crops-card-area">
            {classifyAreaWithUnits(Math.round(areaHa), measurement)}
          </div>
        </Flex>
      </div>
      {cropCardVisible && (
        <div className="selected-crops-container">
          {selectedCrops.map(row => (
            <Flex
              className="selected-crops-row"
              direction="row"
              alignItems="center"
              nowrap
              key={row.key}
              justifyContent="space-between"
            >
              <Flex direction="row" alignItems="center" nowrap className="crop-selections">
                <div className="crop-select">
                  <CropSelect
                    cropValue={row.value}
                    onChange={(value, label, type) => onChangeCrops(row.key, value, label, type)}
                    availableCommodities={availableCommodities}
                  />
                </div>
                {reportType === 'abatement_potential' && (
                  <>
                    <FluroInput
                      type="number"
                      id="crop-volume"
                      name="crop-volume"
                      value={row.volume || 0}
                      className="volume-input"
                      onChange={vol => onChangeVolume(row.key, Number(vol))}
                      placeholder="Enter sourcing volume (optional)"
                    />
                    <FluroSelect
                      value={row.volume_units || 'lb'}
                      onChange={volume_units =>
                        onChangeVolumeUnits(row.key, volume_units as CropUnits)
                      }
                      options={Object.entries(PLANNING_REPORT_VOLUME_OPTIONS).map(
                        ([key, value]) => ({
                          label: value,
                          value: key,
                        })
                      )}
                      className="unit-select"
                    />
                  </>
                )}
              </Flex>
              <FluroButton
                icon
                iconEl={<FontIcon>delete</FontIcon>}
                onClick={e => {
                  onDeleteCropRow(row.key);
                  e.stopPropagation();
                }}
                tooltipPosition={'left'}
                tooltipLabel={'Remove crop'}
                tooltipStyle={{left: '-20px'}}
              />
            </Flex>
          ))}
        </div>
      )}
      <FluroButton
        raised
        blank
        className="add-crop-button"
        onClick={() =>
          setSelectedCrops([
            ...selectedCrops,
            {value: '', label: '', type: '', volume: 0, key: genKey(), volume_units: 'lb'},
          ])
        }
      >
        + Crop
      </FluroButton>
    </div>
  );
};
