import React from 'react';
import {Redirect, Route, Switch} from 'react-router-dom';
import {SI_HOME, SI_PROFILE, SI_GLOBAL_ADMIN, SI_FEATURE_GROUPS} from './routes';
import {PrivateRoute} from 'containers';
import styled from 'styled-components';
import {FeatureGroupsEntry} from 'containers/si/feature-groups/feature-groups-entry';
import {SIHome} from 'containers/si/home/si-home';
import {fetchSIPrograms} from 'containers/si/module/thunks';
import {useAppDispatch, useAppSelector} from '_hooks';
import {selectIsLoading, selectIsSucceed} from 'modules/helpers/selectors';
import {ActionType} from 'containers/si/module/types';
import {selectIsAuth} from 'containers/login/login-selectors';
import {RedirectFromProfilePageToProfileDialog} from 'containers/profile';
import {MENUBAR_HEIGHT} from 'containers/menubar/menubar-constants';

const SIEntry = () => {
  const dispatch = useAppDispatch();
  const isAuth = useAppSelector(selectIsAuth);

  const programsFetched = useAppSelector(s =>
    selectIsSucceed(s, [ActionType.FETCH_ALL_SI_PROGRAMS])
  );
  const programsLoading = useAppSelector(s =>
    selectIsLoading(s, [ActionType.FETCH_ALL_SI_PROGRAMS])
  );

  React.useEffect(() => {
    if (isAuth && !programsFetched && !programsLoading) {
      // This should only fetch the programs that the user has access to
      dispatch(fetchSIPrograms());
    }
  }, [programsFetched, programsLoading, dispatch, isAuth]);

  return (
    <SIGlobalContainer>
      <Switch>
        <PrivateRoute path={SI_FEATURE_GROUPS} component={FeatureGroupsEntry} />
        <PrivateRoute path={SI_HOME} component={SIHome} />

        <PrivateRoute path={SI_PROFILE} component={RedirectFromProfilePageToProfileDialog} />

        <PrivateRoute
          isAdminOnly
          path={SI_GLOBAL_ADMIN}
          component={React.lazy(() => import('containers/admin'))}
        />

        <Route component={() => <Redirect to={SI_HOME} />} />
      </Switch>
    </SIGlobalContainer>
  );
};

const SIGlobalContainer = styled.div`
  margin-top: ${MENUBAR_HEIGHT}px;
  position: relative;
  display: flex;
  height: max-content;
  min-height: calc(100% - ${MENUBAR_HEIGHT}px);
  flex-direction: column;
  background-color: ${({theme}) => theme.color.surface.secondary};

  .second-level-menu-tabs {
    margin: 0;
    background-color: white;
  }
  .admin-panel {
    padding-top: 0;
    height: calc(100vh - ${MENUBAR_HEIGHT}px);
  }

  .map-v2__container {
    top: 45px; // 45px as secondary nav bar's height
  }

  .configure-back-btn {
    width: 100px;
    margin-top: 16px;
    padding-top: 12px;
    color: ${props => props.theme.color.text.main} !important;
    border-color: ${props => props.theme.color.stroke.strong} !important;

    .md-icon-text {
      line-height: 1;
      padding-left: 0px;
      text-transform: uppercase;
    }
  }
`;

export default SIEntry;
