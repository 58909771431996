// @ts-nocheck
import type {ComponentType} from 'react';
import React, {useEffect, useMemo} from 'react';
import {GeoJSON} from 'react-leaflet';
import type {Feature} from 'geojson';
import {useAppDispatch, useAppSelector} from '_hooks';
import {
  selectCurrentField,
  selectCurrentFieldId,
  selectCurrentFieldKml,
  selectCurrentTab,
  selectIsWholeFarmView,
} from '../reducer/selectors';
import {toggleFieldKml} from '../actions';
import {useSelectCurrentFieldLayerImageUrl} from '../selectors';
import {selectIsNRxEnabled} from '../features/nrx/nrx-selectors';

const onFeature = (feature: Feature, layer: any) => {
  if (layer) {
    layer.__fluroLayerToEdit = true;
    layer.setStyle({fillColor: 'transparent', zIndex: 0});

    layer.on('pm:edit', () => {
      layer.__fluroLayerEdited = true;
    });
  }
};

type Props = {
  shouldCheckFieldKmlVisibility: boolean;
  toggleShouldCheckFieldKmlVisibility: (value: boolean) => void;
};

export const KmlFieldOverlay: ComponentType<Props> = ({
  shouldCheckFieldKmlVisibility,
  toggleShouldCheckFieldKmlVisibility,
}) => {
  const dispatch = useAppDispatch();

  const currentFieldKml = useAppSelector(selectCurrentFieldKml);
  const selectedFieldId = useAppSelector(selectCurrentFieldId);
  const visibleFieldKml = useAppSelector(s => s.map.visibleFieldKml);
  const soilMapLayer = useAppSelector(s => s.map.soilMapLayer);
  const field = useAppSelector(selectCurrentField);
  const feature = useAppSelector(selectCurrentTab);
  const isWholeFarmView = useAppSelector(selectIsWholeFarmView);
  const {selectCurrentFieldDefaultImageUrl} = useSelectCurrentFieldLayerImageUrl();
  const currentImageUrl = selectCurrentFieldDefaultImageUrl();
  const nrxEnabled = useAppSelector(selectIsNRxEnabled);

  useEffect(() => {
    if (!shouldCheckFieldKmlVisibility) return null;
    toggleShouldCheckFieldKmlVisibility(false);

    if (selectedFieldId && !visibleFieldKml && currentFieldKml?.type && !currentImageUrl) {
      dispatch(toggleFieldKml(true));
    }
  }, [shouldCheckFieldKmlVisibility]);

  useEffect(() => {
    if (feature === 'zoning' && nrxEnabled && !visibleFieldKml) {
      dispatch(toggleFieldKml(true));
    }
  }, [nrxEnabled, visibleFieldKml, feature]);

  const preparedData = useMemo(
    () =>
      Array.isArray(currentFieldKml)
        ? currentFieldKml
        : currentFieldKml.type
        ? {...currentFieldKml, fluroMainLayer: true}
        : {
            type: 'FeatureCollection',
            features: [],
          },
    [currentFieldKml]
  );

  if (
    !selectedFieldId ||
    !visibleFieldKml ||
    // hide the field kml when soil map is visible
    (soilMapLayer && field.soilLayer) ||
    feature === 'sustainability-insights' ||
    // For whole farm and for crop performance we show WholeFarmOverlay.
    isWholeFarmView ||
    feature === 'crop-performance'
  ) {
    return null;
  }

  return (
    <GeoJSON
      key={Math.random()}
      style={{className: 'field-kml'}}
      data={preparedData}
      onEachFeature={onFeature}
    />
  );
};

export default KmlFieldOverlay;
