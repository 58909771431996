import React, {useEffect, useState} from 'react';
import moment from 'moment';
import {formatDate} from '_utils';
import {applyPathParams} from '_utils/pure-utils';
import {useAppDispatch, useAppSelector} from '_hooks';
import {useParsedMatchParams} from '_hooks/use-parsed-match-params';
import {
  Flex,
  FluroButton,
  FluroDataTable,
  FluroTableBody,
  FluroTableColumn,
  FluroTableHeader,
  FluroTableRow,
  Text,
} from 'components';
import {showNotification} from 'components/notification/notification';
import {CircularProgress, FontIcon, ListItem, MenuButtonColumn} from 'react-md';
import {push} from 'connected-react-router';
import {selectIsAdmin} from '../../login/login-selectors';
import {setCurrentProgram} from 'containers/si/module/reducer';
import {selectIsLoading} from 'modules/helpers/selectors';
import {selectPlanningReportsListByProgramId} from 'containers/si/module/selectors';
import {
  fetchPlanningReports,
  updatePlanningReport,
  updatePlanningReportURL,
} from 'containers/si/module/thunks';
import type {PlanningReport} from 'containers/si/types';
import {SIReportTypeLabel, SIReportModelLabel} from 'containers/si/constants';
import {ActionType} from 'containers/si/module/types';
import {SI_REPORT_DASHBOARD} from 'containers/si/routes';
import {ReportNamePopup} from './dialogs/report-name-popup';
import {ReportURLPopup} from './dialogs/report-url-popup';
import {ReportSummary} from './report/report-summary';
import SIApi from 'containers/si/api/si';

export const PlanningReportTable = () => {
  const dispatch = useAppDispatch();
  const {programId} = useParsedMatchParams();
  const reports = useAppSelector(s => selectPlanningReportsListByProgramId(s, programId));

  const [renameReportVisible, setRenameReportVisible] = useState(false);
  const [reportSummaryVisible, setReportSummaryVisible] = useState(false);
  const [reportURLPopupVisible, setReportURLPopupVisible] = useState(false);
  const [reportSummary, setReportSummary] = useState<PlanningReport>();
  const [updateReport, setUpdateReport] = useState<{id: number; name: string}>({id: 0, name: ''});
  const [updateURL, setUpdateURL] = useState<{id: number; url: string}>({id: 0, url: ''});
  const isAdmin = useAppSelector(selectIsAdmin);

  useEffect(() => {
    dispatch(setCurrentProgram(programId));
  }, [dispatch, programId]);

  const isLoadingReports = useAppSelector(s =>
    selectIsLoading(s, [ActionType.FETCH_PLANNING_REPORTS])
  );

  const handleRenameReport = (newReportName: string) => {
    dispatch(
      updatePlanningReport({
        programId,
        reportId: updateReport.id,
        report: {name: newReportName},
      })
    );
    setRenameReportVisible(false);
    setUpdateReport({id: 0, name: ''});
  };

  const handleReportURL = (newURL: string) => {
    dispatch(
      updatePlanningReportURL({
        programId,
        reportId: updateURL.id,
        reportURL: newURL,
      })
    );
    setReportURLPopupVisible(false);
    setUpdateURL({id: 0, url: ''});
  };

  const handleDeleteReport = async (reportId: number, reportName: string) => {
    const confirm = window.confirm(`Are you sure you want to delete the "${reportName}" report?`);

    if (confirm) {
      SIApi.deletePlanningReport(programId, reportId)
        .then(() => {
          dispatch(fetchPlanningReports(programId));
          showNotification({
            title: 'Success',
            message: `The "${reportName}" report was deleted.`,
            type: 'success',
          });
        })
        .catch(() => {
          showNotification({
            type: 'error',
            title: 'Error',
            message: `The "${reportName}" report was not deleted.`,
          });
        });
    }
  };

  // This function needed to be added to every FluroColumn instead of just the Row since the MenuButton is at the end of the row
  // The MenuButton was not playing nice with the onClick to go to the report.
  // With the combination of needing e.stopPropagation() and needing to write a custom click outside handler, and handle menu visibility, it was easier to just add it to every column
  const handleRowClick = (reportId: number) => {
    dispatch(
      push(
        applyPathParams(SI_REPORT_DASHBOARD, {
          programId: programId,
          reportId: reportId,
        })
      )
    );
  };

  return (
    <div>
      {reports.length > 0 && (
        <>
          <FluroDataTable>
            <FluroTableHeader>
              <FluroTableRow>
                <FluroTableColumn>Report Name</FluroTableColumn>
                <FluroTableColumn>Type</FluroTableColumn>
                <FluroTableColumn>Created </FluroTableColumn>
                <FluroTableColumn>Model</FluroTableColumn>
                <FluroTableColumn>Report Years</FluroTableColumn>
                <FluroTableColumn>{/* Action buttons */}</FluroTableColumn>
              </FluroTableRow>
            </FluroTableHeader>
            <FluroTableBody>
              {reports.map(r => {
                return (
                  <FluroTableRow key={r.id}>
                    <FluroTableColumn
                      onClick={() => handleRowClick(r.id)}
                      className="cursor-pointer"
                    >
                      <Flex alignItems="center">
                        {r.name}
                        {isAdmin ? (
                          <Text inline className="margin-left-8" tertiary variant="small-thin">
                            (#{r.id})
                          </Text>
                        ) : (
                          ' '
                        )}
                      </Flex>
                    </FluroTableColumn>
                    <FluroTableColumn
                      onClick={() => handleRowClick(r.id)}
                      className="cursor-pointer"
                    >
                      {SIReportTypeLabel[r.report_type]}
                    </FluroTableColumn>
                    <FluroTableColumn
                      onClick={() => handleRowClick(r.id)}
                      className="cursor-pointer"
                    >
                      {moment(r.created_at).format(formatDate())}
                    </FluroTableColumn>
                    <FluroTableColumn
                      onClick={() => handleRowClick(r.id)}
                      className="cursor-pointer"
                    >
                      {r?.model ? SIReportModelLabel[r.model] : ''}
                    </FluroTableColumn>
                    <FluroTableColumn
                      onClick={() => handleRowClick(r.id)}
                      className="cursor-pointer"
                    >
                      {r.start_year} - {r.end_year}
                    </FluroTableColumn>
                    <FluroTableColumn type={'number'}>
                      <Flex gap="10px" nowrap justifyContent={'flex-end'} alignItems={'center'}>
                        <FluroButton
                          primary
                          raised
                          onClick={() => {
                            dispatch(
                              push(
                                applyPathParams(SI_REPORT_DASHBOARD, {
                                  programId: programId,
                                  reportId: r.id,
                                })
                              )
                            );
                          }}
                        >
                          View Report
                        </FluroButton>
                        <MenuButtonColumn
                          id="planning-report-menu"
                          icon
                          className="planning-report-menu"
                          menuItems={[
                            <ListItem
                              key={1}
                              onClick={() => {
                                setReportSummary(r);
                                setReportSummaryVisible(true);
                              }}
                              leftIcon={<FontIcon>info</FontIcon>}
                              primaryText="View Request Summary"
                            />,
                            isAdmin && (
                              <ListItem
                                key={2}
                                leftIcon={<FontIcon>edit</FontIcon>}
                                onClick={e => {
                                  e.stopPropagation();
                                  setUpdateReport({id: r?.id, name: r?.name});
                                  setRenameReportVisible(true);
                                }}
                                primaryText={'Rename Report'}
                              />
                            ),
                            isAdmin && (
                              <ListItem
                                key={3}
                                leftIcon={<FontIcon>delete</FontIcon>}
                                onClick={e => {
                                  e.stopPropagation();
                                  handleDeleteReport(r.id, r.name);
                                }}
                                primaryText={'Delete Report'}
                              />
                            ),
                          ]}
                        >
                          <FontIcon inherit>more_vert</FontIcon>
                        </MenuButtonColumn>
                      </Flex>
                    </FluroTableColumn>
                  </FluroTableRow>
                );
              })}
            </FluroTableBody>
          </FluroDataTable>
        </>
      )}
      {isLoadingReports ? (
        <CircularProgress className={'progress'} id={'si-reports-table'} />
      ) : reports.length === 0 ? (
        'No reports found, please create a new report to get started.'
      ) : null}
      {renameReportVisible && (
        <ReportNamePopup
          onHide={() => setRenameReportVisible(false)}
          name={updateReport.name}
          onSave={handleRenameReport}
        />
      )}
      {reportURLPopupVisible && (
        <ReportURLPopup
          onHide={() => setReportURLPopupVisible(false)}
          url={updateURL.url}
          onSave={handleReportURL}
        />
      )}
      {reportSummaryVisible && reportSummary && (
        <ReportSummary onHide={() => setReportSummaryVisible(false)} report={reportSummary} />
      )}
    </div>
  );
};
