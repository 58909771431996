// @ts-nocheck
import moment from 'moment';
import type {AppStore} from 'reducers';
import {FieldSystemProp} from 'containers/map/features/farm/new-fields/types';
import {disableEdit} from 'containers/map/utils/draw';
import {getFarmById} from '_utils';
import {chunkArray, removeDuplicates} from '_utils/pure-utils';
import {FarmApi, KmlApi} from '_api';
import {t} from 'i18n-utils';
import type {AxiosResponse} from 'axios';
import axios from 'axios';
import {AsyncStatusType, dialogToggle, DialogType, setRequestStatus, Status} from 'modules/helpers';
import {showNotification} from 'components/notification/notification';
import {parseGeometryFile} from 'containers/map/utils/file';
import {
  enhanceGeometryProperties,
  getNonPolygonMessage,
  handleFeatureCollection,
  pointInFrance,
  pointInUS,
} from '_utils/geometry';
import {reportError} from 'containers/error-boundary';
import {saveFarm, selectFarm} from 'modules/farms/actions';
import {selectFarms, selectFarmsList} from 'modules/farms/selectors';
import {selectIsAdmin} from 'containers/login/login-selectors';
import type {Farm, Field} from 'containers/map/types';
import tokml from '_utils/tokml';
import {loadFields, saveFields, updateFieldGeometries} from 'containers/map/actions';
import convert from 'convert-units';
import type {SaveFieldsRequest} from '_api/farm';
import {featureCollection, area as turfArea} from '@turf/turf';
import type {Skeleton} from 'skeleton';
import type {AppDispatch} from 'store';
import {selectDialogVisibility} from '../helpers/selectors';
import {selectCurrentFarmId} from 'containers/map/reducer/selectors';

import {createSelector} from 'reselect';
import {enrollFields} from 'containers/mrv/enrollment/carbon-store';
import type {
  AddFieldStep,
  AddNewFieldsStore,
  CLUFieldBoundary,
  CLUFieldBoundaryProps,
  FieldPropsMapping,
} from './types';
import {
  selectAddingFieldsStep,
  selectBoundariesToUpload,
  selectCLUFieldBoundariesIds,
  selectCLUFieldBoundariesList,
  selectGeoJsonFiles,
} from './selectors';
import {fetchPadusProtectedAreaIntersections} from './thunks';
import {CoordinateSystem} from 'containers/mrv/types';
import {checkWorkspace} from 'containers/admin/users/types';

enum ActionType {
  SetFieldPropsMapping = '@add-fields/mapping/set',
  SetFieldPropMapping = '@add-fields/mapping/prop/set',
  SetUploadFields = '@add-fields/upload-fields/set',
  RemoveUploadFields = '@add-fields/upload-fields/remove',
  SetNotUploadFields = '@add-fields/not-uploaded-fields/set',
  SetUploadedFieldFilesNames = '@add-fields/uploaded-field-files/set',
  SetFarmsFieldsAddedTo = '@add-fields/farms-fields-added-to/set',
  SetUploadedFieldsNumber = '@add-fields/uploaded-fields-number/set',
  SetUploadFieldProp = '@add-fields/upload-fields-property/set',
  BulkSetUploadFieldsProp = '@add-fields/upload-fields-property/bulk-set',
  UpdateIdsUploadFields = '@add-fields/upload-field-ids/update',
  SetUploadedFieldsValueFromMappingProps = '@add-fields/upload-fields-props-from-papping/set',
  AddDrawnFieldGeometry = '@add-fields/drawn-field-geometry/add',
  RemoveDrawnFieldGeometry = '@add-fields/drawn-field-geometry/remove',
  SetPropDrawnFieldGeometry = '@add-fields/prop-drawn-field-geometry/set',
  BulkSetPropDrawnFieldGeometry = '@add-fields/bulk-prop-drawn-field-geometry/set',
  SetAddFieldCurrentStep = '@add-fields/change-current-step',
  SetCLUFieldBoundaries = '@add-fields/CLU-field-boundaries/set',
  RemoveCLUFieldBoundaries = '@add-fields/CLU-field-boundaries/remove',
  UpdateCLUFieldBoundariesProp = '@add-fields/CLU-field-boundary/update-prop',
  BulkUpdateCLUFieldBoundariesProp = '@add-fields/CLU-field-boundaries/bulk-update-prop',
  SetBoundaryIdsToUpload = '@add-fields/boundary-ids-to-upload/set',
  ToggleBoundaryIdToUpload = '@add-fields/boundary-id-to-upload/toggle',
  FetchProtectedAreas = '@add-fields/protected-areas/fetch',
  AddProtectedAreas = '@add-fields/protected-areas/add',
}

export const AddingFieldsZoomThreshold = 13.5;
export const FoundLocationZoomLevel = 16;

const initialState: AddNewFieldsStore = {
  propsMapping: {farmName: '__currentFarm__', fieldName: FieldSystemProp.FileName, growerName: ''},
  geoJsonFiles: [],
  notUploadedFields: [],
  uploadedFieldFilesNames: [],
  farmsFieldsAddedTo: [],
  uploadedFieldsNumber: 0,
  drawnFieldsGeometries: [],
  addFieldCurrentStep: 'add-fields',
  cluFieldBoundaries: {},
  protectedAreaBoundaries: {},
  boundaryIdsToUpload: [],
};

export const setFieldPropMapping = (propName: string, value: string) => ({
  type: ActionType.SetFieldPropMapping,
  propName,
  value,
});

export const setFieldPropsMapping = (mapping: FieldPropsMapping) => ({
  type: ActionType.SetFieldPropsMapping,
  mapping,
});

export const setUploadFields = (fields: GeoJSON.Feature[]) => ({
  type: ActionType.SetUploadFields,
  fields,
});

export const removeUploadFields = (ids: string[]) => ({
  type: ActionType.RemoveUploadFields,
  ids,
});

export const setNotUploadedFields = (fieldNames: string[]) => ({
  type: ActionType.SetNotUploadFields,
  fieldNames,
});

export const setUploadedFieldFilesNames = (names: string[]) => ({
  type: ActionType.SetUploadedFieldFilesNames,
  names,
});

export const setFarmsFieldsAddedTo = (farms: {id: number; name: string; isNew?: boolean}[]) => ({
  type: ActionType.SetFarmsFieldsAddedTo,
  farms,
});

export const setUploadedFieldsNumber = (fieldsNumber: number) => ({
  type: ActionType.SetUploadedFieldsNumber,
  fieldsNumber,
});

export const setUploadFieldProp = (id: string | number, prop: string, value: any) => ({
  type: ActionType.SetUploadFieldProp,
  id,
  prop,
  value,
});

export const bulkSetUploadFieldsProp = (ids: string[] | number[], prop: string, value: any) => ({
  type: ActionType.BulkSetUploadFieldsProp,
  ids,
  prop,
  value,
});

export const setFieldsValueFromMappingProps =
  (mappingProp: 'fieldName' | 'farmName') => (dispatch: AppDispatch, getState: () => AppStore) => {
    const farmsList = selectFarmsList(getState());
    dispatch({
      type: ActionType.SetUploadedFieldsValueFromMappingProps,
      mappingProp,
      farmsList,
    });
  };

export const addDrawnFieldGeometry =
  (geoJSON: any) => (dispatch: AppDispatch, getState: () => AppStore) => {
    const currentFarmId = selectCurrentFarmId(getState());
    geoJSON.properties[FieldSystemProp.Area] = convert(turfArea(geoJSON)).from('m2').to('ha');
    geoJSON.properties[FieldSystemProp.Checked] = true;
    geoJSON.properties[FieldSystemProp.FarmId] = currentFarmId || 0;

    dispatch({
      type: ActionType.AddDrawnFieldGeometry,
      geoJSON,
    });
  };

export const removeDrawnFieldsGeometry =
  (ids?: string[]) => (dispatch: any, getState: () => AppStore) => {
    disableEdit();
    //@ts-expect-error error leftover from convertion to strict mode, please fix
    if (window.leafletElement) {
      const drawnFieldsGeometries = selectDrawnFieldGeometries(getState());
      const idsToRemove = ids || drawnFieldsGeometries.map(f => f.properties[FieldSystemProp.Id]);
      //@ts-expect-error error leftover from convertion to strict mode, please fix
      window.leafletElement.eachLayer(l => {
        if (idsToRemove.includes(l.fluroGeometryID))
          //@ts-expect-error error leftover from convertion to strict mode, please fix
          window.leafletElement.removeLayer(l);
      });
    }
    dispatch({
      type: ActionType.RemoveDrawnFieldGeometry,
      ids,
    });
  };

export const removeUnselectedDrawnGeometries = () => (dispatch: any, getState: () => AppStore) => {
  const drawnGeometries = selectDrawnFieldGeometries(getState());

  const geometriesToRemove = drawnGeometries.filter(
    geometry => !geometry.properties[FieldSystemProp.Checked]
  );

  if (!geometriesToRemove.length) return;

  dispatch(
    removeDrawnFieldsGeometry(
      geometriesToRemove.map(geometry => geometry.properties[FieldSystemProp.Id])
    )
  );
};

export const setPropDrawnFieldGeometry = (id: string, prop: any, value: any) => ({
  type: ActionType.SetPropDrawnFieldGeometry,
  id,
  prop,
  value,
});

export const bulkSetPropDrawnFieldGeometry = (ids: string[], prop: any, value: any) => ({
  type: ActionType.BulkSetPropDrawnFieldGeometry,
  ids,
  prop,
  value,
});

export const setAddFieldCurrentStep =
  (step: AddFieldStep) => (dispatch: any, getState: () => AppStore) => {
    if (getState().addFields.addFieldCurrentStep === step) return;
    dispatch({
      type: ActionType.SetAddFieldCurrentStep,
      step,
    });
  };

export const removeCLUFieldBoundaries = (ids: string[]) => ({
  type: ActionType.RemoveCLUFieldBoundaries,
  ids,
});

export const addCLUFieldBoundaries = (
  fieldBoundaries: AddNewFieldsStore['cluFieldBoundaries']
) => ({
  type: ActionType.SetCLUFieldBoundaries,
  fieldBoundaries,
});

export const clearCLUFieldBoundaries = () => ({
  type: ActionType.SetCLUFieldBoundaries,
  fieldBoundaries: [] as CLUFieldBoundary[],
});

export const updateCLUFieldBoundariesProp = (
  id: string | number,
  prop: keyof CLUFieldBoundaryProps,
  value: any
) => ({
  type: ActionType.UpdateCLUFieldBoundariesProp,
  id,
  prop,
  value,
});

export const bulkUpdateCLUFieldBoundariesProp = (
  ids: string[] | number[],
  prop: keyof CLUFieldBoundaryProps,
  value: any
) => ({
  type: ActionType.BulkUpdateCLUFieldBoundariesProp,
  ids,
  prop,
  value,
});

export const toggleBoundaryIdToUpload = (id: string) => ({
  type: ActionType.ToggleBoundaryIdToUpload,
  id,
});

export const setBoundaryIdsToUpload = (boundaryIdsToUpload: string[]) => ({
  type: ActionType.SetBoundaryIdsToUpload,
  boundaryIdsToUpload,
});

export const AddProtectedAreas = (
  protectedAreaBoundaries: AddNewFieldsStore['protectedAreaBoundaries']
) => ({
  type: ActionType.AddProtectedAreas,
  protectedAreaBoundaries,
});

export const maybeEnterSelectBoundariesStepAndFetchCLUs =
  (lat: number, lon: number, shouldFetchPadusProtectedAreaIntersections: boolean) =>
  (dispatch: AppDispatch, getState: () => AppStore) => {
    const state = getState();
    const addFieldDialogOpened = selectDialogVisibility(state, DialogType.addNewField);
    const currentFarmId = selectCurrentFarmId(state);
    const boundaryIds = selectCLUFieldBoundariesIds(state);

    const switchBackToDrawing = () => {
      const currentSelectedFieldBoundaries = selectCLUFieldBoundariesList(getState()).filter(
        b => b.properties[FieldSystemProp.Checked]
      );
      if (!currentSelectedFieldBoundaries.length) {
        // switch back to drawing mode, but only if we don't have selected fields
        dispatch(setAddFieldCurrentStep('draw-fields'));
      }
      dispatch(setRequestStatus(AsyncStatusType.cluFieldBoundaries, Status.Todo));
      !addFieldDialogOpened && dispatch(dialogToggle(DialogType.addNewField, true)); // open only if closed
    };

    if (!pointInUS(lat, lon) && !pointInFrance(lat, lon)) {
      switchBackToDrawing();
      return;
    }

    // Enter select-boundaries state and fetch CLUs.
    dispatch(setRequestStatus(AsyncStatusType.cluFieldBoundaries, Status.Pending));
    KmlApi.getCLUFieldsBoundaries(lat, lon, boundaryIds)
      .then(async ({data}) => {
        dispatch(setRequestStatus(AsyncStatusType.cluFieldBoundaries, Status.Done));

        // handles a case when we already on the next step but the new CLUs are coming and moving as bac to the "select-boundaries" step
        if (selectAddingFieldsStep(getState()) === 'view-selected-boundaries') return;

        if (!data.geometries?.length) {
          switchBackToDrawing();
          return;
        }

        // Assuming that clu endpoints returns the geometry collection of only polygons.
        const polygons = data.geometries as CLUFieldBoundary[];

        const cluFieldBoundaries: AddNewFieldsStore['cluFieldBoundaries'] = {};
        polygons.forEach((p, i) => {
          const fieldId = p.properties.id;
          const fieldName = t({id: 'DefaultFieldName', defaultMessage: 'Field {id}'}, {id: i + 1});
          const farmId = currentFarmId || 0;
          p.properties[FieldSystemProp.Id] = fieldId;
          p.properties[FieldSystemProp.FieldName] = fieldName;
          p.properties[FieldSystemProp.FarmId] = farmId;
          p.properties[FieldSystemProp.Area] = convert(turfArea(p)).from('m2').to('ha');
          p.properties[FieldSystemProp.Checked] = false;
          cluFieldBoundaries[p.properties.id] = p;
        });

        if (shouldFetchPadusProtectedAreaIntersections) {
          dispatch(fetchPadusProtectedAreaIntersections({fieldBoundaries: cluFieldBoundaries}));
        }

        dispatch(addCLUFieldBoundaries(cluFieldBoundaries));
        dispatch(setAddFieldCurrentStep('select-boundaries'));
        !addFieldDialogOpened && dispatch(dialogToggle(DialogType.addNewField, true)); // open only if closed
      })
      .catch(err => {
        dispatch(setRequestStatus(AsyncStatusType.cluFieldBoundaries, Status.Done));
        if (!axios.isCancel(err)) {
          switchBackToDrawing();
        }
      });
  };

export const uploadFieldsFiles =
  (files: File[], coordinateSystem?: CoordinateSystem) => (dispatch: any) => {
    const okFiles: File[] = [];
    const unsupportedFiles: File[] = [];
    files.forEach(f => {
      if (f.type || f.name.endsWith('.kmz') || f.name.endsWith('.kml')) {
        okFiles.push(f);
      } else {
        unsupportedFiles.push(f);
      }
    });
    const errors: any[] = [];

    if (unsupportedFiles.length) {
      unsupportedFiles.forEach(f => {
        showNotification({
          title: t({id: 'note.warning', defaultMessage: 'Warning'}),
          message: t(
            {
              id: 'Unsupported file format. Try uploading a .zip version of the file instead name',
            },
            {name: f.name}
          ),
          type: 'warning',
        });
      });
    }

    if (!okFiles.length) {
      return;
    }

    dispatch(setUploadFields([]));
    dispatch(setUploadedFieldFilesNames(okFiles.map(f => f.name)));
    dispatch(setRequestStatus(AsyncStatusType.parseUploadedFields, Status.Pending));

    const {isWorkspaceMrv} = checkWorkspace();

    const fallbackProjection =
      isWorkspaceMrv && coordinateSystem && coordinateSystem !== CoordinateSystem.EPSG4326
        ? coordinateSystem
        : undefined;

    Promise.all(okFiles.map(f => parseGeometryFile(f, fallbackProjection)))
      .then(result => {
        const validGeometries: any = [];
        const tooSmallGeometries: string[] = [];
        const nonPolygonGeometries: string[] = [];

        let featureCollections = result.reduce((acc, r) => {
          return [...acc, ...(Array.isArray(r.features) ? r.features : [r.features])];
        }, []);

        featureCollections.forEach(gj => {
          if (!gj) {
            return;
          }

          try {
            const gjs = Array.isArray(gj) ? gj : [gj];
            gjs.forEach(gj =>
              handleFeatureCollection(
                gj,
                validGeometries,
                tooSmallGeometries,
                nonPolygonGeometries,
                errors
              )
            );
          } catch (e) {}
        });

        const parsingErrors = result.reduce((acc, r) => [...acc, ...r.errors], []);

        parsingErrors.forEach(e => {
          showNotification({
            title: t({id: 'note.warning', defaultMessage: 'Warning'}),
            message: e,
            type: 'warning',
          });
        });

        // save geometries that wasn't uploaded to display them on complete step
        dispatch(setNotUploadedFields([...nonPolygonGeometries, ...tooSmallGeometries]));

        if (nonPolygonGeometries.length) {
          const {error, note} = getNonPolygonMessage(nonPolygonGeometries, 'fields');
          reportError(error);
          showNotification({
            title: t({id: 'note.warning', defaultMessage: 'Warning'}),
            message: note,
            type: 'warning',
          });
        }

        if (tooSmallGeometries.length) {
          const fields = `Field${tooSmallGeometries.length > 1 ? 's' : ''}`;
          const are = tooSmallGeometries.length > 1 ? 'are' : 'is';
          showNotification({
            title: t({id: 'note.warning', defaultMessage: 'Warning'}),
            message: `${fields} ${tooSmallGeometries.join(
              ', '
            )} ${are} too small (< 0.01 ha) and won’t be uploaded`,
            type: 'warning',
          });
        }
        dispatch(setUploadFields(validGeometries));

        if (validGeometries.length) {
          // move to the next step
          dispatch(setAddFieldCurrentStep('parse-uploading-files'));
        }

        dispatch(setRequestStatus(AsyncStatusType.parseUploadedFields, Status.Done));
      })
      .catch((errors: string[]) => {
        // Should never happen, because parseGeometryFile resolves {features, errors},
        // to avoid failing the batch of files because of one failing file.
        dispatch(setRequestStatus(AsyncStatusType.parseUploadedFields, Status.Done));
        errors.forEach(e => {
          showNotification({
            title: t({id: 'note.error', defaultMessage: 'Error'}),
            message: e,
            type: 'warning',
          });
        });
      });
  };

export const uploadFields =
  (onFieldsUploaded: Skeleton['onFieldsUploaded']) =>
  async (dispatch: any, getState: () => AppStore) => {
    try {
      const {geoJsonFiles} = getState().addFields;
      const isAdmin = selectIsAdmin(getState()); // admins can upload big fields

      dispatch(setRequestStatus(AsyncStatusType.uploadingFieldsToBackend, Status.Pending));
      await dispatch(createNotExistFarms(geoJsonFiles));

      const systemProps = Object.values(FieldSystemProp);
      const updatedGeoJsonFiles = selectGeoJsonFiles(getState()); // get the updated by createNotExistFarms() fields

      const fieldRequests: SaveFieldsRequest[] = updatedGeoJsonFiles.map(field => {
        const farm_id = field.properties[FieldSystemProp.FarmId];
        const name = field.properties[FieldSystemProp.FieldName];

        // create a copy to delete properties safely
        const copyField = {...field, properties: {...field.properties}};

        systemProps.forEach(SystemProp => {
          if (SystemProp === FieldSystemProp.Id) return; // keep the unique id to create different MD5 in case the same field is uploaded
          // clear system props from the file before uploading
          delete copyField.properties[SystemProp];
        });

        return {
          farm_id,
          name,
          kml: tokml(copyField),
        };
      });

      // Batching the amount of fields uploaded into multiple requests of 200 at a time.
      // The server wasn't coping well with huge payloads.
      const chunkedRequests = chunkArray(fieldRequests, 200);
      const responses = await Promise.all(
        chunkedRequests.map(request => FarmApi.saveFields(request, isAdmin))
      );
      const fields = responses
        .map(response => response.data.result)
        .flat()
        .map(f => f.field);

      dispatch(onCompleteUpload(fields, onFieldsUploaded));
    } catch (error) {
      dispatch(onErrorUpload(error));
    }
  };

const createNotExistFarms =
  (fields: any[]) => async (dispatch: AppDispatch, getState: () => AppStore) => {
    // is used when farms are parsed from file props
    const allFarms = selectFarms(getState());
    const oldFarms = [] as {id: number; name: string}[];
    const newFarmNameToFieldIds: {[farmName: string]: number[]} = {};
    const newFarmNames: string[] = [];

    fields.forEach(f => {
      const farmId = f.properties[FieldSystemProp.FarmId];
      const farmName = String(f.properties[FieldSystemProp.NewFarmName]);
      // if farm not selected and farm name exist
      if (farmId) {
        // add farm to the list of exist farms to display on the complete step
        oldFarms.push({
          id: farmId,
          name: allFarms[farmId]?.name,
        });
      } else if (farmName) {
        if (!newFarmNameToFieldIds[farmName]) {
          newFarmNameToFieldIds[farmName] = [];
          newFarmNames.push(farmName);
        }
        newFarmNameToFieldIds[farmName].push(f.properties[FieldSystemProp.Id]);
      }
    });

    const newFarms = await Promise.all(newFarmNames.map(name => dispatch(saveFarm({id: 0, name}))));

    dispatch(
      setFarmsFieldsAddedTo([
        ...removeDuplicates(oldFarms, 'name'),
        ...removeDuplicates(newFarms, 'name').map((farm: Farm) => ({
          id: farm.id,
          name: farm.name,
          isNew: true,
        })),
      ])
    );

    newFarmNames.forEach((f, i) => {
      dispatch(
        bulkSetUploadFieldsProp(newFarmNameToFieldIds[f], FieldSystemProp.FarmId, newFarms[i].id)
      );
    });
  };

const onCompleteUpload =
  (fields: Field[], onFieldsUploaded: Skeleton['onFieldsUploaded']) =>
  async (dispatch: AppDispatch, getState: () => AppStore) => {
    const {isWorkspaceMrv} = checkWorkspace();
    const store = getState();
    const {geoJsonFiles} = store.addFields;

    if (!fields.length) {
      showNotification({
        title: t({id: 'note.warning', defaultMessage: 'Warning'}),
        message: t({id: 'No fields were uploaded.'}),
        type: 'warning',
      });
      return;
    }

    let hasNewFieldInCurrentFarm = false;
    let newField: Field = undefined;
    let newFarmId: number = undefined;
    const fieldsByFarmId: AppStore['map']['fieldsByFarmId'] = {};
    const fieldGeometries: AppStore['map']['fieldGeometries'] = {};
    const fieldsToEnroll: Record<number, boolean> = {};

    geoJsonFiles.forEach(geom => {
      const farmId = geom.properties[FieldSystemProp.FarmId];
      const fieldName =
        String(geom.properties[FieldSystemProp.FieldName]) ||
        geom.properties[FieldSystemProp.FileName];
      const field = fields.find(f => f.Name === fieldName);

      if (!fieldsByFarmId[farmId]) fieldsByFarmId[farmId] = {};
      fieldsByFarmId[farmId][field.ID] = field;
      fieldGeometries[field.MD5] = featureCollection([geom]) as GeoJSON.FeatureCollection;
      enhanceGeometryProperties(fieldGeometries[field.MD5], farmId, field.ID);
      fieldsToEnroll[field.ID] = true;

      if (!newField) {
        newField = field;
        newFarmId = farmId;
      }
      if (!hasNewFieldInCurrentFarm && farmId === store.global.currentGroupId) {
        hasNewFieldInCurrentFarm = true;
        newField = field;
      }
    });

    if (hasNewFieldInCurrentFarm) {
      await dispatch(loadFields(store.global.currentGroupId, newField.ID, true));
    } else {
      if (isWorkspaceMrv) {
        await dispatch(loadFields(newFarmId));
      } else {
        await dispatch(selectFarm(newFarmId));
      }
    }
    dispatch(setBoundaryIdsToUpload([]));
    dispatch(setUploadedFieldsNumber(geoJsonFiles.length));
    dispatch(setUploadFields([]));
    dispatch(setUploadedFieldFilesNames([]));
    dispatch(setAddFieldCurrentStep('complete'));
    dispatch(setRequestStatus(AsyncStatusType.uploadingFieldsToBackend, Status.Done));
    dispatch(enrollFields(fieldsToEnroll));

    dispatch(updateFieldGeometries(fieldGeometries));

    Object.keys(fieldsByFarmId)
      .map(Number)
      .forEach(farmId => dispatch(saveFields(farmId, fieldsByFarmId[farmId])));

    onFieldsUploaded?.(fields);
  };

const onErrorUpload = (err: AxiosResponse<{result: string}>) => (dispatch: any) => {
  reportError(`startUpload fields(), err = ${err}`);
  dispatch(setRequestStatus(AsyncStatusType.uploadingFieldsToBackend, Status.Done));
  showNotification({
    title: t({id: 'note.error', defaultMessage: 'Error'}),
    message: t({id: 'Fields upload failed error'}, {error: err.data?.result}),
    type: 'error',
  });
};

export const saveDrawFields =
  (onFieldsUploaded: Skeleton['onFieldsUploaded']) =>
  async (dispatch: any, getState: () => AppStore) => {
    const drawnFieldsGeometries = selectDrawnFieldGeometries(getState());

    dispatch(
      setUploadFields(
        drawnFieldsGeometries.map((field: GeoJSON.Feature) => {
          return {
            ...field,
            properties: {
              ...field.properties,
              [FieldSystemProp.FieldName]:
                field.properties[FieldSystemProp.FieldName] ||
                `Field ${field.properties[FieldSystemProp.Id]}`,
            },
          };
        })
      )
    );
    await dispatch(uploadFields(onFieldsUploaded));
    dispatch(removeDrawnFieldsGeometry());
  };

export const saveSelectedBoundaries =
  (onFieldsUploaded: Skeleton['onFieldsUploaded']) =>
  async (dispatch: any, getState: () => AppStore) => {
    const cluFieldBoundaries = selectBoundariesToUpload(getState());

    dispatch(
      setUploadFields(
        cluFieldBoundaries.map(field => {
          return {
            type: 'Feature',
            geometry: field,
            properties: field.properties,
          } as GeoJSON.Feature;
        })
      )
    );
    await dispatch(uploadFields(onFieldsUploaded));
    dispatch(clearCLUFieldBoundaries());
  };

export const reducer = (state = initialState, action: any): AddNewFieldsStore => {
  switch (action.type) {
    case ActionType.UpdateIdsUploadFields: {
      return {
        ...state,
        geoJsonFiles: state.geoJsonFiles.map((feature: GeoJSON.Feature) => {
          // search PARSED farms with the same name
          if (
            feature.properties[action.prop] === action.farmName &&
            !feature.properties[FieldSystemProp.FarmId]
          ) {
            return {
              ...feature,
              properties: {...feature.properties, [FieldSystemProp.FarmId]: action.farmId},
            };
          }

          return feature;
        }),
      };
    }

    case ActionType.SetUploadFieldProp: {
      return {
        ...state,
        geoJsonFiles: state.geoJsonFiles.map(f => {
          if (f.properties[FieldSystemProp.Id] === action.id)
            return {
              ...f,
              properties: {...f.properties, [action.prop]: action.value},
            };

          return f;
        }),
      };
    }

    case ActionType.BulkSetUploadFieldsProp: {
      return {
        ...state,
        geoJsonFiles: state.geoJsonFiles.map(f => {
          if (action.ids.includes(f.properties[FieldSystemProp.Id]))
            return {
              ...f,
              properties: {...f.properties, [action.prop]: action.value},
            };

          return f;
        }),
      };
    }

    case ActionType.SetUploadFields: {
      return {
        ...state,
        geoJsonFiles: action.fields,
      };
    }

    case ActionType.RemoveUploadFields: {
      return {
        ...state,
        geoJsonFiles: state.geoJsonFiles.filter(
          f => !action.ids.includes(f.properties[FieldSystemProp.Id])
        ),
      };
    }

    case ActionType.SetNotUploadFields: {
      return {
        ...state,
        notUploadedFields: action.fieldNames,
      };
    }

    case ActionType.SetUploadedFieldFilesNames: {
      return {
        ...state,
        uploadedFieldFilesNames: action.names,
      };
    }

    case ActionType.SetFarmsFieldsAddedTo: {
      return {
        ...state,
        farmsFieldsAddedTo: action.farms,
      };
    }

    case ActionType.SetUploadedFieldsNumber: {
      return {
        ...state,
        uploadedFieldsNumber: action.fieldsNumber,
      };
    }

    case ActionType.SetFieldPropsMapping: {
      return {
        ...state,
        propsMapping: {...action.mapping},
      };
    }

    case ActionType.SetFieldPropMapping: {
      return {
        ...state,
        propsMapping: {
          ...state.propsMapping,
          [action.propName]: action.value,
        },
      };
    }

    case ActionType.SetUploadedFieldsValueFromMappingProps: {
      // updates field values using mapping prop keys and values from the field properties
      return {
        ...state,
        geoJsonFiles: state.geoJsonFiles.map(f => {
          let propertyToUpdate = {};

          // set fields values using propsMapping values
          switch (action.mappingProp) {
            case 'fieldName':
              const fieldName = f.properties[state.propsMapping.fieldName];
              propertyToUpdate = {
                [FieldSystemProp.FieldName]: fieldName ? String(fieldName) : '',
              };
              break;
            case 'farmName':
              const classifiedFarmProp = getFarmById(
                action.farmsList,
                f.properties[state.propsMapping.farmName]
              )
                ? FieldSystemProp.FarmId
                : FieldSystemProp.NewFarmName;
              const farmName = f.properties[state.propsMapping.farmName];
              propertyToUpdate = {
                [classifiedFarmProp]: farmName ? String(farmName) : '',
              };
              break;
          }
          return {...f, properties: {...f.properties, ...propertyToUpdate}};
        }),
      };
    }

    case ActionType.AddDrawnFieldGeometry:
      return {
        ...state,
        drawnFieldsGeometries:
          state.drawnFieldsGeometries.findIndex(
            field =>
              action.geoJSON.properties[FieldSystemProp.Id] === field.properties[FieldSystemProp.Id]
          ) === -1
            ? [...state.drawnFieldsGeometries, action.geoJSON]
            : state.drawnFieldsGeometries.map(field => {
                return action.geoJSON.properties[FieldSystemProp.Id] ===
                  field.properties[FieldSystemProp.Id]
                  ? {
                      ...field,
                      geometry: {...action.geoJSON.geometry},
                      properties: {
                        ...field.properties,
                        ...action.geoJSON.properties,
                      },
                    }
                  : {...field};
              }),
      };

    case ActionType.RemoveDrawnFieldGeometry:
      return {
        ...state,
        drawnFieldsGeometries: action.ids
          ? state.drawnFieldsGeometries.filter(
              f => !action.ids.includes(f.properties[FieldSystemProp.Id])
            )
          : [],
      };

    case ActionType.SetPropDrawnFieldGeometry:
      return {
        ...state,
        drawnFieldsGeometries: state.drawnFieldsGeometries.map(f => {
          if (f.properties[FieldSystemProp.Id] === action.id)
            return {
              ...f,
              properties: {...f.properties, [action.prop]: action.value},
            };

          return f;
        }),
      };

    case ActionType.BulkSetPropDrawnFieldGeometry:
      return {
        ...state,
        drawnFieldsGeometries: state.drawnFieldsGeometries.map(f => {
          if (action.ids.includes(f.properties[FieldSystemProp.Id]))
            return {
              ...f,
              properties: {...f.properties, [action.prop]: action.value},
            };

          return f;
        }),
      };

    case ActionType.SetAddFieldCurrentStep:
      return {
        ...state,
        addFieldCurrentStep: action.step,
      };

    case ActionType.SetCLUFieldBoundaries:
      return {
        ...state,
        cluFieldBoundaries: {...state.cluFieldBoundaries, ...action.fieldBoundaries},
      };

    case ActionType.RemoveCLUFieldBoundaries:
      return {
        ...state,
        boundaryIdsToUpload: state.boundaryIdsToUpload.filter(id => !action.ids.includes(id)),
      };

    case ActionType.UpdateCLUFieldBoundariesProp: {
      const boundary = state.cluFieldBoundaries[action.id];
      return {
        ...state,
        cluFieldBoundaries: {
          ...state.cluFieldBoundaries,
          [action.id]: {
            ...boundary,
            properties: {...boundary?.properties, [action.prop]: action.value},
          },
        },
      };
    }

    case ActionType.BulkUpdateCLUFieldBoundariesProp: {
      const newBoundaries = {...state.cluFieldBoundaries};
      action.ids.forEach((id: string) => {
        const boundary = newBoundaries[id];
        newBoundaries[id] = {
          ...boundary,
          properties: {...boundary?.properties, [action.prop]: action.value},
        };
      });
      return {...state, cluFieldBoundaries: newBoundaries};
    }

    case ActionType.SetBoundaryIdsToUpload:
      return {
        ...state,
        boundaryIdsToUpload: action.boundaryIdsToUpload,
      };

    case ActionType.ToggleBoundaryIdToUpload:
      return {
        ...state,
        boundaryIdsToUpload: state.boundaryIdsToUpload.includes(action.id)
          ? state.boundaryIdsToUpload.filter(id => id !== action.id)
          : [...state.boundaryIdsToUpload, action.id],
      };

    case ActionType.AddProtectedAreas:
      return {
        ...state,
        protectedAreaBoundaries: {
          ...state.protectedAreaBoundaries,
          ...action.protectedAreaBoundaries,
        },
      };

    default:
      return state;
  }
};

export function convertPropertyValue(value: any) {
  if (typeof value === 'object') {
    if (value instanceof Date) {
      return moment(value).format('DD MM YYYY');
    }

    return '';
  }

  if (typeof value === 'string' && value.length > 50) {
    return `${value.slice(0, 50)}...`;
  }

  return value;
}

// selectors part

export const selectDrawnFieldGeometries = (s: AppStore) => s.addFields.drawnFieldsGeometries;

export const selectSelectedDrewFieldGeometries = createSelector(
  [selectDrawnFieldGeometries],
  geometries => geometries.filter(geometry => geometry.properties[FieldSystemProp.Checked])
);

export const selectAddFieldsPropsMapping = (s: AppStore) => s.addFields.propsMapping;

export const selectUploadedFieldFilesNames = (s: AppStore) => s.addFields.uploadedFieldFilesNames;
