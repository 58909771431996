import {Portal} from 'components/fluro-dialog/portal';
import {selectIsAdmin, selectIsHasAccessToSI} from 'containers/login/login-selectors';
import {setFeature} from 'containers/map/actions';
import React, {useEffect} from 'react';
import Helmet from 'react-helmet';
import {useDispatch} from 'react-redux';
import {Redirect} from 'react-router-dom';
import {useAppSelector} from '_hooks';
import {useSIMetrics, useSIPolicy} from './data-hooks';
import {SIFilterStatus} from './filter-status/filter-status';
import {FiltersPanel} from './filters-panel/filters-panel';
import {CI_HOME} from 'containers/map/routes';

const SIContainerInner = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setFeature('sustainability-insights', false));
    // Needed on mount only
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useSIPolicy();
  useSIMetrics();

  return (
    <>
      <Helmet>
        <title>Sustainability Insights - Regrow</title>
      </Helmet>

      <Portal id="map-filter-status">
        <SIFilterStatus />
      </Portal>

      <FiltersPanel />
    </>
  );
};

export const SIContainer = () => {
  const hasSIAccess = useAppSelector(selectIsHasAccessToSI);
  const isAdmin = useAppSelector(selectIsAdmin);
  const isAllowed = hasSIAccess || isAdmin;

  if (!isAllowed) return <Redirect to={CI_HOME} />;

  return <SIContainerInner />;
};

export default SIContainer;
