import React from 'react';
import {CropPerformanceFarmsDropdown} from '../../map/features/crop-performance/farms-dropdown';
import SelectorFarms from './selector-farms';
import {useAppSelector} from '_hooks';
import {selectCurrentTab} from '../../map/reducer/selectors';

type Props = {
  mobileView?: boolean; // used to display data in FluroChips in certain areas (not in the toggle header) for mobiles
};
export const FluroFarmsSelector = ({mobileView}: Props) => {
  const feature = useAppSelector(selectCurrentTab);

  return feature === 'crop-performance' ? (
    <CropPerformanceFarmsDropdown mobileView={mobileView} />
  ) : (
    <SelectorFarms mobileView={mobileView} />
  );
};
