import cn from 'classnames';
import type {ComponentType, ReactNode} from 'react';
import React from 'react';
import type {
  DataTableProps,
  TableBodyProps,
  TableColumnProps,
  TableHeaderProps,
  TablePaginationProps,
  TableRowProps,
} from 'react-md';
import {
  DataTable,
  FontIcon,
  TableBody,
  TableColumn,
  TableHeader,
  TablePagination,
  TableRow,
} from 'react-md';
import {t} from '../../i18n-utils';
import './fluro-table-components.scss';

export type ColumnContentType = 'number' | 'text' | 'date'; // positioning: number - right | text - left | date - center

type FluroTableHeaderProps = TableHeaderProps & {sticky?: boolean};
export const FluroTableHeader = ({
  children,
  className = '',
  sticky,
  ...rest
}: FluroTableHeaderProps) => {
  return (
    <TableHeader {...rest} className={cn({'fluro-table-header': true, sticky, [className]: true})}>
      {children}
    </TableHeader>
  );
};

export const FluroTableRow = ({
  hoverBg = true,
  children,
  className = '',
  ...rest
}: {hoverBg?: boolean} & TableRowProps) => {
  return (
    <TableRow
      {...rest}
      className={cn('fluro-table-row', className, {'fluro-table-row--reset-bg': !hoverBg})}
    >
      {children}
    </TableRow>
  );
};

/**
 * Lite version is created to avoid react-md's behaviour of adding a "hover" className to the table row.
 */
export const FluroTableRowLite = ({children, className}: TableRowProps) => {
  return <tr className={cn('fluro-table-row', className)}>{children}</tr>;
};

export type FluroTableColumnProps = TableColumnProps & {
  id?: string;
  highlight?: boolean;
  nowrap?: boolean;
  sorting?: boolean;
  title?: string;
  type?: ColumnContentType;
  verticalPadding?: boolean;
  hasError?: boolean;
  hasWarning?: boolean;
};

export const FluroTableColumn: ComponentType<FluroTableColumnProps> = ({
  id,
  children,
  className = '',
  type = 'text',
  nowrap = false,
  title,
  highlight = false,
  verticalPadding,
  hasError,
  hasWarning,
  ...rest
}) => {
  return (
    <TableColumn
      {...rest}
      className={cn('fluro-table-column', `type-${type}`, className, {
        nowrap,
        highlight,
        'pt-05': verticalPadding,
        'pb-05': verticalPadding,
        'validation-error': hasError,
        // validation-error takes precedence over soft-warning
        'soft-warning': !hasError && hasWarning,
      })}
      //@ts-expect-error error leftover from convertion to strict mode, please fix
      title={title}
    >
      <div id={id}>{children}</div>
    </TableColumn>
  );
};

export const FluroTableBody = ({
  children,
  className = '',
  ...rest
}: {children?: ReactNode} & Omit<TableBodyProps, 'children'>) => {
  return (
    // ReactMDs poor TS implementation fails to accept common children syntax so we have to use our own property.
    <TableBody {...rest} className={`fluro-table-body ${className}`}>
      {children as React.ReactElement<any> | Array<React.ReactElement<any>>}
    </TableBody>
  );
};

type FluroDataTableProps = DataTableProps & {
  elevated?: boolean;
  mini?: boolean;
};

export const FluroDataTable = ({
  children,
  className = '',
  fixedDividers,
  baseId,
  plain = true, // with or without default checkboxes
  elevated = true,
  mini = false,
  ...rest
}: FluroDataTableProps) => {
  return (
    <DataTable
      {...rest}
      baseId={baseId}
      plain={plain}
      fixedDividers={fixedDividers}
      className={cn('fluro-data-table', className, {elevated, mini})}
    >
      {children}
    </DataTable>
  );
};

export const FluroTablePagination = ({
  page,
  rows,
  rowsPerPage,
  rowsPerPageLabel = t({id: 'Rows per page'}),
  onPagination,
  rowsPerPageItems,
  className = '',
  ...rest
}: TablePaginationProps) => {
  return (
    <TablePagination
      {...rest}
      className={cn({'fluro-table-pagination': true, [className]: true})}
      page={page}
      rows={rows}
      rowsPerPage={rowsPerPage}
      rowsPerPageLabel={rowsPerPageLabel}
      onPagination={onPagination}
      rowsPerPageItems={rowsPerPageItems}
    />
  );
};

type FluroTableControlsProps = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
> & {
  className?: string;
  withElevation?: boolean;
};
export const FluroTableControls = ({
  className = '',
  withElevation = true,
  ...rest
}: FluroTableControlsProps) => {
  return (
    <div
      className={cn({
        'fluro-table-controls': true,
        [className]: true,
        'with-elevation': withElevation,
      })}
      {...rest}
    />
  );
};

export const FluroTableColumnIconButton = ({
  mdIconValue,
  ...rest
}: React.HTMLProps<HTMLDivElement> & {mdIconValue: string}) => {
  return (
    <div className="fluro-table-icon-button" {...rest}>
      <FontIcon>{mdIconValue}</FontIcon>
    </div>
  );
};

export const FluroTableFlexWrapper = ({
  children,
  ...rest
}: React.HTMLProps<HTMLDivElement> & {children: React.ReactNode}) => {
  return (
    <div className="fluro-table-flex-wrapper" {...rest}>
      {children}
    </div>
  );
};
