export const backToHomeLabel = 'Back to home';

export const MRVPhasesDateFormat = 'MMM D YYYY';

export const MRVEntityAllEntitiesId = -1; // By default show all entities (don't filter).

export const defaultSuperclusterOptions = {
  radius: 60,
  extent: 256,
  maxZoom: 11,
};

// Name of custom input that is for cargill account id in `mrv_program_custom_reg_inputs` table.
export const CargillAccountIdNames = new Set(['Cargill Account ID', 'Cargill IDs']);
