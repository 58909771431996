import type {MRVCustomInput, MRVProject, MRVProgram} from '../types';
import MRVApi from '../../../_api/mrv';
import {CargillAccountIdNames} from '../constants';
import useSWR from 'swr';
import keyBy from 'lodash/keyBy';
import {useCallback} from 'react';

const generateCustomInputs = ({project, program}: {project: MRVProject; program: MRVProgram}) => {
  const customInputs: MRVCustomInput[] = project.custom_reg_values || [];
  return program.custom_reg_inputs.map(programCustomInput => {
    const valuedCustomInput = customInputs.find(
      cInput => Number(cInput.key) === programCustomInput.id
    );

    // if account id does not exist, attempt to use hardcoded account id from extra_signup_fields
    let fallbackValue = '';
    if (CargillAccountIdNames.has(programCustomInput.name)) {
      fallbackValue = project?.config?.extra_signup_fields?.value || '';
    }

    if (valuedCustomInput) {
      return {
        id: valuedCustomInput.id,
        key: valuedCustomInput.key,
        name: programCustomInput.name,
        config: programCustomInput.config,
        type_: programCustomInput.type_,
        program_name: program.name,
        program_id: program.id,
        project_id: project.id,
        value: valuedCustomInput.value || fallbackValue,
      };
    }

    return {
      id: 'new',
      key: `${programCustomInput.id}`,
      name: programCustomInput.name,
      config: programCustomInput.config,
      type_: programCustomInput.type_,
      program_name: program.name,
      program_id: program.id,
      project_id: project.id,
      value: fallbackValue,
    };
  });
};

export const useCustomInputs = (projectIds: number[]) => {
  const {data, mutate, isLoading, isValidating} = useSWR(
    [['/projects', '/programs'], projectIds],
    async ([_, pids]) => {
      const customInputs: MRVCustomInput[] = [];
      const projects: MRVProject[] = [];
      const programs: MRVProgram[] = [];

      const promises = pids.map(async projectId => {
        const [project] = (await MRVApi.getProject(projectId)).data;
        projects.push(project);
        if (!programs[project.program_id]) {
          programs[project.program_id] = (await MRVApi.getProgram(project.program_id)).data;
        }
        const program = programs[project.program_id];

        if (program && !!program.custom_reg_inputs.length) {
          const currentCustomInputs = generateCustomInputs({project, program});
          customInputs.push(...currentCustomInputs);
        }
      });
      await Promise.all(promises);

      // only add hardcoded account id input if program is not configured to use custom inputs
      const customInputsKeyByProjectId = keyBy(customInputs, 'project_id');
      const projectConfigs: Pick<MRVProject, 'id' | 'program_name' | 'config'>[] = projects
        .filter(p => {
          const customInput = customInputsKeyByProjectId[p.id];
          return !customInput || !CargillAccountIdNames.has(customInput.name || '');
        })
        .map(p => ({id: p.id, program_name: p.program_name, config: p.config}))
        .sort((a, b) => a.id - b.id);

      return {
        customInputs,
        projectConfigs,
      };
    }
  );

  // update project config (for hardcoded account id inputs)
  const updateProjectConfig = useCallback(
    async ({projectId, accountId}: {projectId: number; accountId: string}) => {
      const result = (
        await MRVApi.updateProjectConfig(projectId, {
          extra_signup_fields: {key: 'cargill_account_id', value: accountId},
        })
      ).data;
      const updatedConfig = result?.config;

      // no need to wait for revalidation
      mutate(currentData => {
        const updatedConfigs = currentData?.projectConfigs || [];
        const updated = updatedConfigs.find(ci => ci.id === projectId);
        if (updated) {
          updated.config = updatedConfig;
          return {
            projectConfigs: updatedConfigs,
            customInputs: currentData?.customInputs || [],
          };
        }
        return currentData;
      });
      return result;
    },
    [mutate]
  );

  const updateCustomInput = useCallback(
    async ({projectId, input}: {projectId: number; input: MRVCustomInput}) => {
      let result: MRVCustomInput[];
      if ('id' in input) {
        result = (await MRVApi.updateMrvProjectCustomInputsValue({projectId, input})).data;
      } else {
        result = (await MRVApi.createMrvProjectCustomInputsValue({projectId, input})).data;
      }
      // no need to wait for revalidation
      mutate(currentData => {
        const customInputs = [...(currentData?.customInputs || [])];
        const updated = customInputs.find(ci => ci.id === input.id);
        if (updated) {
          updated.value = input.value;
        }
        return {
          projectConfigs: currentData?.projectConfigs || [],
          customInputs,
        };
      });
      return result;
    },
    [mutate]
  );

  return {
    isLoading: isLoading || isValidating || !data,
    projectConfigs: data?.projectConfigs || [],
    customInputs: data?.customInputs || [],
    updateProjectConfig,
    updateCustomInput,
  };
};
