import Server from './server';

export default class {
  static getUserActivityList() {
    return Server.get(`admin/users/access_summaries`, {params: {__skipPreloader: true}});
  }

  static getSummary(ids: number[]) {
    return Server.get(`admin/users/access_summaries`, {
      params: {__skipPreloader: true, just_summary: 1, ids: ids.join(',')},
    });
  }
}
