import {captureException, configureScope, Severity} from '@sentry/react';
import {Component} from 'react';
import type {ComponentPropsWithoutRef} from 'react';
import type {ConnectedProps} from 'react-redux';
import {connect} from 'react-redux';
import {getErrorMessage} from '_utils/error';
import type {LoginState} from '../login/types';

/**
 * Report the error to Sentry
 */
export function reportError(error: string | unknown) {
  const message: string = typeof error === 'string' ? error : getErrorMessage(error);
  captureException(new Error(message));
}

type Props = ConnectedProps<typeof connector> &
  ComponentPropsWithoutRef<any> & {expectedError?: boolean}; // expectedError is used when you know the error, but can't fix it (CP benchmark time scale updates)

class ErrorBoundary extends Component<Props> {
  componentDidMount(): void {
    this.addListeners();
  }

  componentWillUnmount(): void {
    this.removeListeners();
  }

  componentDidCatch(error: Error) {
    if (this.props.expectedError) {
      return;
    }

    if (!error) {
      return;
    }
    if (error.name === 'ChunkLoadError') {
      window.location.reload(); // reload the page if there is a new version of the app
      return; // do not sent this error to centry (because it is known error)
    }
    this.errorHandler(error);
  }

  componentDidUpdate(prevProps: Readonly<Props>): void {
    if (this.props.userEmail !== prevProps.userEmail) {
      configureScope(scope => {
        scope.setUser({email: this.props.userEmail});
        scope.setLevel(Severity.Warning);
      });
    }
  }

  addListeners = () => {
    window.addEventListener('unhandledrejection', this.handleRejection);
    window.addEventListener('rejectionhandled', this.handleRejection);
  };

  removeListeners = () => {
    window.removeEventListener('unhandledrejection', this.handleRejection);
    window.removeEventListener('rejectionhandled', this.handleRejection);
  };

  errorHandler = (error: Error) => {
    captureException(error);
  };

  handleRejection = (event: Event) => {
    captureException(new Error(`[promise rejection]: ${event.type}, ${event.target}`));
  };

  render() {
    return this.props.children;
  }
}

const mapStateToProps = ({login}: {login: LoginState}) => ({
  userEmail: login?.user.email,
});

const connector = connect(mapStateToProps, {});

export default connector(ErrorBoundary);
