// @ts-nocheck
import * as React from 'react';
import {useCallback, useEffect, useRef, useState} from 'react';
import {renderToString} from 'react-dom/server';
import L from 'leaflet';
import Supercluster from 'supercluster';
import type {AllGeoJSON} from '@turf/turf';
import {centroid, featureCollection} from '@turf/turf';
import {ClusterMarker} from 'containers/map/features/crop-performance/map/cluster-marker';
import {PANEL_PADDING} from '_constants';
import {FluroMarker} from 'components/fluro-leaflet';
import {defaultSuperclusterOptions} from 'containers/mrv/constants';

export const useClusters = (
  leafletElement: L.Map,
  rerenderMap: () => void,
  geometries: {[fieldId: number]: GeoJSON.FeatureCollection},
  colors: {[fieldId: number]: string},
  categories: {[fieldId: number]: string},
  isEditingMode?: boolean
) => {
  const [clusterMarkers, setClusterMarkers] = useState<{
    clusters: any[];
    clusteredGeometries: {[clusterId: number]: number[]};
    clusteredGeometryIsSeason: {[clusterId: number]: boolean};
  }>({clusters: [], clusteredGeometries: {}, clusteredGeometryIsSeason: {}});
  const [unclusteredIds, setUnclusteredIds] = useState<{[seasonId: string]: true}>({});

  // It needs to be in ref so map.on('moveend') can always access the actual version of
  // the index instead of closured one if we'd use useState instead of useRef.
  const geospatialIndexRef = useRef<Supercluster>();

  const updateClusterMarkers = useCallback(() => {
    if (!geospatialIndexRef.current) {
      return;
    }
    const bounds = leafletElement.getBounds();

    const clusters = geospatialIndexRef.current.getClusters(
      [bounds.getWest(), bounds.getSouth(), bounds.getEast(), bounds.getNorth()],
      Math.round(leafletElement.getZoom())
    );

    // Remember the unclustered crop ids, so we can show their geometries.
    const newUnclusteredIds: {[seasonId: number]: true} = {};
    const clusteredGeometries: {[clusterId: number]: number[]} = {};
    const clusteredGeometryIsSeason: {[clusterId: number]: true} = {};
    clusters.forEach(c => {
      if (!c.properties.cluster) {
        newUnclusteredIds[c.properties.seasonId] = true;
        return;
      }

      const leaves = geospatialIndexRef.current.getLeaves(c.properties.cluster_id, Infinity);
      const ids = leaves.map(l => l.properties.seasonId);

      leaves.forEach(
        leave => (clusteredGeometryIsSeason[leave.properties.seasonId] = leave.properties.isSeason)
      );
      clusteredGeometries[c.properties.cluster_id] = ids;
      clusteredGeometryIsSeason[c.properties.cluster_id] = c.properties.isSeason;
      c.properties.point_count = ids.length;
      c.properties.point_count_abbreviated = ids.length;
    });

    setUnclusteredIds(newUnclusteredIds);

    // TODO: we don't have unclustered here, but
    // we want to have markers for them since they are tiny.
    setClusterMarkers({
      clusters,
      clusteredGeometries,
      clusteredGeometryIsSeason,
    });

    rerenderMap();
  }, []);

  //TODO: (sasha) avoid redundant renders - check if clusters are display
  useEffect(() => {
    leafletElement[isEditingMode ? 'off' : 'on']('moveend', updateClusterMarkers);

    return () => {
      leafletElement.off('moveend', updateClusterMarkers);
    };
  }, [updateClusterMarkers, isEditingMode]);

  // Setup Supercluster.
  useEffect(() => {
    const fieldCentroids = Object.entries(geometries)
      .filter(([_fieldId, geometry]) => geometry)
      .flatMap(([fieldId, geometry]) => {
        const points: GeoJSON.Feature<GeoJSON.Point>[] = [];
        // suggesting type due to bug? in @turf types
        const point = centroid(featureCollection(geometry.features) as AllGeoJSON);
        point.properties.seasonId = fieldId; // use field id for the fields without seasons
        point.properties.isSeason = false; // set is season as a leave property, to get it later
        points.push(point);

        return points;
      });
    geospatialIndexRef.current = new Supercluster(defaultSuperclusterOptions).load(fieldCentroids);
    updateClusterMarkers();
  }, [geometries]);

  const renderClusterMarker = useCallback(
    (ids: number[], _isSeason: {[id: number]: boolean}) => {
      //
      // Create a map of category colors instead of field colors, so we can count categories and show their slices.
      // colors[fieldId] -> colors[categoryName]
      //
      // Given:
      // colors         = {1: 'red',     2: 'blue',  3: 'blue'}
      // categories     = {1: 'invalid', 2: 'valid', 3: 'valid'}
      //
      // Derive:
      // categoryColors = {invalid: 'red', valid: 'blue'}
      // count          = {invalid: 1,     valid: 2}
      //
      const categoryColors: {[category: string]: string} = {};
      const count: {[category: string]: number} = {};
      ids.forEach(id => {
        if (!categoryColors[categories[id]]) {
          categoryColors[categories[id]] = colors[id] || '#c7c7c7';
        }
        if (!count[categories[id]]) {
          count[categories[id]] = 0;
        }
        count[categories[id]] += 1;
      });
      const dataAndColors = {
        data: Object.entries(count).map(([category, value]) => ({
          id: category,
          label: category,
          value,
        })),
        getColor: (slice: any) => categoryColors[slice.id], // slice.id is a category, not fieldId.
      };
      return renderToString(<ClusterMarker dataAndColors={dataAndColors} />);
    },
    [colors, categories]
  );

  // Render.

  const clusterMarkersRendered = clusterMarkers.clusters.map(m => {
    const clusterId = m.properties.cluster_id;
    const seasonIds = clusterMarkers.clusteredGeometries[clusterId];
    const [lng, lat] = m.geometry.coordinates;
    if (clusterId) {
      return (
        <FluroMarker
          key={clusterId}
          position={{lat, lng}}
          icon={L.divIcon({
            html: renderClusterMarker(seasonIds, clusterMarkers.clusteredGeometryIsSeason),
            className: `marker-cluster marker-cluster-${'medium'}`,
            iconSize: L.point(60, 60),
          })}
          eventHandlers={{
            click: e => {
              const zoom = geospatialIndexRef.current.getClusterExpansionZoom(clusterId);
              // TODO: flyTo should take into account the side panel.
              leafletElement.flyTo(e.latlng, zoom);
            },
          }}
        />
      );
    }

    // Otherwise – if it's not a cluster and just a field – show a simple colored circle.
    // And show them only when the map is zoomed out enough. Otherwise we'd rather see the shapes.
    if (leafletElement.getZoom() > 11.5) {
      return null;
    }

    const seasonId = m.properties.seasonId;

    return (
      <FluroMarker
        key={seasonId}
        position={{lat, lng}}
        icon={L.divIcon({
          html: renderClusterMarker([seasonId], {[seasonId]: m.properties.isSeason}),
          className: `marker-cluster marker-cluster-${'medium'}`,
          iconSize: L.point(60, 60),
        })}
        eventHandlers={{
          click: () => {
            // Expand clicked cluster – zoom in and change map center.
            const geom = geometries[seasonId];
            const bounds = L.geoJSON(geom).getBounds();
            leafletElement.fitBounds(bounds, PANEL_PADDING);
          },
        }}
      />
    );
  });

  return {
    unclusteredIds,
    clusterMarkersRendered,
  };
};
