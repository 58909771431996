// @ts-nocheck
import React, {useEffect, useMemo, useState} from 'react';
import './index.scss';
import {TextField, DropdownMenu} from 'react-md';
import LabelOpenBtn from '../label-open-btn';
import type {LayoverPositions} from 'react-md/lib/Helpers';
import {genKey} from '_utils/pure-utils';

type Props = {
  id?: string;
  miniButton?: boolean;
  width?: number;
  label: string;
  menuItems: Array<number | string | {} | React.ReactNode>; //SharedDropdownMenuProps['menuItems'];
  className?: string;
  primary?: boolean;
  iconEl?: React.ReactElement;
  disabled?: boolean;
  position?: LayoverPositions;
  searchLabel?: string;
  menuButtonEl?: React.ReactElement;
  setSearchLabel?(val: string): void;
};

const anchor = {
  x: DropdownMenu.HorizontalAnchors.CENTER,
  y: DropdownMenu.VerticalAnchors.BOTTOM,
};

const FluroDropdownMenu = ({
  id,
  primary,
  width,
  className = '',
  label,
  iconEl,
  disabled,
  position,
  menuButtonEl,
  menuItems,
  miniButton, // reduce height, padding, margin
  searchLabel,
  setSearchLabel,
}: Props) => {
  const inputRef = React.useRef();
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    //@ts-expect-error error leftover from convertion to strict mode, please fix
    inputRef?.current?.focus();
  }, [visible]);
  const _id = useMemo(() => id || genKey(), []);

  return (
    <DropdownMenu
      id={`menu-${_id}`}
      style={{width}}
      anchor={anchor}
      visible={visible}
      position={position || DropdownMenu.Positions.BELOW}
      onVisibilityChange={(value: boolean) => {
        // clear search if hidden
        if (!value && setSearchLabel) setSearchLabel('');

        setVisible(value);
      }}
      menuItems={
        searchLabel !== undefined
          ? [
              <li key={`search-wrapper-${_id}`} className="search-label-wrapper">
                <TextField
                  ref={inputRef}
                  id={`search-input-${_id}`}
                  placeholder="Search label"
                  value={searchLabel}
                  onChange={(value: string) => setSearchLabel(value)}
                />
              </li>,
              ...menuItems,
            ]
          : menuItems
      }
      simplifiedMenu={true}
      className={className}
      listClassName={'autocomplete-list'}
    >
      {menuButtonEl ? (
        menuButtonEl
      ) : (
        <LabelOpenBtn
          className={miniButton ? 'dropDown' : ''}
          label={label}
          disabled={disabled}
          flat={!primary}
          raised={primary}
          primary={primary}
          iconEl={iconEl}
        />
      )}
    </DropdownMenu>
  );
};

export default FluroDropdownMenu;
