import type {BarTooltipDatum} from '@nivo/bar';
import {ResponsiveBar} from '@nivo/bar';
import {ResponsiveLine} from '@nivo/line';
import {Flex, Text} from 'components';
import {Portal} from 'components/fluro-dialog/portal';
import {TOOLTIPS} from 'containers/si/constants';
import {NDVI} from 'containers/si/colors';
import {useFetchKPI} from 'containers/si/hooks/useFetchKPI';
import {useSparklingChart} from 'containers/si/hooks/useSparklingChart';
import {selectSIYearsFilter} from 'containers/si/module/selectors';
import React, {useEffect, useState} from 'react';
import {useTheme} from 'styled-components';
import {useAppSelector} from '_hooks';
import {kiloFormatter, toFixedFloat} from '_utils/number-formatters';
import {ChartMiddleData} from '../../charts/components/chart-middle-data';
import {SparklineChart} from '../../charts/components/sparkline-chart';
import {MultiChartKPICard} from '../../charts/multi-chart-kpi-card';
import {BarContainer, ChartTooltip, Squares} from '../../styled-components';
import {getColorFromValue, getCropYearValues, getNdviValueRangeFromColor} from './utils';

export const LivingRootCard = () => {
  const [currentYear] = useAppSelector(selectSIYearsFilter);

  const {response: livingRootCoverResponse, loading} = useFetchKPI({
    kpi: 'living_root_cover',
    summarizeBy: ['annualized', 'kpi_subtype'],
  });
  const {response: livingRootHealthResponse} = useFetchKPI({
    kpi: 'living_root_health',
    summarizeBy: ['kpi_subtype'],
  });

  const {response: livingRootConfidenceResponse} = useFetchKPI({
    kpi: 'living_root_confidence',
    summarizeBy: ['kpi_subtype'],
  });
  //Previous year to get value for last two months
  const prevYear = currentYear - 1;
  const {response: livingRootCoverResponsePrevYear} = useFetchKPI({
    kpi: 'living_root_cover',
    summarizeBy: ['annualized', 'kpi_subtype'],
    year: prevYear,
  });
  const {response: livingRootHealthResponsePrevYear} = useFetchKPI({
    kpi: 'living_root_health',
    summarizeBy: ['kpi_subtype'],
    year: prevYear,
  });

  const {response: livingRootConfidenceResponsePrevYear} = useFetchKPI({
    kpi: 'living_root_confidence',
    summarizeBy: ['kpi_subtype'],
    year: prevYear,
  });
  const theme = useTheme();

  const {metric, comparisonValues, sparklingChartData, dataset, average} =
    useSparklingChart(livingRootCoverResponse);

  const getDaysWithLivingRoot = (percentage: number) => toFixedFloat((percentage / 100) * 365, 0);

  const [barChartData, setBarChartData] = useState([
    {
      month: `Nov`,
    },
    {
      month: 'Dec',
    },
    {
      month: `Jan`,
    },
    {
      month: 'Feb',
    },
    {
      month: 'Mar',
    },
    {
      month: 'Apr',
    },
    {
      month: 'May',
    },
    {
      month: 'Jun',
    },
    {
      month: 'Jul',
    },
    {
      month: 'Aug',
    },
    {
      month: 'Sep',
    },
    {
      month: 'Oct',
    },
  ]);
  const [confidenceLineData, setConfidenceLineData] = useState<
    Array<{
      id: string;
      color: string;
      data: Array<{
        x: number;
        y: number;
      }>;
    }>
  >([
    {
      id: 'confidence',
      color: theme.colorPalette.fs_main.gray_400,
      data: [],
    },
  ]);

  const [currentChart, setCurrentChart] = useState(0);

  useEffect(() => {
    //Chart calculations for bar chart
    if (
      !livingRootCoverResponse?.kpi_subtype_summary ||
      !livingRootHealthResponse?.kpi_subtype_summary ||
      !livingRootCoverResponsePrevYear?.kpi_subtype_summary ||
      !livingRootHealthResponsePrevYear?.kpi_subtype_summary
    )
      return;

    const pctValuesPrevYear = Object.values(livingRootCoverResponsePrevYear.kpi_subtype_summary);
    const healthValuesPrevYear = Object.values(
      livingRootHealthResponsePrevYear.kpi_subtype_summary
    );
    const pctValues = Object.values(livingRootCoverResponse.kpi_subtype_summary);
    const healthValues = Object.values(livingRootHealthResponse.kpi_subtype_summary);

    //Using both years data to get crop year data
    const cropYearPctValues = getCropYearValues(pctValuesPrevYear, pctValues);
    const cropYearHealthValues = getCropYearValues(healthValuesPrevYear, healthValues);

    const dataWithValuesFromResponse = barChartData.map((month, index) => {
      const pct = toFixedFloat(cropYearPctValues[index] * 100);
      const color = getColorFromValue(cropYearHealthValues[index]);
      const calculatedMonth =
        index === 0
          ? `${month.month.split(' ').shift()} ${prevYear}`
          : index === 2
          ? `${month.month.split(' ').shift()} ${currentYear}`
          : month.month;

      return {
        month: calculatedMonth,
        pct,
        color,
      };
    });
    setBarChartData(dataWithValuesFromResponse);
    // Excludes barChartData, since that is also updated within this hook
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    livingRootCoverResponse,
    livingRootHealthResponse,
    livingRootCoverResponsePrevYear,
    livingRootHealthResponsePrevYear,
    currentYear,
    prevYear,
  ]);

  useEffect(() => {
    //Line calculations for confidence
    if (
      !livingRootConfidenceResponse?.kpi_subtype_summary ||
      !livingRootConfidenceResponsePrevYear?.kpi_subtype_summary
    )
      return;

    const confidenceValuesPrevYear = Object.values(
      livingRootConfidenceResponsePrevYear.kpi_subtype_summary
    );
    const confidenceValues = Object.values(livingRootConfidenceResponse.kpi_subtype_summary);
    const cropYearConfidenceValues = getCropYearValues(confidenceValuesPrevYear, confidenceValues);

    setConfidenceLineData(prevState => {
      //Multiplies by 100 to do a 0-100% values
      const newData = cropYearConfidenceValues.map((value, index) => ({x: index, y: value * 100}));

      return [{...prevState[0], data: newData}];
    });
  }, [livingRootConfidenceResponse, livingRootConfidenceResponsePrevYear]);

  const getBarTooltip = (props: BarTooltipDatum) => {
    const getYear = (index: number) => {
      switch (index) {
        case 0:
        case 2:
          return '';
        case 1:
          return prevYear;
        default:
          return currentYear;
      }
    };

    return (
      <div>
        <Portal id="living-root-tooltip-portal">
          <ChartTooltip>
            <Flex
              style={{
                width: '180px',
                height: '100px',
              }}
            >
              <Text variant="medium" style={{width: '100%', textAlign: 'center'}}>{`${
                props.indexValue
              } ${getYear(props.index)}`}</Text>
              <Flex justifyContent="space-between" fullWidth>
                <Text variant="medium">Living root cover</Text>
                <Text variant="medium" bold>{`${toFixedFloat(props.value, 1)}%`}</Text>
              </Flex>
              <Flex justifyContent="space-between" fullWidth>
                <Text variant="medium">Crop health (NDVI)</Text>
                <Text variant="medium" bold>
                  {getNdviValueRangeFromColor(props.color)}
                </Text>
              </Flex>
              <Flex justifyContent="space-between" fullWidth>
                <Text variant="medium">Image availability</Text>
                <Text variant="medium" bold>{`${toFixedFloat(
                  confidenceLineData[0].data[props.index]?.y,
                  1
                )}%`}</Text>
              </Flex>
            </Flex>
          </ChartTooltip>
        </Portal>
      </div>
    );
  };

  return (
    <MultiChartKPICard
      title="Living root"
      subtitle="living root cover per year"
      tooltip={
        currentChart === 0
          ? TOOLTIPS.KPI.livingRoot.sparklineChart
          : TOOLTIPS.KPI.livingRoot.barChart
      }
      afterChange={currentSlide => setCurrentChart(currentSlide)}
      portalId="living-root-tooltip-portal"
      loading={loading}
    >
      <div>
        <ChartMiddleData
          cardCenterNumber={metric === 0 ? 'No Data' : String(`${kiloFormatter(metric)}%`)}
          cardCenterUnits={`${getDaysWithLivingRoot(metric)} days with living root`}
        />
        <SparklineChart
          sparklingChartCaption={`${sparklingChartData.datasets.length} year avg`}
          sparklingChartCaptionBolded={`${kiloFormatter(average)}%`}
          comparisonValues={comparisonValues}
          chartData={{
            labels: sparklingChartData.labels,
            datasets: dataset,
          }}
          chartDataMidline={average}
          linechartLabelSuffix={'%'}
          tooltipCallbacks={{
            afterLabel: item => `${getDaysWithLivingRoot(Number(item.value))} days`,
          }}
        />
      </div>
      <BarContainer>
        <div className="line">
          <ResponsiveLine
            data={confidenceLineData}
            margin={{bottom: 33, left: 55, right: 15, top: 5}}
            yScale={{
              type: 'linear',
              min: 0,
              max: 100,
            }}
            colors={{datum: 'color'}}
            axisTop={null}
            axisRight={null}
            axisBottom={null}
            axisLeft={null}
            enableGridX={false}
            enableGridY={false}
            enablePoints={false}
            useMesh={true}
          />
        </div>

        <ResponsiveBar
          data={barChartData}
          keys={['pct']}
          indexBy="month"
          margin={{top: 5, right: 0, bottom: 33, left: 40}}
          maxValue={100}
          minValue={0}
          gridYValues={[20, 40, 60, 80, 100]}
          padding={0.4}
          valueScale={{type: 'linear'}}
          indexScale={{type: 'band', round: true}}
          axisTop={null}
          axisRight={null}
          axisBottom={{
            tickValues: [`Nov ${prevYear}`, `Jan ${currentYear}`, 'Mar', 'May', 'Jul', 'Sep'],
            tickSize: 0,
            tickPadding: 10,
            tickRotation: 25,
          }}
          axisLeft={{
            tickValues: [0, 20, 40, 60, 80, 100],
            format: value => `${value}%`,
            tickSize: 0,
            tickPadding: 10,
            tickRotation: 0,
          }}
          colors={{datum: 'data.color'}}
          role="application"
          enableLabel={false}
          tooltip={getBarTooltip}
          theme={{tooltip: {container: {color: 'white', background: 'black', opacity: 0}}}}
        />
        <div className="legend">
          <div className="confidence">
            <div className="label">Image Availability %</div>
          </div>
          <div className="health">
            <div>Crop Health</div>
            <div className="buckets">
              {NDVI.slice(0, -1).map(ndviValue => (
                <Squares key={ndviValue.range} color={ndviValue.color} />
              ))}
            </div>
            <div className="labels">
              <Text variant="medium">Bare soil</Text>
              <Text variant="medium">Healthy vegetation</Text>
            </div>
          </div>
        </div>
      </BarContainer>
    </MultiChartKPICard>
  );
};
