import {convertArea} from '@turf/turf';
import {measurementUnits} from 'containers/map/features/nrx';
import {TOOLTIPS} from 'containers/si/constants';
import {useFetchKPI} from 'containers/si/hooks/useFetchKPI';
import {selectSIYearsFilter} from 'containers/si/module/selectors';
import {t} from 'i18n-utils';
import React, {useCallback, useEffect, useState} from 'react';
import {useTheme} from 'styled-components';
import {useAppSelector} from '_hooks';
import {kiloFormatter, toFixedFloat} from '_utils/number-formatters';
import {calculatePercentage} from '_utils/pure-utils';
import {getTypedKeys} from '_utils/object';
import {isDefined} from '_utils/typeGuards';
import {ChartMiddleData} from '../charts/components/chart-middle-data';
import {SparklineChart} from '../charts/components/sparkline-chart';
import {getSparklineDataSetDefinition} from '../charts/components/helpers';
import {MultiChartKPICard} from '../charts/multi-chart-kpi-card';
import {TillagePieContainer, StyledTillageLabel, StyledLinesContainer} from '../styled-components';
import type {ChartDataSets} from 'chart.js';
import type {SIProgramKPISubtype} from 'containers/si/types';
import {LineChart} from '../charts/line-chart';
import {TillageResponsivePie} from '../charts/tillage-responsive-pie';

type chartsDataType = {
  pieData: {
    id: string;
    label: string;
    value: number;
    color: string;
  }[];
  sparklingData: {labels: string[]; datasets: number[]};
  conservationTillagePercentage: number;
  conservationTillage: number;
  prevYearAvgPct: number;
  allYearsAvgPct: number;
};

export const TillageCard = () => {
  const yearsFilter = useAppSelector(selectSIYearsFilter);
  const measurement = useAppSelector(measurementUnits);
  const [chartsData, setChartsData] = useState<chartsDataType>({
    pieData: [],
    sparklingData: {labels: [], datasets: []},
    conservationTillagePercentage: 0,
    conservationTillage: 0,
    prevYearAvgPct: 0,
    allYearsAvgPct: 0,
  });
  const [tillageAverage, setTillageAverage] = useState(0);
  const {response: tillageResponse, loading} = useFetchKPI({
    kpi: 'tillage',
    summarizeBy: ['annualized', 'kpi_subtype', 'annualized_kpi_subtype'],
  });
  const measureUnits = measurement === 'ac' ? 'acres' : 'hectares';
  const theme = useTheme();

  const calculateFixedPercentage = (value: number, total: number) => {
    return toFixedFloat(calculatePercentage(value, total), 2);
  };

  useEffect(() => {
    if (!tillageResponse) return;

    const conventionalAcres = convertArea(
      tillageResponse.kpi_subtype_summary?.till_conv_area_m2 || 0,
      'meters',
      measureUnits
    );
    const reducedTillAcres = convertArea(
      tillageResponse.kpi_subtype_summary?.till_redu_area_m2 || 0,
      'meters',
      measureUnits
    );
    const noTillAcres = convertArea(
      tillageResponse.kpi_subtype_summary?.till_notill_area_m2 || 0,
      'meters',
      measureUnits
    );

    const total = conventionalAcres + reducedTillAcres + noTillAcres;

    const conservationalTillage = noTillAcres + reducedTillAcres;
    const conservationalTillPercentage = calculateFixedPercentage(
      noTillAcres + reducedTillAcres,
      total
    );

    const sparklingData: {labels: string[]; datasets: number[]} = {labels: [], datasets: []};

    Object.entries(tillageResponse.annualized_summary ?? []).forEach(([year, summary]) => {
      const acres = convertArea(summary, 'meters', 'acres');
      sparklingData.labels.push(String(year));
      sparklingData.datasets.push(acres);
    });

    // Calculate average
    const annualizedSum = sparklingData.datasets.reduce((a, c) => a + c, 0);
    const tillageAvg = annualizedSum / sparklingData.datasets.length;

    setTillageAverage(tillageAvg);

    const recentYearTillage = tillageResponse.annualized_summary?.[2021] ?? 0;
    const prevYearTilleage = tillageResponse.annualized_summary?.[2020] ?? 0;

    const recentYearTillageAcres = convertArea(recentYearTillage, 'meters', measureUnits);

    const prevYearAvgPct = toFixedFloat(
      calculatePercentage(recentYearTillage - prevYearTilleage, recentYearTillage),
      0
    );

    const allYearsAvgPct = toFixedFloat(
      calculatePercentage(recentYearTillageAcres - tillageAvg, recentYearTillageAcres),
      0
    );

    setChartsData({
      pieData: [
        {
          id: 'No-till',
          label: 'No-till',
          value: calculateFixedPercentage(noTillAcres, total),
          color: theme.colorPalette.fs_main.green_600,
        },
        {
          id: 'Reduced-till',
          label: 'Reduced Till',
          value: calculateFixedPercentage(reducedTillAcres, total),
          color: theme.colorPalette.fs_main.green_400,
        },
        {
          id: 'Conventional',
          label: 'Conventional',
          value: calculateFixedPercentage(conventionalAcres, total),
          color: theme.colorPalette.fs_main.orange_400,
        },
      ],
      sparklingData,
      conservationTillagePercentage: conservationalTillPercentage,
      conservationTillage: conservationalTillage,
      prevYearAvgPct,
      allYearsAvgPct,
    });
  }, [tillageResponse, measureUnits, theme]);

  const getLinesData = useCallback(() => {
    if (!tillageResponse) return {datasets: [], labels: []};

    const getPracticeChartDefinitions = (
      tillagePractice: SIProgramKPISubtype['tillage']
    ): Partial<ChartDataSets> => {
      switch (tillagePractice) {
        case 'till_conv_area_m2':
          return {
            borderColor: theme.colorPalette.fs_main.orange_400,
            label: 'Conventional till',
          };

        case 'till_notill_area_m2':
          return {
            borderColor: theme.colorPalette.fs_main.green_600,
            label: 'No till',
          };
        case 'till_redu_area_m2':
          return {
            borderColor: theme.colorPalette.fs_main.green_400,
            label: 'Reduced till',
          };
      }
    };

    const getSum = (value: Record<string, number>) => {
      return Object.values(value).reduce((a, b) => a + b);
    };
    const labels = isDefined(tillageResponse.annualized_kpi_subtype_summary)
      ? getTypedKeys(tillageResponse.annualized_kpi_subtype_summary)
      : [];

    const datasets: ChartDataSets[] = [
      'till_conv_area_m2' as const,
      'till_redu_area_m2' as const,
      'till_notill_area_m2' as const,
    ].map(practice => {
      return {
        fill: false,
        tension: 0.15,
        pointRadius: 4,
        pointHoverRadius: 5,
        pointBackgroundColor: 'white',
        borderWidth: 1.5,
        data: isDefined(tillageResponse.annualized_kpi_subtype_summary)
          ? Object.values(tillageResponse.annualized_kpi_subtype_summary).map(value => {
              if (isDefined(value)) {
                const total = getSum(value);
                const pct = calculateFixedPercentage(value[practice], total);

                return pct;
              } else return 0;
            })
          : [],
        ...getPracticeChartDefinitions(practice),
      };
    });

    return {datasets, labels};
  }, [tillageResponse, theme]);

  return (
    <MultiChartKPICard
      className="kpi-pie-with-sparkling-container"
      title={t({id: 'Tillage'})}
      subtitle={t({id: 'area of cropland practicing conservation tillage'})}
      tooltip={TOOLTIPS.KPI.tillage}
      lazyLoad="ondemand"
      loading={loading}
    >
      <div>
        <ChartMiddleData
          cardCenterNumber={<div>{chartsData.conservationTillagePercentage ?? 0}%</div>}
          cardCenterUnits={
            <div className="label">{`${kiloFormatter(chartsData.conservationTillage)} acres`}</div>
          }
        />
        <SparklineChart
          comparisonValues={[
            {
              label: `Compared to ${yearsFilter[0] - 1}`,
              value: `${chartsData.prevYearAvgPct}%`,
              direction: chartsData.prevYearAvgPct > 0 ? 'positive' : 'negative',
            },
            {
              label: `Compared to ${chartsData.sparklingData.datasets.length} year avg.`,
              value: `${chartsData.allYearsAvgPct}%`,
              direction: chartsData.allYearsAvgPct > 0 ? 'positive' : 'negative',
            },
          ]}
          sparklingChartCaption="5 year avg"
          sparklingChartCaptionBolded={`${kiloFormatter(tillageAverage)} ac`}
          chartData={{
            labels: chartsData.sparklingData.labels,
            datasets: getSparklineDataSetDefinition(chartsData.sparklingData.datasets),
          }}
          chartDataMidline={tillageAverage}
        />
      </div>
      <TillagePieContainer>
        <TillageResponsivePie data={chartsData.pieData} />
        <StyledTillageLabel>
          <div className="label">
            Conservation tillage
            <span className="label-value">{chartsData.conservationTillagePercentage ?? 0}%</span>
          </div>
        </StyledTillageLabel>
      </TillagePieContainer>
      <StyledLinesContainer>
        <LineChart data={{...getLinesData()}} />
      </StyledLinesContainer>
    </MultiChartKPICard>
  );
};
