import {useCallback, useEffect, useMemo, useState} from 'react';
import {useHistory} from 'react-router-dom';
import useSWR, {mutate} from 'swr';
import {MRV_SERVICE_URI} from '_api/mrv';
import Server from '_api/server';
import type {PartialBy} from '_utils/type-utils';
import {isArray, isDefined} from '_utils/typeGuards';
import type {DashboardData, DashboardRelationData} from '../types';

const API_URLS = {
  /**
   * GET: returns all full reporting dashboards
   * PUT: creates full dashboard/s
   */
  dashboardsFull: '/reporting_dashboards/full',

  /**
   * DELETE: deletes a specified dashboard
   * NOTE DOES NOT DELETE SECTIONS, ROWS, OR CHARTS
   */
  dashboards: '/reporting_dashboards',

  /**
   * Dashboard for a dashboard id without program associated
   * SUPER_ADMIN ONLY
   */
  dashboardById: (dashboardId: string | number) => `/reporting_dashboards/${dashboardId}`,
  /**
   * Return dashboard for a dashboard id
   */
  getDashboardFull: (programId: string | number, dashboardKey: string) =>
    `/programs/${programId}/reporting_dashboards/full/key/${dashboardKey}`,
  /**
   * Return all reporting dashboards associated with a program
   */
  getProgramDashboards: (programId: number | string) =>
    `/programs/${programId}/reporting_dashboards/full`,

  getProgramsDashboardRelations: (programId: number | string) =>
    `/programs/${programId}/reporting_dashboard_to_program`,

  patchDisableProgramDashboard: (
    programId: number | string,
    dashboardToProgramId: string | number
  ) => `/programs/${programId}/reporting_dashboard_to_program/${dashboardToProgramId}`,
};

export const useGetDashboards = (programId?: string | number) => {
  const api = useMemo(
    () =>
      isDefined(programId) ? API_URLS.getProgramDashboards(programId) : API_URLS.dashboardsFull,
    [programId]
  );
  const swrRes = useSWR<DashboardData[], {[key: string]: string}>(api);

  return swrRes;
};

export const useGetDashboard = (programId: string | number, dashboardKey: string) => {
  const api = useMemo(
    () => API_URLS.getDashboardFull(programId, dashboardKey),
    [programId, dashboardKey]
  );
  const swrRes = useSWR<DashboardData, {[key: string]: string}>(api);

  return swrRes;
};

export const useToggleDisabledDashboard = (programId?: string | number) => {
  return useCallback(
    (dashboardToProgramId: string | number, dashboardKey: string, disabled: boolean) => {
      const url = isDefined(programId)
        ? API_URLS.patchDisableProgramDashboard(programId, dashboardToProgramId)
        : null;

      Server.patch(`${MRV_SERVICE_URI}${url}`, {
        disabled,
      }).then(
        //  mutate endpoints to revalidate queries
        () => {
          mutate(API_URLS.dashboardsFull);
          if (programId !== undefined) {
            mutate(API_URLS.getDashboardFull(programId, dashboardKey));
            mutate(API_URLS.getProgramDashboards(programId));
            mutate(API_URLS.getProgramsDashboardRelations(programId));
          }
        }
      );
    },
    [programId]
  );
};

/**
 * All dashboard relations for a program
 */
export const useGetProgramDashboardsRelations = (programId?: string | number) => {
  const api = isDefined(programId) ? API_URLS.getProgramsDashboardRelations(programId) : null;
  const swrRes = useSWR<DashboardRelationData[], string>(api);

  return swrRes;
};

/**
 * Singular dashboard relation for a program
 */
export const useGetProgramDashboardRelation = (
  programId?: string | number,
  dashboardKey?: string
) => {
  const [dashboardRelation, setDashboardRelation] = useState<DashboardRelationData>();
  const api = programId !== undefined ? API_URLS.getProgramsDashboardRelations(programId) : null;

  const swrRes = useSWR<DashboardRelationData[], string>(
    isDefined(programId) && isDefined(dashboardKey) ? api : null
  );

  // Return only the dashboard relation data we are interested in
  useEffect(() => {
    if (swrRes.data) {
      const foundRelation = swrRes.data?.find?.(d => d.key === dashboardKey);
      if (foundRelation) {
        setDashboardRelation(foundRelation);
      }
    }
  }, [dashboardKey, swrRes.data]);

  return {...swrRes, data: dashboardRelation};
};

// Gets all related dashboard data and relation info
export const useGetDashboardsAndRelations = (programId?: string | number) => {
  const {data: dashboardsData, isLoading: getDashboardsIsLoading} = useGetDashboards(programId);
  const {data: dashboardRelationsData, isLoading: getRelationsIsLoading} =
    useGetProgramDashboardsRelations(programId);

  const data = useMemo(() => {
    // On 404 data is still returned, but it's an object not an array
    if (!isArray(dashboardRelationsData) || !isArray(dashboardsData)) {
      return {};
    }
    const returnData: {
      [key: string]: DashboardData & {
        dashboardToProgramId: number;
        disabled: boolean;
      };
      // eslint-disable-next-line no-new-object
    } = {};

    dashboardRelationsData?.forEach?.(relation => {
      const dashboardData = dashboardsData?.find?.(d => d?.id === relation?.dashboard_id);
      if (!dashboardData) {
        return;
      }

      returnData[relation.key] = {
        dashboardToProgramId: relation?.id,
        disabled: relation?.disabled,
        ...(dashboardData ?? []),
      };
    });
    return returnData;
  }, [dashboardRelationsData, dashboardsData]);

  return {data, isLoading: getDashboardsIsLoading || getRelationsIsLoading};
};

export const useGetIsDashboardDisabled = (dashboardKey: string, programId: number) => {
  const {data, isLoading} = useGetProgramDashboardsRelations(programId);

  const res = useMemo(() => {
    return {
      isDisabled: !!data?.find?.(d => d.key === dashboardKey)?.disabled,
      isLoading: isLoading,
    };
  }, [dashboardKey, data, isLoading]);

  return res;
};

export const useRedirectIfDashboardIsDisabled = (dashboardKey: string, programId: number) => {
  const {isDisabled, isLoading} = useGetIsDashboardDisabled(dashboardKey, programId);
  const history = useHistory();

  if (isDisabled && !isLoading) {
    history.push(`/mrv/admin/programs/${programId}/configuration/assets`);
  }
};

export const useCreateDashboard = () => {
  return useCallback((dashboard: DashboardData) => {
    const url = API_URLS.dashboardsFull;

    return Server.post<DashboardData>(`${MRV_SERVICE_URI}${url}`, [dashboard]).then(
      //  mutate endpoints to revalidate queries
      res => {
        mutate(API_URLS.dashboardsFull);
        mutate(API_URLS.dashboards);
        return res.data;
      }
    );
  }, []);
};

export const useDeleteDashboard = () => {
  return useCallback((dashboardId?: number) => {
    const url = isDefined(dashboardId) ? API_URLS.dashboardsFull : null;

    return Server.delete<DashboardData>(`${MRV_SERVICE_URI}${url}`, {
      data: [dashboardId],
    }).then(
      //  mutate endpoints to revalidate queries
      res => {
        mutate(API_URLS.dashboardsFull);
        if (isDefined(dashboardId)) {
          mutate(API_URLS.dashboardById(dashboardId));
        }
        return res.data;
      }
    );
  }, []);
};

export const useEditDashboard = () => {
  return useCallback((dashboard: Omit<DashboardData, 'Sections'>) => {
    const url = API_URLS.dashboardById(dashboard.id);

    return Server.patch<DashboardData>(`${MRV_SERVICE_URI}${url}`, dashboard).then(
      //  mutate endpoints to revalidate queries
      () => {
        mutate(API_URLS.dashboardsFull);
        mutate(API_URLS.dashboardById(dashboard.id));
      }
    );
  }, []);
};

export const useEditDashboardRelation = () => {
  return useCallback((dashboardRelation?: PartialBy<DashboardRelationData, 'id'>) => {
    const url = isDefined(dashboardRelation)
      ? isDefined(dashboardRelation?.id)
        ? API_URLS.patchDisableProgramDashboard(dashboardRelation.program_id, dashboardRelation.id)
        : API_URLS.getProgramsDashboardRelations(dashboardRelation.program_id)
      : null;

    const serverMethod = isDefined(dashboardRelation?.id) ? Server.patch : Server.post;
    const body = isDefined(dashboardRelation?.id)
      ? dashboardRelation
      : [dashboardRelation?.dashboard_id];

    serverMethod(`${MRV_SERVICE_URI}${url}`, body).then(
      //  mutate endpoints to revalidate queries
      () => {
        mutate(API_URLS.dashboardsFull);
        if (isDefined(dashboardRelation)) {
          mutate(API_URLS.getDashboardFull(dashboardRelation.program_id, dashboardRelation.key));
          mutate(API_URLS.getProgramDashboards(dashboardRelation.program_id));
          mutate(API_URLS.getProgramsDashboardRelations(dashboardRelation.program_id));
        }
      }
    );
  }, []);
};
