import {InfoBlock} from 'components';
import {t} from 'i18n-utils';
import React from 'react';
import {useAppSelector} from '_hooks';
import {selectEnrollmentReadOnly} from '../carbon-store/selectors';

export const ReadonlyMessage = () => {
  const {isReadOnly, detailedReason} = useAppSelector(selectEnrollmentReadOnly);
  if (!isReadOnly) {
    return null;
  }
  return (
    <InfoBlock icon="lock" mini appearance="info" color="info">
      {t(
        {
          id: 'EnrollSectionLockReason',
          defaultMessage: 'This section is locked, because {detailedReason}.',
        },
        {detailedReason}
      )}
    </InfoBlock>
  );
};
