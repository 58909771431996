export const LOAD_PRE_SIGN_UP_USER_DATA = 'sign-up/pre-sign-up-load';
export const SEND_REGISTRATION_FORM = 'sign-up/send-registration-form';

const initialState = {
  firstName: '',
  surname: '',
  email: '',
  phone: '',
  password: '',
  confirmPassword: '',
  interestClass: 'agronomist',
  promocode: '',
  isRegistrationSent: false,
};

export default (state = initialState, action: any) => {
  switch (action.type) {
    case LOAD_PRE_SIGN_UP_USER_DATA:
      if (action.result && !action.result.interestClass) {
        action.result.interestClass = initialState.interestClass;
      }

      return {
        ...state,
        ...action.result,
      };

    case SEND_REGISTRATION_FORM:
      return {
        ...state,
        isRegistrationSent: !state.isRegistrationSent,
      };

    default:
      return state;
  }
};
