import type {Query} from '@cubejs-client/core';
import type {AxiosPromise} from 'axios';
import axios from 'axios';
import {classifyInviteUsersPayload, createPhase} from 'containers/mrv/admin/base/base';
import type {DocusignContractEvent} from 'containers/mrv/monitoring/module/types';
import type {ProgramRegisterConfig} from 'containers/mrv/sign-up-confirmation/types';
import type {
  AddAUserToAProgramFormData,
  InvitedNewUser,
  MRVAttribute,
  MRVAttributeInput,
  MRVStage,
  MRVStageInput,
  MRVProgram,
  MRVProgramInput,
  MRVProgramStats,
  MRVProject,
  ProgramAdminUser,
  MRVValue,
  MRVValuesInput,
  SignUpFormData,
  StageCompletion,
  MRVCropType,
  MRVRegion,
  EnrollmentImageBlobType,
  MRVNotification,
  MRVPhaseNormalized,
  MRVProjectEligibility,
  MRVProjectConfig,
  ProgramInvite,
  MRVSurvey,
  MRVCommercialRuleData,
  MRVStageNormalized,
  EligibilityMethodItem,
  FmsOption,
  MRVProgramCustomRegInput,
  MRVCustomInput,
  BulkUpdateFieldData,
  MRVEntityType,
  MRVMob,
  MRVFarm,
} from 'containers/mrv/types';
import type {
  ProgramConflictsResponse,
  DNDCResultsResponse,
  GetProjectParams,
  ConflictStatus,
  ProgramConflictsFilter,
  ProjectConflictFile,
  Conflict,
  OptisAttributeMappingPayload,
  HistoryRow,
} from '_api/mrv.types';
import _config from '_environment';
import {downloadFile, getGetURLParam} from '_utils/pure-utils';
import Server from './server';

export const MRV_SERVICE_URI = 'mrv';
export const MRV_SERVICE_STATIC_URI = `${MRV_SERVICE_URI}/static`;

// MRV - Monitoring, Reporting, Verifications
export class MRVApi {
  // A program is a version/config of the inputs to gather.
  // I.e. the very first program is gether operations for Cargill.
  static getPrograms(
    params: {
      page?: number;
      /**
       * Server default: 50
       */
      size?: number;
    } = {}
  ) {
    return Server.get<{items: MRVProgram[]; page: number; size: number; total: number}>(
      `${MRV_SERVICE_URI}/programs`,
      {
        params: {__skipPreloader: true, ...params},
      }
    );
  }

  static getProgramStats(ids: number[]) {
    return Server.get<{[programId: string]: MRVProgramStats}>(
      `${MRV_SERVICE_URI}/programs/stats?ids=${ids}`,
      {
        params: {__skipPreloader: true},
      }
    );
  }

  static getProgramDump(
    programId: number,
    deletedFields: boolean,
    deletedProjects: boolean,
    emailFilter?: string | number
  ) {
    return Server.get<Blob>(`${MRV_SERVICE_URI}/programs/${programId}/dump_program_data`, {
      params: {
        __skipPreloader: true,
        deleted_fields: deletedFields,
        deleted_projects: deletedProjects,
        ...(emailFilter ? {email_filter: emailFilter} : {}),
      },
      responseType: 'blob',
    }).then(response => {
      downloadFile(response.data, `${programId}_program_dump.zip`, 'application/zip');
    });
  }

  static getProgram(programId: number) {
    return Server.get<MRVProgram>(`${MRV_SERVICE_URI}/programs/${programId}`, {
      params: {__skipPreloader: true},
    });
  }

  static addProgram(program: MRVProgramInput) {
    return Server.post<MRVProgram[]>(`${MRV_SERVICE_URI}/programs`, [program], {
      params: {__skipPreloader: true},
    });
  }

  static updateProgram(program: Partial<MRVProgram>) {
    return Server.patch<MRVProgram[]>(`${MRV_SERVICE_URI}/programs/${program.id}`, program, {
      params: {__skipPreloader: true},
    });
  }

  static removeProgram(programId: number) {
    return Server.delete(`${MRV_SERVICE_URI}/programs/${programId}`, {
      params: {__skipPreloader: true},
    });
  }

  static addStage(programId: number, phaseId: number, stage: MRVStageInput) {
    return Server.post<MRVStage[]>(
      `${MRV_SERVICE_URI}/programs/${programId}/phases/${phaseId}/stages`,
      // hack to add empty string for name, until name is removed from the backend
      [{...stage, name: ''}],
      {
        params: {__skipPreloader: true},
      }
    );
  }

  static removeStage(programId: number, phaseId: number, stageId: number) {
    return Server.delete(`${MRV_SERVICE_URI}/programs/${programId}/phases/${phaseId}/stages`, {
      data: [stageId],
      params: {__skipPreloader: true},
    });
  }

  static updateStage(
    programId: number,
    phaseId: number,
    stageId: number,
    stage: Partial<MRVStageInput>
  ) {
    return Server.patch<MRVStage>(
      `${MRV_SERVICE_URI}/programs/${programId}/phases/${phaseId}/stages/${stageId}`,
      stage,
      {
        params: {__skipPreloader: true},
      }
    );
  }

  static updateStages(programId: number, phaseId: number, stages: MRVStageNormalized[]) {
    return Server.patch<MRVStage[]>(
      `${MRV_SERVICE_URI}/programs/${programId}/phases/${phaseId}/stages`,
      stages,
      {
        params: {__skipPreloader: true},
      }
    );
  }

  static addAttributes(programId: number, phaseId: number, attributes: MRVAttributeInput[]) {
    return Server.post<MRVAttribute[]>(
      `${MRV_SERVICE_URI}/programs/${programId}/phases/${phaseId}/attributes`,
      attributes,
      {
        params: {__skipPreloader: true},
      }
    );
  }

  static updateAttribute(
    programId: number,
    phaseId: number,
    attributeId: number,
    attribute: MRVAttributeInput
  ) {
    return Server.patch<MRVAttribute[]>(
      `${MRV_SERVICE_URI}/programs/${programId}/phases/${phaseId}/attributes/${attributeId}`,
      attribute,
      {
        params: {__skipPreloader: true},
      }
    );
  }

  static updateAttributes(programId: number, phaseId: number, attributes: MRVAttributeInput[]) {
    return Server.patch<MRVAttribute[]>(
      `${MRV_SERVICE_URI}/programs/${programId}/phases/${phaseId}/attributes`,
      attributes,
      {
        params: {__skipPreloader: true},
      }
    );
  }

  static removeAttribute(programId: number, phaseId: number, attributeId: number) {
    return Server.delete(`${MRV_SERVICE_URI}/programs/${programId}/phases/${phaseId}/attributes`, {
      data: [attributeId],
      params: {__skipPreloader: true},
    });
  }

  static getStageIcons() {
    return Server.get<Record<string, string>>(`${MRV_SERVICE_URI}/programs/stage_icons`, {
      params: {__skipPreloader: true},
    });
  }

  static getIconUrl(url: string) {
    return `${_config.baseUrl}api/v1/${MRV_SERVICE_URI}${url}`;
  }

  static getStageCompletion(projectId: number, stageId: number): AxiosPromise<StageCompletion> {
    return Server.get(`${MRV_SERVICE_URI}/projects/${projectId}/stages/${stageId}/completion`, {
      params: {__skipPreloader: true},
    });
  }

  static createRecordYears(projectId: number): AxiosPromise<void> {
    return Server.post(`${MRV_SERVICE_URI}/projects/${projectId}/record_years`, {
      params: {__skipPreloader: true, __skipError: true},
    });
  }

  static updateEntityValues(
    projectId: number,
    update: MRVValuesInput,
    entityType: MRVEntityType,
    completion = false
  ): AxiosPromise<{values: MRVValue[]; completion?: StageCompletion; stage_id?: number}> {
    const completionQuery = completion ? '&completion_stats=true' : '';
    return Server.put(
      `${MRV_SERVICE_URI}/projects/${projectId}/entities/values?entity_type=${entityType}${completionQuery}`,
      update,
      {
        params: {__skipPreloader: true},
      }
    );
  }

  static removeProjectValues(
    projectId: number,
    valueIds: number[],
    entityType: MRVEntityType
  ): AxiosPromise<{values: MRVValue[]; completion?: StageCompletion; stage_id?: number}> {
    return Server.delete(
      `${MRV_SERVICE_URI}/projects/${projectId}/entities/values?completion_stats=true&entity_type=${entityType}`,
      {
        data: valueIds,
        params: {__skipPreloader: true},
      }
    );
  }

  static addProjectFields(
    projectId: number,
    fields: {fs_field_id: number; farm_id: number}[]
  ): AxiosPromise<MRVValuesInput> {
    const rerunOptis = getGetURLParam('rerunOptis');
    return Server.post(`${MRV_SERVICE_URI}/projects/${projectId}/fields`, fields, {
      params: {
        __skipPreloader: true,
        undelete: true,
        ...(rerunOptis ? {rerun_optis: true} : {}),
      },
    });
  }

  static removeProjectFields(projectId: number, fieldIds: number[]): AxiosPromise<null> {
    return Server.delete(`${MRV_SERVICE_URI}/projects/${projectId}/fields`, {
      data: fieldIds,
      params: {__skipPreloader: true},
    });
  }

  static getProject(projectId: number, params: GetProjectParams = {}): AxiosPromise<MRVProject[]> {
    const defaultParams = {
      include_core_attributes: true,
      include_user_sync_info: false,
    };

    return Server.get(`${MRV_SERVICE_URI}/projects/${projectId}`, {
      params: {__skipPreloader: true, ...defaultParams, ...params},
    });
  }

  static addProject(programId: number) {
    return Server.post<MRVProject[]>(`${MRV_SERVICE_URI}/projects`, [{program_id: programId}], {
      params: {__skipPreloader: true},
    });
  }

  static inviteMRvProducer(formData: AddAUserToAProgramFormData) {
    return Server.post<{
      result: {projects: Record<string, MRVProject[]>; new_invites: Record<string, ProgramInvite>};
    }>(
      `${MRV_SERVICE_URI}/invites`,
      {invites: classifyInviteUsersPayload(formData), permission: 'producer'},
      {
        params: {__skipPreloader: true},
      }
    );
  }

  static inviteMRvProgramAdmin(formData: AddAUserToAProgramFormData) {
    return Server.post<{
      result: {
        programs: Record<string, ProgramAdminUser[]>;
      };
    }>(
      `${MRV_SERVICE_URI}/invites`,
      {invites: classifyInviteUsersPayload(formData), permission: 'admin'},
      {
        params: {__skipPreloader: true},
      }
    );
  }

  static getNewUserData(uuid: string) {
    return Server.get<{result: InvitedNewUser}>(`mrv-sign-up/uuid/${uuid}`, {
      params: {__skipPreloader: true},
    });
  }

  static signUpNewMrvUser(data: SignUpFormData, uuid: string) {
    return Server.post<{result: {program?: MRVProgram; project?: MRVProject[]}}>(
      `mrv-sign-up/uuid/${uuid}`,
      data,
      {
        params: {__skipPreloader: true},
      }
    );
  }

  static selfRegisterNewUser(data: SignUpFormData, programCode: string) {
    return Server.post<{result: {program?: MRVProgram; project?: MRVProject[]}}>(
      `mrv-sign-up/program/${programCode}`,
      data
    );
  }

  static enrollUserToProgram(programCode: string) {
    return Server.post<null | {detail: string}>(
      `${MRV_SERVICE_URI}/program_codes/${programCode}/enrol`
    );
  }
  static enrollUserToProgramExceptionTypes = {
    ALREADY_ENROLLED: 'User already enrolled in program.',
    ENROLLMENT_CLOSED: 'Enrolment or self enrol is closed.',
    PROGRAM_ID_MISSING: 'Program ID missing from program code?',
    USER_ID_HEADER_MISSING: 'UserID header missing.',
  } as const;

  static getProgramProjects({
    programId,
    pageToFetch,
    searchText,
    perPage = 25,
    onlyWithContractDeletionReason = false,
  }: {
    programId: number;
    pageToFetch: number;
    searchText?: string;
    perPage?: number;
    onlyWithContractDeletionReason?: boolean;
  }) {
    const searchString = !!searchText ? `&search_string=${searchText}` : '';
    const withContractDeletionQueryParam = !!onlyWithContractDeletionReason
      ? '&only_with_contract_deletion_request=true'
      : '';
    return Server.get<{items: MRVProject[]; size: number; page: number; total: number}>(
      `${MRV_SERVICE_URI}/programs/${programId}/projects?include_user_sync_info=true&page=${pageToFetch}&size=${perPage}${searchString}${withContractDeletionQueryParam}`,
      {
        params: {__skipPreloader: true},
      }
    );
  }
  // user roles

  static createProgramAdmin(programId: number, user_id: number): AxiosPromise<ProgramAdminUser[]> {
    return Server.post(`${MRV_SERVICE_URI}/programs/${programId}/users`, [{user_id, details: {}}], {
      params: {__skipPreloader: true},
    });
  }

  static deleteProgramAdmin(programId: number, userId: number): AxiosPromise<ProgramAdminUser[]> {
    return Server.delete(`${MRV_SERVICE_URI}/programs/${programId}/users`, {
      data: [userId],
      params: {__skipPreloader: true},
    });
  }

  static addProjectProducer(projectId: number, user: number): AxiosPromise<ProgramAdminUser[]> {
    return Server.post(`${MRV_SERVICE_URI}/projects/${projectId}/users`, [{user, details: {}}], {
      params: {__skipPreloader: true},
    });
  }

  static deleteProjectProducer(
    projectId: number,
    userId: number
  ): AxiosPromise<ProgramAdminUser[]> {
    return Server.delete(`${MRV_SERVICE_URI}/projects/${projectId}/users`, {
      data: [userId],
      params: {__skipPreloader: true},
    });
  }

  static impersonateProducer(programId: number, userId: number): AxiosPromise<{result: string}> {
    return Server.post(
      `${MRV_SERVICE_URI}/programs/${programId}/users/${userId}/impersonate`,
      {},
      {
        params: {__skipPreloader: true},
      }
    );
  }

  static getUserPermissions() {
    return Server.get<{program_admin: number[]; project_producer: number[]}>(`mrv/user`, {
      params: {__skipPreloader: true},
    });
  }

  static addProgramPhase(programId: number, phase: Partial<MRVPhaseNormalized>) {
    const newPhase = Object.assign(
      createPhase(phase.type_, phase.start_date, phase.end_date),
      phase
    );
    // const phase = {...createPhase(phaseType, startDate, endDate), name: phase.type_};

    return Server.post<MRVProgram>(
      `${MRV_SERVICE_URI}/programs/${programId}/phases`,
      {...newPhase, name: phase.type_},
      {
        params: {__skipPreloader: true},
      }
    );
  }

  static updateProgramPhase(
    programId: number,
    phaseId: number,
    phase: Partial<MRVPhaseNormalized>
  ) {
    return Server.patch<MRVProgram>(
      `${MRV_SERVICE_URI}/programs/${programId}/phases/${phaseId}`,
      phase,
      {
        params: {__skipPreloader: true},
      }
    );
  }

  static addProgramAssets(programId: number, assets: string[]) {
    return Server.post<MRVProgram>(`${MRV_SERVICE_URI}/programs/${programId}/assets`, assets, {
      params: {__skipPreloader: true},
    });
  }

  static removeProgramAssets(programId: number, assets: string[]) {
    return Server.delete(`${MRV_SERVICE_URI}/programs/${programId}/assets`, {
      data: assets,
      params: {__skipPreloader: true},
    });
  }

  static addProgramPracticeChanges(programId: number, practiceChanges: string[]) {
    return Server.post<MRVProgram>(
      `${MRV_SERVICE_URI}/programs/${programId}/practice_changes`,
      practiceChanges,
      {
        params: {__skipPreloader: true},
      }
    );
  }

  static removeProgramPracticeChanges(programId: number, practiceChanges: string[]) {
    return Server.delete(`${MRV_SERVICE_URI}/programs/${programId}/practice_changes`, {
      data: practiceChanges,
      params: {__skipPreloader: true},
    });
  }

  static getInfoAssets() {
    return Server.get<{asset_types: string[]}>(`${MRV_SERVICE_URI}/programs/assets`, {
      params: {__skipPreloader: true},
    });
  }

  static getInfoPracticeChanges() {
    return Server.get<{practice_changes: string[]}>(
      `${MRV_SERVICE_URI}/programs/practice_changes`,
      {
        params: {__skipPreloader: true},
      }
    );
  }

  static getInfoAttributeLabels() {
    // The return structure is {attributeType: attributeLabel}.
    return Server.get<{[attributeType: string]: string}>(
      `${MRV_SERVICE_URI}/programs/attribute_types`,
      {
        params: {__skipPreloader: true},
      }
    );
  }

  static getPracticeIconUrl(name: string) {
    return `${_config.baseUrl}api/v1/${MRV_SERVICE_STATIC_URI}/practice_changes/${name}`;
  }

  static addCropType(programId: number, cropTypes: MRVCropType[]) {
    return Server.post<MRVProgram>(
      `${MRV_SERVICE_URI}/programs/${programId}/crop_types?program_id=${programId}`,
      cropTypes,
      {
        params: {__skipPreloader: true},
      }
    );
  }

  static removeCropType(programId: number, mrvCropTypeIds: number[]) {
    return Server.delete<MRVProgram>(
      `${MRV_SERVICE_URI}/programs/${programId}/crop_types?program_id=${programId}`,
      {
        data: mrvCropTypeIds,
        params: {__skipPreloader: true},
      }
    );
  }

  static getProgramSelfRegisterConfig(code: string) {
    return Server.get<{result: ProgramRegisterConfig}>(`mrv-sign-up/program/${code}`, {
      params: {__skipPreloader: true},
    });
  }

  static callDocusignCallBack({state, code}: {state: string; code: string}) {
    return Server.get(`${MRV_SERVICE_URI}/docusign/callback?state=${state}&code=${code}`, {
      params: {__skipPreloader: true},
    });
  }

  static generateProjectContract({
    projectId,
    phaseId,
    redirect_url,
  }: {
    projectId: number;
    phaseId: number;
    redirect_url: string;
  }) {
    return Server.post<string>(
      `${MRV_SERVICE_URI}/projects/${projectId}/phases/${phaseId}/contract`,
      {
        redirect_url,
      },
      {
        params: {__skipPreloader: true},
      }
    );
  }

  static getContractAttributes({programId, phaseId}: {programId: number; phaseId: number}) {
    return Server.get<{id: number; name: string}[]>(
      `${MRV_SERVICE_URI}/programs/${programId}/phases/${phaseId}/contract/attributes`,
      {
        params: {__skipPreloader: true},
      }
    );
  }

  static updateContractAttributes({
    programId,
    phaseId,
    attributeIds,
  }: {
    programId: number;
    phaseId: number;
    attributeIds: number[];
  }) {
    return Server.put(
      `${MRV_SERVICE_URI}/programs/${programId}/phases/${phaseId}/contract/attributes`,
      attributeIds,
      {
        params: {__skipPreloader: true},
      }
    );
  }

  static uploadEnrollmentContractTemplate(phaseId: number, templateFile: File) {
    const data = new FormData();
    data.append('file', templateFile);
    return Server.post<string>(
      `${MRV_SERVICE_URI}/docusign/contract_template/phases/${phaseId}`,
      data,
      {
        params: {__skipPreloader: true},
      }
    );
  }

  static getEnrollmentContractTemplate(plaseId: number) {
    return Server.get<string>(`${MRV_SERVICE_URI}/docusign/contract_template/phases/${plaseId}`, {
      params: {__skipPreloader: true},
    });
  }

  static deleteEnrollmentContractTemplate(phaseId: number) {
    return Server.delete<string>(
      `${MRV_SERVICE_URI}/docusign/contract_template/phases/${phaseId}`,
      {
        params: {__skipPreloader: true},
      }
    );
  }

  static requestEnrollmentContractRemoving(
    // a producer can request the contract deletion, then an admin should accept it before actually deleting it
    projectId: number,
    reason: string
  ) {
    return Server.post<{deletion_reason: string; id: number; project: number}>(
      `${MRV_SERVICE_URI}/projects/${projectId}/contract/deletion_request`,
      {
        deletion_reason: reason,
      },
      {
        params: {
          __skipPreloader: true,
        },
      }
    );
  }

  static getProjectContract(projectId: number) {
    return Server.get<BlobPart[]>(`${MRV_SERVICE_URI}/projects/${projectId}/contract`, {
      params: {
        __skipPreloader: true,
      },
      responseType: 'blob',
    });
  }

  static deleteProjectContract(
    // a producer can request the contract deletion, then an admin should accept it before actually deleting it
    projectId: number
  ) {
    return Server.delete<string>(`${MRV_SERVICE_URI}/projects/${projectId}/contract`, {
      params: {
        __skipPreloader: true,
      },
    });
  }

  static getRegions(country = 'USA') {
    return Server.get<{level: 'STATE'; name: string}[]>(
      `${MRV_SERVICE_URI}/regions/names?country=${country}`,
      {
        params: {__skipPreloader: true},
      }
    );
  }

  static updateDocusignContractStatus({
    projectId,
    event,
  }: {
    projectId: number;
    event: DocusignContractEvent;
  }) {
    return Server.get(
      `${MRV_SERVICE_URI}/projects/${projectId}/docusign/callback?event=${event}&include_dndc_status=true&include_ofs_status=true`,
      {
        params: {__skipPreloader: true},
      }
    );
  }

  static getRegionsByName(regionName?: string, stateName?: string) {
    return Server.get<MRVRegion[]>(
      `${MRV_SERVICE_URI}/regions/names?name_filter=${regionName}&state_name_filter=${stateName}`,
      {
        params: {__skipPreloader: true},
      }
    );
  }

  static getRegionGeometry(region: MRVRegion) {
    return Server.post<MRVRegion[]>(`${MRV_SERVICE_URI}/regions/filter`, region, {
      params: {__skipPreloader: true},
    });
  }

  static getProgramBoundaries(programId: number) {
    return Server.get<MRVRegion[]>(`${MRV_SERVICE_URI}/programs/${programId}/boundaries`);
  }

  static updateProgramBoundaries(programId: number, boundaryIds: number[]) {
    return Server.put<MRVRegion[]>(
      `${MRV_SERVICE_URI}/programs/${programId}/boundaries`,
      boundaryIds
    );
  }

  static getUrlToUploadEnrollmentImage(
    programId: number,
    imageType: EnrollmentImageBlobType,
    fileType: string
  ) {
    return Server.post(
      `mrv/programs/${programId}/upload_blob/${imageType}`,
      {blob_extension: fileType?.replace('image/', '')},
      {params: {__skipPreloader: true}}
    );
  }
  static uploadEnrollmentImage(uploadingUrl: string, file: File) {
    return axios.put(uploadingUrl, file, {
      headers: {'Content-Type': 'application/octet-stream'},
      params: {__skipPreloader: true},
    });
  }

  static getNotifications(projectId: number) {
    return Server.get<MRVNotification[]>(`${MRV_SERVICE_URI}/projects/${projectId}/notifications`, {
      params: {__skipPreloader: true},
    });
  }

  static getStageEligibilityMethods(programId: number) {
    return Server.get<EligibilityMethodItem[]>(
      `${MRV_SERVICE_URI}/programs/${programId}/eligibility_methods`,
      {
        params: {__skipPreloader: true},
      }
    );
  }

  static getStageEligibility(projectId: number, stageId: number) {
    return Server.get<MRVProjectEligibility>(
      `${MRV_SERVICE_URI}/projects/${projectId}/stages/${stageId}/eligibility`,
      {
        params: {__skipPreloader: true},
      }
    );
  }

  static deleteNotifications(projectId: number, notification_ids: number[]) {
    return Server.post<MRVNotification[]>(
      `${MRV_SERVICE_URI}/projects/${projectId}/notifications/dismiss`,
      {data: {notification_ids}},
      {params: {__skipPreloader: true}}
    );
  }

  /**
   * Figures out how much money to pay in the contract.
   * Creates the line items for the contract.
   *
   * NOTE: Every time the endpoint is called it deletes existing items and creates new ones (this will be changed in the future).
   */
  static getDNDCResults(projectId: number, phaseId: number): AxiosPromise<DNDCResultsResponse> {
    return Server.post(
      `${MRV_SERVICE_URI}/projects/${projectId}/phases/${phaseId}/commercials`,
      null,
      {
        params: {__skipPreloader: true},
      }
    );
  }

  static runDNDC(projectId: number, field_ids: number[]): AxiosPromise<void> {
    return Server.post(
      `${MRV_SERVICE_URI}/projects/${projectId}/run_dndc`,
      {field_ids},
      {
        params: {__skipPreloader: true},
      }
    );
  }

  static updateProjectConfig(projectId: number, config: Partial<MRVProjectConfig>) {
    return Server.patch<Partial<MRVProject>>(
      `${MRV_SERVICE_URI}/projects/${projectId}`,
      {config},
      {
        params: {__skipPreloader: true},
      }
    );
  }

  static validateCargill(accountId: number) {
    return Server.get<{name: string}>(`${MRV_SERVICE_URI}/validate-cargill?id=${accountId}`, {
      params: {__skipPreloader: true},
    });
  }

  static renewProgramInvite(invite_guid: string) {
    return Server.post<{result: ProgramInvite}>(`${MRV_SERVICE_URI}/invites/renew/${invite_guid}`);
  }
  static getProgramInvites(program_id: number) {
    return Server.get<{result: ProgramInvite[]}>(
      `${MRV_SERVICE_URI}/programs/${program_id}/invites`,
      {
        params: {__skipPreloader: true},
      }
    );
  }

  static getSurvey(projectId: number, stageId: number) {
    return Server.get<MRVSurvey>(
      `${MRV_SERVICE_URI}/projects/${projectId}/stages/${stageId}/survey`,
      {
        params: {__skipPreloader: true},
      }
    );
  }

  static postSurvey(projectId: number, stageId: number, submissionId?: string) {
    const submissionQuery = submissionId ? `?submission_id=${submissionId}` : '';
    return Server.post<MRVSurvey>(
      `${MRV_SERVICE_URI}/projects/${projectId}/stages/${stageId}/survey${submissionQuery}`,
      {
        params: {__skipPreloader: true},
      }
    );
  }

  static getCommercialRulePaymentTypes(programId: number) {
    return Server.get<string[]>(
      `${MRV_SERVICE_URI}/programs/${programId}/phases/commercials/payment_types`,
      {
        params: {__skipPreloader: true},
      }
    );
  }

  static getCommercialRules({programId, phaseId}: {programId: number; phaseId: number}) {
    return Server.get<MRVCommercialRuleData[]>(
      `${MRV_SERVICE_URI}/programs/${programId}/phases/${phaseId}/commercials`,
      {
        params: {__skipPreloader: true},
      }
    );
  }

  static createCommercialRule({
    programId,
    phaseId,
    commercialRule,
  }: {
    programId: number;
    phaseId: number;
    commercialRule: MRVCommercialRuleData;
  }) {
    return Server.post<MRVCommercialRuleData[]>(
      `${MRV_SERVICE_URI}/programs/${programId}/phases/${phaseId}/commercials`,
      [commercialRule],
      {
        params: {__skipPreloader: true},
      }
    );
  }

  static updateCommercialRule({
    programId,
    phaseId,
    commercialRule,
  }: {
    programId: number;
    phaseId: number;
    commercialRule: MRVCommercialRuleData;
  }) {
    return Server.patch<MRVCommercialRuleData>(
      `${MRV_SERVICE_URI}/programs/${programId}/phases/${phaseId}/commercials/${commercialRule.id}`,
      commercialRule,
      {
        params: {__skipPreloader: true},
      }
    );
  }

  static deleteCommercialRule({
    programId,
    phaseId,
    commercialRuleId,
  }: {
    programId: number;
    phaseId: number;
    commercialRuleId: number;
  }) {
    return Server.delete<number>(
      `${MRV_SERVICE_URI}/programs/${programId}/phases/${phaseId}/commercials`,
      {
        data: [commercialRuleId],
        params: {__skipPreloader: true},
      }
    );
  }

  static getFmsOptions = () => {
    return Server.get<{fms_options: FmsOption[]}>(`${MRV_SERVICE_URI}/programs/fms_options`, {
      params: {__skipPreloader: true},
    });
  };

  static getProtocols = () => {
    return Server.get<string[]>(`${MRV_SERVICE_URI}/programs/protocols`, {
      params: {__skipPreloader: true},
    });
  };

  static addFmsOptions = ({programId, option}: {programId: number; option: string}) => {
    return Server.post(`${MRV_SERVICE_URI}/programs/${programId}/fms_options`, [option], {
      params: {__skipPreloader: true},
    });
  };

  static deleteFmsOptions = ({programId, option}: {programId: number; option: string}) => {
    return Server.delete(`${MRV_SERVICE_URI}/programs/${programId}/fms_options`, {
      data: [option],
      params: {__skipPreloader: true},
    });
  };

  static redeemFarmlogsInvite(uuid: string): AxiosPromise<any> {
    return Server.post(`sync/farmlogs/users/invite/${uuid}/redeem`, null, {
      params: {__skipPreloader: true},
    });
  }

  static createProgramConflicts(
    programId: number,
    projectIds: number[] = []
  ): AxiosPromise<ProgramConflictsResponse> {
    return Server.post(`${MRV_SERVICE_URI}/programs/${programId}/conflicts`, projectIds, {
      params: {__skipPreloader: true},
    });
  }

  static getProgramConflicts(
    programId: number,
    filter?: ProgramConflictsFilter
  ): AxiosPromise<ProgramConflictsResponse> {
    return Server.get(`${MRV_SERVICE_URI}/programs/${programId}/conflicts`, {
      params: {__skipPreloader: true, ...filter},
    });
  }

  static getCubeQueryCSV(programId: number, query: Query): AxiosPromise<Blob> {
    return Server.post(
      `${MRV_SERVICE_URI}/programs/${programId}/reporting_dashboards/csv`,
      {
        query,
      },
      {
        params: {__skipPreloader: true},
        responseType: 'text',
      }
    );
  }

  static getProgramConflictsCSV(
    programId: number,
    filter?: ProgramConflictsFilter
  ): AxiosPromise<Blob> {
    return Server.get(`${MRV_SERVICE_URI}/programs/${programId}/conflicts/csv`, {
      params: {__skipPreloader: true, ...filter},
      responseType: 'text',
    });
  }

  static updateConflictResolution(
    projectId: number,
    conflictId: number,
    status: ConflictStatus
  ): AxiosPromise<Conflict> {
    return Server.post(
      `${MRV_SERVICE_URI}/projects/${projectId}/conflicts/${conflictId}/resolution`,
      {status: status},
      {
        params: {__skipPreloader: true},
      }
    );
  }

  static addCustomRegistrationInput({
    registrationInput,
    programId,
  }: {
    registrationInput: MRVProgramCustomRegInput;
    programId: number;
  }): AxiosPromise<MRVProgramCustomRegInput[]> {
    return Server.post(
      `${MRV_SERVICE_URI}/programs/${programId}/custom_inputs`,
      [registrationInput],
      {
        params: {__skipPreloader: true},
      }
    );
  }

  static getHistories(projectId: number, conflictId: number): AxiosPromise<HistoryRow[]> {
    return Server.get(
      `${MRV_SERVICE_URI}/projects/${projectId}/conflicts/${conflictId}/resolution`,
      {
        params: {__skipPreloader: true},
      }
    );
  }

  static updateCustomRegistrationInput({
    registrationInput,
    programId,
  }: {
    registrationInput: MRVProgramCustomRegInput;
    programId: number;
  }): AxiosPromise<MRVProgramCustomRegInput[]> {
    return Server.put(
      `${MRV_SERVICE_URI}/programs/${programId}/custom_inputs`,
      [registrationInput],
      {
        params: {__skipPreloader: true},
      }
    );
  }

  static deleteCustomRegistrationInput({inputId, programId}: {inputId: number; programId: number}) {
    return Server.delete(`${MRV_SERVICE_URI}/programs/${programId}/custom_inputs`, {
      data: [inputId],
      params: {__skipPreloader: true},
    });
  }

  static getMrvProjectCustomInputs({
    projectId,
  }: {
    projectId: number;
  }): AxiosPromise<MRVCustomInput[]> {
    return Server.get(`${MRV_SERVICE_URI}/projects/${projectId}/values`, {
      params: {__skipPreloader: true},
    });
  }

  static createMrvProjectCustomInputsValue({
    projectId,
    input,
  }: {
    projectId: number;
    input: MRVCustomInput;
  }): AxiosPromise<MRVCustomInput[]> {
    return Server.post(`${MRV_SERVICE_URI}/projects/${projectId}/values`, [input], {
      params: {__skipPreloader: true},
    });
  }

  static updateMrvProjectCustomInputsValue({
    projectId,
    input,
  }: {
    projectId: number;
    input: MRVCustomInput;
  }): AxiosPromise<MRVCustomInput[]> {
    return Server.put(`${MRV_SERVICE_URI}/projects/${projectId}/values`, [input], {
      params: {__skipPreloader: true},
    });
  }

  static fetchCredentials(): AxiosPromise<string[]> {
    return Server.get(`${MRV_SERVICE_URI}/docusign/credentials`, {
      params: {__skipPreloader: true},
    });
  }

  static fetchCropTypes({
    program_id,
    phase_id,
    stage_id,
    attribute_id,
  }: {
    program_id: number;
    phase_id: number;
    stage_id: number;
    attribute_id: number;
  }): AxiosPromise<number[]> {
    return Server.get(
      `${MRV_SERVICE_URI}/programs/${program_id}/phases/${phase_id}/stages/${stage_id}/attributes/${attribute_id}/crop_types`,
      {
        params: {__skipPreloader: true},
      }
    );
  }

  static deleteCropType({
    program_id,
    phase_id,
    stage_id,
    attribute_id,
    crop_type_ids,
  }: {
    program_id: number;
    phase_id: number;
    stage_id: number;
    attribute_id: number;
    crop_type_ids: number[];
  }): AxiosPromise<number[]> {
    return Server.delete(
      `${MRV_SERVICE_URI}/programs/${program_id}/phases/${phase_id}/stages/${stage_id}/attributes/${attribute_id}/crop_types`,
      {
        data: crop_type_ids,
        params: {__skipPreloader: true},
      }
    );
  }

  static addNewCropType({
    program_id,
    phase_id,
    stage_id,
    attribute_id,
    crop_type_ids,
  }: {
    program_id: number;
    phase_id: number;
    stage_id: number;
    attribute_id: number;
    crop_type_ids: number[];
  }): AxiosPromise<number[]> {
    return Server.post(
      `${MRV_SERVICE_URI}/programs/${program_id}/phases/${phase_id}/stages/${stage_id}/attributes/${attribute_id}/crop_types`,
      crop_type_ids,
      {
        params: {__skipPreloader: true},
      }
    );
  }

  static getProjectConflictFiles(
    programId: number,
    projectId: number,
    conflictId: number
  ): AxiosPromise<ProjectConflictFile[]> {
    return Server.get(
      `${MRV_SERVICE_URI}/programs/${programId}/projects/${projectId}/conflicts/${conflictId}/files`,
      {
        params: {__skipPreloader: true},
      }
    );
  }

  static getUrlsToUploadProjectConflictFiles(
    projectId: number,
    conflictId: number,
    files: {file_name: string}[]
  ): AxiosPromise<ProjectConflictFile[]> {
    return Server.post(
      `${MRV_SERVICE_URI}/projects/${projectId}/conflicts/${conflictId}/files`,
      files,
      {params: {__skipPreloader: true}}
    );
  }

  static markFilesAsUploaded(
    projectId: number,
    conflictId: number,
    fileIdsThatWereUploaded: number[]
  ): AxiosPromise<void> {
    return Server.put(
      `${MRV_SERVICE_URI}/projects/${projectId}/conflicts/${conflictId}/files`,
      fileIdsThatWereUploaded.map(f => ({id: f, is_uploaded: true})),
      {params: {__skipPreloader: true}}
    );
  }

  static deleteProjectConflictFile(
    projectId: number,
    conflictId: number,
    fileIds: number[]
  ): AxiosPromise<void> {
    return Server.delete(`${MRV_SERVICE_URI}/projects/${projectId}/conflicts/${conflictId}/files`, {
      data: fileIds,
      params: {__skipPreloader: true},
    });
  }

  static getOptisAttributeMapping(programId: number) {
    return Server.get<OptisAttributeMappingPayload>(
      `${MRV_SERVICE_URI}/programs/${programId}/optis/labels/mapping`,
      {
        params: {__skipPreloader: true},
      }
    );
  }

  static updateOptisAttributeMapping(
    programId: number,
    attributeMapping: OptisAttributeMappingPayload
  ) {
    return Server.post<OptisAttributeMappingPayload>(
      `${MRV_SERVICE_URI}/programs/${programId}/optis/labels/mapping`,
      attributeMapping,
      {
        params: {__skipPreloader: true},
      }
    );
  }

  static bulkUpdateFieldsFarm({
    projectId,
    field_data,
    target_farm_id,
  }: {
    projectId: number;
    field_data: BulkUpdateFieldData[];
    target_farm_id: number;
  }) {
    return Server.put(
      `${MRV_SERVICE_URI}/projects/${projectId}/fields/update_farm_id`,
      {
        field_data,
        target_farm_id,
      },
      {params: {__skipPreloader: true}}
    );
  }

  static updateFarm({
    projectId,
    mrvFarmId,
    farm,
  }: {
    projectId: number;
    mrvFarmId: number;
    farm: Partial<MRVFarm>;
  }) {
    return Server.patch(`${MRV_SERVICE_URI}/projects/${projectId}/farms/${mrvFarmId}`, farm, {
      params: {__skipPreloader: true},
    });
  }

  static deleteFarm({projectId, mrvFarmId}: {projectId: number; mrvFarmId: number}) {
    return Server.delete(`${MRV_SERVICE_URI}/projects/${projectId}/farms`, {
      data: [mrvFarmId],
      params: {__skipPreloader: true},
    });
  }

  static getConflictsPDF(projectId: number, conflictId: number) {
    return Server.get<Blob>(
      `${MRV_SERVICE_URI}/projects/${projectId}/conflicts/${conflictId}/files/proof_pdf`,
      {
        responseType: 'blob',
        params: {__skipPreloader: true},
      }
    );
  }

  static fetchMobs({projectId}: {projectId: number}): AxiosPromise<MRVMob[]> {
    return Server.get(`${MRV_SERVICE_URI}/projects/${projectId}/mobs`, {
      params: {__skipPreloader: true},
    });
  }

  static getMob({projectId, mobId}: {projectId: number; mobId: number}): AxiosPromise<MRVMob> {
    return Server.get(`${MRV_SERVICE_URI}/projects/${projectId}/mobs/${mobId}`, {
      params: {__skipPreloader: true},
    });
  }

  static updateMob({
    projectId,
    mobId,
    mobName,
  }: {
    projectId: number;
    mobId: number;
    mobName: string;
  }): AxiosPromise<MRVMob> {
    return Server.patch(
      `${MRV_SERVICE_URI}/projects/${projectId}/mobs/${mobId}`,
      {mob_name: mobName},
      {
        params: {__skipPreloader: true},
      }
    );
  }

  static createMobs({
    projectId,
    mobNames,
  }: {
    projectId: number;
    mobNames: string[];
  }): AxiosPromise<MRVMob[]> {
    return Server.post(
      `${MRV_SERVICE_URI}/projects/${projectId}/mobs`,
      {mob_names: mobNames},
      {
        params: {__skipPreloader: true},
      }
    );
  }

  static deleteMobs({
    projectId,
    mobIds,
  }: {
    projectId: number;
    mobIds: number[];
  }): AxiosPromise<void> {
    return Server.delete(`${MRV_SERVICE_URI}/projects/${projectId}/mobs`, {
      data: mobIds,
      params: {__skipPreloader: true},
    });
  }
}

export default MRVApi;
