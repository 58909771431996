import React, {useState, useMemo, useEffect, useRef} from 'react';
import {Autocomplete, FontIcon} from 'react-md';
import {escapeRegExp} from '_utils/pure-utils';
import {Waypoint} from 'react-waypoint';
import cn from 'classnames';
import './index.scss';
import type {LayoverAnchor, LayoverPositions} from 'react-md/lib/Helpers';

const keyZ = 90;
const keyA = 64;

type Props = {
  // important data
  id: string;
  value: string | number;
  menuItems: any[];

  // autocomplete props
  label?: string;
  title?: string;
  placeholder?: string;
  autoComplete?: string;
  simplifiedMenu?: boolean;
  position?: LayoverPositions;
  anchor?: LayoverAnchor;
  name?: string;

  // autocomplete methods
  onBlur?: () => void;
  onFocus?: () => void;
  onChange?: (value: number | string) => void;
  onType?: (value: number | string) => void;
  onEnter?: (value: any) => void;
  onFilter?: (value: number | string) => any[];
  onMenuOpen?: () => void;
  onAutocomplete?: (suggestion: string | number, suggestionIndex: number, matches: any[]) => void;
  filter?: null | ((value: number | string) => void);

  // style
  className?: string;
  containerClassName?: string;
  inputClassName?: string;
  fullWidth?: boolean;
  autoInputWidth?: boolean;

  // additional props
  error?: boolean;
  errorText?: string;
  disabled?: boolean;
  searchIcon?: boolean;
  shouldNotResetOnFocus?: boolean;

  // modify default values
  defaultPagination?: number;
  dataValue?: string;
  dataLabel?: string;
  searchKey?: string;
  formatLabel?: (value: any) => string | React.ReactElement;
  fixedToTop?: {[dataValue: string]: string | number | React.ReactElement}[];
};

const FluroAutocomplete = ({
  // important data
  id,
  menuItems,
  value,

  // autocomplete props
  label = '',
  title = '',
  name = '',
  placeholder = '',
  autoComplete = 'off',
  anchor = undefined,
  position = Autocomplete.Positions.BELOW,
  simplifiedMenu = true,

  // autocomplete methods
  onBlur,
  onFocus,
  onAutocomplete,
  onChange,
  onFilter,
  onEnter,
  onMenuOpen,
  filter = null,
  onType,

  // style
  className = '',
  containerClassName = '',
  inputClassName = '',
  fullWidth = true,
  autoInputWidth,

  // additional props
  error,
  errorText,
  disabled,
  searchIcon,
  shouldNotResetOnFocus,

  // modify default values
  defaultPagination = 25,
  searchKey,
  dataLabel = 'label',
  dataValue = 'value',
  formatLabel,
  fixedToTop,
}: Props) => {
  const [isMenuOpen, toggleMenu] = useState(false);
  const [pagination, setPagination] = useState(defaultPagination);
  const [thisValue, setThisValue] = useState(value);
  const [initialValue, setInitialValue] = useState(value);
  const refInput = useRef(null);
  const [filteredMenuItems, setFilteredMenuItems] = useState(menuItems);

  const inputStyle = useMemo(() => {
    return autoInputWidth ? {width: `${parseInt(`${value}`) * 7.8}px`} : {};
  }, [autoInputWidth, value]);

  useEffect(() => {
    setFilteredMenuItems(menuItems);
  }, [menuItems]);

  useEffect(() => {
    setInitialValue(value);
    setThisValue(value);
  }, [value]);

  const onChangeValue = (value: string | number) => {
    if (typeof value === 'string' && value.length && value[0] === ' ') {
      value = value.replace(' ', '');
    }

    onChange?.(value);

    setThisValue(value);
    thisOnFilter(value);
  };

  const thisOnFilter = (value: string | number) => {
    if (onFilter) {
      return setFilteredMenuItems(onFilter(value));
    }
    const r = new RegExp(escapeRegExp(`${value}`), 'i');
    const filterDataLabel = searchKey || dataLabel;
    const filteredResult = menuItems.filter(item => {
      if (filterDataLabel === 'id') {
        return value === '' || item[filterDataLabel] === parseInt(`${value}`);
      }

      return value === '' || r.test(item[filterDataLabel]);
    });
    setFilteredMenuItems(filteredResult);
  };

  const thisOnFocus = () => {
    !shouldNotResetOnFocus &&
      setTimeout(() => {
        resetValue();
      }, 0);
    setFilteredMenuItems(menuItems);
    onFocus?.();
  };

  const thisOnBlur = () => {
    setThisValue(initialValue);
    onBlur?.();
  };

  // const toggleOpen = () => {
  //   setTimeout(() => {
  //     value === '' && resetValue();
  //     refInput?.current && refInput.current._toggleMenu();
  //   }, 0);
  // };

  const handleMenuOpening = () => {
    toggleMenu(true);
    setTimeout(() => onMenuOpen?.(), 300);
  };

  const onMenuClose = () => {
    toggleMenu(false);
    setPagination(defaultPagination);
  };

  const resetValue = () => {
    setThisValue(' ');
    setFilteredMenuItems(menuItems);
  };

  const loadMore = () => {
    if (filteredMenuItems.length > pagination) {
      setPagination(pagination + defaultPagination);
    }
  };

  const menuList = useMemo(() => {
    let preparedList =
      filteredMenuItems.length && !disabled
        ? [
            ...filteredMenuItems
              .map((el: any, index) =>
                index > pagination ? null : formatLabel ? {...el, [dataLabel]: formatLabel(el)} : el
              )
              .slice(0, pagination),
            <Waypoint key="lazy-load" onEnter={loadMore} />,
          ]
        : [];

    if (fixedToTop && !disabled) {
      const fixedToTopValues = fixedToTop.map(item => item[dataValue]);
      preparedList = preparedList.filter(item => !fixedToTopValues.includes(item[dataValue]));
      preparedList = [...fixedToTop, ...preparedList];
    }

    return preparedList;
  }, [filteredMenuItems, value, pagination, disabled, dataValue, dataLabel]);

  const onKeyUp = (e: any) => {
    // press enter on the input
    if (e.keyCode === 13) {
      onEnter?.(e?.currentTarget?.value || '');
    } else if (e.which <= keyZ && e.which >= keyA) {
      onType?.(e?.currentTarget?.value);
    }
  };

  return (
    <div
      className={cn(`fluro-autocomplete ${className}`, {
        error,
        'with-search-icon': searchIcon,
      })}
    >
      {searchIcon && <FontIcon className={'search-icon'}>search</FontIcon>}
      <Autocomplete
        onKeyUp={onKeyUp}
        ref={refInput}
        id={id}
        name={name}
        value={thisValue}
        inputStyle={inputStyle}
        label={label}
        data={menuList}
        title={title}
        placeholder={placeholder}
        dataLabel={dataLabel}
        dataValue={dataValue}
        onMenuOpen={handleMenuOpening}
        onMenuClose={onMenuClose}
        simplifiedMenu={simplifiedMenu}
        onAutocomplete={onAutocomplete}
        inputClassName={inputClassName}
        className={containerClassName}
        anchor={anchor}
        position={position}
        onBlur={thisOnBlur}
        onFocus={thisOnFocus}
        onChange={onChangeValue}
        fullWidth={fullWidth}
        // @ts-expect-error error leftover from convertion to strict mode, please fix
        filter={filter}
        disabled={disabled}
        autoComplete={autoComplete}
        rightIcon={
          <FontIcon className={'fluro-autocomplete__arrow'}>
            {isMenuOpen ? 'arrow_drop_up' : 'arrow_drop_down'}
          </FontIcon>
        }
      />

      {error && errorText && (
        <div className={'md-text-field-message-container md-full-width md-text--error'}>
          {errorText}
        </div>
      )}
    </div>
  );
};

export default FluroAutocomplete;
