import {useAppSelector} from '_hooks';
import {Flex, Text} from 'components';
import {selectMeasurement} from 'containers/login/login-selectors';
import {useGetRegionById} from 'containers/si/hooks/useGetRegionById';
import {ReportTile, ReportTileHeader} from 'containers/si/planning-report/components/ReportTile';
import type {PlanningReport} from 'containers/si/types';
import {classifyAreaWithUnits} from 'containers/si/utils/format';
import {compact} from 'lodash';
import {selectCropTypesCDLIdMap} from 'modules/global/selectors';
import React from 'react';
import {CropItem} from 'containers/si/planning-report/programPlan/components/CropItem';

export const RegionItem = ({region}: {region: PlanningReport['sourcing_regions'][number]}) => {
  const measurement = useAppSelector(selectMeasurement);
  const cropsById = useAppSelector(selectCropTypesCDLIdMap);

  const {getSubsectionById, getSupplyShedById} = useGetRegionById();

  const [name = '', area] = React.useMemo((): [name?: string, area?: number] => {
    if (region.unit_type === 'supply_shed') {
      const supplyShed = getSupplyShedById(region.unit_id);

      return [supplyShed?.name, supplyShed?.total_field_area_ha];
    } else {
      const subsection = getSubsectionById(region.unit_id);

      return [subsection?.name, subsection?.field_area_ha];
    }
  }, [getSubsectionById, getSupplyShedById, region.unit_id, region.unit_type]);

  return (
    <ReportTile key={`${region.unit_type}-${region.unit_id}`}>
      <ReportTileHeader titleText={name} />
      <Flex gap={16}>
        {compact(region.commodities.map(crop => cropsById[crop.id])).map(cropType => (
          <CropItem key={cropType.id} cropType={cropType} />
        ))}
      </Flex>
      <Text variant="h2" bold>
        {classifyAreaWithUnits({area, measurement})}
      </Text>
    </ReportTile>
  );
};
