import {FormattedMessage} from 'i18n-utils';
import './messages.scss';
import * as React from 'react';
import {useIntercom} from 'react-use-intercom';

export const EligibleMessage = () => {
  const {startTour} = useIntercom();

  return (
    <FormattedMessage
      id="EligibleMessage"
      defaultMessage="Counties in Illinois, Indiana, Ohio, Missouri, Arkansas, and Tennessee are eligible for the program. <a>See the list of eligible counties here</a>."
      values={{
        a: (msg: string) => (
          // eslint-disable-next-line jsx-a11y/anchor-is-valid
          <a
            onClick={() => {
              startTour(249191);
            }}
            style={{cursor: 'pointer'}}
          >
            {msg}
          </a>
        ),
      }}
    />
  );
};
