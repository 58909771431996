import {useState} from 'react';

/**
 * This hook helps to manage stuck headings within a list
 * by keeping the biggest index at the top of the list.
 */
export function useStickyHeadings(): {
  getHeadingIsStuck: (index: number) => boolean;
  updateStuckHeadings: (stuck: boolean, index: number) => void;
} {
  const [stuckHeadingIndexes, setStuckHeadingIndexes] = useState<number[]>([]);

  function updateStuckHeadings(stuck: boolean, index: number) {
    setStuckHeadingIndexes(indices => {
      const wasStuck = indices.includes(index);
      if (wasStuck && !stuck) {
        return indices.filter(v => v !== index);
      }

      if (!wasStuck && stuck) {
        const newIndices = [...indices];
        if (index > newIndices[0]) {
          newIndices.unshift(index);
        } else {
          newIndices.push(index);
        }
        return newIndices;
      }

      return indices;
    });
  }

  function getHeadingIsStuck(index: number) {
    return stuckHeadingIndexes[0] === index;
  }

  return {getHeadingIsStuck, updateStuckHeadings};
}
