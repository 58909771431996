import {toolbar} from 'leaflet';

export const enableDrawShape = (shapeType = 'polygon') => {
  try {
    if (toolbar._toolbars.draw._modes[shapeType])
      setTimeout(() => toolbar?._toolbars?.draw?._modes?.[shapeType]?.handler?.enable(), 0); // it is still a secret for me, why we need to run this actions async
  } catch (e) {}
};

export const disableDrawShape = (shapeType = 'polygon') => {
  try {
    if (toolbar._toolbars.draw._modes[shapeType]) {
      toolbar?._toolbars?.draw?._modes?.[shapeType]?.handler?.disable(); // add additional disable func, because it doesn't work anymore without it
      setTimeout(() => toolbar?._toolbars?.draw?._modes?.[shapeType]?.handler?.disable(), 0);
    }
  } catch (e) {}
};

export const deleteLastPoint = () => {
  try {
    toolbar._toolbars.draw._modes.polygon.handler.deleteLastVertex();
  } catch (e) {}
};

export const disableEdit = () => {
  try {
    if (toolbar._toolbars.edit.options.edit) {
      toolbar._toolbars.edit._modes.edit.handler.revertLayers();
      toolbar._toolbars.edit._modes.edit.handler.disable();
    }
  } catch (e) {}
};

export const enableEdit = () => {
  try {
    toolbar._toolbars.edit._modes.edit.handler.enable();
  } catch (e) {}
};

export const saveEdit = () => {
  try {
    toolbar._toolbars.edit._modes.edit.handler.save();
    toolbar._toolbars.edit._modes.edit.handler.disable();
  } catch (e) {}
};
