// @ts-nocheck
import {createSelector} from '@reduxjs/toolkit';
import type {
  MRVAttribute,
  MRVAttributeType,
  MRVPhaseNormalized,
  MRVProgram,
  MRVProgramNormalized,
  MRVProjectNormalized,
  MRVStageNormalized,
  MRVField,
  MRVRegion,
  CoordinateSystem,
  MRVProgramUnits,
} from 'containers/mrv/types';
import {
  MRVPhaseType,
  PhaseReadOnlyReason,
  ProjectValueSource,
  MRVStageType,
  OFSStatus,
} from 'containers/mrv/types';
import groupBy from 'lodash/groupBy';
import keyBy from 'lodash/keyBy';
import {selectFarmsList} from 'modules/farms/selectors';
import {denormalize} from 'normalizr';
import type {AppStore} from 'reducers';
import {ProgramSchema} from './normalize-schemas';
import type {RouterRootState} from 'connected-react-router';
import {createMatchSelector} from 'connected-react-router';
import {
  MONITORING_PROJECT_FORM,
  MRV_ADMIN_PROGRAM,
  MRV_ENROLLMENT,
  MRV_PROJECT,
} from 'containers/mrv/routes';
import {selectCropTypesList} from 'modules/global/selectors';
import {sortBy, orderBy} from 'lodash';
import moment from 'moment';
import {selectAllFieldsByFieldId} from 'containers/map/reducer/selectors';
import {ExternalService} from 'containers/map/types';
import {selectIntegrationPlatform} from 'containers/profile/integration/integration-selectors';
import type {IntegrationPlatform} from 'containers/profile/integration/types';
import {RequestStatus} from 'types';
import {ExternalServiceLabels} from 'containers/profile/integration/integration-utils';
import {applyPathParams, getToday, createCachingSelector} from '_utils/pure-utils';
import {sortByKey} from '_utils/sorters';
import {getShapeCoordinates} from '_utils/geometry';
import {t} from 'i18n-utils';
import {selectIsImpersonatorNotSuperAdmin} from 'containers/login/login-selectors';

export const selectEntities = (s: AppStore) => s.monitoring.entities;
export const selectCompletion = (s: AppStore) => s.monitoring.completion;

export const selectUserProjectIds = (s: AppStore) => s.monitoring.access.producer;
export const selectUserProgramIds = (s: AppStore) => s.monitoring.access.admin;
export const selectUserIsProgramAdmin = createSelector(
  [selectUserProgramIds],
  programIds => !!programIds.length
);

export const selectMRVAttributes = createSelector([selectEntities], e => e.attributes);
export const selectMRVStages = createSelector([selectEntities], e => e.stages);
export const selectFields = createSelector([selectEntities], e => e.fields);
export const selectPhases = createSelector([selectEntities], e => e.phases || {});
export const selectPrograms = createSelector([selectEntities], e => e.programs);
export const selectProjectValues = createSelector([selectEntities], e => e.projectValues);
export const selectProjects = createSelector([selectEntities], e => e.projects);

export const selectFarms = createSelector([selectFarmsList], list => keyBy(list, 'id'));

export const selectProjectsByProgramId = createSelector([selectProjects], d => {
  const projectByProgramId = groupBy(d, 'program_id');
  return projectByProgramId;
});

const retrieveIdParam = (s: AppStore, id: number) => id;

export const getById = <T = any>(entities: Record<string, T>, id: number): T => entities?.[id];

export const selectIsMonitoring = () => window.location.pathname.includes('monitoring');
export const selectIsEnrollment = () => window.location.pathname.includes('enrollment');

export const selectProjectId = (s: AppStore) => {
  const result = createMatchSelector<RouterRootState, {projectId?: string}>({path: MRV_PROJECT})(s);
  return parseInt(result?.params?.projectId);
};
export const selectProgramId = (s: AppStore) => {
  const result = createMatchSelector<RouterRootState, {programId?: string}>({
    path: MRV_ADMIN_PROGRAM,
  })(s);
  return parseInt(result?.params?.programId);
};

export const selectNormalizedMRVProgramById = createSelector(
  [selectPrograms, (s: AppStore, programId: number) => programId],
  (programs, programId): MRVProgramNormalized | null => programs[programId] || null
);

export const selectMRVProgramById = createSelector(
  [selectNormalizedMRVProgramById, selectEntities],
  (program, entities): MRVProgram | null => {
    const denormalized = denormalize(program, ProgramSchema, entities);
    return denormalized;
  }
);

export const selectProjectById = createCachingSelector(
  [selectProjects, retrieveIdParam],
  (data, id) => getById<MRVProjectNormalized>(data, id)
);

export const selectCurrentProject = createSelector([selectProjects, selectProjectId], (data, id) =>
  getById<MRVProjectNormalized>(data, id)
);

export const selectCurrentProgram = createSelector(
  [selectCurrentProject, selectProgramId, selectPrograms],
  (project, programId, programs): MRVProgramNormalized | undefined => {
    const program = programs[project?.program_id || programId];
    return program;
  }
);

export const selectCurrentProgramUnits = createSelector(
  [selectCurrentProject, selectProgramId, selectPrograms],
  (project, programId, programs): MRVProgramUnits | undefined => {
    const program = programs[project?.program_id || programId];
    return program?.units;
  }
);

export const selectCurrency = createSelector([selectCurrentProgram], program => {
  return program?.currency_char || '$';
});

export const selectCurrentProgramId = createSelector(
  [selectCurrentProgram],
  program => program?.id
);

export const selectProjectProgram = createSelector(
  [selectProjectById, selectPrograms],
  (project, programs) => {
    const programId = project?.program_id;
    return programs[programId];
  }
);

export const selectAllProgramBoundaries = (s: AppStore) => s.monitoring.programBoundaries;
export const selectProgramBoundaries = createSelector(
  [selectCurrentProgram, selectAllProgramBoundaries],
  (program, boundaries): MRVRegion[] | undefined => boundaries[program?.id]
);

export const mapPhasesByType = (
  phases: AppStore['monitoring']['entities']['phases'],
  program?: MRVProgramNormalized
): {[type: string]: MRVPhaseNormalized} => {
  const phasesByType: {[type: string]: MRVPhaseNormalized} = {};
  program?.phases?.forEach(phaseId => {
    const phase = phases?.[phaseId];
    if (phase) {
      phasesByType[phase.type_] = phase;
    }
  });
  return phasesByType;
};

export const selectPhaseById = createSelector(
  [selectPhases, (s: AppStore, id: number) => id],
  (phases, id) => phases[id]
);

export const selectPhasesByType = createSelector(
  [selectPhases, selectCurrentProgram],
  mapPhasesByType
);

export const selectPhasesByTypeAndProgramId = createSelector(
  [(s: AppStore, programId: number) => programId, selectPrograms, selectPhases],
  (programId, programs, phases): {[type: string]: MRVPhaseNormalized} => {
    const program = programs[programId];

    return mapPhasesByType(phases, program);
  }
);

export const selectPhase = createCachingSelector(
  [selectPhasesByType, (s: AppStore, phaseType: MRVPhaseType) => phaseType],
  (phases, phaseType): MRVPhaseNormalized | undefined => {
    return phases[phaseType];
  }
);

export const selectIsOutsideEnrollmentDates = createCachingSelector(
  [selectPhasesByType],
  (phases): boolean => {
    const enrollment = phases[MRVPhaseType.Enrollment];
    return Boolean(
      enrollment?.start_date &&
        enrollment?.end_date &&
        (moment().isBefore(moment(enrollment?.start_date)) ||
          moment().isAfter(moment(enrollment?.end_date)))
    );
  }
);

export const selectPhaseStages = createCachingSelector(
  [selectPhase, selectMRVStages],
  (phase, stages): MRVStageNormalized[] => {
    return orderBy(phase?.stages.map(stageId => stages[stageId]).filter(Boolean), 'order') || [];
  }
);

export const selectPhaseStagesByTypeMap = createCachingSelector(
  [selectPhaseStages],
  (stages): Record<MRVStageType, MRVStageNormalized> => {
    const stagesByType: Record<string, MRVStageNormalized> = {};
    stages?.forEach(stage => {
      stagesByType[stage.type_] = stage;
    });
    return stagesByType;
  }
);

export const selectPhaseStageByType = createCachingSelector(
  [selectPhaseStagesByTypeMap, (s: AppStore, phase: MRVPhaseType, stage: MRVStageType) => stage],
  (stagesByType, stageType): MRVStageNormalized | undefined => {
    return stagesByType[stageType];
  }
);

export const selectEnabledPhaseStages = createCachingSelector(
  [selectPhase, selectPhaseStages, selectMRVAttributes],
  (phase, stages, attributes): MRVStageNormalized[] => {
    return stages?.length
      ? sortBy(
          stages.filter(
            stage =>
              (stage.enabled &&
                // If a stage doesn't have any attributes we will display it. Otherwise at least one attribute has to be enabled.
                // Preventing an edge case where a producer could open a project with incorrect config, and see a table with no inputs / data.
                (stage.attributes.length === 0 ||
                  stage.attributes.some(a => attributes[a]?.enabled))) ||
              // hack to get the Producer Agreement stage in the list of stages
              (stage.type_ === MRVStageType.Contract && phase.show_contract === true) ||
              (stage.type_ === MRVStageType.ViewOutcomes && phase.params?.outcome_estimation)
          ),
          'order'
        )
      : [];
  }
);

export const selectCurrentProjectFarms = createSelector(
  [selectCurrentProject],
  p => p?.farms || []
);

export const selectProjectFieldsIds = createSelector([selectProjectById], p => p?.fields || []);
export const selectCurrentProjectFieldsIds = createSelector(
  [selectCurrentProject],
  p => p?.fields || []
);

export const selectProjectFieldsList = createSelector(
  [selectProjectFieldsIds, selectFields],
  (fieldsIds, fields) => {
    const fieldsList = fieldsIds?.map(id => fields[id]).filter(f => f && !f.deleted_at);
    return fieldsList;
  }
);
export const selectCurrentProjectFieldsList = createSelector(
  [selectCurrentProjectFieldsIds, selectFields],
  (fieldsIds, fields) => {
    const fieldsList = fieldsIds?.map(id => fields[id]).filter(f => f && !f.deleted_at);
    return fieldsList;
  }
);
export const selectCurrentProjectFieldsAwaitingOptis = createSelector(
  [selectCurrentProjectFieldsIds, selectFields],
  (fieldsIds, fields) => {
    const fieldsList = fieldsIds?.filter(
      id => fields[id] && !fields[id].deleted_at && fields[id].ofs_status === OFSStatus.InProgress
    );
    return fieldsList;
  }
);

export const selectMrvFieldsMapByMrvId = createSelector(
  [selectCurrentProjectFieldsList],
  fields => {
    return fields?.reduce<Record<number, MRVField>>((fieldsById, field) => {
      fieldsById[field.id] = field;
      return fieldsById;
    }, {});
  }
);

export const selectCurrentProjectFieldMapKmlId2MrvId = createSelector(
  [selectCurrentProjectFieldsList, selectAllFieldsByFieldId],
  (mrvFields, allFields) => {
    const kmlId2MrvId: {[kmlId: number]: number} = {};
    mrvFields.forEach(mrvField => {
      const f = allFields[mrvField.fs_field_id];
      if (f) {
        kmlId2MrvId[f.ID] = mrvField.id;
      }
    });
    return kmlId2MrvId;
  }
);

export const selectCurrentProjectFieldMapMrvId2KmlId = createSelector(
  [selectCurrentProjectFieldsList, selectAllFieldsByFieldId],
  (mrvFields, allFields) => {
    const mrvId2KmlId: {[mrvId: number]: number} = {};
    mrvFields.forEach(mrvField => {
      const f = allFields[mrvField.fs_field_id];
      if (f) {
        mrvId2KmlId[mrvField.id] = f.ID;
      }
    });
    return mrvId2KmlId;
  }
);

export const selectCurrentProjectFieldMrvIdByKmlId = createSelector(
  [
    selectCurrentProjectFieldMapKmlId2MrvId,
    (s: AppStore, stageId: number, kmlId: number) => kmlId, // stageId is not used anywhere, it's here is just because of how this mapping is gonna be used
  ],
  (kmlId2MrvId, kmlId) => kmlId2MrvId[kmlId]
);

export const selectCurrentProjectFieldByKmlId = createSelector(
  [
    selectCurrentProjectFieldMrvIdByKmlId,
    selectMrvFieldsMapByMrvId,
    (s: AppStore, stageId: number, kmlId: number) => kmlId, // stageId is not used anywhere, it's here is just because of how this mapping is gonna be used
  ],
  (mrvFieldId, fields) => fields[mrvFieldId]
);

export const selectProjectRemovedFieldIds = createSelector(
  [selectProjectFieldsIds, selectFields],
  (fieldsIds, fields) => {
    const fieldsList = fieldsIds.filter(id => fields[id]?.deleted_at).map(id => fields[id].id);
    return fieldsList;
  }
);
export const selectCurrentProjectRemovedFieldIds = createSelector(
  [selectCurrentProjectFieldsIds, selectFields],
  (fieldsIds, fields) => {
    const removedFields: Record<number, true> = {};
    fieldsIds.forEach(id => {
      if (fields[id]?.deleted_at) {
        removedFields[id] = true;
      }
    });
    return removedFields;
  }
);

export const selectProjectFieldsIdsByFarmId = createSelector(
  [selectProjectFieldsList, (s: AppStore, projectId: number, farmId: number) => farmId],
  (fields, farmId) => fields.filter(f => f.farm_id === farmId).map(f => f.id)
);

export const selectProjectFieldsGeometries = createSelector(
  selectProjectFieldsList,
  fields =>
    fields
      ?.map(f => {
        if (!f.core_attributes?.geometry) return null;

        const geom = {...f.core_attributes?.geometry};

        const [lng = 0, lat = 0] = getShapeCoordinates(geom);

        geom.properties = {
          fieldId: f.id,
          name: f.core_attributes?.field_name,
          position: {lat, lng},
        };
        return geom;
      })
      .filter(x => x) || []
);

export const selectProjectFieldsGroupByFarmId = createSelector(
  [selectProjectFieldsList],
  fields => {
    const farmFields = groupBy(fields, 'farm_id');
    return farmFields;
  }
);

export const selectProjectFarmsIds = createSelector(
  [selectProjectFieldsGroupByFarmId],
  farmFields => Object.keys(farmFields).map(k => Number(k))
);

export const selectProjectFarmsList = createSelector(
  [selectProjectFarmsIds, selectFarms],
  (ids, farms) => {
    const farmsList = ids?.map((id, index) => {
      const farm = farms[id];
      return {id, name: farm?.name || `Farm ${index}`};
    });
    return farmsList;
  }
);

export const selectStageNormalizedById = createSelector(
  [selectMRVStages, retrieveIdParam],
  (stages, stageId) => stages[stageId]
);

export const selectStageById = createCachingSelector(
  [selectMRVStages, (s: AppStore, stageId?: number) => stageId],
  (stages, stageId): MRVStageNormalized | undefined => stages?.[stageId]
);

export const selectStageAttributeTypeIds = createCachingSelector(
  [selectMRVStages, selectMRVAttributes, (s: AppStore, stageId?: number) => stageId],
  (stages, attributes, stageId) => {
    const stage = stages?.[stageId];
    const attributeTypeIds: Partial<Record<MRVAttributeType, number>> = {};
    if (!stage) return attributeTypeIds;
    stage.attributes.forEach(attributeId => {
      const attribute = attributes[attributeId];
      if (attribute) {
        attributeTypeIds[attribute.type] = attributeId;
      }
    });
    return attributeTypeIds;
  }
);

export const selectStageAttributes = createCachingSelector(
  [selectStageById, selectMRVAttributes],
  (stage, attributes): MRVAttribute[] =>
    sortByKey(stage?.attributes.map(a => attributes[a]) || [], 'order') || []
);

export const selectStageEnabledAttributes = createCachingSelector(
  [selectStageAttributes],
  (attributes): MRVAttribute[] => attributes?.filter((a: MRVAttribute) => a.enabled)
);

export const selectAttributeById = createCachingSelector(
  [selectMRVAttributes, retrieveIdParam],
  (attributes, id) => attributes[id]
);

export const selectAttributeByType = createSelector(
  [
    selectMRVAttributes,
    (s: AppStore, phase: MRVPhaseType, stage: MRVStageType) =>
      selectPhaseStageByType(s, phase, stage),
    (s: AppStore, phase: MRVPhaseType, stage: MRVStageType, attribute: MRVAttributeType) =>
      attribute,
  ],
  (attributes, stage, type) => {
    const attributeId = stage?.attributes.find(a => attributes[a].type === type);

    if (attributeId) {
      return attributes[attributeId];
    }
  }
);

// Select by mrv field id (not fs field id, not kml id).
export const selectFieldById = createCachingSelector(
  [selectFields, retrieveIdParam],
  (entities, id) => getById<MRVField>(entities, id)
);

export const selectMonitoringFieldsGeometry = createSelector([selectFields], fields =>
  Object.values(fields)
    .map(field => field.core_attributes?.geometry)
    .filter(x => x)
);

export const selectMRVProgramsList = createSelector([selectPrograms], programs => {
  return Object.entries(programs)
    .sort((a, b) => Number(b[0]) - Number(a[0]))
    .map(([_id, program]) => program);
});

export const selectProgramAdmins = createSelector([selectMRVProgramsList], programs => {
  return sortByKey(
    programs.flatMap(program => program.permissions),
    'id'
  );
});

export const selectProgramAdminsByProgramId = createSelector(
  [selectNormalizedMRVProgramById],
  program => {
    return program ? sortByKey([...program.permissions], 'id') : []; // use spread to create a new array and not mutate the state
  }
);

export const selectProjectProducers = createSelector([selectProjects], projects => {
  return sortByKey(
    Object.values(projects).flatMap(project => project.permissions),
    'id'
  );
});

export const selectProjectProducersByProgramId = createSelector(
  [selectProjectsByProgramId, retrieveIdParam],
  (programProjects, programId) => {
    return sortByKey(
      (programProjects[programId] || []).flatMap(project => project.permissions).filter(Boolean),
      'id'
    );
  }
);

export const selectCurrentProjectValues = createSelector(
  [selectCurrentProject, selectProjectValues],
  (project, projectValues) => project?.values?.map(id => projectValues[id]) || []
);

export const selectStageValues = createSelector(
  [selectCurrentProjectValues, selectMRVStages, (s: AppStore, stageId: number) => stageId],
  (values, stages, stageId) => {
    const stage = stages[stageId];
    if (!values?.length || !stage?.attributes?.length) return [];
    const attributesMap = stage.attributes.reduce<{[index: number]: boolean}>(
      (acc, id) => ({...acc, [id]: true}),
      {}
    );
    const stageValues = values.filter(value => !!attributesMap[value.attribute_id]);
    return stageValues;
  }
);

export const selectStageFieldValuesMap = createSelector([selectStageValues], values =>
  groupBy(values, 'field_id')
);

export const selectValuesPerAttribute = createSelector([selectProjectValues], values => {
  return groupBy(Object.values(values), 'attribute_id');
});

export const selectFilledValuesPerAttribute = createSelector([selectProjectValues], values => {
  const confirmed = groupBy(
    Object.values(values).filter(v => v.confirmed),
    'attribute_id'
  );
  return confirmed;
});

export const selectProgram = (s: AppStore, programId: number) => selectPrograms(s)?.[programId];

export const selectProjectPractices = (s: AppStore, projectId: number) => {
  const project = selectProjectById(s, projectId);

  if (project) {
    const program = selectProgram(s, project.program_id);

    return program?.practice_changes || [];
  }

  return [];
};

export const selectUserProjects = createSelector(
  [selectUserProjectIds, selectProjects],
  (ids, projects) => ids.map(id => projects[id]).filter(Boolean)
);

export const selectPotentialCropFsCropType = createSelector(
  [selectCropTypesList, (s: AppStore, value: string) => value],
  (crops, value) => {
    if (value) {
      const crop = crops.find(c => {
        return c.value.includes(value?.split(', ')?.[0]?.toLowerCase() || 'unknown');
      });

      if (crop && crop.icon) {
        return crop.value;
      }
    }

    return '';
  }
);

export const selectEnrollmentProjectIdRedirectUrlForFMS = createSelector(
  [selectProjectId],
  projectId => {
    if (!projectId) return '';

    return `?redirect=${applyPathParams(MRV_ENROLLMENT, {projectId})}`;
  }
);

export const selectMonitoringProjectIdRedirectUrlForFMS = createSelector(
  [selectProjectId],
  projectId => {
    if (!projectId) return '';

    return `?redirect=${applyPathParams(MONITORING_PROJECT_FORM, {
      projectId,
    })}`;
  }
);

export const selectEnrolledFieldsFromFMS = createSelector(
  [selectProjectFieldsList],
  (projectFields = []) => {
    return projectFields.filter(f => !!f.core_attributes?.external_service);
  }
);

export const selectFMSOfEnrolledFieldsFromFMS = createSelector(
  [selectEnrolledFieldsFromFMS], // assume there is only one FMS fields were imported from
  importedFields => {
    let externalService = importedFields[0]?.core_attributes?.external_service;
    if (externalService === ExternalService.JohnDeereLong) {
      externalService = ExternalService.JohnDeere; // normalize the service name
    }
    return externalService;
  }
);

export const selectHasEnrolledFields = (s: AppStore) =>
  Object.keys(s.carbon.enrolledFields).length > 0;

export const selectMonitoringContractIsSigned = createSelector(
  // TODO - eventually we must move to the commented out code below.
  // When producerAgreement is treated as an actual stage.
  //
  // [
  //   selectCurrentProject,
  //   (s: AppStore) => selectPhaseStageByType(s, MRVPhaseType.Monitoring, MRVStageType.Contract),
  // ],
  // (project, producerAgreementStage) => {
  //   return Boolean(
  //     producerAgreementStage?.enabled &&
  //       project?.contracts?.length &&
  //       project.contracts[0].docusign_status === 'completed' &&
  //       project.contract_link
  //   );
  // }
  [selectCurrentProject, (s: AppStore) => selectPhase(s, MRVPhaseType.Monitoring)],
  (project, phase) => {
    return Boolean(
      phase?.show_contract &&
        project?.contracts?.length &&
        project.contracts[0].docusign_status === 'completed' &&
        project.contract_link
    );
  }
);

/**
 * Has a similar implementation to selectEnrollmentReadOnly()
 */
export const selectMonitoringReadOnly = createSelector(
  [
    selectIsImpersonatorNotSuperAdmin,
    selectMonitoringContractIsSigned,
    selectCurrentProject,
    (s: AppStore) => selectPhase(s, MRVPhaseType.Monitoring),
    getToday,
    selectHasEnrolledFields,
  ],
  (
    isImpersonatorNotSuperAdmin,
    contractIsSigned,
    project,
    monitoring,
    todayDate,
    hasEnrolledFields
  ): {isReadOnly: boolean; reason: PhaseReadOnlyReason; detailedReason: string} => {
    return monitoringReadOnlyCheck(
      isImpersonatorNotSuperAdmin,
      contractIsSigned,
      monitoring,
      todayDate,
      hasEnrolledFields,
      project
    );
  }
);

export const monitoringReadOnlyCheck = (
  isImpersonatorNotSuperAdmin: boolean,
  contractIsSigned: boolean,
  monitoring: MRVPhaseNormalized,
  todayDate: string,
  hasEnrolledFields: boolean,
  project?: MRVProjectNormalized
): {isReadOnly: boolean; reason: PhaseReadOnlyReason; detailedReason: string} => {
  const isTooEarly = !moment(todayDate).isSameOrAfter(moment(monitoring?.start_date), 'day');
  const isTooLate = moment(todayDate).isAfter(moment(monitoring?.end_date), 'day');

  if (isImpersonatorNotSuperAdmin) {
    return {
      isReadOnly: true,
      reason: PhaseReadOnlyReason.IsImpersonatorNotSuperAdmin,
      detailedReason: t({
        id: 'PhaseReadOnlyReason.IsImpersonatorNotSuperAdmin',
        defaultMessage: `you're logged in as another user`,
      }),
    };
  }

  if (contractIsSigned) {
    return {
      isReadOnly: true,
      reason: PhaseReadOnlyReason.ContractIsSigned,
      detailedReason: t(
        {
          id: 'PhaseReadOnlyReason.EnrolledInProgram',
          defaultMessage: 'you already enrolled in {program}',
        },
        {program: project?.program_name}
      ),
    };
  }

  if (isTooEarly) {
    return {
      isReadOnly: true,
      reason: PhaseReadOnlyReason.TooEarly,
      detailedReason:
        'monitoring starts on ' + moment(monitoring?.start_date).format('MMMM D, YYYY'),
    };
  }

  if (isTooLate) {
    return {
      isReadOnly: true,
      reason: PhaseReadOnlyReason.TooLate,
      detailedReason:
        'monitoring has ended on ' + moment(monitoring?.end_date).format('MMMM D, YYYY'),
    };
  }

  if (!hasEnrolledFields) {
    return {
      isReadOnly: true,
      reason: PhaseReadOnlyReason.NoFieldsEnrolled,
      detailedReason: `You'll need to enrol some fields before proceeding to the Measuring phase`,
    };
  }

  return {
    isReadOnly: false,
    reason: PhaseReadOnlyReason.Unknown,
    detailedReason: 'unknown',
  };
};

export const selectImportedValues = createSelector([selectCurrentProjectValues], values =>
  values.filter(value => value.source !== ProjectValueSource.user)
);

export const selectSyncingOperationsStatus = (s: AppStore) => {
  const monitoringSyncPlatforms: IntegrationPlatform[] = [
    ExternalService.JohnDeere,
    ExternalService.Climate,
  ];

  const isSync = monitoringSyncPlatforms.some(platform => {
    const platformData = selectIntegrationPlatform(s, platform);
    return platformData.syncStatus === RequestStatus.Loading;
  });

  const syncingPlatform =
    isSync &&
    monitoringSyncPlatforms.find(platform => {
      const platformData = selectIntegrationPlatform(s, platform);
      return platformData.syncStatus === RequestStatus.Loading;
    });

  return {isSync, syncingPlatformName: ExternalServiceLabels[syncingPlatform] || ''};
};

export const selectProgramsPagination = (s: AppStore) => {
  const {page, size, total} = s.monitoring.programs.loaded;
  return {
    shouldFetchMore: page * size < total,
    total,
    page,
  };
};

export const selectProgramInvites = createCachingSelector(
  [selectEntities, retrieveIdParam],
  (entities, programId) => {
    return entities.invites[programId];
  }
);

export const selectCoordinateSystem = createCachingSelector(
  [selectCurrentProgram],
  (program): CoordinateSystem | undefined => {
    return program?.fallback_coordinate_system;
  }
);
