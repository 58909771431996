import {Flex, FluroButton, FluroDialog, FluroInput, Text} from 'components';
import {FluroChipDropdownControllable as FluroChipDropdown} from 'components/fluro-dropdown/fluro-chip-dropdown';
import {Controller, useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import {setProfileSettings} from 'containers/login/actions';
import type {ComponentType} from 'react';
import React, {useEffect, useMemo, useState} from 'react';
import {useAppDispatch, useAppSelector} from '_hooks';
import Yup from 'yup';
import {FontIcon, SelectionControl} from 'react-md';
import {setFilter} from 'modules/sustainability-insights/actions';
import type {SIFilterState} from 'modules/sustainability-insights/types';
import {selectSIFilterPropsToSave} from 'modules/sustainability-insights/selectors';
import {selectUserSettings, selectUserSISavedFilters} from 'containers/login/login-selectors';
import {deepCopy} from '_utils';
import {getTypedKeys} from '_utils/object';
import {CardHr} from 'components/card/card-styled';
import cn from 'classnames';
import {isDefined} from '_utils/typeGuards';

type Props = {
  isLoading: boolean;
};

const dropdownStyles = {width: 'max-content', right: 0, paddingBottom: 0, paddingTop: '5px'};

export const SaveFilter: ComponentType<Props> = ({isLoading}) => {
  const dispatch = useAppDispatch();
  const siFilter = useAppSelector(selectSIFilterPropsToSave);
  const userSavedFilters = useAppSelector(selectUserSISavedFilters);
  const userSettings = useAppSelector(selectUserSettings);
  const [expand, setExpand] = useState(false);
  const [filterApplied, setFilterApplied] = useState(0);
  const [filterToEdit, setFilterToEdit] =
    useState<{id: number; filter: Partial<SIFilterState>; name: string}>();
  const [saveNewFilterFormVisible, toggleSaveNewFilterFormVisibility] = useState(false);
  const [initialFilter, setInitialFilter] = useState<Partial<SIFilterState>>(siFilter);

  const userHasSavedFilters = Object.keys(userSavedFilters).length;

  const userSavedFiltersArray = useMemo(() => {
    return getTypedKeys(userSavedFilters).map(id => ({
      name: userSavedFilters[id].name,
      filter: userSavedFilters[id].filter,
      id,
    }));
  }, [userSavedFilters]);

  useEffect(() => {
    setFilterApplied(0); // reset applied filter when some changes where applied
  }, [siFilter]);

  const hideSaveNewFilterDialog = () => toggleSaveNewFilterFormVisibility(false);

  const onClearFilters = () => {
    setFilterApplied(0); // reset applied filter when some changes where applied
    dispatch(setFilter(initialFilter));
  };

  const applySelectedFilter = (id: number) => {
    if (!filterApplied) {
      setInitialFilter(siFilter);
    }
    const filter = userSavedFilters[id]?.filter;
    filter && dispatch(setFilter(filter));
    setExpand(false);
    setTimeout(() => setFilterApplied(id), 1000); // wait until all internal filter updates are made
  };

  const saveFilterName = async (filterName: string) => {
    hideSaveNewFilterDialog();
    const copiedUserSettings = deepCopy(userSettings);

    if (filterToEdit && isDefined(copiedUserSettings)) {
      await dispatch(
        setProfileSettings(
          {
            ...copiedUserSettings,
            si_saved_filters: {
              ...userSavedFilters,
              [filterToEdit.id]: {
                name: filterName,
                filter: filterToEdit.filter,
              },
            },
          },
          'Filter is saved'
        )
      );
      applySelectedFilter(filterToEdit.id);
    }
  };

  const onEditFilterName = (id: number) => {
    setFilterToEdit({id, filter: userSavedFilters[id]?.filter, name: userSavedFilters[id]?.name});
    toggleSaveNewFilterFormVisibility(true);
  };

  const onCreateNewFilter = () => {
    const id = userHasSavedFilters
      ? Math.max(...getTypedKeys(userSavedFilters)) + 1 // get next id;
      : 1;
    setFilterToEdit({id, filter: siFilter, name: ''});
    toggleSaveNewFilterFormVisibility(true);
  };

  const onDeleteFilter = (id: number) => {
    if (confirm('Are you sure?')) {
      const copiedUserSettings = deepCopy(userSettings);

      if (copiedUserSettings?.si_saved_filters[id]) {
        delete copiedUserSettings.si_saved_filters[id];
        dispatch(setProfileSettings(copiedUserSettings));
      }

      setExpand(false);
    }
  };

  const appliedFilterName = userSavedFilters[filterApplied]?.name || 'Your saved views';

  return (
    <>
      <Flex alignItems="center" justifyContent="space-between" className="save-filter">
        <Text className="margin-bottom-0" variant="h2">
          Filter
        </Text>

        <FluroChipDropdown
          active={!!filterApplied}
          expand={expand}
          dropdownStyle={dropdownStyles}
          setExpand={setExpand}
          onClear={onClearFilters}
          label={appliedFilterName}
          disabled={isLoading}
        >
          <>
            {userSavedFiltersArray.map(filter => {
              return (
                <Flex
                  alignItems="center"
                  nowrap
                  gap={'5px'}
                  key={`user-saved-filters--${filter.id}`}
                >
                  <SelectionControl
                    id={filter.id}
                    name={filter.name}
                    type="radio"
                    label={filter.name}
                    checked={filterApplied === filter.id}
                    onClick={() => applySelectedFilter(filter.id)}
                  />
                  <FluroButton
                    className="filter-action-btn margin-left-auto"
                    icon
                    iconEl={<FontIcon>edit</FontIcon>}
                    onClick={() => onEditFilterName(filter.id)}
                  />
                  <FluroButton
                    className="filter-action-btn"
                    icon
                    iconEl={<FontIcon>delete</FontIcon>}
                    onClick={() => onDeleteFilter(filter.id)}
                  />
                </Flex>
              );
            })}
            <Flex
              className={cn({'save-new-view-container': true, bordered: userHasSavedFilters})}
              fullWidth
              justifyContent="center"
            >
              <FluroButton raised blank transparent onClick={() => onCreateNewFilter()}>
                + Save new view
              </FluroButton>
            </Flex>
          </>
        </FluroChipDropdown>

        {saveNewFilterFormVisible && (
          <FilterNameForm
            onHide={hideSaveNewFilterDialog}
            name={filterToEdit?.name}
            submit={saveFilterName}
          />
        )}
      </Flex>
      <CardHr />
    </>
  );
};

type FilterNameFormProps = {
  onHide: () => void;
  name?: string;
  submit: (name: string) => void;
};

const FilterNameForm: ComponentType<FilterNameFormProps> = ({onHide, name = '', submit}) => {
  const {handleSubmit, control} = useForm<{
    name: string;
  }>({
    resolver: yupResolver(
      Yup.object().shape({
        name: Yup.string().required().max(25),
      })
    ),
    mode: 'onChange',
    defaultValues: {name},
  });

  return (
    <FluroDialog
      id={'filter-selection-save--dialog'}
      initialFocus="#filter-name"
      focusOnMount
      title="Save filtered view"
      visible={true}
      onHide={onHide}
      width={400}
    >
      <Text secondary variant="medium" elementType={'div'}>
        Save your current filter selections so you can revisit this view anytime
      </Text>
      <form onSubmit={handleSubmit(({name}) => submit(name))}>
        <Controller
          name="name"
          control={control}
          render={({field: {value, onChange}, fieldState: {error}}) => (
            <>
              <FluroInput
                id={'filter-name'}
                value={value}
                onChange={onChange}
                label="Name"
                error={!!error}
                errorText={error?.message}
              />
            </>
          )}
        />

        <Flex className="margin-top-10" fullWidth justifyContent="space-between">
          <FluroButton onClick={onHide} raised blank>
            Cancel
          </FluroButton>

          <FluroButton onClick={handleSubmit(({name}) => submit(name))} raised primary>
            Save
          </FluroButton>
        </Flex>
      </form>
    </FluroDialog>
  );
};
