import {t} from 'i18n-utils';
import React from 'react';
import moment from 'moment';

import AnomalyItem from 'components/anomalies-ui/anomaly-item';
import AnomalyLabelsDropdown from './labels-dropdown';

import {formatDate} from '_utils';

import {userFacingArea} from './anomalies-utils';
import {togglePopup} from '../../actions';
import {toggleEditAreaOfInterest} from '../../actions/areas-of-interest-actions';

import {
  toggleLowPerformingAreas,
  changeAnyTypeGeometryProp,
  toggleAnomaliesHistory,
} from '../../actions/anomalies-actions';

import type {IAnomaly} from './types';
import {useAppDispatch, useAppSelector} from '_hooks';
import {selectMeasurement} from 'containers/login/login-selectors';
import {
  selectCurrentDate,
  selectIsReadOnly,
  selectIsWholeTableView,
  selectMapOpenPopUpId,
} from 'containers/map/reducer/selectors';
import {
  selectAnomalies,
  selectPerformanceGeometries,
} from 'containers/map/features/anomalies/anomalies-selectors';

const AreasHistoryList = () => {
  const dispatch = useAppDispatch();
  const measurement = useAppSelector(selectMeasurement);
  const currentDate = useAppSelector(selectCurrentDate);
  const isReadOnly = useAppSelector(selectIsReadOnly);
  const wholeTableView = useAppSelector(selectIsWholeTableView);
  const openPopupId = useAppSelector(selectMapOpenPopUpId);
  const historyOpen = useAppSelector(selectAnomalies).historyOpen;
  const {historyGeometries} = useAppSelector(selectPerformanceGeometries);

  const getAnomalyItem = (anomaly: IAnomaly, i: number) => {
    const {area, checked, endDate, startDate, label, title, id, priority, isLowPerf} =
      anomaly.properties;
    return (
      <AnomalyItem
        measurement={measurement}
        label={
          <AnomalyLabelsDropdown
            disabled={isReadOnly}
            anomaly={anomaly}
            label={label}
            miniButton
            onChange={(prop, value, geometry) =>
              dispatch(changeAnyTypeGeometryProp(geometry as IAnomaly, prop, value))
            }
          />
        }
        isChecked={checked}
        area={userFacingArea(area, measurement)}
        key={`${id}-${i}`}
        startDate={startDate}
        labelName={label}
        endDate={endDate}
        title={title}
        openPopUp={openPopupId === id}
        onCheck={value => dispatch(changeAnyTypeGeometryProp(anomaly, 'checked', Boolean(value)))}
        onView={() => dispatch(togglePopup(id))}
        onEdit={() =>
          isLowPerf
            ? dispatch(toggleLowPerformingAreas(true, anomaly))
            : dispatch(toggleEditAreaOfInterest(true, anomaly))
        }
        isInHistory
        priority={priority}
      />
    );
  };

  const formattedCurrentDate = moment.utc(currentDate, 'DD/MM/YYYY').format(formatDate());

  return (
    <>
      {historyGeometries.length ? (
        <div className="anomalies-history">
          <div
            onClick={() => dispatch(toggleAnomaliesHistory(!historyOpen))}
            className={`anomalies-history__btn ${historyOpen ? 'opened' : ''}`}
          >
            {t({id: 'Areas history', defaultMessage: 'Areas history'})}
          </div>
          <div className="anomalies-history__subtitle">
            {t(
              {id: 'Not detected on', defaultMessage: 'Not detected on {formattedCurrentDate}'},
              {formattedCurrentDate}
            )}
          </div>
          {(historyOpen || wholeTableView) && (
            <div className="anomalies-history__list">{historyGeometries.map(getAnomalyItem)}</div>
          )}
        </div>
      ) : null}
    </>
  );
};

export default AreasHistoryList;
