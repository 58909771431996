import React from 'react';
import type {Farm} from 'containers/map/types';
import {FluroButton} from 'components';
import {useTranslation} from 'i18n-utils';

type Props = {
  isReadOnly: boolean;
  isAgxUser?: boolean;
  isAgxField: boolean;
  currentFarm: Farm;
  onEditFarm: () => void;
  onAddInvite: () => void;
};

const FarmInfo = ({
  currentFarm,
  isReadOnly,
  isAgxField,
  isAgxUser = false,
  onEditFarm,
  onAddInvite,
}: Props) => {
  const t = useTranslation();
  if (!currentFarm?.id) return null;

  return (
    <div className="farm-feature">
      <div className="farm-feature__controls-top">
        <h3 title={currentFarm.name} className="farm-feature__name">
          {currentFarm.growerName && (
            <div className={'farm-feature__grower'}>{currentFarm.growerName}</div>
          )}
          {currentFarm.name}
        </h3>

        <div className={'flex-container'}>
          {!isReadOnly && (
            <FluroButton
              id={'edit-farm-btn'}
              icon
              tooltipPosition="left"
              tooltipLabel={t({id: 'Farm set-up', defaultMessage: 'Farm set-up'})}
              onClick={onEditFarm}
            >
              edit
            </FluroButton>
          )}

          {(isAgxUser && isAgxField) || isReadOnly ? null : (
            <FluroButton
              id={'invite-user-btn'}
              icon
              onClick={onAddInvite}
              className="farm-feature__add-user"
              tooltipPosition="left"
              tooltipLabel={t({
                id: 'Add new user to your farm',
                defaultMessage: 'Add new user to your farm',
              })}
            >
              person_add
            </FluroButton>
          )}
        </div>
      </div>
    </div>
  );
};

export default FarmInfo;
