import React from 'react';
import {Chip} from 'react-md';

type State = {label: string; value: number | string};
type Props = {
  state: State;
  onClick(val: State): void;
  removable?: boolean;
};

const StateChip = ({state, onClick, removable = true}: Props) => {
  const handleRemove = () => {
    removable && onClick(state);
  };

  return <Chip onClick={handleRemove} removable={removable} label={state.label} />;
};

export default StateChip;
