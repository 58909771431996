import React, {useMemo} from 'react';
import {useAppDispatch, useAppSelector} from '_hooks';
import {Flex, Text, FluroInput, FluroSelect} from 'components';
import {
  setNewName,
  setNewStartYear,
  setNewEndYear,
  setNewReportType,
  setNewModel,
  setNewProtocol,
} from 'containers/si/module/reducer';
import {
  selectNewReportName,
  selectNewReportStartYear,
  selectNewReportEndYear,
  selectNewReportType,
  selectNewReportModel,
  selectNewReportProtocol,
} from 'containers/si/module/selectors';
import type {Model, ReportType, Protocol} from 'containers/si/api/apiTypes';
import {
  PLANNING_REPORT_TYPE_OPTIONS,
  PLANNING_REPORT_MODEL_OPTIONS,
  PLANNING_REPORT_PROTOCOL_OPTIONS,
} from 'containers/si/constants';

export const DetailsStep = () => {
  const dispatch = useAppDispatch();

  const newName = useAppSelector(selectNewReportName);
  const newStartYear = useAppSelector(selectNewReportStartYear);
  const newEndYear = useAppSelector(selectNewReportEndYear);
  const newReportType = useAppSelector(selectNewReportType);
  const newModel = useAppSelector(selectNewReportModel);
  const newProtocol = useAppSelector(selectNewReportProtocol);

  const getYearsList = useMemo(() => {
    const currentYear = new Date().getFullYear();
    const earliestYear = currentYear - 10;
    const latestYear = currentYear + 10;
    const years = [];

    for (let i = latestYear; i >= earliestYear; i--) {
      years.push({
        label: i.toString(),
        value: i.toString(),
      });
    }

    return years;
  }, []);

  return (
    <Flex direction="column" alignItems={'center'} className={'details-step'}>
      <Flex direction="column" alignItems={'center'} className={'details-container'}>
        <div className="details-row">
          <Text>Report Name</Text>
          <FluroInput
            type="text"
            id="new-report-name"
            name="new-report-name"
            value={newName}
            className="details-value"
            onChange={p => dispatch(setNewName(String(p)))}
            placeholder="Enter report name"
          />
        </div>
        <div className="details-row">
          <Text>Report Type</Text>
          <FluroSelect
            value={newReportType}
            onChange={p => dispatch(setNewReportType(p as ReportType))}
            options={PLANNING_REPORT_TYPE_OPTIONS}
            className="details-value"
          />
        </div>
        <div className="details-row">
          <Text>Report Years</Text>
          <div className="details-years-value">
            <FluroSelect
              value={String(newStartYear)}
              onChange={value => dispatch(setNewStartYear(Number(value)))}
              options={getYearsList}
              className="details-year-selector"
            />
            <span className="pl-1 pr-1">-</span>
            <FluroSelect
              value={String(newEndYear)}
              onChange={value => dispatch(setNewEndYear(Number(value)))}
              options={getYearsList}
              className="details-year-selector"
            />
          </div>
        </div>
        <div className="details-row">
          <Text>Model</Text>
          <FluroSelect
            value={newModel}
            onChange={p => dispatch(setNewModel(p as Model))}
            options={PLANNING_REPORT_MODEL_OPTIONS}
            className="details-value"
          />
        </div>
        <div className="details-row">
          <Text>Protocol</Text>
          <FluroSelect
            value={newProtocol}
            onChange={p => dispatch(setNewProtocol(p as Protocol))}
            options={PLANNING_REPORT_PROTOCOL_OPTIONS}
            className="details-value"
          />
        </div>
      </Flex>
    </Flex>
  );
};
