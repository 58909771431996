import {Tooltip} from 'components/tooltip/tooltip';
import type {ToggleGlobalTooltipProps} from 'modules/global/types';
import type {FC} from 'react';
import React from 'react';
import {FontIcon} from 'react-md';

export const CardToolTip: FC<ToggleGlobalTooltipProps> = tooltipProps => {
  return (
    <Tooltip {...tooltipProps}>
      <FontIcon>help_outline</FontIcon>
    </Tooltip>
  );
};
