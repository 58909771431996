import {toggleTooltip} from 'modules/global/actions';
import type {ToggleGlobalTooltipProps} from 'modules/global/types';
import type {FC} from 'react';
import React, {Fragment} from 'react';
import {useAppDispatch} from '_hooks';

export const Tooltip: FC<ToggleGlobalTooltipProps & {disabled?: boolean}> = ({
  children,
  disabled = false,
  ...tooltipProps
}) => {
  const dispatch = useAppDispatch();
  return disabled ? (
    <Fragment>{children}</Fragment>
  ) : (
    <div
      data-tip=""
      data-for={tooltipProps.id}
      onMouseEnter={() => dispatch(toggleTooltip({...tooltipProps}))}
    >
      {children}
    </div>
  );
};
