import {createSelector} from '@reduxjs/toolkit';
import {
  selectCurrentDateObj,
  selectCurrentField,
  selectHistogram,
} from 'containers/map/reducer/selectors';
import {convertUnit} from '_utils';
import {toFixedFloatUnsafe} from '_utils/number-formatters';
import {scale} from 'chroma-js';
import {calculateScale} from 'modules/sustainability-insights/utils';
import {selectMeasurement} from 'containers/login/login-selectors';

const histogramScale = scale([
  '#a50026',
  '#fc8d59',
  '#fee08b',
  '#ffffbf',
  '#d9ef8b',
  '#91cf60',
  '#006837',
]);

export const selectHistogramBarValues = createSelector([selectHistogram], histogram => {
  return histogram.bins.map(binValue => toFixedFloatUnsafe(binValue, 3));
});

export const selectHistogramPercentages = createSelector([selectHistogram], histogram => {
  return histogram.percentages;
});

export const selectHistogramBarRangeLabels = createSelector(
  [selectHistogramBarValues],
  histogramValues => {
    return histogramValues
      .map((binValue, i) => {
        if (histogramValues[i + 1] === undefined) return ''; // skip the last element because below we use the current + the next elem

        return `${toFixedFloatUnsafe(binValue, 2)} - ${toFixedFloatUnsafe(
          histogramValues[i + 1],
          2
        )}`;
      })
      .filter(Boolean);
  }
);

export const selectHistogramBarData = createSelector(
  [selectHistogramPercentages, selectCurrentField, selectCurrentDateObj, selectMeasurement],
  (percentages, field, date, measurement) => {
    const cloudPercentage = 1 - (date?.Cloud || 0) / 100;
    const notCloudedFieldArea = convertUnit(measurement, 'ac', field.Area) * cloudPercentage; // get and use not clouded field area, because histogram service removes the clouded pixels before calculations

    return percentages.map(percentage => toFixedFloatUnsafe(percentage * notCloudedFieldArea, 2));
  }
);

export const selectHistogramBarColors = createSelector(
  [selectHistogramBarValues],
  histogramValues => {
    const min = Math.min(...histogramValues);
    const max = Math.max(...histogramValues);
    return histogramValues.map(binValue =>
      histogramScale(calculateScale(binValue, min, max)).css()
    );
  }
);

export const selectHistogramBarLegendData = createSelector(
  [selectHistogramBarRangeLabels, selectHistogramBarColors, selectHistogramPercentages],
  (labels, colors, percentages) => {
    return {
      labels,
      percentages: percentages.map(percentage => toFixedFloatUnsafe(percentage * 100, 2)),
      colors,
    };
  }
);
