import {CI_ROOT_PATH, MRV, SI} from '_environment';
import type {Measurement, SIAccess, CompanyLicenses} from 'containers/login/types';
import type {Subscription} from 'modules/subscriptions';
import type {Locales} from '../../../i18n-utils';
import type {IntegrationPlatform} from '../../profile/integration/types';
import type {SIFilterState} from 'modules/sustainability-insights/types';

export type User = {
  id: number | 'new';
  name: string;
  surname?: string;
  phone?: string;
  email: string;
  photo?: string;
  password?: string;
  groupIds?: number[];
  perm: number;
  active: number;
  emailConfirmed?: number | boolean;
  settings?: UserSettings;

  agxSync?: boolean;
  demoFarms: number[];
  firstName?: string;
  interestClass?: string;
  createdAt?: string;
  defaultOrganizationID?: number | null;
  defaultSubscriptionID?: number | null;
  signupUUID?: string;
  apiUser?: string;

  workspaces?: {[workspase in Workspace]: boolean};
};

/**
 * mrv for Monitoring Reporting Verification
 * ci for Crop Insights (main app)
 * si for Sustainability insights
 */
export enum Workspace {
  Mrv = 'mrv',
  Ci = 'ci',
  Si = 'si',
}
export const workspaces: Workspace[] = [Workspace.Mrv, Workspace.Si, Workspace.Ci];
export const isWorkspace = (workspace: string): workspace is Workspace =>
  workspaces.includes(workspace as Workspace);
export const workspaceLabels: {[workspase in Workspace]: string} = {
  ci: 'Crop Insights',
  mrv: 'MRV',
  si: 'Sustainability Insights',
};
export const workspaceLinks = {
  mrv: `/${MRV}`,
  si: `/${SI}`,
  ci: `/${CI_ROOT_PATH}`,
} as const;

export const getWorkspace = (path = location.pathname) => {
  switch (true) {
    case path.startsWith(workspaceLinks.mrv):
      return Workspace.Mrv;
    case path.startsWith(workspaceLinks.si):
      return Workspace.Si;
    case path.startsWith(workspaceLinks.ci):
      return Workspace.Ci;
    default:
      return Workspace.Ci;
  }
};

export const checkWorkspace = (path = location.pathname) => {
  const workspace = getWorkspace(path);

  return {
    workspace,
    isWorkspaceMrv: workspace === Workspace.Mrv,
    isWorkspaceSi: workspace === Workspace.Si,
    isWorkspaceCi: workspace === Workspace.Ci,
  };
};

/* 

The function applies the passed property regarding the current workspace

For example:
if you have three different URLs for each workspace. 
to avoid writing a bunch of if-else we just put it inside the function

 const releaseNotesLink = applyWorkspace({
    [Workspace.Mrv]: 'https://mrv.notes.com/',
    [Workspace.Si]: 'https://si.notes.com/
    
    other: 'all other workspaces'
})

Note: default - will be applied to all not mentioned workspaces
*/
export const applyWorkspace = <T>(spaces: {
  [Workspace.Mrv]?: T;
  [Workspace.Si]?: T;
  [Workspace.Ci]?: T;
  other: T;
}) => {
  const workspace = getWorkspace();

  return (spaces[workspace] !== undefined ? spaces[workspace] : spaces.other) as T;
};

export type UserSettings = {
  zoning?: UserZoning;
  locale: string;
  langLocale: Locales;
  measurement: Measurement;
  company?: UserCompany;
  showDemoFarms?: boolean;
  pushInfo?: any;
  timezone?: string;
  // notificationFrequency?: number; //deprecated
  tspGroups?: any[]; // [{fieldId: string, groups: [{date: string, gs: string, tsp: [1, 2, 3]}]}]
  surname?: string; // ToDo remove duplicate prop
  scenarioPlanningTool?: 'cargill'; // user registered as scenarioPlanningTool user. The only workflow we have so far is Cargill
  si_tool_access: SIAccess;
  onboarding: {monitoring_video_onboarded?: boolean; re_enroll_fields_onboarded?: boolean};
  favorite_platforms: Record<IntegrationPlatform, boolean>;
  si_saved_filters: Record<number, {name: string; filter: Partial<SIFilterState>}>;
  mostRecentWorkspace?: Workspace;
};

export interface UserZoning {
  doNotShowConfirmPopUp: boolean; // deprecate the prop
  favoriteZoningMethod: any; // TODO backend return Null
}

export interface UserCompany {
  companyName: string;
  businessActivity: string;
  jobTitle: string;
  country: string;
  street: string;
  state: string;
  city: string;
  zip: string;
  licenses: CompanyLicenses;
}

export type InitialState = {
  list: User[];
  user: User;
  organizations: Organization[];
  subscriptions: Subscription[];
};

export type Organization = {
  id: number;
  name: string;
  created_by: number;
  subscription_id: number;
  subscription_name: string;
  created_at: string;
  fields: number;
  farms: number;
  fields_area: number;
  direct_users: number;
  indirect_users: number;
};

export type ActionPayload = {
  type: string;
  list?: User[];
  user?: User;
  organizations?: Organization[];
  subscriptions?: Subscription[];
};

export enum ActionType {
  LOAD_USERS_LIST = 'users/load-users-list',
  LOAD_USERS_ONE = 'users/load-users-one',
  SET_ORGS = 'users/set-organizations',
  SET_SUBS = 'users/set-subscriptions',
}
