// @ts-nocheck
import {selectCCGeometryData} from 'modules/sustainability-insights/selectors';
import type {AreaId} from 'modules/sustainability-insights/types';
import type {ComponentType} from 'react';
import React from 'react';
import {useAppSelector} from '_hooks';
import {kiloFormatter} from '_utils/number-formatters';
import {pctFormatter} from '../filters-panel/cards/utils';

type Props = {id: AreaId; name: string};

export const CoverCropsTooltip: ComponentType<Props> = ({id, name}) => {
  const tooltipData = useAppSelector(s => selectCCGeometryData(s, id));
  if (!tooltipData) return <>No data for {name}</>;

  const isPct = tooltipData.units === 'pct';

  let underLabel = null;

  // Ref: https://regrow.atlassian.net/browse/FSB-5850?focusedCommentId=28986
  if (!!tooltipData.years) {
    const {metricsType, value, years} = tooltipData;
    const [yearFrom, yearTo] = years;
    const signLabel =
      Math.sign(value) === 1 ? (
        <strong className="color-success">increase</strong>
      ) : (
        <strong className="color-danger">decrease</strong>
      );

    if (metricsType === 'ac') {
      if (isPct) {
        // 4% increase in acres with cover crops from 2019-2020
        underLabel = (
          <p className="mb-0">
            {pctFormatter(value)} {signLabel} in acres with cover crops from {yearFrom}-{yearTo}
          </p>
        );
      } else {
        // 6.7k ac increase in acres with cover crops from 2019-2020
        underLabel = (
          <p className="mb-0">
            {kiloFormatter(value)} ac {signLabel} in acres with cover crops from {yearFrom}-{yearTo}
          </p>
        );
      }
    } else if (metricsType === 'op') {
      if (isPct) {
        // 6% increase in operations with cover crops from 2019-2020
        underLabel = (
          <p className="mb-0">
            {pctFormatter(value)} {signLabel} in operations with cover crops from {yearFrom}-
            {yearTo}
          </p>
        );
      } else {
        // 20 operation increase in operations with cover crops from 2019-2020
        underLabel = (
          <p className="mb-0">
            {value} operation {signLabel} in operations with cover crops from {yearFrom}-{yearTo}
          </p>
        );
      }
    }
  } else {
    const {total, pct, metricsType, value} = tooltipData;
    if (metricsType === 'ac') {
      if (isPct) {
        // 3% of 162.8 k acres with cover crops
        underLabel = (
          <p className="mb-0">
            {pctFormatter(pct)} of {kiloFormatter(total)} acres with cover crops
          </p>
        );
      } else {
        // #ac of 162.8k acres with cover crops
        underLabel = (
          <p className="mb-0">
            {kiloFormatter(value)} ac of {kiloFormatter(total)} acres with cover crops
          </p>
        );
      }
    } else if (metricsType === 'op') {
      if (isPct) {
        // 3% of 162.8 k op with cover crops
        underLabel = (
          <p className="mb-0">
            {pctFormatter(pct)} of {kiloFormatter(total)} op with cover crops
          </p>
        );
      } else {
        // # op of 1.9k op with cover crops
        underLabel = (
          <p className="mb-0">
            {kiloFormatter(value)} op of {kiloFormatter(total)} op with cover crops
          </p>
        );
      }
    }
  }

  return (
    <>
      <p className="mb-0">
        <strong>{name}</strong>
      </p>
      {underLabel}
    </>
  );
};
