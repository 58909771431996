import React, {useEffect, useState} from 'react';
import {KPIBarChart} from '../charts/kpi-bar-chart-card';
import {useAppSelector} from '_hooks';
import {kiloFormatter, toFixedFloat} from '_utils/number-formatters';
import {selectSISelectedCropTypes} from 'containers/si/module/selectors';
import {selectCropTypesCDLIdMap} from 'modules/global/selectors';
import {t} from 'i18n-utils';
import {TOOLTIPS} from 'containers/si/constants';
import {useFetchKPI} from 'containers/si/hooks/useFetchKPI';
import {useSparklingChart} from 'containers/si/hooks/useSparklingChart';
import {SparklingChartCard} from '../charts/sparkling-chart-card';

export const FieldNetEmissionFactorCard = () => {
  const cropsById = useAppSelector(selectCropTypesCDLIdMap);
  const selectedCropTypes = useAppSelector(selectSISelectedCropTypes);
  const [chartData, setChartData] = useState<{label: string; value: number}[]>([]);

  const {response: netEmissionResponse, loading} = useFetchKPI({
    kpi: 'net_ghg_emissions_factor',
    summarizeBy: ['crop_type', 'annualized'],
  });
  const {sparklingChartData, dataset, metric, average, comparisonValues} =
    useSparklingChart(netEmissionResponse);

  useEffect(() => {
    // Generate Chart Data
    if (!netEmissionResponse?.crop_type_summary) return;

    if (selectedCropTypes.length > 1) {
      const cropData = Object.entries(netEmissionResponse.crop_type_summary).map(
        ([cropType, value]) => {
          const cropName = cropsById[Number(cropType)]?.label ?? cropType;

          return {
            label: cropName,
            value: toFixedFloat(value, 2),
          };
        }
      );
      setChartData(cropData);
    }
  }, [netEmissionResponse, cropsById, selectedCropTypes]);

  return selectedCropTypes.length > 1 ? (
    <KPIBarChart
      title={t({id: 'Field net EF'})}
      subtitle={t({
        id: 'in kg CO2 eq./1 kg of yield',
      })}
      data={chartData}
      colors={['#A571CB']}
      tooltip={TOOLTIPS.KPI.fieldNetEmissionFactor}
      loading={loading}
    />
  ) : (
    <SparklingChartCard
      title={t({id: 'Field net EF'})}
      subtitle={t({id: 'in kg CO2 eq./1 kg of yield'})}
      cardCenterNumber={
        metric === 0 ? 'No Data' : `${String(kiloFormatter(metric, 3)).toUpperCase()}`
      }
      cardCenterUnits={''}
      comparisonValues={comparisonValues}
      sparklingChartCaption={`${sparklingChartData.datasets.length} year avg`}
      sparklingChartCaptionBolded={`${kiloFormatter(average, 3)}`}
      chartData={{labels: sparklingChartData.labels, datasets: dataset}}
      chartDataMidline={average}
      tooltip={TOOLTIPS.KPI.cropArea}
      decimals={3}
      loading={loading}
    />
  );
};
