import type {PropsWithChildren, CSSProperties} from 'react';
import React, {useState, useEffect, useRef} from 'react';
import cn from 'classnames';
import './sticky.scss';

type Props = PropsWithChildren<{
  className?: string;
  position?: 'top' | 'bottom';
  /**
   * TODO - fix, won't work if the parent container with overflow: auto has padding
   */
  elevateStuck?: boolean;
  alwaysElevated?: boolean;
  onClick?: () => void;
  /**
   * Managing state in the parent?
   * Pass the `stuck` value here.
   */
  stuck?: boolean;
  /**
   * Managing state in the parent?
   * Triggers when `stuck` changes.
   */
  onStuck?: (stuck: boolean) => void;
  variant?: 'container';
  style?: CSSProperties;
}>;

/**
 * Sticky container that sets the class `stuck` when its scrolled to "stuck" position.
 */
export const Sticky = ({
  children,
  className,
  position = 'bottom',
  elevateStuck = true,
  alwaysElevated,
  onClick,
  stuck,
  onStuck,
  variant,
  style,
}: Props) => {
  const [_stuck, setStuck] = useState(stuck || false);
  const stickyRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    setStuck(stuck || false);
  }, [stuck]);

  useEffect(() => {
    const stickyElement = stickyRef.current as Element;
    const observer = new IntersectionObserver(
      ([entry]) => {
        const notFullyVisible = entry.intersectionRatio > 0 && entry.intersectionRatio < 1;
        const dirtyFromSideOfInterest =
          position === 'top'
            ? entry.boundingClientRect.top !== entry.intersectionRect.top
            : entry.boundingClientRect.bottom !== entry.intersectionRect.bottom;

        const shouldStick = notFullyVisible && dirtyFromSideOfInterest;

        if (onStuck) {
          onStuck(shouldStick);
        } else {
          setStuck(shouldStick);
        }
      },
      {
        threshold: [1],
      }
    );
    observer.observe(stickyElement);
    return () => {
      observer.unobserve(stickyElement);
    };
  }, [onStuck, position]);

  return (
    <div
      onClick={onClick}
      ref={stickyRef}
      style={style}
      className={cn(className, {
        'fluro-sticky': true,
        'stuck-elevated': alwaysElevated || (elevateStuck && _stuck),
        stuck: _stuck,
        [position]: true,
        'sticky--container': variant === 'container',
      })}
    >
      {children}
    </div>
  );
};
