import type {FluroSelectLiteItem} from 'components/fluro-select-lite/fluro-select-lite';
import {ColoredCircle, FluroSelectLite} from 'components/fluro-select-lite/fluro-select-lite';
import {Tillage, tillageColor, tillageLabels} from 'containers/mrv/enrollment/base/base';
import {tillAppToServer, tillServerToApp} from 'containers/mrv/enrollment/base/crop-practice';
import {t} from 'i18n-utils';
import type {ComponentType} from 'react';
import React, {useContext} from 'react';
import {InputContext} from './input-context';
import {MRVSelectContainer} from './styled';
import type {onValueChange} from './types';

type Props = {onChange: onValueChange; testId?: string};

export const TillageSelect: ComponentType<Props> = ({onChange, testId}) => {
  const {disabled, value, placeholder, defaultValue, label, subtitle} = useContext(InputContext);

  const tillageOptions: FluroSelectLiteItem[] = [
    Tillage.NoTill,
    Tillage.Low,
    Tillage.Conventional,
  ].map(value => ({
    value: value,
    label: tillageLabels[value](),
    icon: <ColoredCircle color={tillageColor[value]} />,
  }));

  return (
    <MRVSelectContainer>
      <FluroSelectLite
        testId={testId}
        disabled={disabled}
        placeholder={
          placeholder || t({id: 'Placeholder.Select tillage', defaultMessage: 'Select tillage'})
        }
        selectedValue={tillServerToApp(value || defaultValue)}
        items={tillageOptions}
        onSelect={v => onChange(tillAppToServer(v))}
        label={label}
        subtitle={subtitle}
      />
    </MRVSelectContainer>
  );
};
