import {FluroChipDropdown} from 'components/fluro-dropdown/fluro-chip-dropdown';
import {FluroSelectionControlGroup} from 'components/fluro-selection-control-group/fluro-selection-control-group';
import {useTranslation} from 'i18n-utils';
import type {ComponentType} from 'react';
import React from 'react';
import {useDispatch} from 'react-redux';
import {useAppSelector} from '_hooks';
import {setFilterKey} from 'modules/sustainability-insights/actions';
import {
  selectIsDemographicsFilterSet,
  selectMetricsTypeFilter,
  selectOperationStatusFilter,
  selectSummerCropTypesFilter,
} from 'modules/sustainability-insights/selectors';
import type {SIMetricColumn} from 'modules/sustainability-insights/types';

type Props = {};

export const FieldOwnershipFilter: ComponentType<Props> = () => {
  const dispatch = useDispatch();
  const t = useTranslation();
  const value = useAppSelector(selectOperationStatusFilter);
  const summerCropTypes = useAppSelector(selectSummerCropTypesFilter);
  const operationsFilterSet = useAppSelector(s =>
    selectIsDemographicsFilterSet(s, 'operationAdopterType')
  );
  const metricTypesFilter = useAppSelector(selectMetricsTypeFilter);

  const disabled = Boolean(summerCropTypes || operationsFilterSet || metricTypesFilter === 'op');

  const operationStatusOptions = [
    {
      label: t('Owner/Operator'),
      value: 'owner/operator',
    },
    {
      label: t('Rented/Leased'),
      value: 'rented/leased',
    },
  ];

  const handleFilterChange = (value: SIMetricColumn) => {
    dispatch(setFilterKey('operationStatus', value));
  };

  const clearFilter = () => {
    dispatch(setFilterKey('operationStatus', undefined));
  };

  const label = !!value
    ? operationStatusOptions.find(i => i.value === value)?.label
    : t('Field Ownership');

  return (
    <FluroChipDropdown
      active={!!value}
      className="grower-demographics-chip-dropdown"
      label={label}
      onClear={clearFilter}
      disabled={disabled}
    >
      <FluroSelectionControlGroup
        id="operation-status-radio"
        name="operationStatus"
        type="radio"
        value={value}
        onChange={v => handleFilterChange(v as SIMetricColumn)}
        controls={operationStatusOptions}
      />
    </FluroChipDropdown>
  );
};
