import type {AxiosPromise} from 'axios';
import Server from '../server';
import type {ClimateFarmsResponse} from 'containers/profile/integration/types';

export default class ClimateApi {
  static getFarms(): AxiosPromise<ClimateFarmsResponse> {
    return Server.get(`sync/climate`, {params: {__skipPreloader: true}});
  }

  static syncFields(fieldIds: string[], mrvProjectId: number, phaseId?: number) {
    const requestParams: Record<string, any> = {__skipPreloader: true};
    if (mrvProjectId) {
      requestParams.mrv_project_id = mrvProjectId;
    }
    if (phaseId) {
      requestParams.mrv_phase_id = phaseId;
    }

    return Server.post(`sync/climate`, `field_ids=${fieldIds.join(',')}`, {
      params: requestParams,
    });
  }

  static getSyncStatus(jobId: string) {
    return Server.get(`sync/climate/status/${jobId}`, {
      params: {__skipPreloader: true, include_summary: true},
    });
  }

  static logOut() {
    return Server.delete(`sync/climate/delete_user`);
  }
}
