import type {Reducer} from 'redux';
import type {DialogToggleAction, HelpersDialogsCloseAllAction, HelpersStore} from './types';
import {ActionType, DialogType} from './types';

const initialState = {
  info: {
    visible: false,
  },
  statusAlert: {
    visible: false,
  },
  adminAssignOrganizationToFarm: {
    visible: false,
  },
  adminAssignSubscriptionToOrganization: {
    visible: false,
  },
  adminSubscriptionsCreateFarm: {
    visible: false,
  },
  histogram: {
    visible: false,
  },

  assignMultipleFarms: {
    visible: false,
  },

  assignMultipleOrgs: {
    visible: false,
  },

  emailLogsSend: {
    visible: false,
  },

  emailLogsDiscard: {
    visible: false,
  },

  confirm: {
    visible: false,
  },

  alert: {
    visible: false,
  },

  fieldInfo: {
    visible: false,
  },

  editFieldName: {
    visible: false,
  },

  [DialogType.editCloudy]: {
    visible: false,
  },

  [DialogType.editSelectedSeasonsProductivityMap]: {
    visible: false,
  },

  [DialogType.exportEOS]: {
    visible: false,
  },

  [DialogType.cropInsightsReport]: {
    visible: false,
  },

  [DialogType.editCrop]: {
    visible: false,
  },

  [DialogType.fmsSyncReport]: {
    visible: false,
    payload: {},
  },

  [DialogType.fmsSyncDialog]: {
    visible: false,
    payload: {},
  },

  [DialogType.integrationPlatforms]: {
    visible: false,
  },
  [DialogType.addNewField]: {
    visible: false,
  },

  [DialogType.carbonLogin]: {
    visible: false,
  },

  [DialogType.carbonSignup]: {
    visible: false,
  },

  [DialogType.carbonSignupConfirmation]: {
    visible: true,
  },

  [DialogType.carbonRestorePassword]: {
    visible: false,
  },

  // mrv

  [DialogType.mrvPhasesInfo]: {
    visible: false,
  },

  [DialogType.noContractInfo]: {
    visible: false,
  },

  // ---

  [DialogType.editFarm]: {
    visible: false,
  },

  [DialogType.editFarmName]: {
    visible: false,
  },

  [DialogType.deleteDialog]: {
    visible: false,
  },

  [DialogType.carbonResources]: {
    visible: false,
  },

  [DialogType.cropPerformanceReport]: {
    visible: false,
  },

  [DialogType.pivothermoReport]: {
    visible: false,
  },

  [DialogType.cropStressReport]: {
    visible: false,
  },

  [DialogType.endOfSeasonReport]: {
    visible: false,
  },

  [DialogType.requestFSAccess]: {
    visible: false,
  },
};

const dialogsReducer: Reducer<
  HelpersStore['dialogs'],
  DialogToggleAction | HelpersDialogsCloseAllAction
> = (state = initialState, action) => {
  switch (action.type) {
    case ActionType.DialogToggle: {
      return {
        ...state,
        [action.dialogKey]: {
          visible: action.visible,
          // if dialog closed clear payload
          payload: action.visible ? action.payload || undefined : undefined,
        },
      };
    }
    case ActionType.CloseAllDialogs: {
      return {
        ...initialState,
      };
    }

    default:
      return state;
  }
};

export default dialogsReducer;
