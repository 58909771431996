import styled from 'styled-components';
import {FontIcon} from 'react-md';

const sidePadding = `1rem`;

export const CardStyled = styled.div`
  background-color: #fff;
  margin: 20px 0;
  border: 1px solid #d6d8da;
  border-radius: 4px;
  padding: 10px ${sidePadding} 20px ${sidePadding};
  transition: border 0.2s;
  cursor: pointer;

  &.active,
  &:hover {
    border: 1px solid #757575;
  }
  .see-more-btn {
    margin: 10px auto 0;
    display: block;
  }
`;

export const CardTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 20px;
  margin-bottom: ${sidePadding};
  margin-left: -${sidePadding};
  margin-right: -${sidePadding};
  border-bottom: 1px solid #eeeeee;
  cursor: pointer;
`;

export const CardTitleText = styled.h3`
  margin: 0 0 8px ${sidePadding};
  white-space: normal;
`;

export const CardSubtitle = styled.span`
  color: $main-gray-700;
  margin-left: 4px;
`;

export const Controls = styled.div`
  display: flex;
`;

export const HelpButton = styled(FontIcon)`
  padding: 8px 8px 14px 0;
  color: rgba(0, 0, 0, 0.24);
  &:hover {
    color: rgba(0, 0, 0, 0.54);
  }
`;

export const ResetFilterButton = styled.button`
  font-size: 14px;
  margin: 0 8px 8px 0;
  padding: 6px 6px 2px;
  background-color: darkorange;
  border-radius: 4px;
  border: none;
  cursor: pointer;
  &:hover:not([disabled]) {
    box-shadow: 0 0 5px #ccc;
  }
  &:disabled {
    background: #eeeeee;
    border: none;
  }
`;

export const CardInfo = styled.div`
  color: $main-gray-700;
  margin-bottom: 12px;
`;

export const CollapseButton = styled.div`
  display: flex;
  justify-content: center;
  .md-icon {
    color: rgba(0, 0, 0, 0.24);
  }
  &:hover .md-icon {
    color: rgba(0, 0, 0, 0.54);
  }
`;

export const CardHeading = styled.div`
  min-height: 48px; // align all card header
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 ${sidePadding} 0.5rem;
  border-bottom: 1px solid #eeeeee;
  margin-left: -${sidePadding};
  margin-right: -${sidePadding};
  margin-bottom: 0.5rem;
`;

export const CardFullWidth = styled.div`
  margin-left: -${sidePadding};
  margin-right: -${sidePadding};
`;

export const CardHr = styled.hr`
  border: none;
  border-top: 1px solid #f1f1f1;
  margin-left: -${sidePadding};
  margin-right: -${sidePadding};
`;
