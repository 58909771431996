// @ts-nocheck
import {FormattedMessage, t} from 'i18n-utils';
import React, {useEffect, useMemo, useState} from 'react';
import {convertUnit, formatLocaleNumber, formatUnit} from '_utils';
import {toFixedFloatUnsafe} from '_utils/number-formatters';
import {getGDDTillTSDate} from '../../../utils';
import {setZoningUnits, updateZoningZone} from 'containers/map/actions/zoning-actions';
import {
  mergeNRxZones,
  revertMergeNRxZones,
  toggleNRxTabRate,
  updateNRxRecommendationProps,
  toggleNRxSettingsVisibility,
  toggleNRxResultsVisibility,
} from 'containers/map/actions/nitrogen-recommendation-actions';
import {FluroTabs, InfoBlock, Sticky, FluroButton, Text, Flex} from 'components';
import type {Zone} from '../../../types';
import {ZoneType, ZoningTab} from '../../../types';
import cn from 'classnames';
import type {NrxTabRate, NRxZone} from 'containers/map/features/nrx';
import {convertProductToTon, measurementUnits} from 'containers/map/features/nrx';
import EconomicObjectiveTabs from '../nitrogen-recommendation/economic-objective-tabs';
import {useAppDispatch, useAppSelector} from '_hooks';
import {
  selectIsNRxEnabled,
  selectNRecommendation,
  selectNrxFertilizerListItemData,
} from 'containers/map/features/nrx/nrx-selectors';
import {
  selectPointsCurrentGroupDate,
  selectTemperatureData,
  selectZoning,
  selectZoningTab,
} from '../../../reducer/selectors';
import ExportZoning from '../export-zoning';
import {selectMeasurement, selectUserSettings} from 'containers/login/login-selectors';

import {ZoneElement} from '../zoning-zones';
import {ContainersSeparator} from 'components/reusable-conponents.styled';

export const NRxZones = () => {
  const dispatch = useAppDispatch();
  const zoning = useAppSelector(selectZoning);
  const zoningTab = useAppSelector(selectZoningTab);
  const measurement = useAppSelector(selectMeasurement);
  const temperatureData = useAppSelector(selectTemperatureData);
  const pointsCurrentGroupDate = useAppSelector(selectPointsCurrentGroupDate);
  const nRecommendation = useAppSelector(selectNRecommendation);
  const isNrx = useAppSelector(selectIsNRxEnabled);
  const [editZonesNameEnabled, toggleEditZonesName] = useState(false);
  const locale = useAppSelector(selectUserSettings)?.langLocale;
  const productSpecificGravity =
    useAppSelector(s =>
      selectNrxFertilizerListItemData(s, nRecommendation.nrxRecommendationSettings?.product)
    )?.specific_gravity || 1;

  const {
    method,
    nrxResult,
    selectedObjective,
    nrxRecommendationSettings: {product, isLiquid},
    nrxTabRate,
  } = nRecommendation;

  const NRecommendationToggled = zoningTab === ZoningTab.NitrogenRx;

  const gddTillGroupDate = useMemo(() => {
    // @ts-expect-error error leftover from convertion to strict mode, please fix
    const gdd = dispatch(getGDDTillTSDate()) as number;
    return convertUnit(measurement, 'gdd', gdd).toFixed(0);
  }, [pointsCurrentGroupDate, temperatureData]);

  useEffect(() => {
    if (!zoning.currentUnits) {
      dispatch(setZoningUnits(measurementUnits()[measurement][0]));
    }
  }, []);

  const displayNRxSettings = () => {
    dispatch(toggleNRxSettingsVisibility(true));
    dispatch(toggleNRxResultsVisibility(false));
  };

  const setZoneParam = (prop: string, id: number, value: any, _isTree = false) => {
    if (prop === 'value' && !isFinite(value)) return;

    if (NRecommendationToggled && method === 'apsim') {
      // apsim
      return dispatch(updateNRxRecommendationProps({[prop]: value, id}));
    }

    dispatch(updateZoningZone(id, prop, value));
  };

  const preparedZones: Zone[] = isNrx
    ? nrxResult[nrxTabRate][selectedObjective].features
      ? nrxResult[nrxTabRate][selectedObjective].features
          .filter((f: NRxZone) => f.properties.merged !== 'initial') // filter initial zones that was merged
          .map((f: NRxZone) => ({...f.properties}))
      : []
    : [...zoning.zones].reverse();

  const selectedObjectiveData = isNrx && nrxResult[nrxTabRate][selectedObjective];
  const mergedZones = selectedObjectiveData.features?.filter(
    (f: NRxZone) => f.properties.merged === 'merged'
  )?.length;

  const zones: Zone[] = preparedZones.filter(({area, type}) => type === ZoneType.Tree || area > 0);
  const zonesLength = zones.length;

  const diffZone = preparedZones.length - zones.length;
  const productMeasure = formatUnit(measurement, isLiquid ? 'l/ha' : 'kg/ha');
  const nAmountMeasure = formatUnit(measurement, 'kg/ha');
  const yieldGoalUnits = zones.find(z => z.yield_goal_units)?.yield_goal_units;
  const nrxProduct = isNrx && product;
  const nRxTabs = [
    {label: t({id: 'Variable rate'}), value: 'variable'},
    {label: t({id: 'Flat rate'}), value: 'blanket'},
  ];

  const productMeasureString = productMeasure?.includes('/')
    ? productMeasure.split('/')[0]
    : productMeasure;
  const amountProduct = toFixedFloatUnsafe(
    zones.reduce((res, z) => {
      const area = toFixedFloatUnsafe(z.area, 1);
      const rxValue = Number(z.value || 0);
      return res + (z.area !== undefined ? area * rxValue : rxValue);
    }, 0),
    0
  );
  const productAmountInTon =
    product &&
    isNrx &&
    convertProductToTon(productSpecificGravity, amountProduct, productMeasureString);

  const productString = isNrx && product ? `${product.toLowerCase()}` : t({id: 'amount'});

  if (isNrx && !nRecommendation.displayResults) return null;

  return (
    <div className="zones-container element-full-width">
      <ContainersSeparator />
      <Flex
        onClick={() => {
          // allow open the recommendation only from Recommendation settings component
          dispatch(toggleNRxResultsVisibility(false));
          dispatch(toggleNRxSettingsVisibility(true));
        }}
        className={'cursor-pointer'}
        alignItems={'center'}
        justifyContent={'space-between'}
      >
        <Text className={'margin-bottom-0'} elementType="h2">
          {t({id: 'Recommendation'})}
        </Text>

        <FluroButton icon blank>
          keyboard_arrow_down
        </FluroButton>
      </Flex>
      {((isNrx && zonesLength) || !isNrx) && (
        <>
          <FluroTabs
            containerClassName={'nrx-rate-tabs'}
            tabs={nRxTabs}
            selectedTab={nrxTabRate}
            onTabClick={(value: NrxTabRate) => dispatch(toggleNRxTabRate(value))}
          />
          <EconomicObjectiveTabs />

          {!!selectedObjectiveData.yield_potential_max && (
            <div className={'yield-potential'}>
              <div className={'fluro-subtitle'}>{t({id: 'Yield potential'})}</div>
              <div className={'units'}>
                {selectedObjectiveData.yield_potential_min} -{' '}
                {selectedObjectiveData.yield_potential_max} {yieldGoalUnits}
              </div>
            </div>
          )}

          <h3 className={'zones-title'}>{t({id: 'Management zones'})}</h3>

          {pointsCurrentGroupDate && NRecommendationToggled && !isNrx && (
            <div className={'gdd-container'}>
              {t({id: 'GDD'})}: {gddTillGroupDate}
            </div>
          )}

          <div className="zoning-statistics">
            {zonesLength !== 0 ? (
              <>
                <div className="zone-list-header">
                  <div className={cn('zone-list-header__name', {merged: !!mergedZones})}>
                    {t({id: 'Zone name'})}
                    <FluroButton
                      id={'toggle-edit-zones-name'}
                      className={`toggle-edit-zones-name-btn ${
                        editZonesNameEnabled ? 'active' : ''
                      }`}
                      icon
                      onClick={() => toggleEditZonesName(!editZonesNameEnabled)}
                    >
                      edit
                    </FluroButton>
                  </div>
                  <div className={'zone-list-header__area'}>
                    {t({id: 'Area'})} <br />
                  </div>

                  {NRecommendationToggled && nrxProduct && (
                    <div className={'zone-list-header__n-amount'}>
                      {t({id: 'N units'})} <div className={'units'}>{nAmountMeasure}</div>
                    </div>
                  )}
                  <div className={cn('zone-list-header__rx')}>
                    {t({id: 'Rx'})}

                    {nrxProduct && <div className={'units'}>{productMeasure}</div>}
                  </div>
                </div>
                {zones.map((zone, index) => (
                  <ZoneElement
                    key={zone.id}
                    index={index + 1}
                    zone={zone}
                    nRecommendationToggled={NRecommendationToggled}
                    editable={editZonesNameEnabled}
                    setZoneParam={setZoneParam}
                    mergedZones={!!mergedZones}
                    measurement={measurement}
                  />
                ))}
                {diffZone ? (
                  <span className="zoning-diff">
                    {t(
                      {id: 'The selected zoning method allows a maximum of zones'},
                      {count: zones.length}
                    )}
                  </span>
                ) : null}

                {amountProduct ? (
                  <>
                    <div className={'zones-entities-amount'}>
                      <div className={'entity-name'}>
                        {t({id: 'Total {productString} required'}, {productString})}:
                      </div>
                      <div className={'entity-value'}>
                        {amountProduct < 1 ? '<1' : formatLocaleNumber(amountProduct, locale)}{' '}
                        {productMeasureString}
                        <div className={'tons-value'}>{productAmountInTon} T</div>
                      </div>
                    </div>
                  </>
                ) : null}
              </>
            ) : null}
          </div>

          {isNrx && zonesLength === 1 && (zones[0]?.value === 0 || zones[0]?.value === '-') && (
            <InfoBlock appearance={'warning'} className={'tab-info-block nrx-wrong-data-info'}>
              {zones[0]?.resultROISettings === 3 ? (
                <>
                  <FormattedMessage
                    id="We could not find a variable rate application with"
                    defaultMessage="We could not find a variable rate application with <span>these settings.</span> The flat rate application is shown instead."
                    values={{
                      span: (txt: string) => (
                        <span className={'global-link'} onClick={displayNRxSettings}>
                          {txt}
                        </span>
                      ),
                    }}
                  />
                </>
              ) : (
                <>
                  <FormattedMessage
                    id="Make sure <span>recommendation settings</span> are representative of your crop conditions."
                    defaultMessage="Make sure <span>recommendation settings</span> are representative of your crop conditions."
                    values={{
                      span: (txt: string) => (
                        <span className={'global-link'} onClick={displayNRxSettings}>
                          {txt}
                        </span>
                      ),
                    }}
                  />
                </>
              )}
            </InfoBlock>
          )}

          <Sticky className={cn({'zoning-sticky-btns-container': true, nrx: isNrx})}>
            {isNrx && nRecommendation.nrxTabRate !== 'blanket' && (
              <>
                <FluroButton
                  onClick={() => dispatch(mergeNRxZones())}
                  className={'zoning-sticky-btn'}
                  raised
                  blank
                  grayBorder
                  disabled={zones.filter(z => z.selected).length <= 1}
                >
                  {t({id: 'Merge zones'})}
                </FluroButton>
                {mergedZones ? (
                  <FluroButton
                    onClick={() => dispatch(revertMergeNRxZones())}
                    className={'zoning-sticky-btn'}
                    raised
                    grayBorder
                    blank
                  >
                    {t({id: 'Unmerge zones'})}
                  </FluroButton>
                ) : null}
              </>
            )}

            <ExportZoning />
          </Sticky>
        </>
      )}
    </div>
  );
};
