import type {FluroStepsItem} from 'components/fluro-steps/fluro-steps-dynamic';
import {FluroStepsMemo} from 'components/fluro-steps/fluro-steps-dynamic';
import {ENROLLMENT_PROJECT_FORM, MONITORING_PROJECT_FORM} from 'containers/mrv/routes';
import {applyPathParams} from '_utils/pure-utils';
import {getStageName} from 'containers/mrv/base';
import React, {useCallback, useMemo} from 'react';
import {useHistory, useLocation} from 'react-router-dom';
import {useAppSelector} from '_hooks';
import {applyUrlParams} from '_hooks/use-url-params';
import {useMonitoringUrlParams} from '../hooks';
import {selectCompletion, selectEnabledPhaseStages} from '../module/selectors';
import {Flex} from 'components';
import {MRVStageIconComponent} from 'components/mrv/mrv-stage-icon';
import {MRVPhaseType} from 'containers/mrv/types';
import {useIsStageDisabled} from '../hooks/use-is-stage-disabled';

export const MRVTimeline = ({phaseType}: {phaseType: MRVPhaseType}) => {
  const {projectId, stageId: selectedStageId} = useMonitoringUrlParams();
  const history = useHistory();
  const {search} = useLocation();
  const stages = useAppSelector(s => selectEnabledPhaseStages(s, phaseType));
  const completion = useAppSelector(selectCompletion);

  const isStageDisabled = useIsStageDisabled(phaseType);

  const handleOnStepClick = useCallback(
    (stageId: number) => {
      const stage = stages?.find(s => s.id === stageId);
      if (!projectId || !stageId || (stage && isStageDisabled(stage))) {
        return;
      }
      const path =
        phaseType === MRVPhaseType.Monitoring ? MONITORING_PROJECT_FORM : ENROLLMENT_PROJECT_FORM;
      history.push({
        pathname: applyPathParams(path, {projectId}),
        search: applyUrlParams(search, {stageId}),
      });
    },
    [history, isStageDisabled, projectId, search, stages]
  );

  const steps: FluroStepsItem[] = useMemo(() => {
    return stages?.map(stage => {
      const {icon, id, type_} = stage;

      return {
        label: getStageName(stage),
        value: id,
        icon: <MRVStageIconComponent iconUrl={icon} stageType={type_} />,
        progressValue: completion[id]?.is_completed
          ? 100
          : completion[id]?.percentage_complete || 0,
        disabled: isStageDisabled(stage),
      };
    });
  }, [stages, completion, isStageDisabled]);

  return (
    <Flex justifyContent="center" alignItems="center">
      <FluroStepsMemo
        onItemClick={handleOnStepClick}
        selectedItem={selectedStageId}
        items={steps}
      />
    </Flex>
  );
};

export const MRVTimelineMemo = React.memo(MRVTimeline);
