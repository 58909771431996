// @ts-nocheck
import {t} from 'i18n-utils';
import type {ComponentType} from 'react';
import React, {useEffect} from 'react';
import {useSelector} from 'react-redux';
import './index.scss';
import ToogleFieldInfo from './toggle-field-info-btn';
import ToggleWeather from './toggle-field-weather';
import ToggleTreeDetection from './toggle-tree-detection';
import ToggleAnomalies from './toggle-anomalies-btn';
import L from 'leaflet';
import DrawingTools from './drawing-tools';
import ToggleLayerSource from './toggle-layers-source';
import type {AppStore} from 'reducers';
import {addingFieldsFromMapSteps} from 'modules/add-fields/base';
import cn from 'classnames';
import {LocationTracking} from '../location-tracking/location-tracking';
import {useMap, ZoomControl} from 'react-leaflet';
import SearchLocation from './search-location';
import LocationMarker from '../overlays/location-marker';
import Control from 'containers/map/lib/control';
import {useAppSelector} from '_hooks';
import {
  selectCurrentFieldId,
  selectCurrentTab,
  selectDrawControl,
  selectIsMapBarOpen,
  selectIsReadOnly,
  selectIsWholeFarmView,
} from '../reducer/selectors';
import GoHomeBtn from './go-home-btn';
import {selectAddingFieldsStep} from 'modules/add-fields/selectors';
import {selectMeasurement} from 'containers/login/login-selectors';
import _config from '_environment';
import {useWorkspace} from '_hooks/use-workspace';

type Props = {
  leafletElement: L.Map;
  fitBounds: () => void;
  hasFarms?: boolean;
};

const MapButtons: ComponentType<Props> = ({leafletElement, fitBounds, hasFarms = false}) => {
  const isMapBarOpen = useAppSelector(selectIsMapBarOpen);
  const selectedFieldId = useAppSelector(selectCurrentFieldId);
  const isWholeFarmView = useAppSelector(selectIsWholeFarmView);
  const feature = useAppSelector(selectCurrentTab);
  const isReadOnly = useAppSelector(selectIsReadOnly);
  const addFieldCurrentStep = useAppSelector(selectAddingFieldsStep);
  const {workspace} = useWorkspace();
  const isSIApp = workspace === 'si';
  const {isDrawingMode, drawingModeLayerType, isEditingMode} = useAppSelector(selectDrawControl);

  const drawStatus = isDrawingMode ? drawingModeLayerType : isEditingMode ? 'edit' : '';

  useEffect(() => {
    const scaleElement = document.querySelector('.leaflet-bottom.leaflet-right') as HTMLElement;
    if (scaleElement) {
      scaleElement.style.bottom = _config.env === 'development' ? '118px' : '75px';
      scaleElement.style.left = '62px';
    }
  }, [isMapBarOpen, hasFarms]);

  const fieldDependingAllowed = !isWholeFarmView && !!selectedFieldId;
  const featureDependingAllowed = feature !== 'crop-performance';
  const addingFieldFlowDependingAllowed = !addingFieldsFromMapSteps.includes(addFieldCurrentStep);
  const shouldAddPadding = ['zoom-is-too-low', 'select-boundaries', 'draw-fields'].includes(
    addFieldCurrentStep
  );

  return (
    <>
      {/* Top left controls */}
      <SearchLocation />
      {workspace !== 'mrv' && <GoHomeBtn fitBounds={fitBounds} />}
      <LocationMarker />
      <LocationTracking />
      <MeasureAndScaleControls />
      <ZoomControl position="topleft" />

      {/* Bottom left controls */}
      {feature !== 'sustainability-insights' && (
        <Control position="bottomleft">
          <div
            className={cn(`toggle-elements-map`, {
              'adding-fields': shouldAddPadding,
            })}
            style={{bottom: hasFarms || isSIApp ? 65 : 0}}
          >
            {addingFieldFlowDependingAllowed &&
              fieldDependingAllowed &&
              featureDependingAllowed &&
              hasFarms && <ToogleFieldInfo />}

            <DrawingTools
              drawStatus={drawStatus}
              leafletElement={leafletElement}
              isWholeFarmView={isWholeFarmView}
              isReadOnly={isReadOnly}
              selectedFieldId={selectedFieldId}
            />

            {addingFieldFlowDependingAllowed &&
              fieldDependingAllowed &&
              featureDependingAllowed && <ToggleWeather />}
            {addingFieldFlowDependingAllowed && fieldDependingAllowed && <ToggleAnomalies />}
            <ToggleLayerSource />
            {addingFieldFlowDependingAllowed && !!selectedFieldId && featureDependingAllowed && (
              <ToggleTreeDetection />
            )}
          </div>
        </Control>
      )}
    </>
  );
};

/**
 * A separate component for Measure and Scale just to make sure the init is called
 * after the location components are initilized, so the order of appearance is as intended.
 */
const MeasureAndScaleControls = (): React.ReactElement => {
  const measurement = useAppSelector(selectMeasurement);
  const isMapBarOpen = useSelector((store: AppStore) => store.map.isMapBarOpen);

  const leafletElement = useMap();

  useEffect(() => {
    const measureCleanup = initMeasureControl({measurement, leafletElement});
    const scaleCleanup = initScaleControl({measurement, leafletElement, isMapBarOpen});
    return () => {
      measureCleanup();
      scaleCleanup();
    };
  }, []);
  return null;
};

const initMeasureControl = ({
  measurement,
  leafletElement,
}: {
  measurement: string;
  leafletElement: L.Map;
}) => {
  const isUS = measurement === 'ac';
  const halfLimit = isUS ? 800 : 500;

  // Measure is a leaflet plugin which doesn't provide type extension for L.Control.
  // @ts-expect-error error leftover from convertion to strict mode, please fix
  let measureControl = new L.Control.Measure({
    position: 'topleft',
    primaryAreaUnit: isUS ? 'acres' : 'hectares',
    secondaryAreaUnit: isUS ? 'sqfeet' : 'sqmeters',
    primaryLengthUnit: isUS ? 'feet' : 'meters',
    // primaryLengthUnit: isUS ? 'miles' : 'kilometers',
    secondaryLengthUnit: '',
    activeColor: '#4e78b5',
    completedColor: '#3387fe',
  });

  measureControl.addTo(leafletElement);

  const onMeasure = ({length}: {length: number}) => {
    if (length === 0) return false;

    if (length > halfLimit) {
      measureControl.update({
        primaryLengthUnit: isUS ? 'miles' : 'kilometers',
      });
    } else {
      measureControl.update({
        primaryLengthUnit: isUS ? 'feet' : 'meters',
      });
    }
  };
  // Leaflet Measure plugin extends the event with length property.
  // @ts-expect-error error leftover from convertion to strict mode, please fix
  leafletElement.on('measureclick', onMeasure);

  const measureControlToggle = document.querySelector(
    '.leaflet-control-measure-toggle'
  ) as HTMLElement;
  const onMeasureToggle = () => {
    (document.querySelector('.js-start.start') as HTMLElement).click();
  };
  measureControlToggle.addEventListener('click', onMeasureToggle);
  measureControlToggle.title = t({id: 'Measure', defaultMessage: 'Measure'});
  const i = document.createElement('i');
  i.className = 'fas fa-ruler-vertical';
  measureControlToggle.appendChild(i);

  const cleanup = () => {
    i.remove();
    // @ts-expect-error error leftover from convertion to strict mode, please fix
    leafletElement.off('measureclick', onMeasure);
    measureControlToggle.removeEventListener('click', onMeasureToggle);
    measureControl.remove();
  };
  return cleanup;
};

const initScaleControl = ({
  measurement,
  leafletElement,
  isMapBarOpen,
}: {
  measurement: string;
  leafletElement: L.Map;
  isMapBarOpen: boolean;
}) => {
  const control = L.control
    .scale({
      position: 'bottomright',
      metric: measurement === 'ha',
      imperial: measurement === 'ac',
    })
    .addTo(leafletElement);
  const scaleElement = document.querySelector('.leaflet-control-scale') as HTMLElement;
  if (scaleElement) {
    scaleElement.style.right = isMapBarOpen ? '400px' : '50px';
    scaleElement.style.top = isMapBarOpen ? '21px' : '0';
    scaleElement.style.transition = 'right 0.5s, top 0.5s';
  }
  const cleanup = () => control.remove();
  return cleanup;
};

export default MapButtons;
