import {MRV, CARBON} from '_environment';

export const ENROLLMENT = `/${CARBON}/enrollment` as const;

export const PROJECT_ID_PATTERN = ':projectId' as const;

export const SIGN_UP_CONFIRMATION = `/${MRV}/sign-up-confirmation/:uuid` as const;

export const MRV_FARMLOGS_INVITE_REDEEM = `/${MRV}/farmlogs/invite/:uuid` as const;

export const SELF_REGISTER = `/${MRV}/register` as const;

export const MRV_PROJECT_SELECT = `/${MRV}/project/select` as const;

export const MRV_PROJECT = `/${MRV}/project/${PROJECT_ID_PATTERN}` as const;

export const MRV_HOME = `${MRV_PROJECT}/home` as const;

export const MRV_ADMIN = `/${MRV}/admin` as const;

export const MRV_GLOBAL_ADMIN = `/${MRV}/global-admin` as const;

export const MRV_PROFILE = `/${MRV}/profile` as const;

export const MRV_DOCUSIGN_CONSENT_PAGE = `${MRV_ADMIN}/docusign-consent` as const;

export const MRV_ADMIN_PROGRAM = `${MRV_ADMIN}/programs/:programId` as const;
export const MRV_ADMIN_PROGRAM_CONFIGURE = `${MRV_ADMIN_PROGRAM}/configure/:tab?/:subTab?` as const;
export const MRV_ADMIN_PROGRAM_DASHBOARD = `${MRV_ADMIN_PROGRAM}/dashboard/:tab?/:subTab?` as const;

export const MRV_ENROLLMENT = `${MRV_PROJECT}/enrollment` as const;
export const ENROLLMENT_PROJECT_FORM = `${MRV_PROJECT}/enrollment/form` as const;

export const MRV_ENROLLMENT_RE = `${MRV_ENROLLMENT}/*` as const;

export const MONITORING_PROJECT = `${MRV_PROJECT}/monitoring` as const;
export const MONITORING_PROJECT_FORM = `${MRV_PROJECT}/monitoring/form` as const;
