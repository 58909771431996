import React from 'react';
import {t, FormattedMessage} from 'i18n-utils';
import {Ln} from '../components';
import {contactSupportTeam, setGetParamToURL} from '_utils/pure-utils';

export const DontHaveAccess = () => (
  <span>
    <FormattedMessage
      id="doNotHaveAccessToTheFarm"
      values={{
        a: (txt: string) => contactSupportTeam(txt),
      }}
      defaultMessage="You don’t have access to this farm, make sure the URL you entered is correct or contact <a>the support team</a>."
    />
  </span>
);

export const responsesToWarningObjects: Record<
  string,
  () => {
    title?: string | React.ReactElement;
    message: string | React.ReactElement;
  }
> = {
  'User not found': () => ({
    title: t({id: 'note.wrongEmailOrPassword', defaultMessage: 'Wrong email or password.'}),
    message: t({
      id: 'tryAgainLogin',
      defaultMessage: 'Try again or click Forgot password to reset it.',
    }),
  }),

  'Email address not found': () => ({
    title: t({id: 'wrongEmail', defaultMessage: 'Wrong email'}),
    message: t({id: 'emailNotFound', defaultMessage: 'Email address not found'}),
  }),

  'This email address is already registered. You can reset your password using the sign-in page.':
    () => ({
      title: t({id: 'note.warning', defaultMessage: 'Warning'}),
      message: (
        <FormattedMessage
          id="note.alreadyRegistered"
          defaultMessage={'The request couldn’t be completed. Please get in touch with {email}'}
          values={{
            email: <Ln href="mailto:support@regrow.ag">support@regrow.ag</Ln>,
          }}
        />
      ),
    }),
  'Group not found': () => ({
    title: t({id: 'note.error', defaultMessage: 'Error'}),
    message: <DontHaveAccess />,
  }),
  'You do not have access to the farm': () => ({
    title: t({id: 'note.error', defaultMessage: 'Error'}),
    message: <DontHaveAccess />,
  }),
  'Season dates overlap': () => ({
    title: t({id: 'note.warning', defaultMessage: 'Warning'}),
    message: t({id: 'datesOverlap'}),
    type: 'warning',
  }),
  'User already exists.': () => ({
    title: t({id: 'note.warning', defaultMessage: 'Warning'}),
    message: t({id: 'userAlreadyExist'}),
    type: 'warning',
  }),
  'Could not generate second contract.': () => ({
    title: t({id: 'note.warning', defaultMessage: 'Warning'}),
    message: (
      <FormattedMessage
        id="carbon.contractAlreadyGenerated"
        values={{
          email: <Ln href="mailto:support@regrow.ag">support@regrow.ag</Ln>,
        }}
      />
    ),
    type: 'warning',
  }),
  'Fields intersects.': () => ({
    title: t({id: 'note.error', defaultMessage: 'Error'}),
    message:
      'Some of your fields overlap, please make sure they don’t overlap before you confirm your selection.',
    type: 'error',
  }),
  'you do not have access to this program': () => ({
    title: t({id: 'note.error', defaultMessage: 'Error'}),
    message: "You don't have enough permissions to impersonate this user",
    type: 'error',
  }),
  'user is not producer in program': () => ({
    title: t({id: 'note.warning', defaultMessage: 'Warning'}),
    message: "You can't impersonate an admin user.",
    type: 'warning',
  }),
  'a user is super-admin': () => ({
    title: t({id: 'note.warning', defaultMessage: 'Warning'}),
    message: "You can't invite a super-admin to a program.",
    type: 'warning',
  }),
  "Missing contact details: Please ensure your address and contact details are filled in your <a href='/app/profile?tab=profile'>profile<a> before signing your contract.":
    () => ({
      title: t({id: 'note.warning', defaultMessage: 'Warning'}),
      message: (
        <>
          Missing contact details: Please ensure your address and contact details are filled in your{' '}
          <span
            className="global-link"
            onClick={e => {
              e.preventDefault();
              setGetParamToURL('profile-dialog', 'true');
            }}
          >
            profile
          </span>{' '}
          before signing your contract.
        </>
      ),
      type: 'warning',
    }),
};

export const dynamicResponseToLiteralString = (responseMessage: string) => {
  if (typeof responseMessage !== 'string' || !responseMessage) return responseMessage;

  switch (true) {
    case responseMessage?.includes('season dates overlap with another season'):
      return t({id: 'datesOverlap'}); // because in some cases (a wrong start/end date) the back returns {msg: string, bulk_index: number, season_index: number}

    case new RegExp('User [0-9]+ is already an Admin.').test(responseMessage):
      return 'a user is super-admin';

    default:
      return responseMessage;
  }
};

export const responseWarningMessage = (response: string) => {
  return responsesToWarningObjects[dynamicResponseToLiteralString(response)]?.();
};
