import React from 'react';
import {WarningCrossedIcon} from 'containers/map/icons';
import './index.scss';
import {useDispatch} from 'react-redux';
import {toggleTooltip} from 'modules/global/actions';

const PermanentlyExcluded = () => {
  const dispatch = useDispatch();
  const id = `${Math.random()}`;
  return (
    <div
      className={'excluded'}
      data-tip={''}
      data-for={id}
      onMouseEnter={() =>
        dispatch(
          toggleTooltip({
            id: id,
            content:
              'This shape has been labeled as a permanent anomaly and is now excluded from the analysis.',
            place: 'left',
          })
        )
      }
    >
      <WarningCrossedIcon />
    </div>
  );
};

export default PermanentlyExcluded;
