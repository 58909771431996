import {fetchBookValues, fetchKPIData} from './../thunks';
import type {PayloadAction} from '@reduxjs/toolkit';
import {createSlice} from '@reduxjs/toolkit';
import type {SI2State} from '../types';
import type {SIProgramKPI} from 'containers/si/types';
import type {CountryBookValueResponse} from 'containers/si/api/apiTypes';

export const kpisSlice = createSlice({
  name: 'si/kpis',
  initialState: {
    emission_factors: {
      ghg: {cropSummary: []},
      net: {cropSummary: []},
      fertilizer: {cropSummary: []},
    },
    bookValues: [],
  } as SI2State['kpis'],
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase<string, PayloadAction<SIProgramKPI>>(
        fetchKPIData.fulfilled.type,
        (state, action) => {
          const {kpi_name, crop_type_summary} = action.payload;
          switch (kpi_name) {
            case 'ghg_emissions_factor':
              return {
                ...state,
                emission_factors: {
                  ...state.emission_factors,
                  ghg: {
                    ...state.emission_factors.ghg,
                    cropSummary: crop_type_summary ?? [],
                  },
                },
              };
            case 'net_ghg_emissions_factor':
              return {
                ...state,
                emission_factors: {
                  ...state.emission_factors,
                  net: {
                    ...state.emission_factors.net,
                    cropSummary: crop_type_summary ?? [],
                  },
                },
              };
            case 'fert_emissions_factor':
              return {
                ...state,
                emission_factors: {
                  ...state.emission_factors,
                  fertilizer: {
                    ...state.emission_factors.fertilizer,
                    cropSummary: crop_type_summary ?? [],
                  },
                },
              };
          }
          return state;
        }
      )
      .addCase<string, PayloadAction<Array<CountryBookValueResponse>>>(
        fetchBookValues.fulfilled.type,
        (state, action) => {
          const [{crop_book_values}] = action.payload;

          return {...state, bookValues: [...crop_book_values]};
        }
      );
  },
});
