import type {ComponentType} from 'react';
import React from 'react';
import {Badge, Button} from 'react-md';
import config from '_environment';
import {IntercomProvider} from 'react-use-intercom';
import {useAppDispatch, useAppSelector} from '_hooks';
import {selectUser} from 'containers/login/login-selectors';

import {toggleMessagesVisibility, setUnreadMessagesCount} from './reducer';
import {
  selectIntercomUnreadMessages,
  selectIntercomVisibility,
} from '../../modules/helpers/selectors';
import {isLoginAllowedAnalytics} from '_utils/analytics';

const IntercomComponent: ComponentType = ({children}) => {
  const dispatch = useAppDispatch();
  const {email, id, name, createdAt, surname} = useAppSelector(selectUser);
  const userFullName = `${name} ${surname}`;

  if (!config.intercomSettings || email.endsWith('@flurosat.qa')) {
    return null;
  }
  return (
    <IntercomProvider
      appId={config.intercomSettings.app_id}
      onHide={() => dispatch(toggleMessagesVisibility(false))}
      onShow={() => dispatch(toggleMessagesVisibility(true))}
      onUnreadCountChange={(unreadMsg: number) => dispatch(setUnreadMessagesCount(unreadMsg))}
      autoBoot
      shouldInitialize={isLoginAllowedAnalytics(email)}
      autoBootProps={{
        horizontalPadding: 60,
        verticalPadding: 20,
        hideDefaultLauncher: true,
        alignment: config.intercomSettings.alignment,
        customLauncherSelector: '#intercom-launch',
        email: email,
        userId: String(id),
        name: userFullName,
        createdAt: createdAt,
      }}
    >
      {children}
    </IntercomProvider>
  );
};

export const IntercomLaunchBtn: ComponentType = () => {
  const visible = useAppSelector(selectIntercomVisibility);
  const unreadCount = useAppSelector(selectIntercomUnreadMessages);

  return (
    <Badge
      badgeContent={unreadCount}
      invisibleOnZero={true}
      secondary
      badgeId="intercom-badge"
      className="fluro-intercom-launch"
    >
      <Button
        id="intercom-launch"
        primary
        swapTheming
        icon
        tooltipLabel={visible ? 'Close support window' : 'Contact support Team'}
        tooltipPosition="left"
      >
        {visible ? 'close' : 'message'}
      </Button>
    </Badge>
  );
};

export default IntercomComponent;
