import React from 'react';
import {Text} from 'components';

export const EstimatedProgramOutcomes = () => {
  return (
    <div>
      <Text variant="h2" bold>
        Estimated program outcomes
      </Text>
      <Text>
        Estimated county level additionality requirements for crop and intervention combinations and
        analyse the impact on scope 3 emissions{' '}
      </Text>
      <div style={{border: '1px solid', height: '92px'}}> todo </div>
    </div>
  );
};
