// @ts-nocheck
import {t, FormattedMessage} from 'i18n-utils';
import {Button, SelectField} from 'react-md';
import React, {useState, useEffect} from 'react';
import type {IInitialMapState} from '../../types';
import {drawDateIcon} from '../../utils';
import {CloudyText} from 'components/clouds/clouds.styled';
import type {ConnectedProps} from 'react-redux';
import {connect} from 'react-redux';
import {setDate} from '../../actions';
import {generateNitrogenMap} from '../../actions/sampling-points';
import moment from 'moment';
import {formatDate, getDateType} from '_utils';
import {sortDates} from '_utils/pure-utils';
import {GLOBAL_FORMAT_DATE} from '_constants';
import {InfoBlock, FluroDialog} from 'components';

type Props = ConnectedProps<typeof connector> & {
  pointsGroupDateToGenerate: string;
  visible: boolean;
  onHide: () => void;
};

const NmapPopUp = ({
  onHide,
  visible,
  pointsGroupDateToGenerate,
  remoteSensingCloudCover,
  currentFarmId,
  selectedFieldId,
  generateNitrogenMap,
  currentDates,
  currentField,
  currentSeason,
  setDate,
}: Props) => {
  const [generateDate, changeGenerateDate] = useState('');
  const [closestDates, setClosestDates] = useState([]);
  const [generatedNMapDates, setGeneratedNMapDates] = useState(currentField.generatedNMapDates);

  const getClosestDates = (date: string) => {
    const momentDate = moment(date, formatDate());
    const currentDatesKeys = Object.keys(currentDates);
    const resultDates = [];

    const sortedListOfDates = sortDates(
      currentDatesKeys.filter(
        date => currentDates[date].Cloud <= remoteSensingCloudCover && currentDates[date].CCCI
      ),
      'DD/MM/YYYY'
    );
    let closestDate = moment(currentSeason.endDate, GLOBAL_FORMAT_DATE).isBefore(momentDate)
      ? sortedListOfDates[sortedListOfDates.length - 1]
      : sortedListOfDates[0];

    sortedListOfDates.forEach(loopDate => {
      // calc nearest date by time diff between prev and current closestDate
      const loopMoment = moment(loopDate, 'DD/MM/YYYY');
      if (
        Math.abs(loopMoment.diff(momentDate)) <
        Math.abs(moment(closestDate, 'DD/MM/YYYY').diff(momentDate))
      )
        closestDate = loopDate;
    });

    resultDates.push(closestDate);

    const closestDateIndex = sortedListOfDates.findIndex(date => date === closestDate);

    const previousDate = closestDateIndex !== 0 ? sortedListOfDates[closestDateIndex - 1] : null;
    const nextDate =
      sortedListOfDates.length > closestDateIndex + 1
        ? sortedListOfDates[closestDateIndex + 1]
        : null;

    previousDate && resultDates.unshift(previousDate);
    nextDate && resultDates.push(nextDate);
    setClosestDates(
      resultDates.map(date => {
        return {
          value: date,
          label: (
            <div className={'d-flex'}>
              {drawDateIcon({
                type: getDateType(date),
                date,
                nmap: !!currentDates[date]?.NMAP,
                children: ' [CCCI]',
                cloudy: currentDates[date]?.Cloud,
              })}
              {currentDates[date]?.Cloud > 1 ? (
                <CloudyText>({currentDates[date]?.Cloud})%</CloudyText>
              ) : (
                ''
              )}
            </div>
          ),
        };
      })
    );
  };

  useEffect(() => {
    getClosestDates(pointsGroupDateToGenerate);
  }, [pointsGroupDateToGenerate]);

  const generateMap = () => {
    if (selectedFieldId && currentFarmId) {
      const formattedDate = moment(pointsGroupDateToGenerate, formatDate()).format('DD/MM/YYYY');
      const result = {
        ...generatedNMapDates,
        [formattedDate]: generateDate,
      };

      generateNitrogenMap(currentFarmId, selectedFieldId, result);
      setGeneratedNMapDates(result);
      setDate(generateDate);
      onHide();
    }
  };

  return (
    <FluroDialog
      id={'generate-nmap-pop-up'}
      title={t({id: 'Generate Nitrogen Map'})}
      className={'generate-nmap-pop-up'}
      onHide={onHide}
      visible={visible}
      isDraggable
      width={500}
      portal
    >
      <div className={'generate-nmap-pop-up__content'}>
        <SelectField
          id={`select-closest-date`}
          className="select-closest-date"
          label={t({id: 'Select to generate NMAP'})}
          placeholder={t({id: 'Select date'})}
          menuItems={closestDates}
          value={generateDate || ''}
          onChange={(val: string) => {
            changeGenerateDate(val);
          }}
          simplifiedMenu={true}
        />

        <Button
          id={'generate-nmap-button'}
          disabled={!generateDate}
          raised
          primary
          onClick={generateMap}
          className="generate-nmap-btn"
        >
          {t({id: 'Generate Nmap'})}
        </Button>
      </div>

      <InfoBlock className="inside-a-pop-up">
        {!remoteSensingCloudCover ? (
          <FormattedMessage
            id="The map will be generated using the samples taken without clouds"
            defaultMessage="The map will be generated using the samples taken {pointsGroupDateToGenerate}, using a Crop Canopy Chlorophyll Index (CCCI) to extrapolate your observations spatially across the whole field.<br></br><br></br>Please select the observation date to generate the map. In order to get accurate results, only the dates close to the sample collection date and without clouds are available for selection."
            values={{
              pointsGroupDateToGenerate,
              br: () => <br />,
            }}
          />
        ) : (
          <FormattedMessage
            id="The map will be generated using the samples taken with clouds"
            defaultMessage="The map will be generated using the samples taken {pointsGroupDateToGenerate}, using a Crop Canopy Chlorophyll Index (CCCI) to extrapolate your observations spatially across the whole field.<br></br><br></br>Please select the observation date to generate the map. In order to get accurate results, only the dates close to the sample collection date and with a cloud cover < {remoteSensingCloudCover}% are available for selection."
            values={{
              pointsGroupDateToGenerate,
              remoteSensingCloudCover,
              br: () => <br />,
            }}
          />
        )}
      </InfoBlock>
    </FluroDialog>
  );
};

const mapStateToProps = ({map}: {map: IInitialMapState}) => ({
  remoteSensingCloudCover: map.remoteSensingCloudCover,
  currentSeasonId: map.currentSeasonId,
  currentSeason: map.currentSeason,
  currentDates: map.currentDates,
  selectedFieldId: map.selectedFieldId,
  currentField: map.field,
  currentFarmId: map.group.id,
});
const connector = connect(mapStateToProps, {
  generateNitrogenMap,
  setDate,
});
export default connector(NmapPopUp);
