import type {ComponentType} from 'react';
import React, {createContext, useEffect, useState} from 'react';
import {useMonitoringUrlParams} from '../hooks';

type Props = {
  initialValue?: SelectedFieldRow[];
};

export type SelectedFieldRow = {
  entityId: number;
  rowId: number;
};

export const TableRowsSelectionContext = createContext<
  [SelectedFieldRow[], React.Dispatch<React.SetStateAction<SelectedFieldRow[]>>]
>([[], () => {}]);
export const {Consumer} = TableRowsSelectionContext;

export const TableRowsSelectionProvider: ComponentType<Props> = ({children, initialValue = []}) => {
  const {projectId, stageId, entityId} = useMonitoringUrlParams();
  const [selectedRows, setSelectedRows] = useState<SelectedFieldRow[]>(initialValue);

  useEffect(() => {
    setSelectedRows([]);
  }, [projectId, stageId, entityId]);

  return (
    <TableRowsSelectionContext.Provider value={[selectedRows, setSelectedRows]}>
      {children}
    </TableRowsSelectionContext.Provider>
  );
};
