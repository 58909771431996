import {t} from 'i18n-utils';
import React, {useEffect, useMemo} from 'react';
import {FluroDialog} from 'components';
import NewFields from '../map/features/farm/new-fields/new-fields';
import {toggleDrawingMode, toggleMapBar} from '../map/actions';
import {
  setAddFieldCurrentStep,
  clearCLUFieldBoundaries,
  removeDrawnFieldsGeometry,
  selectDrawnFieldGeometries,
  setUploadFields,
} from 'modules/add-fields';
import type {AddFieldStep} from 'modules/add-fields/types';
import {addingFieldsFromMapSteps} from 'modules/add-fields/base';
import {
  selectAddingFieldsStep,
  selectBoundaryIdsToUpload,
  selectGeoJsonFiles,
  selectIsPreviewingAddingFields,
} from 'modules/add-fields/selectors';
import cn from 'classnames';
import {FontIcon} from 'react-md';
import {useAppDispatch, useAppSelector} from '../../_hooks';
import {selectDialogVisibility} from '../../modules/helpers/selectors';
import {dialogToggle, DialogType} from '../../modules/helpers';
import {selectDrawControl} from '../map/reducer/selectors';

const canAddMoreFieldsSteps: AddFieldStep[] = [
  'draw-fields',
  'search-location',
  'zoom-is-too-low',
  'select-boundaries',
];

export const AddFieldDialog = () => {
  const dispatch = useAppDispatch();
  const visible = useAppSelector(s => selectDialogVisibility(s, DialogType.addNewField));
  const {isDrawingMode, drawingModeLayerType} = useAppSelector(selectDrawControl);
  const drawnFieldsGeometries = useAppSelector(selectDrawnFieldGeometries);
  const geoJsonFiles = useAppSelector(selectGeoJsonFiles);
  const currentStep = useAppSelector(selectAddingFieldsStep);
  const boundaryIdsToUpload = useAppSelector(selectBoundaryIdsToUpload);
  const isPreviewingFields = useAppSelector(selectIsPreviewingAddingFields);

  useEffect(() => {
    // clear on unmount (useful during logout)
    return () => closeAddingWorkflow();
  }, []);

  useEffect(() => {
    if (visible && currentStep === 'add-fields' && drawnFieldsGeometries.length) {
      dispatch(setAddFieldCurrentStep('draw-fields'));
    }

    if (visible && isDrawingMode) {
      // disable drawing mode when the pop-up opens
      dispatch(toggleDrawingMode(false, drawingModeLayerType));
    }
  }, [visible]);

  const checkForAddingMoreFieldsCase = () => {
    // check for case when should not exit the adding fields flow, but just back to the fields list view
    return (
      canAddMoreFieldsSteps.includes(currentStep) &&
      (drawnFieldsGeometries.length || boundaryIdsToUpload.length)
    );
  };

  const onHide = () => {
    if (checkForAddingMoreFieldsCase()) {
      const viewFieldsStep = drawnFieldsGeometries.length // apply elimination method
        ? 'view-drawn-fields'
        : 'view-selected-boundaries';
      dispatch(setAddFieldCurrentStep(viewFieldsStep)); // back to correct adding to a farm step
    } else {
      closeAddingWorkflow();
    }
  };

  const closeAddingWorkflow = () => {
    dispatch(dialogToggle(DialogType.addNewField, false));
    dispatch(setUploadFields([]));
    dispatch(removeDrawnFieldsGeometry());
    dispatch(setAddFieldCurrentStep('add-fields'));
    dispatch(toggleMapBar(true));
    dispatch(toggleDrawingMode(false, 'polygon', 'farm'));
    dispatch(clearCLUFieldBoundaries());
  };

  const cancelDrawing = () => {
    dispatch(toggleDrawingMode(false, drawingModeLayerType, 'farm'));
  };

  const hasFieldsToUpload = geoJsonFiles.length || drawnFieldsGeometries.length;

  const dialogTitle = useMemo(() => {
    switch (currentStep) {
      case 'add-fields':
        return t({id: 'Add fields'});

      case 'select-boundaries':
        return t({id: 'Select fields on the map'});

      case 'zoom-is-too-low':
        return t({id: 'Zoom level is too low'});

      case 'select-files-to-upload':
        return t({id: 'Upload field boundaries'});

      case 'parse-uploading-files':
        return t({id: 'Review and configure import'});

      case 'view-fields-from-files':
      case 'view-drawn-fields':
      case 'view-selected-boundaries':
        return t({id: 'Upload Fields'});

      case 'draw-fields':
        return t({id: 'Draw your fields here or search for a location'});

      case 'complete':
        return t({id: 'Field boundaries are successfully set up'});

      default:
        return t({id: 'Add fields'});
    }
  }, [currentStep, geoJsonFiles, drawnFieldsGeometries]);

  const expandPopUpWidth = hasFieldsToUpload && currentStep === 'parse-uploading-files';

  const bottomAligned =
    ['zoom-is-too-low', 'select-boundaries'].includes(currentStep) || 'draw-fields' === currentStep; // stick the pop-up to the bottom

  const reduceDialogSize =
    (currentStep === 'draw-fields' && !(geoJsonFiles.length || drawnFieldsGeometries.length)) ||
    'complete' === currentStep;

  if (currentStep === 'search-location') return null;
  if (!visible) return null;

  return (
    <>
      {!isDrawingMode && (
        <FluroDialog
          id="field-edit--dialog"
          visible={visible && !isDrawingMode}
          isDraggable
          title={dialogTitle}
          focusOnMount={false}
          portal
          className={cn('field-edit--dialog', {
            'right-aligned': isPreviewingFields,
            'bottom-aligned': bottomAligned,
            [currentStep]: true,
          })}
          dialogClassName={cn({
            'reduced-size': reduceDialogSize,
            'expanded-width': expandPopUpWidth,
          })}
          onHide={onHide}
          stickyHeader
          hideOnClickOutside={!(bottomAligned || isPreviewingFields)}
        >
          {addingFieldsFromMapSteps.includes(currentStep) && !drawnFieldsGeometries.length ? (
            <div
              onClick={closeAddingWorkflow}
              className="adding-fields__cancel-btn cancel-adding-fields-mode-btn"
            >
              {t({id: 'Cancel field creation'})}
              <FontIcon>close</FontIcon>
            </div>
          ) : null}

          <NewFields onHide={onHide} />
        </FluroDialog>
      )}

      {'draw-fields' === currentStep && isDrawingMode ? (
        <div onClick={cancelDrawing} className="adding-fields__cancel-btn">
          {t({id: 'Cancel drawing'})}
          <FontIcon>close</FontIcon>
        </div>
      ) : null}
    </>
  );
};

export default AddFieldDialog;
