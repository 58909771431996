import {Text} from 'components';
import {CardBlock} from 'containers/admin/users/edit/user-edit.styled';
import {useTranslation} from 'i18n-utils';
import type {ComponentType} from 'react';
import React from 'react';
import {ProfileCommon} from './profile-common';
import {ProfileSecurity} from './profile-security';

type Props = {};

export const ProfileSettings: ComponentType<Props> = () => {
  const t = useTranslation();

  return (
    <div className="user-settings-inner-container">
      <div>
        <Text elementType="h1" className={'admin-and-profile-title'}>
          {t({id: 'User profile'})}
        </Text>

        <CardBlock>
          <ProfileCommon />
        </CardBlock>

        <CardBlock>
          <ProfileSecurity />
        </CardBlock>
      </div>
    </div>
  );
};
