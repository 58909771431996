import {t} from 'i18n-utils';
import React, {useEffect, useState} from 'react';
import type L from 'leaflet';
import {useSelector} from 'react-redux';
import type {AppStore} from 'reducers';
type Props = {leafletElement: L.Map};
const CursorTooltipOverlay = ({leafletElement}: Props) => {
  const {
    isDrawingMode,
    drawingModeLayerType = '',
    drawingEntity,
  } = useSelector((store: AppStore) => store.map.drawControl);

  const [cursorData, setCursorData] = useState({lat: 0, lng: 0, x: 0, y: 0, isDisplay: false});

  const setCords = (e: L.LeafletMouseEvent) => {
    const x = e.originalEvent.pageX;
    const y = e.originalEvent.pageY;
    setCursorData({
      lat: e.latlng.lat,
      lng: e.latlng.lng,
      x,
      y,
      isDisplay: e.type === 'mousemove',
    });
  };

  useEffect(() => {
    if (leafletElement) {
      leafletElement.on('mousemove', setCords);
      leafletElement.on('mouseout', setCords);
    }

    return () => {
      if (leafletElement) {
        leafletElement.off('mousemove', setCords);
        leafletElement.off('mouseout', setCords);
      }
    };
  }, []);

  return isDrawingMode && drawingModeLayerType === 'marker' && cursorData.isDisplay ? (
    <div
      style={{top: cursorData.y - 70, left: cursorData.x + 25}}
      className="fluro-drawing-tooltip"
    >
      <div>
        <b>Lat:</b> {cursorData.lat.toFixed(5)}, <b>Lng:</b> {cursorData.lng.toFixed(5)}
      </div>
      {
        <div>
          <i>
            {drawingEntity === 'pivot'
              ? t({id: 'Place the pin on the map'})
              : t({id: 'Click on the map to place a pin'})}
          </i>
        </div>
      }
    </div>
  ) : null;
};

export default CursorTooltipOverlay;
