import type {ComponentType} from 'react';
import React from 'react';
import {SelectionControlGroup} from 'react-md';
import {useDispatch} from 'react-redux';
import {useAppSelector} from '_hooks';
import {setFilterAreaUnits} from 'modules/sustainability-insights/actions';
import type {SIAreaUnits} from 'modules/sustainability-insights/types';
import {selectAreaUnits} from 'modules/sustainability-insights/selectors';
import {Flex} from 'components';
import {Span} from 'components/text/span';

type Props = {};

export const AreaUnitsSelect: ComponentType<Props> = () => {
  const dispatch = useDispatch();
  const value = useAppSelector(selectAreaUnits);

  const handleChange = (areaUnits: SIAreaUnits) => {
    dispatch(setFilterAreaUnits(areaUnits));
  };

  return (
    <Flex alignItems="center">
      <Span secondary>in</Span>
      <SelectionControlGroup
        id="area-units-type-select"
        name="radio"
        type="radio"
        className="si-toggle-group si-toggle-group--inline select-units"
        inline
        value={value}
        onChange={v => handleChange(v as SIAreaUnits)}
        controls={[
          {
            label: 'pct (%)',
            value: 'pct',
          },
          {
            label: 'num (#)',
            value: 'num',
          },
        ]}
      />
    </Flex>
  );
};
