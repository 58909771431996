import React from 'react';
import SelectorDate from './selector-date';
import SelectorLayer from './selector-layer';
import NoData from '../no-data';
import {SelectorDateCalendar} from './selector-date-calendar';
import {useAppSelector} from '_hooks';
import {
  selectCurrentDates,
  selectCurrentTab,
  selectZoningTab,
} from 'containers/map/reducer/selectors';
import {ZoningTab} from 'containers/map/types';

type Props = {
  mobileView?: boolean; // used to display data in FluroChips in certain areas (not in the toggle header) for mobiles
};

const DateAndLayerControls = ({mobileView}: Props) => {
  const tab = useAppSelector(selectCurrentTab);
  const currentDates = useAppSelector(selectCurrentDates);
  const zoningTab = useAppSelector(selectZoningTab);

  if (tab === 'sustainability-insights') {
    return null;
  }

  if (tab === 'crop-performance') {
    return <SelectorDateCalendar mobileView={mobileView} />;
  }

  if (!Object.keys(currentDates).length) {
    return <NoData mobileView={mobileView} />;
  }
  if (zoningTab === ZoningTab.ProdMap) {
    // hide the dates selector for the productivity map
    return <SelectorLayer mobileView={mobileView} />;
  }
  return (
    <>
      <SelectorDate mobileView={mobileView} />
      <SelectorLayer mobileView={mobileView} />
    </>
  );
};

export default DateAndLayerControls;
