// @ts-nocheck
import React from 'react';
import type {ConnectedProps} from 'react-redux';
import {connect} from 'react-redux';
import ReadOnly from 'components/read-only-container/index';
import {Button} from 'react-md';
import LabelIcon from 'components/label-icon';

import type {SamplingPoint, SamplingPointProps} from '../../types';
import {updatePointProps, setCurrentMarker} from '../../actions/sampling-points';
import {toggleMarkerPopUp} from '../../utils/sampling-points';
import SamplingPointsLabelsDropdown from './sampling-points-labels-menu';

type Props = ConnectedProps<typeof connector> & {
  currentGroupPoints: SamplingPoint[];
};

const PointsList = ({currentGroupPoints, updatePointProps, setCurrentMarker}: Props) => {
  const onChangePointProp = (point: SamplingPoint, prop: keyof SamplingPointProps, value: any) => {
    prop === 'checked' && toggleMarkerPopUp(point.id, value);
    updatePointProps(point, {[prop]: value});
  };
  return (
    <div className="sampling-points-list">
      {currentGroupPoints
        .filter((point: SamplingPoint) => point.id !== 'new')
        .map((point: SamplingPoint) => (
          <div className="sampling-points-list__item" key={`map-sampling-item-${point.id}`}>
            <LabelIcon
              onClick={() => onChangePointProp(point, 'checked', !point.properties.checked)}
              label={point.properties.checked ? 'done' : point.properties.samplingPointType}
            />
            <div className="info">
              <SamplingPointsLabelsDropdown
                id={`menu-${point.id}`}
                className={'element-full-width'}
                label={point.properties.samplingPointType}
                onChange={val => onChangePointProp(point, 'samplingPointType', val)}
                miniButton
              />

              <div className="name">{point.properties.title}</div>
            </div>
            <ReadOnly>
              <Button onClick={() => setCurrentMarker(point.id)} icon>
                edit
              </Button>
            </ReadOnly>
          </div>
        ))}
    </div>
  );
};

const connector = connect(null, {
  updatePointProps,
  setCurrentMarker,
});
export default connector(PointsList);
