// @ts-nocheck
import React, {useState, useEffect, memo, useCallback} from 'react';
import L from 'leaflet';
import {Marker, useMap} from 'react-leaflet';
import {StopLocateIcon} from '../icons';
import cn from 'classnames';
import {FontIcon} from 'react-md';
import {reportError} from 'containers/error-boundary';

import './location-tracking.scss';
import {dialogToggle, DialogType} from '../../../modules/helpers';
import Ln from 'components/ln';
import {useDispatch, useSelector} from 'react-redux';
import Mixpanel from '_utils/mixpanel-utils';
import type {AppStore} from 'reducers';
import {booleanPointInPolygon} from '@turf/turf';
import {InfoBlock} from 'components';
import Control from 'containers/map/lib/control';

export const LocationTracking = () => {
  const [trackingLocation, setTrackingLocation] = useState(false);
  return (
    <>
      <LocationTrackingControl
        tracking={trackingLocation}
        toggleTracking={() => setTrackingLocation(tracking => !tracking)}
      />
      <LocationTrackingOverlay tracking={trackingLocation} />
    </>
  );
};

export const LocationTrackingControl = memo(
  ({tracking, toggleTracking}: {tracking: boolean; toggleTracking: () => void}) => {
    const dispatch = useDispatch();

    const toggle = useCallback(() => {
      if (tracking) {
        toggleTracking();
        return;
      }

      dispatch(
        dialogToggle(DialogType.alert, true, {
          okAction: async () => {
            Mixpanel.locateMe();
            requestOrientationPermission()
              .then(toggleTracking)
              .catch(err => {
                toggleTracking();
                reportError(err);
              });
          },
          title: 'Info',
          message: (
            <InfoBlock appearance="info" className={'inside-a-pop-up'}>
              If you encounter issues with the location feature. please report it using{' '}
              <Ln
                external
                href="https://help.flurosense.com/en/articles/4475769-locate-your-position-in-the-field"
                blank
              >
                this guide
              </Ln>{' '}
              in order to help us understand and fix the problem.
            </InfoBlock>
          ),
        })
      );
    }, [tracking, toggleTracking]);

    return (
      <Control position="topleft">
        <button
          title={tracking ? 'Stop watching location' : 'Locate me'}
          onClick={toggle}
          className={cn('locate__stop-btn', {'locate__stop-btn--alert': tracking})}
        >
          {tracking ? <StopLocateIcon /> : <FontIcon iconClassName={'fas fa-crosshairs'} />}
        </button>
      </Control>
    );
  }
);

type Coords = {latitude: number; longitude: number};

const requestOrientationPermission = () =>
  new Promise((resolve, reject) => {
    //@ts-expect-error error leftover from convertion to strict mode, please fix
    if (
      window.DeviceOrientationEvent &&
      //@ts-expect-error error leftover from convertion to strict mode, please fix
      typeof window.DeviceOrientationEvent?.requestPermission === 'function'
    ) {
      //@ts-expect-error error leftover from convertion to strict mode, please fix
      window.DeviceOrientationEvent.requestPermission()
        //@ts-expect-error error leftover from convertion to strict mode, please fix
        .then(permissionStatus => resolve(permissionStatus === 'granted'))
        .catch(reject);
    } else {
      setTimeout(resolve, 0);
    }
  });

export const LocationTrackingOverlay = memo(({tracking}: {tracking: boolean}) => {
  let locationId: number = 0;
  const [coords, setCoords] = useState({lat: 0, lng: 0});
  const [arrowRotate, setArrowRotate] = useState<number>(null);
  const currentFieldKml = useSelector((state: AppStore) => state.map.currentFieldKml);

  const leafletElement = useMap();

  const stopTracking = () => {
    navigator.geolocation.clearWatch(locationId);

    if (window.DeviceOrientationEvent) {
      window.removeEventListener('deviceorientation', handleOrientation);
      setArrowRotate(null);
    }
  };

  useEffect(() => {
    if (tracking) {
      navigator.geolocation.getCurrentPosition((e: any) => {
        const latLng = {lat: e.coords.latitude, lng: e.coords.longitude};
        setPosition(e);
        leafletElement.fitBounds(L.latLngBounds(latLng, latLng), {maxZoom: 20});
      });

      locationId = navigator.geolocation.watchPosition(setPosition);

      if (window.DeviceOrientationEvent) {
        window.addEventListener('deviceorientation', handleOrientation, false);
      }
    } else {
      stopTracking();
    }

    return stopTracking;
  }, [tracking]);

  if (!tracking || !leafletElement) {
    return null;
  }

  const handleOrientation = (event: DeviceOrientationEvent) => {
    //@ts-expect-error webkitCompassHeading is experimental webKit feature
    if (event.webkitCompassHeading !== undefined) {
      //@ts-expect-error error leftover from convertion to strict mode, please fix
      setArrowRotate(event.webkitCompassHeading);
    }
  };

  const setPosition = ({coords}: {coords: Coords}) => {
    // send debug info for detecting incorrect geolocation
    if (!booleanPointInPolygon([coords.latitude, coords.longitude], currentFieldKml)) {
      Mixpanel.sendCoordinates(coords);
    }

    const latLng = {lat: coords.latitude, lng: coords.longitude};
    setCoords(latLng);
  };

  return (
    <Marker
      position={coords}
      icon={L.divIcon({
        className: 'css-icon',
        html: arrowRotate === null ? locationIcon : arrowIcon(arrowRotate),
        iconSize: [22, 22],
      })}
    />
  );
});

const arrowIcon = (arrowRotate: number) =>
  `<svg style="transform: rotate(-${arrowRotate}deg)" height='35px' width='30px'  fill="#4798f1" xmlns:dc="http://purl.org/dc/elements/1.1/" xmlns:cc="http://creativecommons.org/ns#" xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#" xmlns:svg="http://www.w3.org/2000/svg" xmlns="http://www.w3.org/2000/svg" xmlns:sodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd" xmlns:inkscape="http://www.inkscape.org/namespaces/inkscape" version="1.1" x="0px" y="0px" viewBox="0 0 100 100"><g transform="translate(0,-952.36218)"><path style="color:#000000;enable-background:accumulate;" d="m 50,962.36218 -33,80.00002 33,-18 33,18 z" fill="#4798f1" stroke="none" marker="none" visibility="visible" display="inline" overflow="visible"/></g></svg>`;

const locationIcon = `<div class="locate-object">
  <div class="gps-circle"></div>
  <div class='gps-ring'></div>
</div>`;
