import {Flex, Text} from 'components';
import {ChromaScale} from 'components/chroma-scale/chroma-scale';
import type {ComponentType} from 'react';
import React from 'react';
import {useAppSelector} from '_hooks';
import {valueFormatter} from '../filters-panel/cards/utils';

import {
  selectAreaUnits,
  selectIsYearsDiff,
  selectYearsFilter,
} from 'modules/sustainability-insights/selectors';

import {
  selectEmissionsFactorChartTab,
  selectEmissionsFactorColorScale,
  selectEmissionsFactorStatsExtrema,
} from 'modules/sustainability-insights/selectors/emissions-factor';

export const EmissionFactorFilterStatus: ComponentType<{}> = () => {
  const {colors, domain} = useAppSelector(selectEmissionsFactorColorScale);
  const units = useAppSelector(selectAreaUnits);
  const {min, max} = useAppSelector(selectEmissionsFactorStatsExtrema);
  const isDiff = useAppSelector(selectIsYearsDiff);
  const years = useAppSelector(selectYearsFilter);
  const chartTab = useAppSelector(selectEmissionsFactorChartTab);

  if (chartTab !== 'summary' || !years?.length || !Number.isFinite(min) || !Number.isFinite(max))
    return null;

  const [yearFrom, yearTo] = years;

  const emissionFactorUnits = units === 'pct' ? '%' : 'kg CO2 eq. / kg crop yield';

  return (
    <>
      <Flex alignItems="center">
        <Text className={`mb-0 mr-2 ${isDiff ? 'is-diff-long' : 'is-diff-short'}`}>
          {isDiff ? (
            <>
              Field Emission Factor {yearFrom} - {yearTo} ({emissionFactorUnits})
            </>
          ) : (
            <>
              Field Emission Factor {yearFrom} ({emissionFactorUnits})
            </>
          )}
        </Text>
        <ChromaScale
          min={valueFormatter(min, units, true)}
          max={valueFormatter(max, units, true)}
          colors={colors}
          domain={domain}
        />
      </Flex>
    </>
  );
};
