import React, {useContext, useMemo} from 'react';
import {useAppSelector} from '_hooks';
import {selectStageById} from 'containers/mrv/monitoring/module/selectors';
import {MRVTableRowMemo} from './mrv-table-row';
import type {FieldValuesRow, MRVPhaseType, MRVValueInput} from 'containers/mrv/types';
import {getIndexesPerYear} from 'containers/mrv/data/field-rows';
import {ValidationContext} from '../../../data/ValidationContext';
import {useHackyValidateFieldRows} from './hacky-validation';

export const MRVTableEntityRows = ({
  phaseType,
  entityId,
  stageId,
  rows,
  isImpersonatorSuperAdmin,
  readOnly,
  isLoading,
  isSelected,
  onSelectRow,
  onUpdateValues,
  onAddRow,
  onRemoveRow,
}: {
  phaseType: MRVPhaseType;
  entityId: number;
  stageId: number;
  rows: FieldValuesRow[];
  isImpersonatorSuperAdmin: boolean;
  readOnly: boolean;
  isLoading: boolean;
  isSelected: (entityId?: number, rowId?: number) => boolean;
  onSelectRow: (entityId: number, rowId: number, selected: boolean) => void;
  onUpdateValues: (entityId: number, rowId: number, values: MRVValueInput[]) => void;
  onAddRow: (entityId: number, rowIndex: number, row: FieldValuesRow) => void;
  onRemoveRow: (entityId: number, rowIndex: number, valueIds: number[]) => void;
}) => {
  const stage = useAppSelector(s => selectStageById(s, stageId));

  const attributesIds = stage?.attributes || [];

  const isMultiYear = (stage?.num_rows || 0) > 1 || stage?.year_end !== stage?.year_start;

  // First and last row indexes are used to show add/delete row buttons.
  const {firstRowIndexPerYear, lastRowIndexPerYear} = useMemo(
    () => getIndexesPerYear(rows),
    [rows]
  );

  const {ignoredSimilarWarnings, ignoredWarnings} = useContext(ValidationContext);
  const {errors, warnings} = useHackyValidateFieldRows(
    rows,
    ignoredWarnings,
    ignoredSimilarWarnings
  );

  if (!rows || !entityId) return <div>No rows</div>;

  return (
    <>
      {rows.map((row, i) => {
        // Always keep at least 1 row (don't let the user delete the first row).
        // Though, always let superadmins remove rows https://regrow.atlassian.net/browse/FSB-12670.
        const canDeleteRow =
          isImpersonatorSuperAdmin ||
          (!readOnly && !!row.year && i > firstRowIndexPerYear[row.year]);
        const canAddRow =
          !readOnly && isMultiYear && !!row.year && lastRowIndexPerYear[row.year] === i;

        return (
          <MRVTableRowMemo
            key={`${stage?.id}-${entityId}-${row.row_id}`}
            row={row}
            rowIndex={i}
            phaseType={phaseType}
            attributesIds={attributesIds}
            readOnly={readOnly}
            canAddRow={canAddRow}
            canDeleteRow={canDeleteRow}
            isSelected={isSelected(entityId, row.row_id)}
            isLoading={isLoading}
            onSelect={onSelectRow}
            onChange={onUpdateValues}
            onRemoveRow={onRemoveRow}
            onAddRow={onAddRow}
            errors={errors}
            warnings={warnings}
          />
        );
      })}
    </>
  );
};
