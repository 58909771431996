import React from 'react';
import type {ComponentType} from 'react';
import {MenubarProfileComponent} from 'containers/menubar/menubar-profile';
import {MenubarWorkspacesComponent} from 'containers/menubar/menubar-workspaces';
import {MenubarTopLevelNavigationComponent} from 'containers/menubar/menubar-middle-nav/menubar-middle-nav';
import styled from 'styled-components';
import {MENUBAR_HEIGHT} from 'containers/menubar/menubar-constants';

export const Menubar: ComponentType = () => {
  return (
    <MenubarContainer id="menubar-container">
      <MenubarWorkspacesComponent />
      <MenubarTopLevelNavigationComponent />

      <MenubarProfileComponent />
    </MenubarContainer>
  );
};

export const MenubarContainer = styled.nav`
  height: ${MENUBAR_HEIGHT}px;
  width: 100%;
  background-color: #191411;
  display: grid;
  grid-template-columns: minmax(max-content, 1fr) minmax(max-content, 1fr) minmax(max-content, 1fr);
  align-items: center;
  color: ${({theme}) => theme.color.stroke.strong};
  position: fixed;
  top: 0;
  z-index: 10000;
`;
