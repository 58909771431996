import groupBy from 'lodash/groupBy';
import {createSelector} from 'reselect';
import {sortByKeyReverse} from '_utils/sorters';
import {averageNumber} from '_utils/pure-utils';
import {selectStatesMeta} from '.';
import {getAbatementPotentialColor, SUMMARY_BARS_PREVIEW_THRESHOLD} from '../constants';
import type {
  AreaId,
  GroupedSummaryChartData,
  SIStateChildMeta,
  SummaryChartData,
  SummaryChartDatum,
} from '../types';
import {calcSummaryChartExtrema, parseAreaId} from '../utils';
import {
  getAreaId,
  selectActiveGeometriesIds,
  selectAggLevel,
  selectCurrentMeta,
  selectLastAvailableYear,
  selectMetrics,
  selectSIChartGroupingType,
  selectSIFilter,
  selectSIGeometryById,
  selectVisibleGeometriesIds,
} from './selectors';

type AbatementPotentialSummaryChartData = SummaryChartData & {
  minValue: number;
  maxValue: number;
  totalSequestered: number;
  valuesById?: Record<
    AreaId,
    {
      area: number;
      value: number;
      name: string;
      totalArableArea?: number;
      numFrom?: number;
      totalFrom?: number;
      numTo?: number;
      totalTo?: number;
    }
  >;
};

export const selectAbatementPotentialMetric = createSelector(
  [selectSIFilter],
  siFilter => siFilter.abatementPotential.selectedMetric
);

export const selectAbatementPotentialChartData = createSelector(
  [
    selectVisibleGeometriesIds,
    selectActiveGeometriesIds,
    selectAggLevel,
    selectMetrics,
    selectLastAvailableYear,
    selectAbatementPotentialMetric,
    selectCurrentMeta,
    selectStatesMeta,
    selectSIChartGroupingType,
  ],
  (
    visibleIds,
    activeIds,
    aggLevel,
    metrics,
    lastAvailableYear,
    abatementMetric,
    meta,
    statesMeta,
    groupingType
  ): AbatementPotentialSummaryChartData | null => {
    if (!activeIds?.length) return null;

    const valuesById: AbatementPotentialSummaryChartData['valuesById'] = {};
    const statesValues: Record<AreaId, number[]> = {}; // need to get "pure" min max values for a state
    let totalArea = 0;
    let totalSequestered = 0;

    const summaryChartData: SummaryChartDatum[] = sortByKeyReverse(
      visibleIds.reduce((acc: Array<SummaryChartDatum>, id) => {
        const areaObj = meta[id];
        const abatementMetricObject = metrics?.[id]?.[lastAvailableYear]?.[abatementMetric];
        const value = abatementMetricObject?.value;
        const area = abatementMetricObject?.area || 0;

        if (!areaObj || !Number.isFinite(value)) return acc;

        const stateId = (areaObj as SIStateChildMeta)?.statefp;
        statesValues[stateId] = statesValues[stateId] ? [...statesValues[stateId], value] : [value];

        if (!activeIds.includes(id)) return acc;

        valuesById[id] = {value, area, name: areaObj.name};
        totalArea = totalArea + area;
        totalSequestered = totalSequestered + value * area;

        return [
          ...acc,
          {
            id,
            value,
            name: areaObj.name,
            statefp: stateId,
            area,
          },
        ];
      }, []),
      'value'
    );

    // Apply filters for chart data
    const preview = summaryChartData.length > SUMMARY_BARS_PREVIEW_THRESHOLD;
    let groupedChartData: GroupedSummaryChartData[] = [];
    if (groupingType === 'grouped') {
      const groupedByState = groupBy(summaryChartData, 'statefp');
      groupedChartData = sortByKeyReverse(
        Object.keys(groupedByState).map(stateFP => {
          const stateMeta = statesMeta[parseAreaId(stateFP)];
          const name = stateMeta?.name?.toUpperCase?.();
          const data = [...groupedByState[stateFP]];
          const stateValues = statesValues[parseAreaId(stateFP)];
          const avg = averageNumber(stateValues);

          return {
            name,
            data,
            value: avg,
          };
        }),
        'value'
      );
    }

    const allValues = Object.values(valuesById).map(({value}) => value);
    const {minValue, maxValue} = calcSummaryChartExtrema(allValues);

    return {
      type: groupingType,
      chartData: groupingType === 'grouped' ? groupedChartData : summaryChartData,
      preview,
      totalValue: totalArea,
      minValue,
      maxValue,
      totalSequestered,
      valuesById,
    };
  }
);

export const selectAbatementPotentialFilterRange = createSelector(
  [selectSIFilter],
  siFilter => siFilter.abatementPotential.filterRange
);

export const getAbatementPotentialAreaValuesById = createSelector(
  [selectAbatementPotentialChartData, getAreaId],
  (chartData, areaId: AreaId) => chartData?.valuesById?.[areaId]
);

export const selectAbatementPotentialAreaData = createSelector(
  [getAbatementPotentialAreaValuesById, getAreaId],
  (areaValues, areaId) => {
    return {
      id: areaId,
      value: areaValues?.value,
      name: areaValues?.name,
    };
  }
);

export const selectAbatementPotentialGeometryData = createSelector(
  [selectAbatementPotentialAreaData, selectSIGeometryById, selectAbatementPotentialFilterRange],
  (abatementPotentialItem, geometry, filterRange) => {
    return {
      ...abatementPotentialItem,
      color: getAbatementPotentialColor(filterRange, abatementPotentialItem.value),
      geometry,
    };
  }
);
