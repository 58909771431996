import {selectProtectedAreaBoundaries} from 'modules/add-fields/selectors';
import {selectFarms} from 'modules/farms/selectors';
import {useCallback, useMemo} from 'react';
import {aNewFieldSizeLimitHa} from '_constants';
import {useAppSelector} from '_hooks';
import {getFieldFarmName} from '_utils/farm-utils';
import type {FieldBoundary} from '../types';
import {FieldSystemProp, NewFieldError} from '../types';
import {newFieldErrorMessages} from './error-messages';

type FieldErrors = {[error in NewFieldError]: Set<string | number> | undefined};
export type FieldErrorsViews = {[error in NewFieldError]: string | JSX.Element | undefined};

export const useFieldErrors = (boundaries: FieldBoundary[]) => {
  const farms = useAppSelector(selectFarms);
  const protectedAreaBoundaries = useAppSelector(selectProtectedAreaBoundaries);

  const errors = useMemo(() => {
    const errors = createErrorMap<Set<string | number>>();
    errorTypes.forEach(e => {
      errors[e] = new Set();
    });
    boundaries.forEach(f => {
      const id = f.properties[FieldSystemProp.Id] || 0;
      const size = f.properties[FieldSystemProp.Area] || 0;
      const name = f.properties[FieldSystemProp.FieldName] || '';
      if (size > aNewFieldSizeLimitHa) {
        errors[NewFieldError.FieldSizeError]?.add(id);
      }
      if (name.length < 1 || name.length > 50) {
        errors[NewFieldError.FieldNameError]?.add(id);
      }
      if (!getFieldFarmName(f, farms)) {
        errors[NewFieldError.FieldFarmNameError]?.add(id);
      }
      if (protectedAreaBoundaries[id]) {
        errors[NewFieldError.FieldProtectedAreaError]?.add(id);
      }
    });
    return errors;
  }, [boundaries, protectedAreaBoundaries, farms]);

  const getFieldErrors = useCallback(
    (fieldId: number | string) => _getFieldErrors(fieldId, errors),
    [errors]
  );

  return {errors, getFieldErrors};
};

const _getFieldErrors = (fieldId: number | string, errors: FieldErrors): FieldErrorsViews => {
  const fieldErrors = createErrorMap<string | JSX.Element>();
  errorTypes.forEach(e => {
    if (errors[e]?.has(fieldId)) {
      fieldErrors[e] = newFieldErrorMessages[e]();
    }
  });
  return fieldErrors;
};

const errorTypes = [
  NewFieldError.FieldNameError,
  NewFieldError.FieldFarmNameError,
  NewFieldError.FieldSizeError,
  NewFieldError.FieldProtectedAreaError,
];

const createErrorMap = <T,>() => {
  const errors: {[error in NewFieldError]: T | undefined} = {
    [NewFieldError.FieldNameError]: undefined,
    [NewFieldError.FieldFarmNameError]: undefined,
    [NewFieldError.FieldSizeError]: undefined,
    [NewFieldError.FieldProtectedAreaError]: undefined,
  };
  return errors;
};
