// @ts-nocheck
import {t} from 'i18n-utils';
import React, {useMemo, memo, useState} from 'react';
import {SAMPLING_POINTS_LABELS_TOP, SAMPLING_POINTS_LABELS} from '_constants';
import {FontIcon} from 'react-md';
import type {LayoverPositions} from 'react-md/lib/Helpers';
import FluroDropdownMenu from 'components/fluro-dropdown-menu';
import LabelIcon from 'components/label-icon';

/*
 *
 * Menu list Id:
 *  1 - Sampling point
 *  2 - Observations
 *  3 - Object
 *
 * */

type Props = {
  id?: string;
  miniButton?: boolean;
  width?: number;
  label: string;
  onChange: (value: string) => void;

  className?: string;
  primary?: boolean;
  iconEl?: React.ReactElement;
  disabled?: boolean;
  position?: LayoverPositions;
  menuButtonEl?: React.ReactElement;
};

const SamplingPointsLabelsDropdown = memo(
  ({
    onChange,
    primary,
    id,
    width,
    className,
    label,
    iconEl,
    disabled,
    position,
    menuButtonEl,
    miniButton, // reduce height, padding, margin
  }: Props) => {
    const [searchLabel, setSearchLabel] = useState('');

    /*
     *
     * Sort labels by top categories
     *
     * */
    const labels = useMemo(() => {
      if (searchLabel) {
        const result = SAMPLING_POINTS_LABELS()
          .filter(({label}) => label.toLocaleLowerCase().includes(searchLabel.toLocaleLowerCase()))
          .map(({label, value}) => ({
            primaryText: label,
            leftAvatar: <LabelIcon label={value} />,
            onClick: () => onChange(value),
          }));

        if (!result.length) {
          result.push({
            primaryText: 'No search results',
            leftAvatar: <FontIcon>mood_bad</FontIcon>,
            onClick: null,
          });
        }

        return result;
      }

      return SAMPLING_POINTS_LABELS_TOP.map(el => {
        const nestedItems = SAMPLING_POINTS_LABELS()
          .filter(label => label.menuListId === el.menuListId)
          .map(({label, value}) => ({
            primaryText: t({id: label}),
            leftAvatar: <LabelIcon label={value} />,
            onClick: () => onChange(value),
          }));

        return {
          primaryText: <span title={t({id: el.title})}>{t({id: el.title})}</span>,
          leftAvatar: <LabelIcon label={el.icon} />,
          nestedListClassName: 'labels-list-item',
          className: 'labels-list-item-top',
          nestedItems,
        };
      });
    }, [searchLabel]);

    return (
      <FluroDropdownMenu
        id={id}
        width={width}
        position={position}
        menuItems={labels}
        className={className}
        miniButton={miniButton}
        label={label}
        disabled={disabled}
        primary={primary}
        iconEl={iconEl}
        menuButtonEl={menuButtonEl}
        searchLabel={searchLabel}
        setSearchLabel={setSearchLabel}
      />
    );
  }
);

export default SamplingPointsLabelsDropdown;
