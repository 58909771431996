// @ts-nocheck
import type {AppStore} from 'reducers';
import type {IntegrationPlatform} from './types';
import {createSelector} from 'reselect';
import {selectUserSettings} from '../../login/login-selectors';

export const selectIntegrationPlatforms = (s: AppStore) => s.integrations.platforms;

export const selectIntegrationPlatform = createSelector(
  [selectIntegrationPlatforms, (s: AppStore, platform: IntegrationPlatform) => platform],
  (platforms, platform) => platforms[platform]
);

export const selectSelectedPlatformToImport = (s: AppStore) =>
  s.integrations.selectedPlatformToImport;

export const selectUserFavoritePlatforms = createSelector(
  [selectUserSettings],
  userSettings => userSettings.favorite_platforms
);

export const selectPlatformIsFavorite = createSelector(
  [selectUserFavoritePlatforms, (s: AppStore, platform: IntegrationPlatform) => platform],
  (favoritePlatforms, platform) => favoritePlatforms[platform]
);
