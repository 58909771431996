import React from 'react';
import InfoBlock from 'components/info-block';
import {DNDCStatus} from 'containers/mrv/types';
import {LoadingIcon} from 'containers/mrv/icons';
import colors from 'variables.scss';
import {useAppSelector} from '_hooks';
import {selectDNDC} from 'containers/mrv/enrollment/carbon-store/selectors';
import {OutcomeEstimationErrorMessage} from 'containers/mrv/outcome-estimation-error-message';
import {t} from 'i18n-utils';

type DNDCMessageProps = {
  isOnMainPage?: boolean;
};
export function DNDCMessage({isOnMainPage: messageIsOnMainPage}: DNDCMessageProps) {
  const {status} = useAppSelector(selectDNDC);

  if (status === DNDCStatus.InProgress) {
    return (
      <InfoBlock
        icon={<LoadingIcon style={{color: colors['main-blue-600']}} />}
        mini
        appearance="info"
        color="info"
      >
        <strong>{t({id: 'DNDCMessage.Title', defaultMessage: 'Calculating outcomes.'})}</strong>{' '}
        {messageIsOnMainPage
          ? t({
              id: 'DNDCMessage.Message.MainPage',
              defaultMessage:
                'You will be able to continue editing your fields once this process is done.',
            })
          : t({
              id: 'DNDCMessage.Message',
              defaultMessage:
                'You will be notified by email when this process is complete. Exciting things coming soon!',
            })}
        {/* Currently the DNDC notifications return all at once, so the percentage jumps from 0 to 100% in one instant.
        Add this code back in once the notifications are returned in chunks. */}
        {/* <PercentComplete>{percentCompleted}% complete</PercentComplete> */}
      </InfoBlock>
    );
  }

  if (status === DNDCStatus.Failed) {
    return <OutcomeEstimationErrorMessage />; // Display it when 429 code or other
  }

  return null;
}

// const PercentComplete = styled.div`
//   margin-top: 8px;
//   font-weight: bold;
// `;
