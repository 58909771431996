import type {ComponentType} from 'react';
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {FluroButton, FluroDataTable, FluroDialog, FluroTableBody, Sticky, Text} from 'components';
import {DialogType, dialogToggle} from 'modules/helpers';
import {useAppDispatch, useAppSelector} from '_hooks';
import {selectDialogData} from 'modules/helpers/selectors';
import {selectMrvFieldsMapByMrvId, selectMRVAttributes} from '../module/selectors';
import {EntitySelectInner} from '../form/entity-select';
import type {Farm} from '../../../map/types';
import type {MRVValue} from '../../types';
import {MRVPhaseType} from '../../types';
import type {MRVTableRowProps} from '../form/mrv-table/mrv-table-row';
import {MRVTableRow} from '../form/mrv-table/mrv-table-row';
import {MRVTableHeaders} from '../form/mrv-table/mrv-table-headers';
import {unique} from '_utils/pure-utils';
import './fms-sync-report.scss';
import {t} from 'i18n-utils';
import {currentYear} from 'containers/mrv/enrollment/base/base';
import {getEntityId} from 'containers/mrv/value-utils';

type EnhancedRowProps = MRVTableRowProps & {farmId: number};

export const FMSSyncReportDialog: ComponentType = () => {
  const dispatch = useAppDispatch();
  const {visible, payload}: {visible?: boolean; payload?: {data: MRVValue[]}} = useAppSelector(s =>
    selectDialogData(s, DialogType.fmsSyncReport)
  );
  const projectFields = useAppSelector(selectMrvFieldsMapByMrvId);
  const [fieldRowsData, setFieldRowsData] = useState<EnhancedRowProps[]>([]);
  const [farmsList, setFarmsList] = useState<Pick<Farm, 'id' | 'name'>[]>([]);
  const [selectedFarm, setSelectedFarm] = useState(-1);
  const storeAttributes = useAppSelector(selectMRVAttributes);

  const onHide = useCallback(
    () => dispatch(dialogToggle(DialogType.fmsSyncReport, false, {})),
    [dispatch]
  );

  const onFarmItemClick = (farmId: number) => {
    setSelectedFarm(farmId);
  };

  useEffect(() => {
    if (!payload?.data?.length) return;

    const farmRecords: Record<number, Pick<Farm, 'id' | 'name'>> = {};
    const rowMap: Record<number, EnhancedRowProps> = {};

    const attributesIds = unique(payload.data.map(valueItem => valueItem.attribute_id));

    payload.data.forEach((valueItem, i) => {
      const entityId = getEntityId(valueItem);
      const fieldData = projectFields?.[entityId];
      if (!fieldData) return;
      valueItem.locked = false; // unlock manually, because the rows are read only anyway
      valueItem.confirmed = true; // assume the data is proved by FMS
      const farmName = fieldData.core_attributes.farm_name || '';

      farmRecords[fieldData.farm_id] = {id: fieldData.farm_id, name: farmName};

      if (!rowMap[valueItem.row_id]) {
        rowMap[valueItem.row_id] = {
          phaseType: MRVPhaseType.Monitoring,
          attributesIds: attributesIds,
          farmId: fieldData.farm_id,
          rowIndex: i,
          row: {
            entity_type: 'field',
            field_id: entityId,
            row_id: valueItem.row_id,
            values: {[valueItem.attribute_id]: valueItem},
            year: currentYear,
          },
          hideCheckbox: true,
          readOnly: true,
        };
      } else {
        rowMap[valueItem.row_id].row.values[valueItem.attribute_id] = valueItem;
      }
    });

    const fieldRowsDataRaw = Object.values(rowMap);
    const fieldRowsDataClearedAttributes = fieldRowsDataRaw.map(dataRow => ({
      ...dataRow,
      attributesIds: dataRow.attributesIds.filter(attributeId => {
        // filter attributes per farm to fill all columns
        // filter per farm because we display rows per farm
        return fieldRowsDataRaw.find(
          searchDataRow =>
            searchDataRow.farmId === dataRow.farmId && searchDataRow.row.values[attributeId]
        );
      }),
    }));
    const updatedFarmsList = Object.values(farmRecords);

    if (updatedFarmsList.length > 1) {
      updatedFarmsList.unshift({
        id: -1,
        name: t({id: 'SelectListItem.Mrv.All farms', defaultMessage: 'All farms'}),
      });
    }

    setFarmsList(updatedFarmsList);
    setFieldRowsData(fieldRowsDataClearedAttributes);
  }, [payload, projectFields]);

  useEffect(
    function defineDefaultSelectedFarm() {
      const firstFarmId = farmsList[0]?.id;
      if (!selectedFarm && firstFarmId) {
        setSelectedFarm(firstFarmId);
      }
    },
    [farmsList, selectedFarm]
  );

  const filteredFieldsByFarm = useMemo(() => {
    if (selectedFarm === -1) return fieldRowsData;
    return fieldRowsData.filter(fieldRow => fieldRow.farmId === selectedFarm);
  }, [selectedFarm, fieldRowsData]);

  const attributes = useMemo(() => {
    if (!filteredFieldsByFarm.length) return [];

    // any field in the farm contains all list of attributes per farm
    return filteredFieldsByFarm[0].attributesIds
      .map(attributeId => storeAttributes[attributeId])
      .filter(Boolean);
  }, [filteredFieldsByFarm, storeAttributes]);

  return (
    <FluroDialog
      title={t({id: 'Fms.Sync.DataImported', defaultMessage: 'Data imported'})}
      onHide={onHide}
      visible={visible}
      id={'fms-sync-report'}
      portal
      dialogClassName={'fms-sync-report'}
    >
      <Text secondary variant={'medium'}>
        {t({id: 'You have imported the following operations to the MRV:'})}
      </Text>

      <EntitySelectInner
        label={t({id: 'FarmLabel'}, {farmsNumber: farmsList.length})}
        entities={farmsList}
        activeEntityId={selectedFarm}
        onEntityItemClick={onFarmItemClick}
      />

      <FluroDataTable>
        <MRVTableHeaders reportView attributes={attributes} />

        <FluroTableBody>
          {filteredFieldsByFarm.map(props => (
            <MRVTableRow key={props.row.row_id} {...props} />
          ))}
        </FluroTableBody>
      </FluroDataTable>

      <Sticky className={'actions-container'}>
        <FluroButton onClick={onHide} primary raised>
          {t({id: 'BtnLabel.Close', defaultMessage: 'Close'})}
        </FluroButton>
      </Sticky>
    </FluroDialog>
  );
};
