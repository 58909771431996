import {isString} from '_utils/typeGuards';

// Moved out of _utils/index.ts

// TODO: sortAsc can be used to replace the repeated logic below
// once these functions are also unit tested to ensure no breakages
export const sortAsc = <T>(x: T, y: T) => (x < y ? -1 : x > y ? 1 : 0);
export const sortDesc = <T>(x: T, y: T) => (x < y ? 1 : x > y ? -1 : 0);

// sort functions
export function sortByKey<T>(array: Array<T> = [], key: keyof T, keySensitive = true): Array<T> {
  return array.sort(function (a, b) {
    let x = a?.[key];
    let y = b?.[key];
    if (!keySensitive && typeof x === 'string' && typeof y === 'string') {
      const X = x.toLowerCase();
      const Y = y.toLowerCase();
      return X < Y ? -1 : X > Y ? 1 : 0;
    }
    //@ts-expect-error error leftover from convertion to strict mode, please fix
    return x < y ? -1 : x > y ? 1 : 0;
  });
}

export function sortByKey2<T>(
  array: T[] = [],
  getter: (item: T) => string | number,
  keySensitive = true
): T[] {
  return array.sort(function (a, b) {
    let x = getter(a);
    let y = getter(b);
    if (!keySensitive && typeof x === 'string' && typeof y === 'string') {
      const X = x.toLowerCase();
      const Y = y.toLowerCase();
      return X < Y ? -1 : X > Y ? 1 : 0;
    }
    return x < y ? -1 : x > y ? 1 : 0;
  });
}

export const sortByKeyReverse = <T extends object>(
  array: Array<T>,
  key: keyof T,
  keySensitive = true
) => {
  return array.sort(function (a, b) {
    const x = a[key];
    const y = b[key];
    if (!keySensitive && isString(x) && isString(y)) {
      return sortDesc(x.toLowerCase(), y.toLowerCase());
    }
    return sortDesc(x, y);
  });
};

/**
 * @deprecated use sortByKeyReverse instead
 */
export function sortByKeyReverseUnsafe(
  array: Array<any> = [],
  key: string | number,
  keySensitive = true
) {
  return array.sort(function (a, b) {
    let x = a?.[key];
    let y = b?.[key];
    if (!keySensitive && typeof x === 'string' && typeof y === 'string') {
      x = x.toLowerCase();
      y = y.toLowerCase();
    }
    return x < y ? 1 : x > y ? -1 : 0;
  });
}

export function naturalSortAlphaNum<T extends object>(array: T[], key: keyof T) {
  return array.sort((a, b) => {
    const valueA = a[key];
    const valueB = b[key];
    if (typeof valueA === 'string' && typeof valueB === 'string') {
      return valueA.localeCompare(valueB, undefined, {numeric: true, sensitivity: 'base'});
    } else {
      return 0;
    }
  });
}
