// @ts-nocheck
import L from 'leaflet';
// @ts-expect-error error leftover from convertion to strict mode, please fix
import tj from '@mapbox/togeojson';
import {
  area as turfArea,
  lineIntersect,
  lineOffset,
  lineToPolygon,
  difference,
  lineString,
  polygon as turfPolygon,
  booleanContains,
  booleanPointInPolygon,
  polygon,
} from '@turf/turf';
// @ts-expect-error error leftover from convertion to strict mode, please fix
import geojsonhint from '@mapbox/geojsonhint';
//@ts-expect-error error leftover from convertion to strict mode, please fix
import polylabel from 'polylabel';
import {reportError} from 'containers/error-boundary';
import type {Field} from 'containers/map/types';
import {GeoJsonType} from 'containers/map/types';
import {booleanIntersectField} from '_utils';
import {genKey, downloadFile} from './pure-utils';
import {getRandomColor, normalizeFirstLastPointOfGeometry, pointInside} from './index';
import {FieldSystemProp} from 'containers/map/features/farm/new-fields/types';
import type {FeatureCollectionWithFilename} from 'shpjs';
import {t} from 'i18n-utils';
import convert from 'convert-units';
import {FranceCoordinates, USCoordinates} from '../_constants';
import type {AppStore} from 'reducers';
import {KmlApi} from '../_api';
import {showNotification} from 'components/notification/notification';

export const geojsonhintExeptions = [
  'Polygons and MultiPolygons should follow the right-hand rule',
];

/**
 * Updates (mutates) properties of each feature inside featureCollections
 * with passed properties.
 *
 * Example:
 * updateFeatureProperties(featureCollection, {'fluro_id': f.ID})
 */
export const updateEachFeatureProperties = (
  featureCollection: GeoJSON.FeatureCollection,
  properties: {[key: string]: any}
) => {
  featureCollection.features.forEach(feature => {
    Object.keys(properties).forEach(key => {
      if (!feature.properties) {
        feature.properties = {};
      }
      feature.properties[key] = properties[key];
    });
  });
};

/**
 * Parses KML string into GeoJSON Feature Collection.
 * Make sure to handle the exception.
 */
export const kmlToGeoJSON = (kml: string): GeoJSON.FeatureCollection => {
  const parser = new DOMParser();
  // trim is important, we had couple files (from the customer: Agra-Terra Farms Ltd.2019.kmz) with new lines and spaces at the begin of the string,
  // it is not allowed for the DOM xml parser
  const xmlDoc = parser.parseFromString((kml || '').trim(), 'text/xml');
  const featureCollection = tj.kml(xmlDoc);
  if (!featureCollection.features.length) {
    throw new Error('Could not parse the kml');
  }
  return featureCollection;
};

type AllowedReturnType = Array<
  GeoJSON.Feature<GeoJSON.Polygon | GeoJSON.MultiPolygon | GeoJSON.GeometryCollection>
>;

export const handleFeatureCollection = (
  featureCollection: FeatureCollectionWithFilename,
  validGeometries: any[],
  tooSmallGeometries: string[],
  nonPolygonGeometries: string[],
  errors: any[]
) => {
  const {allowedGeometries, notAllowedGeometries} = separateGeometry(featureCollection.features);

  if (notAllowedGeometries.length) {
    nonPolygonGeometries.push(...notAllowedGeometries);
  }

  allowedGeometries.forEach(feature => {
    const fileName = featureCollection.fileName;

    normalizeFirstLastPointOfGeometry(feature);

    const arrayIssues = geojsonhint
      .hint(feature)
      .filter((err: any) => !geojsonhintExeptions.find(exept => exept === err.message));
    if (arrayIssues.length) {
      errors.push(t({id: 'KML error, file unable to be uploaded.'}));
      return;
    }

    const geometryAreaInMeters = turfArea(feature);

    // Filter all geometries with area less then 100 m2
    if (geometryAreaInMeters < 100) {
      tooSmallGeometries.push(fileName);
      return;
    }

    feature.properties[FieldSystemProp.Area] = convert(geometryAreaInMeters).from('m2').to('ha');

    // save original file name for parsing file
    feature.properties[FieldSystemProp.FileName] = fileName;
    feature.properties[FieldSystemProp.FarmId] = 0; // reset props
    feature.properties[FieldSystemProp.NewFarmName] = ''; // reset props
    feature.properties[FieldSystemProp.Checked] = true;
    feature.properties[FieldSystemProp.Id] = genKey();

    const featureId = feature.properties[FieldSystemProp.Id];

    validGeometries.push(feature);

    if (fileName.length < 2 || fileName.length > 50) {
      errors.push(featureId);
    }
  });
};

/**
 * Add fluro_id & fluro_farm_id to each feature of the FeatureCollection.
 */
export const enhanceGeometryProperties = (
  geometry: GeoJSON.FeatureCollection,
  farmId: number,
  fieldId: number
) => {
  geometry.features.forEach(feature => {
    if (!feature.properties) {
      feature.properties = {};
    }
    feature.properties.fluro_id = fieldId;
    feature.properties.fluro_farm_id = farmId;
  });
  return geometry;
};

export const getNonPolygonMessage = (
  nonPolygonGeometries: string[],
  type: 'fields' | 'planting areas'
) => {
  const uniqueNonPolygonGeometries = [...new Set(nonPolygonGeometries)];
  const error = [
    `During uploading ${type}, in handleFeatureCollection function`,
    `we found ${nonPolygonGeometries.length} non-polygons: ${uniqueNonPolygonGeometries.join(
      ', '
    )}`,
  ].join(' ');

  //TODO: i18n
  const g = nonPolygonGeometries.length > 1 ? 'geometries' : 'geometry';
  const _t = uniqueNonPolygonGeometries.length > 1 ? 'types' : 'type';
  const i = uniqueNonPolygonGeometries.length > 1 ? 'are' : 'is';

  const ids = uniqueNonPolygonGeometries.join(', ');
  const note = `${nonPolygonGeometries.length} ${g} of ${_t} ${ids} ${i} not supported.`;
  return {error, note};
};

/**
 * Extracts Polygons from features.
 * For GeometryCollections for each geometry it creates a new feature with a single Polygon.
 *
 * Also returns a list of unique non polygon types.
 */
export const separateGeometry = (
  features: Array<GeoJSON.Feature>
): {
  allowedGeometries: AllowedReturnType;
  notAllowedGeometries: GeoJSON.GeoJsonGeometryTypes[];
} => {
  const allowedGeometries: AllowedReturnType = [];
  const notAllowedGeometries: GeoJSON.GeoJsonGeometryTypes[] = [];

  features.forEach(f => {
    switch (f.geometry.type) {
      // GeoJSON type inferring doesn't understand that
      // when f.geometry.type === 'Polygon', the f is Feature<Polygon>.
      case GeoJsonType.Polygon:
      case GeoJsonType.MultiPolygon:
        allowedGeometries.push(f as GeoJSON.Feature<GeoJSON.Polygon | GeoJSON.MultiPolygon>);
        break;
      case GeoJsonType.GeometryCollection:
        f.geometry.geometries = f.geometry.geometries.filter(g => {
          switch (g.type) {
            case GeoJsonType.Polygon:
            case GeoJsonType.MultiPolygon:
              return true;
            case GeoJsonType.Point:
            case GeoJsonType.LineString:
            case GeoJsonType.MultiPoint:
            case GeoJsonType.MultiLineString:
              notAllowedGeometries.push(g.type);
              return false;
            default:
              return false;
          }
        });

        allowedGeometries.push(f as GeoJSON.Feature<GeoJSON.GeometryCollection>);

        break;
      case GeoJsonType.Point:
      case GeoJsonType.LineString:
      case GeoJsonType.MultiPoint:
      case GeoJsonType.MultiLineString:
        notAllowedGeometries.push(f.geometry.type);
        break;
      default:
      // Do nothing.
    }
  });
  return {allowedGeometries, notAllowedGeometries: [...new Set(notAllowedGeometries)]};
};

export const getShapeCoordinates = (geometry: any, ring = false): any => {
  try {
    if (ring) {
      // For a ring (clogged nozzle, irrigation issue) place a popup at the right side
      // of the ring.
      // The inner ring is the geometry with less points than the outer.
      const innerRing =
        geometry.coordinates[0].length > geometry.coordinates[1].length
          ? geometry.coordinates[1]
          : geometry.coordinates[0];
      const outerRing =
        geometry.coordinates[0].length > geometry.coordinates[1].length
          ? geometry.coordinates[0]
          : geometry.coordinates[1];
      let top = -Infinity;
      let bot = Infinity;
      let r1 = -Infinity;
      let r2 = -Infinity;
      innerRing.forEach((p: any) => {
        if (p[0] > r1) r1 = p[0];
        if (p[1] > top) top = p[1];
        if (p[1] < bot) bot = p[1];
      });
      outerRing.forEach((p: any) => {
        if (p[0] > r2) r2 = p[0];
      });
      const hmid = Math.max(r2, r1) - (Math.max(r2, r1) - Math.min(r2, r1)) / 2;
      const vmid = top - (top - bot) / 2;
      return [hmid, vmid];
    }

    switch (geometry?.type) {
      case 'MultiPolygon':
        return polylabel(geometry.coordinates.flat(), 2.0);
      case 'GeometryCollection':
        return getShapeCoordinates(geometry?.geometries[0]); // try to get the lat long from the first geometry
      case 'FeatureCollection':
        return getShapeCoordinates(geometry?.features[0]?.geometry);
      case 'Feature':
        return getShapeCoordinates(geometry?.geometry);
      default:
        return polylabel(geometry.coordinates, 2.0); // simple polygon expected
    }
  } catch (err) {
    reportError(`Error in getShapeCoordinates() geometry type = ${geometry?.type}`);
    return [0, 0];
  }
};

type SeasonProps = {
  id: string;
  fill: string;
  cropSubType: string;
  startDate: string;
  endDate: string;
  cropType: string;
  name: string;
};

export const PLAIN_SEASON_PROPS: SeasonProps = {
  id: '',
  fill: 'red',
  cropSubType: '',
  startDate: '',
  endDate: '',
  cropType: '',
  name: '',
};

export const splitPolygon = (
  polygon: GeoJSON.Polygon,
  line: GeoJSON.LineString
): GeoJSON.Feature<GeoJSON.Polygon>[] => {
  if (polygon.type !== 'Polygon' || line.type !== 'LineString') {
    return [];
  }

  const intersectPoints = lineIntersect(polygon, line);
  const nPoints = intersectPoints.features.length;
  if (nPoints == 0 || nPoints % 2 != 0) {
    return [];
  }

  const offsetLine = lineOffset(line, 0.001, {units: 'kilometers'});

  const polyCoords = [];
  for (let j = 0; j < line.coordinates.length; j++) {
    polyCoords.push(line.coordinates[j]);
  }

  for (let j = offsetLine.geometry.coordinates.length - 1; j >= 0; j--) {
    polyCoords.push(offsetLine.geometry.coordinates[j]);
  }

  polyCoords.push(line.coordinates[0]);

  const thickLineString = lineString(polyCoords);
  const thickLinePolygon = lineToPolygon(thickLineString);

  const clipped = difference(polygon, thickLinePolygon);

  return clipped.geometry.coordinates
    .map((coords: any) => {
      if (coords.length <= 1) {
        return createPolygon(coords);
      }
      try {
        return coords.map((c: any) => createPolygon([c]));
      } catch (e) {
        return createPolygon([coords]);
      }
    })
    .flat();
};

const createPolygon = (coordinates: any) => {
  return turfPolygon(coordinates, {
    ...PLAIN_SEASON_PROPS,
    fill: getRandomColor(),
    id: genKey(),
  });
};

export const pointInUS = (lat: number, lon: number) => pointInside([lon, lat], USCoordinates);
export const pointInFrance = (lat: number, lon: number) =>
  booleanPointInPolygon([lon, lat], polygon(FranceCoordinates));

export const featureCollectionContains = (
  a: GeoJSON.FeatureCollection,
  b: GeoJSON.FeatureCollection
) => {
  return a?.features?.some(fA => b?.features?.some(fB => booleanContains(fA, fB)));
};

export const featureCollectionIntersect = (
  a: GeoJSON.FeatureCollection,
  b: GeoJSON.FeatureCollection,
  // How much of the overlap should be considered as intersection.
  // By default half of the geometry should be overlapped.
  threshold = 0.5
) => {
  const areaA = turfArea(a);
  const areaB = turfArea(b);
  const area = Math.min(areaA, areaB);

  return a?.features?.some(fA =>
    b?.features?.some(fB => {
      // @ts-expect-error TODO (stas): make sure that features A and B are Geometry<Polygon> features and not points.
      const intersection = booleanIntersectField(fA, fB);
      return !!intersection;
      if (!intersection) return false;
      return turfArea(intersection) / area > threshold;
    })
  );
};

export const maskGeometry = (features: GeoJSON.Feature[]) => {
  const outerBounds = new L.LatLngBounds([-90, -360], [90, 360]);
  const outer = [
    outerBounds.getNorthWest(),
    outerBounds.getNorthEast(),
    outerBounds.getSouthEast(),
    outerBounds.getSouthWest(),
  ];

  const polygon: L.LatLng[][] = [outer];

  features.forEach(f => {
    if (f.geometry.type === 'Polygon') {
      const points: L.LatLng[] = [];
      const outer = f.geometry.coordinates[0];
      outer.forEach(latlng => {
        points.push(L.latLng(latlng[1], latlng[0]));
      });
      polygon.push(points);
    } else if (f.geometry.type === 'MultiPolygon') {
      f.geometry.coordinates.forEach(coordinates => {
        const multiPoints: L.LatLng[] = []; // a separate polygon
        coordinates[0].forEach(latlng => {
          multiPoints.push(L.latLng(latlng[1], latlng[0]));
        });
        polygon.push(multiPoints);
      });
    }
  });
  return L.polygon(polygon).toGeoJSON();
};

export const swapLonLatMUTATE = (point: number[]) => {
  const a = point[0];
  point[0] = point[1];
  point[1] = a;
  return point;
};

export const swapLonLatInFeatureMUTATE = (geojson: GeoJSON.Feature) => {
  if (geojson.geometry.type === 'Point') {
    swapLonLatMUTATE(geojson.geometry.coordinates);
  } else if (geojson.geometry.type === 'LineString') {
    geojson.geometry.coordinates.forEach(swapLonLatMUTATE);
  } else if (geojson.geometry.type === 'Polygon') {
    geojson.geometry.coordinates.forEach(outer => {
      outer.forEach(swapLonLatMUTATE);
    });
  } else if (geojson.geometry.type === 'MultiPolygon') {
    geojson.geometry.coordinates.forEach(poly => {
      poly.forEach(outer => {
        outer.forEach(swapLonLatMUTATE);
      });
    });
  }
  return geojson;
};

/**
 * Return overlap field ids. O(n * log(n)).
 */
export const fieldsOverlap = (
  fields: Field[],
  fieldGeometries: AppStore['map']['fieldGeometries']
) => {
  const overlapIds = new Set<number>();
  for (let i = 0; i < fields.length; i++) {
    for (let j = i + 1; j < fields.length; j++) {
      const f1 = fields[i];
      const f2 = fields[j];
      const g1 = fieldGeometries[f1.MD5];
      const g2 = fieldGeometries[f2.MD5];
      const overlaps = featureCollectionIntersect(g1, g2, 0.5);
      if (overlaps) {
        overlapIds.add(f1.ID);
        overlapIds.add(f2.ID);
      }
    }
  }
  return [...overlapIds];
};

export const downloadFieldsBoundary = (md5s: string[], farmId: number, fileName: string) => () => {
  const form = new FormData();
  //@ts-expect-error error leftover from convertion to strict mode, please fix
  form.set('md5', md5s.join(','));

  KmlApi.downloadKml(form, farmId, fileName)
    .then(({data}) => {
      downloadFile(data, `Boundary_${fileName}.zip`, 'application/zip');
    })
    .catch(err => {
      showNotification({
        title: t({id: 'note.error', defaultMessage: 'Error'}),
        message: t({id: 'Cannot download KMLs'}),
        type: 'error',
      });

      reportError(`Error during downloading kmls err=${err}`);
    });
};
