import {schema} from 'normalizr';

// Monitoring --------
export const MonitoringAttributesSchema = new schema.Entity('attributes');

export const MRVStageSchema = new schema.Entity('stages', {
  attributes: [MonitoringAttributesSchema],
});

export const MonitoringConfigSchema = new schema.Entity('monitoringConfigs', {
  stages: [MRVStageSchema],
});

export const EnrollmentConfigSchema = new schema.Entity('enrollmentConfigs', {
  stages: [MRVStageSchema],
});

export const PhaseSchema = new schema.Entity('phases', {
  stages: [MRVStageSchema],
});

export const ProgramSchema = new schema.Entity('programs', {
  phases: [PhaseSchema],
});

export const ProjectFieldsSchema = new schema.Entity('fields');

export const ProjectValuesSchema = new schema.Entity('projectValues', {});

export const ProjectSchema = new schema.Entity('projects', {
  fields: [ProjectFieldsSchema],
  program: ProgramSchema,
  values: [ProjectValuesSchema],
});
