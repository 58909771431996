import React, {useCallback, useEffect, useState} from 'react';
import {useAppSelector} from '_hooks';
import {calculatePercentage} from '_utils/pure-utils';
import {kiloFormatter, toFixedFloat} from '_utils/number-formatters';
import {t} from 'i18n-utils';
import {selectMeasurement} from 'containers/login/login-selectors';
import {TOOLTIPS} from 'containers/si/constants';
import {useFetchKPI} from 'containers/si/hooks/useFetchKPI';
import {useSparklingChart} from 'containers/si/hooks/useSparklingChart';
import {MultiChartKPICard} from '../charts/multi-chart-kpi-card';
import {ChartMiddleData} from '../charts/components/chart-middle-data';
import {SparklineChart} from '../charts/components/sparkline-chart';
import {convertArea} from '@turf/turf';
import {selectCropTypesCDLIdMap} from 'modules/global/selectors';
import {BarChart} from '../charts/components/bar-chart/bar-chart';
import type {BarDataType} from 'containers/si/types';
import {isNull} from '_utils/typeGuards';
import {safeDivision} from 'modules/sustainability-insights/utils';

export const CoverCroppingCard = () => {
  const measurement = useAppSelector(selectMeasurement);
  const cropsById = useAppSelector(selectCropTypesCDLIdMap);

  const [barChartData, setBarChartData] = useState<({secondaryValue: string} & BarDataType)[]>([]);

  const {response: coverResponse, loading} = useFetchKPI({
    kpi: 'cover_crop',
    summarizeBy: ['annualized', 'crop_type'],
    topNCrops: 10,
  });
  const {sparklingChartData, dataset, metric, average, comparisonValues, perArea} =
    useSparklingChart(coverResponse);

  const units = measurement === 'ac' ? 'acres' : 'hectares';

  const getCoverCroppingPercentage = useCallback(
    (coverArea: number, decimals: number = 1) => {
      // Assuming perArea is metric / total crop area
      const totalArea = safeDivision(metric, perArea);

      return !isNull(totalArea)
        ? `${toFixedFloat(calculatePercentage(coverArea, totalArea), decimals)}`
        : '0';
    },
    [metric, perArea]
  );

  const calculateBarChartData = () => {
    if (!coverResponse?.crop_type_summary) return;

    const cropData: ({secondaryValue: string} & BarDataType)[] = Object.entries(
      coverResponse.crop_type_summary
    ).map(([cropType, coverCropping]) => {
      const cropName = cropsById[Number(cropType)]?.label ?? String(cropType);
      const expectedAreaUnit = measurement === 'ac' ? 'acres' : 'hectares';
      const convertedCoverCropping = convertArea(coverCropping, 'meters', expectedAreaUnit);
      const percentage = getCoverCroppingPercentage(convertedCoverCropping, 5);

      return {
        label: cropName,
        value: Number(percentage),
        secondaryValue: `(${kiloFormatter(convertedCoverCropping)})`,
      };
    });
    setBarChartData(cropData);
  };

  useEffect(calculateBarChartData, [
    coverResponse,
    cropsById,
    getCoverCroppingPercentage,
    measurement,
  ]);

  return (
    <MultiChartKPICard
      title={t({id: 'Cover cropping'})}
      subtitle={'area of cropland with cover crop'}
      tooltip={TOOLTIPS.KPI.coverCrops}
      lazyLoad="ondemand"
      loading={loading}
    >
      <div>
        <ChartMiddleData
          cardCenterNumber={`${getCoverCroppingPercentage(metric)}%`}
          cardCenterUnits={
            metric === 0 ? 'No Data' : `${String(kiloFormatter(metric)).toUpperCase()} ${units}`
          }
        />
        <SparklineChart
          tooltipCallbacks={{
            beforeLabel: item => `${getCoverCroppingPercentage(Number(item.value))}%`,
          }}
          comparisonValues={comparisonValues}
          sparklingChartCaption={`${sparklingChartData.datasets.length} year avg`}
          sparklingChartCaptionBolded={kiloFormatter(average)}
          chartData={{labels: sparklingChartData.labels, datasets: dataset}}
          chartDataMidline={average}
          linechartLabelSuffix={units}
        />
      </div>
      <BarChart
        data={barChartData}
        colors={['#CC639C']}
        kiloConvert
        unit="pct"
        margin={{right: 80}}
      />
    </MultiChartKPICard>
  );
};
