import {Flex, FluroButton, Text} from 'components';
import {FluroProgressIcon} from 'components/fluro-progress-icon/fluro-progress-icon';
import {CheckIcon} from 'components/icons';
import type {MRVSurveySubmission} from 'containers/mrv/types';
import {t} from 'i18n-utils';
import React, {memo, useEffect} from 'react';
import styled from 'styled-components';
import {StageCustomView} from './base';

export const SurveyView = memo(
  ({
    surveyId,
    loading,
    pdfUrl,
    onSubmit,
  }: {
    surveyId: string;
    loading: boolean;
    pdfUrl?: string;
    onSubmit: (submissionId: string) => void;
  }) => {
    useEffect(() => {
      const script = document.createElement('script');
      script.src = 'https://paperform.co/__embed.min.js';
      document.body.appendChild(script);
      const handleSubmit = (e: CustomEvent<MRVSurveySubmission>) => {
        const submissionId = e.detail.submission_id;
        onSubmit(submissionId);
      };
      document.addEventListener('PaperformSubmission', handleSubmit);
      return () => {
        document.removeEventListener('PaperformSubmission', handleSubmit);
      };
    }, []);

    if (loading || pdfUrl) {
      return (
        <StageCustomView
          illustration={<img src={'/assets/mrv/survey_illustration.svg'} alt="survey" />}
        >
          {pdfUrl ? (
            <Flex direction="column" gap="10px">
              <Flex alignItems="center" gap="10px">
                <FluroProgressIcon
                  icon={<CheckIcon style={{width: 30, height: 30}} />}
                  outlined
                  active
                  progressValue={100}
                  hideProgressLabel={true}
                  id="success-survey"
                />
                <Text variant="h3" inline>
                  {t({id: 'Survey complete!'})}
                </Text>
              </Flex>
              <FluroButton raised primary onClick={() => showResults(pdfUrl)}>
                {t({id: 'Download PDF'})}
              </FluroButton>
            </Flex>
          ) : (
            <FluroButton raised primary loading>
              {t({id: 'Loading...'})}
            </FluroButton>
          )}
        </StageCustomView>
      );
    }

    return <Content data-paperform-id={surveyId} data-spinner={true} />;
  }
);

const showResults = (pdfUrl: string) => window.open(pdfUrl, '_blank');

const Content = styled.div`
  iframe {
    max-height: 600px !important;
    margin-top: 24px;
  }
`;
