import type {FluroSelectLiteItem} from 'components/fluro-select-lite/fluro-select-lite';
import {t} from 'i18n-utils';

export type MetricYieldRateUnit = 'kg/ha' | 't/ha';
export type ImperialYieldRateUnit = 'bu/ac' | 'T/ac' | 'lb/ac';

export const yieldRateUnitOptions: {
  metric: {label: string; value: MetricYieldRateUnit}[];
  imperial: {label: string; value: ImperialYieldRateUnit}[];
} = {
  metric: [
    {label: t({id: 'Unit.kg/ha', defaultMessage: 'kg/ha'}), value: 'kg/ha'},
    {label: t({id: 'Unit.t/ha', defaultMessage: 't/ha'}), value: 't/ha'},
  ],
  imperial: [
    {label: t({id: 'Unit.bu/ac', defaultMessage: 'bu/ac'}), value: 'bu/ac'},
    {label: t({id: 'Unit.T/ac', defaultMessage: 'T/ac'}), value: 'T/ac'},
    {label: t({id: 'Unit.lb/ac', defaultMessage: 'lb/ac'}), value: 'lb/ac'},
  ],
};

export type MetricAppRateUnit = 'kg1ha-1' | 'nkg1ha-1' | 'l1ha-1' | 't1ha-1';
export type ImperialAppRateUnit =
  | 'lb1ac-1'
  | 'nlb1ac-1'
  | 'gal1ac-1'
  | 'qt1ac-1'
  | 'flo1ac-1'
  | 'pt1ac-1';
export type AppRateCategory = 'mass' | 'volume';

export const nutrientManagementAppRateUnitOptions: {
  metric: FluroSelectLiteItem<MetricAppRateUnit, AppRateCategory>[];
  imperial: FluroSelectLiteItem<ImperialAppRateUnit | AppRateCategory, AppRateCategory>[];
} = {
  metric: [
    {
      label: t({id: 'Unit.t/ha', defaultMessage: 't/ha'}),
      value: 't1ha-1',
      category: 'mass',
    },
    {
      label: t({id: 'Unit.Kg/ha', defaultMessage: 'Kg/ha'}),
      value: 'kg1ha-1',
      category: 'mass',
    },
    {
      label: t({id: 'Unit.Kg N/ha', defaultMessage: 'Kg N/ha'}),
      value: 'nkg1ha-1',
      category: 'mass',
    },
    {
      label: t({id: 'Unit.L/ha', defaultMessage: 'L/ha'}),
      value: 'l1ha-1',
      category: 'volume',
    },
  ],
  imperial: [
    {
      label: t({id: 'Unit.Mass', defaultMessage: 'Mass'}),
      value: 'mass',
      isHeading: true,
    },
    {
      label: t({id: 'Unit.Lbs/ac', defaultMessage: 'Lbs/ac'}),
      value: 'lb1ac-1',
      category: 'mass',
    },
    {
      label: t({id: 'Unit.Lbs N/ac', defaultMessage: 'Lbs N/ac'}),
      value: 'nlb1ac-1',
      category: 'mass',
    },
    {
      label: t({id: 'Unit.Volume', defaultMessage: 'Volume'}),
      value: 'volume',
      isHeading: true,
    },
    {
      label: t({id: 'Unit.Gal/ac', defaultMessage: 'Gal/ac'}),
      value: 'gal1ac-1',
      category: 'volume',
    },
    {
      label: t({id: 'Unit.Qt/ac', defaultMessage: 'Qt/ac'}),
      value: 'qt1ac-1',
      category: 'volume',
    },
    {
      label: t({id: 'Unit.Pt/ac', defaultMessage: 'Pt/ac'}),
      value: 'pt1ac-1',
      category: 'volume',
    },
    {
      label: t({id: 'Unit.Fl .oz/ac', defaultMessage: 'Fl .oz/ac'}),
      value: 'flo1ac-1',
      category: 'volume',
    },
  ],
};

export type MetricIrrigationRateUnit = 'mm' | 'mm1ha-1' | 'mL1ha-1';
export type ImperialIrrigationRateUnit = 'in' | 'in1ac-1' | 'oz1ac-1';

export const irrigationRateUnitOptions: {
  metric: FluroSelectLiteItem<MetricIrrigationRateUnit, AppRateCategory>[];
  imperial: FluroSelectLiteItem<ImperialIrrigationRateUnit | AppRateCategory, AppRateCategory>[];
} = {
  metric: [
    {
      label: t({id: 'mm', defaultMessage: 'mm'}),
      value: 'mm',
    },
    {
      label: t({id: 'mm/ha', defaultMessage: 'mm/ha'}),
      value: 'mm1ha-1',
    },
    {
      label: t({id: 'mL/ha', defaultMessage: 'mL/ha'}),
      value: 'mL1ha-1',
    },
  ],
  imperial: [
    {
      label: t({id: 'Unit.in', defaultMessage: 'in'}),
      value: 'in',
    },
    {
      label: t({id: 'Unit.in/ac', defaultMessage: 'in/ac'}),
      value: 'in1ac-1',
    },
    {
      label: t({id: 'oz/ac', defaultMessage: 'oz/ac'}),
      value: 'oz1ac-1',
    },
  ],
};
