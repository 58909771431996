import React, {useMemo, useState} from 'react';
import {FluroDialog, Flex, FluroButton, Text, FluroChip} from 'components';
import type {Practice} from '../../../types';
import {EligibilityMethod} from '../../../types';
import {PracticePreviewCircles} from 'components/practice-select/practice-preview-circles';
import cn from 'classnames';
import {useTranslation} from 'i18n-utils';
import {PracticeAvatar} from 'components/practice-select/practice-avatar';

type AssignPracticesDialogProps = {
  onHide: () => void;
  onRemoveField: () => void;
  onAssignPractices: (practices: Practice[]) => void;
  practicesItems: Practice[][];
  fieldPractices: Practice[];
  eligibilityMethod: EligibilityMethod;
};
export function AssignPracticesDialog({
  onHide,
  onRemoveField,
  onAssignPractices,
  practicesItems,
  fieldPractices,
  eligibilityMethod,
}: AssignPracticesDialogProps) {
  const [selectedPractices, setSelectedPractices] = useState<Practice[]>(fieldPractices || []);
  const [loading, setLoading] = useState(false);
  const t = useTranslation();

  const individualPractices = useMemo(
    () => practicesItems.filter(p => p.length === 1).flat(),
    [practicesItems]
  );

  function assignPractices() {
    onAssignPractices(selectedPractices);
    setLoading(true);
  }

  function removeField() {
    onRemoveField();
    onHide();
  }

  return (
    <FluroDialog
      portal
      title={t({id: 'EnrollmentStep.Assign Practices', defaultMessage: 'Assign Practices'})}
      onHide={onHide}
      visible={true}
      id={'field-assign-practices-dialog'}
      className={'field-assign-practices-dialog'}
      width={400}
    >
      <Text elementType={'div'} variant={'medium'} className={'margin-bottom-10'}>
        {t({id: 'Assign new or expanded practice plan for the next growing season.'})}
      </Text>
      {/* TODO Use this experience for everything? */}
      {eligibilityMethod === EligibilityMethod.AlwaysEligible ? (
        <Flex gap={8} direction="column">
          {individualPractices.map(practice => {
            const selected = selectedPractices.includes(practice);

            return (
              <FluroChip
                key={practice}
                label={t({id: `Option.Practice.${practice}`, defaultMessage: practice})}
                onClick={() =>
                  setSelectedPractices(state => {
                    if (selected) {
                      return state.filter(p => p !== practice);
                    }

                    return [...state, practice];
                  })
                }
                active={selected}
                leftIcon={<PracticeAvatar value={practice} />}
                style={{width: 'min-content'}}
                hasToggleIcon
                noMargin
              />
            );
          })}
        </Flex>
      ) : (
        practicesItems.map(practicesItem => {
          const practices = practicesItem
            .map(p => t({id: `Option.Practice.${p}`, defaultMessage: p}))
            .join(' + ');
          const selected =
            practicesItem.length === selectedPractices.length &&
            selectedPractices.every(p => practicesItem.includes(p));

          return (
            <div
              onClick={() => setSelectedPractices(practicesItem)}
              className={cn('practice-item', {selected})}
              key={practices}
            >
              <Text elementType={'span'} variant={'medium'}>
                {practices}
              </Text>
              <PracticePreviewCircles id={practicesItem.join()} value={practicesItem} />
            </div>
          );
        })
      )}

      <Flex gap={'10px'} className={'margin-top-15'} fullWidth justifyContent={'flex-end'}>
        <FluroButton blank raised onClick={removeField}>
          {t({id: 'BtnLabel.RemoveField', defaultMessage: 'Remove field'})}
        </FluroButton>
        <FluroButton
          disabled={!selectedPractices.length || loading}
          loading={loading}
          primary
          raised
          onClick={assignPractices}
        >
          {t({id: 'EnrollmentStep.Assign Practices', defaultMessage: 'Assign Practices'})}
        </FluroButton>
      </Flex>
    </FluroDialog>
  );
}
