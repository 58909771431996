import React, {createContext, useCallback, useEffect, useMemo, useState} from 'react';

/**
 * Provides the handle to highlight the incomplete cells and the highlighting status.
 */
export type NavigationHighlightContext = {
  highlighting: boolean;
  highlightUntouchedCells: () => void;
};

export const navigationHighlightContext = createContext<NavigationHighlightContext>({
  highlighting: false,
  highlightUntouchedCells: () => undefined,
});

export const NavigationHighlightContextProvider = (props: React.PropsWithChildren<{}>) => {
  // Increment this number each time you need to blink the cells.
  const [untouchedCellsHighlightRefresher, setUntouchedCellsHighlightRefresher] = useState(0);
  const [highlighting, setHighlighting] = useState(false);

  useEffect(() => {
    setHighlighting(true);
    // 700 is a bit longer than the blinking animation (500), so it doesn't stop abruptly in case of a minor lag.
    const timeout = setTimeout(() => setHighlighting(false), 700);
    return () => clearTimeout(timeout);
  }, [untouchedCellsHighlightRefresher]);

  const highlightUntouchedCells = useCallback(
    () => setUntouchedCellsHighlightRefresher(x => x + 1),
    []
  );

  const value = useMemo(
    () => ({
      highlighting,
      highlightUntouchedCells,
    }),
    [highlighting, highlightUntouchedCells]
  );

  return (
    <navigationHighlightContext.Provider value={value}>
      {props.children}
    </navigationHighlightContext.Provider>
  );
};
