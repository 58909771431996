// @ts-nocheck
import {FarmApi, SubscriptionsApi, UsersApi} from '_api';
import type {Farm, Organization, SetUsersAction, Subscription, User} from './subscriptions.types';
import {ActionType, SubscriptionEntityType} from './subscriptions.types';
import type {Dispatch} from 'redux';

import {dialogToggle, DialogType} from 'modules/helpers';

import {sortByKey} from '_utils/sorters';

/*
 *
 * Load Subscriptions/Organizations/Farms
 * NOTE: This function is not using redux flow for pass props
 *
 * */
export const loadList =
  (type = SubscriptionEntityType.Subscription, id?: number) =>
  (dispatch: any) => {
    // Promise<Subscription[] | Organization[] | Farm[]>
    switch (type) {
      case SubscriptionEntityType.Subscription:
        return SubscriptionsApi.loadSubscriptions().then(response => {
          const {data} = response;

          const subscriptions = (
            (Array.isArray(data.result) ? data.result : []) as Subscription[]
          ).map(s => ({
            ...s,
            type: SubscriptionEntityType.Subscription,
          }));

          dispatch({
            type: ActionType.SetEntities,
            entities: sortByKey(subscriptions, 'name', false),
            entityType: type,
            levelLoadId: id,
          });
        });

      case SubscriptionEntityType.Organization:
        return SubscriptionsApi.loadOrganizations(id).then(response => {
          const {data} = response;
          const organizations = (
            (Array.isArray(data.result) ? data.result : []) as Organization[]
          ).map(s => ({
            ...s,
            type: SubscriptionEntityType.Organization,
          }));

          dispatch({
            type: ActionType.SetEntities,
            entities: sortByKey(organizations, 'name', false),
            entityType: type,
            levelLoadId: id,
          });
        });

      case SubscriptionEntityType.Farm:
      default:
        return Promise.all([
          SubscriptionsApi.loadFarms(id),
          // we need to load organizations to eject subscriptions names to add subscription name to each farm object
          SubscriptionsApi.loadOrganizations(),
        ]).then(response => {
          const {data} = response[0];
          const orgs = response[1]?.data?.result || [];
          const orgsObj: {[orgId: number]: string} = {};

          for (let i = 0; i < orgs.length; i++) {
            orgsObj[orgs[i].id] = orgs[i].subscription_name;
          }

          const farms = ((Array.isArray(data.result) ? data.result : []) as Farm[]).map(s => ({
            ...s,
            type: SubscriptionEntityType.Farm,
            subscription_name: orgsObj[s.organization_id],
          }));

          dispatch({
            type: ActionType.SetEntities,
            entities: sortByKey(farms, 'name', false),
            entityType: type,
            levelLoadId: id,
          });
        });
    }
  };

/*
 *
 * load all users for user autocomplete input at the adding role component
 *
 * */
export const getUsers = () => (dispatch: Dispatch<SetUsersAction>) => {
  return UsersApi.getAll().then(({data}) => {
    const users = (data.result || []).map((user: User) => ({
      id: user.id,
      email: user.email,
      name: user.name || '',
      surname: user.surname || '',
    }));

    dispatch({
      type: ActionType.SetUsers,
      users,
    });
  });
};

export const assignFarmsToOrganization =
  (ids: number[], organization_id: number, isEmbedView = false) =>
  (dispatch: any) => {
    return SubscriptionsApi.addFarm(ids.map(farm_id => ({organization_id, farm_id}))).then(() => {
      if (!isEmbedView) {
        dispatch(dialogToggle(DialogType.adminAssignOrganizationToFarm, false));
        loadList(SubscriptionEntityType.Farm)(dispatch);
      }
    });
  };

export const assignOrganizationToSubscription =
  (orgs: Organization[], subscription_id: number, isEmbedView = false) =>
  (dispatch: any) => {
    return Promise.all(
      orgs.map(org =>
        SubscriptionsApi.updateOrganization(org.id, {name: org.name, subscription_id})
      )
    ).then(() => {
      if (!isEmbedView) {
        dispatch(dialogToggle(DialogType.adminAssignSubscriptionToOrganization, false));
        loadList(SubscriptionEntityType.Organization)(dispatch);
      }
    });
  };

export const createFarmWithOrganization =
  (organization_id: number, name: string, isEmbedView = false) =>
  (dispatch: any) => {
    return FarmApi.saveFarm({name, description: ''}).then(({data}) => {
      const farm_id = data?.result?.id;

      if (farm_id) {
        // attach new farm to organization
        SubscriptionsApi.addFarm([{organization_id, farm_id}]).then(() => {
          dispatch(dialogToggle(DialogType.adminSubscriptionsCreateFarm, false));

          if (!isEmbedView) {
            loadList(SubscriptionEntityType.Farm)(dispatch);
          }

          // for reload main farms list after subscriptions component will unmount
          dispatch({type: ActionType.HasFarmsUpdate, value: true});
        });
      } else {
        dispatch(dialogToggle(DialogType.adminSubscriptionsCreateFarm, false));
      }
    });
  };

export const clearCache = () => ({
  type: ActionType.ClearCache,
});
