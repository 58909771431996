import {Flex} from 'components';
import {FluroChipDropdownControllable as FluroChipDropdown} from 'components/fluro-dropdown/fluro-chip-dropdown';
import type {ComponentType} from 'react';
import React, {useState} from 'react';
import {SelectionControl} from 'react-md';
import styled from 'styled-components';
import type {Option} from 'types';

type Props = {
  onChange: any;
  options: Option[];
  selected: Option;
};

export const AreaSelect: ComponentType<Props> = ({options, selected, onChange, children}) => {
  const [expand, setExpand] = useState(false);

  const handleChange = (_: any, event: Event) => {
    const {value: aggLevel} = event.target as HTMLInputElement;
    onChange(aggLevel, event);
    setExpand(false);
  };

  const {value: selectedValue, label} = selected;

  return (
    <Wrapper>
      <FluroChipDropdown className="mr-1" expand={expand} setExpand={setExpand} label={label}>
        {options.map(({label, value}) => (
          <SelectionControl
            key={value}
            type="radio"
            checked={selectedValue === value}
            name="area-type"
            id={`select-area-type-${value}`}
            value={value}
            label={label}
            onChange={handleChange}
          />
        ))}
      </FluroChipDropdown>
      <Flex alignItems="center" nowrap>
        {children}
      </Flex>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  max-width: 320px;

  .area-type-selector {
    .fluro-button {
      text-transform: unset;
      font-size: 1rem;
    }
  }

  .md-list {
    font-size: 13px;
  }

  .disabled .md-fake-btn {
    opacity: 0.5;
    cursor: default;
  }
`;
