import {useLeafletContext} from '@react-leaflet/core';
import {EditFieldControlButtons} from 'components/edit-field-control-buttons';
import {toggleEditingMode2, toggleMapBar, updateFieldGeometries} from 'containers/map/actions';
import type {ComponentType} from 'react';
import React, {useCallback} from 'react';
import {useDispatch} from 'react-redux';
import {useAppSelector} from '_hooks';
import {saveEditedField} from '../carbon-store';

export const CarbonEditFieldButtonsContainer: ComponentType<{}> = () => {
  const map = useLeafletContext().map;
  const dispatch = useDispatch();
  const isEditingMode = useAppSelector(s => s.map.drawControl.isEditingMode);
  const fieldId = useAppSelector(s => s.map.drawControl.fieldId);
  const fieldGeometries = useAppSelector(s => s.map.fieldGeometries);

  const onCancel = useCallback(() => {
    map.pm.disableGlobalEditMode();
    dispatch(toggleEditingMode2(false));

    // The draw lib do not support undo changes for edited shapes, in this case we use hack -
    // trigger redraw geometries overlay to undo all layer changes.
    // TODO: find more soft way to undo changes
    dispatch(updateFieldGeometries({...fieldGeometries}));

    dispatch(toggleMapBar(true));
  }, [fieldGeometries, map.pm]);

  const onSave = useCallback(() => {
    map.pm.disableGlobalEditMode();
    dispatch(toggleEditingMode2(false));

    map.eachLayer(layer => {
      //@ts-expect-error error leftover from convertion to strict mode, please fix
      if (layer.feature?.properties?.fluro_id === fieldId) {
        //@ts-expect-error error leftover from convertion to strict mode, please fix
        const data = layer.toGeoJSON();
        dispatch(saveEditedField(data));
      }
    });

    dispatch(toggleMapBar(true));
  }, [map, fieldId]);

  return isEditingMode ? <EditFieldControlButtons onSave={onSave} onCancel={onCancel} /> : null;
};
