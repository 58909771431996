import type {ComponentType} from 'react';
import React, {useState} from 'react';
import {batch} from 'react-redux';
import {useAppDispatch, useAppSelector} from '_hooks';
import {FluroChipDropdownControllable} from 'components/fluro-dropdown/fluro-chip-dropdown';
import {FluroSelectionControlGroup} from 'components/fluro-selection-control-group/fluro-selection-control-group';
import {
  setDashboardOrderBy,
  setDashboardOrderDirection,
  setDashboardPageIndex,
} from 'containers/si/module/reducer';
import {selectReportOrderBy, selectReportType} from 'containers/si/module/selectors';
import {SIScenarioKPIs} from 'containers/si/types';
import {
  REPORT_DASHBOARD_RANK_OPTIONS_AP,
  REPORT_DASHBOARD_RANK_OPTIONS_PP,
  SIScenarioKPIsLabel,
} from 'containers/si/constants';

export const ScenarioOrderDropdown: ComponentType<{}> = () => {
  const [expand, setExpand] = useState(false);
  const dispatch = useAppDispatch();
  const value = useAppSelector(selectReportOrderBy);
  const reportType = useAppSelector(selectReportType);

  const handleChange = (selection: SIScenarioKPIs) => {
    setExpand(false);
    if (selection === SIScenarioKPIs.soc_removals) {
      batch(() => {
        dispatch(setDashboardOrderBy(SIScenarioKPIs.soc_removals));
        dispatch(setDashboardOrderDirection('asc'));
        dispatch(setDashboardPageIndex(0));
      });
      return;
    } else {
      batch(() => {
        dispatch(setDashboardOrderBy(selection));
        dispatch(setDashboardOrderDirection('desc'));
        dispatch(setDashboardPageIndex(0));
      });
    }
  };

  return (
    <FluroChipDropdownControllable
      expand={expand}
      className="scenario-order-chip-dropdown"
      label={SIScenarioKPIsLabel[value]}
      setExpand={setExpand}
    >
      <FluroSelectionControlGroup
        id="operation-status-radio"
        name="operationStatus"
        type="radio"
        value={value}
        onChange={v => handleChange(v as SIScenarioKPIs)}
        controls={
          reportType === 'abatement_potential'
            ? REPORT_DASHBOARD_RANK_OPTIONS_AP
            : REPORT_DASHBOARD_RANK_OPTIONS_PP
        }
      />
    </FluroChipDropdownControllable>
  );
};
