import {
  Flex,
  FluroButton,
  FluroDataTable,
  FluroTableBody,
  FluroTableColumn,
  FluroTableHeader,
  FluroTableRow,
  TableSubtext,
  Text,
} from 'components';
import type {ComponentType} from 'react';
import React, {useEffect, useMemo, useState} from 'react';
import {CircularProgress, FontIcon} from 'react-md';
import SIApi from 'containers/si/api/si';
import {useAppDispatch, useAppSelector} from '_hooks';
import {calcPlural} from '_utils/pure-utils';
import {AddUserToSIProgramPopUp} from '../components/add-user-to-si-program-pop-up';
import {fetchSIProgramAdmins, removeSIProgramUser} from '../../module/thunks';
import {selectSIProgramAdminsByProgramId} from '../../module/selectors';
import {selectIsAdmin, selectMeasurement} from '../../../login/login-selectors';
import {selectIsLoading} from 'modules/helpers/selectors';
import {showNotification} from 'components/notification/notification';
import {SISearchInput} from 'containers/si/components/si-search-input';
import {SIDialogPopup} from 'containers/si/components/si-dialog-popup';
import type {AddSIUserToAProgramFormData} from 'containers/si/types';
import {ImpersonateButton} from 'containers/admin/users/impersonate-button';
import {ActionType} from '../../module/types';
import {isDefined} from '_utils/typeGuards';

type Props = {
  selectedProgramId: number;
};

export const SIProgramAdmins: ComponentType<Props> = ({selectedProgramId}) => {
  const dispatch = useAppDispatch();
  const isAdmin = useAppSelector(selectIsAdmin);
  const programAdminsList = useAppSelector(s =>
    selectSIProgramAdminsByProgramId(s, selectedProgramId)
  );
  const isLoadingAdmins = useAppSelector(s =>
    selectIsLoading(s, [ActionType.FETCH_SI_PROGRAM_ADMINS])
  );
  const [bulkAddProgramAdminPopupVisible, setAddProgramAdminPopupVisible] = useState(false);
  const [deleteProgramAdminData, setDeleteProgramAdminData] = useState<{
    programId: number;
    userId: number;
  } | null>(null);
  const measurement = useAppSelector(selectMeasurement);
  const [searchText, setSearchText] = useState('');

  useEffect(() => {
    dispatch(fetchSIProgramAdmins(selectedProgramId));
  }, [dispatch, selectedProgramId]);

  const onAddProgramAdmin = async (formData: AddSIUserToAProgramFormData) => {
    setAddProgramAdminPopupVisible(false);
    try {
      formData.programs.map(async programId => {
        await SIApi.addProgramUser(programId, formData.user_id, 'admin');
        dispatch(fetchSIProgramAdmins(selectedProgramId));
      });

      if (formData.programs.length > 1) {
        showNotification({
          type: 'success',
          title: 'Success',
          message: `A new admin was added to the ${calcPlural('program', formData.programs)}.`,
        });
      } else {
        showNotification({
          type: 'success',
          title: 'Success',
          message: `The ${formData.email} was invited as an admin to the program.`,
        });
      }
    } catch (error) {
      showNotification({
        type: 'error',
        title: 'Error',
        message: `Couldn't add admin to the program`,
      });
    }
  };

  const onRemoveProgramAdmin = () => {
    if (isDefined(deleteProgramAdminData)) {
      dispatch(
        removeSIProgramUser({
          programId: deleteProgramAdminData.programId,
          user_id: deleteProgramAdminData.userId,
          role: 'admin',
        })
      );
      showNotification({
        type: 'success',
        title: 'Success',
        message: 'The program admin was removed.',
      });
      setDeleteProgramAdminData(null);
    } else {
      showNotification({
        type: 'error',
        title: 'Error',
        message: 'The program admin could not be removed.',
      });
    }
  };

  const preparedProgramAdminsList = useMemo(() => {
    return programAdminsList.map(p => ({
      ...p,
      loweredFirstName: (p.first_name || '').toLowerCase(),
      loweredLastName: (p.last_name || '').toLowerCase(),
      loweredEmail: p.email.toLowerCase(),
    }));
  }, [programAdminsList]);

  const filteredProgramAdminsList = useMemo(() => {
    const lowerSearchString = searchText.toLowerCase();

    return preparedProgramAdminsList.filter(
      p =>
        p.loweredFirstName.includes(lowerSearchString) ||
        p.loweredLastName.includes(lowerSearchString) ||
        p.loweredEmail.includes(lowerSearchString)
    );
  }, [preparedProgramAdminsList, searchText]);

  return (
    <div className={'margin-bottom-auto'}>
      <Text variant="h1" secondary>
        Program admins {programAdminsList.length !== 0 && `(${programAdminsList.length})`}
      </Text>
      <Flex justifyContent="space-between" className="mb-1">
        <SISearchInput
          isLoading={isLoadingAdmins}
          value={searchText}
          onChange={setSearchText}
          placeholder={'Search Program Admins'}
        />
        {isAdmin && (
          <FluroButton primary raised onClick={() => setAddProgramAdminPopupVisible(true)}>
            Add new program admin
          </FluroButton>
        )}
      </Flex>
      {filteredProgramAdminsList.length > 0 ? (
        <FluroDataTable>
          <FluroTableHeader>
            <FluroTableRow>
              <FluroTableColumn>
                <div>Name</div>
                <TableSubtext>Email</TableSubtext>
              </FluroTableColumn>
              <FluroTableColumn type={'date'}>Last login</FluroTableColumn>
              <FluroTableColumn># {measurement} enrolled</FluroTableColumn>
            </FluroTableRow>
          </FluroTableHeader>

          <FluroTableBody>
            {filteredProgramAdminsList.map(admin => {
              return (
                <FluroTableRow key={admin.user_id}>
                  <FluroTableColumn>
                    <div>
                      {admin?.first_name} {admin?.last_name}
                    </div>
                    <TableSubtext>{admin?.email}</TableSubtext>
                  </FluroTableColumn>

                  <FluroTableColumn type={'date'}>{admin?.last_login_time || ''}</FluroTableColumn>

                  <FluroTableColumn>
                    -{/*{toFixedFloat(convert(admin.area_ha).from('ha').to(measurement), 1)}*/}
                  </FluroTableColumn>

                  <FluroTableColumn>
                    <Flex gap="10px" nowrap justifyContent="flex-end">
                      {isAdmin && (
                        <ImpersonateButton
                          email={admin?.email}
                          userId={Number(admin?.user_id)}
                          variant="button"
                        />
                      )}
                      <FluroButton
                        icon
                        iconEl={<FontIcon>delete</FontIcon>}
                        onClick={() =>
                          setDeleteProgramAdminData({
                            programId: selectedProgramId,
                            userId: admin.user_id,
                          })
                        }
                      />
                    </Flex>
                  </FluroTableColumn>
                </FluroTableRow>
              );
            })}
          </FluroTableBody>
        </FluroDataTable>
      ) : null}
      {isLoadingAdmins ? (
        <CircularProgress className={'progress'} id={'si-admins-table'} />
      ) : filteredProgramAdminsList.length === 0 ? (
        programAdminsList.length !== 0 && searchText ? (
          `No program admins found for search = ${searchText}`
        ) : (
          'There are currently no admins for the current program.'
        )
      ) : null}
      {bulkAddProgramAdminPopupVisible && (
        <AddUserToSIProgramPopUp
          title={'Add new admin'}
          onHide={() => setAddProgramAdminPopupVisible(false)}
          onAdd={onAddProgramAdmin}
          defaultProgramId={selectedProgramId}
          userToAddType={'admin'}
        />
      )}
      {!!deleteProgramAdminData && (
        <SIDialogPopup
          title={'Remove program admin'}
          text={
            <div>
              This individual will lose it’s program admin access to the platform, but Project and
              User Accounts that this individual manages will remain intact.
            </div>
          }
          onHide={() => setDeleteProgramAdminData(null)}
          onConfirm={() => onRemoveProgramAdmin()}
          saveText="Remove program admin"
        />
      )}
    </div>
  );
};
