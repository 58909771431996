// @ts-nocheck
import type {
  FeatureAction,
  FField,
  AdminFeaturesStore,
  SwitchBulkFeatureAction,
  SwitchFeatureAction,
  SeasonWithFeatures,
} from './types';
import {ActionType, FeatureEntity} from './types';

const initialState: AdminFeaturesStore = {
  tagKeys: [],
  fields: [],
  result: [],
  entities: [],
  farms: [],
  rawFieldsData: [],
  SIPolices: [],
  SIData: {countiesIds: [], meta: {}, statesIds: []},
};

const adminFeaturesReducer = (state = initialState, action: FeatureAction) => {
  switch (action.type) {
    case ActionType.SET_TAG_KEYS:
      return {
        ...state,
        tagKeys: action.tagKeys,
      };

    case ActionType.SWITCH_FEATURE:
      return {
        ...state,
        fields: updatePremiumAppsFields(state.fields, action),
        result: updatePremiumAppsFields(state.result, action),
      };

    case ActionType.BULK_SWITCH_FEATURE:
      return {
        ...state,
        fields: [...updateBulkPremiumAppsFields(state.fields, action)],
        result: updateBulkPremiumAppsFields(state.result, action),
      };

    case ActionType.SET_SEARCH_RESULT:
      return {
        ...state,
        fields: action.fields,
      };

    // TODO: maybe redundant
    case ActionType.SET_ENTITIES:
      return {
        ...state,
        entities: action.entities,
      };

    case ActionType.SET_ACTIVATION_RESULT:
      return {
        ...state,
        result: [...action.result],
      };

    case ActionType.SET_FARMS_RESULT:
      return {
        ...state,
        farms: [...action.farms],
        rawFieldsData: action.rawFieldsData,
      };

    case ActionType.SET_EMAILS:
      return {
        ...state,
        entities: state.entities.map((entity: any) => {
          if (entity.id === action.entityId) {
            return {...entity, value: JSON.stringify({emails: action.emails})};
          }

          return entity;
        }),
        result: updateFarmsEmails(state.result, action),
        farms: updateFarmsEmails(state.farms, action),
      };

    case ActionType.EXTEND_ENTITIES:
      return {
        ...state,
        entities: [...state.entities, ...action.entities],
      };

    case ActionType.SET_SELECTOR_SEASON: {
      const entity = state.entities.find(
        (entity: any) => entity.entity_id === action.season.seasonId
      );
      let emails: string[] = [];
      let entityId = 0;

      if (entity) {
        try {
          entityId = entity.id;

          const data = JSON.parse(entity.value);
          emails = data?.emails || [];
        } catch (e) {}
      }

      return {
        ...state,
        farms: state.farms.map(r => {
          if (r.farmId === action.farmId && r.fieldId === action.fieldId) {
            return {...r, ...action.season, entityId, emails};
          }

          return r;
        }),
        result: state.result.map(r => {
          if (r.farmId === action.farmId && r.fieldId === action.fieldId) {
            return {...r, ...action.season, entityId, emails};
          }

          return r;
        }),
      };
    }

    case ActionType.SI_TAGGING_LOAD_POLICES: {
      return {
        ...state,
        SIPolices: action.polices.map(policy => ({...policy, agg_levels: policy.agg_levels || []})),
      };
    }

    case ActionType.SI_TAGGING_UPDATE_POLICY: {
      // updating can create a new policy
      const updatedPolices = [...state.SIPolices];

      action.policy.user_ids.forEach(userId => {
        const updatedOrNewPolicy = {
          user_id: userId,
          agg_levels: action.policy.agg_levels,
          states: action.policy.states,
          years: action.policy.years,
          metric_groups: action.policy.metric_groups,
        };

        const existingPolicyIndex = updatedPolices.findIndex(policy => policy.user_id === userId);

        if (existingPolicyIndex !== -1) {
          updatedPolices[existingPolicyIndex] = updatedOrNewPolicy; // update existing
        } else {
          updatedPolices.push(updatedOrNewPolicy); // add new
        }
      });

      return {
        ...state,
        SIPolices: updatedPolices,
      };
    }

    case ActionType.SI_TAGGING_DELETE_POLICY: {
      return {
        ...state,
        SIPolices: state.SIPolices.filter(policy => policy.user_id !== action.userId),
      };
    }

    case ActionType.SI_TAGGING_LOAD_ALL_STATES: {
      return {
        ...state,
        SIData: {...action.payload},
      };
    }

    default:
      return state;
  }
};

export default adminFeaturesReducer;

function updateFarmsEmails(farms: any[], action: any) {
  return farms.map((farm: any) => {
    if (farm[`${action.entityType}Id`] === action.farmOrSeasonId) {
      return {
        ...farm,
        entityId: action.entityId,
        emails: [...action.emails],
        manualReview: action.manualReview,
        header_logo_url: action.header_logo_url,
      };
    }
    return farm;
  });
}

function updatePremiumAppsFields(fields: FField[], action: SwitchFeatureAction) {
  if (action.entityType === FeatureEntity.Field) {
    return fields.map(field => {
      if (field.fieldId === action.entityId) {
        field.features[action.key].id = action.keyId;
        field.features[action.key].value = action.value;

        return {...field};
      }

      return field;
    });
  }

  if (action.entityType === FeatureEntity.Season) {
    return fields.map(field => {
      return {
        ...field,
        seasons: (field.seasons || []).map(season => {
          if (season.id === action.entityId) {
            season.features[action.key].id = action.keyId;
            season.features[action.key].value = action.value;

            return {...season};
          }

          return season;
        }),
      };
    });
  }
}

function updateBulkPremiumAppsFields(fields: FField[], action: SwitchBulkFeatureAction) {
  if (action.entityType === FeatureEntity.Field) {
    return fields.map((field: FField) => {
      const result = action.createdKeys.find((k: any) => field.fieldId === k.entity_id);

      if (result) {
        field.features[action.key].value = action.value;
        field.features[action.key].id = result.id;

        return {...field};
      }

      return field;
    });
  }

  if (action.entityType === FeatureEntity.Season) {
    return fields.map((field: FField) => {
      return {
        ...field,
        seasons: (field.seasons || []).map((season: SeasonWithFeatures) => {
          const result = action.createdKeys.find((k: any) => season.id === k.entity_id);

          if (result) {
            season.features[action.key].value = action.value;
            season.features[action.key].id = result.id;

            return {...season};
          }

          return season;
        }),
      };
    });
  }
}
