import React from 'react';
import {Flex, FluroButton, FluroDialog} from 'components';

type SIDialogPopupProps = {
  title: string;
  text: string | React.ReactNode;
  onHide: () => void;
  onConfirm: () => void;
  saveText?: string;
  noCancel?: boolean;
  disabled?: boolean;
  width?: number;
};

export const SIDialogPopup = ({
  title,
  text,
  onHide,
  onConfirm,
  saveText = 'Save',
  noCancel = false,
  disabled = false,
  width = 400,
}: SIDialogPopupProps) => {
  return (
    <FluroDialog id={'si-dialog-popup'} title={title} onHide={onHide} visible={true} width={width}>
      {text}
      <Flex justifyContent="space-between" className="mt-2">
        {noCancel ? (
          <></>
        ) : (
          <FluroButton raised blank onClick={onHide}>
            Cancel
          </FluroButton>
        )}
        <FluroButton raised primary onClick={onConfirm} disabled={disabled}>
          {saveText}
        </FluroButton>
      </Flex>
    </FluroDialog>
  );
};
