const DEMO_FARMS_URLS: {[key: string]: string} = {
  /**** INSTRUCTIONS **** 

 - use 'farmID/fieldId' as key pattern
 - one field, one rule
 - use whole URL param as value, including ? sing
 - copying a string from URL is more save than manual typing
   
   **/

  /// Cereals  ///
  // Fallow weeds - yield impact
  '13365/133463':
    '?layerDate=27%2F01%2F2017-satellite&layer=MSAVI&season=98774&tab=crop&anomaly=btq4c&message=133463',
  // Frosted wheat field
  '13365/1430':
    '?layerDate=07%2F09%2F2018-satellite&layer=NDRE&season=98773&tab=crop&anomaly=722969&message=1430',
  // Wheat nitrogen Rec
  '13365/147770':
    '?layerDate=16%2F11%2F2019-satellite&layer=NDVI&season=99058&tab=zoning&message=147770&nrx-toggle=true&nrx-date=2019-05-28',
  // Wild Rye infestation
  '13365/1434':
    '?layerDate=17%2F09%2F2018-satellite&layer=NDVI&season=99156&tab=crop&anomaly=1314642&message=1434',

  ///  Corn  ///
  // Corn pivot nozzle issue
  '13366/160206':
    '?layerDate=26%2F07%2F2019-plane&layer=TIRS&season=98775&tab=crop&anomaly=780680&message=160206',
  // Drone imagery - crop stress
  '13366/172266':
    '?layerDate=03%2F07%2F2019-dron&layer=NDRE&season=98778&tab=crop&anomaly=7qjuhk&message=172266',
  // Greensnap corn 1
  '13366/185398':
    '?layerDate=27%2F07%2F2019-satellite&layer=NDVI&season=98776&tab=crop&anomaly=722969&message=185398',
  // Greensnap corn 2
  '13366/185399':
    '?layerDate=27%2F07%2F2019-satellite&layer=NDVI&season=98777&tab=crop&anomaly=722989&message=185399',
  // Nitrogen trial
  '13366/153851':
    '?layerDate=12%2F09%2F2019-plane&layer=NDRE&season=99911&tab=analytics&anomaly=7qjuhk&message=153851',

  ///  Potatoes  ///
  // Pivot 1
  '13367/178295':
    '?layerDate=27%2F09%2F2019-satellite&layer=NDVI&season=98779&tab=crop&filterSources=plane&message=178295',
  // Pivot 3
  '13367/178324': '?layerDate=24%2F08%2F2019-plane&layer=NDVI&season=98783&tab=crop&message=178324',
  // Pivot 4
  '13367/178342': '?layerDate=06%2F08%2F2019-plane&layer=TIRS&season=98785&tab=crop&message=178342',
  // Pivot 5
  '13367/178282':
    '?layerDate=05%2F06%2F2019-plane&layer=TIRS&season=98786&tab=data-layers&message=178282&filterSources=satellite',

  // Pivot 6
  '13367/178281':
    '?layerDate=06%2F08%2F2019-satellite&layer=NDVI&season=98787&tab=crop&message=178281',
  // Whole farm
  '13367/WholeFarm':
    '?layerDate=22%2F05%2F2019-plane&layer=NDVI&season=98786&tab=crop-performance&message=potato_1',

  ///  Cotton  ///
  // Disease example
  '13368/1022':
    '?tab=crop&layerDate=16%2F07%2F2018-plane&layer=NDVI&season=98790&anomaly=b9arrk&message=1022',
  // Nitrogen-deficient field
  '13368/170':
    '?layerDate=09%2F12%2F2017-satellite&layer=NDVI&season=98788&tab=crop&anomaly=v15k44&message=170',
  // Variable PGR application
  '13368/132758':
    '?layerDate=17%2F12%2F2018-plane&layer=NDVI&season=99589&tab=data-layers&anomaly=722969&message=132758',

  ///  Fruit or nut trees  ///
  // Almonds
  '13369/220036':
    '?layerDate=01%2F03%2F2017-plane&layer=NDVI&season=99159&tab=data-layers&filterSources=satellite&treeDetectionLayerType=detection&message=220036',
  // Plums
  '13369/148771':
    '?layerDate=17%2F03%2F2017-dron&layer=NDVI&season=98793&tab=data-layers&filterSources=satellite&treeDetectionLayerType=detection&message=148770',
  // Avocados
  '13369/312': '?layerDate=22%2F12%2F2017-plane&layer=NDVI&season=98794&tab=analytics&message=312',
  // Citrus
  '13369/211027':
    '?tab=data-layers&layer=NDVI&layerDate=01%2F10%2F2019-dron&season=98795&filterSources=satellite&treeDetectionLayerType=detection&message=211027',
  // Hazlenats
  '13369/153018':
    '?layerDate=31%2F07%2F2018-plane&layer=NDVI&season=98798&tab=data-layers&treeDetectionLayerType=detection&filterSources=satellite&message=153018',
  // Olives Irrigation issue
  '13369/161362':
    '?layerDate=24%2F09%2F2018-satellite&layer=NDRE&season=98797&tab=crop&anomaly=296247&message=161362',

  ///  Berries  ///
  // Blueberries
  '13370/186461':
    '?tab=zoning&layer=NDVI&layerDate=12%2F09%2F2017-plane&season=98800&filterSources=satellite&message=186461',
  // Blackberries
  '13370/186460':
    '?tab=data-layers&layer=NDVI&layerDate=26%2F09%2F2017-plane&season=98799&filterSources=satellite&message=186460',
};

export default DEMO_FARMS_URLS;
