import {t} from 'i18n-utils';
import React, {useState, useEffect, useMemo} from 'react';
import {useForm} from 'react-hook-form';
import {TextField, SelectField, FontIcon} from 'react-md';
import type {Season} from 'containers/map/types';
import {FluroDatePicker, CropSelectMd, Flex, FluroButton, InfoBlock} from 'components';
import type {Moment as M} from 'moment';
import Moment from 'moment';
import {extendMoment} from 'moment-range';
import {getNextClosestDate} from '_utils';
import {cropId2Subtypes, isDateInRange} from '_utils/pure-utils';
import {validationSchema} from './validation-schema';
import type {CropPayload, SeasonRange} from '../../types';
import {yupResolver} from '@hookform/resolvers/yup';
import {useAppSelector} from '_hooks';
import {selectCropTypeById, selectCropTypes} from 'modules/global/selectors';
import type {AppStore} from 'reducers';

//@ts-expect-error error leftover from convertion to strict mode, please fix
const moment = extendMoment(Moment);

type Props = {
  season: Season | undefined; // an empty season is passed if multipleSeasons is true
  fieldIds?: number[];
  onSubmit: (data: CropPayload, touched?: any) => void;
  onDelete: () => void;
  filledRanges?: SeasonRange[]; // ranges of dates that are in use
  datesDisable?: boolean;
  multipleSeasons?: boolean; // true if multiple season being edited at the same time
  lastStartDate?: string; // is passed to bulk editing form (farm tab, table view)
  isAddingCrop: boolean; // describes it is add crops or edit

  seasonDates?: Array<string[][]>; // [ field: [ season: [startDate, endDate]], ...]
};

type Crop = {
  name?: string;
  startDate: M | undefined;
  endDate: M | undefined;
  cropType: string;
  cropSubType: string;
};

/**
 * Edit season form.
 * Season includes start/end dates and a crop type/subtype.
 */
export const EditSeasonForm = ({
  season = {} as Season,
  onSubmit,
  onDelete,
  filledRanges = [],
  datesDisable = false,
  multipleSeasons = false,
  lastStartDate,
  isAddingCrop,
  seasonDates = [],
}: Props) => {
  const seasonGeometry = useAppSelector(state => state.partFieldSeason.geometry);
  const cropTypes = useAppSelector(selectCropTypes);
  const [touched, setTouched] = useState<{[formValue: string]: boolean}>({});

  const canOverlapping = useMemo(
    () => season?.geometry || seasonGeometry.length,
    [seasonGeometry, season]
  );

  const {
    startDate,
    endDate,
    cropType = 'unknown',
    id: seasonId,
    params,
    name,
    geometry,
  } = season || {};
  // const excludeStartDates = canOverlapping
  //   ? []
  //   : [...new Set(filledRanges.map(r => protectSeasonRange(r).range).flat())].map(d => new Date(d));
  // const excludeEndDates = canOverlapping
  //   ? []
  //   : [...new Set(filledRanges.map(r => r.range).flat())].map(d => new Date(d));

  const defaultStartDate = startDate ? moment(startDate) : undefined;
  const defaultEndDate = endDate ? moment(endDate) : undefined;
  // initialize form
  const {
    handleSubmit,
    formState: {errors},
    setValue,
    watch,
    register,
  } = useForm<Crop>({
    ...(canOverlapping
      ? {}
      : {
          resolver: yupResolver(
            validationSchema(datesDisable, multipleSeasons, filledRanges, lastStartDate)
          ),
        }),
    defaultValues: {
      name,
      startDate: defaultStartDate || getSelectedDate(multipleSeasons, []),
      endDate: defaultEndDate || getSelectedDate(multipleSeasons, [], true),
      cropType,
      cropSubType: params?.cropSubType || '',
    },
  });

  const onSetValue = (prop: any, value: any) => {
    setValue(prop, value);
    setTouched({...touched, [prop]: true});
  };

  // register virtual inputs
  // @ts-expect-error - error leftover from conversion to strict mode, please fix
  register('name');
  register('startDate');
  register('endDate');
  register('cropType');
  register('cropSubType');

  const formValues = watch();

  const hasOverlapDates = useMemo(() => {
    // for planting areas can overlap without any warning messages
    if (canOverlapping) return false;

    return seasonDates.some(f =>
      f.some(
        s =>
          ((formValues.startDate?.isValid() &&
            isDateInRange(formValues.startDate.format(), s[0], s[1])) ||
            (formValues.endDate?.isValid() &&
              isDateInRange(formValues.endDate.format(), s[0], s[1]))) &&
          (touched.startDate || touched.endDate)
      )
    );
  }, [formValues, canOverlapping, seasonDates, touched]);

  const subTypesList = cropId2Subtypes(cropTypes, formValues.cropType);

  const [maxDate, setMaxDate] = useState<M | null>(null);
  // Toggles between existing/custom crop subtype (variety) to show dropdown-list/text input.
  const [existingSubtype, toggleSubtypeField] = useState<boolean>(
    subtypeExists(cropTypes, formValues)
  );

  // Erase cropSubType when cropType changes.
  useEffect(() => {
    if (!touched.cropType) {
      return;
    }
    toggleSubtypeField(subTypesList.length > 0);
    onSetValue('cropSubType', '');
  }, [formValues.cropType, touched.cropType]);

  const onStartDateChange = (newStartDate: M) => {
    if (!newStartDate) {
      return;
    }

    onSetValue('startDate', newStartDate);

    const endDateIsDisabled = multipleSeasons && !touched.endDate;
    if (endDateIsDisabled) {
      return false;
    }

    const closestMaxDate = getNextClosestDate(
      // is used for automatic setting the end date for a new crop that is before another season
      newStartDate,
      filledRanges.map(r => r.startDate)
    );

    if (closestMaxDate && isAddingCrop && !canOverlapping) {
      setMaxDate(closestMaxDate);
      onSetValue('endDate', moment(closestMaxDate).subtract(1, 'day'));
      return;
    }

    if (!formValues.endDate) {
      onSetValue('endDate', newStartDate.clone().add(6, 'M'));
      return;
    }

    const isEndBeforeStart = formValues.endDate.clone().subtract(1, 'M').isBefore(newStartDate);
    if (isEndBeforeStart) {
      onSetValue('endDate', newStartDate.clone().add(6, 'M'));
    }
  };

  const toggleToSubtypeTitle = existingSubtype ? 'text-field' : 'select field';

  const isDisabled = useMemo(() => {
    return (
      !Object.keys(touched).length ||
      (!multipleSeasons && (!formValues.startDate || !formValues.endDate))
    );
  }, [touched, formValues]);

  const isTreeCropType = useAppSelector(s => selectCropTypeById(s, formValues.cropType)) === 'tree';

  const selectedStartDate = formValues.startDate || getSelectedDate(multipleSeasons, []);
  const selectedEndDate = formValues.endDate || getSelectedDate(multipleSeasons, [], true);

  const startDateIsEmpty = !touched.startDate && multipleSeasons && !datesDisable;

  useEffect(() => {
    if (!canOverlapping) {
      if (startDateIsEmpty && lastStartDate) {
        const closestMaxDate = getNextClosestDate(
          moment(lastStartDate),
          filledRanges.map(r => r.startDate)
        );
        if (closestMaxDate) {
          setMaxDate(closestMaxDate);
        }
      }
    }
  }, [lastStartDate, canOverlapping]);

  const endMinDate = (
    startDateIsEmpty ? moment.utc(lastStartDate, 'YYYY-MM-DD') : moment(formValues.startDate)
  ).add(1, 'M');

  return (
    <form
      onSubmit={handleSubmit(data => {
        onSubmit(
          {
            ...data,
            // Yup validation mutated startDate and endDate to strings (Yup.string),
            // but type system of the useForm thinks they're still Moments,
            // so wrap them to Moments and back to strings.
            startDate: moment(selectedStartDate).format(),
            endDate: moment(selectedEndDate).format(),
            isCustomCropSubType: !existingSubtype,
            multipleSeasons,
            geometry,
          },
          touched
        );
      })}
    >
      {canOverlapping && !multipleSeasons ? (
        <>
          <InfoBlock className={'inside-a-pop-up'} appearance="info">
            {t({id: 'This crop is denoted by an area inside the field'})}
          </InfoBlock>

          <TextField
            id="crop-name"
            onChange={val => onSetValue('name', String(val))}
            value={formValues.name}
            label={t({id: 'Name'})}
            lineDirection="center"
            placeholder={t({id: 'Name'})}
            error={!!errors?.name?.message}
            errorText={errors?.name?.message}
          />
        </>
      ) : null}

      <FluroDatePicker
        id="edit-farm-settings-sowing-planting-date"
        name="edit-season-start-date"
        label={`${
          isTreeCropType
            ? t({id: 'Previous harvest/Planting Date'})
            : t({id: 'Sowing/Planting Date'})
        }`}
        selected={selectedStartDate}
        placeholderText={multipleSeasons ? t({id: 'Multiple dates selected'}) : undefined}
        onChange={onStartDateChange}
        // @ts-expect-error Property 'message' does not exist on type 'NestDataObject<Moment>'
        error={!!errors?.startDate?.message}
        // @ts-expect-error Property 'message' does not exist on type 'NestDataObject<Moment>'
        errorText={errors?.startDate?.message}
        // excludeDates={excludeStartDates}
        disabled={datesDisable}
        hideFormat
        className="edit-farm-settings__datepicker"
        onDisabledClickMessage={t({id: 'This date is overlapping with another crop'})}
        autoComplete="off"
      />

      <FluroDatePicker
        id="edit-farm-settings-season-end-date"
        name="edit-season-end-date"
        label={t({id: 'Est. harvest Date'})}
        selected={selectedEndDate}
        placeholderText={multipleSeasons ? t({id: 'Multiple dates selected'}) : undefined}
        onChange={(value: M) => value && onSetValue('endDate', value)}
        // @ts-expect-error Property 'message' does not exist on type 'NestDataObject<Moment>'
        error={!!errors?.endDate?.message}
        // @ts-expect-error Property 'message' does not exist on type 'NestDataObject<Moment>'
        errorText={errors?.endDate?.message}
        // excludeDates={excludeEndDatesWithoutStartMonth}
        minDate={endMinDate}
        maxDate={maxDate === null ? undefined : maxDate}
        disabled={datesDisable}
        hideFormat
        className="edit-farm-settings__datepicker"
        autoComplete="off"
      />

      {hasOverlapDates && (
        <span className="overlap-msg">
          {t({id: 'These dates overlap with another season for this field'})}
        </span>
      )}

      <CropSelectMd
        errorMessage={errors?.cropType?.message}
        cropType={formValues.cropType}
        onChange={(val: string) => onSetValue('cropType', val)}
      />

      <div className="edit-farm-settings__crop-subtype">
        {existingSubtype ? (
          <SelectField
            id="crop-sub-type"
            label={t({id: 'Variety'})}
            placeholder={t({id: 'Variety'})}
            menuItems={subTypesList}
            simplifiedMenu={true}
            onChange={val => onSetValue('cropSubType', String(val))}
            value={formValues.cropSubType}
            className={'subtype-selector'}
          />
        ) : (
          <TextField
            id="crop-custom-sub-type"
            onChange={val => onSetValue('cropSubType', String(val))}
            value={formValues.cropSubType}
            label={t({id: 'Variety'})}
            lineDirection="center"
            placeholder={t({id: 'Variety'})}
            error={!!errors?.cropSubType?.message}
            errorText={errors?.cropSubType?.message}
          />
        )}

        {subTypesList.length ? (
          <div
            className="toggle-sub-field"
            onClick={() => toggleSubtypeField(!existingSubtype)}
            title={t({id: 'toggle input to'}, {value: toggleToSubtypeTitle})}
          >
            <FontIcon>{existingSubtype ? 'text_format' : 'list'}</FontIcon>
          </div>
        ) : null}
      </div>

      {/*{datesDisable ? (*/}
      {/*  <div className="edit-field-dialog__warnings">*/}
      {/*    <FontIcon>error</FontIcon>*/}
      {/*    You can not change dates for more than 1 crop in the same field. Please select one crop*/}
      {/*    per field.*/}
      {/*  </div>*/}
      {/*) : null}*/}

      {!datesDisable &&
      !isAddingCrop &&
      multipleSeasons &&
      (touched.startDate || touched.endDate) ? (
        <div className="edit-field-dialog__warnings">
          <FontIcon>error</FontIcon>
          {t({id: 'Multiple seasons selected, your current dates will be overwritten!'})}
        </div>
      ) : null}

      {multipleSeasons && !isAddingCrop && (touched.cropType || touched.cropSubType) ? (
        <div className="edit-field-dialog__warnings">
          <FontIcon>error</FontIcon>
          {t({id: 'Multiple seasons selected, your current crop settings will be overwritten!'})}
        </div>
      ) : null}

      <Flex justifyContent={'space-between'} gap={'10px'}>
        <FluroButton disabled={seasonId === undefined} raised onClick={onDelete}>
          {t({id: 'Delete crop'})}
        </FluroButton>
        <FluroButton
          type="submit"
          raised
          primary
          disabled={isDisabled}
          className="edit-field-dialog__save-button"
          tabIndex={0}
        >
          {t({id: 'Save changes'})}
        </FluroButton>
      </Flex>
    </form>
  );
};

const subtypeExists = (cropTypes: AppStore['global']['cropTypes'], {cropType, cropSubType}: Crop) =>
  !!cropId2Subtypes(cropTypes, cropType)?.find(({value}) => value === cropSubType);

const getSelectedDate = (multipleSeasons: boolean, excludeDates: Date[], isEndDate?: boolean) => {
  if (multipleSeasons) {
    return undefined;
  }
  const firstExcludedDate = moment(excludeDates[0]);
  const lastExcludedDate = moment(excludeDates[excludeDates.length - 1]);
  const defaultDate = isEndDate ? moment().add(6, 'month') : moment(); // Current date or a month later.
  const defaultDateIsForbidden = defaultDate.isBetween(firstExcludedDate, lastExcludedDate);
  if (defaultDateIsForbidden) {
    const nextAvailableDay = lastExcludedDate.add(1, 'day');
    return isEndDate ? nextAvailableDay.add(6, 'month') : nextAvailableDay;
  }
  return defaultDate;
};
