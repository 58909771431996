import React from 'react';
import {Text} from 'components';
import {CardToolTip} from 'containers/map/features/sustainability-insights/filters-panel/cards/components/card-tooltip';

export const ReductionRemovalCardTooltip = () => (
  <CardToolTip
    id="reduction-removal-card-tooltip"
    content={
      <>
        This matrix shows comparison of the reduction vs. removal of each practice change
        intervention. The highlighted scenarios are displayed in color below with all scenarios in
        the report shown in gray for perspective. This table is sorted according to the{' '}
        <Text variant="small" bold>
          Rank by
        </Text>{' '}
        filter at the top of the report.
      </>
    }
    place="left"
  />
);
