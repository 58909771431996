// @ts-nocheck
import {t} from 'i18n-utils';
import type {ComponentType} from 'react';
import React, {useEffect, useMemo, useState} from 'react';
import {Button, CircularProgress, SelectionControl} from 'react-md';
import config from '_environment';
import {showNotification} from 'components/notification/notification';
import {AgworldApi} from '_api';
import {
  ExpandableArea,
  ExpandableRowContainer,
  NoItemsTitle,
  SubContainer,
  SubItem,
} from 'components/expandable-table-items/expandable-table-items';
import {RequestStatus} from 'types';
import {importAgworldGrowers, setPlatformAuthStatus} from './actions';
import Mixpanel from '_utils/mixpanel-utils';
import {ExternalService} from '../../map/types';
import IntegrationCard from './helpers/integration-card';
import cn from 'classnames';
import {sortByStringKey} from 'containers/profile/integration/helpers/utils';
import {reportError} from '../../error-boundary';
import {useAppDispatch, useAppSelector} from '_hooks';
import {FluroButton, Sticky} from 'components';
import {Flex} from 'components/flex/flex';
import {selectIntegrationPlatform} from './integration-selectors';
import type {IntegrationComponentProps} from './types';
import {SelectedPlatformBackButton} from './helpers/selected-platform-back-btn';
import {SelectFarmsAndFieldsToImportRow} from './helpers/select-farms-fields-to-import-row';

/**
 * Agworld integration flow:
 *
 * 1. Fetch growers list
 * -> if failed – means a user isn't authorized, so we show the Auth button
 * -> if success – show the growers list
 *
 * 2. A user can select growers they want to import from Agworld to Regrow.
 * This procedure can take up to 5 minutes (or even longer) depending on the
 * number of fields to import.
 */

export type AgworldSyncRequestPayload = {
  growers: string[];
  farms: string[];
  fields: string[];
};

type AgworldEntity = {
  guid: string;
  name: string;
};

export const classifyAgworldHighestEntity = (data: AgworldSyncRequestPayload) => {
  if (data.growers) {
    return t({id: 'growers'});
  } else if (data.farms) {
    return t({id: 'farms'});
  } else if (data.fields) {
    return t({id: 'fields'});
  }
};

const NoEntities: AgworldEntity[] = [{name: 'no-entities', guid: null}];

const IntegrationAgworld: ComponentType<IntegrationComponentProps> = ({expanded}) => {
  const dispatch = useAppDispatch();
  const platform = useAppSelector(s => selectIntegrationPlatform(s, ExternalService.Agworld));
  const authorized = platform.authorized;

  const [growers, setGrowers] = useState<AgworldEntity[]>([]);
  const [loading, setLoading] = useState(false);
  const [selectedGrowers, setSelectedGrowers] = useState<string[]>([]);
  const [expandedGrowers, setExpandedGrowers] = useState<{[growerId: string]: boolean}>({});

  const [farms, setFarms] = useState<{[growerId: string]: AgworldEntity[]}>({});
  const [selectedFarms, setSelectedFarms] = useState<{[growerId: string]: string[]}>({});
  const [expandedFarms, setExpandedFarms] = useState<{[farmId: string]: boolean}>({});

  const [fields, setFields] = useState<{[farmId: string]: AgworldEntity[]}>({});
  const [selectedFields, setSelectedFields] = useState<{[farmId: string]: string[]}>({});

  const [requests, setRequests] = useState<{[entityId: string]: RequestStatus}>({});

  const getGrowers = () => {
    if (!expanded || growers.length || loading || !authorized) return;
    setLoading(true);
    AgworldApi.getGrowers()
      .then(res => {
        const sortedGrowers = sortByStringKey(res.data || [], 'name');
        setGrowers(sortedGrowers);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
        dispatch(setPlatformAuthStatus(ExternalService.Agworld, false));
        showNotification({
          title: t({id: 'note.error', defaultMessage: 'Error'}),
          message: "Couldn't fetch Agworld growers.",
          type: 'error',
        });
      });
  };

  useEffect(() => {
    getGrowers();
  }, [authorized, expanded]);

  const toggleGrower = (growerId: string, value: boolean) => {
    setSelectedGrowers(selectedGrowers => {
      return value ? [...selectedGrowers, growerId] : selectedGrowers.filter(x => x !== growerId);
    });
    // If we have farms loaded for the grower, update them.
    if (farms[growerId]) {
      setSelectedFarms(selectedFarms =>
        value
          ? {...selectedFarms, [growerId]: farms[growerId].map(f => f.guid)}
          : {...selectedFarms, [growerId]: []}
      );

      const newFields: {[farmId: string]: string[]} = {};
      farms[growerId].forEach(farm => {
        if (fields[farm.guid]) {
          newFields[farm.guid] = value ? fields[farm.guid].map(f => f.guid) : [];
        }
      });
      setSelectedFields(newFields);
    }
  };

  const toggleAllGrowers = (value: boolean) => {
    setSelectedGrowers(value ? growers.map(g => g.guid) : []);

    const newFarms: {[growerId: string]: string[]} = {};
    Object.entries(farms).forEach(([growerId, farms]) => {
      newFarms[growerId] = value ? farms.map(f => f.guid) : [];
    });
    setSelectedFarms(newFarms);

    const newFields: {[farmId: string]: string[]} = {};
    Object.entries(fields).forEach(([farmId, fields]) => {
      newFields[farmId] = value ? fields.map(f => f.guid) : [];
    });
    setSelectedFields(newFields);
  };

  const getAgworldFarms = (growerId: string) => {
    setRequests(r => ({...r, [growerId]: RequestStatus.Loading}));
    AgworldApi.getFarms(growerId)
      .then(res => {
        let f = sortByStringKey(res.data || [], 'name');
        if (!f.length) {
          f = NoEntities;
        }
        setFarms(farms => ({...farms, [growerId]: f}));
        setRequests(r => ({...r, [growerId]: RequestStatus.Success}));
        if (selectedGrowers.includes(growerId)) {
          setSelectedFarms(selectedFarms => ({
            ...selectedFarms,
            [growerId]: f.map(f => f.guid),
          }));
        }
      })
      .catch(e => {
        setFarms(farms => ({...farms, [growerId]: NoEntities}));
        setRequests(r => ({...r, [growerId]: RequestStatus.Error}));
        reportError(`Agworld sync error fetching farms for grower ${growerId}: ` + e);
      });
  };

  const getAgworldFields = (growerId: string, farmId: string) => {
    setRequests(r => ({...r, [farmId]: RequestStatus.Loading}));

    AgworldApi.getFields(farmId)
      .then(res => {
        let f = sortByStringKey(res.data || [], 'name');
        if (!f.length) {
          f = NoEntities;
        }

        setFields(fields => ({...fields, [farmId]: f}));
        setRequests(r => ({...r, [farmId]: RequestStatus.Success}));
        if (selectedFarms[growerId]?.includes(farmId)) {
          // If the expanded farm was selected, select all the including fields automatically.
          setSelectedFields(selectedFields => {
            return {
              ...selectedFields,
              [farmId]: f.map(f => f.guid),
            };
          });
        }
      })
      .catch(e => {
        setFields(fields => ({...fields, [farmId]: NoEntities}));
        setRequests(r => ({...r, [farmId]: RequestStatus.Error}));
        reportError(`Agworld sync error fetching fields for farm ${farmId}: ` + e);
      });
  };

  const expandGrower = (growerId: string) => {
    const newValue = !expandedGrowers[growerId];
    setExpandedGrowers(x => ({
      ...x,
      [growerId]: newValue,
    }));
    if (newValue && !farms[growerId]) {
      getAgworldFarms(growerId);
    }
  };

  const expandFarm = (growerId: string, farmId: string) => {
    const newValue = !expandedFarms[farmId];
    setExpandedFarms(x => ({
      ...x,
      [farmId]: newValue,
    }));
    if (newValue && !fields[farmId]) {
      getAgworldFields(growerId, farmId);
    }
  };

  const toggleFarm = (growerId: string, farmId: string, value: boolean) => {
    const oldFarms = selectedFarms[growerId] || [];
    const newFarms = value ? [...oldFarms, farmId] : oldFarms.filter(x => x !== farmId);
    setSelectedFarms(selectedFarms => ({
      ...selectedFarms,
      [growerId]: newFarms,
    }));

    // Check if parent (grower) needs to be updated.
    if (oldFarms.length > 0 && newFarms.length === 0) {
      // Some farms of the grower were selected and now none of them are selected.
      // Deselect the grower.
      setSelectedGrowers(growers => growers.filter(id => id !== growerId));
    } else if (oldFarms.length === 0 && newFarms.length > 0) {
      // None of the farms of the grower were selected and now some of them are selected.
      // Select the grower.
      setSelectedGrowers(growers => [...growers, growerId]);
    }

    // If we have fields loaded for the farm, update them.
    if (fields[farmId]) {
      setSelectedFields(selectedFields =>
        value
          ? {...selectedFields, [farmId]: fields[farmId].map(f => f.guid)}
          : {...selectedFields, [farmId]: []}
      );
    }
  };

  const toggleField = (growerId: string, farmId: string, fieldId: string, value: boolean) => {
    const oldFields = selectedFields[farmId] || [];
    const newFields = value ? [...oldFields, fieldId] : oldFields.filter(x => x !== fieldId);
    setSelectedFields(selectedFields => ({
      ...selectedFields,
      [farmId]: newFields,
    }));

    // Check if the parent (farm) needs to be updated.
    if (selectedFarms[growerId]?.includes(farmId) && !newFields.length) {
      // The farm is selected but none of the fields inside this farm is selected anymore.
      // Deselect the farm.
      const oldFarms = selectedFarms[growerId];
      const newFarms = selectedFarms[growerId].filter(id => id !== farmId);
      setSelectedFarms(selectedFarms => ({
        ...selectedFarms,
        [growerId]: newFarms,
      }));

      // Check if parent (grower) needs to be updated.
      if (oldFarms.length > 0 && newFarms.length === 0) {
        // Some farms of the grower were selected and now none of them are selected.
        // Deselect the grower.
        setSelectedGrowers(growers => growers.filter(id => id !== growerId));
      }
    } else if (!selectedFarms[growerId]?.includes(farmId) && newFields.length) {
      // The farm is not selected but the field inside this farm is selected.
      // Select the farm.
      const oldFarms = selectedFarms[growerId] || [];
      const newFarms = [...oldFarms, farmId];
      setSelectedFarms(selectedFarms => ({
        ...selectedFarms,
        [growerId]: newFarms,
      }));

      // Check if parent (grower) needs to be updated.
      if (oldFarms.length === 0 && newFarms.length > 0) {
        // None of the farms of the grower were selected and now some of them are selected.
        // Select the grower.
        setSelectedGrowers(growers => [...growers, growerId]);
      }
    }
  };

  const onImportGrowers = () => {
    const growersNames = growers.filter(g => selectedGrowers.includes(g.guid)).map(g => g.name);
    Mixpanel.importSync(ExternalService.Agworld, growersNames);
    dispatch(
      importAgworldGrowers({
        // send all required fields, farms and growers, dealt with already in the backend
        fields: Object.values(selectedFields).reduce((acc, v) => [...acc, ...v], []),
        farms: Object.values(selectedFarms).reduce((acc, v) => [...acc, ...v], []),
        growers: Object.values(selectedGrowers),
      })
    );
  };

  const isSyncInProgress = platform.syncStatus === RequestStatus.Loading;
  const areGrowersLoading = loading && !growers.length;
  const isReady = Boolean(authorized && !areGrowersLoading);

  const reauthComponent = useMemo(() => {
    return (
      <FluroButton blank raised onClick={authorize}>
        {t({id: 'Re-authorize'})}
      </FluroButton>
    );
  }, []);

  return (
    <div className={cn('integration-platform agworld', {selected: expanded})}>
      <IntegrationCard
        authorized={authorized}
        expanded={expanded}
        onAuthorize={authorize}
        reauthComponent={reauthComponent}
        platform={ExternalService.Agworld}
        loading={areGrowersLoading}
      />

      {expanded && (
        <>
          {isReady && (
            <>
              <SelectFarmsAndFieldsToImportRow />

              <div className={'entities-to-import-container'}>
                {growers.length > 1 && (
                  <SelectionControl
                    id={'select-all-agworld-growers'}
                    name={'select-all-agworld-growers'}
                    label={t(
                      {id: 'Select all growers ({count1}/{count2})'},
                      {count1: selectedGrowers.length, count2: growers.length}
                    )}
                    type={'checkbox'}
                    className={'select-all-checkbox'}
                    checked={selectedGrowers.length === growers.length}
                    onChange={v => toggleAllGrowers(Boolean(v))}
                  />
                )}
                {growers.map(grower => {
                  const isGrowerSelected = selectedGrowers.includes(grower.guid);
                  return (
                    <React.Fragment key={grower.guid}>
                      <ExpandableRowContainer
                        className={cn({
                          selected: isGrowerSelected,
                        })}
                      >
                        <SelectionControl
                          id={grower.guid}
                          name={'grower selection'}
                          className={'highest-platform-entity'}
                          label={
                            grower.name +
                            selectedEntitiesNumber(farms[grower.guid], selectedFarms[grower.guid])
                          }
                          type={'checkbox'}
                          checked={isGrowerSelected}
                          onChange={(v: boolean) => toggleGrower(grower.guid, v)}
                        />
                        <ExpandableArea
                          onClick={() => expandGrower(grower.guid)}
                          expanded={expandedGrowers[grower.guid]}
                        />
                      </ExpandableRowContainer>
                      {expandedGrowers[grower.guid] && (
                        <SubContainer>
                          {requests[grower.guid] === RequestStatus.Loading && (
                            <span className="preloader">
                              <CircularProgress centered={false} id={grower.guid} />
                            </span>
                          )}
                          {farms[grower.guid]?.map(f => {
                            if (farms[grower.guid]?.length === 1 && f.name === 'no-entities') {
                              return (
                                <NoItemsTitle key={f.guid}>
                                  {t({id: 'No farms found'})}
                                </NoItemsTitle>
                              );
                            }

                            const isFarmSelected = (selectedFarms[grower.guid] || []).includes(
                              f.guid
                            );
                            return (
                              <React.Fragment key={f.guid}>
                                <SubItem
                                  className={cn({
                                    selected: isFarmSelected,
                                  })}
                                >
                                  <SelectionControl
                                    id={f.guid}
                                    name={'farm selection'}
                                    className={'medium-platform-entity'}
                                    label={
                                      f.name +
                                      selectedEntitiesNumber(fields[f.guid], selectedFields[f.guid])
                                    }
                                    type={'checkbox'}
                                    checked={isFarmSelected}
                                    onChange={(v: boolean) => toggleFarm(grower.guid, f.guid, v)}
                                  />
                                  <ExpandableArea
                                    onClick={() => expandFarm(grower.guid, f.guid)}
                                    expanded={expandedFarms[f.guid]}
                                  />
                                </SubItem>
                                {expandedFarms[f.guid] && (
                                  <SubContainer>
                                    {requests[f.guid] === RequestStatus.Loading && (
                                      <span className="preloader">
                                        <CircularProgress centered={false} id={f.guid} />
                                      </span>
                                    )}
                                    {fields[f.guid]?.map(field => {
                                      if (
                                        fields[f.guid]?.length === 1 &&
                                        field.name === 'no-entities'
                                      ) {
                                        return (
                                          <NoItemsTitle key={field.guid}>
                                            {t({id: 'No fields found'})}
                                          </NoItemsTitle>
                                        );
                                      }

                                      return (
                                        <SubItem key={field.guid}>
                                          <SelectionControl
                                            id={field.guid}
                                            name={'field selection'}
                                            className={'small-platform-entity'}
                                            label={field.name}
                                            type={'checkbox'}
                                            checked={(selectedFields[f.guid] || []).includes(
                                              field.guid
                                            )}
                                            onChange={(v: boolean) =>
                                              toggleField(grower.guid, f.guid, field.guid, v)
                                            }
                                          />
                                        </SubItem>
                                      );
                                    })}
                                  </SubContainer>
                                )}
                              </React.Fragment>
                            );
                          })}
                        </SubContainer>
                      )}
                    </React.Fragment>
                  );
                })}
              </div>
            </>
          )}

          <Sticky className="action-buttons-holder">
            <SelectedPlatformBackButton />

            {isReady && (
              <Flex alignItems="center">
                {isSyncInProgress && (
                  <CircularProgress
                    className="margin-0 margin-right-5"
                    id="integration-agworld-progress"
                  />
                )}

                <Button
                  raised
                  primary
                  className="md-btn--right"
                  disabled={!selectedGrowers.length || isSyncInProgress}
                  onClick={onImportGrowers}
                >
                  {isSyncInProgress ? t({id: 'Importing...'}) : t({id: 'Import'})}
                </Button>
              </Flex>
            )}
          </Sticky>
        </>
      )}

      {/*<ColoredBorder colorStart={'rgb(111, 190, 68)'} colorEnd={'rgba(111, 190, 68, 0.2)'} />*/}
    </div>
  );
};

const authorize = () => {
  window.location.assign(`${config.baseUrl}login/agworld`);
};

const selectedEntitiesNumber = (list: AgworldEntity[], selected?: string[]) => {
  if (!list) {
    return '';
  }
  return ` (${selected?.length || 0}/${list.length})`;
};

export default IntegrationAgworld;
