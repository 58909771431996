import Server from './server';

export class CropsApi {
  static getIcons(crops?: string[]) {
    const cropsToGet = crops?.length ? `?values=${crops}` : '';
    return Server.get(`crops/icons${cropsToGet}`);
  }
  static getIcon(crop: string) {
    return Server.get(`crops/${crop}/icon`);
  }
  static putIcon(cropId: number, icon: File | File[]) {
    return Server.put(`crops/${cropId}/icon`, icon, {headers: {'content-type': 'image/png'}});
  }
}

export default CropsApi;
