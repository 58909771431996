import {createSelector} from '@reduxjs/toolkit';
import {getStateFP} from 'modules/sustainability-insights/utils';
import type {AppStore} from 'reducers';
import type {AdminFeaturesStore} from './types';

export const selectAdminMeta = (s: AppStore) => s.admin.features.SIData.meta;
export const selectAdminStatesIds = (s: AppStore) => s.admin.features.SIData.statesIds;
export const selectAdminCountyIds = (s: AppStore) => s.admin.features.SIData.countiesIds;

const classifyCountyOptions = (
  countiesIds: number[],
  meta: AdminFeaturesStore['SIData']['meta']
) => {
  const options = Object.values(
    countiesIds.reduce<
      Record<
        string,
        {
          value: number | string;
          label: string;
          options: {value: number | string; label: string}[];
        }
      >
    >((acc, countyId) => {
      const stateFP = getStateFP(countyId);

      const countyMeta = meta[countyId];
      const stateMeta = meta[stateFP];
      acc[stateFP] = acc[stateFP] || {value: stateFP, label: stateMeta?.name, options: []};
      acc[stateFP].options = acc[stateFP].options.concat({
        value: countyId,
        label: countyMeta?.name,
      });

      return acc;
    }, {})
  );

  return options;
};

export const selectAdminCountiesOptions = createSelector(
  [selectAdminCountyIds, selectAdminMeta],
  classifyCountyOptions
);
