import {area as turfArea} from '@turf/turf';
import convert from 'convert-units';
import {pathToRegexp, compile} from 'path-to-regexp';
import Moment from 'moment';
import {extendMoment} from 'moment-range';
import React from 'react';
import type {Season} from 'containers/map/types';
import {history} from '_utils/history-utils';
import _config, {CI_ROOT_PATH} from '_environment';
import {createSelectorCreator, defaultMemoize} from 'reselect';
import type {CropType} from 'modules/global/types';
import type {AppStore} from 'reducers';
import {isEqual} from 'lodash';
import {getTypedKeys} from './object';

/*
 *
 * The main purpose of this utils file - non dependency of import store
 * (decouple components, storybook bug-fix)
 *
 * */

export const createCachingSelector = createSelectorCreator(defaultMemoize, {maxSize: 20});

/**
 * Creates a "Deep Equal" selector.
 * This selector uses lodash isEqual instead of ===.
 * The reason to use isEqual to do a deep comparison is for objects, which might be recreated on every render.
 */
export const createDeepEqualSelector = createSelectorCreator(defaultMemoize, isEqual);

//@ts-expect-error error leftover from convertion to strict mode, please fix
const moment = extendMoment(Moment);
//duplicate from _constants for make storybook great again
const GLOBAL_FORMAT_DATE = 'YYYY-MM-DD';

export const getToday = () => moment().format('YYYY-MM-DD');

export const genKey = function () {
  return Math.random().toString(36).substring(7);
};

export const getRandomNumber = (min = 0, max = 99999) =>
  Math.floor(Math.random() * (max - min)) + min;

export const hexToRgbA = (hex: string) => {
  let c: any;
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    c = hex.substring(1).split('');
    if (c.length === 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]];
    }
    c = '0x' + c.join('');
    return 'rgba(' + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',') + ',1)';
  }
};

export const sum = (list: number[]) => list.reduce((acc, v) => acc + v, 0);

export const calcPlural = (string: string, value: Array<any> | number, customPlural?: string) => {
  const compareValue = Array.isArray(value) ? value.length : value;
  return compareValue === 1 ? string : customPlural || `${string}s`;
};

export const contactSupportTeam = (text: string | React.ReactElement): React.ReactElement => (
  <a href={'mailto:support@regrow.ag'}> {text}</a>
);

export function setFieldIDToURL(fieldID: number, push: any) {
  const pattern = `/${CI_ROOT_PATH}/:groupID/:fieldID?`;
  let finalURL = '';

  const re = pathToRegexp(pattern);
  const result = re.exec(window.location.pathname);

  if (result) {
    const toPath = compile(pattern);

    finalURL = toPath({
      groupID: '' + result[1],
      fieldID: fieldID,
    });
  }

  if (finalURL) {
    push(finalURL.replace('/app', '') + window.location.search);
  }
}

export const setGetParamToURL = (param: string, value: string | number | null) => {
  // TL;DR:
  // Make changes on the next tick to prevent thunks
  // from failing with the error:
  // `Uncaught Error: You may not call store.getState() while the reducer is executing.`
  // Explanation:
  // Because the `history` instance is used by the connected-react-router middleware,
  // any change to the history state triggers action dispatch to the store.
  // When thunks are setting URL params and then trying to call store.getState()
  // within the same tick it triggers redux to throw the error because it won't allow
  // to read the state when there is action dispatch in progress.
  setTimeout(() => {
    // it is important to get the relevant params just before set them, otherwise other state might come between get and set params
    const params = new URLSearchParams(window.location.search);

    if (value == null) {
      params.delete(param);
    } else {
      params.set(param, `${value}`);
    }
    history.replace({search: params.toString()});
  });
};

export const bulkSetGetParamToURL = (params: {[key: string]: string | number}) => {
  Object.keys(params).forEach(key => {
    setGetParamToURL(key, params[key]);
  });
};

export const getGetURLParam = (param: string) => {
  const params = new URLSearchParams(window.location.search);
  return params.get ? params.get(param) : null;
};

export const unreachableError = (x: never, message: string): never => {
  throw new Error(message);
};

export function browserLocale() {
  let lang;

  if (navigator.languages && navigator.languages.length) {
    // latest versions of Chrome and Firefox set this correctly
    lang = navigator.languages[0];
    //@ts-expect-error error leftover from convertion to strict mode, please fix
  } else if (navigator.userLanguage) {
    // IE only
    //@ts-expect-error error leftover from convertion to strict mode, please fix
    lang = navigator.userLanguage;
  } else {
    // latest versions of Chrome, Firefox, and Safari set this correctly
    lang = navigator.language;
  }

  return lang;
}

export function getFarmIdsFromUrl() {
  return getArrayValuesFromUrl('farmIds')?.map(Number);
}

export function getArrayValuesFromUrl(urlParam: string) {
  return getGetURLParam(urlParam)?.split(',').filter(Boolean);
}

export const isDateInRange = (date: string, startDate: string, endDate: string) => {
  const startDateM = moment(startDate, GLOBAL_FORMAT_DATE);
  const endDateM = moment(endDate, GLOBAL_FORMAT_DATE);
  const currentDateM = moment(date, GLOBAL_FORMAT_DATE);
  const range = moment().range(startDateM, endDateM);
  return (
    range.contains(currentDateM) || startDateM.isSame(currentDateM) || endDateM.isSame(currentDateM)
  );
};

export function sortDates(arrayDates: Array<any>, dateFormat = 'DD/MM/YYYY') {
  arrayDates.sort(function (a, b) {
    if (moment(a, dateFormat).isBefore(moment(b, dateFormat))) return -1;
    if (moment(a, dateFormat).isAfter(moment(b, dateFormat))) return 1;
    return 0;
  });
  return arrayDates;
}

export const createDateRange = (startDate: string, endDate: string) => {
  const range = moment.range(moment(startDate, 'YYYY-MM-DD'), moment(endDate, 'YYYY-MM-DD'));

  return Array.from(range.by('day')).map(d => d.format('YYYY-MM-DD'));
};

/**
 * @returns The area in ha
 */
export function getArea(geometry: GeoJSON.Geometry | GeoJSON.GeometryCollection): number {
  switch (geometry.type) {
    case 'GeometryCollection':
      return geometry.geometries.reduce((acc, g) => acc + getArea(g), 0);
    default:
      return convert(turfArea(geometry)).from('m2').to('ha');
  }
}

/**
 * Local crop icons.
 * @deprecated - we should be using server icons
 */
export const getCropSrc = (src: string) => (src ? `/assets/crops/${src}` : '');
export const getFMSIconSrc = (src: string) => (src ? `${_config.baseUrl}api/v1/mrv${src}` : '');
// Server crop icons.
export const getCropSrcServer = (cropType: string) => {
  const safeCropType = encodeURIComponent(cropType);
  return safeCropType
    ? `${_config.baseUrl}api/v1/crops/${safeCropType}/icon`
    : `${_config.baseUrl}api/v1/crops/other/icon`;
};

export const getYearRange = (years: number[]) => {
  if (years.length === 0) return '';
  if (years.length === 1) return years[0];

  // Whether the year period is continuos (2007 – 2010),
  // or has holes (2007, 2008, 2010).
  let continuous = true;
  years.sort();
  for (let i = years.length - 1; i > 0; i--) {
    if (years[i] - years[i - 1] > 1) {
      continuous = false;
      break;
    }
  }
  return continuous ? `${years[0]} – ${years[years.length - 1]}` : years.join(', ');
};

export const isObjectPropValuesTheSame = (data: any[], key: string | number) => {
  const obj: any = {};
  data.forEach((el: any) => {
    obj[el[key]] = null;
  });

  return Object.keys(obj).length <= 1;
};

export const getSeasonByYear = (seasons: Season[], year: number) => {
  return seasons?.find(season => year === moment(season.startDate).year());
};

export const urlParamsToString = <T extends object>(params: T) => {
  return getTypedKeys(params)
    .filter(key => params[key])
    .map(key => `${String(key)}=${params[key]}`)
    .join('&');
};

/**
 * You can put this function to the second parameter of the React.memo()
 * to see which props are causing the re-render
 */
export function checkWhyComponentRerenders<T extends object>(prevProps: T, nextProps: T): boolean {
  let update = false;
  getTypedKeys<T>(prevProps).forEach(key => {
    if (prevProps[key] !== nextProps[key]) {
      update = true;
    }
  });
  return update;
}

export const averageNumber = (values: number[]) => {
  return values.reduce((a, b) => a + b, 0) / values.length;
};

export const calculatePercentage = (value: number, total: number): number => {
  if (!Number.isFinite(value) || !total || !Number.isFinite(total)) return NaN;
  const result = (value / total) * 100;
  return result;
};

export const calculateArraySum = (values: number[]) => {
  return values.reduce((arrSum, a) => arrSum + a, 0);
};

export const applyPathParams = (path: string, values: any): string => {
  const toPath = compile(path);
  const result = toPath(values);
  return result;
};

export function escapeRegExp(string = '') {
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
}

export const parseJwt = (token: string) => {
  try {
    return JSON.parse(atob(token.split('.')[1]));
  } catch (e) {
    return null;
  }
};

export const capitalizeFirstLetter = (string: string) => {
  if (typeof string === 'string') {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  return string;
};

export const capitalizeFirstLetterOfEachWord = (input: string) => {
  const words = input.split(' ');

  for (let i = 0; i < words.length; i++) {
    words[i] = words[i][0].toUpperCase() + words[i].substring(1);
  }

  return words.join(' ');
};

export const asyncTimeout = (ms: number) => {
  return new Promise(resolve => setTimeout(resolve, ms));
};

/**
 * Splits an array into chunks based on chunkLength.
 */
export function chunkArray<T>(array: T[], chunkLength: number) {
  const mutArray = [...array];
  const chunkedArray = [];

  while (mutArray.length) {
    chunkedArray.push(mutArray.splice(0, chunkLength));
  }

  return chunkedArray;
}

export function unique<T>(array: T[]) {
  return [...new Set(array)];
}

// crop type utils

export function cropId2Subtypes(
  cropTypes: AppStore['global']['cropTypes'],
  id: string
): CropType['subtypes'] {
  return (cropTypes[id] && cropTypes[id].subtypes) || [];
}

export const getCropLabelById = (cropTypes: AppStore['global']['cropTypes'], id: string) => {
  return cropTypes[id] ? cropTypes[id].label : id;
};

export const getCropColorById = (cropTypes: AppStore['global']['cropTypes'], id: string) => {
  return cropTypes[id] ? cropTypes[id].color : '#efefef';
};

export function cropSubtype2Label(
  cropTypes: AppStore['global']['cropTypes'],
  crop: string,
  cropSubType: string
) {
  const subtypes = cropId2Subtypes(cropTypes, crop.toLowerCase());
  const subtype = subtypes.length ? subtypes.find(s => s.value === cropSubType) : null;
  return subtype ? subtype.label : cropSubType;
}

type MimeType = 'text/plain' | 'text/csv' | 'application/zip' | 'application/pdf';

// https://github.com/kennethjiang/js-file-download/blob/master/file-download.js
export function downloadFile(data: BlobPart, filename: string, mime?: MimeType) {
  const blob = new Blob([data], {type: mime || 'application/octet-stream'});
  const blobURL = window.URL.createObjectURL(blob);

  const tempLink = document.createElement('a');
  tempLink.style.display = 'none';
  tempLink.href = blobURL;
  tempLink.setAttribute('download', filename);

  if (typeof tempLink.download === 'undefined') {
    tempLink.setAttribute('target', '_blank');
  }

  document.body.appendChild(tempLink);
  tempLink.click();
  document.body.removeChild(tempLink);
  window.URL.revokeObjectURL(blobURL);
}

export function removeDuplicates<T extends Record<string, unknown>>(array: T[], prop: keyof T) {
  return array.filter((obj, pos, arr) => {
    return arr.findIndex(currentObj => currentObj?.[prop] === obj?.[prop]) === pos;
  });
}

/*
 *
 * Return current host with protocol and /app path (/app is returned only for old dev domains)
 *
 * */
export const getCurrentAppHost = (): string =>
  window.location.protocol + '//' + window.location.host;

export function toggle<T>(array: T[], value: T) {
  return array.includes(value) ? array.filter(el => el !== value) : [...array, value];
}

/**
 * Takes the last 3 sections of url.
 *
 * Example:
 * https://storage.googleapis.com/flurosat-us-west1-predate-regrow/processed-v1/md5/date/date_TERRAV_PLN_ndvi.png
 * ->
 * md5/date/date_TERRAV_PLN_ndvi.png
 */
export const getImagePath = (url = '') => url.split('/').slice(-3).join('/');

export const makeSureEndsWithSlash = (url: string) => {
  return url.endsWith('/') ? url : url + '/';
};

/**
 * Formats the number of bytes to _metric_ units.
 * e.g. 16.12 kB, 1.23 MB
 * https://en.wikipedia.org/wiki/Kilobyte
 */
export function formatBytes(bytes: number, decimals = 0) {
  const k = 1000;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['bytes', 'kB', 'MB', 'GB', 'TB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))}${sizes[i]}`;
}

export const getCurrencyChar = (currency_code: string) => {
  switch (currency_code) {
    case 'EUR':
      return '€';
    case 'GBP':
      return '£';
    case 'USD':
    case 'AUD':
    default:
      return '$';
  }
};

export function omit<T>(obj: T, keys: (keyof T)[]) {
  const result = {...obj};
  keys.forEach(key => delete result[key]);
  return result;
}

/**
 *
 * Converts text to Title Case
 */
export const toTitleCase = (text: string) => {
  return text
    .split('_')
    .filter(x => x.length > 0)
    .map(x => x.charAt(0).toUpperCase() + x.slice(1).toLowerCase())
    .join(' ');
};

export const isRegrowDomain = () => {
  return window.location.host.includes('regrow.ag');
};
