// @ts-nocheck
import {t} from 'i18n-utils';
import {booleanIntersect, convertFromSquareMetersToMeasure, formatUnit, convertUnit} from '_utils';
import {downloadFile, genKey} from '_utils/pure-utils';
import {showNotification} from 'components/notification/notification';
import tokml from '_utils/tokml';
import {
  ANOMALY_LABELS,
  getAnomalyLabelValue,
  GLOBAL_FORMAT_DATE,
  PERMANENT_ANOMALIES,
} from '_constants';
import Mixpanel from '_utils/mixpanel-utils';
import {SeasonApi} from '_api';
import moment from 'moment';
import type {IInitialMapState, SourceType} from '../../types';
import {area as turfArea, feature as turfFeature, featureCollection} from '@turf/turf';
import {
  updateBulkPremiumAnomaly,
  uploadAnomaliesToAgworld,
  uploadAnomaliesToAgX,
} from '../../actions/anomalies-actions';
import type {TSensor} from 'types';
import type {IAnomaly, Priority, AnomalyTab} from './types';
import type {AppDispatch} from 'store';
import convert from 'convert-units';
import type {AppStore} from 'reducers';
import {selectCurrentDate, selectCurrentFarm, selectCurrentField} from '../../reducer/selectors';
import {selectMeasurement} from '../../../login/login-selectors';
import {dialogToggle, DialogType} from 'modules/helpers';

export const userFacingPriority = (priority: Priority, label: string) => {
  if (PERMANENT_ANOMALIES.includes(label)) {
    return '—';
  }
  if (priority === 'medium') {
    return 'med';
  }
  if (!priority) {
    return 'new';
  }
  return priority;
};

/**
 * @param area in square meters
 * @param measurement 'ha' | 'ac'
 */
export const userFacingArea = (area: number, measurement: string) => {
  if (!area) {
    return '';
  }
  const a = Math.abs(convertFromSquareMetersToMeasure(area, measurement));
  return `${a.toFixed(2)} ${t({id: measurement})}`;
};

export const isPermanentAnomaly = (anomaly: IAnomaly) => {
  return isPermanentAnomalyLabel(anomaly?.properties?.label);
};
export const isPermanentAnomalyLabel = (label = '') => {
  return PERMANENT_ANOMALIES.includes(label);
};

const getFileNameToExport = (fileType: string) => (dispatch: any, getState: any) => {
  const {field, group, currentDate}: IInitialMapState = getState().map;
  const fileName = `${group.name}_${field.Name}`;
  const formattedCurrentDate = moment(currentDate, 'DD/MM/YYYY').format(GLOBAL_FORMAT_DATE);
  return `Anomalies_${fileName}_${formattedCurrentDate}.${fileType}`;
};

export const exportAnomaliesToShp =
  (anomalies: IAnomaly[]) => (dispatch: AppDispatch, getState: () => AppStore) => {
    if (!anomalies.length) {
      showNotification({
        title: t({id: 'note.info', defaultMessage: 'Info'}),
        message: t({id: 'Please select AOI or Stress Detected object.'}),
        type: 'info',
      });

      return false;
    }

    const preparedGeoJsonObjects = prepareFeaturesForExport(anomalies, getState());

    Mixpanel.exportAnomalies('SHP');
    SeasonApi.downloadShapefile(preparedGeoJsonObjects).then(({data}) => {
      downloadFile(data, dispatch(getFileNameToExport('zip')));
    });
  };

export const exportAnomaliesToKml =
  (anomalies: IAnomaly[]) => (dispatch: AppDispatch, getState: () => AppStore) => {
    const preparedGeoJsonObjects: any = prepareFeaturesForExport(anomalies, getState());
    Mixpanel.exportAnomalies('KML');
    //@ts-expect-error error leftover from convertion to strict mode, please fix
    downloadFile(
      tokml(featureCollection(preparedGeoJsonObjects)),
      dispatch(getFileNameToExport('kml'))
    );
  };

export const selectedPremiumAnomaliesChangeProp =
  (prop: string, val?: any) => (dispatch: any, getState: any) => {
    const {
      premiumAnomalies: {list},
    }: IInitialMapState = getState().map;
    const anomalies = list.filter((el: any) => el.properties.checked);
    const value = prop === 'snoozed' ? (anomalies.every(a => a.properties.snoozed) ? 0 : 1) : val;
    dispatch(
      updateBulkPremiumAnomaly({
        anomalies,
        prop,
        value,
      })
    );
  };

export const onExportToAgX = (anomalies: IAnomaly[], type: AnomalyTab) => (dispatch: any) => {
  if (type === 'areas-of-interest') {
    const isLowPerfPersist = anomalies.some((g: any) => g.properties.isLowPerf);
    if (isLowPerfPersist)
      return dispatch(dialogToggle(DialogType.geometriesNotAllowedToExportToAgx, true));
  }

  let booleanIntersectAny = false;
  anomalies.forEach((feature1, index) => {
    if (!booleanIntersectAny) {
      anomalies.forEach((feature2, internalIndex) => {
        if (internalIndex !== index) {
          booleanIntersectAny = booleanIntersect(feature1, feature2);
        }
      });
    }
  });

  if (booleanIntersectAny) {
    return showNotification({
      title: t({id: 'note.warning', defaultMessage: 'Warning'}),
      message: t({
        id: 'agX does not support overlapping crop stress. Please select not overlapping crop stress to upload.',
      }),
      type: 'warning',
    });
  }
  Mixpanel.exportAnomalies('AgX');
  return dispatch(uploadAnomaliesToAgX(anomalies, type));
};

export const onExportToAgworld = (anomalies: IAnomaly[]) => (dispatch: any) => {
  if (!anomalies.length) {
    showNotification({
      title: t({id: 'note.info', defaultMessage: 'Info'}),
      message: t({id: 'Please select some anomalies to export to Agworld.'}),
      type: 'info',
    });
    return;
  }
  Mixpanel.exportAnomalies('Agworld');
  dispatch(uploadAnomaliesToAgworld(anomalies));
};

export const sumAreas = (anomaliesList: IAnomaly[], measurement: string): string | undefined => {
  if (!anomaliesList.length) {
    return;
  }

  let areasSum = 0;
  anomaliesList.forEach(a => {
    areasSum += a.properties.area;
  });
  if (!areasSum) {
    return;
  }

  return userFacingArea(areasSum, measurement);
};

type SetLowPerfAnomalyProps = {
  features: IAnomaly[];
  date: string;
  dateType: SourceType;
  savedAsRoi: boolean;
};

export const setLowPerfAnomalyProps = (
  {features = [], date, dateType, savedAsRoi}: SetLowPerfAnomalyProps,
  currentSensor: TSensor,
  isLowPerf = false // it can be an uploading ROI geometry
) => {
  return features.map(geometry => {
    const {properties} = geometry;
    // get label value, cause when we export anomaly we save the label, not the value and then
    // we catch the error - No anomaly label info found for the value "some anomaly label"
    const anomalyLabel = getAnomalyLabelValue(properties.label || '');
    if (!isLowPerf) {
      geometry.properties.uploading = true;
    }
    return {
      ...geometry,
      date,
      dateType,
      index: currentSensor,
      savedAsRoi,
      properties: {
        ...geometry.properties,
        area: turfArea(geometry),
        id: geometry.properties.id || genKey(),
        isLowPerf, // to recognize low perf
        startDate: geometry.properties.startDate || date, // for low performing start - end date is the same, cause they are generated for each sensing day
        endDate: geometry.properties.endDate || date,
        checked: false, // reset to default, prevent side effects
        label: anomalyLabel,
      },
    };
  });
};

export const prepareAllLowPerfAnomalies = (lowPerformingAnomalies: any) => {
  const lowPerformingAreas = {} as any;
  lowPerformingAnomalies
    .filter((a: any) => a.GeoJSON.features)
    .forEach((feature: any) => {
      const featureSensor = feature.ImageID.split('_').pop().toUpperCase();
      feature.GeoJSON.features = setLowPerfAnomalyProps(feature.GeoJSON, featureSensor, true);

      lowPerformingAreas[`${feature.ImageID}.png`] = {
        ...feature.GeoJSON,
        date: feature.Date,
        dateType: feature.Type,
      };
    });
  return lowPerformingAreas;
};

export function prepareFeaturesForExport(
  features: Array<GeoJSON.Feature | GeoJSON.FeatureCollection | GeoJSON.GeometryCollection>,
  state: AppStore
) {
  return features
    .map(gj => {
      if (gj && gj.type === 'FeatureCollection') {
        return {
          type: 'FeatureCollection',
          features:
            gj.features && gj.features.length
              ? gj.features.map((f: any) => clearFeatureBeforeExport(f, 'Stress Detected', state))
              : [],
        };
      }

      if (gj && gj.type === 'Feature') {
        return clearFeatureBeforeExport(
          gj,
          gj.properties.isLowPerf ? 'Low performing area' : 'ROI',
          state
        );
      }

      return null;
    })
    .filter(gj => gj);
}

const clearFeatureBeforeExport = (feature: any, info: string, state: AppStore) => {
  const fieldName = selectCurrentField(state)?.Name;
  const farm = selectCurrentFarm(state);
  const measurement = selectMeasurement(state);
  const currentDate = selectCurrentDate(state);

  // use turfFeature for cleaning props from trash params
  let tf = turfFeature({
    type: feature?.geometry?.type || 'Polygon',
    geometries: feature?.geometry?.type === 'GeometryCollection' ? feature.geometry.geometries : [],
    coordinates: feature?.geometry?.coordinates ? feature.geometry.coordinates : [],
  });

  const lbl = ANOMALY_LABELS().find(a => a.value === feature.properties.label);
  tf.properties = {
    farmName: farm?.name,
    fieldName,
    label: lbl ? lbl.label : 'No Label',
    area:
      convertUnit(measurement, 'ac', convert(turfArea(tf)).from('m2').to('ha')) +
      formatUnit(measurement, 'ha'),
    info,
    endDate: feature.properties.endDate || moment(currentDate, 'DD/MM/YYYY').format('YYYY-MM-DD'),
    startDate:
      feature.properties.startDate || moment(currentDate, 'DD/MM/YYYY').format('YYYY-MM-DD'),
    growerName: farm?.growerName || '-',
  };

  return tf;
};
