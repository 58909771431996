import styled from 'styled-components';
import colors from 'variables.scss';

export const Wrapper = styled.div`
  // this should have been implemented within Dialog component
  // https://regrow.atlassian.net/browse/FSB-5476
  // FIXME:
  // adds content scroll for modals that don't fit the screen vertically
  max-height: 90vh;
  overflow: auto;

  .resource-link {
    background-color: #fff;
    border: 1px solid #d6d8da;
    border-radius: 4px;
    padding: 10px 15px;
    transition: border 0.2s;
    cursor: pointer;
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    &:hover {
      border: 1px solid #757575;
    }
  }

  .item-icon {
    margin-right: 10px;
    height: 76px;
    width: 76px;
  }

  .item-title {
    color: ${colors['main-gray-900']};
    text-transform: uppercase;
    font-weight: 500;
    margin-bottom: 5px;
  }

  .item-description {
    color: ${colors['main-gray-600']};
  }
`;
