import type {BarExtendedDatum} from '@nivo/bar';
import {Flex, Text} from 'components';
import React from 'react';
import {StyledEmissionsBarTooltip} from '../../styled-components';
import type {BarDetails} from './bar-charts';

export const BarTooltip = ({
  data: {
    indexValue,
    data: {barDetails},
  },
}: {
  data: BarExtendedDatum & {data: {barDetails: BarDetails}};
}) => {
  if (!barDetails) return null;
  const name = String(indexValue).split('+++'); //assumes indexValue comes in a format divided by +++
  const scenarioName = name[1];
  const cropName = name[2];

  return (
    <StyledEmissionsBarTooltip color={barDetails.color} scenarioRank={barDetails.scenario}>
      <Flex className="mb-0">
        <Text variant="medium" bold className="scenario-name">
          {scenarioName}
          <Text elementType={'span'} noMargin>
            {cropName}
          </Text>
        </Text>
      </Flex>
      <Flex justifyContent="space-between" fullWidth gap={8}>
        <Text variant="medium" noMargin>
          Baseline
        </Text>
        <Text variant="medium" bold noMargin>
          {barDetails.baseline}
        </Text>
      </Flex>
      <Flex justifyContent="space-between" fullWidth gap={8}>
        <Text variant="medium">With interventions</Text>
        <Text variant="medium" bold>
          {barDetails.withInterventions}
        </Text>
      </Flex>
      <Flex justifyContent="space-between" fullWidth gap={8}>
        <Text variant="medium" noMargin>
          Difference
        </Text>
        <Text variant="medium" bold noMargin>
          {barDetails.difference}
        </Text>
      </Flex>
      <Flex justifyContent="space-between" fullWidth gap={8}>
        <Text variant="medium" noMargin>
          % change
        </Text>
        <Text variant="medium" bold noMargin>
          {barDetails.percentChange}%
        </Text>
      </Flex>
    </StyledEmissionsBarTooltip>
  );
};
