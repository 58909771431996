import LoRange from 'lodash/range';
import type {AreaId, CountyFIPS, SIAggLevel, StateFP} from './types';
import {clamp} from '../../_utils';
import {toFixedFloat} from '_utils/number-formatters';
import {LOW_ABATEMENT_THRESHOLD} from './constants';
import {isNumber} from '_utils/typeGuards';

/**
 * Parses string of range formats into tuple representing range number values from and to:
 * <33 - [-Infinity, 33]
 * >22 - [22, Infinity]
 * 12-30 - [12,30]
 */
export const parseNumRangeString = (str: string): [number, number] => {
  const rangeMatch = str.match(/(\d+)-(\d+)/);
  const lessThanMatch = str.match(/<(\d+)/);
  const moreThanMatch = str.match(/>(\d+)/);

  if (rangeMatch) {
    const [, from, to] = rangeMatch;

    return [Number(from), Number(to)];
  } else if (lessThanMatch) {
    const from = -Infinity;
    const [, to] = lessThanMatch;

    return [from, Number(to)];
  } else if (moreThanMatch) {
    const to = Infinity;
    const [, from] = moreThanMatch;

    return [Number(from), to];
  }

  throw new TypeError(
    'Cannot parse range string. Make sure it comply to one of the RegExps: "/(d+)-(d+)/", "/<(d+)/", "/>(d+)/"'
  );
};

/**
 * Returns number between 0 and 1 which is a representation of `value`'s scale position between `min` and `max` values
 */
export const calculateScale = (value: number, min: number, max: number): number => {
  if (max === min) return 1;
  return (1 / (max - min)) * (value - min);
};

export const isYearsDiff = (years: number[]): boolean => {
  if (!years) return false;
  const [yearFrom, yearTo] = years;
  const isRange = Boolean(yearFrom && yearTo);

  return isRange;
};

/**
 * This utility function retrieves county's StateFP from the CountyFIPS identifier.
 * County's id - `county_fips` - consist of two parts: the 3 characters county id that prefixed with the state id which it belongs to.
 * |StateFP|County FIPS|
 *        5|021          - Clay county (county_fips: 5021) from the Arkansas state (state_fp: 5)
 *       17|001          - Adams county (county_fips: 17001) from the Illinois state (state_fp: 17)
 *
 *       there is NEW special case for Canadian states, the states there have format 9999 + two digits (ex. 999911)
 *       * |StateFP|County FIPS|
 *        911|01          - Kings county (county_fips: 91101) from the Prince Edward Island (state_fp: 999911)
 *        935|01          - Stormont, Dundas and Glengarry county (county_fips: 93501) from the Ontario state (state_fp: 999935)
 */
export const getStateFP = (countyFIPS: CountyFIPS): StateFP => {
  const isCanadianCase = String(countyFIPS).length === 5 && String(countyFIPS).indexOf('9') === 0;
  let stateFP = 0;

  if (isCanadianCase) {
    stateFP = parseInt(`999${String(countyFIPS).slice(0, -2)}`, 10);
  } else {
    stateFP = parseInt(String(countyFIPS).slice(0, -3), 10);
  }
  return stateFP;
};

export const parseAreaId = (id: string): AreaId => {
  return parseInt(id, 10);
};

export const classifyAbatementPotentialFilterScale = (maxValue: number): number[] => {
  if (!Number.isFinite(maxValue)) return [];
  const formattedMaxValue = toFixedFloat(maxValue, 1);
  const chunksAmount = clamp(1, maxValue / 0.1, 4); // calc min chunks amount, because maxValue can be 0.1;
  const chunk = toFixedFloat(maxValue / chunksAmount, 1);
  const result = LoRange(chunk, formattedMaxValue, chunk)
    .map(v => toFixedFloat(v, 1))
    .concat(formattedMaxValue);

  return result;
};

export const classifyAbatementPotentialFilterScaleLabel = (filterRange: number[]) => {
  return filterRange
    .map((value, index, arr) => {
      if (index === 0) {
        if (value !== LOW_ABATEMENT_THRESHOLD) return `${LOW_ABATEMENT_THRESHOLD} - ${value}`;
        return null;
      }
      return `${arr[index - 1]} - ${value}`;
    })
    .filter(Boolean);
};

export const calcSummaryChartExtrema = (values: number[]) => {
  // special calculation for min value, it should be 0 or less otherwise labels positioning issue apperas in the chart
  const rawMinValue = Math.min(...values);

  return {minValue: rawMinValue > 0 ? 0 : rawMinValue, maxValue: Math.max(...values)};
};

export const isInRange = (value: number, range: [number, number]): boolean => {
  return value >= range[0] && value <= range[1];
};

const trimString = (str: string, limit: number): string => {
  if (!str || str.length < limit) return str;
  return `${str.slice(0, limit)}...`;
};

export const getSummaryChartLabel = (name: string, upper = false): string => {
  if (!name) return '';
  const UPPERCASE_LENGTH_LIMIT = 14;
  const LOWERCASE_LENGTH_LIMIT = 16;

  return upper
    ? trimString(name.toUpperCase(), UPPERCASE_LENGTH_LIMIT)
    : trimString(name, LOWERCASE_LENGTH_LIMIT);
};

export const aggregationLabelPlural = (aggLevel: SIAggLevel) => {
  if (aggLevel === 'county') {
    return 'counties';
  } else {
    return `${aggLevel}s`;
  }
};

export const safeDivision = (dividend: number, divisor: number): number | null => {
  if (isNumber(dividend) && isNumber(divisor)) {
    const result = dividend / divisor;

    return isFinite(result) ? result : null;
  } else return null;
};
