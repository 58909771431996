import {Flex, Text} from 'components';
import {selectMeasurement} from 'containers/login/login-selectors';
import type {MRVEntityType, MRVFarm, MRVField, MRVMob} from 'containers/mrv/types';
import React from 'react';
import {useAppSelector} from '_hooks';
import {convertUnit} from '_utils';
import {unreachableError} from '_utils/pure-utils';
import {kiloFormatter} from '_utils/number-formatters';
import {MRVFieldPreview} from './mrv-field-preview';
import {isFarm, isField, isMob} from './base';

export const EntityName = ({
  entityType,
  entity,
}: {
  entityType: MRVEntityType;
  entity: MRVFarm | MRVField | MRVMob;
}) => {
  switch (entityType) {
    case 'field':
      return isField(entityType, entity) ? <FieldName field={entity} /> : null;
    case 'farm':
      return isFarm(entityType, entity) ? <FarmName farm={entity} /> : null;
    case 'mob':
      return isMob(entityType, entity) ? <MobName mob={entity} /> : null;
    default:
      unreachableError(
        entityType,
        `<EntityName/> component doesn't support ${entityType} entity type`
      );
  }
  return null;
};

const FieldName = ({field}: {field: MRVField}) => {
  const measurement = useAppSelector(selectMeasurement);
  const fieldGeometry = field?.core_attributes?.geometry;
  const fieldName = field?.core_attributes?.field_name;
  const farmName = field?.core_attributes?.farm_name;
  const fieldArea = field?.core_attributes?.field_area;
  const convertedFieldArea = kiloFormatter(convertUnit(measurement, 'ac', fieldArea));
  return (
    <Flex alignItems="center" nowrap gap="12px">
      {!!fieldGeometry && <MRVFieldPreview geometry={fieldGeometry} />}
      <div>
        <Flex alignItems="center">
          <Text className="field-name" elementType="div" variant="medium" title={fieldName || ''}>
            {fieldName}
          </Text>
          <Text secondary variant="small">
            #{field.id}
          </Text>
        </Flex>

        <Text className="farm-name" elementType="div" variant="small" title={farmName || ''}>
          {convertedFieldArea} {measurement} | {farmName}
        </Text>
      </div>
    </Flex>
  );
};

const FarmName = ({farm}: {farm: MRVFarm}) => {
  return <div>{farm.farm_name}</div>;
};

const MobName = ({mob}: {mob: MRVMob}) => {
  return <div>{mob.mob_name}</div>;
};
