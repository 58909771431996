// @ts-nocheck
import {t} from 'i18n-utils';
import React, {useCallback, useState} from 'react';
import {useDispatch} from 'react-redux';
import moment from 'moment';
import SelectFarmChips from 'containers/select-farm-chips';
import {TextField, FontIcon} from 'react-md';
import {KmlApi} from '_api';
import {showNotification} from 'components/notification/notification';
import type {Farm} from '../../../types';
import {
  FluroDialog,
  FluroDataTable,
  FluroTableBody,
  FluroTableColumn,
  FluroTableHeader,
  FluroTableRow,
  InfoBlock,
  FluroButton,
} from 'components';
import {useAppSelector} from '_hooks';
import {Controller, useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import {reportError} from '../../../../error-boundary';
import {saveFarm} from 'modules/farms/actions';
import {selectFarmsList} from 'modules/farms/selectors';
import {selectCurrentFarmId, selectMapFields} from '../../../reducer/selectors';

type Props = {
  visible: boolean;
  togglePopUp(value: boolean): void;
};

type Payload = {
  farmIds: number[];
  farmName?: string;
};

export const CloneFieldPopup = ({visible, togglePopUp}: Props) => {
  const dispatch = useDispatch();

  const currentFarmId = useAppSelector(selectCurrentFarmId);
  const fields = useAppSelector(selectMapFields);
  const farms = useAppSelector(selectFarmsList);

  const [isAddNewFarm, setIsAddNewFarm] = useState(false);

  const {
    formState: {errors},
    control,
    handleSubmit,
    watch,
  } = useForm<Payload>({
    resolver: yupResolver(
      Yup.object().shape({
        farmIds: Yup.array().of(Yup.number()),
        farmName: Yup.string(),
      })
    ),
    defaultValues: {farmIds: [], farmName: ''},
  });

  const values = watch();

  const hide = useCallback(() => {
    togglePopUp(false);
  }, []);

  const onSave = useCallback(
    async (data: Payload) => {
      const fieldIds = fields.filter(f => f._selected).map(f => ({id: f.ID, name: f.Name}));

      try {
        if (isAddNewFarm) {
          const farm: any = await dispatch(
            saveFarm({
              id: 0,
              name: data.farmName,
            })
          );

          await KmlApi.clonefields(fieldIds, [farm.id], currentFarmId);
        } else {
          await KmlApi.clonefields(fieldIds, data.farmIds, currentFarmId);
        }

        hide();

        showNotification({
          title: t({id: 'note.success', defaultMessage: 'Success'}),
          message: t({id: 'Your field was cloned successfully'}, {count: fieldIds.length}),
          type: 'success',
        });
      } catch (e) {
        reportError(e.message);
        showNotification({
          title: t({id: 'note.error', defaultMessage: 'error'}),
          message: e.message,
          type: 'error',
        });
      }
    },
    [currentFarmId, fields, isAddNewFarm]
  );

  return (
    <FluroDialog
      id={`clone-field-dialog`}
      visible={visible}
      title={t({id: 'Fields selected for coping.'})}
      onHide={hide}
      focusOnMount={false}
      isDraggable
      contentStyle={{minWidth: '450px', minHeight: 350}}
      width="auto"
      height="auto"
      portal
    >
      <FluroDataTable elevated={false}>
        <FluroTableHeader>
          <FluroTableRow>
            <FluroTableColumn>{t({id: 'Name'})}</FluroTableColumn>
            <FluroTableColumn type={'date'}>{t({id: 'Created Date'})}</FluroTableColumn>
          </FluroTableRow>
        </FluroTableHeader>

        <FluroTableBody>
          {fields
            .filter(el => el._selected)
            .map((f, i) => (
              <FluroTableRow key={`field-clone-row-${i}`}>
                <FluroTableColumn>{f.Name}</FluroTableColumn>
                <FluroTableColumn type={'date'}>
                  {moment(f.CreateTime).format('MM/DD/YYYY')}
                </FluroTableColumn>
              </FluroTableRow>
            ))}
        </FluroTableBody>
      </FluroDataTable>

      {farms.filter((f: Farm) => !f.readOnly).length > 1 ? (
        <form onSubmit={handleSubmit(onSave)} id="clone-fields">
          <div style={{display: isAddNewFarm ? 'block' : 'none'}}>
            <Controller
              name="farmName"
              control={control}
              render={({field: {value, onChange}}) => (
                <TextField
                  id="add-new-farm-input-name-3"
                  placeholder={t({id: 'Farm name'})}
                  label={t({id: 'Farm name'})}
                  errorText={errors?.farmName?.message}
                  error={!!errors.farmName}
                  value={value}
                  onChange={onChange}
                  inlineIndicator={
                    <FontIcon
                      style={{right: 0, cursor: 'pointer'}}
                      onClick={() => setIsAddNewFarm(false)}
                    >
                      clear
                    </FontIcon>
                  }
                />
              )}
            />
          </div>

          <div style={{display: !isAddNewFarm ? 'block' : 'none'}}>
            <Controller
              name="farmIds"
              control={control}
              render={({field: {onChange}}) => (
                <SelectFarmChips skipReadOnly onSelectCallback={onChange} />
              )}
            />
          </div>

          {!isAddNewFarm && (
            <div style={{display: 'flex', justifyContent: 'flex-end'}}>
              <FluroButton
                className="add-more-btn"
                iconBefore
                iconEl={<FontIcon>add_circle_outline</FontIcon>}
                onClick={() => setIsAddNewFarm(true)}
              >
                {t({id: 'Add a new farm'})}
              </FluroButton>
            </div>
          )}
        </form>
      ) : (
        <InfoBlock appearance="info" className={'inside-a-pop-up'}>
          {t({id: 'You do not have available farms for the copy fields'})}
        </InfoBlock>
      )}

      <div className="d-flex justify-between">
        <FluroButton blank raised key="cancel-clone-field" onClick={hide}>
          {t({id: 'Cancel'})}
        </FluroButton>

        <FluroButton
          raised
          disabled={(!isAddNewFarm && !values.farmIds.length) || (isAddNewFarm && !values.farmName)}
          primary
          key="run-clone-field"
          form="clone-fields"
          type="submit"
        >
          {t({id: 'Copy'})}
        </FluroButton>
      </div>
    </FluroDialog>
  );
};
