import type {ReactElement} from 'react';
import Mixpanel from '_utils/mixpanel-utils';
import type {LoginResponse, LoginPayload, UpdatePasswordPayload} from './types';
import {LoginActionTypes} from './types';
import {AuthApi, MRVApi, UserApi, retry} from '_api';
import {hardClear} from 'containers/map/actions';
import {push} from 'connected-react-router';
import {loadCropTypes, setGlobalParam, toggleSessionExpired} from 'modules/global/actions';
import {showNotification} from 'components/notification/notification';
import {getFarmsList, selectFarm} from 'modules/farms/actions';
import type {Farm, IInitialMapState} from '../map/types';
import {reportError} from '../error-boundary';
import type {AppStore} from 'reducers';
import {applyPathParams} from '_utils/pure-utils';
import {getDataFromRedirectUrl} from '_utils';
import type {Locales} from 'i18n-utils';
import {t, ALLOWED_LOCALES} from 'i18n-utils';
import type {Dispatch} from 'redux';
import {dialogToggle, DialogType} from '../../modules/helpers';
import type {AppDispatch} from 'store';
import {getUserToken, safeLocalStorage} from '_utils/safe-local-storage';
import {MRV_ADMIN_PROGRAM_CONFIGURE, MRV_ADMIN} from '../mrv/routes';
import {selectUser, selectUserSettings} from './login-selectors';
import type {User, UserSettings} from '../admin/users/types';
import {checkWorkspace, Workspace} from '../admin/users/types';
import type {IntegrationPlatform} from '../profile/integration/types';
import {responseWarningMessage} from '../../_api/messages';
import {ProgramTab} from 'containers/mrv/admin/types';
import {getLocalStorage, removeLocalStorage} from '_hooks/use-storage';

export const setLogin =
  (response: {token: string; user: User}, loginType?: string) => (dispatch: AppDispatch) => {
    if (loginType) {
      Mixpanel.login(response?.user, loginType);
    }
    dispatch({
      type: LoginActionTypes.LOGIN,
      response,
    });
  };

//TODO: add dispatch generics

export const authenticate =
  (credentials: LoginPayload, mrvProgramId?: number) =>
  (dispatch: any, getStore: () => AppStore) => {
    return AuthApi.authenticate(credentials)
      .then(async ({data}) => {
        const newToken = data.result.token;
        const user = data.result.user;

        safeLocalStorage.setItem('token', newToken);

        if (!newToken || !user.active) {
          showNotification({
            title: t({id: 'note.info', defaultMessage: 'Info'}),
            message: t({
              id: 'notActiveAccount',
              defaultMessage: 'Your account is not active, please contact support@regrow.ag',
            }),
            type: 'info',
            autoClose: 30000,
          });
        } else {
          const s = getStore();
          const {isWorkspaceMrv} = checkWorkspace();

          // reImpersonate user after session expire
          if (isWorkspaceMrv && s.login.isImpersonated) {
            const user = selectUser(s);
            // re impersonate user
            if (mrvProgramId && user?.id) {
              const impUserResult = await MRVApi.impersonateProducer(
                mrvProgramId,
                user?.id as number
              );
              safeLocalStorage.setItem('token', impUserResult.data.result);
            }
          } else {
            const {
              data: {result: profileResult},
            } = await UserApi.getProfile(); // get user data only from the profile request

            await dispatch(loadCropTypes());
            dispatch(getFarmsList());

            let {farmId} = getDataFromRedirectUrl();
            const groupIds = profileResult.user?.groupIds || [];
            farmId = farmId || groupIds[0];

            if (!isWorkspaceMrv && farmId) {
              dispatch(setGlobalParam('currentGroupId', farmId));
            }

            const localStoredLocale = safeLocalStorage.getItem('lang') as Locales;

            if (
              ALLOWED_LOCALES.includes(localStoredLocale) &&
              profileResult.user.settings &&
              !profileResult.user.settings.langLocale
            ) {
              profileResult.user.settings.langLocale = localStoredLocale;
            }

            //hide login dialog for carbon domain
            dispatch(dialogToggle(DialogType.carbonLogin, false));
            dispatch(setLogin(profileResult, 'Login form'));
          }
        }

        const requestsToRetry = getStore().global.sessionExpiredRequests;

        // The requests should be retried with the new auth token.
        requestsToRetry.forEach(r => (r.config.headers['x-access-token'] = newToken));
        requestsToRetry.forEach(retry);
        dispatch(toggleSessionExpired(false));
      })
      .catch(err => {
        if (!responseWarningMessage(err?.data?.result)) {
          reportError(`login error = ${JSON.stringify(err)}`);
        }
      });
  };

export const oAuthLogin = (source: string, code: string) => (dispatch: any) => {
  // @ts-expect-error error leftover from convertion to strict mode, please fix
  if (!AuthApi[`${source}Auth`]) return Promise.reject('Undefined source');
  // @ts-expect-error error leftover from convertion to strict mode, please fix
  return AuthApi[`${source}Auth`](code)
    .then(({data}: {data: LoginResponse}) => {
      if (data.result.user && data.result.user.signupUUID) {
        dispatch(push(`/sign-up/${data.result.user.signupUUID}/g`));
      } else {
        if (data.result.user.active) {
          safeLocalStorage.setItem('token', data.result.token);

          // hardest clear of the map store
          dispatch(hardClear(true));

          let {farmId} = getDataFromRedirectUrl();
          const groupIds = data.result.user?.groupIds || [];
          farmId = farmId || groupIds[0];
          const {isWorkspaceMrv} = checkWorkspace();

          if (!isWorkspaceMrv && farmId) {
            dispatch(setGlobalParam('currentGroupId', farmId));
          }

          dispatch(setLogin(data.result, 'oAuth'));

          dispatch(getFarmsList());
        }
      }

      return data;
    })
    .catch((err: Error) => reportError(`oAuthLogin(), source=${source}, err = ${err}`));
};

export const checkAuth = () => (dispatch: Dispatch<any>) => {
  return new Promise((resolve, reject) => {
    const token = getUserToken();
    if (!token) {
      return reject('No token set');
    }

    UserApi.getProfile()
      .then(async ({data, status}) => {
        if (status === 401) {
          return reject(401);
        }

        let {farmId} = getDataFromRedirectUrl();
        const groupIds = data.result?.user?.groupIds || [];
        farmId = farmId || groupIds[0];
        const {isWorkspaceMrv} = checkWorkspace();

        if (!isWorkspaceMrv && farmId) {
          dispatch(setGlobalParam('currentGroupId', farmId));
        }

        if (data.result) {
          dispatch(setLogin(data.result, 'Revalidate login'));
        } else {
          if (status === 200) return;
          reportError(`checkAuth() err = status: ${status}, data: ${data}`);
        }

        resolve(data);
      })
      .catch(err => {
        reportError(`checkAuth() err = ${err}`);
        reject(err);
      });
  });
};

export const getAndSetUserProfile = () => (dispatch: AppDispatch) => {
  UserApi.getProfile().then(({data}) => {
    if (data.result) {
      dispatch(setLogin(data.result, 'Impersonate login'));
    }
  });
};

export const logout = () => async (dispatch: Dispatch<any>) => {
  try {
    const t = getUserToken();

    if (t) {
      await AuthApi.logout(t);
    }

    safeLocalStorage.removeItem('token');

    dispatch({type: LoginActionTypes.LOGOUT});
    dispatch(hardClear(true));
  } catch (error) {}
};

export const verifyEmail = (uuid: string) => () => {
  return AuthApi.verifyEmail(uuid).then(({data}) => {
    return data.result;
  });
};

export const sendEmailResetPassword = (email: string) => (_dispatch: Dispatch<any>) => {
  const EMAIL_ADDRESS_NOT_FOUND = 'Email address not found';
  return AuthApi.emailResetPassword(email)
    .then(({data}) => data.result)
    .catch(err => {
      if (err?.data?.result !== EMAIL_ADDRESS_NOT_FOUND)
        reportError(`sendEmailResetPassword() err = ${err}`);
      return Promise.reject(err?.data?.result);
    });
};

export const sendEmailResetPasswordCarbon =
  (email: string, cbDone?: () => void) => (dispatch: Dispatch<any>) => {
    return AuthApi.emailResetPasswordCarbon(email)
      .then(({data}) => {
        showNotification({
          title: t({id: 'note.success', defaultMessage: 'Success'}),
          message: t({id: 'Please check your inbox to reset your password.'}),
          type: 'success',
        });

        cbDone?.();
        dispatch(push('/carbon/login'));

        return data.result;
      })
      .catch(err => {
        if (err?.data?.result !== 'Email address not found')
          reportError(`sendEmailResetPassword() err = ${err}`);
      });
  };

export const resetPassword =
  (params: UpdatePasswordPayload, cbDone?: () => void) => (dispatch: Dispatch<any>) => {
    return AuthApi.resetPassword(params)
      .then(({data}) => {
        showNotification({
          title: t({id: 'note.success', defaultMessage: 'Success'}),
          message: t({id: 'Password was saved, please login with the new password.'}),
          type: 'success',
        });

        dispatch(push('/login'));

        cbDone?.();

        return data;
      })
      .catch(err => {
        reportError(`resetPassword() err = ${err}`);
      });
  };

export const resetPasswordCarbon =
  (params: UpdatePasswordPayload, cbDone?: () => void) => (dispatch: Dispatch<any>) => {
    return AuthApi.resetPassword(params)
      .then(({data}) => {
        showNotification({
          title: t({id: 'note.success', defaultMessage: 'Success'}),
          message: t({id: 'Password was saved, please login with the new password.'}),
          type: 'success',
        });

        dispatch(push('/carbon/login'));

        cbDone?.();

        return data;
      })
      .catch(err => {
        reportError(`resetPassword() err = ${err}`);
      });
  };

export const updateUserPassword = (params: UpdatePasswordPayload) => () => {
  return AuthApi.updateUserPassword(params)
    .then(({data}) => {
      showNotification({
        title: t({id: 'note.success', defaultMessage: 'Success'}),
        message: t({id: 'Password was changed.'}),
        type: 'success',
      });

      return data;
    })
    .catch(err => reportError(`updateUserPassword() err = ${err}`));
};

export const setProfileSettings =
  (settings: UserSettings, customMessage?: ReactElement | string | false, skipPreloader = false) =>
  (dispatch: AppDispatch) => {
    return UserApi.updateProfileSettings(settings, skipPreloader)
      .then(({data}) => {
        if (customMessage !== false) {
          showNotification({
            title: t({id: 'note.success', defaultMessage: 'Success'}),
            message: customMessage || t({id: 'Profile was updated.'}),
            type: 'success',
          });
        }

        dispatch({
          type: LoginActionTypes.UPDATE_USER_SETTINGS,
          settings,
        });

        return data;
      })
      .catch(err => reportError(`setProfileSettings() err = ${err}`));
  };

export const updateUserProfile = (settings: UserSettings) => (dispatch: AppDispatch) => {
  return UserApi.update(settings)
    .then(({data}) => {
      dispatch({
        type: LoginActionTypes.UPDATE_USER_DATA,
        data: settings,
      });

      //FIXME: figure out better way to catch locale loading and show notification
      // do it async to catch language loading
      setTimeout(() => {
        showNotification({
          title: t({id: 'note.success', defaultMessage: 'Success'}),
          message: t({id: 'Profile was updated.'}),
          type: 'success',
        });
      }, 2000);

      return data;
    })
    .catch(err => reportError(`updateUserProfile() err = ${err}`));
};

export const updateUserFavoritePlatforms =
  (platform: IntegrationPlatform, value: boolean) =>
  (dispatch: AppDispatch, getState: () => AppStore) => {
    const userSettings = selectUserSettings(getState());

    const updatedSettings = {
      ...userSettings,
      favorite_platforms: {
        ...(userSettings && userSettings.favorite_platforms),
        [platform]: value,
      },
    };

    //@ts-expect-error error leftover from convertion to strict mode, please fix
    dispatch(setProfileSettings(updatedSettings, false, true));
  };

export const updateUserDemoFarms =
  (demoFarms: number[]) => (dispatch: any, getState: () => AppStore) => {
    const {login, farms} = getState();
    const currentFarmsWithoutDemos =
      login.user.groupIds ||
      [] // clear groups list from demo farms
        .filter((farmId: number) => !farms.demoFarmsIdsList.includes(farmId));

    UserApi.updateDemoFarmsList(demoFarms).then(() => {
      dispatch({
        type: LoginActionTypes.UPDATE_USER_DATA,
        data: {groupIds: [...new Set([...currentFarmsWithoutDemos, ...demoFarms])]},
      });

      // update farms list for load new added Demo farms
      dispatch(getFarmsList()).then((farmsList: Farm[]) => {
        const {
          map: {group},
        }: {map: IInitialMapState} = getState();
        const newDemoFarm = demoFarms.length
          ? farmsList.find(farm => demoFarms.includes(farm.id))
          : null;

        if (newDemoFarm) {
          return dispatch(selectFarm(newDemoFarm.id));
        }

        if (!newDemoFarm && !farmsList.find(farm => farm.id === group.id)) {
          dispatch(selectFarm(farmsList?.[0]?.id));
        }
      });

      showNotification({
        title: t({id: 'note.success', defaultMessage: 'Success'}),
        message: t({id: 'addFarmsMsg'}, {demoFarmsCount: demoFarms.length}),
        type: 'success',
      });
    });
  };

export const unImpersonateUser =
  (workspace: Workspace, programId?: number) => (dispatch: AppDispatch) => {
    AuthApi.unImpersonateUser().then(({data}) => {
      safeLocalStorage.setItem('token', data.result.token);
      dispatch(setLogin(data.result, 'UnImpersonate login'));

      if (workspace === Workspace.Mrv) {
        const unImpersonateRedirectUrl = getLocalStorage('unImpersonateRedirectUrl');

        removeLocalStorage('unImpersonateRedirectUrl');
        const adminPath = programId
          ? applyPathParams(MRV_ADMIN_PROGRAM_CONFIGURE, {
              programId,
              tab: ProgramTab.Producers,
            })
          : MRV_ADMIN;
        window.location.assign(`${unImpersonateRedirectUrl || adminPath}`);
      } else {
        window.location.reload();
      }
    });
  };

export const refreshSessionImpersonateUser = (programId: number, userId: number) => async () => {
  const {data} = await AuthApi.unImpersonateUser();
  safeLocalStorage.setItem('token', data.result.token);

  const result = await MRVApi.impersonateProducer(programId, userId as number);
  safeLocalStorage.setItem('token', result.data.result);
};
