import type {RequestMethod, Dialogs} from 'types';
import type {ReactElement} from 'react';
import type {Locales} from 'i18n-utils';

export type GlobalState = {
  isMapView: boolean;
  isReportView: boolean;
  cropTypes: Record<string, CropType>;
  isHeaderExpanded: boolean;
  isMapLoading: boolean;
  currentGroupId: number;
  growerName: string;
  loaders: any;
  dialogsState: {[key in Dialogs]?: {[key: string]: any}};
  sessionExpired: boolean;
  sessionExpiredRequests: SessionExpiredRequest[]; // requests that failed because session expired
  tabNotifications: {[tab: string]: {hasUpdates: boolean; count?: number}};
  tooltip: ToggleGlobalTooltipProps;
  locale: Locales;
};

export enum GlobalActionTypes {
  TOGGLE_HEADER = 'global/toggle-header',
  TOGGLE_MAP_VIEW = 'toggle-map-view',
  SET_REPORT_VIEW = 'set-report-view',
  SET_GLOBAL_PARAM = 'global/set-global-param',
  IMPORTED_FROM_AGX = 'global/imported-from-agx',
  ADD_LOADING = 'global/add-loading',
  REMOVE_LOADING = 'global/remove/loading',
  MAP_LOADING = 'global/map/loading',
  SET_CROP_TYPES = 'global/set-crop-types',
  TOGGLE_DIALOG = 'global/toggle-dialog',
  TOGGLE_SESSION_EXPIRED = 'global/toggle-session-expired',
  ADD_SESSION_EXPIRED_REQUEST = 'global/add-session-expired-request',
  SET_TAB_NOTIFICATION = 'global/set-tab/notification',
  TOGGLE_GLOBAL_TOOLTIP = 'global/toggle-tooltip',
  UPDATE_CROP_TYPE = 'global/UPDATE_CROP_TYPE',
  ADD_CROP_TYPE = 'global/ADD_CROP_TYPE',
}

export type CropTypeTranslation = 'label_pt-br' | 'label_ru-ru' | 'label_fr-fr';

export type CropTypeTranslationMRV = 'label_pt' | 'label_ru' | 'label_fr';

export type CropLangProps = Record<CropTypeTranslation, string>;

export type CropLangPropsMRV = Record<CropTypeTranslationMRV, string>;

export type CropType = CropLangProps &
  CropLangPropsMRV & {
    id: number;
    cdl_id?: number;
    value: string;
    label: string;
    icon?: string; // icon name, i.e. "corn.svg". Comes from core: api/v1/crops
    icon_url?: string; // icon url, i.e. "https://app.dev.regrow.ag/api/v1/crops/corn/icon". Comes from mrv attributes/options
    type?: string;
    color?: string; // Format is #efefef
    subtypes: {label: string; value: string}[];
  };

type ToggleHeaderAction = {type: GlobalActionTypes.TOGGLE_HEADER; value: boolean};
type ShowLoadingAction = {
  type: GlobalActionTypes.ADD_LOADING;
  URLPattern: string;
  method: RequestMethod;
  key?: string;
};
type HideLoadingAction = {
  type: GlobalActionTypes.REMOVE_LOADING;
  URLPattern: string;
  method: RequestMethod;
  key?: string;
};
type LoadMapAction = {
  type: GlobalActionTypes.MAP_LOADING;
  value: boolean;
};
// Initialize/deinitialize map component.
type ToggleMapViewAction = {
  type: GlobalActionTypes.TOGGLE_MAP_VIEW;
  value: boolean;
};
export type SetGlobalParamAction<K extends keyof GlobalState> = {
  type: GlobalActionTypes.SET_GLOBAL_PARAM;
  propName: K;
  value: GlobalState[K];
};
export type SetCropTypesAction = {
  type: GlobalActionTypes.SET_CROP_TYPES;
  payload: {
    cropTypes: {[key: string]: CropType};
  };
};
type ToggleGlobalDialog = {
  type: GlobalActionTypes.TOGGLE_DIALOG;
  dialog: Dialogs;
  properties: any;
  resetDialogState: boolean;
};
type ToggleSessionExpired = {
  type: GlobalActionTypes.TOGGLE_SESSION_EXPIRED;
  sessionExpired: boolean;
};
type AddSessionExpiredRequest = {
  type: GlobalActionTypes.ADD_SESSION_EXPIRED_REQUEST;
  request: SessionExpiredRequest;
};
export type SessionExpiredRequest = {
  config: any; // axios request config
  resolve: (data: any) => void;
  reject: (error: any) => void;
};

export type SetTabNotification = {
  type: GlobalActionTypes.SET_TAB_NOTIFICATION;
  tabKey: string;
  tabData: any;
};
export type ToggleGlobalTooltipProps = {
  id: string | number;
  content?: string | ReactElement | number;
  place?: 'top' | 'right' | 'bottom' | 'left';
  width?: string; // specify width of the pop-up
  tooltipClassName?: string;
  disabled?: boolean;
};

export type ToggleGlobalTooltip = {
  type: GlobalActionTypes.TOGGLE_GLOBAL_TOOLTIP;
  data: ToggleGlobalTooltipProps;
};
export type SetReportView = {
  type: GlobalActionTypes.SET_REPORT_VIEW;
  value: boolean;
};

export type AddCropType = {
  type: GlobalActionTypes.ADD_CROP_TYPE;
  crop: CropType;
};

export type UpdateCropType = {
  type: GlobalActionTypes.UPDATE_CROP_TYPE;
  crop: CropType;
};

export type GlobalAction =
  | SetTabNotification
  | ToggleHeaderAction
  | ShowLoadingAction
  | HideLoadingAction
  | LoadMapAction
  | ToggleMapViewAction
  | SetGlobalParamAction<keyof GlobalState>
  | SetCropTypesAction
  | ToggleGlobalDialog
  | ToggleSessionExpired
  | AddSessionExpiredRequest
  | ToggleGlobalTooltip
  | SetReportView
  | AddCropType
  | UpdateCropType;
