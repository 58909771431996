import type {ReactElement} from 'react';
import React, {useState, useEffect, useMemo} from 'react';
import {AccessibleFakeButton, DropdownMenu, FontIcon} from 'react-md';
import {PracticeSelect} from './practice-select';
import {TitleComponent} from './practice.styled';
import {FluroButton} from 'components/fluro-button/fluro-button';
import type {SharedProps} from './types';
import {PracticePreviewCircles} from './practice-preview-circles';
import type {Practice} from 'containers/mrv/types';

type Props = SharedProps & {
  id: string | number;
  fieldsNumber?: number;
  handleButton?: ReactElement;
  isPrimary?: boolean;
  /**
   * Displays Practice Icons instead of a dropdown
   */
  isCirclesHandle?: boolean;
  disabled?: boolean;
  onClick?: (ev: React.MouseEvent<HTMLElement>) => void;
};

export const PracticeSelectDropDown = ({
  id,
  items,
  value = [],
  onChange,
  fieldsNumber = 0,
  handleButton,
  isPrimary,
  isCirclesHandle,
  disabled,
  onClick,
}: Props) => {
  const [visible, setVisible] = useState(false);

  const [selected, setSelected] = useState<Array<Practice>>([]);

  useEffect(() => {
    setSelected([...value]);
  }, [value]);

  const titleComponent = (
    <TitleComponent>
      <div className="title">Practice Changes</div>
      <div className="sub-text">
        Choose the matching practice changes
        <br />
        for the {fieldsNumber} field(s) selected
      </div>
    </TitleComponent>
  );

  let handle = useMemo(
    () => (
      <FluroButton
        onClick={onClick}
        raised
        primary={isPrimary}
        iconBefore={false}
        iconEl={<FontIcon>arrow_drop_down</FontIcon>}
        disabled={disabled}
      >
        Edit practices
      </FluroButton>
    ),
    [isPrimary, disabled]
  );

  if (handleButton && !isCirclesHandle) {
    handle = handleButton;
  }

  if (isCirclesHandle) {
    handle = <PracticePreviewCircles id={`${id}-circles-handle`} value={selected} />;
  }

  if (disabled) {
    return handle;
  }

  return (
    <DropdownMenu
      id={id}
      simplifiedMenu={false}
      listHeightRestricted={false}
      onClick={onClick}
      menuItems={
        <PracticeSelect
          items={items}
          value={selected}
          onChange={data => {
            setSelected([...data]);
            onChange?.(data);
            setVisible(false);
          }}
          onClick={ev => {
            ev.stopPropagation();
          }}
          titleComponent={titleComponent}
        />
      }
      centered
      onVisibilityChange={setVisible}
      visible={visible}
    >
      <AccessibleFakeButton>{handle}</AccessibleFakeButton>
    </DropdownMenu>
  );
};
