// @ts-nocheck
import {FormattedMessage, t} from 'i18n-utils';
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {Button, MenuButton, SelectionControlGroup} from 'react-md';
import CircularProgresslink from 'react-md/lib/Progress/CircularProgress';
import moment from 'moment';
import config from '_environment';
import {cropPerfIsLoading, StatusAlert} from './helpers';
import {classifyNdvi, getBiomassChartData} from './biomass/biomass';

import type {Biomass, CropGrowth, CropStatus, CSGViewModel, FieldVariabilityStatus} from './types';
import {
  Aggregation,
  biomassColors,
  biomassLabels,
  biomassLabelsToValues,
  CROP_TYPES_FOR_GROWTH_STAGES,
  cropGrowthColors,
  cropGrowthLabels,
  cropGrowthLabelsToValues,
  cropGrowthStageColors,
  cropGrowthStageLabels,
  cropGrowthStageLabelsToValues,
  fieldsVariabilityColors,
} from './types';

import {Dropdown, FluroTableControls, FluroTabs, InfoBlock} from 'components';
import {
  setAggregation,
  setIncludeGrowthStage,
  setPeriod,
  setRepresentation,
  setUseUnreliableData,
} from './reducer';
import {CropTypeChart} from './crop-type/crop-type-chart';
import {CropPerformanceCard} from './crop-performance-card';
import {PanelViewCard} from './panel-view-card';
import {PanelViewCardAverage} from './panel-view-card-average';
import './panel-view.scss';
import {
  CPFilterType,
  stage0Filters,
  stage1Filters,
  stage2Filters,
  stage3Filters,
  stage4Filters,
} from '_reducers/crop-performance-filter/field-filter-types';
import {clearFilter, toggleFilter} from '_reducers/crop-performance-filter/field-filter-reducer';
import {CropTimeline} from './crop-timeline';
import {getGetURLParam} from '_utils/pure-utils';
import {TableView} from './table-view-csg';
import {groupAndSortRecords} from './helpers/records';
import Mixpanel from '_utils/mixpanel-utils';
import {exportFile} from './helpers/export-file';
import {DownloadIcon} from 'containers/map/icons';
import CropStressCard from './crop-stress-card';
import {BenchmarkCard} from './benchmark-card/benchmark-card';
import {GLOBAL_APP_DATE_FORMAT, NO_CROP, NO_VARIETY, SERVER_FORMAT_DATE} from '_constants';
import {getPeriodicData, periodOrder} from './helpers/periodic';
import {highlightField, setCurrentFieldId, togglePopup} from 'containers/map/actions';
import {CropPerformanceFiltersDescription} from 'containers/map/crop-performance-filters-description';
import {getCropStatusData} from './crop-status/crops-status';
import {getCropGrowthChartData} from './crop-growth/crop-growth';
import {getFieldsVariabilityChartData} from './field-variability/field-variability';
import {UnreliableData} from './unreliable-data';
import {FieldsWithPlantingAreas} from './fields-with-planting-areas';
import {toggleTooltip} from 'modules/global/actions';
import {useAppDispatch, useAppSelector} from '_hooks';
import {selectIsAdmin, selectMeasurement} from '../../../login/login-selectors';
import {useMapBarView} from '../../map-bar/map-bar-view';
import {getFieldLastFinishedSeason} from '_hooks/use-current-season';
import type {Field} from '../../types';
import {
  selectCropPerformance,
  selectCropPerformanceFilter,
  selectGrowthStageChartData,
} from './crop-performance-selectors';
import {ColoredBullet, ItemName} from 'components/legend-list/legend-list';
import {
  selectCropVarietiesColors,
  selectCurrentDate,
  selectFieldsByFarmId,
} from '../../reducer/selectors';
import {useIntercom} from 'react-use-intercom';
import {selectCropTypes, selectIsReportView} from 'modules/global/selectors';

const CropPerformancePanelView = () => {
  const dispatch = useAppDispatch();
  const isAdmin = useAppSelector(selectIsAdmin);
  const isReportView = useAppSelector(selectIsReportView);
  const [wholeTableView] = useMapBarView();
  const currentDate = useAppSelector(selectCurrentDate);
  const cropPerformanceFilter = useAppSelector(selectCropPerformanceFilter);
  const cropPerformance = useAppSelector(selectCropPerformance);
  const cropVarietyColors = useAppSelector(selectCropVarietiesColors);
  const measurement = useAppSelector(selectMeasurement);
  const fieldsByFarmId = useAppSelector(selectFieldsByFarmId);
  const cropTypes = useAppSelector(selectCropTypes);

  const [tab, setTab] = useState<CropPerformanceTab>('summary');
  const records = cropPerformance.records;
  const twoWeeksAgoRecords = cropPerformance.twoWeeksAgoRecords;
  const [sortType, setSortType] = useState<keyof CSGViewModel>('fieldName');
  const [sortOrder, setSortOrder] = useState(true);

  const {startTour, trackEvent} = useIntercom();

  const fields: Field[] = useMemo(
    () =>
      Object.keys(fieldsByFarmId)
        .filter(farmId => cropPerformance.farms[Number(farmId)])
        .flatMap(farmId => Object.values(fieldsByFarmId[Number(farmId)])),
    [fieldsByFarmId, cropPerformance.farms]
  );
  const fieldsSeasons = fields.filter(f => f.Seasons.length);
  const fieldsVariability = cropPerformance.fieldsVariability;
  const displayIncludeGrowthStageToggle = useMemo(() => {
    const cropTypeFilter = cropPerformanceFilter.filters[CPFilterType.CROP_TYPE];
    const allowedCropTypeFilter = // check if the filter is set to allowed cropType
      cropTypeFilter.length &&
      cropTypeFilter.every(filter => CROP_TYPES_FOR_GROWTH_STAGES.includes(filter.value as string));

    const allSeasonsHaveAllowedCropType =
      records.length &&
      records.every(
        (
          r // the farm may include only one crop type and it might be allowed
        ) => CROP_TYPES_FOR_GROWTH_STAGES.includes(r.cropType)
      );

    return Boolean(
      (allowedCropTypeFilter || allSeasonsHaveAllowedCropType) &&
        fields.some(f => f.Country === 'United States')
    );
  }, [fields, cropPerformanceFilter, records]);

  const sortBy = (newSortType: keyof CSGViewModel, newSortOrder?: boolean) => {
    setSortType(newSortType);
    setSortOrder(
      newSortOrder != null
        ? newSortOrder
        : newSortType === sortType
        ? !sortOrder // toggle
        : sortOrder // leave the old value
    );
  };

  const onSetTab = (tab: CropPerformanceTab) => {
    Mixpanel.cropPerformance_SetTab(tab);
    setTab(tab);
  };

  const onExport = (fileType: 'csv' | 'xlsx') => {
    Mixpanel.cropPerformance_Export(fileType);
    exportFile(fileType, passedS4, periodic);
  };

  const onChangeField = (farmId: number, fieldId: number) => {
    dispatch(setCurrentFieldId(fieldId, false, false, farmId));
  };

  const onChangePeriod = ({next, prev}: {next?: boolean; prev?: boolean}) => {
    const i = periodOrder.indexOf(cropPerformance.period) % periodOrder.length;
    if (next) {
      dispatch(setPeriod(periodOrder[i + 1]));
    }
    if (prev) {
      dispatch(setPeriod(periodOrder[i - 1]));
    }
  };

  const isCurrentDate = (date: string) => {
    return moment(currentDate, GLOBAL_APP_DATE_FORMAT).format(SERVER_FORMAT_DATE) === date;
  };

  const onHighlightField = (fieldId: number) => {
    dispatch(highlightField(fieldId));
    dispatch(togglePopup(fieldId, 'crop-performance'));
  };

  const showTutorial = () => {
    trackEvent('crop-performance-tutorial-open');
    startTour(149028);
  };

  const showTooltip = useCallback(
    (id: string, content: JSX.Element) => {
      dispatch(
        toggleTooltip({
          id,
          place: 'top',
          tooltipClassName: 'line-chart-tooltip',
          content,
          width: 'auto',
        })
      );
    },
    [dispatch, toggleTooltip]
  );

  // When we switch to table view, show fields tab by default.
  useEffect(() => {
    if (wholeTableView) {
      setTab('crops');
    }
  }, [wholeTableView]);

  useEffect(() => {
    if (cropPerformance.includeGrowthStage !== displayIncludeGrowthStageToggle) {
      toggleIncludeGrowthStage();
    }
  }, [displayIncludeGrowthStageToggle, cropPerformance.includeGrowthStage]);

  const biomasses = useMemo(() => {
    const biomasses: {[fieldId: number]: Biomass} = {};
    records.forEach(r => {
      const q = cropPerformance.ndviQuartiles[`${r.cropType}_${r.cropVariety}`]?.smoothSatellite;
      biomasses[r.fieldID] = classifyNdvi(r.smoothSatelliteNdvi, q);
    });
    return biomasses;
  }, [records]);

  const {passedS1, passedS3, passedS4} = useMemo(() => {
    const passedS0 = records.filter(
      r =>
        r.cropType !== 'fallow' &&
        !stage0Filters.includes(cropPerformanceFilter.filterStatus[r.seasonId])
    );

    const passedS1 = passedS0.filter(
      r => !stage1Filters.includes(cropPerformanceFilter.filterStatus[r.seasonId])
    );
    const passedS2 = passedS1.filter(
      r => !stage2Filters.includes(cropPerformanceFilter.filterStatus[r.seasonId])
    );
    const passedS3 = passedS2.filter(
      r => !stage3Filters.includes(cropPerformanceFilter.filterStatus[r.seasonId])
    );
    const passedS4 = passedS3.filter(
      r => !stage4Filters.includes(cropPerformanceFilter.filterStatus[r.seasonId])
    );

    return {passedS1, passedS3, passedS4};
  }, [records, cropPerformanceFilter.filterStatus]);

  const cropStatusCardsData = useMemo(() => {
    return getCropStatusData({
      farms: cropPerformance.farms,
      info: cropPerformance.info,
      useUnreliableData: cropPerformance.useUnreliableData,
      records: passedS1,
      aggregation: cropPerformance.aggregation,
    });
  }, [
    passedS1,
    cropPerformance.farms,
    cropPerformance.info,
    cropPerformance.useUnreliableData,
    cropPerformance.aggregation,
  ]);

  const {twoWeeksAgoPassedS3, twoWeeksAgoPassedS4} = useMemo(() => {
    const passedS0 = twoWeeksAgoRecords.filter(
      r =>
        r.cropType !== 'fallow' &&
        !stage0Filters.includes(cropPerformanceFilter.filterStatus[r.seasonId])
    );

    const passedS1 = passedS0.filter(
      r => !stage1Filters.includes(cropPerformanceFilter.filterStatus[r.seasonId])
    );
    const passedS2 = passedS1.filter(
      r => !stage2Filters.includes(cropPerformanceFilter.filterStatus[r.seasonId])
    );
    const passedS3 = passedS2.filter(
      r => !stage3Filters.includes(cropPerformanceFilter.filterStatus[r.seasonId])
    );
    const passedS4 = passedS3.filter(
      r => !stage4Filters.includes(cropPerformanceFilter.filterStatus[r.seasonId])
    );
    return {
      twoWeeksAgoPassedS3: passedS3,
      twoWeeksAgoPassedS4: passedS4,
    };
  }, [twoWeeksAgoRecords, cropPerformanceFilter.filterStatus]);

  const periodic = useMemo(() => {
    return getPeriodicData(cropPerformance, currentDate, cropPerformanceFilter.filterStatus);
  }, [
    cropPerformance.farms,
    cropPerformance.period,
    cropPerformance.records,
    currentDate,
    cropPerformanceFilter.filterStatus,
  ]);

  const recordGroups = useMemo(() => {
    return groupAndSortRecords(passedS4, periodic, sortType, sortOrder);
  }, [passedS4, periodic, sortType, sortOrder]);

  const fieldsVariabilityData = useMemo(
    () =>
      fields
        .map(f => {
          const season = getFieldLastFinishedSeason(f);
          // Filter out stage1, since variability is stage2 filter.
          if (
            !season ||
            stage1Filters.includes(cropPerformanceFilter.filterStatus[season.id]) ||
            !fieldsVariability[season.id] ||
            !cropPerformanceFilter.filterStatus.hasOwnProperty(season.id)
          )
            return null;
          return {
            status: fieldsVariability[season.id].status,
            id: season.id,
            fieldArea: f.Area,
          };
        })
        .filter(Boolean),
    [fields, fieldsVariability, stage1Filters, cropPerformanceFilter.filterStatus]
  );

  const cropGrowthChartData = getCropGrowthChartData(
    passedS3,
    cropPerformance.useUnreliableData,
    cropPerformance.aggregation
  );
  const biomassChartData = getBiomassChartData(
    passedS3,
    cropPerformance.ndviQuartiles,
    cropPerformance.useUnreliableData,
    cropPerformance.aggregation
  );

  const fieldsVariabilityChartData = getFieldsVariabilityChartData(
    fieldsVariabilityData,
    cropPerformance.aggregation
  );

  const growthStagesCardData = useMemo(() => {
    if (!displayIncludeGrowthStageToggle) return null;

    return selectGrowthStageChartData(passedS1);
  }, [passedS1, displayIncludeGrowthStageToggle]);

  const unreliableCount = useMemo(() => records.filter(r => !r.reliable).length, [records]);
  const predictedCount = useMemo(() => records.filter(r => r.predicted).length, [records]);

  const hiddenByReportView = (card: CPFilterType) => {
    return isReportView && cropPerformance.representation !== card;
  };

  const toggleIncludeGrowthStage = () => {
    dispatch(setIncludeGrowthStage(!cropPerformance.includeGrowthStage));
    // change card in case we disable growth stages
    if (cropPerformance.representation === CPFilterType.CROP_GROWTH_STAGE) {
      dispatch(setRepresentation(CPFilterType.CROP_TYPE));
    }
  };

  return (
    <div className="wf-crop-sm crop-performance">
      {!isReportView && (
        <FluroTableControls
          withElevation={!!wholeTableView}
          className={'crop-performance__top-controls-wrapper'}
        >
          <div className="wf-crop-sm__top-bl">
            <h3 className="tab-title">
              <span>
                <FormattedMessage id="Crop Performance" defaultMessage="" />
              </span>
              <span className="controls">
                {cropPerfIsLoading(cropPerformance) ? (
                  <span className="preloader">
                    <CircularProgresslink id={`farm-loader-panel`} />
                  </span>
                ) : (
                  <Button icon onClick={showTutorial} className="crop-performance-help-button">
                    help_outline
                  </Button>
                )}
                {!wholeTableView && (
                  <Dropdown
                    className={'aggregation-dropdown'}
                    button={<Button icon>more_vert</Button>}
                    anchor={'right'}
                  >
                    <SelectionControlGroup
                      id="aggregation-selector"
                      type="radio"
                      name="Data aggregation type"
                      onChange={v => dispatch(setAggregation(v as Aggregation))}
                      value={cropPerformance.aggregation}
                      controls={[
                        {label: t({id: 'Show number of crops'}), value: Aggregation.COUNT},
                        {
                          label: t({id: 'Show number of {areaUnit}'}, {areaUnit: measurement}),
                          value: Aggregation.SUM,
                        },
                      ]}
                    />
                  </Dropdown>
                )}
                {wholeTableView && cropPerformance.records.length !== 0 && (
                  <MenuButton
                    raised
                    iconEl={<DownloadIcon />}
                    id="crop-perf-export-menu"
                    position="below"
                    menuItems={[
                      {primaryText: 'CSV', onClick: () => onExport('csv')},
                      {primaryText: 'XLSX', onClick: () => onExport('xlsx')},
                    ]}
                  >
                    Export
                  </MenuButton>
                )}
              </span>
            </h3>
          </div>

          {fieldsSeasons.length !== 0 && (
            <FluroTabs
              sticky
              onTabClick={(value: CropPerformanceTab) => onSetTab(value)}
              selectedTab={tab}
              tabs={[
                {label: t({id: 'Summary'}), value: 'summary'},
                {label: t({id: 'Crops ({count})'}, {count: passedS4.length}), value: 'crops'},
                config.env === 'development' || getGetURLParam('croptimeline')
                  ? {label: t({id: 'Crop timeline'}), value: 'croptimeline'}
                  : undefined,
              ].filter(Boolean)}
            />
          )}
        </FluroTableControls>
      )}

      {fieldsSeasons.length !== 0 && (
        <>
          {tab === 'summary' && (
            <div className="crop-performance-summary">
              <>
                {!isReportView && unreliableCount > 0 && (
                  <UnreliableData
                    unreliableCount={unreliableCount}
                    totalCount={records.length}
                    useUnreliableData={cropPerformance.useUnreliableData}
                    toggleUseUnreliableData={() =>
                      dispatch(setUseUnreliableData(!cropPerformance.useUnreliableData))
                    }
                  />
                )}

                {!isReportView && predictedCount > 0 && (
                  <InfoBlock icon={<div className="predicted-example"></div>}>
                    <FormattedMessage
                      id="For X fields crop performance is predicted. These fields are indicated with a dashed border."
                      defaultMessage="For {predictedCount} fields crop performance is predicted. They're indicated with a dashed border."
                      values={{predictedCount}}
                    />
                  </InfoBlock>
                )}

                {!hiddenByReportView(CPFilterType.FIELDS_WITH_PLANTING_AREAS) && (
                  <FieldsWithPlantingAreas
                    fields={fields}
                    filteredFields={cropPerformanceFilter.filters[
                      CPFilterType.FIELDS_WITH_PLANTING_AREAS
                    ].map(filter => filter.value as number)}
                    onFilterClear={() =>
                      dispatch(clearFilter(CPFilterType.FIELDS_WITH_PLANTING_AREAS))
                    }
                    onFilterToggle={fieldIdToFilter => {
                      dispatch(
                        toggleFilter({
                          type: CPFilterType.FIELDS_WITH_PLANTING_AREAS,
                          stage: 0,
                          value: fieldIdToFilter,
                          color: 'rgb(239, 239, 239)',
                        })
                      );
                    }}
                  />
                )}

                {!hiddenByReportView(CPFilterType.CROP_TYPE) && (
                  <CropTypeChart
                    active={cropPerformance.representation === CPFilterType.CROP_TYPE}
                    onWidgetClick={() => dispatch(setRepresentation(CPFilterType.CROP_TYPE))}
                    onFilterClear={() => dispatch(clearFilter(CPFilterType.CROP_TYPE))}
                    onFilterToggle={(
                      cropType,
                      color: string,
                      filterType: CPFilterType.CROP_TYPE | CPFilterType.CROP_VARIETY,
                      label?: string
                    ) => {
                      // Representation can't be CROP_VARIETY. This one should be derived
                      // in place based on store.map.cropVarietyMode.
                      dispatch(setRepresentation(CPFilterType.CROP_TYPE));
                      dispatch(
                        toggleFilter({
                          type: filterType,
                          stage: 1,
                          value:
                            cropType === NO_CROP || cropType === NO_VARIETY ? undefined : cropType,
                          color: color,
                          label,
                        })
                      );
                    }}
                  />
                )}

                {Boolean(
                  !hiddenByReportView(CPFilterType.CROP_GROWTH_STAGE) &&
                    cropPerformance.includeGrowthStage &&
                    displayIncludeGrowthStageToggle &&
                    (growthStagesCardData ||
                      cropPerformanceFilter.filters[CPFilterType.CROP_GROWTH_STAGE].length)
                ) && (
                  <CropPerformanceCard
                    title={t(
                      {id: 'Crop growth stages ({cropType})'},
                      {cropType: CROP_TYPES_FOR_GROWTH_STAGES.join(',')}
                    )}
                    type={CPFilterType.CROP_GROWTH_STAGE}
                    selectedLabels={cropPerformanceFilter.filters[
                      CPFilterType.CROP_GROWTH_STAGE
                    ].map(f => cropGrowthStageLabels[f.value])}
                    selectedCountLabel={t(
                      {id: '{count1} / {count2} crops selected'},
                      {
                        count1:
                          passedS1.length -
                          passedS1.filter(
                            r =>
                              cropPerformanceFilter.filterStatus[r.seasonId] ===
                              CPFilterType.CROP_GROWTH_STAGE
                          ).length,
                        count2: passedS1.length,
                      }
                    )}
                    data={growthStagesCardData}
                    onFilterToggle={f => {
                      const cropGrowthCode = cropGrowthStageLabelsToValues[f];
                      dispatch(setRepresentation(CPFilterType.CROP_GROWTH_STAGE));
                      dispatch(
                        toggleFilter({
                          type: CPFilterType.CROP_GROWTH_STAGE,
                          stage: 3,
                          value: cropGrowthCode,
                          color: cropGrowthStageColors[cropGrowthCode],
                        })
                      );
                    }}
                  />
                )}

                {!hiddenByReportView(CPFilterType.FIELDS_VARIABILITY) &&
                  fieldsVariabilityData.length !== 0 && (
                    <CropPerformanceCard
                      title={t({id: 'Field variability'})}
                      type={CPFilterType.FIELDS_VARIABILITY}
                      selectedLabels={cropPerformanceFilter.filters[
                        CPFilterType.FIELDS_VARIABILITY
                      ].map(f => `${f.value}`)}
                      selectedCountLabel={t(
                        {id: '{count1} / {count2} crops selected'},
                        {
                          count1:
                            fieldsVariabilityData.length -
                            fieldsVariabilityData.filter(
                              season =>
                                cropPerformanceFilter.filterStatus[season.id] ===
                                CPFilterType.FIELDS_VARIABILITY
                            ).length,
                          count2: fieldsVariabilityData.length,
                        }
                      )}
                      data={fieldsVariabilityChartData}
                      info={
                        <div>
                          <FormattedMessage
                            id="This score represents the field's spatial variability..."
                            defaultMessage=""
                          />
                        </div>
                      }
                      onShowTooltip={showTooltip}
                      onFilterToggle={(f: FieldVariabilityStatus) => {
                        dispatch(
                          toggleFilter({
                            type: CPFilterType.FIELDS_VARIABILITY,
                            stage: 2,
                            value: f,
                            color: fieldsVariabilityColors[f],
                          })
                        );
                      }}
                    />
                  )}

                {!hiddenByReportView(CPFilterType.CROP_STATUS) &&
                  cropStatusCardsData.map(({sequence, displaySequence, records, data}) => (
                    <CropPerformanceCard
                      key={sequence}
                      title={t({id: 'Crop status ({displaySequence})'}, {displaySequence})}
                      type={CPFilterType.CROP_STATUS}
                      selectedLabels={cropPerformanceFilter.filters[CPFilterType.CROP_STATUS].map(
                        f => String(f.value)
                      )}
                      selectedCountLabel={t(
                        {id: '{count1} / {count2} crops selected'},
                        {
                          count1:
                            records.length -
                            records.filter(
                              r =>
                                cropPerformanceFilter.filterStatus[r.seasonId] ===
                                CPFilterType.CROP_STATUS
                            ).length,
                          count2: records.length,
                        }
                      )}
                      data={data}
                      info={
                        <div>
                          {cropPerformance.info?.status_seq[sequence].map((status, i) => (
                            <div key={status}>
                              <ColoredBullet
                                style={{backgroundColor: cropPerformance.info?.colours[status]}}
                              />
                              <ItemName>{`${i + 1}. ${status}`}</ItemName>
                            </div>
                          ))}
                        </div>
                      }
                      onShowTooltip={showTooltip}
                      onFilterToggle={f => {
                        dispatch(setRepresentation(CPFilterType.CROP_STATUS));
                        dispatch(
                          toggleFilter({
                            type: CPFilterType.CROP_STATUS,
                            stage: 3,
                            value: f as CropStatus,
                            color: cropPerformance.info?.colours[f],
                          })
                        );
                      }}
                    />
                  ))}

                {!hiddenByReportView(CPFilterType.CROP_GROWTH) &&
                  cropGrowthChartData.values.length > 0 && (
                    <CropPerformanceCard
                      title={t({id: 'Crop growth'})}
                      type={CPFilterType.CROP_GROWTH}
                      selectedLabels={cropPerformanceFilter.filters[CPFilterType.CROP_GROWTH].map(
                        f => cropGrowthLabels[f.value]
                      )}
                      selectedCountLabel={t(
                        {id: '{count1} / {count2} crops selected'},
                        {
                          count1:
                            passedS3.length -
                            passedS3.filter(
                              r =>
                                cropPerformanceFilter.filterStatus[r.seasonId] ===
                                CPFilterType.CROP_GROWTH
                            ).length,
                          count2: passedS3.length,
                        }
                      )}
                      data={cropGrowthChartData}
                      info={
                        <div>
                          {([3, 2, 1, 0, -1, -2, -3] as CropGrowth[]).map((growth, i) => (
                            <div key={growth}>
                              <ColoredBullet style={{backgroundColor: cropGrowthColors[growth]}} />
                              <ItemName>{`${i + 1}. ${cropGrowthLabels[growth]}`}</ItemName>
                            </div>
                          ))}
                        </div>
                      }
                      onShowTooltip={showTooltip}
                      onFilterToggle={f => {
                        dispatch(setRepresentation(CPFilterType.CROP_GROWTH));
                        dispatch(
                          toggleFilter({
                            type: CPFilterType.CROP_GROWTH,
                            stage: 4,
                            value: cropGrowthLabelsToValues[f],
                            color: cropGrowthColors[cropGrowthLabelsToValues[f]],
                          })
                        );
                      }}
                    />
                  )}

                {!hiddenByReportView(CPFilterType.CROP_STRESS) && isAdmin && records.length ? (
                  <CropStressCard
                    recordsBeforeFiltration={passedS3}
                    recordsAfterFiltration={passedS4}
                    twoWeeksAgoRecordsBeforeFiltration={twoWeeksAgoPassedS3}
                    twoWeeksAgoRecordsAfterFiltration={twoWeeksAgoPassedS4}
                    active={cropPerformance.representation === CPFilterType.CROP_STRESS}
                    onWidgetClick={() => dispatch(setRepresentation(CPFilterType.CROP_STRESS))}
                    onFilterClear={() => dispatch(clearFilter(CPFilterType.CROP_STRESS))}
                    onFilterToggle={f => {
                      dispatch(setRepresentation(CPFilterType.CROP_STRESS));

                      dispatch(
                        toggleFilter({
                          type: CPFilterType.CROP_STRESS,
                          stage: 4,
                          value: f.label,
                          color: f.color,
                        })
                      );
                    }}
                  />
                ) : null}

                {!hiddenByReportView(CPFilterType.BIOMASS) && biomassChartData.values.length > 0 && (
                  <CropPerformanceCard
                    title={t({id: 'Biomass NDVI'})}
                    type={CPFilterType.BIOMASS}
                    selectedLabels={cropPerformanceFilter.filters[CPFilterType.BIOMASS].map(
                      f => biomassLabels[f.value as Biomass]
                    )}
                    // selectedLabels={fieldFilter.filters[CPFilterType.BIOMASS].map(f => biomassLabels[f.value as Biomass])}
                    selectedCountLabel={t(
                      {id: '{count1} / {count2} crops selected'},
                      {
                        count1:
                          passedS3.length -
                          passedS3.filter(
                            r =>
                              cropPerformanceFilter.filterStatus[r.seasonId] ===
                              CPFilterType.BIOMASS
                          ).length,
                        count2: passedS3.length,
                      }
                    )}
                    data={biomassChartData}
                    info={
                      <div>
                        {(['noimages', 'low', 'medium', 'high'] as Biomass[]).map((biomass, i) => (
                          <div key={biomass}>
                            <ColoredBullet style={{backgroundColor: biomassColors[biomass]}} />
                            <ItemName>{`${i + 1}. ${t({
                              id: biomassLabels[biomass],
                            })}`}</ItemName>
                          </div>
                        ))}
                      </div>
                    }
                    onShowTooltip={showTooltip}
                    onFilterToggle={f => {
                      dispatch(setRepresentation(CPFilterType.BIOMASS));
                      dispatch(
                        toggleFilter({
                          type: CPFilterType.BIOMASS,
                          stage: 4,
                          value: biomassLabelsToValues[f],
                          color: biomassColors[biomassLabelsToValues[f]],
                        })
                      );
                    }}
                  />
                )}
              </>

              {!hiddenByReportView(CPFilterType.BIOMASS_OVER_TIME) && (
                <BenchmarkCard
                  filteredRecords={passedS4}
                  active={cropPerformance.representation === CPFilterType.BIOMASS_OVER_TIME}
                  onWidgetClick={() => dispatch(setRepresentation(CPFilterType.BIOMASS_OVER_TIME))}
                  onFilterToggle={(seasonId: number, color: string) => {
                    dispatch(
                      toggleFilter({
                        type: CPFilterType.BIOMASS_OVER_TIME,
                        stage: 4,
                        value: seasonId,
                        color: color,
                      })
                    );
                  }}
                />
              )}
            </div>
          )}

          {tab === 'crops' &&
            (wholeTableView ? (
              <TableView
                recordGroups={recordGroups}
                periodic={periodic}
                biomasses={biomasses}
                sortType={sortType}
                sortOrder={sortOrder}
                onClick={sortBy}
                onChangeField={onChangeField}
                onChangePeriod={onChangePeriod}
                isCurrentDate={isCurrentDate}
                showFarmName={Object.keys(cropPerformance.farms).length > 1}
                cropVarietyColors={cropVarietyColors}
                measurement={measurement}
                cropTypes={cropTypes}
              />
            ) : (
              <>
                <div className="field-list">
                  {!passedS4.length && (
                    <div>
                      {t({
                        id: 'Everything is filtered out. Go back to summary tab and try tuning the filters.',
                      })}
                    </div>
                  )}
                  {passedS4.length > 0 &&
                    recordGroups.map(g => {
                      return (
                        <React.Fragment key={`${g.cropType}_${g.cropVariety}`}>
                          <PanelViewCardAverage records={g.records} />
                          {g.records.map(r => (
                            <PanelViewCard
                              key={r.seasonId}
                              record={r}
                              biomass={classifyNdvi(
                                r.smoothSatelliteNdvi,
                                cropPerformance.ndviQuartiles[`${r.cropType}_${r.cropVariety}`]
                                  ?.smoothSatellite
                              )}
                              showFarmName={Object.keys(cropPerformance.farms).length > 1}
                              onHighlightField={onHighlightField}
                            />
                          ))}
                        </React.Fragment>
                      );
                    })}
                </div>
              </>
            ))}

          {tab === 'croptimeline' && <CropTimeline />}

          {wholeTableView && <CropPerformanceFiltersDescription />}
        </>
      )}

      <StatusAlert />
    </div>
  );
};

type CropPerformanceTab = 'summary' | 'crops' | 'croptimeline';

export default CropPerformancePanelView;
