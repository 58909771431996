// @ts-nocheck
import {
  getFieldAndSettingsDetails,
  getHeaderImage,
  getFooterImg,
  getZonesPreviewAndValuesImg,
} from './export-to-pdf-utils';
import {formatDate} from '_utils';
import moment from 'moment';
import Mixpanel from '_utils/mixpanel-utils';
import type {AppStore} from 'reducers';
import type {AppDispatch} from 'store';
import {
  selectCurrentFarm,
  selectCurrentField,
  selectCurrentSeason,
} from '../../../reducer/selectors';
import {selectNRecommendation} from 'containers/map/features/nrx/nrx-selectors';

import {selectMeasurement} from '../../../../login/login-selectors';

const exportNRxToPdf = () => async (dispatch: AppDispatch, getState: () => AppStore) => {
  Mixpanel.exportNRx('PDF');
  const state = getState();
  const {nrxRecommendationSettings, nrxResult, nrxTabRate, selectedObjective} =
    selectNRecommendation(state);
  const farm = selectCurrentFarm(state);
  const field = selectCurrentField(state);
  const currentSeason = selectCurrentSeason(state);
  const measurement = selectMeasurement(state);

  const recommendationDate = moment(nrxRecommendationSettings.recommendation_date).format(
    formatDate()
  );

  const html2canvas = (await import('html2canvas')).default;
  const jsPDF = (await import('jspdf')).default;

  const [headerImg, headerImgHeight] = await getHeaderImage(recommendationDate, html2canvas, true);

  const [fieldAndSettingsImg, fieldAndSettingsImgHeight] = await getFieldAndSettingsDetails(
    farm,
    field,
    currentSeason,
    measurement,
    html2canvas,
    true
  );
  const [zonesPreviewAndValuesImg, zonesPreviewImgHeight] = await getZonesPreviewAndValuesImg(
    nrxResult[nrxTabRate][selectedObjective],
    html2canvas,
    true
  );
  const [footerImg, footerImgHeight] = await getFooterImg(html2canvas);
  const NrxPdf = new jsPDF();
  // hint -  210 / 297 is the default A4 page size
  // page 1
  NrxPdf.addImage(headerImg, 'JPEG', 0, 0, 210, headerImgHeight);
  NrxPdf.addImage(fieldAndSettingsImg, 'JPEG', 0, headerImgHeight, 210, fieldAndSettingsImgHeight);
  NrxPdf.addImage(footerImg, 'JPEG', 0, 297 - footerImgHeight, 210, footerImgHeight);
  // page 2
  NrxPdf.addPage();
  NrxPdf.addImage(headerImg, 'JPEG', 0, 0, 210, headerImgHeight);
  NrxPdf.addImage(zonesPreviewAndValuesImg, 'JPEG', 0, headerImgHeight, 210, zonesPreviewImgHeight);
  NrxPdf.addImage(footerImg, 'JPEG', 0, 297 - footerImgHeight, 210, footerImgHeight);

  NrxPdf.save(`${field.Name} - NRx results`);
};

export default exportNRxToPdf;
