import type {Reducer} from 'redux';
import type {HelpersStore} from './types';
import {ActionType} from './types';

const initialState = {
  subscriptionsFarmId: 0,
  subscriptionsOrgId: 0,
  subscriptionsSubId: 0,
};

const filtersReducer: Reducer<HelpersStore['filters']> = (state = initialState, action) => {
  switch (action.type) {
    case ActionType.SetFilter: {
      return {
        ...state,
        [action.name]: action.value,
      };
    }

    default:
      return state;
  }
};

export default filtersReducer;
