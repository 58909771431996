import {Flex} from 'components';
import React from 'react';
import styled from 'styled-components';
import colors from 'variables.scss';

export const StageCustomView = ({
  illustration,
  children,
}: React.PropsWithChildren<{illustration: JSX.Element}>) => {
  return (
    <StageCustomViewContainer>
      <Flex direction="column" gap="40px" alignItems="center">
        <div></div>
        {illustration}
        {children}
      </Flex>
    </StageCustomViewContainer>
  );
};

export const StageCustomViewContainer = styled.div`
  background-color: ${colors['main-gray-100']};
  padding: 20px;
  border-radius: 10px;
`;
