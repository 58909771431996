// @ts-nocheck
import {CropAvatarLite} from 'components/crop-avatar/crop-avatar-lite';
import {t} from 'i18n-utils';
import type {CropType, CropTypeTranslationMRV} from 'modules/global/types';
import React from 'react';
import {getCropSrc} from '_utils/pure-utils';
import type {MRVAttribute, MRVStageNormalized, StageCompletion} from './types';
import {mrvAttributeTypesLabels, mrvAttributeTypesLabelsReportView, MRVStageType} from './types';

export const getCropItems = (items: CropType[], labelProp?: CropTypeTranslationMRV) => {
  if (!items) return [];
  return items.map(crop => {
    return {
      // TODO (stas): send the right id (or cdl id) when it's decided what we want to store. Until then use a string value.
      // value: crop.id,
      value: crop.value,
      icon: (
        <CropAvatarLite cropType={crop.value} iconSrc={crop.icon_url || getCropSrc(crop.icon)} />
      ),
      label: crop?.[labelProp] || crop.label,
    };
  });
};

/**
 * At least 1 row of values.
 * If the years are the same 2022-2022, we want 1 row of values.
 * If, for some reason, year start > year end, we wanna use 1 row of values.
 */
export const getStageRowsNum = (stage?: MRVStageNormalized) => {
  if (!stage) {
    return 0;
  }
  const start = stage?.year_start || 0;
  const end = stage?.year_end || 0;
  const diff = end - start;
  return Math.max(0, diff) + 1;
};

export const createStageCompletion = (
  stageId: number,
  is_completed: boolean
): {stageId: number; completion: StageCompletion} => ({
  stageId,
  completion: {
    completed: 0,
    is_completed,
    percentage_complete: is_completed ? 100 : 0,
    to_complete: 0,
    total: 0,
  },
});

const customStageViews = [MRVStageType.Eligibility, MRVStageType.Survey, MRVStageType.Contract];
export const isCustomStageView = (stageType?: MRVStageType): boolean => {
  if (stageType === undefined) return false;
  return customStageViews.includes(stageType);
};

export const getStageName = (stage?: MRVStageNormalized) => {
  if (!stage) {
    return '';
  }

  if (stage.custom_name) {
    return stage.custom_name;
  }

  return t({id: `MRVStageType.${stage.type_}`});
};

/**
 * @param attribute
 * @param reportView FMS sync report
 */
export const getAttributeLabel = (attribute: MRVAttribute, reportView: boolean = false) => {
  if (attribute.custom_name) {
    return attribute.custom_name;
  }

  if (reportView) {
    const localized = mrvAttributeTypesLabelsReportView[attribute.type];
    if (localized) {
      return localized;
    }
  }

  const localized = mrvAttributeTypesLabels[attribute.type]?.();
  if (localized) {
    return localized;
  }

  return attribute.label;
};
