// @ts-nocheck
import {routerMiddleware} from 'connected-react-router';
import googleTagManagerMiddleware from 'containers/google-tag-manager';
import thunk from 'redux-thunk';
import {createLogger} from 'redux-logger';

import type {AppStore} from './reducers';
import reducers from './reducers';
import config from '_environment';
import {configureStore} from '@reduxjs/toolkit';

import {history} from '_utils/history-utils';

const logger = createLogger({
  collapsed: true,
});

const initialState = {};

const middleware = [thunk, routerMiddleware(history)];

const isProd = config.env === 'production';
if (isProd && typeof googleTagManagerMiddleware === 'function') {
  middleware.push(googleTagManagerMiddleware);
}

if (!isProd && process.env.DISABLE_REDUX_LOGGER !== 'true') {
  middleware.push(logger);
}

const store = configureStore({
  reducer: reducers(history),
  preloadedState: initialState,
  // @ts-expect-error error leftover from convertion to strict mode, please fix
  devTools: !isProd ? window.__REDUX_DEVTOOLS_EXTENSION__ : undefined,
  middleware: getDefaultMiddleware =>
    // We already have at least "some" bad calls in _api/base re dispatching stuff at the wrong time,
    // so disable immutableCheck.
    getDefaultMiddleware({immutableCheck: false, serializableCheck: false}).concat(middleware),
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;

/*
 *
 * Relating to new version of redux in which was added isDispatching checking
 * https://github.com/reduxjs/redux/issues/1668
 *
 * this is dirty hack for avoiding exception when calling Store.getState() direct
 *
 *
 * */
let _state = store.getState();
export const hackGetState = (): AppStore => _state;
function handleChange() {
  _state = store.getState();
}
store.subscribe(handleChange);

if (module.hot) {
  module.hot.accept('./reducers', () => {
    const nextRootReducer = require('./reducers').default;
    store.replaceReducer(nextRootReducer);
  });
}

export default store;
