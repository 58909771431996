import {useDispatch, useSelector} from 'react-redux';
import React, {Fragment, useState} from 'react';
import {FileInput} from 'react-md';
import {handleFile} from './reducer';
// @ts-expect-error - no types
import Script from 'react-load-script';
import type {AppStore} from '../../../reducers';

const XLSX_URL = 'https://cdnjs.cloudflare.com/ajax/libs/xlsx/0.13.0/xlsx.full.min.js';
type Props = {
  onCloseDialog: () => void;
};
const TSUpload = ({onCloseDialog}: Props) => {
  const dispatch = useDispatch();
  const currentPointId = useSelector((store: AppStore) => store.map.currentPointId);
  const [isXlsxLoading, setIsXlsxLoading] = useState(true);

  const handleScriptLoad = () => setIsXlsxLoading(false);

  const handleScriptCreate = () => null;

  const handleScriptError = () => null;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const startUpload = (file: any) => {
    dispatch(handleFile(file));
    onCloseDialog();
  };

  return (
    <Fragment>
      <Script
        url={XLSX_URL}
        onCreate={handleScriptCreate}
        onError={handleScriptError}
        onLoad={handleScriptLoad}
      />

      {isXlsxLoading ? null : (
        <FileInput
          id="ts-upload-btn"
          name="ts-upload-btn"
          labelClassName="upload-button-label"
          primary
          multiple
          disabled={!!currentPointId}
          accept=".kml,.xls,.csv,.xlsx"
          label="Upload a File"
          onChange={startUpload}
        />
      )}
    </Fragment>
  );
};

export default TSUpload;
