import type {ComponentType, HTMLAttributes, PropsWithChildren} from 'react';
import React from 'react';
import colors from 'variables.scss';
import {Flex} from 'components/flex/flex';
import cn from 'classnames';

export type RectangleProps = PropsWithChildren<
  {
    /**
     * Leave empty for auto size.
     */
    size?: number;
    color?: string;
    borderRadius?: number | string;
    borderColor?: string;
    borderWidth?: number;
    inline?: boolean;
    padding?: boolean;
  } & HTMLAttributes<HTMLElement>
>;

export const Rectangle: ComponentType<RectangleProps> = ({
  children,
  className,
  size,
  color = colors['main-gray-200'],
  borderRadius = 0,
  borderColor,
  borderWidth = 1,
  inline = false,
  padding = true,
  ...props
}) => {
  const sizeProps = size ? {width: size, height: size} : {};
  const inlineProps = inline ? {display: 'inline-block'} : {};
  const border = borderColor ? `${borderWidth}px solid ${borderColor || color}` : undefined;
  return (
    <Flex
      nowrap
      justifyContent="center"
      alignItems="center"
      className={cn(className, 'fluro-rectangle', {'p-05': padding})}
      style={{background: color, borderRadius, border, ...sizeProps, ...inlineProps}}
      {...props}
    >
      {children}
    </Flex>
  );
};
