import React from 'react';
import {FluroDialog} from 'components';

import cn from 'classnames';
import {useAppDispatch, useAppSelector} from '_hooks';
import ProfileIntegration from './integration';
import {toggleMapBar} from 'containers/map/actions';
import {dialogToggle, DialogType as HelpersDialogToggle} from '../../../modules/helpers';
import {selectDialogVisibility} from '../../../modules/helpers/selectors';

const IntegrationsPlatformsPopUp = () => {
  const dispatch = useAppDispatch();
  const visible = useAppSelector(s =>
    selectDialogVisibility(s, HelpersDialogToggle.integrationPlatforms)
  );

  const onHide = () => {
    dispatch(dialogToggle(HelpersDialogToggle.integrationPlatforms, false));
    dispatch(toggleMapBar(true));
  };

  return (
    <FluroDialog
      id="integration-platforms--dialog"
      visible={visible}
      isDraggable
      focusOnMount={false}
      portal
      dialogClassName={cn('integration-platforms-dialog')}
      onHide={onHide}
      stickyHeader
    >
      <ProfileIntegration isPopUpView={true} />
    </FluroDialog>
  );
};

export default IntegrationsPlatformsPopUp;
