import React from 'react';
import {getCropLabelById} from '_utils/pure-utils';
import type {AppStore} from 'reducers';

type PremiumAppColumn = {
  title: string;
  value: string;
  type: string;

  isSelectedAllFilter: boolean;
  isFilterWithSearch: boolean;

  filterKeys: {
    prop: string;
    onSet: (cropTypes: AppStore['global']['cropTypes'], value: string) => JSX.Element | string;
  }[];
};
export const columns: PremiumAppColumn[] = [
  {
    title: 'Last Created Season',
    value: 'cropType',
    type: 'string',

    isSelectedAllFilter: true,
    isFilterWithSearch: true,

    // onFilterChange: () => {},
    // onClearFilter: () => {},

    filterKeys: [
      {
        prop: 'cropType',
        onSet: (cropTypes, value) => getCropLabelById(cropTypes, value),
      },
      {
        prop: 'cropVariety',
        onSet: (cropTypes, value) => <span> {value}</span>,
      },
    ],
  },
];

export const applyAdvancedFilters = (columns: PremiumAppColumn[], filters: any, data: any[]) => {
  const filterProps: any[] = Object.keys(filters);

  if (!filterProps.length) return data;

  return data.filter((item: any) => {
    let filterResults = [];

    filterProps.forEach((fKey: string) => {
      const column = columns.find((el: any) => el.value === fKey);

      if (column) {
        let key = '';
        column.filterKeys.forEach((fk: any) => {
          key += item[fk.prop] + ';;';
        });

        const isFilter = filters[fKey].data.some(
          (filterItem: any) => filterItem.value === key && filterItem.selected
        );
        isFilter && filterResults.push(true);
      }
    });

    return filterResults.length === filterProps.length;
  });
};
