// @ts-nocheck
import {createSelector} from '@reduxjs/toolkit';
import type {AppStore} from 'reducers';
import type {DialogState, DialogType} from './types';
import {RequestStatusRTK} from './types';

export const selectErrors = (s: AppStore) => s.helpers.asyncRTK.errors;
export const selectAsyncStatus = (s: AppStore) => s.helpers.asyncRTK.status;

export const selectActionError = createSelector(
  [selectErrors, (_: AppStore, actionType: string) => actionType],
  (errors, actionType) => errors[actionType]
);

export const selectActionAsyncStatus = createSelector(
  [selectAsyncStatus, (_: AppStore, actionType: string) => actionType],
  (asyncStatus, actionType) => asyncStatus[actionType]
);

export const selectDialogsState = (s: AppStore) => s.helpers.dialogs;

export const selectDialogVisibility = createSelector(
  [selectDialogsState, (s: AppStore, dialogType: DialogType) => dialogType],
  (dialogsStore, dialogType) => dialogsStore[dialogType]?.visible
);

// TODO infer Payload type by DialogType
export const selectDialogData = createSelector(
  [selectDialogsState, (s: AppStore, dialogType: DialogType) => dialogType],
  (dialogsStore, dialogType) => dialogsStore[dialogType] || ({} as DialogState)
);

const createStatusChecker =
  (status: RequestStatusRTK) =>
  (asyncStatus: Record<string, RequestStatusRTK>, actionTypes: string | string[]): boolean => {
    const checkTypes = [].concat(actionTypes); // Convert `string | string[]` into `string[]`
    return checkTypes.some(t => asyncStatus[t] === status);
  };

const retrieveActionTypes = (_: AppStore, actionTypes: string | string[]) => actionTypes;

export const selectIsLoading = createSelector(
  [selectAsyncStatus, retrieveActionTypes],
  createStatusChecker(RequestStatusRTK.pending)
);

export const selectIsFailed = createSelector(
  [selectAsyncStatus, retrieveActionTypes],
  createStatusChecker(RequestStatusRTK.rejected)
);

export const selectIsSucceed = createSelector(
  [selectAsyncStatus, retrieveActionTypes],
  createStatusChecker(RequestStatusRTK.fulfilled)
);

// intercom
export const selectIntercomVisibility = (s: AppStore) => s.helpers.intercom.visibility;

export const selectIntercomUnreadMessages = (s: AppStore) => s.helpers.intercom.unreadMessages;
