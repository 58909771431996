import {FormattedMessage} from 'i18n-utils';
import React from 'react';
import styled from 'styled-components';
import cn from 'classnames';
import variables from 'variables.scss';

export type Props = {
  selectedCount?: string;
  className?: string;
  list: {
    label: string;
    value: string;
    percentage?: number;
    count?: string | JSX.Element;
    backgroundColor: string;
    deselected?: boolean;
  }[];
  diffMode?: boolean; // whether percentage should be signed (-/+) and colored (red/green)
  onClick?(val: string, e: React.MouseEvent): void;
};

export const LegendList = ({list, onClick, selectedCount, diffMode = false}: Props) => {
  return (
    <>
      {selectedCount && <SelectedCount>{selectedCount}</SelectedCount>}

      <LegendListStyled>
        {list.map(l => {
          const {deselected, backgroundColor, label, percentage = 0, count} = l;
          const percentageSign = diffMode && percentage > 0 ? '+' : '';
          const percentageColor = diffMode
            ? percentage < 0
              ? 'red'
              : 'green'
            : variables['main-gray-600'];
          const threeColumns = percentage != null && count != null;
          return (
            // TODO: fixme a11y
            // eslint-disable-next-line styled-components-a11y/click-events-have-key-events, styled-components-a11y/no-noninteractive-element-interactions
            <LegendListItem
              key={l.label}
              onClick={e => onClick?.(l.value, e)}
              className={cn({
                'legend-list-item': true,
                'three-columns': threeColumns,
                deselected,
              })}
            >
              <div>
                <ColoredBullet
                  className={'apply-deselected-opacity'}
                  style={{
                    backgroundColor,
                    border: backgroundColor === '#ffffff' ? '1px solid black' : undefined,
                  }}
                />
                <ItemName>
                  <FormattedMessage id={label} defaultMessage={label} />
                </ItemName>
              </div>
              {percentage != null && (
                <ItemPercentage style={{color: percentageColor}}>
                  {percentageSign}
                  {percentage || '<1'}%
                </ItemPercentage>
              )}
              {count != null && <ItemCount>{count}</ItemCount>}
            </LegendListItem>
          );
        })}
      </LegendListStyled>
    </>
  );
};

export const SelectedCount = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 8px;
  color: #349e21;
`;

export const LegendListStyled = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`;

export const LegendListItem = styled.li`
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;

  &.three-columns {
    display: grid;
    grid-template-columns: 42% 10% 48%;
  }
  &.four-columns {
    display: grid;
    grid-template-columns: 42% 12% 18% 20%;
  }

  &.heading-row {
    grid-template-columns: calc(42% - 13px) 12% 18% 20%; // duplicate of .four-columns and minus bullets actual width
    margin-left: 13px;
    color: ${variables['main-gray-600']};
    font-weight: 500;
    margin-top: 5px;
    margin-bottom: 3px;
  }

  &.deselected {
    color: #a6acbe;
  }
`;

export const ItemName = styled.span`
  color: ${variables['main-gray-600']};
  transition: color 0.2s;

  .deselected & {
    opacity: 0.3;
  }

  .legend-list-item:hover & {
    color: #29353a;
  }
`;

export const ItemPercentage = styled.span`
  text-align: right;
  font-size: 14px;
  color: ${variables['main-gray-600']};
`;

export const ItemDataInfo = styled.span`
  text-align: left;
  font-weight: 300;
  font-size: 14px;
  color: ${variables['main-gray-600']};
`;

export const ItemAreaLight = styled.span`
  text-align: right;
  font-size: 14px;
  color: ${variables['main-gray-500']};
  font-weight: 300;
`;

export const ItemCount = styled.span`
  color: ${variables['main-gray-600']};
  margin-left: 4px;
  text-align: right;
  font-size: 14px;

  .deselected & {
    color: #a6acbe;
    font-weight: 400;
  }
`;

export const ColoredBullet = styled.span`
  .deselected &.apply-deselected-opacity {
    // some bullets have custom opacity (like Crop type chart, or Benchmark card)
    opacity: 0.3;
  }
  width: 10px;
  height: 10px;
  display: inline-block;
  border-radius: 100%;
  margin-right: 4px;
`;
