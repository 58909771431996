import React, {useEffect, useRef} from 'react';
import {FluroDialog} from 'components';
import {useAppDispatch, useAppSelector} from '_hooks';
import {selectDialogVisibility} from '../../../modules/helpers/selectors';
import {setProfileSettings} from '../../login/actions';
import {selectUserSettings} from '../../login/login-selectors';
import {ResourceItem} from 'components/mrv/home-page-components/home-page-components';
import {DocumentWithQuestionIcon} from 'components/fluro-icons';
import type {UserSettings} from '../../admin/users/types';
import {dialogToggle, DialogType} from '../../../modules/helpers';
import {getGetURLParam, setGetParamToURL} from '_utils/pure-utils';
import {selectMRVProgramById, selectPhase} from './module/selectors';
import {isPhaseActive} from '../utils';
import {useMonitoringUrlParams} from './hooks';
import YouTube from 'react-youtube';
import {mixpanelCarbon} from '_utils/mixpanel-utils';
import {MRVPhaseType} from '../types';
import colors from 'variables.scss';
import styled from 'styled-components';

export function MonitoringOnboardingDialog() {
  const dispatch = useAppDispatch();
  const {programId} = useMonitoringUrlParams();
  const program = useAppSelector(s => selectMRVProgramById(s, programId));
  const monitoring = useAppSelector(s => selectPhase(s, MRVPhaseType.Monitoring));
  const activePhase = isPhaseActive(monitoring?.start_date, monitoring?.end_date);
  const userSettings = useAppSelector(selectUserSettings);
  const visible = useAppSelector(s =>
    selectDialogVisibility(s, DialogType.mrvMonitoringOnboardingDialog)
  );
  const profileSettingsUpdatedRef = useRef(false);

  useEffect(() => {
    if (userSettings?.onboarding?.monitoring_video_onboarded === false && activePhase) {
      dispatch(dialogToggle(DialogType.mrvMonitoringOnboardingDialog, true));
    }
  }, [dispatch, activePhase, userSettings]);

  useEffect(() => {
    // If the user has already seen the onboarding modal, don't show it again
    if (
      !profileSettingsUpdatedRef.current &&
      userSettings !== undefined &&
      getGetURLParam('display-onboarding-pop-up')
    ) {
      setGetParamToURL('display-onboarding-pop-up', null);
      const updatedSettings: UserSettings = {
        ...userSettings,
        onboarding: {...userSettings.onboarding, monitoring_video_onboarded: false},
      };
      dispatch(setProfileSettings(updatedSettings, false));
      profileSettingsUpdatedRef.current = true;
    }
  }, [dispatch, userSettings]);

  function hide() {
    if (!userSettings) {
      return;
    }

    const updatedSettings: UserSettings = {
      ...userSettings,
      onboarding: {...userSettings.onboarding, monitoring_video_onboarded: true},
    };
    dispatch(setProfileSettings(updatedSettings, false));
    dispatch(dialogToggle(DialogType.mrvMonitoringOnboardingDialog, false));
  }

  return (
    <OnboardingDialog
      id={'mrvMonitoringOnboardingDialog'}
      title={`Welcome to ${program?.name || 'the program'}!`}
      onHide={hide}
      visible={visible}
      portal
      width={500}
    >
      <Title>Walk through video</Title>
      <Text>
        In this video, we will demonstrate how to work through the open phase of{' '}
        {program?.name || 'the program'}.
      </Text>
      <Video
        videoId="20RwZtktABY"
        onPlay={() => mixpanelCarbon.track('measurement_cargill_video')}
      />
      <Text>If you have more questions, please check out the resources below.</Text>
      <ResourceItem
        icon={<DocumentWithQuestionIcon />}
        title={'User Guide'}
        link="https://hubs.ly/Q01jx5m40"
        content={'Use our step by step guide to help walk you through the Regrow Platform.'}
      />
    </OnboardingDialog>
  );
}

const OnboardingDialog = styled(FluroDialog)`
  color: ${colors['main-gray-900']};

  .fluro-dialog__content {
    flex-direction: column;
    display: flex;
    gap: 15px;
  }

  .fluro-dialog__title h3 {
    margin-bottom: 0;
  }
`;

const Title = styled.div`
  font-size: 16px;
  width: 100%;
`;

const Text = styled.div`
  color: ${colors['main-gray-800']};
  font-size: 14px;
`;

const Video = styled(YouTube)`
  width: 451px;
  height: 254px;
`;
