import {Flex} from 'components/flex/flex';
import React, {useState} from 'react';
import {Collapse, FontIcon} from 'react-md';

import {PracticeAvatar} from './practice-avatar';
import type {SharedProps} from './types';
import {Text} from 'components';
import {useTranslation} from 'i18n-utils';

type Props = SharedProps;

export const PracticeHelp = ({items}: Props) => {
  const [collapsed, toggleCollapsed] = useState(true);
  const t = useTranslation();

  return (
    <div className="mt-1">
      <Flex alignItems="center">
        <Text variant="h2" inline className="mr-05">
          {t({id: 'Practice Changes'})}
        </Text>
        <FontIcon onClick={() => toggleCollapsed(!collapsed)} style={{cursor: 'pointer'}}>
          help_outline
        </FontIcon>
      </Flex>
      <Collapse collapsed={collapsed}>
        <Flex justifyContent="space-between">
          {items.map(p => (
            <Flex key={p} alignItems="center" gap="10px" style={{width: '50%'}}>
              <PracticeAvatar value={p} />
              <Text secondary variant="small">
                {t({id: `Option.Practice.${p}`, defaultMessage: p})}
              </Text>
            </Flex>
          ))}
        </Flex>
      </Collapse>
    </div>
  );
};
