import {keyBy} from 'lodash';
import {useCallback} from 'react';
import {mutate} from 'swr';
import {useAppSelector, useAppThunkDispatch} from '_hooks';
import {currentYear} from '../enrollment/base/base';
import type {PopulateValue} from './field-rows';
import {createNewRowValues} from './field-rows';
import {removeProjectValues, updateMRVValues} from '../monitoring/module/thunks';
import type {FieldValuesRow, MRVPhaseType, MRVStageNormalized, MRVValue} from '../types';
import {MRVAttributeType} from '../types';
import type {RowsPerEntity} from './use-mrv-values';
import {createRow} from '../value-utils';
import {useMRVValuesRequestKey} from './use-mrv-values-request-key';
import {selectStageAttributeTypeIds} from '../monitoring/module/selectors';
import {isDefined} from '_utils/typeGuards';

/**
 * Provides with function add and remove rows of values to mrv table.
 * Use it in mrv-table-row component.
 */
export const useAddRemoveRow = (
  phaseType: MRVPhaseType,
  projectId: number,
  stage?: MRVStageNormalized
) => {
  const dispatch = useAppThunkDispatch();
  const entityType = stage?.entity_type;
  const stageId = stage?.id;
  const attributeIds = useAppSelector(s => selectStageAttributeTypeIds(s, stageId));
  const requestKey = useMRVValuesRequestKey(projectId, stageId);

  const addRow = useCallback(
    (entityId: number, rowIndex: number, row: FieldValuesRow) => {
      mutate<RowsPerEntity>(
        requestKey,
        async rowsPerEntity => {
          if (!rowsPerEntity || !entityType) return;
          const rows = rowsPerEntity[entityId] || [];
          const rowIds = rows.map(r => r.row_id);
          const nextRowId = Math.max(...rowIds) + 1;

          // 0. Populate values.
          const populateValues = [
            MRVAttributeType.RecordYear,
            MRVAttributeType.TillagePeriod,
            MRVAttributeType.PlantingSeason,
          ]
            .map(attributeType => populateValue(attributeIds, attributeType, row.values))
            .filter(isDefined);

          const newValues = createNewRowValues(
            phaseType,
            row.entity_type,
            nextRowId,
            populateValues
          );

          // 1. Update local rows.
          // When the server responds, the row is gonna be recreated because we'll receive the values with ids.
          const year =
            Number(
              newValues.find(v => v.attribute_id === attributeIds[MRVAttributeType.RecordYear])
                ?.value
            ) || currentYear;
          const newRows = [...rows];
          const newRow = createRow(
            entityType,
            entityId,
            nextRowId,
            year,
            keyBy(newValues, 'attribute_id')
          );
          newRows.splice(rowIndex + 1, 0, newRow);

          // 2. Update values on the server.
          // Do not await. Return the data straight away to update the UI.
          dispatch(
            updateMRVValues({
              update: {
                [entityId]: newValues,
              },
              projectId,
              entityType,
            })
          ).then(action => {
            // 3. Replace the local id-less values with the ones from the server to have the ids.
            // We need the ids to be able to remove the row.
            mutate<RowsPerEntity>(
              requestKey,
              async rowsPerEntity => {
                if (!rowsPerEntity || !entityType) return;
                const newRows = [...(rowsPerEntity[entityId] || [])];
                const newRow = newRows[rowIndex + 1];
                const newValues = action.payload as MRVValue[];
                newValues?.forEach(v => {
                  newRow.values[v.attribute_id] = v;
                });

                return {...rowsPerEntity, [entityId]: newRows};
              },
              {revalidate: false}
            );
          });

          return {...rowsPerEntity, [entityId]: newRows};
        },
        {revalidate: false}
      );
    },
    [dispatch, mutate, requestKey, projectId, phaseType, entityType, attributeIds]
  );

  const removeRow = useCallback(
    (entityId: number, rowIndex: number, valueIds: number[]) => {
      mutate<RowsPerEntity>(
        requestKey,
        async rowsPerEntity => {
          if (!rowsPerEntity || !entityType) return;

          // Do not await. Return the data straight away to update the UI.
          dispatch(
            removeProjectValues({
              valueIds,
              projectId,
              entityType,
            })
          );

          const rows = rowsPerEntity[entityId];
          const newRows = [...rows];
          newRows.splice(rowIndex, 1);

          return {...rowsPerEntity, [entityId]: newRows};
        },
        {revalidate: false}
      );
    },
    [dispatch, mutate, requestKey, projectId, entityType]
  );

  return {addRow, removeRow};
};

const populateValue = (
  attributeIds: Partial<Record<MRVAttributeType, number>>,
  attributeType: MRVAttributeType,
  rowValues: FieldValuesRow['values']
): PopulateValue | undefined => {
  const attributeId = attributeIds[attributeType];
  if (!attributeId) return;
  return {
    attributeId,
    attributeType,
    value: Object.values(rowValues).find(v => v.attribute_id === attributeId)?.value,
  };
};
