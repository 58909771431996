import type {ComponentType} from 'react';
import React, {useCallback, useMemo} from 'react';
import {dialogToggle, DialogType} from 'modules/helpers';
import {useAppDispatch, useAppSelector} from '_hooks';
import {selectDialogData} from '../../modules/helpers/selectors';
import {FluroDialog} from 'components';
import {
  ResourceItem,
  ResourceItemsContainer,
  ResourcePhaseContent,
  ResourcePhaseTitle,
} from 'components/mrv/home-page-components/home-page-components';
import {DocumentWithQuestionIcon, VideoIcon} from 'components/fluro-icons';
import {SatelliteIcon} from '../map/icons';
import {FontIcon} from 'react-md';
import {useMonitoringUrlParams} from './monitoring/hooks';
import {selectMRVProgramById, selectPhase} from './monitoring/module/selectors';
import {MRVPhaseType} from './types';
import {t} from 'i18n-utils';

export const MRVPhasesInfoDialog = () => {
  const {visible = false, payload} = useAppSelector(s =>
    selectDialogData(s, DialogType.mrvPhasesInfo)
  );
  const {programId} = useMonitoringUrlParams();
  const program = useAppSelector(s => selectMRVProgramById(s, programId));
  const monitoringPhase = useAppSelector(s => selectPhase(s, MRVPhaseType.Monitoring));
  const enrollmentPhase = useAppSelector(s => selectPhase(s, MRVPhaseType.Enrollment));
  const dispatch = useAppDispatch();

  const onHide = useCallback(() => dispatch(dialogToggle(DialogType.mrvPhasesInfo, false)), []);

  const dialogContent = useMemo(() => {
    if (!visible) return null;
    if (payload?.phase === 'monitoring') {
      return (
        <MonitoringFAQContent
          programName={program?.name || 'program'}
          blob={monitoringPhase?.resources}
        />
      );
    } else {
      return (
        <EnrollmentFAQContent
          programName={program?.name || 'program'}
          blob={enrollmentPhase?.resources}
        />
      );
    }
  }, [
    enrollmentPhase?.resources,
    monitoringPhase?.resources,
    payload?.phase,
    program?.name,
    visible,
  ]);

  return (
    <FluroDialog
      dialogClassName={'mrv-faq-dialog'}
      onHide={onHide}
      visible={visible}
      id={'mrv-faq-dialog'}
    >
      {dialogContent}
    </FluroDialog>
  );
};

type FaqContentProps = {
  programName: string;
  blob?: string;
};

const EnrollmentFAQContent: ComponentType<FaqContentProps> = ({blob}) => {
  return <>{blob && <div dangerouslySetInnerHTML={{__html: blob}} />}</>;
};
const MonitoringFAQContent: ComponentType<FaqContentProps> = ({blob}) => {
  return <>{blob && <div dangerouslySetInnerHTML={{__html: blob}} />}</>;
};

// Use this component to develop the view,
// then copy the result html and put it into the database
// mrv_blobs table and update the needed mrv_enrolments and mrv_monitoring configs
// with the new resources_blob -> blob.id.
const _MonitoringFAQContentDEVELOP: ComponentType<FaqContentProps> = ({programName}) => {
  return (
    <>
      <ResourcePhaseTitle>Measurement</ResourcePhaseTitle>

      <ResourcePhaseContent>
        Welcome to {programName} Measurement phase! In this phase, you will be able enter and
        confirm your fall 2021 and spring 2022 practices.
      </ResourcePhaseContent>
      <ResourcePhaseContent>
        Check out the resources below that can help you get through this process!
      </ResourcePhaseContent>

      <ResourceItemsContainer>
        <ResourceItem
          icon={<VideoIcon />}
          title={t({id: 'ResourceDialog.Title.Video Guide'})}
          link={'https://www.youtube.com/watch?v=t5tvGEErMK0'}
          content={t({
            id: 'ResurceDialog.Message.Video Guide',
            defaultMessage: 'Overview of the monitoring and measurement phase.',
          })}
        />
        <ResourceItem
          icon={<SatelliteIcon />}
          title={t({id: 'ResourceDialog.Title.OpTIS Guide'})}
          link={'https://app.hubspot.com/documents/4430549/view/237525906?accessId=a513a7'}
          content={t({
            id: 'ResurceDialog.Message.OpTIS Guide',
            defaultMessage:
              'Learn how OpTIS uses remote sensing data to monitor cover crops and tillage systems adoption.',
          })}
        />
        <ResourceItem
          icon={<DocumentWithQuestionIcon />}
          title={t({id: 'ResourceDialog.Title.User Guide', defaultMessage: 'User Guide'})}
          content={t({
            id: 'ResourceDialog.Message.User Guide',
            defaultMessage:
              'Walk through the process of entering information for each of your enrolled fields during the monitoring and measurement phase.',
          })}
          link={'https://hubs.ly/Q012LMkJ0'}
        />
        <ResourceItem
          icon={<DocumentWithQuestionIcon />}
          title={t({id: 'ResourceDialog.Title.Tillage Guide', defaultMessage: 'Tillage Guide'})}
          link={'https://hubs.ly/H0SzTX20'}
          content={t({
            id: 'ResourceDialog.Message.Tillage Guide',
            defaultMessage:
              'Use this table to determine tillage classification type based on your tillage activity and depth.',
          })}
        />
        <ResourceItem
          icon={<FontIcon>help_outline</FontIcon>}
          title={t({
            id: 'ResourceDialog.Title.Frequently Asked Questions',
            defaultMessage: 'Frequently Asked Questions',
          })}
          link={'https://app.hubspot.com/documents/4430549/view/235051595?accessId=35fc68'}
          content={t({
            id: 'ResourceDialog.Message.Frequently Asked Questions',
            defaultMessage:
              'Our Frequently Asked Questions will help answer all of your questions.',
          })}
        />
        <ResourceItem
          icon={<DocumentWithQuestionIcon />}
          title={t({id: 'ResourceDialog.Title.Carbon Ebook', defaultMessage: 'Carbon Ebook'})}
          link={'https://app.hubspot.com/documents/4430549/view/235495755?accessId=219151'}
          content={t({
            id: 'ResourceDialog.Message.Carbon Ebook',
            defaultMessage: 'Get started with carbon markets.',
          })}
        />
      </ResourceItemsContainer>
    </>
  );
};
