import {
  Flex,
  FluroButton,
  FluroDataTable,
  FluroTableBody,
  FluroTableColumn,
  FluroTableHeader,
  FluroTableRow,
  TableSubtext,
  Text,
} from 'components';
import {showNotification} from 'components/notification/notification';
import type {ComponentType} from 'react';
import React, {useEffect, useMemo, useState} from 'react';
import SIApi from 'containers/si/api/si';
import {useAppDispatch, useAppSelector} from '_hooks';
import {calcPlural} from '_utils/pure-utils';
import {CircularProgress, FontIcon} from 'react-md';
import type {AddSIUserToAProgramFormData} from 'containers/si/types';
import {AddUserToSIProgramPopUp} from '../components/add-user-to-si-program-pop-up';
import {selectSIProgramUsersByProgramId} from '../../module/selectors';
import {selectIsLoading} from 'modules/helpers/selectors';
import {selectIsAdmin} from 'containers/login/login-selectors';
import {SISearchInput} from 'containers/si/components/si-search-input';
import {PlaceholderLoader} from 'components/placeholder-loader/placeholder-loader';
import {fetchSIProgramUsers, removeSIProgramUser} from '../../module/thunks';
import {ImpersonateButton} from 'containers/admin/users/impersonate-button';
import {ActionType} from '../../module/types';

type Props = {
  selectedProgramId: number;
};

export const SIProgramUsers: ComponentType<Props> = ({selectedProgramId}) => {
  const dispatch = useAppDispatch();
  const isAdmin = useAppSelector(selectIsAdmin);
  const programUsersList = useAppSelector(s =>
    selectSIProgramUsersByProgramId(s, selectedProgramId)
  );
  const isLoading = useAppSelector(s => selectIsLoading(s, [ActionType.FETCH_SI_PROGRAM_USERS]));
  const [addUserPopupVisible, setAddUserPopupVisible] = useState(false);
  const [searchText, setSearchText] = useState('');

  useEffect(() => {
    dispatch(fetchSIProgramUsers(selectedProgramId));
  }, [dispatch, selectedProgramId]);

  const deleteUser = (programId: number, userId: number, email: string) => {
    if (confirm(`Are you sure you want to delete ${email} from the program?`)) {
      dispatch(removeSIProgramUser({programId: programId, user_id: userId, role: 'user'}));

      showNotification({
        type: 'success',
        title: 'Success',
        message: 'The program admin was removed.',
      });
      dispatch(fetchSIProgramUsers(selectedProgramId));
    }
  };

  const onAddUser = async (formData: AddSIUserToAProgramFormData) => {
    setAddUserPopupVisible(false);
    try {
      formData.programs.map(async programId => {
        await SIApi.addProgramUser(programId, formData.user_id, 'user');
        dispatch(fetchSIProgramUsers(selectedProgramId));
      });

      if (formData.programs.length > 1) {
        showNotification({
          type: 'success',
          title: 'Success',
          message: `A new user was added to the ${calcPlural('program', formData.programs)}.`,
        });
      } else {
        showNotification({
          type: 'success',
          title: 'Success',
          message: `The ${formData.email} was added to the program.`,
        });
      }
    } catch (error) {
      showNotification({
        type: 'error',
        title: 'Error',
        message: `Couldn't add user to the program`,
      });
    }
  };

  const preparedProgramUsers = useMemo(() => {
    return programUsersList.map(p => ({
      ...p,
      loweredFirstName: p.first_name.toLowerCase(),
      loweredLastName: p.last_name.toLowerCase(),
      loweredEmail: p.email.toLowerCase(),
    }));
  }, [programUsersList]);

  const filteredProgramUsers = useMemo(() => {
    const lowerSearchString = searchText.toLowerCase();

    return preparedProgramUsers.filter(p => {
      return (
        p.loweredFirstName.includes(lowerSearchString) ||
        p.loweredLastName.includes(lowerSearchString) ||
        p.loweredEmail.includes(lowerSearchString)
      );
    });
  }, [preparedProgramUsers, searchText]);

  const onChangeSearchText = (typedText: string) => {
    setSearchText(typedText);
  };

  return (
    <div className={'margin-bottom-auto'}>
      <Text variant="h1" secondary>
        Users {programUsersList.length !== 0 && `(${programUsersList.length})`}
      </Text>
      <Flex justifyContent="space-between" className="mb-1">
        <SISearchInput
          isLoading={isLoading}
          placeholder={'Search users'}
          value={searchText}
          onChange={onChangeSearchText}
        />
        {isAdmin && (
          <FluroButton primary raised onClick={() => setAddUserPopupVisible(true)}>
            Add a user
          </FluroButton>
        )}
      </Flex>
      {filteredProgramUsers.length > 0 ? (
        <>
          <FluroDataTable>
            <FluroTableHeader>
              <FluroTableRow>
                <FluroTableColumn>
                  <div>Name</div>
                  <TableSubtext>Email</TableSubtext>
                </FluroTableColumn>
                <FluroTableColumn type="date">Last login date</FluroTableColumn>
                <FluroTableColumn>{/* Action buttons */}</FluroTableColumn>
              </FluroTableRow>
            </FluroTableHeader>
            <FluroTableBody>
              {filteredProgramUsers.map(user => {
                const {email, first_name, last_name, user_id, last_login_time} = user || {};

                return (
                  <FluroTableRow key={user_id}>
                    <FluroTableColumn>
                      <div>
                        {first_name} {last_name}
                      </div>

                      <TableSubtext>
                        <Flex alignItems="center" gap="4px">
                          {email || <PlaceholderLoader width={150} />}
                        </Flex>
                      </TableSubtext>
                    </FluroTableColumn>

                    <FluroTableColumn type="date">{last_login_time}</FluroTableColumn>
                    <FluroTableColumn>
                      <Flex gap="10px" nowrap justifyContent={'flex-end'}>
                        {isAdmin && (
                          <ImpersonateButton
                            email={email}
                            userId={user_id as number}
                            variant="button"
                          />
                        )}
                        <FluroButton
                          icon
                          iconEl={<FontIcon>delete</FontIcon>}
                          onClick={() => deleteUser(selectedProgramId, user_id, email)}
                          tooltipPosition={'left'}
                          tooltipLabel={'Remove access'}
                        />
                      </Flex>
                    </FluroTableColumn>
                  </FluroTableRow>
                );
              })}
            </FluroTableBody>
          </FluroDataTable>
        </>
      ) : null}
      {isLoading ? (
        <CircularProgress className={'progress'} id={'si-users-table'} />
      ) : filteredProgramUsers.length === 0 ? (
        preparedProgramUsers.length !== 0 && searchText ? (
          `No users found for search = ${searchText}`
        ) : (
          'There are currently no users for the current program.'
        )
      ) : null}
      {addUserPopupVisible && (
        <AddUserToSIProgramPopUp
          title={'Add new user'}
          onHide={() => setAddUserPopupVisible(false)}
          onAdd={onAddUser}
          defaultProgramId={selectedProgramId}
          userToAddType={'user'}
        />
      )}
    </div>
  );
};
