import {Text} from 'components';
import {FluroGeoJson} from 'components/fluro-leaflet';
import {SIAreaDefaultFillColor} from 'modules/sustainability-insights/constants';
import {selectAreaUnits, selectSIGeometryById} from 'modules/sustainability-insights/selectors';
import type {ComponentType} from 'react';
import React from 'react';
import {Tooltip} from 'react-leaflet';
import {useAppSelector} from '_hooks';
import type {GeometryProps} from './types';
import {
  isGeometryInFertilizerSummaryChart,
  selectFertilizerChartTab,
  selectFertilizerColorById,
  selectFertilizerGeometryValueById,
  selectFertilizerSummaryChartData,
} from 'modules/sustainability-insights/selectors/fertilizer';
import {formatValueKgPerHa} from '../filters-panel/cards/utils';
import {kiloFormatter} from '_utils/number-formatters';

export const FertilizerGeometry: ComponentType<GeometryProps> = ({
  id,
  name,
  isHighlighted,
  isActive,
  onClick,
}) => {
  const geometry = useAppSelector(s => selectSIGeometryById(s, id));
  const color = useAppSelector(s => selectFertilizerColorById(s, id));
  const selectedTab = useAppSelector(selectFertilizerChartTab);
  const isInChart = useAppSelector(s => isGeometryInFertilizerSummaryChart(s, id));
  const value = useAppSelector(s => selectFertilizerGeometryValueById(s, id));
  const units = useAppSelector(selectAreaUnits);
  const summaryChartData = useAppSelector(selectFertilizerSummaryChartData);
  if (!geometry) return null;

  const eventHandlers = {
    click: onClick,
  };

  const fillColor = color || SIAreaDefaultFillColor;
  const defaultPathOptions = {weight: 1};
  const resolveGeometryPathOptions = () => {
    if (!isActive) {
      return {
        fillColor: SIAreaDefaultFillColor,
        fillOpacity: 0.5,
        color: 'black',
      };
    }

    if (selectedTab === 'summary' && !isInChart) {
      return {
        fillColor: SIAreaDefaultFillColor,
        fillOpacity: 1,
        color: '#464646',
      };
    }

    return {
      fillColor,
      fillOpacity: 1,
      color: 'black',
      weight: isHighlighted ? 4 : 1,
    };
  };
  const geometryPathOptions = resolveGeometryPathOptions();

  const pathOptions = {
    ...defaultPathOptions,
    ...geometryPathOptions,
  };

  const classifiedValue = formatValueKgPerHa(Number(value), units);
  const unitsSubtext =
    units === 'pct' ? ` of ${kiloFormatter(Number(summaryChartData?.totalValue))} tons` : '';

  return (
    <FluroGeoJson data={geometry} pathOptions={pathOptions} eventHandlers={eventHandlers}>
      <Tooltip>
        <Text variant="small">{name}</Text>
        <p className="mb-0">
          {value !== undefined && Boolean(summaryChartData?.totalValue)
            ? `${classifiedValue} ${unitsSubtext}`
            : ''}
        </p>
      </Tooltip>
    </FluroGeoJson>
  );
};
