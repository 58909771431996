import type {ComponentType, HTMLAttributes} from 'react';
import React from 'react';

type Props = {};

export const MapBarContent: ComponentType<Props & HTMLAttributes<HTMLDivElement>> = ({
  children,
  className,
  ...props
}) => {
  return (
    <div className={`${className} map-bar__content`} {...props}>
      {children}
    </div>
  );
};
