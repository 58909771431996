import type {ComponentType} from 'react';
import React, {memo, useCallback} from 'react';
import type {FieldValuesRow, MRVPhaseType, MRVValueInput} from 'containers/mrv/types';
import cn from 'classnames';
import {FluroTableColumn} from 'components/fluro-table-components/fluro-table-components';
import {Button, FontIcon} from 'react-md';
import {MRVTableCell} from 'containers/mrv/monitoring/form/mrv-table/mrv-table-cell';
import {isDefined} from '_utils/typeGuards';
import {useAppSelector} from '_hooks';
import {selectMeasurement} from 'containers/login/login-selectors';
import {t} from 'i18n-utils';
import {FluroCheckbox} from 'components/fluro-checkbox/fluro-checkbox';
import {PlaceholderLoader} from 'components/placeholder-loader/placeholder-loader';
import type {
  ValidationError,
  ValidationWarning,
} from 'containers/mrv/monitoring/form/mrv-table/hacky-validation';
import {getHackyOptions} from 'containers/mrv/monitoring/form/mrv-table/get-hacky-options';
import {useMonitoringUrlParams} from 'containers/mrv/monitoring/hooks';
import {selectMRVAttributes} from 'containers/mrv/monitoring/module/selectors';
import {getEntityId} from 'containers/mrv/value-utils';

export type MRVTableRowProps = {
  phaseType: MRVPhaseType;
  row: FieldValuesRow;
  /**
   * Index of the row in the table, as opposed to row_id, which doesn't guarantee the "order",
   * i.e. if the rows are sorted by years first.
   */
  rowIndex: number;
  attributesIds: number[];
  hideCheckbox?: boolean;
  canAddRow?: boolean;
  canDeleteRow?: boolean;
  readOnly?: boolean;
  onChange?: (entityId: number, rowIndex: number, values: MRVValueInput[]) => void;
  onAddRow?: (entityId: number, rowIndex: number, row: FieldValuesRow) => void;
  onRemoveRow?: (entityId: number, rowIndex: number, valueIds: number[]) => void;
  isSelected?: boolean;
  isLoading?: boolean;
  onSelect?: (entityId: number, rowId: number, isSelected: boolean) => void;
  errors?: ValidationError[];
  warnings?: ValidationWarning[];
};

export const MRVTableRow: ComponentType<MRVTableRowProps> = ({
  phaseType,
  row,
  rowIndex,
  attributesIds,
  canAddRow,
  canDeleteRow,
  onChange,
  onAddRow,
  onRemoveRow,
  hideCheckbox,
  readOnly,
  isSelected,
  isLoading,
  onSelect,
  errors = [],
  warnings = [],
}) => {
  const {field_id: fieldId, row_id: rowId, values} = row;
  const entityId = getEntityId(row);
  const measurement = useAppSelector(selectMeasurement);

  const handleCheckbox = useCallback(
    (value: boolean) => entityId && onSelect?.(entityId, rowId, value),
    [onSelect, entityId, rowId]
  );

  const {programId} = useMonitoringUrlParams();
  const attributes = useAppSelector(selectMRVAttributes);

  return (
    <tr
      className={cn('field-row', {
        'field-row-top': rowIndex === 0,
      })}
      data-testid={`MRV-table--row--${rowId}`}
    >
      {!hideCheckbox && (
        <FluroTableColumn className={'mrv-form__checkbox-column'}>
          <FluroCheckbox onChange={handleCheckbox} value={isSelected} disabled={readOnly} />
        </FluroTableColumn>
      )}
      {attributesIds.map(attrId =>
        isLoading ? (
          <FluroTableColumn key={attrId}>
            <PlaceholderLoader width={200} height={30} />
          </FluroTableColumn>
        ) : (
          <MRVTableCell
            phaseType={phaseType}
            entityType={row.entity_type}
            key={`${attrId}-${fieldId}`}
            attributeId={attrId}
            rowId={rowId}
            rowIndex={rowIndex}
            entityId={entityId}
            values={values}
            projectValue={values[attrId]}
            onChange={onChange || noop}
            hackyOptions={getHackyOptions(
              attributes[attrId].type,
              row,
              attributes,
              programId,
              measurement
            )}
            readOnly={readOnly}
            error={errors.find(ve => ve.attributeId === attrId)}
            warning={warnings.find(ve => ve.attributeId === attrId)}
          />
        )
      )}
      {(canAddRow || canDeleteRow) && (
        <FluroTableColumn className="mrv-form__multiple-rows-column" nowrap>
          <Button
            tooltipLabel={t({id: 'BtnLabel.AddEvent', defaultMessage: 'Add event'})}
            tooltipPosition="top"
            style={{width: 50, height: 50, visibility: canAddRow ? 'visible' : 'hidden'}}
            disabled={!canAddRow}
            icon
            onClick={() => entityId && onAddRow?.(entityId, rowIndex, row)}
          >
            <FontIcon style={{fontSize: 30}}>add_circle</FontIcon>
          </Button>
          <Button
            tooltipLabel={t({id: 'BtnLabel.RemoveEvent', defaultMessage: 'Remove event'})}
            tooltipPosition="top"
            style={{width: 50, height: 50, visibility: canDeleteRow ? 'visible' : 'hidden'}}
            disabled={!canDeleteRow}
            icon
            onClick={() => {
              const response = confirm(`Are you sure you want to DELETE the row?`);
              if (response && entityId) {
                onRemoveRow?.(
                  entityId,
                  rowIndex,
                  Object.values(values)
                    .map(v => v.id)
                    .filter(isDefined)
                );
              }
            }}
          >
            <FontIcon style={{fontSize: 30}}>delete_outline</FontIcon>
          </Button>
        </FluroTableColumn>
      )}
    </tr>
  );
};

const noop: () => void = () => undefined;

export const MRVTableRowMemo = memo(MRVTableRow);
