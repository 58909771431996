import {Flex} from 'components';
import {CropAvatar} from 'components/crop-avatar';
import type {CropType} from 'modules/global/types';
import React from 'react';

export const CropItem = ({cropType}: {cropType: CropType}) => (
  <Flex gap={4} alignItems="center">
    <CropAvatar cropType={cropType.value} className="crop-type-select__avatar" />
    {cropType.label}
  </Flex>
);
