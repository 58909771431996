import type {ComponentType, ElementType, HTMLAttributes} from 'react';
import React from 'react';
import cn from 'classnames';
import './flex.scss';

type Props = {
  auto?: boolean;
  basis?: string;
  elementType?: ElementType;
  grow?: boolean;
  /**
   * Number of columns in 12-column grid system to occupy
   * <>
   *  <Col cols="4">
   *  <Col cols="8">
   * </>
   *
   * |-----4-----|-----------------8-----------------|
   */
  cols?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
};

export const Col: ComponentType<Props & HTMLAttributes<HTMLElement>> = ({
  elementType: ColElement = 'div',
  className,
  children,
  basis,
  grow,
  auto = false,
  cols,
}) => {
  return (
    <ColElement
      className={cn(className, 'flex-element__col', {
        'flex-element__col--auto': !basis && auto,
        'flex-element__col--grow': !basis && !auto && grow,
        [`flex-element__col--${cols}`]: cols,
      })}
      style={{flexBasis: basis}}
    >
      {children}
    </ColElement>
  );
};
