import React, {useEffect} from 'react';
import {t} from 'i18n-utils';
import type * as Leaflet from 'leaflet';
import {AnalyticsPinImage} from '../../icons';
import {pointInside} from '_utils';
import {
  addAnalyticPoint,
  removeAnalyticPoint,
  getAnalyticsData,
} from 'containers/map/actions/analytics-actions';
import {showNotification} from 'components/notification/notification';
import type {AnalyticAddedPoint} from '../../types';
import {FluroMarker} from 'components/fluro-leaflet';
import {useAppDispatch, useAppSelector} from '_hooks';
import {
  selectCurrentFieldKml,
  selectCurrentSeason,
  selectCurrentSensor,
  selectCurrentTab,
  selectImagesStatus,
  selectIsDrawingMode,
} from 'containers/map/reducer/selectors';

import {
  selectAnalyticHiddenPointColors,
  selectMapAnalytics,
} from 'containers/map/features/analytics/analytics-selectors';

type Props = {
  leafletElement: Leaflet.Map;
};

const AnalyticsOverlay = ({leafletElement}: Props) => {
  const dispatch = useAppDispatch();
  const currentFieldKml = useAppSelector(selectCurrentFieldKml);
  const analyticPoints = useAppSelector(selectMapAnalytics).addedPoints;
  const hiddenPointColors = useAppSelector(selectAnalyticHiddenPointColors);
  const feature = useAppSelector(selectCurrentTab);
  const currentSensor = useAppSelector(selectCurrentSensor);
  const isDrawingMode = useAppSelector(selectIsDrawingMode);
  const currentSeason = useAppSelector(selectCurrentSeason);
  const {isImagesProcessing} = useAppSelector(selectImagesStatus);

  useEffect(() => {
    leafletElement.on('click', handleClick);
    return () => {
      leafletElement.off('click', handleClick);
    };
  }, [isImagesProcessing, currentSeason, analyticPoints.length]);

  const handleClick = (event: any) => {
    if (isDrawingMode) return;

    if (isImagesProcessing) {
      showNotification({
        title: t({id: 'note.warning', defaultMessage: 'Warning'}),
        message: 'You can not add a pin, awaiting imagery',
        type: 'warning',
      });
    }

    if (!currentSeason?.id) {
      return showNotification({
        title: t({id: 'note.warning', defaultMessage: 'Warning'}),
        message: 'You can not add a pin, please set your crop first',
        type: 'warning',
      });
    }

    if (analyticPoints.length >= 5) {
      return showNotification({
        title: t({id: 'note.warning', defaultMessage: 'Warning'}),
        message: t({
          id: 'You can select up to 5 pins.',
          defaultMessage: 'You can select up to 5 pins.',
        }),
        type: 'warning',
      });
    }

    const isGeometryCollection = currentFieldKml?.geometry?.type === 'GeometryCollection';
    if (
      isGeometryCollection
        ? currentFieldKml.geometry.geometries.every(
            (g: any) => !pointInside([event.latlng.lng, event.latlng.lat], g.coordinates[0])
          )
        : !pointInside(
            [event.latlng.lng, event.latlng.lat],
            currentFieldKml?.geometry?.coordinates?.[0]
          )
    ) {
      return showNotification({
        title: t({id: 'note.warning', defaultMessage: 'Warning'}),
        message: t({
          id: 'You can not add a pin outside the field.',
          defaultMessage: 'You can not add a pin outside the field.',
        }),
        type: 'warning',
      });
    }

    if (currentSensor !== 'NMAP' && event.latlng && currentFieldKml) {
      const point: AnalyticAddedPoint = {latlng: event.latlng, color: ''};
      dispatch(addAnalyticPoint(point)); // if click is on layer, add an analytic point
      dispatch(getAnalyticsData()); // and send request with updated points number
    }
  };

  const removePointFromMap = (color: string) => {
    dispatch(removeAnalyticPoint(color));
  };

  if (!analyticPoints.length || feature !== 'analytics') return null;

  return (
    <>
      {analyticPoints.map((point: AnalyticAddedPoint) => {
        const icon = AnalyticsPinImage(
          point.color,
          !hiddenPointColors.includes(point.color),
          false
        );
        const position = point.latlng;
        return (
          <FluroMarker
            key={point.color}
            position={position}
            eventHandlers={{click: () => removePointFromMap(point.color)}}
            icon={icon}
            title={point.color}
          />
        );
      })}
    </>
  );
};

export default AnalyticsOverlay;
